import styled from '@emotion/styled';
import { Box } from 'rebass';

export const GroupHeader = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 1.5em;
`;

export const ItemsWrapper = styled(Box)`
  position: relative;
`;

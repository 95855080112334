// eslint-disable-next-line max-classes-per-file
import { gql } from '@apollo/client';
import { Client, ITokenProvider, TokenProviderResponse } from '@pusher/push-notifications-web';

import { GraphQL } from '../client/client.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class CustomTokenProvider implements ITokenProvider {
  token: string;
  constructor(token: string) {
    this.token = token;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchToken(userId: string): Promise<TokenProviderResponse> {
    return Promise.resolve({ token: this.token });
  }
}

export class BeamsPushProvider {
  client: Client;
  userId: string;

  constructor(userId: string) {
    this.client = new Client({
      instanceId: 'eaa607f9-a719-4da8-b281-e48188ecf174'
    });
    this.userId = userId;
  }

  public async enableNotifications(): Promise<void> {
    if (!this.userId) return;

    const pusherTokenResult = await GraphQL.query<{ getPusherToken: { token: string } }>(
      gql`mutation GetPusherToken($where: UserWhereUniqueInput!) {
        getPusherToken(where: $where) {
          token
        }
      }`,
      {
        where: {
          id: this.userId
        }
      }
    );

    this.client
      .start()
      .then(() => {
        this.client.setUserId(this.userId, {
          fetchToken: () => Promise.resolve({
            token: pusherTokenResult?.data?.getPusherToken?.token
          })
        });
      })
      .then(() => {
        this.client.addDeviceInterest('general');
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  public getRegistrationState(): Promise<string> {
    return this.client.getRegistrationState();
  }

  public disableNotifications(): void {
    this.client.stop();
  }
}

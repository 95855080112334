import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';
import { breakpoints } from 'theme';

import { space } from 'utils/rebass-theme';

export const TabHeader = styled(Flex)`
    padding: 0 ${space[3]}px;
    
    @media only screen and (max-width: ${breakpoints.medium}) {
      padding: 0 ${space[1]}px;
      flex-grow: 1;
    }
`;

export const TabScrollContainer = styled(Box)`
    width: 100%;
    overflow-x: scroll;

    /* hide scrollbar */
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
`;

import 'styles/tailwind.css';
import { FC } from 'react';

import GenericModal from 'components/generic-modal';

import { CreateTransactionForm } from './components/create-transaction';
import { TransactionCreateModalProps } from './transaction-create-modal.types';

const TransactionCreateModal: FC<TransactionCreateModalProps> = ({
  closeModal,
  isOpen,
  data: {
    orderId,
    merchantId,
    periodId
  }
}) => {
  return (
    <GenericModal
      title="Create transaction"
      closeModal={closeModal}
      modalOpen={isOpen}
      shouldHideFooter={true}
      width={600}
      innerComponent={(
        <CreateTransactionForm
          closeModal={closeModal}
          orderId={orderId}
          merchantId={merchantId}
          periodId={periodId}
        />
      )}
    />
  );
};

export default TransactionCreateModal;

import { gql } from '@apollo/client';

export const ADD_ON_FULL = gql`
  fragment AddOnFull on Addon {
    id
    active
    title
    brand
    channels {
      id
      channel
    }
    merchant {
      id
      flc_id
      currency
      flc_id
      plan {
        features
      }
    }
    category {
      title
      id
    }
    media {
      id
      flc_id
      title
      confirmed
      category
      src
    }
    price
    stock
    allProducts
    selectedProducts {
      id
    }
    createdAt
    updatedAt
  }
`;

export const ADD_ONS_CONNECTION = gql`
  fragment AddonsConnectionFields on AddonConnection {
    pageInfo {
      hasNextPage
    }
    aggregate {
      count
    }
    edges {
      node {
        ...AddOnFull
      }
    }
  }
  ${ADD_ON_FULL}
`;

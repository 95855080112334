import {
  memo,
  useEffect
} from 'react';

import {
  css
} from '@emotion/react';
import {
  RefinementListProvided,
  connectRefinementList
} from 'react-instantsearch-core';

import {
  CatalogItemType
} from 'generated-types.d';

import { PRODUCT_CATEGORIES } from 'features/lists/lists.constants';

import CheckboxList from 'components/checkbox-list';

import { CatalogAccordionHeading } from '../catalog-accordion-heading/catalog-accordion-heading';

import { CheckboxListWrapper } from './catalog-refinement-menu.styles';

interface CatalogRefinementMenuProps extends RefinementListProvided {
  selectedCategory: CatalogItemType | 'All';
  name: string;
}

export const CatalogRefinementMenu = connectRefinementList(memo((props: CatalogRefinementMenuProps): JSX.Element | null => {
  const ALL = 'All';

  const selectedItem = props.items?.find?.(item => item.isRefined);
  const isSelectedItemPopulated = !!selectedItem && !!selectedItem?.count;

  useEffect(() => {
    handleAutoSelection();
  }, []);

  useEffect(() => {
    handleAutoSelection();
  }, [isSelectedItemPopulated]);

  const selectItems = (items: string[]): void => {
    props.refine(items);
  };

  const handleAutoSelection = (): void => {
    if (!isSelectedItemPopulated) {
      const selectedCategory = props.selectedCategory;

      if (selectedCategory) {
        return selectItems([selectedCategory]);
      }
    }
  };

  const buildItems = (): any[] => {
    const items = PRODUCT_CATEGORIES.map(category => {
      const metadata = props.items?.find?.(item => item.label === category.key);

      return {
        title: `${category.title} (${metadata?.count || 0})`,
        value: category.key || '',
        isDisabled: !metadata?.count
      };
    });

    return [
      {
        title: ALL,
        value: ALL,
        isDisabled: false
      },
      ...items
    ];
  };

  if (!props.items.length) return null;

  const products = PRODUCT_CATEGORIES.map(category => category.key);
  const allItems = [
    ALL,
    ...products
  ];

  return (
    <CatalogAccordionHeading
      name={props.name}
      isInitiallyOpen={true}
      shouldDisplayCount={false}
      refinementCount={props.currentRefinement?.length}
    >
      <CheckboxListWrapper>
        <CheckboxList
          data-testid={props.currentRefinement || ALL}
          selectedItems={props.currentRefinement}
          itemValueField="value"
          optionTitleField="title"
          orientation="vertical"
          items={buildItems()}
          checkboxStyles={css`
            margin-bottom: 0;
          `}
          onChange={(value): void => {
            const item = props.items.find(refinementItem => refinementItem.label === value);

            if (value === ALL) {
              // All option clicked when not selected
              if (!item?.value) {
                return selectItems(allItems);
              }

              // All option clicked when already selected
              if (item && item?.value?.length >= 1) {
                return selectItems([]);
              }
            } else {
              // All ordinary options are selected
              if (item?.value?.length === products.length && !item.isRefined) {
                return selectItems(allItems);
              }

              // Uncheck All option if all ordinary options are not selected
              if (item?.value?.length === products.length && item?.isRefined === true) {
                const productsFiltered = products.filter(product => product !== value);

                return selectItems(productsFiltered);
              }

              // Ordinary option clicked
              return selectItems(item?.value || []);
            }
          }}
        />
      </CheckboxListWrapper>
    </CatalogAccordionHeading>
  );
}));

import { FC, Fragment, useEffect } from 'react';

import { Global } from '@emotion/react';
import { inject, observer } from 'mobx-react';

import { PhoneContact } from 'types/conversations.types';

import { ContactsProvider } from 'features/conversations/components/contacts-provider/contacts-provider';

import GenericModal from 'components/generic-modal';

import {
  genericModalStylesProps,
  ModalStyles
} from './conversation-contact-list-modal.styles';
import { ConversationContactListModalProps } from './conversation-contact-list-modal.types';

const ConversationContactListModal: FC<ConversationContactListModalProps> = ({
  conversationsStore,
  merchantStore,
  closeModal,
  isOpen,
  data
}) => {
  const contacts = conversationsStore?.contacts.length ? conversationsStore?.contacts : [];

  useEffect(() => {
    conversationsStore?.fetchContacts(merchantStore?.merchant?.id);
  }, []);

  const handleContactSelect = (contact: PhoneContact): void => {
    data?.onContactSelect?.(contact);
    closeModal();
  };

  return (
    <Fragment>
      <Global styles={ModalStyles} />
      <GenericModal
        title="Share List"
        closeModal={closeModal}
        modalOpen={isOpen}
        shouldHideHeader={false}
        shouldHideFooter={true}
        hasFooterBorder={false}
        hasOverflow={false}
        width={280}
        contentLayoutType="full-height"
        {...genericModalStylesProps}
      >
        <ContactsProvider
          contacts={contacts}
          isLoadingContacts={conversationsStore?.contactsStatus === 'loading'}
          onContactSelect={handleContactSelect}
          onBackButtonClick={closeModal}
          showHeader={false}
        />
      </GenericModal>
    </Fragment>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  selectedListStore: stores.selectedListStore,
  conversationsStore: stores.conversationsStore,
  merchantStore: stores.merchantStore
}))(observer(ConversationContactListModal));

import {
  FC,
  Fragment,
  useState,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { Lambda, observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { TEST_IDS } from 'utils/test/data-test-ids';

import NoResultsGif from 'assets/images/wholesale/no-results.gif';

import { ModalRouterScrollableArea } from 'features/modal-router/modal-router.styles';
import { useHasWholesaleShopAccess } from 'features/wholesale/hooks/hasWholesaleAccess';
import WholesaleServices from 'features/wholesale/services';

import Icon from 'components/icon';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import { WholesaleShopListingCard } from './components/wholesale-shop-listing-card/wholesale-shop-listing-card';
import { TransformedTradeSkuAvailablityItem } from './components/wholesale-shop-listing-card/wholesale-shop-listing-card.types';
import { WholesaleShopListingCarousel } from './components/wholesale-shop-listing-carousel/wholesale-shop-listing-carousel';
import { flattenAvailability } from './utils/transformers';
import { WholesaleShopListingContext } from './wholesale-shop-listing.context';
import {
  CloseIconContainer,
  NoResults,
  NotFoundWrapper,
  WholesaleShopListingContainer
} from './wholesale-shop-listing.styles';
import { WholesaleShopListingProps } from './wholesale-shop-listing.types';

const NotFound: FC<{
  hasWholesaleShopAccess: boolean;
}> = ({
  hasWholesaleShopAccess
}) => {
  const {
    closeTo,
    availabilityItems
  } = useContext(WholesaleShopListingContext);

  const copy = useMemo<{
    heading: string;
    body: string;
    hasButton: boolean;
  }>(() => {
    switch (true) {
      case !hasWholesaleShopAccess: {
        return {
          heading: `Want wholesale access?`,
          body: `Get in touch with Floom and we'll get you set up!`,
          hasButton: false
        };
      }

      case !availabilityItems?.length: {
        return {
          heading: `There's no availability`,
          body: `Oops, this item doesn't have any availability at the moment.`,
          hasButton: true
        };
      }

      default: {
        return {
          heading: 'Oops, cannot find catalog item',
          body: `The catalog item you requested can't be found.`,
          hasButton: true
        };
      }
    }
  }, [availabilityItems]);

  return (
    <NotFoundWrapper>
      <NoResults>
        <NoResultsGeneric
          image={NoResultsGif}
          heading={copy.heading}
          copy={(
            <Fragment>
              {copy.body}
              {copy.hasButton && (
                <Fragment>
                  Go back to{' '}
                  <Link to={closeTo || ''}>
                    <Box
                      as="span"
                      css={css`
                        text-decoration: underline;
                      `}
                    >
                      the shop list
                    </Box>
                  </Link>
                  {' '}
                  to carry on shopping.
                </Fragment>
              )}
            </Fragment>
          )}
        />
      </NoResults>
    </NotFoundWrapper>
  );
};

export const WholesaleShopListing: FC<WholesaleShopListingProps> = inject((stores: FxStores): InjectedFxStores => ({
  catalogItemStore: stores.catalogItemStore,
  merchantStore: stores.merchantStore,
  wholesaleShopBasketStore: stores.wholesaleShopBasketStore,
  wholesaleShopStore: stores.wholesaleShopStore,
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  toasterStore: stores.toasterStore
}))(observer(({
  merchantStore,
  catalogItemStore,
  wholesaleShopBasketStore,
  wholesaleShopStore,
  wholesaleOrdersStore,
  toasterStore,
  config = {
    closeTo: NavPages.WholesaleShop
  }
}) => {
  const {
    hasWholesaleShopAccess
  } = useHasWholesaleShopAccess({
    merchant: merchantStore!.merchant,
    merchantWholesaleSuppliers: wholesaleShopStore!.merchantWholesaleSuppliers,
    wholesaleDeliveryConfig: merchantStore!.wholesaleDeliveryConfig
  });

  const [isFetchingCatalogItem, setIsFetchingCatalogItem] = useState<boolean>(true);
  const [isPlacingOrder, setIsPlacingOrder] = useState<boolean>(false);
  const [isPurchased, setIsPurchased] = useState<boolean>(false);
  const [selectedRadioOption, setSelectedRadioOption] = useState<string>('');
  const [availabilityItems, setAvailabilityItems] = useState<TransformedTradeSkuAvailablityItem[]>([]);

  const isCancelled = useRef(false);

  useEffect(() => {
    const shopObserver = reactToShopLoading();

    handleFetchCatalogItem({
      canFetch: !wholesaleShopStore!.isLoading
    });

    return () => {
      shopObserver();
      catalogItemStore!.resetStore();
      wholesaleShopBasketStore!.resetStore();
      isCancelled.current = true;
    };
  }, []);

  const reactToShopLoading = (): Lambda => {
    return observe(wholesaleShopStore!, 'isLoading', ({
      oldValue,
      newValue
    }: any) => {
      handleFetchCatalogItem({
        canFetch: oldValue === true && newValue === false
      });
    });
  };

  const handleFetchCatalogItem = ({
    canFetch
  }: {
    canFetch: boolean;
  }): void => {
    if (canFetch && wholesaleShopStore!.canDeliver) {
      fetchCatalogItem();
    } else {
      setIsFetchingCatalogItem(false);
    }
  };

  const fetchCatalogItem = async (): Promise<any> => {
    try {
      setIsFetchingCatalogItem(() => true);

      const catalogItem = await catalogItemStore!.fetchCatalogItem({
        catalogItemId: config.id!,
        supplierId: wholesaleShopStore!.selectedSupplier?.id
      });

      const transformedAvailabilityItems = flattenAvailability(catalogItem.tradeSku!);

      setAvailabilityItems(() => transformedAvailabilityItems);
      setIsFetchingCatalogItem(() => false);
    } catch (error) {
      setIsFetchingCatalogItem(() => false);
    }
  };

  const setPurchasedState = (): void => {
    setIsPurchased(() => true);

    setTimeout(() => {
      if (!isCancelled.current) setIsPurchased(() => false);
    }, 3000);
  };

  const fetchOrderForSupplier = async (): Promise<void> => {
    await WholesaleServices.WholesaleOrdersAPIService.fetchCurrentOpenOrders();
  };

  const handleOrderPlace = async ({
    supplierId
  }: {
    supplierId: string;
  }): Promise<any> => {
    try {
      if (!supplierId || !wholesaleShopStore!.canDeliverSupplier(supplierId)) {
        throw new Error('Delivery date not found');
      }

      if (!merchantStore?.merchant?.id) {
        throw new Error('Unable to place order without a merchant selected');
      }

      const deliveryDateConfig = wholesaleShopStore!.getDeliveryConfigForSupplier(supplierId);
      const orderId = wholesaleOrdersStore!.getCurrentOrderId(supplierId);

      setIsPlacingOrder(() => true);

      await wholesaleShopBasketStore!.placeOrder({
        merchantId: merchantStore.merchant.id,
        cutOffDate: deliveryDateConfig?.cutOffAt!,
        deliveryDate: deliveryDateConfig?.dateString!,
        orderId: orderId
      });

      setIsPlacingOrder(() => false);
      setPurchasedState();
      fetchOrderForSupplier();
      wholesaleShopStore!.showOrderSidebar();
    } catch (error: any) {
      if (wholesaleShopBasketStore!.orderCreateError) {
        toasterStore!.popToast(wholesaleShopBasketStore!.orderCreateError, ToastType.Error);
      } else {
        toasterStore!.popErrorToast('this item to your order', 'add');
      }
      setIsPlacingOrder(() => false);
    }
  };

  const closeURI = useMemo((): string => {
    return config.closeTo || NavPages.WholesaleShop;
  }, [config.closeTo]);

  return (
    <ModalRouterScrollableArea>
      <WholesaleShopListingContext.Provider
        value={{
          closeTo: closeURI,
          isPlacingOrder: isPlacingOrder,
          isPurchased: isPurchased,
          onPlaceOrder: handleOrderPlace,
          selectedRadioOption: selectedRadioOption,
          setSelectedRadioOption: setSelectedRadioOption,
          availabilityItems: availabilityItems,
          catalogItem: catalogItemStore!.catalogItem,
          hasShopAccess: hasWholesaleShopAccess
        }}
      >
        <Fragment>
          <CloseIconContainer
            data-testid={TEST_IDS.WholesaleShop.modal.close}
            as={Link}
            to={closeURI}
          >
            <Icon iconName="cross-medium" />
          </CloseIconContainer>
          <WithLoading
            loaderSize="small"
            isLoading={(isFetchingCatalogItem && !catalogItemStore!.catalogItem) || wholesaleShopStore!.isLoading}
            hasNoResults={(!isFetchingCatalogItem && (!catalogItemStore!.catalogItem || !availabilityItems?.length)) || !hasWholesaleShopAccess}
            renderNoResults={() => (
              <NotFound
                hasWholesaleShopAccess={hasWholesaleShopAccess}
              />
            )}
            renderBody={() => (
              <WholesaleShopListingContainer>
                <WholesaleShopListingCarousel />
                <WholesaleShopListingCard />
              </WholesaleShopListingContainer>
            )}
          />
        </Fragment>
      </WholesaleShopListingContext.Provider>
    </ModalRouterScrollableArea>
  );
}));

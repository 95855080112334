import styled from '@emotion/styled';

import { colors, space } from 'utils/rebass-theme';

export const SearchResultInner = styled.li<{ isFocussed: boolean }>`
  display: flex;
  align-items: center;
  padding: ${space[3]}px;
  transition: background-color .1s ease-in-out;
  cursor: pointer;
  background-color: ${({ isFocussed }): string => isFocussed ? colors.lightGrey : 'none'};
  border-top: 1px solid ${colors.middleGrey};

  &:first-of-type {
    border-top: none;
  }
`;

import React from 'react';

import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box, Text } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import UserService from 'features/login-signup/services/';

import Button from 'components/button';
import FieldText from 'components/field-text';

import * as Styles from './reset-password.styles';

const LOGIN_ERRORS = {
  77046112839: 'Cannot find associated account. Your reset link may have expired.'
};

const SOMETHING_WENT_WRONG = 'Something went wrong when resetting your password. Your reset link may have expired.';

class ResetPassword extends React.Component<any> {
  userService = UserService;

  state = {
    passwordEntered: false,
    isLoading: false,
    error: null
  };

  componentDidMount = async (): Promise<any> => {
    this.userService.init();
    this.subscribeToLoginErrors();
  };

  private subscribeToLoginErrors(): void {
    observe(this.props.accountStore, 'error', ({
      newValue: newError
    }: any) => {
      this.setState({
        error: newError.code && LOGIN_ERRORS[newError.code] ? LOGIN_ERRORS[newError.code] : SOMETHING_WENT_WRONG,
        isLoading: false
      });
    });
  }

  private passwordsMatch = (): boolean => this.props.accountStore.newPassword === this.props.accountStore.confirmNewPassword;

  private onPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault();

    this.setState({
      error: null
    });

    if (!this.passwordsMatch()) {
      this.setState({ error: 'Passwords do not match.' });
    } else {
      this.setState({ isLoading: true });
      await this.userService.resetPassword();
    }
  };

  private onChange = (e: React.ChangeEvent<HTMLInputElement>, callback: any): void => {
    this.resetError();

    callback(e.target.value);
  };

  resetError = (): void => {
    if (this.hasError()) {
      this.setState({ error: null });
    }
  };

  private hasError = (): boolean => !!this.state.error;

  render(): React.ReactNode {
    return (
      <Styles.LoginWrapper>
        <Text
          css={textStyles.h3}
          mb="25px"
        >
          Reset password
        </Text>
        <form onSubmit={this.onPasswordSubmit}>
          <Box alignItems="flex-start">
            <FieldText
              type="password"
              placeholder="New password"
              id="new-password-field"
              value={this.props.accountStore.newPassword}
              autoFocus={true}
              onChange={(e): void => this.props.accountStore.updateBasicValue('newPassword', e.target.value)}
              error={this.hasError()}
            />
          </Box>
          <Box
            mt="20px"
            alignItems="flex-start"
          >
            <FieldText
              type="password"
              placeholder="Confirm password"
              id="confirm-password-field"
              value={this.props.accountStore.confirmNewPassword}
              autoFocus={true}
              onChange={(e): void => this.props.accountStore.updateBasicValue('confirmNewPassword', e.target.value)}
              error={this.hasError()}
            />
          </Box>

          { this.hasError() && (
            <Styles.Error>
              {this.state.error}
            </Styles.Error>
          )}

          <Box mt="20px">
            <Box
              as="button"
              type="submit"
              width="100%"
              id="login-submit-button"
              disabled={this.hasError() && this.state.passwordEntered}
            >
              <Button
                isParentWidth={true}
                isDisabled={this.hasError() && this.state.passwordEntered}
                copy="Reset password"
                isLoading={!this.props.accountStore.loggedIn && this.state.isLoading}
              />
            </Box>
          </Box>
        </form>
      </Styles.LoginWrapper>
    );
  }
}

export default inject('accountStore')(observer(ResetPassword));

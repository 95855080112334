import styled from '@emotion/styled';
import { Box, Text } from 'rebass';

import {
  breakpoints,
  borderRadius,
  colors,
  shadows,
  space,
  fontSizes,
  textStyles
} from 'utils/rebass-theme';

export const SuppliersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 260px);
  padding-top: ${space[4]}px;
  grid-gap: ${space[3]}px;
  @media only screen and (max-width: ${breakpoints.medium}) {
    justify-content: center;
    gap: ${space[4]}px;
  }
`;

export const SupplierCard = styled(Box)`
  width: 100%;
  background: ${colors.uiFills};
  box-shadow: ${shadows.small};
  border-radius: ${borderRadius.medium};
  overflow: hidden;
`;

export const SupplierCardTitle = styled(Box)`
  ${textStyles.h4};
  padding: ${space[2]}px ${space[3]}px;
  background: ${colors.lightGrey};
  width: 100%;
  align-items: center;
`;

export const SupplierCardContent = styled.div`
  width: 100%;
  padding: ${space[3]}px;
`;

export const SupplierCardRow = styled(Box)`
  width: 100%;
  display: flex;
`;

export const SupplierRowTitle = styled.h4`
  font-size: ${fontSizes.small}px;
  font-weight: bold;
`;

export const SupplierEditButton = styled.button`
  outline: none;
  display: flex;
  gap: ${space[2]}px;
  align-items: center;
  border: none;
  margin: 0;
  color: ${colors.floomMidnightBlue};
  padding: ${space[2]}px 0px;
  text-decoration: underline;
  background: transparent;
  font-weight: bold;
  font-size: ${fontSizes.small}px;
`;

export const StatusLabel = styled(Text)`
  margin-right: ${space[3]}px;
  font-size: ${fontSizes.small}px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colors } from 'utils/rebass-theme';

export const OrderHighlight = styled.span`
  color: ${colors.floomOrange};
`;

export const SupportLink = css`
  color: ${colors.floomMidnightBlue};
  text-decoration: underline;
`;

import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const Content = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  max-width: 565px;
  min-height: 78px;
`;

export const ContentWrapper = styled.div`
  overflow-x: auto;
  min-width: 500px;
  overflow: visible;
`;

export const ErrorWrapper = styled.div`
    padding-top: 12px;
`;

import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import PromotedMyPromotions from 'features/promoted/pages/my-promotions';
import PromotedOverview from 'features/promoted/pages/overview';
import PromotedUpcoming from 'features/promoted/pages/upcoming';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

const Promoted = (): JSX.Element => {
  return (
    <AppLayout>
      <Helmet>
        <title>Promoted</title>
      </Helmet>
      <Router>
        <AuthedRoute
          path="/promoted"
          component={PromotedOverview}
        />
        <AuthedRoute
          path="/promoted/my-promotions"
          component={PromotedMyPromotions}
        />
        <AuthedRoute
          path="/promoted/upcoming/postcodes"
          component={PromotedUpcoming}
        />
        <AuthedRoute
          path="/promoted/upcoming/payment"
          component={PromotedUpcoming}
        />
      </Router>
    </AppLayout>
  );
};

export default Promoted;

import { List, ListItemUnion } from 'generated-types';

import { ListAttachment, ListItem } from 'types/conversations.types';

import { ModalProps } from '../../../modal-dialogue.types';

export interface ListDetailsModalData {
  list: List | ListAttachment | null | undefined;
  isOpen: boolean;
  timezone: string;
  onClickClose?: () => void;
  title?: string;
}

export const isListItem = (item: ListItem | ListItemUnion): item is ListItem => {
  return item.__typename === 'ListItem';
};

export type ListDetailsModalProps  = ModalProps<ListDetailsModalData>;

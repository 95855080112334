import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import MyPlanPage from 'features/my-plan/my-plan';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

const MyPlan = (): JSX.Element => {
  return (
    <AppLayout>
      {/*
      // @ts-ignore */}
      <Helmet>
        <title>My Plan</title>
      </Helmet>
      <Router>
        <AuthedRoute
          path="/my-plan"
          component={MyPlanPage}
        />
      </Router>
    </AppLayout>
  );
};

export default MyPlan;

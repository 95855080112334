import styled from '@emotion/styled';
import { Flex, Text } from 'rebass';

import { ColourService } from 'lib';

import { PulseAnimation } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const TagSelectionWrapper = styled(Flex)`
  justify-content: flex-start;
  flex-flow: row wrap;
  width: 100%;
  background: ${colors.uiFills};
  border-radius: 3px;
  border: 2px solid ${colors.middleGrey};
  user-select: none;
  min-height: 50px;
  align-items: center;
  padding: 7px 0 0 7px;
`;

export const TagWrapper: any = styled(Flex)`
  ${textStyles.footnote};

  flex-direction: row;
  border-radius: 3px;
  align-items: center;
  height: 25px;
  margin: 0 7px 7px 0;


  ${(props: any): any => {
    const isLowContrast = !!props.colour ? ColourService.isLowContrast(props.colour) : false;

    return `
      border: 2px solid ${isLowContrast ? colors.floomMidnightBlue : props.colour};
      color: ${isLowContrast ? colors.floomMidnightBlue : colors.white};
      background: ${props.colour || colors.floomMidnightBlue};
    `;
  }}

  > p {
    margin: 7px;
  }
`;

export const TagDelete: any = styled.button`
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: auto;
  margin-right: 8px;

  > svg {
    path {
      ${(props: any): any => {
    const isLowContrast = !!props.colour ? ColourService.isLowContrast(props.colour) : false;

    return `
          fill: ${isLowContrast ? colors.floomMidnightBlue : colors.white};
        `;
  }}
    }
  }
`;

export const ActionButton = styled(Flex)`
  ${textStyles.footnote};

  flex-direction: row;
  border-radius: 3px;
  align-items: center;
  height: 25px;
  border: 2px solid ${colors.lightGrey};
  border-radius: 3px;
  cursor: pointer;
  padding-right: 8px;
  margin: 0 7px 7px 0;

  > p {
    margin: 7px;
  }
`;

export const Loading = styled(Text)<any>`
  ${PulseAnimation};
  ${textStyles.footnote};

  padding-left: 15px;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

export const TagsContainer: any = css`
  height: 100%;
`;

export const InputContainer: any = styled(Box)`
  position: absolute;
  bottom: 0;
  padding: 14px 0;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { borderRadius, breakpoints, colors, fontStacks, textStyles } from 'utils/rebass-theme';

const label = css`
  line-height: 1.2;
  font-family: ${fontStacks.secondary};
  font-weight: 700;
  color: ${colors.floomMidnightBlue};
`;

export const SupplierCardContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  position: sticky;
  top: 200px;
  z-index: 8;
  padding: 10px 16px;

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    top: 150px;
  }

  @media (max-height: 500px) {
    position: relative;
    top: 0;
  }
`;

export const SupplierTitle = styled(Box)`
  ${label};
  font-size: 17px;

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 9px;
  }
`;

export const SupplierDelivery = styled(Box)`
  font-size: 14px;
  background: ${colors.lightGrey};
  padding: 10px 16px;
  border-radius: ${borderRadius.large};
  color: ${colors.grey};

  @media (max-width: ${breakpoints.small}) {
    padding-left: 9px;
    padding-right: 9px;
    font-size: 13px;
    width: 100%;
  }
`;

export const PriceRange = styled.div`
  ${textStyles.body};
  color: ${colors.darkGrey};
  font-weight: 800;
  line-height: 1.2;
`;

export const DeliveryCutOff = styled.span<{ color?: string }>`
  font-weight: 800;
  color: ${({ color }) => color};
`;

export const DeliveryDate = styled.span`
  font-weight: 800;
`;

import {
  FC,
  Fragment,
  useState
} from 'react';

import ErrorMessage from 'components/error-message';

import CatalogListItemColours from '../catalog-list-item-colours/catalog-list-item-colours';

import {
  ListWrapper
} from './catalog-list-colours.styles';
import {
  CatalogListColoursProps
} from './catalog-list-colours.types';

export const CatalogListColours: FC<CatalogListColoursProps> = ({
  wrapperStyles,
  orientation,
  items,
  error,
  errorMessage,
  onChange,
  itemValueField,
  metadataField,
  selectedItems
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const getErrorMessage = (): string | undefined => {
    return error && isDirty ? errorMessage : '';
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, item: any): any => {
    if (item.isDisabled) {
      return event.preventDefault();
    }

    setIsDirty(true);

    return onChange(item[itemValueField]);
  };

  return (
    <Fragment>
      <ListWrapper
        wrapperStyles={wrapperStyles}
      >
        {
          items.map((item, i) => (
            <CatalogListItemColours
              item={item}
              orientation={orientation}
              selectedItems={selectedItems}
              onChange={handleOnChange}
              itemValueField={itemValueField}
              metadataField={metadataField}
              itemKey={`${item.value}-${i}`}
              key={`${item.value}-${i}`}
              isDirty={isDirty}
            />
          ))
        }
      </ListWrapper>
      <ErrorMessage
        errorMessage={getErrorMessage()}
      />
    </Fragment>
  );
};

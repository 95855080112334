import { Component, ReactNode } from 'react';

import { Order } from 'generated-types.d';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';

interface RecipientMetaProps {
  data: Order;
}

class RecipientMeta extends Component<RecipientMetaProps> {
  render(): ReactNode {
    return (
      <MetaCardContentWrapper iconName="person">
        {this.props.data.shippingAddress?.recipientFullName}
      </MetaCardContentWrapper>
    );
  }
}

export default RecipientMeta;

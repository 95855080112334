import { MERCHANT_WHOLESALE_SUPPLIERS } from 'lib/graphql/queries/wholesale';

import {
  MerchantWholesaleSupplier,
  MerchantWholesaleSuppliersWhereInput
} from 'generated-types.d';

import { GraphQL } from 'lib';

export default class WholesaleShopApiService {
  public static fetchSupplierDataForMerchant = async (merchantId?: string): Promise<MerchantWholesaleSupplier[]> => {
    if (!merchantId) {
      return [];
    }

    const where: MerchantWholesaleSuppliersWhereInput = {
      merchant: {
        id: merchantId
      }
    };

    try {
      const result = await GraphQL.query<{
        merchantWholesaleSuppliers: MerchantWholesaleSupplier[];
      }>(MERCHANT_WHOLESALE_SUPPLIERS, { where });

      return result.data.merchantWholesaleSuppliers;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

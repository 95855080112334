import { FC, Fragment } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  ListItemType,
  ListItemUnion,
  WholesaleOrderLineItem
} from 'generated-types.d';

import DraftWholesaleOrderStore from 'stores/wholesale/draft-wholesale-order-store/draft-wholesale-order-store';

import {
  ListHeadings
} from 'features/lists/components/list-headings/list-headings';
import {  } from 'features/lists/components/single-list/components/single-list-body/single-list-item/single-list-item.styles';
import {
  getMedia,
  groupListItemsByType,
  listItemMediaImage
} from 'features/lists/lists.helpers';
import {
  Columns,
  ItemDetails,
  ItemTitle,
  ListGroupNameHeading,
  ListHeadingsWrapper,
  ListItemWrapper,
  ListScrollWrapper,
  MetaColumn,
  Thumbnail
} from 'features/lists/lists.styles';
import { ListFieldConfig } from 'features/lists/lists.types';

import Icon from 'components/icon';

import {
  MANAGE_ORDER_LIST_FIELD_CONFIG
} from '../manage-order.config';

import { ManageOrderItemDelete } from './manage-order-item-delete';

export const ManageOrderItems: FC<{
  draftWholesaleOrdersStore?: DraftWholesaleOrderStore;
}> = inject((stores: FxStores) => ({
  draftWholesaleOrdersStore: stores.draftWholesaleOrderStore
}))(observer(({
  draftWholesaleOrdersStore
}) => {
  if (!draftWholesaleOrdersStore!.selectedOrder) return null;

  const fieldConfig = MANAGE_ORDER_LIST_FIELD_CONFIG.Default;

  if (!fieldConfig) return null;

  const groupedItems = groupListItemsByType<WholesaleOrderLineItem>(
    draftWholesaleOrdersStore!.selectedOrder.items,
    item => item.listItem!
  );

  const shouldDisplayColumn = (
    field: ListFieldConfig<WholesaleOrderLineItem>,
    listItem?: ListItemUnion
  ): boolean => {
    return !field.supportedTypes?.length || (!!listItem?.type && !!field.supportedTypes.includes(listItem.type));
  };

  return (
    <Fragment>
      {
        Object.keys(groupedItems).map(key => {
          const listItemType = key as ListItemType;
          const config = groupedItems[listItemType];

          if (!config?.items?.length) return null;

          return (
            <Box
              mb={20}
              key={config.title}
            >
              <ListGroupNameHeading>
                {config.title}
              </ListGroupNameHeading>
              <Box
                css={css`
                  position: relative;
                `}
              >
                <ListScrollWrapper>
                  <ListHeadingsWrapper>
                    <ListHeadings
                      listType="Default"
                      categoryScope={listItemType}
                      config={MANAGE_ORDER_LIST_FIELD_CONFIG}
                    />
                  </ListHeadingsWrapper>
                  <Box>
                    {config.items?.map?.((lineItem: WholesaleOrderLineItem) => {
                      return (
                        <ListItemWrapper key={lineItem.id}>
                          <Thumbnail
                            imageUrl={listItemMediaImage(getMedia(lineItem!.listItem!), true)}
                          >
                            {lineItem?.listItem?.type === 'Flower' && (
                              <Icon iconName="flower-squiggle" />
                            )}
                          </Thumbnail>
                          <Columns>
                            <ItemDetails>
                              <ItemTitle>
                                <Box flex="1">
                                  {fieldConfig.titleField.renderValue?.(lineItem)}
                                </Box>
                              </ItemTitle>
                              {fieldConfig.fields?.map((field, fieldIndex) => {
                                if (!shouldDisplayColumn(field, lineItem.listItem!)) return null;

                                return (
                                  <MetaColumn
                                    key={`${field.key}-${fieldIndex}`}
                                    customStyles={field.customStyles}
                                    isMetadata={false}
                                  >
                                    {field.renderValue?.(lineItem)}
                                  </MetaColumn>
                                );
                              })}
                            </ItemDetails>
                          </Columns>
                          <ManageOrderItemDelete item={lineItem} />
                        </ListItemWrapper>
                      );
                    })}
                  </Box>
                </ListScrollWrapper>
              </Box>
            </Box>
          );
        })
      }
    </Fragment>
  );
}));

import { FC, useMemo } from 'react';

import { observer, inject } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { MerchantStage, SubscriptionStatus } from 'generated-types.d';

import { LocalisationService } from 'lib';

import MerchantDetailStore from 'stores/merchant-detail/merchant-detail-store';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import {
  IconBox,
  MetaSubCopy
} from '../merchant-detail-content.styles';

import SubscriptionCompletionButton from './subscription-completion-button';
import { SubscriptionResetButton } from './subscription-reset-button';

interface SubscriptionStatusProps {
  onCompleteSubscription: () => void;
  merchantDetailStore?: MerchantDetailStore;
}

const SubscriptionStatusDetail: FC<SubscriptionStatusProps> = ({
  onCompleteSubscription,
  merchantDetailStore
}) => {
  const merchant = merchantDetailStore!.selectedMerchant;

  const canResetMerchantSubscription = useMemo((): boolean => {
    const hasResettableSubscriptionStatus = [
      SubscriptionStatus.Unsubscribed,
      SubscriptionStatus.Canceled,
      SubscriptionStatus.IncompleteExpired
    ].includes(merchant?.subscription?.status!);

    const hasResettableStage = [
      MerchantStage.InSetup,
      MerchantStage.New,
      MerchantStage.Dormant,
      MerchantStage.Churn,
      MerchantStage.Deactivated,
      MerchantStage.Unassigned
    ].includes(merchant?.stage!);

    return hasResettableSubscriptionStatus && hasResettableStage;
  }, [
    merchant?.subscription?.status,
    merchant?.stage
  ]);

  const canSubscribeMerchant = useMemo((): boolean => {
    const hasSubscriptionId = !!merchant?.subscription?.stripeSubscriptionId;
    const hasUnsubscribedSubscription = [
      SubscriptionStatus.Unsubscribed,
      SubscriptionStatus.Canceled
    ].includes(merchant?.subscription?.status!);

    return !hasSubscriptionId && hasUnsubscribedSubscription;
  }, [
    merchant?.subscription?.stripeSubscriptionId,
    merchant?.subscription?.status
  ]);

  if (!merchant?.subscription?.status) return null;

  const subscriptionCopy = LocalisationService.localiseSubscriptionStatus(merchant.subscription.status);

  const SubscriptionAction: FC = () => {
    switch (true) {
      case canSubscribeMerchant: {
        return (
          <SubscriptionCompletionButton
            onClick={onCompleteSubscription}
          />
        );
      }

      case canResetMerchantSubscription: {
        return (
          <SubscriptionResetButton />
        );
      }

      default:
        return null;
    }
  };

  return (
    <Flex mt="20px">
      <IconBox>
        <Icon
          iconName="qr-code"
          pathFill={colors.shade40}
        />
      </IconBox>
      <Box>
        <Box
          mt="2px"
          mb="-2px"
        >
          <strong>Subscription status:</strong> {subscriptionCopy.title}
        </Box>
        <MetaSubCopy>
          {subscriptionCopy.reason}
        </MetaSubCopy>
        <SubscriptionAction />
      </Box>
    </Flex>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantDetailStore: stores.merchantDetailStore
}))(observer(SubscriptionStatusDetail));

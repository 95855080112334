import styled from '@emotion/styled';
import { Box } from 'rebass';

import { breakpoints, colors, fontSizes, space } from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  background-color: ${colors.white};
  border: 1px solid ${colors.floomMidnightBlue};

  @media only screen and (max-width: ${breakpoints.small}) {
    border: none;
  }
`;

export const SearchResults = styled.ul<{ height: number | null }>`
  list-style: none;
  width: 100%;
  height: 100%;
  max-height: ${({ height }) => (height ? `${height - 18}px` : 'auto')};
  overflow: auto;
`;

export const NoResult = styled.div`
  color: ${colors.middleGrey};
  text-align: center;
  font-size: ${fontSizes.small}px;
  padding: ${space[3]}px;
  border-top: 1px solid ${colors.middleGrey};
`;

export const AddCustomItem = styled(Box)`
  padding: ${space[3]}px;
  font-size: ${fontSizes.small}px;
  position: sticky;
  bottom: 0;
  margin-top: auto;
  background: ${colors.uiFills};
  border-top: 1px solid ${colors.middleGrey};
  line-height: 2;
  width: 100%;
  text-align: left;

  span {
    color: ${colors.floomMidnightBlue};
    margin-left: ${space[2]}px;
  }
`;

export const HeaderWrapper = styled(Box)`
  border-top: 1px solid ${colors.middleGrey};

  @media only screen and (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

import React, { Component, ReactNode } from 'react';

import { colors } from 'utils/rebass-theme';

import * as Styles from './styled-link.styles';
import * as Types from './styled-link.types';

export default class StyledLink extends Component<Types.StyledLinkProps> {
  static defaultProps = {
    color: colors.floomMidnightBlue,
    isDisabled: false
  };

  render(): ReactNode {
    return(
      <Styles.LinkWrapper
        as="span"
        color={this.props.color}
        isDisabled={this.props.isDisabled}
      >
        {this.props.children}
      </Styles.LinkWrapper>
    );
  }
}

export enum OnboardingStage {
  Home = 'home',
  StripeConnect = 'stripe-connect',
  BankDetails = 'bank-details',
  Subscription = 'subscription'
}

export interface OnboardingStep {
  stage: OnboardingStage;
  title: string;
  isRequired: boolean;
  isComplete: boolean;
  navigateToStep: () => void;
}

import { gql } from '@apollo/client';

export const PROMOTED_LISTS = gql`
  query Lists($where: ListWhereInput) {
    promotedLists(first: 1, where: $where) {
      id
      title
      type
      suppliers {
        id
      }

      items(first: 100) {
        ... on ListItem {
          __typename
          id
          type
          sku
          vbn
          quantity
          maxUnitPrice
          catalogItem {
            media(first: 10, orderBy: createdAt_DESC) {
              id
              src
            }
          }
          promotedTradeSkuAvailability(orderBy: multiplesOf_ASC) {
            unitStock
            supplierProductId
            multiplesOf
            quantityInPackage
            maximumPurchaseQuantity
            isLive
            grower {
              name
              countryCode
            }
            price {
              currency
              price
            }
            media {
              id
              src
            }
          }
        }

        ... on ListItemTypeFlower {
          headNumber
          minimumHeadSize
          maximumHeadSize
          minimumStemLength
          stemLengthUnit
          headSizeUnit
          height
          weight
          maturity
          potSize
        }

        ... on ListItemTypeSundry {
          height
          weight
        }

        ... on ListItemTypePlant {
          minimumHeadSize
          maximumHeadSize
          headSizeUnit
          height
          weight
          maturity
          diameter
          thickness
          potSize
        }
      }
    }
  }
`;

import { ApolloQueryResult } from '@apollo/client';
import { splitSearchableValue } from 'lib/helpers/graphql/graphql-helpers';
import store from 'stores';

import {
  DiscountConnection,
  DiscountCreateInput,
  Discount,
  Channel,
  DiscountMetric,
  Product,
  DiscountUpdateInput,
  DiscountWhereUniqueInput,
  QueryDiscountsArgs
} from 'generated-types.d';

import { GraphQL, ProductService, PermissionsService } from 'lib';

import { DiscountType, DiscountFormSchema, DiscountApplication } from 'stores/discount-create-edit/discount-create-edit-store.types';
import UserStore from 'stores/user/user-store';

import {
  CREATE_DISCOUNT,
  UPDATE_DISCOUNT
} from 'features/settings/graphql/mutators/discount.mutators';
import {
  DISCOUNTS,
  SINGLE_DISCOUNT,
  DISCOUNT_ANALYTICS
} from 'features/settings/graphql/queries/discount.queries';

export default class DiscountsService {
  private userStore = store.userStore as UserStore;

  public paginateDiscounts = async (): Promise<any> => {
    store.discountsStore.onPaginate();

    return GraphQL.query(DISCOUNTS, this.buildListVariables())
      .then((result: ApolloQueryResult<{ discountsConnection: DiscountConnection }>) => {
        store.discountsStore.handlePaginationResult(result.data.discountsConnection);
        store.discountsStore.setListLoading(false);

        return result.data.discountsConnection;
      }).catch((error: any) => {
        window.Sentry.captureException(error);
        store.toasterStore.popErrorToast('discounts', 'retrieve');
        store.discountsStore.setListLoading(false);

        return Promise.reject(error);
      });
  };

  public fetchDiscounts = async (): Promise<any> => {
    return GraphQL.query(DISCOUNTS, this.buildListVariables())
      .then((result: ApolloQueryResult<{ discountsConnection: DiscountConnection }>) => {
        store.discountsStore.setDiscounts(result.data.discountsConnection);

        return result.data.discountsConnection;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        store.toasterStore.popErrorToast('discounts', 'retrieve');

        return Promise.reject(error);
      });
  };

  public fetchDiscount = async (id: string): Promise<any> => {
    const discountWhere: DiscountWhereUniqueInput = { id };

    if (!PermissionsService.isInternalRole()) {
      discountWhere.merchant = {
        id: store.merchantStore.merchant?.id
      };
    }

    return GraphQL.query(SINGLE_DISCOUNT, { where: discountWhere })
      .then((result: ApolloQueryResult<{ discount: Discount }>) => {
        if (!!result.data.discount) {
          store.discountCreateEditStore.populateDiscountForm(result.data.discount);
        }

        store.discountCreateEditStore.setDiscountLoading(false);

        return result.data.discount;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        store.discountCreateEditStore.setDiscountLoading(false);
        store.toasterStore.popErrorToast('discount', 'retrieve');

        return Promise.reject(error);
      });
  };

  public fetchDiscountAnalytics = async (discount: Discount): Promise<any> => {
    const discountWhere: DiscountWhereUniqueInput = {
      id: discount.id,
      merchant: {
        id: discount.merchant!.id
      }
    };

    return GraphQL.query(DISCOUNT_ANALYTICS, { where: discountWhere })
      .then((result: ApolloQueryResult<{ discount: Pick<Discount, 'totalRevenue' | 'amountDiscounted'> }>) => {
        return result.data.discount;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        store.toasterStore.popErrorToast('analytics for this discount', 'retrieve');

        return Promise.reject(error);
      });
  };

  public fetchDiscountProductMetadata = async (): Promise<void> => {
    store.discountCreateEditStore!.setProductMetadataLoading(true);

    try {
      const productTypes = await ProductService.fetchProductTypes();
      const productCategories = await ProductService.fetchProductCategories();

      store.discountCreateEditStore!.setProductMetadata(productTypes, productCategories);
    } catch (error) {
      store.toasterStore.popErrorToast('product types and categories for your discounts', 'retrieve');
    }
  };

  public fetchProducts = async (
    searchValue: string,
    merchantId?: string
  ): Promise<Product[]> => {
    store.discountCreateEditStore!.setProductsLoading(true);

    const channel = store.discountCreateEditStore!.importedDiscount?.channels?.[0]?.channel || store.discountCreateEditStore!.formValues.channel;

    try {
      const productEdges = await ProductService.fetchAllProducts({
        searchable_contains: searchValue,
        channels_some: {
          channel
        },
        ...(!!merchantId ? {
          merchant: {
            id: merchantId
          }
        } : {})
      });

      const products: Product[] = productEdges.map(product => product.node);

      store.discountCreateEditStore!.setProducts(productEdges.map(product => product.node));

      return products;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public createDiscount = async (merchantId: string): Promise<Discount> => {
    const vars: { data: DiscountCreateInput } = { data: this.buildDiscountCreateInput(merchantId) };

    return GraphQL.query(CREATE_DISCOUNT, vars)
      .then((result: ApolloQueryResult<{ createDiscount: Discount }>) => {
        return result.data.createDiscount;
      })
      .catch(error => {
        window.Sentry.captureException(error);

        return Promise.reject(error);
      });
  };

  public updateDiscount = async (): Promise<Discount> => {
    const vars: { data: DiscountUpdateInput; where: DiscountWhereUniqueInput } = {
      data: this.buildDiscountUpdateInput(),
      where: {
        id: store.discountCreateEditStore.importedDiscount!.id
      }
    };

    return GraphQL.query(UPDATE_DISCOUNT, vars)
      .then((result: ApolloQueryResult<{ updateDiscount: Discount }>) => result.data.updateDiscount)
      .catch(error => {
        window.Sentry.captureException(error);

        return Promise.reject(error);
      });
  };

  public toggleIsActive = async (discountId: string, isActive: boolean): Promise<Discount> => {
    const vars: { data: DiscountUpdateInput; where: DiscountWhereUniqueInput } = {
      data: { isActive },
      where: {
        id: discountId
      }
    };

    return GraphQL.query(UPDATE_DISCOUNT, vars)
      .then((result: ApolloQueryResult<{ updateDiscount: Discount }>) => result.data.updateDiscount)
      .catch(error => {
        window.Sentry.captureException(error);

        return Promise.reject(error);
      });
  };

  private buildListVariables = (): QueryDiscountsArgs => {
    const merchantId = store.merchantStore.merchant?.id;
    const channels: Channel[] = !!merchantId ? [Channel.Website] : [Channel.Floom, Channel.Website];

    return {
      skip: store.discountsStore.skipCount,
      first: store.discountsStore.listCount,
      orderBy: store.discountsStore.sortValue,
      where: {
        deletedAt: null,
        channels_some: {
          channel_in: channels
        },
        ...(!store.discountsStore.isShowingInactiveDiscounts ? {
          isActive: true
        } : {}),
        ...(!!store.merchantStore.merchant?.id ? {
          merchant: {
            id: store.merchantStore.merchant.id
          }
        } : {}),
        AND: splitSearchableValue(store.discountsStore.searchValue).map(value => {
          return {
            searchable_contains: value
          };
        })
      }
    };
  };

  private buildDiscountUpdateInput = (): DiscountUpdateInput => {
    const { formValues: data } = store.discountCreateEditStore;

    return {
      ...this.buildCoreDiscountData(data),
      productCategories: { set: [] },
      productTypes: { set: [] },
      selectedProducts: { set: [] },
      ...this.buildDiscountApplicationData(data, 'edit') as DiscountUpdateInput
    };
  };

  private buildDiscountCreateInput = (merchantId?: string): DiscountCreateInput => {
    const { formValues: data } = store.discountCreateEditStore;

    return {
      ...this.buildCoreDiscountData(data) as DiscountCreateInput,
      ...this.buildDiscountApplicationData(data, 'create') as DiscountCreateInput,
      ...(!!merchantId ? {
        merchant: {
          connect: {
            id: merchantId
          }
        }
      } : {}),
      channels: {
        connect: [{
          channel: data.channel
        }]
      }
    };
  };

  private buildDiscountApplicationData = (data: DiscountFormSchema, formType: 'create' | 'edit'): DiscountCreateInput | DiscountUpdateInput => {
    const assignmentProp: ('connect' | 'set') = formType === 'create'
      ? 'connect'
      : 'set';

    switch (data.appliesTo) {
      case DiscountApplication.EntireOrder:

      default:
        return {};

      case DiscountApplication.ProductCategories:
        return {
          productCategories: {
            [assignmentProp]: data.productCategories.map(category => ({
              slug: category.slug
            }))
          }
        };

      case DiscountApplication.ProductTypes:
        return {
          productTypes: {
            [assignmentProp]: data.productTypes.map(type => ({
              slug: type.slug
            }))
          }
        };

      case DiscountApplication.Products:
        return {
          selectedProducts: {
            [assignmentProp]: data.selectedProducts.map(product => ({
              id: product.id
            }))
          }
        };
    }
  };

  private buildCoreDiscountData = (data: DiscountFormSchema): DiscountCreateInput | DiscountUpdateInput => {
    const isFreeShipping = data.discountType === DiscountType.FreeShipping;

    return {
      code: data.code,
      description: data.description,
      isActive: data.isActive,
      isOncePerOrder: isFreeShipping ? null : data.oncePerOrder,
      isFreeShipping: isFreeShipping,
      amount: this.defineAmount(data.discountType),
      amountMetric: this.defineAmountMetric(data.discountType),
      usageLimit: parseInt(data.usageLimit) || null,
      minBasketValue: parseInt(data.minBasketValue),
      startsAt: data.startsAt || undefined,
      endsAt: data.endsAt || undefined,
      perUserLimit: data.perUserLimit ? 1 : 0
    };
  };

  private defineAmount = (discountType: DiscountType): number | null => {
    return discountType === DiscountType.FreeShipping
      ? null
      : parseFloat(store.discountCreateEditStore.formValues.amount);
  };

  private defineAmountMetric = (discountType: DiscountType): DiscountMetric | null => {
    if (discountType === DiscountType.FreeShipping) return null;

    return discountType as any;
  };
}

import React, { Component } from 'react';

import _startCase from 'lodash.startcase';
import { inject, observer } from 'mobx-react';

import { FilterTabs } from './components/filter-tabs';
import * as Types from './order-filters.types';

class OrderListFilters extends Component<Types.OrderFiltersProps> {
  render(): React.ReactNode | null {
    return (
      <FilterTabs
        isVisible={this.props.ordersStore!.areFiltersVisible}
        onSelect={this.props.onSelect}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  merchantStore: stores.merchantStore
}))(observer(OrderListFilters));

import { OrderDeliveryState } from 'generated-types';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import CourierDeliveryTimeMeta from 'components/item-meta/courier-delivery-time-meta';
import LocationMeta from 'components/item-meta/location-meta';
import MerchantTitleMeta from 'components/item-meta/merchant-title-meta';
import OrderTitleMeta from 'components/item-meta/order-title-meta';
import PickupTimeMeta from 'components/item-meta/pickup-time-meta';
import RecipientMeta from 'components/item-meta/recipient-meta';

export type CourierBookingMetaColumn =
  | 'merchantTitle'
  | 'location'
  | 'recipient'
  | 'pickupDate'
  | 'deliveryDate'
  | 'orderTitle';

/**
 * The configuration for the available items on a courier booking. Can be customised
 * on a delivery status basis, in any order.
 */
export const CourierBookingMetaItem: { [key in CourierBookingMetaColumn]: any } = {
  orderTitle: {
    component: OrderTitleMeta,
    dataKey: 'orderTitle'
  },
  merchantTitle: {
    component: MerchantTitleMeta,
    dataKey: 'merchantTitle'
  },
  location: {
    component: LocationMeta,
    dataKey: 'location'
  },
  recipient: {
    component: RecipientMeta,
    dataKey: 'recipient'
  },
  pickupDate: {
    component: PickupTimeMeta,
    dataKey: 'pickupDate'
  },
  deliveryDate: {
    component: CourierDeliveryTimeMeta,
    dataKey: 'deliveryDate'
  }
};

export interface CourierBookingDropdownActionType {
  title: string;
  slug: string;
}

export enum CourierBookingDropdownActionSlug {
  Cancel = 'cancel',
  ReportIssue = 'reportIssue'
}

export const CourierBookingDropdownOptionList: CourierBookingDropdownActionType[] = [
  {
    title: 'Cancel',
    slug: CourierBookingDropdownActionSlug.Cancel
  },
  {
    title: 'Report an issue',
    slug: CourierBookingDropdownActionSlug.ReportIssue
  }
];

export interface CourierBookingStatusConfigType {
  color: ColourOption;
  labelColor: ColourOption;
  headingBg: ColourOption;
  statusActions: CourierBookingDropdownActionType[];
  metaItem: CourierBookingMetaColumn[];
}

export const statusConfig: { [key in OrderDeliveryState]: CourierBookingStatusConfigType } = {
  Booked: {
    color: ColourOption.floomBlue,
    labelColor: ColourOption.shade40,
    headingBg: ColourOption.lightGrey,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'pickupDate'
    ]
  },
  None: {
    color: ColourOption.floomBlue,
    labelColor: ColourOption.shade40,
    headingBg: ColourOption.lightGrey,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'pickupDate'
    ]
  },
  Requested: {
    color: ColourOption.floomBlue,
    labelColor: ColourOption.shade40,
    headingBg: ColourOption.lightGrey,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'pickupDate'
    ]
  },
  AssignedDriver: {
    color: ColourOption.floomBlue,
    labelColor: ColourOption.shade40,
    headingBg: ColourOption.lightGrey,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'pickupDate'
    ]
  },
  PickedUp: {
    color: ColourOption.floomBlue,
    labelColor: ColourOption.shade40,
    headingBg: ColourOption.lightGrey,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'deliveryDate'
    ]
  },
  OutForDelivery: {
    color: ColourOption.floomBlue,
    labelColor: ColourOption.shade40,
    headingBg: ColourOption.lightGrey,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'deliveryDate'
    ]
  },
  Delivered: {
    color: ColourOption.emeral,
    labelColor: ColourOption.emeral,
    headingBg: ColourOption.validationBg,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'deliveryDate'
    ]
  },
  Cancelled: {
    color: ColourOption.errorText,
    labelColor: ColourOption.errorText,
    headingBg: ColourOption.errorBg,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'pickupDate'
    ]
  },
  Failed: {
    color: ColourOption.errorText,
    labelColor: ColourOption.errorText,
    headingBg: ColourOption.errorBg,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'deliveryDate'
    ]
  },
  Refused: {
    color: ColourOption.errorText,
    labelColor: ColourOption.errorText,
    headingBg: ColourOption.errorBg,
    statusActions: CourierBookingDropdownOptionList,
    metaItem: [
      'orderTitle',
      'merchantTitle',
      'location',
      'recipient',
      'pickupDate'
    ]
  }
};

import { ApolloQueryResult } from '@apollo/client';
import camelCase from 'lodash.camelcase';
import store from 'stores';

import {
  ProductType,
  ProductCategory,
  ChannelType,
  ProductConnection,
  PlanFeature
} from 'generated-types.d';

import { MerchantService, ProductService } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import ProductsStore, {
  ProductListLayout,
  ProductGroupOption,
  FilterType,
  ProductFilter,
  Metadata,
  ProductStockGroup
} from 'stores/products-store';
import UserStore from 'stores/user/user-store';

import { ProductQuickEditData } from 'features/products/products.types';

import ProductAPIService from '../product-api/product-api.service';

export default class ProductListApolloService {
  private productsStore = store.productsStore as ProductsStore;

  private userStore = store.userStore as UserStore;

  private merchantStore = store.merchantStore as MerchantStore;

  public init = (): void => {
    this.fetchAllProducts();
    this.fetchProductCategories();
  };

  public updateSearchValue = (searchValue: string): void => {
    this.productsStore.setSearch(searchValue);
  };

  public updateSelectedTypes = (value: string): void => {
    this.setSelectedFilters(value, 'types');
    this.fetchAllProducts();
  };

  public updateSelectedCategories = (value: string): void => {
    this.setSelectedFilters(value, 'categories');
    this.fetchAllProducts();
  };

  public updateSelectedChannels = (value: string): void => {
    this.setSelectedFilters(value, 'channels');
    this.fetchAllProducts();
  };

  public groupProducts = (groupType: ProductGroupOption): void => {
    this.productsStore.setGrouping(groupType);
    this.productsStore.resetProductList();
    this.fetchAllProducts();
  };

  public updateOrdering = (orderType: string): void => {
    this.productsStore.setProductOrdering(orderType);
    this.fetchAllProducts();
  };

  public toggleInactiveProducts = (value: boolean = !this.productsStore.showInactiveProducts): void => {
    this.productsStore.toggleInactiveProducts(value);
    this.fetchAllProducts();
  };

  public updateLayout = (layoutType: ProductListLayout): void => {
    this.productsStore.setLayout(layoutType);
  };

  public loadMoreProducts = (groupName: any): void => {
    this.productsStore.onLoadMore(groupName);
    this.paginateProducts(groupName);
  };

  public resetFilters = (): void => {
    this.productsStore.setProductFilters([], 'types');
    this.productsStore.setProductFilters([], 'channels');
    this.productsStore.setProductFilters([], 'categories');
    this.fetchAllProducts();
  };

  public fetchAllProducts = async (): Promise<void> => {
    this.productsStore.resetPagination();
    this.productsStore.setLoading();
    this.fetchProductCategoriesCount();
    this.fetchProductChannelsCount();
    this.fetchProductTypesCount();

    await ProductAPIService.fetchProducts(this.buildVariables())
      .then(({ data }: ApolloQueryResult<any>) => {
        this.productsStore.updateProducts(data);
      });

    this.fetchAllProductsMeta();
  };

  public fetchAllProductsMeta = (): void => {
    ProductAPIService.fetchProductsMeta()
      .then(data => {
        const count = this.buildMetadataCount(data);
        this.productsStore.updateProductMetadata({ ...count });
      });
  };

  public paginateProducts = (groupOption: ProductGroupOption): void => {
    this.productsStore.setLoading();

    ProductAPIService.paginateProducts(this.buildVariables(), groupOption, this.productsStore.currentGroup)
      .then(({ data }: ApolloQueryResult<any>) => {
        this.productsStore.appendProducts(data.productsConnection, groupOption);
      });
  };

  public quickEditProductVariation = async (variables: ProductQuickEditData): Promise<any> => {
    try {
      return await ProductService.editProduct({ id: variables.productId }, {
        variations: {
          update: [{
            where: {
              id: variables.variationId
            },
            data: {
              stock: variables.stock,
              price: variables.price
            }
          }]
        }
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  private setSelectedFilters = (filter: any, filterType: FilterType): void => {
    // @ts-ignore
    if (this.productsStore.filters[filterType].includes(filter)) {
      return this.productsStore
        .setProductFilters(this.productsStore.filters[filterType].filter((currFilter: string) => {
          return currFilter !== filter;
        }), filterType);
    }

    return this.productsStore.setProductFilters([...this.productsStore.filters[filterType], filter], filterType);
  };

  public fetchProductTypes = (): void => {
    if (!!this.productsStore.productTypes.length) {
      this.fetchProductTypesCount();
    } else {
      ProductService.fetchProductTypes()
        .then((productTypes: ProductType[]) => {
          this.productsStore.setProductTypes(productTypes);
          this.fetchProductTypesCount();
        });
    }
  };

  private fetchProductCategories = (): void => {
    if (!!this.productsStore.productCategories.length) {
      this.fetchProductCategoriesCount();
    } else {
      ProductService.fetchProductCategories()
        .then((productCategories: ProductCategory[]) => {
          this.productsStore.setProductCategories(productCategories);
          this.fetchProductCategoriesCount();
        });
    }
  };

  private buildMetadataCount = (data: { [key in ProductStockGroup]: ProductConnection }): Metadata => {
    const keys = Object.keys(data) as ProductStockGroup[];

    const count = keys.reduce((acc: Metadata['count'], key: ProductStockGroup) => ({
      ...acc,
      [key]: data[key].aggregate.count
    }), {} as Metadata['count']);

    return { count };
  };

  private fetchProductChannelsCount = (): void => {
    if (this.productsStore.productChannels.length !== 0) {
      ProductAPIService.fetchProductChannelsCount(this.buildVariables(), this.productsStore.productChannels)
        .then((productChannelsCount: ApolloQueryResult<ChannelType[]>) => {
          this.setProductChannelsCount(productChannelsCount);
        });
    }
  };

  private fetchProductCategoriesCount = (): void => {
    if (this.productsStore.productCategories.length !== 0) {
      ProductAPIService.fetchProductCategoriesCount(this.buildVariables(), this.productsStore.productCategories)
        .then((productCategoriesCount: ApolloQueryResult<ProductCategory[]>) => {
          this.setProductCategoriesCount(productCategoriesCount);
        });
    }
  };

  private fetchProductTypesCount = (): void => {
    if (this.productsStore.productTypes.length !== 0) {
      ProductAPIService.fetchProductTypesCount(this.buildVariables(), this.productsStore.productTypes)
        .then((productTypesCount: ApolloQueryResult<ProductCategory[]>) => {
          this.setProductTypesCount(productTypesCount);
        });
    }
  };

  private setProductCategoriesCount = async (productCategoriesCount: any): Promise<void> => {
    const mapProductCategories = (): any => this.productsStore.productCategories
      .map((category: ProductFilter) => ({
        ...category,
        count: productCategoriesCount[camelCase(category.slug)].aggregate.count
      }));
    this.productsStore.setProductCategories(mapProductCategories());
  };

  private setProductChannelsCount = async (productChannelsCount: any): Promise<void> => {
    const mapProductChannels = (): any => this.productsStore.productChannels
      .map((channel: any) => ({
        ...channel,
        count: productChannelsCount[camelCase(channel.slug)].aggregate.count
      }));
    this.productsStore.setProductChannels(mapProductChannels());
  };

  private setProductTypesCount = async (productTypesCount: any): Promise<void> => {
    const mapProductTypes = (): any => this.productsStore.productTypes
      .map((type: ProductType) => ({
        ...type,
        count: productTypesCount[camelCase(type.slug)].aggregate.count
      }));

    this.productsStore.setProductTypes(mapProductTypes());
  };

  private buildVariables = (): Record<string, any> => ({
    selectedTypes: this.productsStore.filters.types,
    selectedChannels: this.productsStore.filters.channels,
    selectedCategories: this.productsStore.filters.categories,
    search: this.productsStore.searchValue.trim().toLowerCase() || '',
    orderBy: this.productsStore.orderBy,
    showActiveOnly: !this.productsStore.showInactiveProducts,
    first: this.productsStore.loadIncrement,
    skip: this.productsStore.count,
    merchantId: this.userStore.merchantId,
    isCollectionMerchant: MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.merchantStore.merchant)
  });
}

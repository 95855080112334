import { ApolloError } from '@apollo/client';
import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import stores from 'stores';

import {
  WholesaleOrderStates,
  WholesaleOrderConnection,
  WholesaleOrder,
  WholesalePreOrderStatus,
  WholesalePreOrderConnection
} from 'generated-types.d';

import { CacheService } from 'lib';

import { OrdersListLayout } from 'stores/orders/orders-store.types';

import {
  WholesaleCheckoutHelpers
} from 'features/wholesale/helpers/wholesale-helpers';
import {
  ORDER_LIST_ITERATION_COUNT
} from 'features/wholesale/wholesale.constants';
import {
  WholesaleOrderGroups,
  WholesaleOrderCounts,
  AggregatedWholesaleOrderLineItem,
  AggregatedWholesaleOrder,
  WholesalePreOrderGroups,
  WholesalePreOrderCounts
} from 'features/wholesale/wholesale.types';

export default class WholesaleOrdersStore {
  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isLoadingOrderDetail: observable,
      hasError: observable,
      errorMsg: observable,
      orders: observable,
      preOrders: observable,
      openOrderLoading: observable,
      currentOpenOrders: observable,
      currentOpenOrdersItems: computed,
      isEdit: observable,
      timeStamp: observable,
      orderItemAggregates: observable,
      selectedOrder: observable.deep,
      orderPaginationCounts: observable,
      preOrderPaginationCounts: observable,
      isLoadingInline: observable,
      listLayoutType: observable,
      resetSelectedOrder: action,
      setSelectedOrder: action,
      resetOrdersStore: action,
      setCurrentOpenOrders: action,
      incrementOrderCount: action,
      incrementPreOrderCount: action,
      resetLoading: action,
      setLoading: action,
      setInlineLoading: action,
      resetError: action,
      loadingError: action,
      setOrders: action,
      setOpenOrderLoading: action,
      resetOpenOrderLoading: action,
      setLoadingOrderDetail: action,
      resetLoadingOrderDetail: action
    });
  }

  static resetOrderGroups = (): WholesaleOrderGroups => ({
    Draft: null,
    Open: null,
    Processed: null,
    Completed: null,
    Failed: null
  });

  static resetOrderCounts = (): WholesaleOrderCounts => ({
    Draft: 0,
    Open: 0,
    Processed: 0,
    Completed: 0,
    Failed: 0
  });

  static resetPreOrderGroups = (): WholesalePreOrderGroups => ({
    Open: null,
    Processing: null,
    Cancelled: null,
    Complete: null
  });

  static resetPreOrderCounts = (): WholesalePreOrderCounts => ({
    Open: 0,
    Processing: 0,
    Cancelled: 0,
    Complete: 0
  });

  public isLoading: boolean = true;

  public isLoadingOrderDetail: boolean = true;

  public openOrderLoading: boolean = true;

  public isLoadingInline: boolean = false;

  public selectedOrder: WholesaleOrder | null = null;

  public orderItemAggregates: AggregatedWholesaleOrderLineItem[] = [];

  public isEdit: boolean = false;

  public timeStamp: number = Date.now();

  public updateInterval: NodeJS.Timeout | null = null;

  public hasError: boolean = false;

  public errorMsg: ApolloError | boolean = false;

  public orders: WholesaleOrderGroups = WholesaleOrdersStore.resetOrderGroups();

  public preOrders: WholesalePreOrderGroups | { Merchant: WholesalePreOrderConnection | null } = WholesaleOrdersStore.resetPreOrderGroups();

  public orderPaginationCounts: WholesaleOrderCounts = WholesaleOrdersStore.resetOrderCounts();

  public preOrderPaginationCounts: WholesalePreOrderCounts = WholesaleOrdersStore.resetPreOrderCounts();

  public currentOpenOrders: WholesaleOrder[] = [];

  private populateListLayoutType = (): OrdersListLayout => {
    const cookie = CacheService.retrieveCookie('wholesaleOrderLayout');

    if (cookie?.content.name === 'wholesaleOrderLayout') {
      return cookie?.content.data;
    }

    return 'grid';
  };

  public listLayoutType: OrdersListLayout = this.populateListLayoutType();

  public setLayout(layoutOption: OrdersListLayout): void {
    CacheService.storeCookieObject({
      name: 'wholesaleOrderLayout',
      data: layoutOption
    });

    this.listLayoutType = layoutOption;
  }

  public updateSingleOrder = (order?: WholesaleOrder | null): void => {
    if (!order) return;

    const groupData = ((): { key: WholesaleOrderStates; index: number } | null => {
      for (const key of Object.keys(this.orders)) {
        const groupKey = key as WholesaleOrderStates;
        const orderIndex = this.orders[groupKey]?.edges?.findIndex?.(edge => edge?.node.id === order.id);

        if (!!orderIndex && orderIndex !== -1) {
          return {
            index: orderIndex,
            key: groupKey
          };
        }
      }

      return null;
    })();

    if (groupData && !!this.orders?.[groupData.key]?.edges?.[groupData.index]?.node?.id) {
      this.orders[groupData.key]!.edges[groupData.index]!.node = order;
    }
  };

  public resetOrdersStore = (): void => {
    this.orderPaginationCounts = WholesaleOrdersStore.resetOrderCounts();
    this.orders = WholesaleOrdersStore.resetOrderGroups();

    this.preOrderPaginationCounts = WholesaleOrdersStore.resetPreOrderCounts();
    this.preOrders = WholesaleOrdersStore.resetPreOrderGroups();
  };

  public incrementOrderCount = (group: WholesaleOrderStates): void => {
    this.orderPaginationCounts[group] = this.orderPaginationCounts[group] + ORDER_LIST_ITERATION_COUNT;
  };

  public incrementPreOrderCount = (group: WholesalePreOrderStatus): void => {
    this.preOrderPaginationCounts[group] = this.preOrderPaginationCounts[group] + ORDER_LIST_ITERATION_COUNT;
  };

  public resetLoading(): void {
    this.isLoading = false;
    this.isLoadingInline = false;
  }

  public setCurrentOpenOrders = (orders: WholesaleOrder[] | null): void => {
    this.currentOpenOrders = orders || [];
    this.setUpdateInterval(!!orders && !!orders.length);
  };

  public setOpenOrderLoading = (): void => {
    this.openOrderLoading = true;
  };

  public resetOpenOrderLoading = (): void => {
    this.openOrderLoading = false;
  };

  public setLoading(): void {
    this.resetError();
    this.isLoading = true;
  }

  public setLoadingOrderDetail(): void {
    this.resetError();
    this.isLoadingOrderDetail = true;
  }

  public resetLoadingOrderDetail(): void {
    this.resetError();
    this.isLoadingOrderDetail = false;
  }

  public setInlineLoading(): void {
    this.resetError();
    this.isLoadingInline = true;
  }

  public resetError(): void {
    this.hasError = false;
  }

  public loadingError(error: any): void {
    this.hasError = true;
    this.errorMsg = error;
  }

  public resetSelectedOrder = (): void => {
    this.selectedOrder = null;
    this.orderItemAggregates = [];
  };

  public setSelectedOrder(order: WholesaleOrder): void {
    this.selectedOrder = order;
    const supplierCancelTimeoutMinutes = stores!.wholesaleShopStore?.getCancellationWindow(order?.supplier?.id);
    this.orderItemAggregates = WholesaleCheckoutHelpers.setOrderItemAggregates(this.selectedOrder.items!, supplierCancelTimeoutMinutes);
  }

  public setOrders(orders: WholesaleOrderGroups): void {
    this.orders = orders;
  }

  public setPreOrders(preOrders: WholesalePreOrderGroups | { Merchant: WholesalePreOrderConnection | null }): void {
    this.preOrders = preOrders;
  }

  public appendOrders = (orders: WholesaleOrderConnection, orderState: WholesaleOrderStates): void => {
    this.orders[orderState]!.edges = [...this.orders[orderState]!.edges, ...orders.edges];
    this.orders[orderState]!.pageInfo.hasNextPage = orders.pageInfo.hasNextPage;
  };

  public appendPreOrders = (preOrders: WholesalePreOrderConnection, orderState: WholesalePreOrderStatus): void => {
    this.preOrders[orderState]!.edges = [...this.preOrders[orderState]!.edges, ...preOrders.edges];
    this.preOrders[orderState]!.pageInfo.hasNextPage = preOrders.pageInfo.hasNextPage;
  };

  public get currentOpenOrdersItems(): AggregatedWholesaleOrder[] {
    if (!this.currentOpenOrders || this.currentOpenOrders?.length === 0 || !this.timeStamp) {
      return [];
    }

    return this.currentOpenOrders.map(order => {
      if (!order.items || !order.supplier || !order.items.length) return null;

      const supplierCancelTimeoutMinutes = stores!.wholesaleShopStore?.getCancellationWindow(order.supplier.id);

      return {
        order: WholesaleCheckoutHelpers.setOrderItemAggregates(order?.items, supplierCancelTimeoutMinutes),
        currency: order?.merchant?.currency,
        orderId: order.id,
        supplier: order.supplier
      };
    }).filter(Boolean) as AggregatedWholesaleOrder[];
  }

  public getCurrentOrderId(supplierId: string): string | undefined {
    const currentOrder = this.currentOpenOrders?.find(order => order.supplier?.id === supplierId);

    return currentOrder ? currentOrder.id : undefined;
  }

  private setUpdateInterval(isSet: boolean): void {
    if (isSet && !this.updateInterval) {
      this.updateInterval = setInterval(() => {
        runInAction(() => {
          this.timeStamp = Date.now(); // needed for currentOpenOrderItems autoupdate
        });
      }, 3000);
    }

    if (!isSet && this.updateInterval) {
      clearInterval(this.updateInterval);
    }
  }
}

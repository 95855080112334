import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const ModalHeader = styled(Box)`
  flex: 2;
`;

export const ModalBody = styled(Box)`
  width: 100%;
  flex: 2;
  padding: 20px 0;
`;

export const ModalFooter = styled(Box)`
  flex: 1;
`;

export const ModalSuccessConfirmation = styled(Box)`
  flex: 1;
  align-items: center;
  display: flex;
`;

export const CourierDetailsBox = styled(Box)`
  width: 100%;
  padding: 15px 20px;
  margin: 10px 0;
  background-color: ${colors.middleGrey};
  width: 100%;
  border-radius: 2px;
`;

export const CourierDetailsBoxHeader = styled(Box)`
  ${textStyles.label};
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 10px;
`;

export const CourierDetailsBoxContent = styled(Box)`
  ${textStyles.subhead};
  text-align: left;
`;

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const OrderPauseBanner: any = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const OrderPauseNotification: any = styled(Box)`
  flex: 1;
  max-width: 28em;
`;

export const OrderPauseCheckbox: any = styled(Flex)`
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
`;

export const NotificationCopy: any = styled.span`
  button {
    color: ${colors.uiFills};
    text-decoration: underline;
  }
`;

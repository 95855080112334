import { create } from 'zustand';

import { Notification } from 'generated-types.d';

export type NotificationState = {
  notifications: Notification[];
  setNotifications: (notifications: Notification[]) => void;
  unreadNotificationsCount: () => number;
};

export const useNotificationStore = create<NotificationState>()((set, get) => ({
  notifications: [],
  setNotifications: (notifications: Notification[]) => set(() => ({
    notifications
  })),
  unreadNotificationsCount: () => get().notifications.filter(notification => !notification.readAt).length
}));

import { navigate } from '@reach/router';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './tile.styles';
import * as Types from './tile.types';

const Tile = ({ copy, iconType, url, enabled }: Types.TileProps): JSX.Element => {
  const onClick = (): void => {
    navigate(url);
  };

  return (
    <Styles.TileWrapper
      onClick={onClick}
      disabled={!enabled}
    >
      <Icon
        iconName={iconType}
        styles={Styles.TileIcon}
        pathFill={colors.shade40}
      />
      <Styles.TileCopy>
        {copy}
      </Styles.TileCopy>
    </Styles.TileWrapper>
  );
};

export default Tile;

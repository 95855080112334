import React, { Component, ReactElement } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  MerchantOrderByInput,
  PlanType
} from 'generated-types.d';

import {
  MerchantFilterGroup,
  MerchantsTimezones
} from 'stores/merchant-settings/merchant-settings-store.types';

import { colors } from 'utils/rebass-theme';

import { TagSelectionModalData } from 'features/modal-dialogue/components/modals/tag-selection-modal/tag-selection-modal.types';
import SettingsService from 'features/settings/services';

import { renderContainer, getCheckboxList } from 'components/checkbox-list/checkbox-list.factory';
import { CheckboxListFactoryType, CheckboxListProps } from 'components/checkbox-list/checkbox-list.types';
import { TagSelectionItem } from 'components/field-tag-selection/field-tag-selection.types';
import Filters from 'components/filters';
import { TabChildProps } from 'components/filters/filters.types';
import Icon from 'components/icon';
import ListControls from 'components/list-controls';
import { ListControlConfig } from 'components/list-controls/list-controls.types';

import * as Config from '../merchants-list/merchants-list.config';
import * as Types from '../merchants-list/merchants-list.types';

import * as Styles from './merchant-controls.styles';

class MerchantControls extends Component<Types.MerchantsControlsProps, Types.MerchantControlsState> {
  private SettingsAnalytics = SettingsService.SettingsAnalytics;

  private MerchantsSettingsService = SettingsService.MerchantsSettingsService;

  state: Types.MerchantControlsState = {
    filtersVisible: false
  };

  private onSearch = (value: string): void => {
    this.props.merchantSettingsStore!.setSearchValue(value);
    this.SettingsAnalytics.onMerchantSearch(value);
    this.MerchantsSettingsService.fetchMerchants();
  };

  private onClearSearch = (): void => {
    this.props.merchantSettingsStore!.setSearchValue('');
    this.onSearch('');
  };

  private onToggleActiveMerchants = (): void => {
    this.props.merchantSettingsStore!.resetMerchantList();
    this.props.merchantSettingsStore!.toggleActiveMerchants();
    this.MerchantsSettingsService.fetchMerchants();
  };

  private onSort = (sortOrder: MerchantOrderByInput): void => {
    this.props.merchantSettingsStore!.resetMerchantList();
    this.props.merchantSettingsStore!.setMerchantSortValue(sortOrder);
    this.MerchantsSettingsService.fetchMerchants();
  };

  private fetchData = (): void => {
    this.props.merchantSettingsStore!.resetMerchantList();
    this.MerchantsSettingsService.fetchMerchants();
    this.MerchantsSettingsService.fetchFilterCounts();
  };

  private onChange = (value: PlanType | MerchantsTimezones, filterGroup: MerchantFilterGroup): void => {
    this.props.merchantSettingsStore!.toggleFilter(value, filterGroup);
    this.fetchData();
  };

  private buildItems = (): TagSelectionItem[] => {
    const fieldList = this.props.merchantSettingsStore!.fieldList;

    return [
      {
        title: 'Active',
        id: 'active',
        isSelected: fieldList.includes('active')
      },
      {
        title: 'Merchant ID',
        id: 'merchantId',
        isSelected: fieldList.includes('merchantId')
      },
      {
        title: 'Accepting SD orders',
        id: 'accepting-sd-orders',
        isSelected: fieldList.includes('accepting-sd-orders')
      },
      {
        title: 'Location',
        id: 'location',
        isSelected: fieldList.includes('location')
      },
      {
        title: 'Plan',
        id: 'plan',
        isSelected: fieldList.includes('plan')
      },
      {
        title: 'Stage',
        id: 'stage',
        isSelected: fieldList.includes('stage')
      },
      {
        title: 'Subscription status',
        id: 'subscription-status',
        isSelected: fieldList.includes('subscription-status')
      },
      {
        title: 'First log in',
        id: 'first-log-in',
        isSelected: fieldList.includes('first-log-in')
      },
      {
        title: 'Product count',
        id: 'product-count',
        isSelected: fieldList.includes('product-count')
      },
      {
        title: 'Other',
        id: 'other',
        isSelected: fieldList.includes('other')
      }
    ];
  };

  private onFieldListModalTrigger = (): any => {
    this.props.modalStore!.triggerModal<TagSelectionModalData>({
      modalType: 'tagSelection',
      data: {
        title: 'Select fields',
        confirmButtonCopy: 'Confirm',
        items: this.buildItems(),
        limitSelection: undefined,
        confirmButtonAction: this.onUpdateFields
      }
    });
  };

  private onUpdateFields = (items: TagSelectionItem[]): void => {
    const selectedIds = items
      .filter(item => item.isSelected)
      .reduce((acc: string[], currItem) => [...acc, currItem.id], []);

    this.props.merchantSettingsStore!.setFieldList(selectedIds);
  };

  private onToggleFilters = (): void => {
    this.setState(state => ({ filtersVisible: !state.filtersVisible }));
  };

  private onClearFilters = (): void => {
    this.props.merchantSettingsStore!.resetFilters();
    this.props.merchantSettingsStore!.resetMerchantList();
    this.MerchantsSettingsService.fetchMerchants();
  };

  private calculateSelectedFilters = (): number => {
    return Object
      .values(this.props.merchantSettingsStore!.selectedFilters)
      .reduce((acc, currValue): number => {
        return acc + currValue.size;
      }, 0);
  };

  private renderCheckboxList = (
    factoryType: CheckboxListFactoryType,
    props: Pick<CheckboxListProps, 'items' | 'selectedItems' | 'onChange' | 'checkboxStyles' | 'itemValueField' | 'optionTitleField' | 'metadataField' | 'orientation'>
  ): ReactElement<TabChildProps> => {
    return renderContainer(factoryType, getCheckboxList(factoryType, { ...props, ...{
      optionTitleField: 'title'
    } }));
  };

  private renderFilterCheckboxList = (filterKey: MerchantFilterGroup): JSX.Element => {
    const filterGroupItems = this.props.merchantSettingsStore!.filters[filterKey];

    return this.renderCheckboxList(
      {
        title: filterKey.replace(/([A-Z]+)/g, ' $1'),
        type: 'standard',
        hasFiltersApplied: !!this.props.merchantSettingsStore!.selectedFilters[filterKey].size,
        isLoading: false
      },
      {
        items: filterGroupItems.map(item => {
          const count = this.props.merchantSettingsStore!.filtersCount?.[filterKey]?.[item.value] || 0;

          return {
            title: `${item.title} (${count})`,
            value: item.value,
            isDisabled: count === 0
          };
        }),
        selectedItems: Array.from(this.props.merchantSettingsStore!.selectedFilters[filterKey]),
        onChange: (value: PlanType | MerchantsTimezones): any => this.onChange(value, filterKey),
        itemValueField: 'value',
        optionTitleField: 'title',
        metadataField: filterKey,
        orientation: 'horizontal'
      }
    );
  };

  private buildMerchantControls = (): ListControlConfig => [
    {
      controlOptionType: 'searchField',

      name: 'search',
      alignment: 'left',
      placeholder: 'Search merchants',
      onSearch: this.onSearch,
      onChange: this.props.merchantSettingsStore!.setSearchValue,
      value: this.props.merchantSettingsStore!.searchValue,
      onClear: this.onClearSearch,
      isLoading: false
    },
    {
      controlOptionType: 'toggle',
      name: 'toggle',
      label: 'Show inactive',
      alignment: 'left',
      switchBgActive: colors.validationText,
      switchBgInactive: colors.errorText,
      controlValue: true,
      isChecked: this.props.merchantSettingsStore!.isShowingInactiveMerchants,
      onChange: this.onToggleActiveMerchants
    },
    {
      controlOptionType: 'custom',
      name: 'fields',
      alignment: 'right',
      content: (
        <Styles.FieldListToggle
          onClick={this.onFieldListModalTrigger}
          as="button"
          alignItems="center"
        >
          <Box
            pb="3px"
            mr="10px"
          >
            Select fields
          </Box>
          <Icon
            iconName="settings"
            styles={css`width: 18px; height: 18px;`}
          />
        </Styles.FieldListToggle>
      ),
      wrapperStyles: css`
        justify-self: end;
      `
    },
    {
      controlOptionType: 'custom',
      name: 'filters',
      alignment: 'right',
      content: (
        <Styles.FiltersToggle
          onClick={this.onToggleFilters}
          as="button"
          alignItems="center"
        >
          <Box pb="3px">Filters</Box>
          <Styles.FilterCount>({this.calculateSelectedFilters()})</Styles.FilterCount>
          <Icon iconName={this.state.filtersVisible ? 'minus-small' : 'plus-small'} />
        </Styles.FiltersToggle>
      )
    },
    {
      controlOptionType: 'dropdown',
      name: 'sort',
      alignment: 'right',
      id: 'merchant-list-sort',
      fullWidth: true,
      selected: this.props.merchantSettingsStore!.sortValue,
      options: Config.LIST_SORT_OPTIONS,
      optionTitleField: 'title',
      optionValueField: 'key',
      onChange: this.onSort
    }
  ];

  render(): React.ReactNode {
    return(
      <>
        <ListControls
          config={this.buildMerchantControls()}
          containerStyles={Styles.Wrapper}
        />
        <Filters
          isVisible={this.state.filtersVisible}
          clearFilters={this.onClearFilters}
          totalSelectedFilters={this.calculateSelectedFilters()}
        >
          {
            Object.keys(this.props.merchantSettingsStore!.filters).map((filterKey): JSX.Element => {
              return this.renderFilterCheckboxList(filterKey as MerchantFilterGroup);
            })
          }
        </Filters>
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore,
  merchantSettingsStore: stores.merchantSettingsStore,
  toasterStore: stores.toasterStore
}))(observer(MerchantControls));

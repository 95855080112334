import React, { ReactNode, Component } from 'react';

import ComingSoon from 'components/coming-soon';

import * as Styles from './maintenance-page.styles';

class MaintenancePage extends Component {
  state = {};

  render(): ReactNode {
    return(
      <Styles.MaintenancePage>
        <ComingSoon
          title="Floomx is currently undergoing maintenance"
          copy=""
          offset="0"
        />
      </Styles.MaintenancePage>
    );
  }
}

export default MaintenancePage;

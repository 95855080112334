import {
  action,
  observable,
  makeObservable,
  runInAction
} from 'mobx';

import {
  CatalogItemType,
  List,
  ListItem,
  ListItemCreateWithoutListInput,
  ListItemType,
  ListItemTypeFlower,
  ListItemUpdateWithoutListDataInput,
  ListUpdateInput,
  ListWhereUniqueInput,
  Supplier
} from 'generated-types.d';

import { SuppliersService } from 'features/suppliers/services';

import { SelectedListStoreService } from './selected-list-store.service';

export default class SelectedListStore {
  constructor() {
    makeObservable(this, {
      list: observable,
      availableSuppliers: observable,
      newListItemIds: observable,
      isLoadingList: observable,
      isLoadingAvailableSuppliers: observable,
      selectedCategory: observable,
      setSelectedCategory: action,
      isLoadingItem: observable,
      selectedItem: observable,
      setSelectedItem: action,
      removeNewListItemId: action,
      fetchList: action,
      clearList: action,
      fetchAvailableSuppliers: action,
      updateList: action,
      addItem: action,
      updateItem: action,
      deleteItem: action
    });
  }

  public list: List | null = null;

  public newListItemIds: string[] = [];

  public availableSuppliers: Supplier[] = [];

  public isLoadingAvailableSuppliers: boolean = true;

  public isLoadingList: boolean = true;

  public selectedCategory: CatalogItemType = CatalogItemType.Flower;

  public setSelectedCategory = (category: CatalogItemType): void => {
    this.selectedCategory = category;
  };

  public isLoadingItem: boolean = false;

  public selectedItem: ListItem | ListItemTypeFlower | null = null;

  public setSelectedItem = (item: ListItem): void => {
    this.selectedItem = item;
  }

  public clearSelectedItem = (): void => {
    this.selectedItem = null;
  }

  public removeNewListItemId = (itemId: string): void => {
    this.newListItemIds = this.newListItemIds.filter(id => id !== itemId);
  };

  public clearList = (): void => {
    this.list = null;
    this.availableSuppliers = [];
    this.newListItemIds = [];
    this.isLoadingList = true;
    this.isLoadingAvailableSuppliers = true;
    this.selectedCategory = CatalogItemType.Flower;
  };

  public fetchList = async ({ listId } : { listId: string }): Promise<List> => {
    runInAction(() => {
      this.isLoadingList = true;
    });

    try {
      const result = await SelectedListStoreService.fetchList(listId);

      runInAction(() => {
        this.list = result;
        this.isLoadingList = false;
      });

      return result;
    } catch (error) {
      runInAction(() => {
        this.isLoadingList = false;
      });

      return Promise.reject(error);
    }
  };

  public fetchItem = async ({ itemId, listId } : { itemId: string; listId: string }): Promise<ListItem | null> => {
    runInAction(() => {
      this.isLoadingItem = true;
    });

    try {
      const result = await SelectedListStoreService.fetchItem(itemId, listId);

      runInAction(() => {
        this.selectedItem = result;
        this.isLoadingItem = false;
      });

      return result;
    } catch (error) {
      runInAction(() => {
        this.isLoadingItem = false;
      });

      return Promise.reject(error);
    }
  };

  public fetchAvailableSuppliers = async ({ supplierIds } : { supplierIds: string[] }): Promise<void> => {
    runInAction(() => {
      this.isLoadingAvailableSuppliers = true;
    });

    try {
      const suppliers = await SuppliersService.fetchSuppliers({
        id_in: supplierIds
      });

      runInAction(() => {
        this.availableSuppliers = suppliers;
        this.isLoadingAvailableSuppliers = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingAvailableSuppliers = false;
      });

      return Promise.reject(error);
    }
  };

  public updateList = async ({ id, data } : {
    id: string;
    data: ListUpdateInput;
    where?: ListWhereUniqueInput;
  }): Promise<void> => {
    try {
      const updatedList = await SelectedListStoreService.updateList({ id, data });

      runInAction(() => {
        this.list = updatedList;
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public addItem = async ({ item }: {
    item: ListItemCreateWithoutListInput;
    where?: ListWhereUniqueInput;
  }): Promise<ListItem | undefined> => {
    await this.updateList({
      id: this.list!.id,
      data: {
        items: {
          create: [item]
        }
      }
    });

    const newItem = this.list?.items?.find?.(listItem => !!item.catalogItem?.connect?.id && item.catalogItem?.connect?.id === listItem.catalogItem?.id);

    if (newItem) {
      runInAction(() => {
        this.newListItemIds.push(newItem.id);
      });
    }

    return newItem;
  };

  public updateItem = async ({ item, updateInput }: {
    item: ListItem;
    updateInput: ListItemUpdateWithoutListDataInput;
  }): Promise<ListItem | void> => {
    const data: ListUpdateInput = {
      items: {
        update: [{
          where: {
            id: item.id
          },
          data: {
            ...this.updateMetadata(item),
            ...updateInput,
            type: item.type
          }
        }]
      }
    };

    await this.updateList({ id: this.list!.id, data: data });

    const response = this.list?.items?.find(storeItem => storeItem.id === item.id);

    const dataToUpdate = updateInput[item.type.toLowerCase()]?.update;

    const updatedItem = {
      ...item,
      ...(dataToUpdate || {}),
      quantity: updateInput.quantity
    };

    if (response) {
      this.setSelectedItem(updatedItem);

      return updatedItem;
    }
  };

  public deleteItem = async ({ id }: { id: string }): Promise<void> => {
    try {
      const data: ListUpdateInput = {
        items: {
          disconnect: [{
            id
          }]
        }
      };

      await this.updateList({ id: this.list!.id, data: data });
    } catch {
      return Promise.reject();
    }
  };

  public deleteList = async ({ listId } : { listId: string }): Promise<void> => {
    try {
      await SelectedListStoreService.deleteList(listId);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  private updateMetadata = (item: ListItem): Partial<ListItemUpdateWithoutListDataInput> => {
    switch (item.type) {
      case ListItemType.Decoration:
        return {
          decoration: {}
        };

      case ListItemType.Flower:
        return {
          flower: {
            update: {}
          }
        };

      case ListItemType.Plant:
        return {
          plant: {
            update: {}
          }
        };

      case ListItemType.Sundry:
        return {
          sundry: {
            update: {}
          }
        };

      default:
        return {};
    }
  }
}

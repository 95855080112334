import styled from '@emotion/styled';

import { colors, textStyles } from 'utils/rebass-theme';

export const InputMaxLengthWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const InputMaxLength = styled.p`
  ${textStyles.subhead};
  max-width: 80px;
  height: 20px;
  color: ${colors.shade40};
`;

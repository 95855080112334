import React, { Component, ChangeEvent, ReactNode } from 'react';

import axios, { AxiosRequestConfig } from 'axios';

import {
  CreateMediaLinkOutput,
  MediaCategory
} from 'generated-types.d';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import ErrorMessage from '../error-message';
import ImgixImage from '../imgix-image';

import Image from './media-upload-image/media-upload-image';
import { Content, ImageContainer } from './media-upload-image/media-upload-image.styles';
import MediaUploaderApolloService from './media-uploader-service/media-uploader-apollo.service';
import * as Styles from './media-uploader.styles';
import * as Types from './media-uploader.types';

const CancelToken = axios.CancelToken;
const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

const MediaError = {
  77046112018: 'Image too large! Must be less than 2mb',
  77046112274: 'Image must be of type: jpg / png / gif',
  0: 'Something went wrong, please try again'
};

const CLICK_OR_DRAG = 'Click here or drag image to upload';

export default class MediaUploader extends Component<Types.MediaUploaderProps> {
  mediaService = new MediaUploaderApolloService();

  state = {
    uploading: false,
    imageName: this.props.imageName,
    imageId: this.props.id,
    imageHovering: false,
    localSrc: undefined,
    src: this.props.src,
    source: CancelToken.source(),
    errorCode: -1,
    isDirty: false
  };

  componentDidMount(): void {
    window.addEventListener('dragover', this.handleEvent, false);
    window.addEventListener('drop', this.handleEvent, false);
  }

  componentWillUnmount(): void {
    window.removeEventListener('dragover', this.handleEvent);
    window.removeEventListener('drop', this.handleEvent);
  }

  componentDidUpdate(prevProps: any): void {
    if (this.props !== prevProps) {
      this.setState({
        src: this.props.src,
        imageId: this.props.id
      });
    }
  }

  private handleEvent = (e: any): void => {
    const eventHandler = e || event;

    if (eventHandler.target.type !== 'file') {
      eventHandler.preventDefault();
    }
  };

  private validateFile = (file: any): boolean => {
    const typeIndex: number = allowedTypes.indexOf(file.type.toLowerCase());

    if (typeIndex < 0) {
      this.setState({
        errorCode: 77046112274
      });

      return false;
    }

    if (file.size > 2000000) { // 2MB
      this.setState({
        errorCode: 77046112018
      });

      return false;
    }

    return true;
  };

  private getS3UploadDetails = async (file: any): Promise<CreateMediaLinkOutput> => {
    return this.mediaService.getUploadURL({
      mediaName: file.name,
      mediaType: file.type,
      mediaSize: file.size,
      mediaCategory: this.props.category
    }, file)
      .then(createMediaLink => {
        return createMediaLink;
      })
      .catch(() => {
        this.setState({
          errorCode: 0 // TODO: when validating properly pass errorcodes from apollo
        });
      });
  };

  private uploadToS3 = async (file: any, uploadUrl: string): Promise<boolean> => {
    const options: AxiosRequestConfig = {
      headers: {
        'Content-Type': file.type
      },
      cancelToken: this.state.source.token
    };

    return axios.put(uploadUrl, file, options)
      .then(() => {
        return true;
      })
      .catch(() => {
        this.setState({
          errorCode: 0 // TODO: when validating properly pass errorcodes from apollo
        });

        return false;
      });
  };

  private confirmMediaUpload = async (mediaId: string): Promise<void> => {
    if (this.state.uploading) {
      return this.mediaService.confirmMediaUpload(mediaId)
        .then(confirmMediaUpload => {
          this.setState({
            uploading: false,
            src: confirmMediaUpload.src,
            imageId: confirmMediaUpload.id,
            isDirty: true
          });
          this.props.onUpload(this.state.imageId, this.state.src);
        });
    }

    return;
  };

  private uploadFiles = async (file: File): Promise<any> => {
    const reader = new FileReader();

    reader.onload = (event: any): void => {
      this.setState({
        uploading: true,
        imageHovering: false,
        localSrc: event.target.result,
        source: CancelToken.source()
      });
    };
    // @ts-ignore
    reader.onload = reader.onload.bind(this);

    reader.readAsDataURL(file);

    const valid = this.validateFile(file);

    if (valid) {
      const uploadDetails = await this.getS3UploadDetails(file);

      if (uploadDetails) {
        const uploadToS3 = await this.uploadToS3(file, uploadDetails.url);

        if (uploadToS3) {
          await this.confirmMediaUpload(uploadDetails.id);
        }
      }
    }
  };

  private onChange = async (e: ChangeEvent<HTMLInputElement>): Promise<any> => {
    const files = Array.from(e.target.files!);
    const file: File = files[0];
    await this.uploadFiles(file);
  };

  private preventDefaults = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  private onDrop = async (event: any): Promise<any> => {
    this.preventDefaults(event);
    const files = event.dataTransfer.files;
    const file: File = files[0];
    await this.uploadFiles(file);
  };

  private onDrag = (imageHovering: boolean): void => {
    this.preventDefaults(event);

    this.setState({
      imageHovering
    });
  };

  private removeImage = (): void => {
    this.state.source.cancel('Operation canceled by user');

    this.setState({
      imageId: '',
      src: '',
      uploading: false,
      imageHovering: false,
      errorCode: -1,
      isDirty: true
    });

    this.props.onUpload('', '');
  };

  private renderUploadingImage = (): ReactNode => (
    <Image
      imageId={this.state.imageId}
      name={this.state.imageName}
      localSrc={this.state.localSrc}
      src={this.state.src}
      removeImage={this.removeImage}
      uploading={this.state.uploading}
      errorMessage={MediaError[this.state.errorCode]}
    />
  );

  private renderExistingImage = (): ReactNode => (
    <Image
      imageId={this.state.imageId}
      name={this.state.imageName}
      localSrc={this.state.localSrc}
      src={this.state.src}
      removeImage={this.removeImage}
      errorMessage={MediaError[this.state.errorCode]}
      params={{
        fit: 'crop',
        ar: '1:1'
      }}
    />
  );

  private renderToBeUploadedImage = (): ReactNode => (
    <Styles.UploadButton
      validationError={this.hasError()}
      onChange={this.onChange}
      onDrop={this.onDrop}
      onDragLeave={(): void => this.onDrag(false)}
      onDragEnter={(): void => this.onDrag(true)}
      onDragOver={(): void => this.onDrag(true)}
      imageHovering={this.state.imageHovering}
    >
      <input
        type="file"
        id="single"
        onChange={this.onChange}
      />
      <Styles.UploadLabelContent>
        <div>
          <Icon
            iconName="plus-large"
            pathFill={this.hasError()
              ? colors.errorText
              : colors.floomMidnightBlue}
          />
          <Styles.UploadImageTitle>{this.props.imageName}</Styles.UploadImageTitle>
          <Styles.UploadImageSubtitle
            validationError={this.hasError()}
          >
            {CLICK_OR_DRAG}
          </Styles.UploadImageSubtitle>
        </div>
      </Styles.UploadLabelContent>
    </Styles.UploadButton>
  );

  private renderReadOnly = (): ReactNode => {
    if (!this.state.imageId) return null;

    return (
      <Content>
        <ImageContainer>
          <ImgixImage
            config={{ path: this.state.src }}
            width={170}
            height={170}
            params={{
              fit: 'crop',
              ar: '1:1'
            }}
          />
        </ImageContainer>
        <p>{this.state.imageName}</p>
      </Content>
    );
  };

  private renderContent = (): ReactNode => {
    switch (true) {
      case this.props.isReadOnly:
        return this.renderReadOnly();

      case this.state.uploading:
        return this.renderUploadingImage();

      case !!this.state.imageId:
        return this.renderExistingImage();

      default:
        return this.renderToBeUploadedImage();
    }
  };

  private hasError = (): boolean => {
    return !!this.props.validationUploadError
      && this.state.isDirty
      && this.props.category !== MediaCategory.Details;
  };

  render(): React.ReactNode {
    return (
      <Styles.Content>
        {this.renderContent()}
        <ErrorMessage
          errorMessage={this.hasError() ? this.props.validationErrorMessage : ''}
        />
      </Styles.Content>
    );
  }
}

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';

import MerchantStore from 'stores/merchant/merchant-store';
import ProductEditStore from 'stores/product-edit-store/product-edit-store';
import { Mode } from 'stores/product-edit-store/product-edit-store-types';

import ChannelCheckboxList from 'components/channel-checkbox-list';
import FormFieldWrapper from 'components/create-labeled-component';
import Loader from 'components/loader';

export interface ProductChannelsProps {
  productEditStore?: ProductEditStore;
  merchantStore?: MerchantStore;
}

class ProductChannels extends Component<ProductChannelsProps> {
  onChannelChange = (id: any): void => {
    this.props.productEditStore!.updateSelectedChannel(id);
  };

  render(): React.ReactNode {
    const merchant = this.props.productEditStore!.currentMode === Mode.create
      ? this.props.merchantStore!.merchant!
      : this.props.productEditStore!.productMerchant;

    return (
      <FormFieldWrapper
        title="Channel"
        subtitle="Select the platform where you want to sell your item"
      >
        {(!!this.props.productEditStore!.productMerchant || !!this.props.merchantStore!.merchant) ? (
          <ChannelCheckboxList
            selectedItems={this.props.productEditStore!.selectedChannels}
            itemValueField="id"
            optionTitleField="title"
            orientation="horizontal"
            merchant={merchant}
            onChange={this.onChannelChange}
            error={this.props.productEditStore!.selectedChannels.length === 0}
            errorMessage="At least one channel must be selected"
          />
        )
          : (
            <Flex mt="20px">
              <Loader />
            </Flex>
          )}
      </FormFieldWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  merchantStore: stores.merchantStore
}))(observer(ProductChannels));

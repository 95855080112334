import { gql } from '@apollo/client';
import { ProductType } from 'generated-types';
import camelCase from 'lodash.camelcase';

import { GraphQLHelpers } from 'lib';

import * as Helpers from '../helpers/product-query.helpers';
import * as TypeHelpers from '../helpers/type-query.helpers';

export const PRODUCT_TYPES_COUNT_QUERY = (vars: any, productTypes: ProductType[]): any => {
  const getCountQuery = (variables: any, type: any): string => {
    return `
      ${camelCase(type.title)}: productsConnection(
        ${Helpers.addRootArguments(variables)}
        where: {
          ${Helpers.addRootWhereArguments(variables)}
          ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
          AND: [
            {
              type_some: {
                slug: "${type.slug}"
              }
            }
            {
              ${TypeHelpers.addTypeWhereFilter(variables.selectedCategories)}
            }
            ${GraphQLHelpers.searchableContainsValues(vars.search)}
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    `;
  };

  return gql`
    query ProductTypesCount (${TypeHelpers.addRootQueryParams(vars)}) {
      ${productTypes.map((type: ProductType) => getCountQuery(vars, type))}
    }`;
};

import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { mq, Card } from 'utils/css-mixins/css-mixins';

import OverviewBgTopRightImg from 'assets/images/overview-bg-top-right.png';

export const OverviewWrapper: any = styled(Flex)`
  padding-bottom: 40px;
  position: relative;
  background-image: url(${OverviewBgTopRightImg});
  background-size: 260px;
  background-position: top right;
  background-repeat: no-repeat;
  height: max-content;
`;

export const DashboardGridContainer: any = styled(Flex)`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const DashboardGrid: any = styled(Box)`
  width: ${({ width }): string => width || '50%'};
  margin: 10px 0 0;
  padding: ${({ shouldRemovePadding }): string => shouldRemovePadding ? '0' : '0 10px'};

  display: flex;
  flex-direction: column;

  @media (max-width: ${({ breakOn }): any => breakOn ? `${breakOn}px` : '930px'}) {
    width: 100%;
  }
`;

export const Heading: any = styled(Flex)`
  margin: 60px 0 20px 0;

  ${mq.lessThan('medium')} {
    margin: 30px 0 20px 0;
  }
`;

export const WidgetHeading: any = styled(Flex)`
  margin-top: 20px;
  margin-bottom: 10px;

  > h3 {
    margin-right: 20px;
  }
`;

export const CountBox: any = styled(Box)`
  padding: 5px;
  text-align: center;
  flex-grow: 1;

  > h5 {
    margin-bottom: 20px;
  }
`;

export const Widget: any = styled(Flex)`
  ${Card};
  width: 100%;
  margin: 10px 0 0;
  padding: 20px;
`;

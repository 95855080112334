import React, { Component, ReactNode } from 'react';

import { Flex } from 'rebass';

import { COURIER_BOOKING_STATUSES } from 'features/courier-bookings/courier-bookings.constants';

import Button from 'components/button';

import { CourierBookingDropdownOptionList } from '../courier-item.config';

import * as Styles from './status-quick-switch.styles';
import * as Types from './status-quick-switch.types';
import StatusSelect from './status-select';

export default class StatusQuickSwitch extends Component<Types.StatusQuickSwitchProps> {
  private onStatusChange = (value: any): void => {
    this.props.onChange(value);
  };

  render = (): ReactNode => {
    const bookingStatus = COURIER_BOOKING_STATUSES.find(status => status.slug === this.props.currentStatus);

    return (
      <Flex>
        <Styles.StatusActionButton layout={this.props.layout}>
          <Button
            copy={bookingStatus ? bookingStatus.title : 'Requested'}
            isParentWidth={true}
            isLoading={this.props.isLoading}
          />
        </Styles.StatusActionButton>
        {!this.props.isLoading && (
          <StatusSelect
            layout={this.props.layout}
            hasText={false}
            actions={CourierBookingDropdownOptionList}
            referenceNo={this.props.referenceNo}
            onChange={this.onStatusChange}
          />
        )}
      </Flex>
    );
  };
}

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles, colors } from 'utils/rebass-theme';

export const IngredientsSection = styled(Flex)<any>`
  > p {
    ${textStyles.h4};
    text-align: left;
    padding: 10px 0;
  }
  padding: 20px 0 ${(props: any): number => (props.finalProduct ? 0 : 40)}px 0;
  border-bottom: ${(props: any): number => (props.finalProduct ? 0 : 1)}px solid ${colors.middleGrey};
  margin-bottom: ${(props: any): number => (props.finalProduct ? 0 : 40)}px;
  max-width: 100%;
  flex-direction: column;
`;

export const IngredientsWrapper = styled(Flex)`
  width: 100%;
  height: 80px;
  background-color: ${colors.lightGrey};
  align-items: center;
  justify-content: flex-start;
  ${textStyles.h4};
  margin: 8px 0;
`;

export const IngredientsCounter = styled.p`
  margin: 0 20px;
  ${textStyles.title};
  color: ${colors.shade40};
`;

export const IngredientsImage = styled.div`
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  overflow: hidden;
`;

import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useRef,
  useState
} from 'react';

import { Box, Flex } from 'rebass';

import { CurrencyService } from 'lib';

import CheckboxIcon from 'components/checkbox-icon';

import {
  Input
} from './max-unit-price-field.styles';
import {
  ListItemMaxUnitPriceFieldProps
} from './max-unit-price-field.types';

export const ListItemMaxUnitPriceField: FC<ListItemMaxUnitPriceFieldProps> = ({
  listItem,
  maxUnitPrice,
  maxAvailablePrice,
  placeholder,
  currency,
  onUpdateListItem
}) => {
  const [controlledValue, setValue] = useState<string>(maxUnitPrice ? `${maxUnitPrice.toFixed(2)}` : '');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleReturn = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleUpdate();
      inputRef?.current?.blur?.();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.currentTarget.value);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.valueAsNumber.toFixed(2);
    setValue(value);

    if (`${listItem.maxUnitPrice}` !== value) {
      handleUpdate();
    }
  };

  const handleCheckboxSelect = async (): Promise<void> => {
    if (!!controlledValue) {
      setValue('');
      handleUpdate('');
    } else if (!!maxAvailablePrice) {
      const value = maxAvailablePrice.toFixed(2);

      setValue(value);
      handleUpdate(value);
    }
  };

  const handleUpdate = async (value = controlledValue): Promise<void> => {
    await onUpdateListItem({
      maxUnitPrice: parseFloat(value)
    });
  };

  return (
    <Flex
      alignItems="center"
    >
      <Box
        as="button"
        height="19px"
        onClick={handleCheckboxSelect}
      >
        <CheckboxIcon
          isSelected={!!controlledValue}
        />
      </Box>
      {CurrencyService.renderCurrencySymbol(currency)}
      <Input
        ref={inputRef}
        value={controlledValue}
        placeholder={placeholder}
        type="number"
        min={0}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleReturn}
      />
    </Flex>
  );
};

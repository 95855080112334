import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { OrderItem } from 'generated-types.d';

import GenericModal from 'components/generic-modal';

import { IngredientsList } from './components/ingredients-list/ingredients-list';
import ProductImageSlider from './components/product-image-slider/product-image-slider';
import * as Styles from './order-details-modal.styles';

class OrderDetailsModal extends Component<any> {
  render(): ReactNode {
    return (
      <GenericModal
        title="Products"
        shouldHideFooter={true}
        closeModal={this.props.ordersStore.closeOrderDetailsModal}
        modalOpen={this.props.ordersStore.showOrderDetailsModal}
        width={485}
      >
        <Styles.Container>
          {this.props.ordersStore.orderDetailModalItems.map((orderItem: OrderItem) => {
            const medias = orderItem.productSnapshot.variation ? orderItem.productSnapshot.variation.media : [];
            const recipeItems = orderItem.productSnapshot.variation
              ? orderItem.productSnapshot.variation.recipe
                ? orderItem.productSnapshot.variation.recipe.recipeItems
                : []
              : [];

            return (
              <Styles.OrderItemWrapper key={orderItem.id}>
                <ProductImageSlider
                  orderItem={orderItem}
                  medias={medias}
                />
                <IngredientsList
                  orderItem={orderItem}
                  recipeItems={recipeItems}
                  orderItems={this.props.ordersStore.orderDetailModalItems}
                />
              </Styles.OrderItemWrapper>
            );
          })}
        </Styles.Container>
      </GenericModal>
    );
  }
}

export default inject('ordersStore')(observer(OrderDetailsModal));

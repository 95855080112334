import { FC } from 'react';

import { Flex } from 'rebass';

import { DisabledItem } from 'features/lists/lists.styles';

import {
  ListItemColourProps
} from './list-item-colour-props';
import {
  ColourDot,
  ColourName
} from './list-item-colour.styles';

export const ListItemColour: FC<ListItemColourProps> = ({
  colour,
  status,
  size
}) => {
  const colourName = colour.name.replace('Not Provided', '');

  switch (true) {
    case !!colourName: {
      return (
        <Flex alignItems="baseline">
          <ColourDot
            hex={colour.hex}
            size={size}
          />
          <ColourName
            status={status}
            size={size}
          >
            {colourName}
          </ColourName>
        </Flex>
      );
    }

    default:
      return <DisabledItem>--</DisabledItem>;
  }
};

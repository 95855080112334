import React, { Component, ReactNode } from 'react';

import { createPopup } from '@typeform/embed';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box } from 'rebass';

import { PermissionsService } from 'lib';

import { colors } from 'utils/rebass-theme';

import OrderHelpers from 'features/orders/helpers/order-helpers';
import { ORDER_STATUSES, ALL_STATUS_SLUGS, SimpleOrderStatus } from 'features/orders/orders.constants';
import { OrderStatusSlug } from 'features/orders/orders.types';
import { OrderEditService } from 'features/orders/services';

import DropdownNative from 'components/dropdown-native';

import * as Styles from './order-detail-status-control.styles';
import * as Types from './order-detail-status-control.types';
import { getOrderNo } from 'utils';

class OrderDetailStatusControl extends Component<Types.OrderDetailStatusControlProps> {
  config = {
    headingBg: 'background',
    color: colors.floomMidnightBlue,
    iconName: 'pie-one-third'
  };

  private isInactive = (): boolean => ['delivered', 'cancelled', 'failedDelivery']
    .includes(this.props.ordersStore!.currentOrder.status.slug);

  private getAvailableStatusSlugs = (): string[] => this.shouldDisplayAllStatuses()
    ? ALL_STATUS_SLUGS
    : this.getStatuses();

  private getStatuses = (): any => {
    const isInternalRole = PermissionsService.isInternalRole();
    const isWebsiteOrder = this.props.ordersStore!.currentOrder.channel === 'Website';
    let statusesToFilter: OrderStatusSlug[] = [];

    switch (true) {
      case !isInternalRole && !isWebsiteOrder:
        statusesToFilter = [OrderStatusSlug.Cancelled];

        break;
    }

    return OrderHelpers.getStatusesAfterCurrent(this.props.ordersStore!.currentOrder.status.slug, true, statusesToFilter);
  };

  private buildStatusOptions = (): SimpleOrderStatus[] => {
    const availableStatuses = this.getAvailableStatusSlugs();

    return ORDER_STATUSES.filter(status => availableStatuses.includes(status.slug));
  };

  private shouldDisplayAllStatuses = (): boolean => {
    return PermissionsService.isInternalRole() || (!PermissionsService.isInternalRole() && this.props.ordersStore!.currentOrder.channel === 'Website');
  };

  private onStatusUpdate = async (statusSlug: OrderStatusSlug): Promise<void> => {
    const updatedOrder = await OrderEditService.updateStatusFromDetail(
      statusSlug,
      this.props.ordersStore!.currentOrder
    );
    this.props!.ordersStore!.addChangedStatus(updatedOrder.orderNo, updatedOrder.status);

    const isInternalRole = PermissionsService.isInternalRole();

    if (isInternalRole || (!isInternalRole && updatedOrder.channel === 'Website')) {
      return;
    }

    const formOptions = {
      hidden: {
        order_no: getOrderNo(updatedOrder.orderNo),
        merchant: updatedOrder.merchant?.title,
        deliver_at: moment(updatedOrder.deliverAt).format('DD/MM/YYYY'),
        ordered_at: moment(updatedOrder.orderedAt).format('DD/MM/YYYY - h:mma'),
        customer_name: updatedOrder.billingAddress?.recipientFullName || '',
        customer_email: updatedOrder.customerUser?.email || '',
        customer_telephone: updatedOrder.billingAddress?.phone || ''
      },
      autoClose: 1000
    };

    if (statusSlug === 'failedDelivery') {
      const { toggle } = createPopup('vFajaJyG', formOptions);
      toggle();
    }

    if (statusSlug === 'cancelled') {
      const { toggle } = createPopup('PwCUOzgN', formOptions);
      toggle();
    }

    if (statusSlug === 'delivered') {
      const podPrompt = window.sessionStorage.getItem('podPrompt') ? JSON.parse(sessionStorage.getItem('podPrompt')!) : null;

      window.sessionStorage.setItem(
        'podPrompt',
        JSON.stringify({
          ...podPrompt,
          hasSeenPodPrompt: true,
          dateSeen: moment().format('YYYY-MM-DD')
        })
      );

      const shouldShowPrompt = !!podPrompt ? moment().isAfter(moment(podPrompt.dateSeen), 'day') : true;

      if (shouldShowPrompt) {
        this.props.modalStore!.triggerModal<any>({
          modalType: 'confirmation',
          data: {
            title: 'Proof of delivery',
            copy: (
              <>
                Did you know you can now provide proof of delivery from within FloomX? If you&apos;d like to
                give it a go now, click the button below.
              </>
            ),
            confirmButtonCopy: 'Try it',
            cancelButtonCopy: 'No thanks',
            errorCopy: '',
            confirmButtonAction: (): void => {
              window.sessionStorage.setItem(
                'podPrompt',
                JSON.stringify({
                  hasSeenPodPrompt: true,
                  dateSeen: moment().format('YYYY-MM-DD'),
                  hasTriedPod: true
                })
              );

              const { toggle } = createPopup('bv8N72EL', formOptions);
              toggle();
            }
          }
        });
      }
    }
  };

  render(): ReactNode {
    const statusOptions = this.buildStatusOptions();

    if (this.isInactive() && !this.shouldDisplayAllStatuses()) {
      return (
        <Styles.StatusIndicator config={this.config}>
          - {this.props.ordersStore!.currentOrder.status.title}
        </Styles.StatusIndicator>
      );
    }

    return (
      <>
        <Box>
          {statusOptions.length && (
            <DropdownNative
              id="order-list-sort"
              fullWidth={true}
              label={this.props.label}
              options={this.buildStatusOptions()}
              selected={this.props.ordersStore!.currentOrder.status.slug}
              optionTitleField="title"
              optionValueField="slug"
              onChange={this.onStatusUpdate}
              textColor={this.config.color}
            />
          )}
        </Box>
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  modalStore: stores.modalStore
}))(observer(OrderDetailStatusControl));

import React, { Component, ChangeEvent } from 'react';

import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import MerchantStore from 'stores/merchant/merchant-store';
import ProductEditStore from 'stores/product-edit-store/product-edit-store';
import { Mode } from 'stores/product-edit-store/product-edit-store-types';

import ProductService from 'features/products/services';

import FormFieldWrapper from 'components/create-labeled-component';
import FieldText from 'components/field-text';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

interface ProductTitleProps {
  productEditStore?: ProductEditStore;
  merchantStore?: MerchantStore;
}

class ProductTitle extends Component<ProductTitleProps> {
  ProductCreateEditService = ProductService.ProductCreateEditService;

  private debounceTitleValidation = debounce(() => {
    this.validateProductTitle();
  }, 300);

  private validateProductTitle = (): void => {
    const isCreateMode = this.props.productEditStore!.currentMode === Mode.create;
    const merchant = isCreateMode ? this.props.merchantStore!.merchant! : this.props.productEditStore!.productMerchant;

    this.ProductCreateEditService.validateProductTitle(
      this.props.productEditStore!.title,
      merchant!.id!,
      !isCreateMode ? this.props.productEditStore!.id! : null
    );
  };

  private onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    this.props.productEditStore!.updateTitle(e.target.value);
    this.debounceTitleValidation();
  };

  render(): React.ReactNode {
    return (
      <FormFieldWrapper title="Title">
        <FieldText
          onChange={this.onChange}
          value={this.props.productEditStore!.title}
          isSpellCheckEnabled={false}
          placeholder="name"
          maxLength={21}
          size="normal"
          error={!this.props.productEditStore!.title}
          errorMessage="Title must be provided."
          displayMaxLength={true}
        />
        { this.props.productEditStore!.isProductTitleADuplicate && (
          <Box mt="15px">
            <Notification
              type={NotificationType.Info}
              hasIcon={false}
              hasClose={false}
              copy="Oops! You already have a product with this title"
            />
          </Box>
        )}
      </FormFieldWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  merchantStore: stores.merchantStore
}))(observer(ProductTitle));

import { ReactNode } from 'react';

import { graphql, PageProps } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Helmet } from 'react-helmet';

import { StandardPage } from 'features/marketing/components/standard-page/standard-page';
import { StandardPageQueryData } from 'features/marketing/marketing.types';

import { MarketingLayout } from 'components/global-layout';

const Page = (props: PageProps<StandardPageQueryData>): ReactNode => {
  return (
    <MarketingLayout>
      <HelmetDatoCms seo={props.data.datoCmsPage?.seoMetaTags} />
      <Helmet titleTemplate="%s">
        <title>{props.data.datoCmsPage?.pageTitle} - FloomX</title>
      </Helmet>
      <StandardPage
        data={props.data.datoCmsPage}
      />
    </MarketingLayout>
  );
};

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    datoCmsPage(
      slug: {
        eq: $slug
      },
      locale: {
        eq: "en-GB"
      }
    ) {
      ...StandardPage
    }
  }
`;

import React from 'react';

import { Flex, Box } from 'rebass';

import { Container } from 'utils/css-mixins';

import Button from 'components/button';
import FormFooter from 'components/form-footer/form-footer';

import * as Styles from './settings-template-page.styles';
import * as Types from './settings-template-page.types';

export default class SettingsTemplatePage extends React.Component<Types.SettingsTemplateProps> {
  static defaultProps = {
    validationPassed: true,
    isActionDisabled: false
  };

  private shouldRenderHeaderAction = (): boolean => !!this.props.headerButtonCopy && !!this.props.onHeaderAction;

  render(): JSX.Element {
    return (
      <Styles.SettingsWrapper>
        <Container>
          <Flex
            justifyContent="space-between"
            alignItems="center"
          >
            <Styles.SettingsTitle>
              {this.props.title}
            </Styles.SettingsTitle>
            { this.shouldRenderHeaderAction() && (
              <Box>
                <button
                  onClick={this.props.onHeaderAction}
                  disabled={this.props.isActionDisabled}
                >
                  <Button
                    size="normal"
                    copy={this.props.headerButtonCopy!}
                    isDisabled={this.props.isActionDisabled}
                  />
                </button>
              </Box>
            )}
          </Flex>
          {this.props.children}
          {
            this.props.displayEditFooter && (
              <FormFooter>
                <Styles.ButtonsWrapper>
                  <div
                    onClick={this.props.cancelAction}
                  >
                    <Button
                      size="normal"
                      appearance="secondary"
                      copy="Cancel"
                    />
                  </div>
                  <div
                    onClick={this.props.saveAction}
                  >
                    <Button
                      size="normal"
                      appearance="primary"
                      copy="Save"
                      isDisabled={!this.props.validationPassed}
                    />
                  </div>
                </Styles.ButtonsWrapper>
              </FormFooter>
            )}
        </Container>
      </Styles.SettingsWrapper>
    );
  }
}

import {
  FC,
  ReactNode,
  useMemo
} from 'react';

import { Router } from '@reach/router';
import { inject, observer } from 'mobx-react';
import Helmet from 'react-helmet';
import { InjectedMobxStores } from 'stores';

import { PermissionsService } from 'lib';

import { Container } from 'utils/css-mixins';

import { NotificationSettings } from 'features/settings/components/notifications/notifications';
import AccountDetails from 'features/settings/pages/account/account';
import CustomSiteSettings from 'features/settings/pages/custom-site-settings';
import Delivery from 'features/settings/pages/delivery';
import DeliveryCoverage from 'features/settings/pages/delivery-coverage';
import DiscountCreateEdit from 'features/settings/pages/discount-create-edit';
import Discounts from 'features/settings/pages/discounts';
import DpdUpload from 'features/settings/pages/dpd-upload/dpd-upload';
import SettingsHome from 'features/settings/pages/home';
import MerchantHolidays from 'features/settings/pages/merchant-holidays';
import Merchants from 'features/settings/pages/merchants';
import { MerchantDetailController } from 'features/settings/pages/merchants/merchant-detail-controller';
import Payments from 'features/settings/pages/payments';
import WholesaleSettings from 'features/wholesale/pages/settings/settings';

import ComingSoon from 'components/coming-soon';
import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

const WholesaleHoldingPage: FC = () => {
  return (
    <Container maxWidth={700}>
      <ComingSoon
        title="Want wholesale access?"
        copy="Get in touch with Floom and we'll get you set up!"
      />
    </Container>
  );
};

const SettingsPage: FC<Pick<InjectedMobxStores,
| 'merchantStore'
>> = inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore
}))(observer(({
  merchantStore
}) => {
  const hasAccessToWholesale = useMemo((): boolean => {
    return PermissionsService.isInternalRole()
    || !!merchantStore!.merchant?.wholesaleActive;
  }, [merchantStore!.merchant]);

  const renderRoute = (Component: ReactNode): ReactNode => {
    if (!hasAccessToWholesale) {
      return WholesaleHoldingPage;
    }

    return Component;
  };

  return (
    <AppLayout>
      {/*
    // @ts-ignore */}
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Router>
        <AuthedRoute
          path="/settings"
          component={SettingsHome}
        />
        <AuthedRoute
          path="/settings/delivery"
          component={Delivery}
        />
        <AuthedRoute
          path="/settings/delivery-coverage"
          component={DeliveryCoverage}
        />
        <AuthedRoute
          path="/settings/wholesale/"
          component={renderRoute(WholesaleSettings)}
        />
        <AuthedRoute
          path="/settings/discounts"
          component={Discounts}
        />
        <AuthedRoute
          path="/settings/discounts/create"
          component={DiscountCreateEdit}
          formType="create"
        />
        <AuthedRoute
          path="/settings/discounts/edit/:discountId"
          component={DiscountCreateEdit}
          formType="edit"
        />
        <AuthedRoute
          path="/settings/dpd-upload"
          component={DpdUpload}
        />
        <AuthedRoute
          path="/settings/holidays"
          component={MerchantHolidays}
        />
        <AuthedRoute
          path="/settings/payments"
          component={Payments}
        />
        <AuthedRoute
          path="/settings/merchants"
          component={Merchants}
        >
          <AuthedRoute
            path=":merchantId"
            component={MerchantDetailController}
          />
        </AuthedRoute>
        <AuthedRoute
          path="/settings/account"
          component={AccountDetails}
        />
        <AuthedRoute
          path="/settings/notifications"
          component={NotificationSettings}
        />
        <AuthedRoute
          path="/settings/custom-site-settings"
          component={CustomSiteSettings}
        />
      </Router>
    </AppLayout>
  );
}));

export default SettingsPage;

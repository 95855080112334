import { WholesaleOrderStates, UserRole } from 'generated-types.d';

import { WholesalePermissions, SupplierIntegrationFieldConfigurationConfig } from './wholesale.types';

const {
  CustomerService,
  SuperAdmin,
  TeamAdmin,
  TeamMember,
  TeamOwner
} = UserRole;

interface FreeDeliveryConfig {
  supplier: string;
  totalForFreeDelivery: number;
}

export const DEFAULT_TOTAL_FOR_FREE_DELIVERY = 100;

export const TOTALS_FOR_FREE_DELIVERY: FreeDeliveryConfig[] = [
  {
    supplier: 'hoek-flowers',
    totalForFreeDelivery: 200
  },
  {
    supplier: 'cj-love',
    totalForFreeDelivery: 100
  }
];

export const NEXT_DAY_CUTOFF = 13;

export const ORDER_LIST_ITERATION_COUNT = 30;

export const stackedSearchBreakpoint = '70.625em';

export const stackedSearchBreakpointPx = 1130;

export const stackedSearchBreakpointCompact = '87.5em';

interface WholesaleOrderListConfigItem {
  title: string;
  state: WholesaleOrderStates;
}

type WholesaleOrderListConfig = { [key in WholesaleOrderStates]: WholesaleOrderListConfigItem };

export const ORDER_LIST_CONFIG: WholesaleOrderListConfig = {
  Draft: {
    title: 'Draft',
    state: WholesaleOrderStates.Draft
  },
  Open: {
    title: 'Open',
    state: WholesaleOrderStates.Open
  },
  Processed: {
    title: 'Processed',
    state: WholesaleOrderStates.Processed
  },
  Completed: {
    title: 'Completed',
    state: WholesaleOrderStates.Completed
  },
  Failed: {
    title: 'Failed',
    state: WholesaleOrderStates.Failed
  }
};

export const WHOLESALE_DELIVERY_SETTINGS_PERMISSIONS: WholesalePermissions = {
  createDraftOrder: {
    restrictedRoles: [TeamMember, TeamOwner, TeamAdmin]
  },
  deliveryCopy: {
    restrictedRoles: [SuperAdmin]
  },
  contactButton: {
    restrictedRoles: [SuperAdmin, CustomerService, TeamMember]
  },
  allConfigs: {
    restrictedRoles: [CustomerService, TeamMember, TeamOwner, TeamAdmin]
  },
  paymentSettingsButton: {
    restrictedRoles: [TeamMember]
  }
};

export const DELIVERY_COLUMN_HEADINGS = [
  {
    heading: 'Active',
    id: 'heading',
    fixedWidth: '120px',
    restrictedRoles: [
      UserRole.TeamMember,
      UserRole.TeamAdmin,
      UserRole.TeamOwner
    ]
  },
  {
    heading: 'Merchant',
    id: 'merchant',
    flexGrow: '1',
    flexBasis: '150px',
    restrictedRoles: [
      UserRole.TeamMember,
      UserRole.TeamAdmin,
      UserRole.TeamOwner
    ]
  },
  {
    heading: 'Address',
    id: 'heading',
    flexGrow: '1',
    flexBasis: '150px'
  },
  {
    heading: 'Instructions',
    id: 'instructions',
    flexGrow: '1',
    flexBasis: '150px'
  },
  {
    heading: '',
    id: 'controls',
    fixedWidth: '50px'
  }
];

export const SUPPLIER_INTEGRATION_MAP: SupplierIntegrationFieldConfigurationConfig = {
  Florisoft: {
    displayField: 'externalId',
    fields: [
      {
        field: 'externalId',
        name: 'Supplier account code',
        type: 'textInput',
        validators: [
          {
            name: 'required',
            message: 'Supplier account code is required'
          },
          {
            name: 'alphanumeric',
            message: 'Please add correct supplier code e.g. UK1234'
          }
        ]
      }
    ]
  }
};

import React, { FC } from 'react';

import { css } from '@emotion/react';
import { ListItemUnion } from 'generated-types';
import { Box } from 'rebass';

import { ListItem as ListItemType } from 'types/conversations.types';

import { ListSummary } from 'features/conversations/components/list-summary/list-summary';

import { Card } from 'components/card/card';
import GenericModal from 'components/generic-modal';

import {
  ListItem,
  ListItemQuantity,
  ListItemsWrapper
} from './list-details-modal.styles';
import { isListItem, ListDetailsModalProps } from './list-details-modal.types';

export const ListDetailsModal: FC<ListDetailsModalProps> = ({
  closeModal,
  data: {
    list,
    isOpen,
    timezone = 'Europe/London',
    onClickClose,
    title
  }
}) => {
  const handleClose = (): void => {
    onClickClose?.();

    closeModal();
  };

  if (!list) return null;

  return (
    <GenericModal
      title={title}
      closeModal={handleClose}
      modalOpen={isOpen}
      shouldHideFooter={true}
      shouldHideHeader={false}
    >
      <Card customStyles={css`
          overflow: auto;
          height: calc(100% - 60px);
        `}
      >
        <ListSummary
          list={list}
          timezone={timezone}
        />
        {list?.items?.length && (
          <ListItemsWrapper>
            {list?.items?.map((item: ListItemUnion | ListItemType) => (
              <ListItem key={item.id}>
                <Box mr={2}>
                  {isListItem(item) ? item.title : (item.sku || item.catalogItem?.title)}
                </Box>
                <ListItemQuantity>
                    Qty: {item.quantity}
                </ListItemQuantity>
              </ListItem>
            ))}
          </ListItemsWrapper>
        )}
      </Card>
    </GenericModal>
  );
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CourierStatusIndicator = styled.div``;

export const IndicatorLink = css`
  cursor: pointer;
`;

export const CourierStatusIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 13px;
`;

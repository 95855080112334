import {
  Fragment,
  memo,
  useMemo
} from 'react';

import { inject, observer } from 'mobx-react';
import { CurrentRefinementsProvided } from 'react-instantsearch-core';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

import WholesaleFiltersStore from 'stores/wholesale/wholesale-filters-store/wholesale-filters-store';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import Lozenge from 'components/lozenge';

import { CatalogAccordionHeading } from '../catalog-accordion-heading/catalog-accordion-heading';

import {
  Container,
  LozengeStyles
} from './catalog-refinement-applied-filters.styles';

interface CatalogRefinementAppliedFiltersProps extends CurrentRefinementsProvided {
  wholesaleFiltersStore?: WholesaleFiltersStore;
}

const CatalogRefinementAppliedFilters = connectCurrentRefinements(memo<CatalogRefinementAppliedFiltersProps>(({
  items,
  refine,
  wholesaleFiltersStore
}) => {
  const filteredItems = useMemo(() => {
    return items.filter(refinementItem => refinementItem.attribute !== 'type');
  }, [items]);

  const appliedFilterCount = useMemo<number>(() => {
    return filteredItems
      .reduce<number>((acc, curr) => {
        return acc + (curr.items?.length || 0);
      }, 0);
  }, [filteredItems]);

  if (!appliedFilterCount) return null;

  return (
    <CatalogAccordionHeading
      name="Applied filters"
      isInitiallyOpen={false}
      shouldDisplayCount={true}
      refinementCount={appliedFilterCount}
    >
      <Container>
        {filteredItems.map(item => (
          <Fragment key={item.label}>
            {
              item?.items?.map(nested => (
                <Lozenge
                  key={nested.label}
                  copy={wholesaleFiltersStore!.getLozengeLabel(item.attribute, nested.label)}
                  bg={ColourOption.background}
                  color={ColourOption.floomMidnightBlue}
                  styles={LozengeStyles}
                  hasRemoveAction
                  removeAction={() => refine(nested.value)}
                />
              ))
            }
          </Fragment>
        ))}
      </Container>
    </CatalogAccordionHeading>
  );
}));

export default inject((stores: FxStores) => ({
  wholesaleFiltersStore: stores.wholesaleFiltersStore
}))(observer(CatalogRefinementAppliedFilters));

import { css } from '@emotion/react';

import { colors } from 'utils/rebass-theme';

export const BaseStyles = css`
  > * {
    color: ${colors.floomMidnightBlue};
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;

    path {
      fill: ${colors.errorText}
    }
  }
`;

export const NotificationBorder = css`
  border: 2px solid ${colors.errorText};
`;

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const LoadingKeyFrame = (activeColour: string, gain: number): string => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
    fill: ${colors.shade40};
  }

  50% {
    transform: translate3d(0, -${gain}px, 0);
    fill: ${activeColour};
  }

  100% {
    transform: translate3d(0, 0, 0);
    fill: ${colors.shade40};
  }
`;

export const LoadingAnimation: any = styled(Flex)`
  margin: 5px;

  > svg {
    width: 12px;
    animation: ${({ activeColour, gain }: any): any => LoadingKeyFrame(activeColour, gain)} 2s infinite;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    animation-delay: ${({ delay }: any): number => delay ? delay / 2 : 0}s;
    animation-timing-function: cubic-bezier(.18,1.4,.4,1);
    fill: ${colors.shade40};
    width: ${({ size }: any): number => size}px;
    height: ${({ size }: any): number => size}px;
  }
`;

import { action, observable, makeObservable } from 'mobx';

import * as Types from './modal-store.types';

export default class ModalStore {
  constructor() {
    makeObservable(this, {
      modalInstances: observable,
      triggerModal: action,
      destroyModal: action
    });
  }

  public modalInstances: Types.ModalConfig[] = [];

  public triggerModal = <T = any>(modalConfig: Types.ModalConfig<T>): void => {
    this.modalInstances = [
      ...this.modalInstances,
      modalConfig
    ];
  };

  public destroyModal = (): void => {
    this.modalInstances.pop();
  };
}

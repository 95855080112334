import { ApolloQueryResult, ApolloError } from '@apollo/client';
import store from 'stores';

import { PaymentConnection } from 'generated-types.d';

import { GraphQL, Auth } from 'lib';

import { MERCHANT_PAYMENTS_QUERY } from 'features/settings/components/payments/graphql/payment-settings-apollo.queries';

import { PaymentVariables } from './payment-settings-apollo.service.types';

export default class PaymentSettingsService {
  deliveryStore = store.deliveryStore;

  toasterStore = store.toasterStore;

  userStore = store.userStore;

  merchantStore = store.merchantStore;

  paymentsStore = store.paymentsStore;

  public init(): void {
    this.fetchMerchantPayments();
  }

  public paginatePayments = async (): Promise<PaymentConnection> => {
    const isPaginating = true;
    const shouldReset = false;

    return this.fetchMerchantPayments(shouldReset, isPaginating);
  };

  public fetchMerchantPayments = async (shouldReset = false, isPaginating = false): Promise<PaymentConnection> => {
    if (shouldReset) {
      this.paymentsStore.resetMerchantPayments();
    } else if (isPaginating) {
      this.paymentsStore.toggleIsPaginating();
    }

    const merchantId = this.merchantStore.merchant ? this.merchantStore.merchant.id : Auth.getMerchantIdCookie();

    return GraphQL.query(MERCHANT_PAYMENTS_QUERY, { merchantId: merchantId || '', ...this.buildVariables() })
      .then(({ data }: ApolloQueryResult<{ paymentsConnection: PaymentConnection }>) => {
        if (isPaginating) {
          this.paymentsStore.paginateMerchantPayments(data.paymentsConnection);
          this.paymentsStore.toggleIsPaginating();
        } else {
          this.paymentsStore.setMerchantPayments(data.paymentsConnection);
        }

        return data.paymentsConnection;
      })
      .catch((error: ApolloError) => {
        this.toasterStore.popErrorToast('your payments', 'retrieve');
        window.Sentry.captureException(error);

        throw error;
      });
  };

  private buildVariables = (): PaymentVariables => {
    return {
      first: this.paymentsStore.merchantPaymentsIncrement,
      skip: this.paymentsStore.merchantPaymentsCount,
      orderBy: this.paymentsStore.merchantPaymentsSort,
      search: this.paymentsStore.merchantPaymentsSearch
    };
  };
}

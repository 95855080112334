import { isCharAlphaNumeric, getFirstNonAlphaNumericCharIndex } from 'lib/services/string-format/string-format.service';

const LOCAL_SEPERATOR: string = '_';

export const toDatoLocale = (input: string): string => {
  const numberOfNonAlphaNumerics: number = input.split('').map(char => {
    return isCharAlphaNumeric(char) ? null : char;
  }).filter(char => char).length;

  switch (numberOfNonAlphaNumerics) {
    case 0:
      return input.split('').map((char, index) => {
        if (index < 2) {
          return char.toLowerCase();
        } else if (index === 2) {
          return LOCAL_SEPERATOR + char.toUpperCase();
        }

        return char.toUpperCase();
      }).join('');

    case 1:
      const seperatorIndex: number = getFirstNonAlphaNumericCharIndex(input);

      return input.split('').map((char, index) => {
        if (index < seperatorIndex) {
          return char.toLowerCase();
        } else if (index === seperatorIndex) {
          return !isCharAlphaNumeric(char) ? LOCAL_SEPERATOR : LOCAL_SEPERATOR + char.toUpperCase();
        }

        return char.toUpperCase();
      }).join('');

    default: return input;
  }
};

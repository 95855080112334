import React, { Component, ReactNode } from 'react';

import { DeliveryConfig } from 'generated-types.d';

import { textStyles } from 'utils/rebass-theme';

import ItemMetaWrapper from 'components/meta-card/content-wrapper';

import * as Styles from './delivery-days.styles';

interface DeliveryDaysProps {
  data: DeliveryConfig;
}

class DeliveryDays extends Component<DeliveryDaysProps> {
  singleDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

  render(): ReactNode {
    return (
      <ItemMetaWrapper
        iconName="calendar"
        css={textStyles.body}
      >
        <span css={textStyles.body}>
          {this.singleDays.map((day, index) => (
            <Styles.Day
              key={`${day}-${index}`}
              as="span"
              isActive={this.props.data.deliveryTiming.days[index]}
            >
              {day}
            </Styles.Day>
          ))}
        </span>
      </ItemMetaWrapper>
    );
  }
}

export default DeliveryDays;

import { GraphQLError } from 'graphql';
import store from 'stores';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import Auth from '../auth/auth.service';

import { VALIDATION_CODE_DEFAULTS, HUMAN_READABLE_ERRORS } from './error.service.constants';
import { FxErrorCodes } from './error.service.types';

export default class ErrorService {
  ToasterStore = store.toasterStore;

  chatWidgetStore = store.chatWidgetStore;

  public static buildUserFacingError = (errors: GraphQLError[] | any[] | undefined): string => {
    if (errors && errors.length && errors[0].code) {
      if (errors[0].details && errors[0].details.length && Array.isArray(errors[0].details)) {
        const error = errors[0].details.find((detail: any) => Object.keys(HUMAN_READABLE_ERRORS).find(() => detail.includes(error)));

        if (error) {
          const humanError = Object.keys(HUMAN_READABLE_ERRORS).find(incluesError => error.includes(incluesError));

          if (humanError) {
            return HUMAN_READABLE_ERRORS[humanError];
          }
        } else {
          return VALIDATION_CODE_DEFAULTS[errors[0].code];
        }
      }
    }

    return 'Something went wrong';
  };

  public static doesUserExist = (errors: GraphQLError[] | any[] | undefined): boolean => {
    return !!errors && errors.some((error: any) => error.code && error.code === FxErrorCodes.UserNotExist);
  };

  public static isTokenInvalidated = (errors: GraphQLError[] | any[] | undefined): boolean => {
    return !!errors?.some((error: any) => error.code && error.code === FxErrorCodes.PermissionsInvalidated);
  };

  public static isSessionInvalidated = (errors: GraphQLError[] | any[] | undefined): boolean => {
    return !!errors?.some((error: any) => error.code && error.code === FxErrorCodes.SessionInvalidated);
  };

  public static actionFxError = (errors: GraphQLError[] | any[] | undefined): void => {
    const errorMessage = ErrorService.buildUserFacingError(errors);

    switch (true) {
      case ErrorService.doesUserExist(errors):
      case ErrorService.isTokenInvalidated(errors):
      case ErrorService.isSessionInvalidated(errors):
        store.toasterStore.popToast(errorMessage, ToastType.Error);
        store.chatWidgetStore.hideChatWidget();
        Auth.LogoutUser();
        location.reload();

        if (window['zE']) {
          window['zE']?.('webWidget', 'hide');
        }

        break;

      case !!errorMessage && errorMessage.length:
        store.toasterStore.popToast(errorMessage, ToastType.Error);

        break;

      default:
        break;
    }
  };
}

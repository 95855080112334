import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Flex, Box } from 'rebass';

import { DeliveryConfig } from 'generated-types.d';

import { textStyles } from 'utils/rebass-theme';
import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import { TooltipWrapper } from 'components/field-date-picker/field-date-picker.styles';
import Lozenge from 'components/lozenge';
import ItemMetaWrapper from 'components/meta-card/content-wrapper';
import Tooltip from 'components/tooltip';

interface DeliveryCoverageProps {
  data: DeliveryConfig;
}

class DeliveryCoverage extends Component<DeliveryCoverageProps> {
  private displayDeliveryCoverageTitle = (): string => {
    return this.hasMultipleCoverages()
      ? `${this.getTotalCoverages()} Coverage areas`
      : this.props.data.deliveryCoverage![0].label!;
  };

  private getTotalCoverages = (): number => this.props.data.deliveryCoverage!.length;

  private hasMultipleCoverages = (): boolean => !!this.props.data.deliveryCoverage && this.props.data.deliveryCoverage.length > 1;

  private renderCoverageTitles = (): string => {
    const coverageTitles: string[] = [];

    for (const coverage of this.props.data.deliveryCoverage!) {
      coverageTitles.push(coverage.title || '');
    }

    return coverageTitles.join(', ');
  };

  render(): ReactNode {
    if (!this.props.data.deliveryCoverage) return null;

    const title = this.displayDeliveryCoverageTitle();

    return (
      <ItemMetaWrapper
        iconName="radar"
        css={textStyles.body}
      >
        <Flex
          css={textStyles.body}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box
            title={title}
            css={css`
              margin-right: 10px;

              ${TooltipWrapper} {
                display: flex;
              }
            `}
          >
            <Tooltip
              message={this.renderCoverageTitles()}
              styles={css`
                white-space: nowrap;
                overflow: hidden;
                margin-right: 10px;
                text-overflow: ellipsis;
                width: 100%;
              `}
            >
              {title}
            </Tooltip>
          </Box>
          { this.props.data.default && (
            <Box
              css={css`
                min-width: 64px;
              `}
            >
              <Lozenge
                copy="Default"
                bg={ColourOption.middleGrey}
              />
            </Box>
          )}
        </Flex>
      </ItemMetaWrapper>
    );
  }
}

export default DeliveryCoverage;

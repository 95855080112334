import React, { Component, ReactNode } from 'react';

import * as Styles from './section-heading.styles';
import * as Types from './section-heading.types';

export default class SectionHeading extends Component<Types.SectionHeadingProps> {
  static defaultProps: Types.SectionHeadingProps = {
    hasSpacing: true,
    size: 'normal',
    renderMetadata: (): ReactNode => null
  };

  render(): ReactNode {
    return(
      <Styles.SectionHeading
        id={this.props.title}
        hasSpacing={this.props.hasSpacing}
        size={this.props.size}
      >
        <div>
          <span>
            {this.props.title}
          </span>
          { !!this.props.count && (
            <Styles.GroupCount>
              {this.props.count}
            </Styles.GroupCount>
          )}
        </div>
        {this.props.renderMetadata()}
      </Styles.SectionHeading>
    );
  }
}

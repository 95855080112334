import styled from '@emotion/styled';
import { Flex, Text } from 'rebass';

import { breakpoints, colors } from 'utils/rebass-theme';

export const DeliveryDateWrapper = styled.span`
  z-index: 10;
  margin-right: 15px;
`;

export const NextDeliveryDates = styled(Flex)`
  flex-direction: column;
  font-size: 12px;
  font-weight: 700;

  @media (max-width: ${breakpoints.small}) {
    font-size: 10px;
  }
`;

export const SupplierDeliveryCutoff = styled(Flex)`
  + ${(): any => SupplierDeliveryCutoff} {
    ::before {
      color: ${colors.floomBlue};
      content: "|";
      margin: 0 6px;
    }
  }
`;

export const SupplierDeliveryCutoffText = styled(Text)`
  margin-left: 4px;
  font-weight: 400;
`;

export const SupplierDeliveryCutoffTextHour = styled.span`
  color: ${colors.shade60};
`;

export const SupplierDeliveryCutoffContainer = styled(Flex)`
  margin-top: 4px;
`;

export const SupplierHeaderMobile = styled.span`
  display: none;

  @media (max-width: ${breakpoints.small}) {
    display: inline;
  }
`;

export const SupplierHeader = styled.span`
  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const DeliveryDatesTitle = styled(Text)`
  text-transform: uppercase;
  font-size: 10px;

  @media (max-width: ${breakpoints.small}) {
    font-size: 9px;
  }
`;

import { Currency } from 'generated-types.d';

export const getUserLanguage = (): string => {
  if (window.navigator.languages) {
    return window.navigator.languages[0];
  }

  return window.navigator['userLanguage'] || window.navigator.language;
};

export const getTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getLocaleByCurrency = (currency: Currency): string => {
  switch (currency) {
    case Currency.Gbp:
      return 'UK';

    case Currency.Usd:
      return 'US';

    default:
      return '';
  }
};

import {
  FC,
  Fragment,
  memo,
  useContext,
  useEffect
} from 'react';

import { Box } from 'rebass';

import { ListType } from 'generated-types.d';

import { listItemImage } from 'features/lists/lists.helpers';
import {
  Columns,
  DisabledItem,
  ItemDetails,
  ItemTitle,
  ListCellTitle,
  MetaColumn,
  Thumbnail
} from 'features/lists/lists.styles';
import { SearchListFieldConfig } from 'features/lists/lists.types';

import Icon from 'components/icon';

import { SearchResultContext } from '../catalog-inline-search-field/catalog-inline-search-body';
import { CATALOG_INLINE_SEARCH_FIELD_CONFIG } from '../catalog-inline-search.config';
import { SearchReducerType } from '../catalog-inline-search.reducers';

import {
  SearchResultInner
} from './inline-search-result.styles';
import {
  InlineSearchResultProps
} from './inline-search-result.types';

export const InlineSearchResult: FC<InlineSearchResultProps> = memo(({
  hit,
  isFocussed,
  isLoading,
  listType,
  onMouseEnter,
  onMouseDown
}) => {
  const { state, dispatch } = useContext(SearchResultContext);

  useEffect(() => {
    const itemId = hit.objectID;

    dispatch({
      type: SearchReducerType.SetIsLoading,
      payload: {
        itemId,
        isLoading
      }
    });
  }, [isLoading]);

  const shouldDisplay = (field: SearchListFieldConfig): boolean => {
    return !field.supportedTypes?.length || (!!hit?.type && !!field.supportedTypes.includes(hit.type));
  };

  const fieldConfig = CATALOG_INLINE_SEARCH_FIELD_CONFIG[listType];

  if (!fieldConfig) return null;

  const imageUrls = hit.images?.reduce?.((acc: string[], curr): string[] => [...acc, ...curr.fileName.map(name => `${curr.url}/${name}`)], []);

  return (
    <SearchResultInner
      key={hit.objectID}
      isFocussed={isFocussed}
      onMouseEnter={onMouseEnter}
      onMouseDown={event => onMouseDown(event, state?.[hit.objectID]?.selections || {})}
    >
      <Thumbnail
        imageUrl={listItemImage(imageUrls, true)}
      >
        <Icon
          iconName="flower-squiggle"
        />
      </Thumbnail>
      <Columns>
        <ItemDetails>
          <ItemTitle listType={listType}>
            <Box>
              {fieldConfig.titleField.renderValue?.(hit)}
            </Box>
          </ItemTitle>
          {listType === ListType.Advanced && (
            <Fragment>
              {fieldConfig.fields?.map((field, fieldIndex) => {
                if (!shouldDisplay(field)) return null;

                return (
                  <MetaColumn
                    key={`${field.key}-${fieldIndex}`}
                    customStyles={field.customStyles}
                    isMetadata={field.isMeta}
                  >
                    <ListCellTitle>
                      {field.renderValue?.(hit) || (
                        <DisabledItem>--</DisabledItem>
                      )}
                    </ListCellTitle>
                  </MetaColumn>
                );
              })}
            </Fragment>
          )}
        </ItemDetails>
      </Columns>
    </SearchResultInner>
  );
});

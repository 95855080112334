import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hex2Rgba from 'hex2rgba';
import { Box, Flex } from 'rebass';

import { borderRadius, breakpoints, colors, shadows, textStyles } from 'utils/rebass-theme';

export const StyledBorderItem = styled(Flex)<{ isSelected: boolean }>`
  ${({ isSelected }: any) => isSelected ?
    css`
      box-shadow: ${shadows.xSmall};
      align-items: flex-start;
      border-color: ${colors.middleGrey};
    ` :
    css`
      align-items: center;
      border-color: ${hex2Rgba(colors.middleGrey, '0.6')};
    `};

  border-width: 1px;
  border-style: solid;
  border-radius: ${borderRadius.xLarge};
  padding: 11px 16px;
  width: 100%;
  background: #fff;
  margin-bottom: 6px;
  margin-left: 16px;
  margin-right: 16px;

  @media (max-width: ${breakpoints.small}) {
    margin-left: 10px;
    margin-right: 10px;
    align-items: flex-start;
  }
`;

export const RadioLabel: any = styled.span<{ isDisabled: boolean }>`
  ${textStyles.body};
  width: 100%;
  font-size: 16px;
  position: relative;
  user-select: none;
  color: ${({ isDisabled: id }: any): any => id
    ? colors.middleGrey
    : ({ isError }: any): string => isError ? colors.errorText : colors.floomMidnightBlue};
`;

export const RadioIconContainer: any = styled(Box)<{ isSelected: boolean }>`
  padding-top: ${({ isSelected }): string => isSelected ? '1px' : '2px'};

  @media (max-width: ${breakpoints.small}) {
    padding: 0;
  }
`;

import { ToastVerb } from './toaster-store.types';

export const TOASTER_TYPES: { [type in ToastVerb]: any } = {
  save: {
    successAction: 'saved',
    errorAction: 'saving',
    prefix: ''
  },
  create: {
    successAction: 'created',
    errorAction: 'creating',
    prefix: ''
  },
  change: {
    successAction: 'changed',
    errorAction: 'changing',
    prefix: ''
  },
  finalise: {
    successAction: 'finalised',
    errorAction: 'finalising',
    prefix: ''
  },
  add: {
    successAction: 'added',
    errorAction: 'adding',
    prefix: ''
  },
  get: {
    successAction: 'retrieved',
    errorAction: 'retrieving',
    prefix: ''
  },
  delete: {
    successAction: 'deleted',
    errorAction: 'deleting',
    prefix: ''
  },
  upload: {
    successAction: 'uploaded',
    errorAction: 'uploading',
    prefix: ''
  },
  remove: {
    successAction: 'removed',
    errorAction: 'removing',
    prefix: ''
  },
  retrieve: {
    successAction: 'retrieved',
    errorAction: 'retrieving',
    prefix: ''
  },
  update: {
    successAction: 'updated',
    errorAction: 'updating',
    prefix: ''
  },
  enable: {
    successAction: 'enabled',
    errorAction: 'enabling',
    prefix: ''
  },
  disable: {
    successAction: 'disabled',
    errorAction: 'disabling',
    prefix: ''
  },
  discard: {
    successAction: 'discarded',
    errorAction: 'discarding',
    prefix: 'Changes to '
  },
  publish: {
    successAction: 'published',
    errorAction: 'publishing',
    prefix: ''
  },
  unpublish: {
    successAction: 'unpublished',
    errorAction: 'unpbublishing',
    prefix: ''
  },
  subscribe: {
    successAction: 'subscribed',
    errorAction: 'subscribing',
    prefix: ''
  },
  send: {
    successAction: 'sent',
    errorAction: 'sending',
    prefix: ''
  },
  cancel: {
    successAction: 'cancelled',
    errorAction: 'cancelling',
    prefix: ''
  }
};

import { ApolloError } from '@apollo/client';
import {
  Address,
  Customer,
  Order,
  OrderUpdateInput,
  OrderItem
} from 'generated-types';
import store from 'stores';

import { Auth, Analytics, HTMLService } from 'lib';

import {
  OrderFormModalType,
  OrderFormFieldID,
  OrderFormModalMetadata,
  OrderFormModalDataItem,
  OrderStatusSlug,
  ExtendedOrder
} from 'features/orders/orders.types';
import { OrderAnalytics } from 'features/orders/services';

import { OrdersAPIService } from '..';

const addressFields: OrderFormFieldID[] = [
  'businessName',
  'recipientFullName',
  'phone',
  'address1',
  'address2',
  'city',
  'country',
  'postalCode',
  'region'
];

const nullifyField = (address: OrderUpdateInput): any => {
  // TODO In the future we should add a global option to be able to nullify
  // certain fields as we wish. This is a temporary fix.
  // We are nullifying Address2 field specifically so it doesn't error out
  // if it's an empty string.
  return Object.keys(address).reduce((fieldAccumulator, currField) => {
    if (currField === 'address2' && address[currField] === '') {
      address[currField] = null;
    }

    return {
      ...fieldAccumulator,
      [currField]: address[currField]
    };
  }, {});
};

class OrderEditService {
  private OrdersStore = store.ordersStore;

  private ToasterStore = store.toasterStore;

  public openOrderFormModal = (
    type: OrderFormModalType,
    data: OrderFormModalDataItem[],
    config: OrderFormModalMetadata
  ): void => {
    this.OrdersStore.openOrderFormModal(type, data, config);
  };

  public closeOrderFormModal = (): void => {
    this.OrdersStore.closeOrderFormModal();
  };

  public createOrderNote = async (
    content: string,
    floom: boolean,
    config: OrderFormModalMetadata
  ): Promise<Order> => {
    return OrdersAPIService.updateOrder({
      orderNotes: {
        create: [{
          floom: floom,
          content: content,
          read: false,
          createdBy: {
            // TODO Get user ID in a smarter way
            // https://app.clubhouse.io/floom/story/1938/fe-update-create-order-note-mutator-to-retrieve-createdby-user-id-in-a-less-volatile-way
            connect: {
              id: Auth.getUserID()
            }
          }
        }]
      }
    }, config.orderNo)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Order note', 'create');

        return Promise.resolve(data);
      })
      .catch((error: any) => Promise.reject(error));
  };

  public updateOrderNote = async (
    content: string,
    floom: boolean,
    config: OrderFormModalMetadata
  ): Promise<Order> => {
    return OrdersAPIService.updateOrder({
      orderNotes: {
        update: [{
          where: {
            id: config.itemId
          },
          data: {
            content: content,
            read: false,
            floom: floom,
            createdBy: {
              connect: {
                id: Auth.getUserID()
              }
            }
          }
        }]
      }
    }, config.orderNo)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Order note', 'update');

        return Promise.resolve(data);
      })
      .catch((error: any) => Promise.reject(error));
  };

  public updateStatusFromDetail = (statusSlug: OrderStatusSlug, order: Order): Promise<ExtendedOrder> => {
    return this.updateOrderStatus(statusSlug, order)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Order status', 'update');
        this.OrdersStore.setOrder(data);

        OrderAnalytics.onEditStatus(
          Analytics.OrderEditEntry.OrderDetail,
          statusSlug,
          order
        );

        return data;
      })
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('the order status', 'update');

        return Promise.reject(error);
      });
  };

  public updateDeliveryDate = (orderUpdates: OrderUpdateInput, config: OrderFormModalMetadata): Promise<Order> => {
    return OrdersAPIService.updateOrder(orderUpdates, config.orderNo)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Order delivery date', 'update');

        return Promise.resolve(data);
      })
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('the order delivery date', 'update');

        return Promise.reject(error);
      });
  };

  public updateDeliveryInstruction = (
    orderUpdates: OrderUpdateInput,
    config: OrderFormModalMetadata
  ): Promise<Order> => {
    return OrdersAPIService.updateOrder({ deliveryInstructions: HTMLService.newLineToBreak(orderUpdates.deliveryInstructions!) }, config.orderNo)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Order delivery instructions', 'update');

        return Promise.resolve(data);
      })
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('the order delivery instructions', 'update');

        return Promise.reject(error);
      });
  };

  public updateDeliverySafePlace = (orderUpdates: OrderUpdateInput, config: OrderFormModalMetadata): Promise<Order> => {
    return OrdersAPIService.updateOrder(orderUpdates, config.orderNo)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Delivery safe place', 'update');

        return Promise.resolve(data);
      })
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('this order delivery safe place', 'update');

        return Promise.reject(error);
      });
  };

  public updateGiftMessage = (orderUpdates: any, config: OrderFormModalMetadata): Promise<Order> => {
    const currentOrder = this.OrdersStore.currentOrder!.orderItems.find((orderItem: OrderItem) => orderItem.id === config.itemId);
    const updatedGiftMessages = [...currentOrder.giftMessages.slice(0, config.index)];

    if (orderUpdates.giftMessage.length) {
      updatedGiftMessages.push(orderUpdates.giftMessage);
    }

    updatedGiftMessages.push(...currentOrder.giftMessages.slice(config.index! + 1));

    if (!updatedGiftMessages) {
      this.ToasterStore.popErrorToast('the order item\'s gift message', 'change');

      return Promise.reject();
    }

    return OrdersAPIService.updateOrder({
      orderItems: {
        update: [{
          where: {
            id: config.itemId
          },
          data: {
            giftMessages: {
              set: updatedGiftMessages
            }
          }
        }]
      }
    }, config.orderNo)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Order item gift message', 'update');

        return Promise.resolve(data);
      })
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('the order item\'s gift message', 'change');

        return Promise.reject(error);
      });
  };

  public updateDeliveryAddress = (
    orderUpdates: OrderUpdateInput,
    config: OrderFormModalMetadata
  ): Promise<Order> => {
    return OrdersAPIService.updateOrder({
      shippingAddress: {
        update: nullifyField(orderUpdates)
      }
    }, config.orderNo)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Delivery address', 'update');

        return Promise.resolve(data);
      })
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('the order delivery address', 'change');

        return Promise.reject(error);
      });
  };

  public updateSenderDetails = (
    orderUpdates: Customer & Address,
    config: OrderFormModalMetadata
  ): Promise<Order> => {
    return OrdersAPIService.updateOrder({
      customerUser: {
        update: {
          email: orderUpdates.email,
          givenName: orderUpdates.givenName,
          familyName: orderUpdates.familyName
        }
      },
      billingAddress: {
        update: {
          recipientFullName: `${orderUpdates.givenName} ${orderUpdates.familyName}`.trim(),
          phone: orderUpdates.phone
        }
      }
    }, config.orderNo)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Sender Details', 'update');

        return Promise.resolve(data);
      })
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('the order sender details', 'change');

        return Promise.reject(error);
      });
  };

  public updateBillingAddress = (orderUpdates: OrderUpdateInput, config: OrderFormModalMetadata): Promise<Order> => {
    return OrdersAPIService.updateOrder({
      billingAddress: {
        update: nullifyField(orderUpdates)
      }
    }, config.orderNo)
      .then((data: ExtendedOrder) => {
        this.ToasterStore.popSuccessToast('Billing address', 'update');

        return Promise.resolve(data);
      })
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('order billing address', 'change');

        return Promise.reject(error);
      });
  };

  public updateOrderStatus = (statusSlug: OrderStatusSlug, order: Order): Promise<ExtendedOrder> => {
    OrdersAPIService.updateNewStatus(order.orderNo, order);

    return OrdersAPIService.updateOrder({
      status: {
        connect: {
          slug: statusSlug
        }
      }
    }, order.orderNo);
  };

  public editDeliveryInstructions = (value: string, config: OrderFormModalMetadata): void => {
    this.openOrderFormModal(
      'deliveryInstructions',
      [{
        id: 'deliveryInstructions',
        value: value
      }],
      config
    );
  };

  public editDeliverySafePlace = (value: string, config: OrderFormModalMetadata): void => {
    this.openOrderFormModal(
      'deliverySafePlace',
      [{
        id: 'deliverySafePlace',
        value: value
      }],
      config
    );
  };

  public editDeliveryAddress = (addressData: Address, config: OrderFormModalMetadata): void => {
    this.openOrderFormModal(
      'deliveryAddress',
      this.buildFieldConfig(addressFields, addressData),
      config
    );
  };

  public editSenderDetails = (sender: Customer & Address, config: OrderFormModalMetadata): void => {
    this.openOrderFormModal(
      'senderDetails',
      this.buildFieldConfig(['givenName', 'familyName', 'phone', 'email'], sender),
      config
    );
  };

  private buildFieldConfig = (fields: OrderFormFieldID[], data: any): any => {
    return fields.reduce((acc: any, currField: any): any => {
      return [
        ...acc,
        {
          id: currField,
          value: data[currField]
        }
      ];
    }, []);
  };
}

export default new OrderEditService();

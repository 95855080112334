import styled from '@emotion/styled';
import { Flex } from 'rebass';

import {
  colors,
  borderRadius,
  textStyles,
  breakpoints
} from 'utils/rebass-theme';

import * as Types from './no-results-generic.types';

export const Container = styled(Flex)<{ config: Types.NoResultsConfig }>`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${colors.middleGrey};
  padding: ${(props): string => props.config.boxPadding};
  border-radius: ${borderRadius.medium};

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

export const Heading = styled.h4<{ config: Types.NoResultsConfig }>`
  font-size: ${(props): string => props.config.fontSize};
  margin-bottom: ${(props): string => props.config.headingSpacing};
  color: ${colors.floomMidnightBlue};

  @media (max-width: ${breakpoints.small}) {
    text-align: center;
  }
`;

export const Message = styled.p<{ config: Types.NoResultsConfig }>`
  ${textStyles.body};
  margin-bottom: 0;
  max-width: 300px;
  font-size: ${(props): string => props.config.fontSize};
  color: ${colors.floomMidnightBlue};

  @media (max-width: ${breakpoints.small}) {
    text-align: center;
  }
`;

import { gql } from '@apollo/client';

import { GraphQL } from 'lib';

import { CreateTransactionData } from './transaction-create-modal.types';

export const MERCHANT_DATA_QUERY = gql`
  query Merchant(
    $id: ID!
  ) {
    merchant(
      where: {
        id: $id
      }
    ) {
      timezone
      currency
    }
  }
`;

export const ORDER_DATA_QUERY = gql`
  query Order(
    $id: ID!
  ) {
    order(
      where: {
        id: $id
      }
    ) {
      orderDeliveryConfig {
        price
      }
      payment {
        id
        chargedAmount
      }
      transactions {
        merchantRemittance
        source
        remittanceType
        type
      }
      merchant {
        timezone
        currency
      }
    }
  }
`;

export const CREATE_CUSTOM_ADJUSTMENT = gql`
  mutation CreateCustomAdjustment($data: AdjustmentCreateCustomInput!) {
    createCustomAdjustment(data: $data) {
      id
    }
  }
`;

export const getData = async (
  merchantId: string,
  orderId?: string
): Promise<CreateTransactionData> => {
  if (!!orderId) {
    const orderResult = await GraphQL.query<{ order: any }>(
      ORDER_DATA_QUERY,
      {
        id: orderId
      }
    );

    return {
      merchant: orderResult.data.order.merchant,
      order: orderResult.data.order
    };
  }

  const merchantQueryResult = await GraphQL.query<{ merchant: any }>(
    MERCHANT_DATA_QUERY,
    {
      id: merchantId
    }
  );

  return {
    merchant: merchantQueryResult.data.merchant
  };
};

import { observable, action, set, makeObservable } from 'mobx';

import {
  CourierBookingConnection
} from 'generated-types.d';

import {
  CourierListPageRoute,
  SelectedCourierListFilters,
  CourierListGroupOption
} from 'features/courier-bookings/courier-bookings.types';

import * as Types from './courier-list-store.types';

export default class CourierListStore {
  public resetAppliedFilters = (): SelectedCourierListFilters => {
    return {
      Currency: new Set(),
      Status: new Set()
    };
  };

  constructor() {
    makeObservable(this, {
      courierList: observable.deep,
      courierListLoading: observable,
      searchFilterValue: observable,
      pagination: observable,
      paginationIncrement: observable,
      listLayout: observable,
      selectedCourierListFilters: observable,
      setCourierList: action,
      setInitialPagination: action,
      setListLoading: action,
      resetAppliedFilters: action
    });
  }

  selectedCourierListFilters: SelectedCourierListFilters = this.resetAppliedFilters();

  courierList: Types.CourierListType | Record<string, never> = {};

  courierListLoading: boolean = true;

  searchFilterValue: string = '';

  listLayout: CourierListPageRoute = 'all';

  pagination: any = {};

  paginationIncrement: number = 20;

  public setCourierList = (courierListGroups: Types.CourierListType): void => {
    this.courierList = courierListGroups;
    this.setListLoading(false);
  };

  public onPaginate(groupName: CourierListGroupOption): void {
    this.pagination[groupName] = this.pagination[groupName] + this.paginationIncrement;
  }

  public appendBookings = (bookingData: CourierBookingConnection, groupName: CourierListGroupOption): void => {
    const bookingGroup = {
      ...bookingData,
      edges: [
        ...this.courierList[groupName].edges,
        ...bookingData.edges
      ]
    };

    set(this.courierList[groupName], bookingGroup);
    this.setListLoading(false);
  };

  public setInitialPagination = (courierListGroups: Types.CourierListGroupOption[]): void => {
    this.pagination = courierListGroups.reduce((acc: any, currGroup) => ({
      ...acc, [currGroup]: 0
    }), {});
  };

  public setListLoading = (isLoading = true): void => {
    this.courierListLoading = isLoading;
  };

  public setListLayout = (listLayout: CourierListPageRoute): void => {
    this.listLayout = listLayout;
  };
}

import React, { Component } from 'react';

import Imgix from 'react-imgix';

import { ImgixImageProps } from './imgix-image.types';

export default class ImgixImage extends Component<ImgixImageProps> {
  render(): React.ReactNode {
    return(
      <Imgix
        src={`${process.env.FLOOMX_IMGIX_URL}${this.props.config.path}`}
        imgixParams={{
          mode: 'crop',
          jpegQuality: 80,
          interlace: true,
          auto: 'format',
          fm: 'pjpg',
          ...this.props.params
        }}
        {...this.props}
      />
    );
  }
}

import styled from '@emotion/styled';
import { Box, Text } from 'rebass';

import { colors, fontSizes, space } from 'utils/rebass-theme';

export const InputWrapper: any = styled(Box)`
  margin-bottom: 40px;
  position: relative;
`;

export const InputLabel: any = styled(Text)`
  font-weight: 600;
  margin-bottom: ${space[3]}px;
  font-size: ${fontSizes.base}px;
  color: ${colors.floomMidnightBlue};
  text-align: left;
`;

export const InputCVV: any = styled(InputLabel)`
  max-width: 100px;
`;

export const StripeInputWrapper = styled(Box)<any>`
  .StripeElement {
    background: ${colors.uiFills};
    padding: 0.85rem 0.9rem;
    border: 2px solid ${colors.middleGrey};
    border-radius: 4px;
    transition: border-color .2s ease-in-out;
    color: ${colors.floomMidnightBlue};
    position: relative;

    &--focus {
      border-color: ${colors.floomBlue};
    }

    &--complete {
      border-color: ${colors.validationText};

      &:after {
        display: ${(props): any => props.showTick ? 'block' : 'none'};
        content: '';
        position: absolute;
        width: 16px;
        height: 12px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS44MTcgMi40NjU3M0w2LjMwNDA5IDExLjkxNDJMMC40MjYwMjUgNi4wNzY5M0wyLjQ4MzA2IDQuMDM0MThMNi4zMDM5MSA3LjgyODQ5TDEzLjc1OTggMC40MjMxNTdMMTUuODE3IDIuNDY1NzNaIiBmaWxsPSIjNjJCRDQwIi8+Cjwvc3ZnPgo=');
        transform: translateY(-50%);
        right: 15px;
        top: 50%;
      }
    }

    &--invalid {
      border-color: ${colors.errorText};
      color: ${colors.floomMidnightBlue};
    }

    &--webkit-autofill {
      background: ${colors.uiFills};
      color: ${colors.floomMidnightBlue};
    }
  }
`;

import { FC, Fragment, ReactElement } from 'react';

import { Box } from 'rebass';

import { CheckoutItem } from '../checkout-list/checkout-list.styles';

import { CheckoutItemWithLabel, CheckoutItemLabel } from './list-item-value.styles';

type ListItemValueProps = {
  label: string;
  value: ReactElement | string;
}

export const ListItemValue: FC<ListItemValueProps> = ({
  label, value
}): ReactElement => (
  <Fragment>
    <CheckoutItem>
      {value}
    </CheckoutItem>
    <CheckoutItemWithLabel>
      <CheckoutItemLabel>
        {label}
      </CheckoutItemLabel>
      <Box ml={2}>
        {value}
      </Box>
    </CheckoutItemWithLabel>
  </Fragment>
);

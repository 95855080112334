import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { breakpoints } from 'utils/rebass-theme';

import { ConversationView } from '../../context/ContactContext';

export const Wrapper = styled(Flex)<{view: ConversationView}>`
  height: 100%;
  width: 100%;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  position: relative;

  @media (max-width: ${breakpoints.large}) {
    width: 100%;
    left: ${({ view }) => view === ConversationView.MESSAGES ? '0%' : '100%'};
    transition: left 0.1s ease-in;
    position: absolute;
  }
`;

export const Content = styled(Flex)`
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
`;

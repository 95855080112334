import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Flex } from 'rebass';

import { Channel } from 'generated-types.d';

import { PermissionsService } from 'lib';

import { ORDER_STATUSES, SimpleOrderStatus } from 'features/orders/orders.constants';
import { OrderStatusSlug } from 'features/orders/orders.types';

import Button from 'components/button';

import { OrderStatusConfigType } from '../order-item.config';

import * as Styles from './status-quick-switch.styles';
import * as Types from './status-quick-switch.types';
import StatusSelect from './status-select';

class StatusQuickSwitch extends Component<Types.StatusQuickSwitchProps> {
  private getCorrectStatuses = (availableStatuses: OrderStatusConfigType['statusActions']): SimpleOrderStatus[] => {
    return ORDER_STATUSES.filter(status => {
      if (status.slug === OrderStatusSlug.Cancelled && !PermissionsService.isInternalRole() && this.props.orderChannel === Channel.Floom) {
        return false;
      }

      return availableStatuses.includes(status.slug);
    });
  };

  private shouldRenderInactiveDropdown = (): boolean => this.props.currentStatus === 'failedDelivery'
    || this.props.currentStatus === 'cancelled'
    || this.props.currentStatus === 'delivered';

  private onButtonClick = (statuses: SimpleOrderStatus[]): void => {
    this.props.onChange(statuses[0].slug);
  };

  private onStatusChange = (value: any): void => {
    this.props.onChange(value);
  };

  render = (): ReactNode => {
    const statuses = this.getCorrectStatuses(this.props.config.statusActions);

    return (
      <Flex css={this.props.layout === 'table' && css`border-radius: 3px; overflow: hidden;`}>
        {this.shouldRenderInactiveDropdown() ? (
          <>
            {(statuses.length > 1 && !this.props.isLoading) && (
              <StatusSelect
                layout={this.props.layout}
                hasText={true}
                currentStatus={ORDER_STATUSES.find(status => status.slug === this.props.currentStatus)!.title}
                actions={statuses}
                orderNo={this.props.orderNo}
                onChange={this.onStatusChange}
              />
            )}
          </>
        )
          : (
            <>
              <Styles.StatusActionButton
                layout={this.props.layout}
                onClick={(): void => this.onButtonClick(statuses)}
              >
                <Button
                  copy={`Set as "${statuses[0].title}"`}
                  isParentWidth={true}
                  isLoading={this.props.isLoading}
                />
              </Styles.StatusActionButton>
              {(statuses.length > 1 && !this.props.isLoading) && (
                <StatusSelect
                  layout={this.props.layout}
                  hasText={false}
                  actions={statuses}
                  orderNo={this.props.orderNo}
                  onChange={this.onStatusChange}
                />
              )}
            </>
          )}
      </Flex>
    );
  };
}

export default StatusQuickSwitch;

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import {
  NavPages
} from 'global.types';
import { inject, observer } from 'mobx-react';
import { Flex, Box, Text } from 'rebass';

import {
  DeliveryConfig,
  PlanFeature
} from 'generated-types.d';

import {
  ProductService,
  MerchantService
} from 'lib';

import { colors, textStyles } from 'utils/rebass-theme';
import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import CheckboxToggle from 'components/checkbox-toggle';
import { EntityListRow } from 'components/entity-list/entity-list.styles';
import Icon from 'components/icon';
import Lozenge from 'components/lozenge';
import StyledLink from 'components/styled-link/styled-link';

import FloomCollectionLozenge from '../floom-collection-lozenge';

import * as Styles from './table-layout-item.styles';
import * as Types from './table-layout-item.types';
import VariationList from './variation-list';

class TableLayoutItem extends Component<Types.TableLayoutItemProps, Types.TableLayoutItemState> {
  state = {
    isOpen: false
  };

  private toggleActiveState = async (isChecked: boolean): Promise<void> => {
    this.props.onActiveStateChange(isChecked, this.props.item);
  };

  private toggleItems = (): void => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  private pluralise = (copy: string, shouldPluralise: boolean): string => shouldPluralise ? `${copy}s` : copy;

  private buildChannels = (): string => {
    const channels = [];

    for (const channel of this.props.item.channels!) {
      if (!!channel.channel) {
        channels.push(channel.channel);
      }
    }

    return channels.join(', ');
  };

  private formatDeliveryConfigTitle = (config: DeliveryConfig): string => {
    const maxLength = 20;
    const isConfigTitleTooLong = config.title?.length >= maxLength;

    return isConfigTitleTooLong ? `${config.title.substring(0, maxLength)}...` : config.title;
  };

  private renderFloomCollectionTag = (): ReactNode => {
    return (
      <Box>
        <FloomCollectionLozenge />
      </Box>
    );
  };

  private renderDeliveryConfigs = (): ReactNode => {
    return this.props.item.deliveryConfigs!.map(config => {
      return (
        <Styles.DeliveryZoneItem
          key={config.id}
          title={config.title}
        >
          <Lozenge
            copy={this.formatDeliveryConfigTitle(config)}
            bg={ColourOption.paleGrey}
            color={ColourOption.floomMidnightBlue}
            styles={css`
              font-weight: 100;
            `}
          />
        </Styles.DeliveryZoneItem>
      );
    });
  };

  private canActivateProduct = (): boolean => {
    const hasFloom = MerchantService.hasPlanFeature(PlanFeature.Floom, this.props.item.merchant);
    const hasWebsite = MerchantService.hasPlanFeature(PlanFeature.Website, this.props.item.merchant);
    const isActiveState = MerchantService.isActiveMerchantStage(this.props.item.merchant);

    return (hasFloom || hasWebsite) && isActiveState;
  }

  render(): ReactNode {
    const { item } = this.props;
    const variationCount = item.variations!.length;
    const isTemplateProduct = ProductService.isTemplateProduct(item);
    const isTemplateProductNotEditable = ProductService.isTemplateProductNotEditable(this.props.item);
    const isCollectionManagerMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionManager, this.props.merchantStore!.merchant);
    const isProductActive = ProductService.isProductActive(item, this.props.merchantStore!.merchant || undefined);

    return (
      <EntityListRow role="rowgroup">
        <Flex
          alignItems="center"
          css={Styles.ItemTitleRow}
          onClick={this.toggleItems}
          role="row"
        >
          <Box
            width="70px"
            role="cell"
          >
            <CheckboxToggle
              onChange={this.toggleActiveState}
              switchBgActive={colors.validationText}
              switchBgInactive={colors.errorText}
              activeCopy="On"
              inactiveCopy="Off"
              controlValue={true}
              isDisabled={this.props.isMakingChanges || isTemplateProductNotEditable || !this.canActivateProduct()}
              isChecked={isProductActive}
            />
          </Box>
          <Box
            ml="40px"
            flex="0 0 15%"
            css={{ ...textStyles.subhead }}
            role="rowheader"
          >
            <Link to={`${NavPages.ProductsEdit}${item.id}`}>
              <StyledLink>{item.title}</StyledLink>
            </Link>
          </Box>
          <Box
            ml="60px"
            flex="0 0 10%"
            css={textStyles.body}
            role="cell"
          >
            {item.type && item.type[0].title}
          </Box>
          <Box
            ml="60px"
            flex="0 0 10%"
            css={textStyles.body}
            role="cell"
          >
            {this.buildChannels()}
          </Box>
          <Box
            ml="40px"
            mr="10px"
            flex="0 0 auto"
            css={textStyles.body}
            role="cell"
          >
            { (isTemplateProduct && !isCollectionManagerMerchant)
              ? this.renderFloomCollectionTag()
              : this.renderDeliveryConfigs()
            }
          </Box>
          <Flex
            ml="auto"
            alignItems="center"
            role="cell"
          >
            <Text
              mr={4}
              fontSize={1}
              css={textStyles.body}
              fontWeight="bold"
            >
              {variationCount} {this.pluralise('variation', variationCount > 1)}
            </Text>
            <Icon iconName={this.state.isOpen ? 'expand-open' : 'expand-closed'} />
          </Flex>
        </Flex>
        <Styles.ItemBody
          isOpen={this.state.isOpen}
          role="row"
        >
          <VariationList
            hasVariations={item.variations! && !!item.variations!.length}
            item={item}
          />
        </Styles.ItemBody>
      </EntityListRow>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  toasterStore: stores.toasterStore,
  modalStore: stores.modalStore
}))(observer(TableLayoutItem));

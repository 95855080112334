import { gql } from '@apollo/client';
import store from 'stores';

import { WeeblyLoginLinkInput } from 'generated-types.d';

import { GraphQL } from 'lib';

const MERCHANTS_WEEBLY_SSO_MUTATION = gql`
  mutation RequestWeeblyLoginLink($data: WeeblyLoginLinkInput!) {
    requestWeeblyLoginLink (data: $data) {
      link
    }
  }
`;

const errorMessageForMerchants = 'merchants';

export class MerchantWeeblyAPIService {
  private toasterStore = store.toasterStore;

  private merchantStore = store.merchantStore;

  public fetchMerchantWeeblySSOLink = async (): Promise<boolean> => {
    const merchantId = this.merchantStore.merchant?.id;
    const loginInput: WeeblyLoginLinkInput = {
      merchant: {
        connect: {
          id: merchantId
        }
      }
    };

    return GraphQL.query(MERCHANTS_WEEBLY_SSO_MUTATION, { data: loginInput })
      .then(({ data }: any) => {
        const link = data.requestWeeblyLoginLink.link;
        window.open(link, '_blank');

        return true;
      })
      .catch(() => {
        this.toasterStore.popErrorToast(errorMessageForMerchants, 'retrieve');

        return true;
      });
  };
}

import React from 'react';

import Button from 'components/button';
import FormFieldWrapper from 'components/create-labeled-component';
import FieldText from 'components/field-text';

import { PasswordWrapper } from './change-password.styles';
import { NewPasswordProps } from './change-password.types';

export const NewPassword = (props: NewPasswordProps): JSX.Element => {
  const isCorrectLength: boolean = props.newPassword.length >= 8;
  const isMatching: boolean = props.newPassword === props.newPasswordConfirmation;
  const submitDisabled: boolean = !props.newPassword || !isCorrectLength || !isMatching;

  return (
    <PasswordWrapper>
      <FormFieldWrapper title="New password">
        <FieldText
          onChange={(e): void => props.onChangeNewPassword('newPassword', e.target.value)}
          value={props.newPassword}
          size="normal"
          error={!isCorrectLength}
          errorMessage="Your new password needs a minimum of 8 characters"
          type="password"
          autoFocus={true}
        />
      </FormFieldWrapper>
      <FormFieldWrapper title="Confirm new password">
        <FieldText
          onChange={(e): void => props.onChangeNewPasswordConfirmation('newPasswordConfirmation', e.target.value)}
          value={props.newPasswordConfirmation}
          size="normal"
          type="password"
          error={!isMatching}
          errorMessage="New password needs to match"
        />
      </FormFieldWrapper>
      <div onClick={props.onButtonClick}>
        <Button
          size="normal"
          appearance="primary"
          copy="Reset password"
          isDisabled={submitDisabled}
        />
      </div>
    </PasswordWrapper>
  );
};

import React from 'react';

import { Box } from 'rebass';

import { NavService } from 'lib';

import Button from 'components/button';
import GenericModal from 'components/generic-modal';

import * as Styles from './modal.styles';

export const CreateLeaveModal = (
  isOpen: boolean,
  closeAction: () => any
): JSX.Element => {
  return (
    <GenericModal
      width={430}
      title="Are you sure?"
      innerComponent={(
        <Styles.ConfirmationBox>
          <p>All progress will be lost</p>
        </Styles.ConfirmationBox>
      )}
      confirmButtonAction={(): void => NavService.productList({ persist: true })}
      confirmButtonText="Yes"
      modalOpen={isOpen}
      closeModal={closeAction}
      extraAction={(
        <Box onClick={closeAction}>
          <Button
            size="normal"
            appearance="secondary"
            copy="No"
            isLoading={false}
            isDisabled={false}
          />
        </Box>
      )}
    />
  );
};

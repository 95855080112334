import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Flex, Box } from 'rebass';

import { colors, fonts } from 'utils/rebass-theme';

export const VariationRow: any = styled(Flex)`
  padding: 20px;
  background: ${colors.lightGrey};
  border-top: 1px solid ${hex2rgba(colors.floomMidnightBlue, .2)};

  & + ${(): any => VariationRow} {
    border-top: none;
    padding-top: 0;
  }
`;

export const FieldLabel = styled.span`
  font-family: ${fonts.secondary};
  font-size: 14px;
  color: ${hex2rgba(colors.floomMidnightBlue, 0.4)};
  font-weight: bold;
  margin-right: 10px
`;

export const InputWrapper: any = styled(Box)`
  max-width: 60px;
`;

export const SaveButton: any = styled(Box)`
  transition: opacity .2s ease-in-out;
  opacity: ${({ disabled }: any): number => disabled ? 0 : 1};
  pointer-events: ${({ disabled }: any): string => disabled ? 'none' : 'auto'};
`;

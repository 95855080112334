import _get from 'lodash.get';

import {
  WholesaleOrder,
  WholesalePreOrder
} from 'generated-types.d';

export const getOrderDeliveryCost = (order?: WholesaleOrder): number => {
  return order && order.deliveryDetails && order.deliveryDetails.length ? (
    order.deliveryDetails.reduce((acc, cur): number => {
      return acc + (cur && cur.costValue ? cur.costValue : 0);
    }, 0)
  ) : 0;
};

export const getPreOrderDeliveryCost = (preOrder?: WholesalePreOrder): number => {
  return preOrder && preOrder.totals?.shippingAmount ? preOrder.totals?.shippingAmount : 0;
};

export const getPreOrderTaxValue = (preOrder?: WholesalePreOrder): number => {
  return preOrder && preOrder.totals?.salesTaxAmount ? preOrder.totals?.salesTaxAmount : 0;
};

export const getNumberOfOrderItems = (order?: WholesaleOrder): number => {
  return order && order.items && order.items.length ? (
    order.items.length
  ) : 0;
};

export const getTotal = (order?: WholesaleOrder): number => {
  return order && order.totalPrice ? order.totalPrice : 0;
};

export const getPreOrderTotal = (preOrder?: WholesalePreOrder): number => {
  return preOrder && preOrder.totals?.subTotalAmount ? preOrder.totals?.subTotalAmount : 0;
};

export const getAddress = (order?: WholesaleOrder): string => {
  const title: string = getMerchantTitle(order);
  const postalCode: string = getPostalCode(order);

  switch (true) {
    case title === '-' && postalCode === '-': return '-';

    case postalCode !== '-' && title === '-': return postalCode;

    case postalCode === '-' && title !== '-': return title;

    default: return `${title}, ${postalCode}`;
  }
};

export const getPostalCode = (order?: WholesaleOrder): string => {
  return _get(order, 'wholesaleDeliveryConfigSnapshot.address.postalCode', '-');
};

export const getMerchantTitle = (order?: WholesaleOrder): string => {
  return _get(order, 'merchant.title', '-');
};

import React from 'react';

import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import LearningHome from 'features/learning';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

const Learning = (): JSX.Element => {
  return(
    <AppLayout>
      {/*
      // @ts-ignore */}
      <Helmet>
        <title>Learning - Coming soon</title>
      </Helmet>
      <Router>
        <AuthedRoute
          path="learning"
          component={LearningHome}
        />
      </Router>
    </AppLayout>
  );
};

export default Learning;

import React, { Component, ReactNode } from 'react';

import { Analytics } from 'lib';

import AccountDetails from 'features/settings/components/account/components/account-details';
import SettingsService from 'features/settings/services';

class Account extends Component {
  private SettingsAnalytics = SettingsService.SettingsAnalytics;

  componentDidMount(): void {
    Analytics.page(Analytics.FxPageEvent.SettingsAccount);
    this.SettingsAnalytics.onSectionView(Analytics.SettingsSection.Account);
  }

  render(): ReactNode {
    return <AccountDetails />;
  }
}

export default Account;

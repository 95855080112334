
import { css } from '@emotion/react';
import { Box } from 'rebass';

import { breakpoints } from 'utils/rebass-theme';

import { ListLayoutConfig } from 'features/lists/lists.types';

import { CheckoutListItemPriceDetails } from '../checkout-list-item-price-details';
import { CheckoutListItemTitle } from '../checkout-list-item-title';
import { CheckoutQuantitySelector } from '../checkout-quantity-selector';

export const CHECKOUT_LIST_FIELD_CONFIG: ListLayoutConfig = {
  Promoted: {
    titleField: {
      key: 'itemName',
      heading: 'Item/Genus',
      renderValue: item => <CheckoutListItemTitle listItem={item} />
    },
    fields: [
      {
        key: 'quantity',
        heading: 'Quantity',
        customStyles: css`
          width: 140px;

          @media only screen and (max-width: ${breakpoints.medium}) {
            order: 2;
          }
        `,
        renderValue: item => {
          return (
            <Box pr="10px">
              <CheckoutQuantitySelector
                listItem={item}
              />
            </Box>
          );
        }
      },
      {
        key: 'total',
        heading: 'Item Total Price',
        customStyles: css`
          width: 110px;
          text-align: right;

          @media only screen and (max-width: ${breakpoints.medium}) {
            order: 3;
            width: unset;
          }
        `,
        renderValue: item => <CheckoutListItemPriceDetails listItem={item} />
      }
    ]
  }
};

import React, { Component, ReactNode } from 'react';

import {
  ProductEdge,
  Maybe
} from 'generated-types';
import startCase from 'lodash.startcase';

import SectionHeading from 'components/section-heading';

import GridLayoutItem from '../grid-layout-item';

import * as Styles from './grid-layout-group.styles';
import * as Types from './grid-layout-group.types';

export default class GridLayoutGroup extends Component<Types.GridLayoutGroupProps> {
  private renderHeading = (): ReactNode => (
    <SectionHeading
      title={startCase(this.props.groupName)}
      count={this.props.group.aggregate.count}
    />
  );

  render(): JSX.Element | null {
    if (!this.props.group.edges.length) return null;

    return(
      <div key={this.props.groupName}>
        {this.props.showHeading &&
          this.renderHeading()
        }
        <Styles.Item>
          {this.props.group.edges.map((edge: Maybe<ProductEdge>) => (
            <GridLayoutItem
              key={`${edge!.node.id}`}
              item={edge!.node}
              isMakingChanges={this.props.isMakingChanges}
              onActiveStateChange={this.props.onActiveStateChange}
            />
          ))}
        </Styles.Item>
      </div>
    );
  }
}

import { OrderDeliveryState } from 'generated-types.d';

import { TimeService } from 'lib';

import { courierStatusConfig } from 'features/courier/courier.constants';
import { CourierStatusIndicatorConfigItem } from 'features/courier/courier.types';

export const retrieveCourierStatusConfig = (state: OrderDeliveryState): CourierStatusIndicatorConfigItem  => courierStatusConfig[state] || courierStatusConfig[OrderDeliveryState.None];

export const buildPickupDay = (pickupDate: Date | string): string | void => TimeService.humanReadableDay(pickupDate.toString());

export const buildPickupTime = (pickupDate: Date | string): string | void => TimeService.withTimezone(pickupDate.toString()).utc().format('h:mma');

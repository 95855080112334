import React, { FC, Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import MerchantStore from 'stores/merchant/merchant-store';
import WholesaleOrdersStore from 'stores/wholesale/wholesale-orders-store/wholesale-orders-store';

import OrderCard from 'features/wholesale/components/order-card';

import Button from 'components/button';
import { EntityListItems } from 'components/entity-list/entity-list.styles';
import TableLayoutEntityHeading from 'components/entity-list/table-layout-entity-heading';
import SectionHeading from 'components/section-heading';

import { WholesaleOrderGroupConfig } from '../../pages/orders/orders';
import * as Styles from '../../pages/orders/orders.styles';
import WholesaleServices from '../../services';
import OrderCardTable from '../order-card-table';

import { ListOverflowWrapper, ListWrapper } from './order-group.styles';

export interface OrderGroupProps {
  orderGroup: WholesaleOrderGroupConfig;
  wholesaleOrdersStore?: WholesaleOrdersStore;
  merchantStore?: MerchantStore;
}

const OrderGroup: FC<OrderGroupProps> = ({
  orderGroup,
  wholesaleOrdersStore,
  merchantStore
}) => {
  const loadMoreOrders = (orderGroupKey: any): void => {
    wholesaleOrdersStore!.incrementOrderCount(orderGroupKey);
    wholesaleOrdersStore!.setInlineLoading();
    WholesaleServices.WholesaleOrdersAPIService.paginateOrders(orderGroupKey);
  };

  return (
    <Fragment key={orderGroup.title}>
      <Box
        mt="50px"
        mb={wholesaleOrdersStore!.listLayoutType === 'grid' ? '25px' : '0'}
      >
        <SectionHeading
          title={orderGroup.title}
          size="small"
          hasSpacing={false}
        />
      </Box>
      {wholesaleOrdersStore!.listLayoutType === 'grid' ? (
        <Styles.CardsContainer layoutType="grid">
          {orderGroup.items!.edges?.map(order => {
            if (!order || !order?.node?.items?.length) return null;

            return (
              <OrderCard
                key={order.node.id}
                order={order.node}
                onViewDetail={(): void => orderGroup.onViewDetail(order.node.id)}
                hasGlobalMerchant={!!merchantStore!.merchant}
              />
            );
          })}
        </Styles.CardsContainer>
      ) : (
        <ListOverflowWrapper>
          <ListWrapper>
            <TableLayoutEntityHeading headers={orderGroup.headingConfig} />
            <EntityListItems>
              <Styles.CardsContainer layoutType="table">
                {orderGroup.items!.edges?.map(order => {
                  if (!order || !order?.node?.items?.length) return null;

                  return (
                    <OrderCardTable
                      key={order.node.id}
                      order={order.node}
                    />
                  );
                })}
              </Styles.CardsContainer>
            </EntityListItems>
          </ListWrapper>
        </ListOverflowWrapper>
      )}
      {orderGroup.items!.pageInfo.hasNextPage && (
        <Styles.Pagination>
          <button
            onClick={(): any => loadMoreOrders(orderGroup.key)}
            disabled={wholesaleOrdersStore!.isLoadingInline}
          >
            <Button
              copy="Load more"
              isLoading={wholesaleOrdersStore!.isLoadingInline}
            />
          </button>
        </Styles.Pagination>
      )}
    </Fragment>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore,
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  merchantStore: stores.merchantStore
}))(observer(OrderGroup));

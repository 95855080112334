import AccountStore from 'stores/account/account-store';

export enum LoginState {
  login = 'login',
  forgotPassword = 'forgot-password',
  resetPassword = 'reset-password',
  invitation = 'invitation'
}

export interface LoginPageProps {
  accountStore?: AccountStore;
  loginState?: LoginState;
}

import React, { Component, ReactNode, ChangeEvent } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import {
  CustomSiteConfigCreateInput,
  CustomSiteConfigUpdateInput,
  Merchant
} from 'generated-types.d';

import { colors } from 'utils/rebass-theme';

import CustomSiteService from 'features/settings/components/custom-site/services';

import FormFieldWrapper from 'components/create-labeled-component';
import FieldText from 'components/field-text';
import GenericModal from 'components/generic-modal';

import * as Styles from './custom-site-config-modal.styles';
import * as Types from './custom-site-config-modal.types';

class CustomSiteConfigModal extends Component<Types.CustomSiteConfigModalProps> {
  private customSiteService = CustomSiteService;

  state = {
    customSiteProjectId: '',
    customSiteProjectUrl: '',
    isLoading: false
  };

  componentDidMount(): void {
    if (this.props.data.customSiteConfig) {
      this.setState({
        customSiteProjectId: this.props.data.customSiteConfig.customSiteProjectId,
        customSiteProjectUrl: this.props.data.customSiteConfig.customSiteProjectUrl
      });
    }
  }

  private onConfirm = async (): Promise<any> => {
    this.setState({
      isLoading: true
    });

    if (!this.props.data.customSiteConfig) {
      const parsedData: CustomSiteConfigCreateInput = {
        customSiteProjectId: this.state.customSiteProjectId,
        customSiteProjectUrl: this.state.customSiteProjectUrl,
        merchant: {
          connect: {
            id: this.props.data.id
          }
        }
      };

      try {
        await this.customSiteService.createNewCustomSiteConfig(parsedData);
        this.props.closeModal();
      } catch {
        this.props.toasterStore!.popErrorToast('updated custom website settings', 'retrieve');
      }
    } else {
      const parsedData: CustomSiteConfigUpdateInput = {
        customSiteProjectId: this.state.customSiteProjectId,
        customSiteProjectUrl: this.state.customSiteProjectUrl
      };

      try {
        await this.customSiteService.updateCustomSiteConfig(this.props.data.id, this.props.data.customSiteConfig.customSiteProjectId, parsedData);
        this.props.closeModal();
      } catch {
        this.props.toasterStore!.popErrorToast('updated custom website settings', 'retrieve');
      }
    }

    this.setState({
      isLoading: false
    });
  };

  private updateCustomSiteId = (e: ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      customSiteProjectId: e.target.value
    });
  };

  private updateCustomSiteUrl = (e: ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      customSiteProjectUrl: e.target.value
    });
  };

  private renderMailchimpStatus = (data: Merchant): ReactNode => {
    const hasMailchimp = data.customSiteConfig && data.customSiteConfig.mailchimpInformation;

    return (
      <Flex alignItems="center">
        <Box>
          <Styles.ColourDot colour={hasMailchimp ? colors.emeral : colors.middleGrey} />
        </Box>
        <Box pl="10px">Mailchimp ({!hasMailchimp && <>Not </>}connected)</Box>
      </Flex>
    );
  };

  render(): ReactNode {
    return (
      <GenericModal
        title={this.props.data.title}
        closeModal={this.props.closeModal}
        modalOpen={this.props.isOpen}
        confirmButtonText="Save changes"
        confirmButtonAction={this.onConfirm}
        isButtonLoading={this.state.isLoading}
        innerComponent={(
          <Styles.Container>
            <Box>
              <FormFieldWrapper title="Custom Site ID">
                <FieldText
                  onChange={this.updateCustomSiteId}
                  value={this.state.customSiteProjectId}
                  placeholder="Custom Site ID"
                  isRequired={true}
                />
              </FormFieldWrapper>
            </Box>

            <Box>
              <FormFieldWrapper title="Custom Site URL">
                <FieldText
                  onChange={this.updateCustomSiteUrl}
                  value={this.state.customSiteProjectUrl}
                  placeholder="Custom Site URL"
                />
              </FormFieldWrapper>
            </Box>

            <Box>
              {this.renderMailchimpStatus(this.props.data)}
            </Box>
          </Styles.Container>
        )}
      />
    );
  }
}

export default inject('ordersStore', 'courierStore', 'merchantStore', 'customSiteSettingsStore', 'toasterStore')(observer(CustomSiteConfigModal));

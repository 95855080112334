import React, { ReactNode, Component } from 'react';

import { inject, observer } from 'mobx-react';

import { UserRole } from 'generated-types.d';

import {
  PermissionsService
} from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import UserLoginService from 'features/login-signup/services';
import SubscriptionService from 'features/payment/services/subscription/subscription.service';

import * as Types from './wrapped-stripe-form.types';

const SUBSCRIPTION_ERRORS = {
  77048574407: 'You cannot subscribe to FloomX if you are a team Member, Please login as a Team Admin or Team Owner and try again',
  1234: 'Only Team roles can subscribe themselves to FloomX. You cannot subscribe if your user role is',
  77048574418: 'You have already subscribed!'
};

export default function WrappedStripeForm(WrappedComponent: any): any {
  class WrappedComponentClass extends Component<Types.WrappedStripeFormProps, Types.WrappedStripeFormState>  {
    state = {
      nameOnCard: '',
      card: null,
      stripe: null,
      validation: null,
      isSubmitting: false,
      hasTermsBeenAccepted: false
    };

    private onSubmit = async (cb: any, merchantId?: string): Promise<any> => {
      if (this.shouldSubmit()) {
        this.setState({ isSubmitting: true });

        const userRole = PermissionsService.getUserRole();

        if (userRole === UserRole.TeamMember) {
          this.props.toasterStore!.popToast(`${SUBSCRIPTION_ERRORS[1234]} ${userRole}`, ToastType.Error);
          this.setState({ isSubmitting: false });

          return Promise.reject();
        }

        return cb(merchantId);
      }
    };

    private onInitialSubscribe = async (merchantId: string): Promise<any> => {
      try {
        // @ts-ignore
        const { token } = await this.state.stripe!.createToken({ name: this.state.nameOnCard });
        const subscription = await SubscriptionService.createMerchantSubscription(token, merchantId);

        if (subscription?.data?.createMerchantSubscription?.suscription_id) {
          this.setState({ isSubmitting: false });
        }
      } catch (error) {
        this.setState({ isSubmitting: false });

        if (SUBSCRIPTION_ERRORS[error?.graphQLErrors?.[0].code]) {
          this.props.toasterStore!.popToast(SUBSCRIPTION_ERRORS[error.graphQLErrors[0].code], ToastType.Error);
        }

        return Promise.reject(error);
      }
    };

    private onUpdateSubscription = async (): Promise<any> => {
      // @ts-ignore
      const { token } = await this.state.stripe!.createToken({ name: this.state.nameOnCard });
      const updateSubscription = await SubscriptionService.updateMerchantSubscriptionPaymentMethod(token);

      if (updateSubscription.data && updateSubscription.data.updateMerchantSubscriptionPaymentMethod.response === 'Complete.') {
        this.setState({ isSubmitting: false });
        await UserLoginService.getMerchantDetails();

        if (!UserLoginService.shouldBeOnMerchantOnboarding()) {
          this.props.toasterStore!.popToast('Your payment details have been successfully updated!', ToastType.Success);

          return updateSubscription;
        }
      } else if (updateSubscription.graphQLErrors && SUBSCRIPTION_ERRORS[updateSubscription.graphQLErrors[0].code]) {
        this.setState({ isSubmitting: false });
        this.props.toasterStore!.popToast(SUBSCRIPTION_ERRORS[updateSubscription.graphQLErrors[0].code], ToastType.Error);

        return updateSubscription.graphQLErrors;
      }

      return Promise.reject('Something went wrong');
    };

    private shouldSubmit = (): boolean => this.isFormValid() && !!this.state.card && !!this.state.stripe;

    private isFormValid = (): boolean => {
      return !!this.state.validation
        && !!this.state.nameOnCard.length
        && this.areStripeFieldsValid();
    };

    private areStripeFieldsValid = (): boolean => {
      // @ts-ignore
      return Object.keys(this.state.validation!).every(field => this.state.validation![field].complete);
    };

    private storeCardInstance = (el: stripe.elements.Element | undefined): void => {
      if (el) {
        this.setState({ card: el });
      }
    };

    private updateStripeValidation = (event: stripe.elements.ElementChangeResponse): void => {
      if (event) {
        this.setState({
          // @ts-ignore
          validation: {
            ...this.state.validation ? this.state.validation : {},
            [event!.elementType]: event
          }
        });
      }
    };

    private onNameOnCardChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      this.setState({
        nameOnCard: e.target.value
      });
    };

    private setTermsCheckbox = (): void => {
      this.setState(state => ({
        hasTermsBeenAccepted: !state.hasTermsBeenAccepted
      }));
    };

    private storeStripeInstance = (stripeInstance: stripe.Stripe): void => {
      this.setState({
        stripe: stripeInstance
      });
    };

    private buildElementsFonts = (): stripe.elements.Font[] => ([{
      family: 'Roboto Mono',
      weight: '400',
      src: 'url(https://fonts.gstatic.com/s/robotomono/v5/hMqPNLsu_dywMa4C_DEpY4gp9Q8gbYrhqGlRav_IXfk.woff2) format("woff2")',
      unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215'
    }]);

    render(): ReactNode {
      return (
        <WrappedComponent
          isFormValid={this.isFormValid}
          isSubmitting={this.state.isSubmitting}
          hasAcceptedTerms={this.state.hasTermsBeenAccepted}
          validation={this.state.validation}
          nameOnCard={this.state.nameOnCard}
          buildFonts={this.buildElementsFonts}
          updateStripeValidation={this.updateStripeValidation}
          storeCardInstance={this.storeCardInstance}
          storeStripeInstance={this.storeStripeInstance}
          setTermsCheckbox={this.setTermsCheckbox}
          onNameOnCardChange={this.onNameOnCardChange}
          onInitialSubscribe={(merchantId?: string): Promise<any> => this.onSubmit(this.onInitialSubscribe, merchantId)}
          onUpdateSubscription={(): Promise<any> => this.onSubmit(this.onUpdateSubscription)}
          {...this.props}
        />
      );
    }
  }

  return inject('userStore', 'toasterStore', 'merchantStore')(observer(WrappedComponentClass));
}

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { ProductVariationType } from 'generated-types.d';

import FormFieldWrapper from 'components/create-labeled-component';

import CreateComponentChooser from '../create-component-chooser';

import { InnerTabComponentProps } from './inner-tab-components.types';

class Components extends Component<InnerTabComponentProps> {
  private shouldHide = (): boolean => {
    return this.props.variation.type !== ProductVariationType.Original
      && this.props.variation.selectedComponentConfigs.length === 0;
  };

  render(): ReactNode {
    if (this.shouldHide()) return null;

    return (
      <FormFieldWrapper
        title="Components"
        optional={true}
        subtitle={this.props.copy || 'Select the stem(s) making your product'}
      >
        <CreateComponentChooser
          variation={this.props.variation}
          defaultTab={this.props.variation.type === ProductVariationType.Original}
          isReadOnly={this.props.isReadOnly}
        />
      </FormFieldWrapper>
    );
  }
}

export default inject('productEditStore')(observer(Components));

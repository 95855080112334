import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  MerchantEdge,
  Maybe,
  Merchant
} from 'generated-types.d';

import { MerchantSameDayOrderPauseService } from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import MerchantListItem from 'features/settings/components/merchants/merchant-list-item';
import SettingsService from 'features/settings/services';

import { EntityListItems } from 'components/entity-list/entity-list.styles';
import TableLayoutEntityHeading from 'components/entity-list/table-layout-entity-heading';
import { TableLayoutHeadingConfigProps } from 'components/entity-list/table-layout-entity.types';
import LoadMoreButton from 'components/load-more-button';
import NoResultsGeneric from 'components/no-results-generic';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import WithLoading from 'components/with-loading';

import MerchantControls from '../merchant-controls/merchant-controls';

import * as Config from './merchants-list.config';
import * as Types from './merchants-list.types';

class MerchantsList extends Component<Types.MerchantsListProps> {
  private MerchantsSettingsService = SettingsService.MerchantsSettingsService;

  state = {
    isActiveToggling: false
  };

  componentWillUnmount(): void {
    this.props.merchantSettingsStore!.resetMerchantListPage();
    this.props.merchantSettingsStore!.setNotification();
  }

  private handleActiveToggleError = (e: any): void => {
    const path = e?.graphQLErrors?.[0]?.context?.params?.path;
    const fieldName = Config.FIELD_PATH_MAP[path] || path;
    const message = ((): string => {
      switch (true) {
        case !path:

        default:
          return 'Unable to activate merchant. They need all trading details (like addresses and trading number) and that they have subscribed, with products';

        case Config.NON_EDITABLE_FIELDS.includes(path):
          return `There's an issue with this merchant. Please contact the tech team (cause of error: ${fieldName})`;

        case Config.EDITABLE_FIELDS.includes(path):
          return `Please make sure the merchant has ${fieldName} before activating`;
      }
    })();

    this.props.toasterStore!.popToast(message, ToastType.Error);
  };

  private onToggleActiveState = async (
    isChecked: boolean,
    merchant: Merchant,
    merchantIndex: number
  ): Promise<void> => {
    this.setState({
      isActiveToggling: true
    });

    try {
      await this.MerchantsSettingsService.toggleActiveStatus(merchant.id, isChecked);

      this.props.merchantSettingsStore!.updateMerchantInList(merchantIndex, {
        active: isChecked
      });

      await this.MerchantsSettingsService.fetchMerchants();
    } catch (e) {
      this.handleActiveToggleError(e);
    }

    this.setState({
      isActiveToggling: false
    });
  };

  private onToggleOrderPause = async (
    isChecked: boolean,
    merchant: Merchant,
    merchantIndex: number
  ): Promise<void> => {
    const canAcceptSDOrders = 0;
    const cannotAcceptSDOrders = 2;
    const valueToSend = isChecked ? canAcceptSDOrders : cannotAcceptSDOrders;

    this.props.merchantSettingsStore!.updateMerchantInList(merchantIndex, {
      sameDayOrderPauseStatus: valueToSend
    });

    try {
      const updatedStatus = await new MerchantSameDayOrderPauseService().updateStatus(valueToSend, merchant.id);

      if (typeof updatedStatus === 'number' && !isNaN(updatedStatus)) {
        if (updatedStatus === 2) {
          this.props.toasterStore!.popNotificationToast(`You have paused same day orders for ${merchant.title}, and an email has been sent letting them know.`);
        }

        this.props.merchantSettingsStore!.updateMerchantInList(merchantIndex, {
          sameDayOrderPauseStatus: updatedStatus
        });
      }
    } catch (error) {
      this.props.toasterStore!.popErrorToast(`same day orders for ${merchant.title}.`, 'disable');

      this.props.merchantSettingsStore!.updateMerchantInList(merchantIndex, {
        sameDayOrderPauseStatus: merchant.sameDayOrderPauseStatus
      });
    }
  };

  private hasNoResults = (): boolean => {
    return !this.props.merchantSettingsStore!.isLoading
      && !this.props.merchantSettingsStore!.merchants.length;
  };

  private isLoading = (): boolean => {
    return this.props.merchantSettingsStore!.isLoading;
  };

  private renderItems = (): ReactNode => {
    if (!this.props.merchantSettingsStore!.merchants.length) return null;

    return this.props.merchantSettingsStore!.merchants.map((edge: Maybe<MerchantEdge>, index): React.ReactNode => {
      if (!edge?.node) return null;

      return (
        <MerchantListItem
          key={edge?.node.id}
          item={edge.node}
          isActiveToggling={this.state.isActiveToggling}
          onToggleOrderPause={(isChecked: boolean): Promise<void> => this.onToggleOrderPause(isChecked, edge.node, index)}
          onToggleActiveState={(isChecked: boolean, item: Merchant): Promise<void> => this.onToggleActiveState(isChecked, item, index)}
        />
      );
    });
  };

  private renderNotification = (): ReactNode => {
    if (!this.props.merchantSettingsStore!.notification) return null;

    return (
      <Box mb="20px">
        <Notification
          copy={this.props.merchantSettingsStore!.notification}
          hasClose={true}
          type={NotificationType.Progress}
          textAlign="left"
          onClose={this.props.merchantSettingsStore!.setNotification}
        />
      </Box>
    );
  };

  private onPaginate = (): void => {
    this.MerchantsSettingsService.paginateMerchants();
  };

  private selectedFieldHeaders = (): TableLayoutHeadingConfigProps[] => {
    return Config.LIST_HEADING_CONFIG.filter(config => {
      return this.props.merchantSettingsStore?.fieldList.includes(config.id);
    });
  };

  render(): React.ReactNode {
    return (
      <Box
        mb="30px"
        mt="30px"
      >
        <MerchantControls />
        {this.renderNotification()}
        <WithLoading
          hasNoResults={this.hasNoResults()}
          isLoading={this.isLoading() && !this.props.merchantSettingsStore?.merchants.length}
          renderNoResults={(): ReactNode => (
            <Box mt="40px">
              <NoResultsGeneric
                icon="business-cross"
                heading="Couldn't find any merchants"
                copy=""
              />
            </Box>
          )}
        >
          <Box
            css={css`
              overflow-x: auto;
              padding: 10px;
              margin: -10px;
            `}
          >
            <Box
              css={{
                minWidth: '950px'
              }}
            >
              <TableLayoutEntityHeading headers={this.selectedFieldHeaders()} />
              <EntityListItems>
                {this.renderItems()}
              </EntityListItems>
            </Box>
          </Box>
          <LoadMoreButton
            shouldDisplay={this.props.merchantSettingsStore!.merchantsPageInfo.hasNextPage}
            isLoading={this.props.merchantSettingsStore!.isLoading}
            groupName={false}
            onLoadMore={this.onPaginate}
          />
        </WithLoading>
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantSettingsStore: stores.merchantSettingsStore,
  toasterStore: stores.toasterStore
}))(observer(MerchantsList));

import React, { Component } from 'react';

import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import {
  ProductVariation,
  MediaCategory,
  PlanFeature
} from 'generated-types.d';

import {
  CurrencyService,
  Analytics,
  ProductService,
  CollectionProductConfigService,
  MerchantService
} from 'lib';

import { AbsoluteCover } from 'utils/css-mixins/css-mixins';
import { colors } from 'utils/rebass-theme';

import { BaseProductItemProps } from 'features/products/pages/product-list/product-list.types';
import { STOCK_VALUES } from 'features/products/products.constants';
import ProductServices from 'features/products/services';

import ImgixImage from 'components/imgix-image';

import FloomCollectionLozenge from '../floom-collection-lozenge';

import * as Styles from './grid-layout-item.styles';
import * as Types from './grid-layout-item.types';
import QuickEdit from './quick-edit';
import { QuickEditUpdateData } from './quick-edit/quick-edit.types';
import VariationTabs from './variation-tabs';

class GridLayoutItem extends Component<BaseProductItemProps, Types.GridLayoutItemState> {
  private ProductListService = ProductServices.ProductListService;

  private ProductAnalytics = ProductServices.ProductAnalytics;

  state = {
    selectedVariation: 0,
    isEditing: false,
    isLoading: false
  };

  private toggleActiveState = async (isChecked: boolean): Promise<void> => {
    this.props.onActiveStateChange(isChecked, this.props.item);
  };

  private hasStockUpdated = (variationId: string, stock: number): boolean => {
    const variation = this.props.item.variations!.find(vari => vari.id === variationId);

    return !!variation && variation.stock !== stock;
  };

  private updateProductVariation = async (updates: QuickEditUpdateData, currVariation: ProductVariation): Promise<void> => {
    this.toggleIsLoading();

    const isCollectionManagerMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionManager, this.props.merchantStore!.merchant);

    try {
      if (ProductService.isTemplateProduct(this.props.item) && !isCollectionManagerMerchant) {
        const config = this.props.item.collectionSellerProductConfigs?.[0];

        await CollectionProductConfigService.upsertProductConfig(
          this.props.item,
          this.props.merchantStore!.merchant!,
          !!config?.active,
          config,
          {
            productVariation: currVariation,
            stock: updates.stock
          }
        );
        this.ProductListService.fetchAllProducts();
      } else {
        const updatedProduct = await this.ProductListService.quickEditProductVariation({
          ...updates,
          productId: this.props.item.id,
          variationId: currVariation.id
        });
        this.props.productsStore!.updateEditedProduct(updatedProduct);
      }

      this.props.toasterStore!.popSuccessToast(`Product variation "${currVariation.type}"`, 'update');

      if (this.hasStockUpdated(currVariation.id, updates.stock)) {
        this.ProductAnalytics.onEditStock(
          Analytics.ProductStockEntry.Grid,
          updates.stock,
          currVariation.type!
        );
      }

      this.toggleEditMode();
    } catch (error) {
      this.props.toasterStore!.popErrorToast('this product', 'update');
    }

    this.toggleIsLoading();
  };

  private toggleIsLoading = (): void => {
    this.setState((prevState: Types.GridLayoutItemState) => ({
      isLoading: !prevState.isLoading
    }));
  };

  private toggleEditMode = (): void => {
    this.setState(state => ({
      isEditing: !state.isEditing
    }));
  };

  private updateSelectedVariation = (key: number): void => {
    if (!this.state.isEditing) {
      this.setState({ selectedVariation: key });
    }
  };

  private getStockColor = (stock: number): string => {
    const { lowStockMax } = STOCK_VALUES;

    switch (true) {
      case (stock === 0):
        return colors.errorText;

      case (stock < lowStockMax):
        return colors.peach;

      default:
        return 'inherit';
    }
  };

  private getMainImage = (variation: ProductVariation): string => {
    if (variation.media!.find(e => e.category === MediaCategory.SideOn)) {
      return variation.media!.find(e => e.category === MediaCategory.SideOn)!.src;
    }

    return variation.media![0].src;
  };

  private buildProductDetailURL = (): string => `${NavPages.ProductsEdit}${this.props.item.id}`;

  render(): React.ReactNode {
    const { item } = this.props;
    const currVariation = item.variations! && item.variations![this.state.selectedVariation];
    const mainImage = this.getMainImage(currVariation);
    const isCollectionManagerMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionManager, this.props.merchantStore!.merchant);
    const stockAmount = ProductService.calculateProductVariationStock(item, currVariation, isCollectionManagerMerchant);
    const stockColor = this.getStockColor(stockAmount);
    const productDetailURL = this.buildProductDetailURL();
    const isTemplateProduct = ProductService.isTemplateProduct(item);

    return (
      <Box>
        <Styles.GridItem>
          <Styles.Heading to={productDetailURL}>
            <Flex>
              <Box>
                <Styles.ActivityDot
                  status={ProductService.isProductActive(item, this.props.merchantStore!.merchant || undefined)}
                />
              </Box>
              <Styles.TitleWrapper title={item.title}>
                <Styles.ProductTitle>{item.title}</Styles.ProductTitle>
                <Styles.ProductType>{item.type && item.type[0].title}</Styles.ProductType>
              </Styles.TitleWrapper>
            </Flex>
          </Styles.Heading>
          <Styles.ItemBody>
            <VariationTabs
              selectedIndex={this.state.selectedVariation}
              variations={item.variations!}
              onSelect={this.updateSelectedVariation}
            />
            <Link to={productDetailURL}>
              {!!currVariation.media!.length && (
                <ImgixImage
                  css={AbsoluteCover}
                  params={{
                    fit: 'crop',
                    ar: '1:1'
                  }}
                  config={{
                    path: mainImage
                  }}
                  width={600}
                  height={600}
                />
              )}
            </Link>
            { (isTemplateProduct && !isCollectionManagerMerchant) && (
              <Styles.ItemTags m={(item?.variations?.length || 0) > 1 ? '30px 10px' : '10px'}>
                <FloomCollectionLozenge />
              </Styles.ItemTags>
            )}
          </Styles.ItemBody>
          <Styles.ItemFooter
            onClick={(): void => {
              if (!isCollectionManagerMerchant) {
                this.toggleEditMode();
              }
            }}
          >
            { !isCollectionManagerMerchant && (
              <Styles.VariationMeta color={stockColor}>
                Stock: {stockAmount}
              </Styles.VariationMeta>
            )}
            <Styles.VariationMeta>
              {CurrencyService.formatPrice(currVariation.price, this.props.item.merchant.currency)}
            </Styles.VariationMeta>
            { !isCollectionManagerMerchant && (
              <Styles.Edit>
                Quick Edit
              </Styles.Edit>
            )}
          </Styles.ItemFooter>
          { this.state.isEditing && (
            <QuickEdit
              isVisible={this.state.isEditing}
              toggle={this.toggleEditMode}
              data={currVariation!}
              product={this.props.item}
              currency={this.props.item.merchant.currency}
              isLoading={this.state.isLoading}
              onActiveToggle={this.toggleActiveState}
              isActive={this.props.item.active}
              isActiveToggling={this.props.isMakingChanges}
              stockAmount={stockAmount}
              onSave={async (updates): Promise<void> => {
                this.updateProductVariation(updates, currVariation);
              }}
            />
          )}
        </Styles.GridItem>
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore,
  merchantStore: stores.merchantStore,
  productsStore: stores.productsStore
}))(observer(GridLayoutItem));

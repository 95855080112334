import {
  FC,
  Fragment,
  useState
} from 'react';

import { observer, inject } from 'mobx-react';
import { Box } from 'rebass';
import { InjectedMobxStores } from 'stores';

import { MerchantSubscriptionService } from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import SettingsService from 'features/settings/services';

import Button from 'components/button';

export const SubscriptionResetButton: FC<Pick<InjectedMobxStores,
  | 'merchantDetailStore'
  | 'toasterStore'
>> = inject((stores: FxStores) => ({
  merchantDetailStore: stores.merchantDetailStore,
  toasterStore: stores.toasterStore
}))(observer(({
  toasterStore,
  merchantDetailStore
}) => {
  const [isResettingSubscription, setIsResettingSubscription] = useState<boolean>(false);

  const resetSubscription = async (): Promise<void> => {
    setIsResettingSubscription(true);

    try {
      await MerchantSubscriptionService.resetMerchantSubscription({
        id: merchantDetailStore!.selectedMerchant?.id
      });

      toasterStore!.popSuccessToast('Merchant account and subscription', 'update');
    } catch (error) {
      toasterStore!.popToast('We\'re having trouble resetting the merchant subscription.', ToastType.Error);
    }

    await SettingsService.MerchantsSettingsService.fetchMerchant(merchantDetailStore!.selectedMerchant!.id);

    setIsResettingSubscription(false);
  };

  return (
    <Fragment>
      <Box
        as="button"
        mt="10px"
        isDisabled={isResettingSubscription}
        onClick={resetSubscription}
      >
        <Button
          copy="Reset subscription"
          size="xsmall"
          appearance="primary"
          isParentWidth={false}
          isLoading={isResettingSubscription}
        />
      </Box>
    </Fragment>
  );
}));

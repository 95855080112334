import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const Wrapper = styled(Box)<{ imagePosition: string }>`
  background: ${colors.floomMidnightBlue};

  @media (min-width: 900px) {
    display: flex;
    flex-direction: ${({ imagePosition }): string => imagePosition === 'left' ? 'row' : 'row-reverse'};
    align-items: center;
  }
`;

export const ImageWrapper = styled(Box)<{ bgUrl: string }>`
  background-image: ${({ bgUrl }): string => `url(${bgUrl})`};
  min-height: 350px;
  background-size: cover;
  background-position: center;

  @media (min-width: 900px) {
    width: 50%;
    min-height: 600px;
  }
`;

export const Content = styled(Box)`
  display: flex;
  text-align: center;
  padding: 50px 20px;
  color: ${colors.white};
  background: ${colors.floomMidnightBlue};

  @media (min-width: 900px) {
    width: 50%;
    text-align: left;
    align-items: center;
    padding: 70px 40px;
  }
`;

export const ContentInner = styled(Box)`
  margin: 0 auto;
`;

export const ButtonWrapper = styled(Box)`
  margin-top: 20px;

  @media (min-width: 900px) {
    margin-top: 30px;
  }
`;

import { createContext } from 'react';

import { CatalogItem } from 'generated-types';

import { TransformedTradeSkuAvailablityItem } from './components/wholesale-shop-listing-card/wholesale-shop-listing-card.types';

interface WholesaleShopListingContextProps {
  closeTo?: string;
  isPlacingOrder: boolean;
  isPurchased: boolean;
  onPlaceOrder: (props: {
    supplierId: string;
  }) => Promise<void>;
  selectedRadioOption: string;
  setSelectedRadioOption: React.Dispatch<React.SetStateAction<string>>;
  availabilityItems: TransformedTradeSkuAvailablityItem[];
  catalogItem: CatalogItem | null;
  hasShopAccess: boolean;
}

export const WholesaleShopListingContext = createContext<WholesaleShopListingContextProps>({
  isPlacingOrder: false,
  isPurchased: false,
  onPlaceOrder: async () => {},
  selectedRadioOption: '',
  setSelectedRadioOption: () => {},
  availabilityItems: [],
  catalogItem: null,
  hasShopAccess: false
});

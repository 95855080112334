import React, { Component, ReactNode } from 'react';

import NoResultsGif from 'assets/images/wholesale/no-results.gif';

import Button from 'components/button';
import { GenericListObject } from 'components/generic-select-list/generic-select-list.types';
import Icon from 'components/icon';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import SelectedItem from './selected-item';
import * as Styles from './selected-item-list.styles';
import * as Types from './selected-item-list.types';

export default class SelecteditemList extends Component<Types.SelectedItemListProps> {
  private hasSelectedItems = (): boolean => !!this.props.items.length;

  private renderItems = (): ReactNode => {
    return (
      <>
        <Styles.ContentWrapper isLoading={this.props.isLoading}>
          <WithLoading
            isLoading={this.props.isLoading}
            hasNoResults={!this.props.isLoading && !this.props.hasItems}
            renderNoResults={this.renderNoResults}
            loaderSize="small"
            marginTop="30px"
          >
            <Styles.CardContainer>
              {
                this.props.items.map((item: GenericListObject): JSX.Element => (
                  <SelectedItem
                    key={item.id}
                    onItemRemove={this.props.onItemRemove}
                    item={item}
                  />
                ))
              }
            </Styles.CardContainer>
          </WithLoading>
        </Styles.ContentWrapper>
        <Styles.AddItemButton
          onClick={this.props.openModal}
          disabled={this.props.isLoading}
        >
          <Button
            copy={this.props.addButtonCopy}
            isDisabled={this.props.isLoading}
          />
        </Styles.AddItemButton>
      </>
    );
  };

  private renderNoResults = (): ReactNode => {
    return (
      <NoResultsGeneric
        size="small"
        isFullWidth={true}
        image={NoResultsGif}
        heading={this.props.noResultsHeading}
        copy={this.props.noResultsCopy}
      />
    );
  };

  private renderEmptyList = (): ReactNode => {
    return (
      <Styles.EmptyListButtonWrapper
        as="button"
        isLoading={this.props.isLoading}
        hasItems={this.props.hasItems}
        onClick={this.props.openModal}
        disabled={this.props.isLoading}
      >
        <WithLoading
          isLoading={this.props.isLoading}
          hasNoResults={!this.props.isLoading && !this.props.hasItems}
          renderNoResults={this.renderNoResults}
          loaderSize="small"
          marginTop="15px"
        >
          <Styles.EmptyListButton>
            <Icon iconName="plus-small" />
            <p>{this.props.addButtonCopy}</p>
          </Styles.EmptyListButton>
        </WithLoading>
      </Styles.EmptyListButtonWrapper>
    );
  };

  render(): ReactNode {
    return (
      <>
        <Styles.SelectedWrapper>
          {this.hasSelectedItems() ? this.renderItems() : this.renderEmptyList()}
        </Styles.SelectedWrapper>
      </>
    );
  }
}

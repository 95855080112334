import 'styles/tailwind.css';
import { FC } from 'react';

import GenericModal from 'components/generic-modal';

import { RefundForm } from './components/refund-form';
type OrderRefundModalProps = {
  closeModal: () => void;
  isOpen: boolean;
  data: {
    orderId: string;
  };
};

const OrderRefundModal: FC<OrderRefundModalProps> = ({
  closeModal,
  isOpen,
  data: {
    orderId
  }
}) => {
  // const formSelect = (formId: string): void => {
  //   onFormSelect(formId);
  //   closeModal();
  // };

  return (
    <GenericModal
      title="Refund order"
      closeModal={closeModal}
      modalOpen={isOpen}
      shouldHideFooter={true}
      width={900}
      innerComponent={(
        <RefundForm
          closeModal={closeModal}
          orderId={orderId}
        />
      )}
    />
  );
};

export default OrderRefundModal;

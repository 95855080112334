import React, { ChangeEvent, FC, Fragment, KeyboardEvent, useEffect, useState } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ListItemTypeFlower, ListItemType } from 'generated-types.d';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import { ConfirmDeleteTooltip } from 'features/lists/components/confirm-delete-tooltip/confirm-delete-tooltip';
import { getMedia, listItemMediaImage } from 'features/lists/lists.helpers';

import Button from 'components/button';
import FieldText from 'components/field-text';
import GenericModal from 'components/generic-modal';
import Icon from 'components/icon';
import WithLoading from 'components/with-loading';

import { ITEM_DETAILS_MODAL_FIELD_CONFIG } from './list-item-details-modal.config';
import * as Styles from './list-item-details-modal.styles';
import { FormData, ListItemDetailsModalProps, ListItemTypes } from './list-item-details-modal.types';

const getInitialFormData = (listItem: ListItemTypes): FormData => {
  switch(listItem?.type) {
    case ListItemType.Flower:
      const item = listItem as ListItemTypeFlower;

      return {
        stemLengthUnit: 'cm',
        minimumStemLength: item.minimumStemLength ? item.minimumStemLength : null,
        maturity: item.maturity ? item.maturity : null
      };

    default:
      return null;
  }
};

const isFormUpdated = (
  formData: FormData | null,
  listItem: ListItemTypes
): boolean => {
  if (!formData) {
    return false;
  }

  const fields = Object.keys(formData);

  return fields.some((field: string): boolean => field !== 'stemLengthUnit' && listItem[field] !== formData[field]);
};

const isQuantityUpdated = (quantity: string, listItem: ListItemTypes): boolean => quantity !== `${listItem.quantity}`;

export const ListItemDetailsModalComponent: FC<ListItemDetailsModalProps> = ({
  closeModal,
  selectedListStore,
  isOpen,
  data: {
    itemId,
    listId,
    onDelete,
    onFetch,
    onUpdate
  }
}) => {
  const [quantity, setQuantity] = useState<string>('0');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listItem, setListItem] = useState<ListItemTypes | null>(null);
  const [formData, setFormData] = useState<FormData>(null);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();

  useEffect(() => {
    getItem();
  }, []);

  useEffect(() => {
    if (listItem && isFormUpdated(formData, listItem)) {
      handleUpdateItem();
    }
  }, [formData]);

  useEffect(() => {
    if (!formData && listItem) {
      setFormData(getInitialFormData(listItem));
    }

    setQuantity(listItem?.quantity ? `${selectedListStore?.selectedItem?.quantity}` : '0');
  }, [listItem]);

  const getItem = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const result = await onFetch?.({ itemId, listId });

      if (result) {
        setListItem(result);
      }
    } catch (error) {
      // Error
    }

    setIsLoading(false);
  };

  const handleUpdateItem = async (): Promise<void> => {
    if (!listItem || !onUpdate) return;

    setIsLoading(true);

    try {
      const result = await onUpdate({
        item: listItem,
        updateInput: {
          quantity: parseInt(quantity, 0),
          ...listItem.type.toLowerCase() === 'custom' ? {} :
            {
              [listItem.type.toLowerCase()]: {
                update: {
                  ...formData
                }
              }
            }
        }
      });

      if (result) {
        setListItem(result);
      }
    } catch (error) {
      // Error
    }

    setIsLoading(false);
  };

  const updateQuantity = (value: string): void => {
    if (parseInt(value, 0) < 0) {
      setQuantity('0');

      return;
    }

    setQuantity(value);
  };

  const handleUpdateQuantity = (): void => {
    if (isQuantityUpdated(quantity, listItem!)) {
      handleUpdateItem();
    }
  };

  const handleCloseModal = async (): Promise<void> => {
    setFormData(null);

    selectedListStore?.clearSelectedItem();
    closeModal();
  };

  const handleSave = async (): Promise<void> => {
    if (isLoading || isSubmitting) return;

    setIsSubmitting(true);

    if (isFormUpdated(formData, listItem!) || isQuantityUpdated(quantity, listItem!)) {
      await handleUpdateItem();
    }

    handleCloseModal();
  };

  const handleDelete = async (): Promise<void> => {
    if (!listItem) return;

    setIsLoading(true);
    await onDelete(listItem);

    handleCloseModal();
  };

  const handleKeyPress = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.code === 'Enter') {
      await handleSave();
    }
  };

  const onChange = (e: ChangeEvent<HTMLSelectElement>, field: string): void => {
    const value = e as unknown;

    const formatFormValue = (): number | string | null => {
      if (value === 'Any') {
        return null;
      }

      switch(field) {
        case 'minimumStemLength':
          return parseInt(value as string, 0);

        default:
          return value as string;
      }
    };

    setFormData((prev: FormData): FormData => ({
      ...prev,
      [field]: formatFormValue()
    }));
  };

  const handleClickDelete = (event: React.MouseEvent<Element> | React.TouchEvent<Element>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDeleteMenu = (): void => {
    setAnchorEl(null);
  };

  const getPrimaryName = (item: ListItemTypes | null): string => {
    if (!item) return '';
    const primaryName = item.catalogItem?.primaryName;

    return primaryName || item?.sku || '';
  };

  return (
    <GenericModal
      closeModal={handleCloseModal}
      modalOpen={isOpen}
      shouldHideFooter={true}
      shouldHideHeader={true}
    >
      <Styles.Container p={3}>
        <WithLoading
          loaderSize="small"
          marginTop="0"
          padding="240px 0"
          isLoading={isLoading && !listItem}
          hasNoResults={false}
          renderNoResults={() => 'No item found'}
          renderBody={() => (
            <Fragment>
              <Styles.Item>
                <Styles.ItemImage
                  imageUrl={listItemMediaImage(getMedia(listItem!), true)}
                />
                { wsFriendlyNames.isActive ? (
                  <Box>
                    <Styles.ItemTitle>{getPrimaryName(listItem)}</Styles.ItemTitle>
                    { !!listItem?.catalogItem?.secondaryName && (
                      <Styles.ItemSubTitle>
                        {listItem?.catalogItem?.secondaryName}
                      </Styles.ItemSubTitle>
                    )}
                  </Box>
                ) : (
                  <Styles.ItemName>{listItem?.sku}</Styles.ItemName>
                )}
              </Styles.Item>
              <Styles.InputContainer>
                <Styles.QuantityLabel htmlFor='item-quantity'>
                  Qty
                </Styles.QuantityLabel>
                <Styles.FieldTextWrapper>
                  <FieldText
                    id='item-quantity'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateQuantity(e.target.value)}
                    onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => handleKeyPress(e)}
                    onBlur={() => handleUpdateQuantity()}
                    autoFocus={true}
                    value={quantity}
                    isDisabled={isLoading}
                    type="number"
                  />
                </Styles.FieldTextWrapper>
                <Styles.RemoveButton
                  data-testid='remove-item'
                  onClick={handleClickDelete}
                >
                  <Icon
                    iconName="bin"
                    pathFill="red"
                  />
                </Styles.RemoveButton>
                <ConfirmDeleteTooltip
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseDeleteMenu}
                  onDeleteItem={() => handleDelete()}
                />
              </Styles.InputContainer>
              <Styles.Metadata>
                {ITEM_DETAILS_MODAL_FIELD_CONFIG.fields.map(field => {
                  if (field.supportedTypes?.length && !field.supportedTypes?.some(supportedType => supportedType === listItem?.type)) {
                    return null;
                  }

                  return (
                    <Styles.MetadataItem key={field.key}>
                      <Styles.MetadataLabel>
                        <label htmlFor={field.key}>{field.label}</label>
                      </Styles.MetadataLabel>
                      {field.isStatic ? (
                        <Styles.MetadataReadonlyValue>
                          {field.renderValue(listItem!, isLoading)}
                        </Styles.MetadataReadonlyValue>
                      ) : (
                        <Styles.MetadataValue>
                          {field.renderValue(listItem!, isLoading, formData, onChange)}
                        </Styles.MetadataValue>
                      )}
                    </Styles.MetadataItem>
                  );
                })}

              </Styles.Metadata>
              <Styles.SaveButton
                onMouseDown={() => handleSave()}
              >
                <Button
                  copy='Save'
                  isParentWidth={true}
                  isLoading={isSubmitting}
                  isDisabled={isLoading}
                />
              </Styles.SaveButton>
            </Fragment>
          )}
        />
      </Styles.Container>
    </GenericModal>
  );
};

export const ListItemDetailsModal = inject((stores: FxStores): InjectedFxStores => ({
  selectedListStore: stores.selectedListStore
}))(observer(ListItemDetailsModalComponent));

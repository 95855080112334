import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';

import ImgixImage from 'components/imgix-image';

import * as Styles from './add-ons.styles';
import * as Types from './add-ons.types';

export default class AddOns extends Component<Types.AddOnsProps> {
  render(): ReactNode {
    return this.props.addOns.map((addOn: any, index: number) => {
      return (
        <Styles.AddOnRow
          key={index}
          height={this.props.height || 65}
        >
          <Styles.ImgCol>
            <ImgixImage
              config={{
                path: addOn.image!
              }}
              css={css`display: block;`}
              height={this.props.height || 65}
              width={this.props.height || 65}
            />
          </Styles.ImgCol>
          <Styles.QuantityCol>
            <Styles.Quantity>
              {addOn.quantity}
            </Styles.Quantity>
          </Styles.QuantityCol>
          <Styles.DescCol padding="10px">
            <strong>
              {addOn.title}
            </strong>
            <br />
            <span>
              {addOn.description}
            </span>
          </Styles.DescCol>
        </Styles.AddOnRow>
      );
    });
  }
}

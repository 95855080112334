import { SegmentEventType } from 'lib/services/analytics/analytics.types';

import { Analytics  } from 'lib';

export class ListAnalytics {
  public static createNewListItem = (itemType: SegmentEventType.CreateListItemOnList['interacted_with']): void => {
    Analytics.track(Analytics.FxEventName.CreateListItemOnList, {
      event_version: 1,
      interacted_with: itemType
    });
  };
}

import React, { Component } from 'react';

import { css } from '@emotion/react';
import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Text } from 'rebass';

import {
  MerchantHolidayCreateInput,
  PlanFeature
} from 'generated-types.d';

import {
  Analytics,
  PermissionsService,
  TimeService,
  PeakFeatureToggle,
  MerchantService
} from 'lib';

import { textStyles } from 'utils/rebass-theme';

import { DateRangeModalProps } from 'features/modal-dialogue/components/modals/date-range-modal/date-range-modal.types';
import HolidayList from 'features/settings/components/merchant-holidays/holiday-list/holiday-list';
import MerchantHolidaysService from 'features/settings/components/merchant-holidays/services';
import SettingsTemplatePage from 'features/settings/components/template/settings-template-page';
import SettingsService from 'features/settings/services';

import * as Types from './merchant-holidays.types';

class MerchantHolidays extends Component<Types.HolidayListProps> {
  private SettingsAnalytics = SettingsService.SettingsAnalytics;

  componentDidMount = (): void => {
    Analytics.page(Analytics.FxPageEvent.MerchantHolidays);
    this.SettingsAnalytics.onMerchantHolidays();

    this.fetchHolidays();
  };

  private fetchHolidays = (): void => {
    if (!PermissionsService.isInternalRole() && !this.props.merchantStore!.merchant?.id) {
      observe(
        this.props.merchantStore!, 'merchant',
        ({
          oldValue,
          newValue
        }: any) => {
          if (!oldValue && newValue.id) {
            MerchantHolidaysService.fetchMerchantHolidays();
          }
        }
      );
    } else {
      MerchantHolidaysService.fetchMerchantHolidays();
    }
  };

  private onCreateConfirm = async (startAt: string, endDate: string, merchantId: string): Promise<any> => {
    const data: Pick<MerchantHolidayCreateInput, 'startAt' | 'endAt'> = {
      startAt: moment(startAt).format('YYYY-MM-DD'),
      endAt: moment(endDate).add(TimeService.isSameDay(startAt, endDate) ? 1 : 0, 'd').format('YYYY-MM-DD')
    };

    await MerchantHolidaysService.createMerchantHoliday(data, merchantId);
  };

  private onCreate = (): any => {
    if (!PeakFeatureToggle.isDisabledDuringPeak()) {
      const data: DateRangeModalProps['data'] = {
        title: 'Add a holiday',
        errorCopy: 'adding your holiday',
        fromCopy: 'First day of holiday',
        toCopy: 'First day back',
        timeoutCopy: 'We are still busy creating your holiday which can sometimes take a bit of time... 2 minute meditation anyone?',
        shouldDisplayMerchantSelection: true,
        initialMerchantId: this.props.userStore!.merchantId,
        onConfirm: this.onCreateConfirm
      };

      this.props.modalStore!.triggerModal({
        modalType: 'dateRange',
        data: data
      });
    } else {
      this.props.toasterStore!.popNotificationToast(PeakFeatureToggle.setFeatureToggleNotification());
    }
  };

  private isCreationDisabled = (): boolean => {
    const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.merchantStore!.merchant!);

    return isCollectionSeller;
  }

  render(): JSX.Element {
    return (
      <SettingsTemplatePage
        title="Holidays"
        headerButtonCopy="Create Holiday"
        onHeaderAction={this.onCreate}
        isActionDisabled={this.isCreationDisabled()}
      >
        <Text
          css={css`
            max-width: 600px;
            ${textStyles.body}
          `}
        >
          If you create a holiday block, all your products will be unavailable for delivery during these dates, without affecting your ability to take orders outside of these dates.
        </Text>
        <HolidayList />
      </SettingsTemplatePage>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantHolidaysStore: stores.merchantHolidaysStore,
  merchantStore: stores.merchantStore,
  userStore: stores.userStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(MerchantHolidays));

import gql from 'graphql-tag';

export const UPDATE_MERCHANT_CREDIT_TERMS = gql`
  mutation UpdateMerchantCreditTerms(
    $where: MerchantWhereUniqueInput!,
    $data: MerchantCreditTermsUpdateInput!
  ) {
    updateMerchantCreditTerms(
      where: $where,
      data: $data
    ) {
      id
    }
  }
`;

export const CREATE_WHOLESALE_ORDER = gql`
  mutation CreateWholesaleOrder(
    $where: MerchantWhereUniqueInput
    $data: WholesaleOrderInput!
  ) {
    createWholesaleOrder(
      where: $where
      data: $data
    ) {
      id
      status
    }
  }
`;

export const CANCEL_WHOLESALE_ORDER_ITEM = gql`
  mutation CancelWholesaleOrderItem(
    $where: MerchantWhereUniqueInput,
    $data: WholesaleOrderCancelInput!
  ) {
    cancelWholesaleOrderLineItem(
      where: $where
      data: $data
    ) {
      id
      status
    }
  }
`;

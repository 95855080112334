import { ApolloQueryResult } from '@apollo/client';

import {
  Merchant,
  Product,
  ProductVariation,
  CollectionSellerProductConfig,
  CollectionSellerProductConfigCreateInput,
  CollectionSellerProductConfigUpdateInput,
  CollectionSellerProductConfigWhereUniqueInput,
  CollectionSellerProductConfigOrderByInput,
  CollectionSellerProductConfigWhereInput,
  CollectionSellerProductConfigConnection,
  CollectionSellerProductConfigEdge,
  CollectionSellerProductVariationConfigCreateInput
} from 'generated-types.d';

import * as CollectionProductConfigMutations from '../../graphql/mutation/collection-product-config';
import * as CollectionProductConfigQueries from '../../graphql/queries/collection-product-config';
import {
  GraphQL
} from '../client/client.service';

export default class CollectionProductConfigService {
  public static upsertProductConfig = async (
    product: Product,
    merchant: Merchant,
    active = false,
    existingConfig?: CollectionSellerProductConfig,
    variationConfig?: {
      productVariation: ProductVariation;
      stock: number;
    }
  ): Promise<CollectionSellerProductConfig> => {
    switch (true) {
      case !existingConfig:
        return CollectionProductConfigService.createProductConfig(CollectionProductConfigService.buildCollectionProductConfigCreateInput(
          product,
          variationConfig?.productVariation || product.variations?.[0]!,
          merchant,
          variationConfig?.stock || 0,
          active
        ));

      default:
        return CollectionProductConfigService.updateProductConfig(
          existingConfig!.id,
          CollectionProductConfigService.buildCollectionProductConfigUpdateInput(
            active,
            existingConfig!,
            variationConfig?.productVariation,
            variationConfig?.stock
          )
        );
    }
  };

  public static fetchAllProductConfigs = async (
    whereInput: CollectionSellerProductConfigWhereInput,
    orderBy: CollectionSellerProductConfigOrderByInput = CollectionSellerProductConfigOrderByInput.CreatedAtAsc
  ): Promise<CollectionSellerProductConfigEdge[]> => {
    let skip = 0;
    let hasNextPage = true;
    let productConfigs: CollectionSellerProductConfigEdge[] = [];

    const first = 100;
    const where: CollectionSellerProductConfigWhereInput = {
      ...whereInput,
      deletedAt: null
    };

    while (hasNextPage) {
      try {
        const result: ApolloQueryResult<{ collectionSellerProductConfigsConnection: CollectionSellerProductConfigConnection }> = await GraphQL
          .query(CollectionProductConfigQueries.PRODUCT_COLLECTION_CONFIGS_QUERY, { where, orderBy, first, skip });

        skip = skip + first;
        hasNextPage = result.data.collectionSellerProductConfigsConnection.pageInfo.hasNextPage;

        productConfigs = [
          ...productConfigs,
          ...result.data.collectionSellerProductConfigsConnection.edges as CollectionSellerProductConfigEdge[]
        ];
      } catch (error) {
        hasNextPage = false;

        return Promise.reject(error);
      }
    }

    return productConfigs;
  };

  private static createProductConfig = async (create: CollectionSellerProductConfigCreateInput): Promise<CollectionSellerProductConfig> => {
    try {
      const result: ApolloQueryResult<{ upsertCollectionSellerProductConfig: CollectionSellerProductConfig }> = await GraphQL
        .query(CollectionProductConfigMutations.CREATE_COLLECTION_PRODUCT_CONFIG, { create });

      return result.data.upsertCollectionSellerProductConfig;
    } catch (error) {
      throw new Error(error);
    }
  };

  private static updateProductConfig = async (
    configId: string,
    update: CollectionSellerProductConfigUpdateInput
  ): Promise<CollectionSellerProductConfig> => {
    const where: CollectionSellerProductConfigWhereUniqueInput = {
      id: configId
    };

    try {
      const result: ApolloQueryResult<{ upsertCollectionSellerProductConfig: CollectionSellerProductConfig }> = await GraphQL
        .query(CollectionProductConfigMutations.UPDATE_COLLECTION_PRODUCT_CONFIG, { where, update });

      return result.data.upsertCollectionSellerProductConfig;
    } catch (error) {
      throw new Error(error);
    }
  };

  private static buildCollectionProductConfigCreateInput = (
    product: Product,
    productVariation: ProductVariation,
    merchant: Merchant,
    stock: number,
    active: boolean
  ): CollectionSellerProductConfigCreateInput => {
    const createInput = CollectionProductConfigService.buildProductVariationConfigCreateInput(stock, productVariation!.id);

    return {
      active: active,
      sourceProduct: {
        connect: {
          id: product.id
        }
      },
      merchant: {
        connect: {
          id: merchant.id
        }
      },
      productVariationConfigs: {
        create: [createInput]
      }
    };
  };

  private static buildCollectionProductConfigUpdateInput = (
    active: boolean,
    existingConfig: CollectionSellerProductConfig,
    productVariation?: ProductVariation,
    stock?: number
  ): CollectionSellerProductConfigUpdateInput => {
    const existingVariationConfig = existingConfig.productVariationConfigs?.find(config => config.sourceProductVariation.id === productVariation?.id);
    const isStockANumber = stock === 0 || (!!stock && stock > 0);

    switch (true) {
      case !!existingVariationConfig: {
        const variationStock = isStockANumber ? stock! : existingVariationConfig!.stock!;

        return {
          active: active,
          productVariationConfigs: {
            update: [{
              where: {
                id: existingVariationConfig!.id
              },
              data: {
                stock: variationStock
              }
            }]
          }
        };
      }

      case !existingVariationConfig && !!productVariation: {
        const createInput = CollectionProductConfigService.buildProductVariationConfigCreateInput(stock || 0, productVariation!.id);

        return {
          active: active,
          productVariationConfigs: {
            create: [createInput]
          }
        };
      }

      case !productVariation:
        return {
          active
        };

      default: {
        return {};
      }
    }
  };

  private static buildProductVariationConfigCreateInput = (stock: number, variationID: string): CollectionSellerProductVariationConfigCreateInput => {
    return {
      stock: stock,
      sourceProductVariation: {
        connect: {
          id: variationID
        }
      }
    };
  };
}

import { ChangeEvent, Component, ReactNode } from 'react';

import { Flex, Text } from 'rebass';

import {
  NavService,
  CurrencyService
} from 'lib';

import { textStyles } from 'utils/rebass-theme';

import ProductServices from 'features/products/services';

import FieldText from 'components/field-text';
import StyledLink from 'components/styled-link/styled-link';

import * as Styles from './variation-item.styles';
import * as Types from './variation-item.types';

export default class VariationItem extends Component<Types.VariationItemProps, Types.VariationItemState> {
  private ProductCreateEditService = ProductServices.ProductCreateEditService;

  state = {
    priceState: this.props.variation.price,
    stockState: this.props.stockAmount,
    originalStockAmount: this.props.stockAmount,
    hasChanged: false
  };

  static getDerivedStateFromProps(
    props: Types.VariationItemProps,
    state: Types.VariationItemState
  ): Types.VariationItemState | null {
    if (state.hasChanged && (state.originalStockAmount !== props.stockAmount)) {
      return {
        ...state,
        stockState: props.stockAmount,
        originalStockAmount: props.stockAmount,
        hasChanged: false
      };
    }

    return null;
  }

  private onValueChange = ({ target }: ChangeEvent<HTMLInputElement>, key: keyof Types.VariationItemState): void => {
    // @ts-ignore
    this.setState({
      [key]: target.value.length ? parseFloat(target.value) : target.value
    });
  };

  private updateVariation = async (): Promise<void> => {
    const { priceState, stockState } = this.state;

    if (this.hasChanged()) {
      await this.props.onSave({
        price: this.generateValue(priceState),
        stock: this.generateValue(stockState)
      });

      this.setState({ hasChanged: true });
    }
  };

  private navigateToVariation = (): void => {
    this.ProductCreateEditService.setCurrentVariation(this.props.variation.id);
    NavService.productEditVariation(this.props.productId, this.props.variation.id);
  };

  private generateValue = (value: any): number => !this.isString(value) ? value : 0;

  private isString = (value: any): boolean => typeof value === 'string' || value instanceof String;

  private hasChanged = (): boolean => this.hasStockChanged() || this.hasPriceChanged();

  private hasStockChanged = (): boolean => this.props.stockAmount !== this.state.stockState;

  private hasPriceChanged = (): boolean => this.props.variation.price !== this.state.priceState;

  render(): ReactNode {
    return (
      <Styles.VariationRow alignItems="center">
        <Text
          css={textStyles.subhead}
          ml="130px"
          width="12%"
          role="rowheader"
        >
          <button onClick={this.navigateToVariation}>
            <StyledLink>{this.props.variation.type}</StyledLink>
          </button>
        </Text>
        <Flex
          alignItems="center"
          width="150px"
          mr="30px"
          role="cell"
        >
          <Styles.FieldLabel>
            {CurrencyService.renderCurrencySymbol(this.props.merchant.currency)}
          </Styles.FieldLabel>
          <Styles.InputWrapper>
            <FieldText
              min={0}
              size="small"
              type="number"
              value={this.state.priceState}
              isDisabled={(this.props.isTemplateProduct && !this.props.isCollectionManagerMerchant)
                || this.props.isLoading}
              onChange={(event): void => this.onValueChange(event, 'priceState')}
            />
          </Styles.InputWrapper>
          <Styles.SaveButton
            as="button"
            disabled={!this.hasPriceChanged() || this.props.isLoading}
            css={textStyles.subhead}
            onClick={this.updateVariation}
            ml="14px"
          >
            Save
          </Styles.SaveButton>
        </Flex>
        { !this.props.isCollectionManagerMerchant && (
          <Flex
            alignItems="center"
            width="180px"
            mr="30px"
            role="cell"
          >
            <Styles.FieldLabel>Stock</Styles.FieldLabel>
            <Styles.InputWrapper>
              <FieldText
                min={0}
                size="small"
                type="number"
                value={this.state.stockState!}
                isDisabled={this.props.isLoading}
                onChange={(event): void => this.onValueChange(event, 'stockState')}
              />
            </Styles.InputWrapper>
            <Styles.SaveButton
              as="button"
              disabled={!this.hasStockChanged() || this.props.isLoading}
              css={textStyles.subhead}
              onClick={this.updateVariation}
              ml="14px"
            >
              Save
            </Styles.SaveButton>
          </Flex>
        )}
      </Styles.VariationRow>
    );
  }
}

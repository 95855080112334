import { FC } from 'react';

import { colors } from 'utils/rebass-theme';

const colorOptions = [colors.white, colors.floomMidnightBlue];
type LogoColor = typeof colorOptions[number];

interface LogoProps {
  color?: LogoColor;
  width?: string;
  height?: string;
}

const Logo: FC<LogoProps> = ({
  color = colors.white,
  width = '93',
  height = '18',
  ...props
}: LogoProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 232 46"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={color}>
        <path d="M22.595 9.097H1.088a.594.594 0 0 0-.593.594V44.35c0 .328.266.594.593.594h3.9a.594.594 0 0 0 .593-.594V29.3h14.064a.595.595 0 0 0 .595-.594v-3.582a.593.593 0 0 0-.595-.593H5.581V13.867h17.014a.595.595 0 0 0 .595-.594V9.69a.595.595 0 0 0-.595-.594M56.715 40.173H40.069V9.69a.593.593 0 0 0-.594-.594h-3.898a.593.593 0 0 0-.594.594v34.658c0 .329.266.595.594.595h21.138a.595.595 0 0 0 .595-.595v-3.581a.595.595 0 0 0-.595-.595M195.382 9.097h-4.318a.593.593 0 0 0-.514.296l-13.13 21.13-13.13-21.13a.594.594 0 0 0-.514-.296h-4.318a.594.594 0 0 0-.595.594V44.35c0 .328.266.594.595.594h3.896a.593.593 0 0 0 .594-.594V19.292l11.275 17.564c.106.18.302.292.51.292h3.372a.596.596 0 0 0 .512-.292l11.275-17.564V44.35c0 .328.265.594.594.594h3.896a.595.595 0 0 0 .594-.594V9.69a.595.595 0 0 0-.594-.593M82.893 8.332c-10.32 0-18.688 8.367-18.688 18.688s8.367 18.688 18.688 18.688 18.688-8.368 18.688-18.689S93.214 8.331 82.893 8.331m.012 32.48c-7.617 0-13.377-6.175-13.377-13.792s5.76-13.79 13.377-13.79 13.353 6.173 13.353 13.79-5.736 13.792-13.353 13.792M129.703 8.332c-10.32 0-18.688 8.367-18.688 18.688s8.367 18.688 18.688 18.688 18.687-8.368 18.687-18.689-8.366-18.688-18.687-18.688m.012 32.48c-7.617 0-13.377-6.175-13.377-13.792s5.76-13.79 13.377-13.79c7.616 0 13.352 6.173 13.352 13.79s-5.736 13.792-13.352 13.792M228.884 18.61a2.614 2.614 0 0 1-1.854-.769L213.956 4.767a2.62 2.62 0 1 1 3.707-3.707l13.075 13.074a2.622 2.622 0 0 1-1.854 4.475" />
        <path d="M215.81 18.61a2.62 2.62 0 0 1-1.854-4.475L227.03 1.06a2.62 2.62 0 1 1 3.707 3.707l-13.074 13.074a2.614 2.614 0 0 1-1.854.768" />
      </g>
    </svg>
  );
};

Logo.defaultProps = {
  color: colors.white
};

export default Logo;

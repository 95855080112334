import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { globalHistory } from '@reach/router';
import { phoneString } from 'lib/services/validation/extensions/phone';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import * as Yup from 'yup';

import {
  Merchant,
  MerchantPlan
} from 'generated-types.d';
import { SupportedISOString } from 'global-types';
import {
  MERCHANT_TRIAL_PERIODS
} from 'global.constants';

import {
  MerchantPlanService,
  ValidationService,
  PermissionsService,
  NavService,
  CurrencyService
} from 'lib';

import { MerchantFieldValues, MerchantLocationKey } from 'stores/merchant-onboarding/merchant-onboarding-store.types';
import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { Container } from 'utils/css-mixins';
import { colors } from 'utils/rebass-theme';

import { FORM_CONTAINER_WIDTH } from 'features/merchant-onboarding/merchant-onboarding.constants';
import MerchantOnboardingService from 'features/merchant-onboarding/services';

import Button from 'components/button';
import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import FormFooter from 'components/form-footer';
import FormHeader from 'components/form-header';
import { NotificationType } from 'components/notification/notification.types';

import * as Constants from './add-merchant.constants';
import * as Types from './add-merchant.types';

class AddMerchant extends Component<Types.AddMerchantProps, Types.AddMerchantState> {
  private MerchantCreationService = MerchantOnboardingService.MerchantCreationService;

  private historyListener = globalHistory.listen((listener): void => {
    if (!listener.location.pathname.includes('merchant/create')) {
      this.props.merchantOnboardingStore!.resetStore();
    }
  });

  validation: Yup.Schema<any> = Yup.object().shape({
    plan: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please select a plan')),
    trialPeriod: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please select a trial period')),
    companyName: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Company name is required')),
    givenName: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'First name is required')),
    familyName: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Surname is required')),
    email: Yup
      .string()
      .email(params => ValidationService.generateYupMessageSchema(params, 'Please enter a valid email'))
      .required(params => ValidationService.generateYupMessageSchema(params, 'We need an email for the merchant')),
    planPrice: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Plan price is required'))
      .test(
        'Is Float',
        params => ValidationService.generateYupMessageSchema(params, 'Plan price must be a positive number'),
        value => /^(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(value)
      ),
    phone: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'We need the merchants phone number'))
      .when('location', (location: string) => {
        if (location) {
          const countryCode = location === 'uk' ? 'GB' : 'US';

          return phoneString('phone', countryCode);
        }
      }),
    location: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please select a country of operation'))
  });

  state: Types.AddMerchantState = {
    isPosting: false,
    isDirty: false,
    errorNotification: ''
  };

  componentDidMount = (): void => {
    this.fetchPlans();
    this.redirectTeamUsers();
  };

  componentWillUnmount = (): void => {
    this.historyListener();
  };

  private redirectTeamUsers = (): void => {
    if (!PermissionsService.isInternalRole()) {
      NavService.overview();
    }
  };

  private setMerchantConfirmCopy = (): string => {
    switch (true) {
      case !this.props.merchantOnboardingStore!.createdMerchantId:
        return 'Continue';

      case !!this.props.merchantOnboardingStore!.createdMerchantId?.length && !this.state.isDirty:
        return 'Continue';

      case !!this.props.merchantOnboardingStore!.createdMerchantId?.length && this.state.isDirty:
        return 'Update & Continue';

      default:
        return 'Continue';
    }
  };

  private fetchPlans = async (): Promise<void> => {
    if (!this.props.merchantOnboardingStore!.merchantPlans.length) {
      try {
        const plans = await MerchantPlanService.fetchMerchantPlans({
          type_in: Constants.CREATABLE_MERCHANT_PLAN_TYPES
        });

        this.props.merchantOnboardingStore!.setMerchantPlans(plans);
      } catch (error) {
        this.setState({
          errorNotification: 'We were unable to fetch the available plans for creating a merchant profile'
        });
      }
    }
  };

  private handleChange = (value: MerchantFieldValues[keyof MerchantFieldValues], key: keyof MerchantFieldValues): void => {
    this.props.merchantOnboardingStore!.setValue(value, key);

    if (!this.state.isDirty) {
      this.setState({
        isDirty: true
      });
    }
  };

  private handleBlurValidateFormat = async (value: MerchantFieldValues[keyof MerchantFieldValues], key: keyof MerchantFieldValues): Promise<void> => {
    try {
      const validateValue = this.props.merchantOnboardingStore!.values;
      const validatedFormattedValue = await ValidationService.validateSchemaField(this.validation, validateValue, key);
      this.props.merchantOnboardingStore!.setValue(validatedFormattedValue, key);
    } catch (error) {
      this.props.merchantOnboardingStore!.setErrors(error.errors);
    }
  };

  private onSubmit = async (): Promise<void> => {
    try {
      const merchantSchema: MerchantFieldValues = await ValidationService.validateAll(this.validation, this.props.merchantOnboardingStore!.values);

      switch (true) {
        case !this.props.merchantOnboardingStore!.createdMerchantId:
          this.onCreateMerchant(merchantSchema);

          break;

        case !!this.props.merchantOnboardingStore!.createdMerchantId?.length:

        default:
          this.onUpdateMerchant(this.props.merchantOnboardingStore!.values);

          break;
      }
    } catch (error) {
      this.props.merchantOnboardingStore!.setErrors(error.errors);
      this.props.toasterStore?.popToast('You have some validation errors', ToastType.Error);
    }
  };

  private onCreateMerchant = async (data: MerchantFieldValues): Promise<void> => {
    this.setState({
      isPosting: true,
      errorNotification: ''
    });

    try {
      const merchant = await this.MerchantCreationService.createMerchant(data);

      if (merchant) {
        this.handleMerchantResponse(merchant);
        this.setState({ isPosting: false });
      }
    } catch (error) {
      this.props.toasterStore?.popToast('We\'re having trouble creating your merchant. More information is provided at the top of the form', ToastType.Error);

      window?.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      this.setState({
        isPosting: false,
        isDirty: false,
        errorNotification: this.handleErrorNotification(error)
      });
    }
  };

  private onUpdateMerchant = async (data: MerchantFieldValues): Promise<void> => {
    if (this.state.isDirty) {
      this.setState({
        isPosting: true,
        errorNotification: ''
      });

      try {
        const merchant = await this.MerchantCreationService.updateMerchant(data, this.props.merchantOnboardingStore!.merchantObject!);
        const isEdit = true;

        if (merchant) {
          this.handleMerchantResponse(merchant, isEdit);

          this.setState({
            isPosting: false,
            isDirty: false
          });
        }
      } catch (error) {
        this.props.toasterStore?.popToast(`We're having trouble updating ${data.companyName}. More information is provided at the top of the form`, ToastType.Error);

        window?.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

        this.setState({
          isPosting: false,
          errorNotification: this.handleErrorNotification(error, false)
        });
      }
    } else {
      NavService.merchantCreate('company-info');
    }
  };

  private handleErrorNotification = (errors: any, isCreate: boolean = true): string => {
    switch (true) {
      case errors?.[0]?.message?.includes?.('unique') && errors?.[0]?.message?.includes?.('email'):
        return `We are unable to create a merchant because the email address "${this.props.merchantOnboardingStore!.values?.email}" is already in use`;

      default:
        return `We are having trouble ${isCreate ? 'creating' : 'updating'} a merchant account with the details provided.`;
    }
  };

  private handleMerchantResponse = (merchant: Merchant, isEdit: boolean = false): void => {
    switch (true) {
      case PermissionsService.isInternalRole():
        this.props.merchantSettingsStore!.setNewMerchant(merchant.id);
        this.props.merchantOnboardingStore!.populateMerchant(merchant);
        this.props.toasterStore?.popToast(`You've successfully ${isEdit ? 'edited' : 'created'} ${merchant.companyName}. You can either continue setting up the merchant, or exit the setup process and allow the merchant to complete when they sign up.`, ToastType.Success);
        NavService.merchantCreate('company-info');

        break;

      default:
        break;
    }
  };

  public attemptToLeave = (): void => {
    if (this.state.isDirty) {
      this.props.modalStore!.triggerModal({
        modalType: 'confirmation',
        data: {
          title: 'Discard merchant creation',
          copy: 'Are you sure you want to leave? You will lose your merchant creation progress',
          confirmButtonCopy: 'Yes, leave',
          cancelButtonCopy: 'No, continue',
          errorCopy: '',
          confirmButtonAction: async (): Promise<any> => new Promise((resolve): any => {
            this.props.merchantOnboardingStore!.resetStore();
            NavService.merchantList();
            resolve('');
          })
        }
      });
    } else {
      NavService.merchantList();
    }
  };

  private findError = (key: keyof MerchantFieldValues): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(this.props.merchantOnboardingStore!.validationErrors, key);
  };

  public buildForm = (): FormBuilderConfig => ({
    sections: [
      {
        width: '100',
        fields: [
          {
            key: 'companyNameLabel',
            fieldType: 'label',
            copy: 'Tell us about the new merchant',
            validationError: undefined
          },
          {
            key: 'companyName',
            fieldType: 'textInput',
            placeholder: 'Company name',
            value: this.props.merchantOnboardingStore!.values.companyName,
            validationError: this.findError('companyName'),
            onChange: (e): void => this.handleChange(e.target.value, 'companyName')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'givenNameLabel',
            fieldType: 'label',
            copy: 'First name',
            validationError: undefined
          },
          {
            key: 'givenName',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.givenName,
            validationError: this.findError('givenName'),
            onChange: (e): void => this.handleChange(e.target.value, 'givenName')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'familyNameLabel',
            fieldType: 'label',
            copy: 'Last Name',
            validationError: undefined
          },
          {
            key: 'familyName',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.familyName,
            validationError: this.findError('familyName'),
            onChange: (e): void => this.handleChange(e.target.value, 'familyName')
          }
        ]
      },
      {
        width: '100',
        fields: [
          {
            key: 'emailLabel',
            fieldType: 'label',
            copy: 'Email address',
            validationError: undefined
          },
          {
            key: 'email',
            fieldType: 'textInput',
            placeholder: 'e.g. hello@florist.com',
            type: 'email',
            value: this.props.merchantOnboardingStore!.values.email,
            validationError: this.findError('email'),
            onChange: (e): void => this.handleChange(e.target.value, 'email')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'phoneLabel',
            fieldType: 'label',
            copy: 'Phone number',
            validationError: undefined
          },
          {
            key: 'phone',
            fieldType: 'textInput',
            type: 'tel',
            value: this.props.merchantOnboardingStore!.values.phone,
            validationError: this.findError('phone'),
            onChange: (e): void => this.handleChange(e.target.value, 'phone'),
            onBlur: (e): Promise<void> => this.handleBlurValidateFormat(e.target.value, 'phone')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'locationLabel',
            fieldType: 'label',
            copy: 'Location',
            validationError: undefined
          },
          {
            key: 'location',
            fieldType: 'dropdown',
            placeholder: 'Select a location',
            selected: this.props.merchantOnboardingStore!.values.location,
            id: 'merchant-create-location',
            options: Constants.ADD_MERCHANT_LOCATIONS,
            optionTitleField: 'title',
            optionValueField: 'key',
            validationError: this.findError('location'),
            onChange: (value: MerchantLocationKey): void => {
              this.props.merchantOnboardingStore!.changeLocation(value);
              this.props.merchantOnboardingStore!.resetErrorForField('location');
              this.handleBlurValidateFormat(this.props.merchantOnboardingStore!.values.phone, 'phone');
            }
          }
        ]
      },
      {
        width: '100',
        fields: [
          {
            key: 'selectPlanLabel',
            fieldType: 'label',
            copy: 'Select plan',
            isHidden: !this.props.merchantOnboardingStore!.merchantPlans.length,
            validationError: undefined
          },
          {
            key: 'plan',
            fieldType: 'radioList',
            itemType: 'default',
            itemTitleField: 'title',
            itemSubtitleField: 'subTitle',
            itemValueField: 'id',
            orientation: 'vertical',
            items: this.buildPlans(),
            isHidden: !this.props.merchantOnboardingStore!.merchantPlans.length,
            selectedItem: this.props.merchantOnboardingStore!.values.plan,
            validationError: this.findError('plan'),
            onChange: (planId: string): void => {
              const plan = this.props.merchantOnboardingStore!.findSelectedMerchantPlan(planId);

              this.handleChange(planId, 'plan');
              this.handleChange(`${plan?.price}`, 'planPrice');
              this.handleChange(true, 'isDefaultPlanPrice');
            }
          }
        ]
      },
      {
        width: '50',
        isHidden: !this.props.merchantOnboardingStore!.values.plan?.length,
        fields: [
          {
            key: 'planPriceLabel',
            fieldType: 'label',
            copy: 'Plan Price',
            validationError: undefined
          },
          {
            key: 'planPriceField',
            fieldType: 'textInput',
            type: 'number',
            value: this.props.merchantOnboardingStore!.values.planPrice || '',
            prefix: CurrencyService.renderCurrencySymbol(this.props.merchantOnboardingStore?.currency as SupportedISOString),
            isDisabled: this.props.merchantOnboardingStore!.values.isDefaultPlanPrice,
            validationError: this.findError('planPrice'),
            onChange: (e): void => {
              this.handleChange(e.target.value, 'planPrice');

              if (this.props.merchantOnboardingStore!.values.isDefaultPlanPrice) {
                this.handleChange(false, 'isDefaultPlanPrice');
              }
            }
          }
        ]
      },
      {
        width: '50',
        paddingBottom: '0',
        isHidden: !this.props.merchantOnboardingStore!.values.plan?.length,
        fields: [
          {
            key: 'planPriceDefaultField',
            fieldType: 'singleCheckbox',
            label: `Set as default ${!!this.props.merchantOnboardingStore!.values.plan ? `(${this.renderSelectedPlanPrice()})` : ''}`,
            labelCopy: 'Uncheck to customise',
            isSelected: this.props.merchantOnboardingStore!.values.isDefaultPlanPrice,
            isDisabled: false,
            validationError: undefined,
            handleSelect: (): void => {
              const selectedPlan = this.props.merchantOnboardingStore!.findSelectedMerchantPlan();

              this.handleChange(!this.props.merchantOnboardingStore!.values.isDefaultPlanPrice, 'isDefaultPlanPrice');
              this.handleChange(selectedPlan?.price ? `${selectedPlan?.price}` : '', 'planPrice');
            },
            customStyles: css`
              margin: 58px 0 0 15px;
            `
          }
        ]
      },
      {
        width: '100',
        fields: [
          {
            key: 'selectPlanLabel',
            fieldType: 'label',
            copy: 'Select trial period',
            isHidden: false,
            validationError: undefined
          },
          {
            key: 'trialPeriod',
            fieldType: 'radioList',
            itemType: 'card',
            itemTitleField: 'title',
            itemValueField: 'id',
            orientation: 'horizontal',
            items: MERCHANT_TRIAL_PERIODS,
            selectedItem: this.props.merchantOnboardingStore!.values.trialPeriod,
            validationError: this.findError('trialPeriod'),
            onChange: (trialPeriod: string): void => this.handleChange(trialPeriod, 'trialPeriod')
          }
        ]
      }
    ]
  });

  private buildPlans = (): Types.PlanField[] => {
    return this.props.merchantOnboardingStore!.merchantPlans.reduce((plans: any, plan: MerchantPlan): Types.PlanField[] => {
      if (plan.currency === this.props.merchantOnboardingStore!.currency) {
        return [
          ...plans,
          {
            title: (
              <Box>
                <Box>
                  <strong>{plan.title}</strong>
                  {' - '}
                  <span>{CurrencyService.formatPrice(plan.price, plan.currency)}</span>
                  <Box
                    as="strong"
                    css={css`
                      padding-left: 5px;
                      font-size: 12px;
                      color: ${colors.shade60};
                    `}
                  >
                    /mo
                  </Box>
                </Box>
                <Box
                  css={css`
                    font-size: 12px;
                    margin-top: 3px;
                    color: ${colors.shade60};
                  `}
                >
                  {plan.description}
                </Box>
              </Box>

            ),
            subTitle: plan.description,
            id: plan.id
          }
        ];
      }

      return plans;
    }, []);
  };

  private renderSelectedPlanPrice = (): string => {
    const price = this.props.merchantOnboardingStore!.findSelectedMerchantPlan()?.price;

    if (!price && price !== 0) return '';

    return CurrencyService.formatPrice(price, this.props.merchantOnboardingStore!.currency) || '';
  };

  render(): ReactNode {
    return (
      <>
        <FormHeader
          title="Add Merchant"
          buttonCopy="Cancel"
          hasAction={this.props.merchantOnboardingStore!.canSkipOnboardingSteps}
          onAction={this.attemptToLeave}
        />
        <Container maxWidth={FORM_CONTAINER_WIDTH}>
          <Box m="100px 0">
            <FormBuilder
              config={this.buildForm()}
              notification={{
                hasNotification: !!this.state.errorNotification.length,
                notificationProps: {
                  copy: this.state.errorNotification,
                  type: NotificationType.Error
                }
              }}
            />
            <FormFooter>
              <Container maxWidth={FORM_CONTAINER_WIDTH}>
                <Flex
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box>
                    { this.props.merchantOnboardingStore!.canSkipOnboardingSteps && (
                      <Box
                        as="button"
                        onClick={this.attemptToLeave}
                      >
                        <Button
                          size="normal"
                          appearance="secondary"
                          copy="Leave"
                          isLoading={false}
                          isDisabled={this.state.isPosting}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    as="button"
                    onClick={this.onSubmit}
                    disabled={this.state.isPosting}
                  >
                    <Button
                      size="normal"
                      appearance="primary"
                      copy={this.setMerchantConfirmCopy()}
                      isLoading={this.state.isPosting}
                      isDisabled={!this.props.merchantOnboardingStore!.merchantPlans.length}
                    />
                  </Box>
                </Flex>
              </Container>
            </FormFooter>
          </Box>
        </Container>
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantOnboardingStore: stores.merchantOnboardingStore,
  merchantSettingsStore: stores.merchantSettingsStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(AddMerchant));

import { PlanType } from 'generated-types.d';

import { AddMerchantLocation } from './add-merchant.types';

export const CREATABLE_MERCHANT_PLAN_TYPES: PlanType[] = [
  PlanType.Lite1,
  PlanType.Lite2,
  PlanType.Standard,
  PlanType.Standard2,
  PlanType.Premium,
  PlanType.Premium2,
  PlanType.FloomMarket,
  PlanType.WholesaleOnly
];

export const ADD_MERCHANT_LOCATIONS: AddMerchantLocation[] = [
  {
    title: 'United Kingdom',
    key: 'uk'
  },
  {
    title: 'Boston',
    key: 'boston'
  },
  {
    title: 'Chicago',
    key: 'chicago'
  },
  {
    title: 'Houston',
    key: 'houston'
  },
  {
    title: 'Los Angeles',
    key: 'los-angeles'
  },
  {
    title: 'New York',
    key: 'new-york'
  },
  {
    title: 'San Diego',
    key: 'san-diego'
  },
  {
    title: 'San Francisco',
    key: 'san-francisco'
  }
];

import styled from '@emotion/styled';

import { colors, textStyles } from 'utils/rebass-theme';

export const ConfirmationBox = styled.div`
  background-color: ${colors.uiFills};
  ${textStyles.body};
  padding: 20px;
  height: 100%;
`;

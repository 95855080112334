import { Component, Fragment, ReactNode } from 'react';

import { globalHistory } from '@reach/router';
import { reqPostalCodeString } from 'lib/services/validation/extensions/postalcode';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import * as Yup from 'yup';

import {
  Merchant,
  Currency
} from 'generated-types.d';

import {
  ValidationService,
  PermissionsService,
  NavService
} from 'lib';

import { MerchantFieldValues } from 'stores/merchant-onboarding/merchant-onboarding-store.types';
import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { Container } from 'utils/css-mixins';
import { colors } from 'utils/rebass-theme';

import { findError, postalCodeCopy } from 'features/merchant-onboarding/helpers/merchant-create/merchant-create.helpers';
import { FORM_CONTAINER_WIDTH } from 'features/merchant-onboarding/merchant-onboarding.constants';
import MerchantOnboardingService from 'features/merchant-onboarding/services';

import Button from 'components/button';
import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import FormFooter from 'components/form-footer';
import FormHeader from 'components/form-header';
import { NotificationType } from 'components/notification/notification.types';
import StyledLink from 'components/styled-link/styled-link';

import * as Types from './merchant-shop-info.types';

class MerchantShopInfo extends Component<Types.MerchantShopInfoProps, Types.MerchantShopInfoState> {
  private MerchantCreationService = MerchantOnboardingService.MerchantCreationService;

  private historyListener = globalHistory.listen((listener): void => {
    if (!listener.location.pathname.includes('merchant/create')) {
      this.props.merchantOnboardingStore!.resetStore();
    }
  });

  validation: Yup.Schema<any> = Yup.object().shape({
    businessAdCompanyName: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Your business address needs a name')),
    businessAd1: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Address line 1 is required')),
    businessAd2: Yup
      .string()
      .notRequired(),
    businessAdCity: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'City is required')),
    businessAdPostalCode: reqPostalCodeString('businessAdPostalCode', this.props.merchantOnboardingStore!.merchantObject?.currency === Currency.Usd),
    hasChosenWholesale: Yup
      .boolean()
      .required(),
    wholesaleDeliveryInstructions: Yup
      .string()
      .when('hasChosenWholesale', {
        is: true,
        then: Yup.string().required(params => ValidationService.generateYupMessageSchema(params, 'If you have selected to configure wholesale, you need to provide delivery instructions')),
        otherwise: Yup.string().notRequired()
      })
  });

  state: Types.MerchantShopInfoState = {
    isCreating: false,
    isDirty: false,
    errorNotification: ''
  };

  constructor(props: Types.MerchantShopInfoProps) {
    super(props);
    this.redirect();
  }

  componentWillUnmount = (): void => {
    this.historyListener();
  };

  private redirect = (): void => {
    switch (true) {
      case !PermissionsService.isInternalRole():
        NavService.overview();

        break;

      case !this.props.merchantOnboardingStore!.createdMerchantId && PermissionsService.isInternalRole():
        NavService.merchantCreate('account');

        break;
    }
  };

  private handleChange = (value: MerchantFieldValues[keyof MerchantFieldValues], key: keyof MerchantFieldValues): void => {
    this.props.merchantOnboardingStore!.setValue(value, key);

    if (!this.state.isDirty) {
      this.setState({
        isDirty: true
      });
    }
  };

  private handleCompanyAddressToggle = (): void => {
    if (!this.props.merchantOnboardingStore!.values.areAddressesEqual) {
      this.props.merchantOnboardingStore!.castAddressToBusinessAddress();
    } else {
      this.props.merchantOnboardingStore!.resetBusinessAddress();
    }

    this.handleChange(!this.props.merchantOnboardingStore!.values.areAddressesEqual, 'areAddressesEqual');
  };

  private onSubmit = async (): Promise<void> => {
    try {
      await ValidationService.validateAll(this.validation, this.props.merchantOnboardingStore!.values);

      this.onUpdateMerchant(this.props.merchantOnboardingStore!.values);
    } catch (error) {
      this.props.merchantOnboardingStore!.setErrors(error.errors);
      this.props.toasterStore?.popToast('You have some validation errors', ToastType.Error);
    }
  };

  private onUpdateMerchant = async (data: MerchantFieldValues): Promise<void> => {
    this.setState({
      isCreating: true,
      errorNotification: ''
    });

    try {
      const merchant = await this.MerchantCreationService.updateMerchant(data, this.props.merchantOnboardingStore!.merchantObject!);

      if (merchant) {
        this.handleMerchantUpdate(merchant);
        this.setState({ isCreating: false });
      }
    } catch (error) {
      this.props.toasterStore?.popToast('We\'re having trouble adding your merchants shop address info. More information is provided at the bottom of the form', ToastType.Error);

      this.setState({
        isCreating: false,
        errorNotification: this.handleErrorNotification()
      });
    }
  };

  private handleErrorNotification = (): string => {
    return 'We are having trouble editing a merchant account with the details provided. Please check your entries and try again';
  };

  private handleMerchantUpdate = (merchant: Merchant): void => {
    switch (true) {
      case PermissionsService.isInternalRole():
        this.props.toasterStore?.popToast(`Merchant shop info for ${merchant.companyName} has been added to your merchants records!`, ToastType.Success);
        NavService.merchantCreate('card-details');

        break;

      default:
        break;
    }
  };

  private setNotification = (): void => {
    this.props.merchantSettingsStore!.setNotification(`You have set up your merchant (${this.props.merchantOnboardingStore!.merchantObject?.companyName}), but will need more information before they are ready to trade as some steps were skipped.`);
  };

  public attemptToLeave = (): void => {
    this.props.modalStore!.triggerModal({
      modalType: 'confirmation',
      data: {
        title: 'Leave merchant create',
        copy: 'Are you sure you want to leave? To carry on editing your merchant, you will need to select them in merchant settings',
        confirmButtonCopy: 'Yes, leave',
        cancelButtonCopy: 'No, continue',
        errorCopy: '',
        confirmButtonAction: async (): Promise<any> => new Promise((resolve): any => {
          this.props.merchantOnboardingStore!.resetStore();
          this.onLeave();
          this.setNotification();
          resolve('');
        })
      }
    });
  };

  public attemptToGoBack = (): void => {
    if (this.state.isDirty) {
      this.props.modalStore!.triggerModal({
        modalType: 'confirmation',
        data: {
          title: 'Leave merchant create',
          copy: 'You added shop info that has\'t been saved. Would you like to continue editing, or discard and go back?',
          confirmButtonCopy: 'Go back',
          cancelButtonCopy: 'Continue editing',
          errorCopy: '',
          confirmButtonAction: async (): Promise<any> => new Promise((resolve): any => {
            NavService.merchantCreate('company-info');
            resolve('');
          })

        }
      });
    } else {
      NavService.merchantCreate('company-info');
    }
  };

  public attemptToSkip = (): void => {
    if (this.state.isDirty) {
      this.props.modalStore!.triggerModal({
        modalType: 'confirmation',
        data: {
          title: 'Skip entering company info?',
          copy: 'Skipping this stage will discard any progress you\'ve made setting up business address information.',
          confirmButtonCopy: 'Skip',
          cancelButtonCopy: 'Go back',
          errorCopy: '',
          confirmButtonAction: async (): Promise<any> => new Promise((resolve): any => {
            this.setNotification();
            this.onLeave();
            resolve('');
          })
        }
      });
    } else {
      this.setNotification();
      this.onLeave();
    }
  };

  private onLeave = (): void => {
    NavService.merchantList();
  };

  public buildForm = (): FormBuilderConfig => ({
    subtitle: 'What about the trading address?',
    subCopy: (
      <Fragment>
        The trading address is the address your business operates from, and is used for courier bookings, wholesale delivery and delivery of a range of other services to your business.
      </Fragment>
    ),
    sections: [
      {
        width: '100',
        fields: [
          {
            key: 'areAddressesEqual',
            fieldType: 'toggle',
            switchBgActive: colors.validationText,
            switchBgInactive: colors.errorText,
            label: 'Same as company address?',
            controlValue: true,
            isHidden: !this.props.merchantOnboardingStore!.merchantObject?.registeredAddress,
            isChecked: this.props.merchantOnboardingStore!.values.areAddressesEqual,
            validationError: findError('areAddressesEqual', this.props.merchantOnboardingStore!.validationErrors),
            onChange: this.handleCompanyAddressToggle
          }
        ]
      },
      {
        width: '100',
        fields: [
          {
            key: 'businessAdCompanyNameLabel',
            fieldType: 'label',
            copy: 'Company name',
            validationError: undefined
          },
          {
            key: 'businessAdCompanyName',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.businessAdCompanyName,
            validationError: findError('businessAdCompanyName', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'businessAdCompanyName')
          }
        ]
      },
      {
        width: '100',
        fields: [
          {
            key: 'businessAd1Label',
            fieldType: 'label',
            copy: 'Address line 1',
            validationError: undefined
          },
          {
            key: 'businessAd1',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.businessAd1,
            validationError: findError('businessAd1', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'businessAd1')
          }
        ]
      },
      {
        width: '100',
        fields: [
          {
            key: 'businessAd2Label',
            fieldType: 'label',
            copy: 'Address line 2',
            validationError: undefined
          },
          {
            key: 'businessAd2',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.businessAd2,
            validationError: findError('businessAd2', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'businessAd2')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'businessAdCityLabel',
            fieldType: 'label',
            copy: 'City',
            validationError: undefined
          },
          {
            key: 'businessAdCity',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.businessAdCity,
            validationError: findError('businessAdCity', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'businessAdCity')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'businessAdPostalCodeLabel',
            fieldType: 'label',
            copy: postalCodeCopy(this.props.merchantOnboardingStore?.merchantObject?.currency!),
            validationError: undefined
          },
          {
            key: 'businessAdPostalCode',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.businessAdPostalCode,
            validationError: findError('businessAdPostalCode', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'businessAdPostalCode')
          }
        ]
      }
    ]
  });

  render(): ReactNode {
    return (
      <Fragment>
        <FormHeader
          title="Add Merchant"
          buttonCopy="Cancel"
          hasAction={this.props.merchantOnboardingStore!.canSkipOnboardingSteps}
          onAction={this.attemptToLeave}
        />
        <Container maxWidth={FORM_CONTAINER_WIDTH}>
          <Box m="100px 0">
            <FormBuilder
              config={this.buildForm()}
              notification={{
                hasNotification: !!this.state.errorNotification.length,
                notificationProps: {
                  copy: this.state.errorNotification,
                  type: NotificationType.Error
                }
              }}
            />
            <FormFooter>
              <Container maxWidth={FORM_CONTAINER_WIDTH}>
                <Flex
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box
                    as="button"
                    onClick={this.attemptToGoBack}
                  >
                    <Button
                      size="normal"
                      appearance="secondary"
                      copy="Back"
                      isLoading={false}
                      isDisabled={this.state.isCreating}
                    />
                  </Box>
                  <Flex alignItems="center">
                    { this.props.merchantOnboardingStore!.canSkipOnboardingSteps && (
                      <Box
                        as="button"
                        mr="20px"
                        onClick={this.attemptToSkip}
                      >
                        <StyledLink>
                          Skip
                        </StyledLink>
                      </Box>
                    )}
                    <Box
                      as="button"
                      onClick={this.onSubmit}
                      disabled={this.state.isCreating}
                    >
                      <Button
                        size="normal"
                        appearance="primary"
                        copy="Submit"
                        isLoading={this.state.isCreating}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Container>
            </FormFooter>
          </Box>
        </Container>
      </Fragment>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantOnboardingStore: stores.merchantOnboardingStore,
  merchantSettingsStore: stores.merchantSettingsStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(MerchantShopInfo));

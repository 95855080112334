import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

import FloomXLogo from 'assets/icons/marketing/logo-monogram.svg';

const Logo = styled(Box)`
  padding: 10px;
  border-radius: 10px;
  background: ${colors.floomMidnightBlue};
`;

const SubHeading = styled(Box)`
  ${textStyles.footnote}
  font-weight: 400;
  text-transform: uppercase;
`;

export const SupplierCard: FC<{
  renderSupplierName: () => ReactNode;
}> = ({
  renderSupplierName
}) => {
  return (
    <Flex alignItems="center">
      <Logo>
        <FloomXLogo
          css={{
            width: '45px',
            height: '40px'
          }}
        />
      </Logo>
      <Box pl="20px">
        <SubHeading>
          Supplier
        </SubHeading>
        <Box mt="3px">
          {renderSupplierName()}
        </Box>
      </Box>
    </Flex>
  );
};

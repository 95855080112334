import { Merchant, MutationUpdateMerchantSameDayOrderPauseStatusArgs } from 'generated-types.d';

import { GraphQL } from 'lib';

import { UPDATE_MERCHANT_SAME_DAY_ORDER_PAUSE } from './graphql/merchant-same-day-order-pause.queries';

class MerchantSameDayOrdersPauseService {
  public updateStatus = async (status: number, merchantId: string): Promise<number | null | undefined | void> => {
    const variables: MutationUpdateMerchantSameDayOrderPauseStatusArgs = {
      data: {
        sameDayOrderPauseStatus: status
      },
      where: {
        id: merchantId
      }
    };

    return GraphQL.query(UPDATE_MERCHANT_SAME_DAY_ORDER_PAUSE, variables)
      .then((result: { data: { updateMerchantSameDayOrderPauseStatus: Merchant } }) => {
        return result.data.updateMerchantSameDayOrderPauseStatus.sameDayOrderPauseStatus;
      })
      .catch();
  };
}

export default MerchantSameDayOrdersPauseService;

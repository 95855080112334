import { PlanType } from 'generated-types.d';

import { PlanUpgradeMatrix } from './overview-upgrade-plan.types';

export const PLAN_UPGRADE_MATRIX: PlanUpgradeMatrix = {
  Lite1: PlanType.Standard,
  Lite2: PlanType.Standard2,
  Standard: PlanType.Premium,
  Standard2: PlanType.Premium2
};

import { PaymentType } from 'generated-types.d';

type PaymentTypes = {
  [key in PaymentType]: string;
};

export const PAYMENT_TYPES: PaymentTypes = {
  Courier: 'Courier',
  Wholesale: 'Wholesale Order',
  Order: 'Customer Order',
  Subscription: 'Subscription',
  External: 'External'
};

import { NavPages } from 'global.types';

import {
  UserRole,
  PlanFeature
} from 'generated-types.d';

import { ConfigTile } from './settings-root.types';

const SETTINGS_NAV: ConfigTile[] = [
  {
    copy: 'Account Details',
    iconType: 'account',
    url: '/settings/account/',
    enabled: true,
    restrictedRoles: [],
    requiredPlanFeatures: []
  },
  {
    copy: 'Delivery Configs',
    iconType: 'delivery-in-progress',
    url: '/settings/delivery',
    enabled: true,
    restrictedRoles: [UserRole.TeamMember],
    requiredPlanFeatures: [PlanFeature.Products]
  },
  {
    copy: 'Delivery Coverages',
    iconType: 'map-pin',
    url: '/settings/delivery-coverage',
    enabled: true,
    restrictedRoles: [],
    requiredPlanFeatures: [PlanFeature.Products]
  },
  {
    copy: 'Payment details',
    iconType: 'card',
    url: '/settings/payments',
    enabled: true,
    restrictedRoles: [UserRole.TeamMember, UserRole.CustomerService, UserRole.SuperAdmin],
    requiredPlanFeatures: []
  },
  {
    copy: 'Wholesale settings',
    iconType: 'wholesale',
    url: '/settings/wholesale',
    enabled: true,
    restrictedRoles: [],
    requiredPlanFeatures: [PlanFeature.Wholesale]
  },
  {
    copy: 'Notification settings',
    iconType: 'note',
    url: '/settings/notifications',
    enabled: true,
    restrictedRoles: [],
    requiredPlanFeatures: []
  },
  {
    copy: 'Holidays',
    iconType: 'umbrella',
    url: '/settings/holidays',
    enabled: true,
    restrictedRoles: [],
    requiredPlanFeatures: [PlanFeature.MerchantHoliday]
  },
  {
    copy: 'Merchants',
    iconType: 'company',
    url: '/settings/merchants',
    enabled: true,
    restrictedRoles: [UserRole.TeamMember, UserRole.TeamAdmin, UserRole.TeamOwner],
    requiredPlanFeatures: []
  },
  {
    copy: 'Custom site settings',
    iconType: 'custom-site',
    url: '/settings/custom-site-settings',
    enabled: true,
    restrictedRoles: [UserRole.TeamMember, UserRole.TeamAdmin, UserRole.TeamOwner],
    requiredPlanFeatures: []
  },
  {
    copy: 'Discounts',
    iconType: 'percent',
    url: '/settings/discounts',
    enabled: true,
    restrictedRoles: [UserRole.TeamMember],
    requiredPlanFeatures: [PlanFeature.Website]
  },
  {
    copy: 'DPD Upload',
    iconType: 'delivery-succeeded',
    url: NavPages.DpdUpload,
    enabled: true,
    restrictedRoles: [UserRole.TeamMember, UserRole.TeamAdmin, UserRole.TeamOwner],
    requiredPlanFeatures: []
  }
];

export {
  SETTINGS_NAV
};

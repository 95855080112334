import {
  FC
} from 'react';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import Icon from 'components/icon';

import * as Styles from './lozenge.styles';
import * as Types from './lozenge.types';

export const Lozenge: FC<Types.LozengeProps> = props => {
  const hasRemoveAction = props.hasRemoveAction && !!props.removeAction;

  const handleRemove = (): void => {
    if (hasRemoveAction) {
      props?.removeAction?.();
    }
  };

  return (
    <Styles.Lozenge
      {...props}
      css={props.styles}
      as={hasRemoveAction ? 'button' : 'span'}
      onClick={handleRemove}
    >
      {props.copy}
      {!!hasRemoveAction && (
        <Styles.DeleteCross>
          <Icon iconName="cross-smaller" />
        </Styles.DeleteCross>
      )}
    </Styles.Lozenge>
  );
};

Lozenge.defaultProps = {
  color: ColourOption.uiFills,
  styles: null
};

export default Lozenge;

import React, { memo } from 'react';

import {
  connectCurrentRefinements,
  CurrentRefinementsProvided
} from 'react-instantsearch-core';
import { Box } from 'rebass';

import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

interface SearchBoxProps extends CurrentRefinementsProvided {
  isVisible: boolean;
  onClick: () => void;
}

const COPY = 'You have some filters already selected, click me to reset, or refine your filters by pressing "filter orders" above';

export const SelectedFiltersNotification = connectCurrentRefinements(memo((props: SearchBoxProps) => {
  if (!props.isVisible) return null;

  return (
    <Box
      width="100%"
      mt="20px"
    >
      <Notification
        type={NotificationType.Info}
        copy={COPY}
        hasIcon={false}
        hasClose={false}
        onClick={(): void => {
          props.refine(props.items);
          props.onClick();
        }}
      />
    </Box>
  );
}));

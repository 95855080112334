import { FC, useEffect, useState } from 'react';

import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import type { Layout, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

import type { PaymentMessageType } from '../types';

import PaymentMessage from './payment-message';

const StripePaymentForm: FC<{
  amount: string;
  paymentIntentId?: string;
  handleChange: (event: StripePaymentElementChangeEvent) => void;
}> = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<PaymentMessageType | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      console.log('no stripe');

      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      console.log('no client secret');

      return;
    }

    stripe.retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        console.log('featched intent', paymentIntent);

        switch (paymentIntent?.status) {
          case 'succeeded':
            setMessage({
              type: 'success',
              headerText: 'Payment succeeded!',
              bodyText: 'Your promoted postcodes have been successfully purchased.'
            });

            break;
          case 'processing':
            setMessage({
              type: 'processing',
              bodyText: 'Your payment is processing.'
            });

            break;
          case 'requires_payment_method':
            setMessage({
              type: 'error',
              bodyText: 'Your payment was not successful, please try again.'
            });

            break;

          default:
            setMessage({
              type: 'error',
              bodyText: 'Something went wrong. Please contact us.'
            });

            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async (e: { preventDefault: () => void }): Promise<void> => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements: elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/promoted/my-promotions`
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (!error.message) {
      setMessage({
        type: 'error',
        bodyText: 'An unexpected error occurred.'
      });
    } else if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage({
        type: 'error',
        bodyText: error.message
      });
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'auto' as Layout
  };

  return (
    <form
      id="payment-form"
    >
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onChange={props.handleChange}
        className="mb-5"
      />
      {message && (
        <div className="mb-5">
          <PaymentMessage
            message={message}
          />
        </div>
      )}
      <Box
        disabled={isLoading || !stripe || !elements}
        onClick={handleSubmit}
      >
        <button
          style={{
            backgroundColor: colors.floomMidnightBlue
          }}
          disabled={isLoading || !stripe || !elements}
          className="h-14 rounded-md sm:rounded-lg w-full text-white
                justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                disabled:opacity-30"
        >
          <span>{`Pay ${props.amount}`}</span>
        </button>
      </Box>
    </form>
  );
};

export default StripePaymentForm;

import {
  DeliveryZoneCreateInput
} from 'generated-types.d';

import { ZoneTag } from 'features/settings/components/delivery/components/delivery-coverage/delivery-zone-tags/delivery-zone-tags.types';

export const createDeliveryZones = (zones: ZoneTag[]): DeliveryZoneCreateInput[] => {
  return zones
    .map((zone): DeliveryZoneCreateInput => {
      return {
        postalCode: zone.postalCode,
        title: zone.postalCode
      };
    });
};

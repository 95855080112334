import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const Heading: any = styled(Flex)`
  padding: 10px 15px;
  align-items: center;
  width: 100%;
  background: ${({ status, isNew }: any): any => isNew ? hex2rgba(colors.floomCyan, .4) : colors[status.headingBg]};
  ${({ layout }: any): any => layout === 'table' && 'justify-content: center;'}
`;

export const StatusIcon: any = styled(Flex)`
  ${({ layout }: any): any => layout === 'grid' ? 'margin-right: 7px;' : 'margin-bottom: 10px;'}

  svg path {
    fill: ${({ status, isNew }: any): any => isNew ? colors.floomMidnightBlue : colors[status.color]};
  }
`;

export const OrderLink = css`
  display: flex;
`;

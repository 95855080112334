import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { breakpoints, textStyles } from 'utils/rebass-theme';

export const InputsWrapper = styled(Flex)`
  flex-flow: row wrap;
  max-width: 750px;
  > div {
    max-width: 450px;
    min-width: 350px;
    margin-right: 20px;
    @media (max-width: ${breakpoints.small}) {
      width: 100%;
      max-width: 720px;
      margin-right: 0px;
    }
  }
`;

export const SettingsSubtitle = styled.p`
  ${textStyles.title};
  margin-bottom: 5px;
`;

export const SubtitleWrappers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 350px;
  min-width: 315px;
  margin-bottom: 30px;

  @media (min-width: 1040px) {
    max-width: 720px;
  }

  @media (max-width: ${breakpoints.small}) {
    max-width: 100%;
    padding-right: 25px;
  }
`;

export const LinkWrapper = styled.div`
  margin-top: 4px;
  > span {
    float: right;
  }
`;

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { TagSelectionModalData } from 'features/modal-dialogue/components/modals/tag-selection-modal/tag-selection-modal.types';

import FieldTagSelection from 'components/field-tag-selection';
import { TagSelectionItem } from 'components/field-tag-selection/field-tag-selection.types';

import * as Styles from './colour-selection.styles';
import * as Types from './colour-selection.types';

class ColourSelection extends Component<Types.ColourSelectionProps> {
  private buildItems = (): TagSelectionItem[] => this.props.productEditStore!.colours.reduce((acc: TagSelectionItem[], colour) => {
    const disallowedColourNames = ['not provided', 'undefined'];

    if (disallowedColourNames.includes(colour.name.toLowerCase())) {
      return acc;
    }

    return [
      ...acc,
      {
        title: colour.name,
        id: colour.id,
        color: colour.hex,
        isSelected: this.props.selectedColours.includes(colour.id)
      }
    ];
  }, []);

  private openModal = (): void => {
    const modalData: TagSelectionModalData = {
      title: 'Add new colour',
      confirmButtonCopy: 'Add colours',
      items: this.buildItems(),
      limitSelection: undefined,
      confirmButtonAction: this.onUpdateColours
    };

    this.props.modalStore!.triggerModal({ modalType: 'tagSelection', data: modalData });
  };

  private onUpdateColours = (items: TagSelectionItem[]): void => {
    const selectedIds = items
      .filter(item => item.isSelected)
      .reduce((acc: string[], currItem) => [...acc, currItem.id], []);

    this.props.setColours(selectedIds);
  };

  private onRemoveColour = (colourId: string): void => {
    const filteredColours = this.props.selectedColours.filter(colour => colour !== colourId);

    this.props.setColours(filteredColours);
  };

  render(): JSX.Element {
    return (
      <Styles.Content>
        <FieldTagSelection
          itemCopySingular="colour"
          itemCopyPlural="colours"
          isLoading={!this.props.productEditStore!.colours?.length}
          totalItemsCount={this.props.productEditStore!.colours?.length || 0}
          items={this.buildItems().filter(item => item.isSelected)}
          onRemove={this.onRemoveColour}
          onAdd={this.openModal}
        />
      </Styles.Content>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  modalStore: stores.modalStore
}))(observer(ColourSelection));

import styled from '@emotion/styled';

import { colors } from 'utils/rebass-theme';

import { FIELD_SIZES } from '../field-text.styles';
import { InputSize } from '../field-text.types';

export const Wrapper = styled.div<{ size: InputSize }>`
  position: absolute;

  ${({ size }) => {
    return `
      top: ${FIELD_SIZES[size].prefixPosition};
      left: ${FIELD_SIZES[size].prefixPosition};
    `;
  }};
`;

export const Copy = styled.p<{ size: InputSize }>`
  width: 20px;
  height: 20px;
  color: ${colors.middleGrey};
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
  line-height: ${({ size }) => FIELD_SIZES[size].prefixHeight};
`;

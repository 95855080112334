import { Component, Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';
import * as Yup from 'yup';

import {
  PlanType,
  MerchantPlan,
  PlanFeature
} from 'generated-types.d';
import { SupportedISOString } from 'global-types';
import {
  MERCHANT_TRIAL_PERIODS
} from 'global.constants';

import {
  CurrencyService,
  LocalisationService,
  MerchantPlanService,
  MerchantService,
  MerchantSubscriptionService,
  TimeService,
  ValidationService
} from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import NoResultsGif from 'assets/images/wholesale/no-results.gif';

import Button from 'components/button';
import FormBuilder from 'components/form-builder';
import GenericModal from 'components/generic-modal';
import NoResultsGeneric from 'components/no-results-generic';
import PaymentDetailsCard from 'components/payment-details-card';
import WithLoading from 'components/with-loading';

import {
  DOWNGRADE_COPY,
  SUPPORTED_PLAN_TYPES,
  UNSUBSCRIBED_COPY,
  UPGRADE_COPY,
  UNSUBSCRIBED_CONFIRMATION_COPY,
  UPGRADE_CONFIRMATION_COPY,
  DOWNGRADE_CONFIRMATION_COPY,
  PLAN_SHIFT_FORM_VALIDATION,
  NEUTRAL_PLAN_CHANGE_COPY
} from './plan-shift-modal.constants';
import {
  Body,
  Container,
  Heading,
  PlanDetailItem,
  PlanDetails,
  SubHeading
} from './plan-shift-modal.styles';
import {
  ExtendedPlanShiftFormData,
  PlanShiftFormData,
  PlanShiftModalProps,
  PlanShiftModalState,
  SelectedPlanConfig,
  SupportedPlanType
} from './plan-shift-modal.types';

class PlanShiftModal extends Component<PlanShiftModalProps, PlanShiftModalState> {
  state: PlanShiftModalState = {
    isSaving: false,
    isLoading: true,
    isEstimating: false,
    canPlanShift: true,
    plans: [],
    formErrors: [],
    upgradeEstimate: null,
    isMerchantSubscribed: MerchantService.isSubscribed(this.props.data.merchant),
    formData: {
      hasConfirmed: false,
      planShiftReason: '',
      selectedPlan: null,
      selectedPlanConfig: null,
      selectedTrialPeriod: `${this.props.data.merchant?.subscription?.trialLength}` || '',
      planPrice: null
    }
  };

  componentDidMount = (): void => {
    this.fetchPlans();
  };

  private validateForm = async (): Promise<ExtendedPlanShiftFormData> => {
    try {
      const formData = await ValidationService.validateAll<ExtendedPlanShiftFormData>(
        PLAN_SHIFT_FORM_VALIDATION,
        {
          ...this.state.formData,
          hasSubscribed: this.state.isMerchantSubscribed
        }
      );

      return formData;
    } catch (error: any) {
      this.setState({
        formErrors: error?.errors
      });

      this.props.toasterStore?.popToast('You have some validation errors', ToastType.Error);

      return Promise.reject(error);
    }
  };

  private updateUnsubscribedMerchant = async (formData: ExtendedPlanShiftFormData): Promise<void> => {
    formData.hasConfirmed = true;

    try {
      await MerchantService.updateMerchant({ id: this.props.data.merchant.id }, {
        subscription: {
          update: {
            stripeSubscriptionPrice: formData.planPrice,
            trialLength: parseInt(formData.selectedTrialPeriod)
          }
        },
        plan: {
          connect: {
            id: formData.selectedPlan?.id
          }
        }
      });

      this.props.toasterStore?.popToast(
        `You've change the plan for ${this.props.data.merchant.title} to ${formData.selectedPlan?.type}`,
        ToastType.Success
      );
    } catch (error) {
      this.props.toasterStore?.popToast(
        `We're having trouble updating the plan for ${this.props.data.merchant.title}`,
        ToastType.Error
      );

      return Promise.reject(error);
    }
  };

  private upgradeMerchant = async (formData: ExtendedPlanShiftFormData): Promise<void> => {
    try {
      if (!this.state.upgradeEstimate?.stripePriceId) {
        await this.estimatePlanChangePrice();
      }

      await MerchantSubscriptionService.updateMerchantSubscription(
        formData.selectedPlan!.id,
        this.state.upgradeEstimate!.stripePriceId!,
        this.state.upgradeEstimate!.prorationStartsAt,
        this.props.data.merchant.id
      );

      this.props.toasterStore?.popToast(
        `You've upgraded ${this.props.data.merchant.title} to ${formData.selectedPlan?.type}`,
        ToastType.Success
      );
    } catch (error) {
      this.props.toasterStore?.popToast(
        `We're having trouble upgrading the plan for ${this.props.data.merchant.title}`,
        ToastType.Error
      );

      return Promise.reject(error);
    }
  };

  private estimatePlanChangePrice = async (): Promise<void> => {
    if (!this.state.isEstimating) {
      const planPrice = this.state.formData.planPrice;

      const canEstimate = !!this.state.formData.selectedPlan?.id
        || !!planPrice
        || this.state.formData.planPrice! > 0;

      if (!canEstimate) {
        this.props.toasterStore?.popToast(
          'The plan change price cannot be estimated until we have the plan details',
          ToastType.Error
        );

        return;
      }

      try {
        this.setState({ isEstimating: true });
        const upgradeEstimate = await MerchantSubscriptionService.estimateMerchantSubscription(
          this.state.formData.selectedPlan!.id,
          this.props.data.merchant.id,
          planPrice!
        );

        this.setState({
          upgradeEstimate: upgradeEstimate,
          isEstimating: false
        });
      } catch (error) {
        this.setState({ isEstimating: false });

        this.props.toasterStore?.popToast(
          `We're having trouble estimate the upgrade price for ${this.props.data.merchant.title}`,
          ToastType.Error
        );

        return Promise.reject(error);
      }
    }
  };

  private downgradeMerchantSubscription = async (formData: ExtendedPlanShiftFormData): Promise<void> => {
    try {
      await MerchantSubscriptionService.createProposedMerchantUpdate({
        isConfirmed: formData.hasConfirmed,
        planPrice: formData.planPrice,
        reason: formData.planShiftReason,
        merchant: {
          connect: {
            id: this.props.data.merchant.id
          }
        },
        plan: {
          connect: {
            id: formData.selectedPlan!.id
          }
        }
      });

      this.props.toasterStore?.popToast(
        `The FloomX plan for ${this.props.data.merchant.title} will downgrade to ${formData.selectedPlan?.type} on ${this.currentPeriodEndDate()}`,
        ToastType.Success
      );
    } catch (error) {
      this.props.toasterStore?.popToast(
        `We're having trouble downgrading the plan for ${this.props.data.merchant.title}`,
        ToastType.Error
      );

      return Promise.reject(error);
    }
  };

  private onPlanShift = async (): Promise<void> => {
    try {
      this.setState({ isSaving: true });

      const formData = await this.validateForm();
      const changeType = this.state.formData.selectedPlanConfig?.changeType;

      switch (true) {
        case !formData.hasSubscribed:
          await this.updateUnsubscribedMerchant(formData);

          break;

        case ['upgrade', 'neutral'].includes(changeType!):
          await this.upgradeMerchant(formData);

          break;

        case changeType === 'downgrade':
          await this.downgradeMerchantSubscription(formData);

          break;
      }

      await this.props.data.beforeClose();

      this.props.closeModal();
    } catch (error) {
      this.setState({ isSaving: false });
    }
  };

  private fetchPlans = async (): Promise<void> => {
    const disablePlanShiftState = (): void => {
      this.setState({
        isLoading: false,
        canPlanShift: false
      });

      return;
    };

    const supportedPlans = SUPPORTED_PLAN_TYPES[this.props.data.merchant.plan?.type as SupportedPlanType];

    if (!supportedPlans) {
      return disablePlanShiftState();
    }

    try {
      const plans = await MerchantPlanService.fetchMerchantPlans({
        currency: this.props.data.merchant.currency,
        type_in: supportedPlans.map(plan => plan.planType)
      });

      this.setState({
        plans: plans,
        isLoading: false,
        canPlanShift: true
      });
    } catch (error) {
      return disablePlanShiftState();
    }
  };

  private setValue = (
    value: PlanShiftFormData[keyof PlanShiftFormData],
    key: keyof PlanShiftFormData
  ): void => {
    this.setState(state => {
      return {
        formErrors: state.formErrors?.filter(error => error.path !== key),
        formData: {
          ...state.formData,
          [key]: value
        }
      };
    });
  };

  private findSelectedPlanConfig = (plan: MerchantPlan | undefined): SelectedPlanConfig | undefined => {
    return SUPPORTED_PLAN_TYPES[this.props.data.merchant.plan?.type as SupportedPlanType].find(supportedPlan => {
      return supportedPlan.planType === plan?.type;
    });
  };

  private findError = (key: keyof PlanShiftFormData): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(this.state.formErrors, key);
  };

  private resetProrataFee = (): void => {
    this.setState({
      upgradeEstimate: null
    });
  };

  private currentPeriodEndDate = (): string => {
    return TimeService.dateMonthYearShort(this.props.data.merchant?.subscription?.currentPeriodEndsAt);
  };

  private buttonCopy = (): string => {
    const changeType = this.state.formData.selectedPlanConfig?.changeType;

    switch (true) {
      case changeType === 'downgrade':
        return 'Schedule change now';

      case changeType === 'upgrade':
        return 'Upgrade plan now';

      default:
        return 'Change plan now';
    }
  }

  private renderForm = (): ReactNode => {
    return (
      <Box mt="10px">
        <FormBuilder
          config={{
            sections: [
              {
                width: '50',
                paddingBottom: '0',
                fields: [
                  {
                    key: 'selectedPlanLabel',
                    fieldType: 'labelSmall',
                    copy: 'Select new plan',
                    validationError: undefined
                  },
                  {
                    key: 'selectedPlanField',
                    fieldType: 'dropdown',
                    placeholder: 'Select a plan',
                    selected: this.state.formData.selectedPlan?.id,
                    id: 'plan-shift-new-plan',
                    options: this.state.plans,
                    optionTitleField: 'type',
                    optionValueField: 'id',
                    validationError: this.findError('selectedPlan'),
                    onChange: (value: string): void => {
                      const selectedPlan = this.state.plans.find(plan => plan.id === value);
                      const selectedPlanConfig = this.findSelectedPlanConfig(selectedPlan);

                      if (selectedPlan && selectedPlanConfig) {
                        this.resetProrataFee();

                        this.setState(state => ({
                          formData: {
                            ...state.formData,
                            selectedPlan: selectedPlan,
                            selectedPlanConfig: selectedPlanConfig,
                            planPrice: selectedPlan?.price || 0.00
                          },
                          formErrors: state.formErrors?.filter(error => !['selectedPlan', 'planPrice'].includes(error.path))
                        }));
                      }
                    }
                  }
                ]
              },
              {
                width: '50',
                paddingBottom: '0',
                fields: [
                  {
                    key: 'planPriceLabel',
                    fieldType: 'labelSmall',
                    copy: 'Cost of new plan',
                    validationError: undefined
                  },
                  {
                    key: 'planPriceField',
                    fieldType: 'textInput',
                    type: 'number',
                    value: typeof this.state.formData.planPrice === 'number' ? this.state.formData.planPrice : '',
                    prefix: CurrencyService.renderCurrencySymbol(this.props.data.merchant?.currency as SupportedISOString),
                    validationError: this.findError('planPrice'),
                    onChange: (e): void => {
                      this.setValue(e.target.value ? parseFloat(e.target.value) : null, 'planPrice');
                      this.resetProrataFee();
                    }
                  }
                ]
              },
              {
                width: '100',
                paddingBottom: '0',
                fields: [
                  {
                    key: 'planShiftReasonLabel',
                    fieldType: 'labelSmall',
                    copy: 'Why is the plan changing?',
                    validationError: undefined
                  },
                  {
                    key: 'planShiftReasonField',
                    fieldType: 'textInput',
                    placeholder: '',
                    maxLength: 100,
                    displayMaxLength: true,
                    value: this.state.formData.planShiftReason,
                    validationError: this.findError('planShiftReason'),
                    onChange: (e): void => {
                      this.setValue(e.target.value, 'planShiftReason');
                    }
                  }
                ]
              },
              {
                isHidden: this.state.isMerchantSubscribed,
                paddingBottom: '0',
                width: '100',
                fields: [
                  {
                    key: 'trialPeriodLabel',
                    fieldType: 'labelSmall',
                    copy: 'Select no-fee period',
                    isHidden: false,
                    validationError: undefined
                  },
                  {
                    key: 'trialPeriodField',
                    fieldType: 'radioList',
                    itemType: 'card',
                    itemTitleField: 'title',
                    itemValueField: 'id',
                    orientation: 'horizontal',
                    items: MERCHANT_TRIAL_PERIODS,
                    selectedItem: this.state.formData.selectedTrialPeriod,
                    validationError: this.findError('selectedTrialPeriod'),
                    onChange: (trialPeriod: string): void => {
                      this.setValue(trialPeriod, 'selectedTrialPeriod');
                    }
                  }
                ]
              },
              {
                paddingBottom: '0',
                width: '100',
                fields: [
                  {
                    key: 'planDetails',
                    fieldType: 'custom',
                    customContent: (
                      <Fragment>
                        {this.renderPlanDetailCopy()}
                        {this.renderPlanDetails()}
                        {this.renderCardDetails()}
                      </Fragment>
                    )
                  }
                ]
              },
              {
                width: '100',
                paddingBottom: '0',
                isHidden: !this.state.formData.selectedPlan,
                fields: [
                  {
                    key: 'planChangeConfirmationField',
                    fieldType: 'singleCheckbox',
                    label: this.renderConfirmationCopy(),
                    isSelected: this.state.formData.hasConfirmed,
                    validationError: this.findError('hasConfirmed'),
                    handleSelect: (): void => {
                      this.setValue(!this.state.formData.hasConfirmed, 'hasConfirmed');
                    },
                    customStyles: css`
                      margin: 25px 0 0 10px;
                    `
                  }
                ]
              }
            ]
          }}
        />
      </Box>
    );
  };

  private renderConfirmationCopy = (): string => {
    const changeType = this.state.formData.selectedPlanConfig?.changeType;

    return ((): string => {
      switch (true) {
        case !this.state.isMerchantSubscribed:
          return UNSUBSCRIBED_CONFIRMATION_COPY;

        case changeType === 'downgrade':
          return DOWNGRADE_CONFIRMATION_COPY;

        case changeType === 'upgrade':
          return UPGRADE_CONFIRMATION_COPY;

        case changeType === 'neutral':
          return NEUTRAL_PLAN_CHANGE_COPY;

        default:
          return '';
      }
    })()
      .replace('[MERCHANT_NAME]', this.props.data.merchant?.title)
      .replace('[OLD_PLAN_NAME]', this.props.data.merchant.plan?.type || '')
      .replace('[NEW_PLAN_NAME]', this.state.formData.selectedPlan?.type || '')
      .replace('[NEW_PLAN_PRICE]', CurrencyService.formatPrice(this.state.formData.planPrice || 0, this.props.data.merchant.currency))
      .replace('[BILLING_END_DATE]', this.currentPeriodEndDate());
  };

  private renderIntro = (): ReactNode => {
    const merchant = this.props.data.merchant;
    const currentPlanName = LocalisationService.localisePlanName(merchant.plan?.type);
    const currentPlanPrice = CurrencyService.formatPrice(merchant.subscription?.stripeSubscriptionPrice || 0, this.props.data.merchant.currency);
    const productLimit = merchant.subscription?.productLimit || 'Unlimited';
    const orderLimit = merchant.subscription?.orderLimit || 'Unlimited';
    const productLimitCopy = merchant.plan?.features?.includes(PlanFeature.Floom)
      ? ` Their current plan supports <strong>${productLimit}</strong> active products and <strong>${orderLimit}</strong> orders per month.`
      : '';

    return (
      <Body
        dangerouslySetInnerHTML={{
          __html: `
            You are requesting to change the plan for <strong>${merchant.title}</strong>. Their current
            plan is <strong>${currentPlanName}</strong>, and they are paying <strong>${currentPlanPrice} /mo</strong>.
            ${productLimitCopy}
          `
        }}
      />
    );
  };

  private renderPlanDetailCopy = (): ReactNode => {
    const isSubscribed = this.state.isMerchantSubscribed;

    if (!this.state.formData.selectedPlanConfig && !isSubscribed) return null;

    const changeType = this.state.formData.selectedPlanConfig?.changeType;

    const copy = ((): string => {
      switch (true) {
        case !isSubscribed:
          return UNSUBSCRIBED_COPY;

        case changeType === 'downgrade':
          return DOWNGRADE_COPY;

        case changeType === 'upgrade':
          return UPGRADE_COPY;

        default:
          return '';
      }
    })();

    return (
      <Body
        mt="30px"
        dangerouslySetInnerHTML={{
          __html: copy.replace('[BILLING_END_DATE]', this.currentPeriodEndDate())
        }}
      />
    );
  };

  private renderPlanDetails = (): ReactNode => {
    if (!this.state.formData.selectedPlan) return null;

    return (
      <PlanDetails>
        <PlanDetailItem>
          <SubHeading>
            New Plan
          </SubHeading>
          <Heading>
            {this.state.formData.selectedPlan.type || 'Select plan'}
          </Heading>
        </PlanDetailItem>
        <PlanDetailItem>
          <SubHeading>
            New Price
          </SubHeading>
          <Heading>
            {((): string => {
              const isNumber = typeof this.state.formData.planPrice === 'number';

              switch (true) {
                case !isNumber && !!this.state.formData.selectedPlan?.id:
                  return 'Invalid price';

                case isNumber:
                  return CurrencyService.formatPrice(this.state.formData.planPrice || 0, this.props.data.merchant.currency);

                default:
                  return 'Select plan';
              }
            })()}
          </Heading>
        </PlanDetailItem>
        <PlanDetailItem>
          {this.renderPlanDetailContext()}
        </PlanDetailItem>
      </PlanDetails>
    );
  };

  private renderPlanDetailContext = (): ReactNode => {
    const planChangeType = this.state.formData.selectedPlanConfig?.changeType;

    switch (true) {
      case !this.state.isMerchantSubscribed:
        const trialPeriod = this.state?.formData?.selectedTrialPeriod;

        return (
          <Fragment>
            <SubHeading>
              No fee period
            </SubHeading>
            <Heading>
              {trialPeriod === '0' ? 'n/a' : `${trialPeriod} days`}
            </Heading>
          </Fragment>
        );

      case !!this.state.upgradeEstimate:
        return (
          <Fragment>
            <SubHeading>
              Pro-rata fee
            </SubHeading>
            <Heading>
              {CurrencyService.formatPrice(this.state.upgradeEstimate!.amountToPay!, this.props.data.merchant.currency)}
            </Heading>
          </Fragment>
        );

      case planChangeType === 'upgrade' && !this.state.upgradeEstimate:
        return (
          <Fragment>
            <SubHeading>
              Pro-rata fee
            </SubHeading>
            <button
              css={{ textDecoration: 'underline' }}
              onClick={this.estimatePlanChangePrice}
            >
              <Heading>
                {((): string => {
                  switch (true) {
                    case this.state.isEstimating:
                      return 'Loading...';

                    case !!this.state.upgradeEstimate?.amountToPay:
                      return CurrencyService.formatPrice(this.state.upgradeEstimate!.amountToPay!, this.props.data.merchant.currency);

                    default:
                      return 'Estimate fee';
                  }
                })()}
              </Heading>
            </button>
          </Fragment>
        );

      case planChangeType === 'downgrade':

      case planChangeType === 'neutral':
        return (
          <Fragment>
            <SubHeading>
              Takes effect on
            </SubHeading>
            <Heading>
              {this.currentPeriodEndDate()}
            </Heading>
          </Fragment>
        );

      default:
        return null;
    }
  };

  private renderCardDetails = (): ReactNode => {
    if (!this.state.isMerchantSubscribed) return null;

    return (
      <Box m="30px 0 20px 0">
        <Body mb="20px">
          We will be using the current payment method on record for this plan change.
        </Body>
        <PaymentDetailsCard
          paymentMethod={this.props.data.merchant.paymentMethod}
          fullWidth={true}
        />
      </Box>
    );
  };

  private renderNoResults = (): ReactNode => {
    const hasSupportedPlan = SUPPORTED_PLAN_TYPES[this.props.data?.merchant?.plan?.type as PlanType];

    return (
      <Box p="30px 15px">
        <NoResultsGeneric
          image={NoResultsGif}
          heading="Unable to change this merchants plan"
          copy={hasSupportedPlan
            ? 'They have a legacy plan so a member of the tech team will need to manually change the plan for you.'
            : 'Please speak with a member of the tech team who will be able to investigate for you.'}
        />
      </Box>
    );
  };

  render(): ReactNode {
    return (
      <GenericModal
        title="Change merchant plan"
        closeModal={this.props.closeModal}
        modalOpen={this.props.isOpen}
        confirmButtonText={this.buttonCopy()}
        confirmButtonAction={this.onPlanShift}
        isButtonLoading={this.state.isSaving}
        confirmButtonDisabled={!this.state.canPlanShift || this.state.isLoading}
        width={550}
        extraAction={(
          <Box onClick={this.props.closeModal}>
            <Button
              size="normal"
              appearance="secondary"
              copy="Cancel"
              isLoading={false}
              isDisabled={false}
            />
          </Box>
        )}
        innerComponent={(
          <WithLoading
            isLoading={this.state.isLoading}
            marginTop="0"
            padding="100px 0 300px 0"
            hasNoResults={!this.state.isLoading && !this.state.canPlanShift}
            renderNoResults={this.renderNoResults}
            loaderSize="small"
          >
            <Container>
              {this.renderIntro()}
              {this.renderForm()}
            </Container>
          </WithLoading>
        )}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  toasterStore: stores.toasterStore
}))(observer(PlanShiftModal));

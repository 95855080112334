import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { TermsService, Analytics } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import GenericModal from 'components/generic-modal';
import Loader from 'components/loader';

import TermsContent from '../terms-content';

import * as Styles from './terms-modal.styles';
import * as Types from './terms-modal.types';

class TermsModal extends Component<Types.TermsModalProps, Types.TermsModalState> {
  isFetching = false;

  state = {
    isLoading: true,
    hasError: false,
    data: null
  };

  componentDidUpdate = (): void => {
    if (this.state.isLoading && this.props.isOpen && !this.isFetching) {
      this.isFetching = true;
      this.fetchData();
    }
  };

  private fetchData = (): void => {
    TermsService.fetchTermsPage('termsConditionsPage', this.props.userStore!.userMerchant)
      .then((data: any) => {
        this.isFetching = false;

        this.setState({
          isLoading: false,
          data: data
        });

        if (this.props.userStore!.isTeamRole()) {
          Analytics.track(Analytics.FxEventName.TermsViewed, {
            event_version: 1,
            entry_point: this.props.context,
            terms_last_edited: data.updatedAt,
            terms_version: data.version
          });
        }
      })
      .catch(() => {
        this.isFetching = false;

        this.setState({
          isLoading: false,
          hasError: true
        });
      });
  };

  private closeModal = (): void => {
    this.props.closeModal();

    setTimeout(() => {
      this.setState({
        data: null,
        isLoading: true
      });
    }, 500);
  };

  private shouldDisplayContent = (): boolean => {
    return !!this.state.data
      && typeof this.state.data !== 'string'
      && !this.state.isLoading
      && !this.isFetching
      && !this.state.hasError;
  };

  render(): React.ReactNode {
    return (
      <GenericModal
        modalOpen={this.props.isOpen}
        closeModal={this.closeModal}
        confirmButtonAction={this.closeModal}
        confirmButtonText="Exit"
        // @ts-ignore
        title={this.state.hasError ? 'Error' : this.state.data ? this.state.data!.title : '...'}
        width={649}
        innerComponent={(
          <>
            {this.shouldDisplayContent() ?
              // @ts-ignore
              <TermsContent data={this.state.data!} />
              : (
                <Box p="100px 0">
                  <Styles.ContentLoader justifyContent="center">
                    { !this.state.hasError && typeof this.state.data !== 'string' ?
                      <Loader />
                      : <Box css={textStyles.body}>Unable to load terms & conditions</Box>
                    }
                  </Styles.ContentLoader>
                </Box>
              )}
          </>
        )}
      />
    );
  }
}

export default inject('userStore')(observer(TermsModal));

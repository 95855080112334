import { ReactNode } from 'react';

import { SerializedStyles } from '@emotion/react';
import { CatalogItemHit } from 'global.types';

import {
  CatalogItemType,
  ListItemType,
  ListItemUnion,
  ListType
} from 'generated-types.d';

export interface ListGroupConfig {
  title: string;
}

export interface ListQueryVars {
  search: string;
  displayActive: boolean;
  orderBy: string;
  merchantId: string | null;
  skip: number;
  first: number;
}

export type ListFieldKey =
  | 'itemName'
  | 'colour'
  | 'stemLength'
  | 'potSize'
  | 'height'
  | 'weight'
  | 'maturity'
  | 'estimatedPrice'
  | 'price'
  | 'unitPrice'
  | 'maxUnitPrice'
  | 'quantity'
  | 'total'
  | 'delete';

export type ListLayoutFields<T = ListItemUnion> = {
  productDetail?: ListFieldConfig<T>[];
  pricing?: ListFieldConfig<T>[];
}

export type ListFieldConfig<T = ListItemUnion> = {
  key: ListFieldKey;
  heading: string;
  customStyles?: SerializedStyles;
  supportedTypes?: Array<ListItemType | CatalogItemType>;
  renderValue?: (item: T) => ReactNode | null;
  isMeta?: boolean;
}

export type ListLayoutType = ListType | 'Default';

export type ListLayoutConfig<T = ListItemUnion> = {
  [key in ListLayoutType]?: {
    titleField: ListFieldConfig<T>;
    fields: ListFieldConfig<T>[];
  };
}

export type SearchListFieldConfig<T = CatalogItemHit> = {
  key: ListFieldKey;
  heading: string;
  customStyles?: SerializedStyles;
  supportedTypes?: Array<ListItemType | CatalogItemType>;
  renderValue?: (item: T) => ReactNode | null;
  isMeta?: boolean;
}

export type SearchListLayoutConfig<T = CatalogItemHit> = {
  [key in ListLayoutType]?: {
    titleField: SearchListFieldConfig<T>;
    fields: SearchListFieldConfig<T>[];
  }
}

export enum ListMode {
  FULL = 'FULL',
  CHECKOUT_ONLY = 'CHECKOUT_ONLY',
  SHARE_ONLY = 'SHARE_ONLY',
  SHARE_TO_CONVERSATION = 'SHARE_TO_CONVERSATION',
  SHARE_TO_CONVERSATION_SHARE_ONLY = 'SHARE_TO_CONVERSATION_SHARE_ONLY'
}

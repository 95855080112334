import React, { Component, ChangeEvent } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { Text, Box, Flex } from 'rebass';

import { NavService } from 'lib';

import { OnboardingStage } from 'stores/payment-onboarding/payment-onboarding-store.types';

import { textStyles } from 'utils/rebass-theme';

import OnboardingWrapper from 'features/onboarding/components/onboarding-wrapper';

import Button from 'components/button';
import FormFooter from 'components/form-footer';

import ErrorMessage from '../../../../components/error-message';
import MerchantOnboardingService from '../../../merchant-onboarding/services';

import * as Types from './onboarding-stripe-connect.types';

class OnboardingStripeConnect extends Component<Types.OnboardingStripeConnectProps, Types.OnboardingStripeConnectState> {
  private MerchantCreationService = MerchantOnboardingService.MerchantCreationService;

  copy = {
    heading: 'Custom site payments',
    subheading: 'How you will get paid from your custom site',
    copy: 'We ask you to create a new Stripe account or connect an existing acccount in order for us to be able to pay you when customers checkout using your custom website'
  };

  state = {
    isFinalisingStripeConnect: true,
    errors: ''
  };

  componentDidMount = (): void => {
    this.props.paymentOnboardingStore!.setStage(OnboardingStage.StripeConnect);
    this.formatPage();
  };

  private onContinue = (): void => {
    if (this.isConnected()) {
      const firstIncompleteRoute = this.props.paymentOnboardingStore!.findFirstIncompleteRoute();

      if (!firstIncompleteRoute) {
        this.props.paymentOnboardingStore!.resetStage();
        NavService.onboardingConfirmation();
      } else {
        const firstIncompleteStep = this.props.paymentOnboardingStore!.onboardingConfig.findIndex(step => !step.isComplete && step.isRequired);
        this.props.paymentOnboardingStore!.onboardingConfig?.[firstIncompleteStep]?.navigateToStep();
      }
    }
  };

  private formatPage = (): void => {
    if (this.isConnected()) {
      this.setState({
        isFinalisingStripeConnect: false
      });
    } else {
      this.handleStripeConnect();
    }
  };

  private preventConnect = (e: ChangeEvent<HTMLAnchorElement>): void => {
    if (this.isConnected()) {
      e.preventDefault();
    }
  };

  private showError = (): void => {
    this.setState({
      errors: 'Whoops-a-daisy, try again!'
    });

    this.setState({
      isFinalisingStripeConnect: false
    });
  };

  private handleStripeConnect = async (): Promise<void> => {
    if (this.isStripeRedirect()) {
      const code = queryString.parse(window?.location?.search)?.code;

      if (typeof code === 'string') {
        try {
          const updatedMerchant = await this.MerchantCreationService.authStripeConnect(
            { code },
            this.props.merchantStore!.merchant!
          );

          if (!!updatedMerchant) {
            this.props.merchantStore!.setMerchant(updatedMerchant);
            this.props.userStore!.setUserMerchant(updatedMerchant);

            this.setState({
              isFinalisingStripeConnect: false
            });
          } else {
            this.showError();
          }
        } catch (error) {
          this.showError();
        }
      }
    } else {
      this.setState({
        isFinalisingStripeConnect: false
      });
    }
  };

  private isConnected = (): boolean => {
    return !!this.props.merchantStore!.merchant?.stripeConnectAccountId;
  };

  private isStripeRedirect = (): boolean => {
    return window?.location?.pathname?.includes('stripe-connect') && !!queryString.parse(window?.location?.search)?.code;
  };

  render(): JSX.Element {
    const isConnected = this.isConnected();

    return (
      <OnboardingWrapper my="auto">
        <Text
          css={textStyles.headline}
          color="floomMidnightBlue"
          mb="30px"
          mt="50px"
        >
          {this.copy.heading}
        </Text>
        <Text
          css={textStyles.title}
          color="floomMidnightBlue"
          mb="10px"
        >
          {this.copy.subheading}
        </Text>
        <Text
          css={textStyles.body}
          mb="20px"
        >
          {this.copy.copy}
        </Text>
        <Box
          as="a"
          href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.STRIPE_CONNECT_REDIRECT_URL_ONBOARDING}&scope=read_write`}
          onClick={this.preventConnect}
          css={css`
            display: inline-block;
            cursor: ${isConnected ? 'pointer' : 'not-allowed'}
          `}
        >
          <Button
            size="normal"
            copy={isConnected ? 'Connected' : 'Connect stripe'}
            appearance={isConnected ? 'success' : 'secondary'}
            isParentWidth={false}
            isLoading={this.state.isFinalisingStripeConnect}
          />
          <ErrorMessage
            errorMessage={this.state.errors}
          />
        </Box>
        <FormFooter hasOffset={false}>
          <OnboardingWrapper my="auto">
            <Flex
              justifyContent="space-between"
              width="100%"
            >
              <Box />
              <Box
                as="button"
                onClick={this.onContinue}
                disabled={!this.isConnected()}
              >
                <Button
                  size="normal"
                  appearance="primary"
                  copy="Continue"
                  isDisabled={!this.isConnected()}
                />
              </Box>
            </Flex>
          </OnboardingWrapper>
        </FormFooter>
      </OnboardingWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  paymentOnboardingStore: stores.paymentOnboardingStore,
  userStore: stores.userStore
}))(observer(OnboardingStripeConnect));

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { breakpoints } from 'utils/rebass-theme';

export const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 20px;
  width: 100%;

  @media (max-width: ${breakpoints.medium}) {
    grid-template-columns: none;
  }
`;

import { gql } from '@apollo/client';

export const CONVERSATIONS_QUERY = gql`
  query Conversations(
    $where: MerchantWhereInput
  ) {
    conversations(
      where: $where
    ) {
      id
      merchantId
      name
      unreadMessagesCount
    }
  }
`;

import { createContext } from 'react';

import { ListLayoutType } from '../../../lists/lists.types';

export interface SingleWholesalePreOrderContextProps {
  isEditingPreOrder: boolean;
  isPreOrderEditable: boolean;
  isPreOrderActive: boolean;
  listType: ListLayoutType;
  fetchPreOrder?: () => Promise<void>;
  toggleEditingState?: () => void;
}

/**
 * Instantiate context to share computed data across abstracted sub components
 * of single pre-order view.
 */
export const SingleWholesalePreOrderContext = createContext<SingleWholesalePreOrderContextProps>({
  isEditingPreOrder: false,
  isPreOrderEditable: false,
  isPreOrderActive: false,
  listType: 'Default'
});

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { mq } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const LoginWrapper = styled.div`
  margin: 140px auto 30px auto;
  max-width: 360px;
  position: relative;
`;

export const Error = styled.p`
  ${textStyles.footnote};
  margin: 10px 0;
  color: ${colors.floomRed};
`;

export const BackArrow = css`
  width: 15px;
  height: 15px;
  margin-right: 5px;

  ${mq.lessThan('medium')} {
    width: 13px;
    height: 13px
  }
`;

import { OrderGlobalProperties } from 'lib/services/analytics/analytics.types';
import moment from 'moment';

import { Order } from 'generated-types.d';

import { Analytics } from 'lib';

import { OrderPageRoute } from 'features/orders/orders.types';

class OrderAnalytics {
  public onEditStatus = (
    entryPoint: Analytics.OrderEditEntry,
    toStatus: string,
    order: Order
  ): void => {
    Analytics.track(Analytics.FxEventName.OrderStatusUpdate, {
      event_version: 1,
      entry_point: entryPoint,
      in_app: false,
      from_status: order.status.slug,
      to_status: toStatus,
      minutes_since_order_created: moment().diff(moment(order.orderedAt), 'minutes'),
      ...this.buildOrderGlobalProperties(order)
    });
  };

  public onSearch = (
    listLayout: OrderPageRoute | 'orderOverview',
    query: string
  ): void => {
    Analytics.track(Analytics.FxEventName.OrderSearch, {
      event_version: 1,
      entry_point: this.getListEntryPoint(listLayout),
      query: query,
      in_app: false
    });
  };

  public onViewList = (listLayout: OrderPageRoute, entryPoint: Analytics.OrderListEntry): void => {
    Analytics.track(Analytics.FxEventName.OrderViewList, {
      event_version: 1,
      entry_point: entryPoint,
      list: this.getListEntryPoint(listLayout),
      in_app: false
    });
  };

  public onViewDetail = (listLayout: OrderPageRoute, order: Order): void => {
    Analytics.track(Analytics.FxEventName.OrderViewDetail, {
      event_version: 1,
      list: this.getListEntryPoint(listLayout),
      in_app: false,
      ...this.buildOrderGlobalProperties(order)
    });
  };

  private buildOrderGlobalProperties = (order: Order): OrderGlobalProperties => {
    return {
      order_no: order.orderNo,
      order_id: order.id,
      channel: order.channel,
      delivery_date: order.deliverAt,
      ...order.orderDeliveryConfig && { same_day: order.orderDeliveryConfig!.sameDay }
    };
  };

  private getListEntryPoint = (listLayout: OrderPageRoute | 'orderOverview'): Analytics.OrderSearchEntry => {
    switch (listLayout) {
      case 'past':
        return Analytics.OrderSearchEntry.Past;

      case 'search':
        return Analytics.OrderSearchEntry.Search;

      case 'today':
        return Analytics.OrderSearchEntry.Today;

      case 'tomorrow':
        return Analytics.OrderSearchEntry.Tomorrow;

      case 'upcoming':
        return Analytics.OrderSearchEntry.Upcoming;

      case 'peak':
        return Analytics.OrderSearchEntry.Peak;

      default:
        return Analytics.OrderSearchEntry.OrdersOverview;
    }
  };
}

export default new OrderAnalytics();

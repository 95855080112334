import styled, { StyledComponent } from '@emotion/styled';
import { Flex, Box } from 'rebass';

export const Container = styled(Flex)`
  position: relative;
  flex-direction: column;
  min-height: 420px;
  padding: 20px;
`;

export const IconClose: StyledComponent<any, any, any> = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
`;

export const ButtonContainer: StyledComponent<any, any, any> = styled(Box)`
  width: 100%;
  margin-top: 100px;
`;

import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, shadows, borderRadius, space } from 'utils/rebass-theme';

export const Card = styled(Box)<{ customStyles: SerializedStyles }>`
  border-radius: ${borderRadius.large};
  background-color: ${colors.white};
  box-shadow: ${shadows.small};
  position: relative;
  padding: ${space[3]}px;

  ${({ customStyles }) => customStyles};
`;

import { Component, Fragment, ReactNode } from 'react';

import { ListEdge, Maybe } from 'generated-types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ListType } from 'generated-types.d';

import { NavService } from 'lib';

import { Container } from 'utils/css-mixins';
import { colors } from 'utils/rebass-theme';

import { ListsListItem } from 'features/lists/components/lists-list/lists-list-item/lists-list-item';
import { getListMode } from 'features/lists/lists.helpers';
import { ListMode } from 'features/lists/lists.types';

import Button from 'components/button';
import { ButtonDropdown } from 'components/button-dropdown/button-dropdown';
import { ButtonDropdownOption, ButtonDropdownPosX } from 'components/button-dropdown/button-dropdown.types';
import WithLoading from 'components/with-loading';

import { PageHeader } from '../page-header';

import {
  ListsWrapper,
  NoListsWrapper,
  NoListsHeaderText,
  NoListsBodyText,
  NoListsCreatedIcon
} from './lists-list.styles';
import {
  ListsListProps,
  ListsListState
} from './lists-list.types';

class ListsList extends Component<ListsListProps, ListsListState> {
  state = {
    isCreatingList: false
  }

  private listMode = ListMode.FULL;

  public componentDidMount(): void {
    this.props.listsStore!.fetchLists();

    this.listMode = getListMode(
      this.props.conversationsStore?.shareList?.inShareFlow,
      this.props.merchantStore?.merchant
    );
  }

  public componentWillUnmount(): void {
    this.props.listsStore!.resetLists();
    this.props.conversationsStore!.endShareListFlow();
  }

  private createList = async (type: ListType): Promise<void> => {
    try {
      this.setState({ isCreatingList: true });
      const result = await this.props.listsStore!.createList(type);
      this.setState({ isCreatingList: false });

      NavService.singleList(result.id);
    } catch (error) {
      this.setState({ isCreatingList: false });
    }
  };

  private shouldShowLists = (): boolean => {
    return this.props.merchantStore?.merchant?.listsActive || !this.props.merchantStore?.merchant?.id;
  }

  private renderButtonCopy = (): string => {
    if (!this.props.merchantStore?.merchant?.id) {
      return 'All lists';
    }

    if (!this.props.merchantStore?.merchant?.listsActive) {
      return 'Lists disabled';
    }

    return '+ New list';
  }

  private hasNoResults = (): boolean => {
    return !this.props.listsStore!.isLoadingLists && !this.props.listsStore!.lists?.edges?.length;
  };

  private isLoading = (): boolean => {
    return this.props.listsStore!.isLoadingLists && !this.props.listsStore!.lists?.edges?.length;
  };

  private shareListToConversation = async (listId: string): Promise<void> => {
    this.props.conversationsStore!.setShareListListId(listId);
    NavService.conversationsContact(this.props.conversationsStore!.shareList.contactId || '');
  }

  private getListItemOnClickHandler = (): ((id: string) => void) | undefined => {
    switch (this.listMode) {
      case ListMode.SHARE_TO_CONVERSATION:
      case ListMode.SHARE_TO_CONVERSATION_SHARE_ONLY:
        return this.shareListToConversation;

      default:
        return;
    }
  };

  private deleteListHandler = (listId: string): void => {
    this.props.listsStore?.deleteList({ listId });
  };

  private renderList = (): ReactNode => {
    const edges = this.props.listsStore!.lists?.edges;

    if (!this.shouldShowLists() || !edges) {
      return null;
    }

    return edges.map((edge: Maybe<ListEdge>) => {
      if (!edge) {
        return null;
      }

      return (
        <ListsListItem
          onClick={this.getListItemOnClickHandler()}
          onDeleteListClick={this.deleteListHandler}
          key={edge.node.id}
          itemCount={edge.node.items!.length}
          data={edge.node}
        />
      );
    });
  };

  private renderNoResults = (): ReactNode => {
    return (
      <Box mt="80px">
        <NoListsWrapper>
          <NoListsCreatedIcon />
          <NoListsHeaderText>
            {`Let's create your first list`}
          </NoListsHeaderText>
          <NoListsBodyText>{this.renderCreateListButton('center')}</NoListsBodyText>
        </NoListsWrapper>
      </Box>
    );
  }

  private shouldShowCreateButton = (): boolean => {
    const allowedModes = [
      ListMode.FULL,
      ListMode.CHECKOUT_ONLY,
      ListMode.SHARE_ONLY
    ];

    return allowedModes.includes(this.listMode) && !!this.props.merchantStore?.merchant?.id;
  }

  private shouldShowAdvancedList = (): boolean => {
    const allowedModes = [
      ListMode.FULL,
      ListMode.CHECKOUT_ONLY
    ];

    return allowedModes.includes(this.listMode) && !!this.props.merchantStore?.merchant?.id;
  }

  private renderButtonView = (shouldDisableButton: boolean): ReactNode => {
    return (
      <Button
        copy="New list"
        isDisabled={shouldDisableButton}
        size="xsmall"
      />
    );
  }

  private renderCreateListButton = (dropdownPositionX: ButtonDropdownPosX = 'right'): ReactNode => {
    if (!this.shouldShowCreateButton()) {
      return null;
    }

    const shouldDisableButton = !this.shouldShowLists() || this.state.isCreatingList;

    if (!this.shouldShowAdvancedList()) {
      return (
        <Box
          as="button"
          css={{ minWidth: 'unset' }}
          onClick={() => this.createList(ListType.Simple)}
          disabled={shouldDisableButton}
        >
          {this.renderButtonView(shouldDisableButton)}
        </Box>
      );
    }

    const listCreateOptions: ButtonDropdownOption[] = [
      {
        title: 'Simple list',
        description: 'Specify item name and quantity only',
        onClick: () => this.createList(ListType.Simple),
        iconName: 'list',
        iconBgColour: colors.paleGrey
      },
      {
        title: 'Advanced List',
        description: 'Specify colour, genus, maturity and more',
        onClick: () => this.createList(ListType.Advanced),
        iconName: 'lightning-bolt',
        iconBgColour: colors.paleGrey
      }
    ];

    return (
      <ButtonDropdown
        options={listCreateOptions}
        buttonCopy={this.renderButtonCopy()}
        isHidden={!this.shouldShowCreateButton()}
        isDisabled={shouldDisableButton}
        positionX={dropdownPositionX}
        renderButton={() => this.renderButtonView(shouldDisableButton)}
      />
    );
  }

  render(): JSX.Element {
    return (
      <Fragment>
        <PageHeader
          renderCenter={() => 'Lists'}
          renderRight={() => {
            if (this.isLoading()) return null;

            return this.renderCreateListButton();
          }}
        />
        <Container
          maxWidth="980px"
        >
          <ListsWrapper>
            <WithLoading
              hasNoResults={this.hasNoResults()}
              isLoading={this.isLoading()}
              renderNoResults={this.renderNoResults}
            >
              {this.renderList()}
            </WithLoading>
          </ListsWrapper>
        </Container>
      </Fragment>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  conversationsStore: stores.conversationsStore,
  listsStore: stores.listsStore,
  merchantStore: stores.merchantStore
}))(observer(ListsList));

/* eslint-disable camelcase */

import {
  MediaCategory,
  ProductVariationType,
  Channel
} from 'generated-types.d';

export enum ProductTab {
  product,
  recipe,
  addon,
  delivery
}

export type Dimension = 'length' | 'width' | 'height';

export enum Mode {
  create = 'create',
  edit = 'edit'
}

export interface Tab {
  focusText: string;
  nextButtonCopy: string;
  subtitleText?: string;
  createMode?: boolean;
  disabled?: boolean;
  onClick: () => any;
  tabIsValid: () => boolean;
  tabType: ProductTab;
}

export interface BreadcrumbTab {
  product: Tab;
  recipe: Tab;
  addon: Tab;
  delivery: Tab;
}

export interface MediaItem {
  key: number;
  id?: string;
  src: string;
  name: string;
  updateMediaItem: (mediaId: string, src: string) => any;
  category: MediaCategory;
}

export interface Dimensions {
  id: string;
  width: number;
  height: number;
  length: number;
}

export interface ProductVariation {
  title: string;
  type: ProductVariationType;
  id?: string;
  sku: string | undefined;
  price?: number;
  stock?: number;
  new?: boolean;
  selectedColours: string[];
  selectedComponentConfigs: SelectedComponentConfig[];
  mediaUploads: MediaItem[];
  dimensions: any;
  onClick: (variationId: string | undefined) => any;
  updateStock: (stock: number) => any;
  updatePrice: (price: number) => any;
  setColours: (colourIds: string[]) => any;
  selectComponent: (componentId: string, quantity: number) => any;
  updateComponent: (componentId: string, quantity: number) => any;
  deselectComponent: (componentId: string) => any;
  updateDimension: (dimensionKey: Dimension, value: number) => any;
}

export interface SelectedComponentConfig {
  id: string;
  fx_id?: string;
  quantity: number;
}

export interface ColourListObject {
  name: string;
  id: string;
  hex: string;
}

export interface ProductChannel {
  id: string;
  title: string;
  slug: Channel;
  isDisabled?: boolean;
}

export interface Category {
  id: string;
  title: string;
}

import { Component, Fragment, ReactNode } from 'react';

import { Address } from 'generated-types';
import { Box } from 'rebass';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';
import { textStyles } from 'utils/rebass-theme';

import FunctionalAnchor from 'components/functional-anchor';
import Icon from 'components/icon';

import * as Types from './address.types';

export default class OrderAddress extends Component<Types.AddressProps> {
  FIELDS: Array<keyof Address> = [
    'recipientFullName',
    'businessName',
    'address1',
    'address2',
    'city',
    'region',
    'postalCode'
  ];

  private onEdit = (): void => {
    if (this.props.onEdit) {
      this.props.onEdit();
    }
  };

  private businessAddress(): ReactNode {
    return this.props.isBusiness ? <Box>Business<br /><br /></Box> : null;
  }

  private renderFields = (): ReactNode => {
    return this.FIELDS.map(field => {
      if (!this.props.address[field]) return null;

      return (
        <Box key={field}>
          {this.props.address[field]}
        </Box>
      );
    });
  };

  render(): ReactNode {
    return(
      <Fragment>
        <Box css={textStyles.body}>
          {this.businessAddress()}
          {this.renderFields()}
          {!this.props.hidePhone && (
            <Box mt="5px">
              <FunctionalAnchor
                type="tel"
                copy={this.props.address.phone!}
                anchorHref={this.props.address.phone!}
              />
            </Box>
          )}
        </Box>
        <Box mt="10px">
          {this.props.canEdit && (
            <FloomXRestrictedComponent
              restrictedRoles={this.props.restrictedRoles}
              shouldOverrideRoles={this.props.shouldOverrideRoles}
            >
              <button onClick={(): void => this.onEdit()}>
                <Icon iconName="edit" />
              </button>
            </FloomXRestrictedComponent>
          )}
        </Box>
      </Fragment>
    );
  }
}

import { action, observable, makeObservable } from 'mobx';

import * as Types from './merchant-holidays-store.types';

export default class MerchantHolidayStore {
  constructor() {
    makeObservable(this, {
      merchantHolidays: observable,
      isLoadingHolidays: observable,
      setmerchantHolidays: action,
      clearmerchantHolidays: action
    });
  }

  public merchantHolidays: Types.MerchantHolidayList | null = null;

  public isLoadingHolidays: boolean = true;

  public clearmerchantHolidays = (): void => {
    this.merchantHolidays = null;
    this.isLoadingHolidays = true;
  };

  public setmerchantHolidays = (holidayList: Types.MerchantHolidayList): void => {
    this.merchantHolidays = holidayList;
    this.isLoadingHolidays = false;
  };
}

import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { breakpoints, colors, fontSizes, space } from 'utils/rebass-theme';

export const CheckoutItemWithLabel = styled(Flex)`
  display: none;

  @media only screen and (max-width: ${breakpoints.medium}) {
    display: flex;
    font-size: ${fontSizes.xSmall}px;
    color: ${colors.darkGrey};
    margin-bottom: ${space[1]}px;
    align-items: center;
  }
`;

export const CheckoutItemLabel = styled(Box)`
  font-weight: bold;
`;

import hex2rgba from 'hex2rgba';
import {
  action,
  computed,
  makeAutoObservable,
  runInAction
} from 'mobx';
import moment from 'moment-timezone';

import {
  DeliveryDateForSupplier,
  MerchantWholesaleSupplier,
  Maybe,
  Supplier
} from 'generated-types.d';

import { colors } from 'utils/rebass-theme';

import WholesaleOrdersAPIService from 'features/wholesale/services/wholesale-api/orders/wholesale-orders-api.service';
import WholesaleShopApiService from 'features/wholesale/services/wholesale-api/shop/wholesale-shop-api.service';

import { CountdownProps } from 'components/countdown-clock/countdown-clock.types';

import { SupplierDeliveryDate } from './wholesale-shop-store.types';

export default class WholesaleShopStore {
  constructor() {
    makeAutoObservable(this, {
      canDeliver: computed,
      setSelectedSupplier: action
    });
  }

  /**
   * Creates interval which modifies the delivery countdown.
   * Can only be set once on store initialisation. returns instance
   * of existing interval if instantiated again.
   */
  private initialiseDeliveryTimer = (): NodeJS.Timeout => {
    if (!!this.deliveryTimerInterval) return this.deliveryTimerInterval;

    return setInterval(() => {
      runInAction(() => {
        if (this.nextAvailableDeliveryDates.length && !this.isLoading) {
          const currentDate = this.getCurrentDate();

          const countdownProps: CountdownProps[] = [];

          this.nextAvailableDeliveryDates.forEach(deliveryDate => {
            const diff = moment(deliveryDate.cutOffAt).tz(this.cutoffTimeZone).diff(currentDate);
            const duration = moment.duration(diff, 'milliseconds');

            if (duration.milliseconds() < 0) {
              this.rehydrateWholesaleShop();
            } else {
              countdownProps.push(this.getCountdownProps(duration, deliveryDate.supplierId));
            }
          });

          this.deliveryCountdownProps = countdownProps;
        }
      });
    }, 1000);
  };

  private deliveryTimerInterval: NodeJS.Timeout = this.initialiseDeliveryTimer();

  private merchantId: string = '';

  public cutoffTimeZone: string = 'Europe/London';

  public isDisplayingOrder: boolean = false;

  public isLoading: boolean = true;

  public deliveryCountdownProps: CountdownProps[] = [];

  public merchantWholesaleSuppliers: MerchantWholesaleSupplier[] = [];

  public nextAvailableDeliveryDates: SupplierDeliveryDate[] = [];

  public selectedSupplier: Supplier | null = null;

  public get canDeliver(): boolean {
    return this.nextAvailableDeliveryDates.some(date => {
      return !!date.canDeliver
        && !!date.cutOffAt
        && !!date.dateString;
    });
  }

  public canDeliverSupplier = (supplierId: string): boolean => {
    const deliveryConfig = this.nextAvailableDeliveryDates?.find(date => date.supplierId === supplierId);

    return !!deliveryConfig?.canDeliver
      && !!deliveryConfig?.cutOffAt
      && !!deliveryConfig?.dateString;
  }

  public getDeliveryConfigForSupplier = (supplierId: string): SupplierDeliveryDate => {
    const deliveryDate = this.nextAvailableDeliveryDates.find(deliveryConfig => deliveryConfig.supplierId === supplierId);

    if (!supplierId || !deliveryDate) {
      throw new Error(`Delivery config for supplier ${supplierId} is absent`);
    }

    return deliveryDate;
  }

  public setIsLoading = (value: boolean): void => {
    this.isLoading = value;
  }

  private getCurrentDate = (): moment.Moment => moment().tz(this.cutoffTimeZone);

  public toggleOrderSidebar = (): void => {
    this.isDisplayingOrder = !this.isDisplayingOrder;
  };

  public showOrderSidebar = (): void => {
    this.isDisplayingOrder = true;
  }

  public hideOrderSidebar = (): void => {
    this.isDisplayingOrder = false;
  }

  public clearSuppliers = (): void => {
    this.merchantWholesaleSuppliers = [];
  };

  public setNextAvailableDeliveryDate = (): void => {
    if (!this.merchantWholesaleSuppliers?.length) {
      return;
    }

    this.nextAvailableDeliveryDates = this.merchantWholesaleSuppliers.map(supplier => {
      const deliveryDate: Maybe<DeliveryDateForSupplier> | undefined = supplier.deliveryDates?.find(date => !!date?.canDeliver);

      if (!!deliveryDate) {
        return {
          ...deliveryDate,
          supplierId: supplier?.supplier?.id
        };
      }

      return null;
    }).filter(Boolean) as SupplierDeliveryDate[];
  };

  public setSelectedSupplier = (supplier: Supplier | null): void => {
    runInAction(() => {
      this.selectedSupplier = supplier;
    });
  }

  private getCountdownProps = (duration: moment.Duration, supplierId: string): CountdownProps => {
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const pluralise = (timeframe: number): string => (timeframe > 1 || timeframe === 0) ? 's' : '';

    switch (true) {
      case days > 0:
        return {
          supplierId: supplierId,
          color: colors.grey,
          colorLight: colors.validationBg,
          copy: `${days}day${pluralise(days)} ${hours}hr${pluralise(hours)}`,
          compactCopy: `${days}day${pluralise(days)}`
        };

      case hours >= 3:
        return {
          supplierId: supplierId,
          color: colors.grey,
          colorLight: colors.validationBg,
          copy: `${hours}hr${pluralise(hours)} ${minutes}min${pluralise(minutes)}`,
          compactCopy: `${hours}hr${pluralise(hours)}`
        };

      case minutes >= 30 && hours === 0:
        return {
          supplierId: supplierId,
          color: colors.floomOrange,
          colorLight: hex2rgba(colors.floomOrange, .5),
          copy: `${minutes}mins`,
          compactCopy: `${minutes}mins`
        };

      case hours > 0 && hours < 3:
        return {
          supplierId: supplierId,
          color: colors.floomOrange,
          colorLight: hex2rgba(colors.floomOrange, .5),
          copy: `${hours}hr${pluralise(hours)} ${minutes}min${pluralise(minutes)}`,
          compactCopy: `${hours}hr${pluralise(hours)}`
        };

      default:
        return {
          supplierId: supplierId,
          color: colors.floomRed,
          colorLight: hex2rgba(colors.floomRed, .4),
          copy: `${minutes}min ${seconds}sec`,
          compactCopy: `${seconds}sec`
        };
    }
  };

  public rehydrateWholesaleShop = async (): Promise<void> => {
    await this.fetchSupplierDataForMerchant({
      merchantId: this.merchantId,
      shouldRehydrate: true
    });

    new WholesaleOrdersAPIService().fetchCurrentOpenOrders();
  };

  public fetchSupplierDataForMerchant = async ({
    merchantId = this.merchantId,
    shouldRehydrate = false
  }: {
    merchantId: string;
    shouldRehydrate: boolean;
  }): Promise<MerchantWholesaleSupplier[]> => {
    if (!!this.merchantWholesaleSuppliers.length && !shouldRehydrate) {
      return this.merchantWholesaleSuppliers;
    }

    runInAction(() => {
      if (!shouldRehydrate) {
        this.isLoading = true;
      }
    });

    try {
      const result = await WholesaleShopApiService.fetchSupplierDataForMerchant(merchantId);

      runInAction(() => {
        this.merchantId = merchantId;
        this.merchantWholesaleSuppliers = result;
        this.selectedSupplier = result.length === 1 ? result?.[0]?.supplier ||  null : null;

        this.setNextAvailableDeliveryDate();
        this.isLoading = false;
      });

      return result;
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });

      return [];
    }
  };

  public getCancellationWindow = (supplierId: string | undefined): number => {
    const defaultCancellationWindow = 13;

    if (!supplierId) return defaultCancellationWindow;

    const wholesaleShopSupplier = this.merchantWholesaleSuppliers?.find(item => item?.supplier?.id === supplierId);

    return wholesaleShopSupplier?.supplier?.itemCancellationWindowInMinutes || defaultCancellationWindow;
  }
}

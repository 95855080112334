import React, { Component } from 'react';

import { HTMLService, TimeService } from 'lib';

import * as Styles from './terms-content.styles';
import * as Types from './terms-content.types';

class TermsContent extends Component<Types.TermsContentProps> {
  static defaultProps = {
    isCenteredHeading: false
  };

  render(): React.ReactNode {
    return (
      <Styles.TermsWrapper>
        <Styles.HeadingWrapper isCentered={this.props.isCenteredHeading}>
          <Styles.Heading
            css={this.props.headingStyles}
          >
            {this.props.data.title}
          </Styles.Heading>
          <Styles.LastUpdated>
            Updated: {TimeService.humanDateMonthYear(this.props.data!.updatedAt)}
          </Styles.LastUpdated>
        </Styles.HeadingWrapper>
        <Styles.TermsCopy
          dangerouslySetInnerHTML={{
            __html: HTMLService.sanitize(this.props.data.copy)
          }}
        />
      </Styles.TermsWrapper>
    );
  }
}

export default TermsContent;

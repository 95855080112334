import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';

import { colors, textStyles, breakpoints } from 'utils/rebass-theme';

export const StatusControls: any = styled(Box)`
  display: block;

  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
`;

export const Subheading: any = styled(Box)`
  ${textStyles.subhead};
  padding-bottom: 3px;
  margin: 28px 0 10px 0;
  border-bottom: solid 1px ${colors.middleGrey};

  @media (min-width: ${breakpoints.small}) {
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

export const Block: any = styled(Box)`
  width: 100%;
  padding: 0 10px;
  margin-bottom: 10px;

  @media (min-width: ${breakpoints.small}) {
    width: 33%;
    margin-bottom: 0;
    padding: 0;
  }
`;

export const ContentLoader: any = styled(Flex)`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

export const DeliveryContainer = styled(Flex)`
  margin-top: 20px;
  align-items: center;
  margin: 30px 0 15px 0;
`;

export const DeliveryIcon = styled(Box)`
  width: 24px;
  height: 24px;
  align-self: center;
  margin-right: 10px;

  svg {
    width: 100%;
    transform: translateY(2px);
  }
`;

export const DeliveryHeader = styled(Text)`
  ${textStyles.subhead};
  position: relative;
  cursor: pointer;
  color: ${({ color }: any): any => color};
`;

export const IconBox = styled(Box)`
  padding-top: 28px;
  padding-right: 15px;
  flex: 0 0 auto;
`;

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Text } from 'rebass';

import { Cookies, MerchantService, PermissionsService } from 'lib';

import { fontSizes } from 'utils/rebass-theme';

import laptopIcon from 'assets/images/laptop.svg';

import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import * as Styles from './trial-banner.styles';
import * as Types from './trial-banner.types';

class TrialBanner extends Component<Types.TrialBannerProps, Types.TrialBannerState> {
  state: Types.TrialBannerState = {
    isHidden: false
  };

  private pluraliseWordDay = (daysLeft: number): string => {
    const isPlural = (daysLeft > 1);

    return `day${isPlural ? 's' : ''}`;
  };

  private onClose = (): void => {
    Cookies.set('shouldHideTrialBanner', true, { maxAge: 7200 });

    this.setState({ isHidden: true });
  };

  private shouldDisplay = (daysLeft: number): boolean => {
    const trialBannerCookie = Cookies.get('shouldHideTrialBanner');

    return this.props.merchantStore!.merchant?.subscription?.status === 'TRIALING'
      && daysLeft > 0
      && !PermissionsService.isInternalRole()
      && !this.state.isHidden
      && !trialBannerCookie;
  };

  private renderCopy = (trialDaysRemaining: number): ReactNode => {
    const pluralisedWordDay = this.pluraliseWordDay(trialDaysRemaining);

    const trialCopyHighlighted = (
      <Text
        color="floomOrange"
        as="span"
      >
        {trialDaysRemaining}
      </Text>
    );

    const supportLink = (
      <Styles.SupportLink href="mailto:support@floom.com">
        Contact support
      </Styles.SupportLink>
    );

    return (
      <>
        <Text
          fontWeight="bold"
          fontSize={fontSizes.small}
          mb="5px"
        >
          You have {trialCopyHighlighted} {pluralisedWordDay} left in your free trial.
        </Text>
        <Text fontSize={fontSizes.small}>
          After {trialDaysRemaining} {pluralisedWordDay} your plan will become paid and you will get charged. Any problems? {supportLink}
        </Text>
      </>
    );
  };

  render(): React.ReactNode {
    const daysLeft = MerchantService.getTrialRemainingInDays(this.props.merchantStore!.merchant!);

    if (!this.shouldDisplay(daysLeft)) return null;

    return (
      <Styles.BannerBar>
        <Notification
          type={NotificationType.Banner}
          hasIcon={true}
          hasClose={true}
          onClose={this.onClose}
          customIcon={laptopIcon}
          textAlign="left"
          copy={this.renderCopy(daysLeft)}
        />
      </Styles.BannerBar>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore
}))(observer(TrialBanner));

import React, { Component, Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ProductGroupOption } from 'stores/products-store';

import { Container } from 'utils/css-mixins';

import { BaseProductListLayoutProps } from 'features/products/pages/product-list/product-list.types';
import ProductServices from 'features/products/services';

import LoadMoreButton from 'components/load-more-button';
import WithLoading from 'components/with-loading';

import ProductNoResults from '../no-results';

import TableLayoutGroup from './table-layout-group';

class TableLayout extends Component<BaseProductListLayoutProps> {
  private ProductListService = ProductServices.ProductListService;

  private getGroupKeys = (): any[] => {
    return Object.keys(this.props.productsStore!.products);
  };

  private onLoadMore = (groupName: ProductGroupOption): void => this.ProductListService.loadMoreProducts(groupName);

  private hasProducts = (): number => Object
    .keys(this.props.productsStore!.products)
    .reduce((acc, curr): number => this.props.productsStore!.products[curr].edges.length + acc, 0);

  render(): React.ReactNode {
    const groupKeys: ProductGroupOption[] = this.getGroupKeys();

    return (
      <>
        <Container>
          <WithLoading
            isLoading={this.props.productsStore!.isLoading
              && !groupKeys.length
              && !this.hasProducts()}
            hasNoResults={!this.hasProducts()}
            renderNoResults={(): ReactNode => (
              <ProductNoResults isLoading={this.props.productsStore!.isLoading} />
            )}
          >
            <Box
              css={css`
                overflow-x: auto;
                padding: 10px;
                margin: -10px;
              `}
            >
              <Box
                css={css`
                  min-width: 950px;
                `}
              >
                {
                  groupKeys.map((productGroup: ProductGroupOption) => (
                    <Fragment key={productGroup}>
                      <TableLayoutGroup
                        group={this.props.productsStore!.products[productGroup]}
                        groupName={productGroup}
                        showHeading={groupKeys.length > 1}
                        isMakingChanges={this.props.isMakingChanges}
                        onActiveStateChange={this.props.onActiveStateChange}
                      />
                      <LoadMoreButton
                        shouldDisplay={this.props.productsStore!.products[productGroup].pageInfo.hasNextPage}
                        groupName={productGroup}
                        onLoadMore={this.onLoadMore}
                        isLoading={this.props.productsStore!.isLoading}
                      />
                    </Fragment>
                  ))
                }
              </Box>
            </Box>
          </WithLoading>
        </Container>
      </>
    );
  }
}

export default inject('productsStore')(observer(TableLayout));

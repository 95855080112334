import React from 'react';

import * as Styles from './section.styles';
import { SectionContentProps } from './section.types';

class SectionContent extends React.Component<SectionContentProps> {
  render(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, textAlign, ...props } = this.props;

    return (
      <Styles.SectionContent
        css={{ textAlign }}
        {...props}
      >
        {this.props.children}
      </Styles.SectionContent>
    );
  }
}

export default SectionContent;

import { Fragment } from 'react';

import { css } from '@emotion/react';

import { CatalogItemType, ListItemType } from 'generated-types.d';

import { breakpoints } from 'utils/rebass-theme';

import { ListPriceRange } from 'features/lists/components/list-price-range/list-price-range';
import {
  tradeSkuPricesBySkus,
  getListCurrency,
  getMinTradeSkuPrice,
  getMaxTradeSkuPrice
} from 'features/lists/lists.helpers';
import {
  BoldItem,
  DisabledItem,
  ListCellSubtitle
} from 'features/lists/lists.styles';
import { ListLayoutConfig } from 'features/lists/lists.types';

import { ListItemColour } from '../list-item-colour/list-item-colour';

import { ListItemQuantity } from './components/list-item-quantity-field/list-item-quantity-field';
import { ListItemSimpleTitle, ListItemAdvancedTitle } from './components/single-list-title/single-list-title';
import {
  ListItemQuantityWrapper,
  ReadOnlyQuantity
} from './single-list.styles';

export const SINGLE_LIST_FIELD_CONFIG: ListLayoutConfig = {
  Simple: {
    titleField: {
      key: 'itemName',
      heading: 'Item',
      renderValue: item => (<ListItemSimpleTitle listItem={item} />)
    },
    fields: [
      {
        key: 'quantity',
        heading: 'QTY',
        renderValue: item => {
          return (
            <Fragment>
              <ListItemQuantityWrapper>
                <ListItemQuantity
                  listItem={item}
                />
              </ListItemQuantityWrapper>
              <ReadOnlyQuantity>
                <ListCellSubtitle>
                    Qty: {item.quantity || '0'}
                </ListCellSubtitle>
              </ReadOnlyQuantity>
            </Fragment>
          );
        }
      }
    ]
  },
  Advanced: {
    titleField: {
      key: 'itemName',
      heading: 'Item/Genus',
      renderValue: item => (<ListItemAdvancedTitle listItem={item} />)
    },
    fields: [
      {
        key: 'colour',
        heading: 'Colour',
        isMeta: true,
        renderValue: item => {
          if (!item?.colour) {
            return '--';
          }

          return (
            <ListItemColour
              type="hit"
              status="disabled"
              colour={item.colour!}
            />
          );
        }
      },
      {
        key: 'stemLength',
        heading: 'Stem length',
        supportedTypes: [
          CatalogItemType.Flower,
          ListItemType.Flower
        ],
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypeFlower':
                const hasStemLength = !!item?.minimumStemLength && item?.stemLengthUnit;

                return hasStemLength ? `${item.minimumStemLength}${item.stemLengthUnit}` : null;

              default:
                return 'Any';
            }
          })();

          return value;
        }
      },
      {
        key: 'potSize',
        heading: 'Pot size',
        supportedTypes: [
          CatalogItemType.Plant,
          ListItemType.Plant
        ],
        isMeta: true,
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypePlant':
                return item?.potSize || null;

              default:
                return 'Any';
            }
          })();

          return value;
        }
      },
      {
        key: 'weight',
        heading: 'Weight',
        supportedTypes: [
          CatalogItemType.Sundry,
          ListItemType.Sundry
        ],
        isMeta: true,
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypeSundry':
                return item?.weight || null;

              default:
                return 'Any';
            }
          })();

          return value;
        }
      },
      {
        key: 'height',
        heading: 'Height',
        supportedTypes: [
          CatalogItemType.Plant,
          ListItemType.Plant,
          CatalogItemType.Sundry,
          ListItemType.Sundry
        ],
        isMeta: true,
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypePlant':
              case 'ListItemTypeSundry':
                return item?.height || null;

              default:
                return 'Any';
            }
          })();

          return value;
        }
      },
      {
        key: 'maturity',
        heading: 'Maturity',
        supportedTypes: [
          CatalogItemType.Flower,
          ListItemType.Flower
        ],
        isMeta: true,
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.__typename) {
              case 'ListItemTypeFlower':
                return item?.maturity || null;

              default:
                return 'Any';
            }
          })();

          return value;
        }
      },
      {
        key: 'estimatedPrice',
        heading: 'Est. unit price',
        isMeta: true,
        renderValue: item => {
          const allPrices = tradeSkuPricesBySkus(item.catalogItem?.tradeSku || []);

          if (!allPrices?.length) {
            return null;
          }

          const currency = getListCurrency(item.list);
          const minSkuPrice = getMinTradeSkuPrice(allPrices, currency);
          const maxSkuPrice = getMaxTradeSkuPrice(allPrices, currency);

          return (
            <DisabledItem>
              <ListPriceRange
                min={minSkuPrice?.price}
                max={maxSkuPrice?.price}
                currency={currency}
              />
            </DisabledItem>
          );
        }
      },
      {
        key: 'quantity',
        heading: 'QTY',
        customStyles: css`
          @media only screen and (max-width: ${breakpoints.medium}) {
            order: 1;
          }
        `,
        renderValue: item => {
          return (
            <Fragment>
              <ListItemQuantityWrapper>
                <ListItemQuantity
                  listItem={item}
                />
              </ListItemQuantityWrapper>
              <ReadOnlyQuantity>
                <ListCellSubtitle>
                    Qty: {item.quantity || '0'}
                </ListCellSubtitle>
              </ReadOnlyQuantity>
            </Fragment>

          );
        }
      },
      {
        key: 'total',
        heading: 'Total',
        renderValue: item => {
          const allPrices = tradeSkuPricesBySkus(item.catalogItem?.tradeSku || []);

          if (!allPrices?.length) {
            return '--';
          }

          const currency = getListCurrency(item.list);
          const minSkuPrice = getMinTradeSkuPrice(allPrices, currency);
          const maxSkuPrice = getMaxTradeSkuPrice(allPrices, currency);

          return (
            <BoldItem>
              <ListPriceRange
                min={minSkuPrice?.price ? minSkuPrice.price * (item.quantity || 0) : undefined}
                max={maxSkuPrice?.price ? maxSkuPrice.price * (item.quantity || 0) : undefined}
                currency={currency}
              />
            </BoldItem>
          );
        }
      }
    ]
  }
};

import { action, observable, makeObservable } from 'mobx';

import {
  DiscountConnection,
  DiscountOrderByInput
} from 'generated-types.d';

import { NotificationType } from 'components/notification/notification.types';

export default class DiscountsStore {
  constructor() {
    makeObservable(this, {
      discounts: observable,
      searchValue: observable,
      sortValue: observable,
      isLoadingDiscounts: observable,
      listNotification: observable,
      setDiscounts: action,
      resetDiscountList: action,
      setNotification: action,
      setListLoading: action,
      setSearchValue: action,
      handlePaginationResult: action,
      toggleActiveDiscounts: action,
      setDiscountSortValue: action
    });
  }

  readonly listCount: number = 20;

  public discounts: DiscountConnection | null = null;

  public isLoadingDiscounts: boolean = true;

  public isShowingInactiveDiscounts: boolean = true;

  public skipCount: number = 0;

  public searchValue: string = '';

  public sortValue: DiscountOrderByInput = DiscountOrderByInput.CodeAsc;

  public listNotification: { copy: string; type: NotificationType } | null = null;

  public onPaginate = (): void => {
    this.skipCount = this.skipCount + this.listCount;
  };

  public resetDiscountList = (): void => {
    this.discounts = null;
    this.skipCount = 0;
    this.searchValue = '';
    this.sortValue = DiscountOrderByInput.CodeAsc;
    this.listNotification = null;
    this.setListLoading(true);
  };

  public setNotification = (copy?: string, type?: NotificationType): void => {
    if (!copy || !type) {
      this.listNotification = null;

      return;
    }

    this.listNotification = { copy, type };
  };

  public setDiscounts = (discountList: DiscountConnection): void => {
    this.discounts = discountList;
    this.setListLoading(false);
  };

  public handlePaginationResult = (discountList: DiscountConnection): void => {
    if (!!this.discounts) {
      this.discounts!.aggregate = discountList.aggregate;
      this.discounts!.pageInfo = discountList.pageInfo;

      this.discounts!.edges = [
        ...this.discounts.edges,
        ...discountList.edges
      ];
    }
  };

  public setListLoading = (isLoading = true): void => {
    this.isLoadingDiscounts = isLoading;
  };

  public setSearchValue = (value: string): void => {
    this.searchValue = value;
  };

  public toggleActiveDiscounts = (isShowing = !this.isShowingInactiveDiscounts): void => {
    this.isShowingInactiveDiscounts = isShowing;
  };

  public setDiscountSortValue = (value: DiscountOrderByInput = DiscountOrderByInput.CodeAsc): void => {
    this.sortValue = value;
  };
}

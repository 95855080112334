import React, { Component, ReactNode } from 'react';

import CheckboxItem from '../checkbox-item';
import ErrorMessage from '../error-message';

import * as Styles from './checkbox-list.styles';
import * as Types from './checkbox-list.types';

export default class CheckboxList extends Component<Types.CheckboxListProps> {
  static defaultProps = {
    orientation: 'vertical'
  };

  state = {
    isDirty: false
  };

  getErrorMessage = (): string | undefined => {
    return this.props.error && this.state.isDirty ? this.props.errorMessage : '';
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>, item: any): any => {
    if (item.isDisabled) {
      return event.preventDefault();
    }

    this.setState({
      isDirty: true
    });

    return this.props.onChange(item[this.props.itemValueField]);
  };

  render(): ReactNode {
    return (
      <>
        <Styles.ListWrapper
          wrapperStyles={this.props.wrapperStyles}
        >
          {
            this.props.items.map((item, i) => (
              <CheckboxItem
                item={item}
                onChange={this.onChange}
                checkboxList={this.props}
                metadataField={this.props.metadataField}
                key={`${item.slug}-${i}`}
                isDirty={this.state.isDirty}
                checkboxStyles={this.props.checkboxStyles}
              />
            ))
          }
        </Styles.ListWrapper>
        <ErrorMessage
          errorMessage={this.getErrorMessage()}
        />
      </>
    );
  }
}

import styled from '@emotion/styled';
import { Box, Text } from 'rebass';

export const FeatureList = styled(Box)``;

export const FeatureListHeading = styled(Text)<any>`
  margin-bottom: 30px;
`;

export const ItemIcon = styled(Box)<any>`
  min-width: 24px;
  margin-top: 4px;
`;

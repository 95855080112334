import React, { Component } from 'react';

import CheckboxList from 'components/checkbox-list';
import GenericModal from 'components/generic-modal';

import * as Types from './delete-variation-modal.types';
import * as Styles from './modal.styles';

export class DeleteVariationModal extends Component<Types.DeleteVariationModalProps, Types.DeleteVariationModalState> {
  static defaultProps: Partial<Types.DeleteVariationModalProps> = {
    isLoading: false
  };

  state: Types.DeleteVariationModalState = {
    selectedVariations: []
  };

  private updateSelectedVariation = (variationType: string): void => {
    const index = this.state.selectedVariations.indexOf(variationType);

    if (index > -1) {
      this.state.selectedVariations.splice(index);

      this.setState({
        selectedVariations: [...this.state.selectedVariations]
      });
    } else {
      this.setState({
        selectedVariations: this.state.selectedVariations.concat([variationType])
      });
    }
  };

  private confirmDelete = async (): Promise<void> => {
    await this.props.onConfirm(this.state.selectedVariations);

    this.setState({
      selectedVariations: []
    });
  };

  render(): React.ReactNode {
    return (
      <GenericModal
        width={430}
        innerComponent={(
          <Styles.InnerContent>
            <p>Once you delete a variation it cannot be undone</p>
            <CheckboxList
              items={this.props.availableVariations}
              selectedItems={this.state.selectedVariations}
              itemValueField="id"
              optionTitleField="type"
              orientation="horizontal"
              onChange={this.updateSelectedVariation}
            />
          </Styles.InnerContent>
        )}
        confirmButtonAction={this.confirmDelete}
        confirmButtonDisabled={this.state.selectedVariations.length === 0}
        isButtonLoading={this.props.isLoading}
        confirmButtonText="Permanently delete"
        title="Variation"
        modalOpen={this.props.isOpen}
        closeModal={this.props.closeAction}
      />
    );
  }
}

import React, { Component, ReactNode } from 'react';

import { Address, OrderDeliveryState } from 'generated-types';
import { Flex, Box } from 'rebass';

import { CourierBookingsListLayout } from 'stores/courier-list/courier-list-store.types';
import { OrdersListLayout } from 'stores/orders/orders-store.types';

import { textStyles } from 'utils/rebass-theme';

import { OrderStatusSlug } from 'features/orders/orders.types';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';
import StyledLink from 'components/styled-link/styled-link';
import Tooltip from 'components/tooltip';

interface LocationMetaProps {
  data?: Address;
  status: OrderStatusSlug | OrderDeliveryState;
  layout: OrdersListLayout | CourierBookingsListLayout;
}

class LocationMeta extends Component<LocationMetaProps> {
  private addBusinessLabel = (): ReactNode | null => {
    if (!this.props.data?.businessName) {
      return;
    }

    switch (this.props.layout) {
      case 'table':
        return <Box>{' - '} <span>Business</span></Box>;

      default:
        return <span css={textStyles.body}>{' - '}{' '}Business</span>;
    }
  };

  render(): ReactNode {
    if (this.props.layout === 'table') {
      return (
        <>
          <Tooltip
            message={`${this.props.data?.address1}, ${this.props.data?.city}`}
          >
            <StyledLink>
              {this.props.data?.postalCode}
            </StyledLink>
            {this.addBusinessLabel()}
          </Tooltip>
          <Box>
            {this.props.data?.recipientFullName}
          </Box>
        </>
      );
    }

    return (
      <MetaCardContentWrapper iconName="map-pin">
        <Tooltip
          message={`${this.props.data?.address1}, ${this.props.data?.city}`}
        >
          <Flex css={textStyles.body}>
            {this.props.data?.postalCode}
            {this.addBusinessLabel()}
          </Flex>
        </Tooltip>
      </MetaCardContentWrapper>
    );
  }
}

export default LocationMeta;

import { FC, useEffect, useMemo } from 'react';

import hex2rgba from 'hex2rgba';
import { PermissionsService } from 'lib/services/permissions/permissions.service';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Container } from 'utils/css-mixins';
import { colors } from 'utils/rebass-theme';

import PlantsIconImage from 'assets/images/wholesale/no-results.gif';

import { ListsBlob } from 'features/lists/lists.styles';
import { WholesaleCheckoutHelpers } from 'features/wholesale/helpers/wholesale-helpers';
import { getPaymentStatusConfig } from 'features/wholesale/helpers/wholesale-helpers/wholesale-helpers';

import { LoadingImage } from 'components/loading-image/loading-image';
import WithLoading from 'components/with-loading';

import { CannotFindOrder } from '../single-order/components/cannot-find-order';
import { SingleOrderContext } from '../single-order/single-order.context';

import { ManageOrderBody } from './components/manage-order-body';
import { ManageOrderHeader } from './components/manage-order-header';
import * as Types from './manage-order.types';

export const WholesaleOrderManage: FC<Types.ManageOrderProps> = inject((stores: FxStores) => ({
  draftWholesaleOrdersStore: stores.draftWholesaleOrderStore
}))(observer(({
  orderId,
  draftWholesaleOrdersStore
}) => {
  const merchantCurrency = draftWholesaleOrdersStore!.selectedOrder?.merchant?.currency;
  const grandTotalAsFloat = useMemo(() => WholesaleCheckoutHelpers.calculateGrandTotal(draftWholesaleOrdersStore!.selectedOrder), [draftWholesaleOrdersStore!.selectedOrder]);
  const grandTotalCurrencyFormatted = useMemo(() => WholesaleCheckoutHelpers.renderWholesalePrice(grandTotalAsFloat, merchantCurrency), [grandTotalAsFloat]);
  const paymentStatusConfig = getPaymentStatusConfig(draftWholesaleOrdersStore!.selectedOrder);

  useEffect(() => {
    fetchOrder();
  }, []);

  const shouldDisplayOrder = (): boolean => {
    return !draftWholesaleOrdersStore!.isLoadingOrderDetail
      && !!draftWholesaleOrdersStore!.selectedOrder
      && Array.isArray(draftWholesaleOrdersStore!.selectedOrder?.items) && !!draftWholesaleOrdersStore!.selectedOrder.items.length
      && PermissionsService.isInternalRole();
  };

  const fetchOrder = async (): Promise<void> => {
    if (orderId) {
      draftWholesaleOrdersStore!.fetchOrder(orderId);
    }
  };

  return (
    <SingleOrderContext.Provider
      value={{
        grandTotalAsFloat,
        grandTotalCurrencyFormatted,
        paymentStatusConfig,
        fetchOrder
      }}
    >
      <WithLoading
        loaderSize="small"
        marginTop="30px"
        isLoading={draftWholesaleOrdersStore!.isLoadingOrderDetail}
        hasNoResults={!shouldDisplayOrder()}
        renderNoResults={CannotFindOrder}
        renderLoading={() => (
          <LoadingImage
            text="Loading order..."
            imageSrc={PlantsIconImage}
            imageAlt="plants"
          />
        )}
      >
        <ListsBlob
          fill={hex2rgba(colors.violet, .2)}
        />
        <Container>
          <Box mb="60px">
            <ManageOrderHeader />
            <ManageOrderBody />
          </Box>
        </Container>
      </WithLoading>
    </SingleOrderContext.Provider>
  );
}));

import { gql } from '@apollo/client';

export const MERCHANT_SUPPLIER_INTEGRATIONS_QUERY = gql`
  query MerchantSupplierIntegrations(
    $where: MerchantSupplierIntegrationWhereInput!
  ) {
    merchantSupplierIntegrations(
      where: $where
    ) {
      id
      active
      supplier {
        id
        name
      }
      authentication {
        id
        type
        record
      }
    }
  }
`;

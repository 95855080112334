import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { ProductVariation } from 'stores/product-edit-store/product-edit-store-types';

import ProductService from 'features/products/services';

import * as Styles from './create-variation-tab.styles';
import Colour from './inner-tab-components/inner-tab-colour';
import Components from './inner-tab-components/inner-tab-components';
import Dimensions from './inner-tab-components/inner-tab-dimensions';
import { renderMediaItems } from './inner-tab-components/inner-tab-media';
import RenderStockAndPrice from './inner-tab-components/inner-tab-stock-price';
import { renderVariationButton } from './inner-tab-components/inner-tab-variationButton';

class InnerVariationTab extends Component<any> {
  private getTabContent = (variations: ProductVariation[], currentVariationId: string): ReactNode => {
    return variations.map((variation: ProductVariation) => (
      <div key={variation.id}>
        {
          variation.id === currentVariationId
            ? (
              <div>
                <RenderStockAndPrice
                  variation={variation}
                />
                {renderMediaItems(variation)}
                <Components
                  variation={variation}
                />
                <Colour
                  variation={variation}
                />
                <Dimensions
                  variation={variation}
                />
                {
                  ProductService.ProductCreateEditService.getAvailableVariations().length > 0
                    ? renderVariationButton(this.props.addVariationClick)
                    : null
                }
              </div>
            )
            : null
        }
      </div>
    ));
  };

  render(): React.ReactNode {
    return (
      <Styles.InnerContent>
        {this.getTabContent(this.props.productEditStore.variations, this.props.productEditStore.currentVariationId)}
      </Styles.InnerContent>
    );
  }
}

export default inject('productEditStore')(observer(InnerVariationTab));

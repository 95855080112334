import { FC, Fragment } from 'react';

import { css } from '@emotion/react';
import { Box, Text, Flex } from 'rebass';

import { PhoneContact } from 'types/conversations.types';

import PlantsIconImage from 'assets/images/wholesale/no-results.gif';

import { Contacts } from 'features/conversations/components/contacts/contacts';
import { ConversationView } from 'features/conversations/context/ContactContext';

import IconButton from 'components/icon-button';
import { LoadingImage } from 'components/loading-image/loading-image';
import WithLoading from 'components/with-loading';

import { ContactsWrapper } from '../contacts-wrapper/contacts-wrapper';

import { Container } from './contacts-provider.styles';

interface ContactsProviderProps {
  contacts: PhoneContact[];
  isLoadingContacts: boolean;
  onContactSelect: (contact: PhoneContact) => void;
  onBackButtonClick?: () => void;
  showHeader?: boolean;
}

export const ContactsProvider: FC<ContactsProviderProps> = ({
  contacts,
  isLoadingContacts,
  onContactSelect,
  onBackButtonClick,
  showHeader = false
}) => {
  return (
    <Container>
      <WithLoading
        loaderSize="small"
        marginTop="30px"
        isLoading={isLoadingContacts}
        hasNoResults={false}
        renderNoResults={() => null}
        renderLoading={() => (
          <Flex justifyContent="center">
            <Box
              css={css`
                max-width: 180px;
              `}
            >
              <LoadingImage
                text={(
                  <Text fontSize="16px">
                    Loading suppliers...
                  </Text>
                )}
                imageSrc={PlantsIconImage}
                imageAlt="plants"
              />
            </Box>
          </Flex>
        )}
      >
        <ContactsWrapper
          view={ConversationView.CONTACTS_ONLY}
          headerContent={showHeader && (
            <Fragment>
              <IconButton
                onClick={() => onBackButtonClick?.()}
                iconName="arrow-small-left"
                size={36}
              />
              <span>Select Supplier</span>
              <span>{' '}</span>
            </Fragment>
          )}
        >
          <Contacts
            contacts={contacts || []}
            loading={isLoadingContacts}
            onContactSelect={onContactSelect}
          />
        </ContactsWrapper>
      </WithLoading>
    </Container>
  );
};

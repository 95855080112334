import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { CourierBooking } from 'generated-types';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';

import { gridGutter } from 'utils/rebass-theme';

import CourierAPIService from 'features/orders/services/courier-api/courier-api.service';

import {
  statusConfig,
  CourierBookingMetaItem,
  CourierBookingMetaColumn,
  CourierBookingStatusConfigType,
  CourierBookingDropdownActionSlug
} from './courier-item.config';
import * as Styles from './courier-item.styles';
import * as Types from './courier-item.types';
import CourierBookingItemHeader from './item-header';
import StatusQuickSwitch from './status-quick-switch';

class CourierBookingItem extends Component<Types.CourierBookingItemProps> {
  orderNo = this.props.data.orders ? this.props.data.orders[0]?.orderNo : null;

  state = {
    isUpdatingStatus: false
  };

  onCancelSelect = (): void => {
    CourierAPIService.canCancelCourierBooking(this.props.data.id)
      .then(payload => {
        if (payload?.response === 'YES') {
          this.props.modalStore!.triggerModal({
            modalType: 'courierCancelConfirmation',
            data: {
              bookingId: this.props.data.id,
              courierRef: this.props.data.courierReferenceId,
              cancellationFee: payload.payload.cancellationFee,
              currency: this.props.data.payment?.currency,
              pickupDate: this.props.data.pickupDate,
              deliveryDate: this.props.data.deliveryDate
            }
          });
        } else {
          this.props.modalStore!.triggerModal({
            modalType: 'confirmation',
            data: {
              title: 'Cannot cancel courier booking',
              copy: 'This courier booking cannot be cancelled.',
              confirmButtonCopy: 'OK',
              confirmButtonAction: async (): Promise<any> => new Promise((resolve): any => {
                resolve('');
              })
            }
          });
        }
      });
  };

  onReportSelect = (): void => {
    this.props.chatWidgetStore!.enableChatWidget();
    window['zE']?.('webWidget', 'chat:send', 'Hey FloomX team! I would like to report an issue with my courier booking.');
  };

  onStatusUpdate = (statusSlug: any): void => {
    if (statusSlug === CourierBookingDropdownActionSlug.Cancel) {
      this.onCancelSelect();
    } else if (statusSlug === CourierBookingDropdownActionSlug.ReportIssue) {
      this.onReportSelect();
    }
  };

  generateCardData = (data: CourierBooking): { [key in CourierBookingMetaColumn]?: any } => {
    const initialData: { [key in CourierBookingMetaColumn]?: any } = {
      pickupDate: data.pickupDate,
      deliveryDate: data.deliveryDate
    };

    if (!!data?.orders?.[0]) {
      return {
        ...initialData,
        orderTitle: data.orders[0],
        recipient: data.orders[0],
        merchantTitle: data.orders[0],
        location: data.orders[0].shippingAddress
      };
    } else if (!!data.shippingAddress) {
      return {
        ...initialData,
        recipient: data,
        merchantTitle: data,
        location: data.shippingAddress
      };
    }

    return initialData;
  };

  render(): ReactNode {
    const status: CourierBookingStatusConfigType = statusConfig[this.props.data.deliveryStatus];

    return (
      <Styles.Wrapper
        key={this.props.data.id}
        alignSelf="stretch"
        p={gridGutter / 2}
      >
        <Styles.CourierBookingCard>
          <Styles.CourierBookingCardContent>
            <Flex css={css`position: relative;`}>
              <CourierBookingItemHeader
                layout="grid"
                status={status}
                data={this.props.data}
              />
            </Flex>
            <Styles.Body>
              {
                status.metaItem.map((item: CourierBookingMetaColumn) => {
                  const { component: ColumnComponent, dataKey } = CourierBookingMetaItem[item];

                  const cardData = this.generateCardData(this.props.data);

                  if (!cardData[dataKey]) return null;

                  return (
                    <ColumnComponent
                      key={item}
                      layout="grid"
                      status={this.props.data.deliveryStatus}
                      referenceNo={this.props.data.courierReferenceId}
                      id={this.props.data.id}
                      merchantId={this.props.merchantId}
                      data={cardData[dataKey] || cardData}
                    />
                  );
                })
              }
            </Styles.Body>
          </Styles.CourierBookingCardContent>
          <StatusQuickSwitch
            layout="grid"
            config={status}
            groupName={this.props.groupName}
            currentStatus={this.props.data.deliveryStatus}
            referenceNo={this.props.data.courierReferenceId}
            onChange={this.onStatusUpdate}
            isLoading={this.state.isUpdatingStatus}
          />
        </Styles.CourierBookingCard>
      </Styles.Wrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  modalStore: stores.modalStore,
  chatWidgetStore: stores.chatWidgetStore
}))(observer(CourierBookingItem));

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { borderRadius, breakpoints, colors, shadows } from 'utils/rebass-theme';

export const ConversationsCard = styled(Flex)`
  height: 100%;
  position: relative;
  border-radius: ${borderRadius.large};
  background: ${colors.white};
  box-shadow: ${shadows.small};

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
  }
`;

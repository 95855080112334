import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  OrderItem,
  UserRole,
  Order,
  Channel,
  OrderDiscount
} from 'generated-types.d';

import {
  TimeService,
  CurrencyService,
  PermissionsService
} from 'lib';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';
import { textStyles } from 'utils/rebass-theme';

import OrderHelpers from 'features/orders/helpers/order-helpers';
import { ORDER_PERMISSIONS } from 'features/orders/orders.constants';
import { OrderItemAddonSnapshot } from 'features/orders/orders.types';

import SectionHeading from 'components/section-heading';
import StyledLink from 'components/styled-link/styled-link';

import * as Styles from './order-receipt-content.styles';
import * as Types from './order-receipt-content.types';

class OrderReceiptContent extends Component<Types.OrderReceiptContentProps> {
  state = {
    subTotal: 0,
    delivery: 0,
    discount: 0,
    inclusiveTax: 0
  };

  componentDidMount = (): void => {
    this.setState({
      subTotal: this.calculateSubTotal(),
      delivery: this.calculateDelivery(),
      discount: this.calculateDiscountTotal(),
      inclusiveTax: this.calculateInclusiveTax()
    });
  };

  shouldComponentUpdate = (): boolean => {
    return this.state.subTotal === 0;
  };

  private calculateTotal = (): number => {
    const { subTotal, delivery, discount, inclusiveTax } = this.state;
    const serviceFee = this.props.ordersStore!.orderReceiptData.payment?.serviceFee;

    return subTotal + delivery + discount + inclusiveTax + (PermissionsService.isInternalRole() ? serviceFee : 0);
  };

  private calculateInclusiveTax = (): number => {
    const { tax } = this.props.ordersStore!.orderReceiptData;

    return !!tax && !tax.inclusive ? tax.amount : 0;
  };

  private calculateStripeFee = (): number => (this.props.ordersStore?.orderReceiptData.payment.stripeFee || 0) / 100;

  private calculateDelivery = (): number | null => {
    const { price } = this.props.ordersStore!.orderReceiptData.orderDeliveryConfig;

    return price || null;
  };

  private calculateSubTotal = (): number => {
    if (this.props.ordersStore!.orderReceiptData.orderNo?.length > 10) {
      return this.props.ordersStore!.orderReceiptData.orderItems!.reduce((sum: number, orderItem: OrderItem) => {
        const orderItemPriceMultiplier = this.props.ordersStore!.orderReceiptData.channel === Channel.Floom ? orderItem.quantity : 1;
        let addonTotal = 0;

        if (!!orderItem?.addOnsSnapshot?.length && this.props.ordersStore!.orderReceiptData.channel === Channel.Website) {
          const addonSnapshots = orderItem.addOnsSnapshot as OrderItemAddonSnapshot[];

          addonTotal = addonSnapshots.reduce((addonSum: number, currSnapshot) => {
            return addonSum + currSnapshot.price;
          }, 0);
        }

        return sum + (orderItem.price * orderItemPriceMultiplier) + addonTotal;
      }, 0);
    }

    return this.props.ordersStore!.orderReceiptData.itemSubtotal;
  };

  private calculateDiscountTotal = (): number => {
    const discount: OrderDiscount | null = this.props.ordersStore!.orderReceiptData.orderDiscount;

    return !!discount ? discount.total : 0;
  };

  private currencyFormat = (price: number): string => CurrencyService.formatPrice(
    price,
    this.props.ordersStore!.orderReceiptData.currency!
  );

  private renderAddons = (orderItem: OrderItem): ReactNode => {
    if (!!orderItem?.addOnsSnapshot?.length) {
      const snapshots = orderItem.addOnsSnapshot as OrderItemAddonSnapshot[];

      return snapshots.map((snapshot, index) => (
        <tr key={snapshot.id}>
          <Styles.SummaryRow>
            → {index === 0 ? 'Including' : 'and'} <strong>{snapshot.quantity}x {snapshot.title}</strong>
          </Styles.SummaryRow>
          <Styles.SummaryRow>+ {this.currencyFormat(snapshot.price)}</Styles.SummaryRow>
        </tr>
      ));
    }

    return null;
  };

  render(): React.ReactNode | null {
    const data = this.props.ordersStore!.orderReceiptData as Order;
    const discount = data.orderDiscount;

    return (
      <>
        <Box p={['20px', '30px']}>
          <Styles.OrderSummary>
            <tbody>
              <tr>
                <Styles.SummaryRow>Invoice no:</Styles.SummaryRow>
                <Styles.SummaryRow>{OrderHelpers.shortenOrderNumber(data.orderNo)}</Styles.SummaryRow>
              </tr>
              <tr>
                <Styles.SummaryRow>Order date:</Styles.SummaryRow>
                <Styles.SummaryRow>{TimeService.dayMonthYear(data.orderedAt)}</Styles.SummaryRow>
              </tr>
              <tr>
                <Styles.SummaryRow>Date paid:</Styles.SummaryRow>
                <Styles.SummaryRow>{TimeService.dayMonthYear(data.orderedAt)}</Styles.SummaryRow>
              </tr>
              <tr>
                <Styles.SummaryRow>VAT No (floom):</Styles.SummaryRow>
                <Styles.SummaryRow>232 9057 16</Styles.SummaryRow>
              </tr>
            </tbody>
          </Styles.OrderSummary>
          <FloomXRestrictedComponent
            restrictedRoles={[UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember]}
          >
            <Box mt="30px">
              <SectionHeading
                title="Florist"
                hasSpacing={false}
              />
              <Box
                css={textStyles.body}
                mt="20px"
              >
                <Box>
                  {data.merchant.title}
                </Box>
              </Box>
            </Box>
          </FloomXRestrictedComponent>

          <Box mt="30px">
            <SectionHeading
              title="Sender"
              hasSpacing={false}
            />
            <Box
              css={textStyles.body}
              mt="20px"
            >
              <Box>
                {data.customerUser?.givenName} {data.customerUser?.familyName}
              </Box>
              <FloomXRestrictedComponent
                restrictedRoles={ORDER_PERMISSIONS.emailAddress.restrictedRoles}
                shouldOverrideRoles={OrderHelpers.hasCustomWebsitePermissions(
                  'emailAddress',
                  this.props.ordersStore!.currentOrder.channel
                )}
              >
                <Box mt="5px">
                  <a href={`mailto:${data.customerUser?.email}`}>
                    <StyledLink>
                      {data.customerUser?.email}
                    </StyledLink>
                  </a>
                </Box>
              </FloomXRestrictedComponent>
              {!!data.billingAddress?.phone && (
                <Box mt="5px">
                  <a href={`tel:${data.billingAddress.phone}`}>
                    <StyledLink>
                      {data.billingAddress.phone}
                    </StyledLink>
                  </a>
                </Box>
              )}
            </Box>
          </Box>

          <Box mt="30px">
            <SectionHeading
              title="Items"
              hasSpacing={false}
            />
            <Box
              css={textStyles.body}
              mt="20px"
            >
              <Styles.OrderSummary>
                <tbody>
                  {
                    data.orderItems?.map((orderItem: OrderItem, index: number): JSX.Element => {
                      let addonTotal = 0;

                      if (!!orderItem?.addOnsSnapshot?.length && data.channel === Channel.Floom) {
                        const addonSnapshots = orderItem.addOnsSnapshot as OrderItemAddonSnapshot[];

                        addonTotal = addonSnapshots.reduce((addonSum: number, currSnapshot) => {
                          return addonSum + currSnapshot.price;
                        }, 0);
                      }

                      const orderItemPriceMultiplier = data.channel === Channel.Floom ? orderItem.quantity : 1;

                      return (
                        <>
                          <tr key={index}>
                            <Styles.SummaryRow
                              css={css`
                                padding-top: 15px;
                              `}
                            >
                              <strong>
                                {OrderHelpers.orderItemTitle(orderItem)}
                              </strong>
                            </Styles.SummaryRow>
                            <Styles.SummaryRow
                              css={css`
                                padding-top: 15px;
                              `}
                            >
                              {this.currencyFormat(((orderItem.price - (data.orderNo?.length > 10 ? addonTotal : 0)) * orderItemPriceMultiplier))}
                            </Styles.SummaryRow>
                          </tr>
                          {this.renderAddons(orderItem)}
                        </>
                      );
                    })
                  }
                </tbody>
              </Styles.OrderSummary>
            </Box>
          </Box>
        </Box>
        <Box
          p="30px"
          bg="lightGrey"
        >
          <Styles.OrderSummary>
            <tbody>
              <tr>
                <Styles.SummaryRow>Subtotal</Styles.SummaryRow>
                <Styles.SummaryRow>{this.currencyFormat(this.state.subTotal)}</Styles.SummaryRow>
              </tr>
              {!!this.state.delivery && (
                <tr>
                  <Styles.SummaryRow>Delivery</Styles.SummaryRow>
                  <Styles.SummaryRow>{this.currencyFormat(this.state.delivery)}</Styles.SummaryRow>
                </tr>
              )}
              {!!discount && (
                <tr key={discount.id}>
                  <Styles.SummaryRow>Discount {!!discount.discountSnapshot?.code && `(${discount.discountSnapshot?.code})`}</Styles.SummaryRow>
                  <Styles.SummaryRow>
                    {this.currencyFormat(discount.total!)}
                  </Styles.SummaryRow>
                </tr>
              )}
              {data.tax && (
                <tr>
                  <Styles.SummaryRow>{data.tax.name} {data.tax.inclusive && '(included)'}</Styles.SummaryRow>
                  <Styles.SummaryRow>{this.currencyFormat(data.tax.amount)}</Styles.SummaryRow>
                </tr>
              )}
              {!!PermissionsService.isInternalRole() && (
                <tr>
                  <Styles.SummaryRow>Service fee</Styles.SummaryRow>
                  <Styles.SummaryRow>{this.currencyFormat(data.payment?.serviceFee || 0)}</Styles.SummaryRow>
                </tr>
              )}
              <Styles.BorderRow>
                <Styles.SummaryRow>
                  <div />
                </Styles.SummaryRow>
                <Styles.SummaryRow>
                  <div>Total: {this.currencyFormat(this.calculateTotal())}</div>
                </Styles.SummaryRow>
              </Styles.BorderRow>
            </tbody>
          </Styles.OrderSummary>
        </Box>
      </>
    );
  }
}

export default inject('ordersStore')(observer(OrderReceiptContent));

import styled from '@emotion/styled';
import { Box } from 'rebass';

import {
  colors,
  textStyles
} from 'utils/rebass-theme';

export const Footer: any = styled(Box)`
  text-align: center;
  background-color: ${colors.floomMidnightBlue};
  margin: 0 auto;
`;

export const Link: any = styled(Box)`
  ${textStyles.body}
  color: ${colors.white};
  margin-bottom: .5em;

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

export const Logo: any = styled(Box)`
  margin-bottom: 3em;
`;

export const Contact = styled(Box)`
  margin-top: 2em;
`;

export const ContactTitle = styled(Box)`
  ${textStyles.body}
  color: ${colors.white};
  margin-bottom: .5em;
  font-weight: bold;
`;

import moment from 'moment';

import { PermissionsService } from '../permissions/permissions.service';

import {
  PEAK_PERIOD_CONFIG,
  PeakDateRange
} from './peak-feature-toggle.constants';

const isWithinPeakPeriod = (dates: PeakDateRange[]): boolean => {
  return dates.some(date => {
    return moment().isBetween(moment(date.from), moment(date.to));
  });
};

export const isDisabledDuringPeak = (): boolean => {
  return isWithinPeakPeriod(PEAK_PERIOD_CONFIG.dates) && !PermissionsService.isSuperAdmin();
};

export const setFeatureToggleNotification = (): string => {
  return PEAK_PERIOD_CONFIG.defaultMessage;
};

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const EditBox: any = styled(Box)`
  height: 100%;
  width: 100%;

  textarea {
    height: 190px;
    padding-bottom: 60px !important;
    resize: none;
    border: ${colors.middleGrey} 1px solid;
    border-radius: 3px;

    &:disabled {
      background: #fff;
      color: ${colors.floomMidnightBlue};
    }
  }
`;

export const EditControls: any = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
`;

export const CharCount: any = styled.div`
  position: absolute;
  bottom: 14px;
  left: 14px;
  height: 35px;
  border-radius: 3px;
  background-color: ${colors.lightGrey};
  color: ${colors.middleGrey};
  line-height: 32px;
  padding: 0 10px;
`;

export const IconButton: any = styled.button`
  height: 35px;
  width: 35px;
  border-radius: 3px;
  background-color: ${colors.lightGrey};
  margin: 3px;

  &[disabled] {
    display: none;
  }
`;

export const EditBoxContainer: any = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

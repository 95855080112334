import React, { ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Text, Box } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import CheckboxToggle from 'components/checkbox-toggle';
import SectionHeading from 'components/section-heading';

import { CheckBoxWrapper } from '../../delivery-config-modal.styles';

import * as Types from './delivery-make-default.types';

class DeliveryConfigMakeDefault extends React.Component<Types.DeliveryConfigMakeDefaultProps> {
  isDefault = (): boolean => !!this.props.deliveryStore!.selectedDeliveryConfig
    && !!this.props.deliveryStore!.deliveryConfigs!
      .find(config => config.id === this.props.deliveryStore!.selectedDeliveryConfig!.id && config.default);

  render(): ReactNode {
    if (this.isDefault() || !this.props.deliveryStore!.selectedDeliveryConfig?.deliveryPrice?.isDefault) return null;

    return (
      <Box>
        <SectionHeading title="Make default?" />
        <CheckBoxWrapper>
          <CheckboxToggle
            isChecked={this.props.deliveryStore!.isDefault}
            controlValue={true}
            onChange={this.props.deliveryStore!.toggleIsDefault}
            activeCopy="Yes"
            inactiveCopy="No"
          />
          <Text css={textStyles.body}>
            Set as default delivery option?
          </Text>
        </CheckBoxWrapper>
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  deliveryStore: stores.deliveryStore
}))(observer(DeliveryConfigMakeDefault));

import React, { PureComponent, MouseEvent } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';

import Lozenge from 'components/lozenge';
import Tooltip from 'components/tooltip';

import * as Styles from './floom-collection-lozenge.styles';
import * as Types from './floom-collection-lozenge.types';

class FloomCollectionLozenge extends PureComponent<Types.FloomCollectionLozengeProps> {
  private handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    e.preventDefault();

    const modalData: ConfirmationModalData = {
      title: 'What\'s "Floom Collection"?',
      copy: (
        <>
          <span>
            Floom Collection is a selection of products designed by our
            in-house merchandising team, as a blueprint for you to sell yourselves.
            All you need to do in order to receive sales for your chosen
            products, is add some stock and set those products as active. Then when
            an order arrives, create the exact bouquet as you see here
            and send it on to the customer to enjoy.
          </span>
          <br /><br />
          <span>
            {`To set stock and active states, click the "Quick Edit" button on the product.`}
          </span>
        </>
      ),
      confirmButtonCopy: 'Sounds amazing!',
      cancelButtonCopy: '',
      errorCopy: '',
      onCancel: (): any => {},
      confirmButtonAction: async (): Promise<any> => new Promise(async (resolve): Promise<any> => {
        resolve('');
      })
    };

    this.props.modalStore!.triggerModal({
      modalType: 'confirmation',
      data: modalData
    });
  };

  render(): React.ReactNode | null {
    return (
      <Tooltip
        message={'What\'s "Floom Collection"?'}
        tooltipStyles={css`
          width: 230px;
        `}
      >
        <Box
          as="button"
          onClick={this.handleClick}
        >
          <Lozenge
            copy={(
              <span>
                <span>Floom Collection</span>
                <Styles.QuestionMark>?</Styles.QuestionMark>
              </span>
            )}
            bg={ColourOption.lightPink}
            color={ColourOption.floomMidnightBlue}
          />
        </Box>
      </Tooltip>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore
}))(observer(FloomCollectionLozenge));

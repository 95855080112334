export default class UrlService {
  public static stripSlashes = (path: string): string => path.replace(new RegExp('/', 'g'), '');

  public static isFuzzyMatch = (url: string, location: string): boolean => {
    if (url === '/') {
      return UrlService.isExactMatch(url, location);
    }

    if (url.startsWith('/products')) {
      return location.startsWith('/products');
    }

    if (url.startsWith('/add-ons')) {
      return location.startsWith('/add-ons');
    }

    return location.startsWith(url);
  };

  public static isExactMatch = (url: string, location: string): boolean => {
    return UrlService.stripSlashes(location) === UrlService.stripSlashes(url);
  };

  public static setImageSrc = (imgSrc: string): string => {
    if (imgSrc.toLowerCase().startsWith('http')) return imgSrc;

    return `${process.env.FLOOMX_IMGIX_URL}${imgSrc}`;
  };

  public static checkImageExists = async (largeImage: string): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject): void => {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange =  (): void => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(true);
          } else {
            reject(false);
          }
        }
      };
      xhr.open('HEAD', largeImage, true);
      xhr.send();
    });
  };
}

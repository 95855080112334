import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { ActivityToggle } from 'utils/css-mixins/css-mixins';
import { colors } from 'utils/rebass-theme';

import { EntityListTableItem } from 'components/entity-list/entity-list.styles';

export const EntityItemImageStyles = css`
  display: block;
  border-radius: 3px;
`;

export const EntityItemInputContainer = styled(EntityListTableItem)`
  display: flex;
  align-items: center;
`;

export const IndicatorPanelItem = styled(Flex)`
  + ${(): any => IndicatorPanelItem} {
    margin-top: 10px;
  }

  ${ActivityToggle} {
    padding-top: 2px;
  }
`;

export const EntityItemImageContainer = styled.div<any>`
  height: ${({ height }: any): any => height}px;
  width: ${({ width }: any): any => width}px;

  ${({ hasMultiple, height, width }: any): any => {
    if (hasMultiple) {
      return `
        position: relative;
        z-index: 0;

        &:before, &:after {
          position: absolute;
          height: ${height}px;
          width: ${width}px;
          border-radius: 3px;
          content: '';
        }

        &:before {
          top: 2px;
          left: 2px;
          background: ${colors.paleGrey};
          z-index: -1;
        }
        &:after {
          top: 4px;
          left: 4px;
          background: ${colors.middleGrey};
          z-index: -2;
        }
      `;
    }
  }}
`;

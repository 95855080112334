import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Flex, Box } from 'rebass';

import { MerchantPlan } from 'generated-types.d';

import {
  Cookies,
  CurrencyService,
  LocalisationService,
  MerchantService,
  MerchantSubscriptionService
} from 'lib';

import {
  colors,
  textStyles
} from 'utils/rebass-theme';

import GenericModal from 'components/generic-modal';
import Icon from 'components/icon';
import PaymentDetailsCard from 'components/payment-details-card';

import * as Styles from './plan-upgrade-modal.styles';
import * as Types from './plan-upgrade-modal.types';

const SMALL_PRINT_MARKUP = `
  This is a pro-rata fee between now ([PRORATION_STARTS_AT]) and the
  end of the current billing period, based on the difference
  between your current plan ([CURRENT_PLAN_NAME]) and your new
  plan ([UPGRADE_PLAN_NAME]).
  <br /><br />
  When your next billing period starts on [PRORATION_ENDS_AT],
  you will be charged [UPGRADE_PLAN_PRICE] ([UPGRADE_PLAN_NAME])
  and every subsequent month thereafter until you cancel your subscription.
  <br /><br />
  We will be using your current payment details, listed below.
`;

const SMALL_PRINT_MARKUP_TRIAL = `
  No fee will be charged today. On [TRIAL_ENDS_AT], you will be
  charged [UPGRADE_PLAN_PRICE] for your new plan ([UPGRADE_PLAN_NAME])
  and every subsequent month thereafter until you cancel your subscription.
  <br /><br />
  We will be using your current payment details, listed below.
`;

class PlanUpgradeModal extends Component<Types.PlanUpgradeModalProps> {
  state = {
    isUpgrading: false
  };

  private onUpgrade = async (): Promise<void> => {
    this.setState({ isUpgrading: true });

    try {
      await MerchantSubscriptionService.updateMerchantSubscription(
        this.props.data.upgradePlan.id,
        this.props.data.subscriptionEstimate.stripePriceId!,
        this.props.data.subscriptionEstimate.prorationStartsAt,
        this.props.merchantStore!.merchant!.id
      );

      Cookies.set('floomx-upgraded', true, { maxAge: 43200 });
      window?.location?.reload();
      this.props.toasterStore!.popNotificationToast('You have upgraded!');
    } catch (error) {
      this.props.toasterStore!.popNotificationToast('There\'s been a problem upgrading at the moment');
    }

    this.setState({ isUpgrading: false });
  };

  private formatSmallPrint = (): string => {
    const { subscriptionEstimate, upgradePlan } = this.props.data;
    const { merchant } = this.props.merchantStore!;
    const upgradeCost = this.formatUpgradeCost(this.props.data.upgradePlan);

    switch (true) {
      case MerchantService.isOnTrial(merchant!):
        return SMALL_PRINT_MARKUP_TRIAL
          .replace('[UPGRADE_PLAN_NAME]', LocalisationService.localisePlanName(upgradePlan.type))
          .split('[TRIAL_ENDS_AT]').join(moment(merchant?.subscription?.trialExpiresAt).format('Do MMMM gggg'))
          .replace('[UPGRADE_PLAN_PRICE]', upgradeCost)
          .replace('[UPGRADE_PLAN_CURRENCY]', upgradePlan.currency);

      default:
        return SMALL_PRINT_MARKUP
          .replace('[PRORATION_STARTS_AT]', moment(subscriptionEstimate.prorationStartsAt).format('Do MMMM gggg'))
          .replace('[CURRENT_PLAN_NAME]', LocalisationService.localisePlanName(merchant?.plan?.type))
          .split('[UPGRADE_PLAN_NAME]').join(LocalisationService.localisePlanName(upgradePlan.type))
          .replace('[PRORATION_ENDS_AT]', moment(subscriptionEstimate.prorationEndsAt).format('Do MMMM gggg'))
          .replace('[UPGRADE_PLAN_PRICE]', upgradeCost)
          .replace('[UPGRADE_PLAN_CURRENCY]', upgradePlan.currency);
    }
  };

  private formatUpgradeCost = (plan: MerchantPlan): string => {
    return CurrencyService.formatPrice(plan.price, plan.currency);
  };

  private renderSummary = (): ReactNode => {
    const upgradePlan = this.props.data.upgradePlan;
    const upgradePlanName = LocalisationService.localisePlanName(upgradePlan.type);
    const upgradePlanPrice = CurrencyService.formatPrice(upgradePlan.price, upgradePlan.currency);

    return (
      <Flex>
        <Box flex="1">
          <Styles.Heading>
            Your upgrade
          </Styles.Heading>
          <Styles.Body mt="5px">
            FloomX {upgradePlanName}
          </Styles.Body>
        </Box>
        <Box flex="1">
          <Box>
            <Styles.Heading>
              Cost of plan
            </Styles.Heading>
            <Styles.Body mt="5px">
              {upgradePlanPrice} /mo
            </Styles.Body>
          </Box>
        </Box>
      </Flex>
    );
  };

  private renderFeature = (title: string, copy: string): ReactNode => {
    return (
      <Box p="0 30px">
        <Flex>
          <Box mr="10px">
            <Icon iconName="tick-circle" />
          </Box>
          <Box>
            <Box css={textStyles.subhead}>
              {title}
            </Box>
            <Box
              css={textStyles.body}
              color={colors.shade40}
              mt="4px"
            >
              {copy}
            </Box>
          </Box>
        </Flex>
      </Box>
    );
  };

  private renderFeatures = (): ReactNode => {
    const merchantSubscription = this.props.merchantStore!.merchant?.subscription;
    const upgradePlan = this.props.data.upgradePlan;

    const orderCopy = upgradePlan?.orderLimit! > 0
      ? `Up to ${upgradePlan.orderLimit} orders per month`
      : 'Unlimited orders';

    const productCopy = upgradePlan?.productLimit! > 0
      ? `${upgradePlan?.productLimit} active products`
      : 'Unlimited products';

    return (
      <Styles.BorderSection>
        <Styles.Heading>
          What are you getting
        </Styles.Heading>
        <Flex m="22px -30px 0 -30px">
          {this.renderFeature(orderCopy, `Compared to ${merchantSubscription?.orderLimit} now`)}
          {this.renderFeature(productCopy, `Compared to ${merchantSubscription?.productLimit} now`)}
        </Flex>
      </Styles.BorderSection>
    );
  };

  private renderUpgradeCost = (): ReactNode => {
    const merchant = this.props.merchantStore!.merchant;
    const isOnTrial = MerchantService.isOnTrial(merchant!);

    return (
      <Box>
        <Flex
          justifyContent="space-between"
          alignItems="center"
        >
          <Styles.Heading>
            To pay today:
          </Styles.Heading>
          <Box>
            { !isOnTrial && (
              <Styles.MonthlyPrice as="span">
                {this.formatUpgradeCost(this.props.data.upgradePlan)}
              </Styles.MonthlyPrice>
            )}
            <Styles.UpgradePrice as="span">
              {CurrencyService.formatPrice(
                this.props.data.subscriptionEstimate.amountToPay!,
                this.props.data.upgradePlan.currency
              )}
            </Styles.UpgradePrice>
          </Box>
        </Flex>
      </Box>
    );
  };

  private renderSmallprint = (): ReactNode => {
    return (
      <Box
        css={textStyles.body}
        mt="20px"
      >
        <div
          dangerouslySetInnerHTML={{ __html: this.formatSmallPrint() }}
        />
      </Box>
    );
  };

  private renderCardDetails = (): ReactNode => {
    return (
      <Box mt="30px">
        <PaymentDetailsCard
          paymentMethod={this.props.merchantStore!.merchant?.paymentMethod}
          fullWidth={true}
        />
        <Box
          css={textStyles.body}
          color={colors.shade40}
          mt="20px"
        >
          Not correct? Go to your payment settings to change
        </Box>
      </Box>
    );
  };

  render(): ReactNode {
    return (
      <GenericModal
        title={this.props.data.title}
        closeModal={this.props.closeModal}
        modalOpen={this.props.isOpen}
        confirmButtonText="Upgrade now"
        confirmButtonAction={this.onUpgrade}
        isButtonLoading={this.state.isUpgrading}
        width={550}
        innerComponent={(
          <Styles.Container>
            {this.renderSummary()}

            {this.renderFeatures()}

            <Styles.BorderSection>
              {this.renderUpgradeCost()}
              {this.renderSmallprint()}
              {this.renderCardDetails()}
            </Styles.BorderSection>
          </Styles.Container>
        )}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  toasterStore: stores.toasterStore
}))(observer(PlanUpgradeModal));

import React, { Component, ReactNode } from 'react';

import { Flex } from 'rebass';

import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType, TableLayoutEntityConfigProps } from 'components/entity-list/table-layout-entity.types';

import * as Styles from './selected-item.styles';
import * as Types from './selected-item.types';

export default class SelectedItem extends Component<Types.SelectedItemProps> {
  private cellConfig = (): TableLayoutEntityConfigProps[] => {
    return [
      {
        cellType: CellType.Image,
        props: {
          path: this.props.item.mediaSource
        }
      },
      {
        flexBasis: '200px',
        cellType: CellType.Text,
        props: {
          title: this.props.item.title
        }
      },
      {
        cellType: CellType.Text,
        flexGrow: '2',
        props: {
          text: this.props.item.details
        }
      },
      {
        cellType: CellType.Cross,
        props: {
          onClick: (): void => this.props.onItemRemove(this.props.item.id)
        }
      }
    ];
  };

  private renderLoading = (): ReactNode => {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        css={Styles.ItemLoading}
      >
        Loading item...
      </Flex>
    );
  };

  private renderItem = (): ReactNode => {
    return (
      <TableLayoutEntity
        config={this.cellConfig()}
      />
    );
  };

  render(): JSX.Element | null {
    return (
      <Styles.SelectedItem>
        {!this.props.item ? this.renderLoading() : this.renderItem()}
      </Styles.SelectedItem>
    );
  }
}

import { TableLayoutHeadingConfigProps } from 'components/entity-list/table-layout-entity.types';

export const HeadingsConfig: TableLayoutHeadingConfigProps[] = [
  {
    heading: 'Availability',
    fixedWidth: '170px'
  },
  {
    heading: 'Add-on',
    flexGrow: '2',
    flexBasis: '150px'
  },
  {
    heading: 'Category',
    flexGrow: '1',
    flexBasis: '75px'
  },
  {
    heading: 'Product',
    flexGrow: '1',
    flexBasis: '100px'
  },
  {
    heading: 'Stock',
    fixedWidth: '170px'
  },
  {
    heading: 'Price',
    fixedWidth: '170px'
  }
];

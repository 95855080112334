import {
  Currency as GCurrency,
  OrderDeliveryState
} from 'generated-types.d';

export type CourierListGroupOption =
  | 'upcoming'
  | 'past';

export type CourierListPageRoute =
  | 'all';

export interface CourierBookingPayment {
  amount: number;
  currency: string;
}

export enum CourierListFilterGroup {
  Status = 'Status',
  Currency = 'Currency'
}

export type SelectedCourierListFilters = {
  [key in CourierListFilterGroup]: Set<OrderDeliveryState | GCurrency>
};

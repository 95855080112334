import { FC, Fragment, useEffect, useState } from 'react';

import 'styles/tailwind.css';
import { gql } from '@apollo/client';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import moment from 'moment';
import store from 'stores';

import { PromotedPeriod, PromotedPostcode } from 'generated-types.d';

import { GraphQL } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';

import { colors } from 'utils/rebass-theme';

import PaymentMessage from '../components/payment-message';
import { PaymentMessageType } from '../types';

const merchantStore = store.merchantStore as MerchantStore;
const stripePromise = loadStripe(process.env.FX_FE_STRIPE_PUBLIC_KEY!);
const currency = merchantStore!.merchant?.currency;

interface PostcodesByPeriod extends PromotedPeriod {
  postcodes: PromotedPostcode[];
}

export const PROMOTED_POSTCODES_FOR_MERCHANT = gql`
  query PromotedPostcodes(
    $merchantId: ID!
  ) {
      promotedPostcodes(
        where: {
          status: SOLD
          merchant: {
            id: $merchantId
          }
        }
      ) {
        id
        postcode
        period {
          id
          startDate
          endDate
        }
      }
  }
`;

const getMerchantsPromotedPostcodes = async (merchantId?: string): Promise<PromotedPostcode[]> => {
  const result = await GraphQL.query<{ promotedPostcodes: PromotedPostcode[] }>(
    PROMOTED_POSTCODES_FOR_MERCHANT,
    {
      merchantId
    }
  );

  console.log('getCurrentPeriodPostcodes', result);

  return result.data.promotedPostcodes;
};

export const PromotedMyPromotions: FC = () => {
  return (
    <Elements
    // @ts-ignore
      stripe={stripePromise}
    >
      <InnerMyPromotions />
    </Elements>
  );
};

const InnerMyPromotions: FC = () => {
  console.log('render current');

  const stripe = useStripe();
  const [message, setMessage] = useState<PaymentMessageType | null>(null);
  const merchantId = merchantStore.merchant!.id;
  const today = moment().tz(merchantStore.merchant!.timezone);

  const { data: promotedPostcodes, isLoading } = useQuery({
    queryKey: ['allPromotedPostcodes', merchantId],
    queryFn: () => getMerchantsPromotedPostcodes(merchantId),
    enabled: !!merchantId
  });

  const postcodesByPeriod = promotedPostcodes?.reduce((acc, postcode) => {
    const { period, ...withoutPeriod } = postcode;

    if (!acc[period.id]) {
      acc[period.id] = {
        ...postcode.period,
        postcodes: []
      };
    }

    acc[period.id]['postcodes'].push(withoutPeriod);

    return acc;
  }, {} as PostcodesByPeriod[]);

  useEffect(() => {
    if (!stripe) {
      console.log('no stripe');

      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      console.log('no client secret');

      return;
    }

    stripe.retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent?.status) {
          case 'succeeded':
            setMessage({
              type: 'success',
              headerText: 'Payment succeeded!',
              bodyText: 'Your promoted postcodes have been successfully purchased.'
            });

            break;

          case 'processing':
            setMessage({
              type: 'processing',
              headerText: 'Payment processing',
              bodyText: 'Your payment is processing. Check back later.'
            });

            break;

          case 'requires_payment_method':
            setMessage({
              type: 'error',
              bodyText: (
                <Fragment>
                  Your payment was unsuccessful - you haven&apos;t been charged. Please{' '}
                  <Link
                    className="underline"
                    to={NavPages.PromotedUpcomingPostcodes}
                  >
                    select your postcodes again.
                  </Link>
                </Fragment>
              )
            });

            break;

          default:
            setMessage({
              type: 'error',
              bodyText: 'Something went wrong. Please contact us.'
            });

            break;
        }
      });
  }, [stripe]);

  const periods = !!postcodesByPeriod ? Object.values(postcodesByPeriod)
    .sort((a, b) => {
      if (a.startDate > b.startDate) {
        return -1;
      } else if (a.startDate < b.startDate) {
        return 1;
      }

      return 0;
    }) : [];

  return (
    <div className="max-w-5xl mx-auto px-6 md:px-8 py-6">
      <div className="max-w-3xl">
        <div className="mb-4">
          <h1 className="text-lg">My promotions</h1>
        </div>
        {isLoading ? (
          <div className="h-48 animate-pulse bg-gray-200 rounded-lg" />
        ) : (
          <div className="bg-white border border-gray-300 overflow-hidden sm:rounded-lg py-5">
            <div className="px-2 sm:px-4">
              {message && (
                <div className="mb-5">
                  <PaymentMessage
                    message={message}
                  />
                </div>
              )}
            </div>
            <div className="px-4 sm:px-6">
              {!!postcodesByPeriod && (
                <Fragment>
                  {periods.map(period => {
                    const isCurrentPeriod = today.isBetween(
                      moment(period.startDate).tz(merchantStore.merchant!.timezone),
                      moment(period.endDate).tz(merchantStore.merchant!.timezone),
                      'day',
                      '[]'
                    );

                    const isUpcomingPeriod = today.isBefore(moment(period.startDate).tz(merchantStore.merchant!.timezone));

                    return (
                      <div
                        key={period.id}
                        className="last:mb-0 mb-5"
                      >
                        <div className="flex justify-between items-center mb-2">
                          <div className="flex items-center">
                            <div className="text-sm font-bold mr-2">
                              {`${moment(period.startDate).format('Do MMM')} - ${moment(period.endDate).format('Do MMM')}`}
                            </div>
                            {isCurrentPeriod && (
                              <span
                                style={{ fontSize: '12px' }}
                                className="inline-flex items-center rounded-lg bg-green-50 px-2 py-1 leading-0 font-bold text-green-700 ring-1 ring-inset ring-green-600/20"
                              >
                                Current
                              </span>
                            )}
                            {isUpcomingPeriod && (
                              <span
                                style={{ fontSize: '12px' }}
                                className="inline-flex items-center rounded-lg bg-purple-50 px-2 py-1 leading-0 font-bold text-purple-700 ring-1 ring-inset ring-purple-600/20"
                              >
                                Upcoming
                              </span>
                            )}
                          </div>
                          <div className="text-xs font-medium text-slate-300">
                            {period.postcodes.length} postcode{period.postcodes.length > 1 ? 's' : ''}
                          </div>
                        </div>
                        <div className="flex flex-wrap">
                          {period.postcodes.sort((a, b) => {
                            if (a.postcode < b.postcode) {
                              return -1;
                            } else if (a.postcode > b.postcode) {
                              return 1;
                            }

                            return 0;
                          }).map(postcode => (
                            <div
                              key={postcode.id}
                              className="mr-3 bg-slate-200 text-sm px-2 pt-0.5 pb-1 rounded-lg text-slate-700 text-center w-16"
                            >
                              {postcode.postcode}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </Fragment>
              )}
              {!(periods.length > 0) && (
                <div>
                  <p className="mb-4 text-sm">Your promotions will be listed here. Create your first promotion today!</p>
                  <Link
                    to={NavPages.PromotedUpcomingPostcodes}
                  >
                    <button
                      style={{
                        backgroundColor: colors.floomMidnightBlue
                      }}
                      className="h-10 px-4 pb-1 rounded-md sm:rounded-lg text-white
                        justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                        disabled:opacity-30"
                    >
                      <span>Book promoted {currency === 'GBP' ? 'postcodes' : 'zipcodes'}</span>
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromotedMyPromotions;

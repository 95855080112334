import React, { Component, ReactNode } from 'react';

import startCase from 'lodash.startcase';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import DropdownNative from 'components/dropdown-native';
import FieldSearch from 'components/field-search';
import NoResultsGeneric from 'components/no-results-generic';
import SectionHeading from 'components/section-heading';

import * as Constants from './entity-list.constants';
import * as Styles from './entity-list.styles';
import * as Types from './entity-list.types';

class EntityList extends Component<Types.EntityListProps, Types.EntityListState> {
  static defaultProps = {
    shouldDisplayRegardless: false
  };

  state = {
    searchValue: '',
    config: {
      onSort: (): void => {
        throw new Error(Constants.LIST_ERRORS.onSort);
      },
      onSearch: (): void => {
        throw new Error(Constants.LIST_ERRORS.onSearch);
      },
      onChange: (): void => {
        throw new Error(Constants.LIST_ERRORS.onChange);
      },
      onSearchClear: (): void => {
        throw new Error(Constants.LIST_ERRORS.onSearchClear);
      },
      ...this.props.config!
    }
  };

  private updateSearchValue = (searchValue: string): void => {
    this.setState({ searchValue });
  };

  private shouldShowHeading = (): boolean => !!this.props.showHeading;

  private hasControls = (): boolean => {
    return this.state.config.hasSearch
       || this.state.config.hasSort;
  };

  private renderHeading = (): ReactNode => (
    <SectionHeading
      title={startCase(this.props.heading)}
      count={this.props.headingCount}
    />
  );

  private renderControls = (): ReactNode => {
    if (!this.hasControls()) return null;

    return (
      <Flex
        mb="50px"
        mt="30px"
        flexWrap="wrap"
      >
        { this.state.config.hasSearch && (
          <Box
            width={['100%', '100%', 'auto', 'auto']}
          >
            <FieldSearch
              onSearch={this.state.config.onSearch!}
              onChange={this.updateSearchValue}
              value={this.state.searchValue}
              onClear={this.state.config.onSearchClear}
              placeholder={this.state.config.searchPlaceholder}
            />
          </Box>
        )}
        { this.state.config.hasSort && (
          <Box
            ml="auto"
            mt={['20px', '20px', '0', '0']}
            width={['100%', '100%', 'auto', 'auto']}
            css={Styles.SortDropdown}
          >
            <DropdownNative
              label="Sort By:"
              id="order-list-sort"
              options={this.state.config.sortOptions}
              optionTitleField="title"
              optionValueField="key"
              onChange={this.state.config.onSort!}
            />
          </Box>
        )}
      </Flex>
    );
  };

  render(): ReactNode | null {
    return(
      <>
        {this.shouldShowHeading() && this.renderHeading()}
        { this.props.showEntities && (
          <>
            {this.renderControls()}
            <Styles.EntityList
              role="table"
              aria-label={`${startCase(this.props.heading || 'Entity')} list`}
            >
              {this.props.children}
            </Styles.EntityList>
          </>
        )}
        { this.props.showNoResults && (
          <NoResultsGeneric
            icon="backpack"
            heading="No merchant Selected"
            copy="Please select a merchant to see payment history"
          />
        )}
      </>
    );
  }
}

export default inject('userStore', 'merchantStore')(observer(EntityList));

import gql from 'graphql-tag';

import {
  DRAFT_WHOLESALE_ORDER_ITEM_FRAGMENT,
  DRAFT_WHOLESALE_ORDER_FRAGMENT,
  WHOLESALE_ORDER_PAYMENT_INFO_FRAGMENT
} from '../../queries/draft-order/draft-wholesale-order';

export const FINALISE_DRAFT_WHOLESALE_ORDER = gql`
  mutation FinaliseDraftWholesaleOrder(
    $where: WholesaleOrderWhereUniqueInput!
  ) {
    finaliseDraftWholesaleOrder(
      where: $where
    ) {
      id
    }
  }
`;

export const CANCEL_DRAFT_WHOLESALE_ORDER = gql`
  mutation CancelDraftWholesaleOrder(
    $where: WholesaleOrderWhereUniqueInput!
  ) {
    cancelDraftWholesaleOrder(
      where: $where
    ) {
      id
    }
  }
`;

export const UPDATE_DRAFT_WHOLESALE_ORDER = gql`
  mutation UpdateDraftWholesaleOrder(
    $where: WholesaleOrderWhereUniqueInput!
    $data: DraftWholesaleOrderUpdateInput!
  ) {
    updateDraftWholesaleOrder(
      where: $where,
      data: $data
    ) {
      ...DraftWholesaleOrderFragment
    }
  }
  ${DRAFT_WHOLESALE_ORDER_FRAGMENT}
`;

export const UPDATE_DRAFT_WHOLESALE_ORDER_ITEM = gql`
  mutation UpdateDraftWholesaleOrderItem(
    $orderItemId: ID
    $data: DraftWholesaleOrderUpdateInput!,
    $where: WholesaleOrderWhereUniqueInput!
  ) {
    updateDraftWholesaleOrder(
      data: $data,
      where: $where
    ) {
      ...WholesaleOrderPaymentInfoFragment
      items(
        where: {
          id: $orderItemId
        }
      ) {
        ...DraftWholesaleOrderItemFragment
      }
    }
  }
  ${WHOLESALE_ORDER_PAYMENT_INFO_FRAGMENT}
  ${DRAFT_WHOLESALE_ORDER_ITEM_FRAGMENT}
`;

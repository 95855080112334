import { Component, ReactNode } from 'react';

import { Location } from '@reach/router';
import debounce from 'lodash.debounce';
import posed, { PoseGroup } from 'react-pose';
import { Box } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import Icon from 'components/icon';

import { TOAST_CONFIG } from './toast.config';
import * as Styles from './toast.styles';
import * as Types from './toast.types';

export default class Toast extends Component<Types.ToastProps> {
  toastDuration = 5000;

  closeTimeout = debounce(() => this.closeToast(), this.toastDuration);

  state = {
    isVisible: false
  };

  componentDidMount(): void {
    this.displayToast();
  }

  componentWillUnmount(): void {
    this.closeTimeout.cancel();
  }

  setInitialState(): void {
    this.setState({
      isVisible: false
    });
  }

  displayToast(): void {
    this.setState({
      isVisible: true
    });
  }

  closeToast(): void {
    this.setState({
      isVisible: false
    });

    setTimeout(() => {
      this.props.closeToast(this.props.data.id);
    }, 500);
  }

  render(): ReactNode {
    const ToastTransition = posed.div(Styles.poseConfig(() => this.closeTimeout()));

    return (
      <Location>
        {(): JSX.Element => {
          return (
            <PoseGroup >
              {
                this.state.isVisible && [
                  <ToastTransition
                    key={this.props.data.id}
                    css={Styles.ToastTransitionStyles}
                  >
                    <Styles.StyledToast
                      role="alertdialog"
                      poseKey={this.props.data.id}
                      initialPose="enter"
                      onClick={(): void => this.closeTimeout.flush()}
                      config={TOAST_CONFIG[this.props.data.type]}
                      isModal={false}
                    >
                      { TOAST_CONFIG[this.props.data.type].title && (
                        <Box
                          css={textStyles.subhead}
                          mb="10px"
                        >
                          {TOAST_CONFIG[this.props.data.type].title}
                        </Box>
                      )}
                      <Box css={textStyles.body}>
                        {this.props.data.message}
                      </Box>
                      <Styles.ToastCloseIcon>
                        <Icon
                          iconName="cross-small"
                          pathFill={TOAST_CONFIG[this.props.data.type].textColor}
                        />
                      </Styles.ToastCloseIcon>
                    </Styles.StyledToast>
                  </ToastTransition>
                ]
              }
            </PoseGroup>
          );
        }}
      </Location>
    );
  }
}

import React, { ReactNode, Component } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { Merchant, UserRole } from 'generated-types.d';

import { Analytics } from 'lib';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';
import { gridGutter, colors } from 'utils/rebass-theme';

import CustomSiteService from 'features/settings/components/custom-site/services';
import SettingsTemplatePage from 'features/settings/components/template/settings-template-page';
import SettingsService from 'features/settings/services';

import Icon from 'components/icon';

import * as Styles from './custom-site-settings.styles';
import * as Types from './custom-site-settings.types';

class CustomSiteSettings extends Component<Types.CustomSiteSettingsProps> {
  private SettingsAnalytics = SettingsService.SettingsAnalytics;

  private customSiteService = CustomSiteService;

  componentDidMount(): void {
    this.customSiteService.getMerchantCustomSiteConfigs();
    Analytics.page(Analytics.FxPageEvent.SettingsCustomSiteSettings);
    this.SettingsAnalytics.onSectionView(Analytics.SettingsSection.CustomSiteSettings);
  }

  private triggerModal(merchant: Merchant): any {
    this.props.modalStore!.triggerModal({
      modalType: 'customSiteConfig',
      data: merchant
    });
  }

  private renderMerchantConfigsList = (): React.ReactNode => {
    return this.props.customSiteSettingsStore!.merchantList.map((merchant: Merchant) => {
      const { customSiteConfig } = merchant;
      const hasMailchimp = customSiteConfig && customSiteConfig.mailchimpInformation && customSiteConfig.mailchimpInformation.id;

      return (
        <Flex
          key={merchant.id}
          width={[1, 1, 1 / 2]}
          p={gridGutter / 2}
          css={css`align-items: stretch;`}
        >
          <Styles.CustomSiteConfigCard>
            <Styles.CardHeading>
              <Box>
                <Icon iconName="shop" />
              </Box>
              <Box
                flexGrow={2}
                pl="15px"
              >
                {merchant.title}
              </Box>
              <Box
                as="a"
                onClick={(): any => this.triggerModal(merchant)}
              >
                <Icon iconName="edit" />
              </Box>
            </Styles.CardHeading>
            <Box p="15px">
              <Flex pb="15px">
                <Box>
                  <Icon
                    styles={Styles.CustomConfigIconStyles}
                    iconName="id"
                  />
                </Box>
                <Styles.CustomConfigItem>
                  {customSiteConfig ? customSiteConfig.customSiteProjectId : '-'}
                </Styles.CustomConfigItem>
              </Flex>
              <Flex pb="15px">
                <Box>
                  <Icon
                    styles={Styles.CustomConfigIconStyles}
                    iconName="globe"
                  />
                </Box>
                <Styles.CustomConfigItem>
                  {customSiteConfig ? customSiteConfig.customSiteProjectUrl : '-'}
                </Styles.CustomConfigItem>
              </Flex>
              <Flex>
                <Box>
                  <Styles.ColourDot colour={hasMailchimp ? colors.emeral : colors.middleGrey} />
                </Box>
                <Styles.CustomConfigItem>
                  Mailchimp ({!hasMailchimp && 'Not '}connected)
                </Styles.CustomConfigItem>
              </Flex>
            </Box>
          </Styles.CustomSiteConfigCard>
        </Flex>
      );
    });
  };

  render(): ReactNode {
    return (
      <SettingsTemplatePage title="Custom Site configs">
        <FloomXRestrictedComponent
          restrictedRoles={[UserRole.TeamMember, UserRole.TeamOwner, UserRole.TeamAdmin]}
        >
          <Flex
            flexWrap="wrap"
            m="0 -15px"
          >
            {this.renderMerchantConfigsList()}
          </Flex>
        </FloomXRestrictedComponent>
      </SettingsTemplatePage>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  customSiteSettingsStore: stores.customSiteSettingsStore,
  modalStore: stores.modalStore
}))(observer(CustomSiteSettings));

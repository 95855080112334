import { FC } from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex, Text } from 'rebass';

import { AdjusterType } from 'generated-types.d';

import { CurrencyService, TimeService } from 'lib';

import { Card } from 'utils/css-mixins';
import { textStyles, colors } from 'utils/rebass-theme';

import { ProgressBar } from 'components/progress-bar/progress-bar.styles';

import { DiscountDetails, WholesaleDiscountsProps } from './credit-overview.types';

const WholesaleDiscounts: FC<WholesaleDiscountsProps> = ({ wholesaleSettingsStore, merchantStore }) => {
  const amountSpent = Number(wholesaleSettingsStore!.wholesaleGMV);
  const currencySymbol = merchantStore!.merchant?.currency;

  const renderDiscountTypeText = (type: AdjusterType): string => {
    switch (type) {
      case AdjusterType.ConsumerFee:
        return 'on your floom.com commission';

      case AdjusterType.WholesaleGmv:
        return 'on your wholesale purchases';

      default:
        return '';
    }
  };

  const renderSubscriptionPeriodText = (): string => {
    const subscription = merchantStore!.merchant?.subscription;

    if (subscription) {
      const { currentPeriodStartsAt, currentPeriodEndsAt } = subscription;
      const periodStartTimestamp = Date.parse(currentPeriodStartsAt);
      const periodEndTimestamp = Date.parse(currentPeriodEndsAt);

      if (periodStartTimestamp && periodEndTimestamp) {
        return `${TimeService.dateMonthShort(periodStartTimestamp)} - ${TimeService.dateMonthShort(periodEndTimestamp)}`;
      }
    }

    return '';
  };

  const currentDiscounts = (): DiscountDetails => {
    const tiers = wholesaleSettingsStore!.wholesaleTiers;
    const firstTier = tiers[0];
    const lastTier = tiers[tiers.length - 1];

    // not in a tier & there is next tier - haven't reached first tier
    if (amountSpent < +firstTier.min || amountSpent === 0) {
      return {
        currentDiscountText: '',
        nextDiscountText: `Spend ${CurrencyService.formatPrice(+firstTier.min - amountSpent, currencySymbol!)} to receive ${firstTier.adjusterPercent}% cashback ${renderDiscountTypeText(firstTier.adjusterAppliesTo)} 🎉`,
        progress: amountSpent / +firstTier.min * 100
      };
    }

    const reachedTierIndex = tiers.findIndex(tier => {
      if (tier && tier.min && tier.max) {
        return +tier.min <= amountSpent && +tier.max >= amountSpent;
      }

      return -1;
    });

    // in a tier & there is next tier - reached tier before last
    if (reachedTierIndex !== -1 && reachedTierIndex !== tiers.length - 1) {
      const currentTier = tiers[reachedTierIndex];
      const nextTier = tiers[reachedTierIndex + 1];
      const sumLeft = +nextTier.min - amountSpent;

      return {
        currentDiscountText: `${CurrencyService.formatPrice(amountSpent, currencySymbol!)} spent - you have already unlocked ${currentTier.adjusterPercent}% cashback ${renderDiscountTypeText(currentTier.adjusterAppliesTo)} this billing cycle`,
        nextDiscountText: `You're ${CurrencyService.formatPrice(sumLeft, currencySymbol!)} away from even more savings (${nextTier.adjusterPercent}% cashback)`,
        progress: ((amountSpent - +currentTier.min) / (+currentTier.max - +currentTier.min)) * 100
      };
    }

    // in a tier & there is no next tier - reached top
    if (amountSpent > +lastTier.min) {
      const { adjusterPercent, adjusterAppliesTo } = lastTier;

      return {
        currentDiscountText: `Congratulations, you will receive ${adjusterPercent}% cashback ${renderDiscountTypeText(adjusterAppliesTo)} this billing cycle`,
        nextDiscountText: '',
        progress: 100
      };
    }

    // never happens
    return {
      currentDiscountText: '',
      nextDiscountText: '',
      progress: 0
    };
  };

  if (!wholesaleSettingsStore?.wholesaleTiers?.length) {
    return null;
  }

  const { currentDiscountText, nextDiscountText, progress } = currentDiscounts();

  return (
    <Box
      css={Card}
      p="30px"
    >
      <Flex
        mb="20px"
        alignItems="center"
      >
        <Text css={textStyles.h3}>
          Cashback offer
        </Text>
        <Text
          css={textStyles.body}
          ml="10px"
        >
          {renderSubscriptionPeriodText()}
        </Text>
      </Flex>
      <Box>
        <Text css={textStyles.body}>
          {currentDiscountText}
        </Text>
        <ProgressBar
          width={progress}
          size="medium"
          backgroundColour={colors.paleGrey}
          barColour={colors.emeral}
        />
        <Text
          css={textStyles.body}
          mt="5px"
        >
          {nextDiscountText}
        </Text>
      </Box>
    </Box>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  wholesaleSettingsStore: stores.wholesaleSettingsStore
}))(observer(WholesaleDiscounts));

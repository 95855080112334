import React, { ReactElement, ReactNode } from 'react';

import { Box } from 'rebass';

import WithLoading from 'components/with-loading';

import ChannelCheckboxList from '../channel-checkbox-list';
import CheckboxList from '../checkbox-list';
import { TabChildProps } from '../filters/filters.types';

import { CheckboxListFactoryType, CheckboxListProps } from './checkbox-list.types';

export const getCheckboxList = (
  factoryType: CheckboxListFactoryType,
  props: CheckboxListProps
): ReactElement => {
  switch (factoryType.type) {
    case 'channel':
      return <ChannelCheckboxList {...props} />;

    default:
      return <CheckboxList {...props} />;
  }
};

export const renderContainer = (
  factoryType: CheckboxListFactoryType,
  children: ReactElement<any>
): ReactElement<TabChildProps> => {
  return (
    <Box
      key={factoryType.title}
      label={factoryType.title}
      hasFiltersApplied={factoryType.hasFiltersApplied}
    >
      <WithLoading
        loaderSize="small"
        marginTop="30px"
        hasNoResults={!factoryType.isLoading && children && !children.props.items.length}
        isLoading={factoryType.isLoading}
        renderNoResults={(): ReactNode => (
          `No Filters for ${factoryType.title}`
        )}
      >
        {children}
      </WithLoading>
    </Box>
  );
};

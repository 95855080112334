import React, { ComponentType, ReactNode } from 'react';

import { css } from '@emotion/react';
import { useScrollRestoration } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  CollectionSellerProductConfig,
  PlanFeature,
  Product
} from 'generated-types.d';

import {
  Analytics,
  CollectionProductConfigService,
  MerchantService,
  NavService,
  PermissionsService,
  ProductService
} from 'lib';

import {
  BodyLink,
  Container
} from 'utils/css-mixins';
import { colors } from 'utils/rebass-theme';

import potIcon from 'assets/images/plant-pot-small.svg';

import { EntitySelectorModalData } from 'features/modal-dialogue/components/modals/entity-selector-modal/entity-selector.types';
import OrderCapBanner from 'features/order-cap-banner';
import GridLayout from 'features/products/components/grid-layout';
import ProductControls from 'features/products/components/product-controls';
import ProductGrouping from 'features/products/components/product-grouping';
import TableLayout from 'features/products/components/table-layout';
import ProductServices from 'features/products/services';
import ProductAPIService from 'features/products/services/product-api/product-api.service';
import { renderActiveProductsWarning } from 'features/products/utils/product-copy-helpers';

import AccountChangeNotification from 'components/account-change-notification';
import Button from 'components/button';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import PageHeading from 'components/page-heading';

import { ScrollContainer } from './product-list.styles';
import * as Types from './product-list.types';

const PageScroll = React.forwardRef<HTMLDivElement, Types.PageScrollProps>(({ children }, ref) => {
  const scrollRestoration = useScrollRestoration(`products-list`);

  return (
    <ScrollContainer
      onScroll={scrollRestoration.onScroll}
      ref={node => {
        if (node) {
          scrollRestoration.ref.current = node;

          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            ref.current = node;
          }
        }
      }}
    >
      {children}
    </ScrollContainer>
  );
});

class ProductList extends React.Component<Types.ProductListProps, Types.ProductListState> {
  private ProductListService = ProductServices.ProductListService;

  state = {
    isActiveToggling: false
  };

  scrollContainerRef = React.createRef<HTMLDivElement>();

  componentDidMount(): void {
    if (!this.props.location?.state?.persist || !this.props.productsStore?.products?.allProducts) {
      this.ProductListService.init();

      if (this.scrollContainerRef?.current) {
        this.scrollContainerRef.current.scrollTop = 0;
      }
    }
    this.redirect();

    Analytics.page(Analytics.FxPageEvent.ProductsList);
  }

  private redirect = (): void => {
    if (!this.props.path.includes('list')) {
      NavService.productList();
    }
  };

  private renderListComponent = (): ReactNode => {
    switch (this.props.productsStore!.layoutOption) {
      case 'grid':
        return GridLayout;

      case 'table':
        return TableLayout;
    }
  };

  private toggleActiveState = async (isChecked: boolean, item: Product): Promise<void> => {
    this.setState({ isActiveToggling: true });

    let result: CollectionSellerProductConfig | Product;
    const isCollectionManagerMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionManager, this.props.merchantStore!.merchant);

    try {
      if (ProductService.isTemplateProduct(item) && !isCollectionManagerMerchant) {
        const config = item.collectionSellerProductConfigs?.[0];

        result = await CollectionProductConfigService.upsertProductConfig(
          item,
          this.props.merchantStore!.merchant!,
          isChecked,
          config
        );
      } else {
        result = await ProductAPIService.toggleActiveStatus(item.id, isChecked);
      }

      if (result.active === false && isChecked === true) {
        await this.triggerProductModal(item);
      } else {
        this.props.merchantStore!.setHasProducts();
      }

      await this.ProductListService.fetchAllProducts();

      this.setState({
        isActiveToggling: false
      });
    } catch (error) {
      this.setState({ isActiveToggling: false });
    }
  };

  private fetchModalProductItems = async (item: Product): Promise<Product[]> => {
    const products = await ProductService.fetchAllProducts({
      active: true,
      merchant: {
        id: item.merchant.id
      }
    });

    return products.map(product => product.node);
  };

  private fetchModalProductConfigItems = async (): Promise<Product[]> => {
    const productConfigs = await CollectionProductConfigService.fetchAllProductConfigs({
      active: true,
      merchant: {
        id: this.props.merchantStore!.merchant!.id
      }
    });

    return productConfigs.map(config => {
      return {
        ...config.node.sourceProduct,
        collectionSellerProductConfigs: [config.node]
      };
    });
  };

  private triggerProductModal = async (product: Product): Promise<void> => {
    let resolver: any = null;

    const isTemplateProduct = ProductService.isTemplateProduct(product);

    const allItems = isTemplateProduct
      ? await this.fetchModalProductConfigItems()
      : await this.fetchModalProductItems(product);

    const productLimit = isTemplateProduct
      ? this.props.merchantStore!.merchant!.subscription!.productLimit!
      : product.merchant!.subscription!.productLimit!;

    this.props.modalStore!.triggerModal<EntitySelectorModalData>({
      modalType: 'entitySelector',
      data: {
        initialSelectedIDs: [],
        allItems: allItems.map(itemProduct => ({
          title: itemProduct.title,
          id: itemProduct.id,
          mediaSource: itemProduct.variations?.[0].media?.[0]?.src || '',
          altTitle: itemProduct.title
        })),
        modalProps: {
          title: 'Too many active products',
          confirmButtonText: 'Turn product off'
        },
        notificationCopy: renderActiveProductsWarning(
          productLimit,
          product.title
        ),
        canConfirm: (): boolean => true,
        onClose: (): void => {
          resolver?.();
        },
        selectItems: async (productIDsToDisable): Promise<void> => {
          const productToDisable = allItems.find(item => item.id === productIDsToDisable[0]);

          if (ProductService.isTemplateProduct(product)) {
            await CollectionProductConfigService.upsertProductConfig(
              productToDisable!,
              this.props.merchantStore!.merchant!,
              false,
              productToDisable?.collectionSellerProductConfigs?.[0]
            );
          } else {
            await ProductAPIService.toggleActiveStatus(productIDsToDisable[0], false);
          }

          await this.toggleActiveState(true, product);
          this.props.merchantStore!.setHasProducts();
          resolver?.();
          this.props.toasterStore!.popNotificationToast(`You've disabled ${productToDisable?.title || 'a product'} successfully, And set ${product.title} to active.`);
        },
        renderExtraAction: (modalInstance): any => (
          <>
            <Box
              onClick={(): void => {
                modalInstance.props.closeModal();
                resolver?.();
              }}
            >
              <Button
                size="normal"
                appearance="secondary"
                copy="Go back"
                isLoading={false}
                isDisabled={false}
              />
            </Box>
          </>
        )
      }
    });

    await new Promise((resolve): void => {
      resolver = resolve;
    });

    this.setState({ isActiveToggling: false });
  };

  private isCollectionSeller = (): boolean => {
    return !!this.props.merchantStore!.merchant
      && MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.merchantStore!.merchant);
  };

  private isCreateDisabled = (): boolean => {
    const { merchant, isLoadingMerchant } = this.props.merchantStore!;

    return PermissionsService.isInternalRole() && !isLoadingMerchant && !merchant;
  };

  private renderButtonCopy = (): string => {
    const isInternalRole = PermissionsService.isInternalRole();
    const { isLoadingMerchant } = this.props.merchantStore!;

    switch (true) {
      case isLoadingMerchant:
        return 'Create product';

      case this.isCreateDisabled():
        return 'Select a merchant';

      case isInternalRole && this.isCollectionSeller():
        return 'Create not supported (Lite 2)';

      default:
        return 'Create product';
    }
  };

  private handleCreate = (): void => {
    if (!this.isCollectionSeller()) {
      this.props.productEditStore!.toggleProductCreateTypeModal();
    }
  };

  private shouldDisplayButton = (): boolean => {
    const isLoadingMerchant = this.props.merchantStore!.isLoadingMerchant;

    return (!this.isCollectionSeller() || PermissionsService.isInternalRole()) && !isLoadingMerchant;
  };

  private buildProductAssistanceCopy = (): ReactNode => {
    const isCollectionMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.merchantStore!.merchant);

    if (isCollectionMerchant) {
      return (
        <>
          You’re just a few steps away from selling! Choose your products below. Not sure how? No sweat, we’ve created a
          {' '}
          <a
            href="https://floomx.zendesk.com/hc/en-us/articles/360051771711-Adding-products-from-the-Floom-collection"
            target="_blank"
            css={BodyLink}
            rel="noreferrer"
          >
            handy video
          </a>
          {' '}
          to help guide you through.
        </>
      );
    }

    return (
      <>
        You’re just a few steps away from selling!
        {' '}
        <button
          onClick={this.handleCreate}
          css={BodyLink}
        >
          Click here
        </button>
        {' '}
        to get adding or watch this
        {' '}
        <a
          href="https://floomx.zendesk.com/hc/en-us/articles/360042916711"
          target="_blank"
          css={BodyLink}
          rel="noreferrer"
        >
          handy video
        </a>
        {' '}
        to help guide you through.
      </>
    );
  };

  private shouldHideAssistance = (): boolean => {
    const isInternalUser = PermissionsService.isInternalRole();
    const { hasProducts, isLoadingMerchant } = this.props.merchantStore!;

    return isInternalUser || (!isLoadingMerchant && hasProducts);
  };

  private renderProductAssistance = (): ReactNode => {
    if (this.shouldHideAssistance()) return null;

    return (
      <Container>
        <Box mt="20px">
          <Notification
            type={NotificationType.Banner}
            hasIcon={true}
            hasClose={false}
            customIcon={potIcon}
            textAlign="left"
            copy={(
              <Box
                css={css`
                  font-weight: 700;
                  font-size: 16px;
                  color: ${colors.floomMidnightBlue}
                `}
              >
                {this.buildProductAssistanceCopy()}
              </Box>
            )}
          />
        </Box>
      </Container>
    );
  };

  render(): ReactNode {
    const ListComponent = this.renderListComponent() as ComponentType<Pick<Types.BaseProductListLayoutProps, 'onActiveStateChange' | 'isMakingChanges'>>;
    const isLoadingMerchant = this.props.merchantStore!.isLoadingMerchant;
    const isCreateDisabled = this.isCreateDisabled();
    const isCollectionSeller = this.isCollectionSeller();

    return (
      <PageScroll ref={this.scrollContainerRef}>
        <AccountChangeNotification
          wrapElement={(children): ReactNode => {
            return (
              <Container>
                <Box m="20px 0">
                  {children}
                </Box>
              </Container>
            );
          }}
        />
        <PageHeading titleText="Products">
          {this.shouldDisplayButton() && (
            <button
              onClick={this.handleCreate}
              disabled={isLoadingMerchant || isCreateDisabled || isCollectionSeller}
            >
              <Flex
                alignItems="center"
              >
                <Button
                  copy={this.renderButtonCopy()}
                  isDisabled={isCreateDisabled || isCollectionSeller}
                  isLoading={isLoadingMerchant}
                  size="small"
                  appearance="primary"
                />
              </Flex>
            </button>
          )}
        </PageHeading>
        <Box mb="2em">
          <OrderCapBanner />
        </Box>
        <ProductGrouping />
        <ProductControls />
        {this.renderProductAssistance()}
        <ListComponent
          onActiveStateChange={this.toggleActiveState}
          isMakingChanges={this.state.isActiveToggling}
        />
      </PageScroll>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productsStore: stores.productsStore,
  merchantStore: stores.merchantStore,
  productEditStore: stores.productEditStore,
  toasterStore: stores.toasterStore,
  modalStore: stores.modalStore
}))(observer(ProductList));

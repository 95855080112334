import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors, fonts } from 'utils/rebass-theme';

const borderRadius = '4px';
const borderWidth = '2px';
const transition = '.1s ease-in-out';

export const HideNativeInput = css`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const CheckboxToggleLabel = styled.label<{ isDisabled: boolean }>`
  position: relative;
  display: flex;
  opacity: ${({ isDisabled }): string => isDisabled ? '0.5' : '1'};

  input:checked {
    + ${(): any => ToggleSlider} {
      &:before {
        transform: translateX(-100%);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: ${borderRadius};
        border-bottom-left-radius: ${borderRadius};
      }
    }
  }
`;

export const ToggleSlider: any = styled(Flex)`
  position: relative;
  cursor: ${({ isDisabled }): string => isDisabled ? 'not-allowed' : 'pointer'};
  background-color: ${colors.uiFills};
  border: ${borderWidth} solid ${colors.middleGrey};
  border-radius: ${borderRadius};
  user-select: none;
  z-index: 0;
  padding: 2px 6px;

  > div {
    font-family: ${fonts.secondary};
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    transition: color ${transition};
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    height: calc(100% + 4px);
    width: calc(50% + ${borderWidth});
    top: -${borderWidth};
    bottom: -${borderWidth};
    right: -${borderWidth};
    border-top-right-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
    transition: background-color ${transition},
                transform ${transition},
                border-radius ${transition};
  }
`;

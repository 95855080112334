import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react';

import { WholesaleOrderLineItem } from 'generated-types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { CurrencyService } from 'lib';

import DraftWholesaleOrderStore from 'stores/wholesale/draft-wholesale-order-store/draft-wholesale-order-store';

import { colors } from 'utils/rebass-theme';

import { Input } from '../manage-order.styles';

export interface ManageOrderItemPriceProps {
  item: WholesaleOrderLineItem;
  draftWholesaleOrderStore?: DraftWholesaleOrderStore;
}

const ManageOrderItemPriceView: FC<ManageOrderItemPriceProps> = ({
  item,
  draftWholesaleOrderStore
}) => {
  const [controlledPrice, setPrice] = useState('');
  const priceRef = useRef<HTMLInputElement>(null);
  const isDisabled = !!draftWholesaleOrderStore?.orderItemIdsBeingUpdated?.has?.(item.id);
  const merchantCurrency = draftWholesaleOrderStore?.selectedOrder?.merchant?.currency;

  useEffect(() => {
    if (!!item.unitPrice || item.unitPrice === 0) {
      setPrice(item.unitPrice?.toFixed(2));
    }
  }, [item.unitPrice]);

  const handleReturn = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      updatePrice();
      priceRef?.current?.blur?.();
    }
  };

  const handlePriceBlur = (): void => {
    if (`${item.unitPrice}` !== controlledPrice) {
      updatePrice();
    }
  };

  const updatePrice = async (): Promise<void> => {
    const parsedPrice = ((): number => {
      const price = parseFloat(controlledPrice);

      if (price < 0) {
        setPrice('0');

        return 0;
      }

      return price;
    })();

    await draftWholesaleOrderStore?.updateOrderItem({
      orderId: draftWholesaleOrderStore!.selectedOrder!.id!,
      orderItemId: item.id,
      data: {
        unitPrice: parsedPrice
      }
    });
  };

  const handleUpdatePrice = (e: ChangeEvent<HTMLInputElement>): void => {
    setPrice(e.currentTarget.value);
  };

  return (
    <Box>
      <Box
        as="span"
        mr="2px"
        color={isDisabled ? colors.middleGrey : colors.floomMidnightBlue}
      >
        {CurrencyService.renderCurrencySymbol(merchantCurrency!)}
      </Box>
      <Input
        ref={priceRef}
        value={controlledPrice}
        placeholder="Unit Price"
        type="number"
        min={0}
        disabled={isDisabled}
        onChange={handleUpdatePrice}
        onBlur={handlePriceBlur}
        onKeyDown={handleReturn}
      />
    </Box>
  );
};

export const ManageOrderItemPrice = inject((stores: FxStores): InjectedFxStores => ({
  draftWholesaleOrderStore: stores.draftWholesaleOrderStore
}))(observer(ManageOrderItemPriceView));

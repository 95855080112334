import { FC } from 'react';

import { Box } from 'rebass';

import { Container } from 'utils/css-mixins';

import NoResultsGeneric from 'components/no-results-generic';

export const OrdersCourier: FC = () => {
  return (
    <Container>
      <Box mt="30px">
        <NoResultsGeneric
          icon="leaf-no-results"
          heading="Floom are no longer offering courier services"
          copy="If you need help regarding this, please email florists@floom.com"
        />
      </Box>
    </Container>
  );
};

import styled from '@emotion/styled';
import { Flex, Text } from 'rebass';

import { colors, textStyles, space, borderRadius } from 'utils/rebass-theme';

export const OrderRow = styled(Flex)`
  width: 100%;

  + ${(): any => OrderRow} {
    margin-top: 16px;
  }
`;

export const OrderRowTitle = styled(Text)<any>`
  ${textStyles.body};
  text-decoration: ${({ onClick }): string => onClick ? 'underline' : 'none'};
  font-weight: bold;
  margin-left: ${space[3]}px;
  flex-grow: 2;
  cursor: ${({ onClick }): string => onClick ? 'pointer' : 'normal'};
`;

export const OrderRowLabelWrap = styled(Text)<any>`
  background: ${({ label }): any => label.colour || colors.black};
  border-radius: ${borderRadius.small};
  overflow-x: hidden;
  max-width: 95px;
  padding: 5px;
  white-space: nowrap;
`;

export const OrderRowValue = styled(Text)<any>`
  ${textStyles.body};
  margin-left: ${space[3]}px;
`;

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const MerchantToggleWrapper = styled.div`
  ${textStyles.body};
  margin-bottom: 12px;
`;

export const MerchantCardWrapper = styled(Flex)`
  ${textStyles.subhead};
  align-items: center;
  height: 26px;
  margin-left: 4px;
  margin-right: 4px;
  p {
    margin-bottom: 0px;
    margin-left: 10px;
  }
`;

export const DownChevron = styled.div`
  margin-left: auto;
`;

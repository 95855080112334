import React, { memo, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Flex, Box } from 'rebass';

import {
  OrdersListLayout
} from 'stores/orders/orders-store.types';

import {
  gridGutter
} from 'utils/rebass-theme';

import {
  OrderGroupOption
} from 'features/orders/orders.types';

import {
  Pagination
} from 'components/algolia';
import {
  EntityListItems
} from 'components/entity-list/entity-list.styles';
import TableLayoutEntityHeading from 'components/entity-list/table-layout-entity-heading';

import {
  ORDER_ITEM_HEADING_CONFIG
} from '../order-list.constants';

import {
  OrderItems
} from './order-items';

interface OrderListLayoutProps {
  groupName: OrderGroupOption;
  layoutOption: OrdersListLayout;
  refresh: boolean;
  onRefresh: () => void;
  onPaginate: () => void;
}

export const OrderListLayout = memo(({
  layoutOption,
  groupName,
  refresh,
  onRefresh,
  onPaginate
}: OrderListLayoutProps) => {
  switch (layoutOption) {
    case 'grid':
      return (
        <OrderItems
          // @ts-ignore
          groupName={groupName}
          layoutOption={layoutOption}
          shouldRefresh={refresh}
          onRefresh={onRefresh}
          renderWrapper={(children: ReactNode): ReactNode => {
            return (
              <>
                <Flex
                  flexWrap="wrap"
                  m={-gridGutter / 2}
                >
                  {children}
                </Flex>
                <Pagination handlePaginate={onPaginate} />
              </>
            );
          }}
        />
      );

    case 'table':
      return (
        <OrderItems
          groupName={groupName}
          layoutOption={layoutOption}
          shouldRefresh={refresh}
          onRefresh={onRefresh}
          renderWrapper={(children: ReactNode): ReactNode => {
            return (
              <Box
                css={css`
                  overflow-x: auto;
                  padding: 10px;
                  margin: -20px;
                `}
              >
                <Box
                  css={{
                    minWidth: '950px',
                    padding: '0 10px'
                  }}
                >
                  <TableLayoutEntityHeading headers={ORDER_ITEM_HEADING_CONFIG} />
                  <EntityListItems>
                    {children}
                  </EntityListItems>
                  <Pagination handlePaginate={onPaginate} />
                </Box>
              </Box>
            );
          }}
        />
      );
  }
});

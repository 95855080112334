import { gql } from '@apollo/client';

import {
  GraphQLHelpers
} from 'lib';

import {
  FULL_LIST_ITEM_FRAGMENT,
  LIST_ITEM_WITHOUT_AVAILABILITY_FRAGMENT
} from 'features/lists/graphql/fragments/list.fragments';
import {
  ORDER_LIST_CONFIG,
  ORDER_LIST_ITERATION_COUNT
} from 'features/wholesale/wholesale.constants';
import {
  WholesaleOrderCounts
} from 'features/wholesale/wholesale.types';

export const WHOLESALE_ORDER_CONNECTION_FRAGMENT = gql`
  fragment WholesaleOrderFragment on WholesaleOrder {
    id
    status
    paymentStatus
    wholesaleOrderRef
    totalPrice
    createdAt
    processedAt
    paidAt
    wholesaleDeliveryConfigSnapshot
    merchant {
      id
      title
      currency
      subscription {
        wholesaleTermLength
      }
      paymentMethod {
        type
        brand
        lastFourCardDigits
        isExpiring
        expiryMonth
        expiryYear
      }
    }
    deliveryDetails {
      id
      deliveryDate
      status
      costIncluded
      costValue
      deliveredOnDate
      order {
        id
      }
    }
    tax {
      amount
      name
      inclusive
    }
    items(
      orderBy: createdAt_DESC
    ) {
      id
      createdAt
      quantity
      totalPrice
      unitPrice
      snapshot
      wholesalerLineItemRef
      wholesalerTrackingRef
      listItem {
        ...FullListItemFragment
      }
    }
    credits(
      orderBy: createdAt_DESC
    ) {
      id
      createdAt
      totalPrice
    }
    invoice {
      id
      url
      payment {
        id
      }
    }
    supplier {
      id
      name
      slug
    }
  }
  ${FULL_LIST_ITEM_FRAGMENT}
`;

export const WHOLESALE_ORDER_WITHOUT_LIST_ITEM_AVAILABILITY_CONNECTION_FRAGMENT = gql`
  fragment WholesaleOrderWithoutListItemAvailabilityFragment on WholesaleOrder {
    id
    status
    paymentStatus
    wholesaleOrderRef
    totalPrice
    createdAt
    processedAt
    paidAt
    wholesaleDeliveryConfigSnapshot
    merchant {
      id
      title
      currency
      subscription {
        wholesaleTermLength
      }
      paymentMethod {
        type
        brand
        lastFourCardDigits
        isExpiring
        expiryMonth
        expiryYear
      }
    }
    deliveryDetails {
      id
      deliveryDate
      status
      costIncluded
      costValue
      deliveredOnDate
      order {
        id
      }
    }
    tax {
      amount
      name
      inclusive
    }
    items(
      orderBy: createdAt_DESC
    ) {
      id
      createdAt
      quantity
      totalPrice
      unitPrice
      snapshot
      wholesalerLineItemRef
      wholesalerTrackingRef
      listItem {
        ...ListItemWithoutAvailabilityFragment
      }
    }
    credits(
      orderBy: createdAt_DESC
    ) {
      id
      createdAt
      totalPrice
    }
    invoice {
      id
      url
      payment {
        id
      }
    }
    supplier {
      id
      name
    }
  }
  ${LIST_ITEM_WITHOUT_AVAILABILITY_FRAGMENT}
`;

export const WHOLESALE_ORDER_DASHBOARD_CONNECTION_FRAGMENT = gql`
  fragment WholesaleOrderDashboardFragment on WholesaleOrder {
    id
    status
    paymentStatus
    wholesaleOrderRef
    totalPrice
    createdAt
    processedAt
    paidAt
    wholesaleDeliveryConfigSnapshot
    merchant {
      id
      title
      currency
    }
    deliveryDetails {
      id
      deliveryDate
      status
      costIncluded
      costValue
      deliveredOnDate
      order {
        id
      }
    }
    tax {
      amount
      name
      inclusive
    }
    items(
      orderBy: createdAt_DESC
    ) {
      id
      createdAt
      quantity
      totalPrice
      unitPrice
      snapshot
      wholesalerLineItemRef
      wholesalerTrackingRef
    }
    credits(
      orderBy: createdAt_DESC
    ) {
      id
      createdAt
      totalPrice
    }
    invoice {
      id
      url
      payment {
        id
      }
    }
    supplier {
      id
      name
    }
  }
`;

export const WHOLESALE_ORDER = gql`
  query WholesaleOrder(
    $id: ID!
  ) {
    wholesaleOrder(
      where: {
        id: $id
      }
    ) {
      ...WholesaleOrderWithoutListItemAvailabilityFragment
    }
  }
  ${WHOLESALE_ORDER_WITHOUT_LIST_ITEM_AVAILABILITY_CONNECTION_FRAGMENT}
`;

export const FINALISE_WHOLESALE_ORDER = gql`
  mutation FinaliseWholesaleOrder(
    $where: WholesaleOrderWhereUniqueInput
  ) {
    finaliseWholesaleOrder(
      where: $where
    ) {
      ...WholesaleOrderFragment
    }
  }
  ${WHOLESALE_ORDER_CONNECTION_FRAGMENT}
`;

export const CURRENT_OPEN_ORDERS = gql`
  query CurrentWholesaleOrders(
    $first: Int = 2
    $orderBy: WholesaleOrderOrderByInput = createdAt_ASC
    $where: WholesaleOrderWhereInput = {}
  ) {
    wholesaleOrders(
      first: $first,
      where: $where,
      orderBy: $orderBy
    ) {
      ...WholesaleOrderFragment
    }
  }
  ${WHOLESALE_ORDER_CONNECTION_FRAGMENT}
`;

export const WHOLESALE_ORDERS = (merchantId: any, counts: WholesaleOrderCounts): any => {
  return gql`
    query WholesaleOrders
    ${merchantId && `(${GraphQLHelpers.addVariable(merchantId, 'merchantId', 'ID!')})`} {
      ${Object.keys(ORDER_LIST_CONFIG).map(orderState => `
        ${orderState}: wholesaleOrdersConnection(
          first: ${ORDER_LIST_ITERATION_COUNT},
          orderBy: createdAt_DESC
          skip: ${counts[orderState]}
          where: {
            ${GraphQLHelpers.addMerchantWhereFilter(merchantId)}
            status: ${orderState}
          }
        ) {
          pageInfo {
            hasNextPage
          }
          aggregate {
            count
          }
          edges {
            node {
              ...WholesaleOrderDashboardFragment
            }
          }
        }
      `)}
    }
    ${WHOLESALE_ORDER_DASHBOARD_CONNECTION_FRAGMENT}
  `;
};

export const WHOLESALE_ORDER_PAGINATION = (merchantId: any): any => {
  return gql`
    query WholesaleOrders(
      ${GraphQLHelpers.addVariable(merchantId, 'merchantId', 'ID!')}
      $skip: Int
      $orderState: WholesaleOrderStates
    ) {
      ${`
        wholesaleOrdersConnection(
          first: ${ORDER_LIST_ITERATION_COUNT},
          orderBy: createdAt_DESC
          skip: $skip
          where: {
            ${GraphQLHelpers.addMerchantWhereFilter(merchantId)}
            status: $orderState
          }
        ) {
          pageInfo {
            hasNextPage
          }
          aggregate {
            count
          }
          edges {
            node {
              ...WholesaleOrderDashboardFragment
            }
          }
        }
      `}
    }
    ${WHOLESALE_ORDER_DASHBOARD_CONNECTION_FRAGMENT}
  `;
};

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  padding: 20px;
  position: relative;
  overflow: hidden;
  height: 100%;
`;

export const DeleteButton = styled(Flex)`
  ${textStyles.footnote}
  margin-right: 5px;
  align-items: center;
`;

export const Spacer = styled.span`
  margin: 0 10px;
  height: 2em;
  border-left: 1px solid ${colors.middleGrey};
`;

import {
  FC,
  Fragment,
  useState,
  MouseEvent
} from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';

import { WholesaleOrderLineItem } from 'generated-types.d';

import ToasterStore from 'stores/toaster-store/toaster-store';
import DraftWholesaleOrderStore from 'stores/wholesale/draft-wholesale-order-store/draft-wholesale-order-store';

import { colors } from 'utils/rebass-theme';

import { ConfirmDeleteTooltip } from 'features/lists/components/confirm-delete-tooltip/confirm-delete-tooltip';

import Icon from 'components/icon';

import { DeleteWrapper } from '../manage-order.styles';

export interface ManageOrderItemDeleteViewProps {
  item: WholesaleOrderLineItem;
  draftWholesaleOrderStore?: DraftWholesaleOrderStore;
  toasterStore?: ToasterStore;
}

const ManageOrderItemDeleteView: FC<ManageOrderItemDeleteViewProps> = ({
  item,
  draftWholesaleOrderStore,
  toasterStore
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = (event: MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const handleDeleteItem = async (): Promise<void> => {
    try {
      await draftWholesaleOrderStore?.deleteOrderItem({
        orderId: draftWholesaleOrderStore!.selectedOrder!.id!,
        orderItemId: item.id
      });

      toasterStore?.popSuccessToast('The line item', 'remove');
    } catch (error) {
      toasterStore?.popSuccessToast('the line item', 'remove');
    }
  };

  return (
    <Fragment>
      <DeleteWrapper onClick={handleClick}>
        <Icon
          iconName="bin"
          styles={css`
            color: ${colors.floomMidnightBlue}
          `}
        />
      </DeleteWrapper>
      <ConfirmDeleteTooltip
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        onDeleteItem={handleDeleteItem}
      />
    </Fragment>
  );
};

export const ManageOrderItemDelete = inject((stores: FxStores): InjectedFxStores => ({
  draftWholesaleOrderStore: stores.draftWholesaleOrderStore,
  toasterStore: stores.toasterStore
}))(observer(ManageOrderItemDeleteView));

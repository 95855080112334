import React, { ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Text } from 'rebass';

import { Currency, Merchant, PlanFeature } from 'generated-types.d';

import { MerchantService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import { getDeliveryConfigCutoffTimes } from 'features/settings/components/delivery/services/delivery-apollo.service';

import DropdownNative from 'components/dropdown-native';
import SectionHeading from 'components/section-heading';

import { CheckBoxWrapper } from '../../delivery-config-modal.styles';
import { LeadTimeCheckbox } from '../lead-time-checkbox';

import * as Types from './edit-lead-time.types';

class EditLeadTime extends React.Component<Types.EditLeadTimeProps> {
  private getMerchant = (): Merchant | null | undefined => {
    return this.props.deliveryStore!.formMode === 'edit'
      ? this.props.deliveryStore!.selectedDeliveryConfig?.merchant
      : this.props.merchantStore!.merchant;
  };

  private renderNormalLeadTime = (): ReactNode => {
    return (
      <LeadTimeCheckbox
        value={this.props.deliveryStore!.selectedLeadTime === 2}
        updateMethod={(x: any): any => this.props.deliveryStore!.setLeadTime(!!x ? 2 : 1)}
      >
        Orders have a 2 day lead time
      </LeadTimeCheckbox>
    );
  };

  private renderExtendedLeadTime = (): ReactNode => {
    return (
      <>
        <CheckBoxWrapper>
          <Text
            css={textStyles.body}
          >
            Extended lead time
          </Text>
          <DropdownNative
            optionTitleField="title"
            optionValueField="value"
            id="Lead times"
            options={[
              { title: '2 days', value: 2 },
              { title: '3 days', value: 3 },
              { title: '4 days', value: 4 }
            ]}
            selected={this.props.deliveryStore!.selectedLeadTime.toString()}
            fullWidth={false}
            onChange={(x): any => this.props.deliveryStore!.setLeadTime(parseInt(x))}
          />
        </CheckBoxWrapper>
      </>
    );
  }

  render(): ReactNode {
    const merchant = this.getMerchant();

    if (!merchant) return null;

    const isCollectionSellerMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, merchant);

    const canHaveExtendedLeadTimes = this.props.merchantStore!.merchant?.currency === Currency.Usd
      && this.props.merchantStore!.merchant?.canViewNationwide;

    return (
      <>
        <SectionHeading title="Lead time" />
        <LeadTimeCheckbox
          value={this.props.deliveryStore!.sameDayWeekday}
          updateMethod={(x: any): any => this.props.deliveryStore!.setSameDay('sameDayWeekday', x)}
        >
          Same day on <b>weekdays</b>
        </LeadTimeCheckbox>
        <LeadTimeCheckbox
          value={this.props.deliveryStore!.sameDayWeekend}
          updateMethod={(x: any): any => this.props.deliveryStore!.setSameDay('sameDayWeekend', x)}
        >
          Same day on <b>weekends</b>
        </LeadTimeCheckbox>
        {(!this.props.deliveryStore!.sameDayWeekend && !this.props.deliveryStore!.sameDayWeekday) && (
          canHaveExtendedLeadTimes ? this.renderExtendedLeadTime() : (!isCollectionSellerMerchant ? this.renderNormalLeadTime() : null)
        )}
        {(this.props.deliveryStore!.sameDayWeekend || this.props.deliveryStore!.sameDayWeekday) && (
          <>
            <CheckBoxWrapper>
              <Text
                css={textStyles.body}
              >
                Same day cut off at
              </Text>
              <DropdownNative
                optionTitleField="title"
                optionValueField="value"
                id="Cutoff times"
                options={getDeliveryConfigCutoffTimes()}
                selected={this.props.deliveryStore!.selectedCutoffTime.toString()}
                fullWidth={false}
                onChange={(x): any => this.props.deliveryStore!.updateBasicValue('selectedCutoffTime', parseInt(x))}
              />
            </CheckBoxWrapper>
            <Text css={textStyles.body}>
              Delivery must be made by <b>{moment((this.props.deliveryStore!.selectedCutoffTime + 4), 'HH').format('h')}pm</b>
            </Text>
          </>
        )}
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  deliveryStore: stores.deliveryStore,
  merchantStore: stores.merchantStore
}))(observer(EditLeadTime));

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import GenericModal from 'components/generic-modal';

import OrderReceiptContent from './order-receipt-content';
import * as Types from './order-receipt-modal.types';

class OrderReceiptModal extends Component<Types.OrderReceiptModalProps> {
  static defaultProps = {
    isOpen: false
  };

  render(): React.ReactNode | null {
    return (
      <GenericModal
        innerComponent={(
          <OrderReceiptContent />
        )}
        confirmButtonText="Print Receipt"
        title="Receipt"
        shouldHideFooter={true}
        modalOpen={this.props.isOpen}
        closeModal={this.props.ordersStore!.hideOrderReceipt}
      />
    );
  }
}

export default inject('ordersStore')(observer(OrderReceiptModal));

import { OrderDeliveryState } from 'generated-types.d';

class CourierHelpers {
  public hasErrorStatus = (state: OrderDeliveryState): boolean => {
    return !!state &&
      (
        state === OrderDeliveryState.Refused
        || state === OrderDeliveryState.Failed
        || state === OrderDeliveryState.Cancelled
      );
  };
}

export default new CourierHelpers();

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import {
  textStyles,
  colors,
  borderRadius,
  breakpoints,
  shadows
} from 'utils/rebass-theme';

import { CustomNotificationTheme, NotificationType } from './notification.types';

export const NotificationStyles: any = styled(Flex)<{
  type: NotificationType;
  textAlign: string;
  customTheme: CustomNotificationTheme;
}>`
  ${textStyles.body};
  padding: 10px 20px 11px;
  border-radius: ${borderRadius.medium};
  cursor: pointer;
  width: 100%;
  text-align: ${({ textAlign }): string => textAlign};
  flex-wrap: wrap;

  ${({ type, customTheme }): any => {
    if (customTheme) {
      return `
        color: ${customTheme.color};
        background: ${customTheme.background};
      `;
    }

    switch (type) {
      case NotificationType.Success:
        return `
          color: ${colors.validationText};
          background: ${colors.validationBg};
        `;

      case NotificationType.Error:
        return `
          color: ${colors.uiFills};
          background: ${colors.errorText};
        `;

      case NotificationType.Progress:
        return `
          color: ${colors.uiFills};
          background: ${colors.floomOrange};
        `;

      case NotificationType.Info:
        return `
          color: ${colors.floomMidnightBlue};
          background: ${colors.floomCyan};
        `;

      case NotificationType.Banner:
        return `
          color: ${colors.floomMidnightBlue};
          background: ${colors.white};
          box-shadow: ${shadows.large};
          padding: 20px;
        `;

      default:
        return `
          color: ${colors.uiFills};
          background: ${colors.errorText};
        `;
    }
  }}

  @media (max-width: ${breakpoints.medium}) {
    font-size: 12px;
    padding: 10px 15px 13px;
  }
`;

export const NotificationIcon: any = css`
  width: 15px;
  height: 15px;
  margin-right: 15px;
`;

export const ImageIcon = styled(Flex)`
  width: 45px;
  margin-right: 20px;
  align-items: center;
`;

export const IconWrapper = css`
  @media (max-width: ${breakpoints.medium}) {
    display: none;
  }
`;

export const NotificationCloseIcon = css`
  svg {
    width: 50px;
    height: 50px;
  }

  @media (max-width: ${breakpoints.medium}) {
    width: 20px;
  }
`;

import { Component, ReactNode } from 'react';

import { ModalConfig } from 'stores/modal-store/modal-store.types';

import { MODAL_COMPONENTS } from './modal-wrapper.config';

interface ModalWrapperProps {
  destroyModal: () => void;
  options: ModalConfig;
}

interface ModalWrapperState {
  isVisible: boolean;
}

export default class ModalWrapper extends Component<ModalWrapperProps, ModalWrapperState> {
  state = {
    isVisible: false
  };

  static defaultProps = {
    destroyModal: (): void => {}
  };

  componentDidMount = (): void => {
    this.displayModal();
  };

  private displayModal = (): void => {
    this.setState({
      isVisible: true
    });
  };

  private initialiseDestruction = (): void => {
    this.setState({
      isVisible: false
    }, () => {
      setTimeout(() => {
        this.props.destroyModal();
      }, 200);
    });
  };

  render(): JSX.Element {
    const ModalToRender: ReactNode = MODAL_COMPONENTS[this.props.options.modalType];

    return (
      // @ts-ignore
      <ModalToRender
        data={this.props.options.data}
        closeModal={this.initialiseDestruction}
        isOpen={this.state.isVisible}
      />
    );
  }
}

import { FC, Fragment } from 'react';

import { inject, observer } from 'mobx-react';

import WholesaleOrdersStore from 'stores/wholesale/wholesale-orders-store/wholesale-orders-store';

import {
  ListCardWrapper
} from 'features/lists/lists.styles';

import { SINGLE_ORDER_LIST_FIELD_CONFIG } from '../single-order.config';
import { SectionHeading } from '../single-order.styles';

import { SingleOrderItems } from './single-order-items';
import { SingleOrderTotals } from './single-order-totals';

export const SingleOrderBody: FC<{
  wholesaleOrdersStore?: WholesaleOrdersStore;
}> = inject((stores: FxStores) => ({
  wholesaleOrdersStore: stores.wholesaleOrdersStore
}))(observer(({
  wholesaleOrdersStore
}) => {
  if (!wholesaleOrdersStore!.selectedOrder) return null;

  const fieldConfig = SINGLE_ORDER_LIST_FIELD_CONFIG.Default;

  if (!fieldConfig) return null;

  const availableItems = wholesaleOrdersStore!.selectedOrder.items?.filter(item => item?.totalPrice ? item?.totalPrice > 0 : false);

  return (
    <Fragment>
      <SectionHeading>
        {availableItems?.length} items ordered
      </SectionHeading>
      <ListCardWrapper>
        <SingleOrderItems />
        <SingleOrderTotals />
      </ListCardWrapper>
    </Fragment>
  );
}));

import { ApolloQueryResult } from '@apollo/client';
import cuid from 'cuid';
import _kebabCase from 'lodash.kebabcase';
import store from 'stores';

import {
  Addon,
  Colour,
  ComponentConfig,
  DimensionsCreateOneInput,
  Maybe,
  Product,
  ProductCategory,
  ProductCreateInput,
  ProductType,
  ProductUpdateInput,
  ProductVariationCreateInput,
  ProductVariationCreateManyInput,
  ProductVariationType,
  ProductVariationUpdateDataInput,
  ProductVariationUpdateManyInput,
  ProductVariationUpdateWithWhereUniqueNestedInput,
  RecipeCreateOneInput,
  RecipeUpdateDataInput,
  RecipeUpdateOneInput,
  MerchantHolidayType
} from 'generated-types.d';

import {
  GraphQL,
  NavService,
  HTMLService,
  Analytics,
  ProductService
} from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import ProductEditStore from 'stores/product-edit-store/product-edit-store';
import { Mode, ProductTab, ProductVariation } from 'stores/product-edit-store/product-edit-store-types';
import ProductsStore from 'stores/products-store';
import ToasterStore from 'stores/toaster-store/toaster-store';
import { ToastType } from 'stores/toaster-store/toaster-store.types';
import UserStore from 'stores/user/user-store';

import { parseSlug } from 'utils/slugify';

import { CreateStep } from 'features/products/services/product-analytics/product-analytics.service';

import { GenericListObject } from 'components/generic-select-list/generic-select-list.types';

import {
  PRODUCT_CREATE,
  PRODUCT_EDIT,
  PRODUCT_VARIATION_DELETE
} from '../../graphql/mutations/create-edit-product.mutations';
import {
  ADDONS_QUERY,
  COLOURS_QUERY,
  COMPONENT_CONFIGS_QUERY,
  DELIVERY_OPTIONS,
  DUPLICATE_PRODUCT_TITLE
} from '../../graphql/queries/create-product/create-product-types.queries';
import ProductAnalytics from '../product-analytics/product-analytics.service';

import {
  GenericConnectClause,
  VariationType
} from './product-create-edit.service.types';

export default class ProductCreateEditApolloService {
  private productEditStore = store.productEditStore as ProductEditStore;

  private merchantStore = store.merchantStore as MerchantStore;

  private userStore = store.userStore as UserStore;

  private toasterStore = store.toasterStore as ToasterStore;

  private productsStore = store.productsStore as ProductsStore;

  private ProductAnalytics = new ProductAnalytics();

  newVariationID = 'new-variation';

  // @ts-ignore
  oldProduct: Product;

  originalState?: string;

  public async init(productId?: string, productTypeId?: string): Promise<any> {
    await this.getProductTypes();

    if (productId && !productId.startsWith('pre-create')) {
      await this.loadProductForEdit(productId);
      this.getDeliveryItems(this.productEditStore.productMerchant?.id);
    } else if (productTypeId) {
      await this.loadProductForCreate();
      this.productEditStore.init(productTypeId);
      this.productEditStore.disableProductLoading();
      this.getDeliveryItems(this.merchantStore.merchant?.id);
    } else {
      NavService.productList();
    }

    this.getAllAvailableColours();
    this.getAllAvailableComponents();
    this.getCategories();

    if (productId && productId.startsWith('pre-create') && productTypeId) {
      this.addNewVariation(ProductVariationType.Original, false, false);
    }
  }

  public setActive(page: ProductTab): void {
    this.productEditStore.setActiveTab(page);
  }

  public setCurrentVariation(variationId: string): void {
    this.productEditStore.setCurrentVariation(variationId);
  }

  public loadProductForCreate = async (): Promise<void> => {
    const merchant = this.merchantStore!.merchant;
    const activeProductCount = await ProductService.fetchActiveProductCountForMerchant(merchant!.id);
    const canActivateProduct = ProductService.canActivateProduct(
      merchant?.subscription?.productLimit || null,
      activeProductCount,
      merchant!.stage
    );

    this.productEditStore.setCanActivateProduct(canActivateProduct);
  };

  public async loadProductForEdit(productId: string): Promise<any> {
    this.productEditStore!.setEditMode();
    this.productEditStore!.setProductLoading();

    try {
      const product = await ProductService.fetchProduct(productId, this.userStore.merchantId);

      const activeProductCount = await ProductService.fetchActiveProductCountForMerchant(product.merchant.id);
      const canActivateProduct = ProductService.canActivateProduct(
        product.merchant?.subscription?.productLimit || null,
        activeProductCount,
        product.merchant.stage
      );

      this.productEditStore.setCanActivateProduct(canActivateProduct);
      this.productEditStore.setActiveProductCount(activeProductCount);
      this.oldProduct = product;
      this.productEditStore.importProduct(this.oldProduct);
      this.originalState = this.getProductCreateEditStoreState();
      this.productEditStore!.disableProductLoading();
    } catch (error) {
      this.productEditStore!.disableProductLoading();
      this.toasterStore.popToast('Oops! Looks like something went wrong viewing this product', ToastType.Error);
    }
  }

  public getAllAvailableComponents(): void {
    GraphQL.query(COMPONENT_CONFIGS_QUERY)
      .then(({ data }: any) => {
        const componentConfigs: ComponentConfig[] = data.componentConfigs;

        this.productEditStore.setComponentConfigs(componentConfigs.map((componentConfig): GenericListObject => {
          return {
            title: componentConfig.title!,
            id: componentConfig.id,
            mediaSource: componentConfig.media?.src || ''
          };
        }));
      });
  }

  public getAllAvailableAddons(): void {
    GraphQL.query(ADDONS_QUERY)
      .then(({ data }: any) => {
        const addons: Addon[] = data.addons;

        this.productEditStore.setAddons(addons.map((addon): GenericListObject => {
          return {
            title: addon.title!,
            id: addon.id,
            mediaSource: addon.media ? addon.media!.src : ''
          };
        }));
      });
  }

  public getAllAvailableColours(): void {
    GraphQL.query(COLOURS_QUERY).then(({ data }: ApolloQueryResult<{ colours: Colour[] }>) => {
      this.productEditStore.setColours(data.colours);
    });
  }

  public getDeliveryItems(merchantId: string | undefined): any {
    if (!merchantId) {
      return;
    }

    GraphQL.query(DELIVERY_OPTIONS, { merchantId }, 'no-cache')
      .then(({ data }: any) => {
        this.productEditStore.setDeliveryConfigs(data.deliveryConfigs);
      });
  }

  public getCategories(): void {
    ProductService.fetchProductCategories()
      .then((productCategories: ProductCategory[]) => {
        this.productEditStore.setCategories(productCategories);
      });
  }

  public async getProductTypes(): Promise<any> {
    return ProductService.fetchProductTypes()
      .then((productTypes: ProductType[]) => {
        this.productEditStore.setTypes(productTypes);
      });
  }

  public validateProductTitle = async (title: string, merchantId: string, productId: string | null): Promise<any> => {
    return GraphQL.query(DUPLICATE_PRODUCT_TITLE(productId), { title, merchantId })
      .then(({ data }: ApolloQueryResult<{ products: Product[] }>) => {
        this.productEditStore.setIsProductTitleADuplicate(data.products);
      });
  };

  public async saveProduct(shouldExit: boolean = false): Promise<void> {
    if (this.productEditStore.currentMode === Mode.create) {
      return GraphQL.mutate(PRODUCT_CREATE, { data: this.compileProductCreateInput() })
        .then(() => {
          this.originalState = this.getProductCreateEditStoreState();
          NavService.productList();
          this.productEditStore.toggleIsTabbing(false);
          this.merchantStore.setHasProducts();

          if (this.productEditStore!.currentMode === Mode.create) {
            this.ProductAnalytics.onCreateStep(CreateStep.Save, Analytics.FxEventName.ProductCreateStep, this.productEditStore.selectedProductTypeTitle);
          }
        })
        .catch(() => {
          this.toasterStore.popErrorToast(this.productEditStore.title, 'create');
          this.productEditStore.toggleIsTabbing(false);
        });
    }

    return GraphQL.mutate(PRODUCT_EDIT, {
      data: this.saveEditProduct(),
      productId: this.productEditStore.id
    })
      .then(async (result: any) => {
        this.originalState = this.getProductCreateEditStoreState();
        this.productEditStore.toggleIsTabbing(false);

        if (result && result.data) {
          this.oldProduct = result.data.editProduct;
          this.productEditStore.importProduct(this.oldProduct);

          if (shouldExit) {
            this.productsStore.updateEditedProduct(result.data.editProduct);
            NavService.productList({ persist: true });

            this.ProductAnalytics.onSave(
              this.productEditStore.selectedProductTypeTitle,
              CreateStep.Save
            );
          } else {
            const activeProductCount = await ProductService.fetchActiveProductCountForMerchant(this.oldProduct.merchant.id);
            const canActivateProduct = ProductService.canActivateProduct(
              this.oldProduct.merchant?.subscription?.productLimit || null,
              activeProductCount,
              this.oldProduct.merchant.stage
            );

            this.productEditStore.setCanActivateProduct(canActivateProduct);
          }
        }
      });
  }

  public deleteVariation = async (variationId: string): Promise<void> => {
    if (!this.productEditStore.variations.filter((variation: any) => variation.id === variationId)[0].new) {
      await GraphQL.mutate(PRODUCT_VARIATION_DELETE, {
        productVariationId: variationId
      });
    }

    this.productEditStore.deleteVariation(variationId);
    NavService.productEditVariation(this.productEditStore.id!, this.productEditStore.variations[0].id!);
  };

  public getAvailableVariations(): VariationType[] {
    const variationsAvailable: string[] = [];

    for (const type of [
      ProductVariationType.Original,
      ProductVariationType.Superior,
      ProductVariationType.Deluxe
    ]) {
      if (this.productEditStore.variations.filter((variation: any) => variation.type === type).length === 0) {
        variationsAvailable.push(type);
      }
    }

    return variationsAvailable.map(variationType => {
      return {
        type: variationType,
        slug: variationType
      };
    });
  }

  public getVariationsToDelete(): VariationType[] {
    const variationsToDelete = this.productEditStore.variations.filter((variation: any) => variation.type !== ProductVariationType.Original);

    return variationsToDelete.map((variation: any) => ({
      id: variation.id,
      type: variation.type,
      slug: variation.title
    }));
  }

  public addNewVariation(variationType: string, useDefaultTemplate: boolean = false, shouldNav: boolean = true): void {
    const newVariationSlug = `new-${_kebabCase(variationType.toLowerCase())}-variation`;

    this.productEditStore.addBlankVariation(
      ProductVariationType[variationType],
      newVariationSlug,
      useDefaultTemplate
    );

    if (shouldNav && this.productEditStore.currentMode === Mode.edit) {
      NavService.productEditVariation(this.productEditStore.id!, newVariationSlug);
    }
  }

  saveEditProduct(): ProductUpdateInput {
    const updateProductData: ProductUpdateInput = {
      active: this.productEditStore.active,
      title: this.productEditStore.title,
      description: HTMLService.stripTagsAndAddNewLine(this.productEditStore.description),
      internalDescription: HTMLService.stripTagsAndAddNewLine(this.productEditStore.internalDescription),
      promotable: true,
      type: {
        set: [{
          id: this.productEditStore.selectedProductTypeId
        }]
      },
      variations: this.compileProductVariationsEditSave(),
      deliveryConfigs: {
        set: this.productEditStore.selectedDeliveryItems.map((deliveryConfig: any) => ({
          id: deliveryConfig
        }))
      },
      channels: {
        set: this.productEditStore.selectedChannels.map((channel: any) => ({ channel }))
      },
      categories: {
        set: this.productEditStore.selectedCategoryIds.map(id => {
          return { id };
        })
      }
    };

    if (!!this.productEditStore.availability.length || !!this.productEditStore.oldProduct!.availability?.length) {
      updateProductData.availability = {
        disconnect: this.productEditStore.oldProduct!.availability!.filter(oldItem => {
          return !this.productEditStore.availability!.find(newItem => newItem.id === oldItem.id);
        }).map(itemToDisconnect => {
          return {
            id: itemToDisconnect.id
          };
        }),
        update: this.productEditStore.availability.filter(item => item.isUpdated || !!item.type).map(updateItem => {
          return {
            where: {
              id: updateItem.id
            },
            data: {
              startAt: updateItem.startAt,
              endAt: updateItem.endAt
            }
          };
        }),
        create: this.productEditStore.availability.filter(item => !item.type && !item.isUpdated).map(createItem => {
          return {
            startAt: createItem.startAt,
            endAt: createItem.endAt,
            type: MerchantHolidayType.Product,
            merchant: {
              connect: {
                id: this.productEditStore.productMerchant?.id!
              }
            }
          };
        })
      };
    }

    return updateProductData;
  }

  public isStateDirty = (): boolean => {
    return this.originalState !== this.getProductCreateEditStoreState();
  };

  compileProductVariationsEditSave(): (Maybe<ProductVariationUpdateManyInput> | undefined) {
    const returnObject: Maybe<ProductVariationUpdateManyInput> = {};

    for (const variation of this.productEditStore.variations) {
      if (variation.new) {
        if (!returnObject.create) {
          returnObject.create = [];
        }
        returnObject.create.push(this.compileSingleNewProductVariation(variation));
      } else {
        if (!returnObject.update) {
          returnObject.update = [];
        }
        const variationDataToUpdate: ProductVariationUpdateDataInput = {};
        variationDataToUpdate.price = variation.price;
        variationDataToUpdate.stock = variation.stock;

        variationDataToUpdate.addons = {
          set: this.productEditStore.selectedAddonIds.map((addon: any) => {
            return { id: addon };
          })
        };

        variationDataToUpdate.colours = {
          set: variation.selectedColours.map((colour: any) => {
            return { id: colour };
          })
        };

        variationDataToUpdate.media = {
          set: variation.mediaUploads.filter((media: any) => media.id).map((media: any) => {
            return {
              id: media.id
            };
          })
        };

        variationDataToUpdate.addons = {
          set: this.productEditStore.selectedAddonIds.map((addon: any) => {
            return { id: addon };
          })
        };

        variationDataToUpdate.recipe = this.compileVariationEditRecipeUpdate(variation);

        const VariationToUpdate: ProductVariationUpdateWithWhereUniqueNestedInput = {
          where: { id: variation.id },
          data: variationDataToUpdate
        };
        returnObject.update.push(VariationToUpdate);
      }
    }

    return returnObject;
  }

  compileVariationEditRecipeUpdate(variation: ProductVariation): (RecipeUpdateOneInput | undefined) {
    const originalVariations = this.oldProduct.variations!.filter(oldVariation => oldVariation.id === variation.id);
    const originalRecipe = originalVariations.length > 0
      ? originalVariations[0].recipe
      : undefined;

    // JJT - I usually dont comment code but this is crazy:
    if (originalRecipe) {
      const updateRecipe: RecipeUpdateDataInput = {};

      if (variation.selectedComponentConfigs.length > 0) {
        updateRecipe.recipeItems = {};

        for (const component of variation.selectedComponentConfigs) {
          // Update recipe items that already exist
          if (component.fx_id) {
            if (!updateRecipe.recipeItems.update) {
              updateRecipe.recipeItems.update = [];
            }

            updateRecipe.recipeItems.update.push({
              where: { id: component.fx_id },
              data: {
                quantity: component.quantity
              }
            });
          } else {
            // Otherwise make new recipe items
            if (!updateRecipe.recipeItems.create) {
              updateRecipe.recipeItems.create = [];
            }

            updateRecipe.recipeItems.create.push({
              componentConfig: {
                connect: {
                  id: component.id
                }
              },
              quantity: component.quantity
            });
          }
        }

        for (const component of originalRecipe.recipeItems!) {
          // remove all recipe items no longer used
          const foundCrossover = variation.selectedComponentConfigs.filter(selectedComponent => selectedComponent.fx_id === component.id);

          if (foundCrossover.length === 0) {
            if (!updateRecipe.recipeItems.delete) {
              updateRecipe.recipeItems.delete = [];
            }

            updateRecipe.recipeItems.delete.push({
              id: component.id
            });
          }
        }

        return { update: updateRecipe };
      }

      // no more components, delete the recipe
      return { delete: true };
    }

    // If no existing recipe, make a new one, Easy
    return this.compileVariationRecipe(variation);
  }

  compileProductCreateInput(): ProductCreateInput {
    const createInput: ProductCreateInput = {
      active: this.productEditStore.active,
      title: this.productEditStore.title,
      description: this.productEditStore.description,
      internalDescription: this.productEditStore.internalDescription,
      sku: `fx-${cuid()}`,
      promotable: true,
      deliveryConfigs: {
        connect: this.productEditStore.selectedDeliveryItems.map((item: any) => {
          return { id: item };
        })
      },
      merchant: {
        connect: {
          id: this.merchantStore!.merchant!.id
        }
      },
      variations: this.compileProductVariations(),
      slug: parseSlug(this.productEditStore.title),
      channels: {
        connect: this.productEditStore.selectedChannels.map((channel: any) => ({ channel }))
      },
      // @ts-ignore
      type: {
        connect: [{
          id: this.productEditStore.selectedProductTypeId
        }]
      },
      categories: {
        connect: this.productEditStore.selectedCategoryIds.map(id => {
          return { id };
        })
      },
      availability: {
        create: this.productEditStore!.availability.map(availability => {
          return {
            startAt: availability.startAt,
            endAt: availability.endAt,
            type: MerchantHolidayType.Product,
            merchant: {
              connect: {
                id: this.merchantStore!.merchant!.id
              }
            }
          };
        })
      }
    };

    return createInput;
  }

  compileProductVariations(): ProductVariationCreateManyInput {
    const variations: ProductVariationCreateManyInput = {
      create: this.productEditStore.variations.map((variation: any): ProductVariationCreateInput => {
        return this.compileSingleNewProductVariation(variation);
      })
    };

    return variations;
  }

  compileSingleNewProductVariation(variation: ProductVariation): ProductVariationCreateInput {
    return {
      title: variation.title,
      sku: `fx-${cuid()}`,
      active: true,
      type: variation.type,
      price: variation.price!,
      stock: variation.stock,
      default: variation.type === ProductVariationType.Original,
      dimensions: this.compileVariationDimensions(variation),
      recipe: this.compileVariationRecipe(variation),
      media: {
        connect: variation.mediaUploads.filter(media => !!media.id).map(item => {
          return {
            id: item.id
          };
        })
      },
      colours: {
        connect: variation.selectedColours.map(item => {
          return { id: item };
        })
      }
    };
  }

  compileVariationRecipe(variation: ProductVariation): (RecipeCreateOneInput | undefined) {
    if (variation.selectedComponentConfigs.length === 0) {
      return undefined;
    }

    return {
      create: {
        recipeItems: {
          create: variation.selectedComponentConfigs.map(componentConfig => {
            return {
              componentConfig: {
                connect: {
                  id: componentConfig.id
                }
              },
              quantity: componentConfig.quantity
            };
          })
        }
      }
    };
  }

  compileVariationDimensions(variation: ProductVariation): (DimensionsCreateOneInput | undefined) {
    if (!variation.dimensions.height || !variation.dimensions.length || !variation.dimensions.width) {
      return undefined;
    }

    return {
      create: {
        height: parseInt(variation.dimensions.height.toString()),
        length: parseInt(variation.dimensions.length.toString()),
        width: parseInt(variation.dimensions.width.toString())
      }
    };
  }

  createProductConnectItems(value: any): (GenericConnectClause | undefined) {
    if (value) {
      return {
        connect: {
          id: value
        }
      };
    }

    return undefined;
  }

  getProductCreateEditStoreState = (): string => {
    let buildingState = '';
    buildingState += this.productEditStore.active;
    buildingState += this.productEditStore.description;
    buildingState += this.productEditStore.selectedCategoryIds;
    buildingState += this.productEditStore.selectedProductTypeId;
    buildingState += this.productEditStore.selectedDeliveryItems;
    buildingState += this.productEditStore.selectedAddonIds;
    buildingState += this.productEditStore.selectedChannels;
    buildingState += this.productEditStore.title;

    buildingState += JSON.stringify(this.productEditStore.variations);

    return buildingState;
  };
}

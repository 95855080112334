import styled from '@emotion/styled';
import { Box } from 'rebass';

import { mq } from 'utils/css-mixins/css-mixins';
import {
  colors
} from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  .ais-Pagination-list--noRefinement {
    display: none !important;
  }

  .ais-Pagination-list {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 2em;
  }

  ${mq.lessThan('medium')} {
    font-size: 0.9em;
  }

  .ais-Pagination-link {
    display: block;
    border: 1px solid ${colors.floomMidnightBlue};
    background: white;
    width: 52px;
    height: 52px;
    line-height: 2.8;
    border-radius: 100%;
    -webkit-transition: background-color 0.2s ease-out;
    transition: background-color 0.2s ease-out;
  }

  ${mq.lessThan('medium')} {
    .ais-Pagination-link {
      width: 32px;
      height: 32px;
      line-height: 1.8;
    }
  }

  .ais-Pagination-item {
    text-align: center;
    display: flex;
    align-items: center;
  }

  .ais-Pagination-item--disabled .ais-Pagination-link {
    opacity: 0.6;
    cursor: not-allowed;
    color: ${colors.floomMidnightBlue};
  }

  .ais-Pagination-item + .ais-Pagination-item {
    margin-left: 0.3rem;
  }

  .ais-Pagination-item--selected .ais-Pagination-link {
    color: #fff;
    background-color: ${colors.floomMidnightBlue};
    border-color: ${colors.floomMidnightBlue};
  }

  .ais-Pagination-item--firstPage .ais-Pagination-link,
  .ais-Pagination-item--lastPage .ais-Pagination-link,
  .ais-Pagination-item--previousPage .ais-Pagination-link,
  .ais-Pagination-item--nextPage .ais-Pagination-link {
    background: none;
    border: none;
    width: auto;
    height: auto;
    padding-left: 1em;
    padding-right: 1em;
  }

  ${mq.lessThan('medium')} {
    .ais-Pagination-item--firstPage .ais-Pagination-link,
    .ais-Pagination-item--lastPage .ais-Pagination-link,
    .ais-Pagination-item--previousPage .ais-Pagination-link,
    .ais-Pagination-item--nextPage .ais-Pagination-link {
      font-size: 0.8em;
      padding-right: 0;
      padding-left: 0.2em;
    }

    .ais-Pagination-item--firstPage .ais-Pagination-link,
    .ais-Pagination-item--previousPage .ais-Pagination-link {
      margin-right: auto;
    }

    .ais-Pagination-item--lastPage .ais-Pagination-link,
    .ais-Pagination-item--nextPage .ais-Pagination-link {
      margin-left: auto;
    }
  }
`;

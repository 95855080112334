import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';

import { colors, breakpoints, textStyles } from 'utils/rebass-theme';

export const Heading: any = styled.h1`
  ${textStyles.headline}
  margin-top: 40px;
  margin-bottom: 20px;
  color: ${colors.floomMidnightBlue};
`;

export const SubHeading: any = styled.h1`
  ${textStyles.title}
  margin-bottom: 30px;
  color: ${colors.floomMidnightBlue};
`;

export const PaymentOptionsContainer: any = styled(Flex)`
  text-align: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.small}) {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const PaymentOptions: any = styled(Box)`
  svg {
    margin-left: 10px;
  }
`;

export const CopyBody: any = styled(Text)`
  max-width: 430px;
`;

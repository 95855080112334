import { FC, Fragment, ReactNode, useContext } from 'react';

import styled from '@emotion/styled';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  WholesaleOrderStates,
  WholesalePaymentStatus
} from 'generated-types.d';

import ModalStore from 'stores/modal-store/modal-store';
import ToasterStore from 'stores/toaster-store/toaster-store';
import WholesaleOrdersStore from 'stores/wholesale/wholesale-orders-store/wholesale-orders-store';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';
import { getPaymentStatusConfig } from 'features/wholesale/helpers/wholesale-helpers/wholesale-helpers';
import WholesaleServices from 'features/wholesale/services';

import Lozenge from 'components/lozenge';
import StyledLink from 'components/styled-link';

import { SingleOrderContext } from '../single-order.context';
import { SectionHeading } from '../single-order.styles';

import { FooterCard } from './footer-card';
import { FooterPaymentColumn } from './footer-payment-column';

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  line-height: 1.5;
`;

export const SingleOrderFooter: FC<{
  wholesaleOrdersStore?: WholesaleOrdersStore;
  modalStore?: ModalStore;
  toasterStore?: ToasterStore;
}> = inject((stores: FxStores) => ({
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  modalStore: stores.modalStore
}))(observer(({
  wholesaleOrdersStore,
  modalStore,
  toasterStore
}) => {
  const {
    grandTotalCurrencyFormatted,
    fetchOrder
  } = useContext(SingleOrderContext);

  if (!wholesaleOrdersStore!.selectedOrder) return null;

  const last4Digits = wholesaleOrdersStore?.selectedOrder!.merchant.paymentMethod?.lastFourCardDigits;
  const paymentStatusConfig = getPaymentStatusConfig(wholesaleOrdersStore!.selectedOrder);
  const cardNumber = `xxxx xxxx xxxx ${last4Digits || 'xxxx'}`;

  const canPayEarly = (): boolean => {
    return wholesaleOrdersStore!.selectedOrder?.paymentStatus === WholesalePaymentStatus.Draft
      && wholesaleOrdersStore!.selectedOrder?.status === WholesaleOrderStates.Completed;
  };

  const finaliseOrder = async (): Promise<void> => {
    if (!wholesaleOrdersStore!.selectedOrder) return;

    try {
      await WholesaleServices.WholesaleOrdersAPIService.finaliseOrder(wholesaleOrdersStore!.selectedOrder);
      toasterStore!.popSuccessToast(`Order ${wholesaleOrdersStore!.selectedOrder.wholesaleOrderRef}`, 'finalise');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const triggerPaymentConfirmation = (): void => {
    if (!wholesaleOrdersStore!.selectedOrder) return;

    modalStore!.triggerModal<ConfirmationModalData>({
      modalType: 'confirmation',
      data: {
        title: 'Pay your order early',
        cancelButtonCopy: 'Cancel',
        confirmButtonCopy: `Pay ${grandTotalCurrencyFormatted}`,
        errorCopy: 'Unable to finalise your order at the moment.',
        copy: (
          <Fragment>
            You are paying for your wholesale order
            {' '}<strong>({wholesaleOrdersStore!.selectedOrder.wholesaleOrderRef?.slice?.(0, 7)})</strong> early.
            {' '}By confirming, <strong>{grandTotalCurrencyFormatted}</strong> will
            {' '}be taken from your payment method (card ending in
            {' '}<strong>{last4Digits}</strong>) within 60 minutes.
          </Fragment>
        ),
        confirmButtonAction: finaliseOrder,
        onClose: () => fetchOrder?.()
      }
    });
  };

  const renderDeliveryInfo = (): ReactNode => {
    const address = wholesaleOrdersStore!.selectedOrder!.wholesaleDeliveryConfigSnapshot
      ? wholesaleOrdersStore!.selectedOrder!.wholesaleDeliveryConfigSnapshot.address
      : {};

    return (
      <Fragment>
        <Flex justifyContent="space-between">
          <Box>Delivery address</Box>
          <Box
            css={{
              textAlign: 'right',
              fontWeight: 'bold'
            }}
          >
            <div>{address.recepientFullName}</div>
            <Box>{address.address1}</Box>
            {!!address.address2 &&
              <Box>{address.address2}</Box>
            }
            <Box>{address.businessName}</Box>
            <Box>{address.city}</Box>
            <Box>{address.region}</Box>
            <Box>{address.country}</Box>
            <Box>{address.postalCode}</Box>
          </Box>
        </Flex>
        <Flex
          justifyContent="space-between"
          mt="20px"
        >
          <Box>Phone number</Box>
          <Box
            css={{
              textAlign: 'right',
              fontWeight: 'bold'
            }}
          >
            <a href={`tel:${address.phone}`}>
              <StyledLink>
                {address.phone}
              </StyledLink>
            </a>
          </Box>
        </Flex>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <SectionHeading>
        Other details
      </SectionHeading>
      <Wrapper>
        <FooterCard title="Delivery details">
          {renderDeliveryInfo()}
        </FooterCard>
        <FooterCard title="Instructions">
          {
            wholesaleOrdersStore!.selectedOrder.wholesaleDeliveryConfigSnapshot
              ? wholesaleOrdersStore!.selectedOrder.wholesaleDeliveryConfigSnapshot.instructions
              : 'No delivery instructions provided'
          }
        </FooterCard>
        <FooterCard title="Payment">
          <FooterPaymentColumn
            iconName="card"
            tooltipMessage="Payment method"
          >
            <Box fontWeight="bold">
              Card
            </Box>
            <Box>
              {cardNumber}
            </Box>
          </FooterPaymentColumn>
          <FooterPaymentColumn
            iconName="receipt"
            tooltipMessage="Payment status"
            shouldHide={!paymentStatusConfig}
          >
            <Lozenge
              copy={paymentStatusConfig?.value}
              color={ColourOption.floomMidnightBlue}
              bg={paymentStatusConfig?.bg || ColourOption.lightGrey}
            />
          </FooterPaymentColumn>
          <FooterPaymentColumn
            iconName="hourglass"
            tooltipMessage="Pay early"
            shouldHide={!canPayEarly()}
          >
            <Box
              as="button"
              onClick={triggerPaymentConfirmation}
              mt="-5px"
            >
              <StyledLink>
                Pay your order early
              </StyledLink>
            </Box>
          </FooterPaymentColumn>
        </FooterCard>
      </Wrapper>
    </Fragment>
  );
}));

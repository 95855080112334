import gql from 'graphql-tag';

export const CAN_CANCEL_COURIER_BOOKING = (vars: any): any => gql`
  query CanCancelCourierBooking {
    canCancelCourierBooking(
      where: {
        id: "${vars.bookingId}"
      }
    ) {
      response
      payload {
        cancellationFee
        jobStatus
      }
    }
  }
`;

export const CAN_BOOK_COURIER_BOOKING = (vars: any): any => gql`
    query CanBookCourierBooking {
        canBookCourierBooking( where: { orderNo: "${vars.orderNo}" } ) {
            response
        }
    }
`;

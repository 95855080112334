import styled from '@emotion/styled';
import {
  Box,
  Flex
} from 'rebass';

import {
  colors,
  textStyles
} from 'utils/rebass-theme';

export const Wrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding-bottom: 13px;
`;

export const Title = styled(Box)`
  ${textStyles.footnote};
  font-size: 10px;
  align-items: center;
  color: ${colors.floomMidnightBlue};
  margin-bottom: 0;
  text-transform: uppercase;
`;

export const ClearButton = styled(Box)`
  ${textStyles.footnote};
  font-size: 10px;
  color: ${colors.floomMidnightBlue};
  text-decoration: underline;
  margin-bottom: 0;
`;

export const ResultCountText = styled(Flex)`
  ${textStyles.body};
  font-size: 13px;
  margin-top: -13px;
  padding-bottom: 13px;
`;

import moment from 'moment-timezone';

import { UserRole } from 'generated-types.d';

import { HolidayGroupConfig } from 'features/settings/components/merchant-holidays/merchant-holidays.types';

const TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

const addRealMonth = (iterator: number): moment.Moment => {
  const m = moment();
  const fm = moment().add(iterator, 'M');
  const fmEnd = moment(fm).endOf('month');

  return m.date() !== fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm;
};

const addPreviousMonths = (): HolidayGroupConfig[] => {
  const groups: HolidayGroupConfig[] = [];
  let iterator = -10;
  let m = addRealMonth(iterator);

  do {
    m = addRealMonth(iterator);

    groups.push({
      title: m.format('__MMMM__YYYY'),
      start: m.startOf('month').startOf('d').format(TIME_FORMAT),
      end: m.endOf('month').endOf('d').format(TIME_FORMAT),
      overlap: moment().startOf('d').format(TIME_FORMAT)
    });

    iterator++;
  } while (iterator <= -1);

  return groups;
};

const buildMerchantHolidayGroups = (): HolidayGroupConfig[] => {
  const groups: HolidayGroupConfig[] = [...addPreviousMonths()];
  let iterator = 0;
  let m = addRealMonth(iterator);

  /**
   * Get month groups and build timestamps / titles for each
   */
  do {
    m = addRealMonth(iterator);

    groups.push({
      title: m.format('__MMMM__YYYY'),
      start: m.startOf('month').startOf('d').format(TIME_FORMAT),
      end: m.endOf('month').endOf('d').format(TIME_FORMAT)
    });
    iterator++;
  } while (iterator <= 6);

  m = addRealMonth(iterator++);

  /**
   * Add remaining holidays to this block.
   */
  groups.push({
    title: `__${m.format('MMMM_YYYY')}_Onwards__`,
    start: m.startOf('month').startOf('d').format(TIME_FORMAT)
  });

  return groups;
};

/**
 * @description We want SuperAdmin roles to see holidays grouped via month view,
 * As they will see lots more holidays than a single merchant. Logged in users
 * with Team roles, will see a single holiday group of Upcoming, that will show
 * all holidays, from the start of the current day onwards
 */
export const HOLIDAY_GROUP_CONFIG = (role: UserRole): HolidayGroupConfig[] => {
  switch (role) {
    case UserRole.CustomerService:
    case UserRole.SuperAdmin:
      return buildMerchantHolidayGroups();

    case UserRole.TeamAdmin:
    case UserRole.TeamMember:
    case UserRole.TeamOwner:
      const m = moment();

      return [
        {
          title: '__Current__',
          end: m.format(TIME_FORMAT),
          overlap: m.format(TIME_FORMAT)
        },
        {
          title: '__Upcoming__',
          start: m.format(TIME_FORMAT)
        }
      ];
  }
};

import { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Text } from 'rebass';

import { OrderDeliveryState } from 'generated-types.d';

import { NavService } from 'lib';

import CourierStatusReference from 'features/courier/components/courier-status-reference';
import { courierStatusConfig } from 'features/courier/courier.constants';
import CourierAPIService from 'features/orders/services/courier-api/courier-api.service';

import Button from 'components/button';
import GenericModal from 'components/generic-modal';
import Icon from 'components/icon';

import * as Styles from './courier-status-modal.styles';
import * as Types from './courier-status-modal.types';

class CourierStatusModal extends Component<Types.CourierStatusModalProps> {
  config = courierStatusConfig[this.props.data.courierBooking!.deliveryStatus];

  state = { canCancel: 'NO', cancellationFee: '' };

  componentDidMount(): void {
    this.checkIfCanCancel();
  }

  async checkIfCanCancel(): Promise<any> {
    const { response, payload: { cancellationFee } } = await CourierAPIService.canCancelCourierBooking(this.props.data.courierBooking!.id);

    this.setState({
      canCancel: response,
      cancellationFee: cancellationFee
    });
  }

  private emailFloom = (): void => {
    const merchant = this.props.merchantStore!.merchant!;
    const { orderNo } = this.props.data;

    if (merchant) {
      window.location.href = `mailto:${process.env.NOTIFICATION_EMAIL_REPLY}?subject=COURIER CANCELLED for ${merchant.title}&body=Order Number: ${orderNo}`;
    }
  };

  private bookCourier = (): void => {
    this.props.closeModal();
    NavService.bookCourier(this.props.data.orderNo);
  };

  private onCancelRequest = (): void => {
    this.props.modalStore!.triggerModal({
      modalType: 'courierCancelConfirmation',
      data: {
        cancellationFee: this.state.cancellationFee,
        currency: this.props.data.currency,
        orderNo: this.props.data.orderNo,
        courierRef: this.props.data.courierBooking!.courierReferenceId,
        closeEverything: (): void => this.props.closeModal()
      }
    });
  };

  private buildButtonAction = (): Types.ButtonAction => {
    const { listLayout } = this.props.ordersStore!;

    switch (this.props.data.courierBooking!.deliveryStatus) {
      case OrderDeliveryState.Cancelled:
        return {
          action: this.emailFloom,
          copy: 'Contact us',
          isDisabled: !this.props.merchantStore!.merchant
        };

      case OrderDeliveryState.Failed:
        return {
          action: this.bookCourier,
          copy: 'Book courier',
          isDisabled: false
        };

      case OrderDeliveryState.Refused:
        return {
          action: this.props.closeModal,
          copy: 'Ok',
          isDisabled: false
        };

      default:
        return {
          action: this.props.closeModal,
          copy: `Return to ${listLayout ? `${listLayout} orders` : 'orders'}`,
          isDisabled: false
        };
    }
  };

  render(): ReactNode {
    const buttonConfig = this.buildButtonAction();

    return (
      <GenericModal
        title="Booking"
        shouldHideFooter={true}
        shouldHideHeader={true}
        closeModal={this.props.closeModal}
        modalOpen={this.props.isOpen}
        innerComponent={(
          <Styles.Container>
            <Styles.IconClose onClick={this.props.closeModal}>
              <Icon iconName="cross-small" />
            </Styles.IconClose>
            <Text css={Styles.Title}>
              FloomX courier {this.config.type}
            </Text>
            <CourierStatusReference
              courierBooking={this.props.data.courierBooking!}
            />
            <Styles.ButtonContainer>
              { this.state.canCancel === 'YES' ? (
                <Fragment>
                  <div onClick={this.onCancelRequest}>
                    <Button
                      size="normal"
                      appearance="danger"
                      isParentWidth={false}
                      isDisabled={buttonConfig.isDisabled}
                      copy="Cancel this booking"
                    />
                  </div>

                  <div style={{ marginBottom: 10 }} />
                </Fragment>
              ) : null
              }

              <div onClick={buttonConfig.action}>
                <Button
                  size="normal"
                  appearance="primary"
                  isParentWidth={false}
                  isDisabled={buttonConfig.isDisabled}
                  copy={buttonConfig.copy}
                />
              </div>
            </Styles.ButtonContainer>
          </Styles.Container>
        )}
      />
    );
  }
}

export default inject('ordersStore', 'courierStore', 'merchantStore', 'modalStore')(observer(CourierStatusModal));

import { memo, useRef, RefObject } from 'react';

import {
  Configure,
  Index
} from 'react-instantsearch-dom';

import { Merchant } from 'generated-types.d';

import { PermissionsService } from 'lib';

import { OrdersListLayout } from 'stores/orders/orders-store.types';

import {
  ORDER_GROUP_OPTIONS,
  ORDER_LIST_PAGE_LAYOUTS
} from 'features/orders/graphql/helpers/order-group.config';
import {
  OrderGroupOption,
  OrderPageRoute
} from 'features/orders/orders.types';

import { OrderListLayout } from './order-list-layout';
import { OrderSectionHeading } from './order-section-heading';

interface OrderListGroupProps {
  groupName: OrderGroupOption;
  index: number;
  listStyleType: OrdersListLayout;
  pageLayout: OrderPageRoute;
  indexName: string;
  shouldRefresh: boolean;
  merchant?: Merchant;
  onRefresh: () => void;
}

const handleScroll = (ref: RefObject<HTMLDivElement>): void => {
  if (!!ref?.current) {
    ref?.current?.scrollIntoView();
  }
};

export const OrderListGroup = memo(({
  groupName,
  index,
  listStyleType,
  indexName,
  pageLayout,
  shouldRefresh,
  onRefresh,
  merchant
}: OrderListGroupProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const pageConfig = ORDER_LIST_PAGE_LAYOUTS[pageLayout];
  const groupConfig = ORDER_GROUP_OPTIONS[groupName];
  const pageFilters = pageConfig.globalFilter.trim();
  const groupFilters = groupConfig.filter.trim();
  const pageNumericFilters = pageConfig.numericFilter(merchant?.timezone).trim();
  const groupNumericFilters = groupConfig.numericFilter(merchant?.timezone).trim();
  const indexId = `${index}-${groupName}-${pageLayout}`;
  const hitsPerPage = PermissionsService.isSuperAdmin() ? 40 : 80;

  return (
    <div
      style={{ marginBottom: '2rem' }}
      key={indexId}
      ref={wrapperRef}
    >
      <Index
        indexName={indexName}
        indexId={indexId}
      >
        <Configure
          filters={`${pageFilters} ${(pageFilters.length && groupFilters.length) ? 'AND' : ''} ${groupFilters}`.trim()}
          numericFilters={`${pageNumericFilters} ${(pageNumericFilters.length && groupNumericFilters.length) ? 'AND' : ''} ${groupNumericFilters}`.trim()}
          hitsPerPage={hitsPerPage}
        />
        <OrderSectionHeading
          groupName={groupName}
          layoutOption={listStyleType}
          shouldRefresh={shouldRefresh}
        />
        <OrderListLayout
          groupName={groupName}
          layoutOption={listStyleType}
          refresh={shouldRefresh}
          onRefresh={onRefresh}
          onPaginate={(): void => handleScroll(wrapperRef)}
        />
      </Index>
    </div>
  );
});

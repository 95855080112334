export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
  Long: any;
};

export type ActionLog = {
  __typename?: 'ActionLog';
  id: Scalars['ID'];
  type: ActionLogType;
  nodeId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<User>;
  message?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Json']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionLogConnection = {
  __typename?: 'ActionLogConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ActionLogEdge>>;
  aggregate: AggregateActionLog;
};

export type ActionLogCreateInput = {
  id?: Maybe<Scalars['ID']>;
  type: ActionLogType;
  nodeId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<UserCreateOneInput>;
  message?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Json']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionLogEdge = {
  __typename?: 'ActionLogEdge';
  node: ActionLog;
  cursor: Scalars['String'];
};

export enum ActionLogOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NodeIdAsc = 'nodeId_ASC',
  NodeIdDesc = 'nodeId_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  DataAsc = 'data_ASC',
  DataDesc = 'data_DESC',
  EffectiveAtAsc = 'effectiveAt_ASC',
  EffectiveAtDesc = 'effectiveAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type ActionLogPreviousValues = {
  __typename?: 'ActionLogPreviousValues';
  id: Scalars['ID'];
  type: ActionLogType;
  nodeId?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Json']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionLogSubscriptionPayload = {
  __typename?: 'ActionLogSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ActionLog>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ActionLogPreviousValues>;
};

export enum ActionLogType {
  Global = 'Global',
  Merchant = 'Merchant'
}

export type ActionLogWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  type?: Maybe<ActionLogType>;
  type_not?: Maybe<ActionLogType>;
  type_in?: Maybe<Array<ActionLogType>>;
  type_not_in?: Maybe<Array<ActionLogType>>;
  nodeId?: Maybe<Scalars['ID']>;
  nodeId_not?: Maybe<Scalars['ID']>;
  nodeId_in?: Maybe<Array<Scalars['ID']>>;
  nodeId_not_in?: Maybe<Array<Scalars['ID']>>;
  nodeId_lt?: Maybe<Scalars['ID']>;
  nodeId_lte?: Maybe<Scalars['ID']>;
  nodeId_gt?: Maybe<Scalars['ID']>;
  nodeId_gte?: Maybe<Scalars['ID']>;
  nodeId_contains?: Maybe<Scalars['ID']>;
  nodeId_not_contains?: Maybe<Scalars['ID']>;
  nodeId_starts_with?: Maybe<Scalars['ID']>;
  nodeId_not_starts_with?: Maybe<Scalars['ID']>;
  nodeId_ends_with?: Maybe<Scalars['ID']>;
  nodeId_not_ends_with?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<UserWhereInput>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Scalars['String']>>;
  message_not_in?: Maybe<Array<Scalars['String']>>;
  message_lt?: Maybe<Scalars['String']>;
  message_lte?: Maybe<Scalars['String']>;
  message_gt?: Maybe<Scalars['String']>;
  message_gte?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  effectiveAt?: Maybe<Scalars['DateTime']>;
  effectiveAt_not?: Maybe<Scalars['DateTime']>;
  effectiveAt_in?: Maybe<Array<Scalars['DateTime']>>;
  effectiveAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  effectiveAt_lt?: Maybe<Scalars['DateTime']>;
  effectiveAt_lte?: Maybe<Scalars['DateTime']>;
  effectiveAt_gt?: Maybe<Scalars['DateTime']>;
  effectiveAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ActionLogWhereInput>>;
  OR?: Maybe<Array<ActionLogWhereInput>>;
  NOT?: Maybe<Array<ActionLogWhereInput>>;
};

export type ActionLogWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Addon = {
  __typename?: 'Addon';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  merchant: Merchant;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  category: AddonCategory;
  channels?: Maybe<Array<ChannelType>>;
  media?: Maybe<Media>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  allProducts: Scalars['Boolean'];
  selectedProducts?: Maybe<Array<Product>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};


export type AddonChannelsArgs = {
  where?: Maybe<ChannelTypeWhereInput>;
  orderBy?: Maybe<ChannelTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type AddonSelectedProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<ProductOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AddonCategory = {
  __typename?: 'AddonCategory';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  channels?: Maybe<Array<ChannelType>>;
  restrictedDelivery: Scalars['Boolean'];
  slug: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};


export type AddonCategoryChannelsArgs = {
  where?: Maybe<ChannelTypeWhereInput>;
  orderBy?: Maybe<ChannelTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AddonCategoryConnection = {
  __typename?: 'AddonCategoryConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AddonCategoryEdge>>;
  aggregate: AggregateAddonCategory;
};

export type AddonCategoryCreateInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  channels?: Maybe<ChannelTypeCreateManyInput>;
  restrictedDelivery: Scalars['Boolean'];
  slug: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddonCategoryCreateOneInput = {
  create?: Maybe<AddonCategoryCreateInput>;
  connect?: Maybe<AddonCategoryWhereUniqueInput>;
};

export type AddonCategoryEdge = {
  __typename?: 'AddonCategoryEdge';
  node: AddonCategory;
  cursor: Scalars['String'];
};

export enum AddonCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  RestrictedDeliveryAsc = 'restrictedDelivery_ASC',
  RestrictedDeliveryDesc = 'restrictedDelivery_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type AddonCategoryPreviousValues = {
  __typename?: 'AddonCategoryPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  restrictedDelivery: Scalars['Boolean'];
  slug: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddonCategorySubscriptionPayload = {
  __typename?: 'AddonCategorySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<AddonCategory>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<AddonCategoryPreviousValues>;
};

export type AddonCategorySubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<AddonCategoryWhereInput>;
  AND?: Maybe<Array<AddonCategorySubscriptionWhereInput>>;
  OR?: Maybe<Array<AddonCategorySubscriptionWhereInput>>;
  NOT?: Maybe<Array<AddonCategorySubscriptionWhereInput>>;
};

export type AddonCategoryUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelTypeUpdateManyInput>;
  restrictedDelivery?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddonCategoryUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelTypeUpdateManyInput>;
  restrictedDelivery?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddonCategoryUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  restrictedDelivery?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddonCategoryUpdateOneRequiredInput = {
  create?: Maybe<AddonCategoryCreateInput>;
  update?: Maybe<AddonCategoryUpdateDataInput>;
  upsert?: Maybe<AddonCategoryUpsertNestedInput>;
  connect?: Maybe<AddonCategoryWhereUniqueInput>;
};

export type AddonCategoryUpsertNestedInput = {
  update: AddonCategoryUpdateDataInput;
  create: AddonCategoryCreateInput;
};

export type AddonCategoryWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  channels_every?: Maybe<ChannelTypeWhereInput>;
  channels_some?: Maybe<ChannelTypeWhereInput>;
  channels_none?: Maybe<ChannelTypeWhereInput>;
  restrictedDelivery?: Maybe<Scalars['Boolean']>;
  restrictedDelivery_not?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<AddonCategoryWhereInput>>;
  OR?: Maybe<Array<AddonCategoryWhereInput>>;
  NOT?: Maybe<Array<AddonCategoryWhereInput>>;
};

export type AddonCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

export type AddonConnection = {
  __typename?: 'AddonConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AddonEdge>>;
  aggregate: AggregateAddon;
};

export type AddonCreateInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  merchant: MerchantCreateOneInput;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  channels?: Maybe<ChannelTypeCreateManyInput>;
  category: AddonCategoryCreateOneInput;
  media?: Maybe<MediaCreateOneInput>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  allProducts: Scalars['Boolean'];
  selectedProducts?: Maybe<ProductCreateManyInput>;
};

export type AddonCreateManyInput = {
  connect?: Maybe<Array<AddonWhereUniqueInput>>;
  create?: Maybe<Array<AddonCreateInput>>;
};

export type AddonEdge = {
  __typename?: 'AddonEdge';
  node: Addon;
  cursor: Scalars['String'];
};

export enum AddonOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  BrandAsc = 'brand_ASC',
  BrandDesc = 'brand_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  StockAsc = 'stock_ASC',
  StockDesc = 'stock_DESC',
  AllProductsAsc = 'allProducts_ASC',
  AllProductsDesc = 'allProducts_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type AddonPreviousValues = {
  __typename?: 'AddonPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  brand?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  allProducts: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddonScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  brand_not?: Maybe<Scalars['String']>;
  brand_in?: Maybe<Array<Scalars['String']>>;
  brand_not_in?: Maybe<Array<Scalars['String']>>;
  brand_lt?: Maybe<Scalars['String']>;
  brand_lte?: Maybe<Scalars['String']>;
  brand_gt?: Maybe<Scalars['String']>;
  brand_gte?: Maybe<Scalars['String']>;
  brand_contains?: Maybe<Scalars['String']>;
  brand_not_contains?: Maybe<Scalars['String']>;
  brand_starts_with?: Maybe<Scalars['String']>;
  brand_not_starts_with?: Maybe<Scalars['String']>;
  brand_ends_with?: Maybe<Scalars['String']>;
  brand_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  stock_not?: Maybe<Scalars['Int']>;
  stock_in?: Maybe<Array<Scalars['Int']>>;
  stock_not_in?: Maybe<Array<Scalars['Int']>>;
  stock_lt?: Maybe<Scalars['Int']>;
  stock_lte?: Maybe<Scalars['Int']>;
  stock_gt?: Maybe<Scalars['Int']>;
  stock_gte?: Maybe<Scalars['Int']>;
  allProducts?: Maybe<Scalars['Boolean']>;
  allProducts_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<AddonScalarWhereInput>>;
  OR?: Maybe<Array<AddonScalarWhereInput>>;
  NOT?: Maybe<Array<AddonScalarWhereInput>>;
};

export type AddonSubscriptionPayload = {
  __typename?: 'AddonSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Addon>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<AddonPreviousValues>;
};

export type AddonSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<AddonWhereInput>;
  AND?: Maybe<Array<AddonSubscriptionWhereInput>>;
  OR?: Maybe<Array<AddonSubscriptionWhereInput>>;
  NOT?: Maybe<Array<AddonSubscriptionWhereInput>>;
};

export type AddonUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  merchant?: Maybe<MerchantUpdateOneRequiredInput>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelTypeCreateManyInput>;
  category?: Maybe<AddonCategoryUpdateOneRequiredInput>;
  media?: Maybe<MediaUpdateOneInput>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  allProducts?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  selectedProducts?: Maybe<ProductUpdateManyInput>;
};

export type AddonUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  merchant?: Maybe<MerchantUpdateOneRequiredInput>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelTypeUpdateManyInput>;
  category?: Maybe<AddonCategoryUpdateOneRequiredInput>;
  media?: Maybe<MediaUpdateOneInput>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  allProducts?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  selectedProducts?: Maybe<ProductUpdateManyInput>;
};

export type AddonUpdateManyDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
};

export type AddonUpdateManyInput = {
  connect?: Maybe<Array<AddonWhereUniqueInput>>;
  create?: Maybe<Array<AddonCreateInput>>;
  delete?: Maybe<Array<AddonWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AddonScalarWhereInput>>;
  disconnect?: Maybe<Array<AddonWhereUniqueInput>>;
  set?: Maybe<Array<AddonWhereUniqueInput>>;
  update?: Maybe<Array<AddonUpdateWithWhereUniqueNestedInput>>;
  updateMany?: Maybe<Array<AddonUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<AddonUpsertWithWhereUniqueNestedInput>>;
};

export type AddonUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
};

export type AddonUpdateManyWithWhereNestedInput = {
  where: AddonScalarWhereInput;
  data: AddonUpdateManyDataInput;
};

export type AddonUpdateWithWhereUniqueNestedInput = {
  where: AddonWhereUniqueInput;
  data: AddonUpdateDataInput;
};

export type AddonUpsertWithWhereUniqueNestedInput = {
  where: AddonWhereUniqueInput;
  update: AddonUpdateDataInput;
  create: AddonCreateInput;
};

export type AddonWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  merchant?: Maybe<MerchantWhereInput>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  brand_not?: Maybe<Scalars['String']>;
  brand_in?: Maybe<Array<Scalars['String']>>;
  brand_not_in?: Maybe<Array<Scalars['String']>>;
  brand_lt?: Maybe<Scalars['String']>;
  brand_lte?: Maybe<Scalars['String']>;
  brand_gt?: Maybe<Scalars['String']>;
  brand_gte?: Maybe<Scalars['String']>;
  brand_contains?: Maybe<Scalars['String']>;
  brand_not_contains?: Maybe<Scalars['String']>;
  brand_starts_with?: Maybe<Scalars['String']>;
  brand_not_starts_with?: Maybe<Scalars['String']>;
  brand_ends_with?: Maybe<Scalars['String']>;
  brand_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  channels_every?: Maybe<ChannelTypeWhereInput>;
  channels_some?: Maybe<ChannelTypeWhereInput>;
  channels_none?: Maybe<ChannelTypeWhereInput>;
  category?: Maybe<AddonCategoryWhereInput>;
  media?: Maybe<MediaWhereInput>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  stock_not?: Maybe<Scalars['Int']>;
  stock_in?: Maybe<Array<Scalars['Int']>>;
  stock_not_in?: Maybe<Array<Scalars['Int']>>;
  stock_lt?: Maybe<Scalars['Int']>;
  stock_lte?: Maybe<Scalars['Int']>;
  stock_gt?: Maybe<Scalars['Int']>;
  stock_gte?: Maybe<Scalars['Int']>;
  allProducts?: Maybe<Scalars['Boolean']>;
  allProducts_not?: Maybe<Scalars['Boolean']>;
  selectedProducts_every?: Maybe<ProductWhereInput>;
  selectedProducts_some?: Maybe<ProductWhereInput>;
  selectedProducts_none?: Maybe<ProductWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<AddonWhereInput>>;
  OR?: Maybe<Array<AddonWhereInput>>;
  NOT?: Maybe<Array<AddonWhereInput>>;
  searchable_contains?: Maybe<Scalars['String']>;
};

export type AddonWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
  channels_some?: Maybe<ChannelTypeWhereInput>;
};

export type Address = {
  __typename?: 'Address';
  id: Scalars['ID'];
  honorific?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  recipientFullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  team?: Maybe<Team>;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AddressEdge>>;
  aggregate: AggregateAddress;
};

export type AddressCreateInput = {
  id?: Maybe<Scalars['ID']>;
  honorific?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  recipientFullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamCreateOneInput>;
  createdBy?: Maybe<UserCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressCreateOneInput = {
  create?: Maybe<AddressCreateInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  node: Address;
  cursor: Scalars['String'];
};

export type AddressLookupInput = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  country: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export enum AddressOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HonorificAsc = 'honorific_ASC',
  HonorificDesc = 'honorific_DESC',
  BusinessNameAsc = 'businessName_ASC',
  BusinessNameDesc = 'businessName_DESC',
  RecipientFullNameAsc = 'recipientFullName_ASC',
  RecipientFullNameDesc = 'recipientFullName_DESC',
  FirstNameAsc = 'firstName_ASC',
  FirstNameDesc = 'firstName_DESC',
  LastNameAsc = 'lastName_ASC',
  LastNameDesc = 'lastName_DESC',
  Address1Asc = 'address1_ASC',
  Address1Desc = 'address1_DESC',
  Address2Asc = 'address2_ASC',
  Address2Desc = 'address2_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  PostalCodeAsc = 'postalCode_ASC',
  PostalCodeDesc = 'postalCode_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type AddressPreviousValues = {
  __typename?: 'AddressPreviousValues';
  id: Scalars['ID'];
  honorific?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  recipientFullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressSubscriptionPayload = {
  __typename?: 'AddressSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Address>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<AddressPreviousValues>;
};

export type AddressSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<AddressWhereInput>;
  AND?: Maybe<Array<AddressSubscriptionWhereInput>>;
  OR?: Maybe<Array<AddressSubscriptionWhereInput>>;
  NOT?: Maybe<Array<AddressSubscriptionWhereInput>>;
};

export enum AddressType {
  Commercial = 'Commercial',
  Residential = 'Residential'
}

export type AddressUpdateDataInput = {
  honorific?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  recipientFullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressUpdateInput = {
  honorific?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  recipientFullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressUpdateManyMutationInput = {
  honorific?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  recipientFullName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AddressUpdateOneInput = {
  create?: Maybe<AddressCreateInput>;
  update?: Maybe<AddressUpdateDataInput>;
  upsert?: Maybe<AddressUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AddressWhereUniqueInput>;
};

export type AddressUpdateOneRequiredInput = {
  create?: Maybe<AddressCreateInput>;
  update?: Maybe<AddressUpdateDataInput>;
  upsert?: Maybe<AddressUpsertNestedInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
};

export type AddressUpsertNestedInput = {
  update: AddressUpdateDataInput;
  create: AddressCreateInput;
};

export type AddressWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  honorific?: Maybe<Scalars['String']>;
  honorific_not?: Maybe<Scalars['String']>;
  honorific_in?: Maybe<Array<Scalars['String']>>;
  honorific_not_in?: Maybe<Array<Scalars['String']>>;
  honorific_lt?: Maybe<Scalars['String']>;
  honorific_lte?: Maybe<Scalars['String']>;
  honorific_gt?: Maybe<Scalars['String']>;
  honorific_gte?: Maybe<Scalars['String']>;
  honorific_contains?: Maybe<Scalars['String']>;
  honorific_not_contains?: Maybe<Scalars['String']>;
  honorific_starts_with?: Maybe<Scalars['String']>;
  honorific_not_starts_with?: Maybe<Scalars['String']>;
  honorific_ends_with?: Maybe<Scalars['String']>;
  honorific_not_ends_with?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  businessName_not?: Maybe<Scalars['String']>;
  businessName_in?: Maybe<Array<Scalars['String']>>;
  businessName_not_in?: Maybe<Array<Scalars['String']>>;
  businessName_lt?: Maybe<Scalars['String']>;
  businessName_lte?: Maybe<Scalars['String']>;
  businessName_gt?: Maybe<Scalars['String']>;
  businessName_gte?: Maybe<Scalars['String']>;
  businessName_contains?: Maybe<Scalars['String']>;
  businessName_not_contains?: Maybe<Scalars['String']>;
  businessName_starts_with?: Maybe<Scalars['String']>;
  businessName_not_starts_with?: Maybe<Scalars['String']>;
  businessName_ends_with?: Maybe<Scalars['String']>;
  businessName_not_ends_with?: Maybe<Scalars['String']>;
  recipientFullName?: Maybe<Scalars['String']>;
  recipientFullName_not?: Maybe<Scalars['String']>;
  recipientFullName_in?: Maybe<Array<Scalars['String']>>;
  recipientFullName_not_in?: Maybe<Array<Scalars['String']>>;
  recipientFullName_lt?: Maybe<Scalars['String']>;
  recipientFullName_lte?: Maybe<Scalars['String']>;
  recipientFullName_gt?: Maybe<Scalars['String']>;
  recipientFullName_gte?: Maybe<Scalars['String']>;
  recipientFullName_contains?: Maybe<Scalars['String']>;
  recipientFullName_not_contains?: Maybe<Scalars['String']>;
  recipientFullName_starts_with?: Maybe<Scalars['String']>;
  recipientFullName_not_starts_with?: Maybe<Scalars['String']>;
  recipientFullName_ends_with?: Maybe<Scalars['String']>;
  recipientFullName_not_ends_with?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_not?: Maybe<Scalars['String']>;
  firstName_in?: Maybe<Array<Scalars['String']>>;
  firstName_not_in?: Maybe<Array<Scalars['String']>>;
  firstName_lt?: Maybe<Scalars['String']>;
  firstName_lte?: Maybe<Scalars['String']>;
  firstName_gt?: Maybe<Scalars['String']>;
  firstName_gte?: Maybe<Scalars['String']>;
  firstName_contains?: Maybe<Scalars['String']>;
  firstName_not_contains?: Maybe<Scalars['String']>;
  firstName_starts_with?: Maybe<Scalars['String']>;
  firstName_not_starts_with?: Maybe<Scalars['String']>;
  firstName_ends_with?: Maybe<Scalars['String']>;
  firstName_not_ends_with?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_not?: Maybe<Scalars['String']>;
  lastName_in?: Maybe<Array<Scalars['String']>>;
  lastName_not_in?: Maybe<Array<Scalars['String']>>;
  lastName_lt?: Maybe<Scalars['String']>;
  lastName_lte?: Maybe<Scalars['String']>;
  lastName_gt?: Maybe<Scalars['String']>;
  lastName_gte?: Maybe<Scalars['String']>;
  lastName_contains?: Maybe<Scalars['String']>;
  lastName_not_contains?: Maybe<Scalars['String']>;
  lastName_starts_with?: Maybe<Scalars['String']>;
  lastName_not_starts_with?: Maybe<Scalars['String']>;
  lastName_ends_with?: Maybe<Scalars['String']>;
  lastName_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<AddressType>;
  type_not?: Maybe<AddressType>;
  type_in?: Maybe<Array<AddressType>>;
  type_not_in?: Maybe<Array<AddressType>>;
  address1?: Maybe<Scalars['String']>;
  address1_not?: Maybe<Scalars['String']>;
  address1_in?: Maybe<Array<Scalars['String']>>;
  address1_not_in?: Maybe<Array<Scalars['String']>>;
  address1_lt?: Maybe<Scalars['String']>;
  address1_lte?: Maybe<Scalars['String']>;
  address1_gt?: Maybe<Scalars['String']>;
  address1_gte?: Maybe<Scalars['String']>;
  address1_contains?: Maybe<Scalars['String']>;
  address1_not_contains?: Maybe<Scalars['String']>;
  address1_starts_with?: Maybe<Scalars['String']>;
  address1_not_starts_with?: Maybe<Scalars['String']>;
  address1_ends_with?: Maybe<Scalars['String']>;
  address1_not_ends_with?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address2_not?: Maybe<Scalars['String']>;
  address2_in?: Maybe<Array<Scalars['String']>>;
  address2_not_in?: Maybe<Array<Scalars['String']>>;
  address2_lt?: Maybe<Scalars['String']>;
  address2_lte?: Maybe<Scalars['String']>;
  address2_gt?: Maybe<Scalars['String']>;
  address2_gte?: Maybe<Scalars['String']>;
  address2_contains?: Maybe<Scalars['String']>;
  address2_not_contains?: Maybe<Scalars['String']>;
  address2_starts_with?: Maybe<Scalars['String']>;
  address2_not_starts_with?: Maybe<Scalars['String']>;
  address2_ends_with?: Maybe<Scalars['String']>;
  address2_not_ends_with?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_not?: Maybe<Scalars['String']>;
  city_in?: Maybe<Array<Scalars['String']>>;
  city_not_in?: Maybe<Array<Scalars['String']>>;
  city_lt?: Maybe<Scalars['String']>;
  city_lte?: Maybe<Scalars['String']>;
  city_gt?: Maybe<Scalars['String']>;
  city_gte?: Maybe<Scalars['String']>;
  city_contains?: Maybe<Scalars['String']>;
  city_not_contains?: Maybe<Scalars['String']>;
  city_starts_with?: Maybe<Scalars['String']>;
  city_not_starts_with?: Maybe<Scalars['String']>;
  city_ends_with?: Maybe<Scalars['String']>;
  city_not_ends_with?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCode_not?: Maybe<Scalars['String']>;
  postalCode_in?: Maybe<Array<Scalars['String']>>;
  postalCode_not_in?: Maybe<Array<Scalars['String']>>;
  postalCode_lt?: Maybe<Scalars['String']>;
  postalCode_lte?: Maybe<Scalars['String']>;
  postalCode_gt?: Maybe<Scalars['String']>;
  postalCode_gte?: Maybe<Scalars['String']>;
  postalCode_contains?: Maybe<Scalars['String']>;
  postalCode_not_contains?: Maybe<Scalars['String']>;
  postalCode_starts_with?: Maybe<Scalars['String']>;
  postalCode_not_starts_with?: Maybe<Scalars['String']>;
  postalCode_ends_with?: Maybe<Scalars['String']>;
  postalCode_not_ends_with?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Scalars['String']>>;
  phone_not_in?: Maybe<Array<Scalars['String']>>;
  phone_lt?: Maybe<Scalars['String']>;
  phone_lte?: Maybe<Scalars['String']>;
  phone_gt?: Maybe<Scalars['String']>;
  phone_gte?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_not?: Maybe<Scalars['String']>;
  country_in?: Maybe<Array<Scalars['String']>>;
  country_not_in?: Maybe<Array<Scalars['String']>>;
  country_lt?: Maybe<Scalars['String']>;
  country_lte?: Maybe<Scalars['String']>;
  country_gt?: Maybe<Scalars['String']>;
  country_gte?: Maybe<Scalars['String']>;
  country_contains?: Maybe<Scalars['String']>;
  country_not_contains?: Maybe<Scalars['String']>;
  country_starts_with?: Maybe<Scalars['String']>;
  country_not_starts_with?: Maybe<Scalars['String']>;
  country_ends_with?: Maybe<Scalars['String']>;
  country_not_ends_with?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_not?: Maybe<Scalars['String']>;
  region_in?: Maybe<Array<Scalars['String']>>;
  region_not_in?: Maybe<Array<Scalars['String']>>;
  region_lt?: Maybe<Scalars['String']>;
  region_lte?: Maybe<Scalars['String']>;
  region_gt?: Maybe<Scalars['String']>;
  region_gte?: Maybe<Scalars['String']>;
  region_contains?: Maybe<Scalars['String']>;
  region_not_contains?: Maybe<Scalars['String']>;
  region_starts_with?: Maybe<Scalars['String']>;
  region_not_starts_with?: Maybe<Scalars['String']>;
  region_ends_with?: Maybe<Scalars['String']>;
  region_not_ends_with?: Maybe<Scalars['String']>;
  team?: Maybe<TeamWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<AddressWhereInput>>;
  OR?: Maybe<Array<AddressWhereInput>>;
  NOT?: Maybe<Array<AddressWhereInput>>;
};

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Adjuster = {
  __typename?: 'Adjuster';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  appliesTo?: Maybe<AdjusterType>;
  percent?: Maybe<Scalars['Float']>;
};

export type AdjusterConnection = {
  __typename?: 'AdjusterConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AdjusterEdge>>;
  aggregate: AggregateAdjuster;
};

export type AdjusterCreateInput = {
  id?: Maybe<Scalars['ID']>;
  appliesTo?: Maybe<AdjusterType>;
  percent?: Maybe<Scalars['Float']>;
};

export type AdjusterCreateManyInput = {
  create?: Maybe<Array<AdjusterCreateInput>>;
  connect?: Maybe<Array<AdjusterWhereUniqueInput>>;
};

export type AdjusterEdge = {
  __typename?: 'AdjusterEdge';
  node: Adjuster;
  cursor: Scalars['String'];
};

export enum AdjusterOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  AppliesToAsc = 'appliesTo_ASC',
  AppliesToDesc = 'appliesTo_DESC',
  PercentAsc = 'percent_ASC',
  PercentDesc = 'percent_DESC'
}

export type AdjusterPreviousValues = {
  __typename?: 'AdjusterPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  appliesTo?: Maybe<AdjusterType>;
  percent?: Maybe<Scalars['Float']>;
};

export type AdjusterScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  appliesTo?: Maybe<AdjusterType>;
  appliesTo_not?: Maybe<AdjusterType>;
  appliesTo_in?: Maybe<Array<AdjusterType>>;
  appliesTo_not_in?: Maybe<Array<AdjusterType>>;
  percent?: Maybe<Scalars['Float']>;
  percent_not?: Maybe<Scalars['Float']>;
  percent_in?: Maybe<Array<Scalars['Float']>>;
  percent_not_in?: Maybe<Array<Scalars['Float']>>;
  percent_lt?: Maybe<Scalars['Float']>;
  percent_lte?: Maybe<Scalars['Float']>;
  percent_gt?: Maybe<Scalars['Float']>;
  percent_gte?: Maybe<Scalars['Float']>;
  AND?: Maybe<Array<AdjusterScalarWhereInput>>;
  OR?: Maybe<Array<AdjusterScalarWhereInput>>;
  NOT?: Maybe<Array<AdjusterScalarWhereInput>>;
};

export type AdjusterSubscriptionPayload = {
  __typename?: 'AdjusterSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Adjuster>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<AdjusterPreviousValues>;
};

export type AdjusterSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<AdjusterWhereInput>;
  AND?: Maybe<Array<AdjusterSubscriptionWhereInput>>;
  OR?: Maybe<Array<AdjusterSubscriptionWhereInput>>;
  NOT?: Maybe<Array<AdjusterSubscriptionWhereInput>>;
};

export type AdjusterTier = {
  __typename?: 'AdjusterTier';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<Merchant>;
  qualificationType?: Maybe<AdjusterType>;
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  adjusters?: Maybe<Array<Adjuster>>;
  createdBy?: Maybe<User>;
};


export type AdjusterTierAdjustersArgs = {
  where?: Maybe<AdjusterWhereInput>;
  orderBy?: Maybe<AdjusterOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AdjusterTierConnection = {
  __typename?: 'AdjusterTierConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AdjusterTierEdge>>;
  aggregate: AggregateAdjusterTier;
};

export type AdjusterTierCreateInput = {
  id?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantCreateOneInput>;
  qualificationType?: Maybe<AdjusterType>;
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  adjusters?: Maybe<AdjusterCreateManyInput>;
};

export type AdjusterTierEdge = {
  __typename?: 'AdjusterTierEdge';
  node: AdjusterTier;
  cursor: Scalars['String'];
};

export enum AdjusterTierOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  QualificationTypeAsc = 'qualificationType_ASC',
  QualificationTypeDesc = 'qualificationType_DESC',
  MinAsc = 'min_ASC',
  MinDesc = 'min_DESC',
  MaxAsc = 'max_ASC',
  MaxDesc = 'max_DESC'
}

export type AdjusterTierPreviousValues = {
  __typename?: 'AdjusterTierPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  qualificationType?: Maybe<AdjusterType>;
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type AdjusterTierSubscriptionPayload = {
  __typename?: 'AdjusterTierSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<AdjusterTier>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<AdjusterTierPreviousValues>;
};

export type AdjusterTierSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<AdjusterTierWhereInput>;
  AND?: Maybe<Array<AdjusterTierSubscriptionWhereInput>>;
  OR?: Maybe<Array<AdjusterTierSubscriptionWhereInput>>;
  NOT?: Maybe<Array<AdjusterTierSubscriptionWhereInput>>;
};

export type AdjusterTierUpdateInput = {
  qualificationType?: Maybe<AdjusterType>;
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  adjusters?: Maybe<AdjusterUpdateManyInput>;
};

export type AdjusterTierUpdateManyMutationInput = {
  qualificationType?: Maybe<AdjusterType>;
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type AdjusterTierWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<MerchantWhereInput>;
  qualificationType?: Maybe<AdjusterType>;
  qualificationType_not?: Maybe<AdjusterType>;
  qualificationType_in?: Maybe<Array<AdjusterType>>;
  qualificationType_not_in?: Maybe<Array<AdjusterType>>;
  min?: Maybe<Scalars['Float']>;
  min_not?: Maybe<Scalars['Float']>;
  min_in?: Maybe<Array<Scalars['Float']>>;
  min_not_in?: Maybe<Array<Scalars['Float']>>;
  min_lt?: Maybe<Scalars['Float']>;
  min_lte?: Maybe<Scalars['Float']>;
  min_gt?: Maybe<Scalars['Float']>;
  min_gte?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  max_not?: Maybe<Scalars['Float']>;
  max_in?: Maybe<Array<Scalars['Float']>>;
  max_not_in?: Maybe<Array<Scalars['Float']>>;
  max_lt?: Maybe<Scalars['Float']>;
  max_lte?: Maybe<Scalars['Float']>;
  max_gt?: Maybe<Scalars['Float']>;
  max_gte?: Maybe<Scalars['Float']>;
  adjusters_every?: Maybe<AdjusterWhereInput>;
  adjusters_some?: Maybe<AdjusterWhereInput>;
  adjusters_none?: Maybe<AdjusterWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<AdjusterTierWhereInput>>;
  OR?: Maybe<Array<AdjusterTierWhereInput>>;
  NOT?: Maybe<Array<AdjusterTierWhereInput>>;
};

export type AdjusterTierWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum AdjusterType {
  WholesaleGmv = 'WholesaleGMV',
  ConsumerFee = 'ConsumerFee'
}

export type AdjusterUpdateDataInput = {
  appliesTo?: Maybe<AdjusterType>;
  percent?: Maybe<Scalars['Float']>;
};

export type AdjusterUpdateInput = {
  appliesTo?: Maybe<AdjusterType>;
  percent?: Maybe<Scalars['Float']>;
};

export type AdjusterUpdateManyDataInput = {
  appliesTo?: Maybe<AdjusterType>;
  percent?: Maybe<Scalars['Float']>;
};

export type AdjusterUpdateManyInput = {
  create?: Maybe<Array<AdjusterCreateInput>>;
  update?: Maybe<Array<AdjusterUpdateWithWhereUniqueNestedInput>>;
};

export type AdjusterUpdateManyMutationInput = {
  appliesTo?: Maybe<AdjusterType>;
  percent?: Maybe<Scalars['Float']>;
};

export type AdjusterUpdateManyWithWhereNestedInput = {
  where: AdjusterScalarWhereInput;
  data: AdjusterUpdateManyDataInput;
};

export type AdjusterUpdateWithWhereUniqueNestedInput = {
  where: AdjusterWhereUniqueInput;
  data: AdjusterUpdateDataInput;
};

export type AdjusterUpsertWithWhereUniqueNestedInput = {
  where: AdjusterWhereUniqueInput;
  update: AdjusterUpdateDataInput;
  create: AdjusterCreateInput;
};

export type AdjusterWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  appliesTo?: Maybe<AdjusterType>;
  appliesTo_not?: Maybe<AdjusterType>;
  appliesTo_in?: Maybe<Array<AdjusterType>>;
  appliesTo_not_in?: Maybe<Array<AdjusterType>>;
  percent?: Maybe<Scalars['Float']>;
  percent_not?: Maybe<Scalars['Float']>;
  percent_in?: Maybe<Array<Scalars['Float']>>;
  percent_not_in?: Maybe<Array<Scalars['Float']>>;
  percent_lt?: Maybe<Scalars['Float']>;
  percent_lte?: Maybe<Scalars['Float']>;
  percent_gt?: Maybe<Scalars['Float']>;
  percent_gte?: Maybe<Scalars['Float']>;
  AND?: Maybe<Array<AdjusterWhereInput>>;
  OR?: Maybe<Array<AdjusterWhereInput>>;
  NOT?: Maybe<Array<AdjusterWhereInput>>;
};

export type AdjusterWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Adjustment = {
  __typename?: 'Adjustment';
  id: Scalars['ID'];
  type: AdjustmentType;
  reason?: Maybe<Scalars['String']>;
  reasonFreeform?: Maybe<Scalars['String']>;
  secondaryReason?: Maybe<Scalars['String']>;
  transaction?: Maybe<Transaction>;
  merchant?: Maybe<Merchant>;
  order?: Maybe<Order>;
};

export type AdjustmentConnection = {
  __typename?: 'AdjustmentConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AdjustmentEdge>>;
  aggregate: AggregateAdjustment;
};

export type AdjustmentCreateCustomInput = {
  type: AdjustmentType;
  reason?: Maybe<Scalars['String']>;
  reasonFreeform?: Maybe<Scalars['String']>;
  secondaryReason?: Maybe<Scalars['String']>;
  order?: Maybe<OrderWhereUniqueInput>;
  merchant: MerchantWhereUniqueInput;
  transaction: TransactionCreateInput;
};

export type AdjustmentCreateInput = {
  id?: Maybe<Scalars['ID']>;
  type: AdjustmentType;
  merchant?: Maybe<MerchantCreateOneInput>;
  order?: Maybe<OrderCreateOneInput>;
  reason?: Maybe<Scalars['String']>;
  reasonFreeform?: Maybe<Scalars['String']>;
  secondaryReason?: Maybe<Scalars['String']>;
};

export type AdjustmentCreateManyInput = {
  create?: Maybe<Array<AdjustmentCreateInput>>;
  connect?: Maybe<Array<AdjustmentWhereUniqueInput>>;
};

export type AdjustmentCreateManyWithoutOrderInput = {
  create?: Maybe<Array<AdjustmentCreateWithoutOrderInput>>;
  connect?: Maybe<Array<AdjustmentWhereUniqueInput>>;
};

export type AdjustmentCreateOneInput = {
  create?: Maybe<AdjustmentCreateInput>;
  connect?: Maybe<AdjustmentWhereUniqueInput>;
};

export type AdjustmentCreateOtherInput = {
  type: AdjustmentType;
  reason?: Maybe<Scalars['String']>;
  reasonFreeform?: Maybe<Scalars['String']>;
  secondaryReason?: Maybe<Scalars['String']>;
  order?: Maybe<OrderWhereUniqueInput>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type AdjustmentCreateRefundInput = {
  reason: Scalars['String'];
  reasonFreeform?: Maybe<Scalars['String']>;
  secondaryReason?: Maybe<Scalars['String']>;
  order: OrderWhereUniqueInput;
  grossAmount: Scalars['Float'];
  floomRemittance: Scalars['Float'];
  merchantRemittance: Scalars['Float'];
  floomFee: Scalars['Float'];
};

export type AdjustmentCreateWithoutOrderInput = {
  id?: Maybe<Scalars['ID']>;
  type: AdjustmentType;
  merchant?: Maybe<MerchantCreateOneInput>;
  reason?: Maybe<Scalars['String']>;
  reasonFreeform?: Maybe<Scalars['String']>;
  secondaryReason?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Json']>;
  createdBy?: Maybe<UserCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AdjustmentEdge = {
  __typename?: 'AdjustmentEdge';
  node: Adjustment;
  cursor: Scalars['String'];
};

export enum AdjustmentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC'
}

export type AdjustmentPreviousValues = {
  __typename?: 'AdjustmentPreviousValues';
  id: Scalars['ID'];
  type: AdjustmentType;
  reason?: Maybe<Scalars['String']>;
  reasonFreeform?: Maybe<Scalars['String']>;
  secondaryReason?: Maybe<Scalars['String']>;
};

export enum AdjustmentType {
  Refund = 'Refund',
  PenaltyFee = 'PenaltyFee',
  Stationery = 'Stationery',
  FloristSwap = 'FloristSwap',
  Charge = 'Charge',
  RedeliveryFees = 'RedeliveryFees',
  ProductUpgrade = 'ProductUpgrade'
}

export type AdjustmentUpdateDataInput = {
  type?: Maybe<AdjustmentType>;
  reason?: Maybe<Scalars['String']>;
  reasonFreeform?: Maybe<Scalars['String']>;
  secondaryReason?: Maybe<Scalars['String']>;
};

export type AdjustmentUpdateInput = {
  type?: Maybe<AdjustmentType>;
  reason?: Maybe<Scalars['String']>;
  reasonFreeform?: Maybe<Scalars['String']>;
  secondaryReason?: Maybe<Scalars['String']>;
};

export type AdjustmentUpdateManyInput = {
  create?: Maybe<Array<AdjustmentCreateInput>>;
  update?: Maybe<Array<AdjustmentUpdateInput>>;
  delete?: Maybe<Array<AdjustmentWhereUniqueInput>>;
  connect?: Maybe<Array<AdjustmentWhereUniqueInput>>;
  set?: Maybe<Array<AdjustmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<AdjustmentWhereUniqueInput>>;
};

export type AdjustmentUpdateOneInput = {
  create?: Maybe<AdjustmentCreateInput>;
  update?: Maybe<AdjustmentUpdateDataInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<AdjustmentWhereUniqueInput>;
};

export type AdjustmentWhereInput = {
  AND?: Maybe<Array<AdjustmentWhereInput>>;
  NOT?: Maybe<Array<AdjustmentWhereInput>>;
  OR?: Maybe<Array<AdjustmentWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  reason?: Maybe<Scalars['String']>;
  reason_contains?: Maybe<Scalars['String']>;
  reason_ends_with?: Maybe<Scalars['String']>;
  reason_gt?: Maybe<Scalars['String']>;
  reason_gte?: Maybe<Scalars['String']>;
  reason_in?: Maybe<Array<Scalars['String']>>;
  reason_lt?: Maybe<Scalars['String']>;
  reason_lte?: Maybe<Scalars['String']>;
  reason_not?: Maybe<Scalars['String']>;
  reason_not_contains?: Maybe<Scalars['String']>;
  reason_not_ends_with?: Maybe<Scalars['String']>;
  reason_not_in?: Maybe<Array<Scalars['String']>>;
  reason_not_starts_with?: Maybe<Scalars['String']>;
  reason_starts_with?: Maybe<Scalars['String']>;
  transaction?: Maybe<TransactionWhereInput>;
  type?: Maybe<AdjustmentType>;
  type_in?: Maybe<Array<AdjustmentType>>;
  type_not?: Maybe<AdjustmentType>;
  type_not_in?: Maybe<Array<AdjustmentType>>;
};

export type AdjustmentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type AggregateActionLog = {
  __typename?: 'AggregateActionLog';
  count: Scalars['Int'];
};

export type AggregateAddon = {
  __typename?: 'AggregateAddon';
  count: Scalars['Int'];
};

export type AggregateAddonCategory = {
  __typename?: 'AggregateAddonCategory';
  count: Scalars['Int'];
};

export type AggregateAddress = {
  __typename?: 'AggregateAddress';
  count: Scalars['Int'];
};

export type AggregateAdjuster = {
  __typename?: 'AggregateAdjuster';
  count: Scalars['Int'];
};

export type AggregateAdjusterTier = {
  __typename?: 'AggregateAdjusterTier';
  count: Scalars['Int'];
};

export type AggregateAdjustment = {
  __typename?: 'AggregateAdjustment';
  count: Scalars['Int'];
};

export type AggregateCatalogItem = {
  __typename?: 'AggregateCatalogItem';
  count: Scalars['Int'];
};

export type AggregateCatalogItemFamily = {
  __typename?: 'AggregateCatalogItemFamily';
  count: Scalars['Int'];
};

export type AggregateCatalogItemName = {
  __typename?: 'AggregateCatalogItemName';
  count: Scalars['Int'];
};

export type AggregateCatalogItemRef = {
  __typename?: 'AggregateCatalogItemRef';
  count: Scalars['Int'];
};

export type AggregateCatalogItemTag = {
  __typename?: 'AggregateCatalogItemTag';
  count: Scalars['Int'];
};

export type AggregateChannelType = {
  __typename?: 'AggregateChannelType';
  count: Scalars['Int'];
};

export type AggregateCollectionSellerProductConfig = {
  __typename?: 'AggregateCollectionSellerProductConfig';
  count: Scalars['Int'];
};

export type AggregateCollectionSellerProductVariationConfig = {
  __typename?: 'AggregateCollectionSellerProductVariationConfig';
  count: Scalars['Int'];
};

export type AggregateColour = {
  __typename?: 'AggregateColour';
  count: Scalars['Int'];
};

export type AggregateComponent = {
  __typename?: 'AggregateComponent';
  count: Scalars['Int'];
};

export type AggregateComponentConfig = {
  __typename?: 'AggregateComponentConfig';
  count: Scalars['Int'];
};

export type AggregateCountry = {
  __typename?: 'AggregateCountry';
  count: Scalars['Int'];
};

export type AggregateCourier = {
  __typename?: 'AggregateCourier';
  count: Scalars['Int'];
};

export type AggregateCourierBooking = {
  __typename?: 'AggregateCourierBooking';
  count: Scalars['Int'];
};

export type AggregateCourierStatusHistory = {
  __typename?: 'AggregateCourierStatusHistory';
  count: Scalars['Int'];
};

export type AggregateCourierTimeSlot = {
  __typename?: 'AggregateCourierTimeSlot';
  count: Scalars['Int'];
};

export type AggregateCustomSiteConfig = {
  __typename?: 'AggregateCustomSiteConfig';
  count: Scalars['Int'];
};

export type AggregateCustomer = {
  __typename?: 'AggregateCustomer';
  count: Scalars['Int'];
};

export type AggregateDeliveryConfig = {
  __typename?: 'AggregateDeliveryConfig';
  count: Scalars['Int'];
};

export type AggregateDeliveryCoverage = {
  __typename?: 'AggregateDeliveryCoverage';
  count: Scalars['Int'];
};

export type AggregateDeliveryPricing = {
  __typename?: 'AggregateDeliveryPricing';
  count: Scalars['Int'];
};

export type AggregateDeliveryTiming = {
  __typename?: 'AggregateDeliveryTiming';
  count: Scalars['Int'];
};

export type AggregateDeliveryZone = {
  __typename?: 'AggregateDeliveryZone';
  count: Scalars['Int'];
};

export type AggregateDimensions = {
  __typename?: 'AggregateDimensions';
  count: Scalars['Int'];
};

export type AggregateDiscount = {
  __typename?: 'AggregateDiscount';
  count: Scalars['Int'];
};

export type AggregateGenus = {
  __typename?: 'AggregateGenus';
  count: Scalars['Int'];
};

export type AggregateGeoLocation = {
  __typename?: 'AggregateGeoLocation';
  count: Scalars['Int'];
};

export type AggregateGrower = {
  __typename?: 'AggregateGrower';
  count: Scalars['Int'];
};

export type AggregateIntegrationAuth = {
  __typename?: 'AggregateIntegrationAuth';
  count: Scalars['Int'];
};

export type AggregateInvoice = {
  __typename?: 'AggregateInvoice';
  count: Scalars['Int'];
};

export type AggregateList = {
  __typename?: 'AggregateList';
  count: Scalars['Int'];
};

export type AggregateListItem = {
  __typename?: 'AggregateListItem';
  count: Scalars['Int'];
};

export type AggregateListItemDecoration = {
  __typename?: 'AggregateListItemDecoration';
  count: Scalars['Int'];
};

export type AggregateListItemPlant = {
  __typename?: 'AggregateListItemPlant';
  count: Scalars['Int'];
};

export type AggregateListItemSundry = {
  __typename?: 'AggregateListItemSundry';
  count: Scalars['Int'];
};

export type AggregateLondonOutcodeToTflZone = {
  __typename?: 'AggregateLondonOutcodeToTFLZone';
  count: Scalars['Int'];
};

export type AggregateMailchimpIntegrationMeta = {
  __typename?: 'AggregateMailchimpIntegrationMeta';
  count: Scalars['Int'];
};

export type AggregateMedia = {
  __typename?: 'AggregateMedia';
  count: Scalars['Int'];
};

export type AggregateMerchant = {
  __typename?: 'AggregateMerchant';
  count: Scalars['Int'];
};

export type AggregateMerchantHoliday = {
  __typename?: 'AggregateMerchantHoliday';
  count: Scalars['Int'];
};

export type AggregateMerchantPlan = {
  __typename?: 'AggregateMerchantPlan';
  count: Scalars['Int'];
};

export type AggregateMerchantRecurringPayment = {
  __typename?: 'AggregateMerchantRecurringPayment';
  count: Scalars['Int'];
};

export type AggregateMerchantSupplierIntegration = {
  __typename?: 'AggregateMerchantSupplierIntegration';
  count: Scalars['Int'];
};

export type AggregateOrder = {
  __typename?: 'AggregateOrder';
  count: Scalars['Int'];
};

export type AggregateOrderDeliveryConfig = {
  __typename?: 'AggregateOrderDeliveryConfig';
  count: Scalars['Int'];
};

export type AggregateOrderDiscount = {
  __typename?: 'AggregateOrderDiscount';
  count: Scalars['Int'];
};

export type AggregateOrderItem = {
  __typename?: 'AggregateOrderItem';
  count: Scalars['Int'];
};

export type AggregateOrderNote = {
  __typename?: 'AggregateOrderNote';
  count: Scalars['Int'];
};

export type AggregateOrderStatus = {
  __typename?: 'AggregateOrderStatus';
  count: Scalars['Int'];
};

export type AggregateOrderTax = {
  __typename?: 'AggregateOrderTax';
  count: Scalars['Int'];
};

export type AggregatePayment = {
  __typename?: 'AggregatePayment';
  count: Scalars['Int'];
};

export type AggregatePaymentMethod = {
  __typename?: 'AggregatePaymentMethod';
  count: Scalars['Int'];
};

export type AggregateProduct = {
  __typename?: 'AggregateProduct';
  count: Scalars['Int'];
};

export type AggregateProductCategory = {
  __typename?: 'AggregateProductCategory';
  count: Scalars['Int'];
};

export type AggregateProductType = {
  __typename?: 'AggregateProductType';
  count: Scalars['Int'];
};

export type AggregateProductVariation = {
  __typename?: 'AggregateProductVariation';
  count: Scalars['Int'];
};

export type AggregateProposedMerchantUpdate = {
  __typename?: 'AggregateProposedMerchantUpdate';
  count: Scalars['Int'];
};

export type AggregatePushNotificationRegistration = {
  __typename?: 'AggregatePushNotificationRegistration';
  count: Scalars['Int'];
};

export type AggregateRecipe = {
  __typename?: 'AggregateRecipe';
  count: Scalars['Int'];
};

export type AggregateRecipeItem = {
  __typename?: 'AggregateRecipeItem';
  count: Scalars['Int'];
};

export type AggregateRemittanceAccount = {
  __typename?: 'AggregateRemittanceAccount';
  count: Scalars['Int'];
};

export type AggregateRemittancePeriod = {
  __typename?: 'AggregateRemittancePeriod';
  count: Scalars['Int'];
};

export type AggregateRemittanceTransfer = {
  __typename?: 'AggregateRemittanceTransfer';
  count: Scalars['Int'];
};

export type AggregateReview = {
  __typename?: 'AggregateReview';
  count: Scalars['Int'];
};

export type AggregateShop = {
  __typename?: 'AggregateShop';
  count: Scalars['Int'];
};

export type AggregateStemLength = {
  __typename?: 'AggregateStemLength';
  count: Scalars['Int'];
};

export type AggregateSupplier = {
  __typename?: 'AggregateSupplier';
  count: Scalars['Int'];
};

export type AggregateSupplierDeliveryConfig = {
  __typename?: 'AggregateSupplierDeliveryConfig';
  count: Scalars['Int'];
};

export type AggregateSupplierDeliveryDayConfig = {
  __typename?: 'AggregateSupplierDeliveryDayConfig';
  count: Scalars['Int'];
};

export type AggregateSupplierDeliveryHub = {
  __typename?: 'AggregateSupplierDeliveryHub';
  count: Scalars['Int'];
};

export type AggregateSupplierDeliveryOption = {
  __typename?: 'AggregateSupplierDeliveryOption';
  count: Scalars['Int'];
};

export type AggregateTeam = {
  __typename?: 'AggregateTeam';
  count: Scalars['Int'];
};

export type AggregateTradeSku = {
  __typename?: 'AggregateTradeSku';
  count: Scalars['Int'];
};

export type AggregateTradeSkuAvailability = {
  __typename?: 'AggregateTradeSkuAvailability';
  count: Scalars['Int'];
};

export type AggregateTradeSkuDecoration = {
  __typename?: 'AggregateTradeSkuDecoration';
  count: Scalars['Int'];
};

export type AggregateTradeSkuFlower = {
  __typename?: 'AggregateTradeSkuFlower';
  count: Scalars['Int'];
};

export type AggregateTradeSkuOther = {
  __typename?: 'AggregateTradeSkuOther';
  count: Scalars['Int'];
};

export type AggregateTradeSkuPlant = {
  __typename?: 'AggregateTradeSkuPlant';
  count: Scalars['Int'];
};

export type AggregateTradeSkuPrice = {
  __typename?: 'AggregateTradeSkuPrice';
  count: Scalars['Int'];
};

export type AggregateTradeSkuSundry = {
  __typename?: 'AggregateTradeSkuSundry';
  count: Scalars['Int'];
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  count: Scalars['Int'];
};

export type AggregateUrbItFixedPriceTable = {
  __typename?: 'AggregateUrbItFixedPriceTable';
  count: Scalars['Int'];
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  count: Scalars['Int'];
};

export type AggregateWeeblyConfig = {
  __typename?: 'AggregateWeeblyConfig';
  count: Scalars['Int'];
};

export type AggregateWholesaleCategory = {
  __typename?: 'AggregateWholesaleCategory';
  count: Scalars['Int'];
};

export type AggregateWholesaleDelivery = {
  __typename?: 'AggregateWholesaleDelivery';
  count: Scalars['Int'];
};

export type AggregateWholesaleDeliveryConfig = {
  __typename?: 'AggregateWholesaleDeliveryConfig';
  count: Scalars['Int'];
};

export type AggregateWholesaleFavourite = {
  __typename?: 'AggregateWholesaleFavourite';
  count: Scalars['Int'];
};

export type AggregateWholesaleFeature = {
  __typename?: 'AggregateWholesaleFeature';
  count: Scalars['Int'];
};

export type AggregateWholesaleOrder = {
  __typename?: 'AggregateWholesaleOrder';
  count: Scalars['Int'];
};

export type AggregateWholesaleOrderLineItem = {
  __typename?: 'AggregateWholesaleOrderLineItem';
  count: Scalars['Int'];
};

export type AggregateWholesalePreOrder = {
  __typename?: 'AggregateWholesalePreOrder';
  count: Scalars['Int'];
};

export type AggregateWholesalePreOrderItem = {
  __typename?: 'AggregateWholesalePreOrderItem';
  count: Scalars['Int'];
};

export type AggregateWholesaleTradeLineItem = {
  __typename?: 'AggregateWholesaleTradeLineItem';
  count: Scalars['Int'];
};

export type AllocTransactionUpdateInput = {
  merchant?: Maybe<MerchantWhereUniqueInput>;
  order?: Maybe<OrderWhereUniqueInput>;
  notes?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  grossAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  merchantRemittance: Scalars['Float'];
  floomRemittance: Scalars['Float'];
  isReviewRequired: Scalars['Boolean'];
};

export type AssignTransactionUpdateInput = {
  merchant?: Maybe<MerchantWhereUniqueInput>;
  order?: Maybe<OrderWhereUniqueInput>;
  notes?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  remittanceType?: Maybe<TransactionRemittanceType>;
  remittanceTransfer?: Maybe<RemittanceTransferWhereUniqueInput>;
  isReviewRequired: Scalars['Boolean'];
};

export type AuthStripeConnectInput = {
  code?: Maybe<Scalars['String']>;
};

export type AvailableCourierDeliveryTimeSlot = {
  __typename?: 'AvailableCourierDeliveryTimeSlot';
  courierId: Scalars['String'];
  courierRef: Scalars['String'];
  pickupDate: Scalars['DateTime'];
  pickupMinDelta?: Maybe<Scalars['Float']>;
  pickupMaxDelta?: Maybe<Scalars['Float']>;
  deliveryDate: Scalars['DateTime'];
  deliveryMinDelta?: Maybe<Scalars['Float']>;
  deliveryMaxDelta?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  currency: Scalars['String'];
  courierName: Scalars['String'];
  courierCode: Scalars['String'];
  transportType: Scalars['String'];
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Long'];
};

export type CartAddOnInput = {
  id?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type CartResponse = {
  __typename?: 'CartResponse';
  errors?: Maybe<Array<Maybe<FxErrorDef>>>;
  cart?: Maybe<Order>;
  deliveryDates?: Maybe<Array<Maybe<DeliveryDateOption>>>;
};

export type CatalogItem = {
  __typename?: 'CatalogItem';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  colour: Colour;
  family: CatalogItemFamily;
  subType?: Maybe<Scalars['String']>;
  type: CatalogItemType;
  name?: Maybe<Array<CatalogItemName>>;
  media?: Maybe<Array<Media>>;
  ref?: Maybe<CatalogItemRef>;
  tradeSku?: Maybe<Array<TradeSku>>;
  tag?: Maybe<Array<CatalogItemTag>>;
  primaryName?: Maybe<Scalars['String']>;
  secondaryName?: Maybe<Scalars['String']>;
};


export type CatalogItemNameArgs = {
  where?: Maybe<CatalogItemNameWhereInput>;
  orderBy?: Maybe<CatalogItemNameOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CatalogItemMediaArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<MediaOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CatalogItemTradeSkuArgs = {
  where?: Maybe<TradeSkuWhereInput>;
  orderBy?: Maybe<TradeSkuOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CatalogItemTagArgs = {
  where?: Maybe<CatalogItemTagWhereInput>;
  orderBy?: Maybe<CatalogItemTagOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CatalogItemConnectOneInput = {
  connect?: Maybe<CatalogItemWhereUniqueInput>;
};

export type CatalogItemConnection = {
  __typename?: 'CatalogItemConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CatalogItemEdge>>;
  aggregate: AggregateCatalogItem;
};

export type CatalogItemCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  colour: ColourCreateOneInput;
  family: CatalogItemFamilyCreateOneInput;
  subType?: Maybe<Scalars['String']>;
  type: CatalogItemType;
  name?: Maybe<CatalogItemNameCreateManyInput>;
  media?: Maybe<MediaCreateManyInput>;
  ref?: Maybe<CatalogItemRefCreateOneInput>;
  tradeSku?: Maybe<TradeSkuCreateManyInput>;
  tag?: Maybe<CatalogItemTagCreateManyInput>;
  primaryName?: Maybe<Scalars['String']>;
};

export type CatalogItemCreateOneInput = {
  create?: Maybe<CatalogItemCreateInput>;
  connect?: Maybe<CatalogItemWhereUniqueInput>;
};

export type CatalogItemEdge = {
  __typename?: 'CatalogItemEdge';
  node: CatalogItem;
  cursor: Scalars['String'];
};

export type CatalogItemFamily = {
  __typename?: 'CatalogItemFamily';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  cultivar?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type CatalogItemFamilyConnection = {
  __typename?: 'CatalogItemFamilyConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CatalogItemFamilyEdge>>;
  aggregate: AggregateCatalogItemFamily;
};

export type CatalogItemFamilyCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  cultivar?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type CatalogItemFamilyCreateOneInput = {
  create?: Maybe<CatalogItemFamilyCreateInput>;
  connect?: Maybe<CatalogItemFamilyWhereUniqueInput>;
};

export type CatalogItemFamilyEdge = {
  __typename?: 'CatalogItemFamilyEdge';
  node: CatalogItemFamily;
  cursor: Scalars['String'];
};

export enum CatalogItemFamilyOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  CultivarAsc = 'cultivar_ASC',
  CultivarDesc = 'cultivar_DESC',
  GenusAsc = 'genus_ASC',
  GenusDesc = 'genus_DESC',
  SpeciesAsc = 'species_ASC',
  SpeciesDesc = 'species_DESC'
}

export type CatalogItemFamilyPreviousValues = {
  __typename?: 'CatalogItemFamilyPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  cultivar?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type CatalogItemFamilySubscriptionPayload = {
  __typename?: 'CatalogItemFamilySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CatalogItemFamily>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CatalogItemFamilyPreviousValues>;
};

export type CatalogItemFamilySubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CatalogItemFamilyWhereInput>;
  AND?: Maybe<Array<CatalogItemFamilySubscriptionWhereInput>>;
  OR?: Maybe<Array<CatalogItemFamilySubscriptionWhereInput>>;
  NOT?: Maybe<Array<CatalogItemFamilySubscriptionWhereInput>>;
};

export type CatalogItemFamilyUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  cultivar?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type CatalogItemFamilyUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  cultivar?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type CatalogItemFamilyUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  cultivar?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
};

export type CatalogItemFamilyUpdateOneRequiredInput = {
  create?: Maybe<CatalogItemFamilyCreateInput>;
  update?: Maybe<CatalogItemFamilyUpdateDataInput>;
  upsert?: Maybe<CatalogItemFamilyUpsertNestedInput>;
  connect?: Maybe<CatalogItemFamilyWhereUniqueInput>;
};

export type CatalogItemFamilyUpsertNestedInput = {
  update: CatalogItemFamilyUpdateDataInput;
  create: CatalogItemFamilyCreateInput;
};

export type CatalogItemFamilyWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  cultivar?: Maybe<Scalars['String']>;
  cultivar_not?: Maybe<Scalars['String']>;
  cultivar_in?: Maybe<Array<Scalars['String']>>;
  cultivar_not_in?: Maybe<Array<Scalars['String']>>;
  cultivar_lt?: Maybe<Scalars['String']>;
  cultivar_lte?: Maybe<Scalars['String']>;
  cultivar_gt?: Maybe<Scalars['String']>;
  cultivar_gte?: Maybe<Scalars['String']>;
  cultivar_contains?: Maybe<Scalars['String']>;
  cultivar_not_contains?: Maybe<Scalars['String']>;
  cultivar_starts_with?: Maybe<Scalars['String']>;
  cultivar_not_starts_with?: Maybe<Scalars['String']>;
  cultivar_ends_with?: Maybe<Scalars['String']>;
  cultivar_not_ends_with?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  genus_not?: Maybe<Scalars['String']>;
  genus_in?: Maybe<Array<Scalars['String']>>;
  genus_not_in?: Maybe<Array<Scalars['String']>>;
  genus_lt?: Maybe<Scalars['String']>;
  genus_lte?: Maybe<Scalars['String']>;
  genus_gt?: Maybe<Scalars['String']>;
  genus_gte?: Maybe<Scalars['String']>;
  genus_contains?: Maybe<Scalars['String']>;
  genus_not_contains?: Maybe<Scalars['String']>;
  genus_starts_with?: Maybe<Scalars['String']>;
  genus_not_starts_with?: Maybe<Scalars['String']>;
  genus_ends_with?: Maybe<Scalars['String']>;
  genus_not_ends_with?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  species_not?: Maybe<Scalars['String']>;
  species_in?: Maybe<Array<Scalars['String']>>;
  species_not_in?: Maybe<Array<Scalars['String']>>;
  species_lt?: Maybe<Scalars['String']>;
  species_lte?: Maybe<Scalars['String']>;
  species_gt?: Maybe<Scalars['String']>;
  species_gte?: Maybe<Scalars['String']>;
  species_contains?: Maybe<Scalars['String']>;
  species_not_contains?: Maybe<Scalars['String']>;
  species_starts_with?: Maybe<Scalars['String']>;
  species_not_starts_with?: Maybe<Scalars['String']>;
  species_ends_with?: Maybe<Scalars['String']>;
  species_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<CatalogItemFamilyWhereInput>>;
  OR?: Maybe<Array<CatalogItemFamilyWhereInput>>;
  NOT?: Maybe<Array<CatalogItemFamilyWhereInput>>;
};

export type CatalogItemFamilyWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CatalogItemName = {
  __typename?: 'CatalogItemName';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type CatalogItemNameConnection = {
  __typename?: 'CatalogItemNameConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CatalogItemNameEdge>>;
  aggregate: AggregateCatalogItemName;
};

export type CatalogItemNameCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type CatalogItemNameCreateManyInput = {
  create?: Maybe<Array<CatalogItemNameCreateInput>>;
  connect?: Maybe<Array<CatalogItemNameWhereUniqueInput>>;
};

export type CatalogItemNameEdge = {
  __typename?: 'CatalogItemNameEdge';
  node: CatalogItemName;
  cursor: Scalars['String'];
};

export enum CatalogItemNameOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type CatalogItemNamePreviousValues = {
  __typename?: 'CatalogItemNamePreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type CatalogItemNameScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<CatalogItemNameScalarWhereInput>>;
  OR?: Maybe<Array<CatalogItemNameScalarWhereInput>>;
  NOT?: Maybe<Array<CatalogItemNameScalarWhereInput>>;
};

export type CatalogItemNameSubscriptionPayload = {
  __typename?: 'CatalogItemNameSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CatalogItemName>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CatalogItemNamePreviousValues>;
};

export type CatalogItemNameSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CatalogItemNameWhereInput>;
  AND?: Maybe<Array<CatalogItemNameSubscriptionWhereInput>>;
  OR?: Maybe<Array<CatalogItemNameSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CatalogItemNameSubscriptionWhereInput>>;
};

export type CatalogItemNameUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type CatalogItemNameUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type CatalogItemNameUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type CatalogItemNameUpdateManyInput = {
  create?: Maybe<Array<CatalogItemNameCreateInput>>;
  update?: Maybe<Array<CatalogItemNameUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<CatalogItemNameUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<CatalogItemNameWhereUniqueInput>>;
  connect?: Maybe<Array<CatalogItemNameWhereUniqueInput>>;
  set?: Maybe<Array<CatalogItemNameWhereUniqueInput>>;
  disconnect?: Maybe<Array<CatalogItemNameWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CatalogItemNameScalarWhereInput>>;
  updateMany?: Maybe<Array<CatalogItemNameUpdateManyWithWhereNestedInput>>;
};

export type CatalogItemNameUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type CatalogItemNameUpdateManyWithWhereNestedInput = {
  where: CatalogItemNameScalarWhereInput;
  data: CatalogItemNameUpdateManyDataInput;
};

export type CatalogItemNameUpdateWithWhereUniqueNestedInput = {
  where: CatalogItemNameWhereUniqueInput;
  data: CatalogItemNameUpdateDataInput;
};

export type CatalogItemNameUpsertWithWhereUniqueNestedInput = {
  where: CatalogItemNameWhereUniqueInput;
  update: CatalogItemNameUpdateDataInput;
  create: CatalogItemNameCreateInput;
};

export type CatalogItemNameWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<CatalogItemNameWhereInput>>;
  OR?: Maybe<Array<CatalogItemNameWhereInput>>;
  NOT?: Maybe<Array<CatalogItemNameWhereInput>>;
};

export type CatalogItemNameWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum CatalogItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  SubTypeAsc = 'subType_ASC',
  SubTypeDesc = 'subType_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  PrimaryNameAsc = 'primaryName_ASC',
  PrimaryNameDesc = 'primaryName_DESC'
}

export type CatalogItemPreviousValues = {
  __typename?: 'CatalogItemPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  subType?: Maybe<Scalars['String']>;
  type: CatalogItemType;
  primaryName?: Maybe<Scalars['String']>;
};

export type CatalogItemRef = {
  __typename?: 'CatalogItemRef';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalRef: Scalars['String'];
  sourceType: Scalars['String'];
};

export type CatalogItemRefConnection = {
  __typename?: 'CatalogItemRefConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CatalogItemRefEdge>>;
  aggregate: AggregateCatalogItemRef;
};

export type CatalogItemRefCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalRef: Scalars['String'];
  sourceType: Scalars['String'];
};

export type CatalogItemRefCreateOneInput = {
  create?: Maybe<CatalogItemRefCreateInput>;
  connect?: Maybe<CatalogItemRefWhereUniqueInput>;
};

export type CatalogItemRefEdge = {
  __typename?: 'CatalogItemRefEdge';
  node: CatalogItemRef;
  cursor: Scalars['String'];
};

export enum CatalogItemRefOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ExternalRefAsc = 'externalRef_ASC',
  ExternalRefDesc = 'externalRef_DESC',
  SourceTypeAsc = 'sourceType_ASC',
  SourceTypeDesc = 'sourceType_DESC'
}

export type CatalogItemRefPreviousValues = {
  __typename?: 'CatalogItemRefPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalRef: Scalars['String'];
  sourceType: Scalars['String'];
};

export type CatalogItemRefSubscriptionPayload = {
  __typename?: 'CatalogItemRefSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CatalogItemRef>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CatalogItemRefPreviousValues>;
};

export type CatalogItemRefSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CatalogItemRefWhereInput>;
  AND?: Maybe<Array<CatalogItemRefSubscriptionWhereInput>>;
  OR?: Maybe<Array<CatalogItemRefSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CatalogItemRefSubscriptionWhereInput>>;
};

export type CatalogItemRefUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalRef?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
};

export type CatalogItemRefUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalRef?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
};

export type CatalogItemRefUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalRef?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
};

export type CatalogItemRefUpdateOneInput = {
  create?: Maybe<CatalogItemRefCreateInput>;
  update?: Maybe<CatalogItemRefUpdateDataInput>;
  upsert?: Maybe<CatalogItemRefUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CatalogItemRefWhereUniqueInput>;
};

export type CatalogItemRefUpsertNestedInput = {
  update: CatalogItemRefUpdateDataInput;
  create: CatalogItemRefCreateInput;
};

export type CatalogItemRefWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  externalRef?: Maybe<Scalars['String']>;
  externalRef_not?: Maybe<Scalars['String']>;
  externalRef_in?: Maybe<Array<Scalars['String']>>;
  externalRef_not_in?: Maybe<Array<Scalars['String']>>;
  externalRef_lt?: Maybe<Scalars['String']>;
  externalRef_lte?: Maybe<Scalars['String']>;
  externalRef_gt?: Maybe<Scalars['String']>;
  externalRef_gte?: Maybe<Scalars['String']>;
  externalRef_contains?: Maybe<Scalars['String']>;
  externalRef_not_contains?: Maybe<Scalars['String']>;
  externalRef_starts_with?: Maybe<Scalars['String']>;
  externalRef_not_starts_with?: Maybe<Scalars['String']>;
  externalRef_ends_with?: Maybe<Scalars['String']>;
  externalRef_not_ends_with?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  sourceType_not?: Maybe<Scalars['String']>;
  sourceType_in?: Maybe<Array<Scalars['String']>>;
  sourceType_not_in?: Maybe<Array<Scalars['String']>>;
  sourceType_lt?: Maybe<Scalars['String']>;
  sourceType_lte?: Maybe<Scalars['String']>;
  sourceType_gt?: Maybe<Scalars['String']>;
  sourceType_gte?: Maybe<Scalars['String']>;
  sourceType_contains?: Maybe<Scalars['String']>;
  sourceType_not_contains?: Maybe<Scalars['String']>;
  sourceType_starts_with?: Maybe<Scalars['String']>;
  sourceType_not_starts_with?: Maybe<Scalars['String']>;
  sourceType_ends_with?: Maybe<Scalars['String']>;
  sourceType_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<CatalogItemRefWhereInput>>;
  OR?: Maybe<Array<CatalogItemRefWhereInput>>;
  NOT?: Maybe<Array<CatalogItemRefWhereInput>>;
};

export type CatalogItemRefWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CatalogItemSubscriptionPayload = {
  __typename?: 'CatalogItemSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CatalogItem>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CatalogItemPreviousValues>;
};

export type CatalogItemSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CatalogItemWhereInput>;
  AND?: Maybe<Array<CatalogItemSubscriptionWhereInput>>;
  OR?: Maybe<Array<CatalogItemSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CatalogItemSubscriptionWhereInput>>;
};

export type CatalogItemTag = {
  __typename?: 'CatalogItemTag';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CatalogItemTagConnection = {
  __typename?: 'CatalogItemTagConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CatalogItemTagEdge>>;
  aggregate: AggregateCatalogItemTag;
};

export type CatalogItemTagCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CatalogItemTagCreateManyInput = {
  create?: Maybe<Array<CatalogItemTagCreateInput>>;
  connect?: Maybe<Array<CatalogItemTagWhereUniqueInput>>;
};

export type CatalogItemTagEdge = {
  __typename?: 'CatalogItemTagEdge';
  node: CatalogItemTag;
  cursor: Scalars['String'];
};

export enum CatalogItemTagOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type CatalogItemTagPreviousValues = {
  __typename?: 'CatalogItemTagPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CatalogItemTagScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  value_not?: Maybe<Scalars['String']>;
  value_in?: Maybe<Array<Scalars['String']>>;
  value_not_in?: Maybe<Array<Scalars['String']>>;
  value_lt?: Maybe<Scalars['String']>;
  value_lte?: Maybe<Scalars['String']>;
  value_gt?: Maybe<Scalars['String']>;
  value_gte?: Maybe<Scalars['String']>;
  value_contains?: Maybe<Scalars['String']>;
  value_not_contains?: Maybe<Scalars['String']>;
  value_starts_with?: Maybe<Scalars['String']>;
  value_not_starts_with?: Maybe<Scalars['String']>;
  value_ends_with?: Maybe<Scalars['String']>;
  value_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<CatalogItemTagScalarWhereInput>>;
  OR?: Maybe<Array<CatalogItemTagScalarWhereInput>>;
  NOT?: Maybe<Array<CatalogItemTagScalarWhereInput>>;
};

export type CatalogItemTagSubscriptionPayload = {
  __typename?: 'CatalogItemTagSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CatalogItemTag>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CatalogItemTagPreviousValues>;
};

export type CatalogItemTagSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CatalogItemTagWhereInput>;
  AND?: Maybe<Array<CatalogItemTagSubscriptionWhereInput>>;
  OR?: Maybe<Array<CatalogItemTagSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CatalogItemTagSubscriptionWhereInput>>;
};

export type CatalogItemTagUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CatalogItemTagUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CatalogItemTagUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CatalogItemTagUpdateManyInput = {
  create?: Maybe<Array<CatalogItemTagCreateInput>>;
  update?: Maybe<Array<CatalogItemTagUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<CatalogItemTagUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<CatalogItemTagWhereUniqueInput>>;
  connect?: Maybe<Array<CatalogItemTagWhereUniqueInput>>;
  set?: Maybe<Array<CatalogItemTagWhereUniqueInput>>;
  disconnect?: Maybe<Array<CatalogItemTagWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CatalogItemTagScalarWhereInput>>;
  updateMany?: Maybe<Array<CatalogItemTagUpdateManyWithWhereNestedInput>>;
};

export type CatalogItemTagUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CatalogItemTagUpdateManyWithWhereNestedInput = {
  where: CatalogItemTagScalarWhereInput;
  data: CatalogItemTagUpdateManyDataInput;
};

export type CatalogItemTagUpdateWithWhereUniqueNestedInput = {
  where: CatalogItemTagWhereUniqueInput;
  data: CatalogItemTagUpdateDataInput;
};

export type CatalogItemTagUpsertWithWhereUniqueNestedInput = {
  where: CatalogItemTagWhereUniqueInput;
  update: CatalogItemTagUpdateDataInput;
  create: CatalogItemTagCreateInput;
};

export type CatalogItemTagWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  value_not?: Maybe<Scalars['String']>;
  value_in?: Maybe<Array<Scalars['String']>>;
  value_not_in?: Maybe<Array<Scalars['String']>>;
  value_lt?: Maybe<Scalars['String']>;
  value_lte?: Maybe<Scalars['String']>;
  value_gt?: Maybe<Scalars['String']>;
  value_gte?: Maybe<Scalars['String']>;
  value_contains?: Maybe<Scalars['String']>;
  value_not_contains?: Maybe<Scalars['String']>;
  value_starts_with?: Maybe<Scalars['String']>;
  value_not_starts_with?: Maybe<Scalars['String']>;
  value_ends_with?: Maybe<Scalars['String']>;
  value_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<CatalogItemTagWhereInput>>;
  OR?: Maybe<Array<CatalogItemTagWhereInput>>;
  NOT?: Maybe<Array<CatalogItemTagWhereInput>>;
};

export type CatalogItemTagWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum CatalogItemType {
  Plant = 'Plant',
  Flower = 'Flower',
  Sundry = 'Sundry',
  Decoration = 'Decoration',
  Other = 'Other'
}

export type CatalogItemUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  colour?: Maybe<ColourUpdateOneRequiredInput>;
  family?: Maybe<CatalogItemFamilyUpdateOneRequiredInput>;
  subType?: Maybe<Scalars['String']>;
  type?: Maybe<CatalogItemType>;
  name?: Maybe<CatalogItemNameUpdateManyInput>;
  media?: Maybe<MediaUpdateManyInput>;
  ref?: Maybe<CatalogItemRefUpdateOneInput>;
  tradeSku?: Maybe<TradeSkuUpdateManyInput>;
  tag?: Maybe<CatalogItemTagUpdateManyInput>;
  primaryName?: Maybe<Scalars['String']>;
};

export type CatalogItemUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  colour?: Maybe<ColourUpdateOneRequiredInput>;
  family?: Maybe<CatalogItemFamilyUpdateOneRequiredInput>;
  subType?: Maybe<Scalars['String']>;
  type?: Maybe<CatalogItemType>;
  name?: Maybe<CatalogItemNameUpdateManyInput>;
  media?: Maybe<MediaUpdateManyInput>;
  ref?: Maybe<CatalogItemRefUpdateOneInput>;
  tradeSku?: Maybe<TradeSkuUpdateManyInput>;
  tag?: Maybe<CatalogItemTagUpdateManyInput>;
  primaryName?: Maybe<Scalars['String']>;
};

export type CatalogItemUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  subType?: Maybe<Scalars['String']>;
  type?: Maybe<CatalogItemType>;
  primaryName?: Maybe<Scalars['String']>;
};

export type CatalogItemUpdateOneInput = {
  create?: Maybe<CatalogItemCreateInput>;
  update?: Maybe<CatalogItemUpdateDataInput>;
  upsert?: Maybe<CatalogItemUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CatalogItemWhereUniqueInput>;
};

export type CatalogItemUpsertNestedInput = {
  update: CatalogItemUpdateDataInput;
  create: CatalogItemCreateInput;
};

export type CatalogItemWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  colour?: Maybe<ColourWhereInput>;
  family?: Maybe<CatalogItemFamilyWhereInput>;
  subType?: Maybe<Scalars['String']>;
  subType_not?: Maybe<Scalars['String']>;
  subType_in?: Maybe<Array<Scalars['String']>>;
  subType_not_in?: Maybe<Array<Scalars['String']>>;
  subType_lt?: Maybe<Scalars['String']>;
  subType_lte?: Maybe<Scalars['String']>;
  subType_gt?: Maybe<Scalars['String']>;
  subType_gte?: Maybe<Scalars['String']>;
  subType_contains?: Maybe<Scalars['String']>;
  subType_not_contains?: Maybe<Scalars['String']>;
  subType_starts_with?: Maybe<Scalars['String']>;
  subType_not_starts_with?: Maybe<Scalars['String']>;
  subType_ends_with?: Maybe<Scalars['String']>;
  subType_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<CatalogItemType>;
  type_not?: Maybe<CatalogItemType>;
  type_in?: Maybe<Array<CatalogItemType>>;
  type_not_in?: Maybe<Array<CatalogItemType>>;
  name_every?: Maybe<CatalogItemNameWhereInput>;
  name_some?: Maybe<CatalogItemNameWhereInput>;
  name_none?: Maybe<CatalogItemNameWhereInput>;
  media_every?: Maybe<MediaWhereInput>;
  media_some?: Maybe<MediaWhereInput>;
  media_none?: Maybe<MediaWhereInput>;
  ref?: Maybe<CatalogItemRefWhereInput>;
  tradeSku_every?: Maybe<TradeSkuWhereInput>;
  tradeSku_some?: Maybe<TradeSkuWhereInput>;
  tradeSku_none?: Maybe<TradeSkuWhereInput>;
  tag_every?: Maybe<CatalogItemTagWhereInput>;
  tag_some?: Maybe<CatalogItemTagWhereInput>;
  tag_none?: Maybe<CatalogItemTagWhereInput>;
  primaryName?: Maybe<Scalars['String']>;
  primaryName_not?: Maybe<Scalars['String']>;
  primaryName_in?: Maybe<Array<Scalars['String']>>;
  primaryName_not_in?: Maybe<Array<Scalars['String']>>;
  primaryName_lt?: Maybe<Scalars['String']>;
  primaryName_lte?: Maybe<Scalars['String']>;
  primaryName_gt?: Maybe<Scalars['String']>;
  primaryName_gte?: Maybe<Scalars['String']>;
  primaryName_contains?: Maybe<Scalars['String']>;
  primaryName_not_contains?: Maybe<Scalars['String']>;
  primaryName_starts_with?: Maybe<Scalars['String']>;
  primaryName_not_starts_with?: Maybe<Scalars['String']>;
  primaryName_ends_with?: Maybe<Scalars['String']>;
  primaryName_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<CatalogItemWhereInput>>;
  OR?: Maybe<Array<CatalogItemWhereInput>>;
  NOT?: Maybe<Array<CatalogItemWhereInput>>;
};

export type CatalogItemWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum Channel {
  Floom = 'Floom',
  Pos = 'Pos',
  Website = 'Website'
}

export type ChannelType = {
  __typename?: 'ChannelType';
  id: Scalars['ID'];
  channel?: Maybe<Channel>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ChannelTypeConnection = {
  __typename?: 'ChannelTypeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ChannelTypeEdge>>;
  aggregate: AggregateChannelType;
};

export type ChannelTypeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  channel?: Maybe<Channel>;
};

export type ChannelTypeCreateManyInput = {
  connect?: Maybe<Array<ChannelTypeWhereUniqueInput>>;
};

export type ChannelTypeEdge = {
  __typename?: 'ChannelTypeEdge';
  node: ChannelType;
  cursor: Scalars['String'];
};

export enum ChannelTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ChannelTypePreviousValues = {
  __typename?: 'ChannelTypePreviousValues';
  id: Scalars['ID'];
  channel?: Maybe<Channel>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ChannelTypeScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  channel?: Maybe<Channel>;
  channel_not?: Maybe<Channel>;
  channel_in?: Maybe<Array<Channel>>;
  channel_not_in?: Maybe<Array<Channel>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ChannelTypeScalarWhereInput>>;
  OR?: Maybe<Array<ChannelTypeScalarWhereInput>>;
  NOT?: Maybe<Array<ChannelTypeScalarWhereInput>>;
};

export type ChannelTypeSubscriptionPayload = {
  __typename?: 'ChannelTypeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ChannelType>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ChannelTypePreviousValues>;
};

export type ChannelTypeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ChannelTypeWhereInput>;
  AND?: Maybe<Array<ChannelTypeSubscriptionWhereInput>>;
  OR?: Maybe<Array<ChannelTypeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ChannelTypeSubscriptionWhereInput>>;
};

export type ChannelTypeUpdateDataInput = {
  channel?: Maybe<Channel>;
};

export type ChannelTypeUpdateInput = {
  channel?: Maybe<Channel>;
};

export type ChannelTypeUpdateManyDataInput = {
  channel?: Maybe<Channel>;
};

export type ChannelTypeUpdateManyInput = {
  connect?: Maybe<Array<ChannelTypeWhereUniqueInput>>;
  set?: Maybe<Array<ChannelTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ChannelTypeWhereUniqueInput>>;
};

export type ChannelTypeUpdateManyMutationInput = {
  channel?: Maybe<Channel>;
};

export type ChannelTypeUpdateManyWithWhereNestedInput = {
  where: ChannelTypeScalarWhereInput;
  data: ChannelTypeUpdateManyDataInput;
};

export type ChannelTypeUpdateWithWhereUniqueNestedInput = {
  where: ChannelTypeWhereUniqueInput;
  data: ChannelTypeUpdateDataInput;
};

export type ChannelTypeUpsertWithWhereUniqueNestedInput = {
  where: ChannelTypeWhereUniqueInput;
  update: ChannelTypeUpdateDataInput;
  create: ChannelTypeCreateInput;
};

export type ChannelTypeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  channel?: Maybe<Channel>;
  channel_not?: Maybe<Channel>;
  channel_in?: Maybe<Array<Channel>>;
  channel_not_in?: Maybe<Array<Channel>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ChannelTypeWhereInput>>;
  OR?: Maybe<Array<ChannelTypeWhereInput>>;
  NOT?: Maybe<Array<ChannelTypeWhereInput>>;
};

export type ChannelTypeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  channel?: Maybe<Channel>;
};

export type CollectionItemDeliveryConfigInput = {
  variation: Array<ProductVariationWhereUniqueInput>;
  deliveryZone: DeliveryZoneWhereInput;
};

export type CollectionOrderDistributionInput = {
  variation: ProductVariationWhereUniqueInput;
  deliveryZone: DeliveryZoneWhereInput;
  deliveryDate: Scalars['String'];
  quantity: Scalars['Int'];
};

export type CollectionSellerProductConfig = {
  __typename?: 'CollectionSellerProductConfig';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  deliveryConfigs?: Maybe<Array<DeliveryConfig>>;
  active: Scalars['Boolean'];
  sourceProduct: Product;
  productVariationConfigs?: Maybe<Array<CollectionSellerProductVariationConfig>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};


export type CollectionSellerProductConfigDeliveryConfigsArgs = {
  where?: Maybe<DeliveryConfigWhereInput>;
  orderBy?: Maybe<DeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CollectionSellerProductConfigProductVariationConfigsArgs = {
  where?: Maybe<CollectionSellerProductVariationConfigWhereInput>;
  orderBy?: Maybe<CollectionSellerProductVariationConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CollectionSellerProductConfigConnection = {
  __typename?: 'CollectionSellerProductConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CollectionSellerProductConfigEdge>>;
  aggregate: AggregateCollectionSellerProductConfig;
};

export type CollectionSellerProductConfigCreateInput = {
  merchant?: Maybe<MerchantConnectOneInput>;
  active: Scalars['Boolean'];
  sourceProduct: ProductCreateOneWithoutCollectionSellerProductConfigsInput;
  productVariationConfigs?: Maybe<CollectionSellerProductVariationConfigCreateManyInput>;
};

export type CollectionSellerProductConfigCreateWithoutSourceProductInput = {
  merchant?: Maybe<MerchantConnectOneInput>;
  active: Scalars['Boolean'];
  productVariationConfigs?: Maybe<CollectionSellerProductVariationConfigCreateManyInput>;
};

export type CollectionSellerProductConfigEdge = {
  __typename?: 'CollectionSellerProductConfigEdge';
  node: CollectionSellerProductConfig;
  cursor: Scalars['String'];
};

export enum CollectionSellerProductConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type CollectionSellerProductConfigPreviousValues = {
  __typename?: 'CollectionSellerProductConfigPreviousValues';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CollectionSellerProductConfigScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CollectionSellerProductConfigScalarWhereInput>>;
  OR?: Maybe<Array<CollectionSellerProductConfigScalarWhereInput>>;
  NOT?: Maybe<Array<CollectionSellerProductConfigScalarWhereInput>>;
};

export type CollectionSellerProductConfigSubscriptionPayload = {
  __typename?: 'CollectionSellerProductConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CollectionSellerProductConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CollectionSellerProductConfigPreviousValues>;
};

export type CollectionSellerProductConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CollectionSellerProductConfigWhereInput>;
  AND?: Maybe<Array<CollectionSellerProductConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<CollectionSellerProductConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CollectionSellerProductConfigSubscriptionWhereInput>>;
};

export type CollectionSellerProductConfigUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  productVariationConfigs?: Maybe<CollectionSellerProductVariationConfigUpdateManyInput>;
};

export type CollectionSellerProductConfigUpdateManyDataInput = {
  active?: Maybe<Scalars['Boolean']>;
};

export type CollectionSellerProductConfigUpdateManyMutationInput = {
  active?: Maybe<Scalars['Boolean']>;
};

export type CollectionSellerProductConfigUpdateManyWithWhereNestedInput = {
  where: CollectionSellerProductConfigScalarWhereInput;
  data: CollectionSellerProductConfigUpdateManyDataInput;
};

export type CollectionSellerProductConfigUpdateManyWithoutSourceProductInput = {
  create?: Maybe<Array<CollectionSellerProductConfigCreateWithoutSourceProductInput>>;
  delete?: Maybe<Array<CollectionSellerProductConfigWhereUniqueInput>>;
  connect?: Maybe<Array<CollectionSellerProductConfigWhereUniqueInput>>;
  set?: Maybe<Array<CollectionSellerProductConfigWhereUniqueInput>>;
  disconnect?: Maybe<Array<CollectionSellerProductConfigWhereUniqueInput>>;
  update?: Maybe<Array<CollectionSellerProductConfigUpdateWithWhereUniqueWithoutSourceProductInput>>;
  upsert?: Maybe<Array<CollectionSellerProductConfigUpsertWithWhereUniqueWithoutSourceProductInput>>;
  deleteMany?: Maybe<Array<CollectionSellerProductConfigScalarWhereInput>>;
  updateMany?: Maybe<Array<CollectionSellerProductConfigUpdateManyWithWhereNestedInput>>;
};

export type CollectionSellerProductConfigUpdateWithWhereUniqueWithoutSourceProductInput = {
  where: CollectionSellerProductConfigWhereUniqueInput;
  data: CollectionSellerProductConfigUpdateWithoutSourceProductDataInput;
};

export type CollectionSellerProductConfigUpdateWithoutSourceProductDataInput = {
  active?: Maybe<Scalars['Boolean']>;
  productVariationConfigs?: Maybe<CollectionSellerProductVariationConfigUpdateManyInput>;
};

export type CollectionSellerProductConfigUpsertWithWhereUniqueWithoutSourceProductInput = {
  where: CollectionSellerProductConfigWhereUniqueInput;
  update: CollectionSellerProductConfigUpdateWithoutSourceProductDataInput;
  create: CollectionSellerProductConfigCreateWithoutSourceProductInput;
};

export type CollectionSellerProductConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantWhereInput>;
  deliveryConfigs_every?: Maybe<DeliveryConfigWhereInput>;
  deliveryConfigs_some?: Maybe<DeliveryConfigWhereInput>;
  deliveryConfigs_none?: Maybe<DeliveryConfigWhereInput>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  sourceProduct?: Maybe<ProductWhereInput>;
  productVariationConfigs_every?: Maybe<CollectionSellerProductVariationConfigWhereInput>;
  productVariationConfigs_some?: Maybe<CollectionSellerProductVariationConfigWhereInput>;
  productVariationConfigs_none?: Maybe<CollectionSellerProductVariationConfigWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CollectionSellerProductConfigWhereInput>>;
  OR?: Maybe<Array<CollectionSellerProductConfigWhereInput>>;
  NOT?: Maybe<Array<CollectionSellerProductConfigWhereInput>>;
};

export type CollectionSellerProductConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CollectionSellerProductVariationConfig = {
  __typename?: 'CollectionSellerProductVariationConfig';
  id: Scalars['ID'];
  sourceProductVariation: ProductVariation;
  stock?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CollectionSellerProductVariationConfigConnection = {
  __typename?: 'CollectionSellerProductVariationConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CollectionSellerProductVariationConfigEdge>>;
  aggregate: AggregateCollectionSellerProductVariationConfig;
};

export type CollectionSellerProductVariationConfigCreateInput = {
  sourceProductVariation: ProductVariationConnectOneInput;
  stock?: Maybe<Scalars['Int']>;
};

export type CollectionSellerProductVariationConfigCreateManyInput = {
  create?: Maybe<Array<CollectionSellerProductVariationConfigCreateInput>>;
  connect?: Maybe<Array<CollectionSellerProductVariationConfigWhereUniqueInput>>;
};

export type CollectionSellerProductVariationConfigEdge = {
  __typename?: 'CollectionSellerProductVariationConfigEdge';
  node: CollectionSellerProductVariationConfig;
  cursor: Scalars['String'];
};

export enum CollectionSellerProductVariationConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StockAsc = 'stock_ASC',
  StockDesc = 'stock_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type CollectionSellerProductVariationConfigPreviousValues = {
  __typename?: 'CollectionSellerProductVariationConfigPreviousValues';
  id: Scalars['ID'];
  stock?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CollectionSellerProductVariationConfigScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  stock?: Maybe<Scalars['Int']>;
  stock_not?: Maybe<Scalars['Int']>;
  stock_in?: Maybe<Array<Scalars['Int']>>;
  stock_not_in?: Maybe<Array<Scalars['Int']>>;
  stock_lt?: Maybe<Scalars['Int']>;
  stock_lte?: Maybe<Scalars['Int']>;
  stock_gt?: Maybe<Scalars['Int']>;
  stock_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CollectionSellerProductVariationConfigScalarWhereInput>>;
  OR?: Maybe<Array<CollectionSellerProductVariationConfigScalarWhereInput>>;
  NOT?: Maybe<Array<CollectionSellerProductVariationConfigScalarWhereInput>>;
};

export type CollectionSellerProductVariationConfigSubscriptionPayload = {
  __typename?: 'CollectionSellerProductVariationConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CollectionSellerProductVariationConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CollectionSellerProductVariationConfigPreviousValues>;
};

export type CollectionSellerProductVariationConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CollectionSellerProductVariationConfigWhereInput>;
  AND?: Maybe<Array<CollectionSellerProductVariationConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<CollectionSellerProductVariationConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CollectionSellerProductVariationConfigSubscriptionWhereInput>>;
};

export type CollectionSellerProductVariationConfigUpdateDataInput = {
  stock?: Maybe<Scalars['Int']>;
};

export type CollectionSellerProductVariationConfigUpdateInput = {
  stock?: Maybe<Scalars['Int']>;
};

export type CollectionSellerProductVariationConfigUpdateManyDataInput = {
  stock?: Maybe<Scalars['Int']>;
};

export type CollectionSellerProductVariationConfigUpdateManyInput = {
  create?: Maybe<Array<CollectionSellerProductVariationConfigCreateInput>>;
  update?: Maybe<Array<CollectionSellerProductVariationConfigUpdateWithWhereUniqueNestedInput>>;
};

export type CollectionSellerProductVariationConfigUpdateManyMutationInput = {
  stock?: Maybe<Scalars['Int']>;
};

export type CollectionSellerProductVariationConfigUpdateManyWithWhereNestedInput = {
  where: CollectionSellerProductVariationConfigScalarWhereInput;
  data: CollectionSellerProductVariationConfigUpdateManyDataInput;
};

export type CollectionSellerProductVariationConfigUpdateWithWhereUniqueNestedInput = {
  where: CollectionSellerProductVariationConfigWhereUniqueInput;
  data: CollectionSellerProductVariationConfigUpdateDataInput;
};

export type CollectionSellerProductVariationConfigUpsertWithWhereUniqueNestedInput = {
  where: CollectionSellerProductVariationConfigWhereUniqueInput;
  update: CollectionSellerProductVariationConfigUpdateDataInput;
  create: CollectionSellerProductVariationConfigCreateInput;
};

export type CollectionSellerProductVariationConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  sourceProductVariation?: Maybe<ProductVariationWhereInput>;
  stock?: Maybe<Scalars['Int']>;
  stock_not?: Maybe<Scalars['Int']>;
  stock_in?: Maybe<Array<Scalars['Int']>>;
  stock_not_in?: Maybe<Array<Scalars['Int']>>;
  stock_lt?: Maybe<Scalars['Int']>;
  stock_lte?: Maybe<Scalars['Int']>;
  stock_gt?: Maybe<Scalars['Int']>;
  stock_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CollectionSellerProductVariationConfigWhereInput>>;
  OR?: Maybe<Array<CollectionSellerProductVariationConfigWhereInput>>;
  NOT?: Maybe<Array<CollectionSellerProductVariationConfigWhereInput>>;
};

export type CollectionSellerProductVariationConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Colour = {
  __typename?: 'Colour';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  hex: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ColourConnectOneInput = {
  connect?: Maybe<ColourWhereUniqueInput>;
};

export type ColourConnection = {
  __typename?: 'ColourConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ColourEdge>>;
  aggregate: AggregateColour;
};

export type ColourCreateInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  hex: Scalars['String'];
};

export type ColourCreateManyInput = {
  connect?: Maybe<Array<ColourWhereUniqueInput>>;
  create?: Maybe<Array<ColourCreateInput>>;
};

export type ColourCreateOneInput = {
  create?: Maybe<ColourCreateInput>;
  connect?: Maybe<ColourWhereUniqueInput>;
};

export type ColourEdge = {
  __typename?: 'ColourEdge';
  node: Colour;
  cursor: Scalars['String'];
};

export enum ColourOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  HexAsc = 'hex_ASC',
  HexDesc = 'hex_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ColourPreviousValues = {
  __typename?: 'ColourPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  hex: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ColourScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  hex?: Maybe<Scalars['String']>;
  hex_not?: Maybe<Scalars['String']>;
  hex_in?: Maybe<Array<Scalars['String']>>;
  hex_not_in?: Maybe<Array<Scalars['String']>>;
  hex_lt?: Maybe<Scalars['String']>;
  hex_lte?: Maybe<Scalars['String']>;
  hex_gt?: Maybe<Scalars['String']>;
  hex_gte?: Maybe<Scalars['String']>;
  hex_contains?: Maybe<Scalars['String']>;
  hex_not_contains?: Maybe<Scalars['String']>;
  hex_starts_with?: Maybe<Scalars['String']>;
  hex_not_starts_with?: Maybe<Scalars['String']>;
  hex_ends_with?: Maybe<Scalars['String']>;
  hex_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ColourScalarWhereInput>>;
  OR?: Maybe<Array<ColourScalarWhereInput>>;
  NOT?: Maybe<Array<ColourScalarWhereInput>>;
};

export type ColourSubscriptionPayload = {
  __typename?: 'ColourSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Colour>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ColourPreviousValues>;
};

export type ColourSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ColourWhereInput>;
  AND?: Maybe<Array<ColourSubscriptionWhereInput>>;
  OR?: Maybe<Array<ColourSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ColourSubscriptionWhereInput>>;
};

export type ColourUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  hex?: Maybe<Scalars['String']>;
};

export type ColourUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  hex?: Maybe<Scalars['String']>;
};

export type ColourUpdateManyDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  hex?: Maybe<Scalars['String']>;
};

export type ColourUpdateManyInput = {
  connect?: Maybe<Array<ColourWhereUniqueInput>>;
  create?: Maybe<Array<ColourCreateInput>>;
  delete?: Maybe<Array<ColourWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ColourScalarWhereInput>>;
  disconnect?: Maybe<Array<ColourWhereUniqueInput>>;
  set?: Maybe<Array<ColourWhereUniqueInput>>;
  update?: Maybe<Array<ColourUpdateWithWhereUniqueNestedInput>>;
  updateMany?: Maybe<Array<ColourUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<ColourUpsertWithWhereUniqueNestedInput>>;
};

export type ColourUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  hex?: Maybe<Scalars['String']>;
};

export type ColourUpdateManyWithWhereNestedInput = {
  where: ColourScalarWhereInput;
  data: ColourUpdateManyDataInput;
};

export type ColourUpdateOneInput = {
  create?: Maybe<ColourCreateInput>;
  update?: Maybe<ColourUpdateDataInput>;
  upsert?: Maybe<ColourUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ColourWhereUniqueInput>;
};

export type ColourUpdateOneRequiredInput = {
  create?: Maybe<ColourCreateInput>;
  update?: Maybe<ColourUpdateDataInput>;
  upsert?: Maybe<ColourUpsertNestedInput>;
  connect?: Maybe<ColourWhereUniqueInput>;
};

export type ColourUpdateWithWhereUniqueNestedInput = {
  where: ColourWhereUniqueInput;
  data: ColourUpdateDataInput;
};

export type ColourUpsertNestedInput = {
  update: ColourUpdateDataInput;
  create: ColourCreateInput;
};

export type ColourUpsertWithWhereUniqueNestedInput = {
  where: ColourWhereUniqueInput;
  update: ColourUpdateDataInput;
  create: ColourCreateInput;
};

export type ColourWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  hex?: Maybe<Scalars['String']>;
  hex_not?: Maybe<Scalars['String']>;
  hex_in?: Maybe<Array<Scalars['String']>>;
  hex_not_in?: Maybe<Array<Scalars['String']>>;
  hex_lt?: Maybe<Scalars['String']>;
  hex_lte?: Maybe<Scalars['String']>;
  hex_gt?: Maybe<Scalars['String']>;
  hex_gte?: Maybe<Scalars['String']>;
  hex_contains?: Maybe<Scalars['String']>;
  hex_not_contains?: Maybe<Scalars['String']>;
  hex_starts_with?: Maybe<Scalars['String']>;
  hex_not_starts_with?: Maybe<Scalars['String']>;
  hex_ends_with?: Maybe<Scalars['String']>;
  hex_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ColourWhereInput>>;
  OR?: Maybe<Array<ColourWhereInput>>;
  NOT?: Maybe<Array<ColourWhereInput>>;
};

export type ColourWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
};

export type Component = {
  __typename?: 'Component';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  configs?: Maybe<Array<ComponentConfig>>;
  lengths?: Maybe<Array<StemLength>>;
  media?: Maybe<Media>;
  searchable?: Maybe<Scalars['String']>;
  activeCount?: Maybe<Scalars['Int']>;
};


export type ComponentConfigsArgs = {
  where?: Maybe<ComponentConfigWhereInput>;
  orderBy?: Maybe<ComponentConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ComponentLengthsArgs = {
  where?: Maybe<StemLengthWhereInput>;
  orderBy?: Maybe<StemLengthOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ComponentConfig = {
  __typename?: 'ComponentConfig';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  component?: Maybe<Component>;
  colour?: Maybe<Colour>;
  media?: Maybe<Media>;
  title: Scalars['String'];
  genus?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
  searchable?: Maybe<Scalars['String']>;
  activeCount?: Maybe<Scalars['Int']>;
};

export type ComponentConfigConnection = {
  __typename?: 'ComponentConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ComponentConfigEdge>>;
  aggregate: AggregateComponentConfig;
};

export type ComponentConfigCreateInput = {
  id?: Maybe<Scalars['ID']>;
  component?: Maybe<ComponentCreateOneWithoutConfigsInput>;
  colour?: Maybe<ColourCreateOneInput>;
  media?: Maybe<MediaCreateOneInput>;
  title: Scalars['String'];
  cultivar?: Maybe<Scalars['String']>;
};

export type ComponentConfigCreateManyWithoutComponentInput = {
  create?: Maybe<Array<ComponentConfigCreateWithoutComponentInput>>;
  connect?: Maybe<Array<ComponentConfigWhereUniqueInput>>;
};

export type ComponentConfigCreateOneInput = {
  create?: Maybe<ComponentConfigCreateInput>;
  connect?: Maybe<ComponentConfigWhereUniqueInput>;
};

export type ComponentConfigCreateWithoutComponentInput = {
  id?: Maybe<Scalars['ID']>;
  colour?: Maybe<ColourCreateOneInput>;
  media?: Maybe<MediaCreateOneInput>;
  title: Scalars['String'];
  cultivar?: Maybe<Scalars['String']>;
};

export type ComponentConfigEdge = {
  __typename?: 'ComponentConfigEdge';
  node: ComponentConfig;
  cursor: Scalars['String'];
};

export enum ComponentConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CultivarAsc = 'cultivar_ASC',
  CultivarDesc = 'cultivar_DESC'
}

export type ComponentConfigPreviousValues = {
  __typename?: 'ComponentConfigPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  cultivar?: Maybe<Scalars['String']>;
};

export type ComponentConfigScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<ComponentConfigScalarWhereInput>>;
  OR?: Maybe<Array<ComponentConfigScalarWhereInput>>;
  NOT?: Maybe<Array<ComponentConfigScalarWhereInput>>;
};

export type ComponentConfigSubscriptionPayload = {
  __typename?: 'ComponentConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ComponentConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ComponentConfigPreviousValues>;
};

export type ComponentConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ComponentConfigWhereInput>;
  AND?: Maybe<Array<ComponentConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<ComponentConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ComponentConfigSubscriptionWhereInput>>;
};

export type ComponentConfigUpdateDataInput = {
  component?: Maybe<ComponentUpdateOneWithoutConfigsInput>;
  colour?: Maybe<ColourUpdateOneInput>;
  media?: Maybe<MediaUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
};

export type ComponentConfigUpdateInput = {
  component?: Maybe<ComponentUpdateOneWithoutConfigsInput>;
  colour?: Maybe<ColourUpdateOneInput>;
  media?: Maybe<MediaUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
};

export type ComponentConfigUpdateManyDataInput = {
  title?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
};

export type ComponentConfigUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
};

export type ComponentConfigUpdateManyWithWhereNestedInput = {
  where: ComponentConfigScalarWhereInput;
  data: ComponentConfigUpdateManyDataInput;
};

export type ComponentConfigUpdateManyWithoutComponentInput = {
  create?: Maybe<Array<ComponentConfigCreateWithoutComponentInput>>;
  delete?: Maybe<Array<ComponentConfigWhereUniqueInput>>;
  connect?: Maybe<Array<ComponentConfigWhereUniqueInput>>;
  set?: Maybe<Array<ComponentConfigWhereUniqueInput>>;
  disconnect?: Maybe<Array<ComponentConfigWhereUniqueInput>>;
  update?: Maybe<Array<ComponentConfigUpdateWithWhereUniqueWithoutComponentInput>>;
  upsert?: Maybe<Array<ComponentConfigUpsertWithWhereUniqueWithoutComponentInput>>;
  deleteMany?: Maybe<Array<ComponentConfigScalarWhereInput>>;
  updateMany?: Maybe<Array<ComponentConfigUpdateManyWithWhereNestedInput>>;
};

export type ComponentConfigUpdateOneInput = {
  create?: Maybe<ComponentConfigCreateInput>;
  update?: Maybe<ComponentConfigUpdateDataInput>;
  upsert?: Maybe<ComponentConfigUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ComponentConfigWhereUniqueInput>;
};

export type ComponentConfigUpdateOneRequiredInput = {
  connect?: Maybe<ComponentConfigWhereUniqueInput>;
  set?: Maybe<ComponentConfigWhereUniqueInput>;
};

export type ComponentConfigUpdateWithWhereUniqueWithoutComponentInput = {
  where: ComponentConfigWhereUniqueInput;
  data: ComponentConfigUpdateWithoutComponentDataInput;
};

export type ComponentConfigUpdateWithoutComponentDataInput = {
  colour?: Maybe<ColourUpdateOneInput>;
  media?: Maybe<MediaUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentConfigUpsertNestedInput = {
  update: ComponentConfigUpdateDataInput;
  create: ComponentConfigCreateInput;
};

export type ComponentConfigUpsertWithWhereUniqueWithoutComponentInput = {
  where: ComponentConfigWhereUniqueInput;
  update: ComponentConfigUpdateWithoutComponentDataInput;
  create: ComponentConfigCreateWithoutComponentInput;
};

export type ComponentConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  component?: Maybe<ComponentWhereInput>;
  colour?: Maybe<ColourWhereInput>;
  media?: Maybe<MediaWhereInput>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
  cultivar_not?: Maybe<Scalars['String']>;
  cultivar_in?: Maybe<Array<Scalars['String']>>;
  cultivar_not_in?: Maybe<Array<Scalars['String']>>;
  cultivar_lt?: Maybe<Scalars['String']>;
  cultivar_lte?: Maybe<Scalars['String']>;
  cultivar_gt?: Maybe<Scalars['String']>;
  cultivar_gte?: Maybe<Scalars['String']>;
  cultivar_contains?: Maybe<Scalars['String']>;
  cultivar_not_contains?: Maybe<Scalars['String']>;
  cultivar_starts_with?: Maybe<Scalars['String']>;
  cultivar_not_starts_with?: Maybe<Scalars['String']>;
  cultivar_ends_with?: Maybe<Scalars['String']>;
  cultivar_not_ends_with?: Maybe<Scalars['String']>;
  searchable?: Maybe<Scalars['String']>;
  searchable_not?: Maybe<Scalars['String']>;
  searchable_in?: Maybe<Array<Scalars['String']>>;
  searchable_not_in?: Maybe<Array<Scalars['String']>>;
  searchable_lt?: Maybe<Scalars['String']>;
  searchable_lte?: Maybe<Scalars['String']>;
  searchable_gt?: Maybe<Scalars['String']>;
  searchable_gte?: Maybe<Scalars['String']>;
  searchable_contains?: Maybe<Scalars['String']>;
  searchable_not_contains?: Maybe<Scalars['String']>;
  searchable_starts_with?: Maybe<Scalars['String']>;
  searchable_not_starts_with?: Maybe<Scalars['String']>;
  searchable_ends_with?: Maybe<Scalars['String']>;
  searchable_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<ComponentConfigWhereInput>>;
  OR?: Maybe<Array<ComponentConfigWhereInput>>;
  NOT?: Maybe<Array<ComponentConfigWhereInput>>;
};

export type ComponentConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentConnection = {
  __typename?: 'ComponentConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ComponentEdge>>;
  aggregate: AggregateComponent;
};

export type ComponentCreateInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  configs?: Maybe<ComponentConfigCreateManyWithoutComponentInput>;
  lengths?: Maybe<StemLengthCreateManyWithoutComponentInput>;
};

export type ComponentCreateOneInput = {
  connect?: Maybe<ComponentWhereUniqueInput>;
};

export type ComponentCreateOneWithoutConfigsInput = {
  create?: Maybe<ComponentCreateWithoutConfigsInput>;
  connect?: Maybe<ComponentWhereUniqueInput>;
};

export type ComponentCreateOneWithoutLengthsInput = {
  create?: Maybe<ComponentCreateWithoutLengthsInput>;
  connect?: Maybe<ComponentWhereUniqueInput>;
};

export type ComponentCreateWithoutConfigsInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  lengths?: Maybe<StemLengthCreateManyWithoutComponentInput>;
};

export type ComponentCreateWithoutLengthsInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  configs?: Maybe<ComponentConfigCreateManyWithoutComponentInput>;
};

export type ComponentEdge = {
  __typename?: 'ComponentEdge';
  node: Component;
  cursor: Scalars['String'];
};

export enum ComponentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type ComponentPreviousValues = {
  __typename?: 'ComponentPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentSubscriptionPayload = {
  __typename?: 'ComponentSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Component>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ComponentPreviousValues>;
};

export type ComponentSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ComponentWhereInput>;
  AND?: Maybe<Array<ComponentSubscriptionWhereInput>>;
  OR?: Maybe<Array<ComponentSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ComponentSubscriptionWhereInput>>;
};

export type ComponentUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  configs?: Maybe<ComponentConfigUpdateManyWithoutComponentInput>;
  lengths?: Maybe<StemLengthUpdateManyWithoutComponentInput>;
};

export type ComponentUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type ComponentUpdateOneRequiredInput = {
  connect?: Maybe<ComponentWhereUniqueInput>;
};

export type ComponentUpdateOneWithoutConfigsInput = {
  create?: Maybe<ComponentCreateWithoutConfigsInput>;
  update?: Maybe<ComponentUpdateWithoutConfigsDataInput>;
  upsert?: Maybe<ComponentUpsertWithoutConfigsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ComponentWhereUniqueInput>;
};

export type ComponentUpdateOneWithoutLengthsInput = {
  create?: Maybe<ComponentCreateWithoutLengthsInput>;
  update?: Maybe<ComponentUpdateWithoutLengthsDataInput>;
  upsert?: Maybe<ComponentUpsertWithoutLengthsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ComponentWhereUniqueInput>;
};

export type ComponentUpdateWithoutConfigsDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  lengths?: Maybe<StemLengthUpdateManyWithoutComponentInput>;
};

export type ComponentUpdateWithoutLengthsDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  configs?: Maybe<ComponentConfigUpdateManyWithoutComponentInput>;
};

export type ComponentUpsertWithoutConfigsInput = {
  update: ComponentUpdateWithoutConfigsDataInput;
  create: ComponentCreateWithoutConfigsInput;
};

export type ComponentUpsertWithoutLengthsInput = {
  update: ComponentUpdateWithoutLengthsDataInput;
  create: ComponentCreateWithoutLengthsInput;
};

export type ComponentWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  configs_every?: Maybe<ComponentConfigWhereInput>;
  configs_some?: Maybe<ComponentConfigWhereInput>;
  configs_none?: Maybe<ComponentConfigWhereInput>;
  lengths_every?: Maybe<StemLengthWhereInput>;
  lengths_some?: Maybe<StemLengthWhereInput>;
  lengths_none?: Maybe<StemLengthWhereInput>;
  searchable?: Maybe<Scalars['String']>;
  searchable_not?: Maybe<Scalars['String']>;
  searchable_in?: Maybe<Array<Scalars['String']>>;
  searchable_not_in?: Maybe<Array<Scalars['String']>>;
  searchable_lt?: Maybe<Scalars['String']>;
  searchable_lte?: Maybe<Scalars['String']>;
  searchable_gt?: Maybe<Scalars['String']>;
  searchable_gte?: Maybe<Scalars['String']>;
  searchable_contains?: Maybe<Scalars['String']>;
  searchable_not_contains?: Maybe<Scalars['String']>;
  searchable_starts_with?: Maybe<Scalars['String']>;
  searchable_not_starts_with?: Maybe<Scalars['String']>;
  searchable_ends_with?: Maybe<Scalars['String']>;
  searchable_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<ComponentWhereInput>>;
  OR?: Maybe<Array<ComponentWhereInput>>;
  NOT?: Maybe<Array<ComponentWhereInput>>;
};

export type ComponentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type ConfirmFloomSnapUploadOutput = {
  __typename?: 'ConfirmFloomSnapUploadOutput';
  media_id: Scalars['ID'];
  order_id: Scalars['ID'];
  src: Scalars['String'];
};

export type ConfirmMailchimpIntegrationInput = {
  code: Scalars['String'];
};

export type ConfirmMailchimpIntegrationWhereUniqueInput = {
  merchant: MerchantWhereUniqueInput;
};

export type ConfirmMediaUploadOutput = {
  __typename?: 'ConfirmMediaUploadOutput';
  id: Scalars['ID'];
  src: Scalars['String'];
};

export type ConfirmOrderCreationOutput = {
  __typename?: 'ConfirmOrderCreationOutput';
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  title: Scalars['String'];
  active: Scalars['Boolean'];
  countryCode: Scalars['String'];
  regions: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CountryConnection = {
  __typename?: 'CountryConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CountryEdge>>;
  aggregate: AggregateCountry;
};

export type CountryCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  active: Scalars['Boolean'];
  countryCode: Scalars['String'];
  regions?: Maybe<CountryCreateregionsInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CountryCreateregionsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type CountryEdge = {
  __typename?: 'CountryEdge';
  node: Country;
  cursor: Scalars['String'];
};

export enum CountryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type CountryPreviousValues = {
  __typename?: 'CountryPreviousValues';
  id: Scalars['ID'];
  title: Scalars['String'];
  active: Scalars['Boolean'];
  countryCode: Scalars['String'];
  regions: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CountrySubscriptionPayload = {
  __typename?: 'CountrySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Country>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CountryPreviousValues>;
};

export type CountrySubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CountryWhereInput>;
  AND?: Maybe<Array<CountrySubscriptionWhereInput>>;
  OR?: Maybe<Array<CountrySubscriptionWhereInput>>;
  NOT?: Maybe<Array<CountrySubscriptionWhereInput>>;
};

export type CountryUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  regions?: Maybe<CountryUpdateregionsInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CountryUpdateregionsInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type CountryWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CountryWhereInput>>;
  OR?: Maybe<Array<CountryWhereInput>>;
  NOT?: Maybe<Array<CountryWhereInput>>;
};

export type CountryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Courier = {
  __typename?: 'Courier';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  orderCutOffTime?: Maybe<Scalars['Int']>;
};

export type CourierBooking = {
  __typename?: 'CourierBooking';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  deliveryStatus: OrderDeliveryState;
  courierHistory?: Maybe<Array<CourierStatusHistory>>;
  courier: Courier;
  pickupDate: Scalars['DateTime'];
  deliveryDate: Scalars['DateTime'];
  courierReferenceId: Scalars['String'];
  courierReferenceVehicleType?: Maybe<Scalars['String']>;
  courierReferenceProvider?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Address>;
  pickupCode?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  lastDeliveryStatusReason?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<Maybe<Order>>>;
};


export type CourierBookingCourierHistoryArgs = {
  where?: Maybe<CourierStatusHistoryWhereInput>;
  orderBy?: Maybe<CourierStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CourierBookingOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourierBookingConnection = {
  __typename?: 'CourierBookingConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CourierBookingEdge>>;
  aggregate: AggregateCourierBooking;
};

export type CourierBookingCreateInput = {
  id?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantCreateOneInput>;
  createdBy: UserCreateOneInput;
  deliveryStatus: OrderDeliveryState;
  courier: CourierCreateOneInput;
  pickupDate: Scalars['DateTime'];
  deliveryDate: Scalars['DateTime'];
  courierReferenceId: Scalars['String'];
  pickupCode?: Maybe<Scalars['String']>;
  lastDeliveryStatusReason?: Maybe<Scalars['String']>;
};

export type CourierBookingCreateOneInput = {
  create?: Maybe<CourierBookingCreateInput>;
  connect?: Maybe<CourierBookingWhereUniqueInput>;
};

export type CourierBookingCreateOneWithoutPaymentInput = {
  create?: Maybe<CourierBookingCreateWithoutPaymentInput>;
  connect?: Maybe<CourierBookingWhereUniqueInput>;
};

export type CourierBookingCreateWithoutPaymentInput = {
  id?: Maybe<Scalars['ID']>;
  createdBy: UserCreateOneInput;
  deliveryStatus: OrderDeliveryState;
  courierHistory?: Maybe<CourierStatusHistoryCreateManyInput>;
  courier: CourierCreateOneInput;
  pickupDate: Scalars['DateTime'];
  deliveryDate: Scalars['DateTime'];
  courierReferenceId: Scalars['String'];
  pickupCode?: Maybe<Scalars['String']>;
  lastDeliveryStatusReason?: Maybe<Scalars['String']>;
};

export type CourierBookingEdge = {
  __typename?: 'CourierBookingEdge';
  node: CourierBooking;
  cursor: Scalars['String'];
};

export enum CourierBookingOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeliveryStatusAsc = 'deliveryStatus_ASC',
  DeliveryStatusDesc = 'deliveryStatus_DESC',
  PickupDateAsc = 'pickupDate_ASC',
  PickupDateDesc = 'pickupDate_DESC',
  DeliveryDateAsc = 'deliveryDate_ASC',
  DeliveryDateDesc = 'deliveryDate_DESC',
  CourierReferenceIdAsc = 'courierReferenceId_ASC',
  CourierReferenceIdDesc = 'courierReferenceId_DESC',
  PickupCodeAsc = 'pickupCode_ASC',
  PickupCodeDesc = 'pickupCode_DESC'
}

export type CourierBookingPreviousValues = {
  __typename?: 'CourierBookingPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deliveryStatus: OrderDeliveryState;
  pickupDate: Scalars['DateTime'];
  deliveryDate: Scalars['DateTime'];
  courierReferenceId: Scalars['String'];
  pickupCode?: Maybe<Scalars['String']>;
};

export type CourierBookingResponse = {
  __typename?: 'CourierBookingResponse';
  response: Scalars['String'];
  payload?: Maybe<CourierBookingResponsePayload>;
};

export type CourierBookingResponsePayload = {
  __typename?: 'CourierBookingResponsePayload';
  cancellable?: Maybe<Scalars['String']>;
  cancellationFee?: Maybe<Scalars['String']>;
  jobStatus?: Maybe<Scalars['String']>;
};

export type CourierBookingSubscriptionPayload = {
  __typename?: 'CourierBookingSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CourierBooking>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CourierBookingPreviousValues>;
};

export type CourierBookingSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CourierBookingWhereInput>;
  AND?: Maybe<Array<CourierBookingSubscriptionWhereInput>>;
  OR?: Maybe<Array<CourierBookingSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CourierBookingSubscriptionWhereInput>>;
};

export type CourierBookingUpdateDataInput = {
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  deliveryStatus?: Maybe<OrderDeliveryState>;
  courier?: Maybe<CourierUpdateOneRequiredInput>;
  pickupDate?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  courierReferenceId?: Maybe<Scalars['String']>;
  pickupCode?: Maybe<Scalars['String']>;
};

export type CourierBookingUpdateInput = {
  createdBy?: Maybe<UserUpdateOneRequiredInput>;
  deliveryStatus?: Maybe<OrderDeliveryState>;
  courier?: Maybe<CourierUpdateOneRequiredInput>;
  pickupDate?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  courierReferenceId?: Maybe<Scalars['String']>;
  pickupCode?: Maybe<Scalars['String']>;
  lastDeliveryStatusReason?: Maybe<Scalars['String']>;
};

export type CourierBookingUpdateManyMutationInput = {
  deliveryStatus?: Maybe<OrderDeliveryState>;
  pickupDate?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  courierReferenceId?: Maybe<Scalars['String']>;
  pickupCode?: Maybe<Scalars['String']>;
  lastDeliveryStatusReason?: Maybe<Scalars['String']>;
};

export type CourierBookingUpdateOneInput = {
  create?: Maybe<CourierBookingCreateInput>;
  update?: Maybe<CourierBookingUpdateDataInput>;
  upsert?: Maybe<CourierBookingUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CourierBookingWhereUniqueInput>;
};

export type CourierBookingUpsertNestedInput = {
  update: CourierBookingUpdateDataInput;
  create: CourierBookingCreateInput;
};

export type CourierBookingWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryStatus?: Maybe<OrderDeliveryState>;
  deliveryStatus_not?: Maybe<OrderDeliveryState>;
  deliveryStatus_in?: Maybe<Array<OrderDeliveryState>>;
  deliveryStatus_not_in?: Maybe<Array<OrderDeliveryState>>;
  courierHistory_every?: Maybe<CourierStatusHistoryWhereInput>;
  courierHistory_some?: Maybe<CourierStatusHistoryWhereInput>;
  courierHistory_none?: Maybe<CourierStatusHistoryWhereInput>;
  courier?: Maybe<CourierWhereInput>;
  pickupDate?: Maybe<Scalars['DateTime']>;
  pickupDate_not?: Maybe<Scalars['DateTime']>;
  pickupDate_in?: Maybe<Array<Scalars['DateTime']>>;
  pickupDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  pickupDate_lt?: Maybe<Scalars['DateTime']>;
  pickupDate_lte?: Maybe<Scalars['DateTime']>;
  pickupDate_gt?: Maybe<Scalars['DateTime']>;
  pickupDate_gte?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  deliveryDate_not?: Maybe<Scalars['DateTime']>;
  deliveryDate_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveryDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveryDate_lt?: Maybe<Scalars['DateTime']>;
  deliveryDate_lte?: Maybe<Scalars['DateTime']>;
  deliveryDate_gt?: Maybe<Scalars['DateTime']>;
  deliveryDate_gte?: Maybe<Scalars['DateTime']>;
  courierReferenceId?: Maybe<Scalars['String']>;
  courierReferenceId_not?: Maybe<Scalars['String']>;
  courierReferenceId_in?: Maybe<Array<Scalars['String']>>;
  courierReferenceId_not_in?: Maybe<Array<Scalars['String']>>;
  courierReferenceId_lt?: Maybe<Scalars['String']>;
  courierReferenceId_lte?: Maybe<Scalars['String']>;
  courierReferenceId_gt?: Maybe<Scalars['String']>;
  courierReferenceId_gte?: Maybe<Scalars['String']>;
  courierReferenceId_contains?: Maybe<Scalars['String']>;
  courierReferenceId_not_contains?: Maybe<Scalars['String']>;
  courierReferenceId_starts_with?: Maybe<Scalars['String']>;
  courierReferenceId_not_starts_with?: Maybe<Scalars['String']>;
  courierReferenceId_ends_with?: Maybe<Scalars['String']>;
  courierReferenceId_not_ends_with?: Maybe<Scalars['String']>;
  pickupCode?: Maybe<Scalars['String']>;
  pickupCode_not?: Maybe<Scalars['String']>;
  pickupCode_in?: Maybe<Array<Scalars['String']>>;
  pickupCode_not_in?: Maybe<Array<Scalars['String']>>;
  pickupCode_lt?: Maybe<Scalars['String']>;
  pickupCode_lte?: Maybe<Scalars['String']>;
  pickupCode_gt?: Maybe<Scalars['String']>;
  pickupCode_gte?: Maybe<Scalars['String']>;
  pickupCode_contains?: Maybe<Scalars['String']>;
  pickupCode_not_contains?: Maybe<Scalars['String']>;
  pickupCode_starts_with?: Maybe<Scalars['String']>;
  pickupCode_not_starts_with?: Maybe<Scalars['String']>;
  pickupCode_ends_with?: Maybe<Scalars['String']>;
  pickupCode_not_ends_with?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentWhereInput>;
  AND?: Maybe<Array<CourierBookingWhereInput>>;
  OR?: Maybe<Array<CourierBookingWhereInput>>;
  NOT?: Maybe<Array<CourierBookingWhereInput>>;
};

export type CourierBookingWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CourierConnection = {
  __typename?: 'CourierConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CourierEdge>>;
  aggregate: AggregateCourier;
};

export type CourierCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  orderCutOffTime?: Maybe<Scalars['Int']>;
};

export type CourierCreateOneInput = {
  create?: Maybe<CourierCreateInput>;
  connect?: Maybe<CourierWhereUniqueInput>;
};

export type CourierDeliveryConfirmationOutput = {
  __typename?: 'CourierDeliveryConfirmationOutput';
  response: Scalars['String'];
};

export type CourierDeliveryForOrderCreateInput = {
  timeSlot?: Maybe<CourierDeliveryTimeSlotInput>;
};

export type CourierDeliveryTimeSlotInput = {
  courierId: Scalars['String'];
  courierRef: Scalars['String'];
  pickupDate: Scalars['DateTime'];
  deliveryDate: Scalars['DateTime'];
  price: Scalars['Float'];
  currency: Scalars['String'];
  courierName: Scalars['String'];
  transportType: Scalars['String'];
};

export type CourierEdge = {
  __typename?: 'CourierEdge';
  node: Courier;
  cursor: Scalars['String'];
};

export enum CourierOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  OrderCutOffTimeAsc = 'orderCutOffTime_ASC',
  OrderCutOffTimeDesc = 'orderCutOffTime_DESC'
}

export type CourierPreviousValues = {
  __typename?: 'CourierPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  orderCutOffTime?: Maybe<Scalars['Int']>;
};

export type CourierStatusHistory = {
  __typename?: 'CourierStatusHistory';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  courier?: Maybe<Courier>;
  courierStatus: Scalars['String'];
  floomStatus?: Maybe<OrderDeliveryState>;
  order: Order;
};

export type CourierStatusHistoryConnection = {
  __typename?: 'CourierStatusHistoryConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CourierStatusHistoryEdge>>;
  aggregate: AggregateCourierStatusHistory;
};

export type CourierStatusHistoryCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  courier?: Maybe<CourierCreateOneInput>;
  courierStatus: Scalars['String'];
  floomStatus?: Maybe<OrderDeliveryState>;
  order: OrderCreateOneInput;
};

export type CourierStatusHistoryCreateManyInput = {
  create?: Maybe<Array<CourierStatusHistoryCreateInput>>;
  connect?: Maybe<Array<CourierStatusHistoryWhereUniqueInput>>;
};

export type CourierStatusHistoryEdge = {
  __typename?: 'CourierStatusHistoryEdge';
  node: CourierStatusHistory;
  cursor: Scalars['String'];
};

export enum CourierStatusHistoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CourierStatusAsc = 'courierStatus_ASC',
  CourierStatusDesc = 'courierStatus_DESC',
  FloomStatusAsc = 'floomStatus_ASC',
  FloomStatusDesc = 'floomStatus_DESC'
}

export type CourierStatusHistoryPreviousValues = {
  __typename?: 'CourierStatusHistoryPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title: Scalars['String'];
  courierStatus: Scalars['String'];
  floomStatus?: Maybe<OrderDeliveryState>;
};

export type CourierStatusHistoryScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  courierStatus?: Maybe<Scalars['String']>;
  courierStatus_not?: Maybe<Scalars['String']>;
  courierStatus_in?: Maybe<Array<Scalars['String']>>;
  courierStatus_not_in?: Maybe<Array<Scalars['String']>>;
  courierStatus_lt?: Maybe<Scalars['String']>;
  courierStatus_lte?: Maybe<Scalars['String']>;
  courierStatus_gt?: Maybe<Scalars['String']>;
  courierStatus_gte?: Maybe<Scalars['String']>;
  courierStatus_contains?: Maybe<Scalars['String']>;
  courierStatus_not_contains?: Maybe<Scalars['String']>;
  courierStatus_starts_with?: Maybe<Scalars['String']>;
  courierStatus_not_starts_with?: Maybe<Scalars['String']>;
  courierStatus_ends_with?: Maybe<Scalars['String']>;
  courierStatus_not_ends_with?: Maybe<Scalars['String']>;
  floomStatus?: Maybe<OrderDeliveryState>;
  floomStatus_not?: Maybe<OrderDeliveryState>;
  floomStatus_in?: Maybe<Array<OrderDeliveryState>>;
  floomStatus_not_in?: Maybe<Array<OrderDeliveryState>>;
  AND?: Maybe<Array<CourierStatusHistoryScalarWhereInput>>;
  OR?: Maybe<Array<CourierStatusHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<CourierStatusHistoryScalarWhereInput>>;
};

export type CourierStatusHistoryWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  courier?: Maybe<CourierWhereInput>;
  courierStatus?: Maybe<Scalars['String']>;
  courierStatus_not?: Maybe<Scalars['String']>;
  courierStatus_in?: Maybe<Array<Scalars['String']>>;
  courierStatus_not_in?: Maybe<Array<Scalars['String']>>;
  courierStatus_lt?: Maybe<Scalars['String']>;
  courierStatus_lte?: Maybe<Scalars['String']>;
  courierStatus_gt?: Maybe<Scalars['String']>;
  courierStatus_gte?: Maybe<Scalars['String']>;
  courierStatus_contains?: Maybe<Scalars['String']>;
  courierStatus_not_contains?: Maybe<Scalars['String']>;
  courierStatus_starts_with?: Maybe<Scalars['String']>;
  courierStatus_not_starts_with?: Maybe<Scalars['String']>;
  courierStatus_ends_with?: Maybe<Scalars['String']>;
  courierStatus_not_ends_with?: Maybe<Scalars['String']>;
  floomStatus?: Maybe<OrderDeliveryState>;
  floomStatus_not?: Maybe<OrderDeliveryState>;
  floomStatus_in?: Maybe<Array<OrderDeliveryState>>;
  floomStatus_not_in?: Maybe<Array<OrderDeliveryState>>;
  order?: Maybe<OrderWhereInput>;
  AND?: Maybe<Array<CourierStatusHistoryWhereInput>>;
  OR?: Maybe<Array<CourierStatusHistoryWhereInput>>;
  NOT?: Maybe<Array<CourierStatusHistoryWhereInput>>;
};

export type CourierStatusHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CourierSubscriptionPayload = {
  __typename?: 'CourierSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Courier>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CourierPreviousValues>;
};

export type CourierSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CourierWhereInput>;
  AND?: Maybe<Array<CourierSubscriptionWhereInput>>;
  OR?: Maybe<Array<CourierSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CourierSubscriptionWhereInput>>;
};

export type CourierTimeSlot = {
  __typename?: 'CourierTimeSlot';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  courierId: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  pickupDate: Scalars['DateTime'];
  deliveryDate: Scalars['DateTime'];
};

export type CourierTimeSlotConnection = {
  __typename?: 'CourierTimeSlotConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CourierTimeSlotEdge>>;
  aggregate: AggregateCourierTimeSlot;
};

export type CourierTimeSlotCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  courierId: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  pickupDate: Scalars['DateTime'];
  deliveryDate: Scalars['DateTime'];
};

export type CourierTimeSlotEdge = {
  __typename?: 'CourierTimeSlotEdge';
  node: CourierTimeSlot;
  cursor: Scalars['String'];
};

export enum CourierTimeSlotOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  CourierIdAsc = 'courierId_ASC',
  CourierIdDesc = 'courierId_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  PickupDateAsc = 'pickupDate_ASC',
  PickupDateDesc = 'pickupDate_DESC',
  DeliveryDateAsc = 'deliveryDate_ASC',
  DeliveryDateDesc = 'deliveryDate_DESC'
}

export type CourierTimeSlotPreviousValues = {
  __typename?: 'CourierTimeSlotPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  courierId: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  pickupDate: Scalars['DateTime'];
  deliveryDate: Scalars['DateTime'];
};

export type CourierTimeSlotSubscriptionPayload = {
  __typename?: 'CourierTimeSlotSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CourierTimeSlot>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CourierTimeSlotPreviousValues>;
};

export type CourierTimeSlotSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CourierTimeSlotWhereInput>;
  AND?: Maybe<Array<CourierTimeSlotSubscriptionWhereInput>>;
  OR?: Maybe<Array<CourierTimeSlotSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CourierTimeSlotSubscriptionWhereInput>>;
};

export type CourierTimeSlotUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  courierId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  pickupDate?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
};

export type CourierTimeSlotUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  courierId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  pickupDate?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
};

export type CourierTimeSlotWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  courierId?: Maybe<Scalars['String']>;
  courierId_not?: Maybe<Scalars['String']>;
  courierId_in?: Maybe<Array<Scalars['String']>>;
  courierId_not_in?: Maybe<Array<Scalars['String']>>;
  courierId_lt?: Maybe<Scalars['String']>;
  courierId_lte?: Maybe<Scalars['String']>;
  courierId_gt?: Maybe<Scalars['String']>;
  courierId_gte?: Maybe<Scalars['String']>;
  courierId_contains?: Maybe<Scalars['String']>;
  courierId_not_contains?: Maybe<Scalars['String']>;
  courierId_starts_with?: Maybe<Scalars['String']>;
  courierId_not_starts_with?: Maybe<Scalars['String']>;
  courierId_ends_with?: Maybe<Scalars['String']>;
  courierId_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Scalars['String']>>;
  type_not_in?: Maybe<Array<Scalars['String']>>;
  type_lt?: Maybe<Scalars['String']>;
  type_lte?: Maybe<Scalars['String']>;
  type_gt?: Maybe<Scalars['String']>;
  type_gte?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  pickupDate?: Maybe<Scalars['DateTime']>;
  pickupDate_not?: Maybe<Scalars['DateTime']>;
  pickupDate_in?: Maybe<Array<Scalars['DateTime']>>;
  pickupDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  pickupDate_lt?: Maybe<Scalars['DateTime']>;
  pickupDate_lte?: Maybe<Scalars['DateTime']>;
  pickupDate_gt?: Maybe<Scalars['DateTime']>;
  pickupDate_gte?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  deliveryDate_not?: Maybe<Scalars['DateTime']>;
  deliveryDate_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveryDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveryDate_lt?: Maybe<Scalars['DateTime']>;
  deliveryDate_lte?: Maybe<Scalars['DateTime']>;
  deliveryDate_gt?: Maybe<Scalars['DateTime']>;
  deliveryDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CourierTimeSlotWhereInput>>;
  OR?: Maybe<Array<CourierTimeSlotWhereInput>>;
  NOT?: Maybe<Array<CourierTimeSlotWhereInput>>;
};

export type CourierTimeSlotWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CourierUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type CourierUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type CourierUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type CourierUpdateOneInput = {
  create?: Maybe<CourierCreateInput>;
  update?: Maybe<CourierUpdateDataInput>;
  upsert?: Maybe<CourierUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CourierWhereUniqueInput>;
};

export type CourierUpdateOneRequiredInput = {
  create?: Maybe<CourierCreateInput>;
  update?: Maybe<CourierUpdateDataInput>;
  upsert?: Maybe<CourierUpsertNestedInput>;
  connect?: Maybe<CourierWhereUniqueInput>;
};

export type CourierUpsertNestedInput = {
  update: CourierUpdateDataInput;
  create: CourierCreateInput;
};

export type CourierWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<CourierWhereInput>>;
  OR?: Maybe<Array<CourierWhereInput>>;
  NOT?: Maybe<Array<CourierWhereInput>>;
};

export type CourierWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type CreateCartLineItemInput = {
  variation: ProductVariationWhereUniqueInput;
  quantity?: Maybe<Scalars['Int']>;
  giftMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
  addOns?: Maybe<Array<Maybe<CartAddOnInput>>>;
};

export type CreateMediaLinkInput = {
  mediaName: Scalars['String'];
  mediaType: Scalars['String'];
  mediaSize: Scalars['Int'];
  mediaHeight?: Maybe<Scalars['Int']>;
  mediaWidth?: Maybe<Scalars['Int']>;
  mediaCategory: MediaCategory;
};

export type CreateMediaLinkOutput = {
  __typename?: 'CreateMediaLinkOutput';
  id: Scalars['ID'];
  url: Scalars['String'];
  key: Scalars['String'];
};

export type CreateOrUpdateCartInput = {
  cartNumber?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerCreateInput>;
  currency?: Maybe<Currency>;
  merchantId: Scalars['String'];
  productId: Scalars['String'];
  deliverAt: Scalars['DateTime'];
  shippingPostalCode: Scalars['String'];
  lineItems?: Maybe<Array<Maybe<CreateCartLineItemInput>>>;
  deliverySafePlace?: Maybe<Scalars['String']>;
  discountCode?: Maybe<Scalars['String']>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  newsletterSignupAt?: Maybe<Scalars['DateTime']>;
  shippingAddress?: Maybe<AddressCreateInput>;
  billingAddress?: Maybe<AddressCreateInput>;
  isAddToCart?: Maybe<Scalars['Boolean']>;
};

export type CreateOrderConsumerDeliveryConfigInput = {
  id: Scalars['String'];
  sameDay: Scalars['Boolean'];
  price: Scalars['Float'];
};

export type CreateOrderConsumerInput = {
  merchant_id: Scalars['Int'];
  orderNo: Scalars['String'];
  status: Scalars['String'];
  isCustom?: Maybe<Scalars['Boolean']>;
  customOrderDescription?: Maybe<Scalars['String']>;
  customerUser: CreateOrderCustomerInput;
  billingAddress: AddressCreateInput;
  shippingAddress: AddressCreateInput;
  orderItems: Array<CreateOrderItemManyInput>;
  currency: Currency;
  tax: OrderTaxCreateInput;
  stripeFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  itemSubtotal?: Maybe<Scalars['Float']>;
  discount?: Maybe<DiscountCreateCustomerInput>;
  deliveryConfig?: Maybe<CreateOrderConsumerDeliveryConfigInput>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliverySafePlace?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  deliverAt: Scalars['DateTime'];
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type CreateOrderCustomDeliveryConfigInput = {
  deliveryConfigId: Scalars['String'];
  sameDay: Scalars['Boolean'];
  price: Scalars['Float'];
};

export type CreateOrderCustomInput = {
  merchant: MerchantWhereUniqueInput;
  customerUser: CreateOrderCustomerInput;
  billingAddress: AddressCreateInput;
  shippingAddress: AddressCreateInput;
  orderItems: Array<CreateOrderCustomItemManyInput>;
  currency: Currency;
  tax?: Maybe<OrderTaxCreateInput>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliverySafePlace?: Maybe<Scalars['String']>;
  orderDeliveryConfig: CreateOrderCustomDeliveryConfigInput;
  placedWithSameDay?: Maybe<Scalars['Boolean']>;
  deliverAt: Scalars['DateTime'];
  discount?: Maybe<DiscountValidationWhereInput>;
};

export type CreateOrderCustomItemManyAddonsInput = {
  id: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type CreateOrderCustomItemManyInput = {
  product_var_id: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  productType?: Maybe<Scalars['String']>;
  giftMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
  discounted?: Maybe<Scalars['Boolean']>;
  addOns?: Maybe<Array<Maybe<CreateOrderCustomItemManyAddonsInput>>>;
};

export type CreateOrderCustomerInput = {
  flc_id?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  givenName: Scalars['String'];
  familyName: Scalars['String'];
};

export type CreateOrderItemManyInput = {
  item_id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  giftMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
  addOnsSnapshot?: Maybe<Scalars['Json']>;
};

export type CreatePaymentIntentInput = {
  cartNumber: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  shouldSaveCard?: Maybe<Scalars['Boolean']>;
};

export enum Currency {
  Gbp = 'GBP',
  Usd = 'USD'
}

export type CustomSiteConfig = {
  __typename?: 'CustomSiteConfig';
  id: Scalars['ID'];
  customSiteProjectId: Scalars['String'];
  customSiteProjectUrl?: Maybe<Scalars['String']>;
  customSiteEmail?: Maybe<Scalars['String']>;
  customSiteEmailOverride?: Maybe<Scalars['Boolean']>;
  mailchimpInformation?: Maybe<MailchimpIntegrationMeta>;
  merchant?: Maybe<Merchant>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomSiteConfigConnection = {
  __typename?: 'CustomSiteConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CustomSiteConfigEdge>>;
  aggregate: AggregateCustomSiteConfig;
};

export type CustomSiteConfigCreateInput = {
  id?: Maybe<Scalars['ID']>;
  customSiteProjectId: Scalars['String'];
  customSiteProjectUrl?: Maybe<Scalars['String']>;
  customSiteEmail?: Maybe<Scalars['String']>;
  customSiteEmailOverride?: Maybe<Scalars['Boolean']>;
  mailchimpInformation?: Maybe<MailchimpIntegrationMetaCreateOneInput>;
  merchant?: Maybe<MerchantCreateOneInput>;
};

export type CustomSiteConfigCreateOneInput = {
  create?: Maybe<CustomSiteConfigCreateInput>;
  connect?: Maybe<CustomSiteConfigWhereUniqueInput>;
};

export type CustomSiteConfigEdge = {
  __typename?: 'CustomSiteConfigEdge';
  node: CustomSiteConfig;
  cursor: Scalars['String'];
};

export enum CustomSiteConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CustomSiteProjectIdAsc = 'customSiteProjectId_ASC',
  CustomSiteProjectIdDesc = 'customSiteProjectId_DESC',
  CustomSiteProjectUrlAsc = 'customSiteProjectUrl_ASC',
  CustomSiteProjectUrlDesc = 'customSiteProjectUrl_DESC',
  CustomSiteEmailAsc = 'customSiteEmail_ASC',
  CustomSiteEmailDesc = 'customSiteEmail_DESC',
  CustomSiteEmailOverrideAsc = 'customSiteEmailOverride_ASC',
  CustomSiteEmailOverrideDesc = 'customSiteEmailOverride_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type CustomSiteConfigPreviousValues = {
  __typename?: 'CustomSiteConfigPreviousValues';
  id: Scalars['ID'];
  customSiteProjectId: Scalars['String'];
  customSiteProjectUrl?: Maybe<Scalars['String']>;
  customSiteEmail?: Maybe<Scalars['String']>;
  customSiteEmailOverride?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomSiteConfigSubscriptionPayload = {
  __typename?: 'CustomSiteConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CustomSiteConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CustomSiteConfigPreviousValues>;
};

export type CustomSiteConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CustomSiteConfigWhereInput>;
  AND?: Maybe<Array<CustomSiteConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<CustomSiteConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CustomSiteConfigSubscriptionWhereInput>>;
};

export type CustomSiteConfigUpdateDataInput = {
  customSiteProjectId?: Maybe<Scalars['String']>;
  customSiteProjectUrl?: Maybe<Scalars['String']>;
  customSiteEmail?: Maybe<Scalars['String']>;
  customSiteEmailOverride?: Maybe<Scalars['Boolean']>;
  mailchimpInformation?: Maybe<MailchimpIntegrationMetaUpdateOneInput>;
};

export type CustomSiteConfigUpdateInput = {
  customSiteEmail?: Maybe<Scalars['String']>;
  customSiteEmailOverride?: Maybe<Scalars['Boolean']>;
  customSiteProjectId?: Maybe<Scalars['String']>;
  customSiteProjectUrl?: Maybe<Scalars['String']>;
  mailchimpInformation?: Maybe<MailchimpIntegrationMetaUpdateOneInput>;
};

export type CustomSiteConfigUpdateManyMutationInput = {
  customSiteProjectId?: Maybe<Scalars['String']>;
  customSiteProjectUrl?: Maybe<Scalars['String']>;
  customSiteEmail?: Maybe<Scalars['String']>;
  customSiteEmailOverride?: Maybe<Scalars['Boolean']>;
};

export type CustomSiteConfigUpdateOneInput = {
  create?: Maybe<CustomSiteConfigCreateInput>;
  update?: Maybe<CustomSiteConfigUpdateDataInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CustomSiteConfigWhereUniqueInput>;
};

export type CustomSiteConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  customSiteProjectId?: Maybe<Scalars['String']>;
  customSiteProjectId_not?: Maybe<Scalars['String']>;
  customSiteProjectId_in?: Maybe<Array<Scalars['String']>>;
  customSiteProjectId_not_in?: Maybe<Array<Scalars['String']>>;
  customSiteProjectId_lt?: Maybe<Scalars['String']>;
  customSiteProjectId_lte?: Maybe<Scalars['String']>;
  customSiteProjectId_gt?: Maybe<Scalars['String']>;
  customSiteProjectId_gte?: Maybe<Scalars['String']>;
  customSiteProjectId_contains?: Maybe<Scalars['String']>;
  customSiteProjectId_not_contains?: Maybe<Scalars['String']>;
  customSiteProjectId_starts_with?: Maybe<Scalars['String']>;
  customSiteProjectId_not_starts_with?: Maybe<Scalars['String']>;
  customSiteProjectId_ends_with?: Maybe<Scalars['String']>;
  customSiteProjectId_not_ends_with?: Maybe<Scalars['String']>;
  customSiteProjectUrl?: Maybe<Scalars['String']>;
  customSiteProjectUrl_not?: Maybe<Scalars['String']>;
  customSiteProjectUrl_in?: Maybe<Array<Scalars['String']>>;
  customSiteProjectUrl_not_in?: Maybe<Array<Scalars['String']>>;
  customSiteProjectUrl_lt?: Maybe<Scalars['String']>;
  customSiteProjectUrl_lte?: Maybe<Scalars['String']>;
  customSiteProjectUrl_gt?: Maybe<Scalars['String']>;
  customSiteProjectUrl_gte?: Maybe<Scalars['String']>;
  customSiteProjectUrl_contains?: Maybe<Scalars['String']>;
  customSiteProjectUrl_not_contains?: Maybe<Scalars['String']>;
  customSiteProjectUrl_starts_with?: Maybe<Scalars['String']>;
  customSiteProjectUrl_not_starts_with?: Maybe<Scalars['String']>;
  customSiteProjectUrl_ends_with?: Maybe<Scalars['String']>;
  customSiteProjectUrl_not_ends_with?: Maybe<Scalars['String']>;
  customSiteEmail?: Maybe<Scalars['String']>;
  customSiteEmail_not?: Maybe<Scalars['String']>;
  customSiteEmail_in?: Maybe<Array<Scalars['String']>>;
  customSiteEmail_not_in?: Maybe<Array<Scalars['String']>>;
  customSiteEmail_lt?: Maybe<Scalars['String']>;
  customSiteEmail_lte?: Maybe<Scalars['String']>;
  customSiteEmail_gt?: Maybe<Scalars['String']>;
  customSiteEmail_gte?: Maybe<Scalars['String']>;
  customSiteEmail_contains?: Maybe<Scalars['String']>;
  customSiteEmail_not_contains?: Maybe<Scalars['String']>;
  customSiteEmail_starts_with?: Maybe<Scalars['String']>;
  customSiteEmail_not_starts_with?: Maybe<Scalars['String']>;
  customSiteEmail_ends_with?: Maybe<Scalars['String']>;
  customSiteEmail_not_ends_with?: Maybe<Scalars['String']>;
  customSiteEmailOverride?: Maybe<Scalars['Boolean']>;
  customSiteEmailOverride_not?: Maybe<Scalars['Boolean']>;
  mailchimpInformation?: Maybe<MailchimpIntegrationMetaWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CustomSiteConfigWhereInput>>;
  OR?: Maybe<Array<CustomSiteConfigWhereInput>>;
  NOT?: Maybe<Array<CustomSiteConfigWhereInput>>;
};

export type CustomSiteConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  customSiteProjectId?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  channel: Array<Channel>;
  lastBillingAddress?: Maybe<Address>;
  lastShippingAddress?: Maybe<Address>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerConnectOneInput = {
  connect: CustomerWhereUniqueInput;
};

export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CustomerEdge>>;
  aggregate: AggregateCustomer;
};

export type CustomerCreateInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  channel?: Maybe<CustomerCreatechannelInput>;
  lastBillingAddress?: Maybe<AddressCreateOneInput>;
  lastShippingAddress?: Maybe<AddressCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerCreateOneInput = {
  create?: Maybe<CustomerCreateInput>;
  connect?: Maybe<CustomerWhereUniqueInput>;
};

export type CustomerCreatechannelInput = {
  set?: Maybe<Array<Channel>>;
};

export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  node: Customer;
  cursor: Scalars['String'];
};

export enum CustomerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  GivenNameAsc = 'givenName_ASC',
  GivenNameDesc = 'givenName_DESC',
  FamilyNameAsc = 'familyName_ASC',
  FamilyNameDesc = 'familyName_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type CustomerPreviousValues = {
  __typename?: 'CustomerPreviousValues';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  familyName: Scalars['String'];
  channel: Array<Channel>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerSubscriptionPayload = {
  __typename?: 'CustomerSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Customer>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<CustomerPreviousValues>;
};

export type CustomerSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<CustomerWhereInput>;
  AND?: Maybe<Array<CustomerSubscriptionWhereInput>>;
  OR?: Maybe<Array<CustomerSubscriptionWhereInput>>;
  NOT?: Maybe<Array<CustomerSubscriptionWhereInput>>;
};

export type CustomerUpdateDataInput = {
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  channel?: Maybe<CustomerUpdatechannelInput>;
  lastBillingAddress?: Maybe<AddressUpdateOneInput>;
  lastShippingAddress?: Maybe<AddressUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerUpdateInput = {
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  channel?: Maybe<CustomerUpdatechannelInput>;
  lastBillingAddress?: Maybe<AddressUpdateOneInput>;
  lastShippingAddress?: Maybe<AddressUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerUpdateManyMutationInput = {
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  channel?: Maybe<CustomerUpdatechannelInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerUpdateOneInput = {
  create?: Maybe<CustomerCreateInput>;
  update?: Maybe<CustomerUpdateDataInput>;
  upsert?: Maybe<CustomerUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CustomerWhereUniqueInput>;
};

export type CustomerUpdatechannelInput = {
  set?: Maybe<Array<Channel>>;
};

export type CustomerUpsertNestedInput = {
  update: CustomerUpdateDataInput;
  create: CustomerCreateInput;
};

export type CustomerWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userId_not?: Maybe<Scalars['String']>;
  userId_in?: Maybe<Array<Scalars['String']>>;
  userId_not_in?: Maybe<Array<Scalars['String']>>;
  userId_lt?: Maybe<Scalars['String']>;
  userId_lte?: Maybe<Scalars['String']>;
  userId_gt?: Maybe<Scalars['String']>;
  userId_gte?: Maybe<Scalars['String']>;
  userId_contains?: Maybe<Scalars['String']>;
  userId_not_contains?: Maybe<Scalars['String']>;
  userId_starts_with?: Maybe<Scalars['String']>;
  userId_not_starts_with?: Maybe<Scalars['String']>;
  userId_ends_with?: Maybe<Scalars['String']>;
  userId_not_ends_with?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeCustomerId_not?: Maybe<Scalars['String']>;
  stripeCustomerId_in?: Maybe<Array<Scalars['String']>>;
  stripeCustomerId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeCustomerId_lt?: Maybe<Scalars['String']>;
  stripeCustomerId_lte?: Maybe<Scalars['String']>;
  stripeCustomerId_gt?: Maybe<Scalars['String']>;
  stripeCustomerId_gte?: Maybe<Scalars['String']>;
  stripeCustomerId_contains?: Maybe<Scalars['String']>;
  stripeCustomerId_not_contains?: Maybe<Scalars['String']>;
  stripeCustomerId_starts_with?: Maybe<Scalars['String']>;
  stripeCustomerId_not_starts_with?: Maybe<Scalars['String']>;
  stripeCustomerId_ends_with?: Maybe<Scalars['String']>;
  stripeCustomerId_not_ends_with?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  givenName_not?: Maybe<Scalars['String']>;
  givenName_in?: Maybe<Array<Scalars['String']>>;
  givenName_not_in?: Maybe<Array<Scalars['String']>>;
  givenName_lt?: Maybe<Scalars['String']>;
  givenName_lte?: Maybe<Scalars['String']>;
  givenName_gt?: Maybe<Scalars['String']>;
  givenName_gte?: Maybe<Scalars['String']>;
  givenName_contains?: Maybe<Scalars['String']>;
  givenName_not_contains?: Maybe<Scalars['String']>;
  givenName_starts_with?: Maybe<Scalars['String']>;
  givenName_not_starts_with?: Maybe<Scalars['String']>;
  givenName_ends_with?: Maybe<Scalars['String']>;
  givenName_not_ends_with?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  familyName_not?: Maybe<Scalars['String']>;
  familyName_in?: Maybe<Array<Scalars['String']>>;
  familyName_not_in?: Maybe<Array<Scalars['String']>>;
  familyName_lt?: Maybe<Scalars['String']>;
  familyName_lte?: Maybe<Scalars['String']>;
  familyName_gt?: Maybe<Scalars['String']>;
  familyName_gte?: Maybe<Scalars['String']>;
  familyName_contains?: Maybe<Scalars['String']>;
  familyName_not_contains?: Maybe<Scalars['String']>;
  familyName_starts_with?: Maybe<Scalars['String']>;
  familyName_not_starts_with?: Maybe<Scalars['String']>;
  familyName_ends_with?: Maybe<Scalars['String']>;
  familyName_not_ends_with?: Maybe<Scalars['String']>;
  lastBillingAddress?: Maybe<AddressWhereInput>;
  lastShippingAddress?: Maybe<AddressWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<CustomerWhereInput>>;
  OR?: Maybe<Array<CustomerWhereInput>>;
  NOT?: Maybe<Array<CustomerWhereInput>>;
};

export type CustomerWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};


export enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export type DeleteResult = {
  __typename?: 'DeleteResult';
  id?: Maybe<Scalars['ID']>;
};

export type DeliveryConfig = {
  __typename?: 'DeliveryConfig';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  default: Scalars['Boolean'];
  merchant: Merchant;
  channel: Channel;
  region?: Maybe<Scalars['String']>;
  deliveryCoverage?: Maybe<Array<DeliveryCoverage>>;
  deliveryTiming: DeliveryTiming;
  deliveryPrice?: Maybe<DeliveryPricing>;
  holidays?: Maybe<Array<MerchantHoliday>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};


export type DeliveryConfigDeliveryCoverageArgs = {
  where?: Maybe<DeliveryCoverageWhereInput>;
  orderBy?: Maybe<DeliveryCoverageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DeliveryConfigHolidaysArgs = {
  where?: Maybe<MerchantHolidayWhereInput>;
  orderBy?: Maybe<MerchantHolidayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DeliveryConfigConnectManyInput = {
  connect?: Maybe<Array<DeliveryConfigWhereUniqueInput>>;
};

export type DeliveryConfigConnection = {
  __typename?: 'DeliveryConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<DeliveryConfigEdge>>;
  aggregate: AggregateDeliveryConfig;
};

export type DeliveryConfigCreateInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  default: Scalars['Boolean'];
  merchant: MerchantCreateOneInput;
  channel: Channel;
  region?: Maybe<Scalars['String']>;
  deliveryCoverage?: Maybe<DeliveryCoverageCreateManyInput>;
  deliveryTiming: DeliveryTimingCreateOneInput;
  deliveryPrice?: Maybe<DeliveryPricingCreateOneInput>;
  holidays?: Maybe<MerchantHolidayCreateManyInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryConfigCreateManyInput = {
  connect?: Maybe<Array<DeliveryConfigWhereUniqueInput>>;
  create?: Maybe<Array<DeliveryConfigCreateInput>>;
};

export type DeliveryConfigEdge = {
  __typename?: 'DeliveryConfigEdge';
  node: DeliveryConfig;
  cursor: Scalars['String'];
};

export enum DeliveryConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DefaultAsc = 'default_ASC',
  DefaultDesc = 'default_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type DeliveryConfigPreviousValues = {
  __typename?: 'DeliveryConfigPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  default: Scalars['Boolean'];
  channel: Channel;
  region?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryConfigScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  default_not?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Channel>;
  channel_not?: Maybe<Channel>;
  channel_in?: Maybe<Array<Channel>>;
  channel_not_in?: Maybe<Array<Channel>>;
  region?: Maybe<Scalars['String']>;
  region_not?: Maybe<Scalars['String']>;
  region_in?: Maybe<Array<Scalars['String']>>;
  region_not_in?: Maybe<Array<Scalars['String']>>;
  region_lt?: Maybe<Scalars['String']>;
  region_lte?: Maybe<Scalars['String']>;
  region_gt?: Maybe<Scalars['String']>;
  region_gte?: Maybe<Scalars['String']>;
  region_contains?: Maybe<Scalars['String']>;
  region_not_contains?: Maybe<Scalars['String']>;
  region_starts_with?: Maybe<Scalars['String']>;
  region_not_starts_with?: Maybe<Scalars['String']>;
  region_ends_with?: Maybe<Scalars['String']>;
  region_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DeliveryConfigScalarWhereInput>>;
  OR?: Maybe<Array<DeliveryConfigScalarWhereInput>>;
  NOT?: Maybe<Array<DeliveryConfigScalarWhereInput>>;
};

export type DeliveryConfigSetManyInput = {
  set?: Maybe<Array<DeliveryConfigWhereUniqueInput>>;
};

export type DeliveryConfigSubscriptionPayload = {
  __typename?: 'DeliveryConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<DeliveryConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<DeliveryConfigPreviousValues>;
};

export type DeliveryConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<DeliveryConfigWhereInput>;
  AND?: Maybe<Array<DeliveryConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<DeliveryConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<DeliveryConfigSubscriptionWhereInput>>;
};

export type DeliveryConfigUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantUpdateOneRequiredInput>;
  channel?: Maybe<Channel>;
  region?: Maybe<Scalars['String']>;
  deliveryCoverage?: Maybe<DeliveryCoverageUpdateManyInput>;
  deliveryTiming?: Maybe<DeliveryTimingUpdateOneRequiredInput>;
  deliveryPrice?: Maybe<DeliveryPricingUpdateOneInput>;
  holidays?: Maybe<MerchantHolidayUpdateManyInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryConfigUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantUpdateOneRequiredInput>;
  channel?: Maybe<Channel>;
  region?: Maybe<Scalars['String']>;
  deliveryCoverage?: Maybe<DeliveryCoverageUpdateManyInput>;
  deliveryTiming?: Maybe<DeliveryTimingUpdateOneRequiredInput>;
  deliveryPrice?: Maybe<DeliveryPricingUpdateOneInput>;
  holidays?: Maybe<MerchantHolidayUpdateManyInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryConfigUpdateManyDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Channel>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryConfigUpdateManyInput = {
  connect?: Maybe<Array<DeliveryConfigWhereUniqueInput>>;
  create?: Maybe<Array<DeliveryConfigCreateInput>>;
  delete?: Maybe<Array<DeliveryConfigWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DeliveryConfigScalarWhereInput>>;
  disconnect?: Maybe<Array<DeliveryConfigWhereUniqueInput>>;
  set?: Maybe<Array<DeliveryConfigWhereUniqueInput>>;
  update?: Maybe<Array<DeliveryConfigUpdateWithWhereUniqueNestedInput>>;
  updateMany?: Maybe<Array<DeliveryConfigUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<DeliveryConfigUpsertWithWhereUniqueNestedInput>>;
};

export type DeliveryConfigUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Channel>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryConfigUpdateManyWithWhereNestedInput = {
  where: DeliveryConfigScalarWhereInput;
  data: DeliveryConfigUpdateManyDataInput;
};

export type DeliveryConfigUpdateOneInput = {
  create?: Maybe<DeliveryConfigCreateInput>;
  update?: Maybe<DeliveryConfigUpdateDataInput>;
  upsert?: Maybe<DeliveryConfigUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DeliveryConfigWhereUniqueInput>;
};

export type DeliveryConfigUpdateWithWhereUniqueNestedInput = {
  where: DeliveryConfigWhereUniqueInput;
  data: DeliveryConfigUpdateDataInput;
};

export type DeliveryConfigUpsertNestedInput = {
  update: DeliveryConfigUpdateDataInput;
  create: DeliveryConfigCreateInput;
};

export type DeliveryConfigUpsertWithWhereUniqueNestedInput = {
  where: DeliveryConfigWhereUniqueInput;
  update: DeliveryConfigUpdateDataInput;
  create: DeliveryConfigCreateInput;
};

export type DeliveryConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  default_not?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantWhereInput>;
  channel?: Maybe<Channel>;
  channel_not?: Maybe<Channel>;
  channel_in?: Maybe<Array<Channel>>;
  channel_not_in?: Maybe<Array<Channel>>;
  region?: Maybe<Scalars['String']>;
  region_not?: Maybe<Scalars['String']>;
  region_in?: Maybe<Array<Scalars['String']>>;
  region_not_in?: Maybe<Array<Scalars['String']>>;
  region_lt?: Maybe<Scalars['String']>;
  region_lte?: Maybe<Scalars['String']>;
  region_gt?: Maybe<Scalars['String']>;
  region_gte?: Maybe<Scalars['String']>;
  region_contains?: Maybe<Scalars['String']>;
  region_not_contains?: Maybe<Scalars['String']>;
  region_starts_with?: Maybe<Scalars['String']>;
  region_not_starts_with?: Maybe<Scalars['String']>;
  region_ends_with?: Maybe<Scalars['String']>;
  region_not_ends_with?: Maybe<Scalars['String']>;
  deliveryCoverage_every?: Maybe<DeliveryCoverageWhereInput>;
  deliveryCoverage_some?: Maybe<DeliveryCoverageWhereInput>;
  deliveryCoverage_none?: Maybe<DeliveryCoverageWhereInput>;
  deliveryTiming?: Maybe<DeliveryTimingWhereInput>;
  deliveryPrice?: Maybe<DeliveryPricingWhereInput>;
  holidays_every?: Maybe<MerchantHolidayWhereInput>;
  holidays_some?: Maybe<MerchantHolidayWhereInput>;
  holidays_none?: Maybe<MerchantHolidayWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DeliveryConfigWhereInput>>;
  OR?: Maybe<Array<DeliveryConfigWhereInput>>;
  NOT?: Maybe<Array<DeliveryConfigWhereInput>>;
};

export type DeliveryConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
};

export type DeliveryCoverage = {
  __typename?: 'DeliveryCoverage';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  channel: Channel;
  flcApproved?: Maybe<Scalars['Boolean']>;
  deliveryZones?: Maybe<Array<DeliveryZone>>;
  type: DeliveryCoverageType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};


export type DeliveryCoverageDeliveryZonesArgs = {
  where?: Maybe<DeliveryZoneWhereInput>;
  orderBy?: Maybe<DeliveryZoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DeliveryCoverageConnection = {
  __typename?: 'DeliveryCoverageConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<DeliveryCoverageEdge>>;
  aggregate: AggregateDeliveryCoverage;
};

export type DeliveryCoverageCreateInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  channel: Channel;
  flcApproved?: Maybe<Scalars['Boolean']>;
  deliveryZones?: Maybe<DeliveryZoneCreateManyInput>;
  type: DeliveryCoverageType;
  deletedAt?: Maybe<Scalars['DateTime']>;
  merchant: MerchantCreateOneInput;
};

export type DeliveryCoverageCreateManyInput = {
  create?: Maybe<Array<DeliveryCoverageCreateInput>>;
  connect?: Maybe<Array<DeliveryCoverageWhereUniqueInput>>;
};

export type DeliveryCoverageEdge = {
  __typename?: 'DeliveryCoverageEdge';
  node: DeliveryCoverage;
  cursor: Scalars['String'];
};

export enum DeliveryCoverageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  FlcApprovedAsc = 'flcApproved_ASC',
  FlcApprovedDesc = 'flcApproved_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type DeliveryCoveragePreviousValues = {
  __typename?: 'DeliveryCoveragePreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  channel: Channel;
  flcApproved?: Maybe<Scalars['Boolean']>;
  type: DeliveryCoverageType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryCoverageScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  label_not?: Maybe<Scalars['String']>;
  label_in?: Maybe<Array<Scalars['String']>>;
  label_not_in?: Maybe<Array<Scalars['String']>>;
  label_lt?: Maybe<Scalars['String']>;
  label_lte?: Maybe<Scalars['String']>;
  label_gt?: Maybe<Scalars['String']>;
  label_gte?: Maybe<Scalars['String']>;
  label_contains?: Maybe<Scalars['String']>;
  label_not_contains?: Maybe<Scalars['String']>;
  label_starts_with?: Maybe<Scalars['String']>;
  label_not_starts_with?: Maybe<Scalars['String']>;
  label_ends_with?: Maybe<Scalars['String']>;
  label_not_ends_with?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  channel_not?: Maybe<Channel>;
  channel_in?: Maybe<Array<Channel>>;
  channel_not_in?: Maybe<Array<Channel>>;
  flcApproved?: Maybe<Scalars['Boolean']>;
  flcApproved_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DeliveryCoverageType>;
  type_not?: Maybe<DeliveryCoverageType>;
  type_in?: Maybe<Array<DeliveryCoverageType>>;
  type_not_in?: Maybe<Array<DeliveryCoverageType>>;
  AND?: Maybe<Array<DeliveryCoverageScalarWhereInput>>;
  OR?: Maybe<Array<DeliveryCoverageScalarWhereInput>>;
  NOT?: Maybe<Array<DeliveryCoverageScalarWhereInput>>;
};

export type DeliveryCoverageSubscriptionPayload = {
  __typename?: 'DeliveryCoverageSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<DeliveryCoverage>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<DeliveryCoveragePreviousValues>;
};

export type DeliveryCoverageSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<DeliveryCoverageWhereInput>;
  AND?: Maybe<Array<DeliveryCoverageSubscriptionWhereInput>>;
  OR?: Maybe<Array<DeliveryCoverageSubscriptionWhereInput>>;
  NOT?: Maybe<Array<DeliveryCoverageSubscriptionWhereInput>>;
};

export enum DeliveryCoverageType {
  Core = 'Core',
  Expanded = 'Expanded',
  Nationwide = 'Nationwide'
}

export type DeliveryCoverageUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantUpdateOneInput>;
  channel?: Maybe<Channel>;
  flcApproved?: Maybe<Scalars['Boolean']>;
  deliveryZones?: Maybe<DeliveryZoneUpdateManyInput>;
  type?: Maybe<DeliveryCoverageType>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryCoverageUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  flcApproved?: Maybe<Scalars['Boolean']>;
  deliveryZones?: Maybe<DeliveryZoneUpdateManyInput>;
  type?: Maybe<DeliveryCoverageType>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryCoverageUpdateManyDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  type?: Maybe<DeliveryCoverageType>;
  flcApproved?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryCoverageUpdateManyInput = {
  create?: Maybe<Array<DeliveryCoverageCreateInput>>;
  update?: Maybe<Array<DeliveryCoverageUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DeliveryCoverageUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DeliveryCoverageWhereUniqueInput>>;
  connect?: Maybe<Array<DeliveryCoverageWhereUniqueInput>>;
  set?: Maybe<Array<DeliveryCoverageWhereUniqueInput>>;
  disconnect?: Maybe<Array<DeliveryCoverageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DeliveryCoverageScalarWhereInput>>;
  updateMany?: Maybe<Array<DeliveryCoverageUpdateManyWithWhereNestedInput>>;
};

export type DeliveryCoverageUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  type?: Maybe<DeliveryCoverageType>;
  flcApproved?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryCoverageUpdateManyWithWhereNestedInput = {
  where: DeliveryCoverageScalarWhereInput;
  data: DeliveryCoverageUpdateManyDataInput;
};

export type DeliveryCoverageUpdateWithWhereUniqueNestedInput = {
  where: DeliveryCoverageWhereUniqueInput;
  data: DeliveryCoverageUpdateDataInput;
};

export type DeliveryCoverageUpsertWithWhereUniqueNestedInput = {
  where: DeliveryCoverageWhereUniqueInput;
  update: DeliveryCoverageUpdateDataInput;
  create: DeliveryCoverageCreateInput;
};

export type DeliveryCoverageWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  label_not?: Maybe<Scalars['String']>;
  label_in?: Maybe<Array<Scalars['String']>>;
  label_not_in?: Maybe<Array<Scalars['String']>>;
  label_lt?: Maybe<Scalars['String']>;
  label_lte?: Maybe<Scalars['String']>;
  label_gt?: Maybe<Scalars['String']>;
  label_gte?: Maybe<Scalars['String']>;
  label_contains?: Maybe<Scalars['String']>;
  label_not_contains?: Maybe<Scalars['String']>;
  label_starts_with?: Maybe<Scalars['String']>;
  label_not_starts_with?: Maybe<Scalars['String']>;
  label_ends_with?: Maybe<Scalars['String']>;
  label_not_ends_with?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereInput>;
  nationwide?: Maybe<Scalars['String']>;
  nationwide_not?: Maybe<Scalars['String']>;
  nationwide_in?: Maybe<Array<Scalars['String']>>;
  nationwide_not_in?: Maybe<Array<Scalars['String']>>;
  nationwide_lt?: Maybe<Scalars['String']>;
  nationwide_lte?: Maybe<Scalars['String']>;
  nationwide_gt?: Maybe<Scalars['String']>;
  nationwide_gte?: Maybe<Scalars['String']>;
  nationwide_contains?: Maybe<Scalars['String']>;
  nationwide_not_contains?: Maybe<Scalars['String']>;
  nationwide_starts_with?: Maybe<Scalars['String']>;
  nationwide_not_starts_with?: Maybe<Scalars['String']>;
  nationwide_ends_with?: Maybe<Scalars['String']>;
  nationwide_not_ends_with?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  channel_not?: Maybe<Channel>;
  channel_in?: Maybe<Array<Channel>>;
  channel_not_in?: Maybe<Array<Channel>>;
  flcApproved?: Maybe<Scalars['Boolean']>;
  flcApproved_not?: Maybe<Scalars['Boolean']>;
  deliveryZones_every?: Maybe<DeliveryZoneWhereInput>;
  deliveryZones_some?: Maybe<DeliveryZoneWhereInput>;
  deliveryZones_none?: Maybe<DeliveryZoneWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<DeliveryCoverageType>;
  type_not?: Maybe<DeliveryCoverageType>;
  type_in?: Maybe<Array<DeliveryCoverageType>>;
  type_not_in?: Maybe<Array<DeliveryCoverageType>>;
  AND?: Maybe<Array<DeliveryCoverageWhereInput>>;
  OR?: Maybe<Array<DeliveryCoverageWhereInput>>;
  NOT?: Maybe<Array<DeliveryCoverageWhereInput>>;
};

export type DeliveryCoverageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type DeliveryDateForSupplier = {
  __typename?: 'DeliveryDateForSupplier';
  dateString: Scalars['String'];
  canDeliver: Scalars['Boolean'];
  cutOffAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryDateOption = {
  __typename?: 'DeliveryDateOption';
  date: Scalars['DateTime'];
  deliveryFee: Scalars['Float'];
  isAvailable: Scalars['Boolean'];
  cutOffTime?: Maybe<Scalars['Int']>;
};

export type DeliveryDateOptionsResponse = {
  __typename?: 'DeliveryDateOptionsResponse';
  deliveryDates?: Maybe<Array<Maybe<DeliveryDateOption>>>;
};

export type DeliveryPricing = {
  __typename?: 'DeliveryPricing';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  channel: Channel;
  sameDayPrice: Scalars['Float'];
  nextDayPrice?: Maybe<Scalars['Float']>;
  perItemDeliveryFee?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryPricingConnection = {
  __typename?: 'DeliveryPricingConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<DeliveryPricingEdge>>;
  aggregate: AggregateDeliveryPricing;
};

export type DeliveryPricingCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantCreateOneInput>;
  channel: Channel;
  sameDayPrice: Scalars['Float'];
  nextDayPrice?: Maybe<Scalars['Float']>;
  perItemDeliveryFee?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryPricingCreateOneInput = {
  create?: Maybe<DeliveryPricingCreateInput>;
  connect?: Maybe<DeliveryPricingWhereUniqueInput>;
};

export type DeliveryPricingEdge = {
  __typename?: 'DeliveryPricingEdge';
  node: DeliveryPricing;
  cursor: Scalars['String'];
};

export enum DeliveryPricingOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  SameDayPriceAsc = 'sameDayPrice_ASC',
  SameDayPriceDesc = 'sameDayPrice_DESC',
  NextDayPriceAsc = 'nextDayPrice_ASC',
  NextDayPriceDesc = 'nextDayPrice_DESC',
  PerItemDeliveryFeeAsc = 'perItemDeliveryFee_ASC',
  PerItemDeliveryFeeDesc = 'perItemDeliveryFee_DESC',
  IsDefaultAsc = 'isDefault_ASC',
  IsDefaultDesc = 'isDefault_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type DeliveryPricingPreviousValues = {
  __typename?: 'DeliveryPricingPreviousValues';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  channel: Channel;
  sameDayPrice: Scalars['Float'];
  nextDayPrice?: Maybe<Scalars['Float']>;
  perItemDeliveryFee?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryPricingSubscriptionPayload = {
  __typename?: 'DeliveryPricingSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<DeliveryPricing>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<DeliveryPricingPreviousValues>;
};

export type DeliveryPricingSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<DeliveryPricingWhereInput>;
  AND?: Maybe<Array<DeliveryPricingSubscriptionWhereInput>>;
  OR?: Maybe<Array<DeliveryPricingSubscriptionWhereInput>>;
  NOT?: Maybe<Array<DeliveryPricingSubscriptionWhereInput>>;
};

export type DeliveryPricingUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantUpdateOneInput>;
  channel?: Maybe<Channel>;
  sameDayPrice?: Maybe<Scalars['Float']>;
  nextDayPrice?: Maybe<Scalars['Float']>;
  perItemDeliveryFee?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryPricingUpdateInput = {
  title?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantUpdateOneInput>;
  channel?: Maybe<Channel>;
  sameDayPrice?: Maybe<Scalars['Float']>;
  nextDayPrice?: Maybe<Scalars['Float']>;
  perItemDeliveryFee?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryPricingUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  sameDayPrice?: Maybe<Scalars['Float']>;
  nextDayPrice?: Maybe<Scalars['Float']>;
  perItemDeliveryFee?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryPricingUpdateOneInput = {
  create?: Maybe<DeliveryPricingCreateInput>;
  update?: Maybe<DeliveryPricingUpdateDataInput>;
  upsert?: Maybe<DeliveryPricingUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<DeliveryPricingWhereUniqueInput>;
};

export type DeliveryPricingUpsertNestedInput = {
  update: DeliveryPricingUpdateDataInput;
  create: DeliveryPricingCreateInput;
};

export type DeliveryPricingWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereInput>;
  channel?: Maybe<Channel>;
  channel_not?: Maybe<Channel>;
  channel_in?: Maybe<Array<Channel>>;
  channel_not_in?: Maybe<Array<Channel>>;
  sameDayPrice?: Maybe<Scalars['Float']>;
  sameDayPrice_not?: Maybe<Scalars['Float']>;
  sameDayPrice_in?: Maybe<Array<Scalars['Float']>>;
  sameDayPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  sameDayPrice_lt?: Maybe<Scalars['Float']>;
  sameDayPrice_lte?: Maybe<Scalars['Float']>;
  sameDayPrice_gt?: Maybe<Scalars['Float']>;
  sameDayPrice_gte?: Maybe<Scalars['Float']>;
  nextDayPrice?: Maybe<Scalars['Float']>;
  nextDayPrice_not?: Maybe<Scalars['Float']>;
  nextDayPrice_in?: Maybe<Array<Scalars['Float']>>;
  nextDayPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  nextDayPrice_lt?: Maybe<Scalars['Float']>;
  nextDayPrice_lte?: Maybe<Scalars['Float']>;
  nextDayPrice_gt?: Maybe<Scalars['Float']>;
  nextDayPrice_gte?: Maybe<Scalars['Float']>;
  perItemDeliveryFee?: Maybe<Scalars['Boolean']>;
  perItemDeliveryFee_not?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDefault_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DeliveryPricingWhereInput>>;
  OR?: Maybe<Array<DeliveryPricingWhereInput>>;
  NOT?: Maybe<Array<DeliveryPricingWhereInput>>;
};

export type DeliveryPricingWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type DeliveryTiming = {
  __typename?: 'DeliveryTiming';
  id: Scalars['ID'];
  title: Scalars['String'];
  leadtime: Scalars['Int'];
  cutOffHour: Scalars['Int'];
  sameDays: Array<Scalars['Boolean']>;
  days: Array<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryTimingConnection = {
  __typename?: 'DeliveryTimingConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<DeliveryTimingEdge>>;
  aggregate: AggregateDeliveryTiming;
};

export type DeliveryTimingCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  leadtime: Scalars['Int'];
  cutOffHour: Scalars['Int'];
  sameDays?: Maybe<DeliveryTimingCreatesameDaysInput>;
  days?: Maybe<DeliveryTimingCreatedaysInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryTimingCreateOneInput = {
  create?: Maybe<DeliveryTimingCreateInput>;
  connect?: Maybe<DeliveryTimingWhereUniqueInput>;
};

export type DeliveryTimingCreatedaysInput = {
  set?: Maybe<Array<Scalars['Boolean']>>;
};

export type DeliveryTimingCreatesameDaysInput = {
  set?: Maybe<Array<Scalars['Boolean']>>;
};

export type DeliveryTimingEdge = {
  __typename?: 'DeliveryTimingEdge';
  node: DeliveryTiming;
  cursor: Scalars['String'];
};

export enum DeliveryTimingOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  LeadtimeAsc = 'leadtime_ASC',
  LeadtimeDesc = 'leadtime_DESC',
  CutOffHourAsc = 'cutOffHour_ASC',
  CutOffHourDesc = 'cutOffHour_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type DeliveryTimingPreviousValues = {
  __typename?: 'DeliveryTimingPreviousValues';
  id: Scalars['ID'];
  title: Scalars['String'];
  leadtime: Scalars['Int'];
  cutOffHour: Scalars['Int'];
  sameDays: Array<Scalars['Boolean']>;
  days: Array<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryTimingSubscriptionPayload = {
  __typename?: 'DeliveryTimingSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<DeliveryTiming>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<DeliveryTimingPreviousValues>;
};

export type DeliveryTimingSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<DeliveryTimingWhereInput>;
  AND?: Maybe<Array<DeliveryTimingSubscriptionWhereInput>>;
  OR?: Maybe<Array<DeliveryTimingSubscriptionWhereInput>>;
  NOT?: Maybe<Array<DeliveryTimingSubscriptionWhereInput>>;
};

export type DeliveryTimingUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  leadtime?: Maybe<Scalars['Int']>;
  cutOffHour?: Maybe<Scalars['Int']>;
  sameDays?: Maybe<DeliveryTimingUpdatesameDaysInput>;
  days?: Maybe<DeliveryTimingUpdatedaysInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryTimingUpdateInput = {
  title?: Maybe<Scalars['String']>;
  leadtime?: Maybe<Scalars['Int']>;
  cutOffHour?: Maybe<Scalars['Int']>;
  sameDays?: Maybe<DeliveryTimingUpdatesameDaysInput>;
  days?: Maybe<DeliveryTimingUpdatedaysInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryTimingUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  leadtime?: Maybe<Scalars['Int']>;
  cutOffHour?: Maybe<Scalars['Int']>;
  sameDays?: Maybe<DeliveryTimingUpdatesameDaysInput>;
  days?: Maybe<DeliveryTimingUpdatedaysInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryTimingUpdateOneRequiredInput = {
  create?: Maybe<DeliveryTimingCreateInput>;
  update?: Maybe<DeliveryTimingUpdateDataInput>;
  upsert?: Maybe<DeliveryTimingUpsertNestedInput>;
  connect?: Maybe<DeliveryTimingWhereUniqueInput>;
};

export type DeliveryTimingUpdatedaysInput = {
  set?: Maybe<Array<Scalars['Boolean']>>;
};

export type DeliveryTimingUpdatesameDaysInput = {
  set?: Maybe<Array<Scalars['Boolean']>>;
};

export type DeliveryTimingUpsertNestedInput = {
  update: DeliveryTimingUpdateDataInput;
  create: DeliveryTimingCreateInput;
};

export type DeliveryTimingWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  leadtime?: Maybe<Scalars['Int']>;
  leadtime_not?: Maybe<Scalars['Int']>;
  leadtime_in?: Maybe<Array<Scalars['Int']>>;
  leadtime_not_in?: Maybe<Array<Scalars['Int']>>;
  leadtime_lt?: Maybe<Scalars['Int']>;
  leadtime_lte?: Maybe<Scalars['Int']>;
  leadtime_gt?: Maybe<Scalars['Int']>;
  leadtime_gte?: Maybe<Scalars['Int']>;
  cutOffHour?: Maybe<Scalars['Int']>;
  cutOffHour_not?: Maybe<Scalars['Int']>;
  cutOffHour_in?: Maybe<Array<Scalars['Int']>>;
  cutOffHour_not_in?: Maybe<Array<Scalars['Int']>>;
  cutOffHour_lt?: Maybe<Scalars['Int']>;
  cutOffHour_lte?: Maybe<Scalars['Int']>;
  cutOffHour_gt?: Maybe<Scalars['Int']>;
  cutOffHour_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DeliveryTimingWhereInput>>;
  OR?: Maybe<Array<DeliveryTimingWhereInput>>;
  NOT?: Maybe<Array<DeliveryTimingWhereInput>>;
};

export type DeliveryTimingWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type DeliveryZone = {
  __typename?: 'DeliveryZone';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  geoJSON?: Maybe<Scalars['Json']>;
  postalCode: Scalars['String'];
  radialDistance?: Maybe<Scalars['Int']>;
  radialCentreLat?: Maybe<Scalars['Float']>;
  radialCentreLon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryZoneConnection = {
  __typename?: 'DeliveryZoneConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<DeliveryZoneEdge>>;
  aggregate: AggregateDeliveryZone;
};

export type DeliveryZoneCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  geoJSON?: Maybe<Scalars['Json']>;
  postalCode: Scalars['String'];
  radialDistance?: Maybe<Scalars['Int']>;
  radialCentreLat?: Maybe<Scalars['Float']>;
  radialCentreLon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryZoneCreateManyInput = {
  create?: Maybe<Array<DeliveryZoneCreateInput>>;
};

export type DeliveryZoneEdge = {
  __typename?: 'DeliveryZoneEdge';
  node: DeliveryZone;
  cursor: Scalars['String'];
};

export enum DeliveryZoneOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  GeoJsonAsc = 'geoJSON_ASC',
  GeoJsonDesc = 'geoJSON_DESC',
  PostalCodeAsc = 'postalCode_ASC',
  PostalCodeDesc = 'postalCode_DESC',
  RadialDistanceAsc = 'radialDistance_ASC',
  RadialDistanceDesc = 'radialDistance_DESC',
  RadialCentreLatAsc = 'radialCentreLat_ASC',
  RadialCentreLatDesc = 'radialCentreLat_DESC',
  RadialCentreLonAsc = 'radialCentreLon_ASC',
  RadialCentreLonDesc = 'radialCentreLon_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type DeliveryZonePreviousValues = {
  __typename?: 'DeliveryZonePreviousValues';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  geoJSON?: Maybe<Scalars['Json']>;
  postalCode: Scalars['String'];
  radialDistance?: Maybe<Scalars['Int']>;
  radialCentreLat?: Maybe<Scalars['Float']>;
  radialCentreLon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryZoneScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCode_not?: Maybe<Scalars['String']>;
  postalCode_in?: Maybe<Array<Scalars['String']>>;
  postalCode_not_in?: Maybe<Array<Scalars['String']>>;
  postalCode_lt?: Maybe<Scalars['String']>;
  postalCode_lte?: Maybe<Scalars['String']>;
  postalCode_gt?: Maybe<Scalars['String']>;
  postalCode_gte?: Maybe<Scalars['String']>;
  postalCode_contains?: Maybe<Scalars['String']>;
  postalCode_not_contains?: Maybe<Scalars['String']>;
  postalCode_starts_with?: Maybe<Scalars['String']>;
  postalCode_not_starts_with?: Maybe<Scalars['String']>;
  postalCode_ends_with?: Maybe<Scalars['String']>;
  postalCode_not_ends_with?: Maybe<Scalars['String']>;
  radialDistance?: Maybe<Scalars['Int']>;
  radialDistance_not?: Maybe<Scalars['Int']>;
  radialDistance_in?: Maybe<Array<Scalars['Int']>>;
  radialDistance_not_in?: Maybe<Array<Scalars['Int']>>;
  radialDistance_lt?: Maybe<Scalars['Int']>;
  radialDistance_lte?: Maybe<Scalars['Int']>;
  radialDistance_gt?: Maybe<Scalars['Int']>;
  radialDistance_gte?: Maybe<Scalars['Int']>;
  radialCentreLat?: Maybe<Scalars['Float']>;
  radialCentreLat_not?: Maybe<Scalars['Float']>;
  radialCentreLat_in?: Maybe<Array<Scalars['Float']>>;
  radialCentreLat_not_in?: Maybe<Array<Scalars['Float']>>;
  radialCentreLat_lt?: Maybe<Scalars['Float']>;
  radialCentreLat_lte?: Maybe<Scalars['Float']>;
  radialCentreLat_gt?: Maybe<Scalars['Float']>;
  radialCentreLat_gte?: Maybe<Scalars['Float']>;
  radialCentreLon?: Maybe<Scalars['Float']>;
  radialCentreLon_not?: Maybe<Scalars['Float']>;
  radialCentreLon_in?: Maybe<Array<Scalars['Float']>>;
  radialCentreLon_not_in?: Maybe<Array<Scalars['Float']>>;
  radialCentreLon_lt?: Maybe<Scalars['Float']>;
  radialCentreLon_lte?: Maybe<Scalars['Float']>;
  radialCentreLon_gt?: Maybe<Scalars['Float']>;
  radialCentreLon_gte?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  region_not?: Maybe<Scalars['String']>;
  region_in?: Maybe<Array<Scalars['String']>>;
  region_not_in?: Maybe<Array<Scalars['String']>>;
  region_lt?: Maybe<Scalars['String']>;
  region_lte?: Maybe<Scalars['String']>;
  region_gt?: Maybe<Scalars['String']>;
  region_gte?: Maybe<Scalars['String']>;
  region_contains?: Maybe<Scalars['String']>;
  region_not_contains?: Maybe<Scalars['String']>;
  region_starts_with?: Maybe<Scalars['String']>;
  region_not_starts_with?: Maybe<Scalars['String']>;
  region_ends_with?: Maybe<Scalars['String']>;
  region_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DeliveryZoneScalarWhereInput>>;
  OR?: Maybe<Array<DeliveryZoneScalarWhereInput>>;
  NOT?: Maybe<Array<DeliveryZoneScalarWhereInput>>;
};

export type DeliveryZoneSubscriptionPayload = {
  __typename?: 'DeliveryZoneSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<DeliveryZone>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<DeliveryZonePreviousValues>;
};

export type DeliveryZoneSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<DeliveryZoneWhereInput>;
  AND?: Maybe<Array<DeliveryZoneSubscriptionWhereInput>>;
  OR?: Maybe<Array<DeliveryZoneSubscriptionWhereInput>>;
  NOT?: Maybe<Array<DeliveryZoneSubscriptionWhereInput>>;
};

export type DeliveryZoneUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  geoJSON?: Maybe<Scalars['Json']>;
  postalCode?: Maybe<Scalars['String']>;
  radialDistance?: Maybe<Scalars['Int']>;
  radialCentreLat?: Maybe<Scalars['Float']>;
  radialCentreLon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryZoneUpdateInput = {
  title?: Maybe<Scalars['String']>;
  geoJSON?: Maybe<Scalars['Json']>;
  postalCode?: Maybe<Scalars['String']>;
  radialDistance?: Maybe<Scalars['Int']>;
  radialCentreLat?: Maybe<Scalars['Float']>;
  radialCentreLon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryZoneUpdateManyDataInput = {
  title?: Maybe<Scalars['String']>;
  geoJSON?: Maybe<Scalars['Json']>;
  postalCode?: Maybe<Scalars['String']>;
  radialDistance?: Maybe<Scalars['Int']>;
  radialCentreLat?: Maybe<Scalars['Float']>;
  radialCentreLon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryZoneUpdateManyInput = {
  create?: Maybe<Array<DeliveryZoneCreateInput>>;
};

export type DeliveryZoneUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  geoJSON?: Maybe<Scalars['Json']>;
  postalCode?: Maybe<Scalars['String']>;
  radialDistance?: Maybe<Scalars['Int']>;
  radialCentreLat?: Maybe<Scalars['Float']>;
  radialCentreLon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type DeliveryZoneUpdateManyWithWhereNestedInput = {
  where: DeliveryZoneScalarWhereInput;
  data: DeliveryZoneUpdateManyDataInput;
};

export type DeliveryZoneUpdateWithWhereUniqueNestedInput = {
  where: DeliveryZoneWhereUniqueInput;
  data: DeliveryZoneUpdateDataInput;
};

export type DeliveryZoneUpsertWithWhereUniqueNestedInput = {
  where: DeliveryZoneWhereUniqueInput;
  update: DeliveryZoneUpdateDataInput;
  create: DeliveryZoneCreateInput;
};

export type DeliveryZoneWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCode_not?: Maybe<Scalars['String']>;
  postalCode_in?: Maybe<Array<Scalars['String']>>;
  postalCode_not_in?: Maybe<Array<Scalars['String']>>;
  postalCode_lt?: Maybe<Scalars['String']>;
  postalCode_lte?: Maybe<Scalars['String']>;
  postalCode_gt?: Maybe<Scalars['String']>;
  postalCode_gte?: Maybe<Scalars['String']>;
  postalCode_contains?: Maybe<Scalars['String']>;
  postalCode_not_contains?: Maybe<Scalars['String']>;
  postalCode_starts_with?: Maybe<Scalars['String']>;
  postalCode_not_starts_with?: Maybe<Scalars['String']>;
  postalCode_ends_with?: Maybe<Scalars['String']>;
  postalCode_not_ends_with?: Maybe<Scalars['String']>;
  radialDistance?: Maybe<Scalars['Int']>;
  radialDistance_not?: Maybe<Scalars['Int']>;
  radialDistance_in?: Maybe<Array<Scalars['Int']>>;
  radialDistance_not_in?: Maybe<Array<Scalars['Int']>>;
  radialDistance_lt?: Maybe<Scalars['Int']>;
  radialDistance_lte?: Maybe<Scalars['Int']>;
  radialDistance_gt?: Maybe<Scalars['Int']>;
  radialDistance_gte?: Maybe<Scalars['Int']>;
  radialCentreLat?: Maybe<Scalars['Float']>;
  radialCentreLat_not?: Maybe<Scalars['Float']>;
  radialCentreLat_in?: Maybe<Array<Scalars['Float']>>;
  radialCentreLat_not_in?: Maybe<Array<Scalars['Float']>>;
  radialCentreLat_lt?: Maybe<Scalars['Float']>;
  radialCentreLat_lte?: Maybe<Scalars['Float']>;
  radialCentreLat_gt?: Maybe<Scalars['Float']>;
  radialCentreLat_gte?: Maybe<Scalars['Float']>;
  radialCentreLon?: Maybe<Scalars['Float']>;
  radialCentreLon_not?: Maybe<Scalars['Float']>;
  radialCentreLon_in?: Maybe<Array<Scalars['Float']>>;
  radialCentreLon_not_in?: Maybe<Array<Scalars['Float']>>;
  radialCentreLon_lt?: Maybe<Scalars['Float']>;
  radialCentreLon_lte?: Maybe<Scalars['Float']>;
  radialCentreLon_gt?: Maybe<Scalars['Float']>;
  radialCentreLon_gte?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  region_not?: Maybe<Scalars['String']>;
  region_in?: Maybe<Array<Scalars['String']>>;
  region_not_in?: Maybe<Array<Scalars['String']>>;
  region_lt?: Maybe<Scalars['String']>;
  region_lte?: Maybe<Scalars['String']>;
  region_gt?: Maybe<Scalars['String']>;
  region_gte?: Maybe<Scalars['String']>;
  region_contains?: Maybe<Scalars['String']>;
  region_not_contains?: Maybe<Scalars['String']>;
  region_starts_with?: Maybe<Scalars['String']>;
  region_not_starts_with?: Maybe<Scalars['String']>;
  region_ends_with?: Maybe<Scalars['String']>;
  region_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DeliveryZoneWhereInput>>;
  OR?: Maybe<Array<DeliveryZoneWhereInput>>;
  NOT?: Maybe<Array<DeliveryZoneWhereInput>>;
};

export type DeliveryZoneWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type Dimensions = {
  __typename?: 'Dimensions';
  id: Scalars['ID'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DimensionsConnection = {
  __typename?: 'DimensionsConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<DimensionsEdge>>;
  aggregate: AggregateDimensions;
};

export type DimensionsCreateInput = {
  id?: Maybe<Scalars['ID']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
};

export type DimensionsCreateOneInput = {
  connect?: Maybe<DimensionsWhereUniqueInput>;
  create?: Maybe<DimensionsCreateInput>;
};

export type DimensionsEdge = {
  __typename?: 'DimensionsEdge';
  node: Dimensions;
  cursor: Scalars['String'];
};

export enum DimensionsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  LengthAsc = 'length_ASC',
  LengthDesc = 'length_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DimensionsPreviousValues = {
  __typename?: 'DimensionsPreviousValues';
  id: Scalars['ID'];
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DimensionsSubscriptionPayload = {
  __typename?: 'DimensionsSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Dimensions>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<DimensionsPreviousValues>;
};

export type DimensionsSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<DimensionsWhereInput>;
  AND?: Maybe<Array<DimensionsSubscriptionWhereInput>>;
  OR?: Maybe<Array<DimensionsSubscriptionWhereInput>>;
  NOT?: Maybe<Array<DimensionsSubscriptionWhereInput>>;
};

export type DimensionsUpdateDataInput = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
};

export type DimensionsUpdateInput = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
};

export type DimensionsUpdateManyMutationInput = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
};

export type DimensionsUpdateOneInput = {
  connect?: Maybe<DimensionsWhereUniqueInput>;
  create?: Maybe<DimensionsCreateInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DimensionsUpdateDataInput>;
  upsert?: Maybe<DimensionsUpsertNestedInput>;
};

export type DimensionsUpsertNestedInput = {
  update: DimensionsUpdateDataInput;
  create: DimensionsCreateInput;
};

export type DimensionsWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  width?: Maybe<Scalars['Int']>;
  width_not?: Maybe<Scalars['Int']>;
  width_in?: Maybe<Array<Scalars['Int']>>;
  width_not_in?: Maybe<Array<Scalars['Int']>>;
  width_lt?: Maybe<Scalars['Int']>;
  width_lte?: Maybe<Scalars['Int']>;
  width_gt?: Maybe<Scalars['Int']>;
  width_gte?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  height_not?: Maybe<Scalars['Int']>;
  height_in?: Maybe<Array<Scalars['Int']>>;
  height_not_in?: Maybe<Array<Scalars['Int']>>;
  height_lt?: Maybe<Scalars['Int']>;
  height_lte?: Maybe<Scalars['Int']>;
  height_gt?: Maybe<Scalars['Int']>;
  height_gte?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  length_not?: Maybe<Scalars['Int']>;
  length_in?: Maybe<Array<Scalars['Int']>>;
  length_not_in?: Maybe<Array<Scalars['Int']>>;
  length_lt?: Maybe<Scalars['Int']>;
  length_lte?: Maybe<Scalars['Int']>;
  length_gt?: Maybe<Scalars['Int']>;
  length_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<DimensionsWhereInput>>;
  OR?: Maybe<Array<DimensionsWhereInput>>;
  NOT?: Maybe<Array<DimensionsWhereInput>>;
};

export type DimensionsWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Discount = {
  __typename?: 'Discount';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  channelComplete?: Maybe<Channel>;
  channels?: Maybe<Array<ChannelType>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<Scalars['Int']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amountMetric?: Maybe<DiscountMetric>;
  minBasketValue?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFreeShipping?: Maybe<Scalars['Boolean']>;
  isOncePerOrder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  selectedProducts?: Maybe<Array<Product>>;
  productTypes?: Maybe<Array<ProductType>>;
  productCategories?: Maybe<Array<ProductCategory>>;
  merchant?: Maybe<Merchant>;
  customers?: Maybe<Array<Customer>>;
  usageCount?: Maybe<Scalars['Int']>;
  totalRevenue?: Maybe<Scalars['Float']>;
  amountDiscounted?: Maybe<Scalars['Float']>;
  orderDiscountAmount?: Maybe<Scalars['Float']>;
};


export type DiscountChannelsArgs = {
  where?: Maybe<ChannelTypeWhereInput>;
  orderBy?: Maybe<ChannelTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DiscountSelectedProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<ProductOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DiscountProductTypesArgs = {
  where?: Maybe<ProductTypeWhereInput>;
  orderBy?: Maybe<ProductTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DiscountProductCategoriesArgs = {
  where?: Maybe<ProductCategoryWhereInput>;
  orderBy?: Maybe<ProductCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DiscountCustomersArgs = {
  where?: Maybe<CustomerWhereInput>;
  orderBy?: Maybe<CustomerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DiscountOrderDiscountAmountArgs = {
  where: OrderDiscountAmountWhereInput;
};

export type DiscountConnection = {
  __typename?: 'DiscountConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<DiscountEdge>>;
  aggregate: AggregateDiscount;
};

export type DiscountCreateCustomerInput = {
  name: Scalars['String'];
  amount: Scalars['Float'];
  flc_code: Scalars['String'];
  purchaseTotal?: Maybe<Scalars['Int']>;
  purchaseQty?: Maybe<Scalars['Int']>;
  maxPurchaseQty?: Maybe<Scalars['Int']>;
  freeShipping?: Maybe<Scalars['Boolean']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  perEmailLimit?: Maybe<Scalars['Int']>;
  totalUseLimit?: Maybe<Scalars['Int']>;
  baseDiscount?: Maybe<Scalars['Float']>;
  perItemDiscount?: Maybe<Scalars['Float']>;
  percentDiscount?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
};

export type DiscountCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  channelComplete?: Maybe<Channel>;
  channels?: Maybe<ChannelTypeCreateManyInput>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<Scalars['Int']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amountMetric?: Maybe<DiscountMetric>;
  minBasketValue?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFreeShipping?: Maybe<Scalars['Boolean']>;
  isOncePerOrder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  selectedProducts?: Maybe<DiscountCreateProductConnectInput>;
  productTypes?: Maybe<ProductTypeConnectManyInput>;
  productCategories?: Maybe<DiscountCreateProductCategoryConnectInput>;
  merchant?: Maybe<MerchantConnectOneInput>;
};

export type DiscountCreateManyInput = {
  create?: Maybe<Array<DiscountCreateInput>>;
  connect?: Maybe<Array<DiscountWhereUniqueInput>>;
};

export type DiscountCreateProductCategoryConnectInput = {
  connect?: Maybe<Array<ProductCategoryWhereUniqueInput>>;
};

export type DiscountCreateProductConnectInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type DiscountEdge = {
  __typename?: 'DiscountEdge';
  node: Discount;
  cursor: Scalars['String'];
};

export enum DiscountMetric {
  Percent = 'Percent',
  Fixed = 'Fixed'
}

export enum DiscountOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  LastEditedAtAsc = 'lastEditedAt_ASC',
  LastEditedAtDesc = 'lastEditedAt_DESC',
  ChannelCompleteAsc = 'channelComplete_ASC',
  ChannelCompleteDesc = 'channelComplete_DESC',
  StartsAtAsc = 'startsAt_ASC',
  StartsAtDesc = 'startsAt_DESC',
  EndsAtAsc = 'endsAt_ASC',
  EndsAtDesc = 'endsAt_DESC',
  UsageLimitAsc = 'usageLimit_ASC',
  UsageLimitDesc = 'usageLimit_DESC',
  PerUserLimitAsc = 'perUserLimit_ASC',
  PerUserLimitDesc = 'perUserLimit_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  AmountMetricAsc = 'amountMetric_ASC',
  AmountMetricDesc = 'amountMetric_DESC',
  MinBasketValueAsc = 'minBasketValue_ASC',
  MinBasketValueDesc = 'minBasketValue_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsFreeShippingAsc = 'isFreeShipping_ASC',
  IsFreeShippingDesc = 'isFreeShipping_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC'
}

export type DiscountPreviousValues = {
  __typename?: 'DiscountPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  channelComplete?: Maybe<Channel>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<Scalars['Int']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amountMetric?: Maybe<DiscountMetric>;
  minBasketValue?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFreeShipping?: Maybe<Scalars['Boolean']>;
  isOncePerOrder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DiscountScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  lastEditedAt_not?: Maybe<Scalars['DateTime']>;
  lastEditedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  lastEditedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  lastEditedAt_lt?: Maybe<Scalars['DateTime']>;
  lastEditedAt_lte?: Maybe<Scalars['DateTime']>;
  lastEditedAt_gt?: Maybe<Scalars['DateTime']>;
  lastEditedAt_gte?: Maybe<Scalars['DateTime']>;
  channelComplete?: Maybe<Channel>;
  channelComplete_not?: Maybe<Channel>;
  channelComplete_in?: Maybe<Array<Channel>>;
  channelComplete_not_in?: Maybe<Array<Channel>>;
  startsAt?: Maybe<Scalars['DateTime']>;
  startsAt_not?: Maybe<Scalars['DateTime']>;
  startsAt_in?: Maybe<Array<Scalars['DateTime']>>;
  startsAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startsAt_lt?: Maybe<Scalars['DateTime']>;
  startsAt_lte?: Maybe<Scalars['DateTime']>;
  startsAt_gt?: Maybe<Scalars['DateTime']>;
  startsAt_gte?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  endsAt_not?: Maybe<Scalars['DateTime']>;
  endsAt_in?: Maybe<Array<Scalars['DateTime']>>;
  endsAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endsAt_lt?: Maybe<Scalars['DateTime']>;
  endsAt_lte?: Maybe<Scalars['DateTime']>;
  endsAt_gt?: Maybe<Scalars['DateTime']>;
  endsAt_gte?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<Scalars['Int']>;
  usageLimit_not?: Maybe<Scalars['Int']>;
  usageLimit_in?: Maybe<Array<Scalars['Int']>>;
  usageLimit_not_in?: Maybe<Array<Scalars['Int']>>;
  usageLimit_lt?: Maybe<Scalars['Int']>;
  usageLimit_lte?: Maybe<Scalars['Int']>;
  usageLimit_gt?: Maybe<Scalars['Int']>;
  usageLimit_gte?: Maybe<Scalars['Int']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  perUserLimit_not?: Maybe<Scalars['Int']>;
  perUserLimit_in?: Maybe<Array<Scalars['Int']>>;
  perUserLimit_not_in?: Maybe<Array<Scalars['Int']>>;
  perUserLimit_lt?: Maybe<Scalars['Int']>;
  perUserLimit_lte?: Maybe<Scalars['Int']>;
  perUserLimit_gt?: Maybe<Scalars['Int']>;
  perUserLimit_gte?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amount_not?: Maybe<Scalars['Float']>;
  amount_in?: Maybe<Array<Scalars['Float']>>;
  amount_not_in?: Maybe<Array<Scalars['Float']>>;
  amount_lt?: Maybe<Scalars['Float']>;
  amount_lte?: Maybe<Scalars['Float']>;
  amount_gt?: Maybe<Scalars['Float']>;
  amount_gte?: Maybe<Scalars['Float']>;
  amountMetric?: Maybe<DiscountMetric>;
  amountMetric_not?: Maybe<DiscountMetric>;
  amountMetric_in?: Maybe<Array<DiscountMetric>>;
  amountMetric_not_in?: Maybe<Array<DiscountMetric>>;
  minBasketValue?: Maybe<Scalars['Int']>;
  minBasketValue_not?: Maybe<Scalars['Int']>;
  minBasketValue_in?: Maybe<Array<Scalars['Int']>>;
  minBasketValue_not_in?: Maybe<Array<Scalars['Int']>>;
  minBasketValue_lt?: Maybe<Scalars['Int']>;
  minBasketValue_lte?: Maybe<Scalars['Int']>;
  minBasketValue_gt?: Maybe<Scalars['Int']>;
  minBasketValue_gte?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  isFreeShipping?: Maybe<Scalars['Boolean']>;
  isOncePerOrder?: Maybe<Scalars['Boolean']>;
  isFreeShipping_not?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<DiscountScalarWhereInput>>;
  OR?: Maybe<Array<DiscountScalarWhereInput>>;
  NOT?: Maybe<Array<DiscountScalarWhereInput>>;
};

export type DiscountUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  channelComplete?: Maybe<Channel>;
  channels?: Maybe<ChannelTypeUpdateManyInput>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<Scalars['Int']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amountMetric?: Maybe<DiscountMetric>;
  minBasketValue?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFreeShipping?: Maybe<Scalars['Boolean']>;
  isOncePerOrder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  selectedProducts?: Maybe<DiscountUpdateSelectedProductsInput>;
  productTypes?: Maybe<DiscountUpdateProductTypesInput>;
  productCategories?: Maybe<DiscountUpdateProductCategoryInput>;
};

export type DiscountUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  channelComplete?: Maybe<Channel>;
  channels?: Maybe<ChannelTypeUpdateManyInput>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<Scalars['Int']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amountMetric?: Maybe<DiscountMetric>;
  minBasketValue?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFreeShipping?: Maybe<Scalars['Boolean']>;
  isOncePerOrder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  selectedProducts?: Maybe<DiscountUpdateSelectedProductsInput>;
  productTypes?: Maybe<DiscountUpdateProductTypesInput>;
  productCategories?: Maybe<DiscountUpdateProductCategoryInput>;
};

export type DiscountUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  channelComplete?: Maybe<Channel>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<Scalars['Int']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amountMetric?: Maybe<DiscountMetric>;
  minBasketValue?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFreeShipping?: Maybe<Scalars['Boolean']>;
  isOncePerOrder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DiscountUpdateManyInput = {
  create?: Maybe<Array<DiscountCreateInput>>;
  update?: Maybe<Array<DiscountUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<DiscountUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<DiscountWhereUniqueInput>>;
  connect?: Maybe<Array<DiscountWhereUniqueInput>>;
  set?: Maybe<Array<DiscountWhereUniqueInput>>;
  disconnect?: Maybe<Array<DiscountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DiscountScalarWhereInput>>;
  updateMany?: Maybe<Array<DiscountUpdateManyWithWhereNestedInput>>;
};

export type DiscountUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  channelComplete?: Maybe<Channel>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<Scalars['Int']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amountMetric?: Maybe<DiscountMetric>;
  minBasketValue?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isFreeShipping?: Maybe<Scalars['Boolean']>;
  isOncePerOrder?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DiscountUpdateManyWithWhereNestedInput = {
  where: DiscountScalarWhereInput;
  data: DiscountUpdateManyDataInput;
};

export type DiscountUpdateProductCategoryInput = {
  set?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type DiscountUpdateProductTypesInput = {
  set?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type DiscountUpdateSelectedProductsInput = {
  set?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type DiscountUpdateWithWhereUniqueNestedInput = {
  where: DiscountWhereUniqueInput;
  data: DiscountUpdateDataInput;
};

export type DiscountUpsertWithWhereUniqueNestedInput = {
  where: DiscountWhereUniqueInput;
  update: DiscountUpdateDataInput;
  create: DiscountCreateInput;
};

export type DiscountValidationWhereInput = {
  code: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  merchantId: Scalars['String'];
  variationIds: Array<Scalars['ID']>;
  orderSubTotal?: Maybe<Scalars['Float']>;
  channel?: Maybe<Channel>;
};

export type DiscountWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  lastEditedAt?: Maybe<Scalars['DateTime']>;
  lastEditedAt_not?: Maybe<Scalars['DateTime']>;
  lastEditedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  lastEditedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  lastEditedAt_lt?: Maybe<Scalars['DateTime']>;
  lastEditedAt_lte?: Maybe<Scalars['DateTime']>;
  lastEditedAt_gt?: Maybe<Scalars['DateTime']>;
  lastEditedAt_gte?: Maybe<Scalars['DateTime']>;
  channelComplete?: Maybe<Channel>;
  channelComplete_not?: Maybe<Channel>;
  channelComplete_in?: Maybe<Array<Channel>>;
  channelComplete_not_in?: Maybe<Array<Channel>>;
  channels_every?: Maybe<ChannelTypeWhereInput>;
  channels_some?: Maybe<ChannelTypeWhereInput>;
  channels_none?: Maybe<ChannelTypeWhereInput>;
  startsAt?: Maybe<Scalars['DateTime']>;
  startsAt_not?: Maybe<Scalars['DateTime']>;
  startsAt_in?: Maybe<Array<Scalars['DateTime']>>;
  startsAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startsAt_lt?: Maybe<Scalars['DateTime']>;
  startsAt_lte?: Maybe<Scalars['DateTime']>;
  startsAt_gt?: Maybe<Scalars['DateTime']>;
  startsAt_gte?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  endsAt_not?: Maybe<Scalars['DateTime']>;
  endsAt_in?: Maybe<Array<Scalars['DateTime']>>;
  endsAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endsAt_lt?: Maybe<Scalars['DateTime']>;
  endsAt_lte?: Maybe<Scalars['DateTime']>;
  endsAt_gt?: Maybe<Scalars['DateTime']>;
  endsAt_gte?: Maybe<Scalars['DateTime']>;
  usageLimit?: Maybe<Scalars['Int']>;
  usageLimit_not?: Maybe<Scalars['Int']>;
  usageLimit_in?: Maybe<Array<Scalars['Int']>>;
  usageLimit_not_in?: Maybe<Array<Scalars['Int']>>;
  usageLimit_lt?: Maybe<Scalars['Int']>;
  usageLimit_lte?: Maybe<Scalars['Int']>;
  usageLimit_gt?: Maybe<Scalars['Int']>;
  usageLimit_gte?: Maybe<Scalars['Int']>;
  perUserLimit?: Maybe<Scalars['Int']>;
  perUserLimit_not?: Maybe<Scalars['Int']>;
  perUserLimit_in?: Maybe<Array<Scalars['Int']>>;
  perUserLimit_not_in?: Maybe<Array<Scalars['Int']>>;
  perUserLimit_lt?: Maybe<Scalars['Int']>;
  perUserLimit_lte?: Maybe<Scalars['Int']>;
  perUserLimit_gt?: Maybe<Scalars['Int']>;
  perUserLimit_gte?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amount_not?: Maybe<Scalars['Float']>;
  amount_in?: Maybe<Array<Scalars['Float']>>;
  amount_not_in?: Maybe<Array<Scalars['Float']>>;
  amount_lt?: Maybe<Scalars['Float']>;
  amount_lte?: Maybe<Scalars['Float']>;
  amount_gt?: Maybe<Scalars['Float']>;
  amount_gte?: Maybe<Scalars['Float']>;
  amountMetric?: Maybe<DiscountMetric>;
  amountMetric_not?: Maybe<DiscountMetric>;
  amountMetric_in?: Maybe<Array<DiscountMetric>>;
  amountMetric_not_in?: Maybe<Array<DiscountMetric>>;
  minBasketValue?: Maybe<Scalars['Int']>;
  minBasketValue_not?: Maybe<Scalars['Int']>;
  minBasketValue_in?: Maybe<Array<Scalars['Int']>>;
  minBasketValue_not_in?: Maybe<Array<Scalars['Int']>>;
  minBasketValue_lt?: Maybe<Scalars['Int']>;
  minBasketValue_lte?: Maybe<Scalars['Int']>;
  minBasketValue_gt?: Maybe<Scalars['Int']>;
  minBasketValue_gte?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isActive_not?: Maybe<Scalars['Boolean']>;
  isFreeShipping?: Maybe<Scalars['Boolean']>;
  isOncePerOrder?: Maybe<Scalars['Boolean']>;
  isFreeShipping_not?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  code_not?: Maybe<Scalars['String']>;
  code_in?: Maybe<Array<Scalars['String']>>;
  code_not_in?: Maybe<Array<Scalars['String']>>;
  code_lt?: Maybe<Scalars['String']>;
  code_lte?: Maybe<Scalars['String']>;
  code_gt?: Maybe<Scalars['String']>;
  code_gte?: Maybe<Scalars['String']>;
  code_contains?: Maybe<Scalars['String']>;
  code_not_contains?: Maybe<Scalars['String']>;
  code_starts_with?: Maybe<Scalars['String']>;
  code_not_starts_with?: Maybe<Scalars['String']>;
  code_ends_with?: Maybe<Scalars['String']>;
  code_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  searchable?: Maybe<Scalars['String']>;
  searchable_not?: Maybe<Scalars['String']>;
  searchable_in?: Maybe<Array<Scalars['String']>>;
  searchable_not_in?: Maybe<Array<Scalars['String']>>;
  searchable_lt?: Maybe<Scalars['String']>;
  searchable_lte?: Maybe<Scalars['String']>;
  searchable_gt?: Maybe<Scalars['String']>;
  searchable_gte?: Maybe<Scalars['String']>;
  searchable_contains?: Maybe<Scalars['String']>;
  searchable_not_contains?: Maybe<Scalars['String']>;
  searchable_starts_with?: Maybe<Scalars['String']>;
  searchable_not_starts_with?: Maybe<Scalars['String']>;
  searchable_ends_with?: Maybe<Scalars['String']>;
  searchable_not_ends_with?: Maybe<Scalars['String']>;
  selectedProducts_every?: Maybe<ProductWhereInput>;
  selectedProducts_some?: Maybe<ProductWhereInput>;
  selectedProducts_none?: Maybe<ProductWhereInput>;
  productTypes_every?: Maybe<ProductTypeWhereInput>;
  productTypes_some?: Maybe<ProductTypeWhereInput>;
  productTypes_none?: Maybe<ProductTypeWhereInput>;
  productCategories_every?: Maybe<ProductCategoryWhereInput>;
  productCategories_some?: Maybe<ProductCategoryWhereInput>;
  productCategories_none?: Maybe<ProductCategoryWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  customers_every?: Maybe<CustomerWhereInput>;
  customers_some?: Maybe<CustomerWhereInput>;
  customers_none?: Maybe<CustomerWhereInput>;
  AND?: Maybe<Array<DiscountWhereInput>>;
  OR?: Maybe<Array<DiscountWhereInput>>;
  NOT?: Maybe<Array<DiscountWhereInput>>;
};

export type DiscountWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type DraftWholesaleOrderCreateInput = {
  merchant: MerchantConnectOneInput;
  preOrder?: Maybe<WholesalePreOrderConnectOneInput>;
};

export type DraftWholesaleOrderDeliveryDetailsUpdateDataInput = {
  deliveryDate?: Maybe<Scalars['DateTime']>;
  costValue?: Maybe<Scalars['Float']>;
};

export type DraftWholesaleOrderDeliveryDetailsUpdateInput = {
  update?: Maybe<DraftWholesaleOrderDeliveryDetailsUpdateDataInput>;
};

export type DraftWholesaleOrderLineItemCreateInput = {
  title?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Float']>;
  catalogItem?: Maybe<CatalogItemWhereUniqueInput>;
};

export type DraftWholesaleOrderLineItemUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type DraftWholesaleOrderLineItemUpdateInput = {
  where: WholesaleOrderLineItemWhereUniqueInput;
  data: DraftWholesaleOrderLineItemUpdateDataInput;
};

export type DraftWholesaleOrderLineItemUpdateManyWithoutOrderInput = {
  create?: Maybe<Array<DraftWholesaleOrderLineItemCreateInput>>;
  update?: Maybe<Array<DraftWholesaleOrderLineItemUpdateInput>>;
  delete?: Maybe<Array<WholesaleOrderLineItemWhereUniqueInput>>;
};

export type DraftWholesaleOrderTaxUpdateDataInput = {
  amount?: Maybe<Scalars['Float']>;
};

export type DraftWholesaleOrderTaxUpdateInput = {
  update?: Maybe<DraftWholesaleOrderTaxUpdateDataInput>;
};

export type DraftWholesaleOrderUpdateInput = {
  items?: Maybe<DraftWholesaleOrderLineItemUpdateManyWithoutOrderInput>;
  deliveryDetails?: Maybe<DraftWholesaleOrderDeliveryDetailsUpdateInput>;
  tax?: Maybe<DraftWholesaleOrderTaxUpdateInput>;
};

export type EstimateMerchantSubscriptionOutput = {
  __typename?: 'EstimateMerchantSubscriptionOutput';
  amountToPay?: Maybe<Scalars['Float']>;
  stripePriceId?: Maybe<Scalars['String']>;
  prorationStartsAt?: Maybe<Scalars['DateTime']>;
  prorationEndsAt?: Maybe<Scalars['DateTime']>;
};

export type ExportOrdersJsonResponse = {
  __typename?: 'ExportOrdersJsonResponse';
  data?: Maybe<Array<Maybe<OrderExport>>>;
};

export type ExportOrdersResponse = {
  __typename?: 'ExportOrdersResponse';
  data: Scalars['String'];
};

export type ExportRemittanceTransferResult = {
  __typename?: 'ExportRemittanceTransferResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type FinalizeCartWhereInput = {
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type FinalizeOrderPaymentArgs = {
  __typename?: 'FinalizeOrderPaymentArgs';
  data: OrderPaymentFinalizeInput;
  where: OrderWhereUniqueInput;
};

export type FloomSnapWhereInput = {
  orderWhere?: Maybe<OrderWhereUniqueInput>;
  mediaWhere?: Maybe<MediaWhereUniqueInput>;
};

export type FloomSubscriptionPlan = {
  __typename?: 'FloomSubscriptionPlan';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type FxErrorDef = {
  __typename?: 'FxErrorDef';
  code?: Maybe<Scalars['Float']>;
  message?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<Scalars['String']>>>;
  context?: Maybe<Scalars['Json']>;
  hint?: Maybe<Scalars['String']>;
};

export type FxOrderResponse = {
  __typename?: 'FxOrderResponse';
  id?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
};

export type GenerateCsvsResponse = {
  __typename?: 'GenerateCsvsResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type GenerateRemittanceTransferStatementForMerchantWhereInput = {
  period: RemittancePeriodWhereUniqueInput;
  merchant: MerchantWhereUniqueInput;
};

export type GenerateStatementsResponse = {
  __typename?: 'GenerateStatementsResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type GenerateTransferCsvsInput = {
  period: RemittancePeriodWhereUniqueInput;
  formats?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Genus = {
  __typename?: 'Genus';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  latinName?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  otherNames: Array<Scalars['String']>;
};

export type GenusConnectOneInput = {
  connect?: Maybe<GenusWhereUniqueInput>;
};

export type GenusConnection = {
  __typename?: 'GenusConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<GenusEdge>>;
  aggregate: AggregateGenus;
};

export type GenusCreateInput = {
  id?: Maybe<Scalars['ID']>;
  latinName?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  otherNames?: Maybe<GenusCreateotherNamesInput>;
};

export type GenusCreateotherNamesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type GenusEdge = {
  __typename?: 'GenusEdge';
  node: Genus;
  cursor: Scalars['String'];
};

export enum GenusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  LatinNameAsc = 'latinName_ASC',
  LatinNameDesc = 'latinName_DESC',
  CommonNameAsc = 'commonName_ASC',
  CommonNameDesc = 'commonName_DESC'
}

export type GenusPreviousValues = {
  __typename?: 'GenusPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  latinName?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  otherNames: Array<Scalars['String']>;
};

export type GenusSubscriptionPayload = {
  __typename?: 'GenusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Genus>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<GenusPreviousValues>;
};

export type GenusSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<GenusWhereInput>;
  AND?: Maybe<Array<GenusSubscriptionWhereInput>>;
  OR?: Maybe<Array<GenusSubscriptionWhereInput>>;
  NOT?: Maybe<Array<GenusSubscriptionWhereInput>>;
};

export type GenusUpdateDataInput = {
  latinName?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  otherNames?: Maybe<GenusUpdateotherNamesInput>;
};

export type GenusUpdateInput = {
  latinName?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  otherNames?: Maybe<GenusUpdateotherNamesInput>;
};

export type GenusUpdateManyMutationInput = {
  latinName?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  otherNames?: Maybe<GenusUpdateotherNamesInput>;
};

export type GenusUpdateOneInput = {
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<GenusWhereUniqueInput>;
};

export type GenusUpdateotherNamesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type GenusWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  latinName?: Maybe<Scalars['String']>;
  latinName_not?: Maybe<Scalars['String']>;
  latinName_in?: Maybe<Array<Scalars['String']>>;
  latinName_not_in?: Maybe<Array<Scalars['String']>>;
  latinName_lt?: Maybe<Scalars['String']>;
  latinName_lte?: Maybe<Scalars['String']>;
  latinName_gt?: Maybe<Scalars['String']>;
  latinName_gte?: Maybe<Scalars['String']>;
  latinName_contains?: Maybe<Scalars['String']>;
  latinName_not_contains?: Maybe<Scalars['String']>;
  latinName_starts_with?: Maybe<Scalars['String']>;
  latinName_not_starts_with?: Maybe<Scalars['String']>;
  latinName_ends_with?: Maybe<Scalars['String']>;
  latinName_not_ends_with?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  commonName_not?: Maybe<Scalars['String']>;
  commonName_in?: Maybe<Array<Scalars['String']>>;
  commonName_not_in?: Maybe<Array<Scalars['String']>>;
  commonName_lt?: Maybe<Scalars['String']>;
  commonName_lte?: Maybe<Scalars['String']>;
  commonName_gt?: Maybe<Scalars['String']>;
  commonName_gte?: Maybe<Scalars['String']>;
  commonName_contains?: Maybe<Scalars['String']>;
  commonName_not_contains?: Maybe<Scalars['String']>;
  commonName_starts_with?: Maybe<Scalars['String']>;
  commonName_not_starts_with?: Maybe<Scalars['String']>;
  commonName_ends_with?: Maybe<Scalars['String']>;
  commonName_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<GenusWhereInput>>;
  OR?: Maybe<Array<GenusWhereInput>>;
  NOT?: Maybe<Array<GenusWhereInput>>;
};

export type GenusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  id: Scalars['ID'];
  title: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  region?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type GeoLocationConnection = {
  __typename?: 'GeoLocationConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<GeoLocationEdge>>;
  aggregate: AggregateGeoLocation;
};

export type GeoLocationCreateInput = {
  id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamCreateOneInput>;
  createdBy?: Maybe<UserCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type GeoLocationCreateOneInput = {
  create?: Maybe<GeoLocationCreateInput>;
  connect?: Maybe<GeoLocationWhereUniqueInput>;
};

export type GeoLocationEdge = {
  __typename?: 'GeoLocationEdge';
  node: GeoLocation;
  cursor: Scalars['String'];
};

export enum GeoLocationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  LatAsc = 'lat_ASC',
  LatDesc = 'lat_DESC',
  LonAsc = 'lon_ASC',
  LonDesc = 'lon_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type GeoLocationPreviousValues = {
  __typename?: 'GeoLocationPreviousValues';
  id: Scalars['ID'];
  title: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  region?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type GeoLocationSubscriptionPayload = {
  __typename?: 'GeoLocationSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<GeoLocation>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<GeoLocationPreviousValues>;
};

export type GeoLocationSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<GeoLocationWhereInput>;
  AND?: Maybe<Array<GeoLocationSubscriptionWhereInput>>;
  OR?: Maybe<Array<GeoLocationSubscriptionWhereInput>>;
  NOT?: Maybe<Array<GeoLocationSubscriptionWhereInput>>;
};

export type GeoLocationUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type GeoLocationUpdateInput = {
  title?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type GeoLocationUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type GeoLocationUpdateOneInput = {
  create?: Maybe<GeoLocationCreateInput>;
  update?: Maybe<GeoLocationUpdateDataInput>;
  upsert?: Maybe<GeoLocationUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<GeoLocationWhereUniqueInput>;
};

export type GeoLocationUpsertNestedInput = {
  update: GeoLocationUpdateDataInput;
  create: GeoLocationCreateInput;
};

export type GeoLocationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lat_not?: Maybe<Scalars['Float']>;
  lat_in?: Maybe<Array<Scalars['Float']>>;
  lat_not_in?: Maybe<Array<Scalars['Float']>>;
  lat_lt?: Maybe<Scalars['Float']>;
  lat_lte?: Maybe<Scalars['Float']>;
  lat_gt?: Maybe<Scalars['Float']>;
  lat_gte?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  lon_not?: Maybe<Scalars['Float']>;
  lon_in?: Maybe<Array<Scalars['Float']>>;
  lon_not_in?: Maybe<Array<Scalars['Float']>>;
  lon_lt?: Maybe<Scalars['Float']>;
  lon_lte?: Maybe<Scalars['Float']>;
  lon_gt?: Maybe<Scalars['Float']>;
  lon_gte?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  region_not?: Maybe<Scalars['String']>;
  region_in?: Maybe<Array<Scalars['String']>>;
  region_not_in?: Maybe<Array<Scalars['String']>>;
  region_lt?: Maybe<Scalars['String']>;
  region_lte?: Maybe<Scalars['String']>;
  region_gt?: Maybe<Scalars['String']>;
  region_gte?: Maybe<Scalars['String']>;
  region_contains?: Maybe<Scalars['String']>;
  region_not_contains?: Maybe<Scalars['String']>;
  region_starts_with?: Maybe<Scalars['String']>;
  region_not_starts_with?: Maybe<Scalars['String']>;
  region_ends_with?: Maybe<Scalars['String']>;
  region_not_ends_with?: Maybe<Scalars['String']>;
  team?: Maybe<TeamWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<GeoLocationWhereInput>>;
  OR?: Maybe<Array<GeoLocationWhereInput>>;
  NOT?: Maybe<Array<GeoLocationWhereInput>>;
};

export type GeoLocationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GetDeliveryDateOptionsForProductInput = {
  productId: Scalars['String'];
  postalCode: Scalars['String'];
  daysToGenerate?: Maybe<Scalars['Int']>;
};

export type GetPaymentIntentForPromotedInput = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
  merchant: MerchantConnectOneInput;
};

export type GetPaymentIntentForPromotedResponse = {
  __typename?: 'GetPaymentIntentForPromotedResponse';
  paymentIntentClientSecret?: Maybe<Scalars['String']>;
};

export type GetPusherTokenResponse = {
  __typename?: 'GetPusherTokenResponse';
  token: Scalars['String'];
};

export type Grower = {
  __typename?: 'Grower';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
};

export type GrowerConnection = {
  __typename?: 'GrowerConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<GrowerEdge>>;
  aggregate: AggregateGrower;
};

export type GrowerCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
};

export type GrowerCreateOneInput = {
  create?: Maybe<GrowerCreateInput>;
  connect?: Maybe<GrowerWhereUniqueInput>;
};

export type GrowerEdge = {
  __typename?: 'GrowerEdge';
  node: Grower;
  cursor: Scalars['String'];
};

export enum GrowerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CountryCodeAsc = 'countryCode_ASC',
  CountryCodeDesc = 'countryCode_DESC'
}

export type GrowerPreviousValues = {
  __typename?: 'GrowerPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
};

export type GrowerSubscriptionPayload = {
  __typename?: 'GrowerSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Grower>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<GrowerPreviousValues>;
};

export type GrowerSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<GrowerWhereInput>;
  AND?: Maybe<Array<GrowerSubscriptionWhereInput>>;
  OR?: Maybe<Array<GrowerSubscriptionWhereInput>>;
  NOT?: Maybe<Array<GrowerSubscriptionWhereInput>>;
};

export type GrowerUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type GrowerUpdateInput = {
  name?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type GrowerUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type GrowerUpdateOneInput = {
  create?: Maybe<GrowerCreateInput>;
  update?: Maybe<GrowerUpdateDataInput>;
  upsert?: Maybe<GrowerUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<GrowerWhereUniqueInput>;
};

export type GrowerUpsertNestedInput = {
  update: GrowerUpdateDataInput;
  create: GrowerCreateInput;
};

export type GrowerWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryCode_not?: Maybe<Scalars['String']>;
  countryCode_in?: Maybe<Array<Scalars['String']>>;
  countryCode_not_in?: Maybe<Array<Scalars['String']>>;
  countryCode_lt?: Maybe<Scalars['String']>;
  countryCode_lte?: Maybe<Scalars['String']>;
  countryCode_gt?: Maybe<Scalars['String']>;
  countryCode_gte?: Maybe<Scalars['String']>;
  countryCode_contains?: Maybe<Scalars['String']>;
  countryCode_not_contains?: Maybe<Scalars['String']>;
  countryCode_starts_with?: Maybe<Scalars['String']>;
  countryCode_not_starts_with?: Maybe<Scalars['String']>;
  countryCode_ends_with?: Maybe<Scalars['String']>;
  countryCode_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<GrowerWhereInput>>;
  OR?: Maybe<Array<GrowerWhereInput>>;
  NOT?: Maybe<Array<GrowerWhereInput>>;
};

export type GrowerWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type HoldPromotedPostcodesInput = {
  postcodes: Array<PromotedPostcodeWhereUniqueInput>;
  merchant: MerchantWhereUniqueInput;
  currency: Scalars['String'];
  holdTimeInSeconds: Scalars['Int'];
};

export type HoldPromotedPostcodesResponse = {
  __typename?: 'HoldPromotedPostcodesResponse';
  paymentIntentClientSecret?: Maybe<Scalars['String']>;
};

export type IndexOrderResponse = {
  __typename?: 'IndexOrderResponse';
  success?: Maybe<Scalars['Boolean']>;
  indexedRecordCount?: Maybe<Scalars['Int']>;
  taskIDs?: Maybe<Array<Maybe<Scalars['String']>>>;
  objectIDs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IntegrationAuth = {
  __typename?: 'IntegrationAuth';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: IntegrationAuthType;
  record: Scalars['Json'];
};

export type IntegrationAuthConnection = {
  __typename?: 'IntegrationAuthConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<IntegrationAuthEdge>>;
  aggregate: AggregateIntegrationAuth;
};

export type IntegrationAuthEdge = {
  __typename?: 'IntegrationAuthEdge';
  node: IntegrationAuth;
  cursor: Scalars['String'];
};

export enum IntegrationAuthOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  RecordAsc = 'record_ASC',
  RecordDesc = 'record_DESC'
}

export type IntegrationAuthPreviousValues = {
  __typename?: 'IntegrationAuthPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: IntegrationAuthType;
  record: Scalars['Json'];
};

export type IntegrationAuthSubscriptionPayload = {
  __typename?: 'IntegrationAuthSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<IntegrationAuth>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<IntegrationAuthPreviousValues>;
};

export type IntegrationAuthSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<IntegrationAuthWhereInput>;
  AND?: Maybe<Array<IntegrationAuthSubscriptionWhereInput>>;
  OR?: Maybe<Array<IntegrationAuthSubscriptionWhereInput>>;
  NOT?: Maybe<Array<IntegrationAuthSubscriptionWhereInput>>;
};

export enum IntegrationAuthType {
  SupplySourceFlorisoft = 'SupplySourceFlorisoft',
  SupplierOrderingFlorisoft = 'SupplierOrderingFlorisoft'
}

export type IntegrationAuthWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<IntegrationAuthType>;
  type_not?: Maybe<IntegrationAuthType>;
  type_in?: Maybe<Array<IntegrationAuthType>>;
  type_not_in?: Maybe<Array<IntegrationAuthType>>;
  AND?: Maybe<Array<IntegrationAuthWhereInput>>;
  OR?: Maybe<Array<IntegrationAuthWhereInput>>;
  NOT?: Maybe<Array<IntegrationAuthWhereInput>>;
};

export type IntegrationAuthWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type InviteToFloomXResponse = {
  __typename?: 'InviteToFloomXResponse';
  status?: Maybe<InviteToFloomXResponseStatus>;
};

export enum InviteToFloomXResponseStatus {
  Sent = 'Sent',
  FailedToSend = 'FailedToSend'
}

export type InviteToFloomXWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  merchant?: Maybe<Merchant>;
};

export type InvoiceConnectOneInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<InvoiceEdge>>;
  aggregate: AggregateInvoice;
};

export type InvoiceCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantCreateOneInput>;
};

export type InvoiceCreateOneInput = {
  create?: Maybe<InvoiceCreateInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateOneWithoutPaymentInput = {
  create?: Maybe<InvoiceCreateWithoutPaymentInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type InvoiceCreateWithoutPaymentInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantCreateOneInput>;
};

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  node: Invoice;
  cursor: Scalars['String'];
};

export enum InvoiceOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type InvoicePreviousValues = {
  __typename?: 'InvoicePreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type InvoiceSubscriptionPayload = {
  __typename?: 'InvoiceSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Invoice>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<InvoicePreviousValues>;
};

export type InvoiceSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<InvoiceWhereInput>;
  AND?: Maybe<Array<InvoiceSubscriptionWhereInput>>;
  OR?: Maybe<Array<InvoiceSubscriptionWhereInput>>;
  NOT?: Maybe<Array<InvoiceSubscriptionWhereInput>>;
};

export type InvoiceWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  externalId_not?: Maybe<Scalars['String']>;
  externalId_in?: Maybe<Array<Scalars['String']>>;
  externalId_not_in?: Maybe<Array<Scalars['String']>>;
  externalId_lt?: Maybe<Scalars['String']>;
  externalId_lte?: Maybe<Scalars['String']>;
  externalId_gt?: Maybe<Scalars['String']>;
  externalId_gte?: Maybe<Scalars['String']>;
  externalId_contains?: Maybe<Scalars['String']>;
  externalId_not_contains?: Maybe<Scalars['String']>;
  externalId_starts_with?: Maybe<Scalars['String']>;
  externalId_not_starts_with?: Maybe<Scalars['String']>;
  externalId_ends_with?: Maybe<Scalars['String']>;
  externalId_not_ends_with?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  AND?: Maybe<Array<InvoiceWhereInput>>;
  OR?: Maybe<Array<InvoiceWhereInput>>;
  NOT?: Maybe<Array<InvoiceWhereInput>>;
};

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ItemForDiscountCalculationDataInput = {
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
};


export type List = {
  __typename?: 'List';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: ListType;
  title?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  suppliers?: Maybe<Array<Supplier>>;
  supplierDeliveryConfigs: Array<Maybe<SupplierDeliveryConfig>>;
  items?: Maybe<Array<ListItemUnion>>;
  merchant?: Maybe<Merchant>;
  createdBy?: Maybe<User>;
};


export type ListSuppliersArgs = {
  where?: Maybe<SupplierWhereInput>;
  orderBy?: Maybe<SupplierOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ListSupplierDeliveryConfigsArgs = {
  where?: Maybe<SupplierDeliveryConfigWhereInput>;
  orderBy?: Maybe<SupplierDeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ListItemsArgs = {
  where?: Maybe<ListItemWhereInput>;
  orderBy?: Maybe<ListItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ListConnection = {
  __typename?: 'ListConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ListEdge>>;
  aggregate: AggregateList;
};

export type ListCreateInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ListType>;
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  suppliers?: Maybe<SupplierConnectManyInput>;
  items?: Maybe<ListItemCreateManyWithoutListInput>;
  merchant: MerchantConnectOneInput;
};

export type ListCreateOneWithoutItemsInput = {
  create?: Maybe<ListCreateWithoutItemsInput>;
  connect?: Maybe<ListWhereUniqueInput>;
};

export type ListCreateWithoutItemsInput = {
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ListType>;
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  suppliers?: Maybe<SupplierConnectManyInput>;
  merchant: MerchantConnectOneInput;
};

export type ListEdge = {
  __typename?: 'ListEdge';
  node: List;
  cursor: Scalars['String'];
};

export type ListItem = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: ListItemType;
  usage?: Maybe<ListItemUsage>;
  catalogItem?: Maybe<CatalogItem>;
  colour?: Maybe<Colour>;
  list?: Maybe<List>;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  availableTradeSkus?: Maybe<Array<Maybe<TradeSku>>>;
  promotedTradeSkuAvailability?: Maybe<TradeSkuAvailability>;
};


export type ListItemPromotedTradeSkuAvailabilityArgs = {
  orderBy?: Maybe<TradeSkuAvailabilityOrderByInput>;
};

export type ListItemConnectOneInput = {
  connect?: Maybe<ListItemWhereUniqueInput>;
};

export type ListItemConnection = {
  __typename?: 'ListItemConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ListItemEdge>>;
  aggregate: AggregateListItem;
};

export type ListItemCreateInput = {
  type: ListItemType;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  colour?: Maybe<ColourCreateOneInput>;
  list: ListCreateOneWithoutItemsInput;
  catalogItem?: Maybe<CatalogItemConnectOneInput>;
  flower?: Maybe<ListItemFlowerCreateOneInput>;
  plant?: Maybe<ListItemPlantCreateOneInput>;
  decoration?: Maybe<ListItemDecorationCreateOneInput>;
  sundry?: Maybe<ListItemSundryCreateOneInput>;
};

export type ListItemCreateManyWithoutListInput = {
  create?: Maybe<Array<ListItemCreateWithoutListInput>>;
};

export type ListItemCreateOneInput = {
  create?: Maybe<ListItemCreateInput>;
  connect?: Maybe<ListItemWhereUniqueInput>;
};

export type ListItemCreateOrConnectManyWithoutListInput = {
  create?: Maybe<Array<ListItemCreateWithoutListInput>>;
  connect?: Maybe<Array<ListItemWhereUniqueInput>>;
};

export type ListItemCreateWithoutListInput = {
  type: ListItemType;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  colour?: Maybe<ColourCreateOneInput>;
  catalogItem?: Maybe<CatalogItemConnectOneInput>;
  flower?: Maybe<ListItemFlowerCreateOneInput>;
  plant?: Maybe<ListItemPlantCreateOneInput>;
  decoration?: Maybe<ListItemDecorationCreateOneInput>;
  sundry?: Maybe<ListItemSundryCreateOneInput>;
};

export type ListItemDecoration = {
  __typename?: 'ListItemDecoration';
  id: Scalars['ID'];
};

export type ListItemDecorationConnection = {
  __typename?: 'ListItemDecorationConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ListItemDecorationEdge>>;
  aggregate: AggregateListItemDecoration;
};

export type ListItemDecorationCreateInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ListItemDecorationCreateOneInput = {
  create?: Maybe<ListItemDecorationCreateInput>;
  connect?: Maybe<ListItemDecorationWhereUniqueInput>;
};

export type ListItemDecorationEdge = {
  __typename?: 'ListItemDecorationEdge';
  node: ListItemDecoration;
  cursor: Scalars['String'];
};

export enum ListItemDecorationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type ListItemDecorationPreviousValues = {
  __typename?: 'ListItemDecorationPreviousValues';
  id: Scalars['ID'];
};

export type ListItemDecorationSubscriptionPayload = {
  __typename?: 'ListItemDecorationSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ListItemDecoration>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ListItemDecorationPreviousValues>;
};

export type ListItemDecorationSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ListItemDecorationWhereInput>;
  AND?: Maybe<Array<ListItemDecorationSubscriptionWhereInput>>;
  OR?: Maybe<Array<ListItemDecorationSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ListItemDecorationSubscriptionWhereInput>>;
};

export type ListItemDecorationUpdateOneInput = {
  create?: Maybe<ListItemDecorationCreateInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ListItemDecorationWhereUniqueInput>;
};

export type ListItemDecorationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  AND?: Maybe<Array<ListItemDecorationWhereInput>>;
  OR?: Maybe<Array<ListItemDecorationWhereInput>>;
  NOT?: Maybe<Array<ListItemDecorationWhereInput>>;
};

export type ListItemDecorationWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ListItemEdge = {
  __typename?: 'ListItemEdge';
  node: ListItem;
  cursor: Scalars['String'];
};

export type ListItemFlower = {
  id: Scalars['ID'];
  headNumber?: Maybe<Scalars['Int']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemFlowerCreateInput = {
  id?: Maybe<Scalars['ID']>;
  headNumber?: Maybe<Scalars['Int']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemFlowerCreateOneInput = {
  create?: Maybe<ListItemFlowerCreateInput>;
  connect?: Maybe<ListItemFlowerWhereUniqueInput>;
};

export type ListItemFlowerEdge = {
  __typename?: 'ListItemFlowerEdge';
  node: ListItemFlower;
  cursor: Scalars['String'];
};

export enum ListItemFlowerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HeadNumberAsc = 'headNumber_ASC',
  HeadNumberDesc = 'headNumber_DESC',
  MinimumHeadSizeAsc = 'minimumHeadSize_ASC',
  MinimumHeadSizeDesc = 'minimumHeadSize_DESC',
  MaximumHeadSizeAsc = 'maximumHeadSize_ASC',
  MaximumHeadSizeDesc = 'maximumHeadSize_DESC',
  MinimumStemLengthAsc = 'minimumStemLength_ASC',
  MinimumStemLengthDesc = 'minimumStemLength_DESC',
  StemLengthUnitAsc = 'stemLengthUnit_ASC',
  StemLengthUnitDesc = 'stemLengthUnit_DESC',
  HeadSizeUnitAsc = 'headSizeUnit_ASC',
  HeadSizeUnitDesc = 'headSizeUnit_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  MaturityAsc = 'maturity_ASC',
  MaturityDesc = 'maturity_DESC',
  PotSizeAsc = 'potSize_ASC',
  PotSizeDesc = 'potSize_DESC'
}

export type ListItemFlowerPreviousValues = {
  __typename?: 'ListItemFlowerPreviousValues';
  id: Scalars['ID'];
  headNumber?: Maybe<Scalars['Int']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemFlowerUpdateDataInput = {
  headNumber?: Maybe<Scalars['Int']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemFlowerUpdateInput = {
  headNumber?: Maybe<Scalars['Int']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemFlowerUpdateManyMutationInput = {
  headNumber?: Maybe<Scalars['Int']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemFlowerUpdateOneInput = {
  create?: Maybe<ListItemFlowerCreateInput>;
  update?: Maybe<ListItemFlowerUpdateDataInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ListItemFlowerWhereUniqueInput>;
};

export type ListItemFlowerUpdateOnlyInput = {
  update?: Maybe<ListItemFlowerUpdateDataInput>;
};

export type ListItemFlowerWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  headNumber?: Maybe<Scalars['Int']>;
  headNumber_not?: Maybe<Scalars['Int']>;
  headNumber_in?: Maybe<Array<Scalars['Int']>>;
  headNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  headNumber_lt?: Maybe<Scalars['Int']>;
  headNumber_lte?: Maybe<Scalars['Int']>;
  headNumber_gt?: Maybe<Scalars['Int']>;
  headNumber_gte?: Maybe<Scalars['Int']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  minimumHeadSize_not?: Maybe<Scalars['Int']>;
  minimumHeadSize_in?: Maybe<Array<Scalars['Int']>>;
  minimumHeadSize_not_in?: Maybe<Array<Scalars['Int']>>;
  minimumHeadSize_lt?: Maybe<Scalars['Int']>;
  minimumHeadSize_lte?: Maybe<Scalars['Int']>;
  minimumHeadSize_gt?: Maybe<Scalars['Int']>;
  minimumHeadSize_gte?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize_not?: Maybe<Scalars['Int']>;
  maximumHeadSize_in?: Maybe<Array<Scalars['Int']>>;
  maximumHeadSize_not_in?: Maybe<Array<Scalars['Int']>>;
  maximumHeadSize_lt?: Maybe<Scalars['Int']>;
  maximumHeadSize_lte?: Maybe<Scalars['Int']>;
  maximumHeadSize_gt?: Maybe<Scalars['Int']>;
  maximumHeadSize_gte?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  minimumStemLength_not?: Maybe<Scalars['Int']>;
  minimumStemLength_in?: Maybe<Array<Scalars['Int']>>;
  minimumStemLength_not_in?: Maybe<Array<Scalars['Int']>>;
  minimumStemLength_lt?: Maybe<Scalars['Int']>;
  minimumStemLength_lte?: Maybe<Scalars['Int']>;
  minimumStemLength_gt?: Maybe<Scalars['Int']>;
  minimumStemLength_gte?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  stemLengthUnit_not?: Maybe<Scalars['String']>;
  stemLengthUnit_in?: Maybe<Array<Scalars['String']>>;
  stemLengthUnit_not_in?: Maybe<Array<Scalars['String']>>;
  stemLengthUnit_lt?: Maybe<Scalars['String']>;
  stemLengthUnit_lte?: Maybe<Scalars['String']>;
  stemLengthUnit_gt?: Maybe<Scalars['String']>;
  stemLengthUnit_gte?: Maybe<Scalars['String']>;
  stemLengthUnit_contains?: Maybe<Scalars['String']>;
  stemLengthUnit_not_contains?: Maybe<Scalars['String']>;
  stemLengthUnit_starts_with?: Maybe<Scalars['String']>;
  stemLengthUnit_not_starts_with?: Maybe<Scalars['String']>;
  stemLengthUnit_ends_with?: Maybe<Scalars['String']>;
  stemLengthUnit_not_ends_with?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  headSizeUnit_not?: Maybe<Scalars['String']>;
  headSizeUnit_in?: Maybe<Array<Scalars['String']>>;
  headSizeUnit_not_in?: Maybe<Array<Scalars['String']>>;
  headSizeUnit_lt?: Maybe<Scalars['String']>;
  headSizeUnit_lte?: Maybe<Scalars['String']>;
  headSizeUnit_gt?: Maybe<Scalars['String']>;
  headSizeUnit_gte?: Maybe<Scalars['String']>;
  headSizeUnit_contains?: Maybe<Scalars['String']>;
  headSizeUnit_not_contains?: Maybe<Scalars['String']>;
  headSizeUnit_starts_with?: Maybe<Scalars['String']>;
  headSizeUnit_not_starts_with?: Maybe<Scalars['String']>;
  headSizeUnit_ends_with?: Maybe<Scalars['String']>;
  headSizeUnit_not_ends_with?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  height_not?: Maybe<Scalars['String']>;
  height_in?: Maybe<Array<Scalars['String']>>;
  height_not_in?: Maybe<Array<Scalars['String']>>;
  height_lt?: Maybe<Scalars['String']>;
  height_lte?: Maybe<Scalars['String']>;
  height_gt?: Maybe<Scalars['String']>;
  height_gte?: Maybe<Scalars['String']>;
  height_contains?: Maybe<Scalars['String']>;
  height_not_contains?: Maybe<Scalars['String']>;
  height_starts_with?: Maybe<Scalars['String']>;
  height_not_starts_with?: Maybe<Scalars['String']>;
  height_ends_with?: Maybe<Scalars['String']>;
  height_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  weight_not?: Maybe<Scalars['String']>;
  weight_in?: Maybe<Array<Scalars['String']>>;
  weight_not_in?: Maybe<Array<Scalars['String']>>;
  weight_lt?: Maybe<Scalars['String']>;
  weight_lte?: Maybe<Scalars['String']>;
  weight_gt?: Maybe<Scalars['String']>;
  weight_gte?: Maybe<Scalars['String']>;
  weight_contains?: Maybe<Scalars['String']>;
  weight_not_contains?: Maybe<Scalars['String']>;
  weight_starts_with?: Maybe<Scalars['String']>;
  weight_not_starts_with?: Maybe<Scalars['String']>;
  weight_ends_with?: Maybe<Scalars['String']>;
  weight_not_ends_with?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  maturity_not?: Maybe<Scalars['String']>;
  maturity_in?: Maybe<Array<Scalars['String']>>;
  maturity_not_in?: Maybe<Array<Scalars['String']>>;
  maturity_lt?: Maybe<Scalars['String']>;
  maturity_lte?: Maybe<Scalars['String']>;
  maturity_gt?: Maybe<Scalars['String']>;
  maturity_gte?: Maybe<Scalars['String']>;
  maturity_contains?: Maybe<Scalars['String']>;
  maturity_not_contains?: Maybe<Scalars['String']>;
  maturity_starts_with?: Maybe<Scalars['String']>;
  maturity_not_starts_with?: Maybe<Scalars['String']>;
  maturity_ends_with?: Maybe<Scalars['String']>;
  maturity_not_ends_with?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
  potSize_not?: Maybe<Scalars['String']>;
  potSize_in?: Maybe<Array<Scalars['String']>>;
  potSize_not_in?: Maybe<Array<Scalars['String']>>;
  potSize_lt?: Maybe<Scalars['String']>;
  potSize_lte?: Maybe<Scalars['String']>;
  potSize_gt?: Maybe<Scalars['String']>;
  potSize_gte?: Maybe<Scalars['String']>;
  potSize_contains?: Maybe<Scalars['String']>;
  potSize_not_contains?: Maybe<Scalars['String']>;
  potSize_starts_with?: Maybe<Scalars['String']>;
  potSize_not_starts_with?: Maybe<Scalars['String']>;
  potSize_ends_with?: Maybe<Scalars['String']>;
  potSize_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<ListItemFlowerWhereInput>>;
  OR?: Maybe<Array<ListItemFlowerWhereInput>>;
  NOT?: Maybe<Array<ListItemFlowerWhereInput>>;
};

export type ListItemFlowerWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum ListItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UsageAsc = 'usage_ASC',
  UsageDesc = 'usage_DESC',
  SkuAsc = 'sku_ASC',
  SkuDesc = 'sku_DESC',
  QuantityAsc = 'quantity_ASC',
  QuantityDesc = 'quantity_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  MaxUnitPriceAsc = 'maxUnitPrice_ASC',
  MaxUnitPriceDesc = 'maxUnitPrice_DESC',
  PotSizeAsc = 'potSize_ASC',
  PotSizeDesc = 'potSize_DESC'
}

export type ListItemPlant = {
  id: Scalars['ID'];
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemPlantConnection = {
  __typename?: 'ListItemPlantConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ListItemPlantEdge>>;
  aggregate: AggregateListItemPlant;
};

export type ListItemPlantCreateInput = {
  id?: Maybe<Scalars['ID']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemPlantCreateOneInput = {
  create?: Maybe<ListItemPlantCreateInput>;
  connect?: Maybe<ListItemPlantWhereUniqueInput>;
};

export type ListItemPlantEdge = {
  __typename?: 'ListItemPlantEdge';
  node: ListItemPlant;
  cursor: Scalars['String'];
};

export enum ListItemPlantOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MinimumHeadSizeAsc = 'minimumHeadSize_ASC',
  MinimumHeadSizeDesc = 'minimumHeadSize_DESC',
  MaximumHeadSizeAsc = 'maximumHeadSize_ASC',
  MaximumHeadSizeDesc = 'maximumHeadSize_DESC',
  HeadSizeUnitAsc = 'headSizeUnit_ASC',
  HeadSizeUnitDesc = 'headSizeUnit_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  MaturityAsc = 'maturity_ASC',
  MaturityDesc = 'maturity_DESC',
  DiameterAsc = 'diameter_ASC',
  DiameterDesc = 'diameter_DESC',
  ThicknessAsc = 'thickness_ASC',
  ThicknessDesc = 'thickness_DESC',
  PotSizeAsc = 'potSize_ASC',
  PotSizeDesc = 'potSize_DESC'
}

export type ListItemPlantPreviousValues = {
  __typename?: 'ListItemPlantPreviousValues';
  id: Scalars['ID'];
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemPlantSubscriptionPayload = {
  __typename?: 'ListItemPlantSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ListItemPlant>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ListItemPlantPreviousValues>;
};

export type ListItemPlantSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ListItemPlantWhereInput>;
  AND?: Maybe<Array<ListItemPlantSubscriptionWhereInput>>;
  OR?: Maybe<Array<ListItemPlantSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ListItemPlantSubscriptionWhereInput>>;
};

export type ListItemPlantUpdateDataInput = {
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemPlantUpdateInput = {
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemPlantUpdateManyMutationInput = {
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemPlantUpdateOneInput = {
  create?: Maybe<ListItemPlantCreateInput>;
  update?: Maybe<ListItemPlantUpdateDataInput>;
  upsert?: Maybe<ListItemPlantUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ListItemPlantWhereUniqueInput>;
};

export type ListItemPlantUpdateOnlyInput = {
  update?: Maybe<ListItemPlantUpdateDataInput>;
};

export type ListItemPlantUpsertNestedInput = {
  update: ListItemPlantUpdateDataInput;
  create: ListItemPlantCreateInput;
};

export type ListItemPlantWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  minimumHeadSize_not?: Maybe<Scalars['Int']>;
  minimumHeadSize_in?: Maybe<Array<Scalars['Int']>>;
  minimumHeadSize_not_in?: Maybe<Array<Scalars['Int']>>;
  minimumHeadSize_lt?: Maybe<Scalars['Int']>;
  minimumHeadSize_lte?: Maybe<Scalars['Int']>;
  minimumHeadSize_gt?: Maybe<Scalars['Int']>;
  minimumHeadSize_gte?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize_not?: Maybe<Scalars['Int']>;
  maximumHeadSize_in?: Maybe<Array<Scalars['Int']>>;
  maximumHeadSize_not_in?: Maybe<Array<Scalars['Int']>>;
  maximumHeadSize_lt?: Maybe<Scalars['Int']>;
  maximumHeadSize_lte?: Maybe<Scalars['Int']>;
  maximumHeadSize_gt?: Maybe<Scalars['Int']>;
  maximumHeadSize_gte?: Maybe<Scalars['Int']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  headSizeUnit_not?: Maybe<Scalars['String']>;
  headSizeUnit_in?: Maybe<Array<Scalars['String']>>;
  headSizeUnit_not_in?: Maybe<Array<Scalars['String']>>;
  headSizeUnit_lt?: Maybe<Scalars['String']>;
  headSizeUnit_lte?: Maybe<Scalars['String']>;
  headSizeUnit_gt?: Maybe<Scalars['String']>;
  headSizeUnit_gte?: Maybe<Scalars['String']>;
  headSizeUnit_contains?: Maybe<Scalars['String']>;
  headSizeUnit_not_contains?: Maybe<Scalars['String']>;
  headSizeUnit_starts_with?: Maybe<Scalars['String']>;
  headSizeUnit_not_starts_with?: Maybe<Scalars['String']>;
  headSizeUnit_ends_with?: Maybe<Scalars['String']>;
  headSizeUnit_not_ends_with?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  height_not?: Maybe<Scalars['String']>;
  height_in?: Maybe<Array<Scalars['String']>>;
  height_not_in?: Maybe<Array<Scalars['String']>>;
  height_lt?: Maybe<Scalars['String']>;
  height_lte?: Maybe<Scalars['String']>;
  height_gt?: Maybe<Scalars['String']>;
  height_gte?: Maybe<Scalars['String']>;
  height_contains?: Maybe<Scalars['String']>;
  height_not_contains?: Maybe<Scalars['String']>;
  height_starts_with?: Maybe<Scalars['String']>;
  height_not_starts_with?: Maybe<Scalars['String']>;
  height_ends_with?: Maybe<Scalars['String']>;
  height_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  weight_not?: Maybe<Scalars['String']>;
  weight_in?: Maybe<Array<Scalars['String']>>;
  weight_not_in?: Maybe<Array<Scalars['String']>>;
  weight_lt?: Maybe<Scalars['String']>;
  weight_lte?: Maybe<Scalars['String']>;
  weight_gt?: Maybe<Scalars['String']>;
  weight_gte?: Maybe<Scalars['String']>;
  weight_contains?: Maybe<Scalars['String']>;
  weight_not_contains?: Maybe<Scalars['String']>;
  weight_starts_with?: Maybe<Scalars['String']>;
  weight_not_starts_with?: Maybe<Scalars['String']>;
  weight_ends_with?: Maybe<Scalars['String']>;
  weight_not_ends_with?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  maturity_not?: Maybe<Scalars['String']>;
  maturity_in?: Maybe<Array<Scalars['String']>>;
  maturity_not_in?: Maybe<Array<Scalars['String']>>;
  maturity_lt?: Maybe<Scalars['String']>;
  maturity_lte?: Maybe<Scalars['String']>;
  maturity_gt?: Maybe<Scalars['String']>;
  maturity_gte?: Maybe<Scalars['String']>;
  maturity_contains?: Maybe<Scalars['String']>;
  maturity_not_contains?: Maybe<Scalars['String']>;
  maturity_starts_with?: Maybe<Scalars['String']>;
  maturity_not_starts_with?: Maybe<Scalars['String']>;
  maturity_ends_with?: Maybe<Scalars['String']>;
  maturity_not_ends_with?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  diameter_not?: Maybe<Scalars['String']>;
  diameter_in?: Maybe<Array<Scalars['String']>>;
  diameter_not_in?: Maybe<Array<Scalars['String']>>;
  diameter_lt?: Maybe<Scalars['String']>;
  diameter_lte?: Maybe<Scalars['String']>;
  diameter_gt?: Maybe<Scalars['String']>;
  diameter_gte?: Maybe<Scalars['String']>;
  diameter_contains?: Maybe<Scalars['String']>;
  diameter_not_contains?: Maybe<Scalars['String']>;
  diameter_starts_with?: Maybe<Scalars['String']>;
  diameter_not_starts_with?: Maybe<Scalars['String']>;
  diameter_ends_with?: Maybe<Scalars['String']>;
  diameter_not_ends_with?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  thickness_not?: Maybe<Scalars['String']>;
  thickness_in?: Maybe<Array<Scalars['String']>>;
  thickness_not_in?: Maybe<Array<Scalars['String']>>;
  thickness_lt?: Maybe<Scalars['String']>;
  thickness_lte?: Maybe<Scalars['String']>;
  thickness_gt?: Maybe<Scalars['String']>;
  thickness_gte?: Maybe<Scalars['String']>;
  thickness_contains?: Maybe<Scalars['String']>;
  thickness_not_contains?: Maybe<Scalars['String']>;
  thickness_starts_with?: Maybe<Scalars['String']>;
  thickness_not_starts_with?: Maybe<Scalars['String']>;
  thickness_ends_with?: Maybe<Scalars['String']>;
  thickness_not_ends_with?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
  potSize_not?: Maybe<Scalars['String']>;
  potSize_in?: Maybe<Array<Scalars['String']>>;
  potSize_not_in?: Maybe<Array<Scalars['String']>>;
  potSize_lt?: Maybe<Scalars['String']>;
  potSize_lte?: Maybe<Scalars['String']>;
  potSize_gt?: Maybe<Scalars['String']>;
  potSize_gte?: Maybe<Scalars['String']>;
  potSize_contains?: Maybe<Scalars['String']>;
  potSize_not_contains?: Maybe<Scalars['String']>;
  potSize_starts_with?: Maybe<Scalars['String']>;
  potSize_not_starts_with?: Maybe<Scalars['String']>;
  potSize_ends_with?: Maybe<Scalars['String']>;
  potSize_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<ListItemPlantWhereInput>>;
  OR?: Maybe<Array<ListItemPlantWhereInput>>;
  NOT?: Maybe<Array<ListItemPlantWhereInput>>;
};

export type ListItemPlantWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ListItemPreviousValues = {
  __typename?: 'ListItemPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: ListItemType;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  species?: Maybe<Scalars['String']>;
  grower?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  maximumStemLength?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type ListItemScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ListItemType>;
  type_not?: Maybe<ListItemType>;
  type_in?: Maybe<Array<ListItemType>>;
  type_not_in?: Maybe<Array<ListItemType>>;
  sku?: Maybe<Scalars['String']>;
  sku_not?: Maybe<Scalars['String']>;
  sku_in?: Maybe<Array<Scalars['String']>>;
  sku_not_in?: Maybe<Array<Scalars['String']>>;
  sku_lt?: Maybe<Scalars['String']>;
  sku_lte?: Maybe<Scalars['String']>;
  sku_gt?: Maybe<Scalars['String']>;
  sku_gte?: Maybe<Scalars['String']>;
  sku_contains?: Maybe<Scalars['String']>;
  sku_not_contains?: Maybe<Scalars['String']>;
  sku_starts_with?: Maybe<Scalars['String']>;
  sku_not_starts_with?: Maybe<Scalars['String']>;
  sku_ends_with?: Maybe<Scalars['String']>;
  sku_not_ends_with?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  quantity_not?: Maybe<Scalars['Int']>;
  quantity_in?: Maybe<Array<Scalars['Int']>>;
  quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  quantity_lt?: Maybe<Scalars['Int']>;
  quantity_lte?: Maybe<Scalars['Int']>;
  quantity_gt?: Maybe<Scalars['Int']>;
  quantity_gte?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  position_not?: Maybe<Scalars['Int']>;
  position_in?: Maybe<Array<Scalars['Int']>>;
  position_not_in?: Maybe<Array<Scalars['Int']>>;
  position_lt?: Maybe<Scalars['Int']>;
  position_lte?: Maybe<Scalars['Int']>;
  position_gt?: Maybe<Scalars['Int']>;
  position_gte?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  maxUnitPrice_not?: Maybe<Scalars['Float']>;
  maxUnitPrice_in?: Maybe<Array<Scalars['Float']>>;
  maxUnitPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  maxUnitPrice_lt?: Maybe<Scalars['Float']>;
  maxUnitPrice_lte?: Maybe<Scalars['Float']>;
  maxUnitPrice_gt?: Maybe<Scalars['Float']>;
  maxUnitPrice_gte?: Maybe<Scalars['Float']>;
  AND?: Maybe<Array<ListItemScalarWhereInput>>;
  OR?: Maybe<Array<ListItemScalarWhereInput>>;
  NOT?: Maybe<Array<ListItemScalarWhereInput>>;
};

export type ListItemSundry = {
  id: Scalars['ID'];
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type ListItemSundryConnection = {
  __typename?: 'ListItemSundryConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ListItemSundryEdge>>;
  aggregate: AggregateListItemSundry;
};

export type ListItemSundryCreateInput = {
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type ListItemSundryCreateOneInput = {
  create?: Maybe<ListItemSundryCreateInput>;
  connect?: Maybe<ListItemSundryWhereUniqueInput>;
};

export type ListItemSundryEdge = {
  __typename?: 'ListItemSundryEdge';
  node: ListItemSundry;
  cursor: Scalars['String'];
};

export enum ListItemSundryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC'
}

export type ListItemSundryPreviousValues = {
  __typename?: 'ListItemSundryPreviousValues';
  id: Scalars['ID'];
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type ListItemSundryUpdateDataInput = {
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type ListItemSundryUpdateInput = {
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type ListItemSundryUpdateManyMutationInput = {
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type ListItemSundryUpdateOneInput = {
  create?: Maybe<ListItemSundryCreateInput>;
  update?: Maybe<ListItemSundryUpdateDataInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ListItemSundryWhereUniqueInput>;
};

export type ListItemSundryUpdateOnlyInput = {
  update?: Maybe<ListItemSundryUpdateDataInput>;
};

export type ListItemSundryWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  height?: Maybe<Scalars['String']>;
  height_not?: Maybe<Scalars['String']>;
  height_in?: Maybe<Array<Scalars['String']>>;
  height_not_in?: Maybe<Array<Scalars['String']>>;
  height_lt?: Maybe<Scalars['String']>;
  height_lte?: Maybe<Scalars['String']>;
  height_gt?: Maybe<Scalars['String']>;
  height_gte?: Maybe<Scalars['String']>;
  height_contains?: Maybe<Scalars['String']>;
  height_not_contains?: Maybe<Scalars['String']>;
  height_starts_with?: Maybe<Scalars['String']>;
  height_not_starts_with?: Maybe<Scalars['String']>;
  height_ends_with?: Maybe<Scalars['String']>;
  height_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  weight_not?: Maybe<Scalars['String']>;
  weight_in?: Maybe<Array<Scalars['String']>>;
  weight_not_in?: Maybe<Array<Scalars['String']>>;
  weight_lt?: Maybe<Scalars['String']>;
  weight_lte?: Maybe<Scalars['String']>;
  weight_gt?: Maybe<Scalars['String']>;
  weight_gte?: Maybe<Scalars['String']>;
  weight_contains?: Maybe<Scalars['String']>;
  weight_not_contains?: Maybe<Scalars['String']>;
  weight_starts_with?: Maybe<Scalars['String']>;
  weight_not_starts_with?: Maybe<Scalars['String']>;
  weight_ends_with?: Maybe<Scalars['String']>;
  weight_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<ListItemSundryWhereInput>>;
  OR?: Maybe<Array<ListItemSundryWhereInput>>;
  NOT?: Maybe<Array<ListItemSundryWhereInput>>;
};

export type ListItemSundryWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum ListItemType {
  Flower = 'Flower',
  Sundry = 'Sundry',
  Plant = 'Plant',
  Decoration = 'Decoration',
  Custom = 'Custom'
}

/**
 * ListItemTypeCustom extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Custom.
 */
export type ListItemTypeCustom = ListItem & {
  __typename?: 'ListItemTypeCustom';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: ListItemType;
  usage?: Maybe<ListItemUsage>;
  catalogItem?: Maybe<CatalogItem>;
  colour?: Maybe<Colour>;
  list?: Maybe<List>;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  availableTradeSkus?: Maybe<Array<Maybe<TradeSku>>>;
  promotedTradeSkuAvailability?: Maybe<TradeSkuAvailability>;
};


/**
 * ListItemTypeCustom extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Custom.
 */
export type ListItemTypeCustomPromotedTradeSkuAvailabilityArgs = {
  orderBy?: Maybe<TradeSkuAvailabilityOrderByInput>;
};

/**
 * ListItemTypeDecoration extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Decoration.
 */
export type ListItemTypeDecoration = ListItem & {
  __typename?: 'ListItemTypeDecoration';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: ListItemType;
  usage?: Maybe<ListItemUsage>;
  catalogItem?: Maybe<CatalogItem>;
  colour?: Maybe<Colour>;
  list?: Maybe<List>;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  availableTradeSkus?: Maybe<Array<Maybe<TradeSku>>>;
  promotedTradeSkuAvailability?: Maybe<TradeSkuAvailability>;
};


/**
 * ListItemTypeDecoration extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Decoration.
 */
export type ListItemTypeDecorationPromotedTradeSkuAvailabilityArgs = {
  orderBy?: Maybe<TradeSkuAvailabilityOrderByInput>;
};

/**
 * ListItemTypeFlower extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Flower.
 */
export type ListItemTypeFlower = ListItem & ListItemFlower & {
  __typename?: 'ListItemTypeFlower';
  headNumber?: Maybe<Scalars['Int']>;
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: ListItemType;
  usage?: Maybe<ListItemUsage>;
  catalogItem?: Maybe<CatalogItem>;
  colour?: Maybe<Colour>;
  list?: Maybe<List>;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  availableTradeSkus?: Maybe<Array<Maybe<TradeSku>>>;
  promotedTradeSkuAvailability?: Maybe<TradeSkuAvailability>;
};


/**
 * ListItemTypeFlower extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Flower.
 */
export type ListItemTypeFlowerPromotedTradeSkuAvailabilityArgs = {
  orderBy?: Maybe<TradeSkuAvailabilityOrderByInput>;
};

/**
 * ListItemTypeDecoration extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Plant.
 */
export type ListItemTypePlant = ListItem & ListItemPlant & {
  __typename?: 'ListItemTypePlant';
  minimumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: ListItemType;
  usage?: Maybe<ListItemUsage>;
  catalogItem?: Maybe<CatalogItem>;
  colour?: Maybe<Colour>;
  list?: Maybe<List>;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  availableTradeSkus?: Maybe<Array<Maybe<TradeSku>>>;
  promotedTradeSkuAvailability?: Maybe<TradeSkuAvailability>;
};


/**
 * ListItemTypeDecoration extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Plant.
 */
export type ListItemTypePlantPromotedTradeSkuAvailabilityArgs = {
  orderBy?: Maybe<TradeSkuAvailabilityOrderByInput>;
};

/**
 * ListItemTypeSundry extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Sundry.
 */
export type ListItemTypeSundry = ListItem & ListItemSundry & {
  __typename?: 'ListItemTypeSundry';
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: ListItemType;
  usage?: Maybe<ListItemUsage>;
  catalogItem?: Maybe<CatalogItem>;
  colour?: Maybe<Colour>;
  list?: Maybe<List>;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  availableTradeSkus?: Maybe<Array<Maybe<TradeSku>>>;
  promotedTradeSkuAvailability?: Maybe<TradeSkuAvailability>;
};


/**
 * ListItemTypeSundry extends base ListItem model. It has properties that are
 * only valid and accessible for a ListItem with a type of Sundry.
 */
export type ListItemTypeSundryPromotedTradeSkuAvailabilityArgs = {
  orderBy?: Maybe<TradeSkuAvailabilityOrderByInput>;
};

export type ListItemUnion = ListItemTypeFlower | ListItemTypeSundry | ListItemTypeCustom | ListItemTypeDecoration | ListItemTypePlant;

export type ListItemUpdateDataForPreOrderInput = {
  quantity?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  flower?: Maybe<ListItemFlowerUpdateOneInput>;
  plant?: Maybe<ListItemPlantUpdateOneInput>;
  decoration?: Maybe<ListItemDecorationUpdateOneInput>;
  sundry?: Maybe<ListItemSundryUpdateOneInput>;
};

export type ListItemUpdateDataInput = {
  type?: Maybe<ListItemType>;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  colour?: Maybe<ColourUpdateOneInput>;
  catalogItem?: Maybe<CatalogItemUpdateOneInput>;
  list?: Maybe<ListUpdateOneWithoutItemsInput>;
  flower?: Maybe<ListItemFlowerUpdateOneInput>;
  plant?: Maybe<ListItemPlantUpdateOneInput>;
  decoration?: Maybe<ListItemDecorationUpdateOneInput>;
  sundry?: Maybe<ListItemSundryUpdateOneInput>;
};

export type ListItemUpdateForPreOrderInput = {
  update?: Maybe<ListItemUpdateDataForPreOrderInput>;
};

export type ListItemUpdateInput = {
  type?: Maybe<ListItemType>;
  catalogItem?: Maybe<CatalogItemUpdateOneInput>;
  list?: Maybe<ListUpdateOneRequiredWithoutItemsInput>;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  colour?: Maybe<ColourUpdateOneInput>;
  flower?: Maybe<ListItemFlowerUpdateOnlyInput>;
  plant?: Maybe<ListItemPlantUpdateOnlyInput>;
  decoration?: Maybe<ListItemDecorationUpdateOneInput>;
  sundry?: Maybe<ListItemSundryUpdateOnlyInput>;
};

export type ListItemUpdateManyDataInput = {
  type?: Maybe<ListItemType>;
};

export type ListItemUpdateManyMutationInput = {
  type?: Maybe<ListItemType>;
};

export type ListItemUpdateManyWithoutListInput = {
  create?: Maybe<Array<ListItemCreateWithoutListInput>>;
  update?: Maybe<Array<ListItemUpdateWithWhereUniqueWithoutListInput>>;
  disconnect?: Maybe<Array<ListItemWhereUniqueInput>>;
};

export type ListItemUpdateOneRequiredInput = {
  create?: Maybe<ListItemCreateInput>;
  update?: Maybe<ListItemUpdateDataInput>;
};

export type ListItemUpdateWithWhereUniqueWithoutListInput = {
  where: ListItemWhereUniqueInput;
  data: ListItemUpdateWithoutListDataInput;
};

export type ListItemUpdateWithoutListDataInput = {
  type?: Maybe<ListItemType>;
  sku?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  maxUnitPrice?: Maybe<Scalars['Float']>;
  colour?: Maybe<ColourUpdateOneInput>;
  catalogItem?: Maybe<CatalogItemConnectOneInput>;
  flower?: Maybe<ListItemFlowerUpdateOnlyInput>;
  plant?: Maybe<ListItemPlantUpdateOnlyInput>;
  decoration?: Maybe<ListItemDecorationUpdateOneInput>;
  sundry?: Maybe<ListItemSundryUpdateOnlyInput>;
};

export enum ListItemUsage {
  List = 'List',
  PreOrder = 'PreOrder',
  WholesaleOrder = 'WholesaleOrder'
}

export type ListItemWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ListItemType>;
  type_not?: Maybe<ListItemType>;
  type_in?: Maybe<Array<ListItemType>>;
  type_not_in?: Maybe<Array<ListItemType>>;
  usage?: Maybe<ListItemUsage>;
  usage_not?: Maybe<ListItemUsage>;
  usage_in?: Maybe<Array<ListItemUsage>>;
  usage_not_in?: Maybe<Array<ListItemUsage>>;
  sku?: Maybe<Scalars['String']>;
  sku_not?: Maybe<Scalars['String']>;
  sku_in?: Maybe<Array<Scalars['String']>>;
  sku_not_in?: Maybe<Array<Scalars['String']>>;
  sku_lt?: Maybe<Scalars['String']>;
  sku_lte?: Maybe<Scalars['String']>;
  sku_gt?: Maybe<Scalars['String']>;
  sku_gte?: Maybe<Scalars['String']>;
  sku_contains?: Maybe<Scalars['String']>;
  sku_not_contains?: Maybe<Scalars['String']>;
  sku_starts_with?: Maybe<Scalars['String']>;
  sku_not_starts_with?: Maybe<Scalars['String']>;
  sku_ends_with?: Maybe<Scalars['String']>;
  sku_not_ends_with?: Maybe<Scalars['String']>;
  skuSource?: Maybe<Scalars['String']>;
  skuSource_not?: Maybe<Scalars['String']>;
  skuSource_in?: Maybe<Array<Scalars['String']>>;
  skuSource_not_in?: Maybe<Array<Scalars['String']>>;
  skuSource_lt?: Maybe<Scalars['String']>;
  skuSource_lte?: Maybe<Scalars['String']>;
  skuSource_gt?: Maybe<Scalars['String']>;
  skuSource_gte?: Maybe<Scalars['String']>;
  skuSource_contains?: Maybe<Scalars['String']>;
  skuSource_not_contains?: Maybe<Scalars['String']>;
  skuSource_starts_with?: Maybe<Scalars['String']>;
  skuSource_not_starts_with?: Maybe<Scalars['String']>;
  skuSource_ends_with?: Maybe<Scalars['String']>;
  skuSource_not_ends_with?: Maybe<Scalars['String']>;
  vbn?: Maybe<Scalars['String']>;
  vbn_not?: Maybe<Scalars['String']>;
  vbn_in?: Maybe<Array<Scalars['String']>>;
  vbn_not_in?: Maybe<Array<Scalars['String']>>;
  vbn_lt?: Maybe<Scalars['String']>;
  vbn_lte?: Maybe<Scalars['String']>;
  vbn_gt?: Maybe<Scalars['String']>;
  vbn_gte?: Maybe<Scalars['String']>;
  vbn_contains?: Maybe<Scalars['String']>;
  vbn_not_contains?: Maybe<Scalars['String']>;
  vbn_starts_with?: Maybe<Scalars['String']>;
  vbn_not_starts_with?: Maybe<Scalars['String']>;
  vbn_ends_with?: Maybe<Scalars['String']>;
  vbn_not_ends_with?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  quantity_not?: Maybe<Scalars['Int']>;
  quantity_in?: Maybe<Array<Scalars['Int']>>;
  quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  quantity_lt?: Maybe<Scalars['Int']>;
  quantity_lte?: Maybe<Scalars['Int']>;
  quantity_gt?: Maybe<Scalars['Int']>;
  quantity_gte?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  position_not?: Maybe<Scalars['Int']>;
  position_in?: Maybe<Array<Scalars['Int']>>;
  position_not_in?: Maybe<Array<Scalars['Int']>>;
  position_lt?: Maybe<Scalars['Int']>;
  position_lte?: Maybe<Scalars['Int']>;
  position_gt?: Maybe<Scalars['Int']>;
  position_gte?: Maybe<Scalars['Int']>;
  colour?: Maybe<ColourWhereInput>;
  catalogItem?: Maybe<CatalogItemWhereInput>;
  list?: Maybe<ListWhereInput>;
  flower?: Maybe<ListItemFlowerWhereInput>;
  plant?: Maybe<ListItemPlantWhereInput>;
  sundry?: Maybe<ListItemSundryWhereInput>;
  AND?: Maybe<Array<ListItemWhereInput>>;
  OR?: Maybe<Array<ListItemWhereInput>>;
  NOT?: Maybe<Array<ListItemWhereInput>>;
};

export type ListItemWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ListOfCourierDeliverySlotsOutput = {
  __typename?: 'ListOfCourierDeliverySlotsOutput';
  valid?: Maybe<Array<Maybe<AvailableCourierDeliveryTimeSlot>>>;
  invalid?: Maybe<Array<Maybe<AvailableCourierDeliveryTimeSlot>>>;
};

export enum ListOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC'
}

export type ListPreviousValues = {
  __typename?: 'ListPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: ListType;
  title?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export enum ListType {
  Simple = 'Simple',
  Advanced = 'Advanced',
  Promoted = 'Promoted'
}

export type ListUpdateInput = {
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  suppliers?: Maybe<SupplierSetManyInput>;
  items?: Maybe<ListItemUpdateManyWithoutListInput>;
};

export type ListUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type ListUpdateOneRequiredWithoutItemsInput = {
  create?: Maybe<ListCreateWithoutItemsInput>;
  update?: Maybe<ListUpdateWithoutItemsDataInput>;
  upsert?: Maybe<ListUpsertWithoutItemsInput>;
  connect?: Maybe<ListWhereUniqueInput>;
};

export type ListUpdateOneWithoutItemsInput = {
  create?: Maybe<ListCreateWithoutItemsInput>;
  update?: Maybe<ListUpdateWithoutItemsDataInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ListWhereUniqueInput>;
};

export type ListUpdateWithoutItemsDataInput = {
  title?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type ListUpsertWithoutItemsInput = {
  update: ListUpdateWithoutItemsDataInput;
  create: ListCreateWithoutItemsInput;
};

export type ListWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ListType>;
  type_not?: Maybe<ListType>;
  type_in?: Maybe<Array<ListType>>;
  type_not_in?: Maybe<Array<ListType>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  date_not?: Maybe<Scalars['String']>;
  date_in?: Maybe<Array<Scalars['String']>>;
  date_not_in?: Maybe<Array<Scalars['String']>>;
  date_lt?: Maybe<Scalars['String']>;
  date_lte?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['String']>;
  date_gte?: Maybe<Scalars['String']>;
  date_contains?: Maybe<Scalars['String']>;
  date_not_contains?: Maybe<Scalars['String']>;
  date_starts_with?: Maybe<Scalars['String']>;
  date_not_starts_with?: Maybe<Scalars['String']>;
  date_ends_with?: Maybe<Scalars['String']>;
  date_not_ends_with?: Maybe<Scalars['String']>;
  suppliers_every?: Maybe<SupplierWhereInput>;
  suppliers_some?: Maybe<SupplierWhereInput>;
  suppliers_none?: Maybe<SupplierWhereInput>;
  items_every?: Maybe<ListItemWhereInput>;
  items_some?: Maybe<ListItemWhereInput>;
  items_none?: Maybe<ListItemWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<ListWhereInput>>;
  OR?: Maybe<Array<ListWhereInput>>;
  NOT?: Maybe<Array<ListWhereInput>>;
};

export type ListWhereUniqueInput = {
  id: Scalars['ID'];
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export enum Locale {
  EnGb = 'EN_GB',
  EnUs = 'EN_US'
}

export type LondonOutcodeToTflZone = {
  __typename?: 'LondonOutcodeToTFLZone';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  outCode: Scalars['String'];
  zone: Scalars['Int'];
};

export type LondonOutcodeToTflZoneConnection = {
  __typename?: 'LondonOutcodeToTFLZoneConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<LondonOutcodeToTflZoneEdge>>;
  aggregate: AggregateLondonOutcodeToTflZone;
};

export type LondonOutcodeToTflZoneCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  outCode: Scalars['String'];
  zone: Scalars['Int'];
};

export type LondonOutcodeToTflZoneEdge = {
  __typename?: 'LondonOutcodeToTFLZoneEdge';
  node: LondonOutcodeToTflZone;
  cursor: Scalars['String'];
};

export enum LondonOutcodeToTflZoneOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  OutCodeAsc = 'outCode_ASC',
  OutCodeDesc = 'outCode_DESC',
  ZoneAsc = 'zone_ASC',
  ZoneDesc = 'zone_DESC'
}

export type LondonOutcodeToTflZonePreviousValues = {
  __typename?: 'LondonOutcodeToTFLZonePreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  outCode: Scalars['String'];
  zone: Scalars['Int'];
};

export type LondonOutcodeToTflZoneSubscriptionPayload = {
  __typename?: 'LondonOutcodeToTFLZoneSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<LondonOutcodeToTflZone>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<LondonOutcodeToTflZonePreviousValues>;
};

export type LondonOutcodeToTflZoneSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<LondonOutcodeToTflZoneWhereInput>;
  AND?: Maybe<Array<LondonOutcodeToTflZoneSubscriptionWhereInput>>;
  OR?: Maybe<Array<LondonOutcodeToTflZoneSubscriptionWhereInput>>;
  NOT?: Maybe<Array<LondonOutcodeToTflZoneSubscriptionWhereInput>>;
};

export type LondonOutcodeToTflZoneUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  outCode?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['Int']>;
};

export type LondonOutcodeToTflZoneUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  outCode?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['Int']>;
};

export type LondonOutcodeToTflZoneWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  outCode?: Maybe<Scalars['String']>;
  outCode_not?: Maybe<Scalars['String']>;
  outCode_in?: Maybe<Array<Scalars['String']>>;
  outCode_not_in?: Maybe<Array<Scalars['String']>>;
  outCode_lt?: Maybe<Scalars['String']>;
  outCode_lte?: Maybe<Scalars['String']>;
  outCode_gt?: Maybe<Scalars['String']>;
  outCode_gte?: Maybe<Scalars['String']>;
  outCode_contains?: Maybe<Scalars['String']>;
  outCode_not_contains?: Maybe<Scalars['String']>;
  outCode_starts_with?: Maybe<Scalars['String']>;
  outCode_not_starts_with?: Maybe<Scalars['String']>;
  outCode_ends_with?: Maybe<Scalars['String']>;
  outCode_not_ends_with?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['Int']>;
  zone_not?: Maybe<Scalars['Int']>;
  zone_in?: Maybe<Array<Scalars['Int']>>;
  zone_not_in?: Maybe<Array<Scalars['Int']>>;
  zone_lt?: Maybe<Scalars['Int']>;
  zone_lte?: Maybe<Scalars['Int']>;
  zone_gt?: Maybe<Scalars['Int']>;
  zone_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<LondonOutcodeToTflZoneWhereInput>>;
  OR?: Maybe<Array<LondonOutcodeToTflZoneWhereInput>>;
  NOT?: Maybe<Array<LondonOutcodeToTflZoneWhereInput>>;
};

export type LondonOutcodeToTflZoneWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};


export type MailchimpIntegrationMeta = {
  __typename?: 'MailchimpIntegrationMeta';
  APIUrl: Scalars['String'];
  FLoomXListID: Scalars['String'];
  OAuthToken: Scalars['String'];
  id: Scalars['ID'];
};

export type MailchimpIntegrationMetaConnection = {
  __typename?: 'MailchimpIntegrationMetaConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MailchimpIntegrationMetaEdge>>;
  aggregate: AggregateMailchimpIntegrationMeta;
};

export type MailchimpIntegrationMetaCreateInput = {
  id?: Maybe<Scalars['ID']>;
  OAuthToken: Scalars['String'];
  APIUrl: Scalars['String'];
  FLoomXListID: Scalars['String'];
};

export type MailchimpIntegrationMetaCreateOneInput = {
  create?: Maybe<MailchimpIntegrationMetaCreateInput>;
  connect?: Maybe<MailchimpIntegrationMetaWhereUniqueInput>;
};

export type MailchimpIntegrationMetaEdge = {
  __typename?: 'MailchimpIntegrationMetaEdge';
  node: MailchimpIntegrationMeta;
  cursor: Scalars['String'];
};

export enum MailchimpIntegrationMetaOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OAuthTokenAsc = 'OAuthToken_ASC',
  OAuthTokenDesc = 'OAuthToken_DESC',
  ApiUrlAsc = 'APIUrl_ASC',
  ApiUrlDesc = 'APIUrl_DESC',
  FLoomXListIdAsc = 'FLoomXListID_ASC',
  FLoomXListIdDesc = 'FLoomXListID_DESC'
}

export type MailchimpIntegrationMetaPreviousValues = {
  __typename?: 'MailchimpIntegrationMetaPreviousValues';
  id: Scalars['ID'];
  OAuthToken: Scalars['String'];
  APIUrl: Scalars['String'];
  FLoomXListID: Scalars['String'];
};

export type MailchimpIntegrationMetaSubscriptionPayload = {
  __typename?: 'MailchimpIntegrationMetaSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<MailchimpIntegrationMeta>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<MailchimpIntegrationMetaPreviousValues>;
};

export type MailchimpIntegrationMetaSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<MailchimpIntegrationMetaWhereInput>;
  AND?: Maybe<Array<MailchimpIntegrationMetaSubscriptionWhereInput>>;
  OR?: Maybe<Array<MailchimpIntegrationMetaSubscriptionWhereInput>>;
  NOT?: Maybe<Array<MailchimpIntegrationMetaSubscriptionWhereInput>>;
};

export type MailchimpIntegrationMetaUpdateDataInput = {
  OAuthToken?: Maybe<Scalars['String']>;
  APIUrl?: Maybe<Scalars['String']>;
  FLoomXListID?: Maybe<Scalars['String']>;
};

export type MailchimpIntegrationMetaUpdateInput = {
  OAuthToken?: Maybe<Scalars['String']>;
  APIUrl?: Maybe<Scalars['String']>;
  FLoomXListID?: Maybe<Scalars['String']>;
};

export type MailchimpIntegrationMetaUpdateManyMutationInput = {
  OAuthToken?: Maybe<Scalars['String']>;
  APIUrl?: Maybe<Scalars['String']>;
  FLoomXListID?: Maybe<Scalars['String']>;
};

export type MailchimpIntegrationMetaUpdateOneInput = {
  create?: Maybe<MailchimpIntegrationMetaCreateInput>;
  update?: Maybe<MailchimpIntegrationMetaUpdateDataInput>;
  upsert?: Maybe<MailchimpIntegrationMetaUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MailchimpIntegrationMetaWhereUniqueInput>;
};

export type MailchimpIntegrationMetaUpsertNestedInput = {
  update: MailchimpIntegrationMetaUpdateDataInput;
  create: MailchimpIntegrationMetaCreateInput;
};

export type MailchimpIntegrationMetaWhereInput = {
  AND?: Maybe<Array<MailchimpIntegrationMetaWhereInput>>;
  APIUrl?: Maybe<Scalars['String']>;
  APIUrl_contains?: Maybe<Scalars['String']>;
  APIUrl_ends_with?: Maybe<Scalars['String']>;
  APIUrl_gt?: Maybe<Scalars['String']>;
  APIUrl_gte?: Maybe<Scalars['String']>;
  APIUrl_in?: Maybe<Array<Scalars['String']>>;
  APIUrl_lt?: Maybe<Scalars['String']>;
  APIUrl_lte?: Maybe<Scalars['String']>;
  APIUrl_not?: Maybe<Scalars['String']>;
  APIUrl_not_contains?: Maybe<Scalars['String']>;
  APIUrl_not_ends_with?: Maybe<Scalars['String']>;
  APIUrl_not_in?: Maybe<Array<Scalars['String']>>;
  APIUrl_not_starts_with?: Maybe<Scalars['String']>;
  APIUrl_starts_with?: Maybe<Scalars['String']>;
  FLoomXListID?: Maybe<Scalars['String']>;
  FLoomXListID_contains?: Maybe<Scalars['String']>;
  FLoomXListID_ends_with?: Maybe<Scalars['String']>;
  FLoomXListID_gt?: Maybe<Scalars['String']>;
  FLoomXListID_gte?: Maybe<Scalars['String']>;
  FLoomXListID_in?: Maybe<Array<Scalars['String']>>;
  FLoomXListID_lt?: Maybe<Scalars['String']>;
  FLoomXListID_lte?: Maybe<Scalars['String']>;
  FLoomXListID_not?: Maybe<Scalars['String']>;
  FLoomXListID_not_contains?: Maybe<Scalars['String']>;
  FLoomXListID_not_ends_with?: Maybe<Scalars['String']>;
  FLoomXListID_not_in?: Maybe<Array<Scalars['String']>>;
  FLoomXListID_not_starts_with?: Maybe<Scalars['String']>;
  FLoomXListID_starts_with?: Maybe<Scalars['String']>;
  NOT?: Maybe<Array<MailchimpIntegrationMetaWhereInput>>;
  OAuthToken?: Maybe<Scalars['String']>;
  OAuthToken_contains?: Maybe<Scalars['String']>;
  OAuthToken_ends_with?: Maybe<Scalars['String']>;
  OAuthToken_gt?: Maybe<Scalars['String']>;
  OAuthToken_gte?: Maybe<Scalars['String']>;
  OAuthToken_in?: Maybe<Array<Scalars['String']>>;
  OAuthToken_lt?: Maybe<Scalars['String']>;
  OAuthToken_lte?: Maybe<Scalars['String']>;
  OAuthToken_not?: Maybe<Scalars['String']>;
  OAuthToken_not_contains?: Maybe<Scalars['String']>;
  OAuthToken_not_ends_with?: Maybe<Scalars['String']>;
  OAuthToken_not_in?: Maybe<Array<Scalars['String']>>;
  OAuthToken_not_starts_with?: Maybe<Scalars['String']>;
  OAuthToken_starts_with?: Maybe<Scalars['String']>;
  OR?: Maybe<Array<MailchimpIntegrationMetaWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
};

export type MailchimpIntegrationMetaWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type MailchimpResult = {
  __typename?: 'MailchimpResult';
  linked: Scalars['Boolean'];
  newUser?: Maybe<Scalars['String']>;
  listName?: Maybe<Scalars['String']>;
};

export type Media = {
  __typename?: 'Media';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  category?: Maybe<MediaCategory>;
  src: Scalars['String'];
  type: MediaType;
  format: Scalars['String'];
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum MediaCategory {
  SideOn = 'SideOn',
  Overhead = 'Overhead',
  Lifestyle = 'Lifestyle',
  Details = 'Details',
  FloomSnap = 'FloomSnap'
}

export type MediaConnection = {
  __typename?: 'MediaConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MediaEdge>>;
  aggregate: AggregateMedia;
};

export type MediaCreateInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  category?: Maybe<MediaCategory>;
  src: Scalars['String'];
  type: MediaType;
  format: Scalars['String'];
  createdBy?: Maybe<UserCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MediaCreateManyInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  create?: Maybe<Array<MediaCreateInput>>;
};

export type MediaCreateOneInput = {
  create?: Maybe<MediaCreateInput>;
  connect?: Maybe<MediaWhereUniqueInput>;
};

export type MediaEdge = {
  __typename?: 'MediaEdge';
  node: Media;
  cursor: Scalars['String'];
};

export enum MediaOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ConfirmedAsc = 'confirmed_ASC',
  ConfirmedDesc = 'confirmed_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  SrcAsc = 'src_ASC',
  SrcDesc = 'src_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type MediaPreviousValues = {
  __typename?: 'MediaPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  category?: Maybe<MediaCategory>;
  src: Scalars['String'];
  type: MediaType;
  format: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MediaScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  confirmed_not?: Maybe<Scalars['Boolean']>;
  category?: Maybe<MediaCategory>;
  category_not?: Maybe<MediaCategory>;
  category_in?: Maybe<Array<MediaCategory>>;
  category_not_in?: Maybe<Array<MediaCategory>>;
  src?: Maybe<Scalars['String']>;
  src_not?: Maybe<Scalars['String']>;
  src_in?: Maybe<Array<Scalars['String']>>;
  src_not_in?: Maybe<Array<Scalars['String']>>;
  src_lt?: Maybe<Scalars['String']>;
  src_lte?: Maybe<Scalars['String']>;
  src_gt?: Maybe<Scalars['String']>;
  src_gte?: Maybe<Scalars['String']>;
  src_contains?: Maybe<Scalars['String']>;
  src_not_contains?: Maybe<Scalars['String']>;
  src_starts_with?: Maybe<Scalars['String']>;
  src_not_starts_with?: Maybe<Scalars['String']>;
  src_ends_with?: Maybe<Scalars['String']>;
  src_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<MediaType>;
  type_not?: Maybe<MediaType>;
  type_in?: Maybe<Array<MediaType>>;
  type_not_in?: Maybe<Array<MediaType>>;
  format?: Maybe<Scalars['String']>;
  format_not?: Maybe<Scalars['String']>;
  format_in?: Maybe<Array<Scalars['String']>>;
  format_not_in?: Maybe<Array<Scalars['String']>>;
  format_lt?: Maybe<Scalars['String']>;
  format_lte?: Maybe<Scalars['String']>;
  format_gt?: Maybe<Scalars['String']>;
  format_gte?: Maybe<Scalars['String']>;
  format_contains?: Maybe<Scalars['String']>;
  format_not_contains?: Maybe<Scalars['String']>;
  format_starts_with?: Maybe<Scalars['String']>;
  format_not_starts_with?: Maybe<Scalars['String']>;
  format_ends_with?: Maybe<Scalars['String']>;
  format_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<MediaScalarWhereInput>>;
  OR?: Maybe<Array<MediaScalarWhereInput>>;
  NOT?: Maybe<Array<MediaScalarWhereInput>>;
};

export type MediaSubscriptionPayload = {
  __typename?: 'MediaSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Media>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<MediaPreviousValues>;
};

export type MediaSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<MediaWhereInput>;
  AND?: Maybe<Array<MediaSubscriptionWhereInput>>;
  OR?: Maybe<Array<MediaSubscriptionWhereInput>>;
  NOT?: Maybe<Array<MediaSubscriptionWhereInput>>;
};

export enum MediaType {
  Image = 'Image',
  ProfileImage = 'ProfileImage',
  FloomSnap = 'FloomSnap',
  Genus = 'Genus',
  Floricode = 'Floricode'
}

export type MediaUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  category?: Maybe<MediaCategory>;
  src?: Maybe<Scalars['String']>;
  type?: Maybe<MediaType>;
  format?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MediaUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  category?: Maybe<MediaCategory>;
  src?: Maybe<Scalars['String']>;
  type?: Maybe<MediaType>;
  format?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MediaUpdateManyDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  category?: Maybe<MediaCategory>;
  src?: Maybe<Scalars['String']>;
  type?: Maybe<MediaType>;
  format?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MediaUpdateManyInput = {
  connect?: Maybe<Array<MediaWhereUniqueInput>>;
  create?: Maybe<Array<MediaCreateInput>>;
  delete?: Maybe<Array<MediaWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MediaScalarWhereInput>>;
  disconnect?: Maybe<Array<MediaWhereUniqueInput>>;
  set?: Maybe<Array<MediaWhereUniqueInput>>;
  update?: Maybe<Array<MediaUpdateWithWhereUniqueNestedInput>>;
  updateMany?: Maybe<Array<MediaUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<MediaUpsertWithWhereUniqueNestedInput>>;
};

export type MediaUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  category?: Maybe<MediaCategory>;
  src?: Maybe<Scalars['String']>;
  type?: Maybe<MediaType>;
  format?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MediaUpdateManyWithWhereNestedInput = {
  where: MediaScalarWhereInput;
  data: MediaUpdateManyDataInput;
};

export type MediaUpdateOneInput = {
  create?: Maybe<MediaCreateInput>;
  update?: Maybe<MediaUpdateDataInput>;
  upsert?: Maybe<MediaUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MediaWhereUniqueInput>;
};

export type MediaUpdateWithWhereUniqueNestedInput = {
  where: MediaWhereUniqueInput;
  data: MediaUpdateDataInput;
};

export type MediaUpsertNestedInput = {
  update: MediaUpdateDataInput;
  create: MediaCreateInput;
};

export type MediaUpsertWithWhereUniqueNestedInput = {
  where: MediaWhereUniqueInput;
  update: MediaUpdateDataInput;
  create: MediaCreateInput;
};

export type MediaWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  confirmed_not?: Maybe<Scalars['Boolean']>;
  category?: Maybe<MediaCategory>;
  category_not?: Maybe<MediaCategory>;
  category_in?: Maybe<Array<MediaCategory>>;
  category_not_in?: Maybe<Array<MediaCategory>>;
  src?: Maybe<Scalars['String']>;
  src_not?: Maybe<Scalars['String']>;
  src_in?: Maybe<Array<Scalars['String']>>;
  src_not_in?: Maybe<Array<Scalars['String']>>;
  src_lt?: Maybe<Scalars['String']>;
  src_lte?: Maybe<Scalars['String']>;
  src_gt?: Maybe<Scalars['String']>;
  src_gte?: Maybe<Scalars['String']>;
  src_contains?: Maybe<Scalars['String']>;
  src_not_contains?: Maybe<Scalars['String']>;
  src_starts_with?: Maybe<Scalars['String']>;
  src_not_starts_with?: Maybe<Scalars['String']>;
  src_ends_with?: Maybe<Scalars['String']>;
  src_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<MediaType>;
  type_not?: Maybe<MediaType>;
  type_in?: Maybe<Array<MediaType>>;
  type_not_in?: Maybe<Array<MediaType>>;
  format?: Maybe<Scalars['String']>;
  format_not?: Maybe<Scalars['String']>;
  format_in?: Maybe<Array<Scalars['String']>>;
  format_not_in?: Maybe<Array<Scalars['String']>>;
  format_lt?: Maybe<Scalars['String']>;
  format_lte?: Maybe<Scalars['String']>;
  format_gt?: Maybe<Scalars['String']>;
  format_gte?: Maybe<Scalars['String']>;
  format_contains?: Maybe<Scalars['String']>;
  format_not_contains?: Maybe<Scalars['String']>;
  format_starts_with?: Maybe<Scalars['String']>;
  format_not_starts_with?: Maybe<Scalars['String']>;
  format_ends_with?: Maybe<Scalars['String']>;
  format_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<MediaWhereInput>>;
  OR?: Maybe<Array<MediaWhereInput>>;
  NOT?: Maybe<Array<MediaWhereInput>>;
};

export type MediaWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  customSiteConfig?: Maybe<CustomSiteConfig>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  shops?: Maybe<Array<Shop>>;
  timezone: Scalars['String'];
  regionIso?: Maybe<Scalars['String']>;
  currency: Currency;
  biography?: Maybe<Scalars['String']>;
  stage: MerchantStage;
  address?: Maybe<Address>;
  wholesaleDeliveryConfigs?: Maybe<Array<WholesaleDeliveryConfig>>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<Address>;
  holidays?: Maybe<Array<MerchantHoliday>>;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<MerchantPlan>;
  subscription?: Maybe<MerchantRecurringPayment>;
  paymentMethod?: Maybe<PaymentMethod>;
  payments?: Maybe<Array<Payment>>;
  subscriptionTrialEndAt?: Maybe<Scalars['DateTime']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  listsActive?: Maybe<Scalars['Boolean']>;
  conversationsActive?: Maybe<Scalars['Boolean']>;
  promotedListsActive?: Maybe<Scalars['Boolean']>;
  promotedActive?: Maybe<Scalars['Boolean']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  sameDayOrderPauseStatus?: Maybe<Scalars['Int']>;
  sameDayOrderPauseLimit?: Maybe<Scalars['Int']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  weeblyConfig?: Maybe<WeeblyConfig>;
  canDownloadCsv?: Maybe<Scalars['Boolean']>;
  canViewNationwide?: Maybe<Scalars['Boolean']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  hasProvidedBankDetails?: Maybe<Scalars['Boolean']>;
  canEditFloomDeliveryPricing?: Maybe<Scalars['Boolean']>;
  deliveryConfigs?: Maybe<Array<DeliveryConfig>>;
  productCount?: Maybe<Scalars['Int']>;
  isDemoAccount: Scalars['Boolean'];
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  proposedUpdate?: Maybe<ProposedMerchantUpdate>;
  reviewCount?: Maybe<Scalars['Int']>;
  reviewRating?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  remittanceAccount?: Maybe<RemittanceAccount>;
};


export type MerchantShopsArgs = {
  where?: Maybe<ShopWhereInput>;
  orderBy?: Maybe<ShopOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MerchantWholesaleDeliveryConfigsArgs = {
  where?: Maybe<WholesaleDeliveryConfigWhereInput>;
  orderBy?: Maybe<WholesaleDeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MerchantHolidaysArgs = {
  where?: Maybe<MerchantHolidayWhereInput>;
  orderBy?: Maybe<MerchantHolidayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MerchantPaymentsArgs = {
  where?: Maybe<PaymentWhereInput>;
  orderBy?: Maybe<PaymentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MerchantDeliveryConfigsArgs = {
  where?: Maybe<DeliveryConfigWhereInput>;
  orderBy?: Maybe<DeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MerchantBankDetailsInput = {
  merchantId?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  fname?: Maybe<Scalars['String']>;
  lname?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  route?: Maybe<Scalars['String']>;
  acct?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type MerchantConnectOneInput = {
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantConnection = {
  __typename?: 'MerchantConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MerchantEdge>>;
  aggregate: AggregateMerchant;
};

export type MerchantCreateInput = {
  active: Scalars['Boolean'];
  title: Scalars['String'];
  timezone: Scalars['String'];
  regionIso?: Maybe<Scalars['String']>;
  currency: Currency;
  biography?: Maybe<Scalars['String']>;
  stage?: Maybe<MerchantStage>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserCreateOneWithoutMerchantInput>;
  plan?: Maybe<MerchantPlanCreateOneInput>;
  subscription?: Maybe<MerchantRecurringPaymentCreateOneWithoutMerchantInput>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantCreateOneInput = {
  create?: Maybe<MerchantCreateInput>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantCreateOneWithoutCreatedByInput = {
  create?: Maybe<MerchantCreateWithoutCreatedByInput>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantCreateOneWithoutHolidaysInput = {
  create?: Maybe<MerchantCreateWithoutHolidaysInput>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantCreateOneWithoutPaymentMethodInput = {
  create?: Maybe<MerchantCreateWithoutPaymentMethodInput>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantCreateOneWithoutPaymentsInput = {
  create?: Maybe<MerchantCreateWithoutPaymentsInput>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantCreateOneWithoutSubscriptionInput = {
  create?: Maybe<MerchantCreateWithoutSubscriptionInput>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantCreateOneWithoutWholesaleDeliveryConfigsInput = {
  create?: Maybe<MerchantCreateWithoutWholesaleDeliveryConfigsInput>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantCreateWithoutCreatedByInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  customSiteConfig?: Maybe<CustomSiteConfigCreateOneInput>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  shops?: Maybe<ShopCreateManyInput>;
  timezone: Scalars['String'];
  regionIso?: Maybe<Scalars['String']>;
  currency: Currency;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigCreateManyWithoutMerchantInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressCreateOneInput>;
  holidays?: Maybe<MerchantHolidayCreateManyWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<MerchantPlanCreateOneInput>;
  subscription?: Maybe<MerchantRecurringPaymentCreateOneWithoutMerchantInput>;
  paymentMethod?: Maybe<PaymentMethodCreateOneWithoutMerchantInput>;
  payments?: Maybe<PaymentCreateManyWithoutMerchantInput>;
  subscriptionTrialEndAt?: Maybe<Scalars['DateTime']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantCreateWithoutHolidaysInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  customSiteConfig?: Maybe<CustomSiteConfigCreateOneInput>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  shops?: Maybe<ShopCreateManyInput>;
  timezone: Scalars['String'];
  regionIso?: Maybe<Scalars['String']>;
  currency: Currency;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigCreateManyWithoutMerchantInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressCreateOneInput>;
  createdBy?: Maybe<UserCreateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<MerchantPlanCreateOneInput>;
  subscription?: Maybe<MerchantRecurringPaymentCreateOneWithoutMerchantInput>;
  paymentMethod?: Maybe<PaymentMethodCreateOneWithoutMerchantInput>;
  payments?: Maybe<PaymentCreateManyWithoutMerchantInput>;
  subscriptionTrialEndAt?: Maybe<Scalars['DateTime']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantCreateWithoutPaymentMethodInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  customSiteConfig?: Maybe<CustomSiteConfigCreateOneInput>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  shops?: Maybe<ShopCreateManyInput>;
  timezone: Scalars['String'];
  regionIso?: Maybe<Scalars['String']>;
  currency: Currency;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigCreateManyWithoutMerchantInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressCreateOneInput>;
  holidays?: Maybe<MerchantHolidayCreateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserCreateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<MerchantPlanCreateOneInput>;
  subscription?: Maybe<MerchantRecurringPaymentCreateOneWithoutMerchantInput>;
  payments?: Maybe<PaymentCreateManyWithoutMerchantInput>;
  subscriptionTrialEndAt?: Maybe<Scalars['DateTime']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantCreateWithoutPaymentsInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  customSiteConfig?: Maybe<CustomSiteConfigCreateOneInput>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  shops?: Maybe<ShopCreateManyInput>;
  timezone: Scalars['String'];
  regionIso?: Maybe<Scalars['String']>;
  currency: Currency;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigCreateManyWithoutMerchantInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressCreateOneInput>;
  holidays?: Maybe<MerchantHolidayCreateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserCreateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<MerchantPlanCreateOneInput>;
  subscription?: Maybe<MerchantRecurringPaymentCreateOneWithoutMerchantInput>;
  paymentMethod?: Maybe<PaymentMethodCreateOneWithoutMerchantInput>;
  subscriptionTrialEndAt?: Maybe<Scalars['DateTime']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantCreateWithoutSubscriptionInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  customSiteConfig?: Maybe<CustomSiteConfigCreateOneInput>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  shops?: Maybe<ShopCreateManyInput>;
  timezone: Scalars['String'];
  regionIso?: Maybe<Scalars['String']>;
  currency: Currency;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigCreateManyWithoutMerchantInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressCreateOneInput>;
  holidays?: Maybe<MerchantHolidayCreateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserCreateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<MerchantPlanCreateOneInput>;
  paymentMethod?: Maybe<PaymentMethodCreateOneWithoutMerchantInput>;
  payments?: Maybe<PaymentCreateManyWithoutMerchantInput>;
  subscriptionTrialEndAt?: Maybe<Scalars['DateTime']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantCreateWithoutWholesaleDeliveryConfigsInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  customSiteConfig?: Maybe<CustomSiteConfigCreateOneInput>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  shops?: Maybe<ShopCreateManyInput>;
  timezone: Scalars['String'];
  regionIso?: Maybe<Scalars['String']>;
  currency: Currency;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressCreateOneInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressCreateOneInput>;
  holidays?: Maybe<MerchantHolidayCreateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserCreateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<MerchantPlanCreateOneInput>;
  subscription?: Maybe<MerchantRecurringPaymentCreateOneWithoutMerchantInput>;
  paymentMethod?: Maybe<PaymentMethodCreateOneWithoutMerchantInput>;
  payments?: Maybe<PaymentCreateManyWithoutMerchantInput>;
  subscriptionTrialEndAt?: Maybe<Scalars['DateTime']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantCreditTermsUpdateInput = {
  wholesaleTermLength?: Maybe<Scalars['Int']>;
  wholesaleCreditLimit?: Maybe<Scalars['Float']>;
};

export type MerchantEdge = {
  __typename?: 'MerchantEdge';
  node: Merchant;
  cursor: Scalars['String'];
};

export type MerchantHoliday = {
  __typename?: 'MerchantHoliday';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type: MerchantHolidayType;
  notes?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  merchant?: Maybe<Merchant>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantHolidayConnection = {
  __typename?: 'MerchantHolidayConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MerchantHolidayEdge>>;
  aggregate: AggregateMerchantHoliday;
};

export type MerchantHolidayCreateInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantHolidayType>;
  notes?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  merchant?: Maybe<MerchantCreateOneWithoutHolidaysInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantHolidayCreateManyInput = {
  create?: Maybe<Array<MerchantHolidayCreateInput>>;
  connect?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
};

export type MerchantHolidayCreateManyWithoutMerchantInput = {
  create?: Maybe<Array<MerchantHolidayCreateWithoutMerchantInput>>;
  connect?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
};

export type MerchantHolidayCreateWithoutMerchantInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantHolidayType>;
  notes?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantHolidayEdge = {
  __typename?: 'MerchantHolidayEdge';
  node: MerchantHoliday;
  cursor: Scalars['String'];
};

export enum MerchantHolidayOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NotesAsc = 'notes_ASC',
  NotesDesc = 'notes_DESC',
  StartAtAsc = 'startAt_ASC',
  StartAtDesc = 'startAt_DESC',
  EndAtAsc = 'endAt_ASC',
  EndAtDesc = 'endAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type MerchantHolidayPreviousValues = {
  __typename?: 'MerchantHolidayPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type: MerchantHolidayType;
  notes?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantHolidayScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantHolidayType>;
  type_not?: Maybe<MerchantHolidayType>;
  type_in?: Maybe<Array<MerchantHolidayType>>;
  type_not_in?: Maybe<Array<MerchantHolidayType>>;
  notes?: Maybe<Scalars['String']>;
  notes_not?: Maybe<Scalars['String']>;
  notes_in?: Maybe<Array<Scalars['String']>>;
  notes_not_in?: Maybe<Array<Scalars['String']>>;
  notes_lt?: Maybe<Scalars['String']>;
  notes_lte?: Maybe<Scalars['String']>;
  notes_gt?: Maybe<Scalars['String']>;
  notes_gte?: Maybe<Scalars['String']>;
  notes_contains?: Maybe<Scalars['String']>;
  notes_not_contains?: Maybe<Scalars['String']>;
  notes_starts_with?: Maybe<Scalars['String']>;
  notes_not_starts_with?: Maybe<Scalars['String']>;
  notes_ends_with?: Maybe<Scalars['String']>;
  notes_not_ends_with?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  startAt_not?: Maybe<Scalars['DateTime']>;
  startAt_in?: Maybe<Array<Scalars['DateTime']>>;
  startAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startAt_lt?: Maybe<Scalars['DateTime']>;
  startAt_lte?: Maybe<Scalars['DateTime']>;
  startAt_gt?: Maybe<Scalars['DateTime']>;
  startAt_gte?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  endAt_not?: Maybe<Scalars['DateTime']>;
  endAt_in?: Maybe<Array<Scalars['DateTime']>>;
  endAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endAt_lt?: Maybe<Scalars['DateTime']>;
  endAt_lte?: Maybe<Scalars['DateTime']>;
  endAt_gt?: Maybe<Scalars['DateTime']>;
  endAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<MerchantHolidayScalarWhereInput>>;
  OR?: Maybe<Array<MerchantHolidayScalarWhereInput>>;
  NOT?: Maybe<Array<MerchantHolidayScalarWhereInput>>;
};

export type MerchantHolidaySubscriptionPayload = {
  __typename?: 'MerchantHolidaySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<MerchantHoliday>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<MerchantHolidayPreviousValues>;
};

export type MerchantHolidaySubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<MerchantHolidayWhereInput>;
  AND?: Maybe<Array<MerchantHolidaySubscriptionWhereInput>>;
  OR?: Maybe<Array<MerchantHolidaySubscriptionWhereInput>>;
  NOT?: Maybe<Array<MerchantHolidaySubscriptionWhereInput>>;
};

export enum MerchantHolidayType {
  Merchant = 'Merchant',
  DeliveryConfig = 'DeliveryConfig',
  Product = 'Product'
}

export type MerchantHolidayUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantHolidayType>;
  notes?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<MerchantUpdateOneWithoutHolidaysInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantHolidayUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantHolidayType>;
  notes?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<MerchantUpdateOneWithoutHolidaysInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantHolidayUpdateManyDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantHolidayType>;
  notes?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantHolidayUpdateManyInput = {
  create?: Maybe<Array<MerchantHolidayCreateInput>>;
  update?: Maybe<Array<MerchantHolidayUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<MerchantHolidayUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
  connect?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
  set?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
  disconnect?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MerchantHolidayScalarWhereInput>>;
  updateMany?: Maybe<Array<MerchantHolidayUpdateManyWithWhereNestedInput>>;
};

export type MerchantHolidayUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantHolidayType>;
  notes?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantHolidayUpdateManyWithWhereNestedInput = {
  where: MerchantHolidayScalarWhereInput;
  data: MerchantHolidayUpdateManyDataInput;
};

export type MerchantHolidayUpdateManyWithoutMerchantInput = {
  create?: Maybe<Array<MerchantHolidayCreateWithoutMerchantInput>>;
  delete?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
  connect?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
  set?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
  disconnect?: Maybe<Array<MerchantHolidayWhereUniqueInput>>;
  update?: Maybe<Array<MerchantHolidayUpdateWithWhereUniqueWithoutMerchantInput>>;
  upsert?: Maybe<Array<MerchantHolidayUpsertWithWhereUniqueWithoutMerchantInput>>;
  deleteMany?: Maybe<Array<MerchantHolidayScalarWhereInput>>;
  updateMany?: Maybe<Array<MerchantHolidayUpdateManyWithWhereNestedInput>>;
};

export type MerchantHolidayUpdateWithWhereUniqueNestedInput = {
  where: MerchantHolidayWhereUniqueInput;
  data: MerchantHolidayUpdateDataInput;
};

export type MerchantHolidayUpdateWithWhereUniqueWithoutMerchantInput = {
  where: MerchantHolidayWhereUniqueInput;
  data: MerchantHolidayUpdateWithoutMerchantDataInput;
};

export type MerchantHolidayUpdateWithoutMerchantDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantHolidayType>;
  notes?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantHolidayUpsertWithWhereUniqueNestedInput = {
  where: MerchantHolidayWhereUniqueInput;
  update: MerchantHolidayUpdateDataInput;
  create: MerchantHolidayCreateInput;
};

export type MerchantHolidayUpsertWithWhereUniqueWithoutMerchantInput = {
  where: MerchantHolidayWhereUniqueInput;
  update: MerchantHolidayUpdateWithoutMerchantDataInput;
  create: MerchantHolidayCreateWithoutMerchantInput;
};

export type MerchantHolidayWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantHolidayType>;
  type_not?: Maybe<MerchantHolidayType>;
  type_in?: Maybe<Array<MerchantHolidayType>>;
  type_not_in?: Maybe<Array<MerchantHolidayType>>;
  notes?: Maybe<Scalars['String']>;
  notes_not?: Maybe<Scalars['String']>;
  notes_in?: Maybe<Array<Scalars['String']>>;
  notes_not_in?: Maybe<Array<Scalars['String']>>;
  notes_lt?: Maybe<Scalars['String']>;
  notes_lte?: Maybe<Scalars['String']>;
  notes_gt?: Maybe<Scalars['String']>;
  notes_gte?: Maybe<Scalars['String']>;
  notes_contains?: Maybe<Scalars['String']>;
  notes_not_contains?: Maybe<Scalars['String']>;
  notes_starts_with?: Maybe<Scalars['String']>;
  notes_not_starts_with?: Maybe<Scalars['String']>;
  notes_ends_with?: Maybe<Scalars['String']>;
  notes_not_ends_with?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['DateTime']>;
  startAt_not?: Maybe<Scalars['DateTime']>;
  startAt_in?: Maybe<Array<Scalars['DateTime']>>;
  startAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startAt_lt?: Maybe<Scalars['DateTime']>;
  startAt_lte?: Maybe<Scalars['DateTime']>;
  startAt_gt?: Maybe<Scalars['DateTime']>;
  startAt_gte?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  endAt_not?: Maybe<Scalars['DateTime']>;
  endAt_in?: Maybe<Array<Scalars['DateTime']>>;
  endAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endAt_lt?: Maybe<Scalars['DateTime']>;
  endAt_lte?: Maybe<Scalars['DateTime']>;
  endAt_gt?: Maybe<Scalars['DateTime']>;
  endAt_gte?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<MerchantWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<MerchantHolidayWhereInput>>;
  OR?: Maybe<Array<MerchantHolidayWhereInput>>;
  NOT?: Maybe<Array<MerchantHolidayWhereInput>>;
};

export type MerchantHolidayWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export enum MerchantOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  PublicIdAsc = 'publicId_ASC',
  PublicIdDesc = 'publicId_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  StripeConnectAccountIdAsc = 'stripeConnectAccountId_ASC',
  StripeConnectAccountIdDesc = 'stripeConnectAccountId_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  RegionIsoAsc = 'regionIso_ASC',
  RegionIsoDesc = 'regionIso_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  BiographyAsc = 'biography_ASC',
  BiographyDesc = 'biography_DESC',
  CompanyNameAsc = 'companyName_ASC',
  CompanyNameDesc = 'companyName_DESC',
  TradingNoAsc = 'tradingNo_ASC',
  TradingNoDesc = 'tradingNo_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  SearchableAsc = 'searchable_ASC',
  SearchableDesc = 'searchable_DESC',
  SubscriptionTrialEndAtAsc = 'subscriptionTrialEndAt_ASC',
  SubscriptionTrialEndAtDesc = 'subscriptionTrialEndAt_DESC',
  InvitedAtAsc = 'invitedAt_ASC',
  InvitedAtDesc = 'invitedAt_DESC',
  CanEditFloomDeliveryPricingAsc = 'canEditFloomDeliveryPricing_ASC',
  CanEditFloomDeliveryPricingDesc = 'canEditFloomDeliveryPricing_DESC',
  IsDemoAccountAsc = 'isDemoAccount_ASC',
  IsDemoAccountDesc = 'isDemoAccount_DESC',
  ReviewCountAsc = 'reviewCount_ASC',
  ReviewCountDesc = 'reviewCount_DESC',
  ReviewRatingAsc = 'reviewRating_ASC',
  ReviewRatingDesc = 'reviewRating_DESC',
  CommissionRateAsc = 'commissionRate_ASC',
  CommissionRateDesc = 'commissionRate_DESC'
}

export type MerchantPaymentDetailsLatestInvoiceDetails = {
  __typename?: 'MerchantPaymentDetailsLatestInvoiceDetails';
  description?: Maybe<Scalars['String']>;
  ammount?: Maybe<Scalars['Float']>;
  paymentScheduledAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantPaymentDetailsResponse = {
  __typename?: 'MerchantPaymentDetailsResponse';
  last4Digits?: Maybe<Scalars['String']>;
  nextInvoice?: Maybe<MerchantPaymentDetailsLatestInvoiceDetails>;
};

export type MerchantPaymentMethodUpdateInput = {
  stripe_customer_id: Scalars['String'];
  last_4_digits?: Maybe<Scalars['String']>;
  new_payment_token: Scalars['String'];
};

export type MerchantPaymentMethodUpdateResponse = {
  __typename?: 'MerchantPaymentMethodUpdateResponse';
  response?: Maybe<Scalars['String']>;
};

export type MerchantPlan = {
  __typename?: 'MerchantPlan';
  id: Scalars['ID'];
  type: PlanType;
  title?: Maybe<Scalars['String']>;
  stripePlanId: Scalars['String'];
  price: Scalars['Float'];
  currency: Currency;
  description?: Maybe<Scalars['String']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  features: Array<PlanFeature>;
  hasCollectionSupport?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantPlanConnectOneInput = {
  connect?: Maybe<MerchantPlanWhereUniqueInput>;
};

export type MerchantPlanConnection = {
  __typename?: 'MerchantPlanConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MerchantPlanEdge>>;
  aggregate: AggregateMerchantPlan;
};

export type MerchantPlanCreateInput = {
  id?: Maybe<Scalars['ID']>;
  type: PlanType;
  title?: Maybe<Scalars['String']>;
  stripePlanId: Scalars['String'];
  price: Scalars['Float'];
  currency: Currency;
  description?: Maybe<Scalars['String']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  features?: Maybe<MerchantPlanCreatefeaturesInput>;
  hasCollectionSupport?: Maybe<Scalars['Boolean']>;
  trialPeriod?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantPlanCreateOneInput = {
  create?: Maybe<MerchantPlanCreateInput>;
  connect?: Maybe<MerchantPlanWhereUniqueInput>;
};

export type MerchantPlanCreatefeaturesInput = {
  set?: Maybe<Array<PlanFeature>>;
};

export type MerchantPlanEdge = {
  __typename?: 'MerchantPlanEdge';
  node: MerchantPlan;
  cursor: Scalars['String'];
};

export enum MerchantPlanOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  StripePlanIdAsc = 'stripePlanId_ASC',
  StripePlanIdDesc = 'stripePlanId_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ProductLimitAsc = 'productLimit_ASC',
  ProductLimitDesc = 'productLimit_DESC',
  OrderLimitAsc = 'orderLimit_ASC',
  OrderLimitDesc = 'orderLimit_DESC',
  HasCollectionSupportAsc = 'hasCollectionSupport_ASC',
  HasCollectionSupportDesc = 'hasCollectionSupport_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type MerchantPlanPreviousValues = {
  __typename?: 'MerchantPlanPreviousValues';
  id: Scalars['ID'];
  type: PlanType;
  title?: Maybe<Scalars['String']>;
  stripePlanId: Scalars['String'];
  price: Scalars['Float'];
  currency: Currency;
  description?: Maybe<Scalars['String']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  features: Array<PlanFeature>;
  hasCollectionSupport?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantPlanSubscriptionPayload = {
  __typename?: 'MerchantPlanSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<MerchantPlan>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<MerchantPlanPreviousValues>;
};

export type MerchantPlanSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<MerchantPlanWhereInput>;
  AND?: Maybe<Array<MerchantPlanSubscriptionWhereInput>>;
  OR?: Maybe<Array<MerchantPlanSubscriptionWhereInput>>;
  NOT?: Maybe<Array<MerchantPlanSubscriptionWhereInput>>;
};

export type MerchantPlanUpdateDataInput = {
  type: PlanType;
  title?: Maybe<Scalars['String']>;
  stripePlanId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  features?: Maybe<MerchantPlanUpdatefeaturesInput>;
  hasCollectionSupport?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantPlanUpdateInput = {
  type: PlanType;
  title?: Maybe<Scalars['String']>;
  stripePlanId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  features?: Maybe<MerchantPlanUpdatefeaturesInput>;
  hasCollectionSupport?: Maybe<Scalars['Boolean']>;
  trialPeriod?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantPlanUpdateManyMutationInput = {
  type: PlanType;
  title?: Maybe<Scalars['String']>;
  stripePlanId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  features?: Maybe<MerchantPlanUpdatefeaturesInput>;
  hasCollectionSupport?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantPlanUpdateOneInput = {
  create?: Maybe<MerchantPlanCreateInput>;
  update?: Maybe<MerchantPlanUpdateDataInput>;
  upsert?: Maybe<MerchantPlanUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MerchantPlanWhereUniqueInput>;
};

export type MerchantPlanUpdatefeaturesInput = {
  set?: Maybe<Array<PlanFeature>>;
};

export type MerchantPlanUpsertNestedInput = {
  update: MerchantPlanUpdateDataInput;
  create: MerchantPlanCreateInput;
};

export type MerchantPlanWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  type?: Maybe<PlanType>;
  type_not?: Maybe<PlanType>;
  type_in?: Maybe<Array<PlanType>>;
  type_not_in?: Maybe<Array<PlanType>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  stripePlanId?: Maybe<Scalars['String']>;
  stripePlanId_not?: Maybe<Scalars['String']>;
  stripePlanId_in?: Maybe<Array<Scalars['String']>>;
  stripePlanId_not_in?: Maybe<Array<Scalars['String']>>;
  stripePlanId_lt?: Maybe<Scalars['String']>;
  stripePlanId_lte?: Maybe<Scalars['String']>;
  stripePlanId_gt?: Maybe<Scalars['String']>;
  stripePlanId_gte?: Maybe<Scalars['String']>;
  stripePlanId_contains?: Maybe<Scalars['String']>;
  stripePlanId_not_contains?: Maybe<Scalars['String']>;
  stripePlanId_starts_with?: Maybe<Scalars['String']>;
  stripePlanId_not_starts_with?: Maybe<Scalars['String']>;
  stripePlanId_ends_with?: Maybe<Scalars['String']>;
  stripePlanId_not_ends_with?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  currency_not?: Maybe<Currency>;
  currency_in?: Maybe<Array<Currency>>;
  currency_not_in?: Maybe<Array<Currency>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  productLimit?: Maybe<Scalars['Int']>;
  productLimit_not?: Maybe<Scalars['Int']>;
  productLimit_in?: Maybe<Array<Scalars['Int']>>;
  productLimit_not_in?: Maybe<Array<Scalars['Int']>>;
  productLimit_lt?: Maybe<Scalars['Int']>;
  productLimit_lte?: Maybe<Scalars['Int']>;
  productLimit_gt?: Maybe<Scalars['Int']>;
  productLimit_gte?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  orderLimit_not?: Maybe<Scalars['Int']>;
  orderLimit_in?: Maybe<Array<Scalars['Int']>>;
  orderLimit_not_in?: Maybe<Array<Scalars['Int']>>;
  orderLimit_lt?: Maybe<Scalars['Int']>;
  orderLimit_lte?: Maybe<Scalars['Int']>;
  orderLimit_gt?: Maybe<Scalars['Int']>;
  orderLimit_gte?: Maybe<Scalars['Int']>;
  hasCollectionSupport?: Maybe<Scalars['Boolean']>;
  hasCollectionSupport_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<MerchantPlanWhereInput>>;
  OR?: Maybe<Array<MerchantPlanWhereInput>>;
  NOT?: Maybe<Array<MerchantPlanWhereInput>>;
};

export type MerchantPlanWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type MerchantPreviousValues = {
  __typename?: 'MerchantPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  slug: Scalars['String'];
  timezone: Scalars['String'];
  regionIso?: Maybe<Scalars['String']>;
  currency: Currency;
  biography?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  subscriptionTrialEndAt?: Maybe<Scalars['DateTime']>;
  canEditFloomDeliveryPricing?: Maybe<Scalars['Boolean']>;
  isDemoAccount: Scalars['Boolean'];
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantRecurringPayment = {
  __typename?: 'MerchantRecurringPayment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  creditTermsUpdatedBy?: Maybe<User>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionPrice?: Maybe<Scalars['Float']>;
  status: SubscriptionStatus;
  trialLength: Scalars['Int'];
  trialExpiresAt?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt?: Maybe<Scalars['DateTime']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  wholesaleTermLength: Scalars['Int'];
  wholesaleCreditLimit?: Maybe<Scalars['Float']>;
  merchant?: Maybe<Merchant>;
};

export type MerchantRecurringPaymentConnection = {
  __typename?: 'MerchantRecurringPaymentConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MerchantRecurringPaymentEdge>>;
  aggregate: AggregateMerchantRecurringPayment;
};

export type MerchantRecurringPaymentCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionPrice?: Maybe<Scalars['Float']>;
  status?: Maybe<SubscriptionStatus>;
  trialLength: Scalars['Int'];
  trialExpiresAt?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt?: Maybe<Scalars['DateTime']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  merchant?: Maybe<MerchantCreateOneWithoutSubscriptionInput>;
};

export type MerchantRecurringPaymentCreateOneWithoutMerchantInput = {
  create?: Maybe<MerchantRecurringPaymentCreateWithoutMerchantInput>;
  connect?: Maybe<MerchantRecurringPaymentWhereUniqueInput>;
};

export type MerchantRecurringPaymentCreateWithoutMerchantInput = {
  stripeSubscriptionPrice?: Maybe<Scalars['Float']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  trialLength: Scalars['Int'];
};

export type MerchantRecurringPaymentEdge = {
  __typename?: 'MerchantRecurringPaymentEdge';
  node: MerchantRecurringPayment;
  cursor: Scalars['String'];
};

export enum MerchantRecurringPaymentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ExpiresAtAsc = 'expiresAt_ASC',
  ExpiresAtDesc = 'expiresAt_DESC',
  TrialEndsAtAsc = 'trialEndsAt_ASC',
  TrialEndsAtDesc = 'trialEndsAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StripeSubscriptionIdAsc = 'stripeSubscriptionId_ASC',
  StripeSubscriptionIdDesc = 'stripeSubscriptionId_DESC',
  StripeSubscriptionCustomerIdAsc = 'stripeSubscriptionCustomerId_ASC',
  StripeSubscriptionCustomerIdDesc = 'stripeSubscriptionCustomerId_DESC',
  StripeSubscriptionPriceAsc = 'stripeSubscriptionPrice_ASC',
  StripeSubscriptionPriceDesc = 'stripeSubscriptionPrice_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TrialLengthAsc = 'trialLength_ASC',
  TrialLengthDesc = 'trialLength_DESC',
  TrialExpiresAtAsc = 'trialExpiresAt_ASC',
  TrialExpiresAtDesc = 'trialExpiresAt_DESC',
  CurrentPeriodStartsAtAsc = 'currentPeriodStartsAt_ASC',
  CurrentPeriodStartsAtDesc = 'currentPeriodStartsAt_DESC',
  CurrentPeriodEndsAtAsc = 'currentPeriodEndsAt_ASC',
  CurrentPeriodEndsAtDesc = 'currentPeriodEndsAt_DESC',
  ProductLimitAsc = 'productLimit_ASC',
  ProductLimitDesc = 'productLimit_DESC',
  OrderLimitAsc = 'orderLimit_ASC',
  OrderLimitDesc = 'orderLimit_DESC',
  WholesaleTermLengthAsc = 'wholesaleTermLength_ASC',
  WholesaleTermLengthDesc = 'wholesaleTermLength_DESC',
  WholesaleCreditLimitAsc = 'wholesaleCreditLimit_ASC',
  WholesaleCreditLimitDesc = 'wholesaleCreditLimit_DESC'
}

export type MerchantRecurringPaymentPreviousValues = {
  __typename?: 'MerchantRecurringPaymentPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  creditTermsUpdatedBy?: Maybe<User>;
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionPrice?: Maybe<Scalars['Float']>;
  status?: Maybe<SubscriptionStatus>;
  trialLength: Scalars['Int'];
  trialExpiresAt?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt?: Maybe<Scalars['DateTime']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  wholesaleTermLength: Scalars['Int'];
  wholesaleCreditLimit?: Maybe<Scalars['Float']>;
};

export type MerchantRecurringPaymentSubscriptionPayload = {
  __typename?: 'MerchantRecurringPaymentSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<MerchantRecurringPayment>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<MerchantRecurringPaymentPreviousValues>;
};

export type MerchantRecurringPaymentSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<MerchantRecurringPaymentWhereInput>;
  AND?: Maybe<Array<MerchantRecurringPaymentSubscriptionWhereInput>>;
  OR?: Maybe<Array<MerchantRecurringPaymentSubscriptionWhereInput>>;
  NOT?: Maybe<Array<MerchantRecurringPaymentSubscriptionWhereInput>>;
};

export type MerchantRecurringPaymentUpdateInput = {
  expiresAt?: Maybe<Scalars['DateTime']>;
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionPrice?: Maybe<Scalars['Float']>;
  status?: Maybe<SubscriptionStatus>;
  trialLength?: Maybe<Scalars['Int']>;
  trialExpiresAt?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt?: Maybe<Scalars['DateTime']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  merchant?: Maybe<MerchantUpdateOneWithoutSubscriptionInput>;
};

export type MerchantRecurringPaymentUpdateManyMutationInput = {
  expiresAt?: Maybe<Scalars['DateTime']>;
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionPrice?: Maybe<Scalars['Float']>;
  status?: Maybe<SubscriptionStatus>;
  trialLength?: Maybe<Scalars['Int']>;
  trialExpiresAt?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt?: Maybe<Scalars['DateTime']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  currentPeriodExpiresAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantRecurringPaymentUpdateOneWithoutMerchantInput = {
  create?: Maybe<MerchantRecurringPaymentCreateWithoutMerchantInput>;
  update?: Maybe<MerchantRecurringPaymentUpdateWithoutMerchantDataInput>;
  upsert?: Maybe<MerchantRecurringPaymentUpsertWithoutMerchantInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MerchantRecurringPaymentWhereUniqueInput>;
};

export type MerchantRecurringPaymentUpdateWithoutMerchantDataInput = {
  expiresAt?: Maybe<Scalars['DateTime']>;
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionPrice?: Maybe<Scalars['Float']>;
  status?: Maybe<SubscriptionStatus>;
  trialLength?: Maybe<Scalars['Int']>;
  trialExpiresAt?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt?: Maybe<Scalars['DateTime']>;
  productLimit?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  currentPeriodExpiresAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantRecurringPaymentUpsertWithoutMerchantInput = {
  update: MerchantRecurringPaymentUpdateWithoutMerchantDataInput;
  create: MerchantRecurringPaymentCreateWithoutMerchantInput;
};

export type MerchantRecurringPaymentWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  expiresAt_not?: Maybe<Scalars['DateTime']>;
  expiresAt_in?: Maybe<Array<Scalars['DateTime']>>;
  expiresAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  expiresAt_lt?: Maybe<Scalars['DateTime']>;
  expiresAt_lte?: Maybe<Scalars['DateTime']>;
  expiresAt_gt?: Maybe<Scalars['DateTime']>;
  expiresAt_gte?: Maybe<Scalars['DateTime']>;
  trialEndsAt?: Maybe<Scalars['DateTime']>;
  trialEndsAt_not?: Maybe<Scalars['DateTime']>;
  trialEndsAt_in?: Maybe<Array<Scalars['DateTime']>>;
  trialEndsAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trialEndsAt_lt?: Maybe<Scalars['DateTime']>;
  trialEndsAt_lte?: Maybe<Scalars['DateTime']>;
  trialEndsAt_gt?: Maybe<Scalars['DateTime']>;
  trialEndsAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not?: Maybe<Scalars['String']>;
  stripeSubscriptionId_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionId_lt?: Maybe<Scalars['String']>;
  stripeSubscriptionId_lte?: Maybe<Scalars['String']>;
  stripeSubscriptionId_gt?: Maybe<Scalars['String']>;
  stripeSubscriptionId_gte?: Maybe<Scalars['String']>;
  stripeSubscriptionId_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionId_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId_ends_with?: Maybe<Scalars['String']>;
  stripeSubscriptionId_not_ends_with?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_not?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionCustomerId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeSubscriptionCustomerId_lt?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_lte?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_gt?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_gte?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_not_contains?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_not_starts_with?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_ends_with?: Maybe<Scalars['String']>;
  stripeSubscriptionCustomerId_not_ends_with?: Maybe<Scalars['String']>;
  stripeSubscriptionPrice?: Maybe<Scalars['Float']>;
  stripeSubscriptionPrice_not?: Maybe<Scalars['Float']>;
  stripeSubscriptionPrice_in?: Maybe<Array<Scalars['Float']>>;
  stripeSubscriptionPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  stripeSubscriptionPrice_lt?: Maybe<Scalars['Float']>;
  stripeSubscriptionPrice_lte?: Maybe<Scalars['Float']>;
  stripeSubscriptionPrice_gt?: Maybe<Scalars['Float']>;
  stripeSubscriptionPrice_gte?: Maybe<Scalars['Float']>;
  status?: Maybe<SubscriptionStatus>;
  status_not?: Maybe<SubscriptionStatus>;
  status_in?: Maybe<Array<SubscriptionStatus>>;
  status_not_in?: Maybe<Array<SubscriptionStatus>>;
  status_lt?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  trialLength?: Maybe<Scalars['Int']>;
  trialLength_not?: Maybe<Scalars['Int']>;
  trialLength_in?: Maybe<Array<Scalars['Int']>>;
  trialLength_not_in?: Maybe<Array<Scalars['Int']>>;
  trialLength_lt?: Maybe<Scalars['Int']>;
  trialLength_lte?: Maybe<Scalars['Int']>;
  trialLength_gt?: Maybe<Scalars['Int']>;
  trialLength_gte?: Maybe<Scalars['Int']>;
  trialExpiresAt?: Maybe<Scalars['DateTime']>;
  trialExpiresAt_not?: Maybe<Scalars['DateTime']>;
  trialExpiresAt_in?: Maybe<Array<Scalars['DateTime']>>;
  trialExpiresAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  trialExpiresAt_lt?: Maybe<Scalars['DateTime']>;
  trialExpiresAt_lte?: Maybe<Scalars['DateTime']>;
  trialExpiresAt_gt?: Maybe<Scalars['DateTime']>;
  trialExpiresAt_gte?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt_not?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPeriodStartsAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPeriodStartsAt_lt?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt_lte?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt_gt?: Maybe<Scalars['DateTime']>;
  currentPeriodStartsAt_gte?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt_not?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPeriodEndsAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  currentPeriodEndsAt_lt?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt_lte?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt_gt?: Maybe<Scalars['DateTime']>;
  currentPeriodEndsAt_gte?: Maybe<Scalars['DateTime']>;
  productLimit?: Maybe<Scalars['Int']>;
  productLimit_not?: Maybe<Scalars['Int']>;
  productLimit_in?: Maybe<Array<Scalars['Int']>>;
  productLimit_not_in?: Maybe<Array<Scalars['Int']>>;
  productLimit_lt?: Maybe<Scalars['Int']>;
  productLimit_lte?: Maybe<Scalars['Int']>;
  productLimit_gt?: Maybe<Scalars['Int']>;
  productLimit_gte?: Maybe<Scalars['Int']>;
  orderLimit?: Maybe<Scalars['Int']>;
  orderLimit_not?: Maybe<Scalars['Int']>;
  orderLimit_in?: Maybe<Array<Scalars['Int']>>;
  orderLimit_not_in?: Maybe<Array<Scalars['Int']>>;
  orderLimit_lt?: Maybe<Scalars['Int']>;
  orderLimit_lte?: Maybe<Scalars['Int']>;
  orderLimit_gt?: Maybe<Scalars['Int']>;
  orderLimit_gte?: Maybe<Scalars['Int']>;
  wholesaleTermLength?: Maybe<Scalars['Int']>;
  wholesaleTermLength_not?: Maybe<Scalars['Int']>;
  wholesaleTermLength_in?: Maybe<Array<Scalars['Int']>>;
  wholesaleTermLength_not_in?: Maybe<Array<Scalars['Int']>>;
  wholesaleTermLength_lt?: Maybe<Scalars['Int']>;
  wholesaleTermLength_lte?: Maybe<Scalars['Int']>;
  wholesaleTermLength_gt?: Maybe<Scalars['Int']>;
  wholesaleTermLength_gte?: Maybe<Scalars['Int']>;
  wholesaleCreditLimit?: Maybe<Scalars['Float']>;
  wholesaleCreditLimit_not?: Maybe<Scalars['Float']>;
  wholesaleCreditLimit_in?: Maybe<Array<Scalars['Float']>>;
  wholesaleCreditLimit_not_in?: Maybe<Array<Scalars['Float']>>;
  wholesaleCreditLimit_lt?: Maybe<Scalars['Float']>;
  wholesaleCreditLimit_lte?: Maybe<Scalars['Float']>;
  wholesaleCreditLimit_gt?: Maybe<Scalars['Float']>;
  wholesaleCreditLimit_gte?: Maybe<Scalars['Float']>;
  merchant?: Maybe<MerchantWhereInput>;
  AND?: Maybe<Array<MerchantRecurringPaymentWhereInput>>;
  OR?: Maybe<Array<MerchantRecurringPaymentWhereInput>>;
  NOT?: Maybe<Array<MerchantRecurringPaymentWhereInput>>;
};

export type MerchantRecurringPaymentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum MerchantStage {
  Created = 'CREATED',
  InSetup = 'IN_SETUP',
  New = 'NEW',
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Dormant = 'DORMANT',
  Churn = 'CHURN',
  Unassigned = 'UNASSIGNED'
}

export type MerchantSubscriptionInput = {
  stripe_token: Scalars['String'];
  last_4_digits: Scalars['String'];
};

export type MerchantSubscriptionPayload = {
  __typename?: 'MerchantSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Merchant>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<MerchantPreviousValues>;
};

export type MerchantSubscriptionResponse = {
  __typename?: 'MerchantSubscriptionResponse';
  suscription_id?: Maybe<Scalars['String']>;
};

export type MerchantSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<MerchantWhereInput>;
  AND?: Maybe<Array<MerchantSubscriptionWhereInput>>;
  OR?: Maybe<Array<MerchantSubscriptionWhereInput>>;
  NOT?: Maybe<Array<MerchantSubscriptionWhereInput>>;
};

export type MerchantSupplierIntegration = {
  __typename?: 'MerchantSupplierIntegration';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  merchant: Merchant;
  supplier: Supplier;
  authentication?: Maybe<IntegrationAuth>;
  active: Scalars['Boolean'];
};

export type MerchantSupplierIntegrationConnection = {
  __typename?: 'MerchantSupplierIntegrationConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MerchantSupplierIntegrationEdge>>;
  aggregate: AggregateMerchantSupplierIntegration;
};

export type MerchantSupplierIntegrationCreateInput = {
  supplier: SupplierConnectOneInput;
  merchant: MerchantConnectOneInput;
  details: MerchantSupplierIntegrationDetails;
  active: Scalars['Boolean'];
};

export type MerchantSupplierIntegrationDetails = {
  externalId: Scalars['String'];
};

export type MerchantSupplierIntegrationEdge = {
  __typename?: 'MerchantSupplierIntegrationEdge';
  node: MerchantSupplierIntegration;
  cursor: Scalars['String'];
};

export enum MerchantSupplierIntegrationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type MerchantSupplierIntegrationPreviousValues = {
  __typename?: 'MerchantSupplierIntegrationPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type MerchantSupplierIntegrationSubscriptionPayload = {
  __typename?: 'MerchantSupplierIntegrationSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<MerchantSupplierIntegration>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<MerchantSupplierIntegrationPreviousValues>;
};

export type MerchantSupplierIntegrationSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<MerchantSupplierIntegrationWhereInput>;
  AND?: Maybe<Array<MerchantSupplierIntegrationSubscriptionWhereInput>>;
  OR?: Maybe<Array<MerchantSupplierIntegrationSubscriptionWhereInput>>;
  NOT?: Maybe<Array<MerchantSupplierIntegrationSubscriptionWhereInput>>;
};

export type MerchantSupplierIntegrationUpdateInput = {
  details?: Maybe<MerchantSupplierIntegrationDetails>;
  active?: Maybe<Scalars['Boolean']>;
};

export type MerchantSupplierIntegrationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<MerchantWhereInput>;
  supplier?: Maybe<SupplierWhereInput>;
  authentication?: Maybe<IntegrationAuthWhereInput>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<MerchantSupplierIntegrationWhereInput>>;
  OR?: Maybe<Array<MerchantSupplierIntegrationWhereInput>>;
  NOT?: Maybe<Array<MerchantSupplierIntegrationWhereInput>>;
};

export type MerchantSupplierIntegrationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customSiteConfig?: Maybe<CustomSiteConfigUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  shops?: Maybe<ShopUpdateManyInput>;
  timezone?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  biography?: Maybe<Scalars['String']>;
  stage?: Maybe<MerchantStage>;
  address?: Maybe<AddressUpdateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigUpdateManyInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressUpdateOneInput>;
  holidays?: Maybe<MerchantHolidayUpdateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserUpdateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subscription?: Maybe<MerchantRecurringPaymentUpdateOneWithoutMerchantInput>;
  plan?: Maybe<MerchantPlanUpdateOneInput>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  canViewNationwide?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customSiteConfig?: Maybe<CustomSiteConfigUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  shops?: Maybe<ShopUpdateManyInput>;
  timezone?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUpdateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigUpdateManyInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressUpdateOneInput>;
  holidays?: Maybe<MerchantHolidayUpdateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserUpdateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subscription?: Maybe<MerchantRecurringPaymentUpdateOneWithoutMerchantInput>;
  plan?: Maybe<MerchantPlanUpdateOneInput>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  listsActive?: Maybe<Scalars['Boolean']>;
  conversationsActive?: Maybe<Scalars['Boolean']>;
  promotedListsActive?: Maybe<Scalars['Boolean']>;
  promotedActive?: Maybe<Scalars['Boolean']>;
  sameDayOrderPauseStatus?: Maybe<Scalars['Int']>;
  sameDayOrderPauseLimit?: Maybe<Scalars['Int']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  canViewNationwide?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  biography?: Maybe<Scalars['String']>;
  stage?: Maybe<MerchantStage>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantUpdateOneInput = {
  create?: Maybe<MerchantCreateInput>;
  update?: Maybe<MerchantUpdateDataInput>;
  upsert?: Maybe<MerchantUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantUpdateOneRequiredInput = {
  create?: Maybe<MerchantCreateInput>;
  update?: Maybe<MerchantUpdateDataInput>;
  upsert?: Maybe<MerchantUpsertNestedInput>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantUpdateOneWithoutCreatedByInput = {
  create?: Maybe<MerchantCreateWithoutCreatedByInput>;
  update?: Maybe<MerchantUpdateWithoutCreatedByDataInput>;
  upsert?: Maybe<MerchantUpsertWithoutCreatedByInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantUpdateOneWithoutHolidaysInput = {
  create?: Maybe<MerchantCreateWithoutHolidaysInput>;
  update?: Maybe<MerchantUpdateWithoutHolidaysDataInput>;
  upsert?: Maybe<MerchantUpsertWithoutHolidaysInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantUpdateOneWithoutSubscriptionInput = {
  create?: Maybe<MerchantCreateWithoutSubscriptionInput>;
  update?: Maybe<MerchantUpdateWithoutSubscriptionDataInput>;
  upsert?: Maybe<MerchantUpsertWithoutSubscriptionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type MerchantUpdateWithoutCreatedByDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customSiteConfig?: Maybe<CustomSiteConfigUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  shops?: Maybe<ShopUpdateManyInput>;
  timezone?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUpdateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigUpdateManyInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressUpdateOneInput>;
  holidays?: Maybe<MerchantHolidayUpdateManyWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subscription?: Maybe<MerchantRecurringPaymentUpdateOneWithoutMerchantInput>;
  plan?: Maybe<MerchantPlanUpdateOneInput>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  listsActive?: Maybe<Scalars['Boolean']>;
  conversationsActive?: Maybe<Scalars['Boolean']>;
  promotedListsActive?: Maybe<Scalars['Boolean']>;
  promotedActive?: Maybe<Scalars['Boolean']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantUpdateWithoutHolidaysDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customSiteConfig?: Maybe<CustomSiteConfigUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  shops?: Maybe<ShopUpdateManyInput>;
  timezone?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUpdateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigUpdateManyInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressUpdateOneInput>;
  createdBy?: Maybe<UserUpdateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subscription?: Maybe<MerchantRecurringPaymentUpdateOneWithoutMerchantInput>;
  plan?: Maybe<MerchantPlanUpdateOneInput>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  listsActive?: Maybe<Scalars['Boolean']>;
  conversationsActive?: Maybe<Scalars['Boolean']>;
  promotedListsActive?: Maybe<Scalars['Boolean']>;
  promotedActive?: Maybe<Scalars['Boolean']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantUpdateWithoutPaymentMethodDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customSiteConfig?: Maybe<CustomSiteConfigUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  shops?: Maybe<ShopUpdateManyInput>;
  timezone?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUpdateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigUpdateManyInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressUpdateOneInput>;
  holidays?: Maybe<MerchantHolidayUpdateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserUpdateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subscription?: Maybe<MerchantRecurringPaymentUpdateOneWithoutMerchantInput>;
  plan?: Maybe<MerchantPlanUpdateOneInput>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  listsActive?: Maybe<Scalars['Boolean']>;
  conversationsActive?: Maybe<Scalars['Boolean']>;
  promotedListsActive?: Maybe<Scalars['Boolean']>;
  promotedActive?: Maybe<Scalars['Boolean']>;
  sameDayOrderPauseStatus?: Maybe<Scalars['Int']>;
  sameDayOrderPauseLimit?: Maybe<Scalars['Int']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantUpdateWithoutPaymentsDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customSiteConfig?: Maybe<CustomSiteConfigUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  shops?: Maybe<ShopUpdateManyInput>;
  timezone?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUpdateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigUpdateManyInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressUpdateOneInput>;
  holidays?: Maybe<MerchantHolidayUpdateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserUpdateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subscription?: Maybe<MerchantRecurringPaymentUpdateOneWithoutMerchantInput>;
  plan?: Maybe<MerchantPlanUpdateOneInput>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  listsActive?: Maybe<Scalars['Boolean']>;
  conversationsActive?: Maybe<Scalars['Boolean']>;
  promotedListsActive?: Maybe<Scalars['Boolean']>;
  promotedActive?: Maybe<Scalars['Boolean']>;
  sameDayOrderPauseStatus?: Maybe<Scalars['Int']>;
  sameDayOrderPauseLimit?: Maybe<Scalars['Int']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantUpdateWithoutSubscriptionDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customSiteConfig?: Maybe<CustomSiteConfigUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  shops?: Maybe<ShopUpdateManyInput>;
  timezone?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUpdateOneInput>;
  wholesaleDeliveryConfigs?: Maybe<WholesaleDeliveryConfigUpdateManyInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressUpdateOneInput>;
  holidays?: Maybe<MerchantHolidayUpdateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserUpdateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<MerchantPlanUpdateOneInput>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  listsActive?: Maybe<Scalars['Boolean']>;
  conversationsActive?: Maybe<Scalars['Boolean']>;
  promotedListsActive?: Maybe<Scalars['Boolean']>;
  promotedActive?: Maybe<Scalars['Boolean']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantUpdateWithoutWholesaleDeliveryConfigsDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  customSiteConfig?: Maybe<CustomSiteConfigUpdateOneInput>;
  title?: Maybe<Scalars['String']>;
  shops?: Maybe<ShopUpdateManyInput>;
  timezone?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  biography?: Maybe<Scalars['String']>;
  address?: Maybe<AddressUpdateOneInput>;
  companyName?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressUpdateOneInput>;
  holidays?: Maybe<MerchantHolidayUpdateManyWithoutMerchantInput>;
  createdBy?: Maybe<UserUpdateOneWithoutMerchantInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subscription?: Maybe<MerchantRecurringPaymentUpdateOneWithoutMerchantInput>;
  plan?: Maybe<MerchantPlanUpdateOneInput>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  listsActive?: Maybe<Scalars['Boolean']>;
  conversationsActive?: Maybe<Scalars['Boolean']>;
  promotedListsActive?: Maybe<Scalars['Boolean']>;
  promotedActive?: Maybe<Scalars['Boolean']>;
  sameDayOrderPauseStatus?: Maybe<Scalars['Int']>;
  sameDayOrderPauseLimit?: Maybe<Scalars['Int']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  commissionRate?: Maybe<Scalars['Float']>;
};

export type MerchantUpsertNestedInput = {
  update: MerchantUpdateDataInput;
  create: MerchantCreateInput;
};

export type MerchantUpsertWithoutCreatedByInput = {
  update: MerchantUpdateWithoutCreatedByDataInput;
  create: MerchantCreateWithoutCreatedByInput;
};

export type MerchantUpsertWithoutHolidaysInput = {
  update: MerchantUpdateWithoutHolidaysDataInput;
  create: MerchantCreateWithoutHolidaysInput;
};

export type MerchantUpsertWithoutPaymentMethodInput = {
  update: MerchantUpdateWithoutPaymentMethodDataInput;
  create: MerchantCreateWithoutPaymentMethodInput;
};

export type MerchantUpsertWithoutPaymentsInput = {
  update: MerchantUpdateWithoutPaymentsDataInput;
  create: MerchantCreateWithoutPaymentsInput;
};

export type MerchantUpsertWithoutSubscriptionInput = {
  update: MerchantUpdateWithoutSubscriptionDataInput;
  create: MerchantCreateWithoutSubscriptionInput;
};

export type MerchantUpsertWithoutWholesaleDeliveryConfigsInput = {
  update: MerchantUpdateWithoutWholesaleDeliveryConfigsDataInput;
  create: MerchantCreateWithoutWholesaleDeliveryConfigsInput;
};

export type MerchantWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  publicId_not?: Maybe<Scalars['String']>;
  publicId_in?: Maybe<Array<Scalars['String']>>;
  publicId_not_in?: Maybe<Array<Scalars['String']>>;
  publicId_lt?: Maybe<Scalars['String']>;
  publicId_lte?: Maybe<Scalars['String']>;
  publicId_gt?: Maybe<Scalars['String']>;
  publicId_gte?: Maybe<Scalars['String']>;
  publicId_contains?: Maybe<Scalars['String']>;
  publicId_not_contains?: Maybe<Scalars['String']>;
  publicId_starts_with?: Maybe<Scalars['String']>;
  publicId_not_starts_with?: Maybe<Scalars['String']>;
  publicId_ends_with?: Maybe<Scalars['String']>;
  publicId_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  customSiteConfig?: Maybe<CustomSiteConfigWhereInput>;
  stripeConnectAccountId?: Maybe<Scalars['String']>;
  stripeConnectAccountId_not?: Maybe<Scalars['String']>;
  stripeConnectAccountId_in?: Maybe<Array<Scalars['String']>>;
  stripeConnectAccountId_not_in?: Maybe<Array<Scalars['String']>>;
  stripeConnectAccountId_lt?: Maybe<Scalars['String']>;
  stripeConnectAccountId_lte?: Maybe<Scalars['String']>;
  stripeConnectAccountId_gt?: Maybe<Scalars['String']>;
  stripeConnectAccountId_gte?: Maybe<Scalars['String']>;
  stripeConnectAccountId_contains?: Maybe<Scalars['String']>;
  stripeConnectAccountId_not_contains?: Maybe<Scalars['String']>;
  stripeConnectAccountId_starts_with?: Maybe<Scalars['String']>;
  stripeConnectAccountId_not_starts_with?: Maybe<Scalars['String']>;
  stripeConnectAccountId_ends_with?: Maybe<Scalars['String']>;
  stripeConnectAccountId_not_ends_with?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  shops_every?: Maybe<ShopWhereInput>;
  shops_some?: Maybe<ShopWhereInput>;
  shops_none?: Maybe<ShopWhereInput>;
  timezone?: Maybe<Scalars['String']>;
  timezone_not?: Maybe<Scalars['String']>;
  timezone_in?: Maybe<Array<Scalars['String']>>;
  timezone_not_in?: Maybe<Array<Scalars['String']>>;
  timezone_lt?: Maybe<Scalars['String']>;
  timezone_lte?: Maybe<Scalars['String']>;
  timezone_gt?: Maybe<Scalars['String']>;
  timezone_gte?: Maybe<Scalars['String']>;
  timezone_contains?: Maybe<Scalars['String']>;
  timezone_not_contains?: Maybe<Scalars['String']>;
  timezone_starts_with?: Maybe<Scalars['String']>;
  timezone_not_starts_with?: Maybe<Scalars['String']>;
  timezone_ends_with?: Maybe<Scalars['String']>;
  timezone_not_ends_with?: Maybe<Scalars['String']>;
  regionIso?: Maybe<Scalars['String']>;
  regionIso_not?: Maybe<Scalars['String']>;
  regionIso_in?: Maybe<Array<Scalars['String']>>;
  regionIso_not_in?: Maybe<Array<Scalars['String']>>;
  regionIso_lt?: Maybe<Scalars['String']>;
  regionIso_lte?: Maybe<Scalars['String']>;
  regionIso_gt?: Maybe<Scalars['String']>;
  regionIso_gte?: Maybe<Scalars['String']>;
  regionIso_contains?: Maybe<Scalars['String']>;
  regionIso_not_contains?: Maybe<Scalars['String']>;
  regionIso_starts_with?: Maybe<Scalars['String']>;
  regionIso_not_starts_with?: Maybe<Scalars['String']>;
  regionIso_ends_with?: Maybe<Scalars['String']>;
  regionIso_not_ends_with?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  currency_not?: Maybe<Currency>;
  currency_in?: Maybe<Array<Currency>>;
  currency_not_in?: Maybe<Array<Currency>>;
  biography?: Maybe<Scalars['String']>;
  biography_not?: Maybe<Scalars['String']>;
  biography_in?: Maybe<Array<Scalars['String']>>;
  biography_not_in?: Maybe<Array<Scalars['String']>>;
  biography_lt?: Maybe<Scalars['String']>;
  biography_lte?: Maybe<Scalars['String']>;
  biography_gt?: Maybe<Scalars['String']>;
  biography_gte?: Maybe<Scalars['String']>;
  biography_contains?: Maybe<Scalars['String']>;
  biography_not_contains?: Maybe<Scalars['String']>;
  biography_starts_with?: Maybe<Scalars['String']>;
  biography_not_starts_with?: Maybe<Scalars['String']>;
  biography_ends_with?: Maybe<Scalars['String']>;
  biography_not_ends_with?: Maybe<Scalars['String']>;
  stage?: Maybe<MerchantStage>;
  stage_not?: Maybe<MerchantStage>;
  stage_in?: Maybe<Array<MerchantStage>>;
  stage_not_in?: Maybe<Array<MerchantStage>>;
  address?: Maybe<AddressWhereInput>;
  wholesaleDeliveryConfigs_every?: Maybe<WholesaleDeliveryConfigWhereInput>;
  wholesaleDeliveryConfigs_some?: Maybe<WholesaleDeliveryConfigWhereInput>;
  wholesaleDeliveryConfigs_none?: Maybe<WholesaleDeliveryConfigWhereInput>;
  companyName?: Maybe<Scalars['String']>;
  companyName_not?: Maybe<Scalars['String']>;
  companyName_in?: Maybe<Array<Scalars['String']>>;
  companyName_not_in?: Maybe<Array<Scalars['String']>>;
  companyName_lt?: Maybe<Scalars['String']>;
  companyName_lte?: Maybe<Scalars['String']>;
  companyName_gt?: Maybe<Scalars['String']>;
  companyName_gte?: Maybe<Scalars['String']>;
  companyName_contains?: Maybe<Scalars['String']>;
  companyName_not_contains?: Maybe<Scalars['String']>;
  companyName_starts_with?: Maybe<Scalars['String']>;
  companyName_not_starts_with?: Maybe<Scalars['String']>;
  companyName_ends_with?: Maybe<Scalars['String']>;
  companyName_not_ends_with?: Maybe<Scalars['String']>;
  tradingNo?: Maybe<Scalars['String']>;
  tradingNo_not?: Maybe<Scalars['String']>;
  tradingNo_in?: Maybe<Array<Scalars['String']>>;
  tradingNo_not_in?: Maybe<Array<Scalars['String']>>;
  tradingNo_lt?: Maybe<Scalars['String']>;
  tradingNo_lte?: Maybe<Scalars['String']>;
  tradingNo_gt?: Maybe<Scalars['String']>;
  tradingNo_gte?: Maybe<Scalars['String']>;
  tradingNo_contains?: Maybe<Scalars['String']>;
  tradingNo_not_contains?: Maybe<Scalars['String']>;
  tradingNo_starts_with?: Maybe<Scalars['String']>;
  tradingNo_not_starts_with?: Maybe<Scalars['String']>;
  tradingNo_ends_with?: Maybe<Scalars['String']>;
  tradingNo_not_ends_with?: Maybe<Scalars['String']>;
  registeredAddress?: Maybe<AddressWhereInput>;
  holidays_every?: Maybe<MerchantHolidayWhereInput>;
  holidays_some?: Maybe<MerchantHolidayWhereInput>;
  holidays_none?: Maybe<MerchantHolidayWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  plan?: Maybe<MerchantPlanWhereInput>;
  subscription?: Maybe<MerchantRecurringPaymentWhereInput>;
  paymentMethod?: Maybe<PaymentMethodWhereInput>;
  payments_every?: Maybe<PaymentWhereInput>;
  payments_some?: Maybe<PaymentWhereInput>;
  payments_none?: Maybe<PaymentWhereInput>;
  subscriptionTrialEndAt?: Maybe<Scalars['DateTime']>;
  subscriptionTrialEndAt_not?: Maybe<Scalars['DateTime']>;
  subscriptionTrialEndAt_in?: Maybe<Array<Scalars['DateTime']>>;
  subscriptionTrialEndAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  subscriptionTrialEndAt_lt?: Maybe<Scalars['DateTime']>;
  subscriptionTrialEndAt_lte?: Maybe<Scalars['DateTime']>;
  subscriptionTrialEndAt_gt?: Maybe<Scalars['DateTime']>;
  subscriptionTrialEndAt_gte?: Maybe<Scalars['DateTime']>;
  courierActive?: Maybe<Scalars['Boolean']>;
  courierActive_not?: Maybe<Scalars['Boolean']>;
  wholesaleActive?: Maybe<Scalars['Boolean']>;
  wholesaleActive_not?: Maybe<Scalars['Boolean']>;
  acceptedTermsAt?: Maybe<Scalars['DateTime']>;
  acceptedTermsAt_not?: Maybe<Scalars['DateTime']>;
  acceptedTermsAt_in?: Maybe<Array<Scalars['DateTime']>>;
  acceptedTermsAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  acceptedTermsAt_lt?: Maybe<Scalars['DateTime']>;
  acceptedTermsAt_lte?: Maybe<Scalars['DateTime']>;
  acceptedTermsAt_gt?: Maybe<Scalars['DateTime']>;
  acceptedTermsAt_gte?: Maybe<Scalars['DateTime']>;
  isNewsletterRegistered?: Maybe<Scalars['Boolean']>;
  isNewsletterRegistered_not?: Maybe<Scalars['Boolean']>;
  productsDisabledAt?: Maybe<Scalars['DateTime']>;
  productsDisabledAt_not?: Maybe<Scalars['DateTime']>;
  productsDisabledAt_in?: Maybe<Array<Scalars['DateTime']>>;
  productsDisabledAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  productsDisabledAt_lt?: Maybe<Scalars['DateTime']>;
  productsDisabledAt_lte?: Maybe<Scalars['DateTime']>;
  productsDisabledAt_gt?: Maybe<Scalars['DateTime']>;
  productsDisabledAt_gte?: Maybe<Scalars['DateTime']>;
  areProductsDisabledOnFloom?: Maybe<Scalars['Boolean']>;
  areProductsDisabledOnFloom_not?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['String']>;
  searchable_not?: Maybe<Scalars['String']>;
  searchable_in?: Maybe<Array<Scalars['String']>>;
  searchable_not_in?: Maybe<Array<Scalars['String']>>;
  searchable_lt?: Maybe<Scalars['String']>;
  searchable_lte?: Maybe<Scalars['String']>;
  searchable_gt?: Maybe<Scalars['String']>;
  searchable_gte?: Maybe<Scalars['String']>;
  searchable_contains?: Maybe<Scalars['String']>;
  searchable_not_contains?: Maybe<Scalars['String']>;
  searchable_starts_with?: Maybe<Scalars['String']>;
  searchable_not_starts_with?: Maybe<Scalars['String']>;
  searchable_ends_with?: Maybe<Scalars['String']>;
  searchable_not_ends_with?: Maybe<Scalars['String']>;
  invitedAt?: Maybe<Scalars['DateTime']>;
  invitedAt_not?: Maybe<Scalars['DateTime']>;
  invitedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  invitedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  invitedAt_lt?: Maybe<Scalars['DateTime']>;
  invitedAt_lte?: Maybe<Scalars['DateTime']>;
  invitedAt_gt?: Maybe<Scalars['DateTime']>;
  invitedAt_gte?: Maybe<Scalars['DateTime']>;
  canEditFloomDeliveryPricing?: Maybe<Scalars['Boolean']>;
  canEditFloomDeliveryPricing_not?: Maybe<Scalars['Boolean']>;
  isInternalAccount?: Maybe<Scalars['Boolean']>;
  isInternalAccount_not?: Maybe<Scalars['Boolean']>;
  isDemoAccount?: Maybe<Scalars['Boolean']>;
  isDemoAccount_not?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications?: Maybe<Scalars['Boolean']>;
  orderStatusCheckNotifications_not?: Maybe<Scalars['Boolean']>;
  newOrderNotifications?: Maybe<Scalars['Boolean']>;
  newOrderNotifications_not?: Maybe<Scalars['Boolean']>;
  reviewCount?: Maybe<Scalars['Int']>;
  reviewCount_not?: Maybe<Scalars['Int']>;
  reviewCount_in?: Maybe<Array<Scalars['Int']>>;
  reviewCount_not_in?: Maybe<Array<Scalars['Int']>>;
  reviewCount_lt?: Maybe<Scalars['Int']>;
  reviewCount_lte?: Maybe<Scalars['Int']>;
  reviewCount_gt?: Maybe<Scalars['Int']>;
  reviewCount_gte?: Maybe<Scalars['Int']>;
  reviewRating?: Maybe<Scalars['Float']>;
  reviewRating_not?: Maybe<Scalars['Float']>;
  reviewRating_in?: Maybe<Array<Scalars['Float']>>;
  reviewRating_not_in?: Maybe<Array<Scalars['Float']>>;
  reviewRating_lt?: Maybe<Scalars['Float']>;
  reviewRating_lte?: Maybe<Scalars['Float']>;
  reviewRating_gt?: Maybe<Scalars['Float']>;
  reviewRating_gte?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  commissionRate_not?: Maybe<Scalars['Float']>;
  commissionRate_in?: Maybe<Array<Scalars['Float']>>;
  commissionRate_not_in?: Maybe<Array<Scalars['Float']>>;
  commissionRate_lt?: Maybe<Scalars['Float']>;
  commissionRate_lte?: Maybe<Scalars['Float']>;
  commissionRate_gt?: Maybe<Scalars['Float']>;
  commissionRate_gte?: Maybe<Scalars['Float']>;
  AND?: Maybe<Array<MerchantWhereInput>>;
  OR?: Maybe<Array<MerchantWhereInput>>;
  NOT?: Maybe<Array<MerchantWhereInput>>;
};

export type MerchantWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type MerchantWholesaleSupplier = {
  __typename?: 'MerchantWholesaleSupplier';
  supplier?: Maybe<Supplier>;
  deliveryOption?: Maybe<SupplierDeliveryOption>;
  deliveryDates: Array<Maybe<DeliveryDateForSupplier>>;
};

export type MerchantWholesaleSuppliersWhereInput = {
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type MigrateLegacySupplierIntegrationsInput = {
  supplier?: Maybe<SupplierWhereUniqueInput>;
};

export type MigrateLegacySupplierIntegrationsResponse = {
  __typename?: 'MigrateLegacySupplierIntegrationsResponse';
  merchant: Merchant;
  integration: MerchantSupplierIntegration;
};

export type MigrateWholesaleOrderPaymentDataOutput = {
  __typename?: 'MigrateWholesaleOrderPaymentDataOutput';
  success?: Maybe<Array<Maybe<WholesaleOrder>>>;
  fail?: Maybe<Array<Maybe<WholesaleOrder>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  signUp: TokenResult;
  login: TokenResult;
  createOrderCustom: StripePrePaymentOrder;
  createOrderSearchToken: Scalars['String'];
  createOrderConsumer: FxOrderResponse;
  updateOrder: Order;
  indexOrders: IndexOrderResponse;
  deleteOrderItem: Order;
  updateUser?: Maybe<User>;
  upsertCollectionSellerProductConfig: CollectionSellerProductConfig;
  createProduct: Product;
  editProduct: Product;
  deleteProduct: Product;
  deleteProductVariation?: Maybe<ProductVariation>;
  createMediaLink?: Maybe<CreateMediaLinkOutput>;
  createFloomSnapLink?: Maybe<CreateMediaLinkOutput>;
  confirmMediaUpload?: Maybe<ConfirmMediaUploadOutput>;
  confirmFloomSnapUpload?: Maybe<ConfirmFloomSnapUploadOutput>;
  testValidation?: Maybe<MutationStubOut>;
  testMultiValidation?: Maybe<MutationStubOut>;
  createWholesaleOrder: ConfirmOrderCreationOutput;
  createDraftWholesaleOrder: WholesaleOrder;
  updateDraftWholesaleOrder: WholesaleOrder;
  cancelDraftWholesaleOrder: WholesaleOrder;
  finaliseDraftWholesaleOrder: WholesaleOrder;
  cancelWholesaleOrderLineItem: ConfirmOrderCreationOutput;
  finaliseWholesaleOrder: WholesaleOrder;
  createCourierBooking: CourierDeliveryConfirmationOutput;
  cancelCourierBooking: CourierBookingResponse;
  createUser: User;
  createPushNotificationRegistration: PushNotificationCreateResponse;
  sendTestPushNotification: SendPushNotificationResponse;
  updatePassword: TokenResult;
  finalizeOrderPayment: OrderFinalizationResponse;
  getCart: CartResponse;
  createOrUpdateCart: CartResponse;
  createPaymentIntent: PaymentIntentResponse;
  updatePaymentIntent: PaymentIntentResponse;
  createMerchant: Merchant;
  updateMerchantActiveStatus: UpdateMerchantActiveStatusResponse;
  updateMerchant: Merchant;
  updateMerchantCreditTerms: Merchant;
  createMerchantSubscription: MerchantSubscriptionResponse;
  updateMerchantSubscription: UpdateMerchantSubscriptionOutput;
  estimateMerchantSubscription: EstimateMerchantSubscriptionOutput;
  updateMerchantSubscriptionPaymentMethod: MerchantPaymentMethodUpdateResponse;
  updateMerchantWholesaleDeliveryConfig: WholesaleDeliveryConfig;
  createDeliveryConfig: DeliveryConfig;
  updateDeliveryConfig?: Maybe<DeliveryConfig>;
  updateDeliveryPricing?: Maybe<DeliveryPricing>;
  confirmMailchimpIntegration: MailchimpResult;
  registerNewsletterSubscriber: SubscribeResult;
  createMerchantWholesaleDeliveryConfig: WholesaleDeliveryConfig;
  createAddon: Addon;
  updateAddon?: Maybe<Addon>;
  createMerchantHoliday?: Maybe<MerchantHoliday>;
  updateMerchantHoliday?: Maybe<MerchantHoliday>;
  deleteMerchantHoliday?: Maybe<DeleteResult>;
  updateMerchantSameDayOrderPauseStatus?: Maybe<Merchant>;
  updateMerchantSameDayOrderPauseStatusExternal?: Maybe<Merchant>;
  createCustomSiteConfig: CustomSiteConfig;
  updateCustomSiteConfig?: Maybe<CustomSiteConfig>;
  createDeliveryCoverage?: Maybe<DeliveryCoverage>;
  updateDeliveryCoverage?: Maybe<DeliveryCoverage>;
  resetPasswordInit?: Maybe<ResetPasswordStatus>;
  resetPasswordComplete?: Maybe<ResetPasswordComplete>;
  createWeeblyConfig: WeeblyConfig;
  requestWeeblyLoginLink: WeeblyLoginLink;
  publishWeeblySite: WeeblyConfig;
  inviteToFloomX: InviteToFloomXResponse;
  authStripeConnect?: Maybe<Merchant>;
  updateMerchantBankDetails?: Maybe<Merchant>;
  wholesaleItemsSearch?: Maybe<WholesaleItemsSearchResult>;
  createDiscount: Discount;
  updateDiscount?: Maybe<Discount>;
  updateManyDiscounts: BatchPayload;
  deleteDiscount?: Maybe<Discount>;
  deleteManyDiscounts: BatchPayload;
  reIndexMerchantProductsForSearch: ReIndexMerchantProductsForSearchResponse;
  resetMerchantSubscription: Merchant;
  createProposedMerchantUpdate: ProposedMerchantUpdate;
  updateProposedMerchantUpdate?: Maybe<ProposedMerchantUpdate>;
  deleteProposedMerchantUpdate?: Maybe<ProposedMerchantUpdate>;
  createList: List;
  updateList?: Maybe<List>;
  deleteList?: Maybe<List>;
  migrateWholesaleOrderPaymentData?: Maybe<MigrateWholesaleOrderPaymentDataOutput>;
  createAdjusterTier?: Maybe<AdjusterTier>;
  updateAdjusterTier?: Maybe<AdjusterTier>;
  deleteAdjusterTier?: Maybe<AdjusterTier>;
  createWholesalePreOrder?: Maybe<WholesalePreOrder>;
  updateWholesalePreOrder?: Maybe<WholesalePreOrder>;
  cancelWholesalePreOrder?: Maybe<WholesalePreOrder>;
  createSupplierDeliveryConfig: SupplierDeliveryConfig;
  createMerchantSupplierIntegration: MerchantSupplierIntegration;
  updateMerchantSupplierIntegration?: Maybe<MerchantSupplierIntegration>;
  migrateLegacySupplierIntegrations: Array<Maybe<MigrateLegacySupplierIntegrationsResponse>>;
  createVatReport?: Maybe<VatReportResult>;
  updateTransaction?: Maybe<Transaction>;
  allocTransaction?: Maybe<Transaction>;
  assignTransaction?: Maybe<Transaction>;
  createRefundAdjustment: Adjustment;
  createCustomAdjustment: Adjustment;
  createOtherAdjustment: Adjustment;
  updateAdjustment?: Maybe<Adjustment>;
  deleteAdjustment?: Maybe<Adjustment>;
  createRemittanceTransfers: RemittanceTransfersBulkOutput;
  finaliseRemittanceTransfers: RemittanceTransfersBulkOutput;
  deleteRemittanceTransfer?: Maybe<RemittanceTransfer>;
  deleteManyRemittanceTransfers: BatchPayload;
  generateRemittanceTransferCsvs: GenerateCsvsResponse;
  generateRemittanceTransferStatements: GenerateStatementsResponse;
  generateRemittanceTransferStatementForMerchant: GenerateStatementsResponse;
  getPaymentIntentForPromoted: GetPaymentIntentForPromotedResponse;
  holdPromotedPostcodes: HoldPromotedPostcodesResponse;
  releasePromotedPostcodes: ReleasePromotedPostcodesResponse;
  createRemittanceAccount: RemittanceAccount;
  updateRemittanceAccount?: Maybe<RemittanceAccount>;
  deleteRemittanceAccount?: Maybe<RemittanceAccount>;
  createNotifications: Array<Maybe<Notification>>;
  readNotification: Scalars['Boolean'];
  readAllNotifications: Scalars['Boolean'];
  createOrUpdateCustomer: Customer;
  createOrUpdateOrderCustomer: Customer;
  getPusherToken: GetPusherTokenResponse;
};


export type MutationSignUpArgs = {
  data: UserSignUp;
};


export type MutationLoginArgs = {
  data: UserLoginInput;
};


export type MutationCreateOrderCustomArgs = {
  data: CreateOrderCustomInput;
};


export type MutationCreateOrderSearchTokenArgs = {
  data?: Maybe<MerchantWhereUniqueInput>;
};


export type MutationCreateOrderConsumerArgs = {
  data: CreateOrderConsumerInput;
};


export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationIndexOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};


export type MutationDeleteOrderItemArgs = {
  where?: Maybe<OrderItemWhereInput>;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertCollectionSellerProductConfigArgs = {
  where?: Maybe<CollectionSellerProductConfigWhereUniqueInput>;
  create?: Maybe<CollectionSellerProductConfigCreateInput>;
  update?: Maybe<CollectionSellerProductConfigUpdateInput>;
};


export type MutationCreateProductArgs = {
  data: ProductCreateInput;
};


export type MutationEditProductArgs = {
  data: ProductUpdateInput;
  where?: Maybe<ProductWhereUniqueInput>;
};


export type MutationDeleteProductArgs = {
  where?: Maybe<ProductWhereUniqueInput>;
};


export type MutationDeleteProductVariationArgs = {
  where?: Maybe<ProductVariationWhereUniqueInput>;
};


export type MutationCreateMediaLinkArgs = {
  data?: Maybe<CreateMediaLinkInput>;
};


export type MutationCreateFloomSnapLinkArgs = {
  data?: Maybe<CreateMediaLinkInput>;
};


export type MutationConfirmMediaUploadArgs = {
  where?: Maybe<MediaWhereInput>;
};


export type MutationConfirmFloomSnapUploadArgs = {
  where?: Maybe<FloomSnapWhereInput>;
};


export type MutationTestValidationArgs = {
  data?: Maybe<MutationStubCall>;
};


export type MutationTestMultiValidationArgs = {
  data?: Maybe<MutationStubCall>;
};


export type MutationCreateWholesaleOrderArgs = {
  data: WholesaleOrderInput;
  where?: Maybe<MerchantWhereUniqueInput>;
};


export type MutationCreateDraftWholesaleOrderArgs = {
  data: DraftWholesaleOrderCreateInput;
};


export type MutationUpdateDraftWholesaleOrderArgs = {
  where: WholesaleOrderWhereUniqueInput;
  data: DraftWholesaleOrderUpdateInput;
};


export type MutationCancelDraftWholesaleOrderArgs = {
  where: WholesaleOrderWhereUniqueInput;
};


export type MutationFinaliseDraftWholesaleOrderArgs = {
  where: WholesaleOrderWhereUniqueInput;
};


export type MutationCancelWholesaleOrderLineItemArgs = {
  data: WholesaleOrderCancelInput;
  where?: Maybe<MerchantWhereUniqueInput>;
};


export type MutationFinaliseWholesaleOrderArgs = {
  where?: Maybe<WholesaleOrderWhereUniqueInput>;
};


export type MutationCreateCourierBookingArgs = {
  data?: Maybe<CourierDeliveryForOrderCreateInput>;
  where: OrderWhereUniqueInput;
};


export type MutationCancelCourierBookingArgs = {
  data?: Maybe<CourierDeliveryForOrderCreateInput>;
  where: CourierBookingWhereUniqueInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreatePushNotificationRegistrationArgs = {
  data: PushNotificationInput;
};


export type MutationSendTestPushNotificationArgs = {
  data: SendPushNotificationInput;
};


export type MutationUpdatePasswordArgs = {
  data: UpdatePasswordInput;
  where: UserWhereUniqueInput;
};


export type MutationFinalizeOrderPaymentArgs = {
  data: OrderPaymentFinalizeInput;
  where?: Maybe<OrderWhereUniqueInput>;
};


export type MutationGetCartArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationCreateOrUpdateCartArgs = {
  data: CreateOrUpdateCartInput;
};


export type MutationCreatePaymentIntentArgs = {
  data: CreatePaymentIntentInput;
};


export type MutationUpdatePaymentIntentArgs = {
  data: UpdatePaymentIntentInput;
};


export type MutationCreateMerchantArgs = {
  data: MerchantCreateInput;
};


export type MutationUpdateMerchantActiveStatusArgs = {
  data: UpdateMerchantActiveStatusInput;
};


export type MutationUpdateMerchantArgs = {
  data: MerchantUpdateInput;
  where: MerchantWhereUniqueInput;
};


export type MutationUpdateMerchantCreditTermsArgs = {
  data: MerchantCreditTermsUpdateInput;
  where: MerchantWhereUniqueInput;
};


export type MutationCreateMerchantSubscriptionArgs = {
  data: MerchantSubscriptionInput;
  where?: Maybe<MerchantWhereUniqueInput>;
};


export type MutationUpdateMerchantSubscriptionArgs = {
  data: UpdateMerchantSubscriptionInput;
  where?: Maybe<MerchantWhereUniqueInput>;
};


export type MutationEstimateMerchantSubscriptionArgs = {
  data: UpdateMerchantSubscriptionInput;
  where?: Maybe<MerchantWhereUniqueInput>;
};


export type MutationUpdateMerchantSubscriptionPaymentMethodArgs = {
  data: MerchantPaymentMethodUpdateInput;
  where?: Maybe<MerchantWhereUniqueInput>;
};


export type MutationUpdateMerchantWholesaleDeliveryConfigArgs = {
  data: WholesaleDeliveryConfigUpdateInput;
  where: WholesaleDeliveryConfigWhereUniqueInput;
};


export type MutationCreateDeliveryConfigArgs = {
  data: DeliveryConfigCreateInput;
};


export type MutationUpdateDeliveryConfigArgs = {
  data: DeliveryConfigUpdateInput;
  where: DeliveryConfigWhereUniqueInput;
};


export type MutationUpdateDeliveryPricingArgs = {
  data: DeliveryPricingUpdateInput;
  where: DeliveryPricingWhereUniqueInput;
};


export type MutationConfirmMailchimpIntegrationArgs = {
  data: ConfirmMailchimpIntegrationInput;
  where: ConfirmMailchimpIntegrationWhereUniqueInput;
};


export type MutationRegisterNewsletterSubscriberArgs = {
  data: RegisterNewsletterSubscriberInput;
  where: CustomSiteConfigWhereUniqueInput;
};


export type MutationCreateMerchantWholesaleDeliveryConfigArgs = {
  data: WholesaleDeliveryConfigCreateInput;
  where: MerchantWhereUniqueInput;
};


export type MutationCreateAddonArgs = {
  data: AddonCreateInput;
};


export type MutationUpdateAddonArgs = {
  data: AddonUpdateInput;
  where: AddonWhereUniqueInput;
};


export type MutationCreateMerchantHolidayArgs = {
  data: MerchantHolidayCreateInput;
};


export type MutationUpdateMerchantHolidayArgs = {
  data: MerchantHolidayUpdateInput;
  where: MerchantHolidayWhereUniqueInput;
};


export type MutationDeleteMerchantHolidayArgs = {
  where: MerchantHolidayWhereUniqueInput;
};


export type MutationUpdateMerchantSameDayOrderPauseStatusArgs = {
  data: MerchantUpdateInput;
  where: MerchantWhereUniqueInput;
};


export type MutationUpdateMerchantSameDayOrderPauseStatusExternalArgs = {
  data: MerchantUpdateInput;
  where: MerchantWhereUniqueInput;
};


export type MutationCreateCustomSiteConfigArgs = {
  data: CustomSiteConfigCreateInput;
};


export type MutationUpdateCustomSiteConfigArgs = {
  data: CustomSiteConfigUpdateInput;
  where: CustomSiteConfigWhereUniqueInput;
};


export type MutationCreateDeliveryCoverageArgs = {
  data: DeliveryCoverageCreateInput;
};


export type MutationUpdateDeliveryCoverageArgs = {
  data: DeliveryCoverageUpdateInput;
  where: DeliveryCoverageWhereUniqueInput;
};


export type MutationResetPasswordInitArgs = {
  data: ResetPasswordInitInput;
};


export type MutationResetPasswordCompleteArgs = {
  data: ResetPasswordCompleteInput;
};


export type MutationCreateWeeblyConfigArgs = {
  data: WeeblyConfigCreateInput;
};


export type MutationRequestWeeblyLoginLinkArgs = {
  data: WeeblyLoginLinkInput;
};


export type MutationPublishWeeblySiteArgs = {
  data: WeeblyPublishSiteInput;
};


export type MutationInviteToFloomXArgs = {
  where: InviteToFloomXWhereUniqueInput;
};


export type MutationAuthStripeConnectArgs = {
  data: AuthStripeConnectInput;
  where: MerchantWhereInput;
};


export type MutationUpdateMerchantBankDetailsArgs = {
  data: MerchantBankDetailsInput;
  where: MerchantWhereInput;
};


export type MutationWholesaleItemsSearchArgs = {
  data?: Maybe<WholesaleItemsSearchInput>;
};


export type MutationCreateDiscountArgs = {
  data: DiscountCreateInput;
};


export type MutationUpdateDiscountArgs = {
  data: DiscountUpdateInput;
  where: DiscountWhereUniqueInput;
};


export type MutationUpdateManyDiscountsArgs = {
  data: DiscountUpdateManyMutationInput;
  where?: Maybe<DiscountWhereInput>;
};


export type MutationDeleteDiscountArgs = {
  where: DiscountWhereUniqueInput;
};


export type MutationDeleteManyDiscountsArgs = {
  where?: Maybe<DiscountWhereInput>;
};


export type MutationReIndexMerchantProductsForSearchArgs = {
  where: MerchantWhereInput;
};


export type MutationResetMerchantSubscriptionArgs = {
  where: MerchantWhereUniqueInput;
};


export type MutationCreateProposedMerchantUpdateArgs = {
  data: ProposedMerchantUpdateCreateInput;
};


export type MutationUpdateProposedMerchantUpdateArgs = {
  data: ProposedMerchantUpdateUpdateInput;
  where: ProposedMerchantUpdateWhereUniqueInput;
};


export type MutationDeleteProposedMerchantUpdateArgs = {
  where: ProposedMerchantUpdateWhereUniqueInput;
};


export type MutationCreateListArgs = {
  data: ListCreateInput;
};


export type MutationUpdateListArgs = {
  data: ListUpdateInput;
  where: ListWhereUniqueInput;
};


export type MutationDeleteListArgs = {
  where: ListWhereUniqueInput;
};


export type MutationMigrateWholesaleOrderPaymentDataArgs = {
  where: WholesaleOrderWhereInput;
};


export type MutationCreateAdjusterTierArgs = {
  data: AdjusterTierCreateInput;
};


export type MutationUpdateAdjusterTierArgs = {
  where: AdjusterTierWhereUniqueInput;
  data: AdjusterTierUpdateInput;
};


export type MutationDeleteAdjusterTierArgs = {
  where: AdjusterTierWhereUniqueInput;
};


export type MutationCreateWholesalePreOrderArgs = {
  data: WholesalePreOrderCreateInput;
};


export type MutationUpdateWholesalePreOrderArgs = {
  data: WholesalePreOrderUpdateInput;
  where: WholesalePreOrderWhereUniqueInput;
};


export type MutationCancelWholesalePreOrderArgs = {
  where: WholesalePreOrderWhereUniqueInput;
};


export type MutationCreateSupplierDeliveryConfigArgs = {
  data: SupplierDeliveryConfigCreateInput;
};


export type MutationCreateMerchantSupplierIntegrationArgs = {
  data: MerchantSupplierIntegrationCreateInput;
};


export type MutationUpdateMerchantSupplierIntegrationArgs = {
  data: MerchantSupplierIntegrationUpdateInput;
  where: MerchantSupplierIntegrationWhereUniqueInput;
};


export type MutationMigrateLegacySupplierIntegrationsArgs = {
  data?: Maybe<MigrateLegacySupplierIntegrationsInput>;
};


export type MutationCreateVatReportArgs = {
  data: VatReportCreateInput;
};


export type MutationUpdateTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationAllocTransactionArgs = {
  data: AllocTransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationAssignTransactionArgs = {
  data: AssignTransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};


export type MutationCreateRefundAdjustmentArgs = {
  data: AdjustmentCreateRefundInput;
};


export type MutationCreateCustomAdjustmentArgs = {
  data: AdjustmentCreateCustomInput;
};


export type MutationCreateOtherAdjustmentArgs = {
  data: AdjustmentCreateOtherInput;
};


export type MutationUpdateAdjustmentArgs = {
  data: AdjustmentUpdateInput;
  where: AdjustmentWhereUniqueInput;
};


export type MutationDeleteAdjustmentArgs = {
  where: AdjustmentWhereUniqueInput;
};


export type MutationCreateRemittanceTransfersArgs = {
  data: RemittanceTransfersCreateInput;
};


export type MutationFinaliseRemittanceTransfersArgs = {
  data: RemittanceTransfersSelectInput;
};


export type MutationDeleteRemittanceTransferArgs = {
  where: RemittanceTransferWhereUniqueInput;
};


export type MutationDeleteManyRemittanceTransfersArgs = {
  where?: Maybe<RemittanceTransferWhereInput>;
};


export type MutationGenerateRemittanceTransferCsvsArgs = {
  data: GenerateTransferCsvsInput;
};


export type MutationGenerateRemittanceTransferStatementsArgs = {
  where: RemittancePeriodWhereUniqueInput;
};


export type MutationGenerateRemittanceTransferStatementForMerchantArgs = {
  where: GenerateRemittanceTransferStatementForMerchantWhereInput;
};


export type MutationGetPaymentIntentForPromotedArgs = {
  data: GetPaymentIntentForPromotedInput;
};


export type MutationHoldPromotedPostcodesArgs = {
  data: HoldPromotedPostcodesInput;
};


export type MutationReleasePromotedPostcodesArgs = {
  data: ReleasePromotedPostcodesInput;
};


export type MutationCreateRemittanceAccountArgs = {
  data: RemittanceAccountCreateInput;
};


export type MutationUpdateRemittanceAccountArgs = {
  data: RemittanceAccountUpdateInput;
  where: RemittanceAccountWhereUniqueInput;
};


export type MutationDeleteRemittanceAccountArgs = {
  where: RemittanceAccountWhereUniqueInput;
};


export type MutationCreateNotificationsArgs = {
  data: NotificationsCreateInput;
};


export type MutationReadNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type MutationReadAllNotificationsArgs = {
  where: MerchantWhereUniqueInput;
};


export type MutationCreateOrUpdateCustomerArgs = {
  data: CustomerCreateInput;
  where: CustomerWhereInput;
};


export type MutationCreateOrUpdateOrderCustomerArgs = {
  data: CustomerCreateInput;
  where: OrderWhereUniqueInput;
};


export type MutationGetPusherTokenArgs = {
  where: UserWhereUniqueInput;
};

export type MutationStubCall = {
  should_have?: Maybe<Scalars['String']>;
  cant_have?: Maybe<Scalars['String']>;
};

export type MutationStubOut = {
  __typename?: 'MutationStubOut';
  string?: Maybe<Scalars['Json']>;
};

export enum MutationType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Deleted = 'DELETED'
}

export type Node = {
  id: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  readAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  type: NotificationType;
  link?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  user?: Maybe<User>;
};

export type NotificationCreateInput = {
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  type: NotificationType;
  link?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
  user?: Maybe<UserWhereUniqueInput>;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  node: Notification;
  cursor: Scalars['String'];
};

export enum NotificationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ReadAtAsc = 'readAt_ASC',
  ReadAtDesc = 'readAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC'
}

export type NotificationPreviousValues = {
  __typename?: 'NotificationPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  readAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  type: NotificationType;
  link?: Maybe<Scalars['String']>;
};

export type NotificationSubscriptionPayload = {
  __typename?: 'NotificationSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Notification>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<NotificationPreviousValues>;
};

export type NotificationSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<NotificationWhereInput>;
  AND?: Maybe<Array<NotificationSubscriptionWhereInput>>;
  OR?: Maybe<Array<NotificationSubscriptionWhereInput>>;
  NOT?: Maybe<Array<NotificationSubscriptionWhereInput>>;
};

export enum NotificationType {
  Order = 'ORDER',
  Review = 'REVIEW',
  Promoted = 'PROMOTED'
}

export type NotificationUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  readAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  readAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  readAt?: Maybe<Scalars['DateTime']>;
  readAt_not?: Maybe<Scalars['DateTime']>;
  readAt_in?: Maybe<Array<Scalars['DateTime']>>;
  readAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  readAt_lt?: Maybe<Scalars['DateTime']>;
  readAt_lte?: Maybe<Scalars['DateTime']>;
  readAt_gt?: Maybe<Scalars['DateTime']>;
  readAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  body_not?: Maybe<Scalars['String']>;
  body_in?: Maybe<Array<Scalars['String']>>;
  body_not_in?: Maybe<Array<Scalars['String']>>;
  body_lt?: Maybe<Scalars['String']>;
  body_lte?: Maybe<Scalars['String']>;
  body_gt?: Maybe<Scalars['String']>;
  body_gte?: Maybe<Scalars['String']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  body_starts_with?: Maybe<Scalars['String']>;
  body_not_starts_with?: Maybe<Scalars['String']>;
  body_ends_with?: Maybe<Scalars['String']>;
  body_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationType>;
  type_not?: Maybe<NotificationType>;
  type_in?: Maybe<Array<NotificationType>>;
  type_not_in?: Maybe<Array<NotificationType>>;
  link?: Maybe<Scalars['String']>;
  link_not?: Maybe<Scalars['String']>;
  link_in?: Maybe<Array<Scalars['String']>>;
  link_not_in?: Maybe<Array<Scalars['String']>>;
  link_lt?: Maybe<Scalars['String']>;
  link_lte?: Maybe<Scalars['String']>;
  link_gt?: Maybe<Scalars['String']>;
  link_gte?: Maybe<Scalars['String']>;
  link_contains?: Maybe<Scalars['String']>;
  link_not_contains?: Maybe<Scalars['String']>;
  link_starts_with?: Maybe<Scalars['String']>;
  link_not_starts_with?: Maybe<Scalars['String']>;
  link_ends_with?: Maybe<Scalars['String']>;
  link_not_ends_with?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereInput>;
  user?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<NotificationWhereInput>>;
  OR?: Maybe<Array<NotificationWhereInput>>;
  NOT?: Maybe<Array<NotificationWhereInput>>;
};

export type NotificationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type NotificationsCreateInput = {
  notifications: Array<NotificationCreateInput>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  new: Scalars['Boolean'];
  orderNo: Scalars['String'];
  complete: Scalars['Boolean'];
  status: OrderStatus;
  customerUser?: Maybe<Customer>;
  billingAddress?: Maybe<Address>;
  shippingAddress?: Maybe<Address>;
  merchant: Merchant;
  postalCode?: Maybe<Scalars['String']>;
  itemSubtotal?: Maybe<Scalars['Float']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderNotes?: Maybe<Array<OrderNote>>;
  orderDeliveryConfig?: Maybe<OrderDeliveryConfig>;
  orderDiscount?: Maybe<OrderDiscount>;
  tax?: Maybe<OrderTax>;
  discounts?: Maybe<Array<Discount>>;
  currency?: Maybe<Currency>;
  channel: Channel;
  payment?: Maybe<Payment>;
  transactions?: Maybe<Array<Transaction>>;
  adjustments?: Maybe<Array<Adjustment>>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliverySafePlace?: Maybe<Scalars['String']>;
  courierBooking?: Maybe<CourierBooking>;
  canBookCourier?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  newsletterSignupAt?: Maybe<Scalars['DateTime']>;
  deliverAt: Scalars['DateTime'];
  orderedAt?: Maybe<Scalars['DateTime']>;
  floomSnap?: Maybe<Media>;
  nationwide?: Maybe<Scalars['Boolean']>;
  commercial?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};


export type OrderOrderItemsArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<OrderItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderOrderNotesArgs = {
  where?: Maybe<OrderNoteWhereInput>;
  orderBy?: Maybe<OrderNoteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderDiscountsArgs = {
  where?: Maybe<DiscountWhereInput>;
  orderBy?: Maybe<DiscountOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<TransactionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderAdjustmentsArgs = {
  where?: Maybe<AdjustmentWhereInput>;
  orderBy?: Maybe<AdjustmentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderCheckMerchant = {
  __typename?: 'OrderCheckMerchant';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasSameDay?: Maybe<Scalars['Boolean']>;
  latestCutOff?: Maybe<Scalars['Int']>;
  reviewCount?: Maybe<Scalars['Int']>;
  reviewRating?: Maybe<Scalars['Float']>;
  unread?: Maybe<Scalars['Int']>;
  withFloristOrPrepared?: Maybe<Scalars['Int']>;
  withFlorist?: Maybe<Scalars['Int']>;
  prepared?: Maybe<Scalars['Int']>;
  onTheWay?: Maybe<Scalars['Int']>;
  delivered?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type OrderCheckResponse = {
  __typename?: 'OrderCheckResponse';
  merchants?: Maybe<Array<Maybe<OrderCheckMerchant>>>;
};

export type OrderCheckWhereInput = {
  timezone: Scalars['String'];
  merchantId?: Maybe<Scalars['ID']>;
  beforeHour?: Maybe<Scalars['Int']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderEdge>>;
  aggregate: AggregateOrder;
};

export type OrderCreateManyInput = {
  connect?: Maybe<Array<OrderWhereUniqueInput>>;
};

export type OrderCreateOneInput = {
  connect?: Maybe<OrderWhereUniqueInput>;
};

export type OrderDeliveryConfig = {
  __typename?: 'OrderDeliveryConfig';
  id: Scalars['ID'];
  price: Scalars['Float'];
  sameDay: Scalars['Boolean'];
  deliveryConfigSnapshot: Scalars['Json'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDeliveryConfigConnection = {
  __typename?: 'OrderDeliveryConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderDeliveryConfigEdge>>;
  aggregate: AggregateOrderDeliveryConfig;
};

export type OrderDeliveryConfigCreateInput = {
  id?: Maybe<Scalars['ID']>;
  price: Scalars['Float'];
  sameDay: Scalars['Boolean'];
  coverageLabel?: Maybe<Scalars['String']>;
  deliveryConfigSnapshot: Scalars['Json'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDeliveryConfigCreateOneInput = {
  create?: Maybe<OrderDeliveryConfigCreateInput>;
  connect?: Maybe<OrderDeliveryConfigWhereUniqueInput>;
};

export type OrderDeliveryConfigEdge = {
  __typename?: 'OrderDeliveryConfigEdge';
  node: OrderDeliveryConfig;
  cursor: Scalars['String'];
};

export enum OrderDeliveryConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  SameDayAsc = 'sameDay_ASC',
  SameDayDesc = 'sameDay_DESC',
  DeliveryConfigSnapshotAsc = 'deliveryConfigSnapshot_ASC',
  DeliveryConfigSnapshotDesc = 'deliveryConfigSnapshot_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type OrderDeliveryConfigPreviousValues = {
  __typename?: 'OrderDeliveryConfigPreviousValues';
  id: Scalars['ID'];
  price: Scalars['Float'];
  sameDay: Scalars['Boolean'];
  deliveryConfigSnapshot: Scalars['Json'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDeliveryConfigSubscriptionPayload = {
  __typename?: 'OrderDeliveryConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<OrderDeliveryConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<OrderDeliveryConfigPreviousValues>;
};

export type OrderDeliveryConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<OrderDeliveryConfigWhereInput>;
  AND?: Maybe<Array<OrderDeliveryConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<OrderDeliveryConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<OrderDeliveryConfigSubscriptionWhereInput>>;
};

export type OrderDeliveryConfigUpdateDataInput = {
  price?: Maybe<Scalars['Float']>;
  sameDay?: Maybe<Scalars['Boolean']>;
  deliveryConfigSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDeliveryConfigUpdateInput = {
  price?: Maybe<Scalars['Float']>;
  sameDay?: Maybe<Scalars['Boolean']>;
  deliveryConfigSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDeliveryConfigUpdateManyMutationInput = {
  price?: Maybe<Scalars['Float']>;
  sameDay?: Maybe<Scalars['Boolean']>;
  deliveryConfigSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDeliveryConfigUpdateOneInput = {
  create?: Maybe<OrderDeliveryConfigCreateInput>;
  update?: Maybe<OrderDeliveryConfigUpdateDataInput>;
  upsert?: Maybe<OrderDeliveryConfigUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrderDeliveryConfigWhereUniqueInput>;
};

export type OrderDeliveryConfigUpsertNestedInput = {
  update: OrderDeliveryConfigUpdateDataInput;
  create: OrderDeliveryConfigCreateInput;
};

export type OrderDeliveryConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  sameDay?: Maybe<Scalars['Boolean']>;
  sameDay_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderDeliveryConfigWhereInput>>;
  OR?: Maybe<Array<OrderDeliveryConfigWhereInput>>;
  NOT?: Maybe<Array<OrderDeliveryConfigWhereInput>>;
};

export type OrderDeliveryConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum OrderDeliveryState {
  None = 'None',
  Requested = 'Requested',
  Booked = 'Booked',
  AssignedDriver = 'AssignedDriver',
  PickedUp = 'PickedUp',
  OutForDelivery = 'OutForDelivery',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Refused = 'Refused'
}

export type OrderDiscount = {
  __typename?: 'OrderDiscount';
  id: Scalars['ID'];
  total: Scalars['Float'];
  discount?: Maybe<Discount>;
  discountSnapshot: Scalars['Json'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDiscountAmountWhereInput = {
  orderItems?: Maybe<Array<Maybe<ItemForDiscountCalculationDataInput>>>;
  orderDeliveryAmount?: Maybe<Scalars['Float']>;
};

export type OrderDiscountConnectInput = {
  connect?: Maybe<OrderDiscountWhereUniqueInput>;
};

export type OrderDiscountConnection = {
  __typename?: 'OrderDiscountConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderDiscountEdge>>;
  aggregate: AggregateOrderDiscount;
};

export type OrderDiscountCreateInput = {
  id?: Maybe<Scalars['ID']>;
  total: Scalars['Float'];
  discount?: Maybe<OrderDiscountConnectInput>;
  discountSnapshot: Scalars['Json'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDiscountCreateOneInput = {
  create?: Maybe<OrderDiscountCreateInput>;
  connect?: Maybe<OrderDiscountWhereUniqueInput>;
};

export type OrderDiscountEdge = {
  __typename?: 'OrderDiscountEdge';
  node: OrderDiscount;
  cursor: Scalars['String'];
};

export type OrderDiscountInput = {
  id?: Maybe<Scalars['ID']>;
  total: Scalars['Float'];
  discountSnapshot: Scalars['Json'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum OrderDiscountOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TotalAsc = 'total_ASC',
  TotalDesc = 'total_DESC',
  DiscountSnapshotAsc = 'discountSnapshot_ASC',
  DiscountSnapshotDesc = 'discountSnapshot_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type OrderDiscountPreviousValues = {
  __typename?: 'OrderDiscountPreviousValues';
  id: Scalars['ID'];
  total: Scalars['Float'];
  discountSnapshot: Scalars['Json'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDiscountSubscriptionPayload = {
  __typename?: 'OrderDiscountSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<OrderDiscount>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<OrderDiscountPreviousValues>;
};

export type OrderDiscountSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<OrderDiscountWhereInput>;
  AND?: Maybe<Array<OrderDiscountSubscriptionWhereInput>>;
  OR?: Maybe<Array<OrderDiscountSubscriptionWhereInput>>;
  NOT?: Maybe<Array<OrderDiscountSubscriptionWhereInput>>;
};

export type OrderDiscountUpdateDataInput = {
  total?: Maybe<Scalars['Float']>;
  discount?: Maybe<OrderDiscountConnectInput>;
  discountSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDiscountUpdateInput = {
  total?: Maybe<Scalars['Float']>;
  discount?: Maybe<OrderDiscountConnectInput>;
  discountSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDiscountUpdateManyMutationInput = {
  total?: Maybe<Scalars['Float']>;
  discountSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderDiscountWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  total?: Maybe<Scalars['Float']>;
  total_not?: Maybe<Scalars['Float']>;
  total_in?: Maybe<Array<Scalars['Float']>>;
  total_not_in?: Maybe<Array<Scalars['Float']>>;
  total_lt?: Maybe<Scalars['Float']>;
  total_lte?: Maybe<Scalars['Float']>;
  total_gt?: Maybe<Scalars['Float']>;
  total_gte?: Maybe<Scalars['Float']>;
  discount?: Maybe<DiscountWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderDiscountWhereInput>>;
  OR?: Maybe<Array<OrderDiscountWhereInput>>;
  NOT?: Maybe<Array<OrderDiscountWhereInput>>;
};

export type OrderDiscountWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  node: Order;
  cursor: Scalars['String'];
};

export type OrderExport = {
  __typename?: 'OrderExport';
  orderNumber?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  addons?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  senderPhone?: Maybe<Scalars['String']>;
  senderEmail?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientAddress?: Maybe<Scalars['String']>;
  recipientPhone?: Maybe<Scalars['String']>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  giftMessages?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  merchantName?: Maybe<Scalars['String']>;
  deliveryCutoff?: Maybe<Scalars['String']>;
};

export type OrderFinalizationResponse = {
  __typename?: 'OrderFinalizationResponse';
  response: Scalars['String'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  productSnapshot: Scalars['Json'];
  sourceProductVariation?: Maybe<ProductVariation>;
  giftMessages: Array<Scalars['String']>;
  addOnsSnapshot?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemConnection = {
  __typename?: 'OrderItemConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderItemEdge>>;
  aggregate: AggregateOrderItem;
};

export type OrderItemCreateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  sourceProductVariation?: Maybe<ProductVariationConnectInput>;
  productSnapshot: Scalars['Json'];
  giftMessages?: Maybe<OrderItemCreategiftMessagesInput>;
  addOnsSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemCreateManyInput = {
  create?: Maybe<Array<OrderItemCreateInput>>;
  connect?: Maybe<Array<OrderItemWhereUniqueInput>>;
};

export type OrderItemCreategiftMessagesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderItemEdge = {
  __typename?: 'OrderItemEdge';
  node: OrderItem;
  cursor: Scalars['String'];
};

export enum OrderItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  QuantityAsc = 'quantity_ASC',
  QuantityDesc = 'quantity_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  ProductSnapshotAsc = 'productSnapshot_ASC',
  ProductSnapshotDesc = 'productSnapshot_DESC',
  AddOnsSnapshotAsc = 'addOnsSnapshot_ASC',
  AddOnsSnapshotDesc = 'addOnsSnapshot_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type OrderItemPreviousValues = {
  __typename?: 'OrderItemPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  productSnapshot: Scalars['Json'];
  sourceProductVariation?: Maybe<ProductVariation>;
  giftMessages: Array<Scalars['String']>;
  addOnsSnapshot?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  quantity_not?: Maybe<Scalars['Int']>;
  quantity_in?: Maybe<Array<Scalars['Int']>>;
  quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  quantity_lt?: Maybe<Scalars['Int']>;
  quantity_lte?: Maybe<Scalars['Int']>;
  quantity_gt?: Maybe<Scalars['Int']>;
  quantity_gte?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderItemScalarWhereInput>>;
  OR?: Maybe<Array<OrderItemScalarWhereInput>>;
  NOT?: Maybe<Array<OrderItemScalarWhereInput>>;
};

export type OrderItemSubscriptionPayload = {
  __typename?: 'OrderItemSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<OrderItem>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<OrderItemPreviousValues>;
};

export type OrderItemSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<OrderItemWhereInput>;
  AND?: Maybe<Array<OrderItemSubscriptionWhereInput>>;
  OR?: Maybe<Array<OrderItemSubscriptionWhereInput>>;
  NOT?: Maybe<Array<OrderItemSubscriptionWhereInput>>;
};

export type OrderItemTaxLookupInput = {
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  productTypeSlug?: Maybe<Scalars['String']>;
  discounted: Scalars['Boolean'];
};

export type OrderItemUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  sourceProductVariation?: Maybe<ProductVariationConnectInput>;
  productSnapshot?: Maybe<Scalars['Json']>;
  giftMessages?: Maybe<OrderItemUpdategiftMessagesInput>;
  addOnsSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  sourceProductVariation?: Maybe<ProductVariationConnectInput>;
  productSnapshot?: Maybe<Scalars['Json']>;
  giftMessages?: Maybe<OrderItemUpdategiftMessagesInput>;
  addOnsSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemUpdateManyDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  sourceProductVariation?: Maybe<ProductVariationConnectInput>;
  productSnapshot?: Maybe<Scalars['Json']>;
  giftMessages?: Maybe<OrderItemUpdategiftMessagesInput>;
  addOnsSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemUpdateManyInput = {
  create?: Maybe<Array<OrderItemCreateInput>>;
  update?: Maybe<Array<OrderItemUpdateWithWhereUniqueNestedInput>>;
  disconnect?: Maybe<Array<OrderItemWhereUniqueInput>>;
  updateMany?: Maybe<Array<OrderItemUpdateManyWithWhereNestedInput>>;
};

export type OrderItemUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  sourceProductVariation?: Maybe<ProductVariationConnectInput>;
  productSnapshot?: Maybe<Scalars['Json']>;
  giftMessages?: Maybe<OrderItemUpdategiftMessagesInput>;
  addOnsSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderItemUpdateManyWithWhereNestedInput = {
  where: OrderItemScalarWhereInput;
  data: OrderItemUpdateManyDataInput;
};

export type OrderItemUpdateWithWhereUniqueNestedInput = {
  where: OrderItemWhereUniqueInput;
  data: OrderItemUpdateDataInput;
};

export type OrderItemUpdategiftMessagesInput = {
  set?: Maybe<Array<Scalars['String']>>;
};

export type OrderItemUpsertWithWhereUniqueNestedInput = {
  where: OrderItemWhereUniqueInput;
  update: OrderItemUpdateDataInput;
  create: OrderItemCreateInput;
};

export type OrderItemWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  quantity_not?: Maybe<Scalars['Int']>;
  quantity_in?: Maybe<Array<Scalars['Int']>>;
  quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  quantity_lt?: Maybe<Scalars['Int']>;
  quantity_lte?: Maybe<Scalars['Int']>;
  quantity_gt?: Maybe<Scalars['Int']>;
  quantity_gte?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderItemWhereInput>>;
  OR?: Maybe<Array<OrderItemWhereInput>>;
  NOT?: Maybe<Array<OrderItemWhereInput>>;
};

export type OrderItemWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
};

export type OrderNote = {
  __typename?: 'OrderNote';
  id: Scalars['ID'];
  content: Scalars['String'];
  order: Order;
  read: Scalars['Boolean'];
  floom: Scalars['Boolean'];
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderNoteConnection = {
  __typename?: 'OrderNoteConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderNoteEdge>>;
  aggregate: AggregateOrderNote;
};

export type OrderNoteCreateInput = {
  content: Scalars['String'];
  createdBy?: Maybe<UserCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  floom?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  order: OrderCreateManyInput;
  read?: Maybe<Scalars['Boolean']>;
};

export type OrderNoteCreateManyWithoutOrderInput = {
  connect?: Maybe<Array<OrderNoteWhereUniqueInput>>;
};

export type OrderNoteCreateOnlyManyInput = {
  create?: Maybe<Array<OrderNoteCreateInput>>;
};

export type OrderNoteCreateWithoutOrderInput = {
  id?: Maybe<Scalars['ID']>;
  content: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
  floom?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderNoteEdge = {
  __typename?: 'OrderNoteEdge';
  node: OrderNote;
  cursor: Scalars['String'];
};

export enum OrderNoteOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  ReadAsc = 'read_ASC',
  ReadDesc = 'read_DESC',
  FloomAsc = 'floom_ASC',
  FloomDesc = 'floom_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type OrderNotePreviousValues = {
  __typename?: 'OrderNotePreviousValues';
  id: Scalars['ID'];
  content: Scalars['String'];
  read: Scalars['Boolean'];
  floom: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderNoteScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Scalars['String']>>;
  content_not_in?: Maybe<Array<Scalars['String']>>;
  content_lt?: Maybe<Scalars['String']>;
  content_lte?: Maybe<Scalars['String']>;
  content_gt?: Maybe<Scalars['String']>;
  content_gte?: Maybe<Scalars['String']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  content_starts_with?: Maybe<Scalars['String']>;
  content_not_starts_with?: Maybe<Scalars['String']>;
  content_ends_with?: Maybe<Scalars['String']>;
  content_not_ends_with?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  read_not?: Maybe<Scalars['Boolean']>;
  floom?: Maybe<Scalars['Boolean']>;
  floom_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderNoteScalarWhereInput>>;
  OR?: Maybe<Array<OrderNoteScalarWhereInput>>;
  NOT?: Maybe<Array<OrderNoteScalarWhereInput>>;
};

export type OrderNoteSubscriptionPayload = {
  __typename?: 'OrderNoteSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<OrderNote>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<OrderNotePreviousValues>;
};

export type OrderNoteSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<OrderNoteWhereInput>;
  AND?: Maybe<Array<OrderNoteSubscriptionWhereInput>>;
  OR?: Maybe<Array<OrderNoteSubscriptionWhereInput>>;
  NOT?: Maybe<Array<OrderNoteSubscriptionWhereInput>>;
};

export type OrderNoteUpdateDataInput = {
  content?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  floom?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderNoteUpdateInput = {
  content?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  floom?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderNoteUpdateManyDataInput = {
  content?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  floom?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
};

export type OrderNoteUpdateManyInput = {
  create?: Maybe<Array<OrderNoteCreateInput>>;
  update?: Maybe<Array<OrderNoteUpdateWithWhereUniqueInput>>;
};

export type OrderNoteUpdateManyMutationInput = {
  content?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  floom?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
};

export type OrderNoteUpdateManyWithWhereNestedInput = {
  data: OrderNoteUpdateManyDataInput;
  where: OrderNoteScalarWhereInput;
};

export type OrderNoteUpdateManyWithoutOrderInput = {
  create?: Maybe<Array<OrderNoteCreateWithoutOrderInput>>;
  delete?: Maybe<Array<OrderNoteWhereUniqueInput>>;
  connect?: Maybe<Array<OrderNoteWhereUniqueInput>>;
  set?: Maybe<Array<OrderNoteWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrderNoteWhereUniqueInput>>;
  update?: Maybe<Array<OrderNoteUpdateWithWhereUniqueWithoutOrderInput>>;
  upsert?: Maybe<Array<OrderNoteUpsertWithWhereUniqueWithoutOrderInput>>;
  deleteMany?: Maybe<Array<OrderNoteScalarWhereInput>>;
  updateMany?: Maybe<Array<OrderNoteUpdateManyWithWhereNestedInput>>;
};

export type OrderNoteUpdateWithWhereUniqueInput = {
  where: OrderNoteWhereUniqueInput;
  data: OrderNoteUpdateDataInput;
};

export type OrderNoteUpdateWithWhereUniqueWithoutOrderInput = {
  where: OrderNoteWhereUniqueInput;
  data: OrderNoteUpdateWithoutOrderDataInput;
};

export type OrderNoteUpdateWithoutOrderDataInput = {
  content?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  floom?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderNoteUpsertWithWhereUniqueInput = {
  where: OrderNoteWhereUniqueInput;
  update: OrderNoteUpdateDataInput;
  create: OrderNoteCreateInput;
};

export type OrderNoteUpsertWithWhereUniqueWithoutOrderInput = {
  where: OrderNoteWhereUniqueInput;
  update: OrderNoteUpdateWithoutOrderDataInput;
};

export type OrderNoteWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Scalars['String']>>;
  content_not_in?: Maybe<Array<Scalars['String']>>;
  content_lt?: Maybe<Scalars['String']>;
  content_lte?: Maybe<Scalars['String']>;
  content_gt?: Maybe<Scalars['String']>;
  content_gte?: Maybe<Scalars['String']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  content_starts_with?: Maybe<Scalars['String']>;
  content_not_starts_with?: Maybe<Scalars['String']>;
  content_ends_with?: Maybe<Scalars['String']>;
  content_not_ends_with?: Maybe<Scalars['String']>;
  order?: Maybe<OrderWhereInput>;
  read?: Maybe<Scalars['Boolean']>;
  read_not?: Maybe<Scalars['Boolean']>;
  floom?: Maybe<Scalars['Boolean']>;
  floom_not?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderNoteWhereInput>>;
  OR?: Maybe<Array<OrderNoteWhereInput>>;
  NOT?: Maybe<Array<OrderNoteWhereInput>>;
};

export type OrderNoteWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum OrderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  NewAsc = 'new_ASC',
  NewDesc = 'new_DESC',
  OrderNoAsc = 'orderNo_ASC',
  OrderNoDesc = 'orderNo_DESC',
  CompleteAsc = 'complete_ASC',
  CompleteDesc = 'complete_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  ChannelAsc = 'channel_ASC',
  ChannelDesc = 'channel_DESC',
  DeliveryInstructionsAsc = 'deliveryInstructions_ASC',
  DeliveryInstructionsDesc = 'deliveryInstructions_DESC',
  DeliverySafePlaceAsc = 'deliverySafePlace_ASC',
  DeliverySafePlaceDesc = 'deliverySafePlace_DESC',
  CanBookCourierAsc = 'canBookCourier_ASC',
  CanBookCourierDesc = 'canBookCourier_DESC',
  DeliverAtAsc = 'deliverAt_ASC',
  DeliverAtDesc = 'deliverAt_DESC',
  OrderedAtAsc = 'orderedAt_ASC',
  OrderedAtDesc = 'orderedAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NationwideAsc = 'nationwide_ASC',
  NationwideDesc = 'nationwide_DESC',
  CommercialAsc = 'commercial_ASC',
  CommercialDesc = 'commercial_DESC'
}

export type OrderPaymentFinalizeInput = {
  stripe_payment_intent_id: Scalars['String'];
};

export type OrderPreviousValues = {
  __typename?: 'OrderPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  new: Scalars['Boolean'];
  orderNo: Scalars['String'];
  complete: Scalars['Boolean'];
  currency?: Maybe<Currency>;
  channel: Channel;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliverySafePlace?: Maybe<Scalars['String']>;
  canBookCourier?: Maybe<Scalars['Boolean']>;
  deliverAt: Scalars['DateTime'];
  orderedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  statusId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderStatusConnection = {
  __typename?: 'OrderStatusConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderStatusEdge>>;
  aggregate: AggregateOrderStatus;
};

export type OrderStatusCreateInput = {
  title: Scalars['String'];
  slug: Scalars['String'];
  statusId?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderStatusCreateOneInput = {
  create?: Maybe<OrderStatusCreateInput>;
  connect?: Maybe<OrderStatusWhereUniqueInput>;
};

export type OrderStatusEdge = {
  __typename?: 'OrderStatusEdge';
  node: OrderStatus;
  cursor: Scalars['String'];
};

export enum OrderStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StatusIdAsc = 'statusId_ASC',
  StatusIdDesc = 'statusId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type OrderStatusPreviousValues = {
  __typename?: 'OrderStatusPreviousValues';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  statusId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderStatusSubscriptionPayload = {
  __typename?: 'OrderStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<OrderStatus>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<OrderStatusPreviousValues>;
};

export type OrderStatusSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<OrderStatusWhereInput>;
  AND?: Maybe<Array<OrderStatusSubscriptionWhereInput>>;
  OR?: Maybe<Array<OrderStatusSubscriptionWhereInput>>;
  NOT?: Maybe<Array<OrderStatusSubscriptionWhereInput>>;
};

export type OrderStatusUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  statusId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type OrderStatusUpdateInput = {
  connect?: Maybe<OrderStatusWhereUniqueInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  statusId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type OrderStatusUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  statusId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type OrderStatusUpdateOneRequiredInput = {
  update?: Maybe<OrderStatusUpdateDataInput>;
  upsert?: Maybe<OrderStatusUpsertNestedInput>;
  connect?: Maybe<OrderStatusWhereUniqueInput>;
};

export type OrderStatusUpsertNestedInput = {
  create: OrderStatusCreateInput;
  update: OrderStatusUpdateDataInput;
};

export type OrderStatusWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['Int']>;
  statusId_not?: Maybe<Scalars['Int']>;
  statusId_in?: Maybe<Array<Scalars['Int']>>;
  statusId_not_in?: Maybe<Array<Scalars['Int']>>;
  statusId_lt?: Maybe<Scalars['Int']>;
  statusId_lte?: Maybe<Scalars['Int']>;
  statusId_gt?: Maybe<Scalars['Int']>;
  statusId_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderStatusWhereInput>>;
  OR?: Maybe<Array<OrderStatusWhereInput>>;
  NOT?: Maybe<Array<OrderStatusWhereInput>>;
};

export type OrderStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type OrderSubscriptionPayload = {
  __typename?: 'OrderSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Order>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<OrderPreviousValues>;
};

export type OrderSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<OrderWhereInput>;
  AND?: Maybe<Array<OrderSubscriptionWhereInput>>;
  OR?: Maybe<Array<OrderSubscriptionWhereInput>>;
  NOT?: Maybe<Array<OrderSubscriptionWhereInput>>;
};

export type OrderTax = {
  __typename?: 'OrderTax';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  inclusive?: Maybe<Scalars['Boolean']>;
  taxSnapshot?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<User>;
};

export type OrderTaxConnection = {
  __typename?: 'OrderTaxConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderTaxEdge>>;
  aggregate: AggregateOrderTax;
};

export type OrderTaxCreateInput = {
  id?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  inclusive?: Maybe<Scalars['Boolean']>;
  taxSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderTaxCreateOneInput = {
  create?: Maybe<OrderTaxCreateInput>;
  connect?: Maybe<OrderTaxWhereUniqueInput>;
};

export type OrderTaxEdge = {
  __typename?: 'OrderTaxEdge';
  node: OrderTax;
  cursor: Scalars['String'];
};

export enum OrderTaxOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  InclusiveAsc = 'inclusive_ASC',
  InclusiveDesc = 'inclusive_DESC',
  TaxSnapshotAsc = 'taxSnapshot_ASC',
  TaxSnapshotDesc = 'taxSnapshot_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type OrderTaxPreviousValues = {
  __typename?: 'OrderTaxPreviousValues';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  inclusive?: Maybe<Scalars['Boolean']>;
  taxSnapshot?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderTaxSubscriptionPayload = {
  __typename?: 'OrderTaxSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<OrderTax>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<OrderTaxPreviousValues>;
};

export type OrderTaxSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<OrderTaxWhereInput>;
  AND?: Maybe<Array<OrderTaxSubscriptionWhereInput>>;
  OR?: Maybe<Array<OrderTaxSubscriptionWhereInput>>;
  NOT?: Maybe<Array<OrderTaxSubscriptionWhereInput>>;
};

export type OrderTaxUpdateDataInput = {
  amount?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  inclusive?: Maybe<Scalars['Boolean']>;
  taxSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderTaxUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  inclusive?: Maybe<Scalars['Boolean']>;
  taxSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderTaxUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  inclusive?: Maybe<Scalars['Boolean']>;
  taxSnapshot?: Maybe<Scalars['Json']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderTaxUpdateOneInput = {
  create?: Maybe<OrderTaxCreateInput>;
  update?: Maybe<OrderTaxUpdateDataInput>;
  upsert?: Maybe<OrderTaxUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<OrderTaxWhereUniqueInput>;
};

export type OrderTaxUpsertNestedInput = {
  update: OrderTaxUpdateDataInput;
  create: OrderTaxCreateInput;
};

export type OrderTaxWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  amount_not?: Maybe<Scalars['Float']>;
  amount_in?: Maybe<Array<Scalars['Float']>>;
  amount_not_in?: Maybe<Array<Scalars['Float']>>;
  amount_lt?: Maybe<Scalars['Float']>;
  amount_lte?: Maybe<Scalars['Float']>;
  amount_gt?: Maybe<Scalars['Float']>;
  amount_gte?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  inclusive?: Maybe<Scalars['Boolean']>;
  inclusive_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<OrderTaxWhereInput>>;
  OR?: Maybe<Array<OrderTaxWhereInput>>;
  NOT?: Maybe<Array<OrderTaxWhereInput>>;
};

export type OrderTaxWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type OrderUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Boolean']>;
  orderNo?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
  status?: Maybe<OrderStatusUpdateOneRequiredInput>;
  customerUser?: Maybe<CustomerUpdateOneInput>;
  billingAddress?: Maybe<AddressUpdateOneRequiredInput>;
  shippingAddress?: Maybe<AddressUpdateOneRequiredInput>;
  merchant?: Maybe<MerchantUpdateOneRequiredInput>;
  deliveryConfig?: Maybe<DeliveryConfigUpdateOneInput>;
  orderItems?: Maybe<OrderItemUpdateManyInput>;
  orderNotes?: Maybe<OrderNoteUpdateManyWithoutOrderInput>;
  discounts?: Maybe<DiscountUpdateManyInput>;
  currency?: Maybe<Currency>;
  channel?: Maybe<Channel>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliverySafePlace?: Maybe<Scalars['String']>;
  canBookCourier?: Maybe<Scalars['Boolean']>;
  floomSnap?: Maybe<MediaUpdateOneInput>;
  newsletterSignupAt?: Maybe<Scalars['DateTime']>;
  deliverAt?: Maybe<Scalars['DateTime']>;
  orderedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Boolean']>;
  orderNo?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
  channel?: Maybe<Channel>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliverySafePlace?: Maybe<Scalars['String']>;
  deliverAt?: Maybe<Scalars['DateTime']>;
  orderedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Boolean']>;
  new_not?: Maybe<Scalars['Boolean']>;
  orderNo?: Maybe<Scalars['String']>;
  orderNo_not?: Maybe<Scalars['String']>;
  orderNo_in?: Maybe<Array<Scalars['String']>>;
  orderNo_not_in?: Maybe<Array<Scalars['String']>>;
  orderNo_lt?: Maybe<Scalars['String']>;
  orderNo_lte?: Maybe<Scalars['String']>;
  orderNo_gt?: Maybe<Scalars['String']>;
  orderNo_gte?: Maybe<Scalars['String']>;
  orderNo_contains?: Maybe<Scalars['String']>;
  orderNo_not_contains?: Maybe<Scalars['String']>;
  orderNo_starts_with?: Maybe<Scalars['String']>;
  orderNo_not_starts_with?: Maybe<Scalars['String']>;
  orderNo_ends_with?: Maybe<Scalars['String']>;
  orderNo_not_ends_with?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCode_not?: Maybe<Scalars['String']>;
  postalCode_in?: Maybe<Array<Scalars['String']>>;
  postalCode_not_in?: Maybe<Array<Scalars['String']>>;
  postalCode_lt?: Maybe<Scalars['String']>;
  postalCode_lte?: Maybe<Scalars['String']>;
  postalCode_gt?: Maybe<Scalars['String']>;
  postalCode_gte?: Maybe<Scalars['String']>;
  postalCode_contains?: Maybe<Scalars['String']>;
  postalCode_not_contains?: Maybe<Scalars['String']>;
  postalCode_starts_with?: Maybe<Scalars['String']>;
  postalCode_not_starts_with?: Maybe<Scalars['String']>;
  postalCode_ends_with?: Maybe<Scalars['String']>;
  postalCode_not_ends_with?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
  complete_not?: Maybe<Scalars['Boolean']>;
  status?: Maybe<OrderStatusWhereInput>;
  customerUser?: Maybe<CustomerWhereInput>;
  billingAddress?: Maybe<AddressWhereInput>;
  shippingAddress?: Maybe<AddressWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  orderItems_every?: Maybe<OrderItemWhereInput>;
  orderItems_some?: Maybe<OrderItemWhereInput>;
  orderItems_none?: Maybe<OrderItemWhereInput>;
  orderNotes_every?: Maybe<OrderNoteWhereInput>;
  orderNotes_some?: Maybe<OrderNoteWhereInput>;
  orderNotes_none?: Maybe<OrderNoteWhereInput>;
  orderDeliveryConfig?: Maybe<OrderDeliveryConfigWhereInput>;
  tax?: Maybe<OrderTaxWhereInput>;
  discounts_every?: Maybe<DiscountWhereInput>;
  discounts_some?: Maybe<DiscountWhereInput>;
  discounts_none?: Maybe<DiscountWhereInput>;
  currency?: Maybe<Currency>;
  currency_not?: Maybe<Currency>;
  currency_in?: Maybe<Array<Currency>>;
  currency_not_in?: Maybe<Array<Currency>>;
  channel?: Maybe<Channel>;
  channel_not?: Maybe<Channel>;
  channel_in?: Maybe<Array<Channel>>;
  channel_not_in?: Maybe<Array<Channel>>;
  payment?: Maybe<PaymentWhereInput>;
  transactions_every?: Maybe<TransactionWhereInput>;
  transactions_some?: Maybe<TransactionWhereInput>;
  transactions_none?: Maybe<TransactionWhereInput>;
  adjustments_every?: Maybe<AdjustmentWhereInput>;
  adjustments_some?: Maybe<AdjustmentWhereInput>;
  adjustments_none?: Maybe<AdjustmentWhereInput>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliveryInstructions_not?: Maybe<Scalars['String']>;
  deliveryInstructions_in?: Maybe<Array<Scalars['String']>>;
  deliveryInstructions_not_in?: Maybe<Array<Scalars['String']>>;
  deliveryInstructions_lt?: Maybe<Scalars['String']>;
  deliveryInstructions_lte?: Maybe<Scalars['String']>;
  deliveryInstructions_gt?: Maybe<Scalars['String']>;
  deliveryInstructions_gte?: Maybe<Scalars['String']>;
  deliveryInstructions_contains?: Maybe<Scalars['String']>;
  deliveryInstructions_not_contains?: Maybe<Scalars['String']>;
  deliveryInstructions_starts_with?: Maybe<Scalars['String']>;
  deliveryInstructions_not_starts_with?: Maybe<Scalars['String']>;
  deliveryInstructions_ends_with?: Maybe<Scalars['String']>;
  deliveryInstructions_not_ends_with?: Maybe<Scalars['String']>;
  deliverySafePlace?: Maybe<Scalars['String']>;
  deliverySafePlace_not?: Maybe<Scalars['String']>;
  deliverySafePlace_in?: Maybe<Array<Scalars['String']>>;
  deliverySafePlace_not_in?: Maybe<Array<Scalars['String']>>;
  deliverySafePlace_lt?: Maybe<Scalars['String']>;
  deliverySafePlace_lte?: Maybe<Scalars['String']>;
  deliverySafePlace_gt?: Maybe<Scalars['String']>;
  deliverySafePlace_gte?: Maybe<Scalars['String']>;
  deliverySafePlace_contains?: Maybe<Scalars['String']>;
  deliverySafePlace_not_contains?: Maybe<Scalars['String']>;
  deliverySafePlace_starts_with?: Maybe<Scalars['String']>;
  deliverySafePlace_not_starts_with?: Maybe<Scalars['String']>;
  deliverySafePlace_ends_with?: Maybe<Scalars['String']>;
  deliverySafePlace_not_ends_with?: Maybe<Scalars['String']>;
  courierBooking?: Maybe<CourierBookingWhereInput>;
  canBookCourier?: Maybe<Scalars['Boolean']>;
  canBookCourier_not?: Maybe<Scalars['Boolean']>;
  deliverAt?: Maybe<Scalars['DateTime']>;
  deliverAt_not?: Maybe<Scalars['DateTime']>;
  deliverAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deliverAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deliverAt_lt?: Maybe<Scalars['DateTime']>;
  deliverAt_lte?: Maybe<Scalars['DateTime']>;
  deliverAt_gt?: Maybe<Scalars['DateTime']>;
  deliverAt_gte?: Maybe<Scalars['DateTime']>;
  orderedAt?: Maybe<Scalars['DateTime']>;
  orderedAt_not?: Maybe<Scalars['DateTime']>;
  orderedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  orderedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  orderedAt_lt?: Maybe<Scalars['DateTime']>;
  orderedAt_lte?: Maybe<Scalars['DateTime']>;
  orderedAt_gt?: Maybe<Scalars['DateTime']>;
  orderedAt_gte?: Maybe<Scalars['DateTime']>;
  floomSnap?: Maybe<MediaWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<OrderWhereInput>>;
  OR?: Maybe<Array<OrderWhereInput>>;
  NOT?: Maybe<Array<OrderWhereInput>>;
  searchable_contains?: Maybe<Scalars['String']>;
  nationwide?: Maybe<Scalars['Boolean']>;
  nationwide_not?: Maybe<Scalars['Boolean']>;
  commercial?: Maybe<Scalars['Boolean']>;
  commercial_not?: Maybe<Scalars['Boolean']>;
};

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  orderNo?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Order>;
  merchant?: Maybe<Merchant>;
  courierBooking?: Maybe<CourierBooking>;
  type: PaymentType;
  transaction: TransactionType;
  amount?: Maybe<Scalars['Float']>;
  chargedAmount?: Maybe<Scalars['Float']>;
  discounted?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
  status?: Maybe<PaymentStatus>;
  statusMessage?: Maybe<Scalars['String']>;
  channelComplete?: Maybe<Channel>;
  lastStripePaymentIntentKey?: Maybe<Scalars['String']>;
  stripeFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  invoice?: Maybe<Invoice>;
};

export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PaymentEdge>>;
  aggregate: AggregatePayment;
};

export type PaymentCreateManyWithoutMerchantInput = {
  create?: Maybe<Array<PaymentCreateWithoutMerchantInput>>;
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
};

export type PaymentCreateWithoutMerchantInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PaymentType>;
  transaction: TransactionType;
  amount?: Maybe<Scalars['Float']>;
  chargedAmount?: Maybe<Scalars['Float']>;
  discounted?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
  status?: Maybe<PaymentStatus>;
  statusMessage?: Maybe<Scalars['String']>;
  channelComplete?: Maybe<Channel>;
  lastStripePaymentIntentKey?: Maybe<Scalars['String']>;
  stripeFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
};

export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  node: Payment;
  cursor: Scalars['String'];
};

export type PaymentIntentResponse = {
  __typename?: 'PaymentIntentResponse';
  id?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: PaymentMethodType;
  brand?: Maybe<Scalars['String']>;
  lastFourCardDigits: Scalars['String'];
  expiryMonth?: Maybe<Scalars['Int']>;
  expiryYear?: Maybe<Scalars['Int']>;
  isExpiring: Scalars['Boolean'];
  merchant?: Maybe<Merchant>;
};

export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<PaymentMethodEdge>>;
  aggregate: AggregatePaymentMethod;
};

export type PaymentMethodCreateOneWithoutMerchantInput = {
  create?: Maybe<PaymentMethodCreateWithoutMerchantInput>;
  connect?: Maybe<PaymentMethodWhereUniqueInput>;
};

export type PaymentMethodCreateWithoutMerchantInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: PaymentMethodType;
  lastFourCardDigits: Scalars['String'];
  paymentMethodToken: Scalars['String'];
};

export type PaymentMethodEdge = {
  __typename?: 'PaymentMethodEdge';
  node: PaymentMethod;
  cursor: Scalars['String'];
};

export enum PaymentMethodOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  LastFourCardDigitsAsc = 'lastFourCardDigits_ASC',
  LastFourCardDigitsDesc = 'lastFourCardDigits_DESC'
}

export type PaymentMethodPreviousValues = {
  __typename?: 'PaymentMethodPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: PaymentMethodType;
  brand?: Maybe<Scalars['String']>;
  lastFourCardDigits: Scalars['String'];
  expiryMonth?: Maybe<Scalars['Int']>;
  expiryYear?: Maybe<Scalars['Int']>;
  isExpiring: Scalars['Boolean'];
};

export type PaymentMethodSubscriptionPayload = {
  __typename?: 'PaymentMethodSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PaymentMethod>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PaymentMethodPreviousValues>;
};

export enum PaymentMethodType {
  Card = 'Card'
}

export type PaymentMethodWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PaymentMethodType>;
  type_not?: Maybe<PaymentMethodType>;
  type_in?: Maybe<Array<PaymentMethodType>>;
  type_not_in?: Maybe<Array<PaymentMethodType>>;
  lastFourCardDigits?: Maybe<Scalars['String']>;
  lastFourCardDigits_not?: Maybe<Scalars['String']>;
  lastFourCardDigits_in?: Maybe<Array<Scalars['String']>>;
  lastFourCardDigits_not_in?: Maybe<Array<Scalars['String']>>;
  lastFourCardDigits_lt?: Maybe<Scalars['String']>;
  lastFourCardDigits_lte?: Maybe<Scalars['String']>;
  lastFourCardDigits_gt?: Maybe<Scalars['String']>;
  lastFourCardDigits_gte?: Maybe<Scalars['String']>;
  lastFourCardDigits_contains?: Maybe<Scalars['String']>;
  lastFourCardDigits_not_contains?: Maybe<Scalars['String']>;
  lastFourCardDigits_starts_with?: Maybe<Scalars['String']>;
  lastFourCardDigits_not_starts_with?: Maybe<Scalars['String']>;
  lastFourCardDigits_ends_with?: Maybe<Scalars['String']>;
  lastFourCardDigits_not_ends_with?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereInput>;
  AND?: Maybe<Array<PaymentMethodWhereInput>>;
  OR?: Maybe<Array<PaymentMethodWhereInput>>;
  NOT?: Maybe<Array<PaymentMethodWhereInput>>;
};

export type PaymentMethodWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum PaymentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TransactionAsc = 'transaction_ASC',
  TransactionDesc = 'transaction_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  ChargedAmountAsc = 'chargedAmount_ASC',
  ChargedAmountDesc = 'chargedAmount_DESC',
  DiscountedAsc = 'discounted_ASC',
  DiscountedDesc = 'discounted_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  StatusMessageAsc = 'statusMessage_ASC',
  StatusMessageDesc = 'statusMessage_DESC',
  ChannelCompleteAsc = 'channelComplete_ASC',
  ChannelCompleteDesc = 'channelComplete_DESC',
  LastStripePaymentIntentKeyAsc = 'lastStripePaymentIntentKey_ASC',
  LastStripePaymentIntentKeyDesc = 'lastStripePaymentIntentKey_DESC',
  StripeFeeAsc = 'stripeFee_ASC',
  StripeFeeDesc = 'stripeFee_DESC',
  ServiceFeeAsc = 'serviceFee_ASC',
  ServiceFeeDesc = 'serviceFee_DESC'
}

export type PaymentPreviousValues = {
  __typename?: 'PaymentPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: PaymentType;
  transaction: TransactionType;
  amount?: Maybe<Scalars['Float']>;
  chargedAmount?: Maybe<Scalars['Float']>;
  discounted?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
  status?: Maybe<PaymentStatus>;
  statusMessage?: Maybe<Scalars['String']>;
  channelComplete?: Maybe<Channel>;
  lastStripePaymentIntentKey?: Maybe<Scalars['String']>;
  stripeFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
};

export type PaymentScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<PaymentType>;
  type_not?: Maybe<PaymentType>;
  type_in?: Maybe<Array<PaymentType>>;
  type_not_in?: Maybe<Array<PaymentType>>;
  transaction?: Maybe<TransactionType>;
  transaction_not?: Maybe<TransactionType>;
  transaction_in?: Maybe<Array<TransactionType>>;
  transaction_not_in?: Maybe<Array<TransactionType>>;
  amount?: Maybe<Scalars['Float']>;
  amount_not?: Maybe<Scalars['Float']>;
  amount_in?: Maybe<Array<Scalars['Float']>>;
  amount_not_in?: Maybe<Array<Scalars['Float']>>;
  amount_lt?: Maybe<Scalars['Float']>;
  amount_lte?: Maybe<Scalars['Float']>;
  amount_gt?: Maybe<Scalars['Float']>;
  amount_gte?: Maybe<Scalars['Float']>;
  chargedAmount?: Maybe<Scalars['Float']>;
  chargedAmount_not?: Maybe<Scalars['Float']>;
  chargedAmount_in?: Maybe<Array<Scalars['Float']>>;
  chargedAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  chargedAmount_lt?: Maybe<Scalars['Float']>;
  chargedAmount_lte?: Maybe<Scalars['Float']>;
  chargedAmount_gt?: Maybe<Scalars['Float']>;
  chargedAmount_gte?: Maybe<Scalars['Float']>;
  discounted?: Maybe<Scalars['Boolean']>;
  discounted_not?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
  currency_not?: Maybe<Currency>;
  currency_in?: Maybe<Array<Currency>>;
  currency_not_in?: Maybe<Array<Currency>>;
  status?: Maybe<PaymentStatus>;
  status_not?: Maybe<PaymentStatus>;
  status_in?: Maybe<Array<PaymentStatus>>;
  status_not_in?: Maybe<Array<PaymentStatus>>;
  statusMessage?: Maybe<Scalars['String']>;
  statusMessage_not?: Maybe<Scalars['String']>;
  statusMessage_in?: Maybe<Array<Scalars['String']>>;
  statusMessage_not_in?: Maybe<Array<Scalars['String']>>;
  statusMessage_lt?: Maybe<Scalars['String']>;
  statusMessage_lte?: Maybe<Scalars['String']>;
  statusMessage_gt?: Maybe<Scalars['String']>;
  statusMessage_gte?: Maybe<Scalars['String']>;
  statusMessage_contains?: Maybe<Scalars['String']>;
  statusMessage_not_contains?: Maybe<Scalars['String']>;
  statusMessage_starts_with?: Maybe<Scalars['String']>;
  statusMessage_not_starts_with?: Maybe<Scalars['String']>;
  statusMessage_ends_with?: Maybe<Scalars['String']>;
  statusMessage_not_ends_with?: Maybe<Scalars['String']>;
  channelComplete?: Maybe<Channel>;
  channelComplete_not?: Maybe<Channel>;
  channelComplete_in?: Maybe<Array<Channel>>;
  channelComplete_not_in?: Maybe<Array<Channel>>;
  lastStripePaymentIntentKey?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_not?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_in?: Maybe<Array<Scalars['String']>>;
  lastStripePaymentIntentKey_not_in?: Maybe<Array<Scalars['String']>>;
  lastStripePaymentIntentKey_lt?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_lte?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_gt?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_gte?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_contains?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_not_contains?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_starts_with?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_not_starts_with?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_ends_with?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_not_ends_with?: Maybe<Scalars['String']>;
  stripeFee?: Maybe<Scalars['Float']>;
  stripeFee_not?: Maybe<Scalars['Float']>;
  stripeFee_in?: Maybe<Array<Scalars['Float']>>;
  stripeFee_not_in?: Maybe<Array<Scalars['Float']>>;
  stripeFee_lt?: Maybe<Scalars['Float']>;
  stripeFee_lte?: Maybe<Scalars['Float']>;
  stripeFee_gt?: Maybe<Scalars['Float']>;
  stripeFee_gte?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  serviceFee_not?: Maybe<Scalars['Float']>;
  serviceFee_in?: Maybe<Array<Scalars['Float']>>;
  serviceFee_not_in?: Maybe<Array<Scalars['Float']>>;
  serviceFee_lt?: Maybe<Scalars['Float']>;
  serviceFee_lte?: Maybe<Scalars['Float']>;
  serviceFee_gt?: Maybe<Scalars['Float']>;
  serviceFee_gte?: Maybe<Scalars['Float']>;
  AND?: Maybe<Array<PaymentScalarWhereInput>>;
  OR?: Maybe<Array<PaymentScalarWhereInput>>;
  NOT?: Maybe<Array<PaymentScalarWhereInput>>;
};

export enum PaymentStatus {
  Initialised = 'Initialised',
  Processing = 'Processing',
  Failed = 'Failed',
  Captured = 'Captured',
  Completed = 'Completed',
  External = 'External'
}

export type PaymentSubscriptionPayload = {
  __typename?: 'PaymentSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Payment>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<PaymentPreviousValues>;
};

export type PaymentSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<PaymentWhereInput>;
  AND?: Maybe<Array<PaymentSubscriptionWhereInput>>;
  OR?: Maybe<Array<PaymentSubscriptionWhereInput>>;
  NOT?: Maybe<Array<PaymentSubscriptionWhereInput>>;
};

export enum PaymentType {
  Courier = 'Courier',
  Wholesale = 'Wholesale',
  Order = 'Order',
  Subscription = 'Subscription',
  External = 'External'
}

export type PaymentWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  order?: Maybe<OrderWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  courierBooking?: Maybe<CourierBookingWhereInput>;
  type?: Maybe<PaymentType>;
  type_not?: Maybe<PaymentType>;
  type_in?: Maybe<Array<PaymentType>>;
  type_not_in?: Maybe<Array<PaymentType>>;
  transaction?: Maybe<TransactionType>;
  transaction_not?: Maybe<TransactionType>;
  transaction_in?: Maybe<Array<TransactionType>>;
  transaction_not_in?: Maybe<Array<TransactionType>>;
  amount?: Maybe<Scalars['Float']>;
  amount_not?: Maybe<Scalars['Float']>;
  amount_in?: Maybe<Array<Scalars['Float']>>;
  amount_not_in?: Maybe<Array<Scalars['Float']>>;
  amount_lt?: Maybe<Scalars['Float']>;
  amount_lte?: Maybe<Scalars['Float']>;
  amount_gt?: Maybe<Scalars['Float']>;
  amount_gte?: Maybe<Scalars['Float']>;
  chargedAmount?: Maybe<Scalars['Float']>;
  chargedAmount_not?: Maybe<Scalars['Float']>;
  chargedAmount_in?: Maybe<Array<Scalars['Float']>>;
  chargedAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  chargedAmount_lt?: Maybe<Scalars['Float']>;
  chargedAmount_lte?: Maybe<Scalars['Float']>;
  chargedAmount_gt?: Maybe<Scalars['Float']>;
  chargedAmount_gte?: Maybe<Scalars['Float']>;
  discounted?: Maybe<Scalars['Boolean']>;
  discounted_not?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
  currency_not?: Maybe<Currency>;
  currency_in?: Maybe<Array<Currency>>;
  currency_not_in?: Maybe<Array<Currency>>;
  status?: Maybe<PaymentStatus>;
  status_not?: Maybe<PaymentStatus>;
  status_in?: Maybe<Array<PaymentStatus>>;
  status_not_in?: Maybe<Array<PaymentStatus>>;
  statusMessage?: Maybe<Scalars['String']>;
  statusMessage_not?: Maybe<Scalars['String']>;
  statusMessage_in?: Maybe<Array<Scalars['String']>>;
  statusMessage_not_in?: Maybe<Array<Scalars['String']>>;
  statusMessage_lt?: Maybe<Scalars['String']>;
  statusMessage_lte?: Maybe<Scalars['String']>;
  statusMessage_gt?: Maybe<Scalars['String']>;
  statusMessage_gte?: Maybe<Scalars['String']>;
  statusMessage_contains?: Maybe<Scalars['String']>;
  statusMessage_not_contains?: Maybe<Scalars['String']>;
  statusMessage_starts_with?: Maybe<Scalars['String']>;
  statusMessage_not_starts_with?: Maybe<Scalars['String']>;
  statusMessage_ends_with?: Maybe<Scalars['String']>;
  statusMessage_not_ends_with?: Maybe<Scalars['String']>;
  channelComplete?: Maybe<Channel>;
  channelComplete_not?: Maybe<Channel>;
  channelComplete_in?: Maybe<Array<Channel>>;
  channelComplete_not_in?: Maybe<Array<Channel>>;
  lastStripePaymentIntentKey?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_not?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_in?: Maybe<Array<Scalars['String']>>;
  lastStripePaymentIntentKey_not_in?: Maybe<Array<Scalars['String']>>;
  lastStripePaymentIntentKey_lt?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_lte?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_gt?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_gte?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_contains?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_not_contains?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_starts_with?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_not_starts_with?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_ends_with?: Maybe<Scalars['String']>;
  lastStripePaymentIntentKey_not_ends_with?: Maybe<Scalars['String']>;
  stripeFee?: Maybe<Scalars['Float']>;
  stripeFee_not?: Maybe<Scalars['Float']>;
  stripeFee_in?: Maybe<Array<Scalars['Float']>>;
  stripeFee_not_in?: Maybe<Array<Scalars['Float']>>;
  stripeFee_lt?: Maybe<Scalars['Float']>;
  stripeFee_lte?: Maybe<Scalars['Float']>;
  stripeFee_gt?: Maybe<Scalars['Float']>;
  stripeFee_gte?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  serviceFee_not?: Maybe<Scalars['Float']>;
  serviceFee_in?: Maybe<Array<Scalars['Float']>>;
  serviceFee_not_in?: Maybe<Array<Scalars['Float']>>;
  serviceFee_lt?: Maybe<Scalars['Float']>;
  serviceFee_lte?: Maybe<Scalars['Float']>;
  serviceFee_gt?: Maybe<Scalars['Float']>;
  serviceFee_gte?: Maybe<Scalars['Float']>;
  invoice?: Maybe<InvoiceWhereInput>;
  AND?: Maybe<Array<PaymentWhereInput>>;
  OR?: Maybe<Array<PaymentWhereInput>>;
  NOT?: Maybe<Array<PaymentWhereInput>>;
};

export type PaymentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum PlanFeature {
  Wholesale = 'Wholesale',
  Courier = 'Courier',
  Floom = 'Floom',
  Website = 'Website',
  CollectionManager = 'CollectionManager',
  CollectionSeller = 'CollectionSeller',
  MerchantHoliday = 'MerchantHoliday',
  Products = 'Products',
  Orders = 'Orders'
}

export enum PlanType {
  Basic = 'Basic',
  Advanced = 'Advanced',
  AdvancedWithFloom = 'AdvancedWithFloom',
  Lite1 = 'Lite1',
  Lite2 = 'Lite2',
  Standard = 'Standard',
  Standard2 = 'Standard2',
  Premium = 'Premium',
  Premium2 = 'Premium2',
  StandardLegacy = 'StandardLegacy',
  NonFloristLegacy = 'NonFloristLegacy',
  CollectionManager = 'CollectionManager',
  FloomMarket = 'FloomMarket',
  WholesaleOnly = 'WholesaleOnly'
}

export type PreOrderCustomItemMetadataItem = {
  title: Scalars['String'];
  quantity: Scalars['Int'];
};

export type PreOrderListItemMetadataItem = {
  listItemId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  description: Scalars['String'];
  internalDescription?: Maybe<Scalars['String']>;
  merchant: Merchant;
  deliveryConfigs?: Maybe<Array<DeliveryConfig>>;
  variations?: Maybe<Array<ProductVariation>>;
  slug: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<ChannelType>>;
  type?: Maybe<Array<ProductType>>;
  categories?: Maybe<Array<ProductCategory>>;
  promotable?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  addons?: Maybe<Array<Maybe<Addon>>>;
  availability?: Maybe<Array<MerchantHoliday>>;
  collectionSellerProductConfigs?: Maybe<Array<CollectionSellerProductConfig>>;
  isEnabledForSale: Scalars['Boolean'];
};


export type ProductDeliveryConfigsArgs = {
  where?: Maybe<DeliveryConfigWhereInput>;
  orderBy?: Maybe<DeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProductVariationsArgs = {
  where?: Maybe<ProductVariationWhereInput>;
  orderBy?: Maybe<ProductVariationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProductChannelsArgs = {
  where?: Maybe<ChannelTypeWhereInput>;
  orderBy?: Maybe<ChannelTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProductTypeArgs = {
  where?: Maybe<ProductTypeWhereInput>;
  orderBy?: Maybe<ProductTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProductCategoriesArgs = {
  where?: Maybe<ProductCategoryWhereInput>;
  orderBy?: Maybe<ProductCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProductAddonsArgs = {
  where?: Maybe<AddonWhereInput>;
  orderBy?: Maybe<AddonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProductAvailabilityArgs = {
  where?: Maybe<MerchantHolidayWhereInput>;
  orderBy?: Maybe<MerchantHolidayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProductCollectionSellerProductConfigsArgs = {
  where?: Maybe<CollectionSellerProductConfigWhereInput>;
  orderBy?: Maybe<CollectionSellerProductConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  slug: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCategoryConnection = {
  __typename?: 'ProductCategoryConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProductCategoryEdge>>;
  aggregate: AggregateProductCategory;
};

export type ProductCategoryCreateInput = {
  active: Scalars['Boolean'];
  flc_id?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  slug: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCategoryCreateManyInput = {
  connect?: Maybe<Array<ProductCategoryWhereUniqueInput>>;
  create?: Maybe<Array<ProductCategoryCreateInput>>;
};

export type ProductCategoryEdge = {
  __typename?: 'ProductCategoryEdge';
  node: ProductCategory;
  cursor: Scalars['String'];
};

export enum ProductCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type ProductCategoryPreviousValues = {
  __typename?: 'ProductCategoryPreviousValues';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  title: Scalars['String'];
  slug: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCategoryScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ProductCategoryScalarWhereInput>>;
  OR?: Maybe<Array<ProductCategoryScalarWhereInput>>;
  NOT?: Maybe<Array<ProductCategoryScalarWhereInput>>;
};

export type ProductCategorySubscriptionPayload = {
  __typename?: 'ProductCategorySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ProductCategory>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ProductCategoryPreviousValues>;
};

export type ProductCategorySubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ProductCategoryWhereInput>;
  AND?: Maybe<Array<ProductCategorySubscriptionWhereInput>>;
  OR?: Maybe<Array<ProductCategorySubscriptionWhereInput>>;
  NOT?: Maybe<Array<ProductCategorySubscriptionWhereInput>>;
};

export type ProductCategoryUpdateDataInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCategoryUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCategoryUpdateManyDataInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCategoryUpdateManyInput = {
  connect?: Maybe<Array<ProductCategoryWhereUniqueInput>>;
  set?: Maybe<Array<ProductCategoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductCategoryWhereUniqueInput>>;
};

export type ProductCategoryUpdateManyMutationInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductCategoryUpdateManyWithWhereNestedInput = {
  where: ProductCategoryScalarWhereInput;
  data: ProductCategoryUpdateManyDataInput;
};

export type ProductCategoryUpdateWithWhereUniqueNestedInput = {
  where: ProductCategoryWhereUniqueInput;
  data: ProductCategoryUpdateDataInput;
};

export type ProductCategoryUpsertWithWhereUniqueNestedInput = {
  where: ProductCategoryWhereUniqueInput;
  update: ProductCategoryUpdateDataInput;
  create: ProductCategoryCreateInput;
};

export type ProductCategoryWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ProductCategoryWhereInput>>;
  OR?: Maybe<Array<ProductCategoryWhereInput>>;
  NOT?: Maybe<Array<ProductCategoryWhereInput>>;
};

export type ProductCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProductEdge>>;
  aggregate: AggregateProduct;
};

export type ProductCreateInput = {
  active: Scalars['Boolean'];
  title: Scalars['String'];
  description: Scalars['String'];
  internalDescription?: Maybe<Scalars['String']>;
  deliveryConfigs?: Maybe<DeliveryConfigCreateManyInput>;
  variations?: Maybe<ProductVariationCreateManyInput>;
  slug: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelTypeCreateManyInput>;
  type: ProductTypeCreateManyInput;
  categories?: Maybe<ProductCategoryCreateManyInput>;
  promotable?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  merchant: MerchantCreateOneInput;
  availability?: Maybe<MerchantHolidayCreateManyInput>;
};

export type ProductCreateManyInput = {
  create?: Maybe<Array<ProductCreateInput>>;
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductCreateOneInput = {
  create?: Maybe<ProductCreateInput>;
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreateOneWithoutCollectionSellerProductConfigsInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductCreatechannelsInput = {
  set?: Maybe<Array<Channel>>;
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  node: Product;
  cursor: Scalars['String'];
};

export enum ProductOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  PublicIdAsc = 'publicId_ASC',
  PublicIdDesc = 'publicId_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  InternalDescriptionAsc = 'internalDescription_ASC',
  InternalDescriptionDesc = 'internalDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SkuAsc = 'sku_ASC',
  SkuDesc = 'sku_DESC',
  PromotableAsc = 'promotable_ASC',
  PromotableDesc = 'promotable_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type ProductPreviousValues = {
  __typename?: 'ProductPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  description: Scalars['String'];
  internalDescription?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  promotable?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  publicId_not?: Maybe<Scalars['String']>;
  publicId_in?: Maybe<Array<Scalars['String']>>;
  publicId_not_in?: Maybe<Array<Scalars['String']>>;
  publicId_lt?: Maybe<Scalars['String']>;
  publicId_lte?: Maybe<Scalars['String']>;
  publicId_gt?: Maybe<Scalars['String']>;
  publicId_gte?: Maybe<Scalars['String']>;
  publicId_contains?: Maybe<Scalars['String']>;
  publicId_not_contains?: Maybe<Scalars['String']>;
  publicId_starts_with?: Maybe<Scalars['String']>;
  publicId_not_starts_with?: Maybe<Scalars['String']>;
  publicId_ends_with?: Maybe<Scalars['String']>;
  publicId_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sku_not?: Maybe<Scalars['String']>;
  sku_in?: Maybe<Array<Scalars['String']>>;
  sku_not_in?: Maybe<Array<Scalars['String']>>;
  sku_lt?: Maybe<Scalars['String']>;
  sku_lte?: Maybe<Scalars['String']>;
  sku_gt?: Maybe<Scalars['String']>;
  sku_gte?: Maybe<Scalars['String']>;
  sku_contains?: Maybe<Scalars['String']>;
  sku_not_contains?: Maybe<Scalars['String']>;
  sku_starts_with?: Maybe<Scalars['String']>;
  sku_not_starts_with?: Maybe<Scalars['String']>;
  sku_ends_with?: Maybe<Scalars['String']>;
  sku_not_ends_with?: Maybe<Scalars['String']>;
  promotable?: Maybe<Scalars['Boolean']>;
  promotable_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ProductScalarWhereInput>>;
  OR?: Maybe<Array<ProductScalarWhereInput>>;
  NOT?: Maybe<Array<ProductScalarWhereInput>>;
};

export type ProductSubscriptionPayload = {
  __typename?: 'ProductSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Product>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ProductPreviousValues>;
};

export type ProductSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ProductWhereInput>;
  AND?: Maybe<Array<ProductSubscriptionWhereInput>>;
  OR?: Maybe<Array<ProductSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ProductSubscriptionWhereInput>>;
};

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  title: Scalars['String'];
  slug: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductTypeConnectManyInput = {
  connect?: Maybe<Array<ProductTypeWhereUniqueInput>>;
};

export type ProductTypeConnection = {
  __typename?: 'ProductTypeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProductTypeEdge>>;
  aggregate: AggregateProductType;
};

export type ProductTypeCreateInput = {
  active: Scalars['Boolean'];
  title: Scalars['String'];
  slug: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductTypeCreateManyInput = {
  connect?: Maybe<Array<ProductTypeWhereUniqueInput>>;
};

export type ProductTypeEdge = {
  __typename?: 'ProductTypeEdge';
  node: ProductType;
  cursor: Scalars['String'];
};

export enum ProductTypeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type ProductTypePreviousValues = {
  __typename?: 'ProductTypePreviousValues';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  title: Scalars['String'];
  slug: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductTypeScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ProductTypeScalarWhereInput>>;
  OR?: Maybe<Array<ProductTypeScalarWhereInput>>;
  NOT?: Maybe<Array<ProductTypeScalarWhereInput>>;
};

export type ProductTypeSubscriptionPayload = {
  __typename?: 'ProductTypeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ProductType>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ProductTypePreviousValues>;
};

export type ProductTypeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ProductTypeWhereInput>;
  AND?: Maybe<Array<ProductTypeSubscriptionWhereInput>>;
  OR?: Maybe<Array<ProductTypeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ProductTypeSubscriptionWhereInput>>;
};

export type ProductTypeUpdateDataInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductTypeUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductTypeUpdateManyDataInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductTypeUpdateManyInput = {
  connect?: Maybe<Array<ProductTypeWhereUniqueInput>>;
  set?: Maybe<Array<ProductTypeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductTypeWhereUniqueInput>>;
};

export type ProductTypeUpdateManyMutationInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductTypeUpdateManyWithWhereNestedInput = {
  where: ProductTypeScalarWhereInput;
  data: ProductTypeUpdateManyDataInput;
};

export type ProductTypeUpdateWithWhereUniqueNestedInput = {
  where: ProductTypeWhereUniqueInput;
  data: ProductTypeUpdateDataInput;
};

export type ProductTypeUpsertWithWhereUniqueNestedInput = {
  where: ProductTypeWhereUniqueInput;
  update: ProductTypeUpdateDataInput;
  create: ProductTypeCreateInput;
};

export type ProductTypeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ProductTypeWhereInput>>;
  OR?: Maybe<Array<ProductTypeWhereInput>>;
  NOT?: Maybe<Array<ProductTypeWhereInput>>;
};

export type ProductTypeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};

export type ProductUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  internalDescription?: Maybe<Scalars['String']>;
  deliveryConfigs?: Maybe<DeliveryConfigUpdateManyInput>;
  variations?: Maybe<ProductVariationUpdateManyInput>;
  slug?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  channels?: Maybe<ChannelTypeUpdateManyInput>;
  type?: Maybe<ProductTypeUpdateManyInput>;
  categories?: Maybe<ProductCategoryUpdateManyInput>;
  promotable?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  availability?: Maybe<MerchantHolidayUpdateManyInput>;
};

export type ProductUpdateManyInput = {
  connect?: Maybe<Array<ProductWhereUniqueInput>>;
  set?: Maybe<Array<ProductWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProductWhereUniqueInput>>;
};

export type ProductUpdateOneInput = {
  connect?: Maybe<ProductWhereUniqueInput>;
};

export type ProductVariation = {
  __typename?: 'ProductVariation';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  media?: Maybe<Array<Media>>;
  product?: Maybe<Product>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<ProductVariationType>;
  price: Scalars['Float'];
  stock?: Maybe<Scalars['Int']>;
  default: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dimensions?: Maybe<Dimensions>;
  recipe?: Maybe<Recipe>;
  colours?: Maybe<Array<Colour>>;
};


export type ProductVariationMediaArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<MediaOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProductVariationColoursArgs = {
  where?: Maybe<ColourWhereInput>;
  orderBy?: Maybe<ColourOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductVariationConnectInput = {
  connect?: Maybe<ProductVariationWhereUniqueInput>;
};

export type ProductVariationConnectOneInput = {
  connect?: Maybe<ProductVariationWhereUniqueInput>;
};

export type ProductVariationConnection = {
  __typename?: 'ProductVariationConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProductVariationEdge>>;
  aggregate: AggregateProductVariation;
};

export type ProductVariationCreateInput = {
  active: Scalars['Boolean'];
  addons?: Maybe<AddonCreateManyInput>;
  colours?: Maybe<ColourCreateManyInput>;
  default: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  dimensions?: Maybe<DimensionsCreateOneInput>;
  flc_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  media?: Maybe<MediaCreateManyInput>;
  price: Scalars['Float'];
  recipe?: Maybe<RecipeCreateOneInput>;
  sku?: Maybe<Scalars['String']>;
  stock?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type?: Maybe<ProductVariationType>;
};

export type ProductVariationCreateManyInput = {
  create?: Maybe<Array<ProductVariationCreateInput>>;
  connect?: Maybe<ProductVariationWhereUniqueInput>;
};

export type ProductVariationEdge = {
  __typename?: 'ProductVariationEdge';
  node: ProductVariation;
  cursor: Scalars['String'];
};

export enum ProductVariationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SkuAsc = 'sku_ASC',
  SkuDesc = 'sku_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  StockAsc = 'stock_ASC',
  StockDesc = 'stock_DESC',
  DefaultAsc = 'default_ASC',
  DefaultDesc = 'default_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type ProductVariationPreviousValues = {
  __typename?: 'ProductVariationPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<ProductVariationType>;
  price: Scalars['Float'];
  stock?: Maybe<Scalars['Int']>;
  default: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductVariationScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sku_not?: Maybe<Scalars['String']>;
  sku_in?: Maybe<Array<Scalars['String']>>;
  sku_not_in?: Maybe<Array<Scalars['String']>>;
  sku_lt?: Maybe<Scalars['String']>;
  sku_lte?: Maybe<Scalars['String']>;
  sku_gt?: Maybe<Scalars['String']>;
  sku_gte?: Maybe<Scalars['String']>;
  sku_contains?: Maybe<Scalars['String']>;
  sku_not_contains?: Maybe<Scalars['String']>;
  sku_starts_with?: Maybe<Scalars['String']>;
  sku_not_starts_with?: Maybe<Scalars['String']>;
  sku_ends_with?: Maybe<Scalars['String']>;
  sku_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<ProductVariationType>;
  type_not?: Maybe<ProductVariationType>;
  type_in?: Maybe<Array<ProductVariationType>>;
  type_not_in?: Maybe<Array<ProductVariationType>>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  stock_not?: Maybe<Scalars['Int']>;
  stock_in?: Maybe<Array<Scalars['Int']>>;
  stock_not_in?: Maybe<Array<Scalars['Int']>>;
  stock_lt?: Maybe<Scalars['Int']>;
  stock_lte?: Maybe<Scalars['Int']>;
  stock_gt?: Maybe<Scalars['Int']>;
  stock_gte?: Maybe<Scalars['Int']>;
  default?: Maybe<Scalars['Boolean']>;
  default_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ProductVariationScalarWhereInput>>;
  OR?: Maybe<Array<ProductVariationScalarWhereInput>>;
  NOT?: Maybe<Array<ProductVariationScalarWhereInput>>;
};

export type ProductVariationSubscriptionPayload = {
  __typename?: 'ProductVariationSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ProductVariation>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ProductVariationPreviousValues>;
};

export type ProductVariationSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ProductVariationWhereInput>;
  AND?: Maybe<Array<ProductVariationSubscriptionWhereInput>>;
  OR?: Maybe<Array<ProductVariationSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ProductVariationSubscriptionWhereInput>>;
};

export enum ProductVariationType {
  Default = 'Default',
  Deluxe = 'Deluxe',
  Premium = 'Premium',
  Original = 'Original',
  Superior = 'Superior'
}

export type ProductVariationUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  media?: Maybe<MediaUpdateManyInput>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<ProductVariationType>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  default?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  dimensions?: Maybe<DimensionsUpdateOneInput>;
  recipe?: Maybe<RecipeUpdateOneInput>;
  addons?: Maybe<AddonUpdateManyInput>;
  colours?: Maybe<ColourUpdateManyInput>;
};

export type ProductVariationUpdateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  media?: Maybe<MediaUpdateManyInput>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<ProductVariationType>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  default?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  dimensions?: Maybe<DimensionsUpdateOneInput>;
  recipe?: Maybe<RecipeUpdateOneInput>;
  colours?: Maybe<ColourUpdateManyInput>;
};

export type ProductVariationUpdateManyDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<ProductVariationType>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  default?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductVariationUpdateManyInput = {
  create?: Maybe<Array<ProductVariationCreateInput>>;
  connect?: Maybe<ProductVariationWhereUniqueInput>;
  update?: Maybe<Array<ProductVariationUpdateWithWhereUniqueNestedInput>>;
};

export type ProductVariationUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<ProductVariationType>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  default?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductVariationUpdateManyWithWhereNestedInput = {
  where: ProductVariationScalarWhereInput;
  data: ProductVariationUpdateManyDataInput;
};

export type ProductVariationUpdateWithWhereUniqueNestedInput = {
  where: ProductVariationWhereUniqueInput;
  data: ProductVariationUpdateDataInput;
};

export type ProductVariationUpsertWithWhereUniqueNestedInput = {
  where: ProductVariationWhereUniqueInput;
  update: ProductVariationUpdateDataInput;
  create: ProductVariationCreateInput;
};

export type ProductVariationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  media_every?: Maybe<MediaWhereInput>;
  media_some?: Maybe<MediaWhereInput>;
  media_none?: Maybe<MediaWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  sku?: Maybe<Scalars['String']>;
  sku_not?: Maybe<Scalars['String']>;
  sku_in?: Maybe<Array<Scalars['String']>>;
  sku_not_in?: Maybe<Array<Scalars['String']>>;
  sku_lt?: Maybe<Scalars['String']>;
  sku_lte?: Maybe<Scalars['String']>;
  sku_gt?: Maybe<Scalars['String']>;
  sku_gte?: Maybe<Scalars['String']>;
  sku_contains?: Maybe<Scalars['String']>;
  sku_not_contains?: Maybe<Scalars['String']>;
  sku_starts_with?: Maybe<Scalars['String']>;
  sku_not_starts_with?: Maybe<Scalars['String']>;
  sku_ends_with?: Maybe<Scalars['String']>;
  sku_not_ends_with?: Maybe<Scalars['String']>;
  type?: Maybe<ProductVariationType>;
  type_not?: Maybe<ProductVariationType>;
  type_in?: Maybe<Array<ProductVariationType>>;
  type_not_in?: Maybe<Array<ProductVariationType>>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['Int']>;
  stock_not?: Maybe<Scalars['Int']>;
  stock_in?: Maybe<Array<Scalars['Int']>>;
  stock_not_in?: Maybe<Array<Scalars['Int']>>;
  stock_lt?: Maybe<Scalars['Int']>;
  stock_lte?: Maybe<Scalars['Int']>;
  stock_gt?: Maybe<Scalars['Int']>;
  stock_gte?: Maybe<Scalars['Int']>;
  default?: Maybe<Scalars['Boolean']>;
  default_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  dimensions?: Maybe<DimensionsWhereInput>;
  recipe?: Maybe<RecipeWhereInput>;
  colours_every?: Maybe<ColourWhereInput>;
  colours_some?: Maybe<ColourWhereInput>;
  colours_none?: Maybe<ColourWhereInput>;
  AND?: Maybe<Array<ProductVariationWhereInput>>;
  OR?: Maybe<Array<ProductVariationWhereInput>>;
  NOT?: Maybe<Array<ProductVariationWhereInput>>;
};

export type ProductVariationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
};

export type ProductWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  publicId_not?: Maybe<Scalars['String']>;
  publicId_in?: Maybe<Array<Scalars['String']>>;
  publicId_not_in?: Maybe<Array<Scalars['String']>>;
  publicId_lt?: Maybe<Scalars['String']>;
  publicId_lte?: Maybe<Scalars['String']>;
  publicId_gt?: Maybe<Scalars['String']>;
  publicId_gte?: Maybe<Scalars['String']>;
  publicId_contains?: Maybe<Scalars['String']>;
  publicId_not_contains?: Maybe<Scalars['String']>;
  publicId_starts_with?: Maybe<Scalars['String']>;
  publicId_not_starts_with?: Maybe<Scalars['String']>;
  publicId_ends_with?: Maybe<Scalars['String']>;
  publicId_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Scalars['String']>>;
  description_not_in?: Maybe<Array<Scalars['String']>>;
  description_lt?: Maybe<Scalars['String']>;
  description_lte?: Maybe<Scalars['String']>;
  description_gt?: Maybe<Scalars['String']>;
  description_gte?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereInput>;
  deliveryConfigs_every?: Maybe<DeliveryConfigWhereInput>;
  deliveryConfigs_some?: Maybe<DeliveryConfigWhereInput>;
  deliveryConfigs_none?: Maybe<DeliveryConfigWhereInput>;
  variations_every?: Maybe<ProductVariationWhereInput>;
  variations_some?: Maybe<ProductVariationWhereInput>;
  variations_none?: Maybe<ProductVariationWhereInput>;
  slug?: Maybe<Scalars['String']>;
  slug_not?: Maybe<Scalars['String']>;
  slug_in?: Maybe<Array<Scalars['String']>>;
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  slug_lt?: Maybe<Scalars['String']>;
  slug_lte?: Maybe<Scalars['String']>;
  slug_gt?: Maybe<Scalars['String']>;
  slug_gte?: Maybe<Scalars['String']>;
  slug_contains?: Maybe<Scalars['String']>;
  slug_not_contains?: Maybe<Scalars['String']>;
  slug_starts_with?: Maybe<Scalars['String']>;
  slug_not_starts_with?: Maybe<Scalars['String']>;
  slug_ends_with?: Maybe<Scalars['String']>;
  slug_not_ends_with?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sku_not?: Maybe<Scalars['String']>;
  sku_in?: Maybe<Array<Scalars['String']>>;
  sku_not_in?: Maybe<Array<Scalars['String']>>;
  sku_lt?: Maybe<Scalars['String']>;
  sku_lte?: Maybe<Scalars['String']>;
  sku_gt?: Maybe<Scalars['String']>;
  sku_gte?: Maybe<Scalars['String']>;
  sku_contains?: Maybe<Scalars['String']>;
  sku_not_contains?: Maybe<Scalars['String']>;
  sku_starts_with?: Maybe<Scalars['String']>;
  sku_not_starts_with?: Maybe<Scalars['String']>;
  sku_ends_with?: Maybe<Scalars['String']>;
  sku_not_ends_with?: Maybe<Scalars['String']>;
  channels_every?: Maybe<ChannelTypeWhereInput>;
  channels_some?: Maybe<ChannelTypeWhereInput>;
  channels_none?: Maybe<ChannelTypeWhereInput>;
  type_every?: Maybe<ProductTypeWhereInput>;
  type_some?: Maybe<ProductTypeWhereInput>;
  type_none?: Maybe<ProductTypeWhereInput>;
  categories_every?: Maybe<ProductCategoryWhereInput>;
  categories_some?: Maybe<ProductCategoryWhereInput>;
  categories_none?: Maybe<ProductCategoryWhereInput>;
  promotable?: Maybe<Scalars['Boolean']>;
  promotable_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  collectionSellerProductConfigs_every?: Maybe<CollectionSellerProductConfigWhereInput>;
  collectionSellerProductConfigs_some?: Maybe<CollectionSellerProductConfigWhereInput>;
  collectionSellerProductConfigs_none?: Maybe<CollectionSellerProductConfigWhereInput>;
  AND?: Maybe<Array<ProductWhereInput>>;
  OR?: Maybe<Array<ProductWhereInput>>;
  NOT?: Maybe<Array<ProductWhereInput>>;
  searchable_contains?: Maybe<Scalars['String']>;
};

export type ProductWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  publicId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type PromotablePostcode = {
  __typename?: 'PromotablePostcode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  postcode: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
};

export type PromotablePostcodeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  postcode: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
};

export enum PromotablePostcodeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  PostcodeAsc = 'postcode_ASC',
  PostcodeDesc = 'postcode_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC'
}

export type PromotablePostcodeUpdateInput = {
  postcode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type PromotablePostcodeUpdateManyMutationInput = {
  postcode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type PromotablePostcodeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  postcode?: Maybe<Scalars['String']>;
  postcode_not?: Maybe<Scalars['String']>;
  postcode_in?: Maybe<Array<Scalars['String']>>;
  postcode_not_in?: Maybe<Array<Scalars['String']>>;
  postcode_lt?: Maybe<Scalars['String']>;
  postcode_lte?: Maybe<Scalars['String']>;
  postcode_gt?: Maybe<Scalars['String']>;
  postcode_gte?: Maybe<Scalars['String']>;
  postcode_contains?: Maybe<Scalars['String']>;
  postcode_not_contains?: Maybe<Scalars['String']>;
  postcode_starts_with?: Maybe<Scalars['String']>;
  postcode_not_starts_with?: Maybe<Scalars['String']>;
  postcode_ends_with?: Maybe<Scalars['String']>;
  postcode_not_ends_with?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  timezone_not?: Maybe<Scalars['String']>;
  timezone_in?: Maybe<Array<Scalars['String']>>;
  timezone_not_in?: Maybe<Array<Scalars['String']>>;
  timezone_lt?: Maybe<Scalars['String']>;
  timezone_lte?: Maybe<Scalars['String']>;
  timezone_gt?: Maybe<Scalars['String']>;
  timezone_gte?: Maybe<Scalars['String']>;
  timezone_contains?: Maybe<Scalars['String']>;
  timezone_not_contains?: Maybe<Scalars['String']>;
  timezone_starts_with?: Maybe<Scalars['String']>;
  timezone_not_starts_with?: Maybe<Scalars['String']>;
  timezone_ends_with?: Maybe<Scalars['String']>;
  timezone_not_ends_with?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  AND?: Maybe<Array<PromotablePostcodeWhereInput>>;
  OR?: Maybe<Array<PromotablePostcodeWhereInput>>;
  NOT?: Maybe<Array<PromotablePostcodeWhereInput>>;
};

export type PromotablePostcodeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type PromotedPeriod = {
  __typename?: 'PromotedPeriod';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
  dropDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type PromotedPeriodConnectOneInput = {
  connect?: Maybe<PromotedPeriodWhereUniqueInput>;
};

export type PromotedPeriodCreateInput = {
  id?: Maybe<Scalars['ID']>;
  timezone?: Maybe<Scalars['String']>;
  dropDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type PromotedPeriodCreateOneInput = {
  create?: Maybe<PromotedPeriodCreateInput>;
  connect?: Maybe<PromotedPeriodWhereUniqueInput>;
};

export enum PromotedPeriodOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  DropDateAsc = 'dropDate_ASC',
  DropDateDesc = 'dropDate_DESC',
  StartDateAsc = 'startDate_ASC',
  StartDateDesc = 'startDate_DESC',
  EndDateAsc = 'endDate_ASC',
  EndDateDesc = 'endDate_DESC'
}

export type PromotedPeriodUpdateDataInput = {
  dropDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
};

export type PromotedPeriodUpdateInput = {
  timezone?: Maybe<Scalars['String']>;
  dropDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type PromotedPeriodUpdateManyMutationInput = {
  dropDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
};

export type PromotedPeriodUpdateOneRequiredInput = {
  connect?: Maybe<PromotedPeriodWhereUniqueInput>;
};

export type PromotedPeriodWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
  timezone_not?: Maybe<Scalars['String']>;
  timezone_in?: Maybe<Array<Scalars['String']>>;
  timezone_not_in?: Maybe<Array<Scalars['String']>>;
  timezone_lt?: Maybe<Scalars['String']>;
  timezone_lte?: Maybe<Scalars['String']>;
  timezone_gt?: Maybe<Scalars['String']>;
  timezone_gte?: Maybe<Scalars['String']>;
  timezone_contains?: Maybe<Scalars['String']>;
  timezone_not_contains?: Maybe<Scalars['String']>;
  timezone_starts_with?: Maybe<Scalars['String']>;
  timezone_not_starts_with?: Maybe<Scalars['String']>;
  timezone_ends_with?: Maybe<Scalars['String']>;
  timezone_not_ends_with?: Maybe<Scalars['String']>;
  dropDate?: Maybe<Scalars['DateTime']>;
  dropDate_not?: Maybe<Scalars['DateTime']>;
  dropDate_in?: Maybe<Array<Scalars['DateTime']>>;
  dropDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  dropDate_lt?: Maybe<Scalars['DateTime']>;
  dropDate_lte?: Maybe<Scalars['DateTime']>;
  dropDate_gt?: Maybe<Scalars['DateTime']>;
  dropDate_gte?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_not?: Maybe<Scalars['DateTime']>;
  startDate_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startDate_lt?: Maybe<Scalars['DateTime']>;
  startDate_lte?: Maybe<Scalars['DateTime']>;
  startDate_gt?: Maybe<Scalars['DateTime']>;
  startDate_gte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_not?: Maybe<Scalars['DateTime']>;
  endDate_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endDate_lt?: Maybe<Scalars['DateTime']>;
  endDate_lte?: Maybe<Scalars['DateTime']>;
  endDate_gt?: Maybe<Scalars['DateTime']>;
  endDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<PromotedPeriodWhereInput>>;
  OR?: Maybe<Array<PromotedPeriodWhereInput>>;
  NOT?: Maybe<Array<PromotedPeriodWhereInput>>;
};

export type PromotedPeriodWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type PromotedPostcode = {
  __typename?: 'PromotedPostcode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  postcode: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  period: PromotedPeriod;
  status?: Maybe<PromotedPostcodeStatus>;
  merchant?: Maybe<Merchant>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type PromotedPostcodeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  postcode: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  period: PromotedPeriodCreateOneInput;
  status?: Maybe<PromotedPostcodeStatus>;
  merchant?: Maybe<MerchantCreateOneInput>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export enum PromotedPostcodeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  PostcodeAsc = 'postcode_ASC',
  PostcodeDesc = 'postcode_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  PaymentIntentIdAsc = 'paymentIntentId_ASC',
  PaymentIntentIdDesc = 'paymentIntentId_DESC'
}

export enum PromotedPostcodeStatus {
  Available = 'AVAILABLE',
  Pending = 'PENDING',
  Sold = 'SOLD'
}

export type PromotedPostcodeUpdateInput = {
  postcode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  period?: Maybe<PromotedPeriodUpdateOneRequiredInput>;
  status?: Maybe<PromotedPostcodeStatus>;
  merchant?: Maybe<MerchantUpdateOneInput>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type PromotedPostcodeUpdateManyMutationInput = {
  postcode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<PromotedPostcodeStatus>;
  paymentIntentId?: Maybe<Scalars['String']>;
};

export type PromotedPostcodeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  postcode?: Maybe<Scalars['String']>;
  postcode_not?: Maybe<Scalars['String']>;
  postcode_in?: Maybe<Array<Scalars['String']>>;
  postcode_not_in?: Maybe<Array<Scalars['String']>>;
  postcode_lt?: Maybe<Scalars['String']>;
  postcode_lte?: Maybe<Scalars['String']>;
  postcode_gt?: Maybe<Scalars['String']>;
  postcode_gte?: Maybe<Scalars['String']>;
  postcode_contains?: Maybe<Scalars['String']>;
  postcode_not_contains?: Maybe<Scalars['String']>;
  postcode_starts_with?: Maybe<Scalars['String']>;
  postcode_not_starts_with?: Maybe<Scalars['String']>;
  postcode_ends_with?: Maybe<Scalars['String']>;
  postcode_not_ends_with?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  timezone_not?: Maybe<Scalars['String']>;
  timezone_in?: Maybe<Array<Scalars['String']>>;
  timezone_not_in?: Maybe<Array<Scalars['String']>>;
  timezone_lt?: Maybe<Scalars['String']>;
  timezone_lte?: Maybe<Scalars['String']>;
  timezone_gt?: Maybe<Scalars['String']>;
  timezone_gte?: Maybe<Scalars['String']>;
  timezone_contains?: Maybe<Scalars['String']>;
  timezone_not_contains?: Maybe<Scalars['String']>;
  timezone_starts_with?: Maybe<Scalars['String']>;
  timezone_not_starts_with?: Maybe<Scalars['String']>;
  timezone_ends_with?: Maybe<Scalars['String']>;
  timezone_not_ends_with?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  period?: Maybe<PromotedPeriodWhereInput>;
  status?: Maybe<PromotedPostcodeStatus>;
  status_not?: Maybe<PromotedPostcodeStatus>;
  status_in?: Maybe<Array<PromotedPostcodeStatus>>;
  status_not_in?: Maybe<Array<PromotedPostcodeStatus>>;
  merchant?: Maybe<MerchantWhereInput>;
  paymentIntentId?: Maybe<Scalars['String']>;
  paymentIntentId_not?: Maybe<Scalars['String']>;
  paymentIntentId_in?: Maybe<Array<Scalars['String']>>;
  paymentIntentId_not_in?: Maybe<Array<Scalars['String']>>;
  paymentIntentId_lt?: Maybe<Scalars['String']>;
  paymentIntentId_lte?: Maybe<Scalars['String']>;
  paymentIntentId_gt?: Maybe<Scalars['String']>;
  paymentIntentId_gte?: Maybe<Scalars['String']>;
  paymentIntentId_contains?: Maybe<Scalars['String']>;
  paymentIntentId_not_contains?: Maybe<Scalars['String']>;
  paymentIntentId_starts_with?: Maybe<Scalars['String']>;
  paymentIntentId_not_starts_with?: Maybe<Scalars['String']>;
  paymentIntentId_ends_with?: Maybe<Scalars['String']>;
  paymentIntentId_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<PromotedPostcodeWhereInput>>;
  OR?: Maybe<Array<PromotedPostcodeWhereInput>>;
  NOT?: Maybe<Array<PromotedPostcodeWhereInput>>;
};

export type PromotedPostcodeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ProposedMerchantUpdate = {
  __typename?: 'ProposedMerchantUpdate';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedBy?: Maybe<User>;
  merchant?: Maybe<Merchant>;
  plan?: Maybe<MerchantPlan>;
  planPrice?: Maybe<Scalars['Float']>;
  isConfirmed: Scalars['Boolean'];
  merchantStage?: Maybe<MerchantStage>;
  reason?: Maybe<Scalars['String']>;
};

export type ProposedMerchantUpdateConnection = {
  __typename?: 'ProposedMerchantUpdateConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ProposedMerchantUpdateEdge>>;
  aggregate: AggregateProposedMerchantUpdate;
};

export type ProposedMerchantUpdateCreateInput = {
  merchant?: Maybe<MerchantConnectOneInput>;
  plan?: Maybe<MerchantPlanConnectOneInput>;
  planPrice?: Maybe<Scalars['Float']>;
  merchantStage?: Maybe<MerchantStage>;
  reason?: Maybe<Scalars['String']>;
  isConfirmed: Scalars['Boolean'];
};

export type ProposedMerchantUpdateCreateOneWithoutMerchantInput = {
  create?: Maybe<ProposedMerchantUpdateCreateWithoutMerchantInput>;
  connect?: Maybe<ProposedMerchantUpdateWhereUniqueInput>;
};

export type ProposedMerchantUpdateCreateWithoutMerchantInput = {
  id?: Maybe<Scalars['ID']>;
  plan?: Maybe<MerchantPlanConnectOneInput>;
  planPrice?: Maybe<Scalars['Float']>;
  merchantStage?: Maybe<MerchantStage>;
  reason?: Maybe<Scalars['String']>;
  isConfirmed: Scalars['Boolean'];
};

export type ProposedMerchantUpdateEdge = {
  __typename?: 'ProposedMerchantUpdateEdge';
  node: ProposedMerchantUpdate;
  cursor: Scalars['String'];
};

export enum ProposedMerchantUpdateOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  PlanPriceAsc = 'planPrice_ASC',
  PlanPriceDesc = 'planPrice_DESC',
  MerchantStageAsc = 'merchantStage_ASC',
  MerchantStageDesc = 'merchantStage_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC'
}

export type ProposedMerchantUpdatePreviousValues = {
  __typename?: 'ProposedMerchantUpdatePreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deletedBy?: Maybe<User>;
  planPrice?: Maybe<Scalars['Float']>;
  merchantStage?: Maybe<MerchantStage>;
  reason?: Maybe<Scalars['String']>;
  isConfirmed: Scalars['Boolean'];
};

export type ProposedMerchantUpdateSubscriptionPayload = {
  __typename?: 'ProposedMerchantUpdateSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ProposedMerchantUpdate>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ProposedMerchantUpdatePreviousValues>;
};

export type ProposedMerchantUpdateSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ProposedMerchantUpdateWhereInput>;
  AND?: Maybe<Array<ProposedMerchantUpdateSubscriptionWhereInput>>;
  OR?: Maybe<Array<ProposedMerchantUpdateSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ProposedMerchantUpdateSubscriptionWhereInput>>;
};

export type ProposedMerchantUpdateUpdateInput = {
  plan?: Maybe<MerchantPlanConnectOneInput>;
  planPrice?: Maybe<Scalars['Float']>;
  merchantStage?: Maybe<MerchantStage>;
  reason?: Maybe<Scalars['String']>;
};

export type ProposedMerchantUpdateUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ProposedMerchantUpdateUpdateOneWithoutMerchantInput = {
  create?: Maybe<ProposedMerchantUpdateCreateWithoutMerchantInput>;
  update?: Maybe<ProposedMerchantUpdateUpdateWithoutMerchantDataInput>;
  upsert?: Maybe<ProposedMerchantUpdateUpsertWithoutMerchantInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProposedMerchantUpdateWhereUniqueInput>;
};

export type ProposedMerchantUpdateUpdateWithoutMerchantDataInput = {
  plan?: Maybe<MerchantPlanConnectOneInput>;
  planPrice?: Maybe<Scalars['Float']>;
  merchantStage?: Maybe<MerchantStage>;
  reason?: Maybe<Scalars['String']>;
};

export type ProposedMerchantUpdateUpsertWithoutMerchantInput = {
  update: ProposedMerchantUpdateUpdateWithoutMerchantDataInput;
  create: ProposedMerchantUpdateCreateWithoutMerchantInput;
};

export type ProposedMerchantUpdateWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  deletedBy?: Maybe<UserWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  plan?: Maybe<MerchantPlanWhereInput>;
  planPrice?: Maybe<Scalars['Float']>;
  planPrice_not?: Maybe<Scalars['Int']>;
  planPrice_in?: Maybe<Array<Scalars['Int']>>;
  planPrice_not_in?: Maybe<Array<Scalars['Int']>>;
  planPrice_lt?: Maybe<Scalars['Int']>;
  planPrice_lte?: Maybe<Scalars['Int']>;
  planPrice_gt?: Maybe<Scalars['Int']>;
  planPrice_gte?: Maybe<Scalars['Int']>;
  merchantStage?: Maybe<MerchantStage>;
  merchantStage_not?: Maybe<MerchantStage>;
  merchantStage_in?: Maybe<Array<MerchantStage>>;
  merchantStage_not_in?: Maybe<Array<MerchantStage>>;
  reason?: Maybe<Scalars['String']>;
  reason_not?: Maybe<Scalars['String']>;
  reason_in?: Maybe<Array<Scalars['String']>>;
  reason_not_in?: Maybe<Array<Scalars['String']>>;
  reason_lt?: Maybe<Scalars['String']>;
  reason_lte?: Maybe<Scalars['String']>;
  reason_gt?: Maybe<Scalars['String']>;
  reason_gte?: Maybe<Scalars['String']>;
  reason_contains?: Maybe<Scalars['String']>;
  reason_not_contains?: Maybe<Scalars['String']>;
  reason_starts_with?: Maybe<Scalars['String']>;
  reason_not_starts_with?: Maybe<Scalars['String']>;
  reason_ends_with?: Maybe<Scalars['String']>;
  reason_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<ProposedMerchantUpdateWhereInput>>;
  OR?: Maybe<Array<ProposedMerchantUpdateWhereInput>>;
  NOT?: Maybe<Array<ProposedMerchantUpdateWhereInput>>;
};

export type ProposedMerchantUpdateWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type PushNotificationCreateResponse = {
  __typename?: 'PushNotificationCreateResponse';
  response: Scalars['String'];
};

export type PushNotificationInput = {
  UID: Scalars['String'];
  token: Scalars['String'];
  platform?: Maybe<PushNotificationPlatform>;
};

export enum PushNotificationPlatform {
  Ios = 'IOS',
  Android = 'Android'
}

export type PushNotificationRegistration = {
  __typename?: 'PushNotificationRegistration';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  platform: PushNotificationPlatform;
  deviceId: Scalars['String'];
  token: Scalars['String'];
  user: User;
};

export type Query = {
  __typename?: 'Query';
  actionLog?: Maybe<ActionLog>;
  actionLogs: Array<Maybe<ActionLog>>;
  actionLogsConnection: ActionLogConnection;
  addon?: Maybe<Addon>;
  addons: Array<Maybe<Addon>>;
  addonsConnection: AddonConnection;
  addonCategory?: Maybe<AddonCategory>;
  addonCategories: Array<Maybe<AddonCategory>>;
  addonCategoriesConnection: AddonCategoryConnection;
  address?: Maybe<Address>;
  addresses: Array<Maybe<Address>>;
  addressesConnection: AddressConnection;
  adjusterTier?: Maybe<AdjusterTier>;
  adjusterTiers: Array<Maybe<AdjusterTier>>;
  catalogItem?: Maybe<CatalogItem>;
  catalogItems: Array<Maybe<CatalogItem>>;
  catalogItemsConnection: CatalogItemConnection;
  channelType?: Maybe<ChannelType>;
  channelTypes: Array<Maybe<ChannelType>>;
  channelTypesConnection: ChannelTypeConnection;
  collectionSellerProductConfig?: Maybe<CollectionSellerProductConfig>;
  collectionSellerProductConfigs: Array<Maybe<CollectionSellerProductConfig>>;
  collectionSellerProductConfigsConnection: CollectionSellerProductConfigConnection;
  colour?: Maybe<Colour>;
  colours: Array<Maybe<Colour>>;
  coloursConnection: ColourConnection;
  component?: Maybe<Component>;
  components: Array<Maybe<Component>>;
  componentsConnection: ComponentConnection;
  componentConfig?: Maybe<ComponentConfig>;
  componentConfigs: Array<Maybe<ComponentConfig>>;
  componentConfigsConnection: ComponentConfigConnection;
  country?: Maybe<Country>;
  countries: Array<Maybe<Country>>;
  countriesConnection: CountryConnection;
  courier?: Maybe<Courier>;
  couriers: Array<Maybe<Courier>>;
  couriersConnection: CourierConnection;
  courierBooking?: Maybe<CourierBooking>;
  courierBookings: Array<Maybe<CourierBooking>>;
  courierBookingsConnection: CourierBookingConnection;
  courierStatusHistory?: Maybe<CourierStatusHistory>;
  courierStatusHistories: Array<Maybe<CourierStatusHistory>>;
  courierStatusHistoriesConnection: CourierStatusHistoryConnection;
  courierTimeSlot?: Maybe<CourierTimeSlot>;
  courierTimeSlots: Array<Maybe<CourierTimeSlot>>;
  courierTimeSlotsConnection: CourierTimeSlotConnection;
  customSiteConfig?: Maybe<CustomSiteConfig>;
  customSiteConfigs: Array<Maybe<CustomSiteConfig>>;
  customSiteConfigsConnection: CustomSiteConfigConnection;
  customer?: Maybe<Customer>;
  customers: Array<Maybe<Customer>>;
  customersConnection: CustomerConnection;
  listAllCourierDeliverySlots: ListOfCourierDeliverySlotsOutput;
  canCancelCourierBooking: CourierBookingResponse;
  canBookCourierBooking: CourierBookingResponse;
  deliveryConfig?: Maybe<DeliveryConfig>;
  deliveryConfigs: Array<Maybe<DeliveryConfig>>;
  deliveryConfigsConnection: DeliveryConfigConnection;
  deliveryCoverage?: Maybe<DeliveryCoverage>;
  deliveryCoverages: Array<Maybe<DeliveryCoverage>>;
  deliveryCoveragesConnection: DeliveryCoverageConnection;
  deliveryPricing?: Maybe<DeliveryPricing>;
  deliveryPricings: Array<Maybe<DeliveryPricing>>;
  deliveryPricingsConnection: DeliveryPricingConnection;
  deliveryTiming?: Maybe<DeliveryTiming>;
  deliveryTimings: Array<Maybe<DeliveryTiming>>;
  deliveryTimingsConnection: DeliveryTimingConnection;
  deliveryZone?: Maybe<DeliveryZone>;
  deliveryZones: Array<Maybe<DeliveryZone>>;
  deliveryZonesConnection: DeliveryZoneConnection;
  dimensions?: Maybe<Dimensions>;
  dimensionses: Array<Maybe<Dimensions>>;
  dimensionsesConnection: DimensionsConnection;
  discount?: Maybe<Discount>;
  discounts: Array<Maybe<Discount>>;
  discountsConnection: DiscountConnection;
  discountValidation?: Maybe<Discount>;
  geoLocation?: Maybe<GeoLocation>;
  geoLocations: Array<Maybe<GeoLocation>>;
  geoLocationsConnection: GeoLocationConnection;
  invoice?: Maybe<Invoice>;
  invoices: Array<Maybe<Invoice>>;
  invoicesConnection: InvoiceConnection;
  media?: Maybe<Media>;
  medias: Array<Maybe<Media>>;
  mediasConnection: MediaConnection;
  merchant?: Maybe<Merchant>;
  merchantsDataForToggle: Array<Maybe<MerchantsDataForToggle>>;
  merchants: Array<Maybe<Merchant>>;
  merchantsConnection: MerchantConnection;
  merchantHoliday?: Maybe<MerchantHoliday>;
  merchantHolidays: Array<Maybe<MerchantHoliday>>;
  merchantHolidaysConnection: MerchantHolidayConnection;
  merchantPlan?: Maybe<MerchantPlan>;
  merchantPlans: Array<Maybe<MerchantPlan>>;
  merchantPlansConnection: MerchantPlanConnection;
  merchantRecurringPayment?: Maybe<MerchantRecurringPayment>;
  merchantRecurringPayments: Array<Maybe<MerchantRecurringPayment>>;
  merchantRecurringPaymentsConnection: MerchantRecurringPaymentConnection;
  merchantPaymentDetails: MerchantPaymentDetailsResponse;
  merchantSupplierIntegration?: Maybe<MerchantSupplierIntegration>;
  merchantSupplierIntegrations: Array<Maybe<MerchantSupplierIntegration>>;
  order?: Maybe<Order>;
  orders: Array<Maybe<Order>>;
  ordersConnection: OrderConnection;
  orderDeliveryConfig?: Maybe<OrderDeliveryConfig>;
  orderDeliveryConfigs: Array<Maybe<OrderDeliveryConfig>>;
  orderDeliveryConfigsConnection: OrderDeliveryConfigConnection;
  orderDiscount?: Maybe<OrderDiscount>;
  orderDiscounts: Array<Maybe<OrderDiscount>>;
  orderDiscountsConnection: OrderDiscountConnection;
  orderItem?: Maybe<OrderItem>;
  orderItems: Array<Maybe<OrderItem>>;
  orderItemsConnection: OrderItemConnection;
  orderNote?: Maybe<OrderNote>;
  orderNotes: Array<Maybe<OrderNote>>;
  orderNotesConnection: OrderNoteConnection;
  orderStatus?: Maybe<OrderStatus>;
  orderStatuses: Array<Maybe<OrderStatus>>;
  orderStatusesConnection: OrderStatusConnection;
  payments: Array<Maybe<Payment>>;
  paymentsConnection: PaymentConnection;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethods: Array<Maybe<PaymentMethod>>;
  paymentMethodsConnection: PaymentMethodConnection;
  product?: Maybe<Product>;
  products: Array<Maybe<Product>>;
  productsConnection: ProductConnection;
  productCategory?: Maybe<ProductCategory>;
  productCategories: Array<Maybe<ProductCategory>>;
  productCategoriesConnection: ProductCategoryConnection;
  productCountForLocation?: Maybe<Scalars['Int']>;
  productType?: Maybe<ProductType>;
  productTypes: Array<Maybe<ProductType>>;
  productTypesConnection: ProductTypeConnection;
  productVariation?: Maybe<ProductVariation>;
  productVariations: Array<Maybe<ProductVariation>>;
  productVariationsConnection: ProductVariationConnection;
  promotedPeriod?: Maybe<PromotedPeriod>;
  promotedPeriods: Array<Maybe<PromotedPeriod>>;
  promotedPostcode?: Maybe<PromotedPostcode>;
  promotedPostcodes: Array<Maybe<PromotedPostcode>>;
  proposedMerchantUpdate?: Maybe<ProposedMerchantUpdate>;
  proposedMerchantUpdates: Array<Maybe<ProposedMerchantUpdate>>;
  proposedMerchantUpdatesConnection: ProposedMerchantUpdateConnection;
  recipe?: Maybe<Recipe>;
  recipes: Array<Maybe<Recipe>>;
  recipesConnection: RecipeConnection;
  recipeItem?: Maybe<RecipeItem>;
  recipeItems: Array<Maybe<RecipeItem>>;
  recipeItemsConnection: RecipeItemConnection;
  remittancePeriod?: Maybe<RemittancePeriod>;
  remittancePeriods: Array<Maybe<RemittancePeriod>>;
  remittancePeriodsConnection: RemittancePeriodConnection;
  remittanceTransfer?: Maybe<RemittanceTransfer>;
  remittanceTransfers: Array<Maybe<RemittanceTransfer>>;
  remittanceTransfersConnection: RemittanceTransferConnection;
  orderCheckUnreadPreOrders: OrderCheckResponse;
  orderCheckUnreadAllOrders: OrderCheckResponse;
  orderCheckPrepared: OrderCheckResponse;
  orderCheckOnTheWay: OrderCheckResponse;
  orderCheckDelivered: OrderCheckResponse;
  orderCheckLateDelivery: OrderCheckResponse;
  review?: Maybe<Review>;
  reviews: Array<Maybe<Review>>;
  reviewsConnection: ReviewConnection;
  salesTaxEstimate: TaxEstimateResponse;
  shop?: Maybe<Shop>;
  shops: Array<Maybe<Shop>>;
  shopsConnection: ShopConnection;
  stripeInvoice: StripeInvoiceResponse;
  subscriptionPlan: StripePlanResponse;
  supplierDeliveryConfig?: Maybe<SupplierDeliveryConfig>;
  supplierDeliveryConfigs: Array<Maybe<SupplierDeliveryConfig>>;
  supplierDeliveryOption?: Maybe<SupplierDeliveryOption>;
  supplierDeliveryOptions: Array<Maybe<SupplierDeliveryOption>>;
  supplierDeliveryHub?: Maybe<SupplierDeliveryHub>;
  supplierDeliveryHubs: Array<Maybe<SupplierDeliveryHub>>;
  team?: Maybe<Team>;
  teams: Array<Maybe<Team>>;
  teamsConnection: TeamConnection;
  user?: Maybe<User>;
  users: Array<Maybe<User>>;
  usersConnection: UserConnection;
  wholesaleCategory?: Maybe<WholesaleCategory>;
  wholesaleCategories: Array<Maybe<WholesaleCategory>>;
  wholesaleDeliveryConfigs: Array<Maybe<WholesaleDeliveryConfig>>;
  wholesaleOrder?: Maybe<WholesaleOrder>;
  wholesaleUnpaidBalance?: Maybe<Scalars['Float']>;
  wholesaleOrders?: Maybe<Array<WholesaleOrder>>;
  wholesaleOrdersConnection: WholesaleOrderConnection;
  wholesaleTradeLineItem?: Maybe<WholesaleTradeLineItem>;
  wholesaleTradeLineItems: Array<Maybe<WholesaleTradeLineItem>>;
  wholesaleTradeLineItemsConnection: WholesaleTradeLineItemConnection;
  wholesalePreOrder?: Maybe<WholesalePreOrder>;
  wholesalePreOrderDeliveryDates?: Maybe<Array<Maybe<WholesalePreOrderDeliveryDate>>>;
  wholesalePreOrders: Array<Maybe<WholesalePreOrder>>;
  wholesalePreOrdersConnection: WholesalePreOrderConnection;
  wholesaleFavourite?: Maybe<WholesaleFavourite>;
  wholesaleFavourites: Array<Maybe<WholesaleFavourite>>;
  wholesaleGMV?: Maybe<Scalars['Float']>;
  merchantWholesaleSuppliers: Array<Maybe<MerchantWholesaleSupplier>>;
  supplier?: Maybe<Supplier>;
  suppliers: Array<Maybe<Supplier>>;
  node?: Maybe<Node>;
  exportOrdersList?: Maybe<ExportOrdersJsonResponse>;
  distributeCollectionOrder: Merchant;
  collectionOrderDeliveryConfigs?: Maybe<Array<Maybe<DeliveryConfig>>>;
  collectionOrderDeliveryConfigsV2?: Maybe<Array<Maybe<DeliveryConfig>>>;
  genus?: Maybe<Genus>;
  genera: Array<Maybe<Genus>>;
  generaConnection: GenusConnection;
  list?: Maybe<List>;
  lists: Array<Maybe<List>>;
  listsConnection: ListConnection;
  listItem?: Maybe<ListItemUnion>;
  listItems: Array<ListItemUnion>;
  promotedLists?: Maybe<Array<Maybe<List>>>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Maybe<Transaction>>;
  transactionsConnection: TransactionConnection;
  notification?: Maybe<Notification>;
  notifications: Array<Maybe<Notification>>;
  getDeliveryDateOptionsForProduct?: Maybe<DeliveryDateOptionsResponse>;
};


export type QueryActionLogArgs = {
  where: ActionLogWhereUniqueInput;
};


export type QueryActionLogsArgs = {
  where?: Maybe<ActionLogWhereInput>;
  orderBy?: Maybe<ActionLogOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryActionLogsConnectionArgs = {
  where?: Maybe<ActionLogWhereInput>;
  orderBy?: Maybe<ActionLogOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAddonArgs = {
  where: AddonWhereUniqueInput;
};


export type QueryAddonsArgs = {
  where?: Maybe<AddonWhereInput>;
  orderBy?: Maybe<AddonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAddonsConnectionArgs = {
  where?: Maybe<AddonWhereInput>;
  orderBy?: Maybe<AddonOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAddonCategoryArgs = {
  where: AddonCategoryWhereUniqueInput;
};


export type QueryAddonCategoriesArgs = {
  where?: Maybe<AddonCategoryWhereInput>;
  orderBy?: Maybe<AddonCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAddonCategoriesConnectionArgs = {
  where?: Maybe<AddonCategoryWhereInput>;
  orderBy?: Maybe<AddonCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAddressArgs = {
  where: AddressWhereUniqueInput;
};


export type QueryAddressesArgs = {
  where?: Maybe<AddressWhereInput>;
  orderBy?: Maybe<AddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAddressesConnectionArgs = {
  where?: Maybe<AddressWhereInput>;
  orderBy?: Maybe<AddressOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAdjusterTierArgs = {
  where: AdjusterTierWhereUniqueInput;
};


export type QueryAdjusterTiersArgs = {
  where?: Maybe<AdjusterTierWhereInput>;
  orderBy?: Maybe<AdjusterOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCatalogItemArgs = {
  where: CatalogItemWhereUniqueInput;
};


export type QueryCatalogItemsArgs = {
  where?: Maybe<CatalogItemWhereInput>;
  orderBy?: Maybe<CatalogItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCatalogItemsConnectionArgs = {
  where?: Maybe<CatalogItemWhereInput>;
  orderBy?: Maybe<CatalogItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryChannelTypeArgs = {
  where: ChannelTypeWhereUniqueInput;
};


export type QueryChannelTypesArgs = {
  where?: Maybe<ChannelTypeWhereInput>;
  orderBy?: Maybe<ChannelTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryChannelTypesConnectionArgs = {
  where?: Maybe<ChannelTypeWhereInput>;
  orderBy?: Maybe<ChannelTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCollectionSellerProductConfigArgs = {
  where: CollectionSellerProductConfigWhereUniqueInput;
};


export type QueryCollectionSellerProductConfigsArgs = {
  where?: Maybe<CollectionSellerProductConfigWhereInput>;
  orderBy?: Maybe<CollectionSellerProductConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCollectionSellerProductConfigsConnectionArgs = {
  where?: Maybe<CollectionSellerProductConfigWhereInput>;
  orderBy?: Maybe<CollectionSellerProductConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryColourArgs = {
  where: ColourWhereUniqueInput;
};


export type QueryColoursArgs = {
  where?: Maybe<ColourWhereInput>;
  orderBy?: Maybe<ColourOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryColoursConnectionArgs = {
  where?: Maybe<ColourWhereInput>;
  orderBy?: Maybe<ColourOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryComponentArgs = {
  where: ComponentWhereUniqueInput;
};


export type QueryComponentsArgs = {
  where?: Maybe<ComponentWhereInput>;
  orderBy?: Maybe<ComponentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryComponentsConnectionArgs = {
  where?: Maybe<ComponentWhereInput>;
  orderBy?: Maybe<ComponentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryComponentConfigArgs = {
  where: ComponentConfigWhereUniqueInput;
};


export type QueryComponentConfigsArgs = {
  where?: Maybe<ComponentConfigWhereInput>;
  orderBy?: Maybe<ComponentConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryComponentConfigsConnectionArgs = {
  where?: Maybe<ComponentConfigWhereInput>;
  orderBy?: Maybe<ComponentConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type QueryCountriesArgs = {
  where?: Maybe<CountryWhereInput>;
  orderBy?: Maybe<CountryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCountriesConnectionArgs = {
  where?: Maybe<CountryWhereInput>;
  orderBy?: Maybe<CountryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCourierArgs = {
  where: CourierWhereUniqueInput;
};


export type QueryCouriersArgs = {
  where?: Maybe<CourierWhereInput>;
  orderBy?: Maybe<CourierOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCouriersConnectionArgs = {
  where?: Maybe<CourierWhereInput>;
  orderBy?: Maybe<CourierOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCourierBookingArgs = {
  where: CourierBookingWhereUniqueInput;
};


export type QueryCourierBookingsArgs = {
  where?: Maybe<CourierBookingWhereInput>;
  orderBy?: Maybe<CourierBookingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCourierBookingsConnectionArgs = {
  where?: Maybe<CourierBookingWhereInput>;
  orderBy?: Maybe<CourierBookingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCourierStatusHistoryArgs = {
  where: CourierStatusHistoryWhereUniqueInput;
};


export type QueryCourierStatusHistoriesArgs = {
  where?: Maybe<CourierStatusHistoryWhereInput>;
  orderBy?: Maybe<CourierStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCourierStatusHistoriesConnectionArgs = {
  where?: Maybe<CourierStatusHistoryWhereInput>;
  orderBy?: Maybe<CourierStatusHistoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCourierTimeSlotArgs = {
  where: CourierTimeSlotWhereUniqueInput;
};


export type QueryCourierTimeSlotsArgs = {
  where?: Maybe<CourierTimeSlotWhereInput>;
  orderBy?: Maybe<CourierTimeSlotOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCourierTimeSlotsConnectionArgs = {
  where?: Maybe<CourierTimeSlotWhereInput>;
  orderBy?: Maybe<CourierTimeSlotOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCustomSiteConfigArgs = {
  where: CustomSiteConfigWhereUniqueInput;
};


export type QueryCustomSiteConfigsArgs = {
  where?: Maybe<CustomSiteConfigWhereInput>;
  orderBy?: Maybe<CustomSiteConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCustomSiteConfigsConnectionArgs = {
  where?: Maybe<CustomSiteConfigWhereInput>;
  orderBy?: Maybe<CustomSiteConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCustomerArgs = {
  where: CustomerWhereUniqueInput;
};


export type QueryCustomersArgs = {
  where?: Maybe<CustomerWhereInput>;
  orderBy?: Maybe<CustomerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCustomersConnectionArgs = {
  where?: Maybe<CustomerWhereInput>;
  orderBy?: Maybe<CustomerOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryListAllCourierDeliverySlotsArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryCanCancelCourierBookingArgs = {
  where?: Maybe<OrderWhereUniqueInput>;
};


export type QueryCanBookCourierBookingArgs = {
  where?: Maybe<OrderWhereUniqueInput>;
};


export type QueryDeliveryConfigArgs = {
  where: DeliveryConfigWhereUniqueInput;
};


export type QueryDeliveryConfigsArgs = {
  where?: Maybe<DeliveryConfigWhereInput>;
  orderBy?: Maybe<DeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryConfigsConnectionArgs = {
  where?: Maybe<DeliveryConfigWhereInput>;
  orderBy?: Maybe<DeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryCoverageArgs = {
  where: DeliveryCoverageWhereUniqueInput;
};


export type QueryDeliveryCoveragesArgs = {
  where?: Maybe<DeliveryCoverageWhereInput>;
  orderBy?: Maybe<DeliveryCoverageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryCoveragesConnectionArgs = {
  where?: Maybe<DeliveryCoverageWhereInput>;
  orderBy?: Maybe<DeliveryCoverageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryPricingArgs = {
  where: DeliveryPricingWhereUniqueInput;
};


export type QueryDeliveryPricingsArgs = {
  where?: Maybe<DeliveryPricingWhereInput>;
  orderBy?: Maybe<DeliveryPricingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryPricingsConnectionArgs = {
  where?: Maybe<DeliveryPricingWhereInput>;
  orderBy?: Maybe<DeliveryPricingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryTimingArgs = {
  where: DeliveryTimingWhereUniqueInput;
};


export type QueryDeliveryTimingsArgs = {
  where?: Maybe<DeliveryTimingWhereInput>;
  orderBy?: Maybe<DeliveryTimingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryTimingsConnectionArgs = {
  where?: Maybe<DeliveryTimingWhereInput>;
  orderBy?: Maybe<DeliveryTimingOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryZoneArgs = {
  where: DeliveryZoneWhereUniqueInput;
};


export type QueryDeliveryZonesArgs = {
  where?: Maybe<DeliveryZoneWhereInput>;
  orderBy?: Maybe<DeliveryZoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDeliveryZonesConnectionArgs = {
  where?: Maybe<DeliveryZoneWhereInput>;
  orderBy?: Maybe<DeliveryZoneOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDimensionsArgs = {
  where: DimensionsWhereUniqueInput;
};


export type QueryDimensionsesArgs = {
  where?: Maybe<DimensionsWhereInput>;
  orderBy?: Maybe<DimensionsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDimensionsesConnectionArgs = {
  where?: Maybe<DimensionsWhereInput>;
  orderBy?: Maybe<DimensionsOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDiscountArgs = {
  where: DiscountWhereUniqueInput;
};


export type QueryDiscountsArgs = {
  where?: Maybe<DiscountWhereInput>;
  orderBy?: Maybe<DiscountOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDiscountsConnectionArgs = {
  where?: Maybe<DiscountWhereInput>;
  orderBy?: Maybe<DiscountOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDiscountValidationArgs = {
  where?: Maybe<DiscountValidationWhereInput>;
};


export type QueryGeoLocationArgs = {
  where: GeoLocationWhereUniqueInput;
};


export type QueryGeoLocationsArgs = {
  where?: Maybe<GeoLocationWhereInput>;
  orderBy?: Maybe<GeoLocationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGeoLocationsConnectionArgs = {
  where?: Maybe<GeoLocationWhereInput>;
  orderBy?: Maybe<GeoLocationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<InvoiceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryInvoicesConnectionArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<InvoiceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMediaArgs = {
  where: MediaWhereUniqueInput;
};


export type QueryMediasArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<MediaOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMediasConnectionArgs = {
  where?: Maybe<MediaWhereInput>;
  orderBy?: Maybe<MediaOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantArgs = {
  where: MerchantWhereUniqueInput;
};


export type QueryMerchantsDataForToggleArgs = {
  where?: Maybe<MerchantWhereInput>;
  orderBy?: Maybe<MerchantOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantsArgs = {
  where?: Maybe<MerchantWhereInput>;
  orderBy?: Maybe<MerchantOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantsConnectionArgs = {
  where?: Maybe<MerchantWhereInput>;
  orderBy?: Maybe<MerchantOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantHolidayArgs = {
  where: MerchantHolidayWhereUniqueInput;
};


export type QueryMerchantHolidaysArgs = {
  where?: Maybe<MerchantHolidayWhereInput>;
  orderBy?: Maybe<MerchantHolidayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantHolidaysConnectionArgs = {
  where?: Maybe<MerchantHolidayWhereInput>;
  orderBy?: Maybe<MerchantHolidayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantPlanArgs = {
  where: MerchantPlanWhereUniqueInput;
};


export type QueryMerchantPlansArgs = {
  where?: Maybe<MerchantPlanWhereInput>;
  orderBy?: Maybe<MerchantPlanOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantPlansConnectionArgs = {
  where?: Maybe<MerchantPlanWhereInput>;
  orderBy?: Maybe<MerchantPlanOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantRecurringPaymentArgs = {
  where: MerchantRecurringPaymentWhereUniqueInput;
};


export type QueryMerchantRecurringPaymentsArgs = {
  where?: Maybe<MerchantRecurringPaymentWhereInput>;
  orderBy?: Maybe<MerchantRecurringPaymentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantRecurringPaymentsConnectionArgs = {
  where?: Maybe<MerchantRecurringPaymentWhereInput>;
  orderBy?: Maybe<MerchantRecurringPaymentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMerchantPaymentDetailsArgs = {
  where?: Maybe<MerchantWhereUniqueInput>;
};


export type QueryMerchantSupplierIntegrationArgs = {
  where: MerchantSupplierIntegrationWhereUniqueInput;
};


export type QueryMerchantSupplierIntegrationsArgs = {
  where?: Maybe<MerchantSupplierIntegrationWhereInput>;
  orderBy?: Maybe<MerchantSupplierIntegrationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryOrdersArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrdersConnectionArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderDeliveryConfigArgs = {
  where: OrderDeliveryConfigWhereUniqueInput;
};


export type QueryOrderDeliveryConfigsArgs = {
  where?: Maybe<OrderDeliveryConfigWhereInput>;
  orderBy?: Maybe<OrderDeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderDeliveryConfigsConnectionArgs = {
  where?: Maybe<OrderDeliveryConfigWhereInput>;
  orderBy?: Maybe<OrderDeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderDiscountArgs = {
  where: OrderDiscountWhereUniqueInput;
};


export type QueryOrderDiscountsArgs = {
  where?: Maybe<OrderDiscountWhereInput>;
  orderBy?: Maybe<OrderDiscountOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderDiscountsConnectionArgs = {
  where?: Maybe<OrderDiscountWhereInput>;
  orderBy?: Maybe<OrderDiscountOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderItemArgs = {
  where: OrderItemWhereUniqueInput;
};


export type QueryOrderItemsArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<OrderItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderItemsConnectionArgs = {
  where?: Maybe<OrderItemWhereInput>;
  orderBy?: Maybe<OrderItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderNoteArgs = {
  where: OrderNoteWhereUniqueInput;
};


export type QueryOrderNotesArgs = {
  where?: Maybe<OrderNoteWhereInput>;
  orderBy?: Maybe<OrderNoteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderNotesConnectionArgs = {
  where?: Maybe<OrderNoteWhereInput>;
  orderBy?: Maybe<OrderNoteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderStatusArgs = {
  where: OrderStatusWhereUniqueInput;
};


export type QueryOrderStatusesArgs = {
  where?: Maybe<OrderStatusWhereInput>;
  orderBy?: Maybe<OrderStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderStatusesConnectionArgs = {
  where?: Maybe<OrderStatusWhereInput>;
  orderBy?: Maybe<OrderStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPaymentsArgs = {
  where?: Maybe<PaymentWhereInput>;
  orderBy?: Maybe<PaymentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPaymentsConnectionArgs = {
  where?: Maybe<PaymentWhereInput>;
  orderBy?: Maybe<PaymentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPaymentMethodArgs = {
  where: PaymentMethodWhereUniqueInput;
};


export type QueryPaymentMethodsArgs = {
  where?: Maybe<PaymentMethodWhereInput>;
  orderBy?: Maybe<PaymentMethodOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPaymentMethodsConnectionArgs = {
  where?: Maybe<PaymentMethodWhereInput>;
  orderBy?: Maybe<PaymentMethodOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductsArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<ProductOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProductsConnectionArgs = {
  where?: Maybe<ProductWhereInput>;
  orderBy?: Maybe<ProductOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProductCategoryArgs = {
  where: ProductCategoryWhereUniqueInput;
};


export type QueryProductCategoriesArgs = {
  where?: Maybe<ProductCategoryWhereInput>;
  orderBy?: Maybe<ProductCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProductCategoriesConnectionArgs = {
  where?: Maybe<ProductCategoryWhereInput>;
  orderBy?: Maybe<ProductCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProductCountForLocationArgs = {
  where: DeliveryZoneWhereInput;
};


export type QueryProductTypeArgs = {
  where: ProductTypeWhereUniqueInput;
};


export type QueryProductTypesArgs = {
  where?: Maybe<ProductTypeWhereInput>;
  orderBy?: Maybe<ProductTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProductTypesConnectionArgs = {
  where?: Maybe<ProductTypeWhereInput>;
  orderBy?: Maybe<ProductTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProductVariationArgs = {
  where: ProductVariationWhereUniqueInput;
};


export type QueryProductVariationsArgs = {
  where?: Maybe<ProductVariationWhereInput>;
  orderBy?: Maybe<ProductVariationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProductVariationsConnectionArgs = {
  where?: Maybe<ProductVariationWhereInput>;
  orderBy?: Maybe<ProductVariationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPromotedPeriodArgs = {
  where: PromotedPeriodWhereUniqueInput;
};


export type QueryPromotedPeriodsArgs = {
  where?: Maybe<PromotedPeriodWhereInput>;
  orderBy?: Maybe<PromotedPeriodOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPromotedPostcodeArgs = {
  where: PromotedPostcodeWhereUniqueInput;
};


export type QueryPromotedPostcodesArgs = {
  where?: Maybe<PromotedPostcodeWhereInput>;
  orderBy?: Maybe<PromotedPostcodeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProposedMerchantUpdateArgs = {
  where: ProposedMerchantUpdateWhereUniqueInput;
};


export type QueryProposedMerchantUpdatesArgs = {
  where?: Maybe<ProposedMerchantUpdateWhereInput>;
  orderBy?: Maybe<ProposedMerchantUpdateOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryProposedMerchantUpdatesConnectionArgs = {
  where?: Maybe<ProposedMerchantUpdateWhereInput>;
  orderBy?: Maybe<ProposedMerchantUpdateOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRecipeArgs = {
  where: RecipeWhereUniqueInput;
};


export type QueryRecipesArgs = {
  where?: Maybe<RecipeWhereInput>;
  orderBy?: Maybe<RecipeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRecipesConnectionArgs = {
  where?: Maybe<RecipeWhereInput>;
  orderBy?: Maybe<RecipeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRecipeItemArgs = {
  where: RecipeItemWhereUniqueInput;
};


export type QueryRecipeItemsArgs = {
  where?: Maybe<RecipeItemWhereInput>;
  orderBy?: Maybe<RecipeItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRecipeItemsConnectionArgs = {
  where?: Maybe<RecipeItemWhereInput>;
  orderBy?: Maybe<RecipeItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRemittancePeriodArgs = {
  where: RemittancePeriodWhereUniqueInput;
};


export type QueryRemittancePeriodsArgs = {
  where?: Maybe<RemittancePeriodWhereInput>;
  orderBy?: Maybe<RemittancePeriodOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRemittancePeriodsConnectionArgs = {
  where?: Maybe<RemittancePeriodWhereInput>;
  orderBy?: Maybe<RemittancePeriodOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRemittanceTransferArgs = {
  where: RemittanceTransferWhereUniqueInput;
};


export type QueryRemittanceTransfersArgs = {
  where?: Maybe<RemittanceTransferWhereInput>;
  orderBy?: Maybe<RemittanceTransferOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRemittanceTransfersConnectionArgs = {
  where?: Maybe<RemittanceTransferWhereInput>;
  orderBy?: Maybe<RemittanceTransferOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrderCheckUnreadPreOrdersArgs = {
  where: OrderCheckWhereInput;
};


export type QueryOrderCheckUnreadAllOrdersArgs = {
  where: OrderCheckWhereInput;
};


export type QueryOrderCheckPreparedArgs = {
  where: OrderCheckWhereInput;
};


export type QueryOrderCheckOnTheWayArgs = {
  where: OrderCheckWhereInput;
};


export type QueryOrderCheckDeliveredArgs = {
  where: OrderCheckWhereInput;
};


export type QueryOrderCheckLateDeliveryArgs = {
  where: OrderCheckWhereInput;
};


export type QueryReviewArgs = {
  where: ReviewWhereUniqueInput;
};


export type QueryReviewsArgs = {
  where?: Maybe<ReviewWhereInput>;
  orderBy?: Maybe<ReviewOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryReviewsConnectionArgs = {
  where?: Maybe<ReviewWhereInput>;
  orderBy?: Maybe<ReviewOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySalesTaxEstimateArgs = {
  where: TaxEstimateWhereInput;
};


export type QueryShopArgs = {
  where: ShopWhereUniqueInput;
};


export type QueryShopsArgs = {
  where?: Maybe<ShopWhereInput>;
  orderBy?: Maybe<ShopOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShopsConnectionArgs = {
  where?: Maybe<ShopWhereInput>;
  orderBy?: Maybe<ShopOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStripeInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
};


export type QuerySubscriptionPlanArgs = {
  where?: Maybe<MerchantWhereUniqueInput>;
};


export type QuerySupplierDeliveryConfigArgs = {
  where: SupplierDeliveryConfigWhereUniqueInput;
};


export type QuerySupplierDeliveryConfigsArgs = {
  where?: Maybe<SupplierDeliveryConfigWhereInput>;
  orderBy?: Maybe<SupplierDeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySupplierDeliveryOptionArgs = {
  where: SupplierDeliveryOptionWhereUniqueInput;
};


export type QuerySupplierDeliveryOptionsArgs = {
  where?: Maybe<SupplierDeliveryOptionWhereInput>;
  orderBy?: Maybe<SupplierDeliveryOptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySupplierDeliveryHubArgs = {
  where: SupplierDeliveryHubWhereUniqueInput;
};


export type QuerySupplierDeliveryHubsArgs = {
  where?: Maybe<SupplierDeliveryHubWhereInput>;
  orderBy?: Maybe<SupplierDeliveryHubOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTeamArgs = {
  where: TeamWhereUniqueInput;
};


export type QueryTeamsArgs = {
  where?: Maybe<TeamWhereInput>;
  orderBy?: Maybe<TeamOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTeamsConnectionArgs = {
  where?: Maybe<TeamWhereInput>;
  orderBy?: Maybe<TeamOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUsersConnectionArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWholesaleCategoryArgs = {
  where: WholesaleCategoryWhereUniqueInput;
};


export type QueryWholesaleCategoriesArgs = {
  where?: Maybe<WholesaleCategoryWhereInput>;
  orderBy?: Maybe<WholesaleCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWholesaleDeliveryConfigsArgs = {
  where?: Maybe<WholesaleDeliveryConfigWhereInput>;
  orderBy?: Maybe<WholesaleDeliveryConfigOrderByInput>;
};


export type QueryWholesaleOrderArgs = {
  where: WholesaleOrderWhereUniqueInput;
};


export type QueryWholesaleUnpaidBalanceArgs = {
  where: MerchantWhereUniqueInput;
};


export type QueryWholesaleOrdersArgs = {
  where?: Maybe<WholesaleOrderWhereInput>;
  orderBy?: Maybe<WholesaleOrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWholesaleOrdersConnectionArgs = {
  where?: Maybe<WholesaleOrderWhereInput>;
  orderBy?: Maybe<WholesaleOrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWholesaleTradeLineItemArgs = {
  where: WholesaleTradeLineItemWhereUniqueInput;
};


export type QueryWholesaleTradeLineItemsArgs = {
  where?: Maybe<WholesaleTradeLineItemWhereInput>;
  orderBy?: Maybe<WholesaleTradeLineItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWholesaleTradeLineItemsConnectionArgs = {
  where?: Maybe<WholesaleTradeLineItemWhereInput>;
  orderBy?: Maybe<WholesaleTradeLineItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWholesalePreOrderArgs = {
  where: WholesalePreOrderWhereUniqueInput;
};


export type QueryWholesalePreOrderDeliveryDatesArgs = {
  data?: Maybe<WholesalePreOrderDeliveryDatesInput>;
  where: ListWhereUniqueInput;
};


export type QueryWholesalePreOrdersArgs = {
  where?: Maybe<WholesalePreOrderWhereInput>;
  orderBy?: Maybe<WholesalePreOrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWholesalePreOrdersConnectionArgs = {
  where?: Maybe<WholesalePreOrderWhereInput>;
  orderBy?: Maybe<WholesalePreOrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWholesaleFavouriteArgs = {
  where: WholesaleFavouriteWhereUniqueInput;
};


export type QueryWholesaleFavouritesArgs = {
  where?: Maybe<WholesaleFavouriteWhereInput>;
  orderBy?: Maybe<WholesaleFavouriteOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryWholesaleGmvArgs = {
  where: MerchantWhereUniqueInput;
};


export type QueryMerchantWholesaleSuppliersArgs = {
  where: MerchantWholesaleSuppliersWhereInput;
};


export type QuerySupplierArgs = {
  where: SupplierWhereUniqueInput;
};


export type QuerySuppliersArgs = {
  where?: Maybe<SupplierWhereInput>;
  orderBy?: Maybe<SupplierOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryExportOrdersListArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDistributeCollectionOrderArgs = {
  where?: Maybe<CollectionOrderDistributionInput>;
};


export type QueryCollectionOrderDeliveryConfigsArgs = {
  where?: Maybe<ProductWhereUniqueInput>;
};


export type QueryCollectionOrderDeliveryConfigsV2Args = {
  where?: Maybe<CollectionItemDeliveryConfigInput>;
};


export type QueryGenusArgs = {
  where: GenusWhereUniqueInput;
};


export type QueryGeneraArgs = {
  where?: Maybe<GenusWhereInput>;
  orderBy?: Maybe<GenusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGeneraConnectionArgs = {
  where?: Maybe<GenusWhereInput>;
  orderBy?: Maybe<GenusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryListArgs = {
  where: ListWhereUniqueInput;
};


export type QueryListsArgs = {
  where?: Maybe<ListWhereInput>;
  orderBy?: Maybe<ListOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryListsConnectionArgs = {
  where?: Maybe<ListWhereInput>;
  orderBy?: Maybe<ListOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryListItemArgs = {
  where: ListItemWhereUniqueInput;
};


export type QueryListItemsArgs = {
  where?: Maybe<ListItemWhereInput>;
  orderBy?: Maybe<ListItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPromotedListsArgs = {
  where?: Maybe<ListWhereInput>;
  orderBy?: Maybe<ListOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTransactionArgs = {
  where: TransactionWhereUniqueInput;
};


export type QueryTransactionsArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<TransactionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTransactionsConnectionArgs = {
  where?: Maybe<TransactionWhereInput>;
  orderBy?: Maybe<TransactionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNotificationArgs = {
  where: NotificationWhereUniqueInput;
};


export type QueryNotificationsArgs = {
  where?: Maybe<NotificationWhereInput>;
  orderBy?: Maybe<NotificationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGetDeliveryDateOptionsForProductArgs = {
  data: GetDeliveryDateOptionsForProductInput;
};

export type ReIndexMerchantProductsForSearchResponse = {
  __typename?: 'ReIndexMerchantProductsForSearchResponse';
  log: Scalars['String'];
};

export type Recipe = {
  __typename?: 'Recipe';
  id: Scalars['ID'];
  recipeItems?: Maybe<Array<RecipeItem>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type RecipeRecipeItemsArgs = {
  where?: Maybe<RecipeItemWhereInput>;
  orderBy?: Maybe<RecipeItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RecipeConnection = {
  __typename?: 'RecipeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<RecipeEdge>>;
  aggregate: AggregateRecipe;
};

export type RecipeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  recipeItems?: Maybe<RecipeItemCreateManyInput>;
};

export type RecipeCreateOneInput = {
  connect?: Maybe<RecipeWhereUniqueInput>;
  create?: Maybe<RecipeCreateInput>;
};

export type RecipeEdge = {
  __typename?: 'RecipeEdge';
  node: Recipe;
  cursor: Scalars['String'];
};

export type RecipeItem = {
  __typename?: 'RecipeItem';
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  componentConfig: ComponentConfig;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RecipeItemConnection = {
  __typename?: 'RecipeItemConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<RecipeItemEdge>>;
  aggregate: AggregateRecipeItem;
};

export type RecipeItemCreateInput = {
  id?: Maybe<Scalars['ID']>;
  quantity: Scalars['Int'];
  componentConfig: ComponentConfigCreateOneInput;
};

export type RecipeItemCreateManyInput = {
  connect?: Maybe<Array<RecipeItemWhereUniqueInput>>;
  create?: Maybe<Array<RecipeItemCreateInput>>;
};

export type RecipeItemEdge = {
  __typename?: 'RecipeItemEdge';
  node: RecipeItem;
  cursor: Scalars['String'];
};

export enum RecipeItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  QuantityAsc = 'quantity_ASC',
  QuantityDesc = 'quantity_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RecipeItemPreviousValues = {
  __typename?: 'RecipeItemPreviousValues';
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RecipeItemScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
  quantity_not?: Maybe<Scalars['Int']>;
  quantity_in?: Maybe<Array<Scalars['Int']>>;
  quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  quantity_lt?: Maybe<Scalars['Int']>;
  quantity_lte?: Maybe<Scalars['Int']>;
  quantity_gt?: Maybe<Scalars['Int']>;
  quantity_gte?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RecipeItemScalarWhereInput>>;
  OR?: Maybe<Array<RecipeItemScalarWhereInput>>;
  NOT?: Maybe<Array<RecipeItemScalarWhereInput>>;
};

export type RecipeItemSubscriptionPayload = {
  __typename?: 'RecipeItemSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<RecipeItem>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<RecipeItemPreviousValues>;
};

export type RecipeItemSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<RecipeItemWhereInput>;
  AND?: Maybe<Array<RecipeItemSubscriptionWhereInput>>;
  OR?: Maybe<Array<RecipeItemSubscriptionWhereInput>>;
  NOT?: Maybe<Array<RecipeItemSubscriptionWhereInput>>;
};

export type RecipeItemUpdateDataInput = {
  quantity?: Maybe<Scalars['Int']>;
  componentConfig?: Maybe<ComponentConfigUpdateOneRequiredInput>;
};

export type RecipeItemUpdateInput = {
  quantity?: Maybe<Scalars['Int']>;
  componentConfig?: Maybe<ComponentConfigUpdateOneRequiredInput>;
};

export type RecipeItemUpdateManyDataInput = {
  quantity?: Maybe<Scalars['Int']>;
};

export type RecipeItemUpdateManyInput = {
  connect?: Maybe<Array<RecipeItemWhereUniqueInput>>;
  create?: Maybe<Array<RecipeItemCreateInput>>;
  delete?: Maybe<Array<RecipeItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecipeItemScalarWhereInput>>;
  disconnect?: Maybe<Array<RecipeItemWhereUniqueInput>>;
  set?: Maybe<Array<RecipeItemWhereUniqueInput>>;
  update?: Maybe<Array<RecipeItemUpdateWithWhereUniqueNestedInput>>;
  updateMany?: Maybe<Array<RecipeItemUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<RecipeItemUpsertWithWhereUniqueNestedInput>>;
};

export type RecipeItemUpdateManyMutationInput = {
  quantity?: Maybe<Scalars['Int']>;
};

export type RecipeItemUpdateManyWithWhereNestedInput = {
  where: RecipeItemScalarWhereInput;
  data: RecipeItemUpdateManyDataInput;
};

export type RecipeItemUpdateWithWhereUniqueNestedInput = {
  where: RecipeItemWhereUniqueInput;
  data: RecipeItemUpdateDataInput;
};

export type RecipeItemUpsertWithWhereUniqueNestedInput = {
  where: RecipeItemWhereUniqueInput;
  update: RecipeItemUpdateDataInput;
  create: RecipeItemCreateInput;
};

export type RecipeItemWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
  quantity_not?: Maybe<Scalars['Int']>;
  quantity_in?: Maybe<Array<Scalars['Int']>>;
  quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  quantity_lt?: Maybe<Scalars['Int']>;
  quantity_lte?: Maybe<Scalars['Int']>;
  quantity_gt?: Maybe<Scalars['Int']>;
  quantity_gte?: Maybe<Scalars['Int']>;
  componentConfig?: Maybe<ComponentConfigWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RecipeItemWhereInput>>;
  OR?: Maybe<Array<RecipeItemWhereInput>>;
  NOT?: Maybe<Array<RecipeItemWhereInput>>;
};

export type RecipeItemWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum RecipeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RecipePreviousValues = {
  __typename?: 'RecipePreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RecipeSubscriptionPayload = {
  __typename?: 'RecipeSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Recipe>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<RecipePreviousValues>;
};

export type RecipeSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<RecipeWhereInput>;
  AND?: Maybe<Array<RecipeSubscriptionWhereInput>>;
  OR?: Maybe<Array<RecipeSubscriptionWhereInput>>;
  NOT?: Maybe<Array<RecipeSubscriptionWhereInput>>;
};

export type RecipeUpdateDataInput = {
  recipeItems?: Maybe<RecipeItemUpdateManyInput>;
};

export type RecipeUpdateInput = {
  recipeItems?: Maybe<RecipeItemUpdateManyInput>;
};

export type RecipeUpdateOneInput = {
  connect?: Maybe<RecipeWhereUniqueInput>;
  create?: Maybe<RecipeCreateInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<RecipeUpdateDataInput>;
  upsert?: Maybe<RecipeUpsertNestedInput>;
};

export type RecipeUpsertNestedInput = {
  update: RecipeUpdateDataInput;
  create: RecipeCreateInput;
};

export type RecipeWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  recipeItems_every?: Maybe<RecipeItemWhereInput>;
  recipeItems_some?: Maybe<RecipeItemWhereInput>;
  recipeItems_none?: Maybe<RecipeItemWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RecipeWhereInput>>;
  OR?: Maybe<Array<RecipeWhereInput>>;
  NOT?: Maybe<Array<RecipeWhereInput>>;
};

export type RecipeWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum Region {
  Uk = 'UK',
  Sf = 'SF',
  Ny = 'NY',
  La = 'LA'
}

export type RegisterNewsletterSubscriberInput = {
  email: Scalars['String'];
};

export type ReleasePromotedPostcodesInput = {
  postcodes: Array<PromotedPostcodeWhereUniqueInput>;
};

export type ReleasePromotedPostcodesResponse = {
  __typename?: 'ReleasePromotedPostcodesResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type RemittanceAccounOneInput = {
  connect?: Maybe<RemittanceAccountWhereUniqueInput>;
};

export type RemittanceAccount = {
  __typename?: 'RemittanceAccount';
  id: Scalars['ID'];
  merchant: Merchant;
  routing: Scalars['String'];
  account: Scalars['String'];
  primaryName: Scalars['String'];
  secondaryName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type RemittanceAccountConnection = {
  __typename?: 'RemittanceAccountConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<RemittanceAccountEdge>>;
  aggregate: AggregateRemittanceAccount;
};

export type RemittanceAccountCreateInput = {
  id?: Maybe<Scalars['ID']>;
  routing: Scalars['String'];
  account: Scalars['String'];
  primaryName: Scalars['String'];
  secondaryName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
};

export type RemittanceAccountEdge = {
  __typename?: 'RemittanceAccountEdge';
  node: RemittanceAccount;
  cursor: Scalars['String'];
};

export enum RemittanceAccountOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RoutingAsc = 'routing_ASC',
  RoutingDesc = 'routing_DESC',
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  PrimaryNameAsc = 'primaryName_ASC',
  PrimaryNameDesc = 'primaryName_DESC',
  SecondaryNameAsc = 'secondaryName_ASC',
  SecondaryNameDesc = 'secondaryName_DESC',
  BankNameAsc = 'bankName_ASC',
  BankNameDesc = 'bankName_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type RemittanceAccountPreviousValues = {
  __typename?: 'RemittanceAccountPreviousValues';
  id: Scalars['ID'];
  routing: Scalars['String'];
  account: Scalars['String'];
  primaryName: Scalars['String'];
  secondaryName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type RemittanceAccountUpdateInput = {
  merchant?: Maybe<MerchantConnectOneInput>;
  routing?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  primaryName?: Maybe<Scalars['String']>;
  secondaryName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type RemittanceAccountWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantWhereInput>;
  routing?: Maybe<Scalars['String']>;
  routing_not?: Maybe<Scalars['String']>;
  routing_in?: Maybe<Array<Scalars['String']>>;
  routing_not_in?: Maybe<Array<Scalars['String']>>;
  routing_lt?: Maybe<Scalars['String']>;
  routing_lte?: Maybe<Scalars['String']>;
  routing_gt?: Maybe<Scalars['String']>;
  routing_gte?: Maybe<Scalars['String']>;
  routing_contains?: Maybe<Scalars['String']>;
  routing_not_contains?: Maybe<Scalars['String']>;
  routing_starts_with?: Maybe<Scalars['String']>;
  routing_not_starts_with?: Maybe<Scalars['String']>;
  routing_ends_with?: Maybe<Scalars['String']>;
  routing_not_ends_with?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Scalars['String']>>;
  account_not_in?: Maybe<Array<Scalars['String']>>;
  account_lt?: Maybe<Scalars['String']>;
  account_lte?: Maybe<Scalars['String']>;
  account_gt?: Maybe<Scalars['String']>;
  account_gte?: Maybe<Scalars['String']>;
  account_contains?: Maybe<Scalars['String']>;
  account_not_contains?: Maybe<Scalars['String']>;
  account_starts_with?: Maybe<Scalars['String']>;
  account_not_starts_with?: Maybe<Scalars['String']>;
  account_ends_with?: Maybe<Scalars['String']>;
  account_not_ends_with?: Maybe<Scalars['String']>;
  primaryName?: Maybe<Scalars['String']>;
  primaryName_not?: Maybe<Scalars['String']>;
  primaryName_in?: Maybe<Array<Scalars['String']>>;
  primaryName_not_in?: Maybe<Array<Scalars['String']>>;
  primaryName_lt?: Maybe<Scalars['String']>;
  primaryName_lte?: Maybe<Scalars['String']>;
  primaryName_gt?: Maybe<Scalars['String']>;
  primaryName_gte?: Maybe<Scalars['String']>;
  primaryName_contains?: Maybe<Scalars['String']>;
  primaryName_not_contains?: Maybe<Scalars['String']>;
  primaryName_starts_with?: Maybe<Scalars['String']>;
  primaryName_not_starts_with?: Maybe<Scalars['String']>;
  primaryName_ends_with?: Maybe<Scalars['String']>;
  primaryName_not_ends_with?: Maybe<Scalars['String']>;
  secondaryName?: Maybe<Scalars['String']>;
  secondaryName_not?: Maybe<Scalars['String']>;
  secondaryName_in?: Maybe<Array<Scalars['String']>>;
  secondaryName_not_in?: Maybe<Array<Scalars['String']>>;
  secondaryName_lt?: Maybe<Scalars['String']>;
  secondaryName_lte?: Maybe<Scalars['String']>;
  secondaryName_gt?: Maybe<Scalars['String']>;
  secondaryName_gte?: Maybe<Scalars['String']>;
  secondaryName_contains?: Maybe<Scalars['String']>;
  secondaryName_not_contains?: Maybe<Scalars['String']>;
  secondaryName_starts_with?: Maybe<Scalars['String']>;
  secondaryName_not_starts_with?: Maybe<Scalars['String']>;
  secondaryName_ends_with?: Maybe<Scalars['String']>;
  secondaryName_not_ends_with?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankName_not?: Maybe<Scalars['String']>;
  bankName_in?: Maybe<Array<Scalars['String']>>;
  bankName_not_in?: Maybe<Array<Scalars['String']>>;
  bankName_lt?: Maybe<Scalars['String']>;
  bankName_lte?: Maybe<Scalars['String']>;
  bankName_gt?: Maybe<Scalars['String']>;
  bankName_gte?: Maybe<Scalars['String']>;
  bankName_contains?: Maybe<Scalars['String']>;
  bankName_not_contains?: Maybe<Scalars['String']>;
  bankName_starts_with?: Maybe<Scalars['String']>;
  bankName_not_starts_with?: Maybe<Scalars['String']>;
  bankName_ends_with?: Maybe<Scalars['String']>;
  bankName_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RemittanceAccountWhereInput>>;
  OR?: Maybe<Array<RemittanceAccountWhereInput>>;
  NOT?: Maybe<Array<RemittanceAccountWhereInput>>;
};

export type RemittanceAccountWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type RemittancePeriod = {
  __typename?: 'RemittancePeriod';
  id: Scalars['ID'];
  number?: Maybe<Scalars['Int']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  completeAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RemittancePeriodConnectOneInput = {
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RemittancePeriodWhereUniqueInput>;
};

export type RemittancePeriodConnection = {
  __typename?: 'RemittancePeriodConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<RemittancePeriodEdge>>;
  aggregate: AggregateRemittancePeriod;
};

export type RemittancePeriodCreateInput = {
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['Int']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  completeAt: Scalars['DateTime'];
};

export type RemittancePeriodCreateOneInput = {
  create?: Maybe<RemittancePeriodCreateInput>;
  connect?: Maybe<RemittancePeriodWhereUniqueInput>;
};

export type RemittancePeriodEdge = {
  __typename?: 'RemittancePeriodEdge';
  node: RemittancePeriod;
  cursor: Scalars['String'];
};

export enum RemittancePeriodOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  StartAtAsc = 'startAt_ASC',
  StartAtDesc = 'startAt_DESC',
  EndAtAsc = 'endAt_ASC',
  EndAtDesc = 'endAt_DESC',
  CompleteAtAsc = 'completeAt_ASC',
  CompleteAtDesc = 'completeAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type RemittancePeriodPreviousValues = {
  __typename?: 'RemittancePeriodPreviousValues';
  id: Scalars['ID'];
  number?: Maybe<Scalars['Int']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  completeAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type RemittancePeriodUpdateOneInput = {
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RemittancePeriodWhereUniqueInput>;
};

export type RemittancePeriodWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Scalars['Int']>>;
  number_not_in?: Maybe<Array<Scalars['Int']>>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
  startAt_not?: Maybe<Scalars['DateTime']>;
  startAt_in?: Maybe<Array<Scalars['DateTime']>>;
  startAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startAt_lt?: Maybe<Scalars['DateTime']>;
  startAt_lte?: Maybe<Scalars['DateTime']>;
  startAt_gt?: Maybe<Scalars['DateTime']>;
  startAt_gte?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  endAt_not?: Maybe<Scalars['DateTime']>;
  endAt_in?: Maybe<Array<Scalars['DateTime']>>;
  endAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  endAt_lt?: Maybe<Scalars['DateTime']>;
  endAt_lte?: Maybe<Scalars['DateTime']>;
  endAt_gt?: Maybe<Scalars['DateTime']>;
  endAt_gte?: Maybe<Scalars['DateTime']>;
  completeAt?: Maybe<Scalars['DateTime']>;
  completeAt_not?: Maybe<Scalars['DateTime']>;
  completeAt_in?: Maybe<Array<Scalars['DateTime']>>;
  completeAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  completeAt_lt?: Maybe<Scalars['DateTime']>;
  completeAt_lte?: Maybe<Scalars['DateTime']>;
  completeAt_gt?: Maybe<Scalars['DateTime']>;
  completeAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<RemittancePeriodWhereInput>>;
  OR?: Maybe<Array<RemittancePeriodWhereInput>>;
  NOT?: Maybe<Array<RemittancePeriodWhereInput>>;
};

export type RemittancePeriodWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['Int']>;
};

export type RemittanceTransfer = {
  __typename?: 'RemittanceTransfer';
  id: Scalars['ID'];
  remittancePeriod?: Maybe<RemittancePeriod>;
  merchant?: Maybe<Merchant>;
  pdfFile?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  transferId?: Maybe<Scalars['String']>;
};

export type RemittanceTransferConnection = {
  __typename?: 'RemittanceTransferConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<RemittanceTransferEdge>>;
  aggregate: AggregateRemittanceTransfer;
};

export type RemittanceTransferCreateInput = {
  id?: Maybe<Scalars['ID']>;
  remittancePeriod?: Maybe<RemittancePeriodCreateOneInput>;
  merchant?: Maybe<MerchantConnectOneInput>;
  pdfFile?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  transferId?: Maybe<Scalars['String']>;
};

export type RemittanceTransferCreateOneInput = {
  create?: Maybe<RemittanceTransferCreateInput>;
  connect?: Maybe<RemittanceTransferWhereUniqueInput>;
};

export enum RemittanceTransferCsvFormat {
  Usdach = 'USDACH',
  Gbprev = 'GBPREV',
  Gbpsvb = 'GBPSVB',
  Usdrev = 'USDREV'
}

export type RemittanceTransferEdge = {
  __typename?: 'RemittanceTransferEdge';
  node: RemittanceTransfer;
  cursor: Scalars['String'];
};

export enum RemittanceTransferOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PdfFileAsc = 'pdfFile_ASC',
  PdfFileDesc = 'pdfFile_DESC',
  SentAtAsc = 'sentAt_ASC',
  SentAtDesc = 'sentAt_DESC',
  PaidAtAsc = 'paidAt_ASC',
  PaidAtDesc = 'paidAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  TransferIdAsc = 'transferId_ASC',
  TransferIdDesc = 'transferId_DESC'
}

export type RemittanceTransferPreviousValues = {
  __typename?: 'RemittanceTransferPreviousValues';
  id: Scalars['ID'];
  pdfFile?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  transferId?: Maybe<Scalars['String']>;
};

export type RemittanceTransferUpdateInput = {
  remittancePeriod?: Maybe<RemittancePeriodUpdateOneInput>;
  merchant?: Maybe<MerchantUpdateOneInput>;
  pdfFile?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  transferId?: Maybe<Scalars['String']>;
};

export type RemittanceTransferUpdateManyMutationInput = {
  pdfFile?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  transferId?: Maybe<Scalars['String']>;
};

export type RemittanceTransferUpdateOneInput = {
  create?: Maybe<RemittanceTransferCreateInput>;
  update?: Maybe<RemittanceTransferUpdateInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RemittanceTransferWhereUniqueInput>;
};

export type RemittanceTransferWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  remittancePeriod?: Maybe<RemittancePeriodWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  pdfFile?: Maybe<Scalars['String']>;
  pdfFile_not?: Maybe<Scalars['String']>;
  pdfFile_in?: Maybe<Array<Scalars['String']>>;
  pdfFile_not_in?: Maybe<Array<Scalars['String']>>;
  pdfFile_lt?: Maybe<Scalars['String']>;
  pdfFile_lte?: Maybe<Scalars['String']>;
  pdfFile_gt?: Maybe<Scalars['String']>;
  pdfFile_gte?: Maybe<Scalars['String']>;
  pdfFile_contains?: Maybe<Scalars['String']>;
  pdfFile_not_contains?: Maybe<Scalars['String']>;
  pdfFile_starts_with?: Maybe<Scalars['String']>;
  pdfFile_not_starts_with?: Maybe<Scalars['String']>;
  pdfFile_ends_with?: Maybe<Scalars['String']>;
  pdfFile_not_ends_with?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  sentAt_not?: Maybe<Scalars['DateTime']>;
  sentAt_in?: Maybe<Array<Scalars['DateTime']>>;
  sentAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  sentAt_lt?: Maybe<Scalars['DateTime']>;
  sentAt_lte?: Maybe<Scalars['DateTime']>;
  sentAt_gt?: Maybe<Scalars['DateTime']>;
  sentAt_gte?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paidAt_not?: Maybe<Scalars['DateTime']>;
  paidAt_in?: Maybe<Array<Scalars['DateTime']>>;
  paidAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  paidAt_lt?: Maybe<Scalars['DateTime']>;
  paidAt_lte?: Maybe<Scalars['DateTime']>;
  paidAt_gt?: Maybe<Scalars['DateTime']>;
  paidAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  transferId?: Maybe<Scalars['String']>;
  transferId_not?: Maybe<Scalars['String']>;
  transferId_in?: Maybe<Array<Scalars['String']>>;
  transferId_not_in?: Maybe<Array<Scalars['String']>>;
  transferId_lt?: Maybe<Scalars['String']>;
  transferId_lte?: Maybe<Scalars['String']>;
  transferId_gt?: Maybe<Scalars['String']>;
  transferId_gte?: Maybe<Scalars['String']>;
  transferId_contains?: Maybe<Scalars['String']>;
  transferId_not_contains?: Maybe<Scalars['String']>;
  transferId_starts_with?: Maybe<Scalars['String']>;
  transferId_not_starts_with?: Maybe<Scalars['String']>;
  transferId_ends_with?: Maybe<Scalars['String']>;
  transferId_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<RemittanceTransferWhereInput>>;
  OR?: Maybe<Array<RemittanceTransferWhereInput>>;
  NOT?: Maybe<Array<RemittanceTransferWhereInput>>;
};

export type RemittanceTransferWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type RemittanceTransfersBulkOutput = {
  __typename?: 'RemittanceTransfersBulkOutput';
  remittanceTransfers?: Maybe<Array<Maybe<RemittanceTransfer>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RemittanceTransfersCreateInput = {
  remittancePeriod: RemittancePeriodWhereUniqueInput;
};

export type RemittanceTransfersSelectInput = {
  remittanceTransfersIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  remittancePeriod?: Maybe<RemittancePeriodWhereUniqueInput>;
  merchantIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ResetPasswordComplete = {
  __typename?: 'ResetPasswordComplete';
  status: Scalars['String'];
  token: Scalars['String'];
};

export type ResetPasswordCompleteInput = {
  id: Scalars['String'];
  password: Scalars['String'];
  authorisation: Scalars['String'];
  hasAcceptedTerms?: Maybe<Scalars['Boolean']>;
  hasAgreedNewsletter?: Maybe<Scalars['Boolean']>;
  merchantId?: Maybe<Scalars['String']>;
};

export type ResetPasswordInitInput = {
  email: Scalars['String'];
};

export type ResetPasswordStatus = {
  __typename?: 'ResetPasswordStatus';
  status: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['ID'];
  source: Array<ReviewSource>;
  reviewerId?: Maybe<Scalars['String']>;
  reviewerName?: Maybe<Scalars['String']>;
  reviewerEmail?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  replies?: Maybe<Scalars['Json']>;
  media?: Maybe<Scalars['Json']>;
  createdDate: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  order?: Maybe<Order>;
  merchant?: Maybe<Merchant>;
  active?: Maybe<Scalars['Boolean']>;
};

export type ReviewConnection = {
  __typename?: 'ReviewConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ReviewEdge>>;
  aggregate: AggregateReview;
};

export type ReviewEdge = {
  __typename?: 'ReviewEdge';
  node: Review;
  cursor: Scalars['String'];
};

export enum ReviewOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  ReviewerIdAsc = 'reviewerId_ASC',
  ReviewerIdDesc = 'reviewerId_DESC',
  ReviewerNameAsc = 'reviewerName_ASC',
  ReviewerNameDesc = 'reviewerName_DESC',
  ReviewerEmailAsc = 'reviewerEmail_ASC',
  ReviewerEmailDesc = 'reviewerEmail_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  CommentsAsc = 'comments_ASC',
  CommentsDesc = 'comments_DESC',
  MediaAsc = 'media_ASC',
  MediaDesc = 'media_DESC',
  CreatedDateAsc = 'createdDate_ASC',
  CreatedDateDesc = 'createdDate_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC'
}

export type ReviewPreviousValues = {
  __typename?: 'ReviewPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['ID'];
  source: Array<ReviewSource>;
  reviewerId?: Maybe<Scalars['String']>;
  reviewerName?: Maybe<Scalars['String']>;
  reviewerEmail?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['Json']>;
  createdDate: Scalars['DateTime'];
  active?: Maybe<Scalars['Boolean']>;
};

export enum ReviewSource {
  ReviewsIo = 'ReviewsIO'
}

export type ReviewWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['ID']>;
  externalId_not?: Maybe<Scalars['ID']>;
  externalId_in?: Maybe<Array<Scalars['ID']>>;
  externalId_not_in?: Maybe<Array<Scalars['ID']>>;
  externalId_lt?: Maybe<Scalars['ID']>;
  externalId_lte?: Maybe<Scalars['ID']>;
  externalId_gt?: Maybe<Scalars['ID']>;
  externalId_gte?: Maybe<Scalars['ID']>;
  externalId_contains?: Maybe<Scalars['ID']>;
  externalId_not_contains?: Maybe<Scalars['ID']>;
  externalId_starts_with?: Maybe<Scalars['ID']>;
  externalId_not_starts_with?: Maybe<Scalars['ID']>;
  externalId_ends_with?: Maybe<Scalars['ID']>;
  externalId_not_ends_with?: Maybe<Scalars['ID']>;
  reviewerId?: Maybe<Scalars['String']>;
  reviewerId_not?: Maybe<Scalars['String']>;
  reviewerId_in?: Maybe<Array<Scalars['String']>>;
  reviewerId_not_in?: Maybe<Array<Scalars['String']>>;
  reviewerId_lt?: Maybe<Scalars['String']>;
  reviewerId_lte?: Maybe<Scalars['String']>;
  reviewerId_gt?: Maybe<Scalars['String']>;
  reviewerId_gte?: Maybe<Scalars['String']>;
  reviewerId_contains?: Maybe<Scalars['String']>;
  reviewerId_not_contains?: Maybe<Scalars['String']>;
  reviewerId_starts_with?: Maybe<Scalars['String']>;
  reviewerId_not_starts_with?: Maybe<Scalars['String']>;
  reviewerId_ends_with?: Maybe<Scalars['String']>;
  reviewerId_not_ends_with?: Maybe<Scalars['String']>;
  reviewerName?: Maybe<Scalars['String']>;
  reviewerName_not?: Maybe<Scalars['String']>;
  reviewerName_in?: Maybe<Array<Scalars['String']>>;
  reviewerName_not_in?: Maybe<Array<Scalars['String']>>;
  reviewerName_lt?: Maybe<Scalars['String']>;
  reviewerName_lte?: Maybe<Scalars['String']>;
  reviewerName_gt?: Maybe<Scalars['String']>;
  reviewerName_gte?: Maybe<Scalars['String']>;
  reviewerName_contains?: Maybe<Scalars['String']>;
  reviewerName_not_contains?: Maybe<Scalars['String']>;
  reviewerName_starts_with?: Maybe<Scalars['String']>;
  reviewerName_not_starts_with?: Maybe<Scalars['String']>;
  reviewerName_ends_with?: Maybe<Scalars['String']>;
  reviewerName_not_ends_with?: Maybe<Scalars['String']>;
  reviewerEmail?: Maybe<Scalars['String']>;
  reviewerEmail_not?: Maybe<Scalars['String']>;
  reviewerEmail_in?: Maybe<Array<Scalars['String']>>;
  reviewerEmail_not_in?: Maybe<Array<Scalars['String']>>;
  reviewerEmail_lt?: Maybe<Scalars['String']>;
  reviewerEmail_lte?: Maybe<Scalars['String']>;
  reviewerEmail_gt?: Maybe<Scalars['String']>;
  reviewerEmail_gte?: Maybe<Scalars['String']>;
  reviewerEmail_contains?: Maybe<Scalars['String']>;
  reviewerEmail_not_contains?: Maybe<Scalars['String']>;
  reviewerEmail_starts_with?: Maybe<Scalars['String']>;
  reviewerEmail_not_starts_with?: Maybe<Scalars['String']>;
  reviewerEmail_ends_with?: Maybe<Scalars['String']>;
  reviewerEmail_not_ends_with?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  rating_not?: Maybe<Scalars['Int']>;
  rating_in?: Maybe<Array<Scalars['Int']>>;
  rating_not_in?: Maybe<Array<Scalars['Int']>>;
  rating_lt?: Maybe<Scalars['Int']>;
  rating_lte?: Maybe<Scalars['Int']>;
  rating_gt?: Maybe<Scalars['Int']>;
  rating_gte?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  comments_not?: Maybe<Scalars['String']>;
  comments_in?: Maybe<Array<Scalars['String']>>;
  comments_not_in?: Maybe<Array<Scalars['String']>>;
  comments_lt?: Maybe<Scalars['String']>;
  comments_lte?: Maybe<Scalars['String']>;
  comments_gt?: Maybe<Scalars['String']>;
  comments_gte?: Maybe<Scalars['String']>;
  comments_contains?: Maybe<Scalars['String']>;
  comments_not_contains?: Maybe<Scalars['String']>;
  comments_starts_with?: Maybe<Scalars['String']>;
  comments_not_starts_with?: Maybe<Scalars['String']>;
  comments_ends_with?: Maybe<Scalars['String']>;
  comments_not_ends_with?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['DateTime']>;
  createdDate_not?: Maybe<Scalars['DateTime']>;
  createdDate_in?: Maybe<Array<Scalars['DateTime']>>;
  createdDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdDate_lt?: Maybe<Scalars['DateTime']>;
  createdDate_lte?: Maybe<Scalars['DateTime']>;
  createdDate_gt?: Maybe<Scalars['DateTime']>;
  createdDate_gte?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerWhereInput>;
  order?: Maybe<OrderWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<ReviewWhereInput>>;
  OR?: Maybe<Array<ReviewWhereInput>>;
  NOT?: Maybe<Array<ReviewWhereInput>>;
};

export type ReviewWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum RijnsDeliveryType {
  Standard = 'Standard',
  LondonHub = 'LondonHub'
}

export type SendPushNotificationInput = {
  title: Scalars['String'];
  body: Scalars['String'];
  payload?: Maybe<Scalars['Json']>;
};

export type SendPushNotificationResponse = {
  __typename?: 'SendPushNotificationResponse';
  success: Scalars['Int'];
  failure: Scalars['Int'];
};

export type Shop = {
  __typename?: 'Shop';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  title: Scalars['String'];
  geoLocation?: Maybe<GeoLocation>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
  createdBy?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopConnectWhereUnique = {
  connect?: Maybe<ShopWhereUniqueInput>;
};

export type ShopConnection = {
  __typename?: 'ShopConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ShopEdge>>;
  aggregate: AggregateShop;
};

export type ShopCreateInput = {
  id?: Maybe<Scalars['ID']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  geoLocation?: Maybe<GeoLocationCreateOneInput>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamCreateOneInput>;
  createdBy?: Maybe<UserCreateOneWithoutShopInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopCreateManyInput = {
  create?: Maybe<Array<ShopCreateInput>>;
  connect?: Maybe<Array<ShopWhereUniqueInput>>;
};

export type ShopCreateOneWithoutCreatedByInput = {
  create?: Maybe<ShopCreateWithoutCreatedByInput>;
  connect?: Maybe<ShopWhereUniqueInput>;
};

export type ShopCreateWithoutCreatedByInput = {
  id?: Maybe<Scalars['ID']>;
  active: Scalars['Boolean'];
  title: Scalars['String'];
  geoLocation?: Maybe<GeoLocationCreateOneInput>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopEdge = {
  __typename?: 'ShopEdge';
  node: Shop;
  cursor: Scalars['String'];
};

export enum ShopOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  RegionAsc = 'region_ASC',
  RegionDesc = 'region_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type ShopPreviousValues = {
  __typename?: 'ShopPreviousValues';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  title: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_not?: Maybe<Scalars['String']>;
  region_in?: Maybe<Array<Scalars['String']>>;
  region_not_in?: Maybe<Array<Scalars['String']>>;
  region_lt?: Maybe<Scalars['String']>;
  region_lte?: Maybe<Scalars['String']>;
  region_gt?: Maybe<Scalars['String']>;
  region_gte?: Maybe<Scalars['String']>;
  region_contains?: Maybe<Scalars['String']>;
  region_not_contains?: Maybe<Scalars['String']>;
  region_starts_with?: Maybe<Scalars['String']>;
  region_not_starts_with?: Maybe<Scalars['String']>;
  region_ends_with?: Maybe<Scalars['String']>;
  region_not_ends_with?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ShopScalarWhereInput>>;
  OR?: Maybe<Array<ShopScalarWhereInput>>;
  NOT?: Maybe<Array<ShopScalarWhereInput>>;
};

export type ShopSubscriptionPayload = {
  __typename?: 'ShopSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Shop>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<ShopPreviousValues>;
};

export type ShopSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<ShopWhereInput>;
  AND?: Maybe<Array<ShopSubscriptionWhereInput>>;
  OR?: Maybe<Array<ShopSubscriptionWhereInput>>;
  NOT?: Maybe<Array<ShopSubscriptionWhereInput>>;
};

export type ShopUpdateDataInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  geoLocation?: Maybe<GeoLocationUpdateOneInput>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  createdBy?: Maybe<UserUpdateOneWithoutShopInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  geoLocation?: Maybe<GeoLocationUpdateOneInput>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  createdBy?: Maybe<UserUpdateOneWithoutShopInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopUpdateManyDataInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopUpdateManyInput = {
  create?: Maybe<Array<ShopCreateInput>>;
  update?: Maybe<Array<ShopUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<ShopUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<ShopWhereUniqueInput>>;
  connect?: Maybe<Array<ShopWhereUniqueInput>>;
  set?: Maybe<Array<ShopWhereUniqueInput>>;
  disconnect?: Maybe<Array<ShopWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ShopScalarWhereInput>>;
  updateMany?: Maybe<Array<ShopUpdateManyWithWhereNestedInput>>;
};

export type ShopUpdateManyMutationInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopUpdateManyWithWhereNestedInput = {
  where: ShopScalarWhereInput;
  data: ShopUpdateManyDataInput;
};

export type ShopUpdateOneWithoutCreatedByInput = {
  create?: Maybe<ShopCreateWithoutCreatedByInput>;
  update?: Maybe<ShopUpdateWithoutCreatedByDataInput>;
  upsert?: Maybe<ShopUpsertWithoutCreatedByInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ShopWhereUniqueInput>;
};

export type ShopUpdateWithWhereUniqueNestedInput = {
  where: ShopWhereUniqueInput;
  data: ShopUpdateDataInput;
};

export type ShopUpdateWithoutCreatedByDataInput = {
  active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  geoLocation?: Maybe<GeoLocationUpdateOneInput>;
  region?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopUpsertWithWhereUniqueNestedInput = {
  where: ShopWhereUniqueInput;
  update: ShopUpdateDataInput;
  create: ShopCreateInput;
};

export type ShopUpsertWithoutCreatedByInput = {
  update: ShopUpdateWithoutCreatedByDataInput;
  create: ShopCreateWithoutCreatedByInput;
};

export type ShopWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  geoLocation?: Maybe<GeoLocationWhereInput>;
  region?: Maybe<Scalars['String']>;
  region_not?: Maybe<Scalars['String']>;
  region_in?: Maybe<Array<Scalars['String']>>;
  region_not_in?: Maybe<Array<Scalars['String']>>;
  region_lt?: Maybe<Scalars['String']>;
  region_lte?: Maybe<Scalars['String']>;
  region_gt?: Maybe<Scalars['String']>;
  region_gte?: Maybe<Scalars['String']>;
  region_contains?: Maybe<Scalars['String']>;
  region_not_contains?: Maybe<Scalars['String']>;
  region_starts_with?: Maybe<Scalars['String']>;
  region_not_starts_with?: Maybe<Scalars['String']>;
  region_ends_with?: Maybe<Scalars['String']>;
  region_not_ends_with?: Maybe<Scalars['String']>;
  team?: Maybe<TeamWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<ShopWhereInput>>;
  OR?: Maybe<Array<ShopWhereInput>>;
  NOT?: Maybe<Array<ShopWhereInput>>;
};

export type ShopWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type StemLength = {
  __typename?: 'StemLength';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  stemLength: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
  component?: Maybe<Component>;
};

export type StemLengthConnection = {
  __typename?: 'StemLengthConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<StemLengthEdge>>;
  aggregate: AggregateStemLength;
};

export type StemLengthCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  stemLength: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
  component?: Maybe<ComponentCreateOneWithoutLengthsInput>;
};

export type StemLengthCreateManyWithoutComponentInput = {
  create?: Maybe<Array<StemLengthCreateWithoutComponentInput>>;
  connect?: Maybe<Array<StemLengthWhereUniqueInput>>;
};

export type StemLengthCreateWithoutComponentInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  stemLength: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
};

export type StemLengthEdge = {
  __typename?: 'StemLengthEdge';
  node: StemLength;
  cursor: Scalars['String'];
};

export enum StemLengthOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StemLengthAsc = 'stemLength_ASC',
  StemLengthDesc = 'stemLength_DESC',
  UnitAsc = 'unit_ASC',
  UnitDesc = 'unit_DESC'
}

export type StemLengthPreviousValues = {
  __typename?: 'StemLengthPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  stemLength: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
};

export type StemLengthScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  stemLength?: Maybe<Scalars['Int']>;
  stemLength_not?: Maybe<Scalars['Int']>;
  stemLength_in?: Maybe<Array<Scalars['Int']>>;
  stemLength_not_in?: Maybe<Array<Scalars['Int']>>;
  stemLength_lt?: Maybe<Scalars['Int']>;
  stemLength_lte?: Maybe<Scalars['Int']>;
  stemLength_gt?: Maybe<Scalars['Int']>;
  stemLength_gte?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  unit_not?: Maybe<Scalars['String']>;
  unit_in?: Maybe<Array<Scalars['String']>>;
  unit_not_in?: Maybe<Array<Scalars['String']>>;
  unit_lt?: Maybe<Scalars['String']>;
  unit_lte?: Maybe<Scalars['String']>;
  unit_gt?: Maybe<Scalars['String']>;
  unit_gte?: Maybe<Scalars['String']>;
  unit_contains?: Maybe<Scalars['String']>;
  unit_not_contains?: Maybe<Scalars['String']>;
  unit_starts_with?: Maybe<Scalars['String']>;
  unit_not_starts_with?: Maybe<Scalars['String']>;
  unit_ends_with?: Maybe<Scalars['String']>;
  unit_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<StemLengthScalarWhereInput>>;
  OR?: Maybe<Array<StemLengthScalarWhereInput>>;
  NOT?: Maybe<Array<StemLengthScalarWhereInput>>;
};

export type StemLengthSubscriptionPayload = {
  __typename?: 'StemLengthSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<StemLength>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<StemLengthPreviousValues>;
};

export type StemLengthSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<StemLengthWhereInput>;
  AND?: Maybe<Array<StemLengthSubscriptionWhereInput>>;
  OR?: Maybe<Array<StemLengthSubscriptionWhereInput>>;
  NOT?: Maybe<Array<StemLengthSubscriptionWhereInput>>;
};

export type StemLengthUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  stemLength?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  component?: Maybe<ComponentUpdateOneWithoutLengthsInput>;
};

export type StemLengthUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  stemLength?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type StemLengthUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  stemLength?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type StemLengthUpdateManyWithWhereNestedInput = {
  where: StemLengthScalarWhereInput;
  data: StemLengthUpdateManyDataInput;
};

export type StemLengthUpdateManyWithoutComponentInput = {
  create?: Maybe<Array<StemLengthCreateWithoutComponentInput>>;
  delete?: Maybe<Array<StemLengthWhereUniqueInput>>;
  connect?: Maybe<Array<StemLengthWhereUniqueInput>>;
  set?: Maybe<Array<StemLengthWhereUniqueInput>>;
  disconnect?: Maybe<Array<StemLengthWhereUniqueInput>>;
  update?: Maybe<Array<StemLengthUpdateWithWhereUniqueWithoutComponentInput>>;
  upsert?: Maybe<Array<StemLengthUpsertWithWhereUniqueWithoutComponentInput>>;
  deleteMany?: Maybe<Array<StemLengthScalarWhereInput>>;
  updateMany?: Maybe<Array<StemLengthUpdateManyWithWhereNestedInput>>;
};

export type StemLengthUpdateWithWhereUniqueWithoutComponentInput = {
  where: StemLengthWhereUniqueInput;
  data: StemLengthUpdateWithoutComponentDataInput;
};

export type StemLengthUpdateWithoutComponentDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  stemLength?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type StemLengthUpsertWithWhereUniqueWithoutComponentInput = {
  where: StemLengthWhereUniqueInput;
  update: StemLengthUpdateWithoutComponentDataInput;
  create: StemLengthCreateWithoutComponentInput;
};

export type StemLengthWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  stemLength?: Maybe<Scalars['Int']>;
  stemLength_not?: Maybe<Scalars['Int']>;
  stemLength_in?: Maybe<Array<Scalars['Int']>>;
  stemLength_not_in?: Maybe<Array<Scalars['Int']>>;
  stemLength_lt?: Maybe<Scalars['Int']>;
  stemLength_lte?: Maybe<Scalars['Int']>;
  stemLength_gt?: Maybe<Scalars['Int']>;
  stemLength_gte?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  unit_not?: Maybe<Scalars['String']>;
  unit_in?: Maybe<Array<Scalars['String']>>;
  unit_not_in?: Maybe<Array<Scalars['String']>>;
  unit_lt?: Maybe<Scalars['String']>;
  unit_lte?: Maybe<Scalars['String']>;
  unit_gt?: Maybe<Scalars['String']>;
  unit_gte?: Maybe<Scalars['String']>;
  unit_contains?: Maybe<Scalars['String']>;
  unit_not_contains?: Maybe<Scalars['String']>;
  unit_starts_with?: Maybe<Scalars['String']>;
  unit_not_starts_with?: Maybe<Scalars['String']>;
  unit_ends_with?: Maybe<Scalars['String']>;
  unit_not_ends_with?: Maybe<Scalars['String']>;
  component?: Maybe<ComponentWhereInput>;
  AND?: Maybe<Array<StemLengthWhereInput>>;
  OR?: Maybe<Array<StemLengthWhereInput>>;
  NOT?: Maybe<Array<StemLengthWhereInput>>;
};

export type StemLengthWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type StripeInvoiceResponse = {
  __typename?: 'StripeInvoiceResponse';
  url?: Maybe<Scalars['String']>;
};

export type StripePlanResponse = {
  __typename?: 'StripePlanResponse';
  plan: Scalars['Json'];
  nextInvoiceDueDate?: Maybe<Scalars['Json']>;
};

export type StripePrePaymentOrder = {
  __typename?: 'StripePrePaymentOrder';
  order: Order;
  payment_intent_stripe_secret: Scalars['String'];
  merchant_stripe_connect_id: Scalars['String'];
};

export type SubscribeResult = {
  __typename?: 'SubscribeResult';
  subscribed: Scalars['Boolean'];
};

export type SubscriptionPlansResponse = {
  __typename?: 'SubscriptionPlansResponse';
  plans?: Maybe<Array<Maybe<FloomSubscriptionPlan>>>;
};

export enum SubscriptionStatus {
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  Trialing = 'TRIALING',
  Active = 'ACTIVE',
  PastDue = 'PAST_DUE',
  Canceled = 'CANCELED',
  Unpaid = 'UNPAID',
  Unsubscribed = 'UNSUBSCRIBED'
}

export type Supplier = {
  __typename?: 'Supplier';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  integrationType: SupplierIntegrationType;
  holidays?: Maybe<Array<MerchantHoliday>>;
  wholesaleFeatures?: Maybe<Array<WholesaleFeature>>;
  itemCancellationWindowInMinutes: Scalars['Int'];
};


export type SupplierHolidaysArgs = {
  where?: Maybe<MerchantHolidayWhereInput>;
  orderBy?: Maybe<MerchantHolidayOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SupplierWholesaleFeaturesArgs = {
  where?: Maybe<WholesaleFeatureWhereInput>;
  orderBy?: Maybe<WholesaleFeatureOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SupplierConnectManyInput = {
  connect?: Maybe<Array<SupplierWhereUniqueInput>>;
};

export type SupplierConnectOneInput = {
  connect?: Maybe<SupplierWhereUniqueInput>;
};

export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<SupplierEdge>>;
  aggregate: AggregateSupplier;
};

export type SupplierCreateInput = {
  id?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  holidays?: Maybe<MerchantHolidayCreateManyInput>;
};

export type SupplierCreateManyInput = {
  create?: Maybe<Array<SupplierCreateInput>>;
  connect?: Maybe<Array<SupplierWhereUniqueInput>>;
};

export type SupplierCreateOneInput = {
  create?: Maybe<SupplierCreateInput>;
  connect?: Maybe<SupplierWhereUniqueInput>;
};

export type SupplierDeliveryConfig = {
  __typename?: 'SupplierDeliveryConfig';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  supplier: Supplier;
  catalogItemTypes: Array<CatalogItemType>;
  listItemTypes: Array<ListItemType>;
  deliveryHubs?: Maybe<Array<SupplierDeliveryHub>>;
  deliveryOptions?: Maybe<Array<SupplierDeliveryOption>>;
};


export type SupplierDeliveryConfigDeliveryHubsArgs = {
  where?: Maybe<SupplierDeliveryHubWhereInput>;
  orderBy?: Maybe<SupplierDeliveryHubOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SupplierDeliveryConfigDeliveryOptionsArgs = {
  where?: Maybe<SupplierDeliveryOptionWhereInput>;
  orderBy?: Maybe<SupplierDeliveryOptionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryConfigConnection = {
  __typename?: 'SupplierDeliveryConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<SupplierDeliveryConfigEdge>>;
  aggregate: AggregateSupplierDeliveryConfig;
};

export type SupplierDeliveryConfigCreateInput = {
  supplier: SupplierCreateOneInput;
  catalogItemTypes?: Maybe<SupplierDeliveryConfigCreatecatalogItemTypesInput>;
  deliveryHubs?: Maybe<SupplierDeliveryHubCreateManyInput>;
  deliveryOptions?: Maybe<SupplierDeliveryOptionCreateManyWithoutCriteriaInput>;
};

export type SupplierDeliveryConfigCreateManyWithoutDeliveryOptionsInput = {
  create?: Maybe<Array<SupplierDeliveryConfigCreateWithoutDeliveryOptionsInput>>;
  connect?: Maybe<Array<SupplierDeliveryConfigWhereUniqueInput>>;
};

export type SupplierDeliveryConfigCreateWithoutDeliveryOptionsInput = {
  supplier: SupplierCreateOneInput;
  catalogItemTypes?: Maybe<SupplierDeliveryConfigCreatecatalogItemTypesInput>;
  listItemTypes?: Maybe<SupplierDeliveryConfigCreatelistItemTypesInput>;
  deliveryHubs?: Maybe<SupplierDeliveryHubCreateManyInput>;
};

export type SupplierDeliveryConfigCreatecatalogItemTypesInput = {
  set?: Maybe<Array<CatalogItemType>>;
};

export type SupplierDeliveryConfigCreatelistItemTypesInput = {
  set?: Maybe<Array<ListItemType>>;
};

export type SupplierDeliveryConfigEdge = {
  __typename?: 'SupplierDeliveryConfigEdge';
  node: SupplierDeliveryConfig;
  cursor: Scalars['String'];
};

export enum SupplierDeliveryConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type SupplierDeliveryConfigPreviousValues = {
  __typename?: 'SupplierDeliveryConfigPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  catalogItemTypes: Array<CatalogItemType>;
  listItemTypes: Array<ListItemType>;
};

export type SupplierDeliveryConfigScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<SupplierDeliveryConfigScalarWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryConfigScalarWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryConfigScalarWhereInput>>;
};

export type SupplierDeliveryConfigSubscriptionPayload = {
  __typename?: 'SupplierDeliveryConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<SupplierDeliveryConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<SupplierDeliveryConfigPreviousValues>;
};

export type SupplierDeliveryConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<SupplierDeliveryConfigWhereInput>;
  AND?: Maybe<Array<SupplierDeliveryConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryConfigSubscriptionWhereInput>>;
};

export type SupplierDeliveryConfigUpdateInput = {
  supplier?: Maybe<SupplierUpdateOneRequiredInput>;
  catalogItemTypes?: Maybe<SupplierDeliveryConfigUpdatecatalogItemTypesInput>;
  listItemTypes?: Maybe<SupplierDeliveryConfigUpdatelistItemTypesInput>;
  deliveryHubs?: Maybe<SupplierDeliveryHubUpdateManyInput>;
  deliveryOptions?: Maybe<SupplierDeliveryOptionUpdateManyWithoutCriteriaInput>;
};

export type SupplierDeliveryConfigUpdateManyDataInput = {
  catalogItemTypes?: Maybe<SupplierDeliveryConfigUpdatecatalogItemTypesInput>;
  listItemTypes?: Maybe<SupplierDeliveryConfigUpdatelistItemTypesInput>;
};

export type SupplierDeliveryConfigUpdateManyMutationInput = {
  catalogItemTypes?: Maybe<SupplierDeliveryConfigUpdatecatalogItemTypesInput>;
  listItemTypes?: Maybe<SupplierDeliveryConfigUpdatelistItemTypesInput>;
};

export type SupplierDeliveryConfigUpdateManyWithWhereNestedInput = {
  where: SupplierDeliveryConfigScalarWhereInput;
  data: SupplierDeliveryConfigUpdateManyDataInput;
};

export type SupplierDeliveryConfigUpdateManyWithoutDeliveryOptionsInput = {
  create?: Maybe<Array<SupplierDeliveryConfigCreateWithoutDeliveryOptionsInput>>;
  delete?: Maybe<Array<SupplierDeliveryConfigWhereUniqueInput>>;
  connect?: Maybe<Array<SupplierDeliveryConfigWhereUniqueInput>>;
  set?: Maybe<Array<SupplierDeliveryConfigWhereUniqueInput>>;
  disconnect?: Maybe<Array<SupplierDeliveryConfigWhereUniqueInput>>;
  update?: Maybe<Array<SupplierDeliveryConfigUpdateWithWhereUniqueWithoutDeliveryOptionsInput>>;
  upsert?: Maybe<Array<SupplierDeliveryConfigUpsertWithWhereUniqueWithoutDeliveryOptionsInput>>;
  deleteMany?: Maybe<Array<SupplierDeliveryConfigScalarWhereInput>>;
  updateMany?: Maybe<Array<SupplierDeliveryConfigUpdateManyWithWhereNestedInput>>;
};

export type SupplierDeliveryConfigUpdateWithWhereUniqueWithoutDeliveryOptionsInput = {
  where: SupplierDeliveryConfigWhereUniqueInput;
  data: SupplierDeliveryConfigUpdateWithoutDeliveryOptionsDataInput;
};

export type SupplierDeliveryConfigUpdateWithoutDeliveryOptionsDataInput = {
  supplier?: Maybe<SupplierUpdateOneRequiredInput>;
  catalogItemTypes?: Maybe<SupplierDeliveryConfigUpdatecatalogItemTypesInput>;
  listItemTypes?: Maybe<SupplierDeliveryConfigUpdatelistItemTypesInput>;
  deliveryHubs?: Maybe<SupplierDeliveryHubUpdateManyInput>;
};

export type SupplierDeliveryConfigUpdatecatalogItemTypesInput = {
  set?: Maybe<Array<CatalogItemType>>;
};

export type SupplierDeliveryConfigUpdatelistItemTypesInput = {
  set?: Maybe<Array<ListItemType>>;
};

export type SupplierDeliveryConfigUpsertWithWhereUniqueWithoutDeliveryOptionsInput = {
  where: SupplierDeliveryConfigWhereUniqueInput;
  update: SupplierDeliveryConfigUpdateWithoutDeliveryOptionsDataInput;
  create: SupplierDeliveryConfigCreateWithoutDeliveryOptionsInput;
};

export type SupplierDeliveryConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  supplier?: Maybe<SupplierWhereInput>;
  deliveryHubs_every?: Maybe<SupplierDeliveryHubWhereInput>;
  deliveryHubs_some?: Maybe<SupplierDeliveryHubWhereInput>;
  deliveryHubs_none?: Maybe<SupplierDeliveryHubWhereInput>;
  deliveryOptions_every?: Maybe<SupplierDeliveryOptionWhereInput>;
  deliveryOptions_some?: Maybe<SupplierDeliveryOptionWhereInput>;
  deliveryOptions_none?: Maybe<SupplierDeliveryOptionWhereInput>;
  AND?: Maybe<Array<SupplierDeliveryConfigWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryConfigWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryConfigWhereInput>>;
};

export type SupplierDeliveryConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type SupplierDeliveryDayConfig = {
  __typename?: 'SupplierDeliveryDayConfig';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dayOfWeek: DayOfWeek;
  canDeliver: Scalars['Boolean'];
  leadHours: Scalars['Int'];
  processingHours?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryDayConfigConnection = {
  __typename?: 'SupplierDeliveryDayConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<SupplierDeliveryDayConfigEdge>>;
  aggregate: AggregateSupplierDeliveryDayConfig;
};

export type SupplierDeliveryDayConfigCreateInput = {
  dayOfWeek: DayOfWeek;
  canDeliver?: Maybe<Scalars['Boolean']>;
  leadHours?: Maybe<Scalars['Int']>;
  processingHours?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryDayConfigCreateManyInput = {
  create?: Maybe<Array<SupplierDeliveryDayConfigCreateInput>>;
  connect?: Maybe<Array<SupplierDeliveryDayConfigWhereUniqueInput>>;
};

export type SupplierDeliveryDayConfigEdge = {
  __typename?: 'SupplierDeliveryDayConfigEdge';
  node: SupplierDeliveryDayConfig;
  cursor: Scalars['String'];
};

export enum SupplierDeliveryDayConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DayOfWeekAsc = 'dayOfWeek_ASC',
  DayOfWeekDesc = 'dayOfWeek_DESC',
  CanDeliverAsc = 'canDeliver_ASC',
  CanDeliverDesc = 'canDeliver_DESC',
  LeadHoursAsc = 'leadHours_ASC',
  LeadHoursDesc = 'leadHours_DESC',
  ProcessingHoursAsc = 'processingHours_ASC',
  ProcessingHoursDesc = 'processingHours_DESC'
}

export type SupplierDeliveryDayConfigPreviousValues = {
  __typename?: 'SupplierDeliveryDayConfigPreviousValues';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dayOfWeek: DayOfWeek;
  canDeliver: Scalars['Boolean'];
  leadHours: Scalars['Int'];
  processingHours?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryDayConfigScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  dayOfWeek_not?: Maybe<DayOfWeek>;
  dayOfWeek_in?: Maybe<Array<DayOfWeek>>;
  dayOfWeek_not_in?: Maybe<Array<DayOfWeek>>;
  canDeliver?: Maybe<Scalars['Boolean']>;
  canDeliver_not?: Maybe<Scalars['Boolean']>;
  leadHours?: Maybe<Scalars['Int']>;
  leadHours_not?: Maybe<Scalars['Int']>;
  leadHours_in?: Maybe<Array<Scalars['Int']>>;
  leadHours_not_in?: Maybe<Array<Scalars['Int']>>;
  leadHours_lt?: Maybe<Scalars['Int']>;
  leadHours_lte?: Maybe<Scalars['Int']>;
  leadHours_gt?: Maybe<Scalars['Int']>;
  leadHours_gte?: Maybe<Scalars['Int']>;
  processingHours?: Maybe<Scalars['Int']>;
  processingHours_not?: Maybe<Scalars['Int']>;
  processingHours_in?: Maybe<Array<Scalars['Int']>>;
  processingHours_not_in?: Maybe<Array<Scalars['Int']>>;
  processingHours_lt?: Maybe<Scalars['Int']>;
  processingHours_lte?: Maybe<Scalars['Int']>;
  processingHours_gt?: Maybe<Scalars['Int']>;
  processingHours_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<SupplierDeliveryDayConfigScalarWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryDayConfigScalarWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryDayConfigScalarWhereInput>>;
};

export type SupplierDeliveryDayConfigSubscriptionPayload = {
  __typename?: 'SupplierDeliveryDayConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<SupplierDeliveryDayConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<SupplierDeliveryDayConfigPreviousValues>;
};

export type SupplierDeliveryDayConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<SupplierDeliveryDayConfigWhereInput>;
  AND?: Maybe<Array<SupplierDeliveryDayConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryDayConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryDayConfigSubscriptionWhereInput>>;
};

export type SupplierDeliveryDayConfigUpdateDataInput = {
  dayOfWeek?: Maybe<DayOfWeek>;
  canDeliver?: Maybe<Scalars['Boolean']>;
  leadHours?: Maybe<Scalars['Int']>;
  processingHours?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryDayConfigUpdateInput = {
  dayOfWeek?: Maybe<DayOfWeek>;
  canDeliver?: Maybe<Scalars['Boolean']>;
  leadHours?: Maybe<Scalars['Int']>;
  processingHours?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryDayConfigUpdateManyDataInput = {
  dayOfWeek?: Maybe<DayOfWeek>;
  canDeliver?: Maybe<Scalars['Boolean']>;
  leadHours?: Maybe<Scalars['Int']>;
  processingHours?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryDayConfigUpdateManyInput = {
  create?: Maybe<Array<SupplierDeliveryDayConfigCreateInput>>;
  update?: Maybe<Array<SupplierDeliveryDayConfigUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<SupplierDeliveryDayConfigUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<SupplierDeliveryDayConfigWhereUniqueInput>>;
  connect?: Maybe<Array<SupplierDeliveryDayConfigWhereUniqueInput>>;
  set?: Maybe<Array<SupplierDeliveryDayConfigWhereUniqueInput>>;
  disconnect?: Maybe<Array<SupplierDeliveryDayConfigWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SupplierDeliveryDayConfigScalarWhereInput>>;
  updateMany?: Maybe<Array<SupplierDeliveryDayConfigUpdateManyWithWhereNestedInput>>;
};

export type SupplierDeliveryDayConfigUpdateManyMutationInput = {
  dayOfWeek?: Maybe<DayOfWeek>;
  canDeliver?: Maybe<Scalars['Boolean']>;
  leadHours?: Maybe<Scalars['Int']>;
  processingHours?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryDayConfigUpdateManyWithWhereNestedInput = {
  where: SupplierDeliveryDayConfigScalarWhereInput;
  data: SupplierDeliveryDayConfigUpdateManyDataInput;
};

export type SupplierDeliveryDayConfigUpdateWithWhereUniqueNestedInput = {
  where: SupplierDeliveryDayConfigWhereUniqueInput;
  data: SupplierDeliveryDayConfigUpdateDataInput;
};

export type SupplierDeliveryDayConfigUpsertWithWhereUniqueNestedInput = {
  where: SupplierDeliveryDayConfigWhereUniqueInput;
  update: SupplierDeliveryDayConfigUpdateDataInput;
  create: SupplierDeliveryDayConfigCreateInput;
};

export type SupplierDeliveryDayConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  dayOfWeek_not?: Maybe<DayOfWeek>;
  dayOfWeek_in?: Maybe<Array<DayOfWeek>>;
  dayOfWeek_not_in?: Maybe<Array<DayOfWeek>>;
  canDeliver?: Maybe<Scalars['Boolean']>;
  canDeliver_not?: Maybe<Scalars['Boolean']>;
  leadHours?: Maybe<Scalars['Int']>;
  leadHours_not?: Maybe<Scalars['Int']>;
  leadHours_in?: Maybe<Array<Scalars['Int']>>;
  leadHours_not_in?: Maybe<Array<Scalars['Int']>>;
  leadHours_lt?: Maybe<Scalars['Int']>;
  leadHours_lte?: Maybe<Scalars['Int']>;
  leadHours_gt?: Maybe<Scalars['Int']>;
  leadHours_gte?: Maybe<Scalars['Int']>;
  processingHours?: Maybe<Scalars['Int']>;
  processingHours_not?: Maybe<Scalars['Int']>;
  processingHours_in?: Maybe<Array<Scalars['Int']>>;
  processingHours_not_in?: Maybe<Array<Scalars['Int']>>;
  processingHours_lt?: Maybe<Scalars['Int']>;
  processingHours_lte?: Maybe<Scalars['Int']>;
  processingHours_gt?: Maybe<Scalars['Int']>;
  processingHours_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<SupplierDeliveryDayConfigWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryDayConfigWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryDayConfigWhereInput>>;
};

export type SupplierDeliveryDayConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type SupplierDeliveryHub = {
  __typename?: 'SupplierDeliveryHub';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  supplier: Supplier;
  title?: Maybe<Scalars['String']>;
};

export type SupplierDeliveryHubConnection = {
  __typename?: 'SupplierDeliveryHubConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<SupplierDeliveryHubEdge>>;
  aggregate: AggregateSupplierDeliveryHub;
};

export type SupplierDeliveryHubCreateInput = {
  supplier: SupplierCreateOneInput;
  title?: Maybe<Scalars['String']>;
};

export type SupplierDeliveryHubCreateManyInput = {
  create?: Maybe<Array<SupplierDeliveryHubCreateInput>>;
  connect?: Maybe<Array<SupplierDeliveryHubWhereUniqueInput>>;
};

export type SupplierDeliveryHubEdge = {
  __typename?: 'SupplierDeliveryHubEdge';
  node: SupplierDeliveryHub;
  cursor: Scalars['String'];
};

export enum SupplierDeliveryHubOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type SupplierDeliveryHubPreviousValues = {
  __typename?: 'SupplierDeliveryHubPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type SupplierDeliveryHubScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<SupplierDeliveryHubScalarWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryHubScalarWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryHubScalarWhereInput>>;
};

export type SupplierDeliveryHubSubscriptionPayload = {
  __typename?: 'SupplierDeliveryHubSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<SupplierDeliveryHub>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<SupplierDeliveryHubPreviousValues>;
};

export type SupplierDeliveryHubSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<SupplierDeliveryHubWhereInput>;
  AND?: Maybe<Array<SupplierDeliveryHubSubscriptionWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryHubSubscriptionWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryHubSubscriptionWhereInput>>;
};

export type SupplierDeliveryHubUpdateDataInput = {
  supplier?: Maybe<SupplierUpdateOneRequiredInput>;
  title?: Maybe<Scalars['String']>;
};

export type SupplierDeliveryHubUpdateInput = {
  supplier?: Maybe<SupplierUpdateOneRequiredInput>;
  title?: Maybe<Scalars['String']>;
};

export type SupplierDeliveryHubUpdateManyDataInput = {
  title?: Maybe<Scalars['String']>;
};

export type SupplierDeliveryHubUpdateManyInput = {
  connect?: Maybe<Array<SupplierDeliveryHubWhereUniqueInput>>;
  set?: Maybe<Array<SupplierDeliveryHubWhereUniqueInput>>;
  disconnect?: Maybe<Array<SupplierDeliveryHubWhereUniqueInput>>;
};

export type SupplierDeliveryHubUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
};

export type SupplierDeliveryHubUpdateManyWithWhereNestedInput = {
  where: SupplierDeliveryHubScalarWhereInput;
  data: SupplierDeliveryHubUpdateManyDataInput;
};

export type SupplierDeliveryHubUpdateWithWhereUniqueNestedInput = {
  where: SupplierDeliveryHubWhereUniqueInput;
  data: SupplierDeliveryHubUpdateDataInput;
};

export type SupplierDeliveryHubUpsertWithWhereUniqueNestedInput = {
  where: SupplierDeliveryHubWhereUniqueInput;
  update: SupplierDeliveryHubUpdateDataInput;
  create: SupplierDeliveryHubCreateInput;
};

export type SupplierDeliveryHubWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  supplier?: Maybe<SupplierWhereInput>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<SupplierDeliveryHubWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryHubWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryHubWhereInput>>;
};

export type SupplierDeliveryHubWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type SupplierDeliveryOption = {
  __typename?: 'SupplierDeliveryOption';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryDayConfigs?: Maybe<Array<SupplierDeliveryDayConfig>>;
  shippingPrice?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  deliveryWindowInDays: Scalars['Int'];
  criteria?: Maybe<Array<SupplierDeliveryConfig>>;
};


export type SupplierDeliveryOptionDeliveryDayConfigsArgs = {
  where?: Maybe<SupplierDeliveryDayConfigWhereInput>;
  orderBy?: Maybe<SupplierDeliveryDayConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SupplierDeliveryOptionCriteriaArgs = {
  where?: Maybe<SupplierDeliveryConfigWhereInput>;
  orderBy?: Maybe<SupplierDeliveryConfigOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryOptionConnection = {
  __typename?: 'SupplierDeliveryOptionConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<SupplierDeliveryOptionEdge>>;
  aggregate: AggregateSupplierDeliveryOption;
};

export type SupplierDeliveryOptionCreateInput = {
  deliveryDayConfigs?: Maybe<SupplierDeliveryDayConfigCreateManyInput>;
  shippingPrice?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  deliveryWindowInDays?: Maybe<Scalars['Int']>;
  criteria?: Maybe<SupplierDeliveryConfigCreateManyWithoutDeliveryOptionsInput>;
};

export type SupplierDeliveryOptionCreateManyWithoutCriteriaInput = {
  create?: Maybe<Array<SupplierDeliveryOptionCreateWithoutCriteriaInput>>;
  connect?: Maybe<Array<SupplierDeliveryOptionWhereUniqueInput>>;
};

export type SupplierDeliveryOptionCreateWithoutCriteriaInput = {
  deliveryDayConfigs?: Maybe<SupplierDeliveryDayConfigCreateManyInput>;
  shippingPrice?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  deliveryWindowInDays?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryOptionEdge = {
  __typename?: 'SupplierDeliveryOptionEdge';
  node: SupplierDeliveryOption;
  cursor: Scalars['String'];
};

export enum SupplierDeliveryOptionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ShippingPriceAsc = 'shippingPrice_ASC',
  ShippingPriceDesc = 'shippingPrice_DESC',
  FreeShippingThresholdAsc = 'freeShippingThreshold_ASC',
  FreeShippingThresholdDesc = 'freeShippingThreshold_DESC',
  DeliveryWindowInDaysAsc = 'deliveryWindowInDays_ASC',
  DeliveryWindowInDaysDesc = 'deliveryWindowInDays_DESC'
}

export type SupplierDeliveryOptionPreviousValues = {
  __typename?: 'SupplierDeliveryOptionPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  deliveryWindowInDays: Scalars['Int'];
};

export type SupplierDeliveryOptionScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingPrice_not?: Maybe<Scalars['Float']>;
  shippingPrice_in?: Maybe<Array<Scalars['Float']>>;
  shippingPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  shippingPrice_lt?: Maybe<Scalars['Float']>;
  shippingPrice_lte?: Maybe<Scalars['Float']>;
  shippingPrice_gt?: Maybe<Scalars['Float']>;
  shippingPrice_gte?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  freeShippingThreshold_not?: Maybe<Scalars['Float']>;
  freeShippingThreshold_in?: Maybe<Array<Scalars['Float']>>;
  freeShippingThreshold_not_in?: Maybe<Array<Scalars['Float']>>;
  freeShippingThreshold_lt?: Maybe<Scalars['Float']>;
  freeShippingThreshold_lte?: Maybe<Scalars['Float']>;
  freeShippingThreshold_gt?: Maybe<Scalars['Float']>;
  freeShippingThreshold_gte?: Maybe<Scalars['Float']>;
  deliveryWindowInDays?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_not?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_in?: Maybe<Array<Scalars['Int']>>;
  deliveryWindowInDays_not_in?: Maybe<Array<Scalars['Int']>>;
  deliveryWindowInDays_lt?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_lte?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_gt?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_gte?: Maybe<Scalars['Int']>;
  AND?: Maybe<Array<SupplierDeliveryOptionScalarWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryOptionScalarWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryOptionScalarWhereInput>>;
};

export type SupplierDeliveryOptionSubscriptionPayload = {
  __typename?: 'SupplierDeliveryOptionSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<SupplierDeliveryOption>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<SupplierDeliveryOptionPreviousValues>;
};

export type SupplierDeliveryOptionSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<SupplierDeliveryOptionWhereInput>;
  AND?: Maybe<Array<SupplierDeliveryOptionSubscriptionWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryOptionSubscriptionWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryOptionSubscriptionWhereInput>>;
};

export type SupplierDeliveryOptionUpdateInput = {
  deliveryDayConfigs?: Maybe<SupplierDeliveryDayConfigUpdateManyInput>;
  shippingPrice?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  deliveryWindowInDays?: Maybe<Scalars['Int']>;
  criteria?: Maybe<SupplierDeliveryConfigUpdateManyWithoutDeliveryOptionsInput>;
};

export type SupplierDeliveryOptionUpdateManyDataInput = {
  shippingPrice?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  deliveryWindowInDays?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryOptionUpdateManyMutationInput = {
  shippingPrice?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  deliveryWindowInDays?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryOptionUpdateManyWithWhereNestedInput = {
  where: SupplierDeliveryOptionScalarWhereInput;
  data: SupplierDeliveryOptionUpdateManyDataInput;
};

export type SupplierDeliveryOptionUpdateManyWithoutCriteriaInput = {
  create?: Maybe<Array<SupplierDeliveryOptionCreateWithoutCriteriaInput>>;
  delete?: Maybe<Array<SupplierDeliveryOptionWhereUniqueInput>>;
  connect?: Maybe<Array<SupplierDeliveryOptionWhereUniqueInput>>;
  set?: Maybe<Array<SupplierDeliveryOptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<SupplierDeliveryOptionWhereUniqueInput>>;
  update?: Maybe<Array<SupplierDeliveryOptionUpdateWithWhereUniqueWithoutCriteriaInput>>;
  upsert?: Maybe<Array<SupplierDeliveryOptionUpsertWithWhereUniqueWithoutCriteriaInput>>;
  deleteMany?: Maybe<Array<SupplierDeliveryOptionScalarWhereInput>>;
  updateMany?: Maybe<Array<SupplierDeliveryOptionUpdateManyWithWhereNestedInput>>;
};

export type SupplierDeliveryOptionUpdateWithWhereUniqueWithoutCriteriaInput = {
  where: SupplierDeliveryOptionWhereUniqueInput;
  data: SupplierDeliveryOptionUpdateWithoutCriteriaDataInput;
};

export type SupplierDeliveryOptionUpdateWithoutCriteriaDataInput = {
  deliveryDayConfigs?: Maybe<SupplierDeliveryDayConfigUpdateManyInput>;
  shippingPrice?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  deliveryWindowInDays?: Maybe<Scalars['Int']>;
};

export type SupplierDeliveryOptionUpsertWithWhereUniqueWithoutCriteriaInput = {
  where: SupplierDeliveryOptionWhereUniqueInput;
  update: SupplierDeliveryOptionUpdateWithoutCriteriaDataInput;
  create: SupplierDeliveryOptionCreateWithoutCriteriaInput;
};

export type SupplierDeliveryOptionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  deliveryDayConfigs_every?: Maybe<SupplierDeliveryDayConfigWhereInput>;
  deliveryDayConfigs_some?: Maybe<SupplierDeliveryDayConfigWhereInput>;
  deliveryDayConfigs_none?: Maybe<SupplierDeliveryDayConfigWhereInput>;
  shippingPrice?: Maybe<Scalars['Float']>;
  shippingPrice_not?: Maybe<Scalars['Float']>;
  shippingPrice_in?: Maybe<Array<Scalars['Float']>>;
  shippingPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  shippingPrice_lt?: Maybe<Scalars['Float']>;
  shippingPrice_lte?: Maybe<Scalars['Float']>;
  shippingPrice_gt?: Maybe<Scalars['Float']>;
  shippingPrice_gte?: Maybe<Scalars['Float']>;
  freeShippingThreshold?: Maybe<Scalars['Float']>;
  freeShippingThreshold_not?: Maybe<Scalars['Float']>;
  freeShippingThreshold_in?: Maybe<Array<Scalars['Float']>>;
  freeShippingThreshold_not_in?: Maybe<Array<Scalars['Float']>>;
  freeShippingThreshold_lt?: Maybe<Scalars['Float']>;
  freeShippingThreshold_lte?: Maybe<Scalars['Float']>;
  freeShippingThreshold_gt?: Maybe<Scalars['Float']>;
  freeShippingThreshold_gte?: Maybe<Scalars['Float']>;
  deliveryWindowInDays?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_not?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_in?: Maybe<Array<Scalars['Int']>>;
  deliveryWindowInDays_not_in?: Maybe<Array<Scalars['Int']>>;
  deliveryWindowInDays_lt?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_lte?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_gt?: Maybe<Scalars['Int']>;
  deliveryWindowInDays_gte?: Maybe<Scalars['Int']>;
  criteria_every?: Maybe<SupplierDeliveryConfigWhereInput>;
  criteria_some?: Maybe<SupplierDeliveryConfigWhereInput>;
  criteria_none?: Maybe<SupplierDeliveryConfigWhereInput>;
  AND?: Maybe<Array<SupplierDeliveryOptionWhereInput>>;
  OR?: Maybe<Array<SupplierDeliveryOptionWhereInput>>;
  NOT?: Maybe<Array<SupplierDeliveryOptionWhereInput>>;
};

export type SupplierDeliveryOptionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type SupplierEdge = {
  __typename?: 'SupplierEdge';
  node: Supplier;
  cursor: Scalars['String'];
};

export enum SupplierIntegrationType {
  Florisoft = 'Florisoft'
}

export enum SupplierOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type SupplierPreviousValues = {
  __typename?: 'SupplierPreviousValues';
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};

export type SupplierScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<SupplierScalarWhereInput>>;
  OR?: Maybe<Array<SupplierScalarWhereInput>>;
  NOT?: Maybe<Array<SupplierScalarWhereInput>>;
};

export type SupplierSetManyInput = {
  set?: Maybe<Array<SupplierWhereUniqueInput>>;
};

export type SupplierSubscriptionPayload = {
  __typename?: 'SupplierSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Supplier>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<SupplierPreviousValues>;
};

export type SupplierSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<SupplierWhereInput>;
  AND?: Maybe<Array<SupplierSubscriptionWhereInput>>;
  OR?: Maybe<Array<SupplierSubscriptionWhereInput>>;
  NOT?: Maybe<Array<SupplierSubscriptionWhereInput>>;
};

export type SupplierUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierUpdateManyInput = {
  create?: Maybe<Array<SupplierCreateInput>>;
  update?: Maybe<Array<SupplierUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<SupplierUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<SupplierWhereUniqueInput>>;
  connect?: Maybe<Array<SupplierWhereUniqueInput>>;
  set?: Maybe<Array<SupplierWhereUniqueInput>>;
  disconnect?: Maybe<Array<SupplierWhereUniqueInput>>;
  deleteMany?: Maybe<Array<SupplierScalarWhereInput>>;
  updateMany?: Maybe<Array<SupplierUpdateManyWithWhereNestedInput>>;
};

export type SupplierUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierUpdateManyWithWhereNestedInput = {
  where: SupplierScalarWhereInput;
  data: SupplierUpdateManyDataInput;
};

export type SupplierUpdateOneInput = {
  create?: Maybe<SupplierCreateInput>;
  update?: Maybe<SupplierUpdateDataInput>;
  upsert?: Maybe<SupplierUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<SupplierWhereUniqueInput>;
};

export type SupplierUpdateOneRequiredInput = {
  connect?: Maybe<SupplierWhereUniqueInput>;
};

export type SupplierUpdateWithWhereUniqueNestedInput = {
  where: SupplierWhereUniqueInput;
  data: SupplierUpdateDataInput;
};

export type SupplierUpsertNestedInput = {
  update: SupplierUpdateDataInput;
  create: SupplierCreateInput;
};

export type SupplierUpsertWithWhereUniqueNestedInput = {
  where: SupplierWhereUniqueInput;
  update: SupplierUpdateDataInput;
  create: SupplierCreateInput;
};

export type SupplierWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Scalars['String']>>;
  name_not_in?: Maybe<Array<Scalars['String']>>;
  name_lt?: Maybe<Scalars['String']>;
  name_lte?: Maybe<Scalars['String']>;
  name_gt?: Maybe<Scalars['String']>;
  name_gte?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  holidays_every?: Maybe<MerchantHolidayWhereInput>;
  holidays_some?: Maybe<MerchantHolidayWhereInput>;
  holidays_none?: Maybe<MerchantHolidayWhereInput>;
  wholesaleFeatures_every?: Maybe<WholesaleFeatureWhereInput>;
  wholesaleFeatures_some?: Maybe<WholesaleFeatureWhereInput>;
  wholesaleFeatures_none?: Maybe<WholesaleFeatureWhereInput>;
  AND?: Maybe<Array<SupplierWhereInput>>;
  OR?: Maybe<Array<SupplierWhereInput>>;
  NOT?: Maybe<Array<SupplierWhereInput>>;
};

export type SupplierWhereUniqueInput = {
  id: Scalars['ID'];
  slug?: Maybe<Scalars['String']>;
};

export type TaxEstimateResponse = {
  __typename?: 'TaxEstimateResponse';
  taxable_amount?: Maybe<Scalars['Float']>;
};

export type TaxEstimateWhereInput = {
  pickupAddress: AddressLookupInput;
  deliveryAddress: AddressLookupInput;
  order_items: Array<Maybe<OrderItemTaxLookupInput>>;
  currency: Scalars['String'];
  totalPrice: Scalars['Float'];
  delivery_cost: Scalars['Float'];
  discountAmount?: Maybe<Scalars['Float']>;
  free_shipping: Scalars['Boolean'];
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type TeamConnection = {
  __typename?: 'TeamConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TeamEdge>>;
  aggregate: AggregateTeam;
};

export type TeamCreateInput = {
  title?: Maybe<Scalars['String']>;
  owner?: Maybe<UserCreateOneInput>;
  merchant: MerchantCreateOneInput;
  createdBy?: Maybe<UserCreateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type TeamCreateOneInput = {
  create?: Maybe<TeamCreateInput>;
  connect?: Maybe<TeamWhereUniqueInput>;
};

export type TeamEdge = {
  __typename?: 'TeamEdge';
  node: Team;
  cursor: Scalars['String'];
};

export enum TeamOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type TeamPreviousValues = {
  __typename?: 'TeamPreviousValues';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type TeamSubscriptionPayload = {
  __typename?: 'TeamSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Team>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TeamPreviousValues>;
};

export type TeamSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TeamWhereInput>;
  AND?: Maybe<Array<TeamSubscriptionWhereInput>>;
  OR?: Maybe<Array<TeamSubscriptionWhereInput>>;
  NOT?: Maybe<Array<TeamSubscriptionWhereInput>>;
};

export type TeamUpdateDataInput = {
  title?: Maybe<Scalars['String']>;
  owner?: Maybe<UserUpdateOneInput>;
  merchant?: Maybe<MerchantUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type TeamUpdateInput = {
  title?: Maybe<Scalars['String']>;
  owner?: Maybe<UserUpdateOneInput>;
  merchant?: Maybe<MerchantUpdateOneRequiredInput>;
  createdBy?: Maybe<UserUpdateOneInput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type TeamUpdateManyMutationInput = {
  title?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type TeamUpdateOneInput = {
  create?: Maybe<TeamCreateInput>;
  update?: Maybe<TeamUpdateDataInput>;
  upsert?: Maybe<TeamUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TeamWhereUniqueInput>;
};

export type TeamUpsertNestedInput = {
  update: TeamUpdateDataInput;
  create: TeamCreateInput;
};

export type TeamWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  owner?: Maybe<UserWhereInput>;
  merchant?: Maybe<MerchantWhereInput>;
  createdBy?: Maybe<UserWhereInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<TeamWhereInput>>;
  OR?: Maybe<Array<TeamWhereInput>>;
  NOT?: Maybe<Array<TeamWhereInput>>;
};

export type TeamWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TokenResult = {
  __typename?: 'TokenResult';
  token?: Maybe<Scalars['String']>;
};

export type TradeSku = {
  __typename?: 'TradeSku';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: CatalogItemType;
  skuSignature: Scalars['String'];
  availability?: Maybe<Array<TradeSkuAvailability>>;
  flower?: Maybe<TradeSkuFlower>;
  plant?: Maybe<TradeSkuPlant>;
  decoration?: Maybe<TradeSkuDecoration>;
  sundry?: Maybe<TradeSkuSundry>;
  other?: Maybe<TradeSkuOther>;
};


export type TradeSkuAvailabilityArgs = {
  where?: Maybe<TradeSkuAvailabilityWhereInput>;
  orderBy?: Maybe<TradeSkuAvailabilityOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TradeSkuAvailability = {
  __typename?: 'TradeSkuAvailability';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  unitStock: Scalars['Int'];
  grower?: Maybe<Grower>;
  supplier?: Maybe<Supplier>;
  supplierProductId?: Maybe<Scalars['String']>;
  price?: Maybe<Array<TradeSkuPrice>>;
  priceHistory?: Maybe<Array<TradeSkuPrice>>;
  multiplesOf?: Maybe<Scalars['Int']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity?: Maybe<Scalars['Int']>;
  isLive: Scalars['Boolean'];
  media?: Maybe<Array<Maybe<TradeSkuMedia>>>;
};


export type TradeSkuAvailabilityPriceArgs = {
  where?: Maybe<TradeSkuPriceWhereInput>;
  orderBy?: Maybe<TradeSkuPriceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TradeSkuAvailabilityPriceHistoryArgs = {
  where?: Maybe<TradeSkuPriceWhereInput>;
  orderBy?: Maybe<TradeSkuPriceOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TradeSkuAvailabilityConnection = {
  __typename?: 'TradeSkuAvailabilityConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TradeSkuAvailabilityEdge>>;
  aggregate: AggregateTradeSkuAvailability;
};

export type TradeSkuAvailabilityCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  unitStock: Scalars['Int'];
  grower?: Maybe<SupplierCreateOneInput>;
  supplier?: Maybe<SupplierCreateOneInput>;
  supplierProductId?: Maybe<Scalars['String']>;
  price?: Maybe<TradeSkuPriceCreateManyInput>;
  priceHistory?: Maybe<TradeSkuPriceCreateManyInput>;
  multiplesOf?: Maybe<Scalars['Int']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity?: Maybe<Scalars['Int']>;
  isLive: Scalars['Boolean'];
};

export type TradeSkuAvailabilityCreateManyInput = {
  create?: Maybe<Array<TradeSkuAvailabilityCreateInput>>;
  connect?: Maybe<Array<TradeSkuAvailabilityWhereUniqueInput>>;
};

export type TradeSkuAvailabilityEdge = {
  __typename?: 'TradeSkuAvailabilityEdge';
  node: TradeSkuAvailability;
  cursor: Scalars['String'];
};

export enum TradeSkuAvailabilityOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  UnitStockAsc = 'unitStock_ASC',
  UnitStockDesc = 'unitStock_DESC',
  SupplierProductIdAsc = 'supplierProductId_ASC',
  SupplierProductIdDesc = 'supplierProductId_DESC',
  MultiplesOfAsc = 'multiplesOf_ASC',
  MultiplesOfDesc = 'multiplesOf_DESC',
  QuantityInPackageAsc = 'quantityInPackage_ASC',
  QuantityInPackageDesc = 'quantityInPackage_DESC',
  MaximumPurchaseQuantityAsc = 'maximumPurchaseQuantity_ASC',
  MaximumPurchaseQuantityDesc = 'maximumPurchaseQuantity_DESC',
  IsLiveAsc = 'isLive_ASC',
  IsLiveDesc = 'isLive_DESC'
}

export type TradeSkuAvailabilityPreviousValues = {
  __typename?: 'TradeSkuAvailabilityPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  unitStock: Scalars['Int'];
  supplierProductId?: Maybe<Scalars['String']>;
  multiplesOf?: Maybe<Scalars['Int']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity?: Maybe<Scalars['Int']>;
  isLive: Scalars['Boolean'];
};

export type TradeSkuAvailabilityScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  unitStock?: Maybe<Scalars['Int']>;
  unitStock_not?: Maybe<Scalars['Int']>;
  unitStock_in?: Maybe<Array<Scalars['Int']>>;
  unitStock_not_in?: Maybe<Array<Scalars['Int']>>;
  unitStock_lt?: Maybe<Scalars['Int']>;
  unitStock_lte?: Maybe<Scalars['Int']>;
  unitStock_gt?: Maybe<Scalars['Int']>;
  unitStock_gte?: Maybe<Scalars['Int']>;
  supplierProductId?: Maybe<Scalars['String']>;
  supplierProductId_not?: Maybe<Scalars['String']>;
  supplierProductId_in?: Maybe<Array<Scalars['String']>>;
  supplierProductId_not_in?: Maybe<Array<Scalars['String']>>;
  supplierProductId_lt?: Maybe<Scalars['String']>;
  supplierProductId_lte?: Maybe<Scalars['String']>;
  supplierProductId_gt?: Maybe<Scalars['String']>;
  supplierProductId_gte?: Maybe<Scalars['String']>;
  supplierProductId_contains?: Maybe<Scalars['String']>;
  supplierProductId_not_contains?: Maybe<Scalars['String']>;
  supplierProductId_starts_with?: Maybe<Scalars['String']>;
  supplierProductId_not_starts_with?: Maybe<Scalars['String']>;
  supplierProductId_ends_with?: Maybe<Scalars['String']>;
  supplierProductId_not_ends_with?: Maybe<Scalars['String']>;
  multiplesOf?: Maybe<Scalars['Int']>;
  multiplesOf_not?: Maybe<Scalars['Int']>;
  multiplesOf_in?: Maybe<Array<Scalars['Int']>>;
  multiplesOf_not_in?: Maybe<Array<Scalars['Int']>>;
  multiplesOf_lt?: Maybe<Scalars['Int']>;
  multiplesOf_lte?: Maybe<Scalars['Int']>;
  multiplesOf_gt?: Maybe<Scalars['Int']>;
  multiplesOf_gte?: Maybe<Scalars['Int']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  quantityInPackage_not?: Maybe<Scalars['Int']>;
  quantityInPackage_in?: Maybe<Array<Scalars['Int']>>;
  quantityInPackage_not_in?: Maybe<Array<Scalars['Int']>>;
  quantityInPackage_lt?: Maybe<Scalars['Int']>;
  quantityInPackage_lte?: Maybe<Scalars['Int']>;
  quantityInPackage_gt?: Maybe<Scalars['Int']>;
  quantityInPackage_gte?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_not?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_in?: Maybe<Array<Scalars['Int']>>;
  maximumPurchaseQuantity_not_in?: Maybe<Array<Scalars['Int']>>;
  maximumPurchaseQuantity_lt?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_lte?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_gt?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_gte?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isLive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<TradeSkuAvailabilityScalarWhereInput>>;
  OR?: Maybe<Array<TradeSkuAvailabilityScalarWhereInput>>;
  NOT?: Maybe<Array<TradeSkuAvailabilityScalarWhereInput>>;
};

export type TradeSkuAvailabilitySubscriptionPayload = {
  __typename?: 'TradeSkuAvailabilitySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TradeSkuAvailability>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TradeSkuAvailabilityPreviousValues>;
};

export type TradeSkuAvailabilitySubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TradeSkuAvailabilityWhereInput>;
  AND?: Maybe<Array<TradeSkuAvailabilitySubscriptionWhereInput>>;
  OR?: Maybe<Array<TradeSkuAvailabilitySubscriptionWhereInput>>;
  NOT?: Maybe<Array<TradeSkuAvailabilitySubscriptionWhereInput>>;
};

export type TradeSkuAvailabilityUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  unitStock?: Maybe<Scalars['Int']>;
  grower?: Maybe<SupplierUpdateOneInput>;
  supplier?: Maybe<SupplierUpdateOneInput>;
  supplierProductId?: Maybe<Scalars['String']>;
  price?: Maybe<TradeSkuPriceUpdateManyInput>;
  priceHistory?: Maybe<TradeSkuPriceUpdateManyInput>;
  multiplesOf?: Maybe<Scalars['Int']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
};

export type TradeSkuAvailabilityUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  unitStock?: Maybe<Scalars['Int']>;
  grower?: Maybe<SupplierUpdateOneInput>;
  supplier?: Maybe<SupplierUpdateOneInput>;
  supplierProductId?: Maybe<Scalars['String']>;
  price?: Maybe<TradeSkuPriceUpdateManyInput>;
  priceHistory?: Maybe<TradeSkuPriceUpdateManyInput>;
  multiplesOf?: Maybe<Scalars['Int']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
};

export type TradeSkuAvailabilityUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  unitStock?: Maybe<Scalars['Int']>;
  supplierProductId?: Maybe<Scalars['String']>;
  multiplesOf?: Maybe<Scalars['Int']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
};

export type TradeSkuAvailabilityUpdateManyInput = {
  create?: Maybe<Array<TradeSkuAvailabilityCreateInput>>;
  update?: Maybe<Array<TradeSkuAvailabilityUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<TradeSkuAvailabilityUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<TradeSkuAvailabilityWhereUniqueInput>>;
  connect?: Maybe<Array<TradeSkuAvailabilityWhereUniqueInput>>;
  set?: Maybe<Array<TradeSkuAvailabilityWhereUniqueInput>>;
  disconnect?: Maybe<Array<TradeSkuAvailabilityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TradeSkuAvailabilityScalarWhereInput>>;
  updateMany?: Maybe<Array<TradeSkuAvailabilityUpdateManyWithWhereNestedInput>>;
};

export type TradeSkuAvailabilityUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  unitStock?: Maybe<Scalars['Int']>;
  supplierProductId?: Maybe<Scalars['String']>;
  multiplesOf?: Maybe<Scalars['Int']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
};

export type TradeSkuAvailabilityUpdateManyWithWhereNestedInput = {
  where: TradeSkuAvailabilityScalarWhereInput;
  data: TradeSkuAvailabilityUpdateManyDataInput;
};

export type TradeSkuAvailabilityUpdateWithWhereUniqueNestedInput = {
  where: TradeSkuAvailabilityWhereUniqueInput;
  data: TradeSkuAvailabilityUpdateDataInput;
};

export type TradeSkuAvailabilityUpsertWithWhereUniqueNestedInput = {
  where: TradeSkuAvailabilityWhereUniqueInput;
  update: TradeSkuAvailabilityUpdateDataInput;
  create: TradeSkuAvailabilityCreateInput;
};

export type TradeSkuAvailabilityWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  unitStock?: Maybe<Scalars['Int']>;
  unitStock_not?: Maybe<Scalars['Int']>;
  unitStock_in?: Maybe<Array<Scalars['Int']>>;
  unitStock_not_in?: Maybe<Array<Scalars['Int']>>;
  unitStock_lt?: Maybe<Scalars['Int']>;
  unitStock_lte?: Maybe<Scalars['Int']>;
  unitStock_gt?: Maybe<Scalars['Int']>;
  unitStock_gte?: Maybe<Scalars['Int']>;
  grower?: Maybe<SupplierWhereInput>;
  supplier?: Maybe<SupplierWhereInput>;
  supplierProductId?: Maybe<Scalars['String']>;
  supplierProductId_not?: Maybe<Scalars['String']>;
  supplierProductId_in?: Maybe<Array<Scalars['String']>>;
  supplierProductId_not_in?: Maybe<Array<Scalars['String']>>;
  supplierProductId_lt?: Maybe<Scalars['String']>;
  supplierProductId_lte?: Maybe<Scalars['String']>;
  supplierProductId_gt?: Maybe<Scalars['String']>;
  supplierProductId_gte?: Maybe<Scalars['String']>;
  supplierProductId_contains?: Maybe<Scalars['String']>;
  supplierProductId_not_contains?: Maybe<Scalars['String']>;
  supplierProductId_starts_with?: Maybe<Scalars['String']>;
  supplierProductId_not_starts_with?: Maybe<Scalars['String']>;
  supplierProductId_ends_with?: Maybe<Scalars['String']>;
  supplierProductId_not_ends_with?: Maybe<Scalars['String']>;
  price_every?: Maybe<TradeSkuPriceWhereInput>;
  price_some?: Maybe<TradeSkuPriceWhereInput>;
  price_none?: Maybe<TradeSkuPriceWhereInput>;
  priceHistory_every?: Maybe<TradeSkuPriceWhereInput>;
  priceHistory_some?: Maybe<TradeSkuPriceWhereInput>;
  priceHistory_none?: Maybe<TradeSkuPriceWhereInput>;
  multiplesOf?: Maybe<Scalars['Int']>;
  multiplesOf_not?: Maybe<Scalars['Int']>;
  multiplesOf_in?: Maybe<Array<Scalars['Int']>>;
  multiplesOf_not_in?: Maybe<Array<Scalars['Int']>>;
  multiplesOf_lt?: Maybe<Scalars['Int']>;
  multiplesOf_lte?: Maybe<Scalars['Int']>;
  multiplesOf_gt?: Maybe<Scalars['Int']>;
  multiplesOf_gte?: Maybe<Scalars['Int']>;
  quantityInPackage?: Maybe<Scalars['Int']>;
  quantityInPackage_not?: Maybe<Scalars['Int']>;
  quantityInPackage_in?: Maybe<Array<Scalars['Int']>>;
  quantityInPackage_not_in?: Maybe<Array<Scalars['Int']>>;
  quantityInPackage_lt?: Maybe<Scalars['Int']>;
  quantityInPackage_lte?: Maybe<Scalars['Int']>;
  quantityInPackage_gt?: Maybe<Scalars['Int']>;
  quantityInPackage_gte?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_not?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_in?: Maybe<Array<Scalars['Int']>>;
  maximumPurchaseQuantity_not_in?: Maybe<Array<Scalars['Int']>>;
  maximumPurchaseQuantity_lt?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_lte?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_gt?: Maybe<Scalars['Int']>;
  maximumPurchaseQuantity_gte?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
  isLive_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<TradeSkuAvailabilityWhereInput>>;
  OR?: Maybe<Array<TradeSkuAvailabilityWhereInput>>;
  NOT?: Maybe<Array<TradeSkuAvailabilityWhereInput>>;
};

export type TradeSkuAvailabilityWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TradeSkuConnection = {
  __typename?: 'TradeSkuConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TradeSkuEdge>>;
  aggregate: AggregateTradeSku;
};

export type TradeSkuCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: CatalogItemType;
  skuSignature: Scalars['String'];
  availability?: Maybe<TradeSkuAvailabilityCreateManyInput>;
  flower?: Maybe<TradeSkuFlowerCreateOneInput>;
  plant?: Maybe<TradeSkuPlantCreateOneInput>;
  decoration?: Maybe<TradeSkuDecorationCreateOneInput>;
  sundry?: Maybe<TradeSkuSundryCreateOneInput>;
};

export type TradeSkuCreateManyInput = {
  create?: Maybe<Array<TradeSkuCreateInput>>;
  connect?: Maybe<Array<TradeSkuWhereUniqueInput>>;
};

export type TradeSkuDecoration = {
  __typename?: 'TradeSkuDecoration';
  id: Scalars['ID'];
  headNumber?: Maybe<Scalars['Int']>;
  incrementalQuantityValue?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuDecorationConnection = {
  __typename?: 'TradeSkuDecorationConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TradeSkuDecorationEdge>>;
  aggregate: AggregateTradeSkuDecoration;
};

export type TradeSkuDecorationCreateInput = {
  id?: Maybe<Scalars['ID']>;
  headNumber?: Maybe<Scalars['Int']>;
  incrementalQuantityValue?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuDecorationCreateOneInput = {
  create?: Maybe<TradeSkuDecorationCreateInput>;
  connect?: Maybe<TradeSkuDecorationWhereUniqueInput>;
};

export type TradeSkuDecorationEdge = {
  __typename?: 'TradeSkuDecorationEdge';
  node: TradeSkuDecoration;
  cursor: Scalars['String'];
};

export enum TradeSkuDecorationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HeadNumberAsc = 'headNumber_ASC',
  HeadNumberDesc = 'headNumber_DESC',
  IncrementalQuantityValueAsc = 'incrementalQuantityValue_ASC',
  IncrementalQuantityValueDesc = 'incrementalQuantityValue_DESC',
  MaturityAsc = 'maturity_ASC',
  MaturityDesc = 'maturity_DESC',
  MinimumStemLengthAsc = 'minimumStemLength_ASC',
  MinimumStemLengthDesc = 'minimumStemLength_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC'
}

export type TradeSkuDecorationPreviousValues = {
  __typename?: 'TradeSkuDecorationPreviousValues';
  id: Scalars['ID'];
  headNumber?: Maybe<Scalars['Int']>;
  incrementalQuantityValue?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuDecorationSubscriptionPayload = {
  __typename?: 'TradeSkuDecorationSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TradeSkuDecoration>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TradeSkuDecorationPreviousValues>;
};

export type TradeSkuDecorationSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TradeSkuDecorationWhereInput>;
  AND?: Maybe<Array<TradeSkuDecorationSubscriptionWhereInput>>;
  OR?: Maybe<Array<TradeSkuDecorationSubscriptionWhereInput>>;
  NOT?: Maybe<Array<TradeSkuDecorationSubscriptionWhereInput>>;
};

export type TradeSkuDecorationUpdateDataInput = {
  headNumber?: Maybe<Scalars['Int']>;
  incrementalQuantityValue?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuDecorationUpdateInput = {
  headNumber?: Maybe<Scalars['Int']>;
  incrementalQuantityValue?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuDecorationUpdateManyMutationInput = {
  headNumber?: Maybe<Scalars['Int']>;
  incrementalQuantityValue?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuDecorationUpdateOneInput = {
  create?: Maybe<TradeSkuDecorationCreateInput>;
  update?: Maybe<TradeSkuDecorationUpdateDataInput>;
  upsert?: Maybe<TradeSkuDecorationUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TradeSkuDecorationWhereUniqueInput>;
};

export type TradeSkuDecorationUpsertNestedInput = {
  update: TradeSkuDecorationUpdateDataInput;
  create: TradeSkuDecorationCreateInput;
};

export type TradeSkuDecorationWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  headNumber?: Maybe<Scalars['Int']>;
  headNumber_not?: Maybe<Scalars['Int']>;
  headNumber_in?: Maybe<Array<Scalars['Int']>>;
  headNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  headNumber_lt?: Maybe<Scalars['Int']>;
  headNumber_lte?: Maybe<Scalars['Int']>;
  headNumber_gt?: Maybe<Scalars['Int']>;
  headNumber_gte?: Maybe<Scalars['Int']>;
  incrementalQuantityValue?: Maybe<Scalars['Int']>;
  incrementalQuantityValue_not?: Maybe<Scalars['Int']>;
  incrementalQuantityValue_in?: Maybe<Array<Scalars['Int']>>;
  incrementalQuantityValue_not_in?: Maybe<Array<Scalars['Int']>>;
  incrementalQuantityValue_lt?: Maybe<Scalars['Int']>;
  incrementalQuantityValue_lte?: Maybe<Scalars['Int']>;
  incrementalQuantityValue_gt?: Maybe<Scalars['Int']>;
  incrementalQuantityValue_gte?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  maturity_not?: Maybe<Scalars['String']>;
  maturity_in?: Maybe<Array<Scalars['String']>>;
  maturity_not_in?: Maybe<Array<Scalars['String']>>;
  maturity_lt?: Maybe<Scalars['String']>;
  maturity_lte?: Maybe<Scalars['String']>;
  maturity_gt?: Maybe<Scalars['String']>;
  maturity_gte?: Maybe<Scalars['String']>;
  maturity_contains?: Maybe<Scalars['String']>;
  maturity_not_contains?: Maybe<Scalars['String']>;
  maturity_starts_with?: Maybe<Scalars['String']>;
  maturity_not_starts_with?: Maybe<Scalars['String']>;
  maturity_ends_with?: Maybe<Scalars['String']>;
  maturity_not_ends_with?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  minimumStemLength_not?: Maybe<Scalars['Int']>;
  minimumStemLength_in?: Maybe<Array<Scalars['Int']>>;
  minimumStemLength_not_in?: Maybe<Array<Scalars['Int']>>;
  minimumStemLength_lt?: Maybe<Scalars['Int']>;
  minimumStemLength_lte?: Maybe<Scalars['Int']>;
  minimumStemLength_gt?: Maybe<Scalars['Int']>;
  minimumStemLength_gte?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['String']>;
  weight_not?: Maybe<Scalars['String']>;
  weight_in?: Maybe<Array<Scalars['String']>>;
  weight_not_in?: Maybe<Array<Scalars['String']>>;
  weight_lt?: Maybe<Scalars['String']>;
  weight_lte?: Maybe<Scalars['String']>;
  weight_gt?: Maybe<Scalars['String']>;
  weight_gte?: Maybe<Scalars['String']>;
  weight_contains?: Maybe<Scalars['String']>;
  weight_not_contains?: Maybe<Scalars['String']>;
  weight_starts_with?: Maybe<Scalars['String']>;
  weight_not_starts_with?: Maybe<Scalars['String']>;
  weight_ends_with?: Maybe<Scalars['String']>;
  weight_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TradeSkuDecorationWhereInput>>;
  OR?: Maybe<Array<TradeSkuDecorationWhereInput>>;
  NOT?: Maybe<Array<TradeSkuDecorationWhereInput>>;
};

export type TradeSkuDecorationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TradeSkuEdge = {
  __typename?: 'TradeSkuEdge';
  node: TradeSku;
  cursor: Scalars['String'];
};

export type TradeSkuFlower = {
  __typename?: 'TradeSkuFlower';
  id: Scalars['ID'];
  headNumber?: Maybe<Scalars['Int']>;
  headSize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  potSize?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuFlowerConnection = {
  __typename?: 'TradeSkuFlowerConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TradeSkuFlowerEdge>>;
  aggregate: AggregateTradeSkuFlower;
};

export type TradeSkuFlowerCreateInput = {
  id?: Maybe<Scalars['ID']>;
  headNumber?: Maybe<Scalars['Int']>;
  headSize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  potSize?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuFlowerCreateOneInput = {
  create?: Maybe<TradeSkuFlowerCreateInput>;
  connect?: Maybe<TradeSkuFlowerWhereUniqueInput>;
};

export type TradeSkuFlowerEdge = {
  __typename?: 'TradeSkuFlowerEdge';
  node: TradeSkuFlower;
  cursor: Scalars['String'];
};

export enum TradeSkuFlowerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HeadNumberAsc = 'headNumber_ASC',
  HeadNumberDesc = 'headNumber_DESC',
  HeadSizeAsc = 'headSize_ASC',
  HeadSizeDesc = 'headSize_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  MaturityAsc = 'maturity_ASC',
  MaturityDesc = 'maturity_DESC',
  MinimumStemLengthAsc = 'minimumStemLength_ASC',
  MinimumStemLengthDesc = 'minimumStemLength_DESC',
  PotSizeAsc = 'potSize_ASC',
  PotSizeDesc = 'potSize_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC'
}

export type TradeSkuFlowerPreviousValues = {
  __typename?: 'TradeSkuFlowerPreviousValues';
  id: Scalars['ID'];
  headNumber?: Maybe<Scalars['Int']>;
  headSize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  potSize?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuFlowerSubscriptionPayload = {
  __typename?: 'TradeSkuFlowerSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TradeSkuFlower>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TradeSkuFlowerPreviousValues>;
};

export type TradeSkuFlowerSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TradeSkuFlowerWhereInput>;
  AND?: Maybe<Array<TradeSkuFlowerSubscriptionWhereInput>>;
  OR?: Maybe<Array<TradeSkuFlowerSubscriptionWhereInput>>;
  NOT?: Maybe<Array<TradeSkuFlowerSubscriptionWhereInput>>;
};

export type TradeSkuFlowerUpdateDataInput = {
  headNumber?: Maybe<Scalars['Int']>;
  headSize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  potSize?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuFlowerUpdateInput = {
  headNumber?: Maybe<Scalars['Int']>;
  headSize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  potSize?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuFlowerUpdateManyMutationInput = {
  headNumber?: Maybe<Scalars['Int']>;
  headSize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  potSize?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuFlowerUpdateOneInput = {
  create?: Maybe<TradeSkuFlowerCreateInput>;
  update?: Maybe<TradeSkuFlowerUpdateDataInput>;
  upsert?: Maybe<TradeSkuFlowerUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TradeSkuFlowerWhereUniqueInput>;
};

export type TradeSkuFlowerUpsertNestedInput = {
  update: TradeSkuFlowerUpdateDataInput;
  create: TradeSkuFlowerCreateInput;
};

export type TradeSkuFlowerWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  headNumber?: Maybe<Scalars['Int']>;
  headNumber_not?: Maybe<Scalars['Int']>;
  headNumber_in?: Maybe<Array<Scalars['Int']>>;
  headNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  headNumber_lt?: Maybe<Scalars['Int']>;
  headNumber_lte?: Maybe<Scalars['Int']>;
  headNumber_gt?: Maybe<Scalars['Int']>;
  headNumber_gte?: Maybe<Scalars['Int']>;
  headSize?: Maybe<Scalars['Int']>;
  headSize_not?: Maybe<Scalars['Int']>;
  headSize_in?: Maybe<Array<Scalars['Int']>>;
  headSize_not_in?: Maybe<Array<Scalars['Int']>>;
  headSize_lt?: Maybe<Scalars['Int']>;
  headSize_lte?: Maybe<Scalars['Int']>;
  headSize_gt?: Maybe<Scalars['Int']>;
  headSize_gte?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['String']>;
  height_not?: Maybe<Scalars['String']>;
  height_in?: Maybe<Array<Scalars['String']>>;
  height_not_in?: Maybe<Array<Scalars['String']>>;
  height_lt?: Maybe<Scalars['String']>;
  height_lte?: Maybe<Scalars['String']>;
  height_gt?: Maybe<Scalars['String']>;
  height_gte?: Maybe<Scalars['String']>;
  height_contains?: Maybe<Scalars['String']>;
  height_not_contains?: Maybe<Scalars['String']>;
  height_starts_with?: Maybe<Scalars['String']>;
  height_not_starts_with?: Maybe<Scalars['String']>;
  height_ends_with?: Maybe<Scalars['String']>;
  height_not_ends_with?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  maturity_not?: Maybe<Scalars['String']>;
  maturity_in?: Maybe<Array<Scalars['String']>>;
  maturity_not_in?: Maybe<Array<Scalars['String']>>;
  maturity_lt?: Maybe<Scalars['String']>;
  maturity_lte?: Maybe<Scalars['String']>;
  maturity_gt?: Maybe<Scalars['String']>;
  maturity_gte?: Maybe<Scalars['String']>;
  maturity_contains?: Maybe<Scalars['String']>;
  maturity_not_contains?: Maybe<Scalars['String']>;
  maturity_starts_with?: Maybe<Scalars['String']>;
  maturity_not_starts_with?: Maybe<Scalars['String']>;
  maturity_ends_with?: Maybe<Scalars['String']>;
  maturity_not_ends_with?: Maybe<Scalars['String']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  minimumStemLength_not?: Maybe<Scalars['Int']>;
  minimumStemLength_in?: Maybe<Array<Scalars['Int']>>;
  minimumStemLength_not_in?: Maybe<Array<Scalars['Int']>>;
  minimumStemLength_lt?: Maybe<Scalars['Int']>;
  minimumStemLength_lte?: Maybe<Scalars['Int']>;
  minimumStemLength_gt?: Maybe<Scalars['Int']>;
  minimumStemLength_gte?: Maybe<Scalars['Int']>;
  potSize?: Maybe<Scalars['String']>;
  potSize_not?: Maybe<Scalars['String']>;
  potSize_in?: Maybe<Array<Scalars['String']>>;
  potSize_not_in?: Maybe<Array<Scalars['String']>>;
  potSize_lt?: Maybe<Scalars['String']>;
  potSize_lte?: Maybe<Scalars['String']>;
  potSize_gt?: Maybe<Scalars['String']>;
  potSize_gte?: Maybe<Scalars['String']>;
  potSize_contains?: Maybe<Scalars['String']>;
  potSize_not_contains?: Maybe<Scalars['String']>;
  potSize_starts_with?: Maybe<Scalars['String']>;
  potSize_not_starts_with?: Maybe<Scalars['String']>;
  potSize_ends_with?: Maybe<Scalars['String']>;
  potSize_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  weight_not?: Maybe<Scalars['String']>;
  weight_in?: Maybe<Array<Scalars['String']>>;
  weight_not_in?: Maybe<Array<Scalars['String']>>;
  weight_lt?: Maybe<Scalars['String']>;
  weight_lte?: Maybe<Scalars['String']>;
  weight_gt?: Maybe<Scalars['String']>;
  weight_gte?: Maybe<Scalars['String']>;
  weight_contains?: Maybe<Scalars['String']>;
  weight_not_contains?: Maybe<Scalars['String']>;
  weight_starts_with?: Maybe<Scalars['String']>;
  weight_not_starts_with?: Maybe<Scalars['String']>;
  weight_ends_with?: Maybe<Scalars['String']>;
  weight_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TradeSkuFlowerWhereInput>>;
  OR?: Maybe<Array<TradeSkuFlowerWhereInput>>;
  NOT?: Maybe<Array<TradeSkuFlowerWhereInput>>;
};

export type TradeSkuFlowerWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TradeSkuMedia = {
  __typename?: 'TradeSkuMedia';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  src?: Maybe<Scalars['String']>;
};

export enum TradeSkuOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SkuSignatureAsc = 'skuSignature_ASC',
  SkuSignatureDesc = 'skuSignature_DESC'
}

export type TradeSkuOther = {
  __typename?: 'TradeSkuOther';
  id: Scalars['ID'];
  headNumber?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
  potFlowerCount?: Maybe<Scalars['Int']>;
  mpsCert?: Maybe<Scalars['String']>;
  potMaterial?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['String']>;
};

export type TradeSkuOtherConnection = {
  __typename?: 'TradeSkuOtherConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TradeSkuOtherEdge>>;
  aggregate: AggregateTradeSkuOther;
};

export type TradeSkuOtherEdge = {
  __typename?: 'TradeSkuOtherEdge';
  node: TradeSkuOther;
  cursor: Scalars['String'];
};

export enum TradeSkuOtherOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HeadNumberAsc = 'headNumber_ASC',
  HeadNumberDesc = 'headNumber_DESC',
  MinimumStemLengthAsc = 'minimumStemLength_ASC',
  MinimumStemLengthDesc = 'minimumStemLength_DESC',
  MaximumHeadSizeAsc = 'maximumHeadSize_ASC',
  MaximumHeadSizeDesc = 'maximumHeadSize_DESC',
  StemLengthUnitAsc = 'stemLengthUnit_ASC',
  StemLengthUnitDesc = 'stemLengthUnit_DESC',
  HeadSizeUnitAsc = 'headSizeUnit_ASC',
  HeadSizeUnitDesc = 'headSizeUnit_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  MaturityAsc = 'maturity_ASC',
  MaturityDesc = 'maturity_DESC',
  DiameterAsc = 'diameter_ASC',
  DiameterDesc = 'diameter_DESC',
  ThicknessAsc = 'thickness_ASC',
  ThicknessDesc = 'thickness_DESC',
  PotSizeAsc = 'potSize_ASC',
  PotSizeDesc = 'potSize_DESC',
  PotFlowerCountAsc = 'potFlowerCount_ASC',
  PotFlowerCountDesc = 'potFlowerCount_DESC',
  MpsCertAsc = 'mpsCert_ASC',
  MpsCertDesc = 'mpsCert_DESC',
  PotMaterialAsc = 'potMaterial_ASC',
  PotMaterialDesc = 'potMaterial_DESC',
  QualityAsc = 'quality_ASC',
  QualityDesc = 'quality_DESC'
}

export type TradeSkuOtherPreviousValues = {
  __typename?: 'TradeSkuOtherPreviousValues';
  id: Scalars['ID'];
  headNumber?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
  potFlowerCount?: Maybe<Scalars['Int']>;
  mpsCert?: Maybe<Scalars['String']>;
  potMaterial?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['String']>;
};

export type TradeSkuOtherWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  headNumber?: Maybe<Scalars['Int']>;
  headNumber_not?: Maybe<Scalars['Int']>;
  headNumber_in?: Maybe<Array<Scalars['Int']>>;
  headNumber_not_in?: Maybe<Array<Scalars['Int']>>;
  headNumber_lt?: Maybe<Scalars['Int']>;
  headNumber_lte?: Maybe<Scalars['Int']>;
  headNumber_gt?: Maybe<Scalars['Int']>;
  headNumber_gte?: Maybe<Scalars['Int']>;
  minimumStemLength?: Maybe<Scalars['Int']>;
  minimumStemLength_not?: Maybe<Scalars['Int']>;
  minimumStemLength_in?: Maybe<Array<Scalars['Int']>>;
  minimumStemLength_not_in?: Maybe<Array<Scalars['Int']>>;
  minimumStemLength_lt?: Maybe<Scalars['Int']>;
  minimumStemLength_lte?: Maybe<Scalars['Int']>;
  minimumStemLength_gt?: Maybe<Scalars['Int']>;
  minimumStemLength_gte?: Maybe<Scalars['Int']>;
  maximumHeadSize?: Maybe<Scalars['Int']>;
  maximumHeadSize_not?: Maybe<Scalars['Int']>;
  maximumHeadSize_in?: Maybe<Array<Scalars['Int']>>;
  maximumHeadSize_not_in?: Maybe<Array<Scalars['Int']>>;
  maximumHeadSize_lt?: Maybe<Scalars['Int']>;
  maximumHeadSize_lte?: Maybe<Scalars['Int']>;
  maximumHeadSize_gt?: Maybe<Scalars['Int']>;
  maximumHeadSize_gte?: Maybe<Scalars['Int']>;
  stemLengthUnit?: Maybe<Scalars['String']>;
  stemLengthUnit_not?: Maybe<Scalars['String']>;
  stemLengthUnit_in?: Maybe<Array<Scalars['String']>>;
  stemLengthUnit_not_in?: Maybe<Array<Scalars['String']>>;
  stemLengthUnit_lt?: Maybe<Scalars['String']>;
  stemLengthUnit_lte?: Maybe<Scalars['String']>;
  stemLengthUnit_gt?: Maybe<Scalars['String']>;
  stemLengthUnit_gte?: Maybe<Scalars['String']>;
  stemLengthUnit_contains?: Maybe<Scalars['String']>;
  stemLengthUnit_not_contains?: Maybe<Scalars['String']>;
  stemLengthUnit_starts_with?: Maybe<Scalars['String']>;
  stemLengthUnit_not_starts_with?: Maybe<Scalars['String']>;
  stemLengthUnit_ends_with?: Maybe<Scalars['String']>;
  stemLengthUnit_not_ends_with?: Maybe<Scalars['String']>;
  headSizeUnit?: Maybe<Scalars['String']>;
  headSizeUnit_not?: Maybe<Scalars['String']>;
  headSizeUnit_in?: Maybe<Array<Scalars['String']>>;
  headSizeUnit_not_in?: Maybe<Array<Scalars['String']>>;
  headSizeUnit_lt?: Maybe<Scalars['String']>;
  headSizeUnit_lte?: Maybe<Scalars['String']>;
  headSizeUnit_gt?: Maybe<Scalars['String']>;
  headSizeUnit_gte?: Maybe<Scalars['String']>;
  headSizeUnit_contains?: Maybe<Scalars['String']>;
  headSizeUnit_not_contains?: Maybe<Scalars['String']>;
  headSizeUnit_starts_with?: Maybe<Scalars['String']>;
  headSizeUnit_not_starts_with?: Maybe<Scalars['String']>;
  headSizeUnit_ends_with?: Maybe<Scalars['String']>;
  headSizeUnit_not_ends_with?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  height_not?: Maybe<Scalars['String']>;
  height_in?: Maybe<Array<Scalars['String']>>;
  height_not_in?: Maybe<Array<Scalars['String']>>;
  height_lt?: Maybe<Scalars['String']>;
  height_lte?: Maybe<Scalars['String']>;
  height_gt?: Maybe<Scalars['String']>;
  height_gte?: Maybe<Scalars['String']>;
  height_contains?: Maybe<Scalars['String']>;
  height_not_contains?: Maybe<Scalars['String']>;
  height_starts_with?: Maybe<Scalars['String']>;
  height_not_starts_with?: Maybe<Scalars['String']>;
  height_ends_with?: Maybe<Scalars['String']>;
  height_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  weight_not?: Maybe<Scalars['String']>;
  weight_in?: Maybe<Array<Scalars['String']>>;
  weight_not_in?: Maybe<Array<Scalars['String']>>;
  weight_lt?: Maybe<Scalars['String']>;
  weight_lte?: Maybe<Scalars['String']>;
  weight_gt?: Maybe<Scalars['String']>;
  weight_gte?: Maybe<Scalars['String']>;
  weight_contains?: Maybe<Scalars['String']>;
  weight_not_contains?: Maybe<Scalars['String']>;
  weight_starts_with?: Maybe<Scalars['String']>;
  weight_not_starts_with?: Maybe<Scalars['String']>;
  weight_ends_with?: Maybe<Scalars['String']>;
  weight_not_ends_with?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  maturity_not?: Maybe<Scalars['String']>;
  maturity_in?: Maybe<Array<Scalars['String']>>;
  maturity_not_in?: Maybe<Array<Scalars['String']>>;
  maturity_lt?: Maybe<Scalars['String']>;
  maturity_lte?: Maybe<Scalars['String']>;
  maturity_gt?: Maybe<Scalars['String']>;
  maturity_gte?: Maybe<Scalars['String']>;
  maturity_contains?: Maybe<Scalars['String']>;
  maturity_not_contains?: Maybe<Scalars['String']>;
  maturity_starts_with?: Maybe<Scalars['String']>;
  maturity_not_starts_with?: Maybe<Scalars['String']>;
  maturity_ends_with?: Maybe<Scalars['String']>;
  maturity_not_ends_with?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  diameter_not?: Maybe<Scalars['String']>;
  diameter_in?: Maybe<Array<Scalars['String']>>;
  diameter_not_in?: Maybe<Array<Scalars['String']>>;
  diameter_lt?: Maybe<Scalars['String']>;
  diameter_lte?: Maybe<Scalars['String']>;
  diameter_gt?: Maybe<Scalars['String']>;
  diameter_gte?: Maybe<Scalars['String']>;
  diameter_contains?: Maybe<Scalars['String']>;
  diameter_not_contains?: Maybe<Scalars['String']>;
  diameter_starts_with?: Maybe<Scalars['String']>;
  diameter_not_starts_with?: Maybe<Scalars['String']>;
  diameter_ends_with?: Maybe<Scalars['String']>;
  diameter_not_ends_with?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  thickness_not?: Maybe<Scalars['String']>;
  thickness_in?: Maybe<Array<Scalars['String']>>;
  thickness_not_in?: Maybe<Array<Scalars['String']>>;
  thickness_lt?: Maybe<Scalars['String']>;
  thickness_lte?: Maybe<Scalars['String']>;
  thickness_gt?: Maybe<Scalars['String']>;
  thickness_gte?: Maybe<Scalars['String']>;
  thickness_contains?: Maybe<Scalars['String']>;
  thickness_not_contains?: Maybe<Scalars['String']>;
  thickness_starts_with?: Maybe<Scalars['String']>;
  thickness_not_starts_with?: Maybe<Scalars['String']>;
  thickness_ends_with?: Maybe<Scalars['String']>;
  thickness_not_ends_with?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
  potSize_not?: Maybe<Scalars['String']>;
  potSize_in?: Maybe<Array<Scalars['String']>>;
  potSize_not_in?: Maybe<Array<Scalars['String']>>;
  potSize_lt?: Maybe<Scalars['String']>;
  potSize_lte?: Maybe<Scalars['String']>;
  potSize_gt?: Maybe<Scalars['String']>;
  potSize_gte?: Maybe<Scalars['String']>;
  potSize_contains?: Maybe<Scalars['String']>;
  potSize_not_contains?: Maybe<Scalars['String']>;
  potSize_starts_with?: Maybe<Scalars['String']>;
  potSize_not_starts_with?: Maybe<Scalars['String']>;
  potSize_ends_with?: Maybe<Scalars['String']>;
  potSize_not_ends_with?: Maybe<Scalars['String']>;
  potFlowerCount?: Maybe<Scalars['Int']>;
  potFlowerCount_not?: Maybe<Scalars['Int']>;
  potFlowerCount_in?: Maybe<Array<Scalars['Int']>>;
  potFlowerCount_not_in?: Maybe<Array<Scalars['Int']>>;
  potFlowerCount_lt?: Maybe<Scalars['Int']>;
  potFlowerCount_lte?: Maybe<Scalars['Int']>;
  potFlowerCount_gt?: Maybe<Scalars['Int']>;
  potFlowerCount_gte?: Maybe<Scalars['Int']>;
  mpsCert?: Maybe<Scalars['String']>;
  mpsCert_not?: Maybe<Scalars['String']>;
  mpsCert_in?: Maybe<Array<Scalars['String']>>;
  mpsCert_not_in?: Maybe<Array<Scalars['String']>>;
  mpsCert_lt?: Maybe<Scalars['String']>;
  mpsCert_lte?: Maybe<Scalars['String']>;
  mpsCert_gt?: Maybe<Scalars['String']>;
  mpsCert_gte?: Maybe<Scalars['String']>;
  mpsCert_contains?: Maybe<Scalars['String']>;
  mpsCert_not_contains?: Maybe<Scalars['String']>;
  mpsCert_starts_with?: Maybe<Scalars['String']>;
  mpsCert_not_starts_with?: Maybe<Scalars['String']>;
  mpsCert_ends_with?: Maybe<Scalars['String']>;
  mpsCert_not_ends_with?: Maybe<Scalars['String']>;
  potMaterial?: Maybe<Scalars['String']>;
  potMaterial_not?: Maybe<Scalars['String']>;
  potMaterial_in?: Maybe<Array<Scalars['String']>>;
  potMaterial_not_in?: Maybe<Array<Scalars['String']>>;
  potMaterial_lt?: Maybe<Scalars['String']>;
  potMaterial_lte?: Maybe<Scalars['String']>;
  potMaterial_gt?: Maybe<Scalars['String']>;
  potMaterial_gte?: Maybe<Scalars['String']>;
  potMaterial_contains?: Maybe<Scalars['String']>;
  potMaterial_not_contains?: Maybe<Scalars['String']>;
  potMaterial_starts_with?: Maybe<Scalars['String']>;
  potMaterial_not_starts_with?: Maybe<Scalars['String']>;
  potMaterial_ends_with?: Maybe<Scalars['String']>;
  potMaterial_not_ends_with?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['String']>;
  quality_not?: Maybe<Scalars['String']>;
  quality_in?: Maybe<Array<Scalars['String']>>;
  quality_not_in?: Maybe<Array<Scalars['String']>>;
  quality_lt?: Maybe<Scalars['String']>;
  quality_lte?: Maybe<Scalars['String']>;
  quality_gt?: Maybe<Scalars['String']>;
  quality_gte?: Maybe<Scalars['String']>;
  quality_contains?: Maybe<Scalars['String']>;
  quality_not_contains?: Maybe<Scalars['String']>;
  quality_starts_with?: Maybe<Scalars['String']>;
  quality_not_starts_with?: Maybe<Scalars['String']>;
  quality_ends_with?: Maybe<Scalars['String']>;
  quality_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TradeSkuOtherWhereInput>>;
  OR?: Maybe<Array<TradeSkuOtherWhereInput>>;
  NOT?: Maybe<Array<TradeSkuOtherWhereInput>>;
};

export type TradeSkuOtherWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TradeSkuPlant = {
  __typename?: 'TradeSkuPlant';
  id: Scalars['ID'];
  height?: Maybe<Scalars['String']>;
  headSize?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type TradeSkuPlantConnection = {
  __typename?: 'TradeSkuPlantConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TradeSkuPlantEdge>>;
  aggregate: AggregateTradeSkuPlant;
};

export type TradeSkuPlantCreateInput = {
  id?: Maybe<Scalars['ID']>;
  height?: Maybe<Scalars['String']>;
  headSize?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type TradeSkuPlantCreateOneInput = {
  create?: Maybe<TradeSkuPlantCreateInput>;
  connect?: Maybe<TradeSkuPlantWhereUniqueInput>;
};

export type TradeSkuPlantEdge = {
  __typename?: 'TradeSkuPlantEdge';
  node: TradeSkuPlant;
  cursor: Scalars['String'];
};

export enum TradeSkuPlantOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  HeadSizeAsc = 'headSize_ASC',
  HeadSizeDesc = 'headSize_DESC',
  MaturityAsc = 'maturity_ASC',
  MaturityDesc = 'maturity_DESC',
  DiameterAsc = 'diameter_ASC',
  DiameterDesc = 'diameter_DESC',
  ThicknessAsc = 'thickness_ASC',
  ThicknessDesc = 'thickness_DESC',
  PotSizeAsc = 'potSize_ASC',
  PotSizeDesc = 'potSize_DESC'
}

export type TradeSkuPlantPreviousValues = {
  __typename?: 'TradeSkuPlantPreviousValues';
  id: Scalars['ID'];
  height?: Maybe<Scalars['String']>;
  headSize?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type TradeSkuPlantSubscriptionPayload = {
  __typename?: 'TradeSkuPlantSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TradeSkuPlant>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TradeSkuPlantPreviousValues>;
};

export type TradeSkuPlantSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TradeSkuPlantWhereInput>;
  AND?: Maybe<Array<TradeSkuPlantSubscriptionWhereInput>>;
  OR?: Maybe<Array<TradeSkuPlantSubscriptionWhereInput>>;
  NOT?: Maybe<Array<TradeSkuPlantSubscriptionWhereInput>>;
};

export type TradeSkuPlantUpdateDataInput = {
  height?: Maybe<Scalars['String']>;
  headSize?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type TradeSkuPlantUpdateInput = {
  height?: Maybe<Scalars['String']>;
  headSize?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type TradeSkuPlantUpdateManyMutationInput = {
  height?: Maybe<Scalars['String']>;
  headSize?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
};

export type TradeSkuPlantUpdateOneInput = {
  create?: Maybe<TradeSkuPlantCreateInput>;
  update?: Maybe<TradeSkuPlantUpdateDataInput>;
  upsert?: Maybe<TradeSkuPlantUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TradeSkuPlantWhereUniqueInput>;
};

export type TradeSkuPlantUpsertNestedInput = {
  update: TradeSkuPlantUpdateDataInput;
  create: TradeSkuPlantCreateInput;
};

export type TradeSkuPlantWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  height?: Maybe<Scalars['String']>;
  height_not?: Maybe<Scalars['String']>;
  height_in?: Maybe<Array<Scalars['String']>>;
  height_not_in?: Maybe<Array<Scalars['String']>>;
  height_lt?: Maybe<Scalars['String']>;
  height_lte?: Maybe<Scalars['String']>;
  height_gt?: Maybe<Scalars['String']>;
  height_gte?: Maybe<Scalars['String']>;
  height_contains?: Maybe<Scalars['String']>;
  height_not_contains?: Maybe<Scalars['String']>;
  height_starts_with?: Maybe<Scalars['String']>;
  height_not_starts_with?: Maybe<Scalars['String']>;
  height_ends_with?: Maybe<Scalars['String']>;
  height_not_ends_with?: Maybe<Scalars['String']>;
  headSize?: Maybe<Scalars['Int']>;
  headSize_not?: Maybe<Scalars['Int']>;
  headSize_in?: Maybe<Array<Scalars['Int']>>;
  headSize_not_in?: Maybe<Array<Scalars['Int']>>;
  headSize_lt?: Maybe<Scalars['Int']>;
  headSize_lte?: Maybe<Scalars['Int']>;
  headSize_gt?: Maybe<Scalars['Int']>;
  headSize_gte?: Maybe<Scalars['Int']>;
  maturity?: Maybe<Scalars['String']>;
  maturity_not?: Maybe<Scalars['String']>;
  maturity_in?: Maybe<Array<Scalars['String']>>;
  maturity_not_in?: Maybe<Array<Scalars['String']>>;
  maturity_lt?: Maybe<Scalars['String']>;
  maturity_lte?: Maybe<Scalars['String']>;
  maturity_gt?: Maybe<Scalars['String']>;
  maturity_gte?: Maybe<Scalars['String']>;
  maturity_contains?: Maybe<Scalars['String']>;
  maturity_not_contains?: Maybe<Scalars['String']>;
  maturity_starts_with?: Maybe<Scalars['String']>;
  maturity_not_starts_with?: Maybe<Scalars['String']>;
  maturity_ends_with?: Maybe<Scalars['String']>;
  maturity_not_ends_with?: Maybe<Scalars['String']>;
  diameter?: Maybe<Scalars['String']>;
  diameter_not?: Maybe<Scalars['String']>;
  diameter_in?: Maybe<Array<Scalars['String']>>;
  diameter_not_in?: Maybe<Array<Scalars['String']>>;
  diameter_lt?: Maybe<Scalars['String']>;
  diameter_lte?: Maybe<Scalars['String']>;
  diameter_gt?: Maybe<Scalars['String']>;
  diameter_gte?: Maybe<Scalars['String']>;
  diameter_contains?: Maybe<Scalars['String']>;
  diameter_not_contains?: Maybe<Scalars['String']>;
  diameter_starts_with?: Maybe<Scalars['String']>;
  diameter_not_starts_with?: Maybe<Scalars['String']>;
  diameter_ends_with?: Maybe<Scalars['String']>;
  diameter_not_ends_with?: Maybe<Scalars['String']>;
  thickness?: Maybe<Scalars['String']>;
  thickness_not?: Maybe<Scalars['String']>;
  thickness_in?: Maybe<Array<Scalars['String']>>;
  thickness_not_in?: Maybe<Array<Scalars['String']>>;
  thickness_lt?: Maybe<Scalars['String']>;
  thickness_lte?: Maybe<Scalars['String']>;
  thickness_gt?: Maybe<Scalars['String']>;
  thickness_gte?: Maybe<Scalars['String']>;
  thickness_contains?: Maybe<Scalars['String']>;
  thickness_not_contains?: Maybe<Scalars['String']>;
  thickness_starts_with?: Maybe<Scalars['String']>;
  thickness_not_starts_with?: Maybe<Scalars['String']>;
  thickness_ends_with?: Maybe<Scalars['String']>;
  thickness_not_ends_with?: Maybe<Scalars['String']>;
  potSize?: Maybe<Scalars['String']>;
  potSize_not?: Maybe<Scalars['String']>;
  potSize_in?: Maybe<Array<Scalars['String']>>;
  potSize_not_in?: Maybe<Array<Scalars['String']>>;
  potSize_lt?: Maybe<Scalars['String']>;
  potSize_lte?: Maybe<Scalars['String']>;
  potSize_gt?: Maybe<Scalars['String']>;
  potSize_gte?: Maybe<Scalars['String']>;
  potSize_contains?: Maybe<Scalars['String']>;
  potSize_not_contains?: Maybe<Scalars['String']>;
  potSize_starts_with?: Maybe<Scalars['String']>;
  potSize_not_starts_with?: Maybe<Scalars['String']>;
  potSize_ends_with?: Maybe<Scalars['String']>;
  potSize_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TradeSkuPlantWhereInput>>;
  OR?: Maybe<Array<TradeSkuPlantWhereInput>>;
  NOT?: Maybe<Array<TradeSkuPlantWhereInput>>;
};

export type TradeSkuPlantWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TradeSkuPreviousValues = {
  __typename?: 'TradeSkuPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: CatalogItemType;
  skuSignature: Scalars['String'];
};

export type TradeSkuPrice = {
  __typename?: 'TradeSkuPrice';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  price: Scalars['Float'];
};

export type TradeSkuPriceConnection = {
  __typename?: 'TradeSkuPriceConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TradeSkuPriceEdge>>;
  aggregate: AggregateTradeSkuPrice;
};

export type TradeSkuPriceCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  price: Scalars['Float'];
};

export type TradeSkuPriceCreateManyInput = {
  create?: Maybe<Array<TradeSkuPriceCreateInput>>;
  connect?: Maybe<Array<TradeSkuPriceWhereUniqueInput>>;
};

export type TradeSkuPriceEdge = {
  __typename?: 'TradeSkuPriceEdge';
  node: TradeSkuPrice;
  cursor: Scalars['String'];
};

export enum TradeSkuPriceOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC'
}

export type TradeSkuPricePreviousValues = {
  __typename?: 'TradeSkuPricePreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  price: Scalars['Float'];
};

export type TradeSkuPriceScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  currency_not?: Maybe<Currency>;
  currency_in?: Maybe<Array<Currency>>;
  currency_not_in?: Maybe<Array<Currency>>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  AND?: Maybe<Array<TradeSkuPriceScalarWhereInput>>;
  OR?: Maybe<Array<TradeSkuPriceScalarWhereInput>>;
  NOT?: Maybe<Array<TradeSkuPriceScalarWhereInput>>;
};

export type TradeSkuPriceSubscriptionPayload = {
  __typename?: 'TradeSkuPriceSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TradeSkuPrice>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TradeSkuPricePreviousValues>;
};

export type TradeSkuPriceSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TradeSkuPriceWhereInput>;
  AND?: Maybe<Array<TradeSkuPriceSubscriptionWhereInput>>;
  OR?: Maybe<Array<TradeSkuPriceSubscriptionWhereInput>>;
  NOT?: Maybe<Array<TradeSkuPriceSubscriptionWhereInput>>;
};

export type TradeSkuPriceUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  price?: Maybe<Scalars['Float']>;
};

export type TradeSkuPriceUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  price?: Maybe<Scalars['Float']>;
};

export type TradeSkuPriceUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  price?: Maybe<Scalars['Float']>;
};

export type TradeSkuPriceUpdateManyInput = {
  create?: Maybe<Array<TradeSkuPriceCreateInput>>;
  update?: Maybe<Array<TradeSkuPriceUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<TradeSkuPriceUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<TradeSkuPriceWhereUniqueInput>>;
  connect?: Maybe<Array<TradeSkuPriceWhereUniqueInput>>;
  set?: Maybe<Array<TradeSkuPriceWhereUniqueInput>>;
  disconnect?: Maybe<Array<TradeSkuPriceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TradeSkuPriceScalarWhereInput>>;
  updateMany?: Maybe<Array<TradeSkuPriceUpdateManyWithWhereNestedInput>>;
};

export type TradeSkuPriceUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  price?: Maybe<Scalars['Float']>;
};

export type TradeSkuPriceUpdateManyWithWhereNestedInput = {
  where: TradeSkuPriceScalarWhereInput;
  data: TradeSkuPriceUpdateManyDataInput;
};

export type TradeSkuPriceUpdateWithWhereUniqueNestedInput = {
  where: TradeSkuPriceWhereUniqueInput;
  data: TradeSkuPriceUpdateDataInput;
};

export type TradeSkuPriceUpsertWithWhereUniqueNestedInput = {
  where: TradeSkuPriceWhereUniqueInput;
  update: TradeSkuPriceUpdateDataInput;
  create: TradeSkuPriceCreateInput;
};

export type TradeSkuPriceWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  currency_not?: Maybe<Currency>;
  currency_in?: Maybe<Array<Currency>>;
  currency_not_in?: Maybe<Array<Currency>>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  AND?: Maybe<Array<TradeSkuPriceWhereInput>>;
  OR?: Maybe<Array<TradeSkuPriceWhereInput>>;
  NOT?: Maybe<Array<TradeSkuPriceWhereInput>>;
};

export type TradeSkuPriceWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TradeSkuScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CatalogItemType>;
  type_not?: Maybe<CatalogItemType>;
  type_in?: Maybe<Array<CatalogItemType>>;
  type_not_in?: Maybe<Array<CatalogItemType>>;
  skuSignature?: Maybe<Scalars['String']>;
  skuSignature_not?: Maybe<Scalars['String']>;
  skuSignature_in?: Maybe<Array<Scalars['String']>>;
  skuSignature_not_in?: Maybe<Array<Scalars['String']>>;
  skuSignature_lt?: Maybe<Scalars['String']>;
  skuSignature_lte?: Maybe<Scalars['String']>;
  skuSignature_gt?: Maybe<Scalars['String']>;
  skuSignature_gte?: Maybe<Scalars['String']>;
  skuSignature_contains?: Maybe<Scalars['String']>;
  skuSignature_not_contains?: Maybe<Scalars['String']>;
  skuSignature_starts_with?: Maybe<Scalars['String']>;
  skuSignature_not_starts_with?: Maybe<Scalars['String']>;
  skuSignature_ends_with?: Maybe<Scalars['String']>;
  skuSignature_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TradeSkuScalarWhereInput>>;
  OR?: Maybe<Array<TradeSkuScalarWhereInput>>;
  NOT?: Maybe<Array<TradeSkuScalarWhereInput>>;
};

export type TradeSkuSubscriptionPayload = {
  __typename?: 'TradeSkuSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TradeSku>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TradeSkuPreviousValues>;
};

export type TradeSkuSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TradeSkuWhereInput>;
  AND?: Maybe<Array<TradeSkuSubscriptionWhereInput>>;
  OR?: Maybe<Array<TradeSkuSubscriptionWhereInput>>;
  NOT?: Maybe<Array<TradeSkuSubscriptionWhereInput>>;
};

export type TradeSkuSundry = {
  __typename?: 'TradeSkuSundry';
  id: Scalars['ID'];
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuSundryConnection = {
  __typename?: 'TradeSkuSundryConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TradeSkuSundryEdge>>;
  aggregate: AggregateTradeSkuSundry;
};

export type TradeSkuSundryCreateInput = {
  id?: Maybe<Scalars['ID']>;
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuSundryCreateOneInput = {
  create?: Maybe<TradeSkuSundryCreateInput>;
  connect?: Maybe<TradeSkuSundryWhereUniqueInput>;
};

export type TradeSkuSundryEdge = {
  __typename?: 'TradeSkuSundryEdge';
  node: TradeSkuSundry;
  cursor: Scalars['String'];
};

export enum TradeSkuSundryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC'
}

export type TradeSkuSundryPreviousValues = {
  __typename?: 'TradeSkuSundryPreviousValues';
  id: Scalars['ID'];
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuSundrySubscriptionPayload = {
  __typename?: 'TradeSkuSundrySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TradeSkuSundry>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TradeSkuSundryPreviousValues>;
};

export type TradeSkuSundrySubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TradeSkuSundryWhereInput>;
  AND?: Maybe<Array<TradeSkuSundrySubscriptionWhereInput>>;
  OR?: Maybe<Array<TradeSkuSundrySubscriptionWhereInput>>;
  NOT?: Maybe<Array<TradeSkuSundrySubscriptionWhereInput>>;
};

export type TradeSkuSundryUpdateDataInput = {
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuSundryUpdateInput = {
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuSundryUpdateManyMutationInput = {
  height?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
};

export type TradeSkuSundryUpdateOneInput = {
  create?: Maybe<TradeSkuSundryCreateInput>;
  update?: Maybe<TradeSkuSundryUpdateDataInput>;
  upsert?: Maybe<TradeSkuSundryUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TradeSkuSundryWhereUniqueInput>;
};

export type TradeSkuSundryUpsertNestedInput = {
  update: TradeSkuSundryUpdateDataInput;
  create: TradeSkuSundryCreateInput;
};

export type TradeSkuSundryWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  height?: Maybe<Scalars['String']>;
  height_not?: Maybe<Scalars['String']>;
  height_in?: Maybe<Array<Scalars['String']>>;
  height_not_in?: Maybe<Array<Scalars['String']>>;
  height_lt?: Maybe<Scalars['String']>;
  height_lte?: Maybe<Scalars['String']>;
  height_gt?: Maybe<Scalars['String']>;
  height_gte?: Maybe<Scalars['String']>;
  height_contains?: Maybe<Scalars['String']>;
  height_not_contains?: Maybe<Scalars['String']>;
  height_starts_with?: Maybe<Scalars['String']>;
  height_not_starts_with?: Maybe<Scalars['String']>;
  height_ends_with?: Maybe<Scalars['String']>;
  height_not_ends_with?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  weight_not?: Maybe<Scalars['String']>;
  weight_in?: Maybe<Array<Scalars['String']>>;
  weight_not_in?: Maybe<Array<Scalars['String']>>;
  weight_lt?: Maybe<Scalars['String']>;
  weight_lte?: Maybe<Scalars['String']>;
  weight_gt?: Maybe<Scalars['String']>;
  weight_gte?: Maybe<Scalars['String']>;
  weight_contains?: Maybe<Scalars['String']>;
  weight_not_contains?: Maybe<Scalars['String']>;
  weight_starts_with?: Maybe<Scalars['String']>;
  weight_not_starts_with?: Maybe<Scalars['String']>;
  weight_ends_with?: Maybe<Scalars['String']>;
  weight_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TradeSkuSundryWhereInput>>;
  OR?: Maybe<Array<TradeSkuSundryWhereInput>>;
  NOT?: Maybe<Array<TradeSkuSundryWhereInput>>;
};

export type TradeSkuSundryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type TradeSkuUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CatalogItemType>;
  skuSignature?: Maybe<Scalars['String']>;
  availability?: Maybe<TradeSkuAvailabilityUpdateManyInput>;
  flower?: Maybe<TradeSkuFlowerUpdateOneInput>;
  plant?: Maybe<TradeSkuPlantUpdateOneInput>;
  decoration?: Maybe<TradeSkuDecorationUpdateOneInput>;
  sundry?: Maybe<TradeSkuSundryUpdateOneInput>;
};

export type TradeSkuUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CatalogItemType>;
  skuSignature?: Maybe<Scalars['String']>;
  availability?: Maybe<TradeSkuAvailabilityUpdateManyInput>;
  flower?: Maybe<TradeSkuFlowerUpdateOneInput>;
  plant?: Maybe<TradeSkuPlantUpdateOneInput>;
  decoration?: Maybe<TradeSkuDecorationUpdateOneInput>;
  sundry?: Maybe<TradeSkuSundryUpdateOneInput>;
};

export type TradeSkuUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CatalogItemType>;
  skuSignature?: Maybe<Scalars['String']>;
};

export type TradeSkuUpdateManyInput = {
  create?: Maybe<Array<TradeSkuCreateInput>>;
  update?: Maybe<Array<TradeSkuUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<TradeSkuUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<TradeSkuWhereUniqueInput>>;
  connect?: Maybe<Array<TradeSkuWhereUniqueInput>>;
  set?: Maybe<Array<TradeSkuWhereUniqueInput>>;
  disconnect?: Maybe<Array<TradeSkuWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TradeSkuScalarWhereInput>>;
  updateMany?: Maybe<Array<TradeSkuUpdateManyWithWhereNestedInput>>;
};

export type TradeSkuUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CatalogItemType>;
  skuSignature?: Maybe<Scalars['String']>;
};

export type TradeSkuUpdateManyWithWhereNestedInput = {
  where: TradeSkuScalarWhereInput;
  data: TradeSkuUpdateManyDataInput;
};

export type TradeSkuUpdateWithWhereUniqueNestedInput = {
  where: TradeSkuWhereUniqueInput;
  data: TradeSkuUpdateDataInput;
};

export type TradeSkuUpsertWithWhereUniqueNestedInput = {
  where: TradeSkuWhereUniqueInput;
  update: TradeSkuUpdateDataInput;
  create: TradeSkuCreateInput;
};

export type TradeSkuWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<CatalogItemType>;
  type_not?: Maybe<CatalogItemType>;
  type_in?: Maybe<Array<CatalogItemType>>;
  type_not_in?: Maybe<Array<CatalogItemType>>;
  skuSignature?: Maybe<Scalars['String']>;
  skuSignature_not?: Maybe<Scalars['String']>;
  skuSignature_in?: Maybe<Array<Scalars['String']>>;
  skuSignature_not_in?: Maybe<Array<Scalars['String']>>;
  skuSignature_lt?: Maybe<Scalars['String']>;
  skuSignature_lte?: Maybe<Scalars['String']>;
  skuSignature_gt?: Maybe<Scalars['String']>;
  skuSignature_gte?: Maybe<Scalars['String']>;
  skuSignature_contains?: Maybe<Scalars['String']>;
  skuSignature_not_contains?: Maybe<Scalars['String']>;
  skuSignature_starts_with?: Maybe<Scalars['String']>;
  skuSignature_not_starts_with?: Maybe<Scalars['String']>;
  skuSignature_ends_with?: Maybe<Scalars['String']>;
  skuSignature_not_ends_with?: Maybe<Scalars['String']>;
  availability_every?: Maybe<TradeSkuAvailabilityWhereInput>;
  availability_some?: Maybe<TradeSkuAvailabilityWhereInput>;
  availability_none?: Maybe<TradeSkuAvailabilityWhereInput>;
  flower?: Maybe<TradeSkuFlowerWhereInput>;
  plant?: Maybe<TradeSkuPlantWhereInput>;
  decoration?: Maybe<TradeSkuDecorationWhereInput>;
  sundry?: Maybe<TradeSkuSundryWhereInput>;
  other?: Maybe<TradeSkuOtherWhereInput>;
  AND?: Maybe<Array<TradeSkuWhereInput>>;
  OR?: Maybe<Array<TradeSkuWhereInput>>;
  NOT?: Maybe<Array<TradeSkuWhereInput>>;
};

export type TradeSkuWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  skuSignature?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  merchant?: Maybe<Merchant>;
  customer?: Maybe<Customer>;
  invoice?: Maybe<Invoice>;
  order?: Maybe<Order>;
  adjustment?: Maybe<Adjustment>;
  source: TransactionSource;
  remittanceType: TransactionRemittanceType;
  type: TransactionType;
  currency: Currency;
  remittanceTransfer?: Maybe<RemittanceTransfer>;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  taxMetadata?: Maybe<Scalars['Json']>;
  externalTransactionId?: Maybe<Scalars['String']>;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<TransactionEdge>>;
  aggregate: AggregateTransaction;
};

export type TransactionCreateInput = {
  id?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantCreateOneInput>;
  customer?: Maybe<CustomerCreateOneInput>;
  invoice?: Maybe<InvoiceCreateOneInput>;
  adjustment?: Maybe<AdjustmentCreateOneInput>;
  source: TransactionSource;
  remittanceType: TransactionRemittanceType;
  type: TransactionType;
  currency: Currency;
  remittanceTransfer?: Maybe<RemittanceTransferCreateOneInput>;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  taxMetadata?: Maybe<Scalars['Json']>;
  externalTransactionId?: Maybe<Scalars['String']>;
};

export type TransactionCreateManyWithoutOrderInput = {
  create?: Maybe<Array<TransactionCreateWithoutOrderInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
};

export type TransactionCreateWithoutOrderInput = {
  id?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantCreateOneInput>;
  customer?: Maybe<CustomerCreateOneInput>;
  invoice?: Maybe<InvoiceCreateOneInput>;
  adjustment?: Maybe<AdjustmentCreateOneInput>;
  source: TransactionSource;
  remittanceType: TransactionRemittanceType;
  type: TransactionType;
  currency: Currency;
  remittanceTransfer?: Maybe<RemittanceTransferCreateOneInput>;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  taxMetadata?: Maybe<Scalars['Json']>;
  externalTransactionId?: Maybe<Scalars['String']>;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  node: Transaction;
  cursor: Scalars['String'];
};

export enum TransactionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  RemittanceTypeAsc = 'remittanceType_ASC',
  RemittanceTypeDesc = 'remittanceType_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  IsReviewRequiredAsc = 'isReviewRequired_ASC',
  IsReviewRequiredDesc = 'isReviewRequired_DESC',
  IsExcludedAsc = 'isExcluded_ASC',
  IsExcludedDesc = 'isExcluded_DESC',
  GrossAmountAsc = 'grossAmount_ASC',
  GrossAmountDesc = 'grossAmount_DESC',
  NetAmountAsc = 'netAmount_ASC',
  NetAmountDesc = 'netAmount_DESC',
  ItemAmountAsc = 'itemAmount_ASC',
  ItemAmountDesc = 'itemAmount_DESC',
  DiscountAmountAsc = 'discountAmount_ASC',
  DiscountAmountDesc = 'discountAmount_DESC',
  DeliveryAmountAsc = 'deliveryAmount_ASC',
  DeliveryAmountDesc = 'deliveryAmount_DESC',
  ExternalFeeAsc = 'externalFee_ASC',
  ExternalFeeDesc = 'externalFee_DESC',
  ServiceFeeAsc = 'serviceFee_ASC',
  ServiceFeeDesc = 'serviceFee_DESC',
  FloomFeeAsc = 'floomFee_ASC',
  FloomFeeDesc = 'floomFee_DESC',
  TaxAmountAsc = 'taxAmount_ASC',
  TaxAmountDesc = 'taxAmount_DESC',
  InclusiveTaxRateAsc = 'inclusiveTaxRate_ASC',
  InclusiveTaxRateDesc = 'inclusiveTaxRate_DESC',
  CommissionRateAsc = 'commissionRate_ASC',
  CommissionRateDesc = 'commissionRate_DESC',
  MerchantRemittanceAsc = 'merchantRemittance_ASC',
  MerchantRemittanceDesc = 'merchantRemittance_DESC',
  FloomRemittanceAsc = 'floomRemittance_ASC',
  FloomRemittanceDesc = 'floomRemittance_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  MetadataAsc = 'metadata_ASC',
  MetadataDesc = 'metadata_DESC',
  TaxMetadataAsc = 'taxMetadata_ASC',
  TaxMetadataDesc = 'taxMetadata_DESC',
  ExternalTransactionIdAsc = 'externalTransactionId_ASC',
  ExternalTransactionIdDesc = 'externalTransactionId_DESC'
}

export type TransactionPreviousValues = {
  __typename?: 'TransactionPreviousValues';
  id: Scalars['ID'];
  source: TransactionSource;
  remittanceType: TransactionRemittanceType;
  type: TransactionType;
  currency: Currency;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  taxMetadata?: Maybe<Scalars['Json']>;
  externalTransactionId?: Maybe<Scalars['String']>;
};

export enum TransactionRemittanceType {
  Instant = 'Instant',
  Period = 'Period'
}

export type TransactionScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  source?: Maybe<TransactionSource>;
  source_not?: Maybe<TransactionSource>;
  source_in?: Maybe<Array<TransactionSource>>;
  source_not_in?: Maybe<Array<TransactionSource>>;
  remittanceType?: Maybe<TransactionRemittanceType>;
  remittanceType_not?: Maybe<TransactionRemittanceType>;
  remittanceType_in?: Maybe<Array<TransactionRemittanceType>>;
  remittanceType_not_in?: Maybe<Array<TransactionRemittanceType>>;
  type?: Maybe<TransactionType>;
  type_not?: Maybe<TransactionType>;
  type_in?: Maybe<Array<TransactionType>>;
  type_not_in?: Maybe<Array<TransactionType>>;
  currency?: Maybe<Currency>;
  currency_not?: Maybe<Currency>;
  currency_in?: Maybe<Array<Currency>>;
  currency_not_in?: Maybe<Array<Currency>>;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isReviewRequired_not?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  isExcluded_not?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  grossAmount_not?: Maybe<Scalars['Float']>;
  grossAmount_in?: Maybe<Array<Scalars['Float']>>;
  grossAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  grossAmount_lt?: Maybe<Scalars['Float']>;
  grossAmount_lte?: Maybe<Scalars['Float']>;
  grossAmount_gt?: Maybe<Scalars['Float']>;
  grossAmount_gte?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  netAmount_not?: Maybe<Scalars['Float']>;
  netAmount_in?: Maybe<Array<Scalars['Float']>>;
  netAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  netAmount_lt?: Maybe<Scalars['Float']>;
  netAmount_lte?: Maybe<Scalars['Float']>;
  netAmount_gt?: Maybe<Scalars['Float']>;
  netAmount_gte?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  itemAmount_not?: Maybe<Scalars['Float']>;
  itemAmount_in?: Maybe<Array<Scalars['Float']>>;
  itemAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  itemAmount_lt?: Maybe<Scalars['Float']>;
  itemAmount_lte?: Maybe<Scalars['Float']>;
  itemAmount_gt?: Maybe<Scalars['Float']>;
  itemAmount_gte?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountAmount_not?: Maybe<Scalars['Float']>;
  discountAmount_in?: Maybe<Array<Scalars['Float']>>;
  discountAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  discountAmount_lt?: Maybe<Scalars['Float']>;
  discountAmount_lte?: Maybe<Scalars['Float']>;
  discountAmount_gt?: Maybe<Scalars['Float']>;
  discountAmount_gte?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  deliveryAmount_not?: Maybe<Scalars['Float']>;
  deliveryAmount_in?: Maybe<Array<Scalars['Float']>>;
  deliveryAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  deliveryAmount_lt?: Maybe<Scalars['Float']>;
  deliveryAmount_lte?: Maybe<Scalars['Float']>;
  deliveryAmount_gt?: Maybe<Scalars['Float']>;
  deliveryAmount_gte?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  externalFee_not?: Maybe<Scalars['Float']>;
  externalFee_in?: Maybe<Array<Scalars['Float']>>;
  externalFee_not_in?: Maybe<Array<Scalars['Float']>>;
  externalFee_lt?: Maybe<Scalars['Float']>;
  externalFee_lte?: Maybe<Scalars['Float']>;
  externalFee_gt?: Maybe<Scalars['Float']>;
  externalFee_gte?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  serviceFee_not?: Maybe<Scalars['Float']>;
  serviceFee_in?: Maybe<Array<Scalars['Float']>>;
  serviceFee_not_in?: Maybe<Array<Scalars['Float']>>;
  serviceFee_lt?: Maybe<Scalars['Float']>;
  serviceFee_lte?: Maybe<Scalars['Float']>;
  serviceFee_gt?: Maybe<Scalars['Float']>;
  serviceFee_gte?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  floomFee_not?: Maybe<Scalars['Float']>;
  floomFee_in?: Maybe<Array<Scalars['Float']>>;
  floomFee_not_in?: Maybe<Array<Scalars['Float']>>;
  floomFee_lt?: Maybe<Scalars['Float']>;
  floomFee_lte?: Maybe<Scalars['Float']>;
  floomFee_gt?: Maybe<Scalars['Float']>;
  floomFee_gte?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  taxAmount_not?: Maybe<Scalars['Float']>;
  taxAmount_in?: Maybe<Array<Scalars['Float']>>;
  taxAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  taxAmount_lt?: Maybe<Scalars['Float']>;
  taxAmount_lte?: Maybe<Scalars['Float']>;
  taxAmount_gt?: Maybe<Scalars['Float']>;
  taxAmount_gte?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_not?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_in?: Maybe<Array<Scalars['Float']>>;
  inclusiveTaxRate_not_in?: Maybe<Array<Scalars['Float']>>;
  inclusiveTaxRate_lt?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_lte?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_gt?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_gte?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  commissionRate_not?: Maybe<Scalars['Float']>;
  commissionRate_in?: Maybe<Array<Scalars['Float']>>;
  commissionRate_not_in?: Maybe<Array<Scalars['Float']>>;
  commissionRate_lt?: Maybe<Scalars['Float']>;
  commissionRate_lte?: Maybe<Scalars['Float']>;
  commissionRate_gt?: Maybe<Scalars['Float']>;
  commissionRate_gte?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  merchantRemittance_not?: Maybe<Scalars['Float']>;
  merchantRemittance_in?: Maybe<Array<Scalars['Float']>>;
  merchantRemittance_not_in?: Maybe<Array<Scalars['Float']>>;
  merchantRemittance_lt?: Maybe<Scalars['Float']>;
  merchantRemittance_lte?: Maybe<Scalars['Float']>;
  merchantRemittance_gt?: Maybe<Scalars['Float']>;
  merchantRemittance_gte?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  floomRemittance_not?: Maybe<Scalars['Float']>;
  floomRemittance_in?: Maybe<Array<Scalars['Float']>>;
  floomRemittance_not_in?: Maybe<Array<Scalars['Float']>>;
  floomRemittance_lt?: Maybe<Scalars['Float']>;
  floomRemittance_lte?: Maybe<Scalars['Float']>;
  floomRemittance_gt?: Maybe<Scalars['Float']>;
  floomRemittance_gte?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  externalTransactionId?: Maybe<Scalars['String']>;
  externalTransactionId_not?: Maybe<Scalars['String']>;
  externalTransactionId_in?: Maybe<Array<Scalars['String']>>;
  externalTransactionId_not_in?: Maybe<Array<Scalars['String']>>;
  externalTransactionId_lt?: Maybe<Scalars['String']>;
  externalTransactionId_lte?: Maybe<Scalars['String']>;
  externalTransactionId_gt?: Maybe<Scalars['String']>;
  externalTransactionId_gte?: Maybe<Scalars['String']>;
  externalTransactionId_contains?: Maybe<Scalars['String']>;
  externalTransactionId_not_contains?: Maybe<Scalars['String']>;
  externalTransactionId_starts_with?: Maybe<Scalars['String']>;
  externalTransactionId_not_starts_with?: Maybe<Scalars['String']>;
  externalTransactionId_ends_with?: Maybe<Scalars['String']>;
  externalTransactionId_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TransactionScalarWhereInput>>;
  OR?: Maybe<Array<TransactionScalarWhereInput>>;
  NOT?: Maybe<Array<TransactionScalarWhereInput>>;
};

export enum TransactionSource {
  Wholesale = 'Wholesale',
  WholesaleCashback = 'WholesaleCashback',
  Order = 'Order',
  OrderRefund = 'OrderRefund',
  Adjustment = 'Adjustment',
  Subscription = 'Subscription'
}

export type TransactionSubscriptionPayload = {
  __typename?: 'TransactionSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Transaction>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<TransactionPreviousValues>;
};

export type TransactionSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<TransactionWhereInput>;
  AND?: Maybe<Array<TransactionSubscriptionWhereInput>>;
  OR?: Maybe<Array<TransactionSubscriptionWhereInput>>;
  NOT?: Maybe<Array<TransactionSubscriptionWhereInput>>;
};

export enum TransactionType {
  Credit = 'Credit',
  Debit = 'Debit'
}

export type TransactionUpdateInput = {
  merchant?: Maybe<MerchantUpdateOneInput>;
  customer?: Maybe<CustomerUpdateOneInput>;
  adjustment?: Maybe<AdjustmentUpdateOneInput>;
  source?: Maybe<TransactionSource>;
  remittanceType?: Maybe<TransactionRemittanceType>;
  type?: Maybe<TransactionType>;
  currency?: Maybe<Currency>;
  remittanceTransfer?: Maybe<RemittanceTransferUpdateOneInput>;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  taxMetadata?: Maybe<Scalars['Json']>;
  externalTransactionId?: Maybe<Scalars['String']>;
};

export type TransactionUpdateManyDataInput = {
  source?: Maybe<TransactionSource>;
  remittanceType?: Maybe<TransactionRemittanceType>;
  type?: Maybe<TransactionType>;
  currency?: Maybe<Currency>;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  taxMetadata?: Maybe<Scalars['Json']>;
  externalTransactionId?: Maybe<Scalars['String']>;
};

export type TransactionUpdateManyMutationInput = {
  source?: Maybe<TransactionSource>;
  remittanceType?: Maybe<TransactionRemittanceType>;
  type?: Maybe<TransactionType>;
  currency?: Maybe<Currency>;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  taxMetadata?: Maybe<Scalars['Json']>;
  externalTransactionId?: Maybe<Scalars['String']>;
};

export type TransactionUpdateManyWithWhereNestedInput = {
  where: TransactionScalarWhereInput;
  data: TransactionUpdateManyDataInput;
};

export type TransactionUpdateManyWithoutOrderInput = {
  create?: Maybe<Array<TransactionCreateWithoutOrderInput>>;
  delete?: Maybe<Array<TransactionWhereUniqueInput>>;
  connect?: Maybe<Array<TransactionWhereUniqueInput>>;
  set?: Maybe<Array<TransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<TransactionWhereUniqueInput>>;
  update?: Maybe<Array<TransactionUpdateWithWhereUniqueWithoutOrderInput>>;
  upsert?: Maybe<Array<TransactionUpsertWithWhereUniqueWithoutOrderInput>>;
  deleteMany?: Maybe<Array<TransactionScalarWhereInput>>;
  updateMany?: Maybe<Array<TransactionUpdateManyWithWhereNestedInput>>;
};

export type TransactionUpdateWithWhereUniqueWithoutOrderInput = {
  where: TransactionWhereUniqueInput;
  data: TransactionUpdateWithoutOrderDataInput;
};

export type TransactionUpdateWithoutOrderDataInput = {
  merchant?: Maybe<MerchantUpdateOneInput>;
  customer?: Maybe<CustomerUpdateOneInput>;
  adjustment?: Maybe<AdjustmentUpdateOneInput>;
  source?: Maybe<TransactionSource>;
  remittanceType?: Maybe<TransactionRemittanceType>;
  type?: Maybe<TransactionType>;
  currency?: Maybe<Currency>;
  remittanceTransfer?: Maybe<RemittanceTransferUpdateOneInput>;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['Json']>;
  taxMetadata?: Maybe<Scalars['Json']>;
  externalTransactionId?: Maybe<Scalars['String']>;
};

export type TransactionUpsertWithWhereUniqueWithoutOrderInput = {
  where: TransactionWhereUniqueInput;
  update: TransactionUpdateWithoutOrderDataInput;
  create: TransactionCreateWithoutOrderInput;
};

export type TransactionWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantWhereInput>;
  customer?: Maybe<CustomerWhereInput>;
  invoice?: Maybe<InvoiceWhereInput>;
  order?: Maybe<OrderWhereInput>;
  adjustment?: Maybe<AdjustmentWhereInput>;
  source?: Maybe<TransactionSource>;
  source_not?: Maybe<TransactionSource>;
  source_in?: Maybe<Array<TransactionSource>>;
  source_not_in?: Maybe<Array<TransactionSource>>;
  remittanceType?: Maybe<TransactionRemittanceType>;
  remittanceType_not?: Maybe<TransactionRemittanceType>;
  remittanceType_in?: Maybe<Array<TransactionRemittanceType>>;
  remittanceType_not_in?: Maybe<Array<TransactionRemittanceType>>;
  type?: Maybe<TransactionType>;
  type_not?: Maybe<TransactionType>;
  type_in?: Maybe<Array<TransactionType>>;
  type_not_in?: Maybe<Array<TransactionType>>;
  currency?: Maybe<Currency>;
  currency_not?: Maybe<Currency>;
  currency_in?: Maybe<Array<Currency>>;
  currency_not_in?: Maybe<Array<Currency>>;
  remittanceTransfer?: Maybe<RemittanceTransferWhereInput>;
  isReviewRequired?: Maybe<Scalars['Boolean']>;
  isReviewRequired_not?: Maybe<Scalars['Boolean']>;
  isExcluded?: Maybe<Scalars['Boolean']>;
  isExcluded_not?: Maybe<Scalars['Boolean']>;
  grossAmount?: Maybe<Scalars['Float']>;
  grossAmount_not?: Maybe<Scalars['Float']>;
  grossAmount_in?: Maybe<Array<Scalars['Float']>>;
  grossAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  grossAmount_lt?: Maybe<Scalars['Float']>;
  grossAmount_lte?: Maybe<Scalars['Float']>;
  grossAmount_gt?: Maybe<Scalars['Float']>;
  grossAmount_gte?: Maybe<Scalars['Float']>;
  netAmount?: Maybe<Scalars['Float']>;
  netAmount_not?: Maybe<Scalars['Float']>;
  netAmount_in?: Maybe<Array<Scalars['Float']>>;
  netAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  netAmount_lt?: Maybe<Scalars['Float']>;
  netAmount_lte?: Maybe<Scalars['Float']>;
  netAmount_gt?: Maybe<Scalars['Float']>;
  netAmount_gte?: Maybe<Scalars['Float']>;
  itemAmount?: Maybe<Scalars['Float']>;
  itemAmount_not?: Maybe<Scalars['Float']>;
  itemAmount_in?: Maybe<Array<Scalars['Float']>>;
  itemAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  itemAmount_lt?: Maybe<Scalars['Float']>;
  itemAmount_lte?: Maybe<Scalars['Float']>;
  itemAmount_gt?: Maybe<Scalars['Float']>;
  itemAmount_gte?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountAmount_not?: Maybe<Scalars['Float']>;
  discountAmount_in?: Maybe<Array<Scalars['Float']>>;
  discountAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  discountAmount_lt?: Maybe<Scalars['Float']>;
  discountAmount_lte?: Maybe<Scalars['Float']>;
  discountAmount_gt?: Maybe<Scalars['Float']>;
  discountAmount_gte?: Maybe<Scalars['Float']>;
  deliveryAmount?: Maybe<Scalars['Float']>;
  deliveryAmount_not?: Maybe<Scalars['Float']>;
  deliveryAmount_in?: Maybe<Array<Scalars['Float']>>;
  deliveryAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  deliveryAmount_lt?: Maybe<Scalars['Float']>;
  deliveryAmount_lte?: Maybe<Scalars['Float']>;
  deliveryAmount_gt?: Maybe<Scalars['Float']>;
  deliveryAmount_gte?: Maybe<Scalars['Float']>;
  externalFee?: Maybe<Scalars['Float']>;
  externalFee_not?: Maybe<Scalars['Float']>;
  externalFee_in?: Maybe<Array<Scalars['Float']>>;
  externalFee_not_in?: Maybe<Array<Scalars['Float']>>;
  externalFee_lt?: Maybe<Scalars['Float']>;
  externalFee_lte?: Maybe<Scalars['Float']>;
  externalFee_gt?: Maybe<Scalars['Float']>;
  externalFee_gte?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  serviceFee_not?: Maybe<Scalars['Float']>;
  serviceFee_in?: Maybe<Array<Scalars['Float']>>;
  serviceFee_not_in?: Maybe<Array<Scalars['Float']>>;
  serviceFee_lt?: Maybe<Scalars['Float']>;
  serviceFee_lte?: Maybe<Scalars['Float']>;
  serviceFee_gt?: Maybe<Scalars['Float']>;
  serviceFee_gte?: Maybe<Scalars['Float']>;
  floomFee?: Maybe<Scalars['Float']>;
  floomFee_not?: Maybe<Scalars['Float']>;
  floomFee_in?: Maybe<Array<Scalars['Float']>>;
  floomFee_not_in?: Maybe<Array<Scalars['Float']>>;
  floomFee_lt?: Maybe<Scalars['Float']>;
  floomFee_lte?: Maybe<Scalars['Float']>;
  floomFee_gt?: Maybe<Scalars['Float']>;
  floomFee_gte?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  taxAmount_not?: Maybe<Scalars['Float']>;
  taxAmount_in?: Maybe<Array<Scalars['Float']>>;
  taxAmount_not_in?: Maybe<Array<Scalars['Float']>>;
  taxAmount_lt?: Maybe<Scalars['Float']>;
  taxAmount_lte?: Maybe<Scalars['Float']>;
  taxAmount_gt?: Maybe<Scalars['Float']>;
  taxAmount_gte?: Maybe<Scalars['Float']>;
  inclusiveTaxRate?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_not?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_in?: Maybe<Array<Scalars['Float']>>;
  inclusiveTaxRate_not_in?: Maybe<Array<Scalars['Float']>>;
  inclusiveTaxRate_lt?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_lte?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_gt?: Maybe<Scalars['Float']>;
  inclusiveTaxRate_gte?: Maybe<Scalars['Float']>;
  commissionRate?: Maybe<Scalars['Float']>;
  commissionRate_not?: Maybe<Scalars['Float']>;
  commissionRate_in?: Maybe<Array<Scalars['Float']>>;
  commissionRate_not_in?: Maybe<Array<Scalars['Float']>>;
  commissionRate_lt?: Maybe<Scalars['Float']>;
  commissionRate_lte?: Maybe<Scalars['Float']>;
  commissionRate_gt?: Maybe<Scalars['Float']>;
  commissionRate_gte?: Maybe<Scalars['Float']>;
  merchantRemittance?: Maybe<Scalars['Float']>;
  merchantRemittance_not?: Maybe<Scalars['Float']>;
  merchantRemittance_in?: Maybe<Array<Scalars['Float']>>;
  merchantRemittance_not_in?: Maybe<Array<Scalars['Float']>>;
  merchantRemittance_lt?: Maybe<Scalars['Float']>;
  merchantRemittance_lte?: Maybe<Scalars['Float']>;
  merchantRemittance_gt?: Maybe<Scalars['Float']>;
  merchantRemittance_gte?: Maybe<Scalars['Float']>;
  floomRemittance?: Maybe<Scalars['Float']>;
  floomRemittance_not?: Maybe<Scalars['Float']>;
  floomRemittance_in?: Maybe<Array<Scalars['Float']>>;
  floomRemittance_not_in?: Maybe<Array<Scalars['Float']>>;
  floomRemittance_lt?: Maybe<Scalars['Float']>;
  floomRemittance_lte?: Maybe<Scalars['Float']>;
  floomRemittance_gt?: Maybe<Scalars['Float']>;
  floomRemittance_gte?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  externalTransactionId?: Maybe<Scalars['String']>;
  externalTransactionId_not?: Maybe<Scalars['String']>;
  externalTransactionId_in?: Maybe<Array<Scalars['String']>>;
  externalTransactionId_not_in?: Maybe<Array<Scalars['String']>>;
  externalTransactionId_lt?: Maybe<Scalars['String']>;
  externalTransactionId_lte?: Maybe<Scalars['String']>;
  externalTransactionId_gt?: Maybe<Scalars['String']>;
  externalTransactionId_gte?: Maybe<Scalars['String']>;
  externalTransactionId_contains?: Maybe<Scalars['String']>;
  externalTransactionId_not_contains?: Maybe<Scalars['String']>;
  externalTransactionId_starts_with?: Maybe<Scalars['String']>;
  externalTransactionId_not_starts_with?: Maybe<Scalars['String']>;
  externalTransactionId_ends_with?: Maybe<Scalars['String']>;
  externalTransactionId_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<TransactionWhereInput>>;
  OR?: Maybe<Array<TransactionWhereInput>>;
  NOT?: Maybe<Array<TransactionWhereInput>>;
};

export type TransactionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type UpdateCartInput = {
  email?: Maybe<Scalars['String']>;
  currency: Currency;
  deliverAt: Scalars['DateTime'];
  shippingAddress: AddressUpdateDataInput;
  billingAddress?: Maybe<AddressUpdateDataInput>;
  discount?: Maybe<DiscountValidationWhereInput>;
  lineItems: Array<Maybe<UpdateCartLineItemInput>>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliverySafePlace?: Maybe<Scalars['String']>;
  newsletterSignupAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateCartLineItemInput = {
  orderItem: OrderItemWhereUniqueInput;
  variation: ProductVariationWhereUniqueInput;
  quantity: Scalars['Int'];
  giftMessages?: Maybe<Array<Maybe<Scalars['String']>>>;
  addOns?: Maybe<Array<Maybe<CartAddOnInput>>>;
};

export type UpdateMerchantActiveStatusInput = {
  isActive: Scalars['Boolean'];
  where: UpdateMerchantActiveStatusWhereInput;
};

export type UpdateMerchantActiveStatusResponse = {
  __typename?: 'UpdateMerchantActiveStatusResponse';
  success: Scalars['Boolean'];
};

export type UpdateMerchantActiveStatusWhereInput = {
  id: Scalars['String'];
};

export type UpdateMerchantSubscriptionInput = {
  merchantPlanId: Scalars['String'];
  stripePriceId?: Maybe<Scalars['String']>;
  prorationStartsAt?: Maybe<Scalars['String']>;
  subscriptionPrice?: Maybe<Scalars['Float']>;
};

export type UpdateMerchantSubscriptionOutput = {
  __typename?: 'UpdateMerchantSubscriptionOutput';
  invoiceURL?: Maybe<Scalars['String']>;
};

export type UpdatePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UpdatePaymentIntentInput = {
  cartNumber: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  paymentIntentId?: Maybe<Scalars['String']>;
  shouldSaveCard?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  secondaryEmail?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
  role: UserRole;
  givenName: Scalars['String'];
  familyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Locale>;
  verified: Scalars['Boolean'];
  verifyIssueAt?: Maybe<Scalars['DateTime']>;
  verifyCode?: Maybe<Scalars['String']>;
  lastLoginIp?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<Merchant>;
  shop?: Maybe<Shop>;
  active?: Maybe<Scalars['Boolean']>;
  sessionsExpiredAt?: Maybe<Scalars['DateTime']>;
};

export type UserConnectOneInput = {
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserConnectOneWithoutShopInput = {
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<UserEdge>>;
  aggregate: AggregateUser;
};

export type UserCreateInput = {
  flc_id?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  merchant: MerchantCreateOneInput;
  role: UserRole;
  givenName: Scalars['String'];
  familyName: Scalars['String'];
  verified: Scalars['Boolean'];
  phone: Scalars['String'];
  shop?: Maybe<ShopConnectWhereUnique>;
  secondaryEmail?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
};

export type UserCreateOneInput = {
  connect?: Maybe<UserWhereUniqueInput>;
  create?: Maybe<UserCreateInput>;
};

export type UserCreateOneWithoutMerchantInput = {
  create?: Maybe<UserCreateWithoutMerchantInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateOneWithoutShopInput = {
  create?: Maybe<UserCreateWithoutShopInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateWithoutMerchantInput = {
  email: Scalars['String'];
  secondaryEmail?: Maybe<Scalars['String']>;
  givenName: Scalars['String'];
  familyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Locale>;
  verified: Scalars['Boolean'];
  active?: Maybe<Scalars['Boolean']>;
};

export type UserCreateWithoutShopInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  secondaryEmail?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  team?: Maybe<TeamCreateOneInput>;
  role?: Maybe<UserRole>;
  givenName: Scalars['String'];
  familyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Locale>;
  verified: Scalars['Boolean'];
  verifyIssueAt?: Maybe<Scalars['DateTime']>;
  verifyCode?: Maybe<Scalars['String']>;
  lastLoginIp?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<MerchantCreateOneWithoutCreatedByInput>;
  active?: Maybe<Scalars['Boolean']>;
  sessionsExpiredAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreaterolesInput = {
  set?: Maybe<Array<UserRole>>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: User;
  cursor: Scalars['String'];
};

export type UserLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum UserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  FlcIdAsc = 'flc_id_ASC',
  FlcIdDesc = 'flc_id_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  SecondaryEmailAsc = 'secondaryEmail_ASC',
  SecondaryEmailDesc = 'secondaryEmail_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
  GivenNameAsc = 'givenName_ASC',
  GivenNameDesc = 'givenName_DESC',
  FamilyNameAsc = 'familyName_ASC',
  FamilyNameDesc = 'familyName_DESC',
  JobPositionAsc = 'jobPosition_ASC',
  JobPositionDesc = 'jobPosition_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  SecondaryPhoneAsc = 'secondaryPhone_ASC',
  SecondaryPhoneDesc = 'secondaryPhone_DESC',
  DefaultLocaleAsc = 'defaultLocale_ASC',
  DefaultLocaleDesc = 'defaultLocale_DESC',
  VerifiedAsc = 'verified_ASC',
  VerifiedDesc = 'verified_DESC',
  VerifyIssueAtAsc = 'verifyIssueAt_ASC',
  VerifyIssueAtDesc = 'verifyIssueAt_DESC',
  VerifyCodeAsc = 'verifyCode_ASC',
  VerifyCodeDesc = 'verifyCode_DESC',
  LastLoginIpAsc = 'lastLoginIp_ASC',
  LastLoginIpDesc = 'lastLoginIp_DESC',
  LastLoginAtAsc = 'lastLoginAt_ASC',
  LastLoginAtDesc = 'lastLoginAt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  SessionsExpiredAtAsc = 'sessionsExpiredAt_ASC',
  SessionsExpiredAtDesc = 'sessionsExpiredAt_DESC'
}

export type UserPreviousValues = {
  __typename?: 'UserPreviousValues';
  id: Scalars['ID'];
  flc_id?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  secondaryEmail?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  givenName: Scalars['String'];
  familyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Locale>;
  verified: Scalars['Boolean'];
  verifyIssueAt?: Maybe<Scalars['DateTime']>;
  verifyCode?: Maybe<Scalars['String']>;
  lastLoginIp?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  active?: Maybe<Scalars['Boolean']>;
  sessionsExpiredAt?: Maybe<Scalars['DateTime']>;
};

export enum UserRole {
  SuperAdmin = 'SuperAdmin',
  CustomerService = 'CustomerService',
  TeamOwner = 'TeamOwner',
  TeamAdmin = 'TeamAdmin',
  TeamMember = 'TeamMember'
}

export type UserSignUp = {
  email: Scalars['String'];
  password: Scalars['String'];
  givenName: Scalars['String'];
  familyName?: Maybe<Scalars['String']>;
  team_id: Scalars['ID'];
};

export type UserSubscriptionPayload = {
  __typename?: 'UserSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<User>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<UserPreviousValues>;
};

export type UserSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<UserWhereInput>;
  AND?: Maybe<Array<UserSubscriptionWhereInput>>;
  OR?: Maybe<Array<UserSubscriptionWhereInput>>;
  NOT?: Maybe<Array<UserSubscriptionWhereInput>>;
};

export type UserUpdateDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  role?: Maybe<UserRole>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Locale>;
  verified?: Maybe<Scalars['Boolean']>;
  verifyIssueAt?: Maybe<Scalars['DateTime']>;
  verifyCode?: Maybe<Scalars['String']>;
  lastLoginIp?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<MerchantUpdateOneWithoutCreatedByInput>;
  shop?: Maybe<ShopUpdateOneWithoutCreatedByInput>;
  active?: Maybe<Scalars['Boolean']>;
  sessionsExpiredAt?: Maybe<Scalars['DateTime']>;
};

export type UserUpdateInput = {
  secondaryEmail?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  shop?: Maybe<ShopConnectWhereUnique>;
  active?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
};

export type UserUpdateManyMutationInput = {
  flc_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Locale>;
  verified?: Maybe<Scalars['Boolean']>;
  verifyIssueAt?: Maybe<Scalars['DateTime']>;
  verifyCode?: Maybe<Scalars['String']>;
  lastLoginIp?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  active?: Maybe<Scalars['Boolean']>;
  sessionsExpiredAt?: Maybe<Scalars['DateTime']>;
};

export type UserUpdateOneInput = {
  create?: Maybe<UserCreateInput>;
  update?: Maybe<UserUpdateDataInput>;
  upsert?: Maybe<UserUpsertNestedInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateOneRequiredInput = {
  create?: Maybe<UserCreateInput>;
  update?: Maybe<UserUpdateDataInput>;
  upsert?: Maybe<UserUpsertNestedInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateOneWithoutMerchantInput = {
  create?: Maybe<UserCreateWithoutMerchantInput>;
  update?: Maybe<UserUpdateWithoutMerchantDataInput>;
  upsert?: Maybe<UserUpsertWithoutMerchantInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateOneWithoutShopInput = {
  create?: Maybe<UserCreateWithoutShopInput>;
  update?: Maybe<UserUpdateWithoutShopDataInput>;
  upsert?: Maybe<UserUpsertWithoutShopInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserUpdateWithoutMerchantDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  role?: Maybe<UserRole>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Locale>;
  verified?: Maybe<Scalars['Boolean']>;
  verifyIssueAt?: Maybe<Scalars['DateTime']>;
  verifyCode?: Maybe<Scalars['String']>;
  lastLoginIp?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  shop?: Maybe<ShopUpdateOneWithoutCreatedByInput>;
  active?: Maybe<Scalars['Boolean']>;
  sessionsExpiredAt?: Maybe<Scalars['DateTime']>;
};

export type UserUpdateWithoutShopDataInput = {
  flc_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  team?: Maybe<TeamUpdateOneInput>;
  role?: Maybe<UserRole>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Locale>;
  verified?: Maybe<Scalars['Boolean']>;
  verifyIssueAt?: Maybe<Scalars['DateTime']>;
  verifyCode?: Maybe<Scalars['String']>;
  lastLoginIp?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<MerchantUpdateOneWithoutCreatedByInput>;
  active?: Maybe<Scalars['Boolean']>;
  sessionsExpiredAt?: Maybe<Scalars['DateTime']>;
};

export type UserUpsertNestedInput = {
  update: UserUpdateDataInput;
  create: UserCreateInput;
};

export type UserUpsertWithoutMerchantInput = {
  update: UserUpdateWithoutMerchantDataInput;
  create: UserCreateWithoutMerchantInput;
};

export type UserUpsertWithoutShopInput = {
  update: UserUpdateWithoutShopDataInput;
  create: UserCreateWithoutShopInput;
};

export type UserWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  flc_id_not?: Maybe<Scalars['Int']>;
  flc_id_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_not_in?: Maybe<Array<Scalars['Int']>>;
  flc_id_lt?: Maybe<Scalars['Int']>;
  flc_id_lte?: Maybe<Scalars['Int']>;
  flc_id_gt?: Maybe<Scalars['Int']>;
  flc_id_gte?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  secondaryEmail?: Maybe<Scalars['String']>;
  secondaryEmail_not?: Maybe<Scalars['String']>;
  secondaryEmail_in?: Maybe<Array<Scalars['String']>>;
  secondaryEmail_not_in?: Maybe<Array<Scalars['String']>>;
  secondaryEmail_lt?: Maybe<Scalars['String']>;
  secondaryEmail_lte?: Maybe<Scalars['String']>;
  secondaryEmail_gt?: Maybe<Scalars['String']>;
  secondaryEmail_gte?: Maybe<Scalars['String']>;
  secondaryEmail_contains?: Maybe<Scalars['String']>;
  secondaryEmail_not_contains?: Maybe<Scalars['String']>;
  secondaryEmail_starts_with?: Maybe<Scalars['String']>;
  secondaryEmail_not_starts_with?: Maybe<Scalars['String']>;
  secondaryEmail_ends_with?: Maybe<Scalars['String']>;
  secondaryEmail_not_ends_with?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_not?: Maybe<Scalars['String']>;
  password_in?: Maybe<Array<Scalars['String']>>;
  password_not_in?: Maybe<Array<Scalars['String']>>;
  password_lt?: Maybe<Scalars['String']>;
  password_lte?: Maybe<Scalars['String']>;
  password_gt?: Maybe<Scalars['String']>;
  password_gte?: Maybe<Scalars['String']>;
  password_contains?: Maybe<Scalars['String']>;
  password_not_contains?: Maybe<Scalars['String']>;
  password_starts_with?: Maybe<Scalars['String']>;
  password_not_starts_with?: Maybe<Scalars['String']>;
  password_ends_with?: Maybe<Scalars['String']>;
  password_not_ends_with?: Maybe<Scalars['String']>;
  team?: Maybe<TeamWhereInput>;
  role?: Maybe<UserRole>;
  role_not?: Maybe<UserRole>;
  role_in?: Maybe<Array<UserRole>>;
  role_not_in?: Maybe<Array<UserRole>>;
  givenName?: Maybe<Scalars['String']>;
  givenName_not?: Maybe<Scalars['String']>;
  givenName_in?: Maybe<Array<Scalars['String']>>;
  givenName_not_in?: Maybe<Array<Scalars['String']>>;
  givenName_lt?: Maybe<Scalars['String']>;
  givenName_lte?: Maybe<Scalars['String']>;
  givenName_gt?: Maybe<Scalars['String']>;
  givenName_gte?: Maybe<Scalars['String']>;
  givenName_contains?: Maybe<Scalars['String']>;
  givenName_not_contains?: Maybe<Scalars['String']>;
  givenName_starts_with?: Maybe<Scalars['String']>;
  givenName_not_starts_with?: Maybe<Scalars['String']>;
  givenName_ends_with?: Maybe<Scalars['String']>;
  givenName_not_ends_with?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  familyName_not?: Maybe<Scalars['String']>;
  familyName_in?: Maybe<Array<Scalars['String']>>;
  familyName_not_in?: Maybe<Array<Scalars['String']>>;
  familyName_lt?: Maybe<Scalars['String']>;
  familyName_lte?: Maybe<Scalars['String']>;
  familyName_gt?: Maybe<Scalars['String']>;
  familyName_gte?: Maybe<Scalars['String']>;
  familyName_contains?: Maybe<Scalars['String']>;
  familyName_not_contains?: Maybe<Scalars['String']>;
  familyName_starts_with?: Maybe<Scalars['String']>;
  familyName_not_starts_with?: Maybe<Scalars['String']>;
  familyName_ends_with?: Maybe<Scalars['String']>;
  familyName_not_ends_with?: Maybe<Scalars['String']>;
  jobPosition?: Maybe<Scalars['String']>;
  jobPosition_not?: Maybe<Scalars['String']>;
  jobPosition_in?: Maybe<Array<Scalars['String']>>;
  jobPosition_not_in?: Maybe<Array<Scalars['String']>>;
  jobPosition_lt?: Maybe<Scalars['String']>;
  jobPosition_lte?: Maybe<Scalars['String']>;
  jobPosition_gt?: Maybe<Scalars['String']>;
  jobPosition_gte?: Maybe<Scalars['String']>;
  jobPosition_contains?: Maybe<Scalars['String']>;
  jobPosition_not_contains?: Maybe<Scalars['String']>;
  jobPosition_starts_with?: Maybe<Scalars['String']>;
  jobPosition_not_starts_with?: Maybe<Scalars['String']>;
  jobPosition_ends_with?: Maybe<Scalars['String']>;
  jobPosition_not_ends_with?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Scalars['String']>>;
  phone_not_in?: Maybe<Array<Scalars['String']>>;
  phone_lt?: Maybe<Scalars['String']>;
  phone_lte?: Maybe<Scalars['String']>;
  phone_gt?: Maybe<Scalars['String']>;
  phone_gte?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  secondaryPhone_not?: Maybe<Scalars['String']>;
  secondaryPhone_in?: Maybe<Array<Scalars['String']>>;
  secondaryPhone_not_in?: Maybe<Array<Scalars['String']>>;
  secondaryPhone_lt?: Maybe<Scalars['String']>;
  secondaryPhone_lte?: Maybe<Scalars['String']>;
  secondaryPhone_gt?: Maybe<Scalars['String']>;
  secondaryPhone_gte?: Maybe<Scalars['String']>;
  secondaryPhone_contains?: Maybe<Scalars['String']>;
  secondaryPhone_not_contains?: Maybe<Scalars['String']>;
  secondaryPhone_starts_with?: Maybe<Scalars['String']>;
  secondaryPhone_not_starts_with?: Maybe<Scalars['String']>;
  secondaryPhone_ends_with?: Maybe<Scalars['String']>;
  secondaryPhone_not_ends_with?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Locale>;
  defaultLocale_not?: Maybe<Locale>;
  defaultLocale_in?: Maybe<Array<Locale>>;
  defaultLocale_not_in?: Maybe<Array<Locale>>;
  verified?: Maybe<Scalars['Boolean']>;
  verified_not?: Maybe<Scalars['Boolean']>;
  verifyIssueAt?: Maybe<Scalars['DateTime']>;
  verifyIssueAt_not?: Maybe<Scalars['DateTime']>;
  verifyIssueAt_in?: Maybe<Array<Scalars['DateTime']>>;
  verifyIssueAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  verifyIssueAt_lt?: Maybe<Scalars['DateTime']>;
  verifyIssueAt_lte?: Maybe<Scalars['DateTime']>;
  verifyIssueAt_gt?: Maybe<Scalars['DateTime']>;
  verifyIssueAt_gte?: Maybe<Scalars['DateTime']>;
  verifyCode?: Maybe<Scalars['String']>;
  verifyCode_not?: Maybe<Scalars['String']>;
  verifyCode_in?: Maybe<Array<Scalars['String']>>;
  verifyCode_not_in?: Maybe<Array<Scalars['String']>>;
  verifyCode_lt?: Maybe<Scalars['String']>;
  verifyCode_lte?: Maybe<Scalars['String']>;
  verifyCode_gt?: Maybe<Scalars['String']>;
  verifyCode_gte?: Maybe<Scalars['String']>;
  verifyCode_contains?: Maybe<Scalars['String']>;
  verifyCode_not_contains?: Maybe<Scalars['String']>;
  verifyCode_starts_with?: Maybe<Scalars['String']>;
  verifyCode_not_starts_with?: Maybe<Scalars['String']>;
  verifyCode_ends_with?: Maybe<Scalars['String']>;
  verifyCode_not_ends_with?: Maybe<Scalars['String']>;
  lastLoginIp?: Maybe<Scalars['String']>;
  lastLoginIp_not?: Maybe<Scalars['String']>;
  lastLoginIp_in?: Maybe<Array<Scalars['String']>>;
  lastLoginIp_not_in?: Maybe<Array<Scalars['String']>>;
  lastLoginIp_lt?: Maybe<Scalars['String']>;
  lastLoginIp_lte?: Maybe<Scalars['String']>;
  lastLoginIp_gt?: Maybe<Scalars['String']>;
  lastLoginIp_gte?: Maybe<Scalars['String']>;
  lastLoginIp_contains?: Maybe<Scalars['String']>;
  lastLoginIp_not_contains?: Maybe<Scalars['String']>;
  lastLoginIp_starts_with?: Maybe<Scalars['String']>;
  lastLoginIp_not_starts_with?: Maybe<Scalars['String']>;
  lastLoginIp_ends_with?: Maybe<Scalars['String']>;
  lastLoginIp_not_ends_with?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastLoginAt_not?: Maybe<Scalars['DateTime']>;
  lastLoginAt_in?: Maybe<Array<Scalars['DateTime']>>;
  lastLoginAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  lastLoginAt_lt?: Maybe<Scalars['DateTime']>;
  lastLoginAt_lte?: Maybe<Scalars['DateTime']>;
  lastLoginAt_gt?: Maybe<Scalars['DateTime']>;
  lastLoginAt_gte?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  merchant?: Maybe<MerchantWhereInput>;
  shop?: Maybe<ShopWhereInput>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  sessionsExpiredAt?: Maybe<Scalars['DateTime']>;
  sessionsExpiredAt_not?: Maybe<Scalars['DateTime']>;
  sessionsExpiredAt_in?: Maybe<Array<Scalars['DateTime']>>;
  sessionsExpiredAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  sessionsExpiredAt_lt?: Maybe<Scalars['DateTime']>;
  sessionsExpiredAt_lte?: Maybe<Scalars['DateTime']>;
  sessionsExpiredAt_gt?: Maybe<Scalars['DateTime']>;
  sessionsExpiredAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  flc_id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type VatReportCreateInput = {
  createdBy: UserWhereUniqueInput;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  merchant: MerchantWhereUniqueInput;
};

export type VatReportResult = {
  __typename?: 'VatReportResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type WeeblyConfig = {
  __typename?: 'WeeblyConfig';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
  weeblyUserId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WeeblyConfigConnection = {
  __typename?: 'WeeblyConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WeeblyConfigEdge>>;
  aggregate: AggregateWeeblyConfig;
};

export type WeeblyConfigCreateInput = {
  merchant?: Maybe<WeeblyMerchantCreateOneInput>;
  weeblyUserId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  siteEmail?: Maybe<Scalars['String']>;
};

export type WeeblyConfigEdge = {
  __typename?: 'WeeblyConfigEdge';
  node: WeeblyConfig;
  cursor: Scalars['String'];
};

export enum WeeblyConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  WeeblyUserIdAsc = 'weeblyUserId_ASC',
  WeeblyUserIdDesc = 'weeblyUserId_DESC',
  SiteIdAsc = 'siteId_ASC',
  SiteIdDesc = 'siteId_DESC',
  DomainAsc = 'domain_ASC',
  DomainDesc = 'domain_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  PublishedAsc = 'published_ASC',
  PublishedDesc = 'published_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export type WeeblyConfigPreviousValues = {
  __typename?: 'WeeblyConfigPreviousValues';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  weeblyUserId?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WeeblyConfigSubscriptionPayload = {
  __typename?: 'WeeblyConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WeeblyConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WeeblyConfigPreviousValues>;
};

export type WeeblyConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WeeblyConfigWhereInput>;
  AND?: Maybe<Array<WeeblyConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<WeeblyConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WeeblyConfigSubscriptionWhereInput>>;
};

export type WeeblyConfigUpdateInput = {
  email?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantUpdateOneInput>;
  weeblyUserId?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WeeblyConfigUpdateManyMutationInput = {
  email?: Maybe<Scalars['String']>;
  weeblyUserId?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type WeeblyConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Scalars['String']>>;
  email_not_in?: Maybe<Array<Scalars['String']>>;
  email_lt?: Maybe<Scalars['String']>;
  email_lte?: Maybe<Scalars['String']>;
  email_gt?: Maybe<Scalars['String']>;
  email_gte?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereInput>;
  weeblyUserId?: Maybe<Scalars['Int']>;
  weeblyUserId_not?: Maybe<Scalars['Int']>;
  weeblyUserId_in?: Maybe<Array<Scalars['Int']>>;
  weeblyUserId_not_in?: Maybe<Array<Scalars['Int']>>;
  weeblyUserId_lt?: Maybe<Scalars['Int']>;
  weeblyUserId_lte?: Maybe<Scalars['Int']>;
  weeblyUserId_gt?: Maybe<Scalars['Int']>;
  weeblyUserId_gte?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
  siteId_not?: Maybe<Scalars['Int']>;
  siteId_in?: Maybe<Array<Scalars['Int']>>;
  siteId_not_in?: Maybe<Array<Scalars['Int']>>;
  siteId_lt?: Maybe<Scalars['Int']>;
  siteId_lte?: Maybe<Scalars['Int']>;
  siteId_gt?: Maybe<Scalars['Int']>;
  siteId_gte?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
  domain_not?: Maybe<Scalars['String']>;
  domain_in?: Maybe<Array<Scalars['String']>>;
  domain_not_in?: Maybe<Array<Scalars['String']>>;
  domain_lt?: Maybe<Scalars['String']>;
  domain_lte?: Maybe<Scalars['String']>;
  domain_gt?: Maybe<Scalars['String']>;
  domain_gte?: Maybe<Scalars['String']>;
  domain_contains?: Maybe<Scalars['String']>;
  domain_not_contains?: Maybe<Scalars['String']>;
  domain_starts_with?: Maybe<Scalars['String']>;
  domain_not_starts_with?: Maybe<Scalars['String']>;
  domain_ends_with?: Maybe<Scalars['String']>;
  domain_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  published_not?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<WeeblyConfigWhereInput>>;
  OR?: Maybe<Array<WeeblyConfigWhereInput>>;
  NOT?: Maybe<Array<WeeblyConfigWhereInput>>;
};

export type WeeblyConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  weeblyUserId?: Maybe<Scalars['Int']>;
  siteId?: Maybe<Scalars['Int']>;
  domain?: Maybe<Scalars['String']>;
};

export type WeeblyLoginLink = {
  __typename?: 'WeeblyLoginLink';
  link?: Maybe<Scalars['String']>;
};

export type WeeblyLoginLinkInput = {
  merchant?: Maybe<WeeblyMerchantCreateOneInput>;
};

export type WeeblyMerchantCreateOneInput = {
  connect?: Maybe<MerchantWhereUniqueInput>;
};

export type WeeblyPublishSiteInput = {
  merchant?: Maybe<WeeblyMerchantCreateOneInput>;
};

export type WholesaleAddressResponse = {
  __typename?: 'WholesaleAddressResponse';
  response: Scalars['String'];
};

export type WholesaleCategory = {
  __typename?: 'WholesaleCategory';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  applicationGroup?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type WholesaleCategoryConnection = {
  __typename?: 'WholesaleCategoryConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesaleCategoryEdge>>;
  aggregate: AggregateWholesaleCategory;
};

export type WholesaleCategoryCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  applicationGroup?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type WholesaleCategoryEdge = {
  __typename?: 'WholesaleCategoryEdge';
  node: WholesaleCategory;
  cursor: Scalars['String'];
};

export enum WholesaleCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ApplicationGroupAsc = 'applicationGroup_ASC',
  ApplicationGroupDesc = 'applicationGroup_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC'
}

export type WholesaleCategoryPreviousValues = {
  __typename?: 'WholesaleCategoryPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  applicationGroup?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type WholesaleCategorySubscriptionPayload = {
  __typename?: 'WholesaleCategorySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WholesaleCategory>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesaleCategoryPreviousValues>;
};

export type WholesaleCategorySubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesaleCategoryWhereInput>;
  AND?: Maybe<Array<WholesaleCategorySubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesaleCategorySubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesaleCategorySubscriptionWhereInput>>;
};

export type WholesaleCategoryUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  applicationGroup?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type WholesaleCategoryUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  applicationGroup?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type WholesaleCategoryWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Scalars['String']>>;
  title_not_in?: Maybe<Array<Scalars['String']>>;
  title_lt?: Maybe<Scalars['String']>;
  title_lte?: Maybe<Scalars['String']>;
  title_gt?: Maybe<Scalars['String']>;
  title_gte?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  applicationGroup?: Maybe<Scalars['String']>;
  applicationGroup_not?: Maybe<Scalars['String']>;
  applicationGroup_in?: Maybe<Array<Scalars['String']>>;
  applicationGroup_not_in?: Maybe<Array<Scalars['String']>>;
  applicationGroup_lt?: Maybe<Scalars['String']>;
  applicationGroup_lte?: Maybe<Scalars['String']>;
  applicationGroup_gt?: Maybe<Scalars['String']>;
  applicationGroup_gte?: Maybe<Scalars['String']>;
  applicationGroup_contains?: Maybe<Scalars['String']>;
  applicationGroup_not_contains?: Maybe<Scalars['String']>;
  applicationGroup_starts_with?: Maybe<Scalars['String']>;
  applicationGroup_not_starts_with?: Maybe<Scalars['String']>;
  applicationGroup_ends_with?: Maybe<Scalars['String']>;
  applicationGroup_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<WholesaleCategoryWhereInput>>;
  OR?: Maybe<Array<WholesaleCategoryWhereInput>>;
  NOT?: Maybe<Array<WholesaleCategoryWhereInput>>;
};

export type WholesaleCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type WholesaleDelivery = {
  __typename?: 'WholesaleDelivery';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryDate: Scalars['DateTime'];
  cutOffDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  costIncluded: Scalars['Boolean'];
  costValue?: Maybe<Scalars['Float']>;
  deliveredOnDate?: Maybe<Scalars['DateTime']>;
  order?: Maybe<WholesaleOrder>;
  modifiedBy?: Maybe<User>;
};

export type WholesaleDeliveryConfig = {
  __typename?: 'WholesaleDeliveryConfig';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  address: Address;
  rijnsCode?: Maybe<Scalars['String']>;
  rijnsDeliveryType: RijnsDeliveryType;
  hubs?: Maybe<Array<Maybe<SupplierDeliveryHub>>>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  merchant?: Maybe<Merchant>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
};

export type WholesaleDeliveryConfigConnection = {
  __typename?: 'WholesaleDeliveryConfigConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesaleDeliveryConfigEdge>>;
  aggregate: AggregateWholesaleDeliveryConfig;
};

export type WholesaleDeliveryConfigCreateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  address: AddressCreateOneInput;
  rijnsCode?: Maybe<Scalars['String']>;
  rijnsDeliveryType?: Maybe<RijnsDeliveryType>;
  hubs?: Maybe<SupplierDeliveryHubUpdateManyInput>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
};

export type WholesaleDeliveryConfigCreateManyInput = {
  create?: Maybe<Array<WholesaleDeliveryConfigCreateInput>>;
  connect?: Maybe<Array<WholesaleDeliveryConfigWhereUniqueInput>>;
};

export type WholesaleDeliveryConfigCreateManyWithoutMerchantInput = {
  create?: Maybe<Array<WholesaleDeliveryConfigCreateWithoutMerchantInput>>;
  connect?: Maybe<Array<WholesaleDeliveryConfigWhereUniqueInput>>;
};

export type WholesaleDeliveryConfigCreateWithoutMerchantInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  address?: Maybe<AddressCreateOneInput>;
  rijnsCode?: Maybe<Scalars['String']>;
  hubs?: Maybe<SupplierDeliveryHubUpdateManyInput>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
};

export type WholesaleDeliveryConfigEdge = {
  __typename?: 'WholesaleDeliveryConfigEdge';
  node: WholesaleDeliveryConfig;
  cursor: Scalars['String'];
};

export enum WholesaleDeliveryConfigOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  RijnsCodeAsc = 'rijnsCode_ASC',
  RijnsCodeDesc = 'rijnsCode_DESC',
  RijnsDeliveryTypeAsc = 'rijnsDeliveryType_ASC',
  RijnsDeliveryTypeDesc = 'rijnsDeliveryType_DESC',
  DeliveryInstructionsAsc = 'deliveryInstructions_ASC',
  DeliveryInstructionsDesc = 'deliveryInstructions_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC'
}

export type WholesaleDeliveryConfigPreviousValues = {
  __typename?: 'WholesaleDeliveryConfigPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  rijnsCode?: Maybe<Scalars['String']>;
  rijnsDeliveryType: RijnsDeliveryType;
  hubs?: Maybe<Array<Maybe<SupplierDeliveryHub>>>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type WholesaleDeliveryConfigScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  rijnsCode?: Maybe<Scalars['String']>;
  rijnsCode_not?: Maybe<Scalars['String']>;
  rijnsCode_in?: Maybe<Array<Scalars['String']>>;
  rijnsCode_not_in?: Maybe<Array<Scalars['String']>>;
  rijnsCode_lt?: Maybe<Scalars['String']>;
  rijnsCode_lte?: Maybe<Scalars['String']>;
  rijnsCode_gt?: Maybe<Scalars['String']>;
  rijnsCode_gte?: Maybe<Scalars['String']>;
  rijnsCode_contains?: Maybe<Scalars['String']>;
  rijnsCode_not_contains?: Maybe<Scalars['String']>;
  rijnsCode_starts_with?: Maybe<Scalars['String']>;
  rijnsCode_not_starts_with?: Maybe<Scalars['String']>;
  rijnsCode_ends_with?: Maybe<Scalars['String']>;
  rijnsCode_not_ends_with?: Maybe<Scalars['String']>;
  rijnsDeliveryType?: Maybe<RijnsDeliveryType>;
  rijnsDeliveryType_not?: Maybe<RijnsDeliveryType>;
  rijnsDeliveryType_in?: Maybe<Array<RijnsDeliveryType>>;
  rijnsDeliveryType_not_in?: Maybe<Array<RijnsDeliveryType>>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliveryInstructions_not?: Maybe<Scalars['String']>;
  deliveryInstructions_in?: Maybe<Array<Scalars['String']>>;
  deliveryInstructions_not_in?: Maybe<Array<Scalars['String']>>;
  deliveryInstructions_lt?: Maybe<Scalars['String']>;
  deliveryInstructions_lte?: Maybe<Scalars['String']>;
  deliveryInstructions_gt?: Maybe<Scalars['String']>;
  deliveryInstructions_gte?: Maybe<Scalars['String']>;
  deliveryInstructions_contains?: Maybe<Scalars['String']>;
  deliveryInstructions_not_contains?: Maybe<Scalars['String']>;
  deliveryInstructions_starts_with?: Maybe<Scalars['String']>;
  deliveryInstructions_not_starts_with?: Maybe<Scalars['String']>;
  deliveryInstructions_ends_with?: Maybe<Scalars['String']>;
  deliveryInstructions_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<WholesaleDeliveryConfigScalarWhereInput>>;
  OR?: Maybe<Array<WholesaleDeliveryConfigScalarWhereInput>>;
  NOT?: Maybe<Array<WholesaleDeliveryConfigScalarWhereInput>>;
};

export type WholesaleDeliveryConfigSubscriptionPayload = {
  __typename?: 'WholesaleDeliveryConfigSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WholesaleDeliveryConfig>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesaleDeliveryConfigPreviousValues>;
};

export type WholesaleDeliveryConfigSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesaleDeliveryConfigWhereInput>;
  AND?: Maybe<Array<WholesaleDeliveryConfigSubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesaleDeliveryConfigSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesaleDeliveryConfigSubscriptionWhereInput>>;
};

export type WholesaleDeliveryConfigUpdateDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  address?: Maybe<AddressUpdateOneRequiredInput>;
  rijnsCode?: Maybe<Scalars['String']>;
  rijnsDeliveryType?: Maybe<RijnsDeliveryType>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  componentConfig?: Maybe<ComponentConfigUpdateOneInput>;
};

export type WholesaleDeliveryConfigUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  address?: Maybe<AddressUpdateOneRequiredInput>;
  rijnsCode?: Maybe<Scalars['String']>;
  rijnsDeliveryType?: Maybe<RijnsDeliveryType>;
  hubs?: Maybe<SupplierDeliveryHubUpdateManyInput>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  componentConfig?: Maybe<ComponentConfigUpdateOneInput>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
};

export type WholesaleDeliveryConfigUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  rijnsCode?: Maybe<Scalars['String']>;
  rijnsDeliveryType?: Maybe<RijnsDeliveryType>;
  hubs?: Maybe<SupplierDeliveryHubUpdateManyInput>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type WholesaleDeliveryConfigUpdateManyInput = {
  create?: Maybe<Array<WholesaleDeliveryConfigCreateInput>>;
  update?: Maybe<Array<WholesaleDeliveryConfigUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<WholesaleDeliveryConfigUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<WholesaleDeliveryConfigWhereUniqueInput>>;
  connect?: Maybe<Array<WholesaleDeliveryConfigWhereUniqueInput>>;
  set?: Maybe<Array<WholesaleDeliveryConfigWhereUniqueInput>>;
  disconnect?: Maybe<Array<WholesaleDeliveryConfigWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WholesaleDeliveryConfigScalarWhereInput>>;
  updateMany?: Maybe<Array<WholesaleDeliveryConfigUpdateManyWithWhereNestedInput>>;
};

export type WholesaleDeliveryConfigUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  rijnsCode?: Maybe<Scalars['String']>;
  rijnsDeliveryType?: Maybe<RijnsDeliveryType>;
  hubs?: Maybe<SupplierDeliveryHubUpdateManyInput>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

export type WholesaleDeliveryConfigUpdateManyWithWhereNestedInput = {
  where: WholesaleDeliveryConfigScalarWhereInput;
  data: WholesaleDeliveryConfigUpdateManyDataInput;
};

export type WholesaleDeliveryConfigUpdateWithWhereUniqueNestedInput = {
  where: WholesaleDeliveryConfigWhereUniqueInput;
  data: WholesaleDeliveryConfigUpdateDataInput;
};

export type WholesaleDeliveryConfigUpsertWithWhereUniqueNestedInput = {
  where: WholesaleDeliveryConfigWhereUniqueInput;
  update: WholesaleDeliveryConfigUpdateDataInput;
  create: WholesaleDeliveryConfigCreateInput;
};

export type WholesaleDeliveryConfigWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  address?: Maybe<AddressWhereInput>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
  rijnsCode?: Maybe<Scalars['String']>;
  rijnsCode_not?: Maybe<Scalars['String']>;
  rijnsCode_in?: Maybe<Array<Scalars['String']>>;
  rijnsCode_not_in?: Maybe<Array<Scalars['String']>>;
  rijnsCode_lt?: Maybe<Scalars['String']>;
  rijnsCode_lte?: Maybe<Scalars['String']>;
  rijnsCode_gt?: Maybe<Scalars['String']>;
  rijnsCode_gte?: Maybe<Scalars['String']>;
  rijnsCode_contains?: Maybe<Scalars['String']>;
  rijnsCode_not_contains?: Maybe<Scalars['String']>;
  rijnsCode_starts_with?: Maybe<Scalars['String']>;
  rijnsCode_not_starts_with?: Maybe<Scalars['String']>;
  rijnsCode_ends_with?: Maybe<Scalars['String']>;
  rijnsCode_not_ends_with?: Maybe<Scalars['String']>;
  rijnsDeliveryType?: Maybe<RijnsDeliveryType>;
  rijnsDeliveryType_not?: Maybe<RijnsDeliveryType>;
  rijnsDeliveryType_in?: Maybe<Array<RijnsDeliveryType>>;
  rijnsDeliveryType_not_in?: Maybe<Array<RijnsDeliveryType>>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  deliveryInstructions_not?: Maybe<Scalars['String']>;
  deliveryInstructions_in?: Maybe<Array<Scalars['String']>>;
  deliveryInstructions_not_in?: Maybe<Array<Scalars['String']>>;
  deliveryInstructions_lt?: Maybe<Scalars['String']>;
  deliveryInstructions_lte?: Maybe<Scalars['String']>;
  deliveryInstructions_gt?: Maybe<Scalars['String']>;
  deliveryInstructions_gte?: Maybe<Scalars['String']>;
  deliveryInstructions_contains?: Maybe<Scalars['String']>;
  deliveryInstructions_not_contains?: Maybe<Scalars['String']>;
  deliveryInstructions_starts_with?: Maybe<Scalars['String']>;
  deliveryInstructions_not_starts_with?: Maybe<Scalars['String']>;
  deliveryInstructions_ends_with?: Maybe<Scalars['String']>;
  deliveryInstructions_not_ends_with?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  active_not?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isConfirmed_not?: Maybe<Scalars['Boolean']>;
  componentConfig?: Maybe<ComponentConfigWhereInput>;
  AND?: Maybe<Array<WholesaleDeliveryConfigWhereInput>>;
  OR?: Maybe<Array<WholesaleDeliveryConfigWhereInput>>;
  NOT?: Maybe<Array<WholesaleDeliveryConfigWhereInput>>;
};

export type WholesaleDeliveryConfigWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type WholesaleDeliveryConnection = {
  __typename?: 'WholesaleDeliveryConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesaleDeliveryEdge>>;
  aggregate: AggregateWholesaleDelivery;
};

export type WholesaleDeliveryCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryDate: Scalars['DateTime'];
  status: Scalars['String'];
  costIncluded: Scalars['Boolean'];
  costValue?: Maybe<Scalars['Float']>;
  deliveredOnDate?: Maybe<Scalars['DateTime']>;
  order?: Maybe<WholesaleOrderCreateOneWithoutDeliveryDetailsInput>;
};

export type WholesaleDeliveryCreateManyWithoutOrderInput = {
  create?: Maybe<Array<WholesaleDeliveryCreateWithoutOrderInput>>;
  connect?: Maybe<Array<WholesaleDeliveryWhereUniqueInput>>;
};

export type WholesaleDeliveryCreateWithoutOrderInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryDate: Scalars['DateTime'];
  status: Scalars['String'];
  costIncluded: Scalars['Boolean'];
  costValue?: Maybe<Scalars['Float']>;
  deliveredOnDate?: Maybe<Scalars['DateTime']>;
};

export type WholesaleDeliveryEdge = {
  __typename?: 'WholesaleDeliveryEdge';
  node: WholesaleDelivery;
  cursor: Scalars['String'];
};

export enum WholesaleDeliveryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DeliveryDateAsc = 'deliveryDate_ASC',
  DeliveryDateDesc = 'deliveryDate_DESC',
  CutOffDateAsc = 'cutOffDate_ASC',
  CutOffDateDesc = 'cutOffDate_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  CostIncludedAsc = 'costIncluded_ASC',
  CostIncludedDesc = 'costIncluded_DESC',
  CostValueAsc = 'costValue_ASC',
  CostValueDesc = 'costValue_DESC',
  DeliveredOnDateAsc = 'deliveredOnDate_ASC',
  DeliveredOnDateDesc = 'deliveredOnDate_DESC'
}

export type WholesaleDeliveryPreviousValues = {
  __typename?: 'WholesaleDeliveryPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryDate: Scalars['DateTime'];
  status: Scalars['String'];
  costIncluded: Scalars['Boolean'];
  costValue?: Maybe<Scalars['Float']>;
  deliveredOnDate?: Maybe<Scalars['DateTime']>;
};

export type WholesaleDeliveryScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  deliveryDate_not?: Maybe<Scalars['DateTime']>;
  deliveryDate_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveryDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveryDate_lt?: Maybe<Scalars['DateTime']>;
  deliveryDate_lte?: Maybe<Scalars['DateTime']>;
  deliveryDate_gt?: Maybe<Scalars['DateTime']>;
  deliveryDate_gte?: Maybe<Scalars['DateTime']>;
  cutOffDate?: Maybe<Scalars['DateTime']>;
  cutOffDate_not?: Maybe<Scalars['DateTime']>;
  cutOffDate_in?: Maybe<Array<Scalars['DateTime']>>;
  cutOffDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  cutOffDate_lt?: Maybe<Scalars['DateTime']>;
  cutOffDate_lte?: Maybe<Scalars['DateTime']>;
  cutOffDate_gt?: Maybe<Scalars['DateTime']>;
  cutOffDate_gte?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Scalars['String']>>;
  status_not_in?: Maybe<Array<Scalars['String']>>;
  status_lt?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  costIncluded?: Maybe<Scalars['Boolean']>;
  costIncluded_not?: Maybe<Scalars['Boolean']>;
  costValue?: Maybe<Scalars['Float']>;
  costValue_not?: Maybe<Scalars['Float']>;
  costValue_in?: Maybe<Array<Scalars['Float']>>;
  costValue_not_in?: Maybe<Array<Scalars['Float']>>;
  costValue_lt?: Maybe<Scalars['Float']>;
  costValue_lte?: Maybe<Scalars['Float']>;
  costValue_gt?: Maybe<Scalars['Float']>;
  costValue_gte?: Maybe<Scalars['Float']>;
  deliveredOnDate?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_not?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveredOnDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveredOnDate_lt?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_lte?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_gt?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_gte?: Maybe<Scalars['DateTime']>;
  AND?: Maybe<Array<WholesaleDeliveryScalarWhereInput>>;
  OR?: Maybe<Array<WholesaleDeliveryScalarWhereInput>>;
  NOT?: Maybe<Array<WholesaleDeliveryScalarWhereInput>>;
};

export type WholesaleDeliverySubscriptionPayload = {
  __typename?: 'WholesaleDeliverySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WholesaleDelivery>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesaleDeliveryPreviousValues>;
};

export type WholesaleDeliverySubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesaleDeliveryWhereInput>;
  AND?: Maybe<Array<WholesaleDeliverySubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesaleDeliverySubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesaleDeliverySubscriptionWhereInput>>;
};

export type WholesaleDeliveryUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  costIncluded?: Maybe<Scalars['Boolean']>;
  costValue?: Maybe<Scalars['Float']>;
  deliveredOnDate?: Maybe<Scalars['DateTime']>;
};

export type WholesaleDeliveryUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  costIncluded?: Maybe<Scalars['Boolean']>;
  costValue?: Maybe<Scalars['Float']>;
  deliveredOnDate?: Maybe<Scalars['DateTime']>;
};

export type WholesaleDeliveryUpdateManyWithWhereNestedInput = {
  where: WholesaleDeliveryScalarWhereInput;
  data: WholesaleDeliveryUpdateManyDataInput;
};

export type WholesaleDeliveryUpdateManyWithoutOrderInput = {
  create?: Maybe<Array<WholesaleDeliveryCreateWithoutOrderInput>>;
  delete?: Maybe<Array<WholesaleDeliveryWhereUniqueInput>>;
  connect?: Maybe<Array<WholesaleDeliveryWhereUniqueInput>>;
  set?: Maybe<Array<WholesaleDeliveryWhereUniqueInput>>;
  disconnect?: Maybe<Array<WholesaleDeliveryWhereUniqueInput>>;
  update?: Maybe<Array<WholesaleDeliveryUpdateWithWhereUniqueWithoutOrderInput>>;
  upsert?: Maybe<Array<WholesaleDeliveryUpsertWithWhereUniqueWithoutOrderInput>>;
  deleteMany?: Maybe<Array<WholesaleDeliveryScalarWhereInput>>;
  updateMany?: Maybe<Array<WholesaleDeliveryUpdateManyWithWhereNestedInput>>;
};

export type WholesaleDeliveryUpdateWithWhereUniqueWithoutOrderInput = {
  where: WholesaleDeliveryWhereUniqueInput;
  data: WholesaleDeliveryUpdateWithoutOrderDataInput;
};

export type WholesaleDeliveryUpdateWithoutOrderDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  costIncluded?: Maybe<Scalars['Boolean']>;
  costValue?: Maybe<Scalars['Float']>;
  deliveredOnDate?: Maybe<Scalars['DateTime']>;
};

export type WholesaleDeliveryUpsertWithWhereUniqueWithoutOrderInput = {
  where: WholesaleDeliveryWhereUniqueInput;
  update: WholesaleDeliveryUpdateWithoutOrderDataInput;
  create: WholesaleDeliveryCreateWithoutOrderInput;
};

export type WholesaleDeliveryWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  deliveryDate_not?: Maybe<Scalars['DateTime']>;
  deliveryDate_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveryDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveryDate_lt?: Maybe<Scalars['DateTime']>;
  deliveryDate_lte?: Maybe<Scalars['DateTime']>;
  deliveryDate_gt?: Maybe<Scalars['DateTime']>;
  deliveryDate_gte?: Maybe<Scalars['DateTime']>;
  cutOffDate?: Maybe<Scalars['DateTime']>;
  cutOffDate_not?: Maybe<Scalars['DateTime']>;
  cutOffDate_in?: Maybe<Array<Scalars['DateTime']>>;
  cutOffDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  cutOffDate_lt?: Maybe<Scalars['DateTime']>;
  cutOffDate_lte?: Maybe<Scalars['DateTime']>;
  cutOffDate_gt?: Maybe<Scalars['DateTime']>;
  cutOffDate_gte?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Scalars['String']>>;
  status_not_in?: Maybe<Array<Scalars['String']>>;
  status_lt?: Maybe<Scalars['String']>;
  status_lte?: Maybe<Scalars['String']>;
  status_gt?: Maybe<Scalars['String']>;
  status_gte?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  costIncluded?: Maybe<Scalars['Boolean']>;
  costIncluded_not?: Maybe<Scalars['Boolean']>;
  costValue?: Maybe<Scalars['Float']>;
  costValue_not?: Maybe<Scalars['Float']>;
  costValue_in?: Maybe<Array<Scalars['Float']>>;
  costValue_not_in?: Maybe<Array<Scalars['Float']>>;
  costValue_lt?: Maybe<Scalars['Float']>;
  costValue_lte?: Maybe<Scalars['Float']>;
  costValue_gt?: Maybe<Scalars['Float']>;
  costValue_gte?: Maybe<Scalars['Float']>;
  deliveredOnDate?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_not?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveredOnDate_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deliveredOnDate_lt?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_lte?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_gt?: Maybe<Scalars['DateTime']>;
  deliveredOnDate_gte?: Maybe<Scalars['DateTime']>;
  order?: Maybe<WholesaleOrderWhereInput>;
  AND?: Maybe<Array<WholesaleDeliveryWhereInput>>;
  OR?: Maybe<Array<WholesaleDeliveryWhereInput>>;
  NOT?: Maybe<Array<WholesaleDeliveryWhereInput>>;
};

export type WholesaleDeliveryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type WholesaleFavourite = {
  __typename?: 'WholesaleFavourite';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  purchaseFrequency: Scalars['Int'];
  type: WholesaleItemType;
  subType?: Maybe<Scalars['String']>;
  titleAgg?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  merchant: Merchant;
};

export type WholesaleFavouriteConnection = {
  __typename?: 'WholesaleFavouriteConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesaleFavouriteEdge>>;
  aggregate: AggregateWholesaleFavourite;
};

export type WholesaleFavouriteCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserCreateOneInput>;
  purchaseFrequency?: Maybe<Scalars['Int']>;
  type: WholesaleItemType;
  subType?: Maybe<Scalars['String']>;
  titleAgg?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  merchant: MerchantCreateOneInput;
};

export type WholesaleFavouriteEdge = {
  __typename?: 'WholesaleFavouriteEdge';
  node: WholesaleFavourite;
  cursor: Scalars['String'];
};

export enum WholesaleFavouriteOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  PurchaseFrequencyAsc = 'purchaseFrequency_ASC',
  PurchaseFrequencyDesc = 'purchaseFrequency_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  SubTypeAsc = 'subType_ASC',
  SubTypeDesc = 'subType_DESC',
  TitleAggAsc = 'titleAgg_ASC',
  TitleAggDesc = 'titleAgg_DESC',
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  GenusAsc = 'genus_ASC',
  GenusDesc = 'genus_DESC',
  ImageUrlAsc = 'imageUrl_ASC',
  ImageUrlDesc = 'imageUrl_DESC'
}

export type WholesaleFavouritePreviousValues = {
  __typename?: 'WholesaleFavouritePreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  purchaseFrequency: Scalars['Int'];
  type: WholesaleItemType;
  subType?: Maybe<Scalars['String']>;
  titleAgg?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type WholesaleFavouriteSubscriptionPayload = {
  __typename?: 'WholesaleFavouriteSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WholesaleFavourite>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesaleFavouritePreviousValues>;
};

export type WholesaleFavouriteSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesaleFavouriteWhereInput>;
  AND?: Maybe<Array<WholesaleFavouriteSubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesaleFavouriteSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesaleFavouriteSubscriptionWhereInput>>;
};

export type WholesaleFavouriteUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  purchaseFrequency?: Maybe<Scalars['Int']>;
  type?: Maybe<WholesaleItemType>;
  subType?: Maybe<Scalars['String']>;
  titleAgg?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantUpdateOneRequiredInput>;
};

export type WholesaleFavouriteUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  purchaseFrequency?: Maybe<Scalars['Int']>;
  type?: Maybe<WholesaleItemType>;
  subType?: Maybe<Scalars['String']>;
  titleAgg?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};

export type WholesaleFavouriteWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<UserWhereInput>;
  purchaseFrequency?: Maybe<Scalars['Int']>;
  purchaseFrequency_not?: Maybe<Scalars['Int']>;
  purchaseFrequency_in?: Maybe<Array<Scalars['Int']>>;
  purchaseFrequency_not_in?: Maybe<Array<Scalars['Int']>>;
  purchaseFrequency_lt?: Maybe<Scalars['Int']>;
  purchaseFrequency_lte?: Maybe<Scalars['Int']>;
  purchaseFrequency_gt?: Maybe<Scalars['Int']>;
  purchaseFrequency_gte?: Maybe<Scalars['Int']>;
  type?: Maybe<WholesaleItemType>;
  type_not?: Maybe<WholesaleItemType>;
  type_in?: Maybe<Array<WholesaleItemType>>;
  type_not_in?: Maybe<Array<WholesaleItemType>>;
  subType?: Maybe<Scalars['String']>;
  subType_not?: Maybe<Scalars['String']>;
  subType_in?: Maybe<Array<Scalars['String']>>;
  subType_not_in?: Maybe<Array<Scalars['String']>>;
  subType_lt?: Maybe<Scalars['String']>;
  subType_lte?: Maybe<Scalars['String']>;
  subType_gt?: Maybe<Scalars['String']>;
  subType_gte?: Maybe<Scalars['String']>;
  subType_contains?: Maybe<Scalars['String']>;
  subType_not_contains?: Maybe<Scalars['String']>;
  subType_starts_with?: Maybe<Scalars['String']>;
  subType_not_starts_with?: Maybe<Scalars['String']>;
  subType_ends_with?: Maybe<Scalars['String']>;
  subType_not_ends_with?: Maybe<Scalars['String']>;
  titleAgg?: Maybe<Scalars['String']>;
  titleAgg_not?: Maybe<Scalars['String']>;
  titleAgg_in?: Maybe<Array<Scalars['String']>>;
  titleAgg_not_in?: Maybe<Array<Scalars['String']>>;
  titleAgg_lt?: Maybe<Scalars['String']>;
  titleAgg_lte?: Maybe<Scalars['String']>;
  titleAgg_gt?: Maybe<Scalars['String']>;
  titleAgg_gte?: Maybe<Scalars['String']>;
  titleAgg_contains?: Maybe<Scalars['String']>;
  titleAgg_not_contains?: Maybe<Scalars['String']>;
  titleAgg_starts_with?: Maybe<Scalars['String']>;
  titleAgg_not_starts_with?: Maybe<Scalars['String']>;
  titleAgg_ends_with?: Maybe<Scalars['String']>;
  titleAgg_not_ends_with?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  color_not?: Maybe<Scalars['String']>;
  color_in?: Maybe<Array<Scalars['String']>>;
  color_not_in?: Maybe<Array<Scalars['String']>>;
  color_lt?: Maybe<Scalars['String']>;
  color_lte?: Maybe<Scalars['String']>;
  color_gt?: Maybe<Scalars['String']>;
  color_gte?: Maybe<Scalars['String']>;
  color_contains?: Maybe<Scalars['String']>;
  color_not_contains?: Maybe<Scalars['String']>;
  color_starts_with?: Maybe<Scalars['String']>;
  color_not_starts_with?: Maybe<Scalars['String']>;
  color_ends_with?: Maybe<Scalars['String']>;
  color_not_ends_with?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  genus_not?: Maybe<Scalars['String']>;
  genus_in?: Maybe<Array<Scalars['String']>>;
  genus_not_in?: Maybe<Array<Scalars['String']>>;
  genus_lt?: Maybe<Scalars['String']>;
  genus_lte?: Maybe<Scalars['String']>;
  genus_gt?: Maybe<Scalars['String']>;
  genus_gte?: Maybe<Scalars['String']>;
  genus_contains?: Maybe<Scalars['String']>;
  genus_not_contains?: Maybe<Scalars['String']>;
  genus_starts_with?: Maybe<Scalars['String']>;
  genus_not_starts_with?: Maybe<Scalars['String']>;
  genus_ends_with?: Maybe<Scalars['String']>;
  genus_not_ends_with?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageUrl_not?: Maybe<Scalars['String']>;
  imageUrl_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_not_in?: Maybe<Array<Scalars['String']>>;
  imageUrl_lt?: Maybe<Scalars['String']>;
  imageUrl_lte?: Maybe<Scalars['String']>;
  imageUrl_gt?: Maybe<Scalars['String']>;
  imageUrl_gte?: Maybe<Scalars['String']>;
  imageUrl_contains?: Maybe<Scalars['String']>;
  imageUrl_not_contains?: Maybe<Scalars['String']>;
  imageUrl_starts_with?: Maybe<Scalars['String']>;
  imageUrl_not_starts_with?: Maybe<Scalars['String']>;
  imageUrl_ends_with?: Maybe<Scalars['String']>;
  imageUrl_not_ends_with?: Maybe<Scalars['String']>;
  merchant?: Maybe<MerchantWhereInput>;
  AND?: Maybe<Array<WholesaleFavouriteWhereInput>>;
  OR?: Maybe<Array<WholesaleFavouriteWhereInput>>;
  NOT?: Maybe<Array<WholesaleFavouriteWhereInput>>;
};

export type WholesaleFavouriteWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type WholesaleFeature = {
  __typename?: 'WholesaleFeature';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: WholesaleFeatureType;
};

export type WholesaleFeatureConnection = {
  __typename?: 'WholesaleFeatureConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesaleFeatureEdge>>;
  aggregate: AggregateWholesaleFeature;
};

export type WholesaleFeatureCreateInput = {
  type: WholesaleFeatureType;
};

export type WholesaleFeatureCreateManyInput = {
  create?: Maybe<Array<WholesaleFeatureCreateInput>>;
  connect?: Maybe<Array<WholesaleFeatureWhereUniqueInput>>;
};

export type WholesaleFeatureEdge = {
  __typename?: 'WholesaleFeatureEdge';
  node: WholesaleFeature;
  cursor: Scalars['String'];
};

export enum WholesaleFeatureOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC'
}

export type WholesaleFeaturePreviousValues = {
  __typename?: 'WholesaleFeaturePreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: WholesaleFeatureType;
};

export type WholesaleFeatureScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<WholesaleFeatureType>;
  type_not?: Maybe<WholesaleFeatureType>;
  type_in?: Maybe<Array<WholesaleFeatureType>>;
  type_not_in?: Maybe<Array<WholesaleFeatureType>>;
  AND?: Maybe<Array<WholesaleFeatureScalarWhereInput>>;
  OR?: Maybe<Array<WholesaleFeatureScalarWhereInput>>;
  NOT?: Maybe<Array<WholesaleFeatureScalarWhereInput>>;
};

export type WholesaleFeatureSubscriptionPayload = {
  __typename?: 'WholesaleFeatureSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WholesaleFeature>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesaleFeaturePreviousValues>;
};

export type WholesaleFeatureSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesaleFeatureWhereInput>;
  AND?: Maybe<Array<WholesaleFeatureSubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesaleFeatureSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesaleFeatureSubscriptionWhereInput>>;
};

export enum WholesaleFeatureType {
  LiveOrder = 'LiveOrder',
  PreOrder = 'PreOrder'
}

export type WholesaleFeatureUpdateDataInput = {
  type?: Maybe<WholesaleFeatureType>;
};

export type WholesaleFeatureUpdateInput = {
  type?: Maybe<WholesaleFeatureType>;
};

export type WholesaleFeatureUpdateManyDataInput = {
  type?: Maybe<WholesaleFeatureType>;
};

export type WholesaleFeatureUpdateManyInput = {
  create?: Maybe<Array<WholesaleFeatureCreateInput>>;
  update?: Maybe<Array<WholesaleFeatureUpdateWithWhereUniqueNestedInput>>;
  upsert?: Maybe<Array<WholesaleFeatureUpsertWithWhereUniqueNestedInput>>;
  delete?: Maybe<Array<WholesaleFeatureWhereUniqueInput>>;
  connect?: Maybe<Array<WholesaleFeatureWhereUniqueInput>>;
  set?: Maybe<Array<WholesaleFeatureWhereUniqueInput>>;
  disconnect?: Maybe<Array<WholesaleFeatureWhereUniqueInput>>;
  deleteMany?: Maybe<Array<WholesaleFeatureScalarWhereInput>>;
  updateMany?: Maybe<Array<WholesaleFeatureUpdateManyWithWhereNestedInput>>;
};

export type WholesaleFeatureUpdateManyMutationInput = {
  type?: Maybe<WholesaleFeatureType>;
};

export type WholesaleFeatureUpdateManyWithWhereNestedInput = {
  where: WholesaleFeatureScalarWhereInput;
  data: WholesaleFeatureUpdateManyDataInput;
};

export type WholesaleFeatureUpdateWithWhereUniqueNestedInput = {
  where: WholesaleFeatureWhereUniqueInput;
  data: WholesaleFeatureUpdateDataInput;
};

export type WholesaleFeatureUpsertWithWhereUniqueNestedInput = {
  where: WholesaleFeatureWhereUniqueInput;
  update: WholesaleFeatureUpdateDataInput;
  create: WholesaleFeatureCreateInput;
};

export type WholesaleFeatureWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  type?: Maybe<WholesaleFeatureType>;
  type_not?: Maybe<WholesaleFeatureType>;
  type_in?: Maybe<Array<WholesaleFeatureType>>;
  type_not_in?: Maybe<Array<WholesaleFeatureType>>;
  AND?: Maybe<Array<WholesaleFeatureWhereInput>>;
  OR?: Maybe<Array<WholesaleFeatureWhereInput>>;
  NOT?: Maybe<Array<WholesaleFeatureWhereInput>>;
};

export type WholesaleFeatureWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum WholesaleItemType {
  Plants = 'Plants',
  Flowers = 'Flowers',
  Sundries = 'Sundries',
  Decorations = 'Decorations'
}

export type WholesaleItemsSearchInput = {
  searchString: Scalars['String'];
};

export type WholesaleItemsSearchResult = {
  __typename?: 'WholesaleItemsSearchResult';
  results?: Maybe<Array<Maybe<WholesaleTopLevelType>>>;
};

export type WholesaleOrder = {
  __typename?: 'WholesaleOrder';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  items?: Maybe<Array<WholesaleOrderLineItem>>;
  credits?: Maybe<Array<WholesaleOrderLineItem>>;
  deliveryDetails?: Maybe<Array<WholesaleDelivery>>;
  tax?: Maybe<OrderTax>;
  invoice?: Maybe<Invoice>;
  status: WholesaleOrderStates;
  paymentStatus?: Maybe<WholesalePaymentStatus>;
  wholesaleOrderRef?: Maybe<Scalars['String']>;
  wholesaleDeliveryConfigSnapshot?: Maybe<Scalars['Json']>;
  totalPrice?: Maybe<Scalars['Float']>;
  merchant: Merchant;
  preOrder?: Maybe<WholesalePreOrder>;
  supplier?: Maybe<Supplier>;
};


export type WholesaleOrderItemsArgs = {
  where?: Maybe<WholesaleOrderLineItemWhereInput>;
  orderBy?: Maybe<WholesaleOrderLineItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type WholesaleOrderCreditsArgs = {
  where?: Maybe<WholesaleOrderLineItemWhereInput>;
  orderBy?: Maybe<WholesaleOrderLineItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type WholesaleOrderDeliveryDetailsArgs = {
  where?: Maybe<WholesaleDeliveryWhereInput>;
  orderBy?: Maybe<WholesaleDeliveryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type WholesaleOrderCancelInput = {
  item?: Maybe<WholesaleOrderLineItemWhereUniqueInput>;
  version?: Maybe<WholesaleOrderResolverVersion>;
  order?: Maybe<WholesaleOrderWhereUniqueInput>;
};

export type WholesaleOrderConnection = {
  __typename?: 'WholesaleOrderConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesaleOrderEdge>>;
  aggregate: AggregateWholesaleOrder;
};

export type WholesaleOrderCreateInput = {
  id?: Maybe<Scalars['ID']>;
  items?: Maybe<WholesaleOrderLineItemCreateManyWithoutOrderInput>;
  deliveryDetails?: Maybe<WholesaleDeliveryCreateManyWithoutOrderInput>;
  invoice?: Maybe<InvoiceCreateOneInput>;
  status: WholesaleOrderStates;
  wholesaleOrderRef?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Float']>;
  merchant: MerchantCreateOneInput;
};

export type WholesaleOrderCreateOneInput = {
  create?: Maybe<WholesaleOrderCreateInput>;
  connect?: Maybe<WholesaleOrderWhereUniqueInput>;
};

export type WholesaleOrderCreateOneWithoutDeliveryDetailsInput = {
  create?: Maybe<WholesaleOrderCreateWithoutDeliveryDetailsInput>;
  connect?: Maybe<WholesaleOrderWhereUniqueInput>;
};

export type WholesaleOrderCreateOneWithoutItemsInput = {
  create?: Maybe<WholesaleOrderCreateWithoutItemsInput>;
  connect?: Maybe<WholesaleOrderWhereUniqueInput>;
};

export type WholesaleOrderCreateWithoutDeliveryDetailsInput = {
  id?: Maybe<Scalars['ID']>;
  items?: Maybe<WholesaleOrderLineItemCreateManyWithoutOrderInput>;
  invoice?: Maybe<InvoiceCreateOneInput>;
  status: WholesaleOrderStates;
  wholesaleOrderRef?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Float']>;
  merchant: MerchantCreateOneInput;
};

export type WholesaleOrderCreateWithoutItemsInput = {
  id?: Maybe<Scalars['ID']>;
  deliveryDetails?: Maybe<WholesaleDeliveryCreateManyWithoutOrderInput>;
  invoice?: Maybe<InvoiceCreateOneInput>;
  status: WholesaleOrderStates;
  wholesaleOrderRef?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Float']>;
  merchant: MerchantCreateOneInput;
};

export type WholesaleOrderEdge = {
  __typename?: 'WholesaleOrderEdge';
  node: WholesaleOrder;
  cursor: Scalars['String'];
};

export type WholesaleOrderInput = {
  items?: Maybe<Array<Maybe<WholesaleOrderLineItemCreateWithoutOrderInput>>>;
  snapshot?: Maybe<Scalars['Json']>;
  version?: Maybe<WholesaleOrderResolverVersion>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  cutOffDate?: Maybe<Scalars['DateTime']>;
  order?: Maybe<WholesaleOrderWhereUniqueInput>;
};

export type WholesaleOrderLineItem = {
  __typename?: 'WholesaleOrderLineItem';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  quantity: Scalars['Int'];
  totalPrice?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  wholesalerLineItemRef: Scalars['String'];
  wholesalerTrackingRef?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['Json']>;
  createdBy?: Maybe<User>;
  order?: Maybe<WholesaleOrder>;
  transaction: TransactionType;
  listItem?: Maybe<ListItemUnion>;
};

export type WholesaleOrderLineItemConnection = {
  __typename?: 'WholesaleOrderLineItemConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesaleOrderLineItemEdge>>;
  aggregate: AggregateWholesaleOrderLineItem;
};

export type WholesaleOrderLineItemCreateInput = {
  id?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  quantity: Scalars['Int'];
  totalPrice?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  wholesalerLineItemRef: Scalars['String'];
  wholesalerTrackingRef?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['Json']>;
  createdBy?: Maybe<UserUpdateOneInput>;
  order?: Maybe<WholesaleOrderCreateOneWithoutItemsInput>;
};

export type WholesaleOrderLineItemCreateManyInput = {
  create?: Maybe<Array<WholesaleOrderLineItemCreateInput>>;
  connect?: Maybe<Array<WholesaleOrderLineItemWhereUniqueInput>>;
};

export type WholesaleOrderLineItemCreateManyWithoutOrderInput = {
  create?: Maybe<Array<WholesaleOrderLineItemCreateWithoutOrderInput>>;
  connect?: Maybe<Array<WholesaleOrderLineItemWhereUniqueInput>>;
};

export type WholesaleOrderLineItemCreateWithoutOrderInput = {
  quantity: Scalars['Int'];
  wholesalerLineItemRef: Scalars['String'];
};

export type WholesaleOrderLineItemEdge = {
  __typename?: 'WholesaleOrderLineItemEdge';
  node: WholesaleOrderLineItem;
  cursor: Scalars['String'];
};

export enum WholesaleOrderLineItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  QuantityAsc = 'quantity_ASC',
  QuantityDesc = 'quantity_DESC',
  TotalPriceAsc = 'totalPrice_ASC',
  TotalPriceDesc = 'totalPrice_DESC',
  UnitPriceAsc = 'unitPrice_ASC',
  UnitPriceDesc = 'unitPrice_DESC',
  WholesalerLineItemRefAsc = 'wholesalerLineItemRef_ASC',
  WholesalerLineItemRefDesc = 'wholesalerLineItemRef_DESC',
  WholesalerTrackingRefAsc = 'wholesalerTrackingRef_ASC',
  WholesalerTrackingRefDesc = 'wholesalerTrackingRef_DESC',
  SnapshotAsc = 'snapshot_ASC',
  SnapshotDesc = 'snapshot_DESC'
}

export type WholesaleOrderLineItemPreviousValues = {
  __typename?: 'WholesaleOrderLineItemPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  quantity: Scalars['Int'];
  totalPrice?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  wholesalerLineItemRef: Scalars['String'];
  wholesalerTrackingRef?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['Json']>;
};

export type WholesaleOrderLineItemScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  quantity_not?: Maybe<Scalars['Int']>;
  quantity_in?: Maybe<Array<Scalars['Int']>>;
  quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  quantity_lt?: Maybe<Scalars['Int']>;
  quantity_lte?: Maybe<Scalars['Int']>;
  quantity_gt?: Maybe<Scalars['Int']>;
  quantity_gte?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalPrice_not?: Maybe<Scalars['Float']>;
  totalPrice_in?: Maybe<Array<Scalars['Float']>>;
  totalPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  totalPrice_lt?: Maybe<Scalars['Float']>;
  totalPrice_lte?: Maybe<Scalars['Float']>;
  totalPrice_gt?: Maybe<Scalars['Float']>;
  totalPrice_gte?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPrice_not?: Maybe<Scalars['Float']>;
  unitPrice_in?: Maybe<Array<Scalars['Float']>>;
  unitPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  unitPrice_lt?: Maybe<Scalars['Float']>;
  unitPrice_lte?: Maybe<Scalars['Float']>;
  unitPrice_gt?: Maybe<Scalars['Float']>;
  unitPrice_gte?: Maybe<Scalars['Float']>;
  wholesalerLineItemRef?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_not?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_in?: Maybe<Array<Scalars['String']>>;
  wholesalerLineItemRef_not_in?: Maybe<Array<Scalars['String']>>;
  wholesalerLineItemRef_lt?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_lte?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_gt?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_gte?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_contains?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_not_contains?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_starts_with?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_not_starts_with?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_ends_with?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_not_ends_with?: Maybe<Scalars['String']>;
  wholesalerTrackingRef?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_not?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_in?: Maybe<Array<Scalars['String']>>;
  wholesalerTrackingRef_not_in?: Maybe<Array<Scalars['String']>>;
  wholesalerTrackingRef_lt?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_lte?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_gt?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_gte?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_contains?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_not_contains?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_starts_with?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_not_starts_with?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_ends_with?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<WholesaleOrderLineItemScalarWhereInput>>;
  OR?: Maybe<Array<WholesaleOrderLineItemScalarWhereInput>>;
  NOT?: Maybe<Array<WholesaleOrderLineItemScalarWhereInput>>;
};

export type WholesaleOrderLineItemSubscriptionPayload = {
  __typename?: 'WholesaleOrderLineItemSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WholesaleOrderLineItem>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesaleOrderLineItemPreviousValues>;
};

export type WholesaleOrderLineItemSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesaleOrderLineItemWhereInput>;
  AND?: Maybe<Array<WholesaleOrderLineItemSubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesaleOrderLineItemSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesaleOrderLineItemSubscriptionWhereInput>>;
};

export type WholesaleOrderLineItemUpdateManyDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  wholesalerLineItemRef?: Maybe<Scalars['String']>;
  wholesalerTrackingRef?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['Json']>;
};

export type WholesaleOrderLineItemUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  wholesalerLineItemRef?: Maybe<Scalars['String']>;
  wholesalerTrackingRef?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['Json']>;
};

export type WholesaleOrderLineItemUpdateManyWithWhereNestedInput = {
  where: WholesaleOrderLineItemScalarWhereInput;
  data: WholesaleOrderLineItemUpdateManyDataInput;
};

export type WholesaleOrderLineItemUpdateManyWithoutOrderInput = {
  create?: Maybe<Array<WholesaleOrderLineItemCreateWithoutOrderInput>>;
  delete?: Maybe<Array<WholesaleOrderLineItemWhereUniqueInput>>;
  connect?: Maybe<Array<WholesaleOrderLineItemWhereUniqueInput>>;
  set?: Maybe<Array<WholesaleOrderLineItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<WholesaleOrderLineItemWhereUniqueInput>>;
  update?: Maybe<Array<WholesaleOrderLineItemUpdateWithWhereUniqueWithoutOrderInput>>;
  upsert?: Maybe<Array<WholesaleOrderLineItemUpsertWithWhereUniqueWithoutOrderInput>>;
  deleteMany?: Maybe<Array<WholesaleOrderLineItemScalarWhereInput>>;
  updateMany?: Maybe<Array<WholesaleOrderLineItemUpdateManyWithWhereNestedInput>>;
};

export type WholesaleOrderLineItemUpdateWithWhereUniqueWithoutOrderInput = {
  where: WholesaleOrderLineItemWhereUniqueInput;
  data: WholesaleOrderLineItemUpdateWithoutOrderDataInput;
};

export type WholesaleOrderLineItemUpdateWithoutOrderDataInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  wholesalerLineItemRef?: Maybe<Scalars['String']>;
  wholesalerTrackingRef?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['Json']>;
  createdBy?: Maybe<UserUpdateOneInput>;
};

export type WholesaleOrderLineItemUpsertWithWhereUniqueWithoutOrderInput = {
  where: WholesaleOrderLineItemWhereUniqueInput;
  update: WholesaleOrderLineItemUpdateWithoutOrderDataInput;
  create: WholesaleOrderLineItemCreateWithoutOrderInput;
};

export type WholesaleOrderLineItemWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Int']>;
  quantity_not?: Maybe<Scalars['Int']>;
  quantity_in?: Maybe<Array<Scalars['Int']>>;
  quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  quantity_lt?: Maybe<Scalars['Int']>;
  quantity_lte?: Maybe<Scalars['Int']>;
  quantity_gt?: Maybe<Scalars['Int']>;
  quantity_gte?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalPrice_not?: Maybe<Scalars['Float']>;
  totalPrice_in?: Maybe<Array<Scalars['Float']>>;
  totalPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  totalPrice_lt?: Maybe<Scalars['Float']>;
  totalPrice_lte?: Maybe<Scalars['Float']>;
  totalPrice_gt?: Maybe<Scalars['Float']>;
  totalPrice_gte?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPrice_not?: Maybe<Scalars['Float']>;
  unitPrice_in?: Maybe<Array<Scalars['Float']>>;
  unitPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  unitPrice_lt?: Maybe<Scalars['Float']>;
  unitPrice_lte?: Maybe<Scalars['Float']>;
  unitPrice_gt?: Maybe<Scalars['Float']>;
  unitPrice_gte?: Maybe<Scalars['Float']>;
  wholesalerLineItemRef?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_not?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_in?: Maybe<Array<Scalars['String']>>;
  wholesalerLineItemRef_not_in?: Maybe<Array<Scalars['String']>>;
  wholesalerLineItemRef_lt?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_lte?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_gt?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_gte?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_contains?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_not_contains?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_starts_with?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_not_starts_with?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_ends_with?: Maybe<Scalars['String']>;
  wholesalerLineItemRef_not_ends_with?: Maybe<Scalars['String']>;
  wholesalerTrackingRef?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_not?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_in?: Maybe<Array<Scalars['String']>>;
  wholesalerTrackingRef_not_in?: Maybe<Array<Scalars['String']>>;
  wholesalerTrackingRef_lt?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_lte?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_gt?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_gte?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_contains?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_not_contains?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_starts_with?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_not_starts_with?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_ends_with?: Maybe<Scalars['String']>;
  wholesalerTrackingRef_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserWhereInput>;
  order?: Maybe<WholesaleOrderWhereInput>;
  transaction?: Maybe<TransactionType>;
  transaction_not?: Maybe<TransactionType>;
  transaction_in?: Maybe<Array<TransactionType>>;
  transaction_not_in?: Maybe<Array<TransactionType>>;
  AND?: Maybe<Array<WholesaleOrderLineItemWhereInput>>;
  OR?: Maybe<Array<WholesaleOrderLineItemWhereInput>>;
  NOT?: Maybe<Array<WholesaleOrderLineItemWhereInput>>;
};

export type WholesaleOrderLineItemWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum WholesaleOrderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  WholesaleOrderRefAsc = 'wholesaleOrderRef_ASC',
  WholesaleOrderRefDesc = 'wholesaleOrderRef_DESC',
  TotalPriceAsc = 'totalPrice_ASC',
  TotalPriceDesc = 'totalPrice_DESC'
}

export type WholesaleOrderPreviousValues = {
  __typename?: 'WholesaleOrderPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  status: WholesaleOrderStates;
  wholesaleOrderRef?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Float']>;
};

export enum WholesaleOrderResolverVersion {
  One = 'ONE',
  Two = 'TWO'
}

export enum WholesaleOrderStates {
  Draft = 'Draft',
  Open = 'Open',
  Processed = 'Processed',
  Completed = 'Completed',
  Failed = 'Failed'
}

export type WholesaleOrderSubscriptionPayload = {
  __typename?: 'WholesaleOrderSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WholesaleOrder>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesaleOrderPreviousValues>;
};

export type WholesaleOrderSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesaleOrderWhereInput>;
  AND?: Maybe<Array<WholesaleOrderSubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesaleOrderSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesaleOrderSubscriptionWhereInput>>;
};

export type WholesaleOrderWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  items_every?: Maybe<WholesaleOrderLineItemWhereInput>;
  items_some?: Maybe<WholesaleOrderLineItemWhereInput>;
  items_none?: Maybe<WholesaleOrderLineItemWhereInput>;
  deliveryDetails?: Maybe<WholesaleDeliveryWhereInput>;
  invoice?: Maybe<InvoiceWhereInput>;
  status?: Maybe<WholesaleOrderStates>;
  status_not?: Maybe<WholesaleOrderStates>;
  status_in?: Maybe<Array<WholesaleOrderStates>>;
  status_not_in?: Maybe<Array<WholesaleOrderStates>>;
  wholesaleOrderRef?: Maybe<Scalars['String']>;
  wholesaleOrderRef_not?: Maybe<Scalars['String']>;
  wholesaleOrderRef_in?: Maybe<Array<Scalars['String']>>;
  wholesaleOrderRef_not_in?: Maybe<Array<Scalars['String']>>;
  wholesaleOrderRef_lt?: Maybe<Scalars['String']>;
  wholesaleOrderRef_lte?: Maybe<Scalars['String']>;
  wholesaleOrderRef_gt?: Maybe<Scalars['String']>;
  wholesaleOrderRef_gte?: Maybe<Scalars['String']>;
  wholesaleOrderRef_contains?: Maybe<Scalars['String']>;
  wholesaleOrderRef_not_contains?: Maybe<Scalars['String']>;
  wholesaleOrderRef_starts_with?: Maybe<Scalars['String']>;
  wholesaleOrderRef_not_starts_with?: Maybe<Scalars['String']>;
  wholesaleOrderRef_ends_with?: Maybe<Scalars['String']>;
  wholesaleOrderRef_not_ends_with?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalPrice_not?: Maybe<Scalars['Float']>;
  totalPrice_in?: Maybe<Array<Scalars['Float']>>;
  totalPrice_not_in?: Maybe<Array<Scalars['Float']>>;
  totalPrice_lt?: Maybe<Scalars['Float']>;
  totalPrice_lte?: Maybe<Scalars['Float']>;
  totalPrice_gt?: Maybe<Scalars['Float']>;
  totalPrice_gte?: Maybe<Scalars['Float']>;
  merchant?: Maybe<MerchantWhereInput>;
  preOrder?: Maybe<WholesalePreOrderWhereInput>;
  supplier?: Maybe<SupplierWhereInput>;
  AND?: Maybe<Array<WholesaleOrderWhereInput>>;
  OR?: Maybe<Array<WholesaleOrderWhereInput>>;
  NOT?: Maybe<Array<WholesaleOrderWhereInput>>;
  paymentStatus?: Maybe<WholesalePaymentStatus>;
  paymentStatus_not?: Maybe<WholesalePaymentStatus>;
  paymentStatus_in?: Maybe<Array<WholesalePaymentStatus>>;
  paymentStatus_not_in?: Maybe<Array<WholesalePaymentStatus>>;
  tax?: Maybe<OrderTaxWhereInput>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paidAt_not?: Maybe<Scalars['DateTime']>;
  paidAt_in?: Maybe<Array<Scalars['DateTime']>>;
  paidAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  paidAt_lt?: Maybe<Scalars['DateTime']>;
  paidAt_lte?: Maybe<Scalars['DateTime']>;
  paidAt_gt?: Maybe<Scalars['DateTime']>;
  paidAt_gte?: Maybe<Scalars['DateTime']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedAt_not?: Maybe<Scalars['DateTime']>;
  processedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  processedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  processedAt_lt?: Maybe<Scalars['DateTime']>;
  processedAt_lte?: Maybe<Scalars['DateTime']>;
  processedAt_gt?: Maybe<Scalars['DateTime']>;
  processedAt_gte?: Maybe<Scalars['DateTime']>;
};

export type WholesaleOrderWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export enum WholesalePaymentStatus {
  Draft = 'Draft',
  Open = 'Open',
  Paid = 'Paid',
  Uncollectible = 'Uncollectible',
  Void = 'Void'
}

export type WholesalePreOrder = {
  __typename?: 'WholesalePreOrder';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  totals?: Maybe<WholesalePreOrderTotals>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startProcessingAt: Scalars['DateTime'];
  stopProcessingAt: Scalars['DateTime'];
  deliverAt: Scalars['DateTime'];
  status: WholesalePreOrderStatus;
  merchant: Merchant;
  suppliers?: Maybe<Array<Supplier>>;
  items?: Maybe<Array<WholesalePreOrderItem>>;
  wholesaleOrder?: Maybe<WholesaleOrder>;
};


export type WholesalePreOrderSuppliersArgs = {
  where?: Maybe<SupplierWhereInput>;
  orderBy?: Maybe<SupplierOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type WholesalePreOrderItemsArgs = {
  where?: Maybe<WholesalePreOrderItemWhereInput>;
  orderBy?: Maybe<WholesalePreOrderItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type WholesalePreOrderConnectOneInput = {
  connect: WholesalePreOrderWhereUniqueInput;
};

export type WholesalePreOrderConnection = {
  __typename?: 'WholesalePreOrderConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesalePreOrderEdge>>;
  aggregate: AggregateWholesalePreOrder;
};

export type WholesalePreOrderCreateInput = {
  list: ListWhereUniqueInput;
  listItemsMetadata?: Maybe<Array<Maybe<PreOrderListItemMetadataItem>>>;
  customItemsMetadata?: Maybe<Array<PreOrderCustomItemMetadataItem>>;
  merchant: MerchantWhereUniqueInput;
  deliveryDate: Scalars['DateTime'];
};

export type WholesalePreOrderDeliveryDate = {
  __typename?: 'WholesalePreOrderDeliveryDate';
  dateString?: Maybe<Scalars['String']>;
  canDeliver?: Maybe<Scalars['Boolean']>;
  cutOffAt?: Maybe<Scalars['DateTime']>;
};

export type WholesalePreOrderDeliveryDatesInput = {
  date?: Maybe<Scalars['DateTime']>;
};

export type WholesalePreOrderEdge = {
  __typename?: 'WholesalePreOrderEdge';
  node: WholesalePreOrder;
  cursor: Scalars['String'];
};

export type WholesalePreOrderItem = {
  __typename?: 'WholesalePreOrderItem';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderedAt?: Maybe<Scalars['DateTime']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  noMatchAt?: Maybe<Scalars['DateTime']>;
  processAttemptCount: Scalars['Int'];
  status: WholesalePreOrderItemStatus;
  listItem: ListItemUnion;
  wholesaleOrderLineItems: Array<WholesaleOrderLineItem>;
};


export type WholesalePreOrderItemWholesaleOrderLineItemsArgs = {
  where?: Maybe<WholesaleOrderLineItemWhereInput>;
  orderBy?: Maybe<WholesaleOrderLineItemOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type WholesalePreOrderItemConnection = {
  __typename?: 'WholesalePreOrderItemConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesalePreOrderItemEdge>>;
  aggregate: AggregateWholesalePreOrderItem;
};

export type WholesalePreOrderItemEdge = {
  __typename?: 'WholesalePreOrderItemEdge';
  node: WholesalePreOrderItem;
  cursor: Scalars['String'];
};

export enum WholesalePreOrderItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  OrderedAtAsc = 'orderedAt_ASC',
  OrderedAtDesc = 'orderedAt_DESC',
  FailedAtAsc = 'failedAt_ASC',
  FailedAtDesc = 'failedAt_DESC',
  NoMatchAtAsc = 'noMatchAt_ASC',
  NoMatchAtDesc = 'noMatchAt_DESC',
  ProcessAttemptCountAsc = 'processAttemptCount_ASC',
  ProcessAttemptCountDesc = 'processAttemptCount_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC'
}

export type WholesalePreOrderItemPreviousValues = {
  __typename?: 'WholesalePreOrderItemPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderedAt?: Maybe<Scalars['DateTime']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  noMatchAt?: Maybe<Scalars['DateTime']>;
  processAttemptCount: Scalars['Int'];
  status: WholesalePreOrderItemStatus;
};

export type WholesalePreOrderItemScalarWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  orderedAt?: Maybe<Scalars['DateTime']>;
  orderedAt_not?: Maybe<Scalars['DateTime']>;
  orderedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  orderedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  orderedAt_lt?: Maybe<Scalars['DateTime']>;
  orderedAt_lte?: Maybe<Scalars['DateTime']>;
  orderedAt_gt?: Maybe<Scalars['DateTime']>;
  orderedAt_gte?: Maybe<Scalars['DateTime']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_not?: Maybe<Scalars['DateTime']>;
  failedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  failedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  failedAt_lt?: Maybe<Scalars['DateTime']>;
  failedAt_lte?: Maybe<Scalars['DateTime']>;
  failedAt_gt?: Maybe<Scalars['DateTime']>;
  failedAt_gte?: Maybe<Scalars['DateTime']>;
  noMatchAt?: Maybe<Scalars['DateTime']>;
  noMatchAt_not?: Maybe<Scalars['DateTime']>;
  noMatchAt_in?: Maybe<Array<Scalars['DateTime']>>;
  noMatchAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  noMatchAt_lt?: Maybe<Scalars['DateTime']>;
  noMatchAt_lte?: Maybe<Scalars['DateTime']>;
  noMatchAt_gt?: Maybe<Scalars['DateTime']>;
  noMatchAt_gte?: Maybe<Scalars['DateTime']>;
  processAttemptCount?: Maybe<Scalars['Int']>;
  processAttemptCount_not?: Maybe<Scalars['Int']>;
  processAttemptCount_in?: Maybe<Array<Scalars['Int']>>;
  processAttemptCount_not_in?: Maybe<Array<Scalars['Int']>>;
  processAttemptCount_lt?: Maybe<Scalars['Int']>;
  processAttemptCount_lte?: Maybe<Scalars['Int']>;
  processAttemptCount_gt?: Maybe<Scalars['Int']>;
  processAttemptCount_gte?: Maybe<Scalars['Int']>;
  status?: Maybe<WholesalePreOrderItemStatus>;
  status_not?: Maybe<WholesalePreOrderItemStatus>;
  status_in?: Maybe<Array<WholesalePreOrderItemStatus>>;
  status_not_in?: Maybe<Array<WholesalePreOrderItemStatus>>;
  AND?: Maybe<Array<WholesalePreOrderItemScalarWhereInput>>;
  OR?: Maybe<Array<WholesalePreOrderItemScalarWhereInput>>;
  NOT?: Maybe<Array<WholesalePreOrderItemScalarWhereInput>>;
};

export enum WholesalePreOrderItemStatus {
  Pending = 'Pending',
  NoMatch = 'NoMatch',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Ordered = 'Ordered'
}

export type WholesalePreOrderItemSubscriptionPayload = {
  __typename?: 'WholesalePreOrderItemSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WholesalePreOrderItem>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesalePreOrderItemPreviousValues>;
};

export type WholesalePreOrderItemSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesalePreOrderItemWhereInput>;
  AND?: Maybe<Array<WholesalePreOrderItemSubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesalePreOrderItemSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesalePreOrderItemSubscriptionWhereInput>>;
};

export type WholesalePreOrderItemUpdateDataInput = {
  listItem?: Maybe<ListItemUpdateForPreOrderInput>;
};

export type WholesalePreOrderItemUpdateInput = {
  listItem?: Maybe<ListItemUpdateForPreOrderInput>;
};

export type WholesalePreOrderItemUpdateManyDataInput = {
  status?: Maybe<WholesalePreOrderItemStatus>;
};

export type WholesalePreOrderItemUpdateManyInput = {
  disconnect?: Maybe<Array<WholesalePreOrderItemWhereUniqueInput>>;
  update?: Maybe<Array<WholesalePreOrderItemUpdateWithWhereUniqueNestedInput>>;
};

export type WholesalePreOrderItemUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  orderedAt?: Maybe<Scalars['DateTime']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  noMatchAt?: Maybe<Scalars['DateTime']>;
  processAttemptCount?: Maybe<Scalars['Int']>;
  status?: Maybe<WholesalePreOrderItemStatus>;
};

export type WholesalePreOrderItemUpdateManyWithWhereNestedInput = {
  where: WholesalePreOrderItemScalarWhereInput;
  data: WholesalePreOrderItemUpdateManyDataInput;
};

export type WholesalePreOrderItemUpdateWithWhereUniqueNestedInput = {
  where: WholesalePreOrderItemWhereUniqueInput;
  data: WholesalePreOrderItemUpdateDataInput;
};

export type WholesalePreOrderItemWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  orderedAt?: Maybe<Scalars['DateTime']>;
  orderedAt_not?: Maybe<Scalars['DateTime']>;
  orderedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  orderedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  orderedAt_lt?: Maybe<Scalars['DateTime']>;
  orderedAt_lte?: Maybe<Scalars['DateTime']>;
  orderedAt_gt?: Maybe<Scalars['DateTime']>;
  orderedAt_gte?: Maybe<Scalars['DateTime']>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failedAt_not?: Maybe<Scalars['DateTime']>;
  failedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  failedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  failedAt_lt?: Maybe<Scalars['DateTime']>;
  failedAt_lte?: Maybe<Scalars['DateTime']>;
  failedAt_gt?: Maybe<Scalars['DateTime']>;
  failedAt_gte?: Maybe<Scalars['DateTime']>;
  noMatchAt?: Maybe<Scalars['DateTime']>;
  noMatchAt_not?: Maybe<Scalars['DateTime']>;
  noMatchAt_in?: Maybe<Array<Scalars['DateTime']>>;
  noMatchAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  noMatchAt_lt?: Maybe<Scalars['DateTime']>;
  noMatchAt_lte?: Maybe<Scalars['DateTime']>;
  noMatchAt_gt?: Maybe<Scalars['DateTime']>;
  noMatchAt_gte?: Maybe<Scalars['DateTime']>;
  processAttemptCount?: Maybe<Scalars['Int']>;
  processAttemptCount_not?: Maybe<Scalars['Int']>;
  processAttemptCount_in?: Maybe<Array<Scalars['Int']>>;
  processAttemptCount_not_in?: Maybe<Array<Scalars['Int']>>;
  processAttemptCount_lt?: Maybe<Scalars['Int']>;
  processAttemptCount_lte?: Maybe<Scalars['Int']>;
  processAttemptCount_gt?: Maybe<Scalars['Int']>;
  processAttemptCount_gte?: Maybe<Scalars['Int']>;
  status?: Maybe<WholesalePreOrderItemStatus>;
  status_not?: Maybe<WholesalePreOrderItemStatus>;
  status_in?: Maybe<Array<WholesalePreOrderItemStatus>>;
  status_not_in?: Maybe<Array<WholesalePreOrderItemStatus>>;
  listItem?: Maybe<ListItemWhereInput>;
  wholesaleOrderLineItem_every?: Maybe<WholesaleOrderLineItemWhereInput>;
  wholesaleOrderLineItem_some?: Maybe<WholesaleOrderLineItemWhereInput>;
  wholesaleOrderLineItem_none?: Maybe<WholesaleOrderLineItemWhereInput>;
  AND?: Maybe<Array<WholesalePreOrderItemWhereInput>>;
  OR?: Maybe<Array<WholesalePreOrderItemWhereInput>>;
  NOT?: Maybe<Array<WholesalePreOrderItemWhereInput>>;
};

export type WholesalePreOrderItemWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum WholesalePreOrderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  CancelledAtAsc = 'cancelledAt_ASC',
  CancelledAtDesc = 'cancelledAt_DESC',
  StartProcessingAtAsc = 'startProcessingAt_ASC',
  StartProcessingAtDesc = 'startProcessingAt_DESC',
  StopProcessingAtAsc = 'stopProcessingAt_ASC',
  StopProcessingAtDesc = 'stopProcessingAt_DESC',
  DeliverAtAsc = 'deliverAt_ASC',
  DeliverAtDesc = 'deliverAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC'
}

export type WholesalePreOrderPreviousValues = {
  __typename?: 'WholesalePreOrderPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  startProcessingAt: Scalars['DateTime'];
  stopProcessingAt: Scalars['DateTime'];
  deliverAt: Scalars['DateTime'];
  status: WholesalePreOrderStatus;
};

export enum WholesalePreOrderStatus {
  Open = 'Open',
  Processing = 'Processing',
  Cancelled = 'Cancelled',
  Complete = 'Complete'
}

export type WholesalePreOrderSubscriptionPayload = {
  __typename?: 'WholesalePreOrderSubscriptionPayload';
  node?: Maybe<WholesalePreOrder>;
  mutation: MutationType;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesalePreOrderPreviousValues>;
};

export type WholesalePreOrderSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesalePreOrderWhereInput>;
  AND?: Maybe<Array<WholesalePreOrderSubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesalePreOrderSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesalePreOrderSubscriptionWhereInput>>;
};

export type WholesalePreOrderTotals = {
  __typename?: 'WholesalePreOrderTotals';
  subTotalAmount: Scalars['Float'];
  shippingAmount?: Maybe<Scalars['Float']>;
  salesTaxAmount: Scalars['Float'];
  grandTotalAmount: Scalars['Float'];
};

export type WholesalePreOrderUpdateInput = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
  items?: Maybe<WholesalePreOrderItemUpdateManyInput>;
};

export type WholesalePreOrderUpdateManyMutationInput = {
  cancelledAt?: Maybe<Scalars['DateTime']>;
};

export type WholesalePreOrderWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  cancelledAt_not?: Maybe<Scalars['DateTime']>;
  cancelledAt_in?: Maybe<Array<Scalars['DateTime']>>;
  cancelledAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  cancelledAt_lt?: Maybe<Scalars['DateTime']>;
  cancelledAt_lte?: Maybe<Scalars['DateTime']>;
  cancelledAt_gt?: Maybe<Scalars['DateTime']>;
  cancelledAt_gte?: Maybe<Scalars['DateTime']>;
  startProcessingAt?: Maybe<Scalars['DateTime']>;
  startProcessingAt_not?: Maybe<Scalars['DateTime']>;
  startProcessingAt_in?: Maybe<Array<Scalars['DateTime']>>;
  startProcessingAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  startProcessingAt_lt?: Maybe<Scalars['DateTime']>;
  startProcessingAt_lte?: Maybe<Scalars['DateTime']>;
  startProcessingAt_gt?: Maybe<Scalars['DateTime']>;
  startProcessingAt_gte?: Maybe<Scalars['DateTime']>;
  stopProcessingAt?: Maybe<Scalars['DateTime']>;
  stopProcessingAt_not?: Maybe<Scalars['DateTime']>;
  stopProcessingAt_in?: Maybe<Array<Scalars['DateTime']>>;
  stopProcessingAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  stopProcessingAt_lt?: Maybe<Scalars['DateTime']>;
  stopProcessingAt_lte?: Maybe<Scalars['DateTime']>;
  stopProcessingAt_gt?: Maybe<Scalars['DateTime']>;
  stopProcessingAt_gte?: Maybe<Scalars['DateTime']>;
  deliverAt?: Maybe<Scalars['DateTime']>;
  deliverAt_not?: Maybe<Scalars['DateTime']>;
  deliverAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deliverAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deliverAt_lt?: Maybe<Scalars['DateTime']>;
  deliverAt_lte?: Maybe<Scalars['DateTime']>;
  deliverAt_gt?: Maybe<Scalars['DateTime']>;
  deliverAt_gte?: Maybe<Scalars['DateTime']>;
  status?: Maybe<WholesalePreOrderStatus>;
  status_not?: Maybe<WholesalePreOrderStatus>;
  status_in?: Maybe<Array<WholesalePreOrderStatus>>;
  status_not_in?: Maybe<Array<WholesalePreOrderStatus>>;
  merchant?: Maybe<MerchantWhereInput>;
  suppliers_every?: Maybe<SupplierWhereInput>;
  suppliers_some?: Maybe<SupplierWhereInput>;
  suppliers_none?: Maybe<SupplierWhereInput>;
  items_every?: Maybe<WholesalePreOrderItemWhereInput>;
  items_some?: Maybe<WholesalePreOrderItemWhereInput>;
  items_none?: Maybe<WholesalePreOrderItemWhereInput>;
  wholesaleOrder?: Maybe<WholesaleOrderWhereInput>;
  AND?: Maybe<Array<WholesalePreOrderWhereInput>>;
  OR?: Maybe<Array<WholesalePreOrderWhereInput>>;
  NOT?: Maybe<Array<WholesalePreOrderWhereInput>>;
};

export type WholesalePreOrderWhereUniqueInput = {
  id: Scalars['ID'];
  merchant?: Maybe<MerchantWhereUniqueInput>;
};

export type WholesaleSearchItem = {
  __typename?: 'WholesaleSearchItem';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WholesaleSubType = {
  __typename?: 'WholesaleSubType';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<WholesaleSearchItem>>>;
};

export type WholesaleTopLevelType = {
  __typename?: 'WholesaleTopLevelType';
  name?: Maybe<WholesaleItemType>;
  subtypes?: Maybe<Array<Maybe<WholesaleSubType>>>;
};

export type WholesaleTradeLineItem = {
  __typename?: 'WholesaleTradeLineItem';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<WholesaleItemType>;
  floom_import_batch_ref_id?: Maybe<Scalars['Int']>;
  is_live?: Maybe<Scalars['Boolean']>;
  wholesaler_name?: Maybe<Scalars['String']>;
  wholesaler_line_item_id?: Maybe<Scalars['String']>;
  standardization_ref?: Maybe<Scalars['String']>;
  market_open_date?: Maybe<Scalars['DateTime']>;
  market_close_date?: Maybe<Scalars['DateTime']>;
  image_url?: Maybe<Scalars['String']>;
  image_large_url?: Maybe<Scalars['String']>;
  flower_name?: Maybe<Scalars['String']>;
  supplier_name?: Maybe<Scalars['String']>;
  supplier_country_code?: Maybe<Scalars['String']>;
  quantity_value?: Maybe<Scalars['Int']>;
  incremental_quantity_value?: Maybe<Scalars['String']>;
  inner_package_quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  minimum_price_quantity?: Maybe<Scalars['Int']>;
  maximum_price_quantity?: Maybe<Scalars['Int']>;
  market_status?: Maybe<Scalars['String']>;
  floricode_main_colour?: Maybe<Scalars['String']>;
  floricode_secondary_colour?: Maybe<Scalars['String']>;
  rhs_main_colour?: Maybe<Scalars['String']>;
  rhs_secondary_colour?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  minimum_stem_length?: Maybe<Scalars['Int']>;
  minimum_stem_length_unit?: Maybe<Scalars['String']>;
  product_group?: Maybe<Scalars['String']>;
  application_group?: Maybe<Scalars['String']>;
  head_number?: Maybe<Scalars['String']>;
  /** @deprecated Use `head_size_min` */
  head_size?: Maybe<Scalars['String']>;
  head_size_min?: Maybe<Scalars['String']>;
  head_size_max?: Maybe<Scalars['String']>;
  head_size_unit?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  pot_size?: Maybe<Scalars['String']>;
  pot_flower_count?: Maybe<Scalars['String']>;
  plant_diameter?: Maybe<Scalars['String']>;
  plant_thickness?: Maybe<Scalars['String']>;
  bulb_quantity?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['String']>;
  mps_cert?: Maybe<Scalars['String']>;
  pot_material?: Maybe<Scalars['String']>;
  componentConfig?: Maybe<ComponentConfig>;
};

export type WholesaleTradeLineItemConnection = {
  __typename?: 'WholesaleTradeLineItemConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<WholesaleTradeLineItemEdge>>;
  aggregate: AggregateWholesaleTradeLineItem;
};

export type WholesaleTradeLineItemCreateInput = {
  id?: Maybe<Scalars['ID']>;
  is_live?: Maybe<Scalars['Boolean']>;
  wholesaler_name?: Maybe<Scalars['String']>;
  wholesaler_line_item_id?: Maybe<Scalars['String']>;
  standardization_ref?: Maybe<Scalars['String']>;
};

export type WholesaleTradeLineItemEdge = {
  __typename?: 'WholesaleTradeLineItemEdge';
  node: WholesaleTradeLineItem;
  cursor: Scalars['String'];
};

export enum WholesaleTradeLineItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  FloomImportBatchRefIdAsc = 'floom_import_batch_ref_id_ASC',
  FloomImportBatchRefIdDesc = 'floom_import_batch_ref_id_DESC',
  IsLiveAsc = 'is_live_ASC',
  IsLiveDesc = 'is_live_DESC',
  WholesalerNameAsc = 'wholesaler_name_ASC',
  WholesalerNameDesc = 'wholesaler_name_DESC',
  WholesalerLineItemIdAsc = 'wholesaler_line_item_id_ASC',
  WholesalerLineItemIdDesc = 'wholesaler_line_item_id_DESC',
  StandardizationRefAsc = 'standardization_ref_ASC',
  StandardizationRefDesc = 'standardization_ref_DESC',
  MarketOpenDateAsc = 'market_open_date_ASC',
  MarketOpenDateDesc = 'market_open_date_DESC',
  MarketCloseDateAsc = 'market_close_date_ASC',
  MarketCloseDateDesc = 'market_close_date_DESC',
  ImageUrlAsc = 'image_url_ASC',
  ImageUrlDesc = 'image_url_DESC',
  FlowerNameAsc = 'flower_name_ASC',
  FlowerNameDesc = 'flower_name_DESC',
  SupplierNameAsc = 'supplier_name_ASC',
  SupplierNameDesc = 'supplier_name_DESC',
  SupplierCountryCodeAsc = 'supplier_country_code_ASC',
  SupplierCountryCodeDesc = 'supplier_country_code_DESC',
  QuantityValueAsc = 'quantity_value_ASC',
  QuantityValueDesc = 'quantity_value_DESC',
  IncrementalQuantityValueAsc = 'incremental_quantity_value_ASC',
  IncrementalQuantityValueDesc = 'incremental_quantity_value_DESC',
  InnerPackageQuantityAsc = 'inner_package_quantity_ASC',
  InnerPackageQuantityDesc = 'inner_package_quantity_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  MinimumPriceQuantityAsc = 'minimum_price_quantity_ASC',
  MinimumPriceQuantityDesc = 'minimum_price_quantity_DESC',
  MaximumPriceQuantityAsc = 'maximum_price_quantity_ASC',
  MaximumPriceQuantityDesc = 'maximum_price_quantity_DESC',
  MarketStatusAsc = 'market_status_ASC',
  MarketStatusDesc = 'market_status_DESC',
  FloricodeMainColourAsc = 'floricode_main_colour_ASC',
  FloricodeMainColourDesc = 'floricode_main_colour_DESC',
  FloricodeSecondaryColourAsc = 'floricode_secondary_colour_ASC',
  FloricodeSecondaryColourDesc = 'floricode_secondary_colour_DESC',
  RhsMainColourAsc = 'rhs_main_colour_ASC',
  RhsMainColourDesc = 'rhs_main_colour_DESC',
  RhsSecondaryColourAsc = 'rhs_secondary_colour_ASC',
  RhsSecondaryColourDesc = 'rhs_secondary_colour_DESC',
  CultivarAsc = 'cultivar_ASC',
  CultivarDesc = 'cultivar_DESC',
  SpeciesAsc = 'species_ASC',
  SpeciesDesc = 'species_DESC',
  GenusAsc = 'genus_ASC',
  GenusDesc = 'genus_DESC',
  MinimumStemLengthAsc = 'minimum_stem_length_ASC',
  MinimumStemLengthDesc = 'minimum_stem_length_DESC',
  MinimumStemLengthUnitAsc = 'minimum_stem_length_unit_ASC',
  MinimumStemLengthUnitDesc = 'minimum_stem_length_unit_DESC',
  ProductGroupAsc = 'product_group_ASC',
  ProductGroupDesc = 'product_group_DESC',
  ApplicationGroupAsc = 'application_group_ASC',
  ApplicationGroupDesc = 'application_group_DESC',
  HeadNumberAsc = 'head_number_ASC',
  HeadNumberDesc = 'head_number_DESC',
  HeadSizeAsc = 'head_size_ASC',
  HeadSizeDesc = 'head_size_DESC',
  HeadSizeMinAsc = 'head_size_min_ASC',
  HeadSizeMinDesc = 'head_size_min_DESC',
  HeadSizeMaxAsc = 'head_size_max_ASC',
  HeadSizeMaxDesc = 'head_size_max_DESC',
  HeadSizeUnitAsc = 'head_size_unit_ASC',
  HeadSizeUnitDesc = 'head_size_unit_DESC',
  MaturityAsc = 'maturity_ASC',
  MaturityDesc = 'maturity_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  PotSizeAsc = 'pot_size_ASC',
  PotSizeDesc = 'pot_size_DESC',
  PotFlowerCountAsc = 'pot_flower_count_ASC',
  PotFlowerCountDesc = 'pot_flower_count_DESC',
  PlantDiameterAsc = 'plant_diameter_ASC',
  PlantDiameterDesc = 'plant_diameter_DESC',
  PlantThicknessAsc = 'plant_thickness_ASC',
  PlantThicknessDesc = 'plant_thickness_DESC',
  BulbQuantityAsc = 'bulb_quantity_ASC',
  BulbQuantityDesc = 'bulb_quantity_DESC',
  MpsCertAsc = 'mps_cert_ASC',
  MpsCertDesc = 'mps_cert_DESC',
  PotMaterialAsc = 'pot_material_ASC',
  PotMaterialDesc = 'pot_material_DESC',
  QualityAsc = 'quality_ASC',
  QualityDesc = 'quality_DESC',
  SearchableAsc = 'searchable_ASC',
  SearchableDesc = 'searchable_DESC'
}

export type WholesaleTradeLineItemPreviousValues = {
  __typename?: 'WholesaleTradeLineItemPreviousValues';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<WholesaleItemType>;
  floom_import_batch_ref_id?: Maybe<Scalars['Int']>;
  is_live?: Maybe<Scalars['Boolean']>;
  wholesaler_name?: Maybe<Scalars['String']>;
  wholesaler_line_item_id?: Maybe<Scalars['String']>;
  standardization_ref?: Maybe<Scalars['String']>;
  market_open_date?: Maybe<Scalars['DateTime']>;
  market_close_date?: Maybe<Scalars['DateTime']>;
  image_url?: Maybe<Scalars['String']>;
  flower_name?: Maybe<Scalars['String']>;
  supplier_name?: Maybe<Scalars['String']>;
  supplier_country_code?: Maybe<Scalars['String']>;
  quantity_value?: Maybe<Scalars['Int']>;
  incremental_quantity_value?: Maybe<Scalars['String']>;
  inner_package_quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  minimum_price_quantity?: Maybe<Scalars['Int']>;
  maximum_price_quantity?: Maybe<Scalars['Int']>;
  market_status?: Maybe<Scalars['String']>;
  floricode_main_colour?: Maybe<Scalars['String']>;
  floricode_secondary_colour?: Maybe<Scalars['String']>;
  rhs_main_colour?: Maybe<Scalars['String']>;
  rhs_secondary_colour?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  minimum_stem_length?: Maybe<Scalars['Int']>;
  minimum_stem_length_unit?: Maybe<Scalars['String']>;
  product_group?: Maybe<Scalars['String']>;
  application_group?: Maybe<Scalars['String']>;
  head_number?: Maybe<Scalars['String']>;
  /** @deprecated Use `head_size_min` */
  head_size?: Maybe<Scalars['String']>;
  head_size_min?: Maybe<Scalars['String']>;
  head_size_max?: Maybe<Scalars['String']>;
  head_size_unit?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  pot_size?: Maybe<Scalars['String']>;
  pot_flower_count?: Maybe<Scalars['String']>;
  plant_diameter?: Maybe<Scalars['String']>;
  plant_thickness?: Maybe<Scalars['String']>;
  bulb_quantity?: Maybe<Scalars['String']>;
  mps_cert?: Maybe<Scalars['String']>;
  pot_material?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['String']>;
};

export type WholesaleTradeLineItemSubscriptionPayload = {
  __typename?: 'WholesaleTradeLineItemSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<WholesaleTradeLineItem>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
  previousValues?: Maybe<WholesaleTradeLineItemPreviousValues>;
};

export type WholesaleTradeLineItemSubscriptionWhereInput = {
  mutation_in?: Maybe<Array<MutationType>>;
  updatedFields_contains?: Maybe<Scalars['String']>;
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
  node?: Maybe<WholesaleTradeLineItemWhereInput>;
  AND?: Maybe<Array<WholesaleTradeLineItemSubscriptionWhereInput>>;
  OR?: Maybe<Array<WholesaleTradeLineItemSubscriptionWhereInput>>;
  NOT?: Maybe<Array<WholesaleTradeLineItemSubscriptionWhereInput>>;
};

export type WholesaleTradeLineItemUpdateInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  floom_import_batch_ref_id?: Maybe<Scalars['Int']>;
  is_live?: Maybe<Scalars['Boolean']>;
  wholesaler_name?: Maybe<Scalars['String']>;
  wholesaler_line_item_id?: Maybe<Scalars['String']>;
  standardization_ref?: Maybe<Scalars['String']>;
  market_open_date?: Maybe<Scalars['DateTime']>;
  market_close_date?: Maybe<Scalars['DateTime']>;
  image_url?: Maybe<Scalars['String']>;
  flower_name?: Maybe<Scalars['String']>;
  supplier_name?: Maybe<Scalars['String']>;
  supplier_country_code?: Maybe<Scalars['String']>;
  quantity_value?: Maybe<Scalars['Int']>;
  incremental_quantity_value?: Maybe<Scalars['String']>;
  inner_package_quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  minimum_price_quantity?: Maybe<Scalars['Int']>;
  maximum_price_quantity?: Maybe<Scalars['Int']>;
  market_status?: Maybe<Scalars['String']>;
  floricode_main_colour?: Maybe<Scalars['String']>;
  floricode_secondary_colour?: Maybe<Scalars['String']>;
  rhs_main_colour?: Maybe<Scalars['String']>;
  rhs_secondary_colour?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  minimum_stem_length?: Maybe<Scalars['Int']>;
  minimum_stem_length_unit?: Maybe<Scalars['String']>;
  product_group?: Maybe<Scalars['String']>;
  application_group?: Maybe<Scalars['String']>;
  head_number?: Maybe<Scalars['String']>;
  head_size?: Maybe<Scalars['String']>;
  head_size_min?: Maybe<Scalars['String']>;
  head_size_max?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  componentConfig?: Maybe<ComponentConfigUpdateOneInput>;
  searchable?: Maybe<Scalars['String']>;
};

export type WholesaleTradeLineItemUpdateManyMutationInput = {
  deletedAt?: Maybe<Scalars['DateTime']>;
  floom_import_batch_ref_id?: Maybe<Scalars['Int']>;
  is_live?: Maybe<Scalars['Boolean']>;
  wholesaler_name?: Maybe<Scalars['String']>;
  wholesaler_line_item_id?: Maybe<Scalars['String']>;
  standardization_ref?: Maybe<Scalars['Int']>;
  market_open_date?: Maybe<Scalars['DateTime']>;
  market_close_date?: Maybe<Scalars['DateTime']>;
  image_url?: Maybe<Scalars['String']>;
  flower_name?: Maybe<Scalars['String']>;
  supplier_name?: Maybe<Scalars['String']>;
  supplier_country_code?: Maybe<Scalars['String']>;
  quantity_value?: Maybe<Scalars['Int']>;
  incremental_quantity_value?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  minimum_price_quantity?: Maybe<Scalars['Int']>;
  maximum_price_quantity?: Maybe<Scalars['Int']>;
  market_status?: Maybe<Scalars['String']>;
  floricode_main_colour?: Maybe<Scalars['String']>;
  floricode_secondary_colour?: Maybe<Scalars['String']>;
  rhs_main_colour?: Maybe<Scalars['String']>;
  rhs_secondary_colour?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  minimum_stem_length?: Maybe<Scalars['Int']>;
  minimum_stem_length_unit?: Maybe<Scalars['String']>;
  product_group?: Maybe<Scalars['String']>;
  application_group?: Maybe<Scalars['String']>;
  head_number?: Maybe<Scalars['String']>;
  head_size?: Maybe<Scalars['String']>;
  head_size_min?: Maybe<Scalars['String']>;
  head_size_max?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  searchable?: Maybe<Scalars['String']>;
};

export type WholesaleTradeLineItemWhereInput = {
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Scalars['ID']>>;
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  id_lt?: Maybe<Scalars['ID']>;
  id_lte?: Maybe<Scalars['ID']>;
  id_gt?: Maybe<Scalars['ID']>;
  id_gte?: Maybe<Scalars['ID']>;
  id_contains?: Maybe<Scalars['ID']>;
  id_not_contains?: Maybe<Scalars['ID']>;
  id_starts_with?: Maybe<Scalars['ID']>;
  id_not_starts_with?: Maybe<Scalars['ID']>;
  id_ends_with?: Maybe<Scalars['ID']>;
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdAt_not?: Maybe<Scalars['DateTime']>;
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedAt_not?: Maybe<Scalars['DateTime']>;
  deletedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  deletedAt_lt?: Maybe<Scalars['DateTime']>;
  deletedAt_lte?: Maybe<Scalars['DateTime']>;
  deletedAt_gt?: Maybe<Scalars['DateTime']>;
  deletedAt_gte?: Maybe<Scalars['DateTime']>;
  floom_import_batch_ref_id?: Maybe<Scalars['Int']>;
  floom_import_batch_ref_id_not?: Maybe<Scalars['Int']>;
  floom_import_batch_ref_id_in?: Maybe<Array<Scalars['Int']>>;
  floom_import_batch_ref_id_not_in?: Maybe<Array<Scalars['Int']>>;
  floom_import_batch_ref_id_lt?: Maybe<Scalars['Int']>;
  floom_import_batch_ref_id_lte?: Maybe<Scalars['Int']>;
  floom_import_batch_ref_id_gt?: Maybe<Scalars['Int']>;
  floom_import_batch_ref_id_gte?: Maybe<Scalars['Int']>;
  is_live?: Maybe<Scalars['Boolean']>;
  is_live_not?: Maybe<Scalars['Boolean']>;
  wholesaler_name?: Maybe<Scalars['String']>;
  wholesaler_name_not?: Maybe<Scalars['String']>;
  wholesaler_name_in?: Maybe<Array<Scalars['String']>>;
  wholesaler_name_not_in?: Maybe<Array<Scalars['String']>>;
  wholesaler_name_lt?: Maybe<Scalars['String']>;
  wholesaler_name_lte?: Maybe<Scalars['String']>;
  wholesaler_name_gt?: Maybe<Scalars['String']>;
  wholesaler_name_gte?: Maybe<Scalars['String']>;
  wholesaler_name_contains?: Maybe<Scalars['String']>;
  wholesaler_name_not_contains?: Maybe<Scalars['String']>;
  wholesaler_name_starts_with?: Maybe<Scalars['String']>;
  wholesaler_name_not_starts_with?: Maybe<Scalars['String']>;
  wholesaler_name_ends_with?: Maybe<Scalars['String']>;
  wholesaler_name_not_ends_with?: Maybe<Scalars['String']>;
  wholesaler_line_item_id?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_not?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_in?: Maybe<Array<Scalars['String']>>;
  wholesaler_line_item_id_not_in?: Maybe<Array<Scalars['String']>>;
  wholesaler_line_item_id_lt?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_lte?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_gt?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_gte?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_contains?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_not_contains?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_starts_with?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_not_starts_with?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_ends_with?: Maybe<Scalars['String']>;
  wholesaler_line_item_id_not_ends_with?: Maybe<Scalars['String']>;
  standardization_ref?: Maybe<Scalars['String']>;
  standardization_ref_not?: Maybe<Scalars['String']>;
  standardization_ref_in?: Maybe<Array<Scalars['String']>>;
  standardization_ref_not_in?: Maybe<Array<Scalars['String']>>;
  standardization_ref_lt?: Maybe<Scalars['String']>;
  standardization_ref_lte?: Maybe<Scalars['String']>;
  standardization_ref_gt?: Maybe<Scalars['String']>;
  standardization_ref_gte?: Maybe<Scalars['String']>;
  standardization_ref_contains?: Maybe<Scalars['String']>;
  standardization_ref_not_contains?: Maybe<Scalars['String']>;
  standardization_ref_starts_with?: Maybe<Scalars['String']>;
  standardization_ref_not_starts_with?: Maybe<Scalars['String']>;
  standardization_ref_ends_with?: Maybe<Scalars['String']>;
  standardization_ref_not_ends_with?: Maybe<Scalars['String']>;
  market_open_date?: Maybe<Scalars['DateTime']>;
  market_open_date_not?: Maybe<Scalars['DateTime']>;
  market_open_date_in?: Maybe<Array<Scalars['DateTime']>>;
  market_open_date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  market_open_date_lt?: Maybe<Scalars['DateTime']>;
  market_open_date_lte?: Maybe<Scalars['DateTime']>;
  market_open_date_gt?: Maybe<Scalars['DateTime']>;
  market_open_date_gte?: Maybe<Scalars['DateTime']>;
  market_close_date?: Maybe<Scalars['DateTime']>;
  market_close_date_not?: Maybe<Scalars['DateTime']>;
  market_close_date_in?: Maybe<Array<Scalars['DateTime']>>;
  market_close_date_not_in?: Maybe<Array<Scalars['DateTime']>>;
  market_close_date_lt?: Maybe<Scalars['DateTime']>;
  market_close_date_lte?: Maybe<Scalars['DateTime']>;
  market_close_date_gt?: Maybe<Scalars['DateTime']>;
  market_close_date_gte?: Maybe<Scalars['DateTime']>;
  image_url?: Maybe<Scalars['String']>;
  image_url_not?: Maybe<Scalars['String']>;
  image_url_in?: Maybe<Array<Scalars['String']>>;
  image_url_not_in?: Maybe<Array<Scalars['String']>>;
  image_url_lt?: Maybe<Scalars['String']>;
  image_url_lte?: Maybe<Scalars['String']>;
  image_url_gt?: Maybe<Scalars['String']>;
  image_url_gte?: Maybe<Scalars['String']>;
  image_url_contains?: Maybe<Scalars['String']>;
  image_url_not_contains?: Maybe<Scalars['String']>;
  image_url_starts_with?: Maybe<Scalars['String']>;
  image_url_not_starts_with?: Maybe<Scalars['String']>;
  image_url_ends_with?: Maybe<Scalars['String']>;
  image_url_not_ends_with?: Maybe<Scalars['String']>;
  flower_name?: Maybe<Scalars['String']>;
  flower_name_not?: Maybe<Scalars['String']>;
  flower_name_in?: Maybe<Array<Scalars['String']>>;
  flower_name_not_in?: Maybe<Array<Scalars['String']>>;
  flower_name_lt?: Maybe<Scalars['String']>;
  flower_name_lte?: Maybe<Scalars['String']>;
  flower_name_gt?: Maybe<Scalars['String']>;
  flower_name_gte?: Maybe<Scalars['String']>;
  flower_name_contains?: Maybe<Scalars['String']>;
  flower_name_not_contains?: Maybe<Scalars['String']>;
  flower_name_starts_with?: Maybe<Scalars['String']>;
  flower_name_not_starts_with?: Maybe<Scalars['String']>;
  flower_name_ends_with?: Maybe<Scalars['String']>;
  flower_name_not_ends_with?: Maybe<Scalars['String']>;
  supplier_name?: Maybe<Scalars['String']>;
  supplier_name_not?: Maybe<Scalars['String']>;
  supplier_name_in?: Maybe<Array<Scalars['String']>>;
  supplier_name_not_in?: Maybe<Array<Scalars['String']>>;
  supplier_name_lt?: Maybe<Scalars['String']>;
  supplier_name_lte?: Maybe<Scalars['String']>;
  supplier_name_gt?: Maybe<Scalars['String']>;
  supplier_name_gte?: Maybe<Scalars['String']>;
  supplier_name_contains?: Maybe<Scalars['String']>;
  supplier_name_not_contains?: Maybe<Scalars['String']>;
  supplier_name_starts_with?: Maybe<Scalars['String']>;
  supplier_name_not_starts_with?: Maybe<Scalars['String']>;
  supplier_name_ends_with?: Maybe<Scalars['String']>;
  supplier_name_not_ends_with?: Maybe<Scalars['String']>;
  supplier_country_code?: Maybe<Scalars['String']>;
  supplier_country_code_not?: Maybe<Scalars['String']>;
  supplier_country_code_in?: Maybe<Array<Scalars['String']>>;
  supplier_country_code_not_in?: Maybe<Array<Scalars['String']>>;
  supplier_country_code_lt?: Maybe<Scalars['String']>;
  supplier_country_code_lte?: Maybe<Scalars['String']>;
  supplier_country_code_gt?: Maybe<Scalars['String']>;
  supplier_country_code_gte?: Maybe<Scalars['String']>;
  supplier_country_code_contains?: Maybe<Scalars['String']>;
  supplier_country_code_not_contains?: Maybe<Scalars['String']>;
  supplier_country_code_starts_with?: Maybe<Scalars['String']>;
  supplier_country_code_not_starts_with?: Maybe<Scalars['String']>;
  supplier_country_code_ends_with?: Maybe<Scalars['String']>;
  supplier_country_code_not_ends_with?: Maybe<Scalars['String']>;
  quantity_value?: Maybe<Scalars['Int']>;
  quantity_value_not?: Maybe<Scalars['Int']>;
  quantity_value_in?: Maybe<Array<Scalars['Int']>>;
  quantity_value_not_in?: Maybe<Array<Scalars['Int']>>;
  quantity_value_lt?: Maybe<Scalars['Int']>;
  quantity_value_lte?: Maybe<Scalars['Int']>;
  quantity_value_gt?: Maybe<Scalars['Int']>;
  quantity_value_gte?: Maybe<Scalars['Int']>;
  incremental_quantity_value?: Maybe<Scalars['String']>;
  incremental_quantity_value_not?: Maybe<Scalars['String']>;
  incremental_quantity_value_in?: Maybe<Array<Scalars['String']>>;
  incremental_quantity_value_not_in?: Maybe<Array<Scalars['String']>>;
  incremental_quantity_value_lt?: Maybe<Scalars['String']>;
  incremental_quantity_value_lte?: Maybe<Scalars['String']>;
  incremental_quantity_value_gt?: Maybe<Scalars['String']>;
  incremental_quantity_value_gte?: Maybe<Scalars['String']>;
  incremental_quantity_value_contains?: Maybe<Scalars['String']>;
  incremental_quantity_value_not_contains?: Maybe<Scalars['String']>;
  incremental_quantity_value_starts_with?: Maybe<Scalars['String']>;
  incremental_quantity_value_not_starts_with?: Maybe<Scalars['String']>;
  incremental_quantity_value_ends_with?: Maybe<Scalars['String']>;
  incremental_quantity_value_not_ends_with?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  price_not?: Maybe<Scalars['Float']>;
  price_in?: Maybe<Array<Scalars['Float']>>;
  price_not_in?: Maybe<Array<Scalars['Float']>>;
  price_lt?: Maybe<Scalars['Float']>;
  price_lte?: Maybe<Scalars['Float']>;
  price_gt?: Maybe<Scalars['Float']>;
  price_gte?: Maybe<Scalars['Float']>;
  minimum_price_quantity?: Maybe<Scalars['Int']>;
  minimum_price_quantity_not?: Maybe<Scalars['Int']>;
  minimum_price_quantity_in?: Maybe<Array<Scalars['Int']>>;
  minimum_price_quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  minimum_price_quantity_lt?: Maybe<Scalars['Int']>;
  minimum_price_quantity_lte?: Maybe<Scalars['Int']>;
  minimum_price_quantity_gt?: Maybe<Scalars['Int']>;
  minimum_price_quantity_gte?: Maybe<Scalars['Int']>;
  maximum_price_quantity?: Maybe<Scalars['Int']>;
  maximum_price_quantity_not?: Maybe<Scalars['Int']>;
  maximum_price_quantity_in?: Maybe<Array<Scalars['Int']>>;
  maximum_price_quantity_not_in?: Maybe<Array<Scalars['Int']>>;
  maximum_price_quantity_lt?: Maybe<Scalars['Int']>;
  maximum_price_quantity_lte?: Maybe<Scalars['Int']>;
  maximum_price_quantity_gt?: Maybe<Scalars['Int']>;
  maximum_price_quantity_gte?: Maybe<Scalars['Int']>;
  market_status?: Maybe<Scalars['String']>;
  market_status_not?: Maybe<Scalars['String']>;
  market_status_in?: Maybe<Array<Scalars['String']>>;
  market_status_not_in?: Maybe<Array<Scalars['String']>>;
  market_status_lt?: Maybe<Scalars['String']>;
  market_status_lte?: Maybe<Scalars['String']>;
  market_status_gt?: Maybe<Scalars['String']>;
  market_status_gte?: Maybe<Scalars['String']>;
  market_status_contains?: Maybe<Scalars['String']>;
  market_status_not_contains?: Maybe<Scalars['String']>;
  market_status_starts_with?: Maybe<Scalars['String']>;
  market_status_not_starts_with?: Maybe<Scalars['String']>;
  market_status_ends_with?: Maybe<Scalars['String']>;
  market_status_not_ends_with?: Maybe<Scalars['String']>;
  floricode_main_colour?: Maybe<Scalars['String']>;
  floricode_main_colour_not?: Maybe<Scalars['String']>;
  floricode_main_colour_in?: Maybe<Array<Scalars['String']>>;
  floricode_main_colour_not_in?: Maybe<Array<Scalars['String']>>;
  floricode_main_colour_lt?: Maybe<Scalars['String']>;
  floricode_main_colour_lte?: Maybe<Scalars['String']>;
  floricode_main_colour_gt?: Maybe<Scalars['String']>;
  floricode_main_colour_gte?: Maybe<Scalars['String']>;
  floricode_main_colour_contains?: Maybe<Scalars['String']>;
  floricode_main_colour_not_contains?: Maybe<Scalars['String']>;
  floricode_main_colour_starts_with?: Maybe<Scalars['String']>;
  floricode_main_colour_not_starts_with?: Maybe<Scalars['String']>;
  floricode_main_colour_ends_with?: Maybe<Scalars['String']>;
  floricode_main_colour_not_ends_with?: Maybe<Scalars['String']>;
  floricode_secondary_colour?: Maybe<Scalars['String']>;
  floricode_secondary_colour_not?: Maybe<Scalars['String']>;
  floricode_secondary_colour_in?: Maybe<Array<Scalars['String']>>;
  floricode_secondary_colour_not_in?: Maybe<Array<Scalars['String']>>;
  floricode_secondary_colour_lt?: Maybe<Scalars['String']>;
  floricode_secondary_colour_lte?: Maybe<Scalars['String']>;
  floricode_secondary_colour_gt?: Maybe<Scalars['String']>;
  floricode_secondary_colour_gte?: Maybe<Scalars['String']>;
  floricode_secondary_colour_contains?: Maybe<Scalars['String']>;
  floricode_secondary_colour_not_contains?: Maybe<Scalars['String']>;
  floricode_secondary_colour_starts_with?: Maybe<Scalars['String']>;
  floricode_secondary_colour_not_starts_with?: Maybe<Scalars['String']>;
  floricode_secondary_colour_ends_with?: Maybe<Scalars['String']>;
  floricode_secondary_colour_not_ends_with?: Maybe<Scalars['String']>;
  rhs_main_colour?: Maybe<Scalars['String']>;
  rhs_main_colour_not?: Maybe<Scalars['String']>;
  rhs_main_colour_in?: Maybe<Array<Scalars['String']>>;
  rhs_main_colour_not_in?: Maybe<Array<Scalars['String']>>;
  rhs_main_colour_lt?: Maybe<Scalars['String']>;
  rhs_main_colour_lte?: Maybe<Scalars['String']>;
  rhs_main_colour_gt?: Maybe<Scalars['String']>;
  rhs_main_colour_gte?: Maybe<Scalars['String']>;
  rhs_main_colour_contains?: Maybe<Scalars['String']>;
  rhs_main_colour_not_contains?: Maybe<Scalars['String']>;
  rhs_main_colour_starts_with?: Maybe<Scalars['String']>;
  rhs_main_colour_not_starts_with?: Maybe<Scalars['String']>;
  rhs_main_colour_ends_with?: Maybe<Scalars['String']>;
  rhs_main_colour_not_ends_with?: Maybe<Scalars['String']>;
  rhs_secondary_colour?: Maybe<Scalars['String']>;
  rhs_secondary_colour_not?: Maybe<Scalars['String']>;
  rhs_secondary_colour_in?: Maybe<Array<Scalars['String']>>;
  rhs_secondary_colour_not_in?: Maybe<Array<Scalars['String']>>;
  rhs_secondary_colour_lt?: Maybe<Scalars['String']>;
  rhs_secondary_colour_lte?: Maybe<Scalars['String']>;
  rhs_secondary_colour_gt?: Maybe<Scalars['String']>;
  rhs_secondary_colour_gte?: Maybe<Scalars['String']>;
  rhs_secondary_colour_contains?: Maybe<Scalars['String']>;
  rhs_secondary_colour_not_contains?: Maybe<Scalars['String']>;
  rhs_secondary_colour_starts_with?: Maybe<Scalars['String']>;
  rhs_secondary_colour_not_starts_with?: Maybe<Scalars['String']>;
  rhs_secondary_colour_ends_with?: Maybe<Scalars['String']>;
  rhs_secondary_colour_not_ends_with?: Maybe<Scalars['String']>;
  cultivar?: Maybe<Scalars['String']>;
  cultivar_not?: Maybe<Scalars['String']>;
  cultivar_in?: Maybe<Array<Scalars['String']>>;
  cultivar_not_in?: Maybe<Array<Scalars['String']>>;
  cultivar_lt?: Maybe<Scalars['String']>;
  cultivar_lte?: Maybe<Scalars['String']>;
  cultivar_gt?: Maybe<Scalars['String']>;
  cultivar_gte?: Maybe<Scalars['String']>;
  cultivar_contains?: Maybe<Scalars['String']>;
  cultivar_not_contains?: Maybe<Scalars['String']>;
  cultivar_starts_with?: Maybe<Scalars['String']>;
  cultivar_not_starts_with?: Maybe<Scalars['String']>;
  cultivar_ends_with?: Maybe<Scalars['String']>;
  cultivar_not_ends_with?: Maybe<Scalars['String']>;
  species?: Maybe<Scalars['String']>;
  species_not?: Maybe<Scalars['String']>;
  species_in?: Maybe<Array<Scalars['String']>>;
  species_not_in?: Maybe<Array<Scalars['String']>>;
  species_lt?: Maybe<Scalars['String']>;
  species_lte?: Maybe<Scalars['String']>;
  species_gt?: Maybe<Scalars['String']>;
  species_gte?: Maybe<Scalars['String']>;
  species_contains?: Maybe<Scalars['String']>;
  species_not_contains?: Maybe<Scalars['String']>;
  species_starts_with?: Maybe<Scalars['String']>;
  species_not_starts_with?: Maybe<Scalars['String']>;
  species_ends_with?: Maybe<Scalars['String']>;
  species_not_ends_with?: Maybe<Scalars['String']>;
  genus?: Maybe<Scalars['String']>;
  genus_not?: Maybe<Scalars['String']>;
  genus_in?: Maybe<Array<Scalars['String']>>;
  genus_not_in?: Maybe<Array<Scalars['String']>>;
  genus_lt?: Maybe<Scalars['String']>;
  genus_lte?: Maybe<Scalars['String']>;
  genus_gt?: Maybe<Scalars['String']>;
  genus_gte?: Maybe<Scalars['String']>;
  genus_contains?: Maybe<Scalars['String']>;
  genus_not_contains?: Maybe<Scalars['String']>;
  genus_starts_with?: Maybe<Scalars['String']>;
  genus_not_starts_with?: Maybe<Scalars['String']>;
  genus_ends_with?: Maybe<Scalars['String']>;
  genus_not_ends_with?: Maybe<Scalars['String']>;
  minimum_stem_length?: Maybe<Scalars['Int']>;
  minimum_stem_length_not?: Maybe<Scalars['Int']>;
  minimum_stem_length_in?: Maybe<Array<Scalars['Int']>>;
  minimum_stem_length_not_in?: Maybe<Array<Scalars['Int']>>;
  minimum_stem_length_lt?: Maybe<Scalars['Int']>;
  minimum_stem_length_lte?: Maybe<Scalars['Int']>;
  minimum_stem_length_gt?: Maybe<Scalars['Int']>;
  minimum_stem_length_gte?: Maybe<Scalars['Int']>;
  minimum_stem_length_unit?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_not?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_in?: Maybe<Array<Scalars['String']>>;
  minimum_stem_length_unit_not_in?: Maybe<Array<Scalars['String']>>;
  minimum_stem_length_unit_lt?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_lte?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_gt?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_gte?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_contains?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_not_contains?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_starts_with?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_not_starts_with?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_ends_with?: Maybe<Scalars['String']>;
  minimum_stem_length_unit_not_ends_with?: Maybe<Scalars['String']>;
  product_group?: Maybe<Scalars['String']>;
  product_group_not?: Maybe<Scalars['String']>;
  product_group_in?: Maybe<Array<Scalars['String']>>;
  product_group_not_in?: Maybe<Array<Scalars['String']>>;
  product_group_lt?: Maybe<Scalars['String']>;
  product_group_lte?: Maybe<Scalars['String']>;
  product_group_gt?: Maybe<Scalars['String']>;
  product_group_gte?: Maybe<Scalars['String']>;
  product_group_contains?: Maybe<Scalars['String']>;
  product_group_not_contains?: Maybe<Scalars['String']>;
  product_group_starts_with?: Maybe<Scalars['String']>;
  product_group_not_starts_with?: Maybe<Scalars['String']>;
  product_group_ends_with?: Maybe<Scalars['String']>;
  product_group_not_ends_with?: Maybe<Scalars['String']>;
  application_group?: Maybe<Scalars['String']>;
  application_group_not?: Maybe<Scalars['String']>;
  application_group_in?: Maybe<Array<Scalars['String']>>;
  application_group_not_in?: Maybe<Array<Scalars['String']>>;
  application_group_lt?: Maybe<Scalars['String']>;
  application_group_lte?: Maybe<Scalars['String']>;
  application_group_gt?: Maybe<Scalars['String']>;
  application_group_gte?: Maybe<Scalars['String']>;
  application_group_contains?: Maybe<Scalars['String']>;
  application_group_not_contains?: Maybe<Scalars['String']>;
  application_group_starts_with?: Maybe<Scalars['String']>;
  application_group_not_starts_with?: Maybe<Scalars['String']>;
  application_group_ends_with?: Maybe<Scalars['String']>;
  application_group_not_ends_with?: Maybe<Scalars['String']>;
  head_number?: Maybe<Scalars['String']>;
  head_number_not?: Maybe<Scalars['String']>;
  head_number_in?: Maybe<Array<Scalars['String']>>;
  head_number_not_in?: Maybe<Array<Scalars['String']>>;
  head_number_lt?: Maybe<Scalars['String']>;
  head_number_lte?: Maybe<Scalars['String']>;
  head_number_gt?: Maybe<Scalars['String']>;
  head_number_gte?: Maybe<Scalars['String']>;
  head_number_contains?: Maybe<Scalars['String']>;
  head_number_not_contains?: Maybe<Scalars['String']>;
  head_number_starts_with?: Maybe<Scalars['String']>;
  head_number_not_starts_with?: Maybe<Scalars['String']>;
  head_number_ends_with?: Maybe<Scalars['String']>;
  head_number_not_ends_with?: Maybe<Scalars['String']>;
  head_size?: Maybe<Scalars['String']>;
  head_size_not?: Maybe<Scalars['String']>;
  head_size_in?: Maybe<Array<Scalars['String']>>;
  head_size_not_in?: Maybe<Array<Scalars['String']>>;
  head_size_lt?: Maybe<Scalars['String']>;
  head_size_lte?: Maybe<Scalars['String']>;
  head_size_gt?: Maybe<Scalars['String']>;
  head_size_gte?: Maybe<Scalars['String']>;
  head_size_contains?: Maybe<Scalars['String']>;
  head_size_not_contains?: Maybe<Scalars['String']>;
  head_size_starts_with?: Maybe<Scalars['String']>;
  head_size_not_starts_with?: Maybe<Scalars['String']>;
  head_size_ends_with?: Maybe<Scalars['String']>;
  head_size_not_ends_with?: Maybe<Scalars['String']>;
  head_size_min?: Maybe<Scalars['String']>;
  head_size_min_not?: Maybe<Scalars['String']>;
  head_size_min_in?: Maybe<Array<Scalars['String']>>;
  head_size_min_not_in?: Maybe<Array<Scalars['String']>>;
  head_size_min_lt?: Maybe<Scalars['String']>;
  head_size_min_lte?: Maybe<Scalars['String']>;
  head_size_min_gt?: Maybe<Scalars['String']>;
  head_size_min_gte?: Maybe<Scalars['String']>;
  head_size_min_contains?: Maybe<Scalars['String']>;
  head_size_min_not_contains?: Maybe<Scalars['String']>;
  head_size_min_starts_with?: Maybe<Scalars['String']>;
  head_size_min_not_starts_with?: Maybe<Scalars['String']>;
  head_size_min_ends_with?: Maybe<Scalars['String']>;
  head_size_min_not_ends_with?: Maybe<Scalars['String']>;
  head_size_max?: Maybe<Scalars['String']>;
  head_size_max_not?: Maybe<Scalars['String']>;
  head_size_max_in?: Maybe<Array<Scalars['String']>>;
  head_size_max_not_in?: Maybe<Array<Scalars['String']>>;
  head_size_max_lt?: Maybe<Scalars['String']>;
  head_size_max_lte?: Maybe<Scalars['String']>;
  head_size_max_gt?: Maybe<Scalars['String']>;
  head_size_max_gte?: Maybe<Scalars['String']>;
  head_size_max_contains?: Maybe<Scalars['String']>;
  head_size_max_not_contains?: Maybe<Scalars['String']>;
  head_size_max_starts_with?: Maybe<Scalars['String']>;
  head_size_max_not_starts_with?: Maybe<Scalars['String']>;
  head_size_max_ends_with?: Maybe<Scalars['String']>;
  head_size_max_not_ends_with?: Maybe<Scalars['String']>;
  head_size_unit?: Maybe<Scalars['String']>;
  head_size_unit_not?: Maybe<Scalars['String']>;
  head_size_unit_in?: Maybe<Array<Scalars['String']>>;
  head_size_unit_not_in?: Maybe<Array<Scalars['String']>>;
  head_size_unit_lt?: Maybe<Scalars['String']>;
  head_size_unit_lte?: Maybe<Scalars['String']>;
  head_size_unit_gt?: Maybe<Scalars['String']>;
  head_size_unit_gte?: Maybe<Scalars['String']>;
  head_size_unit_contains?: Maybe<Scalars['String']>;
  head_size_unit_not_contains?: Maybe<Scalars['String']>;
  head_size_unit_starts_with?: Maybe<Scalars['String']>;
  head_size_unit_not_starts_with?: Maybe<Scalars['String']>;
  head_size_unit_ends_with?: Maybe<Scalars['String']>;
  head_size_unit_not_ends_with?: Maybe<Scalars['String']>;
  maturity?: Maybe<Scalars['String']>;
  maturity_not?: Maybe<Scalars['String']>;
  maturity_in?: Maybe<Array<Scalars['String']>>;
  maturity_not_in?: Maybe<Array<Scalars['String']>>;
  maturity_lt?: Maybe<Scalars['String']>;
  maturity_lte?: Maybe<Scalars['String']>;
  maturity_gt?: Maybe<Scalars['String']>;
  maturity_gte?: Maybe<Scalars['String']>;
  maturity_contains?: Maybe<Scalars['String']>;
  maturity_not_contains?: Maybe<Scalars['String']>;
  maturity_starts_with?: Maybe<Scalars['String']>;
  maturity_not_starts_with?: Maybe<Scalars['String']>;
  maturity_ends_with?: Maybe<Scalars['String']>;
  maturity_not_ends_with?: Maybe<Scalars['String']>;
  componentConfig?: Maybe<ComponentConfigWhereInput>;
  searchable?: Maybe<Scalars['String']>;
  searchable_not?: Maybe<Scalars['String']>;
  searchable_in?: Maybe<Array<Scalars['String']>>;
  searchable_not_in?: Maybe<Array<Scalars['String']>>;
  searchable_lt?: Maybe<Scalars['String']>;
  searchable_lte?: Maybe<Scalars['String']>;
  searchable_gt?: Maybe<Scalars['String']>;
  searchable_gte?: Maybe<Scalars['String']>;
  searchable_contains?: Maybe<Scalars['String']>;
  searchable_not_contains?: Maybe<Scalars['String']>;
  searchable_starts_with?: Maybe<Scalars['String']>;
  searchable_not_starts_with?: Maybe<Scalars['String']>;
  searchable_ends_with?: Maybe<Scalars['String']>;
  searchable_not_ends_with?: Maybe<Scalars['String']>;
  AND?: Maybe<Array<WholesaleTradeLineItemWhereInput>>;
  OR?: Maybe<Array<WholesaleTradeLineItemWhereInput>>;
  NOT?: Maybe<Array<WholesaleTradeLineItemWhereInput>>;
};

export type WholesaleTradeLineItemWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  wholesaler_line_item_id?: Maybe<Scalars['String']>;
};

export type MerchantsDataForToggle = {
  __typename?: 'merchantsDataForToggle';
  id: Scalars['ID'];
  title: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  currency: Currency;
  address?: Maybe<MerchantsDataForToggleAddress>;
};

export type MerchantsDataForToggleAddress = {
  __typename?: 'merchantsDataForToggleAddress';
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

import { FC, Fragment, ReactNode, useMemo } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Currency } from 'generated-types.d';

import SelectedWholesalePreOrderStore from 'stores/selected-wholesale-pre-order/selected-wholesale-pre-order-store';

import {
  GrandTotalRow,
  TotalRow,
  TotalsTable,
  TotalToBeCalculatedColumn,
  TotalValueColumn
} from 'features/lists/lists.styles';
import { WholesaleCheckoutHelpers } from 'features/wholesale/helpers/wholesale-helpers';

export const SinglePreOrderTotals: FC<{
  selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
}> = inject((stores: FxStores) => ({
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
}))(observer(({
  selectedWholesalePreOrderStore
}) => {
  const totals = selectedWholesalePreOrderStore!.preOrder?.totals;

  if (!selectedWholesalePreOrderStore!.preOrder || !totals) return null;

  const merchantCurrency = selectedWholesalePreOrderStore!.preOrder?.merchant?.currency;
  const subTotal = selectedWholesalePreOrderStore!.preOrder.totals!.subTotalAmount;
  const grandTotal = selectedWholesalePreOrderStore!.preOrder.totals!.grandTotalAmount;
  const shippingTotal = selectedWholesalePreOrderStore!.preOrder.totals!.shippingAmount;
  const salesTax = selectedWholesalePreOrderStore!.preOrder.totals!.salesTaxAmount;
  const grandTotalWithCurrency = useMemo(() => WholesaleCheckoutHelpers.renderWholesalePrice(grandTotal, merchantCurrency), [grandTotal]);

  const isLoading = selectedWholesalePreOrderStore!.isLoadingTotals || !!selectedWholesalePreOrderStore?.itemIdsBeingEdited.size;
  const opacityStyle = {
    opacity: isLoading ? .4 : 1
  };

  const shippingDisplay = useMemo((): ReactNode => {
    switch (shippingTotal) {
      case 0: {
        return 'Free';
      }

      case (null || undefined): {
        return 'Unknown';
      }

      default: {
        return WholesaleCheckoutHelpers.renderWholesalePrice(shippingTotal!, merchantCurrency);
      }
    }
  }, [shippingTotal]);

  const renderShippingAndTaxRows = (): ReactNode => {
    if (merchantCurrency === Currency.Usd) {
      return (
        <Fragment>
          <TotalRow>
            <Box>Shipping</Box>
            <TotalToBeCalculatedColumn>
              To be calculated
            </TotalToBeCalculatedColumn>
          </TotalRow>
          <TotalRow>
            <Box>Sales Tax</Box>
            <TotalToBeCalculatedColumn>
              To be calculated
            </TotalToBeCalculatedColumn>
          </TotalRow>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <TotalRow>
          <Box fontWeight={600}>
            Subtotal
          </Box>
          <TotalValueColumn>
            <Box
              fontWeight={600}
              style={opacityStyle}
            >
              {WholesaleCheckoutHelpers.renderWholesalePrice(subTotal!, merchantCurrency)}
            </Box>
          </TotalValueColumn>
        </TotalRow>
        <TotalRow>
          <Box>
            Shipping
          </Box>
          <TotalValueColumn>
            <Box style={opacityStyle}>
              {shippingDisplay}
            </Box>
          </TotalValueColumn>
        </TotalRow>
        { !!salesTax && (
          <TotalRow>
            <Box>
              VAT
            </Box>
            <TotalValueColumn
              style={opacityStyle}
            >
              {WholesaleCheckoutHelpers.renderWholesalePrice(salesTax, merchantCurrency)}
            </TotalValueColumn>
          </TotalRow>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <TotalsTable>
        {renderShippingAndTaxRows()}
      </TotalsTable>
      <GrandTotalRow>
        <Box fontWeight={600}>
          Total
        </Box>
        <TotalValueColumn
          fontWeight={600}
          style={opacityStyle}
        >
          {grandTotalWithCurrency}
        </TotalValueColumn>
      </GrandTotalRow>
    </Fragment>
  );
}));

import { Component, Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { PermissionsService } from 'lib';

import { Container } from 'utils/css-mixins';
import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';

import { WholesaleShopHeader } from 'features/wholesale/components/wholesale-shop-header/wholesale-shop-header';
import WholesaleServices from 'features/wholesale/services';

import Button from 'components/button';
import { EntityListItems } from 'components/entity-list/entity-list.styles';
import TableLayoutEntityHeading from 'components/entity-list/table-layout-entity-heading';
import NoResultsGeneric from 'components/no-results-generic';
import SectionHeading from 'components/section-heading';
import WithLoading from 'components/with-loading';

import {
  WHOLESALE_DELIVERY_SETTINGS_PERMISSIONS,
  DELIVERY_COLUMN_HEADINGS
} from '../../wholesale.constants';

import CreditOverview from './components/credit-overview/credit-overview';
import SupplierIntegrations from './components/supplier-integrations/supplier-integrations';
import WholesaleDeliveryItem from './components/wholesale-delivery-item/wholesale-delivery-item';
import {
  LoadMoreLink
} from './settings.styles';
import {
  WholesaleSettingsTypes
} from './settings.types';

class WholesaleSettings extends Component<WholesaleSettingsTypes> {
  private wholesaleSettingsService = WholesaleServices.WholesaleSettingsService;

  state = {
    isCreatingConfig: false
  };

  componentDidMount(): void {
    this.wholesaleSettingsService.fetchWholesaleDeliveryConfigs();
    this.props.wholesaleSettingsStore!.fetchSupplierDeliveryHubs();
  }

  componentWillUnmount(): void {
    this.props.wholesaleSettingsStore!.resetStore();
  }

  private triggerChatWindow = (): void => {
    this.props.chatWidgetStore!.enableChatWidget();
    this.props.chatWidgetStore!.suppressHelpCenter();
    window['zE']?.('webWidget', 'chat:send', 'Hey FloomX team! I would like to change my Wholesale delivery config');
  };

  private createEmptyConfig = async (): Promise<void> => {
    this.setState({ isCreatingConfig: true });

    try {
      await this.wholesaleSettingsService.createBlankDeliveryConfig();
      this.setState({ isCreatingConfig: false });
    } catch (error) {
      this.setState({ isCreatingConfig: false });
    }
  };

  private renderDeliveryLocations = (): ReactNode => {
    return (
      <Box
        css={css`
          overflow-x: auto;
          padding: 10px;
          margin: -10px;
        `}
      >
        <Box
          mb="50px"
          css={{
            minWidth: '950px'
          }}
        >
          <TableLayoutEntityHeading headers={DELIVERY_COLUMN_HEADINGS} />
          <EntityListItems>
            {this.props.wholesaleSettingsStore?.deliveryConfigs.map(config => (
              <WholesaleDeliveryItem
                key={config.id}
                config={config}
              />
            ))}
          </EntityListItems>
        </Box>
      </Box>
    );
  };

  private renderNoResultsCopy = (): ReactNode => {
    const isInternal = PermissionsService.isInternalRole();
    const heading = isInternal ? 'Your selected merchant does not' : 'You do not';
    const isDisabled = this.state.isCreatingConfig;

    return (
      <Fragment>
        <span>{heading} have any wholesale delivery configs yet.</span>
        {' '}
        { isInternal && (
          <Fragment>
            You can
            {' '}
            <LoadMoreLink
              as="button"
              fontWeight="bold"
              disabled={isDisabled}
              onClick={(): Promise<void> => this.createEmptyConfig()}
            >
              {isDisabled ? 'creating config...' : 'create a blank config'}
            </LoadMoreLink>
            {' '}
            for them now if you like
          </Fragment>
        )
        }
      </Fragment>
    );
  };

  private renderNoResults = (): ReactNode => {
    return (
      <Box mt="20px">
        <NoResultsGeneric
          icon="leaf-no-results"
          heading="No configs found"
          copy={this.renderNoResultsCopy()}
        />
      </Box>
    );
  };

  render(): JSX.Element {
    return (
      <Fragment>
        <WholesaleShopHeader
          title="Wholesale settings"
          shouldDisplay={{
            deliveryDate: true
          }}
        />
        <Container>
          <Box mt="40px">
            <CreditOverview />
          </Box>
          <SupplierIntegrations />
          <Box mt="50px">
            <SectionHeading
              title="Delivery locations"
              hasSpacing={false}
            />
          </Box>
          <WithLoading
            isLoading={this.props.wholesaleSettingsStore!.isLoadingConfigs}
            hasNoResults={!this.props.wholesaleSettingsStore!.deliveryConfigs?.length && !this.props.wholesaleSettingsStore!.isLoadingConfigs}
            renderNoResults={this.renderNoResults}
          >
            {this.renderDeliveryLocations()}
          </WithLoading>
          <FloomXRestrictedComponent
            restrictedRoles={WHOLESALE_DELIVERY_SETTINGS_PERMISSIONS.contactButton.restrictedRoles}
            considerSuperRole={true}
          >
            <Box mt="40px">
              <p>Need your address changed? Get in touch with us</p>
              <Flex mt="20px">
                <Box onClick={this.triggerChatWindow}>
                  <Button copy="Contact us" />
                </Box>
              </Flex>
            </Box>
          </FloomXRestrictedComponent>
        </Container>
      </Fragment>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  wholesaleSettingsStore: stores.wholesaleSettingsStore,
  chatWidgetStore: stores.chatWidgetStore
}))(observer(WholesaleSettings));

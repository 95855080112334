import { CookieSetOptions } from 'universal-cookie';

import CookieService from '../cookies/cookies.service';

import * as Types from './cache.service.types';

const rootOptions: CookieSetOptions = {
  path: '/'
};

export default class CacheService {
  public static storeCookieObject = (
    cookieObject: Types.CookieOption,
    cookieOptions: CookieSetOptions = {}
  ): void => {
    const data: Types.CookieToStore = {
      content: cookieObject,
      version: process.env.APP_VERSION!
    };

    CookieService.set(
      cookieObject.name,
      JSON.stringify(data),
      {
        ...rootOptions,
        ...cookieOptions
      }
    );
  };

  public static retrieveCookie = <T = Types.CookieOption>(
    name: Types.CookieName,
    shouldIgnoreVersion: boolean = false
  ): Types.CookieToStore<T> | null => {
    const cookie: Types.CookieToStore<T> = CookieService.get(name);

    if (!!cookie && cookie?.version !== process.env.APP_VERSION && !shouldIgnoreVersion) {
      CookieService.remove(name);

      return null;
    }

    return cookie;
  };

  public static removeCookie = (name: Types.CookieName): void => {
    CookieService.remove(name, { path: '/' });
  };
}

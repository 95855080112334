import { phoneString } from 'lib/services/validation/extensions/phone';
import * as Yup from 'yup';

import { Merchant, Currency } from 'generated-types.d';

import { ValidationService } from 'lib';

import { AccountDetailsField } from './account-details.types';

export const ACCOUNT_DETAILS_FIELDS: AccountDetailsField[] = [
  {
    key: 'givenName',
    inputType: 'text',
    label: 'First Name',
    placeholder: 'First Name',
    maxLength: 100,
    shouldDisplayError: true,
    isOptional: false,
    isLocked: (self): boolean => !self.state.editMode
  },
  {
    key: 'familyName',
    inputType: 'text',
    label: 'Last Name',
    placeholder: 'First Name',
    maxLength: 100,
    shouldDisplayError: false,
    isOptional: true,
    isLocked: (self): boolean => !self.state.editMode
  },
  {
    key: 'email',
    label: 'Primary email address',
    placeholder: 'email address',
    maxLength: 100,
    shouldDisplayError: false,
    isOptional: true,
    isLocked: (): boolean => true
  },
  {
    key: 'password',
    inputType: 'password',
    label: 'Password',
    secondaryLinkCopy: 'Reset Password',
    value: `${'          '}`, // By design - simulates mock password value
    shouldDisplayError: false,
    isOptional: true,
    isLocked: (): boolean => true,
    secondaryAction: (self): void => self.togglePasswordModal()
  },
  {
    key: 'secondaryEmail',
    label: 'Secondary email address',
    placeholder: 'Secondary email',
    maxLength: 100,
    shouldDisplayError: false,
    isOptional: true,
    isLocked: (self): boolean => {
      return !self.state.editMode
        || (self.state.editMode && self.props.userStore!.isTeamRole());
    }
  },
  {
    key: 'phone',
    inputType: 'tel',
    label: 'Primary phone number',
    placeholder: 'Primary phone',
    errorMessage: 'Primary phone number must be provided',
    maxLength: 30,
    shouldDisplayError: true,
    isOptional: false,
    isLocked: (self): boolean => !self.state.editMode
  },
  {
    key: 'secondaryPhone',
    inputType: 'tel',
    label: 'Secondary phone number',
    placeholder: 'Secondary phone',
    maxLength: 30,
    shouldDisplayError: true,
    isOptional: true,
    isLocked: (self): boolean => !self.state.editMode
  }
];

const ACCOUNT_DETAILS_FORM_VALIDATION: Yup.Schema<any> =
  Yup.object().shape({
    givenName: Yup
      .string()
      .trim()
      .required(params => ValidationService.generateYupMessageSchema(params, 'First name must be provided')),
    familyName: Yup
      .string()
      .trim(),
    secondaryEmail: Yup
      .string()
      .trim()
      .email(),
    phone: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Primary phone number must be provided')),
    secondaryPhone: Yup
      .string()
  });

export const getAccountFormValidation = (merchant: Partial<Merchant> | null): Yup.Schema<any> => {
  if (merchant !== null && merchant.currency) {
    const countryCode = merchant.currency === Currency.Usd ? 'US' : 'GB';

    return ACCOUNT_DETAILS_FORM_VALIDATION.concat(Yup.object().shape({
      phone: phoneString('phone', countryCode),
      secondaryPhone: phoneString('secondaryPhone', countryCode)
    }));
  }

  return ACCOUNT_DETAILS_FORM_VALIDATION;
};

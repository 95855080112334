import { Component, ReactNode } from 'react';

import { Order, UserRole } from 'generated-types.d';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';

interface MerchantTitleMetaProps {
  data: Order;
}

class MerchantTitleMeta extends Component<MerchantTitleMetaProps> {
  render(): ReactNode {
    return (
      <FloomXRestrictedComponent
        restrictedRoles={[UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember]}
      >
        <MetaCardContentWrapper iconName="flower-front">
          {this.props.data.merchant.title}
        </MetaCardContentWrapper>
      </FloomXRestrictedComponent>
    );
  }
}

export default MerchantTitleMeta;

import React from 'react';

import * as Styles from './input-max-length.styles';
import * as Types from './input-max-length.types';

const InputMaxLength = (props: Types.InputMaxLengthProps): JSX.Element => {
  const maxLength = props.maxLength;
  const currentLength = props.currentLength;

  return (
    <Styles.InputMaxLengthWrapper>
      <Styles.InputMaxLength>{currentLength}/{maxLength}</Styles.InputMaxLength>
    </Styles.InputMaxLengthWrapper>
  );
};

export default InputMaxLength;

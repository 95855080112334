import { Currency, Locale } from 'generated-types.d';

export interface TermsResult {
  copy: string;
  id: string;
  title: string;
  updatedAt: string;
  version: number;
  locale: string;
}

export type TermsPage =
  | 'termsConditionsPage'
  | 'cookiePolicyPage'
  | 'privacyPolicyPage';

export const TermsLocaleMap: { [currency in Currency]: Locale } = {
  [Currency.Usd]: Locale.EnUs,
  [Currency.Gbp]: Locale.EnGb
};

import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import { mq } from 'theme';

import { Container } from 'utils/css-mixins';
import {
  colors,
  textStyles,
  shadows,
  borderRadius,
  breakpoints
} from 'utils/rebass-theme';

export const FiltersWrapper = styled.div<{ isVisible: boolean }>`
  padding: 30px;
  background: ${colors.uiFills};
  border-radius: ${borderRadius.large};
  box-shadow: ${shadows.small};
  display: ${(props): string => props.isVisible ? 'block' : 'none'};

  @media (max-width: ${breakpoints.large}) {
    padding: 25px;
  }
`;

export const FiltersContainer = styled(Container)`
  padding: 0;
`;

export const CategoryTabs = styled.ol<any>`
  margin: 0;
  display: inline-flex;
  flex-wrap: wrap;
`;

export const CategoryTabsContainer = styled.div`
  position: relative;
  display: flex;
`;

const getLinearGradient = (position: 'left' | 'right'): SerializedStyles => {
  return css`
    background: linear-gradient(${position === 'right' ? '270' : '90'}deg, rgba(255,255,255,1) 15%, rgba(255,255,255,0) 100%);
  `;
};

interface BookendGradientStyleProps {
  position: 'left' | 'right';
}

export const CategoryTabsBookendGradient = styled.div<BookendGradientStyleProps>`
  display: none;
  width: 30px;
  height: 100%;
  position: absolute;
  background: red;
  pointer-events: none;
  top: 0px;
  ${({ position }: BookendGradientStyleProps): any => position && css`
      ${position === 'right' && 'right: 0px'};
      ${getLinearGradient(position)};
  `};

  ${mq('medium')} {
    display: block;
  };
`;

export const CategoryTab = styled.li<any>`
  ${textStyles.subhead};
  cursor: pointer;
  position: relative;
  margin: 0;
  padding: 0px 4px 15px 4px;
  list-style: none;
  display: inline-block;
  color: ${(props: any): any => props.isActive ? colors.uiFills : colors.floomMidnightBlue};
  background: ${(props: any): any => props.isActive ? colors.floomMidnightBlue : colors.uiFills};
  border: 2px solid ${colors.floomMidnightBlue};
  padding: 4px 10px 6px;
  border-radius: ${borderRadius.medium};
  margin: 0 10px 10px 0;
`;

export const ClearFilters = styled(Box)`
  ${textStyles.subhead};
  display: flex;
  flex: 0 0 100px;
  justify-content: flex-end;
  padding: 0;
  margin: 5px 0 0 auto;
`;

export const CategoryTabContent = styled.div`
  padding-top: 30px;

  @media (max-width: ${breakpoints.large}) {
    padding-top: 20px;
  }
`;

import parsePhoneNumber from 'libphonenumber-js';

export const formatNumber = (number: string): string => {
  const parsedNumber = parsePhoneNumber(number);

  if (!parsedNumber?.isValid()) {
    return number;
  }

  return parsedNumber?.formatNational();
};

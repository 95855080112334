import React, { Component } from 'react';

import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import Helmet from 'react-helmet';

import UIStore from 'stores/ui-store';

import { getCurrentBreakpoint } from 'utils/rebass-theme';

import * as Styles from './global-layout.styles';

interface GlobalLayoutProps {
  uiStore?: UIStore;
}

class GlobalLayout extends Component<GlobalLayoutProps> {
  componentDidMount(): void {
    this.setBreakpoint();
    window.addEventListener('resize', debounce(this.setBreakpoint, 70));
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.setBreakpoint);
  }

  setBreakpoint = (): void => {
    this.props.uiStore!.setBreakpoint(getCurrentBreakpoint(window.innerWidth));
  };

  render(): React.ReactNode {
    const devPrefix = process.env.SAASSTAGE !== 'production' ? `[${process.env.SAASSTAGE}] ` : '';

    return (
      <Styles.LayoutStyles>
        <Helmet
          titleTemplate={`${devPrefix}%s - FloomX`}
        />
        {this.props.children}
      </Styles.LayoutStyles>
    );
  }
}

export default inject('uiStore')(observer(GlobalLayout));

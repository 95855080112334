import React, { Component, ReactNode } from 'react';

import { Link } from 'gatsby';
import { inject, observer } from 'mobx-react';

import * as Styles from './data-counts.styles';
import * as Types from './data-counts.types';

class DataCounts extends Component<Types.DataCountsProps> {
  private renderItems = (): ReactNode => {
    return this.props.items.map((item, index)  => {
      return (
        <Styles.CountBox
          key={index}
          numbercolor={item.color}
          as={item.url ? Link : 'div'}
          {...(item.url ? {
            to: item.url
          } : {})}
        >
          <h5>{item.title}</h5>
          <h1>
            {item.count || '0'}
          </h1>
        </Styles.CountBox>
      );
    });
  };

  render(): ReactNode {
    return(
      <Styles.DataCountWrapper>
        {this.renderItems()}
      </Styles.DataCountWrapper>
    );
  }
}

export  default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore
}))(observer(DataCounts));

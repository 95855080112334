import React, { ChangeEvent, Component } from 'react';

import { css } from '@emotion/react';
import { Flex } from 'rebass';

import {
  CurrencyService,
  NavService
} from 'lib';

import { colors } from 'utils/rebass-theme';

import AddOnsServices from 'features/add-ons/services';

import { EntityListRow } from 'components/entity-list/entity-list.styles';
import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType } from 'components/entity-list/table-layout-entity.types';

import * as Types from './table-layout-group.types';

export default class TableLayoutItem extends Component<Types.TableLayoutItemProps, Types.TableLayoutItemState> {
  private AddOnsListService = AddOnsServices.AddOnsListService;

  state: Types.TableLayoutItemState = {
    isSaving: false,
    optimisticallyActive: false,
    isActive: false,
    priceState: this.props.item.price!,
    stockState: this.props.item.stock!
  };

  private toggleActiveState = (isChecked: boolean): void => {
    this.AddOnsListService.toggleActiveStatus(this.props.item.id, isChecked);

    this.setState({
      optimisticallyActive: true,
      isActive: isChecked
    });
  };

  private onValueChange = ({ target }: ChangeEvent<HTMLInputElement>, key: Types.FieldKey): void => {
    // @ts-ignore
    this.setState({
      [key]: target.value.length ? parseFloat(target.value) : target.value
    });
  };

  private onAddOnEdit = (): void => {
    NavService.addOnEdit(this.props.item.id);
  };

  private updateAddOn = async (): Promise<any> => {
    if (this.hasChanged()) {
      this.setState({ isSaving: true });

      await this.AddOnsListService.quickEditAddOn({
        price: this.generateValue(this.state.priceState),
        stock: this.generateValue(this.state.stockState)
      }, this.props.item.id);

      this.setState({ isSaving: false });
    }
  };

  private generateValue = (value: any): number => !this.isString(value) ? value : 0;

  private isString = (value: any): boolean => typeof value === 'string' || value instanceof String;

  private hasChanged = (): boolean => this.hasStockChanged() || this.hasPriceChanged();

  private hasStockChanged = (): boolean => this.props.item.stock !== this.state.stockState;

  private hasPriceChanged = (): boolean => this.props.item.price !== this.state.priceState;

  private renderSelectedProductsText(item: any): React.ReactNode {
    return !!item.allProducts ? 'All Products' : 'Selected Products';
  }

  private cellConfig = (): any => {
    return [
      {
        cellType: CellType.CheckboxToggle,
        props: {
          onChange: this.toggleActiveState,
          switchBgActive: colors.validationText,
          switchBgInactive: colors.errorText,
          isChecked: this.state.optimisticallyActive ? this.state.isActive : this.props.item.active
        },
        fixedWidth: '90px'
      },
      {
        cellType: CellType.Image,
        props: {
          path: this.props.item.media ? this.props.item.media!.src : null
        },
        fixedWidth: '80px'
      },
      {
        cellType: CellType.LinkTitle,
        props: {
          linkTitle: {
            text: this.props.item.title,
            onClick: this.onAddOnEdit
          },
          subtitle: this.props.item.brand
        },
        flexGrow: '2',
        flexBasis: '150px'
      },
      {
        cellType: CellType.Text,
        props: {
          text: this.props.item.category.title
        },
        flexGrow: '1',
        flexBasis: '75px'
      },
      {
        cellType: CellType.Text,
        props: {
          text: this.renderSelectedProductsText(this.props.item)
        },
        flexGrow: '1',
        flexBasis: '100px'
      },
      {
        cellType: CellType.Input,
        props: {
          type: 'number',
          value: this.state.stockState,
          onChange: (e: any): void => this.onValueChange(e, 'stockState'),
          saveDisabled: !this.hasStockChanged(),
          onInputSave: this.updateAddOn,
          uniqueId: 'stock',
          isSaving: this.state.isSaving
        },
        fixedWidth: '170px',
        customCss: css`display: flex; align-items: center;`
      },
      {
        cellType: CellType.Input,
        props: {
          prefix: CurrencyService.renderCurrencySymbol(this.props.item.merchant.currency),
          type: 'number',
          value: this.state.priceState,
          onChange: (e: any): void => this.onValueChange(e, 'priceState'),
          saveDisabled: !this.hasPriceChanged(),
          onInputSave: this.updateAddOn,
          uniqueId: 'price',
          isSaving: this.state.isSaving
        },
        fixedWidth: '170px',
        customCss: css`display: flex; align-items: center;`
      }
    ];
  };

  render(): React.ReactNode {
    return (
      <EntityListRow role="rowgroup">
        <Flex
          alignItems="center"
          role="row"
          p="10px"
        >
          <TableLayoutEntity config={this.cellConfig()} />
        </Flex>
      </EntityListRow>
    );
  }
}

import { gql } from '@apollo/client';

import { GraphQLHelpers } from 'lib';

export const USER_DETAILS_QUERY = (vars: any): any => gql`
  query UserDetails(
    $id: ID!
    ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
  ) {
    user(
      where: {
        id: $id
        ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
      }
    )
    {
      id
      givenName,
      familyName,
      email,
      secondaryEmail,
      givenName,
      familyName,
      phone,
      secondaryPhone,
      role,
      jobPosition,
      merchant {
        title
        id
        currency
      }
    }
  }
`;

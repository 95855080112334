import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { Card as CardMixin } from 'utils/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const CustomSiteConfigCard = styled(Flex)`
  ${CardMixin};
  flex-direction: column;
  font-size: 14px;
  width: 100%;
`;

export const CardHeading: any = styled(Flex)`
  ${textStyles.footnote};
  padding: 15px;
  background: ${colors.lightGrey};
  align-items: center;
`;

export const CustomConfigItem: any = styled(Box)`
  flex-grow: 2;
  padding-left: 15px;
`;

export const CustomConfigIconStyles = css`
  height: 24px;
  width: 24px;
  fill: ${colors.floomMidnightBlue};
`;

export const ColourDot: any = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background: ${(props: any): any => props.colour ? props.colour : 'transparent'};
  display: block;
  border: ${colors.paleGrey} 1px solid;
`;

import { Component, ReactNode } from 'react';

import * as Sentry from '@sentry/browser';
import { inject, observer } from 'mobx-react';

import UserStore from '../../../src/stores/user/user-store';

interface RootErrorBoundaryProps {
  userStore?: UserStore;
}

class RootErrorBoundary extends Component<RootErrorBoundaryProps> {
  componentDidCatch(error: any, errorInfo: any): void {
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render(): ReactNode {
    return this.props.children;
  }
}

export default inject('userStore')(observer(RootErrorBoundary));

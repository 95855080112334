import gql from 'graphql-tag';

export const CREATE_COLLECTION_PRODUCT_CONFIG = gql`
  mutation CreateCollectionSellerProductConfig(
    $create: CollectionSellerProductConfigCreateInput
  ) {
    upsertCollectionSellerProductConfig(
      create: $create
    ) {
      id
      active
      merchant {
        id
      }
      deliveryConfigs {
        id
      }
      sourceProduct {
        id
        variations(first: 3) {
          id
          media(first: 1) {
            src
          }
        }
      }
      productVariationConfigs {
        id
        stock
        sourceProductVariation {
          id
        }
      }
    }
  }
`;

export const UPDATE_COLLECTION_PRODUCT_CONFIG = gql`
  mutation UpdateCollectionSellerProductConfig(
    $where: CollectionSellerProductConfigWhereUniqueInput,
    $update: CollectionSellerProductConfigUpdateInput
  ) {
    upsertCollectionSellerProductConfig(
      where: $where,
      update: $update
    ) {
      id
      active
      merchant {
        id
      }
      deliveryConfigs {
        id
      }
      sourceProduct {
        id
        variations(first: 3) {
          id
          media(first: 1) {
            src
          }
        }
      }
      productVariationConfigs {
        id
        stock
        sourceProductVariation {
          id
        }
      }
    }
  }
`;

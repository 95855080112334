import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding: 50px 30px;
  text-align: center;

  @media (min-width: 900px) {
    padding: 120px 30px;
  }
`;

export const Points = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 40px;
  text-align: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

import cuid from 'cuid';
import _find from 'lodash.find';
import _findIndex from 'lodash.findindex';
import { action, observable, makeObservable } from 'mobx';
import moment from 'moment';

import {
  Colour,
  MediaCategory,
  Product,
  ProductVariation as ProductVariationGenerated,
  ProductVariationType,
  Maybe,
  Media,
  Channel,
  DeliveryConfig,
  RecipeItem,
  Merchant,
  ProductType,
  ProductCategory,
  MerchantHoliday,
  MerchantHolidayUpdateInput
} from 'generated-types.d';

import {
  NavService,
  CurrencyService,
  Analytics,
  TimeService
} from 'lib';

import { GenericListObject } from 'components/generic-select-list/generic-select-list.types';

import {
  BreadcrumbTab,
  ColourListObject,
  Dimension,
  Dimensions,
  MediaItem,
  Mode,
  ProductChannel,
  ProductTab,
  ProductVariation,
  Tab
} from './product-edit-store-types';

export default class ProductEditStore {
  constructor() {
    makeObservable(this, {
      currentTab: observable,
      tabs: observable,
      canActivateProduct: observable,
      activeProductsCount: observable,
      variations: observable,
      title: observable,
      availability: observable,
      sku: observable,
      id: observable,
      isTabbing: observable,
      isProductCreateModalOpen: observable,
      selectedDeliveryItems: observable,
      currentVariationId: observable,
      selectedAddonIds: observable,
      selectedChannels: observable,
      active: observable,
      description: observable,
      internalDescription: observable,
      selectedProductTypeId: observable,
      selectedProductTypeTitle: observable,
      oldProduct: observable,
      isProductTitleADuplicate: observable,
      selectedCategoryIds: observable,
      colours: observable,
      addons: observable,
      categories: observable,
      types: observable,
      componentConfigs: observable,
      channels: observable,
      deliveryConfigs: observable,
      currentMode: observable,
      isloadingProduct: observable,
      setProductLoading: action,
      disableProductLoading: action,
      toggleProductCreateTypeModal: action,
      isRequiredMediaCategory: action,
      selectAddon: action,
      deselectAddon: action,
      setCanActivateProduct: action,
      setActiveProductCount: action,
      setEditMode: action,
      setActiveTab: action,
      setCurrentVariation: action,
      updateSelectedDeliveryItems: action,
      setVariationColours: action,
      setSelectedCategories: action,
      deselectCategory: action,
      setIsProductTitleADuplicate: action,
      selectVariationComponent: action,
      deselectVariationComponent: action,
      updateTitle: action,
      getMediaContent: action,
      updateMediaItem: action,
      createAvailability: action,
      updateAvailability: action,
      deleteAvailability: action,
      setActive: action,
      updateSelectedChannel: action,
      updateDescription: action,
      updateInternalDescription: action,
      updateSelectedProductTypeId: action,
      updateVariationDimension: action,
      resetProductData: action,
      setProductId: action,
      setCategories: action,
      setChannels: action,
      setColours: action,
      setTypes: action,
      setAddons: action,
      setComponentConfigs: action,
      setDeliveryConfigs: action,
      importProduct: action,
      addBlankVariation: action,
      deleteVariation: action,
      editProductValidationPass: action,
      areVariationDimensionsInvalid: action,
      init: action,
      toggleIsTabbing: action
    });

    this.init();
  }

  getDefaultTabs = (): any => {
    return {
      product: {
        focusText: 'Product',
        subtitleText: 'FloomX, POS, +1',
        nextButtonCopy: 'recipe',
        onClick: (): void => this.setActiveTab(ProductTab.product),
        tabIsValid: (): boolean => this.productTabValidationPass(),
        tabType: ProductTab.product
      },
      recipe: {
        focusText: 'Recipe',
        subtitleText: this.variations.length > 0 ? `${this.variations.length} variations` : undefined,
        nextButtonCopy: 'delivery',
        disabled: this.currentMode === Mode.create,
        onClick: (): void => this.setActiveTab(ProductTab.recipe),
        tabIsValid: (): boolean => this.variationTabValidationPass(),
        tabType: ProductTab.recipe
      },

      // ADDONS DISABLED - To enable:
      // 1) uncomment: this addon switch case (product-edit-store.ts)
      // 2) uncomment: this.tabs.addon.disabled (product-edit-store.ts)
      // 3) remove: max with and set min width back to 700px (create-breadcrumb-layout.styles.ts)
      // 4) update: the Recipe onClick (footer-content.tsx)

      // addon: {
      //   focusText: 'Add-on',
      //   onClick: (): void => this.setActiveTab(ProductTab.addon),
      //   nextButtonCopy: 'delivery',
      //   disabled: this.currentMode === Mode.create,
      //   tabIsValid: (): boolean => true,
      //   tabType: ProductTab.addon
      // },
      delivery: {
        focusText: 'Delivery',
        onClick: (): void => this.setActiveTab(ProductTab.delivery),
        nextButtonCopy: 'leave',
        disabled: this.currentMode === Mode.create,
        tabIsValid: (): boolean => this.deliveryItemValidationPass(),
        tabType: ProductTab.delivery
      }
    };
  };

  active: boolean = false;

  id: string | undefined = undefined;

  productMerchant: Merchant | undefined = undefined;

  sku: string | undefined = undefined;

  isTabbing: boolean = false;

  oldProduct: Product | undefined = undefined;

  canActivateProduct: boolean = false;

  activeProductsCount: number | null = null;

  isProductCreateModalOpen: boolean = false;

  isProductTitleADuplicate: boolean = false;

  description: string = '';

  internalDescription: string = '';

  currentVariationId: string | undefined = '';

  selectedCategoryIds: string[] = [];

  selectedProductTypeId: string | undefined = undefined;

  selectedProductTypeTitle: string = '';

  selectedDeliveryItems: string[] = [];

  selectedAddonIds: string[] = [];

  selectedChannels: Channel[] = [];

  availability: Array<Partial<MerchantHoliday & { isUpdated: true }>> = [];

  colours: ColourListObject[] = [];

  channels: ProductChannel[] = [];

  categories: ProductCategory[] = [];

  types: ProductType[] = [];

  componentConfigs: GenericListObject[] = [];

  addons: GenericListObject[] = [];

  deliveryConfigs: DeliveryConfig[] = [];

  variations: ProductVariation[] = [];

  currentMode: Mode = Mode.create;

  title: string = '';

  isloadingProduct: boolean = true;

  // @ts-ignore
  tabs: BreadcrumbTab = this.getDefaultTabs();

  currentTab: Tab = this.tabs.product;

  public init = (productTypeId: string | undefined = this.selectedProductTypeId): void => {
    this.active = false;
    this.description = '';
    this.internalDescription = '';
    this.currentVariationId = '';
    this.setProductId(productTypeId);
    this.productMerchant = undefined;
    this.sku = undefined;
    this.selectedCategoryIds = [];
    this.selectedProductTypeId = productTypeId;
    this.setSelectedProductTypeTitle();
    this.selectedDeliveryItems = [];
    this.selectedAddonIds = [];
    this.selectedChannels = [];
    this.availability = [];
    this.isTabbing = false;
    this.isloadingProduct = true;
    this.isProductTitleADuplicate = false;

    this.colours = [];
    this.channels = [];
    this.categories = [];
    this.componentConfigs = [];
    this.addons = [];
    this.deliveryConfigs = [];
    this.variations = [];

    this.currentMode = Mode.create;
    this.title = '';

    this.tabs = this.getDefaultTabs();

    this.currentTab = this.tabs.product;
  };

  public createAvailability = async (from: string, to: string): Promise<void> => {
    this.availability.push({
      id: cuid(),
      startAt: from,
      endAt: to
    });
  };

  public updateAvailability = async (data: MerchantHolidayUpdateInput, availabilityId: string): Promise<void> => {
    const holidayIndex = this.availability.findIndex(availbility => availbility.id === availabilityId);

    if (holidayIndex !== -1)  {
      this.availability[holidayIndex] = {
        ...this.availability[holidayIndex],
        startAt: moment(data.startAt).format('YYYY-MM-DD'),
        endAt: moment(data.endAt).add(TimeService.isSameDay(data.startAt, data.endAt) ? 1 : 0, 'd').format('YYYY-MM-DD'),
        isUpdated: true
      };
    }
  };

  public deleteAvailability = async (availabilityId: string): Promise<void> => {
    this.availability = this.availability.filter(availability => availability.id !== availabilityId);
  };

  public toggleIsTabbing = (isTabbing: boolean): void => {
    this.isTabbing = isTabbing;
  };

  public toggleProductCreateTypeModal = (): void => {
    this.isProductCreateModalOpen = !this.isProductCreateModalOpen;

    if (this.isProductCreateModalOpen) {
      Analytics.track(Analytics.FxEventName.ProductCreateStep, {
        event_version: 2,
        creation_session_id: this.id || '',
        step: 'Click create',
        product_type: 'Not selected'
      });
    }
  };

  public setProductLoading = (): void => {
    this.isloadingProduct = true;
  };

  public disableProductLoading = (): void => {
    this.isloadingProduct = false;
  };

  public setCanActivateProduct = (canActivate: boolean): void => {
    this.canActivateProduct = canActivate;
  };

  public setActiveProductCount = (count: number): void => {
    this.activeProductsCount = count;
  };

  public setProductId = (productTypeId: string | undefined): void => {
    this.id = productTypeId;
  };

  public updateMediaItem = (variationId: string, mediaKey: number, mediaId: string, src: string): void => {
    const variationIndex = _findIndex(this.variations, variation => variation.id === variationId);

    if (variationIndex > -1) {
      const selectedIndex = _findIndex(this.variations[variationIndex].mediaUploads, mediaItem => mediaItem.key === mediaKey);

      if (selectedIndex > -1) {
        this.variations[variationIndex].mediaUploads[selectedIndex].id = mediaId;
        this.variations[variationIndex].mediaUploads[selectedIndex].src = src;
      }
    }
  };

  public setActiveTab = (activeTab: ProductTab): void => {
    this.isTabbing = false;

    switch (activeTab) {
      case ProductTab.product:
        this.currentTab = this.tabs.product;
        this.tabs.product.disabled = false;

        if (this.id && this.currentMode === Mode.edit) {
          NavService.productEditTab(this.id!, 'product', this.selectedProductTypeId!);
        }

        break;

      case ProductTab.recipe:
        this.currentTab = this.tabs.recipe;
        this.tabs.recipe.disabled = false;

        if (this.id && this.currentMode === Mode.edit) {
          NavService.productEditTab(this.id!, 'recipe', this.selectedProductTypeId!);
        }

        break;

      case ProductTab.addon:
        this.currentTab = this.tabs.addon;
        this.tabs.addon.disabled = false;

        if (this.id && this.currentMode === Mode.edit) {
          NavService.productEditTab(this.id!, 'addon', this.selectedProductTypeId!);
        }

        break;

      case ProductTab.delivery:
        this.currentTab = this.tabs.delivery;
        this.tabs.delivery.disabled = false;

        if (this.id && this.currentMode === Mode.edit) {
          NavService.productEditTab(this.id!, 'delivery', this.selectedProductTypeId!);
        }

        break;
    }
  };

  public setCurrentVariation = (variationId: string): void => {
    const found = this.variations.filter(variation => variation.id === variationId);

    if (found.length > 0) {
      this.currentVariationId = variationId;
    }
  };

  public updateSelectedDeliveryItems = (deliveryId: string): void => {
    const selected = this.selectedDeliveryItems.findIndex(item => item === deliveryId);

    if (selected > -1) {
      this.selectedDeliveryItems.splice(selected, 1);
    } else {
      this.selectedDeliveryItems = this.selectedDeliveryItems.concat(deliveryId);
    }
  };

  public updateTitle = (title: string): void => {
    this.title = title;
  };

  public updateVariationPrice = (id: string, value: any): void => {
    const variationIndex = this.variations.findIndex(variation => variation.id === id);

    if (variationIndex !== -1) {
      this.variations[variationIndex].price = CurrencyService.parseCurrencyString(value);
    }
  };

  public updateVariationStock = (id: string, value: any): void => {
    const variationIndex = this.variations.findIndex(variation => variation.id === id);
    const numberValue: number = parseFloat(value);
    const roundedPrice = Math.round(numberValue);

    if (variationIndex !== -1) {
      this.variations[variationIndex].stock = roundedPrice;
    }
  };

  public selectVariationComponent = (componentId: string, quantity: number): void => {
    let localQuantity = quantity;

    if (localQuantity > 999) {
      localQuantity = 999;
    }

    for (const variation of this.variations) {
      variation.selectedComponentConfigs.push({
        id: componentId,
        quantity: localQuantity
      });
    }
  };

  public updateVariationComponentValue = (variationId: string, componentId: string, quantity: number): void => {
    let localQuantity = quantity;

    if (localQuantity > 999) {
      localQuantity = 999;
    }
    const variationIndex = _findIndex(this.variations, variation => variation.id === variationId);

    if (variationIndex > -1) {
      const selectedIndex = _findIndex(this.variations[variationIndex].selectedComponentConfigs, component => component.id === componentId);

      if (selectedIndex > -1) {
        this.variations[variationIndex].selectedComponentConfigs[selectedIndex].quantity = localQuantity;
      } else {
        this.variations[variationIndex].selectedComponentConfigs.push({
          id: componentId,
          quantity: localQuantity
        });
      }
    }
  };

  public deselectVariationComponent = (componentId: string): void => {
    for (const variation of this.variations) {
      const selectedIndex = _findIndex(variation.selectedComponentConfigs, component => component.id === componentId);

      if (selectedIndex > -1) {
        variation.selectedComponentConfigs.splice(selectedIndex, 1);
      }
    }
  };

  public setVariationColours = (variationId: string, colourIds: string[]): void => {
    const variationIndex = _findIndex(this.variations, variation => variation.id === variationId);

    if (variationIndex > -1) {
      this.variations[variationIndex].selectedColours = colourIds;
    }

    for (const variation of this.variations) {
      variation.selectedColours = colourIds;
    }
  };

  public selectAddon = (addonId: string): void => {
    const componentConfigsFiltered = this.selectedAddonIds.filter(addon => addon === addonId);

    if (componentConfigsFiltered.length === 0) {
      this.selectedAddonIds.push(addonId);
    }
  };

  public deselectAddon = (addonId: string): void => {
    const index = this.selectedAddonIds.indexOf(addonId);

    if (index > -1) {
      this.selectedAddonIds.splice(index, 1);
    }
  };

  public setActive = (active: boolean): void => {
    this.active = active;
  };

  public updateSelectedProductTypeId = (id: string): void => {
    this.selectedProductTypeId = id;
    this.setSelectedProductTypeTitle();
  };

  public setSelectedCategories = (ids: string[]): void => {
    this.selectedCategoryIds = ids;
  };

  public deselectCategory = (idToDeselect: string): void => {
    this.selectedCategoryIds = this.selectedCategoryIds.filter(id => id !== idToDeselect);
  };

  public onlyPOSSelected = (): boolean => {
    if (this.selectedChannels.length === 1) {
      for (const channelId of this.selectedChannels) {
        if (channelId === Channel.Pos) {
          return true;
        }
      }
    }

    return false;
  };

  public updateSelectedChannel = (id: Channel): void => {
    const index = this.selectedChannels.indexOf(id);

    if (index > -1) {
      this.selectedChannels.splice(index, 1);

      this.selectedChannels = [
        ...this.selectedChannels
      ];
    } else {
      this.selectedChannels = this.selectedChannels.concat(id);
    }

    if (id !== Channel.Pos) {
      this.selectDefaultDeliveryItemsIfEmpty();
    }
    this.setProductTabSubtitle();
  };

  public updateDescription = (description: string): void => {
    this.description = description;
  };

  public updateInternalDescription = (internalDescription: string): void => {
    this.internalDescription = internalDescription;
  };

  public updateVariationDimension = (variationId: string, dimensionKey: Dimension, value: number): void => {
    this.variations = this.variations.map(variation => {
      if (variation.id === variationId) {
        variation.dimensions[dimensionKey] = value;
      }

      return variation;
    });
  };

  public setColours = (colours: ColourListObject[]): void => {
    this.colours = colours;
  };

  public setCategories = (categories: ProductCategory[]): void => {
    this.categories = categories;
  };

  public setTypes = (types: ProductType[]): void => {
    this.types = types;
    this.setSelectedProductTypeTitle();
  };

  public setIsProductTitleADuplicate = (products: Product[]): void => {
    this.isProductTitleADuplicate = !!products.length;
  };

  public setChannels = (channels: ProductChannel[]): void => {
    this.channels = channels;
  };

  public setAddons = (addons: GenericListObject[]): void => {
    this.addons = addons;
  };

  public setComponentConfigs = (componentConfigs: GenericListObject[]): void => {
    this.componentConfigs = componentConfigs;
  };

  public setDeliveryConfigs = (deliveryConfigs: DeliveryConfig[]): void => {
    this.deliveryConfigs = deliveryConfigs;

    if (this.currentMode === Mode.create) {
      this.selectedDeliveryItems = deliveryConfigs.filter(config => config.default).map(config => {
        return config.id;
      });
    }
  };

  public setProductTabSubtitle = (): void => {
    const tickedChannels: string[] = [];

    for (const availableChannel of this.channels) {
      if (this.selectedChannels.includes(availableChannel.slug)) {
        tickedChannels.push(availableChannel.title);
      }
    }
    this.tabs.product.subtitleText = tickedChannels.join(', ');
  };

  public setRecipeTabSubtitle = (): void => {
    const count = this.variations.length;
    this.tabs.recipe.subtitleText = `${count} variation${count > 1 ? 's' : ''}`;
  };

  public editProductValidationPass = (): boolean => {
    return (
      this.productTabValidationPass()
      && this.deliveryItemValidationPass()
      && this.variationTabValidationPass()
    );
  };

  public productTabValidationPass = (): boolean => {
    switch (true) {
      case !this.title || this.title === '':
        return false;

      case this.isProductTitleADuplicate:
        return false;

      case this.selectedChannels.length === 0:
        return false;

      case !this.selectedProductTypeId || this.selectedProductTypeId === '':
        return false;

      default:
        return true;
    }
  };

  public deliveryItemValidationPass = (): boolean => {
    switch (true) {
      case (this.onlyPOSSelected()):
        return true;

      case (this.selectedDeliveryItems.length === 0):
        return false;

      default:
        return true;
    }
  };

  public selectDefaultDeliveryItemsIfEmpty = (): void => {
    if (this.selectedDeliveryItems.length === 0) {
      this.selectedDeliveryItems = this.deliveryConfigs.filter(config => config.default).map(config => {
        return config.id;
      });
    }
  };

  public variationTabValidationPass = (): boolean => {
    for (const variation of this.variations) {
      switch (true) {
        case (typeof variation.price === 'undefined' || isNaN(variation.price) || variation.price <= 0):
          return false;

        case (typeof variation.stock === 'undefined' || isNaN(variation.stock) || variation.stock < 0):
          return false;

        case (!this.hasValidVariationMedia(variation)):
          return false;

        case (this.areVariationDimensionsInvalid(variation.dimensions)):
          return false;

        default:
          break;
      }
    }

    return true;
  };

  public isRequiredMediaCategory = (category: MediaCategory): boolean => {
    return category === MediaCategory.Overhead || category === MediaCategory.SideOn;
  };

  public hasValidVariationMedia = (variation: ProductVariation): boolean => {
    const validMedia = variation.mediaUploads.filter(({ category, id, src }) => {
      const isValidImage = !!id && !!src;

      return this.isRequiredMediaCategory(category) && isValidImage;
    });

    return validMedia.length === 2;
  };

  public areVariationDimensionsInvalid = (dimensions: Dimensions): boolean => {
    if (dimensions.width || dimensions.height || dimensions.length) {
      if (dimensions.width  <= 0 || dimensions.height <= 0 || dimensions.length <= 0) {
        return true;
      }
    }

    return false;
  };

  public setEditMode = (): void => {
    this.currentMode = Mode.edit;
  };

  public resetProductData = (): void => {
    this.oldProduct = undefined;
  };

  public importProduct = (product: Product): void => {
    this.oldProduct = product;
    this.currentMode = Mode.edit;
    this.id = product.id;
    this.sku = product.sku!;
    this.active = product.active;
    this.title = product.title;
    this.description = product.description;
    this.internalDescription = product.internalDescription || '';
    this.productMerchant = product.merchant;
    this.availability = product.availability?.filter(item => moment.utc(item.endAt).isSameOrAfter(moment().utc().startOf('day'), 'day')) || [];
    this.isProductTitleADuplicate = false;

    this.selectedDeliveryItems = product.deliveryConfigs
      ? product.deliveryConfigs.map((config: DeliveryConfig) => config.id)
      : [];

    const newChannelList: Channel[] = [];

    for (const channel of product.channels!) {
      if (!!channel.channel) {
        newChannelList.push(channel.channel);
      }
    }

    this.selectedChannels = newChannelList;
    this.selectedProductTypeId = product.type!.length > 0 ? product.type![0].id : undefined;
    this.setSelectedProductTypeTitle();

    this.selectedCategoryIds = [];

    for (const category of product.categories || []) {
      this.selectedCategoryIds = [
        ...this.selectedCategoryIds,
        category.id
      ];
    }

    this.variations = [];

    for (const variation of product.variations!) {
      this.importVariation(variation);
    }

    this.tabs.product.disabled = false;
    this.tabs.recipe.disabled = false;
    // this.tabs.addon.disabled = false;
    this.tabs.delivery.disabled = false;

    this.setProductTabSubtitle();
  };

  public importVariation = (variation: ProductVariationGenerated): void => {
    this.variations = [
      ...this.variations,
      {
        title: variation.title,
        type: variation.type!,
        id: variation.id,
        sku: variation.sku!,
        price: variation.price,
        stock: variation.stock!,
        selectedComponentConfigs: variation.recipe ? variation.recipe.recipeItems!.map((recipeItem: RecipeItem) => {
          return {
            id: recipeItem.componentConfig.id,
            quantity: recipeItem.quantity,
            fx_id: recipeItem.id
          };
        }) : [],
        selectedColours: variation.colours ? variation.colours.map((colour: Colour) => colour.id) : [],
        mediaUploads: this.formatMediaItems(variation.media!, variation.id),
        dimensions: variation.dimensions ? variation.dimensions : {},
        onClick: (): void => this.setCurrentVariation(variation.id),
        updatePrice: (price: number): void => this.updateVariationPrice(variation.id, price),
        updateStock: (stock: number): void => this.updateVariationStock(variation.id, stock),
        setColours: (colourIds: string[]): void => this.setVariationColours(variation.id, colourIds),
        selectComponent: (componentId: string, quantity: number): void => this.selectVariationComponent(componentId, quantity),
        updateComponent: (componentId: string, quantity: number): void => this.updateVariationComponentValue(variation.id, componentId, quantity),
        deselectComponent: (componentId: string): void => this.deselectVariationComponent(componentId),
        updateDimension: (dimensionKey: Dimension, value: number): void => this.updateVariationDimension(variation.id, dimensionKey, value)
      }
    ];

    if (variation.type === ProductVariationType.Original) {
      this.currentVariationId = variation.id;
    }

    this.setRecipeTabSubtitle();
  };

  public formatMediaItems = (medias: Maybe<Media[]>, variationId: string): MediaItem[] => {
    const newMedias: MediaItem[] = this.getMediaContent(variationId);

    if (medias) {
      for (const importedMedia of medias) {
        for (const newMedia of newMedias) {
          if (importedMedia.category === newMedia.category) {
            newMedia.src = importedMedia.src;
            newMedia.id = importedMedia.id;
          }
        }
      }
    }

    return newMedias;
  };

  public getMediaContent = (variationId: string): MediaItem[] => {
    const mediaList: MediaItem[] = [
      {
        key: 0,
        src: '',
        name: 'Side on',
        updateMediaItem: (mediaId: string, src: string): void => this.updateMediaItem(variationId, 0, mediaId, src),
        category: MediaCategory.SideOn
      }, {
        key: 1,
        src: '',
        name: 'Overhead',
        updateMediaItem: (mediaId: string, src: string): void => this.updateMediaItem(variationId, 1, mediaId, src),
        category: MediaCategory.Overhead
      }, {
        key: 2,
        src: '',
        name: 'Lifestyle',
        updateMediaItem: (mediaId: string, src: string): void => this.updateMediaItem(variationId, 2, mediaId, src),
        category: MediaCategory.Lifestyle
      }, {
        key: 3,
        src: '',
        name: 'Details',
        updateMediaItem: (mediaId: string, src: string): void => this.updateMediaItem(variationId, 3, mediaId, src),
        category: MediaCategory.Details
      }
    ];

    return mediaList;
  };

  public cloneMediaContent = (defaultMedias: MediaItem[], variationId: string): MediaItem[] => {
    const newMediaContent = defaultMedias.map(media => ({
      ...media,
      updateMediaItem:
        (mediaId: string, src: string): void => this.updateMediaItem(variationId, media.key, mediaId, src)
    }));

    return newMediaContent;
  };

  public addBlankVariation = (variationType: ProductVariationType, id: string, useDefaultTemplate: boolean = false): void => {
    const defaultVariation = this.variations.filter(variation => variation.type === ProductVariationType.Original)[0];

    this.variations.push({
      id: id,
      title: variationType,
      type: variationType,
      new: true,
      sku: `fx-${cuid()}`,
      selectedComponentConfigs: defaultVariation
        ? defaultVariation.selectedComponentConfigs.map(componentConfig => ({ ...componentConfig }))
        : [],
      selectedColours: defaultVariation
        ? defaultVariation.selectedColours.map(colour => colour)
        : [],
      dimensions: {},
      mediaUploads: defaultVariation && useDefaultTemplate
        ? this.cloneMediaContent(defaultVariation.mediaUploads, id)
        : this.getMediaContent(id),
      onClick: (): void => this.setCurrentVariation(id),
      updatePrice: (price: number): void => this.updateVariationPrice(id, price),
      updateStock: (stock: number): void => this.updateVariationStock(id, stock),
      setColours: (colourIds: string[]): void => this.setVariationColours(id, colourIds),
      selectComponent: (componentId: string, quantity: number): void => this.selectVariationComponent(componentId, quantity),
      updateComponent: (componentId: string, quantity: number): void => this.updateVariationComponentValue(id, componentId, quantity),
      deselectComponent: (componentId: string): void => this.deselectVariationComponent(componentId),
      updateDimension: (dimensionKey: Dimension, value: number): void => this.updateVariationDimension(id, dimensionKey, value)
    });

    this.currentVariationId = this.variations[this.variations.length - 1].id;
    this.setRecipeTabSubtitle();
    this.setProductTabSubtitle();
  };

  public deleteVariation = (id: string): void => {
    const index = _findIndex(this.variations, variation => variation.id === id);
    this.variations.splice(index, 1);
    this.currentVariationId = this.variations[0].id;
    this.setRecipeTabSubtitle();
  };

  public setSelectedProductTypeTitle = (): void => {
    if (this.selectedProductTypeId) {
      const selectedType = _find(this.types, type => type.id === this.selectedProductTypeId);
      this.selectedProductTypeTitle = selectedType ? selectedType.title : '';
    } else {
      this.selectedProductTypeTitle = '';
    }
  };
}

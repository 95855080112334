import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';

import { textStyles, borderRadius, colors } from 'utils/rebass-theme';

export const SelectWrap: any = styled.div`
  position: relative;
  width: ${({ fullWidth }: any): any => fullWidth ? '100%' : 'auto'};
`;

export const Select: any = styled.select`
  ${textStyles.subhead};
  position: relative;
  appearance: none;
  display: block;
  height: 52px;
  border: 2px solid ${colors.middleGrey};
  background: ${colors.uiFills};
  border-radius: ${borderRadius.medium};
  padding: 0 40px 0 15px;
  width: ${({ fullWidth }: any): any => fullWidth ? '100%' : 'auto'};
  color: ${({ textColor }: any): any => textColor || colors.floomMidnightBlue};
  border-color: ${({ textColor, hasError }: any): any => {
    switch(true) {
      case (hasError): {
        return colors.errorText;
      }

      case (textColor): {
        return hex2rgba(textColor, .7);
      }

      default: {
        return colors.middleGrey;
      }
    }
  }};

  &:focus {
    outline: none;
  }
`;

export const Option: any = styled.option<any>`
  width: ${({ fullWidth }: any): any => fullWidth ? '100%' : 'auto'};
  color: ${({ isPlaceholder }): any => isPlaceholder ? colors.middleGrey : colors.floomMidnightBlue};
`;

export const DropdownArrow = css`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-2px);
`;

export const DropdownLabel: any = styled.div`
  ${textStyles.body};
  display: flex;
  color: ${({ textColor }: any): any => textColor};
  margin-right: 20px;
`;

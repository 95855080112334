import React, { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ProductGroupOption } from 'stores/products-store';

import { Container } from 'utils/css-mixins';

import { BaseProductListLayoutProps } from 'features/products/pages/product-list/product-list.types';
import ProductServices from 'features/products/services';

import LoadMoreButton from 'components/load-more-button';
import WithLoading from 'components/with-loading';

import ProductNoResults from '../no-results';

import GridLayoutGroup from './grid-layout-group';

class GridLayout extends Component<BaseProductListLayoutProps> {
  private ProductListService = ProductServices.ProductListService;

  private getGroupKeys = (): any[] => {
    return Object.keys(this.props.productsStore!.products);
  };

  private hasProducts = (): number => this.getGroupKeys()
    .reduce((acc, curr): number => this.props.productsStore!.products[curr].aggregate.count + acc, 0);

  private onLoadMore = (groupName: string): void => this.ProductListService.loadMoreProducts(groupName);

  render(): React.ReactNode {
    const groupKeys = this.getGroupKeys();

    return (
      <>
        <Container mt="50px">
          <WithLoading
            isLoading={this.props.productsStore!.isLoading && !this.hasProducts()}
            hasNoResults={!this.hasProducts()}
            renderNoResults={(): ReactNode => (
              <ProductNoResults isLoading={this.props.productsStore!.isLoading} />
            )}
          >
            <Box
              mt="50px"
              mb="30px"
            >
              {
                groupKeys.map((productGroup: ProductGroupOption) => {
                  if (!this.props.productsStore!.products[productGroup].edges.length) return null;

                  return (
                    <Fragment key={productGroup}>
                      <GridLayoutGroup
                        key={productGroup}
                        group={this.props.productsStore!.products[productGroup]}
                        groupName={productGroup}
                        showHeading={groupKeys.length > 1}
                        isMakingChanges={this.props.isMakingChanges}
                        onActiveStateChange={this.props.onActiveStateChange}
                      />
                      <LoadMoreButton
                        shouldDisplay={this.props.productsStore!.products[productGroup].pageInfo.hasNextPage}
                        groupName={productGroup}
                        onLoadMore={this.onLoadMore}
                        isLoading={this.props.productsStore!.isLoading}
                      />
                    </Fragment>
                  );
                })
              }
            </Box>
          </WithLoading>
        </Container>
      </>
    );
  }
}

export default inject('productsStore')(observer(GridLayout));

import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';

import {
  space,
  fontSizes
} from 'utils/rebass-theme';

export const FormWrapper = styled(Box)`
  padding: ${space[3]}px ${space[4]}px;
  width: 100%;
`;

export const ButtonWrapper = styled(Flex)`
  justify-content: flex-end;
  padding-top: ${space[4]}px;
  padding-bottom: ${space[3]}px;
  width: 100%;
`;

export const FormFieldLabel = styled(Text)`
  font-size: ${fontSizes.small}px;
  padding-bottom: ${space[3]}px
`;

import React, { Component } from 'react';

import { UserRole } from 'generated-types.d';

import { PermissionsService } from 'lib';

export interface FloomXRestrictedProps {
  restrictedRoles: UserRole[];
  shouldOverrideRoles: boolean;
  considerSuperRole: boolean;
}

export default class FloomXRestrictedComponent extends Component<FloomXRestrictedProps> {
  static defaultProps = {
    specialConditions: [],
    shouldOverrideRoles: false,
    considerSuperRole: false
  };

  hasTeamRole = (userRole: UserRole): boolean => userRole !== UserRole.SuperAdmin &&
    userRole !== UserRole.CustomerService;

  isRoleRestricted = (userRole: UserRole): boolean => !!this.props.restrictedRoles && !!this.props.restrictedRoles.length && this.props.restrictedRoles.includes(userRole);

  shouldComponentRender(): boolean {
    const userRole: UserRole = UserRole[PermissionsService.getUserRole()];

    if (this.props.shouldOverrideRoles) return true;

    if (this.hasTeamRole(userRole) || this.props.considerSuperRole) {
      if (this.isRoleRestricted(userRole)) {
        return false;
      }
    }

    return true;
  }

  render(): React.ReactNode {
    return this.shouldComponentRender() ? this.props.children : null;
  }
}

import React, { Component, ReactNode } from 'react';

import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box } from 'rebass';

import { Channel, PlanFeature } from 'generated-types.d';

import {
  LocalisationService,
  MerchantService,
  OrderService,
  ProductService
} from 'lib';

import {
  colors,
  textStyles
} from 'utils/rebass-theme';

import {
  Metadata,
  MetadataItem,
  MetadataTitle,
  MetadataValue
} from 'components/metadata/metadata.styles';
import ProgressBar from 'components/progress-bar';

import * as Styles from './overview-plan-details.styles';
import * as Types from './overview-plan-details.types';

class OverviewPlanDetails extends Component<Types.OverviewPlanDetailsProps, Types.OverviewPlanDetailsState> {
  static defaultProps = {
    isFullWidth: false
  };

  state = {
    isLoadingData: true,
    productCount: 0,
    orderCount: 0
  };

  private merchantListener = observe(this.props.merchantStore!, 'merchant', ({ oldValue, newValue }: any) => {
    if (!oldValue && newValue.id) {
      this.fetchData();
    }
  });

  componentDidMount = (): void => {
    this.fetchData();
  };

  componentWillUnmount = (): void => {
    // Remove event listener from memory
    this.merchantListener();
  };

  private fetchData = async (): Promise<void> => {
    if (this.props.merchantStore!.merchant) {
      const productCount: number = await this.fetchProductCount();
      const orderCount: number = await this.fetchOrderCount();

      this.setState({
        isLoadingData: false,
        productCount: productCount,
        orderCount: orderCount
      });
    }
  };

  private fetchProductCount = async (): Promise<number> => {
    const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.merchantStore!.merchant!);

    switch (true) {
      case isCollectionSeller:
        return ProductService.fetchProductCount({
          merchant: {
            id: this.props.merchantStore!.merchant!.id
          },
          collectionSellerProductConfigs_some: {
            active: true,
            merchant: {
              id: this.props.merchantStore!.merchant!.id
            }
          }
        });

      default:
        return ProductService.fetchProductCount({
          active: true,
          merchant: {
            id: this.props.merchantStore!.merchant!.id
          }
        });
    }
  };

  private fetchOrderCount = async (): Promise<number> => {
    const { subscription, createdAt } = this.props.merchantStore!.merchant!;
    const currentPeriodStartsAt: string = subscription?.currentPeriodStartsAt || moment(createdAt).toISOString();
    const currentPeriodEndsAt: string = subscription?.currentPeriodEndsAt || moment().toISOString();

    return OrderService.fetchOrderCount({
      channel: Channel.Floom,
      createdAt_gte: currentPeriodStartsAt,
      createdAt_lt: currentPeriodEndsAt,
      status: {
        slug_not: 'cancelled'
      },
      merchant: {
        id: this.props.merchantStore!.merchant!.id
      }
    });
  };

  private renderBarPercentage = (total: number, amountUsed: number): number => {
    return (100 / total) * amountUsed;
  };

  private renderBarColour = (percentage: number): string => {
    switch (true) {
      case percentage > 90:
        return colors.errorText;

      case percentage >= 50:
        return colors.floomYellow;

      default:
        return colors.emeral;
    }
  };

  private renderProgressBar = (
    title: string,
    total: number,
    amountUsed: number
  ): ReactNode => {
    const { isLoadingData } = this.state;
    const percentage = this.renderBarPercentage(total, amountUsed);
    const usageCopy = !isLoadingData ? `- ${amountUsed} out of ${total}` : '';

    return (
      <Box mt="20px">
        <Box
          css={textStyles.body}
          mb="5px"
        >
          {title} {usageCopy}
        </Box>
        <ProgressBar
          width={!isLoadingData ? percentage : 0}
          size="medium"
          backgroundColour={colors.paleGrey}
          barColour={this.renderBarColour(percentage)}
        />
      </Box>
    );
  };

  private renderUsageHeading = (): ReactNode => {
    const currentPeriodEndsAt = this.props.merchantStore!.merchant?.subscription?.currentPeriodEndsAt;

    return (
      <Box>
        <Box
          as="span"
          css={textStyles.title}
        >
          Usage
        </Box>
        { !!currentPeriodEndsAt && (
          <Box
            as="span"
            ml="10px"
            css={textStyles.body}
          >
            Order limit resets on {moment(currentPeriodEndsAt).format('Do MMM')}
          </Box>
        )}
      </Box>
    );
  };

  private renderMetadata = (): ReactNode => {
    const merchant = this.props.merchantStore!.merchant!;
    const { isLoadingData } = this.state;
    const renderData = (copy: string): string => isLoadingData ? '...' : copy;

    return (
      <Styles.MetadataWrapper isInline={this.props.isFullWidth}>
        { this.props.isFullWidth && (
          <Box
            mb="24px"
            flex="1 1 100%"
          >
            <Box css={textStyles.title}>
              About your plan
            </Box>
          </Box>
        )}
        <Metadata>
          <MetadataItem>
            <MetadataTitle>
              Current Plan
            </MetadataTitle>
            <MetadataValue>
              {renderData(LocalisationService.localisePlanName(merchant.plan?.type))}
            </MetadataValue>
          </MetadataItem>
          <MetadataItem>
            <MetadataTitle>
              Order limit
            </MetadataTitle>
            <MetadataValue>
              {renderData(`${merchant.subscription?.orderLimit || '...'} per month`)}
            </MetadataValue>
          </MetadataItem>
          <MetadataItem>
            <MetadataTitle>
              Product Limit
            </MetadataTitle>
            <MetadataValue>
              {renderData(`${merchant.subscription?.productLimit || '...'} active`)}
            </MetadataValue>
          </MetadataItem>
        </Metadata>
      </Styles.MetadataWrapper>
    );
  };

  render(): ReactNode {
    if (!this.props.merchantStore!.merchant) return null;

    const merchant = this.props.merchantStore!.merchant;

    return(
      <Styles.Wrapper>
        {this.renderMetadata()}
        <Styles.Usage isInline={this.props.isFullWidth}>
          {this.renderUsageHeading()}
          {this.renderProgressBar('Orders', merchant.subscription?.orderLimit || 0, this.state.orderCount)}
          {this.renderProgressBar('Active products', merchant.subscription?.productLimit || 0, this.state.productCount)}
        </Styles.Usage>
      </Styles.Wrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore
}))(observer(OverviewPlanDetails));

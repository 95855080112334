import { FC, Fragment, useEffect, useRef } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';
import { InjectedMobxStores } from 'stores';

import { PermissionsService } from 'lib';

import { Container } from 'utils/css-mixins';

import PlantsIconImage from 'assets/images/wholesale/no-results.gif';

import { PageHeader } from 'features/lists/components/page-header';
import { SingleListHeading } from 'features/lists/components/single-list/single-list.styles';

import { LoadingImage } from 'components/loading-image/loading-image';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import { CheckoutList } from './components/checkout-list';
import { CheckoutListTotalButton } from './components/checkout-list-total-button';

const PromotedListsCheckout: FC<Pick<InjectedMobxStores,
  | 'promotedListsStore'
  | 'merchantStore'
>> = ({
  promotedListsStore,
  merchantStore
}) => {
  const footerRef = useRef<HTMLDivElement | null>(null);

  const list = promotedListsStore?.list;
  const listTitle = 'Special Offers';
  const merchant = merchantStore?.merchant;

  const hasPromotedListsAccess: boolean = !!merchant?.promotedListsActive;

  useEffect(() => {
    if (!hasPromotedListsAccess) return;

    promotedListsStore!.getData();

    return () => {
      promotedListsStore!.clearStore();
    };
  }, []);

  const shouldShowPromotedLists = (): boolean => {
    return hasPromotedListsAccess && !!list?.items?.length;
  };

  const getNoResultsCopy = (): string => {
    const isInternal = PermissionsService.isInternalRole();

    if (!merchant && isInternal) {
      return 'Please select a merchant in order to access special offers!';
    }

    if (!hasPromotedListsAccess) {
      const merchantName = isInternal && merchant?.title ? merchant.title : 'The current merchant';

      return `${merchantName} does not have access to special offers!`;
    }

    return 'Special Offers is currently unavailable!';
  };

  const onTotalButtonClick = (): void => {
    footerRef?.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <Fragment>
      <PageHeader
        renderCenter={() => (
          <SingleListHeading
            aria-label={listTitle}
            title={listTitle}
            hideMobile={true}
          >
            {listTitle}
          </SingleListHeading>
        )}
        renderRight={() => (<CheckoutListTotalButton onClick={onTotalButtonClick} />)}
      />
      <Container>
        <WithLoading
          loaderSize="small"
          marginTop="30px"
          isLoading={promotedListsStore?.isLoadingData || false}
          hasNoResults={!shouldShowPromotedLists()}
          renderNoResults={() => (
            <Box mt="20px">
              <NoResultsGeneric
                size="small"
                isFullWidth={true}
                image={PlantsIconImage}
                heading="Special Offers"
                copy={getNoResultsCopy()}
              />
            </Box>
          )}
          renderLoading={() => (
            <LoadingImage
              text="Checking availability..."
              imageSrc={PlantsIconImage}
              imageAlt="plants"
            />
          )}
        >
          <CheckoutList footerRef={footerRef} />
        </WithLoading>
      </Container>
    </Fragment>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  promotedListsStore: stores.promotedListsStore,
  merchantStore: stores.merchantStore
}))(observer(PromotedListsCheckout));

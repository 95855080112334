import { gql } from '@apollo/client';

import { MessageFields } from '../fragments';

export const FAKE_INCOMING_MESSAGE_MUTATION = gql`
  mutation CreateFakeIncomingMessage(
    $input: CreateMessageInput!
  ) {
    debug_fakeIncomingMessage(
      input: $input
    ) {
      id
      merchantId
      conversationId
      messageType
      body
      status
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_MESSAGE_MUTATION = gql`
  mutation CreateMessage(
    $input: CreateMessageInput!
  ) {
    createMessage(input: $input) {
      ...MessageFields
    }
  }

  ${MessageFields}
`;

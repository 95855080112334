import styled from '@emotion/styled';
import { Box } from 'rebass';

import {
  borderRadius,
  colors,
  fontSizes,
  space,
  textStyles
} from 'utils/rebass-theme';

export const Container = styled(Box)`
  position: relative; 
`;

export const Item = styled(Box)`
  display: flex;
  align-items: center;
`;

export const ItemImage = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
  border-radius: ${borderRadius.large};
  background-color: ${colors.middleGrey};
  background-image: ${({ imageUrl }) => !!imageUrl ? `url(${imageUrl})` : 'none'};
  background-size: cover;
  background-position: center;
  margin: ${space[2]}px ${space[3]}px;

  svg {
    display: ${({ imageUrl }) => !imageUrl ? 'block' : 'none'};
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const ItemName = styled(Box)``;

export const ItemTitle = styled.h4`
  ${textStyles.h5}
  font-size: ${fontSizes.base}px;
  color: ${colors.floomMidnightBlue};
`;

export const ItemSubTitle = styled.h6`
  ${textStyles.body}
  font-size: ${fontSizes.base}px;
  color: ${colors.floomMidnightBlue};
`;

export const InputContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: ${space[2]}px;
  margin-bottom: ${space[4]}px;
  justify-content: center;
`;

export const FieldTextWrapper = styled(Box)`
  width: ${space[6]}px;
`;

export const QuantityLabel = styled.label`
  margin-right: ${space[3]}px;
`;

export const RemoveButton = styled(Box)`
  margin-left: ${space[3]}px;
  cursor: pointer;
`;

export const SaveButton = styled.button`
  width: 100%;
`;

export const Metadata = styled(Box)`
  padding: ${space[3]}px;
`;

export const MetadataItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${space[3]}px;
`;

export const MetadataLabel = styled(Box)`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.small}px;
  font-weight: bold;
`;

export const MetadataValue = styled(Box)`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.small}px;
`;

export const MetadataReadonlyValue = styled(Box)`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.small}px;
`;

export const TotalPrice = styled(Box)`
  color: ${colors['black-light']};
  font-size: ${fontSizes.small}px;
  font-weight: bold;
`;

import DeliveryCoverage from './components/card-meta/delivery-coverage';
import DeliveryDays from './components/card-meta/delivery-days';
import DeliveryPrice from './components/card-meta/delivery-price';
import LeadTime from './components/card-meta/lead-time';
import SameDayWeekdays from './components/card-meta/same-day-weekdays';
import SameDayWeekends from './components/card-meta/same-day-weekends';

export type DeliveryMetaColumn =
  | 'channel'
  | 'deliveryArea'
  | 'deliveryDays'
  | 'deliveryPrice'
  | 'sameDayWeekdays'
  | 'sameDayWeekends'
  | 'leadTime';

export interface DeliveryMetaItem {
  component: any;
}

export const deliveryMetaItems: DeliveryMetaItem[] = [
  {
    component: DeliveryCoverage
  },
  {
    component: DeliveryDays
  },
  {
    component: DeliveryPrice
  },
  {
    component: SameDayWeekdays
  },
  {
    component: SameDayWeekends
  },
  {
    component: LeadTime
  }
];

import styled from '@emotion/styled';

import { breakpoints, space } from 'utils/rebass-theme';

import { ItemDetails, ListItemWrapper } from 'features/lists/lists.styles';

export const AdditionalItemListItemWrapper = styled(ListItemWrapper)`
  padding: ${space[2]}px 0;
`;

export const AdditionalItemDetails = styled(ItemDetails)`
  @media only screen and (max-width: ${breakpoints.large}) {
    flex-wrap: wrap;
  }
`;

import { ComponentType, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { Box, Text } from 'rebass';

import { Auth } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import Button from 'components/button';
import ComingSoon from 'components/coming-soon';
import { AppLayout, MarketingLayout } from 'components/global-layout';

interface LayoutContext {
  redirectRoute: string;
  layout: ComponentType<any>;
  layoutOffset?: string;
}

const CopyWrapper = css`
  max-width: 450px;
  margin: 0 auto;
`;

const SubCopy = css`
  ${textStyles.body}
  margin-bottom: 20px;
`;

const RedirectWrapper = css`
  max-width: 170px;
  margin: 0 auto;
`;

const NotFound = (): ReactNode => {
  const isLoggedIn = Auth.isLoggedIn();

  const getLayoutConfig = (): LayoutContext => {
    switch (true) {
      case isLoggedIn:
        return {
          redirectRoute: NavPages.Overview,
          layout: AppLayout
        };

      default:
        return {
          redirectRoute: '/',
          layout: MarketingLayout,
          layoutOffset: '0px'
        };
    }
  };

  const layoutConfig = getLayoutConfig();
  const RootLayout = layoutConfig.layout;

  const renderCopy = (): ReactNode => {
    return (
      <div css={CopyWrapper}>
        <Text
          css={SubCopy}
        >
          Oops! the page you are looking for has been removed or relocated.
        </Text>
        <Box css={RedirectWrapper}>
          <Link to={layoutConfig.redirectRoute}>
            <Button copy="Go back" />
          </Link>
        </Box>
      </div>
    );
  };

  return (
    <RootLayout
      isSimplified={true}
    >
      <ComingSoon
        title="Page not found"
        offset={layoutConfig.layoutOffset}
        copy={renderCopy()}
      />
    </RootLayout>
  );
};

export default NotFound;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { breakpoints, colors } from 'utils/rebass-theme';

export const FooterOffSet = css`
  body {
    padding-bottom: 60px;
  }
`;

export const FooterWrapper = styled(Flex)`
  width: 100%;
  position: fixed;
  z-index: 2;
  flex-flow: row;
  bottom: 0;
  left: 0;
  background-color: ${colors.uiFills};
  padding: 15px 30px 15px 30px;
  box-shadow: 10px 0px 10px rgba(53, 52, 49, 0.2);
  border-left: 1px solid ${colors.paleGrey};

  @media (min-width: ${breakpoints.small}) {
    padding-left: ${({ hasOffset }): string => hasOffset ? '268px' : '30px'};
  }
`;

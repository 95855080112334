import React, { Component, ReactNode } from 'react';

import ErrorMessage from '../error-message';

import * as Styles from './field-text.styles';
import * as Types from './field-text.types';
import InputIcon from './input-icon';
import InputMaxLength from './input-max-length';
import { InputPrefix } from './input-prefix/input-prefix';

class FieldText extends Component<Types.FieldTextProps> {
  static defaultProps = {
    type: 'text',
    iconType: '',
    size: 'normal',
    autoFocus: false,
    isValid: false,
    isDisabled: false,
    isInvalid: false,
    isReadOnly: false,
    isRequired: false,
    isSpellCheckEnabled: true,
    isValidationHidden: false,
    locked: false,
    displayMaxLength: false,
    dirty: false,
    id: '',
    parentWidth: false,
    customCss: null,
    onBlur: (): any => {},
    onChange: (): any => {},
    onFocus: (): any => {},
    onKeyDown: (): any => {},
    onKeyPress: (): any => {},
    onKeyUp: (): any => {},
    onPaste: (): any => {}
  };

  state = {
    isDirty: this.props.dirty
  };

  private getErrorMessage = (): string | undefined => {
    return this.props.error && this.state.isDirty ? this.props.errorMessage : '';
  };

  private onChange = (e: React.ChangeEvent<HTMLInputElement>): any => {
    if (!this.state.isDirty) {
      this.setState({
        isDirty: true
      });
    }

    this.props.onChange!(e);
  };

  render(): ReactNode {
    return(
      <Styles.InputWrapper parentWidth={this.props.parentWidth}>
        { !!this.props.prefix && this.props.size !== 'xsmall' && (
          <InputPrefix
            prefix={this.props.prefix}
            size={this.props.size || 'normal'}
          />
        )}
        <Styles.InputElement
          ref={this.props.innerRef}
          autoComplete={this.props.autoComplete}
          disabled={this.props.isDisabled}
          maxLength={this.props.maxLength}
          name={this.props.name}
          onBlur={this.props.onBlur}
          onChange={this.onChange}
          onFocus={this.props.onFocus}
          onKeyDown={this.props.onKeyDown}
          onKeyPress={this.props.onKeyPress}
          onKeyUp={this.props.onKeyUp}
          onPaste={this.props.onPaste}
          pattern={this.props.pattern}
          placeholder={this.props.placeholder}
          readOnly={this.props.isReadOnly}
          required={this.props.isRequired}
          spellCheck={this.props.isSpellCheckEnabled}
          type={this.props.type}
          value={this.props.value}
          hasIcon={!!this.props.iconType}
          isCentered={this.props.isCentered}
          hasPrefix={this.props.prefix}
          size={this.props.size}
          autoFocus={this.props.autoFocus}
          error={this.props.error && this.state.isDirty}
          isValid={this.props.isValid}
          locked={this.props.locked}
          id={this.props.id}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          css={this.props.customCss}
          data-testid={this.props.testId}
        />
        {
          !!this.props.maxLength && this.props.displayMaxLength && !this.props.locked && (
            <InputMaxLength
              maxLength={this.props.maxLength}
              currentLength={this.props.value!.toString().length}
            />
          )}
        { !!this.props.iconType && this.props.iconType.length && (
          <InputIcon
            type={this.props.iconType}
            isDisabled={!!this.props.isDisabled}
            {...((this.props.onIconClick && !this.props.isDisabled) ? {
              onClick: this.props.onIconClick
            } : {})}
          />
        )}

        <ErrorMessage
          errorMessage={this.getErrorMessage()!}
        />
      </Styles.InputWrapper>
    );
  }
}

export default React.forwardRef((props: Types.FieldTextProps, ref: React.Ref<any>) => (
  <FieldText
    innerRef={ref}
    {...props}
  />
));

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import {
  breakpoints,
  colors,
  space,
  textStyles
} from 'utils/rebass-theme';

import {
  getPlaceholderStyle,
  inputReset
} from 'components/field-text/field-text.styles';

export const SectionHeading = styled(Box)`
  ${textStyles.footnote};
  text-transform: uppercase;
  font-weight: 400;
  color: ${colors.floomMidnightBlue};
  margin: 40px 0 20px 0;
`;

export const DeleteWrapper = styled(Flex)`
  border-radius: 30px;
  padding: ${space[2]}px;
  transition: all .2s ease-in-out;
  position: absolute;
  right: 0;
  cursor: pointer;

  @media only screen and (max-width: ${breakpoints.large}) {
    bottom: ${space[3]}px;
  }

  @media only screen and (max-width: ${breakpoints.medium}) {
    bottom: 48px;
  }

  &:hover, &:focus {
    background: ${colors.paleGrey};
  }
`;

export const Input = styled.input`
  ${textStyles.body};
  font-size: 12px;
  width: 50px;
  background: none;
  border-bottom: 1px solid ${({ disabled }) => disabled ? colors.lightGrey : colors.middleGrey};
  color: ${({ disabled }) => disabled ? colors.middleGrey : colors.floomMidnightBlue};

  &:focus {
    outline: none;
  }

  ${inputReset}
  ${getPlaceholderStyle()};
`;

export const SearchWrapper = styled(Box)`
  margin-bottom: 40px;

  @media only screen and (max-width: ${breakpoints.small}) {
    margin-bottom: ${space[3]}px;
    border-bottom: 1px solid ${colors.floomBlue};
  }
`;

export const CancelOrderButtonWrapper = styled(Box)`
  margin-left: ${space[2]}px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    width: 100%;
    margin-bottom: ${space[2]}px;
  }
`;

export const FinaliseOrderButtonWrapper = styled(Box)`
  margin-left: ${space[2]}px;
  
  @media only screen and (max-width: ${breakpoints.medium}) {
    width: 100%;
  }
`;

export const FooterWrapper = styled(Flex)`
  @media only screen and (max-width: ${breakpoints.medium}) {
    flex-wrap: wrap;
  }
`;

export const FinaliseOrderButton = styled.button`
  width: 100%;
`;

export const CancelOrderButton = styled.button`
  width: 100%;
`;

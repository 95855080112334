import React, { Component } from 'react';

import { Analytics, NavService, PermissionsService } from 'lib';

import MerchantsList from 'features/settings/components/merchants/merchants-list';
import SettingsTemplatePage from 'features/settings/components/template/settings-template-page';
import SettingsService from 'features/settings/services';

class Merchants extends Component<any> {
  private MerchantsSettingsService = SettingsService.MerchantsSettingsService;

  componentDidMount(): void {
    Analytics.page(Analytics.FxPageEvent.SettingsMerchantsList);
    this.redirect();

    this.MerchantsSettingsService.fetchMerchants();
    this.MerchantsSettingsService.fetchFilterCounts();
  }

  private redirect = (): void => {
    if (!PermissionsService.isInternalRole()) {
      NavService.overview();
    }
  };

  private onCreate = (): void => {
    NavService.merchantCreate('account');
  };

  render(): JSX.Element {
    return (
      <SettingsTemplatePage
        title="Merchants"
        headerButtonCopy="Create Merchant"
        onHeaderAction={this.onCreate}
      >
        <MerchantsList />
        {this.props.children}
      </SettingsTemplatePage>
    );
  }
}

export default Merchants;

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Text } from 'rebass';

import {
  ProductVariation,
  PlanFeature
} from 'generated-types.d';

import {
  Analytics,
  MerchantService,
  ProductService,
  CollectionProductConfigService
} from 'lib';

import { textStyles } from 'utils/rebass-theme';

import ProductServices from 'features/products/services';

import { QuickEditUpdateData } from '../../grid-layout-item/quick-edit/quick-edit.types';
import VariationItem from '../variation-item';

import * as Styles from './variation-list.styles';
import * as Types from './variation-list.types';

class VariationList extends Component<Types.VariationListProps> {
  private ProductListService = ProductServices.ProductListService;

  private ProductAnalytics = ProductServices.ProductAnalytics;

  state = {
    isLoading: false
  };

  private renderNoVariations = (): ReactNode => (
    <Styles.NoResults>
      <Text css={textStyles.subhead}>
        No variations :(
      </Text>
    </Styles.NoResults>
  );

  private hasStockUpdated = (variationId: string, stock: number): boolean => {
    const variation = this.props.item.variations!.find(vari => vari.id === variationId);

    return !!variation && variation.stock !== stock;
  };

  private updateProductVariation = async (updates: QuickEditUpdateData, variation: ProductVariation): Promise<void> => {
    this.setState({ isLoading: true });

    const isCollectionManagerMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionManager, this.props.merchantStore!.merchant);

    try {
      if (ProductService.isTemplateProduct(this.props.item) && !isCollectionManagerMerchant) {
        const config = this.props.item.collectionSellerProductConfigs?.[0];

        await CollectionProductConfigService.upsertProductConfig(
          this.props.item,
          this.props.merchantStore!.merchant!,
          !!config?.active,
          config,
          {
            productVariation: variation,
            stock: updates.stock
          }
        );
      } else {
        this.trackStockUpdate(variation, updates.stock);

        await this.ProductListService.quickEditProductVariation({
          ...updates,
          productId: this.props.item.id,
          variationId: variation.id
        });
      }

      this.props.toasterStore!.popSuccessToast(`Product variation "${variation.type}"`, 'update');
      await this.ProductListService.fetchAllProducts();

      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      this.props.toasterStore!.popErrorToast('this product', 'update');
    }
  };

  private trackStockUpdate = (variation: ProductVariation, stock: number): void => {
    if (this.hasStockUpdated(variation.id, stock)) {
      this.ProductAnalytics.onEditStock(
        Analytics.ProductStockEntry.List,
        stock,
        variation.type!
      );
    }
  };

  private renderVariation = (variation: ProductVariation): ReactNode => {
    const isTemplateProduct = ProductService.isTemplateProduct(this.props.item);
    const isCollectionManagerMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionManager, this.props.merchantStore!.merchant);
    const stockAmount = ProductService.calculateProductVariationStock(this.props.item, variation, isCollectionManagerMerchant);

    return (
      <VariationItem
        productId={this.props.item.id}
        key={variation.id}
        variation={variation}
        product={this.props.item}
        merchant={this.props.item.merchant}
        stockAmount={stockAmount}
        isLoading={this.state.isLoading}
        isTemplateProduct={isTemplateProduct}
        isCollectionManagerMerchant={isCollectionManagerMerchant}
        onSave={async (updates): Promise<void> => {
          await this.updateProductVariation(updates, variation);
        }}
      />
    );
  };

  render(): ReactNode {
    if (!this.props.hasVariations) return this.renderNoVariations();

    return this.props.item.variations!.map(variation => this.renderVariation(variation));
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  toasterStore: stores.toasterStore
}))(observer(VariationList));

import { FC } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import ConversationsStore from 'stores/conversations/conversations-store';
import MerchantStore from 'stores/merchant/merchant-store';
import ModalStore from 'stores/modal-store/modal-store';

import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';
import { ConversationContactNewModalData } from 'features/modal-dialogue/components/modals/conversation-contact-new-modal/conversation-contact-new-modal.types';

import { ButtonDropdown } from 'components/button-dropdown/button-dropdown';
import { ButtonDropdownOption } from 'components/button-dropdown/button-dropdown.types';
import Icon from 'components/icon';
import IconButton from 'components/icon-button';

import { ConversationView } from '../../context/ContactContext';

import { BackButton, Header, ProfileWrapper } from './messages-header.styles';

type MessagesHeaderProps = {
  conversationsStore?: ConversationsStore;
  merchantStore?: MerchantStore;
  modalStore?: ModalStore;
}

export const MessagesHeader: FC<MessagesHeaderProps> = inject((stores: FxStores): InjectedFxStores => ({
  conversationsStore: stores.conversationsStore,
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore
}))(observer(({
  conversationsStore,
  merchantStore,
  modalStore
}) => {
  const selectedContact = conversationsStore?.selectedContact;

  const dropdownOptions: ButtonDropdownOption[] = [
    {
      title: 'Edit Supplier',
      description: 'Change supplier details',
      onClick: () => {
        modalStore!.triggerModal<ConversationContactNewModalData>({
          modalType: 'conversationContactNew',
          data: { contact: selectedContact || undefined }
        });
      },
      iconName: 'edit'
    },
    {
      title: 'Delete Supplier',
      description: 'Delete supplier contact and messages',
      onClick: async () => {
        const modalData: ConfirmationModalData = {
          title: 'Confirmation',
          copy: <p>Do you want to delete {selectedContact?.name} contact information and all messages in this conversation?<br /><br /><strong>You cannot undo this action.</strong></p>,
          confirmButtonCopy: 'Delete',
          cancelButtonCopy: 'Cancel',
          errorCopy: '',
          confirmButtonAction: async (): Promise<any> => new Promise(async (resolve, reject): Promise<any> => {
            try {
              await conversationsStore?.deleteContact(selectedContact!.id, merchantStore?.merchant?.id);
              resolve('');
            } catch (error) {
              reject();
            }
          })
        };

        modalStore!.triggerModal({
          modalType: 'confirmation',
          data: modalData
        });
      },
      iconName: 'bin'
    }
  ];

  return (
    <Header>
      <IconButton
        styles={BackButton}
        onClick={() => conversationsStore?.setView(ConversationView.CONTACTS)}
        iconName="arrow-small-left"
        size={36}
      />
      <ProfileWrapper px={2}>
        {selectedContact?.name}
      </ProfileWrapper>

      <ButtonDropdown
        options={dropdownOptions}
        buttonCopy="Selected contact options"
        positionX="right"
        renderButton={() => (
          <Icon iconName="more-options" />
        )}
      />

      <Box width="12px" />
    </Header>
  );
}));

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import _get from 'lodash.get';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

import { statusConfig } from 'features/orders/components/order-item/order-item.config';
import OrderHelpers from 'features/orders/helpers/order-helpers';
import { OrderStatusSlug } from 'features/orders/orders.types';

import Icon from 'components/icon';

import OrderDetailStatusControl from '../order-detail-status-control';

import * as Styles from './order-detail-header.styles';
import * as Types from './order-detail-header.types';

class OrderDetailHeader extends Component<Types.OrderDetailHeaderProps> {
  config = {
    headingBg: 'background',
    color: colors.floomMidnightBlue,
    iconName: 'pie-one-third'
  };

  private isInactive = (): boolean => [
    OrderStatusSlug.Delivered,
    OrderStatusSlug.Cancelled,
    OrderStatusSlug.FailedDelivery
  ]
    .includes(this.props.ordersStore!.currentOrder.status.slug);

  private buildConfig = (slug: OrderStatusSlug = OrderStatusSlug.Open): any => ({
    ...statusConfig[slug],
    color: this.getHeadingColour(slug)
  });

  private getHeadingColour = (statusSlug: OrderStatusSlug): any => this.isInactive()
    ? colors[statusConfig[statusSlug].color]
    : colors.floomMidnightBlue;

  private closeButton = (): JSX.Element => (
    <Flex
      as={Link}
      to={this.props.closeTo || '/orders/list/today'}
      state={{
        fromModal: true
      }}
      ml="auto"
    >
      <Icon
        iconName="cross-medium"
        pathFill={this.config.color}
      />
    </Flex>
  );

  render(): ReactNode {
    if (this.props.ordersStore!.currentOrder.status) {
      this.config = this.buildConfig(_get(this.props.ordersStore!.currentOrder, 'status.slug'));

      return(
        <Styles.OrderDetailHeader
          config={this.config}
          alignItems="center"
        >
          <Flex>
            <Styles.StatusIcon
              iconName={this.config.iconName}
              pathFill={this.config.color}
            />
          </Flex>
          <Box ml="10px">
            Order #{OrderHelpers.shortenOrderNumber(this.props.ordersStore!.currentOrder.orderNo)}
          </Box>
          <Styles.StatusControls>
            <OrderDetailStatusControl label="Status:" />
          </Styles.StatusControls>
          <Flex
            ml="auto"
            alignItems="center"
          >
            <Styles.HeaderActions
              alignItems="center"
              color={this.config.color}
            >
              <Flex
                as="button"
                css={css`
                  ${textStyles.subhead};
                  color: ${this.config.color};
                `}
                onClick={(): any => this.props.ordersStore!.viewOrderReceipt(this.props.ordersStore!.currentOrder)}
              >
                <Icon
                  iconName="receipt"
                  pathFill={this.config.color}
                />
                <Styles.ReceiptLink ml="13px">Receipt</Styles.ReceiptLink>
              </Flex>
            </Styles.HeaderActions>
            {this.closeButton()}
          </Flex>
        </Styles.OrderDetailHeader>
      );
    }

    return (
      <Styles.OrderDetailHeader
        config={this.config}
        alignItems="center"
      >
        {this.closeButton()}
      </Styles.OrderDetailHeader>
    );
  }
}

export default inject('ordersStore')(observer(OrderDetailHeader));

import { FC, memo, ReactNode } from 'react';

import { css } from '@emotion/react';
import {
  Pagination as AisPagination
} from 'react-instantsearch-dom';
import { Box } from 'rebass';

import { TEST_IDS } from 'utils/test/data-test-ids';

import {
  Wrapper
} from './pagination.styles';

const ButtonWrapper: FC<{ onClick: () => void; copy: string; testId: string }> = ({
  onClick,
  copy,
  testId
}) => {
  return (
    <Box
      data-testid={testId}
      onClick={onClick}
      css={css`
        height: inherit;
      `}
    >
      {copy}
    </Box>
  );
};

export const Pagination = memo(({
  handlePaginate = () => {}
}: {
  handlePaginate?: () => void;
}) => (
  <Wrapper flex="0 0 100%">
    <AisPagination
      padding={2}
      showFirst={true}
      showLast={true}
      translations={{
        first: (
          <ButtonWrapper
            onClick={handlePaginate}
            copy="First"
            testId={TEST_IDS.WholesaleShop.pagination.first}
          />
        ),
        last: (
          <ButtonWrapper
            onClick={handlePaginate}
            copy="Last"
            testId={TEST_IDS.WholesaleShop.pagination.last}
          />
        ),
        previous: (
          <ButtonWrapper
            onClick={handlePaginate}
            copy="Previous"
            testId={TEST_IDS.WholesaleShop.pagination.previous}
          />
        ),
        next: (
          <ButtonWrapper
            onClick={handlePaginate}
            copy="Next"
            testId={TEST_IDS.WholesaleShop.pagination.next}
          />
        ),
        page: (currentRefinement: number): ReactNode => {
          return (
            <ButtonWrapper
              onClick={handlePaginate}
              copy={`${currentRefinement}`}
              testId={`${TEST_IDS.WholesaleShop.pagination.item}${currentRefinement}`}
            />
          );
        }
      }}
    />
  </Wrapper>
));

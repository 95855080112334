import { FC, ReactElement } from 'react';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';
import { FeatureToggleConfig } from 'hooks/useFeatureFlags/useFeatureFlags.types';

interface WithFeatureFlagProps {
  render: (props: FeatureToggleConfig) =>  ReactElement<any, any> | null;
}

export const WithFeatureFlag: FC<WithFeatureFlagProps> = ({
  render
}) => {
  const config = useFeatureFlags();

  return render(config);
};

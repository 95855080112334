import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const FooterWrapper = styled.div<{ offset: string }>`
  width: calc(100vw - ${({ offset }): string => offset});
`;

export const FooterContent = styled(Flex)`
  margin: 0 auto;
  max-width: 770px;
  justify-content: space-between;
`;

export const NextButtonWrapper: any = styled.div`
  cursor: ${(props: any): any => props.disabled ? 'normal' : 'pointer'};
  pointer-events: ${(props: any): any => props.disabled || props.edit ? 'none' : 'auto'};
`;

import React, { FunctionComponent } from 'react';

import StyledLink from 'components/styled-link/styled-link';

import * as Types from './functional-anchor.types';

const FunctionalAnchor: FunctionComponent<Types.FunctionalAnchorProps> = (props: Types.FunctionalAnchorProps): JSX.Element => {
  return (
    <a href={`${props.type}:${props.anchorHref}`}>
      <StyledLink>
        {props.copy}
      </StyledLink>
    </a>
  );
};

export default FunctionalAnchor;

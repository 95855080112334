import { Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { PhoneContact } from 'types/conversations.types';

import { PermissionsService } from 'lib';

import { ConversationView } from 'features/conversations/context/ContactContext';
import { formatNumber } from 'features/conversations/helpers/formatPhoneNumber';

import { ProfileIcon } from 'components/profile-icon/profile-icon';

import * as Styles from './contacts-list.styles';
import { ContactsListProps } from './contacts-list.types';

export const ContactsList: React.FC<ContactsListProps> = inject((stores: FxStores): InjectedFxStores => ({
  conversationsStore: stores.conversationsStore
}))(observer(({
  conversationsStore,
  onContactSelect,
  contacts = []
}) => {
  const getProfileIcon = (name: string): React.ReactElement => {
    const [firstName, secondName] = name.split(' ');

    return (
      <ProfileIcon
        givenName={firstName}
        familyName={secondName}
      />
    );
  };

  const handleClick = (contact: PhoneContact): void => {
    onContactSelect(contact);
    conversationsStore?.setView(ConversationView.MESSAGES);
  };

  return (
    <Fragment>
      {contacts.map((contact, i) => {
        const isActive = conversationsStore?.selectedContact?.id === contact.id;
        const showUnreadCount = (!isActive || PermissionsService.isInternalRole()) && !!contact?.conversation?.unreadMessagesCount;

        return (
          <Styles.ContactWrapper
            key={i}
            onClick={() => handleClick(contact)}
            active={isActive}
          >
            <Box>
              {getProfileIcon(contact.name)}
            </Box>
            <Styles.NameWrapper>
              <div>
                {contact.name}
              </div>
              <Styles.PhoneNumber>
                {formatNumber(contact.phone)}
              </Styles.PhoneNumber>
            </Styles.NameWrapper>
            {showUnreadCount && (
              <Styles.UnreadCount>
                {contact.conversation?.unreadMessagesCount}
              </Styles.UnreadCount>
            )}
          </Styles.ContactWrapper>
        );
      })}
    </Fragment>
  );
}));

import moment from 'moment';
import stores from 'stores';

import { UserRole } from 'generated-types.d';

import packageJSON from '../../../../package.json';

import {
  SegmentEvent,
  FxEventName,
  FxPageEvent,
  ProductAvailabilityEntry,
  ProductStockEntry,
  OrderListEntry,
  OrderEditEntry,
  OrderSearchEntry,
  SettingsEntryPoint,
  SettingsSection,
  SettingsOverviewEntry,
  TermsEntryPoint
} from './analytics.types';

const page = (pageName: FxPageEvent, properties?: any): void => {
  if (window && window.analytics) {
    window.analytics.page(pageName, {
      ...properties,
      ...addGlobals()
    } || {});
  }
};

const group = (groupId: string, traits?: any): void => {
  if (window && window.analytics) {
    window.analytics.group(groupId, traits || {});
  }
};

const track = (
  eventName: FxEventName,
  properties?: SegmentEvent
): void => {
  if (window && window.analytics) {
    window.analytics.track(eventName, properties ? {
      ...properties,
      ...addGlobals()
    } : {});
  }
};

const identify = (userId: string, traits?: any): void => {
  if (window && window.analytics) {
    window.analytics.identify(userId, traits || {});
  }
};

const reset = (): void => {
  if (window && window.analytics) {
    window.analytics.reset();
  }
};

const addGlobals = (): any => {
  const time = moment();

  return {
    local_minute_of_day: time.diff(time.clone().startOf('d'), 'm'),
    local_time_of_day: time.format('HH:mm'),
    local_date: time.format('Y-MM-DD'),
    app_version: packageJSON.version,
    user_id: stores.userStore?.user?.id || '',
    user_type: logUserType(stores.userStore?.role || ''),
    ...!!stores && stores.userStore.userMerchant && {
      merchant: stores.userStore.userMerchant.title,
      merchant_id: stores.userStore.userMerchant.id
    },
    ...!!stores && stores.userStore.role && {
      user_role: stores.userStore.role
    }
  };
};

const logUserType = (role: string): string => {
  const { CustomerService, SuperAdmin, TeamAdmin, TeamMember, TeamOwner } = UserRole;

  switch (true) {
    case role === CustomerService || role === SuperAdmin:
      return 'Internal';

    case role === TeamAdmin || role === TeamMember || role === TeamOwner:
      return 'Merchant';

    default:
      return 'Marketing';
  }
};

export {
  page,
  group,
  track,
  identify,
  reset,
  FxEventName,
  FxPageEvent,
  ProductAvailabilityEntry,
  ProductStockEntry,
  OrderListEntry,
  OrderEditEntry,
  OrderSearchEntry,
  SettingsEntryPoint,
  SettingsSection,
  SettingsOverviewEntry,
  TermsEntryPoint
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box } from 'rebass';

import { mq } from 'utils/css-mixins/css-mixins';
import { shadows, colors, textStyles, fonts } from 'utils/rebass-theme';

import { SelectWrap, Select, DropdownLabel } from 'components/dropdown-native/dropdown-native.styles';

const ItemBorder = css`
  border-top: 1px solid ${hex2rgba(colors.floomMidnightBlue, .2)};
`;

export const EntityList = styled.section<any>`
  border-radius: 3px;
`;

export const EntityListItems = styled.div<any>`
  border-radius: 3px;
  overflow: ${({ removeOverflow }): string => removeOverflow ? 'visible' : 'hidden'};
  box-shadow: ${shadows.small};
  background: ${colors.uiFills};
`;

export const EntityListRow = styled.div<{ removeOverflow?: boolean }>`
  overflow: ${({ removeOverflow }): string => removeOverflow ? 'visible' : 'hidden'};

  & + ${(): any => EntityListRow} {
    ${ItemBorder};
  }
`;

export const EntityListColumn = styled.div`
  padding: 20px;
  padding-right: 20px !important;
  padding-left: 20px !important;
  background: ${colors.uiFills};
`;

export const SortDropdown = css`
  ${mq.lessThan('medium')} {
    ${SelectWrap}, ${Select}  {
      width: 100%;
    }

    ${DropdownLabel}  {
      display: none;
    }
  }
`;

export interface EntityListTableHeadingItemProps {
  fixedWidth?: string;
  flexGrow?: string;
  flexBasis?: string;
}

export const EntityListTableHeadingItem = styled(Box)<EntityListTableHeadingItemProps>`
  ${textStyles.subhead};
  color: ${colors.floomMidnightBlue};
  padding: 20px 10px;
  ${({ fixedWidth }): any => fixedWidth && `width: ${fixedWidth}; min-width: ${fixedWidth}`};
  ${({ flexGrow }): any => flexGrow && `flex-grow: ${flexGrow};`};
  ${({ flexBasis }): any => flexBasis && `flex-basis: ${flexBasis};`};
`;

export interface EntityListTableItemProps {
  fixedWidth?: string;
  flexGrow?: string;
  flexBasis?: string;
}

export const EntityListTableItem = styled(Box)<EntityListTableItemProps>`
  ${textStyles.body};
  padding: 0 10px;
  ${({ fixedWidth }): any => fixedWidth && `width: ${fixedWidth}; min-width: ${fixedWidth}`};
  ${({ flexGrow }): any => flexGrow && `flex-grow: ${flexGrow};`};
  ${({ flexBasis }): any => flexBasis && `flex-basis: ${flexBasis};`};
`;

export const FieldLabel = styled.span`
  font-family: ${fonts.secondary};
  font-size: 14px;
  color: ${hex2rgba(colors.floomMidnightBlue, 0.4)};
  font-weight: bold;
  margin-right: 10px;
`;

export const InputWrapper: any = styled(Box)`
  max-width: ${(props): string => props.width || '60px'};
`;

export const InputSaveButton: any = styled(Box)<any>`
  transition: opacity .2s ease-in-out;
  opacity: ${({ disabled }): number => disabled ? 0 : 1};
  pointer-events: ${({ disabled, isSaving }): string => (disabled || isSaving) ? 'none' : 'auto'};
  color: ${({ isSaving }): string => isSaving ? colors.middleGrey : colors.floomMidnightBlue};
  cursor: ${({ isSaving }): string => isSaving ? 'not-allowed' : 'pointer'};
`;

export const PlaceholderImage = styled.div<{ size: string }>`
  height: ${({ size }): string => size ? `${size}px` : '59px'};
  width: ${({ size }): string => size ? `${size}px` : '59px'};
  border-radius: 3px;
  background-color: ${colors.paleGrey};
`;

export const DeleteCross: any = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { MediaCategory } from 'generated-types.d';

import MediaUploader from 'components/media-uploader';

import * as Styles from './create-variation-media-uploader.styles';
import { CreateVariationMediaProps } from './create-variation-media-uploader.types';

class CreateVariationMediaUploader extends Component<CreateVariationMediaProps> {
  private isRequiredCategory = (category: MediaCategory): boolean => {
    return this.props.productEditStore!.isRequiredMediaCategory(category);
  };

  render(): React.ReactNode {
    return (
      <Styles.Content>
        {
          this.props.mediaUploads.map((mediaItem, index) => (
            <Styles.Item key={mediaItem.key}>
              <MediaUploader
                index={index}
                imageName={mediaItem.name}
                onUpload={mediaItem.updateMediaItem}
                id={mediaItem.id!}
                src={mediaItem.src}
                category={mediaItem.category}
                validationUploadError={!mediaItem.id && this.isRequiredCategory(mediaItem.category)}
                validationErrorMessage="Image must be provided"
                isReadOnly={this.props.isReadOnly}
              />
            </Styles.Item>
          ))
        }
      </Styles.Content>
    );
  }
}

export default inject('productEditStore')(observer(CreateVariationMediaUploader));

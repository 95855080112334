import { CookieSchemas } from 'lib/services/cache/cache.service.types';
import { action, observable, makeObservable, computed } from 'mobx';

import { CacheService } from 'lib';

import * as Types from './navigation-store.types';

export default class NavigationStore {
  constructor() {
    makeObservable(this, {
      layout: observable,
      navWidth: computed,
      isCollapsed: computed,
      toggleLayout: action,
      setLayout: action
    });
  }

  private initialiseLayout = (): Types.NavigationLayout => {
    const navCookie = CacheService.retrieveCookie('navigationLayout');
    const content = navCookie?.content as CookieSchemas.NavigationLayoutOption | undefined;
    const layoutOptions: Types.NavigationLayout[] = [
      Types.NavigationLayout.Collapsed,
      Types.NavigationLayout.Open
    ];

    if (!!content?.data && layoutOptions.includes(content.data)) {
      return content?.data;
    }

    return Types.NavigationLayout.Open;
  };

  private readonly collapsedSize = '59px';

  private readonly openSize = '240px';

  public layout: Types.NavigationLayout = this.initialiseLayout();

  public toggleLayout = (): void => {
    const layout = this.layout === Types.NavigationLayout.Collapsed
      ? Types.NavigationLayout.Open
      : Types.NavigationLayout.Collapsed;

    CacheService.storeCookieObject({
      name: 'navigationLayout',
      data: layout
    });

    this.layout = layout;
  };

  public setLayout = (layout: Types.NavigationLayout): void => {
    CacheService.storeCookieObject({
      name: 'navigationLayout',
      data: layout
    });

    this.layout = layout;
  };

  public get isCollapsed(): boolean {
    return this.layout === Types.NavigationLayout.Collapsed;
  }

  public get navWidth(): string {
    switch (true) {
      case this.layout === Types.NavigationLayout.Collapsed:
        return this.collapsedSize;

      case this.layout === Types.NavigationLayout.Open:

      default:
        return this.openSize;
    }
  }
}

import { Component, Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import {
  MerchantStage,
  SubscriptionStatus
} from 'generated-types.d';

import {
  CurrencyService,
  LocalisationService,
  MerchantService,
  MerchantSubscriptionService,
  NavService,
  PermissionsService,
  TimeService
} from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import UserLoginService from 'features/login-signup/services';
import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';

import Notification from 'components/notification';
import {
  NotificationType
} from 'components/notification/notification.types';

import {
  BaseStyles,
  NotificationBorder
} from './account-change-notification.styles';
import {
  AccountChangeNotificationProps,
  NotificationConfig
} from './account-change-notification.types';

class AccountChangeNotification extends Component<AccountChangeNotificationProps> {
  static defaultProps = {
    context: null
  };

  private readonly supportedStatuses: SubscriptionStatus[] = [
    SubscriptionStatus.PastDue,
    SubscriptionStatus.Unpaid,
    SubscriptionStatus.Incomplete,
    SubscriptionStatus.IncompleteExpired,
    SubscriptionStatus.Canceled
  ];

  private cancelPlanChange = async (): Promise<void> => {
    const proposedUpdate = this.props.merchantStore!.merchant?.proposedUpdate;

    if (!proposedUpdate?.id) return;

    this.props.modalStore!.triggerModal<ConfirmationModalData>({
      modalType: 'confirmation',
      data: {
        title: proposedUpdate.plan
          ? 'Cancel plan change?'
          : 'Stay on FloomX',
        copy: (
          <Fragment>
            Please confirm that you would like to
            {' '}
            {proposedUpdate.plan ? 'cancel your plan change' : 'stay on FloomX'}
          </Fragment>
        ),
        confirmButtonCopy: 'Confirm',
        cancelButtonCopy: 'Cancel',
        errorCopy: '',
        confirmButtonAction: async (): Promise<any> => new Promise(async (resolve): Promise<void> => {
          if (!this.props.merchantStore?.merchant?.proposedUpdate?.id) return;

          try {
            await MerchantSubscriptionService.deleteProposedMerchantUpdate({
              id: this.props.merchantStore.merchant.proposedUpdate.id
            });

            await UserLoginService.getMerchantDetails();

            const notificationCopy = proposedUpdate.plan
              ? 'Your plan will not be changing'
              : 'You will no longer be leaving FloomX';

            this.props.toasterStore!.popToast(`${notificationCopy} on ${this.currentPeriodEndDate()}`, ToastType.Success);
          } catch (error) {
            this.props.toasterStore!.popToast('We\'re having trouble processing your request', ToastType.Error);
          }

          resolve('');
        })
      }
    });
  };

  private subscriptionPrice = (): string => {
    const merchant = this.props.merchantStore!.merchant;

    return CurrencyService.formatPrice(merchant?.subscription?.stripeSubscriptionPrice || 0, merchant!.currency);
  };

  private endOfGracePeriod = (): string => {
    const merchant = this.props.merchantStore!.merchant;

    return TimeService.dateMonthShort(moment(merchant?.subscription?.currentPeriodStartsAt).add(7, 'days'));
  };

  private currentPeriodEndDate = (): string => {
    const merchant = this.props.merchantStore!.merchant;

    return TimeService.dateMonthShort(merchant?.subscription?.currentPeriodEndsAt);
  };

  private currentPlan = (): string => {
    const merchant = this.props.merchantStore!.merchant;

    return LocalisationService.localisePlanName(merchant?.plan?.type);
  };

  private proposedPlan = (): string => {
    const merchant = this.props.merchantStore!.merchant;

    return LocalisationService.localisePlanName(merchant?.proposedUpdate?.plan?.type);
  };

  private proposedPlanPrice = (): string => {
    const merchant = this.props.merchantStore!.merchant;

    if (!merchant?.currency || !merchant?.proposedUpdate?.planPrice) return '-';

    return CurrencyService.formatPrice(merchant.proposedUpdate.planPrice, merchant.currency);
  };

  private updatePaymentDetails = (): void => {
    NavService.paymentDetails();
  };

  private notificationConfig = (): NotificationConfig | null => {
    const merchant = this.props.merchantStore!.merchant;
    const hasActiveSubscription = MerchantService.hasActiveSubscription(merchant);

    switch (true) {
      case this.props.context === 'wholesale' && !!merchant && !hasActiveSubscription:
        return {
          copy: (
            <Fragment>
              We cannot find an active FloomX subscription or payment method, so wholesale purchases
              {' '}are currently disabled. You may need to update your payment method
            </Fragment>
          ),
          buttonConfig: {
            copy: 'Update payment',
            appearance: 'danger'
          },
          styles: NotificationBorder,
          onClick: this.updatePaymentDetails
        };

      case this.props.context === 'paymentDetails' && !!merchant && !hasActiveSubscription:
        const subscriptionStatusConfig = LocalisationService.localiseSubscriptionStatus(merchant?.subscription?.status);

        return {
          copy: (
            <Fragment>
              There is an issue with your subscription. Your subscription status is <strong>{subscriptionStatusConfig.title}</strong>
              <br />
              <strong>This means</strong>: {subscriptionStatusConfig.reason}
              <br />
              <strong>Solution</strong>: {subscriptionStatusConfig.solution}
            </Fragment>
          ),
          styles: NotificationBorder
        };

      case !!merchant?.proposedUpdate?.plan:
        return {
          copy: (
            <Fragment>
              Your plan is changing to <strong>{this.proposedPlan()}</strong> at the end of
              {' '}your current billing period. Your new monthly fee will be
              {' '}<strong>{this.proposedPlanPrice()}</strong> per month. Changed your mind?
              {' '}You have until <strong>{this.currentPeriodEndDate()}</strong> to stay on
              {' '}your current plan, <strong>{this.currentPlan()}</strong>.
            </Fragment>
          ),
          buttonConfig: {
            copy: 'Stay on FloomX'
          },
          onClick: this.cancelPlanChange
        };

      case merchant?.proposedUpdate?.merchantStage === MerchantStage.Churn:
        return {
          copy: (
            <Fragment>
              It looks like you are leaving us soon. You’ll be losing the ability to sell
              {' '}your products on floom.com and other great features. You have until
              {' '}<strong>{this.currentPeriodEndDate()}</strong> to change your mind.
            </Fragment>
          ),
          buttonConfig: {
            copy: 'Stay on FloomX'
          },
          onClick: this.cancelPlanChange
        };

      case merchant?.proposedUpdate?.merchantStage === MerchantStage.Dormant:
        return {
          copy: (
            <Fragment>
              We will be pausing your FloomX subscription soon. You have until
              {' '}<strong>{this.currentPeriodEndDate()}</strong> to change your mind.
            </Fragment>
          ),
          buttonConfig: {
            copy: 'Stay on FloomX'
          },
          onClick: this.cancelPlanChange
        };

      case merchant?.stage === MerchantStage.Churn && merchant?.subscription?.status === SubscriptionStatus.Canceled:
        return {
          copy: (
            <Fragment>
              Your subscription has ended. To resubscribe, please contact us at florists@floom.com.
            </Fragment>
          ),
          styles: NotificationBorder
        };

      case merchant?.stage === MerchantStage.Dormant && merchant?.subscription?.status === SubscriptionStatus.Canceled:
        return {
          copy: (
            <Fragment>
              Your subscription has been paused. To reactivate your subscription, please contact us at florists@floom.com.
            </Fragment>
          ),
          styles: NotificationBorder
        };

      case merchant?.subscription?.status === SubscriptionStatus.PastDue:
        return {
          copy: (
            <Fragment>
              We were unable to take payment for your FloomX subscription due to an issue with your
              {' '}payment method, resulting in an outstanding balance of <strong>{this.subscriptionPrice()}</strong>.
              {' '}We will automatically attempt payment again between now and <strong>{this.endOfGracePeriod()}</strong>,
              {' '}after which we will suspend your ability to sell products on Floom.com.
            </Fragment>
          ),
          buttonConfig: {
            copy: 'Update payment',
            appearance: 'danger'
          },
          styles: NotificationBorder,
          onClick: this.updatePaymentDetails
        };

      case merchant?.subscription?.status === SubscriptionStatus.Incomplete:
        return {
          copy: (
            <Fragment>
              We were unable to take payment for your FloomX subscription due to an issue with your
              {' '}payment method on record, resulting in an outstanding balance of <strong>{this.subscriptionPrice()}</strong>.
              {' '}please head to your payment settings to update the payment method on record, so that you can continue
              {' '}setting up your account.
            </Fragment>
          ),
          buttonConfig: {
            copy: 'Update payment',
            appearance: 'danger'
          },
          styles: NotificationBorder,
          onClick: this.updatePaymentDetails
        };

      case merchant?.subscription?.status === SubscriptionStatus.Unpaid:
        return {
          copy: (
            <Fragment>
              We were unable to take payment for your FloomX subscription due to an issue with your payment method,
              {' '}resulting in an outstanding balance of <strong>{this.subscriptionPrice()}</strong>. We have suspended
              {' '}your ability to sell products on Floom.com. Please settle your outstanding balance by
              {' '}<strong>{this.currentPeriodEndDate()}</strong> to continue selling on Floom.com, otherwise
              {' '}your subscription will be cancelled.
            </Fragment>
          ),
          buttonConfig: {
            copy: 'Update payment',
            appearance: 'danger'
          },
          styles: NotificationBorder,
          onClick: this.updatePaymentDetails
        };

      case merchant?.paymentMethod?.isExpiring:
        return {
          copy: `
            Your payment method is expiring soon. Please make sure to update it before the expiry date
            to avoid any missed payments to your FloomX subscription.
          `,
          buttonConfig: {
            copy: 'Update payment',
            appearance: 'danger'
          },
          styles: NotificationBorder,
          onClick: this.updatePaymentDetails
        };

      default:
        return null;
    }
  };

  private shouldDisplay = (): boolean => {
    const merchant = this.props.merchantStore!.merchant;
    const isInternalUser = PermissionsService.isInternalRole();
    const hasProposedUpdate = !!merchant?.proposedUpdate;
    const isPaymentMethodExpiring = !!merchant?.paymentMethod?.isExpiring;
    const hasFaultySubscripiton = !!merchant?.subscription?.status && this.supportedStatuses.includes(merchant?.subscription?.status);

    return !isInternalUser && (hasProposedUpdate || isPaymentMethodExpiring || hasFaultySubscripiton);
  };

  private wrapElement = (children: ReactNode): ReactNode => {
    if (this.props.wrapElement) {
      return this.props.wrapElement(children);
    }

    return children;
  };

  render(): ReactNode {
    if (!this.shouldDisplay()) return null;

    const config = this.notificationConfig();

    if (!config) return null;

    return this.wrapElement((
      <div>
        <Notification
          type={NotificationType.Banner}
          hasIcon={true}
          hasClose={false}
          hasButton={!!config.buttonConfig}
          textAlign="left"
          styles={css`
            ${BaseStyles}
            ${config.styles || css``}
          `}
          icon="exclamation-circle"
          onButtonClick={config.onClick}
          buttonProps={config.buttonConfig}
          copy={config.copy}
        />
      </div>
    ));
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(AccountChangeNotification));

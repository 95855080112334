import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const Wrapper: any = styled(Flex)`
  & + ${(): any => Wrapper} {
    margin-top: 15px;
  }
`;

export const MetaIcon = css`
  min-width: 25px;
`;

export const ActivityToggle = styled.span<{ isActive: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 100px;
  margin-left: 5px;
  background: ${({ isActive }): string => isActive ? colors.emeral : colors.middleGrey};
`;

export const ItemIcon = css`
  width: 100%;
  max-width: 24px;
  max-height: 24px;

  path {
    fill: ${colors.middleGrey};
  }
`;

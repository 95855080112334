import React from 'react';

import { inject, observer } from 'mobx-react';

import { StringFormat } from 'lib';

import { LogoutButtonIcon } from 'utils/css-mixins/css-mixins';

import * as Styles from './logout.styles';
import * as Types from './logout.types';

class Logout extends React.Component<Types.LogoutProps> {
  render(): React.ReactNode {
    const { givenName, familyName } = this.props.userStore!;
    const hasName = givenName || familyName;
    const isCollapsed = this.props.navigationStore!.isCollapsed;

    return (
      <Styles.ButtonWrapper>
        { hasName && (
          <LogoutButtonIcon mr="13px">
            {StringFormat.createInitials(givenName, familyName)}
          </LogoutButtonIcon>
        )}

        { !isCollapsed && (
          <Styles.Button>
            { hasName
              ? `${StringFormat.buildFullName(givenName, familyName)}`
              : 'Logout'
            }
          </Styles.Button>
        )}
      </Styles.ButtonWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  userStore: stores.userStore,
  uiStore: stores.uiStore
}))(observer(Logout));

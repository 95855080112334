import { Component, ReactNode } from 'react';

import { SerializedStyles } from '@emotion/css';
import { inject, observer } from 'mobx-react';

import {
  Channel,
  Merchant,
  PlanFeature
} from 'generated-types.d';

import { MerchantService } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import ProductEditStore from 'stores/product-edit-store/product-edit-store';

import CheckboxList from 'components/checkbox-list';

export interface ChannelCheckboxListProps {
  merchantStore?: MerchantStore;
  productEditStore?: ProductEditStore;
  items?: any;
  selectedItems: string[];
  itemValueField: string;
  optionTitleField: string;
  orientation: 'vertical' | 'horizontal';
  onChange: (value: string) => void;
  metadataField?: string;
  error?: boolean;
  errorMessage?: string;
  checkboxStyles?: SerializedStyles;
  merchant?: Merchant;
}

interface ChannelOption {
  id: Channel;
  title: string;
  slug: Channel;
  isDisabled: boolean;
}

class ChannelCheckboxList extends Component<ChannelCheckboxListProps> {
  private getChannels = (): ChannelOption[] => {
    if (!this.props.items && !this.props.merchant?.id && process.env.NODE_ENV === 'development') {
      throw new Error('Please pass a merchant object as a prop to ChannelCheckBoxList if you are not passing an array of items');
    }

    const { merchant } = this.props;
    const hasPlan = MerchantService.hasPlan(merchant!);
    const hasFeature = (feature: PlanFeature): boolean => MerchantService.hasPlanFeature(feature, merchant!);

    return [
      {
        id: Channel.Floom,
        title: 'Floom',
        slug: Channel.Floom,
        isDisabled: hasPlan ? !hasFeature(PlanFeature.Floom) : !merchant?.flc_id
      },
      {
        id: Channel.Website,
        title: 'Custom Website',
        slug: Channel.Website,
        isDisabled: hasPlan ? !hasFeature(PlanFeature.Website) : !merchant?.customSiteConfig?.id
      }
    ];
  };

  private getChannelCounts = (): any => this.getChannels().reduce((acc: any, curr: any) => {
    const channel = this.props.items.find((item: any) => item.title === curr.slug);

    return [
      ...acc,
      {
        ...curr,
        isDisabled: false,
        count: channel.count
      }
    ];
  }, []);

  render(): ReactNode {
    const channelList = !!this.props.items ? this.getChannelCounts() : this.getChannels();

    return (
      <CheckboxList
        items={channelList}
        selectedItems={this.props.selectedItems}
        itemValueField={this.props.itemValueField}
        optionTitleField={this.props.optionTitleField}
        metadataField={this.props.metadataField}
        orientation={this.props.orientation}
        onChange={this.props.onChange}
        error={this.props.error}
        checkboxStyles={this.props.checkboxStyles}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  merchantStore: stores.merchantStore
}))(observer(ChannelCheckboxList));

import {
  Merchant,
  PlanType
} from 'generated-types.d';

import { MerchantService } from 'lib';

export default class NavCheckerService {
  public static isWholesaleOnly(merchant: Merchant | null): boolean {
    return MerchantService.hasPlanType(PlanType.WholesaleOnly, merchant);
  }

  public static hasCustomWebsite(merchant: Merchant | null): boolean {
    return !!merchant?.weeblyConfig?.active;
  }
}

import cuid from 'cuid';
import { action, observable, makeObservable } from 'mobx';

import {
  Addon,
  AddonCategory,
  MediaCategory,
  Channel,
  Currency
} from 'generated-types.d';

import { GenericListObject } from 'components/generic-select-list/generic-select-list.types';

import { MediaItem, Mode } from './add-on-edit-store.types';

export default class AddOnEditStore {
  // here we save the imported addOn in case we are clicking through from an edit link
  oldAddOn: Addon | null = null;

  isSaving: boolean = false;

  isLoadingAddon: boolean = true;

  id: string | null = null;

  active: boolean = false;

  categoryId?: string = '';

  categories: AddonCategory[] = [];

  currentMode: Mode = Mode.create;

  title: string = '';

  brand: string = '';

  price?: number = 0;

  stock?: number = 0;

  selectedChannels: Set<Channel> = new Set();

  mediaItem: MediaItem = {
    id: '',
    src: '',
    name: 'Image',
    category: MediaCategory.Details
  };

  allProducts: boolean = true;

  selectedProductIds: string[] = [];

  productsList: GenericListObject[] = [];

  isRestrictedCategoryModalOpen: boolean = false;

  isNoMerchantModalOpen: boolean = false;

  currency?: Currency = undefined;

  constructor() {
    makeObservable(this, {
      oldAddOn: observable,
      isSaving: observable,
      id: observable,
      isLoadingAddon: observable,
      active: observable,
      categoryId: observable,
      categories: observable,
      currentMode: observable,
      title: observable,
      brand: observable,
      price: observable,
      stock: observable,
      selectedChannels: observable,
      mediaItem: observable,
      allProducts: observable,
      selectedProductIds: observable,
      productsList: observable,
      isRestrictedCategoryModalOpen: observable,
      isNoMerchantModalOpen: observable,
      currency: observable,
      init: action,
      setIsLoading: action,
      resetIsLoading: action,
      importAddOn: action,
      toggleIsSaving: action,
      setTemporaryId: action,
      setActive: action,
      setSelectedProducts: action,
      updateTitle: action,
      updateBrand: action,
      updatePrice: action,
      updateStock: action,
      toggleNoMerchantModal: action,
      toggleRestrictedCategoryModal: action,
      updateCategoryId: action,
      setProducts: action,
      setCategories: action,
      setSelectedProductIds: action,
      deselectProduct: action,
      toggleChannel: action
    });

    this.init();
  }

  public init = (): void => {
    this.id = null;
    this.oldAddOn = null;
    this.categoryId = undefined;
    this.currentMode = Mode.create;
    this.isLoadingAddon = true;
    this.active = false;
    this.setTemporaryId();
    this.title = '';
    this.brand = '';
    this.price = undefined;
    this.stock = undefined;
    this.isSaving = false;
    this.allProducts = true;
    this.selectedChannels = new Set();
    this.currency = undefined;
    this.selectedProductIds = [];

    this.mediaItem = {
      id: '',
      src: '',
      name: 'Image',
      category: MediaCategory.Details
    };
  };

  public importAddOn = (addOn: Addon): void => {
    this.currentMode = Mode.edit;
    this.oldAddOn = addOn;
    this.id = addOn.id;
    this.active = addOn.active;
    this.title = addOn.title;
    this.brand = addOn.brand!;
    this.price = addOn.price!;
    this.stock = addOn.stock!;
    this.categoryId = addOn.category.id;
    this.currency = addOn.merchant.currency;

    if (addOn.channels) {
      for (const channel of addOn.channels) {
        if (channel.channel) {
          this.selectedChannels.add(channel.channel);
        }
      }
    }

    this.mediaItem = {
      id: addOn.media!.id,
      src: addOn.media!.src,
      name: 'Image',
      category: MediaCategory.Details
    };

    this.allProducts = addOn.allProducts;

    this.selectedProductIds = addOn.selectedProducts!.map(product => {
      return product.id;
    });
  };

  public toggleIsSaving = (isSaving: boolean): void => {
    this.isSaving = isSaving;
  };

  public setIsLoading = (): void => {
    this.isLoadingAddon = true;
  };

  public resetIsLoading = (): void => {
    this.isLoadingAddon = false;
  };

  public setTemporaryId = (): void => {
    this.id = `pre-create-${cuid()}`;
  };

  public setActive = (active: boolean): void => {
    this.active = active;
  };

  public setSelectedProducts = (selected: string): void => {
    if (selected === 'selected_products') {
      this.allProducts = false;

      return;
    }
    this.selectedProductIds = [];
    this.allProducts = true;
  };

  public updateTitle = (title: string): void => {
    this.title = title;
  };

  public updateBrand = (brand: string): void => {
    this.brand = brand;
  };

  public updatePrice = (price: number): void => {
    this.price = price;
  };

  public updateStock = (stock: number): void => {
    this.stock = stock;
  };

  public toggleNoMerchantModal = (): void => {
    this.isNoMerchantModalOpen = !this.isNoMerchantModalOpen;
  };

  public toggleRestrictedCategoryModal = (): void => {
    this.isRestrictedCategoryModalOpen = !this.isRestrictedCategoryModalOpen;
  };

  public updateCategoryId = (id: string): void => {
    const category = this.categories.find(cat => {
      return cat.id === id;
    });

    if (category!.slug === 'champagne') {
      this.isRestrictedCategoryModalOpen = true;
    }

    this.categoryId = id;
  };

  public updateMediaItem = (mediaId: string, src: string): void => {
    this.mediaItem.id = mediaId;
    this.mediaItem.src = src;
  };

  public setProducts = (products: any): void => {
    this.productsList = products.map((item: any) => {
      return {
        title: item.title,
        id: item.id,
        details: item.categories.map((category: any) => {
          return category.title;
        }).join(', '),
        mediaSource: item.variations[0].media[0].src
      };
    });
  };

  public setCategories = (categories: any): void => {
    this.categories = categories.edges.map((item: any) => {
      return {
        id: item.node.id,
        slug: item.node.slug,
        title: item.node.title
      };
    });
  };

  public setSelectedProductIds = (productIds: string[]): void => {
    this.selectedProductIds = productIds;
  };

  public deselectProduct = (productId: string): void => {
    this.selectedProductIds = this.selectedProductIds.filter((selectedProductId: string) => {
      return selectedProductId !== productId;
    });
  };

  public toggleChannel = (channel: Channel): void => {
    if (this.selectedChannels.has(channel)) {
      this.selectedChannels.delete(channel);
    } else {
      this.selectedChannels.add(channel);
    }
  };

  public validationPass = (): boolean => {
    switch (true) {
      case (!this.title || this.title === ''):
        return false;

      case (!this.categoryId || this.categoryId === ''):
        return false;

      case (typeof this.price === 'undefined' || isNaN(this.price) || this.price < 0):
        return false;

      case (typeof this.stock === 'undefined' || isNaN(this.stock) || this.stock < 0):
        return false;

      case (this.mediaItem.src === ''):
        return false;

      case (!this.allProducts && !this.selectedProductIds.length):
        return false;

      default:
        return true;
    }
  };
}

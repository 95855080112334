import { FC, Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box, Flex, Text } from 'rebass';

import { PhoneContact } from 'types/conversations.types';

import PlantsIconImage from 'assets/images/wholesale/no-results.gif';

import { LoadingImage } from 'components/loading-image/loading-image';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import { ContactsList } from '../contacts-list/contacts-list';
import { NewSupplierButton } from '../new-supplier-button/new-supplier-button';

import { SubHeader } from './contacts.styles';

export type ContactsProps = {
  contacts: PhoneContact[];
  loading: boolean;
  onContactSelect: (contact: PhoneContact) => void;
};

export const Contacts: FC<ContactsProps> = ({
  contacts,
  loading,
  onContactSelect
}) => {
  const renderLoading = (): ReactNode => {
    return (
      <Flex
        justifyContent="center"
        mb={3}
      >
        <Box
          css={css`
              max-width: 180px;
            `}
        >
          <LoadingImage
            text={(
              <Text fontSize="16px">
                  Loading suppliers...
              </Text>
            )}
            imageSrc={PlantsIconImage}
            imageAlt="plants"
          />
        </Box>
      </Flex>
    );
  };

  return (
    <Fragment >
      <SubHeader>
          Suppliers
      </SubHeader>
      <WithLoading
        loaderSize="small"
        marginTop="30px"
        isLoading={loading}
        hasNoResults={contacts.length === 0}
        renderNoResults={() => (
          <Box p={3}>
            <NoResultsGeneric
              icon="leaf-no-results"
              heading="No suppliers found"
              size="xsmall"
            />
          </Box>
        )}
        renderLoading={renderLoading}
      >
        <ContactsList
          contacts={contacts}
          onContactSelect={onContactSelect}
        />
      </WithLoading>
      <NewSupplierButton />
    </Fragment>
  );
};

import { css } from '@emotion/react';

import typography from 'utils/typography/typography';

import * as Types from './rebass-theme.types';

export const MarketingNavHeight = '75px';

export const HeaderHeight = '60px';

export const HeaderHeightUnit = 60;

export const gridGutter = 30;

export const containerWidth = 1170 + gridGutter * 2;

export const breakpoints: Types.ThemeBreakpoints = {
  small: '40em',
  medium: '55em',
  large: '70em',
  xLarge: '80em',
  xxLarge: '90em'
};

export const breakpointsPixels = Object.keys(breakpoints).reduce((acc, key) => {
  const valueEm = breakpoints[key];
  const valuePixels = parseInt(valueEm, 10) * 18;

  return {
    ...acc,
    [key]: valuePixels
  };
}, {}) as Types.ThemeBreakpointsPixels;

export const maxContentWidth = 964;

export const getEM = (pixels: number): string => {
  const point = (pixels * 3) / 4;

  return `${point / 12}em`;
};

export const getCurrentBreakpoint = (innerWidth: number): any => {
  const getIndex = Object.values(breakpoints).findIndex(breakpoint => Math.round(parseInt(getEM(innerWidth).replace('em', ''))) <
      parseInt(breakpoint.replace('em', '')));

  return getIndex < 0
    ? Object.keys(breakpoints)[Object.keys(breakpoints).length - 1]
    : Object.keys(breakpoints)[getIndex];
};

export const fontSizes: Types.FontSizes = {
  xSmall: 12,
  small: 14,
  base: 16,
  medium: 20,
  large: 24,
  xLarge: 32,
  xxLarge: 48,
  xxxLarge: 64
};

export const colors: Types.ColourOptions = {
  // FloomX Colours
  'azure': '#4254d8',
  'background': '#efefef',
  'chartreuse': '#77c941',
  'darkGrey': '#4F4F4F',
  'emeral': '#337d03',
  'errorBg': '#fecccb',
  'errorText': '#ee4238',
  'floomBlue': '#2b3688',
  'floomBlush': '#ffe3d9',
  'floomCream': '#fafaf2',
  'floomCyan': '#a0e8f4',
  'floomMidnightBlue': '#00124f',
  'floomOrange': '#ff8c6b',
  'floomRed': '#ef544d',
  'floomYellow': '#f5db8c',
  'green': '#579330',
  'grey': '#535764',
  'middleGrey': '#bfc4d3',
  'lightGrey': '#f7f7f7',
  'lighterGray': '#e0e0e0',
  'paleGrey': '#ecedf2',
  'offWhite': '#FAFAFA',
  'peach': '#ffad5f',
  'sky': '#428fe1',
  'shade40': '#969db1',
  'shade60': '#667195',
  'uiFills': '#ffffff',
  'ultramarine': '#1141c7',
  'validationBg': '#d1f5bf',
  'validationText': '#62bd40',
  'violet': '#A461D8',
  'purple': '#625B9C',

  // Legacy Colours
  'black': '#2b3330',
  'black-light': '#404745',
  'blue': '#bad4db',
  'light-blue': '#e3eef1',
  'light-green': '#e4f2e6',
  'light-pink': '#ffefe9',
  'pink': '#ffd6c7',
  'white': '#ffffff'
};

export const easings = {
  easeIn: 'cubic-bezier(0.19, 1, 0.22, 1)'
};

export const shadows = {
  large: '0 0 24px rgba(0, 0, 0, .125)',
  small: '0px 2px 10px rgba(0, 0, 0, 0.2)',
  smallSharp: '0px 0px 10px rgba(53, 52, 49, 0.5)',
  xSmall: '0px 2px 6px rgba(0, 0, 0, 0.2)'
};

export const borderRadius = {
  small: '2px',
  medium: '3px',
  large: '5px',
  xLarge: '8px'
};

export const space = [0, 4, 8, 16, 32, 64, 128, 256];

const fontNameArrayToCSSFontStack = (fontNameArray: string[] | undefined): string => (fontNameArray || [])
  .map(font => (/\s/.test(font) ? `'${font}'` : font))
  .join(', ');

export const fonts = {
  primary: (typography?.options?.headerFontFamily || [])[0],
  secondary: (typography?.options?.bodyFontFamily || [])[0]
};

export const fontStacks = {
  primary: fontNameArrayToCSSFontStack(typography?.options?.headerFontFamily),
  secondary: fontNameArrayToCSSFontStack(typography?.options?.bodyFontFamily)
};

export const textStyles = {
  h1: css`
    line-height: 1.2;
    font-size: 48px;
    font-family: ${fontStacks.primary};
    font-weight: 700;

    @media only screen and (max-width: ${breakpoints.medium}) {
      line-height: 1.1;
      font-size: ${fontSizes.xLarge}px;
    }
  `,
  h2: css`
    line-height: 1.2;
    font-size: 30px;
    font-family: ${fontStacks.primary};
    font-weight: 700;
  `,
  h3: css`
    line-height: 1.2;
    font-size: 20px;
    font-family: ${fontStacks.primary};
    font-weight: 700;
  `,
  h4: css`
    line-height: 1.5;
    font-size: 14px;
    font-family: ${fontStacks.primary};
    font-weight: 700;
  `,
  h5: css`
    line-height: 1.5;
    font-size: 14px;
    font-family: ${fontStacks.secondary};
    font-weight: 700;

    @media (max-width: ${breakpoints.medium}) {
      line-height: 1.38;
      font-size: 16px;
      font-weight: 700;
    }
  `,
  // Deprecated, replaced by `h1`
  headline: css`
    line-height: 1.21;
    font-size: 48px;
    font-family: ${fontStacks.primary};
    font-weight: 700;
  `,
  // Deprecated, replaced by `h3`
  title: css`
    line-height: 1.2;
    font-size: 20px;
    font-family: ${fontStacks.primary};
    font-weight: 700;
  `,
  body: css`
    line-height: 1.5;
    font-size: 14px;
    font-family: ${fontStacks.secondary};
    font-weight: 400;
  `,
  pullQuote: css`
    line-height: 1.2;
    font-size: 30px;
    font-family: ${fontStacks.primary};
    font-weight: 400;
  `,
  pullQuoteSmall: css`
    line-height: 1.4;
    font-size: 20px;
    font-family: ${fontStacks.secondary};
    font-weight: 400;
  `,
  bodyItalic: css`
    line-height: 1.43;
    font-size: 14px;
    font-family: ${fontStacks.secondary};
    font-style: italic;
    font-weight: 400;
  `,
  // Deprecated, replaced by `h4` and `h5`
  subhead: css`
    line-height: 1.5;
    font-size: 14px;
    font-family: ${fontStacks.secondary};
    font-weight: 700;
  `,
  footnote: css`
    line-height: 1.25;
    font-size: 12px;
    font-family: ${fontStacks.secondary};
    font-weight: 700;
  `,
  label: css`
    line-height: 1.4;
    font-size: 10px;
    font-family: ${fontStacks.secondary};
    font-weight: 700;
  `
};

export default {
  breakpoints: Object.values(breakpoints),
  fontSizes: Object.values(fontSizes),
  colors: colors,
  space: space,
  fonts: fonts,
  fontStacks: fontStacks,
  shadows: shadows
};

import { css } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const Overlay: StyledComponent<any, any, any> = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  background: ${hex2rgba(colors.black, 0.6)};
`;

export const DisableScroll = css`
  html {
    overflow: hidden !important;
  }
`;

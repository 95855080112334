import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, borderRadius, textStyles } from 'utils/rebass-theme';

export const ModalRouterWrapper: any = styled.div`
  background: ${colors.uiFills};
  margin: 0 auto;
  overflow: hidden;
  border-radius: ${borderRadius.medium};
  max-width: ${({ width }: any): any => !!width ? `${width}px` : '960px'};
`;

export const ModalRouterScrollableArea: any = styled(Box)`
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 120px);
  max-height: calc(100vh - 120px);

  &::-webkit-scrollbar {
    background: ${colors.lightGrey};
    height: 0;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.shade40};
    border-radius: 1px;
  }
`;

export const ModalRouterHeader: any = styled(Flex)`
  ${textStyles.subhead};
  justify-content: space-between;
  align-items: center;
  background: ${({ config }: any): any => colors[config ? config.headingBg : 'lightGrey']};
  color: ${({ config }: any): any => config ? config.color : colors.floomMidnightBlue};
  min-height: 70px;
  padding: 10px 30px;
`;

export const ModalRouterHeaderTitle: any = styled(Box)`
  flex-grow: 2;
`;

export const ModalRouterCross: any = styled(Box)`

`;

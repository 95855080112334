import { css } from '@emotion/react';

import * as Types from './icon.types';

const Icon = ({ pathFill, size, styles, iconName }: Types.IconProps): JSX.Element | null => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const IconFile = require(`assets/icons/${iconName}.svg`);

  const getSize = (measurement: Types.IconProps['size']): string => {
    if (typeof measurement === 'number') {
      return `${measurement}px`;
    }

    switch (measurement) {
      case 'xsmall': {
        return '10px';
      }

      case 'small': {
        return '14px';
      }

      case 'normal': {
        return '24px';
      }

      case 'large': {
        return '28px';
      }
    }

    return 'auto';
  };

  return IconFile && (
    <IconFile
      // @ts-ignore
      css={css`
        ${styles};
        ${size && `
          width: ${getSize(size)};
          height: ${getSize(size)};
        `}
        path {
          fill: ${pathFill};
        }
      `}
    />
  );
};

Icon.defaultProps = {
  pathFill: null
};

export default Icon;

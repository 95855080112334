import styled from '@emotion/styled';

import { colors } from 'utils/rebass-theme';

export const QuestionMark = styled.span`
  margin-left: 10px;
  border-radius: 70px;
  top: 1px;
  border: 2px solid ${colors.floomMidnightBlue};
  opacity: 0.4;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  padding: 1px 0 0px 1px;
  position: relative;
`;

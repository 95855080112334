import { css } from '@emotion/react';
import { withPrefix } from 'gatsby';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { colors, fonts } from 'utils/rebass-theme';

const BUENOS_AIRES = [
  {
    name: 'Bold',
    style: 'normal',
    url: 'buenos-aires/buenos-aires-bold',
    weight: 600
  },
  {
    name: 'Book',
    style: 'normal',
    url: 'buenos-aires/buenos-aires-book',
    weight: 400
  }
];

const FF_MARK_PRO = [
  {
    name: 'Bold',
    style: 'normal',
    url: 'ff-mark-pro/ffmarkpro-bold',
    weight: 600
  },
  {
    name: 'Book',
    style: 'normal',
    url: 'ff-mark-pro/ffmarkpro-book',
    weight: 400
  }
];

const FontFace = css`
  ${BUENOS_AIRES.map(font => {
    return css`
        @font-face {
          font-family: ${fonts.primary};
          src: url('${withPrefix(`${font.url}.eot`)}');
          src: url('${withPrefix(`${font.url}.eot?#iefix`)}') format('embedded-opentype'),
               url('${withPrefix(`${font.url}.woff`)}') format('woff2'),
               url('${withPrefix(`${font.url}.woff2`)}') format('woff');
          font-weight: ${font.weight};
          font-style: ${font.style};
          font-stretch: ${font.stretch};
          unicode-range: U+000D-FB04;
        }
      `;
  })
}

  ${FF_MARK_PRO.map(font => {
    return css`
        @font-face {
          font-family: ${fonts.secondary};
          font-style: ${font.style};
          font-weight: ${font.weight};
          src: url('${withPrefix(`${font.url}.eot`)}')  format('eot');
          src: url('${withPrefix(`${font.url}.eot`)}?#iefix')  format('embedded-opentype'),
               url('${withPrefix(`${font.url}.woff`)}') format('woff'),
               url('${withPrefix(`${font.url}.woff2`)}') format('woff2'),
               url('${withPrefix(`${font.url}.ttf`)}') format('truetype');
        }
      `;
  })
}
`;

export const GlobalStyles = css`
  ${FontFace};

  img {
    width: 100%;
  }

  html:focus-within {
    scroll-behavior: smooth;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: ${colors.floomCream};
  }
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Subtitle = styled(Box)`
  ${textStyles.label};
  color: ${colors.shade40};
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0px;
`;

export const TagsWrapper = styled(Box)`
  position: relative;
  margin-top: 20px;
`;

export const TagsOverlay = styled(Box)`
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  border-radius: 2px;
  height: 100px;
  width: calc(100% - 4px);
  background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 14%);
  z-index: 1;

  span {
    ${textStyles.body};
    color: ${colors.floomMidnightBlue};
    background: ${colors.lightGrey};
    font-weight: 600;
    position: absolute;
    text-align: right;
    bottom: 12px;
    right: 11px;
    padding: 5px 10px;
    border-radius: 3px;
  }
`;

import React, { Component } from 'react';

import { css } from '@emotion/react';
import { Box, Flex } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import NoResultsGif from 'assets/images/no-results.gif';

import * as Styles from './no-results.styles';
import * as Types from './no-results.types';

export default class ProductNoResults extends Component<Types.ProductNoResultsProps> {
  render(): React.ReactNode | null {
    if (this.props.isLoading) return null;

    return (
      <Box m="50px auto 0 auto">
        <Box
          css={css`
            ${textStyles.title};
            text-align: center;
          `}
        >
          No Products
        </Box>
        <Flex
          justifyContent="center"
          alignItems="flex-start"
          mt="30px"
        >
          <Styles.AnimatedGif
            src={NoResultsGif}
          />
        </Flex>
      </Box>
    );
  }
}

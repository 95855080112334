import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box, Flex } from 'rebass';

import { ListType } from 'generated-types.d';

import {
  breakpoints,
  colors,
  space
} from 'utils/rebass-theme';

export const Wrapper = styled(Flex)<{ isNew: boolean }>`
  flex-direction: row;
  width: 100%;
  padding: ${space[3]}px;
  align-items: center;
  border-bottom: 1px ${colors.middleGrey} solid;
  transition: background-color .2s ease-in-out;
  background-color: ${({ isNew }): string => isNew ? hex2rgba(colors.grey, .05) : 'none'};
  cursor: pointer;

  @media only screen and (max-width: ${breakpoints.medium}) {
    padding: ${space[2]}px 0;
  }
`;

export const DeleteWrapper = styled(Flex)`
  border-radius: 30px;
  padding: 9px;
  transition: all .2s ease-in-out;
  cursor: pointer;

  &:hover, &:focus {
    background: ${colors.paleGrey};
  }

  @media only screen and (max-width: ${breakpoints.medium}) {
    margin-left: ${space[2]}px;
  }
`;

export const ItemPricing = styled(Box)<{listType: ListType}>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 25%;

  @media only screen and (max-width: ${breakpoints.large}) {
    order: 2;
    justify-content: end;
    width: ${({ listType }) => listType === ListType.Simple ? '25%' : '50%'};
  }
`;

import React from 'react';

import { useWindowSize } from 'hooks/useWindowSize/useWindowSize';

import * as Styles from './content-wrapper.styles';

export const ContentWrapper: React.FC = ({ children }) => {
  const { width, height } = useWindowSize();

  const getHeight = (): number | undefined => {
    if (!width || !height) {
      return undefined;
    }

    return width > 640 ? height : height - 50;
  };

  return (
    <Styles.ContentWrapper
      height={getHeight()}
    >
      {children}
    </Styles.ContentWrapper>
  );
};

import styled from '@emotion/styled';
import { cover } from 'polished';
import { Box, Flex } from 'rebass';

import { CardButton, AbsoluteCover } from 'utils/css-mixins/css-mixins';
import { breakpoints, colors, textStyles } from 'utils/rebass-theme';

export const Groups = styled(Flex)`
  margin: 0 -15px;
  overflow: auto;

  @media (max-width: ${breakpoints.medium}) {
    margin: 0 -10px;
  }
`;

export const GroupItem = styled(CardButton)`
  position: relative;
  text-align: center;
  padding: 20px;
  transition: border-color .2s ease-in-out;
`;

export const GroupItemCount: any = styled.div`
  ${textStyles.title};

  @media (max-width: ${breakpoints.medium}) {
    min-width: 35px;
    text-align: left;
  }
`;

export const GroupItemTitle = styled.div`
  ${textStyles.subhead};

  @media (max-width: ${breakpoints.medium}) {
    font-weight: 400;
  }
`;

export const GroupItemContent = styled(Flex)`
  width: 100%;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: column;
  }

  > * {
    position: relative;
    flex: 1;

    @media (max-width: ${breakpoints.medium}) {
      display: flex;
    }

    + * {
      @media (max-width: ${breakpoints.medium}) {
        margin-top: 5px;
      }

      &:before {
        ${AbsoluteCover};
        content: '';
        right: auto;
        width: 1px;
        background: ${colors.middleGrey};

        @media (max-width: ${breakpoints.medium}) {
          display: none;
        }
      }
    }
  }
`;

export const ProductGroupColumn: any = styled(Box)`
  flex: 0 0 calc(100% / 3);
  padding: 0 15px;
  cursor: pointer;
  min-width: 300px;

  @media (max-width: ${breakpoints.medium}) {
    flex: 0 0 auto;
    padding: 0 10px;
    min-width: 230px;
  }
`;

export const Count = styled.div`
  ${textStyles.title}
`;

export const Heading = styled(Box)`
  ${textStyles.title};
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.medium}) {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

export const BodyText = styled.div`
  ${textStyles.body};
  margin-top: 5px;
`;

export const SortSelect = styled.button`
  ${textStyles.body};
  position: relative;
  margin-left: 15px;

  @media (max-width: ${breakpoints.medium}) {
    font-size: 12px;
    top: -1px;
  }

  &:after {
    ${cover()};
    content: '';
    top: auto;
    height: 1px;
    background: ${colors.floomMidnightBlue};
  }
`;

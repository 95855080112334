import React, { Component, ReactNode } from 'react';

import FieldText from 'components/field-text';
import Icon from 'components/icon';

import { DatePickerTriggerProps } from './date-picker-trigger.types';

class DatePickerTrigger extends Component<DatePickerTriggerProps> {
  static defaultProps: Partial<DatePickerTriggerProps> = {
    isDisabled: false,
    isClearable: false,
    shouldAutofocus: false
  };

  triggerRef: any = React.createRef();

  focus = (): any => this.triggerRef.current.focus();

  render(): ReactNode {
    const value = !this.props.controlledValue ? '' : this.props.value;

    switch (this.props.type) {
      case 'button':
        return (
          <button
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
            autoFocus={this.props.shouldAutofocus}
            ref={this.props.innerRef}
          >
            <Icon iconName="edit" />
          </button>
        );

      case 'input-compact':
        return (
          <FieldText
            onChange={this.props.onChange}
            onFocus={this.props.onFocus}
            onKeyUp={this.props.onKeyUp}
            onBlur={this.props.onBlur}
            autoFocus={this.props.shouldAutofocus}
            value={value}
            isReadOnly={true}
            isDisabled={this.props.isDisabled}
            size="small"
            isCentered={true}
            isSpellCheckEnabled={false}
            ref={this.props.innerRef}
          />
        );

      default:
        return (
          <FieldText
            onChange={this.props.onChange}
            onFocus={this.props.onFocus}
            onKeyUp={this.props.onKeyUp}
            onBlur={this.props.onBlur}
            autoFocus={this.props.shouldAutofocus}
            isDisabled={this.props.isDisabled}
            isReadOnly={true}
            value={value}
            onIconClick={this.props.onClear}
            iconType={this.props.isClearable && !!value ? 'cross-big' : 'calendar'}
            size="normal"
            isSpellCheckEnabled={false}
            ref={this.props.innerRef}
          />
        );
    }
  }
}

export default DatePickerTrigger;

import { Component, ReactNode } from 'react';

import { Flex } from 'rebass';

import { UserRole } from 'generated-types.d';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';

import OrderHelpers from 'features/orders/helpers/order-helpers';

import Icon from 'components/icon';

import * as Styles from './field-search-item.styles';
import * as Types from './field-search-item.types';

export default class FieldSearchItem extends Component<Types.FieldSearchItemProps> {
  private generateOrderTitle = (): string => {
    return this.hasMultipleItems()
      ? `${this.props.data.orderItems.length} Products`
      : `1x ${this.getProductTitle(this.props.data.orderItems[0].productSnapshot)}`;
  };

  private getProductTitle = (snap: any): string => snap.product ? snap.product.title : snap.title;

  private hasMultipleItems = (): boolean => this.props.data.orderItems.length > 1;

  render(): ReactNode {
    return(
      <Styles.Item
        as="li"
        onMouseDown={(): void => {
          this.props.onMouseDown(this.props.data.orderNo);
        }}
      >
        <Styles.Heading>
          Order {OrderHelpers.shortenOrderNumber(this.props.data.orderNo)}
          <Styles.Copy pl="5px">
            - {this.props.data.status.title}
            <FloomXRestrictedComponent
              restrictedRoles={[UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember]}
            >
              &nbsp;|&nbsp;{this.props.data.merchant.title}
            </FloomXRestrictedComponent>
          </Styles.Copy>
        </Styles.Heading>
        <Flex
          alignItems="center"
          flexWrap="wrap"
          mt="10px"
        >
          <Flex
            alignItems="center"
            css={Styles.TitleMeta}
          >
            <Styles.SearchIcon>
              <Icon iconName="petal" />
            </Styles.SearchIcon>
            <Styles.Copy pr="20px">
              {this.generateOrderTitle()}
            </Styles.Copy>
          </Flex>
          <Flex alignItems="center">
            <Styles.SearchIcon>
              <Icon iconName="map-pin" />
            </Styles.SearchIcon>
            <Styles.Copy pr="30px">
              {this.props.data.shippingAddress.postalCode}
            </Styles.Copy>
          </Flex>
          <Flex alignItems="center">
            <Styles.SearchIcon>
              <Icon iconName="delivery-time" />
            </Styles.SearchIcon>
            <Styles.Copy pr="20px">
              {OrderHelpers.renderDeliveryTime(this.props.data)}
            </Styles.Copy>
          </Flex>
        </Flex>
      </Styles.Item>
    );
  }
}

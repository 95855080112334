import React, { Component, ReactNode } from 'react';

import _get from 'lodash.get';
import _startCase from 'lodash.startcase';
import { inject, observer } from 'mobx-react';

import { Order } from 'generated-types.d';
import { OmitType } from 'global-types';

import { HTMLService, PermissionsService } from 'lib';

import OrderNoteHelpers from 'features/orders/helpers/order-note-helpers';
import { OrderEditService } from 'features/orders/services';

import GenericModal from 'components/generic-modal';

import NotesModalContent from './notes-modal-content';
import * as Types from './order-notes-modal.types';

const initialState: OmitType<Types.OrderNotesModalState, 'noteToEdit' | 'data'> = {
  isEditing: false,
  isSaving: false,
  editedNote: ''
};

class OrderNotesModal extends Component<Types.OrderNotesModalProps, Types.OrderNotesModalState> {
  setNoteToEdit = (): Types.NoteType => PermissionsService.isInternalRole() ? Types.NoteType.Floom : Types.NoteType.Florist;

  state: Types.OrderNotesModalState = {
    ...initialState,
    noteToEdit: this.setNoteToEdit(),
    data: this.props.data
  };

  private getNoteField = (type: Types.NoteType, field: string = 'content'): string | undefined => {
    if (this.state.data) {
      return _get(OrderNoteHelpers[`find${_startCase(type)}Note`](this.state.data!.notes), field);
    }

    return undefined;
  };

  private closeModal = (): void => {
    this.props.closeModal();

    setTimeout(() => {
      this.props.data.onClose();
    }, 200);
  };

  private changeNote = (value: string): void => {
    this.setState({ editedNote: value });
  };

  private resetModal = (): void => {
    this.setState(initialState);
  };

  private onClick = (): void => {
    if (this.state.isSaving) return;

    if (!this.state.isEditing) {
      this.onEdit();
    } else {
      this.onSave();
    }
  };

  private sanitizeNoteForSave = (): string => HTMLService.sanitize(this.state.editedNote);

  private onEdit = (): void => {
    this.setState(prevState => ({
      isEditing: true,
      editedNote: this.removeBreaks(this.getNoteField(prevState.noteToEdit) || '')
    }));
  };

  private onNoteUpdate = (order: Order): void => {
    const data: any = {
      notes: order.orderNotes!,
      orderNo: order.orderNo
    };

    this.props.ordersStore!.updateNotes(data);

    this.setState({
      isEditing: false,
      editedNote: '',
      data: data
    });
  };

  private onSave = async (): Promise<void> => {
    const noteCopy = this.sanitizeNoteForSave();
    const isFloom = this.state.noteToEdit === Types.NoteType.Floom;
    const noteField = this.getNoteField(this.state.noteToEdit, 'id');

    try {
      this.setState({ isSaving: true });

      if (noteField) {
        const order = await OrderEditService.updateOrderNote(noteCopy, isFloom, {
          orderNo: this.props.data.orderNo,
          itemId: noteField
        });

        this.onNoteUpdate(order);
      } else {
        const order = await OrderEditService.createOrderNote(noteCopy, isFloom, { orderNo: this.props.data.orderNo });

        this.onNoteUpdate(order);
      }

      this.setState({ isSaving: false });
    } catch (error) {
      this.setState({ isSaving: false });
    }
  };

  private onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.keyCode === 13 && event.metaKey) {
      this.onSave();
    }
  };

  private removeBreaks = (note: string): string => HTMLService.stripTagsAndAddNewLine(HTMLService
    .sanitize(note));

  componentDidMount = (): void => {
    if (this.props.data.activeNoteContent) {
      this.setState({
        isEditing: true,
        editedNote: this.props.data.activeNoteContent
      });
    }
  };

  render = (): ReactNode => {
    return(
      <GenericModal
        innerComponent={(
          <NotesModalContent
            isEditing={this.state.isEditing}
            editedNote={this.state.editedNote}
            noteToEdit={this.state.noteToEdit}
            onChange={this.changeNote}
            onKeyDown={this.onKeyDown}
            resetModal={this.resetModal}
            getNoteField={this.getNoteField}
          />
        )}
        confirmButtonAction={this.onClick}
        isButtonLoading={this.state.isSaving}
        confirmButtonText={this.state.isEditing ? 'Save note' : this.getNoteField(this.state.noteToEdit) ? 'Edit note' : 'Add note'}
        title="Order notes"
        modalOpen={this.props.isOpen}
        hasOverflow={false}
        closeModal={this.closeModal}
      />
    );
  };
}

export default inject('ordersStore')(observer(OrderNotesModal));

import { ApolloQueryResult } from '@apollo/client';
import store from 'stores';

import {
  ProductType,
  Product,
  ChannelType,
  ProductConnection,
  PlanFeature
} from 'generated-types.d';

import { GraphQL, MerchantService, ProductService } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import {
  ProductGroupOption,
  ProductStockGroup,
  ProductFilterType
} from 'stores/products-store';

import {
  PRODUCT_CATEGORIES_COUNT_QUERY
} from 'features/products/graphql/queries/product-categories.queries';
import {
  PRODUCT_CHANNELS_COUNT_QUERY
} from 'features/products/graphql/queries/product-channels.queries';
import { PRODUCT_STOCK_QUERY } from 'features/products/graphql/queries/product-meta.queries';
import {
  PRODUCT_TYPES_COUNT_QUERY
} from 'features/products/graphql/queries/product-types.queries';
import {
  ALL_PRODUCTS,
  PRODUCTS_BY_STOCK,
  PAGINATE_PRODUCTS
} from 'features/products/graphql/queries/product.queries';
import { STOCK_VALUES } from 'features/products/products.constants';

const productQueries: { [key in ProductGroupOption]: any } = {
  allProducts: ALL_PRODUCTS,
  stock: PRODUCTS_BY_STOCK
};

class ProductAPIService {
  private ProductsStore = store.productsStore;

  private ToasterStore = store.toasterStore;

  private UserStore = store.userStore;

  private MerchantStore = store.merchantStore as MerchantStore;

  public fetchProducts = async (variables: any): Promise<ApolloQueryResult<any>> => {
    return GraphQL.query(productQueries[this.ProductsStore.currentGroup](variables), variables, 'no-cache')
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.ProductsStore.loadingError(error);
      });
  };

  public paginateProducts = async (
    variables: any,
    groupName: ProductGroupOption,
    currentGroup: ProductGroupOption
  ): Promise<ApolloQueryResult<any>> => {
    return GraphQL.query(PAGINATE_PRODUCTS(variables, groupName, currentGroup), variables)
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.ProductsStore.loadingError(error);
      });
  };

  public fetchProductsMeta = async (): Promise<{ [key in ProductStockGroup]: ProductConnection }> => {
    const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.MerchantStore!.merchant);
    const baseVars = {
      merchantId: this.UserStore.merchantId,
      isCollectionSeller: isCollectionSeller
    };
    const variables = { ...baseVars, ...STOCK_VALUES };

    try {
      const result = await GraphQL.query<{ [key in ProductStockGroup]: ProductConnection }>(PRODUCT_STOCK_QUERY(baseVars), variables);

      return result.data;
    } catch (error) {
      this.ProductsStore.loadingError(error);

      return Promise.reject(error);
    }
  };

  public toggleActiveStatus = async (productId: string, isActive: boolean): Promise<Product> => {
    try {
      return ProductService.editProduct({ id: productId }, {
        active: isActive
      });
    } catch (error) {
      this.ToasterStore.popErrorToast('your product', isActive ? 'enable' : 'disable');
      this.ProductsStore.loadingError(error);

      return Promise.reject(error);
    }
  };

  public fetchProductChannelsCount = async (
    variables: any,
    productChannels: ChannelType[]
  ): Promise<ApolloQueryResult<any[]>> => {
    return GraphQL.query(PRODUCT_CHANNELS_COUNT_QUERY(variables, productChannels), variables, 'no-cache')
      .then(({ data }: any) => data);
  };

  public fetchProductCategoriesCount = async (
    variables: any,
    productCategories: ProductFilterType[]
  ): Promise<ApolloQueryResult<any[]>> => {
    return GraphQL.query(PRODUCT_CATEGORIES_COUNT_QUERY(variables, productCategories), variables, 'no-cache')
      .then(({ data }: any) => data);
  };

  public fetchProductTypesCount = async (
    variables: any,
    productTypes: ProductType[]
  ): Promise<ApolloQueryResult<any[]>> => {
    return GraphQL.query(PRODUCT_TYPES_COUNT_QUERY({ ...variables, merchantId: this.UserStore.merchantId }, productTypes), variables, 'no-cache')
      .then(({ data }: any) => data);
  };
}

export default new ProductAPIService();

import { css } from '@emotion/react';

import { breakpoints, colors } from 'utils/rebass-theme';

export const LoadMoreLink = css`
  font-weight: 600;
  text-decoration: underline;
  display: inline;
  cursor: pointer;
  color: ${colors.floomMidnightBlue};
`;

export const Wrapper = css`
  display: grid;
  grid-template-areas: 'search toggle . sort';
  grid-template-rows: auto;
  align-items: center;
  grid-template-columns: auto;
  grid-gap: 20px;

  @media (max-width: ${breakpoints.large}) {
    grid-template-columns: auto;
    grid-gap: 5px;
    grid-row-gap: 20px;
    grid-template-areas:
      'search sort'
      'toggle toggle';
  }
`;

import { gql } from '@apollo/client';
import store from 'stores';

import {
  List,
  ListWhereInput,
  ListWhereUniqueInput,
  Query,
  QuerySupplierDeliveryConfigsArgs,
  WholesalePreOrder,
  WholesalePreOrderCreateInput,
  WholesalePreOrderDeliveryDate
} from 'generated-types.d';

import { GraphQL } from 'lib';

import {
  LIST_DELIVERY_DATES
} from 'features/lists/graphql/queries/list.queries';
import {
  CREATE_WHOLESALE_PREORDER
} from 'features/wholesale/graphql/mutators/pre-order/pre-order';
import { PROMOTED_LISTS } from 'features/wholesale/graphql/queries/promoted-lists/promoted-list.queries';

export const SUPPLIER_DELIVERY_CONFIGS = gql`
  query SupplierDeliveryConfigs(
    $where: SupplierDeliveryConfigWhereInput!
  ) {
    supplierDeliveryConfigs(
      where: $where
    ) {
      id
      listItemTypes
      supplier {
        id
        active
      }
      deliveryOptions {
        freeShippingThreshold
        shippingPrice
      }
    }
  }
`;

export class PromotedListsStoreService {
  public static fetchList = async (): Promise<List | null> => {
    try {
      const supplierIds = store.merchantStore.merchant?.wholesaleDeliveryConfigs?.[0]?.hubs?.map(hub => hub?.supplier?.id!).filter(Boolean) || [];

      if (!supplierIds?.length) {
        return null;
      }

      const where: ListWhereInput | undefined = supplierIds && {
        suppliers_some: {
          id_in: supplierIds
        }
      };

      const result = await GraphQL.query<Pick<Query, 'promotedLists'>>(PROMOTED_LISTS, { where });

      return result.data.promotedLists?.[0]! || null;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static fetchDeliveryDates = async (id: string): Promise<WholesalePreOrderDeliveryDate[]> => {
    const where: ListWhereUniqueInput = { id };

    if (!!store.merchantStore?.merchant) {
      where.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<{ wholesalePreOrderDeliveryDates: WholesalePreOrderDeliveryDate[] }>(LIST_DELIVERY_DATES, { where });

      return result.data.wholesalePreOrderDeliveryDates;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static fetchDeliveryConfigs = async ({
    supplierId
  }: {
    supplierId: string;
  }): Promise<Query['supplierDeliveryConfigs']> => {
    if (!store.merchantStore?.merchant) {
      return Promise.reject('Merchant must exist');
    }

    const args: QuerySupplierDeliveryConfigsArgs = {
      where: {
        deletedAt: null,
        supplier: {
          id: supplierId
        },
        deliveryHubs_some: {
          id_in: store.merchantStore.merchant.wholesaleDeliveryConfigs?.[0]?.hubs?.map(hub => hub?.id!).filter(Boolean) || []
        }
      }
    };

    try {
      const result = await GraphQL.query<Pick<Query, 'supplierDeliveryConfigs'>>(SUPPLIER_DELIVERY_CONFIGS, args);

      return result.data.supplierDeliveryConfigs;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static createWholesalePreOrder = async (data: WholesalePreOrderCreateInput): Promise<Pick<WholesalePreOrder, 'id'>> => {
    try {
      const result = await GraphQL.query<{ createWholesalePreOrder: Pick<WholesalePreOrder, 'id'> }>(CREATE_WHOLESALE_PREORDER, { data });

      return result.data?.createWholesalePreOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

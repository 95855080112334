import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const EditBox: any = styled(Box)`
  height: 100%;
  width: 100%;
  min-height: 100px;
  padding-bottom: 60px;
  border: ${colors.middleGrey} 2px solid;
  border-radius: 3px;
  background: ${colors.uiFills};

  textarea {
    resize: none;

    &:disabled {
      background: #fff;
      color: ${colors.floomMidnightBlue};
    }
  }
`;

export const EditControls: any = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
`;

export const CharCount: any = styled.div`
  position: absolute;
  bottom: 14px;
  left: 14px;
  height: 35px;
  border-radius: 3px;
  background-color: ${colors.lightGrey};
  color: ${colors.middleGrey};
  line-height: 32px;
  padding: 0 10px;
`;

export const IconButton: any = styled.button<{ isShowingCopy: boolean }>`
  height: 35px;
  width: ${({ isShowingCopy }): string => isShowingCopy ? 'auto' : '35px'};
  padding: ${({ isShowingCopy }): string => isShowingCopy ? '0 10px' : '0'};
  border-radius: 3px;
  margin: 3px;
`;

export const EditBoxContainer: any = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

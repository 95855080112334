import { gql } from '@apollo/client';

import { GraphQLHelpers } from 'lib';

import {
  MerchantHolidayQueryVars
} from 'features/settings/components/merchant-holidays/merchant-holidays.types';

import { MERCHANT_HOLIDAY_FRAGMENT } from '../fragments/merchant-holiday.fragments';

export const mapMerchantHoldayGroups = (vars: MerchantHolidayQueryVars): string[] => {
  return vars.groups.map(group => {
    return `
      ${group.title}: merchantHolidaysConnection(
        first: 150
        where: {
          deletedAt: null
          type: Merchant
          ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
          AND: [
            ${!!group.start ? `{startAt_gte: "${group.start}"}` : ''}
            ${!!group.end ? `{startAt_lte: "${group.end}"}` : ''}
            ${!!group.overlap ? `{endAt_gte: "${group.overlap}"}` : ''}
          ]
        }
      ) {
        aggregate {
          count
        }
        edges {
          node {
            ...MerchantHolidayFragment
          }
        }
      }
    `;
  });
};

export const MERCHANT_HOLIDAYS = (vars: MerchantHolidayQueryVars): any => gql`
  query MerchantHolidays
    ${vars.merchantId ? `(
      ${GraphQLHelpers.addVariable(Boolean(vars.merchantId), 'merchantId', 'ID!')}
    )` : ''} {
      ${mapMerchantHoldayGroups(vars)}
    }
    ${MERCHANT_HOLIDAY_FRAGMENT}
`;

import React, { Component, ReactNode } from 'react';

import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import OrderHelpers from 'features/orders/helpers/order-helpers';
import { OrderStatusSlug } from 'features/orders/orders.types';

import Icon from 'components/icon';
import ModalTrigger from 'components/modal-trigger';
import StyledLink from 'components/styled-link/styled-link';
import Tooltip from 'components/tooltip';

import * as Styles from './item-header.styles';
import * as Types from './item-header.types';

class OrderItemHeader extends Component<Types.OrderItemHeaderProps> {
  liveStatuses: OrderStatusSlug[] = [OrderStatusSlug.Open, OrderStatusSlug.Prepared];

  private getFlexDirection = (): string => {
    switch (this.props.layout) {
      case 'grid':
        return 'row';

      case 'table':
        return 'column';
    }
  };

  private isOrderLive = (): boolean => {
    return this.liveStatuses.includes(this.props.currentStatus) && this.props.data.new;
  };

  render(): ReactNode {
    return (
      <Styles.Heading
        layout={this.props.layout}
        status={this.props.config}
        isNew={this.isOrderLive()}
      >
        <Flex
          flexDirection={this.getFlexDirection()}
          alignItems="center"
        >
          <Styles.StatusIcon
            layout={this.props.layout}
            status={this.props.config}
            isNew={this.isOrderLive()}
          >
            <Icon iconName={this.props.config.iconName} />
          </Styles.StatusIcon>
          <ModalTrigger
            to={`/orders/list/${this.props.orderRoute}/detail/${this.props.data.orderNo}`}
            modalType="order-detail"
            id={this.props.data.orderNo}
            preventScroll={true}
            styles={Styles.OrderLink}
          >
            <StyledLink color={colors[this.props.config.textColor!] || colors.floomMidnightBlue}>
              <Tooltip
                message={this.props.data.status.title}
                styles={{ fontSize: '15px' }}
              >
                {OrderHelpers.shortenOrderNumber(this.props.data.orderNo)}
              </Tooltip>
            </StyledLink>
          </ModalTrigger>
        </Flex>
      </Styles.Heading>
    );
  }
}

export default OrderItemHeader;

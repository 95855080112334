import React, { FC, Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import MerchantStore from 'stores/merchant/merchant-store';
import WholesaleOrdersStore from 'stores/wholesale/wholesale-orders-store/wholesale-orders-store';

import Button from 'components/button';
import { EntityListItems } from 'components/entity-list/entity-list.styles';
import TableLayoutEntityHeading from 'components/entity-list/table-layout-entity-heading';
import SectionHeading from 'components/section-heading';

import { WholesalePreOrderGroupConfig } from '../../pages/orders/orders';
import * as Styles from '../../pages/orders/orders.styles';
import WholesaleServices from '../../services';
import PreOrderCard from '../order-card/pre-order-card';
import PreOrderCardTable from '../pre-order-card-table';

import { ListOverflowWrapper, ListWrapper } from './pre-order-group.styles';

export interface PreOrderGroupProps {
  preOrderGroup: WholesalePreOrderGroupConfig;
  wholesaleOrdersStore?: WholesaleOrdersStore;
  merchantStore?: MerchantStore;
}

const PreOrderGroup: FC<PreOrderGroupProps> = ({
  preOrderGroup,
  wholesaleOrdersStore,
  merchantStore
}) => {
  const loadMorePreOrders = (orderGroupKey: any): void => {
    wholesaleOrdersStore!.incrementPreOrderCount(orderGroupKey);
    wholesaleOrdersStore!.setInlineLoading();
    WholesaleServices.WholesaleOrdersAPIService.paginatePreOrders(orderGroupKey);
  };

  return (
    <Fragment key={preOrderGroup.title}>
      <Box
        mt="50px"
        mb={wholesaleOrdersStore!.listLayoutType === 'grid' ? '25px' : '0'}
      >
        <SectionHeading
          title={preOrderGroup.title}
          size="small"
          hasSpacing={false}
        />
      </Box>
      {wholesaleOrdersStore!.listLayoutType === 'grid' ? (
        <Fragment>
          <Styles.CardsContainer layoutType="grid">
            {preOrderGroup.items!.edges?.map(order => {
              if (!order || !order?.node?.items?.length) return null;

              return (
                <PreOrderCard
                  key={order.node.id}
                  order={order.node}
                  onViewDetail={(): void => preOrderGroup.onViewDetail(order.node.id)}
                  hasGlobalMerchant={!!merchantStore!.merchant}
                />
              );
            })}
          </Styles.CardsContainer>
        </Fragment>
      ) : (
        <ListOverflowWrapper>
          <ListWrapper>
            <TableLayoutEntityHeading headers={preOrderGroup.headingConfig} />
            <EntityListItems>
              <Styles.CardsContainer layoutType="table">
                {preOrderGroup.items!.edges?.map(order => {
                  if (!order || !order?.node?.items?.length) return null;

                  return (
                    <PreOrderCardTable
                      key={order.node.id}
                      preOrder={order.node}
                    />
                  );
                })}
              </Styles.CardsContainer>
            </EntityListItems>
          </ListWrapper>
        </ListOverflowWrapper>
      )}
      {preOrderGroup.items!.pageInfo.hasNextPage && (
        <Styles.Pagination>
          <button
            onClick={(): any => loadMorePreOrders(preOrderGroup.key)}
            disabled={wholesaleOrdersStore!.isLoadingInline}
          >
            <Button
              copy="Load more"
              isLoading={wholesaleOrdersStore!.isLoadingInline}
            />
          </button>
        </Styles.Pagination>
      )}
    </Fragment>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore,
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  merchantStore: stores.merchantStore
}))(observer(PreOrderGroup));

import { Component, ReactNode } from 'react';

import { Order } from 'generated-types.d';

import { textStyles } from 'utils/rebass-theme';

import OrderHelpers from 'features/orders/helpers/order-helpers';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';

interface OrderTitleMetaProps {
  data: Order;
}

class OrderTitleMeta extends Component<OrderTitleMetaProps> {
  render(): ReactNode {
    return (
      <MetaCardContentWrapper iconName="lock">
        <span css={textStyles.subhead}>
          Order {OrderHelpers.shortenOrderNumber(this.props.data.orderNo)}
        </span>
      </MetaCardContentWrapper>
    );
  }
}

export default OrderTitleMeta;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

import { shadows } from 'utils/rebass-theme';

export const poseConfig = (onPoseComplete: () => void): any => ({
  pressable: true,
  enter: {
    y: 0,
    opacity: 1,
    scale: 1,
    onPoseComplete: onPoseComplete,
    transition: {
      duration: 100,
      scale: {
        type: 'spring',
        velocity: 2
      },
      default: {
        type: 'spring'
      }
    }
  },
  exit: {
    y: 0,
    scale: 0.9,
    opacity: 0,
    transition: {
      duration: 1000000,
      scale: {
        type: 'spring',
        velocity: 2
      },
      default: {
        type: 'spring'
      }
    }
  },
  press: {
    scale: 0.95
  }
});

export const StyledToast: any = styled(Box)`
  background: ${({ config }: any): any => config.background};
  position: relative !important;
  padding: 15px 55px 15px 25px;
  border-radius: 2px;
  width: 100%;
  transition: all .2s ease;
  margin: ${({ isModal }: any): any => isModal ? '0 auto' : '0'};
  box-shadow: ${({ isModal }: any): any => isModal ? 'none' : shadows.small};
  max-width: ${({ isModal }: any): any => isModal ? '800px' : '270px'};

  + * {
    margin-top: 20px;
  }

  > * {
    color: ${({ config }: any): any => config.textColor} !important;
  }
`;

export const ToastTransitionStyles = css`
  position: relative !important;
  width: 100%;
`;

export const ToastCloseIcon = styled(Box)`
  position: absolute;
  top: 13px;
  right: 20px;
`;

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, fontSizes } from 'utils/rebass-theme';

export const ItemPriceWrapper = styled(Flex)`
  flex-direction: column;
  flex: 1;
`;

export const ItemTotalPrice = styled(Box)`
  font-size: ${fontSizes.small}px;
  line-height: 20px;
  font-weight: 700;
  color: ${colors.black};
`;

export const ItemUnitPrice = styled(Box)`
  font-size: ${fontSizes.xSmall}px;
  line-height: 20px;
  color: ${colors.grey};
`;

export const ItemUnitPriceHighlight = styled(Box)`
  font-size: ${fontSizes.small}px;
  line-height: 20px;
  font-weight: 700;
  color: ${colors.black};
`;

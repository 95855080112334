import React, { Component, ReactNode } from 'react';

import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';

import {
  PlanFeature
} from 'generated-types.d';

import {
  Auth,
  MerchantService,
  NavService
} from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import {
  OnboardingStep,
  OnboardingStage
} from 'stores/payment-onboarding/payment-onboarding-store.types';

import { Container } from 'utils/css-mixins';

import OnboardingProgressIndicator from 'features/onboarding/components/onboarding-progress-indicator';

import Header from 'components/header';
import PageCornerBackground from 'components/page-corner-background';
import WithLoading from 'components/with-loading';

import * as Types from './onboarding-controller.types';

class OnboardingController extends Component<Types.OnboardingControllerProps, Types.OnboardingControllerState> {
  private config = (): OnboardingStep[] => {
    const merchant = this.props.merchantStore!.merchant!;

    return [
      {
        stage: OnboardingStage.Subscription,
        title: 'Subscription',
        isRequired: true,
        isComplete: MerchantService.isSubscribed(merchant),
        navigateToStep: (): void => NavService.onboardingSubscription()
      },
      {
        stage: OnboardingStage.StripeConnect,
        title: 'Custom site payments',
        isRequired: MerchantService.hasPlanFeature(PlanFeature.Website, merchant),
        isComplete: MerchantService.isStripeConnected(merchant),
        navigateToStep: (): void => NavService.onboardingStripeConnect()
      },
      {
        stage: OnboardingStage.BankDetails,
        title: 'Floom.com payments',
        isRequired: MerchantService.hasPlanFeature(PlanFeature.Floom, merchant),
        isComplete: MerchantService.hasProvidedFloomBankDetails(merchant),
        navigateToStep: (): void => NavService.onboardingFloomPayment()
      }
    ];
  };

  componentDidMount = (): void => {
    this.updateConfig(this.config());
    this.onMerchantUpdate();
    this.redirectToLogin();
  };

  private redirectToLogin = (): void => {
    if (!Auth.isLoggedIn()) {
      Auth.removeLoginToken();
      NavService.login();
    }
  };

  private onMerchantUpdate = (): void => {
    observe<MerchantStore, 'merchant'>(this.props.merchantStore!, 'merchant', ({ oldValue, newValue }: any) => {
      if (
        oldValue?.updatedAt !== newValue?.updatedAt
        || oldValue?.subscription?.updatedAt !== newValue?.subscription?.updatedAt
      ) {
        this.updateConfig(this.config());
      }
    });
  };

  private updateConfig = (config: OnboardingStep[]): void => {
    if (!!this.props.merchantStore!.merchant) {
      this.props.paymentOnboardingStore!.setOnboardingConfig(config);
    }
  };

  private findCurrentStepIndex = (): any => {
    return this.props.paymentOnboardingStore!.onboardingConfig.findIndex(step => step.stage === this.props.paymentOnboardingStore!.stage);
  };

  private handleEagerBeaver = (): void => {
    const { onboardingConfig } = this.props.paymentOnboardingStore!;
    const firstIncompleteStep = onboardingConfig.findIndex(step => !step.isComplete && step.isRequired);
    const currentStep = this.findCurrentStepIndex();

    if (!!this.props.merchantStore!.merchant) {
      switch (true) {
        // If the user lands on a step before they have completed a previous step
        case currentStep !== -1 && firstIncompleteStep !== -1 && currentStep > firstIncompleteStep:
          onboardingConfig[firstIncompleteStep]?.navigateToStep();

          break;
      }
    }
  };

  render(): JSX.Element {
    this.handleEagerBeaver();

    return(
      <>
        <Header hideMenu={true} />
        <PageCornerBackground offset="0" />
        <Container>
          <WithLoading
            loaderSize="small"
            marginTop="30px"
            hasNoResults={!this.props.merchantStore!.isLoadingMerchant && !this.props.merchantStore!.merchant}
            isLoading={!this.props.merchantStore!.merchant}
            renderNoResults={(): ReactNode => (
              'Something has gone wrong'
            )}
          >
            <OnboardingProgressIndicator
              selectedIndex={this.findCurrentStepIndex()}
            />
            {this.props.children}
          </WithLoading>
        </Container>
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  paymentOnboardingStore: stores.paymentOnboardingStore
}))(observer(OnboardingController));

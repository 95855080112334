import React, { Component, ReactNode } from 'react';

import { Global } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { inject, observer } from 'mobx-react';

import { Auth } from 'lib';

import { PusherProvider } from 'hooks/PusherContext';

import Navigation from 'components/navigation';

import * as Types from './app.types';
import GlobalLayout from './global-layout';
import * as Styles from './global-layout.styles';

const queryClient = new QueryClient();

class AppLayout extends Component<Types.AppLayoutProps> {
  state = {
    isLoggedIn: false
  };

  componentDidMount(): void {
    // TODO: When we refactor / extend login auth, this needs to be fixed
    this.setState({
      isLoggedIn: Auth.isLoggedIn()
    });
  }

  private calculateBodyOffset = (): string => {
    if (this.state.isLoggedIn && process.env.FX_UNDER_MAINTENANCE !== 'true') {
      return this.props.navigationStore!.navWidth;
    }

    return '0';
  };

  private renderNavigation = (): ReactNode => {
    if (this.state.isLoggedIn && process.env.FX_UNDER_MAINTENANCE !== 'true') {
      return (
        <Navigation />
      );
    }
  };

  render(): React.ReactNode {
    const marginLeft = this.calculateBodyOffset();

    return (
      <GlobalLayout>
        <PusherProvider>
          <QueryClientProvider client={queryClient}>
            <Global styles={Styles.AppGlobal} />
            {this.renderNavigation()}
            <Styles.Main
              id="global-layout"
              marginLeft={marginLeft}
            >
              {this.props.children}
            </Styles.Main>
          </QueryClientProvider>
        </PusherProvider>
      </GlobalLayout>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore
}))(observer(AppLayout));

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const SelectedWrapper = styled(Flex)`
  flex-flow: row wrap;
  width: calc(100% + 20px);

  :after {
    content: "";
    width: 240px;
  }
`;

export const ItemLayout = css`
  width: calc(33.33% - 20px);
  margin: 0 20px 20px 0;

  @media (max-width: 900px) {
    width: calc(50% - 20px);
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Item = styled(Box)`
  ${ItemLayout};
`;

export const SelectNewItem = styled(Flex)`
  ${textStyles.subhead};
  ${ItemLayout}
  align-self: left;
  height: 70px;
  border: 2px dashed ${colors.middleGrey};
  border-radius: 3px;
  cursor: pointer;
  align-items: center;
  user-select: none;
  justify-content: center;

  > p {
    margin-bottom: 3px;
    margin-left: 8px;
  }
`;

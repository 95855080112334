import { useContext } from 'react';

import { ListFieldKey } from 'features/lists/lists.types';

import { SearchResultContext } from '../catalog-inline-search/catalog-inline-search-field/catalog-inline-search-body';

import { SearchReducerType } from './catalog-inline-search.reducers';

export const useCatalogSearchUpdate = (): {
  changeProperty: (value: string, key: ListFieldKey, itemId: string) => void;
} => {
  const { dispatch, state } = useContext(SearchResultContext);

  const changeProperty = (
    value: string,
    key: ListFieldKey,
    itemId: string
  ): void => {
    switch (true) {
      case value === null: {
        dispatch({
          type: SearchReducerType.DeleteSelection,
          payload: {
            itemId,
            key
          }
        });

        break;
      }

      case !!value && !!state[itemId]?.selections?.[key]: {
        dispatch({
          type: SearchReducerType.UpdateSelection,
          payload: {
            itemId,
            key,
            value
          }
        });
      }

      default:

      case !!value && !state[itemId]?.selections?.hasOwnProperty?.(key): {
        dispatch({
          type: SearchReducerType.CreateSelection,
          payload: {
            itemId,
            key,
            value
          }
        });
      }
    }
  };

  return {
    changeProperty
  };
};

import gql from 'graphql-tag';

import { FULL_LIST_ITEM_FRAGMENT } from 'features/lists/graphql/fragments/list.fragments';

export const WHOLESALE_PRE_ORDER_FRAGMENT = gql`
  fragment WholesalePreOrderFragment on WholesalePreOrder {
    id
    deliverAt
    startProcessingAt
    stopProcessingAt
    status
    totals {
      subTotalAmount
      shippingAmount
      salesTaxAmount
      grandTotalAmount
    }
    merchant {
      id
      title
      currency
      wholesaleDeliveryConfigs {
        hubs {
          title
          supplier {
            id
            name
          }
        }
      }
    }
    suppliers {
      id
      name
    }
    wholesaleOrder {
      id
      totalPrice
      status
      items {
        id
      }
    }
    items {
      id
      orderedAt
      failedAt
      noMatchAt
      processAttemptCount
      status
      wholesaleOrderLineItems {
        id
      }
      listItem {
        ...FullListItemFragment
      }
    }
  }
  ${FULL_LIST_ITEM_FRAGMENT}
`;

export const WHOLESALE_PRE_ORDER_DASHBOARD_FRAGMENT = gql`
  fragment WholesalePreOrderDashboardFragment on WholesalePreOrder {
    id
    deliverAt
    startProcessingAt
    stopProcessingAt
    status
    totals {
      subTotalAmount
      shippingAmount
      salesTaxAmount
      grandTotalAmount
    }
    merchant {
      id
      title
      currency
      wholesaleDeliveryConfigs {
        hubs {
          title
          supplier {
            id
            name
          }
        }
      }
    }
    suppliers {
      id
      name
    }
    wholesaleOrder {
      id
      totalPrice
      status
      items {
        id
      }
    }
    items {
      id
      orderedAt
      failedAt
      noMatchAt
      processAttemptCount
      status
      wholesaleOrderLineItems {
        id
      }
    }
  }
`;

export const WHOLESALE_PRE_ORDER_TOTALS_FRAGMENT = gql`
  fragment WholesalePreOrderTotalsFragment on WholesalePreOrder {
    totals {
      subTotalAmount
      shippingAmount
      salesTaxAmount
      grandTotalAmount
    }
  }
`;

export const WHOLESALE_PRE_ORDER_ITEM_FRAGMENT = gql`
  fragment WholesalePreOrderItemFragment on WholesalePreOrderItem {
    id
    orderedAt
    failedAt
    noMatchAt
    processAttemptCount
    status
    wholesaleOrderLineItems {
      id
    }
    listItem {
      ...FullListItemFragment
    }
  }
  ${FULL_LIST_ITEM_FRAGMENT}
`;

import store from 'stores';

import {
  MerchantHolidayUpdateInput,
  MerchantHolidayCreateInput,
  MerchantHolidayWhereUniqueInput
} from 'generated-types.d';

import { GraphQL, PermissionsService } from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { HOLIDAY_GROUP_CONFIG } from 'features/settings/components/merchant-holidays/merchant-holidays.config';
import { MerchantHolidayQueryVars } from 'features/settings/components/merchant-holidays/merchant-holidays.types';
import { CREATE_MERCHANT_HOLIDAY, UPDATE_MERCHANT_HOLIDAY, DELETE_MERCHANT_HOLIDAY } from 'features/settings/graphql/mutators/merchant-holiday.mutators';
import { MERCHANT_HOLIDAYS } from 'features/settings/graphql/queries/merchant-holiday.queries';

export default class MerchantHolidaysService {
  private toasterStore = store.toasterStore;

  private merchantHolidaysStore = store.merchantHolidaysStore;

  private userStore = store.userStore;

  private merchantStore = store.merchantStore;

  public fetchMerchantHolidays = async (): Promise<any> => {
    const vars: MerchantHolidayQueryVars = {
      groups: HOLIDAY_GROUP_CONFIG(PermissionsService.getUserRole()),
      merchantId: this.userStore.merchantId
    };

    return GraphQL.query(MERCHANT_HOLIDAYS(vars), vars)
      .then(result => {
        this.merchantHolidaysStore.setmerchantHolidays(result.data);

        return result;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('holidays', 'retrieve');

        return error;
      });
  };

  public updateMerchantHoliday = async (
    data: MerchantHolidayUpdateInput,
    id: string
  ): Promise<any> => {
    const where: MerchantHolidayWhereUniqueInput = { id };

    return GraphQL.query(UPDATE_MERCHANT_HOLIDAY, { data, where })
      .then(result => {
        this.fetchMerchantHolidays();
        this.toasterStore.popSuccessToast('merchant holiday', 'update');

        return result;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('merchant holiday', 'update');

        return Promise.reject(error);
      });
  };

  public deleteMerchantHoliday = async (id: string): Promise<any> => {
    return GraphQL.query(DELETE_MERCHANT_HOLIDAY, { where: { id } })
      .then(result => {
        this.fetchMerchantHolidays();
        this.toasterStore.popSuccessToast('merchant holiday', 'delete');

        return result;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('merchant holiday', 'delete');

        return Promise.reject(error);
      });
  };

  public createMerchantHoliday = async (
    data: Pick<MerchantHolidayCreateInput, 'startAt' | 'endAt'>,
    merchantId: string
  ): Promise<any> => {
    const createData: MerchantHolidayCreateInput = {
      startAt: data.startAt,
      endAt: data.endAt,
      notes: '',
      merchant: {
        connect: {
          id: merchantId
        }
      }
    };

    return GraphQL.query(CREATE_MERCHANT_HOLIDAY, { data: createData })
      .then(result => {
        this.fetchMerchantHolidays();

        if (merchantId !== this.userStore.merchantId && this.userStore.merchantId.length > 0) {
          this.toasterStore.popToast(`You've created a merchant holiday, but it's hidden as you are currently only viewing holidays for ${this.merchantStore?.merchant?.title}`, ToastType.Success);
        } else {
          this.toasterStore.popSuccessToast('merchant holiday', 'create');
        }

        return result;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('merchant holiday', 'create');

        return Promise.reject(error);
      });
  };
}

import { css } from '@emotion/react';
import hex2Rgba from 'hex2rgba';

import { colors } from 'utils/rebass-theme';

export const ModalStyles = css`
  .share-list-modal__ContentSlide {
    position: absolute;
    transform: translateX(20px);
    transition: transform 0.2s ease-in-out;
  }

  .share-list-modal__ContentSlide--after-open {
    transform: translateX(0);
  }

  .share-list-modal__ContentSlide--before-close {
    transform: translateX(20px);
  }

  .share-list-modal__OverlaySlide {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .share-list-modal__OverlaySlide--after-open {
    opacity: 1;
  }

  .share-list-modal__OverlaySlide--before-close {
    opacity: 0;
  }
`;

export const genericModalStylesProps = {
  overlayStyles: { background: hex2Rgba(colors.floomMidnightBlue, '0.6') },
  overlayClassName: {
    base: 'share-list-modal__OverlaySlide',
    afterOpen: 'share-list-modal__OverlaySlide--after-open',
    beforeClose: 'share-list-modal__OverlaySlide--before-close'
  },
  contentClassName: {
    base: 'share-list-modal__ContentSlide',
    afterOpen: 'share-list-modal__ContentSlide--after-open',
    beforeClose: 'share-list-modal__ContentSlide--before-close'
  },
  innerContentStyles: { paddingBottom: '60px' }
};

import { action, observable, makeObservable } from 'mobx';
import { TestMessageParams } from 'yup';

import {
  MerchantPlan,
  Merchant,
  Currency
} from 'generated-types.d';

import * as Types from './merchant-onboarding-store.types';

export default class MerchantOnboardingStore {
  constructor() {
    makeObservable(this, {
      canSkipOnboardingSteps: observable,
      merchantPlans: observable,
      values: observable,
      createdMerchantId: observable,
      isLoadingMerchantPlans: observable,
      validationErrors: observable,
      currency: observable,
      country: observable,
      merchantObject: observable,
      changeLocation: action,
      resetErrorForField: action,
      findSelectedMerchantPlan: action,
      populateMerchant: action,
      clearFieldsInformation: action,
      castAddressToBusinessAddress: action,
      resetBusinessAddress: action,
      setErrors: action,
      setValue: action,
      resetMerchantList: action,
      setMerchantPlans: action
    });

    this.setLocaleData();
  }

  private static setValueDefaults = (): Types.MerchantFieldValues => ({
    plan: '',
    planPrice: '',
    isDefaultPlanPrice: true,
    trialPeriod: '',
    companyName: '',
    givenName: '',
    familyName: '',
    email: '',
    phone: '',
    location: '',
    tradingNo: '',
    regAdphone: '',
    regAd1: '',
    regAd2: '',
    regAdcity: '',
    regAdpostalCode: '',
    businessAdCompanyName: '',
    businessAd1: '',
    businessAd2: '',
    businessAdCity: '',
    businessAdPostalCode: '',
    wholesaleDeliveryInstructions: '',
    hasChosenWholesale: false,
    areAddressesEqual: false
  });

  readonly canSkipOnboardingSteps: boolean = false;

  public merchantPlans: MerchantPlan[] = [];

  public isLoadingMerchantPlans: boolean = true;

  public currency: Currency = Currency.Gbp;

  public country: string = '';

  public createdMerchantId: string | null = null;

  public merchantObject: Merchant | null = null;

  public validationErrors: TestMessageParams[] = [];

  public values: Types.MerchantFieldValues = MerchantOnboardingStore.setValueDefaults();

  public populateMerchant = (merchant: Merchant): void => {
    this.merchantObject = merchant;
    this.createdMerchantId = merchant?.id;

    this.values = {
      plan: merchant?.plan?.id || '',
      planPrice: `${merchant?.subscription?.stripeSubscriptionPrice}` || '',
      isDefaultPlanPrice: merchant?.subscription?.stripeSubscriptionPrice === merchant?.plan?.price,
      trialPeriod: `${merchant?.subscription?.trialLength}` || '',
      companyName: merchant?.companyName || '',
      givenName: merchant?.createdBy?.givenName || '',
      familyName: merchant?.createdBy?.familyName || '',
      email: merchant?.createdBy?.email || '',
      phone: merchant?.createdBy?.phone || '',
      location: merchant?.timezone || '',
      tradingNo: merchant?.tradingNo || '',
      regAdphone: merchant?.registeredAddress?.phone || '',
      regAd1: merchant?.registeredAddress?.address1 || '',
      regAd2: merchant?.registeredAddress?.address2 || '',
      regAdcity: merchant?.registeredAddress?.city || '',
      regAdpostalCode: merchant?.registeredAddress?.postalCode || '',
      businessAdCompanyName: (!!merchant?.registeredAddress ? merchant?.registeredAddress?.businessName : merchant.companyName) || '',
      businessAd1: merchant?.address?.address1 || '',
      businessAd2: merchant?.address?.address2 || '',
      businessAdCity: merchant?.address?.city || '',
      businessAdPostalCode: merchant?.address?.postalCode || '',
      wholesaleDeliveryInstructions: merchant?.wholesaleDeliveryConfigs?.[0]?.deliveryInstructions || '',
      hasChosenWholesale: !!merchant?.wholesaleDeliveryConfigs?.length || false,
      areAddressesEqual: this.areAddressesEqual(merchant)
    };
  };

  public findSelectedMerchantPlan = (planIdToFind = this.values.plan): MerchantPlan | undefined => {
    return this.merchantPlans.find(plan => plan.id === planIdToFind);
  };

  public castAddressToBusinessAddress = (): void => {
    const keys: Array<keyof Types.MerchantFieldValues> = ['businessAd1', 'businessAd2', 'businessAdCity', 'businessAdPostalCode'];

    this.values = {
      ...this.values,
      businessAd1: this.values.regAd1,
      businessAd2: this.values.regAd2,
      businessAdCity: this.values.regAdcity,
      businessAdPostalCode: this.values.regAdpostalCode
    };
    this.validationErrors = this.validationErrors?.filter(error => !keys.includes(error.path as keyof Types.MerchantFieldValues));
  };

  public resetBusinessAddress = (): void => {
    this.values = {
      ...this.values,
      businessAd1: '',
      businessAd2: '',
      businessAdCity: '',
      businessAdPostalCode: ''
    };
  };

  public clearFieldsInformation = (fields: Array<keyof Types.MerchantFieldValues>): void => {
    for (const index in fields) {
      if (this.values[fields[index]]) {
        switch (true) {
          case typeof this.values[fields[index]] === 'string':
            // @ts-ignore
            this.values[fields[index]] = '';

            break;

          case typeof this.values[fields[index]] === 'boolean':
            // @ts-ignore
            this.values[fields[index]] = false;

            break;
        }
      }
    }
  };

  public resetStore = (): void => {
    this.isLoadingMerchantPlans = true;
    this.currency = Currency.Gbp;
    this.country = '';
    this.createdMerchantId = null;
    this.merchantObject = null;
    this.validationErrors = [];
    this.values = MerchantOnboardingStore.setValueDefaults();
  };

  public setValue = (value: Types.MerchantFieldValues[keyof Types.MerchantFieldValues], key: keyof Types.MerchantFieldValues): void => {
    this.values[key as any] = value;
    this.resetErrorForField(key);
  };

  public resetErrorForField = (key: keyof Types.MerchantFieldValues): void => {
    this.validationErrors = this.validationErrors?.filter(error => error.path !== key);
  };

  public setErrors = (errors: TestMessageParams[] = []): void => {
    this.validationErrors = errors;
  };

  public changeLocation = (location: Types.MerchantLocationKey): void => {
    this.values.location = location;
    this.values.plan = '';
    this.setLocaleData(location);
  };

  public resetMerchantList = (): void => {
    this.merchantObject = null;
    this.merchantPlans = [];
    this.isLoadingMerchantPlans = true;
  };

  public setMerchantPlans = (plans: MerchantPlan[]): void => {
    this.merchantPlans = plans;
    this.isLoadingMerchantPlans = false;
  };

  private setLocaleData = (location: Types.MerchantLocationKey = 'uk'): void => {
    // This is trash, I am a bag of trash
    switch (true) {
      case location === 'uk':
        this.currency = Currency.Gbp;
        this.country = 'United Kingdom';

        break;

      case location !== 'uk':
        this.currency = Currency.Usd;
        this.country = 'United States';

        break;
    }
  };

  private areAddressesEqual = (merchant: Merchant): boolean => {
    return !!merchant.address
      && !!merchant.registeredAddress
      && merchant.address.postalCode?.toUpperCase() === merchant.registeredAddress.postalCode?.toUpperCase();
  };
}

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { Box, Text } from 'rebass';

import { DiscountOrderByInput } from 'generated-types.d';

import { NavService } from 'lib';

import { colors } from 'utils/rebass-theme';

import DiscountsService from 'features/settings/components/discounts/services';
import SettingsService from 'features/settings/services';

import DiscountListItem from 'components/discounts/discount-list-item';
import { EntityListItems } from 'components/entity-list/entity-list.styles';
import TableLayoutEntityHeading from 'components/entity-list/table-layout-entity-heading';
import ListControls from 'components/list-controls';
import { ListControlConfig } from 'components/list-controls/list-controls.types';
import LoadMoreButton from 'components/load-more-button';
import NoResultsGeneric from 'components/no-results-generic';
import Notification from 'components/notification';
import WithLoading from 'components/with-loading';

import * as Constants from './discount-list.constants';
import * as Styles from './discount-list.styles';
import * as Types from './discount-list.types';

class DiscountList extends Component<Types.DiscountListProps> {
  private SettingsAnalytics = SettingsService.SettingsAnalytics;

  private hasNoResults = (): boolean => {
    return !this.props.discountsStore!.isLoadingDiscounts && !this.props.discountsStore!.discounts?.edges?.length;
  };

  private isLoadingDiscounts = (): boolean => {
    return this.props.discountsStore!.isLoadingDiscounts && !this.props.discountsStore!.discounts?.edges?.length;
  };

  private onLoadMore = (): void => {
    this.props.discountsStore!.setListLoading(true);
    DiscountsService.paginateDiscounts();
  };

  private onSearch = (value: string): void => {
    this.props.discountsStore!.setSearchValue(value);
    this.SettingsAnalytics.onMerchantSearch(value);
    DiscountsService.fetchDiscounts();
  };

  private onClearSearch = (): void => {
    this.props.discountsStore!.setSearchValue('');
    this.onSearch('');
  };

  private onToggleActiveDiscounts = (): void => {
    this.props.discountsStore!.resetDiscountList();
    this.props.discountsStore!.toggleActiveDiscounts();
    DiscountsService.fetchDiscounts();
  };

  private onSort = (sortOrder: DiscountOrderByInput): void => {
    this.props.discountsStore!.resetDiscountList();
    this.props.discountsStore!.setDiscountSortValue(sortOrder);
    DiscountsService.fetchDiscounts();
  };

  private onResetControls = (): void => {
    this.props.discountsStore!.resetDiscountList();
    this.props.discountsStore!.setSearchValue('');
    this.props.discountsStore!.setDiscountSortValue();
    DiscountsService.fetchDiscounts();
  };

  private buildDiscountControls = (): ListControlConfig => [
    {
      controlOptionType: 'searchField',
      name: 'search',
      alignment: 'left',
      placeholder: 'Search discounts',
      onSearch: this.onSearch,
      onChange: this.props.discountsStore!.setSearchValue,
      value: this.props.discountsStore!.searchValue,
      onClear: this.onClearSearch,
      isLoading: false
    },
    {
      controlOptionType: 'toggle',
      name: 'toggle',
      label: 'Show me inactive discounts',
      alignment: 'left',
      switchBgActive: colors.validationText,
      switchBgInactive: colors.errorText,
      controlValue: true,
      isChecked: this.props.discountsStore!.isShowingInactiveDiscounts,
      onChange: this.onToggleActiveDiscounts
    },
    {
      controlOptionType: 'dropdown',
      name: 'sort',
      alignment: 'right',
      id: 'discount-list-sort',
      selected: this.props.discountsStore!.sortValue,
      options: Constants.LIST_SORT_OPTIONS,
      optionTitleField: 'title',
      optionValueField: 'key',
      onChange: this.onSort,
      wrapperStyles: css`
        display: flex;
        justify-content: end;
      `
    }
  ];

  private renderNoResultsHeading = (): string => {
    const value = this.props.discountsStore!.searchValue;
    const searchCopy = value?.length ? ` for "${value}"` : '';

    return `No discounts found${searchCopy}`;
  };

  private renderNoResultsCopy = (): ReactNode => {
    if (this.props.discountsStore!.searchValue?.length) {
      return (
        <>
          <span>Try refining your search criteria to see more results, or</span>
          {' '}
          <Text
            as="button"
            fontWeight="bold"
            onClick={this.onResetControls}
            css={Styles.LoadMoreLink}
          >
            clear your filters
          </Text>
        </>
      );
    }

    return (
      <>
        <span>{`You don't have any discount codes set up yet. Let's go`}</span>{' '}
        <Link to={NavService.discountCreateEditUrl('create')}>
          <Text css={Styles.LoadMoreLink}>
            make one now!
          </Text>
        </Link>
      </>
    );
  };

  private renderNotification = (): ReactNode => {
    if (!this.props.discountsStore!.listNotification) return null;

    return (
      <Box my="20px">
        <Notification
          copy={this.props.discountsStore!.listNotification.copy}
          type={this.props.discountsStore!.listNotification.type}
          hasClose={true}
          textAlign="left"
          onClose={(): void => this.props.discountsStore!.setNotification()}
        />
      </Box>
    );
  };

  private renderList = (): ReactNode => {
    return (
      <Box
        css={css`
          overflow: auto;
          padding: 0 10px;
          margin: 0 -10px;
        `}
      >
        <Box
          css={css`
            min-width: 970px;
            padding-right: 10px;
          `}
        >
          <TableLayoutEntityHeading headers={Constants.LIST_HEADING_CONFIG} />
          <EntityListItems removeOverflow={true}>
            {this.renderItems()}
          </EntityListItems>
          <LoadMoreButton
            shouldDisplay={!!this.props.discountsStore!.discounts?.pageInfo?.hasNextPage}
            onLoadMore={this.onLoadMore}
            isLoading={this.props.discountsStore!.isLoadingDiscounts}
          />
        </Box>
      </Box>
    );
  };

  private renderItems = (): ReactNode => {
    if (!this.props.discountsStore!.discounts?.edges?.length) return null;

    return this.props.discountsStore!.discounts.edges.map((edge: any): React.ReactNode => {
      if (!edge?.node) return null;

      return (
        <DiscountListItem
          key={edge.node.id}
          item={edge.node}
        />
      );
    });
  };

  render(): ReactNode {
    return (
      <Box m="30px 0">
        <ListControls
          config={this.buildDiscountControls()}
          containerStyles={Styles.Wrapper}
        />
        <WithLoading
          hasNoResults={this.hasNoResults()}
          isLoading={this.isLoadingDiscounts()}
          renderNoResults={(): ReactNode => (
            <Box mt="40px">
              <NoResultsGeneric
                icon="leaf-no-results"
                heading={this.renderNoResultsHeading()}
                copy={this.renderNoResultsCopy()}
              />
            </Box>
          )}
        >
          {this.renderNotification()}
          {this.renderList()}
        </WithLoading>
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantHolidaysStore: stores.merchantHolidaysStore,
  merchantStore: stores.merchantStore,
  discountsStore: stores.discountsStore
}))(observer(DiscountList));

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import { OrderStatusSlug } from 'features/orders/orders.types';

import { IconType } from 'components/icon/icon.types';
import AddOnMeta from 'components/item-meta/add-on-meta';
import CancelledMeta from 'components/item-meta/cancelled-meta';
import ChannelMeta from 'components/item-meta/channel-meta';
import DeliveryTimeMeta from 'components/item-meta/delivery-time-meta';
import FailedDeliveryMeta from 'components/item-meta/failed-delivery-meta';
import LocationMeta from 'components/item-meta/location-meta';
import MerchantTitleMeta from 'components/item-meta/merchant-title-meta';
import ProductTitleMeta from 'components/item-meta/product-title-meta';
import ReceiptMeta from 'components/item-meta/receipt-meta';
import RecipientMeta from 'components/item-meta/recipient-meta';

export type OrderMetaColumn =
  | 'merchantTitle'
  | 'orderItems'
  | 'addOns'
  | 'location'
  | 'deliveryTime'
  | 'cancelled'
  | 'failedDelivery'
  | 'receipt'
  | 'channel'
  | 'recipient';

/**
 * The configuration for the available items available on an order item. Can be customised
 * on an order status basis, in any order.
 */
export const orderMetaItem: { [key in OrderMetaColumn]: any } = {
  merchantTitle: {
    component: MerchantTitleMeta
  },
  channel: {
    component: ChannelMeta,
    dataKey: 'channel'
  },
  orderItems: {
    component: ProductTitleMeta,
    dataKey: 'orderItems'
  },
  addOns: {
    component: AddOnMeta,
    dataKey: 'orderItems'
  },
  location: {
    component: LocationMeta,
    dataKey: 'shippingAddress'
  },
  recipient: {
    component: RecipientMeta
  },
  deliveryTime: {
    component: DeliveryTimeMeta
  },
  cancelled: {
    component: CancelledMeta
  },
  failedDelivery: {
    component: FailedDeliveryMeta
  },
  receipt: {
    component: ReceiptMeta
  }
};

export interface OrderStatusConfigType {
  iconName: IconType;
  color: ColourOption;
  headingBg: ColourOption;
  textColor?: ColourOption;
  statusActions: OrderStatusSlug[];
  metaItem: OrderMetaColumn[];
}

export const allStatuses = [
  OrderStatusSlug.Open,
  OrderStatusSlug.Prepared,
  OrderStatusSlug.OnTheWay,
  OrderStatusSlug.Delivered
];

export const statusConfig: { [key in OrderStatusSlug]: OrderStatusConfigType } = {
  open: {
    iconName: 'pie-empty',
    color: ColourOption.middleGrey,
    headingBg: ColourOption.lightGrey,
    statusActions: [OrderStatusSlug.Prepared],
    metaItem: [
      'merchantTitle',
      'orderItems',
      'addOns',
      'channel',
      'location',
      'recipient',
      'deliveryTime'
    ]
  },
  prepared: {
    iconName: 'pie-one-third',
    color: ColourOption.middleGrey,
    headingBg: ColourOption.lightGrey,
    statusActions: [OrderStatusSlug.OnTheWay],
    metaItem: [
      'merchantTitle',
      'orderItems',
      'addOns',
      'channel',
      'location',
      'recipient',
      'deliveryTime'
    ]
  },
  onTheWay: {
    iconName: 'pie-two-thirds',
    color: ColourOption.middleGrey,
    headingBg: ColourOption.lightGrey,
    statusActions: [
      OrderStatusSlug.Delivered,
      OrderStatusSlug.Cancelled,
      OrderStatusSlug.FailedDelivery
    ],
    metaItem: [
      'merchantTitle',
      'orderItems',
      'addOns',
      'channel',
      'location',
      'recipient',
      'deliveryTime'
    ]
  },
  delivered: {
    iconName: 'tick-circle',
    color: ColourOption.emeral,
    headingBg: ColourOption.validationBg,
    textColor: ColourOption.emeral,
    statusActions: [
      OrderStatusSlug.OnTheWay,
      OrderStatusSlug.Cancelled,
      OrderStatusSlug.FailedDelivery
    ],
    metaItem: [
      'merchantTitle',
      'orderItems',
      'addOns',
      'channel',
      'location',
      'recipient',
      'deliveryTime'
    ]
  },
  failedDelivery: {
    iconName: 'cross-circle',
    color: ColourOption.errorText,
    headingBg: ColourOption.errorBg,
    textColor: ColourOption.errorText,
    statusActions: [
      OrderStatusSlug.Cancelled,
      OrderStatusSlug.Delivered,
      OrderStatusSlug.Prepared,
      OrderStatusSlug.Open
    ],
    metaItem: [
      'merchantTitle',
      'orderItems',
      'addOns',
      'channel',
      'location',
      'recipient',
      'failedDelivery'
    ]
  },
  cancelled: {
    iconName: 'cross-circle',
    color: ColourOption.errorText,
    headingBg: ColourOption.errorBg,
    textColor: ColourOption.errorText,
    statusActions: [
      OrderStatusSlug.Delivered,
      OrderStatusSlug.FailedDelivery
    ],
    metaItem: [
      'merchantTitle',
      'orderItems',
      'addOns',
      'channel',
      'location',
      'recipient',
      'cancelled'
    ]
  }
};

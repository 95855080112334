import { ApolloQueryResult } from '@apollo/client';

import {
  ProductType,
  ProductCategory,
  ProductWhereInput,
  ProductOrderByInput,
  ProductConnection,
  ProductEdge,
  Product,
  MerchantStage,
  ProductUpdateInput,
  ProductWhereUniqueInput,
  PlanFeature,
  ProductVariation,
  CollectionSellerProductConfig,
  CollectionSellerProductVariationConfig,
  Merchant
} from 'generated-types.d';

import * as ProductMutations from '../../graphql/mutation/product';
import * as ProductQueries from '../../graphql/queries/product';
import Auth from '../auth/auth.service';
import { GraphQL } from '../client/client.service';
import MerchantService from '../merchant/merchant.service';
import PermissionsService from '../permissions/permissions.service';

export default class ProductService {
  public static isTemplateProduct = (product: Product): boolean => {
    return !!product.merchant?.plan?.features?.includes(PlanFeature.CollectionManager);
  };

  public static canActivateProduct = (
    productLimit: number | null,
    productCount: number,
    merchantStage: MerchantStage
  ): boolean => {
    switch (true) {
      case merchantStage === MerchantStage.Churn:

      case merchantStage === MerchantStage.Dormant:

      case merchantStage === MerchantStage.Unassigned:
        return false;

      case productLimit === null:
        return true;

      case productCount < productLimit!:
        return true;

      default:
        return false;
    }
  };

  public static collectionSellerVariationConfigForVariation = (
    variation: ProductVariation,
    productConfig: CollectionSellerProductConfig | undefined
  ): CollectionSellerProductVariationConfig | undefined => {
    return productConfig?.productVariationConfigs?.find(config => config.sourceProductVariation.id === variation.id);
  };

  public static isTemplateProductNotEditable = (product: Product): boolean => {
    return ProductService.isTemplateProduct(product) && PermissionsService.isInternalRole() && !Auth.getMerchantIdCookie();
  };

  public static calculateProductVariationStock = (
    product: Product,
    variation: ProductVariation,
    isCollectionManager: boolean
  ): number => {
    const isTemplateProduct = ProductService.isTemplateProduct(product);

    switch (true) {
      case !isTemplateProduct || isCollectionManager:
        return variation.stock || 0;

      case ProductService.isTemplateProductNotEditable(product):
        return 0;

      case isTemplateProduct && !isCollectionManager:
        const variationConfig = ProductService.collectionSellerVariationConfigForVariation(variation, product.collectionSellerProductConfigs?.[0]);

        if (variationConfig) {
          return variationConfig.stock || 0;
        }

        return 0;

      default:
        return 0;
    }
  };

  public static isProductActive = (
    product: Product,
    merchant?: Merchant
  ): boolean => {
    const isCollectionManagerMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionManager, merchant || null);

    switch (true) {
      case ProductService.isTemplateProduct(product) && !isCollectionManagerMerchant:
        return !!product.collectionSellerProductConfigs?.[0]?.active;

      default:
        return product.active;
    }
  };

  public static fetchProduct = async (
    productID: string,
    merchantID: string
  ): Promise<Product> => {
    try {
      const result = await GraphQL.query<{ product: Product }>(
        ProductQueries.SINGLE_PRODUCT_QUERY,
        {
          id: productID,
          merchantId: merchantID
        }
      );

      return result.data.product;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public static fetchProductTypes = async (): Promise<ProductType[]> => {
    try {
      const result = await GraphQL.query<{ productTypes: ProductType[] }>(ProductQueries.PRODUCT_TYPES_QUERY, { active: true });

      return result.data.productTypes;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public static fetchProductCategories = async (): Promise<ProductCategory[]> => {
    try {
      const result = await GraphQL.query<{ productCategories: ProductCategory[] }>(ProductQueries.PRODUCT_CATEGORIES_QUERY, { active: true });

      return result.data.productCategories;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public static fetchActiveProductCountForMerchant = async (merchantId: string): Promise<number> => {
    try {
      const result = await GraphQL.query<{ productsConnection: ProductConnection }>(ProductQueries.TOTAL_ACTIVE_MERCHANT_PRODUCTS, { merchantId }, 'no-cache');

      return result.data.productsConnection.aggregate.count;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public static fetchProductCount = async (inputWhere: ProductWhereInput = {}): Promise<number> => {
    const where: ProductWhereInput = {
      ...inputWhere,
      deletedAt: null
    };

    const result: ApolloQueryResult<{ productsConnection: ProductConnection }> = await GraphQL
      .query(ProductQueries.PRODUCT_COUNT_QUERY, { where });

    return result.data.productsConnection.aggregate.count;
  };

  public static fetchAllProducts = async (
    inputWhere: ProductWhereInput = {},
    orderBy: ProductOrderByInput = ProductOrderByInput.TitleAsc,
    first: number = 100
  ): Promise<ProductEdge[]> => {
    let skip = 0;
    let hasNextPage = true;
    let products: ProductEdge[] = [];
    const where: ProductWhereInput = {
      ...inputWhere,
      deletedAt: null
    };

    while (hasNextPage) {
      try {
        const result: ApolloQueryResult<{ productsConnection: ProductConnection }> = await GraphQL
          .query(ProductQueries.PRODUCTS_CONNECTION_QUERY(where?.merchant?.id || undefined), { where, orderBy, first, skip });

        skip = skip + first;
        hasNextPage = result.data.productsConnection.pageInfo.hasNextPage;

        products = [
          ...products,
          ...result.data.productsConnection.edges as ProductEdge[]
        ];
      } catch (error) {
        hasNextPage = false;

        return Promise.reject(error);
      }
    }

    return products;
  };

  public static editProduct = async (where: ProductWhereUniqueInput, data: ProductUpdateInput): Promise<Product> => {
    try {
      const result: ApolloQueryResult<{ editProduct: Product }> = await GraphQL
        .query(ProductMutations.EDIT_PRODUCT_MUTATION, { where, data });

      return result.data.editProduct;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public static deleteProduct = async (where: ProductWhereUniqueInput): Promise<string> => {
    try {
      const result: ApolloQueryResult<{ id: string }> = await GraphQL
        .query(ProductMutations.DELETE_PRODUCT_MUTATION, { where });

      return result.data.id;
    } catch (error) {
      throw new Error(error);
    }
  };
}

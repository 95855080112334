import React, { Component } from 'react';

import { css } from '@emotion/react';
import { Heading, Box, Flex } from 'rebass';

import { Container } from 'utils/css-mixins';
import { textStyles } from 'utils/rebass-theme';

import * as Styles from './page-heading.styles';
import * as Types from './page-heading.types';

export default class PageHeading extends Component<Types.PageHeadingProps> {
  static defaultProps: Partial<Types.PageHeadingProps> = {
    subtitleText: null,
    hasSpacing: true
  };

  render(): React.ReactNode {
    return(
      <div>
        <Container
          css={css`
            ${!this.props.hasSpacing ? 'padding: 0;' : ''}
          `}
        >
          <Styles.PageHeadingWrapper
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            hasSpacing={this.props.hasSpacing}
          >
            <Flex alignItems="center">
              <Heading
                as="h1"
                css={textStyles.headline}
              >
                {this.props.titleText}
              </Heading>
            </Flex>
            <Box>
              {this.props.children}
            </Box>
          </Styles.PageHeadingWrapper>
        </Container>
      </div>
    );
  }
}

import {
  FC,
  Fragment,
  memo,
  ReactNode
} from 'react';

import { css } from '@emotion/react';

import { ListFieldConfig, SearchListFieldConfig } from 'features/lists/lists.types';

import {
  ContentHeading,
  TitleHeading,
  TableHeaderWrapper
} from './list-headings.styles';
import {
  ListHeadingsProps
} from './list-headings.types';

export const ListHeadings: FC<ListHeadingsProps> = memo(({
  listType,
  categoryScope,
  config,
  wrapperStyles,
  cellStyles
}) => {
  const fieldConfig = config[listType];

  if (!fieldConfig) return null;

  const shouldDisplay = (field: ListFieldConfig | SearchListFieldConfig): boolean => {
    return !field?.supportedTypes?.length || (!!categoryScope && !!field.supportedTypes?.includes?.(categoryScope!));
  };

  return (
    <TableHeaderWrapper css={wrapperStyles}>
      <TitleHeading>
        {' '}
      </TitleHeading>
      <Fragment>
        {fieldConfig.fields.map((field: ListFieldConfig): ReactNode => {
          if (!shouldDisplay(field)) return null;

          return (
            <ContentHeading
              key={field.key}
              customStyles={css`
                  ${cellStyles}
                  ${field.customStyles!}
                `}
            >
              {field.heading}
            </ContentHeading>
          );
        })}
      </Fragment>
    </TableHeaderWrapper>
  );
});

import { ApolloQueryResult } from '@apollo/client';
import store from 'stores';

import { AddonCategory, Addon, AddonOrderByInput, Channel } from 'generated-types.d';

import AddOnsStore from 'stores/add-ons-store/add-ons-store';
import {
  AddOnsListLayout
} from 'stores/add-ons-store/add-ons-store.types';

import { AddOnQuickEditData } from 'features/add-ons/add-ons.types';

import AddOnsAPIService from '../add-ons-api/add-ons-api.service';

import AddOnsListService from './add-ons-list.service';

export default class AddOnListApolloService implements AddOnsListService {
  private addOnsStore = store.addOnsStore as AddOnsStore;

  private userStore = store.userStore;

  public updateLayout = (layoutType: AddOnsListLayout): void => {
    this.addOnsStore.setLayout(layoutType);
  };

  public toggleActiveStatus = (addOnId: string, isActive: boolean): Promise<Addon> => {
    return AddOnsAPIService.toggleActiveStatus(addOnId, isActive)
      .then((result): Promise<Addon> => {
        this.fetchAllAddOns();

        return Promise.resolve(result);
      });
  };

  public paginateAddOns = (): void => {
    this.addOnsStore.setLoading();

    AddOnsAPIService.paginateAddOns(this.buildVariables())
      .then(({ data }: ApolloQueryResult<any>) => {
        this.addOnsStore.appendAddOns(data.allAddons);
      }).catch(() => {
        store.toasterStore.popErrorToast('add-ons', 'get');
      });
  };

  public fetchAddOnsCategories = (): void => {
    AddOnsAPIService.fetchAddOnsCategories()
      .then((addOnsCategories: AddonCategory[]) => {
        this.addOnsStore.setAddOnsCategories(addOnsCategories);
      }).catch(() => {
        store.toasterStore.popErrorToast('categories', 'get');
      });
  };

  public quickEditAddOn = async (variables: AddOnQuickEditData, addOnId: string): Promise<any> => {
    try {
      await AddOnsAPIService.quickEditAddOn(variables, addOnId);
      await this.fetchAllAddOns();
    } catch (error) {
      store.toasterStore.popErrorToast('add-on', 'change');
    }
  };

  public loadMoreAddOns = (): void => {
    this.addOnsStore.onLoadMore();
    this.paginateAddOns();
  };

  public updateOrdering = (orderType: AddonOrderByInput): void => {
    this.addOnsStore.setAddOnsOrdering(orderType);
    this.fetchAllAddOns();
  };

  public filterByCategory = (filter: any): void => {
    this.addOnsStore.setCategoryFilter(filter);

    this.fetchAllAddOns();
  };

  public filterByChannel = (filter: Channel): void => {
    this.addOnsStore.setChannelFilter(filter);
    this.fetchAllAddOns();
  };

  public toggleInactiveAddOns = (value: boolean = !this.addOnsStore.showInactiveAddOns): void => {
    this.addOnsStore.toggleInactiveAddOns(value);
    this.fetchAllAddOns();
  };

  public init = (): void => {
    this.fetchAllAddOns();
    this.addOnsStore.setLoading();
    this.fetchAddOnsCategories();
  };

  public fetchAllAddOns = async (): Promise<any> => {
    AddOnsAPIService.fetchAddOns(this.buildVariables())
      .then(({ data }: ApolloQueryResult<any>) => {
        this.addOnsStore.updateAddOns(data);
      }).catch(() => {
        store.toasterStore.popErrorToast('add-ons', 'get');
      });
  };

  private buildVariables = (): Record<string, any> => ({
    selectedChannels: this.addOnsStore.channelFilter,
    selectedCategory: this.addOnsStore.categoryFilter,
    orderBy: this.addOnsStore.orderBy,
    showActiveOnly: !this.addOnsStore.showInactiveAddOns,
    first: this.addOnsStore.loadIncrement,
    merchantId: this.userStore.merchantId,
    skip: this.addOnsStore.count
  });
}

import React, { Component, ReactNode } from 'react';

import { Order } from 'generated-types';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { HTMLService, TimeService, PermissionsService } from 'lib';

import { textStyles, colors } from 'utils/rebass-theme';

import OrderNoteHelpers from 'features/orders/helpers/order-note-helpers';
import { ORDER_PERMISSIONS } from 'features/orders/orders.constants';
import { OrderEditService } from 'features/orders/services';

import Button from 'components/button';
import FxTextArea from 'components/field-text-area';
import StyledLink from 'components/styled-link/styled-link';

import { Subheading } from '../../order-detail.styles';

import * as Styles from './order-notes.styles';
import * as Types from './order-notes.types';

class OrderDetailNotes extends Component<Types.OrderDetailNotesProps> {
  state: Types.OrderDetailNotesState = {
    floristNote: undefined,
    floristNoteEditing: false,
    floristNoteEdited: '',
    floomNote: undefined,
    floomNoteEditing: false,
    floomNoteEdited: '',
    isLoading: false
  };

  config: Types.OrderNodeConfig = {
    florist: {
      title: PermissionsService.isInternalRole() ? 'Florist\'s Note' : 'Your Note',
      restrictedRoles: ORDER_PERMISSIONS.floristNote.restrictedRoles,
      canEdit: !PermissionsService.isInternalRole()
    },
    floom: {
      title: 'Floom note',
      restrictedRoles: ORDER_PERMISSIONS.floomNote.restrictedRoles,
      canEdit: PermissionsService.isInternalRole()
    }
  };

  componentDidMount = (): void => {
    this.setNoteState();
  };

  setNoteState = (): void => {
    this.setState({
      floristNoteEditing: false,
      floomNoteEditing: false,
      floristNote: OrderNoteHelpers.findFloristNote(this.props.notes),
      floomNote: OrderNoteHelpers.findFloomNote(this.props.notes)
    });
  };

  getNoteField = (type: Types.NoteType[keyof Types.NoteType], field = 'content'): string => this.state[`${type}Note`] ? this.state[`${type}Note`][field] : '';

  generateTimestamp = (type: Types.NoteType[keyof Types.NoteType]): string => {
    const noteField = this.getNoteField(type, 'updatedAt');

    return noteField ? TimeService.longFormatWithoutDate(noteField) : '';
  };

  changeNote = (value: string, type: Types.NoteType[keyof Types.NoteType]): void => {
    this.setState({
      [`${type}NoteEdited`]: value
    });
  };

  editNote = (type: Types.NoteType[keyof Types.NoteType]): any => {
    this.setState({
      [`${type}NoteEditing`]: true,
      [`${type}NoteEdited`]: this.removeBreaks(this.getNoteField(type))
    }, () => this.props.onEditNote());
  };

  discardNote = (type: Types.NoteType[keyof Types.NoteType]): any => {
    this.setState({
      [`${type}NoteEditing`]: false,
      [`${type}NoteEdited`]: undefined
    });
  };

  updateNoteOnSave = (order: Order): void => {
    this.props.ordersStore!.setOrder(order);
    this.setNoteState();
  };

  sanitizeNoteForSave = (type: Types.NoteType[keyof Types.NoteType]): string => HTMLService.newLineToBreak(this.state[`${type}NoteEdited`]);

  saveNote = async (type: Types.NoteType[keyof Types.NoteType]): Promise<void> => {
    this.setState({ isLoading: true });

    if (this.state[`${type}Note`]) {
      await OrderEditService.updateOrderNote(
        this.sanitizeNoteForSave(type),
        type === Types.NoteType.Floom,
        {
          orderNo: this.props.ordersStore!.currentOrder.orderNo,
          itemId: this.state[`${type}Note`].id
        }
      ).then(this.updateNoteOnSave);
    } else {
      await OrderEditService.createOrderNote(
        this.sanitizeNoteForSave(type),
        type === Types.NoteType.Floom,
        {
          orderNo: this.props.ordersStore!.currentOrder.orderNo
        }
      ).then(this.updateNoteOnSave);
    }

    this.setState({ isLoading: false });
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>, type: Types.NoteType[keyof Types.NoteType]): void => {
    if (event.keyCode === 13 && event.metaKey) {
      this.saveNote(type);
    }
  };

  removeBreaks = (note: string): string => HTMLService
    .sanitize(note)
    .replace(/\s?(<br\s?\/?>)\s?/g, '\r\n');

  render(): ReactNode {
    return (
      <>
        <Flex
          flexWrap="wrap"
          m="0 -10px"
        >
          {
            Object.keys(this.config).map((type: string) => (
              <Box
                key={type}
                width={[1, '50%']}
                mb={['20px', '']}
                p="0 10px"
              >
                <Subheading>
                  {this.config[type].title}
                  {this.getNoteField(type, 'updatedAt') && (
                    <Styles.Timestamp>
                      {' - '}
                      {this.generateTimestamp(type)}
                    </Styles.Timestamp>
                  )}
                  <br />
                  {type === Types.NoteType.Florist && (
                    <i style={{ fontSize: '11px', fontWeight: 100 }}>For internal use only (not seen by Floom)</i>
                  )}
                </Subheading>
                {this.state[`${type}NoteEditing`] ? (
                  <>
                    <FxTextArea
                      value={this.state[`${type}NoteEdited`]}
                      placeholder="Copy"
                      rows={3}
                      autoFocus={true}
                      onKeyDown={(event): void => {
                        this.onKeyDown(event, type);
                      }}
                      onChange={(value): void => this.changeNote(value, type)}
                    />
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      mt="10px"
                    >
                      <Box
                        as="button"
                        ml="8px"
                        onClick={(): void => this.discardNote(type)}
                      >
                        <StyledLink color={colors.shade40}>
                          Discard
                        </StyledLink>
                      </Box>
                      <button
                        onClick={(): void => {
                          this.saveNote(type);
                        }}
                        disabled={this.state.isLoading}
                      >
                        <Button
                          copy="Save note"
                          isLoading={this.state.isLoading}
                        />
                      </button>
                    </Flex>
                  </>
                )
                  : (
                    <>
                      <Box
                        css={textStyles.body}
                        dangerouslySetInnerHTML={{
                          __html: HTMLService.sanitize(HTMLService.newLineToBreak(this.getNoteField(type) || '-'))
                        }}
                      />
                      {this.config[type].canEdit && (
                        <Box mt="5px">
                          <button onClick={(): void => this.editNote(type)}>
                            <StyledLink>
                              {this.getNoteField(type) ? 'Edit' : 'Add'}
                            </StyledLink>
                          </button>
                        </Box>
                      )}
                    </>
                  )}
              </Box>
            ))
          }
        </Flex>
      </>
    );
  }
}

export default inject('ordersStore')(observer(OrderDetailNotes));

import React from 'react';

import Divider from '../divider';
import NavActionLink from '../nav-action-link';
import NavLink from '../nav-link';
import { NavLinkProps } from '../nav-link/nav-link.types';

export type NavItemType = 'ITEM' | 'ACTIONITEM' | 'DIVIDER';

export type NAV_COMPONENT_TYPE = {
  [key in NavItemType]: React.ReactNode;
};

export const NAV_COMPONENT: React.ReactNode = {
  ITEM: (props: NavLinkProps): JSX.Element => (
    <NavLink
      key={props.route.id}
      {...props}
    />
  ),
  ACTIONITEM: (props: NavLinkProps): JSX.Element => (
    <NavActionLink
      key={props.route.id}
      {...props}
    />
  ),
  DIVIDER: ({ route }: NavLinkProps): JSX.Element => (
    <Divider
      key={route.name}
      name={route.name}
      isFeatureFlagDisabled={route.isFeatureFlagDisabled}
    />
  )
};

import { FC, Fragment, useCallback, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box } from 'rebass';

import { ListItem } from 'generated-types.d';

import { NavService, PermissionsService } from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { colors } from 'utils/rebass-theme';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import { ListCardWrapper, ListScrollWrapper } from 'features/lists/lists.styles';
import { DateRangeModalData } from 'features/modal-dialogue/components/modals/date-range-modal/date-range-modal.types';

import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import { AdditionalItems } from '../additional-items/additional-items';
import { CheckoutListFooter, CheckoutListTableGroup } from '../checkout-list-footer';
import { CheckoutListItem } from '../checkout-list-item/checkout-list-item';

import { getTotalQuantity } from './checkout-list.helpers';
import {
  ItemsWrapper,
  ListCard,
  ListCardContent,
  ListCardHeader,
  ListHeading
} from './checkout-list.styles';
import { CheckoutListProps } from './checkout-list.types';

const CheckoutListView: FC<CheckoutListProps> = ({
  promotedListsStore,
  merchantStore,
  modalStore,
  toasterStore,
  selectedWholesalePreOrderStore,
  footerRef
}) => {
  const [isPlacingPreOrder, setIsPlacingPreOrder] = useState(false);
  const [hasPlacedPreOrder, setHasPlacedPreOrder] = useState(false);
  const [isInfoBoxClosed, setIsInfoBoxClosed] = useState(false);
  const { flags } = useFeatureFlags();
  const additionItemInputRef = useRef<HTMLInputElement>(null);

  const deliveryDateConfig = useCallback((): {
    date: string;
    fromDate: string;
    toDate: string;
    disabledDays: any;
    firstAvailableDate: string | undefined;
  } => {
    const disabledDates = PermissionsService.isInternalRole() ? [] : promotedListsStore!.deliveryDates
      .filter(date => !date.canDeliver)
      .map(date => new Date(date.dateString!));

    const isDeliveryDateDisabled = (): boolean => {
      const isDisabledDate = disabledDates.some(date => moment(promotedListsStore!.selectedDeliveryDate).isSame(moment(date), 'day'));
      const isBeforeFromDate = moment(promotedListsStore!.selectedDeliveryDate).isBefore(fromDate, 'day');
      const isAfterToDate = moment(promotedListsStore!.selectedDeliveryDate).isAfter(
        toDate,
        'day'
      );

      return isDisabledDate || isBeforeFromDate || isAfterToDate;
    };

    const firstAvailableDate = promotedListsStore!.deliveryDates.find?.(date => date.canDeliver);
    const fromDate = promotedListsStore!.deliveryDates?.[0]?.dateString || '';
    const toDate =
      promotedListsStore!.deliveryDates?.[promotedListsStore!.deliveryDates.length - 1]
        ?.dateString || '';

    const disabledDays = [
      ...disabledDates,
      {
        before: new Date(fromDate!),
        after: new Date(toDate!)
      }
    ];

    return {
      date: isDeliveryDateDisabled() ? '' : promotedListsStore!.selectedDeliveryDate,
      fromDate: fromDate || '',
      toDate: toDate || '',
      disabledDays: disabledDays,
      firstAvailableDate: PermissionsService.isInternalRole() ? fromDate : firstAvailableDate?.dateString!
    };
  }, [promotedListsStore!.selectedDeliveryDate, promotedListsStore!.deliveryDates]);

  const list = promotedListsStore?.list;
  const listItems = sortBy(list?.items, ['sku', 'minimumStemLength']);

  if (!list || !listItems) {
    return null;
  }

  const getErrorMessage = (error: any): string => {
    if (!error.graphQLErrors) {
      return `Oops, we're having trouble creating your pre-order at the moment!`;
    }

    const graphQLError = error.graphQLErrors?.[0];

    switch (graphQLError.code) {
      case 99046113996: {
        return 'Oops, you already have a pre-order for this delivery date!';
      }

      default: {
        return `Oops, we're having trouble creating your pre-order at the moment!`;
      }
    }
  };

  const placePreOrder = async (): Promise<void> => {
    try {
      setIsPlacingPreOrder(true);

      const result = await promotedListsStore!.createWholesalePreOrder(merchantStore?.merchant?.id);

      selectedWholesalePreOrderStore!.toggleIsNewPreOrder(true);
      setHasPlacedPreOrder(true);

      setTimeout(() => {
        NavService.wholesalePreOrder(result.id);
      }, 1000);
    } catch (error) {
      toasterStore?.popToast(getErrorMessage(error), ToastType.Error);

      setIsPlacingPreOrder(false);
    } finally {
      setIsPlacingPreOrder(false);
    }
  };

  const openDeliveryDateModal = async (): Promise<void> => {
    if (!promotedListsStore!.deliveryDates.length) {
      toasterStore!.popErrorToast('delivery dates', 'get');

      return;
    }

    const config = deliveryDateConfig();

    modalStore!.triggerModal<DateRangeModalData>({
      modalType: 'dateRange',
      data: {
        title: 'Set delivery date',
        errorCopy: 'setting delivery date',
        formOption: 'single_day',
        fromCopy: 'Date',
        timeoutCopy: '',
        shouldDisplayMerchantSelection: false,
        fromDate: config.date,
        disabledDays: config.disabledDays,
        shouldAutofocus: true,
        initialMerchantId: merchantStore?.merchant?.id,
        initialMonth: config.firstAvailableDate,
        onConfirm: async (from: string): Promise<void> => {
          if (moment(from).isValid()) {
            promotedListsStore!.setDeliveryDate(from);
          }
        }
      }
    });
  };

  const totalPrice = promotedListsStore!.itemsPriceTotal;
  const salesTax = promotedListsStore!.salesTax;
  const grandTotal = promotedListsStore!.grandTotal;

  const group: CheckoutListTableGroup = {
    id: 'valid',
    hasTotalSummary: true,
    basketItems: promotedListsStore!.basketItems,
    totalShipping: promotedListsStore!.shippingTotal,
    totalPrice: {
      min: totalPrice,
      max: totalPrice
    },
    salesTax: {
      min: salesTax,
      max: salesTax
    },
    totalQuantity: getTotalQuantity(promotedListsStore!.basketItems),
    grandTotal: {
      min: grandTotal,
      max: grandTotal
    }
  };

  const renderHeading = (): string => {
    const totalItems = list.items?.length || 0;
    const title = list?.title || '';

    if (totalItems > 0) {
      return `${title} (${totalItems} items)`;
    }

    return title;
  };

  const handleScroll = (): void => {
    additionItemInputRef?.current?.focus();
  };

  const closeInfoBox = (): void => {
    setIsInfoBoxClosed(true);
  };

  return (
    <Fragment
      css={css`
        -webkit-overflow-scrolling : touch !important; /* Fix iOS Safari input focus scroll with keyboard pop-up issue */
      `}
    >
      <Box my={20}>
        <ListHeading>{renderHeading()}</ListHeading>
      </Box>
      <ListCardWrapper padding='8px 20px'>
        <ItemsWrapper>
          <ListScrollWrapper>
            {listItems.map?.((item: ListItem) => {
              return (
                <CheckoutListItem
                  key={item.id}
                  listItem={item}
                />
              );
            })}
          </ListScrollWrapper>
        </ItemsWrapper>
      </ListCardWrapper>
      {flags.promotedListsAdditionalItems?.isActive && (
        <ListCard color={colors.purple}>
          <ListCardHeader
            onClick={handleScroll}
            isSticky={true}
          >
            Request additional items
          </ListCardHeader>
          <ListCardContent>
            <AdditionalItems inputRef={additionItemInputRef} />
            {(!!promotedListsStore!.customItems.length && !isInfoBoxClosed) && (
              <Notification
                copy='We will contact you with a quote for these items shortly.'
                type={NotificationType.Info}
                styles={css` 
                  padding: 8px 16px;
                  margin-top: 17px;
                 `}
                onClose={closeInfoBox}
                customTheme={{
                  color: colors.floomMidnightBlue,
                  background: '#FDF4E4'
                }}
              />
            )}
          </ListCardContent>
        </ListCard>
      )}

      <ListCardWrapper
        padding='0px 20px 20px'
        ref={footerRef}
      >
        <CheckoutListFooter
          group={group}
          onSelectDeliveryDate={openDeliveryDateModal}
          onPlacePreOrder={placePreOrder}
          isPlacingPreOrder={isPlacingPreOrder}
          hasPlacedPreOrder={hasPlacedPreOrder}
          hideTopBorder={true}
        />
      </ListCardWrapper>
    </Fragment>
  );
};

export const CheckoutList = inject((stores: FxStores): InjectedFxStores => ({
  promotedListsStore: stores.promotedListsStore,
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore,
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
}))(observer(CheckoutListView));

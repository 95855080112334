import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const  ItemBody: any = styled(Box)`
  display: ${(props: any): any => props.isOpen ? 'block' : 'none'};
  background-color: ${colors.lightGrey};
  ${textStyles.body};
  padding: 30px 10px;
  border-top: ${colors.middleGrey} 1px solid;

  h5 {
    font-size: 14px;
  }
`;

import { FC, memo, useState, useRef, useEffect } from 'react';

import { css } from '@emotion/react';
import Select, {
  IndicatorProps,
  MenuProps,
  OptionProps,
  OptionTypeBase,
  SelectComponentsConfig,
  SingleValueProps,
  ValueType,
  components
} from 'react-select';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import { selectStyles } from './field-select.styles';
import { FieldSelectProps } from './field-select.types';

const DropdownIndicator: FC<IndicatorProps<any, any, any>> = ({ selectProps, isDisabled }) => {
  return (
    <div
      css={{
        transform: `rotate(${selectProps.menuIsOpen ? '180deg' : '0deg'})`,
        padding: '0 15px',
        height: '25px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Icon
        iconName="arrow-down"
        size="xsmall"
        pathFill={isDisabled ? colors.middleGrey : colors.floomMidnightBlue}
      />
    </div>
  );
};

const Option: FC<{ optionProps: OptionProps<any, false, any>; renderOption?: FieldSelectProps['renderOption'] } | undefined> = memo(({
  optionProps,
  renderOption: RenderOption
}) => {
  return (
    <Flex
      {...optionProps.innerProps}
      css={optionProps.getStyles('option', optionProps)}
    >
      <Box>
        {
          RenderOption ? (
            <RenderOption {...optionProps} />
          ) : optionProps.label
        }
      </Box>
      <Flex
        ml="12px"
        alignItems="center"
        css={css`
          height: 0
        `}
      >
        {optionProps.isSelected && (
          <Icon
            iconName="tick"
            size="small"
          />
        )}
      </Flex>
    </Flex>
  );
});

const Menu: FC<MenuProps<any, any, any>> = props => {
  const menuWrappeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    menuWrappeRef?.current?.addEventListener('touchmove', onTouchMove, { passive: false });

    return () => {
      menuWrappeRef?.current?.removeEventListener('touchmove', onTouchMove);
    };
  }, []);

  const onTouchMove = (event: TouchEvent): boolean => {
    event.stopPropagation();

    return true;
  };

  return (
    <div ref={menuWrappeRef}>
      <components.Menu<any, any, any>
        {...props}
      >
        {props.children}
      </components.Menu>
    </div>
  );
};

const SingleValue: FC<SingleValueProps<any, any>> = props => {
  const label = props.getValue()?.[0]?.label;

  return (
    <div
      {...props.innerProps}
      css={props.getStyles('singleValue', props)}
    >
      {label || props.selectProps.placeholder || 'Select'}
    </div>
  );
};

export const FieldSelect = <T extends OptionTypeBase = { label: string; value: string }, >({
  selectProps,
  options,
  menuStyles,
  onSelect,
  renderOption
}: FieldSelectProps<T>): JSX.Element => {
  const [value, setValue] = useState<ValueType<any, false>>();

  const onSelectChange = (selectValue: ValueType<T, false>): void => {
    setValue(selectValue);
    onSelect?.(selectValue!.value);
  };

  const selectComponents: SelectComponentsConfig<T, false, any> = {
    DropdownIndicator: DropdownIndicator,
    IndicatorSeparator: null,
    SingleValue: SingleValue,
    Menu: Menu,
    Option: optionProps => (
      <Option
        {...optionProps}
        optionProps={optionProps}
        renderOption={renderOption as any}
      />
    )
  };

  Object.keys(components).forEach(key => {
    if (components[key] === undefined) {
      delete components[key];
    }
  });

  return (
    <Box>
      <Select<T>
        {...selectProps}
        isSearchable={!!selectProps?.isSearchable}
        hideSelectedOptions={false}
        isClearable={false}
        onChange={onSelectChange}
        menuPortalTarget={selectProps?.menuPortalTarget || null}
        menuPosition={selectProps?.menuPosition || 'absolute'}
        menuPlacement={selectProps?.menuPlacement || 'auto'}
        value={selectProps?.hasOwnProperty?.('value') ? selectProps.value : value}
        options={options || []}
        closeMenuOnScroll={() => selectProps?.closeMenuOnScroll || false}
        styles={selectStyles({
          menuStyles: menuStyles || {}
        }) as any}
        components={selectComponents}
      />
    </Box>
  );
};

FieldSelect.defaultProps = {
  selectProps: {
    isSearchable: false
  }
};

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const AccordionHeading = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  color: ${colors.floomMidnightBlue};
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const HeaderContainer = styled(Box)`
  padding: 15px 10px 0 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${colors.uiFills};
`;

export const Content = styled(Box)`
  align-self: flex-start;
  width: 100%;
  margin-bottom: 20px;
`;

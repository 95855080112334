import React, { ReactNode } from 'react';

import { Flex } from 'rebass';

import { ListItemUnion } from 'generated-types.d';

import { getCountryByCode } from 'utils/metadata/countries-states';
import { fontSizes } from 'utils/rebass-theme';

import { CheckoutTitleWrapper } from 'features/lists/components/checkout-list/checkout-list.styles';
import {
  ListCellTitle,
  ListCellSubtitle
} from 'features/lists/lists.styles';

import { ListCellSubtitleItem } from './checkout-list-item-title.styles';

interface CheckoutListItemTitleProps {
  listItem: ListItemUnion;
}

export const CheckoutListItemTitle: React.FC<CheckoutListItemTitleProps> = ({ listItem }) => {
  const getStemLength = ((item: ListItemUnion): string | null => {
    switch (item.__typename) {
      case 'ListItemTypeFlower':
        const hasStemLength = !!item?.minimumStemLength && item?.stemLengthUnit;

        return hasStemLength ? `${item.minimumStemLength}${item.stemLengthUnit}` : null;

      default:
        return null;
    }
  });

  const renderStemLength = (): ReactNode => {
    const stemLength = getStemLength(listItem);

    if (!stemLength) return;

    return (
      <ListCellSubtitleItem
        isBold={true}
      >
        {stemLength}
      </ListCellSubtitleItem>
    );
  };

  const renderCountry = (): ReactNode => {
    const country = getCountryByCode(listItem.promotedTradeSkuAvailability?.grower?.countryCode);

    if (!country) return;

    return (
      <ListCellSubtitleItem>
        {country}
      </ListCellSubtitleItem>
    );
  };

  const renderMultiplesOf = (): ReactNode => {
    const multiplesOf = listItem.promotedTradeSkuAvailability?.multiplesOf;

    if (!multiplesOf) return;

    return (
      <ListCellSubtitleItem>
        Multiples of {multiplesOf}
      </ListCellSubtitleItem>
    );
  };

  return (
    <CheckoutTitleWrapper>
      <ListCellTitle
        fontSize={fontSizes.small}
      >
        {listItem?.catalogItem?.title || listItem.sku}
      </ListCellTitle>
      <ListCellSubtitle mt="4px">
        <Flex>
          {renderStemLength()}
          {renderMultiplesOf()}
          {renderCountry()}
        </Flex>
      </ListCellSubtitle>
    </CheckoutTitleWrapper>
  );
};

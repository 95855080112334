import ToasterStore from 'stores/toaster-store/toaster-store';

export interface ChangePasswordProps {
  modalOpen: boolean;
  closeModalAction: () => any;
  toasterStore?: ToasterStore;
}

export enum PasswordScreen {
  new = 'new',
  current = 'current'
}

export interface ChangePasswordState {
  passwordScreen: PasswordScreen;
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
  errorCode: number;
}

export interface CurrentPasswordProps {
  currentPassword: string;
  onChangeCurrentPassword: (field: 'currentPassword' | 'newPassword' | 'newPasswordConfirmation', password: string) => any;
  onButtonClick: () => any;
  error: boolean;
}

export interface NewPasswordProps {
  newPassword: string;
  onChangeNewPassword: (field: 'currentPassword' | 'newPassword' | 'newPasswordConfirmation', password: string) => any;
  newPasswordConfirmation: string;
  onChangeNewPasswordConfirmation: (field: 'currentPassword' | 'newPassword' | 'newPasswordConfirmation', password: string) => any;
  onButtonClick: () => any;
  error: boolean;
}

import gql from 'graphql-tag';

import {
  WHOLESALE_PRE_ORDER_ITEM_FRAGMENT
} from 'features/wholesale/fragments/pre-order/pre-order-fragments';

export const UPDATE_WHOLESALE_PRE_ORDER_ITEM = gql`
  mutation UpdatePreOrderItem(
    $preOrderItemId: ID
    $data: WholesalePreOrderUpdateInput!,
    $where: WholesalePreOrderWhereUniqueInput!
  ) {
    updateWholesalePreOrder(
      data: $data,
      where: $where
    ) {
      items(
        where: {
          id: $preOrderItemId
        }
      ) {
        ...WholesalePreOrderItemFragment
      }
    }
  }
  ${WHOLESALE_PRE_ORDER_ITEM_FRAGMENT}
`;

export const CREATE_WHOLESALE_PREORDER = gql`
  mutation CreateWholesalePreOrder($data: WholesalePreOrderCreateInput!) {
    createWholesalePreOrder(data: $data) {
      id
    }
  }
`;

export const CANCEL_WHOLESALE_PRE_ORDER = gql`
  mutation CancelWholesalePreOrder($where: WholesalePreOrderWhereUniqueInput!) {
    cancelWholesalePreOrder(where: $where) {
      id
    }
  }
`;

export const CREATE_DRAFT_WHOLESALE_ORDER = gql`
  mutation CreateDraftWholesaleOrder($data: DraftWholesaleOrderCreateInput!) {
    createDraftWholesaleOrder(data: $data) {
      id
    }
  }
`;

import {
  FC,
  Fragment,
  ReactNode
} from 'react';

import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import hex2Rgba from 'hex2rgba';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

import WholesaleStemIconImage from 'assets/images/wholesale-stems-in-box.svg';

import { useWholesaleCountdown } from 'features/wholesale/components/date-countdown/date-countdown.hooks';

import Button from 'components/button';
import GenericModal from 'components/generic-modal';

import {
  ButtonWrapper,
  ImageWrapper,
  Wrapper
} from './pre-order-confirmation-modal.styles';
import {
  PreOrderConfirmationModalProps
} from './pre-order-confirmation-modal.types';

export const PreOrderConfirmationModal: FC<PreOrderConfirmationModalProps> = inject((stores: FxStores): InjectedFxStores => ({
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
}))(observer(({
  closeModal,
  isOpen,
  data,
  selectedWholesalePreOrderStore
}) => {
  const {
    renderFullDate,
    timeOfDay
  } = useWholesaleCountdown(selectedWholesalePreOrderStore?.preOrder?.startProcessingAt);

  const getContent = (): ReactNode => {
    if (!!data.isPreOrderEditable) {
      return (
        <Fragment>
          <Box css={textStyles.h2}>
            <Fragment>Pre-order placed!</Fragment>
          </Box>
          <Box
            css={textStyles.body}
            mt="20px"
          >
            <Fragment>
              You can make changes to your order until <strong>{timeOfDay}</strong>{renderFullDate()}.
              You can manage all your upcoming orders in your Order History section.
            </Fragment>
          </Box>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Box css={textStyles.h2}>
          <Fragment>Pre-order requested!</Fragment>
        </Box>
        <Box
          css={textStyles.body}
          mt="20px"
        >
          <Fragment>Floom will process your request and contact you about your pre-order.</Fragment>
        </Box>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <GenericModal
        width={460}
        modalOpen={isOpen}
        closeModal={closeModal}
        shouldHideFooter={true}
        shouldHideHeader={true}
        overlayStyles={{
          background: hex2Rgba(colors.floomMidnightBlue, '0.6')
        }}
        innerComponent={(
          <Wrapper>
            <ImageWrapper>
              <img src={WholesaleStemIconImage} />
            </ImageWrapper>
            {getContent()}
            <ButtonWrapper onClick={closeModal}>
              <Button
                copy="Great!"
                isParentWidth={true}
              />
            </ButtonWrapper>
            <Box
              fontSize="12px"
              mt="20px"
            >
              <Link
                to={NavPages.WholesaleOrders}
                onClick={closeModal}
              >
                View all upcoming orders
              </Link>
            </Box>
          </Wrapper>
        )}
      />
    </Fragment>
  );
}));

import { Message } from 'types/conversations.types';

export interface MessagesProps {
  messages: Message[];
  error: any;
  loading: boolean;
}

export enum Position {
  FIRST = 'FIRST',
  LAST = 'LAST',
  MIDDLE = 'MIDDLE',
  SINGLE = 'SINGLE'
}

import {
  FC,
  Fragment,
  ReactNode
} from 'react';

import { Global } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';

import { colors } from 'utils/rebass-theme';

import noImage from 'assets/images/no_image.png';

import {
  GlobalLightboxStyles
} from './global-lightbox.styles';
import {
  GlobalLightboxProps
} from './global-lightbox.types';

export const GlobalLightbox: FC<GlobalLightboxProps> = inject((stores: FxStores): InjectedFxStores => ({
  lightboxStore: stores.lightboxStore
}))(observer(({
  lightboxStore
}) => {
  const renderImageError = (): ReactNode => {
    return (
      <img
        className="lightBoxError"
        alt="no image found"
        src={noImage}
      />
    );
  };

  return (
    <Fragment>
      <Global styles={GlobalLightboxStyles} />
      {lightboxStore!.isOpen && (
        <Lightbox
          wrapperClassName="lightboxImg"
          mainSrc={lightboxStore!.currentImageInView?.url || ''}
          nextSrc={lightboxStore!.nextImage?.url || ''}
          prevSrc={lightboxStore!.previousImage?.url || ''}
          imageCaption={lightboxStore!.currentImageInView?.caption || ''}
          onCloseRequest={lightboxStore!.closeLightbox}
          onMovePrevRequest={lightboxStore!.viewPreviousImage}
          onMoveNextRequest={lightboxStore!.viewNextImage}
          enableZoom={lightboxStore!.enableZoom}
          animationDisabled={lightboxStore!.animationDisabled}
          animationOnKeyInput={lightboxStore!.animationOnKeyInput}
          animationDuration={lightboxStore!.animationDuration}
          imageLoadErrorMessage={renderImageError()}
          reactModalStyle={{
            overlay: {
              backgroundColor: colors.floomMidnightBlue
            }
          }}
        />
      )}
    </Fragment>
  );
}));

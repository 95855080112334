import { Component } from 'react';

import { Analytics, PermissionsService, NavService } from 'lib';

import DeliveryCoverage from 'features/settings/components/delivery/components/delivery-coverage';
import DeliveryService from 'features/settings/components/delivery/services';
import { SETTINGS_NAV } from 'features/settings/components/settings-root/settings-root.config';
import SettingsService from 'features/settings/services';

class DeliveryCoveragePage extends Component<any> {
  private SettingsAnalytics = SettingsService.SettingsAnalytics;

  private DeliveryService = DeliveryService;

  componentDidMount(): void {
    this.permissionsKickOut();
    this.DeliveryService.initCoverages();
    Analytics.page(Analytics.FxPageEvent.SettingsDeliveryCoverage);
    this.SettingsAnalytics.onSectionView(Analytics.SettingsSection.DeliveryCoverage);
  }

  private permissionsKickOut = (): void => {
    const roles = SETTINGS_NAV.find(tile => this.props.path === tile.url);

    if (roles?.restrictedRoles.some(role => role === PermissionsService.getUserRole())) {
      NavService.settings();
    }
  };

  render(): JSX.Element {
    return <DeliveryCoverage {...this.props} />;
  }
}

export default DeliveryCoveragePage;

import { GraphQLHelpers } from 'lib';

const addRootQueryParams = (vars: any): string => {
  return `
    $orderBy: ProductOrderByInput
    ${GraphQLHelpers.addVariable(vars.selectedCategories.length, 'selectedCategories', '[String!]')}
    ${GraphQLHelpers.addVariable(vars.showActiveOnly, 'showActiveOnly', 'Boolean!')}
    ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
  `;
};

const addTypeWhereFilter = (types: string[]): string => {
  if (!types.length) return '';

  return `
    categories_some: {
      slug_in: $selectedCategories
    }
  `;
};

export {
  addRootQueryParams,
  addTypeWhereFilter
};

import React, { Component } from 'react';

import * as Styles from './form-field-wrapper.styles';
import { FormFieldWrapperProps } from './form-field-wrapper.types';

export default class FormFieldWrapper extends Component<FormFieldWrapperProps> {
  static defaultProps = {
    hasSpacing: true
  };

  render(): React.ReactNode {
    return (
      <Styles.Content hasSpacing={this.props.hasSpacing}>
        <Styles.Title>{this.props.title}</Styles.Title>
        {this.props.optional ? <Styles.Optional>OPTIONAL</Styles.Optional> : null}
        <Styles.SubTitle>{this.props.subtitle}</Styles.SubTitle>
        <Styles.InnerComponent>
          {this.props.children}
        </Styles.InnerComponent>
      </Styles.Content>
    );
  }
}

import * as Yup from 'yup';

import { MerchantStage } from 'generated-types.d';

import {
  LocalisationService,
  ValidationService
} from 'lib';

import {
  StageOption,
  StageShiftFormData
} from './stage-shift-modal.types';

export const STAGE_SHIFT_FORM_VALIDATION = Yup.object().shape<StageShiftFormData>({
  selectedStage: Yup
    .string<MerchantStage>()
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please select a stage')),
  stageShiftReason: Yup
    .string()
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please enter a reason for changing the stage')),
  hasConfirmed: Yup
    .boolean()
    .oneOf([true], params => ValidationService.generateYupMessageSchema(params, 'Please understand and confirm the changes you are about to make'))
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please understand and confirm the changes you are about to make'))
});

export const SUPPORTED_STAGE_TYPES: StageOption[] = [
  {
    id: MerchantStage.Churn,
    title: LocalisationService.localiseMerchantStage(MerchantStage.Churn)
  },
  {
    id: MerchantStage.Dormant,
    title: LocalisationService.localiseMerchantStage(MerchantStage.Dormant)
  }
];

export const CONFIRMATION_COPY = `
  I confirm that I am changing the stage for <strong>[MERCHANT_NAME]</strong>
  from <strong>[OLD_STAGE_NAME]</strong> to <strong>[NEW_STAGE_NAME]</strong> and that this change
  will take place on the <strong>[BILLING_END_DATE]</strong>.
  The merchant will be notified of the change immediately.
`;

import React, { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { AddOnsGroupOption } from 'stores/add-ons-store/add-ons-store.types';

import { Container } from 'utils/css-mixins';

import HamperImage from 'assets/images/hamper.jpg';
import PricePointsImage from 'assets/images/price-points.jpg';

import AddOnsServices from 'features/add-ons/services';

import LoadMoreButton from 'components/load-more-button';
import WithLoading from 'components/with-loading';

import TableLayoutGroup from './table-layout-group';
import * as Styles from './table-layout.styles';
import { TableLayoutProps } from './table-layout.types';

class TableLayout extends Component<TableLayoutProps> {
  AddOnsListService = AddOnsServices.AddOnsListService;

  componentWillUnmount = (): void => {
    this.props.addOnsStore!.resetAddOns();
  };

  getGroupKeys = (): any[] => {
    return Object.keys(this.props.items);
  };

  getGroupCount = (productGroup: string): number => {
    return this.props.items[productGroup].edges.length;
  };

  onLoadMore = (): void => this.AddOnsListService.loadMoreAddOns();

  hasItems = (): number => Object
    .keys(this.props.items)
    .reduce((acc, curr): number => this.props.items[curr].edges.length + acc, 0);

  render(): React.ReactNode {
    const groupKeys: AddOnsGroupOption[] = this.getGroupKeys();

    return (
      <>
        <Container>
          <WithLoading
            isLoading={this.props.isLoading
              && !groupKeys.length
              && !this.hasItems()}
            hasNoResults={!this.hasItems()}
            renderNoResults={(): ReactNode => (
              <Styles.Container>
                <Styles.LargeParagraph>Add-ons are a great way to get your products noticed. If you want to increase your sales and boost your bottom line, then add-ons are the way to go.</Styles.LargeParagraph>

                <Styles.ContentBlock>
                  <Flex>
                    <Box mr="30px">
                      <img
                        src={HamperImage}
                        alt="Hamper of popcorn and chocolate"
                      />
                    </Box>
                    <Box width="190%">
                      <Styles.Heading>What add-ons can I&nbsp;sell?</Styles.Heading>
                      <Styles.Paragraph>
                        We have categories that cover a wide selection of the most sought after products. From vases and pots, to chocolates and cosmetics. To see the full list, try creating your first&nbsp;add-on.
                      </Styles.Paragraph>
                    </Box>
                  </Flex>
                </Styles.ContentBlock>

                <Styles.ContentBlock>
                  <Styles.Heading>Where will my add-ons be&nbsp;shown?</Styles.Heading>
                  <Styles.Paragraph>Add-ons are shown on product pages and at checkout. At checkout, we show a maximum of 8 add-ons, selected from the best selling categories. This leads to better conversion rates, and more money in your&nbsp;pockets!</Styles.Paragraph>
                </Styles.ContentBlock>

                <Styles.ContentBlock>
                  <Flex flexDirection="row-reverse">
                    <Box ml="30px">
                      <img
                        src={PricePointsImage}
                        alt="Bottle of belaire rosé"
                      />
                    </Box>
                    <Box width="190%">
                      <Styles.Heading>What price points should I&nbsp;offer?</Styles.Heading>
                      <Styles.Paragraph>
                        With rising prices having an impact on consumer spending, it&apos;s more important than ever to offer options across all price points. Why not add both small and large bottles of bubbly, or bars of chocolate alongside&nbsp;boxes.
                      </Styles.Paragraph>
                    </Box>

                  </Flex>
                </Styles.ContentBlock>

                <Styles.ContentBlock>
                  <Styles.Heading>Need any help?</Styles.Heading>
                  <Styles.Paragraph>Check out our guide on uploading add-ons over on our <a href="http://help.floomx.com"><Styles.Link>help centre</Styles.Link></a>.</Styles.Paragraph>
                </Styles.ContentBlock>
              </Styles.Container>
            )}
          >
            <Box
              mt="50px"
              mb="30px"
            >
              {
                groupKeys.map((addOnGroup: AddOnsGroupOption) => (
                  <Fragment key={addOnGroup}>
                    <TableLayoutGroup
                      group={this.props.items[addOnGroup]}
                      groupName={addOnGroup}
                      showHeading={false}
                    />
                    <LoadMoreButton
                      shouldDisplay={this.props.items[addOnGroup].pageInfo.hasNextPage}
                      groupName={addOnGroup}
                      onLoadMore={this.onLoadMore}
                      isLoading={this.props.isLoading}
                    />
                  </Fragment>
                ))
              }
            </Box>
          </WithLoading>
        </Container>
      </>
    );
  }
}

export default inject('addOnsStore')(observer(TableLayout));

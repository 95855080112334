import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  position: sticky;
  bottom: 0;
  padding-bottom: 20px;
  background: ${colors.uiFills};
`;

import {
  observable,
  action,
  makeObservable
} from 'mobx';

import { RijnsDeliveryType, WholesaleTradeLineItem } from 'generated-types.d';

import * as Types from './wholesale-payment-store.types';

export default class WholesalePaymentStore {
  constructor() {
    makeObservable(this, {
      basket: observable,
      merchantDeliveryType: observable,
      error: observable,
      isProcessingWholesaleOrder: observable,
      addItemToBasket: action,
      clearLiveBasket: action,
      setMerchantDeliveryType: action,
      setError: action,
      resetError: action
    });
  }

  public basket: Types.WholesaleBasketItem[] = [];

  public merchantDeliveryType: RijnsDeliveryType = RijnsDeliveryType.Standard;

  public error: Types.WholesalePaymentError | null = null;

  /**
   * checkout observables
   */
  public isProcessingWholesaleOrder: boolean = false;

  public clearLiveBasket = (): void => {
    this.basket = [];
  };

  public setMerchantDeliveryType = (deliveryType: RijnsDeliveryType): void => {
    this.merchantDeliveryType = deliveryType;
  };

  public setError = (type: Types.WholesalePaymentErrorType, message: string): void => {
    this.error = {
      type,
      message
    };
  };

  public resetError = (): void => {
    this.error = null;
  };

  /**
   * @todo support multiple items
   * Moved to wholesale shop basket store, where order placement
   * now takes place
   */
  public addItemToBasket = (item: WholesaleTradeLineItem, quantity: number): void => {
    this.basket = [{
      quantity,
      item
    }];
  };
}

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { COMPLETE_MERCHANT_VALIDATION } from 'lib/services/validation/schemas/merchant';
import { inject, observer } from 'mobx-react';

import { ValidationService } from 'lib';

import { ModalRouterScrollableArea } from 'features/modal-router/modal-router.styles';
import SettingsService from 'features/settings/services';

import MerchantDetailContent from './components/merchant-detail-content';
import MerchantDetailHeader from './components/merchant-detail-header';
import * as Types from './merchant-detail.types';

export const MerchantDetailContext = React.createContext({
  canInvite: false
});

class MerchantDetailModal extends Component<Types.MerchantDetailModalProps> {
  private MerchantsSettingsService = SettingsService.MerchantsSettingsService;

  public static defaultProps: Types.MerchantDetailModalProps = {
    config: {
      closeTo: '/',
      type: 'merchant-detail',
      id: '1',
      additionalOptions: {}
    }
  };

  state = {
    canInvite: false
  };

  public componentDidMount(): void {
    this.MerchantsSettingsService.fetchMerchant(this.props.config.id);
    this.setInviteState();
  }

  public componentDidUpdate(): void {
    this.setInviteState();
  }

  public componentWillUnmount(): void {
    this.props.merchantDetailStore!.resetSelectedMerchant();
  }

  private setInviteState = async (): Promise<void> => {
    const canInvite = await ValidationService.isValid(COMPLETE_MERCHANT_VALIDATION, this.props.merchantDetailStore!.selectedMerchant);

    if (this.state.canInvite !== canInvite) {
      this.setState({ canInvite });
    }
  };

  render(): ReactNode | null {
    return (
      <>
        <MerchantDetailHeader
          closeTo={this.props.config.closeTo!}
          merchant={this.props.merchantDetailStore!.selectedMerchant}
        />
        <ModalRouterScrollableArea
          ref={this.props.innerRef!}
          id="checkout-modal-content"
          style={{
            height: 'calc(100vh - 80px)',
            maxHeight: `${this.props.height! - 150}px`
          }}
          css={css`
            padding: 25px 30px;
          `}
        >
          <MerchantDetailContext.Provider
            value={{
              canInvite: this.state.canInvite
            }}
          >
            <MerchantDetailContent
              canInvite={this.state.canInvite}
            />
          </MerchantDetailContext.Provider>
        </ModalRouterScrollableArea>
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantSettingsStore: stores.merchantSettingsStore,
  merchantDetailStore: stores.merchantDetailStore
}))(observer(MerchantDetailModal));

/**
 * Theme file contains global variables and css structures
 * used in multiple locations. Heavily influenced by {@link https://github.com/reactjs/reactjs.org}
 *
 * @copyright FloomLTD
 * @providesModule theme
 */

export type Breakpoint =
  | 'laptop'
  | 'notebook'
  | 'normal'
  | 'medium'
  | 'small'
  | 'xsmall';

export type BreakpointMap = { [index in Breakpoint]: number; };

export type MQType = (bp: Breakpoint | number) => string;

// Media query breakpoint values
export const breakpoints: BreakpointMap = {
  laptop: 1366,
  notebook: 1280,
  normal: 1024,
  medium: 992,
  small: 768,
  xsmall: 576
};

// Generates max-width media query for each defined breakpoint in breakpoints
// run inside an emotion css block like so: ${mq('medium)} { font-size: 3em }
const mq: MQType = (bp: number | Breakpoint): string => {
  if (breakpoints[bp] || typeof bp === 'number') {
    return `@media (max-width: ${breakpoints[bp] || bp}px)`;
  }

  throw new Error('Breakpoint supplied does not exist');
};

export {
  mq
};

import React from 'react';

import { inject, observer } from 'mobx-react';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import GenericModal from 'components/generic-modal';

import UserService from '../../services';

import { CurrentPassword } from './change-password-current-input';
import { NewPassword } from './change-password-new-inputs';
import { ChangePasswordProps, ChangePasswordState, PasswordScreen } from './change-password.types';

class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState> {
  defaultState: ChangePasswordState = {
    passwordScreen: PasswordScreen.current,
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    errorCode: 0
  };

  state = this.defaultState;

  accountService = UserService;

  changePasswordField = (field: 'currentPassword' | 'newPassword' | 'newPasswordConfirmation', value: string): void => {
    // @ts-ignore
    this.setState({
      [field]: value,
      errorCode: 0
    });
  };

  changePasswordPage = (): void => {
    this.setState({
      passwordScreen: PasswordScreen.new
    });
  };

  closeModal = (): void => {
    setTimeout(() => this.setState(this.defaultState), 600);
    this.props.closeModalAction();
  };

  submitChangePassword = (): void => {
    this.accountService.submitChangePassword(this.state.currentPassword, this.state.newPassword)
      .then((errorCode: number | null) => {
        if (!errorCode) {
          this.setState(this.defaultState);
          this.props.closeModalAction();
          this.props.toasterStore!.popSuccessToast('Password', 'update');
        } else {
          switch (errorCode) {
            case 77046114415:
              this.setState({
                ...this.defaultState,
                errorCode
              });

              break;

            case 77046114417:
              this.setState({
                errorCode: errorCode,
                passwordScreen: PasswordScreen.current,
                newPasswordConfirmation: '',
                newPassword: ''
              });

              break;

            case 77046111008:
              this.props.toasterStore!.popToast('You cannot change this users password.', ToastType.Error);

              break;

            default:
              this.props.toasterStore!.popToast('Something went wrong! Please try again or contact Floom', ToastType.Error);

              break;
          }
        }
      });
  };

  submitForm = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (this.state.passwordScreen === PasswordScreen.new && this.state.currentPassword) {
      this.setState({ passwordScreen: PasswordScreen.current });
    } else if (this.state.passwordScreen === PasswordScreen.current
      && this.state.newPassword.length >= 8
      && this.state.newPassword === this.state.newPasswordConfirmation) {
      this.submitChangePassword();
    }
  };

  render(): React.ReactNode {
    return (
      <GenericModal
        innerComponent={(
          <form onSubmit={this.submitForm}>
            {
              this.state.passwordScreen === PasswordScreen.current
                ? (
                  <CurrentPassword
                    currentPassword={this.state.currentPassword}
                    onChangeCurrentPassword={this.changePasswordField}
                    error={this.state.errorCode === 77046114415}
                    onButtonClick={this.changePasswordPage}
                  />
                )
                : (
                  <NewPassword
                    newPassword={this.state.newPassword}
                    newPasswordConfirmation={this.state.newPasswordConfirmation}
                    onChangeNewPassword={this.changePasswordField}
                    onChangeNewPasswordConfirmation={this.changePasswordField}
                    error={this.state.errorCode === 77046114417 || this.state.newPassword === this.state.currentPassword}
                    onButtonClick={this.submitChangePassword}
                  />
                )
            }
          </form>
        )}
        title={`Enter ${this.state.passwordScreen} password`}
        shouldHideFooter={true}
        modalOpen={this.props.modalOpen}
        closeModal={this.closeModal}
        width={355}
      />
    );
  }
}

export default inject('toasterStore')(observer(ChangePassword));

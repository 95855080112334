import gql from 'graphql-tag';

import { PRODUCT_VARIATION_FRAGMENT } from './product-variation';

export const PRODUCT_LIST_FRAGMENT = gql`
  fragment ProductListFragment on Product {
    id
    active
    title
    channels(first: 3) {
      id
      channel
    }
    type(first: 1) {
      id
      title
    }
    merchant {
      id
      currency
      stage
      plan {
        features
      }
    }
    deliveryConfigs {
      id
      title
    }
    variations(first: 3) {
      id
      stock
      price
      type
      media(
        first: 1
        where: {
          category: SideOn
        }
      ) {
        src
        category
      }
    }
  }
`;

export const PRODUCT_FULL_FRAGMENT = gql`
  fragment ProductFull on Product {
    flc_id
    id
    sku
    active
    title
    description
    internalDescription
    channels {
      id
      channel
    }
    merchant {
      id
      currency
      flc_id
      stage
      subscription {
        productLimit
      }
      plan {
        type
        features
      }
      customSiteConfig {
        id
      }
    }
    variations {
      ...VariationsFull
    }
    categories {
      id
      title
      slug
    }
    availability {
      id
      type
      startAt
      endAt
      updatedAt
    }
    deliveryConfigs {
      id
      title
    }
    type {
      id
      title
      slug
      active
    }
  }
  ${PRODUCT_VARIATION_FRAGMENT}
`;

export const PRODUCT_CONNECTION_FRAGMENT = (merchantId?: string): any => {
  return gql`
    fragment ProductConnectionFields on ProductConnection {
      pageInfo {
        hasNextPage
      }
      aggregate {
        count
      }
      edges {
        node {
          ...ProductListFragment
          collectionSellerProductConfigs(
            first: 1
            where: {
              ${merchantId ? `
                merchant: {
                  id: "${merchantId}"
                }
              ` : ''}
            }
          ) {
            id
            active
            productVariationConfigs {
              id
              stock
              sourceProductVariation {
                id
                type
              }
            }
          }
        }
      }
    }
    ${PRODUCT_LIST_FRAGMENT}
  `;
};

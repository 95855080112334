import React, { FC, Fragment, ReactNode, useState } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex } from 'rebass';

import {
  UserRole,
  WholesalePreOrder
} from 'generated-types.d';

import {
  NavService,
  PermissionsService
} from 'lib';

import SelectedWholesalePreOrderStore from 'stores/selected-wholesale-pre-order/selected-wholesale-pre-order-store';
import ToasterStore from 'stores/toaster-store/toaster-store';
import WholesaleShopStore from 'stores/wholesale/wholesale-shop-store/wholesale-shop-store';

import {
  colors,
  textStyles
} from 'utils/rebass-theme';

import {
  WholesaleCheckoutHelpers
} from 'features/wholesale/helpers/wholesale-helpers';

import Button from 'components/button';
import { EntityListRow } from 'components/entity-list/entity-list.styles';
import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType } from 'components/entity-list/table-layout-entity.types';
import Icon from 'components/icon';

import {
  getOrderDeliveryCost,
  getPreOrderDeliveryCost,
  getPreOrderTaxValue,
  getPreOrderTotal,
  getTotal
} from '../order-card/utils/order-card.utils';

export interface PreOrderCardTableProps {
  preOrder: WholesalePreOrder;
  selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
  wholesaleShopStore?: WholesaleShopStore;
  toasterStore?: ToasterStore;
}

const renderTimeToGo = (dateTime: string): ReactNode => {
  const calendarTime = moment(dateTime).calendar(null, {
    sameDay: '[Today] [at] h:mma',
    nextDay: '[Tomorrow] [at] h:mma',
    nextWeek: 'dddd',
    lastDay: '[Yesterday] [at] h:mma',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY'
  });

  const relativeTime = moment(dateTime).fromNow();

  return (
    <Fragment>
      {calendarTime}
      <Box
        css={css`
          color: ${colors.shade60}
        `}
      >
        ({relativeTime})
      </Box>
    </Fragment>
  );
};

const PreOrderCardTable: FC<PreOrderCardTableProps> = ({
  preOrder,
  selectedWholesalePreOrderStore,
  toasterStore
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isInternalRole = PermissionsService.isInternalRole();
  const deliveryCharge = (!!preOrder?.wholesaleOrder && isInternalRole)
    ? getOrderDeliveryCost(preOrder?.wholesaleOrder)
    : getPreOrderDeliveryCost(preOrder);
  const taxAmount = (!!preOrder?.wholesaleOrder && isInternalRole)
    ? WholesaleCheckoutHelpers.orderTaxValue(preOrder?.wholesaleOrder)
    : getPreOrderTaxValue(preOrder);

  const aggregateItems = WholesaleCheckoutHelpers.setOrderItemAggregates(preOrder?.wholesaleOrder?.items || []);
  const total = (!!preOrder?.wholesaleOrder && isInternalRole)
    ? getTotal(preOrder?.wholesaleOrder)
    : getPreOrderTotal(preOrder);
  const merchantCurrency = preOrder?.merchant?.currency;

  const handleCtaClick = async (): Promise<void> => {
    switch (true) {
      case (preOrder.wholesaleOrder && preOrder.wholesaleOrder.status === 'Draft'): {
        NavService.wholesaleManageOrder(preOrder.wholesaleOrder!.id);

        break;
      }

      case (!preOrder.wholesaleOrder): {
        setIsLoading(true);
        const result = await selectedWholesalePreOrderStore?.createDraftOrder(preOrder);

        if (result) {
          toasterStore?.popSuccessToast(`Your order`, 'create');
          NavService.wholesaleManageOrder(result.id);
        } else {
          setIsLoading(false);
        }

        break;
      }

      default: {
        NavService.wholesaleOrderDetail(preOrder.id);

        break;
      }
    }
  };

  const buildHubText = (): string[] | undefined => {
    if (!preOrder.merchant?.wholesaleDeliveryConfigs) {
      return;
    }

    const hubsForOrder = preOrder.merchant?.wholesaleDeliveryConfigs[0]?.hubs?.filter(hub => {
      return hub?.supplier?.id === preOrder.suppliers?.[0].id;
    });

    return hubsForOrder?.map(hub => {
      return `${hub!.supplier?.name} - ${hub!.title}`;
    });
  };

  return (
    <EntityListRow
      key={preOrder.id}
      onClick={() => NavService.wholesalePreOrder(preOrder.id)}
      style={{
        width: '100%',
        cursor: 'pointer'
      }}
    >
      <Flex alignItems="center">
        <Flex
          width="110px"
          height="75px"
          alignItems="stretch"
          justifyContent="center"
          flexDirection="column"
          css={css`
            background-color: ${colors.lightGrey};
            text-align: center;
          `}
        >
          <Icon
            iconName="wholesale"
            pathFill={colors.floomMidnightBlue}
            styles={css`
              width: 20px;
              margin: 0 auto;
            `}
          />
          <Box css={textStyles.body}>
            {preOrder.id!.slice(0, 7)}
          </Box>
        </Flex>
        <Box
          width="100%"
          p="10px"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
          >
            <TableLayoutEntity config={[
              {
                cellType: CellType.Custom,
                restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
                flexGrow: '1',
                flexBasis: '140px',
                customInnerElement: (
                  <Fragment>
                    {preOrder.merchant.title}
                    <Box
                      css={css`
                        color: ${colors.shade60}
                      `}
                    >
                      {buildHubText()}
                    </Box>
                  </Fragment>
                )
              },
              {
                cellType: CellType.Custom,
                flexGrow: '1',
                flexBasis: '50px',
                customInnerElement: (
                  <Fragment>
                    {(isInternalRole && preOrder.wholesaleOrder && aggregateItems.length) ? aggregateItems[0].lineItems.length : preOrder.items?.length}
                  </Fragment>
                )
              },
              {
                cellType: CellType.Text,
                flexGrow: '1',
                flexBasis: '50px',
                props: {
                  text: total ? WholesaleCheckoutHelpers.renderWholesalePrice(total + deliveryCharge + taxAmount, merchantCurrency) : '-'
                }
              },
              {
                cellType: CellType.Custom,
                flexGrow: '1',
                flexBasis: '90px',
                customInnerElement: (
                  <Fragment>
                    {moment(preOrder.deliverAt).format('Do MMM YYYY')}
                  </Fragment>
                )
              },
              preOrder.status === 'Open' ? {
                cellType: CellType.Custom,
                restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
                flexGrow: '1',
                flexBasis: '140px',
                customInnerElement: (
                  <Fragment>
                    {renderTimeToGo(preOrder.startProcessingAt)}
                  </Fragment>
                )
              } : {
                cellType: CellType.Custom,
                restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
                flexGrow: '1',
                flexBasis: '140px',
                customInnerElement: (
                  <Fragment>
                    {renderTimeToGo(preOrder.stopProcessingAt)}
                  </Fragment>
                )
              },
              {
                cellType: CellType.Custom,
                restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
                fixedWidth: '160px',
                isVisible: preOrder.status === 'Processing',
                customInnerElement: (
                  <Flex justifyContent="flex-end">
                    {preOrder.status === 'Processing' && (
                      <button
                        onClick={e => {
                          e.stopPropagation();
                          handleCtaClick();
                        }}
                        css={css`
                          width: 100%
                        `}
                      >
                        <Button
                          copy={preOrder.wholesaleOrder ? 'Edit order' : 'Create order'}
                          appearance={preOrder.wholesaleOrder ? 'primary' : 'secondary'}
                          isParentWidth={true}
                          isLoading={isLoading}
                        />
                      </button>
                    )}
                  </Flex>
                )
              },
              {
                cellType: CellType.Custom,
                restrictedRoles: [UserRole.SuperAdmin, UserRole.CustomerService],
                flexGrow: '1',
                flexBasis: '90px',
                customInnerElement: (
                  <Fragment>
                    {preOrder.status}
                  </Fragment>
                )
              }]}
            />
          </Flex>
        </Box>
      </Flex>
    </EntityListRow>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore,
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore,
  toasterStore: stores.toasterStore
}))(observer(PreOrderCardTable));

import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { breakpoints, colors, space, textStyles } from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  flex-grow: 1;
  margin-top: ${space[3]}px;
  margin-bottom: 40px;
  padding: ${space[3]}px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    margin-bottom: 170px;
    padding: 0;
  }
`;

export const ItemsWrapper = styled(Box)`
  position: relative;
`;

export const HeadingsWrapper = styled(Box)`
  position: sticky;
  top: -1px;
  background: ${colors.floomCream};
  z-index: 10;
`;

export const HitsWrapper = styled(Box)`
  background-color: white;
  border: 1px black solid;
  padding: 5px;
`;

export const TableHeaderWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 70px;
  margin-left: 15px;
`;

export const TableHeaderText = styled.div`
  ${textStyles.footnote};
  text-transform: uppercase;
  font-weight: 300;
  font-size: 9px;
  
  @media only screen and (max-width: ${breakpoints.medium}) {
    font-size: 8px;
  }
`;

export const GroupHeading = styled(Box)`
  ${textStyles.h3}
  padding-bottom: ${space[3]}px;
  padding-left: ${space[3]}px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    padding-bottom: 0;
  }
`;

export const GroupWrapper: any = styled(Box)`
  margin-top: ${space[0]}px;
  
  @media only screen and (max-width: ${breakpoints.medium}) {
    margin-top: ${space[2]}px;
  }
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { fonts, colors, textStyles } from 'utils/rebass-theme';
import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import { InputSize } from './field-text.types';

export type FieldSize = {
  [key in InputSize]: {
    height: string;
    padding: string;
    fontSize: string;
    prefixPosition: string;
    prefixHeight: string;
  };
}

export const FIELD_SIZES: FieldSize = {
  xsmall: {
    height: '0.9rem',
    padding: '0.1rem',
    fontSize: '10px',
    prefixPosition: '15px',
    prefixHeight: '8px'
  },
  small: {
    height: '1.9rem',
    padding: '0.5rem',
    fontSize: '14px',
    prefixPosition: '8px',
    prefixHeight: '16px'
  },
  normal: {
    height: '2.9rem',
    padding: '0.9rem',
    fontSize: '14px',
    prefixPosition: '12px',
    prefixHeight: '27px'
  }
};

export const inputReset = css`
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-clear {
    display: none;
  }

  &:invalid {
    box-shadow: none;
  }

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=search] {
    -webkit-appearance: none;
  }
`;

export const getPlaceholderStyle = (color: ColourOption = ColourOption.shade40): any => css`
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${colors[color]};
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 18- */
    color: ${colors[color]};
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: ${colors[color]};
    opacity: 1;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors[color]};
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors[color]};
  }
`;

// Safari puts on some difficult to remove styles, mainly for disabled inputs
// but we want full control so need to override them in all cases
const overrideSafariDisabledStyles = `
  -webkit-text-fill-color: unset;
  -webkit-opacity: 1;
`;

export const InputWrapper = styled.div<any>`
  position: relative;

  ${({ parentWidth }): any => parentWidth ? css`width: 100%;` : ''}
`;

export const InputElement: any = styled.input`
  ${textStyles.subhead};
  color: inherit;
  cursor: inherit;
  width: 100%;
  padding: ${({ size }: any): any => `0 ${FIELD_SIZES[size].padding}`};
  padding-left: ${(props: any): any => props.hasPrefix ? '35px' : FIELD_SIZES[props.size].padding};
  padding-right: ${(props: any): any => props.maxLength
    ? `${Math.round(props.maxLength / 100 * 2) + 70}px`
    : props.hasIcon ? '45px' : FIELD_SIZES[props.size].padding};
  text-align: ${(props: any): any => props.isCentered ? 'center' : 'left'};
  height: ${({ size }: any): any => FIELD_SIZES[size].height};
  font-size: ${(props: any): any => props.hasPrefix ? '14px' : FIELD_SIZES[props.size].fontSize};
  font-family: ${fonts.secondary};
  outline: none;
  border: 2px solid ${(props: any): any => props.error ? colors.errorText : props.isValid ? colors.validationText : colors.middleGrey};
  border-radius: 4px;
  background: ${(props: any): any => props.locked ? colors.background : colors.uiFills};
  color: ${(props: any): any => props.error ? colors.errorText : props.isValid ? colors.validationText : colors.floomMidnightBlue};
  transition: border-color .2s ease-in-out;
  pointer-events: ${(props: any): any => props.locked ? 'none' : 'auto'};

  ${inputReset}

  &:disabled {
    ${overrideSafariDisabledStyles};
    cursor: not-allowed;
    background: #EFEFEF;
    border-color: #EFEFEF;
    color: ${colors.middleGrey}
  }

  &:focus {
    border-color: ${(props: any): any => props.error ? colors.errorText : props.isValid ? colors.validationText : colors.floomBlue};
  }

  ${getPlaceholderStyle()};
`;

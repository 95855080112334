import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { CurrencyService } from 'lib';

import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';

import FieldText from 'components/field-text';
import Icon from 'components/icon';

import {
  ManageOrderInputProps
} from './manage-order-input.types';

export const ManageOrderInput: FC<ManageOrderInputProps> = inject((stores: FxStores) => ({
  draftWholesaleOrderStore: stores.draftWholesaleOrderStore,
  modalStore: stores.modalStore
}))(observer(({
  draftWholesaleOrderStore,
  modalStore,
  initialValue,
  onUpdate,
  modifiedBy
}) => {
  const [inputValue, setInputValue] = useState<any>(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const merchantCurrency = draftWholesaleOrderStore!.selectedOrder!.merchant.currency;
  const currencySymbol = !!merchantCurrency ? CurrencyService.renderCurrencySymbol(merchantCurrency) : '';

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const handleReturn = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      inputRef?.current?.blur?.();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.currentTarget.value);
  };

  const handleUpdate = (e: ChangeEvent<HTMLInputElement>): void => {
    if (inputValue !== initialValue) {
      const value = (e.currentTarget.valueAsNumber || 0).toFixed(2);

      setInputValue(value);
      onUpdate(parseFloat(value));
    }
  };

  const displayModifiedBy = (): void => {
    if (!modifiedBy) return;

    modalStore!.triggerModal<ConfirmationModalData>({
      modalType: 'confirmation',
      data: {
        title: 'Last updated',
        copy: `This property has been manually edited. It was last updated by ${modifiedBy.givenName} ${modifiedBy.familyName}`,
        confirmButtonCopy: 'Okay'
      }
    });
  };

  return (
    <Flex alignItems="center">
      {!!modifiedBy && (
        <Box
          as="button"
          mr="10px"
          onClick={displayModifiedBy}
        >
          <Icon iconName="question-circle" />
        </Box>
      )}
      <Box width="150px">
        <FieldText
          ref={inputRef}
          value={inputValue}
          size="small"
          type="number"
          min={0}
          onChange={handleChange}
          onBlur={handleUpdate}
          onKeyDown={handleReturn}
          prefix={currencySymbol}
          isDisabled={draftWholesaleOrderStore!.isUpdatingOrder}
        />
      </Box>
    </Flex>
  );
}));

import { Component } from 'react';

import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import { OrdersCourier } from 'features/orders/pages/orders-courier/orders-courier';
import OrdersHome from 'features/orders/pages/orders-home';
import OrdersList from 'features/orders/pages/orders-list';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

export default class Orders extends Component {
  render(): JSX.Element {
    return (
      <AppLayout>
        {/*
        // @ts-ignore */}
        <Helmet>
          <title>Orders</title>
        </Helmet>
        <Router>
          <AuthedRoute
            path="/orders"
            component={OrdersHome}
          />
          <AuthedRoute
            path="/orders/courier/:orderNo"
            component={OrdersCourier}
          />
          <AuthedRoute
            path="/orders/list/:listOption"
            component={OrdersList}
          >
            <AuthedRoute
              path="/detail/:orderId"
              component={OrdersList}
            />
          </AuthedRoute>
          <AuthedRoute
            path="/orders/detail/:orderId"
            component={OrdersList}
          />
        </Router>
      </AppLayout>
    );
  }
}

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import {
  PlanFeature
} from 'generated-types.d';

import {
  MerchantService,
  PermissionsService,
  ProductService
} from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import ProductEditStore from 'stores/product-edit-store/product-edit-store';

import FormFieldWrapper from 'components/create-labeled-component';
import FxTextArea from 'components/field-text-area';

interface ProductInternalDescriptionProps {
  productEditStore?: ProductEditStore;
  merchantStore?: MerchantStore;
}

class ProductInternalDescription extends Component<ProductInternalDescriptionProps> {
  render(): React.ReactNode {
    const isInternalUser = PermissionsService.isInternalRole();
    const isCollectionManagerMerchant = MerchantService.hasPlanFeature(PlanFeature.CollectionManager, this.props.merchantStore!.merchant!);
    const isCollectionProduct = !!isCollectionManagerMerchant || (!!this.props.productEditStore!.oldProduct && ProductService.isTemplateProduct(this.props.productEditStore!.oldProduct!));

    if (isCollectionProduct && (isCollectionManagerMerchant || isInternalUser)) {
      return (
        <FormFieldWrapper
          title="Internal Description"
          optional={true}
        >
          <FxTextArea
            value={this.props.productEditStore!.internalDescription}
            onChange={this.props.productEditStore!.updateInternalDescription}
          />
        </FormFieldWrapper>
      );
    }

    return null;
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  merchantStore: stores.merchantStore
}))(observer(ProductInternalDescription));

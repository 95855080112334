import { gql } from '@apollo/client';

export const WHOLESALE_COLOURS = gql`
  query WholesaleColoursQuery {
    colours {
      name
      hex
    }
  }
`;

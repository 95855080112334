import styled from '@emotion/styled';
import { Box } from 'rebass';

import { borderRadius, breakpoints, colors, fontSizes, space } from 'utils/rebass-theme';

import { EntitySummarySize } from './enitity-summary';

type sizeProps = {
  imageWidth: string;
  title: string;
  subtitle: string;
  margin: string;
}

const sizes: Record<string, sizeProps> = {
  small: {
    imageWidth: '60px',
    title: `15px`,
    subtitle: `${fontSizes.small}px`,
    margin: `${space[1]}px`
  },
  medium: {
    imageWidth: '80px',
    title: `${fontSizes.medium}px`,
    subtitle: `${fontSizes.small}px`,
    margin: `${space[2]}px`
  },
  large: {
    imageWidth: '100px',
    title: `${fontSizes.xLarge}px`,
    subtitle: `${fontSizes.small}px`,
    margin: `${space[2]}px`
  }
};

export const Image = styled(Box)<{
  componentSize: EntitySummarySize;
  imageUrl: string;
}>`
  position: relative;
  overflow: hidden;
  min-width: ${({ componentSize }) => sizes[componentSize].imageWidth};
  width: ${({ componentSize }) => sizes[componentSize].imageWidth};
  height: ${({ componentSize }) => sizes[componentSize].imageWidth};
  border-radius: ${borderRadius.xLarge};
  background-color: ${colors.middleGrey};
  background-image: ${({ imageUrl }) => !!imageUrl ? `url(${imageUrl})` : 'none'};
  background-size: cover;
  background-position: center;
  margin: ${({ componentSize }) => sizes[componentSize].margin};
  margin-right: 8px;

  svg {
    display: ${({ imageUrl }) => !imageUrl ? 'block' : 'none'};
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: ${breakpoints.large}) {
    min-width: 70px;
    width: 70px;
    height: 70px;
  }
`;

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled(Box)<{
  componentSize: EntitySummarySize;
}>`
  margin-left: ${({ componentSize }) => sizes[componentSize].margin};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 7px 0;

  @media (max-width: ${breakpoints.large}) {
    flex-direction: column;
    align-items: start;
    padding: 0;
  }
`;

export const Title = styled(Box)<{
  componentSize: EntitySummarySize;
}>`
  font-size: ${({ componentSize }) => sizes[componentSize].title};
  font-weight: bold;
  line-height: 1.3;
  min-width: 140px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: ${({ componentSize }) => sizes[componentSize].margin};
  
  @media (min-width: 30em) {
    max-width: initial;
    flex: auto;
  }
  
  @media (min-width: ${breakpoints.large}) {
    margin-bottom: 0;
    margin-right: 20px;
    max-width: 327px;
  }
`;

export const SubtitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Subtitle = styled(Box)<{
  componentSize: EntitySummarySize;
}>`
  font-size: ${({ componentSize }) => sizes[componentSize].subtitle};
  margin-right: 20px;
  
  @media (max-width: ${breakpoints.large}) {
    min-width: initial;
    font-size: 12px;
    margin-right: 10px;
  }
`;

export const LastUpdatedAt = styled(Box)`
  font-size: 13px;
  color: ${colors.middleGrey};
  display: flex;
  margin-top: 3px;

  @media (max-width: ${breakpoints.medium}) {
    width: 100%;
    margin-top: 4px;
  }
`;

export const Label = styled(Box)<{
  componentSize: EntitySummarySize;
  colour: string;
}>`
  display: flex;
  font-size: 12px;
  background: ${({ colour }) => colour || colors.lightGrey};
  border-radius: ${borderRadius.medium};
  padding: ${space[1]}px ${space[2]}px;
  align-items: center;
  line-height: 1.1;
  margin-right: 8px;

  @media (min-width: ${breakpoints.medium}) {
    font-size: ${({ componentSize }) => sizes[componentSize].subtitle};
    min-width: 110px;
    justify-content: center;
  }
`;

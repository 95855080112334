import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { UserUpdateInput } from 'generated-types.d';

import { ValidationService } from 'lib';

import { AccountStoreActions } from 'stores/account/account-store';

import UserService from 'features/settings/components/account/services';

import FormFieldWrapper from 'components/create-labeled-component';
import FieldText from 'components/field-text';
import Icon from 'components/icon';
import StyledLink from 'components/styled-link/styled-link';

import SettingsTemplatePage from '../../../template/settings-template-page';
import ChangePassword from '../change-password/change-password';

import { ACCOUNT_DETAILS_FIELDS, getAccountFormValidation } from './account-details.config';
import * as Styles from './account-details.styles';
import * as Types from './account-details.types';

class AccountDetails extends Component<Types.AccountProps, Types.AccountState> {
  userService = UserService;

  state = {
    editMode: false,
    changePasswordModalOpen: false
  };

  componentDidMount(): void {
    this.userService.init();
    this.userService.populateUserDetails();
  }

  componentWillUnmount(): void {
    this.props.accountStore!.clearAccountStore();
  }

  private cancelEditMode = (): void => {
    if (this.userService.snapshotState !== this.props.accountStore!.getAccountStoreSnapshot()) {
      // TODO: clearAccountStore is a short term fix - Clubhouse 2983 - lol
      this.props.accountStore!.clearAccountStore();
      this.userService.populateUserDetails();
    }

    this.setState({
      editMode: false
    });
  };

  private handleBlurValidate = async (value: string, field: Types.AccountDetailsField): Promise<void> => {
    try {
      const validationSchema = getAccountFormValidation(this.props.accountStore!.merchant);
      const validatedFormattedValue = await ValidationService.validateField(validationSchema, value, field.key);
      this.props.accountStore!.updateBasicValue(field.key, validatedFormattedValue);
    } catch (error) {
      this.props.accountStore!.setValidationErrors(error.errors);
    }
  };

  private saveAction = async (): Promise<void> => {
    if (this.userService.snapshotState !== this.props.accountStore!.getAccountStoreSnapshot()) {
      try {
        const validationSchema = getAccountFormValidation(this.props.accountStore!.merchant);
        const validationValues = ACCOUNT_DETAILS_FIELDS
          .filter(field => !field.isLocked(this))
          .reduce((values, fieldConfig) => {
            return {
              ...values,
              [fieldConfig.key]: this.props.accountStore![fieldConfig.key]
            };
          }, {});
        const validatedUserAccount = await ValidationService.validateAll(validationSchema, validationValues);
        const updateArgs = this.buildUserUpdateArgs(validatedUserAccount);
        await this.userService.saveUserChanges(updateArgs);
        this.props.accountStore!.setValidationErrors([]);
        this.toggleEditMode();
      } catch (error: any) {
        this.props.accountStore!.setValidationErrors(error.errors);
      }
    }
  };

  private buildUserUpdateArgs(validatedUserAccount: UserUpdateInput): UserUpdateInput {
    return {
      givenName: validatedUserAccount.givenName,
      familyName: validatedUserAccount.familyName === '' ? null : validatedUserAccount.familyName,
      secondaryPhone: validatedUserAccount.secondaryPhone === '' ? null : validatedUserAccount.secondaryPhone,
      phone: validatedUserAccount.phone,
      secondaryEmail: validatedUserAccount.secondaryEmail === '' ? null : validatedUserAccount.secondaryEmail
    };
  }

  togglePasswordModal = (): void => {
    this.setState({
      changePasswordModalOpen: true
    });
  };

  private toggleEditMode = (): void => {
    this.setState({ editMode: !this.state.editMode });
  };

  private updateFieldValue = (e: React.ChangeEvent<HTMLInputElement>, field: Types.AccountDetailsField): void => {
    this.props.accountStore!.updateBasicValue(field.key, e.target.value);
  };

  private findError = (key: AccountStoreActions): string | undefined => {
    const error = ValidationService.findErrorCopy(this.props.accountStore!.validationErrors, key);

    return error?.label;
  };

  private renderEditButton = (): JSX.Element => {
    return (
      <div onClick={this.toggleEditMode}>
        {
          !this.state.editMode && (
            <Icon
              iconName="edit"
              styles={{ cursor: 'pointer' }}
            />
          )}
      </div>
    );
  };

  render(): React.ReactNode {
    return (
      <SettingsTemplatePage
        title="Account Details"
        displayEditFooter={this.state.editMode}
        cancelAction={this.cancelEditMode}
        saveAction={this.saveAction}
      >
        <Styles.SubtitleWrappers>
          <Styles.SettingsSubtitle>Your personal profile</Styles.SettingsSubtitle>
          {this.renderEditButton()}
        </Styles.SubtitleWrappers>
        <Styles.InputsWrapper>
          {
            ACCOUNT_DETAILS_FIELDS.map((field): JSX.Element => {
              return (
                <FormFieldWrapper
                  title={field.label}
                  key={field.key}
                  optional={field.isOptional}
                >
                  <FieldText
                    onChange={(e): void => {
                      this.props.accountStore!.resetErrorForField(field.key);
                      this.updateFieldValue(e, field);
                    }}
                    onBlur={(e): Promise<void> => this.handleBlurValidate(e.target.value, field)}
                    value={field.value ? field.value : `${this.props.accountStore![field.key] || ''}`}
                    placeholder={field.placeholder}
                    maxLength={field.maxLength}
                    size="normal"
                    error={field.shouldDisplayError && !!this.findError(field.key)}
                    errorMessage={this.findError(field.key)}
                    locked={field.isLocked(this)}
                    autoComplete={field.isLocked(this) ? 'off' : 'on'}
                    isReadOnly={field.isLocked(this)}
                    type={field.inputType}
                  />
                  { !!field.secondaryAction && !!field.secondaryLinkCopy && (
                    <Styles.LinkWrapper onClick={(): void => field.secondaryAction!(this)}>
                      <StyledLink>{field.secondaryLinkCopy}</StyledLink>
                    </Styles.LinkWrapper>
                  )}
                </FormFieldWrapper>
              );
            })
          }
        </Styles.InputsWrapper>
        <ChangePassword
          modalOpen={this.state.changePasswordModalOpen}
          closeModalAction={(): void => this.setState({
            changePasswordModalOpen: false
          })}
        />
      </SettingsTemplatePage>
    );
  }
}

export default inject('accountStore', 'userStore')(observer(AccountDetails));

import styled, { StyledComponent } from '@emotion/styled';
import hex2Rgba from 'hex2rgba';
import { Box } from 'rebass';

import { colors, HeaderHeight, breakpoints, space } from 'utils/rebass-theme';

export const HeaderWrapper: StyledComponent<any, any, any> = styled.div`
  display: block;

  @media (min-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const NavWrapper: any = styled(Box)`
  position: fixed;
  overflow: hidden;
  background: ${colors.uiFills};
  height: 100%;
  top: 0;
  left: 0;
  border-right: 1px solid #dedede;
  width: ${({ width }): string => width};
  transform: ${({ isMenuOpen }: any): string => isMenuOpen ? 'translateX(0)' : 'translateX(-100%)'};
  z-index: 11;
  transition: transform .1s ease-in-out;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @media (min-width: ${breakpoints.small}) {
    transform: none;
  }
`;

export const ScrollArea: StyledComponent<any, any, any> = styled.div`
  width: calc(100% + 40px);
  padding-right: 48px;
  height: calc(100% - ${HeaderHeight});
  padding: ${space[2]}px 48px 0 8px;

  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  ::-webkit-scrollbar { width: 0 !important }
  overflow: -moz-scrollbars-none;

  @media (max-width: ${breakpoints.small}) {
    padding-bottom: ${space[6]}px;
  }
`;

interface FixedWrapperProps {
  width: string;
  displayShadow: boolean;
}

export const FixedWrapper = styled.div<FixedWrapperProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${({ width }): string => width};
  padding: 0 9px;
  background: ${colors.uiFills};

  ${({ displayShadow }): any => {
    if (displayShadow) {
      return `
        border-top: 1px solid #dedede;
      `;
    }
  }}
`;

export const LogoutButton = styled.button`
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
  color: ${colors.floomMidnightBlue};
  padding: 7px 5px;
  cursor: pointer;
  transition: background .2s ease-in-out;
  border-radius: 4px;

  &:hover {
    background: ${hex2Rgba(colors.paleGrey, 0.8)};
  }
`;

export const OverlayWrapper = styled.div`
  display: block;

  @media (min-width: ${breakpoints.small}) {
    display: none;
  }
`;

import { FC, useState } from 'react';

import { Global } from '@emotion/react';
import { graphql, useStaticQuery } from 'gatsby';
import { Query } from 'gatsby-types';

import { Footer } from 'features/marketing/components/footer/footer';
import { MobileMarketingNavigation } from 'features/marketing/components/mobile-navigation/mobile-navigation';
import { MarketingNavigation } from 'features/marketing/components/navigation/navigation';

import GlobalLayout from './global-layout';
import * as Styles from './global-layout.styles';

const query = graphql`
  query NavigationPages {
    allDatoCmsPage(
      filter: {
        locale: {
          eq: "en-GB"
        }
        hideInNav: {
          ne: true
        }
      },
      sort: {
        fields: position,
        order: ASC
      }
    ) {
      edges {
        node {
          pageTitle
          slug
        }
      }
    }
  }
`;

export const MarketingLayout: FC<{ isSimplified?: boolean }> = props => {
  const [isNavOpen, toggleNavigation] = useState(false);
  const data = useStaticQuery<{ allDatoCmsPage: Query['allDatoCmsPage'] }>(query);

  return (
    <GlobalLayout>
      <Global styles={Styles.MarketingGlobal} />
      <MarketingNavigation
        data={data.allDatoCmsPage}
        isNavOpen={isNavOpen}
        onToggleNav={toggleNavigation}
      />
      <Styles.MarketingMain id="global-layout">
        {/* eslint-disable-next-line react/prop-types */}
        {props.children}
      </Styles.MarketingMain>
      <Footer />
      <MobileMarketingNavigation
        data={data.allDatoCmsPage}
        isNavOpen={isNavOpen}
        onToggleNav={toggleNavigation}
      />
    </GlobalLayout>
  );
};

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Price = styled(Flex)<any>`
  padding: ${({ isLoading }): string => isLoading ? '60px 15px 50px' : '15px 15px 20px'};
  text-align: center;
  background: ${colors.uiFills};
  border: solid 2px ${colors.shade40};
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  transition: background .2s ease-in-out;
`;

export const PlanPrice: any = styled.h1`
  ${textStyles.headline}
  margin-bottom: 15px;
  margin-top: 17px;
`;

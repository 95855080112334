import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const DeliveryDays = styled(Flex)`
  width: 100%;
  padding-bottom: 20px;
  justify-content: space-between;
`;

export const DeliveryDaySquare = styled(Flex)<any>`
  border: 2px solid ${({ selected }: any): string => selected ? colors.floomMidnightBlue : colors.shade40};
  height: 70px;
  width: 70px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { textStyles, colors, space } from 'utils/rebass-theme';

export const Divider: any = styled(Box)<any>`
  margin-bottom: 6px;
  border-bottom: 1px solid #dedede;
`;

export const Label: any = styled.p`
  ${textStyles.label};
  font-size: 11px;
  margin-top: ${space[3]}px;
  margin-bottom: 6px;
  padding: 0 ${space[2]}px;
  text-transform: uppercase;
  color: ${colors.shade40};
`;

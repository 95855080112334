import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, textStyles, breakpoints } from 'utils/rebass-theme';

export const Item: any = styled(Box)`
  padding: 20px;
  cursor: pointer;

  &:hover {
    background: ${colors.lightGrey};
  }

  + ${(): any => Item} {
    border-top: 2px solid ${colors.middleGrey};
  }

  svg {
    @media (max-width: ${breakpoints.medium}) {
      width: 20px;
      height: 20px;
    }
  }
`;

export const SearchIcon = styled(Flex)`
  padding-right: 10px;

  svg {
    path {
      fill: ${colors.middleGrey};
    }
  }
`;

export const Heading: any = styled(Box)`
  ${textStyles.footnote};

  @media (max-width: ${breakpoints.medium}) {
    margin-bottom: 15px;
  }
`;

export const Copy: any = styled(Box)`
  ${textStyles.footnote};
  display: inline;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TitleMeta = css`
  min-width: 38%;

  @media (max-width: ${breakpoints.medium}) {
    width: 100%;
    margin-bottom: 13px;
  }
`;

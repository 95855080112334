import React, { Component } from 'react';

import Cross from 'assets/icons/cross-big.svg';

import ImgixImage from '../../imgix-image';
import LoadingAnimation from '../../loading-animation/loading-animation';
import { ImageProps } from '../media-uploader.types';

import * as Styles from './media-upload-image.styles';

export default class Image extends Component<ImageProps> {
  static defaultProps = {
    params: {}
  };

  render(): React.ReactNode {
    return (
      <Styles.Content error={this.props.errorMessage}>
        <Styles.ImageContainer>
          {
            this.props.uploading || !!this.props.localSrc
              ? (
                <Styles.Image
                  uploading={this.props.uploading}
                  src={this.props.localSrc}
                  error={this.props.errorMessage}
                  alt=""
                />
              )
              : (
                <ImgixImage
                  config={{ path: this.props.src }}
                  width={170}
                  height={170}
                  params={this.props.params}
                />
              )
          }
          <LoadingAnimation
            uploading={!!this.props.uploading}
            errorMessage={this.props.errorMessage}
          />
        </Styles.ImageContainer>
        <p>{this.props.name}</p>
        <Styles.SelectedItemDeleteCross onClick={this.props.removeImage}>
          <Cross />
        </Styles.SelectedItemDeleteCross>
      </Styles.Content>
    );
  }
}

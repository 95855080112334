import React, { Component, ReactNode } from 'react';

import { Order } from 'generated-types';
import moment from 'moment';
import { Box } from 'rebass';

import { TimeService } from 'lib';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';
import { textStyles } from 'utils/rebass-theme';

import OrderHelpers from 'features/orders/helpers/order-helpers';
import { ORDER_PERMISSIONS } from 'features/orders/orders.constants';

import FieldDatePicker from 'components/field-date-picker';

import { Subheading } from '../../order-detail.styles';

import * as Types from './delivery-date.types';

export default class DeliveryDate extends Component<Types.DeliveryDateProps> {
  private getDeliveryDateStrings = (order: Order): any => {
    const orderTime = OrderHelpers.getOrderedAt(order);
    const shouldCalculate = orderTime.length && parseInt(orderTime) >= 14 && order.orderDeliveryConfig && order.orderDeliveryConfig.sameDay;
    const deliveryTime = shouldCalculate ? `10am and ${OrderHelpers.getDeliveryTimeRange(order)}pm` : '10am and 6pm';
    const deliveryDate = moment.utc(order.deliverAt).format('YYYY-MM-DD');

    return {
      date: `${TimeService.humanDateMonthYear(deliveryDate)}`,
      time: `between ${deliveryTime}`
    };
  };

  private deliveryDateStrings = this.getDeliveryDateStrings(this.props.order);

  render(): ReactNode {
    return (
      <>
        <Subheading>Delivery date</Subheading>
        <Box css={textStyles.body}>
          {this.deliveryDateStrings.date}<br />
          {this.deliveryDateStrings.time}
        </Box>
        <Box mt="10px">
          <FloomXRestrictedComponent
            restrictedRoles={ORDER_PERMISSIONS.deliverAt.restrictedRoles}
            shouldOverrideRoles={this.props.shouldOverrideRoles('deliverAt')}
          >
            <FieldDatePicker
              onChange={(day: Date): void => {
                this.props.onUpdate(moment(day).toISOString());
              }}
              value={this.props.order.deliverAt}
              inputType="button"
              disabledDays={{
                before: new Date()
              }}
            />
          </FloomXRestrictedComponent>
        </Box>
      </>
    );
  }
}

import { FC } from 'react';

import { NavbarElementProps } from 'react-day-picker/types/Props';
import { Flex, Box } from 'rebass';

import Icon from 'components/icon';

import { YearMonthForm } from './year-month-form';

export const DatePickerNavigation: FC<NavbarElementProps & { onChangeMonth: (month: Date) => void }> = ({
  month,
  localeUtils,
  onPreviousClick,
  onNextClick,
  onChangeMonth
}) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      p="20px"
    >
      <Flex
        as="button"
        onClick={(): void => onPreviousClick()}
      >
        <Icon
          iconName="arrow-small-left"
        />
      </Flex>
      <Box>
        <YearMonthForm
          date={month}
          localeUtils={localeUtils}
          onChange={onChangeMonth}
        />
      </Box>
      <Flex
        as="button"
        onClick={(): void => onNextClick()}
      >
        <Icon
          iconName="arrow-small-right"
        />
      </Flex>
    </Flex>
  );
};

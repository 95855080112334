import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const SectionHeading = styled(Box)`
  ${textStyles.footnote};
  text-transform: uppercase;
  font-weight: 400;
  color: ${colors.floomMidnightBlue};
  margin: 40px 0 20px 0;
`;

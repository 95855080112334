import { FC, ReactNode, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/react';
import { PageProps } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  Mutation,
  MutationDebug_FakeIncomingMessageArgs as MutationDebugFakeIncomingMessageArgs,
  Query
} from 'types/conversations.types';

import ToasterStore from 'stores/toaster-store/toaster-store';
import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { Card } from 'utils/css-mixins';

import PlantsIconImage from 'assets/images/wholesale/no-results.gif';

import { ConversationInput } from 'features/conversations/components/conversation-input/conversation-input';
import { FAKE_INCOMING_MESSAGE_MUTATION } from 'features/conversations/graphql/mutations/messages';
import { CONVERSATIONS_QUERY } from 'features/conversations/graphql/queries/conversation';

import DropdownNative from 'components/dropdown-native';
import { LoadingImage } from 'components/loading-image/loading-image';
import WithLoading from 'components/with-loading';

interface CreateIncomingMessageProps extends PageProps {
  toasterStore?: ToasterStore;
}

export const CreateIncomingMessage: FC<CreateIncomingMessageProps> = inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore
}))(observer(({
  toasterStore
}) => {
  const { data, loading } = useQuery<Pick<Query, 'conversations'>>(CONVERSATIONS_QUERY);
  const [sendFakeIncomingMessage] = useMutation<Pick<Mutation, 'debug_fakeIncomingMessage'>, MutationDebugFakeIncomingMessageArgs>(FAKE_INCOMING_MESSAGE_MUTATION);

  const [selectedConversationId, setConversationId] = useState('');
  const [value, setValue] = useState('');

  const handleConversationChange = (conversationId: string): void => {
    setConversationId(conversationId);
  };

  const handleMessageSubmit = async (body: string): Promise<void> => {
    try {
      if (!selectedConversationId) {
        toasterStore!.popToast(`Please select a conversation before sending a message`, ToastType.Error);

        return Promise.reject('conversation not selected');
      }

      await sendFakeIncomingMessage({
        variables: {
          input: {
            conversationId: selectedConversationId,
            body: body
          }
        }
      });

      toasterStore!.popSuccessToast('Message', 'send');
    } catch (error) {
      toasterStore!.popErrorToast(`message`, 'send');
    }
  };

  const renderNoResults = (): ReactNode => {
    return (
      <div>
        Cannot find conversations
      </div>
    );
  };

  return (
    <WithLoading
      loaderSize="small"
      marginTop="30px"
      isLoading={loading}
      hasNoResults={!loading && !data?.conversations?.length}
      renderNoResults={renderNoResults}
      renderLoading={() => (
        <LoadingImage
          text="Loading order..."
          imageSrc={PlantsIconImage}
          imageAlt="plants"
        />
      )}
    >
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          css={css`
            ${Card}
            max-width: 500px;
            width: 100%;
          `}
        >
          <Box p={4}>
            <Box
              as="h3"
              mb={3}
            >
              Send a fake message to:
            </Box>
            <DropdownNative
              id="conversation-select-list"
              fullWidth={true}
              onChange={handleConversationChange}
              selected={selectedConversationId}
              optionTitleField="title"
              optionValueField="value"
              placeholder="Select a conversation"
              options={data?.conversations?.map?.(conversation => {
                return {
                  title: conversation.name,
                  value: conversation.id
                };
              }) || []}
            />
          </Box>
          <ConversationInput
            onSubmit={handleMessageSubmit}
            value={value}
            setValue={setValue}
          />
        </Box>
      </Flex>
    </WithLoading>
  );
}));

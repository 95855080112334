import gql from 'graphql-tag';
import jwt from 'jsonwebtoken';

import {
  Mutation
} from 'generated-types.d';

import {
  Cookies,
  GraphQL,
  NavService,
  PermissionsService
} from 'lib';

export const CREATE_ORDER_SEARCH_TOKEN_FOR_MERCHANT = /* GraphQL */gql`
  mutation CreateOrderSearchTokenForMerchant(
    $merchantId: ID!
  ) {
    createOrderSearchToken(
      data: {
        id: $merchantId
      }
    )
  }
`;

export const CREATE_ORDER_SEARCH_TOKEN_FOR_ALL = /* GraphQL */gql`
  mutation CreateOrderSearchTokenForAll {
    createOrderSearchToken
  }
`;

export const COOKIE_TOKEN = 'token';

export const ORDER_SEARCH_TOKEN = 'ordersearchtoken';

export const MERCHANT_ID: string = 'MerchantId';

class Auth {
  public static removeLoginToken = (): void => {
    Cookies.remove(COOKIE_TOKEN, { path: '/' });
    Cookies.remove(MERCHANT_ID, { path: '/' });
    Auth.removeOrderSearchToken();
  };

  public static removeOrderSearchToken = (): void => {
    Cookies.remove(ORDER_SEARCH_TOKEN, { path: '/' });
  };

  public static LogoutUser = (): void => {
    Auth.removeLoginToken();
    NavService.login();
  };

  public static decryptToken = (): any => jwt.decode(Auth.getLoginToken());

  public static fetchOrderSearchToken = async (): Promise<string> => {
    try {
      const token = Auth.getOrderSearchToken();

      if (!!token) return token;

      const isInternalRole = PermissionsService.isInternalRole();
      const merchantId = isInternalRole ? Auth.getMerchantIdCookie() : Auth.getUserMerchantId();

      const query = isInternalRole && !merchantId ? CREATE_ORDER_SEARCH_TOKEN_FOR_ALL : CREATE_ORDER_SEARCH_TOKEN_FOR_MERCHANT;
      const result = await GraphQL.query<{ createOrderSearchToken: Mutation['createOrderSearchToken'] }>(query, { merchantId }, 'no-cache');

      Auth.setOrderSearchToken(result.data.createOrderSearchToken);

      return result.data.createOrderSearchToken;
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  public static getLoginToken = (): string => {
    return Cookies.get(COOKIE_TOKEN);
  };

  public static getOrderSearchToken = (): string => {
    return Cookies.get(ORDER_SEARCH_TOKEN);
  };

  public static getUserID = (): string | null => {
    const decryptToken = Auth.decryptToken();

    return decryptToken ? decryptToken.user.id : null;
  };

  public static getUserMerchantId = (): string => {
    const decryptToken = Auth.decryptToken();

    return decryptToken ? decryptToken.user.restrictedRelationshipIds.merchantId : null;
  };

  public static getUserShopId = (): string => {
    const decryptToken = Auth.decryptToken();

    return decryptToken ? decryptToken.user.restrictedRelationshipIds.shopId : null;
  };

  public static getMerchantIdCookie = (): string => {
    return Cookies.get(MERCHANT_ID);
  };

  public static setMerchantIdCookie = (merchantId: string): void => {
    Cookies.set(MERCHANT_ID, merchantId, { path: '/' });
  };

  public static setLoginToken = (token: string): void => {
    Cookies.set(COOKIE_TOKEN, token, { path: '/' });
  };

  public static setOrderSearchToken = (token: string): void => {
    Cookies.set(ORDER_SEARCH_TOKEN, token, { path: '/' });
  };

  public static isLoggedIn = (): boolean => {
    return !!Auth.decryptToken();
  };
}

export default Auth;

import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { GraphQLHelpers } from 'lib';

import { ORDER_FRAGMENT_FULL } from '../fragments/order-fragments';

export const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $orderUpdates: OrderUpdateInput!
    $orderNo: String
  ) {
    updateOrder(
      where: {
        orderNo: $orderNo
      }
      data: $orderUpdates
    ) {
      ...OrderFragmentFull
    }
  }
  ${ORDER_FRAGMENT_FULL}
`;

export const SINGLE_ORDER_QUERY = (merchantId: string = ''): any =>  gql`
  query SingleOrder(
    $orderNo: String
    ${GraphQLHelpers.addVariable(!!merchantId, 'merchantId', 'ID!')}
  ) {
    order(
      where: {
        orderNo: $orderNo
        ${GraphQLHelpers.addMerchantWhereFilter(merchantId)}
      }
    ) {
      ...OrderFragmentFull
    }
  }
  ${ORDER_FRAGMENT_FULL}
`;

export const UPDATE_NEW_STATE: DocumentNode = gql`
  mutation(
    $orderNo: String
  ) {
    updateOrder(
      where: { orderNo: $orderNo }
      data: { new: false }
    ) {
      orderNo
      new
      merchant {
        sameDayOrderPauseStatus
        sameDayOrderPauseLimit
      }
    }
  }
`;

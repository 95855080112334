import { action, observable, makeObservable, runInAction } from 'mobx';
import store from 'stores';

import {
  List,
  ListConnection,
  ListCreateInput,
  ListOrderByInput,
  ListType
} from 'generated-types.d';

import { SelectedListStoreService } from 'stores/selected-list/selected-list-store.service';

import { ListQueryVars } from 'features/lists/lists.types';
import { ListService } from 'features/lists/services';

import { NotificationType } from 'components/notification/notification.types';

export default class ListsStore {
  constructor() {
    makeObservable(this, {
      lists: observable,
      searchValue: observable,
      sortValue: observable,
      isLoadingLists: observable,
      listNotification: observable,
      fetchLists: action,
      createList: action,
      resetLists: action,
      deleteList: action,
      setListLoading: action,
      setSearchValue: action,
      handlePaginationResult: action,
      toggleActiveLists: action,
      setListSortValue: action
    });
  }

  readonly listCount: number = 200;

  public lists: ListConnection | null = null;

  public isLoadingLists: boolean = true;

  public isShowingInactiveLists: boolean = true;

  public skipCount: number = 0;

  public searchValue: string = '';

  public sortValue: ListOrderByInput = ListOrderByInput.UpdatedAtDesc;

  public listNotification: { copy: string; type: NotificationType } | null = null;

  public resetLists = (): void => {
    this.lists = null;
  }

  public fetchLists = async (): Promise<void> => {
    runInAction(() => {
      this.isLoadingLists = true;
    });

    try {
      const search: ListQueryVars = {
        merchantId: store.userStore.merchantId,
        skip: this.skipCount,
        first: this.listCount,
        search: this.searchValue,
        displayActive: this.isShowingInactiveLists,
        orderBy: this.sortValue
      };

      const lists = await ListService.fetchLists({ search });

      runInAction(() => {
        this.lists = lists;
      });
    } catch (error) {
      return Promise.reject(error);
    }

    runInAction(() => {
      this.isLoadingLists = false;
    });
  };

  public createList = async (type: ListType): Promise<List> => {
    try {
      const listCreateInput: ListCreateInput = {
        active: false,
        type: type,
        merchant: {
          connect: {
            id: store.userStore.merchantId
          }
        }
      };

      const list = await ListService.createList({ listCreateInput });

      runInAction(() => {
        this.lists?.edges.push({ node: list, cursor: list.id });
      });

      return list;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public deleteList = async ({ listId } : { listId: string }): Promise<void> => {
    try {
      await SelectedListStoreService.deleteList(listId);

      runInAction(() => {
        this.lists!.edges = this.lists!.edges.filter(edge => edge?.node.id !== listId);
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public onPaginate = (): void => {
    this.skipCount = this.skipCount + this.listCount;
  };

  public handlePaginationResult = (listsList: ListConnection): void => {
    if (!!this.lists) {
      this.lists!.aggregate = listsList.aggregate;
      this.lists!.pageInfo = listsList.pageInfo;

      this.lists!.edges = [
        ...this.lists.edges,
        ...listsList.edges
      ];
    }
  };

  public setListLoading = (isLoading = true): void => {
    this.isLoadingLists = isLoading;
  };

  public setSearchValue = (value: string): void => {
    this.searchValue = value;
  };

  public toggleActiveLists = (isShowing = !this.isShowingInactiveLists): void => {
    this.isShowingInactiveLists = isShowing;
  };

  public setListSortValue = (value: ListOrderByInput = ListOrderByInput.TitleAsc): void => {
    this.sortValue = value;
  };
}

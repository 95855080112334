import React, { ReactNode } from 'react';

import {
  HitsProvided,
  connectHits
} from 'react-instantsearch-core';
import { Flex } from 'rebass';

import { OrdersListLayout } from 'stores/orders/orders-store.types';

import {
  OrderGroupOption,
  ExtendedOrder
} from 'features/orders/orders.types';

import OrderItem from '../../order-item';
import OrderItemTable from '../../order-item/order-item-table';

interface OrderItemsProps extends Pick<HitsProvided<ExtendedOrder>, 'hits'> {
  groupName: OrderGroupOption;
  layoutOption: OrdersListLayout;
  shouldRefresh: boolean;
  onRefresh: () => void;
  renderWrapper: (children: ReactNode) => ReactNode;
}

class OrderItemsComponent extends React.Component<OrderItemsProps, any> {
  shouldComponentUpdate(nextProps: OrderItemsProps): boolean {
    let hasNewHitData = false;

    for (const [i] of this.props.hits.entries()) {
      const currentHit = this.props.hits?.[i];
      const incomingHit = nextProps.hits?.[i];

      if (currentHit?.objectID !== incomingHit?.objectID
        || currentHit?.new !== incomingHit?.new
        || currentHit?.status?.title !== incomingHit?.status?.title
        || currentHit?.orderNotes?.length !== incomingHit?.orderNotes?.length
        || currentHit?.orderNotes?.some((note, index) => incomingHit?.orderNotes?.[index]?.content !== note?.content)
      ) {
        hasNewHitData = true;

        break;
      }
    }

    return this.props.groupName !== nextProps.groupName
      || this.props.layoutOption !== nextProps.layoutOption
      || this.props.shouldRefresh !== nextProps.shouldRefresh
      || hasNewHitData
      || !this.props.hits.length && !!nextProps.hits.length
      || !!this.props.hits.length && !nextProps.hits.length;
  }

  private renderItems = (): ReactNode => {
    const LayoutComponent = this.props.layoutOption === 'grid' ? OrderItem : OrderItemTable;

    return (
      <Flex
        width="100%"
        flexWrap="wrap"
      >
        {
          this.props.hits.map(item => (
            <LayoutComponent
              key={item.objectID}
              hit={item}
              groupName={this.props.groupName}
              onRefresh={this.props.onRefresh}
            />
          ))
        }
      </Flex>
    );
  };

  render(): ReactNode {
    if (!this.props.hits.length) return null;

    return this.props.renderWrapper(this.renderItems());
  }
}

// @ts-ignore
export const OrderItems = connectHits<ExtendedOrder>(OrderItemsComponent) as React.ComponentClass<Omit<OrderItemsProps, 'hits'>>;

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  DeliveryZone,
  DeliveryCoverageUpdateInput,
  DeliveryCoverageWhereUniqueInput,
  Channel
} from 'generated-types.d';

import { PermissionsService, PeakFeatureToggle } from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';
import DeliveryService from 'features/settings/components/delivery/services';

import editInPlace from 'components/edit-in-place';
import Lozenge from 'components/lozenge';

import DeliveryZoneTags from '../delivery-zone-tags';

import * as Styles from './delivery-coverage-item.styles';
import * as Types from './delivery-coverage-item.types';

const EditInPlaceTags = editInPlace(DeliveryZoneTags);

class DeliveryCoverageItem extends Component<Types.DeliveryZoneTagsProps> {
  state = {
    isLoadingZones: false,
    hasAllZones: false
  };

  confirmDiscard?: (value?: unknown) => void;

  cancelDiscard?: () => void;

  componentDidMount = (): void => {
    this.setHasAllZones();
  };

  private setHasAllZones = (): void => {
    if (!!this.props.deliveryCoverage.deliveryZones && this.props.deliveryCoverage.deliveryZones?.length < 100) {
      this.setState({
        hasAllZones: true
      });
    }
  };

  private onTagsSubmit = async (data: Types.ZoneTag[]): Promise<any> => {
    if (!PeakFeatureToggle.isDisabledDuringPeak()) {
      const dataToSubmit: DeliveryCoverageUpdateInput = {
        deliveryZones: {
          create: data.map(zone => {
            return {
              postalCode: zone.postalCode
            };
          })
        }
      };

      const where: DeliveryCoverageWhereUniqueInput = {
        id: this.props.deliveryCoverage.id
      };

      await DeliveryService.updateDeliveryCoverage(dataToSubmit, where);
    } else {
      this.props.toasterStore!.popNotificationToast(PeakFeatureToggle.setFeatureToggleNotification());
    }
  };

  private onDiscardConfirm = async (): Promise<any> => {
    await this.onTagsSubmit([]);
    this.confirmDiscard?.();
  };

  private onLoadAllZones = async (): Promise<any> => {
    this.setState({ isLoadingZones: true });

    try {
      await DeliveryService.allZonesForCoverageArea(this.props.deliveryCoverage.id);

      this.setState({
        hasAllZones: true,
        isLoadingZones: false
      });
    } catch (error) {
      this.setState({ isLoadingZones: false });
      this.props.toasterStore!.popToast('We were unable to load all the delivery zones for this coverage area', ToastType.Error);
    }
  };

  private onDiscard = async (): Promise<void> => {
    if (!PeakFeatureToggle.isDisabledDuringPeak()) {
      const data: ConfirmationModalData = {
        title: 'Remove delivery zones',
        copy: 'Removing all delivery zones from a coverage area is an irreversible action.',
        confirmButtonCopy: 'Remove zones',
        cancelButtonCopy: 'Go back',
        errorCopy: 'We are having trouble removing the delivery zones for this coverage area.',
        confirmButtonAction: async (): Promise<any> => this.onDiscardConfirm(),
        onCancel: (): any => this.cancelDiscard?.()
      };

      this.props.modalStore?.triggerModal({
        modalType: 'confirmation',
        data: data
      });

      await new Promise((resolve, reject): any => {
        this.confirmDiscard = resolve;
        this.cancelDiscard = reject;
      });
    } else {
      this.props.toasterStore!.popNotificationToast(PeakFeatureToggle.setFeatureToggleNotification());
    }
  };

  private buildDeliveryZones = (): Types.ZoneTag[] => (this.props.deliveryCoverage.deliveryZones
    ? this.props.deliveryCoverage.deliveryZones.map((zone: DeliveryZone): Types.ZoneTag => {
      return {
        type: 'existing',
        postalCode: zone.postalCode,
        id: zone.id,
        isValid: true
      };
    }) : []).sort((a, b) => {
    if (a.postalCode < b.postalCode) return -1;

    if (a.postalCode > b.postalCode) return 1;

    return 0;
  });

  render(): ReactNode {
    const isFloomCoverage = this.props.deliveryCoverage.channel === Channel.Floom;

    return (
      <Box
        key={this.props.deliveryCoverage.id}
        mb="40px"
      >
        <Flex alignItems="center">
          <h3
            css={css`
              margin-top: 7px;
              margin-right: 12px;
            `}
          >
            {this.props.deliveryCoverage.title} - ({this.props.deliveryCoverage.label})
          </h3>
          <Box>
            <Lozenge
              copy={this.props.deliveryCoverage.channel}
              bg={isFloomCoverage ? ColourOption.lightPink : ColourOption.lightBlue}
              color={ColourOption.floomMidnightBlue}
            />
          </Box>
        </Flex>
        {!!this.props.deliveryCoverage.flcApproved && (
          <Styles.Subtitle>
            Floom.com Approved
          </Styles.Subtitle>
        )}
        <Styles.TagsWrapper>
          { !this.state.hasAllZones && (
            <Styles.TagsOverlay
              as="button"
              onClick={this.onLoadAllZones}
            >
              <span>
                {this.state.isLoadingZones ? 'Loading...' : PermissionsService.isInternalRole() ? 'Edit all' : 'View all'}
              </span>
            </Styles.TagsOverlay>
          )}
          <EditInPlaceTags
            canEdit={PermissionsService.isInternalRole()}
            onEdit={this.onTagsSubmit}
            hasAll={this.state.hasAllZones}
            onDiscard={this.onDiscard}
            data={this.buildDeliveryZones()}
            excludedButtons={['delete']}
          />
        </Styles.TagsWrapper>
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore,
  modalStore: stores.modalStore
}))(observer(DeliveryCoverageItem));

import { useEffect } from 'react';

import { NavService } from 'lib';

export const MerchantDetailController = (props: any): any => {
  useEffect(() => {
    if (props.merchantId) {
      NavService.merchantDetail(
        props.merchantId,
        true
      );
    }
  }, []);

  return null;
};

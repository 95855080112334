import styled from '@emotion/styled';

import { colors } from 'utils/rebass-theme';

const radioBackground = ({ isSelected, isDisabled }: any): string => {
  if (isSelected && !isDisabled) return colors.floomMidnightBlue;

  if (isDisabled) return colors.background;

  if (!isSelected) return colors.uiFills;

  return colors.floomMidnightBlue;
};

export const RadioInnerStyle = styled.span<any>`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: ${(props: any): string => radioBackground(props)};
  border: 4px solid ${colors.uiFills};
  transition: background .2s ease-in-out;
`;

export const RadioOuterStyle = styled.span<any>`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid ${({ isDisabled }): any => isDisabled ? colors.middleGrey
    : (props: any): any => props.error ? colors.errorText : colors.floomMidnightBlue};
  background: ${colors.uiFills};
  margin-right: 15px;
`;

import { FC, Fragment, ReactNode, useContext } from 'react';

import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';

import SelectedWholesalePreOrderStore from 'stores/selected-wholesale-pre-order/selected-wholesale-pre-order-store';

import NoResultsGif from 'assets/images/wholesale/no-results.gif';

import {
  ListCardWrapper,
  ListSectionHeading
} from 'features/lists/lists.styles';
import { SupplierCard } from 'features/suppliers/components/supplier-card/supplier-card';
import { DateCountdown } from 'features/wholesale/components/date-countdown/date-countdown';

import NoResultsGeneric from 'components/no-results-generic';

import { SINGLE_PRE_ORDER_LIST_FIELD_CONFIG } from '../single-pre-order.config';
import { SingleWholesalePreOrderContext } from '../single-pre-order.context';

import { DateCountdownWrapper } from './single-pre-order-body.styles';
import { SinglePreOrderFooter } from './single-pre-order-footer';
import { SinglePreOrderItems } from './single-pre-order-items';
import { SinglePreOrderTotals } from './single-pre-order-totals';

export const SinglePreOrderBody: FC<{
  selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
}> = inject((stores: FxStores) => ({
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
}))(observer(({
  selectedWholesalePreOrderStore
}) => {
  const totals = selectedWholesalePreOrderStore!.preOrder?.totals;
  const { isPreOrderEditable } = useContext(SingleWholesalePreOrderContext);

  if (!selectedWholesalePreOrderStore!.preOrder || !totals) return null;

  const { listType } = useContext(SingleWholesalePreOrderContext);

  const fieldConfig = SINGLE_PRE_ORDER_LIST_FIELD_CONFIG[listType];

  if (!fieldConfig) return null;

  const renderBody = (): ReactNode => {
    const hasPreOrder = !!selectedWholesalePreOrderStore!.preOrder?.id;
    const hasPreOrderItems = !!selectedWholesalePreOrderStore!.preOrder?.items?.length;

    if (hasPreOrder && !hasPreOrderItems) {
      return (
        <div>
          <NoResultsGeneric
            size="small"
            isFullWidth={true}
            image={NoResultsGif}
            heading="There are no items in your wholesale pre-order"
            copy={(
              <Fragment>
                Why not head over to your
                {' '}
                <Link to={NavPages.Lists}>
                  <strong>
                    <u>
                      lists
                    </u>
                  </strong>
                </Link>
                {' '}
                to create a new one.
              </Fragment>
            )}
          />
        </div>
      );
    }

    return (
      <Fragment>
        <SinglePreOrderItems />
        <SinglePreOrderTotals />
        <SinglePreOrderFooter />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Flex
        mt={20}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <SupplierCard
          renderSupplierName={() => selectedWholesalePreOrderStore!.preOrder?.suppliers?.[0]?.name}
        />
        {isPreOrderEditable && (
          <DateCountdownWrapper>
            <DateCountdown
              prefixCopy="You can make changes until"
              date={selectedWholesalePreOrderStore!.preOrder?.startProcessingAt}
            />
          </DateCountdownWrapper>
        )}
      </Flex>
      <ListSectionHeading>
        {selectedWholesalePreOrderStore!.preOrder.items?.length || 0} items pre-ordered
      </ListSectionHeading>
      <ListCardWrapper>
        {renderBody()}
      </ListCardWrapper>
    </Fragment>
  );
}));

import { Component, Fragment } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Heading } from 'rebass';

import {
  NavService,
  PermissionsService
} from 'lib';

import { Container } from 'utils/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

import CourierItemList from 'features/courier-bookings/components/courier-list';

import Notification from 'components/notification';
import {
  NotificationType
} from 'components/notification/notification.types';

import CourierListService from '../../services/courier-list.service';

import * as Styles from './courier-list.styles';
import * as Types from './courier-list.types';

class CourierList extends Component<Types.CourierListProps, Types.CourierListState> {
  componentDidMount(): void {
    if (!PermissionsService.isInternalRole()) {
      NavService.overview();
    }

    this.props.courierListStore!.setListLoading(true);
    CourierListService.fetchCourierBookings();
  }

  render(): JSX.Element {
    return (
      <Fragment>
        <Styles.ListHeading>
          <Container>
            <Heading
              as="h1"
              css={textStyles.headline}
            >
              Courier bookings
            </Heading>
            <Notification
              type={NotificationType.Info}
              hasIcon={true}
              hasClose={false}
              hasButton={false}
              textAlign="left"
              icon="exclamation-circle"
              iconColor={colors.floomMidnightBlue}
              copy="Courier is a deprecated service. Only floomers can see this page now."
              styles={css`
                margin-top: 10px;
              `}
            />
          </Container>
        </Styles.ListHeading>
        <CourierItemList
          location={this.props.location}
        />
      </Fragment>
    );
  }
}

export default inject('courierListStore', 'courierStore')(observer(CourierList));

import React from 'react';

import { inject, observer } from 'mobx-react';

import { Auth } from 'lib';

import * as Config from './page-corner-background.config';
import * as Styles from './page-corner-background.styles';
import * as Types from './page-corner-background.types';

class PageCornerBackground extends React.Component<Types.PageCornerBackgroundProps> {
  static defaultProps = {
    topLeft: 'top-left',
    topRight: 'top-right',
    bottomRight: 'bottom-right',
    bottomLeft: 'bottom-left',
    bottomCenter: null
  };

  getArtefact(artefactName: Types.CornerArtefact | null): React.ComponentType | null {
    if (!artefactName) return null;

    return require(`assets/icons/bubbles/${artefactName}.svg`);
  }

  render(): React.ReactNode {
    const offsetWidth = this.props.offset
      ? this.props.offset
      : this.props.navigationStore!.navWidth;

    return (
      <Styles.BackgroundWrapper
        offset={Auth.isLoggedIn() ? offsetWidth : '0'}
      >
        {Object.keys(Config.ARTEFACTS_CONFIG).map(artefact => {
          const Style = Config.ARTEFACTS_CONFIG[artefact];
          const Bubble: React.ComponentType | null = this.getArtefact(this.props[artefact]);

          if (!Bubble) return null;

          return (
            <Style key={artefact}>
              <Bubble />
            </Style>
          );
        })}
      </Styles.BackgroundWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore
}))(observer(PageCornerBackground));

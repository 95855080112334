import {
  action,
  observable,
  makeObservable,
  runInAction
} from 'mobx';

import {
  CatalogItem
} from 'generated-types.d';

import { CatalogItemService } from './catalog-item-store.service';

export default class CatalogItemStore {
  constructor() {
    makeObservable(this, {
      catalogItem: observable,
      fetchCatalogItem: action,
      resetStore: action
    });
  }

  public catalogItem: CatalogItem | null = null;

  public resetStore = (): void => {
    this.catalogItem = null;
  }

  public fetchCatalogItem = async ({
    catalogItemId,
    supplierId
  }: {
    catalogItemId: string;
    supplierId?: string;
  }): Promise<CatalogItem> => {
    try {
      const catalogItem = await CatalogItemService.fetchItem({
        catalogItemId,
        supplierId
      });

      runInAction(() => {
        this.catalogItem = catalogItem;
      });

      return catalogItem;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const TermsWrapper = styled(Box)<any>`
  max-width: 600px;
  margin: 0 auto;
`;

export const Heading = css`
  ${textStyles.h2};
`;

import { FC, Fragment, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Adjustment } from 'generated-types';
import { Box } from 'rebass';

import { CurrencyService, GraphQL } from 'lib';

import { colors } from 'utils/rebass-theme';

import { CREATE_CUSTOM_ADJUSTMENT, getData } from '../transaction-create-modal.queries';

export const CreateTransactionForm: FC<{
  orderId?: string;
  merchantId: string;
  periodId?: string;
  closeModal: () => void;
}> = ({
  orderId,
  merchantId,
  periodId,
  closeModal
}) => {
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [type, setType] = useState<string>('Credit');
  const [grossAmount, setGrossAmount] = useState<number>(0);
  const [floomRemittance, setFloomRemittance] = useState<number>(0);
  const [merchantRemittance, setMerchantRemittance] = useState<number>(0);
  const [externalFee, setExternalFee] = useState<number>(0);
  const [source, setSource] = useState<string>('Charge');
  const [reasonFreeform, setReasonFreeform] = useState<string>('');

  const { data, isLoading } = useQuery({
    queryKey: ['create-transaction', merchantId || orderId],
    queryFn: () => getData(merchantId, orderId),
    enabled: !!merchantId || !!orderId
  });

  const merchant = data?.merchant;
  const inclusiveTaxRate = (merchant?.currency === 'GBP' ? 0.2 : 0);
  const taxAmount = (() => {
    if (merchant?.currency === 'GBP') {
      return grossAmount * inclusiveTaxRate;
    }

    return 0;
  })();

  const handleCreate = async (): Promise<any> => {
    setIsSaving(true);

    try {
      const result = await GraphQL.query<{ createCustomAdjustment: Pick<Adjustment, 'id'> }>(
        CREATE_CUSTOM_ADJUSTMENT,
        {
          data: {
            type: source,
            reasonFreeform: reasonFreeform,
            merchant: {
              id: merchantId
            },
            ...(!!orderId && {
              order: {
                id: orderId
              }
            }),
            transaction: {
              source: 'Adjustment',
              remittanceType: 'Period',
              type: type,
              currency: merchant?.currency,
              grossAmount: grossAmount,
              netAmount: grossAmount,
              externalFee: externalFee,
              taxAmount: taxAmount,
              inclusiveTaxRate: inclusiveTaxRate,
              merchantRemittance: merchantRemittance - externalFee,
              floomRemittance: floomRemittance
            }
          }
        }
      );

      if (result.data?.createCustomAdjustment) {
        queryClient.invalidateQueries(['transactions', merchantId, periodId]);
        closeModal();
      }
    } catch (e) {
      setIsSaving(false);

      return Promise.reject(e);
    }
  };

  const errors = (() => {
    const messages = [];

    if (grossAmount && (floomRemittance + merchantRemittance !== grossAmount)) {
      messages.push({
        message: 'Floom remittance and merchant remittance must equal gross amount'
      });
    }

    return messages;
  })();

  const isValid = !errors.length
    && grossAmount > 0
    && (floomRemittance > 0 || merchantRemittance > 0)
    && source !== '';

  return (
    <div
      className="px-6 py-5 overflow-y-auto"
      style={{ minHeight: '948px' }}
    >
      {isLoading && (
        <div className="flex items-center justify-center">
          Loading...
        </div>
      )}
      {!isLoading && (
        <Fragment>
          {!!orderId && (
            <div className="bg-slate-100 p-4 w-100 rounded-lg text-sm mb-4">
              {data?.order?.orderDeliveryConfig?.price && (
                <Fragment>
                  <div className="mb-1">
                    Order total price: {CurrencyService.renderCurrencySymbol(merchant?.currency || 'GBP')}{data?.order?.payment?.chargedAmount}
                  </div>
                  <div className="mb-1">
                    Delivery price: {CurrencyService.renderCurrencySymbol(merchant?.currency || 'GBP')}{data?.order?.orderDeliveryConfig?.price}
                  </div>
                  <div className="mb-1">
                    Merchant remittance: {CurrencyService.renderCurrencySymbol(merchant?.currency || 'GBP')}{data?.order?.transactions?.filter(t => t.source === 'Order')[0]?.merchantRemittance}
                  </div>
                </Fragment>
              )}
            </div>
          )}
          <div className="mb-4">
            <div className="mb-3">
              <label
                htmlFor="source"
                className="block text-sm font-medium text-gray-700"
              >
                Transaction type
              </label>
              <select
                id="type"
                name="type"
                value={type}
                onChange={e => setType(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-floomYellow focus:border-floomYellow sm:text-sm rounded-md"
              >
                <option
                  selected
                  value="Credit"
                >
                  Credit
                </option>
                <option value="Debit">Debit</option>
              </select>
            </div>
            <div className="mb-3">
              <label
                htmlFor="grossAmount"
                className="block text-sm font-medium text-gray-700"
              >
                Gross amount
              </label>
              <input
                type="number"
                name="grossAmount"
                id="grossAmount"
                value={grossAmount}
                onChange={e => setGrossAmount(parseFloat(e.target.value))}
                className="mt-1 focus:ring-floomYellow focus:border-floomYellow block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="merchantRemittance"
                className="block text-sm font-medium text-gray-700"
              >
                Merchant remittance
              </label>
              <input
                type="number"
                name="merchantRemittance"
                id="merchantRemittance"
                value={merchantRemittance}
                onChange={e => setMerchantRemittance(parseFloat(e.target.value))}
                className="mt-1 focus:ring-floomYellow focus:border-floomYellow block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="floomRemittance"
                className="block text-sm font-medium text-gray-700"
              >
                Floom remittance
              </label>
              <input
                type="number"
                name="floomRemittance"
                id="floomRemittance"
                value={floomRemittance}
                onChange={e => setFloomRemittance(parseFloat(e.target.value))}
                className="mt-1 focus:ring-floomYellow focus:border-floomYellow block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="externalFee"
                className="block text-sm font-medium text-gray-700"
              >
                External fee
              </label>
              <input
                type="number"
                name="externalFee"
                id="externalFee"
                value={externalFee}
                onChange={e => setExternalFee(parseFloat(e.target.value))}
                className="mt-1 focus:ring-floomYellow focus:border-floomYellow block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="source"
                className="block text-sm font-medium text-gray-700"
              >
                  Source
              </label>
              <select
                id="source"
                name="source"
                value={source}
                onChange={e => setSource(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-floomYellow focus:border-floomYellow sm:text-sm rounded-md"
              >
                <option
                  selected
                  value="Charge"
                >
                    Charge
                </option>
                <option value="Refund">Refund</option>
                <option value="ProductUpgrade">Product upgrade</option>
                <option value="Stationery">Stationery</option>
                <option value="RedeliveryFees">Redelivery fees</option>
                <option value="PenaltyFee">Penalty fee</option>
                <option value="FloristSwap">Florist swap</option>
              </select>
            </div>
            <div className="mb-3">
              <label
                htmlFor="reason"
                className="block text-sm font-medium text-gray-700"
              >
                Reason
              </label>
              <textarea
                id="reason"
                name="reason"
                rows={3}
                value={reasonFreeform}
                onChange={e => setReasonFreeform(e.target.value)}
                className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            {errors.length > 0 && (
              <div className="mb-3 text-xs text-slate-600">
                {errors.map((e: any) => {
                  return (
                    <p
                      className="mb-2"
                      key={e.message}
                    >
                      {e.message}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <Box
            disabled={!isValid || isSaving}
            onClick={() => handleCreate()}
          >
            <button
              disabled={!isValid || isSaving}
              style={{
                backgroundColor: colors.floomMidnightBlue
              }}
              className="h-14 rounded-md sm:rounded-lg w-full text-white
                justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                disabled:opacity-30"
            >
              {isSaving ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0
                    12h4zm2 5.291A7.962 7.962 0 014 12H0c0
                    3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                <span>Create transaction</span>
              )}
            </button>
          </Box>
        </Fragment>
      )}
    </div>
  );
};

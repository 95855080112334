import styled from '@emotion/styled';

import { colors, textStyles } from 'utils/rebass-theme';

export const LoginWrapper = styled.div`
  margin: 140px auto 30px auto;
  max-width: 360px;
  position: relative;
`;

export const Error = styled.p`
  ${textStyles.footnote};
  margin: 10px 0;
  color: ${colors.floomRed};
`;

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const QuickEdit = styled(Box)`
  position: absolute;
  bottom: 0;
  z-index: 1;
  background: ${colors.uiFills};
  padding: 15px;
  width: 100%;
  box-shadow: 1px -2px 10px 1px rgb(0 0 0 / 14%);
`;

export const QuickEditHeader = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.paleGrey};
`;

export const QuickEditNotification = styled(Flex)`
  font-size: 12px;
  padding: 10px;
  line-height: 1.3;
  background: ${colors.floomCyan};
  color: ${colors.floomMidnightBlue};
  border-radius: 3px;
  margin-bottom: 18px;
`;

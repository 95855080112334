export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: any;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any;
  /** The `AWSPhone` scalar type provided by AWS AppSync, represents a valid Phone Number. Phone numbers are serialized and deserialized as Strings. Segments of the phone number may be whitespace delimited or hyphenated.  The number can specify a country code at the beginning. However, United States numbers without country codes are still considered to be valid. */
  AWSPhone: any;
};













export type Attachment = ListAttachment;

export type AttachmentInput = {
  type: AttachmentType;
  id: Scalars['String'];
};

export enum AttachmentType {
  List = 'List'
}

export type Conversation = Node & {
  __typename?: 'Conversation';
  id: Scalars['ID'];
  merchantId: Scalars['ID'];
  name: Scalars['String'];
  unreadMessagesCount?: Maybe<Scalars['Int']>;
};

export type ConversationEvent = MerchantEvent & {
  __typename?: 'ConversationEvent';
  id: Scalars['ID'];
  merchantId: Scalars['ID'];
  conversationEventType: ConversationEventType;
  conversation: Conversation;
};

export enum ConversationEventType {
  ConversationAdded = 'ConversationAdded',
  ConversationUpdated = 'ConversationUpdated',
  ConversationDeleted = 'ConversationDeleted'
}

export type ConversationWhereInput = {
  merchantId?: Maybe<Scalars['ID']>;
  conversationId?: Maybe<Scalars['ID']>;
};

export type CreateContactInput = {
  name: Scalars['String'];
  phone: Scalars['AWSPhone'];
  merchantId?: Maybe<Scalars['String']>;
};

export type CreateConversationInput = {
  contactId: Scalars['ID'];
  merchantId?: Maybe<Scalars['String']>;
};

export type CreateMerchantInput = {
  id: Scalars['ID'];
  phone: Scalars['AWSPhone'];
};

export type CreateMessageInput = {
  conversationId: Scalars['ID'];
  draftId?: Maybe<Scalars['ID']>;
  body?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<AttachmentInput>>;
  merchantId?: Maybe<Scalars['String']>;
};

export type Event = MessageEvent | ConversationEvent;

export type InternalSendEventInput = {
  merchantId: Scalars['ID'];
  payload: Scalars['String'];
};

export type InternalUpdateMessageInput = {
  merchantId: Scalars['ID'];
  conversationId: Scalars['ID'];
  payload: Scalars['String'];
};

export type ListAttachment = {
  __typename?: 'ListAttachment';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['AWSDate']>;
  items: Array<ListItem>;
};

export type ListItem = {
  __typename?: 'ListItem';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  media?: Maybe<ListItemMedia>;
  quantity?: Maybe<Scalars['Int']>;
  type: ListItemType;
};

export type ListItemMedia = {
  __typename?: 'ListItemMedia';
  title?: Maybe<Scalars['String']>;
  src: Scalars['String'];
  type: ListItemMediaType;
};

export enum ListItemMediaType {
  Image = 'Image',
  ProfileImage = 'ProfileImage',
  FloomSnap = 'FloomSnap',
  Genus = 'Genus',
  Floricode = 'Floricode'
}

export enum ListItemType {
  Flower = 'Flower',
  Sundry = 'Sundry',
  Plant = 'Plant',
  Decoration = 'Decoration',
  Custom = 'Custom'
}

export type Merchant = Node & {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  phone: Scalars['AWSPhone'];
};

export type MerchantEvent = {
  id: Scalars['ID'];
  merchantId: Scalars['ID'];
};

export type MerchantWhereInput = {
  merchantId?: Maybe<Scalars['ID']>;
};

export type Message = Node & {
  __typename?: 'Message';
  id: Scalars['ID'];
  merchantId: Scalars['ID'];
  conversationId: Scalars['ID'];
  draftId?: Maybe<Scalars['ID']>;
  messageType: MessageType;
  author: MessageAuthor;
  body?: Maybe<Scalars['String']>;
  status?: Maybe<MessageStatus>;
  attachments?: Maybe<Array<Attachment>>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export type MessageAuthor = {
  __typename?: 'MessageAuthor';
  name: Scalars['String'];
};

export type MessageEvent = MerchantEvent & {
  __typename?: 'MessageEvent';
  id: Scalars['ID'];
  merchantId: Scalars['ID'];
  messageEventType: MessageEventType;
  message: Message;
};

export enum MessageEventType {
  MessageAdded = 'MessageAdded',
  MessageUpdated = 'MessageUpdated',
  MessageDeleted = 'MessageDeleted'
}

export enum MessageStatus {
  Created = 'Created',
  Sent = 'Sent',
  Delivered = 'Delivered',
  Read = 'Read',
  Failed = 'Failed'
}

export enum MessageType {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing'
}

export type MessagesPage = {
  __typename?: 'MessagesPage';
  messages: Array<Message>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createContact: PhoneContact;
  updateContact: PhoneContact;
  deleteContact: PhoneContact;
  createConversation: Conversation;
  createMessage: Message;
  setConversationRead: Conversation;
  debug_createMerchant: Merchant;
  debug_fakeIncomingMessage: Message;
  /** @deprecated Field no longer supported */
  internal_updateMessage?: Maybe<Message>;
  internal_sendEvent?: Maybe<Event>;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID'];
  merchantId?: Maybe<Scalars['String']>;
};


export type MutationCreateConversationArgs = {
  input: CreateConversationInput;
};


export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};


export type MutationSetConversationReadArgs = {
  where: ConversationWhereInput;
};


export type MutationDebug_CreateMerchantArgs = {
  input: CreateMerchantInput;
};


export type MutationDebug_FakeIncomingMessageArgs = {
  input: CreateMessageInput;
};


export type MutationInternal_UpdateMessageArgs = {
  input: InternalUpdateMessageInput;
};


export type MutationInternal_SendEventArgs = {
  input: InternalSendEventInput;
};

export type Node = {
  id: Scalars['ID'];
};

export type PhoneContact = Node & {
  __typename?: 'PhoneContact';
  id: Scalars['ID'];
  merchantId: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['AWSPhone'];
  conversation?: Maybe<Conversation>;
};

export type Query = {
  __typename?: 'Query';
  merchant?: Maybe<Merchant>;
  contacts: Array<PhoneContact>;
  conversations: Array<Conversation>;
  messages?: Maybe<MessagesPage>;
};


export type QueryMerchantArgs = {
  where?: Maybe<MerchantWhereInput>;
};


export type QueryContactsArgs = {
  where?: Maybe<MerchantWhereInput>;
};


export type QueryConversationsArgs = {
  where?: Maybe<MerchantWhereInput>;
};


export type QueryMessagesArgs = {
  conversationId: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  merchantId?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** @deprecated Field no longer supported */
  onMessageUpdate?: Maybe<Message>;
  /** @deprecated Field no longer supported */
  onMessageUpdateForMerchant?: Maybe<Message>;
  onEvent?: Maybe<Event>;
};


export type SubscriptionOnMessageUpdateArgs = {
  merchantId: Scalars['ID'];
  conversationId: Scalars['ID'];
};


export type SubscriptionOnMessageUpdateForMerchantArgs = {
  merchantId: Scalars['ID'];
};


export type SubscriptionOnEventArgs = {
  merchantId: Scalars['ID'];
};

export type UpdateContactInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['AWSPhone']>;
  merchantId?: Maybe<Scalars['String']>;
};

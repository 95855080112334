import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { textStyles, colors } from 'utils/rebass-theme';

interface FieldWrapperProps {
  width: string;
  paddingBottom?: string;
}

export const FormBuilder = styled(Box)<any>``;

export const Title = styled(Box)<any>`
  ${textStyles.h1}
  display: ${({ exists }): string => exists ? 'block' : 'none'};
  margin-bottom: ${({ hasCopy }): string => hasCopy ? '10px' : '20px'};
`;

export const Subtitle = styled(Box)<any>`
  ${textStyles.h3}
  display: ${({ exists }): string => exists ? 'block' : 'none'};
  margin-bottom: ${({ hasCopy }): string => hasCopy ? '10px' : '30px'};
`;

export const SubCopy = styled(Box)<any>`
  ${textStyles.body}
  display: ${({ exists }): string => exists ? 'block' : 'none'};
  margin-bottom: 30px;
`;

export const OptionalLabel = styled.span<any>`
  ${textStyles.body}
  font-size: 12px;
  color: ${colors.shade40};
  margin-left: 5px;
`;

export const SingleCheckboxLabel = styled(Box)<any>`
  ${textStyles.body};
  margin-left: 15px;
  text-align: left;
  position: relative;
  display: inline-block;
  cursor: pointer;
  top: -1px;
`;

export const SingleCheckboxIcon = styled.span<any>`
  cursor: pointer;
  width: 19px;
  height: 19px;
  flex: 0 0 19px;

  > span {
    display: flex;
  }
`;

export const FormWrapper = styled(Flex)<any>`
  flex-wrap: wrap;
  margin: 0 -10px;
`;

export const SectionWrapper = styled(Box)<FieldWrapperProps>`
  width: ${({ width }): string => `${width}%`};
  padding: ${({ paddingBottom }): string => `0 10px ${paddingBottom || '40px'} 10px`};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

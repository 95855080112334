import React from 'react';

import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { Box } from 'rebass';

import { Auth, NavService, Analytics } from 'lib';

import { ResetPasswordAuth } from 'stores/account/account-store.types';

import ForgotPassword from 'features/login-signup/components/forgot-password';
import InvitationPassword from 'features/login-signup/components/invitation-password';
import LoginForm from 'features/login-signup/components/login-form';
import ResetPassword from 'features/login-signup/components/reset-password';

import Header from 'components/header';
import PageCornerBackground from 'components/page-corner-background';

import * as Styles from './login.styles';
import * as Types from './login.types';

class Login extends React.Component<Types.LoginPageProps> {
  private renderLoginComponent = (): JSX.Element => {
    switch (this.props.loginState) {
      case Types.LoginState.login:
        return <LoginForm />;

      case Types.LoginState.forgotPassword:
        return <ForgotPassword />;

      case Types.LoginState.resetPassword:
        return <ResetPassword />;

      case Types.LoginState.invitation:
        return <InvitationPassword />;

      default:
        return <LoginForm />;
    }
  };

  componentDidMount(): void {
    if (Auth.isLoggedIn()) {
      NavService.overview();
    }

    Analytics.page(Analytics.FxPageEvent.Login);
    this.setAuthParams();
  }

  private setAuthParams(): void {
    const params = queryString.parse(window.location.search);

    if (typeof params?.a === 'string' && typeof params?.i === 'string') {
      let auth: ResetPasswordAuth = {
        a: params?.a,
        i: params?.i
      };

      if (typeof params?.merchantId === 'string') {
        auth = {
          ...auth,
          merchantId: params.merchantId
        };
      }

      this.props.accountStore!.setResetPasswordAuth(auth);
    }
  }

  render(): React.ReactNode {
    if (!Auth.isLoggedIn()) {
      return (
        <Styles.LoginPageWrapper>
          <Header />
          <Box>
            <PageCornerBackground />
          </Box>
          {this.renderLoginComponent()}
        </Styles.LoginPageWrapper>
      );
    }

    return null;
  }
}

export default inject('accountStore')(observer(Login));

import styled from '@emotion/styled';
import { Flex } from 'rebass';
import { mq } from 'theme';

import { breakpoints } from 'utils/rebass-theme';

export const PageHeaderWrapper = styled.span`
  ${mq('laptop')} {
    display: none;
  };
`;

export const HeaderRight = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
  }
`;

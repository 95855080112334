import gql from 'graphql-tag';

import { MERCHANT_FULL } from '../fragments/merchant';

export const UPDATE_MERCHANT = gql`
  mutation UpdateMerchant(
    $data: MerchantUpdateInput!
    $where: MerchantWhereUniqueInput!
  ) {
    updateMerchant(
      data: $data,
      where: $where
    ) {
      ...MerchantFull
    }
  }
  ${MERCHANT_FULL}
`;

export const REINDEX_PRODUCTS = gql`
  mutation ReIndexMerchantProductsForSearch($where: MerchantWhereInput!) {
    reIndexMerchantProductsForSearch(
      where: $where
    ) {
      log
    }
  }
`;

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import {
  borderRadius,
  breakpoints,
  colors,
  textStyles,
  space
} from 'utils/rebass-theme';

export const Wrapper = styled(Flex)`
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: ${space[3]}px;
  margin: 0 auto;
  z-index: 1;
  background: ${colors.white};

  @media only screen and (max-width: ${breakpoints.small}) {
    position: fixed;
    width: 100%;
    left: 0;
    padding: 0;
  }
`;

export const Container = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  background: ${colors.uiFills};
  border-radius: ${borderRadius.large};
  width: 100%;

  @media only screen and (max-width: ${breakpoints.small}) {
    padding: ${space[3]}px ${space[3]}px;
    box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
  }
`;

export const SummaryGroup = styled(Flex)`
  flex-direction: column;
  justify-content: center;
`;

export const SubHeading = styled.span`
  ${textStyles.footnote};
  display: inline-flex;
  color: ${colors.grey};
  margin-bottom: 4px;
  font-weight: 100;
  font-size: 12px;

  @media only screen and (max-width: ${breakpoints.small}) {
    font-size: 10px;
    margin-bottom: 2px;
  }
`;

export const Heading = styled(Box)`
  ${textStyles.body};
  display: inline-flex;
  font-size: 20px;

  @media only screen and (max-width: ${breakpoints.small}) {
    font-size: 16px;
  }
`;

export const CounterDivider = styled(Flex)`
  color: ${colors.middleGrey};
  padding: 0 5px;
`;

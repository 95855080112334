import {
  FC
} from 'react';

import { useWholesaleCountdown } from './date-countdown.hooks';
import {
  Container,
  Copy
} from './date-countdown.styles';

export const DateCountdown: FC<{
  date: Date;
  prefixCopy: string;
}> = ({
  date,
  prefixCopy
}) => {
  const {
    hasNoTimeToCountdown,
    renderFullDate,
    timeOfDay
  } = useWholesaleCountdown(date);

  if (hasNoTimeToCountdown) return null;

  return (
    <Container>
      <Copy>
        {prefixCopy} <strong>{timeOfDay}</strong>{renderFullDate()}
      </Copy>
    </Container>
  );
};

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import ModalStore from 'stores/modal-store/modal-store';
import ProductEditStore from 'stores/product-edit-store/product-edit-store';

import { TagSelectionModalData } from 'features/modal-dialogue/components/modals/tag-selection-modal/tag-selection-modal.types';

import FormFieldWrapper from 'components/create-labeled-component';
import FieldTagSelection from 'components/field-tag-selection';
import { TagSelectionItem } from 'components/field-tag-selection/field-tag-selection.types';

interface ProductCategoriesProps {
  productEditStore?: ProductEditStore;
  modalStore?: ModalStore;
}

class ProductCategories extends Component<ProductCategoriesProps> {
  private buildItems = (): TagSelectionItem[] => this.props.productEditStore!.categories.map(category => ({
    title: category.title,
    id: category.id,
    isSelected: this.props.productEditStore!.selectedCategoryIds.includes(category.id)
  }));

  private onUpdateCategories = (items: TagSelectionItem[]): void => {
    const ids = items
      .filter(item => item.isSelected)
      .reduce((acc: string[], currItem) => [...acc, currItem.id], []);

    this.props.productEditStore!.setSelectedCategories(ids);
  };

  private openModal = (): void => {
    const modalData: TagSelectionModalData = {
      title: 'Add occasions',
      confirmButtonCopy: 'Add occasions',
      items: this.buildItems(),
      limitSelection: 3,
      confirmButtonAction: this.onUpdateCategories
    };

    this.props.modalStore!.triggerModal({ modalType: 'tagSelection', data: modalData });
  };

  render(): React.ReactNode {
    return (
      <FormFieldWrapper
        title="Occasion"
        optional={true}
      >
        <FieldTagSelection
          itemCopySingular="occasion"
          itemCopyPlural="occasions"
          isLoading={!this.props.productEditStore!.categories?.length}
          totalItemsCount={this.props.productEditStore!.categories?.length || 0}
          selectionLimit={3}
          items={this.buildItems().filter(item => item.isSelected)}
          onRemove={this.props.productEditStore!.deselectCategory}
          onAdd={this.openModal}
        />
      </FormFieldWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  modalStore: stores.modalStore
}))(observer(ProductCategories));

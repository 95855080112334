import React, { Component } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ProductListLayout } from 'stores/products-store';

import { Container } from 'utils/css-mixins';
import { breakpoints } from 'utils/rebass-theme';

import { SORT_LIST_OPTIONS } from 'features/products/products.constants';
import ProductServices from 'features/products/services';

import Filters from 'components/filters';
import Icon from 'components/icon';
import ListControls from 'components/list-controls';
import { ListControlConfig } from 'components/list-controls/list-controls.types';

import { getProductsFilterCheckboxList } from './product-controls.helpers';
import * as Styles from './product-controls.styles';
import * as Types from './product-controls.types';

class ProductControls extends Component<Types.ProductControlsTypes, Types.ProductControlsState> {
  ProductListService = ProductServices.ProductListService;

  state = {
    filtersVisible: false
  };

  private onSearch = (): void => {
    this.props.productsStore!.resetProductList();
    this.ProductListService.fetchAllProducts();
  };

  private onClearSearch = (): void => {
    this.ProductListService.updateSearchValue('');
    this.onSearch();
  };

  private onLayoutChange = (layoutOption: ProductListLayout): void => {
    this.ProductListService.updateLayout(layoutOption);
  };

  private onListReorder = (orderType: string): void => {
    this.ProductListService.updateOrdering(orderType);
  };

  private onActiveToggle = (): void => {
    this.ProductListService.toggleInactiveProducts();
  };

  private onToggleFilters = (): void => {
    this.setState(state => ({ filtersVisible: !state.filtersVisible }));
  };

  private onClearFilters = (): void => {
    this.ProductListService.resetFilters();
  };

  private calculateSelectedFilters = (): number => {
    return Object.values(this.props.productsStore!.filters).reduce((acc, curr) => curr.length + acc, 0);
  };

  private buildConfig = (): ListControlConfig => [
    {
      controlOptionType: 'searchField',
      name: 'search',
      alignment: 'left',
      placeholder: 'Search',
      onSearch: this.onSearch,
      onChange: this.ProductListService.updateSearchValue,
      value: this.props.productsStore!.searchValue,
      onClear: this.onClearSearch
    },
    {
      controlOptionType: 'toggle',
      name: 'toggle',
      label: 'Show inactive',
      alignment: 'left',
      controlValue: true,
      isChecked: this.props.productsStore!.showInactiveProducts,
      onChange: this.onActiveToggle
    },
    {
      controlOptionType: 'custom',
      name: 'filters',
      alignment: 'right',
      content: (
        <Styles.FiltersToggle
          onClick={this.onToggleFilters}
          as="button"
          alignItems="center"
        >
          <Box pb="3px">Filters</Box>
          <Styles.FilterCount>{this.calculateSelectedFilters()}</Styles.FilterCount>
          <Icon iconName={this.state.filtersVisible ? 'minus-small' : 'plus-small'} />
        </Styles.FiltersToggle>
      ),
      wrapperStyles: css`
        @media (min-width: ${breakpoints.large}) {
          justify-self: end;
        }
      `
    },
    {
      controlOptionType: 'dropdown',
      name: 'sort',
      alignment: 'right',
      fullWidth: true,
      id: 'product-list-sort',
      selected: this.props.productsStore!.orderBy,
      options: SORT_LIST_OPTIONS,
      optionTitleField: 'title',
      optionValueField: 'key',
      onChange: this.onListReorder,
      wrapperStyles: css`
        @media (min-width: ${breakpoints.large}) {
          justify-self: end;
        }
      `
    },
    {
      controlOptionType: 'layoutToggle',
      name: 'layout',
      alignment: 'right',
      layoutOption: this.props.productsStore!.layoutOption,
      onChange: this.onLayoutChange,
      wrapperStyles: css`
        justify-self: end;
      `
    }
  ];

  private buildAppliedFilters = (): { [key: string]: boolean } => ({
    channels: !!this.props.productsStore!.filters.channels.length,
    type: !!this.props.productsStore!.filters.types.length,
    occasion: !!this.props.productsStore!.filters.categories.length
  });

  render(): React.ReactNode {
    const filtersApplied = this.buildAppliedFilters();

    return(
      <Box
        mb={this.state.filtersVisible ? '20px' : 0}
      >
        <Container>
          <ListControls
            config={this.buildConfig()}
            containerStyles={Styles.Wrapper}
          />
          <Filters
            isVisible={this.state.filtersVisible}
            clearFilters={this.onClearFilters}
            totalSelectedFilters={this.calculateSelectedFilters()}
          >
            {
              getProductsFilterCheckboxList(
                {
                  title: 'Type',
                  type: 'standard',
                  hasFiltersApplied: filtersApplied['type'],
                  isLoading: false
                },
                {
                  items: this.props.productsStore!.productTypes,
                  selectedItems: this.props.productsStore!.filters.types,
                  onChange: this.ProductListService.updateSelectedTypes,
                  checkboxStyles: Styles.Checkbox
                }
              )
            }
            {
              getProductsFilterCheckboxList(
                {
                  title: 'Channels',
                  type: 'channel',
                  hasFiltersApplied:
                  filtersApplied['channels'],
                  isLoading: false
                },
                {
                  items: this.props.productsStore!.productChannels,
                  selectedItems: this.props.productsStore!.filters.channels,
                  onChange: this.ProductListService.updateSelectedChannels,
                  checkboxStyles: Styles.Checkbox
                }
              )
            }
            {
              getProductsFilterCheckboxList(
                {
                  title: 'Occasion',
                  type: 'standard',
                  hasFiltersApplied: filtersApplied['occasion'],
                  isLoading: false
                },
                {
                  items: this.props.productsStore!.productCategories,
                  selectedItems: this.props.productsStore!.filters.categories,
                  onChange: this.ProductListService.updateSelectedCategories,
                  checkboxStyles: Styles.Checkbox
                }
              )
            }
          </Filters>
        </Container>
      </Box>
    );
  }
}

export default inject('productsStore')(observer(ProductControls));

import styled from '@emotion/styled';

export const Container = styled.div`
  max-width: 42em;
`;

export const ContentBlock = styled.div`
  margin-bottom: 40px;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
`;

export const LargeParagraph = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 40px;
`;

export const Header = styled.h2`
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 24px;
`;

export const Heading = styled.h3`
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 5px;
`;

export const Link = styled.span`
  text-decoration: underline;
`;

import { action, observable, set, makeObservable } from 'mobx';

import { PaymentConnection, PaymentOrderByInput } from 'generated-types.d';

export default class PaymentsStore {
  constructor() {
    makeObservable(this, {
      merchantPayments: observable,
      isLoadingMerchantPayments: observable,
      merchantPaymentsCount: observable,
      merchantPaymentsIncrement: observable,
      isPaginating: observable,
      merchantPaymentsSort: observable,
      merchantPaymentsSearch: observable,
      setMerchantPayments: action,
      setMerchantPaymentsSort: action,
      setMerchantPaymentSearch: action,
      incrementPagination: action,
      paginateMerchantPayments: action,
      toggleIsPaginating: action,
      resetMerchantPayments: action,
      clearPaymentsStore: action
    });
  }

  public merchantPayments: PaymentConnection | null = null;

  public merchantPaymentsCount: number = 0;

  public merchantPaymentsIncrement: number = 12;

  public merchantPaymentsSort: PaymentOrderByInput = PaymentOrderByInput.UpdatedAtDesc;

  public merchantPaymentsSearch: string = '';

  public isLoadingMerchantPayments: boolean = true;

  public isPaginating: boolean = false;

  public setMerchantPayments = (merchantPayments: PaymentConnection): void => {
    this.merchantPayments = merchantPayments;
    this.incrementPagination();
    this.isLoadingMerchantPayments = false;
  };

  public setMerchantPaymentsSort = (sortBy: PaymentOrderByInput): void => {
    this.merchantPaymentsSort = sortBy;
  };

  public setMerchantPaymentSearch = (value: string): void => {
    this.merchantPaymentsSearch = value;
  };

  public paginateMerchantPayments = (merchantPayments: PaymentConnection): void => {
    this.incrementPagination();
    set(this.merchantPayments!.pageInfo, 'hasNextPage', merchantPayments.pageInfo.hasNextPage);

    set(this.merchantPayments!.edges, [
      ...this.merchantPayments!.edges,
      ...merchantPayments.edges
    ]);
  };

  public incrementPagination = (): void => {
    this.merchantPaymentsCount = this.merchantPaymentsCount + this.merchantPaymentsIncrement;
  };

  public toggleIsPaginating = (): void => {
    this.isPaginating = !this.isPaginating;
  };

  public resetMerchantPayments = (): void => {
    this.merchantPaymentsCount = 0;
    this.merchantPayments = null;
  };

  public clearPaymentsStore = (): void => {
    this.isLoadingMerchantPayments = true;
    this.merchantPayments = null;
  };
}

import { gql } from '@apollo/client';

import { GraphQLHelpers } from 'lib';

/* eslint-disable */
export const PRODUCT_STOCK_QUERY = (vars: any): any => {
  return gql`
    query ProductTypes(
      $outOfStock: Int!
      $lowStockMax: Int!
      ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
    ) {
    active: productsConnection(
      first: 1
      where: {
        ${GraphQLHelpers.searchableContains(vars.search)}
        ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}

        ${ vars.isCollectionSeller
          ? `
            collectionSellerProductConfigs_some: {
              merchant: {
                id: "${vars.merchantId}"
              }
              active: true
            }
          `
          : `
            active: true
          `
        }
      }
    ) {
      aggregate {
        count
      }
    }
    outOfStock: productsConnection(
      first: 1
      where: {
        ${ vars.isCollectionSeller
          ? `
            collectionSellerProductConfigs_some: {
              merchant: {
                id: "${vars.merchantId}"
              }
              productVariationConfigs_some: {
                stock: $outOfStock
                sourceProductVariation: {
                  default: true
                }
              }
            }
          `
          : `
            variations_some: {
              stock: $outOfStock
              default: true
            },
          `
        }
        ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
        ${GraphQLHelpers.searchableContains(vars.search)}
      }
    ) {
      aggregate {
        count
      }
    }
    lowStock: productsConnection(
      first: 1
      where: {
        ${ vars.isCollectionSeller
          ? `
            AND: [
              {
                collectionSellerProductConfigs_some: {
                  merchant: {
                    id: "${vars.merchantId}"
                  }
                  productVariationConfigs_some: {
                    stock_gt: 0
                    sourceProductVariation: {
                      default: true
                    }
                  }
                }
              }
              {
                collectionSellerProductConfigs_some: {
                  merchant: {
                    id: "${vars.merchantId}"
                  }
                  productVariationConfigs_some: {
                    stock_lte: $lowStockMax
                    sourceProductVariation: {
                      default: true
                    }
                  }
                }
              }
            ],
          `
          : `
            AND: [
              {
                variations_some: {
                  stock_gt: 0
                  default: true
                }
              }
              {
                variations_some: {
                  stock_lte: $lowStockMax
                  default: true
                }
              }
            ],
          `
        }
        ${GraphQLHelpers.searchableContainsValues(vars.search)}
        ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
      }
    ) {
      aggregate {
        count
      }
    }
  }`;
};
/* eslint-enable */

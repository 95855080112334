import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles, colors } from 'utils/rebass-theme';

import { StyledRadioItem } from '../radio-item/radio-item.styles';

const isVertical = (orientation: string): boolean => orientation === 'vertical';

export const StyledDefaultItem = styled(Flex)<any>`
  align-items: flex-start;

  ${(): any => StyledRadioItem} + ${(): any => StyledRadioItem} & {
    padding: ${({ orientation }: any): string => isVertical(orientation) ? '14px 0 0 0' : '0 0 0 40px'};
  }
`;

export const RadioLabel: any = styled.span`
  ${textStyles.body};
  font-size: 16px;
  top: -2px;
  position: relative;
  user-select: none;
  color: ${({ isDisabled: id }: any): any => id
    ? colors.middleGrey
    : ({ isError }: any): string => isError ? colors.errorText : colors.floomMidnightBlue};
`;

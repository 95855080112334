import { ApolloError, FetchResult } from '@apollo/client';
import {
  CourierDeliveryTimeSlotInput,
  CourierBookingResponse,
  AvailableCourierDeliveryTimeSlot
} from 'generated-types';
import _get from 'lodash/get';
import moment from 'moment';
import store from 'stores';

import { GraphQL } from 'lib';

import {
  BOOK_ORDER_DELIVERY_SLOT
} from 'features/orders/graphql/mutations/book-delivery';

import {
  CANCEL_COURIER_BOOKING
} from '../../../modal-dialogue/components/modals/courier-cancel-confirmation-modal/graphql/queries/courier-queries';
import {
  CAN_BOOK_COURIER_BOOKING,
  CAN_CANCEL_COURIER_BOOKING
} from '../../../modal-dialogue/components/modals/courier-status-modal/graphql/queries/courier-queries';

class CourierAPIService {
  private toasterStore = store.toasterStore;

  public cancelCourierBooking = async (bookingId: string): Promise<any> => {
    return GraphQL.mutate(CANCEL_COURIER_BOOKING({ bookingId }), { bookingId })
      .then((results: any): any => results.data)
      .catch(error => {
        this.toasterStore.popErrorToast('your courier booking\'s status', 'change');
        Promise.reject(error);
      });
  };

  public canCancelCourierBooking = async (bookingId: string): Promise<any> => {
    return GraphQL.query(CAN_CANCEL_COURIER_BOOKING({ bookingId }), { bookingId })
      .then(({ data }: any): any => {
        return {
          response: data.canCancelCourierBooking.response,
          payload: _get(data, 'canCancelCourierBooking.payload', { cancellationFee: '' })
        };
      })
      .catch(error => {
        this.toasterStore.popErrorToast('courier booking', 'update');
        Promise.reject(error);
      });
  };

  public canBookCourierBooking = async (orderNo: string): Promise<any> => {
    return GraphQL.query(CAN_BOOK_COURIER_BOOKING({ orderNo }), { orderNo })
      .then((results: any): any => results.data.canBookCourierBooking.response)
      .catch((error: ApolloError) => Promise.reject(error));
  };

  public bookOrderDeliverySlot = async (
    deliverySlot: AvailableCourierDeliveryTimeSlot,
    orderNo: string
  ): Promise<any>  => {
    const timeslot: Omit<CourierDeliveryTimeSlotInput, 'courierCode'> = {
      courierId: deliverySlot.courierId,
      courierRef: deliverySlot.courierRef,
      deliveryDate: deliverySlot.deliveryDate,
      pickupDate: moment(deliverySlot.pickupDate).add(deliverySlot.pickupMaxDelta || 0, 'm').toISOString(),
      price: deliverySlot.price,
      currency: deliverySlot.currency.toString(),
      courierName: deliverySlot.courierName,
      transportType: deliverySlot.transportType
    };

    return GraphQL.mutate(BOOK_ORDER_DELIVERY_SLOT, { timeslot, orderNo })
      .then(({ data }: FetchResult<{ createCourierBooking: CourierBookingResponse }>): any => {
        const { response } = data!.createCourierBooking;

        if (response === 'booked') {
          return Promise.resolve();
        }

        return Promise.reject(response);
      })
      .catch((error: ApolloError) => {
        return Promise.reject(error);
      });
  };
}

export default new CourierAPIService();

import React from 'react';

import { RouteComponentProps } from '@reach/router';

import { Auth, NavService } from 'lib';

import MaintenancePage from 'features/maintenance-page';

interface RouterPageProps extends RouteComponentProps {
  component: React.ComponentType<any>;
}

export const Route = ({ component: Component, ...rest }: RouterPageProps): JSX.Element => {
  return <Component {...rest} />;
};

/**
 * Want to be able to set the props as any, as rest should be able to support any combination of props to pass down for route context
 */
export const AuthedRoute = ({ component: Component, ...rest }: RouterPageProps & any): JSX.Element | null => {
  if (!Auth.isLoggedIn()) {
    Auth.removeLoginToken();

    if (typeof window !== 'undefined') {
      NavService.login();
    }

    return null;
  }

  if (process.env.FX_UNDER_MAINTENANCE === 'true') {
    return (
      <MaintenancePage />
    );
  }

  return (
    <Component {...rest}>
      {rest.children}
    </Component>
  );
};

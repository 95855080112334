import React, { ReactNode } from 'react';

import moment from 'moment';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import { CountdownProps } from 'components/countdown-clock/countdown-clock.types';

import {
  DeliveryCutOff,
  DeliveryDate,
  SupplierCardContainer,
  SupplierDelivery,
  SupplierTitle
} from './wholesale-shop-supplier-card.styles';

interface WholesaleShopSupplierCardProps {
  name: string;
  id: string;
  deliveryDate: string | undefined;
  countDownProps: CountdownProps | undefined;
}

export const WholesaleShopSupplierCard: React.FC<WholesaleShopSupplierCardProps> = ({
  name,
  id,
  deliveryDate,
  countDownProps
}) => {
  const {
    flags: {
      multiSupplier
    }
  } = useFeatureFlags();

  if (!multiSupplier.isActive) return null;

  const renderSupplierDeliveryInfo = (): ReactNode => {
    if (!deliveryDate || !countDownProps) return;

    const deliveryCutOffDate = moment(deliveryDate);

    return (
      <SupplierDelivery>
        Order within <DeliveryCutOff color={countDownProps.color}>{countDownProps.copy}</DeliveryCutOff> for delivery on <DeliveryDate>{deliveryCutOffDate.format('Do MMM')}</DeliveryDate>
      </SupplierDelivery>
    );
  };

  return (
    <SupplierCardContainer key={id}>
      <SupplierTitle>
        {name}
      </SupplierTitle>
      {renderSupplierDeliveryInfo()}
    </SupplierCardContainer>
  );
};

import {
  FC,
  Fragment,
  useRef
} from 'react';

import { Global } from '@emotion/react';
import pluralize from 'pluralize';
import {
  connectStats,
  StatsProvided
} from 'react-instantsearch-core';
import { Box } from 'rebass';

import { TEST_IDS } from 'utils/test/data-test-ids';

import { SidebarStyles } from 'features/modal-dialogue/modal-dialogue.styles';

import Button from 'components/button';
import GenericModal from 'components/generic-modal';

import {
  Container,
  ViewResultsWrapper
} from './catalog-filters-modal.styles';
import {
  CatalogFiltersModalProps
} from './catalog-filters-modal.types';
import {
  CatalogFiltersBody
} from './components/catalog-filters-body/catalog-filters-body';
import {
  FiltersModalHeader
} from './components/catalog-filters-header/catalog-filters-header';

const ViewResults = connectStats(({
  nbHits,
  handleSelect
}: StatsProvided & {
  handleSelect: () => void;
}) => {
  return (
    <ViewResultsWrapper
      as="button"
      data-testid={TEST_IDS.WholesaleShop.modal.close}
      onClick={handleSelect}
    >
      <Box p="10px">
        <Button
          copy={`View ${nbHits} ${pluralize('result', nbHits)}`}
        />
      </Box>
    </ViewResultsWrapper>
  );
});

export const CatalogFiltersModal: FC<CatalogFiltersModalProps> = ({
  closeModal,
  isOpen,
  refinementsState,
  selectedCategory,
  setSelectedCategory
}) => {
  const itemContainerRef = useRef<HTMLUListElement>(null);

  return (
    <Fragment>
      <Global styles={SidebarStyles('catalog-filters-')} />
      <GenericModal
        title="Filter the wholesale catalog"
        closeModal={closeModal}
        modalOpen={isOpen}
        shouldHideHeader={true}
        shouldHideFooter={true}
        hasFooterBorder={false}
        hasOverflow={false}
        width={350}
        contentLayoutType="full-height"
        overlayClassName={{
          base: 'catalog-filters-sidebar__OverlaySlide',
          afterOpen: 'catalog-filters-sidebar__OverlaySlide--after-open',
          beforeClose: 'catalog-filters-sidebar__OverlaySlide--before-close'
        }}
        contentClassName={{
          base: 'catalog-filters-sidebar__ContentSlide',
          afterOpen: 'catalog-filters-sidebar__ContentSlide--after-open',
          beforeClose: 'catalog-filters-sidebar__ContentSlide--before-close'
        }}
        innerContentStyles={{
          paddingBottom: '0'
        }}
        contentTransform=""
        innerComponent={(
          <Container ref={itemContainerRef}>
            <CatalogFiltersBody
              refinementsState={refinementsState}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              renderHeader={() => {
                return (
                  <FiltersModalHeader />
                );
              }}
            />
            <ViewResults
              handleSelect={closeModal}
            />
          </Container>
        )}
      />
    </Fragment>
  );
};

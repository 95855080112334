import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, space } from 'utils/rebass-theme';

export const ListCellSubtitleItem = styled(Box)<{ isBold: boolean }>`
  font-weight: ${({ isBold }): number => isBold ? 800 : 300};
  padding-left: ${space[2]}px;
  padding-right: ${space[2]}px;
  border-right: solid 1px ${colors.grey};

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    border-right: none;
  }
`;

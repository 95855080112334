import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Flex, Box } from 'rebass';

import { colors, fonts } from 'utils/rebass-theme';

const ItemBorder = css`
  border-top: 1px solid ${hex2rgba(colors.floomMidnightBlue, .2)};
`;

export const ItemTitleRow = css`
  padding: 20px;
  background: ${colors.uiFills};
  cursor: pointer;
`;

export const ItemBodyRow: any = styled(Flex)`
  ${ItemBorder};
  padding: 20px;
  background: ${colors.lightGrey};

  > * + * {
    padding-left: 40px;
  }
`;

export const ItemInput = css`
  border: 1px solid ${colors.floomMidnightBlue};
  border-radius: 5px;
  padding: 3px 10px;
  margin-left: 9px;
  width: 85px;
`;

export const Loading = styled.div`
  width: 100%;
  text-align: center;
  padding: 50px;
  border-radius: 5px;
  background: #efefef;
`;

export const ItemBody: any = styled.div`
  display: ${(props: any): any => props.isOpen ? 'block' : 'none'};
`;

export const DeliveryZoneItem: any = styled(Box)`
  + ${(): any => DeliveryZoneItem} {
    margin-top: 15px;
  }
`;

export const FieldLabel = styled.span`
  font-family: ${fonts.secondary};
  font-size: 14px;
  color: ${hex2rgba(colors.floomMidnightBlue, 0.4)};
  font-weight: bold;
  margin-right: 10px
`;

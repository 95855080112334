import { FC } from 'react';

import { SerializedStyles } from '@emotion/react';

import * as Styles from './card.styles';

type CardProps = {
  children: React.ReactNode;
  customStyles?: SerializedStyles;
}

export const Card: FC<CardProps> = ({ children, customStyles }) => {
  return (
    <Styles.Card
      customStyles={customStyles}
    >
      {children}
    </Styles.Card>
  );
};

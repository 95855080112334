import { Dispatch, FC, memo, SetStateAction } from 'react';

import { Link } from 'gatsby';
import { Query } from 'gatsby-types';
import { NavPages } from 'global.types';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';
import { TEST_IDS } from 'utils/test/data-test-ids';

import Logo from 'components/logo';

import * as Styles from './navigation.styles';

const navItemSpacing = '1.5em';

interface MarketingNavProps {
  data: Query['allDatoCmsPage'];
  isNavOpen: boolean;
  onToggleNav: Dispatch<SetStateAction<boolean>>;
}

export const MarketingNavigation: FC<MarketingNavProps> = memo(({
  data,
  isNavOpen,
  onToggleNav
}) => {
  const handleNavToggle = (): void => {
    onToggleNav(state => !state);
  };

  return (
    <Styles.NavContainer>
      <Styles.HamburgerWrapper
        as="button"
        onClick={handleNavToggle}
        data-testid={TEST_IDS.Navigation.desktop.hamburgerMenu}
      >
        <Styles.LineSpin
          isActive={isNavOpen}
          color={colors.floomMidnightBlue}
        />
      </Styles.HamburgerWrapper>
      <Styles.Logo>
        <Link
          to="/"
          data-testid={TEST_IDS.Navigation.desktop.homeLink}
        >
          <Logo
            width="100%"
            height="auto"
            color={colors.floomMidnightBlue}
          />
        </Link>
      </Styles.Logo>
      <Flex alignItems="center">
        {data.edges.map(({ node }: any) => (
          <Box
            key={node.slug}
            pl={navItemSpacing}
            pr={navItemSpacing}
            css={Styles.HideOnSmall}
          >
            <Link to={`/${node.slug}`}>
              <Styles.Link>
                {node.pageTitle}
              </Styles.Link>
            </Link>
          </Box>
        ))}
        <Box key="get-started">
          <Link
            to="/apply"
            data-testid={TEST_IDS.Navigation.getStartedButton}
          >
            <Styles.Button>
              Get started
            </Styles.Button>
          </Link>
        </Box>
        <Box
          key="login"
          css={Styles.HideOnSmall}
          pl={navItemSpacing}
        >
          <Link
            to={NavPages.Login}
            data-testid={TEST_IDS.Navigation.loginLink}
          >
            <Styles.Link>
              Login
            </Styles.Link>
          </Link>
        </Box>
      </Flex>
    </Styles.NavContainer>
  );
});

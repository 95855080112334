import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios';

export default class ApiService {
  public static get(url: string, requestConfig: AxiosRequestConfig = {}): AxiosPromise {
    return axios.get(url, requestConfig);
  }

  public static post(url: string, data: Record<string, any>, requestConfig: AxiosRequestConfig = {}): AxiosPromise {
    return axios.post(url, data, requestConfig);
  }

  public static throwGenericError(error: AxiosError): void {
    if (error.response) {
      throw new Error(`HTTP error ${error.response.status} (${error.response.statusText})`);
    }

    throw new Error('A HTTP Error has occured');
  }
}

import React, { FC, Fragment } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex } from 'rebass';

import {
  UserRole,
  WholesaleOrder,
  WholesaleOrderStates
} from 'generated-types.d';

import {
  NavService,
  TimeService
} from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import WholesaleOrdersStore from 'stores/wholesale/wholesale-orders-store/wholesale-orders-store';

import {
  colors,
  textStyles
} from 'utils/rebass-theme';
import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import {
  WholesaleCheckoutHelpers
} from 'features/wholesale/helpers/wholesale-helpers';

import { EntityListRow } from 'components/entity-list/entity-list.styles';
import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType } from 'components/entity-list/table-layout-entity.types';
import Icon from 'components/icon';
import Lozenge from 'components/lozenge';

import { getPaymentStatusConfig } from '../../helpers/wholesale-helpers/wholesale-helpers';
import {
  getOrderDeliveryCost,
  getTotal
} from '../order-card/utils/order-card.utils';

export interface OrderCardTableProps {
  order: WholesaleOrder;
  wholesaleOrdersStore?: WholesaleOrdersStore;
  merchantStore?: MerchantStore;
}

const OrderCardTable: FC<OrderCardTableProps> = ({
  order
}) => {
  const deliveryCharge = getOrderDeliveryCost(order);
  const taxAmount = WholesaleCheckoutHelpers.orderTaxValue(order);
  const numberOfItems = WholesaleCheckoutHelpers.countOrderItems(order.items);
  const total = getTotal(order);
  const paymentStatusConfig = getPaymentStatusConfig(order);
  const merchantCurrency = order?.merchant?.currency;

  const tableLayoutConfig = [
    {
      cellType: CellType.Custom,
      restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
      flexGrow: '1',
      flexBasis: '140px',
      customInnerElement: (
        <Fragment>
          {order.merchant.title}
        </Fragment>
      )
    },
    {
      cellType: CellType.Custom,
      flexGrow: '1',
      flexBasis: '50px',
      customInnerElement: (
        <Fragment>
          {numberOfItems}
        </Fragment>
      )
    },
    {
      cellType: CellType.Text,
      flexGrow: '1',
      flexBasis: '50px',
      props: {
        text: total ? WholesaleCheckoutHelpers.renderWholesalePrice(total + deliveryCharge + taxAmount, merchantCurrency) : '-'
      }
    },
    {
      cellType: CellType.Custom,
      flexGrow: '1',
      flexBasis: '90px',
      customInnerElement: (
        <Fragment>
          {!!order.deliveryDetails?.length ? TimeService.humanDateMonthYear(moment(order.deliveryDetails![0].deliveryDate).utc()) : '-'}
        </Fragment>
      )
    },
    {
      cellType: CellType.Custom,
      flexGrow: '1',
      flexBasis: '140px',
      isVisible: (order.status !== WholesaleOrderStates.Open),
      customInnerElement: (!!paymentStatusConfig) ? (
        <Lozenge
          copy={paymentStatusConfig?.value}
          color={ColourOption.floomMidnightBlue}
          bg={paymentStatusConfig?.bg || ColourOption.lightGrey}
        />
      ) : (
        <Fragment>
          --
        </Fragment>
      )
    }
  ];

  return (
    <EntityListRow
      key={order.id}
      onClick={() => NavService.wholesaleOrderDetail(order.id)}
      style={{
        width: '100%',
        cursor: 'pointer'
      }}
    >
      <Flex alignItems="center">
        <Flex
          width="110px"
          height="75px"
          alignItems="stretch"
          justifyContent="center"
          flexDirection="column"
          css={css`
            background-color: ${colors.lightGrey};
            text-align: center;
          `}
        >
          <Icon
            iconName="wholesale"
            pathFill={colors.floomMidnightBlue}
            styles={css`
              width: 20px;
              margin: 0 auto;
            `}
          />
          <Box css={textStyles.body}>
            {order.wholesaleOrderRef?.slice(0, 7)}
          </Box>
        </Flex>
        <Box
          width="100%"
          p="10px"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
          >
            <TableLayoutEntity config={tableLayoutConfig} />
          </Flex>
        </Box>
      </Flex>
    </EntityListRow>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore,
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  merchantStore: stores.merchantStore
}))(observer(OrderCardTable));

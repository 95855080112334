import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Content = styled(Flex)`
  width: 100%;
  justify-content: space-evenly;
  padding-bottom: 40px;
`;

export const DeleteValidations = styled(Flex)`
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${colors.middleGrey};
  justify-content: flex-end;
`;

export const DeleteValidationText = styled.div`
  ${textStyles.subhead};
  text-align: right;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: underline;
`;

import {
  ApolloClient,
  ApolloQueryResult,
  createHttpLink,
  FetchPolicy,
  FetchResult,
  OperationVariables,
  ApolloLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'cross-fetch';

import { Auth, ErrorService } from 'lib';

const httpLink = createHttpLink({
  uri: process.env.FLOOMX_API_URL,
  fetch: fetch
});

const authLink = setContext((_, { headers }) => {
  const token = Auth.getLoginToken();

  return {
    headers: {
      ...headers,
      'Authorization': token ? `Bearer ${token}` : null,
      'X-FloomX-Api': process.env.API_TOKEN
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (!!graphQLErrors?.length) {
    ErrorService.actionFxError(graphQLErrors as any);
  }

  if (networkError) {
    // handle errors differently based on its error code
    // console.log(networkError);
  }
});

const Client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    authLink,
    errorLink,
    httpLink
  ])
});

export class GraphQL {
  static query<T = any>(query: any, variables: Record<string, any> = {}, fetchPolicy: FetchPolicy = 'no-cache')
    : Promise<ApolloQueryResult<T>> {
    return Client.query({
      query,
      variables,
      fetchPolicy
    });
  }

  static mutate<T = any>(
    mutation: any,
    variables: OperationVariables = {},
    optimisticResponse?: T
  ): Promise<FetchResult<T>> {
    return Client.mutate<T>({
      mutation,
      variables,
      optimisticResponse
    });
  }
}

import { FC, useState } from 'react';

import { css } from '@emotion/react';
import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { TouchScrollable } from 'react-scrolllock';
import { Box, Flex } from 'rebass';

import { colors, fontStacks } from 'utils/rebass-theme';

import Icon from 'components/icon';

const BackDropIOSBlockScroll: FC<BackdropProps> = (props: BackdropProps) => {
  return (
    <TouchScrollable>
      <Backdrop {...props} />
    </TouchScrollable>
  );
};

const StyledMenuItem = withStyles(() => ({
  root: {
    'padding': '16px 20px',
    'fontFamily': fontStacks.secondary,
    '&:focus': {
      backgroundColor: colors.uiFills
    }
  }
}))(MenuItem);

type ConfirmDeleteTooltipProps = {
  onDeleteItem: () => Promise<void>;
  disableScrollLock?: boolean;
} & Pick<MenuProps, 'open' | 'anchorEl' | 'onClose'>

export const ConfirmDeleteTooltip = withStyles({
  paper: {
    boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.1)'
  },
  list: {
    padding: '0'
  }
})(({
  onDeleteItem,
  disableScrollLock = false,
  ...menuProps
}: ConfirmDeleteTooltipProps) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (): Promise<void> => {
    try {
      setIsDeleting(true);
      await onDeleteItem();
    } catch {
      setIsDeleting(false);
    }
  };

  return (
    <Menu
      {...menuProps}
      elevation={0}
      getContentAnchorEl={null}
      keepMounted={false}
      disableScrollLock={disableScrollLock}
      BackdropComponent={disableScrollLock ? Backdrop : BackDropIOSBlockScroll}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <StyledMenuItem
        disabled={isDeleting}
        onClick={handleDelete}
        disableRipple={true}
      >
        <Flex alignItems="center">
          <Flex
            pr="10px"
            css={css`
              height: 16px;
            `}
          >
            <Icon
              iconName="bin"
              size="xsmall"
              pathFill={isDeleting ? colors.grey : colors.errorText}
            />
          </Flex>
          <Box
            css={css`
              color: ${isDeleting ? colors.grey : colors.errorText};
              font-size: 14px;
            `}
          >
            {isDeleting ? 'Deleting...' : 'Confirm delete'}
          </Box>
        </Flex>
      </StyledMenuItem>
    </Menu>
  );
});

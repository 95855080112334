export enum FxErrorCodes {
  ValidationError = 77046111005,
  NotAuthorised = 77046111001,
  NotAuthorisedAction = 77046111008,
  SessionInvalidated = 77046111092,
  UserNotExist = 77046111040,
  MerchantPermissions = 77046111065,
  FloomDotComSync = 77046111989,
  FloomDotComLink = 77046111912,
  PermissionsInvalidated = 77046111033
}

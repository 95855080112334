import { FC } from 'react';

import css from '@emotion/css';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import hex2rgba from 'hex2rgba';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

import BannerImageDesktop from 'assets/images/wholesale/banner-desktop.png';

import Button from 'components/button';

import * as Styles from './help-centre-banner.styles';

type CopyForFeatures = {
  title: string;
  ctaPrimary: {
    label: string;
    link: string | NavPages;
  };
  ctaSecondary?: {
    label: string;
    link: string | NavPages;
  };
  listItems: {
    text: string;
    articleId: number;
  }[];
};

export const HelpCentreBanner: FC = () => {
  const copy = ((): CopyForFeatures | null => {
    return {
      title: 'Need help? Read our top articles',
      ctaPrimary: {
        label: 'Visit the help centre',
        link: 'https://help.floomx.com'
      },
      listItems: [
        {
          text: `How can I change my delivery settings on Floom?`,
          articleId: 360041327811
        },
        {
          text: `How do I get more orders?`,
          articleId: 360051480751
        },
        {
          text: `What are order status checks?`,
          articleId: 12941487137937
        },
        {
          text: `How to use order help: substitutions, cancellations, failed deliveries and POD`,
          articleId: 12312488338321
        },
        {
          text: `How can I turn my shop off for Floom deliveries?`,
          articleId: 360040900112
        }
      ]
    };
  })();

  if (!copy) {
    return null;
  }

  return (
    <Styles.Container>
      <Styles.BlobLeft
        fill={hex2rgba(colors.floomCyan, 1)}
      />
      <Styles.BlobRight
        fill={hex2rgba(colors.floomOrange, 1)}
      />
      <Styles.Content>
        <Styles.ContentLeft>
          <Styles.Title>
            <h3>
              {copy.title}
            </h3>
          </Styles.Title>
          <Styles.List>
            {copy.listItems.map((listItem, index) => {
              return (
                <Styles.ListItem key={index}>
                  <Box>
                    <Link
                      to={`https://help.floomx.com/hc/en-us/articles/${listItem.articleId}`}
                      aria-label={copy.ctaPrimary.label}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={css`
                      width: 100%;
                      text-decoration: underline;
                      `}
                    >
                      {listItem.text}
                    </Link>
                  </Box>
                </Styles.ListItem>
              );
            })}
          </Styles.List>
          <Styles.ButtonsWrapper>
            <Styles.ButtonContainer>
              <Link
                to={copy.ctaPrimary.link}
                aria-label={copy.ctaPrimary.label}
                target="_blank"
                rel="noopener noreferrer"
                css={css`
                  width: 100%;
                `}
              >
                <Button
                  size="normal"
                  copy={copy.ctaPrimary.label}
                  isParentWidth={true}
                  appearance="primary"
                />
              </Link>
            </Styles.ButtonContainer>
            {!!copy.ctaSecondary && (
              <Styles.ButtonContainer>
                <Link
                  to={copy.ctaSecondary.link}
                  aria-label={copy.ctaSecondary.label}
                  css={css`
                    width: 100%;
                  `}
                >
                  <Button
                    size="normal"
                    copy={copy.ctaSecondary.label}
                    isParentWidth={true}
                    appearance="secondary"
                  />
                </Link>
              </Styles.ButtonContainer>
            )}
          </Styles.ButtonsWrapper>
        </Styles.ContentLeft>
        <Styles.ImageDesktop src={BannerImageDesktop} />
      </Styles.Content>
    </Styles.Container>
  );
};

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex, Text } from 'rebass';
import * as Yup from 'yup';

import { MerchantBankDetailsInput } from 'generated-types.d';

import {
  ValidationService,
  NavService,
  LocalisationService
} from 'lib';

import { OnboardingStage } from 'stores/payment-onboarding/payment-onboarding-store.types';

import { textStyles } from 'utils/rebass-theme';

import OnboardingWrapper from 'features/onboarding/components/onboarding-wrapper';

import Button from 'components/button';
import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import FormFooter from 'components/form-footer';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import MerchantOnboardingService from '../../../merchant-onboarding/services';

import * as Types from './onboarding-bank-details.types';

class OnboardingBankDetails extends Component<Types.OnboardingBankDetailsProps, Types.OnboardingBankDetailsState> {
  private MerchantCreationService = MerchantOnboardingService.MerchantCreationService;

  private copy = {
    heading: 'Floom.com payments',
    subheading: 'How you will get paid from floom.com',
    copy: 'In order to receive payments from floom.com, please give us the following'
  };

  componentDidMount = (): void => {
    this.props.paymentOnboardingStore!.setStage(OnboardingStage.BankDetails);
    this.setState({ shouldShowCompleteNotification: true });
  };

  validation: Yup.Schema<any> = Yup.object().shape({
    fname: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please enter your first name')),
    lname: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please enter your last name')),
    bank: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please supply a bank name')),
    route: Yup
      .string()
      .matches(/^[0-9]*$/, params => ValidationService.generateYupMessageSchema(params, 'Invalid sort code format'))
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please supply your sort code'))
      .min(6, params => ValidationService.generateYupMessageSchema(params, 'Sort code should be at least 6 digits')),
    acct: Yup
      .string()
      .matches(/^[0-9]*$/, params => ValidationService.generateYupMessageSchema(params, 'Invalid sort account number format'))
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please supply an account number'))
      .min(8, params => ValidationService.generateYupMessageSchema(params, 'Account number should be at least 8 digits'))
  });

  state: Types.OnboardingBankDetailsState = {
    isLoading: false,
    isDirty: false,
    shouldShowCompleteNotification: false,
    errorNotification: null,
    bankDetails: {
      fname: '',
      lname: '',
      bank: '',
      route: '',
      acct: ''
    },
    errors: []
  };

  private handleChange = (value: Types.BankDetails[keyof Types.BankDetails], key: keyof Types.BankDetails): void => {
    this.setState({
      bankDetails: {
        ...this.state.bankDetails,
        [key]: value
      },
      errors: this.state.errors.filter(error => error.path !== key)
    });

    if (!this.state.isDirty) {
      this.setState({
        isDirty: true
      });
    }
  };

  private onContinue = (): void => {
    const firstIncompleteRoute = this.props.paymentOnboardingStore!.findFirstIncompleteRoute();

    if (!firstIncompleteRoute) {
      this.props.paymentOnboardingStore!.resetStage();
      NavService.onboardingConfirmation();
    } else {
      const firstIncompleteStep = this.props.paymentOnboardingStore!.onboardingConfig.findIndex(step => !step.isComplete && step.isRequired);
      this.props.paymentOnboardingStore!.onboardingConfig?.[firstIncompleteStep]?.navigateToStep();
    }
  };

  private onSubmit = async (): Promise<void> => {
    if (!this.isComplete()) {
      this.setState({
        isLoading: true
      });

      try {
        const bankDetailsSchema: Types.BankDetails = await ValidationService.validateAll(this.validation, this.state.bankDetails);

        const data: MerchantBankDetailsInput = {
          ...bankDetailsSchema,
          region: this.props.merchantStore!.merchant!.currency === 'GBP' ? 'GB' : 'US',
          merchantId: this.props.merchantStore!.merchant!.flc_id!,
          source: process.env.SAASSTAGE
        };

        try {
          const updatedMerchant = await this.MerchantCreationService.storeBankDetails(data, this.props.merchantStore!.merchant!);

          if (!!updatedMerchant) {
            this.props.merchantStore!.setMerchant(updatedMerchant);
            this.props.userStore!.setUserMerchant(updatedMerchant);
            this.onContinue();

            this.setState({
              isLoading: false
            });
          } else {
            this.setNotificationError();
          }
        } catch (error) {
          this.setNotificationError();
        }
      } catch (error) {
        this.showFieldErrors(error.errors);
      }
    } else {
      this.onContinue();
    }
  };

  private showFieldErrors = (error: Yup.TestMessageParams[]): void => {
    this.setState({
      isLoading: false,
      errorNotification: null,
      errors: error
    });
  };

  private setNotificationError = (): void => {
    this.setState({
      errorNotification: 'We are having trouble saving your bank details at the moment',
      isLoading: false
    });
  };

  private findError = (key: keyof Types.BankDetails): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(this.state.errors, key);
  };

  public buildForm = (): FormBuilderConfig => ({
    sections: [
      {
        width: '50',
        fields: [
          {
            key: 'label',
            fieldType: 'label',
            copy: 'First name'
          },
          {
            key: 'fname',
            fieldType: 'textInput',
            placeholder: 'First name',
            value: this.state.bankDetails.fname,
            validationError: this.findError('fname'),
            onChange: (e): void => this.handleChange(e.target.value, 'fname')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'label',
            fieldType: 'label',
            copy: 'Last name'
          },
          {
            key: 'lname',
            fieldType: 'textInput',
            placeholder: 'Last name',
            value: this.state.bankDetails.lname,
            validationError: this.findError('lname'),
            onChange: (e): void => this.handleChange(e.target.value, 'lname')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'label',
            fieldType: 'label',
            copy: 'Bank name'
          },
          {
            key: 'bank',
            fieldType: 'textInput',
            placeholder: 'Bank name',
            value: this.state.bankDetails.bank,
            validationError: this.findError('bank'),
            onChange: (e): void => this.handleChange(e.target.value, 'bank')
          }
        ]
      },
      {
        width: '50',
        fields: []
      },
      {
        width: '50',
        fields: [
          {
            key: 'label',
            fieldType: 'label',
            copy: LocalisationService.routingCodeCopy(this.props.merchantStore!.merchant?.currency)
          },
          {
            key: 'route',
            fieldType: 'textInput',
            placeholder: LocalisationService.routingCodeCopy(this.props.merchantStore!.merchant?.currency),
            value: this.state.bankDetails.route,
            validationError: this.findError('route'),
            onChange: (e): void => this.handleChange(e.target.value, 'route')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'label',
            fieldType: 'label',
            copy: 'Account number'
          },
          {
            key: 'acct',
            fieldType: 'textInput',
            placeholder: 'Account number',
            value: this.state.bankDetails.acct,
            validationError: this.findError('acct'),
            onChange: (e): void => this.handleChange(e.target.value, 'acct')
          }
        ]
      }
    ]
  });

  private isComplete = (): boolean => {
    return !!this.props.merchantStore!.merchant?.hasProvidedBankDetails && this.state.shouldShowCompleteNotification;
  };

  private renderContent = (): ReactNode => {
    if (this.isComplete()) {
      return (
        <Notification
          copy="You have already provided your bank details! Click continue to go to the next step"
          type={NotificationType.Success}
          hasClose={false}
          textAlign="left"
        />
      );
    }

    return (
      <FormBuilder
        config={this.buildForm()}
        notification={{
          hasNotification: !!this.state.errorNotification?.length,
          notificationProps: {
            copy: this.state.errorNotification,
            type: NotificationType.Error
          }
        }}
      />
    );
  };

  render(): ReactNode {
    return (
      <OnboardingWrapper my="auto">
        <Box m="50px 0">
          <Text
            css={textStyles.headline}
            color="floomMidnightBlue"
            mb="30px"
          >
            {this.copy.heading}
          </Text>
          { !this.isComplete() && (
            <>
              <Text
                css={textStyles.title}
                color="floomMidnightBlue"
                mb="10px"
              >
                {this.copy.subheading}
              </Text>
              <Text
                css={textStyles.body}
                mb="40px"
              >
                {this.copy.copy}
              </Text>
            </>
          )}
          {this.renderContent()}
          <FormFooter hasOffset={false}>
            <OnboardingWrapper my="auto">
              <Flex
                justifyContent="space-between"
                width="100%"
              >
                <Box />
                <Box
                  as="button"
                  onClick={this.onSubmit}
                  disabled={this.state.isLoading}
                >
                  <Button
                    size="normal"
                    appearance="primary"
                    copy="Continue"
                    isLoading={this.state.isLoading}
                    isDisabled={!!this.state.errors.length}
                  />
                </Box>
              </Flex>
            </OnboardingWrapper>
          </FormFooter>
        </Box>
      </OnboardingWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  paymentOnboardingStore: stores.paymentOnboardingStore,
  userStore: stores.userStore
}))(observer(OnboardingBankDetails));

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import {
  RadioItemType,
  RadioItemOrientation
} from '../../radio-list.types';

const isVertical = (orientation: string): boolean => orientation === 'vertical';

export const HiddenRadio = css`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

interface RadioItemProps {
  orientation: RadioItemOrientation;
  type: RadioItemType;
  isDisabled: boolean;
  isSelected: boolean;
  totalItems: number;
}

export const StyledRadioItem: any = styled(Flex)<RadioItemProps>`
  cursor: ${({ isDisabled }: any): string => isDisabled ? 'not-allowed' : 'pointer'};
  width: ${({ orientation, type, totalItems }): string => {
    switch (type) {
      case 'card': {
        return `calc(100% / ${totalItems <= 4 ? `${totalItems}` : '4'})`;
      }

      case 'default':
      // eslint-disable-next-line padding-line-between-statements
      case 'border': {
        return isVertical(orientation) ? '100%' : 'auto';
      }

      default:
        return '';
    }
  }};
`;

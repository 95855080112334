import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import GenericModal from 'components/generic-modal';

import * as Styles from './modal.styles';

export class CategoryModal extends Component<any> {
  render(): React.ReactNode {
    return (
      <GenericModal
        width={430}
        innerComponent={(
          <Styles.InnerContent>
            <p>
            Champagne products are only available in specific delivery zones and might not be visible to customers.
            </p>
          </Styles.InnerContent>
        )}
        confirmButtonAction={this.props.closeAction}
        confirmButtonText="I Acknowledge"
        title="Good to know"
        modalOpen={this.props.isOpen}
        closeModal={this.props.closeAction}
      />
    );
  }
}

export default inject('addOnEditStore')(observer(CategoryModal));

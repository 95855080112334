import gql from 'graphql-tag';

export const COURIER_BOOKING_FRAGMENT_FULL = gql`
  fragment CourierBookingFragmentFull on CourierBooking {
    id
    payment {
      currency
      amount
    }
    courierReferenceId
    courierReferenceVehicleType
    courierReferenceProvider
    orders {
      merchant {
        title
      }
      shippingAddress {
        recipientFullName
        address1
        city
        postalCode
      }
      orderNo
    }
    merchant {
      id
      title
    }
    shippingAddress {
      recipientFullName
      address1
      city
      postalCode
    }
    pickupCode
    pickupDate
    deliveryDate
    deliveryStatus
  }
`;

export const BOOKINGS_CONNECTION = gql`
  fragment CourierBookingsConnectionFull on CourierBookingConnection {
    pageInfo {
      hasNextPage
    }
    aggregate {
      count
    }
    edges {
      node {
        ...CourierBookingFragmentFull
      }
    }
  }
  ${COURIER_BOOKING_FRAGMENT_FULL}
`;

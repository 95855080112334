import {
  Colour
} from 'generated-types.d';

import { GraphQL } from 'lib';

import {
  WHOLESALE_COLOURS
} from 'features/wholesale/graphql/queries/filters/wholesale-filters-queries';

class WholesaleFiltersStoreService {
  public static fetchColours = async (): Promise<Colour[]> => {
    try {
      const result = await GraphQL.query<any>(WHOLESALE_COLOURS);

      return result.data.colours;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default WholesaleFiltersStoreService;

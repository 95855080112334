import React, { Component } from 'react';

import { marketingZIndexes as z } from 'global.constants';

import * as Styles from './section.styles';
import { SectionProps } from './section.types';

class Section extends Component<SectionProps> {
  render(): React.ReactNode {
    const {
      children,
      bgColor,
      textColor,
      sectionHatParams,
      ...props
    } = this.props;
    const { hatIndex = 0 } = sectionHatParams || {};
    const Hat = Styles.hats[hatIndex];

    return (
      <section css={{ zIndex: z.content }}>
        <Styles.Section
          bgColor={bgColor}
          color={textColor}
          sectionHatParams={sectionHatParams}
        >
          {sectionHatParams && (
            <Hat
              bgcolor={bgColor}
              css={Styles.getSectionHatStyle({ ...sectionHatParams, bgColor })}
            />
          )}
          <Styles.SectionInnerWrapper {...props}>
            {children}
          </Styles.SectionInnerWrapper>
        </Styles.Section>
      </section>
    );
  }
}

export default Section;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { breakpoints, space } from 'utils/rebass-theme';

export const ContentWrapper = styled(Box)<{ height?: number }>`
  position: relative;
  padding: ${space[3]}px;
  height: ${({ height }) => height ? `${height}px` : '100vh'};
  overflow: hidden;

  @media (max-width: ${breakpoints.small}) {
    padding: 0;
  }
`;

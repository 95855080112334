import { gql } from '@apollo/client';
import moment from 'moment';

import {
  DeliveryCoverage,
  Product,
  PromotedPeriod,
  PromotedPostcode
} from 'generated-types.d';

import { GraphQL } from 'lib';

export const MERCHANT_COVERAGES_QUERY = gql`
  query DeliveryCoverages(
    $merchantId: ID!
  ) {
    deliveryCoverages(
      where: {
        deletedAt: null
        channel: Floom
        type: Core
        merchant: {
          id: $merchantId
        }
      }
    ) {
      id
      title
      deliveryZones(first: 150) {
        id
        postalCode
      }
    }
  }
`;

export const PROMOTED_POSTCODES_QUERY = gql`
  query PromotedPostcodes(
    $timezone: String!
    $periodId: ID!
  ) {
      promotedPostcodes(
        first: 1000
        where: {
          deletedAt: null
          timezone: $timezone
          period: {
            id: $periodId
          }
        }
        orderBy: postcode_ASC
      ) {
        id
        postcode
        price
        status
        merchant {
          id
        }
        period {
          startDate
          endDate
        }
      }
  }
`;

export const UPCOMING_PROMOTED_PERIOD_QUERY = gql`
  query PromotedPeriod(
    $timezone: String
    $today: DateTime
  ) {
    promotedPeriods(
      first: 300
      where: {
        deletedAt: null
        timezone: $timezone
        startDate_gt: $today
      }
      orderBy: startDate_ASC
    ) {
      id
      timezone
      dropDate
      startDate
      endDate
    }
  }
`;

export const CURRENT_PROMOTED_PERIOD_QUERY = gql`
  query PromotedPeriod(
    $timezone: String
    $today: DateTime
  ) {
    promotedPeriods(
      where: {
        deletedAt: null
        timezone: $timezone
        startDate_lt: $today
        endDate_gt: $today
      }
      orderBy: startDate_ASC
    ) {
      id
      startDate
      endDate
    }
  }
`;

export const RELEASE_POSTCODES_MUTATION = gql`
  mutation ReleasePromotedPostcodes(
    $postcodes: [PromotedPostcodeWhereUniqueInput!]!
  ) {
    releasePromotedPostcodes(
      data: {
        postcodes: $postcodes
      }
    ) {
      success
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query Products(
    $merchantId: ID!
  ) {
    products(
      where: {
        merchant: {
          id: $merchantId
        }
        type_some: {
          title: "Flowers"
        }
      }
    ) {
      id
      slug
      flc_id
      title
      variations {
        title
        price
        media {
          title
          src
          format
        }
      }
      merchant {
        title
        currency
        reviewRating
        reviewCount
      }
    }
  }
`;

export const releasePostcodes = async (
  clearPostcodes: () => void,
  postcodes: PromotedPostcode[]
): Promise<void> => {
  console.log('releasing');

  if (!postcodes.length) {
    return;
  }

  await GraphQL.query<{ releasePromotedPostcodes: { success: boolean } }>(
    RELEASE_POSTCODES_MUTATION,
    {
      postcodes: postcodes.map(postcode => ({ id: postcode.id }))
    }
  );

  clearPostcodes();

  return;
};

export const getUpcomingPeriod = async (
  setUpcomingPeriod: (period: PromotedPeriod) => void,
  merchantTimezone?: string
): Promise<PromotedPeriod | false> => {
  console.log('getUpcomingPromotedPeriod');

  const periodQueryResult = await GraphQL.query<{ promotedPeriods: PromotedPeriod[] }>(
    UPCOMING_PROMOTED_PERIOD_QUERY,
    {
      today: moment().tz(merchantTimezone || 'Europe/London').format('YYYY-MM-DD'),
      timezone: merchantTimezone
    }
  );

  console.log('periodQueryResult', periodQueryResult);

  if (!periodQueryResult.data.promotedPeriods.length) {
    return false;
  }

  setUpcomingPeriod(periodQueryResult.data.promotedPeriods[0]);

  return periodQueryResult.data.promotedPeriods[0];
};

export const getCurrentPeriod = async (merchantTimezone?: string): Promise<PromotedPeriod> => {
  console.log('getCurrentPromotedPeriod');

  const periodQueryResult = await GraphQL.query<{ promotedPeriods: PromotedPeriod[] }>(
    CURRENT_PROMOTED_PERIOD_QUERY,
    {
      timezone: merchantTimezone,
      today: moment().tz(merchantTimezone || 'Europe/London').format('YYYY-MM-DD')
    }
  );

  return periodQueryResult.data.promotedPeriods[0];
};

export const getSelectablePostcodes = async (
  setMerchantSelectablePostcodes: (postcodes: PromotedPostcode[]) => void,
  merchantId?: string,
  merchantTimezone?: string,
  periodId?: string
): Promise<PromotedPostcode[]> => {
  console.log('getSelectablePostcodes');
  console.log('periodId', periodId);

  const coverageQueryResult = await GraphQL.query<{ deliveryCoverages: DeliveryCoverage[] }>(
    MERCHANT_COVERAGES_QUERY,
    {
      merchantId
    }
  );

  const allZones: { id: string; postcode: string }[] = [];

  coverageQueryResult.data.deliveryCoverages.forEach(coverage => {
    coverage.deliveryZones?.forEach(zone => {
      allZones.push({
        ...zone,
        postcode: zone.postalCode
      });
    });
  });

  const uniqueMerchantZones = [...new Set(allZones.map(zone => zone.postcode))];

  const postcodeQueryResult = await GraphQL.query<{ promotedPostcodes: PromotedPostcode[] }>(
    PROMOTED_POSTCODES_QUERY,
    {
      timezone: merchantTimezone,
      periodId: periodId
    }
  );

  const postcodesToReturn = postcodeQueryResult.data.promotedPostcodes.filter(postcode => {
    return uniqueMerchantZones.includes(postcode.postcode);
  });

  setMerchantSelectablePostcodes(postcodesToReturn);

  console.log('postcodesToReturn', postcodesToReturn);

  return postcodesToReturn;
};

export const getProducts = async (merchantId?: string): Promise<Product[]> => {
  console.log('getProducts');

  const productQueryResult = await GraphQL.query<{ products: Product[] }>(
    PRODUCTS_QUERY,
    {
      merchantId
    }
  );

  console.log('productQueryResult', productQueryResult);

  return productQueryResult.data.products;
};

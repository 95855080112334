import { FC, Fragment, ReactNode } from 'react';

import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon
} from '@heroicons/react/20/solid';

import type { PaymentMessageType, MessageColour } from '../types';

const PaymentMessage: FC<{ message: PaymentMessageType }> = ({ message }) => {
  const colours = ((): MessageColour => {
    switch (message.type) {
      case 'error':
        return {
          icon: 'text-red-400',
          headerText: 'text-red-800',
          bodyText: 'text-red-700',
          background: 'bg-red-50'
        };

      case 'success':
        return {
          icon: 'text-green-400',
          headerText: 'text-green-800',
          bodyText: 'text-green-700',
          background: 'bg-green-50'
        };

      default:
        return {
          icon: 'text-yellow-400',
          headerText: 'text-yellow-800',
          bodyText: 'text-yellow-700',
          background: 'bg-yellow-50'
        };
    }
  })();

  const icon = ((): ReactNode => {
    switch (message.type) {
      case 'error':
        return (
          <XCircleIcon
            className={`h-5 w-5 ${colours.icon}`}
            aria-hidden="true"
          />
        );

      case 'success':
        return (
          <CheckCircleIcon
            className={`h-5 w-5 ${colours.icon}`}
            aria-hidden="true"
          />
        );

      case 'processing':
        return (
          <InformationCircleIcon
            className={`h-5 w-5 ${colours.icon}`}
            aria-hidden="true"
          />
        );

      default:
        return null;
    }
  })();

  return (
    <div className={`rounded-md ${colours.background} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {icon}
        </div>
        <div className="ml-3">
          {(message.headerText || message.type === 'error') && (
            <h3 className={`text-sm font-medium ${colours.headerText}`}>
              {message.headerText ?? 'There was an error making payment'}
            </h3>
          )}
          <div className={`mt-2 text-sm ${colours.bodyText}`}>
            {message.bodyText}
          </div>
          {!!message.ctaComponent && (
            <Fragment>
              {message.ctaComponent}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMessage;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

import { ButtonConfig } from './catalog-explorer-item';

export const Row = styled.li<{ isDisabled: boolean }>`
  margin-bottom: 30px;
  transition: opacity .15s ease-ease-in-out;
  opacity: ${({ isDisabled }) => isDisabled ? .6 : 1};
`;

export const ImageWrap = styled(Box)`
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 3px solid ${({ config }) => config.bgColour};
  overflow: hidden;
  transition: border ${({ hasTransition }) => hasTransition ? '.15s' : '0s'} ease-in-out;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export const BgImage = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${colors.paleGrey};
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
`;

export const AddButton = styled.div<{
  config: ButtonConfig;
  isVisible: boolean;
  hasTransition: boolean;
}>`
  display: block;
  align-items: center;
  position: absolute;
  opacity: ${({ isVisible }) => isVisible ? 1 : 0};
  background-color: ${({ config }) => config.bgColour};
  color: ${({ config }) => config.textColour};
  top: ${({ config }) => config.position === 'top' ? '8px' : 'calc(100% - 47px)'};
  right: 8px;
  width: ${({ config }) => config.width};
  max-width: calc(100% - 17px);
  padding: 9px 15px 13px 15px;
  text-align: center;
  font-size: 15px;
  border-radius: 5px;
  transition: all ${({ hasTransition }) => hasTransition ? '.15s' : '0s'} ease-in-out;

  span {
    transition: color ${({ hasTransition }) => hasTransition ? '.15s' : '0s'} ease-in-out;
    color: ${({ config }) => config.textColour};
  }

  svg {
    width: 15px;
    height: 15px;
    position: relative;
    top: 2px;

    path {
      transition: all ${({ hasTransition }) => hasTransition ? '.15s' : '0s'} ease-in-out;
      fill: ${({ config }) => config.textColour};
      stroke: ${({ config }) => config.textColour};
    }
  }
`;

export const Title = styled(Box)`
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
  margin-top: 10px;
  color: ${colors.floomMidnightBlue};
`;

export const SubTitle = styled(Box)`
  font-size: 12px;
  margin-top: 6px;
  color: ${colors.shade40};
`;

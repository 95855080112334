export interface ToasterStoreTypes {
  newToast: boolean;
  popToast: (changeType: string, verb: ToastVerb) => void;
  popErrorToast: (changeType: string, verb: ToastVerb) => void;
  popSuccessToast: (changeType: string, verb: ToastVerb) => void;
  popNotificationToast: (changeType: string) => void;
  resetToasterState: () => void;
  buildSuccessMessage: (
    changeType: string,
    messageType: ToastVerb,
    multiple?: boolean
  ) => void;
  buildErrorMessage: (
    changeType: string,
    messageType: string,
    multiple?: boolean
  ) => void;
  buildNotificationMessage: (
    message: string
  ) => void;
}

export enum ToastType {
  Error = 'error',
  Success = 'success',
  Notification = 'notification'
}

export interface ToastObject {
  message: string;
  type: ToastType;
  id: string;
}

export type ToastVerb =
  | 'save'
  | 'create'
  | 'change'
  | 'add'
  | 'get'
  | 'finalise'
  | 'enable'
  | 'disable'
  | 'delete'
  | 'remove'
  | 'upload'
  | 'retrieve'
  | 'update'
  | 'discard'
  | 'publish'
  | 'unpublish'
  | 'subscribe'
  | 'send'
  | 'cancel';

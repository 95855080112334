import deepmerge from 'deepmerge';
import sanitizeHTML from 'sanitize-html';

import options from './html.constants';

interface SanitizeHTMLOptions extends sanitizeHTML.IOptions {
  textFilter?: (text: string) => string;
}

export const sanitize = (
  dirtyHTML: string,
  additionalOptions: SanitizeHTMLOptions = {}
): string => {
  return sanitizeHTML(dirtyHTML, deepmerge(options, additionalOptions));
};

export const newLineToBreak = (dirtyHTML: string): string => {
  return sanitize(dirtyHTML, {
    textFilter: (text: string): string => {
      return text.replace(/\n/g, '<br />');
    }
  });
};

export const breakToNewLine = (dirtyHTML: string): string => {
  return sanitize(dirtyHTML
    .replace(/<br\s*\/?>/mg, '\n'));
};

// Temp function to replace <br /> to new lines and strip out any other tags
// TODO: fix breakToNewLine and use textFilter to replace <br /> to new lines instead
export const stripTagsAndAddNewLine = (dirtyHTML: string): string => {
  return dirtyHTML
    .replace(/\s?(<br.?\/?>)\s?/g, '\r\n')
    .replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
    .replace(/  +/g, ' ');
};

import styled from '@emotion/styled';
import { Flex } from 'rebass';

export const Container = styled(Flex)`
  position: relative;
  align-items: center;
  align-self: center;
  flex-direction: column;
  min-height: 420px;
  margin: 30px;
  text-align: center;
`;

export const OrderItemWrapper = styled.div`
  width: 100%;
`;

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { OrdersListLayout } from 'stores/orders/orders-store.types';

import { space } from 'utils/rebass-theme';

export const CardsContainer = styled.ul<{ layoutType: OrdersListLayout }>`
  display: ${({ layoutType }) => layoutType === 'grid' ? 'grid' : 'block'};
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 15px;
`;

export const OrdersLowerSection = styled(Flex)`
  width: 100%;
  justify-content: center;
  margin: ${space[5]}px auto;
`;

export const ButtonContainer = styled.div`
  width: 358px;
  max-width: 358px;
`;

export const Pagination = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

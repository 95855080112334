import styled from '@emotion/styled';
import { Box } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const Image = styled.img`
  max-width: 250px;
  margin-bottom: 1em;
`;

export const Heading = styled.h2`
  ${textStyles.h3}
`;

export const Container = styled(Box)`
  padding-top: 20%;
  margin: 0 auto;
  text-align: center;
  max-width: 250px;
`;

import stores from 'stores';

import {
  NavService,
  Auth,
  Analytics
} from 'lib';

import UserLoginService from 'features/login-signup/services';

const onRouteUpdate = ({ location }: any): void => {
  if (!Auth.isLoggedIn()) {
    Analytics.identify('', {
      userType: 'Marketing'
    });
  }

  if (stores.userStore.userMerchant
    && stores.userStore.isTeamRole()
    && UserLoginService.shouldBeOnMerchantOnboarding()
    && !UserLoginService.isLocationOnboarding(location.pathname)
    && Auth.isLoggedIn()
  ) {
    NavService.onboardingHome();
  }
};

export default onRouteUpdate;

import { gql } from '@apollo/client';

export const CREATE_MEDIA_UPLOAD_LINK = gql`
    mutation CreateMediaLink (
      $mediaName: String!
      $mediaType: String!
      $mediaSize: Int!
      $mediaWidth: Int
      $mediaHeight: Int
      $mediaCategory: MediaCategory!
    ) {
      createMediaLink(
        data: {
          mediaName: $mediaName
          mediaType: $mediaType
          mediaSize: $mediaSize
          mediaWidth: $mediaWidth
          mediaHeight: $mediaHeight
          mediaCategory: $mediaCategory
        }
      ) {
        id,
        url,
        key
      }
    }
`;

export const CONFIRM_MEDIA_UPLOAD = gql`
  mutation ConfirmMediaLink (
    $mediaId: ID!
  ) {
    confirmMediaUpload(
      where: {
        id: $mediaId
      }
    ) {
      id
      src
    }
  }
`;

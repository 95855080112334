import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

export const Content = styled(Flex)`
  width: calc(100% + 20px);
  justify-content: flex-start;
  flex-flow: row wrap;
  text-align: center;
`;

export const Item = styled(Box)`
  width: calc(25% - 20px);
  margin-right: 20px;

  @media (max-width: 830px) {
    width: calc(50% - 20px);
    margin-bottom: 20px;
  }
`;

import React, { Component } from 'react';

import { Link } from 'gatsby';

import * as Types from './modal-trigger.types';

export default class ModalTrigger extends Component<Types.ModalTriggerProps> {
  static defaultProps = {
    preventScroll: false,
    modalWidth: 960
  };

  render(): JSX.Element {
    return(
      <Link
        to={this.props.to}
        css={this.props.styles}
        state={{
          modal: {
            type: this.props.modalType,
            width: this.props.modalWidth,
            id: this.props.id
          },
          preventScroll: this.props.preventScroll
        }}
      >
        {this.props.children}
      </Link>
    );
  }
}

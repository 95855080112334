import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const DeleteButton = styled(Box)<any>`
  cursor: ${(props): string => props.isUpdating ? 'not-allowed' : 'pointer'};
  pointer-events: ${(props): string => props.isUpdating ? 'none' : 'auto'};

  path {
    stroke: ${(props): string => props.isUpdating ? colors.middleGrey : colors.floomMidnightBlue};
  }
`;

export const ItemFlorist = styled(Flex)`
  width: 100%;
`;

export const NewFloristBadge = styled(Box)`
  margin-right: 25px;
  height: 0;

  span {
    white-space: nowrap;
  }
`;

export const Overflow = css`
  width: 100%;
  height: 22px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FloristTitle = styled.button`
  ${Overflow}
`;

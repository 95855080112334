import styled from '@emotion/styled';

import { colors, textStyles } from 'utils/rebass-theme';

import { getPlaceholderStyle, inputReset } from 'components/field-text/field-text.styles';

export const Input = styled.input`
  ${textStyles.body};
  color: ${colors.floomMidnightBlue};
  font-size: 12px;
  background: none;
  width: 50px;
  border-bottom: 1px solid ${colors.middleGrey};

  &:focus {
    outline: none;
  }

  ${inputReset}
  ${getPlaceholderStyle()};
`;

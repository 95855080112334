import { ApolloQueryResult } from '@apollo/client';

import {
  EstimateMerchantSubscriptionOutput,
  Merchant,
  MerchantWhereUniqueInput,
  Mutation,
  ProposedMerchantUpdate,
  ProposedMerchantUpdateCreateInput,
  ProposedMerchantUpdateWhereUniqueInput,
  UpdateMerchantSubscriptionInput,
  UpdateMerchantSubscriptionOutput
} from 'generated-types.d';

import { GraphQL } from 'lib';

import {
  ESTIMATE_MERCHANT_SUBSCRIPTION_MUTATION,
  PROPOSE_MERCHANT_DELETE_MUTATION,
  PROPOSE_MERCHANT_UPDATE_MUTATION,
  RESET_MERCHANT_SUBSCRIPTION,
  UPDATE_MERCHANT_SUBSCRIPTION_MUTATION
} from '../../graphql/mutation/merchant-subscription';

export default class MerchantSubscriptionService {
  public static estimateMerchantSubscription = async (
    merchantPlanId: string,
    merchantId: string,
    price?: number
  ): Promise<EstimateMerchantSubscriptionOutput> => {
    const data: UpdateMerchantSubscriptionInput = {
      merchantPlanId
    };

    if (price) {
      data.subscriptionPrice = price;
    }

    try {
      const result: ApolloQueryResult<{ estimateMerchantSubscription: EstimateMerchantSubscriptionOutput }> = await GraphQL.query(
        ESTIMATE_MERCHANT_SUBSCRIPTION_MUTATION,
        { data, merchantId }
      );

      return result.data.estimateMerchantSubscription;
    } catch (error) {
      throw error;
    }
  };

  public static updateMerchantSubscription = async (
    merchantPlanId: string,
    stripePriceId: string,
    prorationStartsAt: string,
    merchantId: string
  ): Promise<UpdateMerchantSubscriptionOutput> => {
    try {
      const result: ApolloQueryResult<{ updateMerchantSubscription: UpdateMerchantSubscriptionOutput }> = await GraphQL.query(
        UPDATE_MERCHANT_SUBSCRIPTION_MUTATION,
        {
          merchantPlanId,
          stripePriceId,
          prorationStartsAt,
          merchantId
        }
      );

      return result.data.updateMerchantSubscription;
    } catch (error) {
      throw error;
    }
  };

  public static createProposedMerchantUpdate = async (data: ProposedMerchantUpdateCreateInput): Promise<ProposedMerchantUpdate> => {
    try {
      const result: ApolloQueryResult<{ createProposedMerchantUpdate: ProposedMerchantUpdate }> = await GraphQL.query(
        PROPOSE_MERCHANT_UPDATE_MUTATION,
        { data }
      );

      return result.data.createProposedMerchantUpdate;
    } catch (error) {
      throw error;
    }
  };

  public static deleteProposedMerchantUpdate = async (where: ProposedMerchantUpdateWhereUniqueInput): Promise<ProposedMerchantUpdate> => {
    try {
      const result: ApolloQueryResult<{ deleteProposedMerchantUpdate: ProposedMerchantUpdate }> = await GraphQL.query(
        PROPOSE_MERCHANT_DELETE_MUTATION,
        { where }
      );

      return result.data.deleteProposedMerchantUpdate;
    } catch (error) {
      throw error;
    }
  };

  public static resetMerchantSubscription = async (where: MerchantWhereUniqueInput): Promise<Merchant | undefined> => {
    try {
      const result = await GraphQL.mutate<Pick<Mutation, 'resetMerchantSubscription'>>(
        RESET_MERCHANT_SUBSCRIPTION,
        { where }
      );

      return result.data?.resetMerchantSubscription;
    } catch (error) {
      throw error;
    }
  };
}

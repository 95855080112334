import React from 'react';

import { inject, observer } from 'mobx-react';

import { PermissionsService } from 'lib';

import { Container } from 'utils/css-mixins';
import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';

import * as Config from './settings-root.config';
import * as Styles from './settings-root.styles';
import * as Types from './settings-root.types';
import Tile from './tile/tile';

class SettingsRoot extends React.Component<Types.SettingsRootTypes> {
  private doesTileSupportMerchantPlan = (tile: Types.ConfigTile): boolean => {
    return tile.requiredPlanFeatures.every(feature => this.props.merchantStore!.merchant?.plan?.features?.includes(feature));
  };

  private renderTiles = (tiles: Types.ConfigTile[]): JSX.Element => {
    return (
      <Styles.Tiles>
        {tiles.map((tile, index) => {
          if (!PermissionsService.isInternalRole() && !this.doesTileSupportMerchantPlan(tile)) return null;

          return (
            <FloomXRestrictedComponent
              restrictedRoles={tile.restrictedRoles}
              key={index}
            >
              <Tile
                copy={tile.copy}
                iconType={tile.iconType}
                url={tile.url}
                enabled={tile.enabled}
              />
            </FloomXRestrictedComponent>
          );
        })}
      </Styles.Tiles>
    );
  };

  render(): JSX.Element {
    return (
      <Styles.SettingsRootWrapper>
        <Container>
          <Styles.SettingsTitle>
            Settings
          </Styles.SettingsTitle>
          {this.renderTiles(Config.SETTINGS_NAV)}
        </Container>
      </Styles.SettingsRootWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  accountStore: stores.accountStore,
  userStore: stores.userStore,
  merchantStore: stores.merchantStore
}))(observer(SettingsRoot));

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles, colors, space } from 'utils/rebass-theme';

const isVertical = (orientation: string): boolean => orientation === 'vertical';

export const HiddenCheckbox = css`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckboxLabel: any = styled.span<any>`
  ${textStyles.body};
  line-height: 16px;
  position: relative;
  user-select: none;

  ${({ truncated }): any => !!truncated && `
    display: inline-block;
    height: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};

  color: ${({ isDisabled: id }: any): any => id
    ? colors.middleGrey
    : ({ isError }: any): string => isError ? colors.errorText : colors.floomMidnightBlue};
`;

export const CheckboxItem: any = styled(Flex)`
  width: ${({ orientation }: any): string => isVertical(orientation) ? '100%' : 'auto'};
  cursor: ${({ isDisabled }: any): string => isDisabled ? 'not-allowed' : 'pointer'};
  margin: ${({ orientation }: any): string => isVertical(orientation) ? '0 0 19px 0' : '0 40px 0 0'};
`;

export const Metadata: any = styled.span`
  ${textStyles.footnote};
  color: ${colors.shade40};
  padding-left: ${space[2]}px;
`;

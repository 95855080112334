import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import {
  textStyles,
  colors,
  breakpoints,
  space,
  fontStacks
} from 'utils/rebass-theme';

export const PageHeadingContainer: any = styled(Flex)`
  align-items: center;
  justify-content: normal;
  flex: auto;
  
  @media only screen and (max-width: ${breakpoints.medium}) {
    justify-content: space-between;
    margin-bottom: ${space[3]}px;
  }
`;

export const PageHeadingIntro: any = styled(Flex)`
  font-family: ${fontStacks.primary};
  font-weight: 700;
  font-size: 38px;
  margin-right: 35px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    font-size: 24px;
    margin-right: 0;
  }
`;

export const Wrapper: any = styled(Flex)`
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${space[4]}px;
  margin-bottom: ${space[3]}px;

  @media (max-width: ${breakpoints.medium}) {
    margin-top: ${space[3]}px;
  }
`;

export const OverviewHeading: any = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  flex: auto;
  width: 100%;
`;

export const MyPlan: any = styled(Box)`
  background: rgba(255, 255, 255, 0.6);
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  border-radius: 3px;
  transition: box-shadow .2s ease-in-out;
  box-shadow: 0 0 5px rgba(53, 52, 49, 0.3);

  :hover {
    background: ${colors.white};
    box-shadow: 0 0 10px rgba(53, 52, 49, 0.5);
  }

  > a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 14px;

    > p {
      padding-left: 10px;
    }
  }
`;

export const PageHeadingDetails: any = styled(Flex)`
  align-items: center;

  @media (max-width: ${breakpoints.medium}) {
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
`;

export const TotalSales: any = styled(Flex)`
  flex-direction: column;

  @media (max-width: ${breakpoints.medium}) {
    margin-top: 2px;
  }
`;

export const TotalSalesHeading: any = styled(Box)`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
  margin-bottom: 3px;
`;

export const CompletedOrderCount: any = styled.span`
  ${textStyles.subhead};
  line-height: 1.1;
  color: ${colors.shade40};
`;

export const CompletedOrderPrice = styled.span`
  font-size: 38px;
  font-weight: 600;

  @media (max-width: ${breakpoints.medium}) {
    font-size: 24px;
  }
`;

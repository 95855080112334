import gql from 'graphql-tag';

export const FULL_LIST_ITEM_FRAGMENT = gql`
  fragment FullListItemFragment on ListItemUnion {
    ... on ListItem {
      __typename
      id
      type
      sku
      skuSource
      vbn
      quantity
      position
      createdAt
      updatedAt
      deletedAt
      maxUnitPrice
      list {
        id
        type
        merchant {
          currency
        }
        suppliers {
          id
          name
        }
      }
      promotedTradeSkuAvailability {
        unitStock
        supplierProductId
        multiplesOf
        quantityInPackage
        maximumPurchaseQuantity
        isLive
        grower {
          name
          countryCode
        }
        price(first: 1) {
          currency
          price
        }
      }
      availableTradeSkus {
        id
        availability(
          first: 10
          orderBy: createdAt_DESC
        ) {
          multiplesOf
          price(first: 1) {
            currency
            price
          }
          priceHistory(first: 10) {
            currency
            price
          }
          supplier {
            id
            name
          }
        }
      }
      colour {
        id
        hex
        name
      }
      catalogItem {
        id
        tradeSku(
          first: 20
          orderBy: createdAt_DESC
        ) {
          availability(
            first: 10
            orderBy: createdAt_DESC
          ) {
            supplier {
              id
              active
            }
            price(first: 1) {
              currency
              price
            }
            media {
              src
            }
          }
          flower {
            maturity
            minimumStemLength
          }
        }
        primaryName
        secondaryName
        media {
          id
          title
          src
          updatedAt
        }
        family {
          genus
        }
        colour {
          name
          hex
        }
      }
    }

    ... on ListItemTypeFlower {
      headNumber
      minimumHeadSize
      maximumHeadSize
      minimumStemLength
      stemLengthUnit
      headSizeUnit
      height
      weight
      maturity
      potSize
    }

    ... on ListItemTypeSundry {
      height
      weight
    }

    ... on ListItemTypePlant {
      minimumHeadSize
      maximumHeadSize
      headSizeUnit
      height
      weight
      maturity
      diameter
      thickness
      potSize
    }
  }
`;

export const LIST_ITEM_WITHOUT_AVAILABILITY_FRAGMENT = gql`
  fragment ListItemWithoutAvailabilityFragment on ListItemUnion {
    ... on ListItem {
      __typename
      id
      type
      sku
      skuSource
      vbn
      quantity
      position
      createdAt
      updatedAt
      deletedAt
      maxUnitPrice
      list {
        id
        type
        merchant {
          currency
        }
        suppliers {
          id
          name
        }
      }
      colour {
        id
        hex
        name
      }
      catalogItem {
        id
        primaryName
        secondaryName
        media {
          id
          title
          src
          updatedAt
        }
        family {
          genus
        }
        colour {
          name
          hex
        }
        tradeSku (
          first: 1
          orderBy: createdAt_DESC
        ) {
          availability (
            first: 1
            orderBy: createdAt_DESC
          ) {
            media {
              src
            }
          }
        }
      }
    }

    ... on ListItemTypeFlower {
      headNumber
      minimumHeadSize
      maximumHeadSize
      minimumStemLength
      stemLengthUnit
      headSizeUnit
      height
      weight
      maturity
      potSize
    }

    ... on ListItemTypeSundry {
      height
      weight
    }

    ... on ListItemTypePlant {
      minimumHeadSize
      maximumHeadSize
      headSizeUnit
      height
      weight
      maturity
      diameter
      thickness
      potSize
    }
  }
`;

export const EXPANDED_LIST_ITEMS_ON_LIST_FRAGMENT = gql`
  fragment ExpandedListItemFragment on List {
    items(
      orderBy: createdAt_DESC
    ) {
      ...FullListItemFragment
    }
  }
  ${FULL_LIST_ITEM_FRAGMENT}
`;

export const BASE_LIST_FRAGMENT = gql`
  fragment BaseListFragment on List {
    id
    title
    description
    date
    type
    merchant {
      id
      title
      currency
      wholesaleDeliveryConfigs {
        id
        hubs {
          title
          supplier {
            id
            active
            name
          }
        }
      }
    }
    suppliers {
      id
      active
      name
    }
    supplierDeliveryConfigs {
      id
      listItemTypes
      supplier {
        id
        active
      }
      deliveryOptions {
        freeShippingThreshold
        shippingPrice
      }
    }
  }
`;

export const LIST_ITEM_IDS_ON_LIST_FRAGMENT = gql`
  fragment ListItemIdsOnListFragment on List {
    items {
      ... on ListItem {
        id
      }
    }
  }
`;

export const LIST_WITH_ITEM_METADATA_FRAGMENT = gql`
  fragment ListWithItemMetadataFragment on List {
    ...BaseListFragment
    ...ExpandedListItemFragment
  }
  ${BASE_LIST_FRAGMENT}
  ${EXPANDED_LIST_ITEMS_ON_LIST_FRAGMENT}
`;

export const LIST_WITHOUT_ITEM_METADATA_FRAGMENT = gql`
  fragment ListWithoutItemMetadataFragment on List {
    id
    title
    description
    date
    type
    updatedAt
    merchant {
      id
      title
      currency
    }
    ...ListItemIdsOnListFragment
  }
  ${LIST_ITEM_IDS_ON_LIST_FRAGMENT}
`;

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Analytics } from 'lib';

import { SubscriptionPaymentModalData, PaymentSubmitProps } from 'features/modal-dialogue/components/modals/subscription-payment-modal/subscription-payment-modal.types';
import { PAYMENT_DETAILS_COPY } from 'features/payment/payment.constants';

import AccountChangeNotification from 'components/account-change-notification';
import FeatureList from 'components/feature-list';
import PaymentDetailsCard from 'components/payment-details-card';
import SectionHeading from 'components/section-heading';

import {
  PaymentsDetailProps,
  PaymentsDetailState
} from './payment-details.types';

class PaymentsDetails extends Component<PaymentsDetailProps, PaymentsDetailState> {
  state = {
    shouldDisplayError: true
  };

  private shouldRenderPaymentDetails = (): boolean => !!this.props.merchantStore!.merchant && this.props.userStore!.isSuperTeamRole();

  private onEditPaymentMethod = async (props: PaymentSubmitProps): Promise<void> => {
    await props.onUpdateSubscription!();
    this.hidePaymentError();
  };

  private hidePaymentError = (): void => {
    this.setState({ shouldDisplayError: false });
  };

  private triggerPaymentModal = async (): Promise<void> => {
    this.props.modalStore!.triggerModal<SubscriptionPaymentModalData>({
      modalType: 'subscriptionPayment',
      data: {
        title: 'Update your payment method',
        merchant: this.props.merchantStore!.merchant!,
        termsEntryPoint: Analytics.TermsEntryPoint.Settings,
        shouldDisplayTerms: false,
        onSubmit: this.onEditPaymentMethod
      }
    });
  };

  render(): JSX.Element | null {
    if (!this.shouldRenderPaymentDetails()) return null;

    return (
      <Box>
        <SectionHeading  title="Payment Details" />
        <AccountChangeNotification
          context="paymentDetails"
          wrapElement={(children): ReactNode => {
            if (!this.state.shouldDisplayError) return null;

            return (
              <Box m="20px 0">
                {children}
              </Box>
            );
          }}
        />
        <PaymentDetailsCard
          onClick={this.triggerPaymentModal}
          paymentMethod={this.props.merchantStore!.merchant?.paymentMethod}
        />
        <Box mt="40px">
          <FeatureList
            heading="These payment details will be used for:"
            config={PAYMENT_DETAILS_COPY}
          />
        </Box>
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  paymentsStore: stores.paymentsStore,
  merchantStore: stores.merchantStore,
  userStore: stores.userStore,
  modalStore: stores.modalStore
}))(observer(PaymentsDetails));

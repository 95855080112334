import { SupportedISOString } from 'global-types';

export const CURRENCY_SYMBOLS: { [key in SupportedISOString]: string } = {
  USD: '$',
  GBP: '£'
};

const renderVat = (value: number): number => {
  return value - (value / 1.2);
};

const renderWithoutVat = (value: number): number => {
  return value / 1.2;
};

export {
  renderWithoutVat,
  renderVat
};

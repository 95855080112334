import { Component } from 'react';

import { Box, Flex } from 'rebass';

import CheckboxIcon from 'components/checkbox-icon';
import CheckboxColourIcon from 'components/checkbox-icon/colour';

import * as Styles from './checkbox-item.styles';
import * as Types from './checkbox-item.types';

export default class CheckboxItem extends Component<Types.CheckboxItemProps> {
  static defaultProps = {
    isSelected: false
  };

  renderCheckboxIcon = (): JSX.Element => {
    return this.props.item.hexes ? (
      <CheckboxColourIcon
        isSelected={this.props.isSelected || this.props.checkboxList.selectedItems.includes(this.props.item[this.props.checkboxList.itemValueField])}
        isDisabled={this.props.item.isDisabled}
        error={this.props.checkboxList.error && this.props.isDirty}
        hexes={this.props.item.hexes}
      />
    ) : (
      <CheckboxIcon
        isSelected={this.props.isSelected || this.props.checkboxList.selectedItems.includes(this.props.item[this.props.checkboxList.itemValueField])}
        isDisabled={this.props.item.isDisabled}
        error={this.props.checkboxList.error && this.props.isDirty}
      />
    );
  };

  render(): JSX.Element {
    return (
      <Styles.CheckboxItem
        data-testid={`CheckboxItem-${this.props.item.value}` || ''}
        as="label"
        key={this.props.item[this.props.checkboxList.itemValueField]}
        alignItems="flex-start"
        orientation={this.props.checkboxList.orientation}
        isDisabled={this.props.item.isDisabled}
        css={this.props.checkboxStyles}
      >
        <input
          css={Styles.HiddenCheckbox}
          type="checkbox"
          name={this.props.item.slug}
          checked={this.props.checkboxList.selectedItems.includes(this.props.item[this.props.checkboxList.itemValueField])}
          onChange={(event): void => this.props.onChange(event, this.props.item)}
        />
        <Box
          flexBasis="30px"
          flexShrink="0"
        >
          {this.renderCheckboxIcon()}
        </Box>
        <Flex
          height="100%"
        >
          <Styles.CheckboxLabel
            isDisabled={this.props.item.isDisabled}
            truncated={this.props.item.truncated}
            isError={this.props.checkboxList.error && this.props.isDirty}
            css={this.props.checkboxLabelStyles}
          >
            {this.props.item[this.props.checkboxList.optionTitleField]}
            {
              this.props.metadataField && (
                <Styles.Metadata>
                  {this.props.item[this.props.metadataField]}
                </Styles.Metadata>
              )}
          </Styles.CheckboxLabel>
        </Flex>
      </Styles.CheckboxItem>
    );
  }
}

import { FC, useEffect, useState } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  ListItem,
  ListItemCreateWithoutListInput,
  ListItemType,
  ListWhereUniqueInput
} from 'generated-types.d';

import { PartialCatalogHit } from 'features/lists/components/catalog-inline-search/catalog-inline-search-field/catalog-inline-search-body.types';
import { CatalogInlineSearch } from 'features/lists/components/catalog-inline-search/catalog-inline-search-field/catalog-inline-search-field';
import { InlineSearchSelections } from 'features/lists/components/catalog-inline-search/catalog-inline-search.reducers';
import { ListHeadings } from 'features/lists/components/list-headings/list-headings';
import { groupListItemsByType, mapCatalogTypeToListItemType, shouldShowLimitedView } from 'features/lists/lists.helpers';

import { SINGLE_LIST_FIELD_CONFIG } from '../../single-list.config';
import { ListItemTabs } from '../list-item-tabs/list-item-tabs';

import {
  GroupWrapper,
  ItemsWrapper,
  Wrapper
} from './single-list-body.styles';
import {
  SingleListBodyProps
} from './single-list-body.types';
import { SingleListItem } from './single-list-item/single-list-item';

type ListItemTypeMetadata = {
  [key in ListItemType]: () => Partial<ListItemCreateWithoutListInput>;
}

type ItemToAddType = {
  item: ListItemCreateWithoutListInput;
  where?: ListWhereUniqueInput;
}

const SingleListBodyView: FC<SingleListBodyProps> = ({
  merchantStore,
  selectedListStore
}) => {
  const [groupedItems, setGroupedItems] = useState(groupListItemsByType<ListItem>(selectedListStore!.list?.items, item => item));
  const [selectedListCategory, setSelectedListCategory] = useState(ListItemType[
    Object.keys(groupedItems).find(key => groupedItems[key].items.length !== 0) as ListItemType || 'Flower'
  ]);

  useEffect(() => {
    const newGroupedItems = groupListItemsByType<ListItem>(selectedListStore!.list?.items, item => item);

    setGroupedItems(() => newGroupedItems);

    if (!selectedListStore?.list?.items?.some(item => item.type === selectedListCategory)) {
      setSelectedListCategory(() => ListItemType[
        Object.keys(newGroupedItems).find(key => newGroupedItems[key].items.length !== 0) as ListItemType || 'Flower'
      ]);
    }
  }, [selectedListStore!.list?.items]);

  const addItem = async (item: ItemToAddType, quantity?: number): Promise<void> => {
    if (!item) return;

    try {
      await selectedListStore?.addItem({
        item: quantity ? {
          ...item.item,
          quantity
        } : item.item,
        where: item.where
      });

      setSelectedListCategory(item.item.type || 'Flower');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleAddItem = async ({ item, selections }: {
    item: PartialCatalogHit;
    selections?: InlineSearchSelections;
  }): Promise<void> => {
    const baseData = getBaseData(item);

    if (!baseData) return Promise.reject();

    const buildMetadata = (): Pick<ListItemCreateWithoutListInput, 'flower' | 'sundry' | 'plant' | 'decoration'> => {
      const listItemTypeSchema: ListItemTypeMetadata = {
        Flower: () => {
          const minStemLength = selections?.['stemLength'] || null;

          return {
            flower: {
              create: {
                stemLengthUnit: 'cm',
                minimumStemLength: minStemLength ? parseInt(minStemLength) : null,
                maturity: selections?.['maturity'] || null
              }
            }
          };
        },
        Sundry: () => {
          return {
            sundry: {
              create: {
                height: selections?.['height'] || null,
                weight: selections?.['weight'] || null
              }
            }
          };
        },
        Decoration: () => {
          return {
            decoration: {
              create: {}
            }
          };
        },
        Plant: () => {
          return {
            plant: {
              create: {
                potSize: selections?.['potSize'] || null,
                height: selections?.['height'] || null
              }
            }
          };
        },
        Custom: () => {
          return {
            type: ListItemType.Custom
          };
        }
      };

      return listItemTypeSchema[item.type || ListItemType.Custom]?.() || {};
    };

    const itemToAdd: ItemToAddType = {
      where: baseData.where,
      item: {
        ...baseData.itemData,
        ...buildMetadata()
      }
    };

    await addItem(itemToAdd);
  };

  const handleDeleteItem = async (itemToDelete: ListItem): Promise<void> => {
    try {
      await selectedListStore!.deleteItem({ id: itemToDelete.id });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getBaseData = (item: PartialCatalogHit | null): { itemData: ListItemCreateWithoutListInput; where: ListWhereUniqueInput } | null => {
    if (!item || !selectedListStore!.list) return null;

    const colourId = item?.colour?.id;
    const catalogItemId = item.objectID;

    return {
      where: {
        id: selectedListStore!.list.id,
        merchant: {
          id: selectedListStore!.list.merchant?.id
        }
      },
      itemData: {
        sku: item.title || '',
        type: mapCatalogTypeToListItemType(item.type!)!,
        catalogItem: !!catalogItemId ? {
          connect: {
            id: catalogItemId
          }
        } : null,
        colour: !!colourId ? {
          connect: {
            id: colourId
          }
        } : null
      }
    };
  };

  if (!selectedListStore!.list) return null;

  const getFilters = (): string => {
    if (selectedListStore!.list?.suppliers?.length) {
      return `tradeSku.availability.supplierId:${selectedListStore!.list?.suppliers?.[0]?.id}`;
    }

    return '';
  };

  const onListCategorySelect = (category: ListItemType): void => {
    setSelectedListCategory(category);
  };

  const config = groupedItems[selectedListCategory];

  const shouldShowImages = !shouldShowLimitedView({
    inShareFlow: false,
    merchant: merchantStore?.merchant
  });

  return (
    <Wrapper>
      <CatalogInlineSearch
        listType={selectedListStore?.list?.type}
        selectedCategory={selectedListStore!.selectedCategory}
        onSelectItem={handleAddItem}
        onCategorySelect={selectedListStore!.setSelectedCategory}
        filters={getFilters()}
      />
      <Box mt={3}>
        <ListItemTabs
          content={groupedItems}
          shouldHideDisabled={true}
          onSelectItem={onListCategorySelect}
          selectedItem={selectedListCategory}
        />
      </Box>
      {config?.items?.length ? (
        <ItemsWrapper>
          <GroupWrapper key={config.title}>
            <ListHeadings
              listType={selectedListStore!.list?.type!}
              categoryScope={selectedListCategory}
              config={SINGLE_LIST_FIELD_CONFIG}
            />
            {config.items?.map((item: ListItem) => (
              <SingleListItem
                key={item.id}
                listItem={item}
                onDelete={handleDeleteItem}
                shouldShowImages={shouldShowImages}
              />
            ))}
          </GroupWrapper>
        </ItemsWrapper>
      ) : null
      }
    </Wrapper>
  );
};

export const SingleListBody = inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  selectedListStore: stores.selectedListStore
}))(observer(SingleListBodyView));

import { useMemo } from 'react';

import {
  WholesaleDeliveryConfig,
  MerchantWholesaleSupplier,
  Merchant
} from 'generated-types';

import { MerchantService } from 'lib';

export const useHasWholesaleShopAccess = ({
  wholesaleDeliveryConfig,
  merchantWholesaleSuppliers,
  merchant
}: {
  wholesaleDeliveryConfig: WholesaleDeliveryConfig | null;
  merchantWholesaleSuppliers: MerchantWholesaleSupplier[];
  merchant: Merchant | null;
}): {
  hasWholesaleShopAccess: boolean;
} => {
  const hasWholesaleShopAccess = useMemo((): boolean => {
    return !!wholesaleDeliveryConfig?.active
      && !!merchantWholesaleSuppliers.length
      && !!wholesaleDeliveryConfig?.hubs?.length
      && MerchantService.hasActiveSubscription(merchant);
  }, [
    wholesaleDeliveryConfig,
    merchant,
    merchantWholesaleSuppliers
  ]);

  return {
    hasWholesaleShopAccess
  };
};

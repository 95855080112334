import { CatalogItemType } from 'generated-types.d';

type ProductCategory = {
  title: string;
  key: CatalogItemType | 'All';
}

export const PRODUCT_CATEGORIES: ProductCategory[] = [
  {
    title: 'Flowers',
    key: CatalogItemType.Flower
  },
  {
    title: 'Decorations',
    key: CatalogItemType.Decoration
  },
  {
    title: 'Plants',
    key: CatalogItemType.Plant
  },
  {
    title: 'Sundries',
    key: CatalogItemType.Sundry
  },
  {
    title: 'Other',
    key: CatalogItemType.Other
  }
];

import React, { Component, ReactNode } from 'react';

import hex2rgba from 'hex2rgba';
import { Text } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './edit-in-place.styles';
import * as Types from './edit-in-place.types';

export default function editInPlace(WrappedComponent: React.ComponentClass<Types.EditableComponentProps>): any {
  return class extends Component<Types.EditInPlaceProps, Types.EditInPlaceState> {
    static defaultProps = {
      toggleEdit: (): any => null,
      hasAll: true,
      excludedButtons: []
    };

    state: Types.EditInPlaceState = {
      isEditing: false,
      isValid: true,
      isLoading: false,
      hasAll: this.props.hasAll,
      data: this.props.data,
      initialData: this.props.data
    };

    componentDidUpdate = (): void => {
      if (!this.state.hasAll && (this.props.data.length > this.state.initialData.length)) {
        this.setState({
          hasAll: true,
          data: this.props.data,
          initialData: this.props.data
        });
      }
    };

    componentDidMount = (): void => {
      this.setState({
        isEditing: false
      });
    };

    private onUpdateData = (data: any): void => {
      this.setState({ data });
    };

    private toggleEdit = (): void => {
      this.setState(prevState => ({
        isEditing: !prevState.isEditing
      }));
    };

    private onValidate = (isValid: boolean): void => {
      this.setState({ isValid });
    };

    private onSave = async (): Promise<void> => {
      this.setState({ isLoading: true });

      try {
        await this.props.onEdit(this.state.data, false);
        // await new Promise(() => {});

        this.setState({
          isEditing: false,
          isLoading: false
        });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    };

    private onReset = (): void => {
      this.setState(prevState => ({
        data: prevState.initialData,
        isEditing: false
      }));
    };

    private onDelete = async (): Promise<any> => {
      await this.props.onDiscard()
        .then(() => {
          this.setState({
            isValid: true,
            data: []
          });
        });
    };

    private shouldHideButton = (name: Types.ControlName): boolean => {
      if (this.props.excludedButtons.includes(name)) return true;

      switch (name) {
        case 'edit':
          return this.state.isEditing;

        case 'save':
          return !this.state.isEditing || !this.state.isValid || !this.state.data.length;

        default:
          return false;
      }
    };

    private closedControls: Types.ControlsConfigItem[] = [
      {
        name: 'edit',
        icon: 'edit',
        action: this.toggleEdit,
        bgcolor: colors.lightGrey,
        color: colors.floomMidnightBlue
      },
      {
        name: 'save',
        icon: 'tick',
        action: this.onSave,
        bgcolor: colors.lightGrey,
        color: colors.floomMidnightBlue
      },
      {
        name: 'delete',
        icon: 'bin',
        action: this.onDelete,
        bgcolor: colors.lightGrey,
        color: colors.floomMidnightBlue
      }
    ];

    private openControls: Types.ControlsConfigItem[] = [
      {
        name: 'save',
        icon: 'tick-small',
        action: this.onSave,
        bgcolor: colors.validationBg,
        color: colors.emeral,
        loadingCopy: 'Loading...'
      },
      {
        name: 'discard',
        icon: 'cross-small',
        action: this.onReset,
        bgcolor: colors.errorBg,
        color: colors.errorText
      }
    ];

    render(): ReactNode {
      const buttons = this.state.isEditing ? this.openControls : this.closedControls;

      return (
        <Styles.EditBoxContainer>
          <Styles.EditBox>
            <WrappedComponent
              isDisabled={!this.state.isEditing || !this.props.canEdit}
              data={this.state.data}
              onUpdate={this.onUpdateData}
              onValidate={this.onValidate}
            />
          </Styles.EditBox>

          {this.props.canEdit && (
            <Styles.EditControls>
              {buttons.map((btn, i) => {
                if (this.shouldHideButton(btn.name)) return null;

                const isShowingCopy = !!btn.loadingCopy && this.state.isLoading;

                return (
                  <Styles.IconButton
                    key={i}
                    disabled={this.state.isLoading}
                    isShowingCopy={isShowingCopy}
                    onMouseDown={btn.action}
                    css={{
                      backgroundColor: hex2rgba(btn.bgcolor, this.state.isLoading ? .5 : 1),
                      pointerEvents: this.state.isLoading ? 'none' : 'auto',
                      cursor: this.state.isLoading ? 'not-allowed' : 'pointer'
                    }}
                  >
                    { isShowingCopy ? (
                      <Text
                        css={textStyles.body}
                        color={hex2rgba(btn.color, this.state.isLoading ? .5 : 1)}
                      >
                        {btn.loadingCopy}
                      </Text>
                    ) : (
                      <Icon
                        iconName={btn.icon}
                        pathFill={hex2rgba(btn.color, this.state.isLoading ? .5 : 1)}
                      />
                    )

                    }
                  </Styles.IconButton>
                );
              })}
            </Styles.EditControls>
          )}
        </Styles.EditBoxContainer>
      );
    }
  };
}

import {
  FC
} from 'react';

import { MerchantWholesaleSupplier } from 'generated-types';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import pluralize from 'pluralize';

import { TEST_IDS } from 'utils/test/data-test-ids';

import {
  DeliveryDateWrapper,
  NextDeliveryDates,
  SupplierDeliveryCutoff,
  SupplierDeliveryCutoffContainer,
  SupplierDeliveryCutoffText,
  SupplierHeader,
  SupplierHeaderMobile,
  DeliveryDatesTitle
} from './header-delivery-date.styles';
import {
  HeaderDeliveryDateProps
} from './header-delivery-date.types';

const displayDeliveryDate = (date: string): string => {
  if (moment(date).isValid()) {
    return moment(date).format('ddd Do');
  }

  return 'Not Set';
};

const lookupSupplierName = (lookupId: string, suppliers: MerchantWholesaleSupplier[]): string | null => {
  const result = suppliers.find(s => s.supplier?.id === lookupId);

  return result ? result.supplier!.name : null;
};

export const HeaderDeliveryDate: FC<HeaderDeliveryDateProps> = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleShopStore: stores.wholesaleShopStore,
  wholesaleOrdersStore: stores.wholesaleOrdersStore
}))(observer(({
  wholesaleShopStore,
  isVisible
}) => {
  const shouldDisplay: boolean = !!isVisible
    && !!wholesaleShopStore!.nextAvailableDeliveryDates.length
    && !wholesaleShopStore!.isLoading;

  if (!shouldDisplay) return null;

  return (
    <DeliveryDateWrapper data-testid={TEST_IDS.WholesaleShopHeader.deliveryDate}>
      <NextDeliveryDates>
        <DeliveryDatesTitle>
          Next delivery {pluralize('date', wholesaleShopStore!.nextAvailableDeliveryDates.length)}
        </DeliveryDatesTitle>
        <SupplierDeliveryCutoffContainer>
          {wholesaleShopStore!.nextAvailableDeliveryDates.map(({ supplierId, dateString }) => {
            const name = lookupSupplierName(supplierId, wholesaleShopStore!.merchantWholesaleSuppliers);

            return (
              <SupplierDeliveryCutoff key={supplierId}>
                <SupplierHeader>{name}:</SupplierHeader>
                <SupplierHeaderMobile>{name?.split(' ').shift()}:</SupplierHeaderMobile>
                <SupplierDeliveryCutoffText>
                  {displayDeliveryDate(dateString)}
                </SupplierDeliveryCutoffText>
              </SupplierDeliveryCutoff>
            );
          })}
        </SupplierDeliveryCutoffContainer>
      </NextDeliveryDates>
    </DeliveryDateWrapper>
  );
}));

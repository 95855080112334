import { ApolloError } from '@apollo/client';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { observable, action, set, makeObservable } from 'mobx';

import {
  OrderEdge,
  OrderConnection,
  Order,
  OrderNote,
  OrderItem,
  Maybe
} from 'generated-types.d';
import {
  OmitType
} from 'global-types';

import {
  CacheService
} from 'lib';

import {
  OrderPageRoute,
  OrderGroupOption,
  OrderFormModalType,
  OrderFormModalMetadata,
  ExtendedOrder,
  ExtendedOrderStatus
} from 'features/orders/orders.types';

import * as Types from './orders-store.types';

export default class OrdersStore {
  constructor() {
    makeObservable(this, {
      isLoadingSearchToken: observable,
      searchToken: observable,
      quickSearchItems: observable,
      errors: observable,
      areFiltersVisible: observable,
      quickSearchLoading: observable,
      orderListLoading: observable,
      orderDetailLoading: observable,
      orderList: observable,
      currentOrder: observable,
      orderStatuses: observable,
      searchFilterValue: observable,
      orderGroupTotals: observable,
      orderReceiptData: observable,
      listLayout: observable,
      layoutOption: observable,
      pagination: observable,
      paginationIncrement: observable,
      isViewingReceipt: observable,
      showOrderDetailsModal: observable,
      showAddOnsModal: observable,
      orderDetailModalItems: observable,
      addOnModalItems: observable,
      orderFormModalOpen: observable,
      orderFormModalData: observable,
      orderFormModalConfig: observable,
      orderFormModalType: observable,
      orderNoteModalData: observable,
      orderNoteModalOpen: observable,
      activePastordersCount: observable,
      changedOrderStatuses: observable,
      hasUrgentOrders: action,
      setQuickSearchResults: action,
      setListLoading: action,
      toggleFiltersVisibility: action,
      closeFilters: action,
      setDetailLoading: action,
      setError: action,
      setOrderList: action,
      appendOrders: action,
      resetError: action,
      resetQuickSearch: action,
      resetOrderDetail: action,
      toggleQuickSearchLoading: action,
      setOrder: action,
      setListLayout: action,
      setLayout: action,
      setListSearchFilter: action,
      openOrderFormModal: action,
      closeOrderFormModal: action,
      openOrderDetailsModal: action,
      closeOrderDetailsModal: action,
      openAddOnsModal: action,
      closeAddOnsModal: action,
      setSearchToken: action,
      setActivePastordersCount: action,
      addChangedStatus: action
    });
  }

  private populateLayoutOption = (): Types.OrdersListLayout => {
    const cookie = CacheService.retrieveCookie('orderLayout');

    if (cookie?.content.name === 'orderLayout') {
      return cookie?.content.data;
    }

    return 'grid';
  };

  public isLoadingSearchToken: boolean = true;

  public searchToken: string | null = null;

  public quickSearchItems: OrderEdge[] = [];

  public quickSearchLoading: boolean = false;

  public orderDetailLoading: boolean = false;

  public orderListLoading: boolean = false;

  public orderReceiptData: Order | any = {};

  public isViewingReceipt: boolean = false;

  public searchFilterValue: string = '';

  public orderList: Types.OrderListType | Record<string, never> = {};

  public currentOrder: ExtendedOrder | any = {};

  public orderStatuses: ExtendedOrderStatus[] = [];

  public listLayout: OrderPageRoute = 'today';

  public layoutOption: Types.OrdersListLayout = this.populateLayoutOption();

  public orderDetailModalItems: OrderItem[] = [];

  public addOnModalItems: any = [];

  public orderFormModalOpen: boolean = false;

  public orderFormModalData: any = null;

  public orderFormModalConfig: OrderFormModalMetadata | null = null;

  public orderFormModalType: OrderFormModalType | null = null;

  public orderNoteModalData: { notes: OrderNote[]; orderNo: string } | null = null;

  public orderNoteModalOpen: boolean = false;

  public showOrderDetailsModal: boolean = false;

  public showAddOnsModal: boolean = false;

  public pagination: any = {};

  public paginationIncrement: number = 32;

  public areFiltersVisible: boolean = false;

  public activePastordersCount: number = 0;

  public orderGroupTotals: OmitType<Types.OrderGroupTotals, 'search'> = {
    today: null,
    tomorrow: null,
    upcoming: null,
    past: null,
    urgentOrders: null,
    peak: null
  };

  public errors: { [key in Types.OrderError]: ApolloError | null } = {
    orderList: null,
    orderEdit: null,
    quickSearch: null,
    orderTotals: null,
    orderStatuses: null,
    orderCounts: null
  };

  public setSearchToken = (token: string): void => {
    this.updateSearchClient(token);
    this.searchToken = token;
    this.isLoadingSearchToken = false;
  };

  public toggleFiltersVisibility = (): void => {
    this.areFiltersVisible = !this.areFiltersVisible;
  };

  public closeFilters = (): void => {
    if (this.areFiltersVisible) {
      this.areFiltersVisible = false;
    }
  };

  public setLayout(layoutOption: Types.OrdersListLayout): void {
    CacheService.storeCookieObject({
      name: 'orderLayout',
      data: layoutOption
    });

    this.layoutOption = layoutOption;
  }

  public setError = (errorType: Types.OrderError, error: ApolloError | null = null): void => {
    set(this.errors, {
      [errorType]: JSON.parse(JSON.stringify(error))
    });
  };

  public resetError = (errorType: Types.OrderError): void => {
    this.setError(errorType);
  };

  public setQuickSearchResults = (results: OrderEdge[]): void => {
    this.resetError('quickSearch');
    this.quickSearchLoading = false;
    this.quickSearchItems = results;
  };

  public resetQuickSearch = (): void => {
    this.quickSearchItems = [];
  };

  public toggleQuickSearchLoading = (): void => {
    this.quickSearchLoading = !this.quickSearchLoading;
  };

  public setListSearchFilter = (searchValue: string): void => {
    this.searchFilterValue = searchValue;
  };

  public setOrderList = (orderGroups: Types.OrderListType): void => {
    this.orderList = orderGroups;
    this.setListLoading(false);
  };

  public setOrder = (order: ExtendedOrder): void => {
    this.currentOrder = order;
    const orderGroupKeys = Object.keys(this.orderList) as OrderGroupOption[];

    if (order?.id) {
      orderGroupKeys.forEach(groupName => {
        if (!!this.orderList?.[groupName]?.edges?.length) {
          const index = this.orderList?.[groupName].edges.findIndex((orderEdge: Maybe<OrderEdge>) => {
            return orderEdge?.node.id === this.currentOrder.id;
          });

          if (index !== -1) {
            this.orderList![groupName]!.edges[index]!.node = this.currentOrder;
          }
        }
      });
    }
    this.setDetailLoading(false);
  };

  public setListLayout = (listLayout: OrderPageRoute): void => {
    this.listLayout = listLayout;
  };

  public setInitialPagination = (orderGroups: OrderGroupOption[]): void => {
    this.pagination = orderGroups.reduce((acc: any, currGroup) => ({
      ...acc, [currGroup]: 0
    }), {});
  };

  public appendOrders = (orderData: OrderConnection, groupName: OrderGroupOption): void => {
    const orderGroup = {
      ...orderData,
      edges: [
        ...this.orderList[groupName].edges,
        ...orderData.edges
      ]
    };

    set(this.orderList[groupName], orderGroup);
    this.setListLoading(false);
  };

  public setListLoading = (isLoading = true): void => {
    this.orderListLoading = isLoading;
  };

  public setDetailLoading = (isLoading = true): void => {
    this.orderDetailLoading = isLoading;
  };

  public resetOrderDetail = (): void => {
    this.currentOrder = {};
  };

  public viewOrderReceipt = (order: Order): void => {
    this.orderReceiptData = order;
    this.isViewingReceipt = true;
  };

  public hideOrderReceipt = (): void => {
    this.isViewingReceipt = false;
  };

  public openOrderDetailsModal = (orderItems: OrderItem[]): void => {
    this.orderDetailModalItems = orderItems;
    this.showOrderDetailsModal = true;
  };

  public closeOrderDetailsModal = (): void => {
    this.showOrderDetailsModal = false;
  };

  public openAddOnsModal = (addOns: any): void => {
    this.addOnModalItems = addOns;
    this.showAddOnsModal = true;
  };

  public closeAddOnsModal = (): void => {
    this.showAddOnsModal = false;
  };

  public updateNotes = (data: { orderNo: string; notes: OrderNote[] }): void => {
    Object.keys(this.orderList).forEach(groupId => {
      if (this.orderList[groupId].edges) {
        const index = this.orderList[groupId].edges
          .findIndex(({ node }: any) => node.orderNo === data.orderNo);

        if (index !== -1) {
          this.orderList[groupId].edges[index].node.orderNotes = data.notes;
        }
      }
    });
  };

  public openOrderFormModal = (
    type: OrderFormModalType,
    data: any,
    config: OrderFormModalMetadata
  ): void => {
    this.orderFormModalOpen = true;
    this.orderFormModalType = type;
    this.orderFormModalConfig = config;
    this.orderFormModalData = data;
  };

  public closeOrderFormModal = (): void => {
    this.orderFormModalOpen = false;
    this.orderFormModalType = null;
    this.orderFormModalConfig = null;
    this.orderFormModalData = null;
  };

  public hasUrgentOrders = (): boolean => {
    return !!this.orderGroupTotals.urgentOrders && !!this.orderGroupTotals.urgentOrders.aggregate.count;
  };

  public setActivePastordersCount(count: number): void {
    this.activePastordersCount = count;
  }

  private searchClient: SearchClient | null = null;

  private updateSearchClient(token: string): void {
    if (token !== this.searchToken && !!this.searchClient) {
      this.searchClient = null;
    }
  }

  public getSearchClient = (): SearchClient | null => {
    if (!this.searchToken) {
      return null;
    }

    if (!!this.searchClient) return this.searchClient;

    this.searchClient = algoliasearch(process.env.ALGOLIA_APPLICATION_ID!, this.searchToken);

    return this.searchClient;
  };

  public changedOrderStatuses: Record<string, ExtendedOrderStatus | null> = {};

  public addChangedStatus = (orderId: string, status: ExtendedOrderStatus | null): void  => {
    this.changedOrderStatuses = {
      ...this.changedOrderStatuses,
      [orderId]: status
    };
  }
}

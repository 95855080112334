import {
  FC,
  Fragment,
  MouseEvent,
  useEffect,
  useState
} from 'react';

import { css, Global } from '@emotion/react';
import { inject, observer } from 'mobx-react';

import { NavService } from 'lib';

import { ConfirmDeleteTooltip } from 'features/lists/components/confirm-delete-tooltip/confirm-delete-tooltip';
import { SidebarStyles } from 'features/modal-dialogue/modal-dialogue.styles';

import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import GenericModal from 'components/generic-modal';
import Icon from 'components/icon';

import {
  DeleteButton,
  Spacer,
  Wrapper
} from './list-settings-sidebar.styles';
import {
  ListSettingsSidebarProps
} from './list-settings-sidebar.types';

const ListSettingsSidebar: FC<ListSettingsSidebarProps> = ({
  closeModal,
  isOpen,
  selectedListStore,
  conversationsStore,
  data
}) => {
  const id = selectedListStore!.list?.id;
  const isSelectedSupplierDisabled = !!selectedListStore!.list?.suppliers?.length && !selectedListStore!.list.suppliers[0].active;

  const [draftTitle, setDraftTitle] = useState(selectedListStore!.list?.title || '');
  const [draftDescription, setDraftDescription] = useState(selectedListStore!.list?.description || '');
  const [draftSelectedSupplierId, setDraftSelectedSupplierId] = useState<string | null>(selectedListStore!.list?.suppliers?.[0]?.id || null);
  const [draftDate, setDraftDate] = useState(selectedListStore!.list?.date || '');
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [filteredSuppliers] = useState(selectedListStore!.availableSuppliers.filter(supplier => supplier.active || (!supplier.active && draftSelectedSupplierId === supplier.id)));

  /**
   * Used to only ensure we autofocus on inputs once, and not
   * over and over again on additional renders.
   */
  useEffect(() => {
    setTimeout(() => {
      setHasMounted(true);
    }, 200);
  }, []);

  const updateListSettings = async (): Promise<void> => {
    setIsLoading(true);

    await selectedListStore!.updateList({
      id: id!,
      data: {
        title: draftTitle,
        description: draftDescription,
        date: draftDate,
        ...(!!draftSelectedSupplierId ? {
          suppliers: {
            set: [{
              id: draftSelectedSupplierId
            }]
          }
        } : {})
      }
    });

    setIsLoading(false);
    closeModal();
  };

  const handleDeleteList = (event: MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget);
  };

  const confirmDeleteList = async (): Promise<void> => {
    try {
      setIsDisabled(true);
      await selectedListStore!.deleteList({ listId: id! });
      conversationsStore!.resetShareListFlow();
      NavService.listsList();
      handleCloseMenu();
      setIsDisabled(false);
      closeModal();
    } catch (error) {
      setIsDisabled(false);

      return;
    }
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const buildForm = (): FormBuilderConfig => ({
    sections: [
      {
        width: '100',
        paddingBottom: '20px',
        fields: [
          {
            key: 'listTitleLabel',
            fieldType: 'labelSmall',
            copy: 'List Name',
            isSmallCaps: true,
            validationError: undefined
          },
          {
            key: 'listTitleInput',
            fieldType: 'textInput',
            placeholder: 'Add a title',
            value: draftTitle,
            validationError: undefined,
            isDisabled: isDisabled,
            onChange: (e): void => setDraftTitle(e.target.value)
          }
        ]
      },
      {
        width: '100',
        paddingBottom: '20px',
        isHidden: filteredSuppliers.length === 1 && !!selectedListStore!.list?.suppliers?.length,
        fields: [
          {
            key: 'listSupplierLabel',
            fieldType: 'labelSmall',
            copy: 'Supplier',
            isSmallCaps: true,
            validationError: undefined
          },
          {
            key: 'listSupplierInput',
            id: 'list-settings-supplier-selection',
            fieldType: 'dropdown',
            placeholder: 'Select a supplier',
            fullWidth: true,
            hasError: isSelectedSupplierDisabled,
            optionTitleField: 'title',
            optionValueField: 'value',
            selected: draftSelectedSupplierId || '',
            onChange: (value): void => setDraftSelectedSupplierId(value || null),
            options: filteredSuppliers.map(supplier => {
              return {
                title: supplier.name,
                value: supplier.id,
                disabled: !supplier.active
              };
            }) || [],
            validationError: isSelectedSupplierDisabled ? {
              label: 'The selected supplier is disabled, please select another. It will not be possible to place a pre-order with this supplier.',
              originalValue: '',
              path: '',
              value: ''
            } : undefined
          }
        ]
      },
      {
        width: '100',
        paddingBottom: '20px',
        fields: [
          {
            key: 'listDescriptionLabel',
            fieldType: 'labelSmall',
            copy: 'Description',
            isOptional: true,
            isSmallCaps: true,
            validationError: undefined
          },
          {
            key: 'listDescriptionInput',
            fieldType: 'textArea',
            placeholder: 'Enter description',
            value: draftDescription,
            autoFocus: data?.autofocusOn === 'description' && !hasMounted,
            validationError: undefined,
            disabled: isDisabled,
            onChange: (e): void => setDraftDescription(e)
          }
        ]
      },
      {
        width: '100',
        paddingBottom: '20px',
        fields: [
          {
            key: 'listDeliveryDateLabel',
            fieldType: 'labelSmall',
            copy: 'Expected delivery date',
            isOptional: true,
            isSmallCaps: true,
            validationError: undefined
          },
          {
            key: 'listDeliveryDatePicker',
            fieldType: 'datePicker',
            value: draftDate!,
            validationError: undefined,
            isClearable: true,
            shouldAutofocus: data?.autofocusOn === 'date' && !hasMounted,
            pickerType: 'popover',
            format: 'DD MMM YYYY',
            isDisabled: isDisabled,
            disabledDays: {
              before: new Date()
            },
            onClear: (): void => {
              setDraftDate('');
            },
            onChange: (e): void => {
              setDraftDate(e?.toUTCString?.() || '');
            }
          }
        ]
      }
    ]
  });

  return (
    <Fragment>
      <Global styles={SidebarStyles('list-settings-')} />
      <GenericModal
        title="Edit List Details"
        confirmButtonText="Confirm"
        closeModal={closeModal}
        confirmButtonAction={updateListSettings}
        confirmButtonDisabled={isDisabled}
        isButtonLoading={isLoading}
        isConfirmButtonParentWidth={true}
        footerStickToBottom={true}
        modalOpen={isOpen}
        width={440}
        closeTimeoutMS={2000}
        overlayClassName={{
          base: 'list-settings-sidebar__OverlaySlide',
          afterOpen: 'list-settings-sidebar__OverlaySlide--after-open',
          beforeClose: 'list-settings-sidebar__OverlaySlide--before-close'
        }}
        contentClassName={{
          base: 'list-settings-sidebar__ContentSlide',
          afterOpen: 'list-settings-sidebar__ContentSlide--after-open',
          beforeClose: 'list-settings-sidebar__ContentSlide--before-close'
        }}
        innerContentStyles={{
          paddingBottom: '60px'
        }}
        contentTransform=""
        contentLayoutType="full-height"
        actionButtonStyles={css`
          width: 100%;
        `}
        innerComponent={(
          <Wrapper>
            <FormBuilder
              config={buildForm()}
            />
          </Wrapper>
        )}
        headerAction={(
          <Fragment>
            <DeleteButton onClick={handleDeleteList}>
              <Icon
                iconName="bin"
                styles={{
                  marginRight: '10px',
                  width: '15px'
                }}
              />
              Delete list
            </DeleteButton>
            <Spacer />
            <ConfirmDeleteTooltip
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              onDeleteItem={confirmDeleteList}
            />
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  selectedListStore: stores.selectedListStore,
  conversationsStore: stores.conversationsStore
}))(observer(ListSettingsSidebar));

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { AbsoluteCover } from 'utils/css-mixins/css-mixins';
import { colors, borderRadius, shadows, textStyles } from 'utils/rebass-theme';

export const Header = css`
  width: 100%;
`;

export const HeaderInner = css`
  margin: 20px 20px 5px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.middleGrey};
`;

export const BackArrow = css`
  height: 21px;
  width: 17px;
`;

export const GenericStyles = css`
  .DayPicker {
    z-index: 1;
    background: ${colors.uiFills};
    border-radius: 3px;

    &:not(.DayPicker--interactionDisabled) {
      .DayPicker-Day {
        &:focus {
          outline: none;
        }

        &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
          &:hover {
            background-color: ${colors.background};
          }
        }
      }
    }
  }

  .DayPicker-TodayButton {
    color: ${colors.floomMidnightBlue};
    background: ${colors.paleGrey};
    padding: 10px 12px 11px;
    border-radius: 5px;
    font-size: 12px;
  }

  .DayPicker-Footer {
    padding: 0;
    text-align: center;
  }

  .DayPickerInput-OverlayWrapper {
    z-index: 1;
  }

  .DayPicker-wrapper {
    border-radius: ${borderRadius.medium};
    border: none;

    &:focus {
      outline: none;
    }
  }

  .DayPickerInput-Overlay {
    display: inline-block;
    z-index: 1;
    background: white;
  }

  .DayPickerInput {
    width: 100%;
  }

  .DayPicker-Month {
    margin: 20px;
  }

  .DayPicker-Weekdays {
    padding-bottom: 5px;
  }

  .DayPicker-Weekday {
    ${textStyles.footnote};
    text-transform: uppercase;
  }

  .DayPicker-Day {
    ${textStyles.body}
    position: relative;
    padding: 0;
    min-width: 44px;
    height: 44px;
    transition: background-color .1s ease-in-out,
                color .1s ease-in-out;

    &:hover {
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
        background: ${colors.middleGrey};
      }
    }

    &--today {
      font-weight: 600;
      color: ${colors.floomMidnightBlue};

      &:before {
        content: '';
        ${AbsoluteCover}
        border: 1px solid ${colors.floomMidnightBlue};
        border-radius: 100%;
      }
    }

    &--selected {
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: ${colors.floomMidnightBlue};
      }
    }

    &--disabled {
      :not(.DayPicker-Day--outside) {
        cursor: not-allowed;
        background-color: ${colors.lightGrey};
        color: ${colors.shade40};
      }
    }
  }

  .DayPicker-Caption > div {
    text-align: center;
  }
`;

export const PopoverWrapper = styled.div<{ customStyles: any }>`
  ${GenericStyles};

  .DayPicker {
    width: 100%;
    height: 100%;
  }

  .DayPicker-wrapper {}

  .DayPickerInput-OverlayWrapper {
    ${AbsoluteCover}
    background: ${colors.uiFills};
    overflow: scroll;
  }

  .DayPickerInput-Overlay {
    position: unset;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }

  .DayPickerInput {}

  .DayPicker-Month {}

  .DayPicker-Weekdays {}

  .DayPicker-Weekday {}

  .DayPicker-Day {
    &--today {}
    &--selected {}
    &--disabled {}
  }

  .DayPicker-Caption > div {}

  ${(props): any => props.customStyles}
`;

export const TooltipWrapper = styled.div<{ customStyles: any }>`
  ${GenericStyles};

  .DayPicker {
    position: relative;
    margin-bottom: 20px;
  }

  .DayPickerInput-OverlayWrapper {
    min-width: 350px;
  }

  .DayPicker-wrapper {}

  .DayPickerInput-Overlay {
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: ${shadows.small};
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background: ${colors.uiFills};
      top: -6px;
      transform: rotate(45deg);
      left: 10px;
      z-index: 0;
      box-shadow: 0 0 6px rgba(0,0,0,0.2);
    }
  }

  .DayPickerInput {
    position: relative;
  }

  .DayPicker-Month {}

  .DayPicker-Weekdays {}

  .DayPicker-Weekday {}

  .DayPicker-Day {
    &--today {}
    &--selected {}
    &--disabled {}
  }

  .DayPicker-Caption > div {}

  ${(props): any => props.customStyles}
`;

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import GenericModal from 'components/generic-modal';

import AddOns from '../../pages/order-detail/components/add-ons';

import * as Styles from './add-ons-modal.styles';

class AddOnsModal extends Component<any> {
  render(): ReactNode {
    return (
      <GenericModal
        title="Add-ons"
        shouldHideFooter={true}
        closeModal={this.props.ordersStore.closeAddOnsModal}
        modalOpen={this.props.ordersStore.showAddOnsModal}
        width={485}
      >
        <Styles.Container>
          <AddOns
            addOns={this.props.ordersStore.addOnModalItems}
            height={80}
          />
        </Styles.Container>
      </GenericModal>
    );
  }
}

export default inject('ordersStore')(observer(AddOnsModal));

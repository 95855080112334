import { Fragment } from 'react';

import moment from 'moment';

import { Message as MessageShape } from 'types/conversations.types';

import PlantsIconImage from 'assets/images/wholesale/no-results.gif';

import { LoadingImage } from 'components/loading-image/loading-image';
import WithLoading from 'components/with-loading';

import { Message } from '../message/message';

import { MessagesElement, DateSeparator } from './messages.styles';
import { MessagesProps, Position } from './messages.types';

export const Messages: React.FC<MessagesProps> = ({ error, loading, messages }) => {
  interface SortedDates {
    date: MessageShape[];
  }

  const getMessage = (index: number): MessageShape | undefined => (index >= 0 && index < messages.length) ? messages[index] : undefined;

  const isDifferentSender = (a: MessageShape, b: MessageShape): boolean => a.messageType !== b.messageType;

  const shouldShowAvatar = (message: MessageShape, index: number): boolean => {
    const prevMessage = getMessage(index - 1);
    const isFromDifferentAuthor = !prevMessage ||
      isDifferentSender(prevMessage, message);

    const isDifferentDate = !prevMessage ||
      moment(message.createdAt).date() !== moment(prevMessage.createdAt).date();

    return isFromDifferentAuthor || isDifferentDate;
  };

  const groupByDay = (messageList: MessageShape[]): Record<never, SortedDates> => {
    return messageList.reduce((acc, message) => {
      const messageDay = moment(message.createdAt).format('YYYY-MM-DD');

      if(acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([message]) };
      }

      return { ...acc, [messageDay]: [message] };
    }, {});
  };

  const getMessagePosition = (message: MessageShape, index: number): Position => {
    const prevMessage = getMessage(index - 1);
    const isLast = !prevMessage ||
      isDifferentSender(prevMessage, message) ||
      moment(message.createdAt).date() !== moment(prevMessage.createdAt).date();

    const nextMessage = getMessage(index + 1);
    const isFirst = !nextMessage ||
      isDifferentSender(nextMessage, message) ||
      moment(message.createdAt).date() !== moment(nextMessage.createdAt).date();

    const isOnly = isFirst && isLast;
    const isMiddle = !isFirst && !isLast;

    switch (true) {
      case isOnly:
        return Position.SINGLE;
      case isMiddle:
        return Position.MIDDLE;
      case isFirst:
        return Position.FIRST;
      case isLast:
        return Position.LAST;

      default:
        return Position.MIDDLE;
    }
  };

  const groupedMessages = groupByDay(messages);

  const dateFormat = 'dddd, MMM Do';

  const renderDate = (messageDate: string): React.ReactElement => {
    return (
      <DateSeparator
        pt={2}
        pb={3}
      >
        {moment(messageDate).calendar(undefined, {
          sameDay: '[Today]',
          lastDay: '[Yesterday]',
          lastWeek: dateFormat,
          sameElse: dateFormat
        })}
      </DateSeparator>
    );
  };

  if (error) {
    return (
      <Fragment>
        Error retrieving messages
      </Fragment>
    );
  }

  return (
    <Fragment>
      <WithLoading
        loaderSize="small"
        marginTop="30px"
        isLoading={loading}
        hasNoResults={messages.length === 0}
        renderNoResults={() => null}
        renderLoading={() => (
          <LoadingImage
            text="Loading messages..."
            imageSrc={PlantsIconImage}
            imageAlt="plants"
          />
        )}
      >
        <MessagesElement
          p={3}
          pb={1}
        >
          {
            messages.map((message, index) => {
              const messageDate = moment(message.createdAt).format('YYYY-MM-DD');

              if (groupedMessages[messageDate].slice(-1).pop() === message) {
                return (
                  <Fragment key={message.id}>
                    <Message
                      shouldShowAvatar={shouldShowAvatar(message, index)}
                      message={message}
                      position={getMessagePosition(message, index)}
                    />
                    {renderDate(messageDate)}
                  </Fragment>
                );
              }

              return (
                <Message
                  key={message.id}
                  message={message}
                  position={getMessagePosition(message, index)}
                  shouldShowAvatar={shouldShowAvatar(message, index)}
                />
              );
            })
          }
        </MessagesElement>
      </WithLoading>
    </Fragment>
  );
};

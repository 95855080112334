import { FetchResult } from '@apollo/client';
import store from 'stores';

import { GraphQL } from 'lib';

import MerchantService from 'utils/global-services/merchant';

import {
  CREATE_MERCHANT_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD
} from 'features/payment/graphql/mutations/create-merchant-subscription';

class SubscriptionService {
  private ToasterStore = store.toasterStore;

  private MerchantStore = store.merchantStore;

  public createMerchantSubscription = async (stripe: any, merchantId: string): Promise<any> => {
    return GraphQL.mutate(CREATE_MERCHANT_SUBSCRIPTION, this.buildCreateSubscriptionVars(stripe, merchantId))
      .then((result: FetchResult<any>): any => result)
      .catch(error => {
        this.ToasterStore.popErrorToast('you to FloomX', 'subscribe');

        return Promise.reject(error);
      });
  };

  private buildCreateSubscriptionVars = (stripe: any, merchantId: string): any => ({
    merchantId: merchantId,
    stripeToken: stripe.id,
    last4: stripe.card.last4
  });

  public updateMerchantSubscriptionPaymentMethod = async (stripe: any): Promise<any> => {
    if (this.MerchantStore.merchant && this.MerchantStore.merchant.subscription) {
      return GraphQL.mutate(UPDATE_SUBSCRIPTION_PAYMENT_METHOD, {
        customerId: this.MerchantStore.merchant!.subscription.stripeSubscriptionCustomerId,
        merchantId: this.MerchantStore.merchant.id,
        paymentToken: stripe.id,
        last4: stripe.card.last4
      })
        .then((result: FetchResult<any>): any => {
          MerchantService.initializeActingMerchantDetails();

          return result;
        })
        .catch(error => {
          this.ToasterStore.popErrorToast('your payment method', 'update');

          return error;
        });
    }

    return Promise.reject('No Merchant Selected');
  };
}

export default new SubscriptionService();

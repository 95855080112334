export const SORT_LIST_OPTIONS = [
  {
    title: 'Title A-Z',
    key: 'title_ASC'
  },
  {
    title: 'Title Z-A',
    key: 'title_DESC'
  },
  {
    title: 'First Created',
    key: 'createdAt_ASC'
  },
  {
    title: 'Last Created',
    key: 'createdAt_DESC'
  },
  {
    title: 'Last Updated',
    key: 'updatedAt_ASC'
  },
  {
    title: 'First Updated',
    key: 'updatedAt_DESC'
  }
];

export const STOCK_VALUES = {
  outOfStock: 0,
  lowStockMax: 10
};

export const STOCK_GROUPS = [
  'outOfStock',
  'lowStock',
  'highStock'
];

import { Component } from 'react';

import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import CourierList from 'features/courier-bookings/pages/courier-list';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

export default class Orders extends Component {
  render(): JSX.Element {
    return (
      <AppLayout>
        {/*
        // @ts-ignore */}
        <Helmet>
          <title>Courier Bookings</title>
        </Helmet>
        <Router>
          <AuthedRoute
            path="/courier"
            component={CourierList}
          />
          <AuthedRoute
            path="/courier/list"
            component={CourierList}
          />
        </Router>
      </AppLayout>
    );
  }
}

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { breakpoints, fontSizes, space } from 'utils/rebass-theme';

type TabButtonProps = {
  color: string;
  disabled: boolean;
  selected: boolean;
}

export const TabButton = styled(Box)<TabButtonProps>`
  padding: ${space[3]}px;
  font-size: ${fontSizes.small}px;
  color: ${({ colour }) => colour};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  border-bottom: 3px solid ${({ colour, selected }) => selected ? colour : 'transparent'};
  transition: border .2s ease-in-out, color .2s ease-in-out;
  min-width: 90px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    padding: ${space[3]}px ${space[2]}px;
    font-size: ${fontSizes.xSmall}px;
  }
`;

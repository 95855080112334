import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import {
  borderRadius,
  breakpoints,
  colors,
  textStyles
} from 'utils/rebass-theme';

export const DeliveryDate = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-top: 3px;
`;

export const PreOrderButton = styled(Box)`
  text-align: left;
`;

export const ButtonWrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ backgroundColor }: any): string => backgroundColor || colors.floomMidnightBlue};
  color: ${({ color }: any): string => color || colors.white};
  padding: 11px 20px 16px;
  font-size: 14px;
  height: 70px;
  border-radius: ${borderRadius.large};
  opacity: ${({ isDisabled }: any): number => (isDisabled ? 0.5 : 1)};
  cursor: ${({ isDisabled }: any): string => isDisabled ? 'not-allowed' : 'pointer'};
`;

export const DeliveryDateWrapper = styled(Box)`
  min-width: 220px;
  max-width: 220px;
  flex-grow: 1;

  @media (max-width: ${breakpoints.medium}) {
    flex: 1;
    min-width: unset;
  }
`;

export const PlaceOrderWrapper = styled(Box)`
  min-width: 220px;
  max-width: 220px;
  flex-grow: 1;

  @media (max-width: ${breakpoints.medium}) {
    flex: 1;
    min-width: unset;
  }
`;

/**
 * List totals styles. Used for checkout / order views
 */
export const TotalsTable = styled(Box)<{ hideTopBorder?: boolean }>`
 font-size: 16px;

 ${({ hideTopBorder }) => hideTopBorder ? '' : `border-top: 3px solid ${colors.floomMidnightBlue};`}
`;

export const TotalRow: any = styled(Flex)`
 padding: 20px 15px;
 justify-content: space-between;
 align-items: center;

 + ${() => TotalRow} {
   border-top: 1px solid ${colors.middleGrey};
 }
`;

export const GrandTotalRow = styled(TotalRow)<{ sticky?: boolean }>`
 background: ${colors.uiFills};
 border-top: 2px solid ${colors.floomMidnightBlue};

 ${({ sticky = true }) => sticky ? `
   position: sticky;
   bottom: 0;
   z-index: 9;
 ` : ''}
`;

export const TotalValueColumn: any = styled(Box)`
 text-align: right;
 padding-left: 15px;
`;

export const TotalSubCopy = styled(Box)`
 ${textStyles.footnote};
 margin-top: 5px;
 font-weight: 400;
 color: ${colors.shade60};
`;

export const TotalToBeCalculatedColumn: any = styled(Box)`
 ${textStyles.footnote};
 text-align: right;
 padding-left: 15px;
 font-weight: 400;
 color: ${colors.shade60};
`;

import { css } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Container = styled(Flex)`
  position: relative;
  align-items: center;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  min-height: 420px;
  padding: 60px 20px 20px 20px;
  text-align: center;
  background: ${colors.floomCream};
`;

export const IconClose: StyledComponent<any, any, any> = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
`;

export const ButtonContainer: StyledComponent<any, any, any> = styled(Box)`
  width: 100%;
  margin-top: 100px;
`;

export const Title = css`
  ${textStyles.title};
  max-width: 245px;
`;

import { ApolloQueryResult, ApolloError } from '@apollo/client';
import { toJS } from 'mobx';
import store from 'stores';

import {
  MerchantConnection,
  InviteToFloomXWhereUniqueInput,
  InviteToFloomXResponse,
  MerchantEdge,
  Merchant,
  PageInfo,
  UpdateMerchantActiveStatusInput,
  UpdateMerchantActiveStatusResponse
} from 'generated-types.d';

import { GraphQL } from 'lib';

import MerchantSettingsStore from 'stores/merchant-settings/merchant-settings-store';
import {
  MerchantsFilters,
  SelectedFilters
} from 'stores/merchant-settings/merchant-settings-store.types';

import { MERCHANT_LIST_FILTER_COUNTS } from 'features/settings/components/merchants/merchant-controls/graphql/merchant-control-queries';
import { INVITE_MERCHANT_TO_FLOOMX, UPDATE_MERCHANT_ACTIVE_STATUS } from 'features/settings/graphql/mutators/merchant-settings.mutators';
import { MERCHANT_LIST, SINGLE_MERCHANT } from 'features/settings/graphql/queries/merchant-settings.queries';

import * as Types from './merchant-settings.service.types';

export default class MerchantsSettingsService {
  private MerchantSettingsStore = store.merchantSettingsStore as MerchantSettingsStore;

  private MerchantDetailStore = store.merchantDetailStore;

  public toggleActiveStatus = async (merchantId: string, isChecked: boolean): Promise<UpdateMerchantActiveStatusResponse> => {
    const data: UpdateMerchantActiveStatusInput = {
      isActive: isChecked,
      where: {
        id: merchantId
      }
    };

    return GraphQL.query(UPDATE_MERCHANT_ACTIVE_STATUS, { data })
      .then((value: ApolloQueryResult<{ results: UpdateMerchantActiveStatusResponse }>) => {
        return value.data.results;
      })
      .catch((error: ApolloError) => {
        return Promise.reject(error);
      });
  };

  public fetchMerchants = async (shouldReplace: boolean = true): Promise<void> => {
    const vars = this.buildVariables();

    return GraphQL.query(MERCHANT_LIST(vars), vars)
      .then((results: ApolloQueryResult<{ merchantsConnection: MerchantConnection }>): any => {
        this.MerchantSettingsStore.setMerchantList(
          results.data.merchantsConnection!.edges as MerchantEdge[],
          shouldReplace
        );
        this.MerchantSettingsStore.setMerchantPageInfo(results.data.merchantsConnection!.pageInfo as PageInfo);
      })
      .catch((error: ApolloError) => {
        this.MerchantSettingsStore.setMerchantList();
        this.MerchantSettingsStore.resetLoading();

        return Promise.reject(error);
      });
  };

  public fetchMerchant = async (id: string): Promise<Merchant> => {
    return GraphQL.query(SINGLE_MERCHANT, { id })
      .then((results: ApolloQueryResult<{ merchant: Merchant }>): Merchant => {
        this.MerchantDetailStore.setSelectedMerchant(results.data.merchant);

        return results.data.merchant;
      })
      .catch((error: ApolloError) => {
        this.MerchantSettingsStore.setMerchantList();

        return Promise.reject(error);
      });
  };

  public paginateMerchants = async (): Promise<void> => {
    const shouldReplaceList = false;

    this.MerchantSettingsStore.onPaginate();
    this.MerchantSettingsStore.setLoading();
    this.fetchMerchants(shouldReplaceList);
  };

  public fetchFilterCounts = async (): Promise<void> => {
    const vars = this.buildVariables();
    const filters: MerchantsFilters = this.MerchantSettingsStore.filters;
    const selectedFilters: SelectedFilters = toJS(this.MerchantSettingsStore.selectedFilters);

    return GraphQL.query(MERCHANT_LIST_FILTER_COUNTS(vars, filters, selectedFilters), vars, 'no-cache')
      .then((results): any => {
        this.MerchantSettingsStore.setFilterCounts(results.data);

        return results.data;
      })
      .catch((error: ApolloError) => {
        return Promise.reject(error);
      });
  };

  public inviteMerchantToFloomX = async (merchantId: string): Promise<InviteToFloomXResponse> => {
    const where: InviteToFloomXWhereUniqueInput = { id: merchantId };

    try {
      const inviteToFloomX: ApolloQueryResult<{ inviteToFloomX: InviteToFloomXResponse }> = await GraphQL.query(INVITE_MERCHANT_TO_FLOOMX, { where });
      await this.fetchMerchant(merchantId);

      return inviteToFloomX.data.inviteToFloomX;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  private buildVariables = (): Types.MerchantFilterVariables => {
    return {
      search: this.MerchantSettingsStore.searchValue,
      displayActive: this.MerchantSettingsStore.isShowingInactiveMerchants,
      orderBy: this.MerchantSettingsStore.sortValue,
      filters: this.MerchantSettingsStore.selectedFilters,
      first: this.MerchantSettingsStore.paginationIncrement,
      count: this.MerchantSettingsStore.paginationCount
    };
  };
}

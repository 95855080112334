import { Fragment, useState, memo } from 'react';

import { css } from '@emotion/react';
import {
  RefinementListProvided,
  connectRefinementList
} from 'react-instantsearch-core';
import { Box, Flex } from 'rebass';

import CheckboxList from 'components/checkbox-list';
import Icon from 'components/icon';

import { AccordionHeading } from './catalog-refinement-list.styles';

interface RefinementListProps extends RefinementListProvided {
  name: string;
}

export const CatalogRefinementList = connectRefinementList(memo((props: RefinementListProps): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(true);

  if (!props.items.length) return null;

  return (
    <Fragment>
      <AccordionHeading
        as="button"
        onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}
      >
        <Box>
          {props.name}
        </Box>
        <Flex>
          <Icon
            iconName={isOpen ? 'arrow-down' : 'arrow-up'}
          />
        </Flex>
      </AccordionHeading>
      {isOpen && (
        <CheckboxList
          selectedItems={props.currentRefinement}
          itemValueField="value"
          optionTitleField="title"
          orientation="vertical"
          checkboxStyles={css`
            margin-bottom: 0;
          `}
          wrapperStyles={css`
            grid-gap: 10px;
          `}
          items={props.items
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(item => {
              return {
                title: `${item.label} (${item.count})`,
                value: item.label,
                isDisabled: item.count === 0
              };
            })}
          onChange={(value): void => {
            const item = props.items.find(refinementItem => refinementItem.label === value);

            if (item) {
              props.refine(item.value);
            }
          }}
        />
      )}
    </Fragment>
  );
}));

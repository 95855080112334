import styled from '@emotion/styled';

import { textStyles, colors } from 'utils/rebass-theme';

export const OrderSummary = styled.table`
  margin: 0;
`;

export const SummaryRow = styled.td`
  ${textStyles.body};
  padding: 6px 0;
  border: none;

  &:last-of-type {
    ${textStyles.subhead};
    text-align: right;
  }
`;

export const BorderRow = styled.tr`
  border-top: 20px solid transparent;

  td {
    vertical-align: top;

    > div {
      padding-top: 20px;
      border-top: 1px solid ${colors.middleGrey};
    }
  }
`;

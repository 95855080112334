import { Reducer } from 'react';

import { ReducerActionMap } from 'global-types';

import { ListFieldKey } from 'features/lists/lists.types';

export type InlineSearchSelections = {
  [key in ListFieldKey]?: string | null;
}

export interface SingleSearchReducerItem {
  selections: InlineSearchSelections;
  isLoading: boolean;
}

export type SingleSearchReducerState = {
  [key in string]?: SingleSearchReducerItem;
}

export enum SearchReducerType {
  CreateSelection = 'ADD_SELECTION',
  UpdateSelection = 'UPDATE_SELECTION',
  DeleteSelection = 'DELETE_SELECTION',
  DeleteItem = 'DELETE_ITEM',
  SetIsLoading = 'SET_IS_LOADING'
}

type SingleSearchReducerPayload = {
  [SearchReducerType.SetIsLoading] : {
    itemId: string;
    isLoading: boolean;
  };
  [SearchReducerType.CreateSelection] : {
    itemId: string;
    key: ListFieldKey;
    value: string;
  };
  [SearchReducerType.UpdateSelection]: {
    itemId: string;
    key: ListFieldKey;
    value: string;
  };
  [SearchReducerType.DeleteSelection]: {
    itemId: string;
    key: ListFieldKey;
  };
  [SearchReducerType.DeleteItem]: {
    itemId: string;
  };
}

export type SingleSearchReducerActions = ReducerActionMap<SingleSearchReducerPayload>[keyof ReducerActionMap<SingleSearchReducerPayload>];

export type SingleSearchReducer = Reducer<SingleSearchReducerState, SingleSearchReducerActions>;

export const singleSearchResultReducer: Reducer<SingleSearchReducerState, SingleSearchReducerActions> = (
  state: SingleSearchReducerState,
  action: SingleSearchReducerActions
): SingleSearchReducerState => {
  const existingItemState = state[action.payload.itemId] || {
    selections: {},
    isLoading: false
  };

  switch (action.type) {
    case SearchReducerType.SetIsLoading:
      return {
        ...state,
        [action.payload.itemId]: {
          ...existingItemState,
          isLoading: action.payload.isLoading
        }
      };

    case SearchReducerType.CreateSelection:
      return {
        ...state,
        [action.payload.itemId]: {
          ...existingItemState,
          selections: {
            ...existingItemState.selections,
            [action.payload.key]: action.payload.value
          }
        }
      };

    case SearchReducerType.UpdateSelection:
      return {
        ...state,
        [action.payload.itemId]: {
          ...existingItemState,
          selections: {
            ...existingItemState.selections,
            [action.payload.key]: action.payload.value
          }
        }
      };

    case SearchReducerType.DeleteSelection:
      // Removes the object property we'd like to delete by abstracting it from
      // the rest spread, and passing that into the reducer
      const { [action.payload.key]: _selectionToDelete,  ...restSelections } = existingItemState.selections;

      return {
        ...state,
        [action.payload.itemId]: {
          ...existingItemState,
          selections: {
            ...restSelections
          }
        }
      };

    case SearchReducerType.DeleteItem:
      if (!state[action.payload.itemId]) return state;

      const { [action.payload.itemId]: _itemToDelete,  ...restState } = state;

      return {
        ...restState
      };

    default:
      return state;
  }
};

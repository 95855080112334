export const LABEL_DROPDOWN_OPTIONS = [
  {
    title: 'Standard',
    key: 'standard'
  },
  {
    title: 'Mother\'s Day',
    key: 'mothers-day'
  },
  {
    title: 'Valentine\'s Day',
    key: 'valentines-day'
  },
  {
    title: 'Expanded',
    key: 'expanded'
  },
  {
    title: 'Core',
    key: 'core'
  },
  {
    title: 'Local 1',
    key: 'local-1'
  },
  {
    title: 'Local 2',
    key: 'local-2'
  },
  {
    title: 'Local 3',
    key: 'local-3'
  }
];

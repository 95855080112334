import { gql } from '@apollo/client';

import { MerchantFragments } from 'lib';

export const MERCHANT_QUERY = gql`
  query MerchantQuery($id: ID!) {
    merchant(
      where: {
        id: $id
      }
    ) {
      ...MerchantFull
    }
  }
  ${MerchantFragments.MERCHANT_FULL}
`;

import gql from 'graphql-tag';

export const WHOLESALE_UNPAID_BALANCE = gql`
  query WholesaleUnpaidBalance(
    $where: MerchantWhereUniqueInput!
  ) {
    wholesaleUnpaidBalance(
      where: $where
    )
  }
`;

export const MERCHANT_WHOLESALE_SUPPLIERS = gql`
  query MerchantWholesaleSuppliers(
    $where: MerchantWholesaleSuppliersWhereInput!
  ) {
    merchantWholesaleSuppliers(
      where: $where
    ) {
      supplier {
        id
        name
        itemCancellationWindowInMinutes
      }
      deliveryDates {
        dateString
        canDeliver
        cutOffAt
      }
    }
  }
`;

import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const LoginWrapper = styled.div`
  margin: 140px auto 30px auto;
  max-width: 360px;
  position: relative;
`;

export const Error = styled.p`
  ${textStyles.footnote};
  margin: 10px 0;
  color: ${colors.floomRed};
`;

export const CheckboxContainer: any = styled(Flex)`
  width: 30px;
  height: 30px;
  align-items: center;
`;

export const LinkTerms: any = styled(Box)`
  text-decoration: underline;
  color: ${colors.floomMidnightBlue};
`;

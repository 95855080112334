export const truncate = (str: string, limit: number, ending = '…'): string => {
  return str.length > limit ? `${str.substring(0, limit)}${ending}` : str;
};

export const getFirstChar = (name: string): any => name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);

export const createInitials = (
  givenName: string,
  familyName?: string
): string => [
  givenName && getFirstChar(givenName),
  familyName && getFirstChar(familyName)
].join('');

export const buildFullName = (
  givenName: string,
  familyName?: string | null
): string => {
  if (!familyName) {
    return capitalise(givenName);
  }

  return `${capitalise(givenName)} ${capitalise(familyName)}`;
};

export const capitalise = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const slugify = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

// Significantly quicker than using regex
export const isCharAlphaNumeric = (input: string): boolean => {
  const code = input.charCodeAt(0);

  if (!(code > 47 && code < 58) &&  // Numeric (0-9)
      !(code > 64 && code < 91) &&  // Upper alpha (A-Z)
      !(code > 96 && code < 123)) { // Lower alpha (a-z)
    return false;
  }

  return true;
};

export const getFirstNonAlphaNumericCharIndex = (input: string): number => {
  const nonNumerics: Array<number | null> = input.split('').map((char, index) => {
    return !isCharAlphaNumeric(char) ? index : null;
  }).filter(index => index !== null);

  return nonNumerics && nonNumerics.length ? (nonNumerics[0] as number) : -1;
};

export const makeID = (length: number): string => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

/**
 * This string helper inserts non-breaking spaces at the end of
 * every sentence to prevent orphans
 *
 * @param str
 */
export const preventOrphans = (str: string): string => {
  const sentences = str.split('\n');

  const sentencesWithNonBreakingSpace = sentences.map(sentence => {
    if (!sentence.length) {
      return undefined;
    }

    const allWords = sentence.split(' ')
      .map(word => word.trim())
      .filter(word => word.length);

    const lastWord = allWords.slice(allWords.length - 1);
    const allWordsWithoutLast = allWords.slice(0, allWords.length - 1).join(' ');

    const wordsGrouped = [
      allWordsWithoutLast,
      ...lastWord
    ];

    return wordsGrouped.join('\xa0');
  });

  return sentencesWithNonBreakingSpace.join('\n');
};

import store from 'stores';

import { GraphQL } from 'lib';

import { CONFIRM_MEDIA_UPLOAD, CREATE_MEDIA_UPLOAD_LINK } from './media-uploader-graphql-mutations';
import MediaUploaderService from './media-uploader.service';
import { UploadFile } from './media-uploader.service.types';

export default class MediaUploaderApolloService implements MediaUploaderService {
  public async getUploadURL(file: UploadFile, fileInsert: any): Promise<any> {
    const url = URL.createObjectURL(fileInsert);

    const image = await new Promise<any>((resolve: any, reject: any): void => {
      const img = new Image();
      img.onload = (): any => resolve(img);
      img.onerror = (e): any => reject(e);
      img.src = url;
    });

    const link = await GraphQL.mutate(CREATE_MEDIA_UPLOAD_LINK, {
      mediaName: file.mediaName,
      mediaSize: file.mediaSize,
      mediaType: file.mediaType,
      mediaCategory: file.mediaCategory,
      mediaWidth: image.width,
      mediaHeight: image.height
    })
      .then(result => {
        return result.data.createMediaLink;
      })
      .catch((error: any) => {
        throw error;
      });

    return link;
  }

  public confirmMediaUpload(mediaId: string): Promise<any> {
    return GraphQL.mutate(CONFIRM_MEDIA_UPLOAD, { mediaId })
      .then(result => {
        return result.data.confirmMediaUpload;
      })
      .catch(() => {
        store.toasterStore.popErrorToast('this file.', 'upload');
      });
  }
}

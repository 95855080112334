import React, { Component } from 'react';

import { Box, Flex } from 'rebass';

import { textStyles, colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './feature-list.styles';
import * as Types from './feature-list.types';

export default class FeatureList extends Component<Types.FeatureListProps> {
  render(): React.ReactNode {
    return (
      <Styles.FeatureList>
        { this.props.heading && (
          <Styles.FeatureListHeading as="h4">
            {this.props.heading}
          </Styles.FeatureListHeading>
        )}
        <Flex
          m="0 -20px"
          flexWrap="wrap"
        >
          {
            this.props.config.map((item, index) => {
              return (
                <Flex
                  key={index}
                  alignItems="center"
                  width={[1 / 2, 1 / 3, 1 / 3]}
                  p="0 20px 20px 20px"
                >
                  <Styles.ItemIcon>
                    <Icon
                      iconName={item.icon}
                      pathFill={colors.shade40}
                    />
                  </Styles.ItemIcon>
                  <Box
                    ml="20px"
                    css={textStyles.body}
                  >
                    {item.copy}
                  </Box>
                </Flex>
              );
            })
          }
        </Flex>
      </Styles.FeatureList>
    );
  }
}

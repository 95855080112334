import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import FormFieldWrapper from 'components/create-labeled-component';

import CreateAddonChooser from './create-addon-chooser';
import * as Styles from './create-addon-tab.styles';

class CreateAddonTab extends Component<any> {
  render(): React.ReactNode {
    return (
      <Styles.Content>
        <Styles.InnerContent>
          <FormFieldWrapper
            title="Add-on"
            optional={true}
            subtitle="Add-ons you want to add to the product"
          >
            <CreateAddonChooser
              addons={this.props.productEditStore.addons}
              selectAddon={this.props.productEditStore.selectAddon}
              deselectAddon={this.props.productEditStore.deselectAddon}
              selectedAddonIds={this.props.productEditStore.selectedAddonIds}
            />
          </FormFieldWrapper>
        </Styles.InnerContent>
      </Styles.Content>
    );
  }
}

export default inject('productEditStore')(observer(CreateAddonTab));

import { FC, Fragment } from 'react';

import 'styles/tailwind.css';

import { gql } from '@apollo/client';
import { navigate } from '@reach/router';
import store from 'stores';

import { Analytics, GraphQL } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';

import { colors } from 'utils/rebass-theme';

import { SingleNotification } from '../components/notification';
import { useNotificationStore } from '../store';

const merchantStore = store.merchantStore as MerchantStore;

export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation ReadAllNotifications($where: MerchantWhereUniqueInput!) {
    readAllNotifications(where: $where)
  }
`;

const NotificationsOverview: FC = () => {
  const {
    notifications
  } = useNotificationStore();

  const markAllAsRead = async (): Promise<void> => {
    console.log('merchantStore.merchant?.id', merchantStore.merchant?.id);

    await GraphQL.mutate(
      MARK_ALL_NOTIFICATIONS_AS_READ,
      {
        where: {
          id: merchantStore.merchant?.id
        }
      }
    );
  };

  const handleReadAllNotifications = async (): Promise<void> => {
    Analytics.track(Analytics.FxEventName.ReadAllNotificationsClicked, {
      event_version: 1,
      merchant_id: merchantStore.merchant?.id
    });

    await markAllAsRead();
  };

  return (
    <div className="max-w-5xl mx-auto px-6 md:px-8 py-6">
      <div className="m-auto max-w-3xl">
        <div className="mb-4 flex items-center justify-between">
          <h1 className="text-lg">Notifications</h1>
          {/* {unreadNotificationsCount() === 0 && (
            <div className="text-center text-slate-900 text-sm">
              <p>🎉 You&apos;re all caught up</p>
            </div>
          )} */}
          <div className="flex gap-4">
            <button
              onClick={() => {
                navigate('/settings/notifications');
              }}
              style={{
                backgroundColor: colors.floomMidnightBlue
              }}
              className="h-8 px-4 pb-1 rounded-md sm:rounded-lg text-white
                justify-center flex items-center text-xs font-bold disabled:cursor-not-allowed
                disabled:opacity-30"
            >
              <span>Settings</span>
            </button>
            <button
              onClick={handleReadAllNotifications}
              style={{
                backgroundColor: colors.floomMidnightBlue
              }}
              className="h-8 px-4 pb-1 rounded-md sm:rounded-lg text-white
                justify-center flex items-center text-xs font-bold disabled:cursor-not-allowed
                disabled:opacity-30"
            >
              <span>Mark all as read</span>
            </button>
          </div>
        </div>
        <ul
          role="list"
          className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
        >
          {notifications.length === 0 ? (
            <div className="p-3 py-5 flex items-center h-full">
              <div className="text-slate-500 text-center text-sm w-full h-full flex items-center justify-center">
                <div>
                  <p className="text-xl mb-2">📣</p>
                  <p>No new notifications</p>
                </div>
              </div>
            </div>
          ) : (
            <Fragment>
              {notifications.map(notification => (
                <SingleNotification
                  key={notification.id}
                  notification={notification}
                  timezone={merchantStore.merchant?.timezone!}
                />
              ))}
            </Fragment>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NotificationsOverview;

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { borderRadius, breakpoints, colors, fontSizes, textStyles } from 'utils/rebass-theme';

import { getPlaceholderStyle, inputReset } from 'components/field-text/field-text.styles';

export const InputContainer = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  padding: 8px 32px 8px 18px;
  border-radius: ${borderRadius.xLarge};
  background: ${colors.lightGrey};

  &:not(:only-child) {
    margin-bottom: 12px;
  }

  @media (max-width: ${breakpoints.medium}) {
    padding: 8px 16px 8px 12px;
  }
`;

export const InputWrapper = styled(Box)`
  flex-grow: 1;
`;

export const ButtonWrapper = styled(Box)`
  max-height: 38px;
  width: 140px;

  @media (max-width: ${breakpoints.small}) {
    width: 80px;
  }
`;

export const Input = styled.input`
  ${textStyles.body};

  font-size: ${fontSizes.base}px;
  color: ${({ disabled }) => disabled ? colors.middleGrey : colors.floomMidnightBlue};
  width: 100%;
  background: none;

  &:focus {
    outline: none;
  }

  ${inputReset}
  ${getPlaceholderStyle()};
`;

import React from 'react';

import { BeamsPushProvider } from 'lib/services/notifications/notifications.service';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { Auth, Analytics, StringFormat, NavService } from 'lib';

import AccountStore from 'stores/account/account-store';
import ChatWidgetStore from 'stores/chat-widget/chat-widget-store';
import MerchantStore from 'stores/merchant/merchant-store';
import UIStore from 'stores/ui-store';
import UserStore from 'stores/user/user-store';

import { LogoutButtonIcon } from 'utils/css-mixins/css-mixins';
import { TEST_IDS } from 'utils/test/data-test-ids';

import { NotificationState } from 'features/notifications/store';

import GenericModal from 'components/generic-modal';
import StyledLink from 'components/styled-link/styled-link';

import * as Styles from './account-modal.styles';

interface AccountModalProps {
  uiStore?: UIStore;
  accountStore?: AccountStore;
  userStore?: UserStore;
  merchantStore?: MerchantStore;
  chatWidgetStore?: ChatWidgetStore;
  notificationsStore?: NotificationState;
}

class AccountModal extends React.Component<AccountModalProps> {
  private navigateToAccount = (): void => {
    this.props.uiStore!.toggleAccountModal();
    NavService.accountDetails();
  };

  private logoutUser = (): void => {
    Analytics.reset();
    Auth.LogoutUser();
    this.props.uiStore!.toggleAccountModal();
    this.props.userStore!.reset();
    this.props.merchantStore!.init();
    this.props.accountStore!.setAccountError();
    this.props.accountStore!.clearAccountStore();
    this.props.chatWidgetStore!.hideChatWidget();
    window['zE']?.('webWidget', 'hide');
    new BeamsPushProvider(this.props.userStore!.userId).disableNotifications();

    Analytics.track(Analytics.FxEventName.SignedOut, {
      event_version: 1,
      username: this.props.userStore?.user?.email || 'Unknown',
      context: {
        group_id: Auth.getUserMerchantId() || ''
      },
      merchant_title: this.props.userStore?.user?.merchant?.title || 'Unknown',
      user_type: this.props.userStore!.isTeamRole() ? 'Merchant' : 'Internal',
      role: this.props.userStore?.user?.role
    });
  };

  render(): React.ReactNode {
    const { givenName, familyName, email } = this.props.userStore!;

    return (
      <GenericModal
        modalOpen={this.props.uiStore!.isAccountModalOpen}
        closeModal={this.props.uiStore!.toggleAccountModal}
        confirmButtonAction={this.logoutUser}
        confirmButtonText="Logout"
        testId={TEST_IDS.Menu.logoutButton}
        title={`Logged in as ${StringFormat.buildFullName(givenName, familyName)}`}
        innerComponent={(
          <Styles.Container>
            <Flex>
              <Box>
                <LogoutButtonIcon
                  mr="15px"
                  size="36px"
                  fontSize="14px"
                >
                  {StringFormat.createInitials(givenName, familyName)}
                </LogoutButtonIcon>
              </Box>
              <Box>
                <Styles.Metadata fontWeight={700}>
                  {givenName}
                </Styles.Metadata>
                <Styles.Metadata>
                  {familyName}
                </Styles.Metadata>
                <Styles.Metadata>
                  {email}
                </Styles.Metadata>
                <Box
                  mt="10px"
                  onClick={this.navigateToAccount}
                >
                  <StyledLink>
                    Account details
                  </StyledLink>
                </Box>
              </Box>
            </Flex>
          </Styles.Container>
        )}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  chatWidgetStore: stores.chatWidgetStore,
  userStore: stores.userStore,
  uiStore: stores.uiStore,
  accountStore: stores.accountStore,
  merchantStore: stores.merchantStore
}))(observer(AccountModal));

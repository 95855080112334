import { ApolloError } from '@apollo/client';
import deepmerge from 'deepmerge';
import {
  action,
  makeObservable,
  observable,
  runInAction,
  toJS
} from 'mobx';

import {
  WholesaleOrder,
  WholesaleOrderLineItem
} from 'generated-types.d';

import {
  DraftWholesaleOrderStoreService
} from './draft-wholesale-order-store-service';
import {
  DeleteDraftWholesaleOrderItemArgs,
  UpdateDraftWholesaleOrderArgs,
  UpdateDraftWholesaleOrderItemArgs
} from './draft-wholesale-order-store-types';

export default class DraftWholesaleOrderStore {
  constructor() {
    makeObservable(this, {
      isCancelling: observable,
      isFinalising: observable,
      selectedOrder: observable.deep,
      hasError: observable,
      errorMsg: observable,
      orderItemIdsBeingUpdated: observable,
      isLoadingOrderDetail: observable,
      isUpdatingOrder: observable,
      setLoadingOrderDetail: action,
      loadingError: action,
      resetError: action,
      resetSelectedOrder: action,
      setSelectedOrder: action,
      resetLoadingOrderDetail: action,
      fetchOrder: action,
      updateOrder: action,
      updateOrderItem: action,
      deleteOrderItem: action,
      cancelOrder: action,
      finaliseOrder: action,
      toggleIsFinalisingOrder: action,
      toggleIsCancellingOrder: action
    });
  }

  public selectedOrder: WholesaleOrder | null = null;

  public orderItemIdsBeingUpdated: Set<string> = new Set();

  public isCancelling: boolean = false;

  public isFinalising: boolean = false;

  public hasError: boolean = false;

  public isLoadingOrderDetail: boolean = false;

  public isUpdatingOrder: boolean = false;

  public errorMsg: ApolloError | boolean = false;

  public setLoadingOrderDetail = (): void => {
    this.resetError();
    this.isLoadingOrderDetail = true;
  };

  public resetError = (): void => {
    this.hasError = false;
  };

  public resetSelectedOrder = (): void => {
    this.selectedOrder = null;
  };

  public setSelectedOrder = (order: WholesaleOrder | null): void => {
    this.selectedOrder = order;
  };

  public updateSelectedOrder = (orderData: Partial<WholesaleOrder> | null): void => {
    this.selectedOrder = deepmerge<WholesaleOrder | null>(this.selectedOrder, orderData);
  };

  public resetLoadingOrderDetail = (): void => {
    this.resetError();
    this.isLoadingOrderDetail = false;
  };

  public loadingError = (error: any): void => {
    this.hasError = true;
    this.errorMsg = error;
  };

  public toggleIsFinalisingOrder = (isFinalising: boolean = !this.isFinalising): void => {
    this.isFinalising = isFinalising;
  };

  public toggleIsCancellingOrder = (isCancelling: boolean = !this.isCancelling): void => {
    this.isCancelling = isCancelling;
  };

  public fetchOrder = async (id: string): Promise<void> => {
    this.setLoadingOrderDetail();

    try {
      const result = await DraftWholesaleOrderStoreService.fetchOrder(id);

      runInAction(() => {
        this.setSelectedOrder(result);
        this.resetLoadingOrderDetail();
      });
    } catch (error) {
      runInAction(() => {
        this.loadingError(error);
        this.resetLoadingOrderDetail();
      });

      return Promise.reject(error);
    }
  };

  public finaliseOrder = async (order: WholesaleOrder): Promise<void> => {
    try {
      await DraftWholesaleOrderStoreService.finaliseOrder(order.id);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public cancelOrder = async (order: WholesaleOrder): Promise<void> => {
    try {
      await DraftWholesaleOrderStoreService.cancelOrder(order.id);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public updateOrder = async ({ where, data }: UpdateDraftWholesaleOrderArgs): Promise<void> => {
    runInAction(() => {
      this.isUpdatingOrder = true;
    });

    try {
      const result = await DraftWholesaleOrderStoreService.updateOrder({ where, data });

      runInAction(() => {
        this.isUpdatingOrder = false;
        this.setSelectedOrder(result);
      });
    } catch (error) {
      runInAction(() => {
        this.isUpdatingOrder = false;
      });

      return Promise.reject(error);
    }
  };

  public updateOrderItem = async (args: UpdateDraftWholesaleOrderItemArgs): Promise<void> => {
    try {
      runInAction(() => {
        this.orderItemIdsBeingUpdated.add(args.orderItemId);
      });

      const { items, ...restOrder } = await DraftWholesaleOrderStoreService.updateOrderItem(args);
      const updatedOrderItem = items?.find?.(orderItem => orderItem.id === args.orderItemId);

      if (!!updatedOrderItem && this.selectedOrder) {
        runInAction(() => {
          this.orderItemIdsBeingUpdated.delete(updatedOrderItem.id);

          this.selectedOrder = {
            ...this.selectedOrder!,
            items: this.updateOrderItems(updatedOrderItem),
            ...restOrder
          };
        });

        this.updateSelectedOrder(restOrder);
        this.updateOrderItems(updatedOrderItem);
      }
    } catch (error) {
      runInAction(() => {
        this.orderItemIdsBeingUpdated.delete(args.orderItemId);
      });

      return Promise.reject(error);
    }
  };

  public deleteOrderItem = async (args: DeleteDraftWholesaleOrderItemArgs): Promise<void> => {
    try {
      runInAction(() => {
        this.orderItemIdsBeingUpdated.add(args.orderItemId);
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { items, ...restOrder } = await DraftWholesaleOrderStoreService.deleteOrderItem(args);

      if (this.selectedOrder) {
        runInAction(() => {
          this.orderItemIdsBeingUpdated.delete(args.orderItemId);

          this.selectedOrder = {
            ...this.selectedOrder!,
            items: this.removeOrderItem(args.orderItemId),
            ...restOrder
          };
        });
      }
    } catch (error) {
      runInAction(() => {
        this.orderItemIdsBeingUpdated.delete(args.orderItemId);
      });

      return Promise.reject(error);
    }
  };

  private removeOrderItem = (itemIdToDelete: string): WholesaleOrderLineItem[] => {
    const itemToDeleteIndex = this.selectedOrder?.items?.findIndex?.(orderItem => orderItem.id === itemIdToDelete);
    const selectedOrder = toJS<WholesaleOrder>(this.selectedOrder!);

    if (typeof itemToDeleteIndex === 'number' && itemToDeleteIndex !== -1 && !!selectedOrder?.items?.length) {
      return [
        ...selectedOrder.items.slice(0, itemToDeleteIndex) || [],
        ...selectedOrder.items.slice(itemToDeleteIndex + 1)
      ];
    }

    return selectedOrder?.items || [];
  };

  private updateOrderItems = (updatedOrderItem: WholesaleOrderLineItem): WholesaleOrderLineItem[] => {
    const itemIndex = this.selectedOrder?.items?.findIndex?.(orderItem => orderItem.id === updatedOrderItem.id);
    const selectedOrder = toJS<WholesaleOrder>(this.selectedOrder!);

    if (typeof itemIndex === 'number' && itemIndex !== -1 && !!selectedOrder?.items?.length) {
      return [
        ...selectedOrder.items.slice(0, itemIndex),
        updatedOrderItem,
        ...selectedOrder.items.slice(itemIndex + 1)
      ];
    }

    return selectedOrder?.items || [];
  };
}

import { TestMessageParams, Schema, reach } from 'yup';

export default class ValidationService {
  private static baseValidationConfig = {
    abortEarly: false,
    stripUnknown: true
  };

  public static generateYupMessageSchema = (params: Partial<TestMessageParams>, errorMessage: string): Partial<TestMessageParams> => {
    return {
      ...params,
      label: errorMessage
    };
  };

  public static validateAll = async <T = any>(schema: Schema<T>, values: T): Promise<T> => {
    return schema.validate(values, ValidationService.baseValidationConfig);
  };

  public static validateField = async <T = any>(schema: Schema<T>, value: any, field: string): Promise<T> => {
    return reach(schema, field).validate(value);
  };

  public static validateSchemaField = async <T = any>(schema: Schema<T>, value: any, field: string): Promise<T> => {
    return schema.validateAt(field, value);
  };

  public static isValid = async (schema: Schema<any>, values: any): Promise<boolean> => {
    return schema.isValid(values, ValidationService.baseValidationConfig);
  };

  public static findErrorCopy = (errors: TestMessageParams[] | undefined, key: any): TestMessageParams | undefined => {
    return errors?.find(error => error?.path === key);
  };
}

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const FormHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  margin: 0 35px;
  border-bottom: 1px solid ${colors.shade40};
`;

export const FormHeaderTitle = styled(Box)`
  ${textStyles.title};
`;

export const FormHeaderButton = styled(Box)`
  ${textStyles.subhead};
`;

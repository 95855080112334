import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import CheckboxToggle from 'components/checkbox-toggle';

import * as Styles from './form-header.styles';
import * as Types from './form-header.types';

class FormHeader extends Component<Types.FormHeaderProps> {
  static defaultProps: Partial<Types.FormHeaderProps> = {
    actionType: 'text',
    hasAction: true,
    isActive: true,
    isActionDisabled: false
  };

  private renderAction = (): ReactNode => {
    if (!this.props.hasAction) return null;

    switch (this.props.actionType) {
      case 'text':
        return (
          <Styles.FormHeaderButton
            as="button"
            onClick={this.props.onAction}
            disabled={this.props.isActionDisabled}
          >
            {this.props.buttonCopy}
          </Styles.FormHeaderButton>
        );

      case 'toggle':
        return (
          <Flex alignItems="center">
            <CheckboxToggle
              isChecked={this.props.isActive}
              isDisabled={this.props.isActionDisabled}
              controlValue={true}
              onChange={(): void => this.props.onAction?.()}
            />
            <Box
              as="button"
              onClick={(): void => this.props.onAction?.()}
              disabled={this.props.isActionDisabled}
              css={textStyles.body}
              ml="10px"
            >
              {this.props.buttonCopy}
            </Box>
          </Flex>
        );
    }
  };

  render(): ReactNode {
    return (
      <Styles.FormHeader>
        <Styles.FormHeaderTitle>
          {this.props.title}
        </Styles.FormHeaderTitle>
        <Box>
          {this.renderAction()}
        </Box>
      </Styles.FormHeader>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantOnboardingStore: stores.merchantOnboardingStore
}))(observer(FormHeader));

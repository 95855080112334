import React, { Component } from 'react';

import CheckboxToggle from 'components/checkbox-toggle';
import DropdownNative from 'components/dropdown-native/dropdown-native';
import GenericModal from 'components/generic-modal';

import { VariationModalProps } from './add-variation-modal.types';
import * as Styles from './modal.styles';

export class AddVariationModal extends Component<VariationModalProps> {
  state = {
    selectedVariationType: '',
    useDefault: false
  };

  updateSelectedVariation = (variationType: string): void => {
    this.setState({
      selectedVariationType: variationType
    });
  };

  updateUseDefault = (useDefault: boolean): void => {
    this.setState({
      useDefault
    });
  };

  confirmVariationAdd = (): void => {
    this.props.onConfirm(this.state.selectedVariationType, this.state.useDefault);

    this.setState({
      useDefault: false,
      selectedVariationType: ''
    });
  };

  render(): React.ReactNode {
    return (
      <GenericModal
        width={430}
        innerComponent={(
          <Styles.InnerContent>
            <DropdownNative
              placeholder="Selection"
              optionTitleField="type"
              optionValueField="type"
              id="add-variation-selection"
              selected={this.state.selectedVariationType}
              options={this.props.availableVariations}
              onChange={this.updateSelectedVariation}
              fullWidth={true}
            />
            <Styles.DefaultWrapper>
              <CheckboxToggle
                isChecked={this.state.useDefault}
                controlValue={true}
                onChange={this.updateUseDefault}
              />
              <p>{`Use "default" recipe as template`}</p>
            </Styles.DefaultWrapper>
          </Styles.InnerContent>
        )}
        confirmButtonAction={this.confirmVariationAdd}
        confirmButtonDisabled={this.state.selectedVariationType === ''}
        confirmButtonText="Add variation"
        title="Variation"
        modalOpen={this.props.isOpen}
        closeModal={this.props.closeAction}
      />
    );
  }
}

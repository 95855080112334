import cuid from 'cuid';
import {
  computed,
  runInAction,
  makeAutoObservable
} from 'mobx';
import moment from 'moment';

import {
  Currency,
  List,
  SupplierDeliveryConfig,
  WholesalePreOrder,
  WholesalePreOrderDeliveryDate
} from 'generated-types.d';

import { TimeService } from 'lib';

import { getTotalListPrice, getTotalShippingEstimate, getSalesTax } from 'features/wholesale/pages/promoted-lists-checkout/components/checkout-list';

import { PromotedListsStoreService } from './promoted-lists-store.service';

export interface PromotedListsBasketItem {
  itemId: string;
  quantity: number;
}

export interface PromotedListsCustomItem {
  itemId: string;
  title: string;
  quantity: number;
}

export default class PromotedListsStore {
  constructor() {
    makeAutoObservable(this, {
      listSupplierId: computed,
      shippingTotal: computed,
      salesTax: computed,
      itemsPriceTotal: computed,
      grandTotal: computed
    });
  }

  public list: List | null = null;

  public basketItems: PromotedListsBasketItem[] = [];

  public customItems: PromotedListsCustomItem[] = [];

  public deliveryDates: WholesalePreOrderDeliveryDate[] = [];

  public deliveryConfigs: SupplierDeliveryConfig[] = [];

  public selectedDeliveryDate: string = '';

  public isLoadingData: boolean = false;

  get listSupplierId(): string | null {
    return this?.list?.suppliers?.[0]?.id || null;
  }

  get listCurrency(): Currency {
    return this.list?.items?.[0].promotedTradeSkuAvailability?.price?.[0]?.currency || Currency.Usd;
  }

  get shippingTotal(): number  {
    if (!this.list) return 0;

    const deliveryOption = this.deliveryConfigs[0]?.deliveryOptions?.[0];

    return getTotalShippingEstimate(this.list, this.basketItems, deliveryOption) || 0;
  }

  get salesTax(): number  {
    if (this.listCurrency === Currency.Usd) {
      return 0;
    }

    return getSalesTax(this.itemsPriceTotal) || 0;
  }

  get itemsPriceTotal(): number  {
    if (!this.list) return 0;

    return getTotalListPrice(this.list, this.basketItems);
  }

  get grandTotal(): number  {
    return this.itemsPriceTotal + this.shippingTotal + this.salesTax;
  }

  /**
   * @param deliveryDate - date string in YYYY-MM-DD format
   */
  public setDeliveryDate = (deliveryDate: string): void => {
    this.selectedDeliveryDate = deliveryDate;
  }

  public clearStore = (): void => {
    this.list = null;
    this.basketItems = [];
    this.customItems = [];
    this.selectedDeliveryDate = '';
    this.deliveryDates = [];
    this.isLoadingData = false;
  };

  public getData = async (): Promise<void> => {
    try {
      runInAction(() => {
        this.isLoadingData = true;
      });

      await this.fetchList();
      await this.fetchDeliveryDates();
      await this.fetchDeliveryConfigs();

      runInAction(() => {
        this.isLoadingData = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingData = false;
      });
    }
  };

  private fetchList = async (): Promise<void> => {
    try {
      const result = await PromotedListsStoreService.fetchList();

      runInAction(() => {
        this.list = result;
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  private fetchDeliveryDates = async (): Promise<void> => {
    try {
      const result = await PromotedListsStoreService.fetchDeliveryDates(this.list!.id);

      runInAction(() => {
        this.deliveryDates = result;
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  private fetchDeliveryConfigs = async (): Promise<void> => {
    if (!this.list?.suppliers?.[0]) {
      return Promise.reject('List does not have a supplier');
    }

    try {
      const result = await PromotedListsStoreService.fetchDeliveryConfigs({
        supplierId: this.list.suppliers[0].id
      });

      runInAction(() => {
        if (!!result) {
          this.deliveryConfigs = result as SupplierDeliveryConfig[];
        }
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public updateBasketItem = (itemToUpdate: PromotedListsBasketItem): void => {
    const updatedBasket = this.basketItems.filter(item => item.itemId !== itemToUpdate.itemId);

    if (itemToUpdate.quantity > 0) {
      updatedBasket.push(itemToUpdate);
    }

    this.basketItems = updatedBasket;
  };

  public createWholesalePreOrder = async (merchantId?: string): Promise<Pick<WholesalePreOrder, 'id'>> => {
    try {
      return PromotedListsStoreService.createWholesalePreOrder({
        deliveryDate: moment.utc(this.selectedDeliveryDate).format(TimeService.FLOOMX_TIMESTAMP_STRING_FORMAT),
        list: {
          id: this.list!.id
        },
        listItemsMetadata: this.basketItems.map(item => ({
          listItemId: item.itemId,
          quantity: item.quantity
        })),
        customItemsMetadata: this.customItems.length ? this.customItems.map(item => ({
          title: item.title,
          quantity: item.quantity
        })) : undefined,
        merchant: {
          id: merchantId
        }
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public createCustomItem = (customItemToAdd: Omit<PromotedListsCustomItem, 'itemId'>): void => {
    this.customItems.unshift({ ...customItemToAdd, itemId: cuid() });
  }

  public deleteCustomItem = (itemToDeleteId: string): void => {
    this.customItems = this.customItems.filter(item => item.itemId !== itemToDeleteId);
  }

  public updateCustomItem = (itemToUpdate: PromotedListsCustomItem): void => {
    try {
      const customItemIndex = this.customItems.findIndex(item => item.itemId === itemToUpdate.itemId);

      this.customItems[customItemIndex] = itemToUpdate;
    } catch (error) {
      throw error;
    }
  }
}

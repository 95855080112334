import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const CrossLeft = keyframes`
  0% { transform: translateX(-13px); }
  25% { transform: translateX(0px); }
  50% { transform: translateX(0px); }
  75% { transform: translateX(0px); }
  100% { transform: translateX(-13px); }
`;

export const CrossRight = keyframes`
  0% { transform: translateX(0px); }
  25% { transform: translateX(0px); }
  50% { transform: translateX(13px); }
  75% { transform: translateX(0px); }
  100% { transform: translateX(0px); }
`;

export const ButtonLoaderStyles: any = styled.div`
  position: absolute;
  width: 100%;
  top: calc(50% + 4px);
  transform: translateY(-50%);
  transition: all .2s ease-in-out;
  opacity: ${({ isVisible }: any): any => isVisible ? 1 : 0};

  svg {
    width: 14px;
    height: 14px;

    &:first-of-type {
      animation: 1s cubic-bezier(0.5, 0.5, 0.17, 1.245) ${CrossLeft};
      animation-iteration-count: infinite;
      padding-right: 3px;
    }

    &:last-of-type {
      animation: 1s cubic-bezier(0.5, 0.5, 0.17, 1.245) ${CrossRight};
      animation-iteration-count: infinite;
      padding-left: 3px;
    }

    rect {
      fill: ${({ appearance }: any): any => appearance.textColor}
    }
  }
`;

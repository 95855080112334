import styled from '@emotion/styled';
import { Box } from 'rebass';

import { textStyles, colors } from 'utils/rebass-theme';

export const Select = styled.select`
  ${textStyles.title}
  background: ${colors.paleGrey};
  padding: 10px 25px 10px 10px;
  border-radius: 5px;
  font-size: 12px;
  -webkit-appearance: none;
`;

export const SelectWrap = styled(Box)`
  position: relative;
  padding: 0 5px;
`;

export const SelectArrow = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  svg {
    width: 10px;
  }
`;

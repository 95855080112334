import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import _get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { TimeService } from 'lib';

import { textStyles, colors } from 'utils/rebass-theme';

import {
  buildPickupDay,
  buildPickupTime
} from 'features/courier/components/courier-status-indicator/courier-status-indicator.helpers';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';

interface PickupItemMetaProps {
  data: string;
}

class PickupTimeMeta extends Component<PickupItemMetaProps> {
  timeFormat = (timestamp: string, delta: number = 0): string => {
    return TimeService
      .withTimezone(timestamp)
      .add(delta, 'm')
      .format('HH:mm a');
  };

  render(): ReactNode {
    return (
      <MetaCardContentWrapper
        iconName="delivery-time"
      >
        <Box>
          <div css={css`${textStyles.label}; color: ${colors.middleGrey}; text-transform: uppercase;`}>
            Est. pickup time
          </div>
          <div css={textStyles.subhead}>
            {buildPickupTime(this.props.data)}, {buildPickupDay(this.props.data)}
          </div>
        </Box>
      </MetaCardContentWrapper>
    );
  }
}

export default inject('courierStore')(observer(PickupTimeMeta));

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { borderRadius, breakpoints, colors } from 'utils/rebass-theme';

export const DeliveryDate = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-top: 3px;
`;

export const PreOrderButton = styled(Box)`
  text-align: left;
`;

export const ButtonWrapper = styled(Flex)`
  align-items: center;
  background-color: ${({ backgroundColor }: any): string => backgroundColor || colors.floomMidnightBlue};
  color: ${({ color }: any): string => color || colors.white};
  padding: 11px 20px 16px;
  font-size: 14px;
  height: 70px;
  border-radius: ${borderRadius.large};
  opacity: ${({ isDisabled }: any): number => isDisabled ? 0.5 : 1};
  cursor: ${({ isDisabled }: any): string => isDisabled ? 'not-allowed' : 'pointer'};
`;

export const OrderingRulesWrapper = styled(Flex)`
  width: 160px;
  align-items: center;
  flex-grow: 10;
  justify-content: flex-end;

  @media only screen and (max-width: ${breakpoints.medium}) {
    justify-content: flex-start;
  }
`;

export const DeliveryDateWrapper = styled(Box)`
  min-width: 220px;
  flex-grow: 1;
`;

export const PlaceOrderWrapper = styled(Box)`
  min-width: 220px;
  flex-grow: 1;
`;

import { FC } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';

import {
  WholesaleOrderStates
} from 'generated-types.d';

import {
  TimeService,
  PermissionsService,
  StringFormat
} from 'lib';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import {
  WholesaleCheckoutHelpers
} from 'features/wholesale/helpers/wholesale-helpers';
import { getPaymentStatusConfig } from 'features/wholesale/helpers/wholesale-helpers/wholesale-helpers';

import Lozenge from 'components/lozenge';

import OrderCardRow from './order-card-row';
import {
  CardContent,
  OrderCard,
  OrderItemHeader
} from './order-card.styles';
import {
  OrderCardProps
} from './order-card.types';
import {
  getOrderDeliveryCost,
  getTotal
} from './utils/order-card.utils';

const OrderItemCard: FC<OrderCardProps> = ({
  hasGlobalMerchant,
  onViewDetail,
  order,
  itemsLabel
}) => {
  const merchantCurrency = order?.merchant?.currency;
  const deliveryCharge = getOrderDeliveryCost(order);
  const taxAmount = WholesaleCheckoutHelpers.orderTaxValue(order);
  const numberOfItems = WholesaleCheckoutHelpers.countOrderItems(order.items);
  const total = getTotal(order);
  const paymentStatusConfig = getPaymentStatusConfig(order);

  return (
    <OrderCard onClick={onViewDetail}>
      <OrderItemHeader>
        <OrderCardRow
          iconName="wholesale"
          title={`Order ${order.wholesaleOrderRef!.slice(0, 7)}`}
          bold={true}
        />
      </OrderItemHeader>
      <CardContent>
        <OrderCardRow
          iconName="flower-front"
          title="No. of items"
          value={numberOfItems ? numberOfItems.toString() : '-'}
          label={itemsLabel!}
        />
        <OrderCardRow
          iconName="orders"
          title="Total"
          value={total ? WholesaleCheckoutHelpers.renderWholesalePrice(total + deliveryCharge + taxAmount, merchantCurrency) : '-'}
        />
        {(!!paymentStatusConfig && order.status !== WholesaleOrderStates.Open) && (
          <OrderCardRow
            iconName="receipt"
            title="Payment"
            value={(
              <Lozenge
                copy={paymentStatusConfig.value}
                color={ColourOption.floomMidnightBlue}
                bg={paymentStatusConfig.bg || ColourOption.lightGrey}
              />
            )}
          />
        )}
        {(order.deliveryDetails && order.status !== WholesaleOrderStates.Draft) && (
          <OrderCardRow
            iconName="delivery-time"
            title="Delivery"
            value={TimeService.humanDateMonthYear(moment(order.deliveryDetails?.[0]?.deliveryDate).utc())}
          />
        )}
        {(!hasGlobalMerchant && PermissionsService.isInternalRole() && !!order.merchant?.title) && (
          <OrderCardRow
            iconName="shop"
            title="Merchant"
            value={StringFormat.truncate(order.merchant.title, 20)}
          />
        )}
        {!!order.supplier && (
          <OrderCardRow
            iconName="shop"
            title="Supplier"
            value={StringFormat.truncate(order.supplier?.name, 20)}
          />
        )}
      </CardContent>
    </OrderCard>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore
}))(observer(OrderItemCard));

import { FC, useEffect, useMemo, useState } from 'react';

import { inject, observer } from 'mobx-react';

import { ListType } from 'generated-types.d';

import { Attachment } from 'types/conversations.types';

import ConversationsStore from 'stores/conversations/conversations-store';
import ListsStore from 'stores/lists/lists-store';

import { ButtonDropdown } from 'components/button-dropdown/button-dropdown';
import {
  ButtonDropdownOption
} from 'components/button-dropdown/button-dropdown.types';
import Icon from 'components/icon';

import { ConversationInput } from '../conversation-input/conversation-input';

import { MoreOptionsContainer, Wrapper } from './conversation-footer.styles';

type ConversationFooterProps = {
  attachments: Attachment[] | [];
  contactId: string;
  conversationsStore?: ConversationsStore;
  listsStore?: ListsStore;
  onSend: (value: string) => Promise<void>;
}

export const ConversationFooter: FC<ConversationFooterProps> = inject((stores: FxStores): InjectedFxStores => ({
  conversationsStore: stores.conversationsStore,
  listsStore: stores.listsStore
}))(observer(({
  attachments,
  contactId,
  conversationsStore,
  listsStore,
  onSend
}) => {
  const [value, setValue] = useState(conversationsStore?.shareList.message || '');

  useEffect(() => {
    listsStore?.fetchLists();

    return () => {
      listsStore?.resetLists();
    };
  }, []);

  const handleShareNewList = async (): Promise<void> => {
    if (!contactId || !listsStore) return;

    const list = await listsStore.createList(ListType.Simple);

    conversationsStore?.startShareNewListFlow(contactId, list.id, value);
  };

  const handleShareExistingList = (): void => {
    if (!contactId) return;

    conversationsStore?.startShareExistingListFlow(contactId, value);
  };

  const shareMenuItems = useMemo((): ButtonDropdownOption[] => {
    const shareNewListMenuItem: ButtonDropdownOption =
      {
        title: 'Share new list',
        description: 'Share a new list to the current conversation',
        iconName: 'list',
        onClick: handleShareNewList
      };

    if (!listsStore?.lists?.edges?.length) {
      return [shareNewListMenuItem];
    }

    return [
      shareNewListMenuItem,
      {
        title: 'Share existing list',
        description: 'Share an existing list to the current conversation',
        iconName: 'flower',
        onClick: handleShareExistingList
      }
    ];
  }, [listsStore?.lists, contactId, value]);

  return (
    <Wrapper>
      <MoreOptionsContainer>
        <ButtonDropdown
          positionY="top"
          positionX="left"
          options={shareMenuItems}
          buttonCopy="More options"
          renderButton={() => (
            <Icon
              iconName="plus-large"
            />
          )}
        />
      </MoreOptionsContainer>
      <ConversationInput
        attachments={attachments}
        onSubmit={onSend}
        setValue={setValue}
        value={value}
      />
    </Wrapper>
  );
}));

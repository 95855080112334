import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { NavService } from 'lib';

import { OnboardingStage } from 'stores/payment-onboarding/payment-onboarding-store.types';

import { Container } from 'utils/css-mixins';
import { textStyles } from 'utils/rebass-theme';

import Button from 'components/button';

import OnboardingWrapper from '../../components/onboarding-wrapper';

import * as Styles from './onboarding-confirmation.styles';
import * as Types from './onboarding-confirmation.types';

class OnboardingConfirmation extends Component<Types.OnboardingConfirmationProps> {
  private CONTENT = {
    heading: 'Welcome to FloomX',
    subHeading: 'We need to take some information from you in order to set up your account'
  };

  componentDidMount = (): void => {
    this.props.paymentOnboardingStore!.setStage(OnboardingStage.Home);
  };

  private onNavigate = (): void => {
    const route = this.props.paymentOnboardingStore?.findFirstIncompleteRoute();

    if (route) {
      route.navigateToStep();
    } else {
      NavService.onboardingConfirmation();
    }
  };

  private canProgress = (): boolean => {
    return !!this.props.paymentOnboardingStore!.onboardingConfig.length;
  };

  render(): JSX.Element {
    return(
      <section>
        <Container>
          <OnboardingWrapper my="auto">
            <Box mt="140px">
              <Styles.Heading
                as="h1"
                mb="20px"
                mt="50px"
                color="floomMidnightBlue"
                css={textStyles.h1}
              >
                {this.CONTENT.heading}
              </Styles.Heading>
              <Styles.Heading
                as="h2"
                mb="40px"
                color="floomMidnightBlue"
                css={textStyles.title}
              >
                {this.CONTENT.subHeading}
              </Styles.Heading>
              <Styles.ButtonContinue onClick={this.onNavigate}>
                <Button
                  size="normal"
                  copy="Let's go"
                  appearance="primary"
                  isParentWidth={true}
                  isLoading={!this.canProgress()}
                />
              </Styles.ButtonContinue>
            </Box>
          </OnboardingWrapper>
        </Container>
      </section>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  paymentOnboardingStore: stores.paymentOnboardingStore
}))(observer(OnboardingConfirmation));

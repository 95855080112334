import {
  FC,
  Fragment,
  useContext,
  useEffect,
  useMemo
} from 'react';

import css from '@emotion/css';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Maybe } from 'generated-types.d';

import { WholesaleShopBasketItem } from 'stores/wholesale/wholesale-shop-basket-store/wholesale-shop-basket-store.types';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';
import { TEST_IDS } from 'utils/test/data-test-ids';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import Lozenge from 'components/lozenge';
import RadioList from 'components/radio-list';

import { groupTradeSkuAvailabilityBySupplier, sortSupplierTradeSkuAvailabilityGroups } from '../../utils/transformers';
import { WholesaleShopListingContext } from '../../wholesale-shop-listing.context';
import { WholesaleShopAvailabilityItem } from '../wholesale-shop-availability-item/wholesale-shop-availability-item';
import { WholesaleShopSupplierCard } from '../wholesale-shop-supplier-card/wholesale-shop-supplier-card';

import * as Styles from './wholesale-shop-listing-card.styles';
import { SupplierTradeSkuAvailabilities, TransformedTradeSkuAvailablityItem, WholesaleShopListingCardProps } from './wholesale-shop-listing-card.types';

export const WholesaleShopListingCard: FC<WholesaleShopListingCardProps> = inject((stores: FxStores): InjectedFxStores => ({
  catalogItemStore: stores.catalogItemStore,
  wholesaleShopBasketStore: stores.wholesaleShopBasketStore,
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  wholesaleShopStore: stores.wholesaleShopStore
}))(observer(({
  catalogItemStore,
  wholesaleShopBasketStore,
  wholesaleOrdersStore,
  wholesaleShopStore
}) => {
  const {
    selectedRadioOption,
    setSelectedRadioOption,
    availabilityItems,
    hasShopAccess
  } = useContext(WholesaleShopListingContext);

  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();

  const supplierAvailabilities = useMemo<SupplierTradeSkuAvailabilities[]>(() => {
    return sortSupplierTradeSkuAvailabilityGroups(groupTradeSkuAvailabilityBySupplier(availabilityItems));
  }, [availabilityItems]);

  const firstItemId = supplierAvailabilities?.[0]?.availability?.[0]?.availabilityItem?.supplierProductId;

  useEffect(() => {
    if (!!firstItemId?.length && !selectedRadioOption) {
      setSelectedRadioOption(firstItemId);
    }
  }, [selectedRadioOption, firstItemId]);

  const isItemInCurrentOrder = useMemo<boolean>(() => {
    const orders = wholesaleOrdersStore!.currentOpenOrders || [];

    return orders.some(order => order?.items?.some(orderItem => orderItem.snapshot.catalog_item_id === catalogItemStore!.catalogItem?.id));
  }, [
    wholesaleOrdersStore!.currentOpenOrders,
    catalogItemStore!.catalogItem?.id
  ]);

  const onOptionChange = (value: string): void => {
    if (value === selectedRadioOption) return;

    wholesaleShopBasketStore!.clearBasket();
    setSelectedRadioOption(value);
  };

  const handleUpdateQuantity = ({
    itemId,
    quantity
  }: {
    itemId: string;
    quantity: number;
  }): void => {
    switch (true) {
      case quantity === 0: {
        wholesaleShopBasketStore!.removeBasketItem(itemId);

        break;
      }

      case (!!getBasketItemById(itemId)): {
        wholesaleShopBasketStore!.updateBasketItem({ itemId, quantity });

        break;
      }

      default: {
        wholesaleShopBasketStore!.addBasketItem({ itemId, quantity });

        break;
      }
    }
  };

  const getBasketItemById = (id?: Maybe<string>): WholesaleShopBasketItem | undefined => {
    return wholesaleShopBasketStore!.basket.find(basketItem => basketItem.itemId === id);
  };

  if (!catalogItemStore!.catalogItem) return null;

  const buildItems = (items: TransformedTradeSkuAvailablityItem[]): any[] => {
    return items.map(({ availabilityItem, availabilitySku }) => {
      if (!availabilityItem) return null;

      const existingBasketItem = getBasketItemById(availabilityItem.supplierProductId);

      return {
        title: (
          <WholesaleShopAvailabilityItem
            key={availabilityItem.id}
            availabilityItem={availabilityItem}
            tradeSku={availabilitySku}
            catalogItem={catalogItemStore!.catalogItem!}
            type={catalogItemStore!.catalogItem?.type!}
            quantity={existingBasketItem?.quantity || 0}
            isSelected={selectedRadioOption === availabilityItem?.supplierProductId}
            hasShopAccess={hasShopAccess}
            setQuantity={(itemId, quantity) => {
              handleUpdateQuantity({
                itemId,
                quantity
              });
            }}
          />),
        value: availabilityItem?.supplierProductId
      };
    }).filter(Boolean);
  };

  return (
    <Styles.WholesaleShopListingCardContainer>
      <Styles.ItemTitle>
        <Box>
          {
            wsFriendlyNames.isActive ? (
              <Fragment>
                <Styles.ItemHeader>
                  {catalogItemStore!.catalogItem?.primaryName}
                </Styles.ItemHeader>
                {catalogItemStore!.catalogItem?.secondaryName && (
                  <Styles.ItemSubHeader>
                    {catalogItemStore!.catalogItem?.secondaryName}
                  </Styles.ItemSubHeader>
                )}
              </Fragment>
            ) : (
              <Styles.ItemHeader>
                {catalogItemStore!.catalogItem?.title}
              </Styles.ItemHeader>
            )
          }
        </Box>
        {isItemInCurrentOrder && (
          <Box>
            <Lozenge
              copy="Purchased"
              bg={ColourOption.green}
              styles={css`
                display: block;
                padding-bottom: 6px;
              `}
            />
          </Box>
        )}
      </Styles.ItemTitle>
      <Styles.SuppliersContainer>
        {supplierAvailabilities.map(({ supplier, availability }) => {
          const nextDeliveryDate = wholesaleShopStore!.nextAvailableDeliveryDates.find(({ supplierId }) => supplierId === supplier.id);
          const deliveryDate = nextDeliveryDate?.dateString;
          const countDownProps = wholesaleShopStore!.deliveryCountdownProps.find(({ supplierId }) => supplierId === supplier.id);

          return (
            <Box
              data-testid={TEST_IDS.WholesaleShop.itemModalDetails}
              key={supplier.id}
            >
              <WholesaleShopSupplierCard
                name={supplier.name}
                id={supplier.id}
                deliveryDate={deliveryDate}
                countDownProps={countDownProps}
              />
              <Styles.ItemsContainer>
                <RadioList
                  itemValueField="value"
                  itemTitleField="title"
                  orientation="vertical"
                  itemType="border"
                  items={buildItems(availability)}
                  selectedItem={selectedRadioOption}
                  onChange={(value: any): void => onOptionChange(value)}
                />
              </Styles.ItemsContainer>
            </Box>
          );
        })}
      </Styles.SuppliersContainer>
    </Styles.WholesaleShopListingCardContainer>
  );
}));

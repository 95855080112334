import styled from '@emotion/styled';

import { colors } from 'utils/rebass-theme';

export const Wrapper = styled.div<{ shouldRemoveSpacing: boolean }>`
  padding: ${({ shouldRemoveSpacing }): string => shouldRemoveSpacing ? '0' : '50px'} 30px 50px 30px;

  @media (min-width: 900px) {
    padding: ${({ shouldRemoveSpacing }): string => shouldRemoveSpacing ? '0' : '120px'} 30px 120px 30px;

    max-width: 900px;
    margin: 0 auto;
  }
`;

export const ApplicantOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
  width: 100%;

  @media (min-width: 900px) {
    margin-top: 50px;
  }
`;

interface ApplicationTypeProps {
  isSelected: boolean;
  disabled: boolean;
}

export const ApplicantType = styled.div<ApplicationTypeProps>`
  padding: 30px 20px;
  border-radius: 5px;
  font-size: 24px;
  font-weight: bold;
  border: 2px solid;
  text-align: center;
  border-color: ${({ isSelected }): string => isSelected ? colors.floomMidnightBlue : colors.middleGrey};
  color: ${({ isSelected }): string => isSelected ? colors.white : colors.middleGrey};
  background: ${({ isSelected }): string => isSelected ? colors.floomOrange : colors.white};
  cursor: ${({ disabled }): string => disabled ? 'not-allowed' : 'pointer'};
  opacity: ${({ disabled }): number => disabled ? .5 : 1};
  transition: opacity .2s ease-in-out,
              border-color .2s ease-in-out,
              background .2s ease-in-out,
              color .2s ease-in-out;

  @media (min-width: 900px) {
    padding: 50px 30px;
    font-size: 30px;
  }
`;

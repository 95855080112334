import { action, observable, makeObservable } from 'mobx';
import moment from 'moment';
import slugify from 'slugify';
import stores from 'stores';

import {
  Channel,
  DeliveryConfig,
  DeliveryCoverage,
  DeliveryPricing,
  DeliveryZone,
  DeliveryZoneEdge
} from '../../generated-types.d';

import { DeliveryDay } from './delivery-store.types';

export default class DeliveryStore {
  constructor() {
    makeObservable(this, {
      formMode: observable,
      deliveryConfigs: observable,
      isDefault: observable,
      duplicateNames: observable,
      selectedChannel: observable,
      allDeliveryZones: observable,
      selectedDeliveryCoverages: observable,
      selectedDeliveryConfig: observable,
      configSameDayPrice: observable,
      configNextDayPrice: observable,
      sameDayWeekend: observable,
      sameDayWeekday: observable,
      selectedCutoffTime: observable,
      selectedLeadTime: observable,
      deliveryDays: observable,
      deliveryCoverages: observable,
      deliveryStoreIsValid: observable,
      title: observable,
      isLoadingDeliveryConfigs: observable,
      isLoadingDeliveryCoverages: observable,
      customSameDayPrice: observable,
      customNextDayPrice: observable,
      customDeliveryConfig: observable,
      isConfigADuplicate: action,
      setSelectedDeliveryCoverages: action,
      updateCoverageAreaZones: action,
      enableDeliveryCoverageLoading: action,
      disableDeliveryCoverageLoading: action,
      setCurrentCustomPricing: action,
      toggleIsDefault: action,
      toggleSelectedDeliveryDay: action,
      updateBasicValue: action,
      generateTitle: action,
      loadConfigForEdit: action,
      loadConfigForCreate: action
    });
  }

  formMode: 'create' | 'edit' | null = null;

  deliveryConfigs: DeliveryConfig[] = [];

  deliveryCoverages: DeliveryCoverage[] = [];

  deliveryPricings: DeliveryPricing[] = [];

  allDeliveryZones: DeliveryZoneEdge[] = [];

  selectedDeliveryConfig: DeliveryConfig | null = null;

  selectedChannel?: Channel = undefined;

  selectedDeliveryCoverages: string[] = [];

  selectedDeliveryPricing: DeliveryPricing | null = null;

  sameDayWeekend: boolean = false;

  sameDayWeekday: boolean = false;

  selectedCutoffTime: number = 13;

  selectedLeadTime: number = 2;

  title: string = '';

  customDeliveryConfig: DeliveryConfig | null = null;

  customSameDayPrice: number = 0;

  customNextDayPrice: number = 0;

  configSameDayPrice: number = 0;

  configNextDayPrice: number = 0;

  displayPricingNotification: boolean = false;

  isDefault: boolean = false;

  isLoadingDeliveryConfigs: boolean = true;

  isLoadingDeliveryCoverages: boolean = true;

  duplicateNames: string = '';

  deliveryDays: DeliveryDay[] = [{
    title: 'Monday',
    selected: true
  }, {
    title: 'Tuesday',
    selected: true
  }, {
    title: 'Wednesday',
    selected: true
  }, {
    title: 'Thursday',
    selected: true
  }, {
    title: 'Friday',
    selected: true
  }, {
    title: 'Saturday',
    selected: true
  }, {
    title: 'Sunday',
    selected: true
  }];

  resetToDefault = (): void => {
    this.formMode = null;
    this.selectedDeliveryConfig = null;
    this.selectedChannel = undefined;
    this.selectedDeliveryCoverages = [];
    this.selectedDeliveryPricing = null;
    this.sameDayWeekend = false;
    this.sameDayWeekday = false;
    this.selectedCutoffTime = 13;
    this.selectedLeadTime = 2;
    this.customSameDayPrice = 0;
    this.customNextDayPrice = 0;
    this.customDeliveryConfig = null;
    this.isLoadingDeliveryConfigs = false;
    this.isLoadingDeliveryCoverages = true;
    this.isDefault = false;
    this.configSameDayPrice = 0;
    this.configNextDayPrice = 0;
    this.displayPricingNotification = false;

    this.deliveryDays = [{
      title: 'Monday',
      selected: true
    }, {
      title: 'Tuesday',
      selected: true
    }, {
      title: 'Wednesday',
      selected: true
    }, {
      title: 'Thursday',
      selected: true
    }, {
      title: 'Friday',
      selected: true
    }, {
      title: 'Saturday',
      selected: true
    }, {
      title: 'Sunday',
      selected: true
    }];

    this.generateTitle();
  };

  public toggleIsDefault = (): void => {
    this.isDefault = !this.isDefault;
  };

  public setCurrentCustomPricing = (customDeliveryConfig: DeliveryConfig): void => {
    this.customDeliveryConfig = customDeliveryConfig;
    this.customSameDayPrice = customDeliveryConfig.deliveryPrice!.sameDayPrice;
    this.customNextDayPrice = customDeliveryConfig.deliveryPrice!.nextDayPrice!;
  };

  public setSelectedDeliveryCoverages = (selectedDeliveryCoverage: string): void => {
    const foundIndex = this.selectedDeliveryCoverages.indexOf(selectedDeliveryCoverage);

    if (foundIndex < 0) {
      this.selectedDeliveryCoverages.push(selectedDeliveryCoverage);
    } else {
      this.selectedDeliveryCoverages.splice(foundIndex, 1);
    }
    this.generateTitle();
  };

  public toggleSelectedDeliveryDay = (day: string): void => {
    const foundDay = this.deliveryDays.find(x => x.title === day);

    if (foundDay) {
      this.deliveryDays[this.deliveryDays.indexOf(foundDay)].selected = !this.deliveryDays[this.deliveryDays.indexOf(foundDay)].selected;
    }
    this.generateTitle();
  };

  public updateBasicValue = (key: keyof DeliveryStore, value: any): void => {
    // @ts-ignore
    this[key] = value;
    this.generateTitle();
  };

  public disableDeliveryPricingNotification = (): void => {
    if (this.displayPricingNotification) {
      this.displayPricingNotification = false;
    }
  };

  public enableDeliveryCoverageLoading = (): void => {
    this.isLoadingDeliveryCoverages = true;
  };

  public disableDeliveryCoverageLoading = (): void => {
    this.isLoadingDeliveryCoverages = false;
  };

  public updateCoverageAreaZones = (zones: DeliveryZone[], id: string): void => {
    const index = this.deliveryCoverages.findIndex(coverage => coverage.id === id);

    if (index !== -1) {
      this.deliveryCoverages[index].deliveryZones = zones;
    }
  };

  public setLeadTime = (value: number): void => {
    this.selectedLeadTime = value;

    if (value) {
      this.sameDayWeekend = false;
      this.sameDayWeekday = false;
    }
    this.generateTitle();
  };

  public setSameDay = (key: keyof DeliveryStore, value: boolean): void => {
    this.selectedLeadTime = 0;
    // @ts-ignore
    this[key] = value;
    this.generateTitle();
  };

  public generateTitle = (): void => {
    // eslint-disable-next-line prefer-template
    const areaString = '_' + this.deliveryCoverages
      .filter(x => this.selectedDeliveryCoverages.indexOf(x.id) >= 0)
      .map(x => slugify(x.label!)
        .replace('-', '_')
        .replace('\'', ''))
      .join('_');

    let selectedDeliveryDayString = '_';

    for (let i = 0; i < this.deliveryDays.length; i++) {
      if (this.deliveryDays[i].selected) {
        selectedDeliveryDayString += i + 1;
      }
    }

    let sameDay: string = this.sameDayWeekday && this.sameDayWeekend
      ? '_SD:ALL'
      : this.sameDayWeekday ? '_SD:WK'
        : this.sameDayWeekend ? '_SD:WKND'
          : '';

    const leadTime: string = !sameDay && this.selectedLeadTime ? `_${this.selectedLeadTime}DL` : !sameDay ? '_1DL' : '';

    sameDay += !!sameDay ? `_${moment(this.selectedCutoffTime, 'HH').format('h')}PM` : '';
    this.title = `${this.getChannelInitial()}${areaString}${selectedDeliveryDayString}${leadTime}${sameDay}`;
  };

  public getChannelInitial = (): string => this.selectedChannel ? this.selectedChannel[0] : '';

  public canEditPricing = (): boolean => !!stores.merchantStore!.merchant?.canEditFloomDeliveryPricing && this.selectedChannel === Channel.Floom
   || this.selectedChannel === Channel.Website;

  public loadConfigForEdit = (config: DeliveryConfig): void => {
    this.formMode = 'edit';
    this.title = config.title;
    this.selectedDeliveryConfig = config;
    this.selectedDeliveryPricing = config.deliveryPrice!;
    this.selectedChannel = config.channel;
    this.selectedDeliveryCoverages = config.deliveryCoverage!.map(x => x.id);
    this.selectedCutoffTime = config.deliveryTiming.cutOffHour;
    this.selectedLeadTime = config.deliveryTiming.leadtime;
    this.isDefault = config.default;

    if (this.canEditPricing()) {
      this.configSameDayPrice = this.selectedDeliveryPricing.sameDayPrice || 0;
      this.configNextDayPrice = this.selectedDeliveryPricing.nextDayPrice || 0;

      if (config.deliveryPrice?.isDefault) {
        this.displayPricingNotification = true;
      }
    }

    for (let i = 0; i < this.deliveryDays.length; i++) {
      this.deliveryDays[i].selected = config.deliveryTiming.days[i] ? true : false;
    }

    this.sameDayWeekday = !!config.deliveryTiming.sameDays.slice(0, 5).filter(x => x).length;
    this.sameDayWeekend = !!config.deliveryTiming.sameDays.slice(5, 7).filter(x => x).length;
  };

  public loadConfigForCreate = (): void => {
    this.formMode = 'create';

    if (this.canEditPricing()) {
      this.selectedDeliveryPricing = this.deliveryPricings.find(pricing => pricing.channel === this.selectedChannel && pricing.isDefault) || null;

      if (!!this.selectedDeliveryPricing) {
        this.configSameDayPrice = this.selectedDeliveryPricing.sameDayPrice;
        this.configNextDayPrice = this.selectedDeliveryPricing.nextDayPrice || 0;
        this.displayPricingNotification = true;
      }
    }
  };

  public isConfigADuplicate = (): boolean => {
    const names: string[] = [];

    const isDuplicate = this.deliveryConfigs
      .filter(config => config.merchant.id === (this.selectedDeliveryConfig ? this.selectedDeliveryConfig.merchant.id : stores.merchantStore.merchant ? stores.merchantStore.merchant.id : '')
        && config.id !== (this.selectedDeliveryConfig ? this.selectedDeliveryConfig.id : ''))
      // eslint-disable-next-line
      .some(config => {
        const isTitleEqual = config.title === this.title || `${this.getChannelInitial()}_${config.title}` === this.title;
        const arePricesEqual = this.selectedDeliveryPricing?.sameDayPrice === this.configSameDayPrice && this.selectedDeliveryPricing?.nextDayPrice === this.configNextDayPrice;

        if (isTitleEqual && (!this.canEditPricing() || arePricesEqual)) {
          names.push(config.title);

          return true;
        }
      });

    this.duplicateNames = names.join(', ');

    return isDuplicate;
  };

  public deliveryStoreIsValid = (): boolean => {
    return this.deliveryDays.filter(x => x.selected).length >= 2
      && !!this.selectedDeliveryCoverages.length && !!this.deliveryPricings.length
      && this!.configSameDayPrice >= 0
      && this!.configNextDayPrice >= 0
      && !this.isConfigADuplicate();
  };
}

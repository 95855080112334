import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import {
  borderRadius,
  colors,
  shadows,
  space
} from 'utils/rebass-theme';

export const OrderCard = styled(Box)`
  width: 100%;
  background: ${colors.uiFills};
  box-shadow: ${shadows.small};
  border-radius: ${borderRadius.large};
  overflow: hidden;
  cursor: pointer;
`;

export const CardContent = styled.div`
  width: 100%;
  padding: 15px;
`;

export const OrderItemHeader = styled(Flex)`
  padding: 0px ${space[3]}px;
  background: ${colors.lightGrey};
  width: 100%;
  height: 46px;
  align-items: center;
`;

import { phoneString } from 'lib/services/validation/extensions/phone';
import { reqPostalCodeString } from 'lib/services/validation/extensions/postalcode';
import * as Yup from 'yup';

import {
  ValidationService
} from 'lib';

import {
  EditFieldsConfigKey
} from 'stores/merchant-detail/merchant-detail-store.types';

export const FORM_VALIDATION: { [key in EditFieldsConfigKey]: Yup.Schema<any> } = {
  basicMerchantDetails: Yup.object().shape({
    givenName: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'First name is required')),
    familyName: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Surname is required')),
    email: Yup
      .string()
      .email()
      .required(params => ValidationService.generateYupMessageSchema(params, 'We need an email for the merchant')),
    phone: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'We need the merchants phone number'))
  }),
  company: Yup.object().shape({
    businessName: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please provide the company name')),
    tradingNo: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please provide the trading number')),
    address1: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Address line 1 is required')),
    address2: Yup
      .string()
      .notRequired(),
    city: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'City is required')),
    postalCode: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Postal code is required'))
  }),
  businessAddress: Yup.object().shape({
    businessName: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please provide the business name')),
    address1: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Address line 1 is required')),
    address2: Yup
      .string()
      .notRequired(),
    city: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'City is required')),
    postalCode: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Postal code is required'))
  })
};

export const getFormValidation = (key: EditFieldsConfigKey, isZip: boolean): Yup.Schema<any> => {
  const validationSchema = FORM_VALIDATION[key];

  if (key === EditFieldsConfigKey.basicMerchantDetails) {
    const countryCode = isZip ? 'US' : 'GB';
    const validationSchemaPhone = validationSchema.concat(Yup.object().shape({
      phone: phoneString('phone', countryCode)
    }));

    return validationSchemaPhone;
  }

  const validationSchemaPostcode = validationSchema.concat(Yup.object().shape({
    postalCode: reqPostalCodeString('postalCode', isZip)
  }));

  return validationSchemaPostcode;
};

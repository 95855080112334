import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { ProductVariationType } from 'generated-types.d';

import FormFieldWrapper from 'components/create-labeled-component';

import ColourSelection from '../colour-selectiom';

import { InnerTabComponentProps } from './inner-tab-components.types';

class Colour extends Component<InnerTabComponentProps> {
  render(): ReactNode {
    if (this.props.variation.type !== ProductVariationType.Original) return null;

    return (
      <FormFieldWrapper
        title="Colour"
        optional={true}
      >
        <ColourSelection
          selectedColours={this.props.variation.selectedColours}
          setColours={this.props.variation.setColours}
        />
      </FormFieldWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore
}))(observer(Colour));

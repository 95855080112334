import { FC } from 'react';

import { inject, observer } from 'mobx-react';

import PromotedListsStore, { PromotedListsCustomItem } from 'stores/promoted-lists-store/promoted-lists-store';

import { QuantitySelectorWrapper } from 'features/lists/components/checkout-list/checkout-list.styles';

import QuantitySelector from 'components/quantity-selector';

export const AdditionalItemQuantitySelector: FC<{
  item: PromotedListsCustomItem;
  promotedListsStore?: PromotedListsStore;
}> = inject((stores: FxStores): InjectedFxStores => ({
  promotedListsStore: stores.promotedListsStore
}))(observer(({ item: item, promotedListsStore }) => {
  const increment = 1;
  const basketItemQuantity = promotedListsStore!.customItems.find(x => x.itemId === item.itemId)?.quantity;

  const handleUpdateQuantity = (value: number): void => {
    promotedListsStore!.updateCustomItem({
      ...item,
      quantity: value
    });
  };

  return (
    <QuantitySelectorWrapper width={130}>
      <QuantitySelector
        step={increment}
        min={1}
        initialValue={basketItemQuantity}
        onChange={handleUpdateQuantity}
        size="responsive"
      />
    </QuantitySelectorWrapper>
  );
}));

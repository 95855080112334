import gql from 'graphql-tag';

export const CANCEL_COURIER_BOOKING = (vars: any): any => gql`
  mutation CancelCourierBooking {
    cancelCourierBooking(
      where: { id: "${vars.bookingId}" },
        data: { timeSlot: {
          courierRef: "${vars.courierRef}"
          courierId: ""
          pickupDate: ""
          deliveryDate: ""
          price: 0
          currency: ""
          courierName: ""
          transportType: ""
        } } ) {
          response
    }
}
`;

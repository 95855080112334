import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { mq } from 'utils/css-mixins/css-mixins';

export const ListHeading: any = styled(Flex)`
  margin: 60px 0 20px 0;

  ${mq.lessThan('medium')} {
    margin: 30px 0 20px 0;
  }
`;

export const BackArrow = css`
  width: 15px;
  height: 15px;

  ${mq.lessThan('medium')} {
    width: 13px;
    height: 13px
  }
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const Day = styled(Box)<{ isActive: boolean; as: string }>`
  color: ${({ isActive }): string => isActive ? colors.floomMidnightBlue : colors.middleGrey};

  + ${(): any => Day} {
    padding-left: 10px;
  }
`;

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import SettingsTemplatePage from 'features/settings/components/template';

import PaymentDetails from './components/payment-details';
import PaymentsList from './components/payments-list';
import * as Types from './payments.types';
import PaymentSettingService from './services';

class PaymentsRoot extends Component<Types.PaymentsRootProps> {
  componentDidMount = (): void => {
    PaymentSettingService.init();
  };

  render(): ReactNode {
    return (
      <SettingsTemplatePage title="Payments">
        <PaymentDetails />
        <PaymentsList />
      </SettingsTemplatePage>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  paymentsStore: stores.paymentsStore,
  userStore: stores.userStore
}))(observer(PaymentsRoot));

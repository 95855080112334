import React, { Component, ReactNode } from 'react';

import TableLayoutItem from 'features/add-ons/components/table-layout/table-layout-item';

import EntityList from 'components/entity-list';
import { EntityListItems } from 'components/entity-list/entity-list.styles';
import TableLayoutEntityHeading from 'components/entity-list/table-layout-entity-heading';

import { HeadingsConfig } from './table-layout-group.config';

export default class TableLayoutGroup extends Component<any> {
  render(): ReactNode | null {
    if (!this.props.group.edges.length) return null;

    return(
      <div key={this.props.groupName}>
        <EntityList
          showHeading={this.props.showHeading}
          heading={this.props.groupName}
          headingCount={this.props.group.aggregate.count}
          showEntities={true}
          showNoResults={false}
        >
          <TableLayoutEntityHeading headers={HeadingsConfig} />
          <EntityListItems>
            {this.props.group.edges.map(({ node: addOn }: any) => (
              <TableLayoutItem
                key={`${addOn.id}`}
                item={addOn}
              />
            ))}
          </EntityListItems>
        </EntityList>
      </div>
    );
  }
}

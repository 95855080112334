import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import {
  InstantSearch,
  Configure,
  connectStateResults
} from 'react-instantsearch-dom';
import { Box } from 'rebass';

import {
  PermissionsService
} from 'lib';

import {
  Container
} from 'utils/css-mixins';

import OrderListControls from 'features/orders/components/order-list-controls';
import {
  OrderListConfig,
  ORDER_LIST_PAGE_LAYOUTS
} from 'features/orders/graphql/helpers/order-group.config';
import {
  OrderGroupOption
} from 'features/orders/orders.types';
import {
  OrderListService,
  OrdersAPIService
} from 'features/orders/services';

import OrderNoResults from '../no-results';

import { OrderListGroup } from './components/order-list-group';
import * as Types from './order-list.types';

const hasSavedFilters = (): boolean => {
  const cache = OrderListService.fetchListFilterCache() || {};

  return !!Object.values(cache).some(value => Array.isArray(value) && !!value.length);
};

const REFRESH_TIMOUT = 15000; // 15 seconds

class OrderList extends Component<Types.OrderListProps, Types.OrderListState> {
  state: Types.OrderListState = {
    refresh: false,
    indexName: process.env.ALGOLIA_FLOOMX_ORDER_INDEX!,
    hasSavedFilters: hasSavedFilters(),
    searchState: {
      refinementList: OrderListService.fetchListFilterCache()
    }
  };

  componentDidMount = (): void => {
    OrderListService.setListLayout(this.props.listLayout);
    this.updateDefaultIndex();

    document.addEventListener('visibilitychange', this.visibilityListener);
  };

  getSnapshotBeforeUpdate = (prevProps: Types.OrderListProps): null => {
    this.updateOrderList(prevProps);

    return null;
  };

  componentDidUpdate(): void {}

  componentWillUnmount = (): void => {
    this.removeRefreshInterval();
    document.removeEventListener('visibilitychange', this.visibilityListener);
  };

  private getDefaultIndex = (): string =>{
    return ORDER_LIST_PAGE_LAYOUTS[this.props.listLayout].defaultIndex || process.env.ALGOLIA_FLOOMX_ORDER_INDEX!;
  }

  private updateDefaultIndex = (): void =>{
    const defaultPageIndex = this.getDefaultIndex();

    if (defaultPageIndex) {
      this.changeIndexName(defaultPageIndex);
    }
  }

  private visibilityListener = (): void => {
    if (document.visibilityState === 'hidden') {
      this.removeRefreshInterval();
    } else if (document.visibilityState === 'visible') {
      this.flushRefreshInterval();
    }
  };

  private toggleRefreshState = (): OrderList => {
    this.setState({ refresh: true }, (): void => {
      this.setState({ refresh: false });
    });

    return this;
  };

  interval = setInterval(this.toggleRefreshState, REFRESH_TIMOUT);

  private removeRefreshInterval = (): OrderList => {
    clearInterval(this.interval);

    return this;
  };

  private updateOrdersCounter = (): OrderList => {
    OrdersAPIService.fetchActiveOrdersCount(this.props.merchantStore?.merchant);

    return this;
  };

  private setRefreshInterval = (): OrderList => {
    this.interval = setInterval(this.toggleRefreshState, REFRESH_TIMOUT);

    return this;
  };

  private flushRefreshInterval = (): void => {
    this.removeRefreshInterval()
      .toggleRefreshState()
      .setRefreshInterval()
      .updateOrdersCounter();
  };

  private changeIndexName = (indexName: string): void => {
    this.setState({ indexName });
  };

  private updateOrderList = (prevProps: Types.OrderListProps): void => {
    if (prevProps.listLayout !== this.props.listLayout && !this.props.location.state.modal) {
      OrderListService.setListLayout(this.props.listLayout);

      this.updateDefaultIndex();
    }
  };

  private getOrderGroupKeys = (): OrderGroupOption[] => {
    const layoutConfig = ORDER_LIST_PAGE_LAYOUTS[this.props.listLayout];
    const groupKey: keyof Pick<OrderListConfig, 'groups' | 'internalGroups'> = PermissionsService.isInternalRole() && !!layoutConfig.internalGroups?.length
      ? 'internalGroups'
      : 'groups';

    return layoutConfig[groupKey]!;
  };

  private onSearchStateChange = (data: any): void => {
    this.setState({ searchState: data });
    OrderListService.cacheListFilters(data);
  };

  private renderGroup = (
    groupName: OrderGroupOption,
    index: number
  ): ReactNode => {
    return (
      <OrderListGroup
        key={`${index}-${groupName}-${this.props.ordersStore!.layoutOption}`}
        groupName={groupName}
        index={index}
        indexName={this.state.indexName}
        listStyleType={this.props.ordersStore!.layoutOption}
        pageLayout={this.props.listLayout}
        shouldRefresh={this.state.refresh}
        onRefresh={this.flushRefreshInterval}
        merchant={this.props.merchantStore?.merchant || undefined}
      />
    );
  };

  private renderBody = (): any => {
    if (!this.props.ordersStore!.searchToken) return null;

    return this
      .getOrderGroupKeys()
      .map(this.renderGroup);
  };

  private renderNoResults = connectStateResults((props): JSX.Element | null => {
    if (props.searchResults?.nbHits !== 0) return null;

    return (
      <OrderNoResults
        listLayout={this.props.listLayout}
        searchValue={this.props.ordersStore!.searchFilterValue || ''}
        isLoading={this.props.ordersStore!.orderListLoading}
        onResetFilters={(): void => {
          this.setState({
            hasSavedFilters: false
          });
        }}
      />
    );
  });

  render(): React.ReactNode {
    const searchClient = this.props.ordersStore!.getSearchClient();
    const pageConfig = ORDER_LIST_PAGE_LAYOUTS[this.props.listLayout];
    const pageFilters = pageConfig.globalFilter.trim();
    const pageNumericFilters = pageConfig.numericFilter(this.props.merchantStore!.merchant?.timezone).trim();

    return (
      <InstantSearch
        indexName={process.env.ALGOLIA_FLOOMX_ORDER_INDEX!}
        searchClient={searchClient}
        refresh={this.state.refresh}
        searchState={this.state.searchState}
        onSearchStateChange={this.onSearchStateChange}
      >
        <Configure
          filters={pageFilters}
          numericFilters={pageNumericFilters}
        />
        <Box mb="60px">
          <Container>
            <OrderListControls
              hasSavedFilters={this.state.hasSavedFilters}
              searchState={this.state.searchState}
              defaultIndex={this.getDefaultIndex()}
              onSort={this.changeIndexName}
              pageLayout={this.props.listLayout}
            />
            <this.renderNoResults />
            {this.renderBody()}
          </Container>
        </Box>
      </InstantSearch>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  merchantStore: stores.merchantStore,
  userStore: stores.userStore
}))(observer(OrderList));

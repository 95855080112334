import { ApolloQueryResult } from '@apollo/client';

import {
  MerchantSupplierIntegrationWhereInput,
  MerchantSupplierIntegration,
  MerchantSupplierIntegrationCreateInput,
  MerchantSupplierIntegrationDetails,
  MerchantSupplierIntegrationUpdateInput,
  SupplierIntegrationType
} from 'generated-types.d';

import {
  GraphQL,
  MerchantSuppliersIntegrationsQueries,
  MerchantSuppliersIntegrationsMutations
} from 'lib';

import { SupplierIntegrationAuth, SupplierIntegratedMerchant } from 'stores/wholesale/wholesale-settings-store/wholesale-settings-store';

import { SUPPLIER_INTEGRATION_MAP } from 'features/wholesale/wholesale.constants';

export default class MerchantSupplierIntegrationService {
  public static retrieveIntegrations = async (merchantId: string): Promise<MerchantSupplierIntegration[]> => {
    const where: MerchantSupplierIntegrationWhereInput = {
      merchant: { id: merchantId }
    };

    try {
      const result: ApolloQueryResult<{merchantSupplierIntegrations: MerchantSupplierIntegration[]}> = await GraphQL.query(MerchantSuppliersIntegrationsQueries.MERCHANT_SUPPLIER_INTEGRATIONS_QUERY, { where });
      const { merchantSupplierIntegrations } = result.data;

      return merchantSupplierIntegrations;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  public static createIntegration = async (input: SupplierIntegrationAuth, supplier: SupplierIntegratedMerchant, merchantId: string): Promise<MerchantSupplierIntegration> => {
    const supplierMap = SUPPLIER_INTEGRATION_MAP[supplier.integrationType];
    const defaultIntegrationStatus = false;

    if (supplier.integrationType === SupplierIntegrationType.Florisoft) {
      const supplierIntegrationInput: MerchantSupplierIntegrationCreateInput = {
        supplier: {
          connect: {
            id: supplier?.id
          }
        },
        merchant: {
          connect: {
            id: merchantId
          }
        },
        active: defaultIntegrationStatus,
        details: supplierMap.fields.reduce((map, fieldConfig) => {
          return {
            ...map,
            [fieldConfig.field]: input[fieldConfig.field]
          };
        }, {}) as Record<keyof MerchantSupplierIntegrationDetails, string>
      };

      try {
        const result: ApolloQueryResult<{createMerchantSupplierIntegration: MerchantSupplierIntegration}> = await GraphQL.query(MerchantSuppliersIntegrationsMutations.CREATE_MERCHANT_SUPPLIER_INTEGRATION, { data: supplierIntegrationInput });
        const { createMerchantSupplierIntegration } = result.data;

        return createMerchantSupplierIntegration;
      } catch (error: any) {
        throw new Error(error);
      }
    }

    return Promise.reject('Unknown supplier type');
  };

  public static updateIntegration = async (input: SupplierIntegrationAuth, supplier: SupplierIntegratedMerchant): Promise<MerchantSupplierIntegration> => {
    const supplierMap = SUPPLIER_INTEGRATION_MAP[supplier.integrationType];
    const integrationId = supplier?.integration?.id;

    if (!integrationId || !supplierMap) {
      return Promise.reject('Wrong integration type or absent integration');
    }

    if (supplier.integrationType === SupplierIntegrationType.Florisoft) {
      const data: MerchantSupplierIntegrationUpdateInput = {
        active: supplier?.integration?.active,
        details: supplierMap.fields.reduce((map, fieldConfig) => {
          return {
            ...map,
            [fieldConfig.field]: input[fieldConfig.field]
          };
        }, {}) as Record<keyof MerchantSupplierIntegrationDetails, string>
      };
      const where = { id: integrationId };

      try {
        const result: ApolloQueryResult<{updateMerchantSupplierIntegration: MerchantSupplierIntegration}> = await GraphQL.query(MerchantSuppliersIntegrationsMutations.UPDATE_MERCHANT_SUPPLIER_INTEGRATION, { data, where });
        const { updateMerchantSupplierIntegration } = result.data;

        return updateMerchantSupplierIntegration;
      } catch (error: any) {
        throw new Error(error);
      }
    }

    return Promise.reject('Unknown supplier type');
  };

  public static changeStatus = async (input: boolean, supplier: SupplierIntegratedMerchant): Promise<MerchantSupplierIntegration> => {
    const data: MerchantSupplierIntegrationUpdateInput = {
      active: input,
      details: { ...supplier?.integration?.authentication?.record }
    };
    const where = { id: supplier?.integration?.id };

    try {
      const result: ApolloQueryResult<{updateMerchantSupplierIntegration: MerchantSupplierIntegration}> = await GraphQL.query(MerchantSuppliersIntegrationsMutations.UPDATE_MERCHANT_SUPPLIER_INTEGRATION, { data, where });
      const { updateMerchantSupplierIntegration } = result.data;

      return updateMerchantSupplierIntegration;
    } catch (error: any) {
      throw new Error(error);
    }
  };
}

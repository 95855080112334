import gql from 'graphql-tag';

export const MERCHANT_HOLIDAY_FRAGMENT = gql`
  fragment MerchantHolidayFragment on MerchantHoliday {
    id
    title
    flc_id
    notes
    startAt
    endAt
    updatedAt
    merchant {
      id
      title
    }
  }
`;

import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import {
  Channel,
  DeliveryCoverageCreateInput,
  DeliveryCoverageType
} from 'generated-types.d';

import {
  createDeliveryZones
} from 'features/settings/components/delivery/components/delivery-coverage/delivery-coverage.helpers';
import DeliveryZoneTags from 'features/settings/components/delivery/components/delivery-coverage/delivery-zone-tags';
import { ZoneTag } from 'features/settings/components/delivery/components/delivery-coverage/delivery-zone-tags/delivery-zone-tags.types';
import DeliveryService from 'features/settings/components/delivery/services';

import Button from 'components/button';
import CheckboxItem from 'components/checkbox-item';
import FormFieldWrapper from 'components/create-labeled-component';
import DropdownNative from 'components/dropdown-native';
import { DropdownOption } from 'components/dropdown/dropdown.types';
import editInPlace from 'components/edit-in-place';
import FieldText from 'components/field-text';
import GenericModal from 'components/generic-modal';
import Loader from 'components/loader';
import RadioList from 'components/radio-list';

import * as Styles from './delivery-coverage-modal.styles';
import * as Types from './delivery-coverage-modal.types';
import { LABEL_DROPDOWN_OPTIONS } from './delivery-coverage.constants';

export interface ChannelSelection {
  title: string;
  slug: Channel;
  isDisabled: boolean;
}

export interface TypeSelection {
  title: DeliveryCoverageType;
  isDisabled: boolean;
}

class DeliveryCoverageModal extends Component<Types.DeliveryCoverageModalProps, Types.DeliveryCoverageModalState> {
  private deliveryService = DeliveryService;

  private channelSelection = (): ChannelSelection[] => {
    return [
      {
        title: 'Floom',
        slug: Channel.Floom,
        isDisabled: !this.props.merchantStore!.hasFloomEnabled
      },
      {
        title: 'Website',
        slug: Channel.Website,
        isDisabled: false
      }
    ];
  };

  private typeSelection = (): TypeSelection[] => {
    return [
      {
        title: DeliveryCoverageType.Core,
        isDisabled: false
      },
      {
        title: DeliveryCoverageType.Expanded,
        isDisabled: this.state?.selectedChannel !== Channel.Floom
      }
    ];
  };

  private getDefaultType = (): DeliveryCoverageType => {
    const defaultType = this.typeSelection().find(item => !item.isDisabled);

    if (defaultType) {
      return defaultType.title;
    }

    return DeliveryCoverageType.Core;
  };

  private getDefaultChannel = (): Channel => {
    const defaultChannel = this.channelSelection().find(item => !item.isDisabled);

    if (defaultChannel) {
      return defaultChannel.slug;
    }

    return Channel.Website;
  };

  state: Types.DeliveryCoverageModalState = {
    coverageFlcApproved: false,
    coverageLabels: [],
    coverageLabel: '',
    coverageTitle: '',
    selectedType: this.getDefaultType(),
    coverageDeliveryZones: [],
    isLoading: false,
    isEditing: false,
    selectedChannel: this.getDefaultChannel(),
    selectedMerchant: this.props.userStore?.merchantId
  };

  componentDidMount = (): void => {
    this.setLabelState();
  };

  private getCoverageLabelOptions = (): DropdownOption[] => {
    if (this.state.selectedType === 'Expanded') {
      return LABEL_DROPDOWN_OPTIONS.filter(option => option.key === 'expanded');
    }

    return LABEL_DROPDOWN_OPTIONS.filter(option => option.key !== 'expanded');
  };

  private setLabelState = (): void => {
    const coverageLabels = this.getCoverageLabelOptions();

    this.setState({
      coverageLabels: coverageLabels,
      coverageLabel: coverageLabels[0].title
    });
  };

  private updateCoverageDeliveryZones = (data: ZoneTag[], isEditing: boolean = false): void => {
    this.setState({
      coverageDeliveryZones: data,
      isEditing: isEditing
    });
  };

  private onMerchantSelect = (value: string): void => {
    this.setState({
      selectedMerchant: value
    });
  };

  private updateFlcApproved = (): void => {
    this.setState(prevState => ({
      coverageFlcApproved: !prevState.coverageFlcApproved
    }));
  };

  private onCoverageTitleChange = (e: any): void => {
    this.setState({
      coverageTitle: e.target.value
    });
  };

  private setCoverageLabel = (value: any): void => {
    this.setState({
      coverageLabel: value
    });
  };

  private onChannelSelect = (selectedChannel: Channel): void => {
    this.setState(state => ({
      selectedChannel: selectedChannel,
      selectedType: selectedChannel === 'Website' ? DeliveryCoverageType.Core : state.selectedType
    }), this.setLabelState);
  };

  private onTypeSelect = (selectedType: DeliveryCoverageType): void => {
    this.setState({
      selectedType
    }, this.setLabelState);
  };

  private onConfirm = async (): Promise<any> => {
    this.setState({
      isLoading: true
    });

    const data: DeliveryCoverageCreateInput = {
      merchant: {
        connect: {
          id: this.state.selectedMerchant
        }
      },
      label: this.state.coverageLabel,
      title: this.state.coverageTitle,
      channel: this.state.selectedChannel,
      type: this.state.selectedType,
      flcApproved: this.state.selectedChannel !== Channel.Floom ? false : this.state.coverageFlcApproved,
      deliveryZones: {
        create: createDeliveryZones(this.state.coverageDeliveryZones)
      }
    };

    try {
      await this.deliveryService.saveNewDeliveryCoverage(data);

      this.props.closeModal();
    } catch (error) {
      this.setState({ isLoading: false });
    }

    this.setState({ isLoading: false });
  };

  isValid = (): boolean => {
    return !this.state.isEditing
      && this.state.coverageDeliveryZones.every(tag => tag.isValid)
      && !!this.state.coverageDeliveryZones.length
      && !!this.state.coverageTitle.length
      && !!this.state.selectedMerchant;
  };

  render(): ReactNode {
    const EditInPlaceTags = editInPlace(DeliveryZoneTags);

    return (
      <GenericModal
        title="New delivery coverage area"
        closeModal={this.props.closeModal}
        modalOpen={this.props.isOpen}
        confirmButtonText="Save changes"
        confirmButtonAction={this.onConfirm}
        confirmButtonDisabled={!this.isValid()}
        extraAction={(
          <div onClick={(): any => this.props.closeModal()}>
            <Button
              appearance="secondary"
              copy="Cancel"
            />
          </div>
        )}
        isButtonLoading={this.state.isLoading}
        innerComponent={(
          <Styles.Container>
            { this.state.selectedChannel === Channel.Floom && (
              <Styles.CheckboxContainer>
                <Box>
                  <CheckboxItem
                    item={{
                      slug: 'coverage-flc-approved',
                      label: 'Floom.com approved'
                    }}
                    isSelected={this.state.coverageFlcApproved}
                    onChange={this.updateFlcApproved}
                    checkboxList={{
                      selectedItems: [],
                      itemValueField: 'label',
                      optionTitleField: 'label',
                      orientation: 'horizontal'
                    }}
                    key="slug"
                    checkboxStyles={css`margin-right: 0;`}
                  />
                </Box>
              </Styles.CheckboxContainer>
            )}
            <Box>
              <FormFieldWrapper title="Merchant">
                <DropdownNative
                  id="delivery-coverage-modal-merchant-select"
                  placeholder="Select a merchant"
                  selected={this.state.selectedMerchant}
                  options={this.props.merchantStore!.merchantList}
                  optionTitleField="title"
                  optionValueField="id"
                  fullWidth={true}
                  onChange={this.onMerchantSelect}
                />
              </FormFieldWrapper>
            </Box>
            <Box>
              {this.props.merchantStore!.merchant ? (
                <FormFieldWrapper title="Channel">
                  <RadioList
                    items={this.channelSelection()}
                    selectedItem={this.state.selectedChannel}
                    itemValueField="slug"
                    itemTitleField="title"
                    orientation="horizontal"
                    onChange={(value: any): void => this.onChannelSelect(value as Channel)}
                  />
                </FormFieldWrapper>
              )
                : (
                  <Flex justifyContent="center">
                    <Loader />
                  </Flex>
                )}
            </Box>
            <Box>
              {this.props.merchantStore!.merchant ? (
                <FormFieldWrapper title="Type">
                  <RadioList
                    items={this.typeSelection()}
                    selectedItem={this.state.selectedType}
                    itemValueField="title"
                    itemTitleField="title"
                    orientation="horizontal"
                    onChange={(value: any): void => this.onTypeSelect(value as DeliveryCoverageType)}
                  />
                </FormFieldWrapper>
              )
                : (
                  <Flex justifyContent="center">
                    <Loader />
                  </Flex>
                )}
            </Box>
            <Box>
              <FormFieldWrapper title="Title">
                <FieldText
                  onChange={this.onCoverageTitleChange}
                  value={this.state.coverageTitle}
                  placeholder="Title"
                  isRequired={true}
                />
              </FormFieldWrapper>
            </Box>
            <Box>
              <FormFieldWrapper title="Label">
                <DropdownNative
                  selected={this.state.coverageLabel}
                  optionTitleField="title"
                  optionValueField="title"
                  id="MerchantSelect"
                  options={this.state.coverageLabels}
                  fullWidth={true}
                  onChange={this.setCoverageLabel}
                />
              </FormFieldWrapper>
            </Box>

            <Box>
              <FormFieldWrapper title="Zones">
                <EditInPlaceTags
                  canEdit={true}
                  onEdit={this.updateCoverageDeliveryZones}
                  data={this.state.coverageDeliveryZones}
                />
              </FormFieldWrapper>
            </Box>
          </Styles.Container>
        )}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  deliveryStore: stores.deliveryStore,
  toasterStore: stores.toasterStore,
  merchantStore: stores.merchantStore,
  userStore: stores.userStore
}))(observer(DeliveryCoverageModal));

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Flex, Box } from 'rebass';

import { UserRole, MerchantHoliday } from 'generated-types.d';

import { PeakFeatureToggle } from 'lib';

import { EntityListRow } from 'components/entity-list/entity-list.styles';
import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType, TableLayoutEntityConfigProps } from 'components/entity-list/table-layout-entity.types';
import Icon from 'components/icon';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import * as Styles from './holiday-list-item.styles';
import * as Types from './holiday-list-item.types';

class HolidayListItem extends Component<Types.HolidayListItemProps, Types.HolidayListItemState> {
  private startAtRef: DayPickerInput | null = null;

  private endAtRef: DayPickerInput | null = null;

  static defaultProps: Partial<Types.HolidayListItemProps> = {
    startDateCopy: 'First day of holiday:',
    endDateCopy: 'First day back:',
    shouldUpdateOnStartDate: false
  };

  state: Types.HolidayListItemState = {
    updatedStartDate: this.props.item.startAt,
    // eslint-disable-next-line react/no-unused-state
    updatedAt: this.props.item.updatedAt,
    isUpdating: false,
    shouldDisplayNotification: false,
    endAt: this.props.item.endAt
  };

  static getDerivedStateFromProps = (
    props: any,
    state: Types.HolidayListItemState
  ): any => {
    if (moment(props.item.updatedAt).isAfter(state.updatedAt)) {
      return {
        updatedStartDate: props.item.startAt,
        endAt: props.item.endAt,
        updatedAt: props.item.updatedAt
      };
    }
  };

  private delayNotification = debounce(() => {
    this.setState({
      shouldDisplayNotification: true
    });
  }, 1000);

  private buildUpdateCopy = (): string => {
    switch (this.state.updateType) {
      case 'delete':
        return 'removing your holiday';

      case 'edit':

      default:
        return 'saving your changes';
    }
  };

  private shouldDisplayNotification = (): boolean => this.state.isUpdating &&
    this.state.shouldDisplayNotification;

  private cellConfig = (): TableLayoutEntityConfigProps[] => {
    const config: TableLayoutEntityConfigProps[] = [];

    if (this.props.shouldDisplayMerchantTitle) {
      const item = this.props.item as MerchantHoliday;

      config.push({
        restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
        cellType: CellType.Text,
        flexGrow: '0',
        flexBasis: '300px',
        props: {
          title: item.merchant?.title || ''
        }
      });
    }

    return [
      ...config,
      {
        cellType: CellType.DatePicker,
        flexGrow: '0',
        flexBasis: '320px',
        props: {
          label: this.props.startDateCopy,
          value: moment(this.state.updatedStartDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'),
          isSaving: this.state.isUpdating,
          disabledDays: {
            before: new Date()
          },
          getInputRef: (ref: DayPickerInput): any => this.startAtRef = ref,
          onChange: (value: string): any => this.onChangeStartDate(value)
        }
      },
      {
        cellType: CellType.DatePicker,
        flexGrow: '1',
        flexBasis: '90px',
        props: {
          label: this.props.endDateCopy,
          isSaving: this.state.isUpdating,
          value: moment(this.state.endAt, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'),
          disabledDays: {
            before: new Date(this.state.updatedStartDate)
          },
          getInputRef: (ref: DayPickerInput): any => this.endAtRef = ref,
          onChange: async (value: string): Promise<any> => await this.onUpdate(value)
        }
      },
      {
        cellType: CellType.Custom,
        customCss: css`
          margin-left: auto;
        `,
        customInnerElement: (
          <Styles.DeleteButton
            as="button"
            isUpdating={this.state.isUpdating}
            onClick={(): any => !this.state.isUpdating && this.onDelete()}
          >
            <Icon iconName="cross-big" />
          </Styles.DeleteButton>
        )
      }
    ];
  };

  /**
   * Need to set a minimal timout because the previous input
   * is blurring at the exact same time, so the next input does not
   * properly focus.
   */
  private onChangeStartDate = async (date: string): Promise<void> => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const endAt = moment(this.state.endAt).isSameOrBefore(moment(date)) ? moment(date).add(1, 'd').format('YYYY-MM-DD') : this.state.endAt;

    this.setState({
      updatedStartDate: date,
      endAt: endAt
    });

    if (this.props.shouldUpdateOnStartDate) {
      await this.props.onUpdate({
        startAt: moment(date).format('YYYY-MM-DD'),
        endAt: endAt
      }, this.props.item.id!);
    }

    setTimeout(() => {
      this.endAtRef?.getInput().focus();
    }, 10);
  };

  private onUpdate = async (endDate: string): Promise<void> => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const endAt = moment(endDate).add(moment(this.state.updatedStartDate!).isSame(endDate, 'd') ? 1 : 0, 'd').format('YYYY-MM-DD');

    this.setState({
      isUpdating: true,
      updateType: 'edit',
      endAt: endAt
    });

    this.delayNotification();

    await this.props.onUpdate({
      startAt: moment(this.state.updatedStartDate).format('YYYY-MM-DD'),
      endAt: endAt
    }, this.props.item.id!);

    this.setState({
      isUpdating: false,
      shouldDisplayNotification: false
    });

    this.delayNotification.cancel();
  };

  private onDelete = async (): Promise<void> => {
    if (!PeakFeatureToggle.isDisabledDuringPeak()) {
      this.delayNotification();

      this.setState({
        isUpdating: true,
        updateType: 'delete'
      });

      await this.props.onDelete(this.props.item.id!);

      this.delayNotification.cancel();
    } else {
      this.props.toasterStore!.popNotificationToast(PeakFeatureToggle.setFeatureToggleNotification());
    }
  };

  render(): ReactNode {
    if (!this.props.item) return null;

    return (
      <EntityListRow
        key={this.props.item.id}
        removeOverflow={true}
      >
        <Flex
          width="100%"
          key={this.props.item.id}
          p="18px 10px"
          alignItems="center"
        >
          <TableLayoutEntity config={this.cellConfig()} />
        </Flex>
        { this.shouldDisplayNotification() && (
          <Box p="0px 10px 10px 10px">
            <Notification
              type={NotificationType.Progress}
              hasIcon={false}
              hasClose={false}
              copy={`We're just ${this.buildUpdateCopy()}, this can sometimes take a while... 2 minute meditation anyone?`}
            />
          </Box>
        )}
      </EntityListRow>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore
}))(observer(HolidayListItem));

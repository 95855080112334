import hex2rgba from 'hex2rgba';

import { colors } from 'utils/rebass-theme';

import {
  ButtonSize,
  ButtonSizeOption,
  ButtonAppearanceOption,
  ButtonAppearance,
  ButtonDisabledOption
} from './button.types';

export interface ButtonTypes {
  [index: string]: string;
}

export const SIZES: { [index in ButtonSizeOption]: ButtonSize; } = {
  xxsmall: {
    padding: '5px 13px',
    fontSize: '12px'
  },
  xsmall: {
    padding: '8px 19px 10px',
    fontSize: '12px'
  },
  small: {
    padding: '10px 24px 13px',
    fontSize: '14px'
  },
  normal: {
    padding: '11px 50px 13px',
    fontSize: '14px'
  },
  large: {
    padding: '20px 50px 21px',
    fontSize: '16px',
    maxHeight: '70px'
  }
};

export const APPEARANCE_OPTIONS: { [index in ButtonAppearanceOption | ButtonDisabledOption]: ButtonAppearance; } = {
  'primary': {
    background: colors.floomMidnightBlue,
    textColor: colors.uiFills,
    borderColor: colors.floomMidnightBlue,
    hoverBackground: '#263564',
    hoverBorderColor: '#263564',
    borderWidth: 1
  },
  'danger': {
    background: colors.floomRed,
    textColor: colors.uiFills,
    borderColor: colors.floomRed,
    hoverBackground: colors.floomRed,
    hoverBorderColor: colors.floomRed,
    borderWidth: 1
  },
  'success': {
    background: colors.emeral,
    textColor: colors.uiFills,
    borderColor: colors.emeral,
    hoverBackground: colors.emeral,
    hoverBorderColor: colors.emeral,
    borderWidth: 1
  },
  'secondary': {
    background: colors.uiFills,
    textColor: colors.floomMidnightBlue,
    borderColor: colors.floomMidnightBlue,
    hoverBackground: colors.background,
    hoverBorderColor: colors.floomMidnightBlue,
    borderWidth: 1
  },
  'secondary-light': {
    background: colors.uiFills,
    textColor: colors.floomMidnightBlue,
    borderColor: colors.shade40,
    hoverBackground: colors.lightGrey,
    hoverBorderColor: colors.shade40,
    borderWidth: 1,
    fontWeight: 400
  },
  'notification': {
    background: colors.ultramarine,
    textColor: colors.uiFills,
    borderColor: colors.ultramarine,
    hoverBackground: colors.ultramarine,
    hoverBorderColor: colors.ultramarine,
    borderWidth: 1
  },
  'disabled-primary': {
    background: colors.shade40,
    textColor: hex2rgba(colors.uiFills, 0.6),
    borderColor: colors.shade40,
    hoverBackground: colors.shade40,
    hoverBorderColor: colors.shade40,
    borderWidth: 1
  },
  'disabled-secondary': {
    background: colors.background,
    textColor: colors.middleGrey,
    borderColor: colors.middleGrey,
    hoverBackground: colors.background,
    hoverBorderColor: colors.middleGrey,
    borderWidth: 1
  },
  'disabled-secondary-light': {
    background: colors.lightGrey,
    textColor: hex2rgba(colors.floomMidnightBlue, 0.6),
    borderColor: colors.shade40,
    hoverBackground: colors.lightGrey,
    hoverBorderColor: colors.shade40,
    borderWidth: 1,
    fontWeight: 400
  },
  'disabled-danger': {
    background: colors.background,
    textColor: colors.middleGrey,
    borderColor: colors.middleGrey,
    hoverBackground: colors.background,
    hoverBorderColor: colors.middleGrey,
    borderWidth: 1
  },
  'disabled-success': {
    background: colors.background,
    textColor: colors.middleGrey,
    borderColor: colors.middleGrey,
    hoverBackground: colors.background,
    hoverBorderColor: colors.middleGrey,
    borderWidth: 1
  },
  'disabled-notification': {
    background: colors.background,
    textColor: colors.middleGrey,
    borderColor: colors.middleGrey,
    hoverBackground: colors.background,
    hoverBorderColor: colors.middleGrey,
    borderWidth: 1
  }
};

import React from 'react';

import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import InsightsHome from 'features/insights';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

const Insights = (): JSX.Element => {
  return(
    <AppLayout>
      {/*
      // @ts-ignore */}
      <Helmet>
        <title>Insights - Coming soon</title>
      </Helmet>
      <Router>
        <AuthedRoute
          path="insights"
          component={InsightsHome}
        />
      </Router>
    </AppLayout>
  );
};

export default Insights;

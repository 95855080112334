import React from 'react';

import { Link } from 'gatsby';
import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box, Text } from 'rebass';

import { textStyles } from 'utils/rebass-theme';
import { TEST_IDS } from 'utils/test/data-test-ids';

import UserService from 'features/login-signup/services/';

import Button from 'components/button';
import FieldText from 'components/field-text';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import StyledLink from 'components/styled-link/styled-link';

import * as Styles from './login-form.styles';

const LOGIN_ERRORS = {
  77046114444: 'Your email and password combination is incorrect',
  77046114404: 'Your email and password combination is incorrect'
};

const SOMETHING_WENT_WRONG = 'Something went wrong when logging you in';

class LoginForm extends React.Component<any> {
  userService = UserService;

  state = {
    emailEntered: false,
    isLoading: false,
    error: null
  };

  componentDidMount = async (): Promise<any> => {
    this.userService.init();
    this.subscribeToLoginErrors();
  };

  subscribeToLoginErrors(): void {
    observe(this.props.accountStore, 'error', ({
      newValue: newError
    }: any) => {
      this.setState({
        error: newError.code && LOGIN_ERRORS[newError.code] ? LOGIN_ERRORS[newError.code] : SOMETHING_WENT_WRONG,
        isLoading: false
      });
    });
  }

  onLogin = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault();

    this.setState({
      error: null
    });

    if (!this.props.accountStore.email) {
      this.setState({ error: 'Email is required' });
    } else if (!this.props.accountStore.password) {
      this.setState({ error: 'Password is required' });
    } else {
      this.setState({ isLoading: true });
      await this.userService.loginUserAccount();
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>, callback: any): void => {
    this.resetError();

    callback(e.target.value);
  };

  hideNotification = (): void => {
    this.props.accountStore.toggleConfirmSentNotification(false);
  };

  resetError = (): void => {
    if (this.hasError()) {
      this.setState({ error: null });
    }
  };

  hasError = (): boolean => !!this.state.error;

  render(): React.ReactNode {
    return (
      <Styles.LoginWrapper>
        <Text
          css={textStyles.h1}
          mb="25px"
        >
          Sign in
        </Text>
        <Text
          css={textStyles.subhead}
          mb="20px"
        >
          { !this.state.emailEntered ?
            'Existing user sign in' :
            'Enter your password'
          }
        </Text>
        {this.props.accountStore.resetEmailSent && (
          <Box mb={['15px', '10px', '35px']}>
            <Notification
              type={NotificationType.Success}
              hasIcon={false}
              textAlign="left"
              copy="If you have an account, we'll email you a reset link."
              onClose={this.hideNotification}
            />
          </Box>
        )}
        <form onSubmit={this.onLogin}>
          <Box alignItems="flex-start">
            <Box
              mt="20px"
              flex="1"
            >
              <FieldText
                placeholder="Email address"
                type="email"
                id="login-email-address-field"
                data-testid={TEST_IDS.Login.email}
                value={this.props.accountStore.email}
                autoFocus={true}
                onChange={(e): void => this.onChange(e, this.userService.updateEmail)}
                error={this.hasError()}
              />
            </Box>
            <Box
              mt="10px"
              flex="1"
            >
              <FieldText
                type="password"
                id="login-password-field"
                data-testid={TEST_IDS.Login.password}
                placeholder="Password"
                value={this.props.accountStore.password}
                onChange={(e): void => this.onChange(e, this.userService.updatePassword)}
                error={this.hasError()}
              />
            </Box>

            <Box m="10px 0 25px">
              <Link
                to="/login/forgot-password"
                data-testid={TEST_IDS.Login.forgotPasswordLink}
              >
                <StyledLink>Forgot password?</StyledLink>
              </Link>
            </Box>

            { this.hasError() && (
              <Styles.Error data-testid={TEST_IDS.Login.errorMessage}>
                {this.state.error}
              </Styles.Error>
            )}
            <Box mt="20px">
              <Box
                as="button"
                type="submit"
                width="100%"
                id="login-submit-button"
                data-testid={TEST_IDS.Login.loginButton}
                disabled={this.hasError() && this.state.emailEntered}
              >
                <Button
                  isParentWidth={true}
                  isDisabled={this.hasError() && this.state.emailEntered}
                  copy={this.state.emailEntered ? 'Login' : 'Next'}
                  isLoading={!this.props.accountStore.loggedIn && this.state.isLoading}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </Styles.LoginWrapper>
    );
  }
}

export default inject('accountStore')(observer(LoginForm));

import React, { ReactNode } from 'react';

import { Flex } from 'rebass';

import Loader from 'components/loader';

import * as Types from './with-loading.types';

class WithLoading extends React.PureComponent<Types.LoadingScreenProps> {
  static defaultProps = {
    marginTop: '100px',
    loaderSize: 'large',
    padding: '0',
    testId: ''
  };

  render(): ReactNode {
    if (this.props.isLoading) {
      return this.props.renderLoading?.() || (
        <Flex
          data-testid={this.props.testId}
          justifyContent="center"
          mt={this.props.marginTop}
          p={this.props.padding}
        >
          <Loader size={this.props.loaderSize} />
        </Flex>
      );
    } else if (this.props.hasNoResults) {
      return this.props.renderNoResults();
    }

    return this.props.renderBody?.() || this.props.children;
  }
}

export default WithLoading;

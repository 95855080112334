import React from 'react';

import { Global } from '@emotion/react';
import ScrollLock from 'react-scrolllock';

import { colors } from 'utils/rebass-theme';

import * as Styles from './overlay.styles';

interface OverlayProps {
  isActive: boolean;
  onClickOverlay: () => void;
}

class Overlay extends React.Component<OverlayProps> {
  static defaultProps = {
    background: colors.black
  };

  render(): React.ReactNode {
    const { isActive, onClickOverlay } = this.props;

    if (!isActive) return null;

    return (
      <>
        <Global styles={Styles.DisableScroll} />
        <Styles.Overlay onClick={onClickOverlay} />
        <ScrollLock isActive={isActive} />
      </>
    );
  }
}

export default Overlay;

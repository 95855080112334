import styled from '@emotion/styled';

import { textStyles, colors } from 'utils/rebass-theme';

export const MerchantInvite = styled.div<any>``;

export const InviteDate = styled.div<any>`
  ${textStyles.label};
  color: ${colors.shade40};
  margin-top: 15px;
  text-transform: uppercase;
`;

export const InviteRequiredInfo = styled.div<any>`
  ${textStyles.body}
  color: ${colors.shade60};
  margin-top: 15px;
  font-size: 12px;
`;

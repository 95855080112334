import { FC } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { ListItemType, WholesaleOrderLineItem } from 'generated-types.d';

import { CurrencyService } from 'lib';

import DraftWholesaleOrderStore from 'stores/wholesale/draft-wholesale-order-store/draft-wholesale-order-store';

import { breakpoints, colors, space } from 'utils/rebass-theme';

import { ListItemColour } from 'features/lists/components/list-item-colour/list-item-colour';
import { ListItemValue } from 'features/lists/components/list-item-value/list-item-value';
import {
  ListCellTitle,
  ListCellSubtitle
} from 'features/lists/lists.styles';
import { ListLayoutConfig } from 'features/lists/lists.types';
import { WholesaleCheckoutHelpers } from 'features/wholesale/helpers/wholesale-helpers';

import { ManageOrderItemPrice } from './components/manage-order-item-price';
import { ManageOrderItemQuantity } from './components/manage-order-item-quantity';
import { ManageOrderItemTitle } from './components/manage-order-item-title';

const smallScreenWidthBaseStyles = css`
  @media only screen and (max-width: ${breakpoints.medium}) {
    width: 50%;
  }

  @media only screen and (max-width: ${breakpoints.small}) {
    width: 100%;
  }`
;

export const MANAGE_ORDER_LIST_FIELD_CONFIG: ListLayoutConfig<WholesaleOrderLineItem> = {
  Default: {
    titleField: {
      key: 'itemName',
      heading: 'Item/Genus',
      customStyles: css`
        @media only screen and (max-width: ${breakpoints.medium}) {
          margin-bottom: ${space[3]}px;
        }
      `,
      renderValue: item => {
        const genus = item.listItem?.catalogItem?.family.genus;
        const colour = item.listItem?.catalogItem?.colour || '';

        return (
          <Box>
            <Box mb={1}>
              <ListCellTitle>
                <ManageOrderItemTitle item={item} />
              </ListCellTitle>
            </Box>
            {!!genus && (
              <ListCellSubtitle>
                <Flex>
                  <span>
                    {genus}
                  </span>
                  {!!colour && (
                    <Box
                      as="span"
                      ml="10px"
                    >
                      <ListItemColour
                        type="hit"
                        status="disabled"
                        colour={colour}
                      />
                    </Box>
                  )}
                </Flex>
              </ListCellSubtitle>
            )}
          </Box>
        );
      }
    },
    fields: [
      {
        key: 'stemLength',
        heading: 'Stem length',
        customStyles: smallScreenWidthBaseStyles,
        supportedTypes: [
          ListItemType.Flower
        ],
        renderValue: ({ listItem }) => {
          const value = ((): string | null => {
            switch (listItem?.__typename) {
              case 'ListItemTypeFlower':
                return listItem?.minimumStemLength ? `${listItem?.minimumStemLength}cm` : null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Stem length"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'potSize',
        heading: 'Pot size',
        customStyles: smallScreenWidthBaseStyles,
        supportedTypes: [
          ListItemType.Plant
        ],
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.listItem?.__typename) {
              case 'ListItemTypePlant':
                return item?.listItem?.potSize || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Pot size"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'weight',
        heading: 'Weight',
        customStyles: smallScreenWidthBaseStyles,
        supportedTypes: [
          ListItemType.Sundry
        ],
        renderValue: ({ listItem }) => {
          const value = ((): string | null => {
            switch (listItem?.__typename) {
              case 'ListItemTypeSundry':
                return listItem?.weight || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Weight"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'height',
        heading: 'Height',
        customStyles: smallScreenWidthBaseStyles,
        supportedTypes: [
          ListItemType.Plant,
          ListItemType.Sundry
        ],
        renderValue: ({ listItem }) => {
          const value = ((): string | null => {
            switch (listItem?.__typename) {
              case 'ListItemTypePlant':
              case 'ListItemTypeSundry':
                return listItem?.height || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Height"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'maturity',
        heading: 'Maturity',
        customStyles: smallScreenWidthBaseStyles,
        supportedTypes: [
          ListItemType.Flower
        ],
        renderValue: ({ listItem }) => {
          const value = ((): string | null => {
            switch (listItem?.__typename) {
              case 'ListItemTypeFlower':
                return listItem?.maturity || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Maturity"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'maxUnitPrice',
        heading: 'Max Unit Price',
        customStyles: css`
          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 100%;
            align-items: center;
          }
        `,
        renderValue: item => {
          const Component: FC<{
            draftWholesaleOrderStore?: DraftWholesaleOrderStore;
          }> = inject((stores: FxStores): InjectedFxStores => ({
            draftWholesaleOrderStore: stores.draftWholesaleOrderStore
          }))(observer(({
            draftWholesaleOrderStore
          }) => {
            const maxUnitPrice = item.listItem?.maxUnitPrice;
            const merchantCurrency = draftWholesaleOrderStore?.selectedOrder?.merchant?.currency;

            return (
              <ListItemValue
                label="Max unit price"
                value={maxUnitPrice ? CurrencyService.formatPrice(maxUnitPrice, merchantCurrency!) : 'No max'}
              />
            );
          }));

          return (
            <Component />
          );
        }
      },
      {
        key: 'unitPrice',
        heading: 'Unit Price',
        customStyles: css`
          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 100%;
            align-items: center;
          }
        `,
        renderValue: item => {
          return (
            <ListItemValue
              label="Unit price"
              value={<ManageOrderItemPrice item={item} />}
            />
          );
        }
      },
      {
        key: 'quantity',
        heading: 'Quantity',
        customStyles: css`
          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 100%;
            align-items: center;
          }
        `,
        renderValue: item => {
          return (
            <ListItemValue
              label="Quantity"
              value={<ManageOrderItemQuantity item={item} />}
            />
          );
        }
      },
      {
        key: 'price',
        heading: 'Total Price',
        customStyles: css`
          min-width: 90px;

          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 100%;
            align-items: center;
            justify-content: flex-end;
          }
        `,
        renderValue: item => {
          const Component: FC<{
            draftWholesaleOrderStore?: DraftWholesaleOrderStore;
          }> = inject((stores: FxStores): InjectedFxStores => ({
            draftWholesaleOrderStore: stores.draftWholesaleOrderStore
          }))(observer(({
            draftWholesaleOrderStore
          }) => {
            const merchantCurrency = draftWholesaleOrderStore!.selectedOrder!.merchant.currency;
            const isDisabled = !!draftWholesaleOrderStore?.orderItemIdsBeingUpdated?.has?.(item.id);

            return (
              <Box color={isDisabled ? colors.middleGrey : colors.floomMidnightBlue}>
                {WholesaleCheckoutHelpers.renderWholesalePrice(item.totalPrice || 0, merchantCurrency)}
              </Box>
            );
          }));

          return (
            <Component />
          );
        }
      }
    ]
  }
};

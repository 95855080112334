import { FC } from 'react';

import { Link } from 'gatsby';

import {
  MarketingLinkProps
} from './link.types';

export const MarketingLink: FC<MarketingLinkProps> = ({
  renderLink,
  type,
  url,
  newTab
}) => {
  if (!url) return null;

  switch (type) {
    case 'external':
      return (
        <a
          href={url}
          target={!!newTab ? '_blank' : '_self'}
          rel="noreferrer"
        >
          {renderLink()}
        </a>
      );

    case 'internal':
      return (
        <Link
          to={url}
          target={!!newTab ? '_blank' : '_self'}
          rel="noreferrer"
        >
          {renderLink()}
        </Link>
      );

    default:
      return null;
  }
};

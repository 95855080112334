import { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { UserRole } from 'generated-types.d';

import { PermissionsService } from 'lib';

import OrderHelpers from 'features/orders/helpers/order-helpers';
import { ORDER_PERMISSIONS } from 'features/orders/orders.constants';
import { OrderEditService } from 'features/orders/services';

import Button from 'components/button';
import ImgixImage from 'components/imgix-image';

import AddOns from '../add-ons';
import EditInPlace from '../edit-in-place';

import * as Styles from './order-item-blocks.styles';
import * as Types from './order-item-blocks.types';

class OrderItemBlocks extends Component<Types.OrderItemBlocksProps> {
  private renderProductImage = (snap: any): ReactNode => {
    const mediaSource = OrderHelpers.getProductImageUrl(snap);

    return (
      <Fragment>
        {(mediaSource?.toLowerCase().includes('http')) ? (
          <img
            src={mediaSource}
            css={Styles.VariationImageStyle}
          />
        )
          : (
            <ImgixImage
              config={{
                path: mediaSource
              }}
              css={Styles.VariationImageStyle}
            />
          )}
      </Fragment>
    );
  };

  private renderProductDetail = (orderItem: any): ReactNode => {
    return (
      <Styles.ProductDetailImgBlock>
        {this.renderProductImage(orderItem.productSnapshot)}
        <Styles.RecipeActionButton onClick={(): any => this.props.ordersStore!.openOrderDetailsModal(this.props.items)}>
          <Button
            copy="View Recipe"
            isParentWidth={true}
          />
        </Styles.RecipeActionButton>
      </Styles.ProductDetailImgBlock>
    );
  };

  private editGiftMessage = (giftMessage: string, orderItem: any, index: number): void => {
    OrderEditService.updateGiftMessage({ giftMessage }, {
      orderNo: this.props.ordersStore!.currentOrder.orderNo,
      index: index,
      itemId: orderItem.id
    });
  };

  private canEditGiftMessage = (): boolean => {
    const restrictedRoles: UserRole[] = ORDER_PERMISSIONS.giftMessages.restrictedRoles;
    const role: UserRole = UserRole[PermissionsService.getUserRole()];

    const hasTeamRole = (userRole: UserRole): boolean => userRole !== UserRole.SuperAdmin &&
      userRole !== UserRole.CustomerService;

    if (this.props.shouldOverrideRoles) return true;

    if (hasTeamRole(role) && restrictedRoles.includes(role)) {
      return false;
    }

    return true;
  };

  private renderGiftMessage = (giftMessage: string, orderItem: any, index: number): ReactNode => (
    <Styles.GiftMessageCol>
      <EditInPlace
        value={giftMessage}
        canEdit={this.canEditGiftMessage()}
        placeholder="No gift message"
        onEdit={(newVal: string): void => {
          this.editGiftMessage(newVal, orderItem, index);
        }}
        maxChars={210}
      />
    </Styles.GiftMessageCol>
  );

  private checkForAddOns = (orderItem: any): boolean => {
    return !!orderItem.addOnsSnapshot
      && !!orderItem.addOnsSnapshot.length
      && orderItem.addOnsSnapshot.length > 0;
  };

  private renderAddOns = (addOnSnapshot: any): ReactNode => (
    <Fragment>
      <AddOns
        addOns={addOnSnapshot}
        height={65}
      />
    </Fragment>
  );

  private renderProducts = (orderItem: any, productQuantity: any): ReactNode => {
    return (
      <Fragment>
        {
          productQuantity.map((giftMessage: string, index: number) => {
            return (
              <Styles.UniqueProductBlock key={index}>
                {this.renderProductDetail(orderItem)}
                {this.renderGiftMessage(giftMessage, orderItem, index)}
              </Styles.UniqueProductBlock>
            );
          })
        }
      </Fragment>
    );
  };

  render(): ReactNode {
    return (
      <Fragment>
        {
          this.props.items.map((orderItem: any) => {
            const productQuantity = [];

            for (let i = 0; i < orderItem.quantity; i++) {
              if (orderItem.giftMessages[i]) {
                productQuantity.push(orderItem.giftMessages[i]);
              } else {
                productQuantity.push('');
              }
            }

            return (
              <Styles.OrderItemBlock key={orderItem.id}>
                <Styles.ProductTitle>
                  <span className="quantity">
                    {orderItem.quantity}
                  </span>
                  {OrderHelpers.getProductTitle(orderItem.productSnapshot)}
                  {OrderHelpers.hasVariation(orderItem.productSnapshot) && (
                    <span className="variation">
                      &nbsp;-&nbsp;{OrderHelpers.getProductVariationTitle(orderItem.productSnapshot)}
                    </span>
                  )}
                </Styles.ProductTitle>

                <Styles.BlockRow>
                  <Box flex="1 1 auto">
                    {this.renderProducts(orderItem, productQuantity)}
                  </Box>

                  <Styles.AddOnsCol isSingular={orderItem.quantity === 1}>
                    {this.checkForAddOns(orderItem) && this.renderAddOns(orderItem.addOnsSnapshot)}
                    {!this.checkForAddOns(orderItem) &&
                      <Styles.Placeholder>No add-ons</Styles.Placeholder>
                    }
                  </Styles.AddOnsCol>
                </Styles.BlockRow>

              </Styles.OrderItemBlock>
            );
          })
        }
      </Fragment>
    );
  }
}

export default inject('ordersStore')(observer(OrderItemBlocks));

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { Analytics } from 'lib';

import ProductEditStore from 'stores/product-edit-store/product-edit-store';
import { Mode } from 'stores/product-edit-store/product-edit-store-types';

import ProductService from 'features/products/services';
import { CreateStep } from 'features/products/services/product-analytics/product-analytics.service';

import CreateVariationTabLayout from './create-variation-tab-layout';
import * as Styles from './create-variation-tab.styles';
import InnerVariationTab from './inner-variation-tab';
import { AddVariationModal } from './modals/add-variation-modal';
import { DeleteVariationModal } from './modals/delete-variation-modal';

interface CreateVariationTabProps {
  page?: string;
  variation?: string;
  productEditStore?: ProductEditStore;
}

class CreateVariationTab extends Component<CreateVariationTabProps> {
  private ProductCreateEditService = ProductService.ProductCreateEditService;

  private ProductAnalyticsService = ProductService.ProductAnalytics;

  state = {
    addVariationModal: false,
    deleteVariationModal: false,
    isDeletingVariations: false
  };

  constructor(props: any) {
    super(props);

    this.ProductCreateEditService.getAvailableVariations();
  }

  private closeAddVariationModal = (): void => {
    this.setState({
      addVariationModal: false
    });
  };

  private closeDeleteVariationModal = (): void => {
    this.setState({
      deleteVariationModal: false
    });
  };

  private submitAddVariation = (variationType: string, useDefault: boolean): void => {
    this.ProductCreateEditService.addNewVariation(variationType, useDefault);
    this.closeAddVariationModal();

    if (this.props.productEditStore!.currentMode === Mode.create) {
      this.ProductAnalyticsService.onCreateStep(
        CreateStep.AddVariation,
        Analytics.FxEventName.ProductCreateStep,
        this.props.productEditStore!.selectedProductTypeTitle,
        variationType
      );
    }

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  };

  private deleteVariations = async (variationIdsToDelete: string[]): Promise<void> => {
    this.setState({ isDeletingVariations: true });

    for (const variationId of variationIdsToDelete) {
      await this.ProductCreateEditService.deleteVariation(variationId);
    }

    this.setState({ isDeletingVariations: false });
    this.closeDeleteVariationModal();
  };

  public render(): React.ReactNode {
    return (
      <Styles.Content>
        { this.props.productEditStore!.variations.length > 1 && (
          <CreateVariationTabLayout
            deleteModalToggle={(): void => this.setState({ deleteVariationModal: true })}
          />
        )}
        <InnerVariationTab
          productService={this.ProductCreateEditService}
          addVariationClick={(): void => this.setState({ addVariationModal: true })}
        />
        <AddVariationModal
          isOpen={this.state.addVariationModal}
          availableVariations={this.ProductCreateEditService.getAvailableVariations()}
          closeAction={this.closeAddVariationModal}
          onConfirm={this.submitAddVariation}
        />
        <DeleteVariationModal
          isOpen={this.state.deleteVariationModal}
          isLoading={this.state.isDeletingVariations}
          availableVariations={this.ProductCreateEditService.getVariationsToDelete()}
          closeAction={this.closeDeleteVariationModal}
          onConfirm={this.deleteVariations}
        />
      </Styles.Content>
    );
  }
}

export default inject('productEditStore')(observer(CreateVariationTab));

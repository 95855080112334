import React, { Component } from 'react';

import * as Styles from './variation-tab-item.styles';
import * as Types from './variation-tab-item.types';

export default class VariationTabItem extends Component<Types.VariationTabItemProps> {
  onSelect = (): void => {
    this.props.onVariationSelect();
    this.props.variation.onClick(this.props.variation.id);
  };

  render(): React.ReactNode {
    return (
      <Styles.TabButton
        key={this.props.variation.id}
        onClick={this.onSelect}
      >
        <Styles.TabNames>
          <Styles.TabText active={this.props.variation.id === this.props.currentVariationId}>
            {this.props.variation.type.toUpperCase()}
          </Styles.TabText>
        </Styles.TabNames>
      </Styles.TabButton>
    );
  }
}

import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import fetch from 'cross-fetch';

import {
  Auth
} from 'lib';

const subscriptionUrl = process.env.CONVERSATIONS_API_URL_WS!;
const apiRegion = process.env.CONVERSATIONS_API_REGION!;

/**
 * @description - Create HTTP Link
 */
const httpLink = createHttpLink({
  uri: process.env.CONVERSATIONS_API_URL,
  fetch: fetch
});

/**
 * @description configure auth options for API subscriptions
 */
const subscriptionAuth: AuthOptions = {
  type: AUTH_TYPE.AWS_LAMBDA,
  token: () => {
    return `Bearer ${Auth.getLoginToken()}`;
  }
};

/**
 * @description configure auth link for API subscriptions
 */
const subscriptionAuthLink = createAuthLink({
  url: subscriptionUrl,
  region: apiRegion,
  auth: subscriptionAuth
});

/**
 * @description instantiate API handshake for real time updates,
 * integrating with HTTP link
 */
const subscriptionHandshakeLink = createSubscriptionHandshakeLink({
  url: subscriptionUrl,
  region: apiRegion,
  auth: subscriptionAuth
}, httpLink);

/**
 * @description - Add Auth headers using login token
 */
const authLink = setContext((_, { headers }) => {
  const token = Auth.getLoginToken();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null
    }
  };
});

/**
 * @todo implement error handling
 */
const errorLink = onError(() => {
  // TODO implement error handling
});

/**
 * @description - Instantiate conversations Apollo Client
 */
export const ConversationsClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    authLink,
    subscriptionAuthLink,
    errorLink,
    subscriptionHandshakeLink
  ])
});

import { FC, Fragment, useCallback, useState } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import pluralize from 'pluralize';
import { Box } from 'rebass';

import {
  ListItem,
  ListItemType,
  ListType
} from 'generated-types.d';

import { NavService, PermissionsService } from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { ListHeadings } from 'features/lists/components/list-headings/list-headings';
import { groupListItemsByType } from 'features/lists/lists.helpers';
import {
  ListCardWrapper,
  ListGroupNameHeading,
  ListHeadingsWrapper,
  ListScrollWrapper,
  ListSectionHeading
} from 'features/lists/lists.styles';
import { DateRangeModalData } from 'features/modal-dialogue/components/modals/date-range-modal/date-range-modal.types';

import { CHECKOUT_LIST_FIELD_CONFIG } from '../../checkout-list.config';
import { CheckoutListFooter } from '../checkout-list-footer/checkout-list-footer';

import {
  ItemsWrapper
} from './checkout-list-body.styles';
import {
  CheckoutListBodyProps
} from './checkout-list-body.types';
import { CheckoutListItem } from './checkout-list-item/checkout-list-item';

const CheckoutListBodyView: FC<CheckoutListBodyProps> = ({
  group,
  type,
  modalStore,
  toasterStore,
  checkoutListStore,
  selectedWholesalePreOrderStore
}) => {
  const [isPlacingPreOrder, setIsPlacingPreOrder] = useState(false);
  const [hasPlacedPreOrder, setHasPlacedPreOrder] = useState(false);

  const deliveryDateConfig = useCallback((): {
    date: string;
    fromDate: string;
    toDate: string;
    disabledDays: any;
    firstAvailableDate: string | undefined;
  } => {
    const disabledDates = PermissionsService.isInternalRole() ? [] : checkoutListStore!.deliveryDates
      .filter(date => !date.canDeliver)
      .map(date => new Date(date.dateString!));

    const isDeliveryDateDisabled = (): boolean => {
      const isDisabledDate = disabledDates.some(date => moment(checkoutListStore!.deliveryDate).isSame(moment(date), 'day'));
      const isBeforeFromDate = moment(checkoutListStore!.deliveryDate).isBefore(fromDate, 'day');
      const isAfterToDate = moment(checkoutListStore!.deliveryDate).isAfter(toDate, 'day');

      return isDisabledDate || isBeforeFromDate || isAfterToDate;
    };

    const firstAvailableDate = checkoutListStore!.deliveryDates.find?.(date => date.canDeliver);
    const fromDate = checkoutListStore!.deliveryDates?.[0]?.dateString || '';
    const toDate = checkoutListStore!.deliveryDates?.[checkoutListStore!.deliveryDates.length - 1]?.dateString || '';

    const disabledDays = [
      ...disabledDates,
      {
        before: new Date(fromDate!),
        after: new Date(toDate!)
      }
    ];

    return {
      date: isDeliveryDateDisabled() ? '' : checkoutListStore!.deliveryDate,
      fromDate: fromDate || '',
      toDate: toDate || '',
      disabledDays: disabledDays,
      firstAvailableDate: PermissionsService.isInternalRole() ? fromDate : firstAvailableDate?.dateString!
    };
  }, [checkoutListStore!.deliveryDate, checkoutListStore!.deliveryDates]);

  if (!checkoutListStore!.list) return null;

  const groupedItems = groupListItemsByType<ListItem>(
    group.listItems,
    item => item
  );

  const getErrorMessage = (error: any): string => {
    if (!error.graphQLErrors) {
      return `Oops, we're having trouble creating your pre-order at the moment!`;
    }

    const graphQLError = error.graphQLErrors?.[0];

    switch (graphQLError.code) {
      case 99046113996: {
        return 'Oops, you already have a pre-order for this delivery date!';
      }

      default: {
        return `Oops, we're having trouble creating your pre-order at the moment!`;
      }
    }
  };

  const placePreOrder = async (): Promise<void> => {
    try {
      setIsPlacingPreOrder(true);

      const result = await checkoutListStore!.createWholesalePreOrder();
      selectedWholesalePreOrderStore!.toggleIsNewPreOrder(true);
      setHasPlacedPreOrder(true);

      setTimeout(() => {
        NavService.wholesalePreOrder(result.id);
      }, 1000);
    } catch (error) {
      toasterStore?.popToast(
        getErrorMessage(error),
        ToastType.Error
      );

      setIsPlacingPreOrder(false);
    } finally {
      setIsPlacingPreOrder(false);
    }
  };

  const openDeliveryDateModal = async (): Promise<void> => {
    if (!checkoutListStore!.deliveryDates.length) {
      toasterStore!.popErrorToast('delivery dates', 'get');

      return;
    }

    const config = deliveryDateConfig();

    modalStore!.triggerModal<DateRangeModalData>({
      modalType: 'dateRange',
      data: {
        title: 'Set delivery date',
        errorCopy: 'setting delivery date',
        formOption: 'single_day',
        fromCopy: 'Date',
        timeoutCopy: '',
        shouldDisplayMerchantSelection: false,
        fromDate: config.date,
        disabledDays: config.disabledDays,
        shouldAutofocus: true,
        initialMerchantId: checkoutListStore!.list?.merchant?.id!,
        initialMonth: config.firstAvailableDate,
        onConfirm: async (from: string): Promise<void> => {
          if (moment(from).isValid()) {
            checkoutListStore!.setDeliveryDate(from);
          }
        }
      }
    });
  };

  const renderHeaderText = (): string => {
    switch (type) {
      case 'valid':
        return `${group.listItems.length} ${pluralize('item', group.listItems.length)} available for pre-order`;

      case 'invalid':
        return `${group.listItems.length} ${pluralize('item', group.listItems.length)} unavailable for pre-order`;

      default:
        return '';
    }
  };

  return (
    <Fragment>
      <ListSectionHeading>
        {renderHeaderText()}
      </ListSectionHeading>
      <ListCardWrapper>
        {
          Object.keys(groupedItems).map(key => {
            const listItemType = key as ListItemType;
            const config = groupedItems[listItemType];

            if (!config?.items?.length) return null;

            return (
              <Box
                mb={20}
                key={config.title}
              >
                <ListGroupNameHeading>
                  {config.title}
                </ListGroupNameHeading>
                <ItemsWrapper>
                  <ListScrollWrapper>
                    <ListHeadingsWrapper>
                      <ListHeadings
                        listType={ListType.Advanced}
                        categoryScope={listItemType}
                        config={CHECKOUT_LIST_FIELD_CONFIG}
                      />
                    </ListHeadingsWrapper>
                    <Box
                      css={css`
                        opacity: ${checkoutListStore!.isCheckingAvailability ? .4 : 1}
                      `}
                    >
                      {
                        config?.items?.map?.((item: ListItem) => {
                          return (
                            <CheckoutListItem
                              key={item.id}
                              listItem={item}
                            />
                          );
                        })
                      }
                    </Box>
                  </ListScrollWrapper>
                </ItemsWrapper>
              </Box>
            );
          })
        }
        <CheckoutListFooter
          group={group}
          onSelectDeliveryDate={openDeliveryDateModal}
          deliveryDate={deliveryDateConfig().date}
          onPlacePreOrder={placePreOrder}
          isPlacingPreOrder={isPlacingPreOrder}
          hasPlacedPreOrder={hasPlacedPreOrder}
        />
      </ListCardWrapper>
    </Fragment>
  );
};

export const CheckoutListBody = inject((stores: FxStores): InjectedFxStores => ({
  checkoutListStore: stores.checkoutListStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore,
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
}))(observer(CheckoutListBodyView));

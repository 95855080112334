import gql from 'graphql-tag';

export const ESTIMATE_MERCHANT_SUBSCRIPTION_MUTATION = gql`
  mutation EstimateMerchantSubscription(
    $data: UpdateMerchantSubscriptionInput!
    $merchantId: ID
  ) {
    estimateMerchantSubscription(
      data: $data,
      where: {
        id: $merchantId
      }
    ) {
      amountToPay
      stripePriceId
      prorationStartsAt
      prorationEndsAt
    }
  }
`;

export const UPDATE_MERCHANT_SUBSCRIPTION_MUTATION = gql`
  mutation UpdateMerchantSubscription(
    $merchantPlanId: String!
    $stripePriceId: String
    $prorationStartsAt: String
    $merchantId: ID
  ) {
    updateMerchantSubscription(
      data: {
        merchantPlanId: $merchantPlanId,
        stripePriceId: $stripePriceId,
        prorationStartsAt: $prorationStartsAt
      },
      where: {
        id: $merchantId
      }
    ) {
      invoiceURL
    }
  }
`;

export const PROPOSE_MERCHANT_UPDATE_MUTATION = gql`
  mutation CreateProposedMerchantUpdate(
    $data: ProposedMerchantUpdateCreateInput!
  ) {
    createProposedMerchantUpdate(
      data: $data
    ) {
      id
      planPrice
      isConfirmed
      merchantStage
      reason
      plan {
        type
        description
        productLimit
        orderLimit
      }
    }
  }
`;

export const PROPOSE_MERCHANT_DELETE_MUTATION = gql`
  mutation DeleteProposedMerchantUpdate(
    $where: ProposedMerchantUpdateWhereUniqueInput!
  ) {
    deleteProposedMerchantUpdate(
      where: $where
    ) {
      id
    }
  }
`;

export const RESET_MERCHANT_SUBSCRIPTION = gql`
  mutation ResetMerchantSubscription(
    $where: MerchantWhereUniqueInput!
  ) {
    resetMerchantSubscription(
      where: $where
    ) {
      id
    }
  }
`;

import React from 'react';

import { inject, observer } from 'mobx-react';

import ProductEditStore from 'stores/product-edit-store/product-edit-store';
import { ProductTab } from 'stores/product-edit-store/product-edit-store-types';

import CreateAddonTab from './create-addon-tab';
import CreateProductTab from './create-product-tab';
import CreateVariationTab from './create-variation-tab';
import ProductDeliveryTab from './product-delivery-tab';

interface GetTabContentProps {
  productEditStore?: ProductEditStore;
}

const TabContent = (props: GetTabContentProps): JSX.Element => {
  switch (props.productEditStore!.currentTab.tabType) {
    case ProductTab.product: return <CreateProductTab />;

    case ProductTab.recipe: return <CreateVariationTab />;

    case ProductTab.addon: return <CreateAddonTab />;

    case ProductTab.delivery: return <ProductDeliveryTab />;
  }
};

export default inject('productEditStore')(observer(TabContent));

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

const isSearching = (props: any): any => props.isSearching ? '0' : null;

export const FieldSearch: any = styled(Box)`
  position: relative;
  ${({ parentWidth }): any => parentWidth ? css`width: 100%;` : ''}

  input {
    border-bottom-left-radius: ${isSearching};
    border-bottom-right-radius: ${isSearching};
  }
`;

export const Results: any = styled(Box)`
  list-style: none;
  position: absolute;
  left: 0;
  right: 0;
  border: 2px solid ${colors.floomMidnightBlue};
  border-radius: 0 0 3px 3px;
  border-top: 0;
  background: ${colors.uiFills};
  max-height: 360px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
`;

export const ViewMoreButton: any = styled(Box)`
  width: 100%;
  display: block;
  text-align: center;
  padding: 20px;
  color: white;
  border-top: 2px solid ${colors.middleGrey};
`;

export const NoResults: any = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px;
`;

export const TEST_IDS = {
  WholesaleShop: {
    searchBar: 'wholesale-shop-search-bar',
    catalogList: 'wholesale-shop-catalog-list',
    catalogListItem: 'wholesale-shop-catalog-list-item',
    catalogListItemImage: 'wholesale-shop-catalog-list-item-image',
    catalogListItemDetails: 'wholesale-shop-catalog-list-item-details',
    catalogListItemPrice: 'wholesale-shop-catalog-list-item-price',
    itemModalImage: 'wholesale-shop-item-modal-image',
    itemModalDetails: 'wholesale-shop-item-modal-details',
    paginationButtons: 'wholesale-shop-pagination-buttons',
    supplierDropdown: 'wholesale-shop-supplier-dropdown',
    pagination: {
      first: 'pagination-first',
      last: 'pagination-last',
      next: 'pagination-next',
      previous: 'pagination-previous',
      item: 'pagination-item-'
    },
    modal: {
      close: 'modal-close'
    },
    basket: {
      container: 'container',
      view: 'basket-view',
      edit: 'basket-edit',
      loading: {
        active: 'active'
      }
    }
  },
  WholesaleShopHeader: {
    orderButton: 'wholesale-shop-header-order-button',
    deliveryDate: 'wholesale-shop-header-delivery-date'
  },
  WholesaleShopFilters: {
    trigger: 'wholesale-shop-filters-trigger'
  },
  WholesaleShopOrderSummary: {
    timeRemaining: 'summary-item-time-remaining',
    cancelButton: 'summary-item-cancel-button'
  },
  ConversationsCloseButton: {
    trigger: 'conversations-close-button'
  },
  ItemQuantityModal: {
    cancelButton: 'item-qty-modal-cancel-button'
  },
  ListSummary: {
    trigger: 'list-summary-header-toggle-button'
  },
  PageHeader: {
    Left: 'page-header-left',
    Right: 'page-header-right',
    Center: 'page-header-center'
  },
  IconButton: {
    Wrapper: 'icon-button-wrapper'
  },
  ButtonDropdown: {
    trigger: 'button-dropdown-trigger',
    item: 'button-dropdown-trigger-item-' // Appends index in code, so will output like `button-dropdown-trigger-item-0`
  },
  Login: {
    email: 'login-email',
    password: 'login-password',
    loginButton: 'login-submit-button',
    forgotPasswordLink: 'forgot-password-link',
    errorMessage: 'login-error-message'
  },
  Navigation: {
    loginLink: 'login-header-link',
    getStartedButton: 'get-started-button',
    desktop: {
      homeLink: 'logo-home-link-desktop',
      hamburgerMenu: 'hamburger-menu-icon-desktop'
    },
    mobile: {
      homeLink: 'logo-home-link-mobile',
      hamburgerMenu: 'hamburger-menu-icon-mobile'
    }
  },
  Overview: {
    merchantName: 'merchant-name',
    todaysDate: 'todays-date'
  },
  Menu: {
    menuList: 'menu-list',
    supportChat: 'support-chat-tab',
    merchantSelect: 'merchant-select',
    account: 'account-tab',
    logoutButton: 'log-out-button'
  },
  PromotedLists: {
    checkoutList: {
      PlacePreOrderButton: 'place-pre-order-button'
    }
  },
  RadioList: {
    item: 'RadioItem-'
  },
  FieldSearch: {
    fieldText: 'field-text',
    searchResult: 'search-result'
  }
};

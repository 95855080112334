import { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';

import { NavService } from 'lib';

import * as Styles from './order-nav-item.styles';
import * as Types from './order-nav-item.types';

class OrderNavItem extends Component<Types.OrderNavItemProps> {
  private navigateToList = (): void => {
    NavService.ordersList(this.props.item.key);
  };

  render(): JSX.Element {
    return(
      <Styles.OrderNavItem
        as="button"
        id={this.props.item.id}
        onClick={this.navigateToList}
      >
        <Flex
          flexWrap={['nowrap', 'nowrap', 'wrap']}
          alignItems="center"
        >
          <Styles.NavHeading as="h3">
            {this.props.item.title}
          </Styles.NavHeading>
          <Styles.TotalOrders>
            {this.props.total}
          </Styles.TotalOrders>
        </Flex>
      </Styles.OrderNavItem>
    );
  }
}

export default inject('ordersStore')(observer(OrderNavItem));

import { FC, Fragment, useContext, useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import {
  useQueryClient,
  useQuery
} from '@tanstack/react-query';
import { navigate } from 'gatsby';
import { NavPages } from 'global.types';
import moment from 'moment';
import pluralize from 'pluralize';
import { Box } from 'rebass';

import { GraphQL } from 'lib';

import { colors } from 'utils/rebass-theme';

import { PusherContext } from 'hooks/PusherContext';

import { HOLD_TIME_IN_SECONDS } from '../constants';
import { getSelectablePostcodes } from '../queries';
import { usePromotedStore } from '../store';
import { HoldPostcodesInput } from '../types';

import { Basket } from './basket';

const HOLD_POSTCODES_MUTATION = gql`
  mutation HoldPostcodes(
    $postcodes: [PromotedPostcodeWhereUniqueInput!]!
    $merchantId: ID!
    $currency: String!
    $holdTimeInSeconds: Int!
  ) {
    holdPromotedPostcodes(
      data: {
        postcodes: $postcodes
        currency: $currency
        holdTimeInSeconds: $holdTimeInSeconds
        merchant: {
          id: $merchantId
        }
      }
    ) {
      paymentIntentClientSecret
    }
  }
`;

const holdPostcodes = async ({
  postcodes,
  currency,
  merchantId,
  holdTimeInSeconds
}: HoldPostcodesInput): Promise<string> => {
  // try {
  const result = await GraphQL.query<{ holdPromotedPostcodes: { paymentIntentClientSecret: string } }>(
    HOLD_POSTCODES_MUTATION,
    {
      postcodes: postcodes.map(postcode => ({ id: postcode.id })),
      currency: currency.toLowerCase(),
      merchantId: merchantId,
      holdTimeInSeconds: holdTimeInSeconds
    }
  );

  return result.data.holdPromotedPostcodes.paymentIntentClientSecret;
  // } catch (error) {
  //  console.log('error', error);
//
  //  return Promise.reject(error);
  // }
};

// interface Message {
//   text: string;
//   author: string;
// }

export const Postcodes: FC = () => {
  console.log('render Postcodes');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropClosedModalOpen, setIsDropClosedModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const queryClient = useQueryClient();
  const pusherInstance = useContext(PusherContext);
  const {
    merchantId,
    merchantTimezone,
    upcomingPeriod,
    setMerchantSelectablePostcodes,
    merchantCurrency,
    merchantSelectablePostcodes,
    setPaymentIntentClientSecret,
    selectedPostcodes,
    currencySymbol,
    togglePostcode,
    discountMultiplier
  } = usePromotedStore();

  const [timeUntilDrop, setTimeUntilDrop] = useState<moment.Duration>(moment.duration(1, 'second'));
  const [timeLeftInDrop, setTimeLeftInDrop] = useState<moment.Duration>(moment.duration(1, 'second'));
  const [intervalState, setIntervalState] = useState<NodeJS.Timeout>();
  // const [intervalStateLeftInDrop, setIntervalStateLeftInDrop] = useState<NodeJS.Timeout>();
  // const dropEndsAt = .tz(merchantTimezone).subtract(1, 'minute');

  // console.log('dropEndsAt', dropEndsAt);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading, isRefetching } = useQuery({
    queryKey: ['postcodes', merchantId],
    queryFn: () => getSelectablePostcodes(
      setMerchantSelectablePostcodes,
      merchantId,
      merchantTimezone,
      upcomingPeriod?.id
    ),
    enabled: !!merchantId && !!upcomingPeriod?.id
  });

  useEffect(() => {
    queryClient.invalidateQueries(['postcodes', merchantId]);
  }, []);

  useEffect(() => {
    const channel = pusherInstance?.subscribe('promoted-postcodes');

    channel?.bind('status-change', () => {
      // Invalidate the 'messages' query
      queryClient.invalidateQueries(['postcodes', merchantId]);
    });

    return () => {
      channel?.unbind_all();
      channel?.unsubscribe();
    };
  }, [queryClient]);

  const setDurations = (): void => {
    const durationUntilDrop = moment.duration(moment(upcomingPeriod?.dropDate).tz(merchantTimezone)
      .diff(moment().tz(merchantTimezone)));

    setTimeUntilDrop(durationUntilDrop);

    const durationLeftInDrop = moment.duration(moment(upcomingPeriod?.startDate).tz(merchantTimezone)
      .subtract(1, 'second').diff(moment().tz(merchantTimezone)));

    setTimeLeftInDrop(durationLeftInDrop);
  };

  useEffect(() => {
    setDurations();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDurations();
    }, 1000);

    setIntervalState(interval);

    return () => clearInterval(interval);
  }, [upcomingPeriod?.dropDate, upcomingPeriod?.startDate]);

  useEffect(() => {
    if (!!upcomingPeriod && timeUntilDrop?.asSeconds() === 0 && timeLeftInDrop?.asSeconds() === 0) {
      console.log('Drop started!');
      clearInterval(intervalState!);
    }
  }, [upcomingPeriod, timeUntilDrop, timeLeftInDrop]);

  useEffect(() => {
    if (!!upcomingPeriod && timeLeftInDrop?.asSeconds() === 0) {
      setIsDropClosedModalOpen(true);
      queryClient.invalidateQueries(['upcomingPeriod']);
    }
  }, [upcomingPeriod, timeLeftInDrop]);

  const handleContinue = async (): Promise<void> => {
    if (!hasPeriodDropped || selectedPostcodes().length === 0 || isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    // check all selected postcodes are still available
    // if they are, navigate to checkout

    try {
      const response = await holdPostcodes({
        postcodes: selectedPostcodes(),
        merchantId: merchantId,
        currency: merchantCurrency,
        holdTimeInSeconds: HOLD_TIME_IN_SECONDS
      });

      if (!!response) {
        setPaymentIntentClientSecret(response);
        navigate(NavPages.PromotedUpcomingPayment);
      }
    } catch (error) {
      setIsSubmitting(false);
      // // show toast
      // console.log('couldnt hold postcodes');
      // setIsSubmitting(false);
      // // show toast
      // console.log(error);
      alert(`Could not hold ${merchantCurrency === 'GBP' ? 'postcodes' : 'zipcodes'}: ${error}`);
    }
  };

  const renderTime = (time: number, label: string): string | null => {
    if (time > 0) {
      return `${time} ${pluralize(label, time)}`;
    }

    return null;
  };

  const renderTimeLeft = (duration: moment.Duration): string => {
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const timeStrings = [
      renderTime(days, 'day'),
      renderTime(hours, 'hour'),
      renderTime(minutes, 'minute'),
      days === 0 && renderTime(seconds, 'second')
    ];

    return timeStrings.filter(time => !!time).join(', ');
  };

  const daysInPeriod = moment(upcomingPeriod?.endDate).diff(moment(upcomingPeriod?.startDate), 'days');

  const renderDropTime = (): string => {
    return moment(upcomingPeriod?.dropDate).tz(merchantTimezone).format('dddd, MMMM Do YYYY, ha');
  };

  const renderDropEndTime = (): string => {
    return moment(upcomingPeriod?.startDate).tz(merchantTimezone).subtract(1, 'second').format('dddd, MMMM Do YYYY, h:mma');
  };

  const hasPeriodDropped = moment().tz(merchantTimezone)
    .isAfter(moment(upcomingPeriod?.dropDate).tz(merchantTimezone)) || timeUntilDrop?.asSeconds() === 0;

  const hasDropClosed = moment().tz(merchantTimezone)
    .isAfter(moment(upcomingPeriod?.startDate).tz(merchantTimezone)) || timeLeftInDrop?.asSeconds() === 0;

  return (
    <Fragment>
      {hasPeriodDropped ? (
        <div className="border border-slate-300 bg-slate-200 rounded-md sm:rounded-lg mb-3">
          <div className="px-4 py-5 sm:px-6 flex items-center">
            <div className="rounded-full w-14 h-14 text-2xl pa-3 bg-slate-100 border border-slate-300 flex items-center justify-around mr-3">
              <span>⏰</span>
            </div>
            <div>
              <p className="text-base leading-6 text-slate-800 font-bold mb-1">Booking closes in {renderTimeLeft(timeLeftInDrop!)}</p>
              <p className="text-sm text-slate-500">{renderDropEndTime()}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="border border-slate-300 bg-slate-200 rounded-md sm:rounded-lg mb-3">
          <div className="px-4 py-5 sm:px-6 flex items-center">
            <div className="rounded-full w-14 h-14 text-2xl pa-3 bg-slate-100 border border-slate-300 flex items-center justify-around mr-3">
              <span>⏰</span>
            </div>
            <div>
              <p className="text-based leading-6 text-slate-800 font-bold mb-1">Booking opens in {renderTimeLeft(timeUntilDrop!)}</p>
              <p className="text-sm text-slate-500">{renderDropTime()}</p>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-6 sm:col-span-4">
          <div
            style={{ maxHeight: '577px' }}
            className="relative bg-white border border-slate-200 overflow-y-scroll h-screen rounded-md sm:rounded-lg"
          >
            <div className="sticky top-0 z-10 bg-white">
              {/* <div className="px-4 py-5 sm:px-6">
                <h3 className="text-base leading-6 font-medium">
                  Select your {merchantCurrency === 'GBP' ? 'postcodes' : 'zipcodes'}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-slate-500">
                  Your personalised segment will always display first in a promoted {merchantCurrency === 'GBP' ? 'postcode' : 'zipcode'}
                </p>
              </div> */}
              {merchantSelectablePostcodes?.length > 0 && (
                // <div className="border-t border-slate-200 px-5 py-5">
                <div className="px-5 py-5">
                  <form
                    className="relative flex flex-1"
                    action="#"
                    method="GET"
                  >
                    <label
                      htmlFor="searchTerm"
                      className="sr-only"
                    >
                      Search
                    </label>
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-slate-400"
                      aria-hidden="true"
                    />
                    <input
                      type="search"
                      name="searchTerm"
                      id="searchTerm"
                      value={searchTerm}
                      placeholder="Search postcodes..."
                      disabled={isSubmitting}
                      onChange={e => setSearchTerm(e.target.value.toUpperCase())}
                      className="block h-full w-full border-0 py-0 pl-8 pr-0 text-slate-900 disabled:bg-inherit
                        placeholder:text-slate-400 focus:ring-0 focus-visible:outline-none sm:text-sm"
                    />
                  </form>
                </div>
              )}
              <div className="border-t border-slate-200">
                <div className="py-2 px-4">
                  <div
                    className="py-3 px-3 sm:px-4 grid grid-cols-[1fr,1fr,2fr,min-content] gap-4 items-center border-b border-slate-200"
                  >
                    <div className="text-sm font-bold">
                      {merchantCurrency === 'GBP' ? 'Postcode' : 'Zipcode'}
                    </div>
                    <div className="text-sm font-bold flex items-center">
                      Status
                      <span
                        onClick={() => setIsModalOpen(true)}
                        className="mt-0.5 inline-block cursor-pointer ml-1.5 w-5 h-5 text-gray-700 rounded-full bg-gray-200 p-1 text-xs leading-none text-center"
                      >
                        ?
                      </span>
                    </div>
                    <div className="text-sm font-bold">
                      Price
                    </div>
                    <div className="text-sm text-white w-20">
                      Action
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 pb-4">
              {isLoading ? (
                <div className="mt-2">
                  {[...Array(5)].map((_, idx) => (
                    <div
                      key={idx}
                      className="h-7 mb-4 animate-pulse bg-gray-200 rounded-md w-full"
                    />
                  ))}
                </div>
              ) : (
                <Fragment>
                  {merchantSelectablePostcodes.filter(postcode => {
                    // filter out postcodes that contain the search term
                    if (searchTerm.length > 0) {
                      return postcode.postcode.toLowerCase().includes(searchTerm.toLowerCase());
                    }

                    return true;
                  }).map((postcode, postcodeIdx) => {
                    const statusColourClass = (() => {
                      if (!hasPeriodDropped) {
                        return 'text-slate-500';
                      }

                      switch (postcode.status) {
                        case 'AVAILABLE':
                          return 'text-emerald-800';

                        case 'SOLD':
                          if (postcode.merchant?.id === merchantId) {
                            return 'text-emerald-950';
                          }

                          return 'text-red-800';

                        default:
                          return 'text-amber-800';
                      }
                    })();
                    const clientFacingStatus = (() => {
                      if (!hasPeriodDropped) {
                        return '--';
                      }

                      switch (postcode.status) {
                        case 'AVAILABLE':
                          return 'Available';

                        case 'SOLD':
                          if (postcode.merchant?.id === merchantId) {
                            return 'Purchased';
                          }

                          return 'Sold';

                        case 'PENDING':
                          return 'Pending';

                        default:
                          return '...';
                      }
                    })();

                    const pricePerDay = ((postcode.price * discountMultiplier()) / daysInPeriod);

                    return (
                      <div
                        key={postcode.id}
                        className={`
                        ${postcodeIdx % 2 === 0 ? 'bg-white' : 'bg-slate-50'}
                        ${postcode.status !== 'AVAILABLE' ? 'opacity-50' : ''}
                        ${' '}py-2 px-3 sm:px-4 grid grid-cols-[1fr,1fr,2fr,min-content] gap-4 items-center rounded-md sm:rounded-lg
                      `}
                      >
                        <div className="text-sm font-medium text-slate-900 rounded-l-md sm:rounded-l-lg">
                          {postcode.postcode}
                        </div>
                        <div className={`text-sm ${statusColourClass}`}>
                          {isLoading || isRefetching ? (
                            <div className="h-4 animate-pulse bg-gray-200 rounded-md w-2/3" />
                          ) : (
                            <Fragment>
                              {clientFacingStatus}
                            </Fragment>
                          )}
                        </div>
                        <div className="text-sm">
                          <span
                            className="text-slate-900 inline-block"
                            style={{ minWidth: '28px' }}
                          >
                            {currencySymbol()}{postcode.price * discountMultiplier()}
                          </span>
                          {discountMultiplier() < 1 && (
                            <span
                              className="text-xs line-through text-slate-500 ml-1.5 inline-block"
                              style={{ minWidth: '28px' }}
                            >
                              {currencySymbol()}{postcode.price}
                            </span>
                          )}
                          <span className="text-xs ml-2 text-slate-500">
                            {currencySymbol()}
                            {
                              Number.isInteger(pricePerDay) ?
                                pricePerDay :
                                pricePerDay.toFixed(2)
                            } per day
                          </span>
                        </div>
                        <div className="whitespace-nowrap text-right text-sm font-medium rounded-r-md sm:rounded-r-lg">
                          <button
                            type="button"
                            className="w-20 inline-flex justify-center items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                            onClick={() => togglePostcode(postcode.postcode)}
                            disabled={hasDropClosed || !hasPeriodDropped || postcode.status !== 'AVAILABLE'}
                          >
                            {!!selectedPostcodes().find(p => p.postcode === postcode.postcode) ? (
                              <Fragment>Remove<span className="sr-only">, {postcode.postcode}</span></Fragment>
                            ) : (
                              <Fragment>Add<span className="sr-only">, {postcode.postcode}</span></Fragment>
                            )}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-6 sm:col-span-2">
          <div className="mb-3">
            <Basket canEdit={true} />
          </div>
          <Box
            onClick={handleContinue}
            disabled={hasDropClosed || !hasPeriodDropped || selectedPostcodes().length === 0 || isSubmitting}
          >
            <button
              disabled={hasDropClosed || !hasPeriodDropped || selectedPostcodes().length === 0 || isSubmitting}
              style={{
                backgroundColor: colors.floomMidnightBlue
              }}
              className="h-14 rounded-md sm:rounded-lg w-full text-white
                justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                disabled:opacity-30"
            >
              <span>Continue</span>
            </button>
          </Box>
        </div>
      </div>
      <StatusModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
      <DropClosedModal
        isOpen={isDropClosedModalOpen}
      />
    </Fragment>
  );
};

const StatusModal: FC<{ isOpen: boolean; setIsOpen: any }> = ({ isOpen, setIsOpen }) => {
  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative"
        style={{ zIndex: 9999 }}
        onClose={setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-fx-mid-blue bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="mb-4">
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="mb-3 text-base font-semibold leading-6"
                    >
                      Statuses explained
                    </Dialog.Title>
                    <div className="bg-slate-100 text-sm rounded-lg px-5 py-4">
                      <div className="mb-4 border-b border-slate-300 pb-3">
                        <p className="mb-1 font-bold text-emerald-800">Available</p>
                        <p>Currently available for purchase</p>
                      </div>
                      <div className="mb-3 border-b border-slate-300 pb-3">
                        <p className="mb-1 font-bold text-amber-800">Pending</p>
                        <p>In someone else&apos;s basket, but not yet paid</p>
                      </div>
                      <div className="mb-3 border-b border-slate-300 pb-3">
                        <p className="mb-1 font-bold text-red-800">Sold</p>
                        <p>No longer available in this drop</p>
                      </div>
                      <div>
                        <p className="mb-1 font-bold text-emerald-950">Purchased</p>
                        <p>You&apos;ve purchased for this period</p>
                      </div>
                    </div>
                  </div>
                </div>
                <Box
                  onClick={() => setIsOpen(false)}
                >
                  <button
                    style={{
                      backgroundColor: colors.floomMidnightBlue
                    }}
                    className="h-10 rounded-md sm:rounded-lg w-full text-white
                        justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                        disabled:opacity-30"
                  >
                    <span>Ok</span>
                  </button>
                </Box>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const goToMyPromotions = (): void => {
  navigate(NavPages.PromotedMyPromotions);
};

const DropClosedModal: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative"
        style={{ zIndex: 9999 }}
        onClose={goToMyPromotions}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-fx-mid-blue bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="mb-4">
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="mb-3 text-base font-semibold leading-6"
                    >
                      Drop closed!
                    </Dialog.Title>
                    <p className="text-sm">That&apos;s it folks. The drop for this period is now&nbsp;closed.</p>
                  </div>
                </div>
                <Box
                  onClick={goToMyPromotions}
                >
                  <button
                    style={{
                      backgroundColor: colors.floomMidnightBlue
                    }}
                    className="h-10 rounded-md sm:rounded-lg w-full text-white
                        justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                        disabled:opacity-30"
                  >
                    <span>Ok</span>
                  </button>
                </Box>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

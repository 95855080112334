import { memo } from 'react';

import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import {
  connectStateResults
} from 'react-instantsearch-core';
import { Box, Flex } from 'rebass';

import {
  breakpoints,
  colors
} from 'utils/rebass-theme';

import { LoadingSpinner } from 'components/loading-spinner/loading-spinner';

const color = hex2rgba(colors.floomMidnightBlue, 0.6);

const LoaderCopy = styled(Box)`
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  color: ${color};

  @media (max-width: ${breakpoints.medium}) {
    display: none;
  }
`;

const LoaderWrapper = styled(Flex)<{ isLoading: boolean }>`
  align-items: center;
  transition: opacity .1s ease-in-out;
  opacity: ${({ isLoading }): number => isLoading ? 1 : 0};
`;

export const LoadingIndicator = memo(connectStateResults(({
  searching,
  isSearchStalled
}) => {
  const isLoading = searching || isSearchStalled;

  return (
    <LoaderWrapper isLoading={isLoading}>
      <LoadingSpinner />
      <LoaderCopy>
        Loading...
      </LoaderCopy>
    </LoaderWrapper>
  );
}));

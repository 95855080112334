import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { Mode, Tab } from 'stores/product-edit-store/product-edit-store-types';

import ErrorMessage from 'components/error-message';

import ProductServices from '../../../services';
import { CreateBreadcrumbSaveModal } from '../modals/create-breadcrumb-save-modal';

import CreateBreadcrumbBox from './create-breadcrumb-box';
import * as Styles from './create-breadcrumb-layout.styles';
import { CreateBreadcrumbLayoutProps, CreateBreadcrumbState } from './create-breadcrumb-layout.types';

class CreateBreadcrumbLayout extends Component<CreateBreadcrumbLayoutProps, CreateBreadcrumbState> {
  productCreateEditService = ProductServices.ProductCreateEditService;

  state: CreateBreadcrumbState = {
    isLoading: false,
    openSaveModal: false,
    displayErrorMessage: false
  };

  saveAndContinue = async (): Promise<void> => {
    this.setState({
      isLoading: true
    });
    await this.props.saveAction(false);

    this.setState({
      openSaveModal: false
    });

    if (this.state.tabChangeMethod) {
      this.state.tabChangeMethod();
    }

    this.setState({
      isLoading: false
    });
  };

  discardAndContinue = async (): Promise<void> => {
    this.setState({
      isLoading: true
    });
    await this.productCreateEditService.loadProductForEdit(this.props.productEditStore!.id!);

    this.setState({
      openSaveModal: false
    });

    if (this.state.tabChangeMethod) {
      this.state.tabChangeMethod();
    }

    this.setState({
      isLoading: false
    });
  };

  changeTab = (tabChangeMethod: () => any | undefined): void => {
    if (this.props.productEditStore!.currentTab.tabIsValid()) {
      this.setState({
        displayErrorMessage: false
      });

      if (this.props.productEditStore!.currentMode === Mode.edit && this.props.isDirty()) {
        this.setState({
          tabChangeMethod: tabChangeMethod,
          openSaveModal: true
        });
      } else {
        tabChangeMethod();
      }
    } else {
      this.setState({
        displayErrorMessage: true
      });
    }
  };

  render(): React.ReactNode {
    return (
      <Styles.ContentWrapper>
        <Styles.Content>
          {
            Object.values(this.props.productEditStore!.tabs).map((tab: Tab): JSX.Element => (
              <CreateBreadcrumbBox
                key={tab.focusText}
                {...tab}
                activeTab={this.props.productEditStore!.currentTab}
                changeTab={(): void => this.changeTab(tab.onClick)}
              />
            ))
          }
        </Styles.Content>
        <Styles.ErrorWrapper>
          <ErrorMessage
            errorMessage={this.state.displayErrorMessage && !this.props.productEditStore!.currentTab.tabIsValid()
              ? 'Some mandatory fields need your attention' : ''}
          />
        </Styles.ErrorWrapper>
        {
          CreateBreadcrumbSaveModal(
            this.state.isLoading,
            this.state.openSaveModal,
            this.saveAndContinue,
            this.discardAndContinue
          )
        }
      </Styles.ContentWrapper>
    );
  }
}

export default inject('productEditStore')(observer(CreateBreadcrumbLayout));

import {
  PlanType,
  SubscriptionStatus as GSubscriptionStatus,
  MerchantStage
} from 'generated-types.d';

export type MerchantSupportedTimezone =
| 'Europe/London'
| 'America/Chicago'
| 'America/New_York'
| 'America/Los_Angeles';

export enum MerchantsTimezones {
  europeLondon = 'Europe/London',
  americaChicago = 'America/Chicago',
  americaLosAngeles = 'America/Los_Angeles',
  americaNewYork = 'America/New_York'
}

export interface MerchantFilterItem {
  title: string;
  value: string;
  slug: string;
}

export interface MerchantFilters {
  plans: MerchantFilterItem[];
  timezone: MerchantFilterItem[];
  stages: MerchantFilterItem[];
  subscriptionStatus: MerchantFilterItem[];
}

export enum MerchantFilterGroup {
  Plans = 'plans',
  Timezone = 'timezone',
  Stages = 'stages',
  SubscriptionStatus = 'subscriptionStatus'
}

export type MerchantFiltersType =
  | 'plans'
  | 'timezone'
  | 'stages'
  | 'subscriptionStatus';

export type MerchantsFilters = {
  [key in MerchantFilterGroup]: MerchantFilterItem[]
};

export type StageMapping = {
  [key in MerchantStage]: string
};

export type SubscriptionStatusMapping = {
  [key in GSubscriptionStatus]: string
};

export type PlansMapping = {
  [key in PlanType]: string
};

export type TimezoneMapping = {
  [key in MerchantsTimezones]: string
};

export type SelectedFilters = {
  [key in MerchantFilterGroup]: Set<PlanType | MerchantsTimezones | MerchantStage | GSubscriptionStatus>
};

export type MerchantFilterCounts = {
  [key in MerchantFilterGroup]: { [
    filterKey in (PlanType | MerchantsTimezones | MerchantStage | GSubscriptionStatus)
    ]: number }
};

export type MerchantListError =
  | 'merchants'
  | 'quickSearch';

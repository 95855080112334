import styled from '@emotion/styled';
import { Flex } from 'rebass';

import {
  breakpoints
} from 'utils/rebass-theme';

export const PageHeadingWrapper: any = styled(Flex)`
  margin: ${({ hasSpacing }): string => hasSpacing ? '30px 0' : ''};

  @media (max-width: ${breakpoints.medium}) {
    margin: 30px 0;
  }
`;

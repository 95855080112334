import React from 'react';

import { Text } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './order-card-row.styles';
import * as Types from './order-card-row.types';

const OrderCardRow = (props: Types.OrderCardRowProps): JSX.Element => {
  return (
    <Styles.OrderRow css={props.rowStyles}>
      <Icon
        iconName={props.iconName}
        pathFill={props.bold ? colors.floomMidnightBlue : colors.middleGrey}
      />
      <Styles.OrderRowTitle onClick={props.onClick}>
        {props.title}
      </Styles.OrderRowTitle>
      {props.label && (
        <Styles.OrderRowLabelWrap label={props.label}>
          <Text
            color={props.label.labelColour || 'white'}
            css={textStyles.footnote}
          >
            {props.label.label}
          </Text>
        </Styles.OrderRowLabelWrap>
      )}
      <Styles.OrderRowValue>
        {props.value}
      </Styles.OrderRowValue>
    </Styles.OrderRow>
  );
};

export default OrderCardRow;

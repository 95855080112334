import React, { Component, ReactNode } from 'react';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './loader.styles';

const crosses = [
  {
    delay: 0,
    color: colors.floomOrange
  },
  {
    delay: 0.5,
    color: colors.floomBlue
  },
  {
    delay: 1,
    color: colors.floomRed
  },
  {
    delay: 1.5,
    color: colors.floomYellow
  }
];

export interface LoaderProps {
  size: 'large' | 'small';
  quantity: number;
  gain: number;
}

export default class Loader extends Component<LoaderProps> {
  static defaultProps = {
    size: 'small',
    quantity: 4,
    gain: 15
  };

  render(): ReactNode {
    return(
      crosses.map((config, key) => {
        if (this.props.quantity >= key + 1) {
          return (
            <Styles.LoadingAnimation
              delay={config.delay}
              activeColour={config.color}
              size={this.props.size === 'large' ? 16 : 10}
              gain={this.props.gain}
              key={key}
            >
              <Icon
                iconName="cross-heavy"
              />
            </Styles.LoadingAnimation>
          );
        }

        return null;
      })
    );
  }
}

import { FC, Fragment } from 'react';

import { Box, Flex } from 'rebass';

import { WholesalePreOrderItem } from 'generated-types.d';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import { ListItemColour } from 'features/lists/components/list-item-colour/list-item-colour';
import {
  ListCellSubtitle,
  ListCellTitle,
  ListItemTitle,
  ListItemSubTitle
} from 'features/lists/lists.styles';

export const PreOrderDefaultTitle: FC<{item: WholesalePreOrderItem}> = ({ item }) => {
  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();
  const title = item.listItem?.catalogItem?.title || item.listItem?.sku;
  const genus = item.listItem?.catalogItem?.family?.genus;

  const itemTitle = item.listItem?.catalogItem?.primaryName ? item.listItem?.catalogItem?.primaryName : title;
  const itemSubTitle = item.listItem?.catalogItem?.secondaryName;

  return wsFriendlyNames.isActive ? (
    <Fragment>
      <ListItemTitle>
        {itemTitle}
      </ListItemTitle>
      {!!itemSubTitle && (
        <ListItemSubTitle>
          {itemSubTitle}
        </ListItemSubTitle>
      )}
    </Fragment>
  ) : (
    <Box>
      <ListCellTitle>
        {title}
      </ListCellTitle>
      {!!genus && (
        <ListCellSubtitle mt="5px">
          <Flex>
            <span>
              {genus}
            </span>
            {!!item.listItem?.colour && (
              <Box
                as="span"
                ml="10px"
              >
                <ListItemColour
                  type="hit"
                  status="disabled"
                  colour={item.listItem?.colour!}
                />
              </Box>
            )}
          </Flex>
        </ListCellSubtitle>
      )}
    </Box>
  );
};

export const PreOrderPromotedTitle: FC<{item: WholesalePreOrderItem}> = ({ item }) => {
  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();

  const title = item.listItem?.catalogItem?.title || item.listItem?.sku;

  const itemTitle = item.listItem?.catalogItem?.primaryName ? item.listItem?.catalogItem?.primaryName : title;
  const itemSubTitle = item.listItem?.catalogItem?.secondaryName;

  return wsFriendlyNames.isActive ? (
    <Fragment>
      <ListItemTitle>
        {itemTitle}
      </ListItemTitle>
      {!!itemSubTitle && (
        <ListItemSubTitle>
          {itemSubTitle}
        </ListItemSubTitle>
      )}
    </Fragment>
  ) : (
    <Box>
      <ListCellTitle>
        {title}
      </ListCellTitle>
    </Box>
  );
};

import Button from 'components/button';
import FormFieldWrapper from 'components/create-labeled-component';

import * as Styles from '../create-variation-tab.styles';

export const renderVariationButton = (onClick: () => any): (JSX.Element | undefined) => {
  return (
    <Styles.VariationWrapper>
      <FormFieldWrapper
        title="Variation"
        optional={true}
        subtitle="You will be able to modify stock, price, size, images for each of them."
      />
      <Styles.VariationButtonWrapper onClick={onClick}>
        <Button
          size="normal"
          appearance="primary"
          copy="Add variation"
          isLoading={false}
          isDisabled={false}
        />
      </Styles.VariationButtonWrapper>
    </Styles.VariationWrapper>
  );
};

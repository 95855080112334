import { gql } from '@apollo/client';

import {
  FULL_LIST_ITEM_FRAGMENT,
  LIST_WITHOUT_ITEM_METADATA_FRAGMENT,
  LIST_WITH_ITEM_METADATA_FRAGMENT
} from '../fragments/list.fragments';

export const CREATE_LIST = gql`
  mutation CreateList($data: ListCreateInput!) {
    createList(data: $data) {
      ...ListWithoutItemMetadataFragment
    }
  }
  ${LIST_WITHOUT_ITEM_METADATA_FRAGMENT}
`;

export const UPDATE_LIST = gql`
  mutation UpdateList(
    $data: ListUpdateInput!,
    $where: ListWhereUniqueInput!
  ) {
    updateList(
      data: $data,
      where: $where
    ) {
      ...ListWithItemMetadataFragment
    }
  }
  ${LIST_WITH_ITEM_METADATA_FRAGMENT}
`;

export const UPDATE_LIST_ITEM = gql`
  mutation UpdateListItem(
    $listItemId: ID
    $data: ListUpdateInput!,
    $where: ListWhereUniqueInput!
  ) {
    updateList(
      data: $data,
      where: $where
    ) {
      items(
        where: {
          id: $listItemId
        }
      ) {
        ...FullListItemFragment
      }
    }
  }
  ${FULL_LIST_ITEM_FRAGMENT}
`;

export const DELETE_LIST = gql`
  mutation DeleteList(
    $where: ListWhereUniqueInput!
  ) {
    deleteList(
      where: $where
    ) {
      id
    }
  }
`;

import { FC, Fragment, ReactNode, useEffect, useState } from 'react';

import { css } from '@emotion/react';
import moment, { Moment } from 'moment';
import { Flex } from 'rebass';
import store from 'stores';

import { GraphQL } from 'lib';

import Icon from 'components/icon';

import * as Styles from './order-checks.styles';
import {
  DELIVERED_CHECK,
  ON_THE_WAY_CHECK,
  UNREAD_ALL_ORDERS_CHECK,
  UNREAD_PRE_ORDERS_CHECK
} from './queries';

const Check: FC<{
  title: string;
  extraTitle?: string;
  subtitle: string;
  count: number;
  total: number;
  status: 'red' | 'amber' | 'green' | 'grey';
}> = ({ title, extraTitle, subtitle, count, total, status }) => {
  return (
    <Styles.CheckContainer>
      <Styles.CheckTitleContainer>
        <Styles.CheckTitle>
          <span>{title}</span>
          {!!extraTitle && (
            <Styles.CheckTitleExtra>
              {extraTitle}
            </Styles.CheckTitleExtra>
          )}
        </Styles.CheckTitle>
        <Styles.CheckSubtitle>
          <span>{subtitle}</span>
        </Styles.CheckSubtitle>
      </Styles.CheckTitleContainer>
      <Styles.CheckContent status={status}>
        <Styles.CheckPercentage>{Math.round((count / total) * 100)}%</Styles.CheckPercentage>
        <Styles.CheckCounts>({count}/{total})</Styles.CheckCounts>
      </Styles.CheckContent>
    </Styles.CheckContainer>
  );
};

// This ain't pretty, but it's the best I could do with the time I had.
// I'd refactor this so we use the time of the check instead
const ORDERS_RECEIVED_BEFORE_HOUR = {
  unreadPre: 17,
  unreadAll: 8,
  onTheWay: 12,
  delivered: 14
};

const OrderChecks: FC = () => {
  const merchantStore = store.merchantStore;
  const userStore = store.userStore;

  const timezone = merchantStore.merchant?.timezone || 'Europe/London';
  const merchantId = userStore.merchantId;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [now, setNow] = useState<Moment>(moment().tz(timezone));
  const [lastUpdatedTime, setLastUpdatedTime] = useState<any>();
  const [orderCheckUnreadPreOrders, setOrderCheckUnreadPreOrders] = useState<any>();
  const [orderCheckUnreadAllOrders, setOrderCheckUnreadAllOrders] = useState<any>();
  const [orderCheckOnTheWay, setOrderCheckOnTheWay] = useState<any>();
  const [orderCheckDelivered, setOrderCheckDelivered] = useState<any>();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const unreadPreOrders = fetchOrderCheckUnreadPreOrders({
        beforeHour: ORDERS_RECEIVED_BEFORE_HOUR['unreadPre']
      }).then(res => {
        setOrderCheckUnreadPreOrders(res.orderCheckUnreadPreOrders);
      });

      const unreadAllOrders = fetchOrderCheckUnreadAllOrders({
        beforeHour: ORDERS_RECEIVED_BEFORE_HOUR['unreadAll']
      }).then(res => {
        setOrderCheckUnreadAllOrders(res.orderCheckUnreadAllOrders);
      });

      const onTheWay = fetchOrderCheckOnTheWay({
        beforeHour: ORDERS_RECEIVED_BEFORE_HOUR['onTheWay']
      }).then(res => {
        setOrderCheckOnTheWay(res.orderCheckOnTheWay);
      });

      const delivered = fetchOrderCheckDelivered({
        beforeHour: ORDERS_RECEIVED_BEFORE_HOUR['delivered']
      }).then(res => {
        setOrderCheckDelivered(res.orderCheckDelivered);
      });

      await Promise.all([unreadPreOrders, unreadAllOrders, onTheWay, delivered]);
    };

    fetchData().then(() => {
      setIsLoading(false);
      setLastUpdatedTime(moment().tz(timezone).format('HH:mm:ss'));
      setNow(moment().tz(timezone));
    });

    const intervalId = setInterval(() => {
      fetchData().then(() => {
        setIsLoading(false);
        setLastUpdatedTime(moment().tz(timezone).format('HH:mm:ss'));
        setNow(moment().tz(timezone));
      });
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchOrderCheckUnreadPreOrders = async ({ beforeHour }: { beforeHour: number }): Promise<any> => {
    return GraphQL.query(UNREAD_PRE_ORDERS_CHECK, { where: { timezone, merchantId, beforeHour } })
      .then(({ data }: any) => data);
  };

  const fetchOrderCheckUnreadAllOrders = async ({ beforeHour }: { beforeHour: number }): Promise<any> => {
    return GraphQL.query(UNREAD_ALL_ORDERS_CHECK, { where: { timezone, merchantId, beforeHour } })
      .then(({ data }: any) => data);
  };

  const fetchOrderCheckOnTheWay = async ({ beforeHour }: { beforeHour: number }): Promise<any> => {
    return GraphQL.query(ON_THE_WAY_CHECK, { where: { timezone, merchantId, beforeHour } })
      .then(({ data }: any) => data);
  };

  const fetchOrderCheckDelivered = async ({ beforeHour }: { beforeHour: number }): Promise<any> => {
    return GraphQL.query(DELIVERED_CHECK, { where: { timezone, merchantId, beforeHour } })
      .then(({ data }: any) => data);
  };

  const renderUnreadPreOrders = (): ReactNode => {
    if (!orderCheckUnreadPreOrders?.merchants?.length) {
      return null;
    }
    const total = orderCheckUnreadPreOrders?.merchants[0]?.total;
    const read = total - orderCheckUnreadPreOrders?.merchants[0]?.unread;
    const checkTime = moment().tz(timezone).startOf('day').add(9, 'hours');
    const isBeforeCheckTime = now.isBefore(checkTime);
    const isUpcoming = now.isBefore(moment(checkTime).subtract(1, 'hour'));

    const getRagStatus = (): 'green' | 'red' | 'amber' | 'grey' => {
      switch (true) {
        case (isUpcoming): {
          return 'grey';
        }

        case (read === total): {
          return 'green';
        }

        default: {
          return isBeforeCheckTime ? 'amber' : 'red';
        }
      }
    };

    return (
      <Check
        title="Read"
        extraTitle="(Pre-orders)"
        subtitle={`100% by ${checkTime.format('ha')}`}
        count={read}
        total={total}
        status={getRagStatus()}
      />
    );
  };

  const renderUnreadAllOrders = (): ReactNode => {
    if (!orderCheckUnreadAllOrders?.merchants?.length) {
      return null;
    }
    const total = orderCheckUnreadAllOrders?.merchants[0]?.total;
    const read = total - orderCheckUnreadAllOrders?.merchants[0]?.unread;
    const checkTime = moment().tz(timezone).startOf('day').add(10, 'hours');
    const isBeforeCheckTime = now.isBefore(checkTime);
    const isUpcoming = now.isBefore(moment(checkTime).subtract(1, 'hour'));

    const getRagStatus = (): 'green' | 'red' | 'amber' | 'grey' => {
      switch (true) {
        case (isUpcoming): {
          return 'grey';
        }

        case (read === total): {
          return 'green';
        }

        default: {
          return isBeforeCheckTime ? 'amber' : 'red';
        }
      }
    };

    return (
      <Check
        title="Read"
        extraTitle="(All orders)"
        subtitle={`100% by ${checkTime.format('ha')}`}
        count={read}
        total={total}
        status={getRagStatus()}
      />
    );
  };

  const renderOnTheWay = (): ReactNode => {
    if (!orderCheckOnTheWay?.merchants?.length) {
      return null;
    }
    const total = orderCheckOnTheWay?.merchants[0]?.total;
    const withFloristOrPrepared = orderCheckOnTheWay?.merchants[0]?.withFloristOrPrepared;
    const onTheWay = total - withFloristOrPrepared;
    const checkTime = moment().tz(timezone).startOf('day').add(14, 'hours');
    const isBeforeCheckTime = now.isBefore(checkTime);
    const isUpcoming = now.isBefore(moment(checkTime).subtract(1, 'hour'));

    const getRagStatus = (): 'green' | 'red' | 'amber' | 'grey' => {
      switch (true) {
        case (isUpcoming): {
          return 'grey';
        }

        case (onTheWay / total >= 0.75): {
          return 'green';
        }

        case (onTheWay / total >= 0.5): {
          return 'amber';
        }

        default: {
          return isBeforeCheckTime ? 'amber' : 'red';
        }
      }
    };

    return (
      <Check
        title="On the way"
        subtitle={`75% by ${checkTime.format('ha')}`}
        count={onTheWay}
        total={total}
        status={getRagStatus()}
      />
    );
  };

  const renderDelivered = (): ReactNode => {
    if (!orderCheckDelivered?.merchants?.length) {
      return null;
    }
    const total = orderCheckDelivered?.merchants[0]?.total;
    const delivered = orderCheckDelivered?.merchants[0]?.delivered;
    const checkTime = moment().tz(timezone).startOf('day').add(16, 'hours');
    const isBeforeCheckTime = now.isBefore(checkTime);
    const isUpcoming = now.isBefore(moment(checkTime).subtract(1, 'hour'));

    const getRagStatus = (): 'green' | 'red' | 'amber' | 'grey' => {
      switch (true) {
        case (isUpcoming): {
          return 'grey';
        }

        case (delivered / total >= 0.5): {
          return 'green';
        }

        case (delivered / total >= 0.25): {
          return 'amber';
        }

        default: {
          return isBeforeCheckTime ? 'amber' : 'red';
        }
      }
    };

    return (
      <Check
        title="Delivered"
        subtitle={`50% by ${checkTime.format('ha')}`}
        count={delivered}
        total={total}
        status={getRagStatus()}
      />
    );
  };

  // const renderLateDelivery = (): ReactNode => {
  //   const total = orderCheckLateDelivery?.merchants[0]?.total;
  //   const delivered = orderCheckLateDelivery?.merchants[0]?.delivered;
  //   const lateDelivery = total - delivered;
  //   const isCurrent = now.isAfter(moment().tz(timezone).startOf('day').add(18, 'hours'));

  //   return (
  //     <Check
  //       title="Late delivery"
  //       count={lateDelivery}
  //       total={total}
  //       isCurrent={isCurrent}
  //     />
  //   );
  // };

  if (!merchantId) {
    return null;
  }

  const hasOrdersForToday = orderCheckDelivered?.merchants[0]?.total > 0;
  const hasUndeliveredOrders = orderCheckDelivered?.merchants[0]?.delivered < orderCheckDelivered?.merchants[0]?.total;

  if (!hasOrdersForToday) {
    return null;
  }

  if (isLoading) {
    return (
      <Fragment>
        Loading...
      </Fragment>
    );
  }

  if (hasUndeliveredOrders) {
    return (
      <Fragment>
        <Flex
          justifyContent="space-between"
          mb="5px"
        >
          <Styles.Title>
            Status checks
            <a
              target="_blank"
              rel="noreferrer"
              href='https://help.floomx.com/hc/en-us/articles/12941487137937'
              css={css`
                margin-left: 4px;
                vertical-align: -2px;
              `}
            >
              <Icon
                size="small"
                iconName="question-circle"
              />
            </a>
          </Styles.Title>
          <Styles.LastUpdated>Last updated: {lastUpdatedTime}</Styles.LastUpdated>
        </Flex>
        <Styles.ChecksContainer>
          {renderUnreadPreOrders()}
          {renderUnreadAllOrders()}
          {renderOnTheWay()}
          {renderDelivered()}
        </Styles.ChecksContainer>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <p>Woohoo, all your orders for today have been delivered 🥳</p>
    </Fragment>
  );
};

export default OrderChecks;

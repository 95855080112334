import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { textStyles, colors, fontSizes } from 'utils/rebass-theme';

export const Icon = styled(Box)`
  box-sizing: border-box;
  background: ${colors.pink};
  width: 30px;
  height: 30px;
  margin-right: 10px;
  text-align: center;
  padding: 3px;
  border-radius: 100%;
  font-weight: bold;
  color: ${colors.floomBlue};
  font-size: ${fontSizes.small}px;
  cursor: pointer;
`;

export const ButtonWrapper: any = styled(Flex)`
  display: inline-flex;
  align-items: center;
`;

export const Button: any = styled.div`
  ${textStyles.subhead}
  text-transform: capitalize;
`;

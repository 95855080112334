import React, { Component, ReactNode, Fragment } from 'react';

import deepmerge from 'deepmerge';
import { Order } from 'generated-types';
import _get from 'lodash.get';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box } from 'rebass';

import { OrderFormModalDataItem } from 'features/orders/orders.types';
import { OrderEditService } from 'features/orders/services';

import DropdownNative from 'components/dropdown-native';
import FieldDatePicker from 'components/field-date-picker';
import FieldText from 'components/field-text';
import FxTextArea from 'components/field-text-area';
import GenericModal from 'components/generic-modal';
import SectionHeading from 'components/section-heading';

import { MODAL_CONFIG } from './order-form-modal.config';
import * as Types from './order-form-modal.types';

const buildFields = (fields: any): any => fields
  .reduce((
    fieldAccumulator: Types.OrderFormModalState['fields'],
    currField: OrderFormModalDataItem
  ) => {
    return {
      ...fieldAccumulator,
      [currField.id]: {
        valid: true,
        dirty: false,
        value: currField.value
      }
    };
  }, {});

class OrderFormModal extends Component<Types.OrderFormModalProps, Types.OrderFormModalState> {
  static getDerivedStateFromProps = (nextProps: any, nextState: any): any => {
    if (nextProps.ordersStore.orderFormModalData && !nextState.fields) {
      return {
        fields: buildFields(nextProps.ordersStore.orderFormModalData)
      };
    }

    if (!nextProps.ordersStore.orderFormModalOpen) {
      return {
        fields: undefined
      };
    }

    return null;
  };

  state: Types.OrderFormModalState = {
    fields: undefined,
    isLoading: false
  };

  updateField = (value: string, id: string): void => {
    this.setState(state => ({
      fields: deepmerge(state.fields!, {
        [id]: {
          dirty: true,
          value: value
        }
      })
    }));
  };

  onConfirm = async (callback: any): Promise<any> => {
    this.setState({ isLoading: true });

    try {
      const order: Order = await callback(this.sanitizeFields(), this.props.ordersStore!.orderFormModalConfig);

      this.props.ordersStore!.setOrder(order);
      OrderEditService.closeOrderFormModal();
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  sanitizeFields = (): any => {
    return Object.keys(this.state.fields!).reduce((fieldAccumulator, currField) => {
      return {
        ...fieldAccumulator,
        [currField]: this.state.fields![currField].value
      };
    }, {});
  };

  buildField = (field: any): JSX.Element | null => {
    const fieldValue = _get(this.state.fields![field.fieldId], 'value');

    switch (field.fieldType) {
      case 'textarea':
        return (
          <FxTextArea
            value={fieldValue}
            onChange={(value): any => this.updateField(value, field.fieldId)}
            placeholder={field.placeholder}
          />
        );

      case 'select':
        return (
          <DropdownNative
            id={field.name}
            options={field.options}
            optionTitleField={field.titleField}
            optionValueField={field.valueField}
            selected={field.formatValue(fieldValue)}
            placeholder={field.placeholder || null}
            onChange={(value: string): void => this.updateField(value, field.fieldId)}
            fullWidth={true}
          />
        );

      case 'text':
        return (
          <FieldText
            autoComplete="off"
            name={field.name}
            type={field.type}
            value={fieldValue}
            placeholder={field.placeholder}
            onChange={(event: any): void => this.updateField(event.target.value, field.fieldId)}
            isSpellCheckEnabled={false}
          />
        );

      case 'region':
        if (_get(this.state, 'fields.country')) {
          return (
            <DropdownNative
              id="region"
              options={field.options(this.state.fields!.country)}
              optionTitleField={field.titleField}
              optionValueField={field.valueField}
              selected={field.formatValue(this.state.fields!.country, fieldValue)}
              onChange={(value: string): void => this.updateField(value, field.fieldId)}
              fullWidth={true}
            />
          );
        }

        return null;

      case 'datepicker':
        return (
          <FieldDatePicker
            onChange={(day: Date): void => this.updateField(moment(day).toISOString(), field.fieldId)}
            value={fieldValue}
            disabledDays={{
              before: new Date()
            }}
          />
        );

      default:
        return null;
    }
  };

  shouldDisplay = (field: any): boolean => field.shouldDisplay ? field.shouldDisplay(this.state.fields) : true;

  render(): ReactNode {
    const config = MODAL_CONFIG[this.props.ordersStore!.orderFormModalType];

    if (config && this.state.fields) {
      return (
        <GenericModal
          width={420}
          innerComponent={(
            <Box p="10px 20px 20px 20px">
              {
                config.fields.map((field, index) => (
                  <Fragment key={index}>
                    { this.state.fields![field.fieldId] && (
                      <>
                        { this.shouldDisplay(field) && (
                          <>
                            { field.label && (
                              <Box mt="20px">
                                <SectionHeading
                                  hasSpacing={false}
                                  title={field.label}
                                />
                              </Box>
                            )}
                            <Box mt="20px">
                              {this.buildField(field)}
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Fragment>
                ))
              }
            </Box>
          )}
          confirmButtonAction={(): any => this.onConfirm(config.action)}
          confirmButtonText={config.buttonText}
          title={config.title}
          modalOpen={this.props.ordersStore!.orderFormModalOpen}
          closeModal={(): void => OrderEditService.closeOrderFormModal()}
          hasFooterBackground={false}
          isButtonLoading={this.state.isLoading}
        />
      );
    }

    return null;
  }
}

export default inject('ordersStore')(observer(OrderFormModal));

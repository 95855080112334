import { FC, Fragment } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ListItemType, WholesaleOrderLineItem } from 'generated-types.d';

import WholesaleOrdersStore from 'stores/wholesale/wholesale-orders-store/wholesale-orders-store';

import { fontSizes, space } from 'utils/rebass-theme';

import { ListHeadings } from 'features/lists/components/list-headings/list-headings';
import { getMedia, groupListItemsByType, listItemImage, listItemMediaImage } from 'features/lists/lists.helpers';
import {
  Columns,
  ItemDetails,
  ItemTitle,
  ListGroupNameHeading,
  ListHeadingsWrapper,
  ListItemWrapper,
  ListScrollWrapper,
  MetaColumn,
  Thumbnail
} from 'features/lists/lists.styles';
import { ListFieldConfig } from 'features/lists/lists.types';

import Icon from 'components/icon';

import { SINGLE_ORDER_LIST_FIELD_CONFIG } from '../single-order.config';

const getItemTypeFromSnapshot = (item: WholesaleOrderLineItem): ListItemType | 'Other' | undefined => {
  if (!!item.listItem || !item.snapshot) {
    return;
  }

  switch (item.snapshot?.type) {
    case 'Flowers': {
      return ListItemType.Flower;
    }

    case 'Plants': {
      return ListItemType.Plant;
    }

    default: {
      return 'Other';
    }
  }
};

export const SingleOrderItems: FC<{
  wholesaleOrdersStore?: WholesaleOrdersStore;
}> = inject((stores: FxStores) => ({
  wholesaleOrdersStore: stores.wholesaleOrdersStore
}))(observer(({
  wholesaleOrdersStore
}) => {
  if (!wholesaleOrdersStore!.selectedOrder) return null;

  const fieldConfig = SINGLE_ORDER_LIST_FIELD_CONFIG.Default;

  if (!fieldConfig) return null;

  const groupedItems = groupListItemsByType<WholesaleOrderLineItem>(
    wholesaleOrdersStore!.selectedOrder.items,
    item => item.listItem!,
    getItemTypeFromSnapshot
  );

  const getItemType = (lineItem: WholesaleOrderLineItem): ListItemType | undefined => {
    if (!!lineItem.listItem) {
      return lineItem.listItem.type;
    }

    switch (lineItem.snapshot?.type) {
      case 'Flowers': {
        return ListItemType.Flower;
      }

      case 'Plants': {
        return ListItemType.Plant;
      }

      default: {
        return ListItemType.Custom;
      }
    }
  };

  const shouldDisplayColumn = (
    field: ListFieldConfig<WholesaleOrderLineItem>,
    itemType: ListItemType | undefined
  ): boolean => {
    return !field.supportedTypes?.length || (!!itemType && field.supportedTypes.includes(itemType));
  };

  return (
    <Fragment>
      {
        Object.keys(groupedItems).map(key => {
          const listItemType = key as ListItemType;
          const config = groupedItems[listItemType];

          if (!config?.items?.length) return null;

          return (
            <Box
              mb={20}
              key={config.title}
            >
              <ListGroupNameHeading>
                {config.title}
              </ListGroupNameHeading>
              <Box
                css={css`
                  position: relative;
                `}
              >
                <ListScrollWrapper>
                  <ListHeadingsWrapper>
                    <ListHeadings
                      listType="Default"
                      categoryScope={listItemType}
                      config={SINGLE_ORDER_LIST_FIELD_CONFIG}
                    />
                  </ListHeadingsWrapper>
                  <Box>
                    {config.items?.map?.((lineItem: WholesaleOrderLineItem) => {
                      const itemType = getItemType(lineItem);
                      const isItemUnavailable = lineItem?.totalPrice === 0;

                      const shouldShowImage = !lineItem?.listItem;
                      const snapshotImage = listItemImage([lineItem?.snapshot?.image], shouldShowImage);
                      const catalogItemImage = listItemMediaImage(getMedia(lineItem!.listItem!), shouldShowImage);

                      return (
                        <ListItemWrapper key={lineItem.id}>
                          <Thumbnail
                            imageUrl={snapshotImage || catalogItemImage}
                            css={css`
                              opacity: ${isItemUnavailable ? 0.5 : 1};
                            `}
                          >
                            {itemType === 'Flower' && (
                              <Icon iconName="flower-squiggle" />
                            )}
                          </Thumbnail>
                          <Columns>
                            <ItemDetails>
                              <ItemTitle>
                                <Box
                                  flex="1"
                                  css={css`
                                    opacity: ${isItemUnavailable ? 0.5 : 1};
                                  `}
                                >
                                  {fieldConfig.titleField.renderValue?.(lineItem)}
                                </Box>
                              </ItemTitle>
                              {isItemUnavailable ? (
                                <Box
                                  mr={space[3]}
                                  fontSize={`${fontSizes.small}px`}
                                >
                                Item unavailable
                                </Box>
                              ) : (
                                <Fragment>
                                  {fieldConfig.fields?.map((field, fieldIndex) => {
                                    if (!shouldDisplayColumn(field, itemType)) return null;

                                    return (
                                      <MetaColumn
                                        key={`${field.key}-${fieldIndex}`}
                                        customStyles={field.customStyles}
                                        isMetadata={false}
                                      >
                                        {field.renderValue?.(lineItem)}
                                      </MetaColumn>
                                    );
                                  })}
                                </Fragment>
                              )}
                            </ItemDetails>
                          </Columns>
                        </ListItemWrapper>
                      );
                    })}
                  </Box>
                </ListScrollWrapper>
              </Box>
            </Box>
          );
        })
      }
    </Fragment>
  );
}));

import { FC, Fragment, useEffect, useState } from 'react';

import 'styles/tailwind.css';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import store from 'stores';

import { PermissionsService } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import ModalStore from 'stores/modal-store/modal-store';

import { colors } from 'utils/rebass-theme';

import { getRemittancePeriods, getTransactions, getTransfer } from '../queries';
import { getOrderNo } from 'utils';

const classNames = (...classes: any): string => {
  return classes.filter(Boolean).join(' ');
};

const merchantStore = store.merchantStore as MerchantStore;
const modalStore = store.modalStore as ModalStore;

const PaymentsOverview: FC = () => {
  const queryClient = useQueryClient();
  const [selectedPeriod, setSelectedPeriod] = useState<any>(null);

  const { data: periods } = useQuery({
    queryKey: ['periods', merchantStore.merchant?.id],
    queryFn: () => getRemittancePeriods(),
    cacheTime: Infinity
  });

  useEffect(() => {
    setSelectedPeriod(periods?.[0]);
  }, [periods]);

  const { data: transactions, isLoading } = useQuery({
    queryKey: ['transactions', merchantStore.merchant?.id, selectedPeriod?.id],
    queryFn: () => getTransactions({
      periodId: selectedPeriod?.id,
      merchantId: merchantStore.merchant?.id
    }),
    enabled: !!selectedPeriod
  });

  const handleSelectedPeriodChange = (period: any): void => {
    setSelectedPeriod(period);

    queryClient.invalidateQueries(['transactions', merchantStore.merchant?.id, selectedPeriod?.id]);
  };

  const dateFormat = merchantStore.merchant?.currency === 'USD' ? 'MM/DD/YY' : 'DD/MM/YY';
  const dateTimeFormat = merchantStore.merchant?.currency === 'USD' ? 'MM/DD HH:mm' : 'DD/MM HH:mm';

  const selectedStartDate = moment(selectedPeriod?.startAt).utc().format(dateFormat);
  const selectedEndDate = moment(selectedPeriod?.endAt).utc().subtract(1, 'day').format(dateFormat);

  const credits = transactions?.filter((transaction: any) => transaction.type === 'Credit');
  const debits = transactions?.filter((transaction: any) => transaction.type === 'Debit');

  const paymentTotal = transactions?.reduce((acc: number, t: any) => {
    if (t.type === 'Credit') {
      return acc + t.merchantRemittance;
    }

    return acc - t.merchantRemittance;
  }, 0) || 0;

  const isPeriodClosed = !!selectedPeriod?.endAt && moment(selectedPeriod?.endAt).isBefore(moment());

  const isInternalRole = PermissionsService.isInternalRole();
  const isSuperAdmin = PermissionsService.isSuperAdmin();

  const otherCredits = credits?.filter((c: any) => c.source !== 'Order') || [];

  return (
    <div
      style={{ fontFamily: 'System UI, Inter' }}
      className="mx-auto px-6 md:px-8 py-6"
    >
      <div className="flex-wrap justify-between items-center">
        <div className="mb-3 flex items-baseline justify-between">
          <p className="text-xl font-bold">Payments</p>
          {!!merchantStore.merchant && (
            <div className="flex items-center">
              <div className="flex items-center mr-3">
                <Listbox
                  value={selectedPeriod}
                  onChange={handleSelectedPeriodChange}
                >
                  {({ open }) => (
                    <Fragment>
                      <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 mr-3">Period</Listbox.Label>
                      <div className="relative">
                        <Listbox.Button className="min-w-[266px] relative h-10 w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                          <span className="inline-flex w-full truncate">
                            <span className="truncate">{selectedPeriod?.number}</span>
                            <span className="ml-2 truncate text-gray-500">{selectedStartDate} - {selectedEndDate}</span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1
                                text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            {periods?.filter((period: any) => {
                              return isInternalRole ? true : period.number >= 108;
                            }).map((period: any) => {
                              const startDate = moment(period.startAt).utc().format(dateFormat);
                              const endDate = moment(period.endAt).utc().subtract(1, 'day').format(dateFormat);

                              return (
                                <Listbox.Option
                                  key={period.id}
                                  className={({ active }) => classNames(
                                    active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                  )}
                                  value={period}
                                >
                                  {({ selected, active }) => (
                                    <Fragment>
                                      <div className="flex">
                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                          {period.number}
                                        </span>
                                        <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                                          {startDate} - {endDate}
                                        </span>
                                      </div>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-indigo-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </Fragment>
                                  )}
                                </Listbox.Option>
                              );
                            })}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Fragment>
                  )}
                </Listbox>
              </div>
              {!!isPeriodClosed && (
                <StatementDownloadButton selectedPeriod={selectedPeriod} />
              )}
              {(isSuperAdmin && !isPeriodClosed) && (
                <AddAdjustmentButton selectedPeriod={selectedPeriod} />
              )}
            </div>
          )}
        </div>
      </div>
      {!merchantStore.merchant ? (
        <div className="h-100 bg-white border border-slate-300 overflow-hidden sm:rounded-lg mb-5">
          <div className="flex items-center p-5 text-center">
            Please select a florist to see their payments 💰
          </div>
        </div>
      ) : (
        <Fragment>
          <p className="text-lg mb-3 font-bold">Credits</p>
          <div className="mb-6">
            <div className="bg-white border border-slate-300 overflow-hidden sm:rounded-lg mb-5">
              <div className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold bg-slate-100">
                <p className="text-md font-bold">Orders</p>
              </div>
              <div className="inline-block min-w-full align-middle">
                <table className="mb-0 min-w-full divide-y divide-slate-300">
                  <thead>
                    <tr className="divide-x divide-gray-200">
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold"
                      >
                          Order
                      </th>
                      {isInternalRole && (
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Net
                        </th>
                      )}
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        Items
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        Delivery
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        Subtotal
                      </th>
                      {isInternalRole && (
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Discount
                        </th>
                      )}
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                          Floom fee
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        Stripe fee
                      </th>
                      {isInternalRole && (
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                        Service fee
                        </th>
                      )}
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        {isInternalRole ? 'Merchant' : 'Payment'}
                      </th>
                      {isInternalRole && (
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Floom
                        </th>
                      )}
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        Transaction date
                      </th>
                      {/* <th
                      scope="col"
                      className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white">
                    {isLoading ? (
                      <Fragment>
                        {[...Array(5)].map((_, idx) => (
                          <tr
                            key={idx}
                            className="divide-x divide-gray-200 even:bg-gray-50 text-slate-500"
                          >
                            {[...Array(isInternalRole ? 12 : 8)].map((_inner, idxInner) => (
                              <td
                                key={idxInner}
                                className="px-2 py-2"
                              >
                                <div className="h-2 animate-pulse bg-gray-200 rounded-md" />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {credits?.filter((c: any) => c.source === 'Order').map((transaction: any) => {
                          const subtotal = transaction.deliveryAmount + transaction.itemAmount;

                          return (
                            <Fragment key={transaction.id}>
                              <tr className="divide-x divide-gray-200 even:bg-gray-50 text-slate-500">
                                <td
                                  className="whitespace-nowrap py-2 pl-4 pr-3 text-sm"
                                >
                                  {getOrderNo(transaction?.order?.orderNo) || '—'}
                                </td>
                                {isInternalRole && (
                                  <td className="whitespace-nowrap px-2 py-2 text-sm">
                                    {transaction.netAmount?.toFixed(2) || '—'}
                                  </td>
                                )}
                                <td className="whitespace-nowrap px-2 py-2 text-sm">
                                  {transaction.itemAmount?.toFixed(2) || '—'}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm">
                                  {transaction.deliveryAmount?.toFixed(2) || '—'}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm">
                                  {!!subtotal ? subtotal.toFixed(2) : '—'}
                                </td>
                                {isInternalRole && (
                                  <td className="whitespace-nowrap px-2 py-2 text-sm">
                                    {!!transaction.discountAmount ? transaction.discountAmount?.toFixed(2) : '—'}
                                  </td>
                                )}
                                <td className="whitespace-nowrap px-2 py-2 text-sm">
                                  {!!transaction.floomFee ? transaction.floomFee?.toFixed(2) : '—'}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm">
                                  {transaction.externalFee?.toFixed(2) || '—'}
                                </td>
                                {isInternalRole && (
                                  <td className="whitespace-nowrap px-2 py-2 text-sm">
                                    {transaction.serviceFee?.toFixed(2) || '—'}
                                  </td>
                                )}
                                <td className="whitespace-nowrap px-2 py-2 text-sm font-bold">
                                  {transaction.merchantRemittance?.toFixed(2) || '—'}
                                </td>
                                {isInternalRole && (
                                  <td className="whitespace-nowrap px-2 py-2 text-sm">
                                    {!!transaction.floomRemittance ? transaction.floomRemittance?.toFixed(2) : '—'}
                                  </td>
                                )}
                                <td className="whitespace-nowrap px-2 py-2 text-sm">
                                  {moment(transaction.createdAt).utc().format(dateTimeFormat)}
                                </td>
                                {/* <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <a
                                  href="#"
                                  className="font-bold text-indigo-600 hover:text-indigo-800"
                                >
                                    Edit<span className="sr-only">, {transaction.id}</span>
                                </a>
                              </td> */}
                              </tr>
                              {(transaction.adjustment
                            && (transaction.adjustment.reasonFreeform || transaction.adjustment.reason)) && (
                                <tr>
                                  <td
                                    colSpan={100}
                                    className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-500"
                                  >
                                    {
                                      [transaction.adjustment.reasonFreeform, transaction.adjustment.reason]
                                        .filter(Boolean)
                                        .join(' - ')
                                    }
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          );
                        })}
                      </Fragment>
                    )}
                  </tbody>
                </table>
              </div>

              {otherCredits.length > 0 && (
                <Fragment>
                  <div className="border-t border-slate-300 pt-5 whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold bg-slate-100">
                    <p className="text-md font-bold">Other</p>
                  </div>
                  <div className="inline-block min-w-full align-middle">
                    <table className="mb-0 min-w-full divide-y divide-slate-300">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold"
                          >
                        Order
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                          >
                        Type
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                          >
                        Net
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                          >
                        Stripe fee
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                          >
                            {isInternalRole ? 'Merchant' : 'Payment'}
                          </th>
                          {isInternalRole && (
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                            >
                          Floom
                            </th>
                          )}
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                          >
                        Transaction date
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-slate-200 bg-white">
                        {isLoading ? (
                          <Fragment>
                            {[...Array(5)].map((_, idx) => (
                              <tr
                                key={idx}
                                className="divide-x divide-gray-200 even:bg-gray-50 text-slate-500"
                              >
                                {[...Array(isInternalRole ? 11 : 6)].map((_inner, idxInner) => (
                                  <td
                                    key={idxInner}
                                    className="px-2 py-2"
                                  >
                                    <div className="h-2 animate-pulse bg-gray-200 rounded-md" />
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {otherCredits.map((transaction: any) => {
                              return (
                                <Fragment key={transaction.id}>
                                  <tr className="divide-x divide-gray-200 even:bg-gray-50 text-slate-500">
                                    <td
                                      className="whitespace-nowrap py-2 pl-4 pr-3 text-sm"
                                    >
                                      {getOrderNo(transaction?.order?.orderNo) || '—'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm font-medium">
                                      {transaction.adjustment?.type || '—'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm">
                                      {transaction.netAmount?.toFixed(2) || '—'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm">
                                      {!!transaction.externalFee ? transaction.externalFee?.toFixed(2) : '—'}
                                    </td>
                                    <td className="whitespace-nowrap px-2 py-2 text-sm font-bold">
                                      {transaction.merchantRemittance?.toFixed(2) || '—'}
                                    </td>
                                    {isInternalRole && (
                                      <td className="whitespace-nowrap px-2 py-2 text-sm">
                                        {!!transaction.floomRemittance ? transaction.floomRemittance?.toFixed(2) : '—'}
                                      </td>
                                    )}
                                    <td className="whitespace-nowrap px-2 py-2 text-sm">
                                      {moment(transaction.createdAt).utc().format(dateTimeFormat)}
                                    </td>
                                    {/* <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                <a
                                  href="#"
                                  className="font-bold text-indigo-600 hover:text-indigo-800"
                                >
                                    Edit<span className="sr-only">, {transaction.id}</span>
                                </a>
                              </td> */}
                                  </tr>
                                  {(
                                    isInternalRole
                                && transaction.adjustment
                                && (transaction.adjustment.reasonFreeform || transaction.adjustment.reason))
                                && (
                                  <tr>
                                    <td
                                      colSpan={100}
                                      className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-500"
                                    >
                                      {
                                        [transaction.adjustment.reasonFreeform, transaction.adjustment.reason]
                                          .filter(Boolean)
                                          .join(' - ')
                                      }
                                    </td>
                                  </tr>
                                )}
                                </Fragment>
                              );
                            })}
                          </Fragment>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Fragment>
              )}

              <div className="border-t border-slate-300 pt-5 whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold bg-slate-100">
                <p className="text-md font-bold">Totals</p>
              </div>
              <div className="inline-block min-w-full align-middle">
                <table className="mb-0 min-w-full divide-y divide-slate-300">
                  <thead>
                    <tr className="divide-x divide-gray-200">
                      {isInternalRole && (
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold"
                        >
                          Net
                        </th>
                      )}
                      <th
                        scope="col"
                        className={`${isInternalRole ? 'px-2 py-3.5' : 'py-3.5 pl-4 pr-3'} whitespace-nowrap text-left text-sm font-semibold`}
                      >
                        Items
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        Delivery
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        Subtotal
                      </th>
                      {isInternalRole && (
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Discount
                        </th>
                      )}
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        Floom fee
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        Stripe fee
                      </th>
                      {isInternalRole && (
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Service fee
                        </th>
                      )}
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                      >
                        {isInternalRole ? 'Merchant' : 'Payment'}
                      </th>
                      {isInternalRole && (
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Floom
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 bg-white">
                    {isLoading ? (
                      <Fragment>
                        {[...Array(1)].map((_, idx) => (
                          <tr
                            key={idx}
                            className="divide-x divide-gray-200 even:bg-gray-50 text-slate-500"
                          >
                            {[...Array(isInternalRole ? 10 : 6)].map((_inner, idxInner) => (
                              <td
                                key={idxInner}
                                className="px-2 py-2"
                              >
                                <div className="h-2 animate-pulse bg-gray-200 rounded-md" />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <tr className="divide-x divide-gray-200 bg-gray-100 text-slate-700">
                          {isInternalRole && (
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm">
                              {credits?.reduce((acc: number, t: any) => acc + t.netAmount, 0).toFixed(2) || '—'}
                            </td>
                          )}
                          <td className={`${isInternalRole ? 'px-2 py-2' : 'py-2 pl-4 pr-3'} whitespace-nowrap text-sm`}>
                            {credits?.reduce((acc: number, t: any) => acc + t.itemAmount, 0).toFixed(2) || '—'}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm">
                            {credits?.reduce((acc: number, t: any) => acc + t.deliveryAmount, 0).toFixed(2) || '—'}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm">
                            {credits?.reduce((acc: number, t: any) => {
                              return acc + (t.deliveryAmount + t.itemAmount);
                            }, 0).toFixed(2) || '—'}
                          </td>
                          {isInternalRole && (
                            <td className="whitespace-nowrap px-2 py-2 text-sm">
                              {credits?.reduce((acc: number, t: any) => acc + t.discountAmount, 0).toFixed(2) || '—'}
                            </td>
                          )}
                          <td className="whitespace-nowrap px-2 py-2 text-sm">
                            {credits?.reduce((acc: number, t: any) => acc + t.floomFee, 0).toFixed(2) || '—'}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm">
                            {credits?.reduce((acc: number, t: any) => acc + t.externalFee, 0).toFixed(2) || '—'}
                          </td>
                          {isInternalRole && (
                            <td className="whitespace-nowrap px-2 py-2 text-sm">
                              {credits?.reduce((acc: number, t: any) => acc + t.serviceFee, 0).toFixed(2) || '—'}
                            </td>
                          )}
                          <td className="whitespace-nowrap px-2 py-2 text-sm font-bold">
                            {credits?.reduce((acc: number, t: any) => acc + t.merchantRemittance, 0).toFixed(2) || '—'}
                          </td>
                          {isInternalRole && (
                            <td className="whitespace-nowrap px-2 py-2 text-sm">
                              {credits?.reduce((acc: number, t: any) => acc + t.floomRemittance, 0).toFixed(2) || '—'}
                            </td>
                          )}
                        </tr>
                      </Fragment>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {debits?.length > 0 && (
            <div className="mb-6">
              <p className="text-lg mb-3 font-bold">Debits</p>
              <div className="bg-white border border-slate-300 overflow-hidden sm:rounded-lg mb-5">
                <div className="inline-block min-w-full align-middle">
                  <table className="mb-0 min-w-full divide-y divide-slate-300">
                    <thead>
                      <tr className="divide-x divide-gray-200">
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold"
                        >
                          Order
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Source
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Reason
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Net
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          {isInternalRole ? 'Merchant' : 'Debited amount'}
                        </th>
                        {isInternalRole && (
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                          >
                          Floom
                          </th>
                        )}
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold"
                        >
                          Transaction date
                        </th>
                        {/* <th
                      scope="col"
                      className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      <span className="sr-only">Edit</span>
                    </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-slate-200 bg-white">
                      {isLoading ? (
                        <Fragment>
                          {[...Array(5)].map((_, idx) => (
                            <tr
                              key={idx}
                              className="divide-x divide-gray-200 even:bg-gray-50 text-slate-500"
                            >
                              {[...Array(isInternalRole ? 8 : 7)].map((_inner, idxInner) => (
                                <td
                                  key={idxInner}
                                  className="px-2 py-2"
                                >
                                  <div className="h-2 animate-pulse bg-gray-200 rounded-md" />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {debits?.map((transaction: any) => (
                            <Fragment key={transaction.id}>
                              <tr
                                className="divide-x divide-gray-200 even:bg-gray-50 text-slate-500"
                                key={transaction.id}
                              >
                                <td
                                  className="whitespace-nowrap py-2 pl-4 pr-3 text-sm"
                                >
                                  {getOrderNo(transaction?.order?.orderNo) || '—'}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium">
                                  {transaction.source}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium">
                                  {transaction.adjustment?.type || '—'}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm font-medium">
                                  {transaction.adjustment?.reason || '—'}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm">
                                  {transaction.netAmount?.toFixed(2)}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm font-bold">
                                  {!!transaction.merchantRemittance ? transaction.merchantRemittance?.toFixed(2) : '—'}
                                </td>
                                {isInternalRole && (
                                  <td className="whitespace-nowrap px-2 py-2 text-sm">
                                    {!!transaction.floomRemittance ? transaction.floomRemittance?.toFixed(2) : '—'}
                                  </td>
                                )}
                                <td className="whitespace-nowrap px-2 py-2 text-sm">
                                  {moment(transaction.createdAt).utc().format(dateTimeFormat)}
                                </td>
                                {/* <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                          <a
                            href="#"
                            className="font-bold text-indigo-600 hover:text-indigo-800"
                          >
                                Edit<span className="sr-only">, {transaction.id}</span>
                          </a>
                        </td> */}
                              </tr>
                              {(isInternalRole && transaction.adjustment.reasonFreeform) && (
                                <tr>
                                  <td
                                    colSpan={100}
                                    className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-slate-500"
                                  >
                                    {transaction.adjustment.reasonFreeform}
                                  </td>
                                </tr>
                              )}
                            </Fragment>
                          ))}
                        </Fragment>
                      )}
                      <tr
                        className="divide-x divide-gray-200 bg-gray-100 text-slate-700"
                      >
                        <td
                          className="whitespace-nowrap py-2 pl-4 pr-3 text-sm sm:pl-0"
                        />
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium" />
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium" />
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-medium" />
                        <td className="whitespace-nowrap px-2 py-2 text-sm">
                          {debits?.reduce((acc: number, t: any) => acc + t.netAmount, 0).toFixed(2) || '—'}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm font-bold">
                          {debits?.reduce((acc: number, t: any) => acc + t.merchantRemittance, 0).toFixed(2) || '—'}
                        </td>
                        {isInternalRole && (
                          <td className="whitespace-nowrap px-2 py-2 text-sm">
                            {debits?.reduce((acc: number, t: any) => acc + t.floomRemittance, 0).toFixed(2) || '—'}
                          </td>
                        )}
                        <td className="whitespace-nowrap px-2 py-2 text-sm" />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          <div className="mb-6">
            <p className="text-lg font-bold mb-3">Totals</p>
            <div className="bg-white border border-slate-300 overflow-hidden sm:rounded-lg mb-5">
              <div className="py-6 px-6 text-slate-500">
                <p className="mb-2">Total payment to be made</p>
                <p className="font-bold text-slate-700">{(paymentTotal).toFixed(2)}</p>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const AddAdjustmentButton: FC<{
  selectedPeriod: any;
}> = ({
  selectedPeriod
}) => {
  const handleAddAdjustment = (orderId?: string): void => {
    modalStore!.triggerModal<any>({
      modalType: 'transactionCreate',
      data: {
        orderId: orderId,
        merchantId: merchantStore!.merchant?.id,
        periodId: selectedPeriod?.id
      }
    });
  };

  return (
    <button
      style={{
        backgroundColor: colors.floomMidnightBlue
      }}
      onClick={() => handleAddAdjustment()}
      className="col-span-1 h-10 px-4 rounded-md sm:rounded-lg text-white
                justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                disabled:opacity-30"
    >
      <span>Add adjustment</span>
    </button>
  );
};

const StatementDownloadButton: FC<{ selectedPeriod: any }> = ({ selectedPeriod }) => {
  const { data: transfer, isLoading } = useQuery({
    queryKey: ['transfer', merchantStore.merchant?.id, selectedPeriod?.id],
    queryFn: () => getTransfer({
      periodId: selectedPeriod?.id,
      merchantId: merchantStore.merchant?.id
    }),
    enabled: !!selectedPeriod
  });

  if (!isLoading && !transfer?.pdfFile) {
    return null;
  }

  return (
    <a
      style={{
        backgroundColor: colors.floomMidnightBlue
      }}
      target="_blank"
      // @todo - protect these links with some kind of auth?
      href={`https://floom-website.s3.eu-west-1.amazonaws.com/remittance-transfers/statements/${selectedPeriod?.number}/${transfer?.pdfFile}`}
      className="mr-3 col-span-1 h-10 px-4 rounded-md sm:rounded-lg text-white
        justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
        disabled:opacity-30"
      rel="noreferrer"
    >
      {isLoading ? (
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0
                        12h4zm2 5.291A7.962 7.962 0 014 12H0c0
                        3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : (
        <span>Download statement</span>
      )}
    </a>
  );
};

export default PaymentsOverview;

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';

import { PermissionsService } from 'lib';

import { TEST_IDS } from 'utils/test/data-test-ids';

import { FieldSelect } from 'components/field-select/field-select';
import { MerchantAPIService } from 'components/navigation/services/merchant-toggle-apollo-service';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import Divider from '../divider';

import * as Styles from './merchant-toggle.styles';
import * as Types from './merchant-toggle.types';

class MerchantToggle extends Component<Types.MerchantToggleProps, Types.MerchantToggleState> {
  merchantService = new MerchantAPIService();

  state = {
    hasError: false
  };

  componentDidMount = async (): Promise<any> => {
    await this.fetchMerchantIds();
  };

  private fetchMerchantIds = async (): Promise<any> => {
    const lastFetchedAtInMins = moment().diff(moment(this.props.merchantStore!.merchantsLastRequestedAt), 'minutes');

    if (!!this.props.merchantStore!.merchantList && !!this.props.merchantStore!.merchantsLastRequestedAt && lastFetchedAtInMins < 60) {
      return;
    }

    if (PermissionsService.isInternalRole()) {
      try {
        await this.merchantService.fetchMerchantIds();
      } catch (error) {
        this.setState({
          hasError: true
        });
      }
    }
  };

  private setNewMerchant = (merchantId: string): void => {
    this.props.userStore!.setMerchantMasquerade(merchantId);
    window.location.reload();
  };

  private shouldRender = (): boolean => {
    return PermissionsService.isInternalRole() && !this.props.navigationStore!.isCollapsed;
  };

  render(): React.ReactNode {
    if (!this.shouldRender()) return null;

    const selectedMerchant = this.props.merchantStore!.merchantList?.find?.(merchant => merchant.id === this.props.userStore!.merchantId);

    return (
      <Styles.MerchantToggleWrapper data-testid={TEST_IDS.Menu.merchantSelect}>
        <Divider name="Merchant" />
        { !this.state.hasError ? (
          <FieldSelect
            onSelect={this.setNewMerchant}
            selectProps={{
              placeholder: 'All',
              isSearchable: true,
              menuPlacement: 'top',
              menuPortalTarget: document.body,
              closeMenuOnScroll: false,
              value: {
                label: selectedMerchant?.title!,
                value: selectedMerchant?.id!
              }
            }}
            options={[
              {
                label: 'All Merchants',
                value: ''
              },
              ...(this.props.merchantStore!.merchantList?.map?.(merchant => {
                const buildLabel = (): string => {
                  if (!merchant.postalCode && !merchant.city) {
                    return merchant.title || '';
                  }

                  if (!merchant.postalCode && !!merchant.city) {
                    return `${merchant.title} - ${merchant.city}`;
                  }

                  const parsedPostcode = merchant.postalCode?.split(' ')?.[0];

                  if (!merchant.city && !!merchant.postalCode) {
                    return `${merchant.title} - ${parsedPostcode}`;
                  }

                  return `${merchant.title} - ${merchant.city} (${parsedPostcode})`;
                };

                return {
                  label: buildLabel(),
                  value: merchant.id
                };
              }) || [])
            ]}
          />
        ) : (
          <Notification
            type={NotificationType.Progress}
            hasIcon={false}
            hasClose={false}
            copy="We are having trouble getting merchants"
          />
        )}
      </Styles.MerchantToggleWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  userStore: stores.userStore,
  merchantStore: stores.merchantStore
}))(observer(MerchantToggle));

import { gql } from '@apollo/client';

export const CREATE_MERCHANT_SUBSCRIPTION = gql`
  mutation(
    $merchantId: ID!
    $stripeToken: String!
    $last4: String!
  ) {
    createMerchantSubscription(where: {
      id: $merchantId
    },
    data: {
      stripe_token: $stripeToken
      last_4_digits: $last4
    }) {
      suscription_id
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PAYMENT_METHOD = gql`
  mutation UpdateSubscriptionPaymentMethod(
    $customerId: String!
    $paymentToken: String!
    $merchantId: ID!
    $last4: String!
  ) {
    updateMerchantSubscriptionPaymentMethod(
      where: {
        id: $merchantId
      }
      data: {
        stripe_customer_id: $customerId
        new_payment_token: $paymentToken
        last_4_digits: $last4
      }
    ) {
      response
    }
  }
`;

import { ApolloQueryResult } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import store from 'stores';

import {
  UserLoginInput,
  ResetPasswordCompleteInput,
  Merchant,
  UserRole,
  User,
  PlanFeature
} from 'generated-types.d';

import {
  GraphQL,
  Auth,
  NavService,
  PermissionsService,
  Analytics,
  MerchantService
} from 'lib';

import {
  USER_DETAILS_QUERY
} from 'features/settings/components/account/services/user-apollo.queries';

import {
  MERCHANT_QUERY
} from './teams-apollo.queries';
import {
  USER_LOGIN_MUTATION,
  USER_INIT_PASSWORD_RESET_MUTATION,
  USER_RESET_PASSWORD_COMPLETE_MUTATION
} from './user-apollo.mutators';

const loginErrorMessage = 'Something went wrong, please try again.';

export default class UserService implements UserService {
  public async init(): Promise<any> {
    if (Auth.isLoggedIn()) {
      store.accountStore.setAccountLoggedIn(true);

      await this.configureUserSearchToken();
      await this.populateUserDetails();
      await this.getMerchantDetails();
      this.populateStoresWithUserData();
      this.handleSessionRouting();
    }
  }

  public configureUserSearchToken = async (): Promise<void> => {
    const token = await Auth.fetchOrderSearchToken();

    if (!!token) {
      store.ordersStore.setSearchToken(token);
    }
  };

  public async sendConfirmationEmail(email: string): Promise<any> {
    GraphQL.mutate(USER_INIT_PASSWORD_RESET_MUTATION, { args: { email } })
      .then(async () => {
        store.accountStore.toggleConfirmSentNotification(true);
        NavService.login();
      })
      .catch(error => {
        if (error.graphQLErrors) {
          store.accountStore.setAccountError(error.graphQLErrors[0]);
        } else {
          store.accountStore.setAccountError(loginErrorMessage);
        }
      });
  }

  public updateEmail = (email: string): void => {
    store.accountStore.setAccountEmail(email);
  };

  public updatePassword = (password: string): void => {
    store.accountStore.setAccountPassword(password);
  };

  private generateBaseResetPasswordArgs = (): ResetPasswordCompleteInput => {
    return {
      password: store.accountStore.newPassword,
      id: store.accountStore.resetPasswordAuth.i,
      authorisation: store.accountStore.resetPasswordAuth.a
    };
  };

  public resetPassword = async (): Promise<void> => {
    return GraphQL.mutate(USER_RESET_PASSWORD_COMPLETE_MUTATION, { args: this.generateBaseResetPasswordArgs() })
      .then(async result => {
        store.toasterStore.popSuccessToast('Password', 'change');

        this.handleLogin(result.data.resetPasswordComplete.token);
      })
      .catch(error => {
        if (error.graphQLErrors) {
          store.accountStore.setAccountError(error.graphQLErrors[0]);
        } else {
          store.accountStore.setAccountError(loginErrorMessage);
        }
      });
  };

  public createPasswordFromInvite = async (
    hasAcceptedTerms: boolean,
    hasAgreedNewsletterSubscribe: boolean
  ): Promise<void> => {
    const args: ResetPasswordCompleteInput = {
      ...this.generateBaseResetPasswordArgs(),
      merchantId: store.accountStore.resetPasswordAuth.merchantId,
      hasAcceptedTerms: hasAcceptedTerms,
      hasAgreedNewsletter: hasAgreedNewsletterSubscribe
    };

    return GraphQL.mutate(USER_RESET_PASSWORD_COMPLETE_MUTATION, { args })
      .then(async result => {
        store.toasterStore.popSuccessToast('Password', 'create');

        this.handleLogin(result.data.resetPasswordComplete.token);
      })
      .catch(error => {
        if (error.graphQLErrors) {
          store.accountStore.setAccountError(error.graphQLErrors[0]);
        } else {
          store.accountStore.setAccountError(loginErrorMessage);
        }
      });
  };

  public updateSignup(): void {
    store.accountStore.setAccountSignupMode(!store.accountStore.signup);
  }

  public updateFamilyName(familyName: string): void {
    store.accountStore.setAccountFamilyName(familyName);
  }

  public updateGivenName(givenName: string): void {
    store.accountStore.setAccountGivenName(givenName);
  }

  public fetchMerchant = async (): Promise<ApolloQueryResult<{ merchant: Merchant }>> => {
    return GraphQL.query(MERCHANT_QUERY, { id: Auth.getUserMerchantId() || Auth.getMerchantIdCookie() }, 'no-cache');
  };

  public hasMerchant = (): boolean => !!Auth.getUserMerchantId() || !!Auth.getMerchantIdCookie();

  /**
   * Temporarily disabling subscription error routing
   * Until we can figure out how to do it correctly
   */
  public handleSessionRouting = (isLogin = false): void => {
    const isUserAMerchant = !!Auth.getUserMerchantId();
    const requiresOnboarding = this.shouldBeOnMerchantOnboarding();
    const hasIssueWithSubscription = !MerchantService.isActiveMerchantStage(store.merchantStore.merchant!);
    const queryParams = queryString.parse(window?.location?.search);
    const hasRedirectCode = !!queryParams.code && !Array.isArray(queryParams.code);

    switch (true) {
      case isUserAMerchant && requiresOnboarding && !hasRedirectCode:
        NavService.onboardingHome();

        break;

      case isLogin || (isUserAMerchant && hasIssueWithSubscription):
        NavService.overview();

        break;
    }
  };

  public isLocationOnboarding = (pathname: string = window?.location?.pathname): boolean => {
    const pathnames = [
      'stripe-connect',
      'get-paid-from-floom',
      'subscription/'
    ];

    return pathnames.some(path => pathname?.includes(path));
  };

  /**
   * @deprecated after 11 July 2020
   * @fix - Remove camelCase keys
   * @description @TODO This is the hottest of hotfixes, hot off the press.
   * It's because the format of our tracking keys were decided to be in snake_case
   * format, instead of camelCase format, though decision was made after
   * Identify was implemented, and clean up did not happen to change the current keys.
   * Now we don't want to prematurely delete them as it will impact our engagement data.
   * If you are a developer and you are looking at this after 11th July, 2020,
   * speak to a PM who will advise what to do to delete the old keys.
   * If you are Brad Locking, clean your stuff up.
   * Original Ticket: {@link https://app.clubhouse.io/floom/story/3743}
   * Ticket to remove the old keys {@link https://app.clubhouse.io/floom/story/3744}
   */
  private buildIdentifyValues = (): any => {
    if (store.userStore.user) {
      const identify = [
        {
          value: `${store.userStore.user.givenName} ${store.userStore.user.familyName}`,
          keys: ['name']
        },
        {
          value: store.userStore.user.email,
          keys: ['email']
        },
        {
          value: this.isInternal(store.userStore.user) ? 'Internal' : 'Merchant',
          keys: ['userType', 'user_type']
        },
        {
          value: store.userStore.userMerchant ? store.userStore.userMerchant.title : null,
          keys: ['merchant']
        },
        {
          value: store.userStore.userMerchant ? store.userStore.userMerchant.id : null,
          keys: ['merchantId', 'merchant_id']
        },
        {
          value: PermissionsService.getUserRole(),
          keys: ['userRole', 'user_role']
        },
        {
          value: store.userStore.user.shop ? store.userStore.user.shop.id : null,
          keys: ['shopId', 'shop_id']
        },
        {
          value: store.userStore.user.shop ? store.userStore.user.shop.title : null,
          keys: ['shopTitle', 'shop_title']
        }
      ];

      return identify
        .reduce((acc, curr) => {
          return {
            ...acc,
            ...curr.keys.reduce((keyAcc, currKey) => {
              if (curr.value === null) return {};

              return {
                ...keyAcc,
                [currKey]: curr.value
              };
            }, {})
          };
        }, {});
    }
  };

  public identifyUser = (): void => {
    if (store.userStore.user) {
      Analytics.identify(store.userStore.user.email, this.buildIdentifyValues());
    }
  };

  public getMerchantDetails = async (): Promise<any> => {
    if (this.hasMerchant() && Auth.isLoggedIn()) {
      try {
        const fetchMerchant = await this.fetchMerchant();

        store.userStore.setUserMerchant(fetchMerchant.data.merchant);
        store.merchantStore.setMerchant(fetchMerchant.data.merchant);
      } catch (error) {
        // TODO Sentry Log
        // console.log(error);
      }
    } else {
      store.merchantStore.disableIsLoadingMerchant();
    }
  };

  public shouldBeOnMerchantOnboarding = (): boolean => {
    const merchant = store.userStore.userMerchant!;

    switch (true) {
      case PermissionsService.isInternalRole():
        return false;

      case !MerchantService.hasSubscriptionId(merchant) && store.userStore.isTeamRole():
        return true;

      case MerchantService.hasPlanFeature(PlanFeature.Website, merchant) && !MerchantService.isStripeConnected(merchant):
        return true;

      case MerchantService.hasPlanFeature(PlanFeature.Floom, merchant) && !MerchantService.hasProvidedFloomBankDetails(merchant):
        return true;

      default:
        return false;
    }
  };

  public loginUserAccount = async (): Promise<any> => {
    GraphQL.mutate(USER_LOGIN_MUTATION, { args: this.buildLoginArgs() })
      .then(async result => {
        this.handleLogin(result.data.login.token);
      })
      .catch(error => {
        if (error.graphQLErrors) {
          store.accountStore.setAccountError(error.graphQLErrors[0]);
        } else {
          store.accountStore.setAccountError(loginErrorMessage);
        }
      });
  };

  private handleLogin = async (token: string): Promise<any> => {
    Auth.setLoginToken(token);
    await this.getMerchantDetails();
    await this.populateUserDetails(true);
    await this.configureUserSearchToken();
    this.populateStoresWithUserData();
    this.handleSessionRouting(true);
  };

  private populateStoresWithUserData(): void {
    store.accountStore.setAccountLoggedIn(true);
    store.accountStore.setAccountPassword();
    store.accountStore.setAccountEmail();

    store.userStore.init();
    store.userStore.setAccountMerchantId(Auth.getUserMerchantId());
    store.userStore.loadMerchantId();
    store.userStore.setAccountRole(PermissionsService.getUserRole());
    store.userStore.setAccountShopId(Auth.getUserShopId());

    this.identifyUser();
  }

  public isInternal = (user: User): boolean => {
    return user.role === UserRole.CustomerService || user.role === UserRole.SuperAdmin;
  };

  public async populateUserDetails(isFromLogin: boolean = false): Promise<any> {
    if (Auth.isLoggedIn()) {
      await GraphQL.query(USER_DETAILS_QUERY({ merchantId: Auth.getUserMerchantId() }), {
        id: Auth.getUserID(),
        merchantId: Auth.getUserMerchantId()
      }, 'no-cache')
        .then(({ data: { user } }) => {
          store.userStore.setUser(user);
          store.userStore.setAccountNames(user.givenName, user.familyName);
          store.userStore.setAccountEmail(user.email);
          store.userStore.setAccountUserId(user.id);

          const merchantId = user?.merchant?.id || '';
          const merchantTitle = user?.merchant?.title || '';

          Sentry.configureScope(scope => {
            scope.setUser({
              id: user.id,
              username: user.email,
              name: `${user.givenName} ${user.familyName}`,
              email: user.email,
              role: `${user.role}`,
              merchant_id: merchantId,
              merchant_title: merchantTitle
            });
          });

          if (isFromLogin) {
            const isInternal = this.isInternal(user);

            if (!isInternal && !!merchantId && !!merchantTitle) {
              Analytics.group(merchantId, {
                name: merchantTitle
              });
            }

            Analytics.track(Analytics.FxEventName.SignedIn, {
              event_version: 1,
              username: user.email,
              context: {
                group_id: Auth.getUserMerchantId() || ''
              },
              merchant_title: merchantTitle,
              user_type: isInternal ? 'Internal' : 'Merchant',
              role: user.role
            });
          }
        });
    }
  }

  private buildLoginArgs(): UserLoginInput {
    return {
      email: store.accountStore.email,
      password: store.accountStore.password
    };
  }
}

import { ChangeEvent, FC, useEffect, useState } from 'react';

import { NavbarElementProps } from 'react-day-picker/types/Props';
import { Flex } from 'rebass';

import Icon from 'components/icon';

import {
  Select,
  SelectArrow,
  SelectWrap
} from './date-picker-navigation.styles';

interface YearMonthFormProps {
  date: Date;
  localeUtils: NavbarElementProps['localeUtils'];
  onChange: (month: Date) => void;
}

export const YearMonthForm: FC<YearMonthFormProps> = ({ date, localeUtils, onChange }) => {
  const [years, setYears] = useState<number[]>([new Date().getFullYear()]);
  const [months] = useState<string[]>(localeUtils.getMonths());

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const fromMonth = new Date(currentYear, 0);
    const toMonth = new Date(currentYear + 10, 11);
    const allYears: number[] = [];

    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      allYears.push(i);
    }

    setYears(allYears);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { year, month } = e.target.form!;

    onChange(new Date(year.value, month.value));
  };

  return (
    <form>
      <Flex>
        <SelectWrap>
          <Select
            name="month"
            id="date-picker-select-month"
            onChange={handleChange}
            value={date.getMonth()}
          >
            {
              months.map((month, i) => (
                <option
                  key={month}
                  value={i}
                >
                  {month}
                </option>
              ))
            }
          </Select>
          <SelectArrow>
            <Icon
              iconName="arrow-down"
              size="small"
            />
          </SelectArrow>
        </SelectWrap>
        <SelectWrap>
          <Select
            name="year"
            onChange={handleChange}
            value={date.getFullYear()}
          >
            {
              years.map(year => (
                <option
                  key={year}
                  value={year}
                >
                  {year}
                </option>
              ))
            }
          </Select>
          <SelectArrow>
            <Icon
              iconName="arrow-down"
              size="small"
            />
          </SelectArrow>
        </SelectWrap>
      </Flex>
    </form>
  );
};

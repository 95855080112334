import { createContext } from 'react';

import { PaymentConfig } from 'features/wholesale/helpers/wholesale-helpers/wholesale-helpers';

export interface SingleOrderContextProps {
  grandTotalAsFloat: number;
  grandTotalCurrencyFormatted: string;
  paymentStatusConfig: PaymentConfig | null;
  fetchOrder?: () => Promise<void>;
}

/**
 * Instantiate context to share computed data across abstracted sub components
 * of single order view.
 */
export const SingleOrderContext = createContext<SingleOrderContextProps>({
  grandTotalAsFloat: 0,
  grandTotalCurrencyFormatted: '',
  paymentStatusConfig: null
});

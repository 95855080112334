import React, { Component } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { PlanFeature } from 'generated-types.d';

import { MerchantService, NavService } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';

import { Container } from 'utils/css-mixins';
import { textStyles } from 'utils/rebass-theme';

import Button from 'components/button';
import Icon from 'components/icon';

import OnboardingWrapper from '../../components/onboarding-wrapper';

import * as Styles from './onboarding-completion.styles';

interface OnboardingCompletionProps {
  merchantStore?: MerchantStore;
}

class OnboardingCompletion extends Component<OnboardingCompletionProps> {
  private CONTENT = {
    heading: 'Amazing!',
    subHeading: `
      Now all you have left to do is get some products up for customers to order.
      Let's explore your product dashboard now.
    `
  };

  private onNavigate = (): void => {
    const merchant = this.props.merchantStore!.merchant;

    switch (true) {
      case MerchantService.hasPlanFeature(PlanFeature.Products, merchant):
        NavService.productList();

        break;

      default:
        NavService.overview();

        break;
    }

    NavService.productList();
  };

  render(): JSX.Element {
    return(
      <section>
        <Container>
          <OnboardingWrapper my="auto">
            <Styles.OnboardingCompletion>
              <Box mt="50px">
                <Icon iconName="onboarding-thumb" />
              </Box>
              <Box mt="30px">
                <Styles.Heading
                  as="h1"
                  mb="20px"
                  mt="30px"
                  color="floomMidnightBlue"
                  css={textStyles.h1}
                >
                  {this.CONTENT.heading}
                </Styles.Heading>
                <Styles.Heading
                  as="h2"
                  mb="40px"
                  color="floomMidnightBlue"
                  css={css`
                    ${textStyles.body};
                    font-size: 18px;
                  `}
                >
                  {this.CONTENT.subHeading}
                </Styles.Heading>
                <Styles.ButtonContinue onClick={this.onNavigate}>
                  <Button
                    size="normal"
                    copy="Enter FloomX 🌼"
                    appearance="primary"
                  />
                </Styles.ButtonContinue>
              </Box>
            </Styles.OnboardingCompletion>
          </OnboardingWrapper>
        </Container>
      </section>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore
}))(observer(OnboardingCompletion));

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box } from 'rebass';

import { breakpoints, colors, fontSizes, space } from 'utils/rebass-theme';

const borderColour = hex2rgba(colors.middleGrey, 0.5);

export const Header = styled(Box)`
  display: flex;
  z-index: 1;
  box-sizing: content-box;
  position: sticky;
  top: 0;
  height: 2.4em;
  margin: 0 ${space[3]}px;
  padding: ${space[2]}px 0 9px;
  border-bottom: 1px solid ${borderColour};
  background: ${colors.uiFills};
  align-items: center;
  
  @media (max-width: ${breakpoints.large}) {
    justify-content: space-between;
    margin: 0 ${space[3]}px;
    padding: ${space[2]}px 0;
  }
`;

export const ProfileWrapper = styled(Box)`
  font-weight: 700;
  font-size: ${fontSizes.small}px;
  color: ${colors.black};
  display: flex;
  align-items: center;
  margin: 0 ${space[3]}px;
  flex-grow: 1;
`;

export const BackButton = css`
  display: none;

  @media (max-width: ${breakpoints.large}) {
    display: block;
    margin: 0;
  }
`;

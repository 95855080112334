import { SupportedISOString } from 'global-types';

import { LocaleService } from 'lib';

export const CURRENCY_SYMBOLS: { [key in SupportedISOString]: string } = {
  USD: '$',
  GBP: '£'
};

const mapLangs = (userLang: string): string => {
  switch (userLang) {
    case 'en-GB':
      return 'en-US';

    default:
      return userLang;
  }
};

const formatPrice = (value: number, ISOCode: string, lang = LocaleService.getUserLanguage()): string => {
  try {
    return Number(value || 0)
      .toLocaleString(mapLangs(lang), {
        style: !!ISOCode ? 'currency' : 'decimal',
        currency: ISOCode || undefined,
        useGrouping: true,
        minimumFractionDigits: 2
      });
  } catch {
    return value.toString();
  }
};

const renderCurrencySymbol = (ISOCode: SupportedISOString): string => {
  try {
    return new Intl.NumberFormat('en', {
      style: 'currency',
      currency: ISOCode
    }).formatToParts(0).find(x => x.type === 'currency')?.value || '';
  } catch (error) {
    return '';
  }
};

const calculateSalesTax = (value: number, percentage = 20): number => {
  return (value / 100) * percentage;
};

const parseCurrencyString = (value: string): number => Math.round(parseFloat(value) * 100) / 100;

export {
  calculateSalesTax,
  formatPrice,
  renderCurrencySymbol,
  parseCurrencyString
};

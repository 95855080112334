import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, fontSizes } from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  height: 100%;
`;

export const MessagesElement = styled(Box)`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  overflow: auto;
`;

export const DateSeparator = styled(Box)`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${colors.middleGrey};
  width: auto;
  font-size: ${fontSizes.xSmall}px;

  :after, :before {
    content: "";
    position: relative;
    border-bottom: 1px solid ${colors.paleGrey};
    flex-grow: 1;
    margin: 15px;
  };
`;

import { FC } from 'react';

import { TEST_IDS } from 'utils/test/data-test-ids';

import { RADIO_ITEM_OPTIONS } from './radio-item.config';
import { StyledRadioItem, HiddenRadio } from './radio-item.styles';
import  { RadioItemControllerProps } from './radio-item.types';

export const RadioItem: FC<RadioItemControllerProps> = ({
  radioType = 'default',
  totalItems,
  item,
  onChange,
  itemValueField,
  itemTitleField,
  itemSubtitleField,
  orientation,
  isDisabled,
  selectedItem,
  slug
}) => {
  const RadioItemView = RADIO_ITEM_OPTIONS[radioType];

  return (
    <StyledRadioItem
      data-testid={`${TEST_IDS.RadioList.item}${item.value}` || ''}
      as="label"
      type={radioType}
      totalItems={totalItems}
      key={item[itemValueField]}
      alignItems="flex-start"
      orientation={orientation}
      isDisabled={isDisabled}
      isSelected={selectedItem === item[itemValueField]}
    >
      <input
        css={HiddenRadio}
        type="checkbox"
        name={slug}
        checked={selectedItem === item[itemValueField]}
        onChange={(event): void => onChange(event, item)}
      />
      <RadioItemView
        isSelected={selectedItem === item[itemValueField]}
        isDisabled={item.isDisabled}
        title={item[itemTitleField]}
        subTitle={item?.[itemSubtitleField || ''] || ''}
        orientation={orientation}
        itemsCount={totalItems}
      />
    </StyledRadioItem>
  );
};

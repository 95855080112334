import { action, observable, makeObservable } from 'mobx';
import { TestMessageParams } from 'yup';

import { Merchant } from 'generated-types.d';

import { AccountStoreTypes, Team, ResetPasswordAuth } from './account-store.types';

export type AccountStoreActions =
  | 'email'
  | 'secondaryEmail'
  | 'phone'
  | 'secondaryPhone'
  | 'jobPosition'
  | 'password'
  | 'givenName'
  | 'familyName'
  | 'error'
  | 'signup'
  | 'loggedIn'
  | 'merchant';

export default class AccountStore implements AccountStoreTypes {
  constructor() {
    makeObservable(this, {
      email: observable,
      familyName: observable,
      givenName: observable,
      password: observable,
      signup: observable,
      error: observable,
      loggedIn: observable,
      resetEmailSent: observable,
      newPassword: observable,
      confirmNewPassword: observable,
      team: observable,
      resetPasswordAuth: observable,
      secondaryPhone: observable,
      phone: observable,
      jobPosition: observable,
      secondaryEmail: observable,
      merchant: observable,
      validationErrors: observable,
      setAccountEmail: action,
      setAccountPassword: action,
      setAccountFamilyName: action,
      setAccountGivenName: action,
      setAccountSignupMode: action,
      setAccountError: action,
      setAccountLoggedIn: action,
      setAccountTeam: action,
      setAccountSecondaryEmail: action,
      setAccountJobPosition: action,
      setAccountPhone: action,
      setAccountSecondaryPhone: action,
      setAccountMerchant: action,
      getAccountStoreSnapshot: action,
      updateBasicValue: action,
      clearAccountStore: action,
      setValidationErrors: action
    });
  }

  email: string = '';

  secondaryEmail: string = '';

  phone: string = '';

  secondaryPhone: string = '';

  jobPosition: string = '';

  password: string = '';

  givenName: string = '';

  familyName: string = '';

  error: string = '';

  validationErrors: TestMessageParams[] = [];

  signup: boolean = false;

  loggedIn: boolean = false;

  merchant: Partial<Merchant> | null = null;

  resetEmailSent: boolean = false;

  newPassword: string = '';

  confirmNewPassword: string = '';

  team: Team = {
    id: '',
    title: ''
  };

  resetPasswordAuth: ResetPasswordAuth = { a: '', i: '' };

  public updateBasicValue = (key: keyof AccountStore, value: any): void => {
    // @ts-ignore
    this[key] = value;
  };

  public toggleConfirmSentNotification = (sent: boolean): void => {
    this.resetEmailSent = sent;
  };

  public setAccountEmail = (email: string = ''): void => {
    this.email = email;
  };

  public setAccountSecondaryEmail = (email: string = ''): void => {
    this.secondaryEmail = email;
  };

  public setAccountPhone = (phone: string = ''): void => {
    this.phone = phone;
  };

  public setAccountSecondaryPhone = (phone: string = ''): void => {
    this.secondaryPhone = phone;
  };

  public setAccountJobPosition = (jobPosition: string = ''): void => {
    this.jobPosition = jobPosition;
  };

  public setAccountPassword = (password: string = ''): void => {
    this.password = password;
  };

  public setAccountFamilyName = (familyName: string = ''): void => {
    this.familyName = familyName;
  };

  public setAccountGivenName = (givenName: string = ''): void => {
    this.givenName = givenName;
  };

  public setAccountMerchant = (merchant: Partial<Merchant> | null = null): void => {
    this.merchant = merchant;
  };

  public setAccountSignupMode = (signup: boolean = false): void => {
    this.signup = signup;
  };

  public setAccountError = (error: string = ''): void => {
    this.error = error;
  };

  public setAccountLoggedIn = (loggedIn: boolean = false): void => {
    this.loggedIn = loggedIn;
  };

  public setAccountTeam = (id: string, title: string): void => {
    this.team = { id, title };
  };

  public setResetPasswordAuth = (auth: ResetPasswordAuth): void => {
    this.resetPasswordAuth = auth;
  };

  public getAccountStoreSnapshot = (): string => {
    let result = '';

    for (const key of Object.keys(this)) {
      if (!!this[key]) {
        result += this[key].toString();
      }
    }

    return result;
  };

  public setValidationErrors = (errors: TestMessageParams[] = []): void => {
    this.validationErrors = errors;
  };

  public resetErrorForField = (key: keyof AccountStore): void => {
    this.validationErrors = this.validationErrors?.filter(error => error.path !== key);
  };

  public clearAccountStore = (): void => {
    this.email = '';
    this.secondaryEmail = '';
    this.phone = '';
    this.secondaryPhone = '';
    this.jobPosition = '';
    this.password = '';
    this.givenName = '';
    this.familyName = '';
    this.error = '';
    this.signup = false;
    this.loggedIn = false;
    this.merchant = null;
    this.resetEmailSent = false;
    this.team = { id: '', title: '' };
    this.resetPasswordAuth = { a: '', i: '' };
    this.validationErrors = [];
  };
}

import { FC, useEffect } from 'react';

import { HelmetDatoCms } from 'gatsby-source-datocms';
import Helmet from 'react-helmet';

import { Auth, NavService } from 'lib';

import { StandardPage } from 'features/marketing/components/standard-page/standard-page';

import { MarketingLayout } from 'components/global-layout';

import { HomePageProps } from './home-page.types';

export const HomePage: FC<HomePageProps> = props => {
  useEffect(() => {
    if (Auth.isLoggedIn()) {
      NavService.overview();
    }
  }, []);

  return (
    <MarketingLayout>
      <HelmetDatoCms seo={props.pageProps.data.datoCmsPage?.seoMetaTags} />
      <Helmet titleTemplate="%s">
        <title>FloomX</title>
      </Helmet>
      <StandardPage
        data={props.pageProps.data.datoCmsPage}
      />
    </MarketingLayout>
  );
};

import { FC } from 'react';

import { SerializedStyles } from '@emotion/react';

import { OmitType } from 'global-types';

import { TEST_IDS } from 'utils/test/data-test-ids';

import Icon from 'components/icon';
import * as Types from 'components/icon/icon.types';

import * as Styles from './icon-button.styles';

interface IconButtonProps extends OmitType<Types.IconProps, 'pathFill'> {
  onClick: (event?: React.MouseEvent<Element> | React.TouchEvent<Element>) => void;
  wrapperStyles?: SerializedStyles;
  testId?: string;
}

const IconButton: FC<IconButtonProps> = ({
  iconName,
  onClick,
  size,
  styles,
  wrapperStyles,
  testId
}) => {
  return (
    <Styles.IconButton
      onClick={onClick}
      role="button"
      styles={wrapperStyles}
      data-testid={testId || TEST_IDS.IconButton.Wrapper}
    >
      <Icon
        size={size}
        iconName={iconName}
        styles={styles}
      />
    </Styles.IconButton>
  );
};

export default IconButton;

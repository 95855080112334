import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { PaymentEdge, Maybe, PaymentOrderByInput } from 'generated-types.d';

import { TimeService, CurrencyService, PermissionsService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import EntityList from 'components/entity-list';
import { EntityListColumn, EntityListRow, EntityListItems } from 'components/entity-list/entity-list.styles';
import { EntityListConfigWithCallbacks } from 'components/entity-list/entity-list.types';
import LoadMoreButton from 'components/load-more-button';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading/with-loading';

import { PAYMENT_TYPES } from '../../payments.constants';
import PaymentSettingService from '../../services';

import * as Constants from './payments-list.constants';
import * as Types from './payments-list.types';

class PaymentsList extends Component<Types.PaymentsListProps> {
  getCount = (): number => {
    return this.props.paymentsStore!.merchantPayments! ? this.props.paymentsStore!.merchantPayments!.aggregate.count : 0;
  };

  loadMorePayments = (): void => {
    PaymentSettingService.paginatePayments();
  };

  fetchPayments = (): void => {
    this.props.paymentsStore!.clearPaymentsStore();
    PaymentSettingService.fetchMerchantPayments(true);
  };

  onSortPayments = (sort: PaymentOrderByInput): void => {
    this.props.paymentsStore!.setMerchantPaymentsSort(sort);
    this.fetchPayments();
  };

  onSearch = (value: string): void => {
    this.props.paymentsStore!.setMerchantPaymentSearch(value);
    this.fetchPayments();
  };

  onSearchClear = (): void => {
    this.props.paymentsStore!.setMerchantPaymentSearch('');
    this.fetchPayments();
  };

  entityListConfig: EntityListConfigWithCallbacks = {
    ...Constants.ENTITY_LIST_CONFIG,
    onSort: this.onSortPayments,
    onSearch: this.onSearch,
    onChange: this.onSearch,
    onSearchClear: this.onSearchClear
  };

  renderItem = (edge: Maybe<PaymentEdge>): ReactNode | null => {
    if (!edge) return null;

    return (
      <EntityListRow key={edge.node.id}>
        <EntityListColumn>
          <Flex alignItems="center">
            <Box
              css={textStyles.subhead}
              width="13%"
            >
              {PAYMENT_TYPES[edge.node.type]}
            </Box>
            <Box
              css={textStyles.body}
              width="15%"
            >
              {TimeService.dateMonthYear(edge.node.updatedAt)}
            </Box>
            {/* TODO: change ID to invoice / courier booking etc ID's */}
            <Box
              css={textStyles.body}
              width="15%"
            >
              ID: {edge.node.id.slice(0, 7)}
            </Box>
            <Box
              css={textStyles.body}
              width="15%"
            >
              {CurrencyService.formatPrice(edge.node.amount!, edge.node.currency!)} {(edge.node.type === PAYMENT_TYPES.Courier) ? '(inc. VAT)' : ''}
            </Box>
            {/* TODO: Handle Invoices when the API has it */}
            {/* <Box
              css={textStyles.body}
              alignSelf="flex-end"
              ml="auto"
            >
              <StyledLink>
                View PDF
              </StyledLink>
            </Box> */}
          </Flex>
        </EntityListColumn>
      </EntityListRow>
    );
  };

  renderItems = (): ReactNode => {
    if (!this.props.paymentsStore!.merchantPayments!) return null;

    return (
      <EntityListItems>
        {this.props.paymentsStore!.merchantPayments!.edges.map(this.renderItem)}
      </EntityListItems>
    );
  };

  renderNoResultsCopy = (): string => {
    return `${PermissionsService.isInternalRole() ? 'This merchant has' : 'You have'} not got any payments to display`;
  };

  hasNoResults = (): boolean => {
    return !this.props.paymentsStore!.isLoadingMerchantPayments
      && !!this.props.paymentsStore!.merchantPayments
      && !this.props.paymentsStore!.merchantPayments!.edges!.length;
  };

  isLoadingMerchant = (): boolean => {
    return this.props.merchantStore!.isLoadingMerchant;
  };

  hasMerchant = (): boolean => {
    return !!this.props.merchantStore!.merchant
      && !!this.props.merchantStore!.merchant.id;
  };

  showPayments = (): boolean => {
    return this.hasMerchant() && !this.isLoadingMerchant();
  };

  showNoResults = (): boolean => {
    return !this.hasMerchant() && !this.isLoadingMerchant();
  };

  render(): JSX.Element {
    return (
      <EntityList
        showHeading={true}
        heading="Payment History"
        headingCount={this.getCount()}
        searchValue={this.props.paymentsStore!.merchantPaymentsSearch}
        config={this.entityListConfig}
        showEntities={this.showPayments()}
        showNoResults={this.showNoResults()}
      >
        <WithLoading
          hasNoResults={this.hasNoResults()}
          isLoading={this.props.paymentsStore!.isLoadingMerchantPayments}
          renderNoResults={(): ReactNode => (
            <NoResultsGeneric
              icon="backpack"
              heading="No payments"
              copy={this.renderNoResultsCopy()}
            />
          )}
        >
          {this.renderItems()}
          { this.props.paymentsStore!.merchantPayments && (
            <LoadMoreButton
              shouldDisplay={this.props.paymentsStore!.merchantPayments!.pageInfo.hasNextPage}
              onLoadMore={this.loadMorePayments}
              isLoading={this.props.paymentsStore!.isPaginating}
            />
          )}
        </WithLoading>
      </EntityList>
    );
  }
}

export default inject('paymentsStore', 'merchantStore')(observer(PaymentsList));

import { COUNTRIES_WITH_STATES, COUNTRIES } from 'utils/metadata/countries-states';

import {
  OrderFormField,
  OrderFormModalConfig
} from 'features/orders/orders.types';
import { OrderEditService } from 'features/orders/services';

const getStates = (countryCode: string): any => {
  return (countryCode && ['US', 'AU', 'CA'].includes(countryCode))
    ? COUNTRIES_WITH_STATES[countryCode.toUpperCase()]
    : [];
};

const addressFields: OrderFormField[] = [
  {
    fieldId: 'businessName',
    fieldType: 'text',
    placeholder: 'Business Name',
    inputType: 'text',
    isSpellCheckEnabled: false
  },
  {
    fieldId: 'recipientFullName',
    fieldType: 'text',
    placeholder: 'Full Name',
    inputType: 'text',
    isSpellCheckEnabled: false
  },
  {
    fieldId: 'phone',
    fieldType: 'text',
    placeholder: 'Phone Number',
    inputType: 'tel',
    isSpellCheckEnabled: false
  },
  {
    fieldId: 'address1',
    fieldType: 'text',
    placeholder: 'Address line 1',
    inputType: 'text',
    isSpellCheckEnabled: false
  },
  {
    fieldId: 'address2',
    fieldType: 'text',
    placeholder: 'Address line 2',
    inputType: 'text',
    isSpellCheckEnabled: false
  },
  {
    fieldId: 'city',
    fieldType: 'text',
    placeholder: 'City',
    inputType: 'text',
    isSpellCheckEnabled: false
  },
  {
    fieldId: 'country',
    fieldType: 'select',
    titleField: 'name',
    valueField: 'code',
    formatValue: (value: string): string => value ? value.toUpperCase() : '',
    placeholder: 'Select a country',
    options: [...COUNTRIES]
  },
  {
    fieldId: 'region',
    fieldType: 'region',
    titleField: 'name',
    valueField: 'name',
    formatValue: (country: any, value: string): string => {
      const state = getStates(country.value).find((currState: any): any => value === currState.name);

      return state ? state.name : '';
    },
    shouldDisplay: (fields: any): boolean => !!getStates(fields.country.value).length,
    options: (country: any): any => getStates(country.value)
  },
  {
    fieldId: 'postalCode',
    fieldType: 'text',
    placeholder: 'Postcode',
    inputType: 'text',
    isSpellCheckEnabled: false
  }
];

export const MODAL_CONFIG: OrderFormModalConfig = {
  giftMessage: {
    title: 'Gift Message',
    fields: [
      {
        fieldId: 'giftMessage',
        fieldType: 'textarea',
        label: 'Message',
        placeholder: 'Enter a gift message'
      }
    ],
    buttonText: 'Save changes',
    action: OrderEditService.updateGiftMessage
  },
  deliveryDate: {
    title: 'Edit delivery date',
    fields: [
      {
        fieldId: 'deliverAt',
        fieldType: 'datepicker',
        placeholder: '',
        label: 'Delivery date'
      }
    ],
    buttonText: 'Update',
    action: OrderEditService.updateDeliveryDate
  },
  failedDelivery: {
    title: 'Failed reason',
    fields: [
      {
        fieldId: 'deliveryFailedReason',
        fieldType: 'textarea',
        label: 'Why did the delivery fail?',
        placeholder: 'Copy'
      }
    ],
    buttonText: 'Save note',
    action: (): boolean => false
  },
  deliveryInstructions: {
    title: 'Instructions',
    fields: [
      {
        fieldId: 'deliveryInstructions',
        fieldType: 'textarea',
        placeholder: 'Enter delivery instruction'
      }
    ],
    buttonText: 'Save changes',
    action: OrderEditService.updateDeliveryInstruction
  },
  deliverySafePlace: {
    title: 'If nobody\'s home',
    fields: [
      {
        fieldId: 'deliverySafePlace',
        fieldType: 'select',
        titleField: 'title',
        valueField: 'title',
        formatValue: (value: string): string => value,
        placeholder: 'Select an option',
        options: [
          {
            title: 'Leave with a neighbour'
          },
          {
            title: 'Leave in a safe place'
          },
          {
            title: 'Leave at reception'
          },
          {
            title: 'Leave with doorman'
          }
        ]
      }
    ],
    buttonText: 'Save changes',
    action: OrderEditService.updateDeliverySafePlace
  },
  billingAddress: {
    title: 'Billing Address',
    fields: addressFields,
    buttonText: 'Save changes',
    action: OrderEditService.updateBillingAddress
  },
  deliveryAddress: {
    title: 'Delivery Address',
    fields: addressFields,
    buttonText: 'Save changes',
    action: OrderEditService.updateDeliveryAddress
  },
  senderDetails: {
    title: 'Update sender details',
    buttonText: 'Save changes',
    fields: [
      {
        fieldId: 'givenName',
        fieldType: 'text',
        placeholder: 'First name'
      },
      {
        fieldId: 'familyName',
        fieldType: 'text',
        placeholder: 'Last name'
      },
      {
        fieldId: 'phone',
        fieldType: 'text',
        placeholder: 'Phone number'
      },
      {
        fieldId: 'email',
        fieldType: 'text',
        inputType: 'email',
        placeholder: 'Email address'
      }
    ],
    action: OrderEditService.updateSenderDetails
  }
};

import { FC } from 'react';

import { inject, observer } from 'mobx-react';
import { InjectedMobxStores } from 'stores';

import { Currency } from 'generated-types.d';

import { CurrencyService } from 'lib';

import Button from 'components/button';

import { TotalButton } from './checkout-list-item-total-button.styles';

export type CheckoutListTotalButtonProps = {
  onClick: () => void;
} & Pick<InjectedMobxStores,
| 'promotedListsStore'
| 'merchantStore'
>

export const CheckoutListTotalButton: FC<CheckoutListTotalButtonProps> = inject((stores: FxStores): InjectedFxStores => ({
  promotedListsStore: stores.promotedListsStore,
  merchantStore: stores.merchantStore
}))(observer(({
  promotedListsStore,
  merchantStore,
  onClick
}) => {
  const buttonCopy = `Total: ${CurrencyService.formatPrice(promotedListsStore!.grandTotal, merchantStore?.merchant?.currency || Currency.Usd)}`;

  return (
    <TotalButton
      as="button"
      onClick={onClick}
    >
      <Button
        copy={buttonCopy}
        size="xsmall"
      />
    </TotalButton>
  );
}));

import {
  action,
  observable,
  makeObservable
} from 'mobx';

import { ThemeBreakpointOption } from 'utils/rebass-theme/rebass-theme.types';

export default class UIStore {
  isMenuOpen: boolean = false;

  isAccountModalOpen: boolean = false;

  currentBreakpoint: ThemeBreakpointOption | '' = '';

  public toggleMenu = (): void => {
    this.isMenuOpen = !this.isMenuOpen;
  };

  public toggleAccountModal = (): void => {
    this.isAccountModalOpen = !this.isAccountModalOpen;
  };

  public setBreakpoint = (breakpoint: ThemeBreakpointOption): void => {
    this.currentBreakpoint = breakpoint;
  };

  constructor() {
    makeObservable(this, {
      isMenuOpen: observable,
      isAccountModalOpen: observable,
      setBreakpoint: observable,
      currentBreakpoint: observable,
      toggleMenu: action,
      toggleAccountModal: action
    });
  }
}

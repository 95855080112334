import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { Card } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const SelectedWrapper = styled(Flex)`
  justify-content: flex-start;
  flex-flow: row wrap;
  text-align: center;
`;

export const SelectedItem = styled(Flex)`
  ${Card};
  margin: 8px;
  width: 170px;
  height: 230px;
  position: relative;
  flex-flow: column wrap;
  margin-bottom: 20px;
`;

export const SelectNewItem = styled(Flex)`
  align-self: left;
  ${textStyles.subhead};
  margin: 8px;
  width: 170px;
  height: 230px;
  border: 2px dashed ${colors.middleGrey};
  border-radius: 3px;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  > p {
    margin-bottom: 3px;
    margin-left: 8px;
    margin-top: 10px;
  }
`;

export const SelectedItemMedia = css`
  height: 170px;
  width: 170px;
  opacity: 0.9
`;

export const MediaWrapper = styled.div`
  height: 170px;
  width: 170px;
  background: #C4C4C4;
`;

export const SelectedItemTitle = styled.div`
  ${textStyles.subhead};
  margin-top: 7px;
`;

export const SelectedItemDetails = styled.div`
  ${textStyles.subhead};
  font-weight: 400;
`;

export const SelectedItemDeleteCross: any = styled.div`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
`;

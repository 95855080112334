import store from 'stores';

import {
  List,
  ListUpdateInput,
  ListWhereUniqueInput,
  WholesalePreOrder,
  WholesalePreOrderCreateInput,
  WholesalePreOrderDeliveryDate
} from 'generated-types.d';

import { GraphQL } from 'lib';

import {
  UPDATE_LIST,
  UPDATE_LIST_ITEM
} from 'features/lists/graphql/mutations/list.mutators';
import {
  LIST_DELIVERY_DATES,
  SINGLE_LIST
} from 'features/lists/graphql/queries/list.queries';
import {
  CREATE_WHOLESALE_PREORDER
} from 'features/wholesale/graphql/mutators/pre-order/pre-order';

export class CheckoutListStoreService {
  public static fetchList = async (id: string): Promise<List> => {
    const where: ListWhereUniqueInput =  { id };

    if (!!store.merchantStore?.merchant) {
      where.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<{ list: List }>(SINGLE_LIST, { where });

      return result.data.list;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static getDeliveryDates = async (id: string): Promise<WholesalePreOrderDeliveryDate[]> => {
    const where: ListWhereUniqueInput = { id };

    if (!!store.merchantStore?.merchant) {
      where.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<{ wholesalePreOrderDeliveryDates: WholesalePreOrderDeliveryDate[] }>(LIST_DELIVERY_DATES, { where });

      return result.data.wholesalePreOrderDeliveryDates;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static updateList = async ({ id, data } : { id: string; data: ListUpdateInput }): Promise<List> => {
    const where: ListWhereUniqueInput =  { id };

    if (!!store.merchantStore?.merchant) {
      where.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<{ updateList: List }>(UPDATE_LIST, { where, data });

      return result.data.updateList;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static updateListItem = async ({ listId, data, listItemId }: {
    listId: string;
    listItemId: string;
    data: ListUpdateInput;
  }): Promise<Pick<List, 'items'>> => {
    const where: ListWhereUniqueInput = {
      id: listId
    };

    if (!!store.merchantStore?.merchant) {
      where.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<{ updateList: Pick<List, 'items'> }>(UPDATE_LIST_ITEM, { where, data, listItemId });

      return result.data.updateList;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static createWholesalePreOrder = async (data: WholesalePreOrderCreateInput): Promise<Pick<WholesalePreOrder, 'id'>> => {
    try {
      const result = await GraphQL.query<{ createWholesalePreOrder: Pick<WholesalePreOrder, 'id'> }>(CREATE_WHOLESALE_PREORDER, { data });

      return result.data?.createWholesalePreOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

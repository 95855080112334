import { FC, useState, MouseEvent, useContext, Fragment, ReactNode } from 'react';

import styled from '@emotion/styled';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  ListItem,
  WholesalePreOrderItem
} from 'generated-types.d';

import SelectedWholesalePreOrderStore from 'stores/selected-wholesale-pre-order/selected-wholesale-pre-order-store';

import { breakpoints, colors, space } from 'utils/rebass-theme';

import { ConfirmDeleteTooltip } from 'features/lists/components/confirm-delete-tooltip/confirm-delete-tooltip';
import { getMedia, listItemMediaImage } from 'features/lists/lists.helpers';
import {
  Columns,
  ItemDetails,
  ItemTitle,
  ListItemWrapper,
  MetaColumn,
  Thumbnail
} from 'features/lists/lists.styles';
import { ListFieldConfig, ListLayoutType } from 'features/lists/lists.types';

import Icon from 'components/icon';

import { SINGLE_PRE_ORDER_LIST_FIELD_CONFIG } from '../single-pre-order.config';
import { SingleWholesalePreOrderContext } from '../single-pre-order.context';

export const DeleteWrapper = styled(Flex)`
  border-radius: 30px;
  padding: ${space[2]}px;
  transition: all .2s ease-in-out;
  position: absolute;
  right: 0;

  @media only screen and (max-width: ${breakpoints.large}) {
    bottom: ${space[3]}px;
  }

  @media only screen and (max-width: ${breakpoints.medium}) {
    bottom: 48px;
  }

  &:hover, &:focus {
    background: ${colors.paleGrey};
  }
`;

export const SinglePreOrderItem: FC<{
  item: WholesalePreOrderItem;
  selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
  listType: ListLayoutType;
}> = inject((stores: FxStores) => ({
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
}))(observer(({
  item,
  selectedWholesalePreOrderStore,
  listType
}) => {
  const { isPreOrderEditable } = useContext(SingleWholesalePreOrderContext);

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const totals = selectedWholesalePreOrderStore!.preOrder?.totals;

  if (!selectedWholesalePreOrderStore!.preOrder || !totals) return null;

  const fieldConfig = SINGLE_PRE_ORDER_LIST_FIELD_CONFIG[listType];

  if (!fieldConfig) return null;

  const handleClick = (event: MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteItem = async (id: string): Promise<void> => {
    await selectedWholesalePreOrderStore!.deletePreOrderItem({
      preOrderId: selectedWholesalePreOrderStore!.preOrder!.id,
      preOrderItemId: id
    });
    await selectedWholesalePreOrderStore!.fetchPreOrderTotals({ preOrderId: selectedWholesalePreOrderStore!.preOrder!.id });
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  const shouldDisplayColumn = (
    field: ListFieldConfig<WholesalePreOrderItem>,
    listItem?: ListItem
  ): boolean => {
    return !field.supportedTypes?.length || (!!listItem?.type && !!field.supportedTypes.includes(listItem.type));
  };

  const renderRemove = (): ReactNode => {
    if (!isPreOrderEditable) return null;

    return (
      <Fragment>
        <DeleteWrapper onClick={handleClick}>
          <Icon
            iconName="bin"
            styles={{
              color: colors.floomMidnightBlue
            }}
          />
        </DeleteWrapper>
        <ConfirmDeleteTooltip
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          onDeleteItem={() => handleDeleteItem(item.id)}
        />
      </Fragment>
    );
  };

  return (
    <ListItemWrapper key={item.id}>
      <Thumbnail
        imageUrl={listItemMediaImage(getMedia(item?.listItem), true)}
      >
        {item?.listItem.type === 'Flower' && (
          <Icon iconName="flower-squiggle" />
        )}
      </Thumbnail>
      <Columns>
        <ItemDetails>
          <ItemTitle>
            <Box flex="1">
              {fieldConfig.titleField.renderValue?.(item)}
            </Box>
          </ItemTitle>
          {fieldConfig.fields?.map((field, fieldIndex) => {
            if (!shouldDisplayColumn(field, item?.listItem)) return null;

            return (
              <MetaColumn
                key={`${field.key}-${fieldIndex}`}
                customStyles={field.customStyles}
                isMetadata={false}
              >
                {field.renderValue?.(item)}
              </MetaColumn>
            );
          })}
        </ItemDetails>
      </Columns>
      {renderRemove()}
    </ListItemWrapper>
  );
}));

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import GenericModal from 'components/generic-modal';

import * as Styles from './modal.styles';

export class NoMerchantModal extends Component<any> {
  render(): React.ReactNode {
    return (
      <GenericModal
        width={430}
        innerComponent={(
          <Styles.InnerContent>
            <p>
              You currently have no merchant selected. Please choose one before proceeding
            </p>
          </Styles.InnerContent>
        )}
        confirmButtonAction={this.props.closeAction}
        confirmButtonText="OK"
        title="Create Add-On"
        modalOpen={this.props.isOpen}
        closeModal={this.props.closeAction}
      />
    );
  }
}

export default inject('addOnEditStore')(observer(NoMerchantModal));

import { gql } from '@apollo/client';

export const CREATE_CONVERSATION_MUTATION = gql`
  mutation CreateConversation(
    $input: CreateConversationInput!
  ) {
    createConversation(input: $input) {
      id
      merchantId
      name
      unreadMessagesCount
    }
  }
`;

export const SET_CONVERSATION_READ_MUTATION = gql`
  mutation SetConversationRead(
    $where: ConversationWhereInput!
  ) {
    setConversationRead(where: $where) {
      id
      merchantId
      name
      unreadMessagesCount
    }
  }
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const DeleteButton = styled(Box)<any>`
  cursor: ${(props): string => props.isUpdating ? 'not-allowed' : 'pointer'};
  pointer-events: ${(props): string => props.isUpdating ? 'none' : 'auto'};

  path {
    stroke: ${(props): string => props.isUpdating ? colors.middleGrey : colors.floomMidnightBlue};
  }
`;

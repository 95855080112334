import { OrderConnection } from 'generated-types';
import store from 'stores';

import { GraphQL } from 'lib';

import { TODAYS_ORDER_REVENUE, LAST_30_DAYS_ORDER_REVENUE } from '../graphql/queries/overview-queries';

class OverviewService {
  private UserStore = store.userStore;

  private MerchantStore = store.merchantStore;

  private OverviewStore = store.overviewStore;

  private buildVariables = (vars: any = {}): any => {
    return  {
      ...vars,
      merchantId: this.UserStore.merchantId,
      merchantTimezone: this.MerchantStore.merchant?.timezone
    };
  };

  public fetchTodaysOrderRevenue = async (variables: any = {}): Promise<OrderConnection> => {
    const vars = this.buildVariables(variables);

    return GraphQL.query(TODAYS_ORDER_REVENUE(vars), vars, 'no-cache')
      .then((results: any): any => {
        this.OverviewStore.setTodaysOrderRevenue(results.data.orders);

        return results.data.orders;
      });
  };

  public fetchLast30DaysOrderRevenue = async (variables: any = {}): Promise<OrderConnection> => {
    const vars = this.buildVariables(variables);

    return GraphQL.query(LAST_30_DAYS_ORDER_REVENUE(vars), vars, 'no-cache')
      .then((results: any): any => {
        this.OverviewStore.setLast30DaysOrderRevenue(results.data.orders);

        return results.data.orders;
      });
  };
}

export default new OverviewService();

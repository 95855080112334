import React from 'react';

import { inject, observer } from 'mobx-react';

import CreateLayout from '../../components/create-edit-product-layout';

import * as Types from './product-create.types';

class ProductCreate extends React.Component<Types.ProductCreateProps> {
  componentDidMount = (): void => {
    this.handleOnCreate();
  };

  componentWillUnmount = (): void => {
    this.props.productEditStore!.init();
  };

  private handleOnCreate = (): void => {
    if (this.props.path === '/products/create/') {
      this.props.productEditStore!.toggleProductCreateTypeModal();
    }
  };

  render(): JSX.Element {
    return (
      <div>
        <CreateLayout
          productId={this.props.productId}
          productTypeId={this.props.productTypeId}
        />
      </div>
    );
  }
}

export default inject('productEditStore', 'productsStore')(observer(ProductCreate));

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const ColourDot = styled(Box)<{ hex: string; size?: 'small' | 'large' }>`
  width: ${({ size }): string => size === 'large' ? '11px' : '9px'};
  height: ${({ size }): string => size === 'large' ? '11px' : '9px'};
  margin-top: ${({ size }): string => size === 'large' ? '2px' : '0'};
  border-radius: 100%;
  border: 1px solid ${({ hex }) => hex && hex.toLowerCase().includes('#fff') ? colors.grey : hex};
  background: ${({ hex }) => hex};
`;

export const ColourName = styled.div<{ status: 'active' | 'disabled'; size?: 'small' | 'large' }>`
  margin-left: 6px;
  font-size: ${({ size }): string => size === 'large' ? '15px' : '12px'};
  color: ${({ status }): string => {
    switch (status) {
      case 'active': {
        return colors.floomMidnightBlue;
      }

      case 'disabled': {
        return colors.grey;
      }

      default: {
        return colors.grey;
      }
    }
  }}  
`;

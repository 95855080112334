import store from 'stores';

import {
  List,
  ListWhereUniqueInput,
  ListUpdateInput,
  ListItem,
  Query
} from 'generated-types.d';

import { GraphQL } from 'lib';

import { DELETE_LIST, UPDATE_LIST } from 'features/lists/graphql/mutations/list.mutators';
import {
  LIST_ITEM,
  SINGLE_LIST
} from 'features/lists/graphql/queries/list.queries';

export class SelectedListStoreService {
  public static fetchList = async (id: string): Promise<List> => {
    const where: ListWhereUniqueInput =  { id };

    if (!!store.merchantStore?.merchant) {
      where.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<{ list: List }>(SINGLE_LIST, { where });

      return result.data.list;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static fetchItem = async (itemId: string, listId: string): Promise<ListItem | null> => {
    try {
      const result = await GraphQL.query<Pick<Query, 'list'>>(LIST_ITEM, { listId, itemId });

      return result.data.list?.items?.[0] || null;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static updateList = async ({ id, data, where } : {
    id: string;
    data: ListUpdateInput;
    where?: ListWhereUniqueInput;
  }): Promise<List> => {
    const whereInput: ListWhereUniqueInput =  {
      ...(where || {}),
      id
    };

    if (!!store.merchantStore?.merchant) {
      whereInput.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<{ updateList: List }>(UPDATE_LIST, {
        where: whereInput,
        data: data
      });

      return result.data.updateList;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static deleteList = async (id: string): Promise<Pick<List, 'id'>> => {
    const where: ListWhereUniqueInput =  { id };

    if (!!store.merchantStore?.merchant) {
      where.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<{ list: List }>(DELETE_LIST, { where });

      return result.data.list;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

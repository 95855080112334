import store from 'stores';

import {
  WholesaleOrder,
  WholesaleOrderStates,
  WholesaleOrderWhereInput
} from 'generated-types.d';

import { GraphQL } from 'lib';

import {
  CANCEL_DRAFT_WHOLESALE_ORDER,
  FINALISE_DRAFT_WHOLESALE_ORDER,
  UPDATE_DRAFT_WHOLESALE_ORDER,
  UPDATE_DRAFT_WHOLESALE_ORDER_ITEM
} from 'features/wholesale/graphql/mutators/draft-order/draft-wholesale-order';
import {
  DRAFT_WHOLESALE_ORDER
} from 'features/wholesale/graphql/queries/draft-order/draft-wholesale-order';

import {
  DeleteDraftWholesaleOrderItemArgs,
  UpdateDraftWholesaleOrderArgs,
  UpdateDraftWholesaleOrderItemArgs,
  UpdateDraftWholesaleOrderQueryVariables,
  UpdateDraftWholesaleOrderResponse
} from './draft-wholesale-order-store-types';

export class DraftWholesaleOrderStoreService {
  public static fetchOrder = async (id: string): Promise<WholesaleOrder | null> => {
    try {
      const where: WholesaleOrderWhereInput = {
        id: id,
        status: WholesaleOrderStates.Draft
      };

      if (!!store.merchantStore?.merchant) {
        where.merchant = {
          id: store.merchantStore.merchant.id
        };
      }

      const result = await GraphQL.query<{ wholesaleOrders: WholesaleOrder[] }>(DRAFT_WHOLESALE_ORDER, { where });

      return result.data.wholesaleOrders?.[0] || null;
    } catch (error) {
      return Promise.reject();
    }
  };

  public static finaliseOrder = async (id: string): Promise<WholesaleOrder> => {
    const where: WholesaleOrderWhereInput = { id };
    const result = await GraphQL.query<{ finaliseDraftWholesaleOrder: WholesaleOrder }>(FINALISE_DRAFT_WHOLESALE_ORDER, { where });

    if (!result.data?.finaliseDraftWholesaleOrder) {
      throw(result);
    }

    return result.data.finaliseDraftWholesaleOrder;
  };

  public static cancelOrder = async (id: string): Promise<WholesaleOrder | null> => {
    try {
      const where: WholesaleOrderWhereInput = { id };
      const result = await GraphQL.query<{ draftWholesaleOrder: WholesaleOrder }>(CANCEL_DRAFT_WHOLESALE_ORDER, { where });

      return result.data.draftWholesaleOrder || null;
    } catch (error) {
      return Promise.reject();
    }
  };

  public static updateOrder = async ({ where, data }: UpdateDraftWholesaleOrderArgs): Promise<WholesaleOrder | null> => {
    try {
      const result = await GraphQL.query<{ updateDraftWholesaleOrder: WholesaleOrder }>(UPDATE_DRAFT_WHOLESALE_ORDER, { where, data });

      return result.data.updateDraftWholesaleOrder || null;
    } catch (error) {
      return Promise.reject();
    }
  };

  public static updateOrderItem = async ({
    orderId,
    data,
    orderItemId
  }: UpdateDraftWholesaleOrderItemArgs): Promise<UpdateDraftWholesaleOrderResponse> => {
    const queryVariables: UpdateDraftWholesaleOrderQueryVariables = {
      orderItemId: orderItemId,
      data: {
        items: {
          update: [{
            where: {
              id: orderItemId
            },
            data: data
          }]
        }
      },
      where: {
        id: orderId
      }
    };

    try {
      const result = await GraphQL.query<{ updateDraftWholesaleOrder: UpdateDraftWholesaleOrderResponse }>(UPDATE_DRAFT_WHOLESALE_ORDER_ITEM, queryVariables);

      return result.data.updateDraftWholesaleOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static deleteOrderItem = async ({
    orderId,
    orderItemId
  }: DeleteDraftWholesaleOrderItemArgs): Promise<UpdateDraftWholesaleOrderResponse> => {
    const queryVariables: UpdateDraftWholesaleOrderQueryVariables = {
      orderItemId: orderItemId,
      data: {
        items: {
          delete: [{
            id: orderItemId
          }]
        }
      },
      where: {
        id: orderId
      }
    };

    try {
      const result = await GraphQL.query<{ updateDraftWholesaleOrder: UpdateDraftWholesaleOrderResponse }>(UPDATE_DRAFT_WHOLESALE_ORDER_ITEM, queryVariables);

      return result.data.updateDraftWholesaleOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

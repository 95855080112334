
import { Product, DeliveryConfig, ProductVariationType } from 'generated-types';
import { ProductCreateTitle } from 'lib/services/analytics/analytics.types';
import store from 'stores';

import { Analytics } from 'lib';

export enum CreateStep {
  ClickCreate = 'clickCreate',
  ChooseType = 'chooseType',
  ProductTab = 'productTab',
  RecipeTab = 'recipeTab',
  AddVariation = 'addVariation',
  AddonTab = 'addOn',
  DeliveryTab = 'deliveryTab',
  Save = 'save'
}

export default class ProductAnalytics {
  private ProductEditStore = store.productEditStore;

  CREATE_STEPS: { [key in CreateStep]: ProductCreateTitle }  = {
    [CreateStep.ClickCreate]: 'Click create',
    [CreateStep.ChooseType]: 'Choose type',
    [CreateStep.ProductTab]: 'Product',
    [CreateStep.RecipeTab]: 'Recipe',
    [CreateStep.AddVariation]: 'Add variation',
    [CreateStep.AddonTab]: 'Addon',
    [CreateStep.DeliveryTab]: 'Delivery',
    [CreateStep.Save]: 'Save'
  };

  public onCreateStep = (
    step: CreateStep,
    eventName: Analytics.FxEventName = Analytics.FxEventName.ProductCreateStep,
    productType: string,
    variationType?: string
  ): void => {
    Analytics.track(eventName, {
      event_version: 2,
      creation_session_id: this.ProductEditStore.id || '',
      step: this.CREATE_STEPS[step],
      product_type: productType,
      ...variationType && { variation_type: variationType }
    });
  };

  public onSave = (productType: string, step: CreateStep): void => {
    this.onCreateStep(step, Analytics.FxEventName.ProductEditSave, productType);
  };

  public onEditAvailability = (available: boolean, entryPoint: Analytics.ProductAvailabilityEntry): void => {
    Analytics.track(Analytics.FxEventName.ProductEditAvailability, {
      event_version: 2,
      available: available,
      entry_point: entryPoint,
      in_app: false
    });
  };

  public onEditStock = (
    entryPoint: Analytics.ProductStockEntry,
    stock: number,
    variationType: ProductVariationType
  ): void => {
    Analytics.track(Analytics.FxEventName.ProductEditStock, {
      event_version: 2,
      entry_point: entryPoint,
      in_app: false,
      stock_level: stock,
      set_to_out_of_stock: stock === 0,
      variation_type: variationType
    });
  };

  public onEditDeliveryConfig = (
    productConfigs: Product['deliveryConfigs'],
    allConfigs: DeliveryConfig[]
  ): void => {
    Analytics.track(Analytics.FxEventName.DeliveryConfigEdit, {
      event_version: 1,
      enabled_all: !!productConfigs && (productConfigs.length === allConfigs.length),
      disabled_all: !!productConfigs && productConfigs.length === 0
    });
  };
}

import React, { Component, ReactNode } from 'react';

import { DeliveryConfig } from 'generated-types.d';

import { textStyles } from 'utils/rebass-theme';

import ItemMetaWrapper from 'components/meta-card/content-wrapper';

interface LeadTimeProps {
  data: DeliveryConfig;
}

class LeadTime extends Component<LeadTimeProps> {
  render(): ReactNode {
    return (
      <ItemMetaWrapper
        iconName="website"
        hasActivityToggle={true}
        isActivityToggleActive={this.props.data.deliveryTiming.leadtime === 2}
        css={textStyles.body}
      >
        <span css={textStyles.body}>
          2 day lead time
        </span>
      </ItemMetaWrapper>
    );
  }
}

export default LeadTime;

import { navigate } from '@reach/router';
import {
  CustomSiteConfigCreateInput,
  CustomSiteConfigUpdateInput,
  CustomSiteConfigWhereUniqueInput
} from 'generated-types';
import store from 'stores';

import { GraphQL } from 'lib';

import MerchantService from 'utils/global-services/merchant';

import {
  CREATE_MAILCHIMP_INTEGRATION,
  CREATE_MERCHANT_CUSTOM_SITE_CONFIG,
  UPDATE_MERCHANT_CUSTOM_SITE_CONFIG,
  UNLINK_MAILCHIMP_SUBSCRIPTION
} from './custom-site-apollo.mutators';
import { GET_ALL_MERCHANTS_CONFIGS } from './custom-site.queries';

export default class CustomSiteService {
  private userStore = store.userStore;

  private toasterStore = store.toasterStore;

  private merchantStore = store.merchantStore;

  private customSiteSettingsStore = store.customSiteSettingsStore;

  private merchantService = MerchantService;

  /**
   * @deprecated
   * @description - This is a legacy method for integrating merchant newsletters with floomx.
   * They used to be connected to legacy custom websites, built on DatoCMS, which are
   * no longer part of our product offering. Please do not remove these directories, as
   * a usecase for integrating with mailchimp may still exist in the future.
   */
  public confirmMailchimp = async (code: string): Promise<any> => {
    return GraphQL.mutate(CREATE_MAILCHIMP_INTEGRATION, {
      code: code,
      where: {
        merchant: {
          id: this.userStore.merchantId
        }
      }
    })
      .then(async () => {
        await this.merchantService.initializeActingMerchantDetails();
        this.customSiteSettingsStore.toggleConfirmingMailchimp();

        navigate('/settings/custom-site');

        return true;
      })
      .catch((e: any) => {
        this.customSiteSettingsStore.setMailchimpError(e);
        this.customSiteSettingsStore.toggleConfirmingMailchimp();
        window.Sentry.captureException(e);
      });
  };

  /**
   * @deprecated
   * @description - This is a legacy method for integrating merchant newsletters with floomx.
   * They used to be connected to legacy custom websites, built on DatoCMS, which are
   * no longer part of our product offering. Please do not remove these directories, as
   * a usecase for integrating with mailchimp may still exist in the future.
   */
  public unlinkMailchimp = async (): Promise<any> => {
    this.customSiteSettingsStore.toggleConfirmingMailchimp();

    const where: CustomSiteConfigWhereUniqueInput = {
      merchant: {
        id: this.merchantStore.merchant?.id
      },
      customSiteProjectId: this.merchantStore.merchant?.customSiteConfig?.customSiteProjectId
    };

    return GraphQL.mutate(UNLINK_MAILCHIMP_SUBSCRIPTION, { where })
      .then(async () => {
        await this.merchantService.initializeActingMerchantDetails();
        this.customSiteSettingsStore.toggleConfirmingMailchimp();

        return true;
      })
      .catch((e: any) => {
        window.Sentry.captureException(e);
      });
  };

  public getMerchantCustomSiteConfigs(): Promise<any> {
    return GraphQL.query(GET_ALL_MERCHANTS_CONFIGS, { active: true })
      .then(({ data }: any) => {
        this.customSiteSettingsStore.getMerchantList(data.merchants);
      })
      .catch(() => {
        this.toasterStore.popErrorToast('custom website settings', 'retrieve');
      });
  }

  public createNewCustomSiteConfig = async (data: CustomSiteConfigCreateInput): Promise<any> => {
    return GraphQL.mutate(CREATE_MERCHANT_CUSTOM_SITE_CONFIG, { data })
      .then(() => {
        this.getMerchantCustomSiteConfigs();
      })
      .catch(() => {
        this.toasterStore.popErrorToast('custom website settings', 'create');
      });
  };

  public updateCustomSiteConfig = async (
    merchantId: string,
    id: string,
    data: CustomSiteConfigUpdateInput
  ): Promise<any> => {
    return GraphQL.mutate(UPDATE_MERCHANT_CUSTOM_SITE_CONFIG, { merchantId, id, data })
      .then(() => {
        this.getMerchantCustomSiteConfigs();
      })
      .catch(() => {
        this.toasterStore.popErrorToast('custom website settings', 'update');
      });
  };
}

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, breakpoints } from 'utils/rebass-theme';

export const TermsAndConditions: any = styled(Box)<any>`
  margin-bottom: 80px;
`;

export const TermsAndConditionsModal: any = styled(Flex)<any>`
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @media (min-width: ${breakpoints.small}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const CheckboxContainer: any = styled(Flex)`
  width: 30px;
  height: 30px;
  align-items: center;
`;

export const ButtonContinue: any = styled(Box)``;

export const LinkTerms: any = styled(Box)`
  text-decoration: underline;
  color: ${colors.floomMidnightBlue};
`;

import { FC, useState } from 'react';

import { css } from '@emotion/react';
import { CatalogItemHit } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Currency, ListItem } from 'generated-types.d';

import { colors } from 'utils/rebass-theme';

import NoMediaImage from 'assets/icons/flower-squiggle.svg';

import { ListPriceRange } from 'features/lists/components/list-price-range/list-price-range';
import { listItemImage } from 'features/lists/lists.helpers';

import Icon from 'components/icon';
import { IconProps, IconType } from 'components/icon/icon.types';

import {
  Row,
  ImageWrap,
  BgImage,
  AddButton,
  Title,
  SubTitle
} from './catalog-explorer-item.styles';

interface CatalogExplorerItemProps {
  onAdd: (hit: CatalogItemHit) => Promise<ListItem | undefined>;
  onRemove: (item: ListItem) => Promise<void>;
  hit: CatalogItemHit;
}

export interface ButtonConfig {
  copy: string;
  textColour: string;
  bgColour: string;
  width: string;
  iconName: IconType;
  iconSize: IconProps['size'];
  position: 'top' | 'bottom';
  clickEvent?: () => any;
}

const CatalogExplorerItem: FC<CatalogExplorerItemProps> = ({
  hit,
  onAdd,
  onRemove
}) => {
  const [hasMouseOver, setHasMouseOver] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [shouldDisplayRemove, setShouldDisplayRemove] = useState(false);
  const [listItem, setListItem] = useState<ListItem | null>(null);
  const [hasTransition, setHasTransition] = useState(false);

  const disableTransition = (): void => {
    setTimeout(() => {
      setHasTransition(() => false);
    }, 300);
  };

  const handleMouseOver = (): void => {
    if (!hasMouseOver && isAdded) {
      setShouldDisplayRemove(true);
    }

    setHasMouseOver(true);
  };

  const handleMouseLeave = (): void => {
    setHasMouseOver(false);

    if (isAdded && !shouldDisplayRemove) {
      setShouldDisplayRemove(true);
    }
  };

  const handleAdd = async (): Promise<void> => {
    try {
      setHasTransition(() => true);
      setIsAdding(() => true);
      const result = await onAdd(hit);

      if (!!result) {
        setListItem(result);
      }

      if (!hasMouseOver) {
        setShouldDisplayRemove(true);
      }

      setIsAdded(() => true);
      setIsAdding(() => false);
      disableTransition();
    } catch (error) {
      setIsAdding(() => false);

      return;
    }
  };

  const handleRemove = async (): Promise<void> => {
    if (!listItem) return;

    try {
      setHasTransition(() => true);
      setIsRemoving(() => true);
      await onRemove(listItem);

      setIsAdded(() => false);
      setIsRemoving(() => false);
      setListItem(() => null);
      setShouldDisplayRemove(() => false);
      disableTransition();
    } catch (error) {
      setIsRemoving(() => false);

      return;
    }
  };

  const buttonConfig = ((): ButtonConfig => {
    switch (true) {
      case isRemoving:
        return {
          copy: 'Remove',
          bgColour: colors.errorText,
          textColour: colors.white,
          iconName: 'cross-big',
          iconSize: 15,
          width: '110px',
          position: 'top'
        };

      case hasMouseOver && shouldDisplayRemove:
        return {
          copy: 'Remove',
          bgColour: colors.errorText,
          textColour: colors.white,
          iconName: 'cross-big',
          iconSize: 15,
          width: '110px',
          position: 'top',
          clickEvent: handleRemove
        };

      case isAdding:
        return {
          copy: 'Adding',
          bgColour: colors.white,
          textColour: colors.floomMidnightBlue,
          iconName: 'tick',
          iconSize: 'normal',
          width: '100%',
          position: 'bottom'
        };

      case isAdded:
        return {
          copy: 'Added',
          bgColour: colors.floomMidnightBlue,
          textColour: colors.white,
          iconName: 'tick',
          iconSize: 'normal',
          width: '100px',
          position: 'top'
        };

      default:
        return {
          copy: 'Add to list',
          bgColour: colors.white,
          textColour: colors.floomMidnightBlue,
          iconName: 'tick',
          iconSize: 'normal',
          width: '100%',
          position: 'bottom',
          clickEvent: handleAdd
        };
    }
  })();

  const isDisabled = isAdding || isRemoving;
  const imageUrls = hit.images?.reduce?.((acc: string[], curr): string[] => [...acc, ...curr.fileName.map(name => `${curr.url}/${name}`)], []);

  return (
    <Row isDisabled={isDisabled}>
      <button
        onClick={() => buttonConfig.clickEvent?.()}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        disabled={isDisabled}
        css={css`
          width: 100%;
          text-align: left;
          cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
        `}
      >
        <ImageWrap
          config={buttonConfig}
          hasTransition={hasTransition}
        >
          <BgImage
            url={listItemImage(imageUrls)}
          >
            {!imageUrls.length && (
              <Box
                css={css`
                  position: absolute;
                  opacity: 0.8;
                  bottom: -8px;
                  left: 50%;
                  transform: translateX(-50%);
                `}
              >
                <NoMediaImage
                  width="100%"
                  height="100%"
                />
              </Box>
            )}
            <AddButton
              isVisible={hasMouseOver || isAdded || isAdding}
              hasTransition={hasTransition}
              config={buttonConfig}
            >
              <Icon
                iconName={buttonConfig.iconName}
                size={buttonConfig.iconSize!}
              />
              {' '}
              <Box
                as="span"
                pl="3px"
              >
                {buttonConfig.copy}
              </Box>
            </AddButton>
          </BgImage>
        </ImageWrap>
        <Title
          title={hit.title}
        >
          {hit.title}
        </Title>
        {!!hit.priceRange && (
          <SubTitle>
            <ListPriceRange
              min={hit.priceRange.min}
              max={hit.priceRange.max}
              currency={Currency.Gbp}
            />
            {' '}
            <span>
              per unit
            </span>
          </SubTitle>
        )}
      </button>
    </Row>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  catalogExplorerStore: stores.catalogExplorerStore
}))(observer(CatalogExplorerItem));

import { FC, Fragment, useState } from 'react';

import moment from 'moment';
import { Box } from 'rebass';

import { Currency, ListType, Media } from 'generated-types.d';

import { NavService } from 'lib';

import { colors } from 'utils/rebass-theme';

import imagePlaceholder from 'assets/images/wholesale/placeholder.jpg';

import { listItemMediaImage } from 'features/lists/lists.helpers';

import { EntitySummary, EntitySummarySize } from 'components/enitity-summary/enitity-summary';
import Icon from 'components/icon';
import IconButton from 'components/icon-button';

import { ConfirmDeleteTooltip } from '../../confirm-delete-tooltip/confirm-delete-tooltip';

import {
  BinIconWrapperStyles,
  EntitySummaryWrapper,
  ListItemButtonWrapper,
  ListItemContainer
} from './lists-list-item.styles';
import * as Types from './lists-list-item.types';

export const ListsListItem: FC<Types.ListsListItemProps> = ({
  data,
  itemCount,
  onClick,
  onDeleteListClick: deleteListClick
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const getMedia = (): Media[] => {
    const listItem = data.items?.find?.(item => !!item.catalogItem?.media?.length);

    if (!!listItem?.catalogItem?.media?.length) {
      return listItem.catalogItem.media;
    }

    return [];
  };

  const ListTypeComponent = (): React.ReactElement => {
    return (
      <Fragment>
        <Icon
          iconName={data.type === ListType.Simple ? 'list' : 'lightning-bolt'}
          size="small"
        />
        <Box ml={2}> {data.type === ListType.Simple ? 'Simple' : 'Advanced'}</Box>
      </Fragment>
    );
  };

  const handleClickDelete = (event: React.MouseEvent<Element> | React.TouchEvent<Element> | undefined): void => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseDeleteMenu = (): void => {
    setAnchorEl(null);
  };

  const renderUpdatedAt = (): string => {
    if (moment(data.updatedAt).isSame(moment(), 'day')) {
      return `Today, ${moment(data.updatedAt).format('h:mma')}`;
    }

    if (moment(data.updatedAt).isSame(moment().subtract(1, 'day'), 'day')) {
      return `Yesterday, ${moment(data.updatedAt).format('h:mma')}`;
    }

    if (moment(data.updatedAt).isSame(moment(), 'year')) {
      if (data.merchant?.currency === Currency.Usd) {
        return moment(data.updatedAt).format('MMM D');
      }

      return moment(data.updatedAt).format('Do MMM');
    }

    if (data.merchant?.currency === Currency.Usd) {
      return moment(data.updatedAt).format('MMM D YYYY');
    }

    return moment(data.updatedAt).format('Do MMM YYYY');
  };

  return (
    <ListItemContainer
      key={data.id}
      alignItems="center"
    >
      <EntitySummaryWrapper
        onClick={(): void => onClick ? onClick(data.id) : NavService.singleList(data.id)}
      >
        <EntitySummary
          imageUrl={listItemMediaImage(getMedia()) || imagePlaceholder}
          size={EntitySummarySize.Small}
          title={data.title || 'Untitled list'}
          subtitle={`${itemCount} Items`}
          label={<ListTypeComponent />}
          labelColour={colors.paleGrey}
          lastUpdated={`Updated: ${renderUpdatedAt()}`}
        />
      </EntitySummaryWrapper>
      <ListItemButtonWrapper>
        <IconButton
          iconName="bin"
          wrapperStyles={BinIconWrapperStyles}
          onClick={handleClickDelete}
        />
        <ConfirmDeleteTooltip
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseDeleteMenu}
          onDeleteItem={async () => {
            deleteListClick?.(data.id);
          }}
        />
      </ListItemButtonWrapper>
    </ListItemContainer>
  );
};

import React, { Component } from 'react';

import _get from 'lodash.get';
import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Merchant } from 'generated-types.d';

import {
  Auth,
  PermissionsService
} from 'lib';

import * as Types from './support-chat.types';

class SupportChat extends Component<Types.SupportChatProps> {
  private zESettings = (merchant: Merchant): any => ({
    webWidget: {
      zIndex: 80,
      helpCenter: {
        suppress: this.props.chatWidgetStore!.isHelpCenterSuppressed
      },
      chat: {
        suppress: !this.props.chatWidgetStore!.isHelpCenterSuppressed,
        departments: {
          enabled: ['floomx'],
          select: 'floomx'
        },
        prechatForm: {
          greeting: {
            '*': 'Please enter your message in the form below to chat to us'
          }
        },
        concierge: {
          avatarPath: 'https://floomx-assets.s3.eu-west-2.amazonaws.com/zendesk-logo.png',
          title: {
            '*': 'FloomX support'
          }
        },
        tags: [
          'FloomX',
          `Merchant ID: ${merchant?.id}`,
          `Merchant Name: ${merchant?.title}`,
          window.location.pathname
        ]
      },
      launcher: {
        labelVisible: false,
        chatLabel: {
          '*': 'FloomX support'
        },
        title: {
          '*': 'FloomX support'
        }
      },
      position: {
        horizontal: 'left',
        vertical: 'bottom'
      },
      offset: {
        horizontal: this.props.navigationStore!.navWidth,
        vertical: '0'
      }
    }
  });

  componentDidMount = (): void => {
    this.initialiseWidget();

    window['zE']?.('webWidget', 'hide');
    window['zE']?.('webWidget:on', 'close', this.props.chatWidgetStore!.disableChatWidget);
    window['zE']?.('webWidget:on', 'open', this.props.chatWidgetStore!.enableChatWidget);
    window['zE']?.('webWidget:on', 'chat:unreadMessages', this.props.chatWidgetStore!.showChatWidget);
  };

  private initialiseWidget = (): void => {
    if (!Auth.isLoggedIn() || PermissionsService.isInternalRole()) {
      window['zE']?.('webWidget', 'hide');
    }

    observe(
      this.props.navigationStore!,
      'layout',
      ({
        oldValue,
        newValue
      }: any) => {
        if (oldValue !== newValue) {
          if (window['zE']) {
            window['zE']('webWidget', 'updateSettings', this.zESettings(this.props.merchantStore!.merchant!));
          }
        }
      }
    );

    observe(this.props.merchantStore!, 'merchant', ({ oldValue, newValue }: any) => {
      if (!oldValue && newValue.id) {
        if (window['zE']) {
          window['zE']('webWidget', 'updateSettings', this.zESettings(this.props.merchantStore!.merchant!));
        }
      }
    });

    observe(this.props.userStore!, 'user', ({ oldValue, newValue }: any) => {
      if (!oldValue && newValue.id) {
        if (window['zE']) {
          const name = `${newValue.givenName} ${newValue.familyName}`;
          const email = newValue.email;
          const phone = newValue.phone;

          if (window['zE'].identify) {
            window['zE'].identify({ name, email, phone });
          }

          window['zE']('webWidget', 'prefill', {
            name: {
              value: name
            },
            email: {
              value: email,
              readOnly: true
            },
            phone: {
              value: phone,
              readOnly: true
            }
          });
        }
      }
    });

    observe(this.props.chatWidgetStore!, 'isHelpCenterSuppressed', () => {
      window['zE']('webWidget', 'updateSettings', this.zESettings(this.props.merchantStore!.merchant!));
    });

    observe(this.props.chatWidgetStore!, 'isChatWidgetOpen', ({ newValue: isOpen }: any) => {
      if (isOpen && Auth.isLoggedIn()) {
        window['zE']('webWidget', 'show');
        window['zE']('webWidget', 'open');
      } else {
        window['zE']('webWidget', 'close');
      }
    });

    observe(this.props.chatWidgetStore!, 'isChatWidgetHidden', ({ newValue: isHidden }: any) => {
      if (isHidden) {
        window['zE']('webWidget', 'hide');
      } else if (Auth.isLoggedIn() && !isHidden) {
        window['zE']('webWidget', 'show');
      }
    });
  };

  render(): React.ReactNode {
    return <div style={{ display: 'none' }} />;
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  chatWidgetStore: stores.chatWidgetStore,
  uiStore: stores.uiStore,
  merchantStore: stores.merchantStore,
  userStore: stores.userStore,
  navigationStore: stores.navigationStore
}))(observer(SupportChat));

import { TransportType, CourierCode } from 'stores/courier/courier-store.types';

import { IconType } from 'components/icon/icon.types';

export interface CourierTransportConfig {
  dimensions: string;
  weight: string;
}

export type CourierCodeConfig = {
  [key in TransportType]: CourierTransportConfig
};

export type CourierMatrix = {
  [key in CourierCode]: CourierCodeConfig
};

export interface TransportConfigItem {
  icon: IconType;
  name: string;
}

type TransportConfig = {
  [key in TransportType]: TransportConfigItem;
};

export const TRANSPORT_CONFIG: TransportConfig = {
  BIKE: {
    icon: 'courier-cycle',
    name: 'Cycle'
  },
  CAR: {
    icon: 'courier-small-car',
    name: 'Car'
  },
  CARGOBIKE: {
    icon: 'courier-cycle',
    name: 'Cargobike'
  },
  MOTORBIKE: {
    icon: 'courier-motorbike',
    name: 'Motorbike'
  },
  VAN: {
    icon: 'courier-van',
    name: 'Van'
  },
  MEDIUMVAN: {
    icon: 'courier-large-van',
    name: 'Medium Van'
  },
  LARGEVAN: {
    icon: 'courier-large-van',
    name: 'Large Van'
  },
  WALKER: {
    icon: 'courier-walker',
    name: 'Walker'
  },
  DEFAULT: {
    icon: 'delivery',
    name: 'Van'
  },
  ANY: {
    icon: 'delivery',
    name: 'Any'
  }
};

export const DEFAULT_DIMENSIONS: CourierTransportConfig = {
  dimensions: '45x30x30cm',
  weight: '5kgs'
};

export const COURIER_MATRIX: CourierMatrix = {
  STUART: {
    WALKER: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    BIKE: {
      dimensions: '40x20x15cm',
      weight: '12kg'
    },
    CAR: {
      dimensions: '40x20x15cm',
      weight: '12kg'
    },
    CARGOBIKE: {
      dimensions: '40x20x15cm',
      weight: '12kg'
    },
    MOTORBIKE: {
      dimensions: '40x20x15cm',
      weight: '12kg'
    },
    VAN: {
      dimensions: '40x20x15cm',
      weight: '12kg'
    },
    MEDIUMVAN: {
      dimensions: '40x20x15cm',
      weight: '12kg'
    },
    LARGEVAN: {
      dimensions: '40x20x15cm',
      weight: '12kg'
    },
    ANY: {
      dimensions: '40x20x15cm',
      weight: '12kg'
    },
    DEFAULT: {
      dimensions: '40x20x15cm',
      weight: '12kg'
    }
  },
  CRAWFORDS: {
    WALKER: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    BIKE: {
      dimensions: '45x30x30cm',
      weight: '5kgs'
    },
    CAR: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    CARGOBIKE: {
      dimensions: '45x30x30cm',
      weight: '5kgs'
    },
    MOTORBIKE: {
      dimensions: '45x30x30cm',
      weight: '5kgs'
    },
    VAN: {
      dimensions: '1.5x1.2x1.2m',
      weight: '600kg'
    },
    MEDIUMVAN: {
      dimensions: '1.7x1.2x1.8m',
      weight: '900kg'
    },
    LARGEVAN: {
      dimensions: '3.5x1.8x1.9m',
      weight: '1200kg'
    },
    ANY: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    DEFAULT: {
      dimensions: 'N/A',
      weight: 'N/A'
    }
  },
  ADDISONLEE: {
    WALKER: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    BIKE: {
      dimensions: '37x28x24cm',
      weight: '5kg'
    },
    CAR: {
      dimensions: '60x90x45cm',
      weight: '40kg'
    },
    CARGOBIKE: {
      dimensions: '37x28x24cm',
      weight: '5kg'
    },
    MOTORBIKE: {
      dimensions: '37x28x24cm',
      weight: '5kg'
    },
    VAN: {
      dimensions: '120x150x90cm',
      weight: '700kg'
    },
    MEDIUMVAN: {
      dimensions: '120x240x150cm',
      weight: '1200kg'
    },
    LARGEVAN: {
      dimensions: '120x240x150cm',
      weight: '1200kg'
    },
    ANY: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    DEFAULT: {
      dimensions: 'N/A',
      weight: 'N/A'
    }
  },
  MYPASSAGE: {
    WALKER: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    BIKE: {
      dimensions: '45x30x30cm',
      weight: '10kgs'
    },
    CAR: {
      dimensions: '60x90x45cm',
      weight: '30kgs'
    },
    CARGOBIKE: {
      dimensions: '45x30x30cm',
      weight: '10kgs'
    },
    MOTORBIKE: {
      dimensions: '45x30x30cm',
      weight: '10kgs'
    },
    VAN: {
      dimensions: '1.2x1.5x1.2m',
      weight: '600kg'
    },
    MEDIUMVAN: {
      dimensions: '2.4x1.7x1.4m',
      weight: '12kg'
    },
    LARGEVAN: {
      dimensions: '3.4x1.7x1.7m',
      weight: '1200kg'
    },
    ANY: {
      dimensions: '45x30x30cm',
      weight: '10kgs'
    },
    DEFAULT: {
      dimensions: '45x30x30cm',
      weight: '10kgs'
    }
  },
  URBIT: {
    WALKER: {
      dimensions: '4 std. bouquets',
      weight: '15kg'
    },
    BIKE: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    CAR: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    CARGOBIKE: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    MOTORBIKE: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    VAN: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    MEDIUMVAN: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    LARGEVAN: {
      dimensions: 'N/A',
      weight: 'N/A'
    },
    ANY: {
      dimensions: '4 std. bouquets',
      weight: '15kg'
    },
    DEFAULT: {
      dimensions: '4 std. bouquets',
      weight: '15kg'
    }
  },
  NOQU: {
    WALKER: {
      dimensions: 'noqu walker',
      weight: '10kg'
    },
    BIKE: {
      dimensions: 'noqu bike',
      weight: '10kg'
    },
    CAR: {
      dimensions: 'noqu car',
      weight: '10kg'
    },
    CARGOBIKE: {
      dimensions: 'noqu cargobike',
      weight: '10kg'
    },
    MOTORBIKE: {
      dimensions: 'noqu motorbike',
      weight: '10kg'
    },
    VAN: {
      dimensions: 'noqu van',
      weight: '10kg'
    },
    MEDIUMVAN: {
      dimensions: 'noqu mediumvan',
      weight: '10kg'
    },
    LARGEVAN: {
      dimensions: 'noqu largevan',
      weight: '10kg'
    },
    ANY: {
      dimensions: 'noqu any',
      weight: '10kg'
    },
    DEFAULT: {
      dimensions: 'noqu default',
      weight: '10kg'
    }
  }
};

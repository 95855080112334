import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors, space, textStyles } from 'utils/rebass-theme';

import { ButtonDropdownPosX } from './button-dropdown.types';

const positionXStyles: Record<ButtonDropdownPosX, SerializedStyles> = {
  left: css``,
  right: css`
    left: auto;
    right: ${space[2]}px;
  `,
  center: css`
    left: 50%;
    transform: translate(-50%, 0);
  `
};

export const Container = styled(Flex)<{ customStyles: SerializedStyles }>`
  border-radius: 10px;
  background-color: ${colors.white};
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  cursor: pointer;
  display: block;
  z-index: 1000;
  position: absolute;
  padding: 5px 10px;
  width: max-content;
  left: ${space[2]}px;

  ${({ positionY }) => positionY === 'top' ? css`
    top: calc(-100% + ${space[2]}px);
    transform: translateY(-100%);
  ` : ``}

  ${({ positionX }) => positionXStyles[positionX]}

  ${({ customStyles }) => customStyles};
`;

export const Item = styled(Flex)`
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;

  + ${(): any => Item} {
    border-top: 1px solid ${colors.paleGrey};
  }
`;

export const IconWrapper = styled(Flex)`
  padding: 5px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: ${props => props.colour || colors.paleGrey};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div``;

export const Header = styled.div`
  font-size: 14px;
`;

export const Description = styled.div`
  margin-top: 5px;
  font-size: 11px;
  color: ${colors.grey};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

export const Heading = styled.div`
  ${textStyles.footnote};
  color: ${colors.grey};
  margin: 12px 16px;
  margin-bottom: 8px;
  cursor: default;
`;

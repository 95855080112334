import { FC } from 'react';

import * as Styles from './list-tab-button.styles';

type TabButtonProps = {
  isDisabled: boolean;
  onClick: () => void;
  colour: string;
  isSelected: boolean;
  title: string;
}

export const TabButton: FC<TabButtonProps> = ({
  isDisabled,
  onClick,
  colour,
  isSelected,
  title
}) => {
  return (
    <Styles.TabButton
      as="button"
      disabled={isDisabled}
      onClick={onClick}
      colour={colour}
      selected={isSelected}
    >
      {title}
    </Styles.TabButton>
  );
};

import { Severity } from '@sentry/browser';
import moment from 'moment';

import {
  Currency,
  Merchant,
  WholesaleOrder,
  WholesaleOrderLineItem
} from 'generated-types.d';

import {
  CurrencyService
} from 'lib';

import {
  WholesaleBasketItem
} from 'stores/wholesale/wholesale-payment-store/wholesale-payment-store.types';

import {
  TOTALS_FOR_FREE_DELIVERY,
  DEFAULT_TOTAL_FOR_FREE_DELIVERY
} from 'features/wholesale/wholesale.constants';
import {
  AggregatedWholesaleOrderLineItem
} from 'features/wholesale/wholesale.types';

export default class WholesaleCheckoutHelpers {
  public static calculateItemTotal = (price: number, quantity: number): number => price * quantity;

  public static calculateDeliveryPrice = (order: WholesaleOrder | null): number => {
    return WholesaleCheckoutHelpers.hasOrder(order)
      ? order!.deliveryDetails![0].costValue!
      : 0;
  };

  public static calculateCreditValue = (order: WholesaleOrder | null): number => {
    if (order?.credits?.length && order.credits[0]?.totalPrice) {
      return -order.credits[0].totalPrice;
    }

    return 0;
  }

  public static calculateGrandTotal = (order: WholesaleOrder | null): number => {
    if (!order) {
      return 0;
    }

    const deliveryCostValue = WholesaleCheckoutHelpers.calculateDeliveryPrice(order);
    const creditValue = WholesaleCheckoutHelpers.calculateCreditValue(order);
    const taxValue = WholesaleCheckoutHelpers.orderTaxValue(order);
    // calculation is incorrect because of JS floats calculation so have to use OR zero
    const total = order.totalPrice! + deliveryCostValue  + taxValue + creditValue;

    return total > 0 ? total : 0;
  }

  public static hasFreeDelivery = (order: WholesaleOrder | null, basketTotal: number = 0): boolean => {
    const orderPrice = WholesaleCheckoutHelpers.hasOrder(order) ? order!.totalPrice! : 0;
    const freeDeliveryTotalForSupplier = TOTALS_FOR_FREE_DELIVERY.find(totals => totals.supplier === order!.supplier?.slug);

    return (orderPrice + basketTotal) > (freeDeliveryTotalForSupplier?.totalForFreeDelivery || DEFAULT_TOTAL_FOR_FREE_DELIVERY);
  };

  public static hasOrder = (order: WholesaleOrder | null): boolean => {
    return !!order && !!order.deliveryDetails && !!order.deliveryDetails.length;
  };

  public static renderWholesalePrice = (price: number, currency?: Currency): string => {
    if (!currency) {
      window?.Sentry?.captureMessage('Rendering wholesale price without currency!', Severity.Warning);
    }

    return CurrencyService.formatPrice(price, currency || '');
  }

  public static orderTaxValue = (order?: WholesaleOrder): number => {
    if (!order?.tax || !!order?.tax?.inclusive) return 0;

    return order.tax.amount;
  };

  public static calculateBasketTotal = (basket: WholesaleBasketItem[], otherCosts: number = 0): number => basket.reduce((acc, curr) => acc + (curr.item.price! * curr.quantity), 0) + otherCosts;

  public static hasValidWholesaleDeliveryConfig = (merchant: Merchant): boolean => !!merchant
    && !!merchant.wholesaleDeliveryConfigs
    && !!merchant.wholesaleDeliveryConfigs.length
    && merchant.wholesaleDeliveryConfigs.some(config => config.active);

  public static setOrderItemAggregates = (items: WholesaleOrderLineItem[] | null, cancelTimeoutMinutes?: number | undefined): AggregatedWholesaleOrderLineItem[] => {
    if (!items?.length) return [];

    return items.reduce((acc: AggregatedWholesaleOrderLineItem[], currVal): AggregatedWholesaleOrderLineItem[] => {
      const dupeIndex = acc.findIndex(item => {
        const shouldAggregate: boolean = item.lineItems.some(order => {
          const checkCancelTimeLeft = moment.utc(order.createdAt).diff(moment.utc().subtract(cancelTimeoutMinutes, 'minutes'));

          return order.wholesalerLineItemRef === currVal.wholesalerLineItemRef && (!cancelTimeoutMinutes || checkCancelTimeLeft < 0);
        });
        const isItemRefValid = currVal.wholesalerLineItemRef !== 'MISSING';

        return shouldAggregate && isItemRefValid;
      });

      if (dupeIndex !== -1) {
        const item = { ...acc[dupeIndex] };

        return [
          ...acc.slice(0, dupeIndex),
          {
            ...item,
            lineItems: [...item.lineItems, currVal],
            quantity: item.quantity + currVal.quantity,
            totalPrice: (item.totalPrice || 0) + (currVal.totalPrice || 0)
          },
          ...acc.slice(dupeIndex + 1)
        ];
      }

      return [
        ...acc,
        {
          lineItems: [currVal],
          quantity: currVal.quantity || 0,
          totalPrice: currVal.totalPrice || 0
        }
      ];
    }, []);
  };

  public static countOrderItems = (items: WholesaleOrderLineItem[] | undefined | null): number => {
    if (!items?.length) return 0;

    const uniqueOrderIds: Set<string> = new Set();

    items.forEach(item => {
      uniqueOrderIds.add(item.wholesalerLineItemRef);
    });

    return uniqueOrderIds.size;
  }
}

import { Component } from 'react';

import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import AddMerchant from 'features/merchant-onboarding/pages/add-merchant/add-merchant';
import MerchantCompanyInfo from 'features/merchant-onboarding/pages/merchant-company-info';
import MerchantShopInfo from 'features/merchant-onboarding/pages/merchant-shop-info';
import MerchantSubscriptionCompletion from 'features/merchant-onboarding/pages/merchant-subscription-completion';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

export default class Merchant extends Component {
  render(): JSX.Element {
    return (
      <AppLayout>
        {/*
        // @ts-ignore */}
        <Helmet>
          <title>Create Merchant</title>
        </Helmet>
        <Router>
          <AuthedRoute
            path="/merchant/create/account"
            component={AddMerchant}
          />
          <AuthedRoute
            path="/merchant/create/company-info"
            component={MerchantCompanyInfo}
          />
          <AuthedRoute
            path="/merchant/create/shop-info"
            component={MerchantShopInfo}
          />
          <AuthedRoute
            path="/merchant/create/card-details"
            component={MerchantSubscriptionCompletion}
          />
        </Router>
      </AppLayout>
    );
  }
}

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import { CourierStatusIndicatorConfig } from './courier.types';

export const courierStatusConfig: CourierStatusIndicatorConfig = {
  Booked: {
    type: 'booked',
    iconType: 'delivery-time',
    textColor: ColourOption.floomMidnightBlue,
    iconColor: ColourOption.middleGrey,
    backgroundColour: ColourOption.paleGrey,
    copy: 'Pickup [TIMEDATE]',
    shouldRespectDefault: true,
    dateKey: 'pickupDate'
  },
  None: {
    type: 'booked',
    iconType: 'delivery-time',
    textColor: ColourOption.floomMidnightBlue,
    iconColor: ColourOption.middleGrey,
    backgroundColour: ColourOption.paleGrey,
    copy: '',
    shouldRespectDefault: true,
    dateKey: 'pickupDate'
  },
  Requested: {
    type: 'booked',
    iconType: 'delivery-in-progress',
    textColor: ColourOption.floomMidnightBlue,
    iconColor: ColourOption.middleGrey,
    backgroundColour: ColourOption.paleGrey,
    copy: 'Pickup [TIMEDATE]',
    shouldRespectDefault: true,
    dateKey: 'pickupDate'
  },
  AssignedDriver: {
    type: 'booked',
    iconType: 'delivery-in-progress',
    textColor: ColourOption.floomMidnightBlue,
    iconColor: ColourOption.middleGrey,
    backgroundColour: ColourOption.paleGrey,
    copy: 'Pickup [TIMEDATE]',
    shouldRespectDefault: true,
    dateKey: 'pickupDate'
  },
  PickedUp: {
    type: 'picked up',
    iconType: 'delivery-in-progress',
    textColor: ColourOption.floomMidnightBlue,
    iconColor: ColourOption.middleGrey,
    backgroundColour: ColourOption.paleGrey,
    copy: 'Delivery est. [TIMEDATE]',
    shouldRespectDefault: true,
    dateKey: 'deliveryDate'
  },
  OutForDelivery: {
    type: 'out for delivery',
    iconType: 'delivery-in-progress',
    textColor: ColourOption.floomMidnightBlue,
    iconColor: ColourOption.middleGrey,
    backgroundColour: ColourOption.paleGrey,
    copy: 'Delivery est. [TIMEDATE]',
    shouldRespectDefault: true,
    dateKey: 'deliveryDate'
  },
  Delivered: {
    type: 'delivered',
    iconType: 'delivery-succeeded',
    textColor: ColourOption.chartreuse,
    iconColor: ColourOption.chartreuse,
    backgroundColour: ColourOption.validationBg,
    copy: 'Courier Delivered',
    shouldRespectDefault: false,
    dateKey: 'deliveryDate'
  },
  Cancelled: {
    type: 'cancelled',
    iconType: 'delivery-error',
    textColor: ColourOption.errorText,
    iconColor: ColourOption.errorText,
    backgroundColour: ColourOption.floomBlush,
    copy: 'Courier cancelled',
    shouldRespectDefault: false,
    dateKey: 'pickupDate'
  },
  Failed: {
    type: 'failed',
    iconType: 'delivery-failed',
    textColor: ColourOption.errorText,
    iconColor: ColourOption.errorText,
    backgroundColour: ColourOption.floomBlush,
    copy: 'Courier failed',
    shouldRespectDefault: false,
    dateKey: 'pickupDate'
  },
  Refused: {
    type: 'refused',
    iconType: 'delivery-error',
    textColor: ColourOption.errorText,
    iconColor: ColourOption.errorText,
    backgroundColour: ColourOption.floomBlush,
    copy: 'Refused',
    shouldRespectDefault: false,
    dateKey: 'pickupDate'
  }
};

import { ComponentType } from 'react';

import { RadioItemType } from '../../radio-list.types';
import { BorderItem } from '../border-item/border-item';
import { CardItem } from '../card-item/card-item';
import { DefaultItem } from '../default-item/default-item';

import { RadioItemViewProps } from './radio-item.types';

type RadioItemOptions = {
  [key in RadioItemType]: ComponentType<RadioItemViewProps>;
};

export const RADIO_ITEM_OPTIONS: RadioItemOptions = {
  default: DefaultItem,
  card: CardItem,
  border: BorderItem
};

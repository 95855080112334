import React, { useState } from 'react';

import styled from '@emotion/styled';
import { capitalise } from 'lib/services/string-format/string-format.service';
import { observer } from 'mobx-react';
import { Box, Flex, Text } from 'rebass';

import {
  Currency,
  Merchant
} from 'generated-types.d';

import {
  CurrencyService,
  TimeService
} from 'lib';

import {
  borderRadius,
  colors,
  textStyles
} from 'utils/rebass-theme';

import Button from 'components/button';
import Icon from 'components/icon';

const Heading = styled(Text)`
  ${textStyles.subhead}
  text-decoration: underline;
`;

interface ProposedMerchantUpdateContextProps {
  proposedUpdate: Merchant['proposedUpdate'];
  subscription: Merchant['subscription'];
  currency: Currency;
  onClick: () => Promise<void>;
}

export const ProposedMerchantUpdateContext = observer((props: ProposedMerchantUpdateContextProps): React.FunctionComponentElement<ProposedMerchantUpdateContextProps> | null => {
  const [state, updateState] = useState({
    isLoading: false
  });

  if (!props.proposedUpdate || !props.subscription) return null;

  const isPlanUpdate = !!props.proposedUpdate.plan;

  const Wrapper = styled(Box)`
    background: ${isPlanUpdate ? colors['light-green'] : colors['light-blue']};
    border-radius: ${borderRadius.large};
    padding: 25px;
    margin-top: 10px;
  `;

  const handleClick = async (): Promise<void> => {
    updateState({ isLoading: true });
    await props.onClick();
    updateState({ isLoading: false });
  };

  return (
    <Wrapper>
      <Flex>
        <Box>
          <Icon iconName="question-circle" />
        </Box>
        <Box pl="20px">
          <Heading>
            The {isPlanUpdate ? 'plan' : 'stage'} is changing on {TimeService.dateMonthYearShort(props.subscription?.currentPeriodEndsAt)}
          </Heading>
          <Flex mt="15px">
            <Text>
              <strong>To:</strong>
              {' '}
              <span>
                { isPlanUpdate
                  ? props.proposedUpdate.plan?.type
                  : capitalise(props.proposedUpdate.merchantStage!.toString())
                }
              </span>
            </Text>
            { isPlanUpdate && (
              <Text ml="30px">
                <strong>Cost:</strong> {CurrencyService.formatPrice(props.proposedUpdate.planPrice!, props.currency)}
              </Text>
            )}
          </Flex>
          <Text mt="6px">
            <strong>Requested by:</strong> {props.proposedUpdate.createdBy?.givenName} {props.proposedUpdate.createdBy?.familyName}
          </Text>
          <Box
            as="button"
            onClick={handleClick}
            mt="20px"
          >
            <Button
              copy={`Cancel ${isPlanUpdate ? 'plan' : 'stage'} change`}
              isLoading={state.isLoading}
            />
          </Box>
        </Box>
      </Flex>
    </Wrapper>
  );
});

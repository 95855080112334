import { Image } from 'rebass';

import { RecipeItem } from 'generated-types.d';

import { UrlService } from 'lib';

import * as Styles from './ingredients-list.styles';

export const IngredientsList = ({ orderItem, recipeItems, orderItems }: any): JSX.Element => {
  return (
    <Styles.IngredientsSection finalProduct={orderItems.indexOf(orderItem) === orderItems.length - 1}>
      {!!recipeItems.length ? <p>Ingredients</p> : null}
      {recipeItems.map((item: RecipeItem) => {
        const componentConfig = item.componentConfig;

        if (!componentConfig) return null;

        return (
          <Styles.IngredientsWrapper key={item.id}>
            <Styles.IngredientsImage>
              <Image src={UrlService.setImageSrc(componentConfig?.media?.src || '')} />
            </Styles.IngredientsImage>
            <Styles.IngredientsCounter>{item.quantity}</Styles.IngredientsCounter>
            {componentConfig.title}
          </Styles.IngredientsWrapper>
        );
      })}
    </Styles.IngredientsSection>
  );
};

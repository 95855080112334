import {
  action,
  observable,
  computed,
  makeObservable,
  runInAction
} from 'mobx';
import moment from 'moment';

import {
  List,
  Supplier,
  ListItem,
  ListItemUpdateWithoutListDataInput,
  ListUpdateInput,
  WholesalePreOrder,
  ListItemUnion,
  WholesalePreOrderDeliveryDate,
  SupplierWhereInput
} from 'generated-types.d';

import {
  MathService,
  TimeService
} from 'lib';

import {
  getTotalShippingEstimate,
  getTotalQuantity,
  getTotalPrice,
  getSalesTax,
  getListItemIncrement
} from 'features/lists/components/checkout-list/checkout-list.helpers';
import {
  CheckoutListGroupType,
  CheckoutListTableGroup
} from 'features/lists/components/checkout-list/checkout-list.types';
import { SuppliersService } from 'features/suppliers/services';

import { CheckoutListStoreService } from './checkout-list-store.service';

export type GroupedCheckoutListItems = {
  [key in CheckoutListGroupType]: ListItemUnion[];
}

export default class CheckoutListStore {
  constructor() {
    makeObservable(this, {
      list: observable,
      suppliers: observable,
      deliveryDates: observable,
      isLoadingSuppliers: observable,
      availability: observable,
      isCheckingAvailability: observable,
      isLoadingDeliveryDates: observable,
      deliveryDate: observable,
      listItemIdsBeingEdited: observable,
      isUpdatingSupplier: observable,
      setDeliveryDate: action,
      clearList: action,
      updateList: action,
      checkAvailability: action,
      toggleIsUpdatingSupplier: action,
      listItemGroups: computed.struct,
      basketItems: computed,
      listSupplierId: computed
    });
  }

  public list: List | null = null;

  public suppliers: Supplier[] = [];

  public deliveryDates: WholesalePreOrderDeliveryDate[] = [];

  public availability: boolean = false;

  public isCheckingAvailability: boolean = true;

  public isLoadingSuppliers: boolean = true;

  public isUpdatingSupplier: boolean = false;

  public isLoadingDeliveryDates: boolean = false;

  public deliveryDate: string = '';

  public listItemIdsBeingEdited: Set<string> = new Set();

  get basketItems(): ListItemUnion[] {
    return (this.list?.items || []).map(item => {
      const increment = getListItemIncrement(item);

      return {
        ...item,
        quantity: MathService.roundToNonZeroInt(item.quantity || 1, increment)
      };
    });
  }

  get listSupplierId(): string | null {
    return this?.list?.suppliers?.[0]?.id || null;
  }

  get groupedBasketItems(): GroupedCheckoutListItems {
    return (this.list?.items || []).reduce<GroupedCheckoutListItems>((acc, curr): GroupedCheckoutListItems => {
      const isValid = this.isListItemTypeSupportedForSupplier(curr);
      const key: CheckoutListGroupType = isValid ? 'valid' : 'invalid';
      const increment = getListItemIncrement(curr);

      return {
        ...acc,
        [key]: [
          ...acc[key],
          {
            ...curr,
            quantity: MathService.roundToNonZeroInt(curr.quantity || 1, increment)
          }
        ]
      };
    }, {
      valid: [],
      invalid: []
    });
  }

  get listItemGroups(): CheckoutListTableGroup[] {
    const groupedItems = this.groupedBasketItems;
    const totalPrice = groupedItems?.valid ? getTotalPrice(groupedItems?.valid) : { min: 0, max: 0 };
    const merchantCurrency = this.list?.merchant?.currency;
    const salesTax = getSalesTax(totalPrice.min, totalPrice.max, merchantCurrency);
    const totalShipping = groupedItems?.valid ? getTotalShippingEstimate(groupedItems?.valid, this.list?.supplierDeliveryConfigs, merchantCurrency) : 0;

    return [
      {
        id: 'valid',
        type: 'valid',
        hasTotalSummary: true,
        listItems: groupedItems?.valid,
        totalShipping: totalShipping,
        totalPrice: totalPrice,
        salesTax: salesTax,
        totalQuantity: groupedItems?.valid ? getTotalQuantity(groupedItems?.valid) : 0,
        grandTotal: {
          min: totalPrice.min + salesTax.min + (totalShipping ?? 0),
          max: totalPrice.max + salesTax.max + (totalShipping ?? 0)
        }
      },
      {
        id: 'invalid',
        type: 'invalid',
        hasTotalSummary: false,
        listItems: groupedItems?.invalid,
        totalQuantity: 0
      }
    ];
  }

  /**
   * @param deliveryDate - date string in YYYY-MM-DD format
   */
  public setDeliveryDate = (deliveryDate: string): void => {
    this.deliveryDate = deliveryDate;
  }

  public toggleIsUpdatingSupplier = (isUpdating: boolean = !this.isUpdatingSupplier): void => {
    this.isUpdatingSupplier = isUpdating;
  }

  public clearList = (): void => {
    this.list = null;
    this.deliveryDate = '';
    this.suppliers = [];
    this.deliveryDates = [];
    this.availability = false;
    this.listItemIdsBeingEdited = new Set();
    this.isCheckingAvailability = true;
    this.isLoadingSuppliers = true;
    this.isLoadingDeliveryDates = true;
    this.isUpdatingSupplier = false;
  };

  public updateList = async ({ id, data } : { id: string; data: ListUpdateInput }): Promise<void> => {
    try {
      const updatedList = await CheckoutListStoreService.updateList({ id, data });

      runInAction(() => {
        this.list = updatedList;
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public updateListItem = async ({ item, updateInput }: {
    item: ListItem;
    updateInput: ListItemUpdateWithoutListDataInput;
  }): Promise<void> => {
    const data: ListUpdateInput = {
      items: {
        update: [{
          where: {
            id: item.id
          },
          data: {
            ...updateInput,
            type: item.type
          }
        }]
      }
    };

    try {
      if (!item.list?.id) return;

      this.listItemIdsBeingEdited.add(item.id);
      const result = await CheckoutListStoreService.updateListItem({
        listId: item.list.id,
        listItemId: item.id,
        data: data
      });

      const updatedListItem = result.items?.find?.(listItem => listItem.id === item.id);

      if (updatedListItem) {
        this.replaceListItem(updatedListItem);
        this.listItemIdsBeingEdited.delete(item.id);
      }
    } catch (error) {
      this.listItemIdsBeingEdited.delete(item.id);

      return Promise.reject(error);
    }
  };

  public checkAvailability = async ({ listId } : { listId: string }): Promise<List> => {
    runInAction(() => {
      this.isCheckingAvailability = true;
    });

    try {
      const result = await CheckoutListStoreService.fetchList(listId);

      runInAction(() => {
        this.list = result;
        this.deliveryDate = this.list?.date || this.deliveryDate;
        this.availability = true;
        this.isCheckingAvailability = false;
      });

      return result;
    } catch (error) {
      runInAction(() => {
        this.isCheckingAvailability = false;
      });

      return Promise.reject(error);
    }
  };

  private replaceListItem = (updatedItem: ListItem): void => {
    const findItemIndex = this.list?.items?.findIndex?.(listItem => listItem.id === updatedItem.id);

    if (typeof findItemIndex === 'number' && findItemIndex !== -1 && !!this.list?.items?.length) {
      this.list.items[findItemIndex] = updatedItem;
    }
  };

  public fetchSuppliers = async (where?: SupplierWhereInput): Promise<void> => {
    if (this.suppliers.length) return;

    runInAction(() => {
      this.isLoadingSuppliers = true;
    });

    try {
      const suppliers = await SuppliersService.fetchSuppliers(where);

      runInAction(() => {
        this.suppliers = suppliers;
        this.isLoadingSuppliers = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingSuppliers = false;
      });

      return Promise.reject(error);
    }
  };

  public fetchDeliveryDates = async ({ listId } : { listId: string }): Promise<void> => {
    runInAction(() => {
      this.isLoadingDeliveryDates = true;
    });

    try {
      const result = await CheckoutListStoreService.getDeliveryDates(listId);

      runInAction(() => {
        this.deliveryDates = result;
        this.isLoadingDeliveryDates = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingDeliveryDates = false;
      });

      return Promise.reject(error);
    }
  };

  public createWholesalePreOrder = async (): Promise<Pick<WholesalePreOrder, 'id'>> => {
    try {
      return CheckoutListStoreService.createWholesalePreOrder({
        deliveryDate: moment.utc(this.deliveryDate).format(TimeService.FLOOMX_TIMESTAMP_STRING_FORMAT),
        list: {
          id: this.list!.id
        },
        listItemsMetadata: this.groupedBasketItems.valid.map(item => ({
          listItemId: item.id,
          quantity: item.quantity!
        })),
        merchant: {
          id: this.list?.merchant?.id
        }
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  private isListItemTypeSupportedForSupplier = (item: ListItemUnion): boolean => {
    return this.list?.supplierDeliveryConfigs[0]?.listItemTypes?.length === 0 || !!this.list?.supplierDeliveryConfigs[0]?.listItemTypes?.includes(item.type);
  };
}

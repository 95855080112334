import store from 'stores';

import {
  DraftWholesaleOrderCreateInput,
  Maybe,
  Mutation,
  MutationUpdateWholesalePreOrderArgs,
  Query,
  WholesaleOrder,
  WholesalePreOrder,
  WholesalePreOrderWhereUniqueInput
} from 'generated-types.d';

import { GraphQL } from 'lib';

import {
  UPDATE_WHOLESALE_PRE_ORDER_ITEM,
  CANCEL_WHOLESALE_PRE_ORDER,
  CREATE_DRAFT_WHOLESALE_ORDER
} from 'features/wholesale/graphql/mutators/pre-order/pre-order';
import {
  SINGLE_WHOLESALE_PRE_ORDER
} from 'features/wholesale/graphql/queries/pre-order/pre-order-queries';

export class SelectedWholesalePreOrderService {
  public static fetchPreOrder = async (id: string): Promise<Maybe<WholesalePreOrder> | undefined> => {
    const where: WholesalePreOrderWhereUniqueInput =  { id };

    if (!!store.merchantStore?.merchant) {
      where.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<Pick<Query, 'wholesalePreOrder'>>(SINGLE_WHOLESALE_PRE_ORDER, { where });

      return result.data.wholesalePreOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static fetchPreOrderTotals = async (id: string): Promise<Maybe<Pick<WholesalePreOrder, 'totals'>> | undefined> => {
    const where: WholesalePreOrderWhereUniqueInput =  { id };

    if (!!store.merchantStore?.merchant) {
      where.merchant = {
        id: store.merchantStore.merchant.id
      };
    }

    try {
      const result = await GraphQL.query<Pick<Query, 'wholesalePreOrder'>>(SINGLE_WHOLESALE_PRE_ORDER, { where });

      return result.data.wholesalePreOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static updatePreOrderItem = async ({
    preOrderItemId,
    data
  }: {
    preOrderItemId: string;
    data: MutationUpdateWholesalePreOrderArgs;
  }): Promise<Maybe<WholesalePreOrder> | undefined> => {
    try {
      const result = await GraphQL.query<Pick<Mutation, 'updateWholesalePreOrder'>>(UPDATE_WHOLESALE_PRE_ORDER_ITEM, { ...data, preOrderItemId });

      return result.data.updateWholesalePreOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static cancelPreOrder = async (preOrderId: string): Promise<Maybe<WholesalePreOrder> | undefined> => {
    const where: WholesalePreOrderWhereUniqueInput = {
      id: preOrderId
    };

    try {
      const result = await GraphQL.query<Pick<Mutation, 'cancelWholesalePreOrder'>>(CANCEL_WHOLESALE_PRE_ORDER, { where });

      return result.data.cancelWholesalePreOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static createDraftOrder = async (preOrder: WholesalePreOrder): Promise<Maybe<WholesaleOrder> | undefined> => {
    const data: DraftWholesaleOrderCreateInput = {
      preOrder: {
        connect: {
          id: preOrder.id
        }
      },
      merchant: {
        connect: {
          id: preOrder.merchant.id
        }
      }
    };

    try {
      const result = await GraphQL.query<Pick<Mutation, 'createDraftWholesaleOrder'>>(CREATE_DRAFT_WHOLESALE_ORDER, { data });

      return result.data.createDraftWholesaleOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

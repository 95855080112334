import { Fragment, FC, memo, useState } from 'react';

import styled from '@emotion/styled';
import { Link } from '@reach/router';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex, Link as StyledLink } from 'rebass';

import { StringFormat } from 'lib';

import ModalStore from 'stores/modal-store/modal-store';
import DraftWholesaleOrderStore from 'stores/wholesale/draft-wholesale-order-store/draft-wholesale-order-store';

import {
  colors,
  textStyles
} from 'utils/rebass-theme';

import { DateRangeModalData } from 'features/modal-dialogue/components/modals/date-range-modal/date-range-modal.types';
import { SupplierCard } from 'features/suppliers/components/supplier-card/supplier-card';

import Icon from 'components/icon';

interface ManageOrderHeaderProps {
  draftWholesaleOrdersStore?: DraftWholesaleOrderStore;
  modalStore?: ModalStore;
}

const DeliveryDate: any = styled(StyledLink)`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

export const ManageOrderHeader: FC<ManageOrderHeaderProps> = inject((stores: FxStores) => ({
  draftWholesaleOrdersStore: stores.draftWholesaleOrderStore,
  modalStore: stores.modalStore
}))(observer(({
  draftWholesaleOrdersStore,
  modalStore
}) => {
  if (!draftWholesaleOrdersStore!.selectedOrder) return null;

  const [controlledDeliveryDate, setControlledDeliveryDate] = useState(draftWholesaleOrdersStore!.selectedOrder.deliveryDetails?.[0]?.deliveryDate);

  const DeliveryDetails = memo<{ deliveryDate?: string }>(({
    deliveryDate
  }) => {
    return (
      <Box
        fontSize="14px"
        mt="8px"
      >
        Due for delivery on <DeliveryDate onClick={openDeliveryDateModal}>{moment(deliveryDate).format('ddd Do MMM')}</DeliveryDate>
      </Box>
    );
  });

  const openDeliveryDateModal = (): void => {
    const disabledDays = [
      {
        before: new Date(moment().utc().subtract(14, 'days').toString())
      }
    ];

    modalStore!.triggerModal<DateRangeModalData>({
      modalType: 'dateRange',
      data: {
        title: 'Set delivery date',
        errorCopy: 'setting delivery date',
        formOption: 'single_day',
        fromCopy: 'Date',
        timeoutCopy: '',
        shouldDisplayMerchantSelection: false,
        fromDate: draftWholesaleOrdersStore!.selectedOrder!.deliveryDetails?.[0]?.deliveryDate,
        disabledDays: disabledDays,
        shouldAutofocus: true,
        initialMerchantId: draftWholesaleOrdersStore!.selectedOrder!.merchant.id,
        onConfirm: async (newDeliveryDate: string): Promise<void> => {
          setControlledDeliveryDate(newDeliveryDate);

          try {
            await draftWholesaleOrdersStore?.updateOrder({
              where: {
                id: draftWholesaleOrdersStore!.selectedOrder!.id
              },
              data: {
                deliveryDetails: {
                  update: {
                    deliveryDate: newDeliveryDate
                  }
                }
              }
            });
          } catch (error) {
            return Promise.reject(error);
          }
        }
      }
    });
  };

  return (
    <Fragment>
      <Box p="60px 0 15px 0">
        <Link
          to={NavPages.WholesaleOrders}
          css={{
            fontSize: '14px',
            paddingBottom: '8px',
            display: 'block'
          }}
        >
          <Flex alignItems="center">
            <Box
              pr="7px"
              color={colors.floomMidnightBlue}
              css={{
                height: '13px'
              }}
            >
              <Icon
                iconName="arrow-full-left"
                size={14}
              />
            </Box>
            <Box color={colors.floomMidnightBlue}>
              Back to all orders
            </Box>
          </Flex>
        </Link>
        <Box css={textStyles.h2}>
          Draft Order #{StringFormat.truncate(draftWholesaleOrdersStore!.selectedOrder.wholesaleOrderRef || '', 7, '')}
        </Box>
        <DeliveryDetails
          deliveryDate={controlledDeliveryDate}
        />
      </Box>
      <Box mt="20px">
        <SupplierCard renderSupplierName={() => draftWholesaleOrdersStore!.selectedOrder?.supplier?.name} />
      </Box>
    </Fragment>
  );
}));

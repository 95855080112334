import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box } from 'rebass';

import { fontSizes } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './no-results-generic.styles';
import * as Types from './no-results-generic.types';

const NO_RESULTS_CONFIG: { [key in Types.NoResultsSize]: Types.NoResultsConfig } = {
  normal: {
    mediaSpacing: '40px',
    headingSpacing: '8px',
    fontSize: `${fontSizes.base}px`,
    boxPadding: '35px'
  },
  small: {
    mediaSpacing: '30px',
    headingSpacing: '4px',
    fontSize: `${fontSizes.small}px`,
    boxPadding: '25px 45px'
  },
  xsmall: {
    mediaSpacing: '20px',
    headingSpacing: '4px',
    fontSize: `${fontSizes.small}px`,
    boxPadding: '15px 25px'
  }
};

class NoResultsGeneric extends Component<Types.NoResultsGenericProps> {
  static defaultProps: Partial<Types.NoResultsGenericProps> = {
    icon: 'backpack',
    image: null,
    size: 'normal',
    copy: '',
    isFullWidth: false,
    imageStyles: null
  };

  render(): ReactNode {
    const config = NO_RESULTS_CONFIG[this.props.size];

    return(
      <Styles.Container
        config={config}
        width={this.props.isFullWidth ? '100%' : 'auto'}
      >
        <Box
          mr={config.mediaSpacing}
          width={this.props.image ? '110px' : '80px'}
          css={this.props.imageStyles}
        >
          { this.props.image
            ? <img src={this.props.image} />
            : <Icon iconName={this.props.icon} />
          }
        </Box>
        <Box
          css={css`
            text-align: left;
          `}
        >
          <Styles.Heading config={config}>
            {this.props.heading}
          </Styles.Heading>
          <Styles.Message config={config}>
            {this.props.copy}
          </Styles.Message>
        </Box>
      </Styles.Container>
    );
  }
}

export default NoResultsGeneric;

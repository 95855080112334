const addMerchantWhereFilter = (merchantId: string | null): string => {
  if (!merchantId) return '';

  return `
    merchant: {
      id: $merchantId
    }
  `;
};

const addVariable = (shouldAdd: boolean, variableName: string, type: string): string => {
  if (!shouldAdd) return '';

  return `$${variableName}: ${type}`;
};

const addFilter = (shouldAdd: boolean, filterName: string, value: string | boolean): string => {
  if (!shouldAdd) return '';

  return `${filterName}: ${value}`;
};

const searchableContains = (value: string): string => {
  return `${!!value && value.length ?
    `AND: [${searchableContainsValues(value)}]`
    : ''}
  `;
};

export const splitSearchableValue = (value: string): string[] => {
  return value
    .toLowerCase()
    .trim()
    .match(/\w+|"[^"]+"/g) || [];
};

const searchableContainsValues = (value: string): string => {
  return `${!!value && value.length ?
    `${splitSearchableValue(value)!
      .map((splitString: any): string => {
        return `
            {
              searchable_contains: "${splitString.replace(/['"]+/g, '').trim()}"
            }
          `;
      })}
    ` : ''}
  `;
};

export const upperCaseFirstLetter = (word: string): string => {
  return word && word.length ? word.charAt(0).toUpperCase() + word.toLowerCase().slice(1) : '';
};

export {
  addMerchantWhereFilter,
  addFilter,
  addVariable,
  searchableContains,
  searchableContainsValues
};

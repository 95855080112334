import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { Card } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const DataCountWrapper: any = styled(Flex)`
  ${Card};
  margin: 10px 0;
  padding: 20px;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    padding: 0;
  }
`;

export const CountBox: any = styled(Box)`
  padding: 5px;
  text-align: center;
  flex-grow: 1;
  transition: color .2s ease-in-out;

  @media (max-width: 1200px) {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
  }

  h1 {
    ${textStyles.h2};
    color: ${({ numbercolor }): string => numbercolor || colors.floomMidnightBlue};
    transition: opacity .2s ease-in-out;

    @media (max-width: 1200px) {
      font-size: 20px;
    }
  }

  > h5 {
    transition: opacity .2s ease-in-out;
    @media (min-width: 1201px) {
      margin-bottom: 20px;
    }
  }

  &:hover {
    h1, h5 {
      opacity: ${({ to }): number => to ? 0.7 : 1};
    }
  }

  + ${(): any => CountBox} {
    border-left: 1px solid ${colors.middleGrey};

    @media (max-width: 1200px) {
      border-left: none;
      border-top: 1px solid ${colors.middleGrey};
    }
  }
`;

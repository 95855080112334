import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Flex } from 'rebass';

import { breakpoints, colors, space, textStyles } from 'utils/rebass-theme';

export const BackLink = styled(Flex)`
  ${textStyles.footnote};
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-left: 5px;
`;

export const TitleInput = styled.input`
  ${textStyles.h2};
  color: ${colors.floomMidnightBlue};
  width: 100%;
  height: 40px;
  resize: none;
  overflow: hidden;
  outline: none;
  box-shadow: none;
  padding: 0;

  &:focus {
    color: ${hex2rgba(colors.floomMidnightBlue, .8)};
  }

  @media only screen and (max-width: ${breakpoints.medium}) {
    padding-bottom: ${space[2]}px;
  }
`;

export const SubheaderButtonGroup = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  width: auto;
  font-size: 14px;
  margin-top: ${space[2]}px;
  cursor: pointer;

  @media only screen and (max-width: ${breakpoints.medium}) {
    flex-wrap: wrap;
  }
`;

export const MetaButton = styled.button<{ hasValue: boolean; hasError?: boolean }>`
  transition: text-decoration 200ms ease-in-out;
  color: ${({ hasValue, hasError }): string => {
    switch (true) {
      case !!hasError:
        return colors.errorText;

      case !!hasValue:
        return colors.floomMidnightBlue;

      default:
        return colors.grey;
    }
  }};

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: ${breakpoints.medium}) {
    width: 100%;
    text-align: left;
    margin-bottom: ${space[2]}px
  }
`;

export const Dot = styled.div`
  ${textStyles.label};
  color: ${colors.grey};
  margin: 0 5px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    display: none;
  }
`;

export const LinkWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  padding: ${space[3]}px;
  position: relative;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${breakpoints.medium}) {
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  top: ${space[3]}px;
  right: ${space[2]}px;
`;

import { gql } from '@apollo/client';

export const UPDATE_MERCHANT_SAME_DAY_ORDER_PAUSE = gql`
  mutation UpdateMerchantSameDayOrderPauseStatus(
    $data: MerchantUpdateInput!
    $where: MerchantWhereUniqueInput!
  ) {
    updateMerchantSameDayOrderPauseStatus(
      data: $data,
      where: $where
    ) {
      id
      title
      sameDayOrderPauseLimit
      sameDayOrderPauseStatus
    }
  }
`;

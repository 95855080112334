import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './checkbox-icon.styles';
import * as Types from './checkbox-icon.types';

const {
  middleGrey,
  background,
  uiFills
} = colors;

export default class CheckboxIcon extends Component<Types.CheckboxIconProps> {
  static defaultProps = {
    isDisabled: false,
    error: false
  };

  render(): ReactNode {
    const { isSelected, isDisabled, error } = this.props;
    const selectedFill = isSelected ? middleGrey : background;

    return(
      <Styles.DropdownCheckbox
        isSelected={isSelected}
        isDisabled={isDisabled}
        error={error}
      >
        <Icon
          iconName="tick"
          styles={css`
            ${Styles.TickIconStyles};
            path {
              fill: ${isDisabled ? selectedFill : uiFills};
            }
          `}
        />
      </Styles.DropdownCheckbox>
    );
  }
}

import { Fragment, FunctionComponentElement, ReactNode } from 'react';

import { observer, inject } from 'mobx-react';
import { Box } from 'rebass';

import {
  MerchantStage
} from 'generated-types.d';

import MerchantDetailStore from 'stores/merchant-detail/merchant-detail-store';

import Button from 'components/button';

import { MerchantDetailContext } from '../../../merchant-detail';
import {
  SubCopy
} from '../merchant-detail-content.styles';

interface SubscriptionCompletionButtonProps {
  onClick: () => void;
  merchantDetailStore?: MerchantDetailStore;
}

const SubscriptionCompletionButton = ({
  merchantDetailStore,
  onClick
}: SubscriptionCompletionButtonProps): FunctionComponentElement<SubscriptionCompletionButtonProps> | null => {
  const buttonCopy = (): string => {
    const finishSubscriptionCopy = 'Finish subscription';
    const resubscribeCopy = 'Resubscribe';

    const stageMap: { [key in MerchantStage]: string } = {
      ACTIVE: finishSubscriptionCopy,
      CHURN: resubscribeCopy,
      CREATED: finishSubscriptionCopy,
      DEACTIVATED: resubscribeCopy,
      DORMANT: resubscribeCopy,
      IN_SETUP: finishSubscriptionCopy,
      NEW: finishSubscriptionCopy,
      UNASSIGNED: finishSubscriptionCopy
    };

    return stageMap[merchantDetailStore!.selectedMerchant?.stage!] || finishSubscriptionCopy;
  };

  return (
    <MerchantDetailContext.Consumer>
      {(context): ReactNode => (
        <Fragment>
          <Box
            as="button"
            mt="10px"
            disabled={!context.canInvite}
            onClick={onClick}
          >
            <Button
              copy={buttonCopy()}
              size="xsmall"
              appearance="secondary"
              isParentWidth={false}
              isDisabled={!context.canInvite}
            />
          </Box>
          {!context.canInvite && (
            <SubCopy>
              Merchant is missing some details before payment can be taken
            </SubCopy>
          )}
        </Fragment>
      )}
    </MerchantDetailContext.Consumer>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantDetailStore: stores.merchantDetailStore
}))(observer(SubscriptionCompletionButton));

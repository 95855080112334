import React, { Component } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Auth } from 'lib';

import NavigationStore from 'stores/navigation/navigation-store';
import { NavigationLayout } from 'stores/navigation/navigation-store.types';
import UIStore from 'stores/ui-store';

import { TEST_IDS } from 'utils/test/data-test-ids';

import IconButton from 'components/icon-button';
import Logo from 'components/navigation/components/logo';

import * as Styles from './header.styles';

interface HeaderProps {
  uiStore?: UIStore;
  navigationStore?: NavigationStore;
  hideMenu?: boolean;
}

class Header extends Component<HeaderProps> {
  static defaultProps = {
    hideMenu: false
  };

  private onClickMenuToggle = (): void => {
    if (this.props.navigationStore!.layout === NavigationLayout.Collapsed) {
      this.props.navigationStore!.setLayout(NavigationLayout.Open);
    }

    this.props.uiStore!.toggleMenu();
  };

  render(): React.ReactNode {
    return (
      <Box
        role="banner"
        bg="floomMidnightBlue"
        css={css`
          position: relative;
          z-index: 50;
        `}
      >
        <Styles.Wrapper>
          {Auth.isLoggedIn() && !this.props.hideMenu && (
            <Styles.MenuIconWrapper>
              <IconButton
                onClick={this.onClickMenuToggle}
                iconName="menu"
                testId={TEST_IDS.Navigation.mobile.hamburgerMenu}
              />
            </Styles.MenuIconWrapper>
          )}
          <Styles.LogoWrapper>
            <Link
              to={Auth.isLoggedIn() ? NavPages.Overview : '/'}
              aria-label="navigation home"
              data-testid={TEST_IDS.Navigation.mobile.homeLink}
            >
              <Logo />
            </Link>
          </Styles.LogoWrapper>
        </Styles.Wrapper>
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  uiStore: stores.uiStore
}))(observer(Header));

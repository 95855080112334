import { gql } from '@apollo/client';

export const MERCHANT_PLANS = gql`
  query MerchantPlans(
    $where: MerchantPlanWhereInput
  ) {
    merchantPlans(
      where: $where
    ) {
      id
      type
      title
      stripePlanId
      price
      currency
      description
      features
      productLimit
      orderLimit
    }
  }
`;

import { FC, Fragment } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import pluralize from 'pluralize';
import { Box } from 'rebass';

import { Currency } from 'generated-types.d';

import { colors } from 'utils/rebass-theme';
import { TEST_IDS } from 'utils/test/data-test-ids';

import { ListPriceRange } from 'features/lists/components/list-price-range/list-price-range';
import { FooterWrapper } from 'features/lists/lists.styles';

import Button from 'components/button';

import {
  ButtonWrapper,
  PreOrderButton,
  DeliveryDate,
  DeliveryDateWrapper,
  PlaceOrderWrapper,
  GrandTotalRow,
  TotalRow,
  TotalsTable,
  TotalValueColumn,
  TotalSubCopy,
  TotalToBeCalculatedColumn
} from './checkout-list-footer.styles';
import { CheckoutListFooterProps } from './checkout-list-footer.types';

const CheckoutListFooterComponent: FC<CheckoutListFooterProps> = ({
  group,
  isPlacingPreOrder,
  hasPlacedPreOrder,
  onSelectDeliveryDate,
  onPlacePreOrder,
  promotedListsStore,
  merchantStore,
  hideTopBorder
}) => {
  const list = promotedListsStore?.list;
  const listCurrency = merchantStore?.merchant?.currency;
  const deliveryOption = promotedListsStore!.deliveryConfigs?.[0]?.deliveryOptions?.[0];

  if (!list || !listCurrency || !deliveryOption) {
    return null;
  }

  const renderDeliveryDate = (): string => {
    switch (true) {
      case !!promotedListsStore!.selectedDeliveryDate &&
        !promotedListsStore!.isLoadingData: {
        return moment(promotedListsStore!.selectedDeliveryDate).format('Do MMMM');
      }

      default: {
        return 'Please select';
      }
    }
  };

  const ShippingAndTaxRows: FC = () => {
    if (merchantStore?.merchant?.currency === Currency.Usd) {
      return (
        <Fragment>
          <TotalRow>
            <Box>Shipping</Box>
            <TotalToBeCalculatedColumn>
              To be calculated
            </TotalToBeCalculatedColumn>
          </TotalRow>
          <TotalRow>
            <Box>Sales Tax</Box>
            <TotalToBeCalculatedColumn>
              To be calculated
            </TotalToBeCalculatedColumn>
          </TotalRow>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <TotalRow>
          <Box>Shipping</Box>
          <TotalToBeCalculatedColumn>
            To be calculated
          </TotalToBeCalculatedColumn>
        </TotalRow>
        <TotalRow>
          <Box>VAT</Box>
          <TotalValueColumn>
            <ListPriceRange
              min={group.salesTax?.min}
              max={group.salesTax?.max}
              currency={listCurrency}
            />
          </TotalValueColumn>
        </TotalRow>
      </Fragment>
    );
  };

  const shouldDisabledPlaceOrderButton =
    isPlacingPreOrder ||
    hasPlacedPreOrder ||
    !promotedListsStore!.selectedDeliveryDate ||
    (!promotedListsStore!.basketItems?.length && !promotedListsStore!.customItems?.length);

  return (
    <Fragment>
      <TotalsTable
        css={css`
          opacity: ${promotedListsStore!.isLoadingData ? 0.4 : 1};
        `}
        hideTopBorder={hideTopBorder}
      >
        <ShippingAndTaxRows />
      </TotalsTable>
      <GrandTotalRow sticky={false}>
        <Box fontWeight={600}>Total</Box>
        <TotalValueColumn>
          <Box fontWeight={600}>
            <ListPriceRange
              min={group.grandTotal?.min}
              max={group.grandTotal?.max}
              currency={listCurrency}
            />
          </Box>
          <TotalSubCopy>
            {group.basketItems.length} {pluralize('item', group.basketItems.length)}
            {' | '}
            {group.totalQuantity} {pluralize('unit', group.totalQuantity)}
          </TotalSubCopy>
        </TotalValueColumn>
      </GrandTotalRow>
      <FooterWrapper>
        <DeliveryDateWrapper
          as="button"
          disabled={isPlacingPreOrder || hasPlacedPreOrder}
          onClick={onSelectDeliveryDate}
        >
          <ButtonWrapper
            backgroundColor={colors.white}
            color={colors.floomMidnightBlue}
            isDisabled={isPlacingPreOrder || hasPlacedPreOrder}
          >
            <PreOrderButton>
              Delivery date
              <DeliveryDate>{renderDeliveryDate()}</DeliveryDate>
            </PreOrderButton>
          </ButtonWrapper>
        </DeliveryDateWrapper>
        <PlaceOrderWrapper
          as="button"
          disabled={shouldDisabledPlaceOrderButton}
          onClick={onPlacePreOrder}
          data-testid={TEST_IDS.PromotedLists.checkoutList.PlacePreOrderButton}
        >
          <Button
            size="large"
            isParentWidth={true}
            copy={hasPlacedPreOrder ? 'Pre-order requested' : 'Request pre-order'}
            appearance={hasPlacedPreOrder ? 'success' : 'primary'}
            isDisabled={shouldDisabledPlaceOrderButton}
            isLoading={isPlacingPreOrder}
          />
        </PlaceOrderWrapper>
      </FooterWrapper>
    </Fragment>
  );
};

export const CheckoutListFooter = inject((stores: FxStores): InjectedFxStores => ({
  promotedListsStore: stores.promotedListsStore,
  merchantStore: stores.merchantStore
}))(observer(CheckoutListFooterComponent));

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { NavService, Analytics } from 'lib';

import { Mode, ProductTab } from 'stores/product-edit-store/product-edit-store-types';

import ProductServices from 'features/products/services';
import { CreateStep } from 'features/products/services/product-analytics/product-analytics.service';

import Button from 'components/button';
import FormFooter from 'components/form-footer';

import { CreateLeaveModal } from '../modals/create-leave-modal';

import * as Styles from './footer-content.styles';
import { FooterContentProps } from './footer-content.types';

class FooterContent extends Component<FooterContentProps> {
  ProductAnalytics = ProductServices.ProductAnalytics;

  state = {
    leaveModalOpen: false
  };

  isEdit = (): boolean => this.props.productEditStore!.currentMode === Mode.edit;

  isDeliveryTab = (): boolean => this.props.productEditStore!.currentTab.tabType === ProductTab.delivery;

  swapToNextTab = async (): Promise<void> => {
    this.props.productEditStore!.toggleIsTabbing(true);

    if (this.isEdit() || this.isDeliveryTab()) {
      await this.props.saveAction();
    }

    switch (this.props.productEditStore!.currentTab.tabType) {
      case ProductTab.product:
        this.props.productEditStore!.tabs.recipe.onClick();
        this.trackTabEvent(CreateStep.ProductTab);

        break;

      case ProductTab.recipe:
        this.props.productEditStore!.tabs.delivery.onClick();
        this.trackTabEvent(CreateStep.RecipeTab);

        break;

      case ProductTab.addon:
        this.props.productEditStore!.tabs.delivery.onClick();
        this.trackTabEvent(CreateStep.AddonTab);

        break;
    }
  };

  trackTabEvent = (step: CreateStep): void => {
    if (this.props.productEditStore!.currentMode === Mode.create) {
      this.ProductAnalytics.onCreateStep(
        step,
        Analytics.FxEventName.ProductCreateStep,
        this.props.productEditStore!.selectedProductTypeTitle
      );
    }
  };

  closeLeaveModal = (): void => {
    this.setState({
      leaveModalOpen: false
    });
  };

  attemptToLeave = (): void => {
    if (this.props.isDirty()) {
      this.setState({
        leaveModalOpen: true
      });
    } else {
      NavService.productList({ persist: true });
    }
  };

  getNextButtonCopy = (): string => {
    if (this.props.productEditStore!.currentTab.tabType === ProductTab.delivery) {
      return 'Save and leave';
    }

    return this.props.productEditStore!.currentMode === Mode.create
      ? `Next to ${this.props.productEditStore!.currentTab.nextButtonCopy}`
      : `Save and go to ${this.props.productEditStore!.currentTab.nextButtonCopy}`;
  };

  isTabValid = (): boolean => this.props.productEditStore!.currentMode === Mode.create
    ? !this.props.productEditStore!.currentTab.tabIsValid()
    : !this.props.productEditStore!.editProductValidationPass();

  render(): React.ReactNode {
    return (
      <>
        <FormFooter>
          <Styles.FooterWrapper offset={this.props.navigationStore!.navWidth}>
            <Styles.FooterContent>
              <Styles.NextButtonWrapper onClick={(): void => this.attemptToLeave()}>
                <Button
                  size="normal"
                  appearance="secondary"
                  copy="Leave"
                  isLoading={false}
                  isDisabled={false}
                />
              </Styles.NextButtonWrapper>
              <Styles.NextButtonWrapper
                onClick={(): Promise<void> => this.swapToNextTab()}
                disabled={!this.props.productEditStore!.currentTab.tabIsValid() || this.props.productEditStore!.isTabbing}
              >
                <Button
                  size="normal"
                  appearance="primary"
                  copy={this.getNextButtonCopy()}
                  isLoading={this.props.productEditStore!.isTabbing}
                  isDisabled={this.isTabValid() || this.props.productEditStore!.isTabbing}
                />
              </Styles.NextButtonWrapper>
            </Styles.FooterContent>
          </Styles.FooterWrapper>
        </FormFooter>

        {CreateLeaveModal(
          this.state.leaveModalOpen,
          this.closeLeaveModal
        )}
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  productEditStore: stores.productEditStore
}))(observer(FooterContent));

import { CourierBookingConnection } from 'generated-types';
import store from 'stores';

import { CourierListType, CourierListGroupOption } from 'stores/courier-list/courier-list-store.types';

import { COURIER_LIST_PAGE_LAYOUTS } from '../graphql/helpers/courier-list-group.config';

import CourierListAPIService from './courier-list-api/courier-list-api.service';

class CourierListService {
  private courierListStore = store.courierListStore;

  public onPaginate = (groupName: CourierListGroupOption): void => {
    this.courierListStore.onPaginate(groupName);
    this.paginateBookings(groupName);
  };

  public paginateBookings = (groupName: CourierListGroupOption): void => {
    this.courierListStore.setListLoading();

    CourierListAPIService.paginateBookings(this.buildVariables(), groupName)
      .then((courierBookingConnection: CourierBookingConnection): void => {
        this.courierListStore.appendBookings(courierBookingConnection, groupName);
      });
  };

  public fetchCourierBookings = (shouldDisplayLoader: boolean = true): void => {
    if (shouldDisplayLoader) {
      this.courierListStore.setListLoading(true);
    }
    this.courierListStore.setInitialPagination(COURIER_LIST_PAGE_LAYOUTS[this.courierListStore.listLayout].groups);

    CourierListAPIService.fetchCourierList(this.buildVariables())
      .then((results: CourierListType): void => {
        this.courierListStore.setCourierList(results);
      });
  };

  public buildVariables = (): any => ({
    search: this.courierListStore.searchFilterValue,
    pagination: this.courierListStore.pagination
  });
}

export default new CourierListService();

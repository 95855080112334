import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { textStyles, colors } from 'utils/rebass-theme';

import { ButtonWrapper } from 'components/button/button.styles';

export const StatusActionButton = styled.button<any>`
  width: 100%;

  ${ButtonWrapper} {
    border-radius: unset;
    padding: 15px;
    cursor: default;

    &:hover {
      background: ${colors.floomMidnightBlue};
    }
  }
`;

export const StatusSelectArrow = styled.select`
  ${textStyles.subhead};
  position: relative;
  appearance: none;
  display: block;
  height: 50px;
  border-top: 2px solid ${colors.middleGrey};
  background: ${colors.uiFills};
  border-radius: 0;
  width: 100%;
  padding: 0 40px 0 15px;

  &:focus {
    outline: none;
  }
`;

export const DropdownArrow = css`
  position: absolute;
  right: 15px;
  top: 50%;
  pointer-events: none;
  transform: translateY(-2px);
`;

export const DropdownLabel = styled.div`
  ${textStyles.body};
  margin-right: 10px;
`;

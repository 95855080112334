import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import debounce from 'lodash.debounce';
import { Box } from 'rebass';

import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType, TableLayoutEntityConfigProps } from 'components/entity-list/table-layout-entity.types';
import FieldText from 'components/field-text';
import NoResultsGeneric from 'components/no-results-generic';

import * as Styles from './generic-select-list.styles';
import * as Types from './generic-select-list.types';

export default class GenericSelectList extends Component<Types.GenericSelectListProps, Types.GenericSelectListState> {
  state: Types.GenericSelectListState = {
    listObjects: this.props.listObjects,
    isLoading: false,
    filter: ''
  };

  handleAsyncSearch = debounce(async () => {
    if (!!this.props.onAsyncSearch) {
      this.setState({ isLoading: true });
      await this.props.onAsyncSearch?.(this.state.filter);
      this.setState({ isLoading: false });
    }
  }, 300);

  private onSearch = async (value: string): Promise<void> => {
    if (!!this.props.onAsyncSearch) {
      this.handleAsyncSearch();
    }

    this.setState({
      filter: value
    }, () => {
      if (!this.props.onAsyncSearch) {
        this.setState({
          listObjects: this.props.listObjects.filter(listObject => listObject.title.toLowerCase().includes(value.toLowerCase()))
        });
      }
    });
  };

  private multiSelectCellConfig = (item: Types.GenericListObject): TableLayoutEntityConfigProps[] => {
    const copyStyles = css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `;

    let baseConfig = [
      {
        cellType: this.props.multiSelect ? CellType.CheckboxIcon : CellType.RadioIcon,
        fixedWidth: '50px',
        customCss: css`
          padding-left: 20px
        `,
        props: {
          isSelected: this.props.multiSelect
            ? this.props.alreadySelectedIds.includes(item.id)
            : !!item.selected
        }
      },
      {
        cellType: CellType.Image,
        props: {
          path: item.mediaSource
        }
      },
      {
        cellType: CellType.Text,
        customCss: copyStyles,
        fixedWidth: !item.details ? '240px' : '120px',
        props: {
          title: item.title
        }
      }
    ];

    if (item.details) {
      baseConfig = [
        ...baseConfig,
        {
          cellType: CellType.Text,
          customCss: copyStyles,
          fixedWidth: '120px',
          props: {
            text: item.details
          } as any
        }
      ];
    }

    return baseConfig;
  };

  private buildItems = (): Types.GenericListObject[] => {
    if (this.props.onAsyncSearch) {
      return this.props.listObjects;
    }

    if (!this.props.multiSelect) {
      return this.state.listObjects.filter(listObject => !this.props.alreadySelectedIds.includes(listObject.id));
    }

    return this.state.listObjects;
  };

  render(): ReactNode {
    const items = this.buildItems();

    return (
      <Styles.Content>
        {!this.props.noSearch && (
          <Styles.SearchBox>
            <FieldText
              size="normal"
              value={this.state.filter}
              onChange={async (event): Promise<void> => this.onSearch(event.target.value)}
              iconType="search"
              placeholder="Search"
              isSpellCheckEnabled={true}
              autoFocus={true}
            />
          </Styles.SearchBox>
        )}
        {!!items?.length && (
          <Styles.ItemsWrapper>
            <Styles.Items isLoading={this.state.isLoading}>
              {items.map(item => (
                <Styles.Item
                  key={item.id}
                  onClick={(): void => this.props.onSelect(item.id)}
                >
                  <TableLayoutEntity config={this.multiSelectCellConfig(item)} />
                </Styles.Item>
              ))}
            </Styles.Items>
          </Styles.ItemsWrapper>
        )}
        {!items?.length && (
          <Box p="10px 20px">
            <NoResultsGeneric
              icon="leaf-no-results"
              heading="No results found"
              size="small"
            />
          </Box>
        )}
      </Styles.Content>
    );
  }
}

import { Component, ReactNode } from 'react';

import { Box } from 'rebass';

import { subscribeEvent, unsubscribeEvent } from 'utils/event';

import FormBuilder from 'components/form-builder';
import GenericModal from 'components/generic-modal';

import * as Styles from './field-edit-modal.styles';
import * as Types from './field-edit-modal.types';

class FieldEditModal extends Component<Types.FieldEditModalProps> {
  state = {
    isLoading: false
  };

  componentDidMount(): void {
    if (this.props.data.onMount) {
      this.setState({ isLoading: true });

      this.props.data.onMount().then(() => {
        this.setState({ isLoading: false });
      });
    }
    subscribeEvent('updateModal', this.updateModal);
  }

  componentWillUnmount(): void {
    unsubscribeEvent('updateModal', this.updateModal);
  }

  private updateModal = (): void => {
    this.forceUpdate();
  }

  private confirmButtonAction = async (): Promise<void> => {
    try {
      this.setState({ isLoading: true });
      await this.props.data.confirmButtonAction();
      this.setState({ isLoading: false });
      this.props.closeModal();
      this.forceUpdate();
    } catch (e) {
      this.setState({ isLoading: false });
      this.forceUpdate();
    }
  };

  private closeModal = (): void => {
    this.props.closeModal();

    if (this.props.data.onClose) {
      this.props.data.onClose();
    }
  };

  render(): ReactNode {
    return (
      <GenericModal
        title={this.props.data.title}
        closeModal={this.closeModal}
        modalOpen={this.props.isOpen}
        confirmButtonText={this.props.data.buttonCopy || 'Save changes'}
        confirmButtonAction={this.confirmButtonAction}
        confirmButtonDisabled={!this.props.data.isDirty()}
        isButtonLoading={this.state.isLoading}
        width={this.props.data.width || 430}
        innerComponent={(
          <Styles.Container>
            <Box>
              <form
                onChange={(): void => {
                  this.forceUpdate();
                }}
              >
                <FormBuilder
                  config={this.props.data.config()}
                />
              </form>
            </Box>
          </Styles.Container>
        )}
      />
    );
  }
}

export default FieldEditModal;

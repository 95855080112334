import { FC, useEffect, useState } from 'react';

import hex2rgba from 'hex2rgba';
import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ListType, WholesalePreOrder, WholesalePreOrderStatus } from 'generated-types.d';

import { Analytics } from 'lib';

import { Container } from 'utils/css-mixins';
import { colors } from 'utils/rebass-theme';

import { ListsBlob } from 'features/lists/lists.styles';
import { ListLayoutType } from 'features/lists/lists.types';

import { LoadingImage } from 'components/loading-image/loading-image';
import WithLoading from 'components/with-loading';

import PlantsIconImage from '../../../../assets/images/wholesale/no-results.gif';

import { CannotFindPreOrder } from './components/cannot-find-pre-order';
import { SinglePreOrderBody } from './components/single-pre-order-body';
import { SinglePreOrderHeader } from './components/single-pre-order-header';
import { SingleWholesalePreOrderContext } from './single-pre-order.context';
import { SingleWholesalePreOrderProps } from './single-pre-order.types';

const blobColours: { [key in WholesalePreOrderStatus | 'Default']: string } = {
  Cancelled: colors.errorBg,
  Complete: hex2rgba(colors.middleGrey, .2),
  Open: colors.floomYellow,
  Processing: hex2rgba(colors.emeral, .2),
  Default: colors.floomYellow
};

export const SingleWholesalePreOrder: FC<SingleWholesalePreOrderProps> = inject((stores: FxStores) => ({
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore,
  modalStore: stores.modalStore
}))(observer(({
  preOrderId,
  modalStore,
  selectedWholesalePreOrderStore
}) => {
  const [isEditingPreOrder, setIsEditingPreOrder] = useState(false);
  const listType: ListLayoutType = selectedWholesalePreOrderStore!.preOrder?.items?.[0]?.listItem?.promotedTradeSkuAvailability ? ListType.Promoted : 'Default';

  const isPreOrderEditable = (): boolean => {
    return selectedWholesalePreOrderStore!.preOrder?.status === WholesalePreOrderStatus.Open
      && !selectedWholesalePreOrderStore!.preOrder?.wholesaleOrder;
  };

  useEffect(() => {
    fetchPreOrder();
    Analytics.page(Analytics.FxPageEvent.WholesalePreOrderDetail);

    return (): void => {
      selectedWholesalePreOrderStore!.clearPreOrder();
    };
  }, []);

  useEffect(() => {
    // @ts-ignore
    const preOrderObserver = observe<WholesalePreOrder>(selectedWholesalePreOrderStore!, 'preOrder', ({
      oldValue,
      newValue
    }: { oldValue: WholesalePreOrder; newValue: WholesalePreOrder }) => {
      if (!oldValue && !!newValue && selectedWholesalePreOrderStore!.isNewPreOrder) {
        modalStore!.triggerModal({
          modalType: 'preOrderConfirmation',
          data: {
            isPreOrderEditable: isPreOrderEditable()
          }
        });
      }
    });

    return (): void => {
      preOrderObserver();
    };
  }, []);

  const fetchPreOrder = async (): Promise<void> => {
    if (preOrderId) {
      selectedWholesalePreOrderStore!.fetchPreOrder({ preOrderId });
    }
  };

  const toggleEditingState = async (): Promise<void> => {
    setIsEditingPreOrder(prevState => !prevState);
  };

  const isPreOrderActive = (() => {
    return selectedWholesalePreOrderStore!.preOrder?.status === WholesalePreOrderStatus.Open
      || selectedWholesalePreOrderStore!.preOrder?.status === WholesalePreOrderStatus.Processing;
  })();

  return (
    <SingleWholesalePreOrderContext.Provider
      value={{
        isEditingPreOrder: isEditingPreOrder,
        isPreOrderEditable: isPreOrderEditable(),
        isPreOrderActive: isPreOrderActive,
        listType: listType,
        fetchPreOrder: fetchPreOrder,
        toggleEditingState: toggleEditingState
      }}
    >
      <WithLoading
        loaderSize="small"
        marginTop="30px"
        isLoading={selectedWholesalePreOrderStore!.isLoadingPreOrder}
        hasNoResults={!selectedWholesalePreOrderStore!.isLoadingPreOrder && !selectedWholesalePreOrderStore!.preOrder}
        renderNoResults={CannotFindPreOrder}
        renderLoading={() => (
          <LoadingImage
            text="Loading pre-order..."
            imageSrc={PlantsIconImage}
            imageAlt="plants"
          />
        )}
      >
        <ListsBlob
          fill={blobColours[selectedWholesalePreOrderStore!.preOrder?.status || 'Default']}
        />
        <Container>
          <Box mb="60px">
            <SinglePreOrderHeader />
            <SinglePreOrderBody />
          </Box>
        </Container>
      </WithLoading>
    </SingleWholesalePreOrderContext.Provider>
  );
}));

import { Component, Fragment } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Heading, Flex, Box } from 'rebass';

import { MerchantService, NavService } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import { OrdersStore } from 'stores/orders/orders-store.types';

import { Container } from 'utils/css-mixins';
import { colors, fontStacks, textStyles } from 'utils/rebass-theme';

import OrderPauseBanner from 'features/order-pause-banner';
import OrderChecks from 'features/orders/components/order-checks';
import { OrderListConfig } from 'features/orders/graphql/helpers/order-group.config';

import Icon from 'components/icon';

import * as Styles from './list-heading.styles';

interface OrdersListHeadingProps {
  ordersStore?: OrdersStore;
  merchantStore?: MerchantStore;
  config: OrderListConfig;
}

class OrderListHeading extends Component<OrdersListHeadingProps> {
  private buildSubheading = (subHeading: string): string => {
    if (RegExp(/\[COUNT\]/g).test(subHeading)) {
      return subHeading
        .replace(/\[COUNT\]/g, this.calculateOpenOrderCount());
    }

    return subHeading;
  };

  private calculateOpenOrderCount = (): any => {
    return Object.keys(this.props.ordersStore!.orderList).reduce((accGroupCount, currGroup) => {
      if (!['delivered', 'cancelled'].includes(currGroup)) {
        return accGroupCount + this.props.ordersStore!.orderList[currGroup].aggregate.count;
      }

      return accGroupCount;
    }, 0);
  };

  render(): JSX.Element {
    return (
      <Fragment>
        <Styles.ListHeading>
          <Container>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb="10px"
            >
              <Box>
                <Flex
                  as="button"
                  alignItems="center"
                  onClick={(): void => NavService.orders()}
                  css={textStyles.headline}
                >
                  <Flex
                    mt="3px"
                    mr="10px"
                  >
                    <Icon
                      iconName="arrow-full-left"
                      styles={Styles.BackArrow}
                    />
                  </Flex>
                  <Box css={css`
                    line-height: 1.5;
                    font-size: 14px;
                    font-family: ${fontStacks.secondary};
                    font-weight: 700;
                    color: ${colors.floomMidnightBlue};
                  `}
                  >
                    Orders Home
                  </Box>
                </Flex>
              </Box>
            </Flex>
            <Styles.Header>
              <Box
                mr="20px"
              >
                <Heading
                  as="h1"
                  css={css`
                    line-height: 1.2;
                    font-size: ${this.props.config.heading === `Today's Orders` ? '25px' : '38px'};
                    font-family: ${fontStacks.primary};
                    font-weight: 700;
                  `}
                >
                  {this.props.config.heading}
                </Heading>
                {(this.props.config.heading === `Today's Orders` && MerchantService.hasOrderPauseSupport(this.props.merchantStore!.merchant)) && (
                  <OrderPauseBanner />
                )}
                {this.props.config.subHeading && (
                  <Heading
                    as="h2"
                    mt="10px"
                    css={css`
                      line-height: 1.2;
                      font-size: 18px;
                      font-family: ${fontStacks.primary};
                      font-weight: 700;
                    `}
                  >
                    {this.buildSubheading(this.props.config.subHeading)}
                  </Heading>
                )}
              </Box>
              {(this.props.config.showChecks && this.props.merchantStore!.merchant) && (
                <Styles.OrderCheckContainer>
                  <OrderChecks />
                </Styles.OrderCheckContainer>
              )}
            </Styles.Header>
          </Container>
        </Styles.ListHeading>
      </Fragment>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  merchantStore: stores.merchantStore
}))(observer(OrderListHeading));

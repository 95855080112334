import { ApolloQueryResult } from '@apollo/client';
import slugify from 'slugify';
import store from 'stores';

import {
  AddonCreateInput,
  AddonUpdateInput,
  AddonCategory,
  Addon,
  ProductConnection,
  ProductEdge
} from 'generated-types.d';

import { GraphQL, NavService } from 'lib';

import AddOnEditStore from 'stores/add-on-edit-store/add-on-edit-store';
import { Mode } from 'stores/add-on-edit-store/add-on-edit-store.types';
import ToasterStore from 'stores/toaster-store/toaster-store';
import UserStore from 'stores/user/user-store';

import { ADD_ON_CREATE, ADD_ON_EDIT } from 'features/add-ons/graphql/mutations/create-edit-add-on.mutations';
import { SINGLE_ADD_ON } from 'features/add-ons/graphql/queries/add-ons.queries';
import { ADD_ONS_PRODUCTS_QUERY } from 'features/add-ons/graphql/queries/create-edit-add-on.queries';
import AddOnsAPIService from 'features/add-ons/services/add-ons-api/add-ons-api.service';

import AddOnCreateEditService from './add-on-create-edit.service';

export default class AddOnCreateEditApolloService implements AddOnCreateEditService {
  private addOnEditStore = store.addOnEditStore as AddOnEditStore;

  private userStore = store.userStore as UserStore;

  private toasterStore = store.toasterStore as ToasterStore;

  originalState?: string;

  // @ts-ignore
  oldAddOn: Addon;

  compileAddOn(): AddonCreateInput {
    const addOn = {
      id: this.addOnEditStore.id,
      active: this.addOnEditStore.active,
      title: this.addOnEditStore.title,
      brand: this.addOnEditStore.brand,
      merchant: {
        connect: {
          id: this.userStore.merchantId
        }
      },
      category: {
        connect: {
          id: this.addOnEditStore.categoryId
        }
      },
      channels: {
        connect: Array.from(this.addOnEditStore.selectedChannels).map(channel => {
          return { channel };
        })
      },
      media: {
        connect: {
          id: this.addOnEditStore.mediaItem!.id
        }
      },
      selectedProducts: this.addOnEditStore.allProducts ? undefined : {
        connect: this.addOnEditStore.selectedProductIds.map((productId: any) => {
          return {
            id: productId
          };
        })
      },
      slug: slugify(this.addOnEditStore.title),
      price: this.addOnEditStore.price,
      stock: this.addOnEditStore.stock,
      allProducts: this.addOnEditStore.allProducts
    };

    return addOn;
  }

  compileUpdatedAddOn(): AddonUpdateInput {
    const addOn: AddonUpdateInput = {
      active: this.addOnEditStore.active,
      title: this.addOnEditStore.title,
      brand: this.addOnEditStore.brand,
      category: {
        connect: {
          id: this.addOnEditStore.categoryId
        }
      },
      channels: {
        set: Array.from(this.addOnEditStore.selectedChannels).map(channel => {
          return { channel };
        })
      },
      media: {
        connect: {
          id: this.addOnEditStore.mediaItem!.id
        }
      },
      selectedProducts: {
        set: this.addOnEditStore.allProducts ? [] : this.addOnEditStore.selectedProductIds.map((productId: any) => {
          return {
            id: productId
          };
        })
      },
      slug: slugify(this.addOnEditStore.title),
      price: this.addOnEditStore.price,
      stock: this.addOnEditStore.stock,
      allProducts: this.addOnEditStore.allProducts
    };

    return addOn;
  }

  public isStateDirty = (): boolean => {
    return this.originalState !== this.getAddOnCreateEditStoreState();
  };

  public async init(addOnId?: string): Promise<any> {
    if (addOnId && !addOnId.startsWith('pre-create')) {
      await this.loadAddOnForEdit(addOnId);
    } else if (addOnId) {
      this.addOnEditStore.init();
      this.getAllAvailableProducts();
      this.addOnEditStore.resetIsLoading();
    } else {
      NavService.addOnsList();
    }

    this.getCategories();
  }

  public getCategories(): void {
    AddOnsAPIService.fetchAddOnsCategories()
      .then((addOnsCategories: AddonCategory[]) => {
        this.addOnEditStore.setCategories(addOnsCategories);
      }).catch(() => {
        this.toasterStore.popErrorToast('categories', 'get');
      });
  }

  public async loadAddOnForEdit(addOnId: string): Promise<any> {
    const merchantId = this.userStore.merchantId;
    const response = await GraphQL.query(SINGLE_ADD_ON(merchantId), { id: addOnId, merchantId: merchantId }, 'no-cache')
      .then(({ data }: any) => {
        this.oldAddOn = data.addon;
        this.addOnEditStore.importAddOn(data.addon);
        this.originalState = this.getAddOnCreateEditStoreState();
        this.getAllAvailableProducts(data.addon.merchant.id);
        this.addOnEditStore.resetIsLoading();
      }).catch(() => {
        this.addOnEditStore.resetIsLoading();
        this.toasterStore.popErrorToast(this.addOnEditStore.title, 'get');
      });

    return response;
  }

  public async getAllAvailableProducts(merchantId: string = this.userStore.merchantId): Promise<void> {
    const count = 100;
    let skip = 0;
    let hasNextPage = true;
    let products: ProductEdge[] = [];

    while (hasNextPage) {
      try {
        const result: ApolloQueryResult<{ allProducts: ProductConnection }> = await GraphQL
          .query(ADD_ONS_PRODUCTS_QUERY(merchantId), { merchantId, count, skip });

        skip = skip + count;
        hasNextPage = result.data.allProducts.pageInfo.hasNextPage;

        products = [
          ...products,
          ...result.data.allProducts.edges as ProductEdge[]
        ];
      } catch (error) {
        hasNextPage = false;
        store.toasterStore.popErrorToast('list of available products', 'get');
      }
    }

    this.addOnEditStore.setProducts(products.map(edge => edge.node));
  }

  public async saveAddOn(): Promise<any> {
    this.addOnEditStore.toggleIsSaving(true);

    if (this.addOnEditStore.currentMode === Mode.create) {
      return GraphQL.mutate(ADD_ON_CREATE, {
        data: this.compileAddOn()
      }).then(() => {
        this.originalState = this.getAddOnCreateEditStoreState();
        this.addOnEditStore.toggleIsSaving(false);
        NavService.addOnsList();
      }).catch((error: any) => {
        this.toasterStore.popErrorToast(this.addOnEditStore.title, 'create');
        this.addOnEditStore.toggleIsSaving(false);

        return Promise.reject(error);
      });
    }

    return GraphQL.mutate(ADD_ON_EDIT, {
      data: this.compileUpdatedAddOn(),
      addOnId: this.addOnEditStore.id
    }).then((result: any) => {
      this.originalState = this.getAddOnCreateEditStoreState();
      this.addOnEditStore.toggleIsSaving(false);

      if (result && result.data) {
        this.oldAddOn = result.data.updateAddon;
        this.addOnEditStore.importAddOn(this.oldAddOn);
        NavService.addOnsList();
      }
    }).catch((error: any) => {
      this.toasterStore.popErrorToast(this.addOnEditStore.title, 'update');
      this.addOnEditStore.toggleIsSaving(false);

      return Promise.reject(error);
    });
  }

  getAddOnCreateEditStoreState = (): string => {
    let buildingState = '';
    buildingState += this.addOnEditStore.active;
    buildingState += this.addOnEditStore.categoryId;
    buildingState += this.addOnEditStore.title;
    buildingState += this.addOnEditStore.brand;
    buildingState += this.addOnEditStore.stock;
    buildingState += this.addOnEditStore.price;

    return buildingState;
  };
}

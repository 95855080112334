import store from 'stores';

import {
  Supplier,
  SupplierDeliveryHub,
  SupplierDeliveryHubWhereInput,
  SupplierWhereInput
} from 'generated-types.d';

import { GraphQL } from 'lib';

import {
  SUPPLIERS,
  SUPPLIER_DELIVERY_HUBS
} from 'features/suppliers/graphql/queries/suppliers.queries';

class SuppliersService {
  public static fetchSuppliers = async (where?: SupplierWhereInput): Promise<Supplier[]> => {
    try {
      const result = await GraphQL.query<{ suppliers: Supplier[] }>(SUPPLIERS, { where });

      return result.data.suppliers;
    } catch (error) {
      store.toasterStore.popErrorToast('suppliers', 'get');

      return Promise.reject(error);
    }
  };

  public static fetchSupplierDeliveryHubs = async (where?: SupplierDeliveryHubWhereInput): Promise<SupplierDeliveryHub[]> => {
    try {
      const result = await GraphQL.query<{ supplierDeliveryHubs: SupplierDeliveryHub[] }>(SUPPLIER_DELIVERY_HUBS, { where });

      return result.data.supplierDeliveryHubs;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default SuppliersService;

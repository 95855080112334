import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex } from 'rebass';

import { MerchantHolidayUpdateInput, MerchantHoliday } from 'generated-types.d';

import { TimeService } from 'lib';

import { Mode } from 'stores/product-edit-store/product-edit-store-types';

import { DateRangeModalProps } from 'features/modal-dialogue/components/modals/date-range-modal/date-range-modal.types';

import Button from 'components/button';
import FormFieldWrapper from 'components/create-labeled-component';
import { EntityListItems } from 'components/entity-list/entity-list.styles';
import Icon from 'components/icon';
import HolidayListItem from 'components/merchant-holidays/holiday-list-item';

import * as Styles from './product-pause-field.styles';
import * as Types from './product-pause-field.types';

class ProductPauseTab extends Component<Types.ProductPauseTabProps> {
  private onBuildAvailability = (): any => {
    const merchant = this.props.productEditStore!.currentMode === Mode.create
      ? this.props.merchantStore!.merchant!
      : this.props.productEditStore!.productMerchant;

    const data: DateRangeModalProps['data'] = {
      title: 'Create product holiday',
      errorCopy: 'creating your product holiday',
      fromCopy: 'On holiday from',
      toCopy: 'Available from',
      timeoutCopy: 'We are still busy creating your product holiday which can sometimes take a bit of time... 2 minute meditation anyone?',
      initialMerchantId: merchant!.id,
      shouldDisplayMerchantSelection: false,
      onConfirm: this.onCreateAvailability
    };

    this.props.modalStore!.triggerModal({
      modalType: 'dateRange',
      data: data
    });
  };

  private onCreateAvailability = async (from: string, to: string): Promise<void> => {
    const endAt = TimeService.isSameDay(from, to) ? moment(to).add(1, 'd').format('YYYY-MM-DD') : moment(to).format('YYYY-MM-DD');

    this.props.productEditStore!.createAvailability(from, endAt);
  };

  private onUpdateAvailability = async (data: MerchantHolidayUpdateInput, availabilityId: string): Promise<void> => {
    this.props.productEditStore!.updateAvailability(data, availabilityId);
  };

  private onDeleteAvailability = async (availabilityId: string): Promise<void> => {
    this.props.productEditStore!.deleteAvailability(availabilityId);
  };

  private renderEmptyList = (): ReactNode => {
    return (
      <Styles.EmptyCreateButton
        as="button"
        onClick={this.onBuildAvailability}
      >
        <Icon
          iconName="plus-small"
        />
        <p>Add product holiday</p>
      </Styles.EmptyCreateButton>
    );
  };

  private renderAvailabilityItems = (): ReactNode => {
    return this.props.productEditStore!.availability.map((item, index) => {
      return (
        <HolidayListItem
          key={item.id || index}
          item={item as MerchantHoliday}
          startDateCopy="On holiday from:"
          endDateCopy="Available from:"
          shouldDisplayMerchantTitle={false}
          shouldUpdateOnStartDate={true}
          onUpdate={this.onUpdateAvailability}
          onDelete={this.onDeleteAvailability}
        />
      );
    });
  };

  private renderPopulatedList = (): ReactNode => {
    return (
      <Box>
        <EntityListItems removeOverflow={true}>
          {this.renderAvailabilityItems()}
        </EntityListItems>
        <Flex
          justifyContent="flex-end"
          mt="20px"
        >
          <Box
            as="button"
            onClick={this.onBuildAvailability}
          >
            <Button copy="Add holiday" />
          </Box>
        </Flex>
      </Box>
    );
  };

  render(): React.ReactNode {
    return (
      <FormFieldWrapper
        title="Product Holidays"
        subtitle="Won't be getting stock for this product during a certain time? Send it on holiday! Select the time period that you would like this product to be disabled for purchase."
        optional={true}
      >
        { !!this.props.productEditStore!.availability.length
          ? this.renderPopulatedList()
          : this.renderEmptyList()
        }
      </FormFieldWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore
}))(observer(ProductPauseTab));

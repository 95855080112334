import {
  FC,
  useState
} from 'react';

import OutsideClickHandler from 'react-outside-click-handler';
import { Flex } from 'rebass';

import { TEST_IDS } from 'utils/test/data-test-ids';

import Button from '../button';
import Icon from '../icon';

import {
  ButtonWrapper,
  Container,
  Content,
  Description,
  Header,
  Heading,
  IconWrapper,
  Item
} from './button-dropdown.styles';
import {
  ButtonDropdownOption,
  ButtonDropdownProps
} from './button-dropdown.types';

export const ButtonDropdown: FC<ButtonDropdownProps> = ({
  buttonCopy,
  renderButton,
  isDisabled = false,
  options,
  isHidden = false,
  heading,
  positionY = 'bottom',
  positionX = 'left',
  customDropdownContainerStyles
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = async (): Promise<void> => {
    if (isDisabled) return;
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const handleSelect = async (optionCallback: () => any): Promise<void> => {
    optionCallback();

    setIsOpen(() => false);
  };

  if (isHidden) return null;

  return (
    <ButtonWrapper>
      <OutsideClickHandler
        disabled={!isOpen}
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <Flex
          disabled={isDisabled}
          onClick={toggleDropdown}
          data-testid={TEST_IDS.ButtonDropdown.trigger}
        >
          {renderButton?.({ isDisabled: !!isDisabled }) || (
            <Button
              size="small"
              copy={buttonCopy}
              isDisabled={isDisabled}
            />
          )}
        </Flex>
        {isOpen && (
          <Container
            positionX={positionX}
            positionY={positionY}
            customStyles={customDropdownContainerStyles}
          >
            {heading && (
              <Heading>
                {heading}
              </Heading>
            )}
            {options.map((option: ButtonDropdownOption, index: number) => {
              return (
                <Item
                  key={index}
                  data-testid={`${TEST_IDS.ButtonDropdown.item}${index}`}
                  onClick={() => handleSelect(option.onClick)}
                >
                  {option.iconName && (
                    <IconWrapper colour={option.iconBgColour}>
                      <Icon
                        iconName={option.iconName}
                        size="small"
                        pathFill={option.iconColour}
                      />
                    </IconWrapper>
                  )}
                  <Content>
                    <Header>
                      {option.title}
                    </Header>
                    {option.description && (
                      <Description>
                        {option.description}
                      </Description>
                    )}
                  </Content>
                </Item>
              );
            })}
          </Container>
        )}
      </OutsideClickHandler>
    </ButtonWrapper>
  );
};

ButtonDropdown.defaultProps = {
  isHidden: false,
  isDisabled: false
};

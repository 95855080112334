import { css } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';

import {
  HeaderHeight,
  breakpoints,
  colors
} from 'utils/rebass-theme';

export const Wrapper: StyledComponent<any, any, any> = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${HeaderHeight};
  padding: 10px 30px;

  @media (min-width: ${breakpoints.small}) {
    justify-content: center;
  }
`;

export const LogoWrapper = styled.div<any>`
  display: none;
  width: 100%;
  max-width: 100px;

  @media (min-width: ${breakpoints.small}) {
    display: ${({ isVisible }): string => isVisible ? 'block' : 'none'};
  }
`;

export const CloseIconWrapper: StyledComponent<any, any, any> = styled.div`
  display: block;
  margin-left: auto;
  line-height: 0;

  @media (min-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const CollapseIconWrapper: StyledComponent<any, any, any> = styled.div`
  display: none;
  position: absolute;
  left: 18px;

  @media (min-width: ${breakpoints.small}) {
    display: block;
  }
`;

export const CloseIcon: any = css`
  path {
    fill: ${colors.uiFills};
  }
`;

import { ApolloQueryResult } from '@apollo/client';
import store from 'stores';

import {
  DeliveryConfig,
  Channel,
  DeliveryConfigCreateInput,
  DeliveryConfigUpdateInput,
  DeliveryPricingUpdateInput,
  DeliveryCoverage,
  DeliveryCoverageCreateInput,
  DeliveryCoverageUpdateInput,
  DeliveryCoverageWhereUniqueInput,
  PlanFeature,
  DeliveryPricingCreateInput,
  DeliveryPricing,
  Merchant
} from 'generated-types.d';

import {
  GraphQL,
  MerchantService
} from 'lib';

import DeliveryStore from 'stores/delivery/delivery-store';
import { DeliveryCutoffTime } from 'stores/delivery/delivery-store.types';
import MerchantStore from 'stores/merchant/merchant-store';

import {
  CREATE_DELIVERY_COVERAGE,
  UPDATE_DELIVERY_COVERAGE,
  CREATE_DELIVERY_CONFIG,
  DELIVERY_CONFIG_MUTATION,
  UPDATE_CUSTOM_DELIVERY_PRICES
} from 'features/settings/graphql/mutators/delivery.mutators';
import {
  DELIVERY_CONFIGS,
  DELIVERY_COVERAGES,
  DELIVERY_PRICINGS,
  ALL_ZONES_FOR_COVERAGE_AREA,
  DEFAULT_PRODUCTS_WITH_CONFIG
} from 'features/settings/graphql/queries/delivery.queries';

export default class DeliveryService {
  deliveryStore = store.deliveryStore as DeliveryStore;

  toasterStore = store.toasterStore;

  merchantStore = store.merchantStore as MerchantStore;

  userStore = store.userStore;

  public initConfigs(): void {
    if (!!this.merchantStore!.merchant?.id) {
      this.deliveryStore!.updateBasicValue('isLoadingDeliveryConfigs', true);
      this.populateDeliveryConfigs();
      this.populateDeliveryCoverages();
      this.populateDeliveryPricings();
      this.deliveryStore.generateTitle();
    } else {
      this.deliveryStore!.updateBasicValue('isLoadingDeliveryConfigs', false);
    }
  }

  public initCoverages(): void {
    if (!!this.merchantStore!.merchant?.id) {
      this.populateDeliveryCoverages();
    } else {
      this.deliveryStore!.updateBasicValue('isLoadingDeliveryCoverages', false);
    }
  }

  public saveModal = async (): Promise<any> => {
    if (this.deliveryStore.selectedDeliveryConfig) {
      return this.submitDeliveryConfigEditUpdate();
    }

    return this.createNewDeliveryConfig();
  };

  public saveNewDeliveryCoverage = async (data: DeliveryCoverageCreateInput): Promise<any> => {
    return GraphQL.mutate(CREATE_DELIVERY_COVERAGE, {
      data
    })
      .then(result => {
        this.populateDeliveryCoverages();
        this.toasterStore.popSuccessToast('Delivery coverage', 'create');

        return result;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('delivery coverage', 'create');

        return Promise.reject(error);
      });
  };

  public updateDeliveryCoverage = async (
    data: DeliveryCoverageUpdateInput,
    where: DeliveryCoverageWhereUniqueInput
  ): Promise<any> => {
    return GraphQL.mutate(UPDATE_DELIVERY_COVERAGE, { data, where })
      .then(result => {
        this.populateDeliveryCoverages();
        this.toasterStore.popSuccessToast('Your delivery coverage', 'update');

        return result;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('delivery coverage', 'update');

        return Promise.reject(error);
      });
  };

  public allZonesForCoverageArea = async (id: string): Promise<void> => {
    return GraphQL.query(ALL_ZONES_FOR_COVERAGE_AREA, { id })
      .then(({ data }: any) => {
        this.deliveryStore.updateCoverageAreaZones(data.deliveryCoverage.deliveryZones, id);
      })
      .catch(() => {
        this.toasterStore.popErrorToast('all delivery zones for this coverage area', 'retrieve');
      });
  };

  public populateDeliveryConfigs = async (): Promise<void> => {
    this.deliveryStore.updateBasicValue('isLoadingDeliveryConfigs', true);

    try {
      const result: ApolloQueryResult<{ deliveryConfigs: DeliveryConfig[] }> = await GraphQL.query(
        DELIVERY_CONFIGS({ merchantId: this.merchantStore.merchant?.id }),
        { merchantId: this.merchantStore.merchant?.id },
        'no-cache'
      );

      this.deliveryStore.updateBasicValue('deliveryConfigs', result.data.deliveryConfigs);
      this.deliveryStore.updateBasicValue('isLoadingDeliveryConfigs', false);
    } catch (error) {
      this.deliveryStore.updateBasicValue('isLoadingDeliveryConfigs', false);

      return Promise.reject(error);
    }
  };

  public async canEditDeliveryPricing(configId: string): Promise<boolean> {
    try {
      const result = await GraphQL.query(DEFAULT_PRODUCTS_WITH_CONFIG, { configId }, 'no-cache');

      return result.data.products.length === 0;
    } catch (error) {
      return false;
    }
  }

  public populateDeliveryPricings(): void {
    GraphQL.query(DELIVERY_PRICINGS({ merchantId: this.merchantStore.merchant?.id }), { merchantId: this.merchantStore.merchant?.id }, 'no-cache')
      .then(({ data }: any) => {
        this.deliveryStore.updateBasicValue('deliveryPricings', data.deliveryPricings);
      });
  }

  public getDeliveryAreas(selectedChannel: string): DeliveryCoverage[] {
    const { selectedDeliveryConfig, deliveryCoverages } = this.deliveryStore;

    return deliveryCoverages.filter((coverage: any) => {
      let isMerchant;
      let isGlobal;

      if (!coverage.merchant) {
        isGlobal = true;
      } else {
        isMerchant = (coverage.merchant.id === (selectedDeliveryConfig ? selectedDeliveryConfig.merchant.id : this.merchantStore.merchant ? this.merchantStore.merchant.id : ''));
      }

      return coverage.channel === selectedChannel
      && (isMerchant || isGlobal);
    })
      .map((coverage: any) => ({
        ...coverage,
        slug: coverage.id
      }));
  }

  public getChannels(): Array<{ title: string; value: Channel; slug: Channel }> {
    const floomChannel = {
      title: 'floom.com',
      value: Channel.Floom,
      slug: Channel.Floom
    };

    const websiteChannel = {
      title: this.merchantStore.customSiteUrlDisplay || 'CustomWebsite.com',
      value: Channel.Website,
      slug: Channel.Website
    };

    if (!this.merchantStore.merchant?.plan) {
      return [
        floomChannel,
        websiteChannel
      ];
    }

    const channels: Array<{ title: string; value: Channel; slug: Channel }> = [];

    this.merchantStore.merchant?.plan?.features?.forEach?.(feature => {
      if (feature === PlanFeature.Website) {
        channels.push(websiteChannel);
      }

      if (feature === PlanFeature.Floom) {
        channels.push(floomChannel);
      }
    });

    return channels;
  }

  public updateCustomDeliveryPricing = async (): Promise<any> => {
    const data: DeliveryPricingUpdateInput = {
      sameDayPrice: this.deliveryStore.customSameDayPrice,
      nextDayPrice: this.deliveryStore.customNextDayPrice
    };

    return GraphQL.mutate(UPDATE_CUSTOM_DELIVERY_PRICES, {
      id: this.deliveryStore.customDeliveryConfig!.deliveryPrice!.id,
      data: data
    })
      .then(result => {
        this.populateDeliveryConfigs();
        this.toasterStore.popSuccessToast('Your custom site delivery prices', 'update', true);

        return result;
      })
      .catch(error => {
        window.Sentry.captureException(error);
        this.toasterStore.popErrorToast('custom site delivery prices', 'update');

        return error;
      });
  };

  private setCustomDeliveryPricing = (deliveryConfigs: DeliveryConfig[]): void => {
    const customDeliveryConfig = this.findFirstCustomConfig(deliveryConfigs);

    if (customDeliveryConfig) {
      this.deliveryStore.setCurrentCustomPricing(customDeliveryConfig);
    }
  };

  private findFirstCustomConfig = (deliveryConfigs: DeliveryConfig[]): DeliveryConfig | undefined => {
    return deliveryConfigs.find(config => config.channel === Channel.Website);
  };

  private shouldModifyDeliveryPricing = (): boolean => {
    const selectedPricing = this.deliveryStore.selectedDeliveryPricing;

    return this.deliveryStore.canEditPricing()
      && (selectedPricing?.sameDayPrice !== this.deliveryStore.configSameDayPrice
        || selectedPricing?.nextDayPrice !== this.deliveryStore.configNextDayPrice);
  };

  private generateDeliveryPricingCreateInput = (): DeliveryPricingCreateInput => {
    return {
      channel: this.deliveryStore.selectedChannel!,
      sameDayPrice: this.deliveryStore.configSameDayPrice,
      nextDayPrice: this.deliveryStore.configNextDayPrice,
      perItemDeliveryFee: false,
      title: `${this.deliveryStore.title}_pricing`,
      merchant: {
        connect: {
          id: this.merchantStore!.merchant?.id
        }
      },
      // Must be false, as a non default pricing is never generated by the user
      isDefault: false
    };
  };

  private buildLeadTimeAmount = (merchant: Merchant): number => {
    if (MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, merchant)) {
      return 0;
    }

    return this.deliveryStore.selectedLeadTime;
  };

  private submitDeliveryConfigEditUpdate = async (): Promise<any> => {
    const sameWeekdays = this.deliveryStore.sameDayWeekday ?
      this.deliveryStore.deliveryDays.slice(0, 5).map((day: any) => day.selected)
      : [false, false, false, false, false];

    const sameWeekends = this.deliveryStore.sameDayWeekend ?
      this.deliveryStore.deliveryDays.slice(5, 7).map((day: any) => day.selected)
      : [false, false];

    const updateObj: DeliveryConfigUpdateInput = {
      title: this.deliveryStore.title,
      default: this.deliveryStore.isDefault,
      deliveryCoverage: {
        set: this.deliveryStore.selectedDeliveryCoverages.map((config: any) => ({
          id: config
        }))
      },
      deliveryTiming: {
        update: {
          days: {
            set: this.deliveryStore.deliveryDays.map((day: any) => day.selected)
          },
          sameDays: {
            set: sameWeekdays.concat(sameWeekends)
          },
          leadtime: this.buildLeadTimeAmount(this.deliveryStore.selectedDeliveryConfig!.merchant!),
          cutOffHour: this.deliveryStore.selectedCutoffTime
        }
      }
    };

    const selectedDeliveryPrice = this.deliveryStore.selectedDeliveryPricing;

    if (this.shouldModifyDeliveryPricing()) {
      switch (true) {
        case selectedDeliveryPrice?.isDefault === true:
          updateObj.deliveryPrice = {
            create: this.generateDeliveryPricingCreateInput()
          };

          break;

        case selectedDeliveryPrice?.isDefault === false:
          updateObj.deliveryPrice = {
            update: {
              sameDayPrice: this.deliveryStore?.configSameDayPrice,
              nextDayPrice: this.deliveryStore?.configNextDayPrice
            }
          };

          break;

        default:
          break;
      }
    }

    return GraphQL.mutate(DELIVERY_CONFIG_MUTATION, {
      args: updateObj,
      where: { id: this.deliveryStore.selectedDeliveryConfig!.id }
    })
      .then(result => {
        this.populateDeliveryConfigs();
        this.toasterStore.popSuccessToast('Delivery configuration', 'update');

        return result;
      })
      .catch(error => {
        this.toasterStore.popErrorToast('Delivery configuration', 'update');

        return Promise.reject(error);
      });
  };

  private createNewDeliveryConfig = async (): Promise<any> => {
    const sameWeekdays = this.deliveryStore.sameDayWeekday ?
      this.deliveryStore.deliveryDays.slice(0, 5).map((day: any) => day.selected)
      : [false, false, false, false, false];

    const sameWeekends = this.deliveryStore.sameDayWeekend ?
      this.deliveryStore.deliveryDays.slice(5, 7).map((day: any) => day.selected)
      : [false, false];

    const updateObj: DeliveryConfigCreateInput = {
      title: this.deliveryStore.title,
      channel: this.deliveryStore.selectedChannel!,
      default: this.deliveryStore.isDefault,
      deliveryCoverage: {
        connect: this.deliveryStore.selectedDeliveryCoverages.map((config: any) => ({
          id: config
        }))
      },
      merchant: {
        connect: {
          id: this.merchantStore.merchant?.id
        }
      },
      deliveryTiming: {
        create: {
          title: 'timing',
          days: {
            set: this.deliveryStore.deliveryDays.map((day: any) => day.selected)
          },
          sameDays: {
            set: sameWeekdays.concat(sameWeekends)
          },
          leadtime: this.buildLeadTimeAmount(this.merchantStore.merchant!),
          cutOffHour: this.deliveryStore.selectedCutoffTime
        }
      }
    };

    if (this.shouldModifyDeliveryPricing()) {
      updateObj.deliveryPrice = {
        create: this.generateDeliveryPricingCreateInput()
      };
    } else {
      updateObj.deliveryPrice = {
        connect: {
          id: this.deliveryStore.deliveryPricings
            .filter((x: DeliveryPricing) => x.channel === this.deliveryStore.selectedChannel && x.isDefault === true)[0].id
        }
      };
    }

    return GraphQL.mutate(CREATE_DELIVERY_CONFIG, {
      args: updateObj
    })
      .then(result => {
        this.populateDeliveryConfigs();
        this.toasterStore.popSuccessToast('Delivery configuration', 'create');

        return result;
      })
      .catch(error => {
        this.toasterStore.popErrorToast('Delivery configuration', 'create');

        return Promise.reject(error);
      });
  };

  private populateDeliveryCoverages = async (): Promise<any> => {
    const vars = {
      merchantId: this.merchantStore.merchant?.id || ''
    };

    return GraphQL.query(DELIVERY_COVERAGES(vars), vars, 'no-cache')
      .then(({ data }: any) => {
        this.deliveryStore.updateBasicValue('deliveryCoverages', data.deliveryCoverages);
        this.deliveryStore.disableDeliveryCoverageLoading();
      });
  };
}

export const getDeliveryConfigCutoffTimes = (): DeliveryCutoffTime[] => {
  const times: DeliveryCutoffTime[] = [];

  for (let i = 1; i < 7; i++) {
    times.push({
      title: `${i}pm`,
      value: i + 12
    });
  }

  return times;
};

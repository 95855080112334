import { gql } from '@apollo/client';

import { MerchantFragments } from 'lib';

export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    honorific
    businessName
    recipientFullName
    address1
    address2
    city
    postalCode
    phone
    country
    region
  }
`;

export const MERCHANT_CREATE_FRAGMENT = gql`
  fragment MerchantCreateFragment on Merchant {
    id
    title
    active
    timezone
    companyName
    tradingNo
    stripeConnectAccountId
    plan {
      id
      price
    }
    subscription {
      trialLength
      stripeSubscriptionPrice
    }
    wholesaleDeliveryConfigs {
      deliveryInstructions
      createdAt
      rijnsCode
      active
      isConfirmed
      address {
        ...AddressFragment
      }
    }
    registeredAddress {
      ...AddressFragment
    }
    address {
      ...AddressFragment
    }
    createdBy {
      givenName
      familyName
      email
      phone
      team {
        id
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const CREATE_MERCHANT = gql`
  mutation CreateMerchant(
    $data: MerchantCreateInput!
  ) {
    createMerchant(
      data: $data
    ) {
      ...MerchantFull
    }
  }
  ${MerchantFragments.MERCHANT_FULL}
`;

export const AUTH_STRIPE_CONNECT = gql`
  mutation AuthStripeConnect(
    $data: AuthStripeConnectInput!
    $where: MerchantWhereInput!
  ) {
    authStripeConnect(
      data: $data,
      where: $where
    ) {
      ...MerchantFull
    }
  }
  ${MerchantFragments.MERCHANT_FULL}
`;

export const STORE_BANK_ACCOUNT_DETAILS = gql`
  mutation BankAccountStore(
    $data: MerchantBankDetailsInput!
    $where: MerchantWhereInput!
  ) {
    updateMerchantBankDetails(
      data: $data,
      where: $where
    ) {
      ...MerchantFull
    }
  }
  ${MerchantFragments.MERCHANT_FULL}
`;

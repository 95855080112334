import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

import { DeleteCross } from 'components/lozenge/lozenge.styles';

export const Container = styled(Box)`
  width: 100%;
  padding: 0 10px 10px;
`;

export const LozengeStyles = css`
  display: inline-flex;
  font-size: 10px;
  font-weight: 700;
  margin: 5px 5px 0 0;
  padding: 6px 9px;
  text-transform: uppercase;

  ${DeleteCross} {
    margin-left: 10px;
  }

  svg {
    cursor: pointer;
    width: 7px;
    height: 7px;
  }
`;

export const ClearButtonStyles = css`
  ${LozengeStyles}

  svg {
    path {
      fill: ${colors.uiFills}
    }
  }
`;

import {
  Currency,
  PlanType,
  MerchantStage,
  SubscriptionStatus
} from 'generated-types.d';

/**
 * @description localises the naming convention for postalcode,
 * which varies from country to country.
 *
 * @param currency
 */
export const renderPostalCodeCopy = (currency: Currency): string => {
  switch (currency) {
    case Currency.Usd:
      return 'zip code';

    case Currency.Gbp:
      return 'postcode';

    default:
      return 'postal code';
  }
};

/**
 * @description returns a human-readable title for the plan
 * a merchant is currently assigned to.
 *
 * @param plan the plan type the merchant is assigned to
 */
export const localisePlanName = (plan?: PlanType): string => {
  if (!plan) return '';

  switch (plan) {
    case PlanType.Lite2:
      return 'Lite (Floom collection)';

    case PlanType.Lite1:
      return 'Lite (Own collection)';

    case PlanType.Basic:
    case PlanType.Advanced:
    case PlanType.AdvancedWithFloom:
    case PlanType.Standard:
    case PlanType.StandardLegacy:
    case PlanType.NonFloristLegacy:
      return 'Standard (Own collection)';

    case PlanType.Standard2:
      return 'Standard (Floom collection)';

    case PlanType.Premium:
      return 'Premium (Own collection)';

    case PlanType.Premium2:
      return 'Premium (Floom collection)';

    case PlanType.FloomMarket:
      return 'Market';

    case PlanType.WholesaleOnly:
      return 'Wholesale';

    default:
      return '';
  }
};

/**
 * @description Turns the merchant stage enum from the API into a human readable string
 * that we can display to the user
 *
 * @param merchantStage the stage of the merchant
 */
export const localiseMerchantStage = (merchantStage?: MerchantStage): string => {
  if (!merchantStage) return '';

  switch (merchantStage) {
    case MerchantStage.Created:
      return 'Created';

    case MerchantStage.InSetup:
      return 'In setup';

    case MerchantStage.New:
      return 'New';

    case MerchantStage.Active:
      return 'Active';

    case MerchantStage.Deactivated:
      return 'Deactivated (payment issues)';

    case MerchantStage.Dormant:
      return 'Dormant';

    case MerchantStage.Churn:
      return 'Churned';

    case MerchantStage.Unassigned:
      return '-';

    default:
      return '';
  }
};

/**
 * @description returns an object containing human readable metadata for each
 * possible merchant subscripton status (which we map from Stripe).
 *
 * @param status the subscription status we hold on the merchants
 * recurring payment data model
 */
export const localiseSubscriptionStatus = (status?: SubscriptionStatus): { title: string; reason: string; solution: string } => {
  switch (status) {
    case SubscriptionStatus.Active:
      return {
        title: 'Active',
        reason: 'All payments up to date',
        solution: 'no action needed'
      };

    case SubscriptionStatus.Canceled:
      return {
        title: 'Cancelled',
        reason: 'The subscription has ended',
        solution: 'Resubscribe to the platform'
      };

    case SubscriptionStatus.Incomplete:
      return {
        title: 'Incomplete',
        reason: 'Payment failed when subscription was created',
        solution: 'Update payment details within 23 hours of subscribing.'
      };

    case SubscriptionStatus.IncompleteExpired:
      return {
        title: 'Incomplete expired',
        reason: 'Payment failed when subscription was created & has now expired',
        solution: 'Resubscribe to the platform'
      };

    case SubscriptionStatus.PastDue:
      return {
        title: 'Past due',
        reason: 'Payment on latest invoice has failed / wasn\'t attempted',
        solution: 'Pay latest subscription invoice. check emails, or update payment method'
      };

    case SubscriptionStatus.Trialing:
      return {
        title: 'In no-fee period',
        reason: 'Currently in FloomX no-fee period',
        solution: 'No ation needed'
      };

    case SubscriptionStatus.Unpaid:
      return {
        title: 'Unpaid',
        reason: 'Subscription is still in place, but last invoice has not been paid',
        solution: 'Pay latest subscription invoice. check emails, or update payment method'
      };

    case SubscriptionStatus.Unsubscribed:
      return {
        title: 'Not yet subscribed',
        reason: 'Merchant has been created, but has yet to enter their card details',
        solution: 'Complete account setup'
      };

    default:
      return {
        title: 'Unknown status',
        reason: '',
        solution: ''
      };
  }
};

/**
 * @description returns the naming convention for a banks routing code,
 * which varies from currency to currency.
 *
 * @param currency
 */
export const routingCodeCopy = (currency: Currency | undefined): string => {
  switch (currency) {
    case Currency.Usd:
      return 'Routing number';

    case Currency.Gbp:
      return 'Sort code';

    default:
      return 'Routing # / Sort code';
  }
};

import React from 'react';

import { Link } from 'gatsby';
import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Flex, Box, Text } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import UserService from 'features/login-signup/services/';

import Button from 'components/button';
import FieldText from 'components/field-text';
import Icon from 'components/icon';
import StyledLink from 'components/styled-link/styled-link';

import * as Styles from './forgot-password.styles';

const LOGIN_ERRORS = {
  77046112839: 'Uh oh! we can\'t find an account with that email 😔. Please check you have entered the correct email',
  77947452841: 'You\'re speedy! Our Floomers are still busy adding the finishing touches to your account. Please check your inbox for an invitation email, or get in touch with us!'
};

const SOMETHING_WENT_WRONG = 'Something went wrong when logging you in';

class ForgotPassword extends React.Component<any> {
  userService = UserService;

  state = {
    emailEntered: false,
    isLoading: false,
    error: null
  };

  componentDidMount = async (): Promise<any> => {
    this.userService.init();
    this.subscribeToLoginErrors();
  };

  private subscribeToLoginErrors(): void {
    observe(this.props.accountStore, 'error', ({
      newValue: newError
    }: any) => {
      this.setState({
        error: LOGIN_ERRORS?.[newError?.code] || SOMETHING_WENT_WRONG,
        isLoading: false
      });
    });
  }

  private onEmailSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault();

    this.setState({
      error: null
    });

    if (!this.props.accountStore.email) {
      this.setState({ error: 'Email is required' });
    } else {
      this.setState({ isLoading: true });
      await this.userService.sendConfirmationEmail(this.props.accountStore.email);
    }
  };

  private onChange = (e: React.ChangeEvent<HTMLInputElement>, callback: any): void => {
    this.resetError();

    callback(e.target.value);
  };

  private resetError = (): void => {
    if (this.hasError()) {
      this.setState({ error: null });
    }
  };

  private hasError = (): boolean => !!this.state.error;

  render(): React.ReactNode {
    return (
      <Styles.LoginWrapper>
        <Flex mb="20px">
          <Link to="/login">
            <Icon
              iconName="arrow-full-left"
              styles={Styles.BackArrow}
            />
            <StyledLink>
              Back
            </StyledLink>
          </Link>
        </Flex>
        <Text
          css={textStyles.h3}
          mb="25px"
        >
          Forgot your password?
        </Text>
        <Text
          css={textStyles.subhead}
          mb="25px"
        >
          Enter your email address so we can send you your password recovery email.
        </Text>
        <form onSubmit={this.onEmailSubmit}>
          <Box alignItems="flex-start">
            <FieldText
              placeholder="Email address"
              type="email"
              id="email-address-field"
              value={this.props.accountStore.email}
              autoFocus={true}
              onChange={(e): void => this.onChange(e, this.userService.updateEmail)}
              error={this.hasError()}
            />
          </Box>

          { this.hasError() && (
            <Styles.Error>
              {this.state.error}
            </Styles.Error>
          )}
          <Box mt="20px">
            <Box
              as="button"
              type="submit"
              width="100%"
              id="login-submit-button"
              disabled={this.hasError() && this.state.emailEntered}
            >
              <Button
                isParentWidth={true}
                isDisabled={this.hasError() && this.state.emailEntered}
                copy="Reset password"
                isLoading={this.state.isLoading}
              />
            </Box>
          </Box>
        </form>
      </Styles.LoginWrapper>
    );
  }
}

export default inject('accountStore')(observer(ForgotPassword));

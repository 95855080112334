import moment, { Moment } from 'moment-timezone';

import PermissionsService from '../permissions/permissions.service';

export const FLOOMX_TIMESTAMP_STRING_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const withTimezone = (timestamp: string | Moment | number, timezone?: string): Moment => {
  return !!timezone ? moment(timestamp).tz(timezone) : moment(timestamp);
};

export const formatTime = (showMins: boolean): string => {
  const mins = showMins ? ':mm' : '';

  return PermissionsService.isInternalRole() ? `h${mins}a z` : `h${mins}a`;
};

export const longFormatWithoutDate = (
  timestamp: string,
  timezone?: string | undefined,
  separator: string = '-',
  showMins = true
): string => {
  return withTimezone(timestamp, timezone).calendar(undefined, {
    sameDay: `[Today ${separator}] ${formatTime(showMins)}`,
    nextDay: `[Tomorrow ${separator}] ${formatTime(showMins)}`,
    lastDay: `[Yesterday ${separator}] ${formatTime(showMins)}`,
    lastWeek: `[Last] ddd ${separator} ${formatTime(showMins)}`,
    nextWeek: `Do MMM ${separator} ${formatTime(showMins)}`,
    sameElse: `Do MMM ${separator} ${formatTime(showMins)}`
  });
};

export const longFormatWithDate = (timestamp: string): string => {
  return moment(timestamp).calendar(undefined, {
    sameDay: '[Today,] Do MMMM',
    nextDay: '[Tomorrow,] Do MMMM',
    lastDay: '[Yesterday,] Do MMMM',
    lastWeek: '[Last] dddd, Do MMMM',
    nextWeek: 'dddd, Do MMMM',
    sameElse: 'dddd, Do MMMM'
  });
};

export const longFormatWithYear = (timestamp: string): string => {
  return moment(timestamp).calendar(undefined, {
    sameDay: '[Today,] Do MMMM YYYY',
    nextDay: '[Tomorrow,] Do MMMM YYYY',
    lastDay: '[Yesterday,] Do MMMM YYYY',
    lastWeek: '[Last] dddd, Do MMMM YYYY',
    nextWeek: 'dddd, Do MMMM YYYY',
    sameElse: 'dddd, Do MMMM YYYY'
  });
};

export const humanReadableDay = (timestamp: string): string => {
  return moment(timestamp).calendar(undefined, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    nextWeek: 'Do MMM',
    sameElse: 'Do MMM'
  });
};

export const dayMonthYear = (timestamp: string | Moment): string => moment(timestamp).format('Y-MM-DD');

export const dateMonth = (timestamp: string | number | Moment): string => moment(timestamp).format('Do MMMM');

export const dateMonthShort = (timestamp: string | number | Moment): string => moment(timestamp).format('Do MMM');

export const dateMonthYear = (timestamp: string | Moment): string => moment(timestamp).format('Do MMMM YYYY');

export const dateMonthYearShort = (timestamp: string | Moment): string => moment(timestamp).format('Do MMM YY');

export const humanDateMonthYear = (timestamp: string | Moment): string => moment(timestamp).format('Do of MMM YYYY');

export const timeOfDay = (timestamp: string | Moment): string => moment(timestamp).utc().format('h:mm a');

export const createIsoTimestamp = (momentTime: Moment): string => {
  return momentTime.format('YYYY-MM-DDTHH:mm:ss.SSS');
};

export const createUnixTimestamp = (momentTime: Moment): number => {
  return momentTime.unix();
};

export const getStartOfDay = (
  offsetFromToday: number = 0,
  format: 'iso' | 'unix' = 'iso',
  timezone?: string
): string | number => {
  const formatter = format === 'iso' ? createIsoTimestamp : createUnixTimestamp;
  const momentInstance = moment();

  if (!!timezone) {
    momentInstance.tz(timezone);
  }

  return formatter(moment(`${momentInstance.format('YYYY-MM-DD')}T00:00:00+00:00`).add(offsetFromToday, 'd'));
};

export const getEndOfDay = (
  offsetFromToday: number = 0,
  format: 'iso' | 'unix' = 'iso',
  timezone?: string
): string | number => {
  const formatter = format === 'iso' ? createIsoTimestamp : createUnixTimestamp;
  const momentInstance = moment();

  if (!!timezone) {
    momentInstance.tz(timezone);
  }

  return formatter(moment(`${momentInstance.format('YYYY-MM-DD')}T23:59:59+00:00`).add(offsetFromToday, 'd'));
};

export const dayWithHourSetISO = (hour: number = 0, timezone: string, addDay: number = 0): string => {
  return createIsoTimestamp(withTimezone(moment(), timezone)
    .add(addDay, 'd')
    .set({
      hour: hour,
      minute: 0,
      second: 0,
      millisecond: 0
    }));
};

export const isTimeGreaterThan = (
  timeToCheck: string | Moment,
  timeConstant: string,
  timezone?: string
): any => {
  return withTimezone(timeToCheck, timezone).format('HH:mm:ss') > timeConstant;
};

export const isSameDay = (
  firstDate: string,
  secondDate: string,
  timezone?: string
): boolean => {
  return withTimezone(firstDate, timezone).isSame(secondDate, 'day');
};

import { GraphQLHelpers } from 'lib';

export const addRootQueryParams = (vars: any): string => {
  return `
    $orderBy: ProductOrderByInput
    ${GraphQLHelpers.addVariable(vars.selectedTypes.length, 'selectedTypes', '[String!]')}
    ${GraphQLHelpers.addVariable(vars.showActiveOnly, 'showActiveOnly', 'Boolean!')}
    ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
  `;
};

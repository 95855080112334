import { Component, Fragment, ReactNode } from 'react';

import { globalHistory } from '@reach/router';
import { inject, observer } from 'mobx-react';
import { Box, Flex, Text } from 'rebass';

import {
  PermissionsService,
  NavService,
  Analytics
} from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { Container } from 'utils/css-mixins';
import { textStyles } from 'utils/rebass-theme';

import { FORM_CONTAINER_WIDTH } from 'features/merchant-onboarding/merchant-onboarding.constants';
import StripeElementsWrapper from 'features/payment/components/stripe-elements-wrapper';
import StripeSubmitElement from 'features/payment/components/stripe-submit-element';
import WrappedStripeForm from 'features/payment/components/wrapped-stripe-form';

import Button from 'components/button';
import FormFooter from 'components/form-footer';
import FormHeader from 'components/form-header';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import PlanDetails from 'components/plan-details';
import StyledLink from 'components/styled-link/styled-link';

import * as Types from './merchant-subscription-completion.types';

class MerchantSubscriptionCompletion extends Component<Types.MerchantSubscriptionCompletionProps> {
  private historyListener = globalHistory.listen((listener): void => {
    if (!listener.location.pathname.includes('merchant/create')) {
      this.props.merchantOnboardingStore!.resetStore();
    }
  });

  constructor(props: Types.MerchantSubscriptionCompletionProps) {
    super(props);
    this.redirect();
  }

  componentWillUnmount = (): void => {
    this.historyListener();
  };

  private redirect = (): void => {
    switch (true) {
      case !PermissionsService.isInternalRole():
        NavService.overview();

        break;

      case !this.props.merchantOnboardingStore!.createdMerchantId && PermissionsService.isInternalRole():
        NavService.merchantCreate('account');

        break;
    }
  };

  private onSubmit = async (): Promise<void> => {
    if (!!this.props.merchantOnboardingStore!.createdMerchantId) {
      try {
        await this.props.onInitialSubscribe!(this.props.merchantOnboardingStore!.createdMerchantId);

        const merchant = this.props.merchantOnboardingStore!.merchantObject;

        if (merchant) {
          this.props.toasterStore?.popToast(`Merchant subscription for ${merchant.companyName} has been successfully created!`, ToastType.Success);
        }

        this.props.merchantOnboardingStore!.resetStore();
        NavService.merchantList();
      } catch (error) {
        this.props.toasterStore?.popToast('We are having issues setting up the subscription', ToastType.Error);
      }
    }
  };

  private setSkipNotification = (): void => {
    this.props.merchantSettingsStore!.setNotification(`${this.props.merchantOnboardingStore!.merchantObject?.companyName} has been created, but you skipped their subscription creation. You can take their card details at any time, by going to their merchant page in FloomX`);
  };

  private goBack = (): void => {
    NavService.merchantCreate('company-info');
  };

  private attemptToSkip = (): void => {
    this.props.modalStore!.triggerModal({
      modalType: 'confirmation',
      data: {
        title: 'Skip merchant subscription?',
        copy: 'You can take their card details at a later date.',
        confirmButtonCopy: 'Skip',
        cancelButtonCopy: 'Go back',
        errorCopy: '',
        confirmButtonAction: async (): Promise<any> => new Promise(async (resolve): Promise<void> => {
          this.setSkipNotification();
          this.onLeave();
          resolve('');
        })
      }
    });
  };

  private onLeave = (): void => {
    NavService.merchantList();
  };

  private canContinue = (): boolean => {
    return this.props.isFormValid() || this.props.isSubmitting;
  };

  private headingCopy = (): string => {
    const companyName = this.props.merchantOnboardingStore!.merchantObject?.companyName;

    return !!companyName
      ? `FloomX subscription card details for ${companyName}`
      : 'Card details for FloomX subscription';
  };

  private renderBody = (): ReactNode => {
    const merchant = this.props.merchantOnboardingStore!.merchantObject;

    switch (true) {
      case !!merchant?.subscription?.stripeSubscriptionId:
        return (
          <Notification
            copy="The merchant has already been subscribed"
            type={NotificationType.Success}
            hasClose={false}
            textAlign="left"
          />
        );

      case !!merchant && !merchant?.subscription?.stripeSubscriptionId:
        return (
          <Fragment>
            <PlanDetails
              merchant={this.props.merchantOnboardingStore?.merchantObject!}
            />
            <StripeElementsWrapper
              hasAcceptedTerms={this.props.hasAcceptedTerms}
              buildFonts={this.props.buildFonts}
              updateStripeValidation={this.props.updateStripeValidation}
              onNameOnCardChange={this.props.onNameOnCardChange}
              storeCardInstance={this.props.storeCardInstance}
              storeStripeInstance={this.props.storeStripeInstance}
              nameOnCard={this.props.nameOnCard}
              validation={this.props.validation}
            />
            <StripeSubmitElement
              hasAcceptedTerms={this.props.hasAcceptedTerms}
              isSubmitting={this.props.isSubmitting}
              setTermsCheckbox={this.props.setTermsCheckbox}
              onSubmit={this.onSubmit}
              isFormValid={this.props.isFormValid}
              context={Analytics.TermsEntryPoint.MerchantCreation}
              isFormInternalFacing={true}
              shouldDisplayTerms={true}
              merchant={this.props.merchantOnboardingStore!.merchantObject!}
            />
          </Fragment>
        );

      default:
        return null;
    }
  };

  render(): ReactNode {
    return (
      <Fragment>
        <FormHeader
          title="Add Merchant"
          buttonCopy="Cancel"
          hasAction={this.props.merchantOnboardingStore!.canSkipOnboardingSteps}
        />
        <Container maxWidth={FORM_CONTAINER_WIDTH}>
          <Box m="100px 0">
            <Text
              css={textStyles.title}
              mb="20px"
            >
              {this.headingCopy()}
            </Text>
            {this.renderBody()}
            <FormFooter>
              <Container maxWidth={FORM_CONTAINER_WIDTH}>
                <Flex
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box
                    as="button"
                    onClick={this.goBack}
                  >
                    <Button
                      size="normal"
                      appearance="secondary"
                      copy="Back"
                      isLoading={false}
                      isDisabled={this.props.isSubmitting}
                    />
                  </Box>
                  <Flex alignItems="center">
                    <Box
                      as="button"
                      mr="20px"
                      onClick={this.attemptToSkip}
                    >
                      <StyledLink>
                        Skip
                      </StyledLink>
                    </Box>
                    <Box
                      as="button"
                      onClick={this.onSubmit}
                      disabled={!this.canContinue()}
                    >
                      <Button
                        size="normal"
                        appearance="primary"
                        copy="Submit"
                        isLoading={this.props.isSubmitting}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Container>
            </FormFooter>
          </Box>
        </Container>
      </Fragment>
    );
  }
}

export default WrappedStripeForm(inject((stores: FxStores): InjectedFxStores => ({
  merchantOnboardingStore: stores.merchantOnboardingStore,
  merchantSettingsStore: stores.merchantSettingsStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(MerchantSubscriptionCompletion)));

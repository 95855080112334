import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';

export const BOOK_ORDER_DELIVERY_SLOT: DocumentNode = gql`
  mutation BookOrderDeliverySlot (
    $orderNo: String
    $timeslot: CourierDeliveryTimeSlotInput
  ) {
    createCourierBooking(where: {
    orderNo: $orderNo
  },
    data: {
      timeSlot: $timeslot
    }) {
      response
    }
  }
`;

import React, { Component, ReactPortal, ReactNode } from 'react';

import { observe, Lambda } from 'mobx';
import { inject, observer } from 'mobx-react';
import { createPortal } from 'react-dom';

import ToasterStore from 'stores/toaster-store/toaster-store';
import { ToastObject } from 'stores/toaster-store/toaster-store.types';

import Toast from './toast';

export interface ToasterProps {
  toasterStore?: ToasterStore;
}

export interface ToasterState {
  toasts: ReactPortal[];
}

class ToasterContainer extends Component<ToasterProps, ToasterState> {
  state = {
    toasts: []
  };

  toasterRoot: any = null;

  componentDidMount(): void {
    this.getToasterRoot();
    this.toasterStoreSubscription();
  }

  componentWillUnmount(): void {
    this.toasterStoreSubscription();
  }

  private getToasterRoot = (): void => {
    this.toasterRoot = document.getElementById('toaster-root') as HTMLElement;
  };

  private toasterStoreSubscription = (): Lambda => {
    return observe<ToasterStore, 'newToast'>(this.props.toasterStore!, 'newToast', ({
      oldValue: oldToast,
      newValue: newToast
    }: any) => {
      if (newToast && (!oldToast || oldToast.id !== newToast.id)) {
        this.popToast(newToast);
      }
    });
  };

  private popToast = (newToastData: ToastObject): void => {
    if (this.toasterRoot) {
      const toastPortal = createPortal(
        (
          <Toast
            key={newToastData.id}
            closeToast={this.closeToast}
            toastCount={this.state.toasts.length + 1}
            data={newToastData}
          />
        ),
        this.toasterRoot
      );

      this.setState({
        toasts: [toastPortal]
      });
    }
  };

  private closeToast = (toastId: string): void => {
    this.setState(prevState => {
      const toasts = prevState.toasts;
      const toastIndex = this.findToastToClose(toasts, toastId);

      toasts.splice(toastIndex, 1);

      return { toasts };
    }, () => {
      this.resetToaster();
    });
  };

  private resetToaster = (): void => {
    if (!this.state.toasts.length) {
      this.props.toasterStore!.resetToasterState();
    }
  };

  private findToastToClose = (toasts: any, toastId: string): number => {
    return toasts.findIndex((toast: ReactPortal) => {
      // @ts-ignore
      return toast.children!.key === toastId;
    });
  };

  render(): ReactNode {
    return [...this.state.toasts];
  }
}

export default inject('toasterStore')(observer(ToasterContainer));

import { FxErrorCodes } from './error.service.types';

export const VALIDATION_CODE_DEFAULTS = {
  [FxErrorCodes.ValidationError]: 'You have validation errors in your submission',
  [FxErrorCodes.NotAuthorised]: 'You are not authorised to access this part of FloomX',
  [FxErrorCodes.NotAuthorisedAction]: 'You are not authorised to perform this action',
  [FxErrorCodes.SessionInvalidated]: 'Your session has been invalidated, please log back in',
  [FxErrorCodes.UserNotExist]: 'You have been logged out as your user no longer exists',
  [FxErrorCodes.MerchantPermissions]: 'You have attempted to access merchant records that you do not have permissions to access',
  [FxErrorCodes.FloomDotComSync]: 'There has been an issue syncing your updates with floom.com. please contact support',
  [FxErrorCodes.FloomDotComLink]: 'There has been an issue linking your updates with floom.com. please contact support'
};

export const HUMAN_READABLE_ERRORS = {
  'product.description is a required field': 'You need a product description',
  'data.variations.update': 'There has been an issue updating your product variations'
};

export interface PeakDateRange {
  from: string;
  to: string;
}

export interface PeakPeriodConfig {
  dates: PeakDateRange[];
  defaultMessage: string;
}

export const PEAK_PERIOD_CONFIG: PeakPeriodConfig = {
  defaultMessage: 'You cannot edit these settings during peak times.',
  dates: []
};

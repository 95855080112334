import React, { Component } from 'react';

import * as Styles from './variation-tabs.styles';
import * as Types from './variation-tabs.types';

export default class GridLayoutItem extends Component<Types.VariationTabsProps> {
  selectVariation = (key: number): void => {
    if (!this.isSelected(key)) this.props.onSelect(key);
  };

  isSelected = (key: number): boolean => key === this.props.selectedIndex;

  render(): React.ReactNode {
    const { variations  } = this.props;

    if (variations && variations.length < 2) return null;

    return (
      <Styles.TabWrapper>
        {
          variations.map((variation, key) => (
            <Styles.Tab
              key={variation.id}
              isActive={this.isSelected(key)}
              onClick={(): void => this.selectVariation(key)}
            >
              {variation.type}
            </Styles.Tab>
          ))
        }
      </Styles.TabWrapper>
    );
  }
}

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { breakpoints, space } from 'utils/rebass-theme';

export const TotalButton = styled(Box)`
  min-width: 140px;

  @media only screen and (max-width: ${breakpoints.medium}) {
      min-width: 110px;

      > div {
        padding: ${space[2]}px;
      }
  }
`;

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

import { EntityListRow } from 'components/entity-list/entity-list.styles';
import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType, TableLayoutEntityConfigProps } from 'components/entity-list/table-layout-entity.types';
import Icon from 'components/icon';

import * as Types from './dpd-upload.types';

export const ActionButton = styled(Box)<any>`
  cursor: ${(props): string => props.isUpdating ? 'not-allowed' : 'pointer'};
  pointer-events: ${(props): string => props.isUpdating ? 'none' : 'auto'};
  padding-top: 7px;

  path {
    stroke: ${(props): string => props.isUpdating ? colors.middleGrey : colors.floomMidnightBlue};
  }
`;

export class DpdUploadListItem extends Component<Types.DpdUploadListItemProps> {
  state = {
    isLoading: false
  };

  private handleDelete = (): void => {
    this.props.onDelete(this.props.item);
  };

  private handleResync = async (): Promise<void> => {
    this.setState({ isLoading: true });

    await this.props.onResync(this.props.item);

    this.setState({ isLoading: false });
  };

  private cellConfig = (): Array<TableLayoutEntityConfigProps & { shouldDisplay: boolean }> => {
    const item = this.props.item;

    const config =  [
      {
        shouldDisplay: this.props.type === 'ready',
        cellType: CellType.Text,
        fixedWidth: '200px',
        props: {
          text: item.order?.merchant?.title
        }
      },
      {
        shouldDisplay: true,
        cellType: CellType.Input,
        fixedWidth: '240px',
        props: {
          type: 'text',
          value: item.orderNo,
          inputWidth: '100%',
          hideSave: true,
          isSaving: this.props.type === 'ready' || this.state.isLoading,
          uniqueId: item.uniqueId,
          onChange: (e: React.ChangeEvent<HTMLInputElement>): void => {
            e.preventDefault();

            this.props.onItemChange(
              item,
              e.target.value,
              'orderNo'
            );
          }
        }
      },
      {
        shouldDisplay: true,
        cellType: CellType.Textarea,
        fixedWidth: '300px',
        props: {
          value: this.props.item.message,
          inputWidth: '100%',
          rows: 5,
          isDisabled: this.state.isLoading,
          onChange: (value: string): void => {
            this.props.onItemChange(
              item,
              value,
              'message'
            );
          }
        }
      },
      {
        shouldDisplay: !!this.props.item.reason?.length && !item.order,
        cellType: CellType.Text,
        fixedWidth: '300px',
        props: {
          title: 'Reason',
          subtitle: this.props.item.reason
        }
      },
      {
        shouldDisplay: true,
        customCss: css`
          margin-left: auto;
        `,
        cellType: CellType.Custom,
        customInnerElement: (
          <Flex alignItems="center">
            { this.props.type === 'error' && (
              <Flex
                mr="30px"
                alignItems="center"
                as="button"
                disabled={this.state.isLoading}
                onClick={this.handleResync}
              >
                <Box mr="10px">
                  Retry
                </Box>
                <ActionButton isUpdating={this.state.isLoading}>
                  <Icon iconName="arrow-full-right" />
                </ActionButton>
              </Flex>
            )}
            <Flex
              alignItems="center"
              as="button"
              disabled={this.state.isLoading}
              onClick={this.handleDelete}
            >
              <Box mr="10px">
                Remove
              </Box>
              <ActionButton isUpdating={this.state.isLoading}>
                <Icon iconName="cross-big" />
              </ActionButton>
            </Flex>
          </Flex>
        )
      }
    ];

    return config.filter(configItem => configItem.shouldDisplay);
  };

  render(): ReactNode {
    if (!this.props.item) return null;

    return (
      <EntityListRow removeOverflow={true}>
        <Flex
          width="100%"
          key={this.props.item.orderNo}
          p="18px 10px"
          alignItems="center"
        >
          <TableLayoutEntity
            config={this.cellConfig()}
          />
        </Flex>
      </EntityListRow>
    );
  }
}

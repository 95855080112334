import { FC, Fragment } from 'react';

import { Box } from 'rebass';

import { ListItem } from 'generated-types.d';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import {
  ListCellSubtitle,
  ListCellTitle
} from 'features/lists/lists.styles';

import {
  ListItemTitle,
  ListItemSubTitle,
  ListGenus
} from '../../single-list.styles';

export const ListItemSimpleTitle: FC<{listItem: ListItem}> = ({ listItem }) => {
  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();

  const itemTitle = listItem?.catalogItem?.primaryName ? listItem?.catalogItem?.primaryName : listItem.sku;
  const itemSubTitle = listItem?.catalogItem?.secondaryName;

  return wsFriendlyNames.isActive ? (
    <Fragment>
      <ListItemTitle>
        {itemTitle}
      </ListItemTitle>
      {!!itemSubTitle && (
        <ListItemSubTitle>
          {itemSubTitle}
        </ListItemSubTitle>
      )}
    </Fragment>
  ) : (
    <ListCellTitle>
      {listItem.sku}
    </ListCellTitle>
  );
};

export const ListItemAdvancedTitle: FC<{listItem: ListItem}> = ({ listItem }) => {
  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();

  const itemTitle = listItem?.catalogItem?.primaryName ? listItem?.catalogItem?.primaryName : listItem.sku;
  const itemSubTitle = listItem?.catalogItem?.secondaryName;

  const genus = listItem.catalogItem?.family?.genus;

  return wsFriendlyNames.isActive ? (
    <Fragment>
      <ListItemTitle>
        {itemTitle}
      </ListItemTitle>
      {!!itemSubTitle && (
        <ListItemSubTitle>
          {itemSubTitle}
        </ListItemSubTitle>
      )}
    </Fragment>
  ) : (
    <Box>
      <ListCellTitle>
        {listItem?.catalogItem?.title || listItem.sku}
      </ListCellTitle>
      {!!genus && (
        <ListGenus>
          <ListCellSubtitle>
            {genus}
          </ListCellSubtitle>
        </ListGenus>
      )}
    </Box>
  );
};

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { capitalise } from 'lib/services/string-format/string-format.service';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';
import * as Yup from 'yup';

import {
  MerchantStage
} from 'generated-types.d';

import {
  LocalisationService,
  MerchantSubscriptionService,
  TimeService,
  ValidationService
} from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import Button from 'components/button';
import FormBuilder from 'components/form-builder';
import GenericModal from 'components/generic-modal';

import {
  SUPPORTED_STAGE_TYPES,
  CONFIRMATION_COPY,
  STAGE_SHIFT_FORM_VALIDATION
} from './stage-shift-modal.constants';
import {
  Container,
  Heading,
  PlanDetailItem,
  PlanDetails,
  SubHeading
} from './stage-shift-modal.styles';
import {
  StageOption,
  StageShiftFormData,
  StageShiftModalProps,
  StageShiftModalState
} from './stage-shift-modal.types';

class StageShiftModal extends Component<StageShiftModalProps, StageShiftModalState> {
  private getSupportedStages = (): StageOption[] => {
    return SUPPORTED_STAGE_TYPES.filter(stage => {
      return stage.id !== this.props.data.merchant.stage.toString();
    });
  };

  state: StageShiftModalState = {
    isSaving: false,
    canStageShift: true,
    stages: this.getSupportedStages(),
    formErrors: [],
    formData: {
      hasConfirmed: false,
      stageShiftReason: '',
      selectedStage: undefined
    }
  };

  private validateForm = async (): Promise<StageShiftFormData> => {
    try {
      return await ValidationService
        .validateAll<StageShiftFormData>(STAGE_SHIFT_FORM_VALIDATION, this.state.formData);
    } catch (error) {
      this.setState({
        formErrors: error.errors
      });

      this.props.toasterStore?.popToast('You have some validation errors', ToastType.Error);

      return Promise.reject(error);
    }
  };

  private changeMerchantStage = async (formData: StageShiftFormData): Promise<void> => {
    try {
      await MerchantSubscriptionService.createProposedMerchantUpdate({
        isConfirmed: formData.hasConfirmed,
        reason: formData.stageShiftReason,
        merchant: {
          connect: {
            id: this.props.data.merchant.id
          }
        },
        merchantStage: formData.selectedStage as MerchantStage
      });

      this.props.toasterStore?.popToast(
        `The FloomX stage for ${this.props.data.merchant.title} will change
        to ${capitalise(formData.selectedStage!.toString())} on ${this.currentPeriodEndDate()}`,
        ToastType.Success
      );
    } catch (error) {
      this.props.toasterStore?.popToast(
        `We're having trouble changing the stage for ${this.props.data.merchant.title}`,
        ToastType.Error
      );

      return Promise.reject(error);
    }
  };

  private onStageShift = async (): Promise<void> => {
    try {
      this.setState({ isSaving: true });

      const formData = await this.validateForm();

      await this.changeMerchantStage(formData);
      await this.props.data.beforeClose();

      this.props.closeModal();
    } catch (error) {
      this.setState({ isSaving: false });
    }
  };

  private currentPeriodEndDate = (): string => {
    return TimeService.dateMonthYearShort(this.props.data.merchant?.subscription?.currentPeriodEndsAt);
  };

  private setValue = (
    value: StageShiftFormData[keyof StageShiftFormData],
    key: keyof StageShiftFormData
  ): void => {
    this.setState(state => {
      return {
        formErrors: state.formErrors?.filter(error => error.path !== key),
        formData: {
          ...state.formData,
          [key]: value
        }
      };
    });
  };

  private findError = (key: keyof StageShiftFormData): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(this.state.formErrors, key);
  };

  private renderForm = (): ReactNode => {
    return (
      <Box mt="10px">
        <FormBuilder
          config={{
            sections: [
              {
                width: '100',
                paddingBottom: '0',
                fields: [
                  {
                    key: 'selectedStageLabel',
                    fieldType: 'labelSmall',
                    copy: 'Select new stage',
                    validationError: undefined
                  },
                  {
                    key: 'selectedStageField',
                    fieldType: 'dropdown',
                    placeholder: 'Select a stage',
                    selected: this.state.formData.selectedStage as string,
                    id: 'stage-shift-new-stage',
                    options: this.state.stages,
                    optionTitleField: 'title',
                    optionValueField: 'id',
                    validationError: this.findError('selectedStage'),
                    onChange: (value): void => {
                      this.setValue(value as MerchantStage, 'selectedStage');
                    }
                  }
                ]
              },
              {
                width: '100',
                paddingBottom: '0',
                fields: [
                  {
                    key: 'stageShiftReasonLabel',
                    fieldType: 'labelSmall',
                    copy: 'Why is the stage changing?',
                    validationError: undefined
                  },
                  {
                    key: 'stageShiftReasonField',
                    fieldType: 'textInput',
                    placeholder: '',
                    maxLength: 100,
                    displayMaxLength: true,
                    value: this.state.formData.stageShiftReason,
                    validationError: this.findError('stageShiftReason'),
                    onChange: (e): void => {
                      this.setValue(e.target.value, 'stageShiftReason');
                    }
                  }
                ]
              },
              {
                paddingBottom: '0',
                width: '100',
                fields: [
                  {
                    key: 'planDetails',
                    fieldType: 'custom',
                    customContent: (
                      <>
                        {this.renderPlanDetails()}
                      </>
                    )
                  }
                ]
              },
              {
                width: '100',
                paddingBottom: '0',
                isHidden: !this.state.formData.selectedStage,
                fields: [
                  {
                    key: 'planChangeConfirmationField',
                    fieldType: 'singleCheckbox',
                    label: this.renderConfirmationCopy(),
                    isSelected: this.state.formData.hasConfirmed,
                    validationError: this.findError('hasConfirmed'),
                    handleSelect: (): void => {
                      this.setValue(!this.state.formData.hasConfirmed, 'hasConfirmed');
                    },
                    customStyles: css`
                      margin: 25px 0 0 10px;
                    `
                  }
                ]
              }
            ]
          }}
        />
      </Box>
    );
  };

  private renderConfirmationCopy = (): string => {
    const newStageName = LocalisationService.localiseMerchantStage(this.state.formData.selectedStage);
    const oldStageName = LocalisationService.localiseMerchantStage(this.props.data.merchant.stage);

    return CONFIRMATION_COPY
      .replace('[MERCHANT_NAME]', this.props.data.merchant?.title)
      .replace('[OLD_STAGE_NAME]', oldStageName)
      .replace('[NEW_STAGE_NAME]', newStageName)
      .replace('[BILLING_END_DATE]', this.currentPeriodEndDate());
  };

  private renderPlanDetails = (): ReactNode => {
    if (!this.state.formData.selectedStage) return null;

    return (
      <PlanDetails>
        <PlanDetailItem>
          <SubHeading>
            Takes effect on
          </SubHeading>
          <Heading>
            {this.currentPeriodEndDate()}
          </Heading>
        </PlanDetailItem>
      </PlanDetails>
    );
  };

  render(): ReactNode {
    return (
      <GenericModal
        title="Change merchant stage"
        closeModal={this.props.closeModal}
        modalOpen={this.props.isOpen}
        confirmButtonText="Schedule"
        confirmButtonAction={this.onStageShift}
        isButtonLoading={this.state.isSaving}
        confirmButtonDisabled={!this.state.canStageShift}
        width={450}
        extraAction={(
          <Box onClick={this.props.closeModal}>
            <Button
              size="normal"
              appearance="secondary"
              copy="Cancel"
              isLoading={false}
              isDisabled={false}
            />
          </Box>
        )}
        innerComponent={(
          <Container>
            {this.renderForm()}
          </Container>
        )}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  toasterStore: stores.toasterStore
}))(observer(StageShiftModal));

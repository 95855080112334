import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const Content = styled.div`
  width: 100%;
`;

export const UnitWrapper = styled(Flex)`
  ${textStyles.subhead};
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  > p {
    margin-bottom: 5px;
  }
`;

export const InputWrapper = styled.div`
  width: 60px;
  margin-left: 10px;
  align-self: flex-end;

  input {
    height: 47px;
  }
`;

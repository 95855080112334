import { DiscountOrderByInput } from 'generated-types.d';

import { TableLayoutHeadingConfigProps } from 'components/entity-list/table-layout-entity.types';

interface DiscountListSortOption {
  title: string;
  key: DiscountOrderByInput;
}

export const LIST_SORT_OPTIONS: DiscountListSortOption[] = [
  {
    title: 'Code A-Z',
    key: DiscountOrderByInput.CodeAsc
  },
  {
    title: 'Code Z-A',
    key: DiscountOrderByInput.CodeDesc
  },
  {
    title: 'Active first',
    key: DiscountOrderByInput.IsActiveDesc
  },
  {
    title: 'Inactive first',
    key: DiscountOrderByInput.IsActiveAsc
  },
  {
    title: 'Most recently edited',
    key: DiscountOrderByInput.LastEditedAtDesc
  },
  {
    title: 'Least recently edited',
    key: DiscountOrderByInput.LastEditedAtAsc
  }
];

export const LIST_HEADING_CONFIG: TableLayoutHeadingConfigProps[] = [
  {
    heading: 'Active',
    fixedWidth: '90px'
  },
  {
    heading: 'Details',
    flexGrow: '1'
  },
  {
    heading: 'Channel',
    fixedWidth: '115px'
  },
  {
    heading: 'Valid from',
    fixedWidth: '115px'
  },
  {
    heading: 'Valid until',
    fixedWidth: '115px'
  },
  {
    heading: 'Last edited',
    fixedWidth: '115px'
  },
  {
    heading: '',
    fixedWidth: '50px'
  },
  {
    heading: '',
    fixedWidth: '150px'
  }
];

import { FC } from 'react';

import css from '@emotion/css';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import hex2rgba from 'hex2rgba';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import BannerImageDesktop from 'assets/images/webinar.jpg';

import Button from 'components/button';
import Icon from 'components/icon';

import * as Styles from './webinar-banner.styles';

// This is so pointless
type BannerContent = {
  title: string;
  ctaPrimary: {
    label: string;
    link: string | NavPages;
  };
  ctaSecondary?: {
    label: string;
    link: string | NavPages;
  };
  description?: string;
  listItems?: {
    text: string;
    articleId?: number;
  }[];
};

export const WebinarBanner: FC = () => {
  const copy = ((): BannerContent => {
    return {
      title: 'Watch our latest webinar!',
      description: `We covered some really helpful topics to help you get the most out of Floom.`,
      ctaPrimary: {
        label: 'Watch now',
        link: 'https://vimeo.com/823446678/09c6abb988'
      },
      listItems: [
        {
          text: `Delivery: Optimizing delivery configurations during peak periods`
        },
        {
          text: `Product tags: Increasing product sales by adding occasion, stem, and color tags`
        },
        {
          text: `Product price: Increase sales with competitive pricing`
        },
        {
          text: `Add-ons: A deep dive into what sells and how to increase average order value`
        },
        {
          text: `Order help: Reduce the time you spend communicating with Floom and increase customer satisfaction`
        }
      ]
    };
  })();

  return (
    <Styles.Container>
      <Styles.BlobLeft
        fill={hex2rgba(colors.floomCyan, 1)}
      />
      <Styles.BlobRight
        fill={hex2rgba(colors.floomOrange, 1)}
      />
      <Styles.Content>
        <Styles.ContentLeft>
          <Flex
            alignItems="center"
            mb="24px"
          >
            <h3>
              {copy.title}
            </h3>
            <div className="ml-3 inline-flex items-center rounded-md bg-green-50 px-3 pt-0.5 pb-1 text-xs font-bold text-green-700 ring-1 ring-inset ring-green-600/20">
              New
            </div>
          </Flex>
          <Styles.Description>
            {copy.description}
          </Styles.Description>
          <Styles.List>
            {copy.listItems?.map((listItem, index) => {
              return (
                <Styles.ListItem key={index}>
                  <Icon
                    iconName="tick-circle"
                    pathFill={colors.floomMidnightBlue}
                    size="normal"
                    styles={css`
                      min-width: 24px
                    `}
                  />
                  <Box
                    ml={2}
                    mt="-2px"
                  >
                    {listItem.text}
                  </Box>
                </Styles.ListItem>
              );
            })}
          </Styles.List>
          <Styles.ButtonsWrapper>
            <Styles.ButtonContainer>
              <Link
                to={copy.ctaPrimary.link}
                aria-label={copy.ctaPrimary.label}
                target="_blank"
                rel="noopener noreferrer"
                css={css`
                  width: 100%;
                `}
              >
                <Button
                  size="normal"
                  copy={copy.ctaPrimary.label}
                  isParentWidth={true}
                  appearance="primary"
                />
              </Link>
            </Styles.ButtonContainer>
            {!!copy.ctaSecondary && (
              <Styles.ButtonContainer>
                <Link
                  to={copy.ctaSecondary.link}
                  aria-label={copy.ctaSecondary.label}
                  css={css`
                    width: 100%;
                  `}
                >
                  <Button
                    size="normal"
                    copy={copy.ctaSecondary.label}
                    isParentWidth={true}
                    appearance="secondary"
                  />
                </Link>
              </Styles.ButtonContainer>
            )}
          </Styles.ButtonsWrapper>
        </Styles.ContentLeft>
        <Styles.ImageDesktop src={BannerImageDesktop} />
      </Styles.Content>
    </Styles.Container>
  );
};

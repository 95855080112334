import { FC, ReactNode, Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import pluralize from 'pluralize';
import { Box } from 'rebass';

import { ListType } from 'generated-types.d';

import { NavService } from 'lib';

import { ListPriceRange } from 'features/lists/components/list-price-range/list-price-range';
import { getListCurrency, getTotalListPrice } from 'features/lists/lists.helpers';

import Button from 'components/button';

import {
  Container,
  Heading,
  CounterDivider,
  SummaryGroup,
  SubHeading,
  Wrapper
} from './single-list-footer.styles';
import {
  ButtonConfig,
  SingleListFooterProps
} from './single-list-footer.types';

const SingleListFooterView: FC<SingleListFooterProps> = ({
  conversationsStore,
  selectedListStore,
  limitedView
}) => {
  if (!selectedListStore?.list) return null;

  const { items } = selectedListStore.list;

  const navigateToCheckout = (): void => {
    NavService.singleListCheckout(selectedListStore!.list!.id);
  };

  const renderSubHeading = (): ReactNode => {
    switch (selectedListStore!.list?.type) {
      default:
      case ListType.Simple:
        return 'List Summary';

      case ListType.Advanced:
        return renderCounter();
    }
  };

  const renderHeading = (): ReactNode => {
    switch (selectedListStore!.list?.type) {
      default:
      case ListType.Simple:
        return renderCounter();

      case ListType.Advanced:
        return renderPriceRange();
    }
  };

  const renderPriceRange = (): ReactNode => {
    const lowestPrice = getTotalListPrice(selectedListStore!.list?.items, Math.min);
    const highestPrice = getTotalListPrice(selectedListStore!.list?.items, Math.max);

    return (
      <ListPriceRange
        min={lowestPrice || 0}
        max={highestPrice || 0}
        currency={getListCurrency(selectedListStore!.list)}
      />
    );
  };

  const renderCounter = (): ReactNode => {
    const itemsAmount: number = selectedListStore!.list?.items?.length || 0;
    const unitAmount = items?.reduce?.((acc, curr) => {
      return acc + (Number(curr.quantity) || 0);
    }, 0);

    return (
      <Fragment>
        {`${itemsAmount} ${pluralize('item', items?.length)}`} <CounterDivider>|</CounterDivider> {`${unitAmount} ${pluralize('unit', unitAmount)}`}
      </Fragment>
    );
  };

  const handleShareToConversationClick = (): void => {
    conversationsStore!.setShareListListId(selectedListStore!.list!.id);
    NavService.conversationsContact(conversationsStore!.shareList.contactId || '');
  };

  const getButtonConfig = (): ButtonConfig | null => {
    if (conversationsStore?.shareList?.inShareFlow) {
      return {
        text: 'Share',
        onClick: handleShareToConversationClick
      };
    }

    if (limitedView) {
      return null;
    }

    return {
      text: 'Checkout',
      onClick: navigateToCheckout
    };
  };

  const renderButton = (): ReactNode => {
    const buttonConfig = getButtonConfig();

    if (!buttonConfig) return null;

    return (
      <Box
        as="button"
        minWidth="120px"
        disabled={hasNoItems}
        onClick={buttonConfig.onClick}
      >
        <Button
          size="normal"
          isParentWidth={true}
          copy={buttonConfig.text}
          appearance="primary"
          isDisabled={hasNoItems}
        />
      </Box>
    );
  };

  const hasNoItems = !selectedListStore!.list?.items?.length;

  return (
    <Wrapper>
      <Container>
        <SummaryGroup>
          <SubHeading>
            {renderSubHeading()}
          </SubHeading>
          <Heading>
            {renderHeading()}
          </Heading>
        </SummaryGroup>
        {renderButton()}
      </Container>
    </Wrapper>
  );
};

export const SingleListFooter = inject((stores: FxStores): InjectedFxStores => ({
  selectedListStore: stores.selectedListStore,
  conversationsStore: stores.conversationsStore
}))(observer(SingleListFooterView));

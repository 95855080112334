import { Order } from 'generated-types.d';

import ToasterStore from 'stores/toaster-store/toaster-store';

import { TableLayoutHeadingConfigProps } from 'components/entity-list/table-layout-entity.types';

export interface DpdUploadState {
  isLoading: boolean;
  isDragging: boolean;
  isSubmitting: boolean;
  items: DpdStatusItem[];
}

export interface DpdUploadProps {
  toasterStore?: ToasterStore;
}

export interface DpdUploadListItemProps {
  item: DpdStatusItem;
  type: ItemGroupType;
  onDelete: (item: DpdStatusItem) => void;
  onResync: (item: DpdStatusItem) => Promise<void>;
  onItemChange: (item: DpdStatusItem, note: string, key: keyof Pick<DpdStatusItem, 'orderNo' | 'message'>) => void;
}

export interface DpdStatusItem {
  uniqueId: string;
  originalOrderNo: string;
  orderNo: string;
  message: string;
  originalMessage?: string;
  dpdParcelNo: string;
  dpdConsignmentNo: string;
  order: Order | null;
  reason: string;
}

export type ItemGroupType = 'error' | 'ready';

export interface ItemGroup {
  id: ItemGroupType;
  title: string;
  tableHeader: TableLayoutHeadingConfigProps[];
  items: DpdStatusItem[];
}

export const ERROR_LIST_HEADING_CONFIG: TableLayoutHeadingConfigProps[] = [
  {
    heading: 'Modify Order #',
    fixedWidth: '240px'
  },
  {
    heading: 'Floom order note',
    fixedWidth: '200px'
  }
];

export const READY_LIST_HEADING_CONFIG: TableLayoutHeadingConfigProps[] = [
  {
    heading: 'Merchant',
    fixedWidth: '200px'
  },
  {
    heading: 'Order #',
    fixedWidth: '240px'
  },
  {
    heading: 'Floom order note',
    fixedWidth: '300px'
  }
];

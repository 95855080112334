import React, { Component, ReactNode } from 'react';

import { Link } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { Flex, Text } from 'rebass';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './merchant-detail-header.styles';
import * as Types from './merchant-detail-header.types';

class MerchantDetailHeader extends Component<Types.ModalCheckoutHeaderProps> {
  config = {
    headingBg: 'background',
    color: colors.floomMidnightBlue,
    iconName: 'pie-one-third'
  };

  closeButton = (): JSX.Element => (
    <Flex
      as={Link}
      to={this.props.closeTo || '/orders/list/today'}
      state={{
        fromModal: true
      }}
      ml="auto"
    >
      <Icon
        iconName="cross-medium"
        pathFill={this.config.color}
      />
    </Flex>
  );

  render(): ReactNode {
    return (
      <Styles.MerchantDetailHeader
        config={this.config}
        alignItems="center"
        justifyContent="space-between"
      >
        <Text>
          {this.props.merchant?.title || ''}
        </Text>
        {this.closeButton()}
      </Styles.MerchantDetailHeader>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantSettingsStore: stores.merchantSettingsStore
}))(observer(MerchantDetailHeader));

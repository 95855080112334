import styled from '@emotion/styled';

import { breakpoints, borderRadius, colors, space, fontSizes } from 'utils/rebass-theme';

import {
  getPlaceholderStyle,
  inputReset
} from 'components/field-text/field-text.styles';

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  pointer-events: none;
  height: 20px;
  width: 20px;

  @media only screen and (max-width: ${breakpoints.small}) {
    height: 10px;
    width: 10px;
    top: unset;
    left: ${space[3]}px;
  }
`;

export const ClearIconWrapper = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 24px;
  height: 24px;

  @media only screen and (max-width: ${breakpoints.small}) {
    right: ${space[3]}px;
    margin-right: 0;
  }
`;

export const InputLoader = styled.div`
  position: absolute;
  right: 20px;
`;

export const ResultsWrapper = styled.div`
  position: absolute;
  z-index: 110;
  width: 100%;
  border: 1px solid ${colors.floomMidnightBlue};
`;

export const Input = styled.input`
  width: 100%;
  height: 60px;
  padding-left: ${space[5]}px;
  background: none;
  font-size: ${fontSizes.base}px;
  color: ${colors.floomMidnightBlue};
  background: ${colors.lightGrey};
  border-radius: ${borderRadius.large};

  &:focus {
    outline: none;
  }

  ${inputReset}
  ${getPlaceholderStyle()};

  @media only screen and (max-width: ${breakpoints.small}) {
    height: 40px;
    padding-left: ${space[4]}px;
    font-size: ${fontSizes.small}px;
  }
`;

import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box, Card as RebassCard } from 'rebass';

import { breakpoints, colors, space } from 'utils/rebass-theme';

import { ConversationView } from '../../context/ContactContext';

const borderColour = hex2rgba(colors.middleGrey, 0.5);

export const Wrapper = styled(RebassCard)<{view: ConversationView}>`
  max-width: 280px;
  height: 100%;
  flex: 0 0 100%;
  overflow: auto;
  ${({ view }) => view !== ConversationView.CONTACTS_ONLY ? `border-right: 1px solid ${borderColour}` : ''} ;

  @media (max-width: ${breakpoints.large}) {
    max-width: none;
    width: 100%;
    position: absolute;
    left: ${({ view }) => view !== ConversationView.MESSAGES ? '0' : '-100%'};
    transition: left 0.1s ease-in;
  }
`;

export const Header = styled(Box)`
  height: 2.4em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${space[3]}px;
  padding-left: ${space[2]}px;
  margin-bottom: ${space[3]}px;
  border-bottom: 1px solid ${borderColour};
`;

import { gql } from '@apollo/client';

import {
  SUPPLIER_FRAGMENT
} from '../fragments/supplier.fragments';

export const SUPPLIERS = gql`#graphql
  query Suppliers (
    $where: SupplierWhereInput
  ) {
    suppliers(
      where: $where
    ) {
      ...SupplierFragment
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

export const SUPPLIER_DELIVERY_HUBS = gql`#graphql
  query SuppliersDeliveryHubs(
    $where: SupplierDeliveryHubWhereInput = {}
  ) {
    supplierDeliveryHubs(
      where: $where
    ) {
      id
      title
      supplier {
        id
        active
        name
      }
    }
  }
`;

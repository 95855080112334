import { FC, ReactNode, useEffect } from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Text } from 'rebass';

import { PermissionsService } from 'lib';

import { SupplierIntegratedMerchant, SupplierIntegrationAuth } from 'stores/wholesale/wholesale-settings-store/wholesale-settings-store';

import { colors, fontSizes } from 'utils/rebass-theme';

import { SupplierIntegrationModalData } from 'features/modal-dialogue/components/modals/supplier-integration-modal/supplier-integration-modal.types';
import { SUPPLIER_INTEGRATION_MAP } from 'features/wholesale/wholesale.constants';

import CheckboxToggle from 'components/checkbox-toggle';
import Icon from 'components/icon';
import NoResultsGeneric from 'components/no-results-generic';
import SectionHeading from 'components/section-heading';
import WithLoading from 'components/with-loading';

import {
  SupplierCard,
  SupplierCardTitle,
  SupplierCardContent,
  SupplierCardRow,
  SupplierRowTitle,
  SupplierEditButton,
  StatusLabel,
  SuppliersWrapper
} from './supplier-integrations.styles';
import { SupplierIntegrationsProps } from './supplier-integrations.types';

const SupplierIntegrations: FC<SupplierIntegrationsProps> = ({
  modalStore,
  merchantStore,
  wholesaleSettingsStore
}) => {
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (): Promise<void> => {
    if (!canManageSupplierIntegrations()) return;

    const id = merchantStore!.merchant?.id;

    if (id) {
      wholesaleSettingsStore?.fetchSupplierIntegrations(id);
    }
  };

  const canManageSupplierIntegrations = (): boolean => {
    return !!merchantStore!.merchant && PermissionsService.isInternalRole();
  };

  const hasExistingIntegration = (supplier: SupplierIntegratedMerchant): boolean => {
    return !!supplier?.integration;
  };

  const openModal = (supplier: SupplierIntegratedMerchant): void => {
    modalStore!.triggerModal<SupplierIntegrationModalData>({
      modalType: 'supplierIntegration',
      data: {
        onSave: saveSupplierIntegration,
        supplier: supplier
      }
    });
  };

  const saveSupplierIntegration = (integration: SupplierIntegrationAuth, supplier: SupplierIntegratedMerchant): Promise<void> => {
    const merchantId = merchantStore!.merchant?.id;
    const hasIntegration = hasExistingIntegration(supplier);

    if (hasIntegration) {
      return wholesaleSettingsStore!.updateSupplierIntegration(integration, supplier);
    }

    if (merchantId && !hasIntegration) {
      return wholesaleSettingsStore!.createSupplierIntegration(integration, supplier, merchantId);
    }

    return Promise.reject('wrong input');
  };

  const onStatusChange = (newStatus: boolean, supplier: SupplierIntegratedMerchant): void => {
    wholesaleSettingsStore?.updateSupplierIntegrationStatus(newStatus, supplier);
  };

  const renderNoResults = (): ReactNode => {
    return (
      <Box mt="20px">
        <NoResultsGeneric
          icon="leaf-no-results"
          heading="No suppliers found"
          copy=""
        />
      </Box>
    );
  };

  if (!canManageSupplierIntegrations()) {
    return null;
  }

  return (
    <Box mt="50px">
      <SectionHeading
        title="Supplier integrations"
        hasSpacing={false}
      />
      <WithLoading
        isLoading={wholesaleSettingsStore!.isLoadingSupplierIntegrations}
        hasNoResults={!wholesaleSettingsStore!.supplierIntegrations?.length && !wholesaleSettingsStore!.isLoadingSupplierIntegrations}
        renderNoResults={renderNoResults}
      >
        <SuppliersWrapper>
          {
            wholesaleSettingsStore!.supplierIntegrations.map(supplier => {
              const supplierMap = SUPPLIER_INTEGRATION_MAP[supplier.integrationType];
              const supplierDisplay = supplier?.integration?.authentication?.record[supplierMap.displayField];
              const hasIntegration = hasExistingIntegration(supplier);

              if (!supplierMap) {
                return null;
              }

              return (
                <SupplierCard key={supplier.id}>
                  <SupplierCardTitle>{supplier.name}</SupplierCardTitle>
                  <SupplierCardContent>
                    <SupplierCardRow mb="20px">
                      <Icon
                        iconName='settings'
                        pathFill={colors.middleGrey}
                      />
                      <Box ml="16px">
                        <SupplierRowTitle>Status:</SupplierRowTitle>
                        <Text fontSize={fontSizes.small}>{hasIntegration ? 'Integrated' : 'Not integrated'}</Text>
                      </Box>
                    </SupplierCardRow>
                    <SupplierCardRow mb="8px">
                      <Icon
                        iconName='key'
                        pathFill={colors.middleGrey}
                      />
                      <Box ml="16px">
                        <SupplierRowTitle>Details:</SupplierRowTitle>
                        <Text fontSize={fontSizes.small}>{supplierDisplay || '--'}</Text>
                      </Box>
                    </SupplierCardRow>
                    <SupplierCardRow mb="8px">
                      <SupplierEditButton onClick={() => openModal(supplier)}>
                        <Icon
                          iconName='edit'
                          pathFill={colors.floomMidnightBlue}
                        />
                        {hasIntegration ? 'Update integration' : 'Create integration'}
                      </SupplierEditButton>
                    </SupplierCardRow>
                    {hasIntegration && (
                      <SupplierCardRow>
                        <StatusLabel>Active</StatusLabel>
                        <CheckboxToggle
                          isChecked={supplier?.integration?.active}
                          activeCopy="Yes"
                          inactiveCopy="No"
                          switchBgActive={colors.validationText}
                          switchBgInactive={colors.errorText}
                          controlValue={true}
                          onChange={(newStatus): void => onStatusChange(newStatus, supplier)}
                        />
                      </SupplierCardRow>
                    )}
                  </SupplierCardContent>
                </SupplierCard>
              );
            })
          }
        </SuppliersWrapper>
      </WithLoading>
    </Box>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore,
  merchantStore: stores.merchantStore,
  wholesaleSettingsStore: stores.wholesaleSettingsStore
}))(observer(SupplierIntegrations));

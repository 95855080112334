import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InputIconWrapper = styled.button<{ isDisabled: boolean }>`
  position: absolute;
  pointer-events: ${({ onClick }): string => !!onClick ? 'all' : 'none'};
  cursor:  ${({ onClick }): string => !!onClick ? 'pointer' : 'auto'};
  opacity:  ${({ isDisabled }): number => isDisabled ? 0.7 : 1};
  top: 15px;
  right: 15px;
`;

export const InputIcon = css`
  width: 20px;
  height: 20px;
`;

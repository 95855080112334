import AccountStore from './account/account-store';
import AddOnEditStore from './add-on-edit-store/add-on-edit-store';
import AddOnsStore from './add-ons-store/add-ons-store';
import CatalogItemStore from './catalog-item/catalog-item-store';
import ChatWidgetStore from './chat-widget/chat-widget-store';
import CheckoutListStore from './checkout-list/checkout-list-store';
import ConversationsStore from './conversations/conversations-store';
import CourierListStore from './courier-list/courier-list-store';
import CourierStore from './courier/courier-store';
import CustomSiteSettingsStore from './custom-site-settings-store/custom-site-settings-store';
import DeliveryStore from './delivery/delivery-store';
import DiscountCreateEditStore from './discount-create-edit/discount-create-edit-store';
import DiscountsStore from './discounts/discounts-store';
import LightboxStore from './lightbox-store/lightbox-store';
import ListsStore from './lists/lists-store';
import MerchantDetailStore from './merchant-detail/merchant-detail-store';
import MerchantHolidaysStore from './merchant-holidays/merchant-holidays-store';
import MerchantOnboardingStore from './merchant-onboarding/merchant-onboarding-store';
import MerchantSettingsStore from './merchant-settings/merchant-settings-store';
import MerchantStore from './merchant/merchant-store';
import ModalStore from './modal-store/modal-store';
import NavigationStore from './navigation/navigation-store';
import OrdersStore from './orders/orders-store';
import OverviewStore from './overview/overview-store';
import PaymentOnboardingStore from './payment-onboarding/payment-onboarding-store';
import PaymentsStore from './payments/payments-store';
import ProductEditStore from './product-edit-store/product-edit-store';
import ProductsStore from './products-store';
import PromotedListsStore from './promoted-lists-store/promoted-lists-store';
import SelectedListStore from './selected-list/selected-list-store';
import SelectedWholesalePreOrderStore from './selected-wholesale-pre-order/selected-wholesale-pre-order-store';
import ToasterStore from './toaster-store/toaster-store';
import UIStore from './ui-store';
import UserStore from './user/user-store';
import DraftWholesaleOrderStore from './wholesale/draft-wholesale-order-store/draft-wholesale-order-store';
import WholesaleFiltersStore from './wholesale/wholesale-filters-store/wholesale-filters-store';
import WholesaleOrdersStore from './wholesale/wholesale-orders-store/wholesale-orders-store';
import WholesalePaymentStore from './wholesale/wholesale-payment-store/wholesale-payment-store';
import WholesaleSettingsStore from './wholesale/wholesale-settings-store/wholesale-settings-store';
import WholesaleShopBasketStore from './wholesale/wholesale-shop-basket-store/wholesale-shop-basket-store';
import WholesaleShopStore from './wholesale/wholesale-shop-store/wholesale-shop-store';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const stores = () => ({
  accountStore: new AccountStore(),
  addOnsStore: new AddOnsStore(),
  addOnEditStore: new AddOnEditStore(),
  catalogItemStore: new CatalogItemStore(),
  chatWidgetStore: new ChatWidgetStore(),
  checkoutListStore: new CheckoutListStore(),
  conversationsStore: new ConversationsStore(),
  courierStore: new CourierStore(),
  courierListStore: new CourierListStore(),
  customSiteSettingsStore: new CustomSiteSettingsStore(),
  deliveryStore: new DeliveryStore(),
  discountsStore: new DiscountsStore(),
  discountCreateEditStore: new DiscountCreateEditStore(),
  draftWholesaleOrderStore: new DraftWholesaleOrderStore(),
  lightboxStore: new LightboxStore(),
  listsStore: new ListsStore(),
  merchantStore: new MerchantStore(),
  merchantHolidaysStore: new MerchantHolidaysStore(),
  merchantSettingsStore: new MerchantSettingsStore(),
  merchantDetailStore: new MerchantDetailStore(),
  merchantOnboardingStore: new MerchantOnboardingStore(),
  modalStore: new ModalStore(),
  navigationStore: new NavigationStore(),
  ordersStore: new OrdersStore(),
  overviewStore: new OverviewStore(),
  paymentsStore: new PaymentsStore(),
  paymentOnboardingStore: new PaymentOnboardingStore(),
  productsStore: new ProductsStore(),
  productEditStore: new ProductEditStore(),
  selectedListStore: new SelectedListStore(),
  selectedWholesalePreOrderStore: new SelectedWholesalePreOrderStore(),
  toasterStore: new ToasterStore(),
  uiStore: new UIStore(),
  userStore: new UserStore(),
  wholesaleFiltersStore: new WholesaleFiltersStore(),
  wholesalePaymentStore: new WholesalePaymentStore(),
  wholesaleOrdersStore: new WholesaleOrdersStore(),
  wholesaleSettingsStore: new WholesaleSettingsStore(),
  wholesaleShopStore: new WholesaleShopStore(),
  wholesaleShopBasketStore: new WholesaleShopBasketStore(),
  promotedListsStore: new PromotedListsStore()
});

const store = stores();

export type InjectedMobxStores = Partial<typeof store>;

export const buildTestStore = (keys: Array<keyof typeof store>): InjectedMobxStores => {
  const duplicatedStore = stores();

  return keys.reduce<InjectedMobxStores>((testStores, key) => {
    return {
      ...testStores,
      [key]: duplicatedStore[key]
    };
  }, {});
};

export default store;

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import ProductEditStore from 'stores/product-edit-store/product-edit-store';

import FormFieldWrapper from 'components/create-labeled-component';
import DropdownNative from 'components/dropdown-native/dropdown-native';

interface ProductTypeProps {
  productEditStore?: ProductEditStore;
}

class ProductType extends Component<ProductTypeProps> {
  render(): React.ReactNode {
    return (
      <FormFieldWrapper
        title="Product Type"
      >
        <DropdownNative
          id="select-product-type"
          placeholder="Product Type"
          optionTitleField="title"
          optionValueField="id"
          selected={this.props.productEditStore!.selectedProductTypeId}
          options={this.props.productEditStore!.types}
          onChange={this.props.productEditStore!.updateSelectedProductTypeId}
          fullWidth={true}
        />
      </FormFieldWrapper>
    );
  }
}

export default inject('productEditStore')(observer(ProductType));

import { FC, Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box, Flex } from 'rebass';

import { WholesaleOrderLineItem } from 'generated-types.d';

import { StringFormat } from 'lib';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import { ListItemColour } from 'features/lists/components/list-item-colour/list-item-colour';
import {
  ListCellTitle,
  ListCellSubtitle,
  ListItemTitle
} from 'features/lists/lists.styles';

export const SingleOrderItemTitle: FC<{
  orderItem: WholesaleOrderLineItem;
}> = ({ orderItem }) => {
  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();

  const title = orderItem.snapshot?.title || orderItem.listItem?.sku || '--';
  const genus = orderItem.snapshot?.genus || orderItem.listItem?.catalogItem?.family.genus;
  const listItemColour = orderItem.listItem?.catalogItem?.colour || '';

  const primaryName = orderItem.snapshot?.primary_name || title;

  const renderColour = (): ReactNode => {
    if (!!listItemColour) {
      return (
        <ListItemColour
          type="hit"
          status="disabled"
          colour={listItemColour}
        />
      );
    }

    if (!!orderItem?.snapshot?.colour) {
      const colour = StringFormat.capitalise(orderItem?.snapshot?.colour);

      return wsFriendlyNames.isActive ?
        colour
        : (
          <Fragment>
            <span css={css`margin-right: 8px`}>—</span> {colour}
          </Fragment>
        );
    }
  };

  return (
    <Box>
      { wsFriendlyNames.isActive ? (
        <Fragment>
          <ListItemTitle>
            {primaryName}
          </ListItemTitle>
          <ListCellSubtitle>
            <Flex>
              <Box
                as="span"
              >
                {renderColour()}
              </Box>
            </Flex>
          </ListCellSubtitle>
        </Fragment>
      ) : (
        <Fragment>
          <ListCellTitle>
            {title}
          </ListCellTitle>
          { !!genus && (
            <ListCellSubtitle>
              <Flex>
                <span>
                  {genus}
                </span>
                <Box
                  as="span"
                  ml="10px"
                >
                  {renderColour()}
                </Box>
              </Flex>
            </ListCellSubtitle>
          )}
        </Fragment>
      )}
    </Box>
  );
};

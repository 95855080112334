import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import debounce from 'lodash.debounce';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import { Box } from 'rebass';

import { PermissionsService } from 'lib';

import FormFieldWrapper from 'components/create-labeled-component';
import DropdownNative from 'components/dropdown-native';
import FieldDatePicker from 'components/field-date-picker';
import GenericModal from 'components/generic-modal';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import RadioList from 'components/radio-list';

import * as Styles from './date-range-modal.styles';
import * as Types from './date-range-modal.types';

const DATE_STRING_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

class DateRangeModal extends Component<Types.DateRangeModalProps, Types.DateRangeModalState> {
  static defaultProps: Partial<Types.DateRangeModalProps> = {
    data: {
      title: 'Date range picker',
      errorCopy: 'Something went wrong',
      fromCopy: 'From',
      toCopy: 'To',
      initialMerchantId: '',
      shouldDisplayMerchantSelection: false,
      shouldAutofocus: false,
      onConfirm: (): any => null
    }
  };

  private initialEndDate = (): moment.Moment => {
    if (moment(this.props.data?.toDate).isValid()) {
      return moment(this.props.data?.toDate);
    }

    return moment().add(1, 'day');
  }

  state: Types.DateRangeModalState = {
    isLoading: false,
    error: null,
    startAt: moment(this.props.data?.fromDate).isValid() ? moment(this.props.data?.fromDate).format(DATE_STRING_FORMAT) : '',
    endAt: this.initialEndDate().format(DATE_STRING_FORMAT),
    selectedRadioOption: this.props.data?.formOption || 'date_range',
    shouldDisplayNotification: false,
    selectedMerchant: this.props.data.initialMerchantId
  };

  private delayNotification = debounce(() => {
    this.setState({
      shouldDisplayNotification: true
    });
  }, 1000);

  private dateSelectionOptions: Types.DateSelectionOption[] = [
    {
      title: 'Multiple days',
      slug: 'date_range'
    },
    {
      title: 'One day',
      slug: 'single_day'
    }
  ];

  private onConfirm = async (): Promise<any> => {
    this.setState({ isLoading: true });

    this.delayNotification();

    try {
      await this.props.data.onConfirm(
        moment(this.state.startAt).format('YYYY-MM-DD'),
        moment(this.isDateRange() ? this.state.endAt : this.state.startAt).format('YYYY-MM-DD'),
        this.state.selectedMerchant!
      );

      this.props.closeModal();
    } catch (error) {
      this.delayNotification.cancel();

      this.setState({
        error: `We are having trouble ${this.props.data.errorCopy}.`,
        shouldDisplayNotification: false
      });
    }

    this.delayNotification.cancel();

    this.setState({
      isLoading: false,
      shouldDisplayNotification: false
    });
  };

  private onOptionChange = (value: Types.DateSelectionSlug): void => {
    this.setState({
      selectedRadioOption: value,
      error: null
    });
  };

  private isEndBeforeStart = (value: Date): boolean => {
    return moment(this.state.endAt).isSameOrBefore(moment(value));
  };

  private onChangeStart = (value: Date): void => {
    const endAt = this.isEndBeforeStart(value)
      ? moment(value).add(1, 'day')
      : moment(this.state.endAt);

    this.setState((): any => ({
      startAt: moment(value).format(DATE_STRING_FORMAT),
      endAt: endAt.format(DATE_STRING_FORMAT),
      error: null
    }));
  };

  private onChangeEnd = (value: Date): void => {
    const daysToAdd = moment(value).isSameOrBefore(moment(this.state.startAt).format(DATE_STRING_FORMAT), 'day') ? 1 : 0;

    this.setState((): any => ({
      endAt: moment(value).add(daysToAdd, 'day').format(DATE_STRING_FORMAT),
      error: null
    }));
  };

  private onMerchantSelect = (value: string): void => {
    this.setState({
      selectedMerchant: value
    });
  };

  private isDateRange = (): boolean => this.state.selectedRadioOption === 'date_range';

  private shouldDisplayMerchantSelection = (): boolean => PermissionsService.isInternalRole() && this.props.data.shouldDisplayMerchantSelection;

  private shouldDisplayTimeoutCopy = (): boolean => this.state.shouldDisplayNotification;

  render(): ReactNode {
    return (
      <GenericModal
        title={this.props.data.title}
        closeModal={this.props.closeModal}
        modalOpen={this.props.isOpen}
        confirmButtonText="Save"
        confirmButtonAction={this.onConfirm}
        confirmButtonDisabled={!Boolean(this.state.selectedMerchant) || !moment(this.state.startAt).isValid()}
        isButtonLoading={this.state.isLoading}
        width={380}
        innerContentStyles={css`
          min-height: 480px;
        `}
        contentStyles={{
          overflow: 'visible !important'
        }}
        innerComponent={(
          <Styles.Container>
            { this.shouldDisplayMerchantSelection() && (
              <Box mb="20px">
                <DropdownNative
                  id="date-range-modal-merchant-select"
                  placeholder="Select a merchant"
                  selected={this.state.selectedMerchant!}
                  options={this.props.merchantStore!.merchantList}
                  optionTitleField="title"
                  optionValueField="id"
                  fullWidth={true}
                  onChange={this.onMerchantSelect}
                />
              </Box>
            )}
            {!this.props.data?.formOption && (
              <Box mb="30px">
                <RadioList
                  itemValueField="slug"
                  itemTitleField="title"
                  orientation="horizontal"
                  items={this.dateSelectionOptions}
                  selectedItem={this.state.selectedRadioOption}
                  onChange={(value: any): void => this.onOptionChange(value)}
                />
              </Box>
            )}
            <Box>
              <FormFieldWrapper title={(this.isDateRange() || !!this.props.data?.formOption) ? this.props.data.fromCopy! : 'On'}>
                <FieldDatePicker
                  pickerType="popover"
                  onChange={this.onChangeStart}
                  format="L"
                  value={this.state.startAt}
                  shouldAutofocus={!!this.props.data?.shouldAutofocus}
                  initialMonth={this.props.data.initialMonth}
                  disabledDays={this.props.data?.disabledDays
                    ? this.props.data?.disabledDays
                    : [{ before: new Date() }]}
                />
              </FormFieldWrapper>
            </Box>
            { this.isDateRange() && (
              <Box>
                <FormFieldWrapper title={this.props.data.toCopy!}>
                  <FieldDatePicker
                    pickerType="popover"
                    onChange={this.onChangeEnd}
                    format="L"
                    value={this.state.endAt}
                    isDisabled={!this.isDateRange()}
                    disabledDays={{
                      before: moment(this.state.startAt).add(1, 'day').toDate()
                    }}
                  />
                </FormFieldWrapper>
              </Box>
            )}
            { this.shouldDisplayTimeoutCopy() && (
              <Notification
                type={NotificationType.Progress}
                hasIcon={false}
                hasClose={false}
                copy={this.props.data.timeoutCopy || ''}
              />
            )}
            { Boolean(this.state.error) && (
              <Notification
                type={NotificationType.Error}
                hasIcon={false}
                hasClose={false}
                copy={this.state.error || ''}
              />
            )}
          </Styles.Container>
        )}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore
}))(observer(DateRangeModal));

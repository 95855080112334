import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import FormFieldWrapper from 'components/create-labeled-component';
import FieldText from 'components/field-text';

import * as Styles from '../create-variation-tab.styles';

import { InnerTabComponentProps } from './inner-tab-components.types';

class Dimensions extends Component<InnerTabComponentProps> {
  render(): React.ReactNode {
    return (
      <FormFieldWrapper
        title="Size"
        optional={true}
      >
        <Styles.SizeWrapper>
          <FieldText
            value={this.props.variation.dimensions.width || ''}
            size="normal"
            type="number"
            placeholder="Width (cm)"
            onChange={(event: any): void => this.props.variation.updateDimension('width', event.target.value)}
            error={this.props.variation.dimensions.width <= 0
              && this.props.productEditStore!.areVariationDimensionsInvalid(this.props.variation.dimensions)}
            errorMessage="Width must be provided"
          />
          <FieldText
            value={this.props.variation.dimensions.height || ''}
            size="normal"
            type="number"
            placeholder="Height (cm)"
            onChange={(event: any): void => this.props.variation.updateDimension('height', event.target.value)}
            error={this.props.variation.dimensions.height <= 0
              && this.props.productEditStore!.areVariationDimensionsInvalid(this.props.variation.dimensions)}
            errorMessage="Height must be provided"
          />
          <FieldText
            value={this.props.variation.dimensions.length || ''}
            size="normal"
            type="number"
            placeholder="Length (cm)"
            onChange={(event: any): void => this.props.variation.updateDimension('length', event.target.value)}
            error={this.props.variation.dimensions.length <= 0
              && this.props.productEditStore!.areVariationDimensionsInvalid(this.props.variation.dimensions)}
            errorMessage="Length must be provided"
          />
        </Styles.SizeWrapper>
      </FormFieldWrapper>
    );
  }
}

export default inject('productEditStore')(observer(Dimensions));

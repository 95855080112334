import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { mq } from 'utils/css-mixins/css-mixins';

export const ListHeading: any = styled(Flex)`
  margin-top: 15px;
  margin-bottom: 20px;

  @media (min-width: 55em) {
    margin-top: 30px;
  }
`;

export const Header: any = styled(Flex)`
  flex-direction: row;
  
  @media (min-width: 55em) {
    align-items: center;
  }
    
  ${mq.lessThan('medium')} {
    flex-direction: column;
  }
`;

export const OrderCheckContainer: any = styled(Box)`
  margin-left: auto;

  ${mq.lessThan('medium')} {
    margin-left: 0;
    margin-top: 20px;
  }
`;

export const BackArrow = css`
  width: 15px;
  height: 15px;

  ${mq.lessThan('medium')} {
    width: 13px;
    height: 13px
  }
`;

import moment from 'moment';
import store from 'stores';

import {
  WholesaleOrder,
  Query,
  WholesaleOrderWhereUniqueInput,
  QueryWholesalePreOrdersArgs,
  WholesalePreOrderOrderByInput,
  WholesalePreOrderStatus,
  WholesalePreOrderConnection,
  Mutation,
  QueryWholesaleOrdersArgs,
  WholesaleOrderStates,
  WholesaleOrderOrderByInput
} from 'generated-types.d';

import { GraphQL } from 'lib';

import {
  CURRENT_OPEN_ORDERS,
  FINALISE_WHOLESALE_ORDER,
  WHOLESALE_ORDER,
  WHOLESALE_ORDER_PAGINATION,
  WHOLESALE_ORDERS
} from 'features/wholesale/graphql/queries/orders/wholesale-order-queries';
import {
  WHOLESALE_PRE_ORDERS
} from 'features/wholesale/graphql/queries/pre-order/pre-order-queries';
import { WholesaleOrderGroups, WholesalePreOrderGroups } from 'features/wholesale/wholesale.types';

import { ORDER_LIST_ITERATION_COUNT } from '../../../wholesale.constants';

export type PreOrderGroups = WholesalePreOrderGroups | { Merchant: WholesalePreOrderConnection | null };

export default class WholesaleOrdersAPIService {
  private preOrderGroups: PreOrderGroups = {
    Open: null,
    Processing: null,
    Complete: null,
    Cancelled: null,
    Merchant: null
  };

  public fetchOrder = async (id: string): Promise<any> => {
    store.wholesaleOrdersStore.setLoadingOrderDetail();

    try {
      const result = await GraphQL.query<Pick<Query, 'wholesaleOrder'>>(WHOLESALE_ORDER, { id });

      store.wholesaleOrdersStore.setSelectedOrder(result.data.wholesaleOrder!);
      store.wholesaleOrdersStore.resetLoadingOrderDetail();
    } catch (error) {
      store.wholesaleOrdersStore.loadingError(error);
      store.wholesaleOrdersStore.resetLoadingOrderDetail();
    }
  };

  public fetchCurrentOpenOrders = async (): Promise<any> => {
    const merchantId = store.merchantStore.merchant?.id || '';

    store.wholesaleOrdersStore.setOpenOrderLoading();

    const wholesaleOrderArgs: Pick<QueryWholesaleOrdersArgs, 'where' | 'orderBy' | 'first'> = {
      orderBy: WholesaleOrderOrderByInput.CreatedAtDesc,
      where: {
        status: WholesaleOrderStates.Open,
        deliveryDetails: {
          cutOffDate_gte: moment().utc().format()
        },
        merchant: {
          id: merchantId
        }
      }
    };

    try {
      const result = await GraphQL.query<Pick<Query, 'wholesaleOrders'>>(CURRENT_OPEN_ORDERS, wholesaleOrderArgs);

      store.wholesaleOrdersStore.setCurrentOpenOrders(result.data.wholesaleOrders || null);
      store.wholesaleOrdersStore.resetOpenOrderLoading();
    } catch (error) {
      store.wholesaleOrdersStore.loadingError(error);
      store.wholesaleOrdersStore.resetOpenOrderLoading();
    }
  };

  public fetchOrders = async (): Promise<any> => {
    const merchantId = store.merchantStore.merchant?.id || '';
    const counts = store.wholesaleOrdersStore.orderPaginationCounts;

    try {
      const result = await GraphQL.query<WholesaleOrderGroups>(WHOLESALE_ORDERS(merchantId, counts), { merchantId });

      store.wholesaleOrdersStore.setOrders(result.data);
      store.wholesaleOrdersStore.resetLoading();
    } catch (error) {
      store.wholesaleOrdersStore.loadingError(error);
      store.wholesaleOrdersStore.resetLoading();
    }
  };

  public fetchPreOrders = async (): Promise<any> => {
    await Promise.all(Object.keys(this.preOrderGroups).map(async (group): Promise<void> => {
      const args: QueryWholesalePreOrdersArgs = {
        first: ORDER_LIST_ITERATION_COUNT,
        orderBy: group === 'Processing' ? WholesalePreOrderOrderByInput.StopProcessingAtAsc : WholesalePreOrderOrderByInput.StartProcessingAtAsc,
        where: {
          deletedAt: null,
          cancelledAt: null,
          status_in: group === 'Merchant' ? ['Open', 'Processing'] : [WholesalePreOrderStatus[group]],
          merchant: {
            id: store.merchantStore.merchant?.id
          }
        }
      };

      try {
        const result = await GraphQL.query<Pick<Query, 'wholesalePreOrdersConnection'>>(WHOLESALE_PRE_ORDERS, args);

        this.preOrderGroups[group] = result.data.wholesalePreOrdersConnection;
      } catch (error) {
        store.wholesaleOrdersStore.loadingError(error);
      }
    }));

    store.wholesaleOrdersStore.setPreOrders(this.preOrderGroups);
  };

  public paginateOrders = async (orderState: any): Promise<any> => {
    const merchantId = store.merchantStore.merchant?.id || '';
    const counts = store.wholesaleOrdersStore.orderPaginationCounts;
    const vars = {
      merchantId: merchantId,
      orderState: orderState,
      skip: counts[orderState]
    };

    try {
      const result = await GraphQL.query<Pick<Query, 'wholesaleOrdersConnection'>>(WHOLESALE_ORDER_PAGINATION(merchantId), vars);

      store.wholesaleOrdersStore.appendOrders(result.data.wholesaleOrdersConnection, orderState);
      store.wholesaleOrdersStore.resetLoading();
    } catch (error) {
      store.wholesaleOrdersStore.loadingError(error);
      store.wholesaleOrdersStore.resetLoading();
    }
  };

  public paginatePreOrders = async (group: any): Promise<any> => {
    const counts = store.wholesaleOrdersStore.preOrderPaginationCounts;

    const args: QueryWholesalePreOrdersArgs = {
      first: ORDER_LIST_ITERATION_COUNT,
      skip: counts[group],
      orderBy: group === 'Processing' ? WholesalePreOrderOrderByInput.StopProcessingAtAsc : WholesalePreOrderOrderByInput.StartProcessingAtAsc,
      where: {
        status_in: group === 'Merchant' ? ['Open', 'Processing'] : [WholesalePreOrderStatus[group]],
        merchant: {
          id: store.merchantStore.merchant?.id
        }
      }
    };

    try {
      const result = await GraphQL.query<Pick<Query, 'wholesalePreOrdersConnection'>>(WHOLESALE_PRE_ORDERS, args);

      store.wholesaleOrdersStore.appendPreOrders(result.data.wholesalePreOrdersConnection, group);
      store.wholesaleOrdersStore.resetLoading();
    } catch (error) {
      store.wholesaleOrdersStore.loadingError(error);
      store.wholesaleOrdersStore.resetLoading();
    }
  };

  public finaliseOrder = async (order: WholesaleOrder): Promise<any> => {
    const where: WholesaleOrderWhereUniqueInput = {
      id: order.id,
      merchant: {
        id: order?.merchant?.id
      }
    };

    try {
      return GraphQL.query<Pick<Mutation, 'finaliseWholesaleOrder'>>(FINALISE_WHOLESALE_ORDER, { where });
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

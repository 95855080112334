import { gql } from '@apollo/client';

import { DISCOUNT_FRAGMENT } from '../fragments/discount.fragments';

export const CREATE_DISCOUNT = gql`
  mutation CreateDiscount($data: DiscountCreateInput!) {
    createDiscount(data: $data) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount(
    $data: DiscountUpdateInput!,
    $where: DiscountWhereUniqueInput!
  ) {
    updateDiscount(
      data: $data,
      where: $where
    ) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

import styled from '@emotion/styled';

import {
  FullBleedTextStyleConfig
} from './full-bleed-text.types';

export const Wrapper = styled.div<{ config: FullBleedTextStyleConfig }>`
  padding: 50px 30px;
  text-align: center;
  background-color: ${({ config }): string => config.background};
  color: ${({ config }): string => config.text};

  @media (min-width: 900px) {
    padding: 120px 30px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const ContentWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

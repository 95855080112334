import { FC } from 'react';

import { ListItemType } from 'generated-types.d';

import { colors } from 'utils/rebass-theme';

import { TabButton } from 'features/lists/components/list-tabs/list-tab-button';

import { TabsWrapper, TabWrapper } from './list-item-tabs.styles';

type ListItemTabsProps = {
  content: any;
  shouldHideDisabled: boolean;
  onSelectItem: (item: ListItemType) => void;
  selectedItem: string;
}

export const ListItemTabs: FC<ListItemTabsProps> = ({
  content,
  shouldHideDisabled,
  onSelectItem,
  selectedItem
}) => {
  return (
    <TabsWrapper>
      {Object.keys(content).map((category, index) => {
        const isDisabled = content[category].items.length === 0;
        const isSelected = selectedItem === category;
        const colour = isDisabled ? colors.middleGrey : colors.floomMidnightBlue;

        if (shouldHideDisabled && isDisabled) {
          return null;
        }

        return (
          <TabWrapper key={index}>
            <TabButton
              isDisabled={isDisabled}
              colour={colour}
              isSelected={isSelected}
              title={content[category].title}
              onClick={() => {
                if (!isSelected) {
                  onSelectItem(ListItemType[category]);
                }
              }}
            />
          </TabWrapper>
        );
      })
      }
    </TabsWrapper>
  );
};

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  PermissionsService
} from 'lib';

import StripeElementsWrapper from 'features/payment/components/stripe-elements-wrapper';
import StripeSubmitElement from 'features/payment/components/stripe-submit-element';
import WrappedStripeForm from 'features/payment/components/wrapped-stripe-form';

import GenericModal from 'components/generic-modal';

import * as Types from './subscription-payment-modal.types';

class SubscriptionPaymentModal extends Component<Types.SubscriptionPaymentModalProps> {
  private onSubmit = async (): Promise<void> => {
    try {
      await this.props.data.onSubmit({
        onInitialSubscribe: this.props.onInitialSubscribe,
        onUpdateSubscription: this.props.onUpdateSubscription
      });

      this.props.closeModal();
    } catch (error) {
      this.props.toasterStore!.popErrorToast('your card details', 'send');
    }
  };

  private renderHeader = (): ReactNode => {
    if (!this.props.data.renderHeader) return null;

    return this.props.data.renderHeader();
  };

  private renderModal = (): ReactNode => {
    return (
      <GenericModal
        modalOpen={this.props.isOpen}
        closeModal={this.props.closeModal}
        title={this.props.data.title}
        width={550}
        shouldHideFooter={true}
        innerComponent={(
          <Box p="20px">
            {this.renderHeader()}
            <StripeElementsWrapper
              hasAcceptedTerms={this.props.hasAcceptedTerms}
              buildFonts={this.props.buildFonts}
              updateStripeValidation={this.props.updateStripeValidation}
              onNameOnCardChange={this.props.onNameOnCardChange}
              storeCardInstance={this.props.storeCardInstance}
              storeStripeInstance={this.props.storeStripeInstance}
              nameOnCard={this.props.nameOnCard}
              validation={this.props.validation}
            />
            <StripeSubmitElement
              hasAcceptedTerms={this.props.hasAcceptedTerms}
              isSubmitting={this.props.isSubmitting}
              setTermsCheckbox={this.props.setTermsCheckbox}
              onSubmit={this.onSubmit}
              isFormValid={this.props.isFormValid}
              context={this.props.data.termsEntryPoint}
              merchant={this.props.data.merchant}
              isModal={true}
              buttonCopy={this.props.data.submitButtonCopy}
              shouldDisplayTerms={this.props.data.shouldDisplayTerms}
              isFormInternalFacing={PermissionsService.isInternalRole()}
            />
          </Box>
        )}
      />
    );
  };

  render(): ReactNode {
    return this.renderModal();
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  toasterStore: stores.toasterStore
}))(observer(WrappedStripeForm(SubscriptionPaymentModal)));

import { navigate } from 'gatsby';
import {
  ModalNavigateOptions,
  NavPages,
  ModalType,
  DiscountCreateEditFormType
} from 'global.types';

import { OrderPageRoute } from 'features/orders/orders.types';

export default class NavService {
  public static orders(): void {
    navigate(NavPages.OrdersHome);
  }

  public static planConfirmation(): void {
    navigate(NavPages.PlanConfirmation);
  }

  public static conversations(): void {
    navigate(NavPages.Conversations);
  }

  public static conversationsContact(contactId: string): void {
    navigate(`${NavPages.Conversations}${contactId}`);
  }

  public static discountsList(): void {
    navigate(NavPages.Discounts);
  }

  public static discountCreateEdit(formType: DiscountCreateEditFormType, id?: string): void {
    navigate(NavService.discountCreateEditUrl(formType, id));
  }

  public static discountCreateEditUrl(formType: DiscountCreateEditFormType, id?: string): string {
    const discountId = id || '';

    return `${NavPages.Discounts}${formType}/${discountId}`;
  }

  public static listsList(): void {
    navigate(NavPages.Lists);
  }

  public static listCreate(): void {
    navigate(`${NavPages.Lists}create/details`);
  }

  public static singleList(id: string): void {
    navigate(`${NavPages.Lists}${id}`);
  }

  public static singleListCheckout(id: string): void {
    navigate(`${NavPages.Lists}checkout/${id}`);
  }

  public static onboardingHome(): void {
    navigate(NavPages.OnboardingHome);
  }

  public static onboardingFloomPayment(): void {
    navigate(NavPages.OnboardingFloomPayment);
  }

  public static onboardingConfirmation(): void {
    navigate(NavPages.OnboardingConfirmation);
  }

  public static onboardingStripeConnect(): void {
    navigate(NavPages.OnboardingStripeConnect);
  }

  public static onboardingSubscription(): void {
    navigate(NavPages.OnboardingSubscription);
  }

  public static onboardingSubscriptionError(): void {
    navigate(NavPages.OnboardingSubscriptionError);
  }

  public static bookCourier(orderNo: string): void {
    navigate(`/orders/courier/${orderNo}`);
  }

  public static ordersList(pageRoute: OrderPageRoute): void {
    navigate(`${NavPages.OrdersList}${pageRoute}`);
  }

  public static productList(options: {persist?: boolean} = { persist: false }): void {
    const routeOptions = {
      state: {
        persist: !!options?.persist
      }
    };
    navigate(`${NavPages.ProductsList}`, routeOptions);
  }

  public static productCreate(id: string, productTypeId: string): void {
    navigate(`${NavPages.ProductsCreate}pre-create-${id}/${productTypeId}`);
  }

  public static productEdit(id: string): void {
    navigate(`${NavPages.ProductsEdit}${id}`);
  }

  public static productEditTab(productId: string, tab: string, productTypeId: string): void {
    navigate(`${NavPages.ProductsEdit}${productId}/${productTypeId}/${tab}`);
  }

  public static productEditVariation(productId: string, variationId: string): void {
    navigate(`${NavPages.ProductsEdit}${productId}/recipe/${variationId}`, {
      replace: true
    });
  }

  public static addOnCreate(id: string): void {
    navigate(`${NavPages.AddOnsCreate}${id}`);
  }

  public static addOnEdit(id: string): void {
    navigate(`${NavPages.AddOnsEdit}${id}`);
  }

  public static addOnsList(): void {
    navigate(NavPages.AddOnsList);
  }

  public static overview(queryString: string = ''): void {
    navigate(`${NavPages.Overview}${queryString}`);
  }

  public static login(): void {
    navigate(NavPages.Login);
  }

  public static orderDetail(orderId: string, closeTo: string = `${NavPages.OrdersList}today`): void {
    const closeUrl = closeTo.includes('list') ? closeTo : `${NavPages.OrdersList}today`;
    const options = NavService.buildModalOptions(
      'order-detail',
      orderId,
      closeTo
    );
    navigate(`${closeUrl}/detail/${orderId}/`, options);
  }

  public static wholesale(): void {
    navigate(NavPages.Wholesale);
  }

  public static wholesalePreOrder(id: string): void {
    navigate(`${NavPages.WholesalePreOrder}${id}`);
  }

  public static wholesaleTradeLineList(category: string): void {
    navigate(`/${NavPages.WholesaleItems}${category}/`);
  }

  public static settings(): void {
    navigate(NavPages.Settings);
  }

  public static accountDetails(): void {
    navigate(`${NavPages.Settings}account`);
  }

  public static paymentDetails(): void {
    navigate(`${NavPages.Settings}payments`);
  }

  public static merchantList(): void {
    navigate(NavPages.MerchantList);
  }

  public static merchantCreate(path: 'account' | 'company-info' | 'shop-info' | 'card-details'): void {
    navigate(`${NavPages.MerchantCreate}${path}`);
  }

  public static wholesaleOrders(): void {
    navigate(NavPages.WholesaleOrders);
  }

  public static wholesaleOrderDetail(id: string): void {
    navigate(`${NavPages.WholesaleOrders}${id}/`);
  }

  public static wholesaleManageOrder(id: string): void {
    navigate(`${NavPages.WholesaleOrders}${id}/manage`);
  }

  public static merchantDetail(
    id: string,
    shouldReplace: boolean = false
  ): void {
    const additionalModalOptions = {};
    const options = NavService.buildModalOptions(
      'merchant-detail',
      id,
      NavPages.MerchantList,
      additionalModalOptions,
      shouldReplace
    );

    navigate(`${NavPages.MerchantDetail}${id}/`, options);
  }

  public static wholesaleItemDetail(
    id: string,
    shouldReplace: boolean = false,
    additionalOptions: any = {}
  ): void {
    const modalPath = `${NavPages.WholesaleItem}${id}`;
    const closeTo = NavPages.WholesaleShop;

    const options = NavService.buildModalOptions(
      'wholesale-shop-listing',
      id,
      closeTo,
      additionalOptions,
      shouldReplace
    );

    navigate(modalPath, options);
  }

  public static myPlan(): void {
    navigate(NavPages.MyPlan);
  }

  public static isOrderDetail = (path: string): boolean => path.includes('/detail/');

  private static buildModalOptions = (
    type: ModalType,
    id: string,
    closeTo: string,
    additionalOptions: any = {},
    shouldReplace: boolean = false
  ): ModalNavigateOptions => {
    return {
      replace: shouldReplace,
      state: {
        preventScroll: true,
        closeTo: closeTo,
        modal: {
          type,
          id,
          additionalOptions
        }
      }
    };
  };
}

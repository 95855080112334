import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { Card, mq } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const Wrapper: any = styled(Flex)`
  width: 25%;

  ${mq.lessThan('xLarge')} {
    width: calc(100% / 3);
  }

  ${mq.lessThan('large')} {
    width: calc(100% / 2);
  }

  ${mq.lessThan('medium')} {
    width: 100%;
  }
`;

export const CourierBookingCardContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-grow: 2;
  flex-direction: column;
`;

export const CourierBookingCard = styled(Box)`
  ${Card};
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Body = styled(Box)`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px 25px;
  font-size: 14px;
  background: ${colors.uiFills};
`;

export const OrderItemTableNoteContainer = styled(Box)<any>`
  ${(props): any => props.containsMultipleNotes && `
    ${OrderItemTableNote} {
      :first-of-type {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      :last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    `
}
`;

export const OrderItemTableNote = styled(Flex)<{ isFloom: boolean; canEdit: boolean }>`
  ${textStyles.body};
  cursor: ${(props): string => props.canEdit ? 'pointer' : 'auto'};
  padding: 10px 15px 12px;
  border-radius: 3px;
  background-color: ${(props): string => props.isFloom ? '#D4F8F3' : '#EAE7FF'}
`;

export const OrderNotesIcon = styled.div<any>`
  height: 24px;
  width: 24px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: -4px;
    left: -4px;
    border-radius: 100%;
    border: ${({ status }: any): any => colors[status.headingBg]} 2px solid;
    background: ${colors.floomRed};
    transform: scale(${({ hasNotes }: any): any => hasNotes ? '1' : '0'});
    opacity: ${({ hasNotes }: any): any => hasNotes ? '1' : '0'};
    transition: transform .2s ease-in-out,
                opacity .2s ease-in-out;
  }

  svg path {
    fill: ${({ status }: any): any => status.color === 'middleGrey' ? colors.floomMidnightBlue : colors[status.color]};
  }
`;

export const OrderNotesButton = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  height: 100%;
  cursor: pointer;
`;

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import ProductEditStore from 'stores/product-edit-store/product-edit-store';

import ProductCategories from './product-tab-components/product-categories';
import ProductChannels from './product-tab-components/product-channels';
import ProductDescription from './product-tab-components/product-description';
import ProductInternalDescription from './product-tab-components/product-internal-description';
import ProductTitle from './product-tab-components/product-title';
import ProductType from './product-tab-components/product-type';

export interface CreateProductTabProps {
  productEditStore?: ProductEditStore;
}

class CreateProductTab extends Component<CreateProductTabProps> {
  render(): React.ReactNode {
    return (
      <div>
        <ProductTitle />
        <ProductType />
        <ProductChannels />
        <ProductDescription />
        <ProductInternalDescription />
        <ProductCategories />
      </div>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore
}))(observer(CreateProductTab));

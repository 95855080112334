import { ApolloError, FetchResult } from '@apollo/client';
import {
  OrderEdge,
  Order,
  OrderUpdateInput,
  OrderWhereInput,
  Merchant
} from 'generated-types';
import moment from 'moment-timezone';
import store from 'stores';
import { getOrderNo } from 'utils';

import {
  GraphQL,
  PermissionsService,
  Auth,
  OrderService
} from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import OrdersStore from 'stores/orders/orders-store';
import { ToastType } from 'stores/toaster-store/toaster-store.types';

import {
  UPDATE_ORDER,
  SINGLE_ORDER_QUERY,
  UPDATE_NEW_STATE
} from 'features/orders/graphql/mutations/update-order';
import {
  ORDER_QUICK_SEARCH_QUERY
} from 'features/orders/graphql/queries/order-queries';
import {
  ExtendedOrder,
  OrderStatusSlug
} from 'features/orders/orders.types';

class OrdersAPIService {
  private OrdersStore = store.ordersStore as OrdersStore;

  private ToasterStore = store.toasterStore;

  private UserStore = store.userStore;

  private MerchantStore = store.merchantStore as MerchantStore;

  private buildVariables = (vars: any = {}): any => {
    return  {
      ...vars,
      merchantId: this.UserStore.merchantId,
      count: this.OrdersStore.paginationIncrement
    };
  };

  public quickSearch = async (variables: any = {}): Promise<OrderEdge[]> => {
    const vars = this.buildVariables(variables);

    return GraphQL.query(ORDER_QUICK_SEARCH_QUERY(vars), vars, 'no-cache')
      .then((results: any): any => results.data.ordersConnection.edges)
      .catch((error: ApolloError) => {
        this.OrdersStore.setError('quickSearch', error);
        this.ToasterStore.popToast('We were unable to search for orders at this time', ToastType.Error);
      });
  };

  public fetchOrder = async (orderNo: string): Promise<ExtendedOrder> => {
    return GraphQL.query(SINGLE_ORDER_QUERY(this.setMerchantId()), {
      orderNo: orderNo,
      merchantId: this.setMerchantId()
    })
      .then(({ data }: FetchResult<{ order: ExtendedOrder }>): any => {
        this.updateNewStatus(data!.order.orderNo, data!.order);
        this.MerchantStore.triggerOrderPauseEvents(data!.order.merchant.sameDayOrderPauseStatus);

        return data!.order;
      })
      .catch((error: ApolloError) => {
        this.OrdersStore.setError('orderList', error);
        this.ToasterStore.popErrorToast(`order (orderNo: ${getOrderNo(orderNo)})`, 'retrieve');

        return Promise.reject(error);
      });
  };

  public updateNewStatus = async (orderNo: string, order: Order, callback: () => void = (): any => null): Promise<any> => {
    if (this.shouldUpdateNewFlag(order)) {
      const updateOrder: FetchResult<{ updateOrder: Order }> = await GraphQL.mutate(UPDATE_NEW_STATE, { orderNo });

      const status = updateOrder.data?.updateOrder.merchant?.sameDayOrderPauseStatus;

      this.MerchantStore.triggerOrderPauseEvents(status);

      callback();
    }
  };

  public updateOrder = async (orderUpdates: OrderUpdateInput, orderNo: string): Promise<ExtendedOrder> => {
    return GraphQL.mutate(UPDATE_ORDER, { orderUpdates, orderNo })
      .then(({ data }: FetchResult<{ updateOrder: Order }>): any => {
        this.MerchantStore.triggerOrderPauseEvents(data!.updateOrder.merchant.sameDayOrderPauseStatus);

        return data!.updateOrder;
      })
      .catch(error => {
        this.OrdersStore.setError('orderEdit', error);
        this.ToasterStore.popErrorToast('order', 'update');

        return Promise.reject(error);
      });
  };

  public fetchActiveOrdersCount = async (merchant?: Merchant | null): Promise<number> => {
    const timezone = merchant?.timezone || 'Europe/London';
    const pastOrdersCutOff = moment().tz(timezone).utc().subtract(1, 'day').endOf('day').toISOString();
    const params: OrderWhereInput = {
      status: {
        slug_in: [OrderStatusSlug.FailedDelivery, OrderStatusSlug.Open, OrderStatusSlug.Prepared, OrderStatusSlug.OnTheWay]
      },
      deliverAt_lt: pastOrdersCutOff
    };

    if (merchant) {
      params.merchant = {
        id: merchant.id
      };
    }
    const activeOrdersCount = await OrderService.fetchOrderCount(params);
    this.OrdersStore.setActivePastordersCount(activeOrdersCount);

    return activeOrdersCount;
  }

  private shouldUpdateNewFlag = (order: Order): boolean => !PermissionsService.isInternalRole() && order.new && order.status.slug !== 'delivered';

  private setMerchantId = (): string => {
    if (PermissionsService.isInternalRole()) return '';

    return Auth.getUserMerchantId();
  };
}

export default new OrdersAPIService();

import { FC, FormEvent, Fragment, useMemo, useState } from 'react';

import { ParseError, parsePhoneNumberWithError, parsePhoneNumberFromString } from 'libphonenumber-js';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';
import * as Yup from 'yup';

import { Currency } from 'generated-types.d';

import { CreateContactInput } from 'types/conversations.types';

import { ValidationService } from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { space } from 'utils/rebass-theme';

import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import GenericModal from 'components/generic-modal';

import { ConversationContactNewModalProps } from './conversation-contact-new-modal.types';

enum EditMode { Create, Update }

const ConversationContactNewModal: FC<ConversationContactNewModalProps> = ({
  closeModal,
  isOpen,
  conversationsStore,
  toasterStore,
  merchantStore,
  data
}) => {
  const editMode = data?.contact ? EditMode.Update : EditMode.Create;
  const isNumberReadonly = !!data?.contact?.conversation;

  const [formValues, setFormValues] = useState<CreateContactInput>(data?.contact || { name: '', phone: '' });
  const [formValueErrors, setFormValueErrors] = useState<Yup.TestMessageParams[] | undefined>();
  const [isPosting, setIsPosting] = useState(false);

  const validation = Yup.object().shape<CreateContactInput>({
    name: Yup
      .string()
      .required('Please enter the suppliers name')
      .matches(/^[a-zA-Z0-9 &-]+$/, `Please use alphanumeric characters for the supplier's name`),
    phone: Yup
      .string()
      .required('Please enter phone number')
      .transform(value => {
        const defaultCountry = !value.startsWith('+') ? 'US' : undefined;

        return parsePhoneNumberFromString(value, defaultCountry)?.number || value;
      })
      .test(
        'is-phone',
        'Must be a valid phone number',
        function(value: string) {
          try {
            return parsePhoneNumberWithError(value).isValid();
          } catch (error) {
            if (error instanceof ParseError) {
              switch(error.message) {
                case 'INVALID_COUNTRY': return this.createError({ message: 'Invalid country code' });
                case 'TOO_SHORT': return this.createError({ message: 'Phone number is too short' });
                case 'TOO_LONG': return this.createError({ message: 'Phone number is too long' });
                case 'INVALID_LENGTH': return this.createError({ message: 'Phone number length is invalid' });

                case 'NOT_A_NUMBER': return this.createError({ message: 'Must be a number' });

                default: return this.createError({ message: 'Must be a valid phone number' });
              }
            }
            console.error(error);

            return false;
          }
        }
      )
  });

  const displayValidationError = (error: Yup.ValidationError, field: string): void => {
    return setFormValueErrors([
      {
        ...error.params as Yup.TestMessageParams,
        path: field,
        label: error.message
      }
    ]);
  };

  const validateField = async (field: string, value: any): Promise<void> => {
    try {
      await ValidationService.validateField(validation, value, field);
      setFormValueErrors(formValueErrors?.filter(error => error?.path !== field));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        displayValidationError(error, field);
      } else {
        toasterStore?.popToast('Error', ToastType.Error);
      }
    }
  };

  const onChange = (value: string, field: keyof CreateContactInput): void => {
    setFormValues(prevState => ({ ...prevState, [field]: value }));
  };

  const onBlur = async (value: string, field: keyof CreateContactInput): Promise<void> => {
    await validateField(field, value);
  };

  const saveSupplier = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    try {
      setIsPosting(true);

      const validFormInput = await ValidationService.validateAll(validation, formValues);
      setFormValueErrors(undefined);

      if (editMode === EditMode.Update) {
        await conversationsStore?.updateContact({
          id: data.contact!.id,
          name: validFormInput.name,
          phone: isNumberReadonly ? undefined : validFormInput.phone,
          merchantId: merchantStore?.merchant?.id
        });
      } else {
        await conversationsStore?.createContact({ ...validFormInput, merchantId: merchantStore?.merchant?.id });
      }

      closeModal();
    } catch (error: any) {
      setIsPosting(false);
      console.error(error);

      if (error instanceof Yup.ValidationError) {
        const validationErrors = error.inner.map(inner => ({ ...inner.params as Yup.TestMessageParams, label: inner.message }));
        setFormValueErrors(validationErrors);
      } else {
        toasterStore?.popToast('There was an error while saving supplier', ToastType.Error);
      }
    }
  };

  const findError = (key: keyof CreateContactInput): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(formValueErrors, key);
  };

  const labelConfig = useMemo((): { name: string; phone: string } => {
    switch (merchantStore?.merchant?.currency) {
      case Currency.Gbp: {
        return {
          name: 'e.g. London Wholesale Flowers',
          phone: 'e.g. +44 7700 900976'
        };
      }

      default: {
        return {
          name: 'e.g. NYC Wholesale Flowers',
          phone: 'e.g. (311) 555-2368'
        };
      }
    }
  }, [merchantStore?.merchant?.currency]);

  const FORM_CONFIG = (): FormBuilderConfig => {
    return {
      sections: [
        {
          width: '100',
          paddingBottom: `${space[3]}px`,
          fields: [
            {
              key: 'nameLabel',
              fieldType: 'labelSmall',
              copy: 'Name',
              subCopy: labelConfig.name
            },
            {
              key: 'nameInput',
              fieldType: 'textInput',
              value: formValues.name,
              validationError: findError('name'),
              onChange: (event): void => onChange(event.target.value, 'name'),
              onBlur: async (event): Promise<void> => await onBlur(event.target.value, 'name'),
              id: 'nameInput'
            },
            {
              key: 'phoneLabel',
              fieldType: 'labelSmall',
              copy: 'Phone',
              subCopy: labelConfig.phone,
              isHidden: isNumberReadonly
            },
            {
              key: 'phoneInput',
              fieldType: 'textInput',
              value: formValues.phone,
              validationError: findError('phone'),
              isHidden: isNumberReadonly,
              onChange: (event): void => onChange(event.target.value, 'phone'),
              onBlur: async (event): Promise<void> => await onBlur(event.target.value, 'phone')
            }
          ]
        }
      ]
    };
  };

  const title = useMemo(() => {
    return editMode === EditMode.Create ? 'Add Supplier' : 'Edit Supplier';
  }, [editMode]);

  return (
    <Fragment>
      <GenericModal
        title={title}
        closeModal={closeModal}
        modalOpen={isOpen}
        confirmButtonText={title}
        confirmButtonAction={saveSupplier}
        isButtonLoading={isPosting}
        innerContentStyles={{
          padding: '0 20px'
        }}
        innerComponent={(
          <Box>
            <form>
              <FormBuilder
                config={FORM_CONFIG()}
              />
            </form>
          </Box>
        )}
      />
    </Fragment>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  conversationsStore: stores.conversationsStore,
  toasterStore: stores.toasterStore,
  merchantStore: stores.merchantStore
}))(observer(ConversationContactNewModal));


import { FC, useEffect } from 'react';

import { inject, observer } from 'mobx-react';

import { PhoneContact } from 'types/conversations.types';

import { PermissionsService } from 'lib';

import ConversationsStore from 'stores/conversations/conversations-store';
import MerchantStore from 'stores/merchant/merchant-store';

import PlantsIconImage from 'assets/images/wholesale/no-results.gif';

import { Contacts } from 'features/conversations/components/contacts/contacts';
import { ContentWrapper } from 'features/conversations/components/content-wrapper/content-wrapper';
import { ConversationBody } from 'features/conversations/components/conversation-body/conversation-body';
import { ConversationView } from 'features/conversations/context/ContactContext';
import { ConversationsCard } from 'features/conversations/conversations.styles';

import { LoadingImage } from 'components/loading-image/loading-image';
import WithLoading from 'components/with-loading';

import { ContactsWrapper } from '../contacts-wrapper/contacts-wrapper';

type ConversationsProviderProps = {
  merchantStore?: MerchantStore;
  conversationsStore?: ConversationsStore;
  contactId?: string;
}

export const ConversationsProvider: FC<ConversationsProviderProps> = inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  conversationsStore: stores.conversationsStore
}))(observer(({
  merchantStore,
  conversationsStore,
  contactId
}) => {
  useEffect(() => {
    conversationsStore?.fetchContacts(merchantStore?.merchant?.id);
  }, []);

  useEffect(() => {
    if (contactId) {
      conversationsStore!.setSelectedContactId(contactId);
      conversationsStore!.setView(ConversationView.MESSAGES);
    }
  }, [conversationsStore?.contacts]);

  useEffect(() => {
    if (!PermissionsService.isInternalRole() && !!conversationsStore?.selectedContact?.conversation?.unreadMessagesCount) {
      conversationsStore!.setContactConversationRead(conversationsStore.selectedContact.id, merchantStore?.merchant?.id);
    }
  }, [conversationsStore?.selectedContact?.conversation]);

  const handleContactSelected = (contact: PhoneContact): void => {
    conversationsStore!.setSelectedContactId(contact.id);
    conversationsStore!.setView(ConversationView.MESSAGES);
  };

  const contacts = conversationsStore?.contacts.length ? conversationsStore?.contacts : [];

  const loadingContact = conversationsStore?.contactsStatus === 'loading' && contactId;

  const view = conversationsStore?.selectedContact ? conversationsStore?.view : ConversationView.CONTACTS;

  const noResultsCopy = PermissionsService.isInternalRole()
    ? 'You need to select a merchant'
    : 'Please refresh the page and try again';

  return (
    <WithLoading
      loaderSize="small"
      marginTop="30px"
      isLoading={!!loadingContact}
      hasNoResults={!merchantStore!.merchant?.id}
      renderNoResults={() => (
        <ContentWrapper>
          <LoadingImage
            text={noResultsCopy}
            imageSrc={PlantsIconImage}
            imageAlt="plants"
          />
        </ContentWrapper>
      )}
      renderLoading={() => (
        <LoadingImage
          text="Loading conversations..."
          imageSrc={PlantsIconImage}
          imageAlt="plants"
        />
      )}
    >
      <ContentWrapper>
        <ConversationsCard>
          <ContactsWrapper view={view}>
            <Contacts
              contacts={contacts}
              loading={conversationsStore?.contactsStatus === 'loading'}
              onContactSelect={handleContactSelected}
            />
          </ContactsWrapper>
          <ConversationBody />
        </ConversationsCard>
      </ContentWrapper>
    </WithLoading>
  );
}));

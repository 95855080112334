import gql from 'graphql-tag';

export const CATALOG_ITEM_QUERY = gql`
  query CatalogItem(
    $catalogItemWhere: CatalogItemWhereUniqueInput!,
    $availabilityWhere: TradeSkuAvailabilityWhereInput! = {}
    $tradeSkuWhere: TradeSkuWhereInput = {}
  ) {
    catalogItem(
      where: $catalogItemWhere
    ) {
      id
      createdAt
      updatedAt
      title
      active
      type
      subType
      primaryName,
      secondaryName,
      name {
        id
        name
      }
      colour {
        id
        name
        hex
      }
      family {
        id
        cultivar
        genus
        species
      }
      ref {
        id
        sourceType
        externalRef
      }
      tradeSku(
        first: 20,
        where: $tradeSkuWhere
      ) {
        id
        flower {
          minimumStemLength
          maturity
          headNumber
        }
        plant {
          potSize
          height
          diameter
          thickness
          maturity
        }
        sundry {
          weight
        }
        other {
          minimumStemLength
          maturity
          headNumber
          potSize
          height
          diameter
          thickness
          weight
        }
        availability(
          where: $availabilityWhere
        ) {
          id
          supplierProductId
          unitStock
          multiplesOf
          quantityInPackage
          maximumPurchaseQuantity
          grower {
            id
            name
            countryCode
          }
          supplier {
            name
            id
          }
          price {
            id
            currency
            price
          }
          media {
            id
            src
          }
        }
      }
    }
  }
`;

import React, { Component } from 'react';

import { Box } from 'rebass';

import * as GroupStyles from 'components/group-item/group-item.styles';
import Icon from 'components/icon';

import * as Styles from './create-breadcrumb-box.styles';
import { CreateBreadcrumbBoxProps } from './create-breadcrumb-box.types';

export default class CreateBreadcrumbBox extends Component<CreateBreadcrumbBoxProps> {
  private shouldDisplayEditIcon = (): boolean => !this.props.createMode
    && !this.props.disabled;

  private displayEditIcon = (): React.ReactNode => (
    <Styles.EditIcon displayIcon={this.shouldDisplayEditIcon()}>
      <Icon
        iconName="edit"
      />
    </Styles.EditIcon>
  );

  private handleClick = (): void => {
    if (!this.props.disabled) {
      this.props.changeTab();
    }
  };

  render(): React.ReactNode {
    return (
      <Styles.Content
        subtitleText={!this.props.disabled ? this.props.subtitleText : undefined}
        onClick={this.props.disabled ? undefined : this.props.changeTab}
        disabled={this.props.disabled}
      >
        <GroupStyles.GroupItem
          isActive={this.props.tabType === this.props.activeTab.tabType}
          disabled={this.props.disabled}
        >
          <GroupStyles.GroupItemContent>
            <Box>
              <GroupStyles.GroupItemFocus
                disabled={this.props.disabled}
              >
                {this.props.focusText}
              </GroupStyles.GroupItemFocus>
              <GroupStyles.GroupItemSubtitle>
                {!this.props.disabled ? this.props.subtitleText : ''}
              </GroupStyles.GroupItemSubtitle>
              {this.displayEditIcon()}
            </Box>
          </GroupStyles.GroupItemContent>
        </GroupStyles.GroupItem>
      </Styles.Content>
    );
  }
}

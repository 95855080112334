import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const InnerContent = styled.div`
  width: 100%;
  padding: 20px;
`;

export const CheckBoxWrapper = styled(Flex)<any>`
  align-items: center;
  margin-bottom: 20px;
  > div, label {
    margin-right: 10px;
  }
`;

export const PageDividerStyle = styled.div<any>`
  width: 100%;
  border-bottom: 1px solid ${colors.middleGrey};
  padding: 16px 0;
  margin: 14px 0 30px 0;
`;

export const HeadingText = css`
  ${textStyles.h3};
  margin-top: 10px;
`;

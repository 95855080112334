import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { CurrencyService, Analytics } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import ProductEditStore from 'stores/product-edit-store/product-edit-store';
import { ProductVariation } from 'stores/product-edit-store/product-edit-store-types';

import ProductServices from 'features/products/services';

import FormFieldWrapper from 'components/create-labeled-component';
import FieldText from 'components/field-text';

import * as Styles from '../create-variation-tab.styles';

interface RenderStockAndPriceProps {
  productEditStore?: ProductEditStore;
  merchantStore?: MerchantStore;
  variation: ProductVariation;
}

class RenderStockAndPrice extends Component<RenderStockAndPriceProps> {
  private ProductAnalytics = ProductServices.ProductAnalytics;

  private renderPriceCurrency = (): string => {
    const { productMerchant } = this.props.productEditStore!;

    return CurrencyService.renderCurrencySymbol(productMerchant ? productMerchant.currency : this.props.merchantStore!.merchant!.currency);
  };

  private onStockUpdate = (e: React.FocusEvent<HTMLInputElement>): void => {
    this.ProductAnalytics.onEditStock(
      Analytics.ProductStockEntry.EditPage,
      Number(e.target.value),
      this.props.variation.type
    );
  };

  render(): JSX.Element {
    return (
      <Styles.StockAndPrice>
        <FormFieldWrapper
          title="Available stock"
        >
          <FieldText
            onChange={(e): void => this.props.variation.updateStock(parseFloat(e.target.value))}
            onBlur={this.onStockUpdate}
            value={this.props.variation.stock}
            isSpellCheckEnabled={false}
            placeholder="round number"
            type="number"
            size="normal"
            min={0}
            error={typeof this.props.variation.stock !== 'number' || isNaN(this.props.variation.stock) || this.props.variation.stock < 0}
            errorMessage="Stock must be provided"
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          title="Price"
        >
          <FieldText
            onChange={(e): void => this.props.variation.updatePrice(parseFloat(e.target.value))}
            value={this.props.variation.price}
            isSpellCheckEnabled={false}
            placeholder="amount"
            prefix={this.renderPriceCurrency()}
            type="number"
            size="normal"
            min={0}
            error={typeof this.props.variation.price !== 'number' || isNaN(this.props.variation.price) || this.props.variation.price <= 0}
            errorMessage="Price must be positive"
          />
        </FormFieldWrapper>
      </Styles.StockAndPrice>
    );
  }
}

export default inject('productEditStore', 'merchantStore')(observer(RenderStockAndPrice));

import {
  createContext,
  FC
} from 'react';

import { SearchClient } from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import {
  CatalogItemType,
  ListItemType,
  ListType
} from 'generated-types.d';

import { CatalogService } from 'lib';

import { ListLayoutType } from 'features/lists/lists.types';

import { CatalogInlineSearchBody } from './catalog-inline-search-body';
import {
  CatalogInlineSearchSelectItemArgs
} from './catalog-inline-search-body.types';

const searchClient: SearchClient | null = CatalogService.createCatalogAlgoliaClient();

export interface CatalogInlineSearchProps {
  listType?: ListLayoutType;
  selectedCategory?: ListItemType | CatalogItemType;
  onCategorySelect: (type: CatalogItemType) => void;
  onSelectItem: (data: CatalogInlineSearchSelectItemArgs) => Promise<void>;
  filters?: string;
}

export const CatalogInlineSearchContext = createContext<Pick<CatalogInlineSearchProps,
| 'listType'
| 'selectedCategory'
| 'onCategorySelect'
| 'onSelectItem'
>>({
  listType: ListType.Simple,
  onCategorySelect: () => null,
  onSelectItem: async () => {}
});

export const CatalogInlineSearch: FC<CatalogInlineSearchProps> = ({
  listType,
  selectedCategory,
  onSelectItem,
  onCategorySelect,
  filters
}) => {
  return (
    <CatalogInlineSearchContext.Provider
      value={{
        listType: listType || ListType.Simple,
        selectedCategory: selectedCategory,
        onCategorySelect: onCategorySelect,
        onSelectItem: onSelectItem
      }}
    >
      <InstantSearch
        indexName={process.env.ALGOLIA_CATALOG_INDEX!}
        searchClient={searchClient}
      >
        <Configure
          hitsPerPage={25}
          filters={filters}
        />
        <CatalogInlineSearchBody />
      </InstantSearch>
    </CatalogInlineSearchContext.Provider>
  );
};

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Container = styled(Box)`
  padding: 40px;
`;

export const Heading = styled(Box)`
  ${textStyles.title}
`;

export const BorderSection = styled(Box)`
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid ${colors.middleGrey};
`;

export const Body = styled(Box)`
  ${textStyles.body}
`;

export const UpgradePrice = styled(Box)`
  ${textStyles.title}

  color: ${colors.emeral};
`;

export const MonthlyPrice = styled(Box)`
  ${textStyles.title}

  color: ${colors.shade40};
  text-decoration: line-through;
  margin-right: 4px;
`;

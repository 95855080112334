import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react';

import { WholesaleOrderLineItem } from 'generated-types';
import { inject, observer } from 'mobx-react';

import DraftWholesaleOrderStore from 'stores/wholesale/draft-wholesale-order-store/draft-wholesale-order-store';

import { Input } from '../manage-order.styles';

export interface ManageOrderItemQuantityProps {
  item: WholesaleOrderLineItem;
  draftWholesaleOrderStore?: DraftWholesaleOrderStore;
}

const ManageOrderItemQuantityView: FC<ManageOrderItemQuantityProps> = ({
  item,
  draftWholesaleOrderStore
}) => {
  const [controlledQuantity, setQuantity] = useState('');
  const quantityRef = useRef<HTMLInputElement>(null);
  const isDisabled = !!draftWholesaleOrderStore?.orderItemIdsBeingUpdated?.has?.(item.id);

  useEffect(() => {
    if (!!item.quantity || item.quantity === 0) {
      setQuantity(item.quantity?.toString?.());
    }
  }, [item.quantity]);

  const handleReturn = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      updateQuantity();
      quantityRef?.current?.blur?.();
    }
  };

  const handleQuantityBlur = (): void => {
    if (`${item.quantity}` !== controlledQuantity) {
      updateQuantity();
    }
  };

  const updateQuantity = async (): Promise<void> => {
    const parsedQuantity = ((): number => {
      const quantity = parseInt(controlledQuantity);

      if (quantity < 0) {
        setQuantity('0');

        return 0;
      }

      return quantity;
    })();

    await draftWholesaleOrderStore?.updateOrderItem({
      orderId: draftWholesaleOrderStore!.selectedOrder!.id!,
      orderItemId: item.id,
      data: {
        quantity: parsedQuantity
      }
    });
  };

  const handleUpdateQuantity = (e: ChangeEvent<HTMLInputElement>): void => {
    setQuantity(e.currentTarget.value);
  };

  return (
    <Input
      ref={quantityRef}
      value={controlledQuantity}
      placeholder="Qty"
      type="number"
      min={0}
      disabled={isDisabled}
      onChange={handleUpdateQuantity}
      onBlur={handleQuantityBlur}
      onKeyDown={handleReturn}
    />
  );
};

export const ManageOrderItemQuantity = inject((stores: FxStores): InjectedFxStores => ({
  draftWholesaleOrderStore: stores.draftWholesaleOrderStore
}))(observer(ManageOrderItemQuantityView));

import { FC, useState, KeyboardEvent } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import Button from 'components/button';

import { AdditionalListItem } from '../additional-items-list-item/additional-list-item';

import {
  ButtonWrapper,
  Input,
  InputContainer,
  InputWrapper
} from './additional-items.styles';
import { AdditionalItemsProps } from './additional-items.types';

const AdditionalItemsView: FC<AdditionalItemsProps> = ({
  inputRef,
  promotedListsStore
}) => {
  const [title, setTitle] = useState('');
  const isTitleValid = !!title?.trim();

  const addItem = (): void => {
    promotedListsStore?.createCustomItem({
      title: title,
      quantity: 1
    });

    setTitle('');
    inputRef?.current?.focus?.();
  };

  const deleteItem = (id: string): void => {
    promotedListsStore?.deleteCustomItem(id);
  };

  const handleReturn = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && isTitleValid) {
      addItem();
    }
  };

  return (
    <Box>
      <InputContainer>
        <InputWrapper>
          <Input
            placeholder='Type to request an item'
            value={title}
            onChange={e => setTitle(e.currentTarget.value)}
            onKeyDown={handleReturn}
            ref={inputRef}
          />
        </InputWrapper>
        <ButtonWrapper onClick={addItem}>
          <Button
            size="small"
            padding='8px 24px 10px'
            isParentWidth={true}
            copy="Request"
            appearance="primary"
            isDisabled={!isTitleValid}
          />
        </ButtonWrapper>
      </InputContainer>

      {promotedListsStore?.customItems?.map(item => (
        <AdditionalListItem
          key={item.itemId}
          item={item}
          onDeleteClick={deleteItem}
        />
      ))}
    </Box>
  );
};

export const AdditionalItems = inject((stores: FxStores): InjectedFxStores => ({ promotedListsStore: stores.promotedListsStore }))(observer(AdditionalItemsView));

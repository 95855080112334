import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { OrderItem } from 'generated-types';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import OrdersStore from 'stores/orders/orders-store';
import { OrdersListLayout } from 'stores/orders/orders-store.types';

import OrderHelpers from 'features/orders/helpers/order-helpers';

import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import { CellType, TableLayoutEntityConfigProps } from 'components/entity-list/table-layout-entity.types';
import StyledLink from 'components/styled-link/styled-link';

interface ProductTitleMetaProps {
  data: OrderItem[];
  ordersStore?: OrdersStore;
  layout: OrdersListLayout;
}

class ProductTitleMeta extends Component<ProductTitleMetaProps> {
  private renderProductAddOnCount = (snapshot: any): ReactNode => {
    if (!snapshot?.length) return null;

    return (
      <div onClick={(): void => this.props.ordersStore!.openAddOnsModal(snapshot)}>
        <StyledLink>{snapshot.length} Add-Ons</StyledLink>
      </div>
    );
  };

  private orderItemTableCellConfig = (item: OrderItem): TableLayoutEntityConfigProps[] => {
    return [
      {
        cellType: CellType.Image,
        fixedWidth: '80px',
        props: {
          path: OrderHelpers.getProductImageUrl(item.productSnapshot)
        }
      },
      {
        cellType: CellType.Custom,
        flexGrow: '1',
        customInnerElement: (
          <>
            <div
              css={css`display: inline-block;`}
              onClick={(): any => this.props.ordersStore!.openOrderDetailsModal(this.props.data)}
            >
              <StyledLink>
                {item.quantity}x {OrderHelpers.getProductTitle(item.productSnapshot)}
              </StyledLink>
              {OrderHelpers.hasVariation(item.productSnapshot) && (
                <>
                  &nbsp;-&nbsp;{item.productSnapshot.variation.type}
                </>
              )}
            </div>
            {this.renderProductAddOnCount(item.addOnsSnapshot)}
          </>
        )
      }
    ];
  };

  private orderItemCardCellConfig = (items: OrderItem[]): TableLayoutEntityConfigProps[] => {
    return [
      {
        cellType: CellType.Image,
        customCss: css`
          padding: 10px 0 0 0;
          min-width: 35px;
          max-width: 35px;
        `,
        props: {
          path: OrderHelpers.getProductImageUrl(items[0].productSnapshot),
          size: 35,
          hasMultiple: !!(items.length > 1)
        }
      },
      {
        cellType: CellType.Custom,
        flexGrow: '1',
        customInnerElement: (
          <>
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
              `}
              onClick={(): any => this.props.ordersStore!.openOrderDetailsModal(this.props.data)}
            >
              {items.length > 1 ? (
                <Box pt="10px">
                  <Box width="100%">
                    <StyledLink>
                      {OrderHelpers.multipleItemsTitle(this.props.data)}
                    </StyledLink>
                  </Box>
                  {!!items.map(item => item.productSnapshot).filter(item => OrderHelpers.hasVariation(item) === true).length &&
                    <div>Various</div>
                  }
                </Box>
              )
                : (
                  <Box pt="10px">
                    <Box
                      css={css`
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 100%;
                    `}
                    >
                      <StyledLink>
                        {items[0].quantity} {OrderHelpers.getProductTitle(items[0].productSnapshot)}
                      </StyledLink>
                    </Box>
                    {OrderHelpers.hasVariation(items[0].productSnapshot) && (
                      <div>
                        {items[0].productSnapshot.variation.type}
                      </div>
                    )}
                  </Box>
                )}
            </div>
          </>
        )
      }
    ];
  };

  render(): ReactNode {
    if (this.props.layout === 'table') {
      return (
        <Box>
          {this.props.data.map((item: OrderItem) => {
            return (
              <Flex
                alignItems="center"
                p="5px 0"
                m="0 -10px"
                key={item.id}
              >
                <TableLayoutEntity config={this.orderItemTableCellConfig(item)} />
              </Flex>
            );
          })}
        </Box>
      );
    }

    return (
      <Box>
        <Flex
          alignItems="center"
          m="0 0 10px -5px"
        >
          <TableLayoutEntity config={this.orderItemCardCellConfig(this.props.data)} />
        </Flex>
      </Box>
    );
  }
}

export default inject('ordersStore')(observer(ProductTitleMeta));

import React, { Component } from 'react';

import { Analytics } from 'lib';

import PaymentsRoot from 'features/settings/components/payments';
import SettingsService from 'features/settings/services';

class Payments extends Component {
  private SettingsAnalytics = SettingsService.SettingsAnalytics;

  componentDidMount(): void {
    Analytics.page(Analytics.FxPageEvent.SettingsPayments);
    this.SettingsAnalytics.onSectionView(Analytics.SettingsSection.Payment);
  }

  render(): JSX.Element {
    return <PaymentsRoot {...this.props} />;
  }
}

export default Payments;

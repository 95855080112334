import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, fontSizes, space } from 'utils/rebass-theme';

export const SubHeader = styled(Box)`
  color: ${colors.grey};
  font-size: ${fontSizes.xSmall}px;
  font-weight: 700;
  padding: ${space[3]}px 0 ${space[2]}px ${space[2]}px;
`;

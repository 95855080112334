import slugify from 'slugify';

export const parseSlug = (toSlugify: string): string => {
  return slugify(
    toSlugify
      .replace(' ', '-')
      .replace('-and-', '-')
    , {
      replacement: '-',
      remove: /[*+~()'"?%!:@.]/g,
      lower: true
    }
  );
};

import React, { Component, ReactNode } from 'react';

import { Order } from 'generated-types';
import { inject, observer } from 'mobx-react';

import { OrdersStore } from 'stores/orders/orders-store.types';

import { OrdersAPIService } from 'features/orders/services';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';
import StyledLink from 'components/styled-link/styled-link';

interface ReceiptMetaProps {
  data: Order;
  orderNo: string;
  ordersStore?: OrdersStore;
}

class ReceiptMeta extends Component<ReceiptMetaProps> {
  render(): ReactNode {
    return (
      <MetaCardContentWrapper iconName="receipt">
        <button
          onClick={(): void => {
            OrdersAPIService.updateNewStatus(this.props.orderNo, this.props.data);
            this.props.ordersStore!.viewOrderReceipt(this.props.data);
          }}
        >
          <StyledLink>
            Receipt
          </StyledLink>
        </button>
      </MetaCardContentWrapper>
    );
  }
}

export default inject('ordersStore')(observer(ReceiptMeta));

import {
  FC
} from 'react';

import {
  ColourCheckbox
} from './checkbox-colour-icon.styles';
import {
  CheckboxColourIconProps
} from './checkbox-colour-icon.types';

const CheckboxColourIcon: FC<CheckboxColourIconProps> = ({
  isDisabled = false,
  error = false,
  isSelected = false,
  hexes
}) => {
  return (
    <ColourCheckbox
      isSelected={isSelected}
      isDisabled={isDisabled}
      error={error}
      colour={hexes[0]}
    />
  );
};

export default CheckboxColourIcon;

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { ColourService } from 'lib';

import { colors, textStyles } from 'utils/rebass-theme';

export const Container = styled(Flex)`
  position: relative;
  align-items: stretch;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const ItemWrapper = styled(Flex)`
  flex-flow: row wrap;
  justify-content: flex-start;
  user-select: none;
  padding: 20px;
`;

export const Item: any = styled.div`
  ${textStyles.subhead};
  cursor: pointer;
  border-radius: 3px;
  font-size: 13px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: color .2s ease-in-out,
              background .2s ease-in-out,
              border .2s ease-in-out
              opacity .2s ease-in-out;
  padding: 4px 10px 7px;

  ${({ isSelected, colour, isDisabled }: any): any => {
    const isLowContrast = ColourService.isLowContrast(colour || '#ffffff');

    return `
      border: 2px solid ${!colour ? colors.floomMidnightBlue : isLowContrast ? colors.floomMidnightBlue : colour};
      color: ${!colour ? (isSelected ? colors.white : colors.floomMidnightBlue) : (isSelected ? colors.white : (isLowContrast ? colors.floomMidnightBlue : colour))};
      background: ${isSelected ? (isLowContrast ? colors.floomMidnightBlue : colour || colors.white) : colors.white};
      opacity: ${isDisabled ? 0.5 : 1};
      cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    `;
  }}
`;

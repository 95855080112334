import styled from '@emotion/styled';

import { fontSizes, space } from 'utils/rebass-theme';

export const CloseButtonWrapper = styled.span`
  font-size: ${fontSizes.base};
  font-weight: bold;
  margin-bottom: ${space[3]}px;
  cursor: pointer;

  svg, span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5;
  }

  svg {
    margin-right: ${space[1]}px;
  }
`;

import { gql } from '@apollo/client';
import store from 'stores';

import { GraphQL } from 'lib';

import { GenericListObject } from '../../generic-select-list/generic-select-list.types';

const MERCHANTS_QUERY = gql`
  query MerchantsDataForToggle {
    merchantsDataForToggle(
      first: 2000
      where: {
        deletedAt: null
      }
      orderBy: title_ASC
    ) {
      id
      title
      active
      currency
      address {
        city
        postalCode
      }
    }
  }
`;

const errorMessageForMerchants = 'merchants';

export class MerchantAPIService {
  private toasterStore = store.toasterStore;

  private merchantStore = store.merchantStore;

  public fetchMerchantIds = async (): Promise<GenericListObject[]> => {
    return GraphQL.query(MERCHANTS_QUERY)
      .then(({ data }: any) => {
        const mappedMerchants = data.merchantsDataForToggle.map((merchant: any) => {
          return {
            id: merchant.id,
            title: merchant.title,
            currency: merchant.currency,
            city: merchant.address?.city,
            postalCode: merchant.address?.postalCode
          };
        });

        this.merchantStore.setMerchantList(mappedMerchants);

        return mappedMerchants;
      })
      .catch(() => {
        this.toasterStore.popErrorToast(errorMessageForMerchants, 'retrieve');
      });
  };
}

import gql from 'graphql-tag';

import { ORDER_FRAGMENT_FULL } from '../fragments/order';

export const ORDER_COUNT_QUERY = gql`
  query OrderCountQuery(
    $where: OrderWhereInput!,
  ) {
    ordersConnection(
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ORDERS_QUERY = gql`
  query OrdersQuery(
    $where: OrderWhereInput!
  ) {
    orders(
      where: $where
    ) {
      ...OrderFragmentFull
    }
  }
  ${ORDER_FRAGMENT_FULL}
`;

export const fireChangeEvent = (element: HTMLInputElement, value: string): void => {
  const changeEvent = new Event('change', { bubbles: true });
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window?.HTMLInputElement.prototype, 'value')?.set;
  nativeInputValueSetter?.call(element, value);
  element.dispatchEvent(changeEvent);
};

export type CustomEventName = 'updateModal';

export const subscribeEvent = (eventName: CustomEventName, listener: () => void): void => {
  document?.addEventListener(eventName, listener);
};

export const unsubscribeEvent = (eventName: CustomEventName, listener: () => void): void => {
  document?.removeEventListener(eventName, listener);
};

export const publishEvent = <T>(eventName: CustomEventName, data?: T): void => {
  const event = new CustomEvent(eventName, { detail: data || null });
  document?.dispatchEvent(event);
};

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import { StyledDefaultItem, RadioLabel } from 'components/radio-list/item-types/default-item/default-item.styles';
import { StyledRadioItem } from 'components/radio-list/item-types/radio-item/radio-item.styles';

export const AccordionHeading = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  color: ${colors.floomMidnightBlue};
`;

export const RadioListWrapper = styled.div`
  font-size: 14px;

  ${StyledRadioItem} {
    padding-top: 0px;

    + * {
      ${StyledDefaultItem} {
        padding: 7px 0 0 0 !important;
      }
    }

    ${RadioLabel} {
      font-size: 14px;
      top: 0px;
      left: -4px;
    }
  }
`;

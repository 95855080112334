import { colors } from 'utils/rebass-theme';

export const TOAST_CONFIG = {
  error: {
    title: 'There\'s a problem',
    textColor: colors.errorText,
    background: colors.errorBg
  },
  success: {
    title: null,
    textColor: colors.emeral,
    background: colors.validationBg
  },
  notification: {
    title: null,
    textColor: colors.white,
    background: colors.floomBlue
  }
};

import { gql } from '@apollo/client';

export const UPDATE_WHOLESALE_TIER = gql`
mutation UpdateAdjusterTier(
    $where: AdjusterTierWhereUniqueInput!,
    $data: AdjusterTierUpdateInput!
) {
    updateAdjusterTier(
        where: $where,
        data: $data
    ) {
        id
    }
}
`;

export const CREATE_WHOLESALE_TIER = gql`
mutation CreateAdjusterTier(
    $data: AdjusterTierCreateInput!
) {
    createAdjusterTier(
        data: $data
    ) {
        id
    }
}
`;

export const DELETE_WHOLESALE_TIER = gql`
mutation DeleteAdjusterTier(
    $where: AdjusterTierWhereUniqueInput!
) {
    deleteAdjusterTier(
        where: $where
    ) {
        id
    }
}
`;

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { breakpoints } from 'utils/rebass-theme';

export const Wrapper: any = styled(Flex)`
  margin: 30px 0;

  @media (min-width: ${breakpoints.medium}) {
    align-items: center;
    height: 100vh;
  }
`;

export const OrderNav = styled(Flex)`
  flex-wrap: wrap;
  margin: -15px;

  @media (max-width: ${breakpoints.medium}) {
    margin: 0;
  }
`;

import { SerializedStyles } from '@emotion/css';

import { UserRole } from 'generated-types.d';

export interface TableLayoutHeadingConfigProps {
  flexGrow?: string;
  fixedWidth?: string;
  flexBasis?: string;
  flexShrink?: string;
  isVisible?: boolean;
  restrictedRoles?: UserRole[];
  heading: string;
  id?: string;
}

export interface TableLayoutEntityHeadingProps {
  headers: TableLayoutHeadingConfigProps[];
}

export interface TableLayoutEntityProps {
  config: TableLayoutEntityConfigProps[];
}

export interface TableLayoutEntityConfigProps {
  flexGrow?: string;
  fixedWidth?: string;
  flexBasis?: string;
  flexShrink?: string;
  isVisible?: boolean;
  restrictedRoles?: UserRole[];
  cellType: CellType;
  props?: CellProps;
  customInnerElement?: JSX.Element;
  customCss?: SerializedStyles;
}

type CellProps =
  | TableLayoutCellProps.DatePicker
  | any;

export declare namespace TableLayoutCellProps {
  export interface DatePicker {
    onChange: (date: string) => void;
    value: string;
    label?: string;
  }
}

export enum CellType {
  CheckboxToggle = 'checkboxtoggle',
  CheckboxIcon = 'checkboxicon',
  RadioIcon = 'radioicon',
  Text = 'text',
  Textarea = 'textarea',
  Image = 'image',
  Input = 'input',
  DatePicker = 'datepicker',
  LinkTitle = 'linktitle',
  Cross = 'cross',
  IndicatorPanel = 'indicatorpanel',
  Custom = 'custom'
}

import styled, { StyledComponent } from '@emotion/styled';
import { Box } from 'rebass';

const OnboardingWrapper: StyledComponent<any, any, any> = styled(Box)`
  width: 100%;
  max-width: 552px;
  margin: 0 auto;
`;

export default OnboardingWrapper;

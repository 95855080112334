import styled from '@emotion/styled';
import { Heading, Box } from 'rebass';

import { Card } from 'utils/css-mixins/css-mixins';
import { textStyles, colors, breakpoints } from 'utils/rebass-theme';

export const OrderNavItem: any = styled(Box)`
  ${Card};
  position: relative;
  padding: 50px 20px;
  text-align: center;
  width: calc(50% - 30px);
  margin: 15px;

  @media (max-width: ${breakpoints.medium}) {
    width: 100%;
    text-align: left;
    padding: 30px 20px;
    margin: 10px 0;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 20px;
  }
`;

export const NavHeading: any = styled(Heading)`
  ${textStyles.title};
  flex: none;
  color: ${colors.floomMidnightBlue};

  @media (min-width: ${breakpoints.medium}) {
    width: 100%;
  }
`;

export const Notification = styled(Box)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 23px;
  text-align: left;

  @media (max-width: ${breakpoints.medium}) {
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: ${breakpoints.small}) {
    height: 20px;

    svg {
      height: 20px;
    }
  }
`;

export const TotalOrders: any = styled(Box)`
  ${textStyles.h2};
  color: ${colors.floomMidnightBlue};
  margin: 0 0 0 10px;

  @media (min-width: ${breakpoints.medium}) {
    margin: 8px 0 0 0;
    width: 100%;
  }
  @media (max-width: ${breakpoints.medium}) {
    margin: 0 0 0 auto;
  }
`;

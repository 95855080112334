import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { Box } from 'rebass';

import StripeFormFields from 'features/payment/components/stripe-form-fields';
import { WrappedStripeFormTypes } from 'features/payment/payment.types';

class StripeElementsWrapper extends Component<WrappedStripeFormTypes> {
  render(): JSX.Element {
    return (
      <Box pt="20px">
        <StripeProvider apiKey={process.env.FX_FE_STRIPE_PUBLIC_KEY!}>
          <Elements fonts={this.props.buildFonts()}>
            <StripeFormFields
              hasAgreedTerms={this.props.hasAcceptedTerms}
              updateStripeValidation={this.props.updateStripeValidation}
              onNameOnCardChange={this.props.onNameOnCardChange}
              storeCardInstance={this.props.storeCardInstance}
              transportStripe={this.props.storeStripeInstance}
              nameOnCard={this.props.nameOnCard}
              validation={this.props.validation}
              {...this.state}
            />
          </Elements>
        </StripeProvider>
      </Box>
    );
  }
}

export default inject('toasterStore', 'userStore')(observer(StripeElementsWrapper));

import React, { Component } from 'react';

import { css } from '@emotion/react';
import { Box } from 'rebass';

import { StringFormat } from 'lib';

import * as Styles from './filters.styles';
import * as Types from './filters.types';

export default class Filters extends Component<Types.FiltersProps> {
  static defaultProps = {
    hideBorders: false
  };

  state = {
    activeTab: this.props.children[0].props.label
  };

  onClickTabItem = (tab: string): void => {
    this.setState({ activeTab: tab });
  };

  render(): React.ReactNode {
    if (!this.props.children) return null;

    const showClearFilters = this.props.totalSelectedFilters > 0;

    return (
      <Styles.FiltersWrapper
        isVisible={this.props.isVisible}
      >
        <Styles.FiltersContainer>
          <Styles.CategoryTabsContainer>
            <Styles.CategoryTabs role="tablist">
              {
                this.props.children.map((child: any, index): React.ReactNode => {
                  const { label, hasFiltersApplied } = child.props;

                  return (
                    <Styles.CategoryTab
                      isActive={label === this.state.activeTab}
                      hasFilters={hasFiltersApplied}
                      onClick={(): void => this.onClickTabItem(label)}
                      key={`${label}-${index}`}
                    >
                      {StringFormat.capitalise(label)}
                    </Styles.CategoryTab>
                  );
                })
              }
            </Styles.CategoryTabs>
            {showClearFilters && (
              <Styles.ClearFilters
                as="button"
                onClick={this.props.clearFilters}
              >
                Clear filters
              </Styles.ClearFilters>
            )}
          </Styles.CategoryTabsContainer>
          <Styles.CategoryTabContent>
            {this.props.children.map((child: any, index) => {
              return (
                <Box
                  key={index}
                  css={css`
                    display: ${child.props.label !== this.state.activeTab ? 'none' : 'block'};
                  `}
                >
                  {child.props.children}
                </Box>
              );
            })}
          </Styles.CategoryTabContent>
        </Styles.FiltersContainer>
      </Styles.FiltersWrapper>
    );
  }
}

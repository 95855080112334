import { FC } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';

import { WholesalePreOrder } from 'generated-types.d';

import {
  TimeService,
  PermissionsService,
  StringFormat
} from 'lib';

import {
  WholesaleCheckoutHelpers
} from 'features/wholesale/helpers/wholesale-helpers';

import OrderCardRow from '../order-card-row';
import {
  CardContent,
  OrderCard,
  OrderItemHeader
} from '../order-card.styles';
import {
  getOrderDeliveryCost,
  getPreOrderDeliveryCost,
  getPreOrderTaxValue,
  getPreOrderTotal,
  getTotal
} from '../utils/order-card.utils';

import {
  PreOrderCardProps
} from './pre-order-card.types';

const buildHubText = (order: WholesalePreOrder): string[] | undefined => {
  if (!order.merchant?.wholesaleDeliveryConfigs) {
    return;
  }

  return order.merchant?.wholesaleDeliveryConfigs?.[0]?.hubs?.map?.(hub => {
    return `${hub!.title}`;
  });
};

const PreOrderItemCard: FC<PreOrderCardProps> = ({
  hasGlobalMerchant,
  onViewDetail,
  order,
  itemsLabel
}) => {
  const isInternalRole = PermissionsService.isInternalRole();
  const deliveryCharge = (!!order?.wholesaleOrder && isInternalRole)
    ? getOrderDeliveryCost(order?.wholesaleOrder)
    : getPreOrderDeliveryCost(order);
  const taxAmount = (!!order?.wholesaleOrder && isInternalRole)
    ? WholesaleCheckoutHelpers.orderTaxValue(order?.wholesaleOrder)
    : getPreOrderTaxValue(order);
  const numberOfItems = WholesaleCheckoutHelpers.countOrderItems(order.wholesaleOrder?.items);
  const total = (!!order?.wholesaleOrder && isInternalRole)
    ? getTotal(order?.wholesaleOrder)
    : getPreOrderTotal(order);
  const merchantCurrency = order?.merchant?.currency;

  return (
    <OrderCard onClick={onViewDetail}>
      <OrderItemHeader>
        <OrderCardRow
          iconName="wholesale"
          title={`Order ${order.id!.slice(0, 7)}`}
          bold={true}
        />
      </OrderItemHeader>
      <CardContent>
        <OrderCardRow
          iconName="flower-front"
          title="No. of items"
          value={(isInternalRole && order.wholesaleOrder && numberOfItems) ? numberOfItems.toString() : (order.items?.length || '-')}
          label={itemsLabel!}
        />
        <OrderCardRow
          iconName="orders"
          title="Total"
          value={total ? WholesaleCheckoutHelpers.renderWholesalePrice(total + deliveryCharge + taxAmount, merchantCurrency) : '-'}
        />
        <OrderCardRow
          iconName="delivery-time"
          title="Delivery"
          value={TimeService.humanDateMonthYear(moment(order.deliverAt).utc())}
        />
        {(!hasGlobalMerchant && isInternalRole && !!order.merchant?.title) && (
          <OrderCardRow
            iconName="shop"
            title="Merchant"
            value={StringFormat.truncate(order.merchant.title, 20)}
            label={itemsLabel!}
          />
        )}
        {!!order.suppliers?.length && (
          <OrderCardRow
            iconName="shop"
            title="Supplier"
            value={StringFormat.truncate(order.suppliers?.[0]?.name, 20)}
          />
        )}
        {(isInternalRole && !!order.merchant?.title) && (
          <OrderCardRow
            iconName="shop"
            title="Delivery hub"
            value={buildHubText(order)}
            label={itemsLabel!}
          />
        )}
        {(isInternalRole && !!order.merchant?.title && order.status === 'Processing') && (
          <OrderCardRow
            iconName="delivery-time"
            title="Process by"
            value={TimeService.longFormatWithoutDate(order.stopProcessingAt)}
            label={itemsLabel!}
          />
        )}
        {(isInternalRole && !!order.merchant?.title && order.status === 'Open') && (
          <OrderCardRow
            iconName="delivery-time"
            title="Starts processing"
            value={TimeService.humanDateMonthYear(moment(order.startProcessingAt).utc())}
            label={itemsLabel!}
          />
        )}
        {(!isInternalRole && !!order.merchant?.title) && (
          <OrderCardRow
            iconName="receipt"
            title="Status"
            value={order.status}
            label={itemsLabel!}
          />
        )}
      </CardContent>
    </OrderCard>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore
}))(observer(PreOrderItemCard));

import { FC, ReactNode } from 'react';

import { preventOrphans } from 'lib/services/string-format/string-format.service';
import { Box } from 'rebass';

import {
  DatoCmsFullBleedText
} from 'gatsby-types.d';

import {
  colors
} from 'utils/rebass-theme';

import {
  MarketingButton
} from 'features/marketing/components/button/button';
import {
  MarketingLink
} from 'features/marketing/components/link/link';
import {
  H1,
  H3
} from 'features/marketing/marketing.styles';

import {
  ButtonWrapper,
  ContentWrapper,
  Wrapper
} from './full-bleed-text.styles';
import {
  FullBleedTextProps,
  FullBleedTextStyleConfig
} from './full-bleed-text.types';

const getStyleConfig = (option: DatoCmsFullBleedText['backgroundColour']): FullBleedTextStyleConfig => {
  switch (option) {
    case 'dark-blue':
      return {
        background: colors.floomMidnightBlue,
        text: colors.floomCream
      };

    case 'light-orange':
      return {
        background: '#FEE2D7',
        text: colors.floomMidnightBlue
      };

    case 'light-blue':
      return {
        background: '#BFE1ED',
        text: colors.floomMidnightBlue
      };

    default:
      return {
        background: 'none',
        text: colors.floomMidnightBlue
      };
  }
};

export const FullBleedText: FC<FullBleedTextProps> = ({ data }) => {
  const styleConfig = getStyleConfig(data.backgroundColour);

  return (
    <Wrapper config={styleConfig}>
      <ContentWrapper>
        {data.heading && (
          <H1>
            {preventOrphans(data.heading)}
          </H1>
        )}
        {data.subheading && (
          <H3>
            {preventOrphans(data.subheading)}
          </H3>
        )}
      </ContentWrapper>
      {(data.primaryCtaText || data.secondaryCtaText) && (
        <ButtonWrapper>
          {data.primaryCtaText && (
            <Box p="0 10px">
              <MarketingLink
                type={data.primaryCtaInternalLink ? 'internal' : 'external'}
                url={!!data.primaryCtaInternalLink ? `/${data.primaryCtaInternalLink.slug}` : data.primaryCtaExternalLink}
                renderLink={(): ReactNode => (
                  <MarketingButton
                    copy={data.primaryCtaText!}
                  />
                )}
              />
            </Box>
          )}
          {data.secondaryCtaText && (
            <Box p="0 10px">
              <MarketingLink
                type={data.secondaryCtaInternalLink ? 'internal' : 'external'}
                url={!!data.secondaryCtaInternalLink ? `/${data.secondaryCtaInternalLink.slug}` : data.secondaryCtaExternalLink}
                renderLink={(): ReactNode => (
                  <MarketingButton
                    copy={data.secondaryCtaText!}
                    type="secondary"
                  />
                )}
              />
            </Box>
          )}
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

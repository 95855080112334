import { ReactNode } from 'react';

import { ButtonProps } from 'components/button/button.types';
import { IconType } from 'components/icon/icon.types';

export enum NotificationType {
  Error = 'error',
  Success = 'success',
  Progress = 'progress',
  Info = 'info',
  Banner = 'banner',
}

export interface CustomNotificationTheme {
  color: string;
  background: string;
}

export interface NotificationProps {
  icon: IconType;
  customIcon: string;
  hasIcon: boolean;
  hasButton: boolean;
  hasClose: boolean;
  type: NotificationType;
  customTheme?: CustomNotificationTheme;
  copy: ReactNode;
  iconColor?: string;
  buttonProps?: Partial<ButtonProps> & Pick<ButtonProps, 'copy'>;
  textAlign: 'center' | 'left';
  styles: any;
  onButtonClick: () => void;
  onClick: () => void;
  onClose: () => void;
}

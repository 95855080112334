import { UserRole } from 'generated-types.d';

import Auth from '../auth/auth.service';

export class PermissionsService {
  public static getUserRole = (): UserRole => {
    const decryptToken = Auth.decryptToken();

    return decryptToken ? decryptToken.user.role : null;
  };

  public static isInternalRole = (): boolean => {
    return [UserRole.SuperAdmin, UserRole.CustomerService].includes(PermissionsService.getUserRole());
  };

  public static isSuperAdmin = (): boolean => {
    return [UserRole.SuperAdmin].includes(PermissionsService.getUserRole());
  };
}

export default PermissionsService;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const LinkWrapper: any = styled(Box)`
  ${textStyles.subhead};
  position: relative;
  cursor: pointer;
  padding-bottom: 2px;
  color: ${({ color }: any): any => color};

  text-decoration: none;
  background-image: ${({ color }: any): any => `linear-gradient(${color}, ${color})`};
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  transition: background-size .3s,
              opacity .2s ease-in-out;

  ${({ isDisabled }): any => {
    if (isDisabled) {
      return `
        opacity: 0.3;
        cursor: not-allowed;
      `;
    }
  }}

  &:hover {
    ${({ isDisabled }): any => {
    if (!isDisabled) {
      return `
          background-size: 0% 1px;
        `;
    }
  }}
  }
`;

export const LinkWrapperBold: any = styled(LinkWrapper)`
  padding-bottom: 6px;
  &:after {
    height: 2px;
  }
`;

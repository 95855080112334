import React, { Component, ReactNode, Ref } from 'react';

import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';
import { DayPickerProps } from 'react-day-picker/types/Props';
import { Flex, Box, Text } from 'rebass';

import 'moment/locale/en-gb';
import 'react-day-picker/lib/style.css';

import { LocaleService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import Icon from 'components/icon';

import { DatePickerNavigation } from './date-picker-navigation/date-picker-navigation';
import DatePickerTrigger from './date-picker-trigger';
import { DatePickerTriggerProps } from './date-picker-trigger/date-picker-trigger.types';
import * as Styles from './field-date-picker.styles';
import * as Types from './field-date-picker.types';

class FieldDatePicker extends Component<Types.FieldDatePickerProps> {
  private dayPickerRef: DayPickerInput | null = null;

  state = {
    month: undefined
  };

  static defaultProps: Partial<Types.FieldDatePickerProps> = {
    disabledDays: {},
    inputType: 'input',
    pickerType: 'tooltip',
    format: 'LL',
    isDisabled: false,
    isClearable: false,
    shouldAutofocus: false,
    sendRefForward: (): any => null,
    onClear: (): any => null,
    customDatePickerStyles: null
  };

  private hideDayPicker = (): void => {
    this.dayPickerRef?.hideDayPicker();
    this.dayPickerRef?.getInput?.()?.blur?.();
  };

  private focusOnInput = (): void => {
    this.dayPickerRef?.getInput?.()?.focus?.();
  };

  private datePickerProps = (): DayPickerProps => ({
    locale: LocaleService.getUserLanguage(),
    localeUtils: MomentLocaleUtils,
    month: this.state.month,
    todayButton: 'Jump to today',
    initialMonth: this.props.initialMonth ? moment(this.props.initialMonth).toDate() : undefined,
    modifiers: {
      disabled: this.props.disabledDays
    },
    onTodayButtonClick: (): void => {
      this.focusOnInput();
    },
    captionElement: (): JSX.Element => (<></>),
    navbarElement: (navbarProps): JSX.Element => (
      <DatePickerNavigation
        {...navbarProps}
        onChangeMonth={(month): void => {
          this.setState({ month }, () => {
            this.focusOnInput();
          });
        }}
      />
    )
  });

  private renderStyleWrapper = (): any => {
    switch (this.props.pickerType) {
      case 'popover': return Styles.PopoverWrapper;

      case 'tooltip': return Styles.TooltipWrapper;
    }
  };

  private renderOverlayComponent = (props: any): JSX.Element => {
    return (
      <div
        className={props.classNames.overlayWrapper}
        {...props}
      >
        <div className={props.classNames.overlay}>
          { this.props.pickerType === 'popover' && (
            <Box
              onClick={this.hideDayPicker}
              css={Styles.Header}
              as="button"
            >
              <Flex
                alignItems="center"
                css={Styles.HeaderInner}
              >
                <Box
                  mr="10px"
                  css={Styles.BackArrow}
                >
                  <Icon iconName="arrow-small-left" />
                </Box>
                <Text css={textStyles.subhead}>Back</Text>
              </Flex>
            </Box>
          )}
          {props.children}
        </div>
      </div>
    );
  };

  render(): ReactNode {
    const Wrapper = this.renderStyleWrapper();

    return (
      <Wrapper customStyles={this.props.customDatePickerStyles}>
        <DayPickerInput
          value={new Date(this.props.value)}
          placeholder=""
          format={this.props.format}
          formatDate={formatDate}
          parseDate={parseDate}
          overlayComponent={this.renderOverlayComponent}
          dayPickerProps={this.datePickerProps()}
          onDayChange={this.props.onChange}
          keepFocus={true}
          ref={(el): void => {
            this.dayPickerRef = el;
            this.props.sendRefForward(el);
          }}
          component={React.forwardRef((props: DatePickerTriggerProps, ref: Ref<HTMLButtonElement>) => {
            return (
              <DatePickerTrigger
                {...props}
                innerRef={ref}
                type={this.props.inputType}
                isDisabled={this.props.isDisabled}
                controlledValue={this.props.value}
                isClearable={!this.props.isDisabled && this.props.isClearable}
                shouldAutofocus={this.props.shouldAutofocus}
                onClear={this.props.onClear}
              />
            );
          })}
        />
      </Wrapper>
    );
  }
}

export default FieldDatePicker;

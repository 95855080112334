import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { breakpoints, colors, space } from 'utils/rebass-theme';

export const Wrapper = styled(Flex)`
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${colors.lightGrey};
  padding: ${space[2]}px;
  position: sticky;
  bottom: 0;

  @media (max-width: ${breakpoints.small}) {
    padding-bottom: ${space[3]}px;
  }
`;

export const MoreOptionsContainer = styled(Box)`
  padding: ${space[3]}px ${space[2]}px 12px 0;
`;

import { ChangeEvent } from 'react';

import moment from 'moment';
import { create } from 'zustand';

import { Currency, Merchant, PromotedPeriod, PromotedPostcode } from 'generated-types.d';

import { CurrencyService } from 'lib';

import { DISCOUNT_SPEND_AMOUNTS } from './constants';
import { ProductForPromotion } from './types';

export type PromotedState = {
  merchantId: string;
  merchantTimezone: string;
  merchantCurrency: Currency;
  upcomingPeriod: PromotedPeriod | null;
  setMerchant: (merchant: Merchant) => void;
  currencySymbol: () => string;
  merchantSelectablePostcodes: PromotedPostcode[];
  selectedProducts: ProductForPromotion[];
  selectedPostcodeIds: string[];
  toggleProduct: (event: ChangeEvent, product: ProductForPromotion) => void;
  togglePostcode: (postcode: string) => void;
  selectedPostcodes: () => PromotedPostcode[];
  selectedPostcodesSubtotal: () => number;
  selectedPostcodesSubtotalPerDay: () => number;
  selectedPostcodesSubtotalAfterDiscount: () => number;
  selectedPostcodesTotalBeforeDiscount: () => number;
  selectedPostcodesSubtotalPerDayAfterDiscount: () => number;
  setMerchantSelectablePostcodes: (postcodes: PromotedPostcode[]) => void;
  selectedPostcodesTax: () => number;
  selectedPostcodesTotal: () => number;
  paymentIntentClientSecret: string;
  setPaymentIntentClientSecret: (secret: string) => void;
  setUpcomingPeriod: (period: PromotedPeriod) => void;
  clearPostcodes: () => void;
  discountMultiplier: () => number;
  nextDiscount: () => { spendNeeded: number; discount: number } | null;
};

export const usePromotedStore = create<PromotedState>()((set, get) => ({
  merchantId: '',
  merchantTimezone: '',
  upcomingPeriod: null,
  merchantCurrency: Currency.Gbp,
  currencySymbol: () => CurrencyService.renderCurrencySymbol(get().merchantCurrency),
  merchantSelectablePostcodes: [],
  selectedProducts: [],
  selectedPostcodeIds: [],
  paymentIntentClientSecret: '',
  setPaymentIntentClientSecret: (secret: string) => set(() => ({
    paymentIntentClientSecret: secret
  })),
  setMerchant: (merchant: Merchant) => set(() => ({
    merchantId: merchant.id,
    merchantCurrency: merchant.currency,
    merchantTimezone: merchant.timezone
  })),
  setMerchantSelectablePostcodes: postcodes => set(() => ({
    merchantSelectablePostcodes: postcodes
  })),
  setUpcomingPeriod: period => set(() => ({
    upcomingPeriod: period
  })),
  // setSelectedPostcodes: postcodes => set(() => ({
  //   merchantSelectablePostcodes: postcodes
  // })),
  selectedPostcodes: () => get().merchantSelectablePostcodes
    .filter(postcode => get().selectedPostcodeIds.includes(postcode.postcode))
    .filter(postcode => {
      return (
        postcode.status === 'AVAILABLE'
        || postcode.status === 'PENDING' && postcode.merchant?.id === get().merchantId
      );
    }),
  toggleProduct: (event, product) => set(state => {
    if (state.selectedProducts.find(selected => selected.id === product.id)) {
      return {
        selectedProducts: [...state.selectedProducts.filter(p => p.id !== product.id)]
      };
    }

    return {
      selectedProducts: [...state.selectedProducts, product]
    };
  }),
  togglePostcode: postcode => set(state => {
    if (state.selectedPostcodeIds.includes(postcode)) {
      return {
        selectedPostcodeIds: [...state.selectedPostcodeIds.filter(p => p !== postcode)]
      };
    }

    return {
      selectedPostcodeIds: [...state.selectedPostcodeIds, postcode]
    };
  }),
  clearPostcodes: () => set(() => ({
    selectedPostcodeIds: []
  })),
  selectedPostcodesSubtotal: () => get().selectedPostcodes()
    .reduce((total, postcode) => {
      return total + postcode.price;
    }, 0),
  selectedPostcodesTotalBeforeDiscount: () => get().selectedPostcodesSubtotal() + get().selectedPostcodesTax(),
  selectedPostcodesSubtotalAfterDiscount: () => {
    const subtotal = get().selectedPostcodesSubtotal();
    const discountMultiplier = get().discountMultiplier();

    return subtotal * discountMultiplier;
  },
  selectedPostcodesSubtotalPerDay: () => {
    const period = get().upcomingPeriod;

    if (!period) {
      return 0;
    }

    const daysInPeriod = moment(period?.endDate).diff(moment(period?.startDate), 'days');
    console.log('daysInPeriod', daysInPeriod);

    return get().selectedPostcodesSubtotal() / daysInPeriod;
  },
  selectedPostcodesSubtotalPerDayAfterDiscount: () => {
    const period = get().upcomingPeriod;

    if (!period) {
      return 0;
    }

    const daysInPeriod = moment(period?.endDate).diff(moment(period?.startDate), 'days');
    console.log('daysInPeriod', daysInPeriod);

    const pricePerDay = get().selectedPostcodesSubtotalAfterDiscount() / daysInPeriod;

    return Number.isInteger(pricePerDay) ? pricePerDay : parseFloat(pricePerDay.toFixed(2));
  },
  selectedPostcodesTax: () => {
    const subtotalAfterDiscount = get().selectedPostcodesSubtotalAfterDiscount();

    return get().merchantCurrency === 'GBP' ?
      parseFloat(((subtotalAfterDiscount * 1.20) - subtotalAfterDiscount).toFixed(2))
      : 0;
  },
  selectedPostcodesTotal: () => get().selectedPostcodesSubtotalAfterDiscount() + get().selectedPostcodesTax(),
  discountMultiplier: () => {
    const subtotal = get().selectedPostcodesSubtotal();
    const discountSpendAmountsForCurrency = DISCOUNT_SPEND_AMOUNTS[get().merchantCurrency];

    // If subtotal is less than £50, no discount
    // If subtotal is between £50 and £100, 10% discount
    // If subtotal is between £100 and £200, 20% discount
    // If subtotal is over £200, 30% discount
    if (subtotal >= discountSpendAmountsForCurrency[0] && subtotal < discountSpendAmountsForCurrency[1]) {
      return 0.9;
    }

    if (subtotal >= discountSpendAmountsForCurrency[1] && subtotal < discountSpendAmountsForCurrency[2]) {
      return 0.8;
    }

    if (subtotal >= discountSpendAmountsForCurrency[2]) {
      return 0.7;
    }

    return 1;
  },
  nextDiscount: () => {
    const subtotal = get().selectedPostcodesSubtotal();
    const discountSpendAmountsForCurrency = DISCOUNT_SPEND_AMOUNTS[get().merchantCurrency];

    if (subtotal < discountSpendAmountsForCurrency[0]) {
      return {
        spendNeeded: discountSpendAmountsForCurrency[0] - subtotal,
        discount: 10
      };
    }

    if (subtotal >= discountSpendAmountsForCurrency[0] && subtotal < discountSpendAmountsForCurrency[1]) {
      return {
        spendNeeded: discountSpendAmountsForCurrency[1] - subtotal,
        discount: 20
      };
    }

    if (subtotal >= discountSpendAmountsForCurrency[1] && subtotal < discountSpendAmountsForCurrency[2]) {
      return {
        spendNeeded: discountSpendAmountsForCurrency[2] - subtotal,
        discount: 30
      };
    }

    return null;
  }
}));

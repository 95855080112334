import { PageProps } from 'gatsby';

import { ConversationsProvider } from 'features/conversations/components/conversations-provider/conversations-provider';
interface ConversationsHomeProps extends PageProps {
  contactId?: string;
}

export const ConversationsHome: React.FC<ConversationsHomeProps> = ({ contactId }) => {
  return (
    <ConversationsProvider contactId={contactId} />
  );
};

import styled from '@emotion/styled';
import { Box, Text, Flex } from 'rebass';

import { colors, breakpoints } from 'utils/rebass-theme';

export const Price = styled(Flex)<any>`
  padding: 40px 0;
  background: ${colors.uiFills};
  border: solid 2px ${colors.shade40};
  min-height: 174px;
  align-items: center;
  justify-content: center;
  transition: background .2s ease-in-out;
`;

export const CopyBody: any = styled(Text)`
  max-width: 430px;
`;

export const ButtonContinue: any = styled(Box)`
  margin: auto;

  @media (min-width: ${breakpoints.medium}) {
    max-width: 130px;
  }
`;

export const Heading: any = styled(Text)`
  text-align: center;
`;

import React from 'react';

import styled from '@emotion/styled';
import { connectStateResults } from 'react-instantsearch-dom';

import { colors } from 'utils/rebass-theme';

const PageInfo = styled.div`
  color: ${colors.middleGrey};
  font-size: 14px;
  font-weight: 600;
  margin-left: auto;
`;

export const OrderListPageInfo = connectStateResults(({ searchResults }) => {
  const hasMultiplePages = searchResults && searchResults.nbHits !== 0 && searchResults.nbPages > 1;

  return (
    <PageInfo>
      <div hidden={!hasMultiplePages}>Page {searchResults.page + 1} of {searchResults.nbPages}</div>
    </PageInfo>
  );
});

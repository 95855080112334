import { RefinementListExposed } from 'react-instantsearch-core';

import { CatalogItemType } from 'generated-types.d';

export type CatalogExplorerFacets = {
  [key in CatalogItemType | 'All']: CatalogItemFacet[]
}

export type CatalogItemFacet = {
  title: string;
  refinement: string;
  operator: RefinementListExposed['operator'];
  hasSearchInput?: boolean;
}

const BASE_CONFIG: CatalogItemFacet[] = [
  {
    title: 'Category',
    refinement: 'subType',
    operator: 'or',
    hasSearchInput: true
  },
  {
    title: 'Colour',
    refinement: 'colour.name',
    operator: 'or'
  }
];

export const CATALOG_ITEM_FACET_CONFIG: CatalogExplorerFacets = {
  ['All']: [
    ...BASE_CONFIG
  ],
  [CatalogItemType.Decoration]: [
    ...BASE_CONFIG
  ],
  [CatalogItemType.Flower]: [
    ...BASE_CONFIG
  ],
  [CatalogItemType.Plant]: [
    ...BASE_CONFIG
  ],
  [CatalogItemType.Sundry]: [
    ...BASE_CONFIG
  ],
  [CatalogItemType.Other]: [
    ...BASE_CONFIG
  ]
};

import {
  FC,
  Fragment,
  ReactNode,
  useEffect
} from 'react';

import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { NavService } from 'lib';

import { Container } from 'utils/css-mixins';

import PlansIconImage from 'assets/images/wholesale/no-results.gif';

import { shouldShowLimitedView } from 'features/lists/lists.helpers';

import { Card } from 'components/card/card';
import IconButton from 'components/icon-button';
import { LoadingImage } from 'components/loading-image/loading-image';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import { MoreOptionsButton } from '../more-options-button/more-options-button';
import { PageHeader } from '../page-header';

import { SingleListBody } from './components/single-list-body/single-list-body';
import { SingleListFooter } from './components/single-list-footer/single-list-footer';
import { SingleListHeader } from './components/single-list-header/single-list-header';
import { SingleListHeading, ListContainer } from './single-list.styles';
import { SingleListProps } from './single-list.types';

const SingleList: FC<SingleListProps> = ({
  conversationsStore,
  merchantStore,
  listId,
  selectedListStore
}) => {
  const listTitle = selectedListStore?.list?.title || 'Untitled list';

  useEffect(() => {
    fetchData();

    return (): void => {
      selectedListStore!.clearList();
      conversationsStore!.endShareListFlow();
    };
  }, []);

  const fetchData = async (): Promise<void> => {
    if (listId) {
      const list = await selectedListStore!.fetchList({ listId });

      selectedListStore!.fetchAvailableSuppliers({
        supplierIds: list?.merchant?.wholesaleDeliveryConfigs?.[0]?.hubs?.map(hub => hub!.supplier.id) || []
      });
    }
  };

  const limitedView = shouldShowLimitedView({
    inShareFlow: conversationsStore?.shareList.inShareFlow,
    merchant: merchantStore?.merchant
  });

  return (
    <Fragment>
      <PageHeader
        renderCenter={() => (
          <SingleListHeading
            as="div"
            aria-label={listTitle}
            title={listTitle}
          >
            {listTitle}
          </SingleListHeading>
        )}
        renderLeft={() => (
          <IconButton
            iconName="arrow-small-left"
            size="large"
            onClick={() => {
              NavService.listsList();
            }}
          />
        )}
        renderRight={() => (
          <MoreOptionsButton />
        )}
      />
      <Container>
        <ListContainer>
          <Card>
            <WithLoading
              hasNoResults={(!selectedListStore!.isLoadingList && !selectedListStore!.list) || !listId}
              isLoading={selectedListStore!.isLoadingList}
              renderLoading={() => (
                <Box height="80vh">
                  <LoadingImage
                    text="Loading list..."
                    imageSrc={PlansIconImage}
                    imageAlt="plants"
                  />
                </Box>
              )}
              renderNoResults={(): ReactNode => (
                <Box>
                  <NoResultsGeneric
                    icon="leaf-no-results"
                    heading="Unable to find list"
                    copy={(
                      <span>
                      head back to
                        {' '}
                        <Link to={NavPages.Lists}><strong><u>lists home</u></strong></Link>
                        {' '}
                      to create a new list
                      </span>
                    )}
                  />
                </Box>
              )}
            >
              <SingleListHeader limitedView={limitedView} />
              <SingleListBody />
              <SingleListFooter limitedView={limitedView} />
            </WithLoading>
          </Card>
        </ListContainer>
      </Container>
    </Fragment>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  conversationsStore: stores.conversationsStore,
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore,
  selectedListStore: stores.selectedListStore
}))(observer(SingleList));

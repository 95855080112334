import gql from 'graphql-tag';

export const MERCHANT_FULL = gql`
  fragment MerchantFull on Merchant {
    id
    flc_id
    acceptedTermsAt
    createdAt
    stage
    active
    biography
    updatedAt
    invitedAt
    stripeConnectAccountId
    hasProvidedBankDetails
    canEditFloomDeliveryPricing
    canViewNationwide
    isNewsletterRegistered
    canDownloadCsv
    courierActive
    wholesaleActive
    isDemoAccount
    listsActive
    promotedListsActive
    promotedActive
    conversationsActive
    title
    timezone
    currency
    companyName
    tradingNo
    sameDayOrderPauseStatus
    areProductsDisabledOnFloom
    orderStatusCheckNotifications
    newOrderNotifications
    productCount
    customSiteConfig {
      id
      customSiteProjectId
      customSiteProjectUrl
      mailchimpInformation {
        id
      }
    }
    weeblyConfig {
      domain
      active
      published
    }
    createdBy {
      givenName
      familyName
      email
      phone
      lastLoginAt
      team {
        id
      }
    }
    paymentMethod {
      type
      brand
      lastFourCardDigits
      isExpiring
      expiryMonth
      expiryYear
    }
    plan {
      id
      title
      price
      currency
      description
      features
      type
    }
    subscription {
      id
      createdAt
      updatedAt
      deletedAt
      stripeSubscriptionId
      stripeSubscriptionCustomerId
      stripeSubscriptionPrice
      productLimit
      orderLimit
      status
      trialExpiresAt
      trialLength
      currentPeriodEndsAt
      currentPeriodStartsAt
      wholesaleTermLength
      wholesaleCreditLimit
      creditTermsUpdatedBy {
        id
        givenName
        familyName
      }
    }
    proposedUpdate {
      id
      createdAt
      planPrice
      isConfirmed
      merchantStage
      reason
      plan {
        id
        type
      }
      createdBy {
        id
        givenName
        familyName
      }
    }
    address {
      honorific
      businessName
      recipientFullName
      address1
      address2
      city
      postalCode
      country
      region
      phone
    }
    registeredAddress {
      honorific
      businessName
      recipientFullName
      address1
      address2
      city
      postalCode
      country
      region
      phone
    }
    wholesaleDeliveryConfigs(first: 1) {
      id
      active
      rijnsCode
      rijnsDeliveryType
      active
      deliveryInstructions
      isConfirmed
      hubs {
        id
        title
        supplier {
          id
          name
        }
      }
      address {
        id
        honorific
        businessName
        recipientFullName
        address1
        address2
        city
        postalCode
        phone
        country
        region
      }
    }
  }
`;

import gql from 'graphql-tag';

import { ORDER_FRAGMENT_FULL } from '../fragments/order';

export const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $where: OrderWhereUniqueInput!,
    $data: OrderUpdateInput!
  ) {
    updateOrder(
      where: $where,
      data: $data
    ) {
      ...OrderFragmentFull
    }
  }
  ${ORDER_FRAGMENT_FULL}
`;

import { gql } from '@apollo/client';

export const MERCHANT_PAYMENTS_QUERY = gql`
  query MerchantPayments(
    $merchantId: ID!
    $first: Int
    $skip: Int
    $orderBy: PaymentOrderByInput
    $search: ID
  ) {
    paymentsConnection(
      first: $first
      skip: $skip
      orderBy: $orderBy
      where: {
        id_starts_with: $search
        merchant: {
          id: $merchantId
        }
      }
    ) {
      aggregate {
        count
      }
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          updatedAt
          transaction
          amount
          type
          currency
          status
          statusMessage
          channelComplete
          lastStripePaymentIntentKey
          invoice {
            id
            createdAt
            updatedAt
            deletedAt
            externalId
            url
          }
          courierBooking {
            id
          }
          order {
            id
          }
          merchant {
            id
            customSiteConfig {
              customSiteProjectId
            }
          }
        }
      }
    }
  }
`;

import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import {
  breakpoints,
  colors,
  textStyles,
  fontStacks
} from 'utils/rebass-theme';

export const WholesaleShopListingCardContainer = styled(Flex)`
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: column;
  position: relative;
`;

export const ItemTitle = styled(Flex)`
  color: ${colors.floomMidnightBlue};
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  
  @media (max-width: ${breakpoints.small}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const ItemsContainer = styled(Box)`
  margin-bottom: 20px;
`;

export const SuppliersContainer = styled(Flex)`
  flex-direction: column;
`;

export const ItemHeader = styled.h4`
  ${textStyles.title};
`;

export const ItemSubHeader = styled.h6`
  line-height: 1.5;
  font-weight: 600;
  font-family: ${fontStacks.primary};
  color: ${colors.middleGrey};
`;

import {
  useFlags,
  useLDClient
} from 'launchdarkly-react-client-sdk';

import {
  FeatureToggleConfig,
  FeatureFlags
} from './useFeatureFlags.types';

/**
 * @description Helper hook to interact with LaunchDarkly feature toggles. It allows the codebase
 * to centralise feature toggle usages, so that we can easily modify a property in one place
 * before going to the effort of crawling through the codebase for all consumers of the toggle.
 */
export const useFeatureFlags = (): FeatureToggleConfig => {
  const flags = useFlags();
  const client = useLDClient();

  const featureFlags: FeatureFlags = {
    multiSupplier: {
      isActive: !!flags?.multiSupplier
    },
    wsFriendlyNames: {
      isActive: !!flags?.wsFriendlyNames
    },
    wsMultiDeliveryDates: {
      isActive: !!flags?.wsMultiDeliveryDates
    },
    orderHelp: {
      isActive: !!flags?.orderHelp
    },
    promotedListsAdditionalItems: {
      // Using topDealsAdditionalItems as a fallback for backwards compatibility
      isActive: !!flags?.promotedListsAdditionalItems || !!flags?.topDealsAdditionalItems
    },
    promoted: {
      isActive: !!flags?.promoted
    },
    wholesaleShop: {
      isActive: !!flags?.wholesaleShop
    },
    refunds: {
      isActive: !!flags?.refunds
    },
    adjustments: {
      isActive: !!flags?.adjustments
    },
    pushNotifications: {
      isActive: !!flags?.pushNotifications
    }
  };

  return {
    flags: featureFlags,
    client: client
  };
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Heading: any = styled(Flex)`
  padding: 15px 20px;
  align-items: center;
  width: 100%;
  background: ${({ status }: any): any => colors[status.headingBg]};
  ${({ layout }: any): any => layout === 'table' && 'justify-content: center;'}
`;

export const StatusIcon: any = styled(Flex)`
  ${({ layout }: any): any => layout === 'grid' ? 'margin-right: 15px;' : 'margin-bottom: 10px;'}

  svg path {
    fill: ${({ status }: any): any => colors[status.color]};
  }
`;

export const Label: any = styled.div`
  ${textStyles.label};
  text-transform: uppercase;
  color: ${({ status }: any): any => colors[status.labelColor]};
`;

export const OrderLink = css`
  display: flex;
`;

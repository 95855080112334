import React, { Component, ReactNode } from 'react';

import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  MerchantPlan,
  PlanType
} from 'generated-types.d';

import {
  CurrencyService,
  LocalisationService,
  MerchantPlanService,
  MerchantService,
  MerchantSubscriptionService
} from 'lib';

import { PlanUpgradeModalData } from 'features/modal-dialogue/components/modals/plan-upgrade-modal/plan-upgrade-modal.types';

import Button from 'components/button';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import * as Constants from './overview-upgrade-plan.constants';
import * as Styles from './overview-upgrade-plan.styles';
import * as Types from './overview-upgrade-plan.types';

class OverviewUpgradePlan extends Component<Types.OverviewUpgradePlanProps, Types.OverviewUpgradePlanState> {
  state: Types.OverviewUpgradePlanState = {
    isLoading: true,
    isLoadingModal: false,
    canMerchantUpgrade: false,
    upgradePlan: null
  };

  private merchantListener = observe(this.props.merchantStore!, 'merchant', ({ oldValue, newValue }: any) => {
    if (!oldValue && newValue.id) {
      this.fetchData();
    }
  });

  componentDidMount = (): void => {
    this.fetchData();
  };

  componentWillUnmount = (): void => {
    this.merchantListener();
  };

  private canMerchantUpgrade = (plan = this.state.upgradePlan): boolean => {
    const merchant = this.props.merchantStore!.merchant;

    return !!plan
      && MerchantService.hasSubscriptionId(merchant)
      && MerchantService.isActiveMerchantStage(merchant!);
  };

  private fetchData = async (): Promise<void> => {
    if (!!this.props.merchantStore!.merchant) {
      try {
        const merchant = this.props.merchantStore!.merchant;
        const proposedPlanType = Constants.PLAN_UPGRADE_MATRIX[merchant.plan!.type];
        const plans = await MerchantPlanService.fetchMerchantPlans({
          currency: merchant.currency,
          type: proposedPlanType
        });

        this.setPlanState(plans.find(plan => plan.type === proposedPlanType));
      } catch (error) {
        this.setState({
          canMerchantUpgrade: false,
          isLoading: false
        });
      }
    }
  };

  private setPlanState = (plan?: MerchantPlan): void => {
    if (!!plan) {
      this.setState({
        upgradePlan: plan,
        canMerchantUpgrade: this.canMerchantUpgrade(plan),
        isLoading: false
      });
    }
  };

  private onUpgradePlan = async (): Promise<void> => {
    this.setState({ isLoadingModal: true });

    try {
      const subscriptionEstimate = await MerchantSubscriptionService.estimateMerchantSubscription(
        this.state.upgradePlan!.id,
        this.props.merchantStore!.merchant!.id
      );

      this.props.modalStore!.triggerModal<PlanUpgradeModalData>({
        modalType: 'planUpgrade',
        data: {
          title: 'Upgrade: almost there',
          subscriptionEstimate: subscriptionEstimate,
          upgradePlan: this.state.upgradePlan!
        }
      });
    } catch (error) {
      this.props.toasterStore!.popNotificationToast('Uh oh, We\'re having trouble getting upgrade info at the moment');
    }

    this.setState({ isLoadingModal: false });
  };

  private renderButtonCopy = (): ReactNode => {
    if (this.state.canMerchantUpgrade && !this.state.isLoading) return null;

    return (
      <Styles.ButtonCopy>
        {`(Hasn't subscribed yet)`}
      </Styles.ButtonCopy>
    );
  };

  private renderRows = (): ReactNode => {
    const merchant = this.props.merchantStore!.merchant;
    const { upgradePlan } = this.state;

    const rows = [
      {
        shouldDisplay: true,
        heading: 'Order limit',
        currentPlanCopy: `${merchant!.subscription?.orderLimit} per month`,
        upgradePlanCopy: upgradePlan?.orderLimit! > 0
          ? `${upgradePlan?.orderLimit} per month`
          : 'Unlimited'
      },
      {
        shouldDisplay: true,
        heading: 'Product limit',
        currentPlanCopy: `${merchant?.subscription?.productLimit || '0'} active`,
        upgradePlanCopy: upgradePlan?.productLimit! > 0
          ? `${upgradePlan?.productLimit} active`
          : 'Unlimited'
      },
      {
        shouldDisplay: upgradePlan?.type === PlanType.Premium,
        heading: 'Custom website',
        currentPlanCopy: 'No',
        upgradePlanCopy: 'Yes'
      }
    ];

    return rows.map((row, index) => {
      if (!row.shouldDisplay) return null;

      return (
        <Flex
          css={Styles.RowBorderBottom}
          key={index}
        >
          <Styles.Cell
            isLeft={true}
            cellWidth="130px"
            isHiddenMobile={true}
          >
            <Styles.CellBody>
              {row.heading}
            </Styles.CellBody>
          </Styles.Cell>
          <Styles.Cell>
            <Styles.CellBody fontWeight="700">
              <Styles.CellTitleInline>
                {row.heading}
              </Styles.CellTitleInline>
              <Box>
                {row.currentPlanCopy}
              </Box>
            </Styles.CellBody>
          </Styles.Cell>
          <Styles.Cell bg="validationBg">
            <Styles.CellBody fontWeight="700">
              <Styles.CellTitleInline>
                {row.heading}
              </Styles.CellTitleInline>
              <Box>
                {row.upgradePlanCopy}
              </Box>
            </Styles.CellBody>
          </Styles.Cell>
        </Flex>
      );
    });
  };

  render(): ReactNode {
    if (!this.props.merchantStore!.merchant) return null;

    const merchant = this.props.merchantStore!.merchant;
    const { isLoading, upgradePlan } = this.state;

    return(
      <Styles.Wrapper>
        <WithLoading
          loaderSize="small"
          hasNoResults={!isLoading && !upgradePlan}
          isLoading={!merchant || isLoading}
          renderNoResults={(): ReactNode => (
            <Box mt="40px">
              <NoResultsGeneric
                icon="backpack"
                size="small"
                heading="Unable to load upgrade details"
                copy=""
              />
            </Box>
          )}
          renderBody={(): ReactNode => {
            const currentPlanPrice = CurrencyService.formatPrice(merchant!.subscription!.stripeSubscriptionPrice! || merchant!.plan!.price, merchant!.currency);
            const upgradePlanPrice = CurrencyService.formatPrice(upgradePlan!.price, upgradePlan!.currency);

            return (
              <Styles.Table>
                <Flex>
                  <Styles.Cell
                    cellWidth="130px"
                    isHiddenMobile={true}
                  />
                  <Styles.Cell>
                    <Styles.CellBody>
                      <Box>
                        <Styles.Heading>
                          {LocalisationService.localisePlanName(merchant.plan?.type)}
                        </Styles.Heading>
                        <Styles.PlanPrice>
                          {currentPlanPrice}
                          {' '}
                          <Box
                            as="span"
                            color="shade60"
                          >
                            /mo
                          </Box>
                        </Styles.PlanPrice>
                      </Box>
                    </Styles.CellBody>
                  </Styles.Cell>
                  <Styles.Cell
                    bg="validationBg"
                    css={Styles.UpgradeBorderTop}
                  >
                    <Styles.CellBody>
                      <Box>
                        <Styles.Heading>
                          {LocalisationService.localisePlanName(upgradePlan?.type)}
                        </Styles.Heading>
                        <Styles.PlanPrice>
                          {upgradePlanPrice}
                          {' '}
                          <Box
                            as="span"
                            color="shade60"
                          >
                            /mo
                          </Box>
                        </Styles.PlanPrice>
                      </Box>
                    </Styles.CellBody>
                  </Styles.Cell>
                </Flex>

                {this.renderRows()}

                <Flex flex={1}>
                  <Styles.Cell
                    cellWidth="130px"
                    isHiddenMobile={true}
                  />
                  <Styles.Cell />
                  <Styles.Cell
                    bg="validationBg"
                    alignCell="flex-start"
                    css={Styles.UpgradeBorderBottom}
                  >
                    <Styles.CellBody>
                      <Box>
                        <Box
                          as="button"
                          disabled={this.state.isLoadingModal || !this.state.canMerchantUpgrade}
                          onClick={this.onUpgradePlan}
                        >
                          <Button
                            size="small"
                            copy="Read more"
                            isDisabled={!this.state.canMerchantUpgrade}
                            isLoading={this.state.isLoadingModal}
                          />
                        </Box>
                        {this.renderButtonCopy()}
                      </Box>
                    </Styles.CellBody>
                  </Styles.Cell>
                </Flex>
              </Styles.Table>
            );
          }}
        />
      </Styles.Wrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(OverviewUpgradePlan));

import { MediaCategory } from 'generated-types.d';

export interface Category {
  id: string;
  title: string;
}

export enum Mode {
  create,
  edit
}

export interface MediaItem {
  id?: string;
  src: string;
  name: string;
  category: MediaCategory;
}

import { FC } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ListItem } from 'generated-types.d';

import PromotedListsStore from 'stores/promoted-lists-store/promoted-lists-store';

import { QuantitySelectorWrapper } from 'features/lists/components/checkout-list/checkout-list.styles';

import Button from 'components/button';
import QuantitySelector from 'components/quantity-selector';

export const CheckoutQuantitySelector: FC<{
  listItem: ListItem;
  promotedListsStore?: PromotedListsStore;
}> = inject((stores: FxStores): InjectedFxStores => ({
  promotedListsStore: stores.promotedListsStore
}))(observer(({ listItem, promotedListsStore }) => {
  const increment = listItem.promotedTradeSkuAvailability?.multiplesOf || 0;
  const basketItemQuantity = promotedListsStore!.basketItems.find(item => item.itemId === listItem.id)?.quantity;

  const handleUpdateQuantity = (value: number): void => {
    promotedListsStore!.updateBasketItem({
      itemId: listItem.id,
      quantity: value
    });
  };

  return (
    <QuantitySelectorWrapper width={130}>
      {!!basketItemQuantity ? (
        <QuantitySelector
          step={increment}
          min={0}
          initialValue={basketItemQuantity}
          onChange={handleUpdateQuantity}
          size="responsive"
        />
      ) : (
        <Box
          as="button"
          width="100%"
          onClick={() => handleUpdateQuantity(increment)}
        >
          <Button
            copy="Add"
            size="xsmall"
            isParentWidth={true}
          />
        </Box>
      )}
    </QuantitySelectorWrapper>
  );
}));

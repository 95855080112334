import gql from 'graphql-tag';

export const DISCOUNT_FRAGMENT = gql`
  fragment DiscountFragment on Discount {
    id
    createdAt
    updatedAt
    deletedAt
    lastEditedAt
    startsAt
    endsAt
    usageLimit
    perUserLimit
    amount
    amountMetric
    minBasketValue
    isActive
    isFreeShipping
    code
    name
    description
    isOncePerOrder
    usageCount
    merchant {
      currency
      id
    }
    channels {
      id
      channel
    }
    productTypes {
      id
      slug
      title
      active
    }
    productCategories {
      id
      slug
      title
      active
    }
    selectedProducts {
      id
      title
      variations(first: 3) {
        id
        title
        media {
          id
          src
        }
      }
    }
  }
`;

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const TabWrapper = styled(Flex)`
  padding: 0 30px 5px 30px;
  background: ${colors.uiFills};
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
`;

export const Tab: any = styled(Flex)`
  ${textStyles.footnote};
  color: ${({ isActive }: any): any => isActive ? colors.floomMidnightBlue : colors.shade40};
  padding-right: 18px;
`;

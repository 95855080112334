import React, { ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Text, Box } from 'rebass';

import { Channel } from 'generated-types.d';

import DeliveryService from 'features/settings/components/delivery/services';

import GenericModal from 'components/generic-modal';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import DeliveryConfigEditChannel from './components/delivery-edit-channel';
import DeliveryConfigMakeDefault from './components/delivery-make-default';
import EditDeliveryDays from './components/edit-delivery-days';
import EditDeliveryPricing from './components/edit-delivery-pricing';
import EditLeadTime from './components/edit-lead-time';
import * as Constants from './delivery-config-modal.constants';
import * as Styles from './delivery-config-modal.styles';
import * as Types from './delivery-config-modal.types';

class DeliveryConfigEditModal extends React.Component<Types.DeliveryEditModalProps, Types.DeliveryEditModalState> {
  deliveryService = DeliveryService;

  state: Types.DeliveryEditModalState = {
    isLoading: false,
    errorNotification: null,
    pricingDisplay: 'loading'
  };

  componentDidMount = async (): Promise<void> => {
    this.configurePricingEligibility();
  };

  componentWillUnmount = (): void => {
    this.props.deliveryStore!.resetToDefault();
  };

  private isPricingSupported = (): boolean => {
    switch (this.props.deliveryStore?.selectedChannel) {
      case Channel.Floom:
        return !!this.props.merchantStore!.merchant?.canEditFloomDeliveryPricing;

      case Channel.Website:
        return true;

      default:
        return false;
    }
  };

  private configurePricingEligibility = async (): Promise<void> => {
    switch (true) {
      case this.props.deliveryStore!.formMode === 'create' && this.isPricingSupported():

      case this.props.deliveryStore?.selectedChannel === Channel.Website:
        this.setState({
          pricingDisplay: 'form'
        });

        break;

      case this.props.deliveryStore!.selectedDeliveryConfig?.default === true:
        this.setState({
          pricingDisplay: 'default-delivery-config'
        });

        break;

      case !!this.props.deliveryStore!.selectedDeliveryConfig?.id && this.isPricingSupported() && this.props.deliveryStore!.formMode === 'edit':
        const canEditPricing = await this.deliveryService.canEditDeliveryPricing(this.props.deliveryStore!.selectedDeliveryConfig!.id);
        const isDefault = this.props.deliveryStore!.selectedDeliveryConfig?.deliveryPrice?.isDefault;

        this.setState({
          pricingDisplay: canEditPricing || isDefault === false ? 'form' : 'connected-products-error'
        });

        break;

      default:
        this.setState({
          pricingDisplay: 'none'
        });

        break;
    }
  };

  private submitModal = async (): Promise<any> => {
    this.setState({
      isLoading: true,
      errorNotification: null
    });

    try {
      const { data } = await this.deliveryService.saveModal();
      this.setState({ isLoading: false });

      if (data && (data.updateDeliveryConfig || data.createDeliveryConfig)) {
        this.props.closeModal();
      }
    } catch (error) {
      window?.Sentry?.captureException(error);
      this.setState({ isLoading: false });

      error?.graphQLErrors?.forEach((graphQLError: { code: number }) => {
        switch (graphQLError.code) {
          case 77048575666:
            this.setState({
              errorNotification: Constants.CANNOT_EDIT_PRICING_COPY
            });

            break;

          case 77048575667:
            this.setState({
              errorNotification: Constants.CANNOT_EDIT_PRICING_DEFAULT_CONFIG
            });

            break;

          default:
            break;
        }
      });
    }
  };

  private renderErrorNotification = (): ReactNode => {
    const isDuplicate = this.props.deliveryStore!.isConfigADuplicate();

    if (!isDuplicate || (!isDuplicate && !this.state.errorNotification)) return null;

    const duplicateNames = !!this.props.deliveryStore!.duplicateNames ? ` (${this.props.deliveryStore!.duplicateNames})` : '';

    const copy = isDuplicate
      ? `Whoops! You already have a delivery config that matches this setup${duplicateNames}`
      : this.state.errorNotification;

    return (
      <Box mt="30px">
        <Notification
          type={NotificationType.Error}
          copy={copy}
          textAlign="left"
          hasClose={false}
        />
      </Box>
    );
  };

  private renderPricing = (): ReactNode => {
    if (!this.isPricingSupported()) return null;

    return (
      <EditDeliveryPricing
        displayFormat={this.state.pricingDisplay}
      />
    );
  };

  render(): ReactNode {
    return (
      <GenericModal
        title="Delivery Option"
        confirmButtonText="Save"
        width={650}
        contentLayoutType="full-height"
        confirmButtonAction={this.submitModal}
        modalOpen={this.props.isOpen}
        closeModal={this.props.closeModal}
        isButtonLoading={this.state.isLoading}
        confirmButtonDisabled={!this.props.deliveryStore!.deliveryStoreIsValid()}
      >
        <Styles.InnerContent>
          <Text css={Styles.HeadingText}>
            {this.props.deliveryStore!.title}
          </Text>
          <DeliveryConfigEditChannel />
          {this.renderPricing()}
          <EditDeliveryDays />
          <EditLeadTime />
          <DeliveryConfigMakeDefault />
          {this.renderErrorNotification()}
        </Styles.InnerContent>
      </GenericModal>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  deliveryStore: stores.deliveryStore,
  merchantStore: stores.merchantStore
}))(observer(DeliveryConfigEditModal));

import React, { Component, Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { Box, Flex, Text } from 'rebass';

import {
  UserRole,
  WholesaleOrderConnection,
  WholesaleOrderStates,
  WholesalePreOrderConnection,
  WholesalePreOrderStatus
} from 'generated-types.d';

import { NavService } from 'lib';

import { OrdersListLayout } from 'stores/orders/orders-store.types';

import { Container } from 'utils/css-mixins';
import { gridGutter, textStyles } from 'utils/rebass-theme';

import NoResultsGif from 'assets/images/wholesale/no-results.gif';

import { WholesaleShopHeader } from 'features/wholesale/components/wholesale-shop-header/wholesale-shop-header';

import { TableLayoutHeadingConfigProps } from 'components/entity-list/table-layout-entity.types';
import LayoutToggle from 'components/layout-toggle';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import FloomXRestrictedComponent from '../../../../utils/floomx-types';
import OrderGroup from '../../components/order-group';
import PreOrderGroup from '../../components/pre-order-group';
import WholesaleServices from '../../services';

import * as Types from './orders.types';

export type OrdersGroupConfig =
  | WholesaleOrderGroupConfig
  | WholesalePreOrderGroupConfig;

export interface BaseWholesaleOrderGroupConfig {
  key: string;
  title: string;
  restrictedRoles?: UserRole[];
  headingConfig: TableLayoutHeadingConfigProps[];
  onViewDetail: (nodeId: string) => any;
}

export interface WholesaleOrderGroupConfig extends BaseWholesaleOrderGroupConfig {
  orderType: 'Order';
  items: WholesaleOrderConnection | null;
}

export interface WholesalePreOrderGroupConfig extends BaseWholesaleOrderGroupConfig {
  orderType: 'PreOrder';
  items: WholesalePreOrderConnection | null;
}

class WholesaleOrders extends Component<Types.OrdersProps> {
  private WholesaleOrderService = WholesaleServices.WholesaleOrdersAPIService;

  componentDidMount = (): void => {
    this.props.wholesaleOrdersStore!.setLoading();
    this.WholesaleOrderService.fetchOrders();
    this.WholesaleOrderService.fetchPreOrders();
  };

  componentWillUnmount = (): void => {
    this.props.wholesaleOrdersStore!.resetOrdersStore();
  };

  private navigateToOrderDetail = (orderId: string): void => {
    NavService.wholesaleOrderDetail(orderId);
  };

  private navigateToPreOrderDetail = (orderId: string): void => {
    NavService.wholesalePreOrder(orderId);
  };

  private hasOrders = (): boolean => {
    const hasWholesaleOrders = Object.values(this.props.wholesaleOrdersStore!.orders)
      .some(orderGroup => orderGroup && orderGroup.edges.length);

    const hasWholesalePreOrders = Object.values(this.props.wholesaleOrdersStore!.preOrders)
      .some(orderGroup => orderGroup && orderGroup.edges.length);

    return hasWholesaleOrders || hasWholesalePreOrders;
  }

  private getOrderGroups = (): OrdersGroupConfig[] => [
    {
      key: WholesalePreOrderStatus.Processing,
      title: 'Pre-orders to process',
      restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
      orderType: 'PreOrder',
      items: this.props.wholesaleOrdersStore!.preOrders[WholesalePreOrderStatus.Processing],
      headingConfig: [
        {
          heading: 'Order',
          fixedWidth: '98px'
        },
        {
          heading: 'Merchant',
          restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
          flexGrow: '1',
          flexBasis: '140px'
        },
        {
          heading: 'No. of items',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Total',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Delivery date',
          flexGrow: '1',
          flexBasis: '90px'
        },
        {
          heading: 'Process before',
          restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
          flexGrow: '1',
          flexBasis: '140px'
        },
        {
          heading: '',
          restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
          fixedWidth: '160px'
        }
      ],
      onViewDetail: (nodeId: string): void => this.navigateToPreOrderDetail(nodeId)
    },
    {
      key: WholesalePreOrderStatus.Open,
      title: 'Pre-orders pending',
      restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
      orderType: 'PreOrder',
      items: this.props.wholesaleOrdersStore!.preOrders[WholesalePreOrderStatus.Open],
      headingConfig: [
        {
          heading: 'Order',
          fixedWidth: '98px'
        },
        {
          heading: 'Merchant',
          restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
          flexGrow: '1',
          flexBasis: '140px'
        },
        {
          heading: 'No. of items',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Total',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Delivery date',
          flexGrow: '1',
          flexBasis: '90px'
        },
        {
          heading: 'Start processing',
          restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
          flexGrow: '1',
          flexBasis: '140px'
        }
      ],
      onViewDetail: (nodeId: string): void => this.navigateToPreOrderDetail(nodeId)
    },
    {
      key: `${WholesalePreOrderStatus.Open}-${WholesalePreOrderStatus.Processing}`,
      title: 'Pre-orders',
      restrictedRoles: [UserRole.SuperAdmin, UserRole.CustomerService],
      orderType: 'PreOrder',
      items: this.props.wholesaleOrdersStore!.preOrders['Merchant'],
      headingConfig: [
        {
          heading: 'Order',
          fixedWidth: '98px'
        },
        {
          heading: 'No. of items',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Total',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Delivery date',
          flexGrow: '1',
          flexBasis: '90px'
        },
        {
          heading: 'Status',
          flexGrow: '1',
          flexBasis: '90px'
        }
      ],
      onViewDetail: (nodeId: string): void => this.navigateToPreOrderDetail(nodeId)
    },
    {
      key: WholesaleOrderStates.Open,
      title: `${WholesaleOrderStates.Open} Orders`,
      orderType: 'Order',
      items: this.props.wholesaleOrdersStore!.orders[WholesaleOrderStates.Open],
      headingConfig: [
        {
          heading: 'Order',
          fixedWidth: '98px'
        },
        {
          heading: 'Merchant',
          restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
          flexGrow: '1',
          flexBasis: '140px'
        },
        {
          heading: 'No. of items',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Total',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Delivery date',
          flexGrow: '1',
          flexBasis: '90px'
        }
      ],
      onViewDetail: (nodeId: string): void => this.navigateToOrderDetail(nodeId)
    },
    {
      key: WholesaleOrderStates.Processed,
      title: `${WholesaleOrderStates.Processed} Orders`,
      orderType: 'Order',
      items: this.props.wholesaleOrdersStore!.orders[WholesaleOrderStates.Processed],
      headingConfig: [
        {
          heading: 'Order',
          fixedWidth: '98px'
        },
        {
          heading: 'Merchant',
          restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
          flexGrow: '1',
          flexBasis: '140px'
        },
        {
          heading: 'No. of items',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Total',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Delivery date',
          flexGrow: '1',
          flexBasis: '90px'
        },
        {
          heading: 'Payment status',
          flexGrow: '1',
          flexBasis: '140px'
        }
      ],
      onViewDetail: (nodeId: string): void => this.navigateToOrderDetail(nodeId)
    },
    {
      key: WholesaleOrderStates.Completed,
      title: `${WholesaleOrderStates.Completed} Orders`,
      orderType: 'Order',
      items: this.props.wholesaleOrdersStore!.orders[WholesaleOrderStates.Completed],
      headingConfig: [
        {
          heading: 'Order',
          fixedWidth: '98px'
        },
        {
          heading: 'Merchant',
          restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
          flexGrow: '1',
          flexBasis: '140px'
        },
        {
          heading: 'No. of items',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Total',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Delivery date',
          flexGrow: '1',
          flexBasis: '90px'
        },
        {
          heading: 'Payment status',
          flexGrow: '1',
          flexBasis: '140px'
        }
      ],
      onViewDetail: (nodeId: string): void => this.navigateToOrderDetail(nodeId)
    },
    {
      key: WholesaleOrderStates.Failed,
      title: `${WholesaleOrderStates.Failed} Orders`,
      orderType: 'Order',
      items: this.props.wholesaleOrdersStore!.orders[WholesaleOrderStates.Failed],
      headingConfig: [
        {
          heading: 'Order',
          fixedWidth: '98px'
        },
        {
          heading: 'Merchant',
          restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
          flexGrow: '1',
          flexBasis: '140px'
        },
        {
          heading: 'No. of items',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Total',
          flexGrow: '1',
          flexBasis: '50px'
        },
        {
          heading: 'Delivery date',
          flexGrow: '1',
          flexBasis: '90px'
        },
        {
          heading: 'Payment status',
          flexGrow: '1',
          flexBasis: '140px'
        }
      ],
      onViewDetail: (nodeId: string): void => this.navigateToOrderDetail(nodeId)
    }
  ];

  private renderOrders = (): ReactNode => {
    const orderGroups = this.getOrderGroups();

    return orderGroups.map(orderGroup => {
      const someHaveItems = orderGroup.items?.edges?.some((edge: any) => !!edge?.node.items?.length);

      if (!orderGroup.items?.edges?.length || !someHaveItems) return null;

      if (orderGroup.orderType === 'PreOrder') {
        if (orderGroup.restrictedRoles) {
          return (
            <FloomXRestrictedComponent
              key={`restricted-${orderGroup.orderType}-${orderGroup.key}`}
              restrictedRoles={orderGroup.restrictedRoles}
              considerSuperRole={true}
            >
              <PreOrderGroup
                key={`${orderGroup.orderType}-${orderGroup.key}`}
                preOrderGroup={orderGroup}
              />
            </FloomXRestrictedComponent>
          );
        }

        return (
          <PreOrderGroup
            key={`${orderGroup.orderType}-${orderGroup.key}`}
            preOrderGroup={orderGroup}
          />
        );
      }

      if (orderGroup.restrictedRoles) {
        return (
          <FloomXRestrictedComponent
            key={`restricted-${orderGroup.orderType}-${orderGroup.key}`}
            restrictedRoles={orderGroup.restrictedRoles}
            considerSuperRole={true}
          >
            <OrderGroup
              key={`${orderGroup.orderType}-${orderGroup.key}`}
              orderGroup={orderGroup}
            />
          </FloomXRestrictedComponent>
        );
      }

      return (
        <OrderGroup
          key={`${orderGroup.orderType}-${orderGroup.key}`}
          orderGroup={orderGroup}
        />
      );
    });
  };

  private onLayoutChange = (layoutOption: OrdersListLayout): void => {
    this.props.wholesaleOrdersStore!.setLayout(layoutOption);
  };

  private renderNoOrders = (): ReactNode => {
    return (
      <Box mt={gridGutter}>
        <NoResultsGeneric
          icon="backpack"
          image={NoResultsGif}
          heading="No Orders found"
          copy={(
            <Fragment>
              There are no orders available. Go back to {' '}
              <Link to="/wholesale">
                <Text
                  as="span"
                  style={{
                    textDecoration: 'underline'
                  }}
                >
                  Wholesale
                </Text>
              </Link>
            </Fragment>
          )}
        />
      </Box>
    );
  };

  render(): JSX.Element {
    return (
      <Fragment>
        <WholesaleShopHeader
          title="Wholesale orders"
          shouldDisplay={{
            deliveryDate: true
          }}
        />
        <Container>
          <WithLoading
            isLoading={this.props.wholesaleOrdersStore!.isLoading}
            hasNoResults={!this.hasOrders()}
            renderNoResults={this.renderNoOrders}
          >
            <Box
              mb="50px"
              mt="30px"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
              >
                <Text css={textStyles.h1}>Orders</Text>
                <Box
                  css={css`
                    grid-area: layout;
                    justify-self: end;
                  `}
                >
                  <LayoutToggle
                    layoutOption={this.props.wholesaleOrdersStore!.listLayoutType}
                    onChange={this.onLayoutChange}
                  />
                </Box>
              </Flex>
              {this.renderOrders()}
            </Box>
          </WithLoading>
          {this.props.children}
        </Container>
      </Fragment>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  wholesaleOrdersStore: stores.wholesaleOrdersStore
}))(observer(WholesaleOrders));

import gql from 'graphql-tag';

export const PRODUCT_VARIATION_FRAGMENT = gql`
  fragment VariationsFull on ProductVariation {
    id
    sku
    title
    type
    price
    stock
    media {
      id
      flc_id
      src
      title
      category
    }
    colours {
      id
      name
      hex
    }
    recipe {
      id
      recipeItems {
        id
        componentConfig {
          id
        }
        quantity
      }
    }
    dimensions {
      height
      length
      width
    }
  }
`;

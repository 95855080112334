import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react';

import { inject, observer } from 'mobx-react';

import {
  QuantityInput
} from './list-item-quantity-field.styles';
import {
  ListItemQuantityProps
} from './list-item-quantity-field.types';

const ListItemQuantityView: FC<ListItemQuantityProps> = ({
  listItem,
  selectedListStore
}) => {
  const [controlledQuantity, setQuantity] = useState('');
  const quantityRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!!listItem.quantity || listItem.quantity === 0) {
      setQuantity(listItem.quantity?.toString?.());
    }
  }, [listItem.quantity]);

  const handleReturn = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      updateQuantity();
      quantityRef?.current?.blur?.();
    }
  };

  const handleQuantityBlur = (): void => {
    if (`${listItem.quantity}` !== controlledQuantity) {
      updateQuantity();
    }
  };

  const updateQuantity = async (): Promise<void> => {
    const parsedQuantity = ((): number => {
      const quantity = parseInt(controlledQuantity);

      if (quantity < 0) {
        setQuantity('0');

        return 0;
      }

      return quantity;
    })();

    await selectedListStore!.updateItem({
      item: listItem,
      updateInput: {
        quantity: parsedQuantity
      }
    });
  };

  const handleUpdateQuantity = (e: ChangeEvent<HTMLInputElement>): void => {
    setQuantity(e.currentTarget.value);
  };

  return (
    <QuantityInput
      ref={quantityRef}
      value={controlledQuantity}
      placeholder="Qty"
      type="number"
      min={0}
      onChange={handleUpdateQuantity}
      onBlur={handleQuantityBlur}
      onKeyDown={handleReturn}
    />
  );
};

export const ListItemQuantity = inject((stores: FxStores): InjectedFxStores => ({
  selectedListStore: stores.selectedListStore
}))(observer(ListItemQuantityView));

import { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { PlanFeature } from 'generated-types.d';

import {
  LocalisationService,
  MerchantService,
  NavService,
  TimeService
} from 'lib';

import {
  colors,
  textStyles
} from 'utils/rebass-theme';
import {
  ColourOption
} from 'utils/rebass-theme/rebass-theme.types';

import {
  EntityListRow
} from 'components/entity-list/entity-list.styles';
import TableLayoutEntity from 'components/entity-list/table-layout-entity';
import {
  CellType,
  TableLayoutEntityConfigProps
} from 'components/entity-list/table-layout-entity.types';
import Lozenge from 'components/lozenge';
import StyledLink from 'components/styled-link/styled-link';
import Tooltip from 'components/tooltip';

import * as Config from '../merchants-list/merchants-list.config';

import * as Styles from './merchant-list-item.styles';
import * as Types from './merchant-list-item.types';

class MerchantListItem extends Component<Types.MerchantListItemProps> {
  state = {};

  private onSelectMerchant = (): void => {
    NavService.merchantDetail(this.props.item.id);
  };

  private sameDayPauseInfoCopy = (): string | null => {
    switch (this.props.item.sameDayOrderPauseStatus) {
      case 1:
        return `Unread limit (${this.props.item.sameDayOrderPauseLimit}) reached`;

      case 2:
        return 'Paused by Floomer';

      case 3:
        return 'Paused by Florist';

      default:
        return null;
    }
  };

  private cellConfig = (): TableLayoutEntityConfigProps[] => {
    const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.item);
    const fieldList = this.props.merchantSettingsStore!.fieldList;

    return [
      {
        cellType: CellType.CheckboxToggle,
        isVisible: fieldList.includes('active'),
        fixedWidth: Config.LIST_HEADING_CONFIG[0].fixedWidth,
        props: {
          onChange: (): void => this.props.onToggleActiveState(!this.props.item.active, this.props.item),
          isDisabled: this.props.isActiveToggling,
          switchBgActive: colors.validationText,
          switchBgInactive: colors.errorText,
          switchActiveCopy: 'Yes',
          switchInactiveCopy: 'No',
          isChecked: this.props.item.active
        }
      },
      {
        cellType: CellType.Custom,
        isVisible: fieldList.includes('florist'),
        flexGrow: Config.LIST_HEADING_CONFIG[1].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[1].flexBasis,
        customInnerElement: (
          <Styles.ItemFlorist>
            { this.props.merchantSettingsStore!.newMerchants.includes(this.props.item.id) && (
              <Styles.NewFloristBadge>
                <Lozenge
                  copy="New"
                  bg={ColourOption.validationText}
                />
              </Styles.NewFloristBadge>
            )}
            <Styles.FloristTitle onClick={this.onSelectMerchant}>
              <Tooltip
                message={this.props.item.title}
                styles={Styles.Overflow}
              >
                <StyledLink>
                  {this.props.item.title}
                </StyledLink>
              </Tooltip>
            </Styles.FloristTitle>
          </Styles.ItemFlorist>
        )
      },
      {
        cellType: CellType.Text,
        isVisible: fieldList.includes('merchantId'),
        flexGrow: Config.LIST_HEADING_CONFIG[2].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[2].flexBasis,
        props: {
          text: this.props.item.flc_id || '-'
        }
      },
      {
        cellType: CellType.CheckboxToggle,
        isVisible: fieldList.includes('accepting-sd-orders'),
        flexGrow: Config.LIST_HEADING_CONFIG[3].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[3].flexBasis,
        props: {
          onChange: this.props.onToggleOrderPause,
          switchBgActive: colors.validationText,
          switchBgInactive: colors.floomOrange,
          switchActiveCopy: 'Yes',
          switchInactiveCopy: 'No',
          isDisabled: isCollectionSeller,
          controlValue: true,
          isChecked: this.props.item.sameDayOrderPauseStatus === 0 || this.props.item.sameDayOrderPauseStatus === null,
          extraCopy: this.sameDayPauseInfoCopy(),
          tooltipMessage: isCollectionSeller ? 'SD order pause not supported for Collection seller merchants' : undefined
        }
      },
      {
        cellType: CellType.Custom,
        isVisible: fieldList.includes('location'),
        flexGrow: Config.LIST_HEADING_CONFIG[4].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[4].flexBasis,
        customInnerElement: (
          <Box
            css={textStyles.body}
          >
            <Box>
              {this.props.item.registeredAddress?.city || '-'}
            </Box>
            <Box>
              {this.props.item.registeredAddress?.country || '-'}
            </Box>
          </Box>
        )
      },
      {
        cellType: CellType.Text,
        isVisible: fieldList.includes('plan'),
        flexGrow: Config.LIST_HEADING_CONFIG[5].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[5].flexBasis,
        props: {
          text: this.props.item.plan?.title || '-'
        }
      },
      {
        cellType: CellType.Text,
        isVisible: fieldList.includes('stage'),
        flexGrow: Config.LIST_HEADING_CONFIG[6].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[6].flexBasis,
        props: {
          text: LocalisationService.localiseMerchantStage(this.props.item.stage)
        }
      },
      {
        cellType: CellType.Text,
        isVisible: fieldList.includes('subscription-status'),
        flexGrow: Config.LIST_HEADING_CONFIG[7].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[7].flexBasis,
        props: {
          text: LocalisationService.localiseSubscriptionStatus(this.props.item.subscription?.status).title
        }
      },
      {
        cellType: CellType.Text,
        isVisible: fieldList.includes('first-log-in'),
        flexGrow: Config.LIST_HEADING_CONFIG[8].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[8].flexBasis,
        props: {
          text: this.props.item.acceptedTermsAt ? TimeService.longFormatWithoutDate(this.props.item.acceptedTermsAt) : '-'
        }
      },
      {
        cellType: CellType.Text,
        isVisible: fieldList.includes('product-count'),
        flexGrow: Config.LIST_HEADING_CONFIG[9].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[9].flexBasis,
        props: {
          text: this.props.item.productCount
        }
      },
      {
        cellType: CellType.IndicatorPanel,
        isVisible: fieldList.includes('other'),
        flexGrow: Config.LIST_HEADING_CONFIG[10].flexGrow,
        flexBasis: Config.LIST_HEADING_CONFIG[10].flexBasis,
        props: {
          items: [
            {
              title: 'Terms Agreed',
              value: !!this.props.item.acceptedTermsAt
            },
            {
              title: 'Products Disabled',
              value: !!this.props.item.areProductsDisabledOnFloom
            }
          ]
        }
      }
    ];
  };

  render(): ReactNode {
    if (!this.props.item) return null;

    return (
      <EntityListRow
        key={this.props.item.id}
        removeOverflow={true}
      >
        <Flex
          width="100%"
          key={this.props.item.id}
          p="18px 10px"
          alignItems="center"
        >
          <TableLayoutEntity
            config={this.cellConfig().filter(config => config.isVisible)}
          />
        </Flex>
      </EntityListRow>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantSettingsStore: stores.merchantSettingsStore
}))(observer(MerchantListItem));

import { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Container } from 'utils/css-mixins';
import { textStyles } from 'utils/rebass-theme';

import Button from 'components/button';
import Icon from 'components/icon';

import OnboardingWrapper from '../../components/onboarding-wrapper';

import * as Styles from './onboarding-subscription-issue.styles';
import * as Types from './onboarding-subscription-issue.types';

class OnboardingSubscriptionIssue extends Component<Types.OnboardingSubscriptionIssueProps> {
  private CONTENT = {
    heading: 'There\'s an issue with your subscription',
    subHeading: 'Please contact FloomX support who will be able to assist',
    zendeskMessageCopy: 'Hey FloomX team! When I log-in, FloomX is saying I have an issue with my subscription. Help!'
  };

  private onContact = (): void => {
    this.props.chatWidgetStore!.enableChatWidget();
    this.props.chatWidgetStore!.suppressHelpCenter();
    window['zE']?.('webWidget', 'chat:send', this.CONTENT.zendeskMessageCopy);
  };

  render(): JSX.Element {
    return(
      <section>
        <Container>
          <OnboardingWrapper my="auto">
            <Styles.OnboardingSubscriptionIssue>
              <Box mt="50px">
                <Icon iconName="onboarding-thumb-down" />
              </Box>
              <Box mt="30px">
                <Styles.Heading
                  as="h1"
                  mb="20px"
                  mt="30px"
                  color="floomMidnightBlue"
                  css={textStyles.h2}
                >
                  {this.CONTENT.heading}
                </Styles.Heading>
                <Styles.Heading
                  as="h2"
                  mb="40px"
                  color="floomMidnightBlue"
                  css={textStyles.title}
                >
                  {this.CONTENT.subHeading}
                </Styles.Heading>
                <Styles.ButtonContinue onClick={this.onContact}>
                  <Button
                    size="normal"
                    copy="Contact FloomX"
                    appearance="primary"
                  />
                </Styles.ButtonContinue>
              </Box>
            </Styles.OnboardingSubscriptionIssue>
          </OnboardingWrapper>
        </Container>
      </section>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  chatWidgetStore: stores.chatWidgetStore
}))(observer(OnboardingSubscriptionIssue));

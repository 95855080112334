import {
  SubscriptionStatus,
  MerchantStage,
  PlanType
} from 'generated-types.d';

import {
  SubscriptionStatusMapping,
  StageMapping,
  PlansMapping,
  MerchantsTimezones,
  TimezoneMapping
} from './merchant-settings-store.types';

export const SubscriptionStatuses: SubscriptionStatus[] = [
  SubscriptionStatus.Incomplete,
  SubscriptionStatus.IncompleteExpired,
  SubscriptionStatus.Trialing,
  SubscriptionStatus.Active,
  SubscriptionStatus.PastDue,
  SubscriptionStatus.Canceled,
  SubscriptionStatus.Unpaid,
  SubscriptionStatus.Unsubscribed
];

export const Stages: MerchantStage[] = [
  MerchantStage.Created,
  MerchantStage.InSetup,
  MerchantStage.New,
  MerchantStage.Active,
  MerchantStage.Deactivated,
  MerchantStage.Dormant,
  MerchantStage.Churn,
  MerchantStage.Unassigned
];

export const Plans: PlanType[] = [
  PlanType.Basic,
  PlanType.Advanced,
  PlanType.AdvancedWithFloom,
  PlanType.Lite1,
  PlanType.Lite2,
  PlanType.Standard,
  PlanType.Standard2,
  PlanType.Premium,
  PlanType.Premium2,
  PlanType.StandardLegacy,
  PlanType.NonFloristLegacy,
  PlanType.CollectionManager
];

export const Timezones: MerchantsTimezones[] = [
  MerchantsTimezones.americaChicago,
  MerchantsTimezones.americaLosAngeles,
  MerchantsTimezones.americaNewYork,
  MerchantsTimezones.europeLondon
];

export const SubscriptionStatusMap: SubscriptionStatusMapping = {
  INCOMPLETE: 'Incomplete',
  INCOMPLETE_EXPIRED: 'Incomplete expired',
  TRIALING: 'Trialing',
  ACTIVE: 'Active',
  PAST_DUE: 'Past due',
  CANCELED: 'Canceled',
  UNPAID: 'Unpaid',
  UNSUBSCRIBED: 'Unsubscribed'
};

export const StagesMap: StageMapping = {
  CREATED: 'Created',
  IN_SETUP: 'In setup',
  NEW: 'New',
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  DORMANT: 'Dormant',
  CHURN: 'Churned',
  UNASSIGNED: 'Unassigned'
};

export const PlansMap: PlansMapping = {
  Basic: 'floom.com',
  Advanced: 'Website',
  AdvancedWithFloom: 'floom.com & Website',
  Lite1: 'Lite 1',
  Lite2: 'Lite 2',
  Standard: 'Standard',
  Standard2: 'Standard 2',
  Premium: 'Premium',
  Premium2: 'Premium 2',
  StandardLegacy: 'Standard Legacy',
  NonFloristLegacy: 'Non-Florist Legacy',
  CollectionManager: 'Collection Manager',
  FloomMarket: 'Market',
  WholesaleOnly: 'Wholesale'
};

export const TimezonesMap: TimezoneMapping = {
  [MerchantsTimezones.americaChicago]: 'Chicago (GMT-5)',
  [MerchantsTimezones.americaLosAngeles]: 'Los Angeles (GMT-7)',
  [MerchantsTimezones.americaNewYork]: 'New York (GMT-4)',
  [MerchantsTimezones.europeLondon]: 'London (GMT)'
};

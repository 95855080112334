import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Container = styled(Box)`
  padding: 10px 15px;
  border-radius: 4px;
  background: ${colors.floomBlush};
`;

export const Copy = styled(Box)`
  ${textStyles.body}
  font-weight: 400;
`;

export type ThemeBreakpointOption = 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge';

export type ThemeBreakpoints = { [option in ThemeBreakpointOption]: string };

export type ThemeBreakpointsPixels = {
  [option in ThemeBreakpointOption]: number
};

export type FontSizeOption =
  | 'xSmall'
  | 'small'
  | 'base'
  | 'medium'
  | 'large'
  | 'xLarge'
  | 'xxLarge'
  | 'xxxLarge';

export type FontSizes = { [option in FontSizeOption]: number };

export enum ColourOption {
  azure = 'azure',
  background = 'background',
  chartreuse = 'chartreuse',
  darkGrey = 'darkGrey',
  emeral = 'emeral',
  errorBg = 'errorBg',
  errorText = 'errorText',
  floomBlue = 'floomBlue',
  floomBlush = 'floomBlush',
  floomCream = 'floomCream',
  floomCyan = 'floomCyan',
  floomMidnightBlue = 'floomMidnightBlue',
  floomOrange = 'floomOrange',
  floomRed = 'floomRed',
  floomYellow = 'floomYellow',
  green = 'green',
  grey = 'grey',
  middleGrey = 'middleGrey',
  lightGrey = 'lightGrey',
  lighterGray = 'lighterGray',
  paleGrey = 'paleGrey',
  peach = 'peach',
  shade40 = 'shade40',
  shade60 = 'shade60',
  sky = 'sky',
  uiFills = 'uiFills',
  ultramarine = 'ultramarine',
  validationBg = 'validationBg',
  validationText = 'validationText',
  black = 'black',
  blackLight = 'black-light',
  blue = 'blue',
  lightBlue = 'light-blue',
  lightGreen = 'light-green',
  lightPink = 'light-pink',
  pink = 'pink',
  white = 'white',
  offWhite = 'offWhite',
  violet = 'violet',
  purple = 'purple'
}

export type ColourOptions = { [option in ColourOption]: string };

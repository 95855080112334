import styled from '@emotion/styled';
import { Box, Text } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const NoteSection: any = styled(Box)`
  margin-bottom: 30px;
`;

export const Timestamp: any = styled(Text)`
  ${textStyles.footnote};
  text-transform: uppercase;
  margin-top: 20px;
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { borderRadius, colors, fontSizes, space } from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  background: ${colors.paleGrey};
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const SkeletonBlock = styled.div`
  background-color: ${colors.middleGrey};
  height: ${fontSizes.base}px;
  width: 300px;
  margin-bottom: ${space[2]}px;
  border-radius: ${borderRadius.medium};
  // animate
  animation: shimmer 1.5s infinite;
  background: linear-gradient(to right, ${colors.lightGrey} 20%, ${colors.paleGrey} 50%, ${colors.lightGrey} 90%);
  background-size: 1000px 100%;

  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }

    100% {
      background-position: 1000px 0;
    }
  }
  // end
}`;

export const ListShareWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const CloseButtonWrapper = styled(Box)`
  flex-basis: 22px;
`;

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { createPopup } from '@typeform/embed';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Flex } from 'rebass';

import { Analytics, PermissionsService } from 'lib';

import { OrderNotesModalData } from 'features/modal-dialogue/components/modals/order-notes-modal/order-notes-modal.types';
import { OrderStatusSlug, ExtendedOrderStatus } from 'features/orders/orders.types';
import OrderHelp from 'features/orders/pages/order-detail/components/order-help/order-help';
import { OrderListService, OrderAnalytics, OrdersAPIService } from 'features/orders/services';

import Icon from 'components/icon';

import OrderItemHeader from './item-header';
import {
  statusConfig,
  orderMetaItem,
  OrderMetaColumn
} from './order-item.config';
import * as Styles from './order-item.styles';
import * as Types from './order-item.types';
import StatusQuickSwitch from './status-quick-switch';
import { getOrderNo } from 'utils';

class OrderItem extends Component<Types.OrderItemProps> {
  state: Types.OrderItemState = {
    isUpdatingStatus: false
  };

  static getDerivedStateFromProps(props: Types.OrderItemProps): Partial<Types.OrderItemState> | null {
    const storeStatus = props?.ordersStore?.changedOrderStatuses[props.hit.orderNo];

    if (storeStatus && storeStatus.slug === props.hit.status.slug) {
      props!.ordersStore!.addChangedStatus(props.hit.orderNo, null);
    }

    return null;
  }

  private checkStatus(): OrderStatusSlug {
    const storeStatus = this.props?.ordersStore?.changedOrderStatuses[this.props.hit.orderNo];

    if (!storeStatus) {
      return this.props.hit.status.slug;
    }

    return storeStatus.slug;
  }

  private onStatusUpdate = async (statusSlug: OrderStatusSlug): Promise<void> => {
    try {
      this.setState({ isUpdatingStatus: true });
      const updatedOrder = await OrderListService.updateStatusFromList(statusSlug, this.props.hit);

      OrderAnalytics.onEditStatus(
        Analytics.OrderEditEntry.OrderList,
        statusSlug,
        this.props.hit
      );

      this.setState({ isUpdatingStatus: false });

      this.props!.ordersStore!.addChangedStatus(this.props.hit.orderNo, updatedOrder.status as ExtendedOrderStatus);
      this.props.onRefresh();

      const isInternalRole = PermissionsService.isInternalRole();

      if (isInternalRole || (!isInternalRole && updatedOrder.channel === 'Website')) {
        return;
      }

      const formOptions = {
        hidden: {
          order_no: getOrderNo(updatedOrder.orderNo),
          merchant: updatedOrder.merchant?.title,
          deliver_at: moment(updatedOrder.deliverAt).format('DD/MM/YYYY'),
          ordered_at: moment(updatedOrder.orderedAt).format('DD/MM/YYYY - h:mma'),
          customer_name: updatedOrder.billingAddress?.recipientFullName || '',
          customer_email: updatedOrder.customerUser?.email || '',
          customer_telephone: updatedOrder.billingAddress?.phone || ''
        },
        autoClose: 1000
      };

      if (statusSlug === 'failedDelivery') {
        const { toggle } = createPopup('vFajaJyG', formOptions);
        toggle();
      }

      if (statusSlug === 'cancelled') {
        const { toggle } = createPopup('PwCUOzgN', formOptions);
        toggle();
      }

      if (statusSlug === 'delivered') {
        const podPrompt = window.sessionStorage.getItem('podPrompt') ? JSON.parse(sessionStorage.getItem('podPrompt')!) : null;

        window.sessionStorage.setItem(
          'podPrompt',
          JSON.stringify({
            ...podPrompt,
            hasSeenPodPrompt: true,
            dateSeen: moment().format('YYYY-MM-DD')
          })
        );

        const shouldShowPrompt = !!podPrompt ? moment().isAfter(moment(podPrompt.dateSeen), 'day') : true;

        if (shouldShowPrompt) {
          this.props.modalStore!.triggerModal<any>({
            modalType: 'confirmation',
            data: {
              title: 'Proof of delivery',
              copy: (
                <>
                  Did you know you can now provide proof of delivery from within FloomX? If you&apos;d like to
                  give it a go now, click the button below.
                </>
              ),
              confirmButtonCopy: 'Try it',
              cancelButtonCopy: 'No thanks',
              errorCopy: '',
              confirmButtonAction: (): void => {
                window.sessionStorage.setItem(
                  'podPrompt',
                  JSON.stringify({
                    hasSeenPodPrompt: true,
                    dateSeen: moment().format('YYYY-MM-DD'),
                    hasTriedPod: true
                  })
                );

                const { toggle } = createPopup('bv8N72EL', formOptions);
                toggle();
              }
            }
          });
        }
      }
    } catch (error) {
      this.setState({ isUpdatingStatus: false });
    }
  };

  private onEditOrderNotes = (): void => {
    OrdersAPIService.updateNewStatus(this.props.hit.orderNo, this.props.hit, () => {
      this.props.onRefresh();
    });

    this.props.modalStore!.triggerModal<OrderNotesModalData>({
      modalType: 'orderNotes',
      data: {
        orderNo: this.props.hit.orderNo,
        notes: this.props.hit.orderNotes || [],
        onClose: () => {
          this.props.onRefresh();
        }
      }
    });
  };

  render(): ReactNode {
    const config = statusConfig[this.checkStatus()];

    return (
      <Styles.Wrapper
        alignSelf="stretch"
        p={10}
      >
        <Styles.OrderCard>
          <Styles.OrderCardContent>
            <Flex css={css`position: relative;`}>
              <OrderItemHeader
                layout="grid"
                config={config}
                orderRoute={this.props?.ordersStore?.listLayout || 'today'}
                data={this.props.hit}
                currentStatus={this.checkStatus()}
              />
              <Styles.OrderHelpButton>
                <OrderHelp
                  buttonAppearance="secondary"
                  buttonSize="xxsmall"
                  order={this.props.hit}
                />
              </Styles.OrderHelpButton>
              <Styles.OrderNotesButton onClick={this.onEditOrderNotes}>
                <Styles.OrderNotesIcon
                  hasNotes={this.props.hit.orderNotes && this.props.hit.orderNotes.length}
                  status={config}
                >
                  <Icon iconName="note" />
                </Styles.OrderNotesIcon>
              </Styles.OrderNotesButton>
            </Flex>
            <Styles.Body>
              {
                config.metaItem.map((item: OrderMetaColumn) => {
                  const { component: ColumnComponent, dataKey } = orderMetaItem[item];

                  return (
                    <ColumnComponent
                      key={item}
                      layout="grid"
                      status={this.checkStatus()}
                      referenceNo={this.props.hit.orderNo}
                      id={this.props.hit.id}
                      data={this.props.hit[dataKey] || this.props.hit}
                    />
                  );
                })
              }
            </Styles.Body>
          </Styles.OrderCardContent>
          <StatusQuickSwitch
            layout="grid"
            config={config}
            groupName="today"
            currentStatus={this.checkStatus()}
            orderNo={this.props.hit.orderNo}
            onChange={this.onStatusUpdate}
            isLoading={this.state.isUpdatingStatus}
            orderChannel={this.props.hit.channel}
          />
        </Styles.OrderCard>
      </Styles.Wrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  modalStore: stores.modalStore
}))(observer(OrderItem as any));

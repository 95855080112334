import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import OverviewPage from 'features/overview/overview';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

const Overview = (): JSX.Element => {
  return (
    <AppLayout>
      {/*
      // @ts-ignore */}
      <Helmet>
        <title>Overview</title>
      </Helmet>
      <Router>
        <AuthedRoute
          path="/overview"
          component={OverviewPage}
        />
      </Router>
    </AppLayout>
  );
};

export default Overview;

import React from 'react';

import { Router, Location } from '@reach/router';
import { inject, observer } from 'mobx-react';
import Helmet from 'react-helmet';

import { NavService } from 'lib';

import CategoryModal from 'features/add-ons/components/modals/category-modal';
import NoMerchantModal from 'features/add-ons/components/modals/no-merchant-modal';
import CreateProductModal from 'features/products/components/create-edit-product-layout/create-product-tab/modals/create-product-modal';
import ProductCreate from 'features/products/pages/product-create';
import ProductList from 'features/products/pages/product-list';
import ProductServices from 'features/products/services';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

class Products extends React.Component<any> {
  ProductListService = ProductServices.ProductListService;

  componentDidMount(): void {
    this.ProductListService.fetchProductTypes();
  }

  onCreateModalClose = (): void => {
    this.props.productEditStore.toggleProductCreateTypeModal();
    NavService.productList();
  };

  render(): JSX.Element {
    return (
      <>
        <AppLayout>
          <Location>
            {(): any => {
              return (
                // @ts-ignore
                <Helmet>
                  <title>Products</title>
                </Helmet>
              );
            }}
          </Location>
          <Router>
            <AuthedRoute
              path="/products"
              component={ProductList}
            />
            <AuthedRoute
              path="/products/list"
              component={ProductList}
            />
            <AuthedRoute
              path="/products/edit/:productId/"
              component={ProductCreate}
            />
            <AuthedRoute
              path="/products/edit/:productId/:productTypeId/:page/"
              component={ProductCreate}
            />
            <AuthedRoute
              path="/products/edit/:productId/:page/:variation"
              component={ProductCreate}
            />
            <AuthedRoute
              path="/products/create/:productId/:productTypeId"
              component={ProductCreate}
            />
            <AuthedRoute
              path="/products/create/"
              component={ProductCreate}
            />
          </Router>
        </AppLayout>
        <CreateProductModal
          isOpen={this.props.productEditStore.isProductCreateModalOpen}
          closeAction={(): void => this.onCreateModalClose()}
          availableProductTypes={this.props.productsStore.productTypes}
          merchantId={this.props.userStore.merchantId}
        />
        {/* @TODO change these modals to use the new modal store once Wholesale code is merged */}
        <CategoryModal
          isOpen={this.props.addOnEditStore!.isRestrictedCategoryModalOpen}
          closeAction={this.props.addOnEditStore!.toggleRestrictedCategoryModal}
        />
        <NoMerchantModal
          isOpen={this.props.addOnEditStore!.isNoMerchantModalOpen}
          closeAction={this.props.addOnEditStore!.toggleNoMerchantModal}
        />
      </>
    );
  }
}

export default inject('productEditStore', 'productsStore', 'userStore', 'addOnEditStore')(observer(Products));

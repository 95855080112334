import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { NavService } from 'lib';

import * as Styles from 'features/products/components/create-edit-product-layout/footer-content/footer-content.styles';
import { CreateLeaveModal } from 'features/products/components/create-edit-product-layout/modals/create-leave-modal';

import Button from 'components/button';
import FormFooter from 'components/form-footer';

import { CreateLayoutFooterProps } from './create-layout-footer.types';

class CreateLayoutFooter extends Component<CreateLayoutFooterProps> {
  state = {
    leaveModalOpen: false
  };

  onSave = (): void => {
    this.props.saveAction();
  };

  closeLeaveModal = (): void => {
    this.setState({
      leaveModalOpen: false
    });
  };

  attemptToLeave = (): void => {
    if (this.props.isDirty()) {
      this.setState({
        leaveModalOpen: true
      });
    } else {
      NavService.addOnsList();
    }
  };

  isValid = (): boolean => this.props.addOnEditStore!.validationPass();

  render(): React.ReactNode {
    return (
      <>
        <FormFooter>
          <Styles.FooterWrapper offset={this.props.navigationStore!.navWidth}>
            <Styles.FooterContent>
              <Styles.NextButtonWrapper onClick={this.attemptToLeave}>
                <Button
                  size="normal"
                  appearance="secondary"
                  copy="Leave"
                  isLoading={false}
                  isDisabled={false}
                />
              </Styles.NextButtonWrapper>
              <Styles.NextButtonWrapper
                onClick={this.onSave}
                disabled={!this.isValid()}
              >
                <Button
                  size="normal"
                  appearance="primary"
                  copy="Save"
                  isLoading={this.props.addOnEditStore!.isSaving}
                  isDisabled={!this.isValid()}
                />
              </Styles.NextButtonWrapper>
            </Styles.FooterContent>
          </Styles.FooterWrapper>
        </FormFooter>

        {CreateLeaveModal(
          this.state.leaveModalOpen,
          this.closeLeaveModal
        )}
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  addOnEditStore: stores.addOnEditStore
}))(observer(CreateLayoutFooter));

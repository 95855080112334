import React, { ReactNode, Component, createRef, RefObject } from 'react';

import { Helmet } from 'react-helmet';

import { MODAL_ROUTER_CONFIG } from './modal-router.config';
import * as Styles from './modal-router.styles';
import * as Types from './modal-router.types';

class ModalRouter extends Component<Types.ModalRouterProps> {
  state = {
    modalComponent: MODAL_ROUTER_CONFIG[this.props.config.type].component,
    type: this.props.config.type,
    height: 0,
    width: 0
  };

  scrollRef: RefObject<HTMLDivElement> = createRef();

  modalWidth: number | undefined = MODAL_ROUTER_CONFIG[this.props.config.type]?.width;

  public componentDidMount = (): void => {
    this.updateDimensions();
    this.addEventListener();
  };

  public componentWillUnmount = (): void => {
    window.removeEventListener('resize', this.updateDimensions);
  };

  public componentDidUpdate = (): void => {
    this.switchModalContent();
  };

  private addEventListener = (): void => {
    window.addEventListener('resize', this.updateDimensions);
  };

  public switchModalContent = (): void => {
    if (this.props.config.type !== this.state.type && !!MODAL_ROUTER_CONFIG[this.props.config.type]?.component) {
      this.setState({
        modalComponent: MODAL_ROUTER_CONFIG[this.props.config.type].component,
        type: this.props.config.type
      });
    }
  };

  public updateDimensions = (): void => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
  };

  render(): ReactNode {
    const ModalComponent: any = this.state.modalComponent;

    return(
      <Styles.ModalRouterWrapper width={this.modalWidth}>
        <Helmet htmlAttributes={{ class: 'ReactModal__Html--open' }} />
        <ModalComponent
          {...this.props}
          innerRef={this.scrollRef}
          height={this.state.height}
          width={this.state.width}
        />
      </Styles.ModalRouterWrapper>
    );
  }
}

export default ModalRouter;

import { gql } from '@apollo/client';

import { DISCOUNT_FRAGMENT } from '../fragments/discount.fragments';

export const SINGLE_DISCOUNT = gql`
  query SingleDiscount($where: DiscountWhereUniqueInput!) {
    discount(where: $where) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const DISCOUNT_ANALYTICS = gql`
  query DiscountAnalytics(
    $where: DiscountWhereUniqueInput!
  ) {
    discount(where: $where) {
      totalRevenue
      amountDiscounted
    }
  }
`;

export const DISCOUNTS = gql`
  query Discounts(
    $where: DiscountWhereInput
    $orderBy: DiscountOrderByInput
    $skip: Int
    $first: Int
  ) {
    discountsConnection(
      orderBy: $orderBy
      first: $first
      skip: $skip
      where: $where
    ) {
      pageInfo {
        hasNextPage
      }
      aggregate {
        count
      }
      edges {
        node {
          ...DiscountFragment
        }
      }
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

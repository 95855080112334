import { FC, useMemo } from 'react';

import moment from 'moment';
import { getOrderNo } from 'utils';

import { ORDER_TYPEFORM } from 'features/orders/orders.constants';
import { ExtendedOrder } from 'features/orders/orders.types';

import { ButtonAppearanceOption, ButtonSizeOption } from 'components/button/button.types';
import { TypeformButton } from 'components/typeform';

interface OrderHelpProps {
  order: ExtendedOrder;
  buttonAppearance?: ButtonAppearanceOption;
  buttonSize?: ButtonSizeOption;
}

const OrderHelp: FC<OrderHelpProps> = ({
  order,
  buttonAppearance,
  buttonSize
}) => {
  const typeformConfigs = useMemo(() => {
    const orderDetails = {
      order_no: getOrderNo(order.orderNo),
      merchant: order.merchant?.title,
      deliver_at: moment(order.deliverAt).format('DD/MM/YYYY'),
      ordered_at: moment(order.orderedAt).format('DD/MM/YYYY - h:mma'),
      customer_name: order.billingAddress?.recipientFullName || '',
      customer_email: order.customerUser?.email || '',
      customer_telephone: order.billingAddress?.phone || ''
    };

    return ORDER_TYPEFORM.map(config => {
      return {
        ...config,
        hiddenFields: { ...orderDetails }
      };
    });
  }, [order]);

  return (
    <TypeformButton
      configs={typeformConfigs}
      buttonAppearance={buttonAppearance}
      buttonSize={buttonSize}
      buttonCopy='Order help'
      modalCopy='Order help'
    />
  );
};

export default OrderHelp;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colors, textStyles } from 'utils/rebass-theme';

// Safari puts on some difficult to remove styles, mainly for disabled inputs
// but we want full control so need to override them in all cases
const overrideSafariDisabledStyles = `
  -webkit-text-fill-color: unset;
  -webkit-opacity: 1;
`;

const getPlaceholderStyle = css`
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${colors.shade40};
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 18- */
    color: ${colors.shade40};
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: ${colors.shade40};
    opacity: 1;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.shade40};
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.shade40};
  }
`;

const getDisabledStyle = (inPlaceEdit?: boolean): any => {
  if (inPlaceEdit) {
    return css`
      background: ${colors.uiFills};
      color: ${colors.floomMidnightBlue};
      border-color: ${colors.middleGrey};
      cursor: default;
    `;
  }

  return css`
    cursor: not-allowed;
    background: ${colors.background};
    border-color: ${colors.background};
    color: ${colors.middleGrey};
  `;
};

export const TextAreaStyle = css`
  ${textStyles.subhead};
  position: relative;
  width: 100%;
  padding: 0.5rem;
  border-radius: 3px;
  outline: none;
  background: ${colors.uiFills};
  transition: border-color .2s ease-in-out;

  &:disabled {
    ${overrideSafariDisabledStyles};
  }

  &:focus {
    border-color: ${colors.floomBlue};
  }

  &:invalid {
    box-shadow: none;
  }

  ${getPlaceholderStyle}
`;

export const Content = styled.div<any>`
  > textarea {
    ${TextAreaStyle}
    color: ${(props): any => props.error ? colors.errorText : colors.floomMidnightBlue};
    border: 2px solid ${(props): any => props.error ? colors.errorText : colors.middleGrey};

    &:disabled {
      ${(props): any => getDisabledStyle(props.inPlaceEdit)}
    }
  }
`;

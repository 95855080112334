import {
  ApolloQueryResult,
  ApolloError
} from '@apollo/client';
import store from 'stores';

import {
  Merchant,
  MerchantCreateInput,
  MerchantUpdateDataInput,
  MerchantWhereUniqueInput,
  MerchantBankDetailsInput,
  TeamCreateOneInput,
  AddressCreateInput,
  AuthStripeConnectInput
} from 'generated-types.d';

import {
  GraphQL,
  MerchantMutations
} from 'lib';

import MerchantOnboardingStore from 'stores/merchant-onboarding/merchant-onboarding-store';
import {
  CITY_TIMEZONE_MATRIX
} from 'stores/merchant-onboarding/merchant-onboarding-store.constants';
import {
  MerchantFieldValues,
  MerchantLocationKey,
  AuthStripeConnectFields
} from 'stores/merchant-onboarding/merchant-onboarding-store.types';

import {
  CREATE_MERCHANT,
  AUTH_STRIPE_CONNECT,
  STORE_BANK_ACCOUNT_DETAILS
} from 'features/merchant-onboarding/graphql/mutations/merchant-creation.mutations';

export default class MerchantCreationService {
  private merchantOnboardingStore = store.merchantOnboardingStore as MerchantOnboardingStore;

  public updateMerchant = async (fields: MerchantFieldValues, existingMerchant: Merchant): Promise<Merchant | void> => {
    const data = this.buildMerchantUpdateData(fields, existingMerchant);
    const where: MerchantWhereUniqueInput = { id: existingMerchant.id };

    return GraphQL.query(MerchantMutations.UPDATE_MERCHANT, { data, where })
      .then((merchant: ApolloQueryResult<{ updateMerchant: Merchant }>): Merchant => {
        this.merchantOnboardingStore.populateMerchant(merchant.data.updateMerchant);

        return merchant.data.updateMerchant;
      }).catch((error: ApolloError) => {
        store.toasterStore.popErrorToast('merchant', 'update');

        return Promise.reject(error.graphQLErrors);
      });
  };

  public createMerchant = async (fields: MerchantFieldValues): Promise<Merchant | void> => {
    const data = this.buildMerchantCreateData(fields);

    return GraphQL.query(CREATE_MERCHANT, { data })
      .then((merchant: ApolloQueryResult<{ createMerchant: Merchant }>): Merchant => {
        return merchant.data.createMerchant;
      }).catch((error: ApolloError) => {
        store.toasterStore.popErrorToast('merchant', 'create');

        return Promise.reject(error.graphQLErrors);
      });
  };

  public authStripeConnect = async (fields: AuthStripeConnectFields, existingMerchant: Merchant): Promise<Merchant | void> => {
    const data: AuthStripeConnectInput = { code: fields.code };
    const where: MerchantWhereUniqueInput = { id: existingMerchant.id };

    return GraphQL.query(AUTH_STRIPE_CONNECT, { data, where })
      .then((merchant: ApolloQueryResult<{ authStripeConnect: Merchant }>): Merchant => {
        return merchant.data.authStripeConnect;
      }).catch((error: ApolloError) => {
        store.toasterStore.popErrorToast('merchant stripe connect', 'add');

        return Promise.reject(error.graphQLErrors);
      });
  };

  public storeBankDetails = async (fields: MerchantBankDetailsInput, existingMerchant: Merchant): Promise<Merchant | void> => {
    const data: MerchantBankDetailsInput = fields;
    const where: MerchantWhereUniqueInput = { id: existingMerchant.id };

    return GraphQL.query(STORE_BANK_ACCOUNT_DETAILS, { data, where })
      .then((merchant: ApolloQueryResult<{ updateMerchantBankDetails: Merchant }>): Merchant => {
        return merchant.data.updateMerchantBankDetails;
      }).catch((error: ApolloError) => {
        store.toasterStore.popErrorToast('merchant bank details', 'add');

        return Promise.reject(error.graphQLErrors);
      });
  };

  private connectTeam = (merchant: Merchant): TeamCreateOneInput => ({
    connect: {
      id: merchant?.createdBy?.team?.id
    }
  });

  private buildMerchantName = (merchant: Merchant): string => {
    return `${merchant?.createdBy?.givenName} ${merchant?.createdBy?.familyName}`;
  };

  /**
   * Intentionally imperative, so it's clear to see what's happening top to bottom
   */
  private buildMerchantUpdateData = (data: MerchantFieldValues, existingMerchant: Merchant): MerchantUpdateDataInput => {
    const updatedMerchant: Partial<MerchantUpdateDataInput> = {};

    if (!!data.companyName && data.companyName !== existingMerchant.companyName) {
      updatedMerchant.companyName = data.companyName;
      updatedMerchant.title = data.companyName;
    }

    if (!!data.location && CITY_TIMEZONE_MATRIX[data.location as MerchantLocationKey] !== existingMerchant.timezone) {
      updatedMerchant.timezone = CITY_TIMEZONE_MATRIX[data.location];
      updatedMerchant.currency = this.merchantOnboardingStore.currency;
    }

    if (!!data.plan && data.plan !== existingMerchant.plan?.id) {
      updatedMerchant.plan = {
        connect: {
          id: data.plan
        }
      };
    }

    updatedMerchant.subscription = {
      update: {}
    };

    if (existingMerchant?.subscription?.stripeSubscriptionPrice !== parseFloat(data.planPrice)) {
      updatedMerchant.subscription.update!.stripeSubscriptionPrice = parseInt(data.planPrice);
    }

    if (existingMerchant?.subscription?.trialLength !== parseInt(data.trialPeriod)) {
      updatedMerchant.subscription.update!.trialLength = parseInt(data.trialPeriod);
    }

    if (!!data.tradingNo && data?.tradingNo !== existingMerchant.tradingNo) {
      updatedMerchant.tradingNo = data.tradingNo;
    }

    if (!!data.businessAdPostalCode && !existingMerchant.address) {
      const merchantCreateAddress: AddressCreateInput = {
        businessName: data.businessAdCompanyName,
        recipientFullName: this.buildMerchantName(existingMerchant),
        address1: data.businessAd1,
        address2: data.businessAd1 || null,
        city: data.businessAdCity || null,
        region: '',
        country: this.merchantOnboardingStore.country,
        postalCode: data.businessAdPostalCode.toUpperCase(),
        phone: existingMerchant?.createdBy?.phone || null,
        team: this.connectTeam(existingMerchant)
      };

      updatedMerchant.address = {
        create: merchantCreateAddress
      };

      if (data.hasChosenWholesale) {
        updatedMerchant.wholesaleActive = true;

        updatedMerchant.wholesaleDeliveryConfigs = {
          create: [{
            deliveryInstructions: data.wholesaleDeliveryInstructions,
            active: false,
            isConfirmed: false,
            address: {
              create: merchantCreateAddress
            }
          }]
        };
      } else {
        updatedMerchant.wholesaleActive = false;
      }
    }

    if (existingMerchant.address) {
      updatedMerchant.address = {
        update: {}
      };

      if (data.hasChosenWholesale) {
        updatedMerchant.wholesaleDeliveryConfigs = {
          update: [{
            where: {
              id: existingMerchant.wholesaleDeliveryConfigs?.[0].id
            },
            data: {
              deliveryInstructions: data.wholesaleDeliveryInstructions,
              address: {
                update: {}
              }
            }
          }]
        };
      }

      if (!!data.businessAd1 && data.businessAd1 !== existingMerchant.address.address1) {
        updatedMerchant.address.update = {
          address1: data.businessAd1
        };

        if (data.hasChosenWholesale) {
          updatedMerchant.wholesaleDeliveryConfigs!.update![0].data!.address!.update = {
            ...updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address!.update,
            address1: data.businessAd1
          };
        }
      }

      if (!!data.businessAd2 && data.businessAd2 !== existingMerchant.address.address2) {
        updatedMerchant.address.update = {
          ...updatedMerchant.address.update,
          address2: data.businessAd2
        };

        if (data.hasChosenWholesale) {
          updatedMerchant.wholesaleDeliveryConfigs!.update![0].data!.address!.update = {
            ...updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address!.update,
            address2: data.businessAd2
          };
        }
      }

      if (!!data.businessAdCity && data.businessAdCity !== existingMerchant.address.city) {
        updatedMerchant.address.update = {
          ...updatedMerchant.address.update,
          city: data.businessAdCity
        };

        if (data.hasChosenWholesale) {
          updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address!.update = {
            ...updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address?.update,
            city: data.businessAdCity
          };
        }
      }

      updatedMerchant.address.update = {
        ...updatedMerchant.address.update,
        postalCode: data.businessAdPostalCode ? data.businessAdPostalCode.toUpperCase() : undefined
      };

      if (data.hasChosenWholesale) {
        updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address!.update = {
          ...updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address?.update,
          postalCode: data.businessAdPostalCode ? data.businessAdPostalCode.toUpperCase() : undefined
        };
      }

      if (!!data.phone && data.phone !== existingMerchant?.createdBy?.phone) {
        updatedMerchant!.address!.update = {
          ...updatedMerchant!.address!.update,
          phone: data.phone
        };

        if (data.hasChosenWholesale) {
          updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address!.update = {
            ...updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address?.update,
            phone: data.phone
          };
        }
      }

      if (!!data.companyName && data.companyName !== existingMerchant?.companyName) {
        updatedMerchant!.address!.update = {
          ...updatedMerchant!.address!.update,
          businessName: data.companyName
        };

        if (data.hasChosenWholesale) {
          updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address!.update = {
            ...updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address?.update,
            businessName: data.companyName
          };
        }
      }

      if ((!!data.givenName?.length && !!data.givenName?.length) && `${data.givenName} ${data.familyName}` !== existingMerchant.address.recipientFullName) {
        updatedMerchant!.address!.update = {
          ...updatedMerchant!.address!.update,
          recipientFullName: `${data.givenName} ${data.familyName}`
        };

        if (data.hasChosenWholesale) {
          updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address!.update = {
            ...updatedMerchant.wholesaleDeliveryConfigs!.update![0].data.address?.update,
            recipientFullName: `${data.givenName} ${data.familyName}`
          };
        }
      }
    }

    if (!!data.regAdpostalCode && !!data.regAdcity && !existingMerchant.registeredAddress) {
      updatedMerchant.registeredAddress = {
        create: {
          businessName: existingMerchant?.companyName,
          recipientFullName: this.buildMerchantName(existingMerchant),
          address1: data.regAd1,
          address2: data.regAd2 || null,
          city: data.regAdcity || null,
          region: '',
          country: this.merchantOnboardingStore.country,
          postalCode: data.regAdpostalCode.toUpperCase(),
          phone: existingMerchant?.createdBy?.phone || null,
          team: this.connectTeam(existingMerchant)
        }
      };
    }

    if (!!existingMerchant.registeredAddress) {
      updatedMerchant.registeredAddress = {};

      if (!!data.regAd1 && data.regAd1 !== existingMerchant.registeredAddress.address1) {
        updatedMerchant.registeredAddress.update = {
          ...updatedMerchant.registeredAddress.update || {},
          address1: data.regAd1
        };
      }

      if (!!data.regAd2 && data.regAd2 !== existingMerchant.registeredAddress.address2) {
        updatedMerchant.registeredAddress.update = {
          ...updatedMerchant.registeredAddress.update,
          address2: data.regAd2
        };
      }

      if (!!data.regAdcity && data.regAdcity !== existingMerchant.registeredAddress.city) {
        updatedMerchant.registeredAddress.update = {
          ...updatedMerchant.registeredAddress.update,
          city: data.regAdcity
        };
      }

      if (!!data.regAdpostalCode && data.regAdpostalCode !== existingMerchant.registeredAddress.postalCode) {
        updatedMerchant.registeredAddress.update = {
          ...updatedMerchant.registeredAddress.update,
          postalCode: data.regAdpostalCode ? data.regAdpostalCode.toUpperCase() : undefined
        };
      }

      if (!!data.phone && data.phone !== existingMerchant?.createdBy?.phone) {
        updatedMerchant!.registeredAddress!.update = {
          ...updatedMerchant!.registeredAddress!.update,
          phone: data.phone
        };
      }

      if (!!data.companyName && data.companyName !== existingMerchant?.companyName) {
        updatedMerchant!.registeredAddress!.update = {
          ...updatedMerchant!.registeredAddress!.update,
          businessName: data.companyName
        };
      }

      if ((!!data.givenName?.length && !!data.givenName?.length) && `${data.givenName} ${data.familyName}` !== existingMerchant.registeredAddress.recipientFullName) {
        updatedMerchant!.registeredAddress!.update = {
          ...updatedMerchant!.registeredAddress!.update,
          recipientFullName: `${data.givenName} ${data.familyName}`
        };
      }
    }

    if (this.isUserDifferent(data, existingMerchant)) {
      updatedMerchant.createdBy = {
        update: {
          email: data.email.toLowerCase(),
          phone: data.phone,
          givenName: data.givenName,
          familyName: data.familyName
        }
      };
    }

    return updatedMerchant;
  };

  private isUserDifferent = (data: MerchantFieldValues, existingMerchant: Merchant): boolean => {
    return !!data.email && data.email !== existingMerchant.createdBy?.email
      || !!data.phone && data.phone !== existingMerchant.createdBy?.phone
      || !!data.givenName && data.givenName !== existingMerchant.createdBy?.givenName
      || !!data.givenName && data.familyName !== existingMerchant.createdBy?.familyName;
  };

  private buildMerchantCreateData = (data: MerchantFieldValues): MerchantCreateInput => ({
    active: true,
    companyName: data.companyName,
    title: data.companyName,
    timezone: CITY_TIMEZONE_MATRIX[data.location],
    currency: this.merchantOnboardingStore.currency,
    plan: {
      connect: {
        id: data.plan
      }
    },
    subscription: {
      create: {
        trialLength: parseInt(data.trialPeriod),
        stripeSubscriptionPrice: parseFloat(data.planPrice)
      }
    },
    createdBy: {
      create: {
        email: data.email.toLowerCase(),
        phone: data.phone,
        givenName: data.givenName,
        familyName: data.familyName,
        verified: false,
        active: true
      }
    }
  });
}

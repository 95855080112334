
export enum EditFieldsConfigKey {
  basicMerchantDetails = 'basicMerchantDetails',
  company = 'company',
  businessAddress = 'businessAddress'
}

export type MerchantDetailFields = {
  [key in any]: string
};

export interface MerchantDetailFieldValue {
  isDirty: boolean;
  fields: MerchantDetailFields;
}

export type MerchantDetailFieldValues = {
  [key in EditFieldsConfigKey]: MerchantDetailFieldValue;
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { Card } from 'utils/css-mixins/css-mixins';
import {
  colors,
  textStyles
} from 'utils/rebass-theme';

export const UpgradeBorderTop = css`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

export const UpgradeBorderBottom = css`
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const RowBorderBottom = css`
  border-bottom: 1px solid ${colors.middleGrey};
`;

export const PlanPrice = styled(Box)`
  ${textStyles.subhead};

  margin-top: 10px;
`;

export const Wrapper = styled(Box)`
  ${Card};
  margin: 10px 0;
  padding: 14px 14px 14px 40px;
  flex: 1 1 100%;

  @media (max-width: 930px) {
    padding: 15px;
  }
`;

export const Table = styled(Flex)`
  flex-direction: column;
  text-align: center;
  height: 100%;
`;

export const Cell = styled(Flex)`
  flex: ${(props): string => !!props.cellWidth ? `0 0 ${props.cellWidth}` : '1'};
  padding: 20px 0;
  align-items: ${(props): string => props.alignCell || 'center'};
  justify-content: ${(props): string => props.isLeft ? 'flex-start' : 'center'};

  @media (max-width: 930px) {
    ${(props): any => props.isHiddenMobile ? css`
      display: none;
    ` : ''
}}
  }
`;

export const CellBody = styled(Flex)`
  font-size: 14px;
  font-weight: ${(props): string => props.fontWeight || '400'};
  justify-content: center;
  flex-wrap: wrap;
`;

export const CellTitleInline = styled(Flex)`
  ${textStyles.subhead};

  color: ${colors.shade60};
  margin-bottom: 5px;
  width: 100%;
  font-size: 14px !important;
  justify-content: center;

  @media (min-width: 930px) {
    display: none;
  }
`;

export const ButtonCopy = styled(Box)`
  ${textStyles.body};

  font-size: 12px;
  text-align: center;
  color: ${colors.shade60};
  margin-top: 10px;
  padding: 0 20px;
`;

export const Heading = styled(Box)`
  ${textStyles.title};

  width: 100%;
  text-align: center;
`;


import { OrderDeliveryState } from 'generated-types';
import gql from 'graphql-tag';

import { GraphQLHelpers } from 'lib';

import {
  SelectedCourierListFilters,
  CourierListGroupOption,
  CourierListPageRoute,
  CourierListFilterGroup
} from 'features/courier-bookings/courier-bookings.types';

import { COURIER_BOOKING_FRAGMENT_FULL, BOOKINGS_CONNECTION } from '../fragments/courier-list-fragments';
import { COURIER_LIST_GROUP_OPTIONS, COURIER_LIST_PAGE_LAYOUTS } from '../helpers/courier-list-group.config';
import { renderCourierListGroups, statusBySlug, mapFilters, hasFilters } from '../helpers/courier-list-helpers';

export const addAggregateFilter = (filterKey: CourierListFilterGroup, value: string): string => {
  switch (filterKey) {
    case CourierListFilterGroup.Currency:
      return `
        currency: ${value}
      `;

    case CourierListFilterGroup.Status:
      return `
        ${statusBySlug(value as OrderDeliveryState)}
      `;

    default:
      return '';
  }
};

export const COURIER_LIST_QUERY = (listLayout: CourierListPageRoute, vars: any): any => {
  return gql`
    query CourierListQuery(
      $orderBy: CourierBookingOrderByInput
      ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
    ) {
      ${renderCourierListGroups(listLayout, vars)}
    }
    ${COURIER_BOOKING_FRAGMENT_FULL}
  `;
};

export const PAGINATE_BOOKINGS = (
  vars: any,
  groupName: CourierListGroupOption,
  listLayout: CourierListPageRoute
): any => {
  const filters: SelectedCourierListFilters = vars.filters;

  return gql`
    query PaginateBookings(
      $orderBy: CourierBookingOrderByInput
      ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
    ) {
      courierBookingsConnection(
        first: 20
        skip: ${vars.pagination[groupName]}
        orderBy: $orderBy
        where: {
          deletedAt: null
          ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
          ${hasFilters(filters) ?
    `
              OR: [
                ${mapFilters(filters)}
              ]
            `
    : ''
}
          AND: [
            ${GraphQLHelpers.searchableContainsValues(vars.search)}
            {${COURIER_LIST_GROUP_OPTIONS[groupName].filter}}
            {${COURIER_LIST_PAGE_LAYOUTS[listLayout].globalFilter}}
          ]
        }
      ) {
        ...CourierBookingsConnectionFull
      }
    }
    ${BOOKINGS_CONNECTION}
  `;
};

import styled from '@emotion/styled';

import { breakpoints } from 'utils/rebass-theme';

const Corner = styled.div`
  position: absolute;
  z-index: 1;
  display: flex; // No idea why flex is necessary here

  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const BackgroundWrapper: any = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  @media (min-width: ${breakpoints.medium}) {
    left: ${({ offset }: any): any => offset};
  }
`;

export const TopLeftCorner = styled(Corner)`
  top: 0;
  left: 0;

  @media (max-width: ${breakpoints.large}) {
    top: -100px;
    left: -100px;
  }
`;

export const TopRightCorner = styled(Corner)`
  top: 0;
  right: 0;

  @media (max-width: ${breakpoints.large}) {
    top: -50px;
    right: -100px;
  }
`;

export const BottomRightCorner = styled(Corner)`
  bottom: 0;
  right: 0;

  @media (max-width: ${breakpoints.large}) {
    bottom: -140px;
    right: -180px;
  }
`;

export const BottomLeftCorner = styled(Corner)`
  bottom: 0;
  left: 0;

  @media (max-width: ${breakpoints.large}) {
    bottom: -100px;
    left: -120px;
  }
`;

export const BottomCenter = styled(Corner)`
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: ${breakpoints.large}) {
    bottom: -100px;
    left: -120px;
  }
`;

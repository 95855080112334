import { action, observable, makeObservable } from 'mobx';

import { UserRole, Merchant, User } from 'generated-types.d';

import { Auth, PermissionsService } from 'lib';

import { UserStoreTypes } from './user-store.types';

export default class UserStore implements UserStoreTypes {
  constructor() {
    makeObservable(this, {
      userId: observable,
      role: observable,
      user: observable,
      merchantId: observable,
      userMerchant: observable,
      shopId: observable,
      givenName: observable,
      familyName: observable,
      email: observable,
      setUser: action,
      setAccountUserId: action,
      setAccountRole: action,
      setAccountMerchantId: action,
      setAccountShopId: action,
      setUserMerchant: action,
      setMerchantMasquerade: action,
      isTeamRole: action,
      isSuperTeamRole: action,
      setAccountNames: action,
      setAccountEmail: action,
      init: action
    });

    this.init();
  }

  userId = '';

  role = '';

  merchantId = '';

  shopId = '';

  givenName = '';

  familyName = '';

  email = '';

  userMerchant: Merchant | null = null;

  user: User | null = null;

  init(): void {
    this.setAccountMerchantId(Auth.getUserMerchantId());
    this.setAccountRole(PermissionsService.getUserRole());
    this.setAccountShopId(Auth.getUserShopId());
    this.loadMerchantId();
  }

  reset = (): void => {
    this.userId = '';
    this.role = '';
    this.merchantId = '';
    this.shopId = '';
    this.givenName = '';
    this.familyName = '';
    this.email = '';
    this.userMerchant = null;
    this.user = null;
  };

  setUser = (user: User): void => {
    this.user = user;
  };

  setAccountUserId(userId: string = ''): void {
    this.userId = userId;
  }

  setUserMerchant(userMerchant: Merchant): void {
    this.userMerchant = userMerchant;
  }

  setAccountRole(role: string = ''): void {
    this.role = role;
  }

  setAccountMerchantId(merchantId: string = ''): void {
    this.merchantId = merchantId;
  }

  setAccountShopId(shopId: string = ''): void {
    this.shopId = shopId;
  }

  setAccountNames(givenName: string, familyName: string): void {
    this.givenName = givenName;
    this.familyName = familyName;
  }

  setAccountEmail(email: string): void {
    this.email = email;
  }

  isTeamRole(): boolean {
    return this.role === UserRole.TeamMember
      || this.role === UserRole.TeamOwner
      || this.role === UserRole.TeamAdmin;
  }

  isSuperTeamRole(): boolean {
    return this.role === UserRole.TeamOwner
      || this.role === UserRole.TeamAdmin;
  }

  loadMerchantId(): void {
    const merchantId = Auth.getMerchantIdCookie();

    if (merchantId) {
      this.merchantId = merchantId;
    }
  }

  setMerchantMasquerade(merchantId: string): void {
    Auth.setMerchantIdCookie(merchantId);
    Auth.removeOrderSearchToken();
    this.merchantId = merchantId;
  }
}

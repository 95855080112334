import { ApolloError } from '@apollo/client';
import { action, observable, makeObservable } from 'mobx';

import {
  Colour,
  Supplier,
  SupplierWhereInput
} from 'generated-types.d';

import SuppliersService from 'features/suppliers/services/suppliers.service';

import WholesaleFiltersStoreService from './wholesale-filters-store.service';
import {
  LoaderType
} from './wholesale-filters-store.types';

export default class WholesaleFiltersStore {
  constructor() {
    makeObservable(this, {
      hasError: observable,
      errorMsg: observable,
      colours: observable,
      suppliers: observable,
      filterSearches: observable,
      fetchColours: action,
      fetchSuppliers: action,
      setLoading: action,
      resetLoading: action,
      resetError: action,
      loadingError: action,
      setFilterSearch: action,
      clearFiltersSearch: action
    });
  }

  hasError: boolean = false;

  errorMsg: ApolloError | boolean = false;

  colours: Colour[] = [];

  suppliers: Supplier[] = [];

  filterSearches: Record<string, string> = {};

  public fetchColours = async (): Promise<void> => {
    if (!!this.colours?.length) return;

    try {
      const colours = await WholesaleFiltersStoreService.fetchColours();

      this.colours = colours;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public fetchSuppliers = async (where?: SupplierWhereInput): Promise<void> => {
    if (this.suppliers.length) return;

    try {
      const suppliers = await SuppliersService.fetchSuppliers(where);

      this.suppliers = suppliers;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public resetLoading(loaderType: LoaderType): void {
    this[loaderType] = false;
  }

  public setLoading(loaderType: LoaderType): void {
    this.resetError();
    this[loaderType] = true;
  }

  public resetError(): void {
    this.hasError = false;
  }

  public loadingError(error: any): void {
    this.hasError = true;
    this.errorMsg = error;
  }

  public getHexesByColourName = (colourName: string): string[] => {
    if (colourName === 'Mixed') {
      const mixedHexes = ['Red', 'Blue', 'Yellow', 'Green'].map(mixedColourName => this.colours.find(colourValue => colourValue.name === mixedColourName))
        .filter(hex => hex)
        .map((colour: Colour | undefined): string => {
          return colour && colour.hex || '#FFFFFF';
        });

      return mixedHexes as string[];
    }

    const colourConfigValue = this.colours.find(colourValue => colourValue.name === colourName);

    return [colourConfigValue && colourConfigValue.hex || '#FFFFFF'];
  };

  public getSupplierNameById = (title: string, value: string): string => {
    if (!value) return title;

    const supplier = this.suppliers.find((supplierValue: { id: string }) => supplierValue.id === value) || { name: '' };

    return title.replace(value, supplier.name);
  };

  public getLozengeLabel = (attribute: string, label: string): string => {
    switch(attribute) {
      case 'tradeSku.availability.supplierId':
        const supplier = this.suppliers.find((supplierValue: { id: string }) => supplierValue.id === label) || { name: '' };

        return supplier.name;

      default:
        return label;
    }
  }

  public setFilterSearch = (filterName: string, value: string): void => {
    this.filterSearches = {
      ...this.filterSearches,
      [filterName]: value
    };
  }

  public clearFiltersSearch = (): void => {
    this.filterSearches = {};
  }
}

import React, { ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { CurrencyService } from 'lib';

import FormFieldWrapper from 'components/create-labeled-component';
import FieldText from 'components/field-text';
import Loader from 'components/loader';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import SectionHeading from 'components/section-heading';

import * as ModalConstants from '../../delivery-config-modal.constants';

import * as Types from './edit-delivery-pricing.types';

class EditDeliveryPricing extends React.Component<Types.EditDeliveryPricingProps> {
  onChange = (key: 'configSameDayPrice' | 'configNextDayPrice', value: string): void => {
    this.props.deliveryStore!.updateBasicValue(key, parseFloat(value));
    this.props.deliveryStore!.disableDeliveryPricingNotification();
  };

  private renderView = (): ReactNode => {
    const currency = this.props.deliveryStore?.selectedDeliveryConfig?.merchant?.currency;
    const currencySymbol = !!currency ? CurrencyService.renderCurrencySymbol(currency) : '';

    switch (this.props.displayFormat) {
      case 'none': return null;

      case 'loading': {
        return (
          <Flex
            justifyContent="center"
            p="30px 0"
          >
            <Loader size="small" />
          </Flex>
        );
      }

      case 'connected-products-error':

      case 'default-delivery-config': {
        const copy = this.props.displayFormat === 'connected-products-error'
          ? ModalConstants.CANNOT_EDIT_PRICING_COPY
          : ModalConstants.CANNOT_EDIT_PRICING_DEFAULT_CONFIG;

        return (
          <Box mt="30px">
            <Notification
              type={NotificationType.Info}
              copy={copy}
              textAlign="left"
              hasClose={false}
            />
          </Box>
        );
      }

      case 'form': {
        return (
          <>
            <Flex
              justifyContent="space-between"
              css={css`
                > * {
                  width: calc(50% - 10px);
                }
              `}
            >
              <FormFieldWrapper
                title="Same day price"
                hasSpacing={false}
              >
                <FieldText
                  onChange={(e): void => this.onChange('configSameDayPrice', e.target.value)}
                  value={this.props.deliveryStore!.configSameDayPrice}
                  isSpellCheckEnabled={false}
                  prefix={currencySymbol}
                  type="number"
                  size="normal"
                  min={0}
                  error={this.props.deliveryStore!.configSameDayPrice < 0}
                  errorMessage="Price must be positive"
                  step={0.01}
                />
              </FormFieldWrapper>
              <FormFieldWrapper
                title="Next Day Price"
                hasSpacing={false}
              >
                <FieldText
                  onChange={(e): void => this.onChange('configNextDayPrice', e.target.value)}
                  value={this.props.deliveryStore!.configNextDayPrice}
                  isSpellCheckEnabled={false}
                  prefix={currencySymbol}
                  type="number"
                  size="normal"
                  min={0}
                  error={this.props.deliveryStore!.configNextDayPrice < 0}
                  errorMessage="Price must be positive"
                  step={0.01}
                />
              </FormFieldWrapper>
            </Flex>
          </>
        );
      }

      default:
        break;
    }
  };

  render(): ReactNode {
    return (
      <Box>
        <SectionHeading title="Cost of delivery" />
        {this.renderView()}
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  deliveryStore: stores.deliveryStore,
  merchantStore: stores.merchantStore
}))(observer(EditDeliveryPricing));

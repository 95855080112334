import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { OrdersListLayout } from 'stores/orders/orders-store.types';

import OrderDownload from 'features/orders/components/order-download';
import OrderFilters from 'features/orders/components/order-filters';

import { SearchBox } from 'components/algolia';
import LayoutToggle from 'components/layout-toggle';

import { FilterToggle } from './components/filter-toggle';
import { LoadingIndicator } from './components/loading-indicator';
import { SelectedFiltersNotification } from './components/selected-filters-notification';
import { SortByItem, SortDropdown } from './components/sort-dropdown';
import * as Styles from './order-list-controls.styles';
import * as Types from './order-list-controls.types';

class OrderListControls extends Component<Types.OrderListControlsProps, Types.OrderListControlsState> {
  state: Types.OrderListControlsState = {
    isNotificationVisible: this.props.hasSavedFilters
  };

  componentDidMount = (): void => {
    this.setSearchValue(this.props.ordersStore!.searchFilterValue);
  };

  componentWillUnmount = (): void => {
    this.props.ordersStore!.closeFilters();
  };

  private onLayoutChange = (layoutOption: OrdersListLayout): void => {
    this.props.ordersStore!.setLayout(layoutOption);
  };

  private setSearchValue = (searchValue: string): void => {
    this.props.ordersStore!.setListSearchFilter(searchValue);
  };

  private hideNotification = (): void => {
    this.setState({
      isNotificationVisible: false
    });
  };

  private handleFilterChange = (): void => {
    if (this.state.isNotificationVisible) {
      this.setState({
        isNotificationVisible: false
      });
    }
  };

  private dropdownItems: SortByItem[] = [
    {
      label: 'Order time',
      value: process.env.ALGOLIA_FLOOMX_ORDER_INDEX!
    },
    {
      label: 'Unread',
      value: process.env.ALGOLIA_FLOOMX_ORDER_INDEX_UNREAD!
    },
    {
      label: 'Delivery time',
      value: process.env.ALGOLIA_FLOOMX_ORDER_INDEX_DELIVER_AT!
    }
  ];

  render(): ReactNode {
    return (
      <Box>
        <Styles.Wrapper>
          <Box
            css={css`
              grid-area: search;
            `}
          >
            <SearchBox
              searchValue={this.props.ordersStore!.searchFilterValue}
              onChange={this.setSearchValue}
              delay={400}
            />
          </Box>
          <Box
            css={css`
              grid-area: filters;
              justify-self: start;
              min-width: max-content;
            `}
          >
            <FilterToggle
              isOpen={this.props.ordersStore!.areFiltersVisible}
              onToggle={this.props.ordersStore!.toggleFiltersVisibility}
            />
          </Box>
          <Box
            css={css`
              grid-area: loader;
            `}
          >
            <LoadingIndicator />
          </Box>
          <Box css={Styles.SortDropdown}>
            <SortDropdown
              defaultRefinement={this.props.defaultIndex}
              items={this.dropdownItems}
              onSort={this.props.onSort}
              pageLayout={this.props.pageLayout}
            />
          </Box>
          <Box
            css={css`
              grid-area: layout;
              justify-self: end;
            `}
          >
            <LayoutToggle
              layoutOption={this.props.ordersStore!.layoutOption}
              onChange={this.onLayoutChange}
            />
          </Box>
        </Styles.Wrapper>
        <OrderFilters
          onSelect={this.handleFilterChange}
        />
        <SelectedFiltersNotification
          isVisible={this.state.isNotificationVisible}
          onClick={this.hideNotification}
        />
        <OrderDownload
          searchState={this.props.searchState}
        />
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  merchantStore: stores.merchantStore
}))(observer(OrderListControls));

import React, { FunctionComponentElement, ReactNode } from 'react';

import { observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';
import { IconType } from 'components/icon/icon.types';

import {
  IconBox
} from '../merchant-detail-content.styles';

interface CopyMetadataItemProps {
  shouldDisplay: () => boolean;
  iconName: IconType;
  copy?: () => ReactNode;
}

export const CopyMetadataItem = observer((props: CopyMetadataItemProps): FunctionComponentElement<CopyMetadataItemProps> | null => {
  if (!props.shouldDisplay()) return null;

  return (
    <Flex mt="20px">
      <IconBox>
        <Icon
          iconName={props.iconName}
          pathFill={colors.shade40}
        />
      </IconBox>
      <Box mt="2px">
        {props.copy?.()}
      </Box>
    </Flex>
  );
});

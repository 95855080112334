import { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { GroupItemCount, GroupItemTitle } from '../product-grouping.styles';

class ProductGroup extends Component<any> {
  render(): ReactNode {
    return (
      <Fragment>
        <Box>
          <GroupItemCount>{this.props.productsStore.metadata.count.outOfStock || '0'}</GroupItemCount>
          <GroupItemTitle>Out of stock</GroupItemTitle>
        </Box>
        <Box>
          <GroupItemCount>{this.props.productsStore.metadata.count.lowStock || '0'}</GroupItemCount>
          <GroupItemTitle>Low stock</GroupItemTitle>
        </Box>
      </Fragment>
    );
  }
}

export default inject('productsStore')(observer(ProductGroup));

import { gql } from '@apollo/client';

import { GraphQLHelpers } from 'lib';

export const CREATE_BLANK_WHOLESALE_CONFIG = gql`
  mutation CreateBlankWholesaleConfig(
    $merchantId: ID
    $country: String
  ) {
    createMerchantWholesaleDeliveryConfig(
      where: {
        id: $merchantId
      },
      data: {
        address: {
          create: {
          honorific: " "
          businessName: " "
          recipientFullName: " "
          address1: " "
          address2: ""
          city: " "
          postalCode: " "
          phone: " "
          country: $country
          region: ""
        }
      }
      rijnsCode: " "
      deliveryInstructions: " "
      active: false
    }){
      id
      rijnsCode
      rijnsDeliveryType
      isConfirmed
      deliveryInstructions
      active
      merchant {
        title
        currency
        id
      }
      address {
        id
        honorific
        businessName
        recipientFullName
        address1
        address2
        city
        postalCode
        phone
        country
        region
      }
    }
  }
`;

export const WHOLESALE_DELIVERY_CONFIGS = (merchantId: any): any => {
  return gql`
    query WholesaleDeliveryConfigs ${merchantId ? `(
      ${GraphQLHelpers.addVariable(merchantId, 'merchantId', 'ID')}
    )` : ''} {
      wholesaleDeliveryConfigs(
        where: {
          ${GraphQLHelpers.addMerchantWhereFilter(merchantId)}
        }
      ) {
        id
        rijnsDeliveryType
        isConfirmed
        deliveryInstructions
        active
        merchant {
          title
          currency
          id
        }
        hubs {
          id
          title
          supplier {
            id
            active
            name
          }
        }
        address {
          id
          honorific
          businessName
          recipientFullName
          address1
          address2
          city
          postalCode
          phone
          country
          region
        }
      }
    }
  `;
};

export const UPDATE_WHOLESALE_DELIVERY_CONFIG = gql`
  mutation UpdateWholesaleDeliveryConfig(
    $updates: WholesaleDeliveryConfigUpdateInput!
    $id: ID
    $merchantId: ID
  ) {
    updateMerchantWholesaleDeliveryConfig(
      where: {
        id: $id
        merchant: {
          id: $merchantId
        }
      }
      data: $updates
    ) {
      id
      rijnsCode
      rijnsDeliveryType
      isConfirmed
      active
      deliveryInstructions
      merchant {
        title
        currency
        id
      }
      address {
        id
        honorific
        businessName
        recipientFullName
        address1
        address2
        city
        postalCode
        phone
        country
        region
      }
    }
  }
`;

export const GET_WHOLESALE_GMV = gql`
  query WholesaleGMV($where: MerchantWhereUniqueInput!) {
    wholesaleGMV(where: $where)
  }
`;

export const GET_WHOLESALE_TIERS = gql`
  query AdjusterTiers(
    $where: AdjusterTierWhereInput!
  ) {
    adjusterTiers(where: $where) {
      id
      max
      min
      qualificationType
      adjusters {
        id
        appliesTo
        percent
      }
    }
  }
`;

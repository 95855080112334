import styled from '@emotion/styled';

export const Content = styled.div<any>`
  width: 170px;
  margin-top: auto;
  cursor: ${(props: any): any => props.disabled ? 'normal' : 'pointer'};

  > div {
    height: ${(props: any): any => props.subtitleText ? '78px' : '57px'};
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 12px;
    padding-left: 15px;
  }
`;

export const EditIcon = styled.div<any>`
  visibility: ${(props: any): any => props.displayIcon ? 'visible' : 'hidden'};
  position: absolute;
  top: -8px;
  right: -15px;
  height: 22px;
  width: 22px;
 `;

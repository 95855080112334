import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const Content = styled.div`
  max-width: 100%;
  min-height: 100%;
`;

export const InnerContent = styled.div`
  padding: 20px 20px 20px 20px;
  > p {
    ${textStyles.body}
    margin-bottom: 15px;
  }
`;

export const DefaultWrapper = styled(Flex)`
  align-items: center;
  margin-top: 20px;
  > p {
    ${textStyles.body}
    padding-left: 10px;
    margin-bottom: 0px;
  }
`;

import { Component, ReactNode, ReactElement } from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf
} from '@react-pdf/renderer';
import { inject, observer } from 'mobx-react';
import { CSVDownload } from 'react-csv';
import ReactDOM from 'react-dom';
import { Flex } from 'rebass';

import { PermissionsService } from 'lib';

import { OrderListService } from 'features/orders/services';

import Button from 'components/button';

import * as Styles from './order-download.styles';
import * as Types from './order-download.types';

class OrderDownload extends Component<Types.OrderDownloadProps, Types.OrderDownloadState> {
  state: Types.OrderDownloadState = {
    isLoadingPDF: false,
    isLoadingCSV: false
  };

  private renderCsv = async (): Promise<void> => {
    this.setState({ isLoadingCSV: true });
    const orderExportList: any = await OrderListService.fetchOrdersForExport(this.props.searchState);
    this.setState({ isLoadingCSV: false });

    const anchor = document.createElement('div');
    anchor.setAttribute('id', 'csvDownloadLink');
    document.body.appendChild(anchor);
    anchor.style['display'] = 'none';

    const stripFields = ['senderPhone', 'senderEmail', 'recipientPhone', '__typename'];

    const readableFieldName = (fieldName: string): string => {
      return fieldName.charAt(0).toUpperCase() + fieldName.replace(/([A-Z]+)/g, '$1').replace(/([A-Z][a-z])/g, ' $1').slice(1);
    };

    const csvData = orderExportList.data.map((row: any) => {
      const csvRow = {};

      for (const field of Object.keys(row)) {
        if (!stripFields.includes(field)) {
          csvRow[readableFieldName(field)] = row[field];
        }
      }

      return csvRow;
    });

    const DownloadLink = (): JSX.Element => (
      <CSVDownload
        data={csvData}
        target="_blank"
      />
    );

    ReactDOM.render(<DownloadLink />, anchor, () => {
      document.getElementById('csvDownloadLink')?.querySelector('a')?.click();
    });
  };

  private renderPdf = async (): Promise<void> => {
    this.setState({ isLoadingPDF: true });
    const orderExportList = await OrderListService.fetchOrdersForExport(this.props.searchState);
    this.setState({ isLoadingPDF: false });

    const createPdfPages = (): ReactNode => {
      if (!orderExportList.data?.length) return null;

      const styledPDF = StyleSheet.create({
        section: {
          padding: 30,
          fontFamily: 'Helvetica'
        },
        header: {
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 30,
          paddingRight: 15,
          paddingTop: 20,
          height: 100
        },
        body: {
          height: 400,
          paddingLeft: 60,
          paddingRight: 30,
          paddingTop: 50
        },
        footer: {
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 70,
          paddingLeft: 15,
          paddingRight: 15,
          height: 300
        },
        footerColumn: {
          width: '50%',
          paddingLeft: 50
        },
        headerColumn: {
          flexGrow: 1
        },
        headerContact: {
          paddingTop: 100
        },
        headerText: {
          fontSize: '12pt',

          paddingBottom: 10
        },
        bodyText: {
          fontSize: '12pt',

          paddingBottom: 10
        },
        inlineHeading: {
          fontWeight: 600
        },
        blockHeading: {
          fontSize: '20pt',
          paddingBottom: 15,
          textDecoration: 'underline'
        }
      });

      const InlineHeading = (copy: string): ReactNode => {
        return (
          <Text style={styledPDF.inlineHeading}>
            {copy}:
          </Text>
        );
      };

      const BlockHeading = (copy: string): ReactNode => {
        return (
          <Text style={styledPDF.blockHeading}>
            {copy}:
          </Text>
        );
      };

      return orderExportList.data.map(order => {
        return (
          <Page
            size="A4"
            orientation="portrait"
            key={order!.orderNumber!}
          >
            <View style={styledPDF.header}>
              <View style={styledPDF.headerColumn}>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Order number')} {order!.orderNumber}
                </Text>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Florist')} {order!.merchantName}
                </Text>
              </View>
              <View style={styledPDF.headerColumn}>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Delivery Date')} {order!.deliveryDate}
                </Text>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Delivery cutoff')} {order!.deliveryCutoff}
                </Text>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Source')} {order!.channel}
                </Text>
              </View>
            </View>
            <View style={styledPDF.body}>
              <View>
                {BlockHeading('Delivery Details')}
                <Text style={styledPDF.bodyText}>
                  {order!.recipientName}
                </Text>
                <Text style={styledPDF.bodyText}>
                  {order!.recipientAddress}
                </Text>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Delivery Instructions')} {order!.deliveryInstructions}
                </Text>
              </View>
              <View style={styledPDF.headerContact}>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Sender number')} {order!.senderPhone}
                </Text>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Sender email')} {order!.senderEmail}
                </Text>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Recipient number')} {order!.recipientPhone}
                </Text>
              </View>
            </View>
            <View style={styledPDF.footer}>
              <View style={styledPDF.footerColumn}>
                {BlockHeading('Products')}
                <Text style={styledPDF.bodyText}>
                  {order!.products}
                </Text>
                <Text style={styledPDF.bodyText}>
                  {order!.addons}
                </Text>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Floom Phone')} +44 020 8068 7498
                </Text>
                <Text style={styledPDF.headerText}>
                  {InlineHeading('Floom Email')} support@floom.com
                </Text>
              </View>
              <View style={styledPDF.footerColumn}>
                {BlockHeading('Gift messages')}
                <Text style={styledPDF.headerText}>
                  {order!.giftMessages}
                </Text>
              </View>
            </View>
          </Page>
        );
      });
    };

    const PdfExport = (): ReactElement<any> => (
      <Document>
        {createPdfPages()}
      </Document>
    );

    const blob = await pdf(PdfExport()).toBlob();
    window.open(window.URL.createObjectURL(blob), '_blank');
  };

  private shouldRenderDownloadControls = (): boolean => {
    return !!this.props.merchantStore!.merchant?.canDownloadCsv
      && (this.props.ordersStore!.listLayout === 'today'
        || this.props.ordersStore!.listLayout === 'tomorrow'
        || this.props.ordersStore!.listLayout === 'upcoming'
      )
      && !PermissionsService.isInternalRole();
  };

  render(): ReactNode {
    if (!this.shouldRenderDownloadControls()) return null;

    return (
      <Flex mt="20px">
        <Styles.DownloadButton
          onClick={this.renderPdf}
          mr="15px"
        >
          <Button
            copy="Download PDF"
            isLoading={this.state.isLoadingPDF}
          />
        </Styles.DownloadButton>
        <Styles.DownloadButton onClick={this.renderCsv}>
          <Button
            copy="Download CSV"
            isLoading={this.state.isLoadingCSV}
          />
        </Styles.DownloadButton>
      </Flex>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  ordersStore: stores.ordersStore,
  merchantStore: stores.merchantStore
}))(observer(OrderDownload));

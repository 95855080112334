import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box, Flex } from 'rebass';

import { borderRadius, breakpoints, colors, fontSizes, textStyles } from 'utils/rebass-theme';

export const GroupHeader = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 1.5em;
`;

export const ItemsWrapper = styled(Box)`
  position: relative;
`;

export const ListHeading = styled(Box)`
  ${textStyles.title};
  margin: 30px 0 20px 2em;

  @media (max-width: ${breakpoints.medium}) {
    margin-left: 1em;
  }
`;

export const ItemPriceWrapper = styled(Flex)`
  flex-direction: column;
  flex: 1;
`;

export const ItemTotalPrice = styled(Box)`
  font-size: ${fontSizes.base}px;
  color: ${colors.black};
`;

export const ItemUnitPrice = styled(Box)`
  font-size: ${fontSizes.xSmall}px;
  color: ${colors.grey};
`;

export const ListCardHeader = styled(Box)<{ isSticky: boolean }>`
  font-size: ${fontSizes.base}px;
  font-weight: bold;
  padding: 20px;
  padding-left: 32px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  color: ${colors.white};

  ${({ isSticky = false }) => {
    if (!!isSticky) {
      return `
        position: -webkit-sticky; /* Safari */
        position: sticky;
        bottom: 0;
      `;
    }

    return 'position: relative;';
  }}

  ${({ onClick }) => {
    if (!!onClick) {
      return `
        -webkit-text-decoration: underline; /* Safari */
        text-decoration: underline; 
        cursor: pointer;
      `;
    }
  }}
`;

export const ListCardContent = styled(Box)`
  padding: 16px 32px;

  @media (max-width: ${breakpoints.medium}) {
    padding: 16px 20px;
  }
`;

export const ListCard = styled(Box)<{ color: string }>`
  display: contents;
  margin-bottom: 20px;
  background: ${colors.uiFills};
  box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
  border-radius: ${borderRadius.large};
  width: 100%;

  & ${ListCardHeader} {
    background-color: ${({ color }) => color};
  }

  & ${ListCardContent} {
    background: inherit;
    margin-bottom: inherit;
    box-shadow: inherit;
    border-radius: inherit;
    border: 1px solid ${({ color }) => hex2rgba(color, .5)};
  }

  & ${ListCardHeader} + ${ListCardContent} {
    /* Extend ListCardContent underneath ListCardHeader to get box-shadow */
    margin-top: -58.4px;
    padding-top: 74.4px;
  }
`;

import { PaymentOrderByInput } from 'generated-types.d';

import { EntityListConfig } from 'components/entity-list/entity-list.types';

export const ENTITY_LIST_CONFIG: EntityListConfig = {
  hasSearch: true,
  hasSort: true,
  searchPlaceholder: 'Enter a payment ID',
  sortOptions: [
    {
      title: 'Newest',
      key: PaymentOrderByInput.UpdatedAtDesc
    },
    {
      title: 'Oldest',
      key: PaymentOrderByInput.UpdatedAtAsc
    },
    {
      title: 'Amount highest first',
      key: PaymentOrderByInput.AmountDesc
    },
    {
      title: 'Amount lowest first',
      key: PaymentOrderByInput.AmountAsc
    }
  ]
};

import { FC } from 'react';

import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box } from 'rebass';

import {
  colors
} from 'utils/rebass-theme';

interface LoadingSpinnerProps {
  color?: string;
  size?: number;
}

const IconWrapper = styled(Box)<{ color: string; size: number }>`
  position:relative;
  height:100%;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  .circular-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
  }

  .loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    stroke-linecap: round;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 5s ease-in-out infinite;
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1,200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -124;
    }
  }

  @keyframes color {
    0% {
      stroke: ${({ color }) => color};
    }

    40% {
      stroke: ${({ color }) => color};
    }

    66% {
      stroke: ${({ color }) => color};
    }

    80%, 90% {
      stroke: ${({ color }) => color};
    }
  }
`;

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  color,
  size
}) => {
  return (
    <IconWrapper
      color={color}
      size={size}
    >
      <svg
        className="circular-loader"
        viewBox={`${size} ${size} ${size! * 2} ${size! * 2}`}
      >
        <circle
          className="loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke={color!}
          strokeWidth="4"
        />
      </svg>
    </IconWrapper>
  );
};

LoadingSpinner.defaultProps = {
  color: hex2rgba(colors.floomMidnightBlue, 0.6),
  size: 25
};

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, borderRadius, shadows } from 'utils/rebass-theme';

import { StyledRadioItem } from '../radio-item/radio-item.styles';

const isVertical = (orientation: string): boolean => orientation === 'vertical';

export const RadioLabelCard: any = styled(Flex)<any>`
  position: relative;
  padding: 13px 10px 17px;
  user-select: none;
  text-align: center;
  transition: border .2s ease-in-out,
              box-shadow .2s ease-in-out,
              color .2s ease-in-out,
              background .2s ease-in-out;
  border-radius: ${borderRadius.medium};
  background: ${({ isSelected }): string => isSelected ? colors.floomMidnightBlue : colors.uiFills};
  color: ${({ isSelected }): string => isSelected ? colors.uiFills : colors.shade60};
  border: 2px solid ${({ isSelected }): string => isSelected ? colors.floomMidnightBlue : colors.shade40};
  box-shadow: ${({ isSelected }): string => isSelected ? shadows.smallSharp : 'none'};
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-self: stretch;

  > * {
    width: 100%;
  }
`;

export const RadioLabelWrapper = styled(Box)`
  width: 100%;

  ${(): any => StyledRadioItem} + ${(): any => StyledRadioItem} & {
    ${({ orientation }: any): any => isVertical(orientation) ? `
      margin-top: 10px;
    ` : `
      margin-left: 10px;
    `};
  }
`;

export const RadioLabelDescription = styled(Box)`
  text-align: center;
  line-height: 1.4;
  margin-top: 12px;
  padding: 0 10px;
  font-size: 12px
`;

import { FC } from 'react';

import { css } from '@emotion/react';
import { Link } from '@reach/router';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

import { SupplierSelect } from 'features/suppliers/components/supplier-select/supplier-select';

import Icon from 'components/icon';

import {
  CheckoutListHeaderProps
} from './checkout-list-header.types';

const CheckoutListHeaderComponent: FC<CheckoutListHeaderProps> = ({
  checkoutListStore,
  toasterStore
}) => {
  if (!checkoutListStore!.list) return null;

  const supplierOptions = checkoutListStore!.suppliers.map(supplier => {
    return {
      title: supplier?.name || 'Unknown',
      id: supplier.id,
      isActive: supplier.active,
      onSelect: () => updateSupplier(supplier.id)
    };
  });

  const activeSupliers = supplierOptions.filter(option => option.isActive);
  const selectedSupplierId = checkoutListStore!.list?.suppliers?.[0]?.id;

  const updateSupplier = async (supplierId: string): Promise<void> => {
    try {
      checkoutListStore!.toggleIsUpdatingSupplier(true);

      await checkoutListStore!.updateList({
        id: checkoutListStore!.list!.id,
        data: {
          suppliers: {
            set: [
              {
                id: supplierId
              }
            ]
          }
        }
      });

      await checkoutListStore!.fetchDeliveryDates({ listId: checkoutListStore!.list!.id });
      await checkoutListStore!.checkAvailability({ listId: checkoutListStore!.list!.id });

      toasterStore!.popSuccessToast('Your chosen supplier', 'change');
      checkoutListStore!.toggleIsUpdatingSupplier(false);
    } catch (error) {
      toasterStore!.popErrorToast('your supplier', 'change');
    }
  };

  const isActiveSupplierSelected = (): boolean => {
    return !!selectedSupplierId
      && activeSupliers.some(supplier => supplier.id === selectedSupplierId);
  };

  return (
    <Box p="60px 0 15px 0">
      <Link
        to={`${NavPages.Lists}${checkoutListStore!.list.id}`}
        css={css`
          font-size: 14px;
          padding-bottom: 8px;
          display: block;
        `}
      >
        <Flex alignItems="center">
          <Box
            pr="7px"
            height="13px"
            color={colors.floomMidnightBlue}
          >
            <Icon
              iconName="arrow-full-left"
              size={14}
            />
          </Box>
          <Box color={colors.floomMidnightBlue}>
            Back to list
          </Box>
        </Flex>
      </Link>
      <Box css={textStyles.h2}>
        Checkout
      </Box>
      <SupplierSelect
        supplierOptions={supplierOptions}
        selectedSupplierId={selectedSupplierId}
        isLoading={checkoutListStore!.isCheckingAvailability || checkoutListStore!.isUpdatingSupplier}
        shouldAllowSelect={activeSupliers.length > 1 || !isActiveSupplierSelected()}
      />
    </Box>
  );
};

export const CheckoutListHeader = inject((stores: FxStores): InjectedFxStores => ({
  checkoutListStore: stores.checkoutListStore,
  toasterStore: stores.toasterStore
}))(observer(CheckoutListHeaderComponent));

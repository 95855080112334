import { Component, Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box, Flex, Text } from 'rebass';
import { TestMessageParams } from 'yup';

import { textStyles, colors } from 'utils/rebass-theme';

import CheckboxIcon from 'components/checkbox-icon';
import CheckboxToggle from 'components/checkbox-toggle';
import DropdownNative from 'components/dropdown-native';
import FieldDatePicker from 'components/field-date-picker';
import FieldItemSelector from 'components/field-item-selector';
import { FieldItemSelectorProps } from 'components/field-item-selector/field-item-selector.types';
import FieldTagSelection from 'components/field-tag-selection';
import { FieldTagSelectionProps } from 'components/field-tag-selection/field-tag-selection.types';
import FieldText from 'components/field-text';
import FieldTextArea from 'components/field-text-area';
import Notification from 'components/notification';
import RadioList from 'components/radio-list';
import StyledLink from 'components/styled-link';

import * as Styles from './form-builder.styles';
import * as Types from './form-builder.types';

class FormBuilder extends Component<Types.FormBuilderProps> {
  private renderField = (config: Types.FormBuilderField, error: TestMessageParams | undefined): ReactNode => {
    switch (config.fieldType) {
      case 'textInput': {
        return (
          <FieldText
            {...config as any}
            error={!!error?.label}
          />
        );
      }

      case 'datePicker': {
        return (
          <Box>
            <FieldDatePicker
              {...config as any}
              customDatePickerStyles={css`
                .DayPickerInput-OverlayWrapper {
                  z-index: 1;
                }
              `}
            />
          </Box>
        );
      }

      case 'dropdown': {
        return (
          <DropdownNative
            fullWidth={true}
            {...config as any}
          />
        );
      }

      case 'radioList': {
        return (
          <Box mt="8px">
            <RadioList
              {...config as any}
            />
          </Box>
        );
      }

      case 'toggle': {
        return (
          <Flex
            alignItems="center"
          >
            <CheckboxToggle {...config as any} />
            { !!config?.label && (
              <Box
                css={textStyles.body}
                ml="10px"
              >
                {config?.label}
              </Box>
            )}
          </Flex>
        );
      }

      case 'singleCheckbox': {
        const key = `form-builder-singlecheckbox-${config.key}`;

        return (
          <Flex
            css={config.customStyles}
          >
            <Styles.SingleCheckboxIcon
              as="label"
              htmlFor={key}
            >
              <CheckboxIcon
                isSelected={config.isSelected!}
                isDisabled={config.isDisabled!}
              />
            </Styles.SingleCheckboxIcon>
            <Styles.SingleCheckboxLabel
              as="label"
              htmlFor={key}
            >
              <Box
                color="floomMidnightBlue"
                dangerouslySetInnerHTML={{
                  __html: config.label
                }}
              />
              { !!config.labelCopy && (
                <Box
                  css={textStyles.body}
                  color="shade60"
                  mt="6px"
                >
                  {config.labelCopy}
                </Box>
              )}
            </Styles.SingleCheckboxLabel>
            <input
              type="checkbox"
              checked={config.isSelected}
              id={key}
              css={css`
                display: none;
              `}
              onChange={(): void => {
                config.handleSelect();
              }}
            />
          </Flex>
        );
      }

      case 'textArea': {
        return (
          <Box
            alignItems="center"
          >
            { !!config.subHeading && (
              <Box
                css={textStyles.subhead}
                mb="15px"
              >
                {config.subHeading}
              </Box>
            )}
            <FieldTextArea {...config as any} />
          </Box>
        );
      }

      case 'labelSmall': {
        const smallCapsCss = {
          ...textStyles.footnote,
          textTransform: 'uppercase'
        };

        return (
          <Fragment>
            <Text
              css={!config.isSmallCaps ? textStyles.subhead : smallCapsCss}
              mb={!config.subCopy ? '10px' : '5px'}
              mt={!config.isSmallCaps ? '20px' : ''}
            >
              {config.copy} {config.isOptional && <Styles.OptionalLabel>Optional</Styles.OptionalLabel>}
            </Text>
            {!!config.subCopy && (
              <Text
                css={textStyles.body}
                color="shade60"
                mb="10px"
              >
                {config.subCopy}
              </Text>
            )}
          </Fragment>
        );
      }

      case 'label': {
        return (
          <Box>
            <Flex
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                css={textStyles.title}
                mb={config.subCopy ? '2px' : '16px'}
              >
                {config.copy} {config.isOptional && <Styles.OptionalLabel>Optional</Styles.OptionalLabel>}
              </Text>
              { !!config.onClickAction && !!config.actionCopy && (
                <Box
                  as="button"
                  onClick={config.onClickAction}
                  disabled={config.isActionDisabled}
                  mb={config.subCopy ? '2px' : '16px'}
                >
                  <StyledLink
                    isDisabled={!!config.isActionDisabled}
                    color={config.actionColour || colors.floomMidnightBlue}
                  >
                    {config.actionCopy}
                  </StyledLink>
                </Box>
              )}
            </Flex>
            { !!config.subCopy && (
              <Text
                css={textStyles.body}
                color="shade60"
                mb="16px"
              >
                {config.subCopy}
              </Text>
            )}
          </Box>
        );
      }

      case 'copy': {
        return (
          <Text
            mt="15px"
            mb="10px"
            css={textStyles.body}
          >
            {config.copy}
          </Text>
        );
      }

      case 'tagSelection': {
        return (
          <FieldTagSelection {...config as FieldTagSelectionProps} />
        );
      }

      case 'itemSelector': {
        return (
          <FieldItemSelector {...config as FieldItemSelectorProps} />
        );
      }

      case 'custom': {
        return config.customContent;
      }

      default:
        return null;
    }
  };

  private renderError = (error: TestMessageParams | undefined): ReactNode => {
    if (!error) return null;

    return (
      <Text
        m="10px 0"
        css={textStyles.footnote}
        color={colors.errorText}
      >
        {error.label}
      </Text>
    );
  };

  private buildSections = (): ReactNode => {
    return this.props.config.sections.map((section, index) => {
      if (section.isHidden) return null;

      return (
        <Styles.SectionWrapper
          key={index}
          width={section.width}
          paddingBottom={section.paddingBottom}
        >
          {
            section.fields
              .filter(field => !field.isHidden)
              .map((fieldConfig, fieldIndex: number) => (
                <Box
                  key={fieldIndex}
                  mt="5px"
                  className={!!fieldConfig.validationError ? 'form-builder-section--has-error' : ''}
                >
                  {this.renderField(fieldConfig, fieldConfig.validationError)}
                  {this.renderError(fieldConfig.validationError)}
                </Box>
              ))
          }
        </Styles.SectionWrapper>
      );
    });
  };

  private renderNotification = (position: 'top' | 'bottom'): ReactNode => {
    const notificationPosition = this.props.notification?.position || 'top';

    if (!this.props.notification?.hasNotification || notificationPosition !== position) return null;

    return (
      <Box mb="20px">
        <Notification
          copy={this.props.notification.notificationProps.copy!}
          type={this.props.notification.notificationProps.type!}
          hasClose={false}
          textAlign="left"
          {...this.props.notification.notificationProps}
        />
      </Box>
    );
  };

  render(): JSX.Element {
    return (
      <Styles.FormBuilder>
        <Styles.Title
          exists={!!this.props.config.title}
          hasCopy={!!this.props.config.subCopy}
        >
          {this.props.config.title}
        </Styles.Title>
        <Styles.Subtitle
          exists={!!this.props.config.subtitle}
          hasCopy={!!this.props.config.subCopy}
        >
          {this.props.config.subtitle}
        </Styles.Subtitle>
        <Styles.SubCopy exists={!!this.props.config.subCopy}>
          {this.props.config.subCopy}
        </Styles.SubCopy>
        {this.renderNotification('top')}
        <Styles.FormWrapper>
          {this.buildSections()}
        </Styles.FormWrapper>
        {this.renderNotification('bottom')}
      </Styles.FormBuilder>
    );
  }
}

export default FormBuilder;

import styled from '@emotion/styled';
import { Flex, Text } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const ProgressWrapper = styled(Flex)`
  margin-top: 50px;
  justify-content: center;
  margin-bottom: 40px;
`;

export const ProgressItem = styled(Flex)`
  flex-direction: column;
  align-items: center;
  min-width: 170px;

  + ${(): any => ProgressItem} {
    ${(): any => ProgressIcon} {
      &:before {
        content: '';
        position: absolute;
        width: 155px;
        left: -155px;
        z-index: -1;
        top: 11px;
        height: 2px;
        background: ${colors.shade40};
      }
    }
  }
`;

export const ProgressCopy = styled(Text)<any>`
  ${textStyles.subhead};
  margin-top: 5px;
  max-width: 110px;
  text-align: center;
  color: ${({ isComplete, isActive }): string => isComplete || isActive ? colors.floomMidnightBlue : colors.shade40};
`;

export const ProgressIcon = styled(Flex)<any>`
  width: 26px;
  height: 26px;
  border-radius: 30px;
  border: 2px solid ${({ isComplete, isActive }): string => isComplete || isActive ? colors.floomMidnightBlue : colors.shade40};
  position: relative;
  background: ${({ isComplete }): string => isComplete ? colors.floomMidnightBlue : colors.floomCream};
  transition: all .2s ease-in-out;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .2s ease-in-out;
    opacity: ${({ isComplete }): number => isComplete ? 1 : 0};
    width: 12px;
    height: 12px;

    path {
      fill: ${colors.floomCream}
    }
  }
`;

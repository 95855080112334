import {
  FC,
  Fragment,
  ReactNode,
  useEffect
} from 'react';

import { inject, observer } from 'mobx-react';

import WholesaleFiltersStore from 'stores/wholesale/wholesale-filters-store/wholesale-filters-store';

import {
  CatalogFiltersProps
} from '../../catalog-filters-modal.types';
import {
  CATALOG_ITEM_FACET_CONFIG
} from '../../catalog-filters.constants';
import CatalogRefinementAppliedFilters from '../catalog-refinement-applied-filters/catalog-refinement-applied-filters';
import {
  CatalogRefinementFilters
} from '../catalog-refinement-filters/catalog-refinement-filters';

import {
  HeaderContainer,
  Content
} from './catalog-filters-body.styles';

const filterDuplicates = (items: any[]): any[] => items.filter((element: { id: any }, index: any, array: any[]) => array.findIndex((arrayItem: { id: any }) => (arrayItem.id === element.id)) === index);

export const CatalogFiltersBody: FC<CatalogFiltersProps & {
  renderHeader?: () => ReactNode;
  wholesaleFiltersStore?: WholesaleFiltersStore;
}> = inject((stores: FxStores) => ({
  wholesaleFiltersStore: stores.wholesaleFiltersStore
}))(observer((({
  refinementsState,
  selectedCategory,
  wholesaleFiltersStore,
  renderHeader
}) => {
  useEffect(() => {
    wholesaleFiltersStore!.fetchColours();
    wholesaleFiltersStore!.fetchSuppliers();
  }, []);

  return (
    <Fragment>
      <HeaderContainer>
        {renderHeader?.()}
      </HeaderContainer>
      <Content>
        <CatalogRefinementAppliedFilters
          transformItems={filterDuplicates}
        />
        <CatalogRefinementFilters
          filters={CATALOG_ITEM_FACET_CONFIG}
          selectedCategory={selectedCategory}
          refinementsState={refinementsState}
        />
      </Content>
    </Fragment>
  );
})));

import { MerchantOrderByInput } from 'generated-types.d';

import { TableLayoutHeadingConfigProps } from 'components/entity-list/table-layout-entity.types';

interface MerchantListSortOption {
  title: string;
  key: MerchantOrderByInput;
}

interface MerchantsListConfigProps extends TableLayoutHeadingConfigProps {
  id: string;
}

export const LIST_HEADING_CONFIG: MerchantsListConfigProps[] = [
  {
    heading: 'Active',
    id: 'active',
    fixedWidth: '120px'
  },
  {
    heading: 'Florist',
    id: 'florist',
    flexGrow: '1',
    flexBasis: '150px'
  },
  {
    heading: 'Merchant ID',
    id: 'merchantId',
    flexGrow: '1',
    flexBasis: '150px'
  },
  {
    heading: 'Accepting SD orders',
    id: 'accepting-sd-orders',
    flexGrow: '1',
    flexBasis: '150px'
  },
  {
    heading: 'Location',
    id: 'location',
    flexGrow: '1',
    flexBasis: '75px'
  },
  {
    heading: 'Plan',
    id: 'plan',
    flexGrow: '1',
    flexBasis: '100px'
  },
  {
    heading: 'Stage',
    id: 'stage',
    flexGrow: '1',
    flexBasis: '100px'
  },
  {
    heading: 'Subscription status',
    id: 'subscription-status',
    flexGrow: '1',
    flexBasis: '100px'
  },
  {
    heading: 'First log in',
    id: 'first-log-in',
    flexGrow: '1',
    flexBasis: '100px'
  },
  {
    heading: 'Product count',
    id: 'product-count',
    flexGrow: '1',
    flexBasis: '100px'
  },
  {
    heading: 'Other',
    id: 'other',
    flexGrow: '1',
    flexBasis: '150px'
  }
];

export const LIST_SORT_OPTIONS: MerchantListSortOption[] = [
  {
    title: 'Title A-Z',
    key: MerchantOrderByInput.TitleAsc
  },
  {
    title: 'Title Z-A',
    key: MerchantOrderByInput.TitleDesc
  },
  {
    title: 'Active first',
    key: MerchantOrderByInput.ActiveDesc
  },
  {
    title: 'Inactive first',
    key: MerchantOrderByInput.ActiveAsc
  },
  {
    title: 'Newest',
    key: MerchantOrderByInput.CreatedAtDesc
  },
  {
    title: 'Oldest',
    key: MerchantOrderByInput.CreatedAtAsc
  }
];

export const EDITABLE_FIELDS = [
  'title',
  'tradingNo',
  'productCount',
  'createdBy.givenName',
  'createdBy.familyName',
  'createdBy.email',
  'createdBy.phone',
  'subscription.status',
  'address',
  'address.businessName',
  'address.recipientFullName',
  'address.address1',
  'address.city',
  'address.postalCode',
  'registeredAddress',
  'registeredAddress.businessName',
  'registeredAddress.recipientFullName',
  'registeredAddress.address1',
  'registeredAddress.city',
  'registeredAddress.postalCode'
];

export const NON_EDITABLE_FIELDS = [
  'title',
  'companyName',
  'createdBy',
  'currency',
  'plan',
  'subscription'
];

export const FIELD_PATH_MAP = {
  'title': 'missing merchant title',
  'tradingNo': 'a trading number',
  'productCount': 'a product created',
  'companyName': 'missing company name',
  'createdBy': 'missing user',
  'createdBy.givenName': 'a user first name',
  'createdBy.familyName': 'a user last name',
  'createdBy.email': 'an email address',
  'createdBy.phone': 'a phone number',
  'currency': 'a currency',
  'plan': 'missing plan',
  'subscription': 'missing subscription',
  'subscription.status': 'subscription is not active / card details not yet taken',
  'address': 'business address',
  'address.businessName': 'business address name',
  'address.recipientFullName': 'business address recipient name',
  'address.address1': 'business address line 1',
  'address.city': 'business address city',
  'address.postalCode': 'business address postcode / zip code',
  'registeredAddress': 'a company address',
  'registeredAddress.businessName': 'company address name',
  'registeredAddress.recipientFullName': 'company address recipient name',
  'registeredAddress.address1': 'company address line 1',
  'registeredAddress.city': 'company address city',
  'registeredAddress.postalCode': 'company address postcode / zip code'
};

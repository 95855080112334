import { parse, fix } from 'postcode';
import * as Yup from 'yup';

import ValidationService from '../validation.service';

const setupPostalCode = (): void => {
  Yup.addMethod(Yup.string, 'zipCode', function({ label, path }: Partial<Yup.TestMessageParams>): Yup.Schema<any> {
    return this.test('postal_code', label!, function(value: string): boolean | Yup.ValidationError {
      const zipRegexp = /^[0-9]{5}(?:-[0-9]{4})?$/;

      if (!zipRegexp.test(value)) {
        // @ts-ignore
        return this.createError({
          path: path,
          // @ts-ignore
          message: {
            path,
            label
          }
        });
      }

      return true;
    });
  });

  Yup.addMethod(Yup.string, 'postalCode', function({ label, path }: Partial<Yup.TestMessageParams>): Yup.Schema<any> {
    return this.test('postal_code', label!, function(value: string): boolean | Yup.ValidationError {
      const parsedPostcode = parse(value);

      if (!parsedPostcode.valid) {
        // @ts-ignore
        return this.createError({
          path: path,
          // @ts-ignore
          message: {
            path,
            label
          }
        });
      }

      return true;
    });
  });
};

setupPostalCode();

export const reqPostalCodeString = (key: string, isZip: boolean): Yup.Schema<any>  => {
  if (isZip) {
    return Yup
      .string()
      .nullable(false)
      .min(1)
      .trim()
      .transform(value => {
        return formatPostcode(value, isZip);
      })
      .required(params => ValidationService.generateYupMessageSchema(params, 'Zip code is required'))
      // @ts-ignore
      .zipCode(ValidationService.generateYupMessageSchema({ path: key }, 'Please format your zip code correctly'));
  }

  return Yup
    .string()
    .nullable(false)
    .min(1)
    .trim()
    .transform(value => {
      return formatPostcode(value, isZip);
    })
    .required(params => ValidationService.generateYupMessageSchema(params, 'Postcode is required'))
    // @ts-ignore
    .postalCode(ValidationService.generateYupMessageSchema({ path: key }, 'Please format your postcode correctly'));
};

const formatPostcode = (value: string, isZip: boolean): string  => {
  if (isZip) {
    return value.trim().replace(' ', '-');
  }

  const postcode = parse(fix(value)).postcode;

  if (postcode) return postcode;

  return value;
};

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Flex, Box } from 'rebass';

import { CurrencyService } from 'lib';

import { colors, textStyles } from 'utils/rebass-theme';

import OrderHelpers from 'features/orders/helpers/order-helpers';
import { TRANSPORT_CONFIG } from 'features/orders/pages/orders-courier/components/deliveryslot/deliveryslot.config';

import Icon from 'components/icon';
import StyledLink from 'components/styled-link/styled-link';

import * as Styles from './item-header.styles';
import * as Types from './item-header.types';

class CourierBookingItemHeader extends Component<Types.CourierBookingItemHeaderProps> {
  getFlexDirection = (): string => {
    switch (this.props.layout) {
      case 'grid':
        return 'row';
    }
  };

  renderPaymentInfo = (): string | null => {
    if (!this.props.data.payment?.amount || !this.props.data.payment?.currency) return null;

    return CurrencyService.formatPrice(this.props.data.payment.amount, this.props.data.payment.currency);
  };

  render(): ReactNode {
    const vehicleType = (this.props.data.courierReferenceVehicleType && TRANSPORT_CONFIG[this.props.data.courierReferenceVehicleType]) ? TRANSPORT_CONFIG[this.props.data.courierReferenceVehicleType] : TRANSPORT_CONFIG.DEFAULT;

    return (
      <Styles.Heading
        layout={this.props.layout}
        status={this.props.status}
      >
        <Flex
          flexDirection={this.getFlexDirection()}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Flex>

            <Styles.StatusIcon
              status={this.props.status}
              layout={this.props.layout}
              alignItems="center"
            >
              <Icon
                iconName={vehicleType.icon}
                styles={css`width: 25px; height: 25px;`}
              />
            </Styles.StatusIcon>
            <Box>
              <Styles.Label status={this.props.status}>
                {this.props.data.courierReferenceProvider} ({vehicleType.name})
              </Styles.Label>
              <StyledLink color={colors[this.props.status.color]}>
                {OrderHelpers.shortenOrderNumber(this.props.data.courierReferenceId).toUpperCase()}
              </StyledLink>
            </Box>
          </Flex>
          <Box>
            <span css={textStyles.subhead}>
              {this.renderPaymentInfo()}
            </span>
          </Box>
        </Flex>
      </Styles.Heading>
    );
  }
}

export default CourierBookingItemHeader;

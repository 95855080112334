import {
  ApolloQueryResult
} from '@apollo/client';

import {
  Order,
  OrderWhereInput,
  OrderConnection,
  OrderWhereUniqueInput,
  OrderUpdateInput
} from 'generated-types.d';

import {
  UPDATE_ORDER
} from '../../graphql/mutation/order';
import {
  ORDERS_QUERY,
  ORDER_COUNT_QUERY
} from '../../graphql/queries/order';
import {
  GraphQL
} from '../client/client.service';

export default class OrderService {
  public static fetchOrders = async (where: OrderWhereInput): Promise<Order[] | null> => {
    try {
      const result: ApolloQueryResult<{ orders: Order[] | null }> = await GraphQL.query(ORDERS_QUERY, { where });

      return result.data.orders;
    } catch (error) {
      throw error;
    }
  };

  public static fetchOrderCount = async (inputWhere: OrderWhereInput = {}): Promise<number> => {
    const where: OrderWhereInput = {
      ...inputWhere,
      deletedAt: null,
      complete: true
    };

    const result: ApolloQueryResult<{ ordersConnection: OrderConnection }> = await GraphQL.query(ORDER_COUNT_QUERY, { where });

    return result.data.ordersConnection.aggregate.count;
  };

  public static updateOrder = async (
    where: OrderWhereUniqueInput,
    data: OrderUpdateInput
  ): Promise<Order[] | null> => {
    try {
      const result: ApolloQueryResult<{ updateOrder: Order[] | null }> = await GraphQL.query(UPDATE_ORDER, { where, data });

      return result.data.updateOrder;
    } catch (error) {
      throw error;
    }
  };
}

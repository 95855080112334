import { gql } from '@apollo/client';

export const CREATE_MERCHANT_SUPPLIER_INTEGRATION = gql`
  mutation CreateMerchantSupplierIntegration(
    $data: MerchantSupplierIntegrationCreateInput!
  ) {
    createMerchantSupplierIntegration(
      data: $data
    ) {
        id
        active
        supplier {
          id
          name
        }
        authentication {
          id
          type
          record
        }
      }
    }
`;

export const UPDATE_MERCHANT_SUPPLIER_INTEGRATION = gql`
  mutation UpdateMerchantSupplierIntegration(
    $data: MerchantSupplierIntegrationUpdateInput!
    $where: MerchantSupplierIntegrationWhereUniqueInput!
  ) {
    updateMerchantSupplierIntegration(
      data: $data
      where: $where
    ) {
        id
        active
        supplier {
          id
          name
        }
        authentication {
          id
          type
          record
        }
      }
    }
`;

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import {
  DeliveryConfig,
  Channel,
  ProductType
} from 'generated-types.d';

import ProductPauseField from 'features/products/components/create-edit-product-layout/product-pause-field';

import DeliveryConfigCard from 'components/delivery-config-card';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import SectionHeading from 'components/section-heading';

import * as Styles from './product-delivery-tab.styles';
import * as Types from './product-delivery-tab.types';

class ProductDeliveryTab extends Component<Types.ProductDeliveryTabProps> {
  displayNotification: { [key in Channel]: boolean } = {
    [Channel.Floom]: false,
    [Channel.Website]: false,
    [Channel.Pos]: false
  };

  private splitConfigsByChannel = (): Types.ConfigsByChannel => {
    const configsByChannel: Types.ConfigsByChannel = {
      [Channel.Floom]: [],
      [Channel.Website]: [],
      [Channel.Pos]: []
    };

    for (const config of this.props.productEditStore!.deliveryConfigs) {
      configsByChannel[config.channel].push(config);
    }

    return configsByChannel;
  };

  private isItemDisabled = (channelConfigs: DeliveryConfig[], item: DeliveryConfig): boolean => {
    return channelConfigs.some(selectedConfig => {
      return selectedConfig.id !== item.id
        && this.props.productEditStore!.selectedDeliveryItems.includes(selectedConfig.id)
        && selectedConfig.deliveryCoverage?.some(coverage => {
          return item.deliveryCoverage?.some(currCoverage => currCoverage.id === coverage.id);
        });
    });
  };

  private shouldHideDeliveryConfig = (config: DeliveryConfig): boolean => {
    const selectedTypeId = this.props.productEditStore!.selectedProductTypeId;
    const selectedType: ProductType | undefined = this.props.productEditStore!.types.find(type => type.id === selectedTypeId);
    const isDefaultProductType = ['flowers', 'plants', 'wreaths-and-trees'].includes(selectedType?.slug || '');
    const isNonDefaultDeliveryPrice = config.deliveryPrice?.isDefault === false;

    return config.channel === Channel.Floom && (!selectedType || (isDefaultProductType && isNonDefaultDeliveryPrice));
  };

  private renderConfigList = (configs: DeliveryConfig[], channel: Channel): ReactNode => {
    let shouldDisplay = false;

    const configsToDisplay = configs.map(currConfig => {
      if (this.shouldHideDeliveryConfig(currConfig)) return null;

      const isDisabled = this.isItemDisabled(configs, currConfig);
      const isSelected = this.props.productEditStore!.selectedDeliveryItems.includes(currConfig.id);

      if (isDisabled) {
        shouldDisplay = true;
      }

      return (
        <Flex
          key={currConfig.id}
          p="0 10px"
          mb="20px"
          width="33.33%"
        >
          <DeliveryConfigCard
            deliveryConfig={currConfig}
            isSelectable={true}
            isSelected={isSelected}
            isDisabled={isDisabled && !isSelected}
            onClick={(): void => this.props.productEditStore!.updateSelectedDeliveryItems(currConfig.id)}
          />
        </Flex>
      );
    });

    if (this.displayNotification[channel] !== shouldDisplay) {
      this.forceUpdate();
    }

    this.displayNotification[channel] = shouldDisplay;

    return configsToDisplay;
  };

  private renderConfigsByChannel = (): ReactNode => {
    const channelConfigs = this.splitConfigsByChannel();

    return Object.keys(channelConfigs).map(channelKey => {
      if (!channelConfigs[channelKey].length) return null;

      return (
        <Box
          key={channelKey}
          mt="30px"
        >
          <SectionHeading
            title={channelKey}
            count={channelConfigs[channelKey].length}
            hasSpacing={false}
          />
          { this.displayNotification[channelKey] && (
            <Box mt="30px">
              <Notification
                copy="Some options have been disabled because only one delivery option per coverage area can be attached to&nbsp;a&nbsp;product"
                type={NotificationType.Info}
                textAlign="left"
                hasIcon={false}
                hasClose={false}
              />
            </Box>
          )}
          <Flex
            flexWrap="wrap"
            margin="30px -10px 0 -10px"
          >
            {this.renderConfigList(channelConfigs[channelKey], channelKey as Channel)}
          </Flex>
        </Box>
      );
    });
  };

  private renderError = (): ReactNode => {
    if (this.props.productEditStore!.deliveryItemValidationPass() || !this.props.productEditStore!.deliveryConfigs.length) return null;

    return (
      <Styles.CheckboxErrorMessage>
        <Notification
          copy="At least one delivery option must be selected"
          type={NotificationType.Progress}
          hasIcon={false}
          hasClose={false}
        />
      </Styles.CheckboxErrorMessage>
    );
  };

  private renderProductPause = (): ReactNode => {
    return (
      <Box mt="30px">
        <ProductPauseField />
      </Box>
    );
  };

  render(): React.ReactNode {
    return (
      <Styles.Content>
        {this.renderError()}
        {this.renderConfigsByChannel()}
        {this.renderProductPause()}
      </Styles.Content>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  merchantStore: stores.merchantStore
}))(observer(ProductDeliveryTab));

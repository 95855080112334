import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react';

import { css } from '@emotion/react';
import { WholesaleOrderLineItem } from 'generated-types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import DraftWholesaleOrderStore from 'stores/wholesale/draft-wholesale-order-store/draft-wholesale-order-store';

import { Input } from '../manage-order.styles';

export interface ManageOrderItemItemProps {
  item: WholesaleOrderLineItem;
  draftWholesaleOrderStore?: DraftWholesaleOrderStore;
}

const ManageOrderItemTitleView: FC<ManageOrderItemItemProps> = ({
  item,
  draftWholesaleOrderStore
}) => {
  const [controlledTitle, setTitle] = useState('');
  const titleRef = useRef<HTMLInputElement>(null);
  const isDisabled = !!draftWholesaleOrderStore?.orderItemIdsBeingUpdated?.has?.(item.id);

  useEffect(() => {
    const title = item.listItem?.sku || '';

    if (title) {
      setTitle(title);
    }
  }, [item.listItem?.sku]);

  const handleReturn = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      updateTitle();
      titleRef?.current?.blur?.();
    }
  };

  const handleTitleBlur = (): void => {
    if (`${item.listItem?.sku}` !== controlledTitle) {
      updateTitle();
    }
  };

  const updateTitle = async (): Promise<void> => {
    const parseTitle = ((): string => {
      const title = controlledTitle;

      if (title === '') {
        setTitle('');

        return '';
      }

      return title;
    })();

    await draftWholesaleOrderStore?.updateOrderItem({
      orderId: draftWholesaleOrderStore!.selectedOrder!.id!,
      orderItemId: item.id,
      data: {
        title: parseTitle
      }
    });
  };

  const handleUpdateTitle = (e: ChangeEvent<HTMLInputElement>): void => {
    setTitle(e.currentTarget.value);
  };

  return (
    <Box ml="auto">
      <Input
        ref={titleRef}
        value={controlledTitle}
        placeholder="Item title"
        type="string"
        min={0}
        disabled={isDisabled}
        onChange={handleUpdateTitle}
        onBlur={handleTitleBlur}
        onKeyDown={handleReturn}
        css={css`
          width: calc(100% - 20px);
          max-width: 200px;
        `}
      />
    </Box>
  );
};

export const ManageOrderItemTitle = inject((stores: FxStores): InjectedFxStores => ({
  draftWholesaleOrderStore: stores.draftWholesaleOrderStore
}))(observer(ManageOrderItemTitleView));

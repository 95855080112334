import { FC } from 'react';

import { TEST_IDS } from 'utils/test/data-test-ids';

import Icon from 'components/icon';

import { CloseButtonWrapper } from './close-button.styles';

type CloseButtonProps = {
  onClose: () => void;
  buttonText?: string;
}

export const CloseButton: FC<CloseButtonProps> = ({
  onClose,
  buttonText
}) => {
  return (
    <CloseButtonWrapper
      onClick={onClose}
      data-testid={TEST_IDS.ConversationsCloseButton.trigger}
    >
      <Icon iconName="cross-circle" /> {buttonText && (<span>Close</span>)}
    </CloseButtonWrapper>
  );
};

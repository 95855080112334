import Typography, { TypographyOptions } from 'typography';

const options: TypographyOptions = {
  baseFontSize: '18px',
  baseLineHeight: 1.5,
  bodyColor: '#00124f',
  bodyFontFamily: [
    'FF Mark Pro',
    'Helvetica',
    'Arial',
    'sans-serif'
  ],
  boldWeight: 600,
  headerFontFamily: [
    'Buenos Aires',
    'Helvetica',
    'Arial',
    'sans-serif'
  ],
  includeNormalize: false,
  overrideStyles: () => ({
    'form, fieldSet, img, ul, li': {
      margin: 0
    },
    'fieldset': {
      border: 'none'
    },
    'a': {
      textDecoration: 'none'
    },
    'td, th': {
      borderBottom: 'none',
      padding: '0'
    },
    'th:first-child,td:first-child': null,
    'th:last-child,td:last-child': null
  })
};

const typography = new Typography(options);

export default typography;

export const rhythm = typography.rhythm;

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { InviteToFloomXResponseStatus } from 'generated-types.d';

import {
  TimeService
} from 'lib';

import SettingsService from 'features/settings/services';

import Button from 'components/button';
import { ButtonProps } from 'components/button/button.types';

import { MerchantDetailContext } from '../../merchant-detail';

import * as Styles from './merchant-invite.styles';
import * as Types from './merchant-invite.types';

class MerchantInvite extends Component<Types.MerchantInviteProps, Types.MerchantInviteState> {
  private MerchantsSettingsService = SettingsService.MerchantsSettingsService;

  state: Types.MerchantInviteState = {
    isLoading: false,
    invitationResponse: null
  };

  private onInvite = async (): Promise<any> => {
    if (this.props.merchantDetailStore!.selectedMerchant?.id && !this.state.isLoading) {
      this.setState({ isLoading: true });

      try {
        const inviteMerchant = await this.MerchantsSettingsService.inviteMerchantToFloomX(this.props.merchantDetailStore!.selectedMerchant.id);

        this.setState({
          isLoading: false,
          invitationResponse: inviteMerchant
        });
      } catch (error) {
        this.setState({
          isLoading: false,
          invitationResponse: {
            status: InviteToFloomXResponseStatus.FailedToSend
          }
        });
      }
    }
  };

  private renderButtonProps = (): Pick<ButtonProps, 'appearance' | 'copy'> => {
    switch (true) {
      case this.state.invitationResponse?.status === InviteToFloomXResponseStatus.Sent:
        return {
          appearance: 'success',
          copy: 'Invite sent'
        };

      case this.state.invitationResponse?.status === InviteToFloomXResponseStatus.FailedToSend:
        return {
          appearance: 'danger',
          copy: 'Invite failed to send'
        };

      default:
        return {
          appearance: 'primary',
          copy: 'Send invite email to florist'
        };
    }
  };

  private renderButtonCopy = (canInvite: boolean): ReactNode => {
    const merchant = this.props.merchantDetailStore?.selectedMerchant;

    switch (true) {
      case canInvite && !merchant?.invitedAt:
        return (
          <Styles.InviteDate>
            Not yet invited
          </Styles.InviteDate>
        );

      case !canInvite:
        return (
          <Styles.InviteRequiredInfo>
            {`There's still a few required pieces of information before you can invite the merchant. Please check that the Company details, Business address and other bits like name, phone, email have been added (you can do all of this right here 😎)`}
          </Styles.InviteRequiredInfo>
        );

      case !!merchant?.invitedAt?.length:
        return (
          <Styles.InviteDate>
            Last sent: {TimeService.dateMonthYear(merchant?.invitedAt)}
          </Styles.InviteDate>
        );

      default:
        return null;
    }
  };

  private shouldHide = (): boolean => {
    return !!this.props.merchantDetailStore!.selectedMerchant?.acceptedTermsAt;
  };

  render(): ReactNode {
    if (this.shouldHide()) return null;

    return (
      <Styles.MerchantInvite>
        <MerchantDetailContext.Consumer>
          {(context): ReactNode => (
            <>
              <button
                onClick={this.onInvite}
                disabled={this.state.isLoading || !context.canInvite}
              >
                <Button
                  {...this.renderButtonProps()}
                  isDisabled={!context.canInvite}
                  isLoading={this.state.isLoading}
                  size="normal"
                />
              </button>
              {this.renderButtonCopy(context.canInvite)}
            </>
          )}
        </MerchantDetailContext.Consumer>
      </Styles.MerchantInvite>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantSettingsStore: stores.merchantSettingsStore,
  merchantDetailStore: stores.merchantDetailStore
}))(observer(MerchantInvite));

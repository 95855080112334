import { Component, ReactNode } from 'react';

import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

import Button from 'components/button';
import GenericModal from 'components/generic-modal';

import * as Styles from './confirmation-modal.styles';
import * as Types from './confirmation-modal.types';

export default class ConfirmationModal extends Component<Types.ConfirmationModalModalProps> {
  state = {
    isLoading: false,
    isLoadingExtra: false,
    hasError: false
  };

  static defaultProps: Partial<Types.ConfirmationModalModalProps> = {
    data: {
      title: 'Are you sure?',
      copy: 'All progress will be lost',
      confirmButtonCopy: 'Yes',
      cancelButtonCopy: 'No',
      errorCopy: 'We are having trouble completing your request',
      confirmButtonAction: (): any => null,
      onCancel: async (): Promise<any> => null
    }
  };

  private onConfirm = async (e: any): Promise<void> => {
    if (e.detail === 1) {
      this.setState({
        isLoading: true,
        hasError: false
      });

      try {
        await this.props.data.confirmButtonAction?.();
        this.props.data?.onClose?.();
        this.props.closeModal();
      } catch (error) {
        this.setState({
          isLoading: false,
          hasError: true
        });
      }

      this.setState({ isLoading: false });
    }
  };

  private onExtraActionClick = async (e: any): Promise<void> => {
    if (e.detail === 1) {
      this.setState({
        isLoadingExtra: true,
        hasError: false
      });

      try {
        if (!!this.props.data.onCancel) {
          await this.props.data.onCancel();
        }
        this.props.closeModal();
      } catch (error) {
        this.setState({
          isLoadingExtra: false,
          hasError: true
        });
      }
    }
  };

  private renderExtraAction = (): ReactNode => {
    if (!this.props.data?.cancelButtonCopy) return null;

    return (
      <Box onClick={this.onExtraActionClick}>
        <Button
          size="normal"
          appearance="secondary"
          copy={this.props.data.cancelButtonCopy}
          isLoading={this.state.isLoadingExtra}
          isDisabled={this.state.isLoading}
        />
      </Box>
    );
  };

  render(): ReactNode {
    return (
      <GenericModal
        width={430}
        title={this.props.data.title}
        innerComponent={(
          <Styles.ConfirmationBox>
            <p>{this.props.data.copy}</p>
            {this.state.hasError && (
              <Box
                color={colors.errorText}
                mt="20px"
              >
                {this.props.data.errorCopy || 'Something went wrong completing your request'}
              </Box>
            )}
          </Styles.ConfirmationBox>
        )}
        confirmButtonAction={this.onConfirm}
        confirmButtonText={this.props.data.confirmButtonCopy}
        modalOpen={this.props.isOpen}
        closeModal={this.props.closeModal}
        isButtonLoading={this.state.isLoading}
        extraAction={this.renderExtraAction()}
      />
    );
  }
}

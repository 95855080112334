import store from 'stores';

import {
  Merchant,
  RijnsDeliveryType
} from 'generated-types.d';

import { GraphQL } from 'lib';

import WholesalePaymentStore from 'stores/wholesale/wholesale-payment-store/wholesale-payment-store';

import { MERCHANT_QUERY } from 'features/login-signup/services/teams-apollo.queries';

export default class MerchantService {
  merchantStore = store.merchantStore;

  userStore = store.userStore;

  wholesalePaymentStore = store.wholesalePaymentStore as WholesalePaymentStore;

  public async init(): Promise<void> {
    await this.initializeActingMerchantDetails();
  }

  public async initializeActingMerchantDetails(): Promise<void> {
    const merchant = await this.fetchMerchant(this.userStore.merchantId);
    this.merchantStore.setMerchant(merchant);
    this.wholesalePaymentStore.setMerchantDeliveryType(merchant?.wholesaleDeliveryConfigs?.[0]?.rijnsDeliveryType || RijnsDeliveryType.Standard);
  }

  private fetchMerchant = async (merchantId: string): Promise<Merchant> => {
    return GraphQL.query(MERCHANT_QUERY, { id: merchantId }, 'no-cache')
      .then(result => {
        this.merchantStore.disableIsLoadingMerchant();

        return result.data.merchant;
      })
      .catch(error => {
        this.merchantStore.disableIsLoadingMerchant();

        return error;
      });
  };
}

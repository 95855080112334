import { Fragment } from 'react';

import 'styles/tailwind.css';
import store from 'stores';

import { MerchantService } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import ToasterStore from 'stores/toaster-store/toaster-store';
import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { PushNotifications } from './push-notifications';

const merchantStore = store.merchantStore as MerchantStore;
const toasterStore = store.toasterStore as ToasterStore;

export const NotificationSettings = (): JSX.Element => {
  const handleOrderNotificationChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    try {
      await MerchantService.updateMerchant({ id: merchantStore?.merchant?.id }, {
        newOrderNotifications: !!e.target.checked
      });

      toasterStore?.popToast(
        `Notification settings have been changed`,
        ToastType.Success
      );
    } catch (error) {
      toasterStore?.popToast(
        `We're having trouble updating your notification settings. Please try again later.`,
        ToastType.Error
      );

      return Promise.reject(error);
    }
  };

  const handleStatusCheckNotificationChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    try {
      await MerchantService.updateMerchant({ id: merchantStore?.merchant?.id }, {
        orderStatusCheckNotifications: !!e.target.checked
      });

      toasterStore?.popToast(
        `Notification settings have been changed`,
        ToastType.Success
      );
    } catch (error) {
      toasterStore?.popToast(
        `We're having trouble updating your notification settings. Please try again later.`,
        ToastType.Error
      );

      return Promise.reject(error);
    }
  };

  const pushNotifications = {
    isActive: false
  };

  return (
    <Fragment>
      <div className="max-w-5xl mx-auto px-6 md:px-8 py-8">
        <div className="m-auto max-w-3xl">
          <div className="mb-4 flex flex-col md:flex-row md:items-center justify-between">
            <h1 className="text-lg">Notification settings <span className="ml-2">📣</span></h1>
            <p className="text-xs md:text-sm">Manage your mobile and desktop&nbsp;notifications</p>
          </div>
          <ul
            role="list"
            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
          >
            <div className="px-4 py-6 md:px-7 md:py-8 h-full">
              {pushNotifications.isActive && <PushNotifications />}
              <h3 className="text-base md:text-lg mb-3">Notification preferences</h3>
              <label
                htmlFor="general"
                className="text-sm cursor-pointer flex w-full gap-2 md:gap-4 mb-4"
              >
                <input
                  className="mt-1 cursor-pointer disabled:opacity-50 text-slate-600"
                  defaultChecked={true}
                  disabled={true}
                  type="checkbox"
                  name="general"
                  id="general"
                />
                <div>
                  <span className="font-bold block mb-1">Announcements (always on)</span>
                  <span className="text-xs md:text-sm">We&apos;ll notify you about new features and anything urgent</span>
                </div>
              </label>
              <label
                htmlFor="new-orders"
                className="text-sm cursor-pointer flex w-full gap-2 md:gap-4 mb-4"
              >
                <input
                  className="mt-1 cursor-pointer"
                  defaultChecked={!!merchantStore?.merchant?.newOrderNotifications}
                  onChange={handleOrderNotificationChange}
                  type="checkbox"
                  name="new-orders"
                  id="new-orders"
                />
                <div>
                  <span className="font-bold block mb-1">New orders</span>
                  <span className="text-xs md:text-sm">Notify me when I receive a new order</span>
                </div>
              </label>
              <label
                htmlFor="order-status-checks"
                className="text-sm cursor-pointer flex w-full gap-2 md:gap-4"
              >
                <input
                  className="mt-1 cursor-pointer"
                  defaultChecked={!!merchantStore?.merchant?.orderStatusCheckNotifications}
                  onChange={handleStatusCheckNotificationChange}
                  type="checkbox"
                  name="order-status-checks"
                  id="order-status-checks"
                />
                <div>
                  <span className="font-bold block mb-1">Order status checks</span>
                  <span className="text-xs md:text-sm">Notify me about status checks that need my attention</span>
                </div>
              </label>
            </div>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { colors } from 'utils/rebass-theme';

const checkboxBackground = ({ isSelected, isDisabled }: any): string => {
  if (isSelected && !isDisabled) return colors.floomMidnightBlue;

  if (isDisabled) return colors.background;

  if (!isSelected) return colors.uiFills;

  return colors.floomMidnightBlue;
};

export const DropdownCheckbox = styled.span<any>`
  position: relative;
  display: inline-block;
  width: 19px;
  height: 19px;
  border-radius: 2px;
  border: 2px solid ${({ isDisabled }): any => isDisabled ? colors.middleGrey
    : (props: any): any => props.error ? colors.errorText : colors.floomMidnightBlue};
  background: ${(props: any): string => checkboxBackground(props)};
  line-height: 0;
  margin-right: 11px;
  transition: background .2s ease-in-out;
`;

export const TickIconStyles: any = css`
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
`;

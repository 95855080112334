import gql from 'graphql-tag';

export const ORDER_FRAGMENT_ADDRESS = gql`
  fragment OrderAddress on Address {
    honorific
    businessName
    recipientFullName
    address1
    address2
    city
    postalCode
    phone
    country
    region
  }
`;

export const ORDER_FRAGMENT_FULL = gql`
  fragment OrderFragmentFull on Order {
    id
    orderNo
    orderedAt
    deliverAt
    complete
    new
    canBookCourier
    courierBooking {
      id
      courierReferenceId
      deliveryStatus
      pickupCode
      deliveryDate
      pickupDate
      lastDeliveryStatusReason
    }
    tax {
      id
      amount
      name
      inclusive
    }
    orderItems {
      id
      productSnapshot
      addOnsSnapshot
      quantity
      price
      giftMessages
    }
    currency
    orderDeliveryConfig {
      id
      price
      sameDay
      deliveryConfigSnapshot
    }
    payment {
      stripeFee
    }
    shippingAddress {
      ...OrderAddress
    }
    billingAddress {
      ...OrderAddress
    }
    orderNotes {
      id
      content
      read
      floom
      updatedAt
    }
    customerUser {
      id
      email
      givenName
      familyName
    }
    orderDiscount {
      id
      total
      discountSnapshot
    }
    deliveryInstructions
    deliverySafePlace
    channel
    merchant {
      title
      id
      timezone
      currency
      courierActive
      sameDayOrderPauseStatus
    }
    status {
      slug
      title
    }
    nationwide
    commercial
  }
  ${ORDER_FRAGMENT_ADDRESS}
`;

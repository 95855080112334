import React, { Component, ReactNode } from 'react';

import { Flex } from 'rebass';

import { ActivityToggle } from 'utils/css-mixins/css-mixins';
import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './content-wrapper.styles';
import * as Types from './content-wrapper.types';

class MetaCardContentWrapper extends Component<Types.MetaCardWrapperProps> {
  static defaultProps = {
    hasActivityToggle: false,
    iconColor: null
  };

  render(): ReactNode {
    return (
      <Styles.Wrapper alignItems="center">
        <Flex
          mr="15px"
          width="25px"
          css={Styles.MetaIcon}
        >
          { this.props.hasActivityToggle ?
            <ActivityToggle isActive={this.props.isActivityToggleActive || false} /> :
            (
              <Icon
                iconName={this.props.iconName}
                styles={Styles.ItemIcon}
                pathFill={colors[this.props.iconColor] || colors.middleGrey}
              />
            )
          }
        </Flex>
        {this.props.children}
      </Styles.Wrapper>
    );
  }
}

export default MetaCardContentWrapper;

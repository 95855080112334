import { FC } from 'react';

import { Box } from 'rebass';

import RadioIcon from 'components/radio-icon';

import { RadioItemViewProps } from '../radio-item/radio-item.types';

import { StyledDefaultItem, RadioLabel }from './default-item.styles';

export const DefaultItem: FC<RadioItemViewProps> = ({
  orientation,
  isSelected,
  isDisabled,
  title
}) => (
  <StyledDefaultItem orientation={orientation}>
    <Box pt="1px">
      <RadioIcon
        isSelected={isSelected}
        isDisabled={isDisabled}
      />
    </Box>
    <RadioLabel isDisabled={isDisabled}>
      {title}
    </RadioLabel>
  </StyledDefaultItem>
);

import { ApolloQueryResult } from '@apollo/client';
import moment from 'moment';

import {
  Merchant,
  PlanFeature,
  PlanType,
  MerchantWhereUniqueInput,
  MerchantUpdateInput
} from 'generated-types.d';
import {
  ACTIVE_MERCHANT_SUBSCRIPTION_STATUSES,
  INACTIVE_MERCHANT_STAGES,
  UNPAID_MERCHANT_SUBSCRIPTION_STATUSES
} from 'global.constants';

import {
  GraphQL,
  MerchantMutations
} from 'lib';

export default class MerchantService {
  public static isStripeConnected(merchant: Merchant): boolean {
    return !!merchant?.stripeConnectAccountId;
  }

  public static hasProvidedFloomBankDetails = (merchant: Merchant): boolean => {
    return !!merchant?.hasProvidedBankDetails;
  };

  public static hasSubscriptionId = (merchant: Merchant | null): boolean => {
    return !!merchant?.subscription?.stripeSubscriptionId;
  };

  public static isActiveMerchantStage = (merchant: Merchant | null): boolean => {
    return !INACTIVE_MERCHANT_STAGES.includes(merchant?.stage!);
  };

  public static hasActiveSubscription = (merchant: Merchant | null): boolean => {
    return !!merchant?.subscription?.status
      && !!ACTIVE_MERCHANT_SUBSCRIPTION_STATUSES.includes(merchant?.subscription?.status);
  };

  public static hasUnpaidSubscription = (merchant: Merchant | null): boolean => {
    return !!merchant?.subscription?.status
      && !!UNPAID_MERCHANT_SUBSCRIPTION_STATUSES.includes(merchant?.subscription?.status);
  };

  public static isSubscribed = (merchant: Merchant): boolean => {
    return MerchantService.hasSubscriptionId(merchant)
      && MerchantService.isActiveMerchantStage(merchant);
  };

  public static hasPlanType = (planType: PlanType | PlanType[], merchant: Merchant | null): boolean => {
    if (Array.isArray(planType)) {
      return !!merchant?.plan && planType.includes(merchant?.plan?.type);
    }

    return merchant?.plan?.type === planType;
  };

  public static hasPlanFeature = (feature: PlanFeature, merchant: Merchant | null): boolean => {
    return !!merchant?.plan?.features?.some(planFeature => planFeature === feature);
  };

  public static hasPlan = (merchant: Merchant): boolean => {
    return !!merchant?.plan?.features?.length;
  };

  public static isOnTrial = (merchant: Merchant): boolean => {
    return moment.utc(merchant?.subscription?.trialExpiresAt).isAfter(moment.utc());
  };

  public static hasOrderPauseSupport = (merchant: Merchant | null): boolean => {
    const isConnectedToFloom = !!merchant?.flc_id;
    const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, merchant);

    return isConnectedToFloom && !isCollectionSeller;
  };

  public static getTrialRemainingInDays = (merchant: Merchant): number => {
    if (!merchant?.subscription?.trialExpiresAt) return 0;

    const trialExpiresAt = merchant?.subscription?.trialExpiresAt;
    const expiryDay = moment(trialExpiresAt).utc();
    const currentDay = moment({ hours: 0 }).utc();
    const trialRemaining = expiryDay.diff(currentDay, 'days');

    return trialRemaining >= 0 ? trialRemaining : 0;
  };

  public static updateMerchant = async (where: MerchantWhereUniqueInput, data: MerchantUpdateInput): Promise<Merchant> => {
    try {
      const result: ApolloQueryResult<{ updateMerchant: Merchant }> = await GraphQL
        .query(MerchantMutations.UPDATE_MERCHANT, { where, data });

      return result.data.updateMerchant;
    } catch (error) {
      throw new Error((error || '') as string);
    }
  };
}

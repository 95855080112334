import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, shadows } from 'utils/rebass-theme';

export const NoDeliveryConfigs = styled(Box)<any>`
  margin-top: 29px;
  padding: 20px;
  background: ${colors.uiFills};
  width: 100%;
  box-shadow: ${shadows.large};
  border-radius: 3px;
  font-size: 14px;
`;

import { action, observable, makeObservable } from 'mobx';

import {
  Order,
  OrderItem,
  Channel
} from 'generated-types.d';

import { OrderItemAddonSnapshot } from 'features/orders/orders.types';

import { OverviewStoreTypes } from './overview-store.types';

export default class OverviewStore implements OverviewStoreTypes {
  constructor() {
    makeObservable(this, {
      setTodaysOrderRevenue: action,
      setLast30DaysOrderRevenue: action,
      completedOrderCount: observable,
      completedOrdersCost: observable,
      last30DaysOrderCount: observable,
      last30DaysOrdersCost: observable
    });
  }

  completedOrderCount: number = 0;
  completedOrdersCost: number = 0;

  last30DaysOrderCount: number = 0;
  last30DaysOrdersCost: number = 0;

  public setTodaysOrderRevenue = (data: Order[]): any => {
    this.completedOrderCount = data.length;

    this.completedOrdersCost = data.reduce((acc: number, curr: Order) => {
      return acc + curr.orderItems?.reduce((accItems: any, item: OrderItem) => {
        return accItems + item.price;
      }, 0);
    }, 0);
  };

  public setLast30DaysOrderRevenue = (data: Order[]): any => {
    this.last30DaysOrderCount = data.length;

    const last30DaysOrdersCost = data.reduce((acc: number, curr: Order) => {
      const inclusiveTax = !!curr.tax && !curr.tax.inclusive ? curr.tax.amount : 0;
      const discountTotal = !!curr.orderDiscount ? curr.orderDiscount.total : 0;
      const delivery = !!curr.orderDeliveryConfig ? curr.orderDeliveryConfig.price : 0;
      const subTotal = curr.orderItems?.reduce((sum: number, orderItem: OrderItem) => {
        const orderItemPriceMultiplier = curr.channel === Channel.Floom ? orderItem.quantity : 1;
        let addonTotal = 0;

        if (!!orderItem?.addOnsSnapshot?.length && curr.channel === Channel.Website) {
          const addonSnapshots = orderItem.addOnsSnapshot as OrderItemAddonSnapshot[];

          addonTotal = addonSnapshots.reduce((addonSum: number, currSnapshot) => {
            return addonSum + currSnapshot.price;
          }, 0);
        }

        return sum + (orderItem.price * orderItemPriceMultiplier) + addonTotal;
      }, 0)  || 0;

      const totalPrice = subTotal + delivery + discountTotal + inclusiveTax;

      return acc + totalPrice;
    }, 0);
    this.last30DaysOrdersCost = (Math.round(last30DaysOrdersCost * 100)) / 100;
  };
}

import {
  ChangeEvent,
  createContext,
  Dispatch,
  FC,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';

import { CatalogItemHit } from 'global.types';
import { inject, observer } from 'mobx-react';
import {
  connectAutoComplete,
  Hit
} from 'react-instantsearch-core';
import OutsideClickHandler from 'react-outside-click-handler';
import { Flex } from 'rebass';
import * as Yup from 'yup';

import {
  CatalogItemType,
  ListItemType
} from 'generated-types.d';

import { ValidationService } from 'lib';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';
import { LoadingSpinner } from 'components/loading-spinner/loading-spinner';

import {
  InlineSearchSelections,
  SearchReducerType,
  SingleSearchReducer,
  SingleSearchReducerActions,
  SingleSearchReducerState,
  singleSearchResultReducer
} from '../catalog-inline-search.reducers';
import {
  InlineSearchResults
} from '../inline-search-results/inline-search-results';

import {
  ClearIconWrapper,
  Input,
  InputIconWrapper,
  InputLoader,
  InputWrapper,
  ResultsWrapper
} from './catalog-inline-search-body.styles';
import {
  CatalogInlineSearchProps,
  SupportedKeyboardKey,
  CatalogInlineSearchSelectItemArgs,
  PartialCatalogHit
} from './catalog-inline-search-body.types';
import {
  CatalogInlineSearchContext
} from './catalog-inline-search-field';

const validation = Yup.object().shape<PartialCatalogHit>({
  title: Yup
    .string()
    .required(),
  // @ts-ignore
  type: Yup
    .string()
    .required()
});

interface SearchResultContextProps {
  state: SingleSearchReducerState;
  dispatch: Dispatch<SingleSearchReducerActions>;
  onSelectItem: (data: CatalogInlineSearchSelectItemArgs) => Promise<void>;
}

export const SearchResultContext = createContext<SearchResultContextProps>({
  state: {},
  dispatch: () => null,
  onSelectItem: async () => {}
});

export const CatalogInlineSearchBody = connectAutoComplete(inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore
}))(observer<FC<CatalogInlineSearchProps>>(({
  currentRefinement,
  hits,
  refine,
  toasterStore
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [loadingItems, setLoadingItems] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputPosition, setInputPositon] = useState<number | null>(null);

  const [state, dispatch] = useReducer<SingleSearchReducer>(singleSearchResultReducer, {});

  const { onSelectItem } = useContext(CatalogInlineSearchContext);

  const contextValue = useMemo(() => {
    return {
      state,
      dispatch,
      onSelectItem
    };
  }, [state, dispatch]);

  useEffect(()=>{
    inputRef?.current?.focus?.();
  }, []);

  const cursorHit = (): CatalogItemHit | undefined => {
    return hits.find((hit, index) => index === cursor - 1);
  };

  const validateAddItem = async (item: PartialCatalogHit): Promise<void> => {
    try {
      await ValidationService.validateAll(validation, item);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const removeLoadingItem = (itemId?: string): void => {
    setLoadingItems(prevItems => prevItems.filter(loadingItem => loadingItem !== itemId));
  };

  const openDropdown = (): void => {
    const inputRect = inputRef?.current?.getBoundingClientRect?.();
    const position = inputRect ? inputRect.top + inputRect.height : null;

    setInputPositon(() => position);
    setIsOpen(() => true);
  };

  const closeDropdown = (): void => {
    setInputPositon(null);
    setIsOpen(false);
  };

  const handleInputRefocus = (): void => {
    inputRef?.current?.focus?.();
  };

  const handleAddItem = async (
    item: PartialCatalogHit,
    selections?: InlineSearchSelections
  ): Promise<void> => {
    if (item?.objectID && loadingItems.includes(item.objectID)) return;

    try {
      inputRef?.current?.focus?.();

      await validateAddItem({
        ...item,
        type: (item.type || ListItemType.Custom) as CatalogItemType
      });

      setIsLoading(true);

      if (!!item?.objectID) {
        setLoadingItems(prevItems => [...prevItems, item.objectID!]);
      }

      await onSelectItem({ item, selections });

      resetCursor();
      closeDropdown();
      refine('');
      removeLoadingItem(item.objectID);
      setIsLoading(false);

      if (!!item?.objectID) {
        dispatch({
          type: SearchReducerType.DeleteItem,
          payload: {
            itemId: item.objectID
          }
        });
      }
    } catch {
      toasterStore!.popErrorToast(`item, title: ${item?.title || 'Unknown'}`, 'add');
      removeLoadingItem(item.objectID);
      setIsLoading(false);

      return Promise.reject();
    }
  };

  const handleBlur = (): void => {
    resetCursor();
  };

  const handleFocus = (): void => {
    openDropdown();
  };

  const handleReturn = async (): Promise<void> => {
    try {
      const item = cursorHit();
      const selections = item?.objectID ? state[item.objectID]?.selections : {};

      const itemData = (): Partial<Hit<CatalogItemHit>> => {
        if (cursor === 0 || !item) {
          return {
            title: inputRef.current?.value || ''
          };
        }

        return item;
      };

      await handleAddItem(itemData(), selections);
      refine('');
    } catch (error) {
      return;
    }
  };

  const handleArrowUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.preventDefault();

    if (cursor > 0) {
      setCursor(prevCursor => prevCursor - 1);
    } else if (cursor === 1 || cursor === 0) {
      setCursor(hits.length);
    }
  };

  const handleArrowDown = (): void => {
    if (cursor < hits.length) {
      setCursor(prevCursor => prevCursor + 1);
    } else if (cursor === hits.length) {
      setCursor(0);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const supportedKeys: SupportedKeyboardKey[] = ['ArrowDown', 'ArrowUp', 'Enter', 'Escape'];

    if (!supportedKeys.includes(event.key as SupportedKeyboardKey)) {
      return;
    }

    const events: { [key in SupportedKeyboardKey]: () => void } = {
      Escape: () => {
        handleBlur();
      },
      Enter: () => {
        handleReturn();
      },
      ArrowUp: () => {
        event.preventDefault();
        handleArrowUp(event);
      },
      ArrowDown: () => {
        event.preventDefault();
        handleArrowDown();
      }
    };

    events[event.key]?.();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!isOpen) {
      openDropdown();
    }

    resetCursor();
    refine(event.currentTarget.value);
  };

  const resetCursor = (): void => {
    if (cursor !== 0) {
      setCursor(0);
    }
  };

  const resetInput = (): void => {
    if (isOpen) {
      closeDropdown();
    }

    resetCursor();
    refine('');
  };

  return (
    <SearchResultContext.Provider value={contextValue}>
      <InputWrapper>
        <Flex alignItems="center">
          <InputIconWrapper>
            <Icon
              iconName="plus-large"
              styles={{
                width: '100%'
              }}
            />
          </InputIconWrapper>
          <Input
            type="search"
            ref={inputRef}
            placeholder="Add item"
            value={currentRefinement}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
          />
          {!isLoading && currentRefinement && (
            <ClearIconWrapper onClick={() => resetInput()}>
              <Icon
                iconName="cross-circle"
                styles={{
                  width: '100%',
                  height: '100%'
                }}
              />
            </ClearIconWrapper>
          )}
          {isLoading && (
            <InputLoader>
              <LoadingSpinner
                color={colors.floomMidnightBlue}
              />
            </InputLoader>
          )}
        </Flex>
        {!!currentRefinement.length && isOpen && (
          <ResultsWrapper>
            <OutsideClickHandler
              disabled={!currentRefinement.length || !isOpen}
              useCapture={true}
              onOutsideClick={() => {
                if (document.activeElement !== inputRef.current) {
                  closeDropdown();
                }
              }}
            >
              <InlineSearchResults
                hits={hits}
                cursor={cursor}
                isVisible={!!currentRefinement.length && isOpen}
                handleAdd={handleAddItem}
                handleAddCustomItem={handleReturn}
                loadingItems={loadingItems}
                handleCursorSelect={setCursor}
                handleInputRefocus={handleInputRefocus}
                inputPosition={inputPosition}
                inputText={currentRefinement}
              />
            </OutsideClickHandler>
          </ResultsWrapper>
        )}
      </InputWrapper>
    </SearchResultContext.Provider>
  );
})));

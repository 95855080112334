import styled from '@emotion/styled';

import { colors, textStyles } from 'utils/rebass-theme';

export const Content = styled.div<any>`
  text-align: left;
  margin-bottom: ${({ hasSpacing }): string => hasSpacing ? '40px' : '0'};
`;

export const Title = styled.p`
  ${textStyles.title};
  display: inline;
  margin-right: 5px;
`;

export const SubTitle = styled.p`
  ${textStyles.label};
  color: ${colors.shade40};
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 0px;
`;

export const Optional = styled.p`
  ${textStyles.label};
  color: ${colors.shade40};
  text-transform: uppercase;
  display: inline;
`;

export const InnerComponent = styled.div`
  margin-top: 20px;
`;

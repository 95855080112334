import styled from '@emotion/styled';

import { CheckboxLabel } from 'components/checkbox-item/checkbox-item.styles';

export const CheckboxListWrapper = styled.div`
  font-size: 14px;
  padding: 10px 12px 20px;

  ${CheckboxLabel} {
    font-size: 12px;
  }
`;

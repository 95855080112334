import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

import { fonts } from 'utils/rebass-theme';

import { ButtonAppearance, ButtonSize } from './button.types';

export const ButtonWrapper: any = styled.div<{
  appearance: ButtonAppearance;
  size: ButtonSize;
  isParentWidth: boolean;
  disabled: boolean;
}>`
  background: ${({ appearance }): string => appearance.background};
  color: ${({ appearance }): string => appearance.textColor};
  border: 2px solid ${({ appearance }): string => appearance.borderColor};
  border-width: ${({ appearance }): string => appearance.borderWidth ? `${appearance.borderWidth}px` : '2px'};
  padding: ${({ size }): string => size.padding};
  padding-left: ${({ isParentWidth }) => isParentWidth ? 0 : null};
  padding-right: ${({ isParentWidth }) => isParentWidth ? 0 : null};
  font-size: ${({ size }): string => size.fontSize};
  font-family: ${fonts.primary};
  width: ${({ isParentWidth }): string => isParentWidth ? '100%' : 'auto'};
  cursor: ${({ disabled }): string => disabled ? 'not-allowed' : 'pointer'};
  transition: all .2s ease-in-out;
  border-radius: 3px;
  font-weight: ${({ appearance }) => appearance.fontWeight || 600};
  max-height: ${({ size }): string => size.maxHeight || '50px'};

  &:hover {
    background: ${({ appearance }: any): string => appearance.hoverBackground};
    border-color: ${({ appearance }: any): string => appearance.hoverBorderColor};
  }
`;

export const ButtonInner = css`
  align-self: center;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  text-align: center;
  position: relative;
`;

export const ButtonCopy: any = styled.span`
  align-items: center;
  align-self: center;
  flex: 1 1 auto;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: opacity .2s ease-in-out;
  opacity: ${({ isLoading }: any): any => isLoading ? 0 : 1};
`;

export const ButtonIcon: any = styled(Box)`
  display: inline-flex;
  margin-right: 5px;
  transition: opacity .2s ease-in-out;
  opacity: ${({ isLoading }: any): any => isLoading ? 0 : 1};
`;

export const ButtonIconAfter: any = styled(Box)`
  display: inline-flex;
  margin-left: auto;
  transition: opacity .2s ease-in-out;
  opacity: ${({ isLoading }: any): any => isLoading ? 0 : 1};
`;

/* eslint-disable camelcase */

import { NavigateOptions } from '@reach/router';

import {
  Merchant,
  PlanFeature,
  UserRole,
  CatalogItemType,
  Currency
} from 'generated-types.d';

import { FeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags.types';

import { IconType } from 'components/icon/icon.types';

import { NavItemType } from './components/navigation/components/nav-list/nav-list.constants';

export enum NavPages {
  AddOnsList = '/add-ons/list/',
  AddOnsCreate = '/add-ons/create/',
  AddOnsEdit = '/add-ons/edit/',
  Conversations = '/conversations/',
  Discounts = '/settings/discounts/',
  DpdUpload = '/settings/dpd-upload/',
  Login = '/login/',
  MerchantList = '/settings/merchants/',
  MerchantDetail = '/settings/merchants/',
  MerchantCreate = '/merchant/create/',
  MyPlan = '/my-plan/',
  Lists = '/lists/',
  OrdersHome = '/orders/',
  OrdersList = '/orders/list/',
  OnboardingHome = '/onboarding/',
  OnboardingStripeConnect = '/onboarding/stripe-connect/',
  OnboardingFloomPayment = '/onboarding/get-paid-from-floom/',
  OnboardingSubscription = '/onboarding/subscription/',
  OnboardingConfirmation = '/onboarding/all-done/',
  OnboardingSubscriptionError = '/onboarding/issue-with-subscription/',
  Overview = '/overview/',
  PlanConfirmation = '/plan-confirmation/',
  ProductsList = '/products/list/',
  ProductsCreate = '/products/create/',
  ProductsEdit = '/products/edit/',
  Notifications = '/notifications/',
  Payments = '/payments/',
  Promoted = '/promoted/',
  PromotedMyPromotions = '/promoted/my-promotions/',
  PromotedUpcomingPostcodes = '/promoted/upcoming/postcodes/',
  PromotedUpcomingPayment = '/promoted/upcoming/payment/',
  Settings = '/settings/',
  Wholesale = '/wholesale/',
  WholesaleCheckout = '/wholesale/checkout/',
  WholesaleOrders = '/wholesale/orders/',
  WholesalePreOrder = '/wholesale/pre-order/',
  WholesaleItems = '/wholesale/items/',
  WholesaleShop = '/wholesale/shop/',
  WholesaleSpecialOffers = '/wholesale/special-offers/',
  WholesaleItem = '/wholesale/shop/',
}

export interface WholesaleComponentType {
  image_url: string;
  description: string;
  price: number;
  unit: number;
  quantity: number;
  identifier: number;
  info: {
    price: number;
    unitCode: number;
    minimumQuantity: number;
    maximumQuantity: number;
    basisQuantity: number;
  };
}

export interface BasketType {
  [id: number]: BasketItemType;
}

export interface BasketItemType {
  component: WholesaleComponentType;
  quantity: number;
}

export interface ChildRouteType {
  name: string;
  url: string;
  id: string;
  isExternal?: boolean;
  isDisabled: boolean | ((merchant: Merchant | null, flags: FeatureFlags) => boolean);
  isHidden?: boolean;
  sectionName: string;
  itemName: string;
}

export type DiscountCreateEditFormType = 'create' | 'edit';

export type NavNotificationType = 'info' | 'warning';

export interface RouteType {
  sectionName: string;
  itemName: string;
  type: NavItemType;
  icon: IconType;
  url: string | ((args: {
    merchant: Merchant | null;
    flags: FeatureFlags;
  }) => string);
  id: string;
  name: string;
  label?: string;
  identifier: string;
  isDisabled?: boolean;
  isBeta?: boolean;
  isHidden?: boolean;
  isExternal?: boolean;
  hasNotifications?: boolean;
  unreadCount?: number;
  notificationType?: NavNotificationType;
  children?: ChildRouteType[];
  requiredPlanFeatures: PlanFeature[];
  isFeatureFlagDisabled?: (merchant: Merchant | null) => boolean;
}

export interface DividerType {
  type: NavItemType;
  name?: string;
  isFeatureFlagDisabled?: (merchant: Merchant | null) => boolean;
}

export type ModalType =
  | 'order-detail'
  | 'wholesale-shop-listing'
  | 'merchant-detail';

export interface ModalConfig {
  type: ModalType;
  id: string;
  closeTo?: string;
  additionalOptions: any;
}

export interface ModalNavigateState {
  preventScroll?: boolean;
  closeTo?: string;
  modal: ModalConfig;
}

export interface DecryptedToken {
  user: {
    id: string;
    role: UserRole;
    restrictedRelationshipIds: {
      merchantId: string;
      shopId?: string;
    };
  };
  iat: string;
}

export type ModalNavigateOptions = NavigateOptions<ModalNavigateState>;

export type AlgoliaItem<T = Record<string, never>> = T & { objectID: string };

export interface TradeSkuMedia {
  url: string;
  fileName: string[];
  supplier: string;
}

export interface TradeSkuPriceRange {
  min: number;
  max: number;
  currency?: Currency;
}

export interface SupplierPriceRange extends TradeSkuPriceRange {
  supplierId: string;
}

export interface TradeSkuHitColour {
  id: string;
  name: string;
  hex: string;
}

export interface TradeSkuHitAvailability {
  id?: string;
  multiplesOf: number;
  priceRange: TradeSkuPriceRange;
  priceRanges: TradeSkuPriceRange[];
  supplierId: string;
}

export interface TradeSkuHit {
  id: string;
  headNumber?: number;
  minimumHeadSize?: number;
  maximumHeadSize?: number;
  stemLength?: number;
  stemLengthUnit?: string;
  headSizeUnit?: string;
  height?: string;
  weight?: string;
  maturity?: string;
  potSize?: string;
  diameter?: string;
  thickness?: string;
  availability?: TradeSkuHitAvailability[];
}

export interface CatalogItemHit {
  objectID: string;
  title: string;
  primaryName: string;
  secondaryName: string;
  type: CatalogItemType;
  genus: string;
  species: string;
  cultivar: string;
  friendlyName: string[];
  images: TradeSkuMedia[];
  priceRange: TradeSkuPriceRange;
  historicalPriceRange: TradeSkuPriceRange;
  livePriceRange: TradeSkuPriceRange;
  supplierLivePriceRanges: SupplierPriceRange[];
  colour: TradeSkuHitColour;
  tradeSku: TradeSkuHit[];
  _highlightResult: any;
}

export interface MerchantForList {
  id?: string;
  title?: string;
  currency?: Currency;
  city?: string;
  postalCode?: string;
}

import { Fragment, memo, useEffect } from 'react';

import { CatalogItemType } from 'generated-types';
import { connectMenu, MenuProvided } from 'react-instantsearch-core';

import { colors } from 'utils/rebass-theme';

import { TabButton } from 'features/lists/components/list-tabs/list-tab-button';
import { PRODUCT_CATEGORIES } from 'features/lists/lists.constants';

import { TabHeader, TabScrollContainer } from './inline-search-tabs.styles';

interface RefinementMenuProps extends MenuProvided {
  onSelect: (type: CatalogItemType) => void;
}

export const InlineSearchTabs = connectMenu(memo((props: RefinementMenuProps): JSX.Element | null => {
  const selectItem = (
    refinement: CatalogItemType | '',
    type: CatalogItemType
  ): void => {
    props.refine(refinement);
    props.onSelect(type);
  };

  const selectedItem = props.items?.find?.(item => item.isRefined);
  const isSelectedItemPopulated = !!selectedItem && !!selectedItem?.count;

  const handleAutoSelection = (): void => {
    if (!isSelectedItemPopulated) {
      const firstPopulatedItem = props.items?.find?.(item => !!item.count);

      if (firstPopulatedItem) {
        selectItem(firstPopulatedItem.value as CatalogItemType, firstPopulatedItem.value as CatalogItemType);
      }
    }
  };

  useEffect(() => {
    handleAutoSelection();
  }, []);

  useEffect(() => {
    handleAutoSelection();
  }, [isSelectedItemPopulated]);

  return (
    <Fragment>
      <TabScrollContainer>
        <TabHeader>
          {PRODUCT_CATEGORIES.map((category, index) => {
            const metadata = props.items.find(item => (item.label as CatalogItemType) === category.key);

            const refinement = metadata?.value as CatalogItemType;
            const type = metadata?.label as CatalogItemType;

            const isDisabled = !metadata?.count;
            const isSelected = !!metadata?.isRefined;
            const colour = isDisabled ? colors.middleGrey : colors.floomMidnightBlue;

            return (
              <TabButton
                title={category.title}
                key={index}
                isDisabled={isDisabled}
                onClick={() => {
                  if (!isDisabled) {
                    selectItem(refinement, type);
                  }
                }}
                colour={colour}
                isSelected={isSelected}
              />
            );
          })}
        </TabHeader>
      </TabScrollContainer>
    </Fragment>
  );
}));

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const PlanContainer: any = styled(Flex)`
  max-width: 30em;
  height: 100%;
  margin: 7% auto 15%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 16px;
`;

export const MyPlanHeader: any = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
`;

export const MyPlan: any = styled.h1`
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 10px;
`;

export const MyPlanSubTitle: any = styled.h2`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const Price: any = styled(Box)`
  margin-top: 30px;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  > span {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const SupportCTAContainer: any = styled(Box)`
  margin-top: 20px;
`;

export const SupportCTA: any = styled(Box)`
  background-color: ${colors.floomMidnightBlue};
  padding: 10px 35px 15px;
  border-radius: 3px;
  color: ${colors.floomCream};
`;

export const DetailListContainer: any = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 20px auto;
  width: 100%;
`;

export const DetailListHeader: any = styled(Flex)`
  border-bottom: 1px solid ${colors.middleGrey};
  width: 100%;
  > h3 {
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const DetailList: any = styled.ul`
  color: ${colors.floomMidnightBlue};
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  list-style: none;
  width: 100%;
  > li {
    padding: 18px 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    border-bottom: 1px solid ${colors.shade40};
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > p {
      margin-bottom: 0;
    }
  }
`;

export const DetailListNotes: any = styled.span`
  font-size: 10px;
  line-height: 16px;
  margin-top: 10px;
`;

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const AddOnRow: any = styled(Flex)`
  flex-direction: row;
  background-color: ${colors.lightGrey};
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
`;

export const Quantity: any = styled.div`
  color: ${colors.shade40};
  font-size: ${textStyles.h3};
  line-height: 24px;
  margin: 0 5px 0 15px;
`;

export const ImgCol: any = styled(Box)`
  flex: 0 0 auto;
`;

export const QuantityCol: any = styled(Box)`
  flex: 0 0 auto;
`;

export const DescCol: any = styled(Box)`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

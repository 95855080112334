import { Component, Fragment, ReactNode } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import hex2Rgba from 'hex2rgba';
import Modal from 'react-modal';

import Button from 'components/button';

import { colors } from '../../utils/rebass-theme';
import Icon from '../icon';

import * as Styles from './generic-modal.styles';
import { ModalProps } from './generic-modal.types';

const queryClient = new QueryClient();

export default class GenericModal extends Component<ModalProps> {
  static defaultProps = {
    width: 430,
    title: '',
    hasFooterBackground: true,
    hasOverflow: true,
    shouldHideFooter: false,
    shouldHideHeader: false,
    isButtonLoading: false,
    contentStyles: {},
    overlayStyles: {},
    innerContentStyles: null,
    hasFooterBorder: true,
    contentTransform: 'translate(calc(-50% - 20px), -50%)',
    contentLayoutType: 'default',
    testId: ''
  };

  componentDidMount(): void {
    Modal.setAppElement('#global-layout');
  }

  render(): ReactNode {
    const customStyles: Modal.Styles = {
      content: {
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        padding: 0,
        height: '100%',
        width: '100%',
        background: 'none',
        border: 'none',
        pointerEvents: 'none'
      },
      overlay: {
        background: hex2Rgba(colors.floomMidnightBlue, '0.9'),
        zIndex: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...this.props.overlayStyles
      }
    };

    return (
      <QueryClientProvider client={queryClient}>
        <Modal
          isOpen={this.props.modalOpen}
          style={customStyles}
          overlayClassName={this.props.overlayClassName}
          className={this.props.contentClassName}
          contentLabel={this.props.title}
          closeTimeoutMS={this.props.closeTimeoutMS || 200}
          onRequestClose={this.props.closeModal}
          preventScroll={true}
          htmlOpenClassName='ReactModal__Html--open'
        >
          <Styles.ContentWrapper
            customStyles={this.props.contentStyles}
            hasOverflow={this.props.hasOverflow}
            width={this.props.width}
            contentLayoutType={this.props.contentLayoutType}
          >
            <Styles.Content hasOverflow={this.props.hasOverflow}>
              {!this.props.shouldHideHeader && (
                <Styles.Header>
                  <Styles.Title>{this.props.title}</Styles.Title>
                  {this.props.headerAction && (
                    <Fragment>
                      <Styles.HeaderAction>{this.props.headerAction}</Styles.HeaderAction>
                    </Fragment>
                  )}
                  <Styles.ExitCross
                    onClick={this.props.closeModal}
                    aria-label='close button'
                  >
                    <Icon
                      iconName="cross-small"
                    />
                  </Styles.ExitCross>
                </Styles.Header>
              )}
              <Styles.InnerContent css={this.props.innerContentStyles}>
                {this.props?.innerComponent || this.props.children}
              </Styles.InnerContent>
              {!this.props.shouldHideFooter && (
                <Styles.FooterWrapper stickToBottom={this.props.footerStickToBottom}>
                  {this.props.footerContent && (
                    <Styles.FooterContent>
                      {this.props.footerContent}
                    </Styles.FooterContent>
                  )}

                  <Styles.Footer hasBg={this.props.hasFooterBackground}>
                    {this.props.extraAction && (
                      <Styles.AdditionalAction>
                        {this.props.extraAction}
                      </Styles.AdditionalAction>
                    )}
                    <Styles.ActionButton
                      css={this.props.actionButtonStyles}
                      onClick={this.props.confirmButtonAction}
                      disabled={this.props.confirmButtonDisabled}
                      aria-label={this.props.confirmButtonText}
                      data-testid={this.props.testId}
                    >
                      <Button
                        size={this.props.confirmButtonSize || 'normal'}
                        appearance="primary"
                        isParentWidth={this.props.isConfirmButtonParentWidth || false}
                        copy={this.props.confirmButtonText || ''}
                        isLoading={this.props.isButtonLoading}
                        isDisabled={this.props.confirmButtonDisabled}
                      />
                    </Styles.ActionButton>
                  </Styles.Footer>
                </Styles.FooterWrapper>
              )}
            </Styles.Content>
          </ Styles.ContentWrapper>
        </Modal>
      </QueryClientProvider>
    );
  }
}

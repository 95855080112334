import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { NavService } from 'lib';

import VariationTabItem from '../variation-tab-item';

import * as Styles from './create-variation-tab-layout.styles';
import * as Types from './create-variation-tab-layout.types';

class CreateVariationTabLayout extends Component<Types.CreateVariationTabLayoutProps> {
  private onVariationSelect = (variationId: string): void => {
    NavService.productEditVariation(this.props.productEditStore!.id!, variationId);
  };

  render(): React.ReactNode {
    return (
      <Styles.Content>
        {this.props.productEditStore!.variations.map(variation => (
          <VariationTabItem
            key={variation.id}
            onVariationSelect={(): void => this.onVariationSelect(variation.id!)}
            variation={variation}
            currentVariationId={this.props.productEditStore!.currentVariationId}
          />
        ))}
        <Styles.DeleteValidations>
          <Styles.DeleteValidationText onClick={this.props.deleteModalToggle}>
            Delete variation(s)
          </Styles.DeleteValidationText>
        </Styles.DeleteValidations>
      </Styles.Content>
    );
  }
}

export default inject('productEditStore')(observer(CreateVariationTabLayout));

import { Box } from 'rebass';

import Button from 'components/button';
import GenericModal from 'components/generic-modal';

import * as Styles from './modal.styles';

export const CreateBreadcrumbSaveModal = (
  isLoading: boolean,
  isOpen: boolean,
  confirmAction: () => any,
  closeAction: () => any
): JSX.Element => {
  const confirmText = isLoading ?
    'Loading...' :
    'Do you want to save your modifications before leaving this&nbsp;page?';

  return (
    <GenericModal
      width={430}
      innerComponent={(
        <Styles.ConfirmationBox>
          <p dangerouslySetInnerHTML={{ __html: confirmText }} />
        </Styles.ConfirmationBox>
      )}
      shouldHideFooter={isLoading}
      confirmButtonAction={confirmAction}
      confirmButtonText="Yes"
      title="You have unsaved progress"
      modalOpen={isOpen}
      closeModal={closeAction}
      extraAction={(
        <Box onClick={closeAction}>
          <Button
            size="normal"
            appearance="secondary"
            copy="No"
            isLoading={false}
            isDisabled={false}
          />
        </Box>
      )}
    />
  );
};

import {
  FC,
  Key,
  useContext,
  useCallback,
  useMemo,
  ReactNode,
  Fragment
} from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { CatalogItemType } from 'generated-types.d';

import { CurrencyService } from 'lib';

import { getCountryByCode } from 'utils/metadata/countries-states';

import { ColourDot  } from 'features/lists/components/list-item-colour/list-item-colour.styles';

import Button from 'components/button';
import { ButtonAppearanceOption } from 'components/button/button.types';
import QuantitySelector from 'components/quantity-selector';

import { WholesaleShopListingContext } from '../../wholesale-shop-listing.context';

import {
  AddButton,
  SupplierCardLineItem,
  TotalPriceCell,
  PriceCell,
  MultiplesOfMobile,
  CardHeader,
  PerUnitCell,
  ButtonCell,
  BuyControlsContainer,
  KeyAttribute,
  AttributeItem,
  PrimaryAttributesContainerDesktop,
  PrimaryAttributesContainerMobile,
  AttributesContainer,
  AttributesWrapper,
  KeyAttributeItem
} from './wholesale-shop-availability-item.styles';
import {
  CatalogItemConfig,
  CatalogItemFacet,
  WholesaleShopAvailabilityItemProps
} from './wholesale-shop-availability-item.types';

export const WholesaleShopAvailabilityItem: FC<WholesaleShopAvailabilityItemProps> = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleShopBasketStore: stores.wholesaleShopBasketStore
}))(observer(({
  wholesaleShopBasketStore,
  availabilityItem,
  catalogItem,
  tradeSku,
  type,
  isSelected,
  quantity,
  hasShopAccess,
  setQuantity
}) => {
  const {
    isPlacingOrder,
    isPurchased,
    onPlaceOrder
  } = useContext(WholesaleShopListingContext);

  const onChange = useCallback((quantityChange: number): void => {
    if (!availabilityItem.supplierProductId) return;

    setQuantity(availabilityItem.supplierProductId, quantityChange);
  }, [availabilityItem.supplierProductId]);

  if (
    !availabilityItem.price?.length
    || !availabilityItem.supplier?.id
    || !availabilityItem.multiplesOf
    || !availabilityItem.maximumPurchaseQuantity
    || !availabilityItem.supplierProductId
  ) return null;

  const price = availabilityItem.price[0];
  const formattedUnitPrice = CurrencyService.formatPrice(price.price, price.currency);

  const canPurchase = useMemo((): boolean => {
    return !!wholesaleShopBasketStore!.basket.length && hasShopAccess;
  }, [wholesaleShopBasketStore!.basket]);

  const renderColour = (): ReactNode => {
    if (!catalogItem?.colour || ['mixed', 'not provided'].includes(catalogItem?.colour?.name.toLowerCase())) {
      return;
    }

    return (
      <Flex
        alignItems="baseline"
        ml="8px"
      >
        <ColourDot
          hex={catalogItem?.colour?.hex}
          size="10px"
        />
        <Box ml="6px">
          {catalogItem?.colour?.name}
        </Box>
      </Flex>
    );
  };

  const catalogItemConfig: CatalogItemConfig = {
    [CatalogItemType.Flower]: {
      shouldDisplayPrimaryAttributes: true,
      attributes: [
        {
          label: 'Stem length',
          value: (() => {
            const stemLengthValue = tradeSku.flower?.minimumStemLength;

            return stemLengthValue ? `${stemLengthValue}cm` : '';
          })()
        },
        {
          label: 'Maturity',
          value: tradeSku.flower?.maturity
        },
        {
          label: 'Heads',
          value: tradeSku.flower?.headNumber
        }
      ]
    },
    [CatalogItemType.Plant]: {
      shouldDisplayPrimaryAttributes: true,
      attributes: [
        {
          label: 'Pot size',
          value: (() => {
            const potSizeValue = tradeSku.plant?.potSize;

            return potSizeValue ? `${parseInt(potSizeValue)}cm` : '';
          })()
        },
        {
          label: 'Height',
          value: tradeSku.plant?.height
        },
        {
          label: 'Diameter',
          value: tradeSku.plant?.diameter
        },
        {
          label: 'Thickness',
          value: tradeSku.plant?.thickness
        },
        {
          label: 'Maturity',
          value: tradeSku.plant?.maturity
        }
      ]
    },
    [CatalogItemType.Decoration]: {
      shouldDisplayPrimaryAttributes: false,
      attributes: [
        {
          label: 'Multiples of',
          value: availabilityItem?.multiplesOf
        }
      ]
    },
    [CatalogItemType.Sundry]: {
      shouldDisplayPrimaryAttributes: false,
      attributes: [
        {
          label: 'Multiples of',
          value: availabilityItem?.multiplesOf
        },
        {
          label: 'Colour',
          value: renderColour()
        },
        {
          label: 'Weight',
          value: tradeSku.sundry?.weight
        }
      ]
    },
    [CatalogItemType.Other]: {
      shouldDisplayPrimaryAttributes: false,
      attributes: [
        {
          label: 'Multiples of',
          value: availabilityItem?.multiplesOf
        }
      ]
    }
  };

  const buttonConfig = useMemo((): { copy: string; appearance: ButtonAppearanceOption } => {
    if (isPurchased) {
      return {
        copy: 'Purchased',
        appearance: 'success'
      };
    }

    return {
      copy: 'Buy',
      appearance: 'primary'
    };
  }, [isPurchased]);

  const firstProperty = catalogItemConfig[type].attributes.find((field: CatalogItemFacet) => !!field.value);

  const renderCountryGrowerInfo = (): ReactNode => {
    const country = getCountryByCode(availabilityItem?.grower?.countryCode);

    const attributes = [
      availabilityItem?.grower?.name,
      country
    ];

    return attributes.map((attr, index) => {
      if (!attr || ['mixed', 'not provided'].includes(attr.toString().toLowerCase())) {
        return null;
      }

      return (
        <AttributeItem key={index}>{attr}</AttributeItem>
      );
    });
  };

  const renderKeyAttribute = (): ReactNode => {
    const isKeyAttributeValid = !!firstProperty && !['mixed', 'not provided'].includes(firstProperty.toString().toLowerCase());

    if (!isKeyAttributeValid) {
      return;
    }

    const getKeyAttributeString = (): string => {
      if (firstProperty.label === 'Stem length') {
        return firstProperty.value;
      }

      return `${firstProperty.label} ${firstProperty.value}`;
    };

    return (
      <KeyAttribute>
        <KeyAttributeItem>
          {getKeyAttributeString()}
          {!!availabilityItem?.multiplesOf && (
            <MultiplesOfMobile>
              <AttributeItem>Multiples of {availabilityItem?.multiplesOf}</AttributeItem>
            </MultiplesOfMobile>
          )}
        </KeyAttributeItem>
      </KeyAttribute>
    );
  };

  return (
    <SupplierCardLineItem>
      <CardHeader isSelected={isSelected}>
        <AttributesWrapper>
          {renderKeyAttribute()}
          <AttributesContainer>
            {!!catalogItemConfig[type].shouldDisplayPrimaryAttributes && (
              <Fragment>
                <PrimaryAttributesContainerDesktop>
                  <Flex
                    flexWrap="wrap"
                  >
                    {!!availabilityItem?.multiplesOf && (
                      <AttributeItem>Multiples of {availabilityItem?.multiplesOf}</AttributeItem>
                    )}
                    {renderCountryGrowerInfo()}
                  </Flex>
                </PrimaryAttributesContainerDesktop>
                <PrimaryAttributesContainerMobile>
                  <Flex
                    flexWrap="wrap"
                  >
                    {renderCountryGrowerInfo()}
                  </Flex>
                </PrimaryAttributesContainerMobile>
              </Fragment>
            )}
            {isSelected && (
              <Flex>
                {catalogItemConfig[type].attributes.map((item: { label: string; value: string }, index: Key) => {
                  if (
                    index === 0
                      || !item.value
                      || firstProperty.label === item.label
                      || (!!item.value && item.value.toString().toLowerCase() === 'not provided')
                  ) {
                    return null;
                  }

                  return (
                    <AttributeItem key={index}>
                      {item.label} {item.value}
                    </AttributeItem>
                  );
                })}
              </Flex>
            )}
          </AttributesContainer>
        </AttributesWrapper>
        {quantity > 0 ? (
          <PriceCell>
            <TotalPriceCell>
              <Box fontWeight="800">
                {CurrencyService.formatPrice(price.price * quantity, price.currency)}
              </Box>
            </TotalPriceCell>
            <PerUnitCell>
              {formattedUnitPrice} per unit
            </PerUnitCell>
          </PriceCell>
        ) : (
          <PriceCell>
            <TotalPriceCell>
              <Box fontWeight="800">
                {formattedUnitPrice}
              </Box>
              <Box>
                per unit
              </Box>
            </TotalPriceCell>
          </PriceCell>
        )}
      </CardHeader>
      {isSelected && (
        <BuyControlsContainer>
          <Box maxWidth="152px">
            <QuantitySelector
              onChange={onChange}
              initialValue={0}
              step={availabilityItem.multiplesOf}
              max={availabilityItem.maximumPurchaseQuantity * availabilityItem.multiplesOf}
              size="normal"
            />
          </Box>
          <ButtonCell>
            <AddButton
              as="button"
              disabled={isPlacingOrder || !canPurchase || isPurchased}
              onClick={() => onPlaceOrder({ supplierId: availabilityItem.supplier!.id })}
            >
              <Button
                copy={buttonConfig.copy}
                size="xsmall"
                appearance={buttonConfig.appearance}
                isDisabled={!canPurchase && !isPurchased}
                isLoading={isPlacingOrder}
              />
            </AddButton>
          </ButtonCell>
        </BuyControlsContainer>
      )}
    </SupplierCardLineItem>
  );
}));

import styled from '@emotion/styled';

import { breakpoints, colors } from 'utils/rebass-theme';

export const Container = styled.div`
  width: 280px;
  background: ${colors.white};
  height: 100vh;
  z-index: 11;

  @media (max-width: ${breakpoints.large}) {
    width: 100%;
  }
`;

import React from 'react';

import { colors } from 'utils/rebass-theme';

import LogoSVG from 'components/logo';

import * as Styles from './logo.styles';

class Logo extends React.Component<any> {
  render(): React.ReactNode {
    return (
      <Styles.Logo>
        <LogoSVG color={colors.white} />
      </Styles.Logo>
    );
  }
}

export default Logo;

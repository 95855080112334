import { Router } from '@reach/router';
import {
  QueryClientProvider,
  QueryClient
} from '@tanstack/react-query';
import Helmet from 'react-helmet';

import NotificationsOverview from 'features/notifications/pages';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

const queryClient = new QueryClient();

const Notifications = (): JSX.Element => {
  return (
    <AppLayout>
      <QueryClientProvider client={queryClient}>
        <Helmet>
          <title>Notifications</title>
        </Helmet>
        <Router>
          <AuthedRoute
            path="/notifications"
            component={NotificationsOverview}
          />
        </Router>
      </QueryClientProvider>
    </AppLayout>
  );
};

export default Notifications;

import { ApolloQueryResult } from '@apollo/client';

import {
  AdjusterTier,
  AdjusterTierCreateInput,
  AdjusterTierUpdateInput,
  AdjusterTierWhereUniqueInput
} from 'generated-types.d';

import { AdjusterMutations, GraphQL } from 'lib';

export default class AdjusterService {
  public static deleteAdjusterTier = async (where: AdjusterTierWhereUniqueInput): Promise<Pick<AdjusterTier, 'id'>> => {
    try {
      const result: ApolloQueryResult<{ updateAdjusterTier: Pick<AdjusterTier, 'id'> }> = await GraphQL
        .query(AdjusterMutations.DELETE_WHOLESALE_TIER, { where });

      return result.data.updateAdjusterTier;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static updateAdjusterTier = async (
    where: AdjusterTierWhereUniqueInput,
    data: AdjusterTierUpdateInput
  ): Promise<Pick<AdjusterTier, 'id'>> => {
    try {
      const result: ApolloQueryResult<{ updateAdjusterTier: Pick<AdjusterTier, 'id'> }> = await GraphQL
        .query(AdjusterMutations.UPDATE_WHOLESALE_TIER, { where, data });

      return result.data.updateAdjusterTier;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static createAdjusterTier = async (data: AdjusterTierCreateInput): Promise<Pick<AdjusterTier, 'id'>> => {
    try {
      const result: ApolloQueryResult<{ createAdjusterTier: Pick<AdjusterTier, 'id'> }> = await GraphQL
        .query(AdjusterMutations.CREATE_WHOLESALE_TIER, { data });

      return result.data.createAdjusterTier;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

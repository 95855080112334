import { action, observable, makeObservable } from 'mobx';

import { TOASTER_TYPES } from './toaster-store.config';
import * as Types from './toaster-store.types';

export default class ToasterStore {
  constructor() {
    makeObservable(this, {
      newToast: observable,
      popToast: action,
      popNotificationToast: action,
      popErrorToast: action,
      popSuccessToast: action,
      resetToasterState: action
    });
  }

  newToast: Types.ToastObject | null = null;

  public popToast = (message: string, type: Types.ToastType): void => {
    this.newToast = {
      message: message,
      type: type,
      id: Math.floor(Math.random() * Math.pow(2, 24)).toString(32)
    };
  };

  public popNotificationToast = (message: string): void => {
    this.popToast(message, Types.ToastType.Notification);
  };

  public popErrorToast = (changeType: string, verb: Types.ToastVerb): void => {
    this.popToast(this.buildErrorMessage(changeType, verb), Types.ToastType.Error);
  };

  public popSuccessToast = (changeType: string, verb: Types.ToastVerb, isMultiple = false): void => {
    this.popToast(this.buildSuccessMessage(changeType, verb, isMultiple), Types.ToastType.Success);
  };

  public resetToasterState = (): void => {
    this.newToast = null;
  };

  private buildSuccessMessage = (
    changeType: string,
    messageType: Types.ToastVerb,
    multiple?: boolean
  ): string => {
    const { successAction, prefix } = TOASTER_TYPES[messageType];
    const copyVerb = (multiple || prefix.length) ? 'have' : 'has';
    const title = multiple ? 'Multiple items' : changeType;

    return `${prefix}${title} ${copyVerb} been successfully ${successAction}`;
  };

  private buildErrorMessage = (
    changeType: string,
    messageType: string,
    multiple?: boolean
  ): string => {
    const title = multiple ? 'multiple items' : changeType;
    const { errorAction } = TOASTER_TYPES[messageType];

    return `Something went wrong while ${errorAction} ${title}`;
  };
}

import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import PaymentsOverview from 'features/payments/pages/overview';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

const Payments = (): JSX.Element => {
  return (
    <AppLayout>
      <Helmet>
        <title>Payments</title>
      </Helmet>
      <Router>
        <AuthedRoute
          path="/payments"
          component={PaymentsOverview}
        />
      </Router>
    </AppLayout>
  );
};

export default Payments;

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { CardButton, AbsoluteCover } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const GroupItem: any = styled(CardButton)`
  position: relative;
  text-align: center;
  padding: 20px;
  transition: border-color .2s ease-in-out;
  background: ${(props: any): any => props.disabled ? '#efefef' : ''};

  ${(props: any): any => !props.isActive && `
    box-shadow: none;
  `}
`;

export const GroupItemFocus: any = styled.div`
  ${textStyles.subhead};
  color: ${(props: any): any => props.disabled ? colors.middleGrey : ''};
  text-align: left;
`;

export const GroupItemSubtitle: any = styled.div`
  ${textStyles.footnote};
  color: #969db1;
  text-align: left;
  white-space: normal;
`;

export const GroupItemContent: any = styled(Flex)`
  width: 100%;

  > div {
    position: relative;
    flex: 1;

    + div {
      &:before {
        ${AbsoluteCover};
        content: '';
        right: auto;
        width: 1px;
        background: ${colors.middleGrey};
      }
    }
  }
`;

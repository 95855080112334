import { FC } from 'react';

import GenericModal from 'components/generic-modal';
import { TypeformList } from 'components/typeform/form-list/form-list';

import { TypeformSelectModalProps } from './typeform-select-modal.types';

const CatalogFiltersModal: FC<TypeformSelectModalProps> = ({
  closeModal,
  isOpen,
  data: {
    title,
    formConfigs,
    onFormSelect
  }
}) => {
  const formSelect = (formId: string): void => {
    onFormSelect(formId);
    closeModal();
  };

  return (
    <GenericModal
      title={title}
      closeModal={closeModal}
      modalOpen={isOpen}
      shouldHideFooter={true}
      width={450}
      innerComponent={(
        <TypeformList
          formConfigs={formConfigs}
          onFormSelect={formSelect}
        />
      )}
    />
  );
};

export default CatalogFiltersModal;

import {
  memo,
  useState,
  useRef,
  useEffect,
  useMemo
} from 'react';

import {
  inject,
  observer
} from 'mobx-react';
import pluralize from 'pluralize';
import {
  connectStats,
  StatsProvided,
  CurrentRefinementsProvided,
  connectCurrentRefinements
} from 'react-instantsearch-core';

import WholesaleFiltersStore from 'stores/wholesale/wholesale-filters-store/wholesale-filters-store';

import {
  Wrapper,
  Title,
  ClearButton,
  ResultCountText
} from './catalog-filters-header.styles';

interface FiltersModalHeaderProps extends CurrentRefinementsProvided {
  wholesaleFiltersStore?: WholesaleFiltersStore;
}

export const ResultsCount = connectCurrentRefinements(connectStats(memo<StatsProvided & CurrentRefinementsProvided>(({
  nbHits,
  items
}: StatsProvided & CurrentRefinementsProvided) => {
  const [showCounter, setShowCounter] = useState<boolean>(false);
  const previousHitsNumber = useRef<number>(nbHits);

  useEffect(() => {
    const isFilterApplied = items.filter(refinementItem => refinementItem.attribute !== 'type').length;

    if (isFilterApplied && previousHitsNumber.current !== nbHits) {
      setShowCounter(true);
    }

    if (!isFilterApplied) {
      setShowCounter(false);
    }
    previousHitsNumber.current = nbHits;
  }, [nbHits, items]);

  if (!showCounter) return null;

  return (
    <ResultCountText>{`${nbHits} ${pluralize('result', nbHits)}`}</ResultCountText>
  );
})));

export const FiltersModalHeader = inject((stores: FxStores) => ({
  wholesaleFiltersStore: stores.wholesaleFiltersStore
}))(observer(connectCurrentRefinements(memo<FiltersModalHeaderProps>(({
  items,
  refine,
  wholesaleFiltersStore
}) => {
  const hasAppliedFilters = useMemo((): boolean => {
    return !!items.filter(refinementItem => refinementItem.attribute !== 'type').length;
  }, [items]);

  const appliedFiltersExcludingType = useMemo((): any[] => {
    return items.filter(refinementItem => refinementItem.attribute !== 'type');
  }, [items]);

  const handleClearFilters = (): void => {
    refine(appliedFiltersExcludingType);
    wholesaleFiltersStore?.clearFiltersSearch();
  };

  return (
    <Wrapper>
      <Title>
        Filter
      </Title>
      {hasAppliedFilters && (
        <ClearButton
          as="button"
          onClick={handleClearFilters}
        >
          Clear all
        </ClearButton>
      )}
    </Wrapper>
  );
}))));

import { FC, Fragment, useContext } from 'react';

import styled from '@emotion/styled';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  ListItemType,
  WholesalePreOrderItem
} from 'generated-types.d';

import SelectedWholesalePreOrderStore from 'stores/selected-wholesale-pre-order/selected-wholesale-pre-order-store';

import { colors } from 'utils/rebass-theme';

import { ListHeadings } from 'features/lists/components/list-headings/list-headings';
import { ItemsWrapper } from 'features/lists/components/single-list/components/single-list-body/single-list-body.styles';
import { groupListItemsByType } from 'features/lists/lists.helpers';
import {
  ListGroupNameHeading,
  ListHeadingsWrapper,
  ListScrollWrapper
} from 'features/lists/lists.styles';

import { SINGLE_PRE_ORDER_LIST_FIELD_CONFIG } from '../single-pre-order.config';
import { SingleWholesalePreOrderContext } from '../single-pre-order.context';

import { SinglePreOrderItem } from './single-pre-order-item';

export const DeleteWrapper = styled(Flex)`
  border-radius: 30px;
  padding: 9px;
  transition: all .2s ease-in-out;
  position: absolute;
  right: 20px;

  &:hover, &:focus {
    background: ${colors.paleGrey};
  }
`;

export const SinglePreOrderItems: FC<{
  selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
}> = inject((stores: FxStores) => ({
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore
}))(observer(({
  selectedWholesalePreOrderStore
}) => {
  const { listType } = useContext(SingleWholesalePreOrderContext);
  const totals = selectedWholesalePreOrderStore!.preOrder?.totals;

  if (!selectedWholesalePreOrderStore!.preOrder || !totals) return null;

  const groupedItems = groupListItemsByType<WholesalePreOrderItem>(
    selectedWholesalePreOrderStore!.preOrder.items,
    item => item.listItem
  );

  return (
    <Fragment>
      {
        Object.keys(groupedItems).map(key => {
          const config = groupedItems[key as ListItemType];

          if (!config?.items?.length) return null;

          return (
            <Box
              mb={20}
              key={config.title}
            >
              <ListGroupNameHeading>
                {config.title}
              </ListGroupNameHeading>
              <ItemsWrapper>
                <ListScrollWrapper>
                  <ListHeadingsWrapper>
                    <ListHeadings
                      listType={listType}
                      categoryScope={key as ListItemType}
                      config={SINGLE_PRE_ORDER_LIST_FIELD_CONFIG}
                    />
                  </ListHeadingsWrapper>
                  <Box>
                    {
                      config?.items?.map?.((lineItem: WholesalePreOrderItem) => {
                        return (
                          <SinglePreOrderItem
                            listType={listType}
                            key={lineItem.id}
                            item={lineItem}
                          />
                        );
                      })
                    }
                  </Box>
                </ListScrollWrapper>
              </ItemsWrapper>
            </Box>

          );
        })
      }
    </Fragment>
  );
}));

import { ApolloQueryResult, FetchResult, ApolloError } from '@apollo/client';
import { AddonCategory, Addon } from 'generated-types';
import store from 'stores';

import { GraphQL } from 'lib';

import {
  AddOnsGroupOption
} from 'stores/add-ons-store/add-ons-store.types';

import { AddOnQuickEditData } from 'features/add-ons/add-ons.types';
import {
  ADD_ON_EDIT
} from 'features/add-ons/graphql/mutations/create-edit-add-on.mutations';
import {
  ADD_ON_CATEGORIES_QUERY
} from 'features/add-ons/graphql/queries/add-ons-categories.queries';
import {
  ALL_ADD_ONS,
  PAGINATE_ADD_ONS
} from 'features/add-ons/graphql/queries/add-ons.queries';

const addOnsQueries: { [key in AddOnsGroupOption]: any } = {
  allAddOns: ALL_ADD_ONS
};

class AddOnsAPIService {
  private AddOnsStore = store.addOnsStore;

  private ToasterStore = store.toasterStore;

  public fetchAddOns = async (variables: any): Promise<ApolloQueryResult<any>> => {
    return GraphQL.query(addOnsQueries[this.AddOnsStore.currentGroup](variables), variables, 'no-cache')
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.AddOnsStore.loadingError(error);
      });
  };

  public fetchAddOnsCategories = async (): Promise<AddonCategory[]> => {
    return GraphQL.query(ADD_ON_CATEGORIES_QUERY, { active: true })
      .then(({ data }: any) => data!.addonCategoriesConnection);
  };

  public quickEditAddOn = async (variables: AddOnQuickEditData, addOnId: string): Promise<Addon> => {
    return GraphQL.mutate(ADD_ON_EDIT, {
      data: variables,
      addOnId: addOnId
    })
      .then(({ data }: FetchResult<{ editAddOn: Addon }>): any => data!.editAddOn)
      .catch(error => {
        this.AddOnsStore.loadingError(error);
      });
  };

  public toggleActiveStatus = async (addOnId: string, isActive: boolean): Promise<Addon> => {
    const optimisticResponse = {
      __typename: 'Mutation',
      editAddOn: {
        __typename: 'Addon',
        id: addOnId,
        active: isActive
      }
    };

    return GraphQL.mutate<{ editAddOn: Addon }>(
      ADD_ON_EDIT,
      {
        addOnId: addOnId,
        data: {
          active: isActive
        }
      },
      optimisticResponse as any
    )
      .then(({ data: addOnData }) => addOnData!.editAddOn)
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('your product', isActive ? 'enable' : 'disable');
        this.AddOnsStore.loadingError(error);

        return Promise.reject(error);
      });
  };

  public paginateAddOns = async (variables: any): Promise<ApolloQueryResult<any>> => {
    return GraphQL.query(PAGINATE_ADD_ONS(variables), variables)
      .then((data: any) => {
        return data;
      })
      .catch(error => {
        this.AddOnsStore.loadingError(error);
      });
  };
}

export default new AddOnsAPIService();

import React, { FC } from 'react';

import { css } from '@emotion/react';

import { DatoCmsFullBleedText } from 'gatsby-types.d';

import { colors } from 'utils/rebass-theme';

import MarketingSiteCurve from 'assets/icons/bubbles/marketing-curve-1.svg';

import {
  WaveSpacerProps
} from './wave-spacer.types';

const getColour = (option: DatoCmsFullBleedText['backgroundColour']): string | null => {
  switch (option) {
    case 'dark-blue': {
      return colors.floomMidnightBlue;
    }

    case 'light-orange': {
      return '#FEE2D7';
    }

    case 'light-blue': {
      return '#BFE1ED';
    }

    default: {
      return null;
    }
  }
};

export const WaveSpacer: FC<WaveSpacerProps> = ({ data }) => {
  return (
    <MarketingSiteCurve
      css={css`
        ${data.waveFill === 'bottom' && `
          transform: rotate(180deg);
        `}
        ${data.waveStyle === 'B' && `
          transform: translateX(-45%) translateY(-55px);
        `}
        background-color: ${getColour(data.upperColour || '')};
        vertical-align: bottom;
        width: 200%;
        path:first-of-type {
          fill: ${getColour(data.lowerColour)};
        }
      `}
    />
  );
};

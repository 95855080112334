/* eslint-disable react/no-unused-state */

import React, { Component } from 'react';

import { css } from '@emotion/react';
import { CardCVCElement, CardNumberElement, CardExpiryElement, injectStripe } from 'react-stripe-elements';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import ErrorMessage from 'components/error-message';
import FieldText from 'components/field-text';

import * as Styles from './stripe-form-fields.styles';
import * as Types from './stripe-form-fields.types';

class StripeFormFields extends Component<Types.StripeFormFieldsProps & Partial<stripe.Stripe>, Types.StripeFormFieldsState> {
  state = {
    nameOnCard: '',
    card: null,
    validation: null,
    hasAttemptedSubmit: false,
    isSubmmitting: false,
    paymentSubmissionError: null
  };

  componentDidMount = (): void => {
    if (this.props.stripe) {
      this.props.transportStripe(this.props.stripe);
    }
  };

  subscribeToChanges = (el: stripe.elements.Element): void => {
    el.on('change', (event): void => {
      if (event) {
        this.props.updateStripeValidation(event);
      }
    });
  };

  cardElementStyles = (): stripe.elements.ElementsOptions['style'] => {
    return {
      base: {
        'fontSize': '14px',
        'lineHeight': '20px',
        'fontFamily': 'Roboto Mono',
        'color': colors.black,
        '::placeholder': {
          color: colors.shade40
        }
      },
      invalid: {
        iconColor: colors.errorText,
        color: colors.errorText
      },
      complete: {
        color: colors.validationText
      }
    };
  };

  displayError = (type: stripe.elements.elementsType): JSX.Element | null => {
    if (!this.props.validation || !this.props.validation![type]) return null;

    // @ts-ignore
    if (this.props.validation![type].error) {
      return (
        <div
          css={css`
            position: absolute;
            width: 100%;
          `}
        >
          <ErrorMessage
            // @ts-ignore
            errorMessage={this.props.validation![type].error.message}
          />
        </div>
      );
    }

    return null;
  };

  render(): JSX.Element {
    return (
      <>
        <Styles.InputWrapper>
          <Styles.InputLabel>
            Name on card
          </Styles.InputLabel>
          <FieldText
            size="normal"
            value={this.props.nameOnCard}
            isValid={!!this.props.nameOnCard.length}
            onChange={this.props.onNameOnCardChange}
            type="text"
            placeholder="Name"
          />
        </Styles.InputWrapper>
        <Styles.InputWrapper>
          <Styles.InputLabel>
            Card number
          </Styles.InputLabel>
          <Styles.StripeInputWrapper showTick={true}>
            <CardNumberElement
              placeholder=""
              style={this.cardElementStyles()}
              onReady={(el): void => {
                this.props.storeCardInstance(el);
                this.subscribeToChanges(el);
              }}
            />
          </Styles.StripeInputWrapper>
          {this.displayError('cardNumber')}
        </Styles.InputWrapper>
        <Flex>
          <Flex
            mr="auto"
            width="100px"
          >
            <Styles.InputWrapper>
              <Styles.InputLabel>
                CVC
              </Styles.InputLabel>
              <Styles.StripeInputWrapper
                width="90px"
                showTick={false}
              >
                <CardCVCElement
                  style={this.cardElementStyles()}
                  onReady={(el): void => {
                    this.subscribeToChanges(el);
                  }}
                />
              </Styles.StripeInputWrapper>
              {this.displayError('cardCvc')}
            </Styles.InputWrapper>
          </Flex>
          <Styles.InputWrapper>
            <Styles.InputLabel>
              Expiration date
            </Styles.InputLabel>
            <Styles.StripeInputWrapper showTick={false}>
              <CardExpiryElement
                style={this.cardElementStyles()}
                onReady={(el): void => {
                  this.subscribeToChanges(el);
                }}
              />
            </Styles.StripeInputWrapper>
            {this.displayError('cardExpiry')}
          </Styles.InputWrapper>
        </Flex>
      </>
    );
  }
}

// @ts-ignore
export default injectStripe(StripeFormFields);

import React, { memo, ReactNode, useContext } from 'react';

import { Box, Text } from 'rebass';

import { CurrencyService } from 'lib';

import { Card } from 'utils/css-mixins';
import { textStyles } from 'utils/rebass-theme';

import {
  Metadata,
  MetadataItem,
  MetadataTitle,
  MetadataValue
} from 'components/metadata/metadata.styles';

import { CreditOverviewContext } from './credit-overview';

export const CreditDetails = memo(() => {
  const {
    isLoading,
    merchant,
    unpaidBalance
  } = useContext(CreditOverviewContext);

  const renderUnpaidBalance = (): ReactNode => {
    switch (true) {
      case isLoading: {
        return 'Loading...';
      }

      case !isLoading && (typeof unpaidBalance !== 'number'): {
        return 'Unknown';
      }

      default: {
        return CurrencyService.formatPrice(
          unpaidBalance!,
          merchant!.currency
        );
      }
    }
  };

  return (
    <Box
      css={Card}
      p="30px"
    >
      <Text
        css={textStyles.h3}
        mb="20px"
      >
        Credit details
      </Text>
      <Metadata>
        <MetadataItem>
          <MetadataTitle>
            Credit Limit
          </MetadataTitle>
          <MetadataValue>
            {CurrencyService.formatPrice(
              merchant!.subscription?.wholesaleCreditLimit || 0,
              merchant!.currency
            )}
          </MetadataValue>
        </MetadataItem>
        <MetadataItem>
          <MetadataTitle>
            Payment terms
          </MetadataTitle>
          <MetadataValue>
            {merchant?.subscription?.wholesaleTermLength} days
          </MetadataValue>
        </MetadataItem>
        <MetadataItem>
          <MetadataTitle>
            Unpaid balance
          </MetadataTitle>
          <MetadataValue>
            {renderUnpaidBalance()}
          </MetadataValue>
        </MetadataItem>
      </Metadata>
    </Box>
  );
});

import { css } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Container = styled(Flex)`
  position: relative;
  align-items: stretch;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const IconClose: StyledComponent<any, any, any> = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
`;

export const ButtonContainer: StyledComponent<any, any, any> = styled(Box)`
  width: 100%;
  margin-top: 100px;
`;

export const Title = css`
  ${textStyles.title};
  max-width: 245px;
`;

// TODO: this appears in 3 places now which means we should abstract it to root components
export const ColourDot: any = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background: ${(props: any): any => props.colour ? props.colour : 'transparent'};
  display: block;
  border: ${colors.paleGrey} 1px solid;
`;

import { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { OrderDeliveryState } from 'generated-types.d';

import { colors } from 'utils/rebass-theme';

import { CourierStatusIndicatorConfigItem } from 'features/courier/courier.types';
import OrderHelpers from 'features/orders/helpers/order-helpers';

import Icon from 'components/icon';
import StyledLink from 'components/styled-link/styled-link';

import {
  retrieveCourierStatusConfig,
  buildPickupTime
} from './courier-status-indicator.helpers';
import * as Styles from './courier-status-indicator.styles';
import * as Types from './courier-status-indicator.types';

class CourierStatusIndicator extends Component<Types.CourierStatusIndicatorProps> {
  static defaultProps = {
    hasIcon: false,
    hasDefault: false
  };

  private DATE_STRING_REPLACER: string = '[TIMEDATE]';

  private DEFAULT_STATUS: string = 'FloomX Courier';

  private IS_NATIONWIDE: boolean = !!this.props.orderData.nationwide === true;

  private hasPickupDate = (): boolean => this.props.orderData.courierBooking?.pickupDate;

  private isDefault = (config: CourierStatusIndicatorConfigItem): boolean => config.shouldRespectDefault && this.props.hasDefault;

  private displayPickupTimeCopy = (copy: string, date: Date): string => copy.replace(this.DATE_STRING_REPLACER, `${buildPickupTime(date)}`);

  private renderDeliverAtTime = (): string => {
    const deliverAt = OrderHelpers.renderDeliveryTime(this.props.orderData);

    return (this.IS_NATIONWIDE) ? `${deliverAt}, Nationwide` : deliverAt;
  };

  private renderCourierStatus = (config: CourierStatusIndicatorConfigItem, date: Date): string => {
    switch (true) {
      case this.isDefault(config):
        return this.DEFAULT_STATUS;

      case config.copy.includes(this.DATE_STRING_REPLACER):
        return this.displayPickupTimeCopy(config.copy, date);

      default:
        return config.copy;
    }
  };

  private triggerModal = (): void => {
    if (!!this.props.orderData.courierBooking) {
      this.props.modalStore!.triggerModal({
        modalType: 'courierStatus',
        data: this.props.orderData
      });
    }
  };

  private renderCourierStatusCopy = (): JSX.Element => {
    const { deliveryStatus } = this.props.orderData.courierBooking!;
    const statusConfig = retrieveCourierStatusConfig(deliveryStatus);

    return (
      <Box
        id="courier-status-indicator-button"
        onClick={this.triggerModal}
        css={Styles.IndicatorLink}
      >
        <Flex alignItems="center">
          {this.renderIcon()}
          <Box>
            <StyledLink
              color={colors[statusConfig.textColor]}
            >
              {this.renderCourierStatus(statusConfig, this.props.orderData.courierBooking![statusConfig.dateKey])}
            </StyledLink>
          </Box>
        </Flex>
      </Box>
    );
  };

  private renderIcon = (): JSX.Element | null => {
    const { courierBooking } = this.props.orderData;
    const config = retrieveCourierStatusConfig(courierBooking ? courierBooking.deliveryStatus : OrderDeliveryState.None);

    if (!this.props.hasIcon) return null;

    return (
      <Styles.CourierStatusIconWrapper>
        <Icon
          iconName={config.iconType}
          pathFill={this.isDefault(config) ? colors.floomMidnightBlue : colors[config.iconColor]}
        />
      </Styles.CourierStatusIconWrapper>
    );
  };

  render = (): ReactNode => this.hasPickupDate() ? this.renderCourierStatusCopy() : this.renderDeliverAtTime();
}

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore
}))(observer(CourierStatusIndicator));

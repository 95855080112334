import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { HTMLService } from 'lib';

import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import { NotificationType } from 'components/notification/notification.types';

import CreateVariationMediaUploader from '../create-variation-tab/create-media-uploader';
import Components from '../create-variation-tab/inner-tab-components/inner-tab-components';

import * as Styles from './collection-product-content.styles';
import * as Types from './collection-product-content.types';

class CreateProductTab extends Component<Types.CreateProductTabProps> {
  private buildForm = (): FormBuilderConfig => {
    const selectedVariation = this.props.productEditStore!.variations.find(variation => variation.id === this.props.productEditStore!.currentVariationId);

    return {
      sections: [
        {
          width: '100',
          fields: [
            {
              key: 'productDescriptionLabel',
              fieldType: 'label',
              copy: 'Description',
              subCopy: 'This is what the customer will see on floom.com',
              validationError: undefined
            },
            {
              key: 'productDescription',
              fieldType: 'copy',
              validationError: undefined,
              copy: (
                <Styles.TextBox
                  dangerouslySetInnerHTML={{
                    __html: HTMLService.newLineToBreak(this.props.productEditStore!.description)
                  }}
                />
              )
            }
          ]
        },
        {
          width: '100',
          isHidden: this.props.productEditStore!.variations.length === 1,
          fields: [
            {
              key: 'productVariationSelection',
              fieldType: 'label',
              copy: 'Variations',
              subCopy: 'Select the variation you would like to see',
              validationError: undefined
            },
            {
              key: 'discountTypeField',
              fieldType: 'radioList',
              itemType: 'card',
              itemTitleField: 'title',
              itemValueField: 'value',
              orientation: 'horizontal',
              items: this.props.productEditStore!.variations.map(variation => {
                return {
                  title: variation.type,
                  value: variation.id
                };
              }),
              selectedItem: this.props.productEditStore!.currentVariationId,
              onChange: (variationId): any => {
                this.props.productEditStore!.setCurrentVariation(variationId);
              }
            }
          ]
        },
        {
          width: '100',
          fields: [
            {
              key: 'productMediaLabel',
              fieldType: 'label',
              copy: `Images for ${selectedVariation!.type}`,
              validationError: undefined
            },
            {
              key: 'productMedia',
              fieldType: 'custom',
              customContent: ((): ReactNode => {
                return (
                  <CreateVariationMediaUploader
                    mediaUploads={selectedVariation!.mediaUploads}
                    isReadOnly={true}
                  />
                );
              })()
            }
          ]
        },
        {
          width: '100',
          isHidden: !this.props.productEditStore!.internalDescription.trim().length,
          fields: [
            {
              key: 'productInternalDescriptionLabel',
              fieldType: 'label',
              copy: 'Recipe requirements',
              subCopy: 'Please refer to the below list of exact stem numbers and types to recreate this product.',
              validationError: undefined
            },
            {
              key: 'productDescription',
              fieldType: 'copy',
              validationError: undefined,
              copy: (
                <Styles.TextBox
                  dangerouslySetInnerHTML={{
                    __html: HTMLService.newLineToBreak(this.props.productEditStore!.internalDescription)
                  }}
                />
              )
            }
          ]
        },
        {
          width: '100',
          isHidden: !selectedVariation?.selectedComponentConfigs?.length,
          fields: [
            {
              key: 'productStemConfig',
              fieldType: 'custom',
              customContent: !!selectedVariation && (
                <Components
                  variation={selectedVariation}
                  copy="What you need to build this product"
                  isReadOnly={true}
                />
              )
            }
          ]
        }
      ]
    };
  };

  render(): React.ReactNode {
    return (
      <Styles.Content>
        <FormBuilder
          config={this.buildForm()}
          notification={{
            hasNotification: false,
            notificationProps: {
              copy: '',
              type: NotificationType.Error
            }
          }}
        />
      </Styles.Content>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore
}))(observer(CreateProductTab));

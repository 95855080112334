import React, { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  DeliveryConfig,
  Channel,
  PlanFeature
} from 'generated-types.d';

import {
  CacheService,
  MerchantService,
  PeakFeatureToggle,
  PermissionsService
} from 'lib';

import {
  gridGutter
} from 'utils/rebass-theme';

import DeliveryService from 'features/settings/components/delivery/services';
import SettingsTemplatePage from 'features/settings/components/template/settings-template-page';
import { SettingsTemplateProps } from 'features/settings/components/template/settings-template-page.types';

import DeliveryConfigCard from 'components/delivery-config-card';
import DropdownNative from 'components/dropdown-native';
import GenericModal from 'components/generic-modal';
import NoResultsGeneric from 'components/no-results-generic';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import SectionHeading from 'components/section-heading';
import WithLoading from 'components/with-loading';

import * as Constants from './delivery-details.constants';
import * as Styles from './delivery-details.styles';
import * as Types from './delivery-details.types';
import * as DeliveryStyles from './delivery.styles';

class Delivery extends Component<Types.DeliveryProps> {
  state = {
    createModalOpen: false
  };

  hasInitialised = false;

  componentDidMount(): void {
    DeliveryService.initConfigs();
  }

  private closeCreateModal = (): void => {
    this.setState({
      createModalOpen: false
    });
  };

  private openCreateModal = (): void => {
    if (!PeakFeatureToggle.isDisabledDuringPeak()) {
      this.setState({
        createModalOpen: true
      });
    } else {
      this.props.toasterStore!.popNotificationToast(PeakFeatureToggle.setFeatureToggleNotification());
    }
  };

  private openModal = (deliveryConfig?: DeliveryConfig): void => {
    if (!PeakFeatureToggle.isDisabledDuringPeak()) {
      if (deliveryConfig) {
        this.props.deliveryStore!.loadConfigForEdit(deliveryConfig);
      } else {
        this.props.deliveryStore!.loadConfigForCreate();
      }

      this.props.modalStore!.triggerModal({
        modalType: 'deliveryConfig'
      });

      this.setState({
        createModalOpen: false
      });
    } else {
      this.props.toasterStore!.popNotificationToast(PeakFeatureToggle.setFeatureToggleNotification());
    }
  };

  private openDetailsModal = (): void => {
    this.openModal();
  };

  private isCreateDisabled = (): boolean => {
    return MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.merchantStore!.merchant!)
      && !CacheService.retrieveCookie('fxSuperEngineer', true);
  }

  private buildMerchantConfigs = (): any => {
    return this.props.deliveryStore!.deliveryConfigs.reduce((acc: any, curr: DeliveryConfig, currIndex: number) => {
      if (currIndex === 0 || !acc[curr.merchant.id]) {
        return {
          ...acc,
          [curr.merchant.id]: {
            merchantName: curr.merchant.title,
            floomConfigs: curr.channel === Channel.Floom ? [curr] : [],
            customConfigs: curr.channel === Channel.Website ? [curr] : []
          }
        };
      }

      if (acc[curr.merchant.id]) {
        const currMerchant = acc[curr.merchant.id];

        acc[curr.merchant.id] = {
          ...currMerchant,
          floomConfigs: curr.channel === Channel.Floom ? [...currMerchant.floomConfigs, curr] : [...currMerchant.floomConfigs],
          customConfigs: curr.channel === Channel.Website ? [...currMerchant.customConfigs, curr] : [...currMerchant.customConfigs]
        };
      }

      return acc;
    }, {});
  };

  private renderConfigGroups = (configs: any, heading: string, removeMargin = false): JSX.Element => {
    return (
      <Box
        width={1}
        mt={removeMargin ? '0' : '30px'}
      >
        <DeliveryStyles.ConfigSubTitle>
          {heading}
        </DeliveryStyles.ConfigSubTitle>
        <Flex
          m={`5px ${-gridGutter / 2}px 0`}
          flexWrap="wrap"
        >
          {
            configs.map((config: any) => (
              <Box
                key={config.id}
                width={[1, 1, 1 / 2, 1 / 3, 1 / 4]}
                p={gridGutter / 2}
              >
                <DeliveryConfigCard
                  deliveryConfig={config}
                  onClick={this.openModal}
                />
              </Box>
            ))
          }
        </Flex>
      </Box>
    );
  };

  private renderModals = (): ReactNode => (
    <>
      <GenericModal
        width={430}
        confirmButtonAction={this.openDetailsModal}
        confirmButtonText="Start creating"
        title="Select a channel"
        modalOpen={this.state.createModalOpen}
        closeModal={this.closeCreateModal}
        confirmButtonDisabled={!this.props.userStore!.merchantId
          || !this.props.deliveryStore!.deliveryPricings.length
          || !this.props.deliveryStore!.selectedChannel}
        innerComponent={(
          <DeliveryStyles.SelectChannelWrapper>
            {
              !this.props.userStore!.merchantId
                ? <p>{Constants.SELECT_MERCHANT_ERROR}</p>
                : !this.props.deliveryStore!.deliveryPricings.length
                  ? <p>{Constants.MISSING_CONFIGURATION}</p>
                  : (
                    <DropdownNative
                      placeholder="Select a channel"
                      optionTitleField="title"
                      optionValueField="value"
                      id="select-product-type"
                      selected={this.props.deliveryStore!.selectedChannel}
                      options={DeliveryService.getChannels()}
                      onChange={(x: any): void => this.props.deliveryStore!.updateBasicValue('selectedChannel', x)}
                      fullWidth={true}
                    />
                  )
            }
          </DeliveryStyles.SelectChannelWrapper>
        )}
      />
    </>
  );

  private renderConfigCards = (): ReactNode => {
    const merchantConfigs = this.buildMerchantConfigs();

    return (
      <DeliveryStyles.CardsWrapper mt="50px">
        { merchantConfigs &&
          Object.keys(merchantConfigs).map((merchantId, index) => (
            <Fragment key={merchantId}>
              { !this.props.userStore!.merchantId && (
                <Box
                  width={1}
                  mt={index !== 0 ? '40px' : '0'}
                >
                  <SectionHeading
                    title={merchantConfigs[merchantId].merchantName}
                    hasSpacing={false}
                  />
                </Box>
              )}
              { !!merchantConfigs[merchantId].floomConfigs.length || !!merchantConfigs[merchantId].customConfigs.length ? (
                <>
                  { !!merchantConfigs[merchantId].floomConfigs.length &&
                    this.renderConfigGroups(merchantConfigs[merchantId].floomConfigs, 'floom.com', !!this.props.userStore!.merchantId)
                  }
                  { !!merchantConfigs[merchantId].customConfigs.length &&
                    this.renderConfigGroups(merchantConfigs[merchantId].customConfigs, this.props.merchantStore!.customSiteUrlDisplay || 'Custom Website')
                  }
                </>
              )
                :
                <Styles.NoDeliveryConfigs>Merchant has no delivery configs</Styles.NoDeliveryConfigs>
              }
            </Fragment>
          ))
        }
      </DeliveryStyles.CardsWrapper>
    );
  };

  private renderNoResults = (): ReactNode => {
    return (
      <Box mt="40px">
        { (!this.props.merchantStore!.merchant && PermissionsService.isInternalRole()) && (
          <Box mb="20px">
            <Notification
              type={NotificationType.Error}
              hasIcon={false}
              hasClose={false}
              copy="Looks like you don't have a merchant selected. Please select one to see their configs"
            />
          </Box>
        )}
        <NoResultsGeneric
          icon="leaf-no-results"
          heading="No delivery configs"
          copy="You do not have any delivery configs currently!"
        />
      </Box>
    );
  };

  render(): React.ReactNode {
    return (
      <SettingsTemplatePage
        title="Delivery"
        {...(!this.isCreateDisabled() ? {
          headerButtonCopy: 'Create delivery option',
          onHeaderAction: this.openCreateModal
        } as Partial<SettingsTemplateProps> : {})}
      >
        <WithLoading
          hasNoResults={this.props.deliveryStore!.deliveryConfigs && !this.props.deliveryStore!.deliveryConfigs.length}
          isLoading={this.props.deliveryStore!.isLoadingDeliveryConfigs}
          renderNoResults={this.renderNoResults}
        >
          {this.renderConfigCards()}
        </WithLoading>
        {this.renderModals()}
      </SettingsTemplatePage>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  deliveryStore: stores.deliveryStore,
  userStore: stores.userStore,
  merchantStore: stores.merchantStore,
  toasterStore: stores.toasterStore,
  modalStore: stores.modalStore
}))(observer(Delivery));

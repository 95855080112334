import { ApolloError } from '@apollo/client';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { TestMessageParams } from 'yup';

import {
  AdjusterTier,
  AdjusterType,
  MerchantSupplierIntegration,
  Supplier,
  SupplierDeliveryHub,
  WholesaleDeliveryConfig,
  WholesaleFeatureType,
  SupplierWhereInput
} from 'generated-types.d';

import { MerchantSupplierIntegrationService } from 'lib';

import { SuppliersService } from 'features/suppliers/services';

export interface AdjusterTierFormValues {
  id: string;
  min: string;
  max: string;
  adjusterId?: string;
  adjusterPercent: string;
  adjusterAppliesTo: AdjusterType;
  pendingOperation: 'create' | 'update' | 'delete' | null;
}

export interface SupplierIntegratedMerchant extends Pick<Supplier,
  | 'active'
  | 'id'
  | 'integrationType'
  | 'name'
  > {
  integration: Pick<MerchantSupplierIntegration,
    | 'id'
    | 'active'
    | 'supplier'
    | 'authentication'
    > | null;
}

export type SupplierIntegrationAuth = FlorisoftIntegrationAuth;

export interface FlorisoftIntegrationAuth {
  externalId: string;
}

export default class WholesaleSettingsStore {
  constructor() {
    makeObservable(this, {
      hasError: observable,
      errorMsg: observable,
      isLoadingConfigs: observable,
      isLoadingTiers: observable,
      isLoadingSupplierIntegrations: observable,
      deliveryConfigs: observable,
      validationErrors: observable,
      wholesaleTiers: observable,
      wholesaleGMV: observable,
      supplierIntegrations: observable,
      setWholesaleTiers: action,
      setWholesaleGMV: action,
      resetWholesaleTiers: action,
      resetStore: action,
      setDeliveryConfig: action,
      setDeliveryConfigs: action,
      loadingError: action
    });
  }

  public hasError: boolean = false;

  public errorMsg: ApolloError | boolean = false;

  public deliveryConfigs: WholesaleDeliveryConfig[] = [];
  public supplierDeliveryHubs: SupplierDeliveryHub[] = [];
  public wholesaleTiers: AdjusterTierFormValues[] = [];
  public wholesaleGMV: number | null = null;
  public isLoadingConfigs: boolean = true;
  public isLoadingTiers: boolean = true;
  public validationErrors: [TestMessageParams[]] = [[]];
  public supplierIntegrations: SupplierIntegratedMerchant[] = [];
  public isLoadingSupplierIntegrations: boolean = true;

  public resetStore = (): void => {
    this.deliveryConfigs = [];
    this.supplierDeliveryHubs = [];
    this.wholesaleTiers = [];
    this.isLoadingConfigs = true;
    this.isLoadingTiers = true;
    this.errorMsg = false;
    this.hasError = false;
    this.validationErrors = [[]];
    this.supplierIntegrations = [];
    this.isLoadingSupplierIntegrations = true;
  };

  public resetWholesaleTiers = (): void => {
    this.wholesaleTiers = [];
    this.isLoadingTiers = true;
    this.validationErrors = [[]];
  };

  public loadingError = (error: any): void => {
    this.hasError = true;
    this.isLoadingConfigs = false;
    this.isLoadingTiers = false;
    this.errorMsg = error;
  };

  public fetchSupplierDeliveryHubs = async (): Promise<void> => {
    try {
      const hubs = await SuppliersService.fetchSupplierDeliveryHubs();

      runInAction(() => {
        this.supplierDeliveryHubs = hubs;
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public setDeliveryConfigs = (configs: WholesaleDeliveryConfig[]): void => {
    this.isLoadingConfigs = false;
    this.deliveryConfigs = configs;
  };

  public setDeliveryConfig = (configId: string, updatedConfig: WholesaleDeliveryConfig): void => {
    const configIndex = this.deliveryConfigs.findIndex(c => c.id === configId);

    this.deliveryConfigs[configIndex] = updatedConfig;
  };

  public setErrors = (errors: TestMessageParams[] = [], index: number): void => {
    this.validationErrors[index] = errors;
  };

  public setWholesaleGMV = (wholesaleGMV: number): void => {
    this.wholesaleGMV = wholesaleGMV;
  };

  public createWholesaleTier = ({ tier }: { tier: AdjusterTierFormValues }): void => {
    this.wholesaleTiers.push({
      ...tier,
      pendingOperation: null
    });
  };

  public updateWholesaleTier = ({ tier }: { tier: AdjusterTierFormValues }): void => {
    const index = this.wholesaleTiers.findIndex(existingTier => existingTier.id === tier.id);

    this.wholesaleTiers[index] = {
      ...tier,
      pendingOperation: null
    };
  };

  public setWholesaleTiers = (wholesaleTiers: AdjusterTier[]): void => {
    this.isLoadingTiers = false;

    if (wholesaleTiers.length) {
      this.wholesaleTiers = wholesaleTiers.map((tier: AdjusterTier) => ({
        id: tier.id?.toString(),
        min: tier.min?.toString() || '0',
        max: tier.max?.toString() || '0',
        adjusterId: tier.adjusters![0]?.id?.toString() || undefined,
        adjusterPercent: tier.adjusters![0]?.percent ? tier.adjusters![0]?.percent?.toString() : '0',
        adjusterAppliesTo: tier.adjusters![0]?.appliesTo || AdjusterType.WholesaleGmv,
        pendingOperation: null
      }));
    } else {
      this.wholesaleTiers = [];
    }
  };

  public deleteWholesaleTier = (tierId: string): void => {
    const itemIndex = this.wholesaleTiers.findIndex(tier => tier.id === tierId);

    if (itemIndex !== -1) {
      this.wholesaleTiers.splice(itemIndex, 1);
    }
  };

  public fetchSupplierIntegrations = async (merchantId: string): Promise<void> => {
    const liveOrderingSuppliersWhere: SupplierWhereInput = {
      wholesaleFeatures_some: {
        type: WholesaleFeatureType.LiveOrder
      }
    };

    Promise.all([
      SuppliersService.fetchSuppliers(liveOrderingSuppliersWhere),
      MerchantSupplierIntegrationService.retrieveIntegrations(merchantId)
    ]).then(([suppliers, integrations]) => {
      const suppliersIntegratedWitMerchant: SupplierIntegratedMerchant[] = suppliers?.map((supplier: Supplier) => {
        const merchantIntegrationForSupplier = integrations.find((integration: MerchantSupplierIntegration) => {
          return integration.supplier.id === supplier.id;
        });

        return {
          ...supplier,
          integration: merchantIntegrationForSupplier || null
        };
      });

      runInAction(() => {
        this.supplierIntegrations = suppliersIntegratedWitMerchant;
        this.isLoadingSupplierIntegrations = false;
      });
    });
  };

  public updateSupplierIntegration = async (input: SupplierIntegrationAuth, supplier: SupplierIntegratedMerchant): Promise<void> => {
    try {
      const supplierIntegrationUpdate = await MerchantSupplierIntegrationService.updateIntegration(input, supplier);
      this.updateSupplierIntegrationInStore([supplierIntegrationUpdate]);
    } catch(err) {
      console.error(err);
      throw new Error('Failed to update supplier integration');
    }
  };

  public createSupplierIntegration = async (input: SupplierIntegrationAuth, supplier: SupplierIntegratedMerchant, merchantId: string): Promise<void> => {
    try {
      const supplierIntegrationUpdate = await MerchantSupplierIntegrationService.createIntegration(input, supplier, merchantId);
      this.updateSupplierIntegrationInStore([supplierIntegrationUpdate]);
    } catch(err) {
      console.error(err);
      throw new Error('Failed to create supplier integration');
    }
  };

  public updateSupplierIntegrationStatus = async (status: boolean, supplier: SupplierIntegratedMerchant): Promise<void> => {
    try {
      const supplierIntegrationUpdate = await MerchantSupplierIntegrationService.changeStatus(status, supplier);
      this.updateSupplierIntegrationInStore([supplierIntegrationUpdate]);
    } catch(err) {
      console.error(err);
      throw new Error('Failed to update supplier integration status');
    }
  };

  private updateSupplierIntegrationInStore = (supplierIntegrationUpdates: MerchantSupplierIntegration[]): void => {
    const suppliersIntegratedWithMerchant: SupplierIntegratedMerchant[] = this.supplierIntegrations?.map((supplierIntegration: SupplierIntegratedMerchant) => {
      const supplierIntegrationUpdated = supplierIntegrationUpdates.find(update => {
        return update.supplier.id === supplierIntegration.id;
      });

      if (supplierIntegrationUpdated) {
        return {
          ...supplierIntegration,
          integration: supplierIntegrationUpdated
        };
      }

      return supplierIntegration;
    });

    runInAction(() => {
      this.supplierIntegrations = suppliersIntegratedWithMerchant;
    });
  }
}

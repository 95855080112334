import * as Yup from 'yup';

import {
  Currency
} from 'generated-types.d';

import {
  ValidationService
} from 'lib';

import {
  MerchantFieldValues
} from 'stores/merchant-onboarding/merchant-onboarding-store.types';

export const findError = (key: keyof MerchantFieldValues, errors: Yup.TestMessageParams[]): Yup.TestMessageParams | undefined => {
  return ValidationService.findErrorCopy(errors, key);
};

export const postalCodeCopy = (currency: Currency): string => currency === Currency.Usd ? 'Zip code' : 'Postcode';

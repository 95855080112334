import React, { memo } from 'react';

import { css } from '@emotion/react';
import {
  RefinementListProvided,
  connectRefinementList
} from 'react-instantsearch-core';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

import CheckboxList from 'components/checkbox-list';

interface RefinementListProps extends RefinementListProvided {
  attribute: string;
  name: string;
  onSelect: () => void;
}

const buildItemLabel = (attribute: string, value: string): string => {
  switch (true) {
    case attribute === 'commercial' && value === 'true':
      return 'Commercial';

    case attribute === 'commercial' && value === 'false':
      return 'Residential';

    case attribute === 'commercial' && value !== 'false' && value !== 'true':

    case attribute === 'nationwide' && value !== 'false' && value !== 'true':
      return 'Unknown';

    case attribute === 'nationwide' && value === 'true':
      return 'Nationwide';

    case attribute === 'nationwide' && value === 'false':
      return 'Local';

    case attribute === 'merchant.timezone':
      return getTimeZone(value);

    default:
      return value;
  }
};

const getTimeZone = (value: string): string => {
  const zoneNames = value.split('/');

  return zoneNames[1]?.replace('_', '-') || '';
};

export const RefinementList = connectRefinementList(memo((props: RefinementListProps): JSX.Element => {
  if (!props.items.length) {
    return (
      <Box
        css={css`
          ${textStyles.body};
          display: inline-block;
          background: ${colors.lightGrey};
          padding: 10px 20px;
          border-radius: 5px;
        `}
      >
        No <strong>&apos;{props.name}&apos;</strong> filters available.
      </Box>
    );
  }

  return (
    <CheckboxList
      selectedItems={props.currentRefinement}
      itemValueField="value"
      optionTitleField="title"
      orientation="horizontal"
      items={props.items
        .sort((a, b) => a.label.localeCompare(b.label))
        .map(item => {
          return {
            title: `${buildItemLabel(props.attribute, item.label)} (${item.count})`,
            value: item.label,
            isDisabled: item.count === 0
          };
        })}
      onChange={(value): void => {
        const item = props.items.find(refinementItem => refinementItem.label === value);

        if (item) {
          props.refine(item.value);
          props.onSelect();
        }
      }}
    />
  );
}));

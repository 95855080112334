import { ApolloError } from '@apollo/client';
import {
  Order,
  CourierBooking,
  ListOfCourierDeliverySlotsOutput,
  AvailableCourierDeliveryTimeSlot,
  Maybe
} from 'generated-types';
import { observable, action, set, makeObservable } from 'mobx';

import * as Types from './courier-store.types';

export default class CourierStore implements Types.CourierStoreType {
  constructor() {
    makeObservable(this, {
      orders: observable,
      orderNumbers: observable,
      showBookingModal: observable,
      selectedDeliverySlot: observable,
      completedCourierBooking: observable,
      courierStatusModalData: observable,
      isDisplayingCourierStatusModal: observable,
      validDeliverySlots: observable,
      invalidDeliverySlots: observable,
      active: observable,
      errors: observable,
      isLoading: observable,
      openCourierStatusModal: action,
      closeCourierStatusModal: action,
      setError: action,
      setDeliverySlots: action,
      clearDeliverySlots: action,
      setOrders: action,
      updateSelectedDeliverySlot: action,
      toggleBookingModal: action,
      clearSelectedDeliverySlot: action,
      setCompletedCourierBooking: action,
      setInitialOrderNumber: action
    });
  }

  active: string = 'book';

  orderNumbers: string[] = [];

  orders: Order[] = [];

  showBookingModal: boolean = false;

  validDeliverySlots: Array<Maybe<AvailableCourierDeliveryTimeSlot>> = [];

  invalidDeliverySlots: Array<Maybe<AvailableCourierDeliveryTimeSlot>> = [];

  selectedDeliverySlot: AvailableCourierDeliveryTimeSlot | null = null;

  completedCourierBooking: CourierBooking | null = null;

  errors: { [key in Types.CourierQueryType]: ApolloError | null } = {
    fetchDeliverySlots: null,
    bookDeliverySlot: null
  };

  courierStatusModalData: CourierBooking | null = null;

  isDisplayingCourierStatusModal: boolean = false;

  isLoading: { [key in Types.CourierQueryType]: boolean } = {
    fetchDeliverySlots: false,
    bookDeliverySlot: false
  };

  updateActive = (tabIndex: string): void => {
    this.active = tabIndex;
  };

  clearCourierStore = (): void => {
    this.active = 'book';
    this.orders = [];
    this.orderNumbers = [];
    this.validDeliverySlots = [];
    this.invalidDeliverySlots = [];
    this.showBookingModal = false;
    this.selectedDeliverySlot = null;
    this.completedCourierBooking = null;

    this.errors = {
      fetchDeliverySlots: null,
      bookDeliverySlot: null
    };

    this.isLoading = {
      fetchDeliverySlots: false,
      bookDeliverySlot: false
    };
  };

  clearSelectedDeliverySlot = (): void => {
    this.selectedDeliverySlot = null;
  };

  updateSelectedDeliverySlot = (deliverySlot: Types.DeliverySlot | any): void => {
    this.selectedDeliverySlot = deliverySlot;
  };

  toggleBookingModal = (): void => {
    this.showBookingModal = !this.showBookingModal;
  };

  setInitialOrderNumber = (initialOrderNumber: string): void => {
    this.orderNumbers = [
      initialOrderNumber,
      ...this.orderNumbers
    ];
  };

  setCompletedCourierBooking = (courierBooking: CourierBooking | null): void => {
    this.completedCourierBooking = courierBooking;
  };

  setOrders = (orders: Order[]): void => {
    this.orders = orders;
  };

  setError = (errorType: Types.CourierQueryType, error: boolean | ApolloError | null = null): void => {
    set(this.errors, {
      [errorType]: JSON.parse(JSON.stringify(error))
    });
  };

  setDeliverySlots = (deliverySlots: ListOfCourierDeliverySlotsOutput): void => {
    this.validDeliverySlots = deliverySlots.valid!;
    this.invalidDeliverySlots = deliverySlots.invalid!;
  };

  clearDeliverySlots = (): void => {
    this.validDeliverySlots = [];
    this.invalidDeliverySlots = [];
    this.orders = [];

    this.errors = {
      fetchDeliverySlots: null,
      bookDeliverySlot: null
    };
  };

  setLoading = (loadingType: Types.CourierQueryType, isLoading: boolean): void => {
    set(this.isLoading, {
      [loadingType]: isLoading
    });
  };

  openCourierStatusModal = (data: CourierBooking): void => {
    this.courierStatusModalData = data;
    this.isDisplayingCourierStatusModal = true;
  };

  closeCourierStatusModal = (): void => {
    this.isDisplayingCourierStatusModal = false;
  };
}

import { Fragment, useEffect, useState } from 'react';

import { BeamsPushProvider } from 'lib/services/notifications/notifications.service';
import store from 'stores';

import UserStore from 'stores/user/user-store';

const userStore = store.userStore as UserStore;

export const PushNotifications = (): any => {
  const [registrationState, setRegistrationState] = useState<string | undefined>(undefined);
  const BeamsClient = new BeamsPushProvider(userStore?.userId);
  console.log('userStore?.userId', userStore?.userId);

  useEffect(() => {
    getRegistrationState();
  }, []);

  const getRegistrationState = async (): Promise<void> => {
    const state = await BeamsClient?.getRegistrationState();
    console.log('state', state);
    setRegistrationState(state);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (registrationState === 'PERMISSION_DENIED') {
      event.preventDefault();
      alert('You need to allow notifications in the browser, for FloomX to use this feature');
    }

    if (event.target.checked) {
      BeamsClient.enableNotifications();
    } else {
      BeamsClient.disableNotifications();
    }
  };

  return (
    <div className="border-b border-slate-200 mb-6 pb-6">
      <h3 className="text-base md:text-lg mb-3">Device notifications</h3>
      <label className="text-sm cursor-pointer flex w-full gap-2 md:gap-4">
        {!!registrationState ? (
          <Fragment>
            <input
              className="mt-1 cursor-pointer"
              defaultChecked={!!(registrationState && registrationState === 'PERMISSION_GRANTED_REGISTERED_WITH_BEAMS')}
              onChange={handleChange}
              type="checkbox"
              name="push-notifications"
              id="push-notifications"
            />
            <div>
              <span className="font-bold block mb-1">Recieve notifications on this device (Only supported using Google Chrome)</span>
              <span className="text-xs md:text-sm">Receive push notifications on this device, even if you&apos;re not on the FloomX dashboard</span>
            </div>
          </Fragment>
        ) : (
          <div className="h-8 w-8 bg-gray-200 rounded-md animate-pulse" />
        )}
      </label>
    </div>
  );
};

import { gql } from '@apollo/client';

import { GraphQLHelpers, MerchantFragments } from 'lib';

import { hasFilters, mapFilters } from 'features/settings/components/merchants/merchant-controls/graphql/merchant-control-queries';
import { MerchantFilterVariables } from 'features/settings/services/merchants-settings/merchant-settings.service.types';

export const MERCHANT_LIST = (vars: MerchantFilterVariables): any => gql`
  query MerchantListQuery(
    $orderBy: MerchantOrderByInput
    $count: Int
    $first: Int
  ) {
    merchantsConnection(
      first: $first
      skip: $count
      orderBy: $orderBy
      where: {
        deletedAt: null
        ${GraphQLHelpers.addFilter(vars.displayActive === false, 'active', true)}
        AND: [
          ${GraphQLHelpers.searchableContainsValues(vars.search)}
        ]
        ${hasFilters(vars.filters) ? `
          OR: [
            {
              AND: [${mapFilters(vars.filters)}]
            }
          ]
        ` : ''}
      }
    ) {
      pageInfo {
        hasNextPage
      }
      aggregate {
        count
      }
      edges {
        node {
          id
          flc_id
          active
          invitedAt
          title
          timezone
          productCount
          subscription {
            status
            trialLength
          }
          acceptedTermsAt
          isNewsletterRegistered
          sameDayOrderPauseStatus
          sameDayOrderPauseLimit
          stage
          areProductsDisabledOnFloom
          customSiteConfig {
            customSiteProjectId
          }
          registeredAddress {
            city
            country
          }
          createdBy {
            givenName
            familyName
            email
          }
          plan {
            title
            features
          }
        }
      }
    }
  }
`;

export const SINGLE_MERCHANT = gql`
  query MerchantQuery($id: ID!) {
    merchant(
      where: {
        id: $id
      }
    ) {
      ...MerchantFull
    }
  }
  ${MerchantFragments.MERCHANT_FULL}
`;

import { FC, useContext } from 'react';

import { inject, observer } from 'mobx-react';

import { UrlService } from 'lib';

import { LightboxImage } from 'stores/lightbox-store/lightbox-store';

import { TEST_IDS } from 'utils/test/data-test-ids';

import Icon from 'components/icon';
import Loader from 'components/loader';

import { WholesaleShopListingContext } from '../../wholesale-shop-listing.context';

import {
  IconWrapper,
  ImagesWrapper,
  HeroImageWrapper,
  ImageWrapper,
  Image,
  ImageLoading
} from './wholesale-shop-listing-carousel.styles';
import {
  WholesaleShopListingCarouselProps
} from './wholesale-shop-listing-carousel.types';

export const WholesaleShopListingCarousel: FC<WholesaleShopListingCarouselProps> = inject((stores: FxStores): InjectedFxStores => ({
  lightboxStore: stores.lightboxStore
}))(observer(({
  lightboxStore
}) => {
  const {
    selectedRadioOption,
    availabilityItems
  } = useContext(WholesaleShopListingContext);

  const currentAvailability = availabilityItems?.find(item => item.availabilityItem?.supplierProductId === selectedRadioOption);
  const media = currentAvailability?.availabilityItem?.media || [];

  /**
   * @description Build list of lightbox images
   */
  const lightBoxImages = (): LightboxImage[] => {
    if (!hasImages()) return [];

    return media?.map(mediaItem  => {
      return {
        url: UrlService.setImageSrc(mediaItem!.src!)
      };
    });
  };

  /**
   * @description Ensure catalog item actually has images
   */
  const hasImages = (): boolean => {
    return !!media.length;
  };

  /**
   * @description When a user selects an image, open it up in a lightbox.
   * @param index - the index of the selected image
   */
  const openLightbox = (index: number): void => {
    lightboxStore!.triggerLightbox(lightBoxImages(), index);
  };

  if (!hasImages()) return null;

  const firstElement = media![0];

  return (
    <ImagesWrapper data-testid={TEST_IDS.WholesaleShop.itemModalImage}>
      <HeroImageWrapper>
        <ImageWrapper
          key={firstElement?.id}
          as="button"
          onClick={() => openLightbox(0)}
        >
          <ImageLoading
            justifyContent="center"
            alignItems="center"
          >
            <Loader
              size="small"
              quantity={3}
              gain={5}
            />
          </ImageLoading>
          <Image
            className="lazyload"
            data-bg={UrlService.setImageSrc(firstElement?.src!)}
          />
          <IconWrapper>
            <Icon
              iconName="search"
            />
          </IconWrapper>
        </ImageWrapper>
      </HeroImageWrapper>
    </ImagesWrapper>
  );
}));

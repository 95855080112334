import { FC, useCallback } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Currency, WholesaleOrderLineItem } from 'generated-types.d';

import { NavigationLayout } from 'stores/navigation/navigation-store.types';

import { TEST_IDS } from 'utils/test/data-test-ids';

import { WholesaleCheckoutHelpers } from 'features/wholesale/helpers/wholesale-helpers';

import Button from 'components/button';

import {
  HeaderOrderButtonProps
} from './header-order-button.types';

interface OrderTotals {
  items: WholesaleOrderLineItem[];
  total: number;
  currency: Currency | undefined;
}

const countValue = (items: WholesaleOrderLineItem[]): string => {
  const count = WholesaleCheckoutHelpers.countOrderItems(items);

  return (count > 9) ? '9+' : `${count}`;
};

export const HeaderOrderButton: FC<HeaderOrderButtonProps> = inject((stores: FxStores): InjectedFxStores => ({
  wholesalePaymentStore: stores.wholesalePaymentStore,
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  navigationStore: stores.navigationStore,
  wholesaleShopStore: stores.wholesaleShopStore,
  merchantStore: stores.merchantStore
}))(observer(({
  wholesaleOrdersStore,
  wholesaleShopStore,
  navigationStore,
  merchantStore,
  isVisible
}) => {
  const toggleOrderList = useCallback((): void => {
    navigationStore!.setLayout(NavigationLayout.Collapsed);
    wholesaleShopStore!.toggleOrderSidebar();
  }, []);

  if (!isVisible) return null;

  const orders = wholesaleOrdersStore!.currentOpenOrders || [];
  const orderData = orders.reduce<OrderTotals>((acc, curr): OrderTotals => {
    const orderPrice = curr?.totalPrice || 0;
    const deliveryPrice = curr.deliveryDetails?.[0]?.costValue || 0;
    const taxAmount = WholesaleCheckoutHelpers.orderTaxValue(curr);

    return {
      total: acc.total + (orderPrice + deliveryPrice + taxAmount),
      currency: curr?.merchant?.currency || merchantStore?.merchant?.currency,
      items: [
        ...(acc?.items || []),
        ...(curr.items || [])
      ]
    };
  }, {
    total: 0,
    items: [],
    currency: merchantStore?.merchant?.currency
  });

  return (
    <Box
      data-testid={TEST_IDS.WholesaleShopHeader.orderButton}
    >
      <Box
        as="button"
        onClick={() => toggleOrderList()}
      >
        <Button
          copy={`${WholesaleCheckoutHelpers.renderWholesalePrice(orderData.total, orderData.currency)} (${countValue(orderData.items || [])})`}
          size="xxsmall"
        />
      </Box>
    </Box>
  );
}));

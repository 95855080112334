import { OrderDeliveryState } from 'generated-types.d';

export interface SimpleCourierBookingStatus {
  title: string;
  slug: OrderDeliveryState;
}

export const COURIER_BOOKING_STATUSES: SimpleCourierBookingStatus[] = [
  {
    title: 'Booked',
    slug: OrderDeliveryState.Booked
  },
  {
    title: 'None',
    slug: OrderDeliveryState.None
  },
  {
    title: 'Requested',
    slug: OrderDeliveryState.Requested
  },
  {
    title: 'Assigned Driver',
    slug: OrderDeliveryState.AssignedDriver
  },
  {
    title: 'Picked Up',
    slug: OrderDeliveryState.PickedUp
  },
  {
    title: 'Out for Delivery',
    slug: OrderDeliveryState.OutForDelivery
  },
  {
    title: 'Delivered',
    slug: OrderDeliveryState.Delivered
  },
  {
    title: 'Cancelled',
    slug: OrderDeliveryState.Cancelled
  },
  {
    title: 'Failed',
    slug: OrderDeliveryState.Failed
  },
  {
    title: 'Refused',
    slug: OrderDeliveryState.Refused
  }
];

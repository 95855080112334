import { ClipboardEvent } from 'react';

import sanitize, { IOptions } from 'sanitize-html';

type StripFunction = (value: string) => string;

const sanitizeConfigOnChange: IOptions = {
  allowedTags: ['div', 'br'],
  allowedAttributes: {}
};

const replaceDivTags = (value: string): string => value.replace(/<div>/gi, '\n').replace(/<\/div>/gi, '');

const replacePTags = (value: string): string => value.replace(/<p>/gi, '\n');

const trimEachLine = (value: string): string => value.split('\n').map((line = '') => line.trim()).join('\n');

const breakToNewLine = (value: string): string => value.replace(/<br\s*\/?>/mg, '\n');

const removeEmptySpace = (value: string): string => value.replace(/[ ]+/g, ' ').trim();

export const removeAdditionalLines = (value: string): string => value.replace(/\n\n+/g, '\n\n');

export const sanitizeHTML = (value: string): string => sanitize(value, sanitizeConfigOnChange);

const conversions: StripFunction[] = [
  sanitizeHTML,
  breakToNewLine,
  replaceDivTags,
  replacePTags,
  trimEachLine,
  removeAdditionalLines,
  removeEmptySpace
];

export const convertInputHTML = (value: string): string => conversions.reduce((curr: string, callback: StripFunction) => callback(curr), value);

export const convertOnPaste = <T = HTMLDivElement>(event: ClipboardEvent<T>): void => {
  event.preventDefault();

  const hasEventClipboard = !!(
    event.clipboardData &&
    typeof event.clipboardData === 'object' &&
    typeof event.clipboardData.getData === 'function'
  );

  const value = hasEventClipboard ? event.clipboardData.getData('text/plain') : '';
  const textarea = document.createElement('textarea');

  textarea.innerHTML = value;

  if (typeof document.execCommand === 'function') {
    document.execCommand('insertText', false, convertInputHTML(textarea.innerText));
  }
};

import { FC, Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

import { DISCOUNT_SPEND_AMOUNTS } from '../constants';
import { usePromotedStore } from '../store';

export const Basket: FC<{ canEdit: boolean }> = props => {
  const {
    selectedPostcodes,
    currencySymbol,
    togglePostcode,
    merchantCurrency,
    discountMultiplier,
    selectedPostcodesSubtotal,
    selectedPostcodesSubtotalPerDayAfterDiscount,
    selectedPostcodesSubtotalAfterDiscount,
    selectedPostcodesTotalBeforeDiscount,
    selectedPostcodesTax,
    selectedPostcodesTotal,
    nextDiscount
  } = usePromotedStore();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const nextDiscountData = nextDiscount();
  const discountMultiplierData = discountMultiplier();
  const discountProgress = 100 - ((DISCOUNT_SPEND_AMOUNTS[merchantCurrency][2] - selectedPostcodesSubtotal()) / (DISCOUNT_SPEND_AMOUNTS[merchantCurrency][2] / 100));
  const discountsForProgress = [
    { name: '10%', isCompleted: discountMultiplierData <= 0.9 },
    { name: '20%', isCompleted: discountMultiplierData <= 0.8 },
    { name: '30%', isCompleted: discountMultiplierData === 0.7 }
  ];

  return (
    <div
      style={{ maxHeight: '500px' }}
      className="bg-white border border-slate-200 overflow-hidden rounded-md sm:rounded-lg h-screen flex flex-col"
    >
      {(props.canEdit || (!props.canEdit && discountMultiplierData < 1)) && (
        <div className="text-sm">
          <div className="py-2 px-4 border-b border-slate-200">
            <h4 className="sr-only">Discount progress</h4>
            <p className="text-sm font-medium text-gray-600">
              {props.canEdit && (
                <Fragment>
                  {!!nextDiscountData ? (
                    <Fragment>
                      <span className="font-bold">
                        Spend {currencySymbol()}{nextDiscountData.spendNeeded} more
                      </span>
                      {' '}to save {nextDiscountData.discount}%
                    </Fragment>
                  ) : (
                    <Fragment>You&apos;ve unlocked the maximum discount</Fragment>
                  )}
                </Fragment>
              )}
              {(!props.canEdit && discountMultiplierData < 1) && (
                <Fragment>
                  <span className="font-bold">
                    You&apos;re saving {`${(Math.round((1 - discountMultiplierData) * 100))}%`}
                  </span>
                </Fragment>
              )}
            </p>
            {props.canEdit && (
              <div
                className="mt-2"
                aria-hidden="true"
              >
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div
                    className="h-2 rounded-full bg-indigo-600"
                    style={{ width: `${discountProgress}%` }}
                  />
                </div>
                <div className="mt-1 grid-cols-[1fr,2fr,2fr,2fr] text-sm font-medium text-gray-600 sm:grid">
                  <div>
                    <span
                      onClick={() => setIsModalOpen(true)}
                      style={{ marginTop: '-6px', paddingTop: '1px' }}
                      className="mt-0.5 inline-block cursor-pointer w-4 h-4 text-gray-700 rounded-full bg-gray-200 text-xs leading-none text-center"
                    >
                  ?
                    </span>
                  </div>
                  {discountsForProgress.map((discount, index) => (
                    <div
                      key={index}
                      className={`
                    flex items-center
                    ${index === 1 ? 'justify-center' : (index === 2 ? 'justify-end' : '')}
                    ${discount.isCompleted ? 'text-indigo-600' : 'text-gray-600'}
                  `}
                    >
                      <CheckCircleIcon
                        className={`${discount.isCompleted ? 'text-indigo-600 group-hover:text-indigo-800' : 'opacity-0'} h-5 w-5 mr-0.5`}
                        aria-hidden="true"
                      />
                      {discount.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="overflow-y-auto flex-1">
        {selectedPostcodes().length === 0 ? (
          <div className="p-3 flex items-center h-full">
            <div className="text-slate-500 text-center text-sm w-full h-full flex items-center justify-center">
              <p>Basket is empty</p>
            </div>
          </div>
        ) : (
          <div className="px-2 py-2 overflow-y-scroll">
            {selectedPostcodes().map((postcode, index) => (
              <div
                key={postcode.id}
                className={`${index % 2 === 0 ? 'bg-white' : 'bg-slate-50'} rounded-md sm:rounded-lg px-3 py-1 mb-1 flex items-center justify-between`}
              >
                <div className="text-sm font-medium text-slate-800">
                  {postcode.postcode}
                </div>
                <div className="flex items-center">
                  <div className="text-sm font-medium text-slate-500">
                    {currencySymbol()}{postcode.price * discountMultiplier()}
                  </div>
                  {discountMultiplier() < 1 && (
                    <span className="text-xs font-medium line-through text-slate-300 ml-1.5">
                      {currencySymbol()}{postcode.price}
                    </span>
                  )}
                  {!!props.canEdit && (
                    <XCircleIcon
                      onClick={() => togglePostcode(postcode.postcode)}
                      className="h-5 w-5 text-slate-500 ml-2 mt-0.5 cursor-pointer"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <dl className="border-t border-slate-200">
        {merchantCurrency === 'GBP' && (
          <Fragment>
            <div className="px-3 py-3 flex justify-between">
              <dt className="text-sm font-medium text-slate-500">
                Subtotal
              </dt>
              <dd className="mt-1 text-sm text-slate-900 sm:mt-0 sm:col-span-2 text-right">
                {selectedPostcodesSubtotalAfterDiscount() > 0 && (
                  <span className="mr-2 text-xs text-slate-500">
                    {currencySymbol()}{
                      Number.isInteger(selectedPostcodesSubtotalPerDayAfterDiscount()) ?
                        selectedPostcodesSubtotalPerDayAfterDiscount() :
                        selectedPostcodesSubtotalPerDayAfterDiscount().toFixed(2)
                    } per day
                  </span>
                )}
                {discountMultiplier() < 1 ? (
                  <Fragment>
                    <span className="line-through text-slate-500 mr-1.5">
                      {currencySymbol()}{selectedPostcodesSubtotal()}
                    </span>
                    <span>{currencySymbol()}{selectedPostcodesSubtotalAfterDiscount()}</span>
                  </Fragment>
                ) : (
                  <Fragment>
                    {currencySymbol()}{selectedPostcodesSubtotal()}
                  </Fragment>
                )}
              </dd>
            </div>
            <div className="px-3 py-3 flex justify-between">
              <dt className="text-sm font-medium text-slate-500">
                VAT
              </dt>
              <dd className="mt-1 text-sm text-slate-900 sm:mt-0 sm:col-span-2">
                {currencySymbol()}{
                  Number.isInteger(selectedPostcodesTax()) ?
                    selectedPostcodesTax() :
                    selectedPostcodesTax().toFixed(2)
                }
              </dd>
            </div>
          </Fragment>
        )}
        <div className="bg-slate-50 px-3 py-3 flex justify-between">
          <dt className="text-base font-medium text-slate-600">
            Total
          </dt>
          <dd className="mt-1 text-base text-slate-800 sm:mt-0 sm:col-span-2">
            {discountMultiplier() < 1 ? (
              <Fragment>
                <span className="line-through text-slate-500 mr-1.5">
                  {currencySymbol()}{
                    Number.isInteger(selectedPostcodesTotalBeforeDiscount()) ?
                      selectedPostcodesTotalBeforeDiscount() :
                      selectedPostcodesTotalBeforeDiscount().toFixed(2)
                  }
                </span>
                <span>{currencySymbol()}{selectedPostcodesTotal()}</span>
              </Fragment>
            ) : (
              <Fragment>
                {currencySymbol()}{
                  Number.isInteger(selectedPostcodesTotal()) ?
                    selectedPostcodesTotal() :
                    selectedPostcodesTotal().toFixed(2)
                }
              </Fragment>
            )}
          </dd>
        </div>
      </dl>
      <DiscountModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </div>
  );
};

const DiscountModal: FC<{ isOpen: boolean; setIsOpen: any }> = ({ isOpen, setIsOpen }) => {
  const {
    currencySymbol,
    merchantCurrency
  } = usePromotedStore();

  const discountSpendAmountsForCurrency = DISCOUNT_SPEND_AMOUNTS[merchantCurrency];

  return (
    <Transition.Root
      show={isOpen}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative"
        style={{ zIndex: 9999 }}
        onClose={setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-fx-mid-blue bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="mb-4">
                  <div>
                    <Dialog.Title
                      as="h3"
                      className="mb-3 text-base font-semibold leading-6"
                    >
                      Discounts explained
                    </Dialog.Title>
                    <div className="text-center bg-slate-100 text-sm rounded-lg px-5 py-4">
                      <div className="mb-4 border-b border-slate-300 pb-3">
                        <p>Spend {currencySymbol()}{discountSpendAmountsForCurrency[0]} for 10% discount</p>
                      </div>
                      <div className="mb-3 border-b border-slate-300 pb-3">
                        <p>Spend {currencySymbol()}{discountSpendAmountsForCurrency[1]} for 20% discount</p>
                      </div>
                      <div>
                        <p>Spend {currencySymbol()}{discountSpendAmountsForCurrency[2]} for 30% discount</p>
                      </div>
                    </div>
                  </div>
                </div>
                <Box
                  onClick={() => setIsOpen(false)}
                >
                  <button
                    style={{
                      backgroundColor: colors.floomMidnightBlue
                    }}
                    className="h-10 rounded-md sm:rounded-lg w-full text-white
                        justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                        disabled:opacity-30"
                  >
                    <span>Ok</span>
                  </button>
                </Box>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import React, { Component, ReactNode } from 'react';

import * as Styles from './error-message.styles';
import * as Types from './error-message.types';

export default class ErrorMessage extends Component<Types.ErrorMessageProps> {
  render(): ReactNode {
    return (
      <Styles.ErrorMessageWrapper>
        {
          this.props.errorMessage
            ? (
              <Styles.ErrorMessage>
                {this.props.errorMessage}
              </Styles.ErrorMessage>
            )
            : undefined
        }
      </Styles.ErrorMessageWrapper>
    );
  }
}

import React, { ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex, Box, Text } from 'rebass';

import { Merchant } from 'generated-types.d';

import {
  CurrencyService,
  PermissionsService,
  WholesaleService
} from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { textStyles } from 'utils/rebass-theme';

import UserLoginService from 'features/login-signup/services';
import { FieldEditModalData } from 'features/modal-dialogue/components/modals/field-edit-modal/field-edit-modal.types';
import { WholesaleTierModalProps } from 'features/modal-dialogue/components/modals/wholesale-tier-modal/wholesale-tier-modal.types';
import WholesaleSettingsAPIService from 'features/wholesale/services/wholesale-api/settings/wholesale-settings-api.service';

import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import SectionHeading from 'components/section-heading';
import StyledLink from 'components/styled-link';

import { CreditDetails } from './credit-details';
import {
  Grid
} from './credit-overview.styles';
import {
  CreditOverviewState,
  WholesaleDeliveryCardProps
} from './credit-overview.types';
import WholesaleDiscounts from './wholesale-discounts';

export const CreditOverviewContext = React.createContext<{
  isLoading: boolean;
  unpaidBalance: number | null;
  merchant: Merchant | null;
}>({
  isLoading: true,
  unpaidBalance: null,
  merchant: null
});

class CreditOverview extends React.Component<WholesaleDeliveryCardProps, CreditOverviewState> {
  state: CreditOverviewState = {
    isDirty: false,
    isLoading: true,
    isLoadingDiscounts: false,
    unpaidBalance: null,
    termsFormValues: {
      wholesaleCreditLimit: `${this.props.merchantStore!.merchant?.subscription?.wholesaleCreditLimit || 0}`,
      wholesaleTermLength: `${this.props.merchantStore!.merchant?.subscription?.wholesaleTermLength || 0}`
    }
  };

  componentDidMount(): void {
    this.loadSettings();
  }

  private loadSettings = async (): Promise<void> => {
    const id = this.props.merchantStore!.merchant?.id;

    if (id) {
      try {
        WholesaleSettingsAPIService.getWholesaleGMV(id);
        WholesaleSettingsAPIService.getWholesaleTiers(id);
        const unpaidBalance = await WholesaleService.currentUnpaidBalance({ id });

        this.setState({
          unpaidBalance: unpaidBalance,
          isLoading: false
        });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    }
  };

  private handleTermsChange = (
    value: string,
    key: keyof CreditOverviewState['termsFormValues']
  ): void => {
    this.setState({
      isDirty: true,
      termsFormValues: {
        ...this.state.termsFormValues,
        [key]: value
      }
    });
  };

  private hasCreditTerms = (): boolean => {
    return !!this.props.merchantStore!.merchant?.subscription?.wholesaleCreditLimit
      || !!this.props.merchantStore!.merchant?.subscription?.wholesaleTermLength;
  };

  private hasWholesaleTiers = (): boolean => !!this.props.wholesaleSettingsStore!.wholesaleTiers.length;

  public TERMS_FORM_CONFIG = (): FormBuilderConfig => {
    const isLimitBelowUnpaidBalance = !!this.state.unpaidBalance
      && parseFloat(this.state.termsFormValues.wholesaleCreditLimit) < this.state.unpaidBalance!;

    return {
      sections: [
        {
          width: '100',
          paddingBottom: '0',
          fields: [
            {
              key: 'paymentTermLength',
              fieldType: 'labelSmall',
              copy: 'Payment term length (days)',
              subCopy: 'How many days an order invoice is allowed to remain unpaid before we automatically attempt payment',
              validationError: undefined
            },
            {
              key: 'creditLimitField',
              fieldType: 'textInput',
              value: `${this.state.termsFormValues.wholesaleTermLength}`,
              onChange: (e): void => {
                this.handleTermsChange(e.target.value, 'wholesaleTermLength');
              }
            }
          ]
        },
        {
          width: '100',
          paddingBottom: '0',
          fields: [
            {
              key: 'creditLimitLabel',
              fieldType: 'labelSmall',
              copy: 'Credit limit',
              subCopy: 'How much a merchant can accrue in unpaid invoices',
              validationError: undefined
            },
            {
              key: 'creditLimitField',
              fieldType: 'textInput',
              prefix: CurrencyService.renderCurrencySymbol(this.props.merchantStore!.merchant?.currency!),
              value: `${this.state.termsFormValues.wholesaleCreditLimit}`,
              onChange: (e): void => {
                this.handleTermsChange(e.target.value, 'wholesaleCreditLimit');
              }
            }
          ]
        },
        {
          paddingBottom: '0',
          isHidden: !isLimitBelowUnpaidBalance,
          width: '100',
          fields: [
            {
              key: 'planDetails',
              fieldType: 'custom',
              customContent: (
                <Box mt="20px">
                  <Notification
                    copy={`You're reducing the credit limit to below the merchants unpaid balance of ${this.unpaidBalance()}. This will cause some unpaid invoices to be charged immediately.`}
                    type={NotificationType.Info}
                    hasClose={false}
                    textAlign="left"
                  />
                </Box>
              )
            }
          ]
        }
      ]
    };
  };

  private triggerTermsModal = (): void => {
    const hasCreditTerms = this.hasCreditTerms();
    const copy = hasCreditTerms ? 'Update' : 'Create';

    this.props.modalStore!.triggerModal<FieldEditModalData>({
      modalType: 'fieldEdit',
      data: {
        title: `${copy} credit terms`,
        buttonCopy: `${copy} terms`,
        isDirty: (): boolean => this.state.isDirty,
        config: (): FormBuilderConfig => this.TERMS_FORM_CONFIG(),
        confirmButtonAction: async (): Promise<void> => new Promise(async (resolve, reject): Promise<void> => {
          try {
            await WholesaleService.updateCreditTerms(
              {
                id: this.props.merchantStore!.merchant?.id
              },
              {
                wholesaleCreditLimit: parseFloat(this.state.termsFormValues.wholesaleCreditLimit),
                wholesaleTermLength: parseFloat(this.state.termsFormValues.wholesaleTermLength)
              }
            );

            await UserLoginService.getMerchantDetails();

            this.props.toasterStore!.popSuccessToast('Credit terms', 'update');
            resolve();
          } catch (e) {
            this.props.toasterStore!.popToast('We\'re having trouble updating the credit terms.', ToastType.Error);
            reject();
          }
        })
      }
    });
  };

  private triggerDiscountsModal = async (): Promise<void> => {
    try {
      this.props.modalStore!.triggerModal<WholesaleTierModalProps>({
        modalType: 'wholesaleTier'
      });
    } catch (error) {
      this.props.toasterStore!.popToast(`We're having trouble updating discount tiers.`, ToastType.Error);
    }

    this.setState({
      isLoadingDiscounts: false
    });
  };

  private unpaidBalance = (): string => CurrencyService.formatPrice(
    this.state.unpaidBalance!,
    this.props.merchantStore!.merchant!.currency
  );

  private renderHeading = (): ReactNode => {
    const hasCreditTerms = this.hasCreditTerms();
    const hasWholesaleTiers = this.hasWholesaleTiers();

    return (
      <Flex mb="20px">
        <SectionHeading
          hasSpacing={false}
          title={(
            <Flex flexWrap="wrap">
              <Text css={textStyles.h3}>
                At a glance
              </Text>
              {PermissionsService.isInternalRole() && (
                <>
                  <Box
                    ml="15px"
                    as="button"
                    onClick={this.triggerTermsModal}
                  >
                    <StyledLink>
                      {hasCreditTerms ? 'Edit terms' : 'Create terms'}
                    </StyledLink>
                  </Box>
                  <Box
                    ml="15px"
                    as="button"
                    onClick={this.triggerDiscountsModal}
                    disabled={this.state.isLoadingDiscounts}
                  >
                    <StyledLink isDisabled={this.state.isLoadingDiscounts}>
                      {((): string => {
                        switch (true) {
                          case hasWholesaleTiers:
                            return 'Edit discounts';

                          case this.state.isLoadingDiscounts:
                            return 'Loading...';

                          default:
                            return 'Create discounts';
                        }
                      })()}
                    </StyledLink>
                  </Box>
                  { !hasCreditTerms && (
                    <Text
                      css={textStyles.body}
                      width="100%"
                      mt="5px"
                    >
                      <strong>{this.props.merchantStore!.merchant?.title}</strong> has no credit terms, click create to configure them
                    </Text>
                  )}
                </>
              )}
            </Flex>
          )}
        />
      </Flex>
    );
  };

  render(): ReactNode {
    const hasCreditTerms = this.hasCreditTerms();
    const hasAdjusterTiers = this.hasWholesaleTiers();
    const isInternalRole = PermissionsService.isInternalRole();
    const hasSubscription = this.props.merchantStore!.merchant?.subscription;
    const hasMerchant = !!this.props.merchantStore!.merchant;

    const shouldSeeComponent = (hasMerchant && isInternalRole) || (!!hasSubscription && (hasCreditTerms || hasAdjusterTiers));

    if (!shouldSeeComponent) return null;

    return (
      <CreditOverviewContext.Provider
        value={{
          isLoading: this.state.isLoading,
          merchant: this.props.merchantStore!.merchant,
          unpaidBalance: this.state.unpaidBalance
        }}
      >
        <Box width="100%">
          {this.renderHeading()}
          <Grid>
            <CreditDetails />
            {hasAdjusterTiers && (
              <WholesaleDiscounts />
            )}
          </Grid>
        </Box>
      </CreditOverviewContext.Provider>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore,
  merchantStore: stores.merchantStore,
  wholesaleSettingsStore: stores.wholesaleSettingsStore
}))(observer(CreditOverview));

import {
  FC,
  memo
} from 'react';

import {
  Container,
  Heading,
  Image
} from './loading-image.styles';
import { LoadingImageProps } from './loading-image.types';

export const LoadingImage: FC<LoadingImageProps> = memo(({
  text,
  imageSrc,
  imageAlt
}) => {
  return (
    <Container>
      <Image
        src={imageSrc}
        alt={imageAlt}
      />
      <Heading>
        {text}
      </Heading>
    </Container>
  );
});

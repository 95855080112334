import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import parseCsv from 'csv-parse';
import cuid from 'cuid';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { Box, Flex, Text } from 'rebass';

import {
  OrderNoteCreateWithoutOrderInput,
  OrderNoteUpdateWithoutOrderDataInput
} from 'generated-types.d';

import {
  NavService,
  PermissionsService,
  OrderService,
  TimeService,
  Auth
} from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { Container } from 'utils/css-mixins';
import { textStyles } from 'utils/rebass-theme';

import SettingsTemplatePage from 'features/settings/components/template';

import Button from 'components/button';
import { EntityListItems } from 'components/entity-list/entity-list.styles';
import TableLayoutEntityHeading from 'components/entity-list/table-layout-entity-heading';
import FormFooter from 'components/form-footer';
import Icon from 'components/icon';
import NoResultsGeneric from 'components/no-results-generic';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import SectionHeading from 'components/section-heading';
import WithLoading from 'components/with-loading';

import { DpdUploadListItem } from './dpd-upload-list-item';
import * as Styles from './dpd-upload.styles';
import * as Types from './dpd-upload.types';
import { getOrderNo } from 'utils';

const NOTIFICATION_COPY = `
  You have some DPD status updates that are linked to orders that cannot be found.
  If you can see something wrong with the order #, try amending it and hit 'retry',
  to see if we can find it for you. Otherwise, press remove to delete it.
`;

class DpdUpload extends Component<Types.DpdUploadProps, Types.DpdUploadState> {
  state: Types.DpdUploadState = {
    isLoading: false,
    isDragging: false,
    isSubmitting: false,
    items: []
  };

  private ITEM_GROUPS = (): Types.ItemGroup[] => [
    {
      id: 'error',
      title: 'We couldn\'t find an order for these',
      tableHeader: Types.ERROR_LIST_HEADING_CONFIG,
      items: this.state.items.filter(item => !item?.order)
    },
    {
      id: 'ready',
      title: 'Ready to upload',
      tableHeader: Types.READY_LIST_HEADING_CONFIG,
      items: this.state.items.filter(item => !!item?.order)
    }
  ];

  componentDidMount(): void {
    this.handleRedirect();
  }

  private handleRedirect = (): void => {
    if (!PermissionsService.isInternalRole()) {
      NavService.overview();
    }
  };

  private handleDelete = (statusItem: Types.DpdStatusItem): void => {
    const items = [...this.state.items];
    const filteredItems = items.filter(item => item.uniqueId !== statusItem.uniqueId);

    this.setState({ items: filteredItems });
  };

  private handleClearAll = (): void => {
    this.setState({ items: [] });
  };

  private buildNoteCopy = (item: string[]): string => {
    return `Tracking ref: ${item[3] || 'Not found'}\nNote Added: ${TimeService.humanDateMonthYear(moment())}\nDPD Note: ${item[5] || 'No note'}`;
  };

  private handleSave = async (): Promise<void> => {
    this.setState({ isSubmitting: true });
    const validItems = this.state.items.filter(item => !!item.order);
    const submittedOrderIds: string[] = [];

    try {
      for (const item of validItems) {
        if (item.order) {
          const floomOrderNote = item.order.orderNotes?.find(note => note.floom);
          const baseUpdates: OrderNoteUpdateWithoutOrderDataInput | OrderNoteCreateWithoutOrderInput = {
            floom: true,
            read: false
          };

          if (!!floomOrderNote) {
            await OrderService.updateOrder({ id: item.order.id }, {
              orderNotes: {
                update: [{
                  where: {
                    id: floomOrderNote.id
                  },
                  data: {
                    ...baseUpdates,
                    content: item.message
                  }
                }]
              }
            });
          } else {
            await OrderService.updateOrder({ id: item.order.id }, {
              orderNotes: {
                create: [{
                  ...baseUpdates,
                  content: item.message,
                  createdBy: {
                    connect: {
                      id: Auth.getUserID()
                    }
                  }
                }]
              }
            });
          }

          submittedOrderIds.push(item.originalOrderNo);
        }
      }

      const remainingItems = this.state.items.filter(item => !submittedOrderIds.includes(item.originalOrderNo));

      if (!!remainingItems.length) {
        this.props.toasterStore!.popNotificationToast('We\'ve removed the orders that have been updated, and kept the orders remaining to be updated.');
      } else {
        this.props.toasterStore!.popSuccessToast('All orders', 'update');
      }

      this.setState({
        isSubmitting: false,
        items: this.state.items.filter(item => !submittedOrderIds.includes(item.originalOrderNo))
      });
    } catch (error) {
      this.setState({ isSubmitting: false });
      this.props.toasterStore!.popErrorToast('the orders you\'ve added', 'update');
    }
  };

  private handleResync = async (statusItem: Types.DpdStatusItem): Promise<void> => {
    const shortenedOrderNo = getOrderNo(statusItem.orderNo);

    if (this.state.items.some(item => getOrderNo(item.orderNo) === shortenedOrderNo && !!item.order)) {
      this.props.toasterStore!.popToast('An order with this order # has already been added', ToastType.Error);

      return;
    }

    const orders = await OrderService.fetchOrders({
      orderNo_contains: shortenedOrderNo
    });

    if (!orders?.length || orders.length > 1) {
      this.props.toasterStore!.popErrorToast(`this order (${shortenedOrderNo})`, 'retrieve');

      return;
    }

    const order = orders[0];
    const orderNo = getOrderNo(order.orderNo);

    const items = this.state.items.map(item => {
      if (getOrderNo(item.orderNo) === orderNo) {
        const note = order?.orderNotes?.find(orderNote => orderNote.floom)?.content || '';

        return {
          ...item,
          order: order,
          message: !!item.originalMessage?.length ? `${note.length ? `${note}\n----\n` : ''}${item.originalMessage}` : item.message,
          originalOrderNo: orderNo
        };
      }

      return item;
    });

    this.setState({ items });
    this.props.toasterStore!.popToast(`Order (${shortenedOrderNo}) added to "Ready to upload"`, ToastType.Success);
  };

  private handleItemChange = (
    statusItem: Types.DpdStatusItem,
    value: string,
    key: keyof Pick<Types.DpdStatusItem, 'orderNo' | 'message'>
  ): void => {
    const index = this.state.items.findIndex(item => item.uniqueId === statusItem.uniqueId);

    this.setState(state => {
      state.items[index][key] = value;

      return {
        items: state.items
      };
    });
  };

  private handleFiles = async (files: File[]): Promise<void> => {
    this.setState({ isLoading: true });

    let items: Types.DpdStatusItem[] = [];

    for (const file of files) {
      let resolver: any = null;

      const reader = new FileReader();

      reader.onload = async (): Promise<void> => {
        // @ts-ignore
        parseCsv(reader.result, async (err, data) => {
          data?.shift?.();

          const output: Types.DpdStatusItem[] = await Promise.all(data?.map(async (item: string[]): Promise<Types.DpdStatusItem> => {
            const orderNo = getOrderNo((item?.[1] || '')
              .trim()
              .toLowerCase()
              .replace('#', ''));

            const baseData: Pick<Types.DpdStatusItem, 'originalOrderNo' | 'orderNo' | 'dpdConsignmentNo' | 'dpdParcelNo' | 'uniqueId'> = {
              uniqueId: cuid(),
              originalOrderNo: item?.[1],
              orderNo: item?.[1],
              dpdConsignmentNo: item?.[6]?.trim() || '',
              dpdParcelNo: item?.[3]
            };

            const noteCopy = this.buildNoteCopy(item);

            const orders = await OrderService.fetchOrders({
              orderNo_contains: orderNo
            });

            const order = orders?.length === 1 ? orders[0] : null;
            const note = order?.orderNotes?.find(orderNote => orderNote.floom)?.content || '';
            const reason = ((): string => {
              switch (true) {
                case !!orders && orders?.length > 1:
                  return 'Multiple orders found for this order number. Cannot add note';

                case !order:
                  return 'Order cannot be found';

                default:
                  return '';
              }
            })();

            return {
              ...baseData,
              message: `${note.length ? `${note}\n----\n` : ''}${noteCopy}`,
              originalMessage: reason.length ? noteCopy : undefined,
              order: order,
              reason: reason
            };
          }));

          if (output?.length) {
            items = [
              ...items,
              ...output
            ];
          }

          resolver?.();
        });
      };

      reader.readAsBinaryString(file);

      await new Promise((resolve: any): void => {
        resolver = resolve;
      });
    }

    this.setState({
      items: items.reduce((acc: Types.DpdStatusItem[], curr: Types.DpdStatusItem): Types.DpdStatusItem[] => {
        if (acc.some(item => item.originalOrderNo === curr.originalOrderNo)) {
          return [
            ...acc,
            {
              ...curr,
              order: null,
              reason: 'Duplicate order number'
            }
          ];
        }

        return [
          ...acc,
          curr
        ];
      }, []),
      isLoading: false
    });
  };

  private setDragging = (): void => {
    this.setState({
      isDragging: true
    });
  };

  private unsetDragging = (): void => {
    this.setState({
      isDragging: false
    });
  };

  private handleError = (): void => {
    this.props.toasterStore!.popNotificationToast('You\'ve tried to upload an unsupported file, please make sure you are uploading a DPD .csv export');
    this.unsetDragging();
  };

  private hasUnfoundOrders = (): boolean => {
    return this.state.items.some(item => !item.order);
  };

  private hasOrdersToSubmit = (): boolean => {
    return this.state.items.some(item => !!item.order);
  };

  private renderNotification = (): ReactNode => {
    if (!this.hasUnfoundOrders()) return null;

    return (
      <Box mb="20px">
        <Notification
          copy={NOTIFICATION_COPY}
          hasClose={false}
          textAlign="left"
          type={NotificationType.Progress}
        />
      </Box>
    );
  };

  private renderItems = (items: (Types.DpdStatusItem | null)[], group: Types.ItemGroup): ReactNode => {
    if (!items.length) return null;

    return items.map((item: Types.DpdStatusItem | null): React.ReactNode => {
      if (!item?.originalOrderNo) return null;

      return (
        <DpdUploadListItem
          key={item.uniqueId}
          item={item}
          type={group.id}
          onDelete={this.handleDelete}
          onResync={this.handleResync}
          onItemChange={this.handleItemChange}
        />
      );
    });
  };

  private renderGroup = (group: Types.ItemGroup): ReactNode => {
    if (!group.items.length) return null;

    return (
      <Box
        key={group.id}
        mb="30px"
      >
        <SectionHeading
          title={group.title}
          count={group.items.length}
        />
        <TableLayoutEntityHeading headers={group.tableHeader} />
        <EntityListItems>
          {this.renderItems(group.items, group)}
        </EntityListItems>
      </Box>
    );
  };

  private renderDropzone = (): ReactNode => {
    if (!!this.state.items.length) return null;

    return (
      <Dropzone
        onDrop={this.handleFiles}
        accept=".csv, text/csv"
        onDragEnter={this.setDragging}
        onDragLeave={this.unsetDragging}
        onDropAccepted={this.unsetDragging}
        onDropRejected={this.handleError}
      >
        {({ getRootProps, getInputProps }): JSX.Element => (
          <Styles.DropArea
            isDragging={this.state.isDragging}
          >
            <Styles.DropAreaContent {...getRootProps()}>
              <input {...getInputProps()} />
              <Box
                mb="20px"
                width="100%"
              >
                <Icon iconName="plus-large" />
              </Box>
              <p>
                { this.state.isDragging
                  ? 'Drop now'
                  : 'Click to upload / drag and drop a .csv export from DPD'
                }
              </p>
            </Styles.DropAreaContent>
          </Styles.DropArea>
        )}
      </Dropzone>
    );
  };

  private renderSubmitButton = (): ReactNode => {
    if (!this.state.items.length) return null;

    return (
      <FormFooter>
        <Container>
          <Flex
            justifyContent="space-between"
            width="100%"
          >
            <Box
              as="button"
              onClick={this.handleClearAll}
              disabled={this.state.isSubmitting}
            >
              <Button
                size="normal"
                appearance="danger"
                copy="Clear all"
                isDisabled={this.state.isSubmitting}
              />
            </Box>
            <Box
              as="button"
              onClick={this.handleSave}
              disabled={this.state.isSubmitting}
            >
              <Button
                size="normal"
                appearance="primary"
                copy="Submit updates"
                isLoading={this.state.isSubmitting}
                isDisabled={!this.hasOrdersToSubmit()}
              />
            </Box>
          </Flex>
        </Container>
      </FormFooter>
    );
  };

  render(): ReactNode {
    return (
      <SettingsTemplatePage
        title="DPD Upload"
      >
        <Text
          css={css`
            max-width: 600px;
            ${textStyles.body}
          `}
        >
          Upload merchant DPD status update CSV’s here, and we will update the order notes for the orders.
        </Text>
        <Box
          mt="30px"
          mb="30px"
        >
          {this.renderNotification()}
          <WithLoading
            hasNoResults={false}
            isLoading={this.state.isLoading && !this.state.items.length}
            renderNoResults={(): ReactNode => (
              <Box mt="40px">
                <NoResultsGeneric
                  icon="business-cross"
                  heading="Failed to process CSV"
                  copy=""
                />
              </Box>
            )}
          >
            {this.renderDropzone()}
            {this.ITEM_GROUPS().map(this.renderGroup)}
            {this.renderSubmitButton()}
          </WithLoading>
        </Box>
      </SettingsTemplatePage>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore
}))(observer(DpdUpload));

import { css } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import hexToRgba from 'hex2rgba';
import { Flex, Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

interface ColourCheckboxStyleProps {
  colour?: string;
  isDisabled?: boolean;
  size?: 'normal' | 'small';
}

export const MixedColourContainer = styled(Box)`
  position: relative;
  display: inline;
  margin-right: 36px;
`;

export const MiniColoursHolder = styled(Flex)`
  position: absolute;
  width: 22px;
  height: 22px;
  flex-wrap: wrap;
`;

export const MixedTickContainer = styled(Flex)`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: 3px;
  left: 3px;
  background: black;
  align-items: center;
  justify-content: center;
`;

export const ColourCheckbox: StyledComponent<ColourCheckboxStyleProps, any, any> = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 12px;
  width: 23px;
  height: 23px;
  border-radius: 23px;
  flex-wrap: wrap;
  background: ${({ colour, isDisabled }: ColourCheckboxStyleProps): any => colour && hexToRgba(colour.substr(1, 6), isDisabled ? .3 : 1)};

  ${({ size }: ColourCheckboxStyleProps): any => size && size === 'small' && css`
    width: 9px;
    height: 9px;
    border-radius: 9px;
    margin: 1px;
  `};

  ${({ colour, isDisabled }: ColourCheckboxStyleProps): any => {
    if (colour && colour.toUpperCase().includes('FFF')) {
      return css`
        border: solid 2px ${hexToRgba(colors.floomMidnightBlue, isDisabled ? .3 : 1)};
        width: 23px;
        height: 23px;
      `;
    }
  }};
`;

export const TickIconStyles: any = css`
  margin: 0 auto;
  width: 18px;
  height: 18px;
`;

export const TickIconMixedStyles: any = css`
  ${TickIconStyles};
  width: 8px;
  height: 8px;
  left: 4px;
  top: 4px;
`;

import { FC, FormEvent, useState } from 'react';

import { css } from '@emotion/react';
import axios from 'axios';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import * as Yup from 'yup';

import { ValidationService } from 'lib';

import { MarketingButton } from 'features/marketing/components/button/button';
import { H2, H3 } from 'features/marketing/marketing.styles';

import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import { NotificationType } from 'components/notification/notification.types';

import {
  Wrapper,
  ApplicantType,
  ApplicantOptions
} from './contact-form.styles';
import {
  ContactFormBlockProps,
  FormValues,
  MailchimpSchemaInput,
  NotificationState
} from './contact-form.types';

const validation = Yup.object().shape<FormValues>({
  WHOAREYOU: Yup
    .string()
    .required(params => ValidationService.generateYupMessageSchema(params, 'Are you a florist or a supplier?')),
  FNAME: Yup
    .string()
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please enter your full name')),
  COMPANY: Yup.string(),
  EMAIL: Yup
    .string()
    .email(params => ValidationService.generateYupMessageSchema(params, 'Please enter a valid email'))
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please enter your email address')),
  PHONE: Yup
    .string()
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please enter a telephone number')),
  SOCIAL: Yup.string(),
  CITY: Yup.string()
});

export const ContactForm: FC<ContactFormBlockProps> = ({ data }) => {
  const [isPosting, setIsPosting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [notificationState, setNotificationState] = useState<NotificationState | null>(null);
  const [formValueErrors, setFormValueErrors] = useState<Yup.TestMessageParams[]>([]);
  const [formValues, updateFormValues] = useState<FormValues>({
    WHOAREYOU: 'florist',
    FNAME: '',
    COMPANY: '',
    EMAIL: '',
    PHONE: '',
    SOCIAL: '',
    CITY: ''
  });

  const resetErrorForField = (key: keyof FormValues): void => {
    setFormValueErrors(state => state?.filter(error => error.path !== key));
  };

  const updateFormValue = (value: string, key: keyof FormValues): void => {
    resetErrorForField(key);

    updateFormValues(state => ({
      ...state,
      [key]: value
    }));

    if (!!notificationState) {
      setNotificationState(null);
    }
  };

  const onSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    try {
      setIsPosting(true);

      const validFormInput: FormValues = await ValidationService.validateAll(validation, formValues);
      const payload = buildPayload(validFormInput);
      const result =  await axios.post('/.netlify/functions/marketing-signup', payload);

      if ([200, 201].includes(result.status)) {
        setIsPosting(false);
        setIsSubmitted(true);

        setNotificationState({
          copy: `Thanks for submitting your interest in FloomX! We have received your details and will be in touch soon.`,
          type: NotificationType.Info
        });

        window.analytics?.track?.('Marketing contact form submission', validFormInput);
      }
    } catch (error) {
      setIsPosting(false);

      if (error.errors) {
        setFormValueErrors(error.errors);
      }

      setNotificationState({
        copy: `Oops! We're having trouble submitting the form. Please check for any validation errors`,
        type: NotificationType.Error
      });
    }
  };

  const buildPayload = (formData: FormValues): MailchimpSchemaInput => {
    return Object.keys(formData).reduce<MailchimpSchemaInput>((acc, curr) => {
      if (curr === 'EMAIL') {
        return {
          ...acc,
          email: formData['EMAIL']
        };
      }

      return {
        ...acc,
        merge_fields: {
          ...acc.merge_fields,
          [curr]: formData[curr]
        }
      };
    }, {
      email: '',
      merge_fields: {}
    });
  };

  const findError = (key: keyof FormValues): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(formValueErrors, key);
  };

  const formBuilderConfig: FormBuilderConfig = {
    sections: [
      {
        width: '100',
        fields: [
          {
            key: 'whoAreYouField',
            fieldType: 'custom',
            customContent: (
              <div>
                <Box
                  css={css`
                    text-align: center;
                  `}
                >
                  <H2>Who are you?</H2>
                  <H3>Click one</H3>
                </Box>
                <ApplicantOptions>
                  <ApplicantType
                    onClick={(): void => {
                      if (!isSubmitted) {
                        updateFormValue('florist', 'WHOAREYOU');
                      }
                    }}
                    isSelected={formValues.WHOAREYOU === 'florist'}
                    disabled={isSubmitted}
                  >
                    Florist
                  </ApplicantType>
                  <ApplicantType
                    onClick={(): void => {
                      if (!isSubmitted) {
                        updateFormValue('supplier', 'WHOAREYOU');
                      }
                    }}
                    isSelected={formValues.WHOAREYOU === 'supplier'}
                    disabled={isSubmitted}
                  >
                    Supplier
                  </ApplicantType>
                </ApplicantOptions>
              </div>
            )
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'fullNameLabel',
            fieldType: 'label',
            copy: `Your full name`,
            validationError: undefined
          },
          {
            key: 'fullNameField',
            fieldType: 'textInput',
            value: formValues.FNAME,
            isDisabled: isSubmitted,
            validationError: findError('FNAME'),
            onChange: (e): void => updateFormValue(e.target.value, 'FNAME')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'companyNameLabel',
            fieldType: 'label',
            copy: `Company name`,
            isOptional: true,
            validationError: undefined
          },
          {
            key: 'companyNameField',
            fieldType: 'textInput',
            value: formValues.COMPANY,
            isDisabled: isSubmitted,
            validationError: findError('COMPANY'),
            onChange: (e): void => updateFormValue(e.target.value, 'COMPANY')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'emailLabel',
            fieldType: 'label',
            copy: `Your email`,
            validationError: undefined
          },
          {
            key: 'emailField',
            fieldType: 'textInput',
            value: formValues.EMAIL,
            isDisabled: isSubmitted,
            validationError: findError('EMAIL'),
            onChange: (e): void => updateFormValue(e.target.value, 'EMAIL')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'phoneNumberLabel',
            fieldType: 'label',
            copy: `Your phone number`,
            isOptional: true,
            validationError: undefined
          },
          {
            key: 'phoneNumberField',
            fieldType: 'textInput',
            value: formValues.PHONE,
            isDisabled: isSubmitted,
            validationError: findError('PHONE'),
            onChange: (e): void => updateFormValue(e.target.value, 'PHONE')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'websiteLabel',
            fieldType: 'label',
            copy: `Company website / instagram`,
            isOptional: true,
            validationError: undefined
          },
          {
            key: 'websiteField',
            fieldType: 'textInput',
            value: formValues.SOCIAL,
            isDisabled: isSubmitted,
            validationError: findError('SOCIAL'),
            onChange: (e): void => updateFormValue(e.target.value, 'SOCIAL')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'locationLabel',
            fieldType: 'label',
            copy: `Your location`,
            isOptional: true,
            validationError: undefined
          },
          {
            key: 'locationField',
            fieldType: 'textInput',
            value: formValues.CITY,
            isDisabled: isSubmitted,
            validationError: findError('CITY'),
            onChange: (e): void => updateFormValue(e.target.value, 'CITY')
          }
        ]
      }
    ]
  };

  return (
    <Wrapper
      as="form"
      onSubmit={onSubmit}
      shouldRemoveSpacing={!!data.removeTopSpacing}
    >
      <FormBuilder
        config={formBuilderConfig}
        notification={{
          hasNotification: !!notificationState,
          position: 'bottom',
          notificationProps: {
            copy: notificationState?.copy,
            type: notificationState?.type
          }
        }}
      />
      {!isSubmitted && (
        <Flex
          justifyContent="center"
          mt="40px"
        >
          <button
            type="submit"
            disabled={isPosting}
          >
            <MarketingButton
              isLoading={isPosting}
              copy={isPosting ? 'Submitting' : 'Submit'}
            />
          </button>
        </Flex>
      )}
    </Wrapper>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore
}))(observer(ContactForm));

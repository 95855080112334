import gql from 'graphql-tag';

export const DRAFT_WHOLESALE_ORDER_ITEM_FRAGMENT = gql`
  fragment DraftWholesaleOrderItemFragment on WholesaleOrderLineItem {
    id
    createdAt
    quantity
    totalPrice
    unitPrice
    snapshot
    wholesalerLineItemRef
    wholesalerTrackingRef
    listItem {
      ... on ListItem {
        type
        sku
        maxUnitPrice
        catalogItem {
          colour {
            name
            hex
          }
          media(
            first: 1
          ) {
            id
            title
            src
          }
          family {
            genus
          }
          tradeSku(
            first: 1
            orderBy: createdAt_DESC
          ) {
            availability(
              orderBy: createdAt_DESC
              first: 1
            ) {
              media {
                src
              }
            }
          }
        }
      }

      ... on ListItemFlower {
        minimumStemLength
        maturity
      }

      ... on ListItemTypeSundry {
        height
        weight
      }

      ... on ListItemTypePlant {
        height
        potSize
      }
    }
  }
`;

export const WHOLESALE_ORDER_PAYMENT_INFO_FRAGMENT = gql`
  fragment WholesaleOrderPaymentInfoFragment on WholesaleOrder {
    totalPrice
    deliveryDetails {
      costIncluded
      costValue
      updatedAt
      modifiedBy {
        id
        givenName
        familyName
      }
    }
    tax {
      amount
      name
      inclusive
      updatedAt
      modifiedBy {
        id
        givenName
        familyName
      }
    }
  }
`;

export const DRAFT_WHOLESALE_ORDER_FRAGMENT = gql`
  fragment DraftWholesaleOrderFragment on WholesaleOrder {
    ...WholesaleOrderPaymentInfoFragment
    id
    status
    paymentStatus
    wholesaleOrderRef
    createdAt
    processedAt
    paidAt
    wholesaleDeliveryConfigSnapshot
    supplier {
      name
    }
    merchant {
      id
      title
      currency
      subscription {
        wholesaleTermLength
      }
      paymentMethod {
        type
        brand
        lastFourCardDigits
        isExpiring
        expiryMonth
        expiryYear
      }
    }
    deliveryDetails {
      id
      deliveryDate
      status
      deliveredOnDate
    }
    items(
      orderBy: createdAt_DESC
    ) {
      ...DraftWholesaleOrderItemFragment
    }
    invoice {
      id
      url
      payment {
        id
      }
    }
  }
  ${DRAFT_WHOLESALE_ORDER_ITEM_FRAGMENT}
  ${WHOLESALE_ORDER_PAYMENT_INFO_FRAGMENT}
`;

export const DRAFT_WHOLESALE_ORDER = gql`
  query WholesaleOrders(
    $where: WholesaleOrderWhereInput!
  ) {
    wholesaleOrders(
      where: $where
    ) {
      ...DraftWholesaleOrderFragment
    }
  }
  ${DRAFT_WHOLESALE_ORDER_FRAGMENT}
`;

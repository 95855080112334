import { FC, Fragment, useEffect } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import CheckoutListStore from 'stores/checkout-list/checkout-list-store';

import { Container } from 'utils/css-mixins';
import { colors } from 'utils/rebass-theme';

import PlantsIconImage from 'assets/images/wholesale/no-results.gif';

import { CheckoutListProps } from 'features/lists/components/checkout-list/checkout-list.types';
import { ListsBlob } from 'features/lists/lists.styles';

import { LoadingImage } from 'components/loading-image/loading-image';
import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import { CheckoutListBody } from './components/checkout-list-body/checkout-list-body';
import { CheckoutListHeader } from './components/checkout-list-header/checkout-list-header';

const CheckoutListGroups: FC<{
  checkoutListStore?: CheckoutListStore;
}> = inject((stores: FxStores): InjectedFxStores => ({
  checkoutListStore: stores.checkoutListStore
}))(observer(({
  checkoutListStore
}) => {
  if (!checkoutListStore!.list?.suppliers?.length) return null;

  return (
    <Fragment>
      {
        checkoutListStore!.listItemGroups.map(group => {
          if (!group.listItems.length) return null;

          return (
            <CheckoutListBody
              key={group.id}
              type={group.type}
              group={group}
            />
          );
        })
      }
    </Fragment>
  );
}));

const NoResults: FC<{
  checkoutListStore?: CheckoutListStore;
}> = inject((stores: FxStores): InjectedFxStores => ({
  checkoutListStore: stores.checkoutListStore
}))(observer(({
  checkoutListStore
}) => {
  if (!!checkoutListStore!.list?.suppliers?.[0]?.active) return null;

  const copy = ((): { heading: string; description: string } => {
    switch (true) {
      case !!checkoutListStore!.list && !checkoutListStore!.list.suppliers?.length: {
        return {
          heading: 'Please select a supplier',
          description: 'For us to be able to build your basket, we need a supplier to be selected'
        };
      }

      case !checkoutListStore!.list?.suppliers?.[0]?.active: {
        return {
          heading: 'Your selected supplier is inactive',
          description: `It's not possible to place a pre-order with this supplier. Please select another from the dropdown in order to see your basket.`
        };
      }

      default: {
        return {
          heading: 'Having trouble building your basket',
          description: 'Oops, we cannot build your checkout basket at the moment, please check back later'
        };
      }
    }
  })();

  return (
    <Box pt="50px">
      <NoResultsGeneric
        size="small"
        isFullWidth={true}
        image={PlantsIconImage}
        heading={copy.heading}
        copy={copy.description}
      />
    </Box>
  );
}));

const CheckoutList: FC<CheckoutListProps> = ({
  listId,
  checkoutListStore
}) => {
  useEffect(() => {
    fetchData();

    return (): void => {
      checkoutListStore!.clearList();
    };
  }, []);

  const fetchData = async (): Promise<void> => {
    if (listId) {
      const list = await checkoutListStore!.checkAvailability({ listId });
      checkoutListStore!.fetchDeliveryDates({ listId });

      checkoutListStore!.fetchSuppliers({
        id_in: list?.merchant?.wholesaleDeliveryConfigs?.[0]?.hubs?.map(hub => hub!.supplier.id) || []
      });
    }
  };

  return (
    <Fragment>
      <ListsBlob
        fill={colors.floomCyan}
      />
      <Container>
        <CheckoutListHeader />
        <WithLoading
          loaderSize="small"
          marginTop="30px"
          isLoading={checkoutListStore!.isCheckingAvailability && !checkoutListStore!.list}
          hasNoResults={!checkoutListStore!.list?.suppliers?.length || !checkoutListStore!.list.suppliers[0].active}
          renderNoResults={() => (
            <NoResults />
          )}
          renderLoading={() => (
            <LoadingImage
              text="Checking availability..."
              imageSrc={PlantsIconImage}
              imageAlt="plants"
            />
          )}
        >
          <CheckoutListGroups />
        </WithLoading>
      </Container>
    </Fragment>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  checkoutListStore: stores.checkoutListStore
}))(observer(CheckoutList));

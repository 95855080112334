import React, { Component, ReactNode } from 'react';

import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';

import * as Styles from './dropdown-native.styles';
import * as Types from './dropdown-native.types';

export default class DropdownNative extends Component<Types.DropdownNativeProps> {
  static defaultProps = {
    fullWidth: false,
    hasError: false
  };

  onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.props.onChange(event.target.value);
  };

  getColour = (): string | undefined => {
    switch (true) {
      case this.props.hasError:
        return colors.errorText;

      case this.props.isDisabled:
        return colors.middleGrey;

      default:
        return this.props.textColor;
    }
  }

  render(): ReactNode {
    return (
      <>
        <Flex alignItems="center">
          { !!this.props.label && (
            <Styles.DropdownLabel textColor={this.props.textColor}>
              {this.props.label}
            </Styles.DropdownLabel>
          )}
          <Styles.SelectWrap
            fullWidth={this.props.fullWidth}
          >
            <Styles.Select
              name={this.props.id}
              id={this.props.id}
              onChange={this.onChange}
              fullWidth={this.props.fullWidth}
              textColor={this.getColour()}
              value={this.props.selected || ''}
              hasError={this.props.hasError}
              disabled={this.props.isDisabled}
            >
              { !!this.props.placeholder && (
                <Styles.Option
                  value=""
                  disabled={true}
                  isPlaceholder={true}
                  fullWidth={this.props.fullWidth}
                >
                  {this.props.placeholder}
                </Styles.Option>
              )}
              {
                this.props.options.map((option: Types.DropdownOption, index) => (
                  <Styles.Option
                    key={`${option[this.props.optionValueField]}-${index}`}
                    value={option[this.props.optionValueField]}
                    disabled={option.disabled || false}
                  >
                    {option[this.props.optionTitleField]}
                  </Styles.Option>
                ))
              }
            </Styles.Select>
            <Icon
              styles={Styles.DropdownArrow}
              iconName="arrow-down"
              pathFill={this.getColour()}
            />
          </Styles.SelectWrap>
        </Flex>
      </>
    );
  }
}

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { breakpoints, colors } from 'utils/rebass-theme';

export const LayoutStyles = styled.div`
  min-height: 100%;
`;

export const Main: any = styled.main`
  margin-left: 0;
  position: relative;

  @media (min-width: ${breakpoints.small}) {
    margin-left: ${(props: any): any => props.marginLeft};
  }
`;

export const AppGlobal = css`
  body {
    color: ${colors.floomMidnightBlue};
  }

  a {
    color: inherit;
  }
`;

export const MarketingGlobal = css`
  body {
    color: ${colors.floomMidnightBlue};
    overflow-x: hidden;
  }

  a {
    color: inherit;
  }
`;

export const MarketingMain = styled.main`
  padding-top: 4.5em;

  @media (min-width: 1000px) {
    padding-top: 5em;
  }
`;

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { SelectedComponentConfig } from 'stores/product-edit-store/product-edit-store-types';

import Icon from 'components/icon';

import * as Styles from './create-component-select-array.styles';
import * as Types from './create-component-select-array.types';
import CreateComponentSelectTile from './create-component-select-tile/create-component-select-tile';

class CreateComponentSelectArray extends Component<Types.CreateComponentSelectArrayProps> {
  private canAddItems = (): boolean => {
    return (this.props.defaultTab
      && this.props.selectedComponentConfigs.length <= this.props.productEditStore!.componentConfigs.length)
      && !this.props.isReadOnly;
  };

  private renderAddButton = (): ReactNode => {
    if (!this.canAddItems()) return null;

    return (
      <Styles.SelectNewItem
        as="button"
        onClick={this.props.openModal}
      >
        <Icon
          iconName="plus-small"
        />
        <p>Add stem</p>
      </Styles.SelectNewItem>
    );
  };

  private renderItems = (): ReactNode => {
    return this.props.selectedComponentConfigs.map((selectedComponent: SelectedComponentConfig) => (
      <Styles.Item key={selectedComponent.id}>
        <CreateComponentSelectTile
          defaultTab={this.props.defaultTab}
          selectedComponent={selectedComponent}
          openModal={this.props.openModal}
          deselectComponent={this.props.deselectComponent}
          updateComponent={this.props.updateComponent}
          componentValues={this.props.productEditStore!.componentConfigs.find(storeComponent => storeComponent.id === selectedComponent.id)!}
          isReadOnly={this.props.isReadOnly}
        />
      </Styles.Item>
    ));
  };

  render(): ReactNode {
    return (
      <Styles.SelectedWrapper>
        {this.renderItems()}
        {this.renderAddButton()}
      </Styles.SelectedWrapper>
    );
  }
}

export default inject('productEditStore')(observer(CreateComponentSelectArray));

import { FC } from 'react';

import {
  Image,
  Label,
  Subtitle,
  SubtitleWrapper,
  TextWrapper,
  Title,
  Wrapper,
  LastUpdatedAt
} from './entity-summary.styles';

export enum EntitySummarySize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export type EntitySummaryProps = {
  size: EntitySummarySize;
  imageUrl: string;
  title: string;
  subtitle: string;
  label?: string | React.ReactElement;
  labelColour?: string;
  lastUpdated?: string;
}

export const EntitySummary: FC<EntitySummaryProps> = ({
  size = 'medium',
  imageUrl,
  title,
  subtitle,
  label,
  labelColour,
  lastUpdated
}) => {
  return (
    <Wrapper>
      <Image
        imageUrl={imageUrl}
        componentSize={size}
      />
      <TextWrapper componentSize={size}>
        <Title componentSize={size}>
          {title}
          <LastUpdatedAt>{lastUpdated}</LastUpdatedAt>
        </Title>
        <SubtitleWrapper>
          <Subtitle componentSize={size}>{subtitle}</Subtitle>
          <Label
            colour={labelColour}
            componentSize={size}
          >
            {label}
          </Label>
        </SubtitleWrapper>
      </TextWrapper>
    </Wrapper>
  );
};

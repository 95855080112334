import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { Card } from 'utils/css-mixins/css-mixins';

export const Wrapper: any = styled(Flex)`
  ${Card};

  flex-wrap: wrap;
  margin: 10px 0;
  padding: 35px;
  flex: 1 1 100%;
  align-items: flex-start;
`;

export const MetadataWrapper: any = styled(Flex)`
  flex: 0 0 ${({ isInline }): string => isInline ? '50%' : '100%'};
  padding-right: ${({ isInline }): string => isInline ? '20px' : '0'};
  flex-wrap: wrap;

  @media (max-width: 1275px) {
    flex: 100%;
    padding-right: 0;
  }
`;

export const Usage: any = styled(Box)`
  margin-top: ${({ isInline }): string => isInline ? '0' : '40px'};
  padding-left: ${({ isInline }): string => isInline ? '20px' : '0'};
  flex: 0 0 ${({ isInline }): string => isInline ? '50%' : '100%'};

  @media (max-width: 1275px) {
    margin-top: 40px;
    flex: 100%;
    padding-left: 0;
  }
`;

import { FC } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ListItemType, WholesaleOrderLineItem } from 'generated-types.d';

import WholesaleOrdersStore from 'stores/wholesale/wholesale-orders-store/wholesale-orders-store';

import { breakpoints } from 'utils/rebass-theme';

import { ListItemValue } from 'features/lists/components/list-item-value/list-item-value';
import {
  ListCellTitle,
  ListCellSubtitle
} from 'features/lists/lists.styles';
import { ListLayoutConfig } from 'features/lists/lists.types';
import { WholesaleCheckoutHelpers } from 'features/wholesale/helpers/wholesale-helpers';

import { SingleOrderItemTitle } from './components/single-order-title';

const defaultColumnStyle = css`
  @media only screen and (max-width: ${breakpoints.medium}) {
    width: 50%;
  }
`;

export const SINGLE_ORDER_LIST_FIELD_CONFIG: ListLayoutConfig<WholesaleOrderLineItem> = {
  Default: {
    titleField: {
      key: 'itemName',
      heading: 'Item/Genus',
      renderValue: item => (<SingleOrderItemTitle orderItem={item} />)
    },
    fields: [
      {
        key: 'stemLength',
        heading: 'Stem length',
        customStyles: defaultColumnStyle,
        supportedTypes: [
          ListItemType.Flower
        ],
        renderValue: item => {
          const stemLength = (() => {
            if (item.listItem?.__typename === 'ListItemTypeFlower' && item.listItem?.minimumStemLength) {
              return item.listItem?.minimumStemLength;
            }

            if (!!item.snapshot?.stem_length) {
              return item.snapshot?.stem_length;
            }

            return null;
          })();

          return (
            <ListItemValue
              label="Stem length"
              value={stemLength ? `${stemLength}cm` : 'Any'}
            />
          );
        }
      },
      {
        key: 'potSize',
        heading: 'Pot size',
        customStyles: defaultColumnStyle,
        supportedTypes: [
          ListItemType.Plant
        ],
        renderValue: item => {
          const value = ((): string | null => {
            switch (item.listItem?.__typename) {
              case 'ListItemTypePlant':
                return item?.listItem?.potSize || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Pot size"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'weight',
        heading: 'Weight',
        customStyles: defaultColumnStyle,
        supportedTypes: [
          ListItemType.Sundry
        ],
        renderValue: ({ listItem }) => {
          const value = ((): string | null => {
            switch (listItem?.__typename) {
              case 'ListItemTypeSundry':
                return listItem?.weight || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Weight"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'height',
        heading: 'Height',
        customStyles: defaultColumnStyle,
        supportedTypes: [
          ListItemType.Plant,
          ListItemType.Sundry
        ],
        renderValue: ({ listItem }) => {
          const value = ((): string | null => {
            switch (listItem?.__typename) {
              case 'ListItemTypePlant':
              case 'ListItemTypeSundry':
                return listItem?.height || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Height"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'maturity',
        heading: 'Maturity',
        customStyles: defaultColumnStyle,
        supportedTypes: [
          ListItemType.Flower
        ],
        renderValue: ({ listItem }) => {
          const value = ((): string | null => {
            switch (listItem?.__typename) {
              case 'ListItemTypeFlower':
                return listItem?.maturity || null;

              default:
                return null;
            }
          })();

          return (
            <ListItemValue
              label="Maturity"
              value={value || 'Any'}
            />
          );
        }
      },
      {
        key: 'quantity',
        heading: 'Quantity',
        customStyles: defaultColumnStyle,
        renderValue: item => {
          return (
            <ListItemValue
              label="Quantity"
              value={`${item.quantity}`}
            />
          );
        }
      },
      {
        key: 'price',
        heading: 'Price',
        customStyles: css`
          min-width: 100px;

          @media only screen and (max-width: ${breakpoints.medium}) {
            width: 100%;
            text-align: right;
          }
        `,
        renderValue: item => {
          const Component: FC<{
            wholesaleOrdersStore?: WholesaleOrdersStore;
          }> = inject((stores: FxStores) => ({
            wholesaleOrdersStore: stores.wholesaleOrdersStore
          }))(observer(({
            wholesaleOrdersStore
          }) => {
            const merchantCurrency = wholesaleOrdersStore?.selectedOrder?.merchant?.currency;

            return (
              <Box flexGrow={1}>
                <ListCellTitle>
                  {WholesaleCheckoutHelpers.renderWholesalePrice(item.totalPrice || 0, merchantCurrency)}
                </ListCellTitle>
                <ListCellSubtitle>
                  {WholesaleCheckoutHelpers.renderWholesalePrice(item.unitPrice || 0, merchantCurrency)} per unit
                </ListCellSubtitle>
              </Box>
            );
          }));

          return (
            <Component />
          );
        }
      }
    ]
  }
};

import { Box } from 'rebass';

import Button from 'components/button';
import GenericModal from 'components/generic-modal';

import * as Styles from './modal.styles';

interface CreateSaveModalProps {
  isOpen: boolean;
  closeAction: () => any;
  setActiveAndSave: (setActive: boolean) => any;
}

export const CreateSaveModal = (props: CreateSaveModalProps): JSX.Element => {
  return (
    <GenericModal
      width={430}
      innerComponent={(
        <Styles.ConfirmationBox>
          <p>Do you want to put your product up for sale?</p>
        </Styles.ConfirmationBox>
      )}
      confirmButtonAction={(): void => props.setActiveAndSave(true)}
      confirmButtonText="Yes"
      title="Available?"
      modalOpen={props.isOpen}
      closeModal={props.closeAction}
      extraAction={(
        <Box onClick={(): void => props.setActiveAndSave(false)}>
          <Button
            size="normal"
            appearance="secondary"
            copy="No"
            isLoading={false}
            isDisabled={false}
          />
        </Box>
      )}
    />
  );
};

import { action, observable, makeObservable } from 'mobx';

import { Merchant } from 'generated-types.d';

export default class CustomSiteSettingsStore {
  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isConfirmingMailchimp: observable,
      mailChimpConfirmationError: observable,
      merchantList: observable,
      getMerchantList: action,
      resetLoading: action
    });
  }

  isLoading: boolean = true;

  isConfirmingMailchimp: boolean = true;

  mailChimpConfirmationError: { code: number; message: string } | null = null;

  merchantList: Merchant[] = [];

  public toggleConfirmingMailchimp = (): void => {
    this.isConfirmingMailchimp = !this.isConfirmingMailchimp;
  };

  public resetMailchimpError = (): void => {
    this.mailChimpConfirmationError = null;
  };

  public setMailchimpError = (e: any): void => {
    switch (e.graphQLErrors[0].code) {
      case 77046113055:
        this.mailChimpConfirmationError = {
          code: e.graphQLErrors[0].code,
          message: 'We cannot integrate with Mailchimp at the moment. Please try again later.'
        };

        break;

      case 77046113060:
        this.mailChimpConfirmationError = {
          code: e.graphQLErrors[0].code,
          message: 'Could not create a new list within Mailchimp. This may be due to invalid configuration or your List limit may have been reached.'
        };

        break;

      case 77046113058:
        this.mailChimpConfirmationError = {
          code: e.graphQLErrors[0].code,
          message: 'Could not retrieve information from Mailchimp. Please try again.'
        };

        break;

      case 77046113062:
        this.mailChimpConfirmationError = {
          code: e.graphQLErrors[0].code,
          message: 'Could not add a user to the Mailchimp List that has been created. Please delete List and try again.'
        };

        break;

      case 77046113064:
        this.mailChimpConfirmationError = {
          code: e.graphQLErrors[0].code,
          message: 'Could not initiate Mailchimp integration. Merchant configuration invalid.'
        };

        break;

      default:
        this.mailChimpConfirmationError = {
          code: e.graphQLErrors[0].code,
          message: 'Something went wrong. Please try again.'
        };
    }
  };

  public getMerchantList = (merchants: Merchant[]): void => {
    this.merchantList = merchants;
    this.resetLoading();
  };

  public resetLoading = (): void => {
    this.isLoading = false;
  };
}

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { TouchScrollable } from 'react-scrolllock';

import { TEST_IDS } from 'utils/test/data-test-ids';

import Header from 'components/header';
import Overlay from 'components/overlay';

import AccountModal from './components/account-modal';
import Logout from './components/logout';
import MerchantToggle from './components/merchant-toggle/merchant-toggle';
import NavHeader from './components/nav-header';
import NavList from './components/nav-list';
import * as Styles from './navigation.styles';
import * as Types from './navigation.types';

class Navigation extends Component<Types.NavigationProps> {
  state = {
    isAtBottom: false
  };

  componentDidMount = (): void => {
    this.handleInitialScroll();
  };

  private handleInitialScroll = (): void => {
    const wrappedElement = document.getElementById('fx-nav-scroll-area');

    if (wrappedElement) {
      this.calculateScrollState(wrappedElement);
    }
  };

  private calculateScrollState = (target: HTMLElement): void => {
    const scrollPosition = target?.scrollHeight - target?.scrollTop;
    const clientHeight = target?.clientHeight;

    if (scrollPosition === clientHeight && !this.state.isAtBottom) {
      this.setState({
        isAtBottom: true
      });
    } else if (scrollPosition !== clientHeight && this.state.isAtBottom) {
      this.setState({
        isAtBottom: false
      });
    }
  };

  private handleScroll = (e: any): void => {
    this.calculateScrollState(e.target);
  };

  render(): React.ReactNode {
    const width = this.props.navigationStore!.navWidth;

    return (
      <>
        <Styles.HeaderWrapper>
          <Header />
        </Styles.HeaderWrapper>
        <Styles.NavWrapper
          isMenuOpen={this.props.uiStore!.isMenuOpen}
          width={width}
        >
          <NavHeader onClickCloseIcon={this.props.uiStore!.toggleMenu} />
          <TouchScrollable isActive={this.props.uiStore!.isMenuOpen}>
            <Styles.ScrollArea
              onScroll={this.handleScroll}
              id="fx-nav-scroll-area"
            >
              <NavList />
              <Styles.FixedWrapper
                width={width}
                displayShadow={!this.state.isAtBottom}
              >
                <MerchantToggle />
                <Styles.LogoutButton
                  onClick={this.props.uiStore!.toggleAccountModal}
                  data-testid={TEST_IDS.Menu.account}
                >
                  <Logout />
                </Styles.LogoutButton>
              </Styles.FixedWrapper>
            </Styles.ScrollArea>
          </TouchScrollable>
        </Styles.NavWrapper>
        <Styles.OverlayWrapper>
          <Overlay
            isActive={this.props.uiStore!.isMenuOpen}
            onClickOverlay={this.props.uiStore!.toggleMenu}
          />
        </Styles.OverlayWrapper>
        <AccountModal />
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  uiStore: stores.uiStore
}))(observer(Navigation));

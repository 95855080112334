import React, { ReactNode } from 'react';

import FieldText from 'components/field-text';

import * as Styles from './wholesale-unit-field.styles';
import * as Types from './wholesale-unit-field.types';

export default class WholesaleUnitField extends React.PureComponent<Types.WholesaleUnitFieldProps> {
  static defaultProps: Partial<Types.WholesaleUnitFieldProps> = {
    unit: ''
  };

  render(): ReactNode {
    return (
      <Styles.UnitWrapper>
        <p>
          Number of units:
        </p>
        <Styles.InputWrapper>
          <FieldText
            size="normal"
            value={this.props.unit}
            type="number"
            placeholder="0"
            onChange={(event): any => {
              this.props.handleChange(event.target.value);
            }}
          />
        </Styles.InputWrapper>
      </Styles.UnitWrapper>
    );
  }
}

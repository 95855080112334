import _get from 'lodash.get';

const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location: currentLocation }
}: any): any => {
  const preventScroll = _get(currentLocation, 'state.preventScroll');
  const prevPreventScroll = _get(prevRouterProps, 'location.state.preventScroll');

  return !(preventScroll || prevPreventScroll);
};

export default shouldUpdateScroll;

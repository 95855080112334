import styled from '@emotion/styled';
import { Box } from 'rebass';

import { breakpoints, space } from 'utils/rebass-theme';

export const DateCountdownWrapper = styled(Box)`
  @media only screen and (max-width: ${breakpoints.medium}) {
    width: 100%;
    margin-top: ${space[3]}px;
  }
`;

import styled from '@emotion/styled';
import { GatsbyLinkProps } from 'gatsby';
import { Box } from 'rebass';

import { breakpoints, colors, shadows } from 'utils/rebass-theme';

export const CloseIconContainer = styled(Box)<GatsbyLinkProps<Record<string, never>>>`
  position: absolute;
  z-index: 10;
  padding: 10px;
  background: ${colors.uiFills};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  text-align: center;
  right: 0;
  margin: 10px;
  box-shadow: ${shadows.small};
`;

export const RightColumn = styled(Box)`
  width: 50%;
  padding: 30px 30px 30px 15px;
`;

export const LeftColumn = styled(Box)`
  width: 50%;
  padding: 30px 15px 30px 30px;
`;

export const NotFoundWrapper = styled(Box)`
  width: 100%;
  margin: auto;
  padding: 30px;
`;

export const NoResults = styled(Box)`
  margin-top: 50px;
`;

export const WholesaleShopListingContainer = styled(Box)`
  width: 100%;

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    flex-direction: column;
  }
`;

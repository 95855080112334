import styled from '@emotion/styled';
import { Box, Text } from 'rebass';

export const OnboardingSubscriptionIssue: any = styled(Text)`
  text-align: center;
  margin: 50px 0;
`;

export const CopyBody: any = styled(Text)`
  max-width: 430px;
`;

export const ButtonContinue: any = styled(Box)`
  margin: auto;
  display: inline-block;
`;

export const Heading: any = styled(Text)`
  text-align: center;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { ListType } from 'generated-types.d';

import {
  shadows,
  colors,
  space,
  textStyles
} from 'utils/rebass-theme';

export const ListItemContainer = styled(Flex)`
  border-radius: 10px;
  background-color: ${colors.white};
  box-shadow: ${shadows.small};
  margin-bottom: ${space[2]}px;
  display: flex;
  justify-content: space-between;
`;

export const EntitySummaryWrapper = styled.div`
  flex: 1;
  cursor: pointer;
`;

export const ListItemButtonWrapper = styled.div`
  margin: 0 ${space[2]}px;
`;

export const BinIconWrapperStyles: any = css`
  padding: ${space[2]}px;
`;

export const ListItemAvatar = styled.div<{ imageUrl?: string }>`
  position: relative;
  display: table;
  overflow: hidden;
  background-color: ${colors.middleGrey};
  width: 55px;
  height: 55px;
  border-radius: 8px;
  margin: 5px;
  background-image: ${({ imageUrl }) => !!imageUrl ? `url(${imageUrl})` : 'none'};
  background-size: cover;
  background-position: center;
`;

export const ListLinkWrapper: any = styled(Box)`
  ${textStyles.subhead};
  line-height: 1.1;
  position: relative;
  cursor: pointer;
  padding-bottom: 2px;
  color: ${colors.floomMidnightBlue};
`;

export const ListLinkDescription: any = styled(Box)`
  color: ${colors.grey};
  margin-top: 2px;
  font-size: 12px;
`;

export const ItemCountContainer = styled(Flex)`
  min-width: 110px;
  height: 32px;
  background-color: ${props => props.type === ListType.Simple ? colors.paleGrey : colors.floomYellow};
  padding: 12px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ItemCountText = styled.div`
  margin-left: 10px;
`;

export const ListMenuContainer = styled(Flex)`
  border-radius: 10px;
  background-color: ${colors.white};
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  margin-left: 30px;
  margin-top: 5px;
  cursor: pointer;
  display: ${props => props.visible === true ? 'block' : 'none'};
  z-index: 1000;
  position: absolute;
  padding: 5px 10px;
`;

export const ListMenuItem = styled(Flex)`
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;

  + ${(): any => ListMenuItem} {
    border-top: 1px solid ${colors.paleGrey};
  }
`;

export const ListMenuIcon = styled(Flex)`
  padding: 5px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  background-color: ${props => props.type === ListType.Simple ? colors.paleGrey : colors.floomYellow};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;

export const ListMenuContent = styled.div``;

export const ListMenuHeader = styled.div`
  font-size: 14px;
`;

export const ListMenuDescription = styled.div`
  margin-top: 5px;
  font-size: 11px;
  color: ${colors.grey};
`;

import { gql } from '@apollo/client';

export const GET_ALL_MERCHANTS_CONFIGS = gql`
  query Merchants(
    $active: Boolean!
    ) {
      merchants(
        first: 1000
        where: {
          active: $active
        }
        orderBy: title_ASC
      ) {
        id
        title
        active
        customSiteConfig {
          customSiteProjectId
          customSiteProjectUrl
          mailchimpInformation {
            id
          }
        }
      }
    }
`;

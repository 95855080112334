import React, { memo } from 'react';

import _startCase from 'lodash.startcase';
import {
  connectCurrentRefinements,
  CurrentRefinementsProvided
} from 'react-instantsearch-core';
import { Box } from 'rebass';

import Filters from 'components/filters';

import { RefinementList } from './refinement-list';

interface FilterTabsProps extends CurrentRefinementsProvided {
  isVisible: boolean;
  onSelect: () => void;
}

export const FilterTabs = connectCurrentRefinements(memo(({
  isVisible,
  items,
  onSelect,
  refine
}: FilterTabsProps): JSX.Element => {
  return (
    <Filters
      hideBorders={true}
      isVisible={isVisible}
      totalSelectedFilters={items.length}
      clearFilters={(): any => {
        refine(items);
        onSelect();
      }}
    >
      <Box label="Status">
        <RefinementList
          attribute="status.title"
          operator="or"
          onSelect={onSelect}
          name="status"
        />
      </Box>
      <Box label="Currency">
        <RefinementList
          attribute="currency"
          operator="or"
          onSelect={onSelect}
          name="currency"
        />
      </Box>
      <Box label="Delivery type">
        <RefinementList
          attribute="nationwide"
          operator="or"
          onSelect={onSelect}
          name="delivery type"
        />
      </Box>
      <Box label="Address type">
        <RefinementList
          attribute="commercial"
          operator="or"
          onSelect={onSelect}
          name="address type"

        />
      </Box>
      <Box label="Channel">
        <RefinementList
          attribute="channel"
          operator="or"
          onSelect={onSelect}
          name="channel"
        />
      </Box>
      <Box label="Timezone">
        <RefinementList
          attribute="merchant.timezone"
          operator="or"
          onSelect={onSelect}
          name="timezone"
        />
      </Box>
    </Filters>
  );
}));

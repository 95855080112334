import { FC, Fragment } from 'react';

import { graphql, PageProps } from 'gatsby';

import { StandardPageQueryData } from 'features/marketing/marketing.types';
import { HomePage } from 'features/marketing/pages/home/home-page';

const Home: FC<PageProps<StandardPageQueryData>> = props => {
  return (
    <Fragment>
      <HomePage
        pageProps={props}
      />
    </Fragment>
  );
};

export default Home;

export const pageQuery = graphql`
  query {
    datoCmsPage(
      slug: {
        eq: "home"
      },
      locale: {
        eq: "en-GB"
      }
    ) {
      ...StandardPage
    }
  }
`;

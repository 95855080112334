import React, { ReactNode } from 'react';

import { DeliveryCoverage } from 'generated-types';
import { inject, observer } from 'mobx-react';
import { Text, Box } from 'rebass';

import { PermissionsService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import DeliveryService from 'features/settings/components/delivery/services';

import CheckboxList from 'components/checkbox-list';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import SectionHeading from 'components/section-heading';

import * as Types from './delivery-edit-channel.types';

interface DeliveryAreaItem extends DeliveryCoverage {
  originalLabel: string | null | undefined;
  isDisabled: boolean;
}

class DeliveryConfigEditChannel extends React.Component<Types.DeliveryConfigEditChannel> {
  private ERROR_COPY = (channel: string): string => {
    switch (true) {
      case PermissionsService.isInternalRole():
        return `Hey! You need to create a delivery coverage area for ${channel} before you can create a delivery option`;

      default:
        return `Oh dear, you are not currently set up with any coverage delivery areas for '${channel}'. Please contact FloomX support who can do this for you.`;
    }
  };

  private renderChannel = (): string => {
    return this.props.deliveryStore!.selectedChannel === 'Floom' ? 'Floom.com' : this.props.merchantStore!.customSiteUrlDisplay || 'CustomWebsite.com';
  };

  private getDeliveryAreaItems = (): DeliveryAreaItem[] => {
    const deliveryCoveragesForChannel = DeliveryService.getDeliveryAreas(this.props.deliveryStore!.selectedChannel!);
    const selectedCoverages = deliveryCoveragesForChannel.filter(coverage => {
      return this.props.deliveryStore!.selectedDeliveryCoverages.includes(coverage.id);
    });

    return deliveryCoveragesForChannel.map(item => {
      const hasExpandedCoverages = deliveryCoveragesForChannel.some(coverage => coverage.type === 'Expanded');
      const isDisabled =  hasExpandedCoverages && selectedCoverages.some(coverage => coverage.type !== item.type);
      const baseLabel = `${item.title} - ${item.label}`;

      return {
        ...item,
        originalLabel: item.label,
        label: hasExpandedCoverages ? `${baseLabel} (${item.type})` : baseLabel,
        isDisabled: isDisabled
      };
    });
  };

  private renderCheckboxList = (hasDisabledAreas: boolean, deliveryAreaItems: DeliveryAreaItem[]): ReactNode => {
    return (
      <>
        { hasDisabledAreas && (
          <Box
            mt="30px"
            mb="30px"
          >
            <Notification
              copy="Some areas have been disabled because only one type of delivery area can be attached to&nbsp;a&nbsp;config"
              type={NotificationType.Info}
              textAlign="left"
              hasIcon={false}
              hasClose={false}
            />
          </Box>
        )}
        <CheckboxList
          items={deliveryAreaItems}
          selectedItems={this.props.deliveryStore!.selectedDeliveryCoverages}
          itemValueField="id"
          optionTitleField="label"
          orientation="horizontal"
          onChange={this.props.deliveryStore!.setSelectedDeliveryCoverages}
          error={!this.props.deliveryStore!.selectedDeliveryCoverages.length}
          errorMessage="Please select at least one delivery area"
        />
      </>
    );
  };

  private renderError = (channel: string): ReactNode => {
    return (
      <Box mt="30px">
        <Notification
          type={NotificationType.Error}
          copy={this.ERROR_COPY(channel)}
          textAlign="left"
          hasClose={false}
        />
      </Box>
    );
  };

  render(): ReactNode {
    const deliveryAreaItems = this.getDeliveryAreaItems();
    const channel = this.renderChannel();
    const hasDisabledAreaItems = deliveryAreaItems.some(item => item.isDisabled);

    return (
      <>
        <SectionHeading title="Channel" />
        <Text css={textStyles.body}>
          {channel}
        </Text>
        <SectionHeading title="Delivery area" />
        {!!deliveryAreaItems && deliveryAreaItems.length
          ? this.renderCheckboxList(hasDisabledAreaItems, deliveryAreaItems)
          : this.renderError(channel)
        }
      </>
    );
  }
}

export default inject('deliveryStore', 'merchantStore')(observer(DeliveryConfigEditChannel));

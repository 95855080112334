import { createContext } from 'react';

type ContactContextProps = {
  selectedContactId: string | null | undefined;
  view: ConversationView;
  setView: (view: ConversationView) => void;
}

export enum ConversationView {
  CONTACTS = 'CONTACTS',
  CONTACTS_ONLY = 'CONTACTS_ONLY',
  MESSAGES = 'MESSAGES'
}

export const ContactContext = createContext<ContactContextProps>({
  selectedContactId: undefined,
  view: ConversationView.CONTACTS,
  setView: () => {}
});

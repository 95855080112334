import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import pluralize from 'pluralize';
import { Box } from 'rebass';

import {
  CurrencyService,
  PermissionsService
} from 'lib';

import { TEST_IDS } from 'utils/test/data-test-ids';

import Icon from 'components/icon';

import * as Styles from './overview-heading.styles';
import * as Types from './overview-heading.types';

class OverviewHeading extends Component<Types.OverviewHeadingProps> {
  private shouldRenderPlanButton = (): boolean => {
    return !!this.props.merchantStore!.merchant?.plan?.id && !PermissionsService.isInternalRole();
  };

  private renderPlanButton = (): ReactNode => {
    if (!this.shouldRenderPlanButton()) return null;

    return (
      <Styles.MyPlan>
        <Link to={NavPages.MyPlan}>
          <Icon
            iconName="my-plan"
            styles={css`
              width: 28px;
              height: 28px;
            `}
          />
          <p>My Plan</p>
        </Link>
      </Styles.MyPlan>
    );
  };

  private shouldRenderSalesDetails= (): boolean => {
    return PermissionsService.isInternalRole() || this.props.completedOrderCount > 0;
  };

  private renderSalesDetails = (): ReactNode => {
    if (!this.props.merchantStore!.merchant || !this.shouldRenderSalesDetails()) return null;

    return (
      <Styles.PageHeadingDetails>
        <Styles.TotalSales>
          <Styles.TotalSalesHeading>
            {`Total sales in last 30 days:`}
          </Styles.TotalSalesHeading>
          <Styles.CompletedOrderCount>
            {`${this.props.completedOrderCount} ${pluralize('order', this.props.completedOrderCount)}`}
          </Styles.CompletedOrderCount>
        </Styles.TotalSales>
        <Box
          ml="40px"
          mt="-5px"
        >
          <Styles.CompletedOrderPrice>
            {CurrencyService.formatPrice(
              this.props.completedOrdersCost,
              this.props.merchantStore!.merchant.currency
            )}
          </Styles.CompletedOrderPrice>
        </Box>
      </Styles.PageHeadingDetails>
    );
  };

  render(): ReactNode {
    return(
      <Styles.Wrapper>
        <Styles.OverviewHeading
          alignItems="center"
          flexWrap="wrap"
          flex="1"
        >
          <Styles.PageHeadingContainer>
            <Styles.PageHeadingIntro data-testid={TEST_IDS.Overview.merchantName}>
              Hi {this.props.userStore!.givenName}
            </Styles.PageHeadingIntro>
            <Box
              minWidth="120px"
            >
              {this.renderPlanButton()}
            </Box>
          </Styles.PageHeadingContainer>
          {this.renderSalesDetails()}
        </Styles.OverviewHeading>
      </Styles.Wrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  userStore: stores.userStore,
  merchantStore: stores.merchantStore
}))(observer(OverviewHeading));

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const StatusIndicator: any = styled(Box)`
  ${textStyles.body};
  margin-left: 5px;
  color: ${({ config }: any): any => config.color};
`;

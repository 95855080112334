
import { ApolloError } from '@apollo/client';
import { CourierBookingConnection } from 'generated-types';
import store from 'stores';

import { GraphQL } from 'lib';

import {
  CourierListType
} from 'stores/courier-list/courier-list-store.types';

import {
  SelectedCourierListFilters,
  CourierListGroupOption
} from 'features/courier-bookings/courier-bookings.types';
import {
  COURIER_LIST_QUERY,
  PAGINATE_BOOKINGS
} from 'features/courier-bookings/graphql/queries/courier-queries';

class CourierListAPIService {
  private CourierListStore = store.courierListStore;

  private ToasterStore = store.toasterStore;

  private UserStore = store.userStore;

  public paginateBookings = async (
    variables: any,
    groupName: CourierListGroupOption
  ): Promise<CourierBookingConnection> => {
    const vars = this.buildVariables(variables);

    return GraphQL.query(PAGINATE_BOOKINGS(vars, groupName, this.CourierListStore.listLayout), vars)
      .then((results: any) => results.data.courierBookingsConnection)
      .catch(error => {
        this.ToasterStore.popErrorToast('more bookings', 'get');

        return Promise.reject(error);
      });
  };

  public fetchCourierList = async (variables: any = {}): Promise<CourierListType> => {
    const vars = this.buildVariables(variables);

    return GraphQL.query(COURIER_LIST_QUERY(this.CourierListStore.listLayout, vars), vars, 'no-cache')
      .then((results: { data: CourierListType }): any => results.data)
      .catch((error: ApolloError) => {
        this.ToasterStore.popErrorToast('your courier bookings', 'retrieve');

        return Promise.reject(error);
      });
  };

  private buildVariables = (vars: any = {}): any => {
    return  {
      ...vars,
      merchantId: this.UserStore.merchantId,
      filters: this.CourierListStore.selectedCourierListFilters as SelectedCourierListFilters
    };
  };
}

export default new CourierListAPIService();

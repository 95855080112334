export default class ColourService {
  /**
   * {@link https://codepen.io/davidhalford/pen/ywEva}
   */
  public static isLowContrast = (hex: string): boolean => {
    const threshold = 170; /* about half of 256. Lower threshold equals more dark text on dark background  */

    const hRed = ColourService.hexToR(hex);
    const hGreen = ColourService.hexToG(hex);
    const hBlue = ColourService.hexToB(hex);
    const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;

    return cBrightness > threshold;
  };

  private static hexToR = (h: string): number => {
    return parseInt((ColourService.cutHex(h)).substring(0, 2), 16);
  };

  private static hexToG = (h: string): number => {
    return parseInt((ColourService.cutHex(h)).substring(2, 4), 16);
  };

  private static hexToB = (h: string): number => {
    return parseInt((ColourService.cutHex(h)).substring(4, 6), 16);
  };

  private static cutHex = (h: string): string => {
    return (h.charAt(0) === '#') ? h.substring(1, 7) : h;
  };
}

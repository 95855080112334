
import store from 'stores';

import { UpdatePasswordInput, UserUpdateInput } from 'generated-types.d';

import { GraphQL, Auth } from 'lib';

import { PASSWORD_RESET_MUTATION, USER_UPDATE_MUTATION } from './user-apollo.mutators';
import { USER_DETAILS_QUERY } from './user-apollo.queries';

export default class UserService {
  accountStore = store.accountStore;

  userStore = store.userStore;

  toasterStore = store.toasterStore;

  snapshotState = '';

  public init(): void {
    if (Auth.isLoggedIn()) {
      this.accountStore.setAccountLoggedIn(true);
    }
  }

  public async populateUserDetails(): Promise<any> {
    if (Auth.isLoggedIn()) {
      await GraphQL.query(USER_DETAILS_QUERY({ merchantId: Auth.getUserMerchantId() }), {
        id: Auth.getUserID(),
        merchantId: Auth.getUserMerchantId()
      }, 'no-cache')
        .then(result => {
          this.userStore.setAccountNames(result.data.user.givenName, result.data.user.familyName);
          this.accountStore.setAccountEmail(result.data.user.email);
          this.accountStore.setAccountFamilyName(result.data.user.familyName);
          this.accountStore.setAccountGivenName(result.data.user.givenName);

          if (result.data.user.team) {
            this.accountStore.setAccountTeam(result.data.user.team.id, result.data.user.team.title);
          }

          if (result.data.user.merchant) {
            this.accountStore.setAccountMerchant(result.data.user.merchant);
          }

          this.accountStore.setAccountJobPosition(result.data.user.jobPosition);
          this.accountStore.setAccountSecondaryEmail(result.data.user.secondaryEmail);
          this.accountStore.setAccountPhone(result.data.user.phone);
          this.accountStore.setAccountSecondaryPhone(result.data.user.secondaryPhone);
          this.snapshotState = this.accountStore.getAccountStoreSnapshot();
        });
    }
  }

  public async saveUserChanges(userUpdateArgs: UserUpdateInput): Promise<any> {
    await GraphQL.mutate(USER_UPDATE_MUTATION, {
      args: userUpdateArgs,
      where: { id: Auth.getUserID() }
    })
      .then(async () => {
        await this.populateUserDetails();
        this.toasterStore.popSuccessToast('Account', 'update');
      })
      .catch(() => {
        this.toasterStore.popErrorToast('Account', 'update');
      });
  }

  public async submitChangePassword(currentPassword: string, newPassword: string): Promise<number | null> {
    const args: UpdatePasswordInput = {
      newPassword: newPassword,
      oldPassword: currentPassword
    };

    return GraphQL.mutate(PASSWORD_RESET_MUTATION, {
      args: args,
      where: {
        id: Auth.getUserID()
      }
    })
      .then(result => {
        const token = result.data.updatePassword.token;
        Auth.setLoginToken(token);
        this.toasterStore.popSuccessToast('Password', 'update');

        return null;
      })
      .catch(error => {
        if (error.graphQLErrors) {
          return error.graphQLErrors[0].code;
        }
      });
  }
}

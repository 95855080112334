import {
  ClipboardEvent,
  Dispatch,
  Fragment,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
  SetStateAction
} from 'react';

import { css } from '@emotion/react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';

import { Attachment } from 'types/conversations.types';

import { useRefCallback } from 'hooks/useRefCallback/useRefCallback';

import Icon from 'components/icon';

import {
  convertOnPaste,
  convertInputHTML
} from './conversation-input.helpers';
import {
  ChatInput,
  Controls,
  InputError,
  SubmitButton,
  InputContainer
} from './conversation-input.styles';

const MAXIMUM_MESSAGE_LENGTH = 1600;

const getCaretIndex = (element: HTMLDivElement | null): number => {
  const isSupported = typeof window.getSelection !== 'undefined';

  if (isSupported && element) {
    const selection = window.getSelection();

    if (selection?.rangeCount !== 0) {
      const range = window?.getSelection?.()?.getRangeAt?.(0);
      const preCaretRange = range?.cloneRange();
      preCaretRange?.selectNodeContents(element);
      preCaretRange?.setEnd(range?.endContainer!, range?.endOffset!);

      return preCaretRange?.toString?.()?.length || 0;
    }

    return 0;
  }

  return 0;
};

interface ConversationInputProps {
  attachments?: Attachment[];
  characterLimit?: number;
  onSubmit: (value: string) => Promise<void>;
  setValue: Dispatch<SetStateAction<string>>;
  value: string;
}

export const ConversationInput: React.FC<ConversationInputProps> = ({
  attachments,
  characterLimit = 9999,
  onSubmit,
  setValue,
  value
}) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isValueInvalid, setIsValueInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsSubmitDisabled(getIsSubmitDisabled());
  }, [attachments, value]);

  const inputRef = useRef<HTMLDivElement | null>(null);

  const characterLimitCopy = useMemo(() => {
    return `Your message is ${value.length - characterLimit} characters over the ${characterLimit} character limit`;
  }, [value.length]);

  const getIsSubmitDisabled = (inputValue = value): boolean => {
    if (attachments?.length && !isValueInvalid) {
      return false;
    }

    return !inputValue?.length
      || isValueInvalid;
  };

  const getIsValueInvalid = (inputValue: string): boolean => {
    if (inputValue.length > MAXIMUM_MESSAGE_LENGTH || inputValue.length > characterLimit) {
      return true;
    }

    return false;
  };

  const handleOnChange = useRefCallback(({
    target: {
      value: inputValue
    }
  }: ContentEditableEvent): void => {
    setValue(inputValue);
    setIsSubmitDisabled(() => getIsSubmitDisabled(inputValue));
    setIsValueInvalid(() => getIsValueInvalid(inputValue));
  }, []);

  const handleOnKeyDown = useRefCallback((e: KeyboardEvent<HTMLDivElement>): void => {
    const isShiftKeyPressed = e.shiftKey;
    const isReturnKey = e.key === 'Enter';
    const hasText = !!value.length;
    const shouldSubmit = !isShiftKeyPressed && isReturnKey && hasText;
    const shouldBlockAction = e.key === 'Enter' && !value.length;

    switch (true) {
      case shouldSubmit: {
        e.preventDefault();
        handleSubmit();

        break;
      }

      case shouldBlockAction: {
        e.preventDefault();

        break;
      }
    }
  }, [value]);

  const handleOnKeyUp = (e: KeyboardEvent<HTMLDivElement>): void => {
    const isShiftKeyPressed = e.shiftKey;
    const isReturnKey = e.key === 'Enter';

    if (isShiftKeyPressed && isReturnKey) {
      scrollInputToBottom();
    }
  };

  const handleOnPaste = useRefCallback((event: ClipboardEvent<HTMLDivElement>): void => {
    const caretPosition = getCaretIndex(inputRef?.current!);
    const isCaretAtEnd = value.length === caretPosition;

    convertOnPaste(event);

    if (isCaretAtEnd) {
      scrollInputToBottom();
    }
  }, [value]);

  const handleSubmit = useRefCallback(async (): Promise<void> => {
    if (!isSubmitDisabled && !isValueInvalid) {
      const sanitizedMessage = convertInputHTML(value);

      try {
        setIsLoading(() => true);

        await onSubmit(convertInputHTML(sanitizedMessage));

        setIsLoading(() => false);

        // Instantiate last - otherwise input will lose focus
        resetInput();
      } catch (error) {
        setIsLoading(() => false);
        inputRef?.current?.focus?.();
      }
    }
  });

  const scrollInputToBottom = (): void => {
    inputRef?.current?.scrollTo?.({
      top: inputRef?.current?.scrollHeight,
      behavior: 'auto'
    });
  };

  const resetInput = (): void => {
    setValue('');
    setIsSubmitDisabled(() => true);
    inputRef?.current?.focus?.();
  };

  return (
    <Fragment>
      <InputContainer>
        {isValueInvalid && (
          <InputError>
            {characterLimitCopy}
          </InputError>
        )}
        <ContentEditable
          id="conversation-input"
          placeholder="Type message..."
          tagName="div"
          spellCheck="false"
          html={value}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onKeyUp={handleOnKeyUp}
          onPaste={handleOnPaste}
          css={ChatInput({ isLoading })}
          disabled={isLoading}
          innerRef={inputRef}
        />
      </InputContainer>
      <Controls>
        <SubmitButton
          onClick={handleSubmit}
          disabled={isSubmitDisabled || isLoading}
          isInvalid={isValueInvalid}
        >
          <Icon
            iconName="paper-plane"
            styles={css`
              width: 100%;
            `}
          />
        </SubmitButton>
      </Controls>
    </Fragment>
  );
};

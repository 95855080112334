import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { PermissionsService, PeakFeatureToggle } from 'lib';

import SettingsTemplatePage from 'features/settings/components/template/settings-template-page';

import NoResultsGeneric from 'components/no-results-generic';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import WithLoading from 'components/with-loading';

import DeliveryCoverageItem from './delivery-coverage-item';
import * as Types from './delivery-coverage.types';

class DeliveryCoverageSettings extends Component<Types.DeliveryCoverageSettingsProps> {
  private triggerModal = (): any => {
    this.props.modalStore!.triggerModal({
      modalType: 'deliveryCoverage',
      data: this.props.merchantStore!.merchant!
    });
  };

  private renderDeliveryCoverageItems = (): ReactNode => {
    return this.props.deliveryStore!.deliveryCoverages.map(coverage => {
      return (
        <DeliveryCoverageItem
          key={coverage.id}
          deliveryCoverage={coverage}
        />
      );
    });
  };

  private openModal = (): void => {
    if (!PeakFeatureToggle.isDisabledDuringPeak()) {
      this.triggerModal();
    } else {
      this.props.toasterStore!.popNotificationToast(PeakFeatureToggle.setFeatureToggleNotification());
    }
  };

  private renderNoResults = (): ReactNode => {
    return (
      <>
        { (!this.props.merchantStore!.merchant && PermissionsService.isInternalRole()) && (
          <Box mb="20px">
            <Notification
              type={NotificationType.Error}
              hasIcon={false}
              hasClose={false}
              copy="Looks like you don't have a merchant selected. Please select one to see their coverages"
            />
          </Box>
        )}
        <NoResultsGeneric
          icon="leaf-no-results"
          heading="No delivery coverages"
          copy=""
        />
      </>
    );
  };

  render(): ReactNode | null {
    const createProps = PermissionsService.isInternalRole()
      ? {
        headerButtonCopy: 'New Coverage',
        onHeaderAction: this.openModal
      } : {};

    return (
      <SettingsTemplatePage
        title="Delivery coverage areas"
        {...createProps}
      >
        <Box mt="40px">
          <WithLoading
            hasNoResults={!this.props.deliveryStore!.isLoadingDeliveryCoverages && !this.props.deliveryStore!.deliveryCoverages.length}
            isLoading={this.props.deliveryStore!.isLoadingDeliveryCoverages}
            renderNoResults={this.renderNoResults}
          >
            {this.renderDeliveryCoverageItems()}
          </WithLoading>
        </Box>
      </SettingsTemplatePage>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  deliveryStore: stores.deliveryStore,
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(DeliveryCoverageSettings));

import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';
import camelCase from 'lodash.camelcase';

import { GraphQLHelpers } from 'lib';

import { ProductFilterType } from 'stores/products-store';

import * as CategoryHelpers from '../helpers/category-query.helpers';
import * as Helpers from '../helpers/product-query.helpers';

export const PRODUCT_CATEGORIES_COUNT_QUERY = (vars: any, productCategories: ProductFilterType[]): DocumentNode => {
  const getCountQuery = (variables: any, category: any): string => {
    return `
      ${camelCase(category.slug)}: productsConnection(
        ${Helpers.addRootArguments(variables)}
        where: {
          ${Helpers.addRootWhereArguments(variables)}
          ${GraphQLHelpers.addMerchantWhereFilter(variables.merchantId)}
          AND: [
            {
              categories_some: {
                slug: "${category.slug}"
              }
            }
            {
              ${Helpers.addTypeWhereFilter(variables.selectedTypes)}
            }
            ${GraphQLHelpers.searchableContainsValues(vars.search)}
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    `;
  };

  return gql`
    query ProductCategoriesCount (${CategoryHelpers.addRootQueryParams(vars)}) {
      ${productCategories.map(category => getCountQuery(vars, category))}
    }`;
};

import { UserRole } from 'generated-types.d';

import { OrderSortOptionKey } from 'features/orders/orders.types';

import { TableLayoutHeadingConfigProps } from 'components/entity-list/table-layout-entity.types';

export interface OrderSortOption {
  title: string;
  key: OrderSortOptionKey;
}

export const ORDER_SORT_OPTIONS: OrderSortOption[] = [
  {
    title: 'Order Time',
    key: 'orderedAt_ASC'
  },
  {
    title: 'New',
    key: 'new_ASC'
  },
  {
    title: 'Delivery Time',
    key: 'deliverAt_ASC'
  }
];

export const ORDER_ITEM_HEADING_CONFIG: TableLayoutHeadingConfigProps[] = [
  {
    heading: 'Order',
    fixedWidth: '116px'
  },
  {
    heading: 'Items',
    flexGrow: '4',
    flexBasis: '130px'
  },
  {
    heading: '',
    restrictedRoles: [UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember],
    flexGrow: '1',
    flexBasis: '90px'
  },
  {
    heading: 'Recipient',
    flexGrow: '1',
    flexBasis: '100px'
  },
  {
    heading: 'Delivery',
    flexGrow: '1',
    flexBasis: '110px'
  },
  {
    heading: '',
    flexGrow: '2',
    flexBasis: '150px'
  }
];

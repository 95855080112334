import styled from '@emotion/styled';
import { Box } from 'rebass';

import { breakpoints, colors, space } from 'utils/rebass-theme';

export const TotalPriceRange = styled(Box)`
  font-size: 14px;
  color: ${colors.floomMidnightBlue};
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CheckoutItem = styled.span`
  width: 100%;
  font-size: 12px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    display: none;
  }
`;

export const QuantitySelectorWrapper = styled(Box)`
  width: 140px;
`;

export const ListItemTypeMaxUnitMobileLabel = styled(Box)`
  display: none;
  font-size: 10px;
  margin-bottom: ${space[1]}px;

  @media only screen and (max-width: ${breakpoints.large}) {
    display: block;
  }
`;

export const CheckoutTitleWrapper = styled(Box)`
  @media only screen and (max-width: ${breakpoints.medium}) {
    margin-bottom: ${space[2]}px;
  }
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

export const MobileNavItem = styled(Box)`
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  padding-bottom: 14px;
`;

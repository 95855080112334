import { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { ProductGroupOption } from 'stores/products-store';

import { Container } from 'utils/css-mixins';

import ProductServices from 'features/products/services';

import { GroupItem } from 'components/group-item/group-item.styles';

import GeneralGroup from './general-group';
import * as Styles from './product-grouping.styles';
import StockGroup from './stock-group';

interface ProductGroup {
  title: string;
  id: ProductGroupOption;
  content: ReactNode;
}

class ProductGrouping extends Component<any> {
  ProductListService = ProductServices.ProductListService;

  GROUPS: ProductGroup[] = [
    {
      title: 'General',
      id: 'allProducts',
      content: GeneralGroup
    },
    {
      title: 'Stock',
      id: 'stock',
      content: StockGroup
    }
  ];

  private groupProducts = (groupType: ProductGroupOption): void => this.ProductListService.groupProducts(groupType);

  render(): ReactNode {
    return (
      <Fragment>
        <Container>
          <Styles.Groups>
            {
              this.GROUPS.map(group => {
                const Content: any = group.content;

                return (
                  <Styles.ProductGroupColumn
                    key={group.id}
                    onClick={(): any => this.groupProducts(group.id)}
                  >
                    <Styles.Heading>
                      {group.title}
                      <Styles.SortSelect>
                        View
                      </Styles.SortSelect>
                    </Styles.Heading>
                    <GroupItem isActive={this.props.productsStore.currentGroup === group.id}>
                      <Styles.GroupItemContent>
                        <Content />
                      </Styles.GroupItemContent>
                    </GroupItem>
                  </Styles.ProductGroupColumn>
                );
              })
            }
          </Styles.Groups>
        </Container>
      </Fragment>
    );
  }
}

export default inject('productsStore')(observer(ProductGrouping));

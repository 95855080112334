import { gql } from '@apollo/client';

export const USER_CREATE_MUTATION = gql`
    mutation CreateUser($args: UserSignUp!) {
        signUp(data: $args) {
            token
        }
    }
`;

export const USER_LOGIN_MUTATION = gql`
    mutation LoginUser($args: UserLoginInput!) {
        login(data: $args) {
            token
        }
    }
`;

export const USER_INIT_PASSWORD_RESET_MUTATION = gql`
    mutation ResetPasswordInit($args: ResetPasswordInitInput!) {
      resetPasswordInit(data: $args) {
        status
      }
    }
`;

export const USER_RESET_PASSWORD_COMPLETE_MUTATION = gql`
    mutation ResetPasswordComplete($args: ResetPasswordCompleteInput!) {
      resetPasswordComplete(data: $args) {
        status,
        token
      }
    }
`;

import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import moment from 'moment';
import { Box, Flex } from 'rebass';

import {
  CurrencyService,
  LocalisationService,
  PermissionsService,
  TimeService
} from 'lib';

import { textStyles } from 'utils/rebass-theme';
import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import Lozenge from 'components/lozenge';

import * as Styles from './plan-details.styles';
import * as Types from './plan-details.types';

export default class PlanDetails extends Component<Types.PlanDetailsProps> {
  private renderPlanPrice = (): string => {
    if (!this.props.merchant!.subscription) return '...';

    return CurrencyService.formatPrice(
      this.props.merchant!.subscription?.stripeSubscriptionPrice || 0,
      this.props.merchant!.currency
    );
  };

  private hasTrial = (): boolean => {
    return !!this.props.merchant?.subscription?.trialLength;
  };

  private renderHeading = (): string => {
    const isInternalRole = PermissionsService.isInternalRole();

    if (isInternalRole) {
      return `Plan breakdown for "${this.props.merchant!.title}"`;
    }

    return 'Your FloomX subscription';
  };

  private renderContent = (): ReactNode => {
    if (!this.props.merchant) return null;

    const hasTrial = this.hasTrial();
    const trialLength = this.props.merchant?.subscription?.trialLength;
    const planPrice = this.renderPlanPrice();
    const toPayNow = hasTrial
      ? CurrencyService.formatPrice(0, this.props.merchant.currency)
      : planPrice;

    return (
      <Flex
        flexDirection="column"
        width="100%"
      >
        <Box css={textStyles.subhead}>
          {this.renderHeading()}
        </Box>
        <Flex
          mt="14px"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <Lozenge
              copy={`FloomX ${LocalisationService.localisePlanName(this.props.merchant!.plan?.type)}`}
              bg={ColourOption.floomMidnightBlue}
            />
          </Box>
          {hasTrial && (
            <Box ml="10px">
              <Lozenge
                copy={`${trialLength} days no fee`}
                bg={ColourOption.floomOrange}
                color={ColourOption.uiFills}
              />
            </Box>
          )}
        </Flex>
        <Styles.PlanPrice
          as="h1"
          mb="0"
          color="floomMidnightBlue"
          css={textStyles.h2}
        >
          {toPayNow}{!hasTrial ? <span css={css`font-size:17px;`}>{' /mo'}</span> : ''}
        </Styles.PlanPrice>
        <Box css={textStyles.subhead}>
          <Flex justifyContent="space-between">
            <Box>Due today:</Box>
            <Box>{toPayNow}</Box>
          </Flex>
          {this.hasTrial() && !!trialLength && trialLength > 0 && (
            <Flex
              justifyContent="space-between"
              mt="5px"
            >
              <Box>Due on {TimeService.dateMonthYear(moment().add(trialLength, 'days'))}</Box>
              <Box>{planPrice} /mo</Box>
            </Flex>
          )}
        </Box>
      </Flex>
    );
  };

  render(): ReactNode {
    return (
      <Styles.Price
        mb="60px"
      >
        {this.renderContent()}
      </Styles.Price>
    );
  }
}

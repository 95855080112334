import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box } from 'rebass';

import {
  borderRadius,
  colors,
  fontSizes,
  space
} from 'utils/rebass-theme';

export const InputContainer = styled(Box)`
  flex: 1;
`;

export const ChatInput = ({ isLoading }: { isLoading: boolean }): SerializedStyles => css`
  flex: 1;
  font-size: ${fontSizes.small}px;
  line-height: 1.5;
  padding: ${space[2] + space[1]}px;
  max-height: 150px;
  overflow: auto;
  border: 1px solid ${hex2rgba(colors.middleGrey, 0.4)};
  border-radius: ${borderRadius.large};
  background-color: ${colors.uiFills};
  outline: 0px solid transparent;
  transition: opacity .2s ease-in-out;
  color: ${colors.floomMidnightBlue};
  cursor: ${isLoading ? 'not-allowed' : 'text'};
  opacity: ${isLoading ? .5 : 1};

  &:focus {
    border-color: ${colors.middleGrey};
  }

  &:empty:before {
    content: attr(placeholder);
    color: ${colors.shade40};
  }

  > div:last-child {
    padding-bottom: 20px;
  }

  b, i, u {
    font-weight: normal;
    text-decoration: none;
    font-style: normal;
  }
`;

export const Controls = styled(Box)`
  padding-left: ${space[2]}px;
  flex: 0 0 auto;
`;

const iconColour = ({ disabled, isInvalid }: any): string => {
  switch (true) {
    case isInvalid: {
      return colors.uiFills;
    }

    case disabled: {
      return colors.shade40;
    }

    default: return colors.floomMidnightBlue;
  }
};

export const SubmitButton = styled.button<{ isInvalid: boolean }>`
  background-color: ${({ isInvalid }) => isInvalid ? hex2rgba(colors.errorText, 0.6) : hex2rgba(colors.floomMidnightBlue, 0.1)};
  padding: ${space[2]}px ${space[2] + space[1]}px;
  border-radius: 5px;
  max-height: 47px;
  max-width: 52px;
  border: 2px solid transparent;
  transition: background-color .2s ease-in-out;
  cursor: ${({ disabled, isInvalid }) => (disabled || isInvalid) ? 'not-allowed' : 'pointer'};

  ${({ disabled, isInvalid }) => {
    if (!disabled && !isInvalid) {
      return `
        &:hover {
          background-color: ${hex2rgba(colors.floomMidnightBlue, 0.2)};
        }

        &:focus {
          border-color: ${colors.floomMidnightBlue};
        }
      `;
    }
  }}

  svg {
    path {
      fill: ${iconColour};
    }
  }
`;

export const InputError = styled(Box)`
  //width: 100%;
  font-size: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
  color: ${colors.errorText};
`;

import React, { Component } from 'react';

import { TermsPage } from 'lib/services/terms/terms.service.types';
import { inject, observer } from 'mobx-react';
import Helmet from 'react-helmet';
import { Box } from 'rebass';

import { TermsService, Analytics } from 'lib';

import UserStore from 'stores/user/user-store';

import TermsContent from './terms-content';
import * as Styles from './terms.styles';

interface TermsProps {
  termsType: 'terms-and-conditions' | 'cookie-policy' | 'privacy-policy';
  userStore: UserStore;
}

type TermsRoute = 'terms-and-conditions' | 'cookie-policy' | 'privacy-policy';

type TermsMap = {
  [key in TermsRoute]: TermsPage
};

const pages: TermsMap = {
  'terms-and-conditions': 'termsConditionsPage',
  'cookie-policy': 'cookiePolicyPage',
  'privacy-policy': 'privacyPolicyPage'
};

class Terms extends Component<TermsProps> {
  isFetching = false;

  state = {
    data: null,
    isLoading: true,
    hasError: false
  };

  componentDidMount = (): void => {
    this.isFetching = true;
    this.fetchData();
  };

  private fetchData = (): void => {
    if (this.isTermsPage()) {
      TermsService.fetchTermsPage(pages[this.props.termsType], this.props.userStore!.userMerchant)
        .then((data: any) => {
          this.isFetching = false;

          this.setState({
            isLoading: false,
            data: data
          });

          if (this.props.userStore!.isTeamRole()) {
            Analytics.track(Analytics.FxEventName.TermsViewed, {
              event_version: 1,
              entry_point: Analytics.TermsEntryPoint.Settings,
              terms_last_edited: data.updatedAt,
              terms_version: data.version
            });
          }
        })
        .catch(() => {
          this.setHasError();
        });
    } else {
      this.setHasError();
    }
  };

  private setHasError = (): void => {
    this.isFetching = false;

    this.setState({
      isLoading: false,
      hasError: true
    });
  };

  private isTermsPage = (): boolean => {
    return this.props.termsType === 'terms-and-conditions'
      || this.props.termsType === 'cookie-policy'
      || this.props.termsType === 'privacy-policy';
  };

  private shouldDisplayContent = (): boolean => {
    return !!this.state.data! && !this.state.isLoading && !this.isFetching && !this.state.hasError;
  };

  render(): React.ReactNode {
    return (
      <Box mt="30px">
        {/*
        // @ts-ignore */}
        <Helmet>
          <title>
            {/*
            // @ts-ignore */}
            {!!this.state.data! ? this.state.data!.title : 'Termss'}
          </title>
        </Helmet>
        <Styles.TermsWrapper>
          { !!this.shouldDisplayContent() && (
            <TermsContent
              data={this.state.data!}
              headingStyles={Styles.Heading}
              isCenteredHeading={true}
            />
          )
          }
        </Styles.TermsWrapper>
      </Box>
    );
  }
}

export default inject('userStore')(observer(Terms));

import { ApolloQueryResult, ApolloError } from '@apollo/client';
import store from 'stores';

import {
  Merchant,
  MerchantUpdateDataInput,
  MerchantWhereUniqueInput,
  TeamCreateOneInput,
  AddressCreateInput,
  MerchantUpdateInput
} from 'generated-types.d';

import {
  GraphQL,
  MerchantMutations
} from 'lib';

import MerchantDetailStore from 'stores/merchant-detail/merchant-detail-store';
import {
  MerchantDetailFields,
  EditFieldsConfigKey
} from 'stores/merchant-detail/merchant-detail-store.types';

export default class MerchantEditService {
  private merchantDetailStore = store.merchantDetailStore as MerchantDetailStore;

  public updateMerchant = async (fields: MerchantDetailFields, category: EditFieldsConfigKey, existingMerchant: Merchant): Promise<Merchant | void> => {
    const data = this.buildMerchantEditData(fields, category, existingMerchant);
    const where: MerchantWhereUniqueInput = { id: existingMerchant.id };

    return GraphQL.query(MerchantMutations.UPDATE_MERCHANT, { data, where })
      .then((merchant: ApolloQueryResult<{ updateMerchant: Merchant }>): Merchant => {
        this.merchantDetailStore.setSelectedMerchant(merchant.data.updateMerchant);

        return merchant.data.updateMerchant;
      }).catch((error: ApolloError) => {
        store.toasterStore.popErrorToast('merchant', 'update');

        return Promise.reject(error.graphQLErrors);
      });
  };

  public reIndexProducts = async (merchantId: string): Promise<boolean> => {
    const where: MerchantWhereUniqueInput = { id: merchantId };

    try {
      const result = await GraphQL.query(MerchantMutations.REINDEX_PRODUCTS, { where });

      return !!result;
    } catch (error) {
      return Promise.reject(error.graphQLErrors);
    }
  };

  public toggleMerchantFeature = async (
    isActive: boolean,
    merchant: Merchant,
    featureToToggle: Extract<keyof Merchant,
    | 'wholesaleActive'
    | 'listsActive'
    | 'conversationsActive'
    | 'isDemoAccount'
    | 'promotedListsActive'
    | 'promotedActive'
    | 'canViewNationwide'
    >
  ): Promise<Merchant | void> => {
    const data: MerchantUpdateInput = {
      [featureToToggle]: isActive
    };

    const where: MerchantWhereUniqueInput = {
      id: merchant.id
    };

    return GraphQL.query(MerchantMutations.UPDATE_MERCHANT, { data, where })
      .then((result: ApolloQueryResult<{ updateMerchant: Merchant }>): Merchant => {
        this.merchantDetailStore.setSelectedMerchant(result.data.updateMerchant);

        return result.data.updateMerchant;
      }).catch((error: ApolloError) => {
        store.toasterStore.popErrorToast('merchant', 'update');

        return Promise.reject(error.graphQLErrors);
      });
  };

  private connectTeam = (merchant: Merchant): TeamCreateOneInput => ({
    connect: {
      id: merchant?.createdBy?.team?.id
    }
  });

  private buildMerchantName = (merchant: Merchant): string => {
    return (`${merchant?.createdBy?.givenName} ${merchant?.createdBy?.familyName}`);
  };

  private isUserDifferent = (data: MerchantDetailFields, existingMerchant: Merchant): boolean => {
    return !!data.email && data.email !== existingMerchant.createdBy?.email
      || !!data.phone && data.phone !== existingMerchant.createdBy?.phone
      || !!data.givenName && data.givenName !== existingMerchant.createdBy?.givenName
      || !!data.givenName && data.familyName !== existingMerchant.createdBy?.familyName;
  };

  public buildMerchantEditData = (data: MerchantDetailFields, category: EditFieldsConfigKey, existingMerchant: Merchant): MerchantUpdateDataInput => {
    const updatedMerchant: Partial<MerchantUpdateDataInput> = {};

    if (!!data.tradingNo && data?.tradingNo !== existingMerchant.tradingNo) {
      updatedMerchant.tradingNo = data.tradingNo;
    }

    if (this.isUserDifferent(data, existingMerchant)) {
      updatedMerchant.createdBy = {
        update: {
          email: data.email.toLowerCase(),
          phone: data.phone,
          givenName: data.givenName,
          familyName: data.familyName
        }
      };
    }

    switch (category) {
      case EditFieldsConfigKey.company:
        if (!!data.postalCode && !existingMerchant.registeredAddress) {
          const merchantCreateAddress: AddressCreateInput = {
            businessName: data.businessName,
            recipientFullName: this.buildMerchantName(existingMerchant),
            address1: data.address1,
            address2: data.address2 || null,
            city: data.city || null,
            region: '',
            country: this.merchantDetailStore.country,
            postalCode: data.postalCode.toUpperCase(),
            phone: existingMerchant?.createdBy?.phone || null,
            team: this.connectTeam(existingMerchant)
          };

          updatedMerchant.registeredAddress = {
            create: merchantCreateAddress
          };
        }

        if (existingMerchant.registeredAddress) {
          updatedMerchant.registeredAddress = {
            update: {}
          };

          if (!!data.address1 && data.address1 !== existingMerchant.registeredAddress.address1) {
            updatedMerchant.registeredAddress.update = {
              address1: data.address1
            };
          }

          if (!!data.address2 && data.address2 !== existingMerchant.registeredAddress.address2) {
            updatedMerchant.registeredAddress.update = {
              ...updatedMerchant.registeredAddress.update,
              address2: data.address2
            };
          }

          if (!!data.city && data.city !== existingMerchant.registeredAddress.city) {
            updatedMerchant.registeredAddress.update = {
              ...updatedMerchant.registeredAddress.update,
              city: data.city
            };
          }

          updatedMerchant.registeredAddress.update = {
            ...updatedMerchant.registeredAddress.update,
            postalCode: data.postalCode ? data.postalCode.toUpperCase() : undefined
          };

          if (!!data.phone && data.phone !== existingMerchant.createdBy?.phone) {
            updatedMerchant!.registeredAddress!.update = {
              ...updatedMerchant!.registeredAddress!.update,
              phone: data.phone
            };
          }

          if (!!data.businessName && data.businessName !== existingMerchant?.registeredAddress?.businessName) {
            updatedMerchant!.registeredAddress!.update = {
              ...updatedMerchant!.registeredAddress!.update,
              businessName: data.businessName
            };
          }

          if ((!!data.givenName?.length && !!data.givenName?.length) && `${data.givenName} ${data.familyName}` !== existingMerchant.registeredAddress!.recipientFullName) {
            updatedMerchant!.registeredAddress!.update = {
              ...updatedMerchant!.registeredAddress!.update,
              recipientFullName: `${data.givenName} ${data.familyName}`
            };
          }
        }

        break;

      case EditFieldsConfigKey.businessAddress:
        if (!existingMerchant.address) {
          updatedMerchant.address = {
            create: {
              businessName: data.businessName,
              recipientFullName: this.buildMerchantName(existingMerchant),
              address1: data.address1,
              address2: data.address2 || null,
              city: data.city || null,
              region: '',
              country: this.merchantDetailStore.country,
              postalCode: data.postalCode.toUpperCase(),
              phone: existingMerchant?.createdBy?.phone || null,
              team: this.connectTeam(existingMerchant)
            }
          };
        } else {
          updatedMerchant.address = {
            update: {}
          };

          if (!!data.address1 && data.address1 !== existingMerchant.address.address1) {
            updatedMerchant.address.update = {
              address1: data.address1
            };
          }

          if (!!data.address2 && data.address2 !== existingMerchant.address.address2) {
            updatedMerchant.address.update = {
              ...updatedMerchant.address.update,
              address2: data.address2
            };
          }

          if (!!data.city && data.city !== existingMerchant.address.city) {
            updatedMerchant.address.update = {
              ...updatedMerchant.address.update,
              city: data.city
            };
          }

          updatedMerchant.address.update = {
            ...updatedMerchant.address.update,
            postalCode: data.postalCode ? data.postalCode.toUpperCase() : undefined
          };

          if (!!data.phone && data.phone !== existingMerchant?.createdBy?.phone) {
            updatedMerchant!.address!.update = {
              ...updatedMerchant!.address!.update,
              phone: data.phone
            };
          }

          if (!!data.businessName && data.businessName !== existingMerchant?.address?.businessName) {
            updatedMerchant!.address!.update = {
              ...updatedMerchant!.address!.update,
              businessName: data.businessName
            };
          }

          if ((!!data.givenName?.length && !!data.givenName?.length) && `${data.givenName} ${data.familyName}` !== existingMerchant.address.recipientFullName) {
            updatedMerchant!.address!.update = {
              ...updatedMerchant!.address!.update,
              recipientFullName: `${data.givenName} ${data.familyName}`
            };
          }
        }

        break;

      default:
        break;
    }

    return updatedMerchant;
  };
}

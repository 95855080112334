import { ReactNode, FC, useState, useEffect, Fragment } from 'react';

import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import { Merchant } from 'generated-types';
import { Box } from 'rebass';
import store from 'stores';

import { GraphQL, NavService } from 'lib';

import { textStyles, colors } from 'utils/rebass-theme';

import CheckboxToggle from 'components/checkbox-toggle';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import * as Styles from './order-pause-banner.styles';

const SAME_DAY_ORDER_PAUSE = gql`
  query MerchantSameDayPauseStatus($where: MerchantWhereUniqueInput!) {
    merchant(where: $where) {
      id
      sameDayOrderPauseStatus
    }
  }
`;

const OrderPauseBanner: FC = () => {
  const merchantStore = store.merchantStore;
  const chatWidgetStore = store.chatWidgetStore;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pauseStatus, setPauseStatus] = useState<number>(0);

  useEffect(() => {
    fetchSameDayOrderPauseStatus()
      .then(response => {
        const merchant: Merchant = response.merchant;
        setPauseStatus(merchant.sameDayOrderPauseStatus || 0);
        setIsLoading(false);
      });

    const intervalId = setInterval(() => {
      fetchSameDayOrderPauseStatus()
        .then(response => {
          const merchant: Merchant = response.merchant;
          setPauseStatus(merchant.sameDayOrderPauseStatus || 0);
          setIsLoading(false);
        });
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchSameDayOrderPauseStatus = async (): Promise<any> => {
    return GraphQL.query(SAME_DAY_ORDER_PAUSE, { where: { id: merchantStore!.merchant?.id } })
      .then(({ data }: any) => data);
  };

  const canToggleSameDayOrderPauseStatus = (): boolean => {
    return pauseStatus === 0 || pauseStatus === 3;
  };

  const shouldDisplayNotification = (): boolean => {
    return [1, 2].includes(pauseStatus);
  };

  const viewOrders = (): void => {
    NavService.ordersList('today');
  };

  const sendMessageToCS = (): void => {
    // @replace with jotai?
    chatWidgetStore!.enableChatWidget();
    window['zE']?.('webWidget', 'open');
    window['zE']?.('webWidget', 'chat:send', 'Hey FloomX team! I would like to re-enable same day orders please :)');
  };

  const onChange = (isChecked: boolean): void => {
    setPauseStatus(isChecked ? 0 : 3);
    merchantStore!.toggleSameDayOrderPauseStatus(isChecked);
  };

  const renderNotificationCopy = (): ReactNode => {
    switch (pauseStatus) {
      // Auto paused
      case 1:
        return (
          <Styles.NotificationCopy>
            ⚠️ Your same day orders for floom.com have been automatically paused. <button onClick={viewOrders}>View orders</button>
          </Styles.NotificationCopy>
        );

      // CS Paused
      case 2:
        return (
          <Styles.NotificationCopy>
            ⚠️ Your same day orders for floom.com have been manually paused by Floom. <button onClick={sendMessageToCS}>Contact us</button>
          </Styles.NotificationCopy>
        );

      default:
        break;
    }
  };

  if (!merchantStore!.merchant) {
    return null;
  }

  if (isLoading) {
    return (
      <Fragment>
        Loading...
      </Fragment>
    );
  }

  return (
    <Styles.OrderPauseBanner>
      {shouldDisplayNotification() ? (
        <Styles.OrderPauseNotification>
          <Notification
            type={NotificationType.Progress}
            copy={renderNotificationCopy()}
            textAlign="left"
            hasClose={false}
            styles={css`
                font-size: 14px;
              `}
          />
        </Styles.OrderPauseNotification>
      ) : (
        <Styles.OrderPauseCheckbox
          alignItems="center"
        >
          <CheckboxToggle
            onChange={onChange}
            switchBgActive={colors.validationText}
            switchBgInactive={colors.floomOrange}
            activeCopy="Yes"
            inactiveCopy="No"
            controlValue={true}
            isDisabled={!canToggleSameDayOrderPauseStatus()}
            isChecked={pauseStatus === 0}
            id="overview-same-day-order-pause-toggle"
          />
          <Box
            css={css`
              ${textStyles.body}
              flex: 1;
            `}
            ml="10px"
            as="label"
            for="overview-same-day-order-pause-toggle"
          >
            Accepting same day orders?
          </Box>
        </Styles.OrderPauseCheckbox>
      )}
    </Styles.OrderPauseBanner>
  );
};

export default OrderPauseBanner;

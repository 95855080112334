import { TimeService } from 'lib';

import { CourierListGroupOption, CourierListPageRoute } from 'features/courier-bookings/courier-bookings.types';

export interface CourierListConfig {
  heading: string;
  subHeading?: string;
  title: string;
  groups: CourierListGroupOption[];
  globalFilter: string;
}

export type CourierListPageLayouts = {
  [courierPageRoute in CourierListPageRoute]: CourierListConfig;
};

export type CourierListGroupOptions = {
  [groupOption in CourierListGroupOption]: {
    title?: string;
    hasStatusAction: boolean;
    filter: string;
  }
};

export const COURIER_LIST_GROUP_OPTIONS: CourierListGroupOptions = {
  upcoming: {
    title: 'Upcoming',
    hasStatusAction: true,
    filter: `AND: [
      {
        deliveryDate_gte: "${TimeService.getStartOfDay(0)}"
      }
      {
        deliveryDate_lte: "${TimeService.getEndOfDay(28)}"
      }
      {
        deliveryStatus_not_in: [Cancelled]
      }
    ]`
  },
  past: {
    title: 'Past',
    hasStatusAction: true,
    filter: `
      OR: [
        {
          deliveryDate_lt: "${TimeService.getEndOfDay(-1)}"
        }
        {
          deliveryStatus_in: [Cancelled]
        }
      ]
    `
  }
};

export const COURIER_LIST_PAGE_LAYOUTS: CourierListPageLayouts = {
  all: {
    heading: 'All Courier Bookings',
    title: 'All Courier Bookings',
    globalFilter: '',
    groups: [
      'upcoming',
      'past'
    ]
  }
};

import { FC, ReactNode } from 'react';

import {
  Query,
  DatoCmsUnionForDatoCmsPagePageBlocks
} from 'gatsby-types.d';

import { ContactForm } from './blocks/contact-form/contact-form';
import { FullBleedImageAndText } from './blocks/full-bleed-image-and-text/full-bleed-image-and-text';
import { FullBleedText } from './blocks/full-bleed-text/full-bleed-text';
import { ImageAndText } from './blocks/image-and-text/image-and-text';
import { KeyPoints } from './blocks/key-points/key-points';
import { WaveSpacer } from './blocks/wave-spacer/wave-spacer';

interface StandardPageProps {
  data: Query['datoCmsPage'];
}

const renderPageBlock = (pageBlock: DatoCmsUnionForDatoCmsPagePageBlocks): ReactNode => {
  switch (pageBlock.__typename) {
    case 'DatoCmsFullBleedImageAndText':
      return (
        <FullBleedImageAndText data={pageBlock} />
      );

    case 'DatoCmsFullBleedText':
      return (
        <FullBleedText data={pageBlock} />
      );

    case 'DatoCmsImageAndText':
      return (
        <ImageAndText data={pageBlock} />
      );

    case 'DatoCmsKeyPointsBlock':
      return (
        <KeyPoints data={pageBlock} />
      );

    case 'DatoCmsWaveSpacer':
      return (
        <WaveSpacer data={pageBlock} />
      );

    case 'DatoCmsContactFormBlock':
      return (
        <ContactForm data={pageBlock} />
      );

    default:
      return null;
  }
};

export const StandardPage: FC<StandardPageProps> = ({ data }) => {
  return (
    <main>
      {data?.pageBlocks?.map(block => {
        if (!block) return null;

        return (
          <section key={block.originalId}>
            {renderPageBlock(block)}
          </section>
        );
      })}
    </main>
  );
};

import { CSSProperties } from 'react';

import styled from '@emotion/styled';
import hex2Rgba from 'hex2rgba';
import { Box, Flex } from 'rebass';
import { mq } from 'theme';

import {
  breakpoints,
  colors,
  space,
  textStyles
} from 'utils/rebass-theme';

import { inputReset, getPlaceholderStyle } from 'components/field-text/field-text.styles';

export const ListContainer = styled(Box)`
  padding: ${space[3]}px ${space[2]}px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    padding: ${space[2]}px 0;
  }
`;

export const Wrapper = styled(Flex)`
  height: 100vh;
  flex-direction: column;
`;

export const ListItemSubTitle = styled.div`
  ${textStyles.body};
  color: ${colors.floomMidnightBlue};
  font-weight: 400;
`;

export const ListGenus = styled.div`
  @media only screen and (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const ListItemTitle = styled.div<{ fontSize?: number }>`
  ${textStyles.h5}
  color: ${colors.floomMidnightBlue};
  font-size: ${({ fontSize }): string => !!fontSize ? `${fontSize}px` : '14px'};
  flex-grow: 2;
`;

export const QuantityInput = styled.input`
  ${textStyles.body};
  color: ${colors.floomMidnightBlue};
  font-size: 16px;
  background: none;
  width: 50px;

  &:focus {
    outline: none;
  }

  ${inputReset}
  ${getPlaceholderStyle()};
`;

export const ListItemQuantityWrapper = styled.div`
  @media only screen and (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const ReadOnlyQuantity = styled.div`
  display: block;

  @media only screen and (min-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: ${space[4]}px;
  right: ${space[4]}px;
  z-index: 1;
`;

export const modalContentStyles: CSSProperties = {
  top: 0,
  right: 0,
  bottom: 0,
  width: '280px',
  left: 'unset',
  transform: 'unset'
};

export const modalOverlayStyles: CSSProperties = {
  background: hex2Rgba(colors.floomMidnightBlue, '0.6')
};

export const SingleListHeading = styled(Box)<{ hideMobile: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 270px;
  overflow: hidden;

  ${({ hideMobile }) => hideMobile ?
    `${mq('medium')} {
      display: none;
  };` : ''}
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const Heading = styled.h1`
  ${textStyles.title};
`;

export const HeadingWrapper = styled(Box)<any>`
  text-align: ${({ isCentered }): string => isCentered ? 'center' : 'left'};
`;

export const LastUpdated = styled.h3`
  color: ${colors.shade40};
  font-size: 10px;
  margin-top: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
`;

export const TermsWrapper = styled(Box)<any>`
  padding: 30px;
`;

export const TermsCopy = styled(Box)<any>`
  * {
    + h1, + h2, + h3, + h4, + h5 {
      margin-top: 40px;
    }

    + p {
      padding-top: 30px;
    }

    + ol, + ul, + dl {
      padding-top: 20px;
    }
  }

  h1,h2,h3,h4,h5 {
    ${textStyles.subhead};
    padding-bottom: 10px;
    border-bottom: 2px solid ${colors.middleGrey};

    * + * {
      margin-top: 40px;
    }
  }

  a {
    color: ${colors.floomMidnightBlue};
    text-decoration: underline;
    font-weight: bold;
  }

  ol, ul, dl {
    margin-left: 12px;
  }

  li {
    ${textStyles.body};
    padding-left: 10px;

    + li {
      margin-top: 3px;
    }
  }

  p {
    ${textStyles.body}
    padding-top: 30px;
  }
`;

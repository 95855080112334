import { FC, useMemo } from 'react';

import * as Styles from './form-list.styles';
import { TypeformListProps } from './form-list.types';

export const TypeformList: FC<TypeformListProps> = ({
  formConfigs,
  onFormSelect
}) => {
  const visibleFormConfigs = useMemo(() => {
    return formConfigs.filter(config => config.isVisible);
  }, [formConfigs]);

  return (
    <Styles.ListContainer>
      {visibleFormConfigs.map(config => {
        return (
          <Styles.ListItem
            onClick={() => onFormSelect(config.formId)}
            key={config.copy}
          >
            <Styles.ListItemHeader>
              {config.copy}
            </Styles.ListItemHeader>
            <Styles.ListItemsubHeader>
              {config.subCopy}
            </Styles.ListItemsubHeader>
          </Styles.ListItem>
        )
        ;
      })}
    </Styles.ListContainer>
  );
};

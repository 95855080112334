import OrderDetail from 'features/orders/pages/order-detail';
import MerchantDetail from 'features/settings/components/merchants/merchant-detail';
import { WholesaleShopListing } from 'features/wholesale/components/wholesale-shop-listing/wholesale-shop-listing';

import { ModalRouterConfig } from './modal-router.types';

export const MODAL_ROUTER_CONFIG: ModalRouterConfig = {
  'order-detail': {
    component: OrderDetail
  },
  'wholesale-shop-listing': {
    component: WholesaleShopListing,
    width: 600
  },
  'merchant-detail': {
    component: MerchantDetail
  }
};

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Flex, Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

import Button from 'components/button';
import Icon from 'components/icon';

import * as Styles from './notification.styles';
import * as Types from './notification.types';

export default class Notification extends Component<Types.NotificationProps> {
  static defaultProps: Partial<Types.NotificationProps> = {
    hasIcon: false,
    hasButton: false,
    hasClose: true,
    textAlign: 'center',
    styles: css``,
    onButtonClick: (): any => null,
    onClick: (): boolean => false,
    onClose: (): boolean => false,
    iconColor: colors.uiFills
  };

  private handleNotificationClick = (): void => {
    this.props.onClick();
  };

  private handleClose = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();

    this.props.onClose();
  };

  private getPathFill = (): string => {
    switch (this.props.type) {
      case Types.NotificationType.Success:
        return colors.validationText;

      case Types.NotificationType.Banner:
      case Types.NotificationType.Info:
        return colors.floomMidnightBlue;

      default:
        return colors.uiFills;
    }
  };

  private renderPrettyIcon = (): ReactNode => {
    if (!this.props.hasIcon) return null;

    const defaultIcon = (): ReactNode => {
      return  (
        <Icon
          iconName={this.props.icon}
          pathFill={this.props.iconColor}
          styles={Styles.NotificationIcon}
        />
      );
    };

    const customIcon = (): ReactNode => {
      return  (
        <Styles.ImageIcon>
          <img
            src={this.props.customIcon}
          />
        </Styles.ImageIcon>
      );
    };

    return (
      <Flex
        alignItems="center"
        flex={this.props.customIcon ? '0 0 65px' : '0 0 auto'}
        css={Styles.IconWrapper}
      >
        { this.props.customIcon
          ? customIcon()
          : defaultIcon()
        }
      </Flex>
    );
  };

  private renderCloseIcon = (): ReactNode => {
    if (!this.props.hasClose) return null;

    return (
      <Flex
        onClick={this.handleClose}
        flex="0 0 50px"
        pl="20px"
        justifyContent="center"
      >
        <Icon
          iconName="cross-small"
          pathFill={this.getPathFill()}
          styles={Styles.NotificationCloseIcon}
        />
      </Flex>
    );
  };

  private renderButton = (): ReactNode => {
    if (!this.props.hasButton || !this.props.buttonProps?.copy) return null;

    return (
      <Box
        onClick={this.props.onButtonClick}
        as="button"
        pl="30px"
      >
        <Button {...this.props.buttonProps} />
      </Box>
    );
  };

  render(): ReactNode {
    return(
      <Styles.NotificationStyles
        alignItems="center"
        justifyContent="space-between"
        as="button"
        textAlign={this.props.textAlign}
        type={this.props.type}
        customTheme={this.props.customTheme}
        onClick={this.handleNotificationClick}
        css={this.props.styles}
      >
        <Flex flex="1">
          {this.renderPrettyIcon()}
          <Box>
            {this.props.copy}
          </Box>
        </Flex>
        {this.renderButton()}
        {this.renderCloseIcon()}
      </Styles.NotificationStyles>
    );
  }
}

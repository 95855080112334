import gql from 'graphql-tag';

export const PRODUCT_COLLECTION_CONFIGS_QUERY = gql`
  query ProductCollectionConfigs(
    $first: Int,
    $skip: Int,
    $where: CollectionSellerProductConfigWhereInput,
    $orderBy: CollectionSellerProductConfigOrderByInput
  ) {
    collectionSellerProductConfigsConnection(
      first: $first,
      skip: $skip,
      where: $where,
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          merchant {
            id
          }
          deliveryConfigs {
            id
          }
          active
          sourceProduct {
            id
            title
            variations(first: 3) {
              id
              media(first: 1) {
                src
              }
            }
          }
          productVariationConfigs {
            id
            sourceProductVariation {
              id
              media {
                src
              }
            }
            stock
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

import { FC } from 'react';

import { List, ListItemUnion } from 'generated-types';
import moment from 'moment-timezone';

import { ListAttachment, ListItem as ListItemType } from 'types/conversations.types';

import { TEST_IDS } from 'utils/test/data-test-ids';

import {
  Header,
  Title,
  ExpectedDate,
  QuantitySummary,
  Wrapper
} from './list-summary.styles';

type ListProps = {
  isLink?: boolean;
  list: List | ListAttachment;
  onClick?: () => void;
  timezone?: string;
};

export const ListSummary: FC<ListProps> = ({
  isLink = false,
  list: {
    date,
    items,
    title
  },
  onClick,
  timezone = 'Europe/London'
}) => {
  const units = items?.map((item: ListItemUnion | ListItemType) => item.quantity || 0).reduce((acc, curr) => acc + curr, 0);
  const formattedDate = date ? moment(date).tz(timezone).format('DD/MM/YYYY') : null;

  return (
    <Wrapper
      isLink={isLink}
      as="button"
      onClick={onClick}
      data-testid={TEST_IDS.ListSummary.trigger}
    >
      <Header>
        <Title isLink={isLink}>{title || 'Untitled List'}</Title>
      </Header>
      {formattedDate && (
        <ExpectedDate mb={3}>Expected: {formattedDate}</ExpectedDate>
      )}
      <QuantitySummary>
        {items?.length} items | {units} units
      </QuantitySummary>
    </Wrapper>
  );
};

import styled from '@emotion/styled';

import { breakpoints } from 'utils/rebass-theme';

export const StyledButton = styled.button`
  width: 240px;

  @media only screen and (max-width: ${breakpoints.medium}) {
    width: 100%;
  }
`;

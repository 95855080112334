import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { Card, PulseAnimation } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const SelectedItem = styled(Flex)`
  ${Card};

  align-items: center;
  height: 70px;
  position: relative;
`;

export const SelectedItemMedia = css`
  height: 70px;
  width: 70px;
`;

export const SelectedItemDetails = styled(Flex)`
  flex-direction: column;
  text-align: left;
  padding: 0px;
  margin: 0 0 0 10px;
`;

export const SelectedItemTitle = styled.div`
  ${textStyles.subhead};

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: -4px;
`;

export const SelectedItemStemCountWrapper = styled(Flex)`
  ${textStyles.label};
  align-items: baseline;
  width: 100%;
  margin-top: 3px;

  > p {
    margin-right: 5px;
  }
`;

export const SelectedItemStemText = styled.p`
  ${textStyles.label};
  color: ${colors.shade40};
`;

export const SelectedItemDeleteCross: any = styled.div`
  cursor: pointer;
  position: absolute;
  margin-left: auto;
  top: 0px;
  right: 0px;
`;

export const SaveEditButton: any = styled(Box)`
  ${textStyles.label};
  transition: opacity .2s ease-in-out;
  opacity: ${({ disabled }: any): number => disabled ? 0 : 1};
  pointer-events: ${({ disabled }: any): string => disabled ? 'none' : 'auto'};
  border-bottom: 1px solid ${colors.floomMidnightBlue}
`;

export const InputField = styled.div`
  max-width: 25px;
  margin-right: -8px;
`;

export const ComponentLoading = css`
  ${PulseAnimation};
  ${textStyles.footnote};
  color: ${colors.floomMidnightBlue}
`;

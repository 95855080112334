import { AxiosResponse, AxiosRequestConfig } from 'axios';

import { Merchant, Currency } from 'generated-types.d';

import { ApiService } from 'lib';

import { toDatoLocale } from 'utils/dato-cms/dato-cms.utils';

import { TermsPage, TermsLocaleMap, TermsResult } from './terms.service.types';

const fetchTermsPage = (pageType: TermsPage, userMerchant: Merchant | null): TermsResult | any => {
  const requestConfig: AxiosRequestConfig = {
    params: {
      page_to_request: pageType,
      locale: toDatoLocale(TermsLocaleMap[userMerchant ? userMerchant.currency : Currency.Gbp])
    }
  };

  return ApiService.get('/.netlify/functions/terms', requestConfig)
    .then((result: AxiosResponse<TermsResult>) => result.data)
    .catch(error => Promise.reject(error));
};

export {
  fetchTermsPage
};

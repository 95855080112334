import { ApolloError } from '@apollo/client';

import { MerchantPlan, MerchantPlanWhereInput } from 'generated-types.d';

import { GraphQL } from 'lib';

import { MERCHANT_PLANS } from './graphql/merchant-plan.queries';

export default class MerchantPlanService {
  public static fetchMerchantPlans = async (where: MerchantPlanWhereInput = {}): Promise<MerchantPlan[]> => {
    return GraphQL.query(MERCHANT_PLANS, { where })
      .then((results: { data: { merchantPlans: MerchantPlan[] } }): any => results.data.merchantPlans)
      .catch((error: ApolloError) => Promise.reject(error));
  };
}

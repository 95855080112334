import { gql } from '@apollo/client';

export const DELIVERY_CONFIG_MUTATION = gql`
  mutation UpdateDeliveryConfig($args: DeliveryConfigUpdateInput!, $where: DeliveryConfigWhereUniqueInput!) {
    updateDeliveryConfig (data: $args, where: $where) {
      id,
    }
  }
`;

export const CREATE_DELIVERY_CONFIG = gql`
  mutation CreateDeliveryConfig($args: DeliveryConfigCreateInput!) {
    createDeliveryConfig (data: $args) {
      id,
    }
  }
`;

export const UPDATE_CUSTOM_DELIVERY_PRICES = gql`
  mutation UpdateCustomDeliveryConfigPrices($id: ID!, $data: DeliveryPricingUpdateInput!) {
    updateDeliveryPricing(
      where: {
        id: $id
      }
      data: $data
    ) {
      id
    }
  }
`;

export const CREATE_DELIVERY_COVERAGE = gql`
  mutation CreateDeliveryCoverage($data: DeliveryCoverageCreateInput!) {
    createDeliveryCoverage(
      data: $data
    ) {
      id,
      deliveryZones {
        id,
        title
      }
    }
  }
`;

export const UPDATE_DELIVERY_COVERAGE = gql`
  mutation UpdateDeliveryCoverage(
    $data: DeliveryCoverageUpdateInput!
    $where: DeliveryCoverageWhereUniqueInput!
  ) {
    updateDeliveryCoverage(
      where: $where,
      data: $data
    ) {
      id,
      deliveryZones {
        id,
        title
      }
    }
  }
`;

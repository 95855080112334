import {
  Component,
  ReactNode,
  MouseEvent
} from 'react';

import ButtonLoader from './button-loader';
import * as Constants from './button.constants';
import * as Styles from './button.styles';
import * as Types from './button.types';

export default class Button extends Component<Types.ButtonProps> {
  static defaultProps = {
    appearance: 'primary',
    size: 'normal',
    isParentWidth: false,
    isLoading: false,
    isDisabled: false,
    iconBefore: null
  };

  private onClick = (event: MouseEvent<HTMLDivElement>): boolean => {
    if (this.shouldPreventClick()) {
      event.stopPropagation();
      event.preventDefault();
    }

    return true;
  };

  private shouldPreventClick = (): boolean => this.props.isDisabled || this.props.isLoading;

  // @ts-ignore
  private getAppearance = (): Types.ButtonAppearanceOption | Types.ButtonDisabledOption => this.props.isDisabled || this.props.isLoading
    ? `disabled-${this.props.appearance}`
    : this.props.appearance;

  render(): ReactNode {
    const appearance: Types.ButtonAppearance = Constants.APPEARANCE_OPTIONS[this.getAppearance()];
    const size: Types.ButtonSize = {
      ...Constants.SIZES[this.props.size],
      ...(this.props.padding ? {
        padding: this.props.padding
      } : {})
    };

    return(
      <Styles.ButtonWrapper
        appearance={appearance}
        size={size}
        isParentWidth={this.props.isParentWidth}
        disabled={this.props.isDisabled || this.props.isLoading}
        onClick={this.onClick}
      >
        <span css={Styles.ButtonInner}>
          {this.props.iconBefore && (
            <Styles.ButtonIcon isLoading={this.props.isLoading}>
              {this.props.iconBefore}
            </Styles.ButtonIcon>
          )}
          <Styles.ButtonCopy isLoading={this.props.isLoading}>
            {this.props.copy}
          </Styles.ButtonCopy>
          <ButtonLoader
            isVisible={this.props.isLoading}
            appearance={appearance}
          />
        </span>
      </Styles.ButtonWrapper>
    );
  }
}

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import {
  colors,
  textStyles,
  borderRadius
} from 'utils/rebass-theme';

export const Metadata: any = styled(Flex)`
  width: calc(100% + 10px);

  @media (max-width: 930px) {
    flex-wrap: wrap;
  }
`;

export const MetadataItem: any = styled(Box)`
  padding: 18px 20px;
  border: 1px solid ${colors.middleGrey};
  border-radius: ${borderRadius.medium};
  flex: ${(props): string => props.flex || '1'};
  margin-right: 10px;

  @media (max-width: 930px) {
    display: flex;
    align-items: center;
    flex: 100%;
    margin: 10px 0 0 0;
    padding: 14px 20px 15px 20px;
  }
`;

export const MetadataTitle: any = styled(Box)`
  ${textStyles.body};
`;

export const MetadataValue: any = styled(Box)`
  ${textStyles.h3};

  font-size: 18px;
  margin-top: 8px;

  @media (max-width: 930px) {
    font-size: 16px;
    margin-top: 4px;
    margin-left: 10px;
  }
`;

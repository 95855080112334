import React, { Component, ReactNode } from 'react';

import { DiscountCreateEditFormType } from 'global.types';
import { inject, observer } from 'mobx-react';

import { NavService } from 'lib';

import DiscountCreateEditLayout from 'features/settings/components/discounts/discount-create-edit-layout';
import DiscountService from 'features/settings/components/discounts/services';

import * as Types from './discount-create-edit.types';

class DiscountCreateEdit extends Component<Types.DiscountsProps> {
  componentDidMount = (): void => {
    this.redirectUser();
    this.fetchDiscount();
  };

  private redirectUser = (): void => {
    const formTypes: DiscountCreateEditFormType[] = ['create', 'edit'];

    if (!formTypes.includes(this.props.formType!)) {
      NavService.discountsList();
    }
  };

  private fetchDiscount = (): void => {
    if (this.props.formType === 'edit' && this.props.discountId) {
      DiscountService.fetchDiscount(this.props.discountId);
    } else {
      this.props.discountCreateEditStore!.setDiscountLoading(false);
    }
  };

  render(): ReactNode {
    return (
      <DiscountCreateEditLayout
        formType={this.props.formType!}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  discountCreateEditStore: stores.discountCreateEditStore
}))(observer(DiscountCreateEdit));

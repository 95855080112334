import { gql } from '@apollo/client';

import { ADD_ON_FULL } from '../fragments/add-ons.fragments';

export const ADD_ON_CREATE = gql`
  mutation CreateAddOn(
    $data: AddonCreateInput!
  )
  {
    createAddon(
      data: $data
    ) {
     ...AddOnFull
    }
  }
  ${ADD_ON_FULL}
`;

export const ADD_ON_EDIT = gql`
  mutation EditAddOn(
    $data: AddonUpdateInput!
    $addOnId: ID!
  )
  {
    updateAddon(
      data: $data,
      where: {
        id: $addOnId
      }
    ) {
      ...AddOnFull
    }
  }
  ${ADD_ON_FULL}
`;

import { FC, ReactNode, Fragment } from 'react';

import { Link } from '@reach/router';
import { WholesalePreOrderStatus } from 'generated-types';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex } from 'rebass';

import { PermissionsService, StringFormat } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';
import SelectedWholesalePreOrderStore from 'stores/selected-wholesale-pre-order/selected-wholesale-pre-order-store';

import { colors, textStyles } from 'utils/rebass-theme';

import Icon from 'components/icon';

interface SinglePreOrderHeaderProps {
  selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
  merchantStore?: MerchantStore;
}

const headingPrefixConfig: { [key in WholesalePreOrderStatus | 'Default']: string } = {
  Cancelled: 'Cancelled ',
  Complete: 'Complete ',
  Open: '',
  Processing: 'Live ',
  Default: ''
};

export const SinglePreOrderHeader: FC<SinglePreOrderHeaderProps> = inject((stores: FxStores) => ({
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore,
  merchantStore: stores.merchantStore
}))(observer(({
  selectedWholesalePreOrderStore
}) => {
  if (!selectedWholesalePreOrderStore!.preOrder) return null;

  const headingPrefix = headingPrefixConfig[selectedWholesalePreOrderStore!.preOrder?.status || 'Default'];

  const renderOrderDetails = (): ReactNode => {
    const deliveryDate = selectedWholesalePreOrderStore!.preOrder?.deliverAt;
    const processBy = selectedWholesalePreOrderStore!.preOrder?.stopProcessingAt;

    if (!deliveryDate) return null;

    const deliveryDateAsString = moment(deliveryDate).format('ddd Do MMM');
    // @TODO - change process by to use logged in users timezone
    const processByDateAsString = moment(processBy).format('ddd Do MMM');
    const processByTime = moment(processBy).format('ha');

    return (
      <Box
        fontSize="14px"
        mt="8px"
      >
        {PermissionsService.isInternalRole() ? (
          <Fragment>
            Order to be placed before <strong>{processByTime}</strong> on <strong>{processByDateAsString}</strong>, for delivery on <strong>{deliveryDateAsString}</strong>
          </Fragment>
        ) : (
          <Fragment>
            Due for delivery on <strong>{deliveryDateAsString}</strong>
          </Fragment>
        )}
      </Box>
    );
  };

  return (
    <Box p="60px 0 15px 0">
      <Link
        to={NavPages.WholesaleOrders}
        css={{
          fontSize: '14px',
          paddingBottom: '8px',
          display: 'block'
        }}
      >
        <Flex alignItems="center">
          <Box
            pr="7px"
            color={colors.floomMidnightBlue}
            css={{
              height: '13px'
            }}
          >
            <Icon
              iconName="arrow-full-left"
              size={14}
            />
          </Box>
          <Box color={colors.floomMidnightBlue}>
            Back to all orders
          </Box>
        </Flex>
      </Link>
      <Box css={textStyles.h2}>
        {headingPrefix}Pre-order #{StringFormat.truncate(selectedWholesalePreOrderStore!.preOrder.id || '', 7, '')}
      </Box>
      {renderOrderDetails()}
    </Box>
  );
}));

import styled from '@emotion/styled';

import { AbsoluteCover } from 'utils/css-mixins/css-mixins';
import {
  textStyles,
  shadows,
  colors
} from 'utils/rebass-theme';

export const DropArea: any = styled.div`
  ${textStyles.footnote};
  text-align: center;
  font-size: 16px;
  width: 100%;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  transition: box-shadow .2s ease-in-out;
  box-shadow: ${(props: any): any => props.isDragging ? shadows.small : 'none'};
  border: 2px dashed ${colors.middleGrey};

  > div {
    :focus {
      outline: none;
    }
  }

  :focus {
    box-shadow: ${shadows.small};
  }

  > input {
    ${AbsoluteCover}

    display: none;
    z-index: 100;
  }
`;

export const DropAreaContent: any = styled.div`
  display: flex;
  padding: 40px;
  flex-wrap: wrap;
  justify-content: center;
`;

import { FC } from 'react';

import { css } from '@emotion/react';

import { colors } from 'utils/rebass-theme';

import {
  Wrapper
} from './button.styles';
import {
  MarketingButtonProps,
  MarketingButtonType
} from './button.types';

const styleConfig: { [key in MarketingButtonType]: { text: string; background: string } } = {
  primary: {
    text: colors.floomCream,
    background: colors.floomOrange
  },
  secondary: {
    text: colors.floomMidnightBlue,
    background: colors.floomCream
  }
};

export const MarketingButton: FC<MarketingButtonProps> = ({ copy, type, isLoading }) => {
  const styleOption = styleConfig[type!];

  return (
    <Wrapper
      disabled={!!isLoading}
      css={css`
        color: ${styleOption.text};
        background-color: ${styleOption.background};
      `}
    >
      {copy}
    </Wrapper>
  );
};

MarketingButton.defaultProps = {
  type: 'primary'
};

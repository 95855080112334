import gql from 'graphql-tag';

import {
  GraphQLHelpers
} from 'lib';

import {
  ORDER_FRAGMENT_SMALL
} from '../fragments/order-fragments';

export const ORDER_QUICK_SEARCH_QUERY = (vars: any): any => gql`
  query OrderQuickSearchQuery ${vars.merchantId ? `(
    ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
  )` : ''} {
    ordersConnection(
      first: 20
      where: {
        deletedAt: null
        ${GraphQLHelpers.searchableContains(vars.search)}
        ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
      }
    ) {
      edges {
        node {
          ...OrderFragmentSmall
        }
      }
    }
  }
  ${ORDER_FRAGMENT_SMALL}
`;

export const ORDER_EXPORT_QUERY = gql`
  query OrderListQuery(
    $orderBy: OrderOrderByInput
    $first: Int
    $skip: Int
    $where: OrderWhereInput
  ) {
    exportOrdersList(
      first: $first,
      skip: $skip,
      orderBy: $orderBy,
      where: $where
    )
    {
      data {
        orderNumber
        orderDate
        deliveryDate
        products
        addons
        senderName
        senderPhone
        senderEmail
        recipientName
        recipientAddress
        recipientPhone
        deliveryInstructions
        giftMessages
        tax
        merchantName
        channel
        deliveryCutoff
      }
    }
  }
`;

import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';

import { Card } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const PaymentMethodWrapper = styled(Box)<any>`
  ${Card};
  transition: background .2s ease-in-out;
  cursor: ${({ isButton }): string => isButton ? 'pointer' : 'auto'};
  display: inline-block;
  width: ${({ fullWidth }): string => fullWidth ? '100%' : 'auto'};

  &:hover {
    background: ${({ onClick }): string => onClick ? colors.uiFills : colors.lightGrey};
  }
`;

export const PaymentMethodDetails = styled(Flex)<any>`
  align-items: center;
  display: inline-flex;
  padding: 14px 20px;
  background: ${colors.lightGrey};
  width: ${({ fullWidth }): string => fullWidth ? '100%' : 'auto'};
`;

export const PaymentMethodBody = styled(Box)<any>`
  padding: 14px 20px;
  background: ${colors.uiFills};
`;

export const PaymentMethodType = styled(Text)`
  ${textStyles.label};
  text-transform: uppercase;
  margin-bottom: 2px;
`;

export const PaymentMethodCardNo = styled(Text)`
  ${textStyles.footnote};
`;

export const PaymentMethodExpiry = styled(Text)`
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
  color: ${(props): string => props.isExpiring ? colors.errorText : colors.floomMidnightBlue}
`;

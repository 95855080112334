/* eslint-disable function-paren-newline */
import { TradeSku } from 'generated-types';

import {
  SupplierTradeSkuAvailabilities,
  SupplierTradeSkuAvailabilityGroups,
  TransformedTradeSkuAvailablityItem
} from '../components/wholesale-shop-listing-card/wholesale-shop-listing-card.types';

export const sortSupplierTradeSkuAvailabilityGroups = (
  supplierTradeSkuAvailabilityGroups: SupplierTradeSkuAvailabilityGroups
): SupplierTradeSkuAvailabilities[] => {
  const supplierGroups = Object.values(supplierTradeSkuAvailabilityGroups);

  return supplierGroups
    .sort((a: SupplierTradeSkuAvailabilities, b: SupplierTradeSkuAvailabilities) => a.supplier.name.localeCompare(b.supplier.name));
};

export const groupTradeSkuAvailabilityBySupplier = (
  availabilities: TransformedTradeSkuAvailablityItem[]
): SupplierTradeSkuAvailabilityGroups => {
  return availabilities.reduce(
    (
      acc: SupplierTradeSkuAvailabilityGroups,
      curr: TransformedTradeSkuAvailablityItem
    ) => {
      const supplierId = curr.availabilityItem.supplier!.id;
      const accSupplierAvailabilities = acc[supplierId]
        ? acc[supplierId].availability
        : [];

      const supplierAvailabilities: SupplierTradeSkuAvailabilities = {
        supplier: {
          id: supplierId,
          name: curr.availabilityItem.supplier!.name
        },
        availability: [...accSupplierAvailabilities, curr]
      };

      supplierAvailabilities.availability.sort((a, b) => (
        a.availabilityItem?.price?.[0].price || 0) - (b.availabilityItem?.price?.[0].price || 0)
      );

      return {
        ...acc,
        [supplierId]: supplierAvailabilities
      };
    },
    {}
  );
};

export const flattenAvailability = (
  tradeSkus?: TradeSku[]
): TransformedTradeSkuAvailablityItem[] => {
  return (
    tradeSkus?.reduce<TransformedTradeSkuAvailablityItem[]>((acc, curr) => {
      const availabilityItems =
        curr.availability?.map<TransformedTradeSkuAvailablityItem>(
          availabilityItem => {
            return {
              availabilityItem: availabilityItem,
              availabilitySku: curr
            };
          }
        ) || [];

      return [...acc, ...availabilityItems];
    }, []) || []
  );
};

import {
  ReactNode,
  Fragment,
  useEffect,
  useState
} from 'react';

import moment from 'moment';

export const useWholesaleCountdown = (date: Date): {
  timeOfDay: string;
  hasNoTimeToCountdown: boolean;
  renderFullDate: () => ReactNode;
} => {
  const [hasNoTimeToCountdown, setHasNoTimeToCountdown] = useState(moment(date).isBefore(moment()));
  const [timeOfDay, setTimeOfDay] = useState(moment(date).format('Do MMMM'));

  const renderFullDate = (): ReactNode => {
    const fullFormatDate = moment(date).format('Do MMMM');

    return (
      <Fragment> on <strong>{fullFormatDate}</strong></Fragment>
    );
  };

  useEffect(() => {
    setHasNoTimeToCountdown(moment(date).isBefore(moment()));
    setTimeOfDay(moment(date).format('ha'));
  }, [date]);

  return {
    timeOfDay,
    hasNoTimeToCountdown,
    renderFullDate
  };
};

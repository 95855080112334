import React, { Component, ReactNode } from 'react';

import { textStyles } from 'utils/rebass-theme';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';

class CancelledMeta extends Component {
  render(): ReactNode {
    return (
      <MetaCardContentWrapper
        iconName="cross-medium"
        css={textStyles.body}
      >
        <span css={textStyles.body}>
          Cancelled
        </span>
      </MetaCardContentWrapper>
    );
  }
}

export default CancelledMeta;

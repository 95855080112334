import gql from 'graphql-tag';

export const SUPPLIER_FRAGMENT = gql`
  fragment SupplierFragment on Supplier {
    id
    active
    integrationType
    name
  }
`;

import { gql } from '@apollo/client';

import { MessageFields } from '../fragments';

export const MESSAGES_QUERY = gql`
  query Messages(
    $conversationId: ID!
    $merchantId: String
  ) {
    messages(
      conversationId: $conversationId
      merchantId: $merchantId
    ) {
      messages {
        ...MessageFields
      }
    }
  }

  ${MessageFields}
`;

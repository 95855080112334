import gql from 'graphql-tag';
import moment from 'moment';

import {
  GraphQLHelpers,
  TimeService
} from 'lib';

export const TODAYS_ORDER_REVENUE = (vars: any): any => gql`
  query TodaysOrderRevenue
    ${vars.merchantId && `(
      ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
    )`
} {
    orders(
      first: 1000
      where: {
        ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
        AND: [
          {
            complete: true
          },
          {
            AND: [
              { orderedAt_gte: "${moment.tz(vars.merchantTimezone).startOf('day').utc().format(TimeService.FLOOMX_TIMESTAMP_STRING_FORMAT)}" }
              { orderedAt_lte: "${moment.tz(vars.merchantTimezone).endOf('day').utc().format(TimeService.FLOOMX_TIMESTAMP_STRING_FORMAT)}" }
            ]
          }
        ]
      }
    ) {
      orderItems {
        price
      }
    }
  }
`;

export const LAST_30_DAYS_ORDER_REVENUE = (vars: any): any => gql`
  query Last30DaysOrderRevenue
    ${vars.merchantId && `(
      ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
    )`
} {
    orders(
      first: 1000
      where: {
        ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
        AND: [
          {
            complete: true
          },
          {
            AND: [
              { orderedAt_gte: "${moment.tz(vars.merchantTimezone).startOf('day').subtract(30, 'days').utc().format(TimeService.FLOOMX_TIMESTAMP_STRING_FORMAT)}" }
              { orderedAt_lte: "${moment.tz(vars.merchantTimezone).startOf('day').utc().format(TimeService.FLOOMX_TIMESTAMP_STRING_FORMAT)}" }
            ]
          }
        ]
      }
    ) {
      orderDiscount {
        total
      }
      tax {
        amount
        inclusive
      }
      orderDeliveryConfig {
        price
      }
      orderItems {
        price
        quantity
        addOnsSnapshot
      }
      channel
    }
  }
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, fontSizes, space } from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 50;
`;

export const ListItemsWrapper = styled(Box)`
  margin-top: ${space[3]}px;
`;

export const ListItem = styled(Box)`
  display: flex;
  color: ${colors['black-light']};
  font-size: ${fontSizes.small}px;
  margin: ${space[2]}px 0;
  line-height: 1.2;
  align-items: baseline;
`;

export const ListItemQuantity = styled(Box)`
  color: ${colors.darkGrey};
  font-size: ${fontSizes.xSmall}px;
  min-width: ${space[5]}px;
`;

export const CloseButtonWrapper = styled(Box)`
  color: ${colors.floomMidnightBlue};
  margin-bottom: ${space[2]}px;
  justify-content: left;
`;

import styled from '@emotion/styled';
import {
  Box,
  Flex
} from 'rebass';

import {
  colors
} from 'utils/rebass-theme';

export const AccordionHeading = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.floomMidnightBlue};
  border-top: 1px solid ${colors.paleGrey};
`;

export const AccordionSelectedRefinements = styled(Box)`
  border-radius: 20px;
  background: ${colors.floomRed};
  margin-right: 10px;
  font-size: 12px;
  color: ${colors.uiFills};
  font-weight: 600;
  display: flex;
  justify-content: center;
  width: 21px;
  height: 21px;
  line-height: 0.8;
  padding-top: 5px;
`;

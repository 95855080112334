import { FC, ReactNode } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { preventOrphans } from 'lib/services/string-format/string-format.service';

import { DatoCmsImageAndText } from 'gatsby-types.d';

import { MarketingButton } from 'features/marketing/components/button/button';
import { MarketingLink } from 'features/marketing/components/link/link';
import { H2, Body, H6 } from 'features/marketing/marketing.styles';

import {
  Content,
  ContentInner,
  Wrapper,
  ButtonWrapper,
  ImageContainer
} from './image-and-text.styles';
import {
  ImageAndTextProps
} from './image-and-text.types';

const hasLink = (data: DatoCmsImageAndText): boolean => {
  return !!data.ctaText && (!!data.ctaLinkExternal || !!data.ctaLinkInternal);
};

export const ImageAndText: FC<ImageAndTextProps> = ({ data }) => {
  const image = data.image?.[0];

  if (!image) return null;

  return (
    <Wrapper imagePosition={data.imagePosition}>
      <ImageContainer>
        <GatsbyImage
          alt={image.alt!}
          image={image.gatsbyImageData}
        />
      </ImageContainer>
      <Content imagePosition={data.imagePosition}>
        <ContentInner>
          {data.subheading && (
            <H6>
              {preventOrphans(data.subheading)}
            </H6>
          )}
          {data.heading && (
            <H2>
              {preventOrphans(data.heading)}
            </H2>
          )}
          {data.body && (
            <Body>
              {preventOrphans(data.body)}
            </Body>
          )}
          { hasLink(data) && (
            <ButtonWrapper>
              <MarketingLink
                type={data.ctaLinkInternal ? 'internal' : 'external'}
                url={!!data.ctaLinkInternal ? `/${data.ctaLinkInternal.slug}` : data.ctaLinkExternal}
                renderLink={(): ReactNode => (
                  <MarketingButton
                    copy={data.ctaText!}
                  />
                )}
              />
            </ButtonWrapper>
          )}
        </ContentInner>
      </Content>
    </Wrapper>
  );
};

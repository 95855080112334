import styled from '@emotion/styled';

export const Wrapper = styled.span<{ disabled: boolean }>`
  font-size: 20px;
  padding: 20px 25px 23px 25px;
  display: inline-block;
  font-weight: 700;
  border-radius: 3px;
  transition: all .2s ease-in-out;
  opacity: ${({ disabled }): number => disabled ? .5 : 1};
  cursor: ${({ disabled }): string => disabled ? 'not-allowed' : 'pointer'};

  @media (min-width: 900px) {
    min-width: 9em;
  }
`;

import {
  FC,
  Fragment,
  useEffect,
  useRef,
  useState
} from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex } from 'rebass';

import { ListType } from 'generated-types.d';

import { StringFormat } from 'lib';

import { colors, space } from 'utils/rebass-theme';

import { CatalogExplorerModalData } from 'features/modal-dialogue/components/modals/catalog-explorer-modal/catalog-explorer-modal.types';
import { ListSettingsSidebarData } from 'features/modal-dialogue/components/modals/list-settings-sidebar/list-settings-sidebar.types';

import Button from 'components/button';
import Icon from 'components/icon';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import {
  HeaderWrapper,
  TitleInput,
  SubheaderButtonGroup,
  MetaButton,
  Dot
} from './single-list-header.styles';
import {
  SingleListHeaderProps
} from './single-list-header.types';

const SingleListHeaderComponent: FC<SingleListHeaderProps> = ({
  selectedListStore,
  limitedView,
  modalStore,
  merchantStore
}) => {
  const id = selectedListStore!.list?.id;
  const title = selectedListStore!.list?.title;
  const description = selectedListStore!.list?.description;
  const date = selectedListStore!.list?.date;
  const supplier = selectedListStore!.list?.suppliers?.[0];

  const [draftTitle, setDraftTitle] = useState(title);
  const [isMerchantNotificationVisible, setMerchantNotificationVisibility] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!!title) {
      setDraftTitle(title);
    }
  }, [title]);

  if (!selectedListStore!.list) return null;

  const triggerSettingsSidebar = (autofocusOn?: ListSettingsSidebarData['autofocusOn']): void => {
    modalStore!.triggerModal<ListSettingsSidebarData>({
      modalType: 'listSettingsSidebar',
      data: {
        autofocusOn
      }
    });
  };

  const openCatalogExplorer = (): void => {
    modalStore!.triggerModal<CatalogExplorerModalData>({
      modalType: 'catalogExplorer',
      data: {
        listTitle: selectedListStore!.list?.title || 'Untitled'
      }
    });
  };

  const updateTitle = (): void => {
    if (title !== draftTitle) {
      selectedListStore!.updateList({
        id: id!,
        data: {
          title: draftTitle!,
          description: description!,
          date: date!
        }
      });
    }
  };

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.code === 'Enter') {
      inputRef.current?.blur?.();
    }
  };

  const shouldShowCatalogExplorer = false;
  const shouldDisplayMerchantNotification = isMerchantNotificationVisible
    && selectedListStore!.list
    && !!merchantStore!.merchant
    && merchantStore!.merchant?.id !== selectedListStore!.list?.merchant?.id;

  return (
    <Fragment>
      <HeaderWrapper>
        <Box flexGrow={1}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
          >
            <Box flex="1">
              <TitleInput
                type="text"
                ref={inputRef}
                value={draftTitle || ''}
                placeholder="Add a title"
                onChange={(e): void => setDraftTitle(e.target.value)}
                onKeyDown={onEnter}
                onBlur={updateTitle}
              />
              <SubheaderButtonGroup>
                <MetaButton
                  hasValue={!!description?.length}
                  onClick={(): void => triggerSettingsSidebar('description')}
                >
                  {!!description?.length
                    ? (
                      <Fragment>
                        {StringFormat.truncate(description, 50)}
                      </Fragment>
                    )
                    : (
                      <Fragment>
                        Add description
                      </Fragment>
                    )
                  }
                </MetaButton>
                {!limitedView && (
                  <Fragment>
                    <Dot>·</Dot>
                    <MetaButton
                      hasValue={!!date}
                      onClick={(): void => triggerSettingsSidebar('date')}
                    >
                      {!!date
                        ? (
                          <Fragment>
                            Expected delivery date: <strong>{moment(date).format('ddd Do MMM')}</strong>
                          </Fragment>
                        )
                        : (
                          <Fragment>
                            Add expected delivery date
                          </Fragment>
                        )
                      }
                    </MetaButton>
                    {selectedListStore!.availableSuppliers.length > 1 && (
                      <Fragment>
                        <Dot>·</Dot>
                        <MetaButton
                          hasValue={!!supplier}
                          hasError={!!supplier?.name && !supplier.active}
                          onClick={(): void => triggerSettingsSidebar()}
                        >
                          <strong>
                            {(() => {
                              switch (true) {
                                case !!supplier?.name && supplier.active:
                                  return supplier!.name;

                                case !!supplier?.name && !supplier.active:
                                  return `${supplier!.name} - inactive`;

                                default:
                                  return 'Select a supplier';
                              }
                            })()}
                          </strong>
                        </MetaButton>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </SubheaderButtonGroup>
            </Box>

            {(shouldShowCatalogExplorer && selectedListStore!.list?.type === ListType.Advanced) && (
              <Box onClick={openCatalogExplorer}>
                <Button
                  copy="Explore catalog"
                  appearance="secondary"
                  size="small"
                  iconBefore={(
                    <Box m="3px 5px 0 0">
                      <Icon
                        iconName="book"
                        size={20}
                        pathFill={colors.sky}
                      />
                    </Box>
                  )}
                />
              </Box>
            )}
          </Flex>
        </Box>
      </HeaderWrapper>
      {shouldDisplayMerchantNotification && (
        <Box
          m="20px 0 0"
          p={[`0 ${space[2]}px`, `0 ${space[3]}px`]}
        >
          <Notification
            type={NotificationType.Info}
            hasIcon={false}
            hasClose={true}
            textAlign="left"
            onClose={() => setMerchantNotificationVisibility(false)}
            copy={(
              <Fragment>
                {`You're`} editing the list for <strong>{selectedListStore!.list?.merchant?.title}</strong>, but your global merchant is set to <strong>{merchantStore!.merchant?.title}</strong>
              </Fragment>
            )}
          />
        </Box>
      )}
    </Fragment>
  );
};

export const SingleListHeader = inject((stores: FxStores): InjectedFxStores => ({
  selectedListStore: stores.selectedListStore,
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore
}))(observer(SingleListHeaderComponent));

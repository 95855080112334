import {
  FC,
  useEffect
} from 'react';

import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';

import NavigationStore from 'stores/navigation/navigation-store';
import { NavigationLayout } from 'stores/navigation/navigation-store.types';

import { PageHeader } from 'features/lists/components/page-header/page-header';

import IconButton from 'components/icon-button';

import { HeaderDeliveryDate } from '../header-delivery-date/header-delivery-date';
import { HeaderOrderButton } from '../header-order-button/header-order-button';

import {
  PageHeaderWrapper,
  HeaderRight
} from './wholesale-shop-header.styles';
import {
  WholesaleShopHeaderProps
} from './wholesale-shop-header.types';

export const WholesaleShopHeader: FC<WholesaleShopHeaderProps> = inject((stores: FxStores): InjectedFxStores => ({
  wholesaleShopStore: stores.wholesaleShopStore,
  navigationStore: stores.navigationStore
}))(observer(({
  onBack,
  navigationStore,
  wholesaleShopStore,
  title = 'Wholesale shop',
  shouldDisplay = {
    deliveryDate: false,
    orderButton: false
  }
}) => {
  useEffect(() => {
    const navLayoutListener = observe<NavigationStore, 'layout'>(
      navigationStore!, 'layout',
      ({
        oldValue: oldLayout,
        newValue: newLayout
      }) => {
        if (oldLayout === NavigationLayout.Collapsed && newLayout === NavigationLayout.Open) {
          wholesaleShopStore!.hideOrderSidebar();
        }
      }
    );

    return () => {
      navLayoutListener();
    };
  }, []);

  return (
    <PageHeader
      renderCenter={() => (
        <PageHeaderWrapper>
          {title}
        </PageHeaderWrapper>
      )}
      renderLeft={() => onBack ? (
        <IconButton
          iconName="arrow-small-left"
          size="large"
          onClick={onBack}
        />
      ) : null}
      renderRight={() => (
        <HeaderRight>
          <HeaderDeliveryDate
            isVisible={!!shouldDisplay?.deliveryDate}
          />
          <HeaderOrderButton
            isVisible={!!shouldDisplay?.orderButton}
          />
        </HeaderRight>
      )}
    />
  );
}));

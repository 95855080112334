import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors, shadows, textStyles } from 'utils/rebass-theme';

export const Content = styled(Flex)`
  position: relative;
  align-items: flex-start;
  margin-top: 5px;
  flex-flow: column;
  text-align: center;
  width: 100%;
`;

export const UploadButton: any = styled.label`
  ${textStyles.footnote};
  text-align: center;
  font-size: 16px;
  width: 170px;
  height: 206px;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  transition: box-shadow .2s ease-in-out;
  box-shadow: ${(props: any): any => props.imageHovering ? shadows.small : 'none'};
  color: ${(props: any): any => props.validationError ? colors.errorText : 'inherit'};
  border: 2px dashed ${(props: any): any => props.validationError ? colors.errorText : colors.middleGrey};

  > input {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 170px;
    height: 206px;
    z-index: 100;
  }
`;

export const UploadLabelContent = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 170px;
  height: 206px;
  > div {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const UploadImageSubtitle: any = styled.div`
  color: ${(props: any): any => props.validationError ? colors.errorText : colors.middleGrey};
  user-select: none;
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 14px;
  padding: 0 10%;
`;

export const UploadImageTitle = styled.p`
  user-select: none;
  margin-top: 10px;
  margin-bottom: 0px;
`;

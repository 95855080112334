import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import TagsInput, { TagProps } from 'react-tagsinput';
import { Box } from 'rebass';

import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import FieldText from 'components/field-text';
import Lozenge from 'components/lozenge';

import * as Config from './delivery-zone-tags.config';
import * as Styles from './delivery-zone-tags.styles';
import * as Types from './delivery-zone-tags.types';

interface TagsPropsExtended extends TagProps {
  tag: Types.ZoneTag;
}

class DeliveryZoneTags extends Component<Types.DeliveryZoneTagsProps, Types.DeliveryZoneTagsState> {
  state: Types.DeliveryZoneTagsState = {
    tags: this.props.data
  };

  static getDerivedStateFromProps(props: any, state: any): any {
    if (props.data !== state.tags) {
      return {
        tags: props.data
      };
    }

    return null;
  }

  private validPostcodeId = (tag: Types.ZoneTag): any => {
    const match = this.props.deliveryStore!.allDeliveryZones.find(zone => zone.node.postalCode.toUpperCase() === tag.postalCode.toUpperCase());

    if (match) {
      return match.node.id;
    }

    return null;
  };

  private isPostalCodeValid = (postalCode: string): boolean => !!postalCode.toLowerCase().match(Config.POSTCODE_REGEX);

  private handleNewTags = (tags: Types.ZoneTag[]): Types.ZoneTag[] => {
    const newTagCount = tags.filter(tag => !tag.type).length;
    const missedTags: string[] = [];
    const newTags = [...tags]
      .reduce((acc: Types.ZoneTag[], currTag: Types.ZoneTag): Types.ZoneTag[] => {
        const isExisting = currTag?.type && currTag?.type === 'existing';

        if (acc.length === 0 || !acc.some(tag => tag.postalCode.toUpperCase() === currTag.postalCode.toUpperCase())) {
          return [
            ...acc,
            {
              type: isExisting ? 'existing' : 'new',
              postalCode: currTag.postalCode.toUpperCase(),
              id: this.validPostcodeId(currTag),
              isValid: isExisting || this.isPostalCodeValid(currTag.postalCode)
            }
          ];
        }
        missedTags.push(currTag.postalCode.toUpperCase());

        return acc;
      }, []);

    if (!!missedTags.length) {
      const postalCodeString = missedTags.join(', ');
      const addedString = newTagCount > 1 ? `${newTagCount} new postal code${newTagCount > 1 ? 's' : ''} added, though some ` : 'All ';

      this.props.toasterStore?.popToast(`${addedString}new postal codes could not be added: ${postalCodeString}. This is likely because they already exist in this list of delivery zones`, ToastType.Error);
    } else if (newTagCount > 1) {
      this.props.toasterStore?.popToast(`${newTagCount} new postal codes added`, ToastType.Success);
    }

    return newTags;
  };

  private handleChange = (tags: Types.ZoneTag[]): void => {
    const newTags = this.handleNewTags(tags);

    this.props.onValidate(!newTags.find((tag: Types.ZoneTag) => !tag.isValid));
    this.props.onUpdate(newTags);
  };

  private renderTagsLayout = (tagComponents: any, inputComponent: any): JSX.Element => {
    return (
      <div>
        <span>
          {tagComponents}
        </span>
        <Styles.InputContainer>
          {!this.props.isDisabled && inputComponent}
        </Styles.InputContainer>
      </div>
    );
  };

  private setTagColour = (tag: Types.ZoneTag): ColourOption => {
    switch (true) {
      case !tag.isValid:
        return ColourOption.errorBg;

      case tag.isValid && tag.type === 'new':
        return ColourOption.lightGreen;

      default:
        return ColourOption.paleGrey;
    }
  };

  private renderTag = (props: TagsPropsExtended): React.ReactNode => {
    const { tag, key, onRemove } = props;

    return (
      <>
        <Lozenge
          key={key}
          copy={tag.postalCode}
          color={ColourOption.floomMidnightBlue}
          bg={this.setTagColour(tag)}
          css={css`
            display: inline-block;
            margin: 5px 0px 5px 5px;
          `}
          hasRemoveAction={!this.props.isDisabled}
          removeAction={(): any => onRemove(key)}
        />
        {/* This space is by design - so that users can copy + paste coverages with spaces in them. Don't remove */}
        {' '}
      </>
    );
  };

  private renderTagsInput = (props: TagProps): JSX.Element => {
    const { onChange, value, ...other } = props;

    return (
      <FieldText
        onChange={onChange}
        onPaste={onChange}
        value={value}
        size="small"
        {...other}
      />
    );
  };

  render(): ReactNode {
    return (
      <Box p="10px">
        <TagsInput
          css={Styles.TagsContainer}
          tagDisplayProp="postalCode"
          addKeys={Config.DELIMITERS}
          addOnPaste={true}
          pasteSplit={(data): any => data.split(/,| |, /).map(d => d.trim())}
          inputProps={{ placeholder: 'Add Postcode' }}
          value={this.state.tags}
          renderLayout={this.renderTagsLayout}
          renderInput={this.renderTagsInput}
          renderTag={this.renderTag}
          onChange={this.handleChange}
          disabled={this.props.isDisabled}
        />
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  deliveryStore: stores.deliveryStore,
  toasterStore: stores.toasterStore
}))(observer(DeliveryZoneTags));

import { OrderNote, UserRole } from 'generated-types';

import { OrdersStore } from 'stores/orders/orders-store.types';

export interface OrderDetailNotesProps {
  ordersStore?: OrdersStore;
  notes: OrderNote[];
  orderId: string;
  onEditNote: () => void;
}

export interface OrderDetailNotesState {
  floristNote: OrderNote | undefined;
  floomNoteEditing: boolean;
  floristNoteEdited: string;
  floomNote: OrderNote | undefined;
  floristNoteEditing: boolean;
  floomNoteEdited: string;
  isLoading: boolean;
}

export enum NoteType {
  Floom = 'floom',
  Florist = 'florist'
}

export type OrderNodeConfigOption =
  | 'floom'
  | 'florist';

export type OrderNodeConfig = {
  [key in OrderNodeConfigOption]: {
    title: string;
    restrictedRoles: UserRole[];
    canEdit: boolean;
  }
};

import styled from '@emotion/styled';

import { Card } from 'utils/css-mixins';

export const Content = styled.div``;

export const TextBox = styled.div`
  ${Card}
  padding: 30px;
  margin-top: 25px;
`;

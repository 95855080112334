import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import FormFieldWrapper from 'components/create-labeled-component';
import FxTextArea from 'components/field-text-area';

class ProductDescription extends Component<any> {
  render(): React.ReactNode {
    return (
      <FormFieldWrapper
        title="Description"
        optional={true}
      >
        <FxTextArea
          value={this.props.productEditStore.description}
          onChange={this.props.productEditStore.updateDescription}
        />
      </FormFieldWrapper>
    );
  }
}

export default inject('productEditStore')(observer(ProductDescription));

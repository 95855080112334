import { FC, Fragment, useContext } from 'react';

import { WholesaleOrder } from 'generated-types';
import NavService from 'lib/services/nav/nav.service';
import { inject, observer } from 'mobx-react';

import ModalStore from 'stores/modal-store/modal-store';
import SelectedWholesalePreOrderStore from 'stores/selected-wholesale-pre-order/selected-wholesale-pre-order-store';
import ToasterStore from 'stores/toaster-store/toaster-store';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';

import { FooterWrapper } from 'features/lists/lists.styles';
import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';
import { WHOLESALE_DELIVERY_SETTINGS_PERMISSIONS } from 'features/wholesale/wholesale.constants';

import Button from 'components/button';

import { SingleWholesalePreOrderContext } from '../single-pre-order.context';

import { StyledButton } from './single-pre-order-footer.styles';

interface SinglePreOrderFooterProps {
  selectedWholesalePreOrderStore?: SelectedWholesalePreOrderStore;
  modalStore?: ModalStore;
  toasterStore?: ToasterStore;
}

export const SinglePreOrderFooter: FC<SinglePreOrderFooterProps> = inject((stores: FxStores) => ({
  selectedWholesalePreOrderStore: stores.selectedWholesalePreOrderStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(({
  selectedWholesalePreOrderStore,
  modalStore,
  toasterStore
}) => {
  const {
    isEditingPreOrder,
    isPreOrderEditable,
    isPreOrderActive,
    toggleEditingState
  } = useContext(SingleWholesalePreOrderContext);

  if (!selectedWholesalePreOrderStore!.preOrder || !isPreOrderActive) return null;

  const getDraftOrder = (): WholesaleOrder | null => {
    return selectedWholesalePreOrderStore!.preOrder!.wholesaleOrder?.status === 'Draft'
      ? selectedWholesalePreOrderStore!.preOrder!.wholesaleOrder
      : null;
  };

  const handleCancelPreOrder = async (): Promise<void> => {
    modalStore!.triggerModal<ConfirmationModalData>({
      modalType: 'confirmation',
      data: {
        title: 'Cancel wholesale pre-order?',
        copy: (
          <Fragment>
            By confirming, your pre-order will be cancelled, and you may lose your delivery slot. Cancelling a wholesale pre-order is irreversible.
          </Fragment>
        ),
        confirmButtonCopy: 'Yes, cancel',
        cancelButtonCopy: 'No, go back',
        errorCopy: '',
        confirmButtonAction: async (): Promise<any> => new Promise(async (resolve, reject): Promise<void> => {
          const preOrderId = selectedWholesalePreOrderStore!.preOrder?.id;

          if (preOrderId) {
            try {
              await selectedWholesalePreOrderStore?.cancelPreOrder({ preOrderId });
              toasterStore?.popSuccessToast(`Your order`, 'cancel');
              resolve('');
              NavService.wholesaleOrders();
            } catch (error) {
              toasterStore?.popErrorToast('your wholesale pre-order', 'cancel');
              resolve('');
            }
          } else {
            reject();
          }
        })
      }
    });
  };

  const handleCreateOrEditDraftOrder = async (): Promise<void> => {
    const draftOrder = getDraftOrder();

    if (draftOrder) {
      NavService.wholesaleManageOrder(draftOrder.id);

      return;
    }

    modalStore!.triggerModal<ConfirmationModalData>({
      modalType: 'confirmation',
      data: {
        title: 'Create draft order from pre-order?',
        copy: (
          <Fragment>
            By confirming, you will create a draft wholesale order for <strong>{selectedWholesalePreOrderStore!.preOrder?.merchant?.title}</strong>.
          </Fragment>
        ),
        confirmButtonCopy: 'Yes, create',
        cancelButtonCopy: 'No, go back',
        errorCopy: '',
        confirmButtonAction: async (): Promise<any> => new Promise(async (resolve, reject): Promise<void> => {
          const preOrder = selectedWholesalePreOrderStore!.preOrder;

          if (preOrder) {
            try {
              const result = await selectedWholesalePreOrderStore?.createDraftOrder(preOrder);

              if (result) {
                toasterStore?.popSuccessToast(`Your order`, 'create');
                resolve('');
                NavService.wholesaleManageOrder(result.id);
              }
            } catch (error) {
              toasterStore?.popErrorToast('your wholesale pre-order', 'cancel');
              resolve('');
            }
          } else {
            reject();
          }
        })
      }
    });
  };

  return (
    <FooterWrapper>
      {isPreOrderEditable && (
        <Fragment>
          <StyledButton onClick={handleCancelPreOrder}>
            <Button
              size="large"
              isParentWidth={true}
              appearance="danger"
              copy="Cancel pre-order"
            />
          </StyledButton>
          <StyledButton onClick={toggleEditingState}>
            <Button
              size="large"
              isParentWidth={true}
              appearance={isEditingPreOrder ? 'success' : 'primary'}
              copy={isEditingPreOrder ? 'Finish editing' : 'Edit pre-order'}
            />
          </StyledButton>
        </Fragment>
      )}
      <FloomXRestrictedComponent
        restrictedRoles={WHOLESALE_DELIVERY_SETTINGS_PERMISSIONS.createDraftOrder.restrictedRoles}
        considerSuperRole={true}
      >
        <StyledButton onClick={handleCreateOrEditDraftOrder}>
          <Button
            size="large"
            appearance="primary"
            copy={!!getDraftOrder() ? 'Edit draft order' : 'Create draft order'}
            isParentWidth={true}
          />
        </StyledButton>
      </FloomXRestrictedComponent>
    </FooterWrapper>
  );
}));

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, fontSizes } from 'utils/rebass-theme';

export const ProfileIcon = styled(Box)`
  background: ${colors.floomMidnightBlue};
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: ${fontSizes.xSmall}px;
  font-weight: 600;
  border-radius: 50%;
  color: ${colors.white};
  text-transform: uppercase;
`;

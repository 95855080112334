import React from 'react';

import { Image } from 'rebass';

import Icon from 'components/icon';
import ImgixImage from 'components/imgix-image';

import * as Styles from './product-image-slider.styles';

interface ProductImageSliderProps {
  orderItem: any;
  medias: [];
}

export default class ProductImageSlider extends React.Component<ProductImageSliderProps> {
  state = {
    imageOffset: 0
  };

  setImageOffset = (offset: number): void => {
    this.setState({
      imageOffset: offset
    });
  };

  render(): JSX.Element {
    const snapshot = this.props.orderItem.productSnapshot;
    const variationTitle = snapshot.variation ? ` - ${snapshot.variation.type}` : '';
    const productTitle = snapshot.product ? snapshot.product.title : snapshot.title;

    const images: Array<{ src: any }> = this.props.medias;

    if (!images.length && snapshot.imageUrl) {
      images.push({ src: snapshot.imageUrl });
    }

    return (
      <>
        <Styles.GalleryTopWrapper>
          <Styles.GalleryProductTitleWrapper>
            <p>{this.props.orderItem.quantity}</p>
            <h3>{`${productTitle}${variationTitle}`}</h3>
          </Styles.GalleryProductTitleWrapper>
          <Styles.GalleryButtonsWrapper display={!!(this.props.medias.length > 1)}>
            <div
              onClick={(): void => this.setImageOffset(this.state.imageOffset !== 0 ? this.state.imageOffset + 1 : 0)}
            >
              <Icon iconName="arrow-small-left" />
            </div>
            <div
              onClick={(): void => this.setImageOffset(this.state.imageOffset > -(this.props.medias.length - 1)
                ? this.state.imageOffset - 1
                : -(this.props.medias.length - 1))}
            >
              <Icon iconName="arrow-small-right" />
            </div>
          </Styles.GalleryButtonsWrapper>
        </Styles.GalleryTopWrapper>
        {
          <Styles.ImagesWrapper>
            <Styles.ImagesHolder imageOffset={this.state.imageOffset}>
              {this.props.medias.map((media: any, index: number) => {
                if (media.src.indexOf('floom.imgix.net') < 0) {
                  return (
                    <ImgixImage
                      key={index}
                      config={{
                        path: media.src!
                      }}
                      width={204}
                      height={204}
                      css={Styles.VariationImageStyle}
                    />
                  );
                }

                return (
                  <Image
                    key={media.src}
                    width={204}
                    height={204}
                    src={media.src}
                    css={Styles.VariationImageStyle}
                  />
                );
              })}
            </Styles.ImagesHolder>
          </Styles.ImagesWrapper>
        }
      </>
    );
  }
}

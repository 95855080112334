import { gql } from '@apollo/client';

import { ProductFragments } from 'lib';

export const PRODUCT_CREATE = gql`
  mutation CreateProduct(
    $data: ProductCreateInput!
  )
  {
    createProduct(
      data: $data
    ) {
      id
    }
  }
`;

export const PRODUCT_EDIT = gql`
  mutation EditProduct(
    $data: ProductUpdateInput!
    $productId: ID!
  )
  {
    editProduct(
      data: $data,
      where: {
        id: $productId
      }
    ) {
      ...ProductFull
    }
  }
  ${ProductFragments.PRODUCT_FULL_FRAGMENT}
`;

export const PRODUCT_VARIATION_DELETE = gql`
  mutation DeleteProductVariation(
    $productVariationId: ID!
  )
  {
    deleteProductVariation(
      where: {
        id: $productVariationId
      }
    ) {
      id
    }
  }
`;

import styled from '@emotion/styled';

import { fontStacks } from 'utils/rebass-theme';

export const H1 = styled.h1`
  font-size: 30px;
  margin-bottom: 25px;
  line-height: 1.2;

  @media (min-width: 900px) {
    font-size: 45px;
    margin-bottom: 25px;
    line-height: 1.3;
  }
`;

export const H2 = styled.h2`
  font-size: 30px;
  margin-bottom: 25px;

  @media (min-width: 900px) {
    font-size: 40px;
    margin-bottom: 30px;
  }
`;

export const H3 = styled.h3`
  font-size: 20px;
  margin-bottom: 1.5em;
  font-weight: 400;
  line-height: 1.4;
  font-family: ${fontStacks.secondary};

  @media (min-width: 900px) {
    font-size: 22px;
  }
`;

export const H4 = styled.h3`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;

  @media (min-width: 900px) {
    font-size: 20px;
  }
`;

export const H6 = styled.h3`
  font-size: 14px;
  margin-bottom: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 1.4;

  @media (min-width: 900px) {
    font-size: 16px;
  }
`;

export const Body = styled.div`
  white-space: pre-line;
  font-size: 18px;
  margin-bottom: 1.5em;
  font-weight: 400;
  line-height: 1.4;
  font-family: ${fontStacks.secondary};

  @media (min-width: 900px) {
    font-size: 20px;
  }

  @media (min-width: 1400px) {
    font-size: 22px;
  }
`;

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const Content = styled.div`
  ${textStyles.subhead}
`;

export const MultiCheckboxes = styled(Flex)`
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const CheckboxErrorMessage = styled.div`
  margin-top: -20px;
  min-height: 20px;
`;

import {
  Fragment,
  useEffect,
  useState
} from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { AttachmentType } from 'types/conversations.types';

import ConversationsStore from 'stores/conversations/conversations-store';
import MerchantStore from 'stores/merchant/merchant-store';
import SelectedListsStore from 'stores/selected-list/selected-list-store';

import PlantsIconImage from 'assets/images/wholesale/no-results.gif';

import { LoadingImage } from 'components/loading-image/loading-image';
import WithLoading from 'components/with-loading';

import { ConversationFooter } from '../conversation-footer/conversation-footer';
import { List } from '../list/list';
import { MessagesHeader } from '../messages-header/messages-header';
import { Messages } from '../messages/messages';

import {
  Content,
  Wrapper
} from './conversation-body.styles';

type ConversationBodyProps = {
  conversationsStore?: ConversationsStore;
  selectedListStore?: SelectedListsStore;
  merchantStore?: MerchantStore;
};

export const ConversationBody: React.FC<ConversationBodyProps> = inject((stores: FxStores): InjectedFxStores => ({
  conversationsStore: stores.conversationsStore,
  merchantStore: stores.merchantStore,
  selectedListStore: stores.selectedListStore
}))(observer(({
  conversationsStore,
  merchantStore,
  selectedListStore
}) => {
  const [attachments, setAttachments] = useState<any[]>([]);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const selectedContact = conversationsStore?.selectedContact;
  const selectedContactData = conversationsStore?.selectedContactData;
  const view = conversationsStore?.view;

  const merchantId = merchantStore?.merchant?.id;

  useEffect(() => {
    if (!isUnmounted && merchantId && conversationsStore?.isSubscriptionClosed) {
      conversationsStore?.subscribe(merchantId);
    }
  }, [merchantId, conversationsStore?.isSubscriptionClosed]);

  useEffect(() => {
    return () => setIsUnmounted(true);
  });

  useEffect(() => {
    const listId = conversationsStore?.shareList.listId;

    if (listId) {
      selectedListStore?.fetchList({ listId });

      setAttachments(prevState => [
        ...prevState.filter(attachment => attachment.id === listId),
        {
          id: listId,
          type: AttachmentType.List
        }
      ]);
    }
  }, [conversationsStore?.shareList.listId]);

  useEffect(() => {
    if (selectedContact && !selectedContactData?.messages) {
      conversationsStore!.fetchMessagesForContact(selectedContact.id, merchantStore?.merchant?.id);
    }
  }, [selectedContact?.id]);

  const shouldShareList = !!conversationsStore?.shareList.listId;

  const handleSendMessage = async (input: string): Promise<void> => {
    if (!selectedContact!.conversation) {
      await conversationsStore!.createConversation(selectedContact!.id, merchantStore?.merchant?.id);
    }

    const newMessage = {
      attachments: attachments,
      conversationId: selectedContact!.conversation!.id,
      body: input,
      merchantId: merchantStore?.merchant?.id
    };

    const draftList = selectedListStore?.list;
    setAttachments([]);
    conversationsStore?.resetShareListFlow();
    selectedListStore?.clearList();

    // Don't await on sending message - we optimistically show it in the conversation
    conversationsStore?.createMessage(newMessage, draftList);
  };

  const handleClearList = (): void => {
    setAttachments([]);
    conversationsStore?.resetShareListFlow();
  };

  if (selectedContactData?.error) {
    return (
      <Wrapper>
        <Wrapper p={3}>
          <MessagesHeader />
          <Box p={3}>
            Error getting conversation
          </Box>
        </Wrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper view={view}>
      {selectedContact?.id && (
        <Fragment>
          <Content>
            <MessagesHeader />
            <WithLoading
              loaderSize="small"
              marginTop="30px"
              isLoading={!!selectedContactData?.isLoading}
              hasNoResults={!selectedContactData?.messages?.length}
              renderNoResults={() => ''}
              renderLoading={() => (
                <LoadingImage
                  text="Loading conversation..."
                  imageSrc={PlantsIconImage}
                  imageAlt="plants"
                />
              )}
            >
              {!!selectedContactData?.messages?.length && (
                <Messages
                  error={selectedContactData.error}
                  loading={selectedContactData.isLoading}
                  messages={selectedContactData.messages}
                />
              )}
            </WithLoading>
          </Content>
          {shouldShareList && (
            <List
              list={selectedListStore?.list}
              loading={selectedListStore?.isLoadingList}
              onClose={handleClearList}
              timezone={merchantStore?.merchant?.timezone}
            />
          )}
          <ConversationFooter
            attachments={attachments}
            contactId={selectedContact?.id}
            onSend={async input => {
              await handleSendMessage(input);
            }}
          />
        </Fragment>
      )}
    </Wrapper>
  );
}));

import { OrderNote } from 'generated-types.d';

import OrdersStore from 'stores/orders/orders-store';

import { ModalProps } from 'features/modal-dialogue/modal-dialogue.types';

export enum NoteType {
  Floom = 'floom',
  Florist = 'florist',
}

export interface OrderNotesModalData {
  notes: OrderNote[];
  activeNoteContent?: string;
  orderNo: string;
  onClose: () => void;
}

export interface OrderNotesModalProps extends ModalProps<OrderNotesModalData> {
  ordersStore?: OrdersStore;
}

export interface OrderNotesModalState {
  isEditing: boolean;
  isSaving: boolean;
  editedNote: string;
  noteToEdit: NoteType;
  data: OrderNotesModalData;
}

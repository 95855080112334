
import { FC, Fragment, useMemo } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Currency, DraftWholesaleOrderUpdateInput } from 'generated-types.d';

import DraftWholesaleOrderStore from 'stores/wholesale/draft-wholesale-order-store/draft-wholesale-order-store';

import {
  GrandTotalRow,
  TotalRow,
  TotalsTable,
  TotalValueColumn
} from 'features/lists/lists.styles';
import { WholesaleCheckoutHelpers } from 'features/wholesale/helpers/wholesale-helpers';

import { ManageOrderInput } from './manage-order-input/manage-order-input';

interface ManageOrderBodyTotalProps {
  draftWholesaleOrdersStore?: DraftWholesaleOrderStore;
}

export const ManageOrderBodyTotal: FC<ManageOrderBodyTotalProps> = inject((stores: FxStores) => ({
  draftWholesaleOrdersStore: stores.draftWholesaleOrderStore
}))(observer(({
  draftWholesaleOrdersStore
}) => {
  if (!draftWholesaleOrdersStore!.selectedOrder) return null;

  const merchantCurrency = draftWholesaleOrdersStore!.selectedOrder?.merchant?.currency;
  const deliveryDetails = draftWholesaleOrdersStore!.selectedOrder.deliveryDetails?.[0];
  const shippingTotal = deliveryDetails?.costValue || 0;
  const orderTaxValue = WholesaleCheckoutHelpers.orderTaxValue(draftWholesaleOrdersStore!.selectedOrder);
  const grandTotal = useMemo(() => WholesaleCheckoutHelpers.calculateGrandTotal(draftWholesaleOrdersStore!.selectedOrder), [draftWholesaleOrdersStore!.selectedOrder]);
  const grandTotalWithCurrency = useMemo(() => WholesaleCheckoutHelpers.renderWholesalePrice(grandTotal, merchantCurrency), [grandTotal]);
  const isUpdating = !!draftWholesaleOrdersStore!.isUpdatingOrder;
  const taxLabel = merchantCurrency === Currency.Usd ? 'Sales Tax' : 'VAT';

  const opacityStyle = {
    opacity: isUpdating ? .4 : 1
  };

  const TaxValue: FC<{ isReadonly: boolean }> = ({ isReadonly }) => {
    if (isReadonly) {
      return (
        <Box style={opacityStyle}>
          {WholesaleCheckoutHelpers.renderWholesalePrice(orderTaxValue, merchantCurrency)}
        </Box>
      );
    }

    return (
      <ManageOrderInput
        initialValue={WholesaleCheckoutHelpers.renderWholesalePrice(orderTaxValue)}
        modifiedBy={draftWholesaleOrdersStore!.selectedOrder!.tax?.modifiedBy}
        onUpdate={value => updateOrder({
          tax: {
            update: {
              amount: value
            }
          }
        })}
      />
    );
  };

  const updateOrder = async (data: DraftWholesaleOrderUpdateInput): Promise<void> => {
    draftWholesaleOrdersStore!.updateOrder({
      where: {
        id: draftWholesaleOrdersStore!.selectedOrder?.id
      },
      data: data
    });
  };

  return (
    <Fragment>
      <TotalsTable>
        <TotalRow>
          <Box fontWeight={600}>
            Subtotal
          </Box>
          <TotalValueColumn>
            <Box
              fontWeight={600}
              style={opacityStyle}
            >
              {WholesaleCheckoutHelpers.renderWholesalePrice(draftWholesaleOrdersStore!.selectedOrder.totalPrice!, merchantCurrency)}
            </Box>
          </TotalValueColumn>
        </TotalRow>
        <TotalRow>
          <Box>
            Shipping
          </Box>
          <TotalValueColumn>
            <ManageOrderInput
              initialValue={!shippingTotal ? '0.00' : WholesaleCheckoutHelpers.renderWholesalePrice(shippingTotal)}
              modifiedBy={deliveryDetails?.modifiedBy}
              onUpdate={value => updateOrder({
                deliveryDetails: {
                  update: {
                    costValue: value
                  }
                }
              })}
            />
          </TotalValueColumn>
        </TotalRow>
        <TotalRow>
          <Box>
            {taxLabel}
          </Box>
          <TotalValueColumn>
            <TaxValue isReadonly={merchantCurrency === Currency.Gbp} />
          </TotalValueColumn>
        </TotalRow>
      </TotalsTable>
      <GrandTotalRow>
        <Box fontWeight={600}>
          Total
        </Box>
        <TotalValueColumn
          fontWeight={600}
          style={opacityStyle}
        >
          {grandTotalWithCurrency}
        </TotalValueColumn>
      </GrandTotalRow>
    </Fragment>
  );
}));

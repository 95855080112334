import { GraphQLError } from 'graphql';
import store from 'stores';

import {
  Conversation,
  CreateContactInput,
  CreateConversationInput,
  Event,
  MessagesPage,
  Mutation,
  PhoneContact,
  Subscription,
  Query,
  UpdateContactInput,
  Message,
  CreateMessageInput,
  MerchantWhereInput,
  ConversationWhereInput
} from 'types/conversations.types';

import { ConversationsClient } from 'lib';

import { CREATE_CONVERSATION_MUTATION, SET_CONVERSATION_READ_MUTATION } from '../graphql/mutations/conversation';
import { CREATE_MESSAGE_MUTATION } from '../graphql/mutations/messages';
import { CONTACTS_QUERY, CREATE_CONTACT_MUTATION, DELETE_CONTACT_MUTATION, UPDATE_CONTACT_MUTATION } from '../graphql/queries/contact';
import { MESSAGES_QUERY } from '../graphql/queries/message';
import { ON_EVENT_SUBSCRIPTION } from '../graphql/subscriptions/event';

export class ConversationsService {
  static fetchContactsList = async (where?: MerchantWhereInput): Promise<PhoneContact[]> => {
    try {
      const result = await ConversationsClient.query<Pick<Query, 'contacts'>>({ query: CONTACTS_QUERY, variables: { where }, fetchPolicy: 'network-only' });

      return result.data.contacts;
    } catch (error) {
      return [];
    }
  }

  static createContact = async (input: CreateContactInput): Promise<PhoneContact | undefined> => {
    try {
      const result = await ConversationsClient.mutate<Pick<Mutation, 'createContact'>>({ mutation: CREATE_CONTACT_MUTATION, variables: { input }, fetchPolicy: 'network-only' });

      return result.data?.createContact;
    } catch (error) {
      store.toasterStore.popErrorToast('your new supplier', 'create');

      return Promise.reject(error);
    }
  }

  static updateContact = async (input: UpdateContactInput): Promise<PhoneContact | undefined> => {
    try {
      const result = await ConversationsClient.mutate<Pick<Mutation, 'updateContact'>>({ mutation: UPDATE_CONTACT_MUTATION, variables: { input }, fetchPolicy: 'network-only' });

      return result.data?.updateContact;
    } catch (error) {
      store.toasterStore.popErrorToast('supplier', 'update');

      return Promise.reject(error);
    }
  }

  static deleteContact = async (contactId: string, merchantId?: string): Promise<PhoneContact | undefined> => {
    try {
      const result = await ConversationsClient.mutate<Pick<Mutation, 'deleteContact'>>({ mutation: DELETE_CONTACT_MUTATION, variables: { id: contactId, merchantId: merchantId }, fetchPolicy: 'network-only' });

      return result.data?.deleteContact;
    } catch (error) {
      store.toasterStore.popErrorToast('supplier', 'delete');

      return Promise.reject(error);
    }
  }

  static createConversation = async (input: CreateConversationInput): Promise<Conversation | undefined> => {
    try {
      const result = await ConversationsClient.mutate<Pick<Mutation, 'createConversation'>>({ mutation: CREATE_CONVERSATION_MUTATION, variables: { input }, fetchPolicy: 'network-only' });

      return result.data?.createConversation;
    } catch (error) {
      store.toasterStore.popErrorToast('conversation', 'create');

      return Promise.reject(error);
    }
  }

  static createMessage = async (input: CreateMessageInput): Promise<Message | undefined> => {
    try {
      const result = await ConversationsClient.mutate<Pick<Mutation, 'createMessage'>>({ mutation: CREATE_MESSAGE_MUTATION, variables: { input }, fetchPolicy: 'network-only' });

      return result.data?.createMessage;
    } catch (error) {
      store.toasterStore.popErrorToast('message', 'create');

      return Promise.reject(error);
    }
  }

  static fetchMessages = async (conversationId: string, merchantId?: string): Promise<MessagesPage | null | undefined> => {
    try {
      const result = await ConversationsClient.query<Pick<Query, 'messages'>>({ query: MESSAGES_QUERY, variables: { conversationId, merchantId }, fetchPolicy: 'network-only' });

      return result.data.messages;
    } catch (error) {
      store.toasterStore.popErrorToast('messages', 'get');

      return Promise.reject(error);
    }
  };

  static setConversationRead = async (where: ConversationWhereInput): Promise<Conversation | undefined> => {
    try {
      const result = await ConversationsClient.mutate<Pick<Mutation, 'setConversationRead'>>({ mutation: SET_CONVERSATION_READ_MUTATION, variables: { where }, fetchPolicy: 'network-only' });

      return result.data?.setConversationRead;
    } catch (error) {
      store.toasterStore.popErrorToast('conversation', 'update');

      return Promise.reject(error);
    }
  }

  static subscribe = async (
    merchantId: string,
    onEvent: (message: Event) => void,
    onError: (errors: readonly GraphQLError[]) => void,
    onComplete?: () => void
  ): Promise<ZenObservable.Subscription> => {
    const subscription = await ConversationsClient.subscribe<Pick<Subscription, 'onEvent'>>({ query: ON_EVENT_SUBSCRIPTION, variables: { merchantId } });

    return subscription.subscribe(response => {
      if (response.errors) {
        onError(response.errors);
      }

      const event = response.data?.onEvent;

      if (event) {
        onEvent(event);
      }
    }, response => onError(response.errors || [response]), onComplete);
  }
}

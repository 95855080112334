import React, { Component } from 'react';

import { Analytics } from 'lib';

import SettingsService from 'features/settings/services';

import SettingsRoot from '../../components/settings-root/settings-root';

class SettingsHome extends Component {
  private SettingsAnalytics = SettingsService.SettingsAnalytics;

  componentDidMount(): void {
    Analytics.page(Analytics.FxPageEvent.Settings);

    this.SettingsAnalytics.onOverview();
  }

  render(): JSX.Element {
    return <SettingsRoot {...this.props} />;
  }
}

export default SettingsHome;

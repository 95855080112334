import {
  object
} from 'yup';

import {
  yupCurrency,
  yupOptionalString,
  yupRequiredEmail,
  yupRequiredString
} from '../utils/root-validations';

export const COMPLETE_MERCHANT_VALIDATION = object().shape({
  title: yupRequiredString,
  timezone: yupRequiredString,
  currency: yupCurrency,
  companyName: yupOptionalString,
  tradingNo: yupRequiredString,
  createdBy: object().shape({
    givenName: yupRequiredString,
    familyName: yupRequiredString,
    email: yupRequiredEmail,
    phone: yupRequiredString
  }),
  plan: object().shape({
    id: yupRequiredString
  }),
  subscription: object().shape({
    id: yupRequiredString
  }),
  address: object().shape({
    businessName: yupRequiredString,
    recipientFullName: yupRequiredString,
    address1: yupRequiredString,
    city: yupRequiredString,
    postalCode: yupRequiredString
  }),
  registeredAddress: object().shape({
    businessName: yupRequiredString,
    recipientFullName: yupRequiredString,
    address1: yupRequiredString,
    city: yupRequiredString,
    postalCode: yupRequiredString
  })
});

import Icon from 'components/icon';

import * as Styles from './input-icon.styles';
import * as Types from './input-icon.types';

const InputIcon = (props: Types.InputIconProps): JSX.Element => {
  return (
    <Styles.InputIconWrapper
      isDisabled={!!props.isDisabled}
      {...(props.onClick ? {
        onClick: props.onClick
      } : {})}
    >
      <Icon
        iconName={props.type}
      />
    </Styles.InputIconWrapper>
  );
};

InputIcon.defaultProps = {
  onClick: (): void => {}
};

export default InputIcon;

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles, breakpoints } from 'utils/rebass-theme';

export const SettingsTitle = styled.p`
  ${textStyles.headline};
  font-size: 38px;
`;

export const SettingsWrapper = styled.div`
  margin: 90px 0 60px 0;
  position: relative;

  @media (max-width: ${breakpoints.medium}) {
    margin: 40px 0;
  }
`;

export const ButtonsWrapper: any = styled(Flex)`
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
`;

import {
  RouteType,
  DividerType
} from 'global.types';

import {
  Channel,
  Currency,
  MerchantStage,
  SubscriptionStatus,
  PlanFeature
} from 'generated-types.d';

import { NavCheckerService, MerchantService } from 'lib';

export const INACTIVE_MERCHANT_STAGES: MerchantStage[] = [
  MerchantStage.Churn,
  MerchantStage.Dormant,
  MerchantStage.Unassigned,
  MerchantStage.Deactivated
];

export const ACTIVE_MERCHANT_SUBSCRIPTION_STATUSES: SubscriptionStatus[] = [
  SubscriptionStatus.Active,
  SubscriptionStatus.Trialing
];

export const UNPAID_MERCHANT_SUBSCRIPTION_STATUSES: SubscriptionStatus[] = [
  SubscriptionStatus.PastDue,
  SubscriptionStatus.Unpaid
];

export const MERCHANT_TRIAL_PERIODS: Array<{ title: string; id: string }> = [
  {
    title: 'No "no fee" period',
    id: '0'
  },
  {
    title: '14 Days',
    id: '14'
  }
];

/**
 *
 * BIG BIG BIG NOTE:
 * DO NOT CHANGE THE ID for a nav item IF YOU ARE ALTERING THE URLS
 * If you do, please double check with somebody from backend / frontend
 * to make sure it won't impact any integration tests or other third party
 * integrations (like pingdom).
 *
 */
export const ROUTES: Array<RouteType | DividerType> = [
  {
    isDisabled: false,
    icon: 'dashboard',
    name: 'Overview',
    type: 'ITEM',
    url: '/overview',
    id: 'overview',
    identifier: 'dashboard',
    sectionName: 'Dashboard',
    itemName: 'Overview',
    requiredPlanFeatures: []
  },
  {
    isDisabled: false,
    icon: 'note',
    name: 'Notifications',
    type: 'ITEM',
    url: '/notifications',
    id: 'fx-notifications',
    identifier: 'notifications',
    sectionName: 'Notifications',
    itemName: 'Notifications',
    requiredPlanFeatures: [PlanFeature.Products]
  },
  {
    type: 'DIVIDER',
    name: 'Floom.com',
    isFeatureFlagDisabled: (merchant): boolean => {
      return NavCheckerService.isWholesaleOnly(merchant);
    }
  },
  {
    isDisabled: false,
    icon: 'lock',
    name: 'Orders',
    type: 'ITEM',
    url: '/orders',
    id: 'orders',
    identifier: 'orders',
    sectionName: 'Orders',
    itemName: 'Orders Home',
    requiredPlanFeatures: [PlanFeature.Orders],
    isFeatureFlagDisabled: (merchant): boolean => {
      return NavCheckerService.isWholesaleOnly(merchant);
    },
    children: [
      {
        name: 'Search all orders',
        url: '/orders/list/search',
        id: 'orders-list-search',
        isDisabled: false,
        sectionName: 'Orders',
        itemName: 'Search'
      },
      {
        name: 'Today',
        url: '/orders/list/today/',
        id: 'orders-list-today',
        isDisabled: false,
        sectionName: 'Orders',
        itemName: 'Today'
      },
      {
        name: 'Tomorrow',
        url: '/orders/list/tomorrow',
        id: 'order-list-tomorrow',
        isDisabled: false,
        sectionName: 'Orders',
        itemName: 'Tomorrow'
      },
      {
        name: 'All upcoming',
        url: '/orders/list/upcoming',
        id: 'orders-list-upcoming',
        isDisabled: false,
        sectionName: 'Orders',
        itemName: 'Upcoming'
      },
      {
        name: 'Past orders',
        url: '/orders/list/past',
        id: 'orders-list-past',
        isDisabled: false,
        sectionName: 'Orders',
        itemName: 'Past'
      },
      {
        name: `Mother's Day`,
        url: '/orders/list/peak',
        id: 'orders-list-peak',
        isDisabled: false,
        sectionName: 'Orders',
        itemName: 'Peak'
      }
    ]
  },
  {
    isDisabled: false,
    icon: 'flower',
    name: 'Products',
    type: 'ITEM',
    url: '/products/list',
    id: 'products-list',
    identifier: 'products',
    sectionName: 'Products',
    itemName: 'List',
    requiredPlanFeatures: [PlanFeature.Products],
    isFeatureFlagDisabled: (merchant): boolean => {
      return NavCheckerService.isWholesaleOnly(merchant);
    }
  },
  // {
  //   isDisabled: false,
  //   icon: 'lightning-bolt',
  //   name: 'Promoted',
  //   type: 'ITEM',
  //   url: '/promoted',
  //   id: 'promoted',
  //   identifier: 'promoted',
  //   sectionName: 'Promoted',
  //   itemName: 'Promoted',
  //   requiredPlanFeatures: [PlanFeature.Products],
  //   isBeta: true,
  //   children: [
  //     {
  //       name: 'Create promotion',
  //       url: '/promoted/upcoming/postcodes',
  //       id: 'promoted-upcoming',
  //       isDisabled: false,
  //       sectionName: 'Promoted',
  //       itemName: 'Upcoming'
  //     },
  //     {
  //       name: 'My promotions',
  //       url: '/promoted/my-promotions',
  //       id: 'promoted-my-promotions',
  //       isDisabled: false,
  //       sectionName: 'Promoted',
  //       itemName: 'Current'
  //     }
  //   ]
  // },
  {
    isDisabled: false,
    icon: 'present',
    name: 'Add-ons',
    type: 'ITEM',
    url: '/add-ons/list',
    id: 'add-ons-list',
    identifier: 'addons',
    sectionName: 'Products',
    itemName: 'Add-ons',
    requiredPlanFeatures: [PlanFeature.Products],
    isFeatureFlagDisabled: (merchant): boolean => {
      const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, merchant);

      return isCollectionSeller || NavCheckerService.isWholesaleOnly(merchant);
    }
  },
  {
    isDisabled: false,
    icon: 'bank',
    name: 'Payments',
    type: 'ITEM',
    url: '/payments',
    id: 'payments',
    identifier: 'payments',
    sectionName: 'Payments',
    itemName: 'Payments',
    requiredPlanFeatures: [PlanFeature.Products]
  },
  {
    type: 'DIVIDER',
    name: 'Wholesale',
    isFeatureFlagDisabled: (merchant): boolean => {
      const hasWholesale = merchant?.wholesaleActive;

      return (!hasWholesale || merchant?.currency === Currency.Usd);
    }
  },
  {
    isDisabled: false,
    icon: 'orders',
    name: 'Orders',
    type: 'ITEM',
    url: '/wholesale/orders',
    id: 'wholesale-orders',
    identifier: 'wholesale-orders',
    sectionName: 'Wholesale Orders',
    itemName: 'Wholesale Orders',
    requiredPlanFeatures: [PlanFeature.Wholesale],
    isFeatureFlagDisabled: (merchant): boolean => {
      const hasWholesale = merchant?.wholesaleActive;

      return (!hasWholesale || merchant?.currency === Currency.Usd);
    }
  },
  {
    type: 'DIVIDER',
    name: 'Custom Website',
    isFeatureFlagDisabled: (merchant): boolean => {
      return !NavCheckerService.hasCustomWebsite(merchant);
    }
  },
  {
    isDisabled: true,
    icon: 'chat-bubble',
    name: 'Custom Site',
    type: 'ACTIONITEM',
    url: '/custom-site',
    id: 'custom-site',
    identifier: 'custom-site',
    sectionName: 'Channel',
    itemName: 'Website',
    children: [],
    requiredPlanFeatures: [PlanFeature.Website],
    isFeatureFlagDisabled: (merchant): boolean => {
      return !NavCheckerService.hasCustomWebsite(merchant);
    }
  },
  {
    type: 'DIVIDER'
  },
  {
    isDisabled: false,
    icon: 'settings',
    name: 'Settings',
    type: 'ITEM',
    url: '/settings',
    id: 'fx-settings',
    identifier: 'settings',
    sectionName: 'Settings',
    itemName: 'Home',
    requiredPlanFeatures: []
  },
  {
    isDisabled: false,
    isExternal: true,
    icon: 'book',
    name: 'Learning',
    type: 'ITEM',
    url: process.env.FX_FE_ZENDESK_URL || '',
    id: 'fx-learning',
    identifier: 'learning',
    sectionName: 'Learning',
    itemName: 'Home',
    requiredPlanFeatures: []
  }
];

export const CHANNELS: { [key in Channel]: string } = {
  Floom: 'Floom.com',
  Pos: 'POS',
  Website: 'Custom Website'
};

/**
 * Supported currencies
 */
export const SUPPORTED_CURRENCIES: Currency[] = [Currency.Gbp, Currency.Usd];

export const marketingZIndexes = {
  background: -2,
  contentBelow: -1,
  content: 0,
  subnav: 1,
  subnavContent: 2,
  navMenu: 3,
  navBar: 4,
  navContent: 5
};

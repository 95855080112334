import styled from '@emotion/styled';

export const ToasterContainer = styled.div<any>`
  position: fixed;
  z-index: 999999;
  right: 40px;
  right: ${({ isModal }: any): any => isModal ? '0' : '30px'};
  bottom: ${({ isModal }: any): any => isModal ? '60px' : '30px'};
  width: ${({ isModal }: any): any => isModal ? '100%' : 'auto'};
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }
`;

import React, { ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { OnboardingStage } from 'stores/payment-onboarding/payment-onboarding-store.types';

import OnboardingWrapper from 'features/onboarding/components/onboarding-wrapper';

import Icon from 'components/icon';

import * as Styles from './onboarding-progress-indicator.styles';
import * as Types from './onboarding-progress-indictator.types';

class OnboardingProgressIndicator extends React.Component<Types.OnboardingProgressIndicatorProps> {
  private shouldDisplay = (): boolean => {
    return [OnboardingStage.BankDetails, OnboardingStage.StripeConnect, OnboardingStage.Subscription]
      .some(stage => stage === this.props.paymentOnboardingStore!.stage);
  };

  private renderIndicators = (): ReactNode => {
    return this.props.paymentOnboardingStore!.onboardingConfig.map((step, index) => {
      if (!step.isRequired) return null;

      return (
        <Styles.ProgressItem key={index}>
          <Styles.ProgressIcon
            isComplete={step.isComplete}
            isActive={this.props.selectedIndex === index}
          >
            <Icon iconName="tick" />
          </Styles.ProgressIcon>
          <Styles.ProgressCopy
            isComplete={step.isComplete}
            isActive={this.props.selectedIndex === index}
          >
            {step.title}
          </Styles.ProgressCopy>
        </Styles.ProgressItem>
      );
    });
  };

  render(): ReactNode {
    if (!this.shouldDisplay()) return null;

    return (
      <OnboardingWrapper my="auto">
        <Styles.ProgressWrapper>
          {this.renderIndicators()}
        </Styles.ProgressWrapper>
      </OnboardingWrapper>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  paymentOnboardingStore: stores.paymentOnboardingStore
}))(observer(OnboardingProgressIndicator));

import {
  FC,
  Fragment,
  useState
} from 'react';

import { inject, observer } from 'mobx-react';

import CheckboxItem from 'components/checkbox-item';
import ErrorMessage from 'components/error-message';

import {
  ListWrapper,
  Checkbox,
  Label
} from './catalog-list-suppliers.styles';
import {
  CatalogListColoursProps
} from './catalog-list-suppliers.types';

const CatalogListSuppliers: FC<CatalogListColoursProps> = ({
  wrapperStyles,
  orientation,
  items,
  error,
  errorMessage,
  onChange,
  itemValueField,
  metadataField,
  selectedItems,
  wholesaleFiltersStore
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const getErrorMessage = (): string | undefined => {
    return error && isDirty ? errorMessage : '';
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, item: any): any => {
    if (item.isDisabled) {
      return event.preventDefault();
    }

    setIsDirty(true);

    return onChange(item[itemValueField]);
  };

  return (
    <Fragment>
      <ListWrapper wrapperStyles={wrapperStyles}>
        {
          items.map((item, i) => (
            <CheckboxItem
              item={{
                isDisabled: false,
                title: item.title,
                value: item.value,
                label: wholesaleFiltersStore!.getSupplierNameById(item.title, item.value)
              }}
              isSelected={selectedItems.includes(item[itemValueField])}
              onChange={handleOnChange}
              checkboxList={{
                selectedItems: selectedItems,
                itemValueField: 'label',
                optionTitleField: 'label',
                orientation: orientation
              }}
              metadataField={metadataField}
              key={i}
              checkboxStyles={Checkbox}
              checkboxLabelStyles={Label}
            />
          ))
        }
      </ListWrapper>
      <ErrorMessage
        errorMessage={getErrorMessage()}
      />
    </Fragment>
  );
};

export default inject((stores: FxStores) => ({
  wholesaleFiltersStore: stores.wholesaleFiltersStore
}))(observer(CatalogListSuppliers));

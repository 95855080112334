import {
  FC,
  PropsWithChildren,
  useContext
} from 'react';

import { css } from '@emotion/react';
import { TradeSkuPriceRange as CatalogPriceRange, TradeSkuHitAvailability, TradeSkuHit, CatalogItemHit } from 'global.types';
import { inject, observer } from 'mobx-react';
import { GroupTypeBase, OptionProps } from 'react-select';
import { Box } from 'rebass';

import { CatalogItemType, Currency, ListItemType, Supplier } from 'generated-types.d';

import SelectedListStore from 'stores/selected-list/selected-list-store';

import { breakpoints, colors } from 'utils/rebass-theme';

import { ListPriceRange } from 'features/lists/components/list-price-range/list-price-range';
import {
  filteredTradeSkus,
  selectableTradeSkuData
} from 'features/lists/lists.helpers';
import {
  DisabledItem
} from 'features/lists/lists.styles';
import { SearchListLayoutConfig } from 'features/lists/lists.types';

import { FieldSelect } from 'components/field-select/field-select';

import { SearchResultContext } from './catalog-inline-search-field/catalog-inline-search-body';
import { useCatalogSearchUpdate } from './catalog-inline-search.hooks';
import { InlineSearchSimpleTitle, InlineSearchAdvancedTitle } from './inline-search-title/inline-search-title';

interface DropdownField {
  label: string | number;
  value: string | number |null;
  priceRange?: CatalogPriceRange;
}

const stopPropagation = (event: React.MouseEvent<HTMLDivElement>): void => {
  event.stopPropagation();
  event.preventDefault();
};

const anyOption = {
  label: 'Any',
  value: null
};

const dropdownStyles = {
  width: '75px',
  [`@media only screen and (max-width: ${breakpoints.medium})`]: {
    width: '60px'
  }
};

const getAvailabilityByListSupplier = (
  availabilityItems: TradeSkuHitAvailability[],
  listSuppliers: Supplier[]
): TradeSkuHitAvailability[] => {
  if (!listSuppliers.length) {
    return availabilityItems;
  }

  return availabilityItems.filter?.(availabilityItem => {
    return availabilityItem.supplierId === listSuppliers[0].id;
  });
};

const buildDropdownOptions = (
  tradeSkus: TradeSkuHit[],
  key: keyof Pick<TradeSkuHit, 'height' | 'maturity' | 'potSize' | 'stemLength' | 'weight'>,
  suppliers: Supplier[],
  currency: Currency = Currency.Gbp
): DropdownField[] => {
  return tradeSkus.reduce<DropdownField[]>((acc, curr) => {
    const availabilityBySupplier = getAvailabilityByListSupplier(curr.availability!, suppliers);

    if (!curr[key]) {
      return acc;
    }

    return [
      ...acc,
      ...(availabilityBySupplier?.map((item): DropdownField => {
        return {
          label: curr[key]!,
          value: curr[key]!,
          priceRange: item?.priceRanges?.find(price => price.currency === currency)
        };
      }) || [])
    ];
  }, []) || [];
};

const DropdownOption: FC<PropsWithChildren<OptionProps<DropdownField, false, GroupTypeBase<DropdownField>>> & { hit: CatalogItemHit }> = ({
  innerProps,
  label,
  data,
  hit
}) => {
  const priceRange = data?.priceRange || hit.priceRange;

  return (
    <span {...innerProps}>
      <span
        css={css`
          min-width: 80px;
          display: inline-flex;
        `}
      >
        {label || 'Any'}
      </span>
      <Box
        as="span"
        color={colors.grey}
      >
        <ListPriceRange
          min={priceRange.min}
          max={priceRange.max}
          currency={Currency.Gbp}
        />
      </Box>
    </span>
  );
};

const getPriceRangesByCurrency = (availabilities: TradeSkuHitAvailability[], merchantCurrency: Currency | undefined): CatalogPriceRange[] | undefined => {
  if (!merchantCurrency)  return undefined;

  return availabilities
    .map(availability => availability?.priceRanges?.find(price => price.currency === merchantCurrency))
    .filter((priceRange): priceRange is CatalogPriceRange => !!priceRange);
};

const getMinPriceRange = (availabilities: TradeSkuHitAvailability[], merchantCurrency: Currency | undefined): CatalogPriceRange | undefined => {
  const foundPriceRanges = getPriceRangesByCurrency(availabilities, merchantCurrency);

  return foundPriceRanges?.length ? foundPriceRanges.reduce((prev, curr) => prev.min < curr.min ? prev : curr) : undefined;
};

const getMaxPriceRange = (availabilities: TradeSkuHitAvailability[], merchantCurrency: Currency | undefined): CatalogPriceRange | undefined => {
  const foundPriceRanges = getPriceRangesByCurrency(availabilities, merchantCurrency);

  return foundPriceRanges?.length ? foundPriceRanges.reduce((prev, curr) => prev.max > curr.max ? prev : curr) : undefined;
};

export const CATALOG_INLINE_SEARCH_FIELD_CONFIG: SearchListLayoutConfig = {
  Simple: {
    titleField: {
      key: 'itemName',
      heading: 'Item/Genus',
      renderValue: item => (<InlineSearchSimpleTitle hit={item} />)
    },
    fields: [
      {
        key: 'estimatedPrice',
        heading: 'Est. unit price',
        customStyles: css`
          min-width: 100px;
        `,
        renderValue: hit => {
          const Component: FC<{
            selectedListStore?: SelectedListStore;
          }> = inject((stores: FxStores): InjectedFxStores => ({
            selectedListStore: stores.selectedListStore
          }))(observer(({
            selectedListStore
          }) => {
            const { state } = useContext(SearchResultContext);
            const filteredSkus = filteredTradeSkus(hit.tradeSku, state[hit.objectID]);
            const availabilities = filteredSkus.reduce<TradeSkuHitAvailability[]>((acc, curr) => {
              const availabilityBySupplier = getAvailabilityByListSupplier(curr?.availability || [], selectedListStore!.list?.suppliers || []);

              return [...acc, ...(availabilityBySupplier || [])];
            }, []);
            const merchantCurrency = selectedListStore!.list?.merchant?.currency;
            const minPriceRange = getMinPriceRange(availabilities, merchantCurrency);
            const maxPriceRange = getMaxPriceRange(availabilities, merchantCurrency);

            return (
              <DisabledItem>
                <ListPriceRange
                  min={minPriceRange?.min}
                  max={maxPriceRange?.max}
                  currency={maxPriceRange?.currency}
                />
              </DisabledItem>
            );
          }));

          return (
            <Component />
          );
        }
      }
    ]
  },
  Advanced: {
    titleField: {
      key: 'itemName',
      heading: 'Item/Genus',
      renderValue: item => (<InlineSearchAdvancedTitle hit={item} />)
    },
    fields: [
      {
        key: 'stemLength',
        heading: 'Stem length',
        isMeta: true,
        supportedTypes: [
          CatalogItemType.Flower,
          ListItemType.Flower
        ],
        customStyles: css`
          width: 20%;
        `,
        renderValue: hit => {
          const Component: FC<{
            selectedListStore?: SelectedListStore;
          }> = inject((stores: FxStores): InjectedFxStores => ({
            selectedListStore: stores.selectedListStore
          }))(observer(({
            selectedListStore
          }) => {
            const { state } = useContext(SearchResultContext);
            const { changeProperty } = useCatalogSearchUpdate();
            const hitState = state[hit.objectID];
            const filteredSkus = selectableTradeSkuData(
              hit,
              hitState?.selections || {},
              'stemLength',
              ['maturity'],
              selectedListStore!.list?.suppliers || []
            );
            const stemLength = state[hit.objectID]?.selections?.stemLength;
            const merchantCurrency = selectedListStore!.list?.merchant?.currency;

            switch (true) {
              case !filteredSkus.length && !!stemLength: {
                return (
                  <DisabledItem>
                    {stemLength}
                  </DisabledItem>
                );
              }

              case filteredSkus.length > 0: {
                const portalTarget = document.getElementById('inline-search-results-wrapper');

                return (
                  <div
                    onMouseDown={stopPropagation}
                    onClick={stopPropagation}
                    style={dropdownStyles}
                  >
                    <FieldSelect<DropdownField>
                      onSelect={value => {
                        changeProperty(value, 'stemLength', hit.objectID);
                      }}
                      selectProps={{
                        menuPortalTarget: portalTarget,
                        menuPosition: 'fixed',
                        placeholder: 'Any',
                        closeMenuOnScroll: false,
                        value: {
                          label: stemLength!,
                          value: stemLength!
                        }
                      }}
                      renderOption={props => {
                        return (
                          <DropdownOption
                            {...props}
                            hit={hit}
                          />
                        );
                      }}
                      options={[
                        anyOption,
                        ...buildDropdownOptions(filteredSkus, 'stemLength', selectedListStore!.list?.suppliers || [], merchantCurrency)
                      ]}
                    />
                  </div>
                );
              }

              default: {
                return <DisabledItem>Any</DisabledItem>;
              }
            }
          }));

          return <Component />;
        }
      },
      {
        key: 'estimatedPrice',
        heading: 'Est. unit price',
        customStyles: css`
          min-width: 100px;
        `,
        renderValue: hit => {
          const Component: FC<{
            selectedListStore?: SelectedListStore;
          }> = inject((stores: FxStores): InjectedFxStores => ({
            selectedListStore: stores.selectedListStore
          }))(observer(({
            selectedListStore
          }) => {
            const { state } = useContext(SearchResultContext);
            const filteredSkus = filteredTradeSkus(hit.tradeSku, state[hit.objectID]);
            const availabilities = filteredSkus.reduce<TradeSkuHitAvailability[]>((acc, curr) => {
              const availabilityBySupplier = getAvailabilityByListSupplier(curr?.availability || [], selectedListStore!.list?.suppliers || []);

              return [...acc, ...(availabilityBySupplier || [])];
            }, []);
            const merchantCurrency = selectedListStore!.list?.merchant?.currency;
            const minPriceRange = getMinPriceRange(availabilities, merchantCurrency);
            const maxPriceRange = getMaxPriceRange(availabilities, merchantCurrency);

            return (
              <DisabledItem>
                <ListPriceRange
                  min={minPriceRange?.min}
                  max={maxPriceRange?.max}
                  currency={maxPriceRange?.currency}
                />
              </DisabledItem>
            );
          }));

          return (
            <Component />
          );
        }
      }
    ]
  }
};

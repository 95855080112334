import { FC, memo, Fragment, ReactNode } from 'react';

import { Link } from '@reach/router';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex } from 'rebass';

import { PermissionsService, StringFormat } from 'lib';

import WholesaleOrdersStore from 'stores/wholesale/wholesale-orders-store/wholesale-orders-store';

import { colors, textStyles } from 'utils/rebass-theme';

import { SupplierCard } from 'features/suppliers/components/supplier-card/supplier-card';

import Button from 'components/button';
import Icon from 'components/icon';

interface SingleOrderHeaderProps {
  wholesaleOrdersStore?: WholesaleOrdersStore;
}

const DeliveryDetails = memo<{ deliveryDate?: string }>(({
  deliveryDate
}) => {
  if (!deliveryDate) return null;

  return (
    <Box
      fontSize="14px"
      mt="8px"
    >
      Delivery on <strong>{moment(deliveryDate).format('ddd Do MMM YY')}</strong>
    </Box>
  );
});

const renderMerchantTitle = (merchantTitle: string): ReactNode => {
  if (!PermissionsService.isInternalRole()) {
    return null;
  }

  return ` - ${merchantTitle}`;
};

export const SingleOrderHeader: FC<SingleOrderHeaderProps> = inject((stores: FxStores) => ({
  wholesaleOrdersStore: stores.wholesaleOrdersStore
}))(observer(({
  wholesaleOrdersStore
}) => {
  if (!wholesaleOrdersStore!.selectedOrder) return null;

  return (
    <Fragment>
      <Box p="60px 0 15px 0">
        <Link
          to={NavPages.WholesaleOrders}
          css={{
            fontSize: '14px',
            paddingBottom: '8px',
            display: 'block'
          }}
        >
          <Flex alignItems="center">
            <Box
              pr="7px"
              color={colors.floomMidnightBlue}
              css={{
                height: '13px'
              }}
            >
              <Icon
                iconName="arrow-full-left"
                size={14}
              />
            </Box>
            <Box color={colors.floomMidnightBlue}>
              Back to all orders
            </Box>
          </Flex>
        </Link>
        <Box css={textStyles.h2}>
          Order #{StringFormat.truncate(wholesaleOrdersStore!.selectedOrder.wholesaleOrderRef || '', 7, '')}
          {renderMerchantTitle(wholesaleOrdersStore!.selectedOrder.merchant.title)}
        </Box>
        <DeliveryDetails
          deliveryDate={wholesaleOrdersStore!.selectedOrder.deliveryDetails?.[0]?.deliveryDate}
        />
      </Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mt="20px"
      >
        <SupplierCard renderSupplierName={() => wholesaleOrdersStore!.selectedOrder?.supplier?.name || 'Hoek Flowers'} />
        {!!wholesaleOrdersStore!.selectedOrder.invoice?.url && (
          <Box>
            <a
              href={wholesaleOrdersStore!.selectedOrder.invoice?.url || ''}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                appearance="secondary"
                size="xsmall"
                copy="View receipt"
                iconBefore={(
                  <Icon
                    iconName="receipt"
                    size={22}
                    pathFill={colors.ultramarine}
                  />
                )}
              />
            </a>
          </Box>
        )}
      </Flex>
    </Fragment>
  );
}));

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';

import { UrlService } from 'lib';

import FieldText from 'components/field-text';
import Icon from 'components/icon';

import * as Styles from './create-component-select-tile.styles';
import * as Types from './create-component-select-tile.types';

class CreateComponentSelectTile extends Component<Types.CreateComponentSelectTileProps, Types.CreateComponentSelectTileState> {
  state: Types.CreateComponentSelectTileState = {
    quantity: this.props.selectedComponent.quantity.toString(),
    hasChanged: false,
    edit: false
  };

  static getDerivedStateFromProps = (
    props: Types.CreateComponentSelectTileProps,
    state: Types.CreateComponentSelectTileState
  ): Partial<Types.CreateComponentSelectTileState> | null => {
    if ((parseFloat(state.quantity) !== props.selectedComponent.quantity) && !state.edit) {
      return {
        quantity: props.selectedComponent.quantity.toString()
      };
    }

    return null;
  };

  private updateQuantity = (quantity: string): void => {
    let parsedQuantity = parseFloat(quantity);

    if (parsedQuantity > 999) {
      parsedQuantity = 999;
    }

    this.setState({
      quantity: parsedQuantity.toString(),
      hasChanged:
        !isNaN(parsedQuantity)
        && parsedQuantity > 0
        && this.props.selectedComponent.quantity !== parseFloat(quantity)
    });
  };

  private persistQuantityToStore = (): void => {
    if (this.state.hasChanged) {
      this.props.updateComponent(this.props.selectedComponent.id, parseFloat(this.state.quantity));
    }

    this.setState({
      hasChanged: false,
      edit: false
    });
  };

  private toggleEdit = (): void => {
    this.setState({
      edit: true,
      hasChanged: false
    });
  };

  private editBlock = (): JSX.Element => (
    <>
      <Styles.InputField>
        <FieldText
          size="xsmall"
          type="number"
          value={this.state.quantity}
          onChange={(event): void => this.updateQuantity(event.target.value)}
          autoFocus={true}
        />
      </Styles.InputField>
      <Styles.SaveEditButton
        as="button"
        onClick={this.persistQuantityToStore}
        ml="14px"
      >
        SAVE
      </Styles.SaveEditButton>
    </>
  );

  private staticBlock = (): JSX.Element => (
    <>
      <Styles.InputField>
        <p>{this.state.quantity}</p>
      </Styles.InputField>
      <Styles.SaveEditButton
        as="button"
        disabled={this.state.edit}
        onClick={this.toggleEdit}
        ml="14px"
      >
        EDIT
      </Styles.SaveEditButton>
    </>
  );

  render(): ReactNode {
    return (
      <Styles.SelectedItem key={this.props.selectedComponent.id}>
        { !this.props.componentValues ?
          (
            <Flex
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              css={Styles.ComponentLoading}
            >
              Loading component...
            </Flex>
          )
          :
          (
            <>
              <img
                src={UrlService.setImageSrc(this.props.componentValues.mediaSource || '')}
                width={46}
                height={46}
                css={Styles.SelectedItemMedia}
              />
              <Styles.SelectedItemDetails>
                <Styles.SelectedItemTitle>
                  {this.props.componentValues.title}
                </Styles.SelectedItemTitle>
                <Styles.SelectedItemStemCountWrapper>
                  <Styles.SelectedItemStemText>STEMS:</Styles.SelectedItemStemText>
                  {!!this.props.isReadOnly ? (
                    <>
                      <p>{this.state.quantity}</p>
                    </>
                  )
                    : (
                      <>
                        {
                          this.state.edit ? this.editBlock() : this.staticBlock()
                        }
                      </>
                    )}
                </Styles.SelectedItemStemCountWrapper>
              </Styles.SelectedItemDetails>
              {
                (this.props.defaultTab && !this.props.isReadOnly) ? (
                  <Styles.SelectedItemDeleteCross
                    onClick={(): void => this.props.deselectComponent(this.props.selectedComponent.id)}
                  >
                    <Icon
                      iconName="cross-small"
                    />
                  </Styles.SelectedItemDeleteCross>
                )
                  : null
              }
            </>
          )
        }
      </Styles.SelectedItem>
    );
  }
}

export default inject('productEditStore')(observer(CreateComponentSelectTile as any));

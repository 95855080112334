import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import {
  breakpoints,
  colors,
  space,
  textStyles
} from 'utils/rebass-theme';

export const TableHeaderWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // static value required to match header columns without the final delete column
  padding: ${space[2]}px ${space[3]}px ${space[2]}px ${space[3] + space[2] + 36}px;
  border-bottom: 1px solid ${colors.paleGrey};
  border-top: 1px solid ${colors.paleGrey};
  width: 100%;

  @media only screen and (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const TableHeaderText = css`
  ${textStyles.footnote};
  color: ${colors.darkGrey};
  text-transform: uppercase;
  font-size: 10px;

  @media only screen and (max-width: ${breakpoints.large}) {
    font-size: 8px;
  }
`;

export const TitleHeading = styled.div`
  ${TableHeaderText}
  width: 30%;
  flex-grow: 2;
`;

export const ContentHeadingWrapper = styled(Flex)`
  justify-content: space-between;
  margin-left: auto;
`;

export const ContentHeading = styled.div<{ customStyles?: SerializedStyles }>`
  ${TableHeaderText}
  font-size: 10px;
  min-width: 55px;
  width: 10%;

  @media only screen and (max-width: ${breakpoints.large}) {
    font-size: 8px;
  }

  ${({ customStyles }) => customStyles};
`;

export const ProductDetailHeadings = styled(Flex)`
  width: 50%;
  justify-content: space-between;
`;

export const ProductPricingHeadings = styled(Flex)`
  width: 25%;
  justify-content: space-evenly;
`;

import { ChangeEvent } from 'react';

import {
  ListItemType,
  TradeSku,
  TradeSkuDecoration,
  TradeSkuFlower,
  TradeSkuPlant,
  TradeSkuSundry
} from 'generated-types.d';

import { ListItemColour } from 'features/lists/components/list-item-colour/list-item-colour';
import { ListPriceRange } from 'features/lists/components/list-price-range/list-price-range';
import { tradeSkuPricesBySkus, tradeSkuPricesBracket, getListCurrency } from 'features/lists/lists.helpers';

import DropdownNative from 'components/dropdown-native';

import { FormData, ListItemDetailsModalConfig, ListItemTypes } from './list-item-details-modal.types';

export type itemFieldKey = 'height' | 'maturity' | 'potSize' | 'minimumStemLength' | 'weight';

interface DropdownField {
  label: string;
  value: string | number |null;
}

export const getAvailabilityByCurrentSelections = (
  tradeSku: TradeSkuFlower | TradeSkuPlant | TradeSkuSundry | TradeSkuDecoration,
  key: itemFieldKey,
  formData: FormData
): boolean => {
  if (!formData) {
    return true;
  }

  const fields = Object.keys(formData);
  const currentValues = fields.filter(field => formData[field] && field !== key && field !== 'stemLengthUnit');

  if (currentValues.length === 0) {
    return true;
  }

  const shouldShowOption = currentValues.every(field => {
    if (!tradeSku || !tradeSku[field]) {
      return false;
    }

    switch (field) {
      case 'minimumStemLength':
        return tradeSku[field]! >= formData[field]!;
      case 'maturity':
        return tradeSku[field] === formData[field];

      default:
        return true;
    }
  });

  return shouldShowOption;
};

export const buildDropdownOptions = (
  tradeSkus: TradeSku[],
  itemType: ListItemType,
  key: 'height' | 'maturity' | 'potSize' | 'minimumStemLength' | 'weight',
  formData: FormData
): DropdownField[] => {
  if (!tradeSkus.length) {
    return [];
  }

  return tradeSkus.reduce<DropdownField[]>((acc, curr) => {
    const type = itemType.toLowerCase();
    const shouldShowBasedOnCurrentSelections = getAvailabilityByCurrentSelections(curr[type], key, formData);

    if (!shouldShowBasedOnCurrentSelections || !curr[type]?.[key] ||
      acc.find(option => option.value === curr[type][key])) {
      return acc;
    }

    return [
      ...acc,
      {
        label: curr[type][key]!,
        value: curr[type][key]!
      }
    ];
  }, [{
    label: 'Any',
    value: null
  }]) || [];
};

export const ITEM_DETAILS_MODAL_FIELD_CONFIG: ListItemDetailsModalConfig = {
  fields: [
    {
      key: 'stemLength',
      label: 'Stem length',
      supportedTypes: [
        ListItemType.Flower
      ],
      renderValue: (item: ListItemTypes, isLoading, formData, onChange) => {
        const dropdownOptions = buildDropdownOptions(item.catalogItem?.tradeSku as TradeSku[], item.type, 'minimumStemLength', formData || null);

        if (dropdownOptions.every(option => !option.value)) {
          return 'Any';
        }

        return(
          <DropdownNative
            id="stemLength"
            options={dropdownOptions}
            selected={`${formData?.minimumStemLength}`}
            optionTitleField="label"
            optionValueField="value"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange?.(e, 'minimumStemLength')}
            isDisabled={isLoading}
          />
        );
      }
    },
    {
      key: 'maturity',
      label: 'Maturity',
      supportedTypes: [
        ListItemType.Flower
      ],
      renderValue: (item: ListItemTypes, isLoading, formData, onChange) => {
        const dropdownOptions = buildDropdownOptions(item.catalogItem?.tradeSku as TradeSku[], item.type, 'maturity', formData || null);

        if (dropdownOptions.every(option => !option.value)) {
          return 'Any';
        }

        return (
          <DropdownNative
            id="maturity"
            options={dropdownOptions}
            selected={formData?.maturity!}
            optionTitleField="label"
            optionValueField="value"
            onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange?.(e, 'maturity')}
            isDisabled={isLoading}
          />
        );
      }
    },
    {
      key: 'colour',
      label: 'Colour',
      isStatic: true,
      supportedTypes: [],
      renderValue: (item: ListItemTypes) => {
        if (!item.colour) {
          return null;
        }

        return (
          <ListItemColour
            type="hit"
            status="disabled"
            colour={item.colour!}
          />
        );
      }
    },
    {
      key: 'estimatedPrice',
      label: 'Est. unit price',
      isStatic: true,
      supportedTypes: [],
      renderValue: (item: ListItemTypes)=> {
        const allPrices = tradeSkuPricesBySkus(item.catalogItem?.tradeSku || []);

        if (!allPrices?.length) {
          return null;
        }

        return  (
          <ListPriceRange
            min={tradeSkuPricesBracket(allPrices, Math.min)}
            max={tradeSkuPricesBracket(allPrices, Math.max)}
            currency={getListCurrency(item.list!)}
          />
        );
      }
    },
    {
      key: 'totalPrice',
      label: 'Total Price',
      isStatic: true,
      supportedTypes: [],
      renderValue: (item: ListItemTypes) => {
        const allPrices = tradeSkuPricesBySkus(item.catalogItem?.tradeSku || []);

        if (!allPrices?.length) {
          return null;
        }

        return (
          <ListPriceRange
            min={tradeSkuPricesBracket(allPrices, Math.min) * (item.quantity || 0)}
            max={tradeSkuPricesBracket(allPrices, Math.max) * (item.quantity || 0)}
            currency={getListCurrency(item.list!)}
          />
        );
      }
    }
  ]
};

import {
  CatalogItem,
  CatalogItemWhereUniqueInput,
  TradeSkuAvailabilityWhereInput,
  TradeSkuWhereInput,
  WholesaleFeatureType
} from 'generated-types.d';

import {
  CatalogQueries,
  GraphQL
} from 'lib';

interface FetchCatalogItemArgs {
  catalogItemId: string;
  supplierId?: string;
  tradeSkuWhere?: TradeSkuWhereInput;
  availabilityWhere?: TradeSkuAvailabilityWhereInput;
}

export class CatalogItemService {
  public static fetchItem = async ({
    catalogItemId,
    supplierId,
    availabilityWhere = {
      isLive: true,
      supplier: {
        id: supplierId
      }
    },
    tradeSkuWhere = {
      availability_some: {
        isLive: true,
        supplier: {
          wholesaleFeatures_some: {
            type: WholesaleFeatureType.LiveOrder
          }
        }
      }
    }
  }: FetchCatalogItemArgs): Promise<CatalogItem> => {
    const catalogItemWhere: CatalogItemWhereUniqueInput = {
      id: catalogItemId
    };

    try {
      const result = await GraphQL.query<{ catalogItem: CatalogItem }>(CatalogQueries.CATALOG_ITEM_QUERY, {
        catalogItemWhere,
        availabilityWhere,
        tradeSkuWhere
      });

      return result.data.catalogItem;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

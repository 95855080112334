import { FC, Fragment, useState } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  CatalogItemType,
  ListType
} from 'generated-types.d';

import {
  NavService
} from 'lib';

import ModalStore from 'stores/modal-store/modal-store';
import ToasterStore from 'stores/toaster-store/toaster-store';
import DraftWholesaleOrderStore from 'stores/wholesale/draft-wholesale-order-store/draft-wholesale-order-store';

import { colors } from 'utils/rebass-theme';

import { CatalogInlineSearchSelectItemArgs } from 'features/lists/components/catalog-inline-search/catalog-inline-search-field/catalog-inline-search-body.types';
import { CatalogInlineSearch } from 'features/lists/components/catalog-inline-search/catalog-inline-search-field/catalog-inline-search-field';
import { ListCardWrapper } from 'features/lists/lists.styles';
import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';

import Button from 'components/button';

import {
  CancelOrderButton,
  CancelOrderButtonWrapper,
  FinaliseOrderButton,
  FinaliseOrderButtonWrapper,
  FooterWrapper,
  SearchWrapper,
  SectionHeading
} from '../manage-order.styles';

import { ManageOrderBodyTotal } from './manage-order-body-total';
import { ManageOrderItems } from './manage-order-items';

interface ManageOrderBodyProps {
  draftWholesaleOrderStore?: DraftWholesaleOrderStore;
  modalStore?: ModalStore;
  toasterStore?: ToasterStore;
}

export const ManageOrderBody: FC<ManageOrderBodyProps> = inject((stores: FxStores) => ({
  draftWholesaleOrderStore: stores.draftWholesaleOrderStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(({
  draftWholesaleOrderStore: draftWholesaleOrderStore,
  modalStore,
  toasterStore
}) => {
  const [searchCategory, setSearchCategory] = useState<CatalogItemType>(CatalogItemType.Flower);

  if (!draftWholesaleOrderStore!.selectedOrder) return null;

  const order = draftWholesaleOrderStore!.selectedOrder;
  const merchantTitle = draftWholesaleOrderStore!.selectedOrder?.merchant?.title || 'Unknown merchant';

  const handleFinaliseOrder = async (): Promise<void> => {
    const paymentCopy = (): string => {
      const creditTermLength = draftWholesaleOrderStore!.selectedOrder?.merchant?.subscription?.wholesaleTermLength;

      switch (true) {
        case !!creditTermLength: {
          return `Because they have credit terms, the order will move into their ${creditTermLength} day credit window.`;
        }

        default: {
          return 'The merchant will be charged immediately once finalised.';
        }
      }
    };

    modalStore!.triggerModal<ConfirmationModalData>({
      modalType: 'confirmation',
      data: {
        title: 'Finalise draft wholesale order?',
        confirmButtonCopy: 'Yes, finalise',
        cancelButtonCopy: 'No, go back',
        errorCopy: '',
        copy: (
          <Fragment>
            By confirming, the wholesale order will be finalised, and <strong>{merchantTitle}</strong> will be able to see the order on their dashboard. {paymentCopy()}
          </Fragment>
        ),
        confirmButtonAction: async (): Promise<void> => new Promise(async (resolve, reject): Promise<void> => {
          try {
            draftWholesaleOrderStore!.toggleIsFinalisingOrder();
            await draftWholesaleOrderStore!.finaliseOrder(order);

            toasterStore?.popSuccessToast('The draft wholesale order', 'finalise');
            NavService.wholesaleOrderDetail(order.id);

            return resolve();
          } catch (error) {
            toasterStore?.popErrorToast('this draft wholesale order', 'finalise');

            reject();
          } finally {
            draftWholesaleOrderStore!.toggleIsFinalisingOrder();
          }
        })
      }
    });
  };

  const handleCancelOrder = async (): Promise<void> => {
    modalStore!.triggerModal<ConfirmationModalData>({
      modalType: 'confirmation',
      data: {
        title: 'Cancel draft wholesale order?',
        confirmButtonCopy: 'Yes, cancel',
        cancelButtonCopy: 'No, go back',
        errorCopy: '',
        copy: (
          <Fragment>
            By confirming, the order will be cancelled, and will no longer be able to be finalised. This is irreversible.
          </Fragment>
        ),
        confirmButtonAction: async (): Promise<any> => new Promise(async (resolve, reject): Promise<void> => {
          try {
            draftWholesaleOrderStore!.toggleIsCancellingOrder();
            await draftWholesaleOrderStore!.cancelOrder(order);
            toasterStore?.popSuccessToast('The draft wholesale order', 'cancel');

            resolve('');

            NavService.wholesaleOrders();
          } catch (error) {
            toasterStore?.popErrorToast('this draft wholesale order', 'cancel');

            reject();
          } finally {
            draftWholesaleOrderStore!.toggleIsCancellingOrder();
          }
        })
      }
    });
  };

  const handleAddItem = async ({ item }: CatalogInlineSearchSelectItemArgs): Promise<void> => {
    try {
      if (!draftWholesaleOrderStore!.selectedOrder || (!item.objectID && !item.title)) {
        toasterStore?.popErrorToast('this item to the order', 'add');

        throw Error('Cannot add item to order, catalog item or custom item not found');
      }

      await draftWholesaleOrderStore!.updateOrder({
        where: {
          id: draftWholesaleOrderStore!.selectedOrder!.id
        },
        data: {
          items: {
            create: [
              {
                title: item.title || 'Untitled',
                ...(item.objectID ? {
                  catalogItem: {
                    id: item.objectID
                  }
                } : {})
              }
            ]
          }
        }
      });

      toasterStore?.popSuccessToast('The item', 'add');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <Fragment>
      <SectionHeading>
        {draftWholesaleOrderStore!.selectedOrder.items?.length} items ordered
      </SectionHeading>
      <ListCardWrapper>
        <SearchWrapper>
          <CatalogInlineSearch
            onCategorySelect={setSearchCategory}
            onSelectItem={handleAddItem}
            listType={ListType.Advanced}
            selectedCategory={searchCategory}
          />
        </SearchWrapper>
        <ManageOrderItems />
        <ManageOrderBodyTotal />
        <Box
          mt={20}
          bg={colors.paleGrey}
          p={[20]}
          css={css`
            width: 100%
          `}
        >
          <FooterWrapper>
            <CancelOrderButtonWrapper>
              <CancelOrderButton onClick={handleCancelOrder}>
                <Button
                  size="large"
                  appearance="danger"
                  copy="Cancel order"
                  isLoading={draftWholesaleOrderStore?.isCancelling}
                  isDisabled={draftWholesaleOrderStore?.isCancelling}
                />
              </CancelOrderButton>
            </CancelOrderButtonWrapper>
            <FinaliseOrderButtonWrapper>
              <FinaliseOrderButton onClick={handleFinaliseOrder}>
                <Button
                  size="large"
                  appearance="primary"
                  copy="Finalise order"
                  isLoading={draftWholesaleOrderStore?.isFinalising}
                  isDisabled={draftWholesaleOrderStore?.isFinalising}
                />
              </FinaliseOrderButton>
            </FinaliseOrderButtonWrapper>
          </FooterWrapper>
        </Box>
      </ListCardWrapper>
    </Fragment>
  );
}));

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles, colors } from 'utils/rebass-theme';

export const VariationImageStyle = css`
  width: 204px;
  height: 204px;
  margin: 15px 22px 0 0;
`;

export const ImagesWrapper = styled.div`
  max-width: 480px;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
`;

export const ImagesHolder = styled.div<any>`
  display: inline-block;
  white-space: nowrap;
  margin-left: ${(props: any): number => props.imageOffset * 226}px;
  transition: margin 350ms;
  min-width: 100%;
  text-align: left;
`;

export const GalleryTopWrapper = styled(Flex)`
  justify-content: space-between;
  min-height: 26px;
`;

export const GalleryProductTitleWrapper = styled(Flex)`
  justify-content: space-between;
  > h3 {
    ${textStyles.title};
    text-align: left;
  }
  > p {
    ${textStyles.title};
    color: ${colors.middleGrey};
    margin-right: 15px;
  }
`;

export const GalleryButtonsWrapper = styled(Flex)<any>`
  justify-content: flex-end;
  cursor: pointer;
  user-select: none;
  display: ${(props: any): string => props.display ? 'flex' : 'none'};
`;

import { Component, Fragment, ReactNode } from 'react';

import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';

import {
  NavService,
  Analytics
} from 'lib';

import OrderCapBanner from 'features/order-cap-banner';
import OrderFormModal from 'features/orders/components/order-form-modal';
import OrderList from 'features/orders/components/order-list';
import OrderReceiptModal from 'features/orders/components/order-receipt-modal';
import { ORDER_LIST_PAGE_LAYOUTS } from 'features/orders/graphql/helpers/order-group.config';
import { OrderPageRoute } from 'features/orders/orders.types';
import OrderListHeading from 'features/orders/pages/orders-list/list-heading';
import { OrderListService, OrderAnalytics } from 'features/orders/services';

import AddOnsModal from '../../components/add-ons-modal/add-ons-modal';
import OrderDetailsModal from '../../components/order-details-modal/order-details-modal';

import * as Types from './orders-list.types';

class OrdersList extends Component<Types.OrdersListProps, Types.OrdersListState> {
  state = {
    listOption: this.props.listOption
  };

  UNSAFE_componentWillMount = (): void => {
    const listOption = this.props.listOption;

    if (listOption) {
      OrderListService.setListLayout(listOption);
    }
  };

  componentDidMount = (): void => {
    this.navigateToDetail();

    Analytics.page(Analytics.FxPageEvent.OrdersList, {
      grouping: ORDER_LIST_PAGE_LAYOUTS[this.props.ordersStore!.listLayout].heading || ''
    });

    OrderAnalytics.onViewList(this.getListOption(), Analytics.OrderListEntry.Overview);
  };

  componentDidUpdate = (): void => {
    if (this.props.listOption !== this.state.listOption && !this.props.location.state.modal) {
      this.setState({ listOption: this.props.listOption });
      OrderListService.setListLayout(this.getListOption());

      Analytics.page(Analytics.FxPageEvent.OrdersList, {
        grouping: ORDER_LIST_PAGE_LAYOUTS[this.props.ordersStore!.listLayout].heading || ''
      });
    }
  };

  private getOrderIdFromRoute(): string | null {
    const path = this.props.location.pathname;

    if (!NavService.isOrderDetail(path)) return null;
    const routeArray = path.split('/');
    const detailPosition = routeArray.findIndex(uriSegment => uriSegment === 'detail');
    const orderId = routeArray[detailPosition + 1];

    if (!orderId) return null;

    return orderId;
  }

  private getListOption = (): OrderPageRoute => this.props.listOption || 'today';

  private navigateToDetail = (): void => {
    if (NavService.isOrderDetail(this.props.location.pathname)) {
      const orderId = this.getOrderIdFromRoute() as string;
      const closeTo = `${NavPages.OrdersList}${this.getListOption()}`;
      NavService.orderDetail(orderId, closeTo);
    }
  };

  render(): ReactNode {
    const config = ORDER_LIST_PAGE_LAYOUTS[this.props.ordersStore!.listLayout];

    return (
      <Fragment>
        <OrderListHeading config={config} />
        <OrderCapBanner />
        <OrderList
          listLayout={this.props.ordersStore!.listLayout}
          location={this.props.location}
        />
        <OrderReceiptModal
          isOpen={this.props.ordersStore!.isViewingReceipt}
        />
        <OrderFormModal />
        <OrderDetailsModal />
        <AddOnsModal />
      </Fragment>
    );
  }
}

export default inject('ordersStore')(observer(OrdersList));

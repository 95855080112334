import { FC, Fragment } from 'react';

import { CatalogItemHit } from 'global.types';
import { Box } from 'rebass';

import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import {
  ListCellTitle,
  ListCellSubtitle,
  ListItemTitle,
  ListItemSubTitle
} from 'features/lists/lists.styles';

import { ListGenus } from '../../single-list/single-list.styles';

export const InlineSearchSimpleTitle: FC<{hit: CatalogItemHit}> = ({ hit }) => {
  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();

  return wsFriendlyNames.isActive ? (
    <Fragment>
      <ListItemTitle>
        {hit?.primaryName || hit?.title}
      </ListItemTitle>
      {!!hit?.secondaryName && (
        <ListItemSubTitle>
          {hit?.secondaryName}
        </ListItemSubTitle>
      )}
    </Fragment>
  ) : (
    <Box>
      <ListCellTitle>
        {hit?.title}
      </ListCellTitle>
      {!!hit.genus && (
        <ListGenus>
          <ListCellSubtitle>
            {hit.genus}
          </ListCellSubtitle>
        </ListGenus>
      )}
    </Box>
  );
};

export const InlineSearchAdvancedTitle: FC<{hit: CatalogItemHit}> = ({ hit }) => {
  const {
    flags: {
      wsFriendlyNames
    }
  } = useFeatureFlags();

  return wsFriendlyNames.isActive ? (
    <Fragment>
      <ListItemTitle>
        {hit?.primaryName || hit?.title}
      </ListItemTitle>
      {!!hit?.secondaryName && (
        <ListItemSubTitle>
          {hit?.secondaryName}
        </ListItemSubTitle>
      )}
    </Fragment>
  ) : (
    <Box>
      <ListCellTitle>
        {hit?.title}
      </ListCellTitle>
      {!!hit.genus && (
        <ListGenus>
          <ListCellSubtitle>
            {hit.genus}
          </ListCellSubtitle>
        </ListGenus>
      )}
    </Box>
  );
};

import { FC, useState } from 'react';

import { css } from '@emotion/react';

import { fontSizes } from 'utils/rebass-theme';

import { CheckoutTitleWrapper } from 'features/lists/components/checkout-list/checkout-list.styles';
import { ConfirmDeleteTooltip } from 'features/lists/components/confirm-delete-tooltip/confirm-delete-tooltip';
import { BinIconWrapperStyles } from 'features/lists/components/lists-list/lists-list-item/lists-list-item.styles';
import { Columns, ItemTitle, ListCellTitle, MetaColumn, Thumbnail } from 'features/lists/lists.styles';

import Icon from 'components/icon';
import IconButton from 'components/icon-button';

import { AdditionalItemQuantitySelector } from '../additional-items-quantity-selector';

import { AdditionalItemDetails, AdditionalItemListItemWrapper } from './additional-list-item.styles';
import { AdditionalListItemProps } from './additional-list-item.types';

export const AdditionalListItem: FC<AdditionalListItemProps> = ({
  item,
  onDeleteClick
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClickDelete = (event: React.MouseEvent<Element> | React.TouchEvent<Element> | undefined): void => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseDeleteMenu = (): void => {
    setAnchorEl(null);
  };

  return (
    <AdditionalItemListItemWrapper>
      <Thumbnail>
        <Icon iconName="flower-squiggle" />
      </Thumbnail>
      <Columns>
        <AdditionalItemDetails>
          <ItemTitle>
            <CheckoutTitleWrapper>
              <ListCellTitle fontSize={fontSizes.small}>
                {item.title}
              </ListCellTitle>
            </CheckoutTitleWrapper>
          </ItemTitle>
          <MetaColumn
            key={`${item.itemId}-quantity`}
            customStyles={css`min-width: 140px;`}
            isMetadata={false}
          >
            <AdditionalItemQuantitySelector item={item} />
          </MetaColumn>

          <MetaColumn
            key={`${item.itemId}-delete`}
            customStyles={css`
              min-width: 32px;
              width: 32px;
              padding-left: 10px;
            `}
            isMetadata={false}
          >
            <IconButton
              iconName="bin"
              wrapperStyles={BinIconWrapperStyles}
              onClick={handleClickDelete}
            />
            <ConfirmDeleteTooltip
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseDeleteMenu}
              onDeleteItem={async () => {
                onDeleteClick?.(item.itemId);
              }}
            />
          </MetaColumn>
        </AdditionalItemDetails>
      </Columns>
    </AdditionalItemListItemWrapper>
  );
};

import React from 'react';

import Button from 'components/button';
import FormFieldWrapper from 'components/create-labeled-component';
import FieldText from 'components/field-text';

import { PasswordWrapper } from './change-password.styles';
import {
  CurrentPasswordProps
} from './change-password.types';

export const CurrentPassword = (props: CurrentPasswordProps): JSX.Element => {
  return (
    <PasswordWrapper>
      <FormFieldWrapper title="Current password">
        <FieldText
          onChange={(e): void => props.onChangeCurrentPassword('currentPassword', e.target.value)}
          value={props.currentPassword}
          size="normal"
          error={props.error}
          errorMessage="Current password is incorrect"
          type="password"
          autoFocus={true}
          dirty={true}
        />
      </FormFieldWrapper>
      <div onClick={props.onButtonClick}>
        <Button
          size="normal"
          appearance="primary"
          copy="Next"
          isDisabled={!props.currentPassword}
        />
      </div>
    </PasswordWrapper>
  );
};

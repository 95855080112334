import { ApolloQueryResult } from '@apollo/client';

import {
  WholesaleTradeLineItem,
  MerchantWhereUniqueInput,
  MerchantCreditTermsUpdateInput,
  Merchant,
  ConfirmOrderCreationOutput,
  MutationCancelWholesaleOrderLineItemArgs,
  Mutation,
  MutationCreateWholesaleOrderArgs
} from 'generated-types.d';

import {
  GraphQL,
  WholesaleQueries,
  WholesaleMutations
} from 'lib';

export default class WholesaleService {
  public static hasLowStock = (item: WholesaleTradeLineItem): boolean => {
    return (item.maximum_price_quantity! <= 2);
  };

  public static updateCreditTerms = async (
    where: MerchantWhereUniqueInput,
    data: MerchantCreditTermsUpdateInput
  ): Promise<Pick<Merchant, 'id'>> => {
    try {
      const result: ApolloQueryResult<{ updateWholesaleCreditTerms: Pick<Merchant, 'id'> }> = await GraphQL.query(
        WholesaleMutations.UPDATE_MERCHANT_CREDIT_TERMS,
        { where, data }
      );

      return result.data.updateWholesaleCreditTerms;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static placeOrder = async ({ args }: {
    args: MutationCreateWholesaleOrderArgs;
  }): Promise<ConfirmOrderCreationOutput> => {
    try {
      const result = await GraphQL.query<Pick<Mutation, 'createWholesaleOrder'>>(WholesaleMutations.CREATE_WHOLESALE_ORDER, args);

      return result.data.createWholesaleOrder;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static cancelOrderItem = async ({ args }: {
    args: MutationCancelWholesaleOrderLineItemArgs;
  }): Promise<ConfirmOrderCreationOutput> => {
    try {
      const result = await GraphQL.mutate<Pick<Mutation, 'cancelWholesaleOrderLineItem'>>(WholesaleMutations.CANCEL_WHOLESALE_ORDER_ITEM, args);

      return result.data!.cancelWholesaleOrderLineItem;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  public static currentUnpaidBalance = async (where: MerchantWhereUniqueInput): Promise<number> => {
    try {
      const result: ApolloQueryResult<{ wholesaleUnpaidBalance: number }> = await GraphQL
        .query(WholesaleQueries.WHOLESALE_UNPAID_BALANCE, { where });

      return result.data.wholesaleUnpaidBalance;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

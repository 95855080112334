import styled from '@emotion/styled';
import { Text, Box } from 'rebass';

import { fontStacks } from 'utils/rebass-theme';

export const Container: any = styled(Box)`
  max-width: 430px;
  margin-top: 10px;
  padding: 20px;
  margin: 0 auto;
`;

export const Metadata: any = styled(Text)`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 2px;
  font-family: ${fontStacks.secondary};
`;

import React, { FC, Fragment, ReactNode, useContext } from 'react';

import { CatalogItemHit } from 'global.types';
import { Hit } from 'react-instantsearch-core';

import { ListType } from 'generated-types.d';

import Icon from 'components/icon';

import { ListHeadings } from '../../list-headings/list-headings';
import { CatalogInlineSearchContext } from '../catalog-inline-search-field/catalog-inline-search-field';
import { CATALOG_INLINE_SEARCH_FIELD_CONFIG } from '../catalog-inline-search.config';
import { InlineSearchSelections } from '../catalog-inline-search.reducers';
import { InlineSearchResult } from '../inline-search-result/inline-search-result';

import {
  SearchResults,
  Wrapper,
  NoResult,
  AddCustomItem,
  HeaderWrapper
} from './inline-search-results.styles';
import {
  InlineSearchResultsProps
} from './inline-search-results.types';
import { InlineSearchTabs } from './inline-search-tabs';

export const InlineSearchResults: FC<InlineSearchResultsProps> = ({
  hits,
  cursor,
  isVisible,
  inputPosition,
  inputText,
  loadingItems,
  handleAdd,
  handleAddCustomItem,
  handleCursorSelect
}) => {
  const {
    listType,
    onCategorySelect,
    selectedCategory
  } = useContext(CatalogInlineSearchContext);

  if (!isVisible) return null;

  const handleMouseEnter = (e: React.MouseEvent, index: number): void => {
    e.preventDefault();
    handleCursorSelect(index + 1);
  };

  const onMouseDown = (
    e: React.MouseEvent,
    hit: Hit<CatalogItemHit>,
    selections: InlineSearchSelections
  ): void => {
    e.stopPropagation();
    e.preventDefault();
    handleAdd(hit, selections);
  };

  const renderResults = (): ReactNode => {
    if (!!hits?.length) {
      return hits.map((hit, index) => (
        <InlineSearchResult
          key={hit.objectID}
          onMouseEnter={(e): void => handleMouseEnter(e, index)}
          onMouseDown={(e, selections): void => onMouseDown(e, hit, selections)}
          hit={hit}
          isLoading={loadingItems.includes(hit.objectID)}
          isFocussed={index + 1 === cursor}
          listType={listType as ListType}
        />
      ));
    }

    return (
      <Fragment>
        <NoResult>No matching results</NoResult>
        <AddCustomItem
          as="button"
          onClick={handleAddCustomItem}
        >
          <Icon iconName="plus-small" />
          <span>Add custom item: &quot;{inputText}&quot;</span>
        </AddCustomItem>
      </Fragment>
    );
  };

  return (
    <Wrapper id="inline-search-results-wrapper">
      <InlineSearchTabs
        attribute="type"
        onSelect={onCategorySelect}
        defaultRefinement={selectedCategory}
      />
      {!!hits?.length && listType === ListType.Advanced && (
        <HeaderWrapper>
          <ListHeadings
            listType={listType || 'Default'}
            categoryScope={selectedCategory}
            config={CATALOG_INLINE_SEARCH_FIELD_CONFIG}
          />
        </HeaderWrapper>
      )}
      <SearchResults
        height={inputPosition && !!window ? window.innerHeight - inputPosition : null}
      >
        {renderResults()}
      </SearchResults>
    </Wrapper>
  );
};

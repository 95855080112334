import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box, Flex } from 'rebass';

import { Card } from 'utils/css-mixins/css-mixins';
import { colors, textStyles, shadows } from 'utils/rebass-theme';

export const SelectedWrapper = styled(Flex)`
  flex-direction: column;
`;

export const CardContainer = styled(Box)`
  ${Card};
`;

export const ContentWrapper: any = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }): any => {
    if (isLoading) {
      return `
        ${Card}
        padding: 25px 0 40px;
      `;
    }
  }};
`;

export const EmptyListButtonWrapper: any = styled(Flex)<{ isLoading: boolean; hasItems: boolean }>`
  width: 100%;
  cursor: ${({ isLoading }): any => isLoading ? 'not-allowed' : 'pointer'};
  justify-content: center;
  align-items: center;
  transition: box-shadow .2s ease-in-out,
              background .2s ease-in-out,
              border-color .2s ease-in-out;

  ${({ isLoading, hasItems }): any => {
    if (isLoading || hasItems) {
      return `
        height: 70px;
        border: 2px dashed ${colors.middleGrey};
        border-radius: 3px;
      `;
    }
  }};

  ${({ hasItems }): any => {
    if (hasItems) {
      return `
        &:hover {
          border-color: ${colors.shade60};
          background-color: ${hex2rgba(colors.uiFills, .4)};
        }

        &:active {
          box-shadow: ${shadows.smallSharp};
        }
      `;
    }
  }};
`;

export const EmptyListButton = styled(Flex)`
  ${textStyles.subhead};
  cursor: pointer;
  align-items: center;
  user-select: none;
  justify-content: center;

  > p {
    margin-bottom: 3px;
    margin-left: 8px;
  }
`;

export const AddItemButton = styled.button`
  display: inline-block;
  align-self: flex-end;
  margin-top: 20px;
`;

import { gql } from '@apollo/client';

import { GraphQLHelpers } from 'lib';

export const ADD_ONS_PRODUCTS_QUERY = (merchantId: any): any => gql`
  query AddonsProductsQuery(
    $count: Int
    $skip: Int
    ${GraphQLHelpers.addVariable(merchantId, 'merchantId', 'ID')}
  ) {
    allProducts: productsConnection(
      first: $count
      skip: $skip
      where: {
        ${GraphQLHelpers.addMerchantWhereFilter(merchantId)}
      }
    ) {
      aggregate {
        count
      }
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          title
          categories {
            title
          }
          channels {
            channel
          }
          variations {
            media {
              src
            }
          }
        }
      }
    }
  }
`;

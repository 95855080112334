import { FC } from 'react';

import { Box } from 'rebass';

import { getMedia, listItemMediaImage } from 'features/lists/lists.helpers';
import { Columns, ItemDetails, ItemTitle, ListItemWrapper, MetaColumn, Thumbnail } from 'features/lists/lists.styles';
import { ListFieldConfig } from 'features/lists/lists.types';

import Icon from 'components/icon';

import { CHECKOUT_LIST_FIELD_CONFIG } from '../checkout-list';

import { CheckoutListItemProps } from './checkout-list-item.types';

export const CheckoutListItem: FC<CheckoutListItemProps> = ({
  listItem
}) => {
  const fieldConfig = CHECKOUT_LIST_FIELD_CONFIG['Promoted'];

  if (!fieldConfig) return null;

  const shouldDisplayColumn = (field: ListFieldConfig): boolean => {
    return !field.supportedTypes?.length || (!!listItem?.type && !!field.supportedTypes.includes(listItem.type));
  };

  return (
    <ListItemWrapper>
      <Thumbnail
        imageUrl={listItemMediaImage(getMedia(listItem), true)}
      >
        {listItem.type === 'Flower' && (
          <Icon iconName="flower-squiggle" />
        )}
      </Thumbnail>
      <Columns>
        <ItemDetails>
          <ItemTitle>
            <Box flex="1">
              {fieldConfig.titleField.renderValue?.(listItem)}
            </Box>
          </ItemTitle>
          {fieldConfig.fields.map((field, fieldIndex) => {
            if (!shouldDisplayColumn(field)) return null;

            return (
              <MetaColumn
                key={`${field.key}-${fieldIndex}`}
                customStyles={field.customStyles}
                isMetadata={false}
              >
                {field.renderValue?.(listItem)}
              </MetaColumn>
            );
          })}
        </ItemDetails>
      </Columns>
    </ListItemWrapper>
  );
};

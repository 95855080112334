import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef
} from 'react';

import { Redirect, redirectTo, Router } from '@reach/router';
import { inject, observer } from 'mobx-react';
import Helmet from 'react-helmet';
import { InjectedMobxStores } from 'stores';

import {
  PermissionsService
} from 'lib';

import { useHasWholesaleShopAccess } from 'features/wholesale/hooks/hasWholesaleAccess';
import { WholesaleOrderManage } from 'features/wholesale/pages/manage-order/manage-order';
import WholesaleOrders from 'features/wholesale/pages/orders';
import  PromotedListsCheckout  from 'features/wholesale/pages/promoted-lists-checkout/promoted-lists-checkout';
import { SingleWholesaleOrder } from 'features/wholesale/pages/single-order/single-order';
import { SingleWholesalePreOrder } from 'features/wholesale/pages/single-pre-order/single-pre-order';
import WholesaleServices from 'features/wholesale/services';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

interface WholesaleContext {
  hasWholesaleShopAccess: boolean;
}

export const WholesaleContext = createContext<WholesaleContext>({
  hasWholesaleShopAccess: false
});

const Wholesale: FC<Pick<InjectedMobxStores,
  | 'merchantStore'
  | 'wholesaleShopStore'
>> = inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  wholesaleShopStore: stores.wholesaleShopStore
}))(observer(({
  merchantStore,
  wholesaleShopStore
}) => {
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      WholesaleServices.WholesaleOrdersAPIService.fetchCurrentOpenOrders();

      hasFetched.current = true;
    }
  }, []);

  const {
    hasWholesaleShopAccess
  } = useHasWholesaleShopAccess({
    merchant: merchantStore!.merchant,
    wholesaleDeliveryConfig: merchantStore!.wholesaleDeliveryConfig,
    merchantWholesaleSuppliers: wholesaleShopStore!.merchantWholesaleSuppliers
  });

  const hasAccessToWholesale = useMemo((): boolean => {
    return PermissionsService.isInternalRole()
      || !!merchantStore!.merchant?.wholesaleActive;
  }, [merchantStore!.merchant]);

  const renderRoute = (Component: ReactNode): ReactNode | void => {
    if (!hasAccessToWholesale) {
      return redirectTo('/overview');
    }

    return Component;
  };

  return(
    <AppLayout>
      <WholesaleContext.Provider
        value={{
          hasWholesaleShopAccess
        }}
      >
        <Helmet>
          <title>Wholesale</title>
        </Helmet>
        <Router>
          <Redirect
            from="/wholesale"
            to="/wholesale/orders"
            noThrow
          />
          <AuthedRoute
            path="wholesale/orders"
            component={renderRoute(WholesaleOrders)}
          />
          <AuthedRoute
            path="wholesale/orders/:orderId"
            component={renderRoute(SingleWholesaleOrder)}
          />
          <AuthedRoute
            path="wholesale/orders/:orderId/manage"
            component={renderRoute(WholesaleOrderManage)}
          />
          <AuthedRoute
            path="wholesale/pre-order/:preOrderId"
            component={renderRoute(SingleWholesalePreOrder)}
          />
          <AuthedRoute
            path="wholesale/special-offers"
            component={renderRoute(PromotedListsCheckout)}
          />
        </Router>
      </WholesaleContext.Provider>
    </AppLayout>
  );
}));

export default Wholesale;

import moment from 'moment';

import {
  WholesaleOrder,
  WholesaleOrderStates,
  WholesalePaymentStatus
} from 'generated-types.d';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

export interface PaymentConfig {
  value: string;
  bg?: ColourOption;
}

export const getPaymentStatusConfig = (order?: WholesaleOrder | null): PaymentConfig | null => {
  if (!order) return null;

  switch (true) {
    case order.status === WholesaleOrderStates.Completed && order.paymentStatus === WholesalePaymentStatus.Paid: {
      return {
        value: `Paid ${moment(order.paidAt).format('Do MMM')}`,
        bg: ColourOption.lightGreen
      };
    }

    case order.status === WholesaleOrderStates.Completed && order.paymentStatus === WholesalePaymentStatus.Open: {
      return {
        value: 'Payment due',
        bg: ColourOption.lightGrey
      };
    }

    case order.status === WholesaleOrderStates.Open: {
      return {
        value: 'Live order',
        bg: ColourOption.lightBlue
      };
    }

    case (
      order.status === WholesaleOrderStates.Completed &&
      order.paymentStatus === WholesalePaymentStatus.Draft &&
      typeof order.merchant?.subscription?.wholesaleTermLength === 'number' &&
      !!order.processedAt
    ): {
      const daysUntilCollection = moment(order.processedAt)
        .add(order.merchant?.subscription?.wholesaleTermLength, 'days')
        .diff(moment(), 'days');

      const copy = ((): string => {
        switch (daysUntilCollection) {
          case 0:
            return 'today';

          case 1:
            return 'tomorrow';

          default:
            return `in ${daysUntilCollection} days`;
        }
      })();

      return {
        value: `Collection ${copy}`,
        bg: ColourOption.lightGrey
      };
    }

    case (
      [WholesaleOrderStates.Completed, WholesaleOrderStates.Failed].includes(order.status) &&
      order.paymentStatus === WholesalePaymentStatus.Void
    ): {
      return {
        value: 'Void',
        bg: ColourOption.lightPink
      };
    }

    case (
      [WholesaleOrderStates.Completed, WholesaleOrderStates.Failed].includes(order.status) &&
      order.paymentStatus === WholesalePaymentStatus.Uncollectible
    ): {
      return {
        value: 'Failed',
        bg: ColourOption.lightPink
      };
    }

    case order.status === WholesaleOrderStates.Processed: {
      return {
        value: 'Processing',
        bg: ColourOption.lightGrey
      };
    }

    default:
      return null;
  }
};

import { FC } from 'react';

import { css } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass';

import {
  H2,
  H4
} from 'features/marketing/marketing.styles';

import {
  Wrapper,
  Points
} from './key-points.styles';
import {
  KeyPointsProps
} from './key-points.types';

export const KeyPoints: FC<KeyPointsProps> = ({ data }) => {
  const entry = data.keyPointsEntry;

  if (!entry || !entry?.keyPoints?.length) return null;

  return (
    <Wrapper>
      { !!entry.title?.trim() && (
        <H2>
          {entry.title}
        </H2>
      )}
      <Points>
        {entry.keyPoints.map(point => {
          if (!point) return null;

          return (
            <Box key={point.originalId}>
              <Box>
                <GatsbyImage
                  alt={point.image!.alt!}
                  image={point.image!.gatsbyImageData}
                />
              </Box>
              <H4
                css={css`
                  margin: 15px 0 0 0;
                `}
              >
                {point.heading}
              </H4>
            </Box>
          );
        })}
      </Points>
    </Wrapper>
  );
};

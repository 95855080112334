import { FC, FormEvent, useState, ChangeEvent, useMemo, useCallback } from 'react';

import { inject, observer } from 'mobx-react';
import * as Yup from 'yup';

import { ValidationService } from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';
import { SupplierIntegrationAuth } from 'stores/wholesale/wholesale-settings-store/wholesale-settings-store';

import {
  space
} from 'utils/rebass-theme';

import { SUPPLIER_INTEGRATION_MAP } from 'features/wholesale/wholesale.constants';
import { SupplierIntegrationFieldConfig } from 'features/wholesale/wholesale.types';

import FormBuilder from 'components/form-builder';
import { FormBuilderConfig, FormBuilderField } from 'components/form-builder/form-builder.types';
import GenericModal from 'components/generic-modal';

import { FormWrapper } from './supplier-integration-modal.styles';
import { SupplierIntegrationModalProps } from './supplier-integration-modal.types';

const SupplierIntegrationModal: FC<SupplierIntegrationModalProps> = ({
  closeModal,
  isOpen,
  toasterStore,
  data: {
    supplier,
    onSave
  }
}) => {
  const hasExistingIntegration = !!supplier?.integration;
  const supplierIntegrationConfig = SUPPLIER_INTEGRATION_MAP[supplier.integrationType];

  const [supplierIntegration, setSupplierIntegration] = useState(() => {
    return supplierIntegrationConfig.fields.reduce((formState, formField) => {
      return {
        ...formState,
        [formField.field]: supplier?.integration?.authentication?.record?.[formField.field] || undefined
      };
    }, {});
  });
  const [formValueErrors, setFormValueErrors] = useState<Yup.TestMessageParams[] | undefined>();

  const createValidator = useCallback((formField: SupplierIntegrationFieldConfig): Yup.Schema<string | boolean> => {
    switch(formField.type) {
      case 'textInput':
        return createStringvalidator(formField);
      case 'toggle':
        return Yup.boolean();
      case 'singleCheckbox':
        return Yup.boolean();
      case 'dropdown':
        return Yup.string().required(`${formField.name} is required`);

      default:
        return Yup.string().required(`${formField.name} is required`);
    }
  }, []);

  const createStringvalidator = (formField: SupplierIntegrationFieldConfig): Yup.Schema<string> => {
    let baseSchema = Yup.string();

    formField.validators.forEach(validator => {
      switch(validator.name) {
        case 'required':
          const requredValidator = Yup.string().required(validator.message);
          baseSchema = baseSchema.concat(requredValidator);

          break;
        case 'alphanumeric':
          const alphanumericValidator = Yup.string().matches(/^[\w]+$/, { excludeEmptyString: true, message: validator.message });
          baseSchema = baseSchema.concat(alphanumericValidator);

          break;

        default:
          throw new Error('validator not implemented yet');
      }
    });

    return baseSchema;
  };

  const createValidationSchema = (): Yup.ObjectSchemaDefinition<SupplierIntegrationAuth> => {
    return supplierIntegrationConfig.fields.reduce((validationSchema, formField) => {
      return {
        ...validationSchema,
        [formField.field]: createValidator(formField)
      };
    }, {}) as Yup.ObjectSchemaDefinition<SupplierIntegrationAuth>;
  };

  const validation = useMemo(() => {
    return Yup.object().shape(createValidationSchema());
  }, [supplier]);

  const handleInputsChange = (event: ChangeEvent<HTMLInputElement>, field: string): void => {
    setSupplierIntegration(prevState => {
      return {
        ...prevState,
        [field]: event.target.value
      };
    });
  };

  const handleSupplierFormSave = async (evt: FormEvent): Promise<void> => {
    evt.preventDefault();

    try {
      const validFormInput = await ValidationService.validateAll(validation, supplierIntegration as SupplierIntegrationAuth);
      setFormValueErrors(undefined);

      const isSame = hasExistingIntegration && Object.keys(validFormInput).every(key => {
        const value = validFormInput[key];
        const initialValue = supplier?.integration?.authentication?.record[key];

        return value === initialValue;
      });

      if (isSame) {
        closeModal();

        return Promise.resolve();
      }

      await onSave?.(validFormInput, supplier);
      closeModal();
    } catch (error: any) {
      console.error(error);

      if (error instanceof Yup.ValidationError) {
        const validationErrors = error.inner.map(inner => ({ ...inner.params as Yup.TestMessageParams, label: inner.message }));
        setFormValueErrors(validationErrors);
      } else {
        toasterStore?.popToast('There was an error while saving supplier integration', ToastType.Error);
        closeModal();
      }
    }
  };

  const findError = (key: string): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(formValueErrors, key);
  };

  const SUPPLIER_INTEGRATION_FORM_CONFIG = (): FormBuilderConfig => {
    return {
      sections: [
        {
          width: '100',
          paddingBottom: `${space[3]}px`,
          fields: supplierIntegrationConfig.fields.map((fieldConfig): FormBuilderField[] => {
            switch(fieldConfig.type) {
              case 'textInput':
                return [
                  {
                    key: `label ${fieldConfig.name}`,
                    fieldType: 'labelSmall',
                    copy: fieldConfig.name
                  },
                  {
                    key: `input ${fieldConfig.name}`,
                    fieldType: fieldConfig.type,
                    value: supplierIntegration[fieldConfig.field],
                    validationError: findError(fieldConfig.field),
                    onChange: (event: ChangeEvent<HTMLInputElement>): void => handleInputsChange(event, fieldConfig.field),
                    id: `input ${fieldConfig.name}`
                  }
                ];

              default:
                throw new Error('Unsupported input type');
            }
          }).reduce((fields, field) => fields.concat(field), [])
        }
      ]
    };
  };

  return (
    <GenericModal
      title={`${hasExistingIntegration ? 'Edit' : 'Integrate'} ${supplier?.name}`}
      closeModal={closeModal}
      modalOpen={isOpen}
      shouldHideHeader={false}
      confirmButtonText={`${hasExistingIntegration ? 'Edit' : 'Save'} integration`}
      confirmButtonAction={handleSupplierFormSave}
      hasOverflow={false}
      width={420}
    >
      <FormWrapper
        as="form"
        onSubmit={handleSupplierFormSave}
      >
        <FormBuilder
          config={SUPPLIER_INTEGRATION_FORM_CONFIG()}
        />
      </FormWrapper>
    </GenericModal>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore
}))(observer(SupplierIntegrationModal));

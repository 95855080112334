import styled from '@emotion/styled';

import { borderRadius, breakpoints, colors, space, textStyles } from 'utils/rebass-theme';

import { getPlaceholderStyle, inputReset } from 'components/field-text/field-text.styles';

export const QuantityInput = styled.input`
  ${textStyles.body};
  color: ${colors.floomMidnightBlue};
  font-size: 12px;
  background: none;
  width: 50px;
  padding: ${space[2]}px;
  border: 2px solid ${colors.middleGrey};
  border-radius: ${borderRadius.medium};
  cursor: text;

  &:focus {
    outline: none;
  }

  ${inputReset}
  ${getPlaceholderStyle()};

  @media only screen and (max-width: ${breakpoints.medium}) {
    width: 45px;
  }
`;

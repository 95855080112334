import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const CardsWrapper = styled(Flex)<any>`
  flex-flow: row wrap;
`;

export const SelectChannelWrapper = styled.div`
  margin: 20px;
  > p {
    ${textStyles.body};
  }
`;

export const CancelButtonWrapper = styled(Box)`
`;

export const ConfigSubTitle = styled.p`
  ${textStyles.body};
  font-weight: 700;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';

export const ListWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 15px;

  ${({ wrapperStyles }) => wrapperStyles};
`;

export const Checkbox = css`
  margin: 0;
`;

export const Label = css`
  margin: 0;
`;

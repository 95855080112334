import { Component, ReactNode } from 'react';

import { Flex } from 'rebass';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';

import * as Styles from './entity-list.styles';
import * as Types from './table-layout-entity.types';

export default class TableLayoutEntityHeading extends Component<Types.TableLayoutEntityHeadingProps> {
  render(): ReactNode {
    return (
      <Styles.EntityListRow>
        <Flex p="0 10px">
          {this.props.headers.map((header, i) => {
            if (header.restrictedRoles) {
              return (
                <FloomXRestrictedComponent
                  restrictedRoles={header.restrictedRoles}
                  key={i}
                >
                  <Styles.EntityListTableHeadingItem
                    fixedWidth={header.fixedWidth}
                    flexGrow={header.flexGrow}
                    flexBasis={header.flexBasis}
                  >
                    {header.heading}
                  </Styles.EntityListTableHeadingItem>
                </FloomXRestrictedComponent>
              );
            }

            return (
              <Styles.EntityListTableHeadingItem
                fixedWidth={header.fixedWidth}
                flexGrow={header.flexGrow}
                flexBasis={header.flexBasis}
                key={i}
              >
                {header.heading}
              </Styles.EntityListTableHeadingItem>
            );
          })}
        </Flex>
      </Styles.EntityListRow>
    );
  }
}

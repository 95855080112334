import { Component, FC, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex } from 'rebass';

import { CHANNELS } from 'global.constants';

import {
  CurrencyService,
  TimeService,
  Analytics,
  StringFormat,
  HTMLService,
  PermissionsService
} from 'lib';

import ModalStore from 'stores/modal-store/modal-store';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';
import { textStyles, colors } from 'utils/rebass-theme';

import CourierStatusIndicator from 'features/courier/components/courier-status-indicator';
import { ModalRouterScrollableArea } from 'features/modal-router/modal-router.styles';
import OrderHelpers from 'features/orders/helpers/order-helpers';
import { ORDER_PERMISSIONS } from 'features/orders/orders.constants';
import { OrderPermissionOption, ExtendedOrder, OrderStatusSlug } from 'features/orders/orders.types';
import { OrderListService, OrderEditService, OrderAnalytics } from 'features/orders/services';

import OrderAddress from 'components/address';
import Button from 'components/button';
import FunctionalAnchor from 'components/functional-anchor';
import Icon from 'components/icon';
import Loader from 'components/loader';
import NoResultsGeneric from 'components/no-results-generic';
import SectionHeading from 'components/section-heading';
import WithLoading from 'components/with-loading';

import DateOrdered from './components/date-ordered';
import DeliveryDate from './components/delivery-date';
import OrderDetailHeader from './components/order-detail-header';
import OrderDetailStatusControl from './components/order-detail-status-control';
import OrderHelp from './components/order-help/order-help';
import OrderItemBlocks from './components/order-item-blocks';
import OrderDetailNotes from './components/order-notes';
import * as Styles from './order-detail.styles';
import * as Types from './order-detail.types';

class OrderDetailModal extends Component<Types.OrderDetailModalProps> {
  static defaultProps = {
    config: {
      closeTo: '/'
    }
  };

  componentDidMount(): void {
    this.fetchOrder();

    Analytics.page(Analytics.FxPageEvent.OrdersDetail);
  }

  componentWillUnmount(): void {
    this.props.ordersStore!.resetOrderDetail();
  }

  private async fetchOrder(): Promise<any> {
    if (this.props.config.id) {
      await OrderListService.fetchOrder(this.props.config.id);
      OrderAnalytics.onViewDetail(this.props.ordersStore!.listLayout, this.props.ordersStore!.currentOrder);
    }
  }

  private editDeliveryAddress = (): void => {
    OrderEditService.editDeliveryAddress(
      this.props.ordersStore!.currentOrder.shippingAddress,
      { orderNo: this.props.ordersStore!.currentOrder.orderNo }
    );
  };

  private editSenderDetails = (): void => {
    OrderEditService.editSenderDetails(
      {
        ...this.props.ordersStore!.currentOrder.customerUser,
        ...this.props.ordersStore!.currentOrder.billingAddress
      },
      { orderNo: this.props.ordersStore!.currentOrder.orderNo }
    );
  };

  private editDeliveryInstructions = (order: ExtendedOrder): void => {
    const instructions = order.deliveryInstructions ? HTMLService.stripTagsAndAddNewLine(order.deliveryInstructions) : '';

    OrderEditService.editDeliveryInstructions(
      instructions,
      { orderNo: order.orderNo }
    );
  };

  private editSafePlace = (order: ExtendedOrder): void => {
    OrderEditService.editDeliverySafePlace(
      order.deliverySafePlace!,
      { orderNo: order.orderNo }
    );
  };

  private updateDeliveryDate = (deliverAt: string): void => {
    OrderEditService.updateDeliveryDate(
      { deliverAt: TimeService.createIsoTimestamp(moment(deliverAt).hour(0).minute(0).second(0)) },
      { orderNo: this.props.ordersStore!.currentOrder.orderNo }
    )
      .then((result: any) => {
        this.props.ordersStore!.setOrder(result);
      });
  };

  private onOrderNoteEdit = (): void => {
    if (this.props.innerRef && this.props.innerRef.current) {
      this.props.innerRef.current.scrollTo({
        top: this.props.innerRef.current.scrollHeight
      });
    }
  };

  private hasCustomWebsitePermissions = (permissionOption: OrderPermissionOption): boolean => {
    return OrderHelpers.hasCustomWebsitePermissions(
      permissionOption,
      this.props.ordersStore!.currentOrder.channel
    );
  };

  private renderDeliveryPrice = (): string => {
    const { orderDeliveryConfig, merchant } = this.props.ordersStore!.currentOrder;

    if (orderDeliveryConfig?.price) {
      return CurrencyService.formatPrice(
        orderDeliveryConfig.price,
        merchant.currency
      );
    }

    return '';
  };

  private renderDeliveryCopy = (): string => {
    const { currentOrder: order } = this.props.ordersStore!;

    switch (true) {
      case order.status.slug === OrderStatusSlug.Delivered || order.status.slug === OrderStatusSlug.Cancelled:
        return 'Order complete, delivery price was';

      case OrderHelpers.isSameDay(order) && OrderHelpers.hasFloomXDeliverySnap(order.orderDeliveryConfig):
        return `Same day delivery, ${OrderHelpers.renderDeliveryTime(order)},`;

      default:
        return `${OrderHelpers.renderDeliveryTime(order)},`;
    }
  };

  private renderNationwideDelivery = (): ReactNode => {
    const { currentOrder: order } = this.props.ordersStore!;

    return (order.nationwide) ? <Box css={textStyles.body}>Nationwide</Box> : null;
  };

  private formattedPickupDate = (pickupDate: Date): string => {
    return moment(pickupDate!.toString()).utc().format('dddd Do of MMMM hh:mma');
  };

  private renderOrderItems = (order: ExtendedOrder): ReactNode => (
    <Box>
      <OrderItemBlocks
        items={order.orderItems!}
        shouldOverrideRoles={this.hasCustomWebsitePermissions('giftMessages')}
      />
    </Box>
  );

  private renderDeliveryDetails = (order: ExtendedOrder): ReactNode => (
    <Box>
      <Styles.StatusControls>
        <OrderDetailStatusControl />
      </Styles.StatusControls>

      <Flex
        flexWrap="wrap"
      >
        <Styles.Block>
          <Flex>
            <Styles.IconBox>
              <Icon
                iconName="calendar"
                pathFill={colors.shade40}
              />
            </Styles.IconBox>
            <Box>
              <DeliveryDate
                onUpdate={this.updateDeliveryDate}
                order={order}
                shouldOverrideRoles={this.hasCustomWebsitePermissions}
              />
            </Box>
          </Flex>
          {order.orderDeliveryConfig && (
            <Flex>
              <Box>
                {!!order.courierBooking ? (
                  <Box
                    css={textStyles.body}
                    px="15px"
                  >
                    <Styles.DeliveryContainer>
                      <CourierStatusIndicator
                        orderData={order}
                        hasDefault={true}
                        hasIcon={true}
                      />
                    </Styles.DeliveryContainer>
                    <Box pl="35px">
                      <Box>
                        <strong>Courier Ref:</strong> {StringFormat.truncate(order.courierBooking.courierReferenceId, 5, '')}
                      </Box>
                      <Box>
                        <strong>Collection:</strong> {this.formattedPickupDate(order.courierBooking.pickupDate)}
                      </Box>
                    </Box>
                  </Box>
                )
                  : (
                    <Flex>
                      <Box
                        pt="28px"
                        pr="15px"
                      >
                        <Icon
                          iconName="delivery"
                          pathFill={colors.shade40}
                        />
                      </Box>
                      <Box>
                        <Styles.Subheading>Shipping Method</Styles.Subheading>
                        <Box css={textStyles.body}>
                          {this.renderDeliveryCopy()} {this.renderDeliveryPrice()}
                        </Box>
                        {this.renderNationwideDelivery()}
                      </Box>
                    </Flex>
                  )}
              </Box>
            </Flex>
          )}
        </Styles.Block>
        <Styles.Block>
          <Flex>
            <Styles.IconBox>
              <Icon
                iconName="map-pin"
                pathFill={colors.shade40}
              />
            </Styles.IconBox>
            <Box>
              <Styles.Subheading>Recipient Address</Styles.Subheading>
              <OrderAddress
                heading="Recipient Address"
                onEdit={this.editDeliveryAddress}
                address={order.shippingAddress!}
                type="shippingAddress"
                restrictedRoles={ORDER_PERMISSIONS.shippingAddress.restrictedRoles}
                shouldOverrideRoles={this.hasCustomWebsitePermissions('shippingAddress')}
                isBusiness={!!order.commercial}
                canEdit={true}
              />
            </Box>
          </Flex>
        </Styles.Block>
        <Styles.Block>
          <Flex>
            <Styles.IconBox>
              <Icon
                iconName="instructions"
                pathFill={colors.shade40}
              />
            </Styles.IconBox>
            <Box>
              <Styles.Subheading>Instructions</Styles.Subheading>
              <Box css={textStyles.body}>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: order.deliveryInstructions ? HTMLService.sanitize(order.deliveryInstructions) : '-'
                  }}
                />
                <FloomXRestrictedComponent
                  restrictedRoles={ORDER_PERMISSIONS.deliveryInstructions.restrictedRoles}
                  shouldOverrideRoles={this.hasCustomWebsitePermissions('deliveryInstructions')}
                >
                  <Box mt="10px">
                    <button onClick={(): any => this.editDeliveryInstructions(order)}>
                      <Icon iconName="edit" />
                    </button>
                  </Box>
                </FloomXRestrictedComponent>
              </Box>
            </Box>
          </Flex>
          <Flex>
            <Styles.IconBox>
              <Icon
                iconName="nobody-home"
                pathFill={colors.shade40}
              />
            </Styles.IconBox>
            <Box>
              <Styles.Subheading>If Nobody is home</Styles.Subheading>
              <Box css={textStyles.body}>
                {order.deliverySafePlace || '-'}
                <FloomXRestrictedComponent
                  restrictedRoles={ORDER_PERMISSIONS.deliverySafePlace.restrictedRoles}
                  shouldOverrideRoles={this.hasCustomWebsitePermissions('deliverySafePlace')}
                >
                  <Box mt="10px">
                    <button onClick={(): any => this.editSafePlace(order)}>
                      <Icon iconName="edit" />
                    </button>
                  </Box>
                </FloomXRestrictedComponent>
              </Box>
            </Box>
          </Flex>
        </Styles.Block>
      </Flex>
    </Box>
  );

  private renderAdditional = (order: ExtendedOrder): ReactNode => (
    <Box mt="10px">
      <SectionHeading
        hasSpacing={false}
      />
      <Flex
        flexWrap="wrap"
      >
        <Styles.Block>
          <Flex>
            <Styles.IconBox>
              <Icon
                iconName="sender"
                pathFill={colors.shade40}
              />
            </Styles.IconBox>

            <Box>
              <Styles.Subheading>Sender details</Styles.Subheading>
              <Box css={textStyles.body}>
                <Box>{order.billingAddress!.recipientFullName}</Box>
                {!!order.billingAddress && !!order.billingAddress.phone && (
                  <Box mt="5px">
                    <FunctionalAnchor
                      type="tel"
                      copy={order.billingAddress.phone}
                      anchorHref={order.billingAddress.phone}
                    />
                  </Box>
                )}
                <FloomXRestrictedComponent
                  restrictedRoles={ORDER_PERMISSIONS.emailAddress.restrictedRoles}
                  shouldOverrideRoles={this.hasCustomWebsitePermissions('emailAddress')}
                >
                  <Box mt="5px">
                    <FunctionalAnchor
                      type="mailto"
                      copy={order.customerUser.email!}
                      anchorHref={order.customerUser.email!}
                    />
                  </Box>
                </FloomXRestrictedComponent>
                <FloomXRestrictedComponent
                  restrictedRoles={ORDER_PERMISSIONS.deliveryInstructions.restrictedRoles}
                  shouldOverrideRoles={this.hasCustomWebsitePermissions('senderDetails')}
                >
                  <Box mt="10px">
                    <button onClick={this.editSenderDetails}>
                      <Icon iconName="edit" />
                    </button>
                  </Box>
                </FloomXRestrictedComponent>
              </Box>
            </Box>
          </Flex>
        </Styles.Block>
        <Styles.Block>
          <Flex>
            <Styles.IconBox>
              <Icon
                iconName="flower-front"
                pathFill={colors.shade40}
              />
            </Styles.IconBox>
            <Box>
              <Styles.Subheading>Florist</Styles.Subheading>
              <Box css={textStyles.body}>
                {order.merchant.title}
              </Box>
            </Box>
          </Flex>
          <Flex>
            <Styles.IconBox>
              <Icon
                iconName="website"
                pathFill={colors.shade40}
              />
            </Styles.IconBox>
            <Box>
              <Styles.Subheading>Platform</Styles.Subheading>
              <Box css={textStyles.body}>
                {CHANNELS[order.channel]}
              </Box>
            </Box>
          </Flex>
        </Styles.Block>
        <Styles.Block>
          <Flex>
            <Styles.IconBox>
              <Icon
                iconName="calendar"
                pathFill={colors.shade40}
              />
            </Styles.IconBox>
            <Box>
              <DateOrdered
                orderedAt={order.orderedAt}
                timezone={order.merchant.timezone}
              />
            </Box>
          </Flex>
        </Styles.Block>
      </Flex>
    </Box>
  );

  private renderOrderNotes = (order: ExtendedOrder): ReactNode => (
    <Box
      p="30px"
      pt="0"
    >
      <Box>
        <OrderDetailNotes
          notes={order.orderNotes!}
          orderId={this.props.ordersStore!.currentOrder.orderNo}
          onEditNote={this.onOrderNoteEdit}
        />
      </Box>
    </Box>
  );

  render(): ReactNode | null {
    const { currentOrder: order, orderDetailLoading } = this.props.ordersStore!;

    if (!!order) {
      return (
        <Fragment>
          <OrderDetailHeader
            closeTo={this.props.config.closeTo!}
          />
          <ModalRouterScrollableArea
            ref={this.props.innerRef}
            style={{
              height: 'calc(100vh - 80px)',
              maxHeight: `${this.props.height! - 150}px`,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <WithLoading
              loaderSize="small"
              marginTop="30px"
              hasNoResults={!orderDetailLoading && !order.status}
              isLoading={orderDetailLoading}
              renderNoResults={(): ReactNode => {
                return (
                  <Box
                    mt="70px"
                    p="0 30px"
                  >
                    <NoResultsGeneric
                      icon="leaf-no-results"
                      heading="Sorry, we couldn't find that order"
                      copy=""
                    />
                  </Box>
                );
              }}
            >
              {order.status ? (
                <Fragment>
                  {this.renderOrderItems(order)}
                  <Box
                    p={['20px', '10px 30px 30px 30px']}
                    bg="lightGrey"
                  >
                    {this.renderDeliveryDetails(order)}
                    {this.renderAdditional(order)}
                  </Box>
                  {this.renderOrderNotes(order)}
                  <Flex
                    mt="auto"
                    bg="lightGrey"
                    p="20px 30px"
                    style={{ position: 'sticky', bottom: '0', borderTop: `1px solid ${colors.middleGrey}` }}
                  >
                    <Box style={{ marginRight: '20px' }}>
                      <OrderHelp
                        order={order}
                      />
                    </Box>
                    <RefundButton
                      order={order}
                      modalStore={this.props.modalStore}
                    />
                    <AdjustmentButton
                      order={order}
                      modalStore={this.props.modalStore}
                    />
                  </Flex>
                </Fragment>
              )
                : (
                  <Styles.ContentLoader justifyContent="center">
                    <Loader />
                  </Styles.ContentLoader>
                )}
            </WithLoading>
          </ModalRouterScrollableArea>
        </Fragment>
      );
    }

    return null;
  }
}

export default inject('ordersStore', 'modalStore')(observer(OrderDetailModal));

type RefundButtonProps = {
  order: ExtendedOrder;
  modalStore: ModalStore | undefined;
}

const RefundButton: FC<RefundButtonProps> = ({ order, modalStore }) => {
  const openModal = (): void => {
    modalStore!.triggerModal<any>({
      modalType: 'orderRefund',
      data: {
        orderId: order.id
      }
    });
  };

  return (
    <Fragment>
      <Box
        as="button"
        style={{ marginRight: '20px' }}
        onClick={openModal}
      >
        <Button
          copy="Issue refund"
          appearance="primary"
          size="small"
        />
      </Box>
    </Fragment>
  );
};

const AdjustmentButton: FC<RefundButtonProps> = ({ order, modalStore }) => {
  if (!PermissionsService.isSuperAdmin()) {
    return <Fragment />;
  }

  const openModal = (): void => {
    modalStore!.triggerModal<any>({
      modalType: 'transactionCreate',
      data: {
        orderId: order.id,
        merchantId: order.merchant.id
      }
    });
  };

  return (
    <Fragment>
      <Box
        as="button"
        onClick={openModal}
      >
        <Button
          copy="Add adjustment"
          appearance="primary"
          size="small"
        />
      </Box>
    </Fragment>
  );
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { breakpoints, textStyles } from 'utils/rebass-theme';

export const ListControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px auto 30px 0;
`;

export const ListOrdering = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
`;

export const FiltersToggle: any = styled(Flex)`
  ${textStyles.subhead};
`;

export const FieldListToggle: any = styled(Flex)`
  ${textStyles.subhead};
`;

export const FilterCount = styled.span`
  color: #969db1;
  margin:0 10px 0 3px;
  font-size: 12px;
`;

export const Checkbox = css`
  flex-basis: 16%;
  margin-bottom: 18px;
`;

export const Wrapper = css`
  display: grid;
  grid-template-areas: 'search toggle . fields filters sort';
  grid-template-rows: auto;
  align-items: center;
  grid-template-columns: auto;
  grid-gap: 20px;

  @media (max-width: ${breakpoints.large}) {
    grid-template-columns: auto;
    grid-gap: 5px;
    grid-row-gap: 20px;
    grid-template-areas:
      'search search sort sort'
      'filters toggle . fields';
  }
`;

import { Fragment, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box, Flex } from 'rebass';

import { breakpoints, colors, textStyles } from 'utils/rebass-theme';

import PlantPotSmall from 'assets/images/plant-pot-small.svg';

import PageCornerBackground from 'components/page-corner-background';

interface ComingSoonProps {
  title?: string;
  copy?: ReactNode;
  offset?: string;
}

const ComingSoon = (props: ComingSoonProps): JSX.Element => {
  return (
    <Fragment>
      <PageCornerBackground
        topLeft="coming-soon-top-left"
        topRight="coming-soon-top-right"
        bottomCenter="coming-soon-bottom-center"
        bottomRight={null}
        bottomLeft={null}
        {...(!!props.offset ? {
          offset: props.offset
        } : {})}
      />
      <Flex
        m="auto"
        p="0 20px"
        flexWrap="wrap"
        alignItems="center"
        css={css`
          margin-top: 100px;

          @media (max-width: ${breakpoints.medium}) {
            height: auto;
            margin-top: 100px;
          }
        `}
      >
        <Box
          width="100%"
          css={css`
            text-align: center;
            color: ${colors.floomMidnightBlue};
          `}
        >
          <Box width="100%">
            <img
              css={css`
                max-width: 170px;
                width: 45%;
              `}
              src={PlantPotSmall}
            />
          </Box>
          <Box width="100%">
            <Box
              as="h2"
              css={css`
                ${textStyles.h2};
                text-align: center;
                margin-top: 40px;
              `}
            >
              {props.title}
            </Box>
            <Box
              as="p"
              mt="15px"
              css={css`
                line-height: 1.5;
              `}
            >
              {props.copy}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Fragment>
  );
};

ComingSoon.defaultProps = {
  copy: 'We are currently working hard on this new feature. We’ll let you know when it’s live!',
  title: 'Coming Soon...'
};

export default ComingSoon;

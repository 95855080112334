import React, { Component, ReactNode } from 'react';

import _startCase from 'lodash.startcase';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { MerchantHolidayEdge, Maybe, PlanFeature } from 'generated-types.d';

import { MerchantService } from 'lib';

import MerchantHolidaysService from 'features/settings/components/merchant-holidays/services';

import { EntityListItems } from 'components/entity-list/entity-list.styles';
import HolidayListItem from 'components/merchant-holidays/holiday-list-item';
import NoResultsGeneric from 'components/no-results-generic';
import SectionHeading from 'components/section-heading';
import WithLoading from 'components/with-loading';

import * as Styles from './holiday-list.styles';
import * as Types from './holiday-list.types';

class HolidayList extends Component<Types.HolidayListProps> {
  componentWillUnmount(): void {
    this.props.merchantHolidaysStore!.clearmerchantHolidays();
  }

  private groupHasItems = (groupName: string): boolean => !!this.props.merchantHolidaysStore!.merchantHolidays?.[groupName].aggregate.count;

  private getHolidayGroupKeys = (): any[] => Object.keys(this.props.merchantHolidaysStore!.merchantHolidays || {});

  private countAllGroups = (): any => this.getHolidayGroupKeys().some(key => this.props.merchantHolidaysStore!.merchantHolidays?.[key].aggregate.count);

  private renderItems = (groupName: string): ReactNode => {
    if (!this.props.merchantHolidaysStore!.merchantHolidays?.[groupName].edges?.length) return null;

    return this.props.merchantHolidaysStore!.merchantHolidays[groupName].edges.map((edge: Maybe<MerchantHolidayEdge>): React.ReactNode => {
      if (!edge?.node) return null;

      return (
        <HolidayListItem
          key={edge?.node.id}
          item={edge.node}
          startDateCopy="First day of holiday:"
          endDateCopy="First day back:"
          shouldDisplayMerchantTitle={true}
          onUpdate={MerchantHolidaysService.updateMerchantHoliday}
          onDelete={MerchantHolidaysService.deleteMerchantHoliday}
        />
      );
    });
  };

  private renderGroup = (groupName: string): ReactNode => {
    if (!this.groupHasItems(groupName)) return null;

    return (
      <div key={groupName}>
        <SectionHeading
          title={_startCase(groupName.match(/\__(.*?)\__/)?.[1])}
          count={this.props.merchantHolidaysStore!.merchantHolidays?.[groupName].aggregate.count}
        />
        <EntityListItems removeOverflow={true}>
          {this.renderItems(groupName)}
        </EntityListItems>
      </div>
    );
  };

  render(): React.ReactNode {
    const keys = this.getHolidayGroupKeys();
    const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.merchantStore!.merchant!);

    return (
      <Box mb="30px">
        <WithLoading
          hasNoResults={!this.props.merchantHolidaysStore!.isLoadingHolidays && !this.countAllGroups()}
          isLoading={this.props.merchantHolidaysStore!.isLoadingHolidays && !keys.length}
          renderNoResults={(): ReactNode => (
            <Box mt="40px">
              {isCollectionSeller ? (
                <NoResultsGeneric
                  icon="backpack"
                  heading="You can't set a holiday for a Floom Collection florist"
                  copy={(
                    <span>Visit&nbsp;
                      <Styles.OuterLink
                        target="_blank"
                        rel="noreferrer noopener"
                        href='https://help.floomx.com/hc/en-us/articles/360052920692-How-do-I-set-a-holiday-with-Floom-Collection-'
                      >this help article
                      </Styles.OuterLink>
                      &nbsp;for more information
                    </span>
                  )}
                />
              ) : (
                <NoResultsGeneric
                  icon="backpack"
                  heading="No current or upcoming holidays"
                  copy="Having some time off? Add your holidays here so we know when you're not able to place orders"
                />
              )}

            </Box>
          )}
        >
          {keys.map((groupName: string) => this.renderGroup(groupName))}
        </WithLoading>
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantHolidaysStore: stores.merchantHolidaysStore,
  merchantStore: stores.merchantStore
}))(observer(HolidayList));

import React from 'react';

import { observer, inject } from 'mobx-react';

import * as Styles from './divider.styles';
import { DividerProps } from './divider.types';

class Divider extends React.Component<DividerProps> {
  private shouldDisplayRoute = (): boolean => {
    return !this.props.isFeatureFlagDisabled?.(this.props.merchantStore!.merchant);
  };

  render(): React.ReactNode {
    const { name } = this.props;
    const isCollapsed = this.props.navigationStore!.isCollapsed;

    if (!this.shouldDisplayRoute()) return null;

    if ((
      this.props.merchantStore!.merchant?.currency === 'USD'
      || !this.props.merchantStore!.merchant?.wholesaleActive
    ) && [
      'Wholesale',
      'Floom.com'
    ].includes(name)) return null;

    return (
      <Styles.Divider
        mt={!!(!name && this.props.merchantStore!.merchant?.currency === 'GBP') ? '13px' : '8px'}
        key={name}
      >
        {name && !isCollapsed && (
          <Styles.Label>
            {name}
          </Styles.Label>
        )}
      </Styles.Divider>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  merchantStore: stores.merchantStore
}))(observer(Divider));

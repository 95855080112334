import React, { Component, ReactNode } from 'react';

import { formatTime } from 'lib/services/time/time.service';
import { Box } from 'rebass';

import { TimeService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import { Subheading } from '../../order-detail.styles';

import * as Types from './date-ordered.types';

export default class DateOrdered extends Component<Types.DateOrderedProps> {
  render(): ReactNode {
    const timezoneMoment = TimeService.withTimezone(this.props.orderedAt, this.props.timezone);

    return (
      <>
        <Subheading>Order placed</Subheading>
        <Box css={textStyles.body}>
          {timezoneMoment.isSame(new Date(), 'day') ? `Today - ${timezoneMoment.format(formatTime(true))}` : timezoneMoment.format(`Do MMM - ${formatTime(true)}`)}
        </Box>
      </>
    );
  }
}

import React from 'react';

import { Router } from '@reach/router';

import TermsPage from 'features/terms';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

const Terms = (): JSX.Element => {
  return (
    <AppLayout>
      <Router>
        <AuthedRoute
          path="/terms/:termsType"
          component={TermsPage}
        />
      </Router>
    </AppLayout>
  );
};

export default Terms;

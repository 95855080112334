import { Component, ReactNode } from 'react';

import { UserRole, Channel } from 'generated-types.d';

import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';

interface ChannelMetaProps {
  orderNo: string;
  data: Channel;
}

class ChannelMeta extends Component<ChannelMetaProps> {
  renderChannel = (): string => {
    switch (this.props.data) {
      case Channel.Floom:
        return 'floom.com';

      case Channel.Website:
        return 'Custom website';

      case Channel.Pos:
        return 'POS';
    }
  };

  render(): ReactNode {
    return (
      <FloomXRestrictedComponent
        restrictedRoles={[UserRole.TeamOwner, UserRole.TeamAdmin, UserRole.TeamMember]}
      >
        <MetaCardContentWrapper iconName="website">
          {this.renderChannel()}
        </MetaCardContentWrapper>
      </FloomXRestrictedComponent>
    );
  }
}

export default ChannelMeta;

import {
  FC,
  Fragment
} from 'react';

import { css, Global, ThemeProvider } from '@emotion/react';
import { WrapRootElementBrowserArgs } from 'gatsby';
import { GlobalStyles } from 'global.styles';
import { Provider as StoreProvider } from 'mobx-react';
import { Helmet } from 'react-helmet';
import stores from 'stores';

import theme from 'utils/rebass-theme';

import RootErrorBoundary from './components/root-error-boundary';

import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.min.js';

/**
 * @description root entry point of application. provides a few things:
 * - MobX Stores
 * - Global styles - CSS declarations shared across the platform.
 * - Theme provider - Rebass uses this
 */
const wrapRootElement: FC<WrapRootElementBrowserArgs> = ({ element }) => {
  return (
    <StoreProvider {...stores}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Fragment>
        <Global styles={css(GlobalStyles)} />
        <RootErrorBoundary>
          <ThemeProvider theme={theme}>
            {element}
          </ThemeProvider>
        </RootErrorBoundary>
      </Fragment>
    </StoreProvider>
  );
};

export default wrapRootElement;

import React, { ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Container } from 'utils/css-mixins';

import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import MyPlanDetails from './component/my-plan-details';
import { MyPlanProps } from './my-plan.types';

class MyPlanContainer extends React.Component<MyPlanProps> {
  render(): JSX.Element {
    return (
      <WithLoading
        hasNoResults={!this.props.merchantStore!.merchant?.plan && !this.props.merchantStore!.isLoadingMerchant}
        isLoading={!this.props.merchantStore!.merchant?.plan && this.props.merchantStore!.isLoadingMerchant}
        renderNoResults={(): ReactNode => (
          <Container>
            <Box mt="60px">
              <NoResultsGeneric
                icon="my-plan"
                heading="We are having trouble finding your plan"
                copy="Please get in touch with Floom who will be able to assist further"
              />
            </Box>
          </Container>
        )}
      >
        <MyPlanDetails />
      </WithLoading>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore
}))(observer(MyPlanContainer));

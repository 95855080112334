import React from 'react';

import { Analytics } from 'lib';

import ComingSoon from 'components/coming-soon';

export default class LearningHome extends React.Component {
  componentDidMount(): void {
    Analytics.page(Analytics.FxPageEvent.LearningComingSoon);
  }

  render(): JSX.Element {
    return(
      <ComingSoon
        title="Learning coming soon"
        offset="0"
      />
    );
  }
}

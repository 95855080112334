import {
  FC
} from 'react';

import { Box } from 'rebass';

import { CatalogFilterCategory } from '../../catalog-filters-modal.types';
import { CatalogExplorerFacets } from '../../catalog-filters.constants';
import { CatalogRefinementList } from '../catalog-refinement-list/catalog-refinement-list';
import { CatalogRefinementMenu } from '../catalog-refinement-menu/catalog-refinement-menu';

interface CatalogRefinementFiltersProps {
  filters: CatalogExplorerFacets;
  selectedCategory: CatalogFilterCategory;
  refinementsState: any;
}

export const CatalogRefinementFilters: FC<CatalogRefinementFiltersProps> = ({
  filters,
  selectedCategory,
  refinementsState
}) => {
  return (
    <Box>
      <Box>
        <CatalogRefinementMenu
          name="Type"
          attribute="type"
          defaultRefinement={refinementsState?.type || []}
          selectedCategory={selectedCategory}
        />
      </Box>
      {filters[selectedCategory]?.map((config: any, index: number) => {
        return (
          <Box key={index}>
            <CatalogRefinementList
              name={config.title}
              attribute={config.refinement}
              operator={config.operator}
              defaultRefinement={refinementsState?.[config.refinement] || []}
              showMore={true}
              showMoreLimit={100}
              hasSearchInput={!!config.hasSearchInput}
              transformItems={(items: any[]) => items.filter(item => !!item?.count)}
            />
          </Box>
        );
      })}
    </Box>
  );
};

import styled from '@emotion/styled';
import { Box } from 'rebass';

export const ListWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;

  ${({ wrapperStyles }) => wrapperStyles};
`;

import { ApolloError } from '@apollo/client';
import store from 'stores';

import { AvailableCourierDeliveryTimeSlot } from 'generated-types.d';

import { CourierQueryType } from 'stores/courier/courier-store.types';

import { ExtendedOrder } from 'features/orders/orders.types';

import CourierApiService from '../courier-api/courier-api.service';
import OrdersApiService from '../orders-api/orders-api.service';

class CourierDeliveryService {
  private CourierStore = store.courierStore;

  public bookOrderDeliverySlot = (
    deliverySlot: AvailableCourierDeliveryTimeSlot,
    orderNo: string
  ): Promise<any> => {
    this.CourierStore.setLoading('bookDeliverySlot', true);
    this.CourierStore.toggleBookingModal();

    return CourierApiService.bookOrderDeliverySlot(deliverySlot, orderNo)
      .then((): Promise<any> => Promise.resolve())
      .catch((error: ApolloError) => {
        this.throwError('bookDeliverySlot');

        return Promise.reject(error);
      });
  };

  public fetchOrder = (id: string): void => {
    OrdersApiService.fetchOrder(id)
      .then((order: ExtendedOrder): void => {
        this.CourierStore.setCompletedCourierBooking(order.courierBooking!);
        this.CourierStore.setLoading('bookDeliverySlot', false);
      })
      .catch((error: ApolloError) => {
        this.throwError('bookDeliverySlot');

        return Promise.reject(error);
      });
  };

  private throwError(request: CourierQueryType): void {
    this.CourierStore.setError(request, true);
    this.CourierStore.setLoading(request, false);
  }
}

export default new CourierDeliveryService();

import React from 'react';

import { css } from '@emotion/react';
import { RouteType, ChildRouteType } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { UrlService } from 'lib';

import Icon from 'components/icon';
import { MerchantWeeblyAPIService } from 'components/navigation/services/merchant-weeblysso-apollo-service';

import * as Styles from './nav-action-link.styles';
import * as Types from './nav-action-link.types';

class NavActionLink extends React.Component<Types.NavLinkProps> {
  state = {
    loading: false
  };

  private shouldDisplayRoute = (): boolean => {
    return !this.props.route?.isFeatureFlagDisabled?.(this.props.merchantStore!.merchant);
  };

  private onClick = (
    event: React.MouseEvent,
    route: RouteType | ChildRouteType
  ): void => {
    event.preventDefault();

    if (!route.isDisabled) {
      window.open(`https://${route.url}`, '_blank');
    }
  };

  private onEditClick = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    this.setState({ loading: true });
    const merchantWeeblyAPIService = new MerchantWeeblyAPIService();
    await merchantWeeblyAPIService.fetchMerchantWeeblySSOLink();
    this.setState({ loading: false });
  };

  render(): React.ReactNode {
    const { route, location } = this.props;
    const { url, isDisabled, id } = route;
    const isCollapsed = this.props.navigationStore!.isCollapsed;
    const isSectionOpen = route.isExternal;

    if (typeof url === 'function' || !this.shouldDisplayRoute()) return null;

    return (
      <Box key={url}>
        <Styles.NavLinkParent
          id={id}
          as="button"
          key={url}
          title={route.name}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => this.onClick(e, route)}
          css={css`
            ${isSectionOpen && Styles.ActiveParent}
            ${isDisabled && Styles.NavLinkDisabled}
            ${UrlService.isExactMatch(url, location) && Styles.NavLinkActive}
          `}
        >
          <Icon
            iconName={route.icon}
            styles={css`
              ${Styles.Icon}
              ${isDisabled && Styles.IconDisabled}
              ${isSectionOpen && Styles.IconActive}
            `}
          />
          { !isCollapsed && (
            <>
              <Styles.NavLinkActionText>{route.name}</Styles.NavLinkActionText>
              {isDisabled && (
                <Icon
                  iconName="plus-circle"
                  styles={Styles.IconAddFeature}
                />
              )}
            </>
          )}
        </Styles.NavLinkParent>

        {route.isExternal && !isCollapsed && (
          <Styles.Accordion
            isSectionOpen={true}
            aria-expanded={true}
          >
            <Styles.AccordionItem
              key="Edit Your Site"
              title="Edit Your Site"
            >
              <Styles.NavLinkChild
                as="button"
                title="Edit Your Site"
                isDisabled={this.state.loading}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>): Promise<void> => this.onEditClick(e)}
              >
                Edit Your Site
              </Styles.NavLinkChild>
            </Styles.AccordionItem>
          </Styles.Accordion>
        )}
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  merchantStore: stores.merchantStore
}))(observer(NavActionLink));

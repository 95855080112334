import { gql } from '@apollo/client';

export const CREATE_MAILCHIMP_INTEGRATION = gql`
  mutation CreateMailchimpIntegration($code: String!, $where: ConfirmMailchimpIntegrationWhereUniqueInput!) {
    confirmMailchimpIntegration(
      data: {code: $code},
      where: $where
    )
    {
      linked
    }
  }
`;

export const CREATE_MERCHANT_CUSTOM_SITE_CONFIG = gql`
    mutation CreateCustomSiteConfig(
      $data: CustomSiteConfigCreateInput!
    ) {
      createCustomSiteConfig(
        data: $data
      )
      {
        id
        customSiteProjectId
        customSiteProjectUrl
      }
    }
`;

export const UNLINK_MAILCHIMP_SUBSCRIPTION = gql`
    mutation UnlinkMailchimpSubscription(
      $where: CustomSiteConfigWhereUniqueInput!
    ) {
      updateCustomSiteConfig(
        where: $where
        data: {
          mailchimpInformation: {
            delete: true
          }
        }
      ) {
        mailchimpInformation {
          id
        }
      }
    }
`;

export const UPDATE_MERCHANT_CUSTOM_SITE_CONFIG = gql`
    mutation UpdateCustomSiteConfig(
      $merchantId: ID!
      $id: String
      $data: CustomSiteConfigUpdateInput!
    ) {
      updateCustomSiteConfig(
        where: {
          customSiteProjectId: $id,
          merchant: {
            id: $merchantId
          }
        }
        data: $data
      )
      {
        id
        customSiteProjectId
        customSiteProjectUrl
      }
    }
`;

import styled from '@emotion/styled';

import { textStyles } from 'utils/rebass-theme';

export const Content = styled.div`
  max-width: 100%;
  min-height: 100%;
`;

export const InnerContent = styled.div`
  padding: 20px;
  > p {
    ${textStyles.body};
  }
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { PulseAnimation } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const SelectedItem = styled(Flex)`
  position: relative;
  align-items: center;
  padding: 15px 10px;

  & + ${(): any => SelectedItem} {
    border-top: ${colors.paleGrey} 1px solid;
  }
`;

export const ItemLoading = css`
  ${PulseAnimation};
  ${textStyles.footnote};
  color: ${colors.floomMidnightBlue};
`;

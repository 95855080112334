import {
  action,
  makeObservable,
  observable
} from 'mobx';

import {
  ConfirmOrderCreationOutput,
  WholesaleOrderLineItemCreateWithoutOrderInput,
  WholesaleOrderResolverVersion
} from 'generated-types.d';

import { WholesaleService } from 'lib';

import { WholesaleShopBasketItem } from './wholesale-shop-basket-store.types';

export default class WholesaleShopBasketStore {
  constructor() {
    makeObservable(this, {
      basket: observable,
      orderCreateError: observable,
      addBasketItem: action,
      clearBasket: action,
      placeOrder: action,
      removeBasketItem: action,
      updateBasketItem: action,
      resetStore: action
    });
  }

  public basket: WholesaleShopBasketItem[] = [];

  public orderCreateError: string | null = null;

  public resetStore = (): void => {
    this.basket = [];
    this.orderCreateError = null;
  };

  public addBasketItem = (item: WholesaleShopBasketItem): void => {
    this.basket = [
      ...this.basket,
      item
    ];
  };

  public updateBasketItem = (item: WholesaleShopBasketItem): void => {
    const itemIndex = this.basket.findIndex(basketItem => basketItem.itemId === item.itemId);
    const hasBasketItem = itemIndex !== -1;

    if (hasBasketItem) {
      this.basket[itemIndex] = item;
    }
  };

  public removeBasketItem = (itemId: string): void => {
    const itemIndex = this.basket.findIndex(basketItem => basketItem.itemId === itemId);
    const hasBasketItem = itemIndex !== -1;

    if (hasBasketItem) {
      this.basket = this.basket.filter(basketItem => basketItem.itemId !== itemId);
    }
  };

  public clearBasket = (): void => {
    this.basket = [];
  };

  public placeOrder = async ({
    merchantId,
    cutOffDate,
    deliveryDate,
    orderId
  }: {
    merchantId: string;
    deliveryDate: string;
    cutOffDate: string;
    orderId?: string;
  }): Promise<ConfirmOrderCreationOutput> => {
    this.orderCreateError = null;

    try {
      const createOrderOutput = await WholesaleService.placeOrder({
        args: {
          where: {
            id: merchantId
          },
          data: {
            version: WholesaleOrderResolverVersion.Two,
            items: this.buildOrderItemsFromBasket(),
            cutOffDate: cutOffDate,
            deliveryDate: deliveryDate,
            ...(!!orderId ? {
              order: {
                id: orderId
              }
            } : {})
          }
        }
      });

      this.basket = [];

      return createOrderOutput;
    } catch (error) {
      window.Sentry.captureException(error);
      this.orderCreateError = this.configureErrorFromOrderCreate(error);

      return Promise.reject(error);
    }
  };

  private buildOrderItemsFromBasket = (): WholesaleOrderLineItemCreateWithoutOrderInput[] => {
    return this.basket.map(item => {
      return {
        quantity: item.quantity,
        wholesalerLineItemRef: item.itemId
      };
    });
  };

  private configureErrorFromOrderCreate = (error: any): string => {
    if (error.graphQLErrors && error.graphQLErrors[0]) {
      switch (error.graphQLErrors[0].code) {
        case 77046121078:
          return 'Some items in your basket are no longer available. FloomX are aware of this issue.';

        case 77046121079:
          return 'There has been a recent purchase for this item. Reduce the amount you are trying to purchase and try again.';

        case 77046121072:
          return 'There has been an error completing your order. Please contact Floom';

        case 77046121090:
          return 'There is no available stock for this item, please try again later';
      }
    }

    return 'There has been an error completing your order. Please contact Floom';
  };
}

import { FC } from 'react';

import { Text } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import { RadioItemViewProps } from '../radio-item/radio-item.types';

import {
  RadioLabelWrapper,
  RadioLabelCard,
  RadioLabelDescription
} from './card-item.styles';

export const CardItem: FC<RadioItemViewProps> = ({
  orientation,
  isSelected,
  isDisabled,
  title,
  subTitle,
  itemsCount
}) => (
  <RadioLabelWrapper>
    <RadioLabelCard
      isDisabled={isDisabled}
      isSelected={isSelected}
      orientation={orientation}
      itemsCount={itemsCount}
    >
      <Text css={textStyles.subhead}>
        {title}
      </Text>
    </RadioLabelCard>
    <RadioLabelDescription>
      {subTitle}
    </RadioLabelDescription>
  </RadioLabelWrapper>
);

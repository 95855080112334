import { ApolloQueryResult } from '@apollo/client';
import store from 'stores';

import {
  AdjusterTier,
  AdjusterTierCreateInput,
  AdjusterTierUpdateInput,
  AdjusterTierWhereUniqueInput,
  Currency,
  WholesaleDeliveryConfig,
  WholesaleDeliveryConfigUpdateInput
} from 'generated-types.d';

import { AdjusterService, GraphQL } from 'lib';

import { AdjusterTierFormValues } from 'stores/wholesale/wholesale-settings-store/wholesale-settings-store';

import {
  WHOLESALE_DELIVERY_CONFIGS,
  UPDATE_WHOLESALE_DELIVERY_CONFIG,
  CREATE_BLANK_WHOLESALE_CONFIG,
  GET_WHOLESALE_GMV,
  GET_WHOLESALE_TIERS
} from 'features/wholesale/graphql/queries/settings/settings-queries';

export default class WholesaleSettingsAPIService {
  public static getWholesaleGMV = async (merchantId: string): Promise<void> => {
    try {
      const where = { id: merchantId };
      const result: ApolloQueryResult<{ wholesaleGMV: number }> = await GraphQL
        .query(GET_WHOLESALE_GMV, { where });
      const { wholesaleGMV } = result.data;
      store.wholesaleSettingsStore.setWholesaleGMV(wholesaleGMV);
    } catch (error) {
      store.toasterStore.popErrorToast('wholesale GMV', 'retrieve');

      return Promise.reject(error);
    }
  };

  public static getWholesaleTiers = async (merchantId: string): Promise<void> => {
    try {
      const where = { merchant: { id: merchantId } };
      const result: ApolloQueryResult<{ adjusterTiers: AdjusterTier[] }> = await GraphQL
        .query(GET_WHOLESALE_TIERS, { where });
      const { adjusterTiers } = result.data;
      store.wholesaleSettingsStore.setWholesaleTiers(adjusterTiers);
    } catch (error) {
      store.toasterStore.popErrorToast('wholesale tiers', 'retrieve');

      return Promise.reject(error);
    }
  };

  public static updateWholesaleTier = async (
    where: AdjusterTierWhereUniqueInput,
    data: AdjusterTierUpdateInput,
    localTier: AdjusterTierFormValues
  ): Promise<void> => {
    try {
      const response = await AdjusterService.updateAdjusterTier(where, data);
      const { id } = response;

      if (id) {
        store.wholesaleSettingsStore!.updateWholesaleTier({ tier: { ...localTier, id } as any });
      }
    } catch (error) {
      store.toasterStore.popErrorToast('wholesale tier', 'update');

      return Promise.reject(error);
    }
  };

  public static createWholesaleTier = async (
    data: AdjusterTierCreateInput,
    localTier: AdjusterTierFormValues
  ): Promise<void> => {
    try {
      const response = await AdjusterService.createAdjusterTier(data);
      const { id } = response;

      if (id) {
        store.wholesaleSettingsStore!.createWholesaleTier({ tier: { ...localTier, id } as any });
      }
    } catch (error) {
      store.toasterStore.popErrorToast('wholesale tier', 'create');

      return Promise.reject(error);
    }
  };

  public static deleteWholesaleTier = async (where: AdjusterTierWhereUniqueInput): Promise<void> => {
    try {
      await AdjusterService.deleteAdjusterTier(where);
      store.wholesaleSettingsStore!.deleteWholesaleTier(where.id as any);
    } catch (error) {
      store.toasterStore.popErrorToast('wholesale tier', 'remove');

      return Promise.reject(error);
    }
  };

  public fetchWholesaleDeliveryConfigs = async (): Promise<WholesaleDeliveryConfig[]> => {
    const merchantId = store.userStore.merchantId || null;

    return GraphQL.query<{ wholesaleDeliveryConfigs: WholesaleDeliveryConfig[] }>(WHOLESALE_DELIVERY_CONFIGS(merchantId), { merchantId }, 'no-cache')
      .then(data => {
        store.wholesaleSettingsStore.setDeliveryConfigs(data.data.wholesaleDeliveryConfigs);

        return data.data.wholesaleDeliveryConfigs;
      })
      .catch(error => {
        store.wholesaleSettingsStore.loadingError(error);

        return Promise.reject(error);
      });
  };

  public updateWholesaleDeliveryConfig = async (existingConfig: WholesaleDeliveryConfig, updates: WholesaleDeliveryConfigUpdateInput): Promise<WholesaleDeliveryConfig> => {
    const data = {
      updates: updates,
      id: existingConfig.id,
      merchantId: existingConfig.merchant!.id
    };

    return GraphQL.query(UPDATE_WHOLESALE_DELIVERY_CONFIG, data)
      .then((result: ApolloQueryResult<{ updateMerchantWholesaleDeliveryConfig: WholesaleDeliveryConfig }>) => {
        return result.data.updateMerchantWholesaleDeliveryConfig;
      })
      .catch(error => {
        window.Sentry.captureException(error);

        return Promise.reject(error);
      });
  };

  public createBlankDeliveryConfig = async (): Promise<WholesaleDeliveryConfig> => {
    const merchant = store.merchantStore?.merchant;

    if (!merchant) {
      return Promise.reject('You cannot create a wholesale config if you don\'t have a merchant selected.');
    }

    const country = merchant.currency === Currency.Gbp ? 'United Kingdom' : 'United States';

    return GraphQL.query(CREATE_BLANK_WHOLESALE_CONFIG, {
      merchantId: merchant.id,
      country: country
    })
      .then((result: ApolloQueryResult<{ createMerchantWholesaleDeliveryConfig: WholesaleDeliveryConfig }>) => {
        store.wholesaleSettingsStore.setDeliveryConfigs([result.data.createMerchantWholesaleDeliveryConfig]);

        return result.data.createMerchantWholesaleDeliveryConfig;
      })
      .catch(error => {
        window.Sentry.captureException(error);

        return Promise.reject(error);
      });
  };
}

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { shadows, colors, textStyles, breakpoints } from 'utils/rebass-theme';

export const TileWrapper = styled.div<{ disabled: boolean }>`
  ${textStyles.title};

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: ${(props: any): any => props.disabled ? 'normal' : 'pointer'};
  color: ${(props: any): any => props.disabled ? colors.shade40 : colors.floomMidnightBlue};
  text-align: center;
  text-align: center;
  border-radius: 3px;
  width: 100%;
  padding: 40px 20px;
  margin: 0px 10px 20px 10px;
  background-color: ${colors.uiFills};
  box-shadow: ${shadows.small};

  @media (min-width: ${breakpoints.medium}) {
    width: calc(50% - 20px);
  }

  @media (min-width: ${breakpoints.large}) {
    width: calc(33.33% - 20px);
  }

  @media (min-width: ${breakpoints.xLarge}) {
    width: calc(25% - 20px);
  }
`;

export const TileIcon = css`
  width: 32px;
  height: 24px;
  margin-bottom: 12px;
`;

export const TileCopy = styled.p`
  width: 100%;
  text-align: center;
`;

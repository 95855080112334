import { FC } from 'react';

import RadioIcon from 'components/radio-icon';

import { RadioItemViewProps } from '../radio-item/radio-item.types';

import { StyledBorderItem, RadioIconContainer, RadioLabel } from './border-item.styles';

export const BorderItem: FC<RadioItemViewProps> = ({
  orientation,
  isSelected,
  isDisabled,
  title
}) => {
  return (
    <StyledBorderItem
      orientation={orientation}
      isSelected={isSelected}
    >
      <RadioIconContainer>
        <RadioIcon
          isSelected={isSelected}
          isDisabled={isDisabled}
        />
      </RadioIconContainer>
      <RadioLabel isDisabled={isDisabled}>
        {title}
      </RadioLabel>
    </StyledBorderItem>
  );
};

import { ReactNode } from 'react';

import { ApolloProvider } from '@apollo/client';
import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import { ConversationsClient } from 'lib';

import { ConversationsHome } from 'features/conversations/pages/conversations-home/conversations-home';
import { CreateIncomingMessage } from 'features/conversations/pages/create-incoming-message/create-incoming-message';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

export const Conversations = (): ReactNode => (
  <ApolloProvider client={ConversationsClient}>
    <AppLayout>
      <Helmet>
        <title>Conversations</title>
      </Helmet>
      <Router>
        <AuthedRoute
          path="/conversations"
          component={ConversationsHome}
        />
        <AuthedRoute
          path="/conversations/:contactId"
          component={ConversationsHome}
        />
        <AuthedRoute
          path="/conversations/create-incoming-message"
          component={CreateIncomingMessage}
        />
      </Router>
    </AppLayout>
  </ApolloProvider>
);

export default Conversations;

import React, { ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Analytics } from 'lib';

import { Mode } from 'stores/add-on-edit-store/add-on-edit-store.types';

import { Container } from 'utils/css-mixins';

import CreateLayout from 'features/add-ons/components/create-layout/create-layout';
import AddOnsService from 'features/add-ons/services';

import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import * as Types from './add-on-create.types';

class AddOnCreate extends React.Component<Types.AddOnCreateProps> {
  componentDidMount = (): void => {
    this.trackPageView();
    AddOnsService.AddOnsCreateEditService.init(this.props.addOnId);
  };

  componentWillUnmount = (): void => {
    this.props.addOnEditStore!.init();
  };

  trackPageView = (): void => {
    const pageName = this.props.path.includes('edit') ? Analytics.FxPageEvent.AddonsEdit : Analytics.FxPageEvent.AddonsCreate;

    Analytics.page(pageName);
  };

  render(): JSX.Element {
    const { isLoadingAddon, currentMode, id } = this.props.addOnEditStore!;

    return (
      <div>
        <WithLoading
          loaderSize="small"
          marginTop="30px"
          hasNoResults={!isLoadingAddon && !id && currentMode === Mode.edit}
          isLoading={isLoadingAddon}
          renderNoResults={(): ReactNode => {
            return (
              <Container maxWidth={790}>
                <Box mt="70px">
                  <NoResultsGeneric
                    icon="leaf-no-results"
                    heading="Sorry, we couldn't find that add-on"
                  />
                </Box>
              </Container>
            );
          }}
        >
          <CreateLayout {...this.props} />
        </WithLoading>
      </div>
    );
  }
}

export default inject('addOnEditStore', 'addOnsStore')(observer(AddOnCreate));

import React, { FC, Fragment, ReactNode  } from 'react';

import { css } from '@emotion/react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box, Flex } from 'rebass';

import { Currency } from 'generated-types.d';

import { CurrencyService } from 'lib';

import { colors } from 'utils/rebass-theme';

import { ListPriceRange } from 'features/lists/components/list-price-range/list-price-range';
import { getListCurrency } from 'features/lists/lists.helpers';
import {
  GrandTotalRow,
  TotalRow,
  TotalsTable,
  TotalValueColumn,
  FooterWrapper,
  TotalSubCopy,
  TotalToBeCalculatedColumn
} from 'features/lists/lists.styles';

import Button from 'components/button';
import Icon from 'components/icon';

import {
  ButtonWrapper,
  PreOrderButton,
  DeliveryDate,
  OrderingRulesWrapper,
  DeliveryDateWrapper,
  PlaceOrderWrapper
} from './checkout-list-footer.styles';
import {
  CheckoutListFooterProps
} from './checkout-list-footer.types';

const CheckoutListFooterComponent: FC<CheckoutListFooterProps> = ({
  group,
  isPlacingPreOrder,
  hasPlacedPreOrder,
  deliveryDate,
  onSelectDeliveryDate,
  onPlacePreOrder,
  checkoutListStore,
  merchantStore,
  modalStore
}) => {
  if (
    !checkoutListStore!.list ||
    !group.hasTotalSummary ||
    !group.totalPrice
  ) return null;

  const supplierDeliveryOption = checkoutListStore?.list?.supplierDeliveryConfigs?.[0]?.deliveryOptions?.[0];
  const freeShippingThreshold = supplierDeliveryOption?.freeShippingThreshold;

  const renderDeliveryDate = (): string => {
    switch (true) {
      case !!deliveryDate && !checkoutListStore!.isLoadingDeliveryDates: {
        return moment(checkoutListStore!.deliveryDate).format('Do MMMM');
      }

      case !checkoutListStore!.list?.suppliers?.length: {
        return 'Select a supplier';
      }

      default: {
        return 'Please select';
      }
    }
  };

  const listCurrency = getListCurrency(checkoutListStore!.list)!;

  const renderShippingCost = (): string => {
    switch (true) {
      case (group.totalShipping === null) :
        return 'Select a supplier';

      case (group.totalShipping || 0) > 0:
        return `${CurrencyService.formatPrice(group.totalShipping!, listCurrency)}`;

      default:
        return 'Free';
    }
  };

  const triggerOrderingRulesSidebar = (): void => {
    modalStore!.triggerModal({
      modalType: 'orderingRulesSidebar'
    });
  };

  const renderShippingAndTaxRows = (): ReactNode => {
    if (merchantStore?.merchant?.currency === Currency.Usd) {
      return (
        <Fragment>
          <TotalRow>
            <Box>Shipping</Box>
            <TotalToBeCalculatedColumn>
              To be calculated
            </TotalToBeCalculatedColumn>
          </TotalRow>
          <TotalRow>
            <Box>Sales Tax</Box>
            <TotalToBeCalculatedColumn>
              To be calculated
            </TotalToBeCalculatedColumn>
          </TotalRow>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <TotalRow>
          <Box>
            Est. Shipping
          </Box>
          <TotalValueColumn>
            <Box>
              {renderShippingCost()}
            </Box>
            {!!freeShippingThreshold && (
              <TotalSubCopy>
                Spend over {CurrencyService.formatPrice(freeShippingThreshold, listCurrency)} for free shipping
              </TotalSubCopy>
            )}
          </TotalValueColumn>
        </TotalRow>
        <TotalRow>
          <Box>
            VAT
          </Box>
          <TotalValueColumn>
            <ListPriceRange
              min={group.salesTax?.min}
              max={group.salesTax?.max}
              currency={listCurrency}
            />
          </TotalValueColumn>
        </TotalRow>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <TotalsTable
        css={css`
          opacity: ${checkoutListStore!.isCheckingAvailability ? .4 : 1}
        `}
      >
        <TotalRow>
          <Box fontWeight={600}>
            Subtotal
          </Box>
          <TotalValueColumn>
            <Box fontWeight={600}>
              <ListPriceRange
                min={group.totalPrice.min}
                max={group.totalPrice.max}
                currency={listCurrency}
              />
            </Box>
            <TotalSubCopy>
              {group.totalQuantity} Units
            </TotalSubCopy>
          </TotalValueColumn>
        </TotalRow>
        {renderShippingAndTaxRows()}
      </TotalsTable>
      <GrandTotalRow>
        <Box fontWeight={600}>
          Total
        </Box>
        <TotalValueColumn>
          <Box fontWeight={600}>
            <ListPriceRange
              min={group.grandTotal?.min}
              max={group.grandTotal?.max}
              currency={listCurrency}
            />
          </Box>
        </TotalValueColumn>
      </GrandTotalRow>
      <FooterWrapper>
        <OrderingRulesWrapper
          as="button"
          onClick={triggerOrderingRulesSidebar}
        >
          <Flex>
            <Icon
              pathFill={colors.floomMidnightBlue}
              iconName="settings"
              size="normal"
              styles={{
                marginRight: '10px'
              }}
            />
            <Box fontSize={16}>
              Ordering rules
            </Box>
          </Flex>
        </OrderingRulesWrapper>
        <DeliveryDateWrapper
          as="button"
          disabled={!checkoutListStore!.list?.suppliers?.length || isPlacingPreOrder || hasPlacedPreOrder}
          onClick={onSelectDeliveryDate}
        >
          <ButtonWrapper
            backgroundColor={colors.white}
            color={colors.floomMidnightBlue}
            isDisabled={!checkoutListStore!.list?.suppliers?.length || isPlacingPreOrder || hasPlacedPreOrder}
          >
            <PreOrderButton>
              Delivery date
              <DeliveryDate>
                {renderDeliveryDate()}
              </DeliveryDate>
            </PreOrderButton>
          </ButtonWrapper>
        </DeliveryDateWrapper>
        <PlaceOrderWrapper
          as="button"
          disabled={!checkoutListStore!.list?.suppliers?.length || isPlacingPreOrder || hasPlacedPreOrder}
          onClick={onPlacePreOrder}
        >
          <Button
            size="large"
            isParentWidth={true}
            copy={hasPlacedPreOrder ? 'Pre-order placed' : 'Place pre-order'}
            appearance={hasPlacedPreOrder ? 'success' : 'primary'}
            isDisabled={!checkoutListStore!.list?.suppliers?.length || isPlacingPreOrder || !deliveryDate || checkoutListStore!.isLoadingDeliveryDates}
            isLoading={isPlacingPreOrder}
          />
        </PlaceOrderWrapper>
      </FooterWrapper>
    </Fragment>
  );
};

export const CheckoutListFooter = inject((stores: FxStores): InjectedFxStores => ({
  checkoutListStore: stores.checkoutListStore,
  modalStore: stores.modalStore,
  merchantStore: stores.merchantStore
}))(observer(CheckoutListFooterComponent));

import React, { Component, ReactNode, ChangeEvent, KeyboardEvent } from 'react';

import debounce from 'lodash.debounce';

import Button from 'components/button';
import FieldText from 'components/field-text';

import { TEST_IDS } from '../../utils/test/data-test-ids';

import FieldSearchItem from './field-search-item';
import * as Styles from './field-search.styles';
import * as Types from './field-search.types';

class FieldSearch extends Component<Types.FieldSearchProps, Types.FieldSearchState> {
  static defaultProps = {
    onClear: (): any => null,
    onResultSelect: (): any => null,
    placeholder: 'Search',
    shouldDisplayResults: false,
    parentWidth: false,
    customCss: null,
    delay: 300,
    autoFocus: false
  };

  previousItemsCount = 0;

  state = {
    isOpen: false,
    isDelayed: false
  };

  delaySearch = debounce(() => {
    this.search();
    this.setState({ isDelayed: false });
  }, this.props.delay);

  getSnapshotBeforeUpdate(prevProps: Types.FieldSearchProps): null {
    if (prevProps.results) {
      this.previousItemsCount = prevProps.results.length;
    }

    if (this.props.value !== prevProps.value) {
      if (this.props.value.length) {
        this.setState({ isDelayed: true });
        this.delaySearch();
      } else {
        this.props?.onClear?.();
      }
    }

    return null;
  }

  componentDidUpdate(): void {}

  private search = (): void => {
    if (this.props.value.length > 1) this.props.onSearch(this.props.value);
  };

  private onItemSelect = (id: string): void => {
    // @ts-ignore
    this.props.inputRef?.current?.focus?.();
    this.props?.onResultSelect?.(id);
  };

  private onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.props.onChange(event.target.value);
  };

  private onKeyDown = ({ which }: KeyboardEvent<HTMLInputElement>): void => {
    if (this.isReturnKey(which)) {
      this.delaySearch.flush();
    }
  };

  private toggle = (): void => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  private isReturnKey = (which: number): boolean => which === 13;

  private hasContent = (): boolean => !!this.props.results && !!this.props.results.length && this.state.isOpen;

  render(): ReactNode {
    const hasNoResults = !!this.props.value && !this.props.isLoading && !this.state.isDelayed && this.props.displayNoResults && this.state.isOpen;
    const hasResults = this.hasContent();

    return(
      <Styles.FieldSearch
        isSearching={hasNoResults || hasResults}
        aria-haspopup="listbox"
        aria-owns="field-search"
        aria-expanded={hasNoResults || hasResults}
        parentWidth={this.props.parentWidth}
      >
        <FieldText
          type="search"
          placeholder={this.props.placeholder}
          autoFocus={this.props.autoFocus}
          value={this.props.value}
          onFocus={this.toggle}
          onBlur={this.toggle}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          iconType="search"
          autoComplete="off"
          aria-autocomplete="list"
          aria-controls="field-search"
          aria-haspopup={true}
          parentWidth={this.props.parentWidth}
          customCss={this.props.customCss}
          innerRef={this.props.inputRef}
          data-testid={TEST_IDS.FieldSearch.fieldText}
        />
        <div
          role="listbox"
          id="field-search"
        >
          { hasResults ?
            (
              <Styles.Results as="ul">
                {this.props.results!.map(({ node }: Types.SearchResultItem) => (
                  <FieldSearchItem
                    key={node.id}
                    data={node}
                    onMouseDown={this.onItemSelect}
                    data-testid={TEST_IDS.FieldSearch.searchResult}
                  />
                ))}
                { this.props.results!.length >= 20 && (
                  <Styles.ViewMoreButton
                    onMouseDown={this.props.onLoadMore}
                  >
                    <Button
                      copy="View more"
                      appearance="secondary"
                    />
                  </Styles.ViewMoreButton>
                )}
              </Styles.Results>
            )
            : hasNoResults ? (
              <Styles.Results as="ul">
                <Styles.NoResults>
                  No results
                </Styles.NoResults>
              </Styles.Results>
            )
              : null
          }
        </div>
      </Styles.FieldSearch>
    );
  }
}

export default React.forwardRef((props: Types.FieldSearchProps, ref: React.Ref<any>) => (
  <FieldSearch
    inputRef={ref}
    {...props}
  />
));

import { FC } from 'react';

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import Icon from 'components/icon';
import { IconType } from 'components/icon/icon.types';
import Tooltip from 'components/tooltip';

const IconBorder = styled(Box)`
  padding: 8px 10px;
  border-radius: 5px;
  background: ${colors.paleGrey};
  cursor: pointer;
`;

const PaymentColumnWrapper: any = styled(Flex)`
  align-items: center;

  + ${() => PaymentColumnWrapper} {
    margin-top: 10px;
  }
`;

export const FooterPaymentColumn: FC<{
  iconName: IconType;
  tooltipMessage: string;
  shouldHide?: boolean;
}> = ({
  children,
  iconName,
  tooltipMessage,
  shouldHide
}) => {
  if (!!shouldHide) return null;

  return (
    <PaymentColumnWrapper>
      <Tooltip
        message={tooltipMessage}
      >
        <IconBorder>
          <Icon
            iconName={iconName}
            pathFill={colors.floomBlue}
          />
        </IconBorder>
      </Tooltip>
      <Box
        ml="10px"
        css={{
          lineHeight: 1.4
        }}
      >
        {children}
      </Box>
    </PaymentColumnWrapper>
  );
};

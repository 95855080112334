import gql from 'graphql-tag';

import { GraphQLHelpers } from 'lib';

import {
  WHOLESALE_PRE_ORDER_DASHBOARD_FRAGMENT,
  WHOLESALE_PRE_ORDER_FRAGMENT,
  WHOLESALE_PRE_ORDER_TOTALS_FRAGMENT
} from 'features/wholesale/fragments/pre-order/pre-order-fragments';
import { ORDER_LIST_ITERATION_COUNT } from 'features/wholesale/wholesale.constants';

export const SINGLE_WHOLESALE_PRE_ORDER = gql`
  query SingleWholesalePreOrder(
    $where: WholesalePreOrderWhereUniqueInput!
  ) {
    wholesalePreOrder(
      where: $where
    ) {
      ...WholesalePreOrderFragment
    }
  }
  ${WHOLESALE_PRE_ORDER_FRAGMENT}
`;

export const SINGLE_WHOLESALE_PRE_ORDER_TOTALS = gql`
  query SingleWholesalePreOrderTotals(
    $where: WholesalePreOrderWhereUniqueInput!
  ) {
    wholesalePreOrder(
      where: $where
    ) {
      ...WholesalePreOrderTotalsFragment
    }
  }
  ${WHOLESALE_PRE_ORDER_TOTALS_FRAGMENT}
`;

export const WHOLESALE_PRE_ORDER_PAGINATION = (merchantId: any): any => {
  return gql`
    query WholesalePreOrders(
      ${GraphQLHelpers.addVariable(merchantId, 'merchantId', 'ID!')}
      $skip: Int
      $orderState: WholesalePreOrderStatus
    ) {
    ${`
        wholesalePreOrdersConnection(
          first: ${ORDER_LIST_ITERATION_COUNT},
          orderBy: createdAt_DESC
          skip: $skip
          where: {
            ${GraphQLHelpers.addMerchantWhereFilter(merchantId)}
            status: $orderState
          }
        ) {
          pageInfo {
            hasNextPage
          }
          aggregate {
            count
          }
          edges {
            node {
              ...WholesalePreOrderFragment
            }
          }
        }
      `}
    }
    ${WHOLESALE_PRE_ORDER_FRAGMENT}
  `;
};

export const WHOLESALE_PRE_ORDERS = gql`
  query WholesalePreOrders(
    $where: WholesalePreOrderWhereInput,
    $orderBy: WholesalePreOrderOrderByInput,
    $skip: Int,
    $first: Int
  ) {
    wholesalePreOrdersConnection(
      where: $where,
      orderBy: $orderBy,
      skip: $skip,
      first: $first
    ) {
      pageInfo {
        hasNextPage
      }
      aggregate {
        count
      }
      edges {
        node {
          ...WholesalePreOrderDashboardFragment
        }
      }
    }
  }
  ${WHOLESALE_PRE_ORDER_DASHBOARD_FRAGMENT}
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, fontSizes, space } from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  position: relative;
  flex-grow: 1;
  text-align: left;
  cursor: ${({ isLink }) => isLink ? 'pointer' : 'default'};
`;

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${space[1]}px
`;

export const Title = styled.h3<{ isLink: boolean }>`
  font-size: ${fontSizes.base}px;
  color: ${({ isLink }) => isLink ? colors.floomMidnightBlue : colors.black};
  text-decoration: ${({ isLink }) => isLink ? 'underline' : 'none'};
`;

export const ExpectedDate = styled(Box)`
  color: ${colors.grey};
  font-size: ${fontSizes.xSmall}px;
`;

export const QuantitySummary = styled(Box)`
  color: ${colors.black};
  font-size: ${fontSizes.xSmall}px;
`;

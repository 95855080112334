import parsePhoneNumber, { CountryCode, validatePhoneNumberLength } from 'libphonenumber-js';
import * as Yup from 'yup';

interface PhoneValidatorParams extends Yup.TestMessageParams {
  countryCode: string;
}

const setupPhoneNumber = (): void => {
  Yup.addMethod(Yup.string, 'phone', function({ label, path, countryCode }: Partial<PhoneValidatorParams>): Yup.Schema<any> {
    return this.test('phone', label!, function(value: string): boolean | Yup.ValidationError {
      if (!value) return true;

      const parsedPhone = parsePhoneNumber(value, countryCode as CountryCode);

      if (!parsedPhone || !parsedPhone.isValid()) {
        const parsingError = validatePhoneNumberLength(value, countryCode as CountryCode);
        const errorMessage = getErrorText(parsingError);

        return this.createError({
          path: path,
          // @ts-ignore
          message: {
            path: path,
            label: errorMessage
          }
        });
      }

      return true;
    });
  });
};

setupPhoneNumber();

export const phoneString = (key: string, countryCode: string): Yup.Schema<any>  => {
  return Yup
    .string()
    .nullable(false)
    .trim()
    // @ts-ignore
    .phone({
      path: key,
      countryCode: countryCode
    })
    .transform((value: string) => {
      return formatPhone(value, countryCode as CountryCode);
    });
};

const formatPhone = (value: string, countryCode?: CountryCode): string  => {
  const parsedNumber = parsePhoneNumber(value, countryCode);

  return parsedNumber ? parsedNumber.getURI().replace('tel:', '') : value;
};

const getErrorText = (err: string | undefined): string => {
  switch(err) {
    case 'INVALID_COUNTRY': {
      return 'Invalid country code';
    }

    case 'TOO_SHORT':  {
      return 'Phone number is too short';
    }

    case 'TOO_LONG':  {
      return 'Phone number is too long';
    }

    case 'INVALID_LENGTH': {
      return 'Phone number length is invalid';
    }

    case 'NOT_A_NUMBER': {
      return 'Must be a valid phone number';
    }

    default: {
      return 'Must be a valid phone number';
    }
  }
};

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import moment from 'moment';
import { Text, Flex, Box } from 'rebass';

import { ActivityToggle } from 'utils/css-mixins/css-mixins';
import FloomXRestrictedComponent from 'utils/floomx-types/floom-x-restricted-component';
import { textStyles } from 'utils/rebass-theme';

import CheckboxIcon from 'components/checkbox-icon';
import CheckboxToggle from 'components/checkbox-toggle';
import FieldDatePicker from 'components/field-date-picker';
import FieldText from 'components/field-text';
import FieldTextArea from 'components/field-text-area';
import Icon from 'components/icon';
import ImgixImage from 'components/imgix-image';
import RadioIcon from 'components/radio-icon';
import StyledLink from 'components/styled-link/styled-link';
import Tooltip from 'components/tooltip';

import {
  EntityListTableItem,
  FieldLabel,
  InputWrapper,
  InputSaveButton,
  PlaceholderImage,
  DeleteCross
} from './entity-list.styles';
import * as Styles from './table-layout-entity.styles';
import * as Types from './table-layout-entity.types';

export default class TableLayoutEntity extends Component<Types.TableLayoutEntityProps> {
  private renderImage = (mediaSource: string, size: number, hasMultiple: boolean): ReactNode => {
    const imgSize = size || 59;
    const props = {
      height: imgSize,
      width: imgSize,
      css: css`
        ${Styles.EntityItemImageStyles}
        height: ${imgSize}px;
        width: ${imgSize}px;
      `
    };

    return (
      <Styles.EntityItemImageContainer
        height={imgSize}
        width={imgSize}
        hasMultiple={hasMultiple}
      >
        {(mediaSource.toLowerCase().includes('http')) ? (
          <img
            src={mediaSource}
            {...props}
          />
        )
          : (
            <ImgixImage
              config={{
                path: mediaSource
              }}
              {...props}
            />
          )}
      </Styles.EntityItemImageContainer>
    );
  };

  private renderCell = (cell: Types.TableLayoutEntityConfigProps): JSX.Element | undefined => {
    switch (cell.cellType) {
      case Types.CellType.Custom:
        return (
          <>{cell.customInnerElement}</>
        );

      case Types.CellType.LinkTitle:
        return (
          <>
            <div onClick={cell.props.linkTitle.onClick}>
              <StyledLink>{cell.props.linkTitle.text}</StyledLink>
            </div>
            {cell.props.subtitle && (
              <Box
                mt="5px"
              >
                {cell.props.subtitle}
              </Box>
            )}
          </>
        );

      case Types.CellType.Text:
        return (
          <>
            { cell.props.title ? (
              <>
                <h5 title={cell.props.title}>{cell.props.title}</h5>
                <div title={cell.props.subtitle}>{cell.props.subtitle}</div>
              </>
            )
              :
              cell.props.text
            }
            {(!!cell.props.extraSubtitles && !!cell.props.extraSubtitles.length) &&
              cell.props.extraSubtitles.map((subtitle: string, key: any) => {
                return (
                  <div key={key}>{subtitle}</div>
                );
              })
            }
          </>
        );

      case Types.CellType.Textarea:
        return (
          <InputWrapper width={cell.props.inputWidth}>
            <FieldTextArea
              value={cell.props.value}
              onChange={cell.props.onChange}
              rows={cell.props.rows}
              disabled={cell.props.isDisabled}
            />
          </InputWrapper>
        );

      case Types.CellType.CheckboxToggle:
        return (
          <div>
            <Tooltip
              message={cell?.props.tooltipMessage}
              tooltipStyles={css`
                width: 200px;
              `}
              styles={css`
                display: flex;
              `}
            >
              <CheckboxToggle
                onChange={cell?.props.onChange}
                switchBgActive={cell?.props.switchBgActive}
                switchBgInactive={cell?.props.switchBgInactive}
                activeCopy={cell?.props.switchActiveCopy || 'On'}
                inactiveCopy={cell?.props.switchInactiveCopy || 'Off'}
                controlValue={true}
                isDisabled={cell?.props.isDisabled}
                isChecked={cell?.props.isChecked}
              />
              { !!cell?.props.extraCopy && (
                <Box
                  css={css`
                    ${textStyles.body}
                    font-size: 12px
                  `}
                  mt="7px"
                >
                  {cell?.props.extraCopy}
                </Box>
              )}
            </Tooltip>
          </div>
        );

      case Types.CellType.CheckboxIcon:
        return (
          <div css={css` height: 19px; `}>
            <CheckboxIcon
              isSelected={!!cell?.props?.isSelected}
              isDisabled={!!cell?.props?.isDisabled}
            />
          </div>
        );

      case Types.CellType.RadioIcon:
        return (
          <div css={css` height: 20px; `}>
            <RadioIcon
              isSelected={!!cell?.props?.isSelected}
              isDisabled={!!cell?.props?.isDisabled}
            />
          </div>
        );

      case Types.CellType.Image:
        return (
          <>
            {(!!cell.props.path && !!cell.props.path.length) ?
              this.renderImage(cell.props.path, cell.props.size, cell.props.hasMultiple)
              :
              <PlaceholderImage size={cell.props.size} />
            }
          </>
        );

      case Types.CellType.Input:
        return (
          <>
            {cell.props.prefix && (
              <FieldLabel>
                {cell.props.prefix}
              </FieldLabel>
            )}
            <InputWrapper width={cell.props.inputWidth}>
              <FieldText
                min={0}
                size="small"
                type={cell.props.type}
                value={cell.props.value}
                onChange={cell.props.onChange}
                id={`input-${cell.props.uniqueId}`}
                isDisabled={cell.props.isSaving}
              />
            </InputWrapper>
            {!cell.props.hideSave && (
              <InputSaveButton
                as="button"
                disabled={cell.props.saveDisabled}
                css={textStyles.subhead}
                onClick={cell.props.onInputSave}
                ml="14px"
                id={`save-${cell.props.uniqueId}`}
                isSaving={cell.props.isSaving}
              >
                Sav{cell.props.isSaving ? 'ing' : 'e'}
              </InputSaveButton>
            )}
          </>
        );

      case Types.CellType.DatePicker:
        return (
          <Flex alignItems="center">
            {cell?.props?.label && (
              <Text mr="20px">{cell.props.label}</Text>
            )}
            <Box width="115px">
              <FieldDatePicker
                format="L"
                pickerType="tooltip"
                inputType="input-compact"
                value={cell.props.value}
                isDisabled={cell.props.isSaving}
                onChange={(day: Date): void => {
                  cell.props.onChange(moment(day).toISOString());
                }}
                sendRefForward={(ref): void => {
                  if (cell.props.getInputRef) {
                    cell.props.getInputRef(ref);
                  }
                }}
                disabledDays={{
                  before: new Date(),
                  ...(cell.props.disabledDays || {})
                }}
              />
            </Box>
          </Flex>
        );

      case Types.CellType.Cross:
        return (
          <>
            <DeleteCross
              onClick={cell.props.onClick}
            >
              <Icon iconName="cross-small" />
            </DeleteCross>
          </>
        );

      case Types.CellType.IndicatorPanel:
        return cell.props.items.map((item: any, index: number): any => {
          return (
            <Styles.IndicatorPanelItem
              alignItems="center"
              key={index}
            >
              <ActivityToggle isActive={!!item.value} />
              <Box ml="10px">
                {item.title}
              </Box>
            </Styles.IndicatorPanelItem>
          );
        });
    }
  };

  private buildRow = (cells: Types.TableLayoutEntityConfigProps[]): React.ReactNode => {
    return cells
      .filter(cell => cell.isVisible === undefined || cell.isVisible)
      .map((cell, i: number) => {
        if (cell.restrictedRoles) {
          return (
            <FloomXRestrictedComponent
              key={i}
              restrictedRoles={cell.restrictedRoles}
              considerSuperRole={true}
            >
              <EntityListTableItem
                fixedWidth={cell.fixedWidth}
                flexGrow={cell.flexGrow}
                flexBasis={cell.flexBasis}
                flexShrink={cell.flexShrink}
                css={cell.customCss}
              >
                {this.renderCell(cell)}
              </EntityListTableItem>
            </FloomXRestrictedComponent>
          );
        }

        return (
          <EntityListTableItem
            fixedWidth={cell.fixedWidth}
            flexGrow={cell.flexGrow}
            flexBasis={cell.flexBasis}
            flexShrink={cell.flexShrink}
            css={cell.customCss}
            key={i}
          >
            {this.renderCell(cell)}
          </EntityListTableItem>
        );
      });
  };

  render(): React.ReactNode {
    return this.buildRow(this.props.config);
  }
}

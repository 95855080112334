import { css } from '@emotion/react';

export const GlobalLightboxStyles = css`
  .lightboxImg {
    background-color: unset;
    margin: auto;
    position: relative;
    overflow: hidden;
  }

  .lightboxImg .ril-image-current {
    width: auto;
    height: auto;
  }

  .lightboxImg .ril-image-prev, .lightboxImg .ril-image-next {
    display: none;
  }

  .ril-toolbar {
    background-color: unset;
  }

  .ril-toolbar .ril-toolbar-right {
    height: 60px;
    padding: 0 10px 10px;
    background-color: rgba(0, 0, 0, .3);
  }
`;

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Text, Flex } from 'rebass';

import { CurrencyService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import { StripeSubmitElementTypes } from 'features/payment/payment.types';

import Button from 'components/button';

import * as Styles from './stripe-submit-element.styles';

const SHARED_COPY = `
  My payment method on file will be charged automatically until I cancel.
  I can cancel anytime online through my account or by contacting Customer
  Support on support@floom.com. If I cancel,
  I won’t receive a refund for fees already paid.
`;

const SMALL_PRINT_MARKUP = 'I will pay [UPGRADE_PLAN_PRICE] today and each subsequent month thereafter.';
const SMALL_PRINT_MARKUP_TRIAL = 'I will pay [UPGRADE_PLAN_PRICE] on [TRIAL_ENDS_AT] and each subsequent month thereafter.';

class StripeSubmitElement extends Component<StripeSubmitElementTypes> {
  static defaultProps: Partial<StripeSubmitElementTypes> = {
    isFormInternalFacing: false,
    shouldDisplayTerms: false,
    buttonCopy: 'Update payment method'
  };

  private renderTermsCopy = (): ReactNode => {
    const trialLength = this.props.merchant?.subscription?.trialLength;
    const subscriptionCost = this.props.merchant?.subscription?.stripeSubscriptionPrice;
    const currency = this.props.merchant?.plan?.currency;
    const formattedSubscriptionCost = !!subscriptionCost && currency ? CurrencyService.formatPrice(subscriptionCost, currency) : '-';

    switch (true) {
      case !!trialLength && trialLength > 0: {
        const trialEndsAt = moment().add(trialLength, 'days').format('Do MMMM gggg');

        const copy = SMALL_PRINT_MARKUP_TRIAL
          .replace('[UPGRADE_PLAN_PRICE]', formattedSubscriptionCost)
          .replace('[TRIAL_ENDS_AT]', trialEndsAt);

        return `${copy} ${SHARED_COPY}`;
      }

      default: {
        const copy = SMALL_PRINT_MARKUP
          .replace('[UPGRADE_PLAN_PRICE]', formattedSubscriptionCost);

        return `${copy} ${SHARED_COPY}`;
      }
    }
  };

  private renderSubmitButton = (): ReactNode => {
    if (!this.props.isModal) return null;

    return (
      <Styles.TermsAndConditionsModal>
        <Flex
          mb={['20px', '0']}
          alignItems="center"
        />
        <Styles.ButtonContinue onClick={this.props.onSubmit}>
          <Button
            size="normal"
            copy={this.props.buttonCopy || ''}
            appearance="primary"
            isDisabled={!this.props.isFormValid()}
            isLoading={this.props.isSubmitting}
          />
        </Styles.ButtonContinue>
      </Styles.TermsAndConditionsModal>
    );
  };

  private renderInternalTerms = (): ReactNode => {
    if (!this.props.shouldDisplayTerms) return null;

    return (
      <Styles.TermsAndConditions>
        { this.props.isFormInternalFacing && (
          <Text
            css={textStyles.h3}
            mb="20px"
          >
            Taking card details over the phone? please read this to the customer before confirming
          </Text>
        )}
        <Text css={textStyles.subhead}>
          Offer terms: <span>{this.renderTermsCopy()}</span>
        </Text>
      </Styles.TermsAndConditions>
    );
  };

  render(): ReactNode {
    return (
      <>
        {this.renderInternalTerms()}
        {this.renderSubmitButton()}
      </>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore,
  userStore: stores.userStore,
  paymentOnboardingStore: stores.paymentOnboardingStore
}))(observer(StripeSubmitElement));

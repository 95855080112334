import React, { ReactNode } from 'react';

import { textStyles } from 'utils/rebass-theme';

import CheckboxIcon from 'components/checkbox-icon';
import Tooltip from 'components/tooltip';

import * as Config from './delivery-config-card.config';
import * as Styles from './delivery-config-card.styles';
import * as Types from './delivery-config-card.types';

const DeliveryConfigCard = (props: Types.DeliveryConfigCardProps): JSX.Element => {
  const isSelected = (): boolean => props.isSelectable && props.isSelected;

  const isSelectable = (): boolean => props.isSelectable;

  const isDisabled = (): boolean => props.isDisabled;

  const renderCheckbox = (): ReactNode => {
    if (!isSelectable()) return null;

    return (
      <Styles.CardCheckbox>
        <CheckboxIcon
          isSelected={isSelected()}
          isDisabled={isDisabled()}
        />
      </Styles.CardCheckbox>
    );
  };

  const onClick = (): void => {
    if (!isDisabled()) {
      props.onClick(props.deliveryConfig);
    }
  };

  return (
    <Styles.CardContainer
      isSelected={isSelected()}
      isDisabled={isDisabled()}
    >
      <Styles.CardWrapper
        onClick={onClick}
        isDisabled={isDisabled()}
      >
        <Styles.Heading
          css={textStyles.footnote}
          width="100%"
          role="rowheader"
        >
          {renderCheckbox()}
          <Styles.HeadingInner>
            <Tooltip
              message={props.deliveryConfig.title}
              styles={Styles.HeadingTooltip}
            >
              {props.deliveryConfig.title}
            </Tooltip>
          </Styles.HeadingInner>
        </Styles.Heading>
        <Styles.DeliveryMeta>
          {
            Config.deliveryMetaItems.map((item: Config.DeliveryMetaItem, index) => {
              const { component: MetaComponent } = item;

              return (
                <MetaComponent
                  key={index}
                  data={props.deliveryConfig}
                />
              );
            })
          }
        </Styles.DeliveryMeta>
      </Styles.CardWrapper>
    </Styles.CardContainer>
  );
};

DeliveryConfigCard.defaultProps = {
  isSelectable: false,
  isSelected: false,
  isDisabled: false
};

export default DeliveryConfigCard;

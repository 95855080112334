import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { NavService } from 'lib';

import DiscountList from 'features/settings/components/discounts/discount-list/discount-list';
import DiscountsService from 'features/settings/components/discounts/services';
import SettingsTemplatePage from 'features/settings/components/template/settings-template-page';

import * as Types from './discounts.types';

class Discounts extends Component<Types.DiscountsProps> {
  componentDidMount = (): void => {
    this.fetchDiscounts();
  };

  componentWillUnmount = (): void => {
    this.props.discountsStore!.resetDiscountList();
  };

  private fetchDiscounts = (): void => {
    DiscountsService.fetchDiscounts();
  };

  private handleCreate = (): void => {
    NavService.discountCreateEdit('create');
  };

  render(): JSX.Element {
    return (
      <SettingsTemplatePage
        title="Discounts"
        headerButtonCopy="Create discount"
        onHeaderAction={this.handleCreate}
      >
        <DiscountList />
      </SettingsTemplatePage>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  userStore: stores.userStore,
  toasterStore: stores.toasterStore,
  discountsStore: stores.discountsStore
}))(observer(Discounts));

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { EntitySelectorModalData } from 'features/modal-dialogue/components/modals/entity-selector-modal/entity-selector.types';

import WholesaleUnitField from 'components/wholesale-unit-field';

import * as Styles from './create-component-chooser.styles';
import * as Types from './create-component-chooser.types';
import CreateComponentSelectArray from './create-component-select-array';

class CreateComponentChooser extends Component<Types.CreateComponentChooserProps> {
  state = {
    units: ''
  };

  private openModal = (): void => {
    this.props.modalStore!.triggerModal<EntitySelectorModalData>({
      modalType: 'entitySelector',
      data: {
        allItems: this.props.productEditStore!.componentConfigs,
        initialSelectedIDs: this.props.variation.selectedComponentConfigs.map(item => item.id),
        selectItems: async (ids): Promise<void> => this.props.variation.selectComponent(ids[0], parseFloat(this.state.units)),
        canConfirm: (): boolean => !!this.state.units && parseFloat(this.state.units) > 0,
        renderExtraAction: (modalInstance): JSX.Element => (
          <WholesaleUnitField
            unit={this.state.units}
            handleChange={(units): void => {
              this.setState({ units });

              modalInstance.forceUpdate();
            }}
          />
        ),
        modalProps: {
          confirmButtonText: 'Add stem',
          title: 'Select a stem'
        }
      }
    });
  };

  render(): JSX.Element {
    return (
      <Styles.Content>
        <CreateComponentSelectArray
          defaultTab={this.props.defaultTab}
          selectedComponentConfigs={this.props.variation.selectedComponentConfigs}
          openModal={this.openModal}
          deselectComponent={this.props.variation.deselectComponent}
          updateComponent={this.props.variation.updateComponent}
          isReadOnly={this.props.isReadOnly}
        />
      </Styles.Content>
    );
  }
}

export default inject('modalStore', 'productEditStore')(observer(CreateComponentChooser));

import React, { Component } from 'react';

import Loader from 'components/loader';

import * as Styles from './loading-animation.styles';
import { LoadingAnimationProps } from './loading-animation.types';

export default class LoadingAnimation extends Component<LoadingAnimationProps> {
  render(): React.ReactNode {
    if (this.props.errorMessage) {
      return (
        <Styles.ErrorState>
          <p>{this.props.errorMessage}</p>
        </Styles.ErrorState>
      );
    }

    return (
      <Styles.LoadingLogo uploading={this.props.uploading}>
        <Loader />
      </Styles.LoadingLogo>
    );
  }
}

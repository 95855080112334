import { Analytics, PermissionsService } from 'lib';

export default class SettingsAnalytics {
  public onSectionView = (
    section: Analytics.SettingsSection,
    entryPoint: Analytics.SettingsEntryPoint = Analytics.SettingsEntryPoint.WebOverview
  ): void => {
    Analytics.track(Analytics.FxEventName.SettingsViewSection, {
      event_version: 1,
      in_app: false,
      entry_point: entryPoint,
      section: section
    });
  };

  public onOverview = (entryPoint: Analytics.SettingsOverviewEntry = Analytics.SettingsOverviewEntry.WebNav): void => {
    Analytics.track(Analytics.FxEventName.SettingsOverview, {
      event_version: 1,
      in_app: false,
      entry_point: entryPoint
    });
  };

  public onMerchantHolidays = (entryPoint: Analytics.SettingsOverviewEntry = Analytics.SettingsOverviewEntry.WebNav): void => {
    Analytics.track(Analytics.FxEventName.SettingsMerchantHolidays, {
      event_version: 1,
      in_app: false,
      entry_point: entryPoint
    });
  };

  public onMerchantsList = (entryPoint: Analytics.SettingsOverviewEntry = Analytics.SettingsOverviewEntry.WebNav): void => {
    Analytics.track(Analytics.FxEventName.SettingsMerchantHolidays, {
      event_version: 1,
      in_app: false,
      entry_point: entryPoint,
      user_role: PermissionsService.getUserRole()
    });
  };

  public toggleMerchantFeature = (entryPoint: Analytics.SettingsOverviewEntry = Analytics.SettingsOverviewEntry.WebNav): void => {
    Analytics.track(Analytics.FxEventName.ToggleMerchantFeature, {
      event_version: 1,
      in_app: false,
      entry_point: entryPoint
    });
  };

  public onMerchantSearch = (value: string): void => {
    Analytics.track(Analytics.FxEventName.SettingsMerchantSearch, {
      event_version: 1,
      entry_point: 'Merchant list',
      query: value,
      in_app: false
    });
  };

  public onDiscountSearch = (value: string): void => {
    Analytics.track(Analytics.FxEventName.SettingsDiscountSearch, {
      event_version: 1,
      entry_point: 'Discount list',
      query: value,
      in_app: false
    });
  };
}

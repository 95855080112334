import { FC, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';

import { ListItemUnion } from 'generated-types.d';

import { CurrencyService } from 'lib';

import PromotedListsStore from 'stores/promoted-lists-store/promoted-lists-store';

import { DisabledItem } from 'features/lists/lists.styles';

import { ItemPriceWrapper, ItemTotalPrice, ItemUnitPrice, ItemUnitPriceHighlight } from './checkout-list-item-price-details.styles';

interface CheckoutListItemPriceDetailsProps {
  listItem: ListItemUnion;
  promotedListsStore?: PromotedListsStore;
}

export const CheckoutListItemPriceDetails: FC<CheckoutListItemPriceDetailsProps> = inject((stores: FxStores): InjectedFxStores => ({
  promotedListsStore: stores.promotedListsStore
}))(observer(({
  listItem,
  promotedListsStore
}) => {
  const price = listItem.promotedTradeSkuAvailability?.price?.[0];
  const basketItem = promotedListsStore!.basketItems.find(item => item.itemId === listItem.id);

  if (!price) {
    return (<DisabledItem>--</DisabledItem>);
  }

  const hasQuantityInBasket = !!basketItem?.quantity && basketItem.quantity > 0;

  const renderItemTotalPrice = (): ReactNode => {
    if (!hasQuantityInBasket) {
      return null;
    }

    const itemTotalPrice = basketItem!.quantity * price.price;

    return <ItemTotalPrice>{CurrencyService.formatPrice(itemTotalPrice, price.currency)}</ItemTotalPrice>;
  };

  const renderItemUnitPrice = (): ReactNode => {
    const unitPrice = CurrencyService.formatPrice(price.price, price.currency);

    if (!hasQuantityInBasket) {
      return (
        <ItemUnitPriceHighlight>
          {unitPrice}<br />per unit
        </ItemUnitPriceHighlight>
      );
    }

    return (
      <ItemUnitPrice>
        {unitPrice} per unit
      </ItemUnitPrice>
    );
  };

  return (
    <ItemPriceWrapper>
      {renderItemTotalPrice()}
      {renderItemUnitPrice()}
    </ItemPriceWrapper>
  );
}));

import { MerchantForList } from 'global.types';
import { action, observable, set, makeObservable } from 'mobx';
import moment from 'moment';

import {
  Merchant,
  WholesaleDeliveryConfig,
  WeeblyConfig,
  MerchantPlan,
  Maybe
} from 'generated-types.d';

import { MerchantSameDayOrderPauseService, PermissionsService } from 'lib';

export default class MerchantStore {
  constructor() {
    makeObservable(this, {
      merchant: observable,
      merchantPlan: observable,
      merchantList: observable,
      hasProducts: observable,
      isLoadingMerchant: observable,
      customSiteUrlDisplay: observable,
      customSiteURLRaw: observable,
      customSitePublished: observable,
      customSiteEditable: observable,
      wholesaleDeliveryConfig: observable,
      mailchimpInformationID: observable,
      canDownloadCsv: observable,
      hasFloomEnabled: observable,
      isAcceptingSameDayOrderOrders: observable,
      canToggleSameDayOrderPauseStatus: observable,
      sameDayOrderPauseStatus: observable,
      subscriptionStatus: observable,
      disableIsLoadingMerchant: action,
      setMerchantList: action,
      setIsAcceptingSameDayOrderOrders: action,
      setToggleStatusPermission: action,
      setMerchant: action,
      setHasProducts: action,
      updateDeliveryConfigPhone: action,
      updateDeliveryConfigInstructions: action,
      updateWholesaleDeliveryConfig: action,
      toggleSameDayOrderPauseStatus: action
    });
  }

  public merchant: Merchant | null = null;

  public merchantPlan: MerchantPlan | undefined | null = null;

  public customSiteUrlDisplay: string = '';

  public customSiteURLRaw: string = '';

  public customSitePublished: boolean = false;

  public customSiteEditable: boolean = false;

  public hasFloomEnabled: boolean = false;

  public mailchimpInformationID: string = '';

  public wholesaleDeliveryConfig: WholesaleDeliveryConfig | null = null;

  public merchantList: MerchantForList[] = [];

  public canDownloadCsv: boolean = false;

  public sameDayOrderPauseStatus: number = 0;

  public isAcceptingSameDayOrderOrders: boolean = true;

  public canToggleSameDayOrderPauseStatus: boolean = false;

  public hasProducts: boolean = false;

  public subscriptionStatus: string = '';

  public merchantsLastRequestedAt: string = '';

  public isLoadingMerchant: boolean = true;

  public init = (): void => {
    this.merchant = null;
    this.merchantPlan = null;
    this.customSiteUrlDisplay = '';
    this.customSiteURLRaw = '';
    this.mailchimpInformationID = '';
    this.isLoadingMerchant = true;
    this.merchantList = [];
  };

  public setMerchantList = (merchants: MerchantForList[]): void => {
    this.merchantList = merchants;
    this.merchantsLastRequestedAt = moment().toISOString();
  };

  public setMerchant = (merchant: Merchant): void => {
    if (!!merchant) {
      this.merchant = merchant;
      this.wholesaleDeliveryConfig = this.setWholesaleDeliveryConfig(merchant);
      this.isLoadingMerchant = false;
      this.hasProducts = !!merchant.productCount;
      this.sameDayOrderPauseStatus = merchant.sameDayOrderPauseStatus || 0;
      this.setIsAcceptingSameDayOrderOrders();
      this.setToggleStatusPermission();

      this.hasFloomEnabled = !!merchant.flc_id;
      this.mailchimpInformationID = merchant.customSiteConfig?.mailchimpInformation?.id || '';

      this.setMerchantCustomWeeblyUrls(merchant.weeblyConfig);
    }
  };

  public setHasProducts = (): void => {
    this.hasProducts = true;
  };

  public disableIsLoadingMerchant = (): void => {
    this.isLoadingMerchant = false;
  };

  public updateDeliveryConfigPhone = (phone: string): void => {
    set(this.wholesaleDeliveryConfig!.address, 'phone', phone);
  };

  public updateDeliveryConfigInstructions = (instructions: string): void => {
    set(this.wholesaleDeliveryConfig!, 'deliveryInstructions', instructions);
  };

  public setWholesaleDeliveryConfig = (merchant: Merchant): WholesaleDeliveryConfig | null => {
    if (this.hasValidWholesaleDeliveryConfig(merchant)) {
      return merchant!.wholesaleDeliveryConfigs!.find(config => config.active)!;
    }

    return null;
  };

  public setSameDayOrderPauseStatus = (status: number): void => {
    this.sameDayOrderPauseStatus = status;
  };

  public setIsAcceptingSameDayOrderOrders = (): void => {
    this.isAcceptingSameDayOrderOrders = this.sameDayOrderPauseStatus === 0;
  };

  public setToggleStatusPermission = (): void => {
    this.canToggleSameDayOrderPauseStatus = this.sameDayOrderPauseStatus === 0 || this.sameDayOrderPauseStatus === 3;
  };

  public updateWholesaleDeliveryConfig = (config: WholesaleDeliveryConfig): void => {
    this.wholesaleDeliveryConfig = config;
  };

  public toggleSameDayOrderPauseStatus = async (isChecked: boolean): Promise<void> => {
    const currentStatus = this.sameDayOrderPauseStatus;
    this.isAcceptingSameDayOrderOrders = !this.isAcceptingSameDayOrderOrders;

    const receivingOrdersStatus = 0;
    const pausedStatus = 3;

    try {
      const updatedStatus = await new MerchantSameDayOrderPauseService().updateStatus(isChecked ? receivingOrdersStatus : pausedStatus, this.merchant!.id);

      if (typeof updatedStatus === 'number' && !isNaN(updatedStatus)) {
        this.setSameDayOrderPauseStatus(updatedStatus);
      } else {
        this.isAcceptingSameDayOrderOrders = !this.isAcceptingSameDayOrderOrders;
        this.setSameDayOrderPauseStatus(currentStatus);
      }
    } catch (error) {
      this.isAcceptingSameDayOrderOrders = !this.isAcceptingSameDayOrderOrders;
      this.setSameDayOrderPauseStatus(currentStatus);
    }
  };

  public triggerOrderPauseEvents = (status: number | null | undefined): void => {
    if (typeof status === 'number' && !isNaN(status) && !PermissionsService.isInternalRole()) {
      this.setSameDayOrderPauseStatus(status);
      this.setIsAcceptingSameDayOrderOrders();
      this.setToggleStatusPermission();
    }
  };

  private setMerchantCustomWeeblyUrls = (weeblySiteConfig?: Maybe<WeeblyConfig>): void => {
    if (weeblySiteConfig) {
      const rawUrl: string = weeblySiteConfig.domain || '';
      let url = weeblySiteConfig ? rawUrl : '';

      url = url.toLowerCase()
        .replace('www.', '')
        .replace('https://', '')
        .replace(/\/$/, '');

      this.customSiteUrlDisplay = url;
      this.customSiteURLRaw = rawUrl;
      this.customSitePublished = !!weeblySiteConfig?.published;
      this.customSiteEditable = !!weeblySiteConfig && !!weeblySiteConfig.active;
    }
  };

  private hasValidWholesaleDeliveryConfig = (merchant: Merchant): boolean => !!merchant
    && !!merchant.wholesaleDeliveryConfigs
    && !!merchant.wholesaleDeliveryConfigs.length
    && merchant.wholesaleDeliveryConfigs.some(config => config.active);
}

import {
  CatalogItemHit,
  TradeSkuHit
} from 'global.types';
import _flatten from 'lodash.flatten';
import _orderBy from 'lodash.orderby';

import {
  CatalogItemType,
  Currency,
  List,
  ListItem,
  ListItemType,
  ListItemUnion,
  Maybe,
  Merchant,
  Scalars,
  Supplier,
  TradeSku,
  TradeSkuAvailability,
  TradeSkuPrice
} from 'generated-types.d';

import {
  UrlService
} from 'lib';

import { SingleSearchReducerItem } from './components/catalog-inline-search/catalog-inline-search.reducers';
import { ListFieldKey, ListMode } from './lists.types';

export const filteredTradeSkus = (
  tradeSkus: TradeSkuHit[],
  state: SingleSearchReducerItem | undefined
): TradeSkuHit[] => {
  return tradeSkus?.reduce?.((filteredSkus: TradeSkuHit[], tradeSku): TradeSkuHit[] => {
    const selectionKeys: ListFieldKey[] = [
      'maturity',
      'stemLength',
      'potSize',
      'height',
      'weight'
    ];
    const hasNoSelections = selectionKeys.every(key => !state?.selections?.[key]);

    const isMatch = (): boolean => {
      return selectionKeys.every(key => {
        return !state?.selections[key] || tradeSku[key] === state.selections[key];
      });
    };

    if (hasNoSelections || isMatch()) {
      return [
        ...filteredSkus,
        tradeSku
      ];
    }

    return filteredSkus;
  }, []) || [];
};

export const getAvailabilityByListSupplier = (
  availabilityItems: TradeSkuAvailability[] = [],
  listSuppliers: Supplier[] = []
): TradeSkuAvailability[] => {
  if (!listSuppliers.length) {
    return availabilityItems;
  }

  const supplierIds: (string | undefined)[] = listSuppliers.map(x => x.id);

  return availabilityItems.filter?.(availabilityItem => supplierIds.includes(availabilityItem?.supplier?.id));
};

export const getAvailabilityPrices = (availability: TradeSkuAvailability[] = [], suppliers: Supplier[] = []): TradeSkuPrice[] => {
  const availabilityForListSupplier = getAvailabilityByListSupplier(availability, suppliers || []);

  const availabilityPrices = _flatten(availabilityForListSupplier.map(availabilityItem => [
    ...(availabilityItem?.price || []),
    ...(availabilityItem?.priceHistory || [])
  ]));

  return availabilityPrices;
};

const getTradeSkuAvailabilityPrices = (tradeSku: Maybe<TradeSku>[] = [], suppliers: Supplier[] = []): TradeSkuPrice[] => {
  return _flatten(tradeSku?.map(sku => getAvailabilityPrices(sku?.availability || [], suppliers)));
};

export const listItemSkuPrices = (item: ListItem): TradeSkuPrice[] => {
  const suppliers = item.list?.suppliers || [];
  let allPrices: TradeSkuPrice[] = [];

  if (!!item.promotedTradeSkuAvailability) {
    allPrices = getAvailabilityPrices([item.promotedTradeSkuAvailability], suppliers);
  } else {
    allPrices = getTradeSkuAvailabilityPrices(item.availableTradeSkus || [], suppliers);
  }

  const currency = item.list?.merchant?.currency;

  if (!!currency) {
    return allPrices.filter(price => price.currency === currency);
  }

  return allPrices;
};

export const tradeSkuPricesBySkus = (skus: TradeSku[]): TradeSkuPrice[] => {
  const prices = getTradeSkuAvailabilityPrices(skus);

  return prices;
};

export const getItemTotalPrice = (item: ListItemUnion, allPrices: number[], expression: Math['min' | 'max'] = Math.max): number => {
  if (item.maxUnitPrice) {
    return item.maxUnitPrice * (item.quantity || 1);
  }

  return expression(...(allPrices?.length ? allPrices : [0]));
};

export const getItemUnitPrice = (item: ListItemUnion, allPrices: number[], expression: Math['min' | 'max'] = Math.max): number => {
  return item.maxUnitPrice || expression(...(allPrices?.length ? allPrices : [0]));
};

export const tradeSkuPricesBracket = (prices: TradeSkuPrice[], expression: Math['min' | 'max'] = Math.max): number => {
  const allPricesInts = prices!.map(price => price.price);

  return expression(...(allPricesInts?.length ? allPricesInts : [0]));
};

export const getMinTradeSkuPrice = (prices: TradeSkuPrice[], currency: Currency = Currency.Gbp): TradeSkuPrice | undefined => {
  const filterPrices = prices.filter(price => price.currency === currency);

  return filterPrices.length ? filterPrices.reduce((prev, curr) => prev.price < curr.price ? prev : curr) : undefined;
};

export const getMaxTradeSkuPrice = (prices: TradeSkuPrice[], currency: Currency = Currency.Gbp): TradeSkuPrice | undefined => {
  const filterPrices = prices.filter(price => price.currency === currency);

  return filterPrices.length ? filterPrices.reduce((prev, curr) => prev.price > curr.price ? prev : curr) : undefined;
};

export const getTotalListPrice = (listItems?: Maybe<ListItemUnion[]>, expression: Math['min' | 'max'] = Math.max): number => {
  return listItems?.reduce?.((accTotal: number, listItem): number => {
    const allPricesForSku = listItemSkuPrices(listItem);

    if (listItem.maxUnitPrice) {
      return accTotal + (listItem.maxUnitPrice * (listItem.quantity || 1));
    }

    const bracket = tradeSkuPricesBracket(allPricesForSku, expression);

    return accTotal + (bracket * (listItem.quantity || 0));
  }, 0) || 0;
};

export const selectableTradeSkuData = (
  hit: Partial<CatalogItemHit>,
  selections: SingleSearchReducerItem['selections'],
  key: ListFieldKey,
  comparisonKeys: ListFieldKey[],
  suppliers?: Supplier[] | undefined
): TradeSkuHit[] => {
  const tradeSkusWithAvailabilityForListSupplier = hit?.tradeSku?.filter?.(sku => {
    if (!suppliers?.length) {
      return true;
    }

    return sku?.availability?.some?.(availability => availability.supplierId === suppliers[0].id);
  });

  const filteredSkus = tradeSkusWithAvailabilityForListSupplier?.filter?.(sku => {
    if (!sku[key]) return false;

    return comparisonKeys.every(comparisonKey => {
      return (selections[comparisonKey] && sku[comparisonKey]) ? sku[comparisonKey] === selections[comparisonKey] : true;
    });
  }) || [];

  return filteredSkus.reduce((acc: TradeSkuHit[], curr): TradeSkuHit[] => {
    if (!acc.some(sku => sku[key] === curr[key])) {
      return [...acc, curr];
    }

    return acc;
  }, []);
};

/** Returns first valid (non-empty) image url */
export const listItemImage = (imageUrls: string[], shouldShowImage: boolean = false): string | undefined => {
  if (!shouldShowImage) return undefined;

  const [imageUrl] = imageUrls.filter(Boolean);

  if (!!imageUrl) {
    return UrlService.setImageSrc(imageUrl);
  }

  return undefined;
};

/** Media type compatible with Media and TradeSkuMedia GQL types */
type UpdatedMedia = { src: Maybe<string>; updatedAt: Maybe<Scalars['DateTime']> };

export const getMedia = (item: ListItem): UpdatedMedia[] => {
  if (!item) return [];

  const catalogMedia = item?.catalogItem?.media?.map(media => {
    return {
      src: media.src,
      updatedAt: media.updatedAt
    };
  });

  if (catalogMedia?.length) return catalogMedia;

  const tradeSkuMedia = item?.catalogItem?.tradeSku?.map(sku => {
    return sku?.availability?.map(avail => {
      return avail.media?.map(media => {
        return {
          src: media?.src,
          updatedAt: media?.updatedAt
        };
      });
    }).flat();
  }).flat().filter((media): media is UpdatedMedia => !!media);

  if (tradeSkuMedia?.length) return tradeSkuMedia;

  return [];
};

/** Returns image url for most recently updated media. */
export const listItemMediaImage = (mediaList?: UpdatedMedia[] | null, shouldShowImage: boolean = false): string | undefined => {
  if (!shouldShowImage || !mediaList) return undefined;

  const [latestMedia] = _orderBy(mediaList.filter(m => m.src && m.updatedAt), 'updatedAt', 'desc');
  const [fallbackMedia] = mediaList.filter(m => m.src);

  const media = latestMedia || fallbackMedia;

  if (!!media?.src) {
    return UrlService.setImageSrc(media.src);
  }

  return undefined;
};

export const mapCatalogTypeToListItemType = (catalogItemType: CatalogItemType): ListItemType | null => {
  switch (catalogItemType) {
    case CatalogItemType.Decoration:
      return ListItemType.Decoration;

    case CatalogItemType.Flower:
      return ListItemType.Flower;

    case CatalogItemType.Plant:
      return ListItemType.Plant;

    case CatalogItemType.Sundry:
      return ListItemType.Sundry;

    case CatalogItemType.Other:
      return ListItemType.Custom;

    default:
      return null;
  }
};

export type ListItemGroupConfig<T = any> = {
  title: string;
  items: T[];
}

export type ListItemConfig<T = any> = {
  [key in ListItemType | 'Other']: ListItemGroupConfig<T>;
}

export const groupListItemsByType = <T = any>(
  items: Maybe<T[]> | undefined,
  findListItem: (item: T) => ListItem,
  getItemTypeFromSnapshot?: (item: T) => string | undefined
): ListItemConfig<T> => {
  const newBaseConfig = ((): ListItemConfig<T> => {
    return {
      Flower: {
        title: 'Flowers',
        items: []
      },
      Decoration: {
        title: 'Decorations',
        items: []
      },
      Plant: {
        title: 'Plants',
        items: []
      },
      Sundry: {
        title: 'Sundries',
        items: []
      },
      Custom: {
        title: 'Custom items',
        items: []
      },
      Other: {
        title: 'Other',
        items: []
      }
    };
  })();

  if (!items) return newBaseConfig;

  for (const item of items) {
    const listItem = findListItem(item);

    if (!!listItem) {
      newBaseConfig[listItem?.type || ListItemType.Custom].items.push(item);
    } else if (!!getItemTypeFromSnapshot) {
      const typeFromSnapshot = getItemTypeFromSnapshot(item);

      if (!!typeFromSnapshot) {
        newBaseConfig[typeFromSnapshot]?.items.push(item);
      }
    }
  }

  return newBaseConfig;
};

export const getListMode = (inShareFlow: boolean | undefined, merchant?: Merchant | null): ListMode => {
  const { conversationsActive, wholesaleActive } = merchant || {};

  const indirectOnlyUser = !wholesaleActive && conversationsActive;
  const fullUser = wholesaleActive && conversationsActive;

  switch (true) {
    case indirectOnlyUser && inShareFlow:
      return ListMode.SHARE_TO_CONVERSATION_SHARE_ONLY;
    case !indirectOnlyUser && inShareFlow:
      return ListMode.SHARE_TO_CONVERSATION;
    case indirectOnlyUser:
      return ListMode.SHARE_ONLY;
    case fullUser:
      return ListMode.FULL;

    default:
      return ListMode.CHECKOUT_ONLY;
  }
};

export const shouldShowLimitedView = (limitedViewParams: { inShareFlow?: boolean; merchant?: Merchant | null }): boolean  => {
  const { inShareFlow, merchant } = limitedViewParams;
  const limitedViewModes = [ListMode.SHARE_ONLY, ListMode.SHARE_TO_CONVERSATION_SHARE_ONLY];

  return limitedViewModes.some(mode => mode === getListMode(inShareFlow, merchant));
};

export const getListCurrency = (list: Maybe<List> | undefined): Currency | undefined => {
  return list?.merchant?.currency;
};

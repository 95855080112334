import React from 'react';

import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box, Text, Flex } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import UserService from 'features/login-signup/services/';
import TermsModal from 'features/terms/terms-modal/terms-modal';

import Button from 'components/button';
import CheckboxItem from 'components/checkbox-item/checkbox-item';
import FieldText from 'components/field-text';

import * as Styles from './invitation-password.styles';

const LOGIN_ERRORS = {
  77046112839: 'Cannot find associated account. Your reset link may have expired.'
};

const SOMETHING_WENT_WRONG = 'Something went wrong when resetting your password. Your reset link may have expired.';

class InvitationPassword extends React.Component<any> {
  userService = UserService;

  state = {
    passwordEntered: false,
    isLoading: false,
    error: null,
    isDisplayingTerms: false,
    hasAgreedTerms: false,
    hasAgreedNewsletterSubscribe: false
  };

  componentDidMount = async (): Promise<any> => {
    this.userService.init();
    this.subscribeToLoginErrors();
  };

  private subscribeToLoginErrors(): void {
    observe(this.props.accountStore, 'error', ({
      newValue: newError
    }: any) => {
      this.setState({
        error: newError.code && LOGIN_ERRORS[newError.code] ? LOGIN_ERRORS[newError.code] : SOMETHING_WENT_WRONG,
        isLoading: false
      });
    });
  }

  private closeTermsModal = (): void => {
    this.setState({
      isDisplayingTerms: false
    });
  };

  private openTermsModal = (): void => {
    this.setState({
      isDisplayingTerms: true
    });
  };

  private passwordsMatch = (): boolean => this.props.accountStore.newPassword === this.props.accountStore.confirmNewPassword
    && !!this.props.accountStore.newPassword.length;

  private onPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault();

    this.setState({
      error: null
    });

    switch (true) {
      case !this.props.accountStore.newPassword.length:
        this.setState({ error: 'Please choose your password.' });

        break;

      case !this.passwordsMatch():
        this.setState({ error: 'Passwords do not match.' });

        break;

      case !this.state.hasAgreedTerms:
        this.setState({ error: 'You need to accept the Terms & Conditions.' });

        break;

      case this.state.hasAgreedTerms && this.passwordsMatch():
        this.setState({ isLoading: true });

        await this.userService.createPasswordFromInvite(
          this.state.hasAgreedTerms,
          this.state.hasAgreedNewsletterSubscribe
        );

        break;

      default:
        this.setState({ error: 'There has been an error setting your password' });

        break;
    }
  };

  private resetError = (): void => {
    if (this.hasError()) {
      this.setState({ error: null });
    }
  };

  private hasError = (): boolean => !!this.state.error;

  render(): React.ReactNode {
    return (
      <Styles.LoginWrapper>
        <Text
          css={textStyles.h1}
          mb="25px"
        >
          Welcome!
        </Text>
        <Text
          css={textStyles.title}
          mb="25px"
        >
          Let&apos;s finish setting up your account!
        </Text>
        <form onSubmit={this.onPasswordSubmit}>
          <Box alignItems="flex-start">
            <FieldText
              type="password"
              placeholder="Choose a password"
              id="new-password-field"
              value={this.props.accountStore.newPassword}
              autoFocus={true}
              onChange={(e): void => {
                this.resetError();
                this.props.accountStore.updateBasicValue('newPassword', e.target.value);
              }}
              error={this.hasError()}
            />
          </Box>
          <Box
            mt="20px"
            alignItems="flex-start"
          >
            <FieldText
              type="password"
              placeholder="Confirm password"
              id="confirm-password-field"
              value={this.props.accountStore.confirmNewPassword}
              autoFocus={true}
              onChange={(e): void => {
                this.resetError();
                this.props.accountStore.updateBasicValue('confirmNewPassword', e.target.value);
              }}
              error={this.hasError()}
            />
          </Box>

          { this.hasError() && (
            <Styles.Error>
              {this.state.error}
            </Styles.Error>
          )}

          <Box pt="20px">
            <Flex
              mb={['20px', '0']}
              alignItems="center"
            >
              <Styles.CheckboxContainer>
                <CheckboxItem
                  onChange={(): void => {
                    this.resetError();
                    this.setState((state: any) => ({ hasAgreedNewsletterSubscribe: !state.hasAgreedNewsletterSubscribe }));
                  }}
                  isSelected={this.state.hasAgreedNewsletterSubscribe}
                  item="string"
                  checkboxList={{
                    selectedItems: [],
                    itemValueField: '',
                    optionTitleField: '',
                    orientation: 'horizontal'
                  }}
                />
              </Styles.CheckboxContainer>
              <Text
                color="floomMidnightBlue"
                fontSize={1}
                as="label"
                pb="5px"
              >
                Subscribe to newsletter
              </Text>
            </Flex>
            <Flex
              mb="5px"
              alignItems="center"
            >
              <Styles.CheckboxContainer>
                <CheckboxItem
                  onChange={(): void => {
                    this.setState((state: any) => ({ hasAgreedTerms: !state.hasAgreedTerms }));
                  }}
                  isSelected={this.state.hasAgreedTerms}
                  item="string"
                  checkboxList={{
                    selectedItems: [],
                    itemValueField: '',
                    optionTitleField: '',
                    orientation: 'horizontal'
                  }}
                />
              </Styles.CheckboxContainer>
              <Text
                color="floomMidnightBlue"
                fontSize={1}
                as="label"
                pb="5px"
              >
                I agree with the
                {' '}
                <Styles.LinkTerms
                  as="span"
                  onClick={this.openTermsModal}
                >
                  Terms & Conditions
                </Styles.LinkTerms>
              </Text>
            </Flex>
          </Box>

          <Box mt="20px">
            <Box
              as="button"
              type="submit"
              width="100%"
              id="login-submit-button"
              disabled={this.hasError() && this.state.passwordEntered}
            >
              <Button
                isParentWidth={true}
                isDisabled={this.hasError() && this.state.passwordEntered}
                copy="Create account"
                isLoading={!this.props.accountStore.loggedIn && this.state.isLoading}
              />
            </Box>
          </Box>
        </form>
        <TermsModal
          isOpen={this.state.isDisplayingTerms}
          closeModal={this.closeTermsModal}
          context={this.props.context}
        />
      </Styles.LoginWrapper>
    );
  }
}

export default inject('accountStore')(observer(InvitationPassword));

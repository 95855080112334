/**
 * @copyright (c), FloomLTD
 */
import * as SentryBrowser from '@sentry/browser';
import Modal from 'react-modal';

import packageJSON from '../../package.json';

const onClientEntry = (): void => {
  Modal.setAppElement('#___gatsby');
  configureSentry();
};

const configureSentry = (): void => {
  SentryBrowser.init({
    dsn: process.env.SENTRY_FX_FE_DSN_URL,
    release: packageJSON.version,
    environment: process.env.SENTRY_FX_FE_ENVIRONMENT
  });

  window.Sentry = SentryBrowser as any;
};

export default onClientEntry;

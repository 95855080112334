import React, { Component } from 'react';

import GenericModal from 'components/generic-modal';
import GenericSelectList from 'components/generic-select-list';

import * as Styles from './create-addon-chooser.styles';
import { CreateAddonChooserProps } from './create-addon-chooser.types';
import CreateAddonSelectArray from './create-addon-select-array';

export default class CreateAddonChooser extends Component<CreateAddonChooserProps> {
  state = {
    modalOpen: false,
    addons: this.props.addons
  };

  openModal = (): void => {
    this.setState({
      modalOpen: true
    });
  };

  closeModal = (): void => {
    this.selectItem('');

    this.setState({
      modalOpen: false
    });
  };

  selectItem = (itemId: string): void => {
    const newAddons = this.state.addons;

    for (const addon of newAddons) {
      addon.selected = addon.id === itemId;
    }

    this.setState({
      addons: newAddons
    });
  };

  submitModal = (): void => {
    const selectedItem = this.state.addons.filter(addon => addon.selected);

    if (selectedItem.length > 0) {
      this.props.selectAddon(selectedItem[0].id);
      selectedItem[0].selected = false;
    }

    this.setState({
      modalOpen: false
    });
  };

  render(): JSX.Element {
    return (
      <Styles.Content>
        <GenericModal
          width={430}
          innerComponent={(
            <GenericSelectList
              listObjects={this.state.addons}
              onSelect={this.selectItem}
              alreadySelectedIds={this.props.selectedAddonIds}
            />
          )}
          confirmButtonAction={this.submitModal}
          confirmButtonText="Add add-on"
          title="Add new add-on"
          modalOpen={this.state.modalOpen}
          closeModal={this.closeModal}
        />
        {
          <CreateAddonSelectArray
            addons={this.props.addons}
            selectedAddonIds={this.props.selectedAddonIds}
            openModal={this.openModal}
            deselectAddon={this.props.deselectAddon}
          />
        }
      </Styles.Content>
    );
  }
}

import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const NoResults: any = styled(Flex)`
  padding: 20px;
  background: ${colors.lightGrey};
  border-top: 1px solid ${hex2rgba(colors.floomMidnightBlue, .2)};
`;

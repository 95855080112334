import styled from '@emotion/styled';
import { Box } from 'rebass';

export const Wrapper = styled(Box)`
  padding: 50px;
  position: relative;
  overflow: hidden;
  text-align: center;
  height: 100%;
`;

export const ButtonWrapper = styled.button`
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
`;

export const ImageWrapper = styled(Box)`
  max-width: 130px;
  margin: 0 auto 25px auto;
`;

import React, { Component } from 'react';

import Icon from 'components/icon';

import * as Styles from './field-tag-selection.styles';
import * as Types from './field-tag-selection.types';

export default class FieldTagSelection extends Component<Types.FieldTagSelectionProps> {
  static defaultProps: Partial<Types.FieldTagSelectionProps> = {
    selectionLimit: undefined
  };

  private shouldDisplayButton = (): boolean => this.props.items.length < this.props.totalItemsCount
    && (!this.props.selectionLimit || this.props.items.length < this.props.selectionLimit);

  private renderCross = (item: Types.TagSelectionItem): (JSX.Element | null) => {
    return (
      <Styles.TagDelete
        onClick={(): void => this.props.onRemove(item.id)}
        colour={item.color}
      >
        <Icon iconName="cross-smaller" />
      </Styles.TagDelete>
    );
  };

  private renderButton = (): (JSX.Element | null) => {
    if (this.shouldDisplayButton()) {
      return (
        <Styles.ActionButton>
          <p>Add {this.props.itemCopyPlural}</p>
          <Icon
            iconName="plus-small"
          />
        </Styles.ActionButton>
      );
    }

    return null;
  };

  private renderSelectedColours = (): React.ReactNode => {
    if (this.props.isLoading) {
      return (
        <Styles.Loading>Loading...</Styles.Loading>
      );
    }

    return this.props.items.map(item => (
      <Styles.TagWrapper
        key={item.id}
        colour={item.color}
      >
        <p>{item.title}</p>
        {this.renderCross(item)}
      </Styles.TagWrapper>
    ));
  };

  render(): JSX.Element {
    return (
      <Styles.TagSelectionWrapper>
        <button onClick={this.props.onAdd}>
          {this.renderButton()}
        </button>
        {this.renderSelectedColours()}
      </Styles.TagSelectionWrapper>
    );
  }
}

import { Component, ReactNode } from 'react';

import { colors } from 'utils/rebass-theme';

import { SimpleOrderStatus } from 'features/orders/orders.constants';

import Icon from 'components/icon';

import * as Styles from './status-select.styles';
import * as Types from './status-select.types';

class StatusSelect extends Component<Types.StatusSelectProps> {
  render(): ReactNode {
    return (
      <Styles.StatusSelectContainer
        hasText={this.props.hasText}
        layout={this.props.layout}
      >
        <Styles.StatusSelect
          hasText={this.props.hasText}
          layout={this.props.layout}
          name={this.props.orderNo}
          id={this.props.orderNo}
          onChange={(e: any): any => this.props.onChange(e.target.value)}
          defaultValue="update"
        >
          <option
            value="update"
            disabled={true}
          >
            {this.props.hasText ? this.props.currentStatus : 'Update status'}
          </option>
          {
            this.props.actions.map((status: SimpleOrderStatus) => (
              <option
                key={status.slug}
                value={status.slug}
              >
                {status.title}
              </option>
            ))
          }
        </Styles.StatusSelect>
        <Icon
          styles={Styles.DropdownArrow}
          pathFill={colors.white}
          iconName="arrow-down"
        />
      </Styles.StatusSelectContainer>
    );
  }
}

export default StatusSelect;

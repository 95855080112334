import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { breakpoints, colors, space } from 'utils/rebass-theme';

import { QuantitySelectorSize } from './quantity-selector.types';

const QuantityAdjusterInputConfig: {
  [key in QuantitySelectorSize]: SerializedStyles;
} = {
  small: css`
    height: 27px;
    font-size: 12px;
  `,
  normal: css`
    height: 34px;
  `,
  responsive: css`
    height: 38px;
    font-size: 12px;

    @media only screen and (max-width: ${breakpoints.large}) {
      padding-left: ${space[1]}px;
      padding-right: ${space[1]}px;
    }
  `
};

export const QuantityInput = styled(Flex)<{ size: QuantitySelectorSize }>`
  margin: 0 2px;

  input {
    ${({ size }) => QuantityAdjusterInputConfig[size]}
    -moz-appearance: textfield;
  }
`;

const QuantityAdjusterSizeConfig: {
  [key in QuantitySelectorSize]: SerializedStyles;
} = {
  small: css`
    height: 27px;
    flex: 0 0 35px;
  `,
  normal: css`
    height: 34px;
    flex: 0 0 40px;
  `,
  responsive: css`
    height: 38px;
    flex: 0 0 40px;
  `
};

export const QuantityAdjuster = styled(Flex)<{ size: QuantitySelectorSize }>`
  text-align: center;
  background: ${colors.paleGrey};
  color: ${colors.floomMidnightBlue};
  border-radius: 3px;
  ${({ isDisabled }): any => {
    if (isDisabled) {
      return css`
        cursor: not-allowed;
        opacity: 0.4;
      `;
    }
  }}

  ${({ size }) => QuantityAdjusterSizeConfig[size]};

  > svg {
    width: 100%;
    padding: 0 13px;

    ${({ size }) => size === 'responsive'
    ? `
      @media only screen and (max-width: ${breakpoints.large}) {
        padding: 0 ${space[2]}px;
      }
    `
    : ''}
  }
`;

import React, { Component } from 'react';

import { css } from '@emotion/react';
import { Flex } from 'rebass';

import { ProductListLayout } from 'stores/products-store';

import Icon from 'components/icon';

import * as Styles from './layout-toggle.styles';
import * as Types from './layout-toggle.types';

export default class LayoutToggle extends Component<Types.LayoutToggleProps> {
  toggles: Types.Toggle[] = [
    {
      iconName: 'grid',
      layoutType: 'grid'
    },
    {
      iconName: 'list',
      layoutType: 'table'
    }
  ];

  onChange = (layoutOption: ProductListLayout): void => this.props.onChange(layoutOption);

  render(): React.ReactNode {
    return(
      <Flex
        alignItems="center"
        css={Styles.Toggles}
      >
        {
          this.toggles.map(({ iconName, layoutType }) => (
            <button
              key={layoutType}
              onClick={(): void => this.onChange(layoutType)}
              css={Styles.ToggleButton}
            >
              <Icon
                iconName={iconName}
                styles={css`
                  opacity: ${this.props.layoutOption === layoutType ? 1 : .5};
                `}
              />
            </button>
          ))}
      </Flex>
    );
  }
}

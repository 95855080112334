import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';
import { Box, Flex } from 'rebass';

import {
  colors,
  HeaderHeight,
  space,
  textStyles
} from 'utils/rebass-theme';

export const Wrapper = styled(Box)`
  width: 100%;
  height: ${HeaderHeight};
  padding: ${space[2]}px ${space[3]}px;
  border-bottom: 1px solid ${hex2rgba(colors.middleGrey, .6)};
  background-color: ${colors.white};
  position: sticky;
  top: 0;
  z-index: 4;
`;

export const Container = styled(Flex)`
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContentCenter = styled(Flex)`
  ${textStyles.title};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`;

export const ContentLeft = styled(Flex)`
  position: absolute;
  left: 0;
`;

export const ContentRight = styled(Flex)`
  position: absolute;
  right: 0;
`;

import { gql } from '@apollo/client';

import { MessageFields } from '../fragments';

export const ON_EVENT_SUBSCRIPTION = gql`
  subscription OnEventSubscription($merchantId: ID = "") {
    onEvent(merchantId: $merchantId) {
      ... on MessageEvent {
        id
        merchantId
        messageEventType
        message {
          ...MessageFields
        }
      }

      ... on ConversationEvent {
        id
        merchantId
        conversationEventType
        conversation {
          id
          merchantId
          name
          unreadMessagesCount
        }
      }
    }
  }

  ${MessageFields}
`;

import { ApolloError } from '@apollo/client';
import { observable, action, set, makeObservable } from 'mobx';

import { AddonConnection, Channel, AddonOrderByInput } from 'generated-types.d';

import * as Types from './add-ons-store.types';

export default class AddOnsStore {
  constructor() {
    makeObservable(this, {
      addOns: observable.deep,
      hasError: observable,
      errorMsg: observable,
      layoutOption: observable,
      currentGroup: observable,
      categoryFilter: observable,
      addOnsCategories: observable,
      channelFilter: observable,
      showInactiveAddOns: observable,
      orderBy: observable,
      loadIncrement: observable,
      count: observable,
      isLoading: observable,
      updateAddOns: action,
      setLayout: action,
      setAddOnsOrdering: action,
      setCategoryFilter: action,
      setAddOnsCategories: action,
      setChannelFilter: action,
      setLoading: action,
      resetLoading: action,
      resetPagination: action,
      toggleInactiveAddOns: action,
      setInitialCounts: action,
      onLoadMore: action,
      appendAddOns: action
    });
  }

  isLoading: boolean = true;

  addOns: any = {};

  categoryFilter?: Types.AddOnsCategoryFilterType = Types.AddOnsCategoryFilter.All;

  addOnsCategories: any[] = [];

  channelFilter: string = 'All';

  addOnsChannels: string[] = [];

  hasError: boolean = false;

  errorMsg: ApolloError | boolean = false;

  layoutOption: Types.AddOnsListLayout = 'table';

  currentGroup: Types.AddOnsGroupOption = 'allAddOns';

  showInactiveAddOns: boolean = true;

  orderBy: AddonOrderByInput = AddonOrderByInput.TitleAsc;

  loadIncrement: number = 100;

  count: number = 0;

  updateAddOns = (addOns: any): void => {
    this.addOns = addOns;
    this.resetLoading();
  };

  resetAddOns = (): void => {
    this.addOns = {};
    this.count = 0;
    this.orderBy = AddonOrderByInput.TitleAsc;
  };

  setLayout(layoutOption: Types.AddOnsListLayout): void {
    this.layoutOption = layoutOption;
  }

  setAddOnsCategories(categories: any): void {
    const allCategories = {
      id: Types.AddOnsCategoryFilter.All,
      slug: 'all',
      title: 'All Categories'
    };

    this.addOnsCategories = [allCategories, ...categories.edges.map((item: any) => {
      return {
        id: item.node.id,
        slug: item.node.slug,
        title: item.node.title
      };
    })];
  }

  setAddOnsOrdering(orderBy: AddonOrderByInput = AddonOrderByInput.TitleAsc): void {
    this.resetPagination();
    this.orderBy = orderBy;
  }

  setInitialCounts(): void {
    this.count = 0;
  }

  setCategoryFilter(filter: Types.AddOnsCategoryFilterType): void {
    this.categoryFilter = filter;
  }

  setChannelFilter(filter: Channel): void {
    this.channelFilter = filter;
  }

  resetLoading(): void {
    this.isLoading = false;
  }

  setLoading(): void {
    this.resetError();
    this.isLoading = true;
  }

  resetError(): void {
    this.hasError = false;
  }

  loadingError(error: any): void {
    this.hasError = true;
    this.errorMsg = error;
  }

  resetPagination(): void {
    this.count = 0;
  }

  toggleInactiveAddOns(value: boolean): void {
    this.showInactiveAddOns = value;
  }

  onLoadMore(): void {
    this.count = this.count + this.loadIncrement;
  }

  appendAddOns = (addOnData: AddonConnection): void => {
    set(this.addOns.allAddons.pageInfo, 'hasNextPage', addOnData.pageInfo.hasNextPage);

    set(this.addOns.allAddons.edges, [
      ...this.addOns.allAddons.edges,
      ...addOnData.edges
    ]);
    this.resetLoading();
  };
}

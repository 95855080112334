import styled from '@emotion/styled';
import { Text } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const LoadMoreLink = styled(Text)`
  font-weight: 600;
  text-decoration: underline;
  display: inline;
  cursor: pointer;
  color: ${({ disabled }): string => disabled ? colors.middleGrey : colors.floomMidnightBlue};
  cursor: ${({ disabled }): string => disabled ? 'not-allowed' : 'pointer'};
`;

import { MerchantLocationKey, MerchantSupportedTimezone } from './merchant-onboarding-store.types';

type CityTimezoneMatrix = {
  [key in MerchantLocationKey]: MerchantSupportedTimezone
};

export const CITY_TIMEZONE_MATRIX: CityTimezoneMatrix = {
  'uk': 'Europe/London',
  'boston': 'America/New_York',
  'chicago': 'America/Chicago',
  'houston': 'America/Chicago',
  'los-angeles': 'America/Los_Angeles',
  'new-york': 'America/New_York',
  'san-diego': 'America/Los_Angeles',
  'san-francisco': 'America/Los_Angeles'
};

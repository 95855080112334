import { Fragment, useState, useEffect, memo } from 'react';

import { CatalogItemType } from 'generated-types';
import {
  MenuProvided,
  connectMenu
} from 'react-instantsearch-core';
import { Box, Flex } from 'rebass';

import { PRODUCT_CATEGORIES } from 'features/lists/lists.constants';

import Icon from 'components/icon';
import RadioList from 'components/radio-list';

import { AccordionHeading, RadioListWrapper } from './catalog-refinement-menu.styles';

interface CatalogRefinementMenuProps extends MenuProvided {
  name: string;
  handleCategorySelect: (category: CatalogItemType) => void;
}

export const CatalogRefinementMenu = connectMenu(memo((props: CatalogRefinementMenuProps): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(true);

  const selectedItem = props.items?.find?.(item => item.isRefined);
  const isSelectedItemPopulated = !!selectedItem && !!selectedItem?.count;

  useEffect(() => {
    handleAutoSelection();
  }, []);

  useEffect(() => {
    handleAutoSelection();
  }, [isSelectedItemPopulated]);

  const selectItem = (
    refinement: CatalogItemType | '',
    type: CatalogItemType
  ): void => {
    props.refine(refinement);
    props.handleCategorySelect(type);
  };

  const handleAutoSelection = (): void => {
    if (!isSelectedItemPopulated) {
      const firstPopulatedItem = props.items?.find?.(item => !!item.count);

      if (firstPopulatedItem) {
        selectItem(firstPopulatedItem.value as CatalogItemType, firstPopulatedItem.value as CatalogItemType);
      }
    }
  };

  const buildItems = (): any[] => {
    return PRODUCT_CATEGORIES.map(category => {
      const metadata = props.items?.find?.(item => item.label === category.key);

      return {
        title: `${category.title} (${metadata?.count || 0})`,
        value: category.key || '',
        isDisabled: !metadata?.count
      };
    });
  };

  if (!props.items.length) return null;

  return (
    <Fragment>
      <AccordionHeading
        as="button"
        onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}
      >
        <Box>
          {props.name}
        </Box>
        <Flex>
          <Icon
            iconName={isOpen ? 'arrow-down' : 'arrow-up'}
          />
        </Flex>
      </AccordionHeading>
      {isOpen && (
        <RadioListWrapper>
          <RadioList
            itemValueField="value"
            itemTitleField="title"
            orientation="vertical"
            selectedItem={props.currentRefinement}
            items={buildItems()}
            onChange={(value): void => {
              const item = props.items.find(refinementItem => refinementItem.label === value);

              if (!!item?.value) {
                selectItem(item.value as CatalogItemType, item.label as CatalogItemType);
              }
            }}
          />
        </RadioListWrapper>
      )}
    </Fragment>
  );
}));

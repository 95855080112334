import React, { Component } from 'react';

import * as Styles from './radio-icon.styles';
import * as Types from './radio-icon.types';

export default class RadioIcon extends Component<Types.RadioIconProps> {
  static defaultProps = {
    isDisabled: false,
    error: false
  };

  render(): React.ReactNode {
    const { isSelected, isDisabled, error } = this.props;

    return(
      <Styles.RadioOuterStyle
        isSelected={isSelected}
        error={error}
      >
        <Styles.RadioInnerStyle
          isSelected={isSelected}
          isDisabled={isDisabled}
          error={error}
        />
      </Styles.RadioOuterStyle>
    );
  }
}

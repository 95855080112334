import { FC, useEffect, useMemo, useState } from 'react';

import { inject, observer } from 'mobx-react';

import { PhoneContact } from 'types/conversations.types';

import { NavService, PermissionsService } from 'lib';

import { colors } from 'utils/rebass-theme';

import { getListMode } from 'features/lists/lists.helpers';
import { ListMode } from 'features/lists/lists.types';
import { ConversationContactListModalData } from 'features/modal-dialogue/components/modals/conversation-contact-list-modal';

import { ButtonDropdown } from 'components/button-dropdown/button-dropdown';
import { ButtonDropdownOption } from 'components/button-dropdown/button-dropdown.types';
import Icon from 'components/icon';

import {
  MoreOptionsButtonProps as ShareListButtonProps
} from './more-options-button.types';

const MoreOptionsButtonView: FC<ShareListButtonProps> = ({
  selectedListStore,
  conversationsStore,
  merchantStore,
  modalStore
}) => {
  const listId = selectedListStore?.list?.id;

  const [listMode, setListMode] = useState(ListMode.FULL);

  useEffect(() => {
    const currentMode = getListMode(
      conversationsStore?.shareList?.inShareFlow,
      merchantStore?.merchant
    );

    setListMode(currentMode);
  }, [
    conversationsStore?.shareList?.inShareFlow,
    merchantStore?.merchant
  ]);

  const shouldShowShareListButton = (): boolean => {
    const allowedModes = [
      ListMode.FULL,
      ListMode.SHARE_ONLY
    ];

    return (
      !PermissionsService.isInternalRole() &&
      allowedModes.includes(listMode)
    );
  };

  const handleContactSelected = (contact: PhoneContact): void => {
    if (!listId) {
      return;
    }

    conversationsStore?.setShareListToConversationFlow(contact.id, listId);
    NavService.conversationsContact(contact.id);
  };

  const handleShareListButtonClick = (): void => {
    modalStore!.triggerModal<ConversationContactListModalData>({
      modalType: 'conversationContactList',
      data: {
        onContactSelect: handleContactSelected
      }
    });
  };

  const moreOptionsList = useMemo((): ButtonDropdownOption[] => {
    if (shouldShowShareListButton()) {
      return [{
        title: 'Share to conversation',
        description: 'Share the current list to a conversation',
        onClick: handleShareListButtonClick,
        iconName: 'chat-bubble',
        iconBgColour: colors.paleGrey
      }];
    }

    return [];
  }, [listId]);

  if (!listId) return null;

  return (
    <ButtonDropdown
      options={moreOptionsList}
      buttonCopy="More options"
      isHidden={!moreOptionsList.length}
      positionX="right"
      renderButton={() => (
        <Icon iconName="more-options" />
      )}
    />
  );
};

export const MoreOptionsButton = inject((stores: FxStores): InjectedFxStores => ({
  selectedListStore: stores.selectedListStore,
  conversationsStore: stores.conversationsStore,
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore
}))(observer(MoreOptionsButtonView));

import { memo, useEffect } from 'react';

import {
  connectSortBy
} from 'react-instantsearch-core';

import DropdownNative from 'components/dropdown-native';

export interface SortByItem {
  label: string;
  value: string;
}

interface SortDropdownProps {
  items: SortByItem[];
  currentRefinement: string;
  defaultRefinement: string;
  pageLayout: string;
  onSort: (value: string) => void;
  refine: any;
}

export const SortDropdown = connectSortBy(memo(({ items, currentRefinement, defaultRefinement, pageLayout, refine, onSort }: SortDropdownProps) => {
  useEffect(() => {
    refine(defaultRefinement);
  }, [pageLayout]);

  return (
    <DropdownNative
      label="Sort By:"
      id="order-list-sort"
      selected={currentRefinement}
      options={items}
      optionTitleField="label"
      optionValueField="value"
      onChange={(value): void => {
        onSort(value);
        refine(value);
      }}
    />
  );
}));

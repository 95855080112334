import styled from '@emotion/styled';
import { lighten } from 'polished';
import { Flex, Text, Box } from 'rebass';

import { fontSizes, textStyles, fonts } from 'utils/rebass-theme';

export const BookingContainer = styled(Box)`
  flex-grow: 1;
  margin-top: 30px;
`;

export const BookingRow = styled(Flex)<any>`
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 20px;
  border-radius: 3px;
`;

export const IconContainer = styled(Box)<any>`
  width: 24px;
  height: 24px;
  align-self: center;
  margin-right: 20px;

  svg {
    width: 100%;
  }
`;

export const MetaInfo = styled(Flex)`
  flex-direction: column;
  margin-right: 30px;
  text-align: left;
`;

export const Label = styled(Text)<any>`
  line-height: 1.4;
  font-size: ${textStyles.label};
  font-weight: 600;
  color: ${({ color }): string => lighten(0.08, color)};
  text-transform: uppercase;
  font-family: ${fonts.secondary};
`;

export const Title = styled(Text)<any>`
  line-height: 1.4;
  font-weight: 600;
  font-size: ${fontSizes.small}px;
  color: ${({ color }): string => color};
  font-family: ${fonts.secondary};
`;

export const DeliveryMessage = styled.p`
  margin: 0;
  max-width: 250px;
  line-height: 16px;
  font-size: ${fontSizes.small}px;
`;

import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { textStyles, colors, breakpoints, borderRadius, space } from 'utils/rebass-theme';

import { ContentLayoutType } from './generic-modal.types';

const contentLayoutStyles: { [key in ContentLayoutType]: SerializedStyles } = {
  'default': css`
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    border-radius: ${borderRadius.large};
  `,
  'full-height': css`
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    transform: none;
    border-radius: 0;
  `
};

export const ContentWrapper = styled(Box)<{
  customStyles: any;
  hasOverflow: boolean;
  width: number;
  contentLayoutType: ContentLayoutType;
}>`
  position: absolute;
  // width: calc(100% - 40px);
  max-width: ${({ width }) => `${width}px`};
  background: ${colors.uiFills};
  padding: 0;
  max-height: 100%;
  border: none;
  pointer-events: all;
  overflow: auto;
  ${({ contentLayoutType }) => contentLayoutStyles[contentLayoutType || 'default']}

  @media (max-width: ${breakpoints.small}) {
    top: ${({ contentLayoutType }) => contentLayoutType === 'default' ? 'auto' : '0'};
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: none;
    transform: none;
  };

  ${({ customStyles }) => css`${customStyles}`};
`;

export const Content: any = styled(Flex)<{ hasOverflow: boolean }>`
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-y: ${({ hasOverflow }): string => hasOverflow ? 'auto' : 'visible'};
`;

export const InnerContent: any = styled.div`
  overflow: visible;
  flex-grow: 1;
  position: relative;
`;

export const FooterContent: any = styled.div`

`;

export const Header: any = styled(Flex)`
  ${textStyles.subhead}
  padding: 15px 20px;
  align-items: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: ${colors.lightGrey};
`;

export const HeaderAction = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 2;
  cursor: pointer;
`;

export const ExitCross: any = styled.button`
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  margin-right: -4px;
`;

export const Title: any = styled.p`
  ${textStyles.subhead};
  line-height: 2;
  margin-bottom: 0;
`;

export const FooterWrapper: any = styled.div<{ hasBg: boolean; hasFooterBorder: boolean; stickToBottom: string }>`
  position: ${({ stickToBottom }): string => stickToBottom ? `sticky` : 'inherit'};
  bottom: ${({ stickToBottom }): string => stickToBottom ? `0px` : 'inherit'};
  z-index: 1;
  padding: 20px;
  background: ${({ hasBg }): string => hasBg ? colors.lightGrey : colors.uiFills};
  border-top: ${({ hasFooterBorder }): string => `1px solid ${hasFooterBorder ? colors.middleGrey : 'transparent'}`};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 100%;
`;

export const Footer: any = styled(Flex)`
  justify-content: flex-end;

  @media (max-width: ${breakpoints.medium}) {
    flex-wrap: wrap;

    > * {
      margin: 0 auto;
    }
  }
`;

export const AdditionalAction: any = styled.div`
  margin-right: 10px;

  @media (max-width: ${breakpoints.medium}) {
    margin: 0 auto ${space[1]}px;
  }
`;

export const ActionButton: any = styled.button`
  cursor: ${(props: any): any => props.disabled ? 'normal' : 'pointer'};
  pointer-events: ${(props: any): any => props.disabled || props.edit ? 'none' : 'auto'};
`;

import {
  FC
} from 'react';

import {
  inject,
  observer
} from 'mobx-react';

import CheckboxItem from 'components/checkbox-item';

import {
  Checkbox,
  CheckboxSelected,
  Label
} from './catalog-list-item-colours.styles';
import {
  CatalogListItemColoursProps
} from './catalog-list-item-colours.types';

const CatalogListItemColours: FC<CatalogListItemColoursProps> = props => {
  const isSelected = props.selectedItems.includes(props.item[props.itemValueField]);
  const checkBoxStyles = isSelected ? CheckboxSelected : Checkbox;

  return (
    <CheckboxItem
      isSelected={isSelected}
      onChange={props.onChange}
      metadataField={props.metadataField}
      key={props.itemKey}
      checkboxStyles={checkBoxStyles}
      checkboxLabelStyles={Label}
      isDirty={props.isDirty}
      item={{
        isDisabled: false,
        title: props.item.title,
        value: props.item.value,
        label: props.item.title,
        hexes: props.wholesaleFiltersStore!.getHexesByColourName(props.item.value)
      }}
      checkboxList={{
        selectedItems: props.selectedItems,
        itemValueField: 'label',
        optionTitleField: 'label',
        orientation: props.orientation
      }}
    />
  );
};

export default inject('wholesaleFiltersStore')(observer(CatalogListItemColours));

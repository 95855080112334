import React, { Component, Fragment, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import * as Yup from 'yup';

import {
  Currency,
  Merchant,
  MerchantStage,
  SubscriptionStatus
} from 'generated-types.d';

import {
  Analytics,
  LocaleService,
  LocalisationService,
  MerchantService,
  MerchantSubscriptionService,
  PermissionsService,
  TimeService,
  ValidationService
} from 'lib';

import {
  EditFieldsConfigKey,
  MerchantDetailFields
} from 'stores/merchant-detail/merchant-detail-store.types';
import {
  ToastType
} from 'stores/toaster-store/toaster-store.types';

import { publishEvent } from 'utils/event';
import { colors } from 'utils/rebass-theme';

import NoResultsGif from 'assets/images/wholesale/no-results.gif';

import { FeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags.types';

import MerchantOnboardingService from 'features/merchant-onboarding/services';
import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';
import { PlanShiftModalData } from 'features/modal-dialogue/components/modals/plan-shift-model/plan-shift-modal.types';
import { SubscriptionPaymentModalData } from 'features/modal-dialogue/components/modals/subscription-payment-modal/subscription-payment-modal.types';
import MerchantInvite from 'features/settings/components/merchants/merchant-detail/components/merchant-invite';
import SettingsService from 'features/settings/services';

import MerchantDetailAddress from 'components/address';
import Button from 'components/button';
import CheckboxToggle from 'components/checkbox-toggle';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import Icon from 'components/icon';
import NoResultsGeneric from 'components/no-results-generic';
import PlanDetails from 'components/plan-details';
import StyledLink from 'components/styled-link/styled-link';
import { WithFeatureFlag } from 'components/with-feature-flag/with-feature-flag';
import WithLoading from 'components/with-loading';

import { CompletionItem } from './components/completion-item';
import { CopyMetadataItem } from './components/core-metadata-item';
import { ProposedMerchantUpdateContext } from './components/proposed-merchant-update-context';
import SubscriptionStatusDetail from './components/subscription-status';
import * as Styles from './merchant-detail-content.styles';
import * as Types from './merchant-detail-content.types';
import { getFormValidation } from './merchant-detail-form-validation';

type MerchantFeatureKey = Extract<keyof Merchant,
  | 'wholesaleActive'
  | 'listsActive'
  | 'isDemoAccount'
  | 'conversationsActive'
  | 'promotedListsActive'
  | 'promotedActive'
  | 'canViewNationwide'
>;

interface MerchantFeatureToggleConfig {
  featureKey: MerchantFeatureKey;
  copy: string;
  isVisible: (allFlags: FeatureFlags) => boolean;
}

class MerchantDetailContent extends Component<Types.MerchantDetailContentProps> {
  private MerchantEditService = MerchantOnboardingService.MerchantEditService;

  state = {
    isTogglingFeatureFlag: false,
    isReIndexingProducts: false,
    hasReIndexed: false
  };

  private featureToggles: MerchantFeatureToggleConfig[] = [
    {
      featureKey: 'wholesaleActive',
      copy: 'Access to Wholesale',
      isVisible: () => true
    },
    {
      featureKey: 'listsActive',
      copy: 'Access to Lists',
      isVisible: () => true
    },
    {
      featureKey: 'conversationsActive',
      copy: 'Access to Conversations',
      isVisible: () => true
    },
    {
      featureKey: 'isDemoAccount',
      copy: 'Demo account?',
      isVisible: () => PermissionsService.isSuperAdmin()
    },
    {
      featureKey: 'promotedListsActive',
      copy: 'Access to Special Offers',
      isVisible: () => true
    },
    {
      featureKey: 'canViewNationwide',
      copy: 'Access to Nationwide',
      isVisible: () => true
    },
    {
      featureKey: 'promotedActive',
      copy: 'Access to Promoted',
      isVisible: () => true
    }
  ];

  private handleChange = (value: any, name: any, key: EditFieldsConfigKey): void => {
    this.props.merchantDetailStore!.handleFieldChange(value, name, key);
  };

  private findError = (key: any): Yup.TestMessageParams | undefined => {
    return ValidationService.findErrorCopy(this.props.merchantDetailStore!.validationErrors, key);
  };

  private handleBlurValidateFormat = async (value: string, key: string, category: EditFieldsConfigKey): Promise<void> => {
    try {
      const validationSchema = getFormValidation(category, this.props.merchantDetailStore!.selectedMerchant?.currency === Currency.Usd);
      const validatedFormattedValue = await ValidationService.validateField(validationSchema, value, key);
      this.props.merchantDetailStore!.handleFieldChange(validatedFormattedValue, key, category);
      publishEvent('updateModal');
    } catch (error: any) {
      this.props.merchantDetailStore!.setErrors(error.errors);
      publishEvent('updateModal');
    }
  };

  private canEditStage = (): boolean => {
    return (MerchantService.isActiveMerchantStage(this.props.merchantDetailStore!.selectedMerchant!)
      || this.props.merchantDetailStore!.selectedMerchant?.stage === MerchantStage.Deactivated)
      && (MerchantService.hasActiveSubscription(this.props.merchantDetailStore!.selectedMerchant)
        || MerchantService.hasUnpaidSubscription(this.props.merchantDetailStore!.selectedMerchant))
      && !this.props.merchantDetailStore!.selectedMerchant?.proposedUpdate;
  };

  private canEditPlan = (): boolean => {
    return !MerchantService.hasUnpaidSubscription(this.props.merchantDetailStore!.selectedMerchant)
      && !this.props.merchantDetailStore!.selectedMerchant?.proposedUpdate;
  };

  public EDIT_FIELDS_CONFIG = (): { [key in EditFieldsConfigKey]: FormBuilderConfig } => {
    return {
      basicMerchantDetails: {
        sections: [
          {
            width: '100',
            fields: [
              {
                key: 'givenNameLabel',
                fieldType: 'labelSmall',
                copy: 'Name'
              },
              {
                key: 'givenName',
                fieldType: 'textInput',
                placeholder: 'Name',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.basicMerchantDetails.fields.givenName,
                validationError: this.findError('givenName'),
                onChange: (e): void => this.handleChange(e.target.value, 'givenName', EditFieldsConfigKey.basicMerchantDetails)
              },
              {
                key: 'familyNameLabel',
                fieldType: 'labelSmall',
                copy: 'Surname'
              },
              {
                key: 'familyName',
                fieldType: 'textInput',
                placeholder: 'Surname',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.basicMerchantDetails.fields.familyName,
                validationError: this.findError('familyName'),
                onChange: (e): void => this.handleChange(e.target.value, 'familyName', EditFieldsConfigKey.basicMerchantDetails)
              },
              {
                key: 'emailLabel',
                fieldType: 'labelSmall',
                copy: 'E-mail'
              },
              {
                key: 'email',
                fieldType: 'textInput',
                placeholder: 'E-mail',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.basicMerchantDetails.fields.email,
                validationError: this.findError('email'),
                onChange: (e): void => this.handleChange(e.target.value, 'email', EditFieldsConfigKey.basicMerchantDetails)
              },
              {
                key: 'phoneLabel',
                fieldType: 'labelSmall',
                copy: 'Phone'
              },
              {
                key: 'phone',
                fieldType: 'textInput',
                placeholder: 'Phone',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.basicMerchantDetails.fields.phone,
                validationError: this.findError('phone'),
                onChange: (e): void => this.handleChange(e.target.value, 'phone', EditFieldsConfigKey.basicMerchantDetails),
                onBlur: (e): Promise<void> => this.handleBlurValidateFormat(e.target.value, 'phone', EditFieldsConfigKey.basicMerchantDetails)
              }
            ]
          }
        ]
      },
      company: {
        sections: [
          {
            width: '100',
            fields: [
              {
                key: 'companyNameLabel',
                fieldType: 'labelSmall',
                copy: 'Name'
              },
              {
                key: 'company-businessName',
                fieldType: 'textInput',
                placeholder: 'Name',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.company.fields.businessName,
                validationError: this.findError('businessName'),
                onChange: (e): void => this.handleChange(e.target.value, 'businessName', EditFieldsConfigKey.company)
              },
              {
                key: 'tradingNoLabel',
                fieldType: 'labelSmall',
                copy: 'Trading number'
              },
              {
                key: 'tradingNo',
                fieldType: 'textInput',
                placeholder: 'Trading number',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.company.fields.tradingNo,
                validationError: this.findError('tradingNo'),
                onChange: (e): void => this.handleChange(e.target.value, 'tradingNo', EditFieldsConfigKey.company)
              },
              {
                key: 'registeredAddressLabel',
                fieldType: 'labelSmall',
                copy: 'Registered address'
              },
              {
                key: 'company-address1',
                fieldType: 'textInput',
                placeholder: 'Address line 1',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.company.fields.address1,
                validationError: this.findError('address1'),
                onChange: (e): void => this.handleChange(e.target.value, 'address1', EditFieldsConfigKey.company)
              },
              {
                key: 'company-address2',
                fieldType: 'textInput',
                placeholder: 'Address line 2',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.company.fields.address2,
                validationError: this.findError('address2'),
                onChange: (e): void => this.handleChange(e.target.value, 'address2', EditFieldsConfigKey.company)
              },
              {
                key: 'company-city',
                fieldType: 'textInput',
                placeholder: 'City',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.company.fields.city,
                validationError: this.findError('city'),
                onChange: (e): void => this.handleChange(e.target.value, 'city', EditFieldsConfigKey.company)
              },
              {
                key: 'company-postalCode',
                fieldType: 'textInput',
                placeholder: 'Postal code',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.company.fields.postalCode,
                validationError: this.findError('postalCode'),
                onChange: (e): void => this.handleChange(e.target.value, 'postalCode', EditFieldsConfigKey.company)
              }
            ]
          }
        ]
      },
      businessAddress: {
        sections: [
          {
            width: '100',
            fields: [
              {
                key: 'businessNameLabel',
                fieldType: 'labelSmall',
                copy: 'Name'
              },
              {
                key: 'businessAddress-businessName',
                fieldType: 'textInput',
                placeholder: 'Business name',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.businessAddress.fields.businessName,
                validationError: this.findError('businessName'),
                onChange: (e): void => this.handleChange(e.target.value, 'businessName', EditFieldsConfigKey.businessAddress)
              },
              {
                key: 'businessAddressLabel',
                fieldType: 'labelSmall',
                copy: 'Business address'
              },
              {
                key: 'businessAddress-address1',
                fieldType: 'textInput',
                placeholder: 'Address line 1',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.businessAddress.fields.address1,
                validationError: this.findError('address1'),
                onChange: (e): void => this.handleChange(e.target.value, 'address1', EditFieldsConfigKey.businessAddress)
              },
              {
                key: 'businessAddress-address2',
                fieldType: 'textInput',
                placeholder: 'Address line 2',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.businessAddress.fields.address2,
                validationError: this.findError('address2'),
                onChange: (e): void => this.handleChange(e.target.value, 'address2', EditFieldsConfigKey.businessAddress)
              },
              {
                key: 'businessAddress-city',
                fieldType: 'textInput',
                placeholder: 'City',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.businessAddress.fields.city,
                validationError: this.findError('city'),
                onChange: (e): void => this.handleChange(e.target.value, 'city', EditFieldsConfigKey.businessAddress)
              },
              {
                key: 'businessAddress-postalCode',
                fieldType: 'textInput',
                placeholder: 'Postal code',
                value: this.props.merchantDetailStore!.merchantDetailFieldValues.businessAddress.fields.postalCode,
                validationError: this.findError('postalCode'),
                onChange: (e): void => this.handleChange(e.target.value, 'postalCode', EditFieldsConfigKey.businessAddress)
              }
            ]
          }
        ]
      }
    };
  };

  private cancelPlanChange = async (): Promise<void> => {
    const proposedUpdate = this.props.merchantDetailStore!.selectedMerchant?.proposedUpdate;

    if (!proposedUpdate?.id) return;

    this.props.modalStore!.triggerModal<ConfirmationModalData>({
      modalType: 'confirmation',
      data: {
        title: 'Change of plans?',
        copy: (
          <>
            Please confirm that you would like to cancel the {proposedUpdate.plan ? 'plan' : 'stage'} change request
            {' '}
            made by <strong>{proposedUpdate.createdBy?.givenName} {proposedUpdate.createdBy?.familyName}</strong>
            {' '}
            on <strong>{TimeService.dateMonthYearShort(proposedUpdate.createdAt)}</strong>
          </>
        ),
        confirmButtonCopy: 'Confirm',
        cancelButtonCopy: 'Cancel',
        errorCopy: '',
        confirmButtonAction: async (): Promise<any> => new Promise(async (resolve): Promise<void> => {
          try {
            await MerchantSubscriptionService.deleteProposedMerchantUpdate({
              id: this.props.merchantDetailStore!.selectedMerchant!.proposedUpdate!.id
            });

            await SettingsService.MerchantsSettingsService.fetchMerchant(this.props.merchantDetailStore!.selectedMerchant!.id);

            this.props.toasterStore!.popSuccessToast(`${proposedUpdate.plan ? 'Plan' : 'Stage'} change`, 'delete');
          } catch (error) {
            this.props.toasterStore!.popErrorToast(`the ${proposedUpdate.plan ? 'plan' : 'stage'} change`, 'delete');
          }
          resolve('');
        })
      }
    });
  };

  private onUpdateMerchant = async (fields: MerchantDetailFields, category: EditFieldsConfigKey): Promise<void> => {
    if (!!this.props.merchantDetailStore!.merchantDetailFieldValues[category].isDirty) {
      try {
        await this.MerchantEditService.updateMerchant(fields, category, this.props.merchantDetailStore!.selectedMerchant!);

        this.props.merchantDetailStore!.clearForm(category);
        this.props.toasterStore!.popSuccessToast('Merchant', 'save');
      } catch (error) {
        this.props.toasterStore!.popToast('We\'re having trouble saving the merchant.', ToastType.Error);
      }
    }
  };

  private reIndexProducts = async (): Promise<void> => {
    this.setState({ isReIndexingProducts: true });

    try {
      await this.MerchantEditService.reIndexProducts(this.props.merchantDetailStore!.selectedMerchant!.id);

      this.setState({ hasReIndexed: true });
      this.props.toasterStore!.popSuccessToast('Products', 'save');
    } catch (error) {
      this.props.toasterStore!.popToast('We\'re having trouble resyncing the merchants products.', ToastType.Error);
    }
    this.setState({ isReIndexingProducts: false });
  };

  private onToggleFeature = async (
    active: boolean,
    key: MerchantFeatureKey
  ): Promise<any> => {
    this.setState({ isTogglingFeatureFlag: true });

    try {
      await this.MerchantEditService.toggleMerchantFeature(active, this.props.merchantDetailStore!.selectedMerchant!, key);

      SettingsService.SettingsAnalytics.toggleMerchantFeature();

      this.props.toasterStore!.popSuccessToast('Merchant', 'save');
    } catch (error) {
      this.props.toasterStore!.popToast('We\'re having trouble saving the merchant.', ToastType.Error);
    }

    this.setState({ isTogglingFeatureFlag: false });
  };

  private triggerFormEditModal = (category: EditFieldsConfigKey): any => {
    this.props.modalStore!.triggerModal({
      modalType: 'fieldEdit',
      data: {
        title: this.props.merchantDetailStore!.selectedMerchant!.title,
        isDirty: (): boolean => this.props.merchantDetailStore!.merchantDetailFieldValues[category].isDirty,
        config: (): FormBuilderConfig => this.EDIT_FIELDS_CONFIG()[category],
        confirmButtonAction: async (): Promise<void> => new Promise(async (resolve, reject): Promise<any> => {
          try {
            const validationSchema = getFormValidation(category, this.props.merchantDetailStore!.selectedMerchant?.currency === Currency.Usd);
            const merchantDetail: any = await ValidationService.validateAll(
              validationSchema,
              this.props.merchantDetailStore!.merchantDetailFieldValues[category].fields
            );

            await this.onUpdateMerchant(merchantDetail, category);

            this.props.merchantDetailStore!.clearForm(category);
            resolve();
          } catch (error: any) {
            this.props.merchantDetailStore!.setErrors(error.errors);
            reject();
          }
        })
      }
    });
  };

  private triggerSubscriptionModal = async (): Promise<void> => {
    const merchant = this.props.merchantDetailStore!.selectedMerchant!;
    const planName = LocalisationService.localisePlanName(merchant.plan?.type);

    this.props.modalStore!.triggerModal<SubscriptionPaymentModalData>({
      modalType: 'subscriptionPayment',
      data: {
        title: `Subscribe merchant to ${planName}`,
        merchant: merchant,
        termsEntryPoint: Analytics.TermsEntryPoint.MerchantDetail,
        shouldDisplayTerms: true,
        submitButtonCopy: 'Subscribe merchant',
        renderHeader: (): ReactNode => (
          <PlanDetails merchant={merchant} />
        ),
        onSubmit: async (props): Promise<void> => {
          try {
            await props.onInitialSubscribe!(merchant.id);
            const updatedMerchant = await SettingsService.MerchantsSettingsService.fetchMerchant(merchant.id);

            if (updatedMerchant.subscription?.status === SubscriptionStatus.Active && !!updatedMerchant.subscription?.stripeSubscriptionId) {
              this.props.toasterStore!.popSuccessToast(`${merchant.title}`, 'subscribe');

              return Promise.resolve();
            }

            return Promise.reject();
          } catch (error) {
            window.Sentry.captureException(error);
          }
        }
      }
    });
  };

  private triggerPlanChangeModal = (): void => {
    this.props.modalStore!.triggerModal<PlanShiftModalData>({
      modalType: 'planShift',
      data: {
        merchant: this.props.merchantDetailStore!.selectedMerchant!,
        beforeClose: async (): Promise<void> => {
          await SettingsService.MerchantsSettingsService.fetchMerchant(this.props.merchantDetailStore!.selectedMerchant!.id);
        }
      }
    });
  };

  private triggerStageChangeModal = (): void => {
    this.props.modalStore!.triggerModal<PlanShiftModalData>({
      modalType: 'stageShift',
      data: {
        merchant: this.props.merchantDetailStore!.selectedMerchant!,
        beforeClose: async (): Promise<void> => {
          await SettingsService.MerchantsSettingsService.fetchMerchant(this.props.merchantDetailStore!.selectedMerchant!.id);
        }
      }
    });
  };

  private renderEmailAddress = (): ReactNode => {
    const merchant = this.props.merchantDetailStore!.selectedMerchant;

    if (!merchant?.createdBy?.email) return null;

    const lastLoginAt = merchant?.createdBy?.lastLoginAt;

    return (
      <Flex mt="20px">
        <Styles.IconBox>
          <Icon
            iconName="mail"
            pathFill={colors.shade40}
          />
        </Styles.IconBox>
        <Box>
          <a href={`mailto:${merchant?.createdBy?.email}`}>
            <StyledLink>
              {merchant?.createdBy?.email}
            </StyledLink>
          </a>
          <Styles.MetaSubCopy>
            Last logged in: {lastLoginAt ? TimeService.longFormatWithoutDate(lastLoginAt) : 'Not logged in yet'}
          </Styles.MetaSubCopy>
        </Box>
      </Flex>
    );
  };

  render(): ReactNode {
    const merchant = this.props.merchantDetailStore!.selectedMerchant!;
    const isLoading = this.props.merchantDetailStore!.isLoadingSelectedMerchant;

    return (
      <WithLoading
        isLoading={isLoading}
        hasNoResults={!isLoading && !merchant}
        loaderSize="small"
        renderNoResults={(): ReactNode => (
          <Box p="30px 15px">
            <NoResultsGeneric
              image={NoResultsGif}
              heading="Merchant not found"
              copy="Please go back to the merchant list and try again"
            />
          </Box>
        )}
      >
        {!!merchant && (
          <Box p="15px 15px">
            <Flex>
              <Styles.DetailsColumn mr="20px">
                <Styles.GreyBox mb="20px">
                  <Flex
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Box>
                      <h3>{merchant.title}</h3>
                    </Box>
                    <button onClick={(): any => this.triggerFormEditModal(EditFieldsConfigKey.basicMerchantDetails)}>
                      <Icon iconName="edit" />
                    </button>
                  </Flex>

                  <CopyMetadataItem
                    iconName="person"
                    shouldDisplay={() => !!merchant.createdBy?.givenName || !!merchant.createdBy?.familyName}
                    copy={(): ReactNode => `${merchant.createdBy?.givenName} ${merchant.createdBy?.familyName}`}
                  />

                  {this.renderEmailAddress()}

                  <SubscriptionStatusDetail
                    onCompleteSubscription={this.triggerSubscriptionModal}
                  />

                  <CopyMetadataItem
                    iconName="pie-empty"
                    shouldDisplay={() => !!merchant.subscription?.currentPeriodStartsAt}
                    copy={(): ReactNode => (
                      <>
                        <strong>Billing period starts:</strong>: {TimeService.humanDateMonthYear(merchant.subscription?.currentPeriodStartsAt)}
                      </>
                    )}
                  />

                  <CopyMetadataItem
                    iconName="pie-two-thirds"
                    shouldDisplay={() => !!merchant.subscription?.currentPeriodStartsAt}
                    copy={(): ReactNode => (
                      <>
                        <strong>Billing period ends:</strong>: {TimeService.humanDateMonthYear(merchant.subscription?.currentPeriodEndsAt)}
                      </>
                    )}
                  />

                  <CopyMetadataItem
                    iconName="plus-circle"
                    shouldDisplay={() => !!merchant.createdBy?.givenName || !!merchant.createdBy?.familyName}
                    copy={(): ReactNode => (
                      <>
                        <strong>Created</strong>: {TimeService.longFormatWithYear(merchant.createdAt)}
                      </>
                    )}
                  />
                  <CopyMetadataItem
                    iconName="phone"
                    shouldDisplay={() => !!merchant?.createdBy?.phone}
                    copy={(): ReactNode => (merchant?.createdBy?.phone)}
                  />
                  <CopyMetadataItem
                    iconName="map-pin-circle"
                    shouldDisplay={() => !!merchant.currency}
                    copy={(): ReactNode => LocaleService.getLocaleByCurrency(merchant.currency!)}
                  />
                </Styles.GreyBox>

                <Styles.GreyBox>
                  <CompletionItem
                    isComplete={(): boolean => !!merchant.acceptedTermsAt || !!merchant.acceptedTermsAt?.length}
                    copy={(): string => 'Agreed to terms'}
                  />
                  <CompletionItem
                    isComplete={(): boolean => !!merchant.isNewsletterRegistered}
                    copy={(): string => 'Registered to Newsletter'}
                  />
                  <CompletionItem
                    isComplete={(): boolean => !!merchant.flc_id}
                    copy={(): string => 'Has access to floom.com'}
                  />
                </Styles.GreyBox>
                <WithFeatureFlag
                  render={config => {
                    return (
                      <Fragment>
                        {
                          this.featureToggles.map((feature, index): ReactNode => {
                            if (!feature.isVisible(config.flags)) return null;

                            return (
                              <Styles.FeatureToggleFlag key={index}>
                                <Flex alignItems="center">
                                  <CheckboxToggle
                                    onChange={(isActive): void => {
                                      this.onToggleFeature(isActive, feature.featureKey);
                                    }}
                                    switchBgActive={colors.green}
                                    switchBgInactive={colors.floomRed}
                                    isChecked={!!this.props.merchantDetailStore?.selectedMerchant?.[feature.featureKey]}
                                    controlValue={true}
                                    isDisabled={this.state.isTogglingFeatureFlag}
                                  />
                                  <Box ml="10px">
                                    {feature.copy}
                                  </Box>
                                </Flex>
                              </Styles.FeatureToggleFlag>
                            );
                          })
                        }
                      </Fragment>
                    );
                  }}
                />
                <Styles.GreyBox
                  mt="30px"
                >
                  <Flex
                    flexDirection="column"
                  >
                    <Box
                      onClick={this.reIndexProducts}
                      mr="auto"
                      mb="15px"
                    >
                      <Button
                        copy="Re-sync products"
                        isLoading={this.state.isReIndexingProducts}
                        isDisabled={this.state.hasReIndexed}
                      />
                    </Box>
                    <Styles.ReIndexInstructions>
                      Pushing this button will update all of this merchants products for Floom.com search
                    </Styles.ReIndexInstructions>
                  </Flex>
                </Styles.GreyBox>
              </Styles.DetailsColumn>

              <Styles.DetailsColumn>
                <Styles.WhiteBox pb="10px">
                  <Flex>
                    <Styles.IconBox>
                      <Icon
                        iconName="membership"
                        pathFill={colors.shade40}
                      />
                    </Styles.IconBox>
                    <Box
                      p="4px"
                      width="100%"
                    >
                      <Flex
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Box mb="8px">
                          <h5>Current plan</h5>
                        </Box>
                        {this.canEditPlan() && (
                          <button onClick={this.triggerPlanChangeModal}>
                            <Icon iconName="edit" />
                          </button>
                        )}
                      </Flex>
                      <Box mb="5px">
                        {LocalisationService.localisePlanName(merchant.plan?.type)}
                      </Box>
                      {!!merchant?.subscription?.trialLength && (
                        <Box mb="5px">
                          <strong>Trial period:</strong> {merchant.subscription.trialLength} days
                        </Box>
                      )}
                    </Box>
                  </Flex>
                  <Flex mt="30px">
                    <Styles.IconBox>
                      <Icon
                        iconName="chart"
                        pathFill={colors.shade40}
                      />
                    </Styles.IconBox>
                    <Box
                      p="5px"
                      width="100%"
                    >
                      <Flex
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Box mb="8px">
                          <h5>Current stage</h5>
                        </Box>
                        {this.canEditStage() && (
                          <button onClick={this.triggerStageChangeModal}>
                            <Icon iconName="edit" />
                          </button>
                        )}
                      </Flex>
                      <Box mb="5px">
                        {LocalisationService.localiseMerchantStage(merchant.stage)}
                      </Box>
                    </Box>
                  </Flex>
                  <ProposedMerchantUpdateContext
                    onClick={this.cancelPlanChange}
                    proposedUpdate={merchant.proposedUpdate}
                    subscription={merchant.subscription}
                    currency={merchant.currency}
                  />
                </Styles.WhiteBox>
                <Styles.WhiteBox>
                  <Flex>
                    <Styles.IconBox>
                      <Icon
                        iconName="company"
                        pathFill={colors.shade40}
                      />
                    </Styles.IconBox>
                    <Box
                      p="5px"
                      width="100%"
                    >
                      <Flex
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Box mb="8px">
                          <h5>Company</h5>
                        </Box>
                        <button onClick={(): any => this.triggerFormEditModal(EditFieldsConfigKey.company)}>
                          <Icon iconName="edit" />
                        </button>
                      </Flex>
                      <Box mb="5px">
                        <strong>Name:</strong> {merchant.address?.businessName}
                      </Box>
                      <Box mb="5px">
                        <strong>Trading number:</strong> {merchant.tradingNo}
                      </Box>
                      <Box>
                        <strong>Registered address:</strong>
                        {!!merchant?.registeredAddress && (
                          <MerchantDetailAddress
                            heading="Address"
                            type="billingAddress"
                            isBusiness={false}
                            address={merchant.registeredAddress}
                            canEdit={false}
                            hidePhone={true}
                          />
                        )}
                      </Box>
                    </Box>
                  </Flex>
                </Styles.WhiteBox>

                <Styles.WhiteBox>
                  <Flex>
                    <Styles.IconBox>
                      <Icon
                        iconName="shop"
                        pathFill={colors.shade40}
                      />
                    </Styles.IconBox>
                    <Box
                      p="5px"
                      width="100%"
                    >
                      <Flex
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <Box mb="8px">
                          <h5>Trading address</h5>
                        </Box>
                        <button onClick={(): any => this.triggerFormEditModal(EditFieldsConfigKey.businessAddress)}>
                          <Icon iconName="edit" />
                        </button>
                      </Flex>
                      {!!merchant?.address && (
                        <MerchantDetailAddress
                          heading="Business Address"
                          type="billingAddress"
                          isBusiness={false}
                          address={merchant.address}
                          canEdit={false}
                          hidePhone={true}
                        />
                      )}
                    </Box>
                  </Flex>
                </Styles.WhiteBox>
                <Styles.WhiteBox>
                  <Box pl="40px">
                    <MerchantInvite />
                  </Box>
                </Styles.WhiteBox>
              </Styles.DetailsColumn>
            </Flex>
          </Box>
        )}
      </WithLoading>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantSettingsStore: stores.merchantSettingsStore,
  merchantDetailStore: stores.merchantDetailStore,
  toasterStore: stores.toasterStore,
  modalStore: stores.modalStore
}))(observer(MerchantDetailContent));

import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

const getColorHexForRagStatus = (status: string): string => {
  switch (status) {
    case 'green':
      return '#44AA7B';

    case 'amber':
      return '#FF8D6B';

    case 'red':
      return '#EF554D';

    default:
      return colors.middleGrey;
  }
};

export const ChecksContainer = styled(Flex)`
  @media (min-width: 565px) {
    justify-content: end;
  }

  @media (max-width: 565px) {
    overflow-x: scroll;
  }
`;

export const CheckContainer: any = styled(Box)`
  margin-right: 20px;
  min-width: 130px;
  border-radius: 4px;
  font-size: 14px;
  transition: opacity 0.2s ease-in-out;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 850px) {
    margin-right: 5px;
  }

  @media (max-width: 565px) {
    min-width: 150px;
  }
`;

export const CheckTitleContainer = styled.div`
  background: white;
  padding: 7px 12px;
  border-radius: 4px 4px 0 0;
  border: 1px solid #e5e5e5;
  border-bottom-color: #efefef;
`;

export const CheckTitle = styled(Flex)`
  font-weight: 600;
  margin-bottom: 4px;
  align-items: center;

  @media (max-width: 565px) {
    font-size: 13px;
  }
`;

export const CheckTitleExtra = styled.span`
  margin-left: 3px;
  margin-top: 2px;
  font-size: 11px;
`;

export const CheckSubtitle = styled(Flex)`
  font-size: 11px;
  font-weight: 300;
  color: ${colors.grey};
`;

export const CheckContent = styled(Flex)`
  padding: 5px 12px 7px;
  border: 1px solid #e5e5e5;
  background: ${({ status }: any): any => getColorHexForRagStatus(status)};
  transition: background 0.2s ease-in-out;
  color: white;
  border-top: none;
  border-radius: 0 0 4px 4px;
  font-size: 17px;
  align-content: baseline;
`;

export const CheckPercentage = styled(Box)`
  font-weight: 600;
`;

export const CheckCounts = styled(Box)`
  font-size: 13px;
  align-self: flex-end;
  margin-left: 6px;
`;

export const Title = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 12px;
  font-weight: 600;
`;

export const LastUpdated = styled.div`
  font-size: 12px;
  font-style: italic;
  color: ${colors.grey};
  margin-bottom: 2px;
`;

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { GenericListObject } from 'components/generic-select-list/generic-select-list.types';
import Icon from 'components/icon';
import ImgixImage from 'components/imgix-image';

import * as Styles from './create-addon-select-array.styles';
import { CreateAddonSelectArrayProps } from './create-addon-select-array.types';

class CreateAddonSelectArray extends Component<CreateAddonSelectArrayProps> {
  getselectedAddonIds = (selectedAddonId: string, selectedAddon: GenericListObject): JSX.Element => (
    <Styles.SelectedItem key={selectedAddonId}>
      <Styles.MediaWrapper>
        <ImgixImage
          config={{
            path: selectedAddon.mediaSource!
          }}
          width={70}
          height={70}
          css={Styles.SelectedItemMedia}
        />
      </Styles.MediaWrapper>
      <Styles.SelectedItemTitle>
        {selectedAddon.title}
      </Styles.SelectedItemTitle>
      <Styles.SelectedItemDetails>
        {selectedAddon.details}
      </Styles.SelectedItemDetails>
      <Styles.SelectedItemDeleteCross onClick={(): void => this.props.deselectAddon(selectedAddonId)}>
        <Icon
          iconName="cross-big"
        />
      </Styles.SelectedItemDeleteCross>
    </Styles.SelectedItem>
  );

  displayNewAddonButton = (): boolean => {
    return this.props.selectedAddonIds.length <= this.props.productEditStore!.addons.length;
  };

  render(): JSX.Element {
    return (
      <Styles.SelectedWrapper>
        {
          this.props.selectedAddonIds.map((selectedAddonId: string): JSX.Element => {
            return this.getselectedAddonIds(
              selectedAddonId,
              this.props.addons.filter(addon => addon.id === selectedAddonId)[0]
            );
          })
        }
        <div onClick={(): void => this.props.openModal()}>
          {
            this.displayNewAddonButton()
              ? (
                <Styles.SelectNewItem>
                  <Icon
                    iconName="plus-large"
                  />
                  <p>Add add-on</p>
                </Styles.SelectNewItem>
              )
              : undefined
          }

        </div>
      </Styles.SelectedWrapper>
    );
  }
}

export default inject('productEditStore')(observer(CreateAddonSelectArray));

import styled from '@emotion/styled';
import { Box, Card as RebassCard, Flex } from 'rebass';

import { borderRadius, colors, fonts, fontSizes, space } from 'utils/rebass-theme';

export const Contacts = styled(RebassCard)<any>`
  flex: 0 0 100%;
`;

export const ContactWrapper = styled(Flex)<{ active: boolean }>`
  font-family: ${fonts.secondary};
  font-size: ${fontSizes.small}px;
  font-weight: 700;
  border-radius: ${borderRadius.large};
  padding: ${space[3]}px ${space[2]}px;

  align-items: center;

  @media (any-hover: hover) {
    &:hover {
      cursor: pointer;
      ${({ active }) => active ? `background: ${colors.paleGrey};` : `background: ${colors.lightGrey}`};
    }
  }

  ${({ active }) => active ? `
    background: ${colors.paleGrey};
    color: ${colors.floomBlue};
  ` : ''};
`;

export const PhoneNumber = styled(Box)`
  font-size: ${fontSizes.xSmall}px;
  color: ${colors.darkGrey};
  font-weight: 400;
  margin-top: 2px;
`;

export const NameWrapper = styled(Box)`
  padding: 0px ${space[2]}px;
  flex-grow: 1;
`;

export const UnreadCount = styled(Box)`
  font-size: ${fontSizes.xSmall}px;
  color: ${colors.white};
  border-radius: 10px;
  padding: 2px 8px 4px 8px;
  background: ${colors.floomBlue};
`;

import { gql } from '@apollo/client';

import {
  LIST_WITH_ITEM_METADATA_FRAGMENT,
  LIST_WITHOUT_ITEM_METADATA_FRAGMENT
} from '../fragments/list.fragments';

export const SINGLE_LIST = gql`
  query SingleList(
    $where: ListWhereUniqueInput!
  ) {
    list(
      where: $where
    ) {
      ...ListWithItemMetadataFragment
    }
  }
  ${LIST_WITH_ITEM_METADATA_FRAGMENT}
`;

export const LIST_DELIVERY_DATES = gql`
  query ListDeliveryDates(
    $where: ListWhereUniqueInput!
  ) {
    wholesalePreOrderDeliveryDates(
      where: $where
    ) {
      dateString
      canDeliver
      cutOffAt
    }
  }
`;

export const LISTS = gql`
  query Lists (
    $orderBy: ListOrderByInput,
    $first: Int,
    $skip: Int,
    $where: ListWhereInput
  ) {
    listsConnection(
      orderBy: $orderBy,
      first: $first,
      skip: $skip,
      where: $where
    ) {
      pageInfo {
        hasNextPage
      }
      aggregate {
        count
      }
      edges {
        node {
          ...ListWithoutItemMetadataFragment
        }
      }
    }
  }
  ${LIST_WITHOUT_ITEM_METADATA_FRAGMENT}
`;

export const LIST_ITEM = gql`
  query ListItem(
    $listId: ID!
    $itemId: ID!
  ) {
    list (where: {
      id: $listId
    }) {
      items (where: {
        id: $itemId
      }) {
        ... on ListItemTypeFlower {
          id
          sku
          colour {
            hex
            name
          }
          quantity
          minimumStemLength
          maturity
          type
          catalogItem {
            id
            primaryName
            secondaryName
            tradeSku(
              first: 20
            ) {
              availability(
                orderBy: createdAt_DESC
                first: 20,
                where: {
                  isLive: true
                }
              ) {
                supplier {
                  id
                  active
                }
                price {
                  currency
                  price
                }
                media {
                  src
                }
              }
              flower {
                maturity
                minimumStemLength
              }
            }
            media {
              id
              title
              src
              updatedAt
            }
          }
          list {
            merchant {
              currency
            }
          }
        }

        ... on ListItemTypePlant {
          id
          sku
          colour {
            hex
            name
          }
          quantity
          type
          catalogItem {
            id
            primaryName
            secondaryName
            tradeSku(
              first: 20
            ) {
              availability(
                orderBy: createdAt_DESC
                first: 20,
                where: {
                  isLive: true
                }
              ) {
                supplier {
                  id
                  active
                }
                price {
                  currency
                  price
                }
              }
            }
            media {
              id
              title
              src
              updatedAt
            }
          }
          list {
            merchant {
              currency
            }
          }
        }

        ... on ListItemTypeSundry {
          id
          sku
          colour {
            hex
            name
          }
          quantity
          type
          catalogItem {
            id
            primaryName
            secondaryName
            tradeSku(
              first: 20
            ) {
              availability(
                orderBy: createdAt_DESC
                first: 20,
                where: {
                  isLive: true
                }
              ) {
                supplier {
                  id
                  active
                }
                price {
                  currency
                  price
                }
              }
            }
            media {
              id
              title
              src
              updatedAt
            }
          }
          list {
            merchant {
              currency
            }
          }
        }

        ... on ListItemTypeDecoration {
          id
          sku
          colour {
            hex
            name
          }
          quantity
          type
          catalogItem {
            id
            primaryName
            secondaryName
            tradeSku(
              first: 20
            ) {
              availability(
                orderBy: createdAt_DESC
                first: 20,
                where: {
                  isLive: true
                }
              ) {
                supplier {
                  id
                  active
                }
                price {
                  currency
                  price
                }
              }
            }
            media {
              id
              title
              src
              updatedAt
            }
          }
          list {
            merchant {
              currency
            }
          }
        }

        ... on ListItemTypeCustom {
          id
          sku
          colour {
            hex
            name
          }
          quantity
          type
          catalogItem {
            id
            primaryName
            secondaryName
            tradeSku(
              first: 20
            ) {
              availability(
                orderBy: createdAt_DESC
                first: 20,
                where: {
                  isLive: true
                }
              ) {
                supplier {
                  id
                  active
                }
                price {
                  currency
                  price
                }
              }
            }
            media {
              id
              title
              src
              updatedAt
            }
          }
          list {
            merchant {
              currency
            }
          }
        }
      }
    }
  }
`;

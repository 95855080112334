import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Text, Flex } from 'rebass';

import { marketingZIndexes as z } from 'global.constants';

import {
  maxContentWidth,
  gridGutter,
  breakpoints,
  textStyles,
  colors
} from 'utils/rebass-theme';

import BlobSectionHatSVG0 from 'assets/icons/bubbles/blob-section-hat-0.svg';
import BlobSectionHatSVG1 from 'assets/icons/bubbles/blob-section-hat-1.svg';

export const hats = [BlobSectionHatSVG0, BlobSectionHatSVG1];

export const getSectionHatStyle: any = ({
  topVmin,
  heightVmin,
  leftPercent,
  rightPercent,
  bgColor,
  flipX = false
}: any): any => {
  return css`
    position: absolute;
    top: ${topVmin}vmin;
    height: ${heightVmin}vmin;
    left: ${leftPercent}%;
    right: ${rightPercent}%;
    width: ${100 - leftPercent - rightPercent}%;
    z-index: ${z.contentBelow};
    ${flipX ? 'transform: scaleX(-1);' : ''}
    path:first-of-type {
      fill: ${bgColor || colors.floomCream};
    }
  `;
};

export const SectionInnerWrapper: any = styled(Flex)`
  display: flex;
  max-width: ${maxContentWidth}px;
  margin: 0 ${gridGutter}px;
  flex: 1 1 auto;
  flex-wrap: wrap;
`;

export const Section: any = styled(Box)`
  color: ${({ color }: any): string => color || 'inherit'};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${gridGutter * 6}px;
  overflow: visible;
  position: relative;
  ${({ sectionHatParams }: any): SerializedStyles => {
    const { topVmin = 0, padFactor = 0 } = sectionHatParams || {};

    return css`
      margin-top: ${-topVmin * padFactor}vmin;
    `;
  }}
  @media (min-width: ${breakpoints.small}) {
    min-height: ${gridGutter * 8}px;
  }
  :before {
    content: '';
    left: 0;
    right: 0;
    ${({ sectionHatParams }: any): SerializedStyles => {
    const { topVmin = 0, heightVmin = 0, bottomOverrun = -200 } =
        sectionHatParams || {};

    return css`
        top: ${topVmin + heightVmin - 0.1}vmin;
        bottom: ${bottomOverrun}px;
      `;
  }}
    background-color: ${({ bgColor }: any): string => bgColor || colors.floomCream};
    z-index: ${z.background};
    position: absolute;
  }
`;

export const SectionContent: any = styled(Flex)`
  flex-wrap: wrap;
  flex-grow: 1;
  width: 100%;
  text-align: center;
  padding: ${gridGutter * 3}px 0;

  @media (min-width: ${breakpoints.medium}) {
    text-align: left;
  }
`;

export const SectionHeading: any = styled(Text)`
  display: flex;
  ${({ textAlign }: any): SerializedStyles => textAlign === 'left'
    ? css`
          justify-content: flex-start;
          text-align: left;
        `
    : css`
          justify-content: center;
          text-align: center;
        `}
`;

export const SectionHeadingText: any = css`
  ${textStyles.h2}
`;

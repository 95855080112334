import React, { ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Text } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

import SectionHeading from 'components/section-heading';

import * as Styles from './edit-delivery-days.styles';
import * as Types from './edit-delivery-days.types';

const MINIMUM_DAYS_SELECTED = 'You need to have a minimum of 2 delivery days selected';

class EditDeliveryDays extends React.Component<Types.EditDeliveryDaysProps> {
  render(): ReactNode {
    return (
      <>
        <SectionHeading title="Days" />
        <Styles.DeliveryDays>
          {
            this.props.deliveryStore!.deliveryDays.map(day => (
              <Styles.DeliveryDaySquare
                key={day.title}
                selected={day.selected}
                onClick={(): void => this.props.deliveryStore!.toggleSelectedDeliveryDay(day.title)}
              >

                <Text
                  css={textStyles.body}
                  color={day.selected ? colors.floomMidnightBlue : colors.shade40}
                >
                  {day.title[0]}
                </Text>
              </Styles.DeliveryDaySquare>
            ))
          }
        </Styles.DeliveryDays>
        <Text
          css={textStyles.body}
        >
          {MINIMUM_DAYS_SELECTED}
        </Text>
      </>
    );
  }
}

export default inject('deliveryStore')(observer(EditDeliveryDays));

import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import _get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { OrderDeliveryState } from 'generated-types.d';

import { textStyles, colors } from 'utils/rebass-theme';

import {
  buildPickupDay,
  buildPickupTime
} from 'features/courier/components/courier-status-indicator/courier-status-indicator.helpers';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';

interface CourierDeliveryItemMetaProps {
  data: string;
  status: OrderDeliveryState;
}

class CourierDeliveryTimeMeta extends Component<CourierDeliveryItemMetaProps> {
  private renderDeliveryTimeCopy = (status: OrderDeliveryState): string => {
    if (status === OrderDeliveryState.Delivered) {
      return 'Delivered at';
    }

    return 'Est. delivery time';
  };

  render(): ReactNode {
    return (
      <MetaCardContentWrapper iconName="delivery-time">
        <Box>
          <div
            css={css`
              ${textStyles.label};
              color: ${colors.middleGrey};
              text-transform: uppercase;
            `}
          >
            {this.renderDeliveryTimeCopy(this.props.status)}
          </div>
          <div css={textStyles.subhead}>
            {buildPickupTime(this.props.data)}, {buildPickupDay(this.props.data)}
          </div>
        </Box>
      </MetaCardContentWrapper>
    );
  }
}

export default inject('courierStore')(observer(CourierDeliveryTimeMeta));

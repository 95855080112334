import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import moment from 'moment-timezone';

import { OrderDeliveryState } from 'generated-types.d';

import { StringFormat, TimeService } from 'lib';

import { colors } from 'utils/rebass-theme';

import { courierStatusConfig } from 'features/courier/courier.constants';
import CourierHelpers from 'features/courier/helpers/courier.helpers';

import Icon from 'components/icon';

import * as Styles from './courier-status-reference.styles';
import * as Types from './courier-status-reference.types';

const CANCELLED_COPY = 'Unfortunately, the pick up has been cancelled. \n\n Please contact us for further information.';

class CourierStatusReference extends Component<Types.CourierStatusReferenceProps> {
  private hasReason = (): boolean => !!this.props.courierBooking && !!this.props.courierBooking.lastDeliveryStatusReason;

  private formatDate = (date: Date): string => moment(date.toString()).utc().format('dddd Do of MMMM h:mma');

  private renderCourierRef = (): ReactNode => {
    if (!this.props.courierBooking) return null;

    const config = courierStatusConfig[this.props.courierBooking.deliveryStatus];

    return (
      <Styles.BookingRow
        bg={config.backgroundColour}
        css={css`
          min-width: 250px;
        `}
      >
        <Styles.IconContainer
          css={css`
            height: 26px;
          `}
        >
          <Icon
            iconName={config.iconType}
            pathFill={colors[config.iconColor]}
          />
        </Styles.IconContainer>
        { this.props.courierBooking.courierReferenceId && (
          <Styles.MetaInfo>
            <Styles.Label
              color={colors[config.textColor]}
            >
              Courier ref
            </Styles.Label>
            <Styles.Title
              color={colors[config.textColor]}
            >
              {StringFormat.truncate(this.props.courierBooking.courierReferenceId, 5, '')}
            </Styles.Title>
          </Styles.MetaInfo>
        )}
        { this.props.courierBooking.pickupCode && (
          <Styles.MetaInfo>
            <Styles.Label
              color={colors[config.textColor]}
            >
              Pick up pin
            </Styles.Label>
            <Styles.Title
              color={colors[config.textColor]}
            >
              {this.props.courierBooking.pickupCode}
            </Styles.Title>
          </Styles.MetaInfo>
        )}
      </Styles.BookingRow>
    );
  };

  private buildCollectionCopy = (): string | null => {
    const { pickupDate, deliveryDate } = this.props.courierBooking!;

    switch (this.props.courierBooking!.deliveryStatus) {
      case 'Requested':
      case 'Booked':
      case 'AssignedDriver':
        return `Courier will collect order on ${this.formatDate(pickupDate)}`;

      case 'OutForDelivery':
      case 'PickedUp':
        return `A courier from ${this.props.courierBooking!.courier.title} will deliver your order at ${TimeService.timeOfDay(deliveryDate)}`;

      default:
        return null;
    }
  };

  private setErrorCopy = (): string => {
    return this.props.courierBooking!.deliveryStatus === OrderDeliveryState.Cancelled
      ? CANCELLED_COPY.replace(/\n/g, '<br />')
      : this.props.courierBooking!.lastDeliveryStatusReason || '';
  };

  private renderCollectionInfo = (): JSX.Element | null => {
    const collectionCopy = this.buildCollectionCopy();

    if (!collectionCopy) return null;

    return (
      <Styles.BookingRow>
        <Styles.IconContainer>
          <Icon iconName="hourglass" />
        </Styles.IconContainer>
        <Styles.MetaInfo>
          <Styles.DeliveryMessage>
            {collectionCopy}
          </Styles.DeliveryMessage>
        </Styles.MetaInfo>
      </Styles.BookingRow>
    );
  };

  render(): ReactNode {
    if (!this.props.courierBooking) return null;

    return(
      <Styles.BookingContainer>
        {this.renderCourierRef()}

        { CourierHelpers.hasErrorStatus(this.props.courierBooking.deliveryStatus) && this.hasReason() && (
          <Styles.BookingRow>
            <Styles.MetaInfo>
              <Styles.DeliveryMessage>
                <span dangerouslySetInnerHTML={{ __html: this.setErrorCopy()  }} />
              </Styles.DeliveryMessage>
            </Styles.MetaInfo>
          </Styles.BookingRow>
        )}

        {this.renderCollectionInfo()}
      </Styles.BookingContainer>
    );
  }
}

export default CourierStatusReference;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { Card as CardMixin } from 'utils/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const Heading: any = styled(Flex)`
  align-items: center;
  ${textStyles.footnote};
  padding: 15px 20px;
  background: ${colors.lightGrey};
`;

export const HeadingInner = styled(Box)`
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
`;

export const CardCheckbox = styled(Box)`
  width: 19px;
  height: 19px;
  margin-right: 10px;
  flex: 0 0 19px;
`;

export const HeadingTooltip = css`
  text-decoration: underline;
`;

export const CardContainer = styled(Flex)<any>`
  border: 2px solid transparent;
  border-radius: 3px;
  transition: opacity .2s ease-in-out;
  width: 100%;

  ${({ isSelected }): any => {
    if (isSelected) {
      return css`
        border-color: ${colors.floomMidnightBlue};
      `;
    }
  }}

  ${({ isDisabled }): any => {
    if (isDisabled) {
      return css`
        cursor: not-allowed;
        opacity: .5;
      `;
    }
  }}
`;

export const CardWrapper = styled.div<any>`
  ${CardMixin}
  cursor: ${({ isDisabled }): string => isDisabled ? 'not-allowed' : 'pointer'};
  width: 100%;
`;

export const DeliveryMeta = styled(Box)`
  padding: 20px;
`;

import { gql } from '@apollo/client';

export const USER_UPDATE_MUTATION = gql`
  mutation UpdateUser($args: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser (data: $args, where: $where) {
      id,
      team {
        title
      }
    }
  }
`;

export const PASSWORD_RESET_MUTATION = gql`
  mutation PasswordReset($args: UpdatePasswordInput!, $where: UserWhereUniqueInput!) {
    updatePassword ( data: $args, where: $where ) {
      token
    }
  }
`;

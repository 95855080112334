import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles, colors } from 'utils/rebass-theme';

export const MerchantDetailHeader = styled(Flex)<any>`
  ${textStyles.subhead};
  background: ${({ config }: any): any => colors[config.headingBg]};
  color: ${({ config }: any): any => config.color};
  min-height: 70px;
  padding: 10px 30px;
`;

export const HeaderActions: any = styled(Flex)`
  position: relative;
  padding-right: 20px;
  margin-right: 25px;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: -5px;
    bottom: -5px;
    width: 1px;
    background: ${({ color }: any): any => color};
  }
`;

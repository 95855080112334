import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const Wrapper = styled(Box)<{ imagePosition: string }>`
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0 auto;

  @media (min-width: 900px) {
    display: flex;
    flex-direction: ${({ imagePosition }): string => imagePosition === 'left' ? 'row' : 'row-reverse'};
    justify-content: space-between;
    align-items: center;
    padding-left: ${({ imagePosition }): string => imagePosition === 'left' ? '0' : '20px'};
    padding-right: ${({ imagePosition }): string => imagePosition === 'right' ? '0' : '20px'};
    margin-right: ${({ imagePosition }): string => imagePosition === 'left' ? '5vw' : '0'};
    margin-left: ${({ imagePosition }): string => imagePosition === 'right' ? '5vw' : '0'};
  }

  @media (min-width: 1200px) {
    padding-left: ${({ imagePosition }): string => imagePosition === 'left' ? '0' : '40px'};
    padding-right: ${({ imagePosition }): string => imagePosition === 'right' ? '0' : '40px'};
    margin-right: ${({ imagePosition }): string => imagePosition === 'left' ? '10vw' : '0'};
    margin-left: ${({ imagePosition }): string => imagePosition === 'right' ? '10vw' : '0'};
  }

  @media (min-width: 1800px) {
    margin-right: ${({ imagePosition }): string => imagePosition === 'left' ? '17vw' : '0'};
    margin-left: ${({ imagePosition }): string => imagePosition === 'right' ? '17vw' : '0'};
  }
`;

export const Content = styled(Box)<{ imagePosition: string }>`
  display: flex;
  text-align: center;
  padding: 50px 20px 0;
  color: ${colors.floomMidnightBlue};

  @media (min-width: 900px) {
    max-width: 40em;
    text-align: left;
    align-items: center;
    padding: ${({ imagePosition }): string => imagePosition === 'left' ? '70px 0 70px 60px' : '70px 60px 70px 0'};
  }
`;

export const ContentInner = styled(Box)`
  margin: 0 auto;
`;

export const ImageContainer = styled(Box)`
  max-width: 40em;

  @media (min-width: 900px) {
    min-width: 20em;
    max-width: 50em;
  }
`;

export const ButtonWrapper = styled(Box)`
  margin-top: 20px;

  @media (min-width: 900px) {
    margin-top: 30px;
  }
`;

import styled from '@emotion/styled';

import { colors, borderRadius } from 'utils/rebass-theme';

export const Lozenge = styled.span`
  font-weight: 600;
  font-size: 13px;
  padding: 5px 8px 8px;
  line-height: 1;
  color: ${({ color }: any): any => colors[color]};
  background: ${({ bg }: any): any => colors[bg]};
  border-radius: ${borderRadius.medium};
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};
`;

export const DeleteCross = styled.div`
  margin-left: 15px;
  display: inline-block;
`;

import React, { Component, Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  PlanFeature
} from 'generated-types.d';

import {
  Analytics,
  ProductService,
  MerchantService,
  NavService
} from 'lib';

import { Mode } from 'stores/product-edit-store/product-edit-store-types';

import { EntitySelectorModalData } from 'features/modal-dialogue/components/modals/entity-selector-modal/entity-selector.types';
import ProductServices from 'features/products/services';
import ProductAPIService from 'features/products/services/product-api/product-api.service';
import { renderActiveProductsWarning } from 'features/products/utils/product-copy-helpers';

import Button from 'components/button';
import CheckboxToggle from 'components/checkbox-toggle';
import GenericModal from 'components/generic-modal';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import CollectionProductContent from '../collection-product-content';
import CreateBreadcrumbLayout from '../create-breadcrumb-layout';
import TabContent from '../create-layout-tabs';

import * as Styles from './create-layout-inner-content.styles';
import { CreateLayoutInnerContentProps } from './create-layout-inner-content.types';

class CreateLayoutInnerContent extends Component<CreateLayoutInnerContentProps> {
  ProductAnalytics = ProductServices.ProductAnalytics;

  state = {
    isLoadingActiveProducts: false,
    deleteProductModalOpen: false
  };

  private handleAvailabilityChange = (isActive: boolean): void => {
    this.props.productEditStore!.setActive(isActive);
    this.ProductAnalytics.onEditAvailability(isActive, Analytics.ProductAvailabilityEntry.EditPage);
  };

  private onAvailabilityChange = (isActive: boolean): void => {
    if (this.canActivateProduct() || this.props.productEditStore?.active) {
      this.handleAvailabilityChange(isActive);

      return;
    }
    this.triggerProductModal(isActive);
  };

  private triggerProductModal = async (isActive: boolean): Promise<void> => {
    this.setState({ isLoadingActiveProducts: true });

    const merchant = this.props.productEditStore?.productMerchant! || this.props.merchantStore!.merchant!;
    const allActiveProducts = await ProductService.fetchAllProducts({
      active: true,
      merchant: {
        id: merchant.id
      }
    });

    this.props.modalStore!.triggerModal<EntitySelectorModalData>({
      modalType: 'entitySelector',
      data: {
        initialSelectedIDs: [],
        allItems: allActiveProducts.map(({ node }) => ({
          title: node.title,
          id: node.id,
          mediaSource: node.variations?.[0].media?.[0]?.src || '',
          altTitle: node.title
        })),
        modalProps: {
          title: 'Active product limit reached',
          confirmButtonText: 'Disable product'
        },
        notificationCopy: renderActiveProductsWarning(
          merchant.subscription?.productLimit!,
          this.props.productEditStore!.title
        ),
        canConfirm: (): boolean => true,
        selectItems: async (productIDsToDisable): Promise<void> => {
          const productToDisable = allActiveProducts.find(product => product.node.id === productIDsToDisable[0]);

          await ProductAPIService.toggleActiveStatus(productIDsToDisable[0], false);
          this.handleAvailabilityChange(isActive);
          this.props.toasterStore!.popNotificationToast(`You've disabled ${productToDisable?.node.title || 'a product'} successfully. Remember to press save to make sure this product is set to active`);
        },
        renderExtraAction: (modalInstance): any => (
          <>
            <Box
              onClick={(): void => {
                modalInstance.props.closeModal();
              }}
            >
              <Button
                size="normal"
                appearance="secondary"
                copy="Go back"
                isLoading={false}
                isDisabled={false}
              />
            </Box>
          </>
        )
      }
    });

    this.setState({ isLoadingActiveProducts: false });
  };

  private canActivateProduct = (): boolean => {
    return this.props.productEditStore!.canActivateProduct;
  };

  private renderActiveCheckboxToggle = (): JSX.Element => {
    return (
      <Styles.ActiveCheckbox>
        <CheckboxToggle
          isChecked={this.props.productEditStore!.active}
          controlValue={true}
          isDisabled={this.state.isLoadingActiveProducts || !this.canActivateProduct()}
          onChange={this.onAvailabilityChange}
        />
        <p>Active</p>
      </Styles.ActiveCheckbox>
    );
  };

  private renderBreadcrumbLayout  = (): JSX.Element => {
    return (
      <CreateBreadcrumbLayout
        saveAction={this.props.productService.saveProduct.bind(this.props.productService)}
        isDirty={this.props.productService.isStateDirty}
      />
    );
  };

  private shouldDisplayNotification = (): boolean => {
    const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.merchantStore!.merchant!);

    return !isCollectionSeller && !this.props.productEditStore!.active && this.props.productEditStore!.currentMode === Mode.edit;
  }

  private renderNotificationCopy = (): JSX.Element => {
    return (
      <Styles.NotificationCopy>
        This product is currently inactive. <button onClick={this.openDeleteProductModal}>Click here</button> to completely remove the product from FloomX.
      </Styles.NotificationCopy>
    );
  };

  private openDeleteProductModal = (): void => {
    this.setState({ deleteProductModalOpen: true });
  }

  private onDeleteProduct = async (): Promise<void> => {
    this.setState({ deleteProductModalOpen: false });
    await ProductService.deleteProduct({ id: this.props.productEditStore!.id });
    NavService.productList({ persist: false });
  }

  private onCloseDeleteProductModal = (): void => {
    this.setState({ deleteProductModalOpen: false });
  }

  private renderDeleteProductModal = (): JSX.Element => {
    return (
      <GenericModal
        width={430}
        confirmButtonAction={this.onDeleteProduct}
        confirmButtonText='Yes'
        title='Are you sure?'
        modalOpen={this.state.deleteProductModalOpen}
        closeModal={this.onCloseDeleteProductModal}
        extraAction={(
          <Box
            onClick={this.onCloseDeleteProductModal}
          >
            <Button
              size="normal"
              appearance="secondary"
              copy="No"
            />
          </Box>
        )}
        innerComponent={(
          <Styles.ModalText>
            This product will be removed from the dashboard and can not be reactivated
          </Styles.ModalText>
        )}
      />
    );
  };

  render(): React.ReactNode {
    const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.merchantStore!.merchant!);
    const FormContent = isCollectionSeller ? CollectionProductContent : TabContent;

    return (
      <Fragment>
        { this.shouldDisplayNotification() && (
          <Styles.NotificationContainer>
            <Notification
              type={NotificationType.Progress}
              copy={this.renderNotificationCopy()}
              textAlign="left"
              hasClose={false}
            />
            {this.renderDeleteProductModal()}
          </Styles.NotificationContainer>
        )}
        <Styles.InnerContent>
          <Styles.HeaderWrapper>
            <Styles.TitleText>
              {this.props.productEditStore?.title || 'New product'}
            </Styles.TitleText>
            {!isCollectionSeller && this.renderActiveCheckboxToggle()}
          </Styles.HeaderWrapper>
          <Styles.SubTitle>
            {
              this.props.productEditStore!.selectedProductTypeTitle
                ? this.props.productEditStore!.selectedProductTypeTitle
                : 'Product type'
            }
          </Styles.SubTitle>

          {!isCollectionSeller && this.renderBreadcrumbLayout()}
          <Styles.TabContent>
            <FormContent />
          </Styles.TabContent>
        </Styles.InnerContent>
      </Fragment>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  modalStore: stores.modalStore,
  merchantStore: stores.merchantStore,
  toasterStore: stores.toasterStore
}))(observer(CreateLayoutInnerContent));

import { Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box } from 'rebass';

import {
  Maybe,
  Message as MessageShape,
  MessageType
} from 'types/conversations.types';

import ConversationsStore from 'stores/conversations/conversations-store';
import MerchantStore from 'stores/merchant/merchant-store';

import { ProfileIcon } from 'components/profile-icon/profile-icon';

import { List } from '../list/list';
import { Position } from '../messages/messages.types';

import {
  DateTime,
  MessageWrapper,
  TextWrapper,
  ProfileWrapper,
  Spacer
} from './message.styles';

type MessageProps = {
  message: Maybe<MessageShape>;
  position: Position;
  shouldShowAvatar: boolean;
  conversationsStore?: ConversationsStore;
  merchantStore?: MerchantStore;
}

export const Message: React.FC<MessageProps> = inject((stores: FxStores): InjectedFxStores => ({
  conversationsStore: stores.conversationsStore,
  merchantStore: stores.merchantStore
}))(observer(({
  conversationsStore,
  merchantStore,
  message,
  position,
  shouldShowAvatar
}) => {
  const attachment = message?.attachments?.length ? message.attachments[0] : null;

  const Attachment: React.FC | null = () => {
    switch (attachment?.__typename) {
      case 'ListAttachment':
        return (
          <Box
            mt={2}
            mb={1}
            width="100%"
          >
            <List
              list={attachment}
              hideWrapper
            />
          </Box>
        );

      default:
        return null;
    }
  };

  const authorName = message?.messageType === MessageType.Incoming
    ? conversationsStore?.selectedContact?.name
    : merchantStore?.merchant?.title;

  const renderProfileIcon = (name?: string): React.ReactElement | null => {
    if (!name) return null;

    const [firstName, secondName] = name.split(' ');

    return (
      <ProfileIcon
        givenName={firstName}
        familyName={secondName}
      />
    );
  };

  const timestamp = moment(message?.createdAt).format('H:mm');
  const isIncoming = message?.messageType === MessageType.Incoming;

  return (
    <Box
      key={message?.id}
      incoming={isIncoming}
      pb={1}
    >
      <MessageWrapper
        incoming={isIncoming}
        isFirstMessage={shouldShowAvatar}
      >
        {shouldShowAvatar && (
          <ProfileWrapper>
            {renderProfileIcon(authorName)}
          </ProfileWrapper>
        )}
        <TextWrapper
          alignItems="center"
          width="auto"
          messageType={isIncoming ? 'incoming' : 'outgoing'}
          position={position}
        >
          {message?.body && (
            <Fragment>
              <Box
                pr={2}
                mb={2}
                display="inline"
              >
                {message?.body}
              </Box>
            </Fragment>
          )}
          {attachment && (
            <Attachment />
          )}
          <Spacer>
            &nbsp;
          </Spacer>
          <DateTime incoming={isIncoming}>
            {timestamp}
          </DateTime>
        </TextWrapper>
      </MessageWrapper>
    </Box>
  );
}));

import { gql } from '@apollo/client';

export const CONTACTS_QUERY = gql`
  query Contacts(
    $where: MerchantWhereInput
  ) {
    contacts(
      where: $where
    ) {
      id
      name
      phone
      conversation {
        id
        unreadMessagesCount
      }
    }
  }
`;

export const CREATE_CONTACT_MUTATION = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
      name
      phone
      conversation {
        id
        unreadMessagesCount
      }
    }
  }
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      id
      name
      phone
      conversation {
        id
        unreadMessagesCount
      }
    }
  }
`;

export const DELETE_CONTACT_MUTATION = gql`
  mutation DeleteContact($id: ID!, $merchantId: String) {
    deleteContact(id: $id, merchantId: $merchantId) {
      id
      name
      phone
      conversation {
        id
      }
    }
  }
`;

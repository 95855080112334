import { Component, Fragment, ReactNode } from 'react';

import { globalHistory } from '@reach/router';
import { reqPostalCodeString } from 'lib/services/validation/extensions/postalcode';
import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';
import * as Yup from 'yup';

import {
  Merchant,
  Currency
} from 'generated-types.d';

import {
  ValidationService,
  PermissionsService,
  NavService
} from 'lib';

import { MerchantFieldValues } from 'stores/merchant-onboarding/merchant-onboarding-store.types';
import { ToastType } from 'stores/toaster-store/toaster-store.types';

import { Container } from 'utils/css-mixins';

import { findError, postalCodeCopy } from 'features/merchant-onboarding/helpers/merchant-create/merchant-create.helpers';
import { FORM_CONTAINER_WIDTH } from 'features/merchant-onboarding/merchant-onboarding.constants';
import MerchantOnboardingService from 'features/merchant-onboarding/services';

import Button from 'components/button';
import FormBuilder from 'components/form-builder';
import { FormBuilderConfig } from 'components/form-builder/form-builder.types';
import FormFooter from 'components/form-footer';
import FormHeader from 'components/form-header';
import { NotificationType } from 'components/notification/notification.types';
import StyledLink from 'components/styled-link/styled-link';

import * as Types from './merchant-company-info.types';

class MerchantCompanyInfo extends Component<Types.MerchantCompanyInfoProps, Types.MerchantCompanyInfoState> {
  private MerchantCreationService = MerchantOnboardingService.MerchantCreationService;

  private historyListener = globalHistory.listen((listener): void => {
    if (!listener.location.pathname.includes('merchant/create')) {
      this.props.merchantOnboardingStore!.resetStore();
    }
  });

  validation: Yup.Schema<any> = Yup.object().shape({
    tradingNo: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please provide a trading number')),
    regAd1: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Address line 1 is required')),
    regAd2: Yup
      .string()
      .notRequired(),
    regAdcity: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'City is required')),
    regAdpostalCode: reqPostalCodeString('regAdpostalCode', this.props.merchantOnboardingStore!.merchantObject?.currency === Currency.Usd)
  });

  state: Types.MerchantCompanyInfoState = {
    isCreating: false,
    isDirty: false,
    errorNotification: ''
  };

  componentDidMount = (): void => {
    this.redirect();
  };

  componentWillUnmount = (): void => {
    this.historyListener();
  };

  private redirect = (): void => {
    switch (true) {
      case !PermissionsService.isInternalRole():
        NavService.overview();

        break;

      case !this.props.merchantOnboardingStore!.createdMerchantId && PermissionsService.isInternalRole():
        NavService.merchantCreate('account');

        break;
    }
  };

  private handleChange = (value: MerchantFieldValues[keyof MerchantFieldValues], key: keyof MerchantFieldValues): void => {
    this.props.merchantOnboardingStore!.setValue(value, key);

    if (!this.state.isDirty) {
      this.setState({
        isDirty: true
      });
    }
  };

  private onSubmit = async (): Promise<void> => {
    try {
      await ValidationService.validateAll(this.validation, this.props.merchantOnboardingStore!.values);

      this.onUpdateMerchant(this.props.merchantOnboardingStore!.values);
    } catch (error) {
      this.props.merchantOnboardingStore!.setErrors(error.errors);
      this.props.toasterStore?.popToast('You have some validation errors', ToastType.Error);
    }
  };

  private onUpdateMerchant = async (data: MerchantFieldValues): Promise<void> => {
    if (this.state.isDirty) {
      this.setState({
        isCreating: true,
        errorNotification: ''
      });

      try {
        const merchant = await this.MerchantCreationService.updateMerchant(data, this.props.merchantOnboardingStore!.merchantObject!);

        if (merchant) {
          this.handleMerchantUpdate(merchant);

          this.setState({
            isCreating: false,
            isDirty: false
          });
        }
      } catch (error) {
        this.props.toasterStore?.popToast('We\'re having trouble adding your merchants company info. More information is provided at the bottom of the form', ToastType.Error);

        window?.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

        this.setState({
          isCreating: false,
          errorNotification: this.handleErrorNotification()
        });
      }
    } else {
      NavService.merchantCreate('shop-info');
    }
  };

  private handleErrorNotification = (): string => {
    return 'We are having trouble editing a merchant account with the details provided. Please check your entries and try again';
  };

  private handleMerchantUpdate = (merchant: Merchant): void => {
    switch (true) {
      case PermissionsService.isInternalRole():
        this.props.merchantOnboardingStore!.populateMerchant(merchant);
        this.props.toasterStore?.popToast(`Company information for ${merchant.companyName} has been added to your merchants records!`, ToastType.Success);
        NavService.merchantCreate('shop-info');

        break;

      default:
        break;
    }
  };

  private clearFields = (): void => {
    this.props.merchantOnboardingStore!.clearFieldsInformation([
      'tradingNo',
      'regAdphone',
      'regAd1',
      'regAd2',
      'regAdcity',
      'regAdpostalCode'
    ]);
  };

  private setNotification = (): void => {
    this.props.merchantSettingsStore!.setNotification(`You have set up your merchant (${this.props.merchantOnboardingStore!.merchantObject?.companyName}), but will need more information before they are ready to trade as some steps were skipped.`);
  };

  public attemptToLeave = (): void => {
    this.props.modalStore!.triggerModal({
      modalType: 'confirmation',
      data: {
        title: 'Leave merchant create',
        copy: 'Are you sure you want to leave? To carry on editing your merchant, you will need to select them in merchant settings',
        confirmButtonCopy: 'Yes, leave',
        cancelButtonCopy: 'No, continue',
        errorCopy: '',
        confirmButtonAction: async (): Promise<any> => new Promise((resolve): any => {
          this.setNotification();
          this.props.merchantOnboardingStore!.resetStore();
          NavService.merchantList();
          resolve('');
        })
      }
    });
  };

  public attemptToGoBack = (): void => {
    if (this.state.isDirty) {
      this.props.modalStore!.triggerModal({
        modalType: 'confirmation',
        data: {
          title: 'Leave merchant create',
          copy: 'You added company information that has\'t been saved. Would you like to continue editing company information, or discard and go back?',
          confirmButtonCopy: 'Go back',
          cancelButtonCopy: 'Continue editing',
          errorCopy: '',
          confirmButtonAction: async (): Promise<any> => new Promise((resolve): any => {
            this.clearFields();
            NavService.merchantCreate('account');
            resolve('');
          })
        }
      });
    } else {
      NavService.merchantCreate('account');
    }
  };

  public attemptToSkip = (): void => {
    if (this.state.isDirty) {
      this.props.modalStore!.triggerModal({
        modalType: 'confirmation',
        data: {
          title: 'Skip entering company info?',
          copy: 'Skipping this stage will discard any progress you\'ve made setting up company information.',
          confirmButtonCopy: 'Skip',
          cancelButtonCopy: 'Go back',
          errorCopy: '',
          confirmButtonAction: async (): Promise<any> => new Promise((resolve): any => {
            this.clearFields();
            NavService.merchantCreate('shop-info');
            resolve('');
          })
        }
      });
    } else {
      NavService.merchantCreate('shop-info');
    }
  };

  private renderTradingCopy = (): ReactNode => {
    switch (this.props.merchantOnboardingStore!.currency) {
      case Currency.Gbp:
        return (
          <Fragment>
            <Box>
              <strong>UTR</strong> - unique taxpayer reference – issued by HMRC, consisting of 10 digits
            </Box>
            <Box mt="5px">
              <strong>VAT number</strong> - issued by HMRC for companies registered for Value Added Tax. It consists of 9 digits, with “GB” sometimes included as a prefix (e.g. GB123456789)
            </Box>
          </Fragment>
        );

      case Currency.Usd:
        return (
          <Fragment>
            A company registration number (CRN) is a unique combination of 8 numbers, or 2 letters followed by 6 numbers. It is used to identify your company or limited partnership and verify its legal existence as an incorporated entity
          </Fragment>
        );

      default:
        return null;
    }
  };

  public buildForm = (): FormBuilderConfig => ({
    subtitle: `Company information for ${this.props.merchantOnboardingStore!.values.companyName}`,
    sections: [
      {
        width: '100',
        fields: [
          {
            key: 'tradingNoLabel',
            fieldType: 'label',
            copy: 'Company registered number',
            validationError: undefined
          },
          {
            key: 'tradingNo',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.tradingNo,
            validationError: findError('tradingNo', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'tradingNo')
          },
          {
            key: 'companyRegisteredNumberCopy',
            fieldType: 'copy',
            copy: this.renderTradingCopy()
          }
        ]
      },
      {
        width: '100',
        fields: [
          {
            key: 'regAd1Label',
            fieldType: 'label',
            copy: 'Address line 1',
            validationError: undefined
          },
          {
            key: 'regAd1',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.regAd1,
            validationError: findError('regAd1', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'regAd1')
          }
        ]
      },
      {
        width: '100',
        fields: [
          {
            key: 'regAd2Label',
            fieldType: 'label',
            copy: 'Address line 2',
            validationError: undefined
          },
          {
            key: 'regAd2',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.regAd2,
            validationError: findError('regAd2', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'regAd2')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'regAdcityLabel',
            fieldType: 'label',
            copy: 'City',
            validationError: undefined
          },
          {
            key: 'regAdcity',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.regAdcity,
            validationError: findError('regAdcity', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'regAdcity')
          }
        ]
      },
      {
        width: '50',
        fields: [
          {
            key: 'regAdpostalCodeLabel',
            fieldType: 'label',
            copy: postalCodeCopy(this.props.merchantOnboardingStore?.merchantObject?.currency!),
            validationError: undefined
          },
          {
            key: 'regAdpostalCode',
            fieldType: 'textInput',
            value: this.props.merchantOnboardingStore!.values.regAdpostalCode,
            validationError: findError('regAdpostalCode', this.props.merchantOnboardingStore!.validationErrors),
            onChange: (e): void => this.handleChange(e.target.value, 'regAdpostalCode')
          }
        ]
      }
    ]
  });

  render(): ReactNode {
    return (
      <Fragment>
        <FormHeader
          title="Add Merchant"
          buttonCopy="Cancel"
          hasAction={this.props.merchantOnboardingStore!.canSkipOnboardingSteps}
          onAction={this.attemptToLeave}
        />
        <Container maxWidth={FORM_CONTAINER_WIDTH}>
          <Box m="100px 0">
            <FormBuilder
              config={this.buildForm()}
              notification={{
                hasNotification: !!this.state.errorNotification.length,
                notificationProps: {
                  copy: this.state.errorNotification,
                  type: NotificationType.Error
                }
              }}
            />
            <FormFooter>
              <Container maxWidth={FORM_CONTAINER_WIDTH}>
                <Flex
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box
                    as="button"
                    onClick={this.attemptToGoBack}
                  >
                    <Button
                      size="normal"
                      appearance="secondary"
                      copy="Back"
                      isLoading={false}
                      isDisabled={this.state.isCreating}
                    />
                  </Box>
                  <Flex alignItems="center">
                    {this.props.merchantOnboardingStore!.canSkipOnboardingSteps && (
                      <Box
                        as="button"
                        mr="20px"
                        onClick={this.attemptToSkip}
                      >
                        <StyledLink>
                          Skip
                        </StyledLink>
                      </Box>
                    )}
                    <Box
                      as="button"
                      onClick={this.onSubmit}
                      disabled={this.state.isCreating}
                    >
                      <Button
                        size="normal"
                        appearance="primary"
                        copy="Save &amp; Continue"
                        isLoading={this.state.isCreating}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Container>
            </FormFooter>
          </Box>
        </Container>
      </Fragment>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantOnboardingStore: stores.merchantOnboardingStore,
  merchantSettingsStore: stores.merchantSettingsStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(MerchantCompanyInfo));

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors  } from 'utils/rebass-theme';

export const Content = styled.div`
  text-align: center;
  margin-bottom: 60px;
`;

export const InnerContent = styled.div`
`;

export const StockAndPrice = styled(Flex)`
  justify-content: space-between;
  flex-flow: row wrap;
  > div {
    width: 48%;
    min-width: 350px;
  }
`;

export const VariationWrapper = styled(Flex)`
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  border: 2px solid ${colors.middleGrey};
  > div {
    margin-bottom: 15px;
    margin-top: 10px;
  }
`;

export const SizeWrapper = styled(Flex)`
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 240px;
  }
`;

export const VariationButtonWrapper = styled.div`

`;

import { FC, ReactNode } from 'react';

import { ConversationView } from 'features/conversations/context/ContactContext';

import { Header, Wrapper } from './contacts-wrapper.styles';

type ContactsWrapperProps = {
  headerContent?: ReactNode;
  view: ConversationView;
};

export const ContactsWrapper: FC<ContactsWrapperProps> = ({
  headerContent,
  children,
  view
}) => {
  return (
    <Wrapper
      px={3}
      view={view}
    >
      {headerContent && <Header>{headerContent}</Header>}
      {children}
    </Wrapper>
  );
};

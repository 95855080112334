import styled from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { textStyles, colors, space, containerWidth, breakpoints } from 'utils/rebass-theme';

export const InnerContent = styled.div`
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 70px;
  min-height: calc(100vh - 110px - 80px);
  position: relative;
`;

export const HeaderWrapper = styled(Flex)`
  max-width: 790px;
  width: 100%;
`;

export const TitleText = styled.p`
  ${textStyles.title};
  font-size: 48px;
  margin-bottom: 0px;
  min-height: 57px;
  word-wrap: break-word;
  word-break: break-all;
  margin-right: 50px;
`;

export const SubTitle = styled.p`
  ${textStyles.title};
  margin-bottom: 40px;
`;

export const TabContent = styled.div`
  padding-top: 50px;
`;

export const ActiveCheckbox = styled(Flex)`
  max-width: 100%;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 10px;
  margin-left: auto;
  > p {
    ${textStyles.subhead};
    margin-bottom: 0px;
    padding-left: 10px;
  }
`;

export const NotificationContainer: any = styled(Box)`
  flex: 1;
  margin-top: ${space[3]}px;

  max-width: ${containerWidth}px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoints.xxLarge}) {
    margin-left: ${space[3]}px;
    margin-right: ${space[3]}px;
  }
`;

export const NotificationCopy: any = styled.span`
  button {
    color: ${colors.uiFills};
    text-decoration: underline;
  }
`;

export const ModalText: any = styled(Box)`
  ${textStyles.body};
  margin: ${space[4]}px ${space[3]}px ${space[3]}px ${space[4]}px;
`;

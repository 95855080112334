import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { borderRadius, colors, textStyles } from 'utils/rebass-theme';

export const Container = styled(Box)`
  padding: 20px 30px;
`;

export const Title = styled(Flex)`
  ${textStyles.title};
  font-size: 26px;
  align-items: center;
`;

export const StatsText = styled(Box)`
  ${textStyles.h5};
  color: ${colors.shade40};
`;

export const ItemContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 12px;
  margin-top: 20px;
  border-radius: ${borderRadius.medium};
  list-style: none;
  padding: 10px 0;
  position: relative;
`;

export const Content = styled(Flex)`
  position: sticky;
  top: 30px;
  align-self: flex-start;
`;

export const Sidebar = styled(Box)`
  margin-top: 30px;
  width: 230px;
  padding-right: 20px;
  position: sticky;
  top: 30px;
  align-self: flex-start;
`;

export const SearchWrapper = styled(Box)`
  position: sticky;
  top: 0;
  padding-top: 20px;
  z-index: 10;
  background: white;
`;

export const ModalStyles = css`
  .catalog-explorer-modal__ContentSlide {
    position: absolute;
    transform: translateX(20px);
    transition: transform .2s ease-in-out;
  }

  .catalog-explorer-modal__ContentSlide--after-open {
    transform: translateX(0);
  }

  .catalog-explorer-modal__ContentSlide--before-close {
    transform: translateX(20px);
  }

  .catalog-explorer-modal__OverlaySlide {
    opacity: 0;
    transition: opacity .3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .catalog-explorer-modal__OverlaySlide--after-open {
    opacity: 1;
  }

  .catalog-explorer-modal__OverlaySlide--before-close {
    opacity: 0;
  }
`;

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { borderRadius, colors, textStyles } from 'utils/rebass-theme';

export const Container = styled(Box)`
  padding: 0 20px 35px;
`;

export const Heading = styled(Box)`
  ${textStyles.title}
  color: ${colors.floomMidnightBlue};
  font-size: 18px;
  margin-top: 3px;
`;

export const SubHeading = styled(Box)`
  ${textStyles.body};
  font-size: 12px;
`;

export const Body = styled(Box)`
  ${textStyles.body}
`;

export const PlanDetails = styled(Box)`
  margin-top: 20px;
`;

export const PlanDetailItem = styled(Box)`
  background: #f2f2f2;
  padding: 15px 15px 17px;
  border-radius: ${borderRadius.medium}
`;

import gql from 'graphql-tag';

import {
  MerchantFilterGroup,
  SelectedFilters,
  MerchantsFilters,
  MerchantFilterItem
} from 'stores/merchant-settings/merchant-settings-store.types';

export const hasFilters = (filters: SelectedFilters): boolean => Object.keys(filters)?.some(filterKey => !!filters?.[filterKey]?.size);

export const mapFilters = (filters: SelectedFilters): string => {
  // @ts-ignore
  return Object.keys(filters).map((filterKey: MerchantFilterGroup) => {
    const filterKeys = Array.from(filters[filterKey]);

    return filterKeys.length ? `{
      OR: [
        ${filterKeys.map((value: any): string => `{
          ${addAggregateFilter(filterKey as MerchantFilterGroup, value)}
        }`)}
      ]
    }` : '';
  });
};

export const addAggregateFilter = (filterKey: MerchantFilterGroup, value: string): string => {
  switch (filterKey) {
    case MerchantFilterGroup.Plans:
      return `
        plan: {
          type: ${value}
        }
      `;

    case MerchantFilterGroup.Timezone:
      return `
        timezone: "${value}"
      `;

    case MerchantFilterGroup.Stages:
      return `
        stage: ${value}
      `;

    case MerchantFilterGroup.SubscriptionStatus:
      return `
        subscription: {
          status: ${value}
        }
      `;

    default:
      return '';
  }
};

const buildRemainingFilterClauses = (filterToOmit: MerchantFilterGroup, selectedFilters: SelectedFilters): any => {
  return Object.keys(selectedFilters).map(filterKey => {
    if (filterKey === filterToOmit) return '';

    const filters = Array.from(selectedFilters[filterKey]);

    /* eslint-disable */
    return filters.length ? `{
      OR: [
        ${filters.map((value: any): string => {
          return `{${addAggregateFilter(filterKey as MerchantFilterGroup, value)}}`;
        })}
      ]
    }` : '';
    /* eslint-enable */
  });
};

const getFilterTitle = (item: MerchantFilterItem, filterKey: string): string => {
  if (filterKey === 'timezone') {
    return item.value.replace('/', '_');
  }

  return item.value;
};

export const MERCHANT_LIST_FILTER_COUNTS = (
  vars: any,
  filters: MerchantsFilters,
  selectedFilters: SelectedFilters
): any => {
  return gql`
    query MerchantFilterListQuery{
      ${Object.keys(filters).map((filterKey): string => {
    return filters[filterKey].map((item: MerchantFilterItem): string => {
      const title = getFilterTitle(item, filterKey);

      return `
            ${filterKey}_${title}: merchantsConnection(
              where:
              {
                deletedAt: null
                ${addAggregateFilter(filterKey as MerchantFilterGroup, item.value)}
                AND: [
                  ${buildRemainingFilterClauses(filterKey as MerchantFilterGroup, selectedFilters)}
                ]
              }
            ) {
                aggregate
                {
                  count
                }
              }
          `;
    });
  })}
    }
  `;
};

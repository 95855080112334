import { gql } from '@apollo/client';

export const MessageFields = gql`
  fragment MessageFields on Message {
    id
    draftId
    merchantId
    conversationId
    messageType
    body
    status
    createdAt
    updatedAt
    author {
      name
    }
    attachments {
      ... on ListAttachment {
        id
        date
        description
        title
        items {
          id
          quantity
          title
          type
          media {
            src
            title
            type
          }
        }
      }
    }
  }
`;

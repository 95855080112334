
import { FC, Fragment, useMemo } from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Currency } from 'generated-types.d';

import WholesaleOrdersStore from 'stores/wholesale/wholesale-orders-store/wholesale-orders-store';

import {
  GrandTotalRow,
  TotalRow,
  TotalsTable,
  TotalValueColumn
} from 'features/lists/lists.styles';
import { WholesaleCheckoutHelpers } from 'features/wholesale/helpers/wholesale-helpers';

interface SingleOrderTotalProps {
  wholesaleOrdersStore?: WholesaleOrdersStore;
}

export const SingleOrderTotals: FC<SingleOrderTotalProps> = inject((stores: FxStores) => ({
  wholesaleOrdersStore: stores.wholesaleOrdersStore
}))(observer(({
  wholesaleOrdersStore
}) => {
  const selectedOrder = wholesaleOrdersStore!.selectedOrder;

  if (!selectedOrder) return null;

  const shippingTotal = selectedOrder.deliveryDetails?.[0]?.costValue || 0;
  const orderTaxValue = WholesaleCheckoutHelpers.orderTaxValue(selectedOrder);
  const grandTotal = useMemo(() => WholesaleCheckoutHelpers.calculateGrandTotal(selectedOrder), [selectedOrder]);
  const taxLabel = selectedOrder.merchant.currency === Currency.Usd ? 'Sales Tax' : 'VAT';
  const merchantCurrency = wholesaleOrdersStore!.selectedOrder?.merchant?.currency;
  const grandTotalWithCurrency = useMemo(() => WholesaleCheckoutHelpers.renderWholesalePrice(grandTotal, merchantCurrency), [grandTotal]);

  return (
    <Fragment>
      <TotalsTable>
        <TotalRow>
          <Box fontWeight={600}>
            Subtotal
          </Box>
          <TotalValueColumn>
            <Box fontWeight={600}>
              {WholesaleCheckoutHelpers.renderWholesalePrice(selectedOrder.totalPrice!, merchantCurrency)}
            </Box>
          </TotalValueColumn>
        </TotalRow>
        <TotalRow>
          <Box>
            Shipping
          </Box>
          <TotalValueColumn>
            <Box>
              {!shippingTotal
                ? <span>Free</span>
                : WholesaleCheckoutHelpers.renderWholesalePrice(shippingTotal, merchantCurrency)
              }
            </Box>
          </TotalValueColumn>
        </TotalRow>
        {!!wholesaleOrdersStore!.selectedOrder?.tax && (
          <TotalRow>
            <Box>
              {taxLabel}
            </Box>
            <TotalValueColumn>
              {WholesaleCheckoutHelpers.renderWholesalePrice(orderTaxValue, merchantCurrency)}
            </TotalValueColumn>
          </TotalRow>
        )}
      </TotalsTable>
      <GrandTotalRow>
        <Box fontWeight={600}>
          Total
        </Box>
        <TotalValueColumn fontWeight={600}>
          {grandTotalWithCurrency}
        </TotalValueColumn>
      </GrandTotalRow>
    </Fragment>
  );
}));

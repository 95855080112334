import React, { Component, ReactNode } from 'react';

import ErrorMessage from '../error-message';

import * as Styles from './field-text-area.styles';
import * as Types from './field-text-area.types';

class FxTextArea extends Component<Types.TextAreaProps> {
  static defaultProps = {
    rows: 5,
    autoFocus: false,
    onKeyDown: (): any => {}
  };

  state = {
    isDirty: false
  };

  getErrorMessage = (): string | undefined => {
    return this.props.error && this.state.isDirty ? this.props.errorMessage : '';
  };

  onChange = (e: any): any => {
    this.setState({
      isDirty: true
    });

    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  };

  render(): ReactNode {
    return (
      <Styles.Content
        error={this.props.error && this.state.isDirty}
        inPlaceEdit={this.props.inPlaceEdit}
      >
        <textarea
          value={this.props.value}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          rows={this.props.rows}
          ref={this.props.innerRef}
          maxLength={this.props.maxLength}
          autoFocus={this.props.autoFocus}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          onChange={this.onChange}
          onKeyDown={this.props.onKeyDown}
          data-testid={this.props.testId}
        />

        <ErrorMessage
          errorMessage={this.getErrorMessage()}
        />
      </Styles.Content>
    );
  }
}

export default React.forwardRef((props: Types.TextAreaProps, ref: React.Ref<any>) => (
  <FxTextArea
    innerRef={ref}
    {...props}
  />
));


import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

export const TabsWrapper = styled(Flex)`
  overflow: auto;
`;

export const TabWrapper = styled(Box)`
  min-width: 75px;
`;

import { ReactNode, Fragment } from 'react';

import { css } from '@emotion/react';
import { Link } from '@reach/router';
import { NavPages } from 'global.types';
import { Box, Text } from 'rebass';

import { colors } from 'utils/rebass-theme';

import wholesaleEmptySvg from 'assets/images/wholesale/wholesale-empty.svg';

import NoResultsGeneric from 'components/no-results-generic';

export const CannotFindPreOrder = (): ReactNode => {
  return (
    <Box p="20px">
      <NoResultsGeneric
        icon="backpack"
        image={wholesaleEmptySvg}
        heading="We're having trouble finding this pre-order"
        imageStyles={css`
          width: 80px;
          margin-right: 30px;
        `}
        copy={(
          <Fragment>
            <Link to={NavPages.WholesaleOrders}>
              <Text
                as="span"
                style={{
                  textDecoration: 'underline',
                  color: colors.floomMidnightBlue
                }}
              >
                Head back
              </Text>
            </Link>
            {' '}
            to see all your orders
          </Fragment>
        )}
      />
    </Box>
  );
};

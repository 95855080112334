import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const SettingsRootWrapper = styled.div`
  margin: 70px 0;
`;

export const SettingsTitle = styled.p`
  ${textStyles.headline}
  margin-bottom: 50px;
`;

export const Tiles = styled(Flex)`
  flex-flow: row wrap;
  margin: 0 -10px;
`;

import {
  Fragment,
  memo,
  FC,
  useEffect
} from 'react';

import {
  css,
  SerializedStyles
} from '@emotion/react';
import {
  inject,
  observer
} from 'mobx-react';
import {
  RefinementListProvided,
  connectRefinementList
} from 'react-instantsearch-core';
import {
  Box
} from 'rebass';

import WholesaleFiltersStore from 'stores/wholesale/wholesale-filters-store/wholesale-filters-store';

import CheckboxList from 'components/checkbox-list';
import FieldText from 'components/field-text';

import { CatalogAccordionHeading } from '../catalog-accordion-heading/catalog-accordion-heading';
import { CatalogListColours } from '../catalog-list-colours/catalog-list-colours';
import CatalogListSuppliers from '../catalog-list-suppliers/catalog-list-suppliers';

import {
  CheckboxListWrapper,
  ColourListWrapper
} from './catalog-refinement-list.styles';

interface RefinementListProps extends RefinementListProvided {
  name: string;
  wholesaleFiltersStore?: WholesaleFiltersStore;
}

type ListConfig = {
  [key: string]: {
    ListComponent: any;
    WrapperComponent: any;
    wrapperStyles: SerializedStyles;
  };
}

const config: ListConfig = {
  Colour: {
    ListComponent: CatalogListColours,
    WrapperComponent: ColourListWrapper,
    wrapperStyles: css`
      grid-gap: 5px;
    `
  },
  Supplier: {
    ListComponent: CatalogListSuppliers,
    WrapperComponent: CheckboxListWrapper,
    wrapperStyles: css`
      grid-gap: 10px;
    `
  },
  Default: {
    ListComponent: CheckboxList,
    WrapperComponent: CheckboxListWrapper,
    wrapperStyles: css`
      grid-gap: 10px;
    `
  }
};

const RenderRefinementList: FC<RefinementListProps & {
  searchValue: string;
  handleSearch: (value: string) => void;
  hasSearchInput?: boolean;
}> = props => {
  const listConfig = config[props.name] || config.Default;

  return (
    <listConfig.WrapperComponent>
      {!!props.hasSearchInput && (
        <Box mb="15px">
          <FieldText
            value={props.searchValue}
            size="small"
            placeholder={`Search for ${props.name?.toLowerCase()}`}
            onChange={e => props.handleSearch(e.target.value)}
          />
        </Box>
      )}
      <listConfig.ListComponent
        orientation="vertical"
        itemValueField="value"
        optionTitleField="title"
        selectedItems={props.currentRefinement}
        wrapperStyles={listConfig.wrapperStyles}
        checkboxStyles={css`
          margin-bottom: 0;
        `}
        items={props.items
          .filter(item => !!item.count)
          .sort((a: { label: string }, b: { label: any }) => a.label.localeCompare(b.label))
          .map((item: { label: any; count: number }) => {
            return {
              title: `${item.label} (${item.count})`,
              value: item.label,
              isDisabled: item.count === 0
            };
          })}
        onChange={(value: string): void => {
          const item = props.items.find((refinementItem: { label: string }) => refinementItem.label === value);

          if (!!item) {
            props.refine(item.value);
          }
        }}
      />
    </listConfig.WrapperComponent>
  );
};

export const CatalogRefinementList = inject((stores: FxStores) => ({
  wholesaleFiltersStore: stores.wholesaleFiltersStore
}))(observer(connectRefinementList(memo<RefinementListProps & { hasSearchInput: boolean }>(props => {
  useEffect(() => {
    if (searchValue?.length && !props.isFromSearch) {
      props.searchForItems([searchValue]);
    }
  }, [props.isFromSearch]);

  const handleSearch = (value: string): void => {
    props.wholesaleFiltersStore?.setFilterSearch(props.name, value);
    props.searchForItems([value]);
  };
  const searchValue = props.wholesaleFiltersStore?.filterSearches[props.name] || '';

  if (!props.items.length && !searchValue?.length) return null;

  const selectedItemCount = props.currentRefinement?.filter(refinement => props.items?.some(item => item.label === refinement))?.length;

  return (
    <Fragment>
      <CatalogAccordionHeading
        name={props.name}
        isInitiallyOpen={false}
        shouldDisplayCount={true}
        refinementCount={selectedItemCount}
      >
        <RenderRefinementList
          {...props}
          searchValue={searchValue}
          handleSearch={handleSearch}
          hasSearchInput={!!props.hasSearchInput}
        />
      </CatalogAccordionHeading>
    </Fragment>
  );
}))));

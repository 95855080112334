import React, { memo } from 'react';

import { CurrentRefinementsProvided } from 'react-instantsearch-core';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { Box } from 'rebass';

import Icon from 'components/icon';

import {
  FilterButton,
  FilterCopy,
  FilterCount,
  FilterIcon
} from '../order-list-controls.styles';

interface FilterToggleProps extends CurrentRefinementsProvided {
  isOpen: boolean;
  onToggle: () => void;
}

export const ToggleIcon = memo(({ selectedFilterCount, isOpen }: { selectedFilterCount: number; isOpen: boolean }) => {
  if (!!selectedFilterCount) {
    return (
      <FilterCount>
        {selectedFilterCount > 9 ? '9+' : selectedFilterCount}
      </FilterCount>
    );
  }

  return (
    <Box>
      <Icon
        iconName="filters"
        styles={FilterIcon(isOpen)}
      />
    </Box>
  );
});

export const FilterToggle = connectCurrentRefinements(memo((props: FilterToggleProps) => {
  const copy = !props.isOpen ? 'Filter orders' : 'Hide filters';
  const count = Object.values(props.items).reduce((acc, curr): number => curr.currentRefinement.length + acc, 0);

  return (
    <FilterButton
      as="button"
      alignItems="center"
      isOpen={props.isOpen}
      onClick={props.onToggle}
    >
      <FilterCopy isOpen={props.isOpen}>
        {copy}
      </FilterCopy>
      <ToggleIcon
        selectedFilterCount={count}
        isOpen={props.isOpen}
      />
    </FilterButton>
  );
}));

import { gql } from '@apollo/client';

import { GraphQLHelpers } from 'lib';

export const DEFAULT_PRODUCTS_WITH_CONFIG = gql`
  query DefaultProductsWithConfig(
    $configId: ID
  ) {
      products(
        first: 1
        where: {
          type_some: {
            slug_in: ["flowers", "plants"]
          }
          deliveryConfigs_some: {
            id: $configId
          }
        }
      ) {
        id
      }
    }
`;

export const DELIVERY_CONFIGS = (vars: any): any => gql`
  query DeliveryConfigs
    ${vars.merchantId && `(
      ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
    )`} {
      deliveryConfigs
        ${vars.merchantId && `( where: {
          ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
          deletedAt: null
        })`} {
        id
        flc_id
        title
        default
        channel
        merchant {
          id
          title
          currency
          plan {
            features
          }
        }
        region
        deliveryCoverage {
          id
          title
          label
        }
        deliveryTiming {
          id
          cutOffHour
          leadtime
          days
          sameDays
        }
        deliveryPrice {
          id
          title
          channel
          sameDayPrice
          nextDayPrice
          perItemDeliveryFee
          isDefault
        }
        holidays {
          id
        }
        deletedAt
      }
    }
`;

export const ALL_ZONES_FOR_COVERAGE_AREA = gql`
  query AllDeliveryZones($id: ID!) {
    deliveryCoverage(
      where: {
        id: $id
      }
    ) {
      deliveryZones(first: 12000) {
        id
        title
        postalCode
      }
    }
  }
`;

export const DELIVERY_COVERAGES = (vars: any): any => gql`
  query DeliveryCoverages
    ${vars.merchantId && `(
      ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
    )`} {
      deliveryCoverages
        ${vars.merchantId && `( where: {
          ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
        })`} {
        id
        flc_id
        title
        label
        channel
        type
        merchant {
          id
        }
        flcApproved
        deliveryZones(first: 100) {
          id
          title
          postalCode
        }
      }
    }
`;

export const DELIVERY_ZONES_ALL = gql`
    query DeliveryZones(
      $count: Int
      $skip: Int
    ) {
      deliveryZonesConnection(
        first: $count
        skip: $skip
      ) {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            postalCode
            id
            title
          }
        }
      }
    }
`;

export const DELIVERY_PRICINGS = (vars: any): any => gql`
  query DeliveryPricings
    ${vars.merchantId && `(
      ${GraphQLHelpers.addVariable(vars.merchantId, 'merchantId', 'ID!')}
    )`} {
      deliveryPricings(
        first: 2000
        where: {
          ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
        }
      ) {
        id
        title
        channel
        sameDayPrice
        nextDayPrice
        perItemDeliveryFee
        isDefault
      }
    }
`;

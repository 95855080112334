import { FC, memo, useEffect } from 'react';

import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { Box } from 'rebass';

import GenericModal from 'components/generic-modal';

import {
  MobileNavItem
} from './mobile-navigation.styles';
import {
  MobileMarketingNavigationProps
} from './mobile-navigation.types';

export const MobileMarketingNavigation: FC<MobileMarketingNavigationProps> = memo(({
  data,
  isNavOpen,
  onToggleNav
}) => {
  useEffect(() => {
    let cachedWindowWidth = window.innerWidth;

    const handleResize = (): void => {
      if (window.innerWidth > 1000 && cachedWindowWidth <= 1000) {
        onToggleNav(false);
      }

      cachedWindowWidth = window.innerWidth;
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeNav = (): void => {
    onToggleNav(false);
  };

  return (
    <GenericModal
      title=""
      closeModal={(): void => onToggleNav(false)}
      modalOpen={isNavOpen}
      shouldHideHeader={true}
      shouldHideFooter={true}
      width={99999}
      overlayStyles={{
        background: 'white'
      }}
      contentStyles={{
        height: '100%',
        padding: '4.5em 10px 0 10px'
      }}
      innerComponent={(
        <Box
          as="ul"
          mt="20px"
        >
          <MobileNavItem
            key="home"
            as="li"
          >
            <Link to="/">
              <span>
                Home
              </span>
            </Link>
          </MobileNavItem>
          {data.edges.map(({ node }: any) => (
            <MobileNavItem
              key={node.slug}
              as="li"
            >
              <Link
                to={`/${node.slug}`}
                onClick={closeNav}
              >
                <span>
                  {node.pageTitle}
                </span>
              </Link>
            </MobileNavItem>
          ))}
          <MobileNavItem
            key="login"
            as="li"
          >
            <Link
              to={NavPages.Login}
              onClick={closeNav}
            >
              <span>
                Login
              </span>
            </Link>
          </MobileNavItem>
        </Box>
      )}
    />
  );
});

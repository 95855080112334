import { FC, useState } from 'react';

import { Flex } from 'rebass';

import ErrorMessage from '../error-message';

import { RadioItem } from './item-types/radio-item/radio-item';
import { RadioListProps } from './radio-list.types';

const RadioList: FC<RadioListProps> = ({
  selectedItem,
  itemValueField,
  itemTitleField,
  itemSubtitleField,
  items,
  itemType = 'default',
  orientation = 'vertical',
  onChange,
  error,
  errorMessage
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const getErrorMessage = (): string | undefined => error && isDirty ? errorMessage : '';

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, item: any): any => {
    if (item.isDisabled) {
      return event.preventDefault();
    }

    setIsDirty(true);

    return onChange(item[itemValueField], event);
  };

  return (
    <div>
      <Flex flexWrap="wrap">
        {items.map(item => (
          <RadioItem
            key={item[itemValueField]}
            item={item}
            totalItems={items.length}
            radioType={itemType}
            onChange={handleOnChange}
            itemValueField={itemValueField}
            itemTitleField={itemTitleField}
            itemSubtitleField={itemSubtitleField}
            orientation={orientation}
            isDisabled={item.isDisabled}
            selectedItem={selectedItem}
            slug={item.slug}
          />
        ))}
      </Flex>
      <ErrorMessage errorMessage={getErrorMessage()} />
    </div>
  );
};

export default RadioList;

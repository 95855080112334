import React, { Component, ReactNode } from 'react';

import { Box } from 'rebass';

import GenericModal from 'components/generic-modal';

import * as Styles from './tag-selection-modal.styles';
import * as Types from './tag-selection-modal.types';

class TagSelectionModal extends Component<Types.TagSelectionModalProps> {
  state = {
    items: this.props.data.items
  };

  private onConfirm = async (): Promise<void> => {
    this.props.data.confirmButtonAction(this.state.items);
    this.props.closeModal();
  };

  private onSelect = (index: number): void => {
    const prevItems = this.state.items;
    prevItems[index].isSelected = !prevItems[index].isSelected;

    this.setState({
      items: prevItems
    });
  };

  private shouldDisableItems = (): boolean => {
    const selectedItemCount = this.state.items.filter(item => item.isSelected).length;

    return !!this.props.data.limitSelection && selectedItemCount >= this.props.data.limitSelection;
  };

  private renderItems = (): ReactNode => {
    const shouldDisableItems = this.shouldDisableItems();

    return (
      <Styles.ItemWrapper>
        {
          this.state.items.map((item, index) => {
            const isDisabled = !item.isSelected && shouldDisableItems;

            return (
              <Styles.Item
                key={item.id}
                colour={item.color}
                isSelected={item.isSelected}
                isDisabled={!item.isSelected && shouldDisableItems}
                onClick={(): void => {
                  if (!isDisabled) {
                    this.onSelect(index);
                  }
                }}
              >
                <p>{item.title}</p>
              </Styles.Item>
            );
          })
        }
      </Styles.ItemWrapper>
    );
  };

  render(): ReactNode {
    return (
      <GenericModal
        title={this.props.data.title}
        closeModal={this.props.closeModal}
        modalOpen={this.props.isOpen}
        confirmButtonText={this.props.data.confirmButtonCopy}
        confirmButtonAction={this.onConfirm}
        confirmButtonDisabled={false}
        width={430}
        innerComponent={(
          <Box>
            {this.renderItems()}
          </Box>
        )}
      />
    );
  }
}

export default TagSelectionModal;

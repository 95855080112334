import { FC, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box, Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import { SupplierCard } from 'features/suppliers/components/supplier-card/supplier-card';

import { ButtonDropdown } from 'components/button-dropdown/button-dropdown';
import Icon from 'components/icon';

import { SupplierSelectProps } from './supplier-select.types';

export const SupplierSelect: FC<SupplierSelectProps> = ({
  selectedSupplierId,
  supplierOptions,
  isLoading = false,
  shouldAllowSelect = false,
  defaultCopy = 'Select supplier'
}) => {
  const renderSingleSupplier = (): ReactNode => {
    return (
      <Box
        css={css`
          text-decoration: underline;
        `}
      >
        {supplierOptions?.[0]?.title || 'Unknown supplier'}
      </Box>
    );
  };

  const renderSupplierDropdown = (): ReactNode => {
    return (
      <ButtonDropdown
        buttonCopy={defaultCopy}
        options={supplierOptions
          .filter(option => option.isActive)
          .map(option => ({
            title: option.title || 'Unknown supplier',
            onClick: option.onSelect
          }))}
        positionX="left"
        renderButton={() => {
          const selectedSupplier = supplierOptions.find(supplier => supplier.id === selectedSupplierId);
          const isSupplierInActive = !!selectedSupplier && !selectedSupplier.isActive;
          const selectionColour = isSupplierInActive ? colors.errorText : colors.floomMidnightBlue;

          return (
            <Flex
              mt="3px"
              alignItems="center"
              css={css`
                opacity: ${isLoading ? .4 : 1};
              `}
            >
              <Box
                css={css`
                  text-decoration: underline;
                  color: ${selectionColour}
                `}
              >
                {(() => {
                  switch (true) {
                    case isLoading: {
                      return 'Loading...';
                    }

                    default: {
                      return selectedSupplier?.title || defaultCopy;
                    }
                  }
                })()}
              </Box>
              <Box ml="10px">
                <Icon
                  iconName="arrow-down"
                  pathFill={selectionColour}
                />
              </Box>
            </Flex>
          );
        }}
      />
    );
  };

  return (
    <Box mt={20}>
      <SupplierCard
        renderSupplierName={shouldAllowSelect ? renderSupplierDropdown : renderSingleSupplier}
      />
    </Box>
  );
};

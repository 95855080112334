import { css } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import { Flex, Box } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

export const Container = styled(Flex)`
  position: relative;
  align-items: stretch;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const IconClose: StyledComponent<any, any, any> = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
`;

export const ButtonContainer: StyledComponent<any, any, any> = styled(Box)`
  width: 100%;
  margin-top: 100px;
`;

export const Title = css`
  ${textStyles.title};
  max-width: 245px;
`;

export const CheckboxContainer = styled(Flex)`
  justify-content: flex-end;
  margin-bottom: 30px;
`;

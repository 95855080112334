import React, { Component } from 'react';

import { Router } from '@reach/router';
import { NavPages } from 'global.types';
import Helmet from 'react-helmet';

import OnboardingBankDetails from 'features/onboarding/pages/onboarding-bank-details';
import OnboardingCompletion from 'features/onboarding/pages/onboarding-completion';
import OnboardingConfirmation from 'features/onboarding/pages/onboarding-confirmation';
import OnboardingController from 'features/onboarding/pages/onboarding-controller';
import OnboardingPaymentDetails from 'features/onboarding/pages/onboarding-payment-details';
import OnboardingStripeConnect from 'features/onboarding/pages/onboarding-stripe-connect';
import OnboardingSubscriptionIssue from 'features/onboarding/pages/onboarding-subscription-issue';

import { AuthedRoute } from 'components/route/route';

export default class Onboarding extends Component {
  render(): JSX.Element {
    return (
      <>
        {/*
        // @ts-ignore */}
        <Helmet>
          <title>Onboarding</title>
        </Helmet>
        <OnboardingController>
          <Router>
            <AuthedRoute
              path={NavPages.OnboardingHome}
              component={OnboardingConfirmation}
            />
            <AuthedRoute
              path={NavPages.OnboardingConfirmation}
              component={OnboardingCompletion}
            />
            <AuthedRoute
              path={NavPages.OnboardingStripeConnect}
              component={OnboardingStripeConnect}
            />
            <AuthedRoute
              path={NavPages.OnboardingFloomPayment}
              component={OnboardingBankDetails}
            />
            <AuthedRoute
              path={NavPages.OnboardingSubscription}
              component={OnboardingPaymentDetails}
            />
            <AuthedRoute
              path={NavPages.OnboardingSubscription}
              component={OnboardingPaymentDetails}
            />
            <AuthedRoute
              path={NavPages.OnboardingSubscriptionError}
              component={OnboardingSubscriptionIssue}
            />
          </Router>
        </OnboardingController>
      </>
    );
  }
}

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { space, textStyles } from 'utils/rebass-theme';

import NoListsCreatedSVG from 'assets/icons/lists-none-created.svg';

export const ListsWrapper = styled(Box)`
  padding: ${space[3]}px ${space[2]}px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NoListsWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoListsCreatedIcon = styled(NoListsCreatedSVG)`
  margin-bottom: 15px;
`;

export const NoListsHeaderText = styled.div`
  ${textStyles.h3};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const NoListsBodyText = styled.div`
${textStyles.body};
`;

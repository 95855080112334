import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

export const TabButton = styled.div`
  cursor: pointer;
  min-width: auto;
`;

export const TabNames = styled(Box)`
  display: inline-flex;
`;

export const TabText: any = styled.div`
  ${textStyles.subhead};
  color: ${(props: any): any => props.active ? colors.floomMidnightBlue : colors.middleGrey};
  padding: 16px;
  border-bottom: 1px solid ${(props: any): any => props.active ? colors.floomMidnightBlue : colors.middleGrey};
  transition: border .2s ease-in-out;
  transition: color .2s ease-in-out;
  white-space: nowrap;
`;

import { FC, Fragment, useEffect } from 'react';

import 'styles/tailwind.css';

import { CheckIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@tanstack/react-query';
import { navigate } from 'gatsby';
import { Currency } from 'generated-types';
import { NavPages } from 'global.types';
import moment from 'moment';
import store from 'stores';
import { shallow } from 'zustand/shallow';

import MerchantStore from 'stores/merchant/merchant-store';

import { colors } from 'utils/rebass-theme';

import { Payment } from '../components/payment';
import { Postcodes } from '../components/postcodes';
import { getUpcomingPeriod } from '../queries';
import { usePromotedStore } from '../store';

const merchantStore = store.merchantStore as MerchantStore;

const PromotedUpcoming: FC<{ path: string }> = props => {
  // if (!merchantStore.merchant!.promotedActive) {
  navigate(NavPages.Overview);
  // }

  console.log('render PromotedUpcoming');

  const {
    setMerchant,
    setUpcomingPeriod,
    merchantTimezone
  } = usePromotedStore(
    state => ({
      setMerchant: state.setMerchant,
      setMerchantSelectablePostcodes: state.setMerchantSelectablePostcodes,
      setUpcomingPeriod: state.setUpcomingPeriod,
      merchantTimezone: state.merchantTimezone
    }),
    shallow
  );

  useEffect(() => {
    setMerchant(merchantStore.merchant!);
  }, [merchantStore]);

  const { data: upcomingPeriod, isLoading, isSuccess } = useQuery({
    queryKey: ['upcomingPeriod'],
    queryFn: () => getUpcomingPeriod(
      setUpcomingPeriod,
      merchantStore.merchant!.timezone
    )
  });

  const currentStep = props.path.split('/')[3];

  const periodDates = !!upcomingPeriod
    ? `${moment(upcomingPeriod.startDate).tz(merchantTimezone).format('Do MMMM')} - ${moment(upcomingPeriod.endDate).tz(merchantTimezone).format('Do MMMM')}`
    : null;

  const timespan = !!upcomingPeriod
    ? moment(upcomingPeriod.endDate).diff(moment(upcomingPeriod.startDate), 'weeks')
    : null;

  return (
    <Fragment>
      <div className="max-w-5xl mx-auto px-6 md:px-8 py-6">
        <div className="mb-3">
          <div className="flex-wrap justify-between items-center">
            <div className="mb-3 flex items-center justify-between">
              <h1 className="text-lg">Book promotion</h1>
              {isLoading ? (
                <div className="h-5 w-24 mt-1 bg-gray-200 rounded animate-pulse" />
              ) : !!periodDates && (
                <p className="flex items-center text-sm bg-white border border-slate-200 rounded-md sm:rounded-lg px-4 py-2 leading-6 text-slate-800 mb-1">
                  <span className="mr-3 text-lg">📅</span>
                  Period runs: <span className="mx-2 font-bold text-base">{periodDates}</span> ({timespan} weeks)
                </p>
              )}
            </div>
            <div className="flex-1">
              {isSuccess ? (
                <Tabs
                  currency={merchantStore.merchant!.currency}
                  currentStep={currentStep}
                />
              ) : (
                <div className="h-20 mt-2 bg-gray-200 rounded-md animate-pulse" />
              )}
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-6 sm:col-span-4 h-96 bg-gray-200 rounded-md animate-pulse" />
            <div className="col-span-6 sm:col-span-2 h-96 bg-gray-200 rounded-md animate-pulse" />
          </div>
        )}
        {isSuccess && (
          <Fragment>
            {currentStep === 'postcodes' && (
              <Postcodes />
            )}
            {currentStep === 'payment' && (
              <Payment />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

const Tabs: FC<{ currentStep: string; currency: Currency }> = props => {
  const {
    selectedPostcodes
  } = usePromotedStore(
    state => ({
      selectedPostcodes: state.selectedPostcodes
    }),
    shallow
  );

  const steps = [
    {
      id: '01',
      name: props.currency === 'GBP' ? 'Postcodes' : 'Zipcodes',
      href: NavPages.PromotedUpcomingPostcodes,
      status: props.currentStep === 'payment' ? 'complete' : 'current'
    },
    {
      id: '02',
      name: 'Payment',
      href: NavPages.PromotedUpcomingPayment,
      status: props.currentStep === 'payment' ? 'current' : 'upcoming'
    }
  ];

  const handleStepClick = (event: any, pageLink: string): void => {
    event.preventDefault();

    if (props.currentStep === 'payment' && selectedPostcodes().length > 0) {
      // @todo: show a modal to confirm leaving the page
      if (!confirm(`If you go back, your chosen ${props.currency === 'GBP' ? 'postcodes' : 'zipcodes'} will be made available for others. Are you sure you want to go back?`)) {
        return;
      }
    }

    navigate(pageLink);
  };

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-slate-200 bg-white rounded-md sm:rounded-lg border border-slate-200 md:flex md:divide-y-0"
      >
        {steps.map((step, stepIdx) => (
          <li
            key={step.name}
            className="relative block md:flex md:flex-1"
          >
            {step.status === 'complete' ? (
              <div
                onClick={e => handleStepClick(e, step.href)}
                className="group flex w-full items-center cursor-pointer"
              >
                <span className="flex items-center px-6 py-2 text-sm font-medium">
                  <span
                    style={{ backgroundColor: colors.floomMidnightBlue }}
                    className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full group-hover:bg-indigo-950"
                  >
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span
                    className="ml-4 text-sm font-bold"
                  >
                    {step.name}
                  </span>
                </span>
              </div>
            ) : step.status === 'current' ? (
              <div
                className="flex items-center px-6 py-2 text-sm font-bold"
                aria-current="step"
              >
                <span
                  style={{ borderColor: colors.floomMidnightBlue, marginTop: '-2px' }}
                  className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2"
                >
                  <span>{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium">{step.name}</span>
              </div>
            ) : (
              <div
                className="group flex items-center"
              >
                <span className="flex items-center px-6 py-2 text-sm font-medium">
                  <span className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-slate-300">
                    <span
                      className="text-slate-500"
                      style={{ marginTop: '-2px' }}
                    >
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-slate-500">{step.name}</span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <Fragment>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute right-0 top-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-slate-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </Fragment>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default PromotedUpcoming;

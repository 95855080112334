import React, { Component } from 'react';

import { Analytics } from 'lib';

import Delivery from 'features/settings/components/delivery/components/delivery-details';
import SettingsService from 'features/settings/services';

class DeliverySettings extends Component {
  private SettingsAnalytics = SettingsService.SettingsAnalytics;

  componentDidMount(): void {
    Analytics.page(Analytics.FxPageEvent.SettingsDelivery);
    this.SettingsAnalytics.onSectionView(Analytics.SettingsSection.Delivery);
  }

  render(): JSX.Element {
    return <Delivery {...this.props} />;
  }
}

export default DeliverySettings;

import {
  action,
  observable,
  makeObservable,
  runInAction
} from 'mobx';

import {
  CatalogItemType,
  Maybe,
  MutationUpdateWholesalePreOrderArgs,
  WholesaleOrder,
  WholesalePreOrder,
  WholesalePreOrderItem,
  WholesalePreOrderItemUpdateInput
} from 'generated-types.d';

import { SelectedWholesalePreOrderService } from './selected-wholesale-pre-order.service';

export default class SelectedWholesalePreOrderStore {
  constructor() {
    makeObservable(this, {
      preOrder: observable,
      itemIdsBeingEdited: observable,
      isLoadingPreOrder: observable,
      isLoadingTotals: observable,
      isNewPreOrder: observable,
      fetchPreOrder: action,
      updatePreOrderItem: action,
      cancelPreOrder: action,
      toggleIsNewPreOrder: action
    });
  }

  public preOrder: WholesalePreOrder | null = null;

  public itemIdsBeingEdited: Set<string> = new Set();

  public isLoadingPreOrder: boolean = true;

  public isLoadingTotals: boolean = false;

  public isNewPreOrder: boolean = false;

  public selectedCategory: CatalogItemType = CatalogItemType.Flower;

  public toggleIsNewPreOrder = (isNewPreOrder = !this.isNewPreOrder): void => {
    this.isNewPreOrder = isNewPreOrder;
  };

  public setSelectedCategory = (category: CatalogItemType): void => {
    this.selectedCategory = category;
  };

  public clearPreOrder = (): void => {
    this.preOrder = null;
    this.isLoadingPreOrder = true;
    this.isNewPreOrder = false;
  };

  public fetchPreOrder = async ({ preOrderId } : { preOrderId: string }): Promise<void> => {
    try {
      runInAction(() => {
        this.isLoadingPreOrder = true;
      });

      const result = await SelectedWholesalePreOrderService.fetchPreOrder(preOrderId);

      if (!!result) {
        runInAction(() => {
          this.preOrder = result;
        });
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      runInAction(() => {
        this.isLoadingPreOrder = false;
      });
    }
  };

  public fetchPreOrderTotals = async ({ preOrderId } : { preOrderId: string }): Promise<void> => {
    try {
      runInAction(() => {
        this.isLoadingTotals = true;
      });

      const result = await SelectedWholesalePreOrderService.fetchPreOrderTotals(preOrderId);

      if (!!result?.totals && !!this.preOrder) {
        runInAction(() => {
          this.preOrder!.totals = result?.totals;
          this.isLoadingTotals = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.isLoadingTotals = false;
      });

      return Promise.reject(error);
    }
  };

  public updatePreOrderItem = async ({
    preOrderId,
    preOrderItemId,
    updateInput
  }: {
    preOrderId: string;
    preOrderItemId: string;
    updateInput: WholesalePreOrderItemUpdateInput;
  }): Promise<void> => {
    const data: MutationUpdateWholesalePreOrderArgs = {
      where: {
        id: preOrderId,
        merchant: {
          id: this.preOrder?.merchant.id
        }
      },
      data: {
        items: {
          update: [{
            where: {
              id: preOrderItemId
            },
            data: updateInput
          }]
        }
      }
    };

    try {
      this.itemIdsBeingEdited.add(preOrderItemId);
      const result = await SelectedWholesalePreOrderService.updatePreOrderItem({ data, preOrderItemId });
      const updatedPreOrderItem = result?.items?.find?.(listItem => listItem.id === preOrderItemId);

      if (updatedPreOrderItem) {
        this.replacePreOrderItem(updatedPreOrderItem);
        this.itemIdsBeingEdited.delete(preOrderItemId);
        this.fetchPreOrderTotals({ preOrderId });
      }
    } catch (error) {
      this.itemIdsBeingEdited.delete(preOrderItemId);

      return Promise.reject(error);
    }
  };

  private replacePreOrderItem = (updatedItem: WholesalePreOrderItem): void => {
    const findItemIndex = this.preOrder?.items?.findIndex?.(preOrderItem => preOrderItem.id === updatedItem.id);

    if (typeof findItemIndex === 'number' && findItemIndex !== -1 && !!this.preOrder?.items?.length) {
      this.preOrder.items[findItemIndex] = updatedItem;
    }
  };

  public deletePreOrderItem = async ({
    preOrderId,
    preOrderItemId
  }: {
    preOrderId: string;
    preOrderItemId: string;
  }): Promise<void> => {
    try {
      const data: MutationUpdateWholesalePreOrderArgs = {
        where: {
          id: preOrderId,
          merchant: {
            id: this.preOrder?.merchant.id
          }
        },
        data: {
          items: {
            disconnect: [
              {
                id: preOrderItemId
              }
            ]
          }
        }
      };

      await SelectedWholesalePreOrderService.updatePreOrderItem({ data, preOrderItemId });

      this.removePreOrderItem(preOrderItemId);
    } catch {
      return Promise.reject();
    }
  };

  private removePreOrderItem = (preOrderItemId: string): void => {
    const itemIndex = this.preOrder?.items?.findIndex?.(preOrderItem => preOrderItem.id === preOrderItemId);

    if (typeof itemIndex === 'number' && itemIndex !== -1 && !!this.preOrder?.items?.length) {
      this.preOrder.items.splice(itemIndex, 1);
    }
  };

  public cancelPreOrder = async ({ preOrderId } : { preOrderId: string }): Promise<void> => {
    try {
      await SelectedWholesalePreOrderService.cancelPreOrder(preOrderId);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public createDraftOrder = async (preOrder : WholesalePreOrder): Promise<Maybe<WholesaleOrder> | undefined> => {
    try {
      return SelectedWholesalePreOrderService.createDraftOrder(preOrder);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

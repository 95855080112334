import { action, observable, makeObservable } from 'mobx';

export default class ChatWidgetStore {
  constructor() {
    makeObservable(this, {
      isChatWidgetOpen: observable,
      isChatWidgetHidden: observable,
      isHelpCenterSuppressed: observable,
      enableChatWidget: action,
      disableChatWidget: action,
      suppressHelpCenter: action,
      showChatWidget: action,
      hideChatWidget: action
    });
  }

  isChatWidgetOpen: boolean = false;

  isHelpCenterSuppressed: boolean = false;

  isChatWidgetHidden: boolean = true;

  public showChatWidget = (): void => {
    this.isChatWidgetHidden = false;
  };

  public hideChatWidget = (): void => {
    this.isChatWidgetHidden = true;
  };

  public suppressHelpCenter = (): void => {
    this.isHelpCenterSuppressed = true;
  };

  public enableChatWidget = (): void => {
    this.isChatWidgetOpen = true;
  };

  public disableChatWidget = (): void => {
    this.isChatWidgetOpen = false;
  };
}

import { FC, Fragment, useEffect, useState } from 'react';

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { Link, navigate } from 'gatsby';
import { NavPages } from 'global.types';
import pluralize from 'pluralize';
import { Box } from 'rebass';
import { shallow } from 'zustand/shallow';

import { Currency } from 'generated-types.d';

import { colors } from 'utils/rebass-theme';

import { HOLD_TIME_IN_SECONDS } from '../constants';
import { releasePostcodes } from '../queries';
import { usePromotedStore } from '../store';

import { Basket } from './basket';
import PaymentForm from './payment-form';

const stripePromise = loadStripe(process.env.FX_FE_STRIPE_PUBLIC_KEY!);

export const Payment: FC = () => {
  console.log('render payment');

  const {
    selectedPostcodes,
    paymentIntentClientSecret,
    currencySymbol,
    selectedPostcodesTotal,
    clearPostcodes,
    merchantCurrency
  } = usePromotedStore(
    state => ({
      selectedPostcodes: state.selectedPostcodes,
      paymentIntentClientSecret: state.paymentIntentClientSecret,
      currencySymbol: state.currencySymbol,
      selectedPostcodesTotal: state.selectedPostcodesTotal,
      clearPostcodes: state.clearPostcodes,
      merchantCurrency: state.merchantCurrency
    }),
    shallow
  );

  const secondsToCheckout = HOLD_TIME_IN_SECONDS;
  const niceTime = pluralize('minute', secondsToCheckout / 60, true);
  const [seconds, setSeconds] = useState<number>(secondsToCheckout);
  // const [isIntervalPaused, setIsIntervalPaused] = useState<boolean>(false);
  const [intervalState, setIntervalState] = useState<NodeJS.Timeout>();

  const paymentElementsOptions: StripeElementsOptions = {
    clientSecret: paymentIntentClientSecret,
    appearance: {
      theme: 'stripe'
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      return setSeconds(s => (s - 1) > 0 ? s - 1 : 0);
    }, 1000);

    setIntervalState(interval);

    return () => {
      console.log('left early');
      clearInterval(intervalState!);
      releasePostcodes(clearPostcodes, selectedPostcodes());
    };
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      console.log('Timer ended!');
      clearInterval(intervalState!);
      releasePostcodes(clearPostcodes, selectedPostcodes());
    }
  }, [seconds]);

  const amountText = `${currencySymbol()}${selectedPostcodesTotal()}`;
  const timerHasEnded = seconds === 0;

  const handleContinue = (): void => {
    navigate(NavPages.PromotedUpcomingPostcodes);
  };

  const handleChange = (event: any): void => {
    console.log('changed', event);
  };

  return (
    <Fragment>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-6 sm:col-span-4">
          <div
            className="bg-white border border-gray-200 overflow-hidden rounded-md sm:rounded-lg"
          >
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium">
                Payment
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                You have {niceTime} to complete payment before your selected {merchantCurrency === 'GBP' ? 'postcodes' : 'zipcodes'} are released for others to buy
              </p>
            </div>
            <div className="border-t border-gray-200">
              <div className="px-4 py-5 sm:px-6">
                {/* {!isIntervalPaused && ( */}
                <TimerNotice
                  secondsToCheckout={secondsToCheckout}
                  seconds={seconds}
                  currency={merchantCurrency}
                />
                {/* )} */}

                {!!timerHasEnded && (
                  <Box
                    onClick={handleContinue}
                  >
                    <button
                      style={{
                        backgroundColor: colors.floomMidnightBlue
                      }}
                      className="mt-3 h-10 px-4 pb-1 rounded-md sm:rounded-lg text-white
                        justify-center flex items-center text-sm font-bold disabled:cursor-not-allowed
                        disabled:opacity-30"
                    >
                      <span>Select new postcodes</span>
                    </button>
                  </Box>
                )}

                {(!!paymentIntentClientSecret && !timerHasEnded) && (
                  <div className="mt-5">
                    <Elements
                    // @ts-ignore
                      options={paymentElementsOptions}
                      stripe={stripePromise}
                    >
                      <PaymentForm
                        handleChange={handleChange}
                        amount={amountText}
                      />
                    </Elements>
                    <p className="text-xs mt-2">
                      By purchasing promoted {merchantCurrency === 'GBP' ? 'postcodes' : 'zipcodes'} you agree to the products{' '}
                      <a
                        href="https://help.floomx.com/hc/en-us/articles/16123049750033-Promoted-Listings-Terms-Conditions"
                        target="_blank"
                        rel="noreferrer"
                        className="underline text-gray-500"
                      >
                        terms and conditions
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-6 sm:col-span-2">
          <div className="mb-3">
            <Basket canEdit={false} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const TimerNotice: FC<{
  seconds: number;
  secondsToCheckout: number;
  currency: Currency;
}> = props => {
  const timerHasEnded = props.seconds === 0;

  return (
    <div className={`transition-colors rounded-md ${timerHasEnded ? 'bg-red-50' : 'bg-yellow-50'} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon
            className={`transition-colors h-5 w-5 ${timerHasEnded ? 'text-red-400' : 'text-yellow-400'}`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 w-full">
          {!timerHasEnded && (
            <Fragment>
              <div className="flex mb-2">
                <p className="inline transition-colors text-sm font-bold text-yellow-800 mr-1.5">
                  Postcodes reserved for
                </p>
                <p
                  className="inline text-sm font-medium text-yellow-800"
                  style={{
                    marginTop: '1px'
                  }}
                >
                  {`${props.seconds < 60 ? (
                    `${props.seconds} seconds`
                  ) : (
                    `${Math.floor(props.seconds / 60)} ${pluralize('minute', Math.floor(props.seconds / 60))} 
                    and ${props.seconds % 60} ${pluralize('second', props.seconds % 60)}`
                  )}`}
                </p>
              </div>
              <ProgressBar
                totalSeconds={props.secondsToCheckout}
                color="yellow"
              />
            </Fragment>
          )}
          {!!timerHasEnded && (
            <Fragment>
              <div className="transition-colors text-sm text-red-700">
                <p>
                  The {props.currency === 'GBP' ? 'postcodes' : 'zipcodes'} you selected are now available for anyone to buy.
                  If you still want to promote in these {props.currency === 'GBP' ? 'postcodes' : 'zipcodes'},{' '}
                  <Link
                    className="underline"
                    to="/promoted/upcoming/postcodes"
                  >
                    please go back and select them again.
                  </Link>
                </p>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const ProgressBar: FC<{ totalSeconds: number; color: 'yellow' }> = props => {
  const [progress, setProgress] = useState(100);
  const [intervalState, setIntervalState] = useState<NodeJS.Timeout>();

  const secondsPerIncrement = props.totalSeconds / 100;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(p => (p - 1) > 0 ? p - 1 : 0);
    }, secondsPerIncrement * 1000);

    setIntervalState(interval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (progress === 0) {
      clearInterval(intervalState!);
    }
  }, [progress]);

  const colours = {
    yellow: {
      background: 'bg-yellow-200',
      bar: 'bg-yellow-400'
    }
  };

  const backgroundColour = colours[props.color].background;
  const barColour = colours[props.color].bar;

  return (
    <Fragment>
      <div className={`w-full ${backgroundColour} rounded-full h-2.5`}>
        <div
          className={`${barColour} h-2.5 rounded-full`}
          style={{ width: `${progress}%` }}
        />
      </div>
    </Fragment>
  );
};

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { OrderItem } from 'generated-types';
import { inject, observer } from 'mobx-react';

import OrdersStore from 'stores/orders/orders-store';

import { textStyles } from 'utils/rebass-theme';

import OrderHelpers from 'features/orders/helpers/order-helpers';

import MetaCardContentWrapper from 'components/meta-card/content-wrapper';
import StyledLink from 'components/styled-link/styled-link';

interface AddOnMetaProps {
  data: OrderItem[];
  ordersStore?: OrdersStore;
}

class AddOnMeta extends Component<AddOnMetaProps> {
  renderTotalAddOnCount = (addOns: any): ReactNode => {
    return (
      <div
        css={css`display: inline-block;`}
        onClick={(): void => this.props.ordersStore!.openAddOnsModal(addOns.items)}
      >
        <StyledLink>
          {addOns.totalCount} {addOns.totalCount > 1 ? 'Add-ons' : 'Add-on'}
        </StyledLink>
      </div>
    );
  };

  render(): ReactNode {
    const addOns = OrderHelpers.addOnsBuilder(this.props.data);

    if (!addOns.totalCount) {
      return null;
    }

    return (
      <MetaCardContentWrapper
        iconName="add-on"
        css={textStyles.body}
      >
        <span css={textStyles.body}>
          {this.renderTotalAddOnCount(addOns)}
        </span>
      </MetaCardContentWrapper>
    );
  }
}

export default inject('ordersStore')(observer(AddOnMeta));

import { action, observable, makeObservable } from 'mobx';

import * as Types from './payment-onboarding-store.types';

export default class PaymentOnboardingStore {
  constructor() {
    makeObservable(this, {
      onboardingConfig: observable,
      stage: observable,
      isLoading: observable,
      setOnboardingConfig: action,
      isLastStage: action,
      setStage: action
    });
  }

  public isLoading: boolean = false;

  public onboardingConfig: Types.OnboardingStep[] = [];

  public stage: Types.OnboardingStage | null = null;

  public findFirstIncompleteRoute = (): Types.OnboardingStep | undefined => {
    return this.onboardingConfig.find(step => step.isRequired && !step.isComplete);
  };

  public isLastStage = (stage: Types.OnboardingStage): boolean => {
    const currentStepIndex = this.onboardingConfig.findIndex(step => step.stage === stage);
    const requiredStepCount = this.onboardingConfig.filter(config => config.isRequired && config.isComplete);

    return requiredStepCount.length - 1 === currentStepIndex;
  };

  public setStage = (stage: Types.OnboardingStage): void => {
    this.stage = stage;
  };

  public resetStage = (): void => {
    this.stage = null;
  };

  public setOnboardingConfig = (config: Types.OnboardingStep[]): void => {
    this.onboardingConfig = config;
  };
}

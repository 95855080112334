import { css } from '@emotion/react';

export const Toggles = css`
  svg {
    transition: opacity .15s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
`;

export const ToggleButton = css`
  display: flex;

  &:first-child {
    margin-right: 15px;
  }
`;

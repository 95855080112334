import styled from '@emotion/styled';
import { Box } from 'rebass';

import { textStyles, colors } from 'utils/rebass-theme';

import { SectionHeadingSize } from './section-heading.types';

export const SectionHeading: any = styled(Box)<{ size: SectionHeadingSize }>`
  display: inline-flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${colors.middleGrey};
  margin: ${({ hasSpacing: hs }: any): any => hs ? '40px 0 15px 0' : 0};
  padding-bottom: 7px;

  ${({ size }): any => {
    switch (size) {
      case 'normal': return textStyles.title;

      case 'small': return textStyles.subhead;
    }
  }};
`;

export const GroupCount = styled.span`
  color: ${colors.middleGrey};
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
`;

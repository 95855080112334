import React, { Component, ReactNode } from 'react';

import { DeliveryConfig } from 'generated-types.d';

import { CurrencyService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import ItemMetaWrapper from 'components/meta-card/content-wrapper';

interface DeliveryPriceProps {
  data: DeliveryConfig;
}

export default class DeliveryPrice extends Component<DeliveryPriceProps> {
  render(): ReactNode {
    const sd = this.props.data.deliveryPrice!.sameDayPrice;
    const nd = this.props.data.deliveryPrice!.nextDayPrice!;
    const curr = this.props.data.merchant.currency;

    return (
      <ItemMetaWrapper
        iconName="delivery"
        css={textStyles.body}
      >
        <span css={textStyles.body}>
          <strong>SD</strong> {CurrencyService.formatPrice(sd, curr)}
          {', '}
          <strong>ND</strong> {CurrencyService.formatPrice(nd, curr)}
        </span>
      </ItemMetaWrapper>
    );
  }
}

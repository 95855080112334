import { FC } from 'react';

import { gql } from '@apollo/client';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { navigate } from 'gatsby';
import moment from 'moment';

import { Notification } from 'generated-types.d';

import { Analytics, GraphQL } from 'lib';

export const MARK_AS_READ_MUTATION = gql`
  mutation ReadNotifications($where: NotificationWhereUniqueInput!) {
    readNotification(where: $where)
  }
`;

export const SingleNotification: FC<{ notification: Notification; timezone: string }> = props => {
  const notificationDate = moment(props.notification.createdAt)
    .tz(props.timezone)
    .calendar(undefined, {
      sameDay: '[Today], h:mm a',
      lastDay: '[Yesterday], h:mm a',
      lastWeek: 'Do MMM, h:mm a',
      sameElse: 'Do MMM, h:mm a'
    });

  const markAsRead = async (id: string): Promise<void> => {
    await GraphQL.query(
      MARK_AS_READ_MUTATION,
      {
        where: {
          id
        }
      }
    );
  };

  const handleClick = (): void => {
    Analytics.track(Analytics.FxEventName.NotificationClicked, {
      event_version: 1,
      notification_id: props.notification.id,
      notification_createdAt: props.notification.createdAt,
      was_read: !!props.notification.readAt
    });

    if (!props.notification.readAt) {
      markAsRead(props.notification.id);
    }

    if (props.notification.link?.startsWith('http')) {
      window.open(props.notification.link, '_blank');
    } else {
      navigate(props.notification.link || '');
    }
  };

  return (
    <li
      key={props.notification.id}
      className="cursor-pointer relative flex items-center justify-between gap-x-6 px-2 py-3 hover:bg-gray-50 sm:px-4"
      onClick={() => handleClick()}
    >
      <div className="flex items-start gap-x-3">
        {props.notification.readAt ? (
          <div className="flex-none rounded-full p-1">
            <div className="h-1.5 w-1.5 rounded-full" />
          </div>
        ) : (
          <div
            className="flex-none rounded-full bg-orange-500/20 p-1"
            style={{ marginTop: '5px' }}
          >
            <div className="h-1.5 w-1.5 rounded-full bg-orange-500" />
          </div>
        )}
        <div className="pr-6">
          <p className="text-sm font-semibold text-slate-900">
            <span className="absolute inset-x-0 -top-px bottom-0" />
            {props.notification.title}
          </p>
          {props.notification.body && (
            <p className="text-xs leading-5 text-slate-500">
              {props.notification.body}
            </p>
          )}
          <p
            className="mt-2 text-xs leading-5 text-slate-400"
          >
            <time dateTime={notificationDate}>{notificationDate}</time>
          </p>
        </div>
      </div>
      <div className="flex flex-shrink-0 items-center gap-x-4">
        <ChevronRightIcon
          className="h-5 w-5 flex-none text-slate-400 mr-5"
          aria-hidden="true"
        />
      </div>
    </li>
  );
};

import {
  FC,
  Fragment
} from 'react';

import { css, Global } from '@emotion/react';
import { inject, observer } from 'mobx-react';

import { SidebarStyles } from 'features/modal-dialogue/modal-dialogue.styles';

import GenericModal from 'components/generic-modal';

import {
  Wrapper
} from './ordering-rules-sidebar.styles';
import {
  ListSettingsSidebarProps
} from './ordering-rules-sidebar.types';

const OrderingRulesSidebar: FC<ListSettingsSidebarProps> = ({
  closeModal,
  isOpen
}) => {
  return (
    <Fragment>
      <Global styles={SidebarStyles()} />
      <GenericModal
        title="Ordering rules"
        confirmButtonText="Ok"
        closeModal={closeModal}
        confirmButtonAction={closeModal}
        confirmButtonDisabled={false}
        isButtonLoading={false}
        isConfirmButtonParentWidth={true}
        footerStickToBottom={true}
        modalOpen={isOpen}
        width={440}
        closeTimeoutMS={2000}
        overlayClassName={{
          base: 'sidebar__OverlaySlide',
          afterOpen: 'sidebar__OverlaySlide--after-open',
          beforeClose: 'sidebar__OverlaySlide--before-close'
        }}
        contentClassName={{
          base: 'sidebar__ContentSlide',
          afterOpen: 'sidebar__ContentSlide--after-open',
          beforeClose: 'sidebar__ContentSlide--before-close'
        }}
        innerContentStyles={{
          paddingBottom: '60px'
        }}
        contentTransform=""
        contentLayoutType="full-height"
        actionButtonStyles={css`
          width: 100%;
        `}
        innerComponent={(
          <Wrapper>
            We will purchase the stems that match your requirements, and are below or equal to your max price settings.
          </Wrapper>
        )}
      />
    </Fragment>
  );
};

export default inject((stores: FxStores): InjectedFxStores => ({
  selectedListStore: stores.selectedListStore
}))(observer(OrderingRulesSidebar));

import { FC, Fragment } from 'react';

import { Currency } from 'generated-types.d';

import { CurrencyService } from 'lib';

export interface ListPriceRangeProps {
  min?: number;
  max?: number;
  currency?: Currency;
}

export const ListPriceRange: FC<ListPriceRangeProps> = ({
  min,
  max,
  currency = Currency.Gbp
}) => {
  if ((min !== 0 && !min) || (max !== 0 && !max)) {
    return (
      <Fragment>
        --
      </Fragment>
    );
  }

  if (min === max) {
    return (
      <Fragment>
        {CurrencyService.formatPrice(min, currency)}
      </Fragment>
    );
  }

  const value = `${CurrencyService.formatPrice(min, currency)} - ${CurrencyService.formatPrice(max, currency)}`;

  return (
    <span title={value}>
      {value}
    </span>
  );
};

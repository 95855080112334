import { Component, ReactNode } from 'react';

import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { Box } from 'rebass';

import { ORDER_LIST_PAGE_LAYOUTS } from 'features/orders/graphql/helpers/order-group.config';
import { OrderListService } from 'features/orders/services';

import NoResultsGeneric from 'components/no-results-generic';

import * as Styles from './no-results.styles';
import * as Types from './no-results.types';

export default class OrderNoResults extends Component<Types.OrderNoResultsProps> {
  private isSearchPage = (): boolean => this.props.listLayout === 'search';

  private navigateToSearch = (): void => {
    OrderListService.setListLayout('search');
  };

  private searchText = connectCurrentRefinements(({ refine, items }): any => {
    const resetFilters = (): void => {
      refine(items);
      this.props.onResetFilters();
    };

    if (this.isSearchPage() && !this.props.searchValue.length) {
      return 'Please enter a search term to see some orders!';
    }

    if (!this.isSearchPage() && !this.props.searchValue.length) {
      return (
        <span>
          Either there are no orders for {ORDER_LIST_PAGE_LAYOUTS[this.props.listLayout].title} currently,
          {' '}or there are filters applied hiding some orders. You can try to
          {' '}<Styles.ButtonCopy onClick={resetFilters}>reset filters</Styles.ButtonCopy>, or
          {' '}<Styles.ButtonCopy onClick={this.navigateToSearch}>search all orders</Styles.ButtonCopy>
        </span>
      );
    }

    return `
      Your search for "${this.props.searchValue}"
      returned 0 orders for ${ORDER_LIST_PAGE_LAYOUTS[this.props.listLayout].title}
    `;
  });

  render(): ReactNode | null {
    if (this.props.isLoading) return null;

    return (
      <Box m="40px auto 0 auto">
        <NoResultsGeneric
          icon="business-cross"
          heading="No orders found"
          copy={(
            <this.searchText />
          )}
        />
      </Box>
    );
  }
}

import * as Yup from 'yup';

import { PlanType } from 'generated-types.d';

import {
  ValidationService
} from 'lib';

import {
  SupportedPlanTypes,
  SelectedPlanChangeType,
  SelectedPlanConfig,
  ExtendedPlanShiftFormData
} from './plan-shift-modal.types';

export const PLAN_SHIFT_FORM_VALIDATION = Yup.object().shape<ExtendedPlanShiftFormData>({
  hasSubscribed: Yup.boolean(),
  selectedPlan: Yup
    .object()
    .nullable(true)
    .shape({
      id: Yup.string().required(),
      type: Yup.string<PlanType>().required(),
      price: Yup.number().required()
    })
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please select a plan')),
  selectedPlanConfig: Yup
    .object()
    .shape<SelectedPlanConfig>({
      changeType: Yup.string<SelectedPlanChangeType>().required(),
      planType: Yup.string<PlanType>().required()
    }),
  selectedTrialPeriod: Yup
    .string()
    .when('hasSubscribed', {
      is: false,
      then: (s: Yup.StringSchema<string>): any => s.required(params => ValidationService.generateYupMessageSchema(params, 'Please select a trial period'))
    }),
  planShiftReason: Yup
    .string()
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please enter a reason for changing the plan')),
  hasConfirmed: Yup
    .boolean()
    .oneOf([true], params => ValidationService.generateYupMessageSchema(params, 'Please understand and confirm the changes you are about to make'))
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please understand and confirm the changes you are about to make')),
  planPrice: Yup
    .number()
    .nullable(true)
    .min(0, params => ValidationService.generateYupMessageSchema(params, 'Plan price must be 0 or greater'))
    .required(params => ValidationService.generateYupMessageSchema(params, 'Please enter a plan price'))
});

export const SUPPORTED_PLAN_TYPES: SupportedPlanTypes = {
  WholesaleOnly: [
    {
      planType: PlanType.Lite1,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Lite2,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Standard,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Standard2,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Premium,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Premium2,
      changeType: 'upgrade'
    }
  ],
  Lite1: [
    {
      planType: PlanType.Lite2,
      changeType: 'neutral'
    },
    {
      planType: PlanType.Standard,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Standard2,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Premium,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Premium2,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.WholesaleOnly,
      changeType: 'downgrade'
    }
  ],
  Lite2: [
    {
      planType: PlanType.Lite1,
      changeType: 'neutral'
    },
    {
      planType: PlanType.Standard,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Standard2,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Premium,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Premium2,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.WholesaleOnly,
      changeType: 'downgrade'
    }
  ],
  Premium: [
    {
      planType: PlanType.Premium2,
      changeType: 'neutral'
    },
    {
      planType: PlanType.Standard,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.Standard2,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.Lite1,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.Lite2,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.WholesaleOnly,
      changeType: 'downgrade'
    }
  ],
  Premium2: [
    {
      planType: PlanType.Premium,
      changeType: 'neutral'
    },
    {
      planType: PlanType.Standard,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.Standard2,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.Lite1,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.Lite2,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.WholesaleOnly,
      changeType: 'downgrade'
    }
  ],
  Standard: [
    {
      planType: PlanType.Premium,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Premium2,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Standard2,
      changeType: 'neutral'
    },
    {
      planType: PlanType.Lite1,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.Lite2,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.WholesaleOnly,
      changeType: 'downgrade'
    }
  ],
  Standard2: [
    {
      planType: PlanType.Premium,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Premium2,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Standard,
      changeType: 'neutral'
    },
    {
      planType: PlanType.Lite1,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.Lite2,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.WholesaleOnly,
      changeType: 'downgrade'
    }
  ],
  StandardLegacy: [
    {
      planType: PlanType.Premium,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Premium2,
      changeType: 'upgrade'
    },
    {
      planType: PlanType.Standard,
      changeType: 'neutral'
    },
    {
      planType: PlanType.Standard2,
      changeType: 'neutral'
    },
    {
      planType: PlanType.Lite1,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.Lite2,
      changeType: 'downgrade'
    },
    {
      planType: PlanType.WholesaleOnly,
      changeType: 'downgrade'
    }
  ]
};

export const UPGRADE_COPY = `
  This plan change is an <strong>upgrade</strong> and the changes will take
  effect immediately, once confirmed. Payment for the upgrade will be
  charged immediately, and you can estimate the pro-rata fee below
  for the remainder of the current billing period ending
  <strong>[BILLING_END_DATE]</strong>.
`;

export const DOWNGRADE_COPY = `
  The plan change is a <strong>downgrade</strong> and the changes will take
  effect at the end of their current billing period (<strong>[BILLING_END_DATE]</strong>).
  You will have until this date to cancel the plan change.
`;

export const NEUTRAL_PLAN_CHANGE_COPY = `
  This changes you are making to this plan will take
  effect immediately, once confirmed. Any changes to the payment for this
  plan change will be charged immediately, and you can estimate the
  pro-rata fee below for the remainder of the current billing period ending
  <strong>[BILLING_END_DATE]</strong>.
`;

export const UNSUBSCRIBED_COPY = `
  Because the merchant has not yet subscribed to FloomX, their trial period can
  be changed. Please select the trial period that has been agreed with the merchant.
  Below are the changes you are making to the plan.
`;

export const UNSUBSCRIBED_CONFIRMATION_COPY = `
  I confirm that I am changing the plan for <strong>[MERCHANT_NAME]</strong>
  from <strong>[OLD_PLAN_NAME]</strong> to <strong>[NEW_PLAN_NAME]</strong>.
  The new monthly subscription fee is <strong>[NEW_PLAN_PRICE]</strong>
  per month, and the merchant will be charged and notified immediately.
`;

export const UPGRADE_CONFIRMATION_COPY = `
  I confirm that I am changing the plan for <strong>[MERCHANT_NAME]</strong>
  from <strong>[OLD_PLAN_NAME]</strong>  to <strong>[NEW_PLAN_NAME]</strong>,
  it will cost [NEW_PLAN_PRICE] per month, and the merchant will be
  charged and notified immediately.
`;

export const DOWNGRADE_CONFIRMATION_COPY = `
  I confirm that I am changing the plan for <strong>[MERCHANT_NAME]</strong>
  from <strong>[OLD_PLAN_NAME]</strong> to <strong>[NEW_PLAN_NAME]</strong>.
  The new monthly subscription fee is <strong>[NEW_PLAN_PRICE]</strong> per
  month which will take effect on <strong>[BILLING_END_DATE]</strong>.
  The merchant will be notified of the change immediately.
`;

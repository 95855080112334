import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { mq } from 'utils/css-mixins/css-mixins';
import { textStyles, colors } from 'utils/rebass-theme';

import { SelectWrap, Select, DropdownLabel } from 'components/dropdown-native/dropdown-native.styles';

export const SortDropdown = css`
  ${mq.lessThan('medium')} {
    ${SelectWrap}, ${Select}  {
      width: 100%;
    }

    ${DropdownLabel}  {
      display: none;
    }
  }
`;

interface FilterStyleProps {
  isOpen: boolean;
}

export const FilterWrapper = styled(Flex)<FilterStyleProps>`
  height: 51px;
  background: ${({ isOpen }): string => isOpen ? colors.uiFills : colors.floomMidnightBlue};
  border: 2px solid ${colors.floomMidnightBlue};
  margin-left: 15px;
  border-radius: 3px;
  padding: 0 20px;
`;

export const FilterCopy = styled(Box)<FilterStyleProps>`
  ${textStyles.subhead}
  color: ${({ isOpen }): string => isOpen ? colors.floomMidnightBlue : colors.uiFills};
  margin-top: -4px;
  padding-right: 10px;
`;

export const FilterIcon = css`
  transform: scale(0.8);
  margin-top: 2px;
`;

export const DownloadButton = styled(Box)`
  display: inline-block;
  align-self: flex-end;
`;

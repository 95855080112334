import { FC } from 'react';

import {
  Copy,
  Wrapper
} from './input-prefix.styles';
import {
  InputPrefixProps
} from './input-prefix.types';

export const InputPrefix: FC<InputPrefixProps> = ({
  prefix,
  size
}) => {
  return (
    <Wrapper size={size}>
      <Copy size={size}>
        {prefix}
      </Copy>
    </Wrapper>
  );
};

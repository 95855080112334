import { FC } from 'react';

import { inject, observer } from 'mobx-react';

import ModalStore from 'stores/modal-store/modal-store';

import { ConversationContactNewModalData } from 'features/modal-dialogue/components/modals/conversation-contact-new-modal/conversation-contact-new-modal.types';

import Button from 'components/button';

import { Wrapper } from './new-supplier-button.styles';

interface NewSupplierButtonProps {
  modalStore?: ModalStore;
}

export const NewSupplierButton: FC<NewSupplierButtonProps> = inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore
}))(observer(({
  modalStore
}) => {
  const openModal = (): void => {
    modalStore!.triggerModal<ConversationContactNewModalData>({
      modalType: 'conversationContactNew',
      data: {}
    });
  };

  return (
    <Wrapper
      as="button"
      onClick={openModal}
      width={1}
      pt={10}
    >
      <Button
        size="small"
        copy='Add Supplier'
        appearance='primary'
        isParentWidth={true}
      />
    </Wrapper>
  );
}));

import { gql } from '@apollo/client';
import { ChannelType } from 'generated-types';
import { DocumentNode } from 'graphql';
import camelCase from 'lodash.camelcase';

import { GraphQLHelpers } from 'lib';

import * as ChannelHelpers from '../helpers/category-query.helpers';
import * as Helpers from '../helpers/product-query.helpers';

export const PRODUCT_CHANNELS_COUNT_QUERY = (vars: any, productChannels: ChannelType[]): DocumentNode => {
  const getCountQuery = (variables: any, channel: any): string => {
    return `
      ${camelCase(channel.slug)}: productsConnection(
        ${Helpers.addRootArguments(variables)}
        where: {
          ${Helpers.addRootWhereArguments(variables)}
          ${GraphQLHelpers.addMerchantWhereFilter(variables.merchantId)}
          AND: [
            {
              channels_some: {
                channel_in: ${channel.slug}
              }
            }
            {
              ${Helpers.addTypeWhereFilter(variables.selectedTypes)}
            }
            ${GraphQLHelpers.searchableContainsValues(vars.search)}
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    `;
  };

  return gql`
    query ProductChannelCount (${ChannelHelpers.addRootQueryParams(vars)}) {
      ${productChannels.map(channel => getCountQuery(vars, channel))}
    }`;
};

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { ProgressBarProps } from './progress-bar.types';

const barHeight = (props: ProgressBarProps): string => {
  switch (props.size) {
    case 'small':

    default:
      return '6px';

    case 'medium':
      return '9px';
  }
};

export const ProgressBar = styled(Box)<ProgressBarProps>`
  position: relative;
  width: 100%;
  height: ${(props): string => barHeight(props)};
  border-radius: 20px;
  margin-top: 10px;
  background: ${(props): string => props.backgroundColour!};
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${(props): string => `${props.width}%`};
    border-radius: 20px;
    background: ${(props): string => props.barColour!};
    transition: width .35s cubic-bezier(0.63, 0.29, 0.17, 1.245);
    transform-origin: left;
  }
`;

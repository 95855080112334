import styled from '@emotion/styled';
import { Box } from 'rebass';
import { mq } from 'theme';

import { colors, borderRadius, shadows } from 'utils/rebass-theme';

export const BannerBar: any = styled(Box)`
  margin-top: 30px;
  top: 0px;
  z-index: 16;

  ${mq('small')} {
    margin-top: 20px
  };

  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
    height: 100%;
    width: 20px;
    top: 0;
    z-index: -1;
  }

  &:before {
    left: -20px;
  }

  &:after {
    right: -20px;
  }
`;

export const Container = styled(Box)`
  overflow: hidden;
  position: relative;
  padding: 20px;
  background: ${colors.white};
  box-shadow: ${shadows.large};
  border-radius: ${borderRadius.medium};
`;

export const SupportLink = styled.a`
  color: ${colors.floomMidnightBlue};
  text-decoration: underline;
`;

import styled, { StyledComponent } from '@emotion/styled';
import { Box } from 'rebass';

import { Container } from 'utils/css-mixins';
import { HeaderHeight, breakpoints } from 'utils/rebass-theme';

export const Wrapper: StyledComponent<any, any, any> = styled(Container)`
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 10;
  height: ${HeaderHeight};
  display: flex;
`;

export const LogoWrapper: StyledComponent<any, any, any> = styled.div`
  max-width: 100px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
`;

export const MenuIconWrapper = styled(Box)`
  display: block;
  padding-left: 10px;

  @media (min-width: ${breakpoints.small}) {
    display: none;
  }
`;

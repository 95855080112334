import { OrderDeliveryState } from 'generated-types';

import { GraphQLHelpers } from 'lib';

import {
  SelectedCourierListFilters,
  CourierListPageRoute,
  CourierListGroupOption,
  CourierListFilterGroup
} from 'features/courier-bookings/courier-bookings.types';
import { addAggregateFilter } from 'features/courier-bookings/graphql/queries/courier-queries';

import {
  COURIER_LIST_GROUP_OPTIONS,
  COURIER_LIST_PAGE_LAYOUTS
} from './courier-list-group.config';

export const hasFilters = (filters: SelectedCourierListFilters): boolean => Object.keys(filters).some(filterKey => !!filters[filterKey].size);

export const statusBySlug = (statusSlug: OrderDeliveryState): string => {
  return `
    status: {
      slug: "${statusSlug}"
    }
  `;
};

export const mapFilters = (filters: SelectedCourierListFilters): string => {
  // @ts-ignore
  return Object.keys(filters).map((filterKey: CourierListFilterGroup) => {
    return Array.from(filters[filterKey]).map(value => {
      return `{${addAggregateFilter(filterKey, value)}}`;
    });
  });
};

export const renderCourierListGroups = (listLayout: CourierListPageRoute, vars: any): string => {
  const filters: SelectedCourierListFilters = vars.filters;

  /* @ts-ignore */
  return COURIER_LIST_PAGE_LAYOUTS[listLayout].groups
    .reduce((acc: string, currGroupName: CourierListGroupOption) => {
      return `
        ${acc}
        ${currGroupName}: courierBookingsConnection(
          first: 20
          orderBy: $orderBy
          where: {
            deletedAt: null
            ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
            ${hasFilters(filters) ? `
              OR: [
                ${mapFilters(filters)}
              ]
            ` : ''}
            AND: [
              ${GraphQLHelpers.searchableContainsValues(vars.search)}
              {${COURIER_LIST_GROUP_OPTIONS[currGroupName].filter}}
              {${COURIER_LIST_PAGE_LAYOUTS[listLayout].globalFilter}}
            ]
          }
        ) {
          pageInfo {
            hasNextPage
          }
          aggregate {
            count
          }
          edges {
            node {
              ...CourierBookingFragmentFull
            }
          }
        }
      `;
    }, '');
};

import { ApolloError } from '@apollo/client';
import { observable, action, set, makeObservable } from 'mobx';

import {
  Product,
  ProductType,
  ProductConnection,
  ProductCategory,
  Channel
} from 'generated-types.d';

import { STOCK_GROUPS } from 'features/products/products.constants';

export type ProductListLayout = 'grid' | 'table';

export type ProductGroupOption = 'allProducts' | 'stock';

export type ProductStockGroup = 'active' | 'lowStock' | 'outOfStock';

export interface ProductFilter {
  title: string;
  active: boolean;
  slug: ProductFilterCategory | ProductFilterType | Channel;
  count?: number;
}

export interface ProductFilters {
  types: FilterType[];
  channels: any[];
  categories: ProductFilterCategory[];
}

export type FilterType =
  | 'types'
  | 'channels'
  | 'categories';

export type ProductFilterCategory =
  | 'mothers-day'
  | 'birthday'
  | 'valentines-day'
  | 'sympathy'
  | 'congratulations'
  | 'chritmas';

export type ProductFilterType =
  | 'flowers'
  | 'plants'
  | 'gifts'
  | 'wreaths-and-trees';

const groups: { [key in ProductGroupOption]: string[] } = {
  allProducts: ['allProducts'],
  stock: STOCK_GROUPS
};

export interface ProductStoreGroups {
  [groupType: string]: ProductConnection;
}

export interface Metadata {
  count: { [key in ProductStockGroup]: number };
}

export default class ProductsStore {
  isLoading: boolean = true;

  hasError: boolean = false;

  errorMsg: ApolloError | boolean = false;

  products: ProductStoreGroups = {};

  metadata: Metadata = {
    count: {
      active: 0,
      lowStock: 0,
      outOfStock: 0
    }
  };

  loadIncrement: number = 36;

  count: { [groupType: string]: number } = {
    allProducts: 0
  };

  productTypes: ProductType[] = [];

  productCategories: any[] = [];

  productChannels: any[] = [
    {
      title: 'Floom',
      active: true,
      slug: 'Floom'
    },
    {
      title: 'Website',
      active: true,
      slug: 'Website'
    }
  ];

  showInactiveProducts: boolean = true;

  orderBy: string = 'title_ASC';

  currentGroup: ProductGroupOption = 'allProducts';

  layoutOption: ProductListLayout = 'grid';

  searchValue: string = '';

  selectedTypes: string[] = [];

  selectedChannels: string[] = [];

  selectedCategories: string[] = [];

  filters: ProductFilters = {
    types: [],
    categories: [],
    channels: []
  };

  public updateProducts = (products: ProductStoreGroups): void => {
    this.products = products;
    this.resetLoading();
  };

  public updateProductMetadata = (metadata: Metadata): void => {
    this.metadata = metadata;
  };

  public appendProducts = (productData: ProductConnection, groupName: ProductGroupOption): void => {
    set(this.products[groupName].pageInfo, 'hasNextPage', productData.pageInfo.hasNextPage);

    set(this.products[groupName].edges, [
      ...this.products[groupName].edges,
      ...productData.edges
    ]);
    this.resetLoading();
  };

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      hasError: observable,
      errorMsg: observable,
      metadata: observable,
      products: observable.deep,
      loadIncrement: observable,
      count: observable,
      productTypes: observable,
      productCategories: observable,
      productChannels: observable,
      selectedTypes: observable,
      selectedChannels: observable,
      selectedCategories: observable,
      searchValue: observable,
      orderBy: observable,
      currentGroup: observable,
      showInactiveProducts: observable,
      layoutOption: observable,
      filters: observable,
      updateEditedProduct: action,
      updateProducts: action,
      appendProducts: action,
      setLoading: action,
      resetLoading: action,
      resetProductList: action,
      setSearch: action,
      setProductFilters: action,
      setGrouping: action,
      setInitialCounts: action,
      toggleInactiveProducts: action,
      setProductOrdering: action,
      setProductTypes: action,
      setProductCategories: action,
      setProductChannels: action,
      setLayout: action,
      resetError: action,
      loadingError: action,
      onLoadMore: action
    });
  }

  public resetLoading(): void {
    this.isLoading = false;
  }

  public setLoading(): void {
    this.resetError();
    this.isLoading = true;
  }

  public resetError(): void {
    this.hasError = false;
  }

  public loadingError(error: any): void {
    this.hasError = true;
    this.errorMsg = error;
  }

  public resetPagination(): void {
    Object.keys(this.count).forEach(groupName => {
      this.count[groupName] = 0;
    });
  }

  public onLoadMore(groupName: ProductGroupOption): void {
    this.count[groupName] = this.count[groupName] + this.loadIncrement;
  }

  public setSearch(searchValue: string = ''): void {
    this.setInitialCounts();
    this.searchValue = searchValue;
  }

  public setProductFilters(filter: string[], filterType: FilterType): void {
    this.setInitialCounts();
    set(this.filters, filterType, filter);
  }

  public setGrouping(selectedGroup: ProductGroupOption): void {
    this.currentGroup = selectedGroup;
    this.setInitialCounts();
  }

  public setInitialCounts(): void {
    this.count = groups[this.currentGroup].reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: 0
      };
    }, {});
  }

  public resetProductList = (): void => {
    this.products = {};
  };

  public toggleInactiveProducts(value: boolean): void {
    this.showInactiveProducts = value;
  }

  public setProductOrdering(orderBy: any = 'title_ASC'): void {
    this.resetPagination();
    this.orderBy = orderBy;
  }

  public setProductTypes(types: ProductType[]): void {
    this.productTypes = types;
  }

  public setProductCategories(category: ProductCategory[]): void {
    this.productCategories = category;
  }

  public setProductChannels(channel: any[]): void {
    this.productChannels = channel;
  }

  public setLayout(layoutOption: ProductListLayout): void {
    this.layoutOption = layoutOption;
  }

  public updateEditedProduct(product: Product): void {
    Object.keys(this.products).forEach(groupName => {
      const editedProductIndex = this.products[groupName].edges
        .findIndex(edge => edge?.node.id === product.id);

      if (editedProductIndex !== -1) {
        set(this.products[groupName].edges[editedProductIndex]!.node, product);
      }
    });
  }
}

import gql from 'graphql-tag';

import { PRODUCT_FULL_FRAGMENT } from '../fragments/product';

export const EDIT_PRODUCT_MUTATION = gql`
  mutation EditProduct(
    $where: ProductWhereUniqueInput,
    $data: ProductUpdateInput!
  ) {
    editProduct(
      where: $where,
      data: $data
    ) {
      ...ProductFull
    }
  }
  ${PRODUCT_FULL_FRAGMENT}
`;

export const DELETE_PRODUCT_MUTATION = gql`
  mutation DeleteProduct(
    $where: ProductWhereUniqueInput,
  ) {
    deleteProduct(
        where: $where
    ) {
      id
    }
  }
`;

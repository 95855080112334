import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import { AbsoluteCover } from 'utils/css-mixins/css-mixins';
import { breakpoints, colors, shadows } from 'utils/rebass-theme';

export const ImagesWrapper: any = styled(Box)`
  background: ${colors.lightGrey};
  max-height: 530px;
  z-index: 9;
  position: sticky;
  top: 0;

  @media (max-height: 500px) {
    position: relative;
  }
`;

export const ImageWrapper: any = styled(Box)`
  position: relative;
  width: 100%;
  height: 176px;
`;

export const HeroImageWrapper: any = styled(Flex)`
  display: flex;
  width: 100%;
  
  ${ImageWrapper} {
    height: 150px;

    @media (min-width: ${breakpoints.small}) {
      height: 200px;
    }
  }
`;

export const Image: any = styled(Flex)`
  ${AbsoluteCover};
  background-size: cover;
  background-position: center 20%;
  transition: opacity .2s ease-in-out;

  &.lazyloaded {
    opacity: 1;

    + ${(): any => ImageLoading} {
      opacity: 0;
    }
  }
`;

export const ImageLoading = styled(Flex)<any>`
  ${AbsoluteCover};
  height: 100%;
  padding: 19px 16px 16px 16px;
  transition: opacity .2s ease-in-out;
  opacity: 1;
`;

export const IconWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  margin: 10px;
  height: 38px;
  width: 38px;
  text-align: center;
  border-radius: 100%;
  line-height: 1;
  background-color: ${colors.white};
  box-shadow: ${shadows.small};

  svg {
    width: 15px;
    height: 15px;
  }
`;

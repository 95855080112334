import { List, SupplierDeliveryOption } from 'generated-types.d';

import { CurrencyService } from 'lib';

import { PromotedListsBasketItem } from 'stores/promoted-lists-store/promoted-lists-store';

export const getTotalListPrice = (list: List, basketItems: PromotedListsBasketItem[]): number => {
  return basketItems?.reduce?.((accTotal: number, basketItem): number => {
    const itemPrice = list.items?.find(item => item.id === basketItem.itemId)!.promotedTradeSkuAvailability?.price?.[0].price;

    if (itemPrice) {
      return accTotal + (itemPrice * (basketItem.quantity || 1));
    }

    return accTotal + (basketItem.quantity || 0);
  }, 0) || 0;
};

export const getTotalShippingEstimate = (
  list: List,
  basketItems: PromotedListsBasketItem[],
  deliveryOption?: SupplierDeliveryOption
): number | null => {
  if (!basketItems.length || !deliveryOption) {
    return null;
  }

  const hasFreeShipping = getTotalListPrice(list, basketItems) >= (deliveryOption.freeShippingThreshold || 0);

  return hasFreeShipping ? 0 : (deliveryOption.shippingPrice || 0);
};

export const getTotalQuantity = (basketItems: PromotedListsBasketItem[]): number => {
  return basketItems.reduce((acc, item) => acc + (item.quantity || 0), 0) || 0;
};

export const getSalesTax = (itemTotalPrice: number): number => {
  return CurrencyService.calculateSalesTax(itemTotalPrice);
};

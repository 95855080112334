import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import { mq } from 'theme';

import { colors } from 'utils/rebass-theme';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SearchWrapper = styled(Box)`
  z-index: 10;
  background: white;
`;

export const ViewResultsWrapper = styled(Box)`
  position: sticky;
  width: 100%;
  background: ${colors.uiFills};
  margin-top: auto;
  bottom: 0;
  box-shadow: 0 0 20px rgb(0 0 0 / 24%);
`;

export const ModalStyles = css`
  .catalog-filters-modal__ContentSlide {
    position: absolute;
    transform: translateX(20px);
    transition: transform .2s ease-in-out;
  }

  .catalog-filters-modal__ContentSlide--after-open {
    transform: translateX(0);
    width: 500px !important;
    ${mq(640)} {
      width: 100% !important;
    }
  }

  .catalog-filters-modal__ContentSlide--before-close {
    transform: translateX(20px);
  }

  .catalog-filters-modal__OverlaySlide {
    opacity: 0;
    transition: opacity .3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .catalog-filters-modal__OverlaySlide--after-open {
    opacity: 1;
  }

  .catalog-filters-modal__OverlaySlide--before-close {
    opacity: 0;
  }
`;

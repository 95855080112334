import { gql } from '@apollo/client';

import { MERCHANT_HOLIDAY_FRAGMENT } from '../fragments/merchant-holiday.fragments';

export const CREATE_MERCHANT_HOLIDAY = gql`
  mutation CreateMerchantHoliday($data: MerchantHolidayCreateInput!) {
    createMerchantHoliday(data: $data) {
      ...MerchantHolidayFragment
    }
  }
  ${MERCHANT_HOLIDAY_FRAGMENT}
`;

export const UPDATE_MERCHANT_HOLIDAY = gql`
  mutation UpdateMerchantHoliday(
    $data: MerchantHolidayUpdateInput!,
    $where: MerchantHolidayWhereUniqueInput!
  ) {
    updateMerchantHoliday(
      data: $data,
      where: $where
    ) {
      ...MerchantHolidayFragment
    }
  }
  ${MERCHANT_HOLIDAY_FRAGMENT}
`;

export const DELETE_MERCHANT_HOLIDAY = gql`
  mutation DeleteMerchantHoliday(
    $where: MerchantHolidayWhereUniqueInput!
  ) {
    deleteMerchantHoliday(where: $where) {
      id
    }
  }
`;

import React, { Component, ReactNode } from 'react';

import _get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';

import { Order, Merchant } from 'generated-types.d';

import CourierStore from 'stores/courier/courier-store';
import MerchantStore from 'stores/merchant/merchant-store';
import { OrdersListLayout } from 'stores/orders/orders-store.types';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import CourierStatusIndicator from 'features/courier/components/courier-status-indicator';
import { retrieveCourierStatusConfig } from 'features/courier/components/courier-status-indicator/courier-status-indicator.helpers';

import { IconType } from 'components/icon/icon.types';
import MetaCardContentWrapper from 'components/meta-card/content-wrapper';

interface DeliveryItemMetaProps {
  data: Order;
  merchant?: Merchant;
  layout: OrdersListLayout;
  courierStore?: CourierStore;
  merchantStore?: MerchantStore;
}

class DeliveryTimeMeta extends Component<DeliveryItemMetaProps> {
  private retrieveIconName = (): IconType => {
    return !!this.props.data.courierBooking
      ? retrieveCourierStatusConfig(this.props.data.courierBooking.deliveryStatus).iconType
      : 'delivery-time';
  };

  private retrieveIconColor = (): ColourOption => {
    return !!this.props.data.courierBooking
      ? retrieveCourierStatusConfig(this.props.data.courierBooking.deliveryStatus).iconColor
      : ColourOption.middleGrey;
  };

  render(): ReactNode {
    if (this.props.layout === 'table') {
      return (
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <CourierStatusIndicator orderData={this.props.data} />
        </Flex>
      );
    }

    return (
      <MetaCardContentWrapper
        iconName={this.retrieveIconName()}
        iconColor={this.retrieveIconColor()}
      >
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <CourierStatusIndicator orderData={this.props.data} />
        </Flex>
      </MetaCardContentWrapper>
    );
  }
}

export default inject('courierStore', 'merchantStore')(observer(DeliveryTimeMeta));

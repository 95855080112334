import { ReactElement } from 'react';

import { renderContainer, getCheckboxList } from 'components/checkbox-list/checkbox-list.factory';
import {
  CheckboxListProps,
  CheckboxListFactoryType
} from 'components/checkbox-list/checkbox-list.types';
import { TabChildProps } from 'components/filters/filters.types';

export const getProductsFilterCheckboxList = (
  factoryType: CheckboxListFactoryType,
  props: Pick<CheckboxListProps, 'items' | 'selectedItems' | 'onChange' | 'checkboxStyles'>
): ReactElement<TabChildProps> => {
  const defaultProps: Pick<CheckboxListProps, 'itemValueField' | 'optionTitleField' | 'metadataField' | 'orientation'> = {
    itemValueField: 'slug',
    optionTitleField: 'title',
    metadataField: 'count',
    orientation: 'horizontal'
  };

  return renderContainer(factoryType, getCheckboxList(factoryType, { ...props, ...defaultProps }));
};

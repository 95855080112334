import React, { Component, ReactNode } from 'react';

import css from '@emotion/css';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { navigate, WrapPageElementBrowserArgs } from 'gatsby';
import hex2Rgba from 'hex2rgba';
import _get from 'lodash.get';
import queryString from 'query-string';
import Modal from 'react-modal';
import { Flex, Box } from 'rebass';
import stores from 'stores';

import { Auth } from 'lib';

import MerchantService from 'utils/global-services/merchant';
import { colors, textStyles } from 'utils/rebass-theme';

import PlansIconImage from 'assets/images/plants-with-laptop.svg';

import UserLoginService from 'features/login-signup/services';
import ModalDialogue from 'features/modal-dialogue';
import ModalRouter from 'features/modal-router';
import UserService from 'features/settings/components/account/services';
import CustomSiteService from 'features/settings/components/custom-site/services/index';

import { GlobalLightbox } from 'components/global-lightbox/global-lightbox';
import Loader from 'components/loader';
import SupportChat from 'components/navigation/components/support-chat/support-chat';
import Toaster from 'components/toaster/toaster';
import { ToasterContainer } from 'components/toaster/toaster.styles';

const LoadingWrapper = styled(Box)`
  max-width: 360px;
  margin: 60px auto;
  text-align: center;
`;

const LoadingImage = styled.img`
  max-width: 250px;
`;

const LoadingSubheading = styled(Box)`
  ${textStyles.subhead};

  color: ${colors.shade60};
  margin-top: 28px;
  text-transform: uppercase;
  font-size: 10px;
`;

const LoadingHeading = styled(Box)`
  ${textStyles.headline};

  color: ${colors.floomMidnightBlue};
  margin-top: 10px;
  font-size: 29px;
`;

type WrapPageElementProps = WrapPageElementBrowserArgs['props'] & {
  element: WrapPageElementBrowserArgs['element'];
};

class WrapPageElement extends Component<WrapPageElementProps> {
  private static renderLoadingText = (): string => {
    const placeholderOptions = [
      'Arranging bouquets',
      'Potting plants',
      'Designing arrangements'
    ];

    const randomNumber = Math.floor(Math.random() * placeholderOptions.length);

    return placeholderOptions[randomNumber];
  };

  state = {
    prevProps: null,
    props: null,
    pathname: null,
    isLoading: true,
    hasMounted: false
  };

  static getDerivedStateFromProps(props: any, state: any): any {
    if (props.location.pathname !== state.pathname) {
      return {
        pathname: props.location.pathname,
        props: props,
        ...(!_get(state, 'props.location.state.modal') && {
          prevProps: state.props
        })
      };
    }

    return null;
  }

  async componentDidMount(): Promise<any> {
    this.setState({ hasMounted: true });

    await this.initialiseLoggedInUser();
    this.handleMailchimpAuth();
  }

  private static loadingText: string = WrapPageElement.renderLoadingText();

  private handleMailchimpAuth = (): void => {
    const queryParams = queryString.parse(this.props.location.search);

    if (queryParams.code && !Array.isArray(queryParams.code)) {
      CustomSiteService.confirmMailchimp(queryParams.code!);
    } else {
      stores.customSiteSettingsStore.toggleConfirmingMailchimp();
    }
  };

  private initialiseLoggedInUser = async (): Promise<any> => {
    if (Auth.isLoggedIn()) {
      await Promise.all([
        UserService.populateUserDetails(),
        UserLoginService.init(),
        MerchantService.init()
      ]);
    }

    this.setState({ isLoading: false });
  };

  private renderLoading = (): ReactNode => {
    return (
      <div>
        <LoadingWrapper>
          <Box>
            <LoadingImage src={PlansIconImage} />
          </Box>
          <LoadingSubheading>
            {`Hold tight, we're busy`}
          </LoadingSubheading>
          <LoadingHeading>
            {WrapPageElement.loadingText}...
          </LoadingHeading>
          <Flex
            justifyContent="center"
            mt="40px"
          >
            <Loader size="small" />
          </Flex>
        </LoadingWrapper>
      </div>
    );
  };

  render(): ReactNode {
    const { location } = this.props;
    const { prevProps } = this.state;
    const modalData = _get(location, 'state.modal');
    // @ts-ignore
    const previousUrl = prevProps && prevProps!.location.pathname;
    const closeUrl = location && _get(location, 'state.closeTo');
    let closeTo: string = '';

    if (modalData) {
      closeTo = closeUrl || previousUrl;
    }

    const ModalFade = css`
      .ReactModal__Overlay {
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }

      .ReactModal__Overlay--after-open {
        opacity: 1;
      }

      .ReactModal__Overlay--before-close {
        opacity: 0;
      }

      .ReactModal__Content {
        opacity: 0;
        transform: scale(0.98);
        transition: transform .5s cubic-bezier(.18,1.4,.4,1),
                    opacity .3s ease-in-out;
      }

      .ReactModal__Content--after-open {
        opacity: 1;
        transform: scale(1);
      }

      .ReactModal__Content--before-close {
        opacity: 0;
        transform: scale(0.98);
      }

      .ReactModal__Html--open, .ReactModal__Body--open {
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
      }
    `;

    const customStyles = {
      content: {
        width: '100%',
        maxWidth: '940px',
        margin: '0 auto',
        padding: '0px',
        top: '60px',
        left: '0',
        right: '0',
        bottom: '0',
        border: 'none',
        background: 'none',
        overflow: 'hidden'
      },
      overlay: {
        background: hex2Rgba(colors.floomMidnightBlue, '0.9'),
        zIndex: 100
      }
    };

    const closeModal = (): void => {
      if (closeTo) {
        navigate(closeTo, {
          state: {
            fromModal: true
          }
        });
      }
    };

    if (!this.state.hasMounted) {
      return null;
    }

    return (
      <>
        <Global
          styles={ModalFade}
        />
        { this.state.isLoading
          ? this.renderLoading()
          : this.props.element
        }
        <Modal
          style={customStyles}
          isOpen={!!modalData}
          ariaHideApp={true}
          closeTimeoutMS={300}
          onRequestClose={closeModal}
        >
          <ModalRouter
            config={{
              ...modalData,
              closeTo
            }}
          />
        </Modal>
        <Toaster />
        <ToasterContainer
          id="toaster-root"
          isModal={false}
        />
        <GlobalLightbox />
        <ModalDialogue />
        <SupportChat />
      </>
    );
  }
}

const wrapPageElement = ({ props, element }: WrapPageElementBrowserArgs): ReactNode => {
  return React.createElement(WrapPageElement, {
    ...props,
    element
  });
};

export default wrapPageElement;

import React, { Component, ReactNode } from 'react';

import { Flex } from 'rebass';

import Button from 'components/button';

import * as Types from './load-more-button.types';

export default class LoadMoreButton extends Component<Types.LoadMoreButtonProps> {
  render(): ReactNode | null {
    if (!this.props.shouldDisplay) return null;

    return(
      <Flex
        mt="40px"
        justifyContent="center"
      >
        <div onClick={(): void => this.props.onLoadMore(this.props.groupName)}>
          <Button
            copy="Load More"
            size="normal"
            appearance="primary"
            isLoading={this.props.isLoading}
          />
        </div>
      </Flex>
    );
  }
}

import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import {
  colors,
  borderRadius,
  shadows,
  breakpoints
} from 'utils/rebass-theme';

import LeftBlobSVG from 'assets/icons/home/bubble-orange.svg';
import RightBlobSVG from 'assets/icons/home/bubble-yellow-mobile.svg';

export const Container = styled(Box)`
  position: relative;
  overflow: hidden;
  padding: 30px 20px;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  background: ${colors.white};
  box-shadow: ${shadows.large};
  border-radius: ${borderRadius.large};
  
  @media screen and (min-width: 1000px) {
    padding: 30px 40px;
  }
`;

export const Content: any = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
`;

export const ContentLeft: any = styled(Box)`
  width: 100%;
  padding-right: 5px;
  
  @media screen and (min-width: 500px) {
    padding-right: 20px;
  }
`;

export const Title: any = styled(Box)`
  margin-bottom: 20px;
`;

export const List = styled.ul`
  margin-bottom: 25px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 10px;
  line-height: 1.3;
  font-weight: 800;
  font-size: 14px;

  @media screen and (min-width: 1000px) {
    font-size: 16px;
  }
`;

export const BlobLeft = styled(LeftBlobSVG)<{ fill?: string }>`
  position: absolute;
  top: 0;
  left: -100px;
  width: auto;
  height: 100%;

  path {
    fill: ${({ fill }) => fill || '#A0E8F4'};
  }

  @media screen and (min-width: 500px) {
    left: 0;
  }
`;

export const BlobRight = styled(RightBlobSVG)<{ fill?: string }>`
  position: absolute;
  top: 0;
  right: -280px;
  width: auto;
  height: 100%;

  path {
    fill: ${({ fill }) => fill || '#A0E8F4'};
  }

  @media screen and (min-width: 500px) {
    right: -180px;
  }
`;

export const ImageMobile = styled.img`
  height: auto;
  width: 110px;


  @media screen and (min-width: 500px) {
    height: 150px;
    width: auto;
  }
  
  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

export const ImageDesktop = styled.img`
  display: none;
  height: 150px;
  width: auto;
  
  @media screen and (min-width: 1000px) {
    display: block;
  }
`;

export const ButtonsWrapper = styled(Flex)`
  flex-wrap: wrap;
  
  @media screen and (max-width: 430px) {
    justify-content: center;
  }
`;

export const ButtonContainer = styled(Box)`
  min-width: 175px;

  &:first-child {
    margin-bottom: 10px;
  }
  
  @media screen and (min-width: 430px) {
    margin-right: 10px;
  }
  
  @media screen and (min-width: ${breakpoints.small}) {
    min-width: 180px;
  }
  
  @media screen and (min-width: 1000px) {
    max-width: 180px;
    min-width: 180px;
  }
`;

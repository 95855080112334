import React, { Component, ReactNode } from 'react';

import { colors } from 'utils/rebass-theme';

import FxTextArea from 'components/field-text-area';
import Icon from 'components/icon';

import * as Styles from './edit-in-place.styles';
import * as Types from './edit-in-place.types';

export default class EditInPlace extends Component<Types.EditInPlaceProps> {
  textareaRef: any = React.createRef();

  state: Types.EditInPlaceState = {
    isEditing: false,
    value: this.props.value,
    initialValue: this.props.value
  };

  componentDidMount = (): void => {
    this.setState({
      isEditing: false,
      value: this.props.value,
      initialValue: this.props.value
    });
  };

  toggleEdit = (): void => {
    this.textareaRef.current.focus();

    this.setState({
      isEditing: !this.state.isEditing
    });
  };

  onSave = (): void => {
    if (this.state.value !== this.state.initialValue) {
      this.props.onEdit(this.state.value);
    }

    this.setState({
      isEditing: false,
      initialValue: this.state.value
    });
  };

  onDiscard = (): void => {
    this.setState({
      isEditing: false,
      value: this.state.initialValue
    });
  };

  onDelete = (): void => {
    this.setState({
      value: ''
    });

    this.props.onEdit('');
  };

  changeValue = (value: string): void => {
    this.setState({
      value
    });
  };

  checkControlsPermissions = (name: string): boolean => {
    return (name === 'edit' && this.state.isEditing)
      || (name === 'save' && !this.state.isEditing)
      || (name === 'delete' && this.state.value === '');
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.keyCode === 13 && event.metaKey) {
      this.onSave();
    }
  };

  onFocus = (): void => {
    this.setState({
      isEditing: true
    });
  };

  onBlur = (): void => {
    if (this.state.value === this.state.initialValue) {
      this.setState({
        isEditing: false
      });
    }
  };

  renderCharCount = (): ReactNode => (
    <>
      <Styles.CharCount>
        <strong>
          {this.state.value.length}
          {!!this.props.maxChars &&
            ` / ${this.props.maxChars}`
          }
        </strong>
      </Styles.CharCount>
    </>
  );

  closedControls: Types.ControlsConfigItem[] = [
    {
      name: 'edit',
      icon: 'edit',
      action: this.toggleEdit
    },
    {
      name: 'save',
      icon: 'tick',
      action: this.onSave
    },
    {
      name: 'delete',
      icon: 'bin',
      action: this.onDelete
    }
  ];

  openControls: Types.ControlsConfigItem[] = [
    {
      name: 'save',
      icon: 'tick-small',
      action: this.onSave,
      bgcolor: colors.validationBg,
      color: colors.emeral
    },
    {
      name: 'discard',
      icon: 'cross-small',
      action: this.onDiscard,
      bgcolor: colors.errorBg,
      color: colors.errorText
    }
  ];

  render(): ReactNode {
    return (
      <Styles.EditBoxContainer>
        <Styles.EditBox>
          <FxTextArea
            inPlaceEdit={true}
            placeholder={this.props.placeholder!}
            value={this.state.value}
            disabled={!this.props.canEdit}
            autoFocus={this.state.isEditing}
            onKeyDown={this.onKeyDown}
            ref={this.textareaRef}
            maxLength={this.props.maxChars}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={(value): void => {
              this.changeValue(value);
            }}
          />
        </Styles.EditBox>

        {this.state.isEditing && this.renderCharCount()}

        {this.props.canEdit && (
          <Styles.EditControls>
            {
              this.state.isEditing ?
                this.openControls.map((btn, i) => {
                  return (
                    <Styles.IconButton
                      key={i}
                      disabled={this.checkControlsPermissions(btn.name)}
                      onMouseDown={btn.action}
                      css={{ backgroundColor: btn.bgcolor }}
                    >
                      <Icon
                        iconName={btn.icon}
                        pathFill={btn.color}
                      />
                    </Styles.IconButton>
                  );
                })
                :
                this.closedControls.map((btn, i) => {
                  return (
                    <Styles.IconButton
                      key={i}
                      disabled={this.checkControlsPermissions(btn.name)}
                      onClick={btn.action}
                    >
                      <Icon
                        iconName={btn.icon}
                      />
                    </Styles.IconButton>
                  );
                })
            }
          </Styles.EditControls>
        )}
      </Styles.EditBoxContainer>
    );
  }
}

import React, { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Text, Box, Flex } from 'rebass';

import { CurrencyService } from 'lib';

import { textStyles, colors } from 'utils/rebass-theme';

import CourierListService from 'features/courier-bookings/services/courier-list.service';
import { buildPickupDay, buildPickupTime } from 'features/courier/components/courier-status-indicator/courier-status-indicator.helpers';
import OrderHelpers from 'features/orders/helpers/order-helpers';
import CourierAPIService from 'features/orders/services/courier-api/courier-api.service';

import Button from 'components/button';
import GenericModal from 'components/generic-modal';
import Icon from 'components/icon';

import * as SharedStyles from '../courier-status-modal/courier-status-modal.styles';

import * as Styles from './courier-cancel-confirmation-modal.styles';
import * as Types from './courier-cancel-confirmation-modal.types';

class CourierCancelConfirmationModal extends Component<Types.CourierCancelConfirmationModalProps, Types.CourierCancelConfirmationModalState> {
  state: Readonly<Types.CourierCancelConfirmationModalState> = {
    requestStatus: 'NONE'
  };

  private returnToBookings = (): void => {
    this.props.closeModal();
  };

  private cancelBooking = async (): Promise<any> => {
    const { bookingId } = this.props.data;

    this.setState({ requestStatus: 'LOADING' });

    await CourierAPIService.cancelCourierBooking(bookingId);

    CourierListService.fetchCourierBookings();

    this.setState({ requestStatus: 'SUCCESS' });
  };

  private renderConfirmation(): ReactNode {
    const { cancellationFee } = this.props.data;

    return (
      <>
        <Styles.ModalHeader>
          <Text css={SharedStyles.Title}>
            Cancel courier booking?
          </Text>
        </Styles.ModalHeader>

        <Styles.ModalBody>
          {
            cancellationFee !== 0 ? (
              <Text>
              A cancellation fee of
                {' '}
                <span style={{ textDecoration: 'underline', fontWeight: 700 }}>
                  {CurrencyService.formatPrice(cancellationFee, this.props.data.currency)}
                </span>
                {' '}
              will apply as we have already matched your booking with the courier.
              </Text>
            ) : (
              <Text>
              You won&apos;t get charged for the cancellation, as we haven&apos;t matched your booking with a courier.
              </Text>
            )}
        </Styles.ModalBody>

        <Styles.ModalFooter>
          <Flex>
            <div onClick={this.props.closeModal}>
              <Button
                size="normal"
                appearance="secondary"
                isParentWidth={false}
                copy="No"
                isDisabled={this.state.requestStatus === 'LOADING'}
              />
            </div>

            <div style={{ marginRight: 10 }} />

            <div onClick={this.cancelBooking}>
              <Button
                size="normal"
                appearance="primary"
                isParentWidth={false}
                copy="Yes, cancel"
                isLoading={this.state.requestStatus === 'LOADING'}
              />
            </div>
          </Flex>
        </Styles.ModalFooter>
      </>
    );
  }

  private renderSuccess(): ReactNode {
    return (
      <>
        <Styles.ModalHeader>
          <Text css={SharedStyles.Title}>
            FloomX Courier
          </Text>
          <Flex
            justifyContent="center"
            alignItems="baseline"
            mb="30px"
          >
            <Text
              css={css`${textStyles.label}; text-transform: uppercase; color: ${colors.shade40}`}
              mr="5px"
            >
              Courier ref
            </Text>
            <Text css={textStyles.subhead}>
              {OrderHelpers.shortenOrderNumber(this.props.data.courierRef)}
            </Text>
          </Flex>
          <Flex justifyContent="center">
            <Box mr="10px">
              <Icon
                iconName="cross-circle"
                pathFill={colors.floomRed}
              />
            </Box>
            <Box>
              <Text css={SharedStyles.Title}>
                Cancelled
              </Text>
            </Box>
          </Flex>
        </Styles.ModalHeader>
        <Styles.ModalBody>
          <Styles.CourierDetailsBox>
            <Styles.CourierDetailsBoxHeader>
              Pickup
            </Styles.CourierDetailsBoxHeader>
            <Styles.CourierDetailsBoxContent>
              {buildPickupDay(this.props.data.pickupDate)}, {buildPickupTime(this.props.data.pickupDate)}
            </Styles.CourierDetailsBoxContent>
          </Styles.CourierDetailsBox>
          <Styles.CourierDetailsBox>
            <Styles.CourierDetailsBoxHeader>
              Dropoff
            </Styles.CourierDetailsBoxHeader>
            <Styles.CourierDetailsBoxContent>
              {buildPickupDay(this.props.data.deliveryDate)}, {buildPickupTime(this.props.data.deliveryDate)}
            </Styles.CourierDetailsBoxContent>
          </Styles.CourierDetailsBox>
        </Styles.ModalBody>

        <Styles.ModalSuccessConfirmation onClick={this.returnToBookings}>
          <Button
            size="normal"
            appearance="primary"
            isParentWidth={false}
            copy="Return to courier bookings"
          />
        </Styles.ModalSuccessConfirmation>
      </>
    );
  }

  private renderSwitch(): ReactNode {
    switch (this.state.requestStatus) {
      case 'NONE':
        return this.renderConfirmation();

      case 'LOADING':
        return this.renderConfirmation();

      case 'SUCCESS':
        return this.renderSuccess();
    }
  }

  render(): ReactNode {
    return (
      <GenericModal
        title="Cancel courier booking?"
        shouldHideFooter={true}
        shouldHideHeader={true}
        closeModal={this.props.closeModal}
        modalOpen={this.props.isOpen}
        innerComponent={(
          <SharedStyles.Container>
            {
              this.renderSwitch()
            }
          </SharedStyles.Container>
        )}
      />
    );
  }
}

export default CourierCancelConfirmationModal;

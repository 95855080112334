import { FC, useEffect, useMemo } from 'react';

import hex2rgba from 'hex2rgba';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Analytics } from 'lib';

import { Container } from 'utils/css-mixins';
import { colors } from 'utils/rebass-theme';

import { ListsBlob } from 'features/lists/lists.styles';
import { WholesaleCheckoutHelpers } from 'features/wholesale/helpers/wholesale-helpers';
import { getPaymentStatusConfig } from 'features/wholesale/helpers/wholesale-helpers/wholesale-helpers';

import { LoadingImage } from 'components/loading-image/loading-image';
import WithLoading from 'components/with-loading';

import PlantsIconImage from '../../../../assets/images/wholesale/no-results.gif';
import WholesaleServices from '../../services';

import { CannotFindOrder } from './components/cannot-find-order';
import { SingleOrderBody } from './components/single-order-body';
import { SingleOrderFooter } from './components/single-order-footer';
import { SingleOrderHeader } from './components/single-order-header';
import { SingleOrderContext } from './single-order.context';
import { SingleWholesaleOrderProps } from './single-order.types';

export const SingleWholesaleOrder: FC<SingleWholesaleOrderProps> = inject((stores: FxStores) => ({
  wholesaleOrdersStore: stores.wholesaleOrdersStore,
  modalStore: stores.modalStore,
  toasterStore: stores.toasterStore
}))(observer(({
  orderId,
  wholesaleOrdersStore
}) => {
  useEffect(() => {
    fetchOrder();
    Analytics.page(Analytics.FxPageEvent.WholesaleOrdersDetail);

    return (): void => {
      wholesaleOrdersStore!.updateSingleOrder(wholesaleOrdersStore!.selectedOrder);
      wholesaleOrdersStore!.resetSelectedOrder();
    };
  }, []);

  /**
   * Calculate values for single order view context provider
   */
  const merchantCurrency = wholesaleOrdersStore!.selectedOrder?.merchant?.currency;
  const grandTotalAsFloat = useMemo(() => WholesaleCheckoutHelpers.calculateGrandTotal(wholesaleOrdersStore!.selectedOrder), [wholesaleOrdersStore!.selectedOrder]);
  const grandTotalCurrencyFormatted = useMemo(() => WholesaleCheckoutHelpers.renderWholesalePrice(grandTotalAsFloat, merchantCurrency), [grandTotalAsFloat]);
  const paymentStatusConfig = getPaymentStatusConfig(wholesaleOrdersStore!.selectedOrder);

  const fetchOrder = async (): Promise<void> => {
    if (orderId) {
      WholesaleServices.WholesaleOrdersAPIService.fetchOrder(orderId);
    }
  };

  const shouldDisplayOrder = (): boolean => {
    return !wholesaleOrdersStore!.isLoadingOrderDetail
      && !!wholesaleOrdersStore!.selectedOrder
      && Array.isArray(wholesaleOrdersStore!.selectedOrder?.items) && !!wholesaleOrdersStore!.selectedOrder.items.length;
  };

  return (
    <SingleOrderContext.Provider
      value={{
        grandTotalAsFloat,
        grandTotalCurrencyFormatted,
        paymentStatusConfig,
        fetchOrder
      }}
    >
      <WithLoading
        loaderSize="small"
        marginTop="30px"
        isLoading={wholesaleOrdersStore!.isLoadingOrderDetail}
        hasNoResults={!shouldDisplayOrder()}
        renderNoResults={CannotFindOrder}
        renderLoading={() => (
          <LoadingImage
            text="Loading order..."
            imageSrc={PlantsIconImage}
            imageAlt="plants"
          />
        )}
      >
        <ListsBlob
          fill={hex2rgba(colors.middleGrey, .2)}
        />
        <Container>
          <Box mb="60px">
            <SingleOrderHeader />
            <SingleOrderBody />
            <SingleOrderFooter />
          </Box>
        </Container>
      </WithLoading>
    </SingleOrderContext.Provider>
  );
}));

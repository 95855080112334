import gql from 'graphql-tag';

import {
  PRODUCT_CONNECTION_FRAGMENT,
  PRODUCT_FULL_FRAGMENT
} from '../fragments/product';

export const SINGLE_PRODUCT_QUERY = gql`
  query Product(
    $id: ID!
    $merchantId: ID!
  ) {
      product(
        where: {
          id: $id
          merchant: {
            id: $merchantId
          }
        }
      ) {
        ...ProductFull
      }
  }
  ${PRODUCT_FULL_FRAGMENT}
`;

export const PRODUCT_TYPES_QUERY = gql`
  query ProductTypes(
    $active: Boolean!
  ) {
    productTypes(
      where: {
        active: $active
      }
    ) {
      id
      title
      slug
      active
    }
  }
`;

export const PRODUCT_CATEGORIES_QUERY = gql`
  query ProductCategories(
    $active: Boolean!
  ) {
    productCategories(
      where: {
        active: $active
      }
    ) {
      id
      title
      slug
      active
    }
  }
`;

export const PRODUCTS_CONNECTION_QUERY = (merchantId?: string): any => gql`
  query ProductConnection(
    $first: Int,
    $skip: Int,
    $where: ProductWhereInput!,
    $orderBy: ProductOrderByInput!
  ) {
    productsConnection(
      first: $first,
      skip: $skip,
      where: $where,
      orderBy: $orderBy
    ) {
      ...ProductConnectionFields
    }
  }
  ${PRODUCT_CONNECTION_FRAGMENT(merchantId)}
`;

export const PRODUCT_COUNT_QUERY = gql`
  query ProductCountQuery(
    $where: ProductWhereInput!,
  ) {
    productsConnection(
      where: $where
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const TOTAL_ACTIVE_MERCHANT_PRODUCTS = gql`
  query TotalActiveMerchantProducts(
    $merchantId: ID!
  ) {
      productsConnection(
        where: {
          active: true,
          deletedAt: null,
          merchant: {
            id: $merchantId
          }
        }
      ) {
        aggregate {
          count
        }
      }
  }
`;

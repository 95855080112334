import React, { Component, ReactNode } from 'react';

import { DeliveryConfig } from 'generated-types.d';

import { textStyles } from 'utils/rebass-theme';

import ItemMetaWrapper from 'components/meta-card/content-wrapper';

interface SameDayWeekdaysProps {
  data: DeliveryConfig;
}

class SameDayWeekdays extends Component<SameDayWeekdaysProps> {
  areSameDayWeekdaysEnabled = (): boolean => {
    const { sameDays } = this.props.data.deliveryTiming;

    return sameDays[0]
      || sameDays[1]
      || sameDays[2]
      || sameDays[3]
      || sameDays[4];
  };

  render(): ReactNode {
    return (
      <ItemMetaWrapper
        iconName="website"
        hasActivityToggle={true}
        isActivityToggleActive={this.areSameDayWeekdaysEnabled()}
        css={textStyles.body}
      >
        <span css={textStyles.body}>
          SD weekdays
        </span>
      </ItemMetaWrapper>
    );
  }
}

export default SameDayWeekdays;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

import { ColourCheckbox } from 'components/checkbox-icon/colour/checkbox-colour-icon.styles';

export const List = styled(Flex)`
  align-items: center;
  width: 100%;
`;

export const Checkbox = css`
  margin: 0;
  padding: 5px;
  border-radius: 3px;

  ${ColourCheckbox} {
    width: 20px;
    height: 20px;
    flex-basis: 20px;
    margin: 0;
  }

  &:last-of-type {
    margin: 0;
  }
`;

export const CheckboxSelected = css`
  ${Checkbox}

  background: ${colors.paleGrey};
`;

export const Label = css`
  display: flex;
  align-items: center;
  margin-left: 3px;
  font-size: 12px;
`;

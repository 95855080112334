import { CSSObject } from '@emotion/react';
import { NamedProps } from 'react-select';

import { breakpoints, colors, space } from 'utils/rebass-theme';

export const selectStyles = ({
  menuStyles
}: {
  menuStyles: CSSObject;
}): NonNullable<NamedProps['styles']> => ({
  control: (existingStyles, { menuIsOpen }) => ({
    ...existingStyles,
    'boxShadow': 'none',
    'background': colors.paleGrey,
    'height': 42,
    'border': `2px solid ${menuIsOpen ? colors.middleGrey : 'transparent'}`,
    ':hover': {
      borderColor: menuIsOpen ? colors.middleGrey : 'transparent'
    },
    [`@media only screen and (max-width: ${breakpoints.medium})`]: {
      height: 32,
      minHeight: 0,
      flexWrap: 'nowrap',
      width: 'auto'
    }
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: `${space[3]}px`,
    [`@media only screen and (max-width: ${breakpoints.medium})`]: {
      padding: `${space[1]}px`,
      minWidth: 30
    }
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: '14px'
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      'display': 'flex',
      'alignItems': 'center',
      'position': 'relative',
      'whiteSpace': 'nowrap',
      'textOverflow': 'ellipsis',
      'width': '100%',
      'overflow': 'hidden',
      'backgroundColor': isFocused ? colors.lightGrey : colors.white,
      'color': colors.floomMidnightBlue,
      'fontSize': '12px',
      'padding': '14px 15px 16px 15px',
      ':active': {
        backgroundColor: colors.paleGrey
      },
      '+ *': {
        borderTop: `1px solid ${colors.middleGrey}`
      }
    };
  },
  menuList: styles => {
    return {
      ...styles,
      padding: 0
    };
  },
  menu: styles => {
    return {
      ...styles,
      border: '1px solid #B5B5BE',
      borderRadius: '4px',
      boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      marginTop: 2,
      width: 'auto',
      ...menuStyles
    };
  },
  input: styles => {
    return {
      ...styles,
      outline: 'none'
    };
  },
  singleValue: styles => {
    return {
      ...styles,
      fontSize: '12px',
      color: colors.floomMidnightBlue
    };
  },
  indicatorsContainer: styles => ({
    ...styles,
    '> div': {
      padding: `10px`,
      [`@media only screen and (max-width: ${breakpoints.medium})`]: {
        padding: `${space[2]}px`
      }
    }
  })
});

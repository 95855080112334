import React from 'react';

import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { TEST_IDS } from 'utils/test/data-test-ids';

import IconButton from 'components/icon-button';

import Logo from '../logo';

import * as Styles from './nav-header.styles';
import { NavHeaderProps } from './nav-header.types';

class NavHeader extends React.Component<NavHeaderProps> {
  render(): React.ReactNode {
    const isCollapsed = this.props.navigationStore!.isCollapsed;

    return (
      <Box bg="floomMidnightBlue">
        <Styles.Wrapper isCollapsed={isCollapsed}>
          <Styles.LogoWrapper isVisible={!isCollapsed}>
            <Link
              to={NavPages.Overview}
              aria-label="navigation home"
              data-testid={TEST_IDS.Navigation.desktop.homeLink}
            >
              <Logo />
            </Link>
          </Styles.LogoWrapper>
          <Styles.CollapseIconWrapper>
            <IconButton
              iconName="menu"
              styles={Styles.CloseIcon}
              onClick={this.props.navigationStore!.toggleLayout}
              testId={TEST_IDS.Navigation.desktop.hamburgerMenu}
            />
          </Styles.CollapseIconWrapper>
          <Styles.CloseIconWrapper>
            <IconButton
              iconName="cross-medium"
              styles={Styles.CloseIcon}
              onClick={this.props.onClickCloseIcon}
              testId={`${TEST_IDS.Navigation.desktop.hamburgerMenu}-close`}
            />
          </Styles.CloseIconWrapper>
        </Styles.Wrapper>
      </Box>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  navigationStore: stores.navigationStore,
  uiStore: stores.uiStore
}))(observer(NavHeader));

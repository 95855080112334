import {
  FC,
  Fragment,
  useState
} from 'react';

import { Box, Flex } from 'rebass';

import Icon from 'components/icon';

import {
  AccordionHeading,
  AccordionSelectedRefinements
} from './catalog-accordion-heading.styles';

interface CatalogAccordionHeadingProps {
  name: string;
  isInitiallyOpen: boolean;
  refinementCount?: number;
  shouldDisplayCount?: boolean;
}

export const CatalogAccordionHeading: FC<CatalogAccordionHeadingProps> = ({
  name,
  isInitiallyOpen,
  refinementCount = 0,
  shouldDisplayCount = false,
  children
}) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);

  return (
    <Fragment>
      <AccordionHeading
        as="button"
        onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}
      >
        <Box>
          {name}
        </Box>
        <Flex alignItems="center">
          {(!!shouldDisplayCount && !!refinementCount) && (
            <AccordionSelectedRefinements>
              {refinementCount < 100 ? refinementCount : '∞'}
            </AccordionSelectedRefinements>
          )}
          <Icon
            iconName={isOpen ? 'arrow-up' : 'arrow-down'}
          />
        </Flex>
      </AccordionHeading>
      {isOpen && children}
    </Fragment>
  );
};

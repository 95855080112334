import { css, SerializedStyles } from '@emotion/react';

export const SidebarStyles = (prefix: string = ''): SerializedStyles => css`
  .${prefix}sidebar__ContentSlide {
    position: absolute;
    transform: translateX(20px);
    transition: transform .15s ease-in-out;
  }

  .${prefix}sidebar__ContentSlide--after-open {
    transform: translateX(0);
  }

  .${prefix}sidebar__ContentSlide--before-close {
    transform: translateX(20px);
  }

  .${prefix}sidebar__OverlaySlide {
    opacity: 0;
    transition: opacity .3s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .${prefix}sidebar__OverlaySlide--after-open {
    opacity: 1;
  }

  .${prefix}sidebar__OverlaySlide--before-close {
    opacity: 0;
  }
`;

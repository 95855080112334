import styled from '@emotion/styled';

import { colors, textStyles, breakpoints } from 'utils/rebass-theme';

export const ErrorMessage = styled.p`
  ${textStyles.label}
  color: ${colors.errorText};
  padding-top: 8px;
`;

export const ErrorMessageWrapper = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.medium}) {
    position: absolute;
  }
`;

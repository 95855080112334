import { ReactElement, createContext, useEffect, useState } from 'react';

import Pusher from 'pusher-js';
import store from 'stores';

import { PermissionsService } from 'lib';

import MerchantStore from 'stores/merchant/merchant-store';

const merchantStore = store.merchantStore as MerchantStore;

const PusherContext = createContext<Pusher | null>(null);

const PusherProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [pusherInstance, setPusherInstance] = useState<Pusher | null>(null);

  useEffect(() => {
    if (!merchantStore.merchant?.promotedActive || PermissionsService.isSuperAdmin()) return;

    const pusher = new Pusher(process.env.PUSHER_APP_KEY!, {
      cluster: process.env.PUSHER_CLUSTER!
    });

    setPusherInstance(pusher);

    return () => {
      if (!merchantStore.merchant?.promotedActive) return;

      pusher.disconnect();
    };
  }, []);

  return (
    <PusherContext.Provider value={pusherInstance}>
      {children}
    </PusherContext.Provider>
  );
};

export { PusherContext, PusherProvider };

import React from 'react';

import { inject, observer } from 'mobx-react';

import { NavService, Analytics } from 'lib';

import TableLayout from 'features/add-ons/components/table-layout/table-layout';
import AddOnsServices from 'features/add-ons/services';

import Button from 'components/button';
import PageHeading from 'components/page-heading';

class AddOnList extends React.Component<any> {
  private AddOnsListService = AddOnsServices.AddOnsListService;

  componentDidMount(): void {
    this.AddOnsListService.init();
    Analytics.page(Analytics.FxPageEvent.AddonsList);
  }

  private createNewAddOn = (): void => {
    if (this.props.userStore.merchantId) {
      NavService.addOnCreate(this.props.addOnEditStore!.id!);
    } else {
      this.props.addOnEditStore!.isNoMerchantModalOpen = true;
    }
  };

  private renderListComponent = (): React.ReactNode => {
    switch (this.props.addOnsStore!.layoutOption) {
      case 'grid':

      case 'table':
        return TableLayout;
    }
  };

  render(): React.ReactNode {
    const ListComponent: any = this.renderListComponent();

    return (
      <>
        <PageHeading titleText="Add-ons">
          <div onClick={this.createNewAddOn}>
            <Button
              copy="Create Add-on"
              size="small"
              appearance="primary"
            />
          </div>
        </PageHeading>
        {/* We are disabling the filter controls until add-ons hit a capacity that requires filtering */}
        {/* <FilterControls /> */}
        {/* will always be TableLayout for now */}
        <ListComponent
          items={this.props.addOnsStore!.addOns}
          isLoading={this.props.addOnsStore!.isLoading}
        />
      </>
    );
  }
}

export default inject('addOnsStore', 'addOnEditStore', 'userStore')(observer(AddOnList));

import { colors } from 'utils/rebass-theme';

import * as Styles from './progress-bar.styles';
import * as Types from './progress-bar.types';

const ProgressBar = (props: Types.ProgressBarProps): JSX.Element => {
  return (
    <Styles.ProgressBar
      width={props.width}
      size={props.size}
      backgroundColour={props.backgroundColour}
      barColour={props.barColour}
    />
  );
};

ProgressBar.defaultProps = {
  backgroundColour: colors.floomCyan,
  barColour: colors.floomMidnightBlue,
  size: 'small'
} as Pick<Types.ProgressBarProps, 'backgroundColour' | 'barColour' | 'size'>;

export default ProgressBar;

import React, { Component, ReactNode } from 'react';

import moment from 'moment';
import { Box } from 'rebass';

import Icon from 'components/icon';
import StyledLink from 'components/styled-link';

import * as Styles from './payment-details-card.styles';
import * as Types from './payment-details-card.types';

export default class PaymentDetailsCard extends Component<Types.PaymentDetailsCardProps> {
  static defaultProps = {
    onClick: null,
    fullWidth: false
  };

  private renderExpiryDate = (): string => {
    if (!this.props.paymentMethod?.expiryYear) return '...';

    return moment()
      .set('year', this.props.paymentMethod.expiryYear)
      .set('month', this.props.paymentMethod!.expiryMonth! - 1)
      .format('MMM YYYY');
  };

  render(): ReactNode {
    return(
      <>
        <Styles.PaymentMethodWrapper
          id="payment-details-card"
          as={!!this.props.onClick ? 'a' : 'div'}
          isButton={!!this.props.onClick}
          onClick={(e: any): any => this.props.onClick && this.props.onClick(e)}
          fullWidth={this.props.fullWidth}
        >
          <Styles.PaymentMethodDetails fullWidth={this.props.fullWidth}>
            <Box>
              <Icon iconName="card" />
            </Box>
            <Box pl="15px">
              <Styles.PaymentMethodType>
                {this.props.paymentMethod?.brand || this.props.paymentMethod?.type}
              </Styles.PaymentMethodType>
              <Styles.PaymentMethodCardNo>
                xxxx xxxx xxxx {this.props.paymentMethod?.lastFourCardDigits || 'xxxx'}
              </Styles.PaymentMethodCardNo>
              { !!this.props.paymentMethod?.expiryYear && (
                <Styles.PaymentMethodExpiry
                  isExpiring={this.props.paymentMethod?.isExpiring}
                >
                  {this.props.paymentMethod?.isExpiring ? 'Expiring' : 'Expires'} {this.renderExpiryDate()}
                </Styles.PaymentMethodExpiry>
              )}
            </Box>
            { !!this.props.onClick && (
              <Box
                ml="60px"
                mb="5px"
              >
                <StyledLink>
                  Edit
                </StyledLink>
              </Box>
            )}
          </Styles.PaymentMethodDetails>
          { !!this.props.children && (
            <Styles.PaymentMethodBody>
              {this.props.children}
            </Styles.PaymentMethodBody>
          )}
        </Styles.PaymentMethodWrapper>
      </>
    );
  }
}

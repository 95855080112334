import { gql } from '@apollo/client';
import moment from 'moment';

import {
  Notification
} from 'generated-types.d';

import { GraphQL } from 'lib';

export const NOTIFICATIONS_QUERY = gql`
  query Notifications(
    $merchantId: ID!
    $lastWeek: DateTime!
  ) {
    notifications(
      where: {
        createdAt_gt: $lastWeek
        merchant: {
          id: $merchantId
        }
      }
      orderBy: createdAt_DESC
    ) {
      id
      title
      body
      type
      link
      createdAt
      readAt
    }
  }
`;

export const getNotifications = async (setNotications: any, merchantId?: string): Promise<Notification[]> => {
  const notificationsQueryResult = await GraphQL.query<{ notifications: Notification[] }>(
    NOTIFICATIONS_QUERY,
    {
      merchantId: merchantId,
      lastWeek: moment().subtract(7, 'days').format('YYYY-MM-DD')
    }
  );

  setNotications(notificationsQueryResult.data.notifications);

  return notificationsQueryResult.data.notifications;
};

import styled from '@emotion/styled';

import { colors, space, textStyles } from 'utils/rebass-theme';

export const ListContainer = styled.ul`
  list-style-type: none; 
  margin: ${space[4]}px;
`;

export const ListItem = styled.li`
  padding-bottom: ${space[3]}px;
  margin-bottom: ${space[3]}px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.middleGrey};
  }

  &:hover > h4 {
    background-size: 0% 1px;
  }
`;

export const ListItemHeader = styled.h4`
  ${textStyles.h5};
  display: inline-flex;
  background-image: linear-gradient(${colors.floomMidnightBlue}, ${colors.floomMidnightBlue});
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  transition: background-size .3s, opacity .2s ease-in-out;
  margin-bottom: ${space[2]}px;
`;

export const ListItemsubHeader = styled.p`
  ${textStyles.body};
`;

import { gql } from '@apollo/client';

import { GraphQLHelpers, ProductFragments } from 'lib';

import { ProductGroupOption } from 'stores/products-store';

import { STOCK_GROUPS } from 'features/products/products.constants';

import * as Helpers from '../helpers/product-query.helpers';

export const PAGINATE_PRODUCTS = (vars: any, groupName: any, currentGroup: ProductGroupOption): any => {
  return gql`
    query LoadMoreProducts(${Helpers.addRootQueryParams(vars)}) {
      productsConnection(
        ${Helpers.addRootArguments(vars)}
        skip: ${vars.skip[groupName]}
        where: {
          ${Helpers.addRootWhereArguments(vars)}
          ${Helpers.addCategoryWhereFilter(vars.selectedCategories)}
          ${Helpers.addChannelWhereFilter(vars.selectedChannels)}
          ${Helpers.addTypeWhereFilter(vars.selectedTypes)}
          ${Helpers.buildGroupFilters(groupName, currentGroup, vars)}
          ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
        }
      ) {
        ...ProductConnectionFields
      }
    }
    ${ProductFragments.PRODUCT_CONNECTION_FRAGMENT(vars.merchantId)}
  `;
};

export const ALL_PRODUCTS = (vars: any): any  => {
  return gql`
    query ProductsQuery(${Helpers.addRootQueryParams(vars)}) {
      allProducts: productsConnection(
        ${Helpers.addRootArguments(vars)}
        where: {
          deletedAt: null
          ${Helpers.addRootWhereArguments(vars)}
          ${Helpers.addTypeWhereFilter(vars.selectedTypes)}
          ${Helpers.addCategoryWhereFilter(vars.selectedCategories)}
          ${Helpers.addChannelWhereFilter(vars.selectedChannels)}
          ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
          ${GraphQLHelpers.searchableContains(vars.search)}
        }
      ) {
        pageInfo {
          hasNextPage
        }
        aggregate {
          count
        }
        edges {
          node {
            ...ProductListFragment
            collectionSellerProductConfigs(
              first: 1
              where: {
                ${GraphQLHelpers.addMerchantWhereFilter(vars.merchantId)}
              }
            ) {
              id
              active
              productVariationConfigs {
                id
                stock
                sourceProductVariation {
                  id
                  type
                }
              }
            }
          }
        }
      }
    }
    ${ProductFragments.PRODUCT_LIST_FRAGMENT}
  `;
};

export const PRODUCTS_BY_STOCK = (vars: any): any => {
  return gql`
    query ProductStockGroupQuery(
      ${Helpers.addRootQueryParams(vars)}
    ) {
      ${Helpers.buildProductGroupQuery('stock', STOCK_GROUPS, vars)}
    }
    ${ProductFragments.PRODUCT_LIST_FRAGMENT}
  `;
};

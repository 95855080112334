import styled from '@emotion/styled';
import { Link, GatsbyLinkProps } from 'gatsby';
import { Flex, Box } from 'rebass';

import { Card } from 'utils/css-mixins/css-mixins';
import { colors, textStyles, space } from 'utils/rebass-theme';

export const GridItem = styled.div`
  ${Card};
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
`;

export const Heading = styled(Link)<GatsbyLinkProps<any>>`
  padding: 18px 10px;
  display: block;
`;

export const TitleWrapper: any = styled(Box)`
  overflow: hidden;
`;

export const ProductTitle = styled('h3')`
  ${textStyles.title};
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
`;

export const ProductType = styled('h4')`
  ${textStyles.subhead};
  margin-bottom: 0;
`;

export const ItemTags = styled(Box)`
  position: absolute;
  z-index: 1;
  top: 0;
`;

export const ItemBody = styled.div`
  position: relative;
  padding-top: 100%;
  background: ${colors.background};
`;

export const ItemFooter: any = styled(Flex)`
  ${textStyles.label};
  text-transform: uppercase;
  padding: 12px 20px;
`;

export const VariationMeta: any = styled(Box)`
  color: ${(props: any): any => props.color};

  & + ${(): any => VariationMeta} {
    margin-left: ${space[2]}px;
  }
`;

export const Edit: any = styled(VariationMeta)`
  margin-left: auto;
`;

export const ActivityDot: any = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: green;
  display: block;
  margin: 7px 10px 0 0;
  background: ${(props: any): any => props.status ? colors.validationText : colors.floomRed};
`;

import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import { ActivityToggle } from 'utils/css-mixins/css-mixins';

interface CompletionItemProps {
  isComplete: () => boolean;
  copy?: () => ReactNode;
}

export const CompletionItem = observer((props: CompletionItemProps): React.FunctionComponentElement<CompletionItemProps> | null => {
  return (
    <Flex mb="20px">
      <Flex
        alignItems="center"
        mr="20px"
      >
        <ActivityToggle
          isActive={props.isComplete()}
          css={{
            display: 'block'
          }}
        />
      </Flex>
      <Box>
        {props.copy?.()}
      </Box>
    </Flex>
  );
});

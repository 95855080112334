import React from 'react';

import { StringFormat } from 'lib';

import * as Styles from './profile-icon.styles';
import { ProfileIconProps } from './profile-icon.types';

export const ProfileIcon: React.FC<ProfileIconProps> = ({
  givenName,
  familyName
}) => {
  const initials = StringFormat.createInitials(givenName, familyName);

  return (
    <Styles.ProfileIcon
      pt={2}
    >
      {initials}
    </Styles.ProfileIcon>
  );
};

import { Component, ChangeEvent, ReactNode, Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { PlanFeature } from 'generated-types.d';

import {
  CurrencyService,
  MerchantService,
  PermissionsService,
  ProductService
} from 'lib';

import {
  colors,
  textStyles
} from 'utils/rebass-theme';

import Button from 'components/button';
import CheckboxToggle from 'components/checkbox-toggle';
import FieldText from 'components/field-text';
import Icon from 'components/icon';

import * as Styles from './quick-edit.styles';
import * as Types from './quick-edit.types';

class QuickEdit extends Component<Types.QuickEditProps, Types.QuickEditState> {
  private setInitialState = (): Types.QuickEditState => {
    return {
      priceState: this.props.data.price,
      stockState: this.props.stockAmount
    };
  };

  state = this.setInitialState();

  private onValueChange = (event: ChangeEvent<HTMLInputElement>, key: Types.FieldKey): void => {
    const { value } = event.target;

    // @ts-ignore
    this.setState({
      [key]: value.length ? parseFloat(event.target.value) : value
    });
  };

  private updateVariation = (): void => {
    const { priceState, stockState } = this.state;

    if (this.hasChanged()) {
      this.props.onSave({
        price: this.generateValue(priceState),
        stock: this.generateValue(stockState)
      });
    }
  };

  private generateValue = (value: any): number => !this.isString(value) ? value : 0;

  private isString = (value: any): boolean => typeof value === 'string' || value instanceof String;

  private hasChanged = (): boolean => {
    const { data, stockAmount } = this.props;
    const { stockState, priceState } = this.state;

    return stockAmount !== stockState || data.price !== priceState;
  };

  private isValid = (): boolean => {
    const { stockState, priceState } = this.state;

    return stockState >= 0 && priceState >= 0;
  };

  private close = (): void => {
    this.props.toggle();

    this.setState(this.setInitialState);
  };

  private isSaveDisabled = (): boolean => {
    return ProductService.isTemplateProductNotEditable(this.props.product)
      || this.props.isActiveToggling
      || !this.hasChanged()
      || !this.isValid();
  };

  private renderButtonCopy = (): string => {
    switch (true) {
      case ProductService.isTemplateProductNotEditable(this.props.product):
        return 'Select a merchant';

      default:
        return 'Save';
    }
  };

  private canActivateProduct = (): boolean => {
    const hasFloom = MerchantService.hasPlanFeature(PlanFeature.Floom, this.props.product.merchant);
    const hasWebsite = MerchantService.hasPlanFeature(PlanFeature.Website, this.props.product.merchant);
    const isActiveState = MerchantService.isActiveMerchantStage(this.props.product.merchant);

    return (hasFloom || hasWebsite) && isActiveState;
  }

  render(): JSX.Element | null {
    if (!this.props.isVisible) return null;

    const isTemplateProductNotEditable = ProductService.isTemplateProductNotEditable(this.props.product);
    const isTemplateProduct = ProductService.isTemplateProduct(this.props.product);
    const isInternalRole = PermissionsService.isInternalRole();
    const merchant = this.props.merchantStore!.merchant;

    const renderInternalNotification = (): ReactNode => {
      if (!isInternalRole || (isInternalRole && !isTemplateProduct)) return null;

      return (
        <Styles.QuickEditNotification>
          { !!merchant
            ? <Fragment>Note: You are editing the collection product settings owned by <br />{merchant?.title || ''}</Fragment>
            : <Fragment>Please select a merchant to be able to edit their settings for this collection product</Fragment>
          }
        </Styles.QuickEditNotification>
      );
    };

    return (
      <Styles.QuickEdit>
        {renderInternalNotification()}
        <Styles.QuickEditHeader>
          <Flex alignItems="center">
            <Box
              css={textStyles.h4}
              mr="10px"
            >
              Active?
            </Box>
            <CheckboxToggle
              onChange={this.props.onActiveToggle}
              switchBgActive={colors.validationText}
              switchBgInactive={colors.errorText}
              activeCopy="On"
              inactiveCopy="Off"
              controlValue={true}
              isDisabled={this.props.isActiveToggling || isTemplateProductNotEditable || !this.canActivateProduct()}
              isChecked={ProductService.isProductActive(this.props.product, this.props.merchantStore!.merchant || undefined)}
            />
          </Flex>
          <button onClick={this.close}>
            <Icon iconName="cross-medium" />
          </button>
        </Styles.QuickEditHeader>
        <Flex>
          <Box flex="1">
            <Box
              css={textStyles.subhead}
              mb="6px"
            >Stock
            </Box>
            <FieldText
              min={0}
              type="number"
              value={this.state.stockState}
              onChange={(event): void => this.onValueChange(event, 'stockState')}
              isDisabled={isTemplateProductNotEditable}
            />
          </Box>
          { !ProductService.isTemplateProduct(this.props.product) && (
            <Box
              pl="20px"
              flex="1"
            >
              <Box
                css={textStyles.subhead}
                mb="6px"
              >Price
              </Box>
              <FieldText
                min={0}
                type="number"
                value={this.state.priceState}
                prefix={CurrencyService.renderCurrencySymbol(this.props.currency)}
                onChange={(event): void => this.onValueChange(event, 'priceState')}
                isDisabled={isTemplateProductNotEditable}
              />
            </Box>
          )}
        </Flex>
        <Box
          mt="15px"
          onClick={this.updateVariation}
        >
          <Button
            copy={this.renderButtonCopy()}
            isParentWidth={true}
            isLoading={this.props.isLoading}
            isDisabled={this.isSaveDisabled()}
          />
        </Box>
      </Styles.QuickEdit>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore
}))(observer(QuickEdit));

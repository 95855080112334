import { FC, ReactNode } from 'react';

import { preventOrphans } from 'lib/services/string-format/string-format.service';

import { DatoCmsFullBleedImageAndText } from 'gatsby-types.d';

import { MarketingButton } from 'features/marketing/components/button/button';
import { MarketingLink } from 'features/marketing/components/link/link';
import { Body, H2 } from 'features/marketing/marketing.styles';

import {
  Content,
  ContentInner,
  ImageWrapper,
  Wrapper,
  ButtonWrapper
} from './full-bleed-image-and-text.styles';
import {
  FullBleedImageAndTextProps
} from './full-bleed-image-and-text.types';

const hasLink = (data: DatoCmsFullBleedImageAndText): boolean => {
  return !!data.ctaText && (!!data.ctaLinkExternal || !!data.ctaLinkInternal);
};

export const FullBleedImageAndText: FC<FullBleedImageAndTextProps> = ({ data }) => {
  const image = data.image?.[0];

  if (!image) return null;

  return (
    <Wrapper imagePosition={data.imagePosition} >
      <ImageWrapper bgUrl={image.fixed?.src} />
      <Content>
        <ContentInner>
          {data.heading && (
            <H2>
              {preventOrphans(data.heading)}
            </H2>
          )}
          {data.body && (
            <Body>
              {preventOrphans(data.body)}
            </Body>
          )}
          { hasLink(data) && (
            <ButtonWrapper>
              <MarketingLink
                type={data.ctaLinkInternal ? 'internal' : 'external'}
                url={!!data.ctaLinkInternal ? `/${data.ctaLinkInternal.slug}` : data.ctaLinkExternal}
                renderLink={(): ReactNode => (
                  <MarketingButton
                    copy={data.ctaText!}
                  />
                )}
              />
            </ButtonWrapper>
          )}
        </ContentInner>
      </Content>
    </Wrapper>
  );
};

import React, { Component, ReactNode } from 'react';

import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';

import ModalStore from 'stores/modal-store/modal-store';
import { ModalConfig } from 'stores/modal-store/modal-store.types';

import ModalWrapper from 'features/modal-dialogue/components/modal-wrapper';

interface ModalDialogueState {
  modals: ReactNode[];
}

interface ModalDialogueProps {
  modalStore?: ModalStore;
}

class ModalDialogue extends Component<ModalDialogueProps, ModalDialogueState> {
  state = {
    modals: []
  };

  componentDidMount = (): void => {
    this.setModalIntanceSubscription();
  };

  private destroyModal = (): void => {
    this.props.modalStore!.destroyModal();
    this.removeModalFromDOM();
  };

  private setModalIntanceSubscription = (): void => {
    observe(this.props.modalStore!, 'modalInstances', ({
      oldValue,
      newValue
    }: any) => {
      if (oldValue && oldValue.length < newValue.length) {
        const modal = this.createModal(newValue[newValue.length - 1], newValue.length - 1);

        this.setState({
          // @ts-ignore
          modals: this.state.modals.concat(modal)
        });
      }
    });
  };

  private removeModalFromDOM = (): void => {
    const modals = this.state.modals;

    modals.pop();

    this.setState({ modals });
  };

  private createModal = (options: ModalConfig, key: number): ReactNode => {
    return (
      <ModalWrapper
        options={options}
        destroyModal={this.destroyModal}
        key={`${key}`}
      />
    );
  };

  render = (): ReactNode[] => this.state.modals;
}

export default inject('modalStore')(observer(ModalDialogue));

import { forwardRef, memo, Ref } from 'react';

import {
  connectSearchBox,
  SearchBoxProvided
} from 'react-instantsearch-core';
import { Box } from 'rebass';

import FieldSearch from 'components/field-search';

interface SearchBoxProps extends SearchBoxProvided {
  searchValue: string;
  delay: number;
  onChange: (value: string) => void;
  inputRef?: Ref<any>;
  placeholder?: string;
}

const SearchBoxComponent = memo(connectSearchBox(({
  refine,
  onChange,
  searchValue,
  delay,
  inputRef,
  placeholder
}: SearchBoxProps) => {
  return (
    <Box width={['100%', '100%', 'auto', 'auto']}>
      <FieldSearch
        inputRef={inputRef || null}
        value={searchValue}
        delay={delay}
        autoFocus={true}
        placeholder={placeholder || 'Search'}
        onChange={onChange}
        onSearch={refine}
        onClear={(): void => {
          onChange('');

          setTimeout(() => {
            refine('');
          }, delay);
        }}
      />
    </Box>
  );
}));

export const SearchBox = forwardRef((props: Omit<SearchBoxProps, keyof SearchBoxProvided>, ref: Ref<any>) => (
  <SearchBoxComponent
    inputRef={ref}
    {...props}
  />
));

import { Component } from 'react';

import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Box } from 'rebass';

import { PlanFeature } from 'generated-types.d';

import {
  CurrencyService,
  LocalisationService,
  MerchantService,
  TimeService
} from 'lib';

import { ColourOption } from 'utils/rebass-theme/rebass-theme.types';

import Lozenge from 'components/lozenge';
import PageCornerBackground from 'components/page-corner-background';

import { MyPlanProps } from '../my-plan.types';

import {
  ASSOCIATED_FEES
} from './my-plan-details.contants';
import * as Styles from './my-plan.styles';

class MyPlanDetails extends Component<MyPlanProps> {
  private subscriptionEndingOn = (): any => {
    return TimeService.dateMonthYear(this.props.merchantStore?.merchant?.subscription?.trialExpiresAt);
  };

  private isShowingTrialInformation = (): boolean => {
    return this.props.merchantStore!.merchant?.subscription?.status !== 'ACTIVE'
      && moment(this.props.merchantStore?.merchant?.subscription?.trialExpiresAt).isAfter(moment())
      && !!this.props.merchantStore!.merchant?.subscription?.trialLength
      && this.props.merchantStore!.merchant?.subscription?.trialLength > 0;
  };

  private renderPaidStartDate = (): string => {
    const trialExpiresAt = this.props.merchantStore?.merchant?.subscription?.trialExpiresAt;
    const invitedAt = this.props.merchantStore?.merchant?.invitedAt;

    if (trialExpiresAt) {
      return TimeService.dateMonthYear(trialExpiresAt);
    } else if (invitedAt) {
      return TimeService.dateMonthYear(invitedAt);
    }

    return TimeService.dateMonthYear(this.props.merchantStore?.merchant?.subscription?.createdAt);
  };

  private hasSalesChannel = (): boolean => {
    return !!this.props.merchantStore!.merchant?.plan?.features?.some(feature => {
      return feature === PlanFeature.Website || feature === PlanFeature.Floom;
    });
  };

  private renderPlanPrice = (): string => {
    const plan = this.props.merchantStore!.merchant?.plan;
    const price = this.props.merchantStore!.merchant?.subscription?.stripeSubscriptionPrice;

    if (typeof price !== 'number' || !plan) {
      return 'Loading...';
    }

    return CurrencyService.formatPrice(price, plan.currency);
  };

  render(): JSX.Element {
    const merchant = this.props.merchantStore!.merchant;
    const plan = this.props.merchantStore!.merchant?.plan;
    const planPrice = this.renderPlanPrice();

    return (
      <Styles.PlanContainer>
        <PageCornerBackground
          topLeft={null}
          topRight="overview-top-right"
          bottomCenter="overview-bottom-center"
          bottomRight={null}
          bottomLeft={null}
        />
        <Styles.MyPlanHeader>
          <Box mb="20px">
            <Lozenge
              copy={`FloomX ${LocalisationService.localisePlanName(plan?.type)}`}
              bg={ColourOption.floomMidnightBlue}
            />
          </Box>
          <Styles.MyPlan>My Plan</Styles.MyPlan>
          <Styles.MyPlanSubTitle>Everything you need to manage and grow</Styles.MyPlanSubTitle>
          <Styles.Price>{planPrice} /mo</Styles.Price>
        </Styles.MyPlanHeader>

        <Styles.DetailListContainer>
          <Styles.DetailListHeader>
            <h3>Plan price</h3>
          </Styles.DetailListHeader>
          <Styles.DetailList>
            {(this.isShowingTrialInformation()) && (
              <li>
                <p>{this.props.merchantStore!.merchant?.subscription!.trialLength} day trial ending on {this.subscriptionEndingOn()}</p>
                <span>Free</span>
              </li>
            )}
            <li>
              <p>From {this.renderPaidStartDate()}</p>
              <span>{planPrice}</span>
            </li>
          </Styles.DetailList>
        </Styles.DetailListContainer>
        { this.hasSalesChannel() && (
          <Styles.DetailListContainer>
            <Styles.DetailListHeader>
              <h3>Sales channels and associated fees</h3>
            </Styles.DetailListHeader>
            <Styles.DetailList>
              { MerchantService.hasPlanFeature(PlanFeature.Floom, merchant!) &&
                <li><p>{ASSOCIATED_FEES.Floom.detail}</p><span>{ASSOCIATED_FEES.Floom.fee}</span></li>
              }
              { MerchantService.hasPlanFeature(PlanFeature.Website, merchant!) &&
                <li><p>{ASSOCIATED_FEES.Website.detail}</p><span>{ASSOCIATED_FEES.Website.fee}</span></li>
              }
            </Styles.DetailList>
            <Styles.DetailListNotes>
              *Please visit stripe fee <a href={ASSOCIATED_FEES.stripeWeb[plan!.currency]}>website</a> for up to date stripe transaction fees.
            </Styles.DetailListNotes>
          </Styles.DetailListContainer>
        )}
      </Styles.PlanContainer>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  chatWidgetStore: stores.chatWidgetStore,
  merchantStore: stores.merchantStore
}))(observer(MyPlanDetails));

import React, { Component, ReactNode } from 'react';

import { css, Global } from '@emotion/react';

import * as Styles from './form-footer.styles';
import * as Types from './form-footer.types';

export default class FormFooter extends Component<Types.FormFooterProps> {
  static defaultProps = {
    hasOffset: true
  };

  render(): ReactNode {
    return (
      <>
        <Global styles={css(Styles.FooterOffSet)} />
        <Styles.FooterWrapper hasOffset={this.props.hasOffset}>
          {this.props.children}
        </Styles.FooterWrapper>
      </>
    );
  }
}

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Box, Flex } from 'rebass';

import {
  Analytics,
  MerchantService,
  NavService
} from 'lib';

import { OnboardingStage } from 'stores/payment-onboarding/payment-onboarding-store.types';
import { ToastType } from 'stores/toaster-store/toaster-store.types';

import UserLoginService from 'features/login-signup/services';
import StripeElementsWrapper from 'features/payment/components/stripe-elements-wrapper';
import StripeSubmitElement from 'features/payment/components/stripe-submit-element';
import WrappedStripeForm from 'features/payment/components/wrapped-stripe-form';

import Button from 'components/button';
import FormFooter from 'components/form-footer';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';
import PlanDetails from 'components/plan-details';

import OnboardingWrapper from '../../components/onboarding-wrapper';

import * as Styles from './onboarding-payment-details.styles';
import * as Types from './onboarding-payment-details.types';

class OnboardingPaymentDetails extends Component<Types.OnboardingPaymentDetails, Types.OnboardingPaymentDetailsState> {
  state = {
    isSubscribed: MerchantService.isSubscribed(this.props.merchantStore!.merchant!)
  };

  componentDidMount = (): void => {
    this.props.paymentOnboardingStore!.setStage(OnboardingStage.Subscription);
  };

  componentWillUnmount = (): void => {
    this.props.paymentOnboardingStore!.resetStage();
  };

  private onSubmit = async (): Promise<void> => {
    if (this.state.isSubscribed) {
      this.onContinue();
    } else {
      await this.props.onInitialSubscribe!(this.props.merchantStore!.merchant!.id);
      await UserLoginService.getMerchantDetails();

      this.onContinue();

      if (!UserLoginService.shouldBeOnMerchantOnboarding()) {
        this.props.toasterStore!.popToast('You are subscribed! Your card details have been saved', ToastType.Success);
      }
    }
  };

  private onContinue = (): void => {
    const firstIncompleteStep = this.props.paymentOnboardingStore!.onboardingConfig.findIndex(step => !step.isComplete && step.isRequired);

    if (firstIncompleteStep !== -1) {
      this.props.paymentOnboardingStore!.onboardingConfig?.[firstIncompleteStep]?.navigateToStep();
    } else {
      this.props.paymentOnboardingStore!.resetStage();
      NavService.onboardingConfirmation();
    }
  };

  private renderSubscribedNotification = (): ReactNode => {
    return (
      <OnboardingWrapper my="auto">
        <Notification
          copy="Your subscription payment details for FloomX have already been supplied. Click continue to go to the next step."
          type={NotificationType.Success}
          hasClose={false}
          textAlign="left"
        />
      </OnboardingWrapper>
    );
  };

  private renderStripeForm = (): ReactNode => {
    return (
      <>
        <PlanDetails
          merchant={this.props.merchantStore!.merchant!}
        />
        <StripeElementsWrapper
          hasAcceptedTerms={this.props.hasAcceptedTerms}
          buildFonts={this.props.buildFonts}
          updateStripeValidation={this.props.updateStripeValidation}
          onNameOnCardChange={this.props.onNameOnCardChange}
          storeCardInstance={this.props.storeCardInstance}
          storeStripeInstance={this.props.storeStripeInstance}
          nameOnCard={this.props.nameOnCard}
          validation={this.props.validation}
        />
        <StripeSubmitElement
          hasAcceptedTerms={this.props.hasAcceptedTerms}
          isSubmitting={this.props.isSubmitting}
          setTermsCheckbox={this.props.setTermsCheckbox}
          onSubmit={async (): Promise<void> => this.props.onInitialSubscribe?.()}
          isFormValid={this.props.isFormValid}
          context={Analytics.TermsEntryPoint.Onboarding}
          merchant={this.props.merchantStore!.merchant!}
          shouldDisplayTerms={true}
        />
      </>
    );
  };

  private canContinue = (): boolean => {
    return (this.props.isFormValid() && !this.state.isSubscribed)
      || this.state.isSubscribed
      || !this.props.isSubmitting;
  };

  render(): ReactNode {
    return (
      <Box
        pb={['30px', '0']}
        id="global-layout"
      >
        <OnboardingWrapper my="auto">
          <Styles.Heading>
            Payment
          </Styles.Heading>
          <Styles.SubHeading>
            Enter the card details you would like to use for your FloomX monthly billing
          </Styles.SubHeading>
          {this.state.isSubscribed ? this.renderSubscribedNotification() : this.renderStripeForm()}
        </OnboardingWrapper>
        <FormFooter hasOffset={false}>
          <OnboardingWrapper my="auto">
            <Flex
              justifyContent="space-between"
              width="100%"
            >
              <Box />
              <Box
                as="button"
                onClick={this.onSubmit}
                disabled={!this.canContinue()}
              >
                <Button
                  size="normal"
                  appearance="primary"
                  copy="Continue"
                  isDisabled={!this.canContinue()}
                  isLoading={this.props.isSubmitting}
                />
              </Box>
            </Flex>
          </OnboardingWrapper>
        </FormFooter>
      </Box>
    );
  }
}

export default WrappedStripeForm(inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore,
  userStore: stores.userStore,
  merchantStore: stores.merchantStore,
  paymentOnboardingStore: stores.paymentOnboardingStore
}))(observer(OnboardingPaymentDetails)));

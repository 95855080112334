import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import Login from 'features/login-signup/pages/login';

import { AppLayout } from 'components/global-layout';
import { Route } from 'components/route/route';

const User = (): JSX.Element => {
  return (
    <AppLayout>
      {/*
      // @ts-ignore */}
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Router>
        <Route
          path="login"
          component={Login}
        />
        <Route
          path="login/:loginState"
          component={Login}
        />
      </Router>
    </AppLayout>
  );
};

export default User;

import { FC, ReactNode } from 'react';

import { TEST_IDS } from 'utils/test/data-test-ids';

import {
  Container,
  ContentLeft,
  ContentCenter,
  ContentRight,
  Wrapper
} from './page-header.styles';

export interface PageHeaderProps {
  renderCenter?: () => ReactNode;
  renderLeft?: () => ReactNode;
  renderRight?: () => ReactNode;
}

export const PageHeader: FC<PageHeaderProps> = ({
  renderCenter = () => null,
  renderLeft = () => null,
  renderRight = () => null
}) => {
  return (
    <Wrapper>
      <Container>
        <ContentLeft data-testid={TEST_IDS.PageHeader.Left}>
          {renderLeft()}
        </ContentLeft>
        <ContentCenter data-testid={TEST_IDS.PageHeader.Center}>
          {renderCenter()}
        </ContentCenter>
        <ContentRight data-testid={TEST_IDS.PageHeader.Right}>
          {renderRight()}
        </ContentRight>
      </Container>
    </Wrapper>
  );
};

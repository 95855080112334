import cuid from 'cuid';
import { List } from 'generated-types';
import { GraphQLError } from 'graphql';
import { CookieSchemas } from 'lib/services/cache/cache.service.types';
import { runInAction, makeAutoObservable, observable, action } from 'mobx';
import moment from 'moment';

import {
  Conversation,
  ConversationEventType,
  CreateContactInput,
  CreateMessageInput,
  Event,
  Message,
  MessageEventType,
  MessageType,
  PhoneContact,
  UpdateContactInput
} from 'types/conversations.types';

import { CacheService, NavService } from 'lib';

import { ConversationView } from 'features/conversations/context/ContactContext';
import { ConversationsService } from 'features/conversations/services';

import { ContactData, ListConversation } from './conversations-store.types';

export default class ConversationsStore {
  public shareList: ListConversation = {
    contactId: null,
    inShareFlow: false,
    listId: null,
    message: ''
  }

  private selectedContactId: string | null | undefined = undefined;

  public view: ConversationView = ConversationView.CONTACTS;

  public contacts: PhoneContact[] = [];

  /** Conversation data mapped by Contact Id */
  private contactsData: { [key in string]: ContactData } = {};

  private subscription?: ZenObservable.Subscription;

  public isSubscriptionConnecting: boolean = false;
  public isSubscriptionClosed: boolean = true;

  public contactsStatus: 'loading' | 'done' | 'failed' = 'loading';

  public message: string = '';

  constructor() {
    makeAutoObservable<this,
    | 'contactsData'
    | 'updateConversation'
    | 'selectedContactId'
    | 'subscription'>(this, {
      contactsData: observable,
      updateConversation: action,
      selectedContactId: observable,
      subscription: false
    });
  }

  public get selectedContact(): PhoneContact | null | undefined {
    if (!this.selectedContactId) {
      return null;
    }

    return  this.contacts.find(x => x.id === this.selectedContactId);
  }

  public get selectedContactData(): ContactData | null | undefined {
    if (!this.selectedContact) {
      return null;
    }

    return  this.contactsData[this.selectedContact.id];
  }

  public setSelectedContactId = (id: string): void => {
    this.selectedContactId = id;
  };

  public setShareListContactId = (contactId: string): void => {
    this.shareList.contactId = contactId;
  }

  public setShareListListId = (listId: string): void => {
    this.shareList.listId = listId;
  }

  public setShareListToConversationFlow = (contactId: string, listId: string): void => {
    this.shareList.contactId = contactId;
    this.shareList.inShareFlow = true;
    this.shareList.listId = listId;
  }

  public startShareExistingListFlow = (contactId: string, message?: string): void => {
    this.shareList.contactId = contactId;
    this.shareList.inShareFlow = true;
    this.shareList.message = message;

    NavService.listsList();
  }

  public startShareNewListFlow = (contactId: string, listId: string, message?: string): void => {
    this.shareList.inShareFlow = true;
    this.shareList.contactId = contactId;
    this.shareList.message = message;

    NavService.singleList(listId);
  }

  public endShareListFlow = (): void => {
    this.shareList.inShareFlow = false;
  }

  public resetShareListFlow = (): void => {
    this.shareList = {
      contactId: null,
      inShareFlow: false,
      listId: null,
      message: ''
    };
  }

  public setView = (view: ConversationView): void => {
    this.view = view;
  }

  public createContact = async (newContact: CreateContactInput): Promise<void> => {
    try {
      const contact = await ConversationsService.createContact(newContact);

      if (contact) {
        runInAction(() => {
          this.contacts.push(contact);
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public updateContact = async (updatedContact: UpdateContactInput): Promise<void> => {
    try {
      const contact = await ConversationsService.updateContact(updatedContact);

      if (contact) {
        runInAction(() => {
          const index = this.contacts.findIndex(x => x.id === contact.id);

          if (index > -1) {
            this.contacts[index] = contact;
          } else {
            this.contacts.push(contact);
          }
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public deleteContact = async (contactId: string, merchantId?: string): Promise<void> => {
    try {
      const deletedContact = await ConversationsService.deleteContact(contactId, merchantId);

      if (deletedContact) {
        runInAction(() => {
          this.contacts = this.contacts.filter(x => x.id !== deletedContact.id);
          this.view = ConversationView.CONTACTS;
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public fetchContacts = async (merchantId?: string): Promise<void> => {
    if (!merchantId) {
      this.contactsStatus = 'done';

      return;
    }

    this.contactsStatus = 'loading';

    try {
      const contacts = await ConversationsService.fetchContactsList({ merchantId });

      runInAction(() => {
        this.contacts = contacts;
        this.contactsStatus = 'done';
      });
    } catch (error) {
      runInAction(() => {
        this.contactsStatus = 'failed';
      });

      return Promise.reject(error);
    }
  };

  public get hasUnreadMessages(): boolean {
    if (this.contactsStatus !== 'done') {
      return this.retrieveCookie()?.hasUnreadMessages || false;
    }

    const value = !!this.contacts?.find(x => !!x.conversation?.unreadMessagesCount);

    this.storeCookie({
      hasUnreadMessages: value
    });

    return value;
  }

  public createConversation = async (contactId: string, merchantId?: string): Promise<void> => {
    const conversation = await ConversationsService.createConversation({ contactId, merchantId });
    this.updateConversation(conversation);
  };

  public setContactConversationRead = async (contactId: string, merchantId?: string): Promise<void> => {
    const conversationId = this.contacts.find(x => x.id === contactId)?.conversation?.id;

    if (!!conversationId) {
      const conversation = await ConversationsService.setConversationRead({ conversationId, merchantId });
      this.updateConversation(conversation);
    }
  };

  private updateContactData = <K extends keyof ContactData>(contactId: string, update: Pick<ContactData, K>): void => {
    const defaultValues = { isLoading: false, messages: [], error: undefined, draftMessage: {} };
    this.contactsData[contactId] = { ...defaultValues, ...this.contactsData[contactId], ...update };
  }

  public createMessage = async (input: CreateMessageInput, draftList?: List | null): Promise<void> => {
    const sentTime = moment.utc().format();
    const draftId = `draft-${cuid()}`;
    const draftMessage: Message = {
      id: draftId,
      draftId: draftId,
      merchantId: input.merchantId || '',
      conversationId: input.conversationId,
      messageType: MessageType.Outgoing,
      author: {
        name: ''
      },
      body: input.body,
      status: null, // draft
      createdAt: sentTime,
      updatedAt: sentTime,
      attachments: draftList ? [{
        __typename: 'ListAttachment',
        id: draftList?.id,
        title: draftList?.title,
        items: draftList?.items!.map(item => ({
          __typename: 'ListItem',
          id: item.id,
          quantity: item.quantity,
          title: item.sku || item.catalogItem?.title,
          type: item.type
        }))
      }] : null,
      __typename: 'Message'
    };

    this.updateMessage(draftMessage);

    const sentMessage = await ConversationsService.createMessage({ ...input, draftId });

    if (sentMessage) {
      this.updateMessage(sentMessage);
    }
  }

  public updateMessage = (newMessage: Message): void => {
    const contact = this.contacts.find(x => x.conversation?.id === newMessage?.conversationId);

    if (!newMessage || !contact) {
      return;
    }

    let messages: Message[] = this.contactsData[contact!.id]?.messages || [];

    // Remove old message
    messages = messages?.filter(x => x.id !== newMessage.id).filter(x => !newMessage.draftId || x.draftId !== newMessage.draftId);

    messages = [newMessage, ...messages]
      .sort((a, b) => moment(b?.createdAt).utc().unix() - moment(a?.createdAt).utc().unix());
    this.updateContactData(contact!.id, { messages });
  }

  private updateConversation = (newConversation?: Conversation): void => {
    if (!newConversation?.id) {
      return;
    }

    this.contacts = this.contacts.map(contact => {
      if (contact.conversation?.id === newConversation.id) {
        return {
          ...contact,
          conversation: newConversation
        };
      }

      return contact;
    });
  }

  public fetchMessagesForContact = async (contactId: string, merchantId?: string): Promise<void> => {
    const contact = this.contacts.find(x => x.id === contactId);
    const conversationId = contact?.conversation?.id;

    if (conversationId && !this.contactsData[contactId]?.isLoading) {
      this.updateContactData(contactId, { isLoading: true });

      try {
        const messagesPage = await ConversationsService.fetchMessages(conversationId, merchantId);
        const messages = messagesPage?.messages || [];

        runInAction(() => {
          if (this.contactsData[contactId]?.isLoading) {
            this.updateContactData(contactId, { isLoading: false, messages: messages });
          }
        });
      } catch (error) {
        runInAction(() => {
          this.updateContactData(contactId, { isLoading: false, error: error });
        });
      }
    }
  };

  public subscribe = async (merchantId: string): Promise<void> => {
    if (this.isSubscriptionConnecting) {
      return;
    }
    this.isSubscriptionConnecting = true;

    const onEvent = (event: Event): void => {
      if (event.__typename === 'MessageEvent') {
        switch (event.messageEventType) {
          case MessageEventType.MessageAdded:
          case MessageEventType.MessageUpdated:
            this.updateMessage(event.message);
        }
      } else if (event.__typename === 'ConversationEvent') {
        switch (event.conversationEventType) {
          case ConversationEventType.ConversationAdded:
          case ConversationEventType.ConversationUpdated:
            this.updateConversation(event.conversation);
        }
      }
    };

    const onError = (errors: readonly GraphQLError[]): void => {
      if (this.subscription?.closed) {
        runInAction(() => {
          this.isSubscriptionClosed = true;
        });
      }
      console.error(errors);
    };

    const onComplete = (): void => {
      runInAction(() => {
        this.isSubscriptionClosed = true;
      });
    };

    try {
      if (this.subscription && !this.isSubscriptionClosed) {
        this.subscription.unsubscribe();
      }

      const newSubscription = await ConversationsService.subscribe(merchantId, onEvent, onError, onComplete);

      runInAction(() => {
        this.isSubscriptionClosed = newSubscription.closed;
        this.subscription = newSubscription;
      });
    } finally {
      runInAction(() => {
        this.isSubscriptionConnecting = false;
      });
    }
  }

  private storeCookie(data: CookieSchemas.ConversationsUnreadMessages['data']): void {
    CacheService.storeCookieObject({
      name: 'conversationsUnreadMessages',
      data: data
    });
  }

  private retrieveCookie(): CookieSchemas.ConversationsUnreadMessages['data'] | undefined {
    const cookie = CacheService.retrieveCookie<CookieSchemas.ConversationsUnreadMessages>('conversationsUnreadMessages');

    return cookie?.content?.data;
  }
}

import React, { ReactNode, Component } from 'react';

import { Box } from 'rebass';

import GenericModal from 'components/generic-modal';
import GenericSelectList from 'components/generic-select-list';
import { GenericListObject } from 'components/generic-select-list/generic-select-list.types';
import Notification from 'components/notification';
import { NotificationType } from 'components/notification/notification.types';

import * as Types from './entity-selector.types';

export default class ItemSelectorModal extends Component<Types.EntitySelectorModalProps, Types.EntitySelectorModalState> {
  state = {
    items: this.props.data.allItems,
    isLoading: false
  };

  private getSelectedItems = (): GenericListObject[] => {
    return this.state.items.filter(item => item.selected);
  };

  private getSelectedItemIDs = (): string[] => {
    return this.getSelectedItems().map(item => item.id);
  };

  private hasSelecteditems = (): boolean => {
    return !!this.getSelectedItems().length;
  };

  private selectItem = (itemID: string = ''): void => {
    this.setState(prevState => {
      const newItems = prevState.items;

      for (const item of newItems) {
        item.selected = item.id === itemID;
      }

      return {
        items: newItems
      };
    });
  };

  private onClose = (): any => {
    this.props.closeModal();
    this.props?.data.onClose?.();
  };

  private onConfirm = async (): Promise<any> => {
    this.setState({ isLoading: true });
    const selectedItems = this.getSelectedItems();

    if (selectedItems.length > 0) {
      await this.props.data.selectItems(this.getSelectedItemIDs(), this);
    }

    this.setState({ isLoading: false });

    this.props.closeModal();
  };

  render(): ReactNode {
    return(
      <GenericModal
        width={430}
        innerComponent={(
          <>
            { !!this.props.data.notificationCopy && (
              <Box m="10px 20px -5px 20px">
                <Notification
                  type={NotificationType.Progress}
                  hasIcon={false}
                  hasClose={false}
                  textAlign="left"
                  copy={this.props.data.notificationCopy}
                />
              </Box>
            )}
            <GenericSelectList
              listObjects={this.state.items}
              onSelect={this.selectItem}
              alreadySelectedIds={this.props.data.initialSelectedIDs}
            />
          </>
        )}
        confirmButtonAction={this.onConfirm}
        confirmButtonText={this.props.data.modalProps.confirmButtonText || 'Confirm'}
        title={this.props.data.modalProps.title || ''}
        modalOpen={this.props.isOpen}
        closeModal={this.onClose}
        isButtonLoading={this.state.isLoading}
        confirmButtonDisabled={!this.hasSelecteditems() || !this.props.data.canConfirm()}
        extraAction={this.props.data.renderExtraAction(this)}
        {...this.props.data.modalProps}
      />
    );
  }
}

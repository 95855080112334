import { gql } from '@apollo/client';
import { RemittancePeriod, RemittanceTransfer, Transaction } from 'generated-types';

import { GraphQL } from 'lib';

export const REMITTANCE_PERIODS = gql`
  query RemittancePeriods(
    $today: DateTime!
  ) {
    remittancePeriods(
      orderBy: startAt_DESC
      where: {
        startAt_lte: $today
      }
    ) {
      id
      startAt
      endAt
      number
    }
  }
`;

export const TRANSACTION_QUERY = gql`
  query Transactions(
    $merchantId: ID!
    $periodId: ID!
  ) {
    transactions(
      orderBy: createdAt_DESC
      first: 1000
      where: {
        merchant: {
          id: $merchantId
        }
        isExcluded: false
        remittanceTransfer: {
          remittancePeriod: {
            id: $periodId
          }
        }
      }
    ) {
      id
      createdAt
      netAmount
      itemAmount
      deliveryAmount
      discountAmount
      externalFee
      serviceFee
      floomFee
      floomRemittance
      merchantRemittance
      type
      source
      remittanceType
      order {
        id
        orderNo
      }
      merchant {
        id
        title
        timezone
      }
      adjustment {
        id
        reason
        reasonFreeform
        type
      }
      remittanceTransfer {
        id
        sentAt
        paidAt
      }
    }
  }
`;

export const TRANSFER = gql`
  query Transfer(
    $periodId: ID!
    $merchantId: ID!
  ) {
    remittanceTransfers(
      where: {
        merchant: {
          id: $merchantId
        }
        remittancePeriod: {
          id: $periodId
        }
      }
    ) {
      id
      pdfFile
      paidAt
      sentAt
    }
  }
`;

export type GetTransactionInput = {
  merchantId: string | undefined;
  periodId: string;
};

export type GetTransferInput = {
  merchantId: string | undefined;
  periodId: string;
};

export const getTransactions = async (input: GetTransactionInput): Promise<any> => {
  const transactonQueryResult = await GraphQL.query<{ transactions: Transaction[] }>(
    TRANSACTION_QUERY,
    input
  );

  return transactonQueryResult.data.transactions;
};

export const getRemittancePeriods = async (): Promise<any> => {
  const today = new Date().toISOString();

  const transactonQueryResult = await GraphQL.query<{ remittancePeriods: RemittancePeriod[] }>(
    REMITTANCE_PERIODS,
    { today }
  );

  return transactonQueryResult.data.remittancePeriods;
};

export const getTransfer = async (input: GetTransferInput): Promise<any> => {
  const transferQueryResult = await GraphQL.query<{ remittanceTransfers: RemittanceTransfer[] }>(
    TRANSFER,
    input
  );

  return transferQueryResult.data.remittanceTransfers?.[0] || [];
};

import gql from 'graphql-tag';

export const ADD_ON_CATEGORIES_QUERY = gql`
  query AddonCategories(
    $active: Boolean!
  ) {
    addonCategoriesConnection(
      first: 50
      where: {
        active: $active
      }
    ) {
      aggregate {
        count
      }
      edges {
        node {
          id
          title
          slug
          channels {
            channel
          }
        }
      }
    }
  }
`;

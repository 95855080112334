import { Component, ReactNode } from 'react';

import cuid from 'cuid';
import { inject, observer } from 'mobx-react';
import { Box, Flex, Text } from 'rebass';
import * as Yup from 'yup';

import {
  AdjusterType
} from 'generated-types.d';

import {
  ValidationService
} from 'lib';

import { ToastType } from 'stores/toaster-store/toaster-store.types';
import { AdjusterTierFormValues } from 'stores/wholesale/wholesale-settings-store/wholesale-settings-store';

import { textStyles } from 'utils/rebass-theme';

import WholesaleSettingsAPIService from 'features/wholesale/services/wholesale-api/settings/wholesale-settings-api.service';

import Button from 'components/button';
import FormBuilder from 'components/form-builder';
import {
  FormBuilderSection
} from 'components/form-builder/form-builder.types';
import GenericModal from 'components/generic-modal';

import {
  WholesaleTierModalProps,
  WholesaleTierModalState
} from './wholesale-tier-modal.types';

class WholesaleTierModal extends Component<WholesaleTierModalProps, WholesaleTierModalState> {
  state: WholesaleTierModalState = {
    isSaving: false,
    unsavedTiers: this.props.wholesaleSettingsStore?.wholesaleTiers || [],
    validationErrors: {}
  };

  tierValidation: Yup.Schema<any> = Yup.object().shape({
    min: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Please add a min tier value')),
    max: Yup
      .string()
      .test(
        'Is larger than min',
        params => ValidationService.generateYupMessageSchema(params, 'Tier ceiling must be larger than tier floor'),
        function(maxValue: string) {
          const values = this.parent;

          if (parseInt(values.min) === 0 && parseInt(values.max) > 0) {
            return true;
          }

          return parseInt(values.min) < parseInt(maxValue);
        }
      ),
    adjusterPercent: Yup
      .string()
      .required(params => ValidationService.generateYupMessageSchema(params, 'Percent is required'))
      .test(
        'Is Percentage',
        params => ValidationService.generateYupMessageSchema(params, 'Percent must be a number between 1 and 100'),
        value => (value >= 1 && value <= 100)
      )
  });

  private createTier = (): void => {
    const tiers = this.state.unsavedTiers;
    const newTier: AdjusterTierFormValues = {
      id: cuid(),
      min: '0',
      max: '0',
      adjusterId: cuid(),
      adjusterPercent: '0',
      adjusterAppliesTo: AdjusterType.WholesaleGmv,
      pendingOperation: 'create'
    };

    if (tiers.length) {
      const latestTier = tiers[tiers.length - 1];

      if (latestTier.adjusterPercent === '0'
        && latestTier.max === '0') {
        this.props.toasterStore!.popToast(`Please complete previous wholesale tier first`, ToastType.Error);

        return;
      }
    }

    this.setState({ unsavedTiers: [...this.state.unsavedTiers, newTier] });
  };

  private updateTierField = ({ tierId, key, value }: { tierId: string; key: keyof AdjusterTierFormValues; value: string }): void => {
    const tierToChangeIndex = this.state.unsavedTiers.findIndex(tier => tier.id === tierId);

    if (tierToChangeIndex !== -1) {
      const isUpdatingNewlyCreatedTier = this.state.unsavedTiers[tierToChangeIndex].pendingOperation === 'create';

      const parseValueToValidNumber = (val: string): string => {
        let numericalValue = /^\d+$/.test(val) ? val : '';

        if (numericalValue.length > 1) {
          numericalValue = numericalValue.replace(/^0+/, '');
        }

        return numericalValue;
      };

      const updatedTier: AdjusterTierFormValues = {
        ...this.state.unsavedTiers[tierToChangeIndex],
        [key]: key === 'adjusterAppliesTo' ? value : parseValueToValidNumber(value),
        pendingOperation: isUpdatingNewlyCreatedTier ? 'create' : 'update' };

      const newUnsavedTiers = [...this.state.unsavedTiers];

      newUnsavedTiers[tierToChangeIndex] = updatedTier;

      this.setState({ unsavedTiers: newUnsavedTiers });
    }
  };

  private removeTier = (tierId: string): void => {
    const tierToDeleteIndex = this.state.unsavedTiers.findIndex(tier => tier.id === tierId);

    if (tierToDeleteIndex !== -1) {
      const isDeletingNewlyCreatedTier = this.state.unsavedTiers[tierToDeleteIndex].pendingOperation === 'create';

      const newUnsavedTiers = [...this.state.unsavedTiers];

      if (isDeletingNewlyCreatedTier) {
        newUnsavedTiers.splice(tierToDeleteIndex, 1);
        this.setState({ unsavedTiers: newUnsavedTiers });

        return;
      }

      const deletedTier: AdjusterTierFormValues = {
        ...this.state.unsavedTiers[tierToDeleteIndex],
        pendingOperation: 'delete'
      };

      newUnsavedTiers[tierToDeleteIndex] = deletedTier;

      this.setState({ unsavedTiers: newUnsavedTiers });
    }
  };

  private closeModal = (): void => {
    this.props.closeModal();
  };

  private save = async (): Promise<void> => {
    let isValid = true;
    this.setState({ isSaving: true });
    const errors: Record<string, Yup.TestMessageParams[]> = {};

    for (const tier of this.state.unsavedTiers) {
      try {
        await ValidationService.validateAll(this.tierValidation, tier);
      } catch (error) {
        if (isValid) {
          isValid = false;
        }
        errors[tier.id] = error.errors;
      }
    }

    if (!isValid) {
      this.props.toasterStore!.popToast(`You've got validation errors`, ToastType.Error);
      this.setState({ isSaving: false, validationErrors: errors });

      return Promise.reject();
    }

    let hasSaved = true;

    for (const formValue of this.state.unsavedTiers) {
      if (!formValue.pendingOperation) {
        continue;
      }

      try {
        switch (formValue.pendingOperation) {
          case 'create': {
            await WholesaleSettingsAPIService.createWholesaleTier({
              id: formValue.id,
              min: parseInt(formValue.min),
              max: parseInt(formValue.max),
              qualificationType: AdjusterType.WholesaleGmv,
              adjusters: {
                create: [
                  {
                    id: formValue.adjusterId,
                    percent: parseInt(formValue.adjusterPercent),
                    appliesTo: formValue.adjusterAppliesTo
                  }
                ]
              },
              merchant: {
                connect: {
                  id: this.props.merchantStore!.merchant!.id
                }
              }
            }, formValue);

            break;
          }

          case 'update': {
            await WholesaleSettingsAPIService.updateWholesaleTier(
              {
                id: formValue.id
              },
              {
                min: parseInt(formValue.min),
                max: parseInt(formValue.max),
                adjusters: {
                  update: [
                    {
                      where: {
                        id: formValue.adjusterId
                      },
                      data: {
                        percent: parseInt(formValue.adjusterPercent),
                        appliesTo: formValue.adjusterAppliesTo
                      }
                    }
                  ]
                }
              }, formValue
            );

            break;
          }

          case 'delete': {
            WholesaleSettingsAPIService.deleteWholesaleTier({ id: formValue.id });

            break;
          }
        }
      } catch (error) {
        if (hasSaved) {
          hasSaved = false;
        }
      }
    }

    if (!hasSaved) {
      this.props.toasterStore!.popToast(
        `We're having trouble updating wholesale discounts.`,
        ToastType.Error
      );

      this.setState({ isSaving: false });

      return Promise.reject();
    }

    this.closeModal();
    this.props.toasterStore!.popSuccessToast(`Wholesale discounts`, 'update', true);
  };

  private findError = (tierId: string, key: keyof AdjusterTierFormValues): Yup.TestMessageParams | undefined => {
    let error: Yup.TestMessageParams | undefined;

    try {
      error = ValidationService.findErrorCopy(this.state.validationErrors[tierId] || [], key);
    } catch {
      error = undefined;
    }

    return error;
  };

  private renderForm = (): JSX.Element => {
    const tiers = this.state.unsavedTiers;

    if (!tiers.filter(tier => tier.pendingOperation !== 'delete').length) {
      return (
        <Flex
          mb="20px"
          mt="20px"
          alignItems="center"
        >
          <Text css={textStyles.body}>No discount tiers created for this merchant</Text>
        </Flex>
      );
    }

    const adjusterTierSections = tiers.map((tier, index): FormBuilderSection[] => {
      const isHidden = tier.pendingOperation === 'delete';

      return [
        {
          width: '22',
          paddingBottom: '0',
          fields: [
            {
              key: `minLabel-${tier.id}`,
              fieldType: 'labelSmall',
              copy: `Tier floor`,
              subCopy: 'Minimum spend amount',
              validationError: undefined,
              isHidden: isHidden
            },
            {
              key: `min-${tier.id}`,
              id: `min-${tier.id}`,
              fieldType: 'textInput',
              value: `${tiers[index].min}`,
              validationError: this.findError(tier.id, 'min'),
              onChange: (e): void => this.updateTierField({ tierId: tier.id, key: 'min', value: e.target.value }),
              isHidden: isHidden
            }
          ]
        },
        {
          width: '22',
          paddingBottom: '0',
          fields: [
            {
              key: `maxLabel-${tier.id}`,
              fieldType: 'labelSmall',
              copy: 'Tier ceiling',
              subCopy: 'Maximum spend amount',
              validationError: undefined,
              isHidden: isHidden
            },
            {
              key: `max-${tier.id}`,
              id: `max-${tier.id}`,
              fieldType: 'textInput',
              value: `${tiers[index].max}`,
              validationError: this.findError(tier.id, 'max'),
              onChange: (e): void => this.updateTierField({ tierId: tier.id, key: 'max', value: e.target.value }),
              isHidden: isHidden
            }
          ]
        },
        {
          width: '22',
          paddingBottom: '0',
          fields: [
            {
              key: `percentLabel-${tier.id}`,
              fieldType: 'labelSmall',
              copy: 'Discount percentage',
              subCopy: '1-100',
              validationError: undefined,
              isHidden: isHidden
            },
            {
              key: `percent-${tier.id}`,
              id: `percent-${tier.id}`,
              fieldType: 'textInput',
              value: `${tiers[index].adjusterPercent}`,
              validationError: this.findError(tier.id, 'adjusterPercent'),
              onChange: (e): void => {
                this.updateTierField({
                  tierId: tier.id,
                  key: 'adjusterPercent',
                  value: e.target.value
                });
              },
              isHidden: isHidden
            }
          ]
        },
        {
          width: '22',
          paddingBottom: '0',
          fields: [
            {
              key: `typeLabel-${tier.id}`,
              fieldType: 'labelSmall',
              copy: `Applies to`,
              subCopy: 'Cashback type',
              validationError: undefined,
              isHidden: isHidden
            },
            {
              key: `type-${tier.id}`,
              id: `type-${tier.id}`,
              fieldType: 'dropdown',
              placeholder: 'Select a type',
              selected: tiers[index].adjusterAppliesTo as string,
              options: [
                { title: 'Wholesale GMV', id: AdjusterType.WholesaleGmv },
                { title: 'Floom.com Fee', id: AdjusterType.ConsumerFee }
              ],
              optionTitleField: 'title',
              optionValueField: 'id',
              onChange: (value: string): void => {
                this.updateTierField({
                  tierId: tier.id,
                  key: 'adjusterAppliesTo',
                  value: value
                });
              },
              isHidden: isHidden
            }
          ]
        },
        {
          width: '12',
          paddingBottom: '0',
          fields: [
            {
              key: `delete-${tier.id}`,
              fieldType: 'custom',
              isHidden: isHidden,
              customContent: (
                <Box
                  mt="88px"
                  onClick={(): void => {
                    this.removeTier(tier.id);
                  }}
                >
                  <Button
                    size="xsmall"
                    appearance="primary"
                    copy="Remove"
                  />
                </Box>
              )
            }
          ]
        }
      ];
    });

    return (
      <Box mt="10px">
        <FormBuilder
          config={{
            sections: [
              ...adjusterTierSections.reduce((a, b) => [...a, ...b], [])
            ]
          }}
        />
      </Box>
    );
  };

  private hasWholesaleTiers = (): boolean => {
    return this.state.unsavedTiers?.length > 0;
  };

  render(): ReactNode {
    const hasAdjusterTiers = this.hasWholesaleTiers();
    const copy = hasAdjusterTiers ? 'Update' : 'Create';

    return (
      <GenericModal
        title={`${copy} discounts`}
        closeModal={this.closeModal}
        modalOpen={this.props.isOpen}
        confirmButtonText="Update discounts"
        confirmButtonAction={this.save}
        isButtonLoading={this.state.isSaving}
        width={900}
        extraAction={(
          <Box
            onClick={this.createTier}
          >
            <Button
              size="normal"
              appearance="primary"
              copy="Add new tier"
            />
          </Box>
        )}
        innerComponent={(
          <Box
            pl="20px"
            pr="20px"
          >
            {this.renderForm()}
          </Box>
        )}
      />
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  merchantStore: stores.merchantStore,
  wholesaleSettingsStore: stores.wholesaleSettingsStore,
  toasterStore: stores.toasterStore
}))(observer(WholesaleTierModal));

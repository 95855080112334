import { css } from '@emotion/react';
import styled from '@emotion/styled';
import hex2rgba from 'hex2rgba';

import { textStyles, colors } from 'utils/rebass-theme';

export const StatusSelectContainer = styled.div<any>`
  position: relative;
  ${(props): any => (props.hasText && props.layout === 'grid') && 'width: 100%;'}
`;

export const StatusSelect = styled.select<any>`
  ${textStyles.subhead};
  color: ${colors.white};
  position: relative;
  appearance: none;
  display: block;
  height: 50px;
  background: ${hex2rgba(colors.floomMidnightBlue, 0.6)};
  border-radius: 0;

  ${(props): any => (props.hasText && props.layout === 'grid') && 'width: 100%;'}
  ${(props): any => !props.hasText ? `
    color: transparent;
    width: 50px;
  ` : `
    padding: 0 40px 0 20px;
  `}
  ${(props): any => props.layout === 'table' && `
    border-top-right-radius: 3px; border-bottom-right-radius: 3px;
  `}

  &:focus {
    outline: none;
  }
`;

export const DropdownArrow = css`
  pointer-events: none;
  position: absolute;
  top: 22px;
  right: 15px;
`;

export const DropdownLabel = styled.div`
  ${textStyles.body};
  margin-right: 10px;
`;

import store from 'stores';

import {
  ListConnection,
  List,
  ListCreateInput,
  QueryListsConnectionArgs,
  ListOrderByInput
} from 'generated-types.d';

import { GraphQL } from 'lib';

import {
  CREATE_LIST
} from 'features/lists/graphql/mutations/list.mutators';
import {
  LISTS
} from 'features/lists/graphql/queries/list.queries';

import {
  ListQueryVars
} from '../lists.types';

class ListsService {
  public static fetchLists = async ({ search } : { search: ListQueryVars }): Promise<ListConnection> => {
    try {
      const result = await GraphQL.query<{ listsConnection: ListConnection }>(
        LISTS,
        ListsService.buildListsQueryArgs(search)
      );

      return result.data.listsConnection;
    } catch (error) {
      store.toasterStore.popErrorToast('lists', 'get');

      return Promise.reject(error);
    }
  };

  public static createList = async ({ listCreateInput }: { listCreateInput: ListCreateInput }): Promise<List> => {
    try {
      const result = await GraphQL.query<{ createList: List }>(CREATE_LIST, { data: listCreateInput });

      return result.data.createList;
    } catch (error) {
      store.toasterStore.popErrorToast('list', 'create');

      return Promise.reject(error);
    }
  };

  private static buildListsQueryArgs = (search: ListQueryVars): QueryListsConnectionArgs => {
    return {
      orderBy: search.orderBy as ListOrderByInput,
      first: search.first,
      skip: search.skip,
      where: {
        deletedAt: null,
        ...(!!search.merchantId ? {
          merchant: {
            id: search.merchantId
          }
        } : {})
      }
    };
  }
}

export default ListsService;

import { Component, ReactNode } from 'react';

import { css } from '@emotion/react';
import { Box, Flex } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import CheckboxToggle from 'components/checkbox-toggle';
import DropdownNative from 'components/dropdown-native';
import FieldSearch from 'components/field-search';
import LayoutToggle from 'components/layout-toggle';

import * as Types from './list-controls.types';

class ListControls extends Component<Types.ListControlsProps> {
  private renderField = (config: Types.ListControlField, index: number): ReactNode => {
    switch (config.controlOptionType) {
      case 'searchField': {
        const { ...fieldSearchConfig } = config as Types.ListControlOptions.SearchField;

        return (
          <FieldSearch
            key={index}
            {...fieldSearchConfig as any}
          />
        );
      }

      case 'dropdown': {
        const dropdownConfig = config as Types.ListControlOptions.Dropdown;

        return (
          <DropdownNative
            key={index}
            {...dropdownConfig as any}
          />
        );
      }

      case 'toggle': {
        const checkboxConfig = config as Types.ListControlOptions.Toggle;

        return (
          <Flex
            key={index}
            alignItems="center"
          >
            <CheckboxToggle {...checkboxConfig as any} />
            { !!checkboxConfig?.label && (
              <Box
                css={textStyles.body}
                ml="10px"
              >
                {checkboxConfig?.label}
              </Box>
            )}
          </Flex>
        );
      }

      case 'layoutToggle': {
        const layoutToggleConfig = config as Types.ListControlOptions.LayoutToggle;

        return (
          <Box>
            <LayoutToggle {...layoutToggleConfig as any} />
          </Box>
        );
      }

      case 'custom': {
        return config.content;
      }

      default:
        return null;
    }
  };

  render(): JSX.Element {
    return (
      <Box
        mb="50px"
        mt="30px"
        css={this.props.containerStyles}
      >
        {this.props.config.map((item, index) => {
          return (
            <Box
              key={index}
              css={css`
                grid-area: ${item.name};
                ${item.wrapperStyles}
              `}
            >
              {this.renderField(item, index)}
            </Box>
          );
        })}
      </Box>
    );
  }
}

export default ListControls;

import React, { Component, ReactNode } from 'react';

import styled from '@emotion/styled';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';

import ProductsStore from 'stores/products-store';

import { breakpoints } from 'utils/rebass-theme';

import ProductServices from 'features/products/services';

import { GroupItemCount, GroupItemTitle } from '../product-grouping.styles';

const Item = styled(Flex)`
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.medium}) {
    flex-direction: row
  }
`;

class GeneralGroup extends Component<{ productsStore?: ProductsStore }> {
  ProductListService = ProductServices.ProductListService;

  render(): ReactNode {
    return (
      <>
        <Item>
          <GroupItemCount>0</GroupItemCount>
          <GroupItemTitle>Best Sellers</GroupItemTitle>
        </Item>
        <Item
          as="button"
          onClick={(): void => this.props.productsStore!.toggleInactiveProducts(false)}
        >
          <GroupItemCount>{this.props.productsStore!.metadata.count.active || '0'}</GroupItemCount>
          <GroupItemTitle>Active</GroupItemTitle>
        </Item>
      </>
    );
  }
}

export default inject('productsStore')(observer(GeneralGroup));

import { gql } from '@apollo/client';

export const INVITE_MERCHANT_TO_FLOOMX = gql`
  mutation InviteMerchantToFloomX($where: InviteToFloomXWhereUniqueInput!) {
    inviteToFloomX(where: $where) {
      status
    }
  }
`;

export const UPDATE_MERCHANT_ACTIVE_STATUS = gql`
  mutation UpdateMerchantActiveStatus($data: UpdateMerchantActiveStatusInput!) {
    updateMerchantActiveStatus(data: $data) {
      success
    }
  }
`;

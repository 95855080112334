import { Text } from 'rebass';

import { textStyles } from 'utils/rebass-theme';

import CheckboxToggle from 'components/checkbox-toggle';

import { CheckBoxWrapper } from '../delivery-config-modal.styles';

interface LeadTimeCheckboxProps {
  value: boolean;
  updateMethod: (x: any) => void;
  children: any;
}

export const LeadTimeCheckbox = (props: LeadTimeCheckboxProps): JSX.Element => (
  <CheckBoxWrapper>
    <CheckboxToggle
      isChecked={props.value}
      controlValue={true}
      onChange={props.updateMethod}
      activeCopy="On"
      inactiveCopy="Off"
    />
    <Text
      css={textStyles.body}
    >
      {props.children}
    </Text>
  </CheckBoxWrapper>
);

import gql from 'graphql-tag';

import { AddOnsCategoryFilter, AddOnsCategoryFilterType } from 'stores/add-ons-store/add-ons-store.types';

import { ADD_ONS_CONNECTION, ADD_ON_FULL } from '../fragments/add-ons.fragments';

const addVariable = (shouldAdd: boolean, variableName: string, type: string): string => {
  if (!shouldAdd) return '';

  return `$${variableName}: ${type}`;
};

const addMerchantWhereFilter = (merchantId: string): string => {
  if (!merchantId) return '';

  return `
    merchant: {
      id: $merchantId
    }
  `;
};

const addFilter = (shouldAdd: boolean, filterName: string, value: string): string => {
  if (!shouldAdd) return '';

  return `${filterName}: ${value}`;
};

// If user has selected "All Categories", then we shouldn't apply a filter at all
const categoryFilterApplied = (category: AddOnsCategoryFilterType): boolean => {
  return !!category && category !== AddOnsCategoryFilter.All;
};

const addCategoryWhereFilter = (category: AddOnsCategoryFilterType): string => {
  if (!categoryFilterApplied(category)) return '';

  return `
    category: {
      id: $selectedCategory
    }
  `;
};

const addRootQueryParams = (vars: any): string => {
  return `
    $orderBy: AddonOrderByInput
    ${addVariable(categoryFilterApplied(vars.selectedCategory), 'selectedCategory', 'ID!')}
    ${addVariable(vars.showActiveOnly, 'showActiveOnly', 'Boolean!')}
    ${addVariable(vars.merchantId, 'merchantId', 'ID!')}
  `;
};

export const SINGLE_ADD_ON = (merchantId: string): any => {
  return gql`
    query SingleAddonQuery(
      $id: ID!
      ${addVariable(!!merchantId, 'merchantId', 'ID')}
    )
    {
      addon(
        where: {
          id: $id
          ${addMerchantWhereFilter(merchantId)}
        }
      )
      {
        ...AddOnFull
      }
    }
    ${ADD_ON_FULL}
  `;
};

export const ALL_ADD_ONS = (vars: any): any => {
  return gql`
    query AddonsQuery(
      ${addRootQueryParams(vars)}
    ) {
      allAddons: addonsConnection(
        first: ${vars.first}
        orderBy: $orderBy
        where: {
          deletedAt: null
          ${addFilter(vars.showActiveOnly, 'active', '$showActiveOnly')}
          ${addCategoryWhereFilter(vars.selectedCategory)}
          ${addMerchantWhereFilter(vars.merchantId)}
        }
      ) {
        ...AddonsConnectionFields
      }
    }
    ${ADD_ONS_CONNECTION}
  `;
};

export const PAGINATE_ADD_ONS = (vars: any): any => {
  return gql`
    query LoadMoreAddOns(
      ${addRootQueryParams(vars)}
    ) {
      allAddons: addonsConnection(
        first: ${vars.first}
        skip: ${vars.skip}
        orderBy: $orderBy
        where: {
          ${addFilter(vars.showActiveOnly, 'active', '$showActiveOnly')}
          ${addCategoryWhereFilter(vars.selectedCategory)}
          ${addMerchantWhereFilter(vars.merchantId)}
        }
      ) {
        ...AddonsConnectionFields
      }
    }
    ${ADD_ONS_CONNECTION}
  `;
};

import styled from '@emotion/styled';

import { Card } from 'utils/css-mixins/css-mixins';
import { colors, textStyles } from 'utils/rebass-theme';

export const SelectedItemDeleteCross: any = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${colors.white};
  display: flex;
  padding: 4px;
  border-radius: 3px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.23);
  transition: box-shadow .2s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Content: any = styled.div`
  ${Card};
  ${textStyles.footnote};
  background-color: ${({ error }: any): string => error ? colors.errorBg : colors.uiFills};
  font-size: 16px;
  position: relative;
  width: auto;
  border-radius: 3px;
  overflow: hidden;

  > p {
    font-size: 14px;
    padding: 10px 0;
  }
`;

export const Image: any = styled.img`
  width: 170px;
  height: 170px;
  overflow: hidden;
  opacity: ${({ uploading, error }: any): string => uploading ? error ? '0' : '0.2' : '1'};
  transition: opacity .8s ease-in-out;
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

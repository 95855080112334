import { Component, ReactNode } from 'react';

import * as Styles from './button-loader.styles';
import * as Types from './button-loader.types';

// Inline svg's here because svg format is a particular
// Use case and doesn't fit in the traditional
// workflow of path's
export default class ButtonLoader extends Component<Types.ButtonLoaderProps> {
  render(): ReactNode {
    if (!this.props.isVisible) return null;

    return(
      <Styles.ButtonLoaderStyles
        isVisible={this.props.isVisible}
        appearance={this.props.appearance}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="3"
            width="3.09646"
            height="14"
            rx="1.54823"
            transform="rotate(-45 1 3)"
            fill="white"
          />
          <rect
            x="3"
            y="13"
            width="3.09646"
            height="14"
            rx="1.54823"
            transform="rotate(-135 3 13)"
            fill="white"
          />
        </svg>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="3"
            width="3.09646"
            height="14"
            rx="1.54823"
            transform="rotate(-45 1 3)"
            fill="white"
          />
          <rect
            x="3"
            y="13"
            width="3.09646"
            height="14"
            rx="1.54823"
            transform="rotate(-135 3 13)"
            fill="white"
          />
        </svg>
      </Styles.ButtonLoaderStyles>
    );
  }
}

import {
  FC,
  Fragment,
  useEffect,
  useRef,
  useState
} from 'react';

import { Global, css } from '@emotion/react';
import { CatalogItemHit } from 'global.types';
import { inject, observer } from 'mobx-react';
import {
  RefinementListExposed
} from 'react-instantsearch-core';
import {
  Configure,
  connectHits,
  InstantSearch
} from 'react-instantsearch-dom';
import { Box, Flex } from 'rebass';

import { CatalogItemType, ListItem, ListItemCreateInput } from 'generated-types.d';

import { CatalogService } from 'lib';

import { textStyles } from 'utils/rebass-theme';

import { mapCatalogTypeToListItemType } from 'features/lists/lists.helpers';

import {
  SearchBox,
  Pagination
} from 'components/algolia';
import GenericModal from 'components/generic-modal';
import Icon from 'components/icon';

import {
  Container,
  Content,
  ItemContainer,
  ModalStyles,
  SearchWrapper,
  Sidebar,
  Title
} from './catalog-explorer-modal.styles';
import {
  CatalogExplorerModalProps
} from './catalog-explorer-modal.types';
import CatalogExplorerItem from './components/catalog-explorer-item';
import { CatalogRefinementList } from './components/catalog-refinement-list/catalog-refinement-list';
import { CatalogRefinementMenu } from './components/catalog-refinement-menu/catalog-refinement-menu';

const searchClient = CatalogService.createCatalogAlgoliaClient();

type CatalogItemFacet = {
  title: string;
  refinement: string;
  operator: RefinementListExposed['operator'];
}

type CatalogExplorerFacets = {
  [key in CatalogItemType]: CatalogItemFacet[]
}

const CATALOG_ITEM_FACET_CONFIG: CatalogExplorerFacets = {
  [CatalogItemType.Decoration]: [
    {
      title: 'Colour',
      refinement: 'tradeSku.colour.name',
      operator: 'or'
    }
  ],
  [CatalogItemType.Flower]: [
    {
      title: 'Genus',
      refinement: 'genus',
      operator: 'or'
    },
    {
      title: 'Colour',
      refinement: 'tradeSku.colour.name',
      operator: 'or'
    }
  ],
  [CatalogItemType.Plant]: [
    {
      title: 'Colour',
      refinement: 'tradeSku.colour.name',
      operator: 'or'
    },
    {
      title: 'Pot Size',
      refinement: 'tradeSku.potSize',
      operator: 'or'
    },
    {
      title: 'Height',
      refinement: 'tradeSku.height',
      operator: 'or'
    }
  ],
  [CatalogItemType.Sundry]: [
    {
      title: 'Colour',
      refinement: 'tradeSku.colour.name',
      operator: 'or'
    },
    {
      title: 'Weight',
      refinement: 'tradeSku.weight',
      operator: 'or'
    },
    {
      title: 'Height',
      refinement: 'tradeSku.height',
      operator: 'or'
    }
  ],
  [CatalogItemType.Other]: [
    {
      title: 'Colour',
      refinement: 'tradeSku.colour.name',
      operator: 'or'
    }
  ]
};

const CatalogExplorerModalView: FC<CatalogExplorerModalProps> = ({
  closeModal,
  data,
  isOpen,
  selectedListStore,
  toasterStore
}) => {
  const itemContainerRef = useRef<HTMLUListElement>(null);
  const searchInputRef = useRef<any>(null);

  const [searchValue, setSearchValue] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(selectedListStore!.selectedCategory);

  useEffect(() => {
    setSearchFocus();

    return () => {
      data?.onClose?.();
    };
  }, []);

  const addItemToList = async (hit: CatalogItemHit): Promise<ListItem | undefined> => {
    const contextualMetadata = (): Partial<ListItemCreateInput> => {
      const map = {
        Decoration: 'decoration',
        Flower: 'flower',
        Plant: 'plant',
        Sundry: 'sundry'
      };

      return {
        [map[hit.type]]: {
          create: {}
        }
      };
    };

    try {
      return selectedListStore!.addItem({
        item: {
          type: mapCatalogTypeToListItemType(hit.type)!,
          sku: hit.title,
          colour: {
            connect: {
              id: hit.colour?.id
            }
          },
          catalogItem: {
            connect: {
              id: hit.objectID
            }
          },
          ...contextualMetadata()
        }
      });
    } catch (error) {
      toasterStore?.popErrorToast('list item to list', 'add');

      return Promise.reject(error);
    }
  };

  const removeItemFromList = async (item: ListItem): Promise<void> => {
    try {
      await selectedListStore!.deleteItem({
        id: item.id
      });
    } catch (error) {
      toasterStore?.popErrorToast('list item to list', 'add');

      return Promise.reject(error);
    }
  };

  const handleSearchInteraction = (value: string): void => {
    setSearchValue(value);
  };

  const setSearchFocus = (): void => {
    searchInputRef?.current?.focus?.();
  };

  const handleScroll = (): void => {
    if (!!itemContainerRef?.current) {
      itemContainerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const getFilters = (): string => {
    if (selectedListStore!.list?.suppliers?.length) {
      return `tradeSku.availability.supplierId:${selectedListStore!.list?.suppliers?.[0]?.id}`;
    }

    return '';
  };

  const ConnectedHits = connectHits<CatalogItemHit>(({
    hits
  }): any => {
    return hits.map(hit => (
      <CatalogExplorerItem
        key={hit?.objectID}
        hit={hit}
        onAdd={addItemToList}
        onRemove={removeItemFromList}
      />
    ));
  });

  const Refinements = (): any => {
    return (
      <Fragment>
        <Box>
          <CatalogRefinementMenu
            name="Category"
            attribute="type"
            defaultRefinement={selectedCategory}
            handleCategorySelect={setSelectedCategory}
          />
        </Box>
        {CATALOG_ITEM_FACET_CONFIG[selectedCategory]?.map?.((config, index) => {
          return (
            <Box
              key={index}
              mt="20px"
            >
              <CatalogRefinementList
                name={config.title}
                attribute={config.refinement}
                operator={config.operator}
              />
            </Box>
          );
        })}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Global styles={ModalStyles} />
      <GenericModal
        title="Add new items"
        closeModal={closeModal}
        modalOpen={isOpen}
        shouldHideHeader={true}
        shouldHideFooter={true}
        hasFooterBorder={false}
        hasOverflow={false}
        width={1050}
        overlayClassName={{
          base: 'catalog-explorer-modal__OverlaySlide',
          afterOpen: 'catalog-explorer-modal__OverlaySlide--after-open',
          beforeClose: 'catalog-explorer-modal__OverlaySlide--before-close'
        }}
        contentClassName={{
          base: 'catalog-explorer-modal__ContentSlide',
          afterOpen: 'catalog-explorer-modal__ContentSlide--after-open',
          beforeClose: 'catalog-explorer-modal__ContentSlide--before-close'
        }}
        innerContentStyles={{
          paddingBottom: '60px'
        }}
        contentTransform=""
        contentLayoutType="full-height"
        innerComponent={(
          <Container
            ref={itemContainerRef}
          >
            <Flex justifyContent="flex-end">
              <Flex
                as="button"
                alignItems="center"
                onClick={closeModal}
              >
                <Box
                  css={css`
                    ${textStyles.footnote}
                    text-transform: uppercase;
                  `}
                >
                  Close
                </Box>
                <Box
                  css={css`
                    position: relative;
                    top: 2px;
                  `}
                >
                  <Icon
                    iconName="cross-small"
                  />
                </Box>
              </Flex>
            </Flex>
            <InstantSearch
              indexName={process.env.ALGOLIA_CATALOG_INDEX!}
              searchClient={searchClient}
            >
              <Configure
                hitsPerPage={24}
                filters={getFilters()}
              />
              <Content>
                <Sidebar>
                  <Title mb="40px">
                    Explore
                  </Title>
                  <Refinements />
                </Sidebar>
                <Box
                  flex={1}
                >
                  <SearchWrapper>
                    <SearchBox
                      inputRef={searchInputRef}
                      searchValue={searchValue}
                      onChange={handleSearchInteraction}
                      placeholder="Search shop"
                      delay={400}
                    />
                  </SearchWrapper>
                  <ItemContainer>
                    <ConnectedHits />
                  </ItemContainer>
                  <Box mb="20px">
                    <Pagination handlePaginate={handleScroll} />
                  </Box>
                </Box>
              </Content>
            </InstantSearch>
          </Container>
        )}
      />
    </Fragment>
  );
};

export const CatalogExplorerModal = inject((stores: FxStores): InjectedFxStores => ({
  toasterStore: stores.toasterStore,
  selectedListStore: stores.selectedListStore
}))(observer(CatalogExplorerModalView));

import { ApolloError } from '@apollo/client';
import {
  CookieSchemas,
  MerchantsSettingsCookieData
} from 'lib/services/cache/cache.service.types';
import { action, observable, makeObservable } from 'mobx';

import {
  MerchantEdge,
  MerchantOrderByInput,
  Merchant,
  PlanType,
  MerchantConnection,
  PageInfo,
  MerchantStage,
  SubscriptionStatus
} from 'generated-types.d';

import {
  CacheService
} from 'lib';

import {
  Plans,
  PlansMap,
  Timezones,
  TimezonesMap,
  Stages,
  StagesMap,
  SubscriptionStatuses,
  SubscriptionStatusMap
} from './merchant-settings-store.constants';
import * as Types from './merchant-settings-store.types';

const buildMappedFilter = <T = string>(items: T[], mapping: any): Types.MerchantFilterItem[] => {
  return items.map(value => {
    return {
      title: mapping[value],
      value: `${value}`,
      slug: `${value}`
    };
  });
};

export default class MerchantSettingsStore {
  constructor() {
    makeObservable(this, {
      merchants: observable,
      searchValue: observable,
      isLoading: observable,
      filtersCount: observable,
      notification: observable,
      sortValue: observable,
      merchantsPageInfo: observable,
      newMerchants: observable,
      fieldList: observable,
      hasSetCachedFilters: observable,
      selectedFilters: observable,
      isShowingInactiveMerchants: observable,
      paginationCount: observable,
      paginationIncrement: observable,
      resetMerchantList: action,
      updateMerchantInList: action,
      setFilterCounts: action,
      setFieldList: action,
      resetMerchantListPage: action,
      setMerchantList: action,
      setNotification: action,
      setSearchValue: action,
      toggleActiveMerchants: action,
      setMerchantSortValue: action,
      setNewMerchant: action,
      resetNewMerchants: action,
      populateFilters: action,
      setLoading: action,
      resetLoading: action,
      setMerchantPageInfo: action
    });
  }

  public merchants: MerchantEdge [] = [];

  public plans: Types.MerchantFilterItem[] = [];

  public timezone: Types.MerchantFilterItem[] = [];

  public searchValue: string = '';

  public sortValue: MerchantOrderByInput = MerchantOrderByInput.TitleAsc;

  public isShowingInactiveMerchants: boolean = true;

  public isLoading: boolean = true;

  public newMerchants: string[] = [];

  public notification: string | null = null;

  public filtersCount: any | null = null;

  public orderBy: string = 'title_ASC';

  public fieldList: string[] = [
    'active',
    'florist',
    'location',
    'plan',
    'stage',
    'subscription-status',
    'other'
  ];

  public filters: Types.MerchantFilters = {
    plans: buildMappedFilter<PlanType>(
      Plans,
      PlansMap
    ),
    timezone: buildMappedFilter<Types.MerchantsTimezones>(
      Timezones,
      TimezonesMap
    ),
    stages: buildMappedFilter<MerchantStage>(
      Stages,
      StagesMap
    ),
    subscriptionStatus: buildMappedFilter<SubscriptionStatus>(
      SubscriptionStatuses,
      SubscriptionStatusMap
    )
  };

  public merchantsPageInfo: PageInfo = {
    hasNextPage: true,
    hasPreviousPage: false
  };

  public paginationCount: number = 0;

  public readonly paginationIncrement: number = 20;

  public hasSetCachedFilters = false;

  public setInitialPagination = (): void => {
    this.paginationCount = 0;
  };

  public onPaginate = (): void => {
    this.paginationCount += this.paginationIncrement;
  };

  public setLoading = (): void => {
    this.isLoading = true;
  };

  public resetLoading = (): void => {
    this.isLoading = false;
  };

  public errors: { [key in Types.MerchantListError]: ApolloError | null } = {
    merchants: null,
    quickSearch: null
  };

  private resetAppliedFilters = (): Types.SelectedFilters => {
    return {
      plans: new Set(),
      timezone: new Set(),
      stages: new Set(),
      subscriptionStatus: new Set()
    };
  };

  private setCachedFilters = (cookieData: MerchantsSettingsCookieData): Types.SelectedFilters => {
    const filters = this.resetAppliedFilters();

    for (const groupKey in cookieData) {
      if (cookieData.hasOwnProperty(groupKey)) {
        filters[groupKey] = new Set(...[cookieData[groupKey]]);
      }
    }

    return filters;
  };

  public setFieldList = (fields: string[] = []): void => {
    const fixedItems = ['florist'];

    this.fieldList = [...fixedItems, ...fields];
  };

  public populateFilters = (): Types.SelectedFilters => {
    const cookie = CacheService.retrieveCookie('merchantsSettingsFilters');

    if (cookie?.content?.name === 'merchantsSettingsFilters') {
      return this.setCachedFilters(cookie.content.data);
    }

    return this.resetAppliedFilters();
  };

  public resetFilters = (): void => {
    CacheService.removeCookie('merchantsSettingsFilters');
    this.selectedFilters = this.resetAppliedFilters();
  };

  public selectedFilters: Types.SelectedFilters = this.populateFilters();

  public merchantFilterCounts: Types.MerchantFilterCounts | null = null;

  public resetMerchantListPage = (): void => {
    this.merchants = [];
    this.searchValue = '';
    this.paginationCount = 0;
    this.newMerchants = [];
    this.sortValue = MerchantOrderByInput.TitleAsc;
    this.isShowingInactiveMerchants =  true;
    this.isLoading = true;
  };

  public resetMerchantList = (): void => {
    this.merchants = [];
    this.paginationCount = 0;
    this.newMerchants = [];
    this.isLoading = true;
  };

  public setNewMerchant = (merchantId: string): void => {
    this.newMerchants = [
      ...this.newMerchants,
      merchantId
    ];
  };

  public setNotification = (copy: string | null = null): void => {
    this.notification = copy;
  };

  public resetNewMerchants = (): void => {
    this.newMerchants = [];
  };

  public setMerchantList = (merchants: MerchantEdge[] = [], shouldReplace: boolean = true): void => {
    this.merchants = shouldReplace ? [...merchants] : [...this.merchants, ...merchants];
    this.isLoading = false;
  };

  public setMerchantPageInfo = (pageInfo?: PageInfo): any => {
    return !!pageInfo ? this.merchantsPageInfo.hasNextPage = pageInfo.hasNextPage : this.merchantsPageInfo.hasNextPage = true;
  };

  public setSearchValue = (value: string): void => {
    this.searchValue = value;
  };

  public setFilterCounts = (data: { [key: string]: MerchantConnection }): void => {
    let counts = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const filterGroup = key.split('_')[0];
        let value = '';

        if (filterGroup === 'timezone') {
          const filterTimezoneGroup = key.split('timezone_')[1];
          value = filterTimezoneGroup.replace('_', '/');
        } else {
          value = key.split(/_(.+)/)[1];
        }

        if (!counts[filterGroup]) {
          counts = {
            ...counts,
            [filterGroup]: {}
          };
        }
        counts[filterGroup][value] = data[key].aggregate.count;
      }
    }

    this.filtersCount = counts as Types.MerchantFilterCounts;
  };

  public toggleFilter = (value: PlanType | Types.MerchantsTimezones, filterGroup: Types.MerchantFilterGroup): void => {
    if (!this.selectedFilters[filterGroup].has(value)) {
      this.selectedFilters[filterGroup].add(value);
    } else {
      this.selectedFilters[filterGroup].delete(value);
    }
    this.cacheFilters();
  };

  public cacheFilters = (): void => {
    const cookieObject: CookieSchemas.MerchantsSettingsFilters = {
      name: 'merchantsSettingsFilters',
      data: {
        plans: [],
        timezone: [],
        stages: [],
        subscriptionStatus: []
      }
    };

    for (const filter in this.selectedFilters) {
      if (this.selectedFilters.hasOwnProperty(filter)) {
        cookieObject.data[filter] = Array.from(this.selectedFilters[filter]);
      }
    }
    CacheService.storeCookieObject(cookieObject);
  };

  public toggleActiveMerchants = (): void => {
    this.isShowingInactiveMerchants = !this.isShowingInactiveMerchants;
  };

  public setMerchantSortValue = (value: MerchantOrderByInput): void => {
    this.sortValue = value;
  };

  public updateMerchantInList = (index: number, updates: Partial<Merchant>): void => {
    this.merchants[index].node = {
      ...this.merchants[index].node,
      ...updates
    };
  };
}

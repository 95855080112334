import { FC } from 'react';

import styled from '@emotion/styled';
import { Box } from 'rebass';

import { colors, textStyles } from 'utils/rebass-theme';

const Wrapper = styled(Box)`
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  background: ${colors.uiFills};
  border-radius: 10px;
  overflow: hidden;
`;

const Title = styled(Box)`
  ${textStyles.h4};
  padding: 15px;
  background: ${colors.paleGrey};
`;

const Body = styled(Box)`
  padding: 15px 15px 25px 15px;
  ${textStyles.body};
`;

interface CardProps {
  title: string;
}

export const FooterCard: FC<CardProps> = ({
  title,
  children
}) => {
  return (
    <Wrapper>
      <Title>
        {title}
      </Title>
      <Body>
        {children}
      </Body>
    </Wrapper>
  );
};

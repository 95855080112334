import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';

import {
  space,
  fontSizes,
  borderRadius,
  colors
} from 'utils/rebass-theme';

import { Position } from '../messages/messages.types';

type MessageType = 'incoming' | 'outgoing';

type MessageOptionsMap = {
  [key in MessageType]?: SerializedStyles;
}

interface MessageOption extends MessageOptionsMap {
  default: SerializedStyles;
}

type MessageStyleConfig = {
  [key in 'SINGLE' | 'MIDDLE' | 'FIRST' | 'LAST']: MessageOption;
}

const messageStyleConfig: MessageStyleConfig = {
  FIRST: {
    incoming: css`border-radius: ${borderRadius.xLarge} ${borderRadius.xLarge} ${borderRadius.xLarge} 0;`,
    default: css`border-radius: ${borderRadius.xLarge} ${borderRadius.xLarge} 0 ${borderRadius.xLarge};`
  },
  LAST: {
    incoming: css`
      border-radius: 0 ${borderRadius.xLarge} ${borderRadius.xLarge} ${borderRadius.xLarge};
      margin-bottom: ${space[2]}px;
    `,
    default: css`
      border-radius: ${borderRadius.xLarge} 0 ${borderRadius.xLarge} ${borderRadius.xLarge};
      margin-bottom: ${space[2]}px;
    `
  },
  MIDDLE: {
    incoming: css`border-radius: 0 ${borderRadius.xLarge} ${borderRadius.xLarge} 0;`,
    default: css`border-radius: ${borderRadius.xLarge} 0 0 ${borderRadius.xLarge};`
  },
  SINGLE: {
    default: css`
      border-radius: ${borderRadius.xLarge};
      margin-bottom: ${space[2]}px;
    `
  }
};

export const TextWrapper = styled(Box)<{
  messageType: MessageType;
  isFirstMessage: boolean;
  position: Position;
}>`
  font-size: ${fontSizes.small}px;
  white-space: pre-line;
  background-color: ${({ messageType }) => messageType === 'incoming' ? colors.middleGrey : colors.floomMidnightBlue};
  color: ${({ messageType }) => messageType === 'incoming' ? colors.black : colors.white};
  padding: ${space[2]}px;
  margin: 0 ${space[2]}px;
  min-width: auto;
  word-break: break-word;
  position: relative;
  max-width: 70%;

  ${({ messageType, position }) => messageStyleConfig[position][messageType] || messageStyleConfig[position]['default']}
`;

export const MessageWrapper = styled(Box)`
  order: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: ${({ incoming }) => incoming ? 'row' : 'row-reverse'};

  ${({ isFirstMessage, incoming }) => isFirstMessage ? '' : `
    ${incoming ? 'margin-left: 30px' : 'margin-right: 30px'};
  `}
`;

export const ProfileWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  min-width: 30px;
  margin-bottom: ${space[2]}px;
`;

export const MessageTail = styled(Box)`
  content: '';
  width: 30px;
  height: ${space[3]}px;
  background-color: transparent;
  position: relative;
  left: ${props => props.incoming ? '12' : '-12'}px;
  ${props => props.incoming ? 'transform: scale(-0.75, 1);' : 'transform: scale(0.75, 1);'}
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 60%;
  box-shadow: -10px 8px 0px 8px ${({ incoming }) => incoming ? colors.middleGrey : colors.floomMidnightBlue};
`;

export const DateTime = styled(Box)`
  font-size: 10px;
  color: ${({ incoming }) => incoming ? colors['black-light'] : colors.middleGrey};;
  min-width: ${space[4]}px;
  text-align: right;
  position: absolute;
  right: ${space[2]}px;
  bottom: ${space[2]}px;
`;

export const LineBreak = styled(Flex)`
  width: 100%;
`;

export const Spacer = styled.div`
  float: right;
  min-width: ${space[4]}px;
`;

import { action, observable, makeObservable } from 'mobx';

export interface LightboxImage {
  url: string;
  caption?: string;
}

export default class LightboxStore {
  constructor() {
    makeObservable(this, {
      isOpen: observable,
      images: observable,
      selectedImageIndex: observable,
      currentIndexInView: observable,
      currentImageInView: observable,
      enableZoom: observable,
      nextImage: observable,
      previousImage: observable,
      animationDisabled: observable,
      animationOnKeyInput: observable,
      animationDuration: observable,
      setNextImage: action,
      setPreviousImage: action,
      viewPreviousImage: action,
      viewNextImage: action,
      triggerLightbox: action,
      closeLightbox: action
    });
  }

  public readonly enableZoom: boolean = true;

  public readonly animationDisabled: boolean = true;

  public readonly animationOnKeyInput: boolean = true;

  public readonly animationDuration: number = 300;

  public isOpen: boolean = false;

  public images: LightboxImage[] = [];

  public selectedImageIndex: number = 0;

  public currentIndexInView: number = 0;

  public currentImageInView: LightboxImage | null = null;

  public nextImage: LightboxImage | null = null;

  public previousImage: LightboxImage | null = null;

  public triggerLightbox = (
    images: LightboxImage[],
    selectedImageIndex: number = 0
  ): void => {
    this.setLightbox(images, selectedImageIndex);
    this.isOpen = true;
  };

  public setLightbox = (
    images: LightboxImage[],
    selectedImageIndex: number = 0
  ): void => {
    this.images = images;
    this.selectedImageIndex = selectedImageIndex;
    this.currentIndexInView = this.selectedImageIndex;
    this.currentImageInView = this.images[this.selectedImageIndex];

    if (images.length > 1) {
      this.setPreviousImage();
      this.setNextImage();
    }
  };

  public setNextImage = (): void => {
    const nextIndex = (this.currentIndexInView + 1) % this.images.length;
    this.nextImage = this.images[nextIndex];
  };

  public setPreviousImage = (): void => {
    const prevIndex =
      (this.currentIndexInView + this.images.length - 1) % this.images.length;
    this.previousImage = this.images[prevIndex];
  };

  public closeLightbox = (): void => {
    this.isOpen = false;
    this.resetLightbox();
  };

  public resetLightbox = (): void => {
    this.images = [];
    this.selectedImageIndex = 0;
    this.currentIndexInView = 0;
    this.currentImageInView = null;
    this.nextImage = null;
    this.previousImage = null;
  };

  public viewPreviousImage = (): void => {
    this.setPreviousImage();

    this.currentIndexInView =
      (this.currentIndexInView + this.images.length - 1) % this.images.length;
    this.currentImageInView = this.images[this.currentIndexInView];
  };

  public viewNextImage = (): void => {
    this.setNextImage();

    this.currentIndexInView =
      (this.currentIndexInView + 1) % this.images.length;
    this.currentImageInView = this.images[this.currentIndexInView];
  };
}

import React, { Component } from 'react';

import debounce from 'lodash.debounce';
import { Flex } from 'rebass';

import { MathService } from 'lib';

import FieldText from 'components/field-text';
import Icon from 'components/icon';

import * as Styles from './quantity-selector.styles';
import * as Types from './quantity-selector.types';

export default class QuantitySelector extends Component<Types.QuantitySelectorProps, Types.QuantitySelectorState> {
  private round = (valueToRound: number): number => {
    if (valueToRound > this.props.max) {
      return MathService.roundToNonZeroInt(this.props.max, this.props.step);
    }

    return MathService.roundToNonZeroInt(valueToRound, this.props.step);
  };

  private getPropsMin = (): number => {
    return this.props.min || 0;
  };

  private getPropsMax = (): number => {
    return this.props.step || 1;
  };

  private getPropsStep = (): number => {
    return this.props.step || 1;
  }

  private initialStep = this.getPropsStep();
  private initialMin = this.getPropsMin();
  private initialMax = this.getPropsMax();

  static defaultProps: Types.QuantitySelectorProps = {
    step: 1,
    min: 0,
    max: 999999999999,
    initialValue: 0,
    isDisabled: false,
    size: 'small'
  };

  state = {
    value: this.props.initialValue >= this.props.min ? `${this.round(this.props.initialValue)}` : `${this.props.min}`
  };

  componentDidUpdate = (): void => {
    const hasStepChanged = this.initialStep !== (this.getPropsStep());
    const hasMinChanged = this.initialMin !== (this.getPropsMin)();
    const hasMaxChanged = this.initialMax !== (this.getPropsMax());

    if (hasStepChanged || hasMinChanged || hasMaxChanged) {
      this.initialStep = this.getPropsStep();
      this.initialMin = this.getPropsMin();
      this.initialMax = this.getPropsMax();

      this.setState({
        value: this.props.initialValue >= this.props.min ? `${this.round(this.props.initialValue)}` : `${this.props.min}`
      });
    }
  };

  private increaseQuantity = (): void => {
    const value = this.calculateQuantityIncrease();

    this.setState({ value: `${value}` });
    this.handleChangeCallback(value);
  };

  private decreaseQuantity = (): void => {
    const value = this.calculateQuantityDecrease();

    this.setState({ value: `${value}` });
    this.handleChangeCallback(value);
  };

  private calculateQuantityIncrease = (): number => {
    const value = parseInt(this.state.value);

    return (value + this.props.step) >= this.props.max ? this.props.max : value + this.props.step;
  };

  private calculateQuantityDecrease = (): number => {
    const value = parseInt(this.state.value);
    const newValue = value - this.props.step;

    return newValue > this.props.min ? newValue : this.props.min;
  };

  private onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.valueAsNumber;

    this.setState({
      value: isNaN(value) ? '' : `${value}`
    });
    this.handleChangeCallback(value);
  };

  private roundToNearestValue = (inputValue: number): void => {
    if ((!!inputValue && inputValue < this.props.min) || !inputValue) {
      this.setState({
        value: `${this.props.min}`
      });

      this.handleChangeCallback(this.props.min);
    } else {
      const newValue = this.round(inputValue);

      this.handleChangeCallback(newValue);

      this.setState({
        value: isNaN(newValue) ? '' : `${newValue}`
      });
    }
  };

  private handleKeyUp = debounce((e: React.KeyboardEvent<HTMLInputElement>): void => {
    // @ts-ignore
    const inputValue = e.target.valueAsNumber;

    this.roundToNearestValue(inputValue);
  }, 1000);

  private handleBlur = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.valueAsNumber;

    this.roundToNearestValue(inputValue);
  };

  private handleChangeCallback = (value: number): void => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  private isDisabled = (direction: 'up' | 'down'): boolean => {
    return direction === 'up'
      ? parseInt(this.state.value) === this.props.max
      : parseInt(this.state.value) === this.props.min;
  };

  render(): JSX.Element {
    return (
      <Flex>
        <Styles.QuantityAdjuster
          onClick={this.decreaseQuantity}
          isDisabled={this.isDisabled('down') || this.props.isDisabled}
          as="button"
          alignItems="center"
          id="quantity-selector-decrease"
          size={this.props.size}
        >
          <Icon iconName="minus-small" />
        </Styles.QuantityAdjuster>
        <Styles.QuantityInput size={this.props.size}>
          <FieldText
            id="quantity-input"
            type="number"
            size="small"
            isCentered={true}
            value={this.state.value}
            max={this.props.max}
            step={this.props.step}
            onChange={this.onChange}
            onBlur={this.handleBlur}
            onKeyUp={this.handleKeyUp}
            customCss={Styles.QuantityInput}
          />
        </Styles.QuantityInput>
        <Styles.QuantityAdjuster
          onClick={this.increaseQuantity}
          isDisabled={this.isDisabled('up') || this.props.isDisabled}
          as="button"
          alignItems="center"
          id="quantity-selector-increase"
          size={this.props.size}
        >
          <Icon iconName="plus-small" />
        </Styles.QuantityAdjuster>
      </Flex>
    );
  }
}

import React, { ReactNode } from 'react';

import { Router } from '@reach/router';
import Helmet from 'react-helmet';

import CheckoutList from 'features/lists/components/checkout-list';
import ListsList from 'features/lists/components/lists-list/lists-list';
import SingleList from 'features/lists/components/single-list';

import { AppLayout } from 'components/global-layout';
import { AuthedRoute } from 'components/route/route';

export const Lists = (): ReactNode =>  (
  <AppLayout>
    <Helmet>
      <title>Create List</title>
    </Helmet>
    <Router>
      <AuthedRoute
        path="/lists"
        component={ListsList}
      />
      <AuthedRoute
        path="/lists/:listId"
        component={SingleList}
      />
      <AuthedRoute
        path="/lists/checkout/:listId"
        component={CheckoutList}
      />
    </Router>
  </AppLayout>
);

export default Lists;

import {
  FC,
  Fragment,
  useEffect
} from 'react';

import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { SINGLE_LIST_FIELD_CONFIG } from 'features/lists/components/single-list/single-list.config';
import { getMedia, listItemMediaImage } from 'features/lists/lists.helpers';
import {
  Columns,
  ItemDetails,
  ItemTitle,
  ListCellTitle,
  MetaColumn,
  ListItemWrapper,
  Thumbnail
} from 'features/lists/lists.styles';
import { ListFieldConfig } from 'features/lists/lists.types';
import { ListItemDetailsModalData } from 'features/modal-dialogue/components/modals/list-item-details-modal/list-item-details-modal.types';

import Icon from 'components/icon';

import {
  SingleListItemProps
} from './single-list-item.types';

const SingleListItemComponent: FC<SingleListItemProps> = ({
  listItem,
  modalStore,
  onDelete,
  selectedListStore,
  shouldShowImages
}) => {
  const fieldConfig = SINGLE_LIST_FIELD_CONFIG[listItem.list!.type];

  useEffect(() => {
    if (selectedListStore!.newListItemIds.includes(listItem.id)) {
      setTimeout((): void => {
        selectedListStore!.removeNewListItemId(listItem.id);
      }, 2000);
    }
  }, []);

  if (!fieldConfig) return null;

  const showModal = (e: MouseEvent | TouchEvent): void => {
    const element = e.target as HTMLElement;

    if (element.tagName === 'INPUT') {
      return;
    }

    modalStore!.triggerModal<ListItemDetailsModalData>({
      modalType: 'listItemDetails',
      data: {
        itemId: listItem.id,
        listId: selectedListStore?.list?.id || '',
        onDelete: onDelete,
        onFetch: selectedListStore?.fetchItem,
        onUpdate: selectedListStore?.updateItem
      }
    });
  };

  const shouldDisplayColumn = (field: ListFieldConfig): boolean => {
    return !field.supportedTypes?.length || (!!listItem?.type && !!field.supportedTypes.includes(listItem.type));
  };

  return (
    <ListItemWrapper
      isNew={selectedListStore!.newListItemIds.includes(listItem.id)}
      onClick={(e: MouseEvent | TouchEvent) => showModal(e)}
    >
      {shouldShowImages && (
        <Thumbnail
          imageUrl={listItemMediaImage(getMedia(listItem), true)}
        >
          {listItem.type === 'Flower' && (
            <Icon iconName="flower-squiggle" />
          )}
        </Thumbnail>
      )}
      <Columns>
        <ItemDetails>
          <ItemTitle listType={selectedListStore?.list?.type}>
            <Box>
              {fieldConfig.titleField.renderValue?.(listItem)}
            </Box>
          </ItemTitle>
          <Fragment>
            {fieldConfig.fields?.map((field, fieldIndex) => {
              if (!shouldDisplayColumn(field)) return null;

              return (
                <MetaColumn
                  key={`${field.key}-${fieldIndex}`}
                  customStyles={field.customStyles}
                  isMetadata={field.isMeta}
                  isQuantity={field.key === 'quantity'}
                >
                  <ListCellTitle>
                    {field.renderValue?.(listItem) || 'Any'}
                  </ListCellTitle>
                </MetaColumn>
              );
            })}
          </Fragment>
        </ItemDetails>
      </Columns>
    </ListItemWrapper>
  );
};

export const SingleListItem = inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore,
  selectedListStore: stores.selectedListStore
}))(observer(SingleListItemComponent));

import { FC, Fragment } from 'react';

import { Link, graphql, useStaticQuery } from 'gatsby';
import { Query } from 'gatsby-types';

import { gridGutter, colors } from 'utils/rebass-theme';

import LogoMonogram from 'assets/icons/marketing/logo-monogram.svg';

import { Section, SectionContent } from '../section';

import * as Styles from './footer.styles';

const query = graphql`
  query FooterPages {
    allDatoCmsPage(
      filter: {
        locale: {
          eq: "en-GB"
        }
        hideInNav: {
          ne: true
        }
      },
      sort: {
        fields: position,
        order: ASC
      }
    ) {
      edges {
        node {
          originalId
          pageTitle
          slug
        }
      }
    }
  }
`;

export const Footer: FC = () => {
  const data = useStaticQuery<{ allDatoCmsPage: Query['allDatoCmsPage'] }>(query);

  return (
    <Fragment>
      <Section
        bgColor={colors.floomMidnightBlue}
        sectionHatParams={{
          topVmin: -20,
          heightVmin: 20,
          leftPercent: -0.1,
          rightPercent: -110,
          hatIndex: 0,
          padFactor: 0.5,
          bottomOverrun: 0,
          flipX: true
        }}
      >
        <SectionContent>
          <Styles.Footer>
            <Styles.Logo>
              <Link to="/">
                <LogoMonogram
                  css={{
                    paddingLeft: -gridGutter,
                    marginRight: -gridGutter * 0.5
                  }}
                />
              </Link>
            </Styles.Logo>
            {data.allDatoCmsPage.edges.map(({ node }: any) => (
              <Link
                key={node.originalId}
                to={`/${node.slug}`}
              >
                <Styles.Link>
                  {node.pageTitle}
                </Styles.Link>
              </Link>
            ))}
            <Styles.Contact>
              <Styles.ContactTitle>
                Contact us
              </Styles.ContactTitle>
              <Styles.Link>
                <a
                  href="mailto:hello@floom.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Say Hi at hello@floom.com
                </a>
              </Styles.Link>
            </Styles.Contact>
          </Styles.Footer>
        </SectionContent>
      </Section>
    </Fragment>
  );
};

import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { textStyles, colors } from 'utils/rebass-theme';

export const EmptyCreateButton = styled(Flex)`
  ${textStyles.subhead};
  width: 100%;
  height: 70px;
  border: 2px dashed ${colors.middleGrey};
  border-radius: 3px;
  margin-top: 20px;
  cursor: pointer;
  align-items: center;
  user-select: none;
  justify-content: center;

  > p {
    margin-bottom: 3px;
    margin-left: 8px;
  }
`;

import { gql } from '@apollo/client';

export const UNREAD_PRE_ORDERS_CHECK = gql`
  query UnreadPreOrdersCheck($where: OrderCheckWhereInput!) {
    orderCheckUnreadPreOrders(
      where: $where
    ) {
      merchants {
        id
        title
        timezone
        email
        phone
        reviewCount
        reviewRating
        unread
        total
      }
    }
  }
`;

export const UNREAD_ALL_ORDERS_CHECK = gql`
  query UnreadAllOrdersCheck($where: OrderCheckWhereInput!) {
    orderCheckUnreadAllOrders(
      where: $where
    ) {
      merchants {
        id
        title
        timezone
        email
        phone
        reviewCount
        reviewRating
        unread
        total
      }
    }
  }
`;

export const PREPARED_CHECK = gql`
  query PreparedCheck($where: OrderCheckWhereInput!) {
    orderCheckPrepared(
      where: $where
    ) {
      merchants {
        id
        title
        timezone
        email
        phone
        reviewCount
        reviewRating
        withFlorist
        total
      }
    }
  }
`;

export const ON_THE_WAY_CHECK = gql`
  query OnTheWayCheck($where: OrderCheckWhereInput!) {
    orderCheckOnTheWay(
      where: $where
    ) {
      merchants {
        id
        title
        timezone
        email
        phone
        reviewCount
        reviewRating
        withFloristOrPrepared
        total
      }
    }
  }
`;

export const DELIVERED_CHECK = gql`
  query DeliveredCheck($where: OrderCheckWhereInput!) {
    orderCheckDelivered(
      where: $where
    ) {
      merchants {
        id
        title
        timezone
        email
        phone
        reviewCount
        reviewRating
        delivered
        total
      }
    }
  }
`;

export const LATE_DELIVERY_CHECK = gql`
  query LateDeliveryCheck($where: OrderCheckWhereInput!) {
    orderCheckLateDelivery(
      where: $where
    ) {
      merchants {
        id
        title
        timezone
        email
        phone
        reviewCount
        reviewRating
        delivered
        total
      }
    }
  }
`;

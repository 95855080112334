import React, { Component, ReactNode } from 'react';

import { observe } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import {
  PlanFeature
} from 'generated-types.d';

import {
  Analytics,
  MerchantService
} from 'lib';

import {
  Mode,
  ProductTab
} from 'stores/product-edit-store/product-edit-store-types';

import {
  Container
} from 'utils/css-mixins';

import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';
import ProductService from 'features/products/services';

import NoResultsGeneric from 'components/no-results-generic';
import WithLoading from 'components/with-loading';

import CreateLayoutInnerContent from './create-layout-inner-content';
import * as Styles from './create-layout.styles';
import * as Types from './create-layout.types';
import FooterContent from './footer-content/footer-content';
import { CreateSaveModal } from './modals/create-save-modal';

class CreateLayout extends Component<Types.CreateLayoutProps> {
  ProductCreateEditService = ProductService.ProductCreateEditService;

  state = {
    saveModalOpen: false
  };

  private merchantListener = observe(this.props.merchantStore!, 'merchant', ({ oldValue, newValue }: any) => {
    if (!oldValue && newValue.id) {
      this.onInit();
    }
  });

  constructor(props: Types.CreateLayoutProps) {
    super(props);

    // @ts-ignore
    const newTab: ProductTab = ProductTab[props.page];

    if (this.props.productId) {
      props.productEditStore!.setEditMode();
    }

    if (newTab) {
      this.ProductCreateEditService.setActive(newTab);
    }
  }

  componentDidMount = (): void => {
    Analytics.page(Analytics.FxPageEvent.ProductEdit);

    this.onInit();
  };

  componentWillUnmount = (): void => {
    this.merchantListener();
    this.props.productEditStore!.resetProductData();
  };

  private onInit = async (): Promise<void> => {
    if (!this.isLoadingForCreate()) {
      const { productId, productTypeId } = this.props;
      await this.ProductCreateEditService.init(productId, productTypeId);
      await this.ProductCreateEditService.setActive(ProductTab.product);
    }
  };

  private saveWithActiveModal = async (): Promise<void> => {
    if (!this.props.productEditStore!.active) {
      this.props.productEditStore!.toggleIsTabbing(false);

      const modalData: ConfirmationModalData = {
        title: 'Confirmation',
        copy: 'Do you want to put your product up for sale?',
        confirmButtonCopy: 'Yes please',
        cancelButtonCopy: 'No, not yet',
        errorCopy: '',
        onCancel: async (): Promise<any> => new Promise(async (resolve, reject): Promise<any> => {
          try {
            await this.ProductCreateEditService.saveProduct();
            resolve('');
          } catch (error) {
            reject();
          }
        }),
        confirmButtonAction: async (): Promise<any> => new Promise(async (resolve, reject): Promise<any> => {
          try {
            this.props.productEditStore!.setActive(true);
            await this.ProductCreateEditService.saveProduct();
            resolve('');
          } catch (error) {
            reject();
          }
        })
      };

      this.props.modalStore!.triggerModal({
        modalType: 'confirmation',
        data: modalData
      });
    } else {
      return this.ProductCreateEditService.saveProduct();
    }
  };

  private createSaveProduct = async (): Promise<void> => {
    if (this.props.productEditStore!.currentMode === Mode.create && this.props.productEditStore!.canActivateProduct) {
      return this.saveWithActiveModal();
    }

    return this.ProductCreateEditService.saveProduct(this.props.productEditStore!.currentTab.tabType === ProductTab.delivery);
  };

  private setActiveAndSave = async (active: boolean): Promise<void> => {
    this.props.productEditStore!.setActive(active);
    await this.ProductCreateEditService.saveProduct();
    this.closeActiveAndSaveModal();
  };

  private closeActiveAndSaveModal = (): void => {
    this.setState({
      saveModalOpen: false
    });
  };

  private isLoadingForCreate = (): boolean => {
    return this.props.productEditStore!.currentMode === Mode.create && this.props.merchantStore!.isLoadingMerchant;
  };

  render(): ReactNode {
    const { isloadingProduct, currentMode, id } = this.props.productEditStore!;
    const isCollectionSeller = MerchantService.hasPlanFeature(PlanFeature.CollectionSeller, this.props.merchantStore!.merchant!);

    return (
      <Styles.Content>
        <WithLoading
          loaderSize="small"
          marginTop="30px"
          hasNoResults={!isloadingProduct && !id && currentMode === Mode.edit}
          isLoading={isloadingProduct || this.isLoadingForCreate()}
          renderNoResults={(): ReactNode => {
            return (
              <Container maxWidth={790}>
                <Box mt="70px">
                  <NoResultsGeneric
                    icon="leaf-no-results"
                    heading="Sorry, we couldn't find that product"
                    copy=""
                  />
                </Box>
              </Container>
            );
          }}
        >
          <CreateLayoutInnerContent
            productService={this.ProductCreateEditService}
          />
          { !isCollectionSeller && (
            <FooterContent
              saveAction={this.createSaveProduct}
              isDirty={this.ProductCreateEditService.isStateDirty}
            />
          )}
          <CreateSaveModal
            isOpen={this.state.saveModalOpen}
            closeAction={this.closeActiveAndSaveModal}
            setActiveAndSave={this.setActiveAndSave}
          />
        </WithLoading>
      </Styles.Content>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  productEditStore: stores.productEditStore,
  modalStore: stores.modalStore,
  merchantStore: stores.merchantStore
}))(observer(CreateLayout));

import styled from '@emotion/styled';

import { textStyles } from 'utils/rebass-theme';

export const InnerContent = styled.div`
  padding: 20px;
  > p {
    ${textStyles.body};
  }
`;

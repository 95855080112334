import React, { Component, ReactNode } from 'react';

import TableLayoutItem from 'features/products/components/table-layout-item';

import EntityList from 'components/entity-list';
import { EntityListItems } from 'components/entity-list/entity-list.styles';

import * as Types from './table-layout-group.types';

export default class TableLayoutGroup extends Component<Types.TableLayoutGroupProps> {
  render(): ReactNode | null {
    if (!this.props.group.edges.length) return null;

    return(
      <div key={this.props.groupName}>
        <EntityList
          showHeading={this.props.showHeading}
          heading={this.props.groupName}
          headingCount={this.props.group.aggregate.count}
          showEntities={true}
          showNoResults={false}
        >
          <EntityListItems>
            {this.props.group.edges.map(({ node: product }: any) => (
              <TableLayoutItem
                key={`${product.id}`}
                item={product}
                isMakingChanges={this.props.isMakingChanges}
                onActiveStateChange={this.props.onActiveStateChange}
              />
            ))}
          </EntityListItems>
        </EntityList>
      </div>
    );
  }
}

import React, { Component, ReactNode } from 'react';

import { inject, observer } from 'mobx-react';
import { Flex, Box } from 'rebass';

import { Container } from 'utils/css-mixins';
import { gridGutter } from 'utils/rebass-theme';

import { CourierListGroupOption } from 'features/courier-bookings/courier-bookings.types';
import { COURIER_LIST_GROUP_OPTIONS } from 'features/courier-bookings/graphql/helpers/courier-list-group.config';
import CourierListService from 'features/courier-bookings/services/courier-list.service';

import LoadMoreButton from 'components/load-more-button';
import NoResultsGeneric from 'components/no-results-generic';
import SectionHeading from 'components/section-heading';
import WithLoading from 'components/with-loading';

import CourierItem from '../courier-item';

import * as Types from './courier-list.types';

class CourierList extends Component<Types.CourierListProps> {
  private onPaginate = (groupName: CourierListGroupOption): void => {
    CourierListService.onPaginate(groupName);
  };

  // any because of Object/keys issue https://github.com/Microsoft/TypeScript/issues/20853
  private getCourierListGroupKeys = (): any[] => Object.keys(this.props.courierListStore!.courierList);

  private countAllGroups = (): any => this.getCourierListGroupKeys()
    .some(key => this.props.courierListStore!.courierList[key].aggregate.count);

  private groupHasItems = (groupName: CourierListGroupOption): boolean => !!this.props.courierListStore!.courierList[groupName].aggregate.count;

  private shouldDisplayHeading = (groupName: CourierListGroupOption): boolean => COURIER_LIST_GROUP_OPTIONS[groupName] && !!COURIER_LIST_GROUP_OPTIONS[groupName].title;

  private renderItems = (groupName: CourierListGroupOption): ReactNode => {
    return this.props.courierListStore!.courierList[groupName].edges.map(edge => {
      if (!!edge?.node) {
        return (
          <CourierItem
            key={edge.node.id}
            data={edge.node}
            groupName={groupName}
            merchantId={this.props.userStore!.merchantId}
          />
        );
      }

      return null;
    });
  };

  private renderLayoutType = (groupName: CourierListGroupOption): ReactNode => {
    return (
      <Flex
        flexWrap="wrap"
        m={-gridGutter / 2}
      >
        {this.renderItems(groupName)}
      </Flex>
    );
  };

  private renderGroup = (groupName: CourierListGroupOption): ReactNode => {
    if (!this.groupHasItems(groupName)) return null;

    return (
      <div key={groupName}>
        { this.shouldDisplayHeading(groupName) && (
          <SectionHeading
            title={COURIER_LIST_GROUP_OPTIONS[groupName].title}
            count={this.props.courierListStore!.courierList[groupName].aggregate.count}
          />
        )}
        {this.renderLayoutType(groupName)}
        <LoadMoreButton
          shouldDisplay={this.props.courierListStore!.courierList[groupName].pageInfo.hasNextPage}
          isLoading={this.props.courierListStore!.courierListLoading}
          groupName={groupName}
          onLoadMore={this.onPaginate}
        />
      </div>
    );
  };

  render(): React.ReactNode {
    const courierListGroupKeys = this.getCourierListGroupKeys();

    return (
      <Box mb="30px">
        <Container>
          <WithLoading
            hasNoResults={!this.props.courierListStore!.courierListLoading && !this.countAllGroups()}
            isLoading={this.props.courierListStore!.courierListLoading
              && !courierListGroupKeys.length}
            renderNoResults={(): ReactNode => (
              <NoResultsGeneric
                icon="backpack"
                heading="No courier bookings available"
                copy=""
              />
            )}
          >
            {
              courierListGroupKeys
                .map((groupName: CourierListGroupOption) => this.renderGroup(groupName))
            }
          </WithLoading>
        </Container>
      </Box>
    );
  }
}

export default inject('courierListStore', 'userStore')(observer(CourierList));

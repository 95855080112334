import React from 'react';

import { ProductVariation } from 'stores/product-edit-store/product-edit-store-types';

import FormFieldWrapper from 'components/create-labeled-component';

import CreateVariationMediaUploader from '../create-media-uploader';

export const renderMediaItems = (variation: ProductVariation): JSX.Element => {
  return (
    <FormFieldWrapper
      title="Image"
      subtitle="Upload Jpeg files with a maximum filesize of 2mb"
    >
      <CreateVariationMediaUploader
        mediaUploads={variation.mediaUploads}
      />
    </FormFieldWrapper>
  );
};

import React, { Component } from 'react';

import cuid from 'cuid';
import { ProductType } from 'generated-types';
import { inject, observer } from 'mobx-react';

import { NavService, Analytics } from 'lib';

import { Mode } from 'stores/product-edit-store/product-edit-store-types';

import ProductServices from 'features/products/services';
import { CreateStep } from 'features/products/services/product-analytics/product-analytics.service';

import DropdownNative from 'components/dropdown-native';
import GenericModal from 'components/generic-modal';

import * as Types from './create-product-modal.types';
import * as Styles from './modal.styles';

export class CreateProductModal extends Component<Types.CreateProductModalProps, Types.CreateProductModalState> {
  ProductAnalytics = ProductServices.ProductAnalytics;

  state: Types.CreateProductModalState = {
    selectedProductType: null
  };

  updateSelectedVariation = (selectedProductTypeId: string): void => {
    this.setState({
      selectedProductType: this.findProductType(selectedProductTypeId)
    });
  };

  findProductType = (productTypeId: string): ProductType | undefined => {
    return this.props.availableProductTypes.find(type => type.id === productTypeId);
  };

  startCreating = (): void => {
    if (this.state.selectedProductType) {
      NavService.productCreate(cuid(), this.state.selectedProductType.id);
      this.props.productEditStore!.toggleProductCreateTypeModal();
      this.props.productEditStore!.updateSelectedProductTypeId(this.state.selectedProductType.id);
      this.setState({ selectedProductType: null });

      if (this.props.productEditStore!.currentMode === Mode.create) {
        this.ProductAnalytics.onCreateStep(CreateStep.ChooseType, Analytics.FxEventName.ProductCreateStep, this.state.selectedProductType!.title);
      }
    }
  };

  render(): React.ReactNode {
    const isEmptyMerchantID = this.props.merchantId === '';

    return (
      <GenericModal
        width={430}
        innerComponent={(
          <Styles.InnerContent>
            {
              !this.props.merchantId?.length || isEmptyMerchantID
                ? <p>You currently have no merchant selected. Please choose one before proceeding</p>
                : (
                  <DropdownNative
                    placeholder="Select product type"
                    optionTitleField="title"
                    optionValueField="id"
                    id="select-product-type"
                    selected={this.state.selectedProductType?.id}
                    options={this.props.availableProductTypes}
                    onChange={this.updateSelectedVariation}
                    fullWidth={true}
                  />
                )
            }
          </Styles.InnerContent>
        )}
        confirmButtonAction={this.startCreating}
        confirmButtonDisabled={!this.state.selectedProductType || isEmptyMerchantID}
        confirmButtonText="Start creating"
        title="Product type"
        modalOpen={this.props.isOpen}
        closeModal={this.props.closeAction}
      />
    );
  }
}

export default inject('productEditStore')(observer(CreateProductModal));

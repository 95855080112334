import { FC, Fragment } from 'react';

import { List as ListType } from 'generated-types';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { ListAttachment } from 'types/conversations.types';

import { Card } from 'components/card/card';

import ModalStore from '../../../../stores/modal-store/modal-store';
import { ListDetailsModalData } from '../../../modal-dialogue/components/modals/list-details-modal/list-details-modal.types';
import { CloseButton } from '../close-button/close-button';
import { ListSummary } from '../list-summary/list-summary';

import { Wrapper, Header, SkeletonBlock, CloseButtonWrapper, ListShareWrapper } from './list.styles';

type ListProps = {
  list?: ListType | ListAttachment | null;
  loading?: boolean;
  onClose?: () => void;
  timezone?: string;
  hideWrapper?: boolean;
  modalStore?: ModalStore;
}

const ListView: FC<ListProps> = ({
  list,
  loading,
  onClose,
  timezone = 'Europe/London',
  hideWrapper = false,
  modalStore
}) => {
  const handleClick = (): void => {
    modalStore!.triggerModal<ListDetailsModalData>({
      modalType: 'listDetails',
      data: {
        title: 'List Details',
        list: list,
        isOpen: true,
        timezone: 'Europe/London'
      }
    });
  };

  const ListContent: FC = () => {
    if (!list) return null;

    return (
      <Fragment>
        <Box
          width="100%"
          textAlign="left"
        >
          <Card>
            <ListShareWrapper>
              <ListSummary
                isLink={true}
                list={list}
                timezone={timezone}
                onClick={handleClick}
              />
              {onClose && (
                <CloseButtonWrapper>
                  <CloseButton onClose={onClose} />
                </CloseButtonWrapper>
              )}
            </ListShareWrapper>
          </Card>
        </Box>
      </Fragment>
    );
  };

  if (!list || loading) {
    return (
      <Wrapper
        py={2}
        px={3}
      >
        <Card>
          <Header mb={1}>
            <SkeletonBlock />
          </Header>
          <SkeletonBlock />
          <SkeletonBlock />
        </Card>
      </Wrapper>
    );
  }

  if (!hideWrapper) {
    return (
      <Wrapper
        py={2}
        px={3}
      >
        <ListContent />
      </Wrapper>
    );
  }

  return <ListContent />;
};

export const List = inject((stores: FxStores): InjectedFxStores => ({
  modalStore: stores.modalStore
}))(observer(ListView));

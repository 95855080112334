import styled from '@emotion/styled';
import { Box } from 'rebass';

export const ListOverflowWrapper: any = styled(Box)`
  overflow-x: auto;
  padding: 10px;
  margin: -10px;
`;

export const ListWrapper: any = styled(Box)`
  min-width: 950px;
`;

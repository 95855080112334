import styled from '@emotion/styled';
import { Flex } from 'rebass';

import { colors } from 'utils/rebass-theme';

export const ErrorState = styled.div`
  color: ${colors.errorText};
  position: absolute;
  bottom: 20px;
  padding: 10px;
  width: 100%;
`;

export const LoadingLogo = styled(Flex)<any>`
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 20px;
  bottom: 20px;
  transition: opacity .2s ease-in-out;
  opacity: ${({ uploading }: any): number => uploading ? 1 : 0};
`;

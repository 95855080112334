import { Currency, ListItemUnion, Maybe, SupplierDeliveryConfig } from 'generated-types.d';

import { CurrencyService } from 'lib';

import {
  getAvailabilityByListSupplier,
  getTotalListPrice
} from 'features/lists/lists.helpers';

export const getTotalQuantity = (items: ListItemUnion[]): number => {
  return items.reduce((acc, item) => acc + (item.quantity || 0), 0) || 0;
};

export const getTotalPrice = (items: ListItemUnion[]): { min: number; max: number } => {
  return {
    min: getTotalListPrice(items, Math.min),
    max: getTotalListPrice(items, Math.max)
  };
};

export const getListItemIncrement = (item: ListItemUnion): number => {
  let incrementValue = 1;

  item?.availableTradeSkus?.forEach?.(sku => {
    if (incrementValue !== 1) return;

    const availabilityForListSupplier = getAvailabilityByListSupplier(sku?.availability || [], item.list?.suppliers || []);

    availabilityForListSupplier?.forEach?.(availability => {
      if (incrementValue !== 1) return;

      if (!!availability?.multiplesOf) {
        incrementValue = availability?.multiplesOf;
      }
    });
  });

  return incrementValue;
};

export const getSalesTax = (min: number, max: number, merchantCurrency?: Currency): { min: number; max: number } => {
  if (merchantCurrency === Currency.Usd) {
    return {
      min: 0,
      max: 0
    } ;
  }

  return {
    min: CurrencyService.calculateSalesTax(min),
    max: CurrencyService.calculateSalesTax(max)
  };
};

export const getTotalShippingEstimate = (
  items: ListItemUnion[],
  supplierDeliveryConfigs?: Maybe<SupplierDeliveryConfig>[],
  merchantCurrency?: Currency
): number | null => {
  if (merchantCurrency === Currency.Usd) {
    return 0;
  }

  const deliveryOption = supplierDeliveryConfigs?.[0]?.deliveryOptions?.[0];

  if (!deliveryOption) return null;

  const freeShippingThreshold = deliveryOption.freeShippingThreshold;
  const shippingPrice = deliveryOption.shippingPrice;

  const hasFreeShipping = getTotalListPrice(items, Math.min) >= (freeShippingThreshold || 0);

  return hasFreeShipping ? 0 : (shippingPrice || 0);
};
